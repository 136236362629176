import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import DataModuloGrupo from "../../data/modulo/Grupo";
import { VentanaBase } from "../controlD3/AVentanaBase";
import { CardV2Collapse } from "../controlD3/CardV2Collapse";
import { CardInfoV2 } from "../controlD3/CardV2Info";
import { ExpanderItemListCardStyle, IExpanderItemListItemConfig } from "../controlD3/ExpanderItemList";
import { NotificacionV2 } from "../controlD3/NotificacionV2";
import { UIPanelCardGruposAlumnosV2 } from "../panelCard/GruposAlumnosV2";
import { UIPanelCardGruposHorarioOperacionV2 } from "../panelCard/GruposHorarioOperacionV2";
import { UIPanelCardGruposInfoGeneralV2 } from "../panelCard/GruposInfoGeneralV2";
import { UIPanelCardGruposMaestrosV2 } from "../panelCard/GruposMaestrosV2";
import { UIUtilIconResources } from "../util/IconResourses";
import { UIUtilLang } from "../util/Language";
import { UIUtilPermission } from "../util/Permission";
import { UIUtilGeneral } from "../util/Util";
import { UIWindowManager } from "./WindowManager";
import IGrupo = Entidad.IGrupo;
type CardsKeys = "pgrupoinfogeneral" | "pgrupohorariooperacion" | "pgruposmaestros" | "pgrupoalumnos";
type Modes = CardsKeys | "All"

const TarjetasDisponibles: { [k in CardsKeys]: { modulo: Entidad.CModulo, cardColl: CardV2Collapse<any>, langKey?: string } } = {
    "pgrupoinfogeneral": { modulo: Entidad.CModulo.Grupos, cardColl: null, langKey: "grupopanelinfogeneral" },
    "pgrupohorariooperacion": { modulo: Entidad.CModulo.Grupos, cardColl: null, langKey: "grupopanelhorariooperacion" },
    "pgruposmaestros": { modulo: Entidad.CModulo.PanelMaestros, cardColl: null },
    "pgrupoalumnos": { modulo: Entidad.CModulo.Alumnos, cardColl: null, langKey: "grupopanel_alumnos" },
}
export type KGruposCard = CardsKeys;

export class UIVentanaGruposPanelV2 extends VentanaBase {
    private currentIdGrupo: number;
    private currentMode: Modes;
    private currentGrupo: IGrupo;
    private ctrCardInfo: CardInfoV2;
    private ctrlCardsList: ExpanderItemListCardStyle;

    constructor(contenedor: d3.Selection<HTMLDivElement, any, any, any>, modulo: Entidad.CModulo) {
        super(contenedor, modulo, "grupospanel");
        this.InitPanel();
        this.InitInicialParams();
    }

    public _OnServiceEvent(eventName: DataModuloMain.TipoRequestMonitorId): void {
        super._OnServiceEvent(eventName);

        switch (eventName) {
            case Entidad.CTipoRequest.Grupo:
                this.currentGrupo = DataModuloMain._GetItemDataByName("Grupo", this.currentIdGrupo);
                TarjetasDisponibles["pgrupoinfogeneral"].cardColl._UpdateCardData(false, this.currentGrupo);
                TarjetasDisponibles["pgrupohorariooperacion"].cardColl._UpdateCardData(false, this.currentGrupo, "real");
                TarjetasDisponibles["pgruposmaestros"].cardColl._UpdateCardData(false, this.currentGrupo);
                TarjetasDisponibles["pgrupoalumnos"].cardColl._UpdateCardData(false, this.currentGrupo);
                this.UpdateCardInfo()
                break;
            case Entidad.CTipoRequest.HorarioAlumno:
                this.currentGrupo = DataModuloMain._GetItemDataByName("Grupo", this.currentIdGrupo);
                TarjetasDisponibles["pgrupoalumnos"].cardColl._UpdateCardData(false, this.currentGrupo);
                this.UpdateCardInfo();
                break;
            //case Entidad.CTipoRequest.Maestro:
            //    this.currentGrupo = DataModuloMain.fn_GetItemDataByName("Grupo", this.currentIdGrupo);
            //    TarjetasDisponibles["pgruposmaestros"].cardColl.met_UpdateCardData(false, this.currentGrupo);
            //    this.UpdateCardInfo();
            //    break;
        }
    }

    private changeHeaderInteraction(quit: boolean) {
        this.ctrlCardsList._ControlContainerSelection.selectAll(".item").select("wc-ic-collapse").classed("hide", quit);
        this.ctrlCardsList._ControlContainerSelection.selectAll(".item").style("pointer-events", quit ? "none" : "auto");
    }

    private manageCardError(isError: boolean, itemSelection: TSelectionHTML<"div", any, any>, reason: string) {
        if (itemSelection) {
            itemSelection.classed("itemlist_error", isError);
            let div_infoerror = itemSelection.select<HTMLDivElement>(".mark_infoerror");
            if (!div_infoerror.node()) {
                div_infoerror = itemSelection.append("div").classed("mark_infoerror hide", true)
                div_infoerror.append("img")
                    .attr("src", UIUtilIconResources.CGeneral.InfoMark)
                    .attr("draggable", false);
                div_infoerror.append("wc-tooltip")
                    .attr("max-width", "300px");
            }
            div_infoerror.classed("hide", !(isError && Boolean(reason)));
            if (isError && reason) {
                div_infoerror.select("wc-tooltip")
                    .text(reason);
            }
        }
    }

    private InitPanel() {
        this.windowContent.classed("panel", true);

        // >>Tarjeta de info de Grupo
        this.ctrCardInfo = new CardInfoV2()
            ._SetTitulo(null)
            ._MainInfo_CreateImageControl({
                AspectRatio: "fill",
                ControlForm: "circle",
                ImageMargin: "20%",
                Border: { // FIXME el CardInfo no deberia de controlar el borde
                    Width: 0,
                    Color: null
                }
            });

        this.windowContent.append(() => this.ctrCardInfo._CardNode);

        if (TarjetasDisponibles["pgrupoinfogeneral"].cardColl == null) {
            TarjetasDisponibles["pgrupoinfogeneral"].cardColl = new UIPanelCardGruposInfoGeneralV2(this.modulo);
            TarjetasDisponibles["pgrupohorariooperacion"].cardColl = new UIPanelCardGruposHorarioOperacionV2(this.modulo);
            TarjetasDisponibles["pgruposmaestros"].cardColl = new UIPanelCardGruposMaestrosV2(Entidad.CModulo.PanelMaestros);
            TarjetasDisponibles["pgrupoalumnos"].cardColl = new UIPanelCardGruposAlumnosV2(Entidad.CModulo.Alumnos);

            this.AllCardsRemoveHeader();
            this.CardHundredPercentHeight("pgrupohorariooperacion");
            this.CardHundredPercentHeight("pgruposmaestros");
            this.CardHundredPercentHeight("pgrupoalumnos");
        }

        TarjetasDisponibles["pgrupoinfogeneral"].cardColl._OnEditarChange((editing) => {
            if (this.currentMode == "All") {
                this.changeHeaderInteraction(editing);
            }
        })

        TarjetasDisponibles["pgrupohorariooperacion"].cardColl._OnEditarChange((editing) => {
            if (this.currentMode == "All") {
                this.changeHeaderInteraction(editing);
            }
        })

        TarjetasDisponibles["pgrupohorariooperacion"].cardColl._OnCardError((isError, reason) => {
            let itemSelection = this.ctrlCardsList._getItemList("pgrupohorariooperacion")?.CollapserItemSelection;
            this.manageCardError(isError, itemSelection, reason);
        })

        this.ctrlCardsList = new ExpanderItemListCardStyle({
            OnSelectItem: (id: CardsKeys, item) => {
                const grupo = DataModuloMain._GetItemDataByName("Grupo", this.currentIdGrupo);

                if (id == "pgrupohorariooperacion") {
                    TarjetasDisponibles["pgrupohorariooperacion"].cardColl._UpdateCardData(true, grupo, (this.currentMode !== "All" && this.currentGrupo.Horario.length == 0) ? "predefined" : "real");
                } else {
                    TarjetasDisponibles[id].cardColl._UpdateCardData(false, grupo);
                }
                this.ExpandCard(id);
            },
            OnCollapseItem: (id: CardsKeys, item) => {
                this.CollapseCard(id);
            }
        })
        this.windowContent.append(() => this.ctrlCardsList._ControlContainerNode);
    }

    private InitInicialParams() {
        const paramsAux = UIWindowManager._GetCurrentParams();
        const success = this.UpdateDataPanelByURLParams(paramsAux);

        if (success) {
            const onchangeparams: ((e: UIWindowManager.HashEvent) => void) = (e) => {
                this.UpdateDataPanelByURLParams(e.detail.Params);
            }
            this["__onchangeparams"] = onchangeparams;
            UIWindowManager._AddOnParamsChangeEventListener(onchangeparams);
        }
    }

    private UpdateDataPanelByURLParams(URLParams: URLSearchParams) {
        const idGrupo: number = (URLParams.get("id")?.split(",").map(d => Number(d))[0] || null);
        const mode: Modes = (URLParams.get("mode")?.split(",")[0] as Modes || null);
        if (idGrupo == null || mode == null) {
            this.ErrorInPanelsGoBack();
            return false;
        }
        const grupoChanged = this.currentIdGrupo != idGrupo;
        const modeChanged = this.currentMode != mode;
        this.currentIdGrupo = idGrupo;
        this.currentMode = mode;
        this.currentGrupo = DataModuloMain._GetItemDataByName("Grupo", idGrupo);
        if (!this.currentGrupo) {
            this.ErrorInPanelsGoBack(UIUtilLang._GetUIString("general", "notif_infonodisponible"));
            return false;
        }
        this.UpdateCardInfo();
        this.RefreshCardsList((!grupoChanged && !modeChanged), mode, modeChanged, grupoChanged);
        return true;
    }

    private UpdateCardInfo() {
        this.ctrCardInfo
            ._MainInfo_SetURLResourceToImageControl(this.currentGrupo.EsPrincipal ? UIUtilIconResources.CGeneral.FullStar : UIUtilIconResources.CGeneral.EmptyStar)
            ._MainInfo_ImageControlDisable(true)
            ._MainInfo_SetDescripcionGeneralPrimaryInfo(this.currentGrupo.Nombre)
            ._MainInfo_SetDescripcionGeneralSecondaryInfo([
                {
                    TopicTag: UIUtilLang._GetUIString("grupos", "d_field_nombregrado"),
                    Description: this.currentGrupo.NombreGrado
                },
                {
                    TopicTag: UIUtilLang._GetUIString("grupos", "d_field_nombreescolaridad"),
                    Description: this.currentGrupo.NombreEscolaridad
                },
                {
                    TopicTag: UIUtilLang._GetUIString("grupos", "d_field_nombrekinder"),
                    Description: (elemento) => {
                        let linkeable = UIUtilGeneral._CreateLinkeableElement(this.currentGrupo.NombreKinder, `#escuelas/escuelas/panel--id=${this.currentGrupo.IdKinder}`);
                        elemento.append(() => linkeable.node());
                    }
                }
            ])
            ._SetSections([
                {
                    ID: "descripcion_extra",
                    GetTemplateContent: (seccion) => {
                        seccion
                            .classed(UIUtilGeneral.FBoxOrientation.Vertical, true)
                            .style("row-gap", "10px")
                            .html(`
                                        <b>${UIUtilLang._GetUIString("grupos", "tag_alumnos")}</b>
                                        <label class="section_alumnos_total"></label>
                                        <label class="section_alumnos_inscritos"></label>             
                                    `)
                    },
                    OnUpdate: (seccion) => {
                        let alumnosAsignados = DataModuloGrupo._LOCALDATA_GetAlumnosEnGrupo(this.currentIdGrupo);
                        let alumnosInscritos = Array.from(alumnosAsignados.values()).filter(d => (d.IdChildMovimiento == Entidad.CNinioMovimiento.Activo));
                        seccion
                            .select(":scope > .section_alumnos_total")
                            .text(UIUtilLang._GetUIString("grupos", "tag_total") + ": " + alumnosAsignados.size);
                        seccion
                            .select(":scope > .section_alumnos_inscritos")
                            .text(UIUtilLang._GetUIString("grupos", "tag_inscritos") + ": " + alumnosInscritos.length)
                    }
                }
            ])
    }

    private ErrorInPanelsGoBack(message: string = "") {
        if (message) {
            NotificacionV2._Mostrar(message, "ADVERTENCIA");
        }
        UIWindowManager._HistoryBackEval("home");
    }

    private RefreshCardsList(useAnimation = true, mode: Modes, modeChanged: boolean, grupoChanged: boolean) {
        let itemsCardList: IExpanderItemListItemConfig[] = [];

        for (const key in TarjetasDisponibles) {
            const Tarjeta = TarjetasDisponibles[key as CardsKeys];
            if (mode == "All" || mode == key) {
                if (UIUtilPermission._HasModulePermission(Tarjeta.modulo, this.currentGrupo.IdKinder)) {
                    let label: string;
                    if (Tarjeta.langKey) {
                        label = UIUtilLang._GetUIString(Tarjeta.langKey, "card_headertitle")
                    } else {
                        label = UIUtilLang._GetUIString(Entidad.CModulo[Tarjeta.modulo], "card_headertitle")
                    }
                    itemsCardList.push({
                        Id: key,
                        Label: label,
                        ContentSelection: Tarjeta.cardColl._CardSelection
                    })
                }
            }
        }

        if (itemsCardList.length) {
            this.ctrlCardsList
                ._SetItems(itemsCardList, useAnimation);

            if (mode !== "All") {
                this.changeHeaderInteraction(true);
                if (modeChanged) {
                    TarjetasDisponibles[mode].cardColl._Expandir();
                    this.ctrlCardsList._SetIDSelected(mode);
                } else {
                    if (mode == "pgrupoinfogeneral" || mode == "pgrupohorariooperacion") {
                        let predefined = (mode == "pgrupohorariooperacion" && this.currentGrupo.Horario.length == 0);
                        TarjetasDisponibles[mode].cardColl._UpdateCardData(true, this.currentGrupo, predefined ? "predefined" : "real");
                    } else {
                        TarjetasDisponibles[mode].cardColl._UpdateCardData(false, this.currentGrupo);
                    }
                }

                if (mode == "pgrupoinfogeneral" || mode == "pgrupohorariooperacion") {
                    TarjetasDisponibles[mode].cardColl._EditarCard();
                }
            } else {
                this.changeHeaderInteraction(false);
                this.ctrlCardsList._RemoveCurrentItemFocus();
                for (const key in TarjetasDisponibles) {
                    this.CollapseCard(key as CardsKeys);
                }
                TarjetasDisponibles["pgrupohorariooperacion"].cardColl._UpdateCardData(true, this.currentGrupo, "real");
            }
            (TarjetasDisponibles["pgrupohorariooperacion"].cardColl as UIPanelCardGruposHorarioOperacionV2)._ExitWhenFinish(this.currentMode !== "All");
        }
        else {
            this.ErrorInPanelsGoBack(UIUtilLang._GetUIString("general", "notif_infonodisponible"));
        }
    }

    private ExpandCard(id: CardsKeys) {
        for (const key in TarjetasDisponibles) {
            const expanded = TarjetasDisponibles[key as CardsKeys].cardColl._expanded;
            if (key === id) {
                if (!expanded) {
                    TarjetasDisponibles[key].cardColl._Expandir();
                }
            } else if (expanded) {
                TarjetasDisponibles[key as CardsKeys].cardColl._Colapsar();
            }
        }
    }

    private CollapseCard(id: CardsKeys) {
        if (TarjetasDisponibles[id].cardColl._expanded) {
            TarjetasDisponibles[id].cardColl._Colapsar();
        }
    }

    private AllCardsRemoveHeader() {
        for (const key in TarjetasDisponibles) {
            if (TarjetasDisponibles[key as CardsKeys].cardColl?._HeaderNode) {
                TarjetasDisponibles[key as CardsKeys].cardColl._HeaderSelection.remove();
                TarjetasDisponibles[key as CardsKeys].cardColl._CardActionsSelection
                    .style("top", "var(--ventanapanel-top-options)")
                    .style("right", "0")
                    .style("padding", "var(--padding2) calc(2 * var(--padding2))");
            }
        }
    }

    private CardHundredPercentHeight(id: CardsKeys) {
        if (TarjetasDisponibles[id].cardColl?._CardNode) {
            TarjetasDisponibles[id].cardColl._CardSelection.style("height", "100%");
        }
    }

    public _Destroy(): void {
        super._Destroy();
        if (this["__onchangeparams"]) {
            UIWindowManager._RemoveOnParamsChangeEventListener(this["__onchangeparams"]);
        }
        for (const key in TarjetasDisponibles) {
            TarjetasDisponibles[key as CardsKeys].cardColl._Destroy();
            this.CollapseCard(key as CardsKeys);
        }
    }
}
