export namespace DataSecurity {

    /**
     * Encriptación AES-GCM
     */
    var salt: Uint8Array;
    var iv: Uint8Array;
    var keyPass: CryptoKey;
    var fullKey: CryptoKey;

    export async function _Ini(_password: string, _salt: string, _iv: string): Promise<boolean> {
        // console.log("soportado: " + fn_IsSupported());

        if (!_IsSupported()) {
            return false;
        }

        try {
            if (fullKey == undefined) {
                salt = new TextEncoder().encode(_salt);
                iv = new TextEncoder().encode(_iv);
                var _tempPass: Uint8Array = new TextEncoder().encode(_password);
                keyPass = await crypto.subtle.importKey(
                    "raw",
                    _tempPass,
                    "PBKDF2",
                    false,
                    ["deriveBits", "deriveKey"]
                );

                fullKey = await crypto.subtle.deriveKey(
                    {
                        "name": "PBKDF2",
                        salt: salt,
                        "iterations": 100000,
                        "hash": "SHA-256"
                    },
                    keyPass,
                    { "name": "AES-GCM", "length": 256 },
                    true,
                    ["encrypt", "decrypt"]
                );
            }
        } catch (error) {
            console.warn(error);
            return false;
        }

        // console.log("Init - " + fullKey);
        return true;
    }

    export async function _Encrypt(Data: string) {
        try {
            var encoded: Uint8Array = new TextEncoder().encode(Data);
            // var DataEncode: ArrayBuffer = await window.crypto.subtle.encrypt({
            var DataEncode: ArrayBuffer = await self.crypto.subtle.encrypt({
                name: "AES-GCM",
                iv: iv
            },
                fullKey,
                encoded
            )

            return DataEncode;
        } catch (error) {
            console.log(error);
            return null;
        }
    }


    export async function _Decrypt(Data: ArrayBuffer) {
        try {
            // var decrypted: ArrayBuffer = await window.crypto.subtle.decrypt(
            var decrypted: ArrayBuffer = await self.crypto.subtle.decrypt(
                {
                    name: "AES-GCM",
                    iv: iv
                },
                fullKey,
                Data
            );
            var text: string = await new TextDecoder().decode(decrypted);
            return JSON.parse(text);
        } catch (error) {
            console.log(error);
            return null;
        }
    }


    /**
    *
    * Encriptado Web solo funciona en un origen seguro
    * Los orígenes seguros son aquellos que coinciden con al menos uno de los siguientes patrones (scheme, host, port)
    * - (https, *, *)
    * - (wss, *, *)
    * - (*, localhost, *)
    * - (*, 127/8, *)
    * - (*, ::1/128, *)
    * - (file, *, —)
    * - (chrome-extension, *, —)
    */
    export function _IsSupported(): boolean {
        var _supported = false;

        // if (window.crypto && window.crypto.subtle) {
        if (self.crypto && self.crypto.subtle) {
            _supported = true;
        }

        return _supported;
    }

}
