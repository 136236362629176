import * as d3 from "d3";

/** @deprecated */
export namespace Notificacion {
    /** @deprecated */
    export enum CTipoNotificacion {
        // NORMAL = 1, ADVERTENCIA = 2, INFO = 3, REQUIRED = 4
        NORMAL = "NORMAL", ADVERTENCIA = "ADVERTENCIA", INFO = "INFO", REQUIRED = "REQUIRED"
    }
    /** @deprecated */
    export interface IObjetoControl {
        _Limpiar(): void;
        _Ocultar(): void;
        /** La cadena acepta html */
        _Mostrar(msg: string, tipo: (keyof typeof CTipoNotificacion)): void;
        _Destroy(): void;
    }

    interface IProperties {
        _d3_select_parent?: d3.Selection<HTMLElement, any, any, any>;
        _d3_select_contenedor?: d3.Selection<HTMLDivElement, any, any, any>;
        _d3_select_labelMsj?: d3.Selection<HTMLLabelElement, any, any, undefined>;
    }
    /** @deprecated */
    export function _Main(contenedor?: d3.Selection<HTMLElement, any, any, any>, idclass = ""): IObjetoControl {
        var propiedades: IProperties = {};
        // var v_contenedor = d3.select(document.body);
        propiedades._d3_select_parent = d3.select(document.body);
        let notificationNodeExisted: TSelectionHTML<"div">;

        if (contenedor && contenedor.size() > 0) {
            propiedades._d3_select_parent = contenedor;
        }
        if (idclass) {
            notificationNodeExisted = propiedades._d3_select_parent.select<HTMLDivElement>(":scope > ." + idclass.toLowerCase());
        }

        if (notificationNodeExisted?.node()) {
            propiedades._d3_select_contenedor = notificationNodeExisted;
            propiedades._d3_select_labelMsj = notificationNodeExisted.select(".labelTituloNotif");
        } else {
            propiedades._d3_select_contenedor = d3.create<HTMLDivElement>("div")
                .classed("notificacion", true)
                .classed(idclass.toLowerCase(), true)
                .style("display", "none")
                .style("width", "auto")
                .style("height", "auto")
                .on("click", () => {
                    Ocultar(propiedades);
                });

            propiedades._d3_select_labelMsj = propiedades._d3_select_contenedor.append<HTMLLabelElement>("label")
                .classed("labelTituloNotif", true)
                .style("color", "var(--color_text4)", "important");
        }

        var obj_control: IObjetoControl = {
            _Limpiar: () => Limpiar(propiedades),
            _Ocultar: () => Ocultar(propiedades),
            _Mostrar: (msg: string, tipo: (keyof typeof CTipoNotificacion)) => Mostrar(propiedades, msg, tipo),
            _Destroy: () => Destroy(propiedades)
        }
        return obj_control;
    }

    function Limpiar(propiedades: IProperties): void {
        propiedades._d3_select_labelMsj.text("");
    }

    function Ocultar(propiedades: IProperties): void {
        propiedades._d3_select_contenedor.transition()
            .duration(1000)
            .style("opacity", 0)
            .on("end", function () {
                d3.select(this).style("display", "none").remove();
                Limpiar(propiedades);
            });
    }

    function Mostrar(propiedades: IProperties, msg: string, tipo: (keyof typeof CTipoNotificacion)): void {
        Limpiar(propiedades);
        propiedades._d3_select_labelMsj.html(msg)
            .selectAll("b")
            .style("color", "var(--color_text4)", "important");

        var clase: string = "notificacion_info";
        switch (tipo) {
            case CTipoNotificacion.INFO:
                clase = "notificacion_info";
                break;
            case CTipoNotificacion.ADVERTENCIA:
                clase = "notificacion_warning";
                break;
            case CTipoNotificacion.REQUIRED:
                clase = "notificacion_required";
                break;
            default:
                clase = "notificacion_info";
                break;
        }

        propiedades._d3_select_parent.append(() => {
            return propiedades._d3_select_contenedor.node();
        })
        propiedades._d3_select_contenedor.transition()
            .duration(1000)
            .attr("class", clase)
            .style("display", "block")
            .style("opacity", 1)
            .on("end", function () {
                d3.select(this).style("display", "block");
            });
        setTimeout(() => {
            Ocultar(propiedades);
        }, 3000);
    }

    function Destroy(propiedades: IProperties) {
        propiedades._d3_select_contenedor.remove();
    }
}
