import { Entidad } from "../../data/Entidad";
import _L from "../../util/Labels";
import { FormGenerator } from "../controlD3/Formulario";
import { UIUtilMask } from "../util/Mask";
import { UIUtilPermission } from "../util/Permission";

import CAccion = Entidad.CAccionPermiso;

export namespace UIUtilViewFinanzasProveedores {
    const IDMODULO = Entidad.CModulo.FinanzasProveedores;
    type IProveedor = Entidad.IFinanzaProveedor;

    type IProveedorFormMulti = Omit<IProveedor, "IdEscuela"> & { IdEscuela: number[] }
    type IProveedorFormMono = Omit<IProveedor, "IdEscuela"> & { IdEscuela: number }
    /**
     * @param action `Agregar` | `Editar`
     * @param idEscuela `number` | `null`
     * @param escuelaMultiple `boolean`
     * @returns Form
     */
    export function _GetForm<Multi extends boolean>(action: CAccion.Agregar | CAccion.Editar, idEscuela: number | null, escuelaMultiple: Multi): FormGenerator<Multi extends true ? IProveedorFormMulti : IProveedorFormMono> {
        escuelaMultiple = (escuelaMultiple || false) as Multi
        const esEditar = (action == Entidad.CAccionPermiso.Editar)
        const form = new FormGenerator<IProveedorFormMulti | IProveedorFormMono>()
        form._Crear({
            LabelMaxWidth: 110,
            OnLoad: () => {
                UIUtilMask._ApplyUpperCaseMask(form._GetModelControl("RFC").selection as any, false)
            },
            schema: [
                {
                    model: "RazonSocial", type: "input", labelText: _L("finanzasproveedores.d_field_razonsocial"),
                    inputAttr: { maxlength: 80, required: true }
                },
                {
                    model: "RFC", type: "input", labelText: _L("finanzasproveedores.d_field_rfc"),
                    inputAttr: { maxlength: 30, required: true }
                },
                {
                    model: "CP", type: "input", labelText: _L("finanzasproveedores.d_field_cp"),
                    inputAttr: { maxlength: 10, required: true }
                },
                {
                    model: "Telefono", type: "input", labelText: _L("finanzasproveedores.d_field_telefono"),
                    inputAttr: { type: "phone_10A" }
                },
                {
                    model: "Correo", type: "input", labelText: _L("finanzasproveedores.d_field_correo"),
                    inputAttr: { type: "email", maxlength: 200 }
                },
                {
                    model: "Notas", type: "textArea", labelText: _L("finanzasproveedores.d_field_notas"),
                    inputAttr: { maxlength: 400 }
                },
                {
                    model: "IdEscuela", type: "selectMaterial", labelText: _L("finanzasproveedores.d_field_nombreescuela"),
                    selectMaterialAttr: {
                        disabled: esEditar,
                        removeBorder: esEditar,
                        required: true,
                        valueMember: "IdKinder",
                        displayMember: "Nombre",
                        multiselect: escuelaMultiple,
                        Data: UIUtilPermission._GetSchoolsByActionModule(IDMODULO, action, idEscuela),
                    }
                }
            ]
        })
        return form as any
    }
}
