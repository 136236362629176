import * as d3 from "d3";
import { UIUtilIconResources } from "../util/IconResourses";
import { UIUtilLang } from "../util/Language";
import { UIUtilStrings } from "../util/Strings";
import { UIUtilGeneral } from "../util/Util";
import { Button } from "./Button";
import { DateInput, IDateVal } from "./DateInput";
import { COpciones, DropdownFlex } from "./DropdownFlex";
import { TOptionInMain } from "./MenuFlex";

interface IConfigFilter<TData> {
    Parent: d3.Selection<HTMLDivElement, {}, HTMLElement, null>;
    // /** @deprecated */
    // TypeFilter?: TTypeFilter;
    Data?: Array<TData>;
    Parameters: NonNullable<Array<IParametroFiltroOLD<any>>> // FIXME ANY
    /** culumnas para filtrado de texto
     * * Ej: ["Id", "Nombre", "Tipo.Nombre"]
     */
    ColumnsFields: string[];
    BackgroundColor?: string;
    PaddingLeft?: number;
    ShowSearchFilter: boolean;
    OnGetItemDataToFilter?: (dato: TData) => any
    OnFilter: (datos: Array<TData>) => void;

    OnStepFilterByText?: (field: string, dato: TData, strFilter: string) => boolean;
    /** Personalización del filtro
     *
     * @param datum Dentro la configuración de la tabla, en caso de tener datos que se colapsan,
     * datum pasa todos los tipos de datos que maneja la tabla en sus diferentes niveles
     *
     * @returns
     * * string -> se realiza el filtro correspondiente.
     * * boolean -> Se salta el filtro por default (el valor equivale al resultado del filtro)
     */
    OnGetValueToStepFilterByParams?: (inputToSearch: string | IDateVal, datum: TData, field: string) => string | boolean

    OnStepItemFilterFinal?: (dato: TData, filterResult: boolean, originEvent: TOriginEvent) => boolean;

    /** Key del "modulo" al que pertenecen los strings labels del control */
    LangModuleKeyInContext?: string;
}

interface IPropieties {
    /** Guarda los parámetros de búsqueda actuales */
    Parameters: Map<string, IParametroFiltroAdvance>,
    ParametersBackground: Array<IParametroBackgroundFilter>
}

interface IElements {
    /** Contenedor del control */
    DivContent: TSelectionHTML<"div">
    DivAreaParam: TSelectionHTML<"div">
    DivAreaClear: TSelectionHTML<"div">

    DivButtonAdd: TSelectionHTML<"div">
    DivSearch: TSelectionHTML<"div">

    Dropdown: DropdownFlex;
    ParamBox: ParamBoxControl;
}
// /** @deprecated */
// export enum TTypeFilter { ArrayObjects, ArrayItemsTable }

/** @deprecated */
export interface IParametroFiltroOLD<TData = Object> {
    /** Ejs: "Nombre" | "Objeto.Nombre" | ... */
    Field: (keyof TData & string);
    Label?: string;
    /** @default == thisconfig.Field */
    LabelLangKey?: string;
    /** if == null -> No search Lang context
     * @default config.LangModuleKeyInContext */
    LangModuleKeyInContext?: string;
    IsDate?: boolean;
    OptionsDefault?: Array<IParametroSimple>;

    /** Aplica solo para la conf de la Tabla */
    Level?: number;
    /** Aplica solo para la conf de la Tabla
     * 
     * * @returns
     * * string -> se realiza el filtro correspondiente.
     * * boolean -> Se salta el filtro por default (el valor equivale al resultado del filtro)
    */
    OnGet_OnStepFilterByParams?: (dato: any, level: number, input: string | IDateVal) => string | boolean;
}

interface IParametersAddToFilter {
    ValueSearch?: IParametroSimple;
}
interface IParametroFiltroAdvance extends IParametroFiltroOLD, IParametersAddToFilter { }

/** @deprecated */
export interface IParametroBackgroundFilter {
    /** Ejs: "Nombre" | "Objeto.Nombre" | ... */
    Field: string,
    Value: any
}

/** @deprecated */
export interface IParametroSimple {
    Label: string;
    Value: string | IDateVal;
}

type TOriginEvent = "dataFilter" | "externalItemFilter";

/** @deprecated use FilterControlV2 instead */
export class FilterControl<TData> {
    private propieties: IPropieties;
    private config: IConfigFilter<TData>;
    private elements: IElements;
    private startSearch: NodeJS.Timeout;
    private searchText: string;

    constructor(config: IConfigFilter<TData>) {
        this.propieties = <IPropieties>{}
        this.config = <IConfigFilter<TData>>{}
        this.elements = <IElements>{}
        this.searchText = "";
        this.Init();
        this.SetConfig(config);
    }

    private Init() {
        let elem = this.elements
        elem.DivContent = d3.create("div")
            .classed("filter_container", true)

        let containerFilters = elem.DivContent.append("div")
            .classed("filters-wrapper", true)

        let containerSearch = elem.DivContent.append("div")
            .classed("search-wrapper", true)

        elem.DivAreaParam = containerFilters.append<HTMLDivElement>("div")
            .classed("area_parametros", true)
        elem.DivAreaClear = containerFilters.append<HTMLDivElement>("div")
            .classed("btn_clear", true)

        this.elements.DivSearch = containerSearch.append<HTMLDivElement>("div")
            .classed("search", true)

        const input = this.elements.DivSearch
            .append("input")
            .attr("type", "text")
            .attr("placeholder", UIUtilLang._GetUIString("general", "buscar"))
            .on("input", () => this.SearchByText())
            .node();

        this.elements.DivSearch
            .append("img")
            .attr("src", UIUtilIconResources.CGeneral.Search)
            .attr("draggable", false)
            .on("click", () => input.focus());

        elem.DivAreaClear.append<HTMLLabelElement>("label").text(UIUtilLang._GetUIString("table", "limpiarfiltro"))

        this.elements.DivButtonAdd = d3.create<HTMLDivElement>("div")
    }

    private SetConfig(config: IConfigFilter<TData>) {
        this.config = config;
        this.config.Parent.append(() => this.elements.DivContent.node())
        this.config.Data = config.Data
        this.propieties.Parameters = new Map()
        this.DrawConfig()

        this.elements.DivContent.select(".search-wrapper").classed("hide", !this.config.ShowSearchFilter);

        // >> Fix Lang context
        config.Parameters
            .forEach(d => {
                if (d.LangModuleKeyInContext == null) {
                    d.LangModuleKeyInContext = this.config.LangModuleKeyInContext;
                }
                if (d.LabelLangKey == null) {
                    d.LabelLangKey = d.Field;
                }
                let lblTag = this.GetLangContext(d.LabelLangKey, d.LangModuleKeyInContext);
                if (lblTag != d.LabelLangKey) {
                    d.Label = lblTag;
                }
            })

        // >>
        if (this.config.Parameters.length == 0) {
            this.elements.DivContent.select(".filters-wrapper").classed("hide", true)
            this.elements.DivContent.select(".search-wrapper").classed("without-border", true)
        }

        let dropdown = new DropdownFlex()
        this.elements.Dropdown = dropdown._SetConfig({
            ReferenceContent: this.elements.DivButtonAdd,
            LocationOptions: COpciones.TopAndBottom,
            ConfigOptions: this.TranslateParametros(this.config.Parameters),
            MaxHeight: 220,
        })

        let parambox = new ParamBoxControl()
        this.elements.ParamBox = parambox._SetConfig({
            parent: this.elements.DivButtonAdd,
            OnAcept: (filtro: IParametroFiltroAdvance) => {
                this.AddFilter(filtro)
            }
        })
    }

    private DrawConfig() {
        this.elements.DivAreaParam.append(() => this.elements.DivButtonAdd.node())

        if (this.config.PaddingLeft) {
            this.elements.DivAreaParam.style("padding-left", this.config.PaddingLeft + "px")
        }

        Button.BtnPlus._GetPlusButton(15, this.elements.DivButtonAdd);

        this.elements.DivButtonAdd
            .classed("button_gen button_add", true)
        this.elements.DivButtonAdd.append("label")
            .text(UIUtilLang._GetUIString("table", "filtrar"))

        this.elements.DivButtonAdd.on("click", () => {
            this.elements.Dropdown._Show()
        })

        this.elements.DivAreaClear.on("click", () => {
            this.ClearFilters()
        })
    }

    private TranslateParametros(parametros: Array<IParametroFiltroOLD>): Array<TOptionInMain> {
        let options = new Array<TOptionInMain>()
        parametros.forEach(datum => {
            let finalDatum: IParametroFiltroAdvance = {
                ...datum,
                ...{ ValueSearch: { Label: "", Value: "" } }
            }
            if (finalDatum.IsDate) {
                finalDatum.ValueSearch.Value = <IDateVal>{
                    dateA: null,
                    dateB: null,
                    // typeDateInput: TDateInputType.DateRange
                }
            }
            options.push({
                Label: finalDatum.Label,
                Callback: () => {
                    this.OpenMiniModal(finalDatum)
                }
            })
        })
        return options
    }

    private OpenMiniModal(filtro: IParametroFiltroAdvance) {
        this.elements.ParamBox._Update(filtro)
        this.elements.ParamBox._Show()
    }

    private UpdateData(data: Array<TData>, autoFilter: boolean) {
        this.config.Data = data
        if (autoFilter && this.ExistFilters()) this.ControllerDataFilter(data);
    }

    private ExistFilters(): boolean {
        return this.propieties.Parameters.size > 0 ||
            this.searchText != "" ||
            (this.propieties.ParametersBackground && this.propieties.ParametersBackground.length > 0)
    }

    private UpdateFilterParam(parametro: IParametroFiltroAdvance) {
        let found = false
        this.config.Parameters.forEach(param => {
            if (param.Field === parametro.Field) {
                param.OptionsDefault = parametro.OptionsDefault
                found = true
            }
        })
        if (!found) this.config.Parameters.push(parametro)
        if (this.config.Parameters.length > 0) {
            this.elements.DivContent.select(".filters-wrapper").classed("hide", false)
            this.elements.DivContent.select(".search-wrapper").classed("without-border", false)
        }
    }

    private AddFilter(filtro: IParametroFiltroAdvance) {
        this.propieties.Parameters.set(filtro.Field, filtro)
        this.DrawFiltersActive()
        this.ControllerDataFilter(this.config.Data)
    }

    private QuitFilter(filtroKey: string) {
        this.ResetFilter(filtroKey);
        if (this.propieties.Parameters.delete(filtroKey)) {
            this.DrawFiltersActive()
            this.ControllerDataFilter(this.config.Data)
        }
    }

    private ClearFilters(): void {
        if (this.propieties.Parameters.size > 0) {
            this.propieties.Parameters.forEach((item, key) => this.ResetFilter(key))
            this.propieties.Parameters.clear()
            this.DrawFiltersActive()
            this.ControllerDataFilter(this.config.Data)
        }
    }

    private ResetFilter(keyFilter: string) {
        let filtro = this.propieties.Parameters.get(keyFilter);
        if (filtro) {
            filtro.ValueSearch.Label = ""
            if (filtro.IsDate) {
                filtro.ValueSearch.Value = <IDateVal>{
                    dateA: null,
                    dateB: null,
                    // typeDateInput: TDateInputType.DateRange
                }
            } else {
                filtro.ValueSearch.Value = ""
            }
        }
    }

    private DrawFiltersActive() {
        let parametros: Array<IParametroFiltroAdvance> = Array.from(this.propieties.Parameters.values())

        this.elements.DivAreaParam.selectAll<HTMLDivElement, IParametroFiltroAdvance>(".item_filter")
            .data(parametros, (d) => d.Field)
            .join(
                enter => {
                    let item = enter.append<HTMLDivElement>("div")
                        .style("opacity", 0)
                        .classed("button_gen item_filter", true)

                    item.append("label");

                    Button.BtnClose._GetCloseCircleButton(item);

                    item.classed("test", (d, i, items) => {
                        let itemDiv = d3.select<HTMLDivElement, IParametroFiltroAdvance>(items[i])
                        this.UpdateFilterItemView(d, itemDiv);
                        return false;
                    })
                    item.transition().duration(200)
                        .style("opacity", 1);

                    return item;
                },
                update => update.classed("test", (d, i, items) => {
                    let itemDiv = d3.select<HTMLDivElement, IParametroFiltroAdvance>(items[i])
                    this.UpdateFilterItemView(d, itemDiv);
                    return false;
                }),
                exit => exit.transition().duration(200)
                    .style("opacity", 0)
                    .style("width", "0px")
                    .remove()
            )
        this.elements.DivButtonAdd.raise();
    }

    private UpdateFilterItemView(d: IParametroFiltroAdvance, viewItem: d3.Selection<HTMLDivElement, IParametroFiltroAdvance, HTMLElement, any>) {
        viewItem.select<HTMLLabelElement>("label").text(() => d.Label + ': "' + d.ValueSearch.Label + '"')

        viewItem.on("click", () => {
            let even = d3.event
            if (even.target.localName === "label" || even.target.localName === "div") {
                this.OpenMiniModal(d)
            }
        })
        viewItem.select<SVGElement>(".btn_close_circle").on("click", () => {
            this.QuitFilter(d.Field)
        })
    }

    private SearchByText() {
        let timeDelay = 1000;
        if (this.startSearch) {
            clearTimeout(this.startSearch);
        }
        this.startSearch = setTimeout(() => {
            let input = this.elements.DivSearch.select("input");
            this.searchText = input.property("value");
            this.ControllerDataFilter(this.config.Data);
        }, timeDelay);
    }

    private ControllerDataFilter(data: Array<TData>) {
        // if (this.config.TypeFilter === TTypeFilter.ArrayObjects) {
        //     this.ApplyFilterObjects(data as Array<Object>)
        // } else {
        if (data) {
            let auxData: Array<TData> = new Array();
            // auxData = this.Item_ApplyFilterItemsTable(auxData);
            // auxData = this.ApplySearchByText(auxData)
            data.forEach((itemD) => {
                if (this.ItemControllerFilter(itemD, true, true, "dataFilter")) {
                    auxData.push(itemD);
                }
            })
            this.config.OnFilter(auxData);
        }
        /// }
    }

    private ItemControllerFilter(itemD: TData, includeBackgroundFilter: boolean, includeSearchTextFilter: boolean, originEvent: TOriginEvent): boolean {
        let itemAux = this.config.OnGetItemDataToFilter ? this.config.OnGetItemDataToFilter(itemD) : itemD;
        let backgroundFilterResult = (includeBackgroundFilter ? this.Item_ApplyBackgroundFilter(itemAux) : true);
        let filterResult = backgroundFilterResult;

        if (backgroundFilterResult) {
            filterResult = this.Item_ApplyFilterItemsTable(itemAux, itemD);
            if (includeSearchTextFilter && filterResult) {
                filterResult = this.Item_ApplySearchByText(itemAux, itemD);
            }
        }

        if (this.config.OnStepItemFilterFinal) {
            filterResult = this.config.OnStepItemFilterFinal(itemD, filterResult, originEvent);
        }
        return filterResult;
    }

    private Item_ApplyBackgroundFilter(itemD: TData): boolean {
        let params = this.propieties.ParametersBackground;
        // let auxData: Array<Table.TDataItemTable<TData>> = new Array();
        if (params) { // && params.length > 0) {
            // data.forEach(itemD => {
            // <Sólo para idsKinders: KinderFiltro
            if (params.length == 0 && itemD["KinderFiltro"] && itemD["KinderFiltro"].length == 0) {
                // auxData.push(itemD)
                // NOTE NEVER
                return true;
            }
            // Sólo para idsKinders/>
            for (let param of params) {
                let dat = UIUtilGeneral._GetValueLevelsObj(param.Field, itemD);
                if (Array.isArray(dat)) {
                    if (dat.length == 0) {
                        // auxData.push(itemD);
                        // break;
                        return true;
                    }
                    let indice = dat.indexOf(param.Value);
                    if (indice >= 0) {
                        // auxData.push(itemD);
                        // break;
                        return true;
                    }
                }
                else {
                    if (dat == param.Value) {
                        // auxData.push(itemD);
                        // break;
                        return true;
                    }
                }
            }
            return false;
            // })
            // return auxData;
        }
        return true;
    }

    private Item_ApplyFilterItemsTable(itemAux: unknown, itemD: TData): boolean {
        // let results: TDataItemTable<TData>[] = new Array()
        //results = Array.from(JSON.parse(JSON.stringify(data))) as Array<TDataItemTable>
        // results = data
        //console.log("Results: ", results, " Data: ", data)
        let parametros = Array.from(this.propieties.Parameters.values())
        for (let param of parametros) {
            // let res = new Array()
            // res = data.filter(itemD => {

            let register: any;
            if (this.config.OnGetValueToStepFilterByParams) {
                register = this.config.OnGetValueToStepFilterByParams(param.ValueSearch.Value, itemD, param.Field);
            } else {
                register = UIUtilGeneral._GetValueLevelsObj(param.Field, itemAux);
            }
            register = (register != null && typeof register != "boolean") ? String(register) : register;

            if (typeof register == "string" && register) {
                let toFilter = String(register)
                if (param.IsDate) {
                    /* if (!this.CompareDates(param, toFilter)) {
                        return false;
                    } */
                } else {
                    if (toFilter !== "" && toFilter[0]) {
                        if (!UIUtilStrings._StringRemoveDiacriticMarks(toFilter.toLowerCase()).startsWith(UIUtilStrings._StringRemoveDiacriticMarks(String(param.ValueSearch.Value).toLowerCase()))) {
                            return false
                        }
                    }
                }
            }
            else if (typeof register == "boolean") {
                if (!register) {
                    return false;
                }
            }
            // else {
            //     return false;
            // }
            // })
            // data = res
        }
        //console.log("Results: ", results, " Data: ", data)
        //this.config.OnFilter(results)
        return true;
    }

    private Item_ApplySearchByText(itemAux: unknown, itemD: TData): boolean {
        let fields = this.config.ColumnsFields;
        // data && data.length > 0 && 
        // let results = new Array();
        if (fields?.length > 0 && this.searchText) {
            // results = data.filter(itemD => {
            for (let field of fields) {
                if (this.config.OnStepFilterByText) {
                    if (this.config.OnStepFilterByText(field, itemD, this.searchText.toLowerCase())) {
                        return true;
                    }
                } else {
                    let register = UIUtilGeneral._GetValueLevelsObj(field, itemAux);
                    if (register) {
                        let toFilter = String(register)

                        if (toFilter !== "" && toFilter[0]) {
                            if (UIUtilStrings._StringRemoveDiacriticMarks(toFilter.toLowerCase()).includes(UIUtilStrings._StringRemoveDiacriticMarks(this.searchText.toLowerCase()))) {
                                return true
                            }
                        }
                        else if (toFilter == "" && this.searchText == "") {
                            return true;
                        }
                    }
                }
            }
            return false;
            // })
        }
        // else {
        //     results = data
        // }
        // console.log("Results: ", results, " Data: ", data)
        return true;
    }

    /* private CompareDates(param: IParametroFiltroAdvance, strDate: string): boolean {
        let search: IDateVal = <IDateVal>param.ValueSearch.Value;
        let dateSearchStart = new Date(search.dateStart.Year, search.dateStart.Month - 1, search.dateStart.Day);
        let dateRegister = new Date(strDate);
        // let dateSearchStart = new Date(search.dateStart.Year + "-" + (search.dateStart.Month < 10 ? "0" : "") + search.dateStart.Month + "-" + (search.dateStart.Day < 10 ? "0" : "") + search.dateStart.Day)
        let valide = false;
        // console.log(strDate, search.dateStart.Year + "-" + (search.dateStart.Month < 10 ? "0" : "") + search.dateStart.Month + "-" + (search.dateStart.Day < 10 ? "0" : "") + search.dateStart.Day, param)
        switch (search.typeDateInput) {
            case TDateInputType.DateRange:
                // let dateSearchEnd = new Date(search.dateEnd.Year + "-" + search.dateEnd.Month + "-" + search.dateEnd.Day);
                let dateSearchEnd = new Date(search.dateEnd.Year, search.dateEnd.Month - 1, search.dateEnd.Day);
                dateSearchStart.setHours(0);
                dateSearchStart.setMinutes(0);
                dateSearchStart.setMilliseconds(0);

                dateSearchEnd.setHours(11);
                dateSearchEnd.setMinutes(59);
                dateSearchEnd.setMilliseconds(99);

                if (dateRegister >= dateSearchStart && dateRegister <= dateSearchEnd) {
                    valide = true;
                }
                break;
            case TDateInputType.OnlyADate:
                // if (dateSearchStart.getTime() == dateRegister.getTime()) {
                if ((dateRegister.getFullYear() == dateSearchStart.getFullYear() && dateRegister.getMonth() == dateSearchStart.getMonth() && dateRegister.getDate() == dateSearchStart.getDate())) {
                    valide = true;
                }
                break;
            case TDateInputType.Year:
                break;
            case TDateInputType.YearMonth:
                break;
            default:
        }
        return valide;
    } */

    private GetLangContext(stringKey: string, langModuleKeyInContext = this.config.LangModuleKeyInContext) {
        if (langModuleKeyInContext) {
            return (UIUtilLang._GetUIString(langModuleKeyInContext, stringKey) || stringKey);
        }
        return stringKey;
    }

    // ********************************************************************************
    // PUBLIC METHODS
    // ********************************************************************************

    public get _CurrentSearchText() {
        return this.searchText;
    }

    public get _ControlSelection() {
        return this.elements.DivContent;
    }

    public get _ControlNode() {
        return this.elements.DivContent.node();
    }

    public _SetSearchText(value: string) {
        this.searchText = value;
        this.elements.DivSearch
            .select("input")
            .property("value", value);
        this.ControllerDataFilter(this.config.Data);
    }

    public _GetFiltersExist() {
        this.ExistFilters()
    }
    public _UpdateData(data: Array<TData>, autoFilter: boolean) {
        this.UpdateData(data, autoFilter);
    }

    /** NOTA: El tipo de filtro es considerado "externalItemFilter" */
    public _IsItemValid(item: TData, includeBackgroundFilter: boolean, includeSearchTextFilter: boolean) {
        return this.ItemControllerFilter(item, includeBackgroundFilter, includeSearchTextFilter, "externalItemFilter");
    }

    public _UpdateFilter(parametro: IParametroFiltroOLD) { // REMOVER
        let newParameter: IParametroFiltroAdvance = {
            ...parametro,
            ...{ ValueSearch: { Label: "", Value: "" } }
        }
        if (newParameter.IsDate) {
            newParameter.ValueSearch.Value = <IDateVal>{
                dateA: null,
                dateB: null,
                // typeDateInput: TDateInputType.DateRange
            }
        }
        this.UpdateFilterParam(newParameter)
        this.elements.Dropdown._UpdateOptions(this.TranslateParametros(this.config.Parameters))
        if (this.elements.ParamBox._IsVisible) this.elements.ParamBox._Update(newParameter)
    }
    public _Clear() {
        this.ClearFilters();
    }
    public _BackgroundFilter(paramsBack: Array<IParametroBackgroundFilter>) {
        this.propieties.ParametersBackground = paramsBack;
        this.ControllerDataFilter(this.config.Data);
    }
}

// CONTROL PARAMBOXCONTROL: ENTRADA DE PARAMETRO DE FILTRO
interface IConfigParamBox {
    parent: d3.Selection<HTMLDivElement, {}, HTMLElement, null>;
    OnAcept(param: IParametroFiltroAdvance): void
}

class ParamBoxControl {
    private divContainer: TSelectionHTML<"div">
    private labelTitle: d3.Selection<HTMLLabelElement, {}, HTMLElement, null>

    private labelAceptar: d3.Selection<HTMLLabelElement, {}, HTMLElement, null>
    private inputValue: d3.Selection<HTMLInputElement, {}, HTMLElement, null>
    private inputSelect: TSelectionHTML<"input">
    private inputContent: TSelectionHTML<"div">

    private ctrlDropdown: DropdownFlex
    private ctrlDateInput: DateInput;

    private config: IConfigParamBox
    private param: IParametroFiltroAdvance
    private typeControl: "input" | "select" | "date";
    private initValue: IParametroSimple
    private initOptions: { key: string, options: Array<TOptionInMain> }
    private historyOptions: Map<string, Array<TOptionInMain>>
    private visible: boolean

    private observeResize: ResizeObserver;

    constructor() {
        this.initValue = <IParametroSimple>{}
        this.historyOptions = new Map<string, Array<TOptionInMain>>()
        this.visible = false
        this.Init()
    }
    private Init() {
        this.divContainer = d3.create<HTMLDivElement>("div")
            .classed("ghost_none", true)

        this.divContainer.node().onclick = (e) => {
            if (e.target == this.divContainer.node()) {
                if (this.param) {
                    this.param.ValueSearch.Label = this.initValue.Label
                    this.param.ValueSearch.Value = this.initValue.Value
                }
                this.CancelarData()
                this.Hide()
            }
        }
        let control = this.divContainer.append<HTMLDivElement>("div")
            .classed("parambox_container", true)

        let head = control.append<HTMLDivElement>("div")
            .classed("param_head button_gen", true)

        let areatitle = head.append<HTMLDivElement>("div")
            .classed("head_title", true)
        this.labelTitle = areatitle.append<HTMLLabelElement>("label").text("Title")

        let areaclose = head.append<HTMLDivElement>("div")
            .classed("head_close", true)

        let svgClose = areaclose.append<SVGElement>("svg")
            .attr("viewBox", "0 0 100 100")

        svgClose.append<SVGPathElement>("path")
            .attr("d", "M 8 8 l 84 84 z M 8 92 l 84 -84")
        svgClose.on("click", () => {
            this.CancelarData()
            this.Hide()
        })

        let divBody = control.append<HTMLDivElement>("div")
            .classed("param_body", true)

        this.inputContent = divBody.append("div").style("position", "relative")

        this.inputValue = this.inputContent.append<HTMLInputElement>("input")
            .attr("placeholder", "Empieza con...")
            .classed("filter_input", true)

        this.inputSelect = this.inputContent.append<HTMLInputElement>("div")
            .style("display", "none")
            .classed("filter_select", true)

        this.inputSelect.append<SVGElement>("svg")
            .attr("viewBox", "0 0 15 12")
            .append<SVGPathElement>("path")
            .attr("d", "M 5 5 l 12 0 l -6 5 z")

        let pie = control.append<HTMLDivElement>("div")
            .classed("param_footer", true)

        this.labelAceptar = pie.append<HTMLLabelElement>("label").text(UIUtilLang._GetUIString("general", "aplicar"))

        this.ctrlDateInput = new DateInput({
            Parent: divBody
        })
    }

    public _SetConfig(config: IConfigParamBox): this {
        this.config = config
        // Evento escribir
        this.inputValue.node().onkeyup = (e) => {
            this.param.ValueSearch.Value = this.inputValue.node().value;
            this.param.ValueSearch.Label = this.inputValue.node().value;
            if (e.code == "Enter") {
                this.AceptData();
            }
        }
        // Evento ACEPTAR
        this.labelAceptar.on("click", () => {
            this.AceptData()
        })

        // Creando DropDown
        let drop = new DropdownFlex()
        this.ctrlDropdown = drop._SetConfig({
            ReferenceContent: this.inputContent,
            LocationOptions: COpciones.OnlyBottom,
            ShowLastSelect: true,
            MaxHeight: 200,
            Width: 180,
            ZIndexInit: 540
        })
        this.AutoUbicate()
        return this;
    }

    private AceptData() {
        if (this.typeControl == "date") {
            /* let value = this.ctrlDateInput._GetValues()
            if (value.typeDateInput == TDateInputType.DateRange) {
                if (value.dateStart && value.dateEnd) {
                    this.param.ValueSearch.Label = this.ctrlDateInput._GetStrDate(value.dateStart, "/") + " - " + this.ctrlDateInput._GetStrDate(value.dateEnd, "/");
                    this.param.ValueSearch.Value = value;
                    this.config.OnAcept(this.param)
                    this.Hide();
                } else {
                    if (!value.dateStart) {
                        this.divContainer.select(".date-start").classed("input_war", true)
                    }
                    if (!value.dateEnd) {
                        this.divContainer.select(".date-end").classed("input_war", true)
                    }
                }
            } else if (value.typeDateInput == TDateInputType.OnlyADate) {
                if (value.dateStart) {
                    this.param.ValueSearch.Label = this.ctrlDateInput._GetStrDate(value.dateStart, "/");
                    this.param.ValueSearch.Value = value;
                    this.config.OnAcept(this.param)
                    this.Hide();
                } else {
                    this.divContainer.select(".date-start").classed("input_war", true)
                }
            } */
            return;
        }
        if (this.inputValue.node().value != "") {
            this.config.OnAcept(this.param);
            this.Hide();
        } else {
            this.inputValue.classed("input_war", true);
            this.inputValue.node().focus();
        }
    }

    private CancelarData() {
        this.param.ValueSearch.Label = this.initValue.Label
        this.param.ValueSearch.Value = this.initValue.Value
        if (this.initOptions && this.initOptions.options) {
            let opc: Array<TOptionInMain> = this.historyOptions.get(this.initOptions.key)
            if (opc) {
                opc.forEach((op, i) => {
                    op.selected = this.initOptions.options[i].selected
                })
            }
        }
    }

    private Show() {
        this.visible = true
        let body = d3.select("body")
        body.append(() => this.divContainer.node())
        this.Reubicate()
        this.observeResize?.observe(this.divContainer.node())
        this.inputValue.node().focus()
    }

    private Hide() {
        this.visible = false
        this.observeResize?.unobserve(this.divContainer.node())
        this.divContainer.remove()
        this.divContainer.selectAll("input").classed("input_war", false)
    }

    private Update(param: IParametroFiltroAdvance) {
        this.param = param
        this.initValue.Label = param.ValueSearch.Label
        this.initValue.Value = param.ValueSearch.Value

        this.labelTitle.text(param.Label)

        if (param.IsDate) {
            this.typeControl = "date";
            this.divContainer.select(".parambox_container").style("width", "350px");
            this.ctrlDateInput._Show();
            this.ctrlDateInput._SetValues(<IDateVal>this.initValue.Value)
            d3.select(this.inputValue.node().parentNode as HTMLElement).classed("display_none", true);
            return;
        }
        this.divContainer.select(".parambox_container").style("width", "200px")
        d3.select(this.inputValue.node().parentNode as HTMLElement).classed("display_none", false)
        this.ctrlDateInput._Remove()
        this.inputValue.node().value = param.ValueSearch.Label
        if (param.OptionsDefault) {
            this.typeControl = "select"
            this.inputValue.attr("placeholder", UIUtilLang._GetUIString("general", "selectaoption"))
            this.initOptions = { key: param.Field, options: JSON.parse(JSON.stringify(this.translateOptions(param.Field, param.OptionsDefault))) }
            this.ctrlDropdown._UpdateOptions(this.translateOptions(param.Field, param.OptionsDefault))
            this.inputValue.node().disabled = true
            this.inputSelect.style("display", "flex")
            this.inputSelect.on("click", () => {
                if (param.OptionsDefault.length > 0) {
                    this.ctrlDropdown._Show()
                }
            })
        }
        else {
            this.typeControl = "input";
            this.inputValue.attr("placeholder", UIUtilLang._GetUIString("general", "startswith") + "...");
            this.inputValue.node().disabled = false
            this.inputSelect.style("display", "none")
            this.inputSelect.on("click", null)
        }
    }

    private translateOptions(key: string, opciones: Array<IParametroSimple>): Array<TOptionInMain> {
        let options = new Array<TOptionInMain>()
        if (this.historyOptions.has(key)) {
            options = this.historyOptions.get(key)
            // Si es nueva busqueda, desmarcar LastSelect
            if (this.initValue.Value == "") {
                options.forEach(opc => opc.selected = false)
            }
        }
        else {
            if (opciones) {
                opciones.forEach(d => {
                    options.push({
                        Label: d.Label,
                        Callback: () => {
                            this.inputValue.node().value = d.Label
                            this.param.ValueSearch.Label = d.Label
                            this.param.ValueSearch.Value = d.Value
                        }
                    })
                })
                if (options.length > 0) this.historyOptions.set(key, options)
            }
        }
        return options
    }

    private Reubicate() {
        let limits = this.config.parent.node().getBoundingClientRect()
        let containerBox = this.divContainer.node().getBoundingClientRect()
        let dif = containerBox.width - (limits.x + limits.width + 300)
        let left = limits.x + (dif < 0 ? dif : 0)
        this.divContainer.select(".parambox_container").style("top", (limits.y + limits.height) + "px")
            .style("left", left + "px")
    }

    private AutoUbicate() {
        this.observeResize = UIUtilGeneral._GetResizeObserver(entries => {
            this.Reubicate();
        });
    }

    public _Show() {
        this.Show();
    }

    public _Hide() {
        this.Hide();
    }

    public _Update(param: IParametroFiltroAdvance) {
        this.Update(param)
    }

    get _IsVisible() {
        return this.visible
    }

}
