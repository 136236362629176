export namespace UIUtilIconResources {
    export enum CGeneral {
        App = "./image/logos/app_logo.svg",
        AppLightBackground = "./image/logos/app_logo_background_light.svg",
        User = "./image/menu/ic_usuario.svg",
        Camara = "./image/iconos/ic_camara.svg",
        Folder = "./image/iconos/ic_folder.svg",
        PDF = "./image/iconos/ic_pdf.svg",
        Copy = "./image/iconos/ic_copy.svg",
        AngleUp = "image/iconos/ic_angle_up.svg",
        AngleDown = "image/iconos/ic_angle_down.svg",
        Editar = "./image/iconos/ninioinfo/ic_editar.svg",
        Eliminar = "./image/iconos/ninioinfo/ic_eliminar.svg",
        Filtrar = "./image/iconos/ninioinfo/ic_filtrar.svg",
        AcceptInCircle = "./image/iconos/ic_acept.svg",
        CloseInCircle = "image/iconos/ic_close.svg",
        Sync = "image/iconos/ic_sync.svg",
        EmptyStar = "image/iconos/grupoinfo/ic_emptystar.svg",
        FullStar = "image/iconos/grupoinfo/ic_fullstar.svg",
        InfoMark = "image/iconos/ic_infomark.svg",
        Eye = "image/iconos/ic_eye.svg",
        EyeV2 = "image/iconos/ic_eye_v2.svg",
        EyeHidden = "image/iconos/ic_eye_hidden.svg",
        Download = "image/iconos/ic_download.svg",
        Search = "image/iconos/ic_search.svg",
        Logout = "image/menu/ic_logout.svg",
        Elevenminds = "image/logos/app_elevenminds.svg",
        Pin = "image/menu/ic_pin.png",
        Unping = "image/menu/ic_unpin.png",
        Settings = "image/iconos/ic_cogs.svg",
        Print = "image/iconos/ic_print.svg",
        Circular2 = "image/iconos/ic_circulares2.svg",
        ClearText = "image/iconos/ic_clear_text.svg",
        Close = "image/iconos/ninioinfo/ico_quitar.svg",
        CloseLight = "image/iconos/ico_closelight.svg",
        Options = "image/iconos/ic_options.svg",
        ChangePassword = "image/iconos/ic_rstpsswd.svg",
        Menos = "image/iconos/ic_minus.svg",
        Add = "image/iconos/grupoinfo/ic_add.svg",
        Upload = "image/iconos/ic_upload.svg",
        Dimensions = "image/iconos/ic_dimensions.svg",
        Pastel = "image/iconos/ninioinfo/ic_pastel.svg",
        Evento = "image/iconos/ninioinfo/ic_evento.svg",
    }

    export enum CActividadesEscolaridad {
        Actividades = "./image/iconos/escuelasactividades/ic_actividades.svg",
        Alimentos = "./image/iconos/escuelasactividades/ic_alimentos.svg",
        Logros = "./image/iconos/escuelasactividades/ic_logros.svg",
        Humor = "./image/iconos/escuelasactividades/ic_humor.svg",
        Fotos = "./image/iconos/escuelasactividades/ic_fotos.svg",
        Higiene = "./image/iconos/escuelasactividades/ic_higiene.svg",
        Comentarios = "./image/iconos/escuelasactividades/ic_comentarios.svg",
        Chat = "./image/iconos/escuelasactividades/ic_chat.svg",
        Siesta = "./image/iconos/escuelasactividades/ic_siesta.svg"
    }
    export enum CEmociones {
        Enfermo = "./image/iconos/escuelashumor/ic_enfermo.svg",
        Enojado = "./image/iconos/escuelashumor/ic_enojado.svg",
        Feliz = "./image/iconos/escuelashumor/ic_feliz.svg",
        Lloroso = "./image/iconos/escuelashumor/ic_lloroso.svg",
        Serio = "./image/iconos/escuelashumor/ic_serio.svg",
        Somnoliento = "./image/iconos/escuelashumor/ic_somnoliento.svg",
        Travieso = "./image/iconos/escuelashumor/ic_travieso.svg",
        Triste = "./image/iconos/escuelashumor/ic_triste.svg"
    }
    export enum CMenuIco {
        Alumno = "image/menu/modulo/ic_alumnos.svg",
        Ayuda = "image/menu/modulo/ic_ayuda.svg",
        Circular = "image/menu/modulo/ic_circulares.svg",
        Escuela = "image/menu/modulo/ic_escuelas.svg",
        Finanzas = "image/menu/modulo/ic_finanzas.svg",
        Licencias = "image/menu/modulo/ic_licencias.svg",
        Logros = "image/menu/modulo/ic_logros.svg",
        Reportes = "image/menu/modulo/ic_reportes.svg",
        Usuario = "image/menu/modulo/ic_usuarios.svg",
    }

    export enum Payment {
        Transfer = "image/iconos/payment/ic_transfer.svg",
        Card = "image/iconos/payment/ic_credit_card.svg",
        MoneyBill = "image/iconos/payment/ic_money_bill_alt.svg"
    }

    export enum COpenPay {
        Cards1 = "image/openpay/ic_cards1.png",
        Cards2 = "image/openpay/ic_cards2.png",
        CVV = "image/openpay/ic_cvv.png",
        OpenPay = "image/openpay/ic_openpay.png",
        RadioOn = "image/openpay/ic_radio_on.png",
        Security = "image/openpay/ic_security.png",
    }
}
