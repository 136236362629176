import { MainPage } from "../../MainPage";
import { DataModuloMain } from "../../data/ModuloMain";

import CTipoRequestM = DataModuloMain.TipoRequestMonitorId;

type TCallback<k extends CTipoRequestM> = (detail: MainPage.EventWkrMessage<k>["detail"]) => void;
interface IItem {
    active: boolean;
    call: (evData: MainPage.EventWkrMessage<any>) => void;
}

/** Administrador de listeners para el Worker monitor de modulos `WModulos` */
export class UIUtilMonitorRequestObserver {
    private tRequestsCalls: Map<CTipoRequestM, IItem>;
    private cancelingElement: HTMLElement;
    private deleteAllCallbacksOnCancel: boolean;

    constructor() {
        this.tRequestsCalls = new Map();
    }

    private ActiveEventListener(tReq: CTipoRequestM): void {
        const item = this.tRequestsCalls.get(tReq);
        if (!item || item.active) return;
        item.active = true;
        MainPage._AddEventListenerWorkerRequest(tReq, item.call);
    }

    private StopEventListener(tReq: CTipoRequestM): void {
        const item = this.tRequestsCalls.get(tReq);
        if (!item || !item.active) return;
        item.active = false;
        MainPage._RemoveEventListenerWorkerRequest(tReq, item.call);
    }

    /** Si se establece, se toma de referencia para cancelar los `callbacks` cuando el elemento deje de estar conectado.
     * Evalua `element.isConnected`
     * @param element elemento de referencia para cancelar los `callbacks`
     * @param deleteAllCallbacks
     *  * Si es `true` detiene y elimina los `callbacks` configurados
     *  * Si es `false` solo detiene los listeners
     * @default true
     * @returns `this`
    */
    public _SetCancelingElement(element: HTMLElement, deleteAllCallbacks = true): this {
        this.cancelingElement = element;
        this.deleteAllCallbacksOnCancel = deleteAllCallbacks;
        return this;
    }

    /**
     * Agrega o reemplaza un `callback` para un `requestID`
     * @param requestID
     * @param callback
     * @returns `this`
     */
    public _SetCallback<k extends CTipoRequestM>(requestID: k, callback: TCallback<k>): this {
        const itemOld = this.tRequestsCalls.get(requestID);
        if (itemOld?.active) {
            this.StopEventListener(requestID);
        }
        this.tRequestsCalls.set(requestID, {
            active: false,
            call: (e) => {
                if (this.cancelingElement && !this.cancelingElement.isConnected) {
                    if (this.deleteAllCallbacksOnCancel)
                        this._DeleteAllRequestCallback();
                    else
                        this._StopAll();
                    return;
                }
                callback(e.detail);
            },
        });
        if (itemOld?.active) {
            this.ActiveEventListener(requestID);
        }
        return this;
    }

    /**
     * Agrega o reemplaza un `callback` compartido para cada Id de `requestIDs`
     * @param requestIDs
     * @param callback
     * @returns `this`
     */
    public _SetCallbackLinked(requestIDs: CTipoRequestM[], callback: TCallback<CTipoRequestM>) {
        requestIDs.forEach(idReq => {
            this._SetCallback(idReq, callback);
        })
        return this;
    }

    /**
     * Elimina el `callback` del `requestID`
     * @param requestID
     * @returns `this`
     */
    public _DeleteRequestCallback(requestID: CTipoRequestM) {
        const item = this.tRequestsCalls.get(requestID);
        if (item?.active) this.StopEventListener(requestID);
        this.tRequestsCalls.delete(requestID);
        return this;
    }

    public _DeleteAllRequestCallback() {
        Array.from(this.tRequestsCalls.entries()).forEach(([k, item]) => {
            if (item.active) this.StopEventListener(k);
            this.tRequestsCalls.delete(k);
        })
        return this;
    }

    /**
     * Activa un listener para el `requestID` con un `callback` asignado
     * @param requestID
     * @returns `this`
     */
    public _ObserveReq(requestID: CTipoRequestM) {
        this.ActiveEventListener(requestID);
        return this;
    }

    public _ObserveAll() {
        this.tRequestsCalls.forEach((_item, k) => {
            this.ActiveEventListener(k);
        })
        return this;
    }

    public _StopReq(requestID: CTipoRequestM) {
        this.StopEventListener(requestID);
        return this;
    }

    public _StopAll() {
        this.tRequestsCalls.forEach((_item, k) => {
            this.StopEventListener(k);
        })
        return this;
    }
}
