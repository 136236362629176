import * as d3 from "d3";
import _L from "../../util/Labels";
import { HTMLIconCollapseElement } from "../controlWC/IconTogglerCollapseComponent";
import { HTMLProgressElement } from "../controlWC/ProgressComponent";
import { HTMLTooltipComponent } from "../controlWC/TooltipComponent";
import { DropdownAdvanced, TDropdownData } from "./DropdownAdvanced";

export interface ISelectV2Config<TData, VM extends keyof TData, TypeCtrl extends keyof IMapTData<TData> = "monoselect"> {
    Type: TypeCtrl;
    Parent: d3.Selection<HTMLElement, any, any, any>;
    /** @default true */
    AppendInputOnParent?: boolean;
    ListWidth?: string,
    ValueMember: VM;
    DisplayMember: keyof TData;
    /** @default [] */
    Data?: TDropdownData<TData>;
    /** @default "Seleccione una opción" */
    Placeholder?: string;
    /** @default false */
    Disabled?: boolean;
    RemoveBorder?: boolean;
    /** Se invoca cada vez que los datos seleccionados se actualizan
     * * Si no hay elementos selecciondos, devuelve undefined o arreglo vacio (si es multiselect)
    */
    OnSelect?: (dato: IMapTData<TData>[TypeCtrl]) => void;
    /** Se invoca solo cuando el usuario interactua con la lista se elementos */
    OnChange?: (valueMember: IMapVM<TData, VM>[TypeCtrl], dato: IMapTData<TData>[TypeCtrl]) => void;
    OnAccept?: (valueMembers: Array<TData[VM]>, datos: Array<TData>) => void; //Added
    /** Paso por cada elemento de la lista, reemplaza la asignación de texto por default en cada elemento */
    OnStepItemListUI?: (container: IMapContainerContent[TypeCtrl], dato: TData, step: TypeStep) => void;
    OnChangeSearchText_Validator?: (textInput: string, dataItem: TData) => boolean;
    OnChangeSearchText_GetDataValueToEval?: (dataItem: TData) => string;
    /** Por defecto el filtro se hace de acuerdo al valor de TData[DisplayMember] y no difiere entre mayúsculas y minúsculas
        * @default false
    */
    ShowAndEnableSearchText?: boolean;
    /** Solo disponible con "multiselect"
     * @default false
    */
    ShowNSelectedInList?: boolean;
    RecyclerView?: boolean;
}

interface IElements {
    Container: d3.Selection<HTMLDivElement, any, HTMLElement, any>;
    //En control anterior:
    //Overlay &&
    //ListContainer
}

type TypeStep = ("enter" | "update" | "exit");

export interface IMapTData<TData> {
    "multiselect": Array<TData>;
    "monoselect": TData;
}

/** Value member map (Array<ValueMember> | ValueMember) */
interface IMapVM<TData, K extends keyof TData> {
    "multiselect": Array<TData[K]>;
    "monoselect": TData[K];
}

interface IMapContainerContent {
    "multiselect": d3.Selection<HTMLDivElement, any, HTMLLIElement, any>;
    "monoselect": d3.Selection<HTMLLIElement, any, HTMLUListElement, any>;
}

export class SelectV2<
    TData = any,
    VM extends keyof TData = any,
    TypeCtrl extends keyof IMapTData<any> & string = "monoselect"
> {
    private elements: IElements;
    private config: ISelectV2Config<TData, VM, TypeCtrl>;

    private multiselect: boolean;
    private dropList: DropdownAdvanced<TData, VM>;

    private onChangeListeners: null | ISelectV2Config<TData, VM, TypeCtrl>["OnChange"][]

    constructor(config: ISelectV2Config<TData, VM, TypeCtrl>) {
        this.elements = <IElements>{};

        if (config) {
            let defaultConfig = <ISelectV2Config<TData, VM, TypeCtrl>>{
                Data: [],
                Placeholder: _L("general.selectaoption"),
                Disabled: false,
                RemoveBorder: false,
                ShowAndEnableSearchText: false,
                ShowNSelectedInList: false,
                AppendInputOnParent: true
            }
            for (const k in config) {
                if (config[k] === undefined) {
                    delete config[k]
                }
            }
            this.config = { ...defaultConfig, ...config };

            this.multiselect = (this.config.Type == "multiselect");
            this.CrearElementos(this.config.Parent);
        }
    }

    private ShowListView() {
        if (!this.config.Disabled) {
            // if (this.dropList.prop_dataitems && this.dropList.prop_dataitems.length > 0) {
            this.dropList._Show();

            this.elements.Container.select<HTMLTooltipComponent>(":scope .tooltip_selected")
                .node()._Visible = false;
            // }
        }
    }

    private RemoveListView() {
        this.dropList._Hide();
    }

    private IconToggleStatus(collapsed: boolean) {
        this.elements.Container.select<HTMLIconCollapseElement>("wc-ic-collapse")
            .attr("collapsed", collapsed);
    }

    private CrearElementos(parent: d3.Selection<HTMLElement, any, HTMLElement, any>) {
        // Crear contenedor
        this.elements.Container = (this.config.AppendInputOnParent ? parent.append("div") : d3.create("div"))
        this.elements.Container.classed("contenedor_combo", true)
            .classed("combo-borde", this.config.RemoveBorder)

        // Contenido del contenedor
        this.elements.Container.html(`
                    <input class="input_text_seleccion"></input>
                    <div class="n_selected"></div>
                    <div class="collapser_indicator"></div>
                    <wc-tooltip class="tooltip_selected" position="bottom"></wc-tooltip>
                `);

        const inputElem = this.GetMainInputSelection()
            .attr("type", "text")
            .attr("readonly", true)
            .attr("placeholder", this.GetPlaceholderText())
            .node()
        inputElem.onkeyup = (e) => {
            if (e.key == " ") {
                this.ShowListView();
                e.stopPropagation();
            }
        };
        inputElem.onblur = (e) => {
            if (this.dropList._Visible) {
                e.stopImmediatePropagation();
            }
        }


        this.elements.Container.select<HTMLDivElement>(":scope > .collapser_indicator")
            .append<HTMLIconCollapseElement>("wc-ic-collapse")
            .style("width", "15px");

        this.elements.Container// .node()
            .on("click", (data, indice, nodes) => {
                this.ShowListView();
            });

        this.ShowInputProgressbar()

        // Crear contenedor de lista
        this.dropList = new DropdownAdvanced<TData, VM>({
            IsMultiSelect: this.multiselect,
            Parent: this.elements.Container,
            ListWidth: this.config.ListWidth,
            ValueMember: this.config.ValueMember,
            DisplayMember: this.config.DisplayMember,
            Data: this.config.Data,
            RecyclerView: this.config.RecyclerView,
            OnUpdateData: () => {
                this.GetMainInputSelection().attr("placeholder", this.GetPlaceholderText());
                this.RemoveInputProgressbar();
                if (this.dropList)
                    this.UpdateInputText();
            },
            OnSelect: (datos) => {
                if (this.config.OnSelect) {
                    if (this.multiselect) {
                        this.config.OnSelect(datos as IMapTData<TData>[TypeCtrl])
                    } else {
                        this.config.OnSelect(datos[0] as IMapTData<TData>[TypeCtrl])
                    }
                }
                this.UpdateInputText(datos);
                this.GetMainInputSelection().attr("placeholder", this.GetPlaceholderText());
                this.elements.Container.attr("selected", this.dropList._dataValueMemberSelected.toString());
            },
            OnChange: (ids, datos) => {
                const calls: (typeof this.config.OnChange)[] = []
                if (this.config?.OnChange)
                    calls.push(this.config.OnChange)
                if (this.onChangeListeners?.length)
                    calls.push(...this.onChangeListeners)
                if (calls.length) {
                    calls.forEach(call => {
                        if (this.multiselect) {
                            call(ids as IMapVM<TData, VM>[TypeCtrl], datos as IMapTData<TData>[TypeCtrl]);
                        } else {
                            call(ids[0] as IMapVM<TData, VM>[TypeCtrl], datos[0] as IMapTData<TData>[TypeCtrl]);
                        }
                    })
                }
                this.UpdateInputText(datos);
                this.GetMainInputSelection().attr("placeholder", this.GetPlaceholderText());
            },
            OnAccept: (ids, datos) => {
                if (this.config.OnAccept) {
                    this.config.OnAccept(ids, datos);
                }
            },
            OnStepItemListUI: this.config.OnStepItemListUI,
            OnChangeSearchText_Validator: this.config.OnChangeSearchText_Validator,
            OnChangeSearchText_GetDataValueToEval: this.config.OnChangeSearchText_GetDataValueToEval,
            OnChangeVisible: (visible) => {
                this.IconToggleStatus(!visible);
                this.UpdateInputText(); // ocultar/mostrar tooltip
                if (!visible) {
                    this.elements.Container// .node()
                        .on("click", (data, indice, nodes) => {
                            this.ShowListView();
                        });
                    if (!(document.activeElement instanceof HTMLInputElement || document.activeElement instanceof HTMLTextAreaElement))
                        this.GetMainInputSelection()?.node().focus();
                } else {
                    this.elements.Container// .node()
                        .on("click", null);
                }
            },
            ShowNSelectedInList: this.config.ShowNSelectedInList,
            ShowAndEnableSearchText: this.config.ShowAndEnableSearchText
        })
    }

    private UpdateInputText(dataValues: TData[] = this.dropList._controlItemsSelected.map(d => d.data)) {
        const nSelected = this.elements.Container.select<HTMLDivElement>(":scope > .n_selected");
        const tooltip = this.elements.Container.select<HTMLTooltipComponent>(":scope .tooltip_selected");
        const input = this.GetMainInputSelection();
        const { length: totalSelected } = dataValues;
        if (totalSelected == 0) {
            input.property("value", "");
            nSelected.text("");
            tooltip.node()._RemoveObservedELements(nSelected.node());
            return;
        }
        // let valuesM = dataValues.map(d => d[this.config.ValueMember])
        const valuesD = dataValues.map(d => d[this.config.DisplayMember]) as string[]
        const fnGetMainText = () => (valuesD[0] != null ? valuesD[0] : "...") + ""
        if (!this.multiselect) {
            input.property("value", fnGetMainText());
            nSelected.text("");
            tooltip.node()._RemoveObservedELements(nSelected.node());
            return;
        }
        const isAllSelected = totalSelected == this.dropList._dataitems.length;
        if (this.dropList._Visible) {
            tooltip.node()._RemoveObservedELements(nSelected.node());
        } else {
            const indexStartTooltipItems = isAllSelected ? 0 : 1;
            const maxTooltipItems = 10;
            const htmlItemsTooltip = valuesD
                .filter((d, i) => i >= indexStartTooltipItems)
                .reduce((res, val, i, arr) => {
                    if (i < maxTooltipItems)
                        return res + `● ${val}` + (i < arr.length ? "\n" : "");
                    else if (i == maxTooltipItems)
                        return res + `● ${arr.length - i} ${_L("general.mas").toLowerCase()}...`;
                    else
                        return res + "";
                }, "");
            tooltip.node()._SetObservedElementsAdvanced({
                Target: nSelected.node(),
                HTMLBasic: htmlItemsTooltip,
            })
        }
        if (isAllSelected && (!this.config.Disabled || totalSelected > 1)) {
            input.property("value", _L("general.todo"));
            nSelected.text("(" + totalSelected + ")");
            return;
        }
        input.property("value", fnGetMainText());
        nSelected.text(totalSelected > 1 ? " (+" + (totalSelected - 1) + ")" : "");
    }

    private ShowInputProgressbar(setData: TDropdownData<TData> = this.config.Data) {
        if (typeof setData != "function" && !(setData instanceof Promise))
            return
        if (!this._controlSelection.select(":scope>wc-progress").node()) {
            this._controlSelection.append<HTMLProgressElement>("wc-progress")
                .attr("position", "bottom")
                .attr("progress-height", "1px")
        }
    }

    // const timeoutRemoveProgressDelay = 250
    // let timeoutRemoveProgress: NodeJS.Timeout
    private RemoveInputProgressbar() {
        // if (timeoutRemoveProgress)
        //     clearTimeout(timeoutRemoveProgress)
        // timeoutRemoveProgress = setTimeout(() => {
        //     timeoutRemoveProgress = null
        this._controlSelection.selectAll<HTMLProgressElement, any>("wc-progress").remove()
        // }, timeoutRemoveProgressDelay)
    }

    // *************************************************************************
    // PRIVATE GETERS
    // *************************************************************************

    private GetMainInputSelection() {
        return this.elements.Container.select<HTMLInputElement>(":scope > .input_text_seleccion");
    }

    //private GetMainInputNode() {
    //    return this.GetMainInputSelection()?.node();
    //}

    private GetPlaceholderText() {
        if (!this.dropList?._dataitems.length) {
            return _L("general.nooptions");
        }
        return this.config.Placeholder;
    }

    // *************************************************************************
    // PUBLIC PROPERTIES
    // *************************************************************************

    public get _visible() {
        return this.dropList._Visible;
    }

    public get _multiselect(): TypeCtrl {
        return this.config.Type;
    }

    public get _dataitems() {
        return this.dropList._dataitems;
    }

    public get _dataSelected() {
        return this.dropList._dataSelected; // this.dataSelected;
    }

    public get _dataValueMemberSelected() {
        return this.dropList._dataValueMemberSelected;
    }

    public get _controlSelection() {
        return this.elements.Container;
    }

    public get _InputTextSelection() {
        return this.elements.Container.select<HTMLInputElement>(".input_text_seleccion");
    }

    public get _listContainer() {
        return this.dropList._listContainer;
    }

    public set _disabled(value: boolean) {
        this.GetMainInputSelection().property("disabled", value)
        this.config.Disabled = value;
    }

    public set _onChange(onchange: (valueMember: IMapVM<TData, VM>[TypeCtrl], dato: IMapTData<TData>[TypeCtrl]) => void) {
        this.config.OnChange = onchange;
    }

    public _AddOnChangeListener(call: typeof this.onChangeListeners[number]) {
        if (!this.onChangeListeners)
            this.onChangeListeners = []
        this.onChangeListeners.push(call)
    }

    public _UpdateList(datos: TDropdownData<TData>) {
        this.ShowInputProgressbar();
        this.dropList._UpdateList(datos);
        return this;
    }

    public _RefreshList() {
        this.dropList._RefreshList();
        return this;
    }

    public _ResetSelect() {
        this.dropList._ResetSelect()
        return this;
    }

    public _valueSelect(valueMember: (IMapVM<TData, VM>["monoselect"] | IMapVM<TData, VM>["multiselect"])) {
        let items = (Array.isArray(valueMember)) ? valueMember : [valueMember];
        this.dropList._valueSelect(items);
        return this;
    }

    public _SelectAll(selected: boolean) {
        if (selected)
            this.dropList._valueSelect(this.dropList._dataitems.map(d => d[this.config.ValueMember]))
        else
            this.dropList._ResetSelect()
    }

    public _disableItems(valuesMember: (TData[VM] | TData[VM][])) {
        this.dropList._disableItems(valuesMember);
        return this;
    }

    public _enableItems(valuesMember: TData[VM][]) {
        this.dropList._enableItems(valuesMember);
        return this;
    }

    public _showOptionsList() {
        this.ShowListView();
        return this;
    }

    public _hideOptionsList() {
        this.RemoveListView();
        return this;
    }

    public _ShowInputBorder(value: boolean) {
        this.elements.Container.classed("combo-borde", value);
        return this;
    }

    // /** @deprecated */
    // public _Configuracion(
    //     elemento_adjunto: d3.Selection<HTMLElement, any, any, any>,
    //     datos: TDropdownData<TData>,
    //     valueMember: VM, displayMember: keyof TData,
    //     model: string,
    //     placeholder?: string,
    //     disabled?: boolean,
    //     onChange?: (data: IMapVM<TData, VM>[TypeCtrl], dataObj: IMapTData<TData>[TypeCtrl]) => void,
    //     onSelect?: (data: IMapTData<TData>[TypeCtrl]) => void,
    //     removeBorder?: boolean,
    //     multiselect: boolean = false,
    //     showAndEnableSearchText: boolean = false,
    //     showNSelectedInList: boolean = false,
    //     onChangeSearchText?: (textInput: string, dataItem: any) => boolean,
    //     onStepItemListUI?: (container: IMapContainerContent[TypeCtrl], dato: TData, step: TypeStep) => void,
    //     onChangeSearchText_GetDataValueToEval?: (dataItem: TData) => string,
    //     onAccept?: ISelectV2Config<TData, VM, TypeCtrl>["OnAccept"],
    //     recyclerView?: boolean,
    //     listWidth?: string,
    // ): d3.Selection<HTMLDivElement, {}, HTMLElement, undefined> {

    //     if (this.config) {
    //         console.warn(model, "is null")
    //         return null;
    //     }
    //     this.multiselect = multiselect;
    //     this.config = {
    //         Type: "monoselect" as any,
    //         Parent: elemento_adjunto,
    //         Data: datos,
    //         ValueMember: valueMember,
    //         DisplayMember: displayMember,
    //         Placeholder: (placeholder ? placeholder : _L("general.selectaoption")),
    //         OnChange: onChange,
    //         OnSelect: onSelect,
    //         RemoveBorder: removeBorder,
    //         Disabled: disabled,
    //         ShowAndEnableSearchText: showAndEnableSearchText,
    //         ShowNSelectedInList: showNSelectedInList,
    //         AppendInputOnParent: true,
    //         OnChangeSearchText_Validator: onChangeSearchText,
    //         OnChangeSearchText_GetDataValueToEval: onChangeSearchText_GetDataValueToEval,
    //         OnStepItemListUI: onStepItemListUI,
    //         OnAccept: onAccept,
    //         RecyclerView: recyclerView,
    //         ListWidth: listWidth,
    //     }
    //     this.CrearElementos(elemento_adjunto);
    //     return this.elements.Container;
    // }
}
