import { ILicenciaConfigOrganizacion } from "./entidad/Licencia";

export namespace Global {
    const PRODUCTION_MODE = import.meta.env.MODE == "production"; //PROD;
    /** Destinado para carácteristicas en fase BETA, independiente de @const PRODUCTION_MODE */
    const ENVIRONMENT_BETA = import.meta.env.MODE == "beta";
    /** UI Version
     * * Por cada aumento, crear el .json correspondiente
    */
    export const _APP_VERSION_BASE = import.meta.env.APP_VERSION_BASE;
    export const _APP_VERSION_NAME = import.meta.env.APP_VERSION_NAME;
    export const _APP_VERSION_NAME_TAG = "v" + _APP_VERSION_NAME;
    /** Formato yymmdd-hhmm */
    export const _APP_VERSION_BUILD = process.env.APP_BUILD_VERSION;

    export let _DEVELOPMENT_UTILS = true;

    export const _DB_NAME = import.meta.env.APP_DB_NAME;

    export const _LICENCIA: ILicenciaConfigOrganizacion = <ILicenciaConfigOrganizacion>{}
    // const BUILD_SECUREPROTOCOL = location.protocol == "https:";

    interface IEnvironmentBuildConfigs {
        // ServicesUrlBase: string;
        IncOpenPayMerchantId: string;
        IncOpenPayPublicKey: string;
        IncOpenPayURLDashboard: string;
        // WebUrlOrigin: string;
    }

    const DEBUG_CONF: IEnvironmentBuildConfigs = {
        IncOpenPayMerchantId: "ma4cg4tjvztupdo9qkk0",
        IncOpenPayPublicKey: "pk_313a393551e545ee9d3b2872cc3b6c67",
        IncOpenPayURLDashboard: "https://sandbox-dashboard.openpay.mx",
        // WebUrlOrigin: "",
    }

    const RELESE_CONF: IEnvironmentBuildConfigs = {
        IncOpenPayMerchantId: "mveoyjzhzkaxn2b23rna",                   // NOTE BETA - SANDBOX
        IncOpenPayPublicKey: "pk_d6127688ea2a4477aa3884decc797255",     // NOTE BETA - SANDBOX
        IncOpenPayURLDashboard: "https://sandbox-dashboard.openpay.mx", // NOTE BETA - SANDBOX
        // IncOpenPayURLDashboard: "https://dashboard.openpay.mx",      // NOTE PRODUCCION
        // WebUrlOrigin: (ENVIRONMENT_BETA ?  : ),
    }

    const CURRENTCONF: IEnvironmentBuildConfigs = PRODUCTION_MODE ? RELESE_CONF : DEBUG_CONF;

    export const _GLOBAL_CONF = {
        DEBUG_MODE: !PRODUCTION_MODE && !ENVIRONMENT_BETA,
        /** Destinado para carácteristicas en fase BETA, independiente de @const PRODUCTION_MODE */
        BETA_MODE: ENVIRONMENT_BETA,
        /** Modo productivo final
         * ```
         * !_GLOBAL_CONF.DEBUG_MODE && !_GLOBAL_CONF.BETA_MODE
         * ```
        */
        RELEASE_MODE: (PRODUCTION_MODE && !ENVIRONMENT_BETA),
        /** url (root) de servicios
         * ```
         * - "https://rst1-dev.kidi.mx/"  // PRUEBAS
         * - "https://rst1.kidi.mx/"      // PRODUCCION
         * - "https://ws-test.kidi.mx/"   // beta en desuso
         * - "http://localhost:8602/"     // local
         * - "http://192.168.13.16/"      // pruebas internas
         * ```
        */
        URL_SrvRoot: import.meta.env.APP_URL_SRVROOT + "/",
        /**
         * ```
         * _GLOBAL_CONF.URL_SrvRoot + "AdminJS/"
         * ```
         * @note OLD SERVICES */
        URL_RSTV1: import.meta.env.APP_URL_SRVROOTV1,
        /** ```
         * _GLOBAL_CONF.URL_SrvRoot + "adminjsv2/"
         * ``` */
        URL_RSTV2: import.meta.env.APP_URL_SRVROOTV2,
        // URL_RSTV2: "http://localhost:8602/",
        /** ```
         * _GLOBAL_CONF.URL_SrvRoot + "license-pay/"
         * ``` */
        URL_RSTLicenciasRoot: import.meta.env.APP_URL_SRVROOT + "/license-pay/",
        /** ```
         * _GLOBAL_CONF.URL_SrvRoot + "license-pay/index.php/"
         * ``` */
        URL_RSTLicenciasOpenPay: import.meta.env.APP_URL_SRVROOT + "/license-pay/index.php/",

        IncOpenPay_SandboxMode: !PRODUCTION_MODE,
        IncOpenPay_MerchantId: CURRENTCONF.IncOpenPayMerchantId,
        IncOpenPay_PublicKey: CURRENTCONF.IncOpenPayPublicKey,
        IncOpenPay_URLDashboard: CURRENTCONF.IncOpenPayURLDashboard,
        IncOpenPay_AmountPay3D: 10000, // 1500
        /**
         * URL Origin, actuales:
         * ```
         * "https://admin2-qa.kidi.mx/"     // PRODUCCIÓN PRUEBAS
         * "https://admin2-uat.kidi.mx/"    // PRODUCCIÓN BETA
         * "https://admin2.kidi.mx/"        // PRODUCCIÓN RELESE
         * ```
         */
        IncApp_WebUrlOrigin: import.meta.env.APP_WEBURLORIGIN + "/",

        GoogleMaps_Key: "AIzaSyAKLHl0lkIZ-jzi1qRYQ_dHRK0FPkCIDPY"
    } as const;

    Object.freeze(_GLOBAL_CONF);
}