import { group as d3Group } from "d3-array";
import { IPagoInfoFacturar } from "../../../data/entidad/Factura";
import { _SvFacturaCFDIObtenerPagosInfoFacturar } from "../../../data/modulo/FacturaCFDI";
import _L, { _HttpMsgV2 } from "../../../util/Labels";
import { ModalThings } from "../../controlD3/ModalThings";
import { NotificacionV2 } from "../../controlD3/NotificacionV2";
import { AlumnoEdoCtaFacturacionUtil as Util } from "./AlumnoEdoCtaFacturacionUtil"

export namespace AlumnoEdoCtaFacturacionViewMovs {
    export interface ViewMovimientosPagos {
        GetMovimientosCFDI: () => Util.IMovimientoPagosAgrupados[]
        IsValid: () => boolean
        SelectItems: (ids: number[]) => void
        SelectAll: () => void
        LoadComplete: Promise<boolean>
        RefreshInfo: () => void
    }
    export function GenerarCFDI_ViewPagosInfo(container: TSelectionHTML<"div">, mt: ModalThings.IModalThings, idEscuela: number, idAlumno: number, idsPagos: number[]): ViewMovimientosPagos {
        type PagoInfoFacturarControl = IPagoInfoFacturar & { _Selected: boolean }
        container
            .text(_L("panelfinanzasedocuenta_factura.movs_seleccionapagos"))
            .style("min-height", "200px")
        let movimientosPagosMap: Map<number, PagoInfoFacturarControl> = new Map()
        const progressNode = mt.Progress.node()
        const pagosInfoListCtrl = Util._CreateListControl<PagoInfoFacturarControl>({ addMarginTop: true })
            ._SetParent(container)
            ._SetUpdateItem((container, d) => {
                const onClick = () => {
                    d._Selected = !d._Selected
                    Util._ViewItemListCheck(container, d._Selected, contentHTML)
                }
                let contentHTML = Util._ViewRowInfoBase(d.NombreCargo, "", false)
                    + Util._ViewRowInfo("panelfinanzasedocuenta_factura.metodo_pago", d.NombreMetodoPago)
                    + Util._ViewRowInfoBase("", d.FechaPagoDia)
                if (!d.EsFacturable) {
                    contentHTML += `<br><span style="color: var(--color_app_red1);">${_L("panelfinanzasedocuenta_factura.no_facturable")}</span>`
                }
                Util._ViewItemListCheck(container, d._Selected, contentHTML)
                container
                    .style("opacity", d.EsFacturable ? null : ".6")
                    .on("click", d.EsFacturable ? onClick : null)
            })

        const sinRegistrosComponent = Util._CreateSinRegistrosComponent(container)
        const fnPagosSeleccionadosList = () => [...movimientosPagosMap.values()].filter(d => d._Selected)
        const fnProcesaDatosRes = (resData: IPagoInfoFacturar[]) => {
            const auxDataMap = new Map(movimientosPagosMap)
            movimientosPagosMap.clear()
            resData
                .sort((a, b) => new Date(b.FechaPago).getTime() - new Date(a.FechaPago).getTime())
                .forEach((d) => {
                    const dd = d as PagoInfoFacturarControl
                    dd._Selected = dd.EsFacturable ? (auxDataMap.get(d.Id)?._Selected || false) : false
                    movimientosPagosMap.set(d.Id, dd)
                })
        }
        const fnConsultar = () => {
            progressNode._Visible = true
            control.LoadComplete = _SvFacturaCFDIObtenerPagosInfoFacturar(idEscuela, idAlumno, idsPagos)
                .then((res) => {
                    fnProcesaDatosRes(res.Datos || [])
                    if (res.Resultado <= 0) {
                        res.Mensaje = _HttpMsgV2(res)
                        NotificacionV2._Mostrar(res.Mensaje, "ADVERTENCIA")
                    } else if (!res.Datos.length) {
                        res.Mensaje = _L("panelfinanzasedocuenta_factura.no_facturables")
                    }
                    if (res.Mensaje) {
                        sinRegistrosComponent._SetText(res.Mensaje)
                    }
                    sinRegistrosComponent._Visible(!!res.Mensaje)
                    pagosInfoListCtrl._SetItems([...movimientosPagosMap.values()])
                    return !res.Mensaje
                })
                .catch(() => false)
                .finally(() => {
                    progressNode._Visible = false
                });
        }
        const control: ViewMovimientosPagos = {
            LoadComplete: null,
            GetMovimientosCFDI: () => {
                const pagosAgrupados = [...d3Group(fnPagosSeleccionadosList(), d => d.GrupoCFDI).values()]
                return pagosAgrupados.map((pagosPorCFDI, i) => {
                    const pagoBase = pagosPorCFDI[0]
                    const monto: number = pagosPorCFDI.reduce((res, { Monto }) => res + Monto, 0);
                    return <Util.IMovimientoPagosAgrupados>{
                        ID: _L("panelfinanzasedocuenta_factura.cfdi") + (i + 1),
                        FechaPago: pagoBase.FechaPago,
                        FechaPagoDia: pagoBase.FechaPagoDia,
                        GrupoCFDI: pagoBase.GrupoCFDI,
                        IdFormaPagoSAT: pagoBase.IdFormaPagoSAT,
                        NombreFormaPagoSAT: pagoBase.NombreFormaPagoSAT,
                        IdMetodoPago: pagoBase.IdMetodoPago,
                        NombreMetodoPago: pagoBase.NombreMetodoPago,
                        Pagos: pagosPorCFDI,
                        IdsPagos: pagosPorCFDI.map(d => d.Id),
                        Monto: monto,
                    }
                })
            },
            SelectItems: (ids: number[]) => {
                movimientosPagosMap.forEach(m => {
                    m._Selected = false
                    if (!m.EsFacturable) return
                    m._Selected = ids.includes(m.Id)
                })
                pagosInfoListCtrl._RefreshList()
            },
            SelectAll: () => {
                movimientosPagosMap.forEach(m => {
                    m._Selected = false
                    if (!m.EsFacturable) return
                    m._Selected = true
                })
                pagosInfoListCtrl._RefreshList()
            },
            RefreshInfo: () => fnConsultar(),
            IsValid: () => {
                const isValid = !!fnPagosSeleccionadosList().length
                if (!isValid)
                    NotificacionV2._Mostrar(_L("panelfinanzasedocuenta_factura.movs_seleccionapagos"), "ADVERTENCIA")
                return isValid
            },
        }
        fnConsultar()
        return control
    }
}