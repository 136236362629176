import { ColorSelector } from "./ColorPicker";

export interface IInputColorSelectConfig {
    Parent: d3.Selection<HTMLElement, any, any, any>;
    Data?: string[],
    OnShowing?: () => void;
    OnSelect?: (datos: string[]) => void;
    OnChange?: (datos: string[]) => void;
    OnPickColor?: (datos: string[]) => void;
    FooterColors?: string[];
    StorageColorKey?: string;
    MaxFooterOptions?: number;

    InputColor?: string;
    InputWidth?: string;
}

export class InputColor {
    private inputContainer: d3.Selection<HTMLDivElement, any, HTMLElement, any>;
    private ctrlColorSelector: ColorSelector;
    private config: IInputColorSelectConfig;

    constructor(config: IInputColorSelectConfig) {
        if (config) {
            let defaultConfig = <IInputColorSelectConfig>{
                InputColor: "#FFFFFF",
                InputWidth: "25px"
            }

            this.config = { ...defaultConfig, ...config };
            this.CrearElementos(this.config.Parent);
        }
    }

    private ShowColorSelectView() {
        this.ctrlColorSelector._Show();
    }

    private RemoveColorSelectView() {
        this.ctrlColorSelector._Hide();
    }

    private CrearElementos(parent: d3.Selection<HTMLElement, any, HTMLElement, any>) {
        this.inputContainer = parent.append("div")
            .classed("color_input", true)
            .style("background-color", this.config.InputColor);

        this.inputContainer.
            on("click", (data, i) => {
                if (!this.ctrlColorSelector._Visible) this.ShowColorSelectView();
            });

        this.ctrlColorSelector = new ColorSelector({
            Parent: this.inputContainer as any,
            Data: this.config?.Data,
            OnChange: (datos) => {
                if (this.config.OnChange) {
                    this.config.OnChange(datos);
                }
                this.UpdateInputColor(datos);
            },
            OnSelect: (datos) => {
                if (this.config.OnSelect) {
                    this.config.OnSelect(datos);
                }
                this.UpdateInputColor(datos);
            },
            OnChangeVisible: (visible) => {
                this.inputContainer.classed("color_input_selected", visible);
            },
            MaxFooterOptions: this.config?.MaxFooterOptions,
            StorageColorKey: this.config?.StorageColorKey
        })
    }

    private UpdateInputColor(data: string[] = this.ctrlColorSelector._controlItemsSelected.map(d => d.data)) {
        this.inputContainer.style("background-color", data[0]);
    }


    // *************************************************************************
    // PUBLIC PROPERTIES
    // *************************************************************************

    public get _visible() {
        return this.ctrlColorSelector._Visible;
    }

    public get _dataitems() {
        return this.ctrlColorSelector._dataItems;
    }

    public get _dataSelected() {
        return this.ctrlColorSelector._dataSelected; // this.dataSelected;
    }

    public get _controlSelection() {
        return this.inputContainer;
    }

    public get _listContainer() {
        return this.ctrlColorSelector._listContainer;
    }

    public set _onChange(onChange: (datos: string[]) => void) {
        this.config.OnChange = onChange;
    }

    public _UpdateList(datos: string[]) {
        this.ctrlColorSelector._UpdateList(datos);
        return this;
    }

    public _UpdateFooterList(datos: string[]) {
        this.ctrlColorSelector._UpdateFooterList(datos);
        return this;
    }

    public _ResetSelect() {
        this.ctrlColorSelector._ResetSelect();
    }

    public _valueSelect(items: string[]) {
        this.ctrlColorSelector._valueSelect(items);
        this.inputContainer.style("background-color", items[0]);
        return this;
    }

    public _ShowOptionsSelector() {
        this.ShowColorSelectView();
        return this;
    }

    public _HideOptionsSelector() {
        this.RemoveColorSelectView();
        return this;
    }
}
