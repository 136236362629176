import { HTMLLoaderElement } from "../controlWC/LoaderComponent";

export function _ShowLoaderMessage(message: string/*, clearAll = false*/): HTMLLoaderElement {
    const body = document.body
    // if (clearAll) {
    //     body.selectAll("*").remove();
    // }
    let loaderElement = body.querySelector(":scope>wc-loader") as HTMLLoaderElement;
    if (!loaderElement) {
        loaderElement = document.createElement("wc-loader") as HTMLLoaderElement;
    }
    body.append(loaderElement)
    loaderElement._Description = message
    return loaderElement
}