import { DataDRequest } from "../DRequest";
import { Entidad } from "../Entidad";
import { Global } from "../Global";
import { DataUtil } from "../util/Util";


export function _GetVersionConfig(version: string): Promise<DataDRequest.IResultadoPeticion<Entidad.IAdminVersionInfo>> {
    const url = Global._GLOBAL_CONF.IncApp_WebUrlOrigin + "GetSettings";
    const params = {
        v: (version || Global._APP_VERSION_BASE)
    };
    return new Promise(resolve => {
        DataDRequest._Request(url, "GET", params, (res: Entidad.IAdminVersionInfo) => {
            resolve({
                Resultado: res ? 1 : -1,
                Data: res
            })
        })
    })
}


export interface IExportarExcelPlantillaInfo {
    Escuela: "C6",
    FechaDescrga: "C7",
    RangoTable: ["B9", "B11"],
    Titulo: "C2",
    Pie: "B13"
}

// interface IExportarExcelParams {
//     IdEscuela: number[],
//     IdCategoria: number
// }
export interface IExportarExcelResponse {
    Info: IExportarExcelPlantillaInfo;
    FileTemplate: File;
    EscuelaLogos: Map<number, File> | null;
}

//----------------------------------------------Archivos
export async function _GetExcelTemplateToExport(idsEscuelas: number[], idCategoria: number, requestType: Entidad.CTipoRequest): Promise<DataDRequest.IResultadoPeticion<IExportarExcelResponse>> {
    const url = Global._GLOBAL_CONF.URL_SrvRoot + "archivo/exportar/plantilla/ObtenerExcel";
    const params = {
        "IdEscuela": idsEscuelas?.toString() || "0",
        "IdCategoria": idCategoria
    }

    return new Promise(function (resolve, reject) {
        DataDRequest._RequestFile(url, "GET", params, (result) => {
            const hasData = Boolean(result?.has("info") && result?.has("plantilla"));
            let logos: Map<number, File> = null;;

            if (hasData) {
                logos = new Map();
                idsEscuelas.forEach(idEscuela => {
                    if (result.has("logo" + idEscuela)) {
                        logos.set(idEscuela, result.get("logo" + idEscuela) as File);
                    }
                })
            }

            resolve({
                Resultado: hasData ? 1 : -100,
                Data: {
                    Info: hasData ? JSON.parse(result.get("info").toString()) : null,
                    FileTemplate: (result?.get("plantilla") as File || null),
                    EscuelaLogos: logos
                },
                TipoRequest: requestType
            });
        });
    });
}

export default {
    _GetVersionConfig,
    _GetExcelTemplateToExport
}
