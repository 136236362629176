import { Entidad } from "../../data/Entidad";
import DataModuloEscuela from "../../data/modulo/Escuela";
import DataModuloPermisos from "../../data/modulo/Permisos";
import { DataUtilPermission } from "../../data/util/Permission";
import { DataUtil } from "../../data/util/Util";
import { UIUtilLang } from "./Language";

/** Permissions Utils */
export namespace UIUtilPermission {
    import CModulo = Entidad.CModulo;
    import CAccionPermiso = Entidad.CAccionPermiso;
    import CTipoEvento = Entidad.CTipoEvento;
    import MODULO_LOGROS = Entidad._MODULOS_LOGROS;
    import MODULO_CALIFICACIONES = Entidad._MODULOS_CALIFICACIONES;

    export function _HasSchoolPermission(idEscuela): boolean {
        if (DataUtil._Usuario.Perfil !== Entidad.CTipoPerfil.Admin) {
            return DataUtilPermission._DiccPermisos.has(idEscuela);
        }
        return true;
    }

    /** Retorna verdadero si la acción está permitida en el modulo, en caso de omitir "idEscuela" busca la acción en todas las escuelas que tengan acceso al modulo
     * @param idAccion
     * @param modulo (idModulo)
     * @param idEscuela en caso de omitir busca la acción en todas las escuelas que tengan acceso al modulo
    */
    export function _HasAccionPermission(idAccion: CAccionPermiso, modulo: CModulo, idEscuela: number = null): boolean {
        if (!modulo || !idAccion) {
            let messageError = !modulo ? `\n -> IDModulo (${modulo}) no valid!` : "";
            messageError += !idAccion ? `\n -> IDAccion (${idAccion}) no valid!` : "";
            console.warn("-d", messageError);
            return false;
        }
        if (DataUtil._Usuario.Perfil == Entidad.CTipoPerfil.Admin) {
            return true;
        }
        let hasPermission = false;
        if (idEscuela != null) {
            if (DataUtil._Usuario.Perfil == Entidad.CTipoPerfil.SuperUsuario) {
                hasPermission = Boolean(DataUtilPermission._DiccPermisos.get(idEscuela));
            }
            else {
                hasPermission = Boolean(DataUtilPermission._DiccPermisos.get(idEscuela)?.get(modulo)?.get(idAccion));
            }
        }
        else if (DataUtil._Usuario.Perfil == Entidad.CTipoPerfil.SuperUsuario) {
            hasPermission = (DataUtilPermission._DiccPermisos.size > 0);
        }
        else {
            for (let escuelaModulos of Array.from(DataUtilPermission._DiccPermisos.values())) {
                if (Boolean(escuelaModulos.get(modulo)?.get(idAccion))) {
                    hasPermission = true;
                    break;
                }
            }
        }
        // let auxSchool = idEscuela ? `E: ${Data.Modulo.Escuela.DiccFullEscuelas.get(idEscuela)?.Nombre?.slice(0, 8)},` : "";
        // if (!hasPermission) {
        //     console.warn("-d", `Permiso 🚫: ${auxSchool} M: ${CModulo[modulo]}, A: ${CAccionPermiso[idAccion]}`);
        // }
        return hasPermission;
    }

    interface IPermissionResult {
        HasPermission: boolean;
        Message?: string;
        SchoolsConflict?: number[];
    }

    /** retorna {HasPermission: true} si exite el permiso para `idAccion` en `modulo` dentro de cada elemento de `idsEscuelas` */
    export function _HasActionsPermissionFromManySchools(idAccion: CAccionPermiso, modulo: CModulo, idsEscuelas: number[]): IPermissionResult {
        let hasPermission = true;
        let message = "";
        let schoolsConflict: number[] = [];

        idsEscuelas = [...new Set(idsEscuelas)];

        if (idsEscuelas?.length) {
            if (DataUtil._Usuario.Perfil !== Entidad.CTipoPerfil.Admin) {
                for (let idEscuela of idsEscuelas) {
                    if (!UIUtilPermission._HasAccionPermission(idAccion, modulo, idEscuela)) {
                        hasPermission = false;
                        schoolsConflict.push(idEscuela);
                        if (!message) {
                            message = UIUtilLang._GetUIString("permission", "accionparaescuelas");
                            message = message.replace("_ACCION", DataModuloPermisos._PermisosAccionesMap.get(idAccion)?.Nombre?.toLowerCase());
                            message = message.replace("_ESCUELA", DataModuloEscuela._DiccFullEscuelas.get(idEscuela)?.Nombre);
                            // message = `No tienes permisos para ${data.modulos.Usuario.permisosAccionesMap.get(idAccion)?.Nombre?.toLowerCase()} en la escuela: ${data.modulos.Kinder.DiccFullEscuelas.get(idEscuela)?.Nombre}`;
                        }
                        // NOTE Cambiar por: No tienes permisos para realizar esta acción con los datos de la escuela: SCHOOL_NAME
                    }
                }
            }
        }
        else {
            console.warn("-d", CModulo[modulo] + ": " + CAccionPermiso[idAccion], "Ids from Schools are empty!");
            // hasPermission = false; // Utils.fn_HasAccionPermission(idAccion, modulo);
        }
        return {
            HasPermission: hasPermission,
            Message: message,
            SchoolsConflict: schoolsConflict
        }
    }

    /** Retorna verdadero si hay permisos para acceso al modulo en la escuela, en caso de omitir "idEscuela" busca la acción en todas las escuelas que tengan acceso al modulo
     * @param modulo (idModulo)
     * @param idEscuela en caso de omitir, busca el modulo en todas las escuelas a las que se tenga acceso
    */
    export function _HasModulePermission(modulo: CModulo, idEscuela: number = null): boolean {
        if (!modulo) {
            console.warn("-d", `IDModulo (${modulo}) no valid!`);
        }
        if (DataUtil._Usuario.Perfil == Entidad.CTipoPerfil.Admin) {
            return true;
        }
        if (idEscuela != null) {
            if (DataUtil._Usuario.Perfil == Entidad.CTipoPerfil.SuperUsuario) {
                return Boolean(DataUtilPermission._DiccPermisos.get(idEscuela));
            }
            else {
                return Boolean(DataUtilPermission._DiccPermisos.get(idEscuela)?.get(modulo));
            }
        }
        else if (DataUtil._Usuario.Perfil == Entidad.CTipoPerfil.SuperUsuario) {
            return (DataUtilPermission._DiccPermisos.size > 0);
        }
        else {
            for (let escuelaModulos of Array.from(DataUtilPermission._DiccPermisos.values())) {
                if (Boolean(escuelaModulos.get(modulo))) {
                    return true;
                }
            }
            return false;
        }
    }

    /** Retorna un arreglo con las escuelas que coinciden en Permisos con respecto a: `modulo` y `idAccion` */
    export function _GetSchoolsByActionModule(modulo: CModulo, idAccion: CAccionPermiso, idEscuela?: number): Array<Entidad.IEscuela> {
        let escuelas: Array<Entidad.IEscuela>;
        if (DataUtil._Usuario.Perfil != Entidad.CTipoPerfil.Admin) {
            if (idEscuela && idAccion == CAccionPermiso.Editar && !UIUtilPermission._HasAccionPermission(idAccion, modulo, idEscuela)) {
                return [DataModuloEscuela._DiccEscuela.get(idEscuela)];
            }
            escuelas = [];
            DataUtilPermission._DiccPermisos
                .forEach((modulosPermisosMap, idEscuela) => {
                    let escuela = DataModuloEscuela._DiccEscuela.get(idEscuela);
                    if (escuela == null) {
                        return;
                    }
                    if (DataUtil._Usuario.Perfil == Entidad.CTipoPerfil.SuperUsuario) {
                        escuelas.push(escuela);
                    }
                    else if (Boolean(modulosPermisosMap.get(modulo)?.get(idAccion))) {
                        escuelas.push(escuela);
                    }
                })
        }
        else {
            escuelas = Array.from(DataModuloEscuela._DiccEscuela.values());
        }
        if (Entidad._MODULOS_FINANZAS.includes(modulo)) {
            return escuelas.filter(d => _HasFinanzasModulesPermissionFromSchools(d.IdKinder));
        }
        else if (Entidad._MODULOS_LOGROS.includes(modulo)) {
            return escuelas.filter(d => _HasLogrosModulesPermissionFromSchools(d.IdKinder));
        }
        if (Entidad._MODULOS_CALIFICACIONES.includes(modulo)) {
            return escuelas.filter(d => _HasCalifModulesPermissionFromSchools(d.IdKinder));
        }
        return escuelas;
    }

    /**
     * Retorna verdadero si hay permisos, evalua el campo "UsaFinanzas",
     * * Los modulos bloqueables (Cargos, Metodos de pago, Pagos recibidos, ...) se obtienen de `Entidad._MODULOS_FINANZAS`
     * @param idEscuela En caso de omitir, evalua todas las escuelas disponibles,
     * devuelve true si alguna escuela UsaFinanzas, pero no especifica qué escuela.
    */
    export function _HasFinanzasModulesPermissionFromSchools(idEscuela?: number): boolean {
        if (idEscuela) {
            return DataModuloEscuela._DiccEscuela.get(idEscuela).UsaFinanzas;
        } else {
            for (let escuela of DataModuloEscuela._DiccEscuela.values()) {
                if (escuela.UsaFinanzas) {
                    return true;
                }
            }
            return false;
        }
    }

    export function _HasModuleFinanzasPermission(idModulo: CModulo) {
        for (const escuela of DataModuloEscuela._DiccEscuela.values()) {
            if (escuela.UsaFinanzas && UIUtilPermission._HasModulePermission(idModulo, escuela.IdKinder)) {
                return true;
            }
        }
        return false;
    }

    /**
     * Retorna verdadero si hay permisos, evalua el campo "ModuloEval",
     * * Los modulos bloqueables (Logros, Logros categorias, ...) se obtienen de `Entidad._MODULOS_LOGROS`
     * @param idEscuela En caso de omitir, evalua todas las escuelas disponibles,
     * devuelve true si alguna escuela UsaLogros, pero no especifica qué escuela.
    */
    export function _HasLogrosModulesPermissionFromSchools(idEscuela?: number): boolean {
        if (idEscuela) {
            const { ModuloEval } = DataModuloEscuela._DiccEscuela.get(idEscuela);
            return (!ModuloEval || ModuloEval == CTipoEvento.Logros);
        }
        for (const escuela of DataModuloEscuela._DiccEscuela.values()) {
            if (!escuela.ModuloEval || escuela.ModuloEval == CTipoEvento.Logros) {
                return true;
            }
        }
        return false;
    }

    export function _HasModuleLogrosPermission(idModulo: CModulo) {
        for (const escuela of DataModuloEscuela._DiccEscuela.values()) {
            if ((!escuela.ModuloEval || escuela.ModuloEval == CTipoEvento.Logros) && UIUtilPermission._HasModulePermission(idModulo, escuela.IdKinder)) {
                return true;
            }
        }
        return false;
    }

    /**
     * Retorna verdadero si hay permisos, evalua el campo "ModuloEval",
     * * Los modulos bloqueables (Materias, Asignaciones Alumno-Materia, Boletas, ...) se obtienen de `Entidad._MODULOS_CALIFICACIONES`
     * @param idEscuela En caso de omitir, evalua todas las escuelas disponibles,
     * devuelve true si alguna escuela UsaLogros, pero no especifica qué escuela.
    */
    export function _HasCalifModulesPermissionFromSchools(idEscuela?: number): boolean {
        if (idEscuela) {
            const escuela = DataModuloEscuela._DiccEscuela.get(idEscuela);
            return (escuela.ModuloEval == CTipoEvento.Evaluacion);
        }
        for (const escuela of DataModuloEscuela._DiccEscuela.values()) {
            if (escuela.ModuloEval == CTipoEvento.Evaluacion) {
                return true;
            }
        }
        return false;
    }

    export function _HasModuleCalificationsPermission(idModulo: CModulo) {
        for (const escuela of DataModuloEscuela._DiccEscuela.values()) {
            if (escuela.ModuloEval == CTipoEvento.Evaluacion && UIUtilPermission._HasModulePermission(idModulo, escuela.IdKinder)) {
                return true;
            }
        }
        return false;
    }

    export function _IsSchoolModuleInUse(idEscuela: number, idModulo: CModulo) {
        if (Entidad._MODULOS_FINANZAS.includes(idModulo)) {
            return _HasFinanzasModulesPermissionFromSchools(idEscuela);
        }
        else if (Entidad._MODULOS_LOGROS.includes(idModulo)) {
            return _HasLogrosModulesPermissionFromSchools(idEscuela);
        }
        if (Entidad._MODULOS_CALIFICACIONES.includes(idModulo)) {
            return _HasCalifModulesPermissionFromSchools(idEscuela);
        }
        return true
    }

    export function _IsConfigurableModuleAvailable(idModulo: CModulo) {
        if (Entidad._MODULOS_FINANZAS.includes(idModulo)) {
            if (!UIUtilPermission._HasModuleFinanzasPermission(idModulo)) {
                return false
            }
        }
        else if (Entidad._MODULOS_LOGROS.includes(idModulo)) {
            if (!UIUtilPermission._HasModuleLogrosPermission(idModulo)) {
                return false
            }
        }
        else if (Entidad._MODULOS_CALIFICACIONES.includes(idModulo)) {
            if (!UIUtilPermission._HasModuleCalificationsPermission(idModulo)) {
                return false
            }
        }
        return true;
    }

    export function _ResolveModuloEvaluacion(idModulo: CModulo, idEscuela: number): boolean {
        // if (!MODULO_LOGROS.includes(idModulo) && !MODULO_CALIFICACIONES.includes(idModulo)) return true;
        // const escuela = idEscuela.map(d => Data.Modulo.Escuela.DiccEscuela.get(d));
        const escuela = DataModuloEscuela._DiccEscuela.get(idEscuela);
        if (!escuela) return false;
        if (!escuela.ModuloEval || escuela.ModuloEval == CTipoEvento.Logros)
            return MODULO_LOGROS.includes(idModulo);
        else
            return MODULO_CALIFICACIONES.includes(idModulo);
    }
}
