import { group as d3Group } from "d3-array";
import { MainPage } from "../../MainPage";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import DataModuloProveedor from "../../data/modulo/Proveedor";
import { IConfigGridExcelExport, IGridExtraTableConfig, IGridRenderInfo, VentanaGrid } from "../controlD3/AVentanaGrid";
import { ExcelThings } from "../controlD3/ExcelExport";
import { ModalThings } from "../controlD3/ModalThings";
import { Table } from "../controlD3/Tabla";
import { UIUtilLang } from "../util/Language";
import { UIUtilViewFinanzasProveedores } from "../utilView/FinanzasProveedor";

type IProveedor = Entidad.IFinanzaProveedor & {
    KinderFiltro: number[];
}

export class UIVentanaFinanzasProveedores extends VentanaGrid<IProveedor> {
    constructor(content: TSelectionHTML<"div">, modulo: Entidad.CModulo) {
        super(content, modulo);
    }
    protected GRID_GetDataRequestID(): DataModuloMain.TipoRequestMonitorId {
        return Entidad.CTipoRequest.Proveedor;
    }
    protected GRID_GetMenuTopGrid(): Table.ITableMenuTopDefaultOptionConfig[] {
        let res: Table.ITableMenuTopDefaultOptionConfig[] = [];
        if (this.GridHasPermisoAccion(Entidad.CAccionPermiso.Agregar)) {
            res.push({
                Label: "Agregar",
                Callback: () => this.OpenModal_Agregar()
            })
        }
        return res;
    }
    protected GRID_GetSelectionDataMenuV2(menuLocation: "row" | "top-selected", dataGridSelected: IProveedor[]): Table.ITableMenuDataSelectedOptionConfig<IProveedor>[] {
        let opciones: Array<Table.ITableMenuDataSelectedOptionConfig<IProveedor>> = [];
        if (this.GridHasPermisoAccion(Entidad.CAccionPermiso.Editar)) {
            opciones.push({
                Label: "Editar",
                Callback: (datos) => this.OpenModal_Editar(datos[0]),
                MultiData: false
            })
        }
        if (this.GridHasPermisoAccion(Entidad.CAccionPermiso.Eliminar)) {
            opciones.push({
                Label: "Eliminar",
                Callback: (dato) => this.OpenModal_Eliminar(dato),
            })
        }
        return opciones;
    }
    protected GRID_GetFilters(): Table.IParametroFiltro<IProveedor>[] {
        return [
            { Field: "RazonSocial", Label: "RazonSocial" },
            { Field: "RFC", Label: "RFC" },
            { Field: "CP", Label: "CP" },
            { Field: "Telefono", Label: "Telefono" },
            { Field: "Correo", Label: "Correo" },
            { Field: "Notas", Label: "Notas" },
        ]
    }
    protected GRID_GetTableConfigBase(): IGridRenderInfo<IProveedor> {
        return {
            IdTabla: "FinanzasCatalogos-Proveedores",
            MinWidth: 1000,
            IdData: "Id",
            DefaultSort: "RazonSocial",
            Columns: [
                { Label: "RazonSocial", Field: "RazonSocial", Width: "15%", MinWidth: "100px" },
                { Label: "RFC", Field: "RFC", Width: "10%", MinWidth: "100px" },
                { Label: "CP", Field: "CP", Width: "5.5%", MinWidth: "100px" },
                { Label: "Telefono", Field: "Telefono", Width: "8%", MinWidth: "100px" },
                { Label: "Correo", Field: "Correo", Width: "13%", MinWidth: "100px" },
                { Label: "Notas", Field: "Notas", Width: "13%", MinWidth: "100px" },
                { Label: "Escuela", Field: "getNombreEscuela", LabelLangKey: "d_field_nombreescuela", Width: "12%", MinWidth: "100px" },
            ]
        }
    }
    protected GRID_GetTableConfigAdvanced(): IGridExtraTableConfig<IProveedor> {
        return null;
    }

    protected GRID_GetExportarConfig(dataGrid: IProveedor[]): IConfigGridExcelExport<any> {
        return {
            IdsEscuelas: [...new Set(dataGrid.map(d => d.IdEscuela))],
            ColumnsConfig: this.ctrlTabla
                ._InfoColumns
                .map<ExcelThings.IColumnToExcelExportFileConfig<IProveedor>>((d) => ({
                    Field: d.Field as keyof IProveedor,
                    HeaderTag: d.Label,
                    WidthCell: 25
                })),
            OnGetDataBySheets: async () => {
                return Array.from(d3Group(dataGrid, d => d.IdEscuela))
                    .map<ExcelThings.ISheetConfig<IProveedor>>(entrie => ({
                        IdSheet: entrie[0], // IdEscuela
                        SheetName: entrie[1][0].getNombreEscuela,
                        Data: entrie[1]
                    }))
            },
            OnGetEscuelasTagInSheet: (dataSheet: IProveedor[]) => dataSheet[0].getNombreEscuela,
        }
    }

    // FORMS

    private OpenModal_Agregar() {
        const ACCION_AGREGAR = Entidad.CAccionPermiso.Agregar;
        this.GridOpenModal_ActionFormToAGridData({
            Title: "Agregar",
            Action: ACCION_AGREGAR,
            GetForm: () => UIUtilViewFinanzasProveedores._GetForm(ACCION_AGREGAR, null, true),
            Width: 400,
            OnGetIdEscuelasToReload: (form, res) => form._Data.IdEscuela,
            OnAccept: async (form, mt) => {
                const data = form._Data;
                const res = await DataModuloProveedor._NuevosRegistrosMultiple(data, data.IdEscuela)
                if (data.IdEscuela.length == 1 || res.Fallidos.length == 0)
                    return res;
                if (res.Resultado > 0) {
                    await MainPage._ReloadServiceAndAwaitBool(res.TipoRequest, res.Exitosos);
                    this.GridUpdateData();
                }
                ModalThings._GetModalInfoDataList<typeof res.Fallidos[number]>({
                    Title: UIUtilLang._GetUIString("general", "resumen"),
                    InfoText: UIUtilLang._GetUIString("general", "notif_failarrayproccess"),
                    Width: 300,
                    DataList: res.Fallidos,
                    OnStepItemData: (container, item) => {
                        container.html(`<b>${DataModuloMain._GetDataValueFieldByName("Escuela", item.Escuela, "Nombre")}</b><br>`
                            + UIUtilLang._GetHTTPMessage({
                                TipoRequest: res.TipoRequest,
                                Resultado: item.Resultado,
                            }, "agregar"))
                    },
                    OnClose: () => mt.Modal._Ocultar(),
                })
                return null;
            },
            AutoReloadGridRequestOnFinally: true,
        })
    }

    private OpenModal_Editar(dato: IProveedor) {
        this.GridOpenModal_ActionFormToAGridData({
            Title: "Editar",
            Action: Entidad.CAccionPermiso.Editar,
            IdsEscuelas: [dato.IdEscuela],
            GetForm: () => {
                const form = UIUtilViewFinanzasProveedores._GetForm(Entidad.CAccionPermiso.Editar, dato.IdEscuela, false);
                form._AsignaData(dato);
                return form;
            },
            Width: 400,
            OnAccept: (form) => DataModuloProveedor._ActualizarRegistro(form._Data),
            AutoReloadGridRequestOnFinally: true,
        })
    }

    private OpenModal_Eliminar(datos: IProveedor[]) {
        this.GridOpenModal_ProccessArrayData({
            DataToProccess: datos,
            Width: 300,
            OnGetIdEscuela: (dato) => dato.IdEscuela,
            OnError_GetItemDataTag: (dato) => (
                `${this.VB_GetUIStringModule("d_field_razonsocial")}: ${dato.RazonSocial}\n`
                + `${this.VB_GetUIStringModule("d_field_rfc")}: ${dato.RFC}`
            ),
            OnStepAProccess: (dato) => DataModuloProveedor._EliminarRegistro(dato.Id),
        })
    }
}
