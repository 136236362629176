import * as d3 from "d3"
import { UIUtilGeneral } from "../util/Util";

export interface IFloatingOptionsConfig {
    Parent: HTMLElement | TSelectionHTML<any>;
    ElementToHover?: HTMLElement | TSelectionHTML<any>;
    Items: TItemsFloatingOptionsCfg;
    OnHide?: () => void;
}

export type TItemsFloatingOptionsCfg = IItemCtrlFloatingOptions[] | (() => IItemCtrlFloatingOptions[]) /* | (() => Promise<IItemCtrlFloatingOptions[]>) */;

interface IItemCtrlFloatingOptions {
    Enable: boolean;
    Src?: string;
    Description: string;
    OnSelect?: () => void;
}

type TStep = UIUtilGeneral.TTypeStepJoin;

export class FloatingOptionsBtns {
    private optionsContainer: d3.Selection<HTMLDivElement, any, HTMLElement, null>;
    private config: IFloatingOptionsConfig
    private reziseObserver: ResizeObserver;

    constructor(config: IFloatingOptionsConfig) {
        this.reziseObserver = UIUtilGeneral._GetResizeObserver(entries => {
            setTimeout(() => this.FixPosition(), 200)
        })
        if (config) {
            let defaultConfig = <IFloatingOptionsConfig>{
                ElementToHover: config.Parent
            }
            this.config = { ...defaultConfig, ...config };
            this.CrearElementos();
        }
    }

    private CrearElementos() {
        this.optionsContainer = d3.create("div")
            .attr("class", "control_optionlist");

        this.optionsContainer.append("div")
            .attr("class", "options_container");

        this.optionsContainer.node().onmouseover = e => {
            e.stopPropagation();
        }
    }

    private FixPosition() {
        const marginTop = 5;
        let parent: DOMRect = (this.config.Parent instanceof HTMLElement ? this.config.Parent : this.config.Parent.node() as HTMLElement).getBoundingClientRect();
        let list: DOMRect = this.optionsContainer.node().getBoundingClientRect();

        let top = parent.y - list.height - marginTop;
        let xPosition = parent.x + (parent.width / 2) - (list.width / 2)
        this.optionsContainer.style("top", top + "px")
        this.optionsContainer.style("left", xPosition + "px")
    }

    private Show() {
        const { Parent } = this.config;
        (Parent instanceof HTMLElement ? d3.select(Parent) as TSelectionHTML : Parent as TSelectionHTML).append(() => this.optionsContainer.node());
        this.UpdateItemsOptionsView();
        this.FixPosition();
        this.reziseObserver?.observe(document.body);

        if (this["__clickbody"]) {
            document.removeEventListener("click", this["__clickbody"]);
        }
        this["__clickbody"] = () => {
            this.Hide();
        }
        setTimeout(() => {
            document.addEventListener("click", this["__clickbody"]);
        })
    }

    private Hide() {
        if (this.isVisible()) {
            if (this["__clickbody"]) {
                document.removeEventListener("click", this["__clickbody"]);
            }
            this.reziseObserver.unobserve(document.body);
            this.optionsContainer.remove();
            if (this.config.OnHide) {
                this.config.OnHide();
            }
        }
    }

    private UpdateItemsOptionsView() {
        const items = this.GetOptionItems();
        this.optionsContainer.select(".options_container")
            .selectAll<HTMLDivElement, IItemCtrlFloatingOptions>(":scope > .item")
            .data(items)
            .join(
                enter => {
                    let item = enter.append("div").attr("class", "item");
                    item.append("img");
                    item.append("span")
                    return this.UpdateItemOptionView(item, "enter")
                },
                update => this.UpdateItemOptionView(update, "update"),
                exit => this.UpdateItemOptionView(exit, 'exit')
                    .remove()
            )
    }

    private UpdateItemOptionView(item: TSelectionHTML<"div", IItemCtrlFloatingOptions>, step: TStep) {
        return item
            .each((d, i, elements) => {
                d3.select(elements[i]).select("img").classed("hide", !Boolean(d.Src))
                if (d.Src) {
                    d3.select(elements[i]).select("img")
                        .attr("src", d.Src)
                    d3.select(elements[i]).select("span")
                        .text(d.Description);
                } else {
                    d3.select(elements[i]).select("span")
                        .text(d.Description);
                }

                if (step == "exit") return
                if (d.Enable) {
                    elements[i].onclick = e => {
                        d.OnSelect();
                        this.Hide();
                        e.stopPropagation();
                    }
                }
            })
    }

    private GetOptionItems() {
        return ((typeof this.config.Items == "function" ? this.config.Items() : this.config.Items) || [])
    }

    private isVisible(): boolean {
        return this.optionsContainer.node().isConnected;
    }

    public _Show() {
        this.Show();
        return this;
    }

    public _IsVisible() {
        return this.isVisible()
    }
}