import * as d3 from "d3";
import { ascending, group as d3Group, descending } from "d3-array";
import { MainPage } from "../../MainPage";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import { _SvAlumnoCargaMasiva, _SvAlumnoGetBoletaCalificacionesV2, _SvAlumnoNuevo } from "../../data/modulo/Alumno";
import { _SvArchivoDescargarPlantillaExcel } from "../../data/modulo/Archivo";
import { IMapSendCincularItems, _SvNoticiaNueva } from "../../data/modulo/Noticia";
import { DateV2 } from "../../util/DateV2";
import _L from "../../util/Labels";
import { IConfigGridExcelExport, IGridExtraTableConfig, IGridRenderInfo, VentanaGrid } from "../controlD3/AVentanaGrid";
import { ElementWrapper } from "../controlD3/ElementWrapper";
import { ExcelThings } from "../controlD3/ExcelExport";
import { Fields, FormGenerator } from "../controlD3/Formulario";
import { ExcelThingsV2 } from "../controlD3/InputExcelV2";
import { ModalThings } from "../controlD3/ModalThings";
import { NotificacionV2 } from "../controlD3/NotificacionV2";
import { SelectV2 } from "../controlD3/SelectV2";
import { Table } from "../controlD3/Tabla";
import { UIUtilIconResources } from "../util/IconResourses";
import { UIUtilLang } from "../util/Language";
import { UIUtilPermission } from "../util/Permission";
import { UIUtilTime } from "../util/Time";
import { UIUtilGeneral } from "../util/Util";
import { UIUtilViewData } from "../util/ViewData";
import { UIUtilViewAlumno } from "../utilView/Alumno";
import { UIUtilViewCalificacion } from "../utilView/Calificacion";
import { UIUtilViewCircular } from "../utilView/Circular";
import { UIUtilViewGrupos } from "../utilView/Grupos";
import { UIWindowManager } from "./WindowManager";

import IAlumno = Entidad.IAlumno;
import CAccionPermiso = Entidad.CAccionPermiso;
import CModulo = Entidad.CModulo;
import CNinioMovimiento = Entidad.CNinioMovimiento;
import ICicloEscolar = Entidad.ICicloEscolar;
import IElementoCalificado = UIUtilViewCalificacion.IElementoCalificado;
// import IAuxInfoEvaluaciones = UIUtilViewCalificacion.IAuxInfoEvaluaciones;
// import ICalificacion = UIUtilViewCalificacion.ICalificacion;
import CTipoEvaluacion = Entidad.CTipoEvaluacion;

type IAlumnoBoleta = UIUtilViewCalificacion.IAlumnoBoleta;

interface IPeriodoD extends UIUtilViewData.IPeriodoD {
    EnCicloEscolar: boolean;
}
export class UIVentanaAlumnos extends VentanaGrid<IAlumno> {
    constructor(container: d3.Selection<HTMLDivElement, undefined, HTMLElement, any>, modulo: CModulo) {
        super(container, modulo, {
            ModuloObservableToTblRefresh: [Entidad.CTipoRequest.HorarioAlumno, Entidad.CTipoRequest.Licencia],
            MaxOptionsInRow: 3,
            MaxOptionsInTop: 3,
        });
    }

    protected GRID_GetTableConfigBase(): IGridRenderInfo<IAlumno> {
        return {
            IdTabla: "Alumnos",
            Title: "",
            DefaultSort: "NombreCompleto",
            IdData: "IdChild",
            MaxOptionsInRow: 3,
            MinWidth: 1400,
            MenuInRowNoCellsToIgnoreWidth: 2,
            Columns: [
                { Field: "getThumbnailURL", LabelLangKey: null, Label: "", Width: "4%", MinWidth: "30px", IsSortable: false, Align: "center", Icon: UIUtilIconResources.CGeneral.User },
                { Field: "NombreCompleto", Label: "Nombre", Width: "20%", MinWidth: "140px", ClassStyle: "link_item", OnClickInCellCont: (dato) => this.PanelModeVerDetalle(dato.IdChild), },
                { Field: "Matricula", Label: "Matrícula", Width: "10%", MinWidth: "110px" },
                { Field: "FechaNacimiento", Label: "Nacimiento", Width: "10%", MinWidth: "110px" },
                { Field: "StrSexo", Label: "Sexo", Width: "10%", MinWidth: "110px" },
                { Field: "StrEstado", Label: "Estado", Width: "12%", MinWidth: "120px" },
                { Field: "NombreKinder", Label: "Escuela", Width: "15%", MinWidth: "150px" },
                { Field: "StrEscolaridad", Label: "Escolaridad", Width: "14%", MinWidth: "140px" },
                { Field: "StrGrado", Label: "Grado", Width: "10%", MinWidth: "110px" },
                { Field: "StrGrupoPrincipal", Label: "Grupo principal", Width: "12%", MinWidth: "120px" }
            ]
        };
    }

    protected GRID_GetTableConfigAdvanced(): IGridExtraTableConfig<IAlumno> {
        return {
            EvaluatorAndSubLevelsBuild: {
                OnStepCellTable: (container, datum, field: keyof IAlumno) => {
                    if (field == "getThumbnailURL") {
                        UIUtilViewAlumno._UIUpdateFotoAlumno(container, datum);
                    }
                    if (field == "StrGrupoPrincipal") {
                        UIUtilViewGrupos._ApplyLblsGruposToContainer(container, datum.IdChild);
                    }
                    if (field == "FechaNacimiento") {
                        container.text(datum.getFechaNacimientoFmt)
                    }
                }
            }
        }
    }

    protected GRID_GetMenuTopGrid(): Array<Table.ITableMenuTopDefaultOptionConfig> {
        let objOpcion: Array<Table.ITableMenuTopDefaultOptionConfig> = [];

        if (this.GridHasPermisoAccion(CAccionPermiso.Agregar)) {
            objOpcion.push(
                {
                    Label: "Agregar", Callback: () => {
                        this.OpenModal_FormularioAgregar();
                    }
                },
                {
                    Label: "action_cargamasiva",
                    Callback: () => {
                        this.OpenModal_CargaMasiva();
                    }
                },
            );
        }
        return objOpcion;
    }

    protected GRID_GetSelectionDataMenuV2(menuLocation: "row" | "top-selected", dataGridSelected: IAlumno[]): Table.ITableMenuDataSelectedOptionConfig<IAlumno>[] {
        let opciones: Array<Table.ITableMenuDataSelectedOptionConfig<IAlumno>> = [];

        if (this.GridHasPermisoAccion(CAccionPermiso.Editar)) {
            opciones.push({
                Label: "Editar", MultiData: false, Callback: (datos) => {
                    this.OpenModal_FormularioEditar(datos[0]);
                }
            });
        }

        if (UIUtilPermission._HasAccionPermission(CAccionPermiso.Editar, CModulo.PanelNinioHorario)) {
            opciones.push({
                Label: "action_inscribir",
                Callback: (datos: Array<IAlumno>) => { /* this.ValidaSelect(datos) */
                    this.OpenPanelModeInscribir(datos);
                },
                GetDetails: (datos: Array<IAlumno>) => {
                    let enabled = this.AllStudentsValidToAsignSchedules(datos);
                    return {
                        Enabled: enabled.enable,
                        Description: enabled.message
                    }
                }
            })
        }

        if (UIUtilPermission._HasModulePermission(CModulo.PanelFinanzasCargosDescuentos)) {
            opciones.push({
                Label: "action_cargosdescs",
                Callback: (datos: Array<IAlumno>) => {
                    this.OpenPanelModeCargosYDescuentos(datos);
                },
                GetDetails: (datos: IAlumno[]) => {
                    let enable: boolean = true;
                    let message: string = '';
                    if (!datos.every(d => datos[0].IdKinder == d.IdKinder)) {
                        enable = false;
                        message = this.VB_GetUIStringModule("tlt_cargosdesc_fail_nosameschool")
                    }
                    else if (!UIUtilPermission._HasFinanzasModulesPermissionFromSchools(datos[0].IdKinder)) {
                        enable = false;
                        message = this.VB_GetUIStringModule("tlt_cargosdesc_fail_nofinanzas").replace("_NAME", datos[0].NombreKinder)
                    }
                    return {
                        Enabled: enable,
                        Description: message
                    }
                    //return (Util.Permission.fn_HasFinanzasModulesPermissionFromSchools(datos[0].IdKinder) && datos.every(d => datos[0].IdKinder == d.IdKinder));
                }
            });
        }

        let escuelaModuloEval = DataModuloMain._GetItemDataByName("Escuela", dataGridSelected[0].IdKinder).ModuloEval;
        let label = (escuelaModuloEval == Entidad.CTipoEvento.Evaluacion) ? "action_asignarmateria" : "action_asignlogro";

        if ((escuelaModuloEval == Entidad.CTipoEvento.Logros && UIUtilPermission._HasModulePermission(CModulo.PanelNinioLogros)) || (escuelaModuloEval == Entidad.CTipoEvento.Evaluacion && UIUtilPermission._HasModulePermission(CModulo.PanelAlumnoMaterias))) {
            opciones.push({
                Label: label,
                Callback: (datos: Array<IAlumno>) => {
                    (escuelaModuloEval == Entidad.CTipoEvento.Logros) ? this.OpenPanelModeAsignarLogros(datos) : this.OpenPanelModeAsignarMaterias(datos);
                },
                GetDetails: (datos: Array<IAlumno>) => {
                    if (datos.length === 1) {
                        return {
                            Enabled: true
                        }
                    }

                    let enabled = (escuelaModuloEval == Entidad.CTipoEvento.Logros) ? this.AllStudentsToAsignLogros(datos) : this.AllStudentsToAsignMaterias(datos);
                    return {
                        Enabled: enabled.enable,
                        Description: enabled.message
                    }
                }
            })
        }

        if (UIUtilPermission._HasModulePermission(CModulo.Circulares)) {
            opciones.push({
                Label: "action_circular",
                Callback: (data: IAlumno[]) => {
                    this.OpenModal_Circulares(data);
                },
                GetDetails: (datos: IAlumno[]) => {
                    let enable: boolean = true;
                    let message: string = '';
                    let noInactivos = datos.filter(d => d.IdChildMovimiento != Entidad.CNinioMovimiento.Activo).length;
                    if (!datos.every(d => d.IdChildMovimiento == Entidad.CNinioMovimiento.Activo)) {
                        enable = false;
                        message = this.VB_GetUIStringModule(datos.length == 1 ? "tlt_noactivestudent" : "tlt_noactivestudents").replace("_NALUMNOS", noInactivos + "")
                    }
                    return {
                        Description: message,
                        Enabled: enable
                    }
                }
            })
        }

        if (escuelaModuloEval == Entidad.CTipoEvento.Evaluacion && UIUtilPermission._HasModulePermission(CModulo.PanelAlumnoMaterias)) {
            opciones.push({
                Label: "action_generaboleta",
                Callback: (alumnos: Array<IAlumno>) => {
                    this.OpenGenerarBoletas(alumnos);
                },
                MultiData: true,
                GetDetails: (datos: IAlumno[]) => {
                    let enable: boolean = true;
                    let message: string = '';
                    if (!datos.every(d => datos[0].IdKinder == d.IdKinder)) {
                        enable = false;
                        message = this.VB_GetUIStringModule("tlt_cargosdesc_fail_nosameschool")
                    }
                    return {
                        Enabled: enable,
                        Description: message
                    }
                }
            })
        }

        if (this.GridHasPermisoAccion(CAccionPermiso.Eliminar)) {
            opciones.push({
                Label: "action_bajadefinit", Callback: (datos: Array<IAlumno>) => {
                    this.OpenModal_EliminarDatos(datos);
                }
            });
        }

        return opciones;
    }

    protected GRID_GetDataRequestID(): DataModuloMain.TipoRequestMonitorId {
        return Entidad.CTipoRequest.Alumno;
    }

    protected GRID_GetFilters(): Array<Table.IParametroFiltro<IAlumno>> {
        return [
            { Label: "Nombre", Field: "Nombre" },
            { LabelLangKey: "d_field_appaterno", Field: "ApPaterno" },
            { LabelLangKey: "d_field_apmaterno", Field: "ApMaterno" },
            { Label: "Matrícula", Field: "Matricula" },
            { Label: "Fecha de nacimiento", Field: "FechaNacimiento", Type: "date", MaxDate: new Date().toISOString() },
            {
                LabelLangKey: "d_field_strsexo",
                Field: "Sexo",
                Type: "select",
                Options: UIUtilViewData._GetList_Sexo()
            },
            {
                LabelLangKey: "d_field_strestado",
                Field: "IdChildMovimiento",
                Type: "select",
                Options: UIUtilViewData._GetList_EstadosMovimientos()
                    .filter(d => ((d.Id == CNinioMovimiento.Activo) || (d.Id == CNinioMovimiento.Preinscripcion) || (d.Id == CNinioMovimiento.BajaTemporal)))
            },
            { Label: "Escolaridad", Field: "StrEscolaridad" },
            { Label: "Grado", Field: "StrGrado" },
            {
                Label: "Grupo principal", Field: "StrGrupoPrincipal", Type: "text",
                OnGetValueToMatch: (dato: IAlumno) => dato.StrGrupoPrincipalNames
            },
        ];
    }





    // **********************************************************************************************
    // FORMULARIOS MODAL
    // **********************************************************************************************

    private OpenModal_FormularioAgregar() {
        this.GridOpenModal_ActionFormToAGridData({
            Action: CAccionPermiso.Agregar,
            GetForm: () => UIUtilViewAlumno._GetAlumnoForm(CAccionPermiso.Agregar, this.modulo as any),
            OnAccept: async (form, modalThings) => {
                let dataForm: UIUtilViewAlumno.IAlumnoForm = form._Data;
                dataForm.FechaNacimiento = UIUtilViewAlumno._GetDtNacimientoFixedTZ(dataForm);
                return _SvAlumnoNuevo((dataForm as IAlumno), dataForm.Foto);
            }
        })
    }

    // FIXME
    private OpenModal_FormularioEditar(datoOrigin: IAlumno) {
        this.GridOpenModal_ActionFormToAGridData({
            Action: CAccionPermiso.Editar,
            IdsEscuelas: [datoOrigin.IdKinder],
            AutoReloadGridRequestOnFinally: false, // Se aplica en utilView.Alumno.s_ProcesoFinal_ActualizarAlumno
            GetForm: () => UIUtilViewAlumno._GetAlumnoForm(CAccionPermiso.Editar, this.modulo as any, datoOrigin),
            OnAccept: async (form, mt) => {
                let dataForm = form._Data;
                if (dataForm.IdChildMovimiento == CNinioMovimiento.Activo) {
                    if (!UIUtilViewAlumno._NotiFalloEditEstadoYGrado(form._DataOrigin.IdChildMovimiento, form._DataOrigin.IdGrado, dataForm.IdGrado)) {
                        return null;
                    }
                    let movementChildValidity = UIUtilViewAlumno._ValidarTutorYHorario(datoOrigin);
                    if (!movementChildValidity.IsValid) {
                        let resStepsGuide = await UIUtilViewAlumno._TutorYHorarioStepGuideForm(movementChildValidity.SpecificErr, dataForm.IdChild, datoOrigin.IdKinder);
                        if (resStepsGuide) mt.Modal._Ocultar();
                        return null;
                    }
                }
                dataForm.FechaNacimiento = UIUtilViewAlumno._GetDtNacimientoFixedTZ(dataForm);
                let res = await UIUtilViewAlumno._ProcesoFinal_ActualizarAlumno(datoOrigin, dataForm);

                if (dataForm.ImageIsChange && res?.Resultado > 0) {
                    let res = await UIUtilViewAlumno._SvActualizarFoto(dataForm, dataForm.Foto);
                    if (res.Resultado > 0) {
                        MainPage._ReloadService(this.GRID_GetDataRequestID(), dataForm.IdKinder);
                    }
                }

                return res;
            }
        })
    }

    private OpenModal_EliminarDatos(datos: Array<IAlumno>) {
        this.GridOpenModal_ProccessArrayData({
            Title: "action_bajadefinit", //"Baja definitiva",
            Message: _L("alumnos.confirma_bajadef_msg2", datos.length.toString()), // `¿Estás segur@ que deseas dar de baja de manera definitiva a los alumnos seleccionados (${datos.length})?`,
            DataToProccess: datos,
            AccionToHttpMessage: "editar",
            OnError_GetItemDataTag: (dato) => dato.NombreCompleto,
            OnGetIdEscuela: (dato) => dato.IdKinder,
            OnStepAProccess: (dato) => {
                return UIUtilViewAlumno._SvCambiarEstadoEscolar(dato, CNinioMovimiento.BajaDefinitiva);
            },
            OnEndAndCloseProccess: (correctos, idsEscuelas) => {
                if (correctos.length) {
                    MainPage._ReloadService(Entidad.CTipoRequest.HorarioAlumno, idsEscuelas);
                }
            }
        })
    }

    private OpenModal_CargaMasiva() {
        type TGeneralData = {
            IdEscuela: number;
            IdEscolaridad: number;
            IdGrado: number;
        }
        let formRegistrar: FormGenerator<TGeneralData>;
        ExcelThingsV2._UploadExcelData<Entidad.IDataNinioCargaMasiva>({
            ValidateUniqueItems: true,
            TableId: "Alumnos-CargaMasiva",
            TableMinWidth: 1800,
            Columns: [
                {
                    Field: "Matricula", Label: this.VB_GetUIStringModule("d_field_matricula"), Required: false, UIMinWidthTH: "80px", UniqueValue: true,
                    OnGetErrorType: (value: string) => {
                        const matriculaExist = DataModuloMain._GetReqDataArrayByName("Alumno")
                            .find(d => (d.Matricula === (value?.trim() || undefined)));
                        return {
                            ErrorType: "matricula_exist",
                            Valid: !Boolean(matriculaExist),
                        }
                    },
                    OnGetErrorMessage: (value, errorType) => {
                        if (errorType == "matricula_exist") {
                            return this.VB_GetUIStringModule("error_matricula_existe");
                        }
                        return null;
                    },
                },
                { Field: "Nombre", Label: this.VB_GetUIStringModule("d_field_nombre"), Required: true, UIMinWidthTH: "100px" },
                { Field: "ApPaterno", Label: this.VB_GetUIStringModule("d_field_appaterno"), Required: true, UIMinWidthTH: "100px" },
                { Field: "ApMaterno", Label: this.VB_GetUIStringModule("d_field_apmaterno"), Required: false, UIMinWidthTH: "100px" },
                {
                    Field: "FechaNacimiento", Label: this.VB_GetUIStringModule("d_field_fechanacimiento"),
                    Required: false, UIMinWidthTH: "100px", ValueFormat: "dt_ddmmyyyy", RequiredValueType: "date_localUTC",
                },
                { Field: "Sexo", Label: this.VB_GetUIStringModule("d_field_strsexo"), Required: false, UIMinWidthTH: "100px", ValueFormat: "genero", RequiredValueType: "enumsexo" },
                { Field: "NombreT", Label: this.VB_GetUIStringModule("tag_nombret"), Required: true, UIMinWidthTH: "100px" },
                { Field: "ApPaternoT", Label: this.VB_GetUIStringModule("tag_appaternot"), Required: true, UIMinWidthTH: "100px" },
                { Field: "ApMaternoT", Label: this.VB_GetUIStringModule("tag_apmaternot"), Required: false, UIMinWidthTH: "100px" },
                { Field: "TelefonoT", Label: this.VB_GetUIStringModule("tag_telefonot"), Required: true, UIMinWidthTH: "100px", ValueFormat: "phonenumber" },
                {
                    Field: "CorreoT", Label: this.VB_GetUIStringModule("tag_correot"), Required: true, UIMinWidthTH: "200px",
                    ValueFormat: "email", UniqueValue: true,
                    // OnGetErrorType: (correo: string) => {
                    //     const correoF = correo.trim().toLowerCase();
                    //     let correoExistente = DataModuloMain.fn_GetReqDataArrayByName("Tutor")
                    //         .find((tutor) => (correoF == tutor.Correo.trim().toLowerCase())) != null;
                    //     return {
                    //         ErrorType: correoExistente ? "correo_existe" : null,
                    //         Valid: !correoExistente
                    //     }
                    // },
                    // OnGetErrorMessage: (value, errorType) => (errorType == "correo_existe") ? this.VB_GetUIStringModule("error_email_existe") : null,
                },
            ],
            OnGetTemplateFile: () => _SvArchivoDescargarPlantillaExcel(),
            ConfigActionUploadSelection: {
                TypeUpload: "allByOne",
                OnCallBeforeToProccess: () => new Promise<boolean>(resolve => {
                    ModalThings._GetModalToForm({
                        Title: UIUtilLang._GetUIString("crgamasiva", "tag_assignregts"),
                        Width: 400,
                        GetForm: () => (formRegistrar = new FormGenerator<TGeneralData>()._Crear({
                            LabelMaxWidth: 120,
                            schema: [
                                {
                                    type: Fields.selectMaterial,
                                    model: "IdEscuela",
                                    labelAttr: { text: UIUtilLang._GetUIString("crgamasiva", "tag_esc") },
                                    selectMaterialAttr: {
                                        valueMember: "IdKinder", displayMember: "Nombre", required: true,
                                        onChange: (idKinder) => {
                                            let listEscolaridad = DataModuloMain._GetReqDataArrayByName("Escolaridad", true)
                                                .filter(d => d.IdEscuela == idKinder);

                                            (<SelectV2>formRegistrar._ControlsData.get("IdEscolaridad").instance)
                                                ._ResetSelect()
                                                ._UpdateList(listEscolaridad);

                                            (<SelectV2>formRegistrar._ControlsData.get("IdGrado").instance)
                                                ._ResetSelect()
                                                ._UpdateList([]);
                                        }
                                    },
                                    values: DataModuloMain._GetReqDataArrayByName("Escuela", true)
                                },
                                {
                                    type: Fields.selectMaterial,
                                    model: "IdEscolaridad",
                                    labelAttr: { text: UIUtilLang._GetUIString("crgamasiva", "tag_nivel") },
                                    selectMaterialAttr: {
                                        valueMember: "Id", displayMember: "Nombre", required: true,
                                        onChange: (idEscolaridad) => {
                                            let listGrados = DataModuloMain._GetReqDataArrayByName("Grado", true)
                                                .filter(d => (d.IdEscolaridad == idEscolaridad));

                                            (<SelectV2>formRegistrar._ControlsData.get("IdGrado").instance)
                                                ._ResetSelect()
                                                ._UpdateList(listGrados);
                                        }
                                    },
                                    values: []
                                },
                                {
                                    type: Fields.selectMaterial,
                                    model: "IdGrado",
                                    labelAttr: { text: UIUtilLang._GetUIString("crgamasiva", "tag_grado") },
                                    selectMaterialAttr: { valueMember: "IdNivel", displayMember: "Nombre", required: true },
                                    values: []
                                }
                            ],
                        }), formRegistrar),
                        OnClose: () => {
                            resolve(false);
                        },
                        OnAccept: (form, mt) => {
                            resolve(true);
                            mt.Modal._Ocultar();
                            return null;
                        }
                    })
                }),
                OnCallService: async (datos) => {
                    let datosGen = formRegistrar._Data;
                    let res = await _SvAlumnoCargaMasiva(
                        datosGen.IdEscuela,
                        datosGen.IdGrado,
                        datosGen.IdEscolaridad,
                        datos
                            .map(d => {
                                let dataReal = Object.assign({}, d);
                                if (dataReal.FechaNacimiento) {
                                    dataReal.FechaNacimiento = new DateV2(dataReal.FechaNacimiento as any as Date)
                                        ._SetTimeZone(DataModuloMain._GetDataValueFieldByName("Escuela", datosGen.IdEscuela, "ZonaHoraria"), true)
                                        ._ToISOString();
                                }
                                delete dataReal._ID;
                                return dataReal;
                            })
                    );
                    if (res.Resultado > 0) {
                        await MainPage._ReloadServiceAndAwaitBool(Entidad.CTipoRequest.Alumno);
                        await MainPage._ReloadServiceAndAwaitBool(Entidad.CTipoRequest.Tutor);
                        await MainPage._ReloadServiceAndAwaitBool(Entidad.CTipoRequest.TutorAsignacion);
                    }
                    if (res.Resultado < 0) {
                        res.Mensaje = UIUtilLang._GetHTTPMessage(res, "cargamasiva").replace("_C", res.Mensaje);
                    }
                    return res;
                }
            }
        });
    }

    private OpenModal_Circulares(alumnos: IAlumno[]) {
        interface IAlumnosEscuela {
            IdsAlumnos: number[];
            IdEscuela: number;
            NombreEscuela: string;
        }
        const arrAlumnosEscuela = Array.from(d3Group(alumnos, d => d.IdKinder).values()).map<IAlumnosEscuela>(d => ({ IdEscuela: d[0].IdKinder, IdsAlumnos: d.map(d => d.IdChild), NombreEscuela: d[0].NombreKinder }));
        this.GridOpenModal_ActionFormToAGridData({
            Title: "tag_enviar_circular",
            GetForm: () => UIUtilViewCircular._GetNoticeForm(Entidad.CModulo.PanelCircular, Entidad.CAccionPermiso.Agregar, "childPanel").Form,
            OnAccept: async (form, modalThings) => {
                let dataForm = form._Data;
                interface DataProcess {
                    Resultado: number,
                    ItemData: IAlumnosEscuela,
                    TipoRequest: Entidad.CTipoRequest
                }

                let dataProcess = arrAlumnosEscuela.map<DataProcess>(alumnosEsc => ({
                    Resultado: -1,
                    ItemData: alumnosEsc,
                    TipoRequest: Entidad.CTipoRequest.Circular,
                }))
                await ModalThings._ProccessServiceByServiceFromAArrayIterator(modalThings, dataProcess, {
                    OnStepAProccess: async (item) => {
                        return _SvNoticiaNueva("ByAlumnos", <IMapSendCincularItems["ByAlumnos"]>{
                            IdEscuela: item.IdEscuela,
                            Titulo: dataForm.Titulo,
                            Mensaje: dataForm.Mensaje,
                            Archivo: ((dataForm.Archivo as any instanceof File) ? dataForm.Archivo : null) as any as File,
                            Programado: dataForm.Programado,
                            ReqAutorizacion: dataForm.ReqAutorizacion,
                            IdAlumnos: item.IdsAlumnos
                        })
                    },
                    OnError_GetItemDataTag: (itemData) => itemData.NombreEscuela,
                    Action: Entidad.CAccionPermiso.Agregar,
                    GeneralErrMessage: this.VB_GetUIStringModule("msg_sendcirc_err"),
                }).catch((err) => { console.log(err); return null; });

                modalThings.Modal._Ocultar();
                return null;
            },
        })
    }

    protected GRID_GetExportarConfig(dataGrid: IAlumno[]): IConfigGridExcelExport<IAlumno> {
        return {
            IdsEscuelas: [... new Set(dataGrid.map(d => (d.IdKinder)))],
            ColumnsConfig: this.ctrlTabla
                ._InfoColumns
                .filter(d => (d.Field != "getThumbnailURL"))
                .map<ExcelThings.IColumnToExcelExportFileConfig<IAlumno>>(d => ({
                    Field: (d.Field == "FechaNacimiento" ? "getFechaNacimientoFmt" : d.Field),
                    HeaderTag: d.Label,
                    WidthCell: (d.Field == "NombreCompleto" ? 35 : 20),
                })),
            OnGetDataBySheets: async () => {
                return Array.from(d3Group(dataGrid, d => d.IdKinder))
                    .map<ExcelThings.ISheetConfig<IAlumno>>(([idEscuela, alumnos]) => ({
                        IdSheet: idEscuela,
                        SheetName: alumnos[0].NombreKinder,
                        Data: alumnos,
                    }))
            },
            OnGetEscuelasTagInSheet: (datos) => datos[0].NombreKinder
        }
    }

    // **********************************************************************************************
    // PANEL AND THINGS
    // **********************************************************************************************

    private PanelModeVerDetalle(idAlumno: number) {
        UIWindowManager._DoHash("alumnos/alumnos/panel", {
            mode: "All",
            id: [idAlumno]
        })
    }

    private OpenPanelModeAsignarLogros(datos: IAlumno[]) {
        UIWindowManager._DoHash("alumnos/alumnos/panel", {
            mode: "palumnologros",
            id: datos.map(d => d.IdChild)
        })
    }

    private OpenPanelModeInscribir(datos: IAlumno[]) {
        UIWindowManager._DoHash("alumnos/alumnos/panel", {
            mode: "palumnohorariosgrupos",
            id: datos.map(d => d.IdChild)
        })
    }

    private OpenPanelModeCargosYDescuentos(datos: Array<IAlumno>) {
        UIWindowManager._DoHash("alumnos/alumnos/panel", {
            mode: "palumnocargosdescuentos",
            id: datos.map(d => d.IdChild)
        })
    }

    private OpenPanelModeAsignarMaterias(datos: IAlumno[]) {
        UIWindowManager._DoHash("alumnos/alumnos/panel", {
            mode: "palumnomaterias",
            id: datos.map(d => d.IdChild)
        })
    }

    // **********************************************************************************************
    // PROCESOS AUXILIARES
    // **********************************************************************************************

    /** Valída:
     * * (1) Todos los alumnos pertenecen a la misma escuela, escolaridad y grado.
     * * (2) Ningún alumno tiene horarios (asignaciones a grupos) dentro de su escuela actual (asignaciones válidas).
     */
    private AllStudentsValidToAsignSchedules(ninios: IAlumno[]) { //, HorariosAsignados = this.ninioHorarioAsignacion) {
        /* let allInSameGrade = ninios.every(d => (
            (ninios[0].IdKinder == d.IdKinder) &&
            (ninios[0].IdEscolaridad == d.IdEscolaridad) &&
            (ninios[0].IdGrado == d.IdGrado)
        )); */

        //No están en el mismo kinder
        if (!ninios.every(d => (ninios[0].IdKinder == d.IdKinder))) {
            return {
                enable: false,
                message: this.VB_GetUIStringModule("tlt_inscribir_fail_nosameschool")
            }
        }
        //No están en el mismo nivel
        if (!ninios.every(d => (ninios[0].IdEscolaridad == d.IdEscolaridad))) {
            return {
                enable: false,
                message: this.VB_GetUIStringModule("tlt_inscribir_fail_nosamelevel")
            }
        }
        //No están en el mismo grado
        if (!ninios.every(d => (ninios[0].IdGrado == d.IdGrado))) {
            return {
                enable: false,
                message: this.VB_GetUIStringModule("tlt_inscribir_fail_nosamegrade")
            }
        }

        for (let alumnoHorarios of UIUtilViewAlumno._GetGruposInscritosValidos(ninios).GruposHorariosValidosMap.values()) {
            if (alumnoHorarios.length > 0) {
                // >> Si algún alumno tiene asignaciones de horario con algún grupo
                return {
                    enable: false,
                    message: this.VB_GetUIStringModule("tlt_inscribir_fail_asignhorario").replace("_NAME", DataModuloMain._GetDataValueFieldByName("Alumno", alumnoHorarios[0].IdNinio, "NombreCompleto"))
                }
            }
        }
        return {
            enable: true,
            message: ""
        };
        // Tienen que estar en el mismo grado
        /* if (allInSameGrade) {
            for (let alumnoHorarios of UIUtilViewAlumno.fn_GetGruposInscritosValidos(ninios).GruposHorariosValidosMap.values()) {
                if (alumnoHorarios.length > 0) {
                    // >> Si algún alumno tiene asignaciones de horario con algún grupo
                    return {
                        enable: false,
                        message: "Ya existen asignaciones de horario"
                    }
                }
            }
            return {
                enable: true,
                message: ""
            };
        } */
        /* return {
            enable: false,
            message: "No todos los alumnos están en el mismo grado"
        }; */
    }

    private AllStudentsToAsignLogros(students: IAlumno[]) {
        let allInSameSchool = students.every(d => (
            (students[0].IdKinder == d.IdKinder)
        ));
        if (!allInSameSchool)
            return {
                enable: false,
                message: this.VB_GetUIStringModule("tlt_asign_fail_nosameschool")
            }
        // let alumnoConLogros = students.find(d => (Data.Modulo.Alumno.fn_LOCALDATA_GetLogrosDeAlumno(d.IdChild).size > 0));
        // if (alumnoConLogros) {
        //     return {
        //         enable: false,
        //         message: this.VB_GetUIStringModule("tlt_logrosasign_fail_multasign")
        //     }
        // }
        return {
            enable: true,
        }
    }

    private AllStudentsToAsignMaterias(students: IAlumno[]) {
        let allInSameSchool = students.every(d => (students[0].IdKinder == d.IdKinder));

        if (!allInSameSchool) {
            return {
                enable: false,
                message: this.VB_GetUIStringModule("tlt_asign_fail_nosameschool")
            }
        }
        return {
            enable: true,
        }
    }

    private OpenGenerarBoletas(alumnos: Array<IAlumno>) {
        const MapAlumnos = new Map<number, IAlumno>();
        alumnos.forEach(d => MapAlumnos.set(d.IdChild, d));
        let ctrlSelectCicloEscolar: SelectV2<ICicloEscolar, "Id">;
        let ctrlSelectPeriodo: SelectV2<IPeriodoD, "Id", "multiselect">;
        let inputInitRange: HTMLInputElement;
        let inputFinRange: HTMLInputElement;

        ModalThings._GetModalToAProccess({
            Title: "tag_periodo_generarboletas",
            LangModuleKeyInContext: "alumnos",
            DrawContent: (content, modalThings) => {
                let selectorsContainer = content.append("div")
                    .classed(UIUtilGeneral.FBoxOrientation.Vertical, true)
                    .style("gap", "24px")
                    .style("padding", "14px");

                ctrlSelectCicloEscolar = new SelectV2<ICicloEscolar, "Id">({
                    Type: "monoselect",
                    Parent: selectorsContainer,
                    DisplayMember: "Nombre",
                    ValueMember: "Id",
                    OnChange: (id, cicloEscolar) => {
                        let periodos = this.GetPeriodosListFromCicloEscolar(cicloEscolar);
                        ctrlSelectPeriodo._UpdateList(periodos)
                    }
                });

                selectorsContainer.append(() => ElementWrapper._WrapperToSelectControl(ctrlSelectCicloEscolar, this.VB_GetUIStringModule("tag_cicloesc")).node());

                ctrlSelectPeriodo = new SelectV2<IPeriodoD, "Id", "multiselect">({
                    Type: "multiselect",
                    Parent: selectorsContainer,
                    DisplayMember: "Name",
                    ValueMember: "Id",
                    OnSelect: (data) => {
                        if (!data.length) { inputInitRange.min = null; inputInitRange.max = null; inputFinRange.min = null; inputFinRange.max = null; return; };
                        const periodosDtSelected: Date[] = data.map(d => new Date(d.Year, d.Month, 1));
                        periodosDtSelected.sort((a, b) => ascending(a.getTime(), b.getTime()));
                        let dtInicio = new Date(periodosDtSelected[0]);
                        let dtFin = new Date(periodosDtSelected[periodosDtSelected.length - 1]);
                        dtFin.setMonth(dtFin.getMonth() + 1);
                        dtFin.setMilliseconds(dtFin.getMilliseconds() - 1);
                        const minInput = UIUtilTime._FmtToInputDate(dtInicio);
                        const maxInput = UIUtilTime._FmtToInputDate(dtFin);
                        inputInitRange.min = minInput;
                        inputInitRange.max = maxInput;
                        inputFinRange.min = minInput;
                        inputFinRange.max = maxInput;
                    }
                })

                selectorsContainer.append(() => ElementWrapper._WrapperToSelectControl(ctrlSelectPeriodo, this.VB_GetUIStringModule("tag_period")).node());

                selectorsContainer.append("label")
                    .text(_L("alumnos.tag_generarboleta_filtro_opcional"))
                    .style("font-family", "Lato-Bold")

                let dateRangeContainer = selectorsContainer.append("div")
                    .style("display", "flex")
                    .style("flex-flow", "row")
                    .style("gap", "10px")

                inputInitRange = dateRangeContainer.append("input")
                    .attr("type", "date")
                    .node();

                dateRangeContainer.append(() => ElementWrapper._WrapperToElement(inputInitRange, "Inicio").node());

                inputFinRange = dateRangeContainer.append("input")
                    .attr("type", "date")
                    .node();

                dateRangeContainer.append(() => ElementWrapper._WrapperToElement(inputFinRange, "Fin").node());

                const idEscuela = alumnos[0].IdKinder;
                const ciclosEscolaresList = this.GetCiclosEscolaresList(idEscuela);
                ctrlSelectCicloEscolar._UpdateList(ciclosEscolaresList);

                let cicloEscCurrent: ICicloEscolar;
                if (!ctrlSelectCicloEscolar._dataValueMemberSelected.length) {
                    cicloEscCurrent = this.GetCurrentCicloEscolar(ciclosEscolaresList, idEscuela);
                    ctrlSelectCicloEscolar._valueSelect(cicloEscCurrent?.Id);
                }

                const periodosList = this.GetPeriodosListFromCicloEscolar(ctrlSelectCicloEscolar._dataSelected[0])
                ctrlSelectPeriodo._UpdateList(periodosList);
                if (!ctrlSelectPeriodo._dataValueMemberSelected.length) {
                    const periodoCurrent = this.GetInitPeriodoFromCicloEsc(cicloEscCurrent, periodosList);
                    ctrlSelectPeriodo._valueSelect(periodoCurrent?.Id);
                }
            },
            OnAccept: async (mt) => {
                const idEscuela = alumnos[0].IdKinder;
                const [cicloEscolarSelected] = ctrlSelectCicloEscolar._dataValueMemberSelected;
                const periodosSelected: string[] = ctrlSelectPeriodo._dataSelected.map(d => new Date(d.Year, d.Month, 2).toISOString())
                let dtInitRange: DateV2;
                let dtFinRange: DateV2;

                let strInitRange = inputInitRange.value;
                let strFinRange = inputFinRange.value;

                let usesDateRange = false;
                if ((strInitRange || strFinRange) || (!inputInitRange.validity.valid || !inputFinRange.validity.valid)) { // SI ALGUNO DE LOS DOS INPUTS TIENEN VALOR
                    if ((!inputInitRange.validity.valid || !inputFinRange.validity.valid) || (!strInitRange || !strFinRange)) { NotificacionV2._Mostrar(_L("alumnos.notif_erro_filterrange"), "ADVERTENCIA"); return; }
                    dtInitRange = new DateV2(UIUtilTime._GetLocalDateFromInputDateString(inputInitRange.value))._SetTimeZoneByIdSchool(idEscuela, true);
                    dtFinRange = new DateV2(UIUtilTime._GetLocalDateFromInputDateString(inputFinRange.value))._SetTimeZoneByIdSchool(idEscuela, true);
                    dtFinRange.setDate(dtFinRange.getDate() + 1);
                    dtFinRange.setMilliseconds(dtFinRange.getMilliseconds() - 1)
                    if (dtFinRange.getTime() < dtInitRange.getTime()) { NotificacionV2._Mostrar(_L("alumnos.notif_erro_filterrange"), "ADVERTENCIA"); return; }
                    usesDateRange = true;
                }

                if (!cicloEscolarSelected) { NotificacionV2._Mostrar(_L("alumnos.notif_selectperiod_tocontinue"), "ADVERTENCIA"); return }
                if (!periodosSelected?.length) { NotificacionV2._Mostrar(_L("alumnos.notif_selectperiod_tocontinue"), "ADVERTENCIA"); return }

                const [cicloEscolarSelectedInfo] = ctrlSelectCicloEscolar._dataSelected;
                const periodosDtSelected: Date[] = ctrlSelectPeriodo._dataSelected.map(d => new DateV2(new Date(d.Year, d.Month, 1))._SetTimeZoneByIdSchool(idEscuela, true));
                const { dtInicio, dtFin } = UIUtilViewCalificacion._GetInitAndEndInPeriodosList(periodosDtSelected);

                const strInit = dtInicio._ToISOLocalString();
                const strFin = dtFin._ToISOLocalString();

                mt.Progress.attr("oculto", false);
                // EN SERVICIO SE AJUSTA LA FECHA DE EVALUACIÓN A LA ZONA DE LA ESCUELA // LAS FECHAS DE LOS PERIODOS Y DE EVALUACIONES SON TRUNCADAS AL MES
                let resCalificaciones = await _SvAlumnoGetBoletaCalificacionesV2(alumnos.map(d => d.IdChild), cicloEscolarSelected, periodosSelected);
                mt.Progress.attr("oculto", true);

                if (resCalificaciones.Resultado < 1) { NotificacionV2._Mostrar(_L("general.notif_fail"), "ADVERTENCIA"); return; }
                if (!resCalificaciones.Datos?.length) { NotificacionV2._Mostrar(_L("alumnos.notif_nocalifreg"), "ADVERTENCIA"); return; }

                const BoletasAlumnos: IAlumnoBoleta[] = [];
                let allStudentsCalif = resCalificaciones.Datos;
                allStudentsCalif = allStudentsCalif.filter(calif => {
                    const iDelete: number[] = [];
                    calif.FechasEval.forEach((evalFecha, i) => {
                        const dtEval = new DateV2(evalFecha)._SetTimeZoneByIdSchool(idEscuela, true);
                        let dateInRange = false;
                        dateInRange = UIUtilViewCalificacion._IsDateInRange(dtEval, periodosDtSelected, (usesDateRange) ? { dtInitRange: dtInitRange, dtFinRange: dtFinRange } : null)
                        if (!dateInRange) iDelete.push(i);
                    })
                    iDelete.forEach((iPos, i) => { calif.Evaluaciones.splice(iPos - i, 1); calif.FechasEval.splice(iPos - i, 1); calif.IdsEvaluaciones.splice(iPos - i, 1); calif.IdsEvaluadores.splice(iPos - i, 1); calif.Observaciones.splice(iPos - i, 1); })
                    const auxInfoEvaluaciones: UIUtilViewCalificacion.IAuxInfoEvaluaciones[] = calif.Evaluaciones
                        .map((d, i) => ({
                            Evaluacion: calif.Evaluaciones[i],
                            FechaEval: calif.FechasEval[i],
                            IdEvaluacion: calif.IdsEvaluaciones[i],
                            Observacion: calif.Observaciones[i],
                            TipoEval: calif.Tipo,
                            IdEvaluador: calif.IdsEvaluadores[i],
                        }))
                    auxInfoEvaluaciones.sort((a, b) => descending(a.IdEvaluacion, b.IdEvaluacion));
                    auxInfoEvaluaciones.sort((a, b) => descending(a.FechaEval, b.FechaEval));
                    calif.Evaluacion = [UIUtilViewCalificacion._GetEvalGral(auxInfoEvaluaciones.map(d => d.Evaluacion), calif.Tipo)[0]].join("\r\n");
                    return calif.Evaluaciones.length > 0;
                })

                if (!allStudentsCalif.length) { NotificacionV2._Mostrar(_L("alumnos.notif_nocalifreg"), "ADVERTENCIA"); return; }
                mt.Progress.attr("oculto", false);
                const resLoadInfoToBuildGradesReport = await UIUtilViewCalificacion._LoadInfoToBuildReport(Array.from(d3Group(allStudentsCalif, d => d.IdAlumno).keys()), alumnos[0].IdKinder, cicloEscolarSelected, strInit, strFin);
                if (!resLoadInfoToBuildGradesReport.successReload) { NotificacionV2._Mostrar(_L("general.notif_fail"), "ADVERTENCIA"); return; }
                mt.Progress.attr("oculto", true);

                const arrAlumnosErrBoletasLimit: IAlumno[] = [];
                for (const alumno of alumnos) {
                    const boletasInfoChild = resLoadInfoToBuildGradesReport.mapBoletasInfoChild.get(alumno.IdChild);
                    // VALIDACION DE NUMERO DE BOLETAS EN CICLO
                    if (boletasInfoChild && boletasInfoChild.length > 2) { arrAlumnosErrBoletasLimit.push(alumno); }
                }

                if (arrAlumnosErrBoletasLimit.length) {
                    ModalThings._GetModalInfoDataList({
                        Title: _L("alumnos.tag_title_boletasenciclo"),
                        InfoText: _L("alumnos.msg_err_boletasexceeded").replace("_CICLOESC", `<b>${cicloEscolarSelectedInfo.Nombre}</b>`) + ":",
                        DataList: arrAlumnosErrBoletasLimit,
                        OnStepItemData(content, itemData) {
                            content.text(itemData.NombreCompleto);
                        },
                    })
                    return;
                }


                interface IAlumnoErrPeriod extends IAlumno {
                    PeriodsErr: number[];
                }

                const auxArrInfoBoletas = resLoadInfoToBuildGradesReport.arrAsignacionInfo;

                const arrPeriodsIdsEvalRepeat: IAlumnoErrPeriod[] = [];

                d3Group(allStudentsCalif, d => d.IdAlumno)
                    .forEach((alumnoCalifs, idAlumno) => {
                        const boletasInfoChild = resLoadInfoToBuildGradesReport.mapBoletasInfoChild.get(idAlumno);
                        const calificaciones: IElementoCalificado[] = alumnoCalifs.map(elCalificado => {
                            const evaluaciones = elCalificado.Evaluacion.split("\r\n");
                            if (elCalificado.Tipo == CTipoEvaluacion.Numeros)
                                evaluaciones.forEach((d, i) => { evaluaciones[i] = Number(d).toFixed(1) });

                            return {
                                ...elCalificado,
                                EvaluacionArr: evaluaciones,
                                AuxInfoEvaluaciones: elCalificado.Evaluaciones
                                    .map((d, i) => ({
                                        Evaluacion: elCalificado.Evaluaciones[i],
                                        FechaEval: elCalificado.FechasEval[i],
                                        IdEvaluacion: elCalificado.IdsEvaluaciones[i],
                                        Observacion: elCalificado.Observaciones[i],
                                        IdEvaluador: elCalificado.IdsEvaluadores[i],
                                        TipoEval: elCalificado.Tipo,
                                    }))
                                    .sort((a, b) => b.FechaEval.localeCompare(a.FechaEval)),
                            }
                        })
                        let otherInfo = resLoadInfoToBuildGradesReport.mapOtherInfo.get(idAlumno);

                        let inasistenciasFiltered = (otherInfo?.Inasistencias) ? [...otherInfo?.Inasistencias] : null;
                        if (otherInfo?.Inasistencias) {
                            inasistenciasFiltered = UIUtilViewCalificacion._FilterInasistenciasByRanges(inasistenciasFiltered, periodosDtSelected, idEscuela, (usesDateRange) ? { dtInitRange: dtInitRange, dtFinRange: dtFinRange } : null);
                        }

                        const currentPeriodEvalIds: number[] = [];
                        calificaciones.forEach(d => {
                            currentPeriodEvalIds.push(...d.IdsEvaluaciones)
                        })

                        if (boletasInfoChild) {
                            boletasInfoChild.sort((a, b) => ascending(a.FechaCreacion, b.FechaCreacion));
                            boletasInfoChild.forEach((boleta, iPos) => {
                                const hasIdEvalRepeat = boleta.IdsEvaluaciones.some(idEval => currentPeriodEvalIds.some(currenPIdEval => currenPIdEval == idEval))
                                if (hasIdEvalRepeat) {
                                    let alumnoInfoErr = arrPeriodsIdsEvalRepeat.find(d => d.IdChild == idAlumno);
                                    if (!alumnoInfoErr) arrPeriodsIdsEvalRepeat.push({ ...MapAlumnos.get(idAlumno), PeriodsErr: [] });
                                    alumnoInfoErr = arrPeriodsIdsEvalRepeat.find(d => d.IdChild == idAlumno);
                                    alumnoInfoErr.PeriodsErr.push(iPos + 1);
                                }
                            })
                        }

                        BoletasAlumnos.push({
                            Alumno: MapAlumnos.get(idAlumno),
                            Evaluaciones: calificaciones,
                            CicloEscolar: cicloEscolarSelectedInfo,
                            Comentario: "",
                            InfoExtra: resLoadInfoToBuildGradesReport.mapInfoExtra.get(idAlumno),
                            Inasistencias: otherInfo?.Inasistencias ? inasistenciasFiltered.length : null,
                            BoletasGeneradasEnCiclo: boletasInfoChild,
                        })
                    })

                if (arrPeriodsIdsEvalRepeat.length) {
                    ModalThings._GetModalInfoDataList({
                        Title: _L("alumnos.tag_title_evaluacionesrepeat"),
                        InfoText: _L("alumnos.msg_err_evaluacionesrepeat"),
                        DataList: arrPeriodsIdsEvalRepeat,
                        OnStepItemData(content, itemData) {
                            content.append("b").text(`${itemData.NombreCompleto}: `);
                            content.append("span").text(`${itemData.PeriodsErr.map(d => "Periodo " + d).join(", ")}`);
                        }
                    })
                    return;
                }
                UIUtilViewCalificacion._OpenModalGenerarBoletas(BoletasAlumnos, auxArrInfoBoletas);

                mt.Modal._Ocultar();
                return null;
            },
        })
    }

    private GetCiclosEscolaresList(idEscuela: number) {
        let ciclosEscolaresList = DataModuloMain._GetReqDataArrayByName("CicloEscolar")
            .filter(dCiclo => (dCiclo.IdEscuela == idEscuela))
            .sort((a, b) => Number(new Date(b.FechaInicio)) - Number(new Date(a.FechaInicio)))

        return (ciclosEscolaresList || []);
    }

    private GetCurrentCicloEscolar(ciclosEscolares = DataModuloMain._GetReqDataArrayByName("CicloEscolar"), idEscuela: number): (ICicloEscolar | null) {
        const now = new DateV2()._SetTimeZoneByIdSchool(idEscuela).getTime();
        let cicloEsc = ciclosEscolares
            .filter(dCiclo => (dCiclo.IdEscuela == idEscuela))
            .find(d =>
                new DateV2(d.FechaInicio)._SetTimeZoneByIdSchool(idEscuela).getTime() <= now
                && new DateV2(d.FechaFin)._SetTimeZoneByIdSchool(idEscuela).getTime() >= now
            );

        if (!cicloEsc) {
            cicloEsc = ciclosEscolares[0];
        }

        return cicloEsc;
    }

    private GetPeriodosListFromCicloEscolar(cicloEscolar: ICicloEscolar): IPeriodoD[] {
        let periodos: IPeriodoD[] = [];

        if (cicloEscolar) {
            periodos = UIUtilViewData._GetPeriodosFromCicloEscolar(cicloEscolar, true).map(d => ({ ...d, EnCicloEscolar: true }));
        }

        return periodos;
    }

    private GetInitPeriodoFromCicloEsc(cicloEscolar: ICicloEscolar, periodos = this.GetPeriodosListFromCicloEscolar(cicloEscolar)) {
        const now = new Date();

        let periodo = periodos
            .find(d => d.Month == now.getMonth() && d.Year == now.getFullYear());

        if (!periodo && periodos) {
            periodo = periodos[0]
        }

        return periodo;
    }
}
