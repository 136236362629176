export namespace UIUtilGlobalKeyEvents {
    type TID = number | string | HTMLElement;
    // Keyevents controller Callbacks
    /** @example (keyof KeyboardEvent)[] */
    const CONTROLKEYS = ["ctrlKey", "altKey", "shiftKey", "metaKey"] as const;
    type TControlKey = typeof CONTROLKEYS[number]

    // type TControlKeyConfig = keyof Pick<typeof CONTROLKEYS, "ctrlKey" | "altKey">;
    /** Keys en comun entre: `e.key` y `e.code` */
    type TKeyCode = "Enter" | "Tab" | "Backspace" | "Pause" | "ArrowLeft" | "ArrowUp" | "ArrowRight" | "ArrowDown" | " "; // "Escape"
    /** Keys `e.code` */
    type TKey = "0" | "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9"
        | "A" | "B" | "C" | "D" | "F" | "S";
    type TK = TKeyCode | TKey;
    type TKeyEventConfig<TId extends TID> = {
        id: TId;
        key: TK;
        callback: Function;
        onExpireCallback?: () => void;
        /** Si true, previene la llamada de otros callbacks configurados
         * @default true */
        preventCalls?: boolean;
        /** Coloca el escuchador sobre el elemento de referencia y detiene la propagación,
         * evita usar el escuchador global de {@link document}
         * @default false */
        eventOnRefElement?: boolean;
    }
        & Partial<Pick<KeyboardEvent, TControlKey>>
        & (
            TId extends Element
            ? {
                /** Sí el ID es `Element` por defecto toma su valor */
                refElement?: TRefElement;
            }
            : {
                refElement: TRefElement;
            }
        )
    type TRefElement = HTMLElement & {
        __eventOnRefElement?: (e: KeyboardEvent) => void;
    }
    type TKeyEventItem<TId extends TID> = TKeyEventConfig<TId>
        & {
            __eventOnRefElement?: (e: TKeyboardEvent) => void;
            __activeEventOnRefElement?: () => void;
            __desactiveEventOnRefElement?: () => void;
        }
    type TKeyboardEvent = KeyboardEvent & {
        __itemKeyEventTarget?: TKeyEventItem<TID>;
    }

    const ESCAPEKEY: any = "Escape";
    const KEYEVENTCALLS = new Map<TID, TKeyEventItem<TID>>();
    const KEY_DIRECTION = "keyup" as const

    const KeyEventListener = (e: TKeyboardEvent) => {
        const key = e.key as TK;
        let keyCalls = e.__itemKeyEventTarget ? [e.__itemKeyEventTarget] : GetAllCallbacksByFinalKey(key);
        KillExpireCallbakcs(keyCalls);
        if (key == ESCAPEKEY && CONTROLKEYS.every(d => e[d] == false)) { // ValidaControlKeys(e)) {
            let keyCalls = GetAllCallbacksByFinalKey(key).filter(d => CONTROLKEYS.every(k => d[k] !== true));
            let { id, callback } = (keyCalls.pop() || {}); // GetLastCallbackByKey(key);
            if (callback) {
                callback();
                _RemoveKeyEventCallback(id);
            }
        }
        else {
            let prevent = false;
            const calls = GetAllCallbacksByFinalKey(key);
            let preventCallback: TKeyEventItem<TID>;
            let validCallbacks: TKeyEventItem<TID>[] = [];
            for (const d of calls) {
                if (!ValidaControlKeys(e, d)) continue;

                if (d.preventCalls)
                    preventCallback = d; // pop

                validCallbacks.push(d);
            }

            if (preventCallback) {
                preventCallback.callback();
                prevent = true;
            }
            else if (validCallbacks.length) {
                prevent = true;
                validCallbacks.forEach(d => {
                    d.callback();
                });
            }
            if (prevent)
                e.preventDefault();
        }
    }
    document.addEventListener(KEY_DIRECTION, KeyEventListener);
    let existGlobalListener = true;

    function KillExpireCallbakcs(items: TKeyEventItem<TID>[]) {
        items.forEach(d => {
            if (!d.refElement.isConnected) {
                _RemoveKeyEventCallback(d.id);
            }
        })
    }

    function ValidaControlKeys(e: KeyboardEvent, kItem: TKeyEventItem<TID>) {
        for (const key of CONTROLKEYS) {
            const isActive: boolean = e[key];
            const isObservable = kItem[key] === true;
            if ((isObservable && !isActive) || (!isObservable && isActive)) {
                return false;
            }
        }
        return true;
    }

    // function GetLastCallbackByKey(key: TK) {
    //     let keydownCallsFromKey = GetAllCallbacksByFinalKey(key);
    //     return keydownCallsFromKey[keydownCallsFromKey.length - 1] || <typeof keydownCallsFromKey[0]>{};
    // }

    function GetAllCallbacksByFinalKey(key: TK): TKeyEventItem<TID>[] {
        if (key == null) return [];
        return GetKeyEventCallsArray()
            .filter(d => (d.key.toLowerCase() == key.toLowerCase()));
    }

    function GetAllCallbacksByKItem(kItem: TKeyEventItem<TID>): TKeyEventItem<TID>[] {
        if (kItem == null) return [];
        return GetKeyEventCallsArray()
            .filter(d => (<(keyof TKeyEventItem<TID>)[]>[...CONTROLKEYS, "id", "key"])
                .every(prop => (d[prop] === kItem[prop]))
            )
    }

    function GetKeyEventCallsArray() {
        return Array.from(KEYEVENTCALLS.values());
    }

    // General

    export function _ActiveGlobalListener() {
        KEYEVENTCALLS.forEach(d => {
            if (!d.eventOnRefElement) return;
            d.__activeEventOnRefElement();
        })
        if (existGlobalListener) return;
        document.addEventListener(KEY_DIRECTION, KeyEventListener);
        existGlobalListener = true;
    }

    export function _StopGlobalListener() {
        KEYEVENTCALLS.forEach(d => {
            if (!d.eventOnRefElement) return;
            d.__desactiveEventOnRefElement();
        })
        if (!existGlobalListener) return;
        document.removeEventListener(KEY_DIRECTION, KeyEventListener);
        existGlobalListener = false;
    }

    // Global Key controlador

    export function _SetKeyEventCallback<TId extends TID>(id: TId, item: Omit<TKeyEventConfig<TId>, keyof Pick<TKeyEventConfig<TId>, "id">>) {
        if (!item.refElement && id instanceof HTMLElement) {
            item.refElement = id;
        }
        else if (!item.refElement) throw Error("RefElement not found");
        const finalItem: TKeyEventItem<TID> = {
            id: id,
            ...item
        }
        finalItem.preventCalls = finalItem.preventCalls == null ? true : finalItem.preventCalls;
        const keyCalls = GetAllCallbacksByKItem(finalItem);
        KillExpireCallbakcs(keyCalls);
        KEYEVENTCALLS.set(id as TId, finalItem);
        if (finalItem.eventOnRefElement) {
            finalItem.__eventOnRefElement = (e: TKeyboardEvent) => {
                e.stopPropagation();
                e.__itemKeyEventTarget = finalItem;
                KeyEventListener(e);
            };
            finalItem.__activeEventOnRefElement = () => {
                if (finalItem.refElement.__eventOnRefElement) return;
                finalItem.refElement.__eventOnRefElement = finalItem.__eventOnRefElement;
                finalItem.refElement.addEventListener(KEY_DIRECTION, finalItem.__eventOnRefElement);
            }
            finalItem.__desactiveEventOnRefElement = () => {
                if (!finalItem.refElement.__eventOnRefElement) return;
                finalItem.refElement.__eventOnRefElement = null;
                finalItem.refElement.removeEventListener(KEY_DIRECTION, finalItem.__eventOnRefElement);
            }
            if (existGlobalListener)
                finalItem.__activeEventOnRefElement();
        }
    }

    export function _RemoveKeyEventCallback(id: TID) {
        const item = KEYEVENTCALLS.get(id);
        if (!item) return;
        if (item.onExpireCallback) {
            item.onExpireCallback();
        }
        if (item.eventOnRefElement) {
            item.__desactiveEventOnRefElement();
        }
        KEYEVENTCALLS.delete(id);
    }

    // ESCAPE Key controlador


    export function _SetEscKeyEventCallback(id: TID, call: Function) {
        _SetKeyEventCallback(id, {
            key: ESCAPEKEY,
            callback: () => {
                call();
                _RemoveKeyEventCallback(id);
            }
        })
    }

    export const _RemoveEscKeyEventCallback = _RemoveKeyEventCallback;

    // ArrowUp && ArrowDown in list

    type IArrowMoveElementList = HTMLElement & {
        __IDArrowMoveController?: string;
        __OnMouseOver?: (e: MouseEvent) => void;
    }
    interface IArrowMoveConfig {
        ListContainer: IArrowMoveElementList;
        UpdateItemFocusStyle: (item: HTMLElement, focused: boolean) => any;
        GetElementIsValid?: (item: HTMLElement) => boolean;
        OnSelect: (item: HTMLElement) => void;
        /** @default -1 */
        StartFocus?: number | HTMLElement;
        OnMoveFocus?: () => void,
        BlurPrevent?: boolean;
    }
    // FIXME PENDIENTE incluir interacciones de TAB KEY y FOCUS a los elementos
    export function _SetArrowMoveKeyEventCallback(config: IArrowMoveConfig) {
        let keyCalls = [...GetAllCallbacksByFinalKey("ArrowDown"), ...GetAllCallbacksByFinalKey("ArrowUp")];
        KillExpireCallbakcs(keyCalls);
        if (!config.ListContainer.__IDArrowMoveController) {
            config.ListContainer.__IDArrowMoveController = "arrowMoveController_" + new Date().getTime();
        }
        const idBase = config.ListContainer.__IDArrowMoveController;
        const { ListContainer, UpdateItemFocusStyle, GetElementIsValid, OnSelect, StartFocus = -1, OnMoveFocus } = config;

        UIUtilGlobalKeyEvents._SetKeyEventCallback(idBase + "_1", {
            key: "ArrowUp",
            refElement: ListContainer,
            eventOnRefElement: true,
            callback: () => fnMoveFocus(-1),
        })
        UIUtilGlobalKeyEvents._SetKeyEventCallback(idBase + "_2", {
            key: "ArrowDown",
            refElement: ListContainer,
            eventOnRefElement: true,
            callback: () => fnMoveFocus(1),
            onExpireCallback: () => {
                ListContainer.removeEventListener("mouseover", ListContainer.__OnMouseOver);
            },
        })

        if (ListContainer.__OnMouseOver) {
            ListContainer.removeEventListener("mouseover", ListContainer.__OnMouseOver);
        }
        let lastOverElement: HTMLElement;
        ListContainer.__OnMouseOver = (e: MouseEvent) => {
            if (e.target === lastOverElement)
                return;
            lastOverElement = e.target as HTMLElement;
            let nIndex = fnGetElementIndex(lastOverElement);
            if (nIndex == -1) return;
            fnFocus(nIndex, false);
        }
        ListContainer.addEventListener("mouseover", ListContainer.__OnMouseOver);

        const fnGetElementIndex = (element: HTMLElement) => {
            return [...ListContainer.children].indexOf(element);
        }

        const fnMoveFocus = (move: number) => {
            if (OnMoveFocus) OnMoveFocus();
            const nLastIndex = ListContainer.childElementCount - 1;
            if (nLastIndex < 0) return;

            let nextIndex = focusIndex + move;
            if (nextIndex < 0)
                nextIndex = nLastIndex;
            else if (nextIndex > nLastIndex)
                nextIndex = 0;

            if (nextIndex == focusIndex) {
                return;
            }
            if (nextIndex == -1) return;
            if (!fnFocus(nextIndex, true)) {
                fnMoveFocus(move < 0 ? move-- : move++);
            }
        }

        const fnFocus = (nextIndex: number, scrollIntoView: boolean) => {
            let nextElementFocus = ListContainer.children[nextIndex] as HTMLElement;
            let valid = nextElementFocus != null;
            if (valid && GetElementIsValid) {
                valid = GetElementIsValid(nextElementFocus)
            }

            focusIndex = nextIndex

            if (!valid) {
                return false;
            }

            nextElementFocus.focus();
            if (scrollIntoView) nextElementFocus.scrollIntoView({ block: "nearest" });
            ListContainer.childNodes.forEach((child, key) => {
                if (child instanceof HTMLElement) {
                    UpdateItemFocusStyle(child, child == nextElementFocus);
                }
            })
            _SetKeyEventCallback("__arrowMoveController_Enter", {
                key: "Enter",
                refElement: nextElementFocus,
                callback: () => OnSelect(nextElementFocus),
            })
            _SetKeyEventCallback("__arrowMoveController_Space", {
                key: " ",
                refElement: nextElementFocus,
                callback: () => OnSelect(nextElementFocus),
            })
            return valid;
        }

        let focusIndex = (typeof StartFocus == "number") ? StartFocus : fnGetElementIndex(StartFocus);
        setTimeout(() => {
            // if (document.activeElement != ListContainer) {
            // (document.activeElement as HTMLElement).blur();
            // }

            if (!config.BlurPrevent) {
                (document.activeElement as HTMLElement).blur();
            }

            if (ListContainer.children[focusIndex]) {
                fnFocus(focusIndex, true);
            }
        })
    }

    export function _RemoveArrowMoveKeyEventCallback(listContainer: IArrowMoveElementList) {
        if (!listContainer.__IDArrowMoveController) return;
        const idBase = listContainer.__IDArrowMoveController;
        UIUtilGlobalKeyEvents._RemoveKeyEventCallback(idBase + "_1");
        UIUtilGlobalKeyEvents._RemoveKeyEventCallback(idBase + "_2");
        listContainer.removeEventListener("mouseover", listContainer.__OnMouseOver)
        UIUtilGlobalKeyEvents._RemoveKeyEventCallback("__arrowMoveController_Enter");
        UIUtilGlobalKeyEvents._RemoveKeyEventCallback("__arrowMoveController_Space");
    }
}
