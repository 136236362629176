import * as d3 from "d3";
import { UIUtilGlobalKeyEvents } from "../util/GlobalKeyEvent";
import { UIUtilGeneral } from "../util/Util";
import { TOptionInMain } from "./MenuFlex";

export interface IConfigDropdown {
    /** Contenedor cuyas medidas son tomadas en cuenta para ubicar al control */
    ReferenceContent: TSelectionHTML<any>
    Data?: Array<Object>
    ConfigOptions?: Array<TOptionInMain>;
    LocationOptions: COpciones;
    /** Permite visualizar el último item seleccionado */
    ShowLastSelect?: boolean;
    MaxHeight?: number;
    Width?: number;
    ZIndexInit?: number;
}
interface IElements {
    DivControl: TSelectionHTML<"div">
    DivGhost: TSelectionHTML<"div">
}

interface IItem {
    enable: boolean;
    item: TOptionInMain;
}

export enum COpciones { OnlyTop = 1, OnlyBottom = 2, TopAndBottom = 3 }

export interface IColorFont { normal: string, disabled: string }

/** @deprecated use DropdownFlexV2 instead */
export class DropdownFlex {
    private elements: IElements;
    private config: IConfigDropdown;
    private items: Map<string | number, IItem>;
    // private colorFont: IColorFont;
    private visible: boolean;
    private observeResize: ResizeObserver;

    constructor() {
        this.elements = <IElements>{}
        this.items = new Map<string | number, IItem>()
        this.visible = false
        // this.colorFont = { normal: "rgb(30, 144, 255)", disabled: "rgba(30, 144, 255, 0.5)" }
        this.Init()
        //this.elements.ctrlsOpcSecundario = new Array()
    }

    private Init() {
        this.elements.DivControl = d3.create<HTMLDivElement>("div")
            .classed("dropdown_container", true)
            .classed("display_none", true)

        this.elements.DivGhost = d3.create<HTMLDivElement>("div")
            .classed("ghost_none", true)
            .classed("display_none", true)
            .on("click", () => this.Hide())
    }

    public _SetConfig(config: IConfigDropdown): this {
        this.config = config
        this.DrawConfig()
        this.AutoUbicate()
        return this
    }

    private DrawConfig() {
        if (this.config.MaxHeight) {
            this.elements.DivControl.style("max-height", this.config.MaxHeight + "px")
            this.elements.DivControl.style("overflow-y", "auto")
        }
        if (this.config.Width) this.elements.DivControl.style("width", this.config.Width + "px")

        if (this.config.ZIndexInit) {
            this.elements.DivGhost.style("z-index", this.config.ZIndexInit)
            this.elements.DivControl.style("z-index", this.config.ZIndexInit + 1)
        }
        // Primer intento de construir opciones
        if (this.config.ConfigOptions) this.UpdateOptions(this.config.ConfigOptions)
    }

    private HabilitarItem(key: string | number, enable: boolean) {
        let item = this.items.get(key)
        item.enable = enable
    }

    private SeleccionarItem(key: string | number, enable: boolean) {
        let item = this.items.get(key)
        item.item.selected = enable
    }

    private AsignaDisabled(key: string | number, item: IItem, element: d3.Selection<HTMLDivElement, {}, HTMLElement, null>): boolean {
        if (item.enable) {
            element
                .on("click", () => {
                    if (item.item.Callback) item.item.Callback(this.config.Data)
                    if (this.config.ShowLastSelect) {
                        this.items.forEach((item, key) => this.SeleccionarItem(key, false))
                        this.SeleccionarItem(key, true)
                        this.DrawConfig()
                    }
                    this.Hide()
                })
                .style("opacity", null);
            return false
        }
        else {
            element
                .on("click", null)
                .style("opacity", .6);
            // element.style("color", this.colorFont.disabled)
            return true
        }
    }

    private DrawConfigOptions() {
        this.elements.DivControl.selectAll(".opcion_item").remove()

        this.items.forEach((item, key) => {
            let itemDiv = this.elements.DivControl.append("div")
                .text(item.item.Label)
                .classed("opcion_item", true)

            itemDiv.classed("opcion_disabled", () => {
                return this.AsignaDisabled(key, item, itemDiv)
            })

            if (item.item.selected) {
                itemDiv.style("background", "var(--color_primary3)");
            }
        })
    }

    private UpdateData(data: Array<Object>) {
        this.config.Data = data
        this.VerifyEnableItems()
        this.DrawConfigOptions()
    }

    private UpdateOptions(confOpc: Array<TOptionInMain>) {
        this.config.ConfigOptions = confOpc
        this.items.clear()
        confOpc.forEach((d, i) => {
            let key = d.key ? d.key : i
            if (this.config.ShowLastSelect) {
                if (!d.selected) d.selected = false
            }
            this.items.set(key, { enable: true, item: d })
        })
        this.VerifyEnableItems()
        this.DrawConfigOptions()
    }

    private Show() {
        UIUtilGlobalKeyEvents._SetEscKeyEventCallback(this.elements.DivGhost.node(), () => this.Hide());
        this.visible = true
        d3.select("body").append(() => this.elements.DivGhost.node())
        d3.select("body").append(() => this.elements.DivControl.node())
        this.elements.DivControl.classed("display_none", false)
        this.elements.DivGhost.classed("display_none", false)
        this.Reubicate()
        this.observeResize?.observe(this.elements.DivGhost.node())
    }
    private Hide() {
        UIUtilGlobalKeyEvents._RemoveEscKeyEventCallback(this.elements.DivGhost.node());
        this.visible = false
        //d3.select("body").select(".ghost_none").remove()
        this.observeResize?.unobserve(this.elements.DivGhost.node())
        this.elements.DivGhost.remove()
        d3.select("body").select(".dropdown_container").remove()
        this.elements.DivControl.classed("display_none", true)
        this.elements.DivGhost.classed("display_none", true)
    }

    private AutoUbicate() {
        this.observeResize = UIUtilGeneral._GetResizeObserver(entries => {
            this.Reubicate();
        })
    }

    private Reubicate() {
        let limits = d3.select("body")
        let body_limits = <HTMLBodyElement>limits.node()
        let box_parent = this.config.ReferenceContent.node().getBoundingClientRect();
        let box_control = this.elements.DivControl.node().getBoundingClientRect();

        let x = box_parent.x
        let y = box_parent.y + box_parent.height + 5

        // Según la configuración de posibles ubicaciones
        if (this.config.LocationOptions === COpciones.TopAndBottom) {
            if (y + box_control.height + 10 > body_limits.offsetHeight) {
                y -= (box_parent.height + box_control.height + 15);
            }
        } else if (this.config.LocationOptions === COpciones.OnlyTop) {
            y -= (box_parent.height + box_control.height + 15);
        }

        if (x + box_control.width + 5 > body_limits.offsetWidth) {
            x -= ((x + box_control.width) - body_limits.offsetWidth);
        }

        this.elements.DivControl
            .style("top", y + "px")
            .style("left", x + "px")
    }

    /** Verifica las opciones que son multidata=false, de acuerdo al número de datos actual */
    private VerifyEnableItems() {
        if (this.config.Data && this.config.Data.length > 0) {
            this.items.forEach((item: IItem, key: string | number,) => {
                if (item.item.OnValidateDisabling) {
                    if (item.item.OnValidateDisabling(this.config.Data)) {
                        this.HabilitarItem(key, true)
                    } else {
                        this.HabilitarItem(key, false)
                    }
                } else {
                    if (item.item.MultiData != undefined && item.item.MultiData != null) {
                        if (!item.item.MultiData) {
                            if (this.config.Data.length > 1) {
                                this.HabilitarItem(key, false)
                                //this.items[i].enable = false
                            } else if (this.config.Data.length === 1) {
                                this.HabilitarItem(key, true)
                                //this.items[i].enable = true
                            }
                        }
                    }
                }
            })
        }
    }

    public _Show() {
        this.Show;
    }

    public _Hide() {
        this.Hide();
    }

    public _UpdateData(data: Array<Object>) {
        this.UpdateData(data)
    }

    public _UpdateOptions(confOpcs: Array<TOptionInMain>) {
        this.UpdateOptions(confOpcs)
    }

    public _EnableItem(key: string | number, enable: boolean) {
        this.HabilitarItem(key, enable)
    }

    get _ListContainer() {
        return this.elements.DivControl
    }

    get _IsVisible() {
        return this.visible;
    }

}
