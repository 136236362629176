const TRANSITIONDURATION = 300;
const STATUSPREFIX = "status-";
const TEMPLATE = document.createElement("template");
TEMPLATE.innerHTML = `
<style>
    :host {
        display: inline-block;
        min-width: 5px;
        min-height: 5px;
        width: 18px;
        white-space: normal;
        cursor: pointer;
        /* height: 18px; */
    }

    /* DEFAULT STYLE */

    .icon_toggle {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .icon_toggle>* {
        transition: transform ${TRANSITIONDURATION}ms;
        width: 100%;
    }

    .disabled {
        opacity: .8;
        pointer-events: none;
        cursor: default;
    }
</style>

<div class="icon_toggle"></div>
`;

export class HTMLIconToggleElement extends HTMLElement {
    #controlContainerElement: HTMLDivElement;
    #disabled: boolean;
    /** Map<AttributeKey, IconPath> */
    #statusAvailables: Map<string, HTMLElement>;

    // #evOnchange: (e: Event) => void;

    constructor() {
        super();
        this.attachShadow({ mode: "open" });

        this.shadowRoot.appendChild(TEMPLATE.content.cloneNode(true));

        this.#controlContainerElement = this.shadowRoot.querySelector(".icon_toggle");

        this.#disabled = false;

        if (!this.#statusAvailables) {
            this.#statusAvailables = new Map();
        }
    }

    // *****************************************************************************
    // PRIVATE METHODS
    // *****************************************************************************

    private ResetContentStatus() {
        this.#controlContainerElement
            .childNodes.forEach((e) => e.remove());
    }

    private CheckStatusAvailables() {
        Array.from(this.attributes)
            .forEach(attr => {
                if (attr.name.startsWith(STATUSPREFIX)) {
                    const statusKey = attr.name.replace(STATUSPREFIX, "");
                    if (this.hasAttribute(attr.name)) {
                        let element: HTMLImageElement = this.#statusAvailables.get(statusKey) as any;
                        if (!element) {
                            element = document.createElement("img");
                            element.draggable = false;
                        }
                        if (element.src != attr.value) {
                            element.src = attr.value;
                        }
                        this.#statusAvailables.set(statusKey, element);
                    } else {
                        this.#statusAvailables.delete(statusKey);
                    }
                    // this.ApplyStatusIcon();
                }
            })
    }

    protected ApplyStatusIcon() {
        this.CheckStatusAvailables();
        this.ResetContentStatus();
        if (this.hasAttribute("status")) {
            const statusKey = this.getAttribute("status");
            if (statusKey) {
                const statusContent = this.#statusAvailables.get(statusKey);
                if (statusContent) {
                    this.#controlContainerElement.appendChild(statusContent);
                } else {
                    console.warn("status icon no found");
                }
            }
        }
    }

    private Data_SetStatus(status: string) {
        if (status) {
            this.setAttribute("status", status);
        } else {
            this.removeAttribute("status");
        }
    }

    // private OnChange(e: Event) {
    //     if (this.#evOnchange) {
    //         this.#evOnchange(e);
    //     }
    // }

    // *****************************************************************************
    // ELEMENT LIFE CICLE CALLBACKS
    // *****************************************************************************

    // private onClickEvent: (e: MouseEvent) => void;

    /** Invocado cuando el componente personalizado se conecta por primera vez al DOM del documento. */
    connectedCallback() {
        // this.onClickEvent = (e) => {
        //     if (!this.#disabled) {
        //         this.Data_SetStatus(this.getAttribute("status"));
        //         this.OnChange(e);
        //     }
        //     e.stopPropagation();
        // }

        // this.#controlContainerElement.addEventListener("click", this.onClickEvent);
    }

    /** Invocado cuando el componente personalizado se deconecta del DOM del documento. */
    disconnectedCallback() {
        // this.#controlContainerElement.removeEventListener("click", this.onClickEvent);
    }

    /** Invocado cuando el componente personalizado se mueve a un nuevo documento. */
    adoptedCallback(oldDocument: Document, newDocument: Document) {
    }

    /** Invocado cuando uno de los atributos del componente personalizado es añadido, removido o modificado. */
    attributeChangedCallback(attrName: string, oldValue: string, newValue: string) {
        switch (attrName) {
            case "disabled":
                this.#disabled = this.hasAttribute("disabled");

                if (this.#disabled) {
                    this.#controlContainerElement.classList.add("disabled");
                }
                else {
                    this.#controlContainerElement.classList.remove("disabled");
                }
                break;
            case "status":
                this.ApplyStatusIcon();
                break;
        }
    }

    static get observedAttributes() {
        return ['disabled', 'status'];
    }

    // *****************************************************************************
    // PUBLIC PROPERTIES
    // *****************************************************************************

    public get _Status() {
        return this.getAttribute("status");
    }

    public set _Status(value: string) {
        this.Data_SetStatus(value?.toString());
    }

    public get _Disabled() {
        return this.#disabled;
    }

    public set _Items(items: [string, HTMLElement][]) {
        this.#statusAvailables.clear();
        items.forEach((item) => {
            this.#statusAvailables.set(item[0], item[1]);
        });
        this.ApplyStatusIcon();
    }

    // public set prop_OnChange(fn: (e: Event) => void) {
    //     this.#evOnchange = fn;
    // }
}

customElements.define("wc-icon-toggle", HTMLIconToggleElement);
