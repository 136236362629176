import * as d3 from "d3";
import { DataDRequest } from "../../data/DRequest";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import DataModuloCircular from "../../data/modulo/Noticia";
import DataModuloEscolaridad from "../../data/modulo/Escolaridad";
import DataModuloEscuela from "../../data/modulo/Escuela";
import DataModuloGrado from "../../data/modulo/Grado";
import DataModuloGrupo from "../../data/modulo/Grupo";
import { DateV2 } from "../../util/DateV2";
import { ComponentCircleItems } from "../controlD3/ComponentCircleItems";
import { Fields, FormGenerator, IField } from "../controlD3/Formulario";
import { FullView } from "../controlD3/FullView";
import { FullViewFile } from "../controlD3/FullViewFile";
import { InputFileControl } from "../controlD3/InputFileControlV2";
import { ModalThings } from "../controlD3/ModalThings";
import { NotificacionV2 } from "../controlD3/NotificacionV2";
import { SelectV2 } from "../controlD3/SelectV2";
import { UIUtilIconResources } from "../util/IconResourses";
import { UIUtilLang } from "../util/Language";
import { UIUtilPermission } from "../util/Permission";
import { UIUtilStrings } from "../util/Strings";
import { UIUtilTime } from "../util/Time";
import { UIUtilGeneral } from "../util/Util";

export namespace UIUtilViewCircular {
    import IInfoArchivo = Entidad.IInfoArchivo;
    import ICircular = Entidad.ICircular;
    import CAutorizado = Entidad.CCircularEstadoAutorizacion;

    const coloresDisponibles = ["#ffd92f", "#17becf", "#d0e6a5", "#ff9190", "#ccabda", "#ffbe72", "#e29135", "#62c245", "#ff4d4d", "#45c2a7",
        "#5b5dff", "#a53fe0", "#c24598", "#b6c245", "#90aca7", "#df9aff", "#9aca14", "#d87c31"];


    export interface ICircularChildDestino {
        // /** IdAlumno-IdGrado,... */
        IdAlumno: number;
        Alumno: Entidad.IAlumno;
        /** // NOTE Los items tendrán el mismo estado de Autorización (debería) */
        GruposInfoAutorizacion: ICircularChildDestinoGrupo[]
        // ReqAutorizacion: boolean;
    }

    export interface ICircularChildDestinoGrupo {
        IdGrupo: number;
        Grupo?: Entidad.IGrupo;
        Autorizado?: CAutorizado | null;
    }
    export interface ICircularGradoDestino {
        IdGrado: number;
        // IdEscolaridad: number;
        MapGrupos: Map<number, Entidad.IGrupo>;
        StrEscolaridad: string;
        StrGrado: string;
        /** En caso de ser más de uno, se separan por "," */
        StrGrupos: string;
    }

    // *********************************************************************
    // Form
    // *********************************************************************

    const minExtraSinceCurrentTime = 5;
    export interface ICircularForm extends ICircular {
        // IdsGrados: number[];
        Archivo: string | File;
        ProgramadoAux: boolean;
        ExpiraAux: boolean;
        IdsEscolaridad?: number[];
    }

    /** El formulario solo es editable si, la acción es Agregar o el registro no ha sido procesado (la fecha Programada es mayor a la fecha actual)
     * * Titulo
     * * Mensaje
     * * Contenido - Opcional
     * * Escuela
     * * Nivel (Escolaridad)
     * * Grados
     * * Grupos
     * * Programación (1.- Check "Programar" - Opcional, 2.- (Input Fecha, Input Hora) - Requerido solo si Programar is Checked).
     * * Incluir Botones de respuesta SI NO - Opcional
     * @param module
     * @param action
     * @param notificador
     * @param typeForm
     * @param datoC El objeto es clonado antes de asignar al Formulario
     * @returns
     */
    export function _GetNoticeForm(module: (Entidad.CModulo.Circulares | Entidad.CModulo.PanelCircular), action: (Entidad.CAccionPermiso.Agregar | Entidad.CAccionPermiso.Editar), typeForm: ("grid" | "childPanel"), datoC = <ICircular>{}) {
        let formEsEditable = (action == Entidad.CAccionPermiso.Agregar || (datoC.Programado && new Date(datoC.Programado) >= new Date()))
        let escuelasPermission: Entidad.IEscuela[] = [];
        if (typeForm == "grid") {
            escuelasPermission = UIUtilPermission._GetSchoolsByActionModule(module, action);
        }
        if (action == Entidad.CAccionPermiso.Editar && !escuelasPermission.find(d => (d.IdKinder == datoC.IDKinder)) && DataModuloEscuela._DiccEscuela.has(datoC.IDKinder)) {
            escuelasPermission.push(DataModuloEscuela._DiccEscuela.get(datoC.IDKinder));
        }

        let datoCircularForm: ICircularForm = Object.assign(<ICircularForm>{
            Niveles: [],
            Grupos: [],
            IdsEscolaridad: [],
            ProgramadoAux: Boolean(datoC.Programado),
            ExpiraAux: Boolean(datoC.Expira)
        }, datoC);

        let fn_OnChangeCheckboxToSchedule = (checkedProgramer: boolean) => {
            let dateContainerElement = form._ControlsData.get("ProgramadoAux").row
                .select(":scope > .input_content");
            let inputDateValue: DateV2 = null;
            let minValidDate = "";

            if (formEsEditable) {
                minValidDate = UIUtilTime._FmtToInputDate(new Date());
            }

            let inputDateSendElement = dateContainerElement
                .select<HTMLInputElement>("input[type='date']")
                .attr("min", minValidDate)
                .node();
            let inputTimeSendElement = dateContainerElement
                .select<HTMLInputElement>("input[type='time']")
                .node();

            dateContainerElement.classed("hide", !checkedProgramer);

            inputDateSendElement.required = true;
            inputTimeSendElement.required = true;
            if (!checkedProgramer) {
                inputDateSendElement.value = null;
                inputTimeSendElement.value = null;
                inputDateSendElement.required = false;
                inputTimeSendElement.required = false;
                form._DataOrigin.Programado = null;
                // console.log("Programado no checkeado");
            }
            else if (form._DataOrigin.Programado) {
                // console.log("Programado checkeado", form.prop_DataOrigin.Programado)
                inputDateValue = new DateV2(form._DataOrigin.Programado)._SetTimeZoneByIdSchool(form._DataOrigin.IDKinder);
                inputDateSendElement.value = UIUtilTime._FmtToInputDate(inputDateValue);
                inputTimeSendElement.value = UIUtilTime._FmtToInputTime(inputDateValue);
            }
            else if (action == Entidad.CAccionPermiso.Agregar) {
                // console.log("Se está dando de alta y no hay fecha seleccionada por el usuario");
                inputDateValue = new DateV2(); // Now date
                inputDateValue.setHours(inputDateValue.getHours() < 23 ? inputDateValue.getHours() + 1 : inputDateValue.getHours());
                inputDateSendElement.value = UIUtilTime._FmtToInputDate(inputDateValue);
                inputTimeSendElement.value = UIUtilTime._FmtToInputTime(inputDateValue);
            }
            fn_OnChangeDateInputToSchedule(inputDateValue);
        }

        let fn_OnChangeDateInputToSchedule = (inpScheduleDt: DateV2) => {
            fn_OnChangeTimeInputToSchedule(inpScheduleDt);
        }

        /** Evalua la fecha (solo yyyy-mm-dd) para actualizar la hora minima del control de Tiempo (hh:mm)
         * * Si es el dia actual, la hora min será la actual, sino será null
         * * Al ser el último evento que se actualiza despues de que el checkbox, input[date] o  input[time] cambian de valor,
         * también actualiza la propiedad "Programado" del data Original del formulario
         */
        let fn_OnChangeTimeInputToSchedule = (inpScheduleDt?: DateV2) => {
            let dateContainerElement = form._ControlsData.get("ProgramadoAux").row
                .select(":scope > .input_content");
            const nowDate = new Date();
            /** yyyy-mm-dd */
            let dateToSendValue: string;
            /** hh:mm */
            let timeToSendValue: string;

            if (!inpScheduleDt) {
                dateToSendValue = dateContainerElement
                    .select<HTMLInputElement>("input[type='date']")
                    .node()
                    .value;

                // inpScheduleDt = new Date(dateToSendValue + " 00:00");
                inpScheduleDt = new DateV2(UIUtilTime._GetDateConcatenatedDateTime(dateToSendValue));
            }
            else if (!isNaN(Number(inpScheduleDt))) {
                dateToSendValue = UIUtilTime._FmtToInputDate(inpScheduleDt);
            }

            let inputTimeSendElement = dateContainerElement
                .select<HTMLInputElement>("input[type='time']")
                .node();

            timeToSendValue = inputTimeSendElement.value;

            inputTimeSendElement.min = "";
            if (!isNaN(Number(inpScheduleDt)) && timeToSendValue != '') {
                if (inpScheduleDt.getFullYear() == nowDate.getFullYear() && inpScheduleDt.getMonth() == nowDate.getMonth() && inpScheduleDt.getDate() == nowDate.getDate()) {
                    // La fecha de programación coincide con el día actual
                    if ((nowDate.getHours() * 60 + nowDate.getMinutes()) < (23 * 60 + 55)) {
                        nowDate.setMinutes(nowDate.getMinutes() + minExtraSinceCurrentTime);
                    }
                    // FIXME Durante la edición no se toma en cuenta la zona horaria
                    // NOTE Hay conflicto si se está editando en una zona horaria diferente a la de la escuela seleccionada
                    inputTimeSendElement.min = UIUtilTime._FmtToInputTime(nowDate);
                }
                // form.prop_DataOrigin.Programado = new Date(dateToSendValue + " " + timeToSendValue)?.toISOString();
                form._DataOrigin.Programado = UIUtilTime._GetDateConcatenatedDateTime(dateToSendValue, timeToSendValue).toISOString();
            }
            else {
                form._DataOrigin.Programado = null;
            }
        }

        let actualMinDate: Date;

        let fn_GetDateAndTimeExpireInputs = () => {
            let dateContainerElementExp = form._ControlsData.get("ExpiraAux").row
                .select(":scope > .input_content");
            let inputDateSendElement = dateContainerElementExp
                .select<HTMLInputElement>("input[type='date']")
                .node();
            let inputTimeSendElement = dateContainerElementExp
                .select<HTMLInputElement>("input[type='time']")
                .node();
            return {
                container: dateContainerElementExp,
                inputDate: inputDateSendElement,
                inputTime: inputTimeSendElement
            }
        }

        // Con Input Date & Time fn_OnChangeCheckboxExpire
        // let fn_OnChangeCheckboxExpire = (checkedExpire: boolean) => {
        //     let inputDateValue: DateDecorator = null;
        //     const inputs = fn_GetDateAndTimeExpireInputs();
        //     inputs.container.classed("hide", !checkedExpire);
        //     inputs.inputDate.required = checkedExpire;
        //     inputs.inputTime.required = checkedExpire;
        //     if (!checkedExpire) {
        //         inputs.inputDate.value = null;
        //         inputs.inputTime.value = null;
        //         form.prop_DataOrigin.Expira = null;
        //     } else if (form.prop_DataOrigin.Expira) {
        //         inputDateValue = new DateDecorator(form.prop_DataOrigin.Expira).met_SetTimeZoneByIdSchool(form.prop_DataOrigin.IDKinder);
        //         inputs.inputDate.value = UIUtilTime._FmtToInputDate(inputDateValue);
        //         inputs.inputTime.value = UIUtilTime._FmtToInputTime(inputDateValue);
        //     } else if (action == Entidad.CAccionPermiso.Agregar) {
        //         const suggestedDate = new Date(actualMinDate);
        //         suggestedDate.setDate(suggestedDate.getDate() + 1);
        //         inputs.inputDate.value = UIUtilTime._FmtToInputDate(suggestedDate);
        //         inputs.inputTime.value = UIUtilTime._FmtToInputTime(suggestedDate);
        //     }
        // }

        let fn_OnChangeCheckboxExpire = (checkedExpire: boolean) => {
            let inputDateValue: DateV2 = null;
            const inputs = fn_GetDateAndTimeExpireInputs();
            inputs.container.classed("hide", !checkedExpire);
            inputs.inputDate.required = checkedExpire;
            if (!checkedExpire) {
                inputs.inputDate.value = null;
                form._DataOrigin.Expira = null;
            } else if (form._DataOrigin.Expira) {
                inputDateValue = new DateV2(form._DataOrigin.Expira)._SetTimeZoneByIdSchool(form._DataOrigin.IDKinder);
                inputs.inputDate.value = UIUtilTime._FmtToInputDate(inputDateValue);
            } else if (action == Entidad.CAccionPermiso.Agregar) {
                const suggestedDate = new Date(actualMinDate);
                // suggestedDate.setDate(suggestedDate.getDate() + 1);
                inputs.inputDate.value = UIUtilTime._FmtToInputDate(suggestedDate);
            }
        }

        // Actualización de Fecha Mínima con input Time
        // let fn_UpdateMinExpireDate = () => {
        //     actualMinDate = form.prop_Data.Programado ? new Date(form.prop_Data.Programado) : new Date();
        //     actualMinDate.setHours(actualMinDate.getHours() + 1);
        // }


        let fn_UpdateMinExpireDate = () => {
            actualMinDate = form._Data.Programado ? new Date(form._Data.Programado) : new Date();
            actualMinDate.setDate(actualMinDate.getDate() + 1);
        }

        let fn_ApplyMinValueToInputDateExpira = () => {
            let minValidDate = UIUtilTime._FmtToInputDate(actualMinDate);
            const inputs = fn_GetDateAndTimeExpireInputs();
            inputs.inputDate.min = form._Data.ExpiraAux ? minValidDate : "";
        }

        // Aplica mínimo a input Time
        // let fn_ApplyMinValueToInputTimeExpira = () => {
        //     let inpScheduleDt: DateDecorator;
        //     /** yyyy-mm-dd */
        //     let dateToSendValue: string;
        //     const inputs = fn_GetDateAndTimeExpireInputs();
        // 
        //     dateToSendValue = inputs.inputDate.value;
        //     inpScheduleDt = new DateDecorator(UIUtilTime.fn_GetDateConcatenatedDateTime(dateToSendValue));
        // 
        //     inputs.inputTime.min = "";
        // 
        //     if (!isNaN(Number(inpScheduleDt))) {
        //         if (inpScheduleDt.getFullYear() == actualMinDate.getFullYear() && inpScheduleDt.getMonth() == actualMinDate.getMonth() && inpScheduleDt.getDate() == actualMinDate.getDate()) {
        //             inputs.inputTime.min = UIUtilTime._FmtToInputTime(actualMinDate);
        //         }
        //     }
        // }

        // Construye fecha con input Time
        // let fn_BuildExpireDate = () => {
        //     let inpScheduleDt: DateDecorator;
        //     /** yyyy-mm-dd */
        //     let dateToSendValue: string;
        //     /** hh:mm */
        //     let timeToSendValue: string;
        //     const inputs = fn_GetDateAndTimeExpireInputs();
        // 
        //     dateToSendValue = inputs.inputDate.value;
        //     inpScheduleDt = new DateDecorator(UIUtilTime.fn_GetDateConcatenatedDateTime(dateToSendValue));
        //     timeToSendValue = inputs.inputTime.value;
        //     if (!isNaN(Number(inpScheduleDt)) && timeToSendValue != '') {
        //         form.prop_DataOrigin.Expira = UIUtilTime.fn_GetDateConcatenatedDateTime(dateToSendValue, timeToSendValue).toISOString();
        //     } else {
        //         form.prop_DataOrigin.Expira = null;
        //     }
        //     console.log(form.prop_Data.Expira, form.prop_DataOrigin.Expira)
        // }

        let fn_BuildExpireDate = () => {
            let inpScheduleDt: DateV2;
            /** yyyy-mm-dd */
            let dateToSendValue: string;
            /** hh:mm */
            const inputs = fn_GetDateAndTimeExpireInputs();

            dateToSendValue = inputs.inputDate.value;
            inpScheduleDt = new DateV2(UIUtilTime._GetDateConcatenatedDateTime(dateToSendValue));
            if (!isNaN(Number(inpScheduleDt))) {
                form._DataOrigin.Expira = new DateV2(UIUtilTime._GetDateConcatenatedDateTime(dateToSendValue))._SetTimeZoneByIdSchool(form._Data.IDKinder, true)._ToISOString();
            } else {
                form._DataOrigin.Expira = null;
            }
        }

        let fn_GrupoHasAlumnosActivos = (grupo: Entidad.IGrupo) => {
            let alumnosEnGrupo = DataModuloGrupo._LOCALDATA_GetAlumnosEnGrupo(grupo.IdGrupo);
            if (alumnosEnGrupo.size > 0) {
                for (let alumno of alumnosEnGrupo.values()) {
                    if (alumno.IdChildMovimiento == Entidad.CNinioMovimiento.Activo) {
                        return true;
                    }
                }
            }
            return false;
        }

        datoCircularForm.Niveles?.forEach(idGrado => {
            // if (!datoCircularForm.IdsEscolaridad) {
            let getGrado = DataModuloGrado._DiccGrado.get(idGrado);
            if (getGrado && datoCircularForm.IdsEscolaridad.indexOf(getGrado.IdEscolaridad) == -1) {
                datoCircularForm.IdsEscolaridad.push(getGrado.IdEscolaridad);
            }
            // datoCircularForm.IdsEscolaridad = data.modulos.Nivel.DiccNivel.get(idGrado)?.IdEscolaridad;
            // }
        })
        if (!datoCircularForm.IdsEscolaridad.length && action == Entidad.CAccionPermiso.Editar) {
            console.warn("-d", "IdEscolaridad no encontrada, niveles no válidos... Ids Grados", datoCircularForm.Niveles);
        }

        let form = new FormGenerator<ICircularForm>();
        let scheme: IField<ICircularForm>[];

        scheme = [
            {
                type: Fields.input,
                inputAttr: { type: "text", maxlength: 100, disabled: !formEsEditable, required: true },
                labelAttr: { text: "d_field_titulo" },
                model: "Titulo"
            },
            {
                type: Fields.textArea,
                inputAttr: { class: "input-form", disabled: !formEsEditable },
                labelAttr: { text: "d_field_mensaje" },
                model: "Mensaje"
            },
            {
                type: Fields.fotoV2,
                labelAttr: { text: "tag_content" },
                fotoV2Attrs: {
                    ControlForm: InputFileControl.ControlForm.Semicuadrado,
                    TypeValue: "File",
                    AspectRatio: "contain",
                    Disabled: (action != Entidad.CAccionPermiso.Agregar),
                    // DefaultBackgroundImage: Utils.CIconResource.Folder,
                    ShowAreaControlOptions: (action == Entidad.CAccionPermiso.Agregar),
                    FilesAccept: [
                        "image/jpg", "image/png", "image/svg", "image/jpeg",
                        "application/pdf", "application/msword", "application/vnd.ms-powerpoint", "application/vnd.ms-excel",
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/vnd.openxmlformats-officedocument.presentationml.presentation", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                        // "text/plain", "application/epub+zip"
                    ],
                    OnValideBeforeRender: async (isImage, isFromInput, file, src) => {
                        if (isFromInput) {
                            if (isImage) {
                                return await fn_GetAcceptImage(src);
                            } else {
                                return true;
                            }
                        }
                        return true;
                    }
                },
                model: "Archivo"
            },
            {
                type: Fields.input,
                inputAttr: { type: "checkbox", disabled: !formEsEditable },
                labelAttr: { text: "tag_programar" },
                model: "ProgramadoAux"
            },
            {
                type: Fields.input,
                inputAttr: { type: "checkbox", disabled: !formEsEditable },
                labelText: "Expira",
                model: "ExpiraAux"
            },
            {
                type: Fields.input,
                inputAttr: { type: "checkbox", disabled: !formEsEditable },
                labelAttr: { text: "tag_includebtns", class: "titulos_base" },
                model: "ReqAutorizacion"
            },
        ]

        if (typeForm == "grid") {
            scheme.push(
                {
                    type: Fields.selectMaterial,
                    labelAttr: { text: "d_field_nombrekinder" },
                    model: "IDKinder",
                    selectMaterialAttr: {
                        valueMember: "IdKinder", displayMember: "Nombre",
                        disabled: !(action == Entidad.CAccionPermiso.Agregar),
                        removeBorder: !(action == Entidad.CAccionPermiso.Agregar),
                        onSelect: (value: Entidad.IEscuela) => {
                            let comboEscolaridad = form._ControlsData.get("IdsEscolaridad").instance as SelectV2<Entidad.IEscolaridad, "Id">;

                            if (value) {
                                let escolaridadesF = Array.from(DataModuloEscolaridad._DiccEscolaridad.values())
                                    .filter(d => (d.IdEscuela == value.IdKinder));
                                let itemsToDisable = escolaridadesF
                                    .filter(d => (DataModuloEscolaridad._LOCALDATA_GetGradosDeEscolaridad(d.Id).size == 0))
                                    .map(d => d.Id)

                                comboEscolaridad._UpdateList(escolaridadesF);
                                comboEscolaridad._disableItems(itemsToDisable);
                            } else {
                                comboEscolaridad._UpdateList([]);
                            }
                        }, required: true
                    },
                    values: escuelasPermission
                },
                {
                    type: Fields.selectMaterial,
                    labelAttr: { text: "tag_nivel" },
                    model: "IdsEscolaridad",
                    selectMaterialAttr: {
                        valueMember: "Id", displayMember: "Nombre",
                        disabled: !(action == Entidad.CAccionPermiso.Agregar),
                        removeBorder: !(action == Entidad.CAccionPermiso.Agregar),
                        OnStepItemListUI: (container, escolaridad: Entidad.IEscolaridad, step) => {
                            container.classed(UIUtilGeneral.FBoxOrientation.Vertical, true)
                                .style("align-items", "flex-start")
                                .html(`<label>${escolaridad.Nombre}</label>`
                                    + (DataModuloEscolaridad._LOCALDATA_GetGradosDeEscolaridad(escolaridad.Id).size == 0 ? "<label style='color:red;font-size:var(--fontsize_me4);'>Sin grados registrados</label>" : "") // FIX_LANGUAGE
                                )
                        },
                        onSelect: (value: Entidad.IEscolaridad[]) => {
                            let gradosComponent = form._ControlsData.get("Niveles").instance as unknown as ComponentCircleItems<Entidad.IGrado, "IdNivel">;
                            if (value) {
                                let grados = Array.from(DataModuloGrado._DiccGrado.values())
                                    .filter(dGrado => (value.find(dEsc => (dGrado.IdEscolaridad == dEsc.Id))))

                                gradosComponent
                                    ._UpdateItems(grados)
                                    ._UI_RefreshCirculos();
                            } else {
                                gradosComponent
                                    ._UpdateItems([])
                                    ._UI_RefreshCirculos();
                            }
                        },
                        multiselect: true,
                        ShowNSelectedInList: true,
                        required: true
                    },
                    values: [] //this_c.escolaridad//[]
                },
                {
                    type: Fields.label,
                    labelAttr: { text: "tag_grados" },
                    model: "Niveles"
                },
                {
                    type: Fields.label,
                    labelAttr: { text: "tag_grupos" },
                    model: "Grupos"
                }
            )
        }
        const maxWidthLabel = 150;
        form._Crear({
            schema: scheme,
            LabelMaxWidth: maxWidthLabel,
            LangModuleKeyInContext: Entidad.CModulo[Entidad.CModulo.Circulares],
            BuildView: (container, controlsForm, form) => {
                const fnAppendRow = (model: keyof ICircularForm) => {
                    let control = controlsForm.get(model);

                    if (control) {
                        // Personalización de controles faltantes: Grados, Grupos, Programar, ReqAutorizacion
                        switch (model) {
                            case "Niveles":
                                control.row.classed(UIUtilGeneral.FBoxOrientation.Horizontal, true);

                                let componentGrados = new ComponentCircleItems<Entidad.IGrado, "IdNivel">({
                                    IdData: "IdNivel",
                                    OnGetCircleTag: (dato) => dato.Nombre,
                                    OnGetContentToTooltip: (dato) => ([
                                        [UIUtilLang._GetUIString(Entidad.CModulo[Entidad.CModulo.Circulares], "tag_nivel"), DataModuloEscolaridad._DiccEscolaridad.get(dato.IdEscolaridad)?.Nombre],
                                        [UIUtilLang._GetUIString(Entidad.CModulo[Entidad.CModulo.Circulares], "d_field_strgrado"), dato.Nombre]
                                    ]),
                                    // OnMouseEnter: (dato) => console.log("Grado", dato.IdNivel, dato),
                                    OnMouseClick: (dato) => {
                                        let gruposEnGrado = DataModuloGrado._LOCALDATA_GetGruposEnGrado(dato.IdNivel)
                                        if (!gruposEnGrado.size && componentGrados._ItemHasSelected(dato.IdNivel)) {
                                            let escolaridad = DataModuloEscolaridad._DiccEscolaridad.get(dato.IdEscolaridad);
                                            NotificacionV2._Mostrar(UIUtilLang._GetUIString("circulares", "notif_gradosingrupos").replace("_NOMBRE", `<b>${`${dato.Nombre} (${escolaridad?.Nombre})`}</b>`), "ADVERTENCIA");
                                            componentGrados._SelectItems(false, dato.IdNivel)
                                                ._UI_RefreshCirculos();
                                        }
                                    },
                                    OnSelectItemsEvent: (gradosSeleccionados, originEvent) => {
                                        let grupos: Entidad.IGrupo[] = [];
                                        let gruposComponent = controlsForm.get("Grupos").instance as unknown as ComponentCircleItems<Entidad.IGrupo, "IdGrupo">;

                                        // console.log("Grados seleccionados...", gradosSeleccionados.map(d => d.IdNivel));

                                        gradosSeleccionados.forEach(grado => {
                                            DataModuloGrupo._DiccGrupo.forEach(grupo => {
                                                if (grupo.IdNivel == grado.IdNivel) {
                                                    grupos.push(grupo);
                                                }
                                            })
                                        })

                                        gruposComponent?._UpdateItems(grupos)
                                            ._UI_RefreshCirculos();

                                        form._DataOrigin.Niveles = gradosSeleccionados.map(d => d.IdNivel);
                                    },
                                    Colores: coloresDisponibles,
                                    Parent: control.row.node()
                                })

                                componentGrados._EnableControl = (action == Entidad.CAccionPermiso.Agregar);
                                componentGrados._ControlContainer
                                    .style("height", "100px")
                                    .style("max-width", "330px")
                                    .style("border-bottom", "1px var(--color_action1) solid");

                                control.instance = componentGrados as any;
                                control.selection = componentGrados._ControlContainer;
                                break;
                            case "Grupos":
                                control.row.classed(UIUtilGeneral.FBoxOrientation.Horizontal, true);

                                let componentGrupos = new ComponentCircleItems<Entidad.IGrupo, "IdGrupo">({
                                    IdData: "IdGrupo",
                                    OnGetCircleTag: (dato) => dato.Nombre,
                                    OnGetContentToTooltip: (dato) => ([
                                        [UIUtilLang._GetUIString(Entidad.CModulo[Entidad.CModulo.Circulares], "tag_nivel"), DataModuloEscolaridad._DiccEscolaridad.get(DataModuloGrado._DiccGrado.get(dato.IdNivel)?.IdEscolaridad)?.Nombre],
                                        [UIUtilLang._GetUIString(Entidad.CModulo[Entidad.CModulo.Circulares], "d_field_strgrado"), dato.NombreGrado],
                                        [UIUtilLang._GetUIString(Entidad.CModulo[Entidad.CModulo.Circulares], "d_field_strgrupos"), dato.Nombre]
                                    ]),
                                    // OnMouseEnter: (dato) => console.log("Grupo", dato.IdGrupo, dato),
                                    OnMouseClick: (dato) => {
                                        // Solo se valida que tenga alumnos si la circular no se ha programado
                                        if (!form._OnlyFrmData.ProgramadoAux && !fn_GrupoHasAlumnosActivos(dato) && componentGrupos._ItemHasSelected(dato.IdGrupo)) {
                                            NotificacionV2._Mostrar(UIUtilLang._GetUIString("circulares", "notif_gruposinalumnos").replace("_NOMBRE", `<b>${dato.Nombre}</b>`), "ADVERTENCIA");
                                            componentGrupos._SelectItems(false, dato.IdGrupo);
                                        }
                                    },
                                    OnSelectItemsEvent: (gruposSeleccionados, originEvent) => {
                                        // console.log("Grupos seleccionados...", gruposSeleccionados.map(d => d.IdGrupo));
                                        form._DataOrigin.Grupos = gruposSeleccionados.map(d => d.IdGrupo);
                                    },
                                    Colores: coloresDisponibles,
                                    Parent: control.row.node()
                                })

                                componentGrupos._EnableControl = (action == Entidad.CAccionPermiso.Agregar);
                                componentGrupos._ControlContainer
                                    .style("height", "100px")
                                    .style("max-width", "330px")
                                    .style("border-bottom", "1px var(--color_action1) solid");

                                control.instance = componentGrupos as any;
                                control.selection = componentGrupos._ControlContainer;
                                break;
                            case "ProgramadoAux":
                                control.row
                                    .style("flex-wrap", "wrap")
                                    .select(".input_content")
                                    .style("width", "30px")
                                    .select<HTMLInputElement>("input")
                                    .style("box-shadow", "none", "important") // Nunca indicar que el checkbox tiene error cuando "Programado" no sea válido
                                    .node()
                                    .onchange = e => {
                                        let checkedProgramer = (e.target as HTMLInputElement).checked;
                                        fn_OnChangeCheckboxToSchedule(checkedProgramer);
                                        fn_UpdateMinExpireDate();
                                        fn_ApplyMinValueToInputDateExpira();
                                        // fn_ApplyMinValueToInputTimeExpira();
                                    }

                                let dateContainerElement = control.row.append("div")
                                    .classed("input_content", true)
                                    .style("width", "100%")
                                    .style("gap", "var(--padding1)")

                                dateContainerElement.append("div")
                                    .style("max-width", `${maxWidthLabel}px`)
                                    .style("flex", "100%")

                                dateContainerElement.append("input")
                                    .attr("disabled", !formEsEditable || null)
                                    .attr("type", "date")
                                    .node()
                                    .onchange = e => {
                                        const dateSelected = new DateV2(UIUtilTime._GetDateConcatenatedDateTime((e.target as HTMLInputElement).value));
                                        fn_OnChangeDateInputToSchedule(dateSelected);
                                        fn_UpdateMinExpireDate();
                                        fn_ApplyMinValueToInputDateExpira();
                                        // fn_ApplyMinValueToInputTimeExpira();
                                    }

                                dateContainerElement.append("input")
                                    .attr("disabled", !formEsEditable || null)
                                    .attr("type", "time")
                                    // .attr("step", "1800") // saltos de 30 min
                                    .node()
                                    .onchange = e => {
                                        fn_OnChangeTimeInputToSchedule();
                                        fn_UpdateMinExpireDate();
                                        fn_ApplyMinValueToInputDateExpira();
                                        // fn_ApplyMinValueToInputTimeExpira();
                                    }

                                break;
                            case "ExpiraAux":
                                control.row
                                    .style("flex-wrap", "wrap")
                                    .select(".input_content")
                                    .style("width", "30px")
                                    .select<HTMLInputElement>("input")
                                    .style("box-shadow", "none", "important") // Nunca indicar que el checkbox tiene error cuando "Programado" no sea válido
                                    .node()
                                    .onchange = e => {
                                        let checkedExpire = (e.target as HTMLInputElement).checked;
                                        fn_OnChangeCheckboxExpire(checkedExpire);
                                        fn_ApplyMinValueToInputDateExpira();
                                    }
                                let expDateContainerElement = control.row.append("div")
                                    .classed("input_content", true)
                                    .style("width", "100%")
                                    .style("gap", "var(--padding1)")

                                expDateContainerElement.append("div")
                                    .style("max-width", `${maxWidthLabel}px`)
                                    .style("flex", "50%")
                                expDateContainerElement.append("input")
                                    .attr("disabled", !formEsEditable || null)
                                    .attr("type", "date")
                                    .node()
                                    .onchange = e => {
                                        fn_UpdateMinExpireDate();
                                        // fn_ApplyMinValueToInputTimeExpira();
                                        fn_BuildExpireDate();
                                    }

                                // expDateContainerElement.append("input")
                                //     .attr("disabled", !formEsEditable || null)
                                //     .attr("type", "time")
                                //     .node()
                                //     .onchange = e => {
                                //         fn_BuildExpireDate();
                                //     }
                                break;
                            case "ReqAutorizacion":
                                control.row.select(".input_content")
                                    .style("width", "30px");
                                break;
                        }

                        container.append(() => control.row.node());
                    } else {
                        console.warn("-d", model, "NO encontrado");
                    }
                }

                fnAppendRow("Titulo");
                fnAppendRow("Mensaje");
                fnAppendRow("Archivo");
                fnAppendRow("IDKinder");
                fnAppendRow("IdsEscolaridad");
                fnAppendRow("Niveles");
                fnAppendRow("Grupos");
                fnAppendRow("ProgramadoAux");
                fnAppendRow("ExpiraAux");
                fnAppendRow("ReqAutorizacion");
            },
            Validation: (value, field, dataForm, controlsForm) => {
                // -> Campos requeridos:  Titulo, Escuela, NivelEscolar, Grados, Grupos, Programado (solo si ProgramadoAux está habilitado)
                switch (field) {
                    case "ProgramadoAux":
                        fn_OnChangeTimeInputToSchedule();
                        let dateContainerElement = form._ControlsData.get("ProgramadoAux").row
                            .select(":scope > .input_content");
                        let inputDateSendElement = dateContainerElement
                            .select<HTMLInputElement>("input[type='date']")
                            .node();
                        let inputTimeSendElement = dateContainerElement
                            .select<HTMLInputElement>("input[type='time']")
                            .node();
                        return (inputDateSendElement.validity.valid && inputTimeSendElement.validity.valid);
                        break;
                    case "ExpiraAux":
                        fn_UpdateMinExpireDate();
                        fn_ApplyMinValueToInputDateExpira();
                        // fn_ApplyMinValueToInputTimeExpira();
                        fn_BuildExpireDate();

                        let dateContainerElementExp = form._ControlsData.get("ExpiraAux").row
                            .select(":scope > .input_content");
                        let inputDateSendElementExp = dateContainerElementExp
                            .select<HTMLInputElement>("input[type='date']")
                            .node();
                        /* let inputTimeSendElementExp = dateContainerElementExp
                            .select<HTMLInputElement>("input[type='time']")
                            .node(); */

                        // return (inputDateSendElementExp.validity.valid && inputTimeSendElementExp.validity.valid);
                        return (inputDateSendElementExp.validity.valid);
                        break;
                    case "Mensaje":
                    case "Archivo":
                        if (!dataForm.Mensaje.trim() && !dataForm.Archivo) {
                            NotificacionV2._Mostrar(UIUtilLang._GetUIString("circulares", "notif_frm_contentreq"), "ADVERTENCIA");
                            return false;
                        }
                        break;
                    case "Niveles":
                        let idsGradosSeleccionados = (form._DataOrigin[field] as number[]);
                        let gruposSeleccionados1 = form._DataOrigin.Grupos.map(idGrupo => DataModuloGrupo._DiccGrupo.get(idGrupo));
                        for (let idGrado of idsGradosSeleccionados) {
                            // Valida que todos los grados seleccionados tengan al menos un grupo seleccionado
                            let hasGroupSelected = Boolean(gruposSeleccionados1.find(grupo => (grupo?.IdNivel == idGrado)));
                            if (!hasGroupSelected) {
                                let grado = DataModuloGrado._DiccGrado.get(idGrado);
                                let escolaridad = DataModuloEscolaridad._DiccEscolaridad.get(grado?.IdEscolaridad);
                                NotificacionV2._Mostrar(UIUtilLang._GetUIString("circulares", "notif_gradosingruposseleccionados").replace("_NOMBRE", `<b>${(`${grado?.Nombre} (${escolaridad?.Nombre})`)}</b>`), "ADVERTENCIA");
                                return false;
                            }
                        }
                        // Fuera del control total del formulario
                        return (form._DataOrigin[field] as number[]).length > 0;
                        break
                    case "Grupos":
                        if (!dataForm.ProgramadoAux) {
                            // Solo se valida que tenga alumnos activos si la circular NO se ha programado
                            let gruposSeleccionados2 = (form._DataOrigin[field] as number[]).map(idGrupo => DataModuloGrupo._DiccGrupo.get(idGrupo));
                            for (let grupo of gruposSeleccionados2) {
                                if (!fn_GrupoHasAlumnosActivos(grupo)) {
                                    NotificacionV2._Mostrar(UIUtilLang._GetUIString("circulares", "notif_gruposinalumnos").replace("_NOMBRE", `<b>${grupo?.Nombre || ""}</b>`), "ADVERTENCIA");
                                    return false;
                                }
                            }
                        }
                        // Fuera del control total del formulario
                        return (form._DataOrigin[field] as number[]).length > 0;
                        break;
                }
                return true;

                // if (field == "ProgramadoAux") {
                //     // -> Programado solo es requerido en caso de estar activado el checkbox
                //     // y se evalua en base a los input que componen la fecha de programación
                //     fn_OnChangeTimeInputToSchedule();
                // 
                //     let dateContainerElement = form.prop_ControlsData.get("ProgramadoAux").row
                //         .select(":scope > .input_content");
                // 
                //     let inputDateSendElement = dateContainerElement
                //         .select<HTMLInputElement>("input[type='date']")
                //         .node();
                //     let inputTimeSendElement = dateContainerElement
                //         .select<HTMLInputElement>("input[type='time']")
                //         .node();
                // 
                //     return (inputDateSendElement.validity.valid && inputTimeSendElement.validity.valid);
                // }
                // else if (field == "ExpiraAux") {
                //     fn_OnChangeTimeInputToExpire();
                // 
                //     let dateContainerElement = form.prop_ControlsData.get("ExpiraAux").row
                //         .select(":scope > .input_content");
                // 
                //     let inputDateSendElement = dateContainerElement
                //         .select<HTMLInputElement>("input[type='date']")
                //         .node();
                //     let inputTimeSendElement = dateContainerElement
                //         .select<HTMLInputElement>("input[type='time']")
                //         .node();
                // 
                //     return (inputDateSendElement.validity.valid && inputTimeSendElement.validity.valid);
                // }
                // else {
                //     switch (field) {
                //         // Debe tener al menos un tipo de contenido
                //         case "Mensaje":
                //         case "Archivo":
                //             if (!dataForm.Mensaje.trim() && !dataForm.Archivo) {
                //                 NotificacionV2.fn_Mostrar(UIUtilLang.fn_GetUIString("circulares", "notif_frm_contentreq"), "ADVERTENCIA");
                //                 return false;
                //             }
                //             break;
                //         /* case "Titulo":
                //         case "IDKinder":
                //         case "IdsEscolaridad":
                //             // El formulario los puede controlar
                //             return Boolean(value) && (String(value).trim() != ""); */
                //         case "Niveles":
                //             let idsGradosSeleccionados = (form.prop_DataOrigin[field] as number[]);
                //             let gruposSeleccionados1 = form.prop_DataOrigin.Grupos.map(idGrupo => Data.Modulo.Grupo.DiccGrupo.get(idGrupo));
                //             for (let idGrado of idsGradosSeleccionados) {
                //                 // Valida que todos los grados seleccionados tengan al menos un grupo seleccionado
                //                 let hasGroupSelected = Boolean(gruposSeleccionados1.find(grupo => (grupo?.IdNivel == idGrado)));
                //                 if (!hasGroupSelected) {
                //                     let grado = Data.Modulo.Grado.DiccGrado.get(idGrado);
                //                     let escolaridad = Data.Modulo.Escolaridad.DiccEscolaridad.get(grado?.IdEscolaridad);
                //                     NotificacionV2.fn_Mostrar(UIUtilLang.fn_GetUIString("circulares", "notif_gradosingruposseleccionados").replace("_NOMBRE", `<b>${(`${grado?.Nombre} (${escolaridad?.Nombre})`)}</b>`), "ADVERTENCIA");
                //                     return false;
                //                 }
                //             }
                //             // Fuera del control total del formulario
                //             return (form.prop_DataOrigin[field] as number[]).length > 0;
                //         case "Grupos":
                //             if (!dataForm.ProgramadoAux) {
                //                 // Solo se valida que tenga alumnos activos si la circular NO se ha programado
                //                 let gruposSeleccionados2 = (form.prop_DataOrigin[field] as number[]).map(idGrupo => Data.Modulo.Grupo.DiccGrupo.get(idGrupo));
                //                 for (let grupo of gruposSeleccionados2) {
                //                     if (!fn_GrupoHasAlumnosActivos(grupo)) {
                //                         NotificacionV2.fn_Mostrar(UIUtilLang.fn_GetUIString("circulares", "notif_gruposinalumnos").replace("_NOMBRE", `<b>${grupo?.Nombre || ""}</b>`), "ADVERTENCIA");
                //                         return false;
                //                     }
                //                 }
                //             }
                //             // Fuera del control total del formulario
                //             return (form.prop_DataOrigin[field] as number[]).length > 0;
                //     }
                // }
                // return true;
            }
        }, datoCircularForm);

        if (typeForm == "grid") {
            // -> Valores iniciales a controles del formulario a los que el mismo no tiene control total
            let gradosComponent = form._ControlsData.get("Niveles").instance as unknown as ComponentCircleItems<Entidad.IGrado, "IdNivel">;
            let gruposComponent = form._ControlsData.get("Grupos").instance as unknown as ComponentCircleItems<Entidad.IGrupo, "IdGrupo">;

            gradosComponent
                ._SelectItems(true, ...datoCircularForm.Niveles)
                ._UI_RefreshCirculos();

            gruposComponent
                ._SelectItems(true, ...datoCircularForm.Grupos)
                ._UI_RefreshCirculos();
        }


        if (action == Entidad.CAccionPermiso.Editar) {
            let archivoComponent = form._ControlsData.get("Archivo").instance as InputFileControl.InputFile;
            _UpdateFileControlAndCircularData(datoC.IdNoticia, archivoComponent, 3)
                .then(resFileInfo => {
                    if (resFileInfo.Resultado < 0) {
                        NotificacionV2._Mostrar(UIUtilLang._GetHTTPMessage(resFileInfo, "obtenerarchivoinfo"), "ADVERTENCIA");
                    }
                });
        }

        fn_OnChangeCheckboxToSchedule(datoCircularForm.ProgramadoAux);
        fn_OnChangeCheckboxExpire(datoCircularForm.ExpiraAux);
        fn_UpdateMinExpireDate();
        // fn_OnChangeCheckboxToExpire(datoCircularForm.ExpiraAux);

        // console.warn("The form", form, datoCircularForm);
        return {
            EsEditable: formEsEditable,
            Form: form
        }
    }

    /**
     * * Un control File es valido siempre la consulta de info de archivo se halla realizado correctamente. Independientemente de que tenga archivo o no.
     */
    export function _UpdateFileControlAndCircularData(idNoticia: number, fileControl: InputFileControl.InputFile, tamanio: 1 | 2 | 3, emptyUseDefaultImage = false) {
        return new Promise<DataDRequest.IRequestResponseBase<IInfoArchivo[]>>((resolve, reject) => {
            fileControl._SpinnerLoadVisible = true;
            DataModuloCircular._GetInfoArchivos(idNoticia)
                .then(resFilesInfo => {
                    fileControl._SpinnerLoadVisible = false;
                    let styleCircle = false;
                    if (resFilesInfo.Resultado > 0) {
                        fileControl._DefaultBackgroundText = "";
                        if (resFilesInfo.Datos.length) {
                            const fileInfo = resFilesInfo.Datos[0];
                            if (fileInfo.MimeType.startsWith("image")) {
                                // NOTE: "prop_IsValidFile" toma valor de manera interna en el control.
                                fileControl._UrlResource = DataModuloCircular._GetUrlObtenerArchivo(fileInfo.IdArchivo, tamanio);
                                // fileControl.prop_AuxExtensionFile = fileInfo.Extension;
                                styleCircle = true;
                            } else {
                                fileControl._Reset();
                                // fileControl.prop_AuxExtensionFile = fileInfo.Extension;
                                // fileControl.prop_IsValidFile = true;
                            }
                            fileControl._AuxExtensionFile = fileInfo?.Extension;
                        }
                        else { // if (fileControl.prop_IsValidFile) {
                            // console.warn("File control reset 1", datoC.IdNoticia, datoC.Titulo);
                            if (emptyUseDefaultImage) {
                                fileControl._UrlResource = UIUtilIconResources.CGeneral.Circular2;
                            } else {
                                fileControl._Reset();
                            }
                            fileControl._AuxExtensionFile = "nofile"; // NOTE Por defecto el control no muestra extensiones diferentes a video o imagen
                            // fileControl.prop_IsValidFile = true;
                        }
                    } else {
                        // console.warn("File control reset 2", datoC.IdNoticia, datoC.Titulo);
                        if (emptyUseDefaultImage) {
                            fileControl._UrlResource = UIUtilIconResources.CGeneral.Circular2;
                        } else {
                            fileControl._Reset(); // FIXME ? Hace que el control se resetee cuando no debe? Problema con files diferentes de imagen
                        }
                        fileControl._DefaultBackgroundText = "Error";
                    }

                    // if (tamanio == 3) {
                    fileControl._ChangeForm(styleCircle ? "Semicuadrado" : "None");
                    // }
                    resolve(resFilesInfo);
                })
        })
    }

    // **
    // Utilidades
    // **

    /**
     * @param datoCircular
     * @param forceRequest @default false
     * @param idsGrupos Si es `null` toma en cuenta todos los grupos destino
     * @default null
     * @returns Map<IdAlumno, ICircularChildDestino>
     */
    export async function _GetSchemeAutorizacionesInfoByAlumnos(datoCircular: Entidad.ICircular, idsGrupos: number[] = null, forceRequest: boolean = false): Promise<DataDRequest.IRequestResponseA<Map<number, ICircularChildDestino>>> {
        const res = await DataModuloCircular._GetInfoAutorizacion(datoCircular.IdNoticia, forceRequest);
        let finalData = new Map<number, ICircularChildDestino>();

        res.Datos?.forEach(dGrupoD => {
            if (idsGrupos && idsGrupos.indexOf(dGrupoD.IdGrupo) == -1) {
                return;
            }
            let status_aprobacion = new Map<number, CAutorizado>();
            dGrupoD.Autorizaciones.Aprobados.forEach(idAlumno => {
                status_aprobacion.set(idAlumno, CAutorizado.Aprobado);
            })
            dGrupoD.Autorizaciones.Rechazados.forEach(idAlumno => {
                status_aprobacion.set(idAlumno, CAutorizado.Rechazado);
            })

            dGrupoD.Autorizaciones.AlumnosDestino.forEach(idAlumno => {
                let alumno = DataModuloMain._GetItemDataByName("Alumno", idAlumno);
                if (!alumno) {
                    console.warn("Detalle circular, alumno no encontrado", datoCircular.IdNoticia, dGrupoD.IdGrupo, idAlumno);
                    return;
                }
                let item = finalData.get(idAlumno);

                if (!item) {
                    item = {
                        IdAlumno: idAlumno,
                        Alumno: alumno,
                        GruposInfoAutorizacion: [],
                        // ReqAutorizacion: datoCircular.ReqAutorizacion
                    };
                    finalData.set(idAlumno, item);
                }

                if (!item.GruposInfoAutorizacion.find(d => d.IdGrupo == dGrupoD.IdGrupo)) {
                    let grupo = DataModuloMain._GetItemDataByName("Grupo", dGrupoD.IdGrupo);
                    item.GruposInfoAutorizacion.push({
                        IdGrupo: dGrupoD.IdGrupo,
                        Grupo: grupo,
                        Autorizado: (datoCircular.ReqAutorizacion ? (status_aprobacion.get(idAlumno) || CAutorizado.Pendiente) : undefined)
                    });
                }
            })
        })

        return {
            Resultado: res.Resultado,
            TipoRequest: res.TipoRequest,
            Datos: finalData,
        };
    }

    /**
     *
     * @param datoCircular
     * @param forceRequest @default false
     * @returns Map<IdGrado, ICircularGradoDestino>
     */
    export async function _GetSchemeAutorizacionesInfoByGrados(datoCircular: Entidad.ICircular, forceRequest: boolean = false): Promise<DataDRequest.IRequestResponseA<Map<number, ICircularGradoDestino>>> {
        const res = await DataModuloCircular._GetInfoAutorizacion(datoCircular.IdNoticia, forceRequest);
        let subData: Map<number, ICircularGradoDestino> = new Map();

        res.Datos.forEach(dGrupoD => {
            let grupo = DataModuloMain._GetItemDataByName("Grupo", dGrupoD.IdGrupo);
            if (!grupo) {
                console.warn("-d", "Group no found in Dict", dGrupoD.IdGrupo);
                return;
            }
            let subItem = subData.get(grupo.IdNivel);

            if (!subItem) {
                let grado = DataModuloMain._GetItemDataByName("Grado", grupo.IdNivel);
                let escolaridad: Entidad.IEscolaridad;
                if (grado) escolaridad = DataModuloMain._GetItemDataByName("Escolaridad", grado.IdEscolaridad);
                subItem = {
                    IdGrado: grupo.IdNivel,
                    MapGrupos: new Map(),
                    StrEscolaridad: escolaridad?.Nombre || UIUtilLang._GetUIString("general", "nodisponible"),
                    StrGrado: grupo?.NombreGrado || UIUtilLang._GetUIString("general", "nodisponible"),
                    StrGrupos: grupo.Nombre
                }
                subData.set(grupo.IdNivel, subItem);
            }

            subItem.MapGrupos.set(grupo.IdGrupo, grupo);

            if (subItem.MapGrupos.size > 1) {
                subItem.StrGrupos = Array.from(subItem.MapGrupos.values()).map(d => (d.Nombre)).join(", ");
            }
        })
        return {
            Resultado: res.Resultado,
            TipoRequest: res.TipoRequest,
            Datos: subData,
        };
    }

    /** Retorna la interpretación de la info de autorizaciones
     * @param item
     * @param idsGrupos Si es `null` toma en cuenta todos los grupos destino
     * @default null
     * @param forceRequest
     * @returns `string`
     */
    export async function _GetAutorizacionRespuestaFormat(item: ICircular, idsGrupos: number[] = null, forceRequest = false) {
        if (item.ReqAutorizacion) {
            const infoAutorizaciones = await DataModuloCircular._GetInfoAutorizacion(item.IdNoticia, forceRequest);
            let aprobados = 0, rechazados = 0, total = 0, strTags = "";
            infoAutorizaciones.Datos?.forEach(dInfo => {
                if (!idsGrupos || idsGrupos.indexOf(dInfo.IdGrupo) > -1) {
                    total += dInfo.Autorizaciones.AlumnosDestino.length;
                    aprobados += dInfo.Autorizaciones.Aprobados.length;
                    rechazados += dInfo.Autorizaciones.Rechazados.length;
                }
            })
            let hasTagAprobados = (rechazados != total);
            if (hasTagAprobados) {
                strTags += `${UIUtilLang._GetUIString("general", "afirma")}: ${aprobados}/${total}`;
            }
            if (aprobados != total) {
                strTags += (hasTagAprobados ? "\n" : "") + `${UIUtilLang._GetUIString("general", "niega")}: ${rechazados}/${total}`;
            }
            return strTags || UIUtilLang._GetUIString("general", "nodisponible");
        }
        return "N/A";
    }

    function fn_GetAcceptImage(imgBase64: string) {
        return new Promise<boolean>(resolve => {
            const control = new FullView()
                ._SetTitle(UIUtilLang._GetUIString("general", "preview"))
                ._OnClose(() => resolve(false))
                ._Options([{
                    Icon: UIUtilIconResources.CGeneral.CloseInCircle,
                    OnClick: () => (resolve(false), control._Destroy()),
                }, {
                    Icon: UIUtilIconResources.CGeneral.AcceptInCircle,
                    OnClick: () => (resolve(true), control._Destroy()),
                }])
                ._Content(d3.create("img")
                    .attr("src", imgBase64)
                    .attr("draggable", false)
                    .style("max-width", "100%")
                    .style("max-height", "100%")
                    .style("object-fit", "contain")
                    .node())
            control._btnCloseNode.remove();
        })
    }

    export function _UIStepContentIcoElement(container: TSelectionHTML<"div">, datum: Pick<ICircular, "IdNoticia" | "Titulo" | "Mensaje">, modulo: Entidad.CModulo) {
        let ctrlArchivo = container.node()["_circularIcon"] as InputFileControl.InputFile & {
            __idNoticia?: number;
        };

        if (!ctrlArchivo) {
            container.style("overflow", "visible");
            ctrlArchivo = new InputFileControl.InputFile({
                ControlForm: "none", // InputFileControl.ControlForm.Semicuadrado,
                Dimentions: 35,
                DefaultBackgroundImage: UIUtilIconResources.CGeneral.Circular2,
                ShowAreaControlOptions: false,
                Disabled: true,
                // ShowErrorTag: true, // FIXME Lo requiere pero solo aplica a imagenes
                SpinnerWidth: 3,
            })
            ctrlArchivo._SpinnerControl.node()._Dim = 25; // FIXME Agregar en la configuracion del control InputFile
            ctrlArchivo._ControlNode.style.margin = "auto";

            ctrlArchivo["__idNoticia"] = datum.IdNoticia;
            container.append(() => ctrlArchivo._ControlNode);
            container.node()["_circularIcon"] = ctrlArchivo;
            // d3.select(container.node().parentElement).style("padding", "5px 10px 5px 5px");
        }

        if (!ctrlArchivo._AuxExtensionFile || (ctrlArchivo["__idNoticia"] != datum.IdNoticia)) {
            // !ctrlArchivo.prop_IsImage || !ctrlArchivo.prop_UrlResource?.startsWith(datum.InfoArchivos[0]?.UrlFile)) {
            UIUtilViewCircular._UpdateFileControlAndCircularData(datum.IdNoticia, ctrlArchivo, 3, true);
        }

        ctrlArchivo["__idNoticia"] = datum.IdNoticia;

        ctrlArchivo._OnClickImageArea(() => {
            UIUtilViewCircular._OpenModal_CircularBasicView(datum, modulo);
        })
    }

    export function _OpenModal_CircularBasicView(circularInfo: Pick<ICircular, "IdNoticia" | "Titulo" | "Mensaje">, modulo: Entidad.CModulo) {
        ModalThings._GetModalToAProccess({
            Title: UIUtilLang._GetUIString("circulares", "tag_content"),
            Action: Entidad.CAccionPermiso.Ver,
            Modulo: modulo,
            Width: 450,
            DrawContent: (content, modalThings) => {
                content
                    .classed(UIUtilGeneral.FBoxOrientation.Vertical, true)
                    .style("gap", "var(--padding2)")
                modalThings.Modal._FooterSelection
                    .classed("hide", true);

                // Title & Message
                content.append("label")
                    .html(
                        `<b>${UIUtilLang._GetUIString("circulares", "d_field_titulo")}:</b></br>`
                        + `<span>${circularInfo.Titulo}</span>`
                    )
                if (circularInfo.Mensaje.trim()) {
                    content.append("div")
                        .html(
                            `<b>${UIUtilLang._GetUIString("circulares", "d_field_mensaje")}:</b></br>`
                            + `<div>${UIUtilStrings._AutoLink(circularInfo.Mensaje).innerHTML}</div>`
                        )
                        .select("div")
                        .style("max-height", "100px")
                        .style("overflow", "auto")
                        .style("line-break", "anywhere")
                }

                let fileInfo: IInfoArchivo;
                const fileControl = new InputFileControl.InputFile({
                    ControlForm: InputFileControl.ControlForm.Cuadrado,
                    ControlStyle: "foto_control_style2",
                    AspectRatio: "contain",
                    ShowBtnLoadFile: false,
                    ShowBtnDownloadFile: true,
                    Disabled: true,
                    Dimentions: content.node().getBoundingClientRect().width - 26,
                    OnGetFileName: () => fileInfo.Nombre,
                    OnDownload_GetRealResource: () => DataModuloCircular._GetUrlObtenerArchivo(fileInfo.IdArchivo, 2),
                    OptionsExtras: [
                        {
                            ID: 0,
                            Icon: UIUtilIconResources.CGeneral.EyeV2,
                            OnClick: async () => {
                                new FullViewFile()._SetContent([
                                    {
                                        Id: circularInfo.IdNoticia,
                                        Filename: () => circularInfo.Titulo,
                                        Download: () => DataModuloCircular._GetUrlObtenerArchivo(fileInfo.IdArchivo, 1),
                                        Content: () => DataModuloCircular._GetUrlObtenerArchivo(fileInfo.IdArchivo, 1),
                                        Description: circularInfo.Mensaje
                                    }
                                ])
                            },
                            Visible: true,
                        }
                    ]
                })

                fileControl._SpinnerControl.node()._Dim = 50;

                modalThings.Progress.attr("oculto", false);
                _UpdateFileControlAndCircularData(circularInfo.IdNoticia, fileControl, 2)
                    .then((resFilesInfo) => {
                        modalThings.Progress.attr("oculto", true);

                        if (resFilesInfo.Datos.length) {
                            fileInfo = resFilesInfo.Datos[0];

                            if (!resFilesInfo.Datos[0].MimeType.startsWith("image")) {
                                fileControl._Dimentions = 120;
                            }

                            content.append("b")
                                .text(UIUtilLang._GetUIString("circulares", "tag_content") + ":")
                            content.append(() => fileControl._ControlNode);

                        } else if (!circularInfo.Mensaje) {
                            content.append("b")
                                .text(UIUtilLang._GetUIString("circulares", "tag_content") + ":")

                            if (resFilesInfo.Resultado < 0)
                                content.append("span")
                                    .text(UIUtilLang._GetHTTPMessage(resFilesInfo, "obtenerarchivoinfo"))
                            else
                                content.append("span")
                                    .text(UIUtilLang._GetUIString("general", "sincontenido"));

                        }
                    })
            }
        })
    }
}
