import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import _L from '../../util/Labels';
import { PDFThings } from "../controlD3/PDFBuilder";
import { UIUtilGeneral } from "../util/Util";
import { Entidad } from "../../data/Entidad";
import { UIUtilViewCalificacion } from "./Calificacion";
import { DataModuloMain } from "../../data/ModuloMain";
import { ascending, group as d3Group } from "d3-array";
import IBoletaConfig = UIUtilViewCalificacion.IBoletaConfigV2;
import CTipoEvaluacion = Entidad.CTipoEvaluacion;
import { _DiccMateriasV2 } from "../../data/modulo/MateriaV2";

const _METADATA = PDFThings._METADATA;
const TextFooterMimos = "Para nosotros es muy importante la comunicación entre padres y maestros, estamos a tus órdenes para platicar directamente en caso de que tengas alguna duda o comentario. Las calificaciones finales se entregarán en la entrevista entre padres y maestros."

export namespace UIUtilViewCalificacionBoletaV4 {
    export async function _BoletaPDFV4(boletaConfig: IBoletaConfig) {
        /** Calificaciones del periodo actual (Calificaciones "seleccionadas") */
        const materiasEvals = boletaConfig.MateriasCurrentCalificaciones;
        /** Calificaciones de todos los periodos (Agrupadas por materias) 
         * 
         * Cada Criterio/Materia asignada tiene un una matriz de evaluaciones [P1[],P2[],P3[],PR[]]
        */
        const allPeriodsMaterias = boletaConfig.MateriasCalificacionesAllPeriods;

        /** Arreglo de info de boletas generadas en el ciclo actual */
        const boletasEnCiclo = boletaConfig.BoletasEnCiclo || [];
        boletasEnCiclo.sort((a, b) => ascending(a.FechaCreacion, b.FechaCreacion))

        /** Mapa de materias involucradas en las boletas del ciclo, cada materia contiene los ids de los maestros que evaluaron esa materia */
        const mapMateriasMaestrosEval: Map<number, number[]> = new Map<number, number[]>();
        boletasEnCiclo.forEach(boletaInCiclo => {
            boletaInCiclo.IdsMaterias.forEach(((idMateria, i) => {
                const materiaTeachers = mapMateriasMaestrosEval.get(idMateria) || [];
                const teacherIds = boletaInCiclo.IdsEvaluadores[i].split(",").map(id => Number(id));
                teacherIds.forEach(idTeacher => {
                    const itemExist = materiaTeachers.findIndex(idT => idT == idTeacher);
                    if (itemExist < 0) {
                        materiaTeachers.push(idTeacher);
                    }
                })
                mapMateriasMaestrosEval.set(idMateria, materiaTeachers);
            }))
        })
        materiasEvals.forEach(materiaCurrentPeriod => {
            const materiaTeachers = mapMateriasMaestrosEval.get(materiaCurrentPeriod.IdMateria) || [];
            materiaCurrentPeriod.IdEvaluadores.forEach(idTeacher => {
                const itemExist = materiaTeachers.findIndex(idT => idT == idTeacher);
                if (itemExist < 0) { materiaTeachers.push(idTeacher) }
            })
            mapMateriasMaestrosEval.set(materiaCurrentPeriod.IdMateria, materiaTeachers);
        })


        const _CONTENT = {
            TopTitle: boletaConfig.Titulo || _METADATA.title,
            LogoURL: boletaConfig.Logo,
        }

        const _STYLES = {
            PaddingPercentFromWidthPage: 4,
            LogoDimPercentFromWidthPage: 18,
            FontFamily: "helvetica", FontSizeTitle: 10, FontSizeSubTitle: 9, FontSizePlain: 8, FontSizeMedium: 7, FontSizeShort: 6, FontColor: "#000",
            TableFillColor: "#fff", TableLinesColor: "#000", TableLineWidth: .1,
            DrawAuxColor: "#d3d3d3", PageFormat: "A5",
        }

        const pdf = new jsPDF({ orientation: "portrait", unit: "px", format: _STYLES.PageFormat });

        const PAGE_WIDTH: number = pdf.internal.pageSize.getWidth();
        const PAGE_HEIGHT: number = pdf.internal.pageSize.getHeight();
        const PAGE_PADDING = (PAGE_WIDTH / 100 * _STYLES.PaddingPercentFromWidthPage);

        const STYLES = {
            ..._STYLES,
            ...{
                Padding: PAGE_PADDING, Height: PAGE_HEIGHT, Width: PAGE_WIDTH,
                LogoWidth: 0, WidthArea: PAGE_WIDTH - PAGE_PADDING * 2, HeightArea: PAGE_HEIGHT - PAGE_PADDING * 2,
            }
        }
        /** Ayuda a trazar lineas y espacios imaginarios en la boleta */
        const nDivs = 6;
        /** Resultado de dividir el ancho disponible de la hoja entre un numero definido */
        const divWidth = STYLES.WidthArea / nDivs;

        STYLES.LogoWidth = divWidth * 2;
        let contentY = STYLES.Padding;

        pdf.setProperties({
            title: boletaConfig.MetadataTitle || _CONTENT.TopTitle || _METADATA.title,
            subject: _METADATA.subject,
            author: _METADATA.author,
            creator: _METADATA.creator,
            keywords: _METADATA.keywords,
        });

        const TableStylesAux = {
            font: STYLES.FontFamily, fontSize: STYLES.FontSizeMedium, textColor: STYLES.FontColor,
            fillColor: STYLES.TableFillColor, lineColor: STYLES.TableLinesColor, lineWidth: STYLES.TableLineWidth,
        }
        const TableMarginsAux = { top: STYLES.Padding, bottom: STYLES.Padding, left: STYLES.Padding, right: STYLES.Padding }

        pdf.setLineWidth(STYLES.TableLineWidth);
        pdf.setDrawColor(STYLES.TableLinesColor);

        interface MateriasCalificadas {
            NombreMateria: string,
            ArrDataCalificaciones: (string | number)[][];
        }
        /** Dibuja una tabla de  Materias - Calificaciones 
         * 
         * Agrupa las evaluaciones de las materias en un mapa materia - Calificaciones {Materia, ["Eval1", "Eval2", "Eval3", "Prom"]}
         * 
         * Dependiendo de los argumentos que reciba la función
         * @argument tipoEval Tipo de evaluación: define el tipo de dibujado que tendrá la tabla y sus celdas
         * 
         * Ej. si es CTipoEvaluacion.Colores || CTipoEvaluacion.Letras
         * Dibujará encima de la tabla la descripción de las evaluaciones tomando de evaluationCfg (La descripción de evaluaciones se dibujará junta)
         * @argument evaluationCfg Config de Evaluación, contiene el valor y la descripción de las configuraciones de materias
         * 
         * La tabla generada está compuesta de una cabecera.
         * Y por cada materia una cabecera propia de la materia.
         * Junto con sus evaluaciones
         * @example
         * [ Org.Curricular || 1er trim || 2do trim || 3er trim || Final ]
         * [                           MateriaName                       ]
         * [ Criterio       ||    A+    ||    B+    ||    A+    ||   A+  ]
        */
        const drawQualificationTbl = (pdf: jsPDF, tipoEval: CTipoEvaluacion, data: UIUtilViewCalificacion.ICalificacionInfoAPeriods[], evaluationCfg: UIUtilViewCalificacion.ItemConfigEval[] = []) => {
            const materiasCalificaciones: Map<string, MateriasCalificadas> = new Map<string, MateriasCalificadas>();
            data.forEach((d, i) => {
                const ArrDataCalificaciones: (string | number)[][] = [];
                d.Evaluaciones.forEach(e => {
                    ArrDataCalificaciones.push(
                        [
                            e.Nombre,
                            (e.Evaluaciones[0].length == 1 && e.Evaluaciones[0][0] == '') ? "-" : e.Evaluaciones[0].join("\n"),
                            (e.Evaluaciones[1].length == 1 && e.Evaluaciones[1][0] == '') ? "-" : e.Evaluaciones[1].join("\n"),
                            (e.Evaluaciones[2].length == 1 && e.Evaluaciones[2][0] == '') ? "-" : e.Evaluaciones[2].join("\n"),
                            (e.Evaluaciones[3].length == 1 && e.Evaluaciones[3][0] == '') ? "-" : e.Evaluaciones[3].join("\n"),
                        ]
                    );
                });
                materiasCalificaciones.set(d.MateriaNombre + i, { NombreMateria: d.MateriaNombre, ArrDataCalificaciones: ArrDataCalificaciones });
            })

            let heightEvalContent = 0;
            let posXEval = STYLES.Padding;
            if ([CTipoEvaluacion.Colores, CTipoEvaluacion.Letras].includes(tipoEval)) {
                // ANTES DE SER COLOCADO SE REALIZA EL CÁLCULO PARA VER SI TODO EL ELEMENTO CABE EN UNA MISMA HOJA -> DE NO SER ASÍ ENTONCES SE AGREGA UNA PÁGINA NUEVA
                pdf.setFontSize(STYLES.FontSizeMedium);
                dimText = pdf.getTextDimensions(_L("boleta_v4.evaluacion"));
                let comesFromBreakLine = false;

                // CALCULO DE POSICIÓN
                heightEvalContent += dimText.h;
                heightEvalContent += STYLES.Padding / 2;
                switch (tipoEval) {
                    case CTipoEvaluacion.Colores:
                        pdf.setDrawColor(STYLES.DrawAuxColor);
                        for (let ind = 0; ind < evaluationCfg.length; ind++) {
                            const d = evaluationCfg[ind];
                            const isLastItemEvalCfg = (ind == evaluationCfg.length - 1);
                            const hasDescription = (d.Descripcion && d.Descripcion.length);
                            const r = pdf.getTextDimensions("T").h - 2;
                            const textItemDesc = hasDescription ? (d.Descripcion) : "";
                            const textItemEqual = hasDescription ? " = " : "";
                            const dimItemDesc = pdf.getTextDimensions(textItemDesc);
                            const equalWidth = pdf.getTextDimensions(textItemEqual).w;
                            const itemCfgWidth = (r * 2) + equalWidth + dimItemDesc.w;
                            if ((posXEval + itemCfgWidth) > (STYLES.Width - STYLES.Padding)) {
                                if (ind !== 0 && !comesFromBreakLine) heightEvalContent += (STYLES.Padding / 3) + pdf.getTextDimensions("Tgj").h;
                                posXEval = STYLES.Padding;
                                if (itemCfgWidth > STYLES.WidthArea) {
                                    let splitTextItemCfg = pdf.splitTextToSize(textItemDesc, (STYLES.WidthArea - (r * 2))) as string[];
                                    splitTextItemCfg.forEach((t, i) => {
                                        const isLastLine = (i == (splitTextItemCfg.length - 1));
                                        const tHeight = pdf.getTextDimensions(t).h;
                                        heightEvalContent += tHeight;
                                        if (!isLastLine) heightEvalContent += (STYLES.Padding / 3 * 0.10);
                                    });
                                    if (!isLastItemEvalCfg) { heightEvalContent += (STYLES.Padding / 3) }
                                    posXEval = STYLES.Padding;
                                    comesFromBreakLine = true;
                                    continue;
                                }
                            }
                            comesFromBreakLine = false;
                            posXEval += r * 2;
                            posXEval += equalWidth;
                            posXEval += (dimItemDesc.w) + STYLES.Padding;
                            if (isLastItemEvalCfg) heightEvalContent += pdf.getTextDimensions("Tgj").h;
                        }
                        break;
                    case CTipoEvaluacion.Letras:
                        evaluationCfg.forEach((d, ind) => {
                            const isLastItemEvalCfg = (ind == evaluationCfg.length - 1);
                            const hasDescription = (d.Descripcion && d.Descripcion.length);
                            const textItemCfg = d.Valor + (hasDescription ? (" = " + d.Descripcion) : "");
                            const dimItemCfg = pdf.getTextDimensions(textItemCfg);
                            if ((posXEval + dimItemCfg.w) > (STYLES.Width - STYLES.Padding)) {
                                if (ind !== 0 && !comesFromBreakLine) heightEvalContent += (STYLES.Padding / 3) + pdf.getTextDimensions("Tgj").h;
                                posXEval = STYLES.Padding;
                                if (dimItemCfg.w > STYLES.WidthArea) {
                                    let splitTextItemCfg = pdf.splitTextToSize(textItemCfg, STYLES.WidthArea) as string[];
                                    splitTextItemCfg.forEach((t, i) => {
                                        const isLastLine = (i == (splitTextItemCfg.length - 1));
                                        if (!isLastLine) heightEvalContent += (STYLES.Padding / 3 * 0.10) + pdf.getTextDimensions("Tgj").h;
                                    })
                                    if (!isLastItemEvalCfg) { heightEvalContent += (STYLES.Padding / 3) + pdf.getTextDimensions("Tgj").h; }
                                    comesFromBreakLine = true;
                                } else {
                                    posXEval += dimItemCfg.w + STYLES.Padding;
                                    comesFromBreakLine = false;
                                }
                            } else {
                                posXEval += dimItemCfg.w + STYLES.Padding;
                                comesFromBreakLine = false;
                            }
                        })
                        break;
                }
                if ((contentY + heightEvalContent) > (STYLES.Height - STYLES.Padding)) {
                    pdf.addPage();
                    contentY = STYLES.Padding;
                }
                contentY += dimText.h;
                pdf.text(_L("boleta_v4.evaluacion"), STYLES.Padding, contentY);
                contentY += STYLES.Padding / 2;
                comesFromBreakLine = false;
                switch (tipoEval) {
                    case CTipoEvaluacion.Colores:
                        posXEval = STYLES.Padding;
                        for (let ind = 0; ind < evaluationCfg.length; ind++) {
                            const d = evaluationCfg[ind];
                            const isLastItemEvalCfg = (ind == evaluationCfg.length - 1);
                            const hasDescription = (d.Descripcion && d.Descripcion.length);
                            const r = pdf.getTextDimensions("T").h - 2;
                            const textItemDesc = hasDescription ? (d.Descripcion) : "";
                            const textItemEqual = hasDescription ? " = " : "";
                            const dimItemDesc = pdf.getTextDimensions(textItemDesc);
                            const equalWidth = pdf.getTextDimensions(textItemEqual).w;
                            const itemCfgWidth = (r * 2) + equalWidth + dimItemDesc.w;
                            if ((posXEval + itemCfgWidth) > (STYLES.Width - STYLES.Padding)) {
                                if (ind !== 0 && !comesFromBreakLine) contentY += (STYLES.Padding / 3) + pdf.getTextDimensions("Tgj").h;
                                posXEval = STYLES.Padding;
                                if (itemCfgWidth > STYLES.WidthArea) {
                                    let textHeight = 0;
                                    let splitTextItemCfg = pdf.splitTextToSize(textItemDesc, (STYLES.WidthArea - (r * 2))) as string[];
                                    splitTextItemCfg.forEach((t, i) => {
                                        const isLastLine = (i == (splitTextItemCfg.length - 1));
                                        textHeight += pdf.getTextDimensions(t).h;
                                        if (!isLastLine) textHeight += (STYLES.Padding / 3 * 0.10);
                                    })
                                    const textMiddle = contentY + (textHeight / 2);
                                    pdf.circle(posXEval + r, textMiddle, r, "DF")
                                    posXEval += r * 2;
                                    pdf.text(textItemEqual, posXEval, textMiddle + (pdf.getTextDimensions(textItemEqual).h) / 4);
                                    posXEval += equalWidth;
                                    splitTextItemCfg.forEach((t, i) => {
                                        const isLastLine = (i == (splitTextItemCfg.length - 1));
                                        const tHeight = pdf.getTextDimensions(t).h;
                                        pdf.text(t, posXEval, contentY + tHeight);
                                        contentY += tHeight;
                                        if (!isLastLine) contentY += (STYLES.Padding / 3 * 0.10);
                                    });
                                    if (!isLastItemEvalCfg) { contentY += (STYLES.Padding / 3) }
                                    posXEval = STYLES.Padding;
                                    comesFromBreakLine = true;
                                    continue;
                                }
                            }
                            comesFromBreakLine = false;
                            pdf.setFillColor(d.Valor);

                            pdf.circle(posXEval + r, contentY + r, r, "DF")
                            posXEval += r * 2;
                            pdf.text(textItemEqual, posXEval, contentY + dimItemDesc.h);
                            posXEval += equalWidth;
                            pdf.text(textItemDesc, posXEval, contentY + dimItemDesc.h);
                            posXEval += (dimItemDesc.w) + STYLES.Padding;
                            if (isLastItemEvalCfg) contentY += pdf.getTextDimensions("Tgj").h;
                        }
                        contentY += (STYLES.Padding / 3);
                        break;
                    case CTipoEvaluacion.Letras:
                        posXEval = STYLES.Padding;
                        evaluationCfg.forEach((d, ind) => {
                            const isLastItemEvalCfg = (ind == evaluationCfg.length - 1);
                            const hasDescription = (d.Descripcion && d.Descripcion.length);
                            const textItemCfg = d.Valor + (hasDescription ? (" = " + d.Descripcion) : "");
                            const dimItemCfg = pdf.getTextDimensions(textItemCfg);
                            if ((posXEval + dimItemCfg.w) > (STYLES.Width - STYLES.Padding)) {
                                if (ind !== 0 && !comesFromBreakLine) contentY += (STYLES.Padding / 3) + pdf.getTextDimensions("Tgj").h;
                                posXEval = STYLES.Padding;
                                if (dimItemCfg.w > STYLES.WidthArea) {
                                    let splitTextItemCfg = pdf.splitTextToSize(textItemCfg, STYLES.WidthArea) as string[];
                                    splitTextItemCfg.forEach((t, i) => {
                                        const isLastLine = (i == (splitTextItemCfg.length - 1));
                                        pdf.text(t, posXEval, contentY);
                                        if (!isLastLine) contentY += (STYLES.Padding / 3 * 0.10) + pdf.getTextDimensions("Tgj").h;
                                    })
                                    if (!isLastItemEvalCfg) { contentY += (STYLES.Padding / 3) + pdf.getTextDimensions("Tgj").h; }
                                    comesFromBreakLine = true;
                                } else {
                                    pdf.text(textItemCfg, posXEval, contentY);
                                    posXEval += dimItemCfg.w + STYLES.Padding;
                                    comesFromBreakLine = false;
                                }
                            } else {
                                pdf.text(textItemCfg, posXEval, contentY);
                                posXEval += dimItemCfg.w + STYLES.Padding;
                                comesFromBreakLine = false;
                            }
                        })
                        contentY += (STYLES.Padding / 3);
                        break;
                }
            }

            autoTable(pdf, {
                startY: contentY,
                head: [{ "curricularOrganizer": "", "firstTrim": "", "secondTrim": "", "thirdTrim": "", "final": "" }],
                styles: {
                    ...TableStylesAux,
                    fontStyle: "bold",
                    halign: "center",
                    valign: "middle",
                },
                showHead: false,
                body: [[_L("boleta_v4.curr_organizer"), _L("boleta_v4.primer_trimestre"), _L("boleta_v4.segundo_trimestre"), _L("boleta_v4.tercer_trimestre"), _L("boleta_v4.final")]],
                columnStyles: {
                    0: { cellWidth: divWidth * 2.5 },
                    1: { cellWidth: divWidth * 0.875 },
                    2: { cellWidth: divWidth * 0.875 },
                    3: { cellWidth: divWidth * 0.875 },
                    4: { cellWidth: divWidth * 0.875 },
                },
                theme: "grid",
                margin: TableMarginsAux,
            })

            contentY = (pdf as any).lastAutoTable.finalY;

            materiasCalificaciones.forEach((val) => {
                autoTable(pdf, {
                    startY: contentY,
                    head: [{ "subject": val.NombreMateria }],
                    styles: {
                        ...TableStylesAux,
                        halign: "center",
                        valign: "middle",
                    },
                    theme: "grid",
                    margin: TableMarginsAux,
                })
                contentY = (pdf as any).lastAutoTable.finalY;
                const heightTextAux = pdf.getTextDimensions("T").h
                autoTable(pdf, {
                    startY: contentY,
                    head: [{ "curricularOrganizer": "", "firstTrim": "", "secondTrim": "", "thirdTrim": "", "final": "" }],
                    styles: {
                        ...TableStylesAux,
                        halign: "center",
                        valign: "middle",
                    },
                    showHead: false,
                    body: val.ArrDataCalificaciones,
                    rowPageBreak: tipoEval == CTipoEvaluacion.Colores ? "avoid" : "auto",
                    didParseCell: (data) => {
                        if (tipoEval == CTipoEvaluacion.Colores && data.cell.raw && typeof data.cell.raw == 'string' && data.cell.raw.split("")[0] == "#") {
                            data.cell.text = [];
                            const nEvals = data.cell.raw.split("\n").length;
                            // Alto mínimo de celda = Padding Top de 3 + separación de 2px bajo cada círculo + PaddingBottom de 1
                            data.cell.styles.minCellHeight = 3 + ((nEvals * ((heightTextAux - 2) * 2)) + (nEvals * 2)) + 1;
                        }
                    },
                    didDrawCell: (data) => {
                        if (tipoEval == CTipoEvaluacion.Colores && data.cell.raw && typeof data.cell.raw == 'string' && data.cell.raw.split("")[0] == "#") {
                            pdf.setDrawColor(STYLES.DrawAuxColor);
                            const cellWidth = data.cell.width;
                            const cellHeight = data.cell.height;
                            const centerX = data.cell.x + cellWidth / 2;

                            const evaluations = data.cell.raw.split("\n");
                            const nEvals = evaluations.length;
                            const heightCirclesGroup = ((nEvals * ((heightTextAux - 2) * 2)) + ((nEvals - 1) * 2));
                            let PosY = data.cell.y + ((cellHeight - heightCirclesGroup) / 2);
                            const radius = heightTextAux - 2;
                            evaluations.forEach(value => {
                                pdf.setFillColor(value);
                                pdf.circle(centerX, PosY + radius, radius, 'DF');
                                PosY += (radius * 2) + 2
                            });
                        }
                    },
                    columnStyles: {
                        0: { cellWidth: divWidth * 2.5, fontSize: STYLES.FontSizeShort, halign: "left" },
                        1: { cellWidth: divWidth * 0.875 },
                        2: { cellWidth: divWidth * 0.875 },
                        3: { cellWidth: divWidth * 0.875 },
                        4: { cellWidth: divWidth * 0.875 },
                    },
                    theme: "grid",
                    margin: TableMarginsAux,
                })
                contentY = (pdf as any).lastAutoTable.finalY;
            })
            contentY += STYLES.Padding;
        }

        let heightLogo = 0;
        await UIUtilGeneral._GetIMGElementOnLoadResource(_CONTENT.LogoURL)
            .then(img => {
                // Dibujado de imagen
                heightLogo = img.height * STYLES.LogoWidth / img.width;
                pdf.addImage(img, "JPEG", STYLES.Padding, contentY, STYLES.LogoWidth, heightLogo);
            })
            .catch(() => {
                // Si falla la carga de imagen el alto que se tomará como espacio de la imagen será 50
                heightLogo = 50;
                console.warn("-d Fail img LOAD");
            })


        /** Ancho para tablas de Info de boletas (Inicio y última hoja)  */
        const tableWidth = (STYLES.WidthArea - (divWidth * 2) - STYLES.Padding);

        pdf.setFont(STYLES.FontFamily, "bold");
        const textSubHeader = boletaConfig.Nivel || _L("general.nodisponible");

        // HEADER TEXT
        pdf.setFontSize(STYLES.FontSizeTitle)
        let dimText = pdf.getTextDimensions(_L("boleta_v4.performance_evaluation"));
        let xTextPosition = STYLES.Width - STYLES.Padding - dimText.w;
        pdf.text(_L("boleta_v4.performance_evaluation"), xTextPosition, contentY + dimText.h);
        contentY += dimText.h + STYLES.Padding;

        // SUBHEADER TEXT
        autoTable(pdf, {
            startY: contentY,
            tableWidth: tableWidth,
            head: [{ "title": textSubHeader }],
            styles: {
                font: STYLES.FontFamily,
                fillColor: STYLES.TableFillColor,
                fontSize: STYLES.FontSizeSubTitle,
                textColor: STYLES.FontColor,
                halign: "right",
                valign: "middle",
            },
            headStyles: {
                cellPadding: { right: 0 }
            },
            theme: "plain",
            margin: {
                top: STYLES.Padding,
                bottom: STYLES.Padding,
                left: (STYLES.Padding * 2) + (STYLES.LogoWidth),
                right: STYLES.Padding
            },
        })

        contentY = (pdf as any).lastAutoTable.finalY + STYLES.Padding;

        // ****** Conformación de data general para boleta ****** //
        const dataInfoAlumno: (string | number)[][] = [
            [_L("boleta_v4.alumno"), boletaConfig.NombreAlumno],
            [_L("boleta_v4.fecha_nacimiento"), boletaConfig.FechaNacimiento],
            [_L("boleta_v4.grado_grupo"), boletaConfig.Grado + " " + boletaConfig.Grupos],
        ];
        const dataInfoGralBoleta = [...dataInfoAlumno];
        let ArrInfoExtra = [...boletaConfig.InfoExtra];
        const CurpItem = ArrInfoExtra.find(d => d.Tag.toUpperCase() == _L("boleta_v4.curp"));
        ArrInfoExtra = ArrInfoExtra.filter(d => d.Tag.toUpperCase() != _L("boleta_v4.curp"));
        if (CurpItem) dataInfoGralBoleta.push([CurpItem.Tag, CurpItem.Val]);
        ArrInfoExtra.forEach(info => {
            dataInfoGralBoleta.push([info.Tag, info.Val]);
        })
        dataInfoGralBoleta.push([_L("boleta_v4.cicloesc"), boletaConfig.CicloEscolar]);
        // ****** Dibujado Tabla Parte 1. InfoAlumno ****** //
        autoTable(pdf, {
            startY: contentY,
            tableWidth: tableWidth,
            head: [{ "clave": "", "valor": "" }],
            styles: {
                ...TableStylesAux,
                halign: "left",
                valign: "middle",
                cellPadding: 1
            },
            columnStyles: {
                0: { cellWidth: tableWidth / 10 * 4 },
            },
            showHead: false,
            body: dataInfoGralBoleta,
            theme: "grid",
            margin: {
                ...TableMarginsAux,
                left: (STYLES.Padding * 2) + (STYLES.LogoWidth),
            },
        })
        contentY = (pdf as any).lastAutoTable.finalY;

        // ****** Data para info - Materias - Maestros ****** //
        // La data está ordenada Por materias en orden alfabético
        const dataMateriasMaestros: string[][] = [];
        const arrMateriasMaestros: { NombreMateria: string; NombresTeachers: string }[] = [];
        mapMateriasMaestrosEval.forEach((idsTeachears, idMateria) => {
            arrMateriasMaestros.push({ NombreMateria: DataModuloMain._GetDataValueFieldByName("MateriaV2", idMateria, "Nombre"), NombresTeachers: idsTeachears.map(d => DataModuloMain._GetDataValueFieldByName("Maestro", d, "NombreCompleto")).join("\n") })
        })
        arrMateriasMaestros.sort((a, b) => ascending(a.NombreMateria, b.NombreMateria))
        arrMateriasMaestros.forEach(d => {
            dataMateriasMaestros.push([d.NombreMateria, d.NombresTeachers])
        })
        // ****** Dibujado Tabla Parte 2. MateriasTeachers ****** //
        autoTable(pdf, {
            startY: contentY,
            tableWidth: tableWidth,
            head: [{ "clave": "", "valor": "" }],
            styles: {
                ...TableStylesAux,
                halign: "left",
                valign: "middle",
                cellPadding: 1
            },
            columnStyles: {
                0: { cellWidth: tableWidth / 10 * 4 },
            },
            showHead: false,
            body: dataMateriasMaestros,
            theme: "grid",
            margin: {
                ...TableMarginsAux,
                left: (STYLES.Padding * 2) + (STYLES.LogoWidth),
            },
        })
        contentY = (pdf as any).lastAutoTable.finalY + STYLES.Padding;

        // Agrupado de materias por tipo de evaluación
        pdf.setFont(STYLES.FontFamily, "normal");
        d3Group(allPeriodsMaterias, d => d.TipoEval).forEach((arrType, tipoEval) => {
            // Si el tipo de evaluación es CTipoEvaluacion.Cualitativa || CTipoEvaluacion.Numeros Se dibuja una tabla de evaluación
            if ([CTipoEvaluacion.Cualitativa, CTipoEvaluacion.Numeros].includes(tipoEval))
                drawQualificationTbl(pdf, tipoEval, arrType)
            else {
                // Si son de tipo CTipoEvaluacion.Colores || CTipoEvaluacion.Letras
                // => Aún se tienen que agrupar por configuraciones de evaluaciones coincidentes
                // Por cada grupo de evaluaciones coincidentes se dibujará una tabla de evaluación
                // Mapa que agrupa la configuración de evaluación (Nombre - Descripción) con materias coincidentes
                const mapMateriasPerEvalCfg = new Map<number, UIUtilViewCalificacion.MateriasPerEvalCfgV2>();
                arrType.forEach((materia, index) => {
                    // Por cada materia en el tipo
                    // se obtiene su configEval (Nombre - Desc)
                    const evalConfig: UIUtilViewCalificacion.ItemConfigEval[] = materia.EvalValor.map<UIUtilViewCalificacion.ItemConfigEval>((evalCfg, i) => ({ Valor: evalCfg, Descripcion: materia.EvalDescripcion[i] || "" }));
                    let hasConfigCoincidenceV2 = false;
                    // Verifica si en algun elemento del mapa de Cfg - Materia existe una configuración coincidente
                    mapMateriasPerEvalCfg.forEach((val, key) => {
                        if (hasConfigCoincidenceV2) return;
                        if (val.ConfigEval.length != evalConfig.length) hasConfigCoincidenceV2 = false;
                        else {
                            hasConfigCoincidenceV2 = evalConfig.every(itemCfg => { return val.ConfigEval.some(item => (itemCfg.Valor.trim().toLowerCase() == itemCfg.Valor.trim().toLowerCase() && itemCfg.Descripcion.trim().toLowerCase() == item.Descripcion.trim().toLowerCase())) })
                            // Si existe una configuración coincidente la agrega en el mapa en el elemento de configuración coincidente
                            if (hasConfigCoincidenceV2) { val.MateriasCalificadas.push(materia) }
                        }
                    })
                    // Si no hay coincidencia se agrega un nuevo elemento del mapa
                    if (!hasConfigCoincidenceV2) { mapMateriasPerEvalCfg.set(index, { ConfigEval: evalConfig, MateriasCalificadas: [materia] }); }
                })
                mapMateriasPerEvalCfg.forEach((val, key) => {
                    drawQualificationTbl(pdf, tipoEval, val.MateriasCalificadas, val.ConfigEval);
                })
            }
        })
        pdf.setDrawColor(STYLES.TableFillColor);

        contentY = (pdf as any).lastAutoTable.finalY + STYLES.Padding;

        // OBSERVACIONES DE LOS MAESTROS
        pdf.addPage();
        contentY = STYLES.Padding;
        autoTable(pdf, {
            startY: contentY,
            head: [{ "periodo": "", "observacion": _L("boleta_v4.teachers_comments"), "firmas": _L("boleta_v4.firmas") }],
            styles: {
                ...TableStylesAux,
                halign: "center",
                valign: "middle",
            },
            columnStyles: {
                0: { cellWidth: divWidth },
                1: { cellWidth: divWidth * 3, halign: "left" },
                2: { cellWidth: divWidth * 2 }
            },
            showHead: "firstPage",
            body: [
                [_L("boleta_v4.primer_trimestre").split(" ").join("\n"), boletaConfig.TeachersCommentsAllPeriods[0]],
                [_L("boleta_v4.segundo_trimestre").split(" ").join("\n"), boletaConfig.TeachersCommentsAllPeriods[1]],
                [_L("boleta_v4.tercer_trimestre").split(" ").join("\n"), boletaConfig.TeachersCommentsAllPeriods[2]],
                [_L("boleta_v4.final"), boletaConfig.TeachersCommentsAllPeriods[3]],
            ],
            bodyStyles: {
                minCellHeight: divWidth
            },
            theme: "grid",
            margin: TableMarginsAux,
        });

        contentY = (pdf as any).lastAutoTable.finalY;
        contentY += (STYLES.Padding * 5)

        pdf.setDrawColor(STYLES.TableLinesColor);

        // CALCULO DE POSICION DE ELEMENTOS DE FIRMA Y SELLO
        // EN BASE AL ALTO CONTENEDOR DE LA PAGINA Y (EL ALTO DE LOS ELEMENTOS DE FIRMA + UN PADDING ADICIONAL)
        const position = _L("boleta_v4.dir_gral");
        const heightSignElements = pdf.getTextDimensions(position).h
        if ((contentY + heightSignElements) > STYLES.HeightArea) {
            pdf.addPage();
            contentY = STYLES.Padding * 5;
        }
        const CenterXPoint = STYLES.Width / 2; // AND FINISHES
        const widthLineSign = (CenterXPoint / 4) * 3;
        let startsLineX = (CenterXPoint / 2) - (widthLineSign / 2);
        pdf.line(startsLineX, contentY, startsLineX + widthLineSign, contentY);
        startsLineX = (CenterXPoint + (CenterXPoint / 2) - (widthLineSign / 2))
        pdf.line(startsLineX, contentY, startsLineX + widthLineSign, contentY)
        contentY += STYLES.Padding / 2;
        let dimSignText = pdf.getTextDimensions(position);
        pdf.setFontSize(STYLES.FontSizeMedium);
        pdf.text(position, (CenterXPoint / 2) - (dimSignText.w / 2), contentY);
        pdf.text(_L("boleta_v4.sello_cole"), (CenterXPoint + (CenterXPoint / 2)) - (dimSignText.w / 2), contentY);

        pdf.addPage();
        contentY = STYLES.Padding;

        // ESTA PÁGINA (COMENTARIOS TUTORES QUEDA APARTE, DEBE DE INCLUIR LA INFORMACIÓN DEL ALUMNO [ALUMNO, FECHA NACIMIENTO, GRADO Y GRUPO, CICLO ESCOLAR])
        // ESTA HOJA SE ENTREGA A LOS PADRES Y ELLOS LA LLENAN ("Esto porque hay alumnos que se van a mitad de ciclo y necesitan como este comprobante")
        // LA EDICIÓN DE LA INFO DE ESTA HOJA SE CONTEMPLA PARA LA 3RA ENTREGA
        // INFO ALUMNO
        autoTable(pdf, {
            startY: contentY,
            tableWidth: tableWidth,
            head: [{ "clave": "", "valor": "" }],
            styles: {
                ...TableStylesAux,
                halign: "left",
                valign: "middle",
                cellPadding: 1
            },
            columnStyles: {
                0: { cellWidth: tableWidth / 10 * 4 },
            },
            showHead: false,
            body: [
                ...dataInfoGralBoleta
            ],
            theme: "grid",
            margin: {
                ...TableMarginsAux,
                left: STYLES.Padding,
            },
        })
        contentY = (pdf as any).lastAutoTable.finalY + STYLES.Padding;

        autoTable(pdf, {
            startY: contentY,
            tableWidth: STYLES.WidthArea,
            head: [{ "title": _L("boleta_v4.tutors_comments") }],
            styles: {
                font: STYLES.FontFamily,
                fontSize: STYLES.FontSizeMedium,
                fillColor: STYLES.TableFillColor,
                textColor: STYLES.FontColor,
                halign: "center",
                valign: "middle",
                fontStyle: "normal",
                cellPadding: 1,
            },
            theme: "plain",
            margin: TableMarginsAux,
        })

        contentY = (pdf as any).lastAutoTable.finalY + STYLES.Padding / 2;

        // TUTORES FIRMAS Y COMENTARIOS
        autoTable(pdf, {
            startY: contentY,
            head: [{ "ev": _L("boleta_v4.evaluacion"), "sign": _L("boleta_v4.firma"), "name": _L("boleta_v4.name"), "coments": _L("boleta_v4.comments") }],
            styles: {
                ...TableStylesAux,
                halign: "center",
                valign: "middle",
                minCellHeight: divWidth * .75
            },
            columnStyles: {
                0: { cellWidth: divWidth },
                1: { cellWidth: divWidth * 1.5 },
                2: { cellWidth: divWidth * 1.5, valign: "top", halign: "left" },
                3: { cellWidth: divWidth * 2, valign: "top", halign: "left" }
            },
            showHead: "firstPage",
            body: [
                [_L("boleta_v4.primer_trimestre").split(" ").join("\n"), , boletaConfig.TutoresNamesAllPeriods[0], boletaConfig.TutoresCommentsAllPeriods[0]],
                [_L("boleta_v4.segundo_trimestre").split(" ").join("\n"), , boletaConfig.TutoresNamesAllPeriods[1], boletaConfig.TutoresCommentsAllPeriods[1]],
                [_L("boleta_v4.tercer_trimestre").split(" ").join("\n"), , boletaConfig.TutoresNamesAllPeriods[2], boletaConfig.TutoresCommentsAllPeriods[2]],
                [_L("boleta_v4.final"), , boletaConfig.TutoresNamesAllPeriods[3], boletaConfig.TutoresCommentsAllPeriods[3]]
            ],
            theme: "grid",
            margin: TableMarginsAux,
        });
        contentY = (pdf as any).lastAutoTable.finalY + STYLES.Padding;

        autoTable(pdf, {
            startY: contentY,
            tableWidth: STYLES.WidthArea,
            head: [{ "title": TextFooterMimos }],
            styles: {
                font: STYLES.FontFamily,
                fontSize: STYLES.FontSizeMedium,
                fillColor: STYLES.TableFillColor,
                textColor: STYLES.FontColor,
                halign: "center",
                valign: "middle",
                fontStyle: "normal",
                cellPadding: 1,
            },
            theme: "plain",
            margin: TableMarginsAux,
        })

        var pageCount = pdf.getNumberOfPages(); // Total Page Number
        const devDrawGuideLines = false;
        for (let i = 0; i < pageCount; i++) {
            pdf.setPage(i);
            pdf.setFontSize(STYLES.FontSizePlain);
            let pageCurrent = pdf.getCurrentPageInfo().pageNumber; // Current Page

            if (devDrawGuideLines) {
                pdf.setDrawColor("#F95410");
                pdf.line(STYLES.Padding, 0, STYLES.Padding, STYLES.Height);
                pdf.line(STYLES.Width - STYLES.Padding, 0, STYLES.Width - STYLES.Padding, STYLES.Height);
                pdf.line(0, STYLES.Padding, STYLES.Width, STYLES.Padding);
                pdf.line(0, STYLES.Height - STYLES.Padding, STYLES.Width, STYLES.Height - STYLES.Padding);
            }

            pdf.text(
                `${pageCurrent}`,
                (STYLES.Width - STYLES.Padding - pdf.getTextDimensions(pageCurrent + "").w),
                (STYLES.Height - STYLES.Padding + pdf.getTextDimensions("1").h),
            );
        }
        return pdf;
    }
}
