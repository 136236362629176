import * as d3 from "d3";
import { UIUtilLang } from "../util/Language";
import { Button } from "./Button";

export namespace ItemsController {
    type TID = any; // (string | number);
    type TStep = "enter" | "update" | "exit";

    export interface IConfig<TData> {
        Container: d3.Selection<HTMLElement, any, any, any>;
        OnClickAddBtn?: () => IItem<TData> | Promise<IItem<TData>>;
        /** @default true */
        ShowAreaClearBtn?: boolean;
        OnDrawItemBtnAdd?: (btnContainer: TSelectionHTML<"div">) => void;
        OnStepItem?: (container: TSelectionHTML<"div">, itemData: TData, id: TID, step: TStep) => void;
        OnRemoveItem?: (id: TID, data: TData) => void;
        OnClear?: () => void;
        OnChange?: () => void;
    }

    interface IItem<TData> {
        Id: TID;
        Data: TData;
    }

    export class ItemsController<TD> {
        private config: IConfig<TD>;
        private data: IItem<TD>[];
        private controlContainer: TSelectionHTML<"div">;

        private refreshLimitTimeFlag: NodeJS.Timeout;

        constructor(config: IConfig<TD>) {
            this.config = { ...config };
            this.data = [];

            if (this.config.ShowAreaClearBtn == null) {
                this.config.ShowAreaClearBtn = true;
            }

            this.UI_Init();
        }

        private UI_Init() {
            this.controlContainer = this.config.Container
                .append("div")
                .attr("class", "anyitemscontrol_cont");

            let itemsContainer = this.controlContainer
                .append("div")
                .attr("class", "anyitems_cont");

            // >> Btn Add item
            if (this.config.OnClickAddBtn) {
                const itemBtnAdd = itemsContainer.append("div")
                    .attr("class", "item_base item_button")

                itemBtnAdd
                    .node()
                    .onclick = async e => {
                        let getItem = this.config.OnClickAddBtn()
                        let item: any;

                        if (getItem instanceof Promise) {
                            item = await getItem;
                        } else {
                            item = getItem;
                        }

                        if (item) {
                            this.SetItems(item);
                        } else {
                            console.debug("Fail item");
                        }

                    }

                if (this.config.OnDrawItemBtnAdd) {
                    this.config.OnDrawItemBtnAdd(itemBtnAdd);
                } else {
                    itemBtnAdd
                        .append("label")
                        .style("font-weight", "bold")
                        .style("font-size", "20px")
                        .text("+")
                }

                // >> area btn clear
                if (this.config.ShowAreaClearBtn) {
                    this.controlContainer
                        .append("div")
                        .attr("class", "clear_cont")
                        .append<HTMLLabelElement>("label")
                        .text(UIUtilLang._GetUIString("general", "clear"))
                        .node()
                        .addEventListener("click", e => {
                            this.Clear();
                        });
                }
            }
        }

        private UI_Refresh() {
            if (this.refreshLimitTimeFlag != null) {
                clearTimeout(this.refreshLimitTimeFlag);
            }

            this.refreshLimitTimeFlag = setTimeout(() => {
                const itemsContainer = this.controlContainer
                    .select<HTMLDivElement>(":scope > .anyitems_cont");

                itemsContainer
                    .selectAll<HTMLDivElement, IItem<TD>>(":scope > .item_d")
                    .data(this.data, (d, i) => i)
                    .join(
                        enter => {
                            const enterItems = enter
                                .append("div")
                                .attr("class", "item_base item_d")
                                .style("opacity", 0);

                            enterItems.append("div")
                                .attr("class", "cont");

                            Button.BtnClose._GetCloseCircleButton(enterItems);

                            enterItems
                                .transition()
                                .duration(200)
                                .style("opacity", 1);

                            return this.UI_UpdateItem(enterItems, "enter");
                        },
                        update => this.UI_UpdateItem(update, "update"),
                        exit => {
                            this.UI_UpdateItem(exit, "exit")
                                .style("opacity", 1)
                                .transition()
                                .duration(200)
                                .style("opacity", 0)
                                .style("width", "0px")
                                .remove();
                        }
                    );

                itemsContainer
                    .select(":scope > .item_button")
                    .raise();

                this.refreshLimitTimeFlag = null;
            }, 200);
        }

        private UI_UpdateItem(items: TSelectionHTML<"div", IItem<TD>>, step: TStep): TSelectionHTML<"div", IItem<TD>> {
            return items
                .each((d, i, uiItems) => {
                    const uiItem = d3.select<HTMLDivElement, IItem<TD>>(uiItems[i]);
                    const uiItemContent = uiItem.select<HTMLDivElement>(":scope > .cont");

                    // >> Update UI
                    if (this.config.OnStepItem) {
                        this.config.OnStepItem(uiItemContent, d.Data, d.Id, step);
                    } else {
                        uiItemContent.text(d.Id + ": " + d.Data);
                    }

                    // Remove evt
                    uiItem
                        .select<SVGElement>(":scope > .btn_close_circle")
                        .raise()
                        .on("click", () => {
                            this.RemoveItems(d);
                        });
                });
        }

        private SetItems(...items: IItem<TD>[]) {
            this.data.push(...items);

            this.UI_Refresh();
            this.OnChange();
        }

        private RemoveItems(...idsItems: (IItem<TD> | number)[]) {
            idsItems
                .forEach(idItem => {
                    let index = -1;
                    let deletedItems: IItem<TD>[];

                    if ((typeof idItem == "number") || (typeof idItem == "string")) {
                        index = this.data.findIndex(d => (d.Id == idItem));
                    } else {
                        index = this.data.indexOf(idItem);
                    }

                    if (index > -1) {
                        deletedItems = this.data.splice(index, 1);
                    }

                    if (deletedItems?.length && this.config.OnRemoveItem) {
                        this.config.OnRemoveItem(deletedItems[0].Id, deletedItems[0].Data);
                    }
                });

            this.UI_Refresh();
            this.OnChange();
        }

        private Clear() {
            this.data.splice(0, this.data.length);
            this.UI_Refresh();

            if (this.config.OnClear) {
                this.config.OnClear();
            }

            this.OnChange();
        }

        private OnChange() {
            if (this.config.OnChange) {
                this.config.OnChange();
            }
        }

        // *********************************************************
        // PUBLIC METHODS
        // *********************************************************

        get _ControlSel() {
            return this.controlContainer;
        }

        public _Data(): IItem<TD>[] {
            return this.data;
            // .map(d => Object.assign({}, d));
        }

        public _GetItem(id: TID): IItem<TD> {
            return this.data
                .find(d => (d.Id == id));
        }

        public _Refresh(): this {
            this.UI_Refresh();
            return this;
        }

        public _SetItems(...items: IItem<TD>[]) {
            this.SetItems(...items);
            return this;
        }

        public _RemoveItems(...ids: number[]) {
            this.RemoveItems(...ids);
            return this;
        }

        public _UpdateData(...items: IItem<TD>[]) {
            this.Clear();
            this.SetItems(...items);
            return this;
        }

        public _Clear() {
            this.Clear();
            return this;
        }
    }
}
