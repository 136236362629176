import { group as d3Group } from "d3-array";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import DataModuloEscuela from "../../data/modulo/Escuela";
import DataModuloFinanzaCargo from "../../data/modulo/FinanzaCargo";
import { IConfigGridExcelExport, IGridExtraTableConfig, IGridRenderInfo, VentanaGrid } from "../controlD3/AVentanaGrid";
import { ExcelThings } from "../controlD3/ExcelExport";
import { Fields, FormGenerator, IField } from "../controlD3/Formulario";
import { ModalThings } from "../controlD3/ModalThings";
import { Table } from "../controlD3/Tabla";
import { UIUtilFormat } from "../util/Format";
import { UIUtilViewData } from "../util/ViewData";
import { UIUtilViewFinanzaCargo } from "../utilView/FinanzaCargo";

import CCategoriaTipoFinanza = Entidad.CFinanzaCargoCategoria;
//import CPeriodicidadCargos = Entidad.CFinanzaCargoPeriodicidadCargos;
import CTipoValor = Entidad.CFinanzaCargoTipoValor;
// import CTipoInteres = Entidad.CFinanzaCargoTipoInteres;
import IFinanzaCargo = Entidad.IFinanzaCargo;

import CAccionPermiso = Entidad.CAccionPermiso;
//import CTipoNotificacion = Notificacion.CTipoNotificacion;

// enum CEtapaProcesoCargo {
//     AValoresIniciales = 1, BPeriodicidad, CResumenFinal
// }

enum CFormModels {
    Nombre = "Nombre",
    TipoValor = "TipoValor",
    Valor = "Valor",
    // Modalidad = "Modalidad",
    Periodicidad = "Periodicidad",
    Escuela = "IdEscuela",
    TipoRecargo = "TipoRecargo",
    TipoInteres = "TipoInteres",
    ValorRecargo = "ValorRecargo"
}

export class UIVentanaFinanzasCargos extends VentanaGrid<IFinanzaCargo> {

    constructor(content: d3.Selection<HTMLDivElement, undefined, HTMLElement, any>, modulo: Entidad.CModulo) {
        super(content, modulo);
    }

    protected GRID_GetDataRequestID(): DataModuloMain.TipoRequestMonitorId {
        return Entidad.CTipoRequest.FinanzaCargo;
    }

    //#region <ConfiguracionGrid_inicial

    // < Config_OpcionesGrid_tableActions
    protected GRID_GetTableConfigBase(): IGridRenderInfo<IFinanzaCargo> {
        return {
            IdTabla: "FinanzasCatalogos-Ingresos",
            Title: "",
            DefaultSort: "Nombre",
            IdData: "ID",
            MinWidth: 800,
            Columns: [
                {
                    Field: "Nombre", Label: "Nombre", Width: "30%", MinWidth: "75px", ClassStyle: "link_item", OnClickInCellCont: (datum, event) => {
                        console.debug("Vent_FinCargo&Desc ->", datum)
                        if (this.GridHasPermisoAccion(CAccionPermiso.Ver)) {
                            this.OpenModal_Informacion(datum);
                        }
                    }
                },
                { Field: "Valor", Label: "Valor", Width: "15%", MinWidth: "75px", /* Align: "end" */ },
                { Field: "StrCategoria", Label: "Categoria", Width: "15%", MinWidth: "90px" },
                { Field: "getValorRecargoFmt", Label: "recargos", Width: "20%", MinWidth: "85px" },
                { Field: "StrEscuela", Label: "Escuela", Width: "20%", MinWidth: "75px" },
                // { Field: "NombreKinder", Label: "Escuela", Width: "25%", MinWidth: "75px" }
            ],
        }
    }

    protected GRID_GetTableConfigAdvanced(): IGridExtraTableConfig<IFinanzaCargo> {
        return <IGridExtraTableConfig<IFinanzaCargo>>{
            // AddNameFieldClassToCells: true,
            EvaluatorAndSubLevelsBuild: {
                OnStepCellTable: (container, dato, field) => {
                    switch (field) {
                        case "Valor":
                            if (dato.TipoValor == CTipoValor.Monto) {
                                container.text(UIUtilFormat._CurrencyFmt(dato.Valor));
                            } else if (dato.TipoValor == CTipoValor.Porcentaje) {
                                container.text(UIUtilFormat._PercentFmt(dato.Valor));
                            }
                            break;
                    }
                }
            }
        }
    }

    protected GRID_GetFilters(): Array<Table.IParametroFiltro<IFinanzaCargo>> {
        return [
            { Field: "Nombre", Label: "Nombre" },
            { Field: "Valor", Label: "Valor", Type: "number" },
            {
                Field: "Categoria",
                LabelLangKey: "d_field_strcategoria",
                Type: "select",
                Options: UIUtilViewData._GetList_CargoCategoria()
            },
            { Field: "getValorRecargoFmt" },
        ]
    }

    protected GRID_GetMenuTopGrid(): Array<Table.ITableMenuTopDefaultOptionConfig> {
        let opciones: Array<Table.ITableMenuTopDefaultOptionConfig> = [];

        if (this.GridHasPermisoAccion(CAccionPermiso.Agregar)) {
            opciones.push({
                Label: "action_addcargo",
                Callback: () => {
                    // console.debug("Vent_FinCargo&Desc -> Agregar")
                    // const cargo = <IFinanzaCargo>
                    const formCargo = UIUtilViewFinanzaCargo._GetFormAddEditCargo(CAccionPermiso.Agregar);
                    UIUtilViewFinanzaCargo._OpenModal_FormAddEditCargo(CAccionPermiso.Agregar, formCargo._Data, formCargo, "FinanzasCargos");
                }
            });
        }

        if (this.GridHasPermisoAccion(CAccionPermiso.AgregarDescuentos)) {
            opciones.push({
                Label: "action_adddesc",
                Callback: () => {
                    // console.debug("Vent_FinCargo&Desc ->", dato)
                    this.OpenModal_FormAddDesc();
                }
            });
        }
        return opciones
    }

    protected GRID_GetSelectionDataMenuV2(menuLocation: "row" | "top-selected", dataGridSelected: IFinanzaCargo[]): Table.ITableMenuDataSelectedOptionConfig<IFinanzaCargo>[] {
        let opciones: Array<Table.ITableMenuDataSelectedOptionConfig<IFinanzaCargo>> = [];

        if (this.GridHasPermisoAccion(CAccionPermiso.Editar)) {
            opciones.push({
                Label: "Editar",
                MultiData: false,
                Callback: (datos: Array<IFinanzaCargo>) => {
                    const datoCargoDesc = { ...datos[0] };
                    console.debug("Vent_FinCargo&Desc ->", datos)

                    const tiposCargo = [CCategoriaTipoFinanza.Cargo, CCategoriaTipoFinanza.CargoEntrada, CCategoriaTipoFinanza.CargoSalida]
                    if (tiposCargo.includes(datoCargoDesc.Categoria)) {
                        const formCargo = UIUtilViewFinanzaCargo._GetFormAddEditCargo(CAccionPermiso.Editar, datoCargoDesc);
                        UIUtilViewFinanzaCargo._OpenModal_FormAddEditCargo(CAccionPermiso.Editar, datoCargoDesc, formCargo, "FinanzasCargos");
                    } else if (datoCargoDesc.Categoria == CCategoriaTipoFinanza.Descuento) {
                        this.OpenModal_FormEditDesc(datoCargoDesc);
                    }
                }
            });
        }

        // if (permiso) {
        //     opciones.push({
        //             Label: "Asignar", MultiData: true, Callback: (datos) => console.debug("Vent_FinCargo&Desc ->", datos), OnValidateDisabling: () => false
        //         })
        // }

        // if (this.GridHasPermisoAccion(CAccionPermiso.Eliminar) && (datosCargos.every(d => (d.Categoria != CCategoriaTipoFinanza.CargoEntrada && d.Categoria != CCategoriaTipoFinanza.CargoSalida)))) {
        if (this.GridHasPermisoAccion(CAccionPermiso.Eliminar)) {
            opciones.push({
                Label: "Eliminar",
                Callback: (datos: Array<IFinanzaCargo>) => this.OpenModal_Eliminar(datos),
                GetDetails: (datos) => {
                    let enable = true;
                    let message = '';
                    if (!datos.every(d => (d.Categoria != CCategoriaTipoFinanza.CargoEntrada && d.Categoria != CCategoriaTipoFinanza.CargoSalida))) {
                        enable = false;
                        message = this.VB_GetUIStringModule("tlt_eliminar_desc_fail");
                    }
                    return {
                        Enabled: enable,
                        Description: message
                    }
                }
            });
        }
        return opciones;
    }

    // Config_OpcionesGrid_tableActions />

    //#endregion  ConfiguracionGrid_inicial />

    //#region < Formularios_Actions

    //***************************************************************************************
    // PROCESO AGREGAR/EDITAR CARGO
    //***************************************************************************************

    // private OpenFormularioEditarCargo(dato: IFinanzaCargo) {
    //     // this.form.prop_ControlsData.get(CFormModels.Modalidad).row.classed("hide", false);
    //     this.form.prop_ControlsData.get(CFormModels.Periodicidad).row.classed("hide", false);
    //     this.form.prop_ControlsData.get(CFormModels.TipoValor).row.classed("hide", true);
    //     this.form.prop_ControlsData.get(CFormModels.TipoRecargo).row.classed("hide", false);
    //     this.form.prop_ControlsData.get(CFormModels.TipoInteres).row.classed("hide", false);
    //     this.form.prop_ControlsData.get(CFormModels.ValorRecargo).row.classed("hide", false);

    //     this.MostrarModal({
    //         ExtraToTitle: "cargo",
    //         TipoModal: CAccionPermiso.Editar,
    //         Data: {
    //             ...dato,
    //             ...{
    //                 TipoInteres: dato.TipoRecargo == CTipoValor.Porcentaje ? dato.TipoInteres : CTipoInteres.Ninguno
    //             }
    //         },
    //         OnClose: () => {
    //             this.calendarGrid = null;
    //         }
    //     });

    //     this.form.prop_ControlsData.get(CFormModels.TipoInteres).selection.select(".itemradio").classed("hide", true); // opción 'Ninguno' oculto

    //     FinanzasCargos.ApplyTypeValueToInputValue(dato.TipoValor, "valorCargo", false, this.form);
    //     FinanzasCargos.ApplyTypeValueToInputValue(dato.TipoRecargo, "valorRecargo", false, this.form);
    //     this.OpenFormularioCargo_ValoresIniciales(CAccionPermiso.Editar);
    // }

    protected GRID_GetExportarConfig(dataGrid: IFinanzaCargo[]): IConfigGridExcelExport<any> {
        return {
            IdsEscuelas: [...new Set(dataGrid.map(d => d.IdEscuela))],
            ColumnsConfig: this.ctrlTabla
                ._InfoColumns
                .map<ExcelThings.IColumnToExcelExportFileConfig<IFinanzaCargo>>((d) => ({
                    Field: d.Field as keyof IFinanzaCargo,
                    HeaderTag: d.Label,
                    WidthCell: 25
                })),
            OnGetDataBySheets: async () => {
                return Array.from(d3Group(dataGrid, d => d.IdEscuela))
                    .map<ExcelThings.ISheetConfig<IFinanzaCargo>>(entrie => ({
                        IdSheet: entrie[0], // IdEscuela
                        SheetName: entrie[1][0].StrEscuela,
                        Data: entrie[1]
                    }))
            },
            OnGetEscuelasTagInSheet: (dataSheet) => dataSheet[0].StrEscuela,
            OnStepFieldInDataRow: (field, dato) => {
                switch (field) {
                    case "Valor":
                        return UIUtilFormat._CurrencyFmt(dato[field]); // Ajustar la celda Excel a currency, en lugar de mandar un string
                    default:
                        return dato[field];
                }
            }
        }
    }

    //***************************************************************************************
    // PROCESO AGREGAR/EDITAR DESCUENTO
    //***************************************************************************************

    private OpenModal_FormAddDesc() {
        this.GridOpenModal_ActionFormToAGridData({
            Width: 400,
            Action: CAccionPermiso.AgregarDescuentos,
            GetForm: () => this.GetFormDescuento(CAccionPermiso.AgregarDescuentos, <IFinanzaCargo>{ Categoria: CCategoriaTipoFinanza.Descuento, TipoValor: CTipoValor.Monto }),
            OnAccept: (form, mt) => {
                let formData = form._Data;
                return DataModuloFinanzaCargo._InsertDescuento(formData);
            }
        })
    }

    private OpenModal_FormEditDesc(descuento: IFinanzaCargo) {
        this.GridOpenModal_ActionFormToAGridData({
            Width: 400,
            Action: CAccionPermiso.Editar,
            IdsEscuelas: [descuento.IdEscuela],
            GetForm: () => this.GetFormDescuento(CAccionPermiso.Editar, descuento),
            OnAccept: (form, mt) => {
                let formData = form._Data;
                return DataModuloFinanzaCargo._UpdateDescuento(formData);
            }
        })
    }

    private GetFormDescuento(action: (CAccionPermiso.AgregarDescuentos | CAccionPermiso.Editar), descuento: IFinanzaCargo) {
        let esEditar = (action == CAccionPermiso.Editar);
        let escuelasConPermiso = this.GridGetEscuelasConPermisoDeAccion(action, descuento.IdEscuela);
        //let escuelasConPermiso = Util.Permission.fn_GetSchoolsByActionModule(Entidad.CModulo.FinanzasCargo, action, descuento.IdEscuela);
        let form = new FormGenerator<IFinanzaCargo>();
        let formSchema: Array<IField<IFinanzaCargo>> = [
            {
                model: CFormModels.Nombre,
                type: Fields.input,
                inputAttr: { type: "text", disabled: false, removeBorder: false, required: true },
                labelAttr: { text: "frm1_nombre" },
            },
            {
                model: CFormModels.TipoValor,
                type: Fields.radioList,
                labelAttr: { text: "frm1_tipovalor" },
                radioListAttr: {
                    ValueMember: "Id",
                    DisplayMember: "Name",
                    ReturnOnlyValueMember: true,
                    Data: UIUtilViewData._GetList_TipoValor(),
                    required: true,
                    OnChange: (data: UIUtilViewData.IBaseData) => {
                        UIUtilViewFinanzaCargo._ApplyTypeValueToInputValue(data.Id, "valorCargo", form);
                    }
                }
            },
            {
                model: CFormModels.Valor,
                type: Fields.input,
                inputAttr: { type: "number", min: 0, required: true },
                labelAttr: { text: "frm1_valor" }
            },
            {
                model: CFormModels.Escuela,
                type: Fields.selectMaterial,
                labelAttr: { text: "frm1_esc" },
                selectMaterialAttr: { valueMember: "IdKinder", displayMember: "Nombre", disabled: esEditar, removeBorder: esEditar, required: true },
                values: escuelasConPermiso
            }
        ];

        let validaValor = (valor: number, tipoValor: CTipoValor) => {
            if (tipoValor == CTipoValor.Porcentaje) {
                return valor > 0 && valor <= 100;
            }
            else if (tipoValor == CTipoValor.Monto) {
                return valor > 0;
            } else {
                return false;
            }
        }

        form._Crear({
            LangModuleKeyInContext: "finanzascargo",
            schema: formSchema,
            LabelMaxWidth: 100,
            Validation: (value, field, dataForm, controlsForm) => {
                let isValid = true;

                if (field == "Valor") {
                    let strDecims = dataForm["Valor"].toString().split(".")[1];
                    if (strDecims) isValid = !(strDecims.length > 2);

                    if (isValid) {
                        dataForm.Valor = Number(dataForm.Valor);
                        isValid = validaValor(dataForm.Valor, form._Data.TipoValor);
                    }
                }
                return isValid;
            },
        }, descuento);
        UIUtilViewFinanzaCargo._ApplyTypeValueToInputValue(descuento.TipoValor, "valorCargo", form);

        return form;
    }

    private OpenModal_Eliminar(datos: Array<IFinanzaCargo>) {
        this.GridOpenModal_ProccessArrayData({
            DataToProccess: datos,
            Message: "confirma_eliminar",
            Width: 350,
            OnGetIdEscuela: (dato) => dato.IdEscuela,
            OnError_GetItemDataTag: (dato) => `${dato.Nombre} (${DataModuloEscuela._DiccFullEscuelas.get(dato.IdEscuela)?.Nombre})`,
            OnStepAProccess: null,
            OnStepAllProccess: (dato: IFinanzaCargo[]) => this.Sv_DataEliminarCargoDescuento(datos)
        })
    }

    private OpenModal_Informacion(dato: IFinanzaCargo) {
        ModalThings._GetModalSimple({
            Title: "tag_inforeg",
            LangModuleKeyInContext: this.labelsKeyBase,
            DrawContent: (content, modalThings) => {
                UIUtilViewFinanzaCargo._ApplyInfoTagsV2(dato, content);
            }
        })
    }
    //#endregion Formularios_Actions />

    //#region < Operaciones_Data

    private Sv_DataEliminarCargoDescuento(datos: IFinanzaCargo[]) {
        return DataModuloFinanzaCargo._Delete(datos.map(d => d.ID));
    }

    //#endregion Operaciones_Data />

    //#region <TEST
    /** TEST: Equivale a app.ReloadService(data.Entidades.CTipoRequest.FinanzasCargos); */
    // private UpdateGridDataTest() {
    //     this.GridUpdateData();
    //     this.ConfigProgressBar.Hide();
    // }
    //#endregion TEST />
}
