import { ArrayV2 } from "../../util/ArrayV2";
import { DataDRequest } from "../DRequest";
import { Entidad } from "../Entidad";
import { Global } from "../Global";
import { DataUtil } from "../util/Util";

import ILogroCategoria = Entidad.ILogroCategoria;
import IEscuela = Entidad.IEscuela;

export const _DiccCategorias: Map<number, ILogroCategoria> = new Map();

const IDREQUEST = Entidad.CTipoRequest.LogroCategoria;
const URL_LOGROCATEGORIA = Global._GLOBAL_CONF.URL_RSTV2 + "logro/";

export function _GetList(escuelas: IEscuela[]): Promise<DataDRequest.IRequestResponseListA<ILogroCategoria>[]> {
    const url = URL_LOGROCATEGORIA + "ObtenerListaCategoria";
    return new ArrayV2<IEscuela>()
        ._Push(...escuelas)
        ._MapAwait(async ({ IdKinder }) => {
            const currentDateStr = await DataUtil._GetMaxDate(IDREQUEST, IdKinder);
            const params = {
                "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
                "IdEscuela": IdKinder,
                "Modificacion": currentDateStr
            }
            return DataDRequest._Request4List(url, "GET", params, IDREQUEST, IdKinder);
        });
}

export async function _AltaCategoria(logroCat: ILogroCategoria): Promise<DataDRequest.IResultadoPeticion<undefined>> {
    let url = Global._GLOBAL_CONF.URL_RSTV1 + "Logro/NuevaCategoria";
    const params = {
        "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "IdKinder": logroCat.IdKinder,
        "Nombre": logroCat.Nombre,
        "Imagen": logroCat["Imagen"]
    }
    return new Promise(function (resolve, reject) {
        DataDRequest._Request(url, "POST", params, (result: any) => {
            let response = { Resultado: (!result) ? - 1 : result, TipoRequest: IDREQUEST }
            resolve(response);
        });
    });
}

export async function _ActualizarCategoria(logroCat: ILogroCategoria): Promise<DataDRequest.IRequestResponseA> {
    const url = Global._GLOBAL_CONF.URL_RSTV1 + "Logro/ActualizarCategoria";
    const params = {
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "IdCategoria": logroCat.IdCategoria,
        "Nombre": logroCat.Nombre
    }
    return new Promise(function (resolve, reject) {
        DataDRequest._Request<number>(url, "POST", params, (result) => {
            resolve({
                Resultado: (result == null ? -100 : result),
                TipoRequest: IDREQUEST,
            });
        });
    });
}


export async function _EliminaCategoria(logroCat: ILogroCategoria): Promise<DataDRequest.IRequestResponseA> {
    let url = Global._GLOBAL_CONF.URL_RSTV1 + "Logro/EliminarCategoria";
    const params = {
        "IdCategoria": logroCat.IdCategoria,
        "IdUsuario": DataUtil._Usuario.IdUsuario
    }
    return new Promise(function (resolve, reject) {
        DataDRequest._Request<number>(url, "POST", params, (result) => {
            resolve({
                Resultado: (result == null ? -100 : result),
                TipoRequest: IDREQUEST,
            });
        });
    });
}

export function _GetUrlObtenerFotoCategoria(idCategoria: number) {
    return Global._GLOBAL_CONF.URL_RSTV1 + "Logro/ObtenerImagenCategoria?IdCategoria=" + idCategoria;
}

export default {
    _DiccCategorias,
    _AltaCategoria,
    _ActualizarCategoria,
    _EliminaCategoria,
    _GetUrlObtenerFotoCategoria
}
