import { ArrayV2 } from "../../util/ArrayV2";
import { DataDRequest } from "../DRequest";
import { Entidad } from "../Entidad";
import { Global } from "../Global";
import { DataUtil } from "../util/Util";

import IProveedor = Entidad.IFinanzaProveedor;
const IDREQUEST = Entidad.CTipoRequest.Proveedor;

export const _DictProveedor = new Map<number, IProveedor>();

export async function _GetList(escuelas: Entidad.IEscuela[]): Promise<DataDRequest.IRequestResponseListA<IProveedor>[]> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "proveedor/ObtenerLista";

    return new ArrayV2(...escuelas)
        ._MapAwait<DataDRequest.IRequestResponseListA<IProveedor>>(async (escuela, i) => {
            const lastStrDate = await DataUtil._GetMaxDate(IDREQUEST, escuela.IdKinder);
            const params = {
                "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
                "IdEscuela": escuela.IdKinder,
                "Modificacion": lastStrDate,
            }

            return DataDRequest._Request4List(url, "GET", params, IDREQUEST, escuela.IdKinder);
        });
}

type IProveedorAgregar = Pick<IProveedor, "IdEscuela" | "RFC" | "RazonSocial" | "CP"> & Partial<Pick<IProveedor, "Telefono" | "Correo" | "Notas">>
export function _NuevoRegistro(dato: IProveedorAgregar) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "proveedor/Nuevo";
    const params = {
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
        "IdEscuela": dato.IdEscuela,
        "RazonSocial": dato.RazonSocial,
        "RFC": dato.RFC,
        "CP": dato.CP,
        "Telefono": dato.Telefono || "",
        "Correo": dato.Correo || "",
        "Notas": dato.Notas || "",
    };
    return DataDRequest._Request5(url, "POST", params, IDREQUEST);
}

export function _NuevosRegistrosMultiple(dato: Omit<IProveedorAgregar, keyof Pick<IProveedorAgregar, "IdEscuela">>, idsEscuelas: number[]) {
    return new ArrayV2<number>()
        ._Push(...idsEscuelas)
        ._ReduceAwait(async (result, idEscuela) => {
            const res = await _NuevoRegistro({
                ...dato,
                IdEscuela: idEscuela,
            })
            if (res.Resultado > 1) {
                result.Exitosos.push(idEscuela);
            } else {
                result.Fallidos.push({
                    Escuela: idEscuela,
                    Resultado: res.Resultado,
                });
                if (result.Fallidos.length == idsEscuelas.length) {
                    result.Resultado = result.Fallidos.length == 1 ? res.Resultado : 0;
                }
            }
            return result;
        }, <DataDRequest.IRequestResponseA & { Exitosos: number[]; Fallidos: { Escuela: number, Resultado: number }[]; }>{
            Resultado: 1,
            TipoRequest: IDREQUEST,
            Exitosos: [],
            Fallidos: [],
        })
}

export function _ActualizarRegistro(dato: IProveedor) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "proveedor/Actualizar";
    const params = {
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "Id": dato.Id,
        "RazonSocial": dato.RazonSocial,
        "RFC": dato.RFC,
        "CP": dato.CP,
        "Telefono": dato.Telefono,
        "Correo": dato.Correo,
        "Notas": dato.Notas,
    };
    return DataDRequest._Request5(url, "POST", params, IDREQUEST);
}

export function _EliminarRegistro(id: number) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "proveedor/Eliminar";
    const params = {
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "Id": id,
    };
    return DataDRequest._Request5(url, "POST", params, IDREQUEST);
}

export default {
    _DictProveedor,
    _NuevoRegistro,
    _NuevosRegistrosMultiple,
    _ActualizarRegistro,
    _EliminarRegistro
}
