import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import _L from "../../util/Labels";
import { PDFThings } from "../controlD3/PDFBuilder";
import { UIUtilGeneral } from "../util/Util";
import { Entidad } from "../../data/Entidad";
import { group as d3Group, descending } from "d3-array";
import { UIUtilViewCalificacion } from "./Calificacion";

import CTipoEvaluacion = Entidad.CTipoEvaluacion;
import IBoletaConfig = UIUtilViewCalificacion.IBoletaConfigV2;
import IInfoExtraBoleta = UIUtilViewCalificacion.IInfoExtraBoleta

const _METADATA = PDFThings._METADATA;

export namespace UIUtilViewCalificacionBoletaV3 {
    export async function _BoletaPDFV3(boletaConfig: IBoletaConfig) {
        /** Calificaciones de todos los periodos (Agrupadas por materias) 
         * 
         * Cada Criterio/Materia asignada tiene un una matriz de evaluaciones [P1[],P2[],P3[],PR[]]
        */
        const allPeriodsMaterias = boletaConfig.MateriasCalificacionesAllPeriods;

        const _CONTENT = {
            TopTitle: boletaConfig.Titulo || _METADATA.title,
            LogoURL: boletaConfig.Logo,
        }

        const _STYLES = {
            PageFormat: "A5",
            PaddingPercentFromWidthPage: 4,
            LogoDimPercentFromWidthPage: 18,
            TableFillColor: "#fff", TableLinesColor: "#d3d3d3", TableLineWidth: .1,
            FontFamily: "helvetica", FontSizeTitle: 14, FontSizePlain: 8, FontSizeMedium: 7, FontSizeShort: 5, FontColor: "#000",
        }

        const pdf = new jsPDF({ orientation: "portrait", unit: "px", format: _STYLES.PageFormat });

        const PAGE_WIDTH: number = pdf.internal.pageSize.getWidth();
        const PAGE_HEIGHT: number = pdf.internal.pageSize.getHeight();
        const PAGE_PADDING = (PAGE_WIDTH / 100 * _STYLES.PaddingPercentFromWidthPage);

        const STYLES = {
            ..._STYLES,
            ...{
                Padding: PAGE_PADDING, Height: PAGE_HEIGHT, Width: PAGE_WIDTH,
                LogoWidth: PAGE_WIDTH / 100 * _STYLES.LogoDimPercentFromWidthPage,
                WidthArea: PAGE_WIDTH - PAGE_PADDING * 2,
                HeightArea: PAGE_HEIGHT - PAGE_PADDING * 2,
            }
        }

        let contentY = STYLES.Padding;

        pdf.setProperties({
            title: boletaConfig.MetadataTitle || _CONTENT.TopTitle || _METADATA.title,
            subject: _METADATA.subject,
            author: _METADATA.author,
            creator: _METADATA.creator,
            keywords: _METADATA.keywords,
        });

        const TableStylesAux = {
            font: STYLES.FontFamily, fontSize: STYLES.FontSizePlain, textColor: STYLES.FontColor,
            lineColor: STYLES.TableLinesColor, lineWidth: STYLES.TableLineWidth,
            fillColor: STYLES.TableFillColor,
        }

        pdf.setLineWidth(STYLES.TableLineWidth);
        pdf.setDrawColor(STYLES.TableLinesColor);
        pdf.setFillColor(STYLES.TableFillColor);
        const TableMarginsAux = { top: STYLES.Padding, bottom: STYLES.Padding, left: STYLES.Padding, right: STYLES.Padding }

        /**
         * Dibuja una tabla de Materias - Evaluaciones
         * 
         * Recorre el arreglo de Materias - Evaluaciones => Evaluando si es una materia con criterios
         * @constant ArrDataCalificaciones: Matriz de Evaluaciones/Materias Valores [Nombre, P1, P2, P3, PR]
         * @constant ArrCriteriosIndex: almacena las posiciones en ArrDataCalificaciones de los elementos que representan criterios,
         * las posiciones que se encuentren en este arreglo serán formateadas en la tabla en la primer columna que es la columna que dibuja los nombres
         * 
         * Cuando es una materia con criterios se agrega a la matriz unicamente el nombre de la materia
         * Sus criterios se agregan igualmente a la matriz con sus respectivos valores, y se almacena en ArrCriteriosIndex la posición que ocupará en la matriz
         * 
         * @constant [arrP1Cals, arrP2Cals, arrP3Cals, arrPromCals]: Almacenan valores de evaluaciones de cada Periodo/Promedio 
         * 
         * @constant [PromP1, PromP2, PromP3, PromGral]: Almacenan los promedios de cada Periodo/Promedio
         */
        interface IAsignacionPeriod {
            Eval: string;
            MaxDtEval: string;
            MaxIdEval: number;
        }
        const drawQualificationTbl = (pdf: jsPDF, tipoEval: CTipoEvaluacion, data: UIUtilViewCalificacion.ICalificacionInfoAPeriods[]) => {
            const ArrDataCalificaciones: (string | number)[][] = [];
            const ArrCriteriosIndex: number[] = [];
            const arrP1CalsV2: Array<IAsignacionPeriod> = [];
            const arrP2CalsV2: Array<IAsignacionPeriod> = [];
            const arrP3CalsV2: Array<IAsignacionPeriod> = [];

            const arrP1Cals: string[] = [];
            const arrP2Cals: string[] = [];
            const arrP3Cals: string[] = [];
            const arrPromCals: string[] = [];
            let index = 0;
            data.forEach(d => {
                if (d.TieneCriterios) {
                    ArrDataCalificaciones.push([d.MateriaNombre])
                    index++;
                    d.Evaluaciones.forEach(c => {
                        ArrDataCalificaciones.push(
                            [
                                c.Nombre,
                                (c.Evaluaciones[0].length == 1 && c.Evaluaciones[0][0] == '') ? "-" : c.Evaluaciones[0].join("\n"),
                                (c.Evaluaciones[1].length == 1 && c.Evaluaciones[1][0] == '') ? "-" : c.Evaluaciones[1].join("\n"),
                                (c.Evaluaciones[2].length == 1 && c.Evaluaciones[2][0] == '') ? "-" : c.Evaluaciones[2].join("\n"),
                                (c.Evaluaciones[3].length == 1 && c.Evaluaciones[3][0] == '') ? "-" : c.Evaluaciones[3].join("\n"),
                            ]
                        )
                        ArrCriteriosIndex.push(index);
                        arrP1CalsV2.push(...c.Evaluaciones[0].map<IAsignacionPeriod>(d => ({ Eval: d, MaxDtEval: c.MaxDtsEvals[0], MaxIdEval: c.MaxIdsEvals[0] })))
                        arrP2CalsV2.push(...c.Evaluaciones[1].map<IAsignacionPeriod>(d => ({ Eval: d, MaxDtEval: c.MaxDtsEvals[1], MaxIdEval: c.MaxIdsEvals[1] })))
                        arrP3CalsV2.push(...c.Evaluaciones[2].map<IAsignacionPeriod>(d => ({ Eval: d, MaxDtEval: c.MaxDtsEvals[2], MaxIdEval: c.MaxIdsEvals[2] })))
                        arrP1Cals.push(...c.Evaluaciones[0])
                        arrP2Cals.push(...c.Evaluaciones[1])
                        arrP3Cals.push(...c.Evaluaciones[2])
                        arrPromCals.push(...c.Evaluaciones[3])
                        index++;
                    })
                } else {
                    ArrDataCalificaciones.push(
                        [
                            d.MateriaNombre,
                            (d.Evaluaciones[0].Evaluaciones[0].length == 1 && d.Evaluaciones[0].Evaluaciones[0][0] == '') ? "-" : d.Evaluaciones[0].Evaluaciones[0].join("\n"),
                            (d.Evaluaciones[0].Evaluaciones[1].length == 1 && d.Evaluaciones[0].Evaluaciones[1][0] == '') ? "-" : d.Evaluaciones[0].Evaluaciones[1].join("\n"),
                            (d.Evaluaciones[0].Evaluaciones[2].length == 1 && d.Evaluaciones[0].Evaluaciones[2][0] == '') ? "-" : d.Evaluaciones[0].Evaluaciones[2].join("\n"),
                            (d.Evaluaciones[0].Evaluaciones[3].length == 1 && d.Evaluaciones[0].Evaluaciones[3][0] == '') ? "-" : d.Evaluaciones[0].Evaluaciones[3].join("\n"),
                        ]
                    )
                    arrP1CalsV2.push(...d.Evaluaciones[0].Evaluaciones[0].map<IAsignacionPeriod>(ev => ({ Eval: ev, MaxDtEval: d.Evaluaciones[0].MaxDtsEvals[0], MaxIdEval: d.Evaluaciones[0].MaxIdsEvals[0] })))
                    arrP2CalsV2.push(...d.Evaluaciones[0].Evaluaciones[1].map<IAsignacionPeriod>(ev => ({ Eval: ev, MaxDtEval: d.Evaluaciones[0].MaxDtsEvals[1], MaxIdEval: d.Evaluaciones[0].MaxIdsEvals[1] })))
                    arrP3CalsV2.push(...d.Evaluaciones[0].Evaluaciones[2].map<IAsignacionPeriod>(ev => ({ Eval: ev, MaxDtEval: d.Evaluaciones[0].MaxDtsEvals[2], MaxIdEval: d.Evaluaciones[0].MaxIdsEvals[2] })))
                    arrP1Cals.push(...d.Evaluaciones[0].Evaluaciones[0]);
                    arrP2Cals.push(...d.Evaluaciones[0].Evaluaciones[1]);
                    arrP3Cals.push(...d.Evaluaciones[0].Evaluaciones[2]);
                    arrPromCals.push(...d.Evaluaciones[0].Evaluaciones[3]);
                    index++;
                }
            })

            arrP1CalsV2.sort((a, b) => descending(a.MaxIdEval, b.MaxIdEval));
            arrP1CalsV2.sort((a, b) => descending(a.MaxDtEval, b.MaxDtEval));

            arrP2CalsV2.sort((a, b) => descending(a.MaxIdEval, b.MaxIdEval));
            arrP2CalsV2.sort((a, b) => descending(a.MaxDtEval, b.MaxDtEval));

            arrP3CalsV2.sort((a, b) => descending(a.MaxIdEval, b.MaxIdEval));
            arrP3CalsV2.sort((a, b) => descending(a.MaxDtEval, b.MaxDtEval));

            const arrPromPeriods: string[] = [];

            // let PromP1 = [UIUtilViewCalificacion._GetEvalGral(arrP1CalsV2.map(d => d.Eval), tipoEval)[0]];
            // let PromP2 = [UIUtilViewCalificacion._GetEvalGral(arrP2CalsV2.map(d => d.Eval), tipoEval)[0]];
            // let PromP3 = [UIUtilViewCalificacion._GetEvalGral(arrP3CalsV2.map(d => d.Eval), tipoEval)[0]];

            // arrPromPeriods.push(...PromP3)
            // arrPromPeriods.push(...PromP2)
            // arrPromPeriods.push(...PromP1)


            let PromP1 = !arrP1CalsV2.length ? [] : [UIUtilViewCalificacion._GetEvalGral(arrP1CalsV2.map(d => d.Eval), tipoEval)[0]];
            let PromP2 = !arrP2CalsV2.length ? [] : [UIUtilViewCalificacion._GetEvalGral(arrP2CalsV2.map(d => d.Eval), tipoEval)[0]];
            let PromP3 = !arrP3CalsV2.length ? [] : [UIUtilViewCalificacion._GetEvalGral(arrP3CalsV2.map(d => d.Eval), tipoEval)[0]];

            arrPromPeriods.push((PromP3[0] == null) ? '' : PromP3[0])
            arrPromPeriods.push((PromP2[0] == null) ? '' : PromP2[0])
            arrPromPeriods.push((PromP1[0] == null) ? '' : PromP1[0])

            let PromGral = [UIUtilViewCalificacion._GetEvalGral(arrPromPeriods, tipoEval)[0]];

            PromP1 = (!PromP1.length) ? [""] : !PromP1.filter(d => d != "").length ? ["-"] : PromP1;
            PromP2 = (!PromP2.length) ? [""] : !PromP2.filter(d => d != "").length ? ["-"] : PromP2;
            PromP3 = (!PromP3.length) ? [""] : !PromP3.filter(d => d != "").length ? ["-"] : PromP3;
            PromGral = !PromGral.filter(d => d != "").length ? ["-"] : PromGral;

            ArrDataCalificaciones.push([_L("boleta_v3.promedio"), PromP1.join("\n"), PromP2.join("\n"), PromP3.join("\n"), PromGral.join("\n")])

            autoTable(pdf, {
                startY: contentY,
                tableWidth: STYLES.WidthArea,
                head: [{ "title": _L("boleta_v3.asig_oficiales") }],
                styles: { ...TableStylesAux, halign: "center", valign: "middle", },
                theme: "grid",
                margin: TableMarginsAux,
            });

            let circleIndexes = {};
            let rawRowIndex = -1;

            function getCellKey(rowIndex: number, colIndex: number) {
                return `${rowIndex}_${colIndex}`;
            }

            contentY = (pdf as any).lastAutoTable.finalY;
            const heightTextAux = pdf.getTextDimensions("T").h;
            let minCellHeight = 0;
            autoTable(pdf, {
                startY: contentY,
                head: [{ "materias": "", "calP1": "", "calP2": "", "calP3": "", "calProm": "" }],
                tableWidth: STYLES.WidthArea,
                showHead: false,
                body: ArrDataCalificaciones,
                theme: "grid",
                styles: { ...TableStylesAux },
                bodyStyles: { halign: "center", valign: "middle" },
                margin: TableMarginsAux,
                columnStyles: {
                    1: { cellWidth: widthRect, fontSize: (tipoEval == CTipoEvaluacion.Cualitativa) ? STYLES.FontSizeShort : STYLES.FontSizePlain },
                    2: { cellWidth: widthRect, fontSize: (tipoEval == CTipoEvaluacion.Cualitativa) ? STYLES.FontSizeShort : STYLES.FontSizePlain },
                    3: { cellWidth: widthRect, fontSize: (tipoEval == CTipoEvaluacion.Cualitativa) ? STYLES.FontSizeShort : STYLES.FontSizePlain },
                    4: { cellWidth: widthRect, fontSize: (tipoEval == CTipoEvaluacion.Cualitativa) ? STYLES.FontSizeShort : STYLES.FontSizePlain },
                    5: { cellWidth: widthRect, fontSize: (tipoEval == CTipoEvaluacion.Cualitativa) ? STYLES.FontSizeShort : STYLES.FontSizePlain },
                },
                rowPageBreak: tipoEval == CTipoEvaluacion.Colores ? "avoid" : "auto",
                didParseCell: (data) => {
                    if (data.column.index != 0 && tipoEval == CTipoEvaluacion.Colores && data.cell.raw && typeof data.cell.raw == 'string' && data.cell.raw.split("")[0] == "#") {
                        data.cell.text = []
                        const nEvals = data.cell.raw.split("\n").length;
                        // Alto mínimo de celda = Padding Top de 3 + separación de 2px bajo cada círculo + PaddingBottom de 1
                        minCellHeight = 3 + ((nEvals * ((heightTextAux - 2) * 2)) + (nEvals * 2)) + 1;
                        data.cell.styles.minCellHeight = minCellHeight;

                        // Inicializar el índice del círculo para esta celda si no existe
                        var cellKey = getCellKey(data.row.index, data.column.index);
                        if (!circleIndexes[cellKey]) {
                            circleIndexes[cellKey] = 0;
                        }
                    }
                    if (data.column.index === 0) {
                        data.cell.styles.halign = "left";
                    }
                    if (data.column.index === 0 && data.row.index === (ArrDataCalificaciones.length - 1) && data.cell.raw === _L("boleta_v3.promedio")) {
                        data.cell.styles.fontStyle = 'bold';
                    }
                    if (data.column.index === 0 && ArrCriteriosIndex.includes(data.row.index)) {
                        data.cell.styles.cellPadding = { vertical: 2, left: STYLES.Padding, right: 3 }
                    }
                },
                didDrawCell: (data) => {
                    if (data.column.index != 0 && tipoEval == CTipoEvaluacion.Colores && data.cell.raw && typeof data.cell.raw == 'string' && data.cell.raw.split("")[0] == "#") {
                        const cellWidth = data.cell.width;
                        const cellHeight = data.cell.height;
                        const centerX = data.cell.x + cellWidth / 2;

                        const evaluations = data.cell.raw.split("\n");
                        const nEvals = evaluations.length;
                        const heightCirclesGroup = ((nEvals * ((heightTextAux - 2) * 2)) + ((nEvals - 1) * 2));
                        let PosY = data.cell.y + ((cellHeight - heightCirclesGroup) / 2);
                        const radius = heightTextAux - 2;
                        evaluations.forEach(value => {
                            pdf.setFillColor(value);
                            pdf.circle(centerX, PosY + radius, radius, 'DF');
                            PosY += (radius * 2) + 2
                        });
                    }
                },

            })
            contentY = (pdf as any).lastAutoTable.finalY;
            contentY += STYLES.Padding;
        }

        // ******** TOP CONTENT (LOGO ESCUELA   ->   TITULO) ********
        pdf.setFontSize(STYLES.FontSizeTitle);
        pdf.setFont(STYLES.FontFamily, "", "normal");
        contentY += pdf.getTextDimensions("T").h;

        let textDims = pdf.getTextDimensions(_L("boleta_v3.historial_academico"));
        let heightLogo = 0;

        // 
        await UIUtilGeneral._GetIMGElementOnLoadResource(_CONTENT.LogoURL)
            .then(img => {
                // Dibujado de Imagen
                heightLogo = img.height * STYLES.LogoWidth / img.width;
                pdf.addImage(img, "JPEG", STYLES.Padding, STYLES.Padding, STYLES.LogoWidth, heightLogo);
                contentY += (heightLogo / 2) - (textDims.h / 2);
            })
            .catch(() => {
                // Si la imagen falla toma un alto definido por 50
                heightLogo = 50;
                contentY += (heightLogo / 2) - (textDims.h / 2);
                console.warn("-d Fail img LOAD");
            })

        pdf.text(_L("boleta_v3.historial_academico"), (STYLES.Width - (STYLES.Padding + textDims.w)), contentY);
        contentY += ((textDims.h / 2) - (heightLogo / 2));
        contentY += heightLogo;

        pdf.setFontSize(STYLES.FontSizePlain);

        // La tabla tendrá que llenarse de arriba hacia abajo y hacia al lado
        // si existe CURP, entonces deberá de seguir al alumno
        // la demás información extra deberá de ser después de grado
        // Después de eso, por último deberá de ir la fecha

        // Conformación de InfoGral para boleta
        const ArrTagsNatural = [_L("boleta_v3.alumno") + ":", _L("boleta_v3.grado") + ":", _L("boleta_v3.fecha") + ":"];
        const [alumnoTag, gradoTag, fechaTag] = ArrTagsNatural;
        // Numero de Elementos Info
        const LengthTags = ArrTagsNatural.length + (boletaConfig.InfoExtra?.length || 0);
        let ArrInfoExtra = [...boletaConfig.InfoExtra];
        const CurpItem = ArrInfoExtra.find(d => d.Tag.toUpperCase() == _L("boleta_v3.curp"));
        ArrInfoExtra = ArrInfoExtra.filter(d => d.Tag.toUpperCase() != _L("boleta_v3.curp"));
        const arrInfoBoleta: IInfoExtraBoleta[] = [];
        arrInfoBoleta.push({ Tag: alumnoTag, Val: boletaConfig.NombreAlumno });
        if (CurpItem) arrInfoBoleta.push({ Tag: CurpItem.Tag + ":", Val: CurpItem.Val });
        arrInfoBoleta.push({ Tag: gradoTag, Val: boletaConfig.Grado });
        ArrInfoExtra.forEach(d => { arrInfoBoleta.push({ Tag: d.Tag + ":", Val: d.Val }) });
        arrInfoBoleta.push({ Tag: fechaTag, Val: boletaConfig.Fecha });

        // Se dividen los items para ubicarlos en 2 columnas
        const arrInfoBoletaCol1: IInfoExtraBoleta[] = arrInfoBoleta.slice(0, Math.round(LengthTags / 2));
        const arrInfoBoletaCol2: IInfoExtraBoleta[] = arrInfoBoleta.slice(Math.round(LengthTags / 2), LengthTags);

        // ["COL 1, COL 2"]
        // Se recorre el arreglo de la primer columna de elementos y se va juntando con sus posiciones correspondientes en el arreglo de la columna 2
        const dataInfoExtra: (string | number)[][] = [];
        arrInfoBoletaCol1.forEach((d, i) => {
            const col2 = arrInfoBoletaCol2[i];
            dataInfoExtra.push([d.Tag, d.Val, col2 ? col2.Tag : "", col2 ? col2.Val : ""])
        })

        // Dibujado de tabla Info boleta (La estructura de la tabla no es visible)
        autoTable(pdf, {
            startY: contentY,
            head: [{ "col1": "", "col1Val": "", "col2": "", "col2Val": "" }],
            showHead: false,
            body: dataInfoExtra,
            styles: {
                font: STYLES.FontFamily,
                fillColor: STYLES.TableFillColor,
                textColor: STYLES.FontColor,
                fontSize: STYLES.FontSizePlain,
                halign: "left",
                valign: "middle",
            },
            columnStyles: {
                0: { cellWidth: (STYLES.WidthArea / 2) / 3 }, 1: { cellWidth: (STYLES.WidthArea / 2) / 3 * 2 },
                2: { cellWidth: (STYLES.WidthArea / 2) / 3 }, 3: { cellWidth: (STYLES.WidthArea / 2) / 3 * 2 }
            },
            theme: "plain",
            margin: TableMarginsAux,
        })

        contentY = (pdf as any).lastAutoTable.finalY + STYLES.Padding;
        pdf.setFontSize(STYLES.FontSizeMedium);

        // DIBUJADO ITEM INFO ESCOLARIDAD
        const posYTIndicator = contentY;
        const textCicloEscolar = `${boletaConfig.Nivel}\n${_L("boleta_v3.cicloesc")}\n${boletaConfig.CicloEscolar}`;
        const WidthCiclo = STYLES.WidthArea / 16 * 3.5
        autoTable(pdf, {
            startY: posYTIndicator,
            tableWidth: WidthCiclo,
            head: [{ "title": textCicloEscolar }],
            theme: "grid",
            styles: {
                ...TableStylesAux,
                fontSize: STYLES.FontSizeMedium,
                fontStyle: "normal",
                halign: "center",
                valign: "middle",
                cellPadding: [STYLES.Padding, STYLES.Padding / 2, STYLES.Padding, STYLES.Padding / 2]
            },
            margin: TableMarginsAux,
        })

        // CÁLCULOS PARA TABLA GUÍA PERIODOS
        const arrTagsColHeadings = [_L("boleta_v3.periodo_1"), _L("boleta_v3.periodo_2"), _L("boleta_v3.periodo_3"), _L("boleta_v3.promedio")];
        let xTblHeadings = (STYLES.Padding * 5) + WidthCiclo;
        const widthTblHeadings = STYLES.Width - xTblHeadings - STYLES.Padding;
        const heigthRect = pdf.getTextDimensions(arrTagsColHeadings[0]).w + STYLES.Padding;
        const widthRect = widthTblHeadings / 4;

        autoTable(pdf, {
            startY: posYTIndicator,
            head: [{ "Calificacion": _L("boleta_v3.calificacion") }],
            theme: "grid",
            tableWidth: widthTblHeadings,
            styles: {
                ...TableStylesAux,
                fontSize: STYLES.FontSizeMedium,
                fontStyle: "normal",
                halign: "center",
                valign: "middle",
            },
            margin: {
                ...TableStylesAux,
                left: xTblHeadings,
            }
        })

        const [P1, P2, P3, Prom] = arrTagsColHeadings;
        const startYRectHeadings = (pdf as any).lastAutoTable.finalY;
        const startYTextHeadings = startYRectHeadings + heigthRect;
        pdf.rect(xTblHeadings, startYRectHeadings, widthRect, heigthRect);
        let widthText = pdf.getTextDimensions(P1).w;
        let heightText = pdf.getTextDimensions(P1).h;
        pdf.text(P1, (xTblHeadings + heightText + ((widthRect - heightText) / 2)), startYTextHeadings - ((heigthRect - widthText) / 2), { angle: 90 })
        xTblHeadings += widthRect;

        pdf.rect(xTblHeadings, startYRectHeadings, widthRect, heigthRect)
        widthText = pdf.getTextDimensions(P2).w;
        heightText = pdf.getTextDimensions(P2).h;
        pdf.text(P2, (xTblHeadings + heightText + ((widthRect - heightText) / 2)), startYTextHeadings - ((heigthRect - widthText) / 2), { angle: 90 })
        xTblHeadings += widthRect;

        pdf.rect(xTblHeadings, startYRectHeadings, widthRect, heigthRect)
        widthText = pdf.getTextDimensions(P3).w;
        heightText = pdf.getTextDimensions(P3).h;
        pdf.text(P3, (xTblHeadings + heightText + ((widthRect - heightText) / 2)), startYTextHeadings - ((heigthRect - widthText) / 2), { angle: 90 })
        xTblHeadings += widthRect;

        pdf.rect(xTblHeadings, startYRectHeadings, widthRect, heigthRect)
        widthText = pdf.getTextDimensions(Prom).w;
        heightText = pdf.getTextDimensions(Prom).h;
        pdf.text(Prom, (xTblHeadings + heightText + ((widthRect - heightText) / 2)), startYTextHeadings - ((heigthRect - widthText) / 2), { angle: 90 })
        xTblHeadings += widthRect;

        contentY = startYRectHeadings + heigthRect + STYLES.Padding
        // Agrupado de materias por tipo de evaluación
        d3Group(allPeriodsMaterias, d => d.TipoEval).forEach((arrType, tipoEval) => {
            // Si el tipo de evaluación es CTipoEvaluacion.Cualitativa || CTipoEvaluacion.Numeros Se dibuja una tabla de evaluación
            if ([CTipoEvaluacion.Cualitativa, CTipoEvaluacion.Numeros].includes(tipoEval)) {
                drawQualificationTbl(pdf, tipoEval, arrType);
            } else {
                // Si son de tipo CTipoEvaluacion.Colores || CTipoEvaluacion.Letras
                // => Aún se tienen que agrupar por configuraciones de evaluaciones coincidentes
                // Por cada grupo de evaluaciones coincidentes se dibujará una tabña de evaluación
                const mapMateriasPerEvalCfg = new Map<number, UIUtilViewCalificacion.MateriasPerEvalCfgV2>();
                arrType.forEach((materia, index) => {
                    const evalConfig: UIUtilViewCalificacion.ItemConfigEval[] = materia.EvalValor.map<UIUtilViewCalificacion.ItemConfigEval>((evalCfg, i) => ({ Valor: evalCfg, Descripcion: materia.EvalDescripcion[i] || "" }));
                    let hasConfigCoincidenceV2 = false;
                    mapMateriasPerEvalCfg.forEach((val, key) => {
                        if (hasConfigCoincidenceV2) return;
                        if (val.ConfigEval.length != evalConfig.length) hasConfigCoincidenceV2 = false;
                        else {
                            hasConfigCoincidenceV2 = evalConfig.every(itemCfg => { return val.ConfigEval.some(item => (itemCfg.Valor == itemCfg.Valor && itemCfg.Descripcion == item.Descripcion)) })
                            if (hasConfigCoincidenceV2) { val.MateriasCalificadas.push(materia) }
                        }
                    })
                    if (!hasConfigCoincidenceV2) { mapMateriasPerEvalCfg.set(index, { ConfigEval: evalConfig, MateriasCalificadas: [materia] }); }
                })
                mapMateriasPerEvalCfg.forEach((val, key) => {
                    drawQualificationTbl(pdf, tipoEval, val.MateriasCalificadas);
                })
            }
        })

        // TAMAÑO DE CONJUNTO DE TBLS PARA INASISTENCIAS 43
        contentY = (pdf as any).lastAutoTable.finalY + STYLES.Padding;
        const staticHeigthInasist = 43; // NOTE: MODIFICAR CUANDO CAMBIEN DIMENSIONES DEL DOCUMENTO
        if ((contentY + staticHeigthInasist) > (STYLES.Height - STYLES.Padding)) {
            pdf.addPage();
            contentY = STYLES.Padding;
        }

        const WidthInasist = STYLES.WidthArea / 16 * 8;
        autoTable(pdf, {
            startY: contentY,
            tableWidth: WidthInasist,
            head: [{ "title": _L("boleta_v3.inasistencias") }],
            styles: {
                ...TableStylesAux,
                halign: "center",
                valign: "middle",
                fontStyle: "normal",
            },
            theme: "grid",
            margin: TableMarginsAux,
        })

        contentY = (pdf as any).lastAutoTable.finalY

        // DATA INASISTENCIAS
        const P1Inasistencias = boletaConfig.InasistenciasAllPeriods[0];
        const P2Inasistencias = boletaConfig.InasistenciasAllPeriods[1];
        const P3Inasistencias = boletaConfig.InasistenciasAllPeriods[2];
        const TotalInasistencias = (P1Inasistencias || 0) + (P2Inasistencias || 0) + (P3Inasistencias || 0);

        // DIBUJADO DE TABLA INASISTENCIAS
        autoTable(pdf, {
            startY: contentY,
            tableWidth: WidthInasist,
            head: [{ "per1": _L("boleta_v3.periodo_1"), "per2": _L("boleta_v3.periodo_2"), "per3": _L("boleta_v3.periodo_3"), "total": _L("boleta_v3.total") }],
            body: [[P1Inasistencias, P2Inasistencias, P3Inasistencias, TotalInasistencias]],
            styles: {
                ...TableStylesAux,
                fontStyle: "normal",
                halign: "center",
                valign: "middle",
            },
            headStyles: {
                fontSize: STYLES.FontSizeMedium
            },
            theme: "grid",
            margin: TableMarginsAux,
        })

        contentY = (pdf as any).lastAutoTable.finalY;
        contentY += (STYLES.Padding * 5)

        // CALCULO DE POSICION DE ELEMENTOS DE FIRMA
        // EN BASE AL ALTO CONTENEDOR DE LA PAGINA Y (EL ALTO DE LOS ELEMENTOS DE FIRMA + UN PADDING ADICIONAL)
        const widthAreaSign = STYLES.WidthArea / 3;
        const widthLineSign = widthAreaSign / 9 * 8;

        const sello = _L("boleta_v3.sello");
        const name = UIUtilViewCalificacion.MapEscuelasDireccionGeneral.get(boletaConfig.IdEscuela);
        const position = _L("boleta_v3.dir_gral");
        const firmatutor = _L("boleta_v3.tutor_sign");
        let nameHeight = 0;
        if (name) {
            const SplittedName = pdf.splitTextToSize(name, widthLineSign) as string[];
            SplittedName.forEach((t, i) => {
                nameHeight += pdf.getTextDimensions(t).h;
            })
        }
        const heightSignElements = (nameHeight + pdf.getTextDimensions(position).h)

        if ((contentY + heightSignElements) > (STYLES.Height - STYLES.Padding)) {
            pdf.addPage();
            contentY = STYLES.Padding * 5;
        }

        let xStartPos = STYLES.Padding;
        let yPosLine = contentY;
        let yPosText = contentY + STYLES.Padding / 2;;

        // Drawing Lines
        let centerDivX = ((xStartPos + widthAreaSign) / 2) + (STYLES.Padding / 2)
        let startsLineX = centerDivX - (widthLineSign / 2)
        pdf.line(startsLineX, yPosLine, startsLineX + widthLineSign, yPosLine);
        pdf.text(sello, centerDivX - (pdf.getTextDimensions(sello).w / 2), yPosText);
        xStartPos += widthAreaSign;
        startsLineX += widthAreaSign;
        centerDivX += widthAreaSign;
        pdf.line(startsLineX, yPosLine, startsLineX + widthLineSign, yPosLine);
        contentY = yPosText;
        if (name) {
            const nameSplitted = pdf.splitTextToSize(name, widthLineSign) as string[];
            nameSplitted.forEach((t, i) => {
                let dimSignName = pdf.getTextDimensions(t);
                pdf.text(t, centerDivX - (dimSignName.w / 2), contentY);
                contentY += dimSignName.h;
            })
        }
        pdf.text(position, centerDivX - (pdf.getTextDimensions(position).w / 2), contentY);
        contentY += pdf.getTextDimensions(position).h
        xStartPos += widthAreaSign;
        startsLineX += widthAreaSign;
        centerDivX += widthAreaSign;
        pdf.line(startsLineX, yPosLine, startsLineX + widthLineSign, yPosLine);
        pdf.text(firmatutor, centerDivX - (pdf.getTextDimensions(firmatutor).w / 2), yPosText);
        return pdf;
    }
}
