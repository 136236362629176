import saveAs from "file-saver";
import { DataDRequest } from "../../data/DRequest";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import { _SvAlumnoNuevosExpedientes, _SvAlumnoObtenerExpedientes, _SvAlumnoURLObtenerExpedienteArchivo } from "../../data/modulo/Alumno";
import DataModuloEscuela from "../../data/modulo/Escuela";
import { _HttpMsgV2 } from "../../util/Labels";
import { TCARDV2COLL_OnEditOriginEvent } from "../controlD3/CardV2Collapse";
import { CardV2CollapseAdvancedTable, IConfigCardV2CollapseExcelExport } from "../controlD3/CardV2CollapseAdvancedTable";
import { FileListUploadExpedientes } from "../controlD3/FileListUpload";
import { InputFileControl } from "../controlD3/InputFileControlV2";
import { ModalThings } from "../controlD3/ModalThings";
import { NotificacionV2 } from "../controlD3/NotificacionV2";
import { Table } from "../controlD3/Tabla";
import { UIUtilLang } from "../util/Language";
import { UIUtilGeneral } from "../util/Util";
import { UIUtilViewData } from "../util/ViewData";

type IAlumno = Entidad.IAlumno;

interface IExpediente extends Entidad.IExpediente {
    UrlArchivo?: string;
    StrTipoExpediente?: string;
}

export class UIPanelCardAlumnosExpediente extends CardV2CollapseAdvancedTable<IExpediente, [IAlumno]> {
    constructor(modulo: Entidad.CModulo.PanelExpediente) {
        super("", modulo);
    }

    protected CARDCOLLADTAB_Table_GetMenuTop(): Table.ITableMenuTopDefaultOptionConfig[] {
        let opciones: Array<Table.ITableMenuTopDefaultOptionConfig> = [];

        if (this.HasActionPermission(Entidad.CAccionPermiso.Agregar)) {
            opciones = [{
                Label: "action_agregar",
                Callback: () => this.OpenModalAgregarExpedientes()
            }]
        }
        return opciones;
    }
    protected CARDCOLLADTAB_GetExportarConfig(dataTable: IExpediente[]): IConfigCardV2CollapseExcelExport<any> | Promise<IConfigCardV2CollapseExcelExport<any>> {
        return {
            FileName: this.cardData[0].NombreCompleto + " - " + UIUtilViewData._GetStr_Modulo("panelexpediente"),
            IdsEscuelas: [this.cardData[0].IdKinder],
            TypeRequest: Entidad.CTipoRequest.Alumno,
            ColumnsConfig: this.ctrlTabla._InfoColumns
                .map(d => ({
                    Field: d.Field as keyof IExpediente,
                    HeaderTag: d.Label,
                    WidthCell: 20
                })),
            OnGetDataBySheets: async () => {
                return [{
                    IdSheet: this.cardData[0].IdKinder, // IdEscuela
                    SheetName: this.cardData[0].NombreCompleto,
                    Data: dataTable,
                }]
            },
            OnGetEscuelasTagInSheet: (datos) => DataModuloEscuela._DiccEscuela.get(this.cardData[0].IdKinder).Nombre
        }
    }
    protected CARDCOLLADTAB_Table_GetConfig(): Omit<Table.IConfig<IExpediente>, "Parent"> {
        return {
            IdTabla: "AlumnosPanelExpediente",
            IdData: "IdExpediente",
            OrderDefault: { Type: Table.CStatusOrder.Asc, Field: "Nombre" },
            MinWidth: 100,
            RenderColumnHeadings: [
                {
                    Field: "Nombre", Label: "Nombre", Width: "50%", MinWidth: "75px", ClassStyle: "link_item",
                    OnClickInCellCont: (datum) => this.OpenModalExpedienteInfo(datum)
                },
                { Field: "Extension", Label: "Extension", Width: "50%", MinWidth: "75px" },
                { Field: "StrTipoExpediente", Label: "Tipo expediente", Width: "50%", MinWidth: "75px" }
            ],
            OptionsTopDefaultV2: {
                MaxOptionsInRow: 1,
                Options: []
            },
            OptionsOfDataCheckV3: () => this.CARDCOLLADTAB_GetTableOptionsToSelectedData(this.cardData[0].IdKinder, "top-selected", this.TableGetDataSelectedMenu()),
            FilterParameters: this.TableGetFilters(),
            //  HideCheckboxes: true,
            OnValueSelectRow: (key, value) => {
                console.log(key, value);
            },
            EvaluatorAndSubLevelsBuild: {
                GetOptionsInRowV2: () => this.CARDCOLLADTAB_GetTableOptionsToSelectedData(this.cardData[0].IdKinder, "row", this.TableGetDataSelectedMenu())
            }
        }
    }
    protected CARDCOLL_OnInitBuild(contentContainer: TSelectionHTML<"div", any, any>): void {
        this.btnEditarCard._d3Selection.remove();
        this.cardFooterSelection.remove();
    }
    protected CARDCOLL_GetVariantToValidateUpdate(cardData_0: IAlumno): string {
        return cardData_0.IdChild.toString();
    }
    protected CARDCOLL_OnUpdateData(cardData_0: IAlumno): void | Promise<void> {
        return this.UI_Table_UpdateExpedienteData();
    }
    protected CARDCOLL_MostrarBody(): void {
        this.cardContentContainerSelection.style("height", "100%");
        this.UI_Table_UpdateExpedienteData();
    }
    protected CARDCOLL_OcultarBody(): void {
        this.cardContentContainerSelection.style("height", null);
    }
    protected CARDCOLL_OnEditarCard(originEvent: TCARDV2COLL_OnEditOriginEvent): void { }
    protected CARDCOLL_OnCancelaEditarCard(originEvent: TCARDV2COLL_OnEditOriginEvent): void { }
    protected CARDCOLL_GuardarCardV2(): Promise<DataDRequest.IRequestResponseA<any>> { return null }
    protected CARDCOLL_SyncOrGetIdToDownloadData(): DataModuloMain.TipoRequestMonitorId | (() => Promise<any>) | DataModuloMain.TipoRequestMonitorId[] {
        return () => this.UI_Table_UpdateExpedienteData();
    }
    protected CARDCOLL_GetIdSchool(cardData_0: IAlumno): number {
        return cardData_0.IdKinder;
    }

    private TableGetDataSelectedMenu(): Array<Table.ITableMenuDataSelectedOptionConfig<IExpediente>> {
        let opciones: Array<Table.ITableMenuDataSelectedOptionConfig<IExpediente>> = [];
        if (this.HasActionPermission(Entidad.CAccionPermiso.Ver)) {
            opciones.push({
                Label: "action_download",
                Callback: (datos: Array<IExpediente>) => this.Sv_DownloadFiles(datos),
            })
        }
        return opciones;
    }

    private TableGetFilters(): Array<Table.IParametroFiltro<IExpediente>> {
        return [
            { LabelLangKey: "d_field_nombre", Field: "Nombre" },
            {
                LabelLangKey: "d_field_strtipoexpediente",
                Field: "IdTipoExpediente",
                Type: "select",
                Options: UIUtilViewData._GetList_TiposExpedientes()
            }
        ]
    }

    //#endregion
    private UI_Table_UpdateExpedienteData() {
        if (this.CARDCOLL_StatusCardExpandido && this.cardData !== undefined) {
            const { IdKinder, IdChild } = <Entidad.IAlumno>(this.cardData[0] || {});
            let expedientes: IExpediente[] = [];

            this.ctrlProgress.attr("oculto", false);

            return _SvAlumnoObtenerExpedientes(IdKinder, IdChild)
                .then((res) => {
                    if (res.Datos) {
                        expedientes = res.Datos.map((exp: IExpediente) => {
                            exp.UrlArchivo = _SvAlumnoURLObtenerExpedienteArchivo(exp.IdExpediente);
                            exp.StrTipoExpediente = UIUtilViewData._GetStr_TipoExpediente(exp.IdTipoExpediente);
                            return exp;
                        });
                    }
                    if (res.Resultado <= 0) {
                        this.ctrlNotification._Mostrar(_HttpMsgV2(res, "consulta"), "ADVERTENCIA");
                    }
                    this.ctrlProgress.attr("oculto", true);
                    if (expedientes.length || (this.ctrlTabla._data || [])[0]?.IdAlumno != IdChild)
                        this.ctrlTabla._UpdateData(expedientes);
                })
                .catch(() => null);
        }
        this.ctrlTabla._UpdateData([]);
        return null;
    }

    private OpenModalAgregarExpedientes() {
        const ctrlFileUpload = new FileListUploadExpedientes();

        ModalThings._OpenModalToProccessServiceByServiceFromAArrayAdvance({
            Title: "tag_files",
            IdsEscuelas: [this.cardData[0].IdKinder],
            Modulo: this.modulo,
            Action: Entidad.CAccionPermiso.Agregar,
            AccionToHttpMessage: "expediente",
            Width: 1000,
            OnDrawContent: (container, modalThings) => {
                ctrlFileUpload.filterFiles = [];
                ctrlFileUpload.filterFiles.push({ Label: this.CARDCOLL_GetUIStringModule("tag_doc"), Ext: ["pdf", "docx", "doc", "xls", "xlsx", "ppt", "pptx"] });
                ctrlFileUpload.filterFiles.push({ Label: this.CARDCOLL_GetUIStringModule("tag_img"), Ext: ["jpg", "jpeg", "png"] });
                ctrlFileUpload.ListaTipoExpediente = UIUtilViewData._GetList_TiposExpedientes().filter(d => d.Id !== Entidad.CTipoExpediente.Boleta)
                    .map(d => ({
                        Id: d.Id,
                        Nombre: d.Name
                    }));
                ctrlFileUpload._Build();

                container.append(() => ctrlFileUpload.D3Selection.node());
            },
            OnValideData: (modalThings) => {
                if (ctrlFileUpload._files.length > 0) {
                    for (let dFile of ctrlFileUpload._files) {
                        if (dFile.TipoExpediente == null) {
                            NotificacionV2._Mostrar(
                                this.CARDCOLL_GetUIStringModule("notif_selecttypeexptofile")
                                    .replace("_FILENAME", (dFile.Nombre + "." + dFile.Extension)),
                                "ADVERTENCIA"
                            );

                            return false;
                        }
                    }

                    return true;
                }
                else {
                    NotificacionV2._Mostrar(this.CARDCOLL_GetUIStringModule("notif_errorupload"), "ADVERTENCIA");
                    return false;
                }
            },
            OnGetDataToProccess: () => ctrlFileUpload._files,
            OnStepAProccess: (itemData) => {
                const parametros = {
                    IdEscuela: this.cardData[0].IdKinder,
                    IdAlumno: this.cardData[0].IdChild,
                    TipoExpediente: itemData.TipoExpediente,
                    Estado: 0
                }

                return _SvAlumnoNuevosExpedientes({
                    IdEscuela: parametros.IdEscuela,
                    IdAlumno: parametros.IdAlumno,
                    TipoExpediente: parametros.TipoExpediente,
                    Estado: parametros.Estado,
                    Archivos: [{ File: itemData.File, Nombre: itemData.Nombre + "." + itemData.Extension }]
                })
            },
            OnError_GetItemDataTag: (itemData, container) => `<b>${itemData.Nombre + "." + itemData.Extension}</b> (${UIUtilViewData._GetStr_TipoExpediente(itemData.TipoExpediente)})`,
            OnEndAndCloseProccess: (correctos) => {
                if (correctos.length) {
                    this.UI_Table_UpdateExpedienteData();
                }
            }
        })
    }

    private OpenModalExpedienteInfo(datoExp: IExpediente) {
        ModalThings._GetModalSimple({
            Title: this.CARDCOLL_GetUIStringModule("tag_infoexpediente"),
            DrawContent: (container, modalThings) => {
                const inputFile = new InputFileControl.InputFile({
                    Border: { Width: 0 },
                    ShowBtnLoadFile: false,
                    ShowBtnDownloadFile: true,
                    ControlForm: InputFileControl.ControlForm.Cuadrado,
                    OnGetFileName: () => datoExp.Nombre,
                    ControlStyle: "foto_control_style2",
                    MaxDimention: "100%",
                    Dimentions: (datoExp.EsImagen ? "100%" : "45%"),
                    OnDownload_GetRealResource: () => datoExp.UrlArchivo,
                    OnError: (type) => {
                        if (type == "ondownload") {
                            NotificacionV2._Mostrar(
                                this.CARDCOLL_GetUIStringModule("notif_error_download")
                                    .replace("_FILENAME", (datoExp.Nombre + datoExp.Extension)),
                                "ADVERTENCIA"
                            );
                        }
                    }
                })

                if (datoExp.EsImagen) {
                    inputFile._UrlResource = datoExp.UrlArchivo;
                } else {
                    inputFile._AuxExtensionFile = datoExp.Extension;
                }

                inputFile._SpinnerControl.node()._Dim = 50;
                container
                    .classed(UIUtilGeneral.FBoxOrientation.Vertical, true)
                    .classed(UIUtilGeneral.FBoxAlign.CenterCenter, true);
                container.append(() => inputFile._ControlNode);
                container.append("label")
                    .html(`<b>${this.CARDCOLL_GetUIStringModule("d_field_nombre")}:</b> ${datoExp.Nombre + datoExp.Extension}`);
            }
        })
    }

    // **************************************************************************
    // PERMISOS COSAS
    // **************************************************************************

    // **************************************************************************
    // SERVICIOS COSAS
    // **************************************************************************

    private async Sv_DownloadFiles(datos: IExpediente[]) {
        let errorItems: IExpediente[] = [];

        this.ctrlProgress.attr("oculto", false);
        for (let d of datos) {
            // window.open(d.UrlArchivo);
            await new Promise<void>(resolve => {
                DataDRequest._RequestBlobFromUrlResource(d.UrlArchivo, (fileBlob, error) => {
                    if (!error && fileBlob) {
                        saveAs(fileBlob, d.Nombre + d.Extension);
                    } else {
                        errorItems.push(d);
                    }
                    resolve();
                })
            })
        }

        if (errorItems.length == 1) {
            this.ctrlNotification._Mostrar(
                this.CARDCOLL_GetUIStringModule("notif_error_download")
                    .replace("_FILENAME", (errorItems[0].Nombre + errorItems[0].Extension)),
                "ADVERTENCIA"
            );
        } else if (errorItems.length > 1) {
            ModalThings._GetModalInfoDataList<IExpediente>({
                Title: UIUtilLang._GetUIString("general", "failresume"),
                InfoText: this.CARDCOLL_GetUIStringModule("tag_errordownload_msj"),
                DataList: errorItems,
                Width: 320,
                OnStepItemData: (container, itemData) => {
                    container.text(itemData.Nombre + itemData.Extension);
                }
            })
        }
        this.ctrlProgress.attr("oculto", true);
    }

}
