import { UIUtilGeneral } from "../util/Util";
import { MenuFlex } from "./MenuFlexV2";

export namespace TableUtil {
    export function _GetMaxWidthMenuFlex(tableRow: TSelectionHTML<"tr", any, any>, noCellsToIgnoreWidth: number, spaceTableSelection: TSelectionHTML<"div">, ctrlMenu: MenuFlex): number {
        let maxWidth = 0;
        const cellWidths = Array.from(tableRow.node().childNodes)
            .map((child: HTMLTableCellElement, index) => {
                if (index > (1 + noCellsToIgnoreWidth)) {
                    const w = UIUtilGeneral._GetElementWidthInViewport(child, spaceTableSelection.node())
                    maxWidth += w;
                    return w;
                }
                return 0;
            }).reverse();
        const controlContainer = ctrlMenu._ControlContainer;
        controlContainer.style("min-width", null);
        setTimeout(() => {
            const controlWidthAuto = controlContainer.node().clientWidth;
            let controlWidthFixed = 0;
            for (const w of cellWidths) {
                controlWidthFixed += w;
                if (controlWidthFixed > controlWidthAuto) {
                    break;
                }
            }
            if (controlWidthFixed > controlWidthAuto) {
                controlContainer.style("min-width", controlWidthFixed + "px");
            } else {
                controlContainer.style("min-width", (controlWidthAuto + 20) + "px");
            }
        });
        return maxWidth;
    }
}