import { MainPage } from "../../MainPage";
import { DataDRequest } from "../../data/DRequest";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import DataModuloGrupo from "../../data/modulo/Grupo";
import { CardV2Collapse, TCARDV2COLL_OnEditOriginEvent } from "../controlD3/CardV2Collapse";
import { FormGenerator } from "../controlD3/Formulario";
import { UIUtilLang } from "../util/Language";
import { UIUtilViewGrupos } from "../utilView/Grupos";
import IGrupo = Entidad.IGrupo;

export class UIPanelCardGruposInfoGeneralV2 extends CardV2Collapse<[IGrupo]> {

    private form: FormGenerator<IGrupo>;
    private currentGrupoOrigin: IGrupo;
    private currentGrupoForm: IGrupo;

    constructor(modulo: Entidad.CModulo) {
        super("card_headertitle", modulo, "grupopanelinfogeneral");
    }

    protected CARDCOLL_OnInitBuild(container: TSelectionHTML<"div", any, any>): void {
        //this.cardHeaderSelection.remove();
        //this.cardActionsContainerSelection.style("top", "-32px");

        this.currentGrupoForm = <IGrupo>{};
        this.form = UIUtilViewGrupos._GetForm(Entidad.CAccionPermiso.Editar, this.currentGrupoForm);
        container.append(() => this.form._Form.node());
        this.UpdateModalAndFormMode();
    }
    protected CARDCOLL_GetVariantToValidateUpdate(grupo: IGrupo): string {
        return grupo?.IdGrupo + grupo?.Modificacion;
    }
    protected CARDCOLL_OnUpdateData(grupoData: IGrupo): void {
        this.currentGrupoOrigin = grupoData;
        let auxGrupo = (Object.assign({}, grupoData) as IGrupo);
        for (let key in auxGrupo) {
            this.currentGrupoForm[key] = auxGrupo[key];
        }

        this.form._AsignaData(this.currentGrupoForm);
        UIUtilViewGrupos._UpdateFormFromPermission(this.form, Entidad.CAccionPermiso.Editar, this.currentGrupoForm);
        this.UpdateModalAndFormMode();
    }
    protected CARDCOLL_MostrarBody(): void {
        this.UpdateUIFromPermissions();
    }
    protected CARDCOLL_OcultarBody(): void {
    }

    //Checar esta implementacion
    protected CARDCOLL_OnEditarCard(originEvent: TCARDV2COLL_OnEditOriginEvent): void {
        //if (!editar) {
        //this.CARDCOLL_UpdateCardData(this.currentGrupoOrigin);
        //}
        this.UpdateModalAndFormMode();
    }
    protected CARDCOLL_OnCancelaEditarCard(originEvent: TCARDV2COLL_OnEditOriginEvent): void {
        this.CARDCOLL_UpdateCardData(false, this.currentGrupoOrigin);
    }
    protected async CARDCOLL_GuardarCardV2(): Promise<DataDRequest.IRequestResponseA<any>> {
        let res: DataDRequest.IResultadoPeticion<undefined>;

        if (this.form._GetIsValidForm()) {
            let datoForm = this.form._Data;
            res = await DataModuloGrupo._ActualizarGrupoV3(datoForm.IdGrupo, datoForm.Nombre, datoForm.IdNivel, datoForm.IdEscolaridad, datoForm.EsPrincipal);

            if (res.Resultado > 0) {
                let resultSuccess = await MainPage._ReloadServiceAndAwaitBool(Entidad.CTipoRequest.Grupo, datoForm.IdKinder);
                if (!resultSuccess) {
                    setTimeout(() => {
                        this.ctrlNotification._Mostrar(UIUtilLang._GetUIString("general", "notif_fail_infosync"), "ADVERTENCIA");
                    }, 2000);
                }
            }
            res.Mensaje = UIUtilLang._GetHTTPMessage(res, "Editar");
        }

        return res;
    }
    protected CARDCOLL_SyncOrGetIdToDownloadData(): DataModuloMain.TipoRequestMonitorId | DataModuloMain.TipoRequestMonitorId[] | (() => Promise<any>) {
        return Entidad.CTipoRequest.Grupo;
    }
    protected CARDCOLL_GetIdSchool(cardData_0_0: IGrupo): number {
        return cardData_0_0.IdKinder;
    }

    private async UpdateModalAndFormMode() {
        this.form._PreviewMode(!this.CARDCOLL_StatusCardEditando);
        if (!this.CARDCOLL_StatusCardEditando && this.currentGrupoOrigin) {
            this.form._GetIsValidForm(); // Aprovecha su función para resetear los bordes rojos de los campos
        }

        this.UpdateUIFromPermissions();
    }

    private UpdateUIFromPermissions() {
        if (!this.CARDCOLL_StatusCardEditando) {
            this.btnEditarCard._d3Selection.classed("hide", !this.HasActionPermission(Entidad.CAccionPermiso.Editar));
        }
    }

}
