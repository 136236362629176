import { DataDRequest } from "../../data/DRequest";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import DataModuloCicloEscolar from "../../data/modulo/CicloEscolar";
import DataModuloEscuela from "../../data/modulo/Escuela";
import { DataUtil } from "../../data/util/Util";
import { DateV2 } from "../../util/DateV2";
import { IConfigCardCollapseExcelExport } from "../controlD3/CardCollapseAdvancedTable";
import { TCARDV2COLL_OnEditOriginEvent } from "../controlD3/CardV2Collapse";
import { CardV2CollapseAdvancedTable } from "../controlD3/CardV2CollapseAdvancedTable";
import { Fields, FormGenerator } from "../controlD3/Formulario";
import { Table } from "../controlD3/Tabla";
import { UIUtilLang } from "../util/Language";
import { UIUtilTime } from "../util/Time";
import IEscuela = Entidad.IEscuela;
import ICicloEscolar = Entidad.ICicloEscolar;

export class UIPanelCardEscuelasCicloEscolar extends CardV2CollapseAdvancedTable<ICicloEscolar, [IEscuela]> {
    constructor() {
        super("", Entidad.CModulo.PanelCicloEscolar);
    }

    protected Table_GetMenuDataSelected(): Array<Table.ITableMenuDataSelectedOptionConfig<ICicloEscolar>> {
        let opciones: Array<Table.ITableMenuDataSelectedOptionConfig<ICicloEscolar>> = [];

        //if (this.HasActionPermission(data.Entidades.CAccionPermiso.Eliminar)) {
        // MISSING_PERMISSION Eliminar
        if (DataUtil._Usuario.Perfil == Entidad.CTipoPerfil.Admin) {
            opciones.push({
                Label: "action_eliminar",
                Callback: (datos) => this.OpenModal_EliminarDatos(datos)
            })
        }
        // }

        return opciones;
    }

    private OpenModal_FormularioAgregar() {
        const escuela = this.cardData[0];
        this.CARDCOLLAD_OpenModal_ActionFormToAGridData({
            Title: "action_agregar",
            Action: Entidad.CAccionPermiso.Agregar,
            IdsEscuelas: [escuela.IdKinder],
            GetForm: () => new FormGenerator<ICicloEscolar>()
                ._Crear({
                    LangModuleKeyInContext: this.moduloName,
                    LabelMaxWidth: 120,
                    schema: [
                        {
                            type: Fields.input,
                            inputAttr: { type: "text", maxlength: 120, required: true },
                            labelAttr: { text: "d_field_nombre" },
                            model: "Nombre"
                        },
                        {
                            type: Fields.input,
                            inputAttr: { type: "date", required: true },
                            labelAttr: { text: "d_field_fechainicio" },
                            model: "FechaInicio"
                        },
                        {
                            type: Fields.input,
                            inputAttr: { type: "date", required: true },
                            labelAttr: { text: "d_field_fechafin" },
                            model: "FechaFin"
                        }
                    ],
                    Validation: (value, field, datoForm) => {
                        //if (Boolean(value) && Boolean(String(value).trim())) {
                        switch (field) {
                            case "FechaInicio":
                            case "FechaFin":
                                // >> Valida fechas
                                let fechaInicio = new Date(datoForm.FechaInicio);
                                let fechaFin = new Date(datoForm.FechaFin);
                                if (!isNaN(Number(fechaInicio)) && !isNaN(Number(fechaFin))) {
                                    return (fechaFin > fechaInicio);
                                }
                                break;
                            default:
                                return true;
                        }
                        //}
                        return true;
                    }
                }, <ICicloEscolar>{}),
            OnAccept: (form) => this.Sv_Alta(escuela, form._Data),
            TypeRequest: Entidad.CTipoRequest.CicloEscolar,
            OnEndReloadService: async () => this.UI_UpdateTable(),
        })
    }

    private OpenModal_EliminarDatos(datos: ICicloEscolar[]) {
        this.CARDCOLLAD_OpenModal_ProccessArrayData<ICicloEscolar>({
            // Tittle: "Eliminar ciclos escolares",
            DataToProccess: datos,
            TypeRequest: Entidad.CTipoRequest.CicloEscolar,
            OnGetIdEscuela: (dato) => dato.IdEscuela,
            OnError_GetItemDataTag: (dato) => (
                `<b>${dato.Nombre}</b>\n`
                + `${this.CARDCOLL_GetUIStringModule("tag_periodo")}: ${UIUtilTime._DateFormatStandarFixTimeZone(dato.FechaInicio, this.cardData[0].ZonaHoraria)} - ${UIUtilTime._DateFormatStandarFixTimeZone(dato.FechaFin, this.cardData[0].ZonaHoraria)}`
            ),
            OnStepAProccess: async (dato) => {
                let res = await this.Sv_Eliminar(dato);
                if (res.Resultado == -1 || res.Resultado == -2) {
                    res.Mensaje = UIUtilLang._GetHTTPMessage(res, "Eliminar");
                }
                return res;
            },
            OnEndAndCloseProccess: async (correctos, incorrectos) => {
                if (correctos.length) {
                    this.UI_UpdateTable();
                }
            }
        })
    }

    private UI_UpdateTable() {
        this.ctrlTabla._UpdateData(this.GetCiclosEscolares());
    }

    protected CARDCOLL_MostrarBody() {
        this.cardSelection.style("height", "100%");
        return this;
    }

    protected CARDCOLL_OcultarBody(): void {
        this.cardSelection.style("height", null);
    }
    protected CARDCOLL_OnEditarCard(): void {
        // throw new Error("Method not implemented.");
    }
    protected CARDCOLL_OnCancelaEditarCard(originEvent: TCARDV2COLL_OnEditOriginEvent): void {
    }
    protected CARDCOLL_GuardarCardV2(): Promise<DataDRequest.IRequestResponseA<any>> {
        throw new Error("Method not implemented.");
    }

    protected CARDCOLL_OnInitBuild(contentContainer: TSelectionHTML<"div", any, any>): void {
        this.cardFooterSelection.remove();
        this.btnEditarCard._d3Selection.remove();
        this.cardHeaderSelection.remove();
    }

    protected CARDCOLL_GetVariantToValidateUpdate(cardData_0: IEscuela): string {
        return null;
    }

    protected CARDCOLL_OnUpdateData(cardData_0: IEscuela): void {
        this.UI_UpdateTable();
    }

    protected CARDCOLL_SyncOrGetIdToDownloadData(): DataModuloMain.TipoRequestMonitorId | (() => Promise<any>) | DataModuloMain.TipoRequestMonitorId[] {
        return Entidad.CTipoRequest.CicloEscolar
    }

    protected CARDCOLL_GetIdSchool(cardData_0_0: IEscuela): number {
        return cardData_0_0.IdKinder;
    }

    protected CARDCOLLADTAB_Table_GetConfig(): Omit<Table.IConfig<ICicloEscolar>, keyof Pick<Table.IConfig<ICicloEscolar>, "Parent">> {
        return {
            IdTabla: "EscuelasPanelCicloEscolar",
            Title: "",
            IdData: "Id",
            OrderDefault: { Type: Table.CStatusOrder.Asc, Field: "FechaInicio" },
            MinWidth: 600,
            RenderColumnHeadings: [
                { Field: "Nombre", Label: "Nombre", Width: "40%", MinWidth: "75px", OrderTypeParse: String },
                { Field: "FechaInicio", Label: "Inicio", Width: "25%", MinWidth: "75px", OrderTypeParse: Date },
                { Field: "FechaFin", Label: "Fin", Width: "25%", MinWidth: "75px", OrderTypeParse: Date }
            ],
            OptionsTopDefaultV2: {
                MaxOptionsInRow: 3,
                Options: [] // this.TableGetMenu()
            },
            OptionsOfDataCheckV3: () => {
                if (this.cardData?.length) {
                    return this.CARDCOLLADTAB_GetTableOptionsToSelectedData(this.cardData[0].IdKinder, "top-selected", this.Table_GetMenuDataSelected());
                }
                return null;
            },
            EvaluatorAndSubLevelsBuild: {
                GetOptionsInRowV2: () => this.CARDCOLLADTAB_GetTableOptionsToSelectedData(this.cardData[0].IdKinder, "row", this.Table_GetMenuDataSelected()),
                OnStepCellTable: (container, datum, field, step) => {
                    switch (field) {
                        case "FechaInicio":
                            container.text(UIUtilTime._DateFormatStandarFixTimeZone(datum.FechaInicio, this.cardData[0].ZonaHoraria));
                            return false;
                        case "FechaFin":
                            container.text(UIUtilTime._DateFormatStandarFixTimeZone(datum.FechaFin, this.cardData[0].ZonaHoraria));
                            return false;
                    }
                    return true;
                }
            }
        }
    }

    protected CARDCOLLADTAB_Table_GetMenuTop(): Table.ITableMenuTopDefaultOptionConfig[] {
        let opciones: Array<Table.ITableMenuTopDefaultOptionConfig> = [];

        if (this.HasActionPermission(Entidad.CAccionPermiso.Agregar)) {
            opciones.push({
                Label: "action_agregar",
                Callback: () => this.OpenModal_FormularioAgregar()
            })
        }

        return opciones;
    }

    protected CARDCOLLADTAB_GetExportarConfig(dataTable: ICicloEscolar[]): IConfigCardCollapseExcelExport<ICicloEscolar> {
        const escuela = this.cardData[0];
        return {
            FileName: escuela.Nombre + " - " + this.CARDCOLL_GetUIStringModule("tag_ciclosescolares"),
            IdsEscuelas: [escuela.IdKinder],
            TypeRequest: Entidad.CTipoRequest.Escuela,
            ColumnsConfig: this.ctrlTabla._InfoColumns
                .map(d => ({
                    Field: d.Field as keyof ICicloEscolar,
                    HeaderTag: d.Label,
                    WidthCell: 25
                })),
            OnGetDataBySheets: async () => {
                return [{
                    IdSheet: escuela.IdKinder, // IdEscuela
                    SheetName: escuela.Nombre,
                    Data: dataTable,
                }]
            },
            OnGetEscuelasTagInSheet: (datos) => DataModuloEscuela._DiccEscuela.get(escuela.IdKinder).Nombre,
            OnStepFieldInDataRow: (field, dato) => {
                if ((field == "FechaInicio" || field == "FechaFin") && dato[field]) {
                    return UIUtilTime._DateFormatStandarFixTimeZone(dato[field], this.cardData[0].ZonaHoraria);
                }
                return dato[field] as string;
            }
        }
    }

    // **************************************************************************
    // PERMISOS
    // **************************************************************************

    // **************************************************************************
    //  SERVICIOS Y DATA
    // **************************************************************************

    private GetCiclosEscolares() {
        if (this.cardData[0]) {
            return DataModuloMain._GetReqDataArrayByName("CicloEscolar")
                .filter(d => (d.IdEscuela == this.cardData[0].IdKinder));
        }
        return []
    }

    private Sv_Alta(escuela: IEscuela, dataForm: ICicloEscolar) {
        let fechaInicio = new DateV2(UIUtilTime._GetLocalDateFromInputDateString(dataForm.FechaInicio))
            ._SetTimeZone(escuela.ZonaHoraria, true)
            ._ToISOString();
        let fechaFin = new DateV2(UIUtilTime._GetLocalDateFromInputDateString(dataForm.FechaFin))
            ._SetTimeZone(escuela.ZonaHoraria, true)
            ._ToISOString();
        return DataModuloCicloEscolar._AltaCiclo({
            IdEscuela: escuela.IdKinder,
            Nombre: dataForm.Nombre,
            FechaInicio: fechaInicio,
            FechaFin: fechaFin,
        })
    }

    private Sv_Eliminar(datoCE: ICicloEscolar) {
        return DataModuloCicloEscolar._EliminarCicloEscolar(datoCE.Id, datoCE.IdEscuela);
    }
}
