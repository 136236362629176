import * as d3 from "d3";
import { Global } from "../../data/Global";

export namespace NotificacionV2 {
    export type TTipoNotificacion = "NORMAL" | "ADVERTENCIA" | "INFO" | "REQUIRED";
}

import TTipoNotificacion = NotificacionV2.TTipoNotificacion;

const DURATION_READ_DEFAULT = 3000;
const DURATION_TRANSITION = 1000;

export class NotificacionV2 {
    private notificationsElements: Set<HTMLElement>;
    private parentSelection: TSelectionHTML<"htmlelement">;
    private notificationsContainerAux: TSelectionHTML<"div">;

    /**
     * @param parentSelection ```d3.select(document.body)``` (default)
     */
    constructor(parentSelection: TSelectionHTML<"htmlelement"> = d3.select(document.body)) {
        this.parentSelection = parentSelection;
        this.notificationsElements = new Set();
    }

    // *********************************************************************
    // STATIC METHODS
    // *********************************************************************

    private static RemoverTodo() {
        d3.select(document.body)
            .selectAll(".notificacion_container")
            .remove()
            .selectAll(".notificacion")
            .interrupt()
            .remove();
    }

    private static MostrarGeneral(msg: string, tipo: TTipoNotificacion, readDuration: number) {
        const parentSelection = d3.select(document.body);
        const fnAnimationOcultarItem = () => NotificacionV2.AnimationOcultarItem(notificationElement, () => {
            if (NotificacionV2.GetNotificationContainerElementInParent(parentSelection).childElementCount == 0) {
                notificationsContainerElement.remove();
            }
        });
        let notificationsContainerElement = NotificacionV2.GetNotificationContainerElementInParent(parentSelection);

        if (!notificationsContainerElement) {
            notificationsContainerElement = NotificacionV2.GetNotificationContainerElement();
            parentSelection.append(() => notificationsContainerElement);
        }
        const notificationElement = NotificacionV2.GetNotificacionItemElement(msg, tipo);
        notificationElement.onclick = e => fnAnimationOcultarItem();
        notificationsContainerElement.append(notificationElement);
        NotificacionV2.AnimationMostrarItem(notificationElement);

        setTimeout(() => fnAnimationOcultarItem(), readDuration);
    }

    private static GetNotificationContainerElementInParent(parent: TSelectionHTML<"htmlelement">) {
        return parent
            .select<HTMLDivElement>(":scope > .notificacion_container")
            .node();
    }

    private static GetNotificationContainerElement() {
        return d3.create("div")
            .attr("class", "notificacion_container")
            .node();
    }

    private static GetNotificacionItemElement(msg: string, tipo: TTipoNotificacion) {
        const itemSelection = d3.create("div")
            .attr("class", "notificacion")
            .html(msg)

        itemSelection.selectAll("b").style("color", "var(--color_text4)", "important");

        var clase: string;
        switch (tipo) {
            case "INFO":
                clase = "notificacion_info";
                break;
            case "ADVERTENCIA":
                clase = "notificacion_warning";
                break;
            case "REQUIRED":
                clase = "notificacion_required";
                break;
            // case "NORMAL":
            // default:
            //     clase = "notificacion_info";
            //     break;
        }
        if (clase) itemSelection.classed(clase, true);

        return itemSelection.node();
    }

    private static AnimationMostrarItem(notificationElement: HTMLElement) {
        d3.select(notificationElement)
            .transition()
            .duration(DURATION_TRANSITION)
            .style("display", "block")
            .style("opacity", 1)
        // .on("end", function () {
        //     d3.select(this).style("display", "block");
        // });
    }

    private static AnimationOcultarItem(notificationElement: HTMLElement, onEnd: Function) {
        if (!d3.select(notificationElement).classed("ocultando")) {
            const transition = d3.select(notificationElement)
                .classed("ocultando", true)
                .transition()
                .duration(DURATION_TRANSITION)
                .style("opacity", 0)
                .remove();
            if (onEnd) {
                transition.on("end", () => onEnd());
            }
        }
    }

    // *********************************************************************
    // PRIVATE METHODS
    // *********************************************************************

    private ContainerAppendEvaluator() {
        const existNotifications = (this.notificationsElements.size > 0);

        let notificationsContainerElement = NotificacionV2.GetNotificationContainerElementInParent(this.parentSelection);

        if (existNotifications) {
            if (!notificationsContainerElement) {
                notificationsContainerElement = NotificacionV2.GetNotificationContainerElement();
                this.parentSelection.append(() => notificationsContainerElement);
            }
            if (!this.notificationsContainerAux?.node()) {
                this.notificationsContainerAux = d3.select(notificationsContainerElement);
            }
            else if (this.notificationsContainerAux.node() != notificationsContainerElement) {
                this.notificationsElements.forEach(elem => notificationsContainerElement.append(elem));
                this.notificationsContainerAux.remove();
                this.notificationsContainerAux = d3.select(notificationsContainerElement);
            }
        } else {
            if (notificationsContainerElement) {
                if (notificationsContainerElement.childElementCount == 0) {
                    this.notificationsContainerAux.remove();
                    this.notificationsContainerAux = null;
                }
                // else {
                //     // NOTE Notificaciones de otra instancia
                // }
            } else {
                this.notificationsContainerAux?.remove();
                this.notificationsContainerAux = null;
            }
        }
        return this.notificationsContainerAux;
    }

    private AddNotification(msg: string, tipo: TTipoNotificacion, readDuration: number) {
        // >> Crea elemento
        const newNotificationElement = NotificacionV2.GetNotificacionItemElement(msg, tipo);

        // >> Agrega a la cola de elementos & mostrar
        this.notificationsElements.add(newNotificationElement);

        this.ContainerAppendEvaluator()
            .append(() => newNotificationElement)
            .on("click", () => this.AnimationOcultarItem(newNotificationElement))

        NotificacionV2.AnimationMostrarItem(newNotificationElement);

        setTimeout(() => this.AnimationOcultarItem(newNotificationElement), readDuration);
    }

    private AnimationOcultarItem(notificationElement: HTMLElement) {
        NotificacionV2.AnimationOcultarItem(notificationElement, () => {
            this.RemoverItem(notificationElement);
            this.ContainerAppendEvaluator();
        })
    }

    private RemoverItem(notificacion: HTMLElement) {
        if (this.notificationsElements.has(notificacion)) {
            this.notificationsElements.delete(notificacion);

            d3.select(notificacion)
                // .style("display", "none")
                .interrupt()
                .remove();
        }
    }

    // *********************************************************************
    // PUBLIC METHODS
    // *********************************************************************

    public _SetParent(parentSelection: TSelectionHTML<"htmlelement">) {
        this.parentSelection = parentSelection;
        this.ContainerAppendEvaluator();
        return this;
    }

    // private fn_RemoverNotificacion()

    public _ClearAll() {
        this.notificationsElements
            .forEach(notifElem => this.AnimationOcultarItem(notifElem))
        return this;
    }

    /** La cadena acepta html */
    public _Mostrar(msg: string, tipo: TTipoNotificacion, readDuration = DURATION_READ_DEFAULT) {
        // const consoleColorTipo = (tipo == "ADVERTENCIA" ? "red" : tipo == "INFO" ? "green" : "black");
        // console.warn("Notification >> instance > %c" + tipo, "background-color: lightgray; color:" + consoleColorTipo, msg?.slice(0, 20));
        this.AddNotification(msg, tipo, readDuration);
        return this;
    }

    public _DestroyAll(): this {
        this.notificationsElements
            .forEach(notifElem => this.RemoverItem(notifElem));
        this.ContainerAppendEvaluator();
        return this;
    }

    /** La cadena acepta html */
    public static _Mostrar(msg: string, tipo: TTipoNotificacion, readDuration = DURATION_READ_DEFAULT) {
        if (Global._DEVELOPMENT_UTILS) {
            const consoleColorTipo = (tipo == "ADVERTENCIA" ? "red" : tipo == "INFO" ? "green" : "black");
            console.warn("Notification >> instance > %c" + tipo, "background-color: lightgray; color:" + consoleColorTipo, msg?.slice(0, 20));
        }
        NotificacionV2.MostrarGeneral(msg, tipo, readDuration);
        return this;
    }

    public static _DestroyAll() {
        NotificacionV2.RemoverTodo();
        return this;
    }
}
