import { ArrayV2 } from "../../util/ArrayV2";
import { DataDRequest } from "../DRequest";
import { Entidad } from "../Entidad";
import { Global } from "../Global";
import { DataUtil } from "../util/Util";

import ICategoriaEgreso = Entidad.IFinanzasEgresoCategoria;
const IDREQUEST = Entidad.CTipoRequest.EgresoCategoria;

export const _DictCategorias = new Map<number, ICategoriaEgreso>();

export async function _GetList(escuelas: Entidad.IEscuela[]): Promise<DataDRequest.IRequestResponseListA<ICategoriaEgreso>[]> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "finanzaEgresoCategoria/ObtenerLista";

    return new ArrayV2<Entidad.IEscuela>()
        ._Push(...escuelas)
        ._MapAwait(async (esc) => {
            const currentDateStr = await DataUtil._GetMaxDate(IDREQUEST, esc.IdKinder);
            const params = {
                "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
                "IdEscuela": esc.IdKinder,
                "Modificacion": currentDateStr
            }
            return DataDRequest._Request4List(url, "GET", params, IDREQUEST, esc.IdKinder);
        })
}

export function _Nuevo(categoriaEgreso: ICategoriaEgreso) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "finanzaEgresoCategoria/Nuevo";

    const params = {
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
        "IdEscuela": categoriaEgreso.IdEscuela,
        "Nombre": categoriaEgreso.Nombre
    }

    return DataDRequest._Request5(url, "POST", params, IDREQUEST)
}

export function _Actualizar(categoriaEgreso: ICategoriaEgreso) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "finanzaEgresoCategoria/Actualizar";

    const params = {
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "IdCategoria": categoriaEgreso.Id,
        "IdEscuela": categoriaEgreso.IdEscuela,
        "Nombre": categoriaEgreso.Nombre
    }

    return DataDRequest._Request5(url, "POST", params, IDREQUEST);
}

export function _Eliminar(idCategoria: number) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "finanzaEgresoCategoria/Eliminar";

    const params = {
        "IdCategoria": idCategoria,
        "IdUsuario": DataUtil._Usuario.IdUsuario
    }

    return DataDRequest._Request5(url, "POST", params, IDREQUEST)
}

export default {
    _DictCategorias,
    _Nuevo,
    _Actualizar,
    _Eliminar
}
