import * as d3 from "d3";
import { NotificacionV2 } from "./NotificacionV2";

export class CardV2 {
    /** Main selection */
    protected readonly cardSelection: TSelectionHTML<"div">;
    protected readonly cardHeaderSelection: TSelectionHTML<"div">;
    protected readonly cardHeaderTitleSelection: TSelectionHTML<"label">;
    protected readonly cardActionsContainerSelection: TSelectionHTML<"div">;
    protected readonly cardContentContainerSelection: TSelectionHTML<"div">;
    protected readonly ctrlProgress: TSelectionHTML<"wc-progress">;
    protected readonly ctrlNotification: NotificacionV2;

    constructor() {
        // Init card
        this.cardSelection = d3.create("div")
            .attr("class", "card");

        this.cardHeaderSelection = this.cardSelection.append("div")
            .attr("class", "header");

        this.cardHeaderTitleSelection = this.cardHeaderSelection.append("label")
            .attr("class", "title");

        this.cardContentContainerSelection = this.cardSelection.append("div")
            .attr("class", "content");

        this.cardActionsContainerSelection = this.cardSelection.append("div")
            .attr("class", "actions_container");

        this.ctrlNotification = new NotificacionV2();
        this.ctrlProgress = this.cardContentContainerSelection.append<HTMLProgressElement>("wc-progress")
            .attr("oculto", true) as unknown as TSelectionHTML<"wc-progress">;
    }

    public _SetTitulo(titulo: string) {
        this.cardHeaderTitleSelection.text(titulo);
        return this;
    }

    public _Destroy() {
        this.ctrlNotification._DestroyAll();
        this.ctrlProgress.attr("oculto", true);
        this.cardSelection.remove();
        return this;
    }

    public get _CardSelection() {
        return this.cardSelection;
    }

    public get _CardNode() {
        return this.cardSelection.node();
    }

    public get _HeaderSelection() {
        return this.cardHeaderSelection;
    }

    public get _HeaderNode() {
        return this.cardHeaderSelection.node();
    }

    public get _CardActionsSelection() {
        return this.cardActionsContainerSelection;
    }

    public get _CardActionsNode() {
        return this.cardActionsContainerSelection.node();
    }
}
