import * as d3 from "d3";
import { MainPage } from "../../MainPage";
import { DataDRequest } from "../../data/DRequest";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import { _DICC_ALUMNO, _LOCALDATA_GetAsignacionesHorasExtras } from "../../data/modulo/Alumno";
import DataModuloEscuela from "../../data/modulo/Escuela";
import DataModuloFinanzaCargo from "../../data/modulo/FinanzaCargo";
import { DateV2 } from "../../util/DateV2";
import { IConfigGridExcelExport, IGridExtraTableConfig, IGridRenderInfo, VentanaGrid } from "../controlD3/AVentanaGrid";
import { Button } from "../controlD3/Button";
import { ExcelThings } from "../controlD3/ExcelExport";
import { ModalThings } from "../controlD3/ModalThings";
import { Table } from "../controlD3/Tabla";
import { UIUtilFormat } from "../util/Format";
import { UIUtilLang } from "../util/Language";
import { UIUtilPermission } from "../util/Permission";
import { UIUtilTime } from "../util/Time";
import { UIUtilGeneral } from "../util/Util";
import { UIUtilViewData } from "../util/ViewData";
import { UIUtilViewFinanzaCargo } from "../utilView/FinanzaCargo";
import { UIUtilViewFinanzaHorasExtras } from "../utilView/FinanzaHorasExtras";

// import CTipoInteres = Entidad.CFinanzaCargoTipoInteres;
// import CTipoTasa = Entidad.CFinanzasCargoTipoTasa;
import ICargo = Entidad.IFinanzaCargo;
import IEscuela = Entidad.IEscuela;
import ICicloEscolar = Entidad.ICicloEscolar;

import CTipoHE = Entidad.CTipoHoraExtra;
import CEstadoCobro = Entidad.CEstadoCobro;
import CCategoriaFinanza = Entidad.CFinanzaCargoCategoria;
import CTipoValor = Entidad.CFinanzaCargoTipoValor;

import CAccionPermiso = Entidad.CAccionPermiso;

import _FmtToInputDate = UIUtilTime._FmtToInputDate;
import GetValidatorDateYM = UIUtilTime._GetValidatorDateYM;

import CFinanzasCargoRecurrenciaRecargo = Entidad.CFinanzasCargoRecurrenciaRecargo;
import CFormCargoField = UIUtilViewFinanzaCargo.CFormCargoField;

type IHoraExtra = Entidad.IFinanzaItemHoraExtra & {
    /** Auxiliar */
    DtEsperada?: DateV2;
}
type IAlumno = Entidad.IAlumno & {
    HorasExtras?: Map<number, IHoraExtra>;
};
const langContextModuleFCargo = "finanzascargo";

/** La estructura almacena información de la escuela a la que los cargos de alumnos pertenecen */
interface IProcesoRegistroCargo { // NOTE Arreglo de IProcesoRegistroCargo cuando sea una seleccion multiple de alumnos a procesar
    CicloEscolar: ICicloEscolar;
    FechaAplicacion: DateV2;
    ItemsProcesoCargo: IItemAlumnoProcesoCargo[];

    Escuela: IEscuela;
    CargoEntrada: ICargo;
    CargoSalida: ICargo;
    CiclosEscolares: ICicloEscolar[]
}
interface IItemAlumnoProcesoCargo {
    IdAux: number;
    Alumno: IAlumno;
    ParentProceso: IProcesoRegistroCargo;
    Entradas: IItemAlumnoHorasExtrasSelected;
    Salidas: IItemAlumnoHorasExtrasSelected;
}
interface IItemAlumnoHorasExtrasSelected {
    // Cargo: ICargo;
    HorasExtras: IHoraExtra[];
    /** Suma a pagar */
    ValorPago: number;
}

export class UIVentanaFinanzasHorasExtras extends VentanaGrid<IAlumno> {
    private ModalHrsExtras: UIUtilViewFinanzaHorasExtras.ModalHorasExtrasAlumno;

    constructor(content: d3.Selection<HTMLDivElement, any, HTMLElement, any>, modulo: Entidad.CModulo) {
        super(content, modulo);
    }

    public _OnServiceEvent(event: DataModuloMain.TipoRequestMonitorId) {
        super._OnServiceEvent(event);
        switch (event) {
            case this.GRID_GetDataRequestID():
                if (this.ModalHrsExtras && this.ModalHrsExtras.Modal._Visible) {
                    const alumno = DataModuloMain._GetItemDataByName("Alumno", this.ModalHrsExtras.DatoChild.IdChild);
                    let alumnoFinal: IAlumno = UIUtilGeneral._ObjectClone(alumno);
                    alumnoFinal.HorasExtras = _LOCALDATA_GetAsignacionesHorasExtras(alumno.IdChild);
                    this.ModalHrsExtras.DatoChild = alumnoFinal;
                    this.ModalHrsExtras.refresh();
                }
                break;
        }
    }

    // ***********************************************************************
    // Override
    // ***********************************************************************

    protected async GridGetData(): Promise<Array<IAlumno>> {
        return DataModuloMain._GetReqDataArrayByName("Alumno", true)
            .map(d => {
                let alumnoFinal: IAlumno = UIUtilGeneral._ObjectClone(d);
                alumnoFinal.HorasExtras = _LOCALDATA_GetAsignacionesHorasExtras(d.IdChild);
                return alumnoFinal;
            })
            .filter(d => (d.HorasExtras.size > 0));
    }

    // ***********************************************************************
    // GRID
    // ***********************************************************************

    protected GRID_GetDataRequestID(): DataModuloMain.TipoRequestMonitorId {
        return Entidad.CTipoRequest.FinanzaHoraExtra;
    }

    protected GRID_GetTableConfigBase(): IGridRenderInfo<IAlumno> {
        return {
            IdTabla: "FinanzasIngresos-HorasExtras",
            DefaultSort: "NombreCompleto",
            IdData: "IdChild",
            MinWidth: 700,
            Columns: [
                { Field: "NombreCompleto", Label: "Alumno", MinWidth: "200px", Width: "40%" },
                { Field: "NombreKinder", Label: "Escuela", MinWidth: "150px", Width: "25%" },
                {
                    Field: "Costo" as any,
                    Label: "Costo",
                    MinWidth: "100px",
                    Width: "20%",
                    ClassStyle: "link_item",
                    OnClickInCellCont: (alumno, event) => {
                        this.ModalHrsExtras = UIUtilViewFinanzaHorasExtras._OpenModal_VerHorasExtras(alumno);
                    }
                },
            ],
        }
    }

    protected GRID_GetTableConfigAdvanced(): IGridExtraTableConfig<IAlumno> {
        return {
            AddNameFieldClassToCells: true,
            EvaluatorAndSubLevelsBuild: <Table.IStepEvaluator<IAlumno, IHoraExtra, any>>{
                OnStepCellTable: (container, datum, field, indexInit) => {
                    if (field == "NombreCompleto") {
                        UIUtilGeneral._ElementAdd_LinkToGoToPanel(container, "alumnos/alumnos/panel", datum.IdChild);
                    }
                    else if (field == "Costo") {
                        container.text(this.GetTotalCostoHorasExtras(Array.from(datum.HorasExtras.values())));
                    }
                },
            }
        }
    }

    protected GRID_GetFilters(): Array<Table.IParametroFiltro<IAlumno>> {
        return [
            { Label: "Nombre", Field: "NombreCompleto" },
        ]
    }

    protected GRID_GetMenuTopGrid(): Array<Table.ITableMenuTopDefaultOptionConfig> {
        let acciones: Array<Table.ITableMenuTopDefaultOptionConfig> = [];
        if (UIUtilPermission._HasFinanzasModulesPermissionFromSchools()) {
            if (UIUtilPermission._HasAccionPermission(CAccionPermiso.Editar, Entidad.CModulo.FinanzasCargo)) {
                acciones.push({
                    Label: "action_editentrycharge",
                    Callback: () => {
                        this.OpenProcesoEditarCargos(CCategoriaFinanza.CargoEntrada)
                    }
                })
                acciones.push({
                    Label: "action_editexitcharge",
                    Callback: () => {
                        this.OpenProcesoEditarCargos(CCategoriaFinanza.CargoSalida)
                    }
                })
            }
        }
        return acciones;
    }

    protected GRID_GetSelectionDataMenuV2(menuLocation: "row" | "top-selected", alumnos: IAlumno[]): Table.ITableMenuDataSelectedOptionConfig<IAlumno>[] {
        let opciones: Array<Table.ITableMenuDataSelectedOptionConfig<IAlumno>> = [];
        const fnHasHEGeneradas: Table.ITableMenuDataSelectedOptionConfig<IAlumno>["GetDetails"] = (datosChild: IAlumno[]) => {
            let message: string;
            let timeZone = DataModuloMain._GetDataValueFieldByName("Escuela", datosChild[0].IdKinder, "ZonaHoraria");
            let sameTimeZone = datosChild.every(d => (timeZone == DataModuloMain._GetDataValueFieldByName("Escuela", d.IdKinder, "ZonaHoraria")));
            if (!sameTimeZone) {
                message = this.VB_GetUIStringModule("action_descr_failtimezone");
            }
            let tieneHEGeneradas = Boolean(
                datosChild
                    .find(child => {
                        return Boolean(
                            Array.from(child.HorasExtras.values())
                                .find(he => (he.EstadoCobro == CEstadoCobro.Generado))
                        )
                    })
            )
            if (!tieneHEGeneradas) {
                message = this.VB_GetUIStringModule("action_descr_failinvalid");
            }
            return {
                Enabled: (sameTimeZone && tieneHEGeneradas),
                Description: (sameTimeZone && tieneHEGeneradas) ? null : message,
            }
        }
        if (this.GridHasPermisoAccion(CAccionPermiso.GenerarCobro)) {
            opciones.push({
                Label: "action_generacobro",
                GetDetails: fnHasHEGeneradas,
                Callback: (datosChild) => {
                    let alumnos: IAlumno[] = datosChild
                        .map(d => {
                            const dd = Object.assign({}, d);
                            let horasExtrasGeneradas = Array.from(d.HorasExtras.values())
                                .filter(he => (he.EstadoCobro == CEstadoCobro.Generado));

                            if (horasExtrasGeneradas.length) {
                                dd.HorasExtras = new Map(horasExtrasGeneradas.map(d => [d.Id, d]));
                            } else {
                                dd.HorasExtras = null;
                            }
                            return dd;
                        })
                        .filter(d => Boolean(d.HorasExtras));

                    if (alumnos.length) {
                        this.OpenProcesoGenerarCobroMulti_0(alumnos);
                    } else {
                        this.notificacion._Mostrar(this.VB_GetUIStringModule("notif_noextrahrvalid"), "ADVERTENCIA");
                    }
                }
            })
        }

        if (this.GridHasPermisoAccion(CAccionPermiso.Ignorar)) {
            opciones.push({
                Label: "action_ignorextrahr",
                GetDetails: fnHasHEGeneradas,
                Callback: (datosChild) => {
                    let horasExtrasToDiscart: IHoraExtra[] = [];

                    datosChild.forEach(d => {
                        horasExtrasToDiscart.push(
                            ...Array.from(d.HorasExtras.values())
                                .filter(d => (d.EstadoCobro == CEstadoCobro.Generado))
                        );
                    });

                    if (horasExtrasToDiscart.length) {
                        this.OpenModal_ProcesoIgnorarHorasExtras(horasExtrasToDiscart);
                    } else {
                        this.notificacion._Mostrar(this.VB_GetUIStringModule("notif_noextrahrvalid"), "ADVERTENCIA");
                    }
                }
            })
        }

        if (this.GridHasPermisoAccionV2(CAccionPermiso.VerDetalle, alumnos.map(d => d.IdKinder))) {
            opciones.push({
                Label: "action_seeall",
                MultiData: false,
                Callback: (alumno) => {
                    UIUtilViewFinanzaHorasExtras._OpenModal_VerHorasExtras(alumno[0], true)
                }
            })
        }

        return opciones;
    }

    // ***********************************************************************
    // Formularios things
    // ***********************************************************************

    // *************************************************************************************
    // PROCESO REGISTRO HORAS POR SELECCIÓN MULTIPLE DE ALUMNOS
    // *************************************************************************************

    private OpenProcesoGenerarCobroMulti_0(alumnos: IAlumno[]) {
        if (!this.GridHasPermisoAccion(CAccionPermiso.GenerarCobro, ...alumnos.map(d => d.IdKinder))) {
            return;
        }
        let itemsProcesos: Map<number, IProcesoRegistroCargo> = new Map();

        for (let alumno of alumnos) {
            let itemProcesosByEscuela: IProcesoRegistroCargo = itemsProcesos.get(alumno.IdKinder);
            let childHorasSelected = Array.from(alumno.HorasExtras.values()); // .filter(d => d["isSelected"]);

            if (!itemProcesosByEscuela) {
                let escuela = this.GetEscuela(alumno.IdKinder);
                let ciclosEscolares = this.GetCiclosEscolaresByIdEsc(alumno.IdKinder);

                if (ciclosEscolares.length == 0) {
                    this.notificacion._Mostrar(
                        this.VB_GetUIStringModule("notif_escsinciclosesc")
                            .replace("_ESCUELA", escuela.Nombre),
                        "ADVERTENCIA"
                    )
                    return null;
                }

                itemProcesosByEscuela = {
                    CicloEscolar: null,
                    FechaAplicacion: null,
                    ItemsProcesoCargo: [],

                    CiclosEscolares: ciclosEscolares,
                    Escuela: escuela,
                    CargoEntrada: null,
                    CargoSalida: null
                }

                itemsProcesos.set(alumno.IdKinder, itemProcesosByEscuela);
            }

            let itemAlumnoProceso = this.GetItemAlumnoProcesoCargo(alumno, childHorasSelected);
            itemAlumnoProceso.ParentProceso = itemProcesosByEscuela;

            // Busca cargos de Entrada y Salida por escuela
            if (itemAlumnoProceso.Entradas.HorasExtras.length > 0 && !itemProcesosByEscuela.CargoEntrada) {
                itemProcesosByEscuela.CargoEntrada = this.GetFinanzaCargoToHoraExtra(alumno.IdKinder, CCategoriaFinanza.CargoEntrada);
                if (!itemProcesosByEscuela.CargoEntrada) {
                    this.notificacion._Mostrar(
                        this.VB_GetUIStringModule("notif_escsincrgoentrada")
                            .replace("_ESCUELA", itemProcesosByEscuela.Escuela.Nombre),
                        "ADVERTENCIA"
                    );
                    return null;
                }
            }
            if (itemAlumnoProceso.Salidas.HorasExtras.length > 0 && !itemProcesosByEscuela.CargoSalida) {
                itemProcesosByEscuela.CargoSalida = this.GetFinanzaCargoToHoraExtra(alumno.IdKinder, CCategoriaFinanza.CargoSalida);
                if (!itemProcesosByEscuela.CargoSalida) {
                    this.notificacion._Mostrar(
                        this.VB_GetUIStringModule("notif_escsincrgosalida")
                            .replace("_ESCUELA", itemProcesosByEscuela.Escuela.Nombre),
                        "ADVERTENCIA"
                    );
                    return null;
                }
            }

            itemProcesosByEscuela.ItemsProcesoCargo.push(itemAlumnoProceso);
        }

        this.openModal_MultiCobro(Array.from(itemsProcesos.values()), null);
    }

    private openModal_MultiCobro(itemsProcesos: Array<IProcesoRegistroCargo>, modalThings: ModalThings.IModalThings) {
        let auxItemsProcesos: IItemAlumnoProcesoCargo[] = [];
        let inputFAplicacion: HTMLInputElement;
        modalThings = ModalThings._GetModalToAProccess({
            Title: this.VB_GetUIStringModule("tag_regsthrextra"),
            Width: 1400,
            DrawContent: (container, mt) => {
                const validaDatos = () => {
                    let isValid = true;
                    for (let proceso of auxItemsProcesos) {
                        if (!proceso.ParentProceso.CicloEscolar) {
                            isValid = false;
                            break;
                        }
                    }
                    Button._EnableButton(mt.BtnRight, isValid);
                }
                let auxAllCicloesEscolares: ICicloEscolar[] = [];
                itemsProcesos.forEach(d => {
                    auxAllCicloesEscolares.push(...d.CiclosEscolares);
                })
                const limitsAplicationDate = this.GetMinMaxFromCiclos(auxAllCicloesEscolares);

                itemsProcesos.forEach(d => {
                    d.ItemsProcesoCargo.forEach(dP => {
                        auxItemsProcesos.push(dP) //    FIXME
                    })
                });

                mt.CurrentBody
                    .classed(UIUtilGeneral.FBoxOrientation.Vertical, true)
                    .style("height", "600px")
                    .style("row-gap", "10px")
                    .selectAll("div").remove()
                    .classed("registro_horasextras_multi", true);

                let div = mt.CurrentBody.append("div")
                    .classed("input_content", true)
                    .classed(UIUtilGeneral.FBoxAlign.StartCenter, true)
                    .style("gap", "var(--padding2)")
                    .style("width", "100%")
                    .style("flex-wrap", "wrap");

                div.append("label").text(this.VB_GetUIStringModule("tag_dtaplica") + ":")
                    .style("flex", "none");
                inputFAplicacion = div.append("input")
                    .style("width", "150px")
                    .classed("input_err", true)
                    .attr("type", "date")
                    .attr("min", _FmtToInputDate(limitsAplicationDate.Min))
                    .attr("max", _FmtToInputDate(limitsAplicationDate.Max))
                    .attr("required", true)
                    .node();
                inputFAplicacion.onchange = e => {
                    let fechaAppl: DateV2;
                    if (inputFAplicacion.validity.valid == false) {
                        d3.select(inputFAplicacion).classed("input_err", true);
                        Button._EnableButton(mt.BtnRight, false);
                        return;
                    }
                    fechaAppl = new DateV2(UIUtilTime._GetLocalDateFromInputDateString(inputFAplicacion.value));
                    itemsProcesos.forEach(dProceso => {
                        d3.select(inputFAplicacion)
                            .classed("input_err", false);
                        dProceso.FechaAplicacion = fechaAppl;
                        // Utils.Time.fn_GetDateConcatenatedDateTime(inputFAplicacion.value);
                        let cicloEscolarFinded = dProceso.CiclosEscolares
                            .find(dC => (
                                (GetValidatorDateYM(dProceso.FechaAplicacion) >= GetValidatorDateYM(new DateV2(dC.FechaInicio)._SetTimeZone(dProceso.Escuela.ZonaHoraria)))
                                && (GetValidatorDateYM(dProceso.FechaAplicacion) <= GetValidatorDateYM(new DateV2(dC.FechaFin)._SetTimeZone(dProceso.Escuela.ZonaHoraria)))
                            ))
                        dProceso.CicloEscolar = cicloEscolarFinded;
                    });
                    validaDatos();
                    ctrlTable._RefreshView();
                };

                let ctrlTable: Table.Tabla<IItemAlumnoProcesoCargo> = new Table.Tabla<IItemAlumnoProcesoCargo>({
                    IdTabla: "FinanzasIngresos-HorasExtras-GenerarCobro",
                    IdData: "IdAux",
                    Parent: mt.CurrentBody,
                    AddNameFieldClassToCells: true,
                    //FilterByStrSearch: "none",
                    RenderColumnHeadings: [
                        { Field: "Nombre" as any, Label: this.VB_GetUIStringModule("tblmultipay_field_nombre"), Width: "20%", MinWidth: "100px" },
                        { Field: "Escuela" as any, Label: this.VB_GetUIStringModule("tblmultipay_field_esc"), Width: "15%", MinWidth: "100px" },
                        { Field: "CicloEscolar" as any, Label: this.VB_GetUIStringModule("tblmultipay_field_cicloesc"), Width: "10%", MinWidth: "100px" },
                        { Field: "TotalCargo" as any, Label: this.VB_GetUIStringModule("tblmultipay_field_totcargo"), Width: "10%", MinWidth: "100px" },
                        { Field: "Tipo" as any, Label: this.VB_GetUIStringModule("tblmultipay_field_dttipo"), Width: "5%", MinWidth: "100px" },
                        { Field: "Vencimiento" as any, Label: this.VB_GetUIStringModule("tblmultipay_field_dtvence"), Width: "10%", MinWidth: "100px" },
                        { Field: "Morosidad" as any, Label: this.VB_GetUIStringModule("tblmultipay_field_dtmoroso"), Width: "10%", MinWidth: "100px" },
                        { Field: "Recargo" as any, Label: this.VB_GetUIStringModule("tblmultipay_field_rcrgs"), Width: "10%", MinWidth: "100px" },
                    ],
                    OptionsOfDataCheckV3: (data) => ({
                        MaxOptionsInRow: 5,
                        Options: [
                            {
                                Label: this.VB_GetUIStringModule("tag_descardeproceso"),
                                Callback: () => {
                                    let newItemsProcesos: IItemAlumnoProcesoCargo[] = [];

                                    for (let itemData of ctrlTable._data) {
                                        let childrenData = ctrlTable._SelectItemData<IItemAlumnoHorasExtrasSelected>(itemData.IdAux).GetChildrenData();
                                        let allChildsChecked = Array.from(childrenData.values())
                                            .every(d => d.IsCheked);

                                        if (!allChildsChecked) {
                                            newItemsProcesos.push(itemData);

                                            let itemEntrada = childrenData.get(CTipoHE.Llegada);
                                            let itemSalida = childrenData.get(CTipoHE.Salida);

                                            if (itemEntrada?.Data && itemEntrada.IsCheked) {
                                                itemData.Entradas.HorasExtras = [];
                                                itemData.Entradas.ValorPago = 0;
                                            }
                                            if (itemSalida?.Data && itemSalida.IsCheked) {
                                                itemData.Salidas.HorasExtras = [];
                                                itemData.Salidas.ValorPago = 0;
                                            }
                                        }
                                    }

                                    if (newItemsProcesos.length == 0) {
                                        ModalThings._GetConfirmacionModal({
                                            Message: "tag_descartandexit",
                                            LangModuleKeyInContext: this.labelsKeyBase,
                                            StrModalBotons: "sí_no",
                                            OnAccept: () => mt.Modal._Ocultar()
                                        });
                                    } else {
                                        auxItemsProcesos = newItemsProcesos;
                                        validaDatos();
                                        ctrlTable._UpdateData(auxItemsProcesos)
                                            ._RefreshView();
                                    }
                                }
                            }
                        ]
                    }),
                    EvaluatorAndSubLevelsBuild: <Table.IStepEvaluator<IItemAlumnoProcesoCargo, (IItemAlumnoHorasExtrasSelected & { IdAux: CTipoHE }), any>>{
                        OnEvalIsCollapsedRow: () => false,
                        OnStepCellTable: (container, datum, field, step) => {
                            let strText = ""
                            switch (field) {
                                case "Nombre":
                                    strText = datum.Alumno.NombreCompleto;
                                    break;
                                case "Escuela":
                                    strText = datum.ParentProceso.Escuela.Nombre;
                                    break;
                                case "CicloEscolar":
                                    if (datum.ParentProceso.CicloEscolar) {
                                        strText = datum.ParentProceso.CicloEscolar.Nombre;
                                        container.style("color", null);
                                    }
                                    else {
                                        strText = this.VB_GetUIStringModule("tag_selectdtaplica");
                                        container.style("color", "red");
                                    }

                                    break;
                                case "TotalCargo":
                                    strText = UIUtilFormat._CurrencyFmt(datum.Entradas.ValorPago + datum.Salidas.ValorPago);
                                    break;
                                case "Tipo":
                                case "Vencimiento":
                                case "Morosidad":
                                case "Recargo":
                                    break;
                            }
                            container.text(strText)
                        },
                        OnStepRowTable: (datum, tr, rowBody) => {
                            rowBody.selectAll("td").style("background", (!datum.ParentProceso.CicloEscolar ? "rgb(255, 248, 248)" : null), "important");
                        },
                        EvaluatorSubLevel: {
                            IdMember: "IdAux",
                            OnGetData: (datum) => {
                                let itemsChilds: (IItemAlumnoHorasExtrasSelected & { IdAux: CTipoHE })[] = [];

                                if (datum.Entradas.HorasExtras.length > 0) {
                                    itemsChilds.push({
                                        ...{ IdAux: CTipoHE.Llegada },
                                        ...datum.Entradas
                                    });
                                }
                                if (datum.Salidas.HorasExtras.length > 0) {
                                    itemsChilds.push({
                                        ...{ IdAux: CTipoHE.Salida },
                                        ...datum.Salidas
                                    });
                                }
                                return itemsChilds;
                            },
                            OnStepRowTable: (childDatum, childTr, rowBody, indexOrigin, datumProceso) => {
                                let strText = ""
                                ctrlTable._InfoColumns.forEach(d => {
                                    switch (d.Field as any) {
                                        case "Nombre":
                                        case "Escuela":
                                        case "CicloEscolar":
                                            break;
                                        case "TotalCargo":
                                            strText = UIUtilFormat._CurrencyFmt(childDatum.ValorPago);
                                            break;
                                        case "Tipo":
                                            strText = UIUtilViewData._GetStr_TipoHoraExtra(childDatum.HorasExtras[0].Tipo);
                                            break;
                                        case "Vencimiento":
                                            if (datumProceso.ParentProceso.FechaAplicacion) {
                                                let fechaSelDt = new DateV2(datumProceso.ParentProceso.FechaAplicacion);
                                                if (childDatum.HorasExtras[0].Tipo == CTipoHE.Llegada) {
                                                    fechaSelDt.setDate(fechaSelDt.getDate() + datumProceso.ParentProceso.CargoEntrada.DiaVencimiento);
                                                } else if (childDatum.HorasExtras[0].Tipo == CTipoHE.Salida) {
                                                    fechaSelDt.setDate(fechaSelDt.getDate() + datumProceso.ParentProceso.CargoSalida.DiaVencimiento);
                                                }
                                                strText = UIUtilTime._DateFormatStandar(fechaSelDt);
                                            } else {
                                                strText = "---";
                                            }
                                            break;
                                        case "Morosidad":
                                            if (datumProceso.ParentProceso.FechaAplicacion) {
                                                let fechaSelDt = new DateV2(datumProceso.ParentProceso.FechaAplicacion);
                                                if (childDatum.HorasExtras[0].Tipo == CTipoHE.Llegada) {
                                                    fechaSelDt.setDate(fechaSelDt.getDate() + datumProceso.ParentProceso.CargoEntrada.DiaVencimiento + datumProceso.ParentProceso.CargoEntrada.DiaMorosidad);
                                                } else if (childDatum.HorasExtras[0].Tipo == CTipoHE.Salida) {
                                                    fechaSelDt.setDate(fechaSelDt.getDate() + datumProceso.ParentProceso.CargoSalida.DiaVencimiento + datumProceso.ParentProceso.CargoSalida.DiaMorosidad);
                                                }
                                                strText = UIUtilTime._DateFormatStandar(fechaSelDt);
                                            } else {
                                                strText = "---";
                                            }
                                            break;
                                        case "Recargo":
                                            strText = "---"
                                            if (childDatum.HorasExtras[0].Tipo == CTipoHE.Llegada) {
                                                let cargoEntrada = datumProceso.ParentProceso.CargoEntrada;
                                                strText = UIUtilViewFinanzaCargo._GetStrRecargosCol(cargoEntrada);
                                            } else if (childDatum.HorasExtras[0].Tipo == CTipoHE.Salida) {
                                                let cargoSalida = datumProceso.ParentProceso.CargoSalida;
                                                strText = UIUtilViewFinanzaCargo._GetStrRecargosCol(cargoSalida);
                                            }
                                            break;
                                    }
                                    childTr.select("." + d.Field).select(".item_cont").text(strText)
                                })
                            }
                        }
                    },
                    OnValueSelectRow: (id, datum) => console.log(id, datum),
                })

                ctrlTable._Control.select(".area_filtros").remove();

                validaDatos();
                ctrlTable._UpdateData(auxItemsProcesos);
            },
            OnAccept: async (mt) => {
                if (!(itemsProcesos.every(dP => (dP.FechaAplicacion && !isNaN(dP.FechaAplicacion.getDate()))))) {
                    d3.select(inputFAplicacion).classed("input_err", true);
                    return null;
                }
                else {
                    d3.select(inputFAplicacion).classed("input_err", false);
                    console.log("Temporal registrar cargos multiples", auxItemsProcesos)
                    let res: DataDRequest.IResultadoPeticion<number[]>;

                    let itemsFallidos: IItemAlumnoProcesoCargo[] = [];
                    let IDSEscuelas: number[] = [];

                    for (let proceso of auxItemsProcesos) {
                        IDSEscuelas.push(proceso.Alumno.IdKinder);
                        res = await UIUtilViewFinanzaHorasExtras._SvRegistrarCargo({
                            FechaAplicacion: proceso.ParentProceso.FechaAplicacion,
                            CargoEntrada: proceso.ParentProceso.CargoEntrada,
                            CargoSalida: proceso.ParentProceso.CargoSalida,
                            CicloEscolar: proceso.ParentProceso.CicloEscolar,
                            Item: proceso
                        }, false);

                        if (res.Resultado < 1) {
                            itemsFallidos.push(proceso);
                        }
                    }

                    if (itemsFallidos.length > 0) {
                        ModalThings._GetModalInfoDataList({
                            Title: UIUtilLang._GetUIString("general", "failresume"),
                            InfoText: this.VB_GetUIStringModule("notif_failarraycobros"),
                            DataList: itemsFallidos,
                            Width: 500,
                            OnStepItemData: (container, item) => {
                                container.text(item.Alumno.NombreCompleto);
                            },
                            OnAccept: () => {
                                if (auxItemsProcesos.length > itemsFallidos.length) {
                                    this.ctrlProgressBar.attr("oculto", false);
                                    MainPage._ReloadService(this.GRID_GetDataRequestID(), IDSEscuelas, (reqID, reloadId, error) => {
                                        if (error !== null) {
                                            this.notificacion._Mostrar(UIUtilLang._GetUIString("general", "notif_fail_infoupdate"), "ADVERTENCIA");
                                        }
                                    });
                                }
                                modalThings.Modal._Ocultar();
                            }
                        })
                    }
                    else {
                        this.ctrlProgressBar.attr("oculto", false);
                        MainPage._ReloadService(this.GRID_GetDataRequestID(), IDSEscuelas, (requID, reloadId, error) => {
                            if (error !== null) {
                                this.notificacion._Mostrar(UIUtilLang._GetUIString("general", "notif_fail_infoupdate"), "ADVERTENCIA");
                            }
                        });
                        modalThings.Modal._Ocultar();
                    }
                    return null;
                }
            }
        })
        modalThings.BtnLeft.classed("hide_transparent", true).on("click", null);
    }
    // *************************************************************************************
    // PROCESO IGNORAR HORAS EXTRAS
    // *************************************************************************************

    private OpenModal_ProcesoIgnorarHorasExtras(horasExtras: IHoraExtra[]) {
        // if (!this.GridHasPermisoAccion(CAccionPermiso.Ignorar, ...alumnos.map(d => d.IdKinder))) {
        //     return;
        // }
        ModalThings._GetModalToAProccess({
            LangModuleKeyInContext: this.labelsKeyBase,
            Title: "action_ignorextrahr",
            IdsEscuelas: [...new Set(horasExtras.map(d => d.IdEscuela))],
            Modulo: this.modulo,
            Action: CAccionPermiso.Ignorar,
            Width: 800,
            AccionToHttpMessage: "agregar",
            DrawContent: (content, modalThings) => {
                content
                    .classed(UIUtilGeneral.FBoxOrientation.Vertical, true)
                    .style("height", "100%")
                    .style("overflow", "hidden")
                    .style("row-gap", "10px")
                    .append("div")
                    .text(this.VB_GetUIStringModule("tag_regtsaignorar"));

                let ctrltablaIgnorados = new Table.Tabla<IHoraExtra>({
                    IdTabla: "FinanzasIngresos-HorasExtras-IgnorarHrsExtr",
                    Parent: content,
                    IdData: "Id",
                    FilterByStrSearch: "none",
                    HideCheckboxes: true,
                    MinWidth: 600,
                    RenderColumnHeadings: [
                        { Field: "Alumno" as any, Label: this.VB_GetUIStringModule("tblignor_field_alum"), MinWidth: "100px", Width: "25%", IsSortable: false },
                        { Field: "Costo", Label: this.VB_GetUIStringModule("tblignor_field_cost"), MinWidth: "100px", Width: "20%", IsSortable: false },
                        { Field: "Tipo", Label: this.VB_GetUIStringModule("tblignor_field_tipo"), MinWidth: "100px", Width: "15%", IsSortable: false },
                        { Field: "Fecha", Label: this.VB_GetUIStringModule("tblignor_field_dt"), MinWidth: "100px", Width: "20%", IsSortable: false },
                        { Field: "Escuela" as any, Label: this.VB_GetUIStringModule("tblignor_field_esc"), MinWidth: "100px", Width: "20%", IsSortable: false }
                    ],
                    EvaluatorAndSubLevelsBuild: {
                        OnStepCellTable: (container, datum, field, step) => {
                            switch (field) {
                                case "Alumno":
                                    container.text(_DICC_ALUMNO.get(datum.IdAlumno).NombreCompleto);
                                    break;
                                case "Costo":
                                    container.text(UIUtilFormat._CurrencyFmt(datum.Costo));
                                    break;
                                case "Tipo":
                                    container.text(UIUtilViewData._GetStr_TipoHoraExtra(datum.Tipo));
                                    break;
                                case "Fecha":
                                    container.text(UIUtilTime._DateFormatStandar(datum.Fecha)); // FIX_TIMEZONE
                                    break;
                                case "Escuela":
                                    container.text(DataModuloEscuela._DiccEscuela.get(datum.IdEscuela).Nombre)
                                    break;
                            }
                        }
                    }
                    // OnStepItemTableV2: 
                })

                ctrltablaIgnorados._UpdateData(horasExtras);
            },
            OnAccept: (mt) => UIUtilViewFinanzaHorasExtras._SvIgnorarHorasExtras(horasExtras)
        })
    }

    // *************************************************************************************
    // PROCESO EDITAR CARGO
    // *************************************************************************************

    private OpenProcesoEditarCargos(categoria: (CCategoriaFinanza.CargoEntrada | CCategoriaFinanza.CargoSalida)) {
        let kindersConCargos = UIUtilPermission._GetSchoolsByActionModule(Entidad.CModulo.FinanzasCargo, CAccionPermiso.Editar) // this.kinders
            .filter(d => {
                return Boolean(this.GetFinanzaCargoToHoraExtra(d.IdKinder, categoria)) && UIUtilPermission._HasFinanzasModulesPermissionFromSchools(d.IdKinder)
            });

        if (kindersConCargos.length == 0) {
            if (categoria == CCategoriaFinanza.CargoEntrada) {
                this.notificacion._Mostrar(this.VB_GetUIStringModule("notif_escssincargsentrada"), "ADVERTENCIA");
            }
            else if (categoria == CCategoriaFinanza.CargoSalida) {
                this.notificacion._Mostrar(this.VB_GetUIStringModule("notif_escssincargssalida"), "ADVERTENCIA");
            }
            return;
        }

        const initCargoFinded = this.GetFinanzaCargoToHoraExtra(kindersConCargos[0].IdKinder, categoria);
        const formCargo = UIUtilViewFinanzaCargo._GetFormAddEditCargo(CAccionPermiso.Editar, initCargoFinded, "HorasExtras");
        UIUtilViewFinanzaCargo._OpenModal_FormAddEditCargo(CAccionPermiso.Editar, initCargoFinded, formCargo, "HorasExtras");
    }

    protected GRID_GetExportarConfig(dataGrid: IAlumno[]): IConfigGridExcelExport<any> {
        interface IDataToExport extends Pick<IAlumno, "NombreCompleto" | "NombreKinder"> {
            Costo: string;
        }

        let idsEscuelas = [... new Set(dataGrid.map(d => (d.IdKinder)))];

        return <IConfigGridExcelExport<IDataToExport>>{
            IdsEscuelas: idsEscuelas,
            ColumnsConfig: this.ctrlTabla
                ._InfoColumns
                .map<ExcelThings.IColumnToExcelExportFileConfig<IDataToExport>>(d => ({
                    Field: d.Field as keyof IDataToExport,
                    HeaderTag: d.Label,
                    TypeData: "text", // d.Field == "FechaRegistro" ? "date" : "text",
                    WidthCell: d.Field == "NombreCompleto" ? 25 : 20
                })),
            OnGetDataBySheets: async () => {
                let groupedData: Map<number, IDataToExport[]> = new Map(idsEscuelas.map(idEscuela => ([idEscuela, []])));

                dataGrid.forEach((dato) => {
                    let { Data: alumno } = this.ctrlTabla._SelectItemData(dato.IdChild);
                    let datosEnEscuela = groupedData.get(dato.IdKinder);

                    datosEnEscuela.push({
                        NombreCompleto: alumno.NombreCompleto,
                        NombreKinder: alumno.NombreKinder,
                        Costo: this.GetTotalCostoHorasExtras(Array.from(alumno.HorasExtras.values())),
                    });
                });

                return Array.from(groupedData)
                    .map(([idEscuela, datos]) => ({
                        IdSheet: idEscuela,
                        SheetName: datos[0].NombreKinder,
                        Data: datos,
                    }));
            },
            OnGetEscuelasTagInSheet: (datos) => datos[0].NombreKinder,
        }
    }

    // *************************************************************************************
    // LOCAL DATA
    // *************************************************************************************

    /** Solo devuelve el total del costo de horas extras en Estado Cobro == Generado */
    private GetTotalCostoHorasExtras(horasExtras: IHoraExtra[]) {
        let totalPago = 0;
        horasExtras.forEach(itemHE => {
            if (itemHE.EstadoCobro == CEstadoCobro.Generado) {
                totalPago += itemHE.Costo;
            }
        });
        return UIUtilFormat._CurrencyFmt(totalPago);
    }


    private GetCiclosEscolaresByIdEsc(idEscuela: number) {
        const validatorCurrentDate = GetValidatorDateYM(new Date());
        return DataModuloMain._GetReqDataArrayByName("CicloEscolar")
            .filter(d => (d.IdEscuela == idEscuela && (GetValidatorDateYM(new Date(d.FechaFin)) >= validatorCurrentDate)));
    }

    private GetEscuela(idEscuela: number) {
        return DataModuloEscuela._DiccEscuela.get(idEscuela);
    }

    private GetFinanzaCargoToHoraExtra(idEscuela: number, tipo: (CCategoriaFinanza.CargoEntrada | CCategoriaFinanza.CargoSalida)) {
        return Array.from(DataModuloFinanzaCargo._DiccFinanzasCargos.values())
            .find(d => (d.IdEscuela == idEscuela && d.Categoria == tipo))
    }

    private GetTotalesByHorasExtras(horasExtras: IHoraExtra[]) {
        let totalPago = 0;
        let minutosTotales = 0;
        let minutosCobrados = 0;
        horasExtras.forEach(item => {
            totalPago += item.Costo;
            minutosTotales += item.MinutosTotales;
            minutosCobrados += item.MinutosCobrados;
        });
        return {
            TotalPago: totalPago,
            MinTotales: minutosTotales,
            MinCobrados: minutosCobrados
        }
    }

    /** Agrupa el tipo de horas extras del alumno y calcula el total a pagar */
    private GetItemAlumnoProcesoCargo(datoChild: IAlumno, horasExtra: IHoraExtra[]): IItemAlumnoProcesoCargo {
        let item: IItemAlumnoProcesoCargo = {
            IdAux: datoChild.IdChild,
            Alumno: datoChild,
            ParentProceso: null,
            Entradas: {
                ValorPago: 0,
                // Cargo: null,
                HorasExtras: []
            },
            Salidas: {
                ValorPago: 0,
                // Cargo: null,
                HorasExtras: []
            }
        }

        // Agrupa Entradas, Salidas
        horasExtra.forEach(d => {
            if (d.Tipo == CTipoHE.Llegada) {
                item.Entradas.HorasExtras.push(d);
            } else if (d.Tipo == CTipoHE.Salida) {
                item.Salidas.HorasExtras.push(d);
            }
        });

        if (item.Entradas.HorasExtras.length > 0) {
            item.Entradas.ValorPago = this.GetTotalesByHorasExtras(item.Entradas.HorasExtras).TotalPago;
        }
        if (item.Salidas.HorasExtras.length > 0) {
            item.Salidas.ValorPago = this.GetTotalesByHorasExtras(item.Salidas.HorasExtras).TotalPago;
        }

        return item;
    }

    /** Devuelve las fechas minima y maxima de un arreglo de ciclos escolares
     * Nota: El limite del minimo es la fecha actual
    */
    private GetMinMaxFromCiclos(cicloesEscolares: ICicloEscolar[]) {
        let minDate: DateV2 = null;
        let maxDate: DateV2 = null;
        const timeZone = DataModuloMain._GetDataValueFieldByName("Escuela", cicloesEscolares[0].IdEscuela, "ZonaHoraria");
        const currentDate = new DateV2()._SetTimeZone(timeZone, true);

        cicloesEscolares.forEach(ciclo => {
            if (!minDate && !maxDate) {
                minDate = new DateV2(ciclo.FechaInicio)._SetTimeZone(timeZone);
                maxDate = new DateV2(ciclo.FechaFin)._SetTimeZone(timeZone);
            } else {
                let auxMinDate = new DateV2(ciclo.FechaInicio)._SetTimeZone(timeZone);;
                let auxMaxDate = new DateV2(ciclo.FechaFin)._SetTimeZone(timeZone);;
                if (GetValidatorDateYM(auxMinDate) < GetValidatorDateYM(minDate)) {
                    minDate = auxMinDate
                }
                if (GetValidatorDateYM(auxMaxDate) > GetValidatorDateYM(maxDate)) {
                    maxDate = auxMaxDate
                }
            }
        })
        return {
            Min: ((GetValidatorDateYM(minDate) < GetValidatorDateYM(currentDate)) ? currentDate : minDate),
            Max: maxDate
        }
    }

    // *************************************************************************************
    // Data final things
    // *************************************************************************************
}
