import * as d3 from "d3";
import { group as d3Group } from "d3-array";
import { MainPage } from "../../MainPage";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import DataModuloEscuela from "../../data/modulo/Escuela";
import DataModuloGrupo from "../../data/modulo/Grupo";
import { IConfigGridExcelExport, IGridExtraTableConfig, IGridRenderInfo, VentanaGrid } from "../controlD3/AVentanaGrid";
import { ExcelThings } from "../controlD3/ExcelExport";
import { Fields, FormGenerator } from "../controlD3/Formulario";
import { ExcelThingsV2 } from "../controlD3/InputExcelV2";
import { ModalThings } from "../controlD3/ModalThings";
import { SelectV2 } from "../controlD3/SelectV2";
import { Table } from "../controlD3/Tabla";
import { UIUtilLang } from "../util/Language";
import { UIUtilViewGrupos } from "../utilView/Grupos";
import { UIWindowManager } from "./WindowManager";

import CAccionPermiso = Entidad.CAccionPermiso;
import IGrupo = Entidad.IGrupo;

export class UIVentanaGrupos extends VentanaGrid<IGrupo>{

    constructor(content: d3.Selection<HTMLDivElement, undefined, HTMLElement, any>, modulo: Entidad.CModulo) {
        super(content, modulo);
    }

    //-CONFIG TABLA-------------------------------------------------------------------------
    protected GRID_GetTableConfigBase(): IGridRenderInfo<IGrupo> {
        return {
            IdTabla: "Grupos",
            Title: "",
            DefaultSort: "Nombre",
            IdData: "IdGrupo",
            MaxOptionsInRow: 3,
            MinWidth: 700,
            Columns: [
                {
                    Field: "Nombre", Label: "Nombre", Width: "20%", MinWidth: "75px", ClassStyle: "link_item", OnClickInCellCont: (datoGrupo: IGrupo, event) => {
                        this.OpenPanelModeVerDetalle(datoGrupo);
                    }
                },
                { Field: "StrTipoGrupo", Label: "Tipo", Width: "20%", MinWidth: "75px" },
                { Field: "NombreGrado", Label: "Grado", Width: "20%", MinWidth: "75px" },
                { Field: "NombreEscolaridad", Label: "Escolaridad", Width: "20%", MinWidth: "75px" },
                { Field: "NombreKinder", Label: "Escuela", Width: "20%", MinWidth: "75px" }
            ]
        };
    }

    protected GRID_GetMenuTopGrid(): Table.ITableMenuTopDefaultOptionConfig[] {
        let res: Table.ITableMenuTopDefaultOptionConfig[] = [];
        if (this.GridHasPermisoAccion(Entidad.CAccionPermiso.Agregar)) {
            res.push(
                {
                    Label: "Agregar", Callback: () => {
                        this.OpenModal_FormularioAgregar();
                    }
                },
                {
                    Label: "action_cargamasiva", Callback: () => {
                        this.OpenModal_CargaMasiva();
                    }
                }
            )
        }

        return res;
    }

    protected GRID_GetSelectionDataMenuV2(menuLocation: "row" | "top-selected", dataGridSelected: IGrupo[]): Table.ITableMenuDataSelectedOptionConfig<IGrupo>[] {
        let opciones: Array<Table.ITableMenuDataSelectedOptionConfig<IGrupo>> = [];

        if (this.GridHasPermisoAccion(Entidad.CAccionPermiso.Editar)) {
            opciones.push({
                Label: "Editar",
                MultiData: false,
                Callback: (datos: Array<IGrupo>) => {
                    this.OpenModal_FormularioEditar(datos[0]);
                }
            });
        }

        if (this.GridHasPermisoAccion(Entidad.CAccionPermiso.Editar)) {
            opciones.push({
                Label: "action_edithorario",
                MultiData: false,
                Callback: (datos: Array<IGrupo>) => {
                    this.OpenPanelModeHorarioOperacion(datos[0].IdGrupo, datos[0].IdKinder, "real");
                }
            });
        }

        if (this.GridHasPermisoAccion(Entidad.CAccionPermiso.Eliminar)) {
            opciones.push({
                Label: "Eliminar",
                GetDetails: (grupos) => {
                    let enable = grupos.find(grupo => (DataModuloGrupo._LOCALDATA_GetAlumnosEnGrupo(grupo.IdGrupo).size > 0));
                    return {
                        Enabled: !Boolean(enable),
                        Description: enable ? this.VB_GetUIStringModule("tlt_eliminar_desc_fail").replace("_NAME", enable?.Nombre) : "", //(!enable ? "⚠️ Existen alumnos en el grupo" : null),
                    }
                },
                Callback: (datos: Array<IGrupo>) => this.OpenModal_EliminarDatos(datos)
            });
        }

        return opciones;
    }

    protected GRID_GetFilters(): Array<Table.IParametroFiltro<IGrupo>> {
        return [
            { Label: "Nombre", Field: "Nombre" },
            {
                LabelLangKey: "d_field_strtipogrupo",
                Field: "EsPrincipal",
                Type: "select",
                Options: [
                    { Id: true, Name: this.VB_GetUIStringModule("tag_primario") },
                    { Id: false, Name: this.VB_GetUIStringModule("tag_secundario") },
                ],
            },
            { Label: "Escolaridad", Field: "NombreEscolaridad" },
            { Label: "Grado", Field: "NombreGrado" }
        ];
    };

    protected GRID_GetTableConfigAdvanced(): IGridExtraTableConfig<any> {
        return {
            AddNameFieldClassToCells: true,
            EvaluatorAndSubLevelsBuild: <Table.IStepEvaluator<any, any, any>>{
                OnStepCellTable: (container, datum, field, indexInit) => {
                    if (field == "Nombre") {
                        container.text(UIUtilViewGrupos._GetLblGrupoName(datum));
                    }
                }
            }
        }
    }

    protected GRID_GetDataRequestID(): DataModuloMain.TipoRequestMonitorId {
        return Entidad.CTipoRequest.Grupo
    }

    // protected GRID_GridGetDataRequestIDExtras(): data.Entidades.CTipoRequest[] {
    //     return [data.Entidades.CTipoRequest.Nivel];
    // }

    public _OnServiceEvent(eventName: DataModuloMain.TipoRequestMonitorId) {
        super._OnServiceEvent(eventName);
        switch (eventName) {
            case Entidad.CTipoRequest.Grado:
                // app.RefreshDict(this.GRID_GetDataRequestID()); //  REMOVER?
                // this.GridUpdateData();
                this.ctrlTabla._RefreshView();
                break;
        }
    }

    private async OpenModal_FormularioAgregar() {
        this.GridOpenModal_ActionFormToAGridData({
            Action: Entidad.CAccionPermiso.Agregar,
            AutoReloadGridRequestOnFinally: false,
            Width: 450,
            GetForm: () => UIUtilViewGrupos._GetForm(CAccionPermiso.Agregar, <IGrupo>{}),
            OnAccept: async (form) => {
                let grupoDForm = form._Data;

                let res = await DataModuloGrupo._AltaGrupoV2(grupoDForm);

                if (res.Resultado > 0) {
                    let resultSuccess = await MainPage._ReloadServiceAndAwaitBool(this.GRID_GetDataRequestID(), grupoDForm.IdKinder);
                    if (resultSuccess) {
                        this.OpenPanelModeHorarioOperacion(res.Resultado, grupoDForm.IdKinder, "predefined");
                    } else {
                        this.notificacion._Mostrar(UIUtilLang._GetUIString("general", "notif_fail_infosync"), "ADVERTENCIA");
                    }
                }

                return res;
            }
        })
    }

    private OpenModal_FormularioEditar(grupoDato: IGrupo) {
        let grupoToForm = Object.assign({}, grupoDato) as IGrupo;

        this.GridOpenModal_ActionFormToAGridData({
            Action: Entidad.CAccionPermiso.Editar,
            Width: 450,
            IdsEscuelas: [grupoDato.IdKinder],
            GetForm: () => UIUtilViewGrupos._GetForm(CAccionPermiso.Editar, grupoToForm),
            OnAccept: (form, modalThings) => {
                let fdata = form._Data;
                return DataModuloGrupo._ActualizarGrupoV3(fdata.IdGrupo, fdata.Nombre, fdata.IdNivel, fdata.IdEscolaridad, fdata.EsPrincipal);
            },
        })
    }

    private OpenModal_EliminarDatos(datosGrupos: Array<IGrupo>) {
        this.GridOpenModal_ProccessArrayData({
            DataToProccess: datosGrupos,
            OnGetIdEscuela: (grupo) => grupo.IdKinder,
            OnError_GetItemDataTag: (grupo) => grupo.Nombre,
            OnStepAProccess: (grupo) => DataModuloGrupo._EliminarGrupoV2(grupo.IdGrupo)
        })
    }

    private OpenModal_CargaMasiva() {
        type TGeneralData = {
            IdEscuela: number;
            IdEscolaridad: number;
            IdGrado: number;
        }
        let formRegistrar: FormGenerator<TGeneralData>;
        ExcelThingsV2._UploadExcelData<Pick<IGrupo, "Nombre" | "EsPrincipal">>({
            TableId: "Grupos-CargaMasiva",
            ValidateUniqueItems: true,
            ModalWidth: 800,
            TableMinWidth: 600,
            Columns: [
                { Field: "Nombre", Label: this.VB_GetUIStringModule("d_field_nombre"), Required: true, UIMinWidthTH: "150px" },
                {
                    Field: "EsPrincipal",
                    RequiredValueType: "boolean",
                    OnGetFixedValueMapped: (adaptedValue, cellVal) => {
                        if (cellVal == "Principal" || cellVal == "Normal") {
                            return (cellVal == "Principal");
                        }
                        return null;
                    },
                    OnGetErrorType: (cellVal) => {
                        return {
                            Valid: (cellVal != null),
                            ErrorType: "_tipofmt"
                        }
                    },
                    OnGetErrorMessage: (value, errorType) => {
                        if (errorType == "_tipofmt") {
                            return [
                                UIUtilLang._GetUIString("crgamasiva", "tag_incorrcttipo"),
                                "'Principal' o 'Normal'"
                            ]
                        }
                        return "";
                    },
                    ValueFormat: "excel_origin",
                    Label: this.VB_GetUIStringModule("d_field_strtipogrupo"),
                    UIMinWidthTH: "150px",
                    // "RequiredValueType": "string"
                }
            ],
            OnGetTemplateFile: () => DataModuloGrupo._DownloadExcelTemplate(),
            ConfigActionUploadSelection: {
                TypeUpload: "allByOne",
                OnCallService: async (datos) => {
                    let datosGen = formRegistrar._Data;

                    let res = await DataModuloGrupo._CargaMasivaV2(
                        datosGen.IdEscuela,
                        datosGen.IdEscolaridad,
                        datosGen.IdGrado,
                        datos
                            .map(d => ({
                                Nombre: d.Nombre,
                                EsPrincipal: d.EsPrincipal
                            }))
                    );

                    if (res.Resultado > 0) {
                        MainPage._ReloadService(this.GRID_GetDataRequestID(), datosGen.IdEscuela);
                    }

                    return res;
                },
                OnCallBeforeToProccess: () => {
                    return new Promise<boolean>(resolve => {
                        ModalThings._GetModalToForm({
                            Title: UIUtilLang._GetUIString("crgamasiva", "tag_assignregts"),
                            Width: 400,
                            GetForm: () => {
                                formRegistrar = new FormGenerator<TGeneralData>()
                                    ._Crear({
                                        LabelMaxWidth: 120,
                                        schema: [
                                            {
                                                type: Fields.selectMaterial,
                                                model: "IdEscuela",
                                                labelAttr: { text: UIUtilLang._GetUIString("crgamasiva", "tag_esc") },
                                                selectMaterialAttr: {
                                                    valueMember: "IdKinder",
                                                    displayMember: "Nombre",
                                                    onChange: (idKinder) => {
                                                        let listEscolaridad = DataModuloMain._GetReqDataArrayByName("Escolaridad", true)
                                                            .filter(d => d.IdEscuela == idKinder);

                                                        (<SelectV2>formRegistrar._ControlsData.get("IdEscolaridad").instance)
                                                            ._ResetSelect()
                                                            ._UpdateList(listEscolaridad);

                                                        (<SelectV2>formRegistrar._ControlsData.get("IdGrado").instance)
                                                            ._ResetSelect()
                                                            ._UpdateList([]);
                                                    }
                                                },
                                                values: DataModuloMain._GetReqDataArrayByName("Escuela", true)
                                            },
                                            {
                                                type: Fields.selectMaterial,
                                                model: "IdEscolaridad",
                                                labelAttr: { text: UIUtilLang._GetUIString("crgamasiva", "tag_nivel") },
                                                selectMaterialAttr: {
                                                    valueMember: "Id",
                                                    displayMember: "Nombre",
                                                    onChange: (idEscolaridad) => {
                                                        let listGrados = DataModuloMain._GetReqDataArrayByName("Grado", true)
                                                            .filter(d => (d.IdEscolaridad == idEscolaridad));

                                                        (<SelectV2>formRegistrar._ControlsData.get("IdGrado").instance)
                                                            ._ResetSelect()
                                                            ._UpdateList(listGrados);
                                                    }
                                                },
                                                values: []
                                            },
                                            {
                                                type: Fields.selectMaterial,
                                                model: "IdGrado",
                                                labelAttr: { text: UIUtilLang._GetUIString("crgamasiva", "tag_grado") },
                                                selectMaterialAttr: {
                                                    valueMember: "IdNivel",
                                                    displayMember: "Nombre"
                                                },
                                                values: []
                                            }
                                        ],
                                        Validation: (val: number) => Boolean(val)
                                    }, <TGeneralData>{});

                                return formRegistrar;
                            },
                            OnClose: () => {
                                resolve(false);
                            },
                            OnAccept: (form, mt) => {
                                resolve(true);
                                mt.Modal._Ocultar();
                                return null;
                            }
                        })
                    })
                }
            }
        })
    }

    protected GRID_GetExportarConfig(dataGrid: IGrupo[]): IConfigGridExcelExport<any> {
        return {
            IdsEscuelas: [...new Set(dataGrid.map(d => d.IdKinder))],
            OnGetDataBySheets: async () => {
                return Array.from(d3Group(dataGrid, d => d.IdKinder))
                    .map<ExcelThings.ISheetConfig<IGrupo>>((entrie) => ({
                        IdSheet: entrie[0], // IdEscuela
                        SheetName: DataModuloEscuela._DiccEscuela.get(entrie[0]).Nombre,
                        Data: entrie[1],
                    }))
            },
            OnGetEscuelasTagInSheet: (datos) => datos[0].NombreKinder,
        }
    }

    // **********************************************************************************************
    // PANEL AND THINGS
    // **********************************************************************************************

    private OpenPanelModeVerDetalle(grupoData: IGrupo) {
        UIWindowManager._DoHash("escuelas/grupos/panel", {
            mode: "All",
            id: [grupoData.IdGrupo]
        })
    }

    private OpenPanelModeHorarioOperacion(idGrupo: number, idEscuela: number, typeRender: "real" | "predefined") {
        // FIXME Proximamente:
        //  * Quitar condición
        //  * Solo mostrar notificación de "No permiso..."
        //  * Validar Card para que no se active el Modo Editar
        if (this.GridHasPermisoAccion(Entidad.CAccionPermiso.Editar, idEscuela)) {
            // && !this.GridHasPermisoAccion(data.Entidades.CAccionPermiso.EditarHorario, datos.IdKinder)
            UIWindowManager._DoHash("escuelas/grupos/panel", {
                mode: "pgrupohorariooperacion",
                id: [idGrupo],
            })
        }
    }
}
