import { ArrayV2 } from "../../util/ArrayV2";
import { DataDRequest } from "../DRequest";
import { Entidad } from "../Entidad";
import { Global } from "../Global";
import { ICatalogoSATClaveProductServ } from "../entidad/Factura";
import { DataUtil } from "../util/Util";
import { _ObtenerCatalogoSATClaveProductServ } from "./FacturaCatalogoSAT";

type TFinanzaCargo = Entidad.IFinanzaCargo;
type IEscuela = Entidad.IEscuela;
interface IExtraData {
    IdUsuario: number;
    IdOrganizacion?: number;
}

export const _DiccFinanzasCargos: Map<number, TFinanzaCargo> = new Map();

const REQUESTID = Entidad.CTipoRequest.FinanzaCargo;

export function _GetListFinanzaCargo(escuelas: IEscuela[]): Promise<DataDRequest.IRequestResponseListA<TFinanzaCargo>[]> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "finanzas/ObtenerListaCargos"
    return new ArrayV2<IEscuela>()
        ._Push(...escuelas)
        ._MapAwait(async ({ IdKinder }) => {
            const currentDateStr = await DataUtil._GetMaxDate(REQUESTID, IdKinder);
            const params = {
                "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
                "IdEscuela": IdKinder,
                "Modificacion": currentDateStr
            }
            return DataDRequest._Request4List(url, "GET", params, REQUESTID, IdKinder);
        })
}

export async function _SvFinanzasNuevoCargo(datos: TFinanzaCargo) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "finanzas/NuevoCargoV3";
    datos.TipoInteres = _SvFinanzasUtilEvaluarTipoInteres(datos)
    datos.IntervaloBloque = _SvFinanzasUtilEvaluarIntervaloBloque(datos)
    datos.TipoTasa = _SvFinanzasUtilEvaluarTipoTasa(datos)
    const params = <Partial<TFinanzaCargo> & IExtraData>{
        IdOrganizacion: DataUtil._Usuario.IdOrganizacion,
        IdUsuario: DataUtil._Usuario.IdUsuario,
        Nombre: datos.Nombre,
        TipoValor: datos.TipoValor,
        Valor: datos.Valor,
        Periodicidad: datos.Periodicidad,
        MesAplicacion: datos.MesAplicacion,
        DiaAplicacion: datos.DiaAplicacion,
        DiaMorosidad: datos.DiaMorosidad,
        DiaVencimiento: datos.DiaVencimiento,
        TipoRecargo: datos.TipoRecargo,
        TipoInteres: datos.TipoInteres,
        ValorRecargo: datos.ValorRecargo,
        IdEscuela: datos.IdEscuela,
        TipoTasa: datos.TipoTasa,
        IntervaloBloque: datos.IntervaloBloque,
        ClaveProdServSAT: datos.ClaveProdServSAT,
        NombreProdServSAT: datos.NombreProdServSAT,
    }

    const res = await DataDRequest._Request5<TFinanzaCargo>(url, "POST", params);
    res.Datos = datos;
    return res;
}

export async function _InsertDescuento(datos: TFinanzaCargo) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "finanzas/NuevoCargoDescuento";
    const params = <Partial<TFinanzaCargo> & IExtraData>{
        IdOrganizacion: DataUtil._Usuario.IdOrganizacion,
        IdUsuario: DataUtil._Usuario.IdUsuario,
        Nombre: datos.Nombre,
        TipoValor: datos.TipoValor,
        Valor: datos.Valor,
        IdEscuela: datos.IdEscuela
    }

    const res = await DataDRequest._Request5<TFinanzaCargo>(url, "POST", params);
    res.Datos = datos;
    return res;
}

export async function _SvFinanzasActualizarCargo(dato: TFinanzaCargo) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "finanzas/ActualizarCargoV3";
    dato.TipoInteres = _SvFinanzasUtilEvaluarTipoInteres(dato)
    dato.IntervaloBloque = _SvFinanzasUtilEvaluarIntervaloBloque(dato)
    dato.TipoTasa = _SvFinanzasUtilEvaluarTipoTasa(dato)
    const params = <Partial<TFinanzaCargo> & IExtraData>{
        IdUsuario: DataUtil._Usuario.IdUsuario,
        IdCargo: dato.ID,
        Nombre: dato.Nombre,
        Valor: dato.Valor,
        Periodicidad: dato.Periodicidad,
        MesAplicacion: dato.MesAplicacion,
        DiaAplicacion: dato.DiaAplicacion,
        DiaMorosidad: dato.DiaMorosidad,
        DiaVencimiento: dato.DiaVencimiento,
        TipoRecargo: dato.TipoRecargo,
        TipoInteres: dato.TipoInteres,
        ValorRecargo: dato.ValorRecargo,
        TipoTasa: dato.TipoTasa,
        IntervaloBloque: dato.IntervaloBloque,
        ClaveProdServSAT: dato.ClaveProdServSAT,
        NombreProdServSAT: dato.NombreProdServSAT,
    }
    return DataDRequest._Request5(url, "POST", params);
}

export async function _UpdateDescuento(dato: TFinanzaCargo) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "finanzas/ActualizarCargoDescuento";
    const params = <Partial<TFinanzaCargo> & IExtraData>{
        IdUsuario: DataUtil._Usuario.IdUsuario,
        IdCargo: dato.ID,
        Nombre: dato.Nombre,
        TipoValor: dato.TipoValor,
        Valor: dato.Valor
    }
    return DataDRequest._Request5(url, "POST", params);
}

export async function _Delete(ids: Array<number>) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "finanzas/EliminarCargo";
    const params = {
        Cargos: ids,
        IdUsuario: DataUtil._Usuario.IdUsuario
    }
    return DataDRequest._Request5<number[]>(url, "POST", params);
}

/** Si la consulta falla retorna la config del metodo de pago */
export function _ObtenerClaveProdServSATByCargo(idEscuela: number, idCargo: number): Promise<DataDRequest.IRequestResponseA<ICatalogoSATClaveProductServ[]>> {
    const cargo = _DiccFinanzasCargos.get(idCargo)
    let catalogoItemAux: ICatalogoSATClaveProductServ
    if (cargo)
        catalogoItemAux = { key: cargo.ClaveProdServSAT, name: cargo.NombreProdServSAT, complement: "" }
    return _ObtenerClaveProdServSATBase(idEscuela, catalogoItemAux)
}

export function _ObtenerClaveProdServSATBase(idEscuela: number, catalogoItemDefault: ICatalogoSATClaveProductServ): Promise<DataDRequest.IRequestResponseA<ICatalogoSATClaveProductServ[]>> {
    const catchList: ICatalogoSATClaveProductServ[] = []
    if (catalogoItemDefault)
        catchList.push(catalogoItemDefault)
    return new Promise(resolve => {
        if (!idEscuela) {
            resolve({ Resultado: 0, Datos: catchList })
            return
        }
        _ObtenerCatalogoSATClaveProductServ(idEscuela)
            .then(res => {
                if (res.Resultado > 0)
                    resolve(res)
                else
                    resolve({ ...res, Datos: catchList })
            })
            .catch(() => resolve({ Resultado: 0, Datos: catchList }))
    })
}

export function _SvFinanzasUtilEvaluarTipoInteres(cargo: TFinanzaCargo): Entidad.CFinanzaCargoTipoInteres {
    const defaultResult = Entidad.CFinanzaCargoTipoInteres.MontoUnico
    switch (cargo.TipoRecargo) {
        case Entidad.CFinanzaCargoTipoValor.Monto:
            return (cargo.IntervaloBloque == Entidad.CFinanzasCargoRecurrenciaRecargo.Diaria)
                ? Entidad.CFinanzaCargoTipoInteres.Simple
                : defaultResult
            break
        case Entidad.CFinanzaCargoTipoValor.Porcentaje:
            return cargo.TipoInteres || defaultResult
            break
    }
    return defaultResult
}

export function _SvFinanzasUtilEvaluarIntervaloBloque(cargo: TFinanzaCargo) {
    const defaultResult = Entidad.CFinanzasCargoRecurrenciaRecargo.Diaria
    return cargo.IntervaloBloque < 0
        ? defaultResult
        : cargo.IntervaloBloque || defaultResult
}

export function _SvFinanzasUtilEvaluarTipoTasa(cargo: TFinanzaCargo): Entidad.CFinanzasCargoTipoTasa {
    if (cargo.IntervaloBloque == Entidad.CFinanzasCargoRecurrenciaRecargo.Mensual) {
        return Entidad.CFinanzasCargoTipoTasa.Mensual
    }
    return Math.abs(cargo.TipoTasa) || Entidad.CFinanzasCargoTipoTasa.NoAplica
}

export default {
    _DiccFinanzasCargos,
    _InsertDescuento,
    _UpdateDescuento,
    _Delete
}
