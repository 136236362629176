import * as d3 from "d3";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import { _LOCALDATA_GetGruposHorariosDeAlumno, _SvAlumnoGetBoletasGeneradas, _SvAlumnoURLObtenerExpedienteArchivo } from "../../data/modulo/Alumno";
import { DateV2 } from "../../util/DateV2";
import _L from "../../util/Labels";
import { IConfigGridExcelExport, IGridExtraTableConfig, IGridRenderInfo, VentanaGrid } from "../controlD3/AVentanaGrid";
import { ElementWrapper } from "../controlD3/ElementWrapper";
import { FullViewFile } from "../controlD3/FullViewFile";
import { SelectV2 } from "../controlD3/SelectV2";
import { Table } from "../controlD3/Tabla";
import { HTMLFileIcoElement } from "../controlWC/FileIcoComponent";
import { HTMLTooltipComponent } from "../controlWC/TooltipComponent";
import { UIUtilTime } from "../util/Time";
import { UIUtilGeneral } from "../util/Util";
import { UIUtilViewData } from "../util/ViewData";
import { UIUtilViewGrupos } from "../utilView/Grupos";

import IAlumno = Entidad.IAlumno;
import IBoletaGenerada = Entidad.IBoletaGeneradaInfo;
import ICicloE = Entidad.ICicloEscolar;
import IPeriodoD = UIUtilViewData.IPeriodoD;

type IAlumnoPick = Pick<IAlumno, "IdChild" | "NombreCompleto" | "Nombre" | "ApPaterno" | "ApMaterno" | "KinderFiltro" | "Matricula" | "Sexo" | "StrSexo" | "StrGrupoPrincipal" | "StrGrado" | "StrEscolaridad" | "NombreKinder" | "IdKinder">;
type IBoletaPick = {
    [k in keyof IBoletaGenerada as `Boleta${k}`]: IBoletaGenerada[k];
}
interface IDataGrid extends IAlumnoPick, IBoletaPick {
    BoletaRegistroFmt: string;
    BoletaUrlArchivo: string;
}

export class UIVentanaBoletasGeneradas extends VentanaGrid<IDataGrid> {
    private comboCicloE: SelectV2<ICicloE, "Id", "monoselect">;
    private comboPeriodo: SelectV2<IPeriodoD, "Id", "multiselect">;

    constructor(container: TSelectionHTML<"div">, modulo: Entidad.CModulo) {
        super(container, modulo, {
            LabelsKeyBase: "boletasgeneradas",
            ModuloObservableToTblRefresh: [Entidad.CTipoRequest.HorarioAlumno],
        });
        this.GridTreeEscuelasMultiselect(false);
    }

    protected GRID_GetDataRequestID(): DataModuloMain.TipoRequestMonitorId {
        return null;
    }

    protected GRID_GetMenuTopGrid(): Table.ITableMenuTopDefaultOptionConfig[] {
        return null;
    }

    protected GRID_GetSelectionDataMenuV2(menuLocation: "row" | "top-selected", dataGridSelected: IDataGrid[]): Table.ITableMenuDataSelectedOptionConfig<IDataGrid>[] {
        return null;
    }

    protected GRID_GetFilters(): Table.IParametroFiltro<IDataGrid>[] {
        return [
            { LangModuleKeyInContext: "alumnos", LabelLangKey: "d_field_nombre", Field: "Nombre" },
            { LangModuleKeyInContext: "alumnos", LabelLangKey: "d_field_appaterno", Field: "ApPaterno" },
            { LangModuleKeyInContext: "alumnos", LabelLangKey: "d_field_apmaterno", Field: "ApMaterno" },
            { Label: "Fecha", Field: "BoletaRegistro", Type: "date" },
            {
                Label: "Grupo principal",
                Field: "StrGrupoPrincipal",
                OnGetValueToMatch: (datoAlumno: IDataGrid) => Array.from(_LOCALDATA_GetGruposHorariosDeAlumno(datoAlumno.IdChild).values())
                    .filter(d => d.EsPrincipal)
                    .map(d => d.Nombre),
            },
            { Label: "Grado", Field: "StrGrado" },
            { Label: "Escolaridad", Field: "StrEscolaridad" },
        ];
    }

    protected GRID_GetTableConfigBase(): IGridRenderInfo<IDataGrid> {
        return {
            IdTabla: "MateriasBoletasGeneradas",
            Title: "",
            DefaultSort: {
                Field: "BoletaRegistroFmt",
                Type: Table.CStatusOrder.Desc,
            },
            IdData: "BoletaId",
            MaxOptionsInRow: 2,
            MinWidth: 900,
            Columns: [
                { Field: "BoletaUrlArchivo", Label: "", Width: "1%", MinWidth: "35px", IsSortable: false, Align: "center", Icon: { Type: "file", Text: "FILE" } },
                { Field: "NombreCompleto", Label: "Nombre", Width: "18%", MinWidth: "140px", },
                { Field: "BoletaRegistroFmt", Label: "Fecha", Width: "8%", MinWidth: "100px", OrderField: "BoletaRegistro" },
                { Field: "StrGrupoPrincipal", Label: "Grupo principal", Width: "14%", MinWidth: "80px", },
                { Field: "StrGrado", Label: "Grado", Width: "10%", MinWidth: "80px", },
                { Field: "StrEscolaridad", Label: "Escolaridad", Width: "10%", MinWidth: "100px", },
                { Field: "NombreKinder", Label: "Escuela", Width: "12%", MinWidth: "100px", },
            ]
        };
    }

    protected GRID_GetTableConfigAdvanced(): IGridExtraTableConfig<IDataGrid> {
        return {
            EvaluatorAndSubLevelsBuild: <Table.IStepEvaluator<IDataGrid, any, any>>{
                OnStepCellTable: (container, d, field: keyof IDataGrid) => {
                    switch (field) {
                        case "BoletaUrlArchivo":
                            if (!container.select("wc-fileico").node()) {
                                container.text("");
                                container.append<HTMLFileIcoElement>("wc-fileico")
                                    .style("margin-left", "3px")
                                    .style("width", "24px")
                                    .style("height", "32px")
                                    .style("cursor", "pointer")
                                    .property("_LblSize", "7px");
                                container.append<HTMLTooltipComponent>("wc-tooltip");
                            }
                            container.select<HTMLTooltipComponent>("wc-tooltip")
                                .text(d.BoletaNombre + d.BoletaExtension);

                            container.select<HTMLFileIcoElement>("wc-fileico")
                                .attr("ext", d.BoletaExtension.toUpperCase().replace(".", ""))
                                .node()
                                .onclick = e => {
                                    new FullViewFile()._SetContent([{
                                        Id: d.BoletaIdArchivo,
                                        Filename: () => d.BoletaNombre,
                                        Download: () => d.BoletaUrlArchivo,
                                        Content: () => d.BoletaUrlArchivo,
                                    }])
                                }
                            break;
                        case "NombreCompleto":
                            UIUtilGeneral._ElementAdd_LinkToGoToPanel(container, "alumnos/alumnos/panel", d.BoletaIdAlumno);
                            break;
                        case "StrGrupoPrincipal":
                            UIUtilViewGrupos._ApplyLblsGruposToContainer(container, d.IdChild);
                            break;
                    }
                },
            }
        };
    }

    protected GRID_GetExportarConfig(dataGrid: IDataGrid[]): IConfigGridExcelExport<IDataGrid> {
        return null
    }

    private UI_AjustaDrawGrid() {
        // Area combos-filtros
        let filterCont = this.ctrlTabla._Control.select(".filter_container");
        let searchwrapper = filterCont.select(".search-wrapper");

        let areaCombos = filterCont.append("div")
            .attr("class", "search-wrapper " + UIUtilGeneral.FBoxOrientation.Horizontal)
            // .style("height", "30px")
            .style("column-gap", "40px")
            .style("padding", "0 20px")
            .style("box-sizing", "border-box")

        searchwrapper.raise();

        this.comboCicloE = (() => {
            const control = new SelectV2<ICicloE, "Id", "monoselect">({
                Type: "monoselect",
                Parent: areaCombos,
                ValueMember: "Id",
                DisplayMember: "Nombre",
                Data: () => this.GetCiclosEscolaresDisponibles(),
                OnSelect: (item) => {
                    if (item) {
                        let mesesDisponibles = this.GetPeriodosDisponibles(item);
                        this.comboPeriodo._UpdateList(mesesDisponibles);
                        let selectedM = this.comboPeriodo._dataSelected[0];
                        if (!selectedM) {
                            this.comboPeriodo._valueSelect(mesesDisponibles[0].Id);
                        }
                    } else {
                        this.comboPeriodo._UpdateList([]);
                    }
                    this.GridUpdateData();
                }
            })
            areaCombos.append(() => ElementWrapper._WrapperToSelectControl(control, _L("boletasgeneradas.cicloesc") + ":", { Borders: ["left", "right"] })
                .style("height", "35px")
                .node())
            return control
        })()

        this.comboPeriodo = (() => {
            const control = new SelectV2<IPeriodoD, "Id", "multiselect">({
                Type: "multiselect",
                Parent: areaCombos,
                ValueMember: "Id",
                DisplayMember: "Name",
                OnChange: (idMonth, item) => {
                    this.GridUpdateData();
                }
            })
            areaCombos.append(() => ElementWrapper._WrapperToSelectControl(control, _L("boletasgeneradas.periodo") + ":", { Borders: ["left", "right"] }).node())
            return control
        })()
    }

    // **********************************************
    // Overwrite methods
    // **********************************************

    protected GridInitTable() {
        super.GridInitTable();
        this.UI_AjustaDrawGrid();
    }

    protected GridRefreshKinderList() {
        super.GridRefreshKinderList();

        this.comboCicloE._RefreshList()
        if (!this.comboCicloE._dataSelected.length) {
            this.comboCicloE._valueSelect(this.comboCicloE._dataitems[0]?.Id);
        }
    }

    protected async GridOnKinderSelectionChange() {
        this.comboCicloE._RefreshList()
        if (!this.comboCicloE._dataSelected.length) {
            this.comboCicloE._valueSelect(this.comboCicloE._dataitems[0]?.Id);
        }
    }

    protected async GridGetData(): Promise<IDataGrid[]> {
        const [escuela] = this.GridGetEscuelasSeleccionadas();
        const idCicloEscFilter = this.comboCicloE._dataSelected[0]?.Id;
        const periodosFilter = this.comboPeriodo._dataSelected;

        // >> Consulta data
        if (!escuela || !periodosFilter.length) return [];

        this.ctrlProgressBar.attr("oculto", false);

        // >> Consulta eventos de las escuelas seleccionadas y agrupa por Escuela y Alumno
        const res = await _SvAlumnoGetBoletasGeneradas(escuela.IdKinder);

        if (res.Resultado <= 0) {
            this.notificacion._Mostrar(_L("general.notif_fail_infoupdate"), "ADVERTENCIA");
        }
        const periodosFix = periodosFilter.map(d => new Date(d.Year, d.Month, 1).getTime());

        const ddd = res.Datos
            .map<IDataGrid>(d => {
                const alumno = DataModuloMain._GetItemDataByName("Alumno", d.IdAlumno);
                if (!alumno)
                    return null;
                const item = UIUtilGeneral._ObjectClone(alumno) as unknown as IDataGrid;
                for (const k in d) {
                    item["Boleta" + k] = d[k];
                }
                item.BoletaUrlArchivo = _SvAlumnoURLObtenerExpedienteArchivo(item.BoletaIdArchivo);
                item.BoletaRegistroFmt = UIUtilTime._DateFormatStandar(item.BoletaRegistro);
                return item;
            })
            .filter(d => {
                if (d.BoletaIdCicloEscolar != idCicloEscFilter || !d) return false;
                const dReg = UIUtilTime._TruncDate(new DateV2(d.BoletaRegistro)._SetTimeZone(escuela.ZonaHoraria), "Day").getTime();
                return periodosFix.includes(dReg);
            })
        return ddd;
    }

    // **********************************************
    // Data things
    // **********************************************

    private GetCiclosEscolaresDisponibles() {
        const [escuela] = this.GridGetEscuelasSeleccionadas();
        if (!escuela) return [];

        return DataModuloMain._GetReqDataArrayByName("CicloEscolar")
            .filter(d => d.IdEscuela == escuela.IdKinder)
            .sort((a, b) => d3.descending(a.FechaInicio, b.FechaInicio))
    }

    private GetPeriodosDisponibles(cicloE: ICicloE) {
        return UIUtilViewData._GetPeriodosFromCicloEscolar(cicloE)
        // .sort((a, b) => d3.descending(a.Id, b.Id));
    }

    public _Mostrar(): void {
        super._Mostrar();
    }

    public _Destroy(): void {
        super._Destroy();
    }
}
