import { HTMLSpinnerElement } from "../controlWC/SpinnerComponent";
import { HTMLTooltipComponent } from "../controlWC/TooltipComponent";
import { UIUtilIconResources } from "../util/IconResourses";
import { UIUtilLang } from "../util/Language";
import { CopyElementTextControl } from "./CopyElementTextControl";
import { NotificacionV2 } from "./NotificacionV2";

export function _TextoCensuraDiv(container: TSelectionHTML<"div">, obtenerString: () => Promise<string>, maxWidth: string, restriction: string = null) {
    let textVisible = container.node()["_textVisible"] as boolean || false;
    let timeOutVisibility: NodeJS.Timeout;
    let downloading = false;
    let label = container.select<HTMLLabelElement>("label");
    let imgContainer = container.select<HTMLDivElement>("div");
    let img = imgContainer.select<HTMLImageElement>("img");
    let tooltipPsswd = imgContainer.select<HTMLTooltipComponent>("wc-tooltip")
    let spinner = imgContainer.select<HTMLSpinnerElement>("wc-spinner");

    if (!label.node()) {
        container.attr("style", `width: 100%; max-width: ${maxWidth}; display: flex; justify-content: space-between; align-items: center; gap: 5px`)
        label = container.append("label").style("overflow", "hidden").style("overflow-wrap", "break-word");
        label.text("●●●●")
        imgContainer = container.append("div").style("cursor", (!restriction) ? 'pointer' : 'auto').style("position", "relative").style("display", "flex");
        img = imgContainer.append("img")
            .attr("src", UIUtilIconResources.CGeneral.EyeHidden)
            .attr("draggable", false)
            .style("width", "20px")
            .style("opacity", (!restriction) ? '1' : '0.4');
        tooltipPsswd = imgContainer.append<HTMLTooltipComponent>('wc-tooltip')
            .text(restriction);

        spinner = imgContainer.append<HTMLSpinnerElement>("wc-spinner")
            .attr("dim", 20)
            .attr("border-width", 3)
            .attr("center", "")
            .classed("hide", true);
    }
    else {
        if (restriction) {
            label.text("●●●●");
            textVisible = false;
            container.node()["_textVisible"] = textVisible;
            CopyElementTextControl._RemoveCopyTextBehaviour(container.node());
        }
        if (!textVisible) CopyElementTextControl._RemoveCopyTextBehaviour(container.node());
        imgContainer.style("cursor", (!restriction) ? 'pointer' : 'auto');
        img.style("opacity", (!restriction) ? '1' : '0.4');
        img.attr("src", `${(textVisible && !restriction) ? UIUtilIconResources.CGeneral.Eye : UIUtilIconResources.CGeneral.EyeHidden}`);
        tooltipPsswd.text(restriction);
    }

    if (!restriction) {
        imgContainer.node()
            .onclick = async e => {
                e.stopPropagation();
                if (!downloading) {
                    if (!textVisible) {
                        downloading = true;
                        spinner.classed("hide", false);
                        clearTimeout(timeOutVisibility);
                        await obtenerString()
                            .then(strPsswd => {

                                downloading = false;
                                spinner.classed("hide", true);

                                if (strPsswd == null) {
                                    NotificacionV2._Mostrar(UIUtilLang._GetUIString("general", "notif_fail_getpass"), "ADVERTENCIA");
                                }
                                else {
                                    label.text(strPsswd || "●●●●");
                                    setVisibility(true);
                                    CopyElementTextControl._AddCopyTextBehaviour(container.node(), () => strPsswd);

                                    timeOutVisibility = setTimeout(() => {
                                        CopyElementTextControl._RemoveCopyTextBehaviour(container.node());
                                        setVisibility(false);
                                        label.text("●●●●");
                                    }, 30000); // NOTE 30 segundos para ver la contraseña
                                }
                            })
                    }
                    else {
                        clearTimeout(timeOutVisibility);
                        setVisibility(false);
                        label.text("●●●●");
                        CopyElementTextControl._RemoveCopyTextBehaviour(container.node());
                    }
                }
            }
    }
    else {
        imgContainer.node().onclick = null;
    }

    const setVisibility = (value: boolean) => {
        textVisible = value;
        container.node()["_textVisible"] = textVisible;
        img.attr("src", `${(textVisible) ? UIUtilIconResources.CGeneral.Eye : UIUtilIconResources.CGeneral.EyeHidden}`);
    }
}
