import { Entidad } from "../../data/Entidad";
import { _LOCALDATA_GetGruposHorariosDeAlumno } from "../../data/modulo/Alumno";
import DataModuloEscolaridad from "../../data/modulo/Escolaridad";
import DataModuloEscuela from "../../data/modulo/Escuela";
import DataModuloGrado from "../../data/modulo/Grado";
import DataModuloGrupo from "../../data/modulo/Grupo";
import { Fields, FormGenerator } from "../controlD3/Formulario";
import { NotificacionV2 } from "../controlD3/NotificacionV2";
import { SelectV2 } from "../controlD3/SelectV2";
import { UIUtilLang } from "../util/Language";
import { UIUtilPermission } from "../util/Permission";
import { UIUtilGeneral } from "../util/Util";

export namespace UIUtilViewGrupos {
    import CAccionPermiso = Entidad.CAccionPermiso;
    import IGrupo = Entidad.IGrupo;

    // *********************************************************************
    // FORM CONFIG
    // *********************************************************************

    export function _UpdateFormFromPermission(form: FormGenerator<IGrupo>, accion: CAccionPermiso.Agregar | CAccionPermiso.Editar, grupoDato: IGrupo) {
        let escuelasSelectCtrl = form._ControlsData.get("IdKinder")
            .instance as SelectV2<Entidad.IEscuela, "IdKinder">;

        let escuelasConPermiso = escuelasSelectCtrl._dataitems;
        if (accion == CAccionPermiso.Editar && !escuelasConPermiso.find(d => (d.IdKinder == grupoDato.IdKinder)) && DataModuloEscuela._DiccEscuela.has(grupoDato.IdKinder)) {
            escuelasConPermiso.push(DataModuloEscuela._DiccEscuela.get(grupoDato.IdKinder));
        }

        escuelasSelectCtrl._UpdateList(escuelasConPermiso);
        form._AsignaData(grupoDato);
    }

    export function _GetForm(accion: CAccionPermiso.Agregar | CAccionPermiso.Editar, grupoDato: IGrupo) {
        let form = new FormGenerator<IGrupo>();
        let dataToForm = Object.assign({}, grupoDato);
        let escuelasConPermiso = UIUtilPermission._GetSchoolsByActionModule(Entidad.CModulo.Grupos, accion);

        form._Crear({
            LangModuleKeyInContext: "grupos",
            LabelMaxWidth: 145,
            schema: [
                {
                    model: "Nombre", type: Fields.input,
                    labelAttr: { text: "frm1_nombre" },
                    inputAttr: { type: "text", required: true },
                },
                {
                    model: "IdKinder", type: Fields.selectMaterial,
                    labelAttr: { text: "frm1_escuela" },
                    selectMaterialAttr: {
                        disabled: (accion != CAccionPermiso.Agregar),
                        removeBorder: (accion == CAccionPermiso.Editar),
                        displayMember: "Nombre",
                        valueMember: "IdKinder",
                        required: true,
                        onSelect: (escuelaD: Entidad.IEscuela) => {
                            let escolaridadesPorEscuela: Entidad.IEscolaridad[] = []
                            let escolaridadCtrl = form._ControlsData.get("IdEscolaridad").instance as SelectV2;

                            if (escuelaD) {

                                escolaridadesPorEscuela = Array.from(DataModuloEscolaridad._DiccEscolaridad.values())
                                    .filter(d => (d.IdEscuela == escuelaD.IdKinder))
                            }

                            escolaridadCtrl._UpdateList(escolaridadesPorEscuela);
                        }
                    },
                    values: escuelasConPermiso
                },
                {
                    model: "IdEscolaridad", type: Fields.selectMaterial,
                    labelAttr: { text: "frm1_nivel" },
                    selectMaterialAttr: {
                        // disabled: (accion != CAccionPermiso.Agregar),
                        // removeBorder: (accion == CAccionPermiso.Editar),
                        displayMember: "Nombre",
                        valueMember: "Id",
                        required: true,
                        onSelect: (escolaridadD: Entidad.IEscolaridad) => {
                            let gradosPorEscolaridad: Entidad.IGrado[] = [];
                            let gradosCtrl = form._ControlsData.get("IdNivel").instance as SelectV2;

                            if (escolaridadD) {

                                gradosPorEscolaridad = Array.from(DataModuloGrado._DiccGrado.values())
                                    .filter(d => (d.IdEscolaridad == escolaridadD.Id));
                            }

                            gradosCtrl._UpdateList(gradosPorEscolaridad);
                        }
                    },
                    values: Array.from(DataModuloEscolaridad._DiccEscolaridad.values())
                },
                {
                    // Grado
                    model: "IdNivel", type: Fields.selectMaterial,
                    labelAttr: { text: "frm1_grado" },
                    selectMaterialAttr: {
                        displayMember: "Nombre",
                        valueMember: "IdNivel",
                        required: true,
                    },
                    values: Array.from(DataModuloGrado._DiccGrado.values())
                },
                {
                    model: "EsPrincipal",
                    type: Fields.input,
                    labelAttr: { text: "frm1_esprincipal" },
                    inputAttr: { type: "checkbox", class: UIUtilGeneral.FBoxAlign.StartStart }
                }
            ],
            Validation: (value, field, datoForm, controls) => {
                if (field == "IdNivel" && (value != grupoDato.IdNivel) && DataModuloGrupo._LOCALDATA_GetAlumnosEnGrupo(grupoDato.IdGrupo).size > 0) {
                    NotificacionV2._Mostrar(UIUtilLang._GetUIString("grupos", "notif_nivelgradonoedit"), "ADVERTENCIA");
                    return false;
                }
                return true;
            }
        }, dataToForm);

        // // Fix: Si no tiene permisos para ver la contraseña, ocultar
        if (accion == CAccionPermiso.Editar) {
            _UpdateFormFromPermission(form, accion, dataToForm);
        }

        return form;
    }

    // *********************************************************************
    // TAGS
    // *********************************************************************

    /**
    * * Por defecto solo se agrega el prefijo al nombre cuando el Grupo es Principal
    * @param grupo
    * @param spaceBetweenStarAndName Incluir o no un espacio entre el prefijo y el nombre de grupo @default true
    * @param forceStar Activar prefijo cuando el Grupo no es Principal @default false
    * @returns ("★" | "☆") + "GRUPO_NOMBRE"
    */
    export function _GetLblGrupoName<TGrupo extends { Nombre: string; EsPrincipal: boolean; }>(grupo: TGrupo, spaceBetweenStarAndName = true, forceStar = false) {
        if (!grupo?.Nombre) return UIUtilLang._GetUIString("general", "nodisponible");
        let str = grupo.Nombre;
        if (grupo.EsPrincipal) {
            str = "★" + (spaceBetweenStarAndName ? " " : "") + str;
        } else if (forceStar) {
            str = "☆" + (spaceBetweenStarAndName ? " " : "") + str;
        }
        return str;
    }

    /**
     * @param alumno
     * @param type (default ```"EsPrincipal"```)
     */
    export function _GetLblsGrupos(alumno: Entidad.IAlumno, type: "EsPrincipal" | "NoEsPrincipal" | "All" = "EsPrincipal", spaceBetweenStarAndName?: boolean, forceStar?: boolean) {
        return Array.from(_LOCALDATA_GetGruposHorariosDeAlumno(alumno.IdChild).values())
            .filter(d => (type == "All" || (type == "EsPrincipal" && d.EsPrincipal) || (type == "NoEsPrincipal" && !d.EsPrincipal)))
            .map(d => _GetLblGrupoName(d, spaceBetweenStarAndName, forceStar));
    }

    /**
     * @param type @default "EsPrincipal"
     */
    export function _ApplyLblsGruposToContainer(container: TSelectionHTML<"div">, idAlumno: number, type: "EsPrincipal" | "NoEsPrincipal" | "All" = "EsPrincipal", spaceBetweenStarAndName?: boolean, forceStar?: boolean) {
        let asignacionesHG = Array.from(_LOCALDATA_GetGruposHorariosDeAlumno(idAlumno).values())
            .filter(d => (type == "All" || (type == "EsPrincipal" && d.EsPrincipal) || (type == "NoEsPrincipal" && !d.EsPrincipal)))

        UIUtilGeneral._CreaElementosLinkeablesV2({
            Container: container,
            Data: asignacionesHG,
            Path: "escuelas/grupos/panel",
            GetTag: (d) => UIUtilViewGrupos._GetLblGrupoName(d, spaceBetweenStarAndName, forceStar),
            GetId: d => d.IdGrupo
        });
    }

    // *********************************************************************
    // PROCESOS
    // *********************************************************************

    // export function _OpenModal_AsignarCalendarioGoogle(grupos: IGrupo[]) {
    //     let message = "";
    //     if (grupos.length > 1) {
    //         message = UIUtilLang._GetUIString(Entidad.CModulo[Entidad.CModulo.Grupos], "confirma_assigngooglegrupos")
    //             .replace("_GRUPOS", grupos.map(d => (`<b>${d.Nombre}</b>`)).join(", "));
    //         // `¿Deseas asignar un calendario Google a los grupos ${grupos.map(d => (`<b>${d.Nombre}</b>`)).join(", ")} con la cuenta vinculada a la escuela correspondiente?`;
    //     } else {
    //         // message = `¿Deseas asignar un calendario Google al grupo <b>${grupos[0].Nombre}</b> con la cuenta vinculada a la escuela correspondiente?`;
    //         message = UIUtilLang._GetUIString(Entidad.CModulo[Entidad.CModulo.Grupos], "confirma_assigngooglegrupo")
    //             .replace("_GRUPO", grupos[0].Nombre);
    //     }
    //     ModalThings._GetConfirmacionModal({
    //         Title: UIUtilLang._GetUIString(Entidad.CModulo[Entidad.CModulo.Grupos], "tag_assigngrupogoogle_title"),
    //         Message: message,
    //         OnAccept: async () => {
    //             let finalRes: string[] = [];
    //             for (let grupo of grupos) {
    //                 let res = await _SvAsignarCalendarioGoogle(grupo);
    //                 if (res.Resultado == -2) {
    //                     break;
    //                 }
    //                 if (res.Resultado > 0) {
    //                     finalRes.push(res.Mensaje);
    //                 } else {
    //                     finalRes.push(grupo.Nombre + ": " + res.Mensaje);
    //                 }
    //             }
    //             if (finalRes.length > 1) {
    //                 ModalThings._GetConfirmacionModal({
    //                     Title: UIUtilLang._GetUIString("general", "resumen"),
    //                     DrawContent: (container) => {
    //                         container
    //                             .classed(UIUtilGeneral.FBoxOrientation.Vertical, true)
    //                             .selectAll("label")
    //                             .data(finalRes)
    //                             .join(
    //                                 enter => enter.append("label")
    //                                     .style("padding-bottom", "10px")
    //                                     .text(strRes => strRes)
    //                             )
    //                     }
    //                 })
    //             }
    //         }
    //     })
    // }

    // *********************************************************************
    // SERVICES THINGS
    // *********************************************************************

    /* export async function _SvAsignarCalendarioGoogle(grupo: IGrupo) {
        let res = await DataModuloGrupo._AsignarCalendarioGoogle(grupo.IdGrupo);

        let mensaje = UIUtilLang._GetHTTPMessage(res, "asignargooglecalendar");

        if (res.Resultado > 0) {
            mensaje = mensaje.replace("_GRUPONAME", grupo.Nombre);
        } else if (res.Resultado == -2) {
            mensaje = mensaje.replace("_ESCUELA", DataModuloEscuela._DiccFullEscuelas.get(grupo.IdKinder).Nombre);
        }
        NotificacionV2._Mostrar(mensaje, (res.Resultado > 0) ? "INFO" : "ADVERTENCIA");

        res.Mensaje = mensaje;
        return res;
    } */
}
