import * as d3 from "d3";
import { Entidad } from "../../data/Entidad";
import DataModuloEscolaridad from "../../data/modulo/Escolaridad";
import DataModuloEscuela from "../../data/modulo/Escuela";
import DataModuloGrado from "../../data/modulo/Grado";
import DataModuloGrupo from "../../data/modulo/Grupo";
import DataModuloMateriaV2 from "../../data/modulo/MateriaV2";
import { Button } from "../controlD3/Button";
import { CalendarioGridMaterias } from "../controlD3/CalendarioGridMaterias";
import { Fields, FormGenerator, IField } from "../controlD3/Formulario";
import { InputColor } from "../controlD3/InputColorSelect";
import { InputFileControl } from "../controlD3/InputFileControlV2";
import { ModalThings } from "../controlD3/ModalThings";
import { SelectV2 } from "../controlD3/SelectV2";
import { Table } from "../controlD3/Tabla";
import { HTMLButton2Element } from "../controlWC/Button2Component";
import { UIUtilIconResources } from "../util/IconResourses";
import { UIUtilLang } from "../util/Language";
import { UIUtilPermission } from "../util/Permission";
import { UIUtilGeneral } from "../util/Util";
import { UIUtilViewData } from "../util/ViewData";

export namespace UIUtilViewMateria {
    //import CModulo = Entidad.CModulo;
    import CAccionPermiso = Entidad.CAccionPermiso;
    import IMateria = Entidad.IMateria;
    import IEvaluacionConfigItem = Entidad.IEvaluacionConfigItem;
    import ICriterio = Entidad.ICriterio;
    import CTipoEvaluacion = Entidad.CTipoEvaluacion;
    import CFrecuenciaEval = Entidad.CMateriaFrecuenciaEvaluacion;
    import IBaseData = UIUtilViewData.IBaseData;
    export interface ICalificaciones {
        MaxCal: { id: number, cal: number };
        MinCal: { id: number, cal: number };
        MinAprob: number;
    }

    export interface IMateriaForm extends Omit<IMateria, "Criterios"> {
        Escuelas: number[];
        Niveles: number[];
        Grados: number[];
        Imagen?: string;
        Foto?: File;
        Criterios: ICriterio[];
        ConfiguracionEval: IEvaluacionConfigItem[];
        /**Extra para edicion múltiple de materias */
        idsMaterias: number[];
    }

    export interface ITipoEvalGrid extends IEvaluacionConfigItem {
        Ids: number[];
        Orden: number;
        Actions: any;
    }

    export interface ICriterioGrid extends ICriterio {
        Ids: number[];
        Actions: any;
    }

    let playgroundItemsEvaluacionList: ITipoEvalGrid[];
    let playgroundItemsCriteriosList: ICriterioGrid[];
    let playgroundItemsCriteriosOriginal: ICriterioGrid[];
    let playgroundEvaluacionOriginal: ITipoEvalGrid[];
    let tablaEvaluacion: Table.Tabla<ITipoEvalGrid>;
    let calificacionesList: ICalificaciones;

    let inputCalMin: HTMLInputElement;
    let inputCalMax: HTMLInputElement;
    let inputCalMinAprob: HTMLInputElement;

    interface IConfigTiempoEval {
        BloqueCalificarInicio: number;
        DiaCalificarInicio: number;
        DiasCalificar: number;
    }
    let configTiempoEval: IConfigTiempoEval;


    export function _GetMateriaForm(action: (CAccionPermiso.Agregar | CAccionPermiso.Editar), materia = <IMateriaForm>{}) {
        const dataFrecEval: IBaseData[] = [
            { Id: CFrecuenciaEval.Diario, Name: "Diario" },
            { Id: CFrecuenciaEval.Semanal, Name: "Semanal" },
            { Id: CFrecuenciaEval.Mensual, Name: "Mensual" },
            { Id: CFrecuenciaEval.Bimestral, Name: "Bimestral" },
            { Id: CFrecuenciaEval.Trimestral, Name: "Trimestral" },
            { Id: CFrecuenciaEval.Cuatrimestral, Name: "Cuatrimestral" },
            { Id: CFrecuenciaEval.Semestral, Name: "Semestral" },
            { Id: CFrecuenciaEval.Anual, Name: "Anual" },
            { Id: CFrecuenciaEval.Libre, Name: "Libre" },
        ]


        const getInfoTimeEvalText = (idFrecuencia: number, bloqueCalificarInicio: number, diaCalificarInicio: number, diasCalificar: number) => {
            let strInfoConfigTime = "";
            if (!bloqueCalificarInicio || !diaCalificarInicio || !diasCalificar) {
                strInfoConfigTime = UIUtilLang._GetUIString("materias", "tag_sinconfigurar");
            } else {
                strInfoConfigTime = UIUtilLang._GetUIString("materias", "tag_iniciarevaluacion") + ": ";
                if (bloqueCalificarInicio == 1 && diaCalificarInicio == 1) {
                    strInfoConfigTime += UIUtilLang._GetUIString("materias", "tag_mismodiainicio") + " " + UIUtilLang._GetUIString("materias", "tag_aprox") + "\n";
                } else {
                    if (bloqueCalificarInicio > 1) {
                        let tagMeses = (bloqueCalificarInicio > 2) ? UIUtilLang._GetUIString("materias", "tag_meses") : UIUtilLang._GetUIString("materias", "tag_mes");
                        strInfoConfigTime += (bloqueCalificarInicio - 1) + " " + tagMeses + " ";
                        if (diaCalificarInicio > 1) strInfoConfigTime += UIUtilLang._GetUIString("materias", "tag_andconector") + " ";
                    }
                    if (diaCalificarInicio > 1) strInfoConfigTime += (diaCalificarInicio - 1)?.toString() + " " + UIUtilLang._GetUIString("materias", "tag_dias") + " ";
                    strInfoConfigTime += UIUtilLang._GetUIString("materias", "tag_despues") + " " + UIUtilLang._GetUIString("materias", "tag_aprox") + "\n";
                }
                strInfoConfigTime += UIUtilLang._GetUIString("materias", "tag_diascalificar") + ": " + diasCalificar + " " + UIUtilLang._GetUIString("materias", "tag_dias") + " " + UIUtilLang._GetUIString("materias", "tag_aprox");
            }
            return strInfoConfigTime;
        }

        const fnOpenModalEvalTimeConfing = (idFrecuencia: number, bloqueCalificarInicio: number = 1, diaCalificarInicio: number = 1, diasCalificar: number = 1) => {
            bloqueCalificarInicio = !bloqueCalificarInicio ? 1 : bloqueCalificarInicio;
            diaCalificarInicio = !diaCalificarInicio ? 1 : diaCalificarInicio;
            diasCalificar = !diasCalificar ? 1 : diasCalificar;
            return new Promise<IConfigTiempoEval>(resolve => {
                ModalThings._GetModalToAProccess({
                    Title: UIUtilLang._GetUIString("materias", "title_configevaltime"),
                    DrawContent: (content, mt) => {
                        let contInfo = content.select<HTMLDivElement>(".cont_info");
                        let contentido = content.html(`
                                <div class="cont_calendar"></div>
                                <div class="cont_info"></div>
                            `)

                        contInfo = contentido.select(".cont_info"); // container.append("div").classed("cont_info", true);

                        for (let i = 1; i <= 3; i++) {
                            let tag = contInfo.append("div").classed("tag_" + i, true);
                            tag.append("div").classed("selector", true).classed("sel_" + i, true);
                            tag.append("div").classed("info", true).append("label");
                        }

                        let calendarGrid: CalendarioGridMaterias.CalendarioGridMaterias = content["CalendarGrid"];
                        let contCalendar = content.select<HTMLDivElement>(".cont_calendar"); // this.propModal.BodyModal.select<HTMLDivElement>(".cargo_perio0dicidad");
                        let updateInfoCalendar = (periodicidad: Entidad.CMateriaFrecuenciaEvaluacion, bloqueCalificarInicioUpdt: number, diaCalificarInicioUpdt: number, diasCalificarUpdt: number) => {
                            bloqueCalificarInicio = bloqueCalificarInicioUpdt;
                            diaCalificarInicio = diaCalificarInicioUpdt;
                            diasCalificar = diasCalificarUpdt;

                            contInfo.select(".tag_1").select(".info").select("label").text(GetTextPeriod(periodicidad, "aplicacion", bloqueCalificarInicio, diaCalificarInicio));
                            contInfo.select(".tag_2").select(".info").select("label").text(GetTextPeriod(periodicidad, "vencimiento", bloqueCalificarInicio, diasCalificar));
                        }

                        if (!calendarGrid) {
                            calendarGrid = new CalendarioGridMaterias.CalendarioGridMaterias(contCalendar, {
                                ContainerToRedimention: mt.Modal._ModalSelection,
                                OnChangeSomeSelector: (result) => {
                                    updateInfoCalendar(idFrecuencia, result.Aplicacion.Month, result.Aplicacion.Days, result.Vencimiento.Days + 1);
                                },
                                BloqueIniciar: bloqueCalificarInicio,
                                DiaCalificacion: diaCalificarInicio,
                                DiasCalificar: diasCalificar
                            });
                            content["CalendarGrid"] = calendarGrid;
                        } else {
                            // En caso de que se regrese y modifique la periodicidad, se resetean los valores de dias
                            if (idFrecuencia != calendarGrid._Periodicidad) {
                                bloqueCalificarInicio = 1;
                                diaCalificarInicio = 1;
                                diasCalificar = 1;
                            }
                        }

                        calendarGrid._Periodicidad = idFrecuencia;
                        calendarGrid._SetPosicionSelectores(bloqueCalificarInicio, diaCalificarInicio, diasCalificar)

                        let initRes = calendarGrid._GetResultSelected();
                        updateInfoCalendar(idFrecuencia, initRes.Aplicacion.Month, initRes.Aplicacion.Days, initRes.Vencimiento.Days + 1);
                    },
                    OnClose: () => resolve(null),
                    OnAccept: (mt) => {
                        resolve({ BloqueCalificarInicio: bloqueCalificarInicio, DiaCalificarInicio: diaCalificarInicio, DiasCalificar: diasCalificar });
                        mt.Modal._Ocultar();
                        return null;
                    }
                })
            })
        }

        const evalConfigTimeEvalValid = () => {
            return !(!configTiempoEval || !configTiempoEval.BloqueCalificarInicio || !configTiempoEval.DiaCalificarInicio || !configTiempoEval.DiasCalificar);
        }

        let datoMateriaForm: IMateriaForm = Object.assign(<IMateriaForm>{
            Niveles: [],
            Grados: [],
            IdsGrupos: [],
            Criterios: [],
            ConfiguracionEval: []
        }, materia);


        if (action == CAccionPermiso.Agregar) {
            datoMateriaForm.TipoEvaluacion = 2;
            datoMateriaForm.UsaCriterios = true;
        }

        if (!datoMateriaForm.FrecuenciaEval) {
            datoMateriaForm.FrecuenciaEval = 9;
        }

        if (datoMateriaForm.IdsGrupos) {
            datoMateriaForm?.IdsGrupos.forEach(idGrupo => {
                let getGrupo = DataModuloGrupo._DiccGrupo.get(idGrupo);
                if (getGrupo && datoMateriaForm.Grados.indexOf(getGrupo.IdNivel) == -1) {
                    datoMateriaForm.Grados.push(getGrupo.IdNivel)
                }
                if (getGrupo && datoMateriaForm.Niveles.indexOf(getGrupo.IdEscolaridad) == -1) {
                    datoMateriaForm.Niveles.push(getGrupo.IdEscolaridad);
                }
            })
        }

        let escuelasPermission = UIUtilPermission._GetSchoolsByActionModule(Entidad.CModulo.Materias, action);
        let esAgregar = action == Entidad.CAccionPermiso.Agregar;
        let form = new FormGenerator<IMateriaForm>();
        let tablaCriterios: Table.Tabla<ICriterioGrid>;
        playgroundItemsEvaluacionList = [];
        playgroundItemsCriteriosList = [];
        playgroundItemsCriteriosOriginal = [];
        playgroundEvaluacionOriginal = [];
        tablaEvaluacion = null;

        configTiempoEval = {
            BloqueCalificarInicio: null,
            DiaCalificarInicio: null,
            DiasCalificar: null
        }

        configTiempoEval.BloqueCalificarInicio = datoMateriaForm?.BloqueCalificarInicio;
        configTiempoEval.DiaCalificarInicio = datoMateriaForm?.DiaCalificarInicio;
        configTiempoEval.DiasCalificar = datoMateriaForm?.DiasCalificar;

        calificacionesList = {
            MaxCal: { id: null, cal: 0 },
            MinCal: { id: null, cal: 0 },
            MinAprob: 0,
        }

        if (!esAgregar && datoMateriaForm.idsMaterias.length == 1) {
            datoMateriaForm.Imagen = DataModuloMateriaV2._GetUrlObtenerLogoMateria(<IMateria>{ ...materia, Criterios: [] }, 3); //FIXME CAMBIO DE CRITERIOS
        }

        let initFormSchema: Array<IField<IMateriaForm>> = [
            {
                model: "Imagen",
                type: Fields.fotoV2,
                fotoV2Attrs: {
                    Disabled: (!esAgregar && datoMateriaForm.idsMaterias.length > 1),
                    RowClase: "alinear_foto",
                    TypeValue: "File",
                    DefaultBackgroundImage: UIUtilIconResources.CGeneral.Folder,
                    AspectRatio: "contain",
                    ControlForm: InputFileControl.ControlForm.Semicuadrado,
                }
            },
            { model: "Nombre", type: Fields.input, labelText: "Nombre", inputAttr: { required: true } },
            {
                model: "Escuelas",
                type: Fields.selectMaterial,
                labelText: UIUtilLang._GetUIString("materias", "d_field_strescuela"),
                selectMaterialAttr: {
                    multiselect: true,
                    disabled: !esAgregar,
                    removeBorder: !esAgregar,
                    required: true,
                    valueMember: "IdKinder",
                    displayMember: "Nombre",
                    onSelect: (datoEscuela: Entidad.IEscuela[]) => {
                        let comboNivel = form._ControlsData.get("Niveles").instance as SelectV2;
                        if (datoEscuela.length) {
                            comboNivel._UpdateList(GetEscolaridadesFiltrados(datoEscuela.map(d => d.IdKinder)))
                        } else {
                            comboNivel._ResetSelect();
                            comboNivel._UpdateList([]);
                        }
                    }
                }, values: escuelasPermission
            },
            {
                model: "Niveles",
                type: Fields.selectMaterial,
                labelText: UIUtilLang._GetUIString("materias", "d_field_strnivel"),
                selectMaterialAttr: {
                    disabled: (!esAgregar && datoMateriaForm.idsMaterias.length > 1),
                    multiselect: (esAgregar || datoMateriaForm.idsMaterias.length > 1),
                    required: true,
                    valueMember: "Id",
                    displayMember: "Nombre",
                    removeBorder: (!esAgregar && datoMateriaForm.idsMaterias.length > 1),
                    onSelect: (datoNiveles: Entidad.IEscolaridad[]) => {
                        let comboGrados = form._ControlsData.get("Grados").instance as SelectV2;
                        if (datoNiveles) {
                            let nivelesSelected: Entidad.IEscolaridad[] = [];
                            if (!(datoNiveles instanceof Array)) nivelesSelected = [datoNiveles]; else nivelesSelected = [...datoNiveles];
                            let grados = Array.from(DataModuloGrado._DiccGrado.values())
                                .filter(dGrado => (nivelesSelected.find(dNivel => (dGrado.IdEscolaridad == dNivel.Id))));

                            comboGrados._UpdateList(grados);

                            let itemsDisabled = grados
                                .filter(d => (DataModuloGrado._LOCALDATA_GetGruposEnGrado(d.IdNivel).size == 0))
                                .map(d => d.IdNivel);
                            comboGrados._disableItems(itemsDisabled);
                            //comboGrados.met_UpdateList(GetGradosFiltrados(datoNivel.IdEscuela, datoNivel.Id))
                        } else {
                            comboGrados._ResetSelect();
                            comboGrados._UpdateList([]);
                        }
                    },
                    OnStepItemListUI: (container, dato: Entidad.IEscolaridad, step) => {
                        if (step == "enter") {
                            container.classed(UIUtilGeneral.FBoxOrientation.Vertical, true);
                            container.style("align-items", "flex-start");
                        }

                        if (form._Data.Escuelas.length > 1) {
                            container.html(`
                                <label>${UIUtilLang._GetUIString("materias", "d_field_strnivel")}: ${dato.Nombre}</label> 
                                <label>${UIUtilLang._GetUIString("materias", "d_field_strescuela")}: ${DataModuloEscuela._DiccEscuela.get(dato.IdEscuela)?.Nombre || UIUtilLang._GetUIString("general", "nodisponible")}</label>
                            `);
                        } else {
                            container.html(`<label>${dato.Nombre}`);
                        }
                    },
                }, values: []
            },
            {
                model: "Grados",
                type: Fields.selectMaterial,
                labelText: UIUtilLang._GetUIString("materias", "lbl_grados"),
                selectMaterialAttr: {
                    multiselect: (esAgregar || datoMateriaForm.idsMaterias.length > 1),
                    required: true,
                    disabled: (!esAgregar && datoMateriaForm.idsMaterias.length > 1),
                    valueMember: "IdNivel",
                    displayMember: "Nombre",
                    removeBorder: (!esAgregar && datoMateriaForm.idsMaterias.length > 1),
                    onSelect: (datoGrados: Entidad.IGrado[]) => {
                        let comboGrupos = form._ControlsData.get("IdsGrupos").instance as SelectV2;
                        if (datoGrados) {
                            let gradosSelected: Entidad.IGrado[] = [];
                            if (!(datoGrados instanceof Array)) gradosSelected = [datoGrados]; else gradosSelected = [...datoGrados]
                            let grupos = Array.from(DataModuloGrupo._DiccGrupo.values())
                                .filter(dGrupo => (gradosSelected.find(dGrado => dGrupo.IdNivel == dGrado.IdNivel)));
                            comboGrupos._UpdateList(grupos);
                        } else {
                            comboGrupos._ResetSelect();
                            comboGrupos._UpdateList([]);
                        }
                    },
                    OnStepItemListUI: (container, dato: Entidad.IGrado, step) => {
                        if (step == "enter") {
                            container.classed(UIUtilGeneral.FBoxOrientation.Vertical, true);
                            container.style("align-items", "flex-start");
                            //container.classed(Util.FBoxAlign.CenterStart, true);
                        }

                        let html = `<label>${UIUtilLang._GetUIString("materias", "d_field_strgrado")}: ${dato.Nombre}</label>`
                        if (form._Data.Escuelas.length > 1) {
                            html += `<label style='font-size: var(--fontsize_me4)'>${UIUtilLang._GetUIString("materias", "d_field_strnivel")}: ${DataModuloEscolaridad._DiccEscolaridad.get(dato.IdEscolaridad)?.Nombre || UIUtilLang._GetUIString("general", "nodisponible")}</label>`;
                            html += `<label style='font-size: var(--fontsize_me4)'>${UIUtilLang._GetUIString("materias", "d_field_strescuela")}: ${DataModuloEscuela._DiccEscuela.get(dato.IdKinder)?.Nombre || UIUtilLang._GetUIString("general", "nodisponible")}</label>`;
                        } else if (form._Data.Niveles.length > 1) {
                            html += `<label style='font-size: var(--fontsize_me4)'>${UIUtilLang._GetUIString("materias", "d_field_strnivel")}: ${DataModuloEscolaridad._DiccEscolaridad.get(dato.IdEscolaridad)?.Nombre || UIUtilLang._GetUIString("general", "nodisponible")}</label>`;
                        } else {
                            html = `<label>${dato.Nombre}`;
                        }

                        container.html(html);
                        if (DataModuloGrado._LOCALDATA_GetGruposEnGrado(dato.IdNivel).size == 0) {
                            container.append("label")
                                .style("color", "red")
                                .style("font-size", "var(--fontsize_me4)")
                                .text("Grado sin Grupos");
                        }
                    }
                }, values: []
            },
            {
                model: "IdsGrupos",
                type: Fields.selectMaterial,
                labelText: UIUtilLang._GetUIString("materias", "lbl_grupos"),
                selectMaterialAttr: {
                    disabled: (!esAgregar && datoMateriaForm.idsMaterias.length > 1),
                    multiselect: true,
                    required: true,
                    valueMember: "IdGrupo",
                    removeBorder: (!esAgregar && datoMateriaForm.idsMaterias.length > 1),
                    displayMember: "Nombre",
                    OnStepItemListUI: (container, dato: Entidad.IGrupo, step) => {
                        if (step == "enter") {
                            container.classed(UIUtilGeneral.FBoxOrientation.Vertical, true);
                        }
                        let html = `<label>${UIUtilLang._GetUIString("materias", "d_field_strgrupos")}: ${dato.Nombre}</label>`;

                        if (form._Data.Escuelas.length > 1) {
                            html += `<label style='font-size: var(--fontsize_me4)'>${UIUtilLang._GetUIString("materias", "d_field_strgrado")}: ${DataModuloGrado._DiccGrado.get(dato.IdNivel)?.Nombre || UIUtilLang._GetUIString("general", "nodisponible")}</label>`;
                            html += `<label style='font-size: var(--fontsize_me4)'>${UIUtilLang._GetUIString("materias", "d_field_strnivel")}: ${DataModuloEscolaridad._DiccEscolaridad.get(dato.IdEscolaridad)?.Nombre || UIUtilLang._GetUIString("general", "nodisponible")}</label>`;
                            html += `<label style='font-size: var(--fontsize_me4)'>${UIUtilLang._GetUIString("materias", "d_field_strescuela")}: ${DataModuloEscuela._DiccEscuela.get(dato.IdKinder)?.Nombre || UIUtilLang._GetUIString("general", "nodisponible")}</label>`;
                        } else if (form._Data.Niveles?.length && form._Data.Niveles.length > 1) {
                            html += `<label style='font-size: var(--fontsize_me4)'>${UIUtilLang._GetUIString("materias", "d_field_strgrado")}: ${DataModuloGrado._DiccGrado.get(dato.IdNivel)?.Nombre || UIUtilLang._GetUIString("general", "nodisponible")}</label>`;
                            html += `<label style='font-size: var(--fontsize_me4)'>${UIUtilLang._GetUIString("materias", "d_field_strnivel")}: ${DataModuloEscolaridad._DiccEscolaridad.get(dato.IdEscolaridad)?.Nombre || UIUtilLang._GetUIString("general", "nodisponible")}</label>`;
                        } else if (form._Data.Grados?.length && form._Data.Grados.length > 1) {
                            html += `<label style='font-size: var(--fontsize_me4)'>${UIUtilLang._GetUIString("materias", "d_field_strgrado")}: ${DataModuloGrado._DiccGrado.get(dato.IdNivel)?.Nombre || UIUtilLang._GetUIString("general", "nodisponible")}</label>`;
                        } else {
                            html = `<label>${dato.Nombre}</label>`;
                        }

                        container.html(html);
                    },
                    onSelect: (data: Entidad.IGrupo[]) => {
                        if (!datoMateriaForm.IdsGrupos) return;
                        let gruposDeleted = datoMateriaForm.IdsGrupos.filter(gA => {
                            let grupo = data.find(gB => gA == gB.IdGrupo);
                            return !Boolean(grupo);
                        });
                        let labelMessageAlert = form._Form.select(".row_1").select(".row_gruposselect").select<HTMLElement>(".control_wrapper").select(".labelAlert");
                        if (gruposDeleted.length) {
                            if (!labelMessageAlert.node())
                                form._Form.select(".row_1").select(".row_gruposselect").select<HTMLElement>(".control_wrapper").append("label")
                                    .text(UIUtilLang._GetUIString("materias", "alert_groupsdeselected"))
                                    .style("color", "red")
                                    .style("font-size", "var(--fontsize_me2)")
                                    .classed("labelAlert", true);
                        } else if (labelMessageAlert.node()) {
                            labelMessageAlert.remove();
                        }
                    },
                }, values: []
            },
            {
                model: "FrecuenciaEval",
                type: Fields.selectMaterial,
                labelText: UIUtilLang._GetUIString("materias", "lbl_frecuenciaeval"),
                selectMaterialAttr: {
                    required: true, displayMember: "Name", valueMember: "Id",
                    onChange: async (idDato: number, dato: IBaseData) => {
                        configTiempoEval.BloqueCalificarInicio = null;
                        configTiempoEval.DiaCalificarInicio = null;
                        configTiempoEval.DiasCalificar = null;
                        //datoMateriaForm.BloqueCalificarInicio = null;
                        //datoMateriaForm.DiaCalificarInicio = null;
                        //datoMateriaForm.DiasCalificar = null;
                        if (idDato !== CFrecuenciaEval.Diario && idDato !== CFrecuenciaEval.Libre) {
                            let configTiempoEvalu = await fnOpenModalEvalTimeConfing(idDato);
                            if (configTiempoEvalu) {
                                configTiempoEval.BloqueCalificarInicio = configTiempoEvalu.BloqueCalificarInicio;
                                configTiempoEval.DiaCalificarInicio = configTiempoEvalu.DiaCalificarInicio;
                                configTiempoEval.DiasCalificar = configTiempoEvalu.DiasCalificar;
                                //datoMateriaForm.BloqueCalificarInicio = configTiempoEval.BloqueCalificarInicio;
                                //datoMateriaForm.DiaCalificarInicio = configTiempoEval.DiasCalificarInicio;
                                //datoMateriaForm.DiasCalificar = configTiempoEval.DiasCalificar;
                            }
                        }
                        const tiempoEvalInfo = form._ControlsData.get("FrecuenciaEval").row.select<HTMLDivElement>(".tiempoeval_info");
                        tiempoEvalInfo.classed("hide", [CFrecuenciaEval.Libre, CFrecuenciaEval.Diario].includes(dato.Id))
                        tiempoEvalInfo.text("");
                        tiempoEvalInfo.append("pre").style("font-size", "var(--fontsize_me2)").text(getInfoTimeEvalText(form._Data.FrecuenciaEval, configTiempoEval.BloqueCalificarInicio, configTiempoEval.DiaCalificarInicio, configTiempoEval.DiasCalificar) + "\n\n").style("color", evalConfigTimeEvalValid() ? "var(--color_text1)" : "var(--color_app_red1)");
                        new Button(tiempoEvalInfo.node(), "Editar")
                            ._d3Selection
                            .on("click", async () => {
                                let configTiempoEvalu = await fnOpenModalEvalTimeConfing(idDato, configTiempoEval.BloqueCalificarInicio, configTiempoEval.DiaCalificarInicio, configTiempoEval.DiasCalificar);
                                if (configTiempoEvalu) {
                                    configTiempoEval.BloqueCalificarInicio = configTiempoEvalu.BloqueCalificarInicio;
                                    configTiempoEval.DiaCalificarInicio = configTiempoEvalu.DiaCalificarInicio;
                                    configTiempoEval.DiasCalificar = configTiempoEvalu.DiasCalificar;
                                    tiempoEvalInfo.select("pre").text(getInfoTimeEvalText(form._Data.FrecuenciaEval, configTiempoEval.BloqueCalificarInicio, configTiempoEval.DiaCalificarInicio, configTiempoEval.DiasCalificar) + "\n\n").style("color", evalConfigTimeEvalValid() ? "var(--color_text1)" : "var(--color_app_red1)")
                                }
                            })
                    }
                },
                onLoad: ({ controlWrapper }) => {
                    let prePadre = controlWrapper.append("pre")
                        .attr("class", "tiempoeval_info")
                        .classed("hide", [CFrecuenciaEval.Libre, CFrecuenciaEval.Diario].includes(datoMateriaForm.FrecuenciaEval))
                        .style("padding", "var(--padding1)")
                        .style("font-size", "var(--fontsize_me2)")
                        .style("border", "1px solid var(--color_borderbox1)")
                        .style("border-radius", "var(--border_radius_base)")
                    prePadre.append("pre")
                        .style("font-size", "var(--fontsize_me2)")
                        .text(getInfoTimeEvalText(form._Data.FrecuenciaEval, configTiempoEval.BloqueCalificarInicio, configTiempoEval.DiaCalificarInicio, configTiempoEval.DiasCalificar) + "\n\n").style("color", evalConfigTimeEvalValid() ? "var(--color_text1)" : "var(--color_app_red1)")
                    new Button(prePadre.node(), "Editar")
                        ._d3Selection
                        .on("click", async () => {
                            let configTiempoEvalu = await fnOpenModalEvalTimeConfing(datoMateriaForm.FrecuenciaEval, configTiempoEval.BloqueCalificarInicio, configTiempoEval.DiaCalificarInicio, configTiempoEval.DiasCalificar);
                            if (configTiempoEvalu) {
                                configTiempoEval.BloqueCalificarInicio = configTiempoEvalu.BloqueCalificarInicio;
                                configTiempoEval.DiaCalificarInicio = configTiempoEvalu.DiaCalificarInicio;
                                configTiempoEval.DiasCalificar = configTiempoEvalu.DiasCalificar;
                            }
                            prePadre.select("pre").text(getInfoTimeEvalText(form._Data.FrecuenciaEval, configTiempoEval.BloqueCalificarInicio, configTiempoEval.DiaCalificarInicio, configTiempoEval.DiasCalificar) + "\n\n").style("color", evalConfigTimeEvalValid() ? "var(--color_text1)" : "var(--color_app_red1)")
                        })
                },
                values: dataFrecEval,
                onValidate: (dato, control) => {
                    if (!dato) return false;
                    if (![CFrecuenciaEval.Diario, CFrecuenciaEval.Libre].includes(dato)) {
                        if (!configTiempoEval.BloqueCalificarInicio && !configTiempoEval.DiaCalificarInicio && !configTiempoEval.DiasCalificar) { // DOTEST
                            setTimeout(() => {
                                control.selection.classed("input_err", false);
                            })
                            return false;
                        }
                    }
                    return true;
                }
            },
            /* {
                model: "BloqueCalificarInicio",
                type: Fields.input,
                labelText: "Mes de evaluación",
                inputAttr: { type: "number", value: "1", min: 1 },
            },
            {
                model: "DiaCalificarInicio",
                type: Fields.input,
                labelText: "Día de inicio de evaluación",
                inputAttr: { type: "number", value: "1", min: 1, max: 30 },
            },
            {
                model: "DiasCalificar",
                type: Fields.input,
                labelText: "Días para calificar",
                inputAttr: { type: "number", value: "1", min: 1 },
            }, */
            {
                model: "TipoEvaluacion",
                type: Fields.radioList,
                labelText: UIUtilLang._GetUIString("materias", "d_field_tipoevaluacion"),
                radioListAttr: {
                    ValueMember: "valor",
                    DisplayMember: "criterio",
                    ReturnOnlyValueMember: true,
                    required: true,
                    Direction: "vertical",
                    Data: [
                        { criterio: UIUtilLang._GetUIString("materias", "eval_letras"), valor: CTipoEvaluacion.Letras },
                        { criterio: UIUtilLang._GetUIString("materias", "eval_colores"), valor: CTipoEvaluacion.Colores },
                        { criterio: UIUtilLang._GetUIString("materias", "eval_numeros"), valor: CTipoEvaluacion.Numeros },
                        { criterio: UIUtilLang._GetUIString("materias", "eval_cualitativa"), valor: CTipoEvaluacion.Cualitativa },
                    ],
                    OnChange: (data) => {
                        playgroundItemsEvaluacionList.splice(0, playgroundItemsEvaluacionList.length);
                        calificacionesList.MaxCal.cal = null;
                        calificacionesList.MaxCal.id = null;
                        calificacionesList.MinCal.cal = null;
                        calificacionesList.MinCal.id = null;
                        let parentTable = form._Form.select(".row_1").select(".row_EvalConfig").select<HTMLElement>(".control_wrapper");
                        let formEval = parentTable.select("form");
                        if (tablaEvaluacion) {
                            tablaEvaluacion._Control.remove();
                        }
                        if (formEval.node()) {
                            formEval.remove();
                        }
                        tablaEvaluacion = null;
                        tablaEvaluacion = getEvalConfigContentByEvalType(data.valor, parentTable);
                        if (data.valor == CTipoEvaluacion.Numeros) {
                            calificacionesList.MinAprob = datoMateriaForm.MinimoAprobar;
                            inputCalMinAprob.value = (calificacionesList.MinAprob) ? calificacionesList.MinAprob.toString() : null;
                            inputCalMinAprob.style.border = !inputCalMinAprob.value ? "1px solid var(--color_app_red1)" : null;
                        }
                        if (datoMateriaForm.TipoEvaluacion == data.valor) {
                            playgroundEvaluacionOriginal.forEach(d => {
                                playgroundItemsEvaluacionList.push({ ...d });
                            })
                            if (form._Data.TipoEvaluacion == CTipoEvaluacion.Numeros) {
                                calificacionesList.MinCal.cal = Number(playgroundEvaluacionOriginal[0].Nombre);
                                calificacionesList.MaxCal.cal = Number(playgroundEvaluacionOriginal[1].Nombre);
                                calificacionesList.MinAprob = datoMateriaForm.MinimoAprobar;
                                inputCalMin.value = calificacionesList.MinCal.cal.toString();
                                inputCalMax.value = calificacionesList.MaxCal.cal.toString();
                                inputCalMinAprob.value = calificacionesList.MinAprob.toString();
                                inputCalMin.style.border = !inputCalMin.value ? "1px solid var(--color_app_red1)" : null;
                                inputCalMax.style.border = !inputCalMax.value ? "1px solid var(--color_app_red1)" : null;
                                inputCalMinAprob.style.border = !inputCalMinAprob.value ? "1px solid var(--color_app_red1)" : null;
                            }
                        }
                        if (data.valor !== Entidad.CTipoEvaluacion.Numeros && !playgroundItemsEvaluacionList.length) playgroundItemsEvaluacionList.push({ Ids: [-1], Id: -1, Nombre: "", Descripcion: "", Actions: "", Orden: null, Valor: null, IdMateria: null, TipoEvaluacion: null });
                        if (tablaEvaluacion) tablaEvaluacion._UpdateData(playgroundItemsEvaluacionList);
                    }
                },
                onValidate: (dato, control) => {
                    if (!dato) return false;
                    if (form._Data.TipoEvaluacion !== CTipoEvaluacion.Numeros) {
                        if (!tablaEvaluacion._data.length) {
                            setTimeout(() => {
                                control.selection.classed("input_err", false);
                                tablaEvaluacion._Control.classed("borderErr", true);
                            })
                            return false;
                        }
                        let itemNombreVacio = tablaEvaluacion._data.find(d => (d.Nombre.trim() == ""));
                        if (itemNombreVacio) {
                            setTimeout(() => {
                                control.selection.classed("input_err", false);
                            })
                            return false;
                        }
                    } else {
                        if (inputCalMin.value.trim() == '' || inputCalMax.value.trim() == '' || inputCalMinAprob.value.trim() == '') {
                            setTimeout(() => {
                                control.selection.classed("input_err", false);
                            })
                            return false;
                        }
                    }
                    return true;
                },
            },
            {
                model: "UsaCriterios",
                type: Fields.radioList,
                labelText: UIUtilLang._GetUIString("materias", "lbl_hascriterios"),
                radioListAttr: {
                    ValueMember: "valor",
                    DisplayMember: "titulo",
                    ReturnOnlyValueMember: true,
                    required: true,
                    Direction: "horizontal",
                    Data: [
                        { titulo: UIUtilLang._GetUIString("general", "afirma"), valor: true },
                        { titulo: UIUtilLang._GetUIString("general", "niega"), valor: false }
                    ],
                    OnChange: (data) => {
                        tablaCriterios._Control.style("opacity", data.valor ? null : "0.5");
                        tablaCriterios._Control.style("pointer-events", data.valor ? null : "none");
                        if (!data.valor) {
                            tablaCriterios._Control.classed("borderErr", false);
                        }
                    }
                },
                onValidate: (dato, control) => {
                    if (dato) {
                        if (!tablaCriterios._data.length) {
                            setTimeout(() => {
                                control.selection.classed("input_err", false);
                                tablaCriterios._Control.classed("borderErr", true);
                            })
                            return false;
                        }
                        let itemTextoVacio = tablaCriterios._data.find(d => (d.Descripcion.trim() == ""));
                        if (itemTextoVacio) {
                            setTimeout(() => {
                                control.selection.classed("input_err", false);
                            })
                            return false;
                        }
                        const conjunto = new Set<string>(tablaCriterios._data.map(d => d.Descripcion));
                        if (conjunto.size !== tablaCriterios._data.length) {
                            setTimeout(() => {
                                control.selection.classed("input_err", false);
                            })
                            return false;
                        }
                    };
                    return true;
                }
            }
        ];

        form._Crear({
            LabelMaxWidth: 150,
            schema: initFormSchema,
            BuildView: (container, controlsForms) => {
                container
                    .classed("materia_form", true)
                    .style("flex-direction", "row")
                    .style("gap", "25px");
                container.append("div").classed("row_1", true);
                container.append("div").classed("row_2", true)
                    .append("div").style("margin-bottom", "var(--padding1)")
                    .append("b").text(UIUtilLang._GetUIString("materias", "tag_tiempoeval"));
                const fnAppendRow = (model: keyof IMateriaForm) => {
                    let control = controlsForms.get(model);
                    if (control) {
                        switch (model) {
                            case "UsaCriterios":
                                let eventCriteriosDragOverIndex = -1;
                                function fn_UpdateCriteriosArrayOrder() {
                                    playgroundItemsCriteriosList.forEach((d, i) => {
                                        d.Orden = (i + 1);
                                    })
                                }
                                let idAuxCriterio = 0;
                                control.row.style("flex-direction", "column");
                                control.row.select(".titulos_base").style("max-width", "100%");
                                tablaCriterios = new Table.Tabla<ICriterioGrid>({
                                    IdTabla: "Materias-FormCriterios",
                                    Parent: control.row.select(".control_wrapper"),
                                    IdData: "Ids",
                                    MinWidth: 250,
                                    RenderColumnHeadings: [
                                        { Field: "Orden", Label: "", Width: "1%", MinWidth: "20px", IsSortable: false },
                                        {
                                            Field: "Descripcion", Label: UIUtilLang._GetUIString("materias", "lbl_putcriterios"), MinWidth: "100px", Width: "90%", IsSortable: false, OnLoadHead: (container) => {
                                                container.style("justify-content", "center")
                                                //container.selectAll("div").style("color", "var(--color_primary1)");
                                                container.selectAll("div:not(.icon)").style("color", "var(--color_primary1)");
                                            }
                                        },
                                        {
                                            Field: "Actions",
                                            Label: "",
                                            MinWidth: "50px",
                                            Width: "10%",
                                            IsSortable: false,
                                            OnLoadHead: (container) => {
                                                container.append("wc-button")
                                                    .attr("dim", "24")
                                                    .property("_SrcIco", UIUtilIconResources.CGeneral.Add)
                                                    .classed("btn_round", true)
                                                    .style("cursor", "pointer")
                                                    .on("click", () => {
                                                        idAuxCriterio--;
                                                        playgroundItemsCriteriosList.push({ Ids: [idAuxCriterio], Id: idAuxCriterio, Descripcion: "", Actions: "", Orden: null, IdMateria: null });
                                                        tablaCriterios._UpdateData(playgroundItemsCriteriosList);
                                                        if (tablaCriterios._data.length) {
                                                            tablaCriterios._Control.classed("borderErr", false);
                                                        }
                                                    });
                                            }
                                        }
                                    ],
                                    HideCheckboxes: true,
                                    EvaluatorAndSubLevelsBuild: {
                                        OnStepRowTable: (datum, tr, rowBody, indexOrigin) => {
                                            let rowBodyElement = rowBody.node();
                                            rowBodyElement.dataset.key = indexOrigin.toString();
                                            rowBodyElement.setAttribute("draggable", true + "");

                                            if (indexOrigin == eventCriteriosDragOverIndex) {
                                                rowBody.style("background-color", "var(--color_primary3)");
                                            } else {
                                                rowBody.style("background-color", null);
                                            }

                                            rowBodyElement.ondragover = function (e) {
                                                e.preventDefault();

                                                let lastIndex = eventCriteriosDragOverIndex;
                                                eventCriteriosDragOverIndex = Number(e.currentTarget["dataset"].key);

                                                if (lastIndex == eventCriteriosDragOverIndex) {
                                                    return;
                                                }

                                                playgroundItemsCriteriosList = UIUtilGeneral._ArrayMoveIndex(playgroundItemsCriteriosList, lastIndex, eventCriteriosDragOverIndex);
                                                fn_UpdateCriteriosArrayOrder();
                                                tablaCriterios._UpdateData(playgroundItemsCriteriosList);
                                            }

                                            rowBodyElement.ondragstart = function (e) {
                                                eventCriteriosDragOverIndex = Number(e.currentTarget["dataset"].key);
                                                rowBody.style("background-color", "var(--color_primary3)");
                                            }

                                            rowBodyElement.ondragend = function (e) {
                                                eventCriteriosDragOverIndex = -1;
                                                tablaCriterios._RefreshView();
                                            }
                                        },
                                        OnStepCellTable: (container: TSelectionHTML<"div", any, any>, datum, fieldNameStep, indexInicialOfDatum?: number) => {
                                            switch (fieldNameStep) {
                                                case "Orden":
                                                    container
                                                        .style("transform", "rotate(90deg)")
                                                        .style("color", "gray")
                                                        .style("cursor", "grab")
                                                        .text("|||");
                                                    break;
                                                case "Descripcion":
                                                    let inputCriterio = container.select<HTMLDivElement>("div").node();
                                                    if (!inputCriterio) {
                                                        container.text("");
                                                        //Checar Overflow cuando cambie
                                                        inputCriterio = container.append("div").classed("input-form", true).style("text-align", "center").style("max-height", "70px").style("overflow", "auto").attr("contenteditable", true).node();
                                                        inputCriterio.onclick = e => e.stopPropagation();
                                                    }
                                                    inputCriterio.innerText = datum.Descripcion;
                                                    inputCriterio.oninput = (e) => {
                                                        let inputErr = false;
                                                        if (tablaCriterios._data.find(d => d.Descripcion == inputCriterio.innerText)) inputErr = true;
                                                        datum.Descripcion = inputCriterio.innerText;
                                                        if (!datum.Descripcion) inputErr = true;
                                                        inputCriterio.style.border = inputErr ? "1px solid var(--color_app_red1)" : null;
                                                    }
                                                    inputCriterio.onkeydown = e => {
                                                        if (e.key == "Enter") {
                                                            e.preventDefault();
                                                            e.stopImmediatePropagation(); //Dejar?
                                                            e.stopPropagation();
                                                        }
                                                    }
                                                    inputCriterio.style.border = !datum.Descripcion ? "1px solid var(--color_app_red1)" : null;
                                                    container.style("width", "100%");
                                                    break;
                                                case "Actions":
                                                    let btnRemove = container.select<HTMLButton2Element>("wc-button").node();
                                                    if (!btnRemove) {
                                                        container.text("");
                                                        btnRemove = container.append<HTMLButton2Element>("wc-button").attr("dim", "24")
                                                            .property("_SrcIco", UIUtilIconResources.CGeneral.Menos)
                                                            .classed("btn_round", true).node();
                                                        btnRemove.setAttribute("draggable", false + "");
                                                        btnRemove.ondragstart = e => e.stopPropagation();
                                                    }
                                                    btnRemove.onclick = (e) => {
                                                        let itemEliminar = datum;
                                                        let indexItemReal = playgroundItemsCriteriosList.findIndex(d => (d.Ids.join("_") == itemEliminar.Ids.join("_")));
                                                        if (indexItemReal > -1) {
                                                            playgroundItemsCriteriosList.splice(indexItemReal, 1);
                                                            tablaCriterios._UpdateData(playgroundItemsCriteriosList);
                                                        } else {
                                                            console.warn("-d", "Error al eliminar Item");
                                                        }
                                                    }
                                                    break;
                                            }
                                        }
                                    },
                                    Resizable: false
                                });
                                control.row.select("label").style("font-weight", "bold");
                                tablaCriterios._Control.style("margin-top", "var(--padding1)")
                                tablaCriterios._Control.select(".area_filtros").remove();
                                tablaCriterios._Control.select(".rhead").select("tr").style("background-color", "var(--color_action1focus)");

                                tablaCriterios._Control.style("opacity", datoMateriaForm.UsaCriterios ? null : "0.5").style("pointer-events", datoMateriaForm.UsaCriterios ? null : "none");
                                tablaCriterios._Control.style("max-height", "630px");
                                break;
                            case "TipoEvaluacion":
                                control.row.style("flex-direction", "column");
                                control.row.style("max-height", "100%");
                                control.row.select(".titulos_base").style("font-weight", "bolder").style("flex", "none");
                                control.row.select(".control_wrapper").style("display", "flex").style("flex-wrap", "wrap").style("gap", "35px");
                                control.selection.style("min-width", "170px").style("flex", "1").style("max-height", "100%").style("overflow", "auto").style("flex-wrap", "unset");
                                control.row.classed("row_EvalConfig", true).style("z-index", 1);
                                tablaEvaluacion = getEvalConfigContentByEvalType(datoMateriaForm.TipoEvaluacion, control.row.select(".control_wrapper"));
                                break;
                            case "IdsGrupos":
                                control.row.classed("row_gruposselect", true);
                                break;
                            default:
                                //console.warn("Normal");
                                break;
                        }
                        const row1Fields: (keyof IMateriaForm)[] = ["UsaCriterios", "FrecuenciaEval", "BloqueCalificarInicio", "DiaCalificarInicio", "DiasCalificar"]
                        if (row1Fields.includes(model)) {
                            if (model == "FrecuenciaEval" || model == "BloqueCalificarInicio" || model == "DiaCalificarInicio") {
                                control.row.select("label").style("margin-top", 0)
                            }
                            container.select(".row_2").append(() => control.row.node());
                        } else {
                            container.select(".row_1").append(() => control.row.node());
                        }
                    } else {
                        console.warn("-d", model, "NO encontrado");
                    }
                }
                fnAppendRow("Imagen");
                fnAppendRow("Nombre");
                fnAppendRow("Escuelas");
                fnAppendRow("Niveles");
                fnAppendRow("Grados");
                fnAppendRow("IdsGrupos");
                fnAppendRow("TipoEvaluacion");
                fnAppendRow("FrecuenciaEval");
                fnAppendRow("BloqueCalificarInicio");
                fnAppendRow("DiaCalificarInicio");
                fnAppendRow("DiasCalificar");
                fnAppendRow("UsaCriterios");
            }
        }, datoMateriaForm);

        return {
            form,
            tablaEvaluacion,
            formNums: {
                inputCalMin: inputCalMin,
                inputCalMax: inputCalMax,
                inputCalMinAprob: inputCalMinAprob
            },
            calificacionesList: calificacionesList,
            playgroundItemsCriteriosList,
            playgroundItemsEvaluacionList,
            playgroundItemsCriteriosOriginal,
            playgroundEvaluacionOriginal,
            tablaCriterios,
        }
    }

    function GetEscolaridadesFiltrados(idEscuelas: number[]): Array<Entidad.IEscolaridad> {
        return Array.from(DataModuloEscolaridad._DiccEscolaridad.values())
            .filter(dEscolaridad => (idEscuelas.includes(dEscolaridad.IdEscuela)));
    }

    export function _GetTablaEvalData() {
        return tablaEvaluacion?._data;
    }

    export function _GetTimeEvalConfig(): IConfigTiempoEval {
        return {
            BloqueCalificarInicio: configTiempoEval.BloqueCalificarInicio,
            DiaCalificarInicio: configTiempoEval.DiaCalificarInicio,
            DiasCalificar: configTiempoEval.DiasCalificar
        }
    }

    function GetTextPeriod(periodicidad: Entidad.CMateriaFrecuenciaEvaluacion, namePeriodicidad: ("aplicacion" | "vencimiento"), nMes: number, nPrincipal: number): string {
        //const langContextModule = "materias";
        //const fnGetTalLang = (str: string) => UIUtilLang.fn_GetUIString(langContextModule, str);

        let strTag = "";

        switch (namePeriodicidad) {
            case "aplicacion":
                strTag = UIUtilLang._GetUIString("materias", "tag_iniciarevaluacion") + ": ";
                if (nMes == 1 && nPrincipal == 1) {
                    strTag += UIUtilLang._GetUIString("materias", "tag_mismodiainicio");
                    break;
                }
                if (nMes > 1) {
                    let tagMeses = (nMes > 2) ? UIUtilLang._GetUIString("materias", "tag_meses") : UIUtilLang._GetUIString("materias", "tag_mes");
                    strTag += (nMes - 1) + " " + tagMeses + " ";
                    if (nPrincipal > 1) strTag += UIUtilLang._GetUIString("materias", "tag_andconector") + " "
                }
                if (nPrincipal > 1) strTag += (nPrincipal - 1)?.toString() + " " + UIUtilLang._GetUIString("materias", "tag_dias") + " ";
                strTag += UIUtilLang._GetUIString("materias", "tag_despues");
                break;
            case "vencimiento":
                strTag = UIUtilLang._GetUIString("materias", "tag_diascalificar") + ": ";
                strTag += nPrincipal?.toString() + " " + UIUtilLang._GetUIString("materias", "tag_dias");
                break;
        }

        return strTag;
    }

    function getEvalConfigContentByEvalType(evalType: CTipoEvaluacion, parent: d3.Selection<HTMLElement, any, any, any>): Table.Tabla<ITipoEvalGrid> {
        let tablaEvaluacionConfig: Table.Tabla<ITipoEvalGrid>;
        if (evalType !== CTipoEvaluacion.Numeros) {
            let eventEvaluacionesDragOverIndex = -1;
            function fn_UpdateEvaluacionesArrayOrder() {
                playgroundItemsEvaluacionList.forEach((d, i) => {
                    d.Orden = (i + 1);
                })
            }
            let idAuxEval = -1;

            let LabelNombreField = "";
            let WidthNombreField = "";
            let MinWidthNombreField = "";
            let TblId = "Materias-FormEvaluacion-";

            switch (evalType) {
                case CTipoEvaluacion.Letras:
                    LabelNombreField = "";
                    WidthNombreField = "30%"
                    MinWidthNombreField = "40px";
                    TblId += "Letras"
                    break;
                case CTipoEvaluacion.Colores:
                    LabelNombreField = "";
                    WidthNombreField = "10%"
                    MinWidthNombreField = "40px";
                    TblId += "Colores"
                    break;
                case CTipoEvaluacion.Cualitativa:
                    LabelNombreField = UIUtilLang._GetUIString("materias", "tag_observacion")
                    WidthNombreField = "90%"
                    MinWidthNombreField = "100px";
                    TblId += "Cualitativa"
                    break;
            }

            let renderColumnHeadings: Table.IColumn<ITipoEvalGrid>[] = [
                { Field: "Orden", Label: "", Width: "1%", MinWidth: "20px", IsSortable: false },
                {
                    Field: "Nombre", Label: LabelNombreField, Width: WidthNombreField, MinWidth: MinWidthNombreField, IsSortable: false, OnLoadHead: (evalType != CTipoEvaluacion.Cualitativa) ? null : (container) => {
                        container.style("justify-content", "center");
                        // container.select("div").style("color", "var(--color_primary1)")
                        container.selectAll("div:not(.icon)").style("color", "var(--color_primary1)");
                    }
                }
            ];

            if (evalType != CTipoEvaluacion.Cualitativa) {
                renderColumnHeadings.push({
                    Field: "Descripcion", Label: UIUtilLang._GetUIString("materias", "tag_observacion"), MinWidth: "60px", Width: (evalType == CTipoEvaluacion.Letras) ? "60%" : "80%", IsSortable: false, OnLoadHead: (container) => {
                        container.style("justify-content", "center");
                        // container.selectAll("div:not(.icon)").style("color", "var(--color_primary1)");
                        container.selectAll("div:not(.icon)").style("color", "var(--color_primary1)");
                    }
                })
            }

            renderColumnHeadings.push({
                Field: "Actions", Label: "", MinWidth: "30px", Width: "10%", IsSortable: false, OnLoadHead: (container) => {
                    container.append("wc-button")
                        .attr("dim", "24")
                        .property("_SrcIco", UIUtilIconResources.CGeneral.Add)
                        .classed("btn_round", true)
                        .style("cursor", "pointer")
                        .on("click", () => {
                            idAuxEval--;
                            playgroundItemsEvaluacionList.push({ Ids: [idAuxEval], Id: idAuxEval, Nombre: "", Descripcion: "", Actions: "", Orden: null, Valor: null, IdMateria: null, TipoEvaluacion: null });
                            tablaEvaluacionConfig._UpdateData(playgroundItemsEvaluacionList);
                            if (tablaEvaluacionConfig._data.length) {
                                tablaEvaluacionConfig._Control.classed("borderErr", false);
                            }
                        });
                }
            })

            tablaEvaluacionConfig = new Table.Tabla<ITipoEvalGrid>({
                IdTabla: TblId,
                Parent: parent,
                IdData: "Ids",
                MinWidth: 300,
                RenderColumnHeadings: renderColumnHeadings,
                HideCheckboxes: true,
                Resizable: false,
                EvaluatorAndSubLevelsBuild: {
                    OnStepRowTable: (datum, tabeRow, rowBody, indexOrigin) => {
                        let rowBodyElement = rowBody.node();
                        rowBodyElement.dataset.key = indexOrigin.toString();
                        rowBodyElement.setAttribute("draggable", true + "");

                        if (indexOrigin == eventEvaluacionesDragOverIndex) {
                            rowBody.style("background-color", "var(--color_primary3)");
                        } else {
                            rowBody.style("background-color", null);
                        }

                        rowBodyElement.ondragover = function (e) {
                            e.preventDefault();

                            let lastIndex = eventEvaluacionesDragOverIndex;
                            eventEvaluacionesDragOverIndex = Number(e.currentTarget["dataset"].key);

                            if (lastIndex == eventEvaluacionesDragOverIndex) {
                                return;
                            }

                            playgroundItemsEvaluacionList = UIUtilGeneral._ArrayMoveIndex(playgroundItemsEvaluacionList, lastIndex, eventEvaluacionesDragOverIndex);
                            fn_UpdateEvaluacionesArrayOrder();
                            tablaEvaluacionConfig._UpdateData(playgroundItemsEvaluacionList);
                            // console.debug("Drag over", event.currentTarget, lastIndex, eventDragOverIndex, playgroundItemsList.length);
                        }

                        rowBodyElement.ondragstart = function (e) {
                            eventEvaluacionesDragOverIndex = Number(e.currentTarget["dataset"].key);
                            rowBody.style("background-color", "var(--color_primary3)");
                        }

                        rowBodyElement.ondragend = function (e) {
                            eventEvaluacionesDragOverIndex = -1;
                            tablaEvaluacionConfig._RefreshView();
                        }
                    },
                    OnStepCellTable: (container: d3.Selection<HTMLDivElement, any, HTMLElement, any>, datum: ITipoEvalGrid, fieldNameStep: string, indexInicialOfDatum?: number) => {
                        switch (fieldNameStep) {
                            case "Orden":
                                container
                                    .style("transform", "rotate(90deg)")
                                    .style("color", "gray")
                                    .style("cursor", "grab")
                                    .text("|||")
                                break;
                            case "Nombre":
                                let inputNombre = container.select<HTMLInputElement>("input").node();
                                if (evalType == CTipoEvaluacion.Colores) {
                                    if (inputNombre) inputNombre.remove(); else container.text("");
                                    let inputColor = new InputColor({
                                        Parent: container,
                                        OnSelect: (datos) => {
                                            if (datos[0]) datum.Nombre = datos[0];
                                            inputColor._controlSelection.node().style.border = !datum.Nombre ? "1px solid var(--color_app_red1)" : null;
                                        },
                                        StorageColorKey: "materias",
                                        MaxFooterOptions: 6
                                    });
                                    inputColor._controlSelection.node().style.border = !datum.Nombre ? "1px solid var(--color_app_red1)" : null;
                                    if (datum.Nombre) inputColor._valueSelect([datum.Nombre]);
                                    break;
                                }
                                if (!inputNombre) {
                                    container.text("");
                                    inputNombre = container.append("input").node();
                                }
                                let inputNombreSelection = d3.select(inputNombre);
                                switch (evalType) {
                                    case CTipoEvaluacion.Letras:
                                        inputNombreSelection.classed("input-form", true).style("text-align", "center");
                                        inputNombre.value = datum.Nombre;
                                        break;
                                    case CTipoEvaluacion.Cualitativa:
                                        inputNombreSelection.classed("input-form", true).style("text-align", "center");
                                        container.style("width", "100%");
                                        inputNombre.value = datum.Nombre;
                                        break;
                                }
                                inputNombre.oninput = e => {
                                    datum.Nombre = inputNombre.value;
                                    inputNombre.style.border = !inputNombre.value ? "1px solid var(--color_app_red1)" : null;
                                    d3.select(inputNombre).classed(".input-color-err", !inputNombre.value);
                                }
                                inputNombre.style.border = !inputNombre.value ? "1px solid var(--color_app_red1)" : null;
                                d3.select(inputNombre).classed(".input-color-err", !inputNombre.value);
                                break;
                            case "Descripcion":
                                let inputDescripcion = container.select<HTMLInputElement>("input").node();
                                if (!inputDescripcion) {
                                    container.text("");
                                    inputDescripcion = container.append("input").classed("input-form", true).style("text-align", "center").node();
                                    container.style("width", "100%");
                                }
                                inputDescripcion.value = datum.Descripcion;
                                inputDescripcion.oninput = e => {
                                    datum.Descripcion = inputDescripcion.value;
                                }
                                break;
                            case "Actions":
                                let btnRemove = container.select<HTMLButton2Element>("wc-button").node();
                                if (!btnRemove) {
                                    container.text("");
                                    btnRemove = container.append<HTMLButton2Element>("wc-button").attr("dim", "24")
                                        .property("_SrcIco", UIUtilIconResources.CGeneral.Menos)
                                        .classed("btn_round", true).node();
                                    btnRemove.setAttribute("draggable", false + "");
                                    btnRemove.ondragstart = e => e.stopPropagation();
                                }
                                btnRemove.onclick = (e) => {
                                    e.stopPropagation();
                                    let itemEliminar = datum;
                                    let indexItemReal = playgroundItemsEvaluacionList.findIndex(d => (d.Ids.join("_") == itemEliminar.Ids.join("_")));
                                    if (indexItemReal > -1) {
                                        playgroundItemsEvaluacionList.splice(indexItemReal, 1);
                                        tablaEvaluacionConfig._UpdateData(playgroundItemsEvaluacionList);
                                    } else {
                                        console.warn("-d", "Error al eliminar item");
                                    }
                                }
                                break;
                        }
                    }
                }
            });
            tablaEvaluacionConfig._Control.select(".area_filtros").remove();
            tablaEvaluacionConfig._Control.select(".rhead").select("tr").style("background-color", "var(--color_action1focus)");
            tablaEvaluacionConfig._Control.style("flex", "2").style("min-width", "300px").style("max-height", "300px");
            return tablaEvaluacionConfig;
        } else {
            parent.style("gap", "35px");
            let formEval = parent.append("form").classed("formulario_generator form_modalbase", true).style("gap", "10%").style("min-width", "300px").style("flex", "2");
            let row1 = formEval.append("div").classed("row", true);
            row1.append("label").text(UIUtilLang._GetUIString("materias", "lbl_califmin"));
            inputCalMin = row1.append("div").classed("control_wrapper", true).append("div").classed("input_content", true).append("input").attr("type", "number").classed("input-form", true).node();
            inputCalMin.oninput = (e) => {
                calificacionesList.MinCal.cal = Number(inputCalMin.value);
                inputCalMin.style.border = !inputCalMin.value ? "1px solid var(--color_app_red1)" : null;
            }
            inputCalMin.style.border = !inputCalMin.value ? "1px solid var(--color_app_red1)" : null;

            let row2 = formEval.append("div").classed("row", true);
            row2.append("label").text(UIUtilLang._GetUIString("materias", "lbl_califmax"));
            inputCalMax = row2.append("div").classed("control_wrapper", true).append("div").classed("input_content", true).append("input").attr("type", "number").classed("input-form", true).node();
            inputCalMax.oninput = (e) => {
                calificacionesList.MaxCal.cal = Number(inputCalMax.value);
                inputCalMax.style.border = !inputCalMax.value ? "1px solid var(--color_app_red1)" : null;
            }
            inputCalMax.style.border = !inputCalMax.value ? "1px solid var(--color_app_red1)" : null;

            let row3 = formEval.append("div").classed("row", true);
            row3.append("label").text(UIUtilLang._GetUIString("materias", "lbl_califminaprob"));
            inputCalMinAprob = row3.append("div").classed("control_wrapper", true).append("div").classed("input_content", true).append("input").attr("type", "number").classed("input-form", true).node();
            inputCalMinAprob.oninput = () => {
                calificacionesList.MinAprob = Number(inputCalMinAprob.value);
                inputCalMinAprob.style.border = !inputCalMinAprob.value ? "1px solid var(--color_app_red1)" : null;
            }
            inputCalMinAprob.style.border = !inputCalMinAprob.value ? "1px solid var(--color_app_red1)" : null;
        }
        return tablaEvaluacionConfig;
    }
}
