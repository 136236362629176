import { Entidad } from "../Entidad";
import { Global } from "../Global";
import { DataDRequest } from "../DRequest";
import { DataUtil } from "../util/Util";

import IEscuela = Entidad.IEscuela;
import IAyuda = Entidad.IAyuda;

export const _DiccAyuda: Map<number, IAyuda> = new Map();
const IDREQUEST = Entidad.CTipoRequest.Ayuda;

export async function _GetList(escuelas: IEscuela[]): Promise<DataDRequest.IRequestResponseListA<IAyuda>[]> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "ayuda/ObtenerLista";
    const currentDateStr = await DataUtil._GetMaxDate(IDREQUEST);
    const params = {
        "Modificacion": currentDateStr
    }
    return DataDRequest._Request4List(url, "GET", params, IDREQUEST)
        .then(res => [res])
        .catch(() => []);
}

export function _GetUrlObtenerArchivo(idAyuda: number, tamanio: 1 | 2 | 3) {
    return Global._GLOBAL_CONF.URL_RSTV2 + `ayuda/ObtenerArchivo?IdAyuda=${idAyuda}&Tamanio=${tamanio}`;
}

export function _GetUrlObtenerThumbnail(datoAyuda: Entidad.IAyuda, tamanio: 1 | 2 | 3) {
    return Global._GLOBAL_CONF.URL_RSTV2 + `ayuda/ObtenerImagenMini?IdAyuda=${datoAyuda.Id}&Tamanio=${tamanio}&Modificacion=${datoAyuda.Modificacion}`;
}

export default {
    _DiccAyuda,
    _GetUrlObtenerArchivo,
    _GetUrlObtenerThumbnail
}
