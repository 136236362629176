import { DataDRequest } from "../DRequest";
import { Entidad } from "../Entidad";
import { Global } from "../Global";

export async function _FinanzasObtenerPagosPorRecibirV2(idEscuela: number): Promise<DataDRequest.IRequestResponseA<Entidad.IFinanzaPagoPendiente[]>> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "finanzas/ObtenerPagosPorRecibirV2";
    const params = {
        IdEscuela: idEscuela
    }
    return DataDRequest._Request5(url, "GET", params, { idToKeepPromiseUntilFinished: idEscuela })
}
