import * as d3 from "d3";
import { MainPage } from "../../MainPage";
import { DataDRequest } from "../../data/DRequest";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import { HTMLIconCollapseElement } from "../controlWC/IconTogglerCollapseComponent";
import { UIUtilIconResources } from "../util/IconResourses";
import { UIUtilLang } from "../util/Language";
import { UIUtilPermission } from "../util/Permission";
import { UIUtilGeneral } from "../util/Util";
import { UIUtilViewData } from "../util/ViewData";
import { Button, CBGColor } from "./Button";
import { CardV2 } from "./CardV2";

export type TCARDV2COLL_OnEditOriginEvent = "onCancel" | "onInitEdit" | "onSuccessfullSave";

export abstract class CardV2Collapse<TDataToDoUpdate extends Array<any> = [unknown]> extends CardV2 {
    protected readonly modulo: Entidad.CModulo;
    protected readonly moduloName: string;

    protected cardFooterSelection: TSelectionHTML<"div">;
    private btnCollapser: TSelectionHTML<"wc-ic-collapse">;
    protected btnCancelarCard: Button;
    protected btnEditarCard: Button;
    protected btnGuardarCard: Button;
    private btnSync: TSelectionHTML<"img">;
    private onEditarChange: (editing: boolean) => void;
    private onCardError: (error: boolean, reason: string) => void;

    // private cardHeaderActionsContainerSelection: TSelectionHTML<"div">;

    /** La posisión 0 está reservada para el evento principal */
    // private onCollapseEvts: ((expanded: boolean) => void)[];
    // private onSave: () => void;

    private status_cardEditando: boolean;
    // private status_cardEnableCollapser: boolean;
    private status_cardExpandido: boolean;

    protected cardData: TDataToDoUpdate;
    private variantToValidateUpdate: string;

    // private debugTag: string;
    /** Se ocupa cuando la data se actualiza.
     * - @default false: En éste caso se respeta el estado "Editando", si se está editando, no se actualiza antomáticaente.
     * - true: Se ignora que se está editando para actualizar la UI.
    */
    protected forceUpdateInEditingStatus: boolean;

    constructor(tituloTag: string, modulo: Entidad.CModulo = null, moduloName = (modulo != null ? Entidad.CModulo[modulo] : "")) {
        // >> Valide default module titles
        super();

        if (!tituloTag.includes(" ") && modulo != null && Entidad.CModulo[modulo] == moduloName) {
            tituloTag = (UIUtilViewData._GetStr_Modulo(modulo) || tituloTag);
        }

        this.modulo = modulo;
        this.moduloName = moduloName;
        // this.status_cardEnableCollapser = true;
        this.status_cardExpandido = false;
        this.status_cardEditando = false;
        this.forceUpdateInEditingStatus = false;
        // this.onCollapseEvts = [];
        // this.debugTag = `cardcollapse 🚩 ${this.moduloName} (${tituloTag}) -> `;

        const auxMostrarNotif = this.ctrlNotification._Mostrar.bind(this.ctrlNotification);
        this.ctrlNotification._Mostrar = (msg, tipo, duration) => {
            if (!this.status_cardExpandido) {
                let titulo = this.cardHeaderTitleSelection.text();
                msg = "<b>" + titulo + "</b>\n" + msg;
            }
            auxMostrarNotif(msg, tipo, duration);
            return this.ctrlNotification;
        }

        this.InitCardCollapse(tituloTag);
    }

    private InitCardCollapse(titulo: string) {
        let titleCont = d3.create("div")
            .attr("class", UIUtilGeneral.FBoxOrientation.Horizontal + " " + UIUtilGeneral.FBoxAlign.StartCenter);
        this.cardHeaderSelection.node()
            .insertAdjacentElement("afterbegin", titleCont.node());
        this.btnCollapser = titleCont.append<HTMLIconCollapseElement>("wc-ic-collapse")
            .style("width", "17px");
        titleCont.append(() => this.cardHeaderTitleSelection.node());

        this.cardFooterSelection = d3.create("div")
            .attr("class", "footer");
        this.cardSelection.node()
            .insertAdjacentElement("beforeend", this.cardFooterSelection.node());

        this.cardSelection.classed("card_collapse", true);

        titleCont
            .on("click", () => {
                this.CARDCOLL_UpdateExpandedCard(!this.status_cardExpandido);
            });

        let div_infoerror = this.cardActionsContainerSelection
            .append("div")
            .attr("class", "mark_infoerror hide");

        div_infoerror.append("img")
            .attr("src", UIUtilIconResources.CGeneral.InfoMark)
            .attr("draggable", false);

        div_infoerror.append("wc-tooltip")
            .attr("max-width", "300px");

        this.btnSync = this.cardActionsContainerSelection
            .append("img")
            .attr("class", "btn_sync hide")
            .attr("src", UIUtilIconResources.CGeneral.Sync)
            .attr("draggable", false);

        this.btnEditarCard = new Button(this.cardActionsContainerSelection.node(), UIUtilLang._GetUIString("c_actions", "Editar"), CBGColor.blue);

        // this.met_SetActions([
        //     () => this.btnSync.node(),
        //     () => this.btnSync.node(),
        // ]);

        this.btnEditarCard._d3Selection.classed("btn_edit", true);
        this.btnEditarCard._d3Selection.on("click", () => {
            this.UpdateEditandoCard(true, "onInitEdit");
        });

        this.btnCancelarCard = new Button(this.cardFooterSelection.node(), UIUtilLang._GetUIString("general", "cancelar"), CBGColor.blue);
        this.btnCancelarCard._d3Selection.classed("btn_cancel", true);
        this.btnCancelarCard._d3Selection.on("click", () => {
            this.UpdateEditandoCard(false, "onCancel");
        });
        this.btnGuardarCard = new Button(this.cardFooterSelection.node(), UIUtilLang._GetUIString("general", "save"), CBGColor.blue);

        this.btnGuardarCard._d3Selection.classed("btn_save", true)
        this.btnGuardarCard._d3Selection.on("click", async () => {
            if (this.btnGuardarCard._d3Selection.classed("btn_disable")) return;
            this.ctrlProgress.attr("oculto", false);
            this._SetCardButtonsEnable(false);
            let res = await this.CARDCOLL_GuardarCardV2();
            this.ctrlProgress.attr("oculto", true);
            this._SetCardButtonsEnable(true);

            if (res?.Mensaje) {
                this.ctrlNotification._Mostrar(res.Mensaje, res.Resultado > 0 ? "INFO" : "ADVERTENCIA");
            }

            if (res?.Resultado > 0) {
                this.UpdateEditandoCard(false, "onSuccessfullSave");
            }
        });

        this.CARDCOLL_OnInitBuild(this.cardContentContainerSelection);

        this.UpdateEditandoCard(this.status_cardEditando, (this.status_cardEditando ? "onInitEdit" : "onCancel"));
        this.CARDCOLL_UpdateExpandedCard(this.status_cardExpandido);

        this._SetTitulo(titulo);
    }

    // ****************************************************************************
    // PRIVATE METHODS
    // ****************************************************************************

    private UI_UpdateIconCollapsed() {
        this.btnCollapser.node()._Collapsed = !this.btnCollapser.node()._Collapsed;
        // this.header.Collapser.node().prop_Collapsed = !this.status_cardExpandido;
        return this;
    }

    /** Actualiza evento click y visibilidad del boton para sincronizar datos de card  */
    private UI_SetSyncBtnClickEvent(promise: () => Promise<any>) {
        this.btnSync.classed("hide", !Boolean(promise));

        if (promise) {
            this.btnSync.on("click", async () => {
                this.ctrlProgress.attr("oculto", false);
                this.btnSync.classed("anim_syncing", true);
                await promise();
                this.ctrlProgress.attr("oculto", true);
                this.btnSync.classed("anim_syncing", false);
            });
        } else {
            this.btnSync.on("click", null);
        }
    }

    private DefaultEventToSyncData() {
        const card_ev = this.CARDCOLL_SyncOrGetIdToDownloadData();
        let promise: () => Promise<any>;

        if (typeof card_ev == "number") {
            promise = () => MainPage._ReloadServiceAndAwaitBool(card_ev, this.CARDCOLL_GetIdSchool(...this.cardData));
        }
        else if (Array.isArray(card_ev)) {
            const idEscuela = this.CARDCOLL_GetIdSchool(...this.cardData);

            promise = async () => {
                for (let idReq of card_ev) {
                    await MainPage._ReloadServiceAndAwaitBool(idReq, idEscuela);
                }
            }
        }
        else if (card_ev instanceof Function) {
            promise = card_ev;
        }

        this.UI_SetSyncBtnClickEvent(promise);
    }

    /** @param editar
     * * true: Solo se puede editar si el card está expandido
     * * false: expandido -> any
     * */
    private UpdateEditandoCard(editar: boolean, originEvent: TCARDV2COLL_OnEditOriginEvent) {
        let hasEditPermission = this.HasActionPermission(Entidad.CAccionPermiso.Editar);
        this.cardFooterSelection.classed("hide", (!hasEditPermission));
        this.btnEditarCard._d3Selection.classed("hide", (!hasEditPermission));

        if (hasEditPermission) {
            if (this.status_cardExpandido) {
                this.cardFooterSelection.classed("hide", !editar);
                this.btnEditarCard._d3Selection.classed("hide", editar);
                if (this.status_cardEditando == editar) return;
                this.status_cardEditando = editar;

                this.cardFooterSelection.classed("hide", !editar);
                this.btnEditarCard._d3Selection.classed("hide", editar);

                if (editar) {
                    this.CARDCOLL_OnEditarCard(originEvent);
                    this.UI_SetSyncBtnClickEvent(null);
                } else {
                    this.CARDCOLL_OnCancelaEditarCard(originEvent);
                    this.DefaultEventToSyncData();
                }
                if (this.onEditarChange) this.onEditarChange(editar);
            } else {
                if (!this.status_cardEditando) return
                this.status_cardEditando = false;
                this.cardFooterSelection.classed("hide", true);
                this.btnEditarCard._d3Selection.classed("hide", true);
                if (this.onEditarChange) this.onEditarChange(editar);
            }
        } else if (this.status_cardEditando) {
            this.status_cardEditando = false;
            this.CARDCOLL_OnCancelaEditarCard("onCancel");
            this.DefaultEventToSyncData();
        } else if (editar) {
            this.ctrlNotification._Mostrar("No tienes permisos para editar en esta tarjeta", "ADVERTENCIA");
        }
    }

    // ****************************************************************************
    // PROTECTED METHODS
    // ****************************************************************************

    protected HasActionPermission(action: Entidad.CAccionPermiso): boolean {
        if (this.cardData && this.cardData[0]) {
            const idEscuela = this.CARDCOLL_GetIdSchool(...this.cardData);
            return UIUtilPermission._HasAccionPermission(action, this.modulo, idEscuela);
        }
        return false;
    }

    /** Retorna el string asignado al modulo actual (si aplica) de acuerdo a @param stringKey,
     * Si no se encuentra retorna el mismo parametro
    */
    protected CARDCOLL_GetUIStringModule(stringKey: string): string {
        if (this.moduloName && !stringKey.includes(" ")) {
            return (UIUtilLang._GetUIString(this.moduloName, stringKey) || stringKey);
        }
        return stringKey;
    }

    protected get CARDCOLL_StatusCardEditando() {
        return this.status_cardEditando;
    }

    protected get CARDCOLL_StatusCardExpandido() {
        return this.status_cardExpandido;
    }

    protected get CARDCOLL_CardData() {
        return this.cardData;
    }

    /**
     *
     * @param expandir
     * * true: editando -> any
     * * false: Solo se puede colapsar mientras no se esté editando
     */
    protected CARDCOLL_UpdateExpandedCard(expandir: boolean) {
        if (expandir) {
            this.status_cardExpandido = true;
            // this.met_Expandir();
            this.cardContentContainerSelection.classed("hide", false);
            this.cardHeaderSelection.classed("expandido", true);
            this.btnEditarCard._d3Selection.classed("hide", false);
            this.cardActionsContainerSelection.select(".normal").classed("hide", false);

            this.CARDCOLL_MostrarBody();
            this.DefaultEventToSyncData();

            // this.onCollapseEvts.forEach(fn => {
            //     if (fn) {
            //         fn(true);
            //     }
            // });

            // if (this.ctrlTabla) {
            //     this.ctrlTabla.met_RefreshView();
            // }
        }
        else if (!this.status_cardEditando) {
            this.status_cardExpandido = false;
            // this._Colapsar();
            this.cardContentContainerSelection.classed("hide", true);
            this.cardFooterSelection.classed("hide", true);
            this.cardHeaderSelection.classed("expandido", false);
            // this.btnEditarCard.prop_d3Selection.classed("hide", true);
            this.cardActionsContainerSelection.select(".normal").classed("hide", true);

            // this.header.d3Selection.select(".UI_Button").classed("hide", true);

            this.CARDCOLL_OcultarBody()
            this.UI_SetSyncBtnClickEvent(null);

            // this.onCollapseEvts.forEach(fn => {
            //     if (fn) {
            //         fn(false);
            //     }
            // });
        }

        this.UI_UpdateIconCollapsed();
    }

    /** NOTA: Definir si se va a invocar automáticamente al Cancelar Edición y (Incluye Guardar Exitosamente) */
    protected async CARDCOLL_UpdateCardData(showProgress = false, ...cardData: TDataToDoUpdate) {
        if (showProgress) this.ctrlProgress.attr("oculto", false);
        let promise = this.CARDCOLL_OnUpdateData(...cardData);
        if (promise instanceof Promise) await promise;
        this.btnSync.classed("anim_syncing", false);
        this.ctrlProgress.attr("oculto", true);
        // .classed("hide", true);
        // this.btnSync.on("click", null);
    }

    protected CARDCOLL_UICardHasError(isError: boolean, reason = "") {
        this.cardSelection.classed("card_error", isError);

        this.onCardError(isError, reason);

        let div_infoerror = this.cardActionsContainerSelection.select<HTMLDivElement>(".mark_infoerror")
            .classed("hide", !(isError && Boolean(reason)));
        //     .node();

        if (isError && reason) {
            div_infoerror.select("wc-tooltip")
                .text(reason);
            //     let tooltip = img_infoerror["tooltip_infoerror"] as controlD3.Tooltip<any>;
            //     tooltip.prop_Contenido.firstElementChild.textContent = reason;
        }
    }

    /**
     * @param contentContainer -> this.cardContentContainerSelection
    */
    protected abstract CARDCOLL_OnInitBuild(contentContainer: TSelectionHTML<"div">): void

    /** CardCollapse guarda el valor devuelto y lo compara con la siguiente llamada a _UpdateCardData, si los valores son diferentes,
     * deja que el card actualice su data
     *
     * Se invoca antes de CARDCOLL_OnUpdateData
     *
     * Si siempre retorna false ("", 0, null, undefined), el data del CARD se actualiza con cada _UpdateCardData
     *  */
    protected abstract CARDCOLL_GetVariantToValidateUpdate(...cardData: TDataToDoUpdate): string;

    /** Si el usuario está Editando, muestra un btn para sync y deja en manos del usuario actualizar o no el card.
     *
     * NOTA: NO INVOCAR directamente, usar CARDCOLL_UpdateCardData
    */
    protected abstract CARDCOLL_OnUpdateData(...cardData: TDataToDoUpdate): void | Promise<void>

    /**NOTA: NO INVOCAR directamente, usar CARDCOLL_UpdateExpandedCard(true) */
    protected abstract CARDCOLL_MostrarBody(): void

    /** NOTA: NO INVOCAR directamente, usar CARDCOLL_UpdateExpandedCard(false) */
    protected abstract CARDCOLL_OcultarBody(): void

    /** Se llama cuando:
     * * Se invocar la edición de card
     * * Cuando se cancela la edición del card
     * * Cuando finaliza correctamente el guardado del card
     *
     * NOTA: NO INVOCAR directamente, usar CARDCOLL_UpdateEditandoCard(false | true, originEv)
     * @param originEvent `deprecated`
     * */
    protected abstract CARDCOLL_OnEditarCard(originEvent: TCARDV2COLL_OnEditOriginEvent): void

    protected abstract CARDCOLL_OnCancelaEditarCard(originEvent: TCARDV2COLL_OnEditOriginEvent): void

    protected abstract CARDCOLL_GuardarCardV2(): Promise<DataDRequest.IRequestResponseA<any>>

    /**
     * @returns Promise: La promesa de encarga de descargar directamente los datos,
     * en caso de ser necesario invocar al Actualizador de la tarjeta
     * @returns CTipoRequest: En invocador de la función se encarga de llamar a app.ReloadService(CTipoRequest).
     * Procurar usar Registrar CTipo request en met_OnServiceEvent,
     * en caso de ser necesario invocar al Actualizador de la tarjeta.
     * */
    protected abstract CARDCOLL_SyncOrGetIdToDownloadData(): (() => Promise<any>) | (DataModuloMain.TipoRequestMonitorId | DataModuloMain.TipoRequestMonitorId[]);

    // NOTE Proximamente, se pueden revisar permisos directamente desde ésta clase, Ej:
    // - Editar
    protected abstract CARDCOLL_GetIdSchool(...cardData_0: TDataToDoUpdate): number;

    // ****************************************************************************
    // PUBLIC PROPERTIES
    // ****************************************************************************

    /** Verifica antes de actualizar los datos */
    public _UpdateCardData(forceToUpdate: boolean, ...cardData: TDataToDoUpdate) {
        this.cardData = cardData;
        const variantUpdate = this.CARDCOLL_GetVariantToValidateUpdate(...cardData);
        const fnShowBtnToSync = () => {
            if (this.forceUpdateInEditingStatus) {
                this.CARDCOLL_UpdateCardData(forceToUpdate, ...cardData);
            } else {
                // Mostrar btn reload para sincronizar en estado de edición
                this.UI_SetSyncBtnClickEvent(async () => {
                    this.CARDCOLL_UpdateCardData(true, ...cardData);
                    this.UI_SetSyncBtnClickEvent(null);
                })
            }
        }

        if (this.status_cardEditando) {
            if (forceToUpdate) {
                this.CARDCOLL_UpdateCardData(forceToUpdate, ...cardData);
            } else if (!this.variantToValidateUpdate && !variantUpdate) {
                fnShowBtnToSync();
            } else if (this.variantToValidateUpdate != variantUpdate) {
                fnShowBtnToSync();
            } else {
                // console.debug(this.debugTag + "(No update válid) ", /* data.Entidades.CModulo[this.modulo], */ this.header.Titulo);
                return;
            }
        } else {
            if (forceToUpdate) {
                this.CARDCOLL_UpdateCardData(forceToUpdate, ...cardData);
            } else if (!this.variantToValidateUpdate && !variantUpdate) {
                this.CARDCOLL_UpdateCardData(forceToUpdate, ...cardData);
            } else if (this.variantToValidateUpdate != variantUpdate) {
                this.CARDCOLL_UpdateCardData(forceToUpdate, ...cardData);
            } else {
                // console.debug(this.debugTag + "(No update válid) ", this.header.Titulo);
                return;
            }
        }
        // console.debug(this.debugTag, "(Updated)", (this.variantToValidateUpdate != variantUpdate)) //[this.variantToValidateUpdate, variantUpdate]);
        this.variantToValidateUpdate = variantUpdate;
    }

    /** get */
    get _expanded() { return this.status_cardExpandido; }

    public _OnEditarChange(callback: typeof this.onEditarChange) {
        this.onEditarChange = callback;
        return this;
    }

    public _OnCardError(callback: typeof this.onCardError) {
        this.onCardError = callback;
        return this;
    }
    /** set */
    // set prop_onCollapse(value: (expanded: boolean) => void) {
    //     this.onCollapseEvts[0] = value;
    // }

    // public met_Collapse_AddEventListener(fn: (expanded: boolean) => void) {
    //     this.onCollapseEvts.push(fn);
    // }

    // public met_Collapse_RemoveEventListener(fn: (expanded: boolean) => void) {
    //     let i = this.onCollapseEvts.indexOf(fn);
    //     this.onCollapseEvts.splice(i, 1);
    // }

    // public met_Collapse_ClearListeners() {
    //     this.onCollapseEvts.splice(1, this.onCollapseEvts.length);
    // }

    public _EditarCard() {
        this.UpdateEditandoCard(true, "onInitEdit");
        return this;
    }

    public _CancelarEdicionCard() {
        this.UpdateEditandoCard(false, "onCancel");
        return this;
    }

    // /** @deprecated // FIXME implementar como: met_ForceEnableHeaderClick (habilita el click en el header colapsar/descolapsar a pesar de estar editando) */
    // public met_EnableHeaderClick(enable: boolean) {
    //     this.cardHeaderSelection //.select(".div_header")
    //         .style("pointer-events", (enable ? null : "none"));
    // }

    // public met_IconCollapsedVisible(visible: boolean) {
    //     this.btnCollapser.classed("hide", !visible);
    //     return this;
    // }

    // public met_Destroy(): this {
    //     super.met_Destroy();
    //     return this;
    // }

    public _Expandir(): this {
        this.CARDCOLL_UpdateExpandedCard(true);
        return this;
    }

    public _Colapsar(): this {
        this.UpdateEditandoCard(false, "onCancel");
        this.CARDCOLL_UpdateExpandedCard(false);
        return this;
    }

    /** Habilitar/Desabilitar los botones principales del modal (Editar, Guardar, Cancelar) */
    public _SetCardButtonsEnable(value: boolean) {
        Button._EnableButton(this.btnGuardarCard._d3Selection, value);
        Button._EnableButton(this.btnEditarCard._d3Selection, value);
        Button._EnableButton(this.btnCancelarCard._d3Selection, value);
        return this;
    }
}
