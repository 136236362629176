import { DataDRequest } from "../DRequest";
import { Global } from "../Global";
import { IFacturaCliente, IFacturaClienteAlumno } from "../entidad/Factura";
import { DataUtil } from "../util/Util";

export function _SvFacturaObtenerClientes(idEscuela: number) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "factura/ObtenerClientes"
    const params = {
        "IdEscuela": idEscuela,
    }
    return DataDRequest._Request5<IFacturaCliente[]>(url, "GET", params)
}

export function _SvFacturaObtenerClientesAlumno(idEscuela: number, idAlumno: number) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "factura/ObtenerClientesAlumno"
    const params = {
        "IdEscuela": idEscuela,
        "IdAlumno": idAlumno,
    }
    return DataDRequest._Request5<IFacturaClienteAlumno[]>(url, "GET", params)
}

export function _SvFacturaRegistrarCliente(params: IClienteFacturaRegistrar) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "factura/RegistrarCliente"
    params = <IClienteFacturaRegistrarFinal>{
        ...params,
        "IdUsuario": DataUtil._Usuario.IdUsuario,
    }
    return DataDRequest._Request5<IClienteFacturaRegistrado>(url, "POST", params)
}

export function _SvFacturaAsignarClienteAlumno(idEscuela: number, idAlumno: number, rfc: string, uid: string) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "factura/AsignarClienteAlumno"
    const params = {
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "IdEscuela": idEscuela,
        "IdAlumno": idAlumno,
        "RFC": rfc,
        "UID": uid,
    }
    return DataDRequest._Request5<IClienteFacturaRegistrado>(url, "POST", params)
}

export function _SvFacturaEliminarAsignacionClienteAlumno(idAlumno: number, rfc: string, uid: string) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "factura/EliminarAsignacionClienteAlumno"
    const params = {
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "IdAlumno": idAlumno,
        "RFC": rfc,
        "UID": uid,
    }
    return DataDRequest._Request5(url, "POST", params)
}

interface IClienteFacturaRegistrarFinal extends IClienteFacturaRegistrar {
    IdUsuario: number
}
export interface IClienteFacturaRegistrar {
    IdAlumno: number
    IdEscuela: number
    apellidos: string
    banco: string
    calle: string
    ciudad: string
    codpos: number
    colonia: string
    cuenta: string
    email: string
    email2: string
    email3: string
    estado: string
    localidad: string
    nombre: string
    numero_exterior: 1234
    numero_interior: 567
    numregidtrib: number
    pais: string
    razons: string
    regimen: 603
    rfc: string
    telefono: string
    usocfdi: string
}
type IClienteFacturaRegistrado = Omit<IFacturaCliente, keyof Pick<IFacturaCliente, "Alumnos">>
