import * as d3 from "d3";
import { group as d3Group } from "d3-array";
import { MainPage } from "../../MainPage";
import { DataDRequest } from "../../data/DRequest";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import DataModuloGrupo from "../../data/modulo/Grupo";
import DataModuloMateriaV2, { ISendMateriaUpdt } from "../../data/modulo/MateriaV2";
import { IConfigGridExcelExport, IGridExtraTableConfig, IGridRenderInfo, VentanaGrid } from "../controlD3/AVentanaGrid";
import { ExcelThings } from "../controlD3/ExcelExport";
import { List } from "../controlD3/List";
import { ModalThings } from "../controlD3/ModalThings";
import { Table } from "../controlD3/Tabla";
import { UIUtilLang } from "../util/Language";
import { UIUtilGeneral } from "../util/Util";
import { UIUtilViewData } from "../util/ViewData";
import { UIUtilViewGrupos } from "../utilView/Grupos";
import { UIUtilViewMateria } from "../utilView/Materia";

import IMateria = Entidad.IMateria;
import IEvaluacionConfigItem = Entidad.IEvaluacionConfigItem;

interface IMateriaGrid extends Omit<IMateria, "ConImagen" | "Modificacion" | "EnUso" | "Criterios"> {
    Id: string;
    Materia: IMateria;
    StrNivel: string;
    StrGrado: string;
    StrGrupos: string;
    IdGridGrupos: number[];
    KinderFiltro?: number[];
    NombreKinder: string;
    NombreEval: string;
    NombreFrecuencia: string;
    strCriterios?: string;
    ConfigEval: IEvaluacionConfigItem[];
    Criterios: Entidad.ICriterio[];
};
export class UIVentanaMateria extends VentanaGrid<IMateriaGrid> {
    constructor(content: d3.Selection<HTMLDivElement, undefined, HTMLElement, any>, modulo: Entidad.CModulo) {
        super(content, modulo);
    }

    public _Mostrar(): void {
        super._Mostrar();
        MainPage._ActivarServiceMonitor(Entidad.CTipoRequest.MateriaV2, true);
    }

    public _Destroy(): void {
        super._Destroy();
        MainPage._ActivarServiceMonitor(Entidad.CTipoRequest.MateriaV2, false);
    }

    protected GRID_GetDataRequestID(): DataModuloMain.TipoRequestMonitorId {
        return Entidad.CTipoRequest.MateriaV2;
    }

    protected GRID_GetMenuTopGrid(): Table.ITableMenuTopDefaultOptionConfig[] {
        let objOpcion: Table.ITableMenuTopDefaultOptionConfig[] = [];
        if (this.GridHasPermisoAccionV2(Entidad.CAccionPermiso.Agregar)) {
            objOpcion.push(
                {
                    Label: "action_addmateria",
                    Callback: () => {
                        this.OpenModal_FormularioAddEdit(Entidad.CAccionPermiso.Agregar)
                    }
                }
            )
        }
        return objOpcion;
    }
    protected GRID_GetSelectionDataMenuV2(menuLocation: "row" | "top-selected", dataGridSelected: IMateriaGrid[]): Table.ITableMenuDataSelectedOptionConfig<IMateriaGrid>[] {
        let acciones: Array<Table.ITableMenuDataSelectedOptionConfig<IMateriaGrid>> = []
        if (this.GridHasPermisoAccionV2(Entidad.CAccionPermiso.Editar)) {
            acciones.push({
                Label: "Editar",
                Callback: async (datos: Array<IMateriaGrid>) => {
                    let materia: UIUtilViewMateria.IMateriaForm;
                    materia = { ...datos[0].Materia, Escuelas: [datos[0].IdEscuela], Niveles: [], Grados: [], UsaCriterios: (datos[0].UsaCriterios) ? true : false, Criterios: datos[0].Criterios, ConfiguracionEval: datos[0].ConfigEval, idsMaterias: datos.map(d => d.IdMateria) }
                    if (datos.length > 1) {
                        let gruposAux: number[] = [];
                        datos.forEach(d => {
                            d.IdsGrupos.forEach(d => {
                                if (gruposAux.indexOf(d) == -1) gruposAux.push(d);
                            });
                        });
                        materia.IdsGrupos = gruposAux;
                    }
                    this.OpenModal_FormularioAddEdit(Entidad.CAccionPermiso.Editar, materia);
                },
                GetDetails: (materias) => {
                    let enabled = this.AllMateriasValidToEdit(materias);
                    return {
                        Enabled: enabled.enable,
                        Description: enabled.message
                    }
                }
            })
        }
        if (this.GridHasPermisoAccionV2(Entidad.CAccionPermiso.Eliminar)) {
            acciones.push({
                Label: "Eliminar",
                Callback: (datos: Array<IMateriaGrid>) => {
                    this.OpenModal_DeleteData(datos);
                }
            })
        }
        //if (this.GridHasPermisoAccionV2(Entidad.CAccionPermiso.Eliminar)) {
        if (1 > 2) {
            acciones.push({
                Label: "Remover asignación",
                Callback: (datos: Array<IMateriaGrid>) => {
                    console.log("Remover Asignación");
                }
            })
        }
        //}
        return acciones;
    }
    protected GRID_GetFilters(): Table.IParametroFiltro<IMateriaGrid>[] {
        return [
            { Field: "Nombre", Label: "" },
            { Field: "TipoEvaluacion", Label: "", Type: "select", Options: UIUtilViewData._GetList_TipoEvaluacionMateria() },
            { Field: "FrecuenciaEval", Label: "", Type: "select", Options: UIUtilViewData._GetList_TipoFrecuenciaEval() },
            {
                Field: "StrGrupos", Label: "", Type: "text", OnGetValueToMatch: (dato: IMateriaGrid) => {
                    return dato?.IdsGrupos.map(d => DataModuloMain._GetDataValueFieldByName("Grupo", d, "Nombre"));
                }
            },
            { Field: "StrGrado", Label: "" },
            { Field: "StrNivel", Label: "" },
        ];
    }
    protected GRID_GetTableConfigBase(): IGridRenderInfo<IMateriaGrid> {
        return {
            IdTabla: "CalificacionesMaterias",
            Title: "",
            DefaultSort: "Nombre",
            IdData: "Id",
            Columns: [
                { Field: "Nombre", Label: "", MinWidth: "75px", Width: "20%" },
                { Field: "NombreEval", Label: "", MinWidth: "90px", Width: "15%" },
                { Field: "NombreFrecuencia", Label: "", MinWidth: "90px", Width: "15%" },
                { Field: "StrGrupos", Label: "", MinWidth: "75px", Width: "25%", },
                { Field: "StrGrado", Label: "", MinWidth: "75px", Width: "15%" },
                { Field: "StrNivel", Label: "", MinWidth: "75px", Width: "15%" },
                { Field: "NombreKinder", Label: "Escuela", MinWidth: "75px", Width: "20%" }
            ]
        }
    }
    protected GRID_GetTableConfigAdvanced(): IGridExtraTableConfig<IMateriaGrid> {
        return {
            EvaluatorAndSubLevelsBuild: {
                OnStepRowTable: (datum, tblRow, rowBody) => {
                    if (!datum.UsaCriterios || !datum.Criterios.length) {
                        let criteriosLine = rowBody.select<HTMLTableRowElement>(".criteriosLine");
                        if (criteriosLine.node()) {
                            criteriosLine.remove();
                        }
                        return;
                    };
                    let isCollapsed = rowBody.select(":scope>.principal").select(".collapsercell").select("wc-ic-collapse")?.attr("collapsed");
                    let criteriosLine = rowBody.select<HTMLTableRowElement>(".criteriosLine");
                    if (!criteriosLine.node()) {
                        criteriosLine = rowBody.append("tr").classed("criteriosLine principal", true).classed("hide", (isCollapsed == "true"));
                        criteriosLine.append("td").classed("actioncell checkcell", true);
                        criteriosLine.append("td").classed("actioncell collapsercell", true);
                        criteriosLine.append("td").classed("field", true).append("div").text("Criterios").style("font-weight", "bold");
                        criteriosLine.append("td").classed("lastcell", true);
                    }
                    criteriosLine.style("background-color", "var(--color_primary3)").style("height", "35px");
                    tblRow.node().insertAdjacentElement("afterend", criteriosLine.node())
                },
                OnStepCellTable: (container, datum, field: keyof IMateriaGrid) => {
                    if (field == "StrGrupos") {
                        if (datum.IdsGrupos) UIUtilGeneral._CreaElementosLinkeablesV2({
                            Container: container,
                            Data: datum.IdsGrupos.map(d => DataModuloMain._GetItemDataById(Entidad.CTipoRequest.Grupo, d)).filter(d => Boolean(d)),
                            Path: "escuelas/grupos/panel",
                            GetTag: (d) => UIUtilViewGrupos._GetLblGrupoName(d, true, false),
                            GetId: d => d?.IdGrupo
                        })
                    }
                },
                OnCollapserChange: (isCollapsed, dato, rowBodyParent) => {
                    d3.select(rowBodyParent.node().parentElement).select(".criteriosLine").classed("hide", isCollapsed);
                },
                EvaluatorSubLevel: <Table.IStepSubLevelEvaluator<IMateriaGrid, Entidad.ICriterio, any>>{
                    IdMember: "Id",
                    OnGetData: (dato) => {
                        if (!dato.UsaCriterios) return null;
                        return dato.Criterios
                    },
                    OnStepCellTable: (container, dato, field) => {
                        if (field == "Nombre") {
                            d3.select(container.node().parentElement).classed(field, false)
                            container.text(dato.Descripcion);
                        } else {
                            container.node().parentElement.remove();
                        }
                    },
                    RemoveLevelCheckboxes: true,
                },
            },
        };
    }
    protected GRID_GetExportarConfig(dataGrid: IMateriaGrid[]): IConfigGridExcelExport<IMateriaGrid> {
        let columnsConfig: ExcelThings.IColumnToExcelExportFileConfig<any>[];
        columnsConfig = this.ctrlTabla._InfoColumns.map(d => ({ Field: d.Field, HeaderTag: d.Label, WidthCell: 25 }));
        columnsConfig.push({ Field: "strCriterios", HeaderTag: "Criterios", WidthCell: 50 });
        return {
            IdsEscuelas: [...new Set(dataGrid.map(d => d.IdEscuela))],
            ColumnsConfig: columnsConfig,
            OnGetDataBySheets: async () => {
                const materiasAux = dataGrid.map(d => UIUtilGeneral._ObjectClone(d));
                for (const materia of materiasAux) {
                    const criterios = materia.Criterios;
                    if (!materia.UsaCriterios) {
                        materia.strCriterios = "";
                    } else {
                        materia.strCriterios = criterios.reduce((list, current, index, arr) => {
                            list += "•" + current.Descripcion + ((index == (arr.length - 1)) ? "" : "\n\n");
                            return list;
                        }, '');
                    }
                }
                return Array.from(d3Group(materiasAux, d => d.IdEscuela))
                    .map<ExcelThings.ISheetConfig<IMateriaGrid>>(([idEscuela, materias]) => ({
                        IdSheet: idEscuela,
                        SheetName: materias[0].NombreKinder,
                        Data: materias
                    }));
            },
            OnGetEscuelasTagInSheet: (datos) => datos[0].NombreKinder
        };
    }

    protected async GridGetData(): Promise<IMateriaGrid[]> {
        let dataResult: IMateriaGrid[] = [];
        await super.GridGetData()
            .then(dataBase => {
                (dataBase as any as IMateria[]).forEach(materia => {
                    dataResult.push({
                        ...materia,
                        ... {
                            Id: materia.IdMateria + "",
                            Materia: materia,
                            StrNivel: (materia.IdEscolaridad) ? DataModuloMain._GetDataValueFieldByName("Escolaridad", materia.IdEscolaridad, "Nombre") : null,
                            StrGrado: (materia.IdGrado) ? DataModuloMain._GetDataValueFieldByName("Grado", materia.IdGrado, "Nombre") : null,
                            StrGrupos: (materia.IdsGrupos) ? materia.IdsGrupos.map(d => DataModuloMain._GetDataValueFieldByName("Grupo", d, "Nombre")).sort().join(", ") : null,
                            IdGridGrupos: materia.IdsGrupos || [],
                            KinderFiltro: [materia.IdEscuela],
                            NombreKinder: DataModuloMain._GetDataValueFieldByName("Escuela", materia.IdEscuela, "Nombre"),
                            NombreEval: this.GetTipoEvalKey(materia.TipoEvaluacion),
                            NombreFrecuencia: this.GetFrecuenciaKey(materia.FrecuenciaEval),
                            ConfigEval: materia.EvalValor.map((valor, index) => ({ Nombre: valor, Descripcion: materia.EvalDescripcion[index], Valor: (index + 1), Id: (index + 1), IdMateria: materia.IdMateria, TipoEvaluacion: materia.TipoEvaluacion })),
                            Criterios: materia.Criterios.map((valor, index) => ({ Descripcion: valor, IdMateria: materia.IdMateria, Id: (index + 1), Orden: (index + 1) }))
                        }
                    })
                });
            })
        return dataResult;
    }

    // DOTEST CUANDO QUEDE SERVICIO PARA EDITAR
    private AllMateriasValidToEdit(materias: IMateriaGrid[]) {
        //No son de la misma escuela
        if (!materias.every(d => materias[0].IdEscuela == d.IdEscuela)) {
            return {
                enable: false,
                message: this.VB_GetUIStringModule("mssg_notsameschool")
            }
        }

        //No comparten el mismo nombre
        if (!materias.every(d => materias[0].Nombre == d.Nombre)) {
            return {
                enable: false,
                message: this.VB_GetUIStringModule("mssg_notsamename")
            }
        }

        //No comparten la misma periodicidad de evaluación
        if (
            !materias.every(d => materias[0].FrecuenciaEval == d.FrecuenciaEval) ||
            !materias.every(d => materias[0].BloqueCalificarInicio == d.BloqueCalificarInicio) ||
            !materias.every(d => materias[0].DiaCalificarInicio == d.DiaCalificarInicio) ||
            !materias.every(d => materias[0].DiasCalificar == d.DiasCalificar)
        ) {
            return {
                enable: false,
                message: this.VB_GetUIStringModule("mssg_notsameevaltime")
            }
        }

        //No comparten el mismo tipo de evaluación
        if (!materias.every(d => materias[0].TipoEvaluacion == d.TipoEvaluacion)) {
            return {
                enable: false,
                message: this.VB_GetUIStringModule("mssg_notsameevaltype")
            }
        }

        //No comparten EvalConfig
        let arrEvalConfigMultiCompare = materias.map(d => d.ConfigEval.map(d => ({ Nombre: d.Nombre, Descripcion: d.Descripcion })));
        if (!this.AreObjectArraysEqual(...arrEvalConfigMultiCompare)) {
            return {
                enable: false,
                message: this.VB_GetUIStringModule("mssg_notsameevalconfig")
            }
        }

        //No comparten el uso de criterios
        if (!materias.every(d => materias[0].UsaCriterios == d.UsaCriterios)) {
            return {
                enable: false,
                message: this.VB_GetUIStringModule("mssg_notsamecriteriosussg")
            }
        }

        //No comparten criterios
        let arrCriteriosMultiCompare = materias.map(d => d.Criterios.map(dC => dC.Descripcion));
        if (!this.AreObjectArraysEqual(...arrCriteriosMultiCompare)) {
            return {
                enable: false,
                message: this.VB_GetUIStringModule("mssg_notsamenacriteriosconfig")
            }
        }

        return {
            enable: true,
            message: ""
        }
    }

    private GetTipoEvalKey = (tipoEval: Entidad.CTipoEvaluacion) => {
        const keys = Object.keys(Entidad.CTipoEvaluacion) as Array<keyof typeof Entidad.CTipoEvaluacion>
        if (tipoEval == Entidad.CTipoEvaluacion.Numeros) return "Números";
        for (const key of keys) {
            if (Entidad.CTipoEvaluacion[key] == tipoEval) {
                return key;
            }
        }
        return null;
    }

    private GetFrecuenciaKey = (tipoFrec: Entidad.CMateriaFrecuenciaEvaluacion) => {
        const keys = Object.keys(Entidad.CMateriaFrecuenciaEvaluacion) as Array<keyof typeof Entidad.CMateriaFrecuenciaEvaluacion>
        for (const key of keys) {
            if (Entidad.CMateriaFrecuenciaEvaluacion[key] == tipoFrec) {
                return key;
            }
        }
        return null;
    }

    private OpenModal_FormularioAddEdit(action: (Entidad.CAccionPermiso.Agregar | Entidad.CAccionPermiso.Editar), materia = <UIUtilViewMateria.IMateriaForm>{}) {
        let tablaEvaluacion: Table.Tabla<UIUtilViewMateria.ITipoEvalGrid>;
        let tablaCriterios: Table.Tabla<UIUtilViewMateria.ICriterioGrid>;
        let evalForm: Evalform;
        let criteriosItemList: UIUtilViewMateria.ICriterioGrid[];
        let evaluacionItemList: UIUtilViewMateria.ITipoEvalGrid[];
        let originalCriteriosItemList: UIUtilViewMateria.ICriterioGrid[] = [];
        let originalEvaluacionItemList: UIUtilViewMateria.ITipoEvalGrid[] = [];
        let originalGruposIds: number[] = materia?.IdsGrupos || [];
        let originUtilCriterios: UIUtilViewMateria.ICriterioGrid[] = [];
        let originUtilEvalConfg: UIUtilViewMateria.ITipoEvalGrid[] = [];
        let calificacionesList: UIUtilViewMateria.ICalificaciones;

        interface Evalform {
            inputCalMin: HTMLInputElement;
            inputCalMax: HTMLInputElement;
            inputCalMinAprob: HTMLInputElement;
        }

        this.GridOpenModal_ActionFormToAGridData({
            Width: 1000,
            Height: "800px",
            Action: action,
            IdsEscuelas: (action == Entidad.CAccionPermiso.Editar) ? [materia.IdEscuela] : null,
            GetForm: () => {
                let formComplete = UIUtilViewMateria._GetMateriaForm(action, materia);
                evalForm = formComplete.formNums;
                tablaEvaluacion = formComplete.tablaEvaluacion;
                tablaCriterios = formComplete.tablaCriterios;
                criteriosItemList = formComplete.playgroundItemsCriteriosList;
                evaluacionItemList = formComplete.playgroundItemsEvaluacionList;
                calificacionesList = formComplete.calificacionesList;
                originUtilCriterios = formComplete.playgroundItemsCriteriosOriginal;
                originUtilEvalConfg = formComplete.playgroundEvaluacionOriginal;
                return formComplete.form;
            },
            DrawContent: async (container, form, mt) => {
                form._Form.style("height", "100%");
                if (action == Entidad.CAccionPermiso.Editar && materia.idsMaterias.length > 1) {
                    mt.Progress.attr("oculto", false);
                    mt.Modal._DeshabilitarBtns();
                    let criteriosLista = materia?.Criterios;
                    criteriosLista.forEach((d, i) => {
                        criteriosItemList.push({
                            Actions: "",
                            Descripcion: d.Descripcion,
                            Id: d.Id,
                            Ids: [d.Id],
                            Orden: d.Orden
                        })
                    })

                    originalCriteriosItemList = criteriosItemList.map(d => {
                        const dd = Object.assign({}, d);
                        return dd;
                    });

                    originalCriteriosItemList.forEach(d => {
                        originUtilCriterios.push({ ...d });
                    });

                    tablaCriterios._UpdateData(criteriosItemList);

                    let evalConfigItemsLista = materia.ConfiguracionEval;
                    evalConfigItemsLista.forEach((d, i) => {
                        evaluacionItemList.push({
                            Actions: "",
                            Nombre: d.Nombre,
                            Descripcion: d.Descripcion,
                            Valor: d.Valor,
                            Id: d.Id,
                            Ids: [d.Id],
                            Orden: d.Valor,
                            IdMateria: d.IdMateria,
                            TipoEvaluacion: d.TipoEvaluacion
                        });
                    });

                    originalEvaluacionItemList = evaluacionItemList.map(d => {
                        const dd = Object.assign({}, d);
                        return dd;
                    });

                    originalEvaluacionItemList.forEach(d => {
                        originUtilEvalConfg.push({ ...d });
                    });

                    mt.Progress.attr("oculto", true);
                    mt.Modal._HabilitarBtns();
                } else if (action == Entidad.CAccionPermiso.Editar) {
                    mt.Progress.attr("oculto", false);
                    mt.Modal._DeshabilitarBtns();
                    let criteriosLista = materia?.Criterios;
                    criteriosLista.forEach((d, i) => {
                        criteriosItemList.push({
                            Actions: "",
                            Descripcion: d.Descripcion,
                            Id: d.Id,
                            Ids: [d.Id],
                            Orden: d.Orden
                        })
                    })

                    originalCriteriosItemList = criteriosItemList.map(d => {
                        const dd = Object.assign({}, d);
                        return dd;
                    });

                    originalCriteriosItemList.forEach(d => {
                        originUtilCriterios.push({ ...d });
                    })

                    tablaCriterios._UpdateData(criteriosItemList);

                    let evalConfigItemsLista = materia.ConfiguracionEval;
                    evalConfigItemsLista.forEach((d, i) => {
                        evaluacionItemList.push({
                            Actions: "",
                            Nombre: d.Nombre,
                            Descripcion: d.Descripcion,
                            Valor: d.Valor,
                            Id: d.Id,
                            Ids: [d.Id],
                            Orden: d.Valor,
                            IdMateria: d.IdMateria,
                            TipoEvaluacion: d.TipoEvaluacion
                        });
                    });

                    originalEvaluacionItemList = evaluacionItemList.map(d => {
                        const dd = Object.assign({}, d);
                        return dd;
                    })
                    originalEvaluacionItemList.forEach(d => {
                        originUtilEvalConfg.push({ ...d });
                    })

                    mt.Progress.attr("oculto", true);
                    mt.Modal._HabilitarBtns();
                }
                if (evaluacionItemList && !evaluacionItemList.length) {
                    evaluacionItemList.push({ Ids: [-1], Id: -1, Nombre: "", Descripcion: "", Actions: "", Orden: null, Valor: null, IdMateria: null, TipoEvaluacion: null });
                }
                if (materia.TipoEvaluacion == Entidad.CTipoEvaluacion.Numeros) {
                    calificacionesList.MinCal.cal = Number(evaluacionItemList[0].Nombre);
                    calificacionesList.MinCal.id = evaluacionItemList[0].Id;
                    evalForm.inputCalMin.value = evaluacionItemList[0].Nombre;
                    evalForm.inputCalMin.style.border = !evalForm.inputCalMin.value ? "1px solid var(--color_app_red1)" : null;
                    calificacionesList.MaxCal.cal = Number(evaluacionItemList[1].Nombre);
                    calificacionesList.MaxCal.id = evaluacionItemList[1].Id;
                    evalForm.inputCalMax.value = evaluacionItemList[1].Nombre;
                    evalForm.inputCalMax.style.border = !evalForm.inputCalMax.value ? "1px solid var(--color_app_red1)" : null;
                    calificacionesList.MinAprob = materia.MinimoAprobar;
                    evalForm.inputCalMinAprob.value = materia.MinimoAprobar.toString();
                    evalForm.inputCalMinAprob.style.border = !evalForm.inputCalMinAprob.value ? "1px solid var(--color_app_red1)" : null;
                }
                tablaEvaluacion?._UpdateData(evaluacionItemList);
            },
            OnAccept: async (form, modalThings) => {
                let configTimeEval = UIUtilViewMateria._GetTimeEvalConfig();
                let dataTablaEval = UIUtilViewMateria._GetTablaEvalData();
                let dataTablaCriterios = tablaCriterios?._data;
                dataTablaEval.forEach(d => {
                    d.Nombre = d.Nombre.trim();
                    d.Descripcion = d.Descripcion.trim();
                })
                dataTablaCriterios.forEach(d => {
                    d.Descripcion = d.Descripcion.trim();
                })
                let gruposAdded: number[] = [];
                let itemsCriteriosAddedEdited: UIUtilViewMateria.ICriterioGrid[] = [];
                let itemsEvalAddedEdited: UIUtilViewMateria.ITipoEvalGrid[] = [];
                let itemsEvalDeleted: number[] = [];
                let itemsCriteriosDeleted: number[] = [];

                interface IEvalConfigCompare {
                    Nombre: string;
                    Descripcion: string;
                }

                //Not For MultiEdit
                gruposAdded = form._Data.IdsGrupos.filter(gA => {
                    let grupo = originalGruposIds.find(gB => (gB == gA))
                    return !Boolean(grupo);
                })
                //Not For MultiEdit
                let gruposDeleted = originalGruposIds.filter(gA => {
                    let grupo = form._Data.IdsGrupos.find(gB => (gB == gA));
                    return !Boolean(grupo)
                })

                if (action == Entidad.CAccionPermiso.Editar && materia.idsMaterias.length == 1) {
                    //Se evalua la edicion de una materia para preprocesar
                    //La tabla tiene data
                    if (dataTablaEval) {
                        dataTablaEval.forEach((d, i) => {
                            d.Valor = (i + 1);
                            itemsEvalAddedEdited.push(d);
                        })
                    }

                    //El original era tipoEval Numeros y el actual también por tanto solo se está editando en el form de evaluacion
                    if (action == Entidad.CAccionPermiso.Editar && materia.TipoEvaluacion == Entidad.CTipoEvaluacion.Numeros && form._Data.TipoEvaluacion == Entidad.CTipoEvaluacion.Numeros) {
                        itemsEvalAddedEdited.push({ Ids: [calificacionesList.MinCal.id], Id: calificacionesList.MinCal.id, Nombre: calificacionesList.MinCal.cal.toString(), Descripcion: "", Valor: 1, Actions: null, Orden: 1 });
                        itemsEvalAddedEdited.push({ Ids: [calificacionesList.MaxCal.id], Id: calificacionesList.MaxCal.id, Nombre: calificacionesList.MaxCal.cal.toString(), Descripcion: "", Valor: 2, Actions: null, Orden: 2 })
                    }

                    //Se cambia de !Numeros a Numeros
                    if (action == Entidad.CAccionPermiso.Editar && materia.TipoEvaluacion != Entidad.CTipoEvaluacion.Numeros && form._Data.TipoEvaluacion == Entidad.CTipoEvaluacion.Numeros) {
                        itemsEvalAddedEdited.push({ Ids: [calificacionesList.MinCal.id], Id: calificacionesList.MinCal.id, Nombre: calificacionesList.MinCal.cal.toString(), Descripcion: "", Valor: 1, Actions: null, Orden: 1 });
                        itemsEvalAddedEdited.push({ Ids: [calificacionesList.MaxCal.id], Id: calificacionesList.MaxCal.id, Nombre: calificacionesList.MaxCal.cal.toString(), Descripcion: "", Valor: 2, Actions: null, Orden: 2 })
                    }

                    //se cambió de Numeros a otro tipo de evaluacion
                    if (materia.TipoEvaluacion == Entidad.CTipoEvaluacion.Numeros) { }

                    /* if (dataTablaEval) {
                        dataTablaEval
                            .forEach((d, i) => {
                                d.Valor = (i + 1);
                                if (d.Id < 0) {
                                    itemsEvalAddedEdited.push(d)
                                } else {
                                    let itemO = originalEvaluacionItemList.find(dA => (dA.Id == d.Id));
                                    if (itemO.Valor !== d.Valor || itemO.Nombre !== d.Nombre || itemO.Descripcion !== d.Descripcion) {
                                        itemsEvalAddedEdited.push(d);
                                    }
                                }
                            });
                        itemsEvalDeleted = originalEvaluacionItemList.filter(dA => {
                            let itemFound = dataTablaEval.find(dB => (dB.Id == dA.Id));
                            return !Boolean(itemFound);
                        }).map(d => d.Id);
                    } */

                    //El original era tipoEval Numeros y el actual también por tanto solo se está editando en el form de evaluacion
                    /* if (action == Entidad.CAccionPermiso.Editar && materia.TipoEvaluacion == Entidad.CTipoEvaluacion.Numeros && form.prop_Data.TipoEvaluacion == Entidad.CTipoEvaluacion.Numeros) {
                        if (originalEvaluacionItemList[0].Descripcion != calificacionesList.MinCal.cal.toString()) {
                            itemsEvalAddedEdited.push({ Ids: [calificacionesList.MinCal.id], Id: calificacionesList.MinCal.id, Nombre: "Calificación Minima", Descripcion: calificacionesList.MinCal.cal.toString(), Valor: 1, Actions: null, Orden: 1 })
                        }
                        if (originalEvaluacionItemList[1].Descripcion != calificacionesList.MaxCal.cal.toString()) {
                            itemsEvalAddedEdited.push({ Ids: [calificacionesList.MaxCal.id], Id: calificacionesList.MaxCal.id, Nombre: "Calificación Máxima", Descripcion: calificacionesList.MaxCal.cal.toString(), Valor: 2, Actions: null, Orden: 2 })
                        }
                    } */

                    //Se cambia de !Numeros a Numeros
                    /* if (action == Entidad.CAccionPermiso.Editar && materia.TipoEvaluacion != Entidad.CTipoEvaluacion.Numeros && form.prop_Data.TipoEvaluacion == Entidad.CTipoEvaluacion.Numeros) {
                        itemsEvalAddedEdited.push({ Ids: [calificacionesList.MinCal.id], Id: calificacionesList.MinCal.id, Nombre: "Calificación Minima", Descripcion: calificacionesList.MinCal.cal.toString(), Valor: 1, Actions: null, Orden: 1 });
                        itemsEvalAddedEdited.push({ Ids: [calificacionesList.MaxCal.id], Id: calificacionesList.MaxCal.id, Nombre: "Calificación Máxima", Descripcion: calificacionesList.MaxCal.cal.toString(), Valor: 2, Actions: null, Orden: 2 })
                        itemsEvalDeleted = originalEvaluacionItemList.map(d => d.Id);
                    } */

                    //se cambió de Numeros a otro tipo de evaluacion
                    //if (materia.TipoEvaluacion == Entidad.CTipoEvaluacion.Numeros) { }

                    dataTablaCriterios.forEach((d, i) => {
                        d.Orden = (i + 1);
                        itemsCriteriosAddedEdited.push(d);
                    });



                    /* dataTablaCriterios.forEach((d, i) => {
                        d.Orden = (i + 1);
                        if (d.Id < 0) {
                            itemsCriteriosAddedEdited.push(d);
                        } else {
                            let itemO = originalCriteriosItemList.find(dA => (dA.Id == d.Id));
                            if (itemO.Orden !== d.Orden || itemO.Descripcion !== d.Descripcion) {
                                itemsCriteriosAddedEdited.push(d);
                            }
                        }
                    })
 
                    itemsCriteriosDeleted = originalCriteriosItemList.filter(dA => {
                        let itemFound = dataTablaCriterios.find(dB => (dB.Id == dA.Id));
                        return !Boolean(itemFound);
                    }).map(d => d.Id); */
                }

                if (action == Entidad.CAccionPermiso.Agregar) { //Agrega materias => Una o +
                    materia = {
                        ...materia,
                        Foto: form._Data.Imagen as unknown as File,
                        Nombre: form._Data.Nombre,
                        IdsGrupos: form._Data.IdsGrupos,
                        Escuelas: form._Data.Escuelas,
                        TipoEvaluacion: form._Data.TipoEvaluacion,
                        MinimoAprobar: (form._Data.TipoEvaluacion == Entidad.CTipoEvaluacion.Numeros) ? calificacionesList.MinAprob || 0 : 0,
                        UsaCriterios: form._Data.UsaCriterios,
                        Criterios: form._Data.UsaCriterios ? dataTablaCriterios.map(d => ({ Descripcion: d.Descripcion })) : [],
                        FrecuenciaEval: form._Data.FrecuenciaEval || 9,
                        BloqueCalificarInicio: configTimeEval.BloqueCalificarInicio || 1,
                        DiaCalificarInicio: configTimeEval.DiaCalificarInicio || 1,
                        DiasCalificar: configTimeEval.DiasCalificar || 1,
                    }
                    if (materia.TipoEvaluacion !== Entidad.CTipoEvaluacion.Numeros) {
                        materia.ConfiguracionEval = dataTablaEval.map(d => ({ Nombre: d.Nombre, Descripcion: d.Descripcion, Valor: d.Valor }));
                    } else {
                        materia.ConfiguracionEval = [{ Nombre: calificacionesList.MinCal.cal.toString(), Descripcion: "", Valor: 1 }, { Nombre: calificacionesList.MaxCal.cal.toString(), Descripcion: "", Valor: 2 }]
                    }
                    let matGrupos = materia.IdsGrupos
                        .map(idGrupo => DataModuloMain._GetItemDataByName("Grupo", idGrupo))
                        .filter(d => Boolean(d));
                    let materiasToAdd: UIUtilViewMateria.IMateriaForm[] = [];
                    d3Group(matGrupos, d => d.IdNivel)
                        .forEach((grupos, idGrado) => {
                            let materiaAux: UIUtilViewMateria.IMateriaForm = { ...materia };
                            materiaAux.IdEscuela = grupos[0].IdKinder;
                            const idG = grupos.map(d => d.IdGrupo);
                            materiaAux.IdEscolaridad = grupos[0].IdEscolaridad;
                            materiaAux.IdGrado = idGrado;
                            materiaAux.IdsGrupos = idG;
                            materiasToAdd.push(materiaAux);
                        });
                    let errorItems: UIUtilViewMateria.IMateriaForm[] = [];
                    for (const materia of materiasToAdd) {
                        let res = await DataModuloMateriaV2._AltaMateria(materia);
                        if (res.Resultado < 0) {
                            materia["res"] = res;
                            errorItems.push(materia);
                        }
                    }
                    if (errorItems.length > 0) {
                        ModalThings._GetModalInfoDataList<UIUtilViewMateria.IMateriaForm>({
                            Title: UIUtilLang._GetUIString("general", "failresume"),
                            InfoText: this.VB_GetUIStringModule("fail_materiaregister"),
                            DataList: errorItems,
                            Width: 340,
                            OnStepItemData: (container, itemData) => {
                                container.style("display", "grid")
                                container.append("b").text(DataModuloMain._GetDataValueFieldByName("Grado", itemData.IdGrado, "Nombre"));
                                container.append("label").text(this.VB_GetUIStringModule("d_field_strnivel") + ": " + DataModuloMain._GetDataValueFieldByName("Escolaridad", itemData.IdEscolaridad, "Nombre")).style("font-size", "var(--fontsize_me2)");
                                container.append("label").text(this.VB_GetUIStringModule("d_field_strescuela") + ": " + DataModuloMain._GetDataValueFieldByName("Escuela", itemData.IdEscuela, "Nombre")).style("font-size", "var(--fontsize_me4)");
                                container.append("i")
                                    .text(UIUtilLang._GetHTTPMessage({
                                        TipoRequest: (itemData["res"] as DataDRequest.IRequestResponseA<undefined>).TipoRequest,
                                        Resultado: (itemData["res"] as DataDRequest.IRequestResponseA<undefined>).Resultado,
                                        EndPointName: "materia/NuevaMateria"
                                    }))
                                    .style("font-size", "var(--fontsize_me2)").style("color", "red");
                            },
                            OnClose: () => {
                                if (materiasToAdd.length > errorItems.length) {
                                    MainPage._ReloadService(this.GRID_GetDataRequestID(), materia.Escuelas, (reqID, reloadId, error) => {
                                        if (error !== null) {
                                            this.notificacion._Mostrar(UIUtilLang._GetUIString("general", "notif_fail_infoupdate"), "ADVERTENCIA");
                                        }
                                    });
                                }
                                modalThings.Modal._Ocultar();
                            }
                        })
                    } else {
                        this.ctrlProgressBar.attr("oculto", false);
                        this.notificacion._Mostrar(UIUtilLang._GetHTTPMessage({ TipoRequest: Entidad.CTipoRequest.MateriaV2, Resultado: 1, EndPointName: "materia/NuevaMateria" }), "INFO");
                        MainPage._ReloadService(this.GRID_GetDataRequestID(), materia.Escuelas, (reqID, reloadId, error) => {
                            if (error !== null) {
                                this.notificacion._Mostrar(UIUtilLang._GetUIString("general", "notif_fail_infoupdate"), "ADVERTENCIA");
                            }
                        });
                        modalThings.Modal._Ocultar();
                    }
                    return null;
                } else if (action == Entidad.CAccionPermiso.Editar && materia.idsMaterias.length == 1) { //Editar una materia
                    let configEval: Entidad.IEvaluacionConfigItem[] = itemsEvalAddedEdited.map(d => {
                        if (d.Id > 0) {
                            return { Id: d.Id, Nombre: d.Nombre, Descripcion: d.Descripcion, Valor: d.Valor }
                        } else {
                            return { Nombre: d.Nombre, Descripcion: d.Descripcion, Valor: d.Valor }
                        }
                    });

                    let configCriterios: Entidad.ICriterio[] = itemsCriteriosAddedEdited.map(d => {
                        if (d.Id > 0) {
                            return { Id: d.Id, Descripcion: d.Descripcion, Orden: d.Orden }
                        } else {
                            return { Descripcion: d.Descripcion, Orden: d.Orden }
                        }
                    });
                    let configChanged: boolean = false;
                    configEval.sort((a, b) => (a.Valor - b.Valor));
                    configCriterios.sort((a, b) => (a.Orden - b.Orden));

                    let configEvalCompare: IEvalConfigCompare[][] = [];
                    configEvalCompare.push(configEval.map(d => ({ Nombre: d.Nombre, Descripcion: d.Descripcion })))
                    configEvalCompare.push(originalEvaluacionItemList.map(d => ({ Nombre: d.Nombre, Descripcion: d.Descripcion })))

                    let configCriteriosCompare: string[][] = [];
                    configCriteriosCompare.push(configCriterios.map(d => (d.Descripcion)));
                    configCriteriosCompare.push(originalCriteriosItemList.map(d => d.Descripcion));

                    //Revisar cambio de tipo de evaluación con fermín;
                    configChanged = (materia.TipoEvaluacion !== form._Data.TipoEvaluacion || !this.AreObjectArraysEqual(...configEvalCompare) || !this.AreObjectArraysEqual(...configCriteriosCompare));
                    let materiaToEdit: ISendMateriaUpdt;
                    materiaToEdit = {
                        ...materia,
                        Nombre: form._Data.Nombre,
                        IdMateria: materia.IdMateria,
                        Foto: form._Data.Imagen as unknown as File,
                        GruposEliminar: gruposDeleted || [],
                        GruposNuevos: gruposAdded || [],
                        MinimoAprobar: (form._Data.TipoEvaluacion == Entidad.CTipoEvaluacion.Numeros) ? calificacionesList.MinAprob || 0 : materia.MinimoAprobar || 0,
                        TipoEvaluacion: form._Data.TipoEvaluacion,
                        EvalConfiEliminar: itemsEvalDeleted,
                        ConfiguracionEval: configEval,
                        UsaCriterios: form._Data.UsaCriterios,
                        Criterios: configCriterios,
                        CriteriosEliminar: form._Data.UsaCriterios ? itemsCriteriosDeleted : [],
                        FrecuenciaEval: form._Data.FrecuenciaEval || materia.FrecuenciaEval,
                        BloqueCalificarInicio: configTimeEval.BloqueCalificarInicio || 1,
                        DiaCalificarInicio: configTimeEval.DiaCalificarInicio || 1,
                        DiasCalificar: configTimeEval.DiasCalificar || 1,
                        IdEscolaridad: form._Data.Niveles as any,
                        IdGrado: form._Data.Grados as any,
                    }
                    let res = DataModuloMateriaV2._ActualizarMateria(materiaToEdit, configChanged);
                    return res;
                } else if (action == Entidad.CAccionPermiso.Editar) {

                    if (dataTablaEval) {
                        dataTablaEval.forEach((d, i) => {
                            d.Valor = (i + 1);
                            itemsEvalAddedEdited.push(d);
                        })
                    }

                    //El original era tipoEval Numeros y el actual también por tanto solo se está editando en el form de evaluacion
                    if (action == Entidad.CAccionPermiso.Editar && materia.TipoEvaluacion == Entidad.CTipoEvaluacion.Numeros && form._Data.TipoEvaluacion == Entidad.CTipoEvaluacion.Numeros) {
                        itemsEvalAddedEdited.push({ Ids: [calificacionesList.MinCal.id], Id: calificacionesList.MinCal.id, Nombre: calificacionesList.MinCal.cal.toString(), Descripcion: "", Valor: 1, Actions: null, Orden: 1 });
                        itemsEvalAddedEdited.push({ Ids: [calificacionesList.MaxCal.id], Id: calificacionesList.MaxCal.id, Nombre: calificacionesList.MaxCal.cal.toString(), Descripcion: "", Valor: 2, Actions: null, Orden: 2 })
                    }

                    //Se cambia de !Numeros a Numeros
                    if (action == Entidad.CAccionPermiso.Editar && materia.TipoEvaluacion != Entidad.CTipoEvaluacion.Numeros && form._Data.TipoEvaluacion == Entidad.CTipoEvaluacion.Numeros) {
                        itemsEvalAddedEdited.push({ Ids: [calificacionesList.MinCal.id], Id: calificacionesList.MinCal.id, Nombre: calificacionesList.MinCal.cal.toString(), Descripcion: "", Valor: 1, Actions: null, Orden: 1 });
                        itemsEvalAddedEdited.push({ Ids: [calificacionesList.MaxCal.id], Id: calificacionesList.MaxCal.id, Nombre: calificacionesList.MaxCal.cal.toString(), Descripcion: "", Valor: 2, Actions: null, Orden: 2 })
                    }

                    //se cambió de Numeros a otro tipo de evaluacion
                    if (materia.TipoEvaluacion == Entidad.CTipoEvaluacion.Numeros) { }

                    dataTablaCriterios.forEach((d, i) => {
                        d.Orden = (i + 1);
                        itemsCriteriosAddedEdited.push(d);
                    });

                    let configEval: Entidad.IEvaluacionConfigItem[] = itemsEvalAddedEdited.map(d => {
                        if (d.Id > 0) {
                            return { Id: d.Id, Nombre: d.Nombre, Descripcion: d.Descripcion, Valor: d.Valor }
                        } else {
                            return { Nombre: d.Nombre, Descripcion: d.Descripcion, Valor: d.Valor }
                        }
                    });

                    let configCriterios: Entidad.ICriterio[] = itemsCriteriosAddedEdited.map(d => {
                        if (d.Id > 0) {
                            return { Id: d.Id, Descripcion: d.Descripcion, Orden: d.Orden }
                        } else {
                            return { Descripcion: d.Descripcion, Orden: d.Orden }
                        }
                    });

                    let configChanged: boolean = false;
                    configEval.sort((a, b) => (a.Valor - b.Valor));
                    configCriterios.sort((a, b) => (a.Orden - b.Orden));

                    let configEvalCompare: IEvalConfigCompare[][] = [];
                    configEvalCompare.push(configEval.map(d => ({ Nombre: d.Nombre, Descripcion: d.Descripcion })))
                    configEvalCompare.push(originalEvaluacionItemList.map(d => ({ Nombre: d.Nombre, Descripcion: d.Descripcion })))

                    let configCriteriosCompare: string[][] = [];
                    configCriteriosCompare.push(configCriterios.map(d => (d.Descripcion)));
                    configCriteriosCompare.push(originalCriteriosItemList.map(d => d.Descripcion));

                    //Revisar cambio de tipo de evaluación con fermín;
                    configChanged = (materia.TipoEvaluacion !== form._Data.TipoEvaluacion || !this.AreObjectArraysEqual(...configEvalCompare) || !this.AreObjectArraysEqual(...configCriteriosCompare));
                    let errorItems: Entidad.IMateria[] = [];
                    for (const materiaId of materia.idsMaterias) {
                        let materiaToEdit: ISendMateriaUpdt;
                        materiaToEdit = {
                            ...materia,
                            Nombre: form._Data.Nombre,
                            IdMateria: materiaId,
                            GruposEliminar: [],
                            GruposNuevos: [],
                            MinimoAprobar: (form._Data.TipoEvaluacion == Entidad.CTipoEvaluacion.Numeros) ? calificacionesList.MinAprob || 0 : materia.MinimoAprobar || 0,
                            TipoEvaluacion: form._Data.TipoEvaluacion,
                            EvalConfiEliminar: itemsEvalDeleted,
                            ConfiguracionEval: configEval,
                            UsaCriterios: form._Data.UsaCriterios,
                            Criterios: configCriterios,
                            CriteriosEliminar: itemsCriteriosDeleted || [],
                            FrecuenciaEval: form._Data.FrecuenciaEval || materia.FrecuenciaEval,
                            BloqueCalificarInicio: configTimeEval.BloqueCalificarInicio || 1,
                            DiaCalificarInicio: configTimeEval.DiaCalificarInicio || 1,
                            DiasCalificar: configTimeEval.DiasCalificar || 1,
                            IdEscolaridad: DataModuloMain._GetDataValueFieldByName("MateriaV2", materiaId, "IdEscolaridad"),
                            IdGrado: DataModuloMain._GetDataValueFieldByName("MateriaV2", materiaId, "IdGrado")
                        }
                        let res = await DataModuloMateriaV2._ActualizarMateria(materiaToEdit, configChanged);
                        if (res.Resultado < 0) {
                            let materiaErr = DataModuloMain._GetItemDataByName("MateriaV2", materiaId);
                            materiaErr["res"] = res;
                            errorItems.push(materiaErr);
                        }
                    }
                    if (errorItems.length > 0) {
                        ModalThings._GetModalInfoDataList({
                            Title: UIUtilLang._GetUIString("general", "failresume"),
                            InfoText: this.VB_GetUIStringModule("fail_materiaedit"),
                            DataList: errorItems,
                            Width: 340,
                            OnStepItemData: (content, itemData) => {
                                content.style("display", "grid")
                                content.append("b").text(DataModuloMain._GetDataValueFieldByName("Grado", itemData.IdGrado, "Nombre"));
                                content.append("label").text("Nivel: " + DataModuloMain._GetDataValueFieldByName("Escolaridad", itemData.IdEscolaridad, "Nombre")).style("font-size", "var(--fontsize_me2)");
                                content.append("i")
                                    .text(UIUtilLang._GetHTTPMessage({
                                        TipoRequest: (itemData["res"] as DataDRequest.IRequestResponseA<undefined>)?.TipoRequest,
                                        Resultado: (itemData["res"] as DataDRequest.IRequestResponseA<undefined>)?.Resultado,
                                        EndPointName: "materia/ActualizarMateria"
                                    }))
                                    .style("font-size", "var(--fontsize_me2)").style("color", "red");
                            },
                            OnClose: () => {
                                if (materia.idsMaterias.length > errorItems.length) {
                                    MainPage._ReloadService(this.GRID_GetDataRequestID(), materia?.IdEscuela, (reqID, reloadId, error) => {
                                        if (error !== null) {
                                            this.notificacion._Mostrar(UIUtilLang._GetUIString("general", "notif_fail_infoupdate"), "ADVERTENCIA");
                                        }
                                    });
                                }
                                modalThings.Modal._Ocultar();
                            }
                        })
                    } else {
                        this.ctrlProgressBar.attr("oculto", false);
                        this.notificacion._Mostrar(UIUtilLang._GetHTTPMessage({ TipoRequest: Entidad.CTipoRequest.MateriaV2, Resultado: 1, EndPointName: "materia/ActualizarMateria" }, "Agregar"), "INFO");
                        MainPage._ReloadService(this.GRID_GetDataRequestID(), materia?.IdEscuela, (reqID, reloadId, error) => {
                            if (error !== null) {
                                this.notificacion._Mostrar(UIUtilLang._GetUIString("general", "notif_fail_infoupdate"), "ADVERTENCIA");
                            }
                        });
                        modalThings.Modal._Ocultar();
                    }
                }
                return null;
            }
        })
    }

    /* private OpenModal_AddEditForm(action: (Entidad.CAccionPermiso.Agregar | Entidad.CAccionPermiso.Editar), materia = <UIUtilViewMateria.IMateriaForm>{}) {
        let originalCriteriosItemList: UIUtilViewMateria.ICriterioGrid[] = [];
        let originalEvaluacionItemList: UIUtilViewMateria.ITipoEvalGrid[] = [];
        let originalGruposIds: number[] = materia?.IdsGrupos || [];

        //Extra para edición multiple
        let criteriosArrMulti: Array<UIUtilViewMateria.ICriterioGrid[]> = [];
        let evalConfigArrMulti: Array<UIUtilViewMateria.ITipoEvalGrid[]> = [];
        let criteriosArrMultiToCompare: Array<Pick<Entidad.ICriterio, "Descripcion" | "Orden">[]> = [];
        let evalConfigArrMultiToCompare: Array<Pick<UIUtilViewMateria.ITipoEvalGrid, "Descripcion" | "Nombre">[]> = [];

        let materiaForm = new UtilView.MateriaForm.MateriaForm(action, materia);
        materiaForm.met_CreateForm();
        this.GridOpenModal_ActionFormToAGridData({
            Width: 1000,
            Height: "800px",
            IdsEscuelas: [materia.IdEscuela],
            Action: action,
            OnGetIdEscuelasToReload: (form) => [form.prop_Data.IdEscuela],
            GetForm: () => materiaForm.prop_Formulario,
            DrawContent: async (container, form, mt) => {
                form.prop_Form.style("height", "100%");
                if (action == Entidad.CAccionPermiso.Editar && materia.idsMaterias.length > 1) {
                    mt.Progress.attr("oculto", false);
                    mt.Modal.met_DeshabilitarBtns();
                    for (const idMateria of materia.idsMaterias) {
                        let resCriterios = await Data.Modulo.MateriaV2.fn_GetCriterios(idMateria);
                        if (resCriterios.Resultado < 1) {
                            this.notificacion.met_Mostrar(this.VB_GetUIStringModule("fail_getinfomateria"), "ADVERTENCIA");
                            mt.Progress.attr("oculto", true);
                            mt.Modal.met_Ocultar();
                            return;
                        }
                        let criteriosList: UIUtilViewMateria.ICriterioGrid[] = resCriterios?.Datos.map(d => (
                            {
                                Ids: [d.Id],
                                Id: d.Id,
                                Descripcion: d.Descripcion,
                                Orden: d.Orden,
                                IdMateria: d.IdMateria,
                                Actions: ""
                            }
                        )).sort((a, b) => a.Orden - b.Orden);

                        if (criteriosList.length) criteriosArrMulti.push(criteriosList);
                        criteriosArrMultiToCompare.push(criteriosList.map(d => ({ Descripcion: d.Descripcion, Orden: d.Orden })));

                        let resEvalConfig = await Data.Modulo.MateriaV2.fn_GetEvaluacionConfig(idMateria);
                        if (resEvalConfig.Resultado < 1) {
                            this.notificacion.met_Mostrar(this.VB_GetUIStringModule("fail_getinfomateria"), "ADVERTENCIA");
                            mt.Progress.attr("oculto", true);
                            mt.Modal.met_Ocultar();
                            return;
                        }
                        let evalConfigList: UIUtilViewMateria.ITipoEvalGrid[] = resEvalConfig?.Datos.map(d => (
                            {
                                Ids: [d.Id],
                                Id: d.Id,
                                Nombre: d.Nombre,
                                Descripcion: d.Descripcion,
                                Valor: d.Valor,
                                Orden: d.Valor,
                                TipoEvaluacion: d.TipoEvaluacion,
                                IdMateria: d.IdMateria,
                                Actions: ""
                            }
                        )).sort((a, b) => a.Valor - b.Valor);
                        evalConfigArrMulti.push(evalConfigList);
                        evalConfigArrMultiToCompare.push(evalConfigList.map(d => ({ Nombre: d.Nombre, Descripcion: d.Descripcion })));
                    }
                    mt.Progress.attr("oculto", true);
                    if (!this.AreObjectArraysEqual(...criteriosArrMultiToCompare)) {
                        this.notificacion.met_Mostrar(this.VB_GetUIStringModule("criteriosconfig_nomatch"), "ADVERTENCIA", 4500);
                        mt.Modal.met_Ocultar();
                        return;
                    }
                    if (!this.AreObjectArraysEqual(...evalConfigArrMultiToCompare)) {
                        this.notificacion.met_Mostrar(this.VB_GetUIStringModule("evalconfig_nomatch"), "ADVERTENCIA", 4500);
                        mt.Modal.met_Ocultar();
                        return;
                    }

                    //let criteriosRepresentantes: UIUtilViewMateria.ICriterioGrid[] = [];
                }
                if (action == Entidad.CAccionPermiso.Editar) {
                    mt.Progress.attr("oculto", false);
                    mt.Modal.met_DeshabilitarBtns();

                    let resCriterios = await Data.Modulo.MateriaV2.fn_GetCriterios(materia.IdMateria);
                    if (resCriterios.Resultado < 1) {
                        this.notificacion.met_Mostrar("Error al cargar la información", "ADVERTENCIA");
                        mt.Modal.met_Ocultar();
                        return;
                    }
                    let criteriosList = resCriterios?.Datos;
                    mt.Progress.attr("oculto", true);
                    criteriosList.sort((a, b) => (a.Orden - b.Orden));
                    criteriosList.forEach((d, i) => {
                        materiaForm.met_AddCriterioItem({
                            Actions: "",
                            Descripcion: d.Descripcion,
                            Id: d.Id,
                            Ids: [d.Id],
                            Orden: d.Orden,
                            IdMateria: d.IdMateria
                        })
                    });
                    originalCriteriosItemList = materiaForm.prop_ItemsCriteriosList.map(d => {
                        const dd = Object.assign({}, d);
                        return dd;
                    });
                    originalCriteriosItemList.forEach(d => {
                        materiaForm.met_AddCriterioOriginal({ ...d });
                    })
                    materiaForm.met_UpdateCriteriosTablaData()
                    mt.Progress.attr("oculto", false);

                    let resEvalConfig = await Data.Modulo.MateriaV2.fn_GetEvaluacionConfig(materia.IdMateria);
                    if (resEvalConfig.Resultado < 1) {
                        this.notificacion.met_Mostrar("Error al cargar la información", "ADVERTENCIA");
                        mt.Modal.met_Ocultar();
                        return;
                    }
                    let evalConfigItemsList = resEvalConfig?.Datos;
                    mt.Progress.attr("oculto", true);
                    mt.Modal.met_HabilitarBtns();
                    evalConfigItemsList.sort((a, b) => (a.Valor - b.Valor));
                    evalConfigItemsList.forEach((d, i) => {
                        materiaForm.met_AddEvalConfItem({
                            Actions: "",
                            Nombre: d.Nombre,
                            Descripcion: d.Descripcion,
                            Valor: d.Valor,
                            Id: d.Id,
                            Ids: [d.Id],
                            Orden: d.Valor,
                            IdMateria: d.IdMateria,
                            TipoEvaluacion: d.TipoEvaluacion
                        })
                    });

                    //Posiblemente todo lo que tenga que ver con originales se pueda llevar al util
                    originalEvaluacionItemList = materiaForm.prop_ItemsEvalList.map(d => {
                        const dd = Object.assign({}, d);
                        return dd;
                    });
                    originalEvaluacionItemList.forEach(d => {
                        materiaForm.met_AddEvalConfItemOriginal({ ...d });
                    })
                    if (materiaForm.prop_ItemsEvalList && !materiaForm.prop_ItemsEvalList.length) {
                        // CHECK
                        materiaForm.met_AddEvalConfItem({ Ids: [-1], Id: -1, Nombre: "", Descripcion: "", Actions: "", Orden: null, Valor: null, IdMateria: null, TipoEvaluacion: null });
                    }
                    materiaForm.met_UpdateEvalConfigTablaData();
                }
                if (materiaForm.prop_ItemsEvalList && !materiaForm.prop_ItemsEvalList.length) {
                    materiaForm.met_AddEvalConfItem({ Ids: [-1], Id: -1, Nombre: "", Descripcion: "", Actions: "", Orden: null, Valor: null, IdMateria: null, TipoEvaluacion: null })
                }
                if (materia.TipoEvaluacion == Entidad.CTipoEvaluacion.Numeros) {
                    console.log(materiaForm.prop_ItemsEvalList);
                    materiaForm.metAssignMinCal(materiaForm.prop_ItemsEvalList[0]?.Id, Number(materiaForm.prop_ItemsEvalList[0]?.Descripcion))
                    materiaForm.metAssignMaxCal(materiaForm.prop_ItemsEvalList[1]?.Id, Number(materiaForm.prop_ItemsEvalList[1]?.Descripcion))
                    materiaForm.metAssignMinApr(materia.MinimoAprobar);
                }
            },
            OnAccept: (form, modalthings) => {
                let gruposAdded: number[] = [];
                let itemsCriteriosAddedEdited: UIUtilViewMateria.ICriterioGrid[] = [];
                let itemsEvalAddedEdited: UIUtilViewMateria.ITipoEvalGrid[] = [];
                let itemsEvalDeleted: number[] = [];
                let itemsCriteriosDeleted: number[] = [];

                //Not For MultiEdit
                gruposAdded = form.prop_Data.IdsGrupos.filter(gA => {
                    let grupo = originalGruposIds.find(gB => (gB == gA))
                    return !Boolean(grupo);
                })
                //Not For MultiEdit
                let gruposDeleted = originalGruposIds.filter(gA => {
                    let grupo = form.prop_Data.IdsGrupos.find(gB => (gB == gA));
                    return !Boolean(grupo)
                })

                if (action == Entidad.CAccionPermiso.Agregar) { //Accept para agregar 1 o +

                } else if (materia.idsMaterias.length == 1) { //Accept para editar 1 materia
                    let dataTablaEval = materiaForm.prop_DataTablaEvaluacion;
                    let dataTablaCriterios = materiaForm.prop_DataTablaCriterios;
                    let calificacionesList = materiaForm.propCalificacionesList;

                    if (dataTablaEval) {
                        dataTablaEval.forEach((d, i) => {
                            d.Valor = (i + 1);
                            if (d.Id < 0) {
                                itemsEvalAddedEdited.push(d);
                            } else {
                                let itemO = originalEvaluacionItemList.find(dA => (dA.Id == d.Id));
                                if (itemO.Valor !== d.Valor || itemO.Nombre !== d.Nombre || itemO.Descripcion !== d.Descripcion) {
                                    itemsEvalAddedEdited.push(d);
                                }
                            }
                        });
                        itemsEvalDeleted = originalEvaluacionItemList.filter(dA => {
                            let itemFound = dataTablaEval.find(dB => (dB.Id == dA.Id));
                            return !Boolean(itemFound);
                        }).map(d => d.Id);
                    }

                    dataTablaCriterios.forEach((d, i) => {
                        d.Orden = (i + 1);
                        if (d.Id < 0) {
                            itemsCriteriosAddedEdited.push(d);
                        } else {
                            let itemO = originalCriteriosItemList.find(dA => (dA.Id == d.Id));
                            if (itemO.Orden !== d.Orden || itemO.Descripcion !== d.Descripcion) {
                                itemsCriteriosAddedEdited.push(d);
                            }
                        }
                    })
                    itemsCriteriosDeleted = originalCriteriosItemList.filter(dA => {
                        let itemFound = dataTablaCriterios.find(dB => (dB.Id == dA.Id));
                        return !Boolean(itemFound);
                    }).map(d => d.Id);

                    //Evaluación para numeros
                    //El original era tipoEval Numeros y el actual también por tanto solo se está editando en el form de evaluacion
                    if (action == Entidad.CAccionPermiso.Editar && materia.TipoEvaluacion == Entidad.CTipoEvaluacion.Numeros && form.prop_Data.TipoEvaluacion == Entidad.CTipoEvaluacion.Numeros) {
                        if (originalEvaluacionItemList[0].Descripcion != calificacionesList.MinCal.cal.toString()) {
                            itemsEvalAddedEdited.push({ Ids: [calificacionesList.MinCal.id], Id: calificacionesList.MinCal.id, Nombre: "Calificación Minima", Descripcion: calificacionesList.MinCal.cal.toString(), Valor: 1, Actions: null, Orden: 1 })
                        }
                        if (originalEvaluacionItemList[1].Descripcion != calificacionesList.MaxCal.cal.toString()) {
                            itemsEvalAddedEdited.push({ Ids: [calificacionesList.MaxCal.id], Id: calificacionesList.MaxCal.id, Nombre: "Calificación Máxima", Descripcion: calificacionesList.MaxCal.cal.toString(), Valor: 2, Actions: null, Orden: 2 })
                        }
                    }

                    //Se cambia de !Numeros a Numeros
                    if (action == Entidad.CAccionPermiso.Editar && materia.TipoEvaluacion != Entidad.CTipoEvaluacion.Numeros && form.prop_Data.TipoEvaluacion == Entidad.CTipoEvaluacion.Numeros) {
                        itemsEvalAddedEdited.push({ Ids: [calificacionesList.MinCal.id], Id: calificacionesList.MinCal.id, Nombre: "Calificación Minima", Descripcion: calificacionesList.MinCal.cal.toString(), Valor: 1, Actions: null, Orden: 1 });
                        itemsEvalAddedEdited.push({ Ids: [calificacionesList.MaxCal.id], Id: calificacionesList.MaxCal.id, Nombre: "Calificación Máxima", Descripcion: calificacionesList.MaxCal.cal.toString(), Valor: 2, Actions: null, Orden: 2 })
                        itemsEvalDeleted = originalEvaluacionItemList.map(d => d.Id);
                    }

                    let configEval: Entidad.IEvaluacionConfigItem[] = itemsEvalAddedEdited.map(d => {
                        if (d.Id > 0) {
                            return { Id: d.Id, Nombre: d.Nombre, Descripcion: d.Descripcion, Valor: d.Valor }
                        } else {
                            return { Nombre: d.Nombre, Descripcion: d.Descripcion, Valor: d.Valor }
                        }
                    });

                    let configCriterios: Entidad.ICriterio[] = itemsCriteriosAddedEdited.map(d => {
                        if (d.Id > 0) {
                            return { Id: d.Id, Descripcion: d.Descripcion, Orden: d.Orden }
                        } else {
                            return { Descripcion: d.Descripcion, Orden: d.Orden }
                        }
                    });

                    configEval.sort((a, b) => (a.Valor - b.Valor));
                    configCriterios.sort((a, b) => (a.Orden - b.Orden));

                    let materiaToEdit: Data.Modulo.MateriaV2.ISendMateriaUpdt;
                    materiaToEdit = {
                        ...materia,
                        Nombre: form.prop_Data.Nombre,
                        IdMateria: materia.IdMateria,
                        Foto: form.prop_Data.Imagen as unknown as File,
                        GruposEliminar: gruposDeleted,
                        GruposNuevos: gruposAdded,
                        MinimoAprobar: (form.prop_Data.TipoEvaluacion == Entidad.CTipoEvaluacion.Numeros) ? materiaForm.propCalificacionesList.MinAprob || 0 : materia.MinimoAprobar || 0,
                        TipoEvaluacion: form.prop_Data.TipoEvaluacion,
                        EvalConfiEliminar: itemsEvalDeleted,
                        ConfiguracionEval: configEval,
                        UsaCriterios: form.prop_Data.UsaCriterios,
                        Criterios: form.prop_Data.UsaCriterios ? configCriterios : [],
                        CriteriosEliminar: form.prop_Data.UsaCriterios ? itemsCriteriosDeleted : [],
                        FrecuenciaEval: form.prop_Data.FrecuenciaEval || materia.FrecuenciaEval
                    }
                    let res = Data.Modulo.MateriaV2.fn_ActualizarMateria(materiaToEdit);
                    return res;

                } else { // Accept para editar +1 materia
                }

                return null;
            }
        })
    } */

    private OpenModal_DeleteData(materiasToDelete: IMateriaGrid[]) {
        interface MateriaConflict {
            materia: IMateria;
            gruposConflicto: Entidad.IGrupo[];
        }

        let realMateriasToDelete: IMateriaGrid[] = [];
        let materiasConflicto: MateriaConflict[] = [];


        d3Group(materiasToDelete, d => d.IdMateria)
            .forEach((arr, idMateria) => {
                let materia = arr[0].Materia;
                let gruposConflicto: Entidad.IGrupo[] = [];
                let gruposSelect: number[] = [];

                arr.forEach(d => {
                    gruposSelect.push(...d.IdGridGrupos);
                })

                materia.IdsGrupos.forEach(g => {
                    if (gruposSelect.every(gs => gs !== g)) {
                        let grupoConflict = DataModuloGrupo._DiccGrupo.get(g);
                        if (grupoConflict) {
                            gruposConflicto.push(grupoConflict);
                        }
                    }
                });

                if (gruposConflicto.length) {
                    materiasConflicto.push({ gruposConflicto, materia })
                }
            })

        materiasToDelete.forEach(materia => {
            if (realMateriasToDelete.findIndex(d => (d.IdMateria == materia.IdMateria)) < 0) {
                realMateriasToDelete.push(materia);
            }
        });

        this.GridOpenModal_ProccessArrayData({
            TypeRequest: this.GRID_GetDataRequestID(),
            Action: Entidad.CAccionPermiso.Eliminar,
            AccionToHttpMessage: "EliminarMateria",
            AutoReloadGridRequestOnFinally: true,
            OnGetIdEscuela: (materia) => materia.IdEscuela,
            DataToProccess: realMateriasToDelete,
            OnError_GetItemDataTag: (itemData) => (itemData.Nombre + " - " + itemData.StrGrado),
            OnStepAProccess: (itemData) => DataModuloMateriaV2._Eliminar(itemData.IdMateria),
            OnEndAndCloseProccess: async (datosCorrectos, idsEscuelas) => {
                if (datosCorrectos.length == 0) {
                    let idEscuelasMaterias = new Set(realMateriasToDelete.map(d => d.IdEscuela));
                    this.ctrlProgressBar.attr("oculto", false);
                    let successReload = await MainPage._ReloadServiceAndAwaitBool(this.GRID_GetDataRequestID(), Array.from(idEscuelasMaterias));
                    this.ctrlProgressBar.attr("oculto", true);
                    if (!successReload) this.notificacion._Mostrar(UIUtilLang._GetUIString("general", "notif_fail_infoupdate"), "ADVERTENCIA");
                }
            },
            OnDrawContent: (container) => {
                if (materiasConflicto.length) {
                    container.classed(UIUtilGeneral.FBoxOrientation.Vertical, true)
                        .style("gap", "var(--padding2)");
                    container.append("label")
                        .text(materiasConflicto.length > 1 ? this.VB_GetUIStringModule("manymateriafectagrupos") : this.VB_GetUIStringModule("manymateriafectagrupos"));

                    new List<MateriaConflict>()
                        ._SetParent(container)
                        ._SetUpdateItem((container, materiaConflicto) => {
                            container.text(materiaConflicto.materia.Nombre)
                            container.append("div")
                                .selectAll(":scope >li")
                                .data(materiaConflicto.gruposConflicto)
                                .join("li")
                                .text(d => d.Nombre)
                        })
                        ._SetItems(materiasConflicto)
                        ._ListContainerSelection
                        .style("border-top", "1px solid var(--color_borderbox1)");
                }
            }
        });
    }

    private AreObjectArraysEqual(...criteriosConfigs: Object[][]): boolean {
        if (criteriosConfigs.length < 2) return true; //Si no hay al menos 2 arreglos para comparar entonces no cabe la comparación
        const firstArr = criteriosConfigs[0];
        for (let i = 1; i < criteriosConfigs.length; i++) {
            const currentArr = criteriosConfigs[i];
            if (firstArr.length !== currentArr.length) return false; //Si la longitud del primer arreglo no coincide con la longitud de los demás arreglos
            for (let j = 0; j < firstArr.length; j++) {
                if (!this.AreObjectsEqual(firstArr[j], currentArr[j])) return false; // Llama a la función que valida que los objetos sean iguales en su tamaño y valor de propiedades;
            }
        }
        return true;
    }

    private AreObjectsEqual(object1: object, object2: object): boolean {
        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);
        if (keys1.length !== keys2.length) return false; //Si los objetos no tienen la misma cantidad de propiedades
        for (const key of keys1) {
            if (object1[key] !== object2[key]) return false;
        }
        return true;
    }
}
