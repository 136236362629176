import * as d3 from "d3";
import { UIUtilLang } from "../util/Language";
import { UIUtilTime } from "../util/Time";
import { Button } from "./Button";
import { CalendarioGridBase } from "./CalendarioGridBase";

export namespace CalendarioGridYearDateRange {

    // enum CViewMode {
    //     OneMonth = 1, StarEndMonths, ParcialYear
    // }
    import DirectionIndicator = Button.DirectionIndicator;

    export interface ICalendarioGridYearDateRangeConfig {
        // Type: CViewMode;
        /** @default true */
        FistMonthIsActualMonth?: boolean;
        // NMesInit: number;
        // NMaxMonthsInAView: number;
        ContainerToRedimention?: d3.Selection<HTMLDivElement, any, HTMLElement, any>;
        OnChangeSomeSelector?: (resultSelectors: IResultSelectors) => void;
    }

    interface IResultSelectors {
        StartDate: Date;
        EndDate: Date;
    }

    enum CTypeSelector {
        Inicio = 1,
        Fin
    }

    interface IKeysToAddDateSelector {
        YearDay?: number
    }
    export class CalendarioGridYearDateRange extends CalendarioGridBase.CalendarGridBase<"DataReal", CTypeSelector, IKeysToAddDateSelector> { //<"SelectorBase" | "SelectorReal"> {
        private config: ICalendarioGridYearDateRangeConfig;

        private areaYearName: d3.Selection<HTMLDivElement, any, HTMLElement, any>;
        private tagDatesSelected: d3.Selection<HTMLDivElement, any, HTMLElement, any>;

        private currentYearView: number;
        private lastYearDayEnter: number;
        private colorSelectedCells: string;
        private nMesesEnVistaInicial: number

        private minYear: number;
        private maxYear: number;
        private minDate: Date;
        private maxDate: Date;
        private evalMinDate: number;
        private evalMaxDate: number;
        private diasNoLaborales: number[];

        constructor(parent: d3.Selection<HTMLDivElement, any, HTMLElement, any>, config: ICalendarioGridYearDateRangeConfig) {
            super({
                Parent: parent,
                TypeSelector: "DataReal",
                Selectors: [
                    {
                        Id: CTypeSelector.Inicio,
                        Date: null, // new Date(),
                        PriorityWhenMerging: 1,
                        IdMaxSelector: CTypeSelector.Fin,
                        Class: "sel_1"
                    },
                    {
                        Id: CTypeSelector.Fin,
                        Date: null, // new Date(),
                        IdMinSelector: CTypeSelector.Inicio,
                        PriorityWhenMerging: 2,
                        Class: "sel_2"
                    },
                ],
                OnPositionSelectorCalled: (selectorsContainer, selectorContainer, selectorInfo) => {
                    this.CGBcontrolContainer.select(".area_calendar_grid").selectAll(".cell").style("background", null);
                    selectorsContainer.append(() => selectorContainer.node());
                    this.UpdateViewTagDatesSelected();
                    if (this.CGBdateSelectors.get(CTypeSelector.Fin).Date) {
                        this.UpdateViewDrawRangeSelected(this.CGBdateSelectors.get(CTypeSelector.Fin).YearDay);
                    }
                    return true;
                },
                OnRemovePositionSelector: (lastSelectorsContainer, selectorContainer, selectorInfo) => {
                    d3.select(lastSelectorsContainer.node().parentElement).style("background", null);
                }
            });

            let initDate = new Date();
            this.CGBdateSelectors.get(CTypeSelector.Inicio).Date = new Date(initDate.getFullYear(), initDate.getMonth(), initDate.getDate());
            this.CGBdateSelectors.get(CTypeSelector.Fin).Date = new Date(initDate.getFullYear(), initDate.getMonth(), initDate.getDate());
            this.colorSelectedCells = "rgb(225, 225, 225)";
            this.lastYearDayEnter = 0;
            this.minYear = 1980;
            this.maxYear = 2500;
            this.evalMinDate = this.CGBcreator.GetDateEvaluator("min");
            this.evalMaxDate = this.CGBcreator.GetDateEvaluator("max");
            this.InitDraw();
            this.UpdateConfigAndView(config);
        }

        private InitDraw(): void {
            const subHeader = this.CGBcontrolContainer.select<HTMLDivElement>(".sub_header");
            this.tagDatesSelected = this.CGBcontrolContainer.select<HTMLDivElement>(".footer")
                .classed("cont_info", true);

            let lastYearDiv = subHeader.append("div")
                .classed("accion_item", true)
                .classed("lastyear", true);
            lastYearDiv.append(() => DirectionIndicator._GetDirectionIndicator("Left").node())

            this.areaYearName = subHeader.append("div")
                .classed("yearname", true);

            let nextYearDiv = subHeader.append("div")
                .classed("accion_item", true)
                .classed("nextyear", true);
            nextYearDiv.append(() => DirectionIndicator._GetDirectionIndicator("Right").node())

            const tagInicio = this.tagDatesSelected.append("div").classed("tag_inicio", true);
            tagInicio.append("div").classed("selector sel_1", true)
            tagInicio.append("div").classed("info", true)

            const tagFin = this.tagDatesSelected.append("div").classed("tag_fin", true);
            tagFin.append("div").classed("selector sel_2", true)
            tagFin.append("div").classed("info", true)

            lastYearDiv.node().onclick = (e: MouseEvent) => {
                if ((this.currentYearView - 1) >= this.minYear) {
                    this.currentYearView--;
                    this.UpdateAndDrawYearMonths(this.currentYearView);
                }
            }

            nextYearDiv.node().onclick = (e: MouseEvent) => {
                if ((this.currentYearView + 1) <= this.maxYear) {
                    this.currentYearView++
                    this.UpdateAndDrawYearMonths(this.currentYearView);
                }
            }
        }

        private UpdateConfigAndView(config: ICalendarioGridYearDateRangeConfig) {
            const defaultConfig = <ICalendarioGridYearDateRangeConfig>{
                FistMonthIsActualMonth: true
            };
            const currentYear = new Date().getFullYear();

            this.config = { ...defaultConfig, ...config };

            this.UpdateAndDrawYearMonths(currentYear);
        }

        private UpdateViewTagDatesSelected(previewInicioDate?: Date, previewFinDate?: Date) {
            const endDate = this.CGBdateSelectors.get(CTypeSelector.Fin).Date;
            const startDate = this.CGBdateSelectors.get(CTypeSelector.Inicio).Date;
            if (previewFinDate && startDate) {
                if (previewFinDate < startDate) {
                    previewInicioDate = previewFinDate;
                    previewFinDate = null;
                } else if (previewFinDate.getFullYear() == startDate.getFullYear() && this.CGBcreator.GetDayIntoYear(previewFinDate) == this.CGBcreator.GetDayIntoYear(startDate)) {
                    previewFinDate = null;
                }
            }
            let inicio = previewInicioDate ? UIUtilTime._DateFormatStandar(previewInicioDate) : startDate ? "<b>" + UIUtilTime._DateFormatStandar(startDate) + "</b>" : "";
            let fin = previewFinDate ? "" + UIUtilTime._DateFormatStandar(previewFinDate) : endDate ? "<b>" + UIUtilTime._DateFormatStandar(endDate) + "</b>" : "";

            this.tagDatesSelected.select(".tag_inicio")
                .select(".info")
                .html(UIUtilLang._GetUIString("general", "inicio") + ": " + (inicio ? inicio : UIUtilLang._GetUIString("general", "nodtselected")));

            this.tagDatesSelected.select(".tag_fin")
                .select(".info")
                .html(UIUtilLang._GetUIString("general", "fin") + ": " + (fin ? fin : UIUtilLang._GetUIString("general", "nodtselected")));

            this.tagDatesSelected.select(".tag_inicio").on("click", null).style("cursor", "default");
            this.tagDatesSelected.select(".tag_fin").on("click", null).style("cursor", "default");

            if (startDate) {
                this.tagDatesSelected.select(".tag_inicio").on("click", () => {
                    this.UpdateAndDrawYearMonths(startDate.getFullYear());
                }).style("cursor", "pointer");
            }
            if (endDate) {
                this.tagDatesSelected.select(".tag_fin").on("click", () => {
                    this.UpdateAndDrawYearMonths(endDate.getFullYear());
                }).style("cursor", "pointer");
            }
        }

        private BuildMonthView(year: number) {
            let allMonths = this.CGBcontrolContainer.select(".body").select(".area_calendar_grid").selectAll<HTMLDivElement, CalendarioGridBase.ICGB_Month>(".month");
            let months: Array<CalendarioGridBase.ICGB_Month> = [];

            if (this.config.FistMonthIsActualMonth) {
                const currentDate = new Date();
                const initDate = currentDate.getFullYear() == year ? currentDate.getMonth() + 1 : 1;

                for (let i = initDate; i <= 12; i++) {
                    months.push(this.CGBcreator.BuildYearMonth(i as CalendarioGridBase.TCGB_ValidMonths, year));
                }
            } else {
                months = this.CGBcreator.BuildRealYear(year).Months;
            }
            this.nMesesEnVistaInicial = months.length;

            // month.Container no es tomado en cuenta
            allMonths
                .data(months, (d, i) => i + "")
                .join(
                    enter => this.CGBEnterMonthContainer(enter),
                    update => update
                        .classed("test", (d, i, arrDivs) => {
                            this.CGBUpdateMonthContainer(d3.select(arrDivs[i]), d);
                            return false;
                        }),
                    exit => exit.remove()
                )

            this.CGBUpdateAllSelectorsPosition()
            allMonths.selectAll(".date_cells").selectAll(".cell").style("min-height", "25px")
            allMonths.selectAll(".date_cells").selectAll(".lastMonthCell").style("min-height", "25px")
            allMonths.selectAll(".date_cells").selectAll(".nextMonthCell").style("min-height", "25px")

            return this;
        }

        protected CGBOnStepMonthCell(datum: CalendarioGridBase.ICGB_DateCell, month: CalendarioGridBase.ICGB_Month, divCell: d3.Selection<HTMLDivElement, CalendarioGridBase.ICGB_DateCell, HTMLElement, any>, divSelectors: d3.Selection<HTMLDivElement, any, HTMLElement, any>, dateCell: Date, typeUpdate: "enter" | "update" | "exit") {
            if (typeUpdate != "enter") {
                const lastId = divCell.select<HTMLLabelElement>(".lbl_name").node().id;
                divCell.classed(lastId, false);
            }
            divCell
                .classed("c" + datum.YearDay, true)
                .style("background", null);
            divCell.select<HTMLLabelElement>(".lbl_name").node().id = "c" + datum.YearDay;

            if (typeUpdate != "exit") {
                let selectorInicio = this.CGBdateSelectors.get(CTypeSelector.Inicio);
                let selectorFin = this.CGBdateSelectors.get(CTypeSelector.Fin);
                const areaCalendar = this.CGBcontrolContainer.select(".area_calendar_grid");
                dateCell = new Date(dateCell.getFullYear(), dateCell.getMonth(), dateCell.getDate());

                datum.IsEnable = true;
                // if (this.minDate) {
                //     if (month.Year < this.minDate.getFullYear()) {
                //         datum.IsEnable = false;
                //     } else if (month.Year == this.minDate.getFullYear()) {
                //         if (month.NMonth < this.minDate.getMonth() + 1) {
                //             datum.IsEnable = false;
                //         }
                //         // else if (month.NMonth == this.minDate.getFullYear()) {
                //         //     datum.YearDay
                //         // }
                //     }
                // }
                if (this.evalMinDate == this.CGBcreator.GetDateEvaluator("min") && this.evalMaxDate == this.CGBcreator.GetDateEvaluator("max")) {
                    datum.IsEnable = true
                } else {
                    const evalCurrentDate = this.CGBcreator.GetDateEvaluator("min", dateCell); // Number(month.Year + "" + (month.NMonth < 10 ? "0" + month.NMonth : month.NMonth) + (datum.MonthDay < 10 ? "0" + datum.MonthDay : datum.MonthDay));
                    let dayInWeek = UIUtilTime._GetDayInWeek(dateCell);
                    let isNoLaboralday = this.diasNoLaborales ? this.diasNoLaborales.includes(dayInWeek) : false;
                    datum.IsEnable = evalCurrentDate >= this.evalMinDate && evalCurrentDate <= this.evalMaxDate && !isNoLaboralday;
                    (evalCurrentDate == this.evalMinDate) ? divCell.style("border-top", "2px solid var(--color_action1)") : divCell.style("border-top", null);
                    (evalCurrentDate == this.evalMaxDate) ? divCell.style("border-bottom", "2px solid var(--color_action1)") : divCell.style("border-bottom", null);
                }

                if (datum.IsEnable) {
                    divCell.node().onclick = (e: MouseEvent) => {
                        if ((selectorInicio.Date && selectorFin.Date) || (!selectorInicio.Date && !selectorFin.Date)) {
                            selectorFin.Date = null;
                            selectorFin.YearDay = 0;
                            selectorInicio.Date = dateCell;
                            selectorInicio.YearDay = this.CGBcreator.GetDayIntoYear(dateCell);
                        } else if (selectorInicio.Date && !selectorFin.Date) {
                            if (dateCell > selectorInicio.Date) {
                                selectorFin.Date = dateCell;
                                selectorFin.YearDay = this.CGBcreator.GetDayIntoYear(dateCell);
                            } else if (dateCell < selectorInicio.Date) {
                                selectorInicio.Date = dateCell;
                                selectorInicio.YearDay = this.CGBcreator.GetDayIntoYear(dateCell);
                            } else {
                                selectorFin.Date = null;
                                selectorFin.YearDay = 0;
                            }
                        }

                        if (this.config.OnChangeSomeSelector) {
                            this.config.OnChangeSomeSelector(this.GetSelectedDates())
                        }
                        this.CGBUpdateAllSelectorsPosition();
                        e.stopPropagation();
                    }
                }

                divCell.node().onmouseenter = (e: MouseEvent) => {
                    if (!selectorInicio.Date) {
                        this.UpdateViewTagDatesSelected(dateCell)
                    } else if (!selectorFin.Date) {
                        this.UpdateViewTagDatesSelected(undefined, dateCell);
                    }

                    if (selectorInicio.Date && !selectorFin.Date) {
                        if (this.lastYearDayEnter != 0 && datum.YearDay < this.lastYearDayEnter) {
                            for (let i = datum.YearDay; i <= this.lastYearDayEnter; i++) {
                                areaCalendar.select(".c" + i).style("background", null)
                            }
                        }

                        this.UpdateViewDrawRangeSelected(datum.YearDay);

                        this.lastYearDayEnter = datum.YearDay
                    }
                    // e.stopPropagation();
                }

                divCell.node().onmouseleave = (e: MouseEvent) => {
                    if (!selectorInicio.Date && !selectorFin.Date) {
                        this.UpdateViewTagDatesSelected(); // Reset info tags
                    }
                }
            }
        }

        protected CGBOnCreateSelectorContainer(selector: CalendarioGridBase.ICGB_SelectorMap<CTypeSelector>["DataReal"]) { }

        private UpdateViewDrawRangeSelected(endYearDate: number) {
            const areaCalendar = this.CGBcontrolContainer.select(".area_calendar_grid");
            const selectorInicio = this.CGBdateSelectors.get(CTypeSelector.Inicio);
            const endDate = this.CGBdateSelectors.get(CTypeSelector.Fin).Date;
            if (selectorInicio.Date) {
                if (selectorInicio.Date.getFullYear() == this.currentYearView) {
                    endYearDate = endDate ? endDate.getFullYear() > selectorInicio.Date.getFullYear() ? 366 : endYearDate : endYearDate;

                    for (let i = selectorInicio.YearDay; i <= endYearDate; i++) {
                        areaCalendar.select(".c" + i).style("background", this.colorSelectedCells)
                    }
                } else if (selectorInicio.Date.getFullYear() < this.currentYearView) {
                    if (endDate) {
                        if (endDate.getFullYear() < this.currentYearView) {
                            endYearDate = 0
                        } else if (endDate.getFullYear() > this.currentYearView) {
                            endYearDate = 366;
                        }
                    }

                    for (let i = 1; i <= endYearDate; i++) {
                        areaCalendar.select(".c" + i).style("background", this.colorSelectedCells)
                        // d3.select(areaCalendar.select(".cell").select<HTMLLabelElement>("#c" + i).node().parentElement).style("background", "rgb(245, 245, 245)");
                    }
                }
            }
        }

        private UpdateAndDrawYearMonths(year: number) {
            this.currentYearView = year;
            this.areaYearName.html("<b>" + year + "</b>");
            this.CGBcontrolContainer.select(".area_calendar_grid").selectAll(".cell").style("background", null);

            this.BuildMonthView(year);

            if (this.nMesesEnVistaInicial < 4) {
                this.CGBUpdateAreaCalendarGrid(this.nMesesEnVistaInicial as any, 1);
                this.RedimContainer(300 + (this.nMesesEnVistaInicial * 100));
            } else {
                this.CGBUpdateAreaCalendarGrid(4, 3)
                    .RedimContainer(800);
            }

            this.UpdateViewDrawRangeSelected(this.CGBdateSelectors.get(CTypeSelector.Fin).YearDay);
        }

        private RedimContainer(width: number) {
            if (this.config.ContainerToRedimention) {
                this.config.ContainerToRedimention.style("width", width + "px");
            }
        }

        private GetSelectedDates(): IResultSelectors {
            return {
                StartDate: this.CGBdateSelectors.get(CTypeSelector.Inicio).Date,
                EndDate: this.CGBdateSelectors.get(CTypeSelector.Fin).Date
            }
        }

        //*************************************************************************
        // Public Properties
        //*************************************************************************

        set _DiasNoLabores(diasNoLaborales: number[]) {
            this.diasNoLaborales = diasNoLaborales;
        }

        set _minDate(value: Date) {
            this.minDate = value;
            this.minYear = value ? value.getFullYear() : 1980;
            this.evalMinDate = this.CGBcreator.GetDateEvaluator("min", this.minDate);
        }

        get _minDate() {
            return this.minDate;
        }

        set _maxDate(value: Date) {
            this.maxDate = value;
            this.maxYear = value ? value.getFullYear() : 2500;
            this.evalMaxDate = this.CGBcreator.GetDateEvaluator("max", this.maxDate);
        }

        get _maxDate() {
            return this.maxDate;
        }

        get _control() {
            return this.CGBcontrolContainer;
        }

        //*************************************************************************
        // Public Methods
        //*************************************************************************

        public _SetPosicionSelectores(startDate?: Date, endDate?: Date): void {
            this.CGBdateSelectors.get(CTypeSelector.Inicio).Date = startDate;
            this.CGBdateSelectors.get(CTypeSelector.Fin).Date = endDate;
            this.CGBdateSelectors.get(CTypeSelector.Inicio).YearDay = startDate ? this.CGBcreator.GetDayIntoYear(startDate) : 0;
            this.CGBdateSelectors.get(CTypeSelector.Fin).YearDay = endDate ? this.CGBcreator.GetDayIntoYear(endDate) : 0;
            this.CGBcontrolContainer.select(".area_calendar_grid").selectAll(".cell").style("background", null);
            this.CGBUpdateAllSelectorsPosition();
            this.UpdateViewTagDatesSelected();
        }

        public _GetResultSelected(): IResultSelectors {
            return this.GetSelectedDates();
        }

        /** @param newYear (opcional) Actualiza el calendario en el año indicado, por default recarga el año visible actual */
        public _UpdateViewMonthsYear(newYear?: number) {
            this.UpdateAndDrawYearMonths(newYear ? newYear : this.currentYearView);
        }
    }
}
