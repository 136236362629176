import { DataDRequest } from "../../data/DRequest";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import { _SvAlumnoObtenerHistorialMovimiento } from "../../data/modulo/Alumno";
import DataModuloEscuela from "../../data/modulo/Escuela";
import { TCARDV2COLL_OnEditOriginEvent } from "../controlD3/CardV2Collapse";
import { CardV2CollapseAdvancedTable, IConfigCardV2CollapseExcelExport } from "../controlD3/CardV2CollapseAdvancedTable";
import { ExcelThings } from "../controlD3/ExcelExport";
import { FormGenerator } from "../controlD3/Formulario";
import { NotificacionV2 } from "../controlD3/NotificacionV2";
import { SelectV2 } from "../controlD3/SelectV2";
import { Table } from "../controlD3/Tabla";
import { UIUtilTime } from "../util/Time";
import { UIUtilViewData } from "../util/ViewData";
import { UIUtilViewAlumno } from "../utilView/Alumno";
import { UIWindowManager } from "../ventana/WindowManager";

import IAlumno = Entidad.IAlumno;
import CNinioMovimiento = Entidad.CNinioMovimiento;

interface IMovimientoChild extends Entidad.IMovimientoChild {
    StrMovimiento?: string;
    InicioFmt?: string;
    FinFmt?: string;
}
export class UIPanelCardAlumnosInfoGralPreview extends CardV2CollapseAdvancedTable<IMovimientoChild, [IAlumno]> {

    public form: FormGenerator<IAlumno>;
    private listaMovimientos: Array<IMovimientoChild>;

    constructor(modulo: Entidad.CModulo) {
        super("", modulo);
        this.listaMovimientos = [];
    }

    protected CARDCOLLADTAB_Table_GetMenuTop(): Table.ITableMenuTopDefaultOptionConfig[] {
        return null;
    }
    protected CARDCOLLADTAB_GetExportarConfig(dataTable: IMovimientoChild[]): IConfigCardV2CollapseExcelExport<any> | Promise<IConfigCardV2CollapseExcelExport<any>> {
        return {
            FileName: this.cardData[0].NombreCompleto + " - " + this.CARDCOLL_GetUIStringModule("tag_movimientos"),
            IdsEscuelas: [this.cardData[0].IdKinder],
            TypeRequest: Entidad.CTipoRequest.Alumno,
            ColumnsConfig: this.ctrlTabla._InfoColumns
                .map<ExcelThings.IColumnToExcelExportFileConfig<IMovimientoChild>>(d => ({
                    Field: d.Field,
                    HeaderTag: d.Label,
                    // TypeData: (d.Field == "Inicio" || d.Field == "Fin" ? "date" : "text"),
                })),
            OnGetDataBySheets: async () => {
                return [{
                    IdSheet: this.cardData[0].IdKinder,
                    SheetName: this.cardData[0].NombreCompleto,
                    Data: dataTable
                }]
            },
            OnGetEscuelasTagInSheet: (dataSheet) => DataModuloEscuela._DiccEscuela.get(this.cardData[0].IdKinder).Nombre
        }
    }
    protected CARDCOLLADTAB_Table_GetConfig(): Omit<Table.IConfig<IMovimientoChild>, "Parent"> {
        return {
            IdTabla: "AlumnosPanelInformacionGeneral",
            Title: this.CARDCOLL_GetUIStringModule("tag_movimientos"),
            MinWidth: 500,
            RenderColumnHeadings: [
                { Field: "StrMovimiento", Label: "Movimiento", Width: "30%", MinWidth: "90px" },
                { Field: "InicioFmt", LabelLangKey: "d_field_inicio", Label: "FechaInicio", Width: "30%", MinWidth: "75px", OrderField: "Inicio", OrderTypeParse: Date },
                { Field: "FinFmt", LabelLangKey: "d_field_fin", Label: "FechaFin", Width: "30%", MinWidth: "75px", OrderField: "Fin", OrderTypeParse: Date },
            ],
            OnValueSelectRow: (id, dato) => console.log(id, dato),
            OrderDefault: { Type: Table.CStatusOrder.Desc, Field: "Inicio" },
            FilterByStrSearch: "none",
            HideCheckboxes: true,
            // OptionsOfDataCheckV2: () => this.CARDCOLLADTAB_GetTableOptionsToSelectedData(this.cardData[0].IdKinder, "top-selected"),
        }
    }
    protected CARDCOLL_OnInitBuild(container: TSelectionHTML<"div", any, any>): void {
        // -> Área Formulario
        this.form = UIUtilViewAlumno._GetAlumnoForm(Entidad.CAccionPermiso.Editar, Entidad.CModulo.PanelInfoGeneral); // this.cardData[0]);
        container.append(() => this.form._Form.node());

        this.CARDCOLLADTAB_CreateTable(container);

        this.ctrlTabla._UpdateData(this.listaMovimientos);
        this.ctrlTabla._Control
            .style("width", "auto")
            .style("height", "auto")
            .style("overflow", "visible");

        this.form._ControlsData.forEach((c, k) => c.row.classed("row_" + k.toLowerCase(), true));
    }
    protected CARDCOLL_GetVariantToValidateUpdate(cardData_0: IAlumno): string {
        return (cardData_0?.IdChild + cardData_0?.Modificacion).toString();
    }
    protected CARDCOLL_OnUpdateData(cardData_0: IAlumno): void | Promise<void> {
        return this.ActualizarCard(cardData_0, false);
    }
    protected CARDCOLL_MostrarBody(): void {
        this.UpdateUIFromPermissions();
        this.UI_UpdateMovimientosTable(true);
    }
    protected CARDCOLL_OcultarBody(): void {
    }
    protected CARDCOLL_OnEditarCard(originEvent: TCARDV2COLL_OnEditOriginEvent): void {
        if (this.form) {
            this.form._Form.node()
                .scrollIntoView({
                    behavior: "smooth"
                });
        }
        this.UI_UpdateModalAndFormMode();
    }
    protected CARDCOLL_OnCancelaEditarCard(originEvent: TCARDV2COLL_OnEditOriginEvent): void {
        this.CARDCOLL_UICardHasError();
        this.UI_ResetFormData(this.cardData[0]);
        this.UI_UpdateModalAndFormMode();
        if (originEvent == "onCancel") {
            this.UI_UpdateMovimientosTable();
        }
    }
    protected async CARDCOLL_GuardarCardV2(): Promise<DataDRequest.IRequestResponseA<any>> {
        this.form["__noshownotification__"] = undefined;
        if (this.form._GetIsValidForm()) {
            const dataForm = this.form._Data;

            if (dataForm.IdChildMovimiento == CNinioMovimiento.Activo) {
                if (!UIUtilViewAlumno._NotiFalloEditEstadoYGrado(this.form._DataOrigin.IdChildMovimiento, this.form._DataOrigin.IdGrado, dataForm.IdGrado)) {
                    return null;
                }
                let movementChildValidity = UIUtilViewAlumno._ValidarTutorYHorario(this.form._DataOrigin);
                if (!movementChildValidity.IsValid) {
                    let resStepsGuide = await UIUtilViewAlumno._TutorYHorarioStepGuideForm(movementChildValidity.SpecificErr, dataForm.IdChild, this.cardData[0].IdKinder)
                    if (resStepsGuide) {
                        this._CancelarEdicionCard();
                        document.querySelector<HTMLElement>(".expander_list .card_infogeneral").click()
                    }
                    return null;
                }
            }

            return new Promise(async (resolve) => {
                // (1) Confirmación del usuario.
                // (2) Consumo de servicio para editar datos.
                // (3) Si (2) es correcto, se lanza el ReloadService.
                // (4) Al finalizar ReloadService se invoca CALLBACK_FIN
                dataForm.FechaNacimiento = UIUtilViewAlumno._GetDtNacimientoFixedTZ(dataForm);
                const res = await UIUtilViewAlumno._ProcesoFinal_ActualizarAlumno(this.cardData[0], dataForm, true, (res) => {
                    // NOTE (res.Resultado > 0) Proceso es exitoso
                    if (dataForm.IdChildMovimiento == CNinioMovimiento.BajaDefinitiva || dataForm.IdChildMovimiento == CNinioMovimiento.Graduado) {
                        resolve(null);
                        setTimeout(() => {
                            NotificacionV2._DestroyAll();
                            NotificacionV2._Mostrar(res.Mensaje, "INFO");
                            UIWindowManager._DoHash("alumnos/alumnos");
                        }, 50);
                    } else {
                        resolve(res);
                    }
                });

                if (!res || res.Resultado <= 0) {
                    resolve(res);
                }
            })
        }
        this.form["__noshownotification__"] = 1;
        return null;
    }
    protected CARDCOLL_SyncOrGetIdToDownloadData(): DataModuloMain.TipoRequestMonitorId | DataModuloMain.TipoRequestMonitorId[] | (() => Promise<any>) {
        return Entidad.CTipoRequest.Alumno;
    }
    protected CARDCOLL_GetIdSchool(cardData_0: IAlumno): number {
        return cardData_0.IdKinder;
    }

    protected CARDCOLL_UICardHasError(error: boolean = null) {
        super.CARDCOLL_UICardHasError(this.cardData[0]?.IdChildMovimiento !== CNinioMovimiento.Activo, this.CARDCOLL_GetUIStringModule("notif_noactivo"))
    }

    // **************************************************************************
    // PRIVATE METHODS
    // **************************************************************************

    private UI_UpdateModalAndFormMode() {
        this.form._PreviewMode(!this.CARDCOLL_StatusCardEditando);
    }

    private UI_ResetFormData(alumno: IAlumno) {
        // >> Update Form Lists
        let updatedLists = UIUtilViewAlumno._GetListasToForm(alumno, Entidad.CAccionPermiso.Editar, this.modulo as any);
        (this.form._ControlsData.get("IdChildMovimiento").instance as SelectV2)._UpdateList(updatedLists.ListaEdos);
        (this.form._ControlsData.get("IdKinder").instance as SelectV2)._UpdateList(updatedLists.ListaEscuelas);

        // >> Update data form
        let datoToForm = Object.assign({}, this.cardData[0]);
        datoToForm.FechaNacimiento = (this.cardData[0].FechaNacimiento ? UIUtilTime._FmtToInputDate(this.cardData[0].FechaNacimiento, this.cardData[0].IdKinder) : this.cardData[0].FechaNacimiento);
        this.form._AsignaData(datoToForm);
        this.form["__noshownotification__"] = 1;
        this.form._GetIsValidForm();
    }

    private UI_UpdateMovimientosTable(showProgress = false) {
        if (this.CARDCOLL_StatusCardExpandido && this.cardData !== undefined) {
            return new Promise<void>(async (resolve, reject) => {
                if (showProgress) this.ctrlProgress.attr("oculto", false);
                let listaMovimientos = await this.GetHistorialMovimientos();
                this.ctrlTabla._UpdateData(listaMovimientos);
                if (showProgress) this.ctrlProgress.attr("oculto", true);
                resolve();
            })
        }
        else {
            this.ctrlTabla._UpdateData([]);
            return null;
        }
    }

    private ActualizarCard(cardData_0 = this.cardData[0], progress = false) {
        this.CARDCOLL_UICardHasError();
        this.UI_ResetFormData(cardData_0);
        this.UI_UpdateModalAndFormMode();
        return this.UI_UpdateMovimientosTable(progress);
    }

    // **************************************************************************
    // PERMISOS COSAS
    // **************************************************************************

    private UpdateUIFromPermissions() {
        if (!this.CARDCOLL_StatusCardEditando) {
            this.btnEditarCard._d3Selection.classed("hide", !this.HasActionPermission(Entidad.CAccionPermiso.Editar));
        }
    }

    // **************************************************************************
    //  COSAS: SERVICIOS Y DATA
    // **************************************************************************

    private async GetHistorialMovimientos(ninio: Entidad.IAlumno = this.cardData[0]) {
        let res = await _SvAlumnoObtenerHistorialMovimiento(ninio.IdChild, ninio.IdKinder);
        if (res.Resultado > 0) {
            this.listaMovimientos = res.Data
                .map((datumMov: IMovimientoChild, i) => {
                    datumMov.StrMovimiento = UIUtilViewData._GetStr_EdoMovimiento(datumMov.IdMovimiento);
                    datumMov.InicioFmt = UIUtilTime._DateFormatStandar(datumMov.Inicio);
                    datumMov.FinFmt = UIUtilTime._DateFormatStandar(datumMov.Fin);
                    return datumMov;
                });
        } else if (this.listaMovimientos[0]?.IdAlumno != ninio.IdChild) {
            this.listaMovimientos = [];
        }
        // console.debug(this.listaMovimientos, "movimiendosthis.ListaMovimientos");
        return this.listaMovimientos;
    }

}
