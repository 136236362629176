import { DataDRequest } from "../DRequest";
import { Entidad } from "../Entidad";
import { Global } from "../Global";

export function _ObtenerCalendarios(idsEscuelas: number[]) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "calendario/ObtenerCalendarios";
    const params = {
        "IdsEscuelas": idsEscuelas,
    }
    return DataDRequest._Request5<Entidad.ICalendario[]>(url, "GET", params);
}

export default { _ObtenerCalendarios }