import { DataDRequest } from "../../data/DRequest";
import { Entidad } from "../../data/Entidad";
import { _SvAlumnoRecuperarContraseniaViaEmail, _SvAlumnoRecuperarContraseniaViaSMS } from "../../data/modulo/Tutor";
import _L from "../../util/Labels";
import { List } from "../controlD3/List";
import { ModalThings } from "../controlD3/ModalThings";

type ITutor = Entidad.ITutor

interface IRecuperarContraseniaConfig {
    tutores: ITutor[]
    typeRequest: Entidad.CTipoRequest.Tutor | Entidad.CTipoRequest.TutorAsignacion
    modulo: Entidad.CModulo.Tutores | Entidad.CModulo.PanelTutores
    // action: Entidad.CAccionPermiso.EnviarEmail | Entidad.CAccionPermiso.EnviarSMS
}
export namespace UIUtilViewTutor {
    const MAX_INTENTOS = 3
    export function _RecuperarContraseniaViaEmail({ tutores, typeRequest, modulo }: IRecuperarContraseniaConfig) {
        const action = Entidad.CAccionPermiso.EnviarEmail
        if (!tutores.length) {
            this.ctrlNotification._Mostrar(_L("paneltutores.notif_selecttutor"), "ADVERTENCIA");
            return
        }
        tutores = fnEliminarDuplicados(tutores, "Correo")
        let fallos = 0
        let auxFailResponse: DataDRequest.IRequestResponseA
        ModalThings._OpenModalToProccessServiceByServiceFromAArrayBasic({
            Title: _L("tutores.action_sendemail"),
            Message: _L("tutores.tlt_sendemail_desc"),
            DataToProccess: tutores,
            Width: 300,
            TypeRequest: typeRequest,
            Modulo: modulo,
            Action: action,
            OnDrawContent: (container) => fnBuildTutoresList(container, tutores, "Correo"),
            OnError_GetItemDataTag: (d) => `${d.NombreCompleto} (${d.Correo})`,
            OnEndProccess: () => (fallos = 0),
            OnStepAProccess: (tutor) => {
                if (fallos >= MAX_INTENTOS) {
                    return new Promise((resolve) => resolve({ ...auxFailResponse, Resultado: -1.1 }))
                }
                return _SvAlumnoRecuperarContraseniaViaEmail(tutor.Correo)
                    .then(res => {
                        if (res.Resultado < 1) {
                            fallos++
                            auxFailResponse = res
                        }
                        return res
                    })
            },
        })
    }

    export function _RecuperarContraseniaViaSMS({ tutores, typeRequest, modulo }: IRecuperarContraseniaConfig) {
        if (!tutores.length) {
            this.ctrlNotification._Mostrar(_L("paneltutores.notif_selecttutor"), "ADVERTENCIA");
            return
        }
        const action = Entidad.CAccionPermiso.EnviarSMS
        tutores = fnEliminarDuplicados(tutores, "Correo")
        let fallos = 0
        let auxFailResponse: DataDRequest.IRequestResponseA
        ModalThings._OpenModalToProccessServiceByServiceFromAArrayBasic({
            Title: _L("tutores.action_sendsms"),
            Message: _L("tutores.tlt_sendsms_desc"),
            DataToProccess: tutores,
            Width: 300,
            TypeRequest: typeRequest,
            Modulo: modulo,
            Action: action,
            OnDrawContent: (container) => fnBuildTutoresList(container, tutores, "Telefono"),
            OnError_GetItemDataTag: (d) => `${d.NombreCompleto} (${d.Telefono})`,
            OnEndProccess: () => (fallos = 0),
            OnStepAProccess: (tutor) => {
                if (fallos >= MAX_INTENTOS) {
                    return new Promise((resolve) => resolve({ ...auxFailResponse, Resultado: -1.1 }))
                }
                return _SvAlumnoRecuperarContraseniaViaSMS(tutor.Correo)
                    .then(res => {
                        if (res.Resultado < 1) {
                            fallos++
                            auxFailResponse = res
                        }
                        return res
                    })
            },
        })
    }

    const fnEliminarDuplicados = (tutores: ITutor[], field: (keyof Pick<ITutor, "Correo" | "Telefono">)) => tutores.reduce((result, d) => {
        if (result.some(r => r[field] == d[field])) {
            return result
        }
        return (result.push(d), result)
    }, <ITutor[]>[])

    const fnBuildTutoresList = (container: TSelectionHTML, tutores: ITutor[], type: (keyof Pick<ITutor, "Correo" | "Telefono">)) => new List<ITutor>()
        ._SetParent(container)
        ._SetCreateTemplate((itemContainer) => {
            itemContainer.append("label")
            itemContainer.append("br")
            itemContainer.append("span").style("font-size", "calc(var(--fontsize) - 3px)")
        })
        ._SetUpdateItem((itemContainer, t) => {
            itemContainer.select("label").text(t.NombreCompleto)
            itemContainer.select("span").text(t[type])
        })
        ._SetItems(tutores)
        ._ListContainerSelection
        .style("margin-top", "var(--padding1)")
}