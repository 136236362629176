import * as d3 from "d3";
import { MainPage } from "../../MainPage";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import { ICatalogoSATClaveProductServ } from "../../data/entidad/Factura";
import { _DiccEscuela } from "../../data/modulo/Escuela";
import { _SvFinanzasActualizarCargo, _SvFinanzasNuevoCargo, _ObtenerClaveProdServSATByCargo } from "../../data/modulo/FinanzaCargo";
import { _SvFinanzasNuevoCargoAsignAlumnoNoPlantilla } from "../../data/modulo/FinanzasAsignaciones";
import { ArrayV2 } from "../../util/ArrayV2";
import { DateV2 } from "../../util/DateV2";
import _L, { _HttpMsg, _HttpMsgV2 } from "../../util/Labels";
import { Button } from "../controlD3/Button";
import { CalendarioGridCargos } from "../controlD3/CalendarioGridCargos";
import { Fields, FormGenerator, IField } from "../controlD3/Formulario";
import { ModalThings } from "../controlD3/ModalThings";
import { NotificacionV2 } from "../controlD3/NotificacionV2";
import { RadioList } from "../controlD3/RadioList";
import { SelectV2 } from "../controlD3/SelectV2";
import { UIUtilFormat } from "../util/Format";
import { UIUtilLang } from "../util/Language";
import { UIUtilPermission } from "../util/Permission";
import { UIUtilTime } from "../util/Time";
import { UIUtilGeneral } from "../util/Util";
import { UIUtilViewData } from "../util/ViewData";
import { UIUtilViewEscuelas } from "./Escuelas";
import { UIUtilViewFinanzaHorasExtras } from "./FinanzaHorasExtras";

export namespace UIUtilViewFinanzaCargo {
    import IFinanzaCargo = Entidad.IFinanzaCargo;
    import CCategoriaTipoFinanza = Entidad.CFinanzaCargoCategoria
    import CPeriodicidadCargos = Entidad.CFinanzaCargoPeriodicidadCargos;
    import CTipoValor = Entidad.CFinanzaCargoTipoValor;
    import CAccionPermiso = Entidad.CAccionPermiso;
    import CTipoInteres = Entidad.CFinanzaCargoTipoInteres;
    import CTipoTasa = Entidad.CFinanzasCargoTipoTasa;
    import CFinanzasCargoRecurrenciaRecargo = Entidad.CFinanzasCargoRecurrenciaRecargo;
    // import CTipoInteres = data.Entidades.CFinanzaCargoTipoInteres;

    type ICatalogoSATClaveProductServForm = ICatalogoSATClaveProductServ & {
        full_name: string
    }

    export enum CFormCargoField {
        Nombre = "Nombre",
        TipoValor = "TipoValor",
        Valor = "Valor",
        // Modalidad = "Modalidad",
        Periodicidad = "Periodicidad",
        Escuela = "IdEscuela",
        Escuelas = "IdEscuelas",
        TipoRecargo = "TipoRecargo",
        TipoInteres = "TipoInteres",
        ValorRecargo = "ValorRecargo",
        TipoTasa = "TipoTasa",
        IntervaloBloque = "IntervaloBloque",
        IdCicloEscolar = "IdCicloEscolar",
        FechaAplicacion = "FechaAplicacion",
        ClaveProdServSAT = "ClaveProdServSAT",
    }

    const langContextModuleFCargo = "finanzascargo";
    const INTERVALOBLOQUE_VALOR_NINGUNO = -1 as any

    export function _GetCustomFieldScheme(field: CFormCargoField, frm: FormGenerator<TFinanzaCargoForm>): IField<TFinanzaCargoForm> {
        switch (field) {
            case CFormCargoField.Escuela:
                return {
                    model: CFormCargoField.Escuela,
                    type: Fields.selectMaterial,
                    labelText: "frm1_esc",
                    selectMaterialAttr: {
                        valueMember: "IdKinder", displayMember: "Nombre", required: true,
                        onChange: () => {
                            const selectPS = frm._ControlsData.get("ClaveProdServSAT").instance as SelectV2<ICatalogoSATClaveProductServForm, "key", "monoselect">;
                            if (!selectPS) return
                            selectPS._UpdateList(GetDataFromCargoFormAndClavePSVisible(frm))
                        }
                    }
                };
                break;
            case CFormCargoField.Escuelas:
                return {
                    model: CFormCargoField.Escuelas,
                    type: Fields.selectMaterial,
                    labelText: "frm1_esc",
                    selectMaterialAttr: {
                        multiselect: true, valueMember: "IdKinder", displayMember: "Nombre", required: true,
                        onChange: () => {
                            const selectPS = frm._ControlsData.get("ClaveProdServSAT").instance as SelectV2<ICatalogoSATClaveProductServForm, "key", "monoselect">;
                            if (!selectPS) return
                            selectPS._UpdateList(GetDataFromCargoFormAndClavePSVisible(frm))
                        },
                    },
                }
                break;
            case CFormCargoField.Nombre:
                return {
                    model: CFormCargoField.Nombre,
                    type: Fields.input,
                    inputAttr: { type: "text", required: true },
                    labelText: "frm1_nombre"
                };
                break;
            case CFormCargoField.Valor:
                return {
                    model: CFormCargoField.Valor,
                    type: Fields.input,
                    inputAttr: { type: "number", min: 0, required: true, step: ".01" },
                    labelText: "frm1_valor",
                    onValidate: (val) => {
                        let isValid = true
                        let strDecims = val.toString().split(".")[1];
                        if (strDecims) isValid = !(strDecims.length > 2);
                        if (isValid) {
                            isValid = validaValor(val, frm._Data.TipoValor);
                        }
                        return isValid
                    }
                };
                break;
            case CFormCargoField.Periodicidad:
                return {
                    model: CFormCargoField.Periodicidad,
                    type: Fields.selectMaterial,
                    labelText: "frm1_periodic",
                    selectMaterialAttr: { Data: UIUtilViewData._GetList_Periodicidad(), valueMember: "Id", displayMember: "Name", required: true },
                };
                break;
            case CFormCargoField.TipoRecargo:
                return {
                    model: CFormCargoField.TipoRecargo,
                    type: Fields.radioList,
                    labelText: "frm1_recarg",
                    radioListAttr: {
                        Data: UIUtilViewData._GetList_TipoValor("complete"),
                        ReturnOnlyValueMember: true,
                        ValueMember: "Id", DisplayMember: "Name", required: true
                    }
                };
                break;
            case CFormCargoField.TipoInteres:
                return {
                    model: CFormCargoField.TipoInteres,
                    type: Fields.radioList,
                    labelText: "frm1_tipointeres",
                    radioListAttr: {
                        ReturnOnlyValueMember: true,
                        ValueMember: "Id", DisplayMember: "Name", required: true,
                        Data: () => {
                            const opcionNA = { Id: Entidad.CFinanzaCargoTipoInteres.MontoUnico, Name: _L("general.ninguno") }
                            const opcionISimple = { Id: Entidad.CFinanzaCargoTipoInteres.Simple, Name: _L("c_tipointeres.simple") }
                            const opcionICompuesto = { Id: Entidad.CFinanzaCargoTipoInteres.Compuesto, Name: _L("c_tipointeres.compuesto") }
                            return [opcionNA, opcionISimple, opcionICompuesto]
                        },
                        OnChange: (data) => {
                            console.debug("Vent_FinCargo&Desc ->", data, "radios tipo interes")
                            // const tipoTasaCtrl = frm._GetModelControl<"radioList">(CFormCargoField.TipoTasa);
                            // const tipoTasaRadioCtrl = tipoTasaCtrl.instance;
                            const esInteresPorcentaje = [CTipoInteres.Simple, CTipoInteres.Compuesto].includes(data.Id);
                            // const esInteresRecDiaria = frm._Data.IntervaloBloque == CFinanzasCargoRecurrenciaRecargo.Diaria;
                            const frmIntervaloBloqueValue = frm._GetModelValue(CFormCargoField.IntervaloBloque)

                            if (esInteresPorcentaje && (frmIntervaloBloqueValue == null || frmIntervaloBloqueValue == INTERVALOBLOQUE_VALOR_NINGUNO)) {
                                // tipoTasaCtrl.selection.select(".itemradio").classed("hide", true); // Oculta opcion "No apĺica"
                                // if (frm._Data.TipoTasa == CTipoTasa.NoAplica) {
                                //     tipoTasaRadioCtrl._SetValueSelected(esInteresRecDiaria ? CTipoTasa.Diaria : CTipoTasa.Mensual); // Recurrencia mensual solo tiene Tasa mensual
                                // }
                                frm._SetModelValue(CFormCargoField.IntervaloBloque, CFinanzasCargoRecurrenciaRecargo.Diaria)
                            } else if (!esInteresPorcentaje) {
                                // tipoTasaRadioCtrl?._SetValueSelected(CTipoTasa.NoAplica);
                                frm._SetModelValue(CFormCargoField.IntervaloBloque, INTERVALOBLOQUE_VALOR_NINGUNO)
                            }
                            // tipoTasaCtrl.row.classed("hide", !(esInteresRecDiaria && esInteresPorcentaje))
                            frm._GetModelControl(CFormCargoField.IntervaloBloque).row.classed("hide", !esInteresPorcentaje)
                            RefreshTipoTasaItemForm(frm)
                        }
                    },
                };
                break;
            case CFormCargoField.ValorRecargo:
                return {
                    model: CFormCargoField.ValorRecargo,
                    type: Fields.input,
                    inputAttr: { type: "number", min: 0, required: true, step: ".01" },
                    labelText: "frm1_cant",
                    onValidate: (valor) => {
                        const tipoRecargo = frm._Data.TipoRecargo
                        let isValid = true
                        if (tipoRecargo != CTipoValor.Ninguno) {
                            let strDecims = valor.toString().split(".")[1];
                            if (strDecims) isValid = !(strDecims.length > 2);
                            if (isValid) {
                                isValid = validaValor(valor, tipoRecargo);
                            }
                        } else if (frm._Data.ValorRecargo != 0) {
                            frm._SetModelValue("ValorRecargo", 0);
                        }
                        return isValid
                    }
                };
                break;
            case CFormCargoField.IntervaloBloque:
                return {
                    model: CFormCargoField.IntervaloBloque,
                    type: Fields.radioList,
                    labelText: "frm1_recurrencia",
                    radioListAttr: {
                        ValueMember: "Id", DisplayMember: "Name", required: true, ReturnOnlyValueMember: true, Direction: "horizontal",
                        Data: () => {
                            const opcionNA = { Id: INTERVALOBLOQUE_VALOR_NINGUNO, Name: _L("general.ninguno") }
                            const opcionDiario = { Id: CFinanzasCargoRecurrenciaRecargo.Diaria, Name: _L("finanzascargo.tag_daily") }
                            const opcionMensual = { Id: CFinanzasCargoRecurrenciaRecargo.Mensual, Name: _L("finanzascargo.tag_monthly_fraction"), }
                            if (frm._GetModelValue("TipoRecargo") == CTipoValor.Monto) {
                                return [opcionNA, opcionDiario]
                            }
                            return [opcionDiario, opcionMensual]
                        },
                        OnChange: (d, id: CFinanzasCargoRecurrenciaRecargo) => {
                            RefreshTipoTasaItemForm(frm)
                        }
                    },
                };
                break;
            case CFormCargoField.TipoTasa:
                return {
                    model: CFormCargoField.TipoTasa,
                    type: Fields.radioList,
                    labelText: "frm1_tipotasa",
                    onValidate: () => {
                        if ([CTipoInteres.Compuesto, CTipoInteres.Simple].includes(frm._GetModelValue("TipoInteres"))) {
                            if (frm._GetModelValue("TipoTasa") == CTipoTasa.NoAplica) {
                                return false
                            }
                        } else {
                            frm._SetModelValue("TipoTasa", CTipoTasa.NoAplica) // NOTE ???
                            // dataForm["TipoTasa"] = CTipoTasa.NoAplica;
                        }
                        return true
                    },
                    radioListAttr: {
                        // "tag_daily": "", // FIXME
                        Data: [
                            { Id: CTipoTasa.NoAplica, Name: UIUtilLang._GetUIString(langContextModuleFCargo, "tag_dnaplly") },
                            { Id: CTipoTasa.Diaria, Name: UIUtilLang._GetUIString(langContextModuleFCargo, "tag_daily") },
                            { Id: CTipoTasa.Mensual, Name: UIUtilLang._GetUIString(langContextModuleFCargo, "tag_monthly") } // FIXME ENUM
                        ],
                        ValueMember: "Id", DisplayMember: "Name", required: true, ReturnOnlyValueMember: true,
                    },
                }
                break;
            case CFormCargoField.IdCicloEscolar:
                return {
                    model: "IdCicloEscolar",
                    type: Fields.selectMaterial,
                    labelText: "Ciclo Escolar",
                    selectMaterialAttr: {
                        valueMember: "Id", displayMember: "Nombre", required: true
                    },
                }
                break;
            case CFormCargoField.FechaAplicacion:
                return {
                    model: "FechaAplicacion",
                    type: Fields.input,
                    inputAttr: { type: "date", required: true },
                    labelText: "Aplicación"
                }
                break;
            case CFormCargoField.ClaveProdServSAT:
                return {
                    model: "ClaveProdServSAT",
                    type: "selectMaterial",
                    labelText: "frm1_claveprodservsat",
                    selectMaterialAttr: {
                        valueMember: "key",
                        displayMember: "full_name",
                        required: true,
                        ShowAndEnableSearchText: true,
                        RecyclerView: true,
                        ListWidth: "450px",
                        OnChangeSearchText_GetDataValueToEval: ({ key, name }: { key: string, name: string }) => (key + name),
                        Data: GetDataFromCargoFormAndClavePSVisible(frm),
                        onChange: (_, d: ICatalogoSATClaveProductServ) => {
                            frm._DataOrigin.NombreProdServSAT = d?.name || ""
                        }
                    }
                }
        }
    }

    function GetDataFromCargoFormAndClavePSVisible(frm: FormGenerator<TFinanzaCargoForm>): Promise<ICatalogoSATClaveProductServForm[]> {
        return new Promise((resolve, reject) => setTimeout(() => {
            const idCargo = frm._DataOrigin?.ID
            const idsEscuelas: number[] = (() => {
                const d = frm._Data
                if (frm._ControlsData.has("IdEscuela")) {
                    return d.IdEscuela ? [d.IdEscuela] : []
                } else if (frm._ControlsData.has("IdEscuelas")) {
                    return (d.IdEscuelas || [])
                }
            })()
            const usarClaves = (idsEscuelas?.length
                ? idsEscuelas.every(id => !!_DiccEscuela.get(id)?.UsaFactura)
                : false)

            frm._ControlsData.get("ClaveProdServSAT").row.classed("hide", !usarClaves) // -> ClavePSVisible
            if (!usarClaves) {
                resolve([])
                return
            }
            _ObtenerClaveProdServSATByCargo(idsEscuelas[0], idCargo)
                .then(res => {
                    if (res.Resultado == -2) {
                        NotificacionV2._Mostrar(_HttpMsg("factura/base", res.Resultado), "ADVERTENCIA")
                    }
                    else if (res.Resultado <= 0) {
                        NotificacionV2._Mostrar(_HttpMsgV2(res), "ADVERTENCIA")
                    }
                    const dataFinal = res.Datos.map((d) => (<ICatalogoSATClaveProductServForm>{ ...d, full_name: `${d.key} - ${d.name}` }))
                    dataFinal.length
                        ? resolve(dataFinal)
                        : reject()
                })
                .catch(reject)
        }))
    }

    export function _GetStrRecargosCol(datoCargo: Pick<IFinanzaCargo, "TipoRecargo" | "Categoria" | "ValorRecargo" | "TipoInteres" | "TipoTasa" | "IntervaloBloque"> /* & { TipoTasa?: CTipoTasa } */) {
        const esCargoValido = [CCategoriaTipoFinanza.Cargo, CCategoriaTipoFinanza.CargoEntrada, CCategoriaTipoFinanza.CargoSalida]
            .includes(datoCargo.Categoria);
        let str = "";
        if (!esCargoValido || datoCargo.TipoRecargo == CTipoValor.Ninguno) {
            return str;
        }
        if (datoCargo.TipoRecargo == CTipoValor.Porcentaje) {
            const generaInteres = datoCargo.TipoInteres != CTipoInteres.MontoUnico
            str = UIUtilFormat._PercentFmt(datoCargo.ValorRecargo);
            if (generaInteres)
                str += " " + UIUtilViewData._GetStr_TipoInteres(datoCargo.TipoInteres);
            else
                str += " " + _L("finanzascargo.tag_recargounico")
            if (generaInteres) {

                if ([CTipoTasa.Diaria, CTipoTasa.Mensual].includes(datoCargo.TipoTasa)) {
                    str += " (" + UIUtilViewData._GetStr_TipoTasa(datoCargo.TipoTasa) + ")";
                }
                if (datoCargo.IntervaloBloque == CFinanzasCargoRecurrenciaRecargo.Diaria) {
                    str += ", " + _L("finanzascargo.tag_recurrencia_dia").toLowerCase();
                } else if (datoCargo.IntervaloBloque == CFinanzasCargoRecurrenciaRecargo.Mensual) {
                    str += ", " + _L("finanzascargo.tag_recurrencia_mes").toLowerCase();
                }
            }
        } else {
            str = UIUtilFormat._CurrencyFmt(datoCargo.ValorRecargo)
            if (datoCargo.TipoInteres == CTipoInteres.MontoUnico) {
                str += " " + UIUtilViewData._GetStr_TipoInteres(datoCargo.TipoInteres);
            }
            else if (datoCargo.TipoInteres == CTipoInteres.Simple) {
                if (datoCargo.IntervaloBloque == CFinanzasCargoRecurrenciaRecargo.Diaria) {
                    str += " " + _L("finanzascargo.tag_recurrencia_dia")
                } else if (datoCargo.IntervaloBloque == CFinanzasCargoRecurrenciaRecargo.Mensual) {
                    str += " " + _L("finanzascargo.tag_recurrencia_mes")
                }
            }
        }
        return str;
    }

    /** Los descuentos se validan individualmente sobre el resultado de reducción (cargoRestante) del descuento anterior (en el arreglo) sobre el cargo
     * * Nota: Solo el primer elemento del arreglo aplica la validación del descuento sobre el cargo original
     */
    export function _DATA_ValidaDescuentosACargo<T extends Pick<IFinanzaCargo, "TipoValor" | "Valor">>(cargo: number, descuentosInfo: T[]) {
        let descuentoTotal = 0;
        let cargoRestante = cargo;
        let esValido = false;
        let itemsMayoresACargo: T[] = [];
        let itemsEnCero: T[] = [];

        if (cargo > 0) {
            itemsMayoresACargo = descuentosInfo
                .filter(datoDesc => {
                    let descuentoMonto = 0;
                    switch (datoDesc.TipoValor) {
                        case CTipoValor.Monto:
                            descuentoMonto = datoDesc.Valor;
                            descuentoTotal += datoDesc.Valor;

                            break;
                        case CTipoValor.Porcentaje:
                            descuentoMonto = (cargoRestante * (datoDesc.Valor / 100));
                            descuentoMonto = ((descuentoMonto < 0) ? (-1 * descuentoMonto) : descuentoMonto);

                            descuentoTotal += descuentoMonto;
                            // cargoRestante -= descuentoMonto;
                            if (descuentoMonto == 0) {
                                itemsEnCero.push(datoDesc);
                            }
                            break;
                    }

                    if (descuentoMonto > cargoRestante) {
                        // cargoRestante -= descuentoMonto;
                        return true;
                    }

                    // Se ajusta cargo restante para calcular el proximo descuento
                    cargoRestante -= descuentoMonto;
                    return false;
                });

            esValido = ((itemsEnCero.length == 0) && (descuentoTotal <= cargo)); // (cargoRestante >= 0);
        }

        // Es inválido si
        // * Si el valor inicial del cargo es igual a cero
        // * El valor de descuento total es mayor al valor inicial del cargo
        // * Existen items mayores con valores mayores al cargo
        // * Algun descuento (%) es igual a 0. Es porque eventualmente el cargo restante llegó a 0

        return {
            Valido: esValido, // (descuentoTotal <= cargo),
            DescuentoMontoTotal: descuentoTotal,
            DescuentosMayoresACargo: itemsMayoresACargo,
            DescuentosEnCero: itemsEnCero
        }
    }

    /** Los descuentos se validan individualmente sobre el resultado de reducción (cargoRestante) del descuento anterior (en el arreglo) sobre el cargo
     * * Nota: Solo el primer elemento del arreglo aplica la validación del descuento sobre el cargo original
     */
    export function _UI_ValidaDescuentosACargo<T extends Pick<IFinanzaCargo, "TipoValor" | "Valor" | "Nombre">>(cargoValor: number, cargoNombre: string, descuentos: T[]) {
        if (descuentos.length) {
            const validacionRes = _DATA_ValidaDescuentosACargo(cargoValor, descuentos);

            if (!validacionRes.Valido) {
                const fnGetTalLang = (str: string) => UIUtilLang._GetUIString(langContextModuleFCargo, str);

                if (validacionRes.DescuentosMayoresACargo.length) {
                    const descuentosConflict = validacionRes.DescuentosMayoresACargo;
                    if (descuentosConflict.length > 1) {
                        NotificacionV2._Mostrar(
                            fnGetTalLang("notif_conflict_desc_n")
                                .replace("_NDESCS", descuentosConflict.length.toString())
                                .replace("_CARGO", cargoNombre)
                                .replace("_DESCRIP", ""), // Utils.currencyFormater.format(cargoValor)),
                            "ADVERTENCIA"
                        );
                    } else {
                        NotificacionV2._Mostrar(
                            fnGetTalLang("notif_conflict_desc_1")
                                .replace("_DESCUENTO", descuentosConflict[0].Nombre)
                                .replace("_CARGO", cargoNombre)
                                .replace("_DESCRIP", ""), // Utils.currencyFormater.format(cargoValor)),
                            "ADVERTENCIA"
                        );
                    }
                }
                else if (validacionRes.DescuentoMontoTotal > cargoValor) {
                    NotificacionV2._Mostrar(
                        fnGetTalLang("notif_conflict_totdesc")
                            .replace("_CARGO", cargoNombre)
                            .replace("_VALOR", UIUtilFormat._CurrencyFmt(cargoValor)),
                        "ADVERTENCIA"
                    );
                }
                else if (validacionRes.DescuentosEnCero.length) {
                    NotificacionV2._Mostrar(
                        fnGetTalLang("notif_conflict_saldo0")
                            .replace("_CARGO", cargoNombre),
                        "ADVERTENCIA"
                    );
                }
            }

            return validacionRes.Valido
        }
        return false;
    }

    type CCargoFormActions = (CAccionPermiso.Agregar | CAccionPermiso.Editar);
    type TCargoFormOrigin = ("FinanzasCargos" | "HorasExtras" | "AlumnosEstadoCuenta");
    export function _OpenModal_FormAddEditCargo(action: CCargoFormActions, cargo: TFinanzaCargoForm, formCargo: FormGenerator<TFinanzaCargoForm>, origin: TCargoFormOrigin = "FinanzasCargos", idAlumno?: number) {
        const esEditar = (action == CAccionPermiso.Editar);
        const fromHorasExtras = (origin == "HorasExtras");
        const fromEdoCuenta = (origin == "AlumnosEstadoCuenta");
        return new Promise<boolean>(resolve => {
            const StepsConfig: ModalThings.IConfigModalToALongProccessItem[] = []
            // Paso 1: FORM CARGO
            const CargoFormStep = _GetStepCargoForm();
            CargoFormStep.Title = (esEditar) ? "action_editcargo" : "action_addcargo";
            CargoFormStep.Width = 560;
            CargoFormStep.OnDrawContent = (content) => {
                content.append(() => formCargo._Form.node());
            }
            CargoFormStep.OnFocusContent = (content, mt) => {
                Button._EnableButton(mt.BtnRight, true);
            }
            CargoFormStep.OnValideStep = () => {
                if (formCargo._GetIsValidForm()) {
                    if (fromHorasExtras || fromEdoCuenta) {
                        //Usar cuando sea FromHorasExtras
                        cargo = { ...cargo, ...formCargo._Data };
                    }
                    else {
                        //Usar cuando no sea FromHorasExtras
                        cargo = { ...cargo, ...formCargo._Data, MesAplicacion: cargo.MesAplicacion, DiaAplicacion: cargo.DiaAplicacion, DiaMorosidad: cargo.DiaMorosidad, DiaVencimiento: cargo.DiaVencimiento };
                    }
                    return true;
                }
                else {
                    return false;
                }
            }
            // PASO 2: CALENDAR SELECTION
            const CargoAplicacionCalendar = _GetStepCargoAplicacionCalendar();
            CargoAplicacionCalendar.OnDrawContent = (content, mt) => _DrawCalendar(content);
            CargoAplicacionCalendar.OnFocusContent = (content, mt) => {
                mt.Modal._SetTitle(UIUtilLang._GetUIString("finanzascargo", (esEditar) ? "ttl_editcargo" : "ttl_addcargo").replace("_NAME", cargo.Nombre))
                let calendarGrid: CalendarioGridCargos.CalendarioGridCargos = content["CalendarGrid"];
                let contCalendar = content.select<HTMLDivElement>(".cont_calendar"); // this.propModal.BodyModal.select<HTMLDivElement>(".cargo_perio0dicidad");
                let contInfo = content.select<HTMLDivElement>(".cont_info");

                let updateInfoCalendar = (periodicidad: Entidad.CFinanzaCargoPeriodicidadCargos, inicioMes: number, apli: number, venc: number, moro: number) => {
                    cargo.MesAplicacion = inicioMes;
                    cargo.DiaAplicacion = apli;
                    cargo.DiaVencimiento = venc;
                    cargo.DiaMorosidad = moro;

                    let textaplicacion = "";
                    let textvencimiento = "";
                    let textmorosidad = "";

                    if (fromEdoCuenta) {
                        const dtFechaAplicacion = new Date(UIUtilTime._GetDateConcatenatedDateTime(formCargo._Data.FechaAplicacion));
                        const dtFechaVencimiento = new Date(dtFechaAplicacion);
                        dtFechaVencimiento.setDate(dtFechaVencimiento.getDate() + cargo.DiaVencimiento);
                        const dtFechaMorosidad = new Date(dtFechaVencimiento);
                        dtFechaMorosidad.setDate(dtFechaMorosidad.getDate() + cargo.DiaMorosidad);

                        const fnGetTalLang = (str: string) => UIUtilLang._GetUIString(langContextModuleFCargo, str);

                        textaplicacion = fnGetTalLang("tag_dtaplica") + ": " + UIUtilTime._DateFormatStandar(dtFechaAplicacion, "d MMM yyyy")
                        textvencimiento = fnGetTalLang("tag_dtvence") + ": " + UIUtilTime._DateFormatStandar(dtFechaVencimiento, "d MMM yyyy")
                        textmorosidad = fnGetTalLang("tag_dtmoroso") + ": " + UIUtilTime._DateFormatStandar(dtFechaMorosidad, "d MMM yyyy")
                    } else {
                        textaplicacion = GetTextPeriod(periodicidad, "aplicacion", inicioMes, apli);
                        textvencimiento = GetTextPeriod(periodicidad, "vencimiento", inicioMes, venc);
                        textmorosidad = GetTextPeriod(periodicidad, "morosidad", inicioMes, moro);
                    }


                    if (fromHorasExtras) {
                        formCargo._AsignaData(cargo);
                    }
                    contInfo.select(".tag_1").select(".info").select("label").text(textaplicacion);
                    contInfo.select(".tag_2").select(".info").select("label").text(textvencimiento);
                    contInfo.select(".tag_3").select(".info").select("label").text(textmorosidad);
                }

                if (!calendarGrid) {
                    calendarGrid = new CalendarioGridCargos.CalendarioGridCargos(contCalendar, {
                        ContainerToRedimention: mt.Modal._ModalSelection,
                        OnChangeSomeSelector: (result) => {
                            updateInfoCalendar(cargo.Periodicidad, result.Aplicacion.Month, result.Aplicacion.Days, result.Vencimiento.Days, result.Morocidad.Days);
                        }
                    });
                    content["CalendarGrid"] = calendarGrid;
                } else {
                    if (cargo.Periodicidad != calendarGrid._Periodicidad) {
                        cargo.MesAplicacion = 1;
                        cargo.DiaAplicacion = 1;
                        cargo.DiaVencimiento = 0;
                        cargo.DiaMorosidad = 1;
                        if (fromHorasExtras) {
                            formCargo._AsignaData(cargo);
                        }
                    }
                }

                calendarGrid._Periodicidad = cargo.Periodicidad;
                console.log(cargo.DiaAplicacion, cargo.DiaVencimiento, cargo.DiaMorosidad);
                calendarGrid._SetPosicionSelectores(
                    (cargo.MesAplicacion || 1),
                    (cargo.DiaAplicacion || 1),
                    (cargo.DiaVencimiento || 0),
                    (cargo.DiaMorosidad || 1)
                )

                let initRes = calendarGrid._GetResultSelected();
                updateInfoCalendar(cargo.Periodicidad, initRes.Aplicacion.Month, initRes.Aplicacion.Days, initRes.Vencimiento.Days, initRes.Morocidad.Days);
            }
            // PASO 3: INFO CARGO => ACCEPT CARGO
            const CargoInfoFinalStep = _GetStepCargoInfoFinal();
            CargoInfoFinalStep.Title = (esEditar) ? "tlt_cargoresult" : "tlt_nuevocargo"
            CargoInfoFinalStep.OnDrawContent = (content) => {
                _ApplyInfoTagsV2({ ...cargo, DtAplicacion: (fromEdoCuenta) ? new DateV2(UIUtilTime._GetDateConcatenatedDateTime(formCargo._Data.FechaAplicacion)) : null, IdCicloEscolar: (fromEdoCuenta) ? formCargo._Data.IdCicloEscolar : null }, content);
            }
            CargoInfoFinalStep.OnFocusContent = (content) => {
                _ApplyInfoTagsV2({ ...cargo, DtAplicacion: (fromEdoCuenta) ? new DateV2(UIUtilTime._GetDateConcatenatedDateTime(formCargo._Data.FechaAplicacion)) : null, IdCicloEscolar: (fromEdoCuenta) ? formCargo._Data.IdCicloEscolar : null }, content);
            }
            // OnAccept Config
            if (!fromEdoCuenta) {
                CargoInfoFinalStep.OnAccept = async (MT) => {
                    if (esEditar) {
                        let res = await _SvFinanzasActualizarCargo(cargo);
                        if (res.Resultado > 0) {
                            await MainPage._ReloadServiceAndAwaitBool(Entidad.CTipoRequest.FinanzaCargo, cargo.IdEscuela);
                            resolve(true);
                        }
                        else {
                            resolve(false);
                        }
                        return res;
                    } else {
                        interface DataProcess {
                            Resultado: number,
                            ItemData: { idEscuela: number, cargo: TFinanzaCargoForm },
                            TipoRequest: Entidad.CTipoRequest
                        }

                        let dataProcess = cargo.IdEscuelas.map<DataProcess>(idEscuela => ({
                            Resultado: -1,
                            ItemData: { idEscuela, cargo },
                            TipoRequest: Entidad.CTipoRequest.FinanzaCargo
                        }));
                        await ModalThings._ProccessServiceByServiceFromAArrayIterator(MT, dataProcess, {
                            OnStepAProccess: async (item) => {
                                item.cargo = { ...item.cargo, IdEscuela: item.idEscuela }
                                return _SvFinanzasNuevoCargo(item.cargo);
                            },
                            AccionToHttpMessage: "NuevoCargoV2",
                            OnError_GetItemDataTag: (item, container) => `${DataModuloMain._GetItemDataById(Entidad.CTipoRequest.Escuela, item.idEscuela).Nombre}`,
                        }).catch((err) => {
                            console.log(err);
                            return null;
                        });

                        let arrDataProcess = new ArrayV2<DataProcess>()._Push(...dataProcess);
                        let nReloadErr = 0;
                        await arrDataProcess._ForEachAwait(async (d) => {
                            if (d.Resultado > 0) {
                                await MainPage._ReloadServiceAndAwait(Entidad.CTipoRequest.FinanzaCargo, d.ItemData.idEscuela).catch((err) => {
                                    nReloadErr++;
                                });
                            }
                        });
                        if (nReloadErr > 0) NotificacionV2._Mostrar(UIUtilLang._GetUIString("general", "notif_fail_infoupdate") + "", "ADVERTENCIA");

                        MT.Modal._Ocultar();
                        (dataProcess.filter(d => d.Resultado < 1).length) ? resolve(false) : resolve(true);
                        await UIUtilGeneral._Sleep(300);
                    }
                }
            }
            else {
                CargoInfoFinalStep.OnAccept = async (mt) => {
                    const noCuenta = 1;
                    const res = await _SvFinanzasNuevoCargoAsignAlumnoNoPlantilla(
                        cargo,
                        idAlumno,
                        noCuenta, formCargo._Data.IdCicloEscolar,
                        new DateV2(UIUtilTime._GetDateConcatenatedDateTime(formCargo._Data.FechaAplicacion))._ToISOLocalString(),
                        null,
                        formCargo._GetModelValue("ClaveProdServSAT"),
                        formCargo._DataOrigin.NombreProdServSAT,
                    )
                    resolve(res.Resultado > 0);
                    return res;
                }
            }

            StepsConfig.push(CargoFormStep);
            StepsConfig.push(CargoAplicacionCalendar);
            StepsConfig.push(CargoInfoFinalStep);

            ModalThings._GetModalToALongProccess({
                Modulo: Entidad.CModulo.FinanzasCargo,
                Action: action,
                IdsEscuelas: [cargo.IdEscuela],
                StartEndIds: ["cargo", "cargo_info_final"],
                StepsConfig: StepsConfig,
                OnClose: () => resolve(false)
            })
        })
    }

    export function _DrawCalendar(content: TSelectionHTML<"div", any, any, any>) {
        let contInfo = content.select<HTMLDivElement>(".cont_info");

        let contentido = content.html(`
            <div class="cont_calendar"></div>
            <div class="cont_info"></div>
        `)
        contInfo = contentido.select(".cont_info"); // container.append("div").classed("cont_info", true);

        for (let i = 1; i <= 3; i++) {
            let tag = contInfo.append("div").classed("tag_" + i, true);
            tag.append("div").classed("selector", true).classed("sel_" + i, true);
            tag.append("div").classed("info", true).append("label");
        }
    }

    export type TFinanzaCargoForm = IFinanzaCargo & { IdEscuelas?: number[], IdCicloEscolar?: number, FechaAplicacion?: string }
    export function _GetFormAddEditCargo(action: (CAccionPermiso.Agregar | CAccionPermiso.Editar), cargoBase?: TFinanzaCargoForm, formOrigin: TCargoFormOrigin = "FinanzasCargos", idEscuela?: number): FormGenerator<TFinanzaCargoForm> {
        // ***** PARA ALUMNOS EDO.CUENTA ***** 
        let ciclosEscolares: Entidad.ICicloEscolar[];
        let initCicloEscolar: Entidad.ICicloEscolar;
        let initMin: string;
        let initMax: string;
        let initDate: string;

        const getInitDateDefault = (init: string, fin: string) => {
            let initValidDt = UIUtilTime._GetValidatorDateYMD(new Date(init));
            let endValidDt = UIUtilTime._GetValidatorDateYMD(new Date(fin));
            let nowValidDt = UIUtilTime._GetValidatorDateYMD(new Date());

            if (initValidDt <= nowValidDt && endValidDt >= nowValidDt) {
                return UIUtilTime._FmtToInputDate(new Date(), { removeZ: true })
            } else {
                return UIUtilTime._FmtToInputDate(init, { removeZ: true });
            }
        }

        // ***** PARA ALUMNOS EDO.CUENTA ***** 
        if (formOrigin == "AlumnosEstadoCuenta") {
            ciclosEscolares = UIUtilViewEscuelas._GetCiclosEscolaresListSchool(idEscuela);
            if (!ciclosEscolares.length) {
                NotificacionV2._Mostrar(_L("panelfinanzasedocuenta.notif_nociclosescolares"), "ADVERTENCIA")
                return;
            }
            initCicloEscolar = UIUtilViewEscuelas._GetCurrentCicloEscolar(ciclosEscolares);
            initMin = initCicloEscolar ? UIUtilTime._FmtToInputDate(initCicloEscolar.FechaInicio, { removeZ: true }) : null;
            initMax = initCicloEscolar ? UIUtilTime._FmtToInputDate(initCicloEscolar.FechaFin, { removeZ: true }) : null;
            initDate = getInitDateDefault(initCicloEscolar.FechaInicio, initCicloEscolar.FechaFin);
        }
        const cargo: TFinanzaCargoForm = (() => {
            const esNuevo = !cargoBase || !cargoBase.ID;
            if (esNuevo) {
                cargoBase = <TFinanzaCargoForm>{
                    Categoria: CCategoriaTipoFinanza.Cargo, TipoValor: CTipoValor.Monto, TipoRecargo: CTipoValor.Ninguno,
                    TipoInteres: CTipoInteres.MontoUnico, ValorRecargo: 0,
                    Periodicidad: (formOrigin == "AlumnosEstadoCuenta" ? CPeriodicidadCargos.Unico : null),
                    IdCicloEscolar: initCicloEscolar?.Id, FechaAplicacion: initDate,
                    IntervaloBloque: CFinanzasCargoRecurrenciaRecargo.Diaria,
                }
                if (idEscuela)
                    cargoBase.IdEscuela = idEscuela
            } else {
                cargoBase.IntervaloBloque = cargoBase.TipoRecargo == CTipoValor.Monto && cargoBase.TipoInteres == CTipoInteres.MontoUnico
                    ? INTERVALOBLOQUE_VALOR_NINGUNO
                    : cargoBase.IntervaloBloque // DEFINIR RECURRENCUIA COMO INTERVALOBLOQUE_VALOR_NINGUNO CUANDO NO
            }
            return {
                ...cargoBase,
                TipoTasa: (cargoBase.TipoRecargo == CTipoValor.Porcentaje && cargoBase.IntervaloBloque == CFinanzasCargoRecurrenciaRecargo.Mensual ? CTipoTasa.Mensual : cargoBase.TipoTasa)
            };
        })()
        const esEditar = (action == CAccionPermiso.Editar);
        const isHorasExtras = (cargo.Categoria == CCategoriaTipoFinanza.CargoEntrada || cargo.Categoria == CCategoriaTipoFinanza.CargoSalida);
        const isEdoCuenta = formOrigin == "AlumnosEstadoCuenta"
        const escuelasConPermiso = UIUtilPermission._GetSchoolsByActionModule(Entidad.CModulo.FinanzasCargo, action, cargo.IdEscuela)
        const form = new FormGenerator<TFinanzaCargoForm>();

        if (!esEditar) {
            // cargo.IntervaloBloque = 0;
            if (escuelasConPermiso.length == 1) {
                cargo.IdEscuela = escuelasConPermiso[0].IdKinder;
                cargo.IdEscuelas = [escuelasConPermiso[0].IdKinder];
            }
        }

        let formSchema: Array<IField<TFinanzaCargoForm>> = [];
        // Concepto/Nombre
        const formFieldNombre = _GetCustomFieldScheme(CFormCargoField.Nombre, form);
        formFieldNombre.inputAttr.disabled = isHorasExtras;
        formFieldNombre.inputAttr.removeBorder = isHorasExtras;

        // Valor
        const formFieldValor = _GetCustomFieldScheme(CFormCargoField.Valor, form);

        // Periodicidad
        const formFieldPeriodicidad = _GetCustomFieldScheme(CFormCargoField.Periodicidad, form)
        formFieldPeriodicidad.selectMaterialAttr.disabled = isHorasExtras || isEdoCuenta;
        formFieldPeriodicidad.selectMaterialAttr.removeBorder = isHorasExtras || isEdoCuenta;

        // Escuelas
        const formFieldEscuelas = _GetCustomFieldScheme(CFormCargoField.Escuelas, form)
        formFieldEscuelas.selectMaterialAttr.disabled = esEditar;
        formFieldEscuelas.selectMaterialAttr.removeBorder = esEditar;
        formFieldEscuelas.selectMaterialAttr.Data = escuelasConPermiso;

        // Escuela
        const formFieldEscuela = _GetCustomFieldScheme(CFormCargoField.Escuela, form)
        formFieldEscuela.selectMaterialAttr.disabled = esEditar && formOrigin != "HorasExtras";
        formFieldEscuela.selectMaterialAttr.removeBorder = esEditar && formOrigin != "HorasExtras";
        formFieldEscuela.selectMaterialAttr.Data = escuelasConPermiso;
        if (formOrigin == "HorasExtras") {
            formFieldEscuela.selectMaterialAttr.onChange = (idEscuela: number) => {
                let newCargo = UIUtilViewFinanzaHorasExtras._GetFinanzaCargoToHoraExtra(idEscuela, cargo.Categoria as (CCategoriaTipoFinanza.CargoEntrada | CCategoriaTipoFinanza.CargoSalida));
                form._AsignaData(newCargo as TFinanzaCargoForm);
                // form._ControlsData.get("TipoInteres").selection.select(".itemradio").classed("hide", true); // opción 'Ninguno' oculto
                _ApplyTypeValueToInputValue(newCargo.TipoValor, "valorCargo", form);
                _ApplyTypeValueToInputValue(newCargo.TipoRecargo, "valorRecargo", form);
                _ApplyInfoTagTasa(form);
            }
        }

        // Tipo recargo
        const formFieldTipoRecargo = _GetCustomFieldScheme(CFormCargoField.TipoRecargo, form);
        formFieldTipoRecargo.radioListAttr.OnChange = (data) => {
            console.debug("Vent_FinCargo&Desc ->", data, "radios tipo recargos");
            const esRecargoPorcentaje = data.Id == CTipoValor.Porcentaje;
            (form._ControlsData.get(CFormCargoField.IntervaloBloque).instance as RadioList)?._SetValueSelected(CFinanzasCargoRecurrenciaRecargo.Diaria);
            if (!esRecargoPorcentaje) {
                (form._ControlsData.get(CFormCargoField.TipoInteres).instance as RadioList)?._SetValueSelected(CTipoInteres.MontoUnico + "");
                (form._ControlsData.get(CFormCargoField.TipoTasa).instance as RadioList)?._SetValueSelected(CTipoTasa.NoAplica);

                if (data.Id == CTipoValor.Ninguno) {
                    form._ControlsData.get(CFormCargoField.ValorRecargo).selection.property("value", 0);
                }
            } else {
                (form._ControlsData.get(CFormCargoField.TipoTasa).instance as RadioList)?._SetValueSelected(CTipoTasa.Diaria); // DOTEST: HORAS EXTRAS NO LO INCLUYE
                (form._ControlsData.get(CFormCargoField.TipoInteres).instance as RadioList)?._SetValueSelected(CTipoInteres.Simple);
                // form._ControlsData.get(CFormCargoField.TipoInteres).selection.select(".itemradio").classed("hide", true); // opción 'Ninguno' oculto
            }
            if (esRecargoPorcentaje || data.Id == CTipoValor.Monto) {
                form._GetModelControl<"radioList">("IntervaloBloque").instance._Refresh()
                if (data.Id == CTipoValor.Monto) {
                    form._SetModelValue("IntervaloBloque", INTERVALOBLOQUE_VALOR_NINGUNO)
                }
            }
            _ApplyTypeValueToInputValue(data.Id, "valorRecargo", form);
            _ApplyInfoTagTasa(form);
        }

        // Tipo interés
        const formFieldTipoInteres = _GetCustomFieldScheme(CFormCargoField.TipoInteres, form);
        // formFieldTipoInteres.radioListAttr.OnChange =

        // Tipo valor recargo
        const formFielValorRecargo = _GetCustomFieldScheme(CFormCargoField.ValorRecargo, form);

        // Intervalo bloque
        const formFieldIntervaloBloque = _GetCustomFieldScheme(CFormCargoField.IntervaloBloque, form);
        // formFieldIntervaloBloque.radioListAttr.OnChange =

        // TipoTasa
        const formFieldTipoTasa = _GetCustomFieldScheme(CFormCargoField.TipoTasa, form)
        formFieldTipoTasa.radioListAttr.OnChange = () => {
            _ApplyInfoTagTasa(form)
        }

        // ***** PARA ALUMNOS EDO.CUENTA *****
        // IdCicloEscolar
        const formFieldIdCicloEscolar = _GetCustomFieldScheme(CFormCargoField.IdCicloEscolar, form);
        formFieldIdCicloEscolar.selectMaterialAttr.Data = () => UIUtilViewEscuelas._GetCiclosEscolaresListSchool(idEscuela);
        formFieldIdCicloEscolar.selectMaterialAttr.onChange = (id: number, dato: Entidad.ICicloEscolar) => {
            let initMin = UIUtilTime._FmtToInputDate(dato.FechaInicio, { removeZ: true });
            let initMax = UIUtilTime._FmtToInputDate(dato.FechaFin, { removeZ: true });
            form._ControlsData.get("FechaAplicacion").selection
                .attr("min", initMin)
                .attr("max", initMax)
                .property("value", getInitDateDefault(dato.FechaInicio, dato.FechaFin));
        }
        // FechaAplicacion
        const formFieldFechaAplicacion = _GetCustomFieldScheme(CFormCargoField.FechaAplicacion, form);
        formFieldFechaAplicacion.inputAttr.min = initMin;
        formFieldFechaAplicacion.inputAttr.max = initMax;
        // ***** PARA ALUMNOS EDO.CUENTA *****

        if (formOrigin == "AlumnosEstadoCuenta") {
            formSchema.push(formFieldIdCicloEscolar);
            formSchema.push(formFieldFechaAplicacion);
        }
        formSchema.push(formFieldNombre);
        if (!isHorasExtras) formSchema.push(formFieldValor);
        formSchema.push(formFieldPeriodicidad);
        if (formOrigin != "AlumnosEstadoCuenta") {
            if (action == CAccionPermiso.Agregar) formSchema.push(formFieldEscuelas);
            else formSchema.push(formFieldEscuela);
        } else {
            formSchema.push(formFieldEscuela);
        }
        formSchema.push(formFieldTipoRecargo);
        formSchema.push(formFieldTipoInteres);
        formSchema.push(formFielValorRecargo);
        formSchema.push(formFieldIntervaloBloque);
        formSchema.push(formFieldTipoTasa);
        formSchema.push(_GetCustomFieldScheme(CFormCargoField.ClaveProdServSAT, form));

        form._Crear({
            LangModuleKeyInContext: langContextModuleFCargo,
            schema: formSchema,
            LabelMaxWidth: 140,
            BuildView: (container, controlsForm, form) => {
                const fnAppendRow = (model: CFormCargoField) => {
                    let control = controlsForm.get(model);
                    if (control) {
                        container.append(() => control.row.node())
                    }
                }
                if (formOrigin == "AlumnosEstadoCuenta") {
                    fnAppendRow(CFormCargoField.IdCicloEscolar);
                    fnAppendRow(CFormCargoField.FechaAplicacion);
                    fnAppendRow(CFormCargoField.ClaveProdServSAT);
                }
                if (formOrigin == "HorasExtras") {
                    fnAppendRow(CFormCargoField.Escuelas);
                    fnAppendRow(CFormCargoField.Escuela);
                    fnAppendRow(CFormCargoField.ClaveProdServSAT);
                }
                fnAppendRow(CFormCargoField.Nombre);
                fnAppendRow(CFormCargoField.Valor);
                fnAppendRow(CFormCargoField.Periodicidad);
                if (formOrigin != "HorasExtras" && formOrigin != "AlumnosEstadoCuenta") {
                    fnAppendRow(CFormCargoField.Escuelas);
                    fnAppendRow(CFormCargoField.Escuela);
                    fnAppendRow(CFormCargoField.ClaveProdServSAT);
                }
                fnAppendRow(CFormCargoField.TipoRecargo);
                fnAppendRow(CFormCargoField.TipoInteres);
                fnAppendRow(CFormCargoField.ValorRecargo);
                fnAppendRow(CFormCargoField.IntervaloBloque);
                fnAppendRow(CFormCargoField.TipoTasa);
            },
            OnAssignData: (cargo, controls, form) => {
                // controls.get(CFormCargoField.TipoInteres).selection.select(".itemradio").classed("hide", true); // opción TipoInterés 'Ninguno' oculto
                controls.get(CFormCargoField.TipoTasa).selection.select(".itemradio").classed("hide", true); // opción TipoTasa 'NoAplica' oculto
                setTimeout(() => {

                    form._GetModelControl<"radioList">("IntervaloBloque").instance._Refresh()
                    form._SetModelValue("IntervaloBloque", cargo.IntervaloBloque)

                    controls.get(CFormCargoField.TipoTasa).row.classed("hide", cargo.IntervaloBloque != CFinanzasCargoRecurrenciaRecargo.Diaria);
                    UIUtilViewFinanzaCargo._ApplyTypeValueToInputValue(cargo.TipoValor, "valorCargo", form);
                    UIUtilViewFinanzaCargo._ApplyTypeValueToInputValue(cargo.TipoRecargo, "valorRecargo", form);
                    RefreshTipoTasaItemForm(form)
                });
                // _ApplyInfoTagTasa(form);
            },
            Validation: (_, field, dataForm, controlsForm) => {
                let isValid = true;
                switch (field) {
                    // case "TipoInteres":
                    //     if (dataForm["TipoRecargo"] == CTipoValor.Porcentaje) {
                    //         if (dataForm[field] == CTipoInteres.MontoUnico) {
                    //             isValid = false;
                    //         }
                    //     } else {
                    //         dataForm["TipoInteres"] = CTipoInteres.MontoUnico
                    //     }
                    //     break;
                }
                return isValid;
            },
        }, cargo)

        return form;
    }

    let validaValor = (valor: number, tipoValor: CTipoValor) => {
        if (tipoValor == CTipoValor.Porcentaje) {
            return valor > 0 && valor <= 100;
        }
        else if (tipoValor == CTipoValor.Monto) {
            return valor > 0;
        } else {
            return false;
        }
    }

    export function _GetStepCargoForm(): ModalThings.IConfigModalToALongProccessItem {
        return {
            Id: "cargo",
            Title: "",
            NextID: () => "cargo_periodicidad",
            Width: 500,
            OnDrawContent: (content, modalThings) => {
            },
        }
    }

    export function _GetStepCargoAplicacionCalendar(): ModalThings.IConfigModalToALongProccessItem {
        return {
            Id: "cargo_periodicidad",
            Title: "",
            NextID: () => "cargo_info_final",
            Width: 1100,
            PreviousID: "cargo",
            OnDrawContent: (content, modalThings) => {
            }
        }
    }

    export function _GetStepCargoInfoFinal(): ModalThings.IConfigModalToALongProccessItem {
        return {
            Id: "cargo_info_final",
            Title: "",
            PreviousID: "cargo_periodicidad",
            Width: 440,
            OnDrawContent: (content, mt) => {
            }
        }
    }

    export function _ApplyInfoTagsV2(dato: IFinanzaCargo & { IdCicloEscolar?: number, DtAplicacion?: Date }, container: d3.Selection<HTMLElement, any, HTMLElement, any>) {
        const fnGetTalLang = (str: string) => UIUtilLang._GetUIString(langContextModuleFCargo, str);
        container.select<HTMLDivElement>(".body_informacion").remove();
        let infoBody = container.append<HTMLDivElement>("div").classed("body_informacion", true);
        infoBody.append("label").text(fnGetTalLang("d_field_nombre") + ": " + dato.Nombre);
        let labelValor = infoBody.append("label");

        switch (dato.Categoria) {
            case CCategoriaTipoFinanza.Cargo:
            case CCategoriaTipoFinanza.CargoEntrada:
            case CCategoriaTipoFinanza.CargoSalida:
                labelValor.text(fnGetTalLang("d_field_valor") + ": " + GetTextValor(dato.Categoria, dato.Valor));
                infoBody.append("label").text(fnGetTalLang("tag_periodic") + ": " + UIUtilViewData._GetStr_Periodicidad(dato.Periodicidad));
                if (!dato.DtAplicacion) {
                    infoBody.append("label").text(GetTextPeriod(dato.Periodicidad, "aplicacion", dato.MesAplicacion, dato.DiaAplicacion));
                    infoBody.append("label").text(GetTextPeriod(dato.Periodicidad, "vencimiento", dato.MesAplicacion, dato.DiaVencimiento));
                    infoBody.append("label").text(GetTextPeriod(dato.Periodicidad, "morosidad", dato.MesAplicacion, dato.DiaMorosidad));
                } else {
                    const dtFechaVencimiento = new Date(dato.DtAplicacion);
                    dtFechaVencimiento.setDate(dtFechaVencimiento.getDate() + dato.DiaVencimiento);
                    const dtFechaMorosidad = new Date(dtFechaVencimiento);
                    dtFechaMorosidad.setDate(dtFechaMorosidad.getDate() + dato.DiaMorosidad);
                    infoBody.append("label").text(fnGetTalLang("tag_dtaplica") + ": " + UIUtilTime._DateFormatStandar(dato.DtAplicacion, "d MMM yyyy"));
                    infoBody.append("label").text(fnGetTalLang("tag_dtvence") + ": " + UIUtilTime._DateFormatStandar(dtFechaVencimiento, "d MMM yyyy"));
                    infoBody.append("label").text(fnGetTalLang("tag_dtmoroso") + ": " + UIUtilTime._DateFormatStandar(dtFechaMorosidad, "d MMM yyyy"));
                }
                if (dato.TipoRecargo != CTipoValor.Ninguno) infoBody.append("label").text(fnGetTalLang("tag_recgs") + ": " + UIUtilViewFinanzaCargo._GetStrRecargosCol(dato));
                if (dato.IdCicloEscolar) {
                    const cicloEsc = DataModuloMain._GetDataValueFieldByName("CicloEscolar", dato.IdCicloEscolar, "Nombre");
                    if (cicloEsc) infoBody.append("label").text(fnGetTalLang("tag_cicloesc") + ": " + cicloEsc)
                }
                break;
            case CCategoriaTipoFinanza.Descuento:
                labelValor.text(fnGetTalLang("d_field_valor") + ": " + (dato.TipoValor == CTipoValor.Porcentaje ? UIUtilFormat._PercentFmt(dato.Valor) : UIUtilFormat._CurrencyFmt(dato.Valor)));
                infoBody.append("label").text(fnGetTalLang("tag_tipo") + ": " + UIUtilViewData._GetStr_TipoValor(dato.TipoValor));
                break;
        }
    }

    function GetTextValor(categoria: CCategoriaTipoFinanza, valor: number) {
        if (categoria == CCategoriaTipoFinanza.CargoEntrada || categoria == CCategoriaTipoFinanza.CargoSalida) {
            return "N/A";
        }
        return UIUtilFormat._CurrencyFmt(valor);
    }

    function GetTextPeriod(periodicidad: CPeriodicidadCargos, namePeriodicidad: ("aplicacion" | "vencimiento" | "morosidad"), nMes: number, nPrincipal: number): string {
        const fnGetTalLang = (str: string) => UIUtilLang._GetUIString(langContextModuleFCargo, str);
        let strTag = "";

        switch (namePeriodicidad) {
            case "aplicacion":
                strTag = fnGetTalLang("tag_dtaplica") + ": ";

                switch (periodicidad) {
                    case CPeriodicidadCargos.Mensual:
                        strTag += fnGetTalLang("tag_aplicames")
                            .replace("_NDIA", nPrincipal.toString());
                        break;
                    case CPeriodicidadCargos.Unico:
                        strTag += fnGetTalLang("tag_aplicaunico");
                        break;
                    default:
                        strTag += fnGetTalLang("tag_aplicaotro")
                            .replace("_NDIA", nPrincipal.toString())
                            .replace("_NMES", nMes.toString())
                            .replace("_PERIODO", UIUtilViewData._GetStr_Periodicidad(periodicidad));
                        break;
                }
                break;
            case "vencimiento":
                strTag = fnGetTalLang("tag_dtvence") + ": ";

                if (nPrincipal == 0) {
                    strTag += fnGetTalLang("tag_venceenaplica");
                }
                else {
                    strTag += fnGetTalLang("tag_vencedespaplica")
                        .replace("_DIA", nPrincipal.toString());
                }
                break;
            case "morosidad":
                strTag = fnGetTalLang("tag_dtmoroso") + ": ";

                if (nPrincipal == 0) {
                    strTag += fnGetTalLang("tag_morosoenvence");
                }
                else {
                    strTag += fnGetTalLang("tag_morosodespvence")
                        .replace("_DIA", nPrincipal.toString());
                }
                break;
        }
        return strTag;
    }

    export function _ApplyTypeValueToInputValue<T extends IFinanzaCargo>(typeValue: CTipoValor, modelBase: "valorCargo" | "valorRecargo", form: FormGenerator<T>) { //: d3.Selection<HTMLDivElement, any, HTMLElement, any> {
        // let itemFormRadioTipoValor = form.prop_ControlsData.get(modelBase == "valorCargo" ? CFormModels.TipoValor : CFormModels.TipoRecargo);
        const itemFormInputValor = form._GetModelControl<"input">(modelBase == "valorCargo" ? CFormCargoField.Valor : CFormCargoField.ValorRecargo);

        if (itemFormInputValor?.selection.node()) {
            const containerInputValue = d3.select(itemFormInputValor.selection.node().parentElement as HTMLDivElement);
            const esValorPorcentaje = typeValue == CTipoValor.Porcentaje

            itemFormInputValor.selection
                .attr("max", esValorPorcentaje ? 100 : null)
                .attr("step", ".01")

            if (modelBase == "valorRecargo") {
                const generaInteres = [CTipoInteres.Simple, CTipoInteres.Compuesto].includes(form._GetModelValue(CFormCargoField.TipoInteres))
                const esRecargoDiario = (esValorPorcentaje && form._GetModelValue(CFormCargoField.IntervaloBloque) == CFinanzasCargoRecurrenciaRecargo.Diaria)
                const tieneRecurrencia = (esValorPorcentaje && generaInteres) || typeValue == CTipoValor.Monto
                itemFormInputValor.row.classed("hide", (typeValue == CTipoValor.Ninguno));
                form._GetModelControl(CFormCargoField.TipoInteres).row.classed("hide", !esValorPorcentaje);
                form._GetModelControl(CFormCargoField.IntervaloBloque).row.classed("hide", !tieneRecurrencia);
                form._GetModelControl(CFormCargoField.TipoTasa)?.row.classed("hide", !esRecargoDiario);
            }

            // if (applyRadioListInput) {
            //     (itemFormRadioTipoValor.instance as RadioList)?.met_valueCheck(typeValue + "");
            // }

            let symbolElement = containerInputValue.select(".input_symbol");
            if (!symbolElement.node()) {
                symbolElement = containerInputValue.append("div").classed("input_symbol", true)
                symbolElement.append("span");
            }
            symbolElement = symbolElement.select("span");

            if (typeValue == CTipoValor.Monto) {
                containerInputValue.classed("input_currency", true);
                containerInputValue.classed("input_percent", false);
                symbolElement.text("$");
            } else if (esValorPorcentaje) {
                containerInputValue.classed("input_currency", false);
                containerInputValue.classed("input_percent", true);
                symbolElement.text("%");
            }
        }
    }

    function RefreshTipoTasaItemForm(frm: FormGenerator<TFinanzaCargoForm>) {
        const esRecDiaria = frm._GetModelValue(CFormCargoField.IntervaloBloque) == CFinanzasCargoRecurrenciaRecargo.Diaria;
        const esInteresPorcentaje = [CTipoInteres.Simple, CTipoInteres.Compuesto].includes(frm._GetModelValue("TipoInteres"));
        const esInteresRecDiaria = (frm._GetModelValue("TipoRecargo") == CTipoValor.Porcentaje) && esInteresPorcentaje && esRecDiaria

        const tipoTasaReseterValue = esInteresPorcentaje
            ? (esRecDiaria ? CTipoTasa.Diaria : CTipoTasa.Mensual)
            : CTipoTasa.NoAplica
        // const tipoTasaCurrentValue = frm._GetModelControl("TipoTasa")== CTipoTasa.NoAplica

        frm._GetModelControl(CFormCargoField.TipoTasa).row.classed("hide", !esInteresRecDiaria);
        if (esInteresPorcentaje) {
            if (frm._GetModelValue("TipoTasa") == CTipoTasa.NoAplica)
                frm._SetModelValue(CFormCargoField.TipoTasa, tipoTasaReseterValue)
            else if (frm._GetModelValue("IntervaloBloque") == CFinanzasCargoRecurrenciaRecargo.Mensual)
                frm._SetModelValue(CFormCargoField.TipoTasa, CTipoTasa.Mensual) // Recurrencia mensual solo tiene Tasa mensual
        }
        if (!esInteresRecDiaria) return
        _ApplyInfoTagTasa(frm);
    }

    export function _ApplyInfoTagTasa<T extends IFinanzaCargo>(form: FormGenerator<T>) {
        let mapStringsInfoTasas = new Map<CTipoTasa, string>([
            [CTipoTasa.NoAplica, ""],
            [CTipoTasa.Diaria, UIUtilLang._GetUIString(langContextModuleFCargo, "tag_dailyrate")],
            [CTipoTasa.Mensual, UIUtilLang._GetUIString(langContextModuleFCargo, "tag_monthlyrate")]
        ]);
        let labelInfoMssg = form._ControlsData.get("TipoTasa").controlWrapper.select(".labelInfoMssg");
        if (!labelInfoMssg.node()) {
            labelInfoMssg = form._ControlsData.get("TipoTasa").controlWrapper.append("label").classed("labelInfoMssg", true).style("font-size", "var(--fontsize_me2)").style("color", "var(--color_app_red1)");
        }
        labelInfoMssg.text(mapStringsInfoTasas.get(form._Data.TipoTasa));
    }
}
