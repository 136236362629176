import { MainPage } from "../../MainPage";
import { DataDRequest } from "../../data/DRequest";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import { _DICC_ALUMNO } from "../../data/modulo/Alumno";
import DataModuloEscuela from "../../data/modulo/Escuela";
import DataModuloGrupo from "../../data/modulo/Grupo";
import { DateV2 } from "../../util/DateV2";
import { CardV2Collapse, TCARDV2COLL_OnEditOriginEvent } from "../controlD3/CardV2Collapse";
import { NotificacionV2 } from "../controlD3/NotificacionV2";
import { UIUtilLang } from "../util/Language";
import { UIUtilPermission } from "../util/Permission";
import { UIUtilTime } from "../util/Time";
import { UIUtilViewGruposScheduleControl } from "../utilView/GruposScheduleControl";
import { UIWindowManager } from "../ventana/WindowManager";
import CDia = Entidad.CDiaSemanal;
type TTypeRender = "real" | "predefined" | "none";

export class UIPanelCardGruposHorarioOperacionV2 extends CardV2Collapse<[Entidad.IGrupo, TTypeRender]> {
    private scheduleControl: UIUtilViewGruposScheduleControl.ScheduleControl;
    private horarioOrigin: Entidad.IHorarioDia[];
    private currentEscuela: Entidad.IEscuela;
    private currentGrupo: Entidad.IGrupo;
    private dias_operacion: Map<Entidad.CDiaSemanal, boolean>;
    private typeRender: TTypeRender;
    private exitWhenFinish: boolean;

    constructor(modulo: Entidad.CModulo) {
        super("card_headertitle", modulo, "grupopanelhorariooperacion");

        this.dias_operacion = new Map([
            [CDia.Lunes, false],
            [CDia.Martes, false],
            [CDia.Miercoles, false],
            [CDia.Jueves, false],
            [CDia.Viernes, false],
            [CDia.Sabado, false],
            [CDia.Domingo, false]
        ]);
        this.exitWhenFinish = false;
        this.typeRender = "real";
    }

    protected CARDCOLL_OnInitBuild(container: TSelectionHTML<"div", any, any>): void {
        //this.cardHeaderSelection.remove();
        //this.cardActionsContainerSelection.style("top", "-32px");
        //this.cardSelection.style("height", "100%");

        container.style("height", "100%")
        this.scheduleControl = new UIUtilViewGruposScheduleControl.ScheduleControl({
            Parent: container,
        })
    }
    protected CARDCOLL_GetVariantToValidateUpdate(grupo: Entidad.IGrupo, cardData_1: TTypeRender): string {
        return grupo.IdGrupo + "_" + grupo.Modificacion; // grupo.Horario.map(d => (d.IdDia + d.Entrada + d.Salida)).join(",");
    }
    protected CARDCOLL_OnUpdateData(grupo: Entidad.IGrupo, typeRender: TTypeRender): void {
        this.currentEscuela = DataModuloEscuela._DiccEscuela.get(grupo.IdKinder);
        this.currentGrupo = grupo;
        this.horarioOrigin = grupo.Horario ? grupo.Horario : [];
        this.typeRender = typeRender;
        this.scheduleControl._IdGrupoToValidateBlocks = grupo.IdGrupo;
        this.scheduleControl._ZonaHoraria = this.currentEscuela.ZonaHoraria;

        this.UIUpdateCardHasErrorDefault();
        this.ResetScheduleItems();

        this.UpdateUIFromPermissions();
    }
    protected CARDCOLL_MostrarBody(): void {
        this.cardContentContainerSelection.style("height", "100%");
        this.UpdateUIFromPermissions();
    }
    protected CARDCOLL_OcultarBody(): void {
        this.cardContentContainerSelection.style("height", null);
    }
    protected CARDCOLL_OnEditarCard(originEvent: TCARDV2COLL_OnEditOriginEvent): void {
        //  Checar quitar validacion
        //if (originEvent == "onCancel") {
        //this.ResetScheduleItems();
        //} else {
        this.UpdateItemsHorarioEditarStatus();
        this.UpdateDiasOperacionEnableStatus();
        //}

    }
    protected CARDCOLL_OnCancelaEditarCard(originEvent: TCARDV2COLL_OnEditOriginEvent): void {
        if (originEvent == "onCancel") {
            this.ResetScheduleItems();
        }
        if (originEvent !== "onCancel") {
            this.UpdateItemsHorarioEditarStatus();
            this.UpdateDiasOperacionEnableStatus();
        }
        this.UIUpdateCardHasErrorDefault();
        if (this.exitWhenFinish) {
            setTimeout(() => {
                if (this.CARDCOLL_StatusCardExpandido) UIWindowManager._HistoryBackEval("escuelas/grupos");
            })

        }
    }
    protected async CARDCOLL_GuardarCardV2(): Promise<DataDRequest.IRequestResponseA<any>> {
        const horariosNewConfig = this.GetHorarios();
        let res: DataDRequest.IResultadoPeticion<any>;

        this.CARDCOLL_UICardHasError(horariosNewConfig.length == 0);

        if (horariosNewConfig.length > 0) {
            let resumenErrores = this.scheduleControl._GetResumenErrores();

            this.CARDCOLL_UICardHasError(resumenErrores.length > 0);

            if (resumenErrores.length) {
                let strTempMessage = "";
                let errorLimites = resumenErrores.filter(d => (d.Tipo == "limites" && resumenErrores[0].IdDia == d.IdDia));
                let errorBloquesEliminados = resumenErrores.filter(d => (d.Tipo == "noeliminardia" && resumenErrores[0].IdDia == d.IdDia));

                if (errorLimites.length > 0) {
                    strTempMessage = this.CARDCOLL_GetUIStringModule("notif_alumnosfueradelimites_some")
                        .replace("_NALUMNOS", errorLimites.length.toString())
                        .replace("_DIA", UIUtilTime._GetDayName(errorLimites[0].IdDia));
                }
                else if (errorBloquesEliminados.length > 0) {
                    strTempMessage = this.CARDCOLL_GetUIStringModule("notif_noeliminarbloque_some")
                        .replace("_NALUMNOS", errorBloquesEliminados.length.toString())
                        .replace("_DIA", UIUtilTime._GetDayName(errorBloquesEliminados[0].IdDia));
                }

                NotificacionV2._Mostrar(strTempMessage, "ADVERTENCIA");
                //this.notificacion.met_Mostrar(strTempMessage, "ADVERTENCIA");

                return null;
            }

            // >>
            return new Promise(async resolve => {
                let res = await this.Sv_ActualizarHorarioOperacion(horariosNewConfig);
                NotificacionV2._Mostrar(UIUtilLang._GetHTTPMessage(res, "editarhorariooperacion"), res.Resultado > 0 ? "INFO" : "ADVERTENCIA");
                //this.notificacion.met_Mostrar(UIUtilLang.fn_GetHTTPMessage(res, "editarhorariooperacion"), res.Resultado > 0 ? "INFO" : "ADVERTENCIA");

                if (res.Resultado > 0) {
                    MainPage._ReloadService(Entidad.CTipoRequest.Grupo, this.currentEscuela.IdKinder, () => {
                        MainPage._ReloadService(Entidad.CTipoRequest.HorarioAlumno, this.currentEscuela.IdKinder);
                        if (this.exitWhenFinish) {
                            setTimeout(() => {
                                resolve(res);
                            }, 2000);
                        } else {
                            resolve(res);
                        }
                    });
                } else {
                    if (res.Data) {
                        let alumnoError = _DICC_ALUMNO.get(res.Data[0]?.IdAlumno);
                        if (alumnoError) {
                            let mensaje = "";
                            if (res.Data.length > 1) {
                                mensaje = this.CARDCOLL_GetUIStringModule("notif_conclictoalumnos")
                                    .replace("_ALUMNO", alumnoError.NombreCompleto)
                                    .replace("_NMAS", (res.Data.length - 1).toString());
                            } else {
                                mensaje = this.CARDCOLL_GetUIStringModule("notif_conclictoalumno")
                                    .replace("_ALUMNO", alumnoError.NombreCompleto);
                            }
                            NotificacionV2._Mostrar(mensaje + ".", "ADVERTENCIA");
                            //this.notificacion.met_Mostrar(mensaje + ".", "ADVERTENCIA");
                        }
                    }
                    resolve(res);
                }
            })

        } else {
            NotificacionV2._Mostrar(UIUtilLang._GetUIString("grupos", "notif_horarioempy"), "ADVERTENCIA");
            //this.notificacion.met_Mostrar(UIUtilLang.fn_GetUIString("grupos", "notif_horarioempy"), "ADVERTENCIA");
        }

        return res;
    }
    protected CARDCOLL_SyncOrGetIdToDownloadData(): DataModuloMain.TipoRequestMonitorId | DataModuloMain.TipoRequestMonitorId[] | (() => Promise<any>) {
        return Entidad.CTipoRequest.Grupo;
    }
    protected CARDCOLL_GetIdSchool(cardData_0_0: Entidad.IGrupo, cardData_0_1: TTypeRender): number {
        return cardData_0_0.IdKinder;
    }

    private UIUpdateCardHasErrorDefault() {
        this.CARDCOLL_UICardHasError(this.horarioOrigin.length == 0, this.CARDCOLL_GetUIStringModule("notif_falta_horarioconfig"));
    }

    private ResetScheduleItems() {
        if (this.currentEscuela) {
            if (this.scheduleControl) {
                // -> Update Items in control
                this.scheduleControl._UpdateHorarioLimitesGeneral(this.currentEscuela.Entrada, this.currentEscuela.Salida);
                let diasChidos = this.UpdateDiasOperacionEnableStatus();
                this.UpdateItemsHorarioEditarStatus();
                this.scheduleControl._ClearItems();

                switch (this.typeRender) {
                    case "real":
                        this.RenderHorarioFromOriginData();
                        break;
                    case "predefined":
                        if (UIUtilPermission._HasAccionPermission(Entidad.CAccionPermiso.Editar, this.modulo, this.currentEscuela.IdKinder)) {
                            this.RenderHorarioPredefined(diasChidos);
                        }
                        else {
                            this.RenderHorarioFromOriginData();
                        }
                        break;
                    case "none":
                        break;
                }
            }
        } else {
            console.error("Escuela no encontrada !!", this.currentGrupo.IdKinder);
        }
    }

    private UpdateItemsHorarioEditarStatus() {
        if (this.CARDCOLL_StatusCardEditando) {
            if (this.currentEscuela && UIUtilPermission._HasAccionPermission(Entidad.CAccionPermiso.Editar, this.modulo, this.currentEscuela.IdKinder)) {
                this.scheduleControl._SetEnableItemsToEdit(true);
            } else {
                this.scheduleControl._SetEnableItemsToEdit(false);
            }
        } else {
            this.scheduleControl._SetEnableItemsToEdit(false);
        }
    }

    private UpdateDiasOperacionEnableStatus() {
        // -> Enable / Disable dias operación
        this.currentEscuela?.HoraEntradas.forEach((hora, i) => {
            this.dias_operacion.set((i + 1), Boolean(hora));
        })


        let diasChidos = [];
        let diasNoChidos = [];

        this.dias_operacion.forEach((opera, dia) => {
            if (opera) {
                diasChidos.push(dia);
            } else {
                diasNoChidos.push(dia);
            }
        })

        this.scheduleControl?._DaysEnable(diasChidos, true);
        this.scheduleControl?._DaysEnable(diasNoChidos, false);

        return diasChidos;
    }

    private RenderHorarioFromOriginData() {
        this.horarioOrigin.forEach(d => {
            this.scheduleControl._SetScheduleItem({
                Id: d.IdDia,
                IdDia: d.IdDia,
                Inicio: new DateV2(d.Entrada)
                    ._SetTimeZone(this.currentEscuela.ZonaHoraria),
                Fin: new DateV2(d.Salida)
                    ._SetTimeZone(this.currentEscuela.ZonaHoraria),
            })
        })
    }

    private RenderHorarioPredefined(diasChidos: number[]) {
        const scheduleLimitMinutosInicio = this.scheduleControl._GetLimitesHorario.MinInicio;
        const dt = new DateV2();
        dt._SetTimeZone(this.currentEscuela.ZonaHoraria);
        dt.setHours(0, 0, 0, 0);

        diasChidos.forEach((idDia, i) => {
            this.scheduleControl._SetScheduleItem({
                Id: 0 - (idDia),
                IdDia: idDia,
                Inicio: new DateV2(dt)
                    ._AddMinutes(scheduleLimitMinutosInicio),
                Fin: new DateV2(dt)
                    ._AddMinutes(scheduleLimitMinutosInicio + 60),
            })
        })
    }

    private GetHorarios(): Entidad.IHorarioDia[] {
        return this.scheduleControl?._GetCurrentItems()
            .map(d => (<Entidad.IHorarioDia>{
                IdDia: d.IdDia,
                Entrada: new DateV2()
                    ._SetTimeZone(this.currentEscuela.ZonaHoraria)
                    ._SetLocalStrHour(d.HrInicio + ":00.00")
                    ._ToISOString(),
                Salida: new DateV2()
                    ._SetTimeZone(this.currentEscuela.ZonaHoraria)
                    ._SetLocalStrHour(d.HrFin + ":00.00")
                    ._ToISOString()
            }))
    }

    private UpdateUIFromPermissions() {
        if (!this.CARDCOLL_StatusCardEditando) {
            this.btnEditarCard._d3Selection.classed("hide", !this.HasActionPermission(Entidad.CAccionPermiso.Editar));
        }
    }

    // FIXME
    public _ExitWhenFinish(val: boolean) {
        this.exitWhenFinish = val;
    }

    // **********************************************************************************************
    // SERVICES THINGS
    // **********************************************************************************************

    private Sv_ActualizarHorarioOperacion(horarios: Entidad.IHorarioDia[]) {
        return DataModuloGrupo._ActualizarHorarioOperacion(this.currentGrupo.IdGrupo, horarios);
    }
}
