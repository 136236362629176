import * as d3 from "d3";
import { ascending, descending } from "d3-array";
import { DataDRequest } from "../../data/DRequest";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import { _LOCALDATA_GetTutoresDeAlumno, _SvAlumnoGetBoletaCalificacionesV2 } from "../../data/modulo/Alumno";
import DataModuloEscuela from "../../data/modulo/Escuela";
import { DateV2 } from "../../util/DateV2";
import _L from "../../util/Labels";
import { Button } from "../controlD3/Button";
import { TCARDV2COLL_OnEditOriginEvent } from "../controlD3/CardV2Collapse";
import { CardV2CollapseAdvancedTable, IConfigCardV2CollapseExcelExport } from "../controlD3/CardV2CollapseAdvancedTable";
import { ElementWrapper } from "../controlD3/ElementWrapper";
import { SelectV2 } from "../controlD3/SelectV2";
import { Table } from "../controlD3/Tabla";
import { HTMLImage2Component } from "../controlWC/HTMLImage2Component";
import { UIUtilLang } from "../util/Language";
import { UIUtilPermission } from "../util/Permission";
import { UIUtilTime } from "../util/Time";
import { UIUtilGeneral } from "../util/Util";
import { UIUtilViewData } from "../util/ViewData";
import { UIUtilViewCalificacion } from "../utilView/Calificacion";

import IAlumno = Entidad.IAlumno;
import ICicloEscolar = Entidad.ICicloEscolar;
import CTipoEvaluacion = Entidad.CTipoEvaluacion;
import IElementoCalificacionGrid = UIUtilViewCalificacion.IElementoCalificado;
import IAuxInfoEvaluaciones = UIUtilViewCalificacion.IAuxInfoEvaluaciones;
import IAlumnoBoleta = UIUtilViewCalificacion.IAlumnoBoleta;

interface IPeriodoD extends UIUtilViewData.IPeriodoD {
    EnCicloEscolar: boolean;
}
interface ICurrentData {
    IdAlumno: number;
    NombreCompleto: string;
    Tutores: string[];
    StrCicloEscolar: string;
    //StrPeriodo: string;
    StrNivel: string;
    StrGrado: string;
    StrGruposPrincipales: string[];
}

/* interface IBoletaData {
    Alumno: IAlumno;
    IdAlumno: number;
    IdKinder: number;
    Calificaciones: ICalificacion[];
    Comentario: string;
} */

export class UIPanelCardAlumnosBoletas extends CardV2CollapseAdvancedTable<IElementoCalificacionGrid, [IAlumno]> {
    private ctrlSelectCicloEscolar: SelectV2<ICicloEscolar, "Id", "monoselect">;
    private ctrlSelectPeriodo: SelectV2<IPeriodoD, "Id", "multiselect">;
    private dtInitRange: DateV2;
    private dtFinRange: DateV2;

    /** No usar directamente, usar this.GetCurrentInfoGeneral */
    private infoGeneral: ICurrentData;

    /** No usar directamente, usar this.GetCiclosEscolaresList */
    private ciclosEscolaresList: ICicloEscolar[];

    /** IdAlumno -> CriteriosCalificados */
    private boletasMap: Map<number, IElementoCalificacionGrid[]>;

    constructor(modulo: Entidad.CModulo.PanelBoletas) {
        super("", modulo);
        this.boletasMap = new Map();
    }

    protected CARDCOLLADTAB_Table_GetMenuTop(): Table.ITableMenuTopDefaultOptionConfig[] {
        return null;
    }
    protected CARDCOLLADTAB_GetExportarConfig(dataTable: IElementoCalificacionGrid[]): IConfigCardV2CollapseExcelExport<any> | Promise<IConfigCardV2CollapseExcelExport<any>> {
        return null;
    }

    /**
     * La tabla inicialmente se ordena por el nombre de la materia
     * Tiene 2 filtros de nivel 2: fechaEval, y Observación
     * Se manejan los eventos OnFilter y OnCheckedData para hacer calculos y modificar la data y la vista
     */
    protected CARDCOLLADTAB_Table_GetConfig(): Omit<Table.IConfig<IElementoCalificacionGrid>, "Parent"> {
        return {
            IdTabla: "AlumnosPanelBoletas",
            Title: "",
            MinWidth: 700,
            IdData: "IdAsignacion",
            OrderDefault: { Type: Table.CStatusOrder.Asc, Field: "Materia" },
            FilterParameters: [
                {
                    Field: "Materia", Type: "select", Label: "Materia",
                    Options: () => DataModuloMain._GetReqDataArrayByName("MateriaV2")
                        .map(d => ({ Id: d.Nombre, Name: d.Nombre }))
                        .concat(this.ctrlTabla._data.map(d => ({ Id: d.Materia, Name: d.Materia })))
                        .filter(d => (!!d.Name))
                        .sort((a, b) => d3.ascending(a.Name.toLowerCase(), b.Name.toLowerCase()))
                },
                {
                    Field: "Criterio", Type: "select", Label: "Criterio",
                    Options: () => {
                        const ArrCriterios: string[] = [];
                        DataModuloMain._GetReqDataArrayByName("MateriaV2")
                            .map(d => d.Criterios)
                            .forEach(d => ArrCriterios.push(...d));
                        this.ctrlTabla._data.forEach(d => ArrCriterios.push(d.Criterio));
                        return ArrCriterios
                            .map(d => ({ Id: d, Name: d }))
                            .filter(d => (!!d.Name))
                            .sort((a, b) => d3.ascending(a.Name.toLowerCase(), b.Name.toLowerCase()));
                    }
                },
                {
                    Field: "FechaEval" as any, Label: "Fecha", Level: 2, Type: "date", MaxDate: new Date().toISOString(), OnChange: (filtro) => {
                        if (!filtro) { this.dtInitRange = null; this.dtFinRange = null; return; }
                        this.dtInitRange = new DateV2(filtro.dateA)._SetTimeZoneByIdSchool(this.GetIdEscuela(), true);
                        this.dtFinRange = new DateV2(filtro.dateB)._SetTimeZoneByIdSchool(this.GetIdEscuela(), true);
                    }
                },
                { Field: "Observacion" as any, Label: "Observaciones", Level: 2, Type: "text" }
            ],
            RenderColumnHeadings: [
                { Field: "Materia", Label: "", MinWidth: "90px", Width: "15%" },
                { Field: "Criterio", Label: "", MinWidth: "150px", Width: "20%" },
                { Field: "Evaluacion", Label: "", MinWidth: "150px", Width: "20%", OrderLevelRows: 2 },
                { Field: "FechaEval" as any, Label: "", MinWidth: "100px", Width: "15%", OrderLevelRows: 2 },
                { Field: "Observacion" as any, Label: "", MinWidth: "125px", Width: "25%", OrderLevelRows: 2 }
            ],
            OnFilter: () => {
                this.Update_ItemsDataTable(this.ctrlTabla._dataFiltered);
            },
            EvaluatorAndSubLevelsBuild: {
                ForceHideLevelRowsWithoutChilds: true,
                RemoveLevelCheckboxes: true,
                OnStepCellTable: (container, datum, field) => {
                    switch (field) {
                        case "Evaluacion":
                            if (datum.EvaluacionArr.length == 1 && datum.EvaluacionArr[0] == "") {
                                container.text("")
                            } else {
                                this.CreateCalificacionColumn(datum.Tipo, datum.EvaluacionArr, container);
                            }
                            break;
                        case "Criterio":
                            container.text((!datum.Criterio?.length) ? "NA" : datum.Criterio)
                            break;
                    }
                },
                OnEvalIsCollapsedRow: (dato) => false,
                EvaluatorSubLevel: {
                    OnGetData: (dato) => {
                        return dato.AuxInfoEvaluaciones;
                    },
                    RemoveLevelCheckboxes: true,
                    IdMember: "IdEvaluacion",
                    OnStepCellTable: (container, datum: UIUtilViewCalificacion.IAuxInfoEvaluaciones, field) => {
                        switch (field) {
                            case "Evaluacion":
                                if (datum.Evaluacion == '') {
                                    container.text("-")
                                } else {
                                    this.CreateCalificacionColumn(datum.TipoEval, [datum.Evaluacion], container)
                                }
                                break;
                            case "FechaEval":
                                container.text(UIUtilTime._DateFormatStandarFixTimeZoneByIdSchool(datum.FechaEval, this.GetIdEscuela()))
                                break;
                        }
                    },
                }
            }

        };
    }
    protected CARDCOLL_OnInitBuild(container: TSelectionHTML<"div", any, any>): void {
        this.btnEditarCard._d3Selection.remove();
        this.btnCancelarCard._d3Selection.remove();

        let listPeriodos: IPeriodoD[] = [];

        container
            .classed(UIUtilGeneral.FBoxOrientation.Vertical, true)
            .classed("finanzas_edocuenta", true);

        let areaMenu = container.append("div")
            .classed(UIUtilGeneral.FBoxOrientation.Horizontal, true)
            .classed(UIUtilGeneral.FBoxAlign.StartCenter, true)
            .classed("area_menu", true);

        let areaInfo = container.append("div")
            .classed(UIUtilGeneral.FBoxOrientation.Horizontal, true)
            .classed(UIUtilGeneral.FBoxAlign.SpacebetweenStart, true)
            .classed("area_info", true);

        areaInfo.append<HTMLImage2Component>("wc-img")
            .attr("class", "escuela_logo")
            .attr("spinner-border-width", 5)
            .attr("spinner-dim", 40);

        areaInfo.append("div")
            .classed(UIUtilGeneral.FBoxOrientation.Vertical, true)
            .classed(UIUtilGeneral.FBoxAlign.StartStart, true)
            .classed("subareainfo_left", true);

        areaInfo.append("div")
            .classed(UIUtilGeneral.FBoxOrientation.Vertical, true)
            .classed(UIUtilGeneral.FBoxAlign.StartStart, true)
            .classed("subareainfo_right", true);

        container.selectAll(".tabla_container").remove();

        this.CARDCOLLADTAB_CreateTable(container);

        /* let areaComentario = container.append("div")
            .classed(UIUtilGeneral.FBoxOrientation.Horizontal, true)
            .classed(UIUtilGeneral.FBoxAlign.CenterCenter, true)
            .classed("area_comentario", true)
            .style("gap", "10px")
        areaComentario.append("label").text("Comentarios: ");
        areaComentario.append("textarea").classed("comentario_area", true); */

        this.ctrlSelectCicloEscolar = new SelectV2<ICicloEscolar, "Id">({
            Type: "monoselect",
            Parent: areaMenu,
            Data: [],
            ValueMember: "Id",
            DisplayMember: "Nombre",
            OnStepItemListUI: (container, d, step) => {
                if (step == "enter") {
                    container.classed(UIUtilGeneral.FBoxOrientation.Vertical, true);
                    container.style("align-items", "flex-start");
                }
                container.html(`<label>${d.Nombre}</label>`)
                if (new Date().getTime() < new Date(d.FechaInicio).getTime()) {
                    container.append("label")
                        .style("color", "red")
                        .style("font-size", "var(--fontsize_me4)")
                        .text(this.CARDCOLL_GetUIStringModule("tag_ciclosinperiodos"));
                }
            },
            OnChange: (id, cicloEscolar) => {
                let periodos = this.GetPeriodosListFromCicloEscolar(cicloEscolar);
                let periodoValid = periodos.find(d => d.Month == new Date().getMonth() && d.Year == new Date().getFullYear());
                periodoValid = periodoValid ? periodoValid : periodos[0];
                this.ctrlSelectPeriodo._UpdateList(periodos);
                this.ctrlSelectPeriodo._valueSelect(periodoValid.Id);

                const info = this.GetCurrentInfoGeneral();
                this.UI_UpdateCardInfoStatus(info);
                this.UI_UpdateCardData(false, true);
            },
        });

        areaMenu.append(() => ElementWrapper._WrapperToSelectControl(this.ctrlSelectCicloEscolar, this.CARDCOLL_GetUIStringModule("filtro_cicloesc")).node());

        this.ctrlSelectPeriodo = new SelectV2<IPeriodoD, "Id", "multiselect">({
            Type: "multiselect",
            Parent: areaMenu,
            Data: listPeriodos,
            ValueMember: "Id",
            DisplayMember: "Name",
            OnChange: (valueMembers, datos) => {
                if (!datos.length) {
                    this.boletasMap.set(this.GetIdCurretStudent(), []);
                    this.ctrlTabla._UpdateData(this.GetBoletaList());
                    this.UI_EnableMainButton(this.ctrlTabla._data.length > 0);
                    return;
                }
                const info = this.GetCurrentInfoGeneral()
                this.UI_UpdateCardInfoStatus(info);
                this.UI_UpdateCardData(false, true);
            },
        })

        areaMenu.append(() => ElementWrapper._WrapperToSelectControl(this.ctrlSelectPeriodo, _L("panelboletas.tag_periodo")).node())
            .style("height", "100%")
    }
    protected CARDCOLL_GetVariantToValidateUpdate(cardData_0: IAlumno): string {
        return cardData_0.IdChild.toString();
    }
    protected CARDCOLL_OnUpdateData(cardData_0: IAlumno): any | Promise<any> {
        this.UI_UpdateCardPermissions();
        return this.UI_UpdateCardData(true, true);
    }
    protected CARDCOLL_MostrarBody(): void {
        this.cardSelection.style("height", "100%");
        this.UI_UpdateCardPermissions();
        this.UI_UpdateCardData(true, true);
    }
    protected CARDCOLL_OcultarBody(): void {
        this.cardSelection.style("height", null);
    }
    protected CARDCOLL_OnEditarCard(originEvent: TCARDV2COLL_OnEditOriginEvent): void {
        //throw new Error("Method not implemented.");
    }
    protected CARDCOLL_OnCancelaEditarCard(originEvent: TCARDV2COLL_OnEditOriginEvent): void {
        //throw new Error("Method not implemented.");
    }
    protected CARDCOLL_GuardarCardV2(): Promise<DataDRequest.IRequestResponseA<any>> {
        throw new Error("Method not implemented.");
    }
    protected CARDCOLL_SyncOrGetIdToDownloadData(): DataModuloMain.TipoRequestMonitorId | DataModuloMain.TipoRequestMonitorId[] | (() => any | Promise<any>) {
        return () => this.UI_UpdateCardData();
    }
    protected CARDCOLL_GetIdSchool(cardData_0: IAlumno): number {
        return cardData_0.IdKinder;
    }

    private Update_ItemsDataTable(dataTable: IElementoCalificacionGrid[], refreshTblView = true) {
        // -d Por cada dato filtrado de la tabla
        dataTable.forEach(dato => {
            const Item = this.ctrlTabla._SelectItemData(dato.IdAsignacion);
            const ArrAuxInfoEvals: IAuxInfoEvaluaciones[] = Array.from(Item.GetChildrenDataFiltered().values()).map<IAuxInfoEvaluaciones>(d => d.Data as any)
            ArrAuxInfoEvals.sort((a, b) => descending(a.IdEvaluacion, b.IdEvaluacion));
            ArrAuxInfoEvals.sort((a, b) => descending(a.FechaEval, b.FechaEval));
            dato.IdsEvaluaciones = ArrAuxInfoEvals.map(d => d.IdEvaluacion);
            dato.Evaluaciones = ArrAuxInfoEvals.map(d => d.Evaluacion);
            dato.FechasEval = ArrAuxInfoEvals.map(d => d.FechaEval)
            dato.Observaciones = ArrAuxInfoEvals.map(d => d.Observacion);
            dato.IdsEvaluadores = ArrAuxInfoEvals.map(d => d.IdEvaluador);
            dato.EvaluacionArr = [UIUtilViewCalificacion._GetEvalGral(ArrAuxInfoEvals.map(d => d.Evaluacion), dato.Tipo)[0]];
            dato.AuxInfoEvaluaciones = ArrAuxInfoEvals;
        });
        /* dataTable.forEach(dato => {
            // -d Selección de Item en tabla del dato iterado
            const Item = this.ctrlTabla.met_SelectItemData(dato.IdAsignacion);
            const Enabled = !!Item.GetChildrenDataFiltered().size;
            // -d ? El item actual tiene hijos post filtro ?
            if (Enabled) {
                // -d Recálculo y ajuste de evaluaciones del criterio
                const ArrAuxInfoEvals: IAuxInfoEvaluaciones[] = Array.from(Item.GetChildrenDataFiltered().values()).map<IAuxInfoEvaluaciones>(d => d.Data as any)
                dato.AuxInfoEvaluaciones = [...ArrAuxInfoEvals]
                dato.EvaluacionArr = UIUtilViewCalificacion.fn_GetEvalGral(ArrAuxInfoEvals.map(d => d.Evaluacion), dato.Tipo);
            }

            if (refreshTblView) {
                // -d ? el nuevo estado difiere del antiguo? => Se coloca el nuevo estado y Refresca la vista de la tabla
                if (Enabled !== Item.IsEnable) Item.EnableItemRow(Enabled).RefreshTableView();
            }
        }) */
    }

    private UI_UpdateCardInfoStatus(info: ICurrentData = this.GetCurrentInfoGeneral()) {
        const infoLeft = this.cardContentContainerSelection.select(".subareainfo_left");
        const infoRight = this.cardContentContainerSelection.select(".subareainfo_right");

        let strTutores = (info.Tutores.length == 0) ? UIUtilLang._GetUIString("general", "sinasignaciones") : info.Tutores.join(", ");

        infoLeft.html(`
                    <label>${this.CARDCOLL_GetUIStringModule("infoalumno_nombre")}:
                        <span>${info.NombreCompleto}</span>
                    </label>
                    <label>${this.CARDCOLL_GetUIStringModule("infoalumno_tutoresnombrea")}:
                        <span>${strTutores}</span>
                    </label>
                `);

        infoRight.html(`
                    <label>${this.CARDCOLL_GetUIStringModule("infocta_fecha")}:
                    <span>${UIUtilTime._DateFormatStandar(new Date())}</span>
                    </label>
                    <label>${this.CARDCOLL_GetUIStringModule("infoalumno_nivel")}:
                    <span>${info.StrNivel}</span>
                    </label>
                    <label>${this.CARDCOLL_GetUIStringModule("infoalumno_grado")}:
                    <span>${info.StrGrado}</span>
                    </label>
                `);

        // Habilitar/Deshabilitar btn
        this.UI_EnableMainButton(this.ctrlTabla._data.length > 0)
    }

    private UI_UpdateCardData(updateLogo = false, showProgressBar = false) {
        // -d Debería siempre de resetearse textarea de comentario??
        // this.cardContentContainerSelection.select<HTMLDivElement>(".area_comentario").select<HTMLTextAreaElement>(".comentario_area").node().value = '';
        if (!this.CARDCOLL_StatusCardExpandido || this.cardData == undefined) {
            this.ctrlTabla._UpdateData([]);
            return null;
        }

        // -d se obtiene Lista Ciclos escolares => Actualiza la lista del control => Se selecciona el actual o más cerano
        const cicloesEscolaresList = this.GetCiclosEscolaresList();
        this.ctrlSelectCicloEscolar._UpdateList(cicloesEscolaresList);
        let itemsDisabled = cicloesEscolaresList.filter(d => new Date().getTime() < new Date(d.FechaInicio).getTime())
        this.ctrlSelectCicloEscolar._disableItems(itemsDisabled.map(d => d.Id))
        let cicloEscCurrent: ICicloEscolar;
        if (!this.ctrlSelectCicloEscolar._dataValueMemberSelected.length) {
            cicloEscCurrent = this.GetCurrentCicloEscolar(cicloesEscolaresList);
            this.ctrlSelectCicloEscolar._valueSelect(cicloEscCurrent?.Id);
        }

        // -d se obtienen Lista Periodos del ciclo seleccionado => Actualiza la lista del control => Se selecciona el actual o más cerano
        const periodosList = this.GetPeriodosListFromCicloEscolar(this.ctrlSelectCicloEscolar._dataSelected[0], cicloesEscolaresList);
        this.ctrlSelectPeriodo._UpdateList(periodosList);
        if (!this.ctrlSelectPeriodo._dataValueMemberSelected.length) {
            const periodoCurrent = this.GetInitPeriodoFromCicloEsc(cicloEscCurrent, periodosList);
            this.ctrlSelectPeriodo._valueSelect(periodoCurrent?.Id);
        }

        const dataInfo = this.GetCurrentInfoGeneral(true);
        this.UI_UpdateCardInfoStatus(dataInfo);

        if (updateLogo) {
            this.cardContentContainerSelection.select<HTMLImage2Component>(".escuela_logo")
                .attr("src", this.GetEscuela().Logo);
        }
        if (!this.CARDCOLL_StatusCardExpandido) {
            this.ctrlTabla._UpdateData([]);
            return true;
        }


        return new Promise<boolean>(async (resolve, reject) => {
            if (showProgressBar) {
                this.ctrlProgress.attr("oculto", false);
            }

            this.UI_EnableMainButton(false);

            const idAlumno = this.GetIdCurretStudent();
            const BoletaCalificaciones: IElementoCalificacionGrid[] = [];
            // -d Ciclo escolar seleccionado [0]
            const [cicloEscolarSelected] = this.ctrlSelectCicloEscolar._dataValueMemberSelected;
            // -d Períodos seleccionados
            const periodosSelected: string[] = this.ctrlSelectPeriodo._dataSelected.map(d => new Date(d.Year, d.Month, 2).toISOString())
            const res2 = await _SvAlumnoGetBoletaCalificacionesV2([idAlumno], cicloEscolarSelected, periodosSelected);
            if (res2.Resultado > 0) {
                // Calificaciones
                const arrEvalsInBoleta: number[] = [];
                res2.Datos.forEach(elCalificado => {
                    arrEvalsInBoleta.push(...elCalificado.IdsEvaluaciones);
                    // -d Por cada criterio del alumno
                    // -d Arreglo auxiliar alimentado de las evaluaciones que ha tenido un criterio
                    const auxInfoEvaluaciones: IAuxInfoEvaluaciones[] = elCalificado.Evaluaciones
                        .map((d, i) => ({
                            Evaluacion: elCalificado.Evaluaciones[i],
                            FechaEval: elCalificado.FechasEval[i],
                            IdEvaluacion: elCalificado.IdsEvaluaciones[i],
                            IdEvaluador: elCalificado.IdsEvaluadores[i],
                            Observacion: elCalificado.Observaciones[i],
                            TipoEval: elCalificado.Tipo,
                        }))
                        .sort((a, b) => b.FechaEval.localeCompare(a.FechaEval))
                    // -d Al arreglo de criterios del alumno se agrega el criterio actual, con 2 propiedades más
                    // -d EvaluacionArr => Es la calificación del criterio("10\r\n9\r\n8") convertida a Array([10,9,8]);
                    // -d AuxInfoEvaluaciones => Arreglo auxInfoEvaluaciones ordenado por fecha más reciente a vieja
                    auxInfoEvaluaciones.sort((a, b) => descending(a.IdEvaluacion, b.IdEvaluacion));
                    auxInfoEvaluaciones.sort((a, b) => descending(a.FechaEval, b.FechaEval));
                    elCalificado.Evaluacion = [UIUtilViewCalificacion._GetEvalGral(auxInfoEvaluaciones.map(d => d.Evaluacion), elCalificado.Tipo)[0]].join("\r\n");
                    const evaluaciones = elCalificado.Evaluacion.split("\r\n");
                    if (elCalificado.Tipo == CTipoEvaluacion.Numeros)
                        evaluaciones.forEach((d, i) => { evaluaciones[i] = Number(d).toFixed(1) });
                    BoletaCalificaciones.push({
                        ...elCalificado,
                        EvaluacionArr: evaluaciones,
                        AuxInfoEvaluaciones: auxInfoEvaluaciones
                    });
                })
                // -d Llenado el arreglo de criterios de alumno se agrega al mapa
                this.boletasMap.set(idAlumno, BoletaCalificaciones);
            } else {
                this.ctrlNotification._Mostrar(this.CARDCOLL_GetUIStringModule("notif_failgetboleta"), "ADVERTENCIA");
            }
            this.ctrlTabla._CheckAllItems(false);
            this.ctrlTabla._UpdateData(this.GetBoletaList());
            if (showProgressBar) {
                this.ctrlProgress.attr("oculto", true);
            }
            this.UI_EnableMainButton(this.ctrlTabla._data.length > 0);
            resolve(true);
        })
    }
    // -d Obtener lista de boleta (Arreglo de criterios calificados || [])
    private GetBoletaList(): IElementoCalificacionGrid[] {
        return (this.boletasMap.get(this.GetIdCurretStudent()) || []);
    }
    private UI_UpdateCardPermissions() {
        const hasPermission = UIUtilPermission._HasAccionPermission(Entidad.CAccionPermiso.Ver, Entidad.CModulo.PanelAlumnoMaterias, this.GetIdEscuela())
        this.btnGuardarCard._enabled = hasPermission;
        if (this.CARDCOLL_StatusCardExpandido) {
            this.cardFooterSelection.classed("hide", !hasPermission);
        } else {
            this.cardFooterSelection.classed("hide", true);
        }
        if (hasPermission) {
            this.btnGuardarCard._d3Selection
                .text(this.CARDCOLL_GetUIStringModule("tag_generarboleta"))
                .on("click", async () => {
                    if (this.btnGuardarCard._d3Selection.attr("oculto") == "true") return;

                    const evaluacionesChecked: UIUtilViewCalificacion.IElementoCalificado[] = this.ctrlTabla._dataChecked || [];
                    if (!evaluacionesChecked.length) {
                        this.ctrlNotification._Mostrar(_L("panelboletas.select_required"), "ADVERTENCIA");
                        return;
                    }
                    this.btnGuardarCard._d3Selection.attr("oculto", true);
                    this.UI_EnableMainButton(false);
                    const [cicloEscSelected] = this.ctrlSelectCicloEscolar._dataSelected;
                    const periodosDtSelected: Date[] = this.ctrlSelectPeriodo._dataSelected.map(d => new Date(d.Year, d.Month, 1));
                    const { dtInicio, dtFin } = UIUtilViewCalificacion._GetInitAndEndInPeriodosList(periodosDtSelected);
                    dtInicio._SetTimeZoneByIdSchool(this.GetIdEscuela(), true);
                    dtFin._SetTimeZoneByIdSchool(this.GetIdEscuela(), true);
                    dtInicio._SetTimeZoneByIdSchool(this.GetIdEscuela(), true);
                    const strInit = new DateV2(dtInicio)._ToISOLocalString();
                    const strFin = new DateV2(dtFin)._ToISOLocalString();
                    this.ctrlProgress.attr("oculto", false);
                    const resLoadInfoToBuildGradesReport = await UIUtilViewCalificacion._LoadInfoToBuildReport([this.GetIdCurretStudent()], this.GetCurrentStudent().IdKinder, cicloEscSelected.Id, strInit, strFin);
                    this.ctrlProgress.attr("oculto", true);
                    if (!resLoadInfoToBuildGradesReport.successReload) { this.ctrlNotification._Mostrar(UIUtilLang._GetUIString("general", "notif_fail"), "ADVERTENCIA"); return; }

                    // let comentario = this.cardContentContainerSelection.select<HTMLTextAreaElement>(".area_comentario .comentario_area").node().value;

                    let otherInfo = resLoadInfoToBuildGradesReport.mapOtherInfo.get(this.GetIdCurretStudent());
                    let inasistenciasFiltered = [...otherInfo?.Inasistencias];
                    if (otherInfo?.Inasistencias) {
                        inasistenciasFiltered = UIUtilViewCalificacion._FilterInasistenciasByRanges(inasistenciasFiltered, periodosDtSelected, this.GetIdEscuela(), (this.dtInitRange) ? { dtInitRange: this.dtInitRange, dtFinRange: this.dtFinRange } : null)
                    }
                    const boletasInfoChild = resLoadInfoToBuildGradesReport.mapBoletasInfoChild.get(this.GetIdCurretStudent());
                    if (boletasInfoChild && boletasInfoChild.length > 2) {
                        this.ctrlNotification._Mostrar(_L("panelboletas.notif_boletaslimitexceeded"), "ADVERTENCIA", 4000)
                        this.btnGuardarCard._d3Selection.attr("oculto", null);
                        this.UI_EnableMainButton(true);
                        return;
                    }

                    const auxArrInfoBoletas = resLoadInfoToBuildGradesReport.arrAsignacionInfo;

                    const currentPeriodEvalIds: number[] = [];
                    evaluacionesChecked.forEach(d => {
                        currentPeriodEvalIds.push(...d.IdsEvaluaciones)
                    })

                    const periodsErr: number[] = []
                    if (boletasInfoChild) {
                        boletasInfoChild.sort((a, b) => ascending(a.FechaCreacion, b.FechaCreacion));
                        boletasInfoChild.forEach((boleta, iPos) => {
                            const hasIdEvalRepeat = boleta.IdsEvaluaciones.some(idEval => currentPeriodEvalIds.some(currenPIdEval => currenPIdEval == idEval))
                            if (hasIdEvalRepeat) { periodsErr.push(iPos + 1); }
                        })
                    }

                    if (periodsErr.length) {
                        this.ctrlNotification._Mostrar(_L("panelboletas.notif_evalsrepeat") + ": " + periodsErr.map(d => `${_L("panelboletas.tag_periodo")}: ${d}`).join(", "), "ADVERTENCIA", 4000)
                        this.btnGuardarCard._d3Selection.attr("oculto", null);
                        this.UI_EnableMainButton(true);
                        return;
                    }

                    const boletaInfo: IAlumnoBoleta = {
                        Alumno: this.GetCurrentStudent(),
                        Evaluaciones: evaluacionesChecked,
                        CicloEscolar: cicloEscSelected,
                        Comentario: "",
                        InfoExtra: resLoadInfoToBuildGradesReport.mapInfoExtra.get(this.GetIdCurretStudent()),
                        Inasistencias: otherInfo?.Inasistencias ? inasistenciasFiltered.length : null,
                        BoletasGeneradasEnCiclo: boletasInfoChild
                    }
                    UIUtilViewCalificacion._OpenModalGenerarBoletas([boletaInfo], auxArrInfoBoletas, () => {
                        this.btnGuardarCard._d3Selection.attr("oculto", null);
                        this.UI_EnableMainButton(true);
                    })
                })
        }
    }

    private UI_EnableMainButton(enable: boolean) {
        Button._EnableButton(this.btnGuardarCard._d3Selection, enable);
    }

    private CreateCalificacionColumn(tipoEval: CTipoEvaluacion, calificaciones: string[], container: TSelectionHTML<"div", any, any>) {
        switch (tipoEval) {
            case CTipoEvaluacion.Letras:
            case CTipoEvaluacion.Numeros:
                container.text(calificaciones.join(', '))
                break;
            case CTipoEvaluacion.Colores:
                container.text("");
                container.style("display", "flex").style("gap", "10px");
                calificaciones.forEach(calificacion => {
                    container.append("div")
                        .style("height", "23px")
                        .style("width", "23px")
                        .style("background-color", calificacion)
                        .style("border-radius", "50%")
                        .style("border", "1px solid var(--color_borderbox1)");
                })
                break;
            case CTipoEvaluacion.Cualitativa:
                if (calificaciones.length == 1 && calificaciones[0] == "") container.text("")
                else container.text(calificaciones.map(d => "• " + d).join("\n"));
                break;
        }
    }

    private GetCurrentInfoGeneral(forceUpdt: boolean = false) {
        const student = this.GetCurrentStudent();
        if (!this.infoGeneral) {
            forceUpdt = true;
        } else if (this.infoGeneral.IdAlumno == student.IdChild || this.infoGeneral.StrCicloEscolar != this.ctrlSelectCicloEscolar._dataSelected[0].Nombre) {
            forceUpdt = true
        }

        if (forceUpdt) {
            let tutores: string[] = [];
            if (student) {
                tutores = Array.from(_LOCALDATA_GetTutoresDeAlumno(student.IdChild).values())
                    .map(d => (`${d.Nombre} ${d.ApPaterno}`));
            }

            this.infoGeneral = {
                IdAlumno: student?.IdChild,
                NombreCompleto: (student?.NombreCompleto || ""),
                Tutores: tutores,
                StrNivel: (student.StrEscolaridad || ""),
                StrGrado: (student.StrGrado || ""),
                StrGruposPrincipales: (student.StrGrupoPrincipalNames || [""]),
                StrCicloEscolar: this.ctrlSelectCicloEscolar._dataSelected[0]?.Nombre || "",
            }
        }

        return this.infoGeneral;
    }

    private GetCurrentStudent() {
        return this.cardData[0];
    }

    private GetIdCurretStudent() {
        return this.GetCurrentStudent()?.IdChild;
    }

    private GetEscuela() {
        return DataModuloEscuela._DiccEscuela.get(this.GetIdEscuela());
    }

    private GetIdEscuela() {
        return this.cardData[0]?.IdKinder;
    }

    /** Obtiene todos los ciclos escolares disponibles de la escuela actual */
    private GetCiclosEscolaresList() {
        const idEscuela = this.GetIdEscuela();
        this.ciclosEscolaresList = DataModuloMain._GetReqDataArrayByName("CicloEscolar")
            .filter(dCiclo => (dCiclo.IdEscuela == idEscuela))
            .sort((a, b) => Number(new Date(b.FechaInicio)) - Number(new Date(a.FechaInicio)));
        return (this.ciclosEscolaresList || []);
    }

    /**
     * @returns
     * * El ciclo escolar actual (de acuerdo a la fecha actual)
     * * En caso de no existir uno, retorna el primer elemento de la lista
     * */
    private GetCurrentCicloEscolar(ciclosEscolares = DataModuloMain._GetReqDataArrayByName("CicloEscolar")): (ICicloEscolar | null) {
        const idEscuela = this.GetIdEscuela();
        const now = new DateV2()._SetTimeZoneByIdSchool(idEscuela).getTime();

        let cicloEsc = ciclosEscolares
            .filter(dCiclo => (dCiclo.IdEscuela == idEscuela))
            .find(d => new DateV2(d.FechaInicio)._SetTimeZoneByIdSchool(idEscuela).getTime() <= now && new DateV2(d.FechaFin)._SetTimeZoneByIdSchool(idEscuela).getTime() >= now);

        if (!cicloEsc) {
            cicloEsc = ciclosEscolares[0];
        }

        return cicloEsc;
    }

    private GetPeriodosListFromCicloEscolar(cicloEscolar: ICicloEscolar, ciclosEscolaresList = this.GetCiclosEscolaresList()): IPeriodoD[] {
        //const ultimoCiclo = ciclosEscolaresList.findIndex(c => c.Id == cicloEscolar.Id) == 0;
        let periodos: IPeriodoD[] = [];

        if (cicloEscolar) {
            periodos = UIUtilViewData._GetPeriodosFromCicloEscolar(cicloEscolar, true).map(d => ({ ...d, EnCicloEscolar: true }));
        }

        return periodos;
    }

    private GetInitPeriodoFromCicloEsc(cicloEscolar: ICicloEscolar, periodos = this.GetPeriodosListFromCicloEscolar(cicloEscolar)) {
        const now = new Date();

        let periodo = periodos
            .find(d => d.Month == now.getMonth() && d.Year == now.getFullYear());

        if (!periodo && periodos) {
            periodo = periodos[0];
        }

        return periodo;
    }

    /* private OpenModalGenerarBoletasCal(datos: IBoletaData[], onClose: () => void) {
        type IAlumno = Entidad.IAlumno;

        type ItemAlumno = Pick<IAlumno, "IdChild" | "NombreCompleto"> & {
            Selected: boolean;
            IdEscuela: number;
            PDF: File;
            PDFStatus: number;
            PDFFileName: string;
            AlumnoDescripcion: string;
        }

        const manySchools = d3Group(datos, (d) => d.IdKinder).size > 1;
        const dataPorAlumno = new Map<number, IBoletaData>()
        const dataAlumnosList = new ArrayV2<ItemAlumno>();

        datos.forEach(alumno => {
            const alumnoData = DataModuloMain._GetItemDataByName("Alumno", alumno.IdAlumno);
            let strDescripcion = "";
            if (alumnoData.Matricula) {
                strDescripcion += UIUtilLang._GetUIString("alumnos", "d_field_matricula") + ": " + alumnoData.Matricula + "\n";
            }
            if (manySchools) {
                strDescripcion += this.CARDCOLL_GetUIStringModule("d_field_strescuela") + ": " + alumnoData.NombreKinder;
            }
            dataAlumnosList.push({
                IdChild: alumno.IdAlumno,
                IdEscuela: alumnoData.KinderFiltro[0],
                NombreCompleto: alumnoData.NombreCompleto,
                Selected: false,
                PDF: null,
                PDFStatus: 0,
                PDFFileName: this.CARDCOLL_GetUIStringModule("boleta") + " " + UIUtilTime._GetValidatorDateYMD(new Date()) + " - " + alumnoData.NombreCompleto + ".pdf",
                AlumnoDescripcion: strDescripcion,
            });

            dataPorAlumno.set(alumno.IdAlumno, alumno);
        })

        ModalThings._GetModalToAProccess({
            Title: this.CARDCOLL_GetUIStringModule("generarboleta_title"),
            Width: 1000,
            Height: "800px",
            DrawContent: (content, mt) => {
                content.classed(UIUtilGeneral.FBoxOrientation.Horizontal, true);
                let divPadre = content.append("div");
                divPadre.classed("divPadreLateral", true);
                const ctrlList = new List<ItemAlumno>()
                    ._SetParent(divPadre.node())
                    ._SetCreateTemplate((itemContainer, d) => {
                        let lbl = itemContainer
                            .classed(UIUtilGeneral.FBoxOrientation.Horizontal, true)
                            .classed(UIUtilGeneral.FBoxAlign.SpacebetweenCenter, true)
                            .append("label");
                        lbl.append("span")
                            .attr("id", "name");
                        lbl.append("pre")
                            .attr("id", "description");
                    })
                    ._SetUpdateItem((itemContainer, d) => {
                        itemContainer.select("#name")
                            .text(d.NombreCompleto);
                        itemContainer.select("#description")
                            .text(d.AlumnoDescripcion);

                        itemContainer.on("click", async (d) => {
                            if (!d.Selected) {
                                dataAlumnosList.forEach(d => d.Selected = false);
                                d.Selected = true;
                                if (!d.PDF || d.PDFStatus != 1) {
                                    await fnAlumnoPDFBuild(d);
                                } else {
                                    ctrlList._RefreshList();
                                    PDFThings._PDFViewer(d.PDF, pdfViewerContainer.node());
                                }
                            }
                        })

                        if (d.Selected) {
                            if (!itemContainer.select(".opciones").node()) {
                                itemContainer.style("background-color", "var(--color_primary2)");

                                const opcionesContainer = itemContainer.append("div")
                                    .attr("class", "opciones")
                                    .classed(UIUtilGeneral.FBoxOrientation.Horizontal, true)
                                    .classed(UIUtilGeneral.FBoxAlign.EndCenter, true)
                                    .style("min-width", "min-width")
                                    .style("gap", "var(--padding1)");

                                const tooltipElement = itemContainer.append<HTMLTooltipComponent>("wc-tooltip").node();

                                tooltipElement._SetObservedElementsAdvanced(
                                    {
                                        Target: opcionesContainer.append("img")
                                            .attr("class", "btn_round")
                                            .attr("src", UIUtilIconResources.CGeneral.Print)
                                            .attr("draggable", false)
                                            .on("click", () => {
                                                let uri = URL.createObjectURL(d.PDF)
                                                printJS({
                                                    printable: uri,
                                                    type: "pdf",
                                                    showModal: true,
                                                    onPrintDialogClose() {
                                                        URL.revokeObjectURL(uri);
                                                    }
                                                });
                                            })
                                            .node(),
                                        Text: UIUtilLang._GetUIString("general", "print"),
                                    },
                                    {
                                        Target: opcionesContainer.append("img")
                                            .attr("class", "btn_round")
                                            .attr("src", UIUtilIconResources.CGeneral.Download)
                                            .attr("draggable", false)
                                            .on("click", () => saveAs(d.PDF, d.PDFFileName))
                                            .node(),
                                        Text: UIUtilLang._GetUIString("general", "descargar"),
                                    },
                                    {
                                        Text: this.CARDCOLL_GetUIStringModule("enviartutor"),
                                        Target: opcionesContainer.append("img")
                                            .attr("class", "btn_round")
                                            .attr("src", UIUtilIconResources.CGeneral.Circular2)
                                            .attr("draggable", false)
                                            .on("click", async () => {
                                                let messageCircular = "";
                                                ModalThings._GetConfirmacionModal({
                                                    Title: this.CARDCOLL_GetUIStringModule("title_confirm_envio"),
                                                    Message: "Mensaje (opcional):",
                                                    DrawContent: (content, mt) => {
                                                        content.append("br");
                                                        // let messgCont = content.append("div").classed(Util.FBoxOrientation.Horizontal, true).style("gap", "var(--padding3)").style("margin-top", "var(--padding3)");
                                                        // messgCont.append("label").text("Mensaje (opcional):");
                                                        let inputMssg = content.append("textarea").style("margin-top", "var(--padding2)").node();
                                                        inputMssg.oninput = e => {
                                                            messageCircular = inputMssg.value;
                                                        }
                                                    },
                                                    OnAccept: async (mt) => {
                                                        mt.Progress.attr("oculto", false);
                                                        mt.Modal._DeshabilitarBtns();
                                                        let res = await DataModuloCircular._AltaCircular("ByAlumnos", <IMapSendCincularItems["ByAlumnos"]>{
                                                            IdEscuela: d.IdEscuela,
                                                            Titulo: this.CARDCOLL_GetUIStringModule("boleta") + " " + UIUtilTime._GetValidatorDateYMD(new Date()) + " - " + d.NombreCompleto,
                                                            Mensaje: messageCircular,
                                                            Archivo: d.PDF,
                                                            Programado: null,
                                                            ReqAutorizacion: false,
                                                            IdAlumnos: [d.IdChild]
                                                        })
                                                        mt.Progress.attr("oculto", true);
                                                        mt.Modal._HabilitarBtns();
                                                        if (res.Resultado > 0) {
                                                            NotificacionV2._Mostrar(this.CARDCOLL_GetUIStringModule("success_process"), "INFO");
                                                        } else {
                                                            NotificacionV2._Mostrar(this.CARDCOLL_GetUIStringModule("fail_process"), "ADVERTENCIA");
                                                        }
                                                    },
                                                })
                                            })
                                            .node(),
                                    },
                                );
                            }
                        }
                        else {
                            itemContainer.style("background-color", null)
                            itemContainer.select(".opciones").remove();
                            itemContainer.select("wc-tooltip").remove();
                        }
                    })
                    ._SetItems(dataAlumnosList);

                let divButtons = divPadre.append("div");
                divButtons.classed("divButtons", true);

                new Button(divButtons.node(), UIUtilLang._GetUIString("general", "descargartodo"))
                    ._d3Selection
                    .on("click", () => {
                        fnBuildAndDownloadAllPDF();
                    });

                new Button(divButtons.node(), this.CARDCOLL_GetUIStringModule("enviartutores"))
                    ._d3Selection.classed("sendTutores", true)
                    .on("click", async () => {
                        let btnSendTutores = mt.CurrentBody.select(".divPadreLateral").select(".divButtons").select<HTMLButtonElement>(".sendTutores");
                        if (btnSendTutores.classed("btn_disable")) return;
                        btnSendTutores.classed("btn_disable", true);
                        mt.Progress.attr("oculto", false);
                        mt.Modal._DeshabilitarBtns();
                        let arrErr: ItemAlumno[] = [];
                        await dataAlumnosList._ForEachAwait(async (d) => {
                            await fnAlumnoPDFBuild(d, true);
                            if (!d.PDF || d.PDFStatus != 1) {
                                arrErr.push(d);
                                btnSendTutores.classed("btn_disable", false);
                                return;
                            }
                        });
                        if (arrErr.length) {
                            NotificacionV2._Mostrar(this.CARDCOLL_GetUIStringModule("pdf_fail_construct"), "ADVERTENCIA")
                            mt.Progress.attr("oculto", true);
                            mt.Modal._HabilitarBtns();
                            btnSendTutores.classed("btn_disable", false);
                            return;
                        }
                        let messageCircular: string = "";
                        ModalThings._OpenModalToProccessServiceByServiceFromAArrayBasic({
                            Title: this.CARDCOLL_GetUIStringModule("title_confirm_envio"),
                            Message: "Mensaje (opcional):",
                            OnDrawContent: (content) => {
                                content.append("br");
                                let inputMssg = content.append("textarea").style("margin-top", "var(--padding2)").node();
                                inputMssg.oninput = e => {
                                    messageCircular = inputMssg.value;
                                }
                            },
                            DataToProccess: dataAlumnosList,
                            Width: 400,
                            OnStepAProccess: async (d) => {
                                let horariosGruposMap = _LOCALDATA_GetGruposHorariosDeAlumno(d.IdChild, d.IdEscuela);
                                let horariosGruposArray = Array.from(horariosGruposMap.values());
                                let grupo = horariosGruposArray.find(d => (d.EsPrincipal))
                                if (!grupo) grupo = horariosGruposArray[0];
                                let blob = d.PDF;
                                return DataModuloCircular._AltaCircular("ByAlumnos", <IMapSendCincularItems["ByAlumnos"]>{
                                    IdEscuela: d.IdEscuela,
                                    Titulo: this.CARDCOLL_GetUIStringModule("boleta") + " " + UIUtilTime._GetValidatorDateYMD(new Date()) + " - " + d.NombreCompleto,
                                    Mensaje: messageCircular,
                                    Archivo: new File([blob], d.PDFFileName, { type: blob.type }),
                                    Programado: null,
                                    ReqAutorizacion: false,
                                    IdAlumnos: [d.IdChild]
                                })
                            },
                            OnError_GetItemDataTag: (d) => {
                                return `${d.NombreCompleto}`;
                            },
                            TypeRequest: null
                        }).then((value) => {
                            btnSendTutores.classed("btn_disable", false);
                        }).catch((err) => null);
                        mt.Progress.attr("oculto", true);
                        mt.Modal._HabilitarBtns();
                    });

                const pdfViewerContainer = content.append("div")
                    .classed("pdfViewerContainer", true);

                const fnAlumnoPDFBuild = async (d: ItemAlumno, showProgres = true) => {
                    if (!d.PDF || d.PDFStatus !== 1) {
                        if (showProgres) mt.Progress.attr("oculto", false);
                        let pdf = await GetPDFBuilded(d.IdChild, d.NombreCompleto, d.IdEscuela);
                        d.PDF = pdf;
                        d.PDFStatus = (!pdf) ? 0 : 1;
                        if (showProgres) mt.Progress.attr("oculto", true);
                        if (d.Selected) {
                            ctrlList._RefreshList();
                            PDFThings._PDFViewer(d.PDF, pdfViewerContainer.node());
                        }
                    }
                }

                const fnBuildAndDownloadAllPDF = async () => {
                    const resultFolderName = this.CARDCOLL_GetUIStringModule("boleta_title") + " " + UIUtilTime._GetValidatorDateYMD(new Date());
                    const zip = new JSZip();
                    const resumenFolder = zip.folder(resultFolderName);
                    mt.Progress.attr("oculto", false);
                    await dataAlumnosList
                        ._ForEachAwait(async (d) => {
                            await fnAlumnoPDFBuild(d, false);
                            let blob = d.PDF
                            resumenFolder.file(d.PDFFileName, blob);
                        })
                    zip.generateAsync({ type: "blob" })
                        .then(function (content) {
                            saveAs(content, resultFolderName);
                        })
                        .catch((e) => {
                            DataUtilAlertBot._SendError(e, "Reporte Boleta calificaciones");
                            this.ctrlNotification._Mostrar(UIUtilLang._GetUIString("general", "notif_fail"), "ADVERTENCIA");
                        })
                        .finally(() => {
                            mt.Progress.attr("oculto", true);
                        });
                }

                setTimeout(async () => {
                    if (dataPorAlumno.size == 1) {
                        let alumno = dataAlumnosList[0];
                        alumno.Selected = true;
                        fnAlumnoPDFBuild(alumno);
                    }
                });
            },
            OnClose: () => {
                onClose();
            }
        });

        const GetPDFBuilded = (idAlumno: number, nombreAlumno: string, idEscuela: number) => {
            const tutores = Array.from(_LOCALDATA_GetTutoresDeAlumno(idAlumno).values())
                .map(d => d.NombreCompleto)
            const calificacionesAlumno = dataPorAlumno.get(idAlumno);

            return UIUtilViewCalificacion._BoletaDiaPDFV1({
                NombreAlumno: nombreAlumno,
                IdEscuela: idEscuela,
                Tutores: tutores,
                Grado: calificacionesAlumno.Alumno.StrGrado,
                Nivel: calificacionesAlumno.Alumno.StrEscolaridad,
                Evaluaciones: calificacionesAlumno.Calificaciones.map<UIUtilViewCalificacion.IEvaluacionInfo>(d => {
                    return {
                        Grupo: DataModuloMain._GetDataValueFieldByName("Grupo", d.IdGrupo, "Nombre") || "No disponible",
                        Materia: d.StrMateria,
                        CriterioEval: (!d.Criterios.length) ? [{ Criterio: d.StrMateria, Evaluacion: d.Evaluacion }] : d.Criterios.map<UIUtilViewCalificacion.ICriterioEval>(c => {
                            return {
                                Criterio: c.Criterio,
                                Evaluacion: c.EvaluacionArr
                            }
                        }),
                        TipoEval: d.TipoEval,
                        MinimoAprobatorio: d.MinimoAprobatorio,
                        EvalDescripcion: d.ValDescripcion,
                        EvalValor: d.ValNombres
                    }
                }),
                Comentario: calificacionesAlumno.Comentario
            })
        }
    } */

}
