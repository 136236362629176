import * as d3 from "d3";
import saveAs from "file-saver";
import { DataDRequest } from "../../data/DRequest";
import { HTMLSpinnerElement } from "../controlWC/SpinnerComponent";
import { UIUtilIconResources } from "../util/IconResourses";
import { UIUtilLang } from "../util/Language";
import { UIUtilMimeType } from "../util/MimeTypes";
import { UIUtilGeneral } from "../util/Util";
import { Button } from "./Button";
import { NotificacionV2 } from "./NotificacionV2";
import { FileLoaded } from "./InputFileControlV2FileItem";
import { InputFileDialog } from "./InputFileDialog";
import _L from "../../util/Labels";

export namespace InputFileControl {
    import CDefaultSrc = UIUtilIconResources.CGeneral;
    export interface IConfig {
        ControlStyle?: ("foto_control_style2" | "foto_control_style3")
        /** @default "#fff" */
        DefaultBackgroundColor?: string;
        DefaultBackgroundImage?: CDefaultSrc | string;
        ImageMargin?: string;
        /** @default controlD3.FotoControlForm.Circular */
        DefaultBackgroundText?: string;
        /** @default ControlForm.Circular */
        ControlForm?: TControlForm;
        /** * Muestra un tag con la extension del archivo (cuándo es disponible) disponible para archivo Video e Imagen
         * @default false
         *  */
        ShowExtensionTag?: boolean;
        /** Muestra el tag en rojo cuando falla la carga del archivo, "error" en caso de no estar disponible la extension
         * @default true */
        ShowErrorTag?: boolean;
        /** @default 90px */
        Dimentions?: number | string;
        MinDimention?: string;
        MaxDimention?: string;
        /**
         * @default [".png", ".jpeg", ".jpg", ".nef", ".cr2"]
         * @example [".doc",".docx","application/msword", "image/jpeg"]
         * @invalid ["* /* ", "image/*"]
         * */
        FilesAccept?: string[];
        Border?: IBorder;
        /** Color de spinner de carga */
        SpinnerColor?: string;
        /** Relación de aspecto de imagen
         * @default "cover" */
        AspectRatio?: "fill" | "cover" | "contain" | "scale-down";
        /** Valida si el recurso puede ser mostrado después de seleccionarlo
         * *  Se invoca solo cuando el usuario da una entrada
         * @param isImage ¿El archivo es imagen?
         * @param isFromInput es verdadero si el origen del archivo es por entrada del usuario desde algún archivo local
         * @param file
         * @param base64 devuelve valor si es imagen */
        OnValideBeforeRender?: (isImage: boolean, isFromInput: boolean, file: File, base64: string) => Promise<boolean>;
        /** Se invoca siempre que que el archivo que contiene el control, es modificado (siempre que el proceso sea exitoso)
         * @param isImage ¿El archivo es imagen?
         * @param isFromInput es verdadero si el origen del archivo es por entrada del usuario desde algún archivo local
         * @param file
         * @param base64 devuelve valor si es imagen */
        OnChange?: (isImage: boolean, isFromInput: boolean, file: File, base64: string) => void;
        /** Se invoca al final de cualquier proceso de carga de archivo, sea exitoso o no */
        OnEndLoadFileStatus?: (isImage: boolean, isFromInput: boolean, correcto: boolean) => void;
        /** Identificador de consolas TEST */
        NameTest?: string;
        /** @default 5 (px) */
        SpinnerWidth?: number;

        /** @default false
         * @deprecated
        */
        Disabled?: boolean; // FIXME Ajustar: DisableAll ?
        /** Ver btn CargarArchivo ubicado al fondo del control y sobre la imagen
         * @default true
         * */
        ShowAreaControlOptions?: boolean;

        /** Ver btn CargarArchivo ubicado al fondo del control y sobre la imagen
         * @default true
         * */
        ShowBtnLoadFile?: boolean;
        /** @default false */
        ShowBtnDownloadFile?: boolean;

        OnClickImageArea?: () => void;
        /** @returns url-resource | file */
        OnDownload_GetRealResource?: () => string | File | Promise<File>;

        OnError?: (type: "ondownload", e: Error) => void;
        /** @default () => "kidiadmin-image" */
        OnGetFileName?: () => string;

        OptionsExtras?: IOption[];
    }

    interface IOption {
        readonly ID: (number | string);
        // Label?: string;
        Icon?: string
        OnUpdateViewContent?: (optionContainer: d3.Selection<HTMLDivElement, IOption, any, any>) => void,
        OnClick: () => Promise<any>;
        Visible?: boolean;
        // Enable?: boolean;
    }

    interface IOptionAdvanced extends IOption {
        ViewContent?: d3.Selection<any, any, any, any>;
    }

    interface IOptionSelection {
        // OnClick(event: () => void): IOptionSelection;
        Visible(visible: boolean): IOptionSelection;
        OnGetViewContent: () => d3.Selection<HTMLDivElement, any, any, any>;
    }

    export const ImagesAccept = [".png", ".jpeg", ".jpg", ".nef", ".cr2"];

    interface IBorder {
        /** @default 0 */
        Width: number;
        Color?: string;
    }

    /** @deprecated */
    export enum ControlForm {
        Circular = "circle",
        /** Cuadrado con bordes redondeados */
        Semicuadrado = "square_roundededges",
        Cuadrado = "normal",
        None = "none"
    }
    type TControlForm = "circle" | "square_roundededges" | "normal" | "none";

    export class InputFile {
        #controlContainer: d3.Selection<HTMLDivElement, any, (HTMLElement | null), any>;
        #controlContent: d3.Selection<HTMLDivElement, any, (HTMLElement | null), any>;
        #imgElement: HTMLImageElement;
        // #inputElement: HTMLInputElement;
        #ctrlSpinner: TSelectionHTML<"wc-spinner">;

        #config: IConfig;
        #auxExtensionFake: string;
        #fileController: FileLoaded;
        #urlResource: string;
        #isLoadingFile: boolean;

        #prefix_test: string;
        #timeoutUpdate: NodeJS.Timeout;

        constructor(config?: IConfig) {
            this.#config = {
                ...<IConfig>{
                    ControlForm: ControlForm.Circular,
                    Disabled: false,
                    DefaultBackgroundColor: "#fff",
                    ShowExtensionTag: false,
                    ShowErrorTag: true,
                    ShowAreaControlOptions: true,
                    ShowBtnLoadFile: true,
                    ShowBtnDownloadFile: false,
                    Dimentions: 90,
                    FilesAccept: ImagesAccept,
                    AspectRatio: "cover",
                    Border: {
                        Width: 0,
                        Color: null
                    },
                    SpinnerWidth: 5,
                    OnGetFileName: () => "kidiadmin-image"
                },
                ...config
            }

            // let extFixed: string[] = [];
            // this.#config.FilesAccept
            //     .forEach(d => {
            //         if (d.includes("/")) {
            //             let exts = Utils.MimeType.fn_GetExtensionsFromMimeType(d);
            //             if (exts?.length) {
            //                 extFixed.push(...exts);
            //             }
            //         }
            //         return extFixed.push(d);
            //     });
            // this.#config.FilesAccept = extFixed;

            this.#prefix_test = `IMG ${this.#config.NameTest} >>`;
            this.#isLoadingFile = false;

            this.#fileController = new FileLoaded();
            // .met_AllowedExtensions(this.#config.FilesAccept);

            this.UI_BuildControl();
        }

        private UI_BuildControl() {
            this.#controlContainer = d3.create("div")
                .classed("foto_control_container", true)

            if (this.#config.ControlStyle) {
                this.#controlContainer
                    .classed(this.#config.ControlStyle, true);
            }

            this.#controlContent = this.#controlContainer.append("div")
                .classed("foto_control", true)
                .classed(this.#config.ControlForm, true);

            let resizeObserver = UIUtilGeneral._GetResizeObserver((entries, observer) => {
                (entries[0].target as HTMLDivElement).style.height = entries[0].contentRect.width + "px";
            });
            resizeObserver.observe(this.#controlContainer.node());

            // this.UI_UpdateDefaultBackground("principal");

            this.#ctrlSpinner = this.#controlContainer.append<HTMLSpinnerElement>("wc-spinner")
                .attr("center", "");
            this.#ctrlSpinner.classed("hide", true);
            if (this.#config.SpinnerColor) this.#ctrlSpinner.attr("color", this.#config.SpinnerColor);
            setTimeout(() => {
                if (!this.#ctrlSpinner.attr("dim") || this.#ctrlSpinner.attr("dim") == "0")
                    this.#ctrlSpinner.attr("dim", this.#controlContainer.node().offsetWidth)
            });

            this.#imgElement = this.#controlContent.append("img")
                .classed("hide", true)
                .attr("draggable", false)
                .style("margin", this.#config.ImageMargin)
                .node();


            this.#imgElement
                .style
                .objectFit = this.#config.AspectRatio;

            this._Disabled = this.#config.Disabled;
            this._Border = this.#config.Border;
            this._SpinnerWidth = this.#config.SpinnerWidth;

            this.UI_UpdateOnClickImageArea();
            this.UI_UpdateOptionsArea();
            this.UI_UpdateView();
            this.UI_UpdateDimentions();
        }

        private UI_UpdateDimentions() {
            this.#config.Dimentions = typeof this.#config.Dimentions == "number" ? this.#config.Dimentions + "px" : this.#config.Dimentions;

            if (!this.#config.MinDimention) {
                this.#config.MinDimention = this.#config.Dimentions;
            }
            if (!this.#config.MaxDimention) {
                this.#config.MinDimention = this.#config.Dimentions;
            }

            this.#controlContainer
                .style("min-width", this.#config.MinDimention)
                .style("min-height", this.#config.MinDimention)
                .style("max-width", this.#config.MaxDimention)
                .style("max-height", this.#config.MaxDimention);

            // if (this.#config.Dimentions != "90px") {
            this.#controlContainer
                .style("width", this.#config.Dimentions);
            // } else {
            //     this.#containerBaseSel
            //         .style("width", null);
            // }
        }

        private async UI_UpdateView() {
            const fileC = this.#fileController;
            const config = this.#config;
            this.#controlContent.select(".tag_text_back").remove();
            this.#controlContent.style("background-image", null);
            this.#controlContent.style("background-color", null);

            if (config.DefaultBackgroundColor && config.DefaultBackgroundColor != "#fff") {
                this.#controlContent
                    .style("background-color", config.DefaultBackgroundColor);
            }

            d3.select(this.#imgElement)
                .classed("hide", !fileC._Thumbnail);

            if (fileC._IsValid) {
                // Vista de archivo válido
                let fileico = this.#controlContent.select("wc-fileico")
                    .classed("hide", (fileC._Thumbnail != null));

                this.UI_UpdateTopTag(fileC._Extension, false);

                if (fileC._Thumbnail) {
                    this.#imgElement.onload = (e) => {
                        URL.revokeObjectURL((e.target as HTMLImageElement).src);
                        this.#imgElement.onload = null;
                    }
                    this.#imgElement.src = URL.createObjectURL(fileC._Thumbnail);
                }
                else {
                    if (!fileico.node()) {
                        fileico = this.#controlContent
                            .append("wc-fileico")
                            .style("height", "100%")
                            .style("width", "80%");
                    }

                    fileico.attr("ext", fileC._Extension?.toUpperCase().replace(".", "") || "?");

                    if (this.#auxExtensionFake && (this.#auxExtensionFake != fileC._Extension)) {
                        console.warn("-d", "Extensión auxiliar y real difieren", this.#auxExtensionFake, fileC._Extension);
                    }

                    this.#imgElement.removeAttribute("src");
                }
            }
            else {
                // Vista de archivo no válido
                let fileico = this.#controlContent.select("wc-fileico")
                    .classed("hide", (!this.#auxExtensionFake || Boolean(fileC._Thumbnail)));

                this.#imgElement.removeAttribute("src");

                this.UI_UpdateTopTag(this.#auxExtensionFake, false);

                if (fileC._Thumbnail) {
                    this.#imgElement.onload = (e) => {
                        URL.revokeObjectURL(this.#imgElement.src);
                        this.#imgElement.onload = null;
                    }
                    this.#imgElement.src = URL.createObjectURL(fileC._Thumbnail);
                }
                else if (this.#auxExtensionFake) {
                    if (!fileico.node()) {
                        fileico = this.#controlContent
                            .append("wc-fileico")
                            .style("height", "100%")
                            .style("width", "80%");
                    }

                    fileico.attr("ext", this.#auxExtensionFake?.toUpperCase().replace(".", "") || "?");
                }
                else if (config.DefaultBackgroundImage) {
                    this.#controlContent
                        .style("background-image", `url("${config.DefaultBackgroundImage}"`);
                }

                if (config.DefaultBackgroundText) {
                    this.#controlContent.append("div")
                        .classed("tag_text_back", true)
                        .append("label")
                        .text(config.DefaultBackgroundText);
                }
            }
        }

        private UI_UpdateTopTag(tag, error: boolean) {
            const fileC = this.#fileController;
            const config = this.#config;
            let topTag: string;

            if (tag && config.ShowExtensionTag && fileC._Thumbnail) { //(fileC.prop_IsImage || fileC.prop_IsVideo)) {
                topTag = tag.toUpperCase();
            }
            else if (error && config.ShowErrorTag) {
                topTag = "ERROR";
            }
            let contExtTag = this.#controlContainer
                .select<HTMLDivElement>(".tag_ext");

            if (!contExtTag.node()) {
                contExtTag = this.#controlContainer.append("div")
                    .classed("tag_ext", true);
            }

            contExtTag
                .classed("hide", !topTag)
                .classed("tag_ext_error", error)
                .text(topTag);
        }

        private UI_UpdateOnClickImageArea() {
            const imageAreaNode = this.#controlContent
                .style("cursor", (this.#config.OnClickImageArea ? "pointer" : null))
                .node();

            imageAreaNode.removeEventListener("click", this.UI_OnClickImageArea);

            if (this.#config.OnClickImageArea) {
                imageAreaNode["_fnOnClickImageArea"] = this.#config.OnClickImageArea;
                imageAreaNode.addEventListener("click", this.UI_OnClickImageArea);
            }
        }

        private UI_OnClickImageArea(e: MouseEvent) {
            e.stopPropagation();
            e.currentTarget["_fnOnClickImageArea"]();
        }

        private UI_UpdateOptionsArea() {
            if (this.#timeoutUpdate) {
                clearTimeout(this.#timeoutUpdate);
            }

            this.#timeoutUpdate = setTimeout(() => {
                this.#timeoutUpdate = null;

                let options: IOption[] = [
                    {
                        ID: "btn_loadfile",
                        // Label: "Cargar archivo",
                        Icon: CDefaultSrc.Folder,
                        OnClick: async () => {
                            if (!this.#config.Disabled && !this.#isLoadingFile) {
                                InputFileDialog._Open({
                                    MaxFiles: 1,
                                    AcceptExtensions: this.#config.FilesAccept,
                                    OnStartProcess: () => {
                                        this.#ctrlSpinner.classed("hide", false);
                                    },
                                    OnError: () => {
                                        this.#ctrlSpinner.classed("hide", true);
                                        NotificacionV2._Mostrar(_L("general.notif_fail_fileprocessed"), "ADVERTENCIA");
                                    },
                                    OnLoad: ([file]) => {
                                        this.#ctrlSpinner.classed("hide", true);
                                        this.ReadFile(file, true);
                                    }
                                })
                            }
                        },
                        Visible: this.#config.ShowBtnLoadFile,
                    },
                    {
                        ID: "btn_downloadfile",
                        // Label: "Descargar archivo",
                        Icon: CDefaultSrc.Download,
                        OnClick: () => this.DATA_DownloadCurrentFile(),
                        Visible: this.#config.ShowBtnDownloadFile,
                    },
                    // >> Add Extra Options
                    ...(this.#config.OptionsExtras || [])
                ];

                const showOptnsArea = this.#config.ShowAreaControlOptions ? Boolean(options.find(d => d.Visible)) : false;

                let areaOptions = this.#controlContent.select<HTMLDivElement>(".area_options");

                if (showOptnsArea) {
                    if (!areaOptions.node()) {
                        areaOptions = this.#controlContent.append("div")
                            .classed("area_options", true);
                    }

                    areaOptions
                        .selectAll<HTMLDivElement, IOptionAdvanced>(".option")
                        .data(options)
                        .join(
                            enter => {
                                let itemEnter = enter
                                    .append("div")
                                    .classed("option", true)

                                itemEnter.append("img")
                                    .attr("draggable", false)
                                    .classed("hide", d => !d.Icon)
                                    .attr("src", d => d.Icon);

                                itemEnter.each((d: IOptionAdvanced, i, arr) => {
                                    let item = d3.select<HTMLDivElement, IOptionAdvanced>(arr[i]);
                                    d.ViewContent = item;

                                    if (d.OnUpdateViewContent) {
                                        d.OnUpdateViewContent(item);
                                    }
                                })

                                itemEnter
                                    .classed("hide", d => !d.Visible)
                                    .on("click", async (d, i, arr) => {
                                        Button._EnableButton(d3.select(arr[i]), false);
                                        await d.OnClick();
                                        Button._EnableButton(d3.select(arr[i]), true);
                                    });

                                return itemEnter;
                            },

                            update => update
                                .each((d: IOptionAdvanced, i, arr) => {
                                    let item = d3.select<HTMLDivElement, IOption>(arr[i]);
                                    d.ViewContent = item;

                                    item.select("img")
                                        .classed("hide", d => !d.Icon)
                                        .attr("src", d.Icon);

                                    if (d.OnUpdateViewContent) {
                                        d.OnUpdateViewContent(item);
                                    }
                                })
                                .classed("hide", d => !d.Visible)
                                .on("click", async (d, i, arr) => {
                                    Button._EnableButton(d3.select(arr[i]), false);
                                    await d.OnClick();
                                    Button._EnableButton(d3.select(arr[i]), true);
                                }),

                            exit => exit.remove()
                        )
                }
                else if (areaOptions.node()) {
                    areaOptions.remove();
                }
            }, 100);
        }

        // **********************************************************************************
        // LOAD AND READ FILE THINGS
        // **********************************************************************************

        private Reset() {
            this.#auxExtensionFake = null;
            this.#urlResource = null;
            this.#fileController
                // .met_OnValidateFileAfterReading(null)
                // .met_OnReadStart(null)
                // .met_OnReadEndSuccessfull(null)
                // .met_OnReadEndFail(null)
                ._Reset();

            this.UI_UpdateView();
            this.UI_UpdateTopTag(null, false);
        }

        private ReadFile(newFile: (File | null), isFromInput: boolean) {
            let isImage = Boolean(newFile?.type.startsWith("image"));
            const config = this.#config;
            const fileC = this.#fileController;

            fileC
                ._OnValidateFileAfterReading(async (file, base64) => {
                    if (config.OnValideBeforeRender) {
                        return config.OnValideBeforeRender(
                            file.type.startsWith("image"),
                            isFromInput,
                            file,
                            base64
                        );
                    }
                    return true;
                })
                ._OnReadStart(() => {
                    this.#isLoadingFile = true;
                    this.#ctrlSpinner.classed("hide", false);
                })
                ._OnReadEndSuccessfull(async () => {
                    this.#isLoadingFile = false;
                    this.#ctrlSpinner.classed("hide", true);

                    // >> On change
                    if (config.OnChange) {
                        config.OnChange(
                            isImage,
                            isFromInput,
                            newFile,
                            fileC._FileAsBase64
                        );
                    }

                    if (config.OnEndLoadFileStatus) {
                        config.OnEndLoadFileStatus(isImage, isFromInput, true);
                    }

                    // if (config.ShowExtensionTag && (fileC.prop_IsImage || fileC.prop_IsVideo)) {
                    //     this.UI_UpdateTopTagX(fileC.prop_Extension, false);
                    // } else {
                    //     this.UI_UpdateTopTagX(null, false);
                    // }

                    this.UI_UpdateView();
                })
                ._OnReadEndFail((type, file, extension) => {
                    this.#isLoadingFile = false;
                    this.#ctrlSpinner.classed("hide", true);

                    if (config.OnEndLoadFileStatus) {
                        config.OnEndLoadFileStatus(isImage, isFromInput, false);
                    }

                    if (type == "error-onreading") {
                        // >> Error al leer el archivo
                        fileC._Reset();

                        this.UI_UpdateTopTag(extension, true);
                    }
                    else if (newFile == null && type == "invalid-beforeread" && config.ShowErrorTag) { // isFromInput
                        this.UI_UpdateTopTag(null, true);
                    }
                    else {
                        this.UI_UpdateTopTag(null, false);
                    }

                    this.UI_UpdateView();
                })
                ._SetFile(newFile);
        }

        private DATA_LoadAndReadResourceFileFromUri(value: string) {
            this.#ctrlSpinner.classed("hide", false);
            this.#isLoadingFile = true;

            DataDRequest._RequestBlobFromUrlResourceV2(value, (blob, error) => {
                let newFile: File;

                if (error == null) {
                    if (blob) {
                        newFile = new File([blob], this.DATA_GetFullFileName(blob), { "type": blob.type });
                    } else {
                        console.warn("-d", this.#prefix_test, "Resource fail load 2, LoadAndReadResourceFileFromUri");
                    }
                } else {
                    console.warn("-d", this.#prefix_test, "Resource fail load 1, LoadAndReadResourceFileFromUri");
                }

                this.ReadFile(newFile, false);
            })
        }

        private DATA_GetFullFileName(blob: Blob): string {
            if (blob) {
                let extension = "." + UIUtilMimeType._GetExtensionFromMimeType(blob.type);
                let filename = this.#config.OnGetFileName();

                if (filename.toLowerCase().endsWith(extension)) {
                    return filename;
                } else {
                    return (filename + extension);
                }
            }
            return this.#config.OnGetFileName();
        }

        private async DATA_DownloadCurrentFile(save = true) {
            let fileBlob: File;

            this.#ctrlSpinner.classed("hide", false);

            if (this.#config.OnDownload_GetRealResource) {
                let resFile = this.#config.OnDownload_GetRealResource();
                if (typeof resFile == "string") {
                    await new Promise<void>(resolve => {
                        DataDRequest._RequestBlobFromUrlResourceV2(resFile as string, (blob, error) => {
                            if (error == null && blob) {
                                fileBlob = new File([blob], this.DATA_GetFullFileName(blob), { "type": blob.type });
                            }
                            resolve();
                        })
                    })
                }
                else if (resFile instanceof Promise) {
                    await resFile
                        .then(res => {
                            fileBlob = res;
                        })
                        .catch(e => {
                            console.warn("Error to get file");
                        })
                }
                else if (resFile instanceof File) {
                    fileBlob = resFile;
                }
            } else {
                fileBlob = this.#fileController._File;
            }

            if (save) {
                if (fileBlob) {
                    saveAs(fileBlob, fileBlob.name);
                } else {
                    let error = new Error();
                    error.name = this.#prefix_test;
                    error.message = "filenofound";

                    if (this.#config.OnError) {
                        this.#config.OnError("ondownload", error);
                    }
                    console.warn("-d", error);
                    NotificacionV2._Mostrar(UIUtilLang._GetUIString("general", "notif_fail"), "ADVERTENCIA");
                }
            }

            this.#ctrlSpinner.classed("hide", true);
            return fileBlob;
        }

        // **********************************************************************************
        // PUBLIC PROPERTIES
        // **********************************************************************************

        get _ControlSelection() {
            return this.#controlContainer;
        }

        get _ControlNode(): (HTMLDivElement | null) {
            return this.#controlContainer.node();
        }

        get _ImgSelection(): d3.Selection<HTMLImageElement, any, HTMLElement, any> {
            return d3.select(this.#imgElement);
        }

        /** Contenedor Top-Right donde normalmente se pone el Tag de la extensión del video o imagen, o el Tag "Error" */
        get _TopTagSelection() {
            return this.#controlContainer
                .select(".tag_ext");
        }

        set _Dimentions(dims: number | string) {
            this.#config.Dimentions = dims;
            this.UI_UpdateDimentions();
        }

        /** // NOTE No representa al archivo real
         * @deprecated */
        get _SrcFromImg() {
            return this.#imgElement.src;
        }

        get _Base64() {
            // return this.#imgElement.src?.split(",")[1];
            return this.#fileController._FileAsBase64?.split(",").pop();
        }

        set _UrlResource(value: string) {
            this.#urlResource = value;

            if (Boolean(value)) {
                this.DATA_LoadAndReadResourceFileFromUri(value);
            } else {
                this.Reset();
            }
        }

        get _UrlResource(): string {
            return this.#urlResource;
        }

        get _File() {
            return this.#fileController._File;
        }

        set _File(file: File) {
            this.ReadFile(file, false);
        }

        set _Border(value: IBorder) {
            this.#config.Border = value;
            this.#controlContent.node().style.borderWidth = value?.Width ? value.Width + "px" : "0";
            this.#controlContent.node().style.borderColor = value?.Color;
            // this.#ctrlSpinner.prop_Dimentions = "max-parentreference"; // this.UI_GetDimentionInPx(); // - (this.#config.Border.Width * 2);
        }

        get _Border(): IBorder {
            return this.#config.Border;
        }

        set _Disabled(value: boolean) {
            this.#config.Disabled = value;
            // this.#containerSel.style("cursor", value ? "not-allowed" : null)
        }

        get _Disabled(): boolean {
            return this.#config.Disabled;
        }

        get _IsImage(): boolean {
            return this.#fileController._IsImage;
        }

        get _IsVideo(): boolean {
            return this.#fileController._IsVideo;
        }

        get _IsValidFile(): boolean {
            return this.#fileController._IsValid;
        }

        // set prop_IsValidFile(value: boolean) { // FIXME Implementación de Circulares
        //     this.#isValidFileGeneral = value;
        // }

        /** Su valor, define la imagen de fondo que se muestra en caso de no poder mostrar el recurso (en caso de archivos diferentes de imagen o video) */
        set _AuxExtensionFile(value: string) {
            this.#auxExtensionFake = value?.toLowerCase()?.replace(".", "");

            if (value) {
                // this.UI_UpdateTopTagX(this.#auxExtensionFake, false);
                this.UI_UpdateView();
            } else {
                console.error("-d", "Extensión no valida -> ", value);
            }
        }

        get _AuxExtensionFile() {
            return this.#auxExtensionFake;
        }

        get _DefaultBackgroundText(): string {
            return this.#config.DefaultBackgroundText;
        }

        set _DefaultBackgroundText(value: string) {
            this.#config.DefaultBackgroundText = value;
        }

        set _SpinnerLoadVisible(value: boolean) {
            this.#ctrlSpinner.classed("hide", !value);
        }

        get _SpinnerLoadVisible(): boolean {
            return this.#ctrlSpinner.classed("hide");
        }

        set _SpinnerColor(valor: string) {
            this.#config.SpinnerColor = valor;
            this.#ctrlSpinner.style("color", valor);
        }

        set _SpinnerWidth(valor: number) {
            this.#config.SpinnerWidth = valor;
            this.#ctrlSpinner.node()._BorderWidth = valor;
        }

        get _SpinnerControl() {
            return this.#ctrlSpinner;
        }

        get _Thumbnail(): File {
            return this.#fileController._Thumbnail;
        }

        set _Thumbnail(file: File) {
            this.#fileController._SetThumbnail(file);
            this.UI_UpdateView();
        }

        /** DEFAULT Control Options: "btn_loadfile", "btn_downloadfile" */
        public _OptionsSelect(id: (number | string)): (IOptionSelection | null) {
            let opcSelected: IOptionAdvanced = this.#config.OptionsExtras?.find(d => (d.ID == id));

            if (opcSelected) {
                const selection: IOptionSelection = {
                    Visible: (value) => {
                        opcSelected.Visible = value;
                        this.UI_UpdateOptionsArea();
                        return selection;
                    },
                    OnGetViewContent: () => opcSelected.ViewContent//(typeof opcSelected.ViewContent == "string" ? null : opcSelected.ViewContent as any)
                }

                return selection;
            } else {
                return null;
            }
        }

        public _OnClickImageArea(event: () => void) {
            this.#config.OnClickImageArea = event;
            this.UI_UpdateOnClickImageArea();
            return this;
        }

        public _OnDownload_GetRealresource(fn: () => string | Promise<File>) {
            this.#config.OnDownload_GetRealResource = fn;
            return this;
        }

        public _OnGetFileName(fn: () => string) {
            this.#config.OnGetFileName = fn;
            return this;
        }

        public _AreaOptionsVisible(value: boolean) {
            this.#config.ShowAreaControlOptions = value;
            this.UI_UpdateOptionsArea();
            return this;
        }

        public _BtnLoadFileVisible(value: boolean) {
            this.#config.ShowBtnLoadFile = value;
            this.UI_UpdateOptionsArea();
            return this;
        }

        public _ChangeForm(form: keyof typeof ControlForm) {
            const container = this.#controlContainer
                .select(".foto_control");

            for (let o in ControlForm) {
                container.classed(ControlForm[o], false);
            }
            container.classed(ControlForm[form], true);
            return this;
        }

        public _BtnDownloadFileVisible(value: boolean) {
            this.#config.ShowBtnDownloadFile = value;
            this.UI_UpdateOptionsArea();
            return this;
        }

        public _Download() {
            this.DATA_DownloadCurrentFile();
        }

        public _GetDownloaded() {
            return this.DATA_DownloadCurrentFile(false);
        }

        public _Reset() {
            this.Reset();
            return this;
        }
    }
}
