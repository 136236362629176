import * as d3 from "d3";
import { UIUtilIconResources } from "../util/IconResourses";
import { CardV2 } from "./CardV2";
import { InputFileControl } from "./InputFileControlV2";

type TUpdateImageCallback = ((src64: string, file: File) => Promise<boolean>);
interface IImageConfig extends InputFileControl.IConfig {
    UpdateImageCallbackService?: TUpdateImageCallback;
}
interface IItemSeccion {
    ID: string;
    Title?: string;
    GetTemplateContent?: (section: TSelectionHTML<"htmlelement">) => void;
    OnUpdate: (section: TSelectionHTML<"div">) => void;
}

interface IItemInfoGeneral {
    TopicTag?: string;
    Description?: string | ((elemento: TSelectionHTML<"htmlelement">) => void);
}

export class CardInfoV2 extends CardV2 {
    private cardContentInfoPrincipalSelection: TSelectionHTML<"div">;
    private cardContentInfoPrincipalDescripContSelection: TSelectionHTML<"div">;
    private cardContentSeccionesSelection: TSelectionHTML<"div">;
    protected ctrlImage: InputFileControl.InputFile;

    constructor() {
        super();
        this.InitCardInfo();
    }

    private InitCardInfo() {
        this.cardSelection
            .classed("UI_CardInfo", true);

        this.cardContentInfoPrincipalSelection = this.cardContentContainerSelection.append("div")
            .classed("resumen_gral", true);

        this.cardContentInfoPrincipalDescripContSelection = this.cardContentInfoPrincipalSelection.append("div")
            .classed("descripcion_gral", true);

        this.cardContentSeccionesSelection = this.cardContentContainerSelection.append("div")
            .classed("secciones", true);

        this.cardContentInfoPrincipalSelection.append("wc-tooltip")
            .attr("position", "right")
            .attr("max-width", "300px")

        this.cardSelection.append("img")
            .attr("class", "btn_collapser")
            .attr("src", UIUtilIconResources.CGeneral.AngleDown)
            .attr("draggable", false)
            .on("click", () => {
                this.cardSelection.classed("collapsed", !this.cardSelection.classed("collapsed"));
            });

        this.cardSelection.append("img")
            .attr("class", "btn_returninfogral")
            .attr("src", UIUtilIconResources.CGeneral.AngleDown)
            .attr("draggable", false)
            .on("click", () => {
                this.cardContentContainerSelection.node().scrollTo({
                    top: 0,
                    behavior: "smooth"
                })
            });
    }

    private timeoutUpdateTooltip: NodeJS.Timeout;
    private UpdateTooltipResumenGral() {
        if (this.timeoutUpdateTooltip) {
            clearTimeout(this.timeoutUpdateTooltip);
            this.timeoutUpdateTooltip = null;
        }
        this.timeoutUpdateTooltip = setTimeout(() => {
            this.timeoutUpdateTooltip = null;
            const tooltip = this.cardContentInfoPrincipalSelection.select("wc-tooltip")
            tooltip.selectAll(":scope>*").remove();
            tooltip.append(() => this.cardContentInfoPrincipalSelection.select(".descripcion_gral").clone(true).node())
                .style("border-bottom", "1px solid var(--color_borderbox1)")
                .style("padding-bottom", "var(--padding2)");
            tooltip.append(() => this.cardContentSeccionesSelection.clone(true).node());
            tooltip.selectAll("a").style("color", "var(--color_primary1)", "important");
            tooltip.select(".section_hermanos").selectAll("label").style("color", "var(--color_primary1)", "important");
        }, 100);
    }

    public _RefeshTooltipResumenGral() {
        if (this["__timeOutTooltip"]) {
            clearTimeout(this["__timeOutToolTip"]);
            this["__timeOutToolTip"] = null;
        }
        this["__timeOutToolTip"] = setTimeout(() => {
            this["__timeOutToolTip"] = null;
            this.UpdateTooltipResumenGral();
        }, 100)
        return this;
    }

    public _MainInfo_CreateImageControl(config: IImageConfig = {}): this {
        this.ctrlImage = new InputFileControl.InputFile({
            ...{
                DefaultBackgroundImage: UIUtilIconResources.CMenuIco.Escuela,
                OnValideBeforeRender: async (isImage, isFromInput, file, src) => {
                    let src64 = src.split(",")[1];
                    if (isImage && isFromInput && config.UpdateImageCallbackService) {
                        return await config.UpdateImageCallbackService(src64, file);
                    }
                    return true;
                },
                OnChange: (isImage, isFromInput, file, src) => {
                    // console.log(src, isImage)
                },
                OnEndLoadFileStatus: (isImage, isFromInput, isSuccesful) => {
                    // console.log(isSuccesful, this.photoIsUpdating, "OnImgEndLoad")
                    // if (isSuccesful && this.photoIsUpdating) {
                    //     this.ControlImage.prop_SpinnerLoadVisible = true;
                    // }
                    // this.photoIsUpdating = false;
                },
                NameTest: "Logo CardInfo",
                Border: {
                    Width: 5,
                    Color: null
                }
            },
            ...config
        });
        this.cardContentInfoPrincipalSelection.node()
            .insertAdjacentElement("afterbegin", this.ctrlImage._ControlNode);
        return this;
    }

    public _MainInfo_SetURLResourceToImageControl(newUrlResourse: string): this {
        if (this.ctrlImage && this.ctrlImage._UrlResource != newUrlResourse) {
            if (newUrlResourse) {
                this.ctrlImage._Reset();
            }
            this.ctrlImage._UrlResource = newUrlResourse;
        }
        return this;
    }

    public _MainInfo_ImageControlDisable(disable: boolean) {
        if (this.ctrlImage) {
            this.ctrlImage._AreaOptionsVisible(!disable)
        }
        return this;
    }

    public _MainInfo_SetColors(spinner: string, border: string) {
        if (this.ctrlImage) {
            this.ctrlImage._SpinnerColor = spinner;
            this.ctrlImage._Border = {
                ...this.ctrlImage._Border,
                Color: border
            }
        }
        return this;
    }

    public _MainInfo_SetDescripcionGeneralPrimaryInfo(text: string): this {
        let lblCabecera = this.cardContentInfoPrincipalDescripContSelection.select<HTMLElement>(":scope > .cabecera");
        if (!lblCabecera.node()) {
            lblCabecera = d3.create("b")
                .attr("class", "cabecera");
            this.cardContentInfoPrincipalDescripContSelection
                .node()
                .insertAdjacentElement("afterbegin", lblCabecera.node());
        }
        lblCabecera.text(text);
        this.UpdateTooltipResumenGral();
        return this;
    }

    public _MainInfo_SetDescripcionGeneralSecondaryInfo(items: IItemInfoGeneral[]): this {
        function UpdateItem(item: TSelectionHTML<"label", IItemInfoGeneral>) {
            return item
                .each((d, i, elements) => {
                    let topicTagElement = elements[i].querySelector("b");
                    let descriptionElement = elements[i].querySelector("span");

                    if (d.TopicTag && !topicTagElement) {
                        topicTagElement = document.createElement("b");
                        elements[i].insertAdjacentElement("afterbegin", topicTagElement);
                    }
                    else if (!d.TopicTag && topicTagElement) {
                        topicTagElement.remove();
                        topicTagElement = null;
                    }
                    if (topicTagElement) {
                        topicTagElement.textContent = d.TopicTag;
                        if (d.Description) {
                            topicTagElement.textContent += ": "
                        }
                    }

                    if (d.Description && !descriptionElement) {
                        descriptionElement = document.createElement("span");
                        elements[i].insertAdjacentElement("beforeend", descriptionElement);
                    }
                    else if (!d.Description && descriptionElement) {
                        descriptionElement.remove();
                        descriptionElement = null;
                    }
                    if (descriptionElement) {
                        descriptionElement.textContent = "";
                        if (typeof (d.Description) === "string") {
                            descriptionElement.textContent = d.Description;
                        } else {
                            d.Description(d3.select(descriptionElement));
                        }
                    }
                });
        }
        this.cardContentInfoPrincipalDescripContSelection
            .selectAll<HTMLLabelElement, IItemInfoGeneral>(":scope > .lbl_info")
            .data(items, (d, i) => i.toString())
            .join(
                enter => UpdateItem(
                    enter.append("label")
                        .attr("class", "lbl_info")
                ),
                update => UpdateItem(update),
                exit => exit.remove()
            )
        this.UpdateTooltipResumenGral();
        return this;
    }

    public _SetSections(secciones: IItemSeccion[]): this {
        this.cardContentSeccionesSelection
            .selectAll<HTMLDivElement, IItemSeccion>(":scope > .item_seccion")
            .data(secciones, d => d.ID)
            .join(
                enter => {
                    const itemEnter = enter.append("div")
                        .attr("class", "item_seccion");
                    itemEnter
                        .append("label")
                        .attr("class", "tittle")
                        .classed("hide", d => (d.Title === undefined))
                    itemEnter
                        .each((d, i, elements) => {
                            const seccion = d3.select(elements[i]);
                            d.GetTemplateContent(seccion);
                            d.OnUpdate(seccion);
                        })
                    return itemEnter;
                },
                update => {
                    update.select<HTMLLabelElement>(":scope > .tittle")
                        .attr("class", "tittle")
                        .classed("hide", d => (d.Title === undefined));
                    update
                        .each((d, i, elements) => {
                            const seccion = d3.select(elements[i]);
                            d.OnUpdate(seccion);
                        })
                    return update;
                },
                exit => exit.remove(),
            );
        //setTimeout(this.UpdateTooltipResumenGral.bind(this), 200);
        this.UpdateTooltipResumenGral();
        return this;
    }

    public _SetTitulo(titulo: (string | null)) {
        super._SetTitulo(titulo)
            .cardHeaderSelection
            .classed("hide", !titulo);
        return this;
    }
    // /** set - seccion RESUMEN*/
    // set prop_borderFotoColor(value: string) {
    //     this.resumen.img.BorderColor = value;
    //     if (this.builded)
    //         this.controlImage.prop_Border = {
    //             Width: this.controlImage.prop_Border.Width,
    //             Color: value
    //         }
    //     // this.resumen.img.D3Selection.style("border-width", "5px").style("border-color", this.resumen.img.BorderColor);
    // }
    // set prop_spinnerFotoColor(value: string) {
    //     if (this.builded) {
    //         this.controlImage.prop_SpinnerColor = value;
    //     }
    // }
    // /** set - seccion RESUMEN*/
    // set prop_ImgURL(value: string) {
    //     this.ValidaImagen(value).then((img: Blob) => {
    //         if (img.size > 0) {
    //             this.resumen.img.URL = value;
    //         }
    //     })
    // }
    // /** set cabecera al lado de la foto - seccion RESUMEN*/
    // set prop_encabezado(value: string) {
    //     this.resumen.Descripcion.Encabezado.Title = value;
    //     if (this.builded) {
    //         this.resumen.Descripcion.Encabezado.D3Selection.text(value) // FIXME ¿SIRVE DE ALGO?
    //     }
    // }
    // /** set - Items de informadcion del usuario - seccion RESUMEN */
    // set prop_Descripcion(value: Array<string | HTMLElement>) {
    //     this.resumen.Descripcion.Items = value;
    //     if (this.builded) {
    //         this.resumen.Descripcion.D3Selection.selectAll(".descipcion_item").remove();
    //         this.resumen.Descripcion.Items.forEach(e => {
    //             if (typeof e == "string") {
    //                 this.resumen.Descripcion.D3Selection.append("label")//item - descipcion
    //                     .classed("descipcion_item", true)
    //                     .text(e)
    //             } else if (e instanceof HTMLElement)
    //                 this.resumen.Descripcion.D3Selection.append(() => e);
    //         })
    //     }
    // }
    // /** set */
    // set prop_Seccions(value: ICardInfoSeccion[]) {
    //     let elem: d3.Selection<HTMLDivElement, {}, HTMLElement, any> = null;
    //     this.secciones.D3Selection.selectAll(".item_seccion").remove();
    //     this.secciones.Items = [];
    //     for (let sec of value) {
    //         if (this.builded) {
    //             elem = this.secciones.D3Selection.append("div")
    //                 .classed("item_seccion", true);
    //             elem.append("label")
    //                 .classed("tittle", true)
    //                 .text(sec.Title);
    //             if (typeof sec.Content == "string") elem.append("label").text(sec.Content);
    //             else elem.append(() => <HTMLElement>sec.Content);
    //         }
    //         this.secciones.Items.push({ D3Selection: elem, ...sec });
    //     }
    // }

    // get prop_SeccionsV2() {
    //     return this.secciones.Items;
    // }
    // /** get */
    // get prop_Card() {
    //     return this.card;
    // }
    // /** get */
    // get prop_Node() {
    //     return this.card.prop_node
    // }

    // protected CARDINFO_HeaderVisible(visible: boolean) {
    //     this.card.prop_header.d3Selection.classed("hide", !visible);
    // }

    // protected CARDINFO_UpdateTitulo(title: string) {
    //     this.card.met_UpdateTitulo(title);
    // }

    // get prop_SeccDescripcion() {
    //     return this.resumen.D3Selection
    // }
    // //#endregion // REMOVER?
    // public met_Build(parent: HTMLElement): controlD3.Card {
    //     this.card.met_Build(parent);
    //     this.card.prop_d3Selection.classed("UI_CardInfo", true);
    //     this.card.prop_header.Collapser.remove();
    //     //hacer algo antes de contruir...
    //     this.builded = true;

    //     this.InitComponents();

    //     if (this.ctrlImage) {
    //         this.prop_borderFotoColor = this.resumen.img.BorderColor;
    //     }
    //     this.prop_encabezado = this.resumen.Descripcion.Encabezado.Title;
    //     this.prop_Descripcion = this.resumen.Descripcion.Items;
    //     this.prop_Seccions = this.secciones.Items;

    //     this.prop_Seccions = this.CARDINFO_Build();

    //     return this.card;
    // }

    // protected abstract CARDINFO_Build(): ICardInfoSeccion[];

    // protected abstract CARDINFO_UpdateCard(dataCard: TData): void;

    // protected abstract CARDINFO_UpdateFotoToService(src64: string, file: File): Promise<boolean>;

    // /** Se invoca al llamar a CARDINFO_UpdateCard */
    // protected abstract CARDCOLL_GetUrlToUpdateFoto(dataCard: TData): string;

    /** inicializa cascaron 👇 */
    // private InitComponents() {
    //     this.resumen.D3Selection = d3.create("div").classed("resumen_gral", true);

    //     this.notificacion = new NotificacionV2();

    //     if (this.ctrlImage) {
    //         this.resumen.D3Selection.append(() => this.ctrlImage.prop_ControlNode);
    //         this.resumen.img.D3Selection = this.ctrlImage.prop_ControlSelection;
    //     }

    //     this.resumen.Descripcion.D3Selection = //this.resumen.D3Selection
    //         this.resumen.D3Selection
    //             .append("div")
    //             .classed("descripcion_gral", true);

    //     this.resumen.Descripcion.Encabezado.D3Selection = this.resumen.Descripcion.D3Selection
    //         .append("label")//titulo
    //         .classed("cabecera font_bold font_black", true)

    //     this.secciones.D3Selection = d3.create("div").classed("secciones", true);

    //     this.card.met_Append(this.resumen.D3Selection.node());
    //     this.card.met_Append(this.secciones.D3Selection.node());
    // }

    /** solo se usa para validar si la imagen tiene bytes */ //algunos ca
    // private async ValidaImagen(url: string): Promise<Blob> {//si tiene contenido
    //     const response = await fetch(url);
    //     return await response.blob();
    // }

    // private UpdateFoto(src64: string, file: File): Promise<boolean> {
    //     return this.CARDINFO_UpdateFotoToService(src64, file);
    // }

    // public met_UpdateData(dataCard: TData) {
    //     this.currentData = dataCard;
    //     this.CARDINFO_UpdateCard(dataCard);

    //     if (this.ctrlImage) {
    //         let newUrlResourse = this.CARDCOLL_GetUrlToUpdateFoto(dataCard)
    //         if (this.ctrlImage.prop_UrlResource != newUrlResourse) {
    //             if (newUrlResourse) {
    //                 this.ctrlImage.met_Reset();
    //             }
    //             this.ctrlImage.prop_UrlResource = newUrlResourse;
    //         }
    //     }
    // }

    // public met_Destroy() {
    //     super.met_Destroy();
    //     return this;
    // }
}
