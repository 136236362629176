import * as d3 from "d3";
import { group as d3Group } from "d3-array";
import { MainPage } from "../../MainPage";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import DataModuloEgresoMovimiento from "../../data/modulo/EgresoMovimiento";
import DataModuloProveedor from "../../data/modulo/Proveedor";
import { ArrayV2 } from "../../util/ArrayV2";
import { DateV2 } from "../../util/DateV2";
import { Button } from "../controlD3/Button";
import { ElementWrapper } from "../controlD3/ElementWrapper";
import { FormGenerator } from "../controlD3/Formulario";
import { InputFileDialog } from "../controlD3/InputFileDialog";
import { ModalThings } from "../controlD3/ModalThings";
import { NotificacionV2 } from "../controlD3/NotificacionV2";
import { SelectV2 } from "../controlD3/SelectV2";
import { Table } from "../controlD3/Tabla";
import { UIUtilCFDI } from "../util/CFDI";
import { UIUtilFormat } from "../util/Format";
import { UIUtilLang } from "../util/Language";
import { UIUtilTime } from "../util/Time";
import { UIUtilGeneral } from "../util/Util";
import { UIUtilViewData } from "../util/ViewData";
import { UIUtilViewFinanzaEgresoCategoria } from "./FinanzaEgresoCategoria";
import { UIUtilViewFinanzaEgresoPago } from "./FinanzasEgresoPago";
import { UIUtilViewFinanzaEgresoUtil } from "./FinanzasEgresoUtil";
import { _HttpMsgV2 } from "../../util/Labels";

export namespace UIUtilViewFinanzaEgresoCargaXMLMasivo {
    import CModulo = Entidad.CModulo;
    import IEscuela = Entidad.IEscuela;
    import IProveedor = Entidad.IFinanzaProveedor;

    const _CurrencyFmt = UIUtilFormat._CurrencyFmt;
    const { _GetUIString: LangString } = UIUtilLang;
    export const _EGRESO_LANGKEY = "finanzasegresosmovimientos";

    function OpenModal_EditarDatosPago(pagoDatos: UIUtilViewFinanzaEgresoPago.IPagarFormParamsBase & { IdCuenta: number, FechaPago: string }): Promise<UIUtilViewFinanzaEgresoPago.IPagarDataForm> {
        const { IdEscuela } = pagoDatos;
        let form: FormGenerator<UIUtilViewFinanzaEgresoPago.IPagarDataForm>;
        return new Promise<UIUtilViewFinanzaEgresoPago.IPagarDataForm>(resolve => {
            ModalThings._GetModalToForm<UIUtilViewFinanzaEgresoPago.IPagarDataForm>({
                LangModuleKeyInContext: _EGRESO_LANGKEY,
                Title: "tag_pagar_pendiente",
                Width: 400,
                OnClose: () => resolve({
                    Cantidad: form._Data.Cantidad,
                    IdCuenta: form._Data.IdCuenta,
                    FechaPago: new DateV2(UIUtilTime._GetLocalDateFromInputDateString(form._Data.FechaPago))
                        ._SetTimeZoneByIdSchool(IdEscuela, true)
                        ._ToISOString()
                }),
                OnAccept: (form, mt) => {
                    mt.Modal._Ocultar();
                    return null;
                },
                DrawContent: async (container, form, mt) => {
                    mt.Progress.node()._Oculto = false;
                    await MainPage._ReloadServiceAndAwaitBool(Entidad.CTipoRequest.FinanzaMetodoPago, IdEscuela);
                    await MainPage._ReloadServiceAndAwaitBool(Entidad.CTipoRequest.CuentaBancaria, IdEscuela);
                    mt.Progress.node()._Oculto = true;
                },
                GetForm: (container, mt) => {
                    form = UIUtilViewFinanzaEgresoPago._GetFormularioPago(pagoDatos);
                    form._ControlsData.get("Cantidad").row.classed("hide", true);
                    form._AsignaData({
                        Cantidad: pagoDatos.CantidadPago,
                        IdCuenta: pagoDatos.IdCuenta,
                        FechaPago: pagoDatos.FechaPago ? UIUtilTime._FmtToInputDate(pagoDatos.FechaPago, IdEscuela) : null,
                    })
                    return form;
                }
            })
        })
    }

    interface IAgregarEgresosXMLConfig {
        Modulo: CModulo.FinanzasEgresosMovimientos | CModulo.FinanzasEgresosPagosPendientes,
        Escuelas: IEscuela[];
        OnEnd: (egresosAgregados: boolean) => void;
    }
    // Crear egresos via XMLs
    export function _OpenModal_AgregarEgresosXML(config: IAgregarEgresosXMLConfig) {
        interface IInfo {
            // Folio: string;
            Id: number;
            File: File;
            UUID: string; // Folio
            Concepto: string;
            // IdEscuela: number;
            TipoEgreso: Entidad.CFinanzaEgresoTipo;
            IdCategoria: number;
            FechaAplicacion: string;
            FechaEmision: string;
            FechaVencimiento?: string;

            SubTotal: number;
            Descuento: number;
            ImpuestosTrasladados?: string;
            TrasladoIVA: number;
            TrasladoIEPS: number;
            ImpuestosRetenidos?: string;
            RetencionISR: number;
            RetencionIEPS: number;
            RetencionIVA: number;
            MontoTotal: number;

            // Emisor
            ProveedorRFC: string;
            ProveedorRazonSocial: string;
            ProveedorCP: string;
            ProveedorInfo: IProveedor;

            Pagado?: boolean;
            FechaPago?: string;
            CuentaPago?: number;

            Estado: TEstado;
            Focus: boolean;

            MessageProveedorErr?: string;
            // MessageError?: string;
        }
        const Estado = { // FIXME Remover las etiquetas sin lang al implementarse el proximo servicio
            Pendiente: LangString("general", "pendiente"),
            FolioExistente: LangString(_EGRESO_LANGKEY, "tag_folioexistente"),
            Registrado: "Egreso registrado",
            RegistrarProveedorFallo: "Ocurrió un error al registrar al proveedor",
            XMLRegistroError: "Ocurrió un problema al registrar el XML",
            Pagado: "Pago registrado",
            PagoError: "Error de pago, reintente",
            Error: LangString("general", "notif_fail"),
            Finalizado: LangString("general", "fin_exitoso")
        } as const
        type TEstado = (typeof Estado)[keyof typeof Estado];
        if (!config.Escuelas.length) {
            NotificacionV2._Mostrar(LangString(_EGRESO_LANGKEY, "tag_selecciona_escuela"), "ADVERTENCIA");
            return;
        }
        type IModalExtras = Table.Tabla<IInfo> & {
            fnIdEscuela(): number;
            fnRequestProveedores(): Promise<void>;
        };

        let egresosAgregados = false;
        ModalThings._GetModalToAProccess<IModalExtras>({
            Title: LangString(_EGRESO_LANGKEY, "tag_add_egresos"),
            Width: 1300,
            Height: "850px",
            OnClose: () => config.OnEnd(egresosAgregados),
            DrawContent: async (container, mt) => {
                let proveedoresInCurrentSchoolByRFC: Map<string, IProveedor>;
                const fnIdEscuela = () => ctrlSelectEscuelas._dataValueMemberSelected[0];
                const fnValidaDatos = () => {
                    if (mt.Extras._data.length && fnIdEscuela() && !mt.Extras._dataChecked.length) {
                        // NOTE: Si !d.UUID, no se valida, pero no pasa a ser procesado
                        const datosValidos = mt.Extras._data
                            .every(d => (!d.UUID || (d.Concepto?.trim() && d.FechaAplicacion && /*d.ProveedorInfo &&*/ d.TipoEgreso && (!d.Pagado || (d.CuentaPago && d.FechaPago)))));
                        if (datosValidos) {
                            mt.BtnRight.fn_Enable();
                            return;
                        }
                    }
                    mt.BtnRight.fn_Disable();
                }
                const fnRequestCategoriaEgreso = async (idEscuela = fnIdEscuela()) => {
                    if (!idEscuela) return;
                    mt.Progress.node()._Visible = true;
                    await MainPage._ReloadServiceAndAwaitBool(Entidad.CTipoRequest.EgresoCategoria, idEscuela);
                    mt.Progress.node()._Visible = false;
                }
                const fnRequestProveedores = async (idEscuela = fnIdEscuela()) => {
                    mt.Progress.node()._Visible = true;
                    await MainPage._ReloadServiceAndAwaitBool(Entidad.CTipoRequest.Proveedor, idEscuela); //config.Escuelas.map(d => d.IdKinder));
                    proveedoresInCurrentSchoolByRFC = new Map(DataModuloMain._GetReqDataArrayByName("Proveedor")
                        .filter(d => (d.IdEscuela == idEscuela))
                        .map(d => ([d.RFC, d])));
                    mt.Progress.node()._Visible = false;
                }
                const fnReadXMLFiles = (xmlFiles: File[]) => new ArrayV2<File>()
                    ._Push(...xmlFiles)
                    ._MapAwait<IInfo>(async (file, i) => {
                        console.warn(i, file);
                        const comprobante = await UIUtilCFDI._ProcesaComprobanteCFDI_ByFile(file);
                        const timbreFiscalDigital = comprobante.Sequence.Complemento?.Childs?.find(d => d.Name == "TimbreFiscalDigital");
                        const impuestosTrasladadosMap = d3Group(comprobante.Sequence.Impuestos?.Sequence.Traslados?.Childs || [], d => d.Attrs.Impuesto)
                        const impuestosRetenidosMap = d3Group(comprobante.Sequence.Impuestos?.Sequence.Retenciones?.Childs || [], d => d.Attrs.Impuesto)
                        const totalImpuesto = (impuestos: (CFDIV4.CFDIV4_ESQUEMA["Sequence"]["Impuestos"]["Sequence"]["Traslados" | "Retenciones"]["Childs"][number])[]) =>
                            (impuestos?.reduce<number>((total, imp) => total + Number(imp.Attrs.Importe), 0) || 0)
                        const imptoTrasladosIVA = totalImpuesto(impuestosTrasladadosMap.get("002")); // IVA
                        const imptoTrasladosIEPS = totalImpuesto(impuestosTrasladadosMap.get("003")); // IEPS
                        const imptoRetenidosISR = totalImpuesto(impuestosRetenidosMap.get("001")); // ISR
                        const imptoRetenidosIVA = totalImpuesto(impuestosRetenidosMap.get("002")); // IVA
                        const imptoRetenidosIEPS = totalImpuesto(impuestosRetenidosMap.get("003")); // IEPS

                        // console.warn(comprobante);
                        return UIUtilGeneral._ObjectAddGetters<IInfo>({
                            Id: i,
                            File: file,
                            UUID: timbreFiscalDigital?.Attrs.UUID?.toString(),
                            Concepto: comprobante.Sequence.Conceptos.Childs ? comprobante.Sequence.Conceptos.Childs[0]?.Attrs?.Descripcion || "" : "",
                            // IdEscuela: ctrlSelectEscuelas.prop_dataSelected[0]?.IdKinder,
                            FechaAplicacion: comprobante.Attrs.Fecha ? UIUtilTime._FmtToInputDate(comprobante.Attrs.Fecha) : "",
                            FechaEmision: comprobante.Attrs.Fecha,
                            TipoEgreso: Entidad.CFinanzaEgresoTipo.Otros,
                            IdCategoria: UIUtilViewFinanzaEgresoCategoria._ID_EGRESO_GENERAL,

                            SubTotal: Number(comprobante.Attrs.SubTotal),
                            Descuento: Number(comprobante.Attrs.Descuento || 0),
                            TrasladoIVA: imptoTrasladosIVA,
                            TrasladoIEPS: imptoTrasladosIEPS,
                            RetencionISR: imptoRetenidosISR,
                            RetencionIVA: imptoRetenidosIVA,
                            RetencionIEPS: imptoRetenidosIEPS,
                            MontoTotal: Number(comprobante.Attrs.Total),

                            ProveedorRFC: comprobante.Sequence.Emisor.Attrs.Rfc.toUpperCase(),
                            ProveedorRazonSocial: comprobante.Sequence.Emisor.Attrs.Nombre,
                            ProveedorCP: comprobante.Attrs.LugarExpedicion || "",
                            ProveedorInfo: null,
                            Estado: null,
                            Focus: false,

                            Pagado: false,
                        }, {
                            ProveedorInfo: (dato) => {
                                const prov = proveedoresInCurrentSchoolByRFC.get(dato.ProveedorRFC);
                                if (prov?.IdEscuela == fnIdEscuela()) return prov;
                                return null;
                            },
                        })
                    })
                const fnDescartarElemento = (items: IInfo[]) => {
                    const dataTable = mt.Extras._data;
                    items.forEach(item => {
                        const indexToDelete = dataTable.indexOf(item);
                        dataTable.splice(indexToDelete, 1);
                    })
                    mt.Extras._UpdateData(dataTable);
                    fnValidaDatos();
                }

                // Draw
                container.classed(UIUtilGeneral.FBoxOrientation.Vertical, true).style("gap", "var(--padding2)");
                mt.BtnLeft.remove();
                mt.BtnRight._Enable(false);

                const ctrlSelectEscuelas = (() => {
                    const controlLista = new SelectV2<IEscuela, "IdKinder", "monoselect">({
                        Type: "monoselect",
                        Parent: container,
                        ValueMember: "IdKinder",
                        DisplayMember: "Nombre",
                        Data: config.Escuelas,
                        OnChange: (idEscuela, escuela) => {
                            fnRequestProveedores();
                            fnRequestCategoriaEgreso();
                            mt.Extras._RefreshView();
                            wrapper.style("border-color", !controlLista._dataSelected.length ? "var(--color_app_red1)" : null);
                            fnValidaDatos();
                        },
                    })
                    if (config.Escuelas.length == 1) {
                        controlLista._valueSelect(config.Escuelas[0].IdKinder);
                    } else {
                        controlLista._showOptionsList();
                    }
                    const wrapper = ElementWrapper._WrapperToSelectControl(controlLista, LangString(_EGRESO_LANGKEY, "d_field_nombreescuela"))
                        .style("max-width", "350px")
                        .style("border-color", !controlLista._dataSelected.length ? "var(--color_app_red1)" : null);
                    container.append(() => wrapper.node())
                    return controlLista;
                })()

                const ctrlTabla = new Table.Tabla<IInfo>({
                    IdTabla: "FinanzasEgresos-Movimientos-XMLCargaMasiva",
                    Parent: container,
                    IdData: "Id",
                    MinWidth: 1760,
                    OnValueSelectRow: (key, value) => console.log(key, value),
                    RenderColumnHeadings: [
                        { Field: "File", Label: "", Width: "1%", MinWidth: "30px", IsSortable: false, Icon: { Type: "file", Text: "DOC" } },
                        { Field: "ProveedorInfo", Width: "15%", MinWidth: "50px", Label: LangString(_EGRESO_LANGKEY, "d_field_proveedor") },
                        { Field: "TipoEgreso", Width: "11%", MinWidth: "50px", Label: LangString(_EGRESO_LANGKEY, "d_field_tipoegreso") },
                        { Field: "IdCategoria", Width: "11%", MinWidth: "50px", Label: LangString(_EGRESO_LANGKEY, "d_field_categoria") },
                        { Field: "Concepto", Width: "13%", MinWidth: "50px", Label: LangString(_EGRESO_LANGKEY, "d_field_descripcion") },
                        { Field: "FechaAplicacion", Width: "9%", MinWidth: "50px", Label: LangString(_EGRESO_LANGKEY, "d_field_fechaaplicacion") },
                        { Field: "FechaVencimiento", Width: "9%", MinWidth: "50px", Label: LangString(_EGRESO_LANGKEY, "d_field_fechavencimiento") },
                        { Field: "Pagado", Width: "11%", MinWidth: "50px", Label: LangString(_EGRESO_LANGKEY, "tag_pagado") },
                        { Field: "MontoTotal", Width: "8%", MinWidth: "50px", Label: LangString(_EGRESO_LANGKEY, "tag_total") },

                        { Field: "SubTotal", Width: "8%", MinWidth: "50px", Label: LangString(_EGRESO_LANGKEY, "tag_subtotal") },
                        { Field: "Descuento", Width: "8%", MinWidth: "50px", Label: LangString(_EGRESO_LANGKEY, "d_field_descuento") },
                        { Field: "ImpuestosRetenidos", Width: "10%", MinWidth: "50px", Label: LangString(_EGRESO_LANGKEY, "d_field_impuestosretenidos") },
                        { Field: "ImpuestosTrasladados", Width: "10%", MinWidth: "50px", Label: LangString(_EGRESO_LANGKEY, "d_field_impuestostrasladados") },
                        { Field: "UUID", Width: "11%", MinWidth: "50px", Label: LangString(_EGRESO_LANGKEY, "d_field_folio") },
                    ],
                    OptionsTopDefaultV2: {
                        Options: [{
                            Label: LangString(_EGRESO_LANGKEY, "tag_carga_archivos"),
                            Callback: () => InputFileDialog._Open({
                                AcceptExtensions: [".xml"],
                                OnLoad: async (files) => {
                                    mt.Progress.node()._Visible = true;
                                    const dataReaded = await fnReadXMLFiles(files);
                                    mt.Progress.node()._Visible = false;
                                    mt.Extras._UpdateData(dataReaded);
                                    fnValidaDatos();
                                }
                            })
                        }]
                    },
                    OptionsOfDataCheckV3: (datos) => {
                        fnValidaDatos();
                        mt.Extras._MenuTopOfDataChecked._OnHide(() => {
                            fnValidaDatos();
                            mt.Extras._MenuTopOfDataChecked._OnHide(null);
                        })
                        return {
                            Options: [{ Label: LangString(_EGRESO_LANGKEY, "tag_descartar"), Callback: () => fnDescartarElemento(datos) }]
                        }
                    },
                    EvaluatorAndSubLevelsBuild: {
                        GetOptionsInRowV2: (dato) => ({
                            Options: [{ Label: LangString(_EGRESO_LANGKEY, "tag_descartar"), Callback: () => fnDescartarElemento([dato]) }]
                        }),
                        OnStepRowTable: (dato, tr, rowBody) => {
                            if (!dato.Focus) return;
                            const spaceTabla = mt.Extras._Control.select<HTMLDivElement>(".space_tabla").node();
                            const rBodyB = spaceTabla.querySelector<HTMLDivElement>(".rbodyB");
                            if (spaceTabla.scrollLeft) {
                                spaceTabla.scrollLeft = 0;
                                rBodyB.scrollTop = 0;
                            }
                            dato.Focus = false;
                            rowBody.node().scrollIntoView({ behavior: "smooth", block: "center" });
                        },
                        OnStepCellTable: (container, dato, field: keyof IInfo) => {
                            switch (field) {
                                case "File":
                                    if (container.text()) container.text("");
                                    UIUtilViewFinanzaEgresoUtil._IconDocumentacionXMLOpenPreview(container, dato.File);
                                    break;
                                case "UUID":
                                    container.style("color", !dato.UUID ? "var(--color_app_red1)" : null)
                                        .text(dato.UUID || LangString("general", "nodisponible") + "\n" + LangString(_EGRESO_LANGKEY, "tag_descartar_proc"))
                                    break;
                                case "TipoEgreso":
                                    let ctrlTipoEgreso: SelectV2 = container.node()["__CtrlTipoEgreso"]
                                    if (!ctrlTipoEgreso) {
                                        container.text("");
                                        ctrlTipoEgreso = new SelectV2({
                                            Type: "monoselect",
                                            Parent: container,
                                            ValueMember: "Id",
                                            DisplayMember: "Name",
                                            ListWidth: "210px",
                                            Data: UIUtilViewData._GetList_EgresoTipo(),
                                        })
                                        ctrlTipoEgreso._controlSelection.style("border", "none")
                                            .node().onclick = e => e.stopPropagation();
                                    }
                                    ctrlTipoEgreso._disabled = !dato.UUID;
                                    ctrlTipoEgreso._ShowInputBorder(!!dato.UUID);
                                    ctrlTipoEgreso._valueSelect(dato.TipoEgreso);
                                    ctrlTipoEgreso._onChange = (tipoEgreso) => {
                                        dato.TipoEgreso = tipoEgreso;
                                    }
                                    break;
                                case "IdCategoria":
                                    let ctrlCategoria: SelectV2 = container.node()["__CtrlCategoria"]
                                    if (!ctrlCategoria) {
                                        container.text("");
                                        ctrlCategoria = new SelectV2<Entidad.IFinanzasEgresoCategoria>({
                                            Type: "monoselect",
                                            Parent: container,
                                            ValueMember: "Id",
                                            DisplayMember: "Nombre",
                                            ListWidth: "210px",
                                            Data: () => UIUtilViewFinanzaEgresoCategoria._GetCategoriasFullList(fnIdEscuela() || -1),
                                        })
                                        ctrlCategoria._controlSelection.style("border", "none")
                                            .node().onclick = e => e.stopPropagation();
                                    }
                                    ctrlCategoria._disabled = !dato.UUID;
                                    ctrlCategoria._ShowInputBorder(!!dato.UUID);
                                    ctrlCategoria._valueSelect(dato.IdCategoria);
                                    ctrlCategoria._onChange = (idCategoria) => {
                                        dato.IdCategoria = idCategoria;
                                    }
                                    break;
                                // case "IdEscuela":
                                //     container
                                //         .style("color", !dato.IdEscuela ? "var(--color_app_red1)" : null)
                                //         .text(!dato.IdEscuela ? "Selecciona una escuela" : DataModuloMain.fn_GetDataValueFieldByName("Escuela", dato.IdEscuela, "Nombre"))
                                //     break;
                                case "Concepto":
                                    const contNode = container.node();
                                    const td = d3.select(container.node().parentElement);
                                    let lblStatus = td.select<HTMLLabelElement>(":scope > label");

                                    if (!container.classed("input-form")) {
                                        container.classed("input-form shy_scrollthin", true)
                                        td.style("flex-direction", "column")
                                            .style("justify-content", "center")
                                            .style("align-items", "start")
                                            .style("gap", "var(--padding1)");
                                        contNode.style.width = "100%";
                                        contNode.style.height = "max-content";
                                        contNode.style.maxHeight = "100px";
                                        contNode.style.overflow = "hidden auto";
                                        contNode.contentEditable = "true";
                                        contNode.onclick = e => e.stopPropagation();
                                    }
                                    contNode.textContent = dato.Concepto;
                                    contNode.style.border = !dato.Concepto ? "1px solid var(--color_app_red1)" : null;
                                    contNode.oninput = e => {
                                        dato.Concepto = contNode.innerText;
                                        contNode.style.border = !dato.Concepto ? "1px solid var(--color_app_red1)" : null;
                                        fnValidaDatos();
                                    };

                                    if (dato.Estado) {
                                        if (!lblStatus.node()) lblStatus = td.append("label");
                                        lblStatus.text(dato.Estado)
                                            .style("color", (dato.Estado == Estado.Finalizado
                                                ? "var(--color_app_green1)"
                                                : (dato.Estado == Estado.Error || dato.Estado == Estado.PagoError || dato.Estado == Estado.XMLRegistroError || dato.Estado == Estado.FolioExistente || dato.Estado == Estado.RegistrarProveedorFallo
                                                    ? "var(--color_app_red1)"
                                                    : "var(--color_action1)"))
                                            );
                                    } else if (lblStatus.node()) lblStatus.remove();
                                    break;
                                case "FechaAplicacion":
                                case "FechaVencimiento":
                                    let inputFecha = container.select<HTMLInputElement>("input").node()
                                    if (!inputFecha) {
                                        container.text("");
                                        inputFecha = container.append("input").node();
                                        inputFecha.className = "input-form";
                                        inputFecha.type = "date";
                                        inputFecha.onclick = e => e.stopPropagation();
                                        if (field == "FechaVencimiento") container.style("white-space", "normal").append("wc-tooltip").text("Opcional"); // FIX_LANGUAGE
                                    }
                                    inputFecha.max = field == "FechaAplicacion" ? UIUtilTime._FmtToInputDate(dato.FechaEmision) : "";
                                    inputFecha.min = field == "FechaVencimiento" ? dato.FechaAplicacion || "" : "";
                                    inputFecha.value = dato[field];
                                    inputFecha.onchange = e => {
                                        dato[field] = inputFecha.value;
                                        if (field == "FechaAplicacion") {
                                            inputFecha.style.border = !dato[field] ? "1px solid var(--color_app_red1)" : null;
                                            fnValidaDatos();
                                        }
                                    };
                                    if (field == "FechaAplicacion") inputFecha.style.border = !dato.FechaAplicacion ? "1px solid var(--color_app_red1)" : null;
                                    break;
                                // case "FechaEmision":
                                //     container.text(UIUtilTime.fn_DateFormatStandar(dato.FechaEmision, "dd/mm/yyyy h24:mm:ss"));
                                //     break;
                                case "ImpuestosRetenidos":
                                    container.classed(UIUtilGeneral.FBoxOrientation.Vertical, true)
                                        .style("width", "100%").style("height", "100%").style("overflow", "auto")
                                        .html(`<table>`
                                            + `<tr><th>${LangString(_EGRESO_LANGKEY, "tag_isr")}</th><td> ${_CurrencyFmt(dato.RetencionISR)}</td></tr>`
                                            + `<tr><th>${LangString(_EGRESO_LANGKEY, "tag_iva")}</th><td> ${_CurrencyFmt(dato.RetencionIVA)}</td></tr>`
                                            + `<tr><th>${LangString(_EGRESO_LANGKEY, "tag_ieps")}</th><td> ${_CurrencyFmt(dato.RetencionIEPS)}</td></tr>`
                                            + `<tr><th>${LangString(_EGRESO_LANGKEY, "tag_total")}</th><td> ${_CurrencyFmt(dato.RetencionIEPS + dato.RetencionISR + dato.RetencionIVA)}</td></tr>`
                                            + `</table>`)
                                    break;
                                case "ImpuestosTrasladados":
                                    container.classed(UIUtilGeneral.FBoxOrientation.Vertical, true)
                                        .style("width", "100%").style("height", "100%").style("overflow", "auto")
                                        .html(`<table>`
                                            + `<tr><th>${LangString(_EGRESO_LANGKEY, "tag_iva")}</th><td> ${_CurrencyFmt(dato.TrasladoIVA)}</td></tr>`
                                            + `<tr><th>${LangString(_EGRESO_LANGKEY, "tag_ieps")}</th><td> ${_CurrencyFmt(dato.TrasladoIEPS)}</td></tr>`
                                            + `<tr><th>${LangString(_EGRESO_LANGKEY, "tag_total")}</th><td> ${_CurrencyFmt(dato.TrasladoIVA + dato.TrasladoIEPS)}</td></tr>`
                                            + `</table>`)
                                    break;
                                // case "IVA":
                                // case "IEPS":
                                // case "RetencionISR":
                                // case "RetencionIVA":
                                case "SubTotal":
                                case "Descuento":
                                case "MontoTotal":
                                    container.text(_CurrencyFmt(dato[field]));
                                    break;
                                case "Pagado":
                                    let checkbox = container.select<HTMLInputElement>("input[type=checkbox]").node();
                                    const fnUpdatePagoInfoView = () => {
                                        container.select("button").classed("hide", !dato.Pagado)
                                        container.select("label").classed("hide", !dato.Pagado)
                                            .style("color", !(dato.Pagado && dato.FechaPago) ? "var(--color_app_red1)" : null)
                                            .html(!(dato.CuentaPago && dato.FechaPago)
                                                ? LangString("general", "notif_infofalta")
                                                : `<b>${LangString(_EGRESO_LANGKEY, "tag_cuenta")}:</b> ${DataModuloMain._GetDataValueFieldByName("CuentaBancaria", dato.CuentaPago, "Beneficiario")}`
                                                + `<br><b>${LangString(_EGRESO_LANGKEY, "tag_fechapago")}:</b> ${UIUtilTime._DateFormatStandarFixTimeZoneByIdSchool(dato.FechaPago, fnIdEscuela())}`
                                            )
                                    }
                                    const fnProcesoEditarInfo = async () => {
                                        if (!fnIdEscuela()) {
                                            ctrlSelectEscuelas._showOptionsList();
                                            NotificacionV2._Mostrar(LangString(_EGRESO_LANGKEY, "tag_selecciona_escuela"), "ADVERTENCIA");
                                            return
                                        }
                                        const pagoInfo = await OpenModal_EditarDatosPago({
                                            CantidadPago: dato.MontoTotal,
                                            FechaEmision: dato.FechaEmision,
                                            FechaPago: dato.FechaPago,
                                            IdCuenta: dato.CuentaPago,
                                            IdEscuela: fnIdEscuela(),
                                        });
                                        dato.CuentaPago = pagoInfo?.IdCuenta;
                                        dato.FechaPago = pagoInfo?.FechaPago;
                                        // dato. = pagoInfo?.Cantidad; // NOTE Es pago completo
                                        fnUpdatePagoInfoView();
                                        fnValidaDatos();
                                    }
                                    if (!checkbox) {
                                        if (container.text()) container.text("")
                                        checkbox = container.classed(UIUtilGeneral.FBoxOrientation.Vertical, true)
                                            .style("gap", "var(--padding1)")
                                            .append("input")
                                            .attr("type", "checkbox")
                                            .style("width", "16px")
                                            .style("height", "16px")
                                            .node();
                                        checkbox.onclick = e => e.stopPropagation();
                                        // const infoPagoContainer = container.append("div")
                                        // container.append("input")
                                        container.append("label")
                                        new Button(container, LangString(_EGRESO_LANGKEY, "tag_edita_infopago"))
                                    }
                                    container.select<HTMLButtonElement>("button").node().onclick = async e => {
                                        e.stopPropagation();
                                        fnProcesoEditarInfo();
                                    }
                                    checkbox.disabled = !dato.UUID;
                                    checkbox.checked = dato.Pagado;
                                    checkbox.onchange = e => {
                                        e.stopPropagation();
                                        dato.Pagado = checkbox.checked;
                                        fnUpdatePagoInfoView();
                                        fnValidaDatos();
                                        if (dato.Pagado && (!dato.CuentaPago || !dato.FechaPago)) fnProcesoEditarInfo();
                                    }
                                    fnUpdatePagoInfoView();
                                    break;
                                case "ProveedorInfo":
                                    const provInfo = dato.ProveedorInfo;
                                    container.html(`<b>${dato.ProveedorRFC}</b>`
                                        + `<br>${provInfo?.RazonSocial || dato.ProveedorRazonSocial || "--"}`
                                        + `<br>CP: ${provInfo?.CP || dato.ProveedorCP || "--"}`
                                        + (!provInfo
                                            ? `<br><label style="color:var(--color_app_red1);">${LangString(_EGRESO_LANGKEY, "tag_prov_sera_registrado")}</label>`
                                            : (provInfo.Correo ? `<br><span font-size="var(--fontsize_me2)">${provInfo.Correo}</span>` : "")
                                        )
                                    )
                                    break;
                            }
                        }
                    },
                })
                mt.Extras = ctrlTabla as IModalExtras;
                mt.Extras.fnIdEscuela = fnIdEscuela;
                mt.Extras.fnRequestProveedores = fnRequestProveedores;

                fnRequestProveedores();
                fnRequestCategoriaEgreso();
            },
            OnAccept: async (mt) => {
                const idEscuela = mt.Extras.fnIdEscuela();
                mt.Extras._data.forEach(d => {
                    d.Estado = d.UUID ? Estado.Pendiente : null;
                })
                mt.Extras._RefreshView();
                await UIUtilGeneral._Sleep(500);
                await new ArrayV2(...mt.Extras._data)
                    ._ForEachAwait(async d => {
                        if (!d.UUID) return;
                        d.Focus = true;
                        d.MessageProveedorErr = null
                        mt.Extras._RefreshView();

                        if (!d.ProveedorInfo) {
                            const resP = await DataModuloProveedor._NuevoRegistro({
                                IdEscuela: idEscuela,
                                RFC: d.ProveedorRFC,
                                RazonSocial: d.ProveedorRazonSocial,
                                CP: d.ProveedorCP,
                            })
                            if (resP.Resultado > 0 || resP.Resultado == -1 || resP.Resultado == -2) { // Proveedor Nuevo o existente - Request list
                                await mt.Extras.fnRequestProveedores();
                                if (!d.ProveedorInfo) { // Fallo al obtener lista actualizada
                                    d.Estado = Estado.RegistrarProveedorFallo;
                                    d.MessageProveedorErr = _HttpMsgV2(resP)
                                    mt.Extras._RefreshView();
                                    return
                                }
                            } else {
                                d.Estado = Estado.RegistrarProveedorFallo;
                                mt.Extras._RefreshView();
                                return
                            }
                        }

                        const resE = await DataModuloEgresoMovimiento._NuevoEgreso({
                            ...d,
                            SubTotal: d.SubTotal,
                            Descripcion: d.Concepto,
                            FechaEmision: d.FechaEmision,
                            IdCategoria: d.IdCategoria,
                            FechaAplicacion: new DateV2(UIUtilTime._GetLocalDateFromInputDateString(d.FechaAplicacion))
                                ._SetTimeZoneByIdSchool(idEscuela, true)
                                ._ToISOString(),
                            FechaVencimiento: d.FechaVencimiento ? new DateV2(UIUtilTime._GetLocalDateFromInputDateString(d.FechaVencimiento))
                                ._SetTimeZoneByIdSchool(idEscuela, true)
                                ._ToISOString() : null,
                            Folio: d.UUID,
                            IdEscuela: idEscuela,
                            IdProveedor: d.ProveedorInfo.Id,
                            EsFactura: true,
                        })
                        if (resE.Resultado > 0)
                            d.Estado = Estado.Registrado;
                        else {
                            if (resE.Resultado == -1 || resE.Resultado == -2) {
                                d.Estado = Estado.FolioExistente;
                                mt.Extras._RefreshView();
                                return;
                            }
                            else
                                d.Estado = Estado.Error;
                            mt.Extras._RefreshView();
                            return;
                        }
                        mt.Extras._RefreshView()

                        const resD = await DataModuloEgresoMovimiento._ActualizarDocumentacion(resE.Resultado, [d.File], [])
                        if (resD.Resultado <= 0) {
                            d.Estado = Estado.XMLRegistroError;
                            mt.Extras._RefreshView();
                        }

                        const resP = await (async () => {
                            if (!d.Pagado) return null;
                            let res = await DataModuloEgresoMovimiento._NuevoPago({
                                Cantidad: d.MontoTotal,
                                FechaPago: d.FechaPago,
                                IdCuenta: d.CuentaPago,
                                IdMovimiento: resE.Resultado,
                            })
                            if (res.Resultado > 0) d.Estado = Estado.Pagado
                            else d.Estado = Estado.PagoError;
                            mt.Extras._RefreshView();
                            return res;
                        })()

                        if (resE.Resultado > 0 && resD.Resultado > 0 && (!resP || resP.Resultado > 0)) {
                            mt.Extras._RefreshView();
                            d.Estado = Estado.Finalizado;
                        }
                        // d.Focus = false;
                        // mt.Extras.met_RefreshView()
                    });
                await UIUtilGeneral._Sleep(500);
                const datosPendientes: IInfo[] = [];
                const datosExitosos: IInfo[] = [];
                const datosYaExistentes: IInfo[] = [];
                mt.Extras._data.forEach(d => {
                    if (d.Estado == Estado.Finalizado) datosExitosos.push(d);
                    else if (d.Estado == Estado.FolioExistente) datosYaExistentes.push(d);
                    else datosPendientes.push(d);
                })
                egresosAgregados = !!datosExitosos.length || !!datosYaExistentes.length;
                await ModalThings._GetConfirmacionBasicoV2({
                    Title: LangString("general", "resumen"),
                    Width: 220,
                    Calcelable: false,
                    Content: d3.create("table").style("width", "100%").call(table => {
                        table.append("tr").call(tr => {
                            tr.append("th").text(LangString(_EGRESO_LANGKEY, "tag_xmlregs_nuevos"));
                            tr.append("td").text(datosExitosos.length).classed("text_right", true);
                        })
                        table.append("tr").call(tr => {
                            tr.append("th").text(LangString(_EGRESO_LANGKEY, "tag_xmlregs_existentes"));
                            tr.append("td").text(datosYaExistentes.length).classed("text_right", true);
                        })
                        table.append("tr").call(tr => {
                            tr.append("th").text(LangString(_EGRESO_LANGKEY, "tag_xmlregs_fallos"));
                            tr.append("td").text(datosPendientes.length).classed("text_right", true);
                        })
                    }),
                })
                mt.Extras._UpdateData(datosPendientes);
                return null;
            }
        })
    }
}
