import { create, select } from "d3"
import { ICatalogoSATClaveProductServ, ICatalogoSATClaveUnidad, IFacturaClienteAlumno, IFacturaClienteCuentaBanco, IInfoEmpresaFactura, IPagoInfoFacturar } from "../../../data/entidad/Factura"
import { _ProcesaFacturaCatalogo } from "../../../data/modulo/FacturaCatalogo"
import { _ObtenerCatalogoSATClaveUnidad, _ObtenerCatalogoSATFormaPago, _ObtenerCatalogoSATMetodoPago, _ObtenerCatalogoSATUsoCFDI, _ObtenerTiposDocumentos } from "../../../data/modulo/FacturaCatalogoSAT"
import { _ObtenerDetalleEmpresaFactura } from "../../../data/modulo/FacturaEmpresa"
import { _ObtenerSeries } from "../../../data/modulo/FacturaSerie"
import { _ObtenerClaveProdServSATBase } from "../../../data/modulo/FinanzaCargo"
import { DateV2 } from "../../../util/DateV2"
import _L from "../../../util/Labels"
import { UtilObject } from "../../../util/Object"
import { FormGenerator } from "../../controlD3/Formulario"
import { ModalThings } from "../../controlD3/ModalThings"
import { NotificacionV2 } from "../../controlD3/NotificacionV2"
import { SelectV2 } from "../../controlD3/SelectV2"
import { UIUtilFormat } from "../../util/Format"
import { UIUtilTime } from "../../util/Time"
import { AlumnoEdoCtaFacturacionUtil as Util } from "./AlumnoEdoCtaFacturacionUtil"

export namespace UIUtilViewAlumnoEdoCtaFacturacionViewCFDIInfo {
    export type ICFDIInfoItemFinal = ICFDIInfoGeneral & ICFDIInfoItemPreFinal
    export interface ViewInfoCFDI {
        GetData: () => ICFDIInfoItemFinal[]
        IsValid: () => boolean
        Refresh: (cliente: IFacturaClienteAlumno, movimientosPagos: Util.IMovimientoPagosAgrupados[]) => void
        // SetClient: (cliente: IFacturaCliente) => void
    }
    export function _GenerarCFDI_ViewInfo(container: TSelectionHTML<"div">, mt: ModalThings.IModalThings, idEscuela: number, idAlumno: number): ViewInfoCFDI {
        // type MovimientosPagosAgrupadosControl = IMovimientosPagosAgrupados & { _FormaPago: string, _NoCuenta: string, _NoPedido: string }
        let infoEmpresaFactura: IInfoEmpresaFactura = undefined
        // let infoClienteFactura: IFacturaClienteAlumno
        const progressBar = mt.Progress.node()
        const initData = <ICFDIInfoGeneral>{
            TipoCfdi: "factura",
        }
        const fnSetFormData = (data: ICFDIInfoGeneral) => form._AsignaData(data)
        const fnConsultarInfo = () => {
            let saveInfo = infoEmpresaFactura
            if (infoEmpresaFactura === null) {
                return
            }
            infoEmpresaFactura = null // null -> (consultando)
            progressBar._Visible = true
            _ObtenerDetalleEmpresaFactura(idEscuela)
                .then((res) => {
                    if (res.Resultado <= 0) {
                        if (saveInfo)
                            infoEmpresaFactura = saveInfo
                        return
                    }
                    infoEmpresaFactura = res.Datos
                    if (!form._GetModelValue("LugarExpedicion") || infoEmpresaFactura)
                        form._SetModelValue("LugarExpedicion", infoEmpresaFactura.codpos)
                    // console.debug("infoEmpresaFactura", infoEmpresaFactura, form)
                })
                .finally(() => {
                    progressBar._Visible = false
                })
        }

        const form = new FormGenerator<ICFDIInfoGeneral>()

        form._Crear({
            schema: [
                {
                    model: "TipoCfdi", type: "selectMaterial", labelText: _L("panelfinanzasedocuenta_factura.tipo_cfdi"),
                    selectMaterialAttr: {
                        required: true,
                        valueMember: "key",
                        displayMember: "name",
                        disabled: true,
                        removeBorder: true,
                        Data: _ObtenerTiposDocumentos(),
                    }
                },
                { model: "LugarExpedicion", type: "input", labelText: _L("panelfinanzasedocuenta_factura.lugar_expedicion"), inputAttr: { required: true, minlength: 5, maxlength: 5 } },
                {
                    model: "Serie", type: "selectMaterial", labelText: _L("panelfinanzasedocuenta_factura.serie"),
                    selectMaterialAttr: {
                        required: true,
                        valueMember: "SerieID",
                        displayMember: "SerieDescription",
                        Data: _ProcesaFacturaCatalogo(_ObtenerSeries(idEscuela))
                            .then(lista => {
                                form._SetModelValue("Serie", lista[0]?.SerieID)
                                return lista.map(d => {
                                    d.SerieDescription = `${d.SerieName} - ${d.SerieDescription}`
                                    return d
                                })
                            }),
                    },
                },
                {
                    model: "MetodoPago", type: "selectMaterial", labelText: _L("panelfinanzasedocuenta_factura.metodo_pago"),
                    selectMaterialAttr: {
                        required: true,
                        valueMember: "key",
                        displayMember: "name",
                        disabled: true,
                        removeBorder: true,
                        Data: _ProcesaFacturaCatalogo(_ObtenerCatalogoSATMetodoPago(idEscuela))
                            .then(lista => {
                                form._SetModelValue("MetodoPago", lista[0].key)
                                if (lista.length) {
                                    lista = [lista[0]]
                                }
                                return lista
                            }),
                    },
                },
            ]
        })
        container.append(() => form._Form.node())
            .style("max-width", "550px")
        fnSetFormData(initData)

        const cfdisTitle = (container.append("br"), container.append("b").text("_CFDIs a crear_"))
        const cfdisListControl = CreateCFDISListControl(idEscuela, container)

        fnConsultarInfo()

        return {
            GetData: () => {
                const globalData = form._Data
                return cfdisListControl._GetDataFinal().map((cfdiinfo) => {
                    const item: ICFDIInfoItemFinal = {
                        TipoCfdi: globalData.TipoCfdi,
                        LugarExpedicion: globalData.LugarExpedicion,
                        Serie: globalData.Serie,
                        MetodoPago: globalData.MetodoPago,
                        ...cfdiinfo,
                    }
                    return UtilObject._GettersSetters(item, {
                        getFecha: () => {
                            const dt: Date = new Date()
                            const dif: number = FECHA_TIMBRADO_OPCIONES_BYKEY[item.fechaTimbrado].difDays
                            dt.setDate(dt.getDate() - dif)
                            const y = dt.getFullYear()
                            const m = (dt.getMonth() + 1).toString().padStart(2, '0')
                            const d = dt.getDate().toString().padStart(2, "0")
                            // FORMATO `2020-03-20`
                            return `${y}-${m}-${d}`
                        }
                    })
                })
            },
            IsValid: () => {
                if (cfdisListControl._GetCountData() == 0) {
                    NotificacionV2._Mostrar(_L("panelfinanzasedocuenta_factura.no_cfdis_a_crear"), "ADVERTENCIA")
                    return false
                }
                const generalFormValid = form._GetIsValidForm() // Formulario general
                if (!generalFormValid) {
                    NotificacionV2._Mostrar(_L("general.notif_infofalta"), "ADVERTENCIA")
                    form._Form.node().scrollIntoView({ block: "nearest" })
                    return false
                }
                // const cfdisControls =
                for (const cfdiControls of cfdisListControl._GetControls()) {
                    if (!cfdiControls.Form._GetIsValidForm()) {
                        NotificacionV2._Mostrar(_L("general.notif_infofalta"), "ADVERTENCIA")
                        cfdiControls.Form._Form.node().scrollIntoView({ block: "nearest" })
                        return false
                    }
                    // const validCfdiClaves = [...movimientosPagosCFDIMap.values()].every(m => m.Pagos.every(p => !!p.ClaveProdServSAT)) // Conceptos con clave
                    const selectsControl = [...cfdiControls.CFDISelectProdServControls, ...cfdiControls.CFDISelectUnidadControls]
                    for (const pagoClaveControl of selectsControl) {
                        pagoClaveControl._controlSelection.style("border", "none")
                        if (!pagoClaveControl._dataSelected.length) {
                            NotificacionV2._Mostrar(_L("general.notif_infofalta"), "ADVERTENCIA")
                            const fcus = pagoClaveControl._controlSelection
                                .style("border", "1px solid var(--color_app_red1)")
                                .node()
                            fcus.scrollIntoView({ block: "center" })
                            fcus.click()
                            return false
                        }
                    }
                }
                return true
            },
            Refresh: (cliente: IFacturaClienteAlumno, movimientosPagos) => {
                if (!infoEmpresaFactura) {
                    fnConsultarInfo()
                }

                // Update Movimientos

                cfdisTitle.text(_L(movimientosPagos.length > 1
                    ? "panelfinanzasedocuenta_factura.cfdis_a_crear"
                    : "panelfinanzasedocuenta_factura.cfdi_a_crear", movimientosPagos.length))


                // infoClienteFactura = cliente
                cfdisListControl._SetData(cliente, movimientosPagos)
            }
        }
    }
}

interface CFDISListControl {
    _SetData(cliente: IFacturaClienteAlumno, movimientosPagos: Util.IMovimientoPagosAgrupados[]): CFDISListControl
    _GetCountData(): number
    _GetDataFinal(): ICFDIInfoItemPreFinal[]
    _GetControls(): { CFDIGroup: string, Form: CFDIItemForm, CFDISelectProdServControls: SelectV2<TProdServItemData>[], CFDISelectUnidadControls: SelectV2<TClaveUnidadItemData>[] }[]
}
function CreateCFDISListControl(idEscuela: number, container: TSelectionHTML): CFDISListControl {
    const movimientosPagosCFDIMap: Map<string, Util.IMovimientoPagosAgrupados> = new Map()
    const cfdisFormsMap = new Map<string, CFDIItemForm>()
    const pagosClavesControlMap = new Map<number, SelectV2<TProdServItemData>>()
    const unidadClavesControlMap = new Map<number, SelectV2<TClaveUnidadItemData>>()
    const listView = Util._CreateListControl<Util.IMovimientoPagosAgrupados>({ addMarginTop: true, addMarginBottom: true })
        ._SetParent(container)
        ._SetCreateTemplate((itemContent, d) => {
            itemContent.append("b").attr("class", "cfdi_head")
                .style("background-color", "var(--color_borderbox1)")
                .style("padding", "0 var(--padding1)")
                .style("border-radius", "var(--border_radius_base)")
            itemContent.append("div").attr("class", "cfdi_info").style("margin-top", "var(--padding2)")
        })
        ._SetUpdateItem((itemContent, d) => {
            // CFDI HEAD
            itemContent.select(".cfdi_head")
                .classed("hide", movimientosPagosCFDIMap.size <= 1)
                .text(movimientosPagosCFDIMap.size > 1 ? d.ID : "")
            //_L("panelfinanzasedocuenta_factura.cfdi") + " " + d.Id
            // CFDI FORM
            cfdisFormsMap.get(d.GrupoCFDI).__SetContainer(itemContent)

            // CFDI INFO
            itemContent.select(".cfdi_info").html(`
                ${_L("panelfinanzasedocuenta_factura.conceptos")}

                <table style="min-width:100%;margin-top:var(--padding1);border-top:1px solid var(--color_borderbox1);">
                    <tr>
                        <th style="width:30%;">${_L("panelfinanzasedocuenta.tag_cargo")}</th>
                        <th style="width:30%;">${_L("panelfinanzasedocuenta_factura.claveprodserv")}</th>
                        <th style="width:25%;">${_L("panelfinanzasedocuenta_factura.unidad")}</th>
                        <th style="width:15%;text-align:right;">${_L("panelfinanzasedocuenta_factura.monto")}</th>
                    </tr>
                    ${d.Pagos.map(pago =>
                `<tr>
                        <td>${pago.NombreCargo}</td>
                        <td class="clave_prodserv" name="${pago.Id}"></td>
                        <td class="clave_unidad" name="${pago.Id}"></td>
                        <td style="text-align:right;">${UIUtilFormat._CurrencyFmt(pago.Monto)}</td>
                    </tr>`).join("")}
                </table>`).raise()

            itemContent.selectAll<HTMLTableCellElement, any>(".clave_prodserv").each((_, i, cells) => { // Clave Prod/Serv
                const td = cells[i]
                select(td).selectAll(":scope>*").remove()
                td.appendChild(pagosClavesControlMap.get(+td.getAttribute("name"))._controlSelection.node())
            })
            itemContent.selectAll<HTMLTableCellElement, any>(".clave_unidad").each((_, i, cells) => { // Clave Prod/Serv
                const td = cells[i]
                select(td).selectAll(":scope>*").remove()
                td.appendChild(unidadClavesControlMap.get(+td.getAttribute("name"))._controlSelection.node())
            })
        })
    const result: CFDISListControl = {
        _GetCountData: () => movimientosPagosCFDIMap.size,
        _SetData: (cliente, movimientosPagos) => {
            movimientosPagosCFDIMap.clear()
            movimientosPagos.forEach(m => {
                movimientosPagosCFDIMap.set(m.GrupoCFDI, {
                    ...m,
                    Pagos: m.Pagos.map(p => {
                        let selectProdServCtrl: SelectV2<TProdServItemData>
                        let selectUnidadCtrl: SelectV2<TClaveUnidadItemData>
                        if (!pagosClavesControlMap.has(p.Id)) {
                            selectProdServCtrl = fnCreatePagoClavePSControl(p, idEscuela)
                            selectProdServCtrl._valueSelect(p.ClaveProdServSAT)
                            pagosClavesControlMap.set(p.Id, selectProdServCtrl)
                            selectUnidadCtrl = fnCreatePagoClaveUnidadControl(p, idEscuela)
                            unidadClavesControlMap.set(p.Id, selectUnidadCtrl)
                        } else {
                            selectProdServCtrl = pagosClavesControlMap.get(p.Id)
                            selectUnidadCtrl = unidadClavesControlMap.get(p.Id)
                        }
                        console.debug("Update Clave SAT, selectedValue = ", selectProdServCtrl._dataSelected[0]?.key)
                        return <Util.IMovimientoPagosAgrupados["Pagos"][number]>{
                            ...p,
                            ClaveProdServSAT: (selectProdServCtrl._dataSelected[0]?.key || "") as string,
                            ClaveUnidadSAT: (selectUnidadCtrl._dataSelected[0]?.key || undefined) as string | number,
                            UnidadSAT: (selectUnidadCtrl._dataSelected[0]?.name || undefined) as string,
                        }
                    })
                })
            })

            const cfdisFormsMapAux = new Map(cfdisFormsMap)
            cfdisFormsMap.clear()
            movimientosPagosCFDIMap.forEach(d => {
                if (cfdisFormsMapAux.has(d.GrupoCFDI)) {
                    cfdisFormsMap.set(d.GrupoCFDI, cfdisFormsMapAux.get(d.GrupoCFDI))
                } else {
                    cfdisFormsMap.set(d.GrupoCFDI, CrearCFDIItemForm(idEscuela))
                }
                cfdisFormsMap.get(d.GrupoCFDI)
                    .__SetClient(cliente)
                    .__SetMovimientoPagosAgrupados(d)
            })

            listView._SetItems([...movimientosPagosCFDIMap.values()])
            return result
        },
        _GetDataFinal: () => [...movimientosPagosCFDIMap.entries()].map(([GrupoCFDI, { ID, IdsPagos }]) => {
            const cfdiInfo = cfdisFormsMap.get(GrupoCFDI)._Data
            const itemResult: ICFDIInfoItemPreFinal = {
                ID,
                fechaTimbrado: cfdiInfo.fechaTimbrado,
                Fecha: null,
                UsoCFDI: cfdiInfo.UsoCFDI,
                FormaPago: cfdiInfo.FormaPago,
                NumOrder: cfdiInfo.NumOrder,
                CondicionPago: cfdiInfo.CondicionPago,
                Banco: cfdiInfo.Banco,
                Cuenta: cfdiInfo.Cuenta,
                Comentarios: cfdiInfo.Comentarios,
                EnviarCorreo: cfdiInfo.EnviarCorreo,
                Conceptos: IdsPagos.map<ICFDIInfoItemPreFinal["Conceptos"][number]>(idPago => ({
                    IdPago: idPago,
                    ClaveProdServSAT: pagosClavesControlMap.get(idPago)._dataValueMemberSelected[0],
                    ClaveUnidadSAT: unidadClavesControlMap.get(idPago)._dataValueMemberSelected[0],
                    UnidadSAT: unidadClavesControlMap.get(idPago)._dataSelected[0].name,
                })),
            }
            return itemResult
        }),
        _GetControls: () => [...movimientosPagosCFDIMap.entries()].map(([GrupoCFDI, cfdiInfo]) => ({
            CFDIGroup: GrupoCFDI,
            Form: cfdisFormsMap.get(GrupoCFDI),
            CFDISelectProdServControls: cfdiInfo.IdsPagos.map(idPago => pagosClavesControlMap.get(idPago)),
            CFDISelectUnidadControls: cfdiInfo.IdsPagos.map(idPago => unidadClavesControlMap.get(idPago)),
        })),
    }
    return result
}

const fnCreatePagoClavePSControl = (p: IPagoInfoFacturar, idEscuela: number) => {
    const control = new SelectV2<TProdServItemData, "key", "monoselect">({
        Parent: create("div"), // AUX PARENT
        Type: "monoselect",
        ValueMember: "key",
        DisplayMember: "full_name",
        ListWidth: "450px",
        ShowAndEnableSearchText: true,
        RecyclerView: true,
        OnChange: (_, dato) => {
            p.ClaveProdServSAT = dato.key
            p.NombreProdServSAT = dato.name
        },
        Data: (() => {
            const itemDefault: ICatalogoSATClaveProductServ = !p.ClaveProdServSAT ? null : { key: p.ClaveProdServSAT, name: p.NombreProdServSAT, complement: "" }
            const resolveData = (res: ICatalogoSATClaveProductServ[]) => res.map(d => ({ ...d, full_name: (d.key + " - " + d.name) }))
            const res = _ProcesaFacturaCatalogo(_ObtenerClaveProdServSATBase(idEscuela, itemDefault)).then(resolveData)
            return res
        })()
    })
    control._controlSelection.style("border", "none")
    return control
}
const fnCreatePagoClaveUnidadControl = (p: IPagoInfoFacturar, idEscuela: number) => {
    const control = new SelectV2<TClaveUnidadItemData, "key", "monoselect">({
        Parent: create("div"), // AUX PARENT
        Type: "monoselect",
        ValueMember: "key",
        DisplayMember: "full_name",
        ListWidth: "350px",
        ShowAndEnableSearchText: true,
        // RecyclerView: true,
        OnChange: (_, dato) => {
            p.ClaveUnidadSAT = dato.key
            p.UnidadSAT = dato.name
        },
        Data: (() => {
            // const itemDefault: TClaveUnidadItemData = null // !p.ClaveProdServSAT ? null : { key: p.ClaveProdServSAT, name: p.NombreProdServSAT, complement: "" }
            const resolveData = (res: ICatalogoSATClaveUnidad[]) => res.map(d => ({ ...d, full_name: (d.key + " - " + d.name) }))
            // const res = _ProcesaFacturaCatalogo(_ObtenerClaveProdServSATBase(idEscuela, itemDefault)).then(resolveData)
            const res = _ProcesaFacturaCatalogo(_ObtenerCatalogoSATClaveUnidad(idEscuela)).then(resolveData)
            return res
        })()
    })
    control._controlSelection.style("border", "none")
    return control
}


// UTILS

type CFDIItemForm = FormGenerator<ICFDIInfoItem> & {
    __SetClient?: (client: IFacturaClienteAlumno) => CFDIItemForm
    __SetContainer?: (container: TSelectionHTML) => CFDIItemForm
    __SetMovimientoPagosAgrupados?: (d: Util.IMovimientoPagosAgrupados) => CFDIItemForm
    __GetMovimientoPagosAgrupados?: () => Util.IMovimientoPagosAgrupados
}
function CrearCFDIItemForm(idEscuela: number) {
    type TCuentaBanco = ({ key: string, name: string } & Partial<IFacturaClienteCuentaBanco>)
    let infoClienteFactura: IFacturaClienteAlumno
    let movimientoPagosAgrupados: Util.IMovimientoPagosAgrupados
    const initData = <ICFDIInfoItem>{
        fechaTimbrado: "hoy",
        CuentaBanco: "0",
        EnviarCorreo: true,
    }
    const form: CFDIItemForm = new FormGenerator<ICFDIInfoItem>()
    const fnListaCuentas = () => {
        const listBase: TCuentaBanco[] = [{ key: "0", name: _L("general.ninguno") }]
        const listCuentas = infoClienteFactura?.cuentas_banco.map((cliente, i) => ({
            key: (i + 1) + "",
            name: `${cliente.banco} - ${cliente.cuenta}`,
            ...cliente,
        })) || []
        return [...listBase, ...listCuentas]
    }
    const camposCol1: (keyof ICFDIInfoItem)[] = ["fechaPago", "fechaTimbrado", "UsoCFDI", "FormaPago"]
    const camposCol2: (keyof ICFDIInfoItem)[] = ["NumOrder", "CondicionPago", "CuentaBanco", "Comentarios", "EnviarCorreo"]
    form._Crear({
        LabelWrap: false,
        OnLoad: (container, rows) => {
            container
                .style("flex-direction", "row")
                .style("gap", "var(--padding2)")
                .call(cont => {
                    cont.append("div").call(div => camposCol1.forEach(c => div.append(() => rows.get(c).row.node())))
                        .style("width", "100%")
                    cont.append("div")
                        .call(div => camposCol2.forEach(c => {
                            div.append(() => rows.get(c).row.node())
                                .select("label.titulos_base")
                                .style("flex", "62%")
                        }))
                        .style("width", "100%")
                })
        },
        schema: [
            { model: "fechaPago", type: "input", labelText: _L("panelfinanzasedocuenta.d_field_fechapago"), inputAttr: { required: true, removeBorder: true, disabled: true } },
            {
                model: "fechaTimbrado", type: "selectMaterial", labelText: _L("panelfinanzasedocuenta_factura.fecha_cfdi"),
                selectMaterialAttr: {
                    required: true,
                    valueMember: "key",
                    displayMember: "name",
                    Data: () => ObtenerFechaTimbradoOpciones(movimientoPagosAgrupados?.FechaPago, idEscuela),
                }
            },
            {
                model: "UsoCFDI", type: "selectMaterial", labelText: _L("panelfinanzasedocuenta_factura.uso_cfdi"),
                selectMaterialAttr: {
                    required: true,
                    valueMember: "key",
                    displayMember: "name",
                    ShowAndEnableSearchText: true,
                    Data: _ProcesaFacturaCatalogo(_ObtenerCatalogoSATUsoCFDI(idEscuela)),
                }
            },
            {
                model: "FormaPago", type: "selectMaterial", labelText: _L("panelfinanzasedocuenta_factura.forma_pago"),
                selectMaterialAttr: {
                    required: true,
                    valueMember: "key",
                    displayMember: "name",
                    Data: _ProcesaFacturaCatalogo(_ObtenerCatalogoSATFormaPago(idEscuela)),
                },
            },
            { model: "NumOrder", type: "input", labelText: _L("panelfinanzasedocuenta_factura.num_orden") },
            { model: "CondicionPago", type: "input", labelText: _L("panelfinanzasedocuenta_factura.condicion_pago"), inputAttr: { maxlength: 1000 } },
            {
                model: "CuentaBanco", type: "selectMaterial", labelText: _L("panelfinanzasedocuenta_factura.num_cuenta"),
                selectMaterialAttr: {
                    valueMember: "key",
                    displayMember: "name",
                    Data: () => fnListaCuentas(),
                    onSelect: (item: TCuentaBanco) => {
                        form._DataOrigin.Banco = item?.banco || undefined
                        form._DataOrigin.Cuenta = item?.cuenta || undefined
                    }
                }
            },
            { model: "Comentarios", type: "textArea", labelText: _L("panelfinanzasedocuenta_factura.comentarios"), inputAttr: { maxlength: 2000 } },
            { model: "EnviarCorreo", type: "input", labelText: _L("panelfinanzasedocuenta_factura.enviar_correo"), inputAttr: { type: "checkbox" } }
        ]
    }, initData)
    form.__SetClient = (cliente) => {
        if (!cliente)
            return form
        const clientChanged = infoClienteFactura?.UID != cliente.UID
        infoClienteFactura = cliente

        const cuentaControl = form._GetModelControl<"selectMaterial">("CuentaBanco")
        cuentaControl.instance._RefreshList()
        cuentaControl.row.classed("hide", !cliente.cuentas_banco?.length)

        if (clientChanged) {
            cuentaControl.instance._valueSelect(0) // reset
            form._SetModelValue("UsoCFDI", cliente.UsoCFDI)
        }
        return form
    }
    form.__SetContainer = (container) => {
        const formElement = form._Form.node()
        container.selectAll<HTMLFormElement, any>(":scope>.formulario_generator").each((_, i, elmts) => {
            if (formElement !== elmts[i])
                elmts[i].remove()
        })
        if (formElement.parentElement != container.node())
            container.append(() => formElement)
        return form
    }
    form.__SetMovimientoPagosAgrupados = (data: Util.IMovimientoPagosAgrupados) => {
        movimientoPagosAgrupados = data
        form._SetModelValue("fechaPago", data.FechaPagoDia)

        const formaPagoControl = form._GetModelControl<"selectMaterial">("FormaPago").instance
        const formaPagoEnable = !data.IdFormaPagoSAT
        formaPagoControl._disabled = !formaPagoEnable
        formaPagoControl._ShowInputBorder(!formaPagoEnable)
        if (!form._GetModelValue("FormaPago"))
            form._SetModelValue("FormaPago", data.IdFormaPagoSAT)
        // else if (!formaPagoEnable)
        //     form._SetModelValue("FormaPago", "")

        const fechaTimbradoControl = form._GetModelControl<"selectMaterial">("fechaTimbrado").instance
        fechaTimbradoControl._RefreshList()

        return form
    }
    form.__GetMovimientoPagosAgrupados = () => movimientoPagosAgrupados
    return form
}

type TProdServItemData = ICatalogoSATClaveProductServ & { full_name: string }
type TClaveUnidadItemData = ICatalogoSATClaveUnidad & { full_name: string }

type TFechaTimbradoItem = { key: TFechaTimbrado, name: string, difDays: number }
const FECHA_TIMBRADO_OPCIONES: { [k: number]: TFechaTimbradoItem } = {
    0: { difDays: 0, key: "hoy", name: _L("panelfinanzasedocuenta_factura.fechatimbrar_hoy") },
    1: { difDays: 1, key: "ayer", name: _L("panelfinanzasedocuenta_factura.fechatimbrar_ayer") },
    2: { difDays: 2, key: "antier", name: _L("panelfinanzasedocuenta_factura.fechatimbrar_antier") },
    3: { difDays: 3, key: "anteantier", name: _L("panelfinanzasedocuenta_factura.fechatimbrar_anteantier") },
}
const FECHA_TIMBRADO_OPCIONES_BYKEY: { [k in TFechaTimbrado]: TFechaTimbradoItem } = {
    "hoy": FECHA_TIMBRADO_OPCIONES[0],
    "ayer": FECHA_TIMBRADO_OPCIONES[1],
    "antier": FECHA_TIMBRADO_OPCIONES[2],
    "anteantier": FECHA_TIMBRADO_OPCIONES[3],
}
function ObtenerFechaTimbradoOpciones(fechaPagoUTC: string, idEscuela: number) {
    let daysElapse = 0
    if (fechaPagoUTC) {
        const dt: DateV2 = new DateV2(fechaPagoUTC)._SetTimeZoneByIdSchool(idEscuela)._Truncate("Hour")
        const now = new DateV2()._Truncate("Hour")
        daysElapse = UIUtilTime._GetTimeElapsedSimple(dt, now).Days()
    }
    const result = <TFechaTimbradoItem[]>[]
    for (const k in FECHA_TIMBRADO_OPCIONES) {
        const nK: number = +k
        if (nK <= daysElapse)
            result.push(FECHA_TIMBRADO_OPCIONES[nK])
    }
    return result
}

type TFechaTimbrado = "anteantier" | "antier" | "ayer" | "hoy"
interface ICFDIInfoGeneral {
    // Cliente: string
    /** @example "43000" (CP) */
    LugarExpedicion: string
    // LugarExpedicionId: number
    /** @example "PUE" */
    MetodoPago: string
    // Moneda: string
    // NumeroDecimales: string
    Serie: number
    TipoCfdi: string
}
interface ICFDIInfoItem {
    fechaTimbrado: TFechaTimbrado
    fechaPago: string
    /** @example "01" */
    FormaPago: string
    /** @example "00110011" */
    ClaveProdServ: string
    /** NoOrdenPedido */
    NumOrder: string
    Fecha: string
    FechaExpedicion: string
    /** Nuero de cuenta */
    Cuenta: string
    CondicionPago: string
    UsoCFDI: string
    /** Auxiliar para campos `Banco` y `Cuenta` */
    CuentaBanco: string
    /** Banco de nuero de cuenta */
    Banco: string
    Comentarios: string
    EnviarCorreo: boolean
}

type ICFDIInfoItemPreFinal = Pick<ICFDIInfoItem,
    | "fechaTimbrado"
    | "Fecha"
    | "UsoCFDI"
    | "FormaPago"
    | "NumOrder"
    | "CondicionPago"
    | "Cuenta"
    | "Banco"
    | "Comentarios"
    | "EnviarCorreo">
    & {
        ID: string
        Conceptos: {
            IdPago: number,
            ClaveProdServSAT: string
            ClaveUnidadSAT: string | number
            UnidadSAT: string
        }[]
    }
