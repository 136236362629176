import * as d3 from "d3";
import { UIUtilLang } from "../util/Language";
import { UIUtilTime } from "../util/Time";

export namespace CalendarioGridBase {

    interface ICGB_SelectorBase<IDType> {
        /** @requires */
        readonly Id: IDType;
        /** @optional */
        Name?: string;
        /** @optional */
        InitColor?: string;
        /** Selector container */
        Container?: d3.Selection<HTMLDivElement, any, HTMLElement, any>;
        /** En caso de que un más de un selector ocupe una celda, se acuerdo a la prioridad, éstos se acomodan
         * * Sólo números positivos, cualquier número positivo será devuelto a 0
         * * prioridad 1 es la más alta, 2, 3...
         * @default 0
        */
        PriorityWhenMerging?: number
        /** Styles */
        readonly Class?: string
    }

    interface ICGB_SelectorRealYearDays<IDType> extends ICGB_SelectorBase<IDType> {
        /** Dia de posición inicial del selector en el calendario, Fecha especifica
         * @optional */
        Date?: Date;

        /** Tipo string: Id de otro selector para tomar en cuenta como min Date
         * @optional */
        readonly IdMinSelector?: IDType;
        // /** Limite minimo de fechas en las que se puede mover el selector, Fecha especifica
        // * @optional */
        // MinDate?: Date;
        readonly MinSelectorData?: ICGB_SelectorRealYearDays<IDType> | null;

        /** Tipo string: Id de otro selector para tomar en cuenta como max Date
         * @optional */
        readonly IdMaxSelector?: IDType;
        // /** Limite máximo de fechas en las que se puede mover el selector, Fecha especifica
        // * @optional */
        // MaxDate?: Date;
        // /** @requires */
        // readonly type?: NonNullable<"real">
        readonly MaxSelectorData?: ICGB_SelectorRealYearDays<IDType> | null;
    }

    interface ICGB_SelectorBaseYearDays<IDType> extends ICGB_SelectorBase<IDType> {
        /** Dia de posición inicial del selector en el calendario, Dia del año especifico
         * @optional */
        Date?: number;

        /** Tipo string: Id de otro selector para tomar en cuenta como min Date
         * @optional */
        readonly IdMinSelector?: IDType;
        // /** Limite minimo de fechas en las que se puede mover el selector, Dia del año especifico
        // * @optional */
        // MinDate?: number;
        readonly MinSelectorData?: ICGB_SelectorBaseYearDays<IDType> | null;

        /** Tipo string: Id de otro selector para tomar en cuenta como max Date
         * @optional */
        readonly IdMaxSelector?: IDType;
        // /** Limite máximo de fechas en las que se puede mover el selector, Dia del año especifico
        // * @optional */
        // MaxDate?: number;
        // /** @requires */
        // readonly type?: NonNullable<"base">
        readonly MaxSelectorData?: ICGB_SelectorBaseYearDays<IDType> | null;
    }

    export interface ICGB_SelectorMap<IDType, KToAddDateSelector = {}> {
        DataBase: ICGB_SelectorBaseYearDays<IDType> & KToAddDateSelector;
        DataReal: ICGB_SelectorRealYearDays<IDType> & KToAddDateSelector;
    }

    export interface ICGB_TypeGeneralMap {
        DataBase: IGeneralTypeBase;
        DataReal: IGeneralTypeReal;
    }

    interface IGeneralTypeBase {
        BuildManyBaseMonths: (nMonthsToBluild: number) => Array<ICGB_Month>
        BuildBaseMonth: (nMonth: number, beforeMonth?: ICGB_Month) => ICGB_Month
    }

    interface IGeneralTypeReal {
        /**
        * @param year (opcional) en caso de omitir, el año será el actual
        * @param labelMonthNamePrefix (opcional) el prefijo de nombre se añade en la propiedad Name del objeto devuelto, por defecto = ""
        * @param labelMonthNameSufix (opcional) el sufijo de nombre se añade en la propiedad Name del objeto devuelto, por defecto = ""
        * @param addMonthName (opcional) Agrega en la propiedad Name, el nombre real del mes, por defecto = true
        */
        BuildRealYear: (year?: number, labelMonthNamePrefix?: string, labelMonthNameSufix?: string, addMonthName?: boolean) => ICGB_Year
        /** Crea un mes real
         * @param nMonth 1 = "Enero", ...
         * @param year (opcional) por defecto es el Año actual
         * @param labelMonthNamePrefix (opcional) el prefijo de nombre se añade en la propiedad Name del objeto devuelto, por defecto = ""
         * @param labelMonthNameSufix (opcional) el sufijo de nombre se añade en la propiedad Name del objeto devuelto, por defecto = ""
         * @param addMonthName (opcional) Agrega en la propiedad Name, el nombre real del mes, por defecto = true
        */
        BuildYearMonth: (nMonth: TCGB_ValidMonths, year?: number, labelMonthNamePrefix?: string, labelMonthNameSufix?: string, addMonthName?: boolean) => ICGB_Month
        /** Devuelve el numero de día del año */
        GetDayIntoYear: (date: Date) => number
        /** @returns date ? Number(String(yyyy + mm + dd)) : (type == "min" ? 0 : 99999999) */
        GetDateEvaluator: (type: "min" | "max", date?: Date) => number
    }

    export type TCGB_ValidMonths = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

    export interface ICGB_Year {
        /** Año */
        NYear: number;
        Months: Array<ICGB_Month>;
    }

    export interface ICGB_Month {
        /** Numero del mes en el año */
        readonly NMonth: number;
        readonly Year: number;
        Name: string;
        IsEnable: boolean;
        Days: Array<ICGB_DateCell>;
        /** Se contruye en CalendarGridBase.CGBDrawOrUpdateGridMonth(month: IMonth) */
        Container: d3.Selection<HTMLDivElement, any, HTMLElement, any>;
    }

    export interface ICGB_DateCell {
        /** Dia de la semana: 0 = Domingo, 1 = Lunes ... */
        readonly WeekDay: number;
        readonly MonthDay: number;
        readonly YearDay: number;
        /** Numero de semana dentro del mes */
        readonly NMonthWeek: number;
        IsEnable: boolean;
        Name: string;
        // /** en caso de que la celda se encuentre dentro de un rango de fechas seleccionados */
        // IsInRange: boolean;
    }

    type TConfigToSelectors<IDType, KToAddDateSelector, KSelector extends keyof ICGB_SelectorMap<IDType, KToAddDateSelector>> = Omit<ICGB_SelectorMap<IDType, KToAddDateSelector>[KSelector], keyof Pick<ICGB_SelectorMap<IDType, KToAddDateSelector>[KSelector], "Container" | "MaxSelectorData" | "MinSelectorData">>
    /** d3.Selection<HTMLDivElement, T, HTMLElement, any> */
    type SelectionHTML<TDatum = any, KElement extends keyof HTMLElementTagNameMap & string = "div"> = d3.Selection<HTMLElementTagNameMap[KElement], TDatum, HTMLElement, any>;

    export interface ICGB_Config<KSelector extends keyof ICGB_SelectorMap<IDType, KToAddDateSelector>, IDType, KToAddDateSelector> {
        // OnClickInCell?: (date: Date, divCell: d3.Selection<HTMLDivElement, ICGB_DateCell, HTMLElement,any>) => void;
        Parent?: d3.Selection<HTMLElement, any, HTMLElement, any>;
        /** @requires */
        TypeSelector: KSelector;
        Selectors?: Array<TConfigToSelectors<IDType, KToAddDateSelector, KSelector>>;
        /** Sobreescribe el comportamiento por defecto, del momento en que se posiciona un selector, después de que el contenedor de la celda a la que pertenece es encontrado
         * * NOTA: Solo se invoca si el campo "Date" del selector tiene un valor y el contenedor de selectores (de la celda a la que pertenece el "Date") es encontrado
         * @param selectorsContainer contenedor de los selectores
         * @param selectorContainer contenedor del selector a pegar
         * @param selector objeto con la información del selector
        */
        OnPositionSelectorCalled?: (selectorsContainer: d3.Selection<HTMLDivElement, any, HTMLElement, any>, selectorContainer: d3.Selection<HTMLDivElement, any, HTMLElement, any>, selector: ICGB_SelectorMap<IDType, KToAddDateSelector>[KSelector]) => boolean;
        /** Se invoca en el momento en que el selector es removido de su última posición
         * * NOTA: Solo se invoca cuando el contenedor de selectores (de la celda a la que pertenece el "Date" anterior) es encontrado
         * @param selectorsContainer contenedor de los selectores anterior
         * @param selectorContainer contenedor del selector removido
         * @param selector objeto con la información del selector
        */
        OnRemovePositionSelector?: (lastSelectorsContainer: d3.Selection<HTMLDivElement, any, HTMLElement, any>, selectorContainer: d3.Selection<HTMLDivElement, any, HTMLElement, any>, selector: ICGB_SelectorMap<IDType, KToAddDateSelector>[KSelector]) => void;
        // OnStepMonthCell?: (datum: ICGB_DateCell, month: ICGB_Month, divCell: d3.Selection<HTMLDivElement, ICGB_DateCell, HTMLElement,any>, dateCell: Date, typeUpdate: "enter" | "update" | "exit") => void;
        // OnCreateSelectorContainer?: (id: number, selector: ICGB_SelectorMap[KSelector]) => void;
    }

    // const WeekDaysLong = ["Domingo", "Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sábado"];
    // const WeekDaysShort = ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"];
    // const MonthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    const BaseMonthDays = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30/31"];

    /** 
     * * KSelector: "DataBase" | "DataReal"
     * * IDType: number | string
     * * KToAddDateSelector: any - campos a agregar al selector
     * */
    export abstract class CalendarGridBase<KSelector extends keyof ICGB_SelectorMap<IDType, KToAddDateSelector> & string, IDType extends string | number, KToAddDateSelector = {}> {
        private CGBparent: d3.Selection<HTMLElement, any, HTMLElement, any>;
        private CGBconfig: ICGB_Config<KSelector, IDType, KToAddDateSelector>;

        /** Provee de métodos para crear las estructuras de los meses de acuerdo al tipo de Calendario ("Base" || "Real") */
        protected CGBcreator: ICGB_TypeGeneralMap[KSelector];
        protected CGBcontrolContainer: SelectionHTML<any>;
        protected CGBdateSelectors: Map<IDType, ICGB_SelectorMap<IDType, KToAddDateSelector>[KSelector]>;

        constructor(config: ICGB_Config<KSelector, IDType, KToAddDateSelector>) {
            const defaultConfig = <ICGB_Config<KSelector, IDType, KToAddDateSelector>>{
                Selectors: []
            }
            this.CGBparent = config.Parent;
            this.CGBconfig = { ...defaultConfig, ...config };
            this.CGBdateSelectors = new Map();
            this.InitDrawBase();

            this.CreateCreator();

            if (this.CGBconfig.Selectors) {
                this.InitSelectors();
            }
        }

        get _CGBParent(): d3.Selection<HTMLElement, any, HTMLElement, any> {
            return this.CGBparent;
        }

        set _CGBParent(parent: d3.Selection<HTMLElement, any, HTMLElement, any>) {
            this.CGBparent = parent;
        }

        private CreateCreator() {
            if (this.CGBconfig.TypeSelector == "DataBase") {
                this.CGBcreator = <IGeneralTypeBase>{
                    BuildBaseMonth: (nMonth: number, beforeMonth?: ICGB_Month) => {
                        return this.BuildBaseMonth(nMonth, beforeMonth);
                    },
                    BuildManyBaseMonths: (nMonthsToBluild: number) => {
                        return this.BuildManyBaseMonths(nMonthsToBluild);
                    }
                } as ICGB_TypeGeneralMap[KSelector];
            }
            else if (this.CGBconfig.TypeSelector == "DataReal") {
                this.CGBcreator = <IGeneralTypeReal>{
                    BuildRealYear: (year?: number, labelMonthNamePrefix = "", labelMonthNameSufix = "", addMonthName = true) => {
                        return this.BuildRealYear(year, labelMonthNamePrefix, labelMonthNameSufix, addMonthName);
                    },
                    BuildYearMonth: (nMonth: TCGB_ValidMonths, year?: number, labelMonthNamePrefix = "", labelMonthNameSufix = "", addMonthName = true) => {
                        return this.BuildYearMonth(nMonth, year, labelMonthNamePrefix, labelMonthNameSufix, addMonthName);
                    },
                    GetDayIntoYear: (date: Date) => {
                        return this.GetDayIntoYear(date);
                    },
                    GetDateEvaluator: (type: "min" | "max", date: Date) => {
                        return this.GetDateEvaluator(type, date)
                    }
                } as ICGB_TypeGeneralMap[KSelector];
            }
        }

        private InitDrawBase(): void {
            this.CGBcontrolContainer = this.CGBparent ? this.CGBparent.append<HTMLDivElement>("div") : d3.create<HTMLDivElement>("div");

            this.CGBcontrolContainer.classed("container_calendar_grid", true);

            this.CGBcontrolContainer.append("div").classed("header", true);
            this.CGBcontrolContainer.append("div").classed("sub_header", true);
            let body = this.CGBcontrolContainer.append("div").classed("body", true);
            this.CGBcontrolContainer.append("div").classed("footer", true);

            body.append("div").classed("area_side", true).classed("side_left", true);
            body.append("div").classed("area_calendar_grid", true);
            body.append("div").classed("area_side", true).classed("side_right", true);

            this.CGBUpdateAreaCalendarGrid();
        }

        private CreateSelectorContainer(id: IDType, selector: ICGB_SelectorMap<IDType, KToAddDateSelector>[KSelector]) {
            selector.Container = d3.create<HTMLDivElement>("div")
                .classed("date_selector", true)
                .style("background", selector.InitColor);

            if (selector.Class) {
                selector.Container.classed(selector.Class, true);
            }
            this.CGBOnCreateSelectorContainer(selector);
        }

        private InitSelectors(): Map<IDType, ICGB_SelectorMap<IDType, KToAddDateSelector>[KSelector]> {
            this.CGBconfig.Selectors.forEach(selectorConfig => {
                this.AddASelectorToDateSelectors(selectorConfig);
            });

            this.CGBdateSelectors.forEach((selector, id) => {
                this.CreateASelectorAndRelations(selector);
            });

            return this.CGBdateSelectors;
        }

        private AddASelectorToDateSelectors(selectorConfig: TConfigToSelectors<IDType, KToAddDateSelector, KSelector>): ICGB_SelectorMap<IDType, KToAddDateSelector>[KSelector] {
            selectorConfig.PriorityWhenMerging = !selectorConfig.PriorityWhenMerging || selectorConfig.PriorityWhenMerging < 0 ? 0 : selectorConfig.PriorityWhenMerging;
            const newSelector = <ICGB_SelectorMap<IDType, KToAddDateSelector>[KSelector]>selectorConfig;
            this.CGBdateSelectors.set(selectorConfig.Id, newSelector);
            return newSelector;
        }

        /** Crea los elementos div container de los Selectores y asigna los selectores con los que está relacionado */
        private CreateASelectorAndRelations(selector: ICGB_SelectorMap<IDType, KToAddDateSelector>[KSelector]) {
            if (selector.IdMinSelector != null && selector.IdMinSelector != undefined) {
                let minSelector = this.CGBdateSelectors.get(selector.IdMinSelector);
                //@ts-ignore ARREGLAR!!!
                selector.MinSelectorData = minSelector ? minSelector : null;
            }

            if (selector.IdMaxSelector != null && selector.IdMaxSelector != undefined) {
                let maxSelector = this.CGBdateSelectors.get(selector.IdMaxSelector);
                //@ts-ignore ARREGLAR!!!
                selector.MaxSelectorData = maxSelector ? maxSelector : null;
            }

            this.CreateSelectorContainer(selector.Id, selector);
        }

        private PositionSelector(selector: ICGB_SelectorMap<IDType, KToAddDateSelector>[KSelector]): boolean {
            let areaCalendar = this.CGBcontrolContainer.select<HTMLDivElement>(".area_calendar_grid");
            const lastSelectorParent = selector.Container.node().parentElement as HTMLDivElement
            selector.Container.remove();
            if (this.CGBconfig.OnRemovePositionSelector && lastSelectorParent) {
                this.CGBconfig.OnRemovePositionSelector(d3.select(lastSelectorParent), selector.Container, selector);
            }

            if (areaCalendar.node() && selector.Container && selector.Date) {
                let selectorsContainer: SelectionHTML<any>;

                if (this.CGBconfig.TypeSelector == "DataBase") {
                    let selectorBase = <ICGB_SelectorBaseYearDays<IDType>>selector;
                    if (typeof selectorBase.Date == "number") {
                        selectorsContainer = areaCalendar.select("#cell_" + selectorBase.Date) //selectAll ?
                            .select(".selectors_container")
                    }
                }
                else if (this.CGBconfig.TypeSelector == "DataReal") {
                    let dateInit = (<ICGB_SelectorRealYearDays<IDType>>selector).Date;
                    if (typeof dateInit != "number") {
                        selectorsContainer = areaCalendar.select("#cell_" + dateInit.getFullYear() + "-" + (dateInit.getMonth() + 1) + "-" + dateInit.getDate())
                            .select(".selectors_container")
                    }
                }

                if (selectorsContainer && selectorsContainer.node()) {
                    if (this.CGBconfig.OnPositionSelectorCalled) {
                        let res = this.CGBconfig.OnPositionSelectorCalled(selectorsContainer, selector.Container, selector);
                        if (res) this.CGBUpdateSelectoresPriority();
                        return res;
                    } else {
                        selectorsContainer.append(() => selector.Container.node());
                        this.CGBUpdateSelectoresPriority()
                        return true;
                    }
                }
            }
            return false
        }

        private InitDrawMonth(monthContainer: d3.Selection<HTMLDivElement, ICGB_Month, HTMLElement, any>, month: ICGB_Month) { //,  = month.Container) {
            if (month.Year == -1) {
                // MES BASE
                monthContainer.attr("id", "month_" + month.NMonth);
            } else {
                // MES REAL
                monthContainer.attr("id", "month_" + month.Year + "-" + month.NMonth);
            }
            // month.Container
            // .style("grid-area", "mes-" + configMonth.NMonth);

            let headerM = monthContainer.append<HTMLDivElement>("div").classed("header", true)
            headerM.append("div").classed("area_side", true).classed("side_left", true);
            headerM.append("div").classed("area_name", true).append("label");
            headerM.append("div").classed("area_side", true).classed("side_right", true);

            let bodyM = monthContainer.append<HTMLDivElement>("div").classed("body", true);
            bodyM.append("div").classed("name_days", true);
            bodyM.append("div").classed("date_cells", true);

            monthContainer.append<HTMLDivElement>("div").classed("footer", true);
            return monthContainer;
        }

        //******************************************************************************************
        // METODOS PROTEGIDOS
        //******************************************************************************************

        /** Devuelve el selector con el contenedor ya creado -> selector.Container */
        protected abstract CGBOnCreateSelectorContainer(selector: ICGB_SelectorMap<IDType, KToAddDateSelector>[KSelector]): void;

        /** ocurre cada que se invoca CGBUpdateMonthContainer(container, month: ICGB_Month) */
        protected abstract CGBOnStepMonthCell(datum: ICGB_DateCell, month: ICGB_Month, divCell: SelectionHTML<ICGB_DateCell>, divSelectors: SelectionHTML<any>, dateCell: Date, typeUpdate: "enter" | "update" | "exit"): void;

        protected CGBShow() {
            if (this.CGBparent) this.CGBparent.append(() => this.CGBcontrolContainer.node());
            return this;
        }

        protected CGBRemove() {
            this.CGBcontrolContainer.remove()
            return this;
        }

        /** Join -> ENTER
         * * Crea Divs e invoca a CGBUpdateMonthContainer() para actualizar por primera vez.
         * * No asigna contenedor a month.Container
         * @param enter
         */
        protected CGBEnterMonthContainer(enter: d3.Selection<d3.EnterElement, ICGB_Month, d3.BaseType, any>): d3.Selection<HTMLDivElement, ICGB_Month, d3.BaseType, any> {
            let monthContainer = enter.append("div").classed("month", true);

            monthContainer.classed("test", (d, i, arrDivs) => {
                this.InitDrawMonth(d3.select(arrDivs[i]), d);
                this.CGBUpdateMonthContainer(d3.select(arrDivs[i]), d);
                return false;
            })

            return monthContainer;
        }

        /** Join -> UPDATE.
         * * No asigna contenedor a month.Container
         * @param container contenedor del mes, class == "month"
         * @param month objeto Mes
         */
        protected CGBUpdateMonthContainer(container: SelectionHTML<ICGB_Month>, month: ICGB_Month): SelectionHTML<ICGB_Month> {
            // Nombre del mes
            container.select(".header").select(".area_name").select<HTMLLabelElement>("label").text(month.Name);

            const contDays = container.select<HTMLDivElement>(".body").select(".name_days");
            const contDateCells = container.select<HTMLDivElement>(".body").select(".date_cells");
            const updateDayNameDiv = (div: SelectionHTML<string>, datum: string, index: number) => {
                div.style("grid-area", "1/" + (index + 1))
                div.select("label").text(datum)
                return false;
            }
            const updateDateCellDiv = (divCell: SelectionHTML<ICGB_DateCell>, datum: ICGB_DateCell, month: ICGB_Month, typeUpdate: "enter" | "update" | "exit") => {
                divCell.select(".lbl_name").text(datum.Name);
                divCell.style("grid-area", datum.NMonthWeek + 1 + "/" + (datum.WeekDay + 1) + "/ auto / auto"); // row/col

                if (month.Year == -1) {
                    // MES BASE
                    divCell.attr("id", "cell_" + datum.YearDay)

                } else {
                    // MES REAL
                    divCell.attr("id", "cell_" + month.Year + "-" + month.NMonth + "-" + datum.MonthDay)
                }

                let dateCell = new Date();
                dateCell.setDate(1);
                dateCell.setFullYear(month.Year);
                dateCell.setMonth(month.NMonth - 1);
                dateCell.setDate(datum.MonthDay);

                this.CGBOnStepMonthCell(datum, month, divCell, divCell.select(".selectors_container"), dateCell, typeUpdate);

                divCell.classed("cell_disabled", !datum.IsEnable);
                if (datum.IsEnable) {
                    divCell.style("pointer-events", "all");
                } else {
                    divCell.style("pointer-events", "none");
                }
                return false;
            }

            // Nombres de los días
            contDays.selectAll<HTMLDivElement, string>("div").data(UIUtilTime._GetWeekDays("short"), (d, i) => i + "").join(
                enter => {
                    let item = enter.append<HTMLDivElement>("div");
                    item.append("label");
                    item.classed("test", (d, i, arrDivs) => updateDayNameDiv(d3.select(arrDivs[i]), d, i));
                    return item;
                },
                update => {
                    return update.classed("test", (d, i, arrDivs) => updateDayNameDiv(d3.select(arrDivs[i]), d, i));
                },
                exit => exit.remove()
            )

            // First & Last MonthWeeks
            // if (month.Days[0].WeekDay !== 0 || month.Days[month.Days.length - 1].WeekDay !== 6) {
            let initMonth = false;
            let endMonth = false;
            for (let i = 0; i < 7; i++) {
                if (month.Days[0].WeekDay == i) initMonth = true;
                if (month.Days[month.Days.length - 1].WeekDay + 1 == i) endMonth = true;
                if (!initMonth) {
                    let startCell = contDateCells.select("#LMCell_" + i);
                    if (!startCell.node()) {
                        startCell = contDateCells.append("div")
                            .classed("lastMonthCell", true)
                            .attr("id", "LMCell_" + i);
                    }
                    startCell?.style("grid-area", "2/" + (i + 1) + "/ auto / auto");
                } else if (initMonth && contDateCells.select("#LMCell_" + i).node()) {
                    contDateCells.select("#LMCell_" + i).remove();
                }
                if (endMonth) {
                    let endCell = contDateCells.select("#NMCell_" + i)
                    if (!endCell.node()) {
                        endCell = contDateCells.append("div")
                            .classed("nextMonthCell", true)
                            .attr("id", "NMCell_" + i)
                    }
                    endCell?.style("grid-area", (month.Days[month.Days.length - 1].NMonthWeek + 1) + "/" + (i + 1) + "/ auto / auto");
                } else if (!endMonth && contDateCells.select("#NMCell_" + i).node()) {
                    contDateCells.select("#NMCell_" + i).remove();
                }
            }
            // }

            // Celdas de la grilla del mes
            contDateCells.selectAll<HTMLDivElement, ICGB_DateCell>(".cell").data(month.Days, (d, i) => "" + i).join(
                enter => {
                    let cell = enter.append<HTMLDivElement>("div")
                        .classed("cell", true);

                    cell.append("label")
                        .classed("lbl_name", true);

                    cell.append("div")
                        .classed("selectors_container", true);

                    cell.classed("test", (d, i, arrDivs) => updateDateCellDiv(d3.select(arrDivs[i]), d, month, "enter"));
                    return cell;
                },
                update => {
                    return update.classed("test", (d, i, arrDivs) => updateDateCellDiv(d3.select(arrDivs[i]), d, month, "update"));
                },
                exit => exit.remove()
            )

            // let footer = configMonth.Container.select<HTMLDivElement>(".footer");
            return container;
        }

        /** Crea y/o actualiza componente Month en div[class == "area_calendar_grid"].
         * * Componente: month.Container
         * * Invoca a CGBUpdateMonthContainer() para actualizar
         * @param month objeto Mes
         */
        protected CGBDrawOrUpdateGridMonth(month: ICGB_Month) { //, monthContainer?: d3.Selection<HTMLDivElement, ICGB_Month, HTMLElement, any>) {
            const area_calendar = this.CGBcontrolContainer.select(".body").select(".area_calendar_grid");
            // month.Container = monthContainer ? monthContainer : month.Container;
            if (!month.Container || !month.Container.node()) {
                month.Container = area_calendar.append<HTMLDivElement>("div")
                    .classed("month", true)

                this.InitDrawMonth(month.Container, month);
            }
            this.CGBUpdateMonthContainer(month.Container, month);
            return this
        }

        /** Crea o actualiza (si existe) un selector y actualiza this.CGBdateSelectors
         * @param selectorConfig Configuración del selector
         * @param updatePositions si es true actualiza la posición del selector
        */
        protected CGBAddOrUpdateSelector(selectorConfig: TConfigToSelectors<IDType, KToAddDateSelector, KSelector>, updatePositions: boolean = false): ICGB_SelectorMap<IDType, KToAddDateSelector>[KSelector] {
            if (this.CGBdateSelectors.has(selectorConfig.Id)) {
                this.CGBRemoveSelector(selectorConfig.Id);
            }
            const newSelector = this.AddASelectorToDateSelectors(selectorConfig);
            this.CreateASelectorAndRelations(newSelector);
            if (updatePositions) {
                this.CGBUpdateSelectorPosition(newSelector);
            }
            return newSelector;
        }

        /** ELimina un selector y actualiza this.CGBdateSelectors
         * @param selectoId Id del selector a eliminar
         * @param updatePositions si es true actualiza la posición del los selectores
        */
        protected CGBRemoveSelector(selectoId: IDType, updatePositions: boolean = false) {
            this.CGBdateSelectors.forEach((selector, id) => {
                if (selector.IdMinSelector == selectoId && selector.MinSelectorData) {
                    //@ts-ignore
                    selector.MinSelectorData = null;
                }
                if (selector.IdMaxSelector == selectoId && selector.MaxSelectorData) {
                    //@ts-ignore
                    selector.MaxSelectorData = null;
                }
            })
            this.CGBdateSelectors.delete(selectoId);
            if (updatePositions) {
                this.CGBUpdateAllSelectorsPosition();
            }
            return this;
        }

        /** Reordena de acuerdo a la prioridad del selector (selector.PriorityWhenMerging), los elementos de cada selector (selector.Container), de menor a mayor (izq. a derecha) */
        protected CGBUpdateSelectoresPriority() {
            Array.from(this.CGBdateSelectors.values()).sort((a, b) => a.PriorityWhenMerging - b.PriorityWhenMerging).forEach(sel => {
                if (sel.Container) sel.Container.raise();
            })
            return this;
        }

        /** Actualiza la ubicación del selector en la celda que corresponde a la fecha del selector (selector.Date)
         * * Aplica el orden por prioridad
         *  @param selector Recive el Id del selector o el objeto
        */
        protected CGBUpdateSelectorPosition(selector: IDType | ICGB_SelectorMap<IDType, KToAddDateSelector>[KSelector]) {
            if (selector !== null && selector !== undefined) {
                if (typeof selector === "number" || typeof selector === "string") {
                    if (this.CGBdateSelectors.has(selector as IDType)) {
                        this.PositionSelector(this.CGBdateSelectors.get(selector as IDType));
                    }
                } else if ((<ICGB_SelectorMap<IDType, KToAddDateSelector>[KSelector]>selector).Id !== null && (<ICGB_SelectorMap<IDType, KToAddDateSelector>[KSelector]>selector).Id !== undefined) {
                    this.PositionSelector(<ICGB_SelectorMap<IDType, KToAddDateSelector>[KSelector]>selector);
                }
            }
            return this;
        }

        /** Actualiza la ubicación de cada selector en la celda que corresponde a la fecha del selector (selector.Date)
         * * Aplica el orden por prioridad
        */
        protected CGBUpdateAllSelectorsPosition() {
            this.CGBdateSelectors.forEach((selector, id) => {
                this.PositionSelector(selector);
            })
            return this;
        }

        // protected DrawUpdateMonths(months: Array<ICGB_Month>) {}

        /** Numero de meses visibles distribuidos en columnas y filas
         * @param nCols (opcional) número de máximo columnas
         * @param nRows (opcional) número máximo de filas
        */
        protected CGBUpdateAreaCalendarGrid(nCols: 1 | 2 | 3 | 4 = 1, nRows = 1) {
            let area_calendar = this.CGBcontrolContainer.select(".body").select(".area_calendar_grid");
            area_calendar.classed(`gT-columns-4`, false);
            area_calendar.classed(`gT-columns-3`, false);
            area_calendar.classed(`gT-columns-2`, false);
            area_calendar.classed(`gT-columns-1`, false);
            area_calendar.classed(`gT-columns-${nCols}`, true);
            //area_calendar.style("grid-template-columns", "repeat(" + nCols + ", auto"); // + (100 / nCols) + "%)");
            area_calendar.style("grid-template-rows", "repeat(" + nRows + ", auto"); // + (100 / nRows) + "%)");

            let prefix = "size_" + (this.CGBconfig.TypeSelector == "DataReal" ? "reals_" : "bases_");
            area_calendar.classed(prefix + 1, false);
            area_calendar.classed(prefix + 2, false);
            area_calendar.classed(prefix + 3, false);
            area_calendar.classed(prefix + 4, false);
            area_calendar.classed(prefix + nCols, true);
            return this;
        }

        //******************************************************************************************
        // METODOS DE CALENDARIO PARA FECHAS BASE
        //******************************************************************************************

        private BuildManyBaseMonths(nMonthsToBluild: number): Array<ICGB_Month> {
            let months: Array<ICGB_Month> = [];
            for (let i = 1; i <= nMonthsToBluild; i++) {
                //let month = this.BuildBaseMonth(i);
                let month = this.BuildBaseMonth(i, months[i - 2]);
                months.push(month);
            }
            return months;
        }

        private BuildBaseMonth(nMonth: number, beforeMonth?: ICGB_Month): ICGB_Month {
            let month = <ICGB_Month>{
                NMonth: nMonth,
                Days: [],
                IsEnable: true,
                Name: UIUtilLang._GetUIString("time", "mes") + " " + nMonth,
                Year: -1
            }
            let weekDay = 0;
            let monthDay = 0;
            let yearDay = beforeMonth ? beforeMonth.Days[beforeMonth.Days.length - 1].YearDay : 0;
            let nWeek = 1;
            for (let strDay of BaseMonthDays) {
                monthDay++;
                yearDay++;
                if (weekDay == 7) {
                    weekDay = 0;
                }
                if (monthDay != 1 && weekDay == 0) nWeek++;
                month.Days.push({
                    Name: strDay,
                    WeekDay: weekDay,
                    MonthDay: monthDay,
                    YearDay: yearDay,
                    IsEnable: true,
                    NMonthWeek: nWeek
                })
                weekDay++;
            }
            return month;
        }

        //******************************************************************************************
        // METODOS DE CALENDARIO PARA FECHAS REALES
        //******************************************************************************************

        /**
        * @param year (opcional) en caso de omitir, el año será el actual
        * @param labelMonthNamePrefix (opcional) el prefijo de nombre se añade en la propiedad Name del objeto devuelto
        * @param labelMonthNameSufix (opcional) el prefijo de nombre se añade en la propiedad Name del objeto devuelto
        * @param addMonthName (opcional) Agrega en la propiedad Name, el nombre real del mes
        */
        private BuildRealYear(year?: number, labelMonthNamePrefix = "", labelMonthNameSufix = "", addMonthName = true): ICGB_Year {
            let months: Array<ICGB_Month> = [];
            for (let indexMonth = 1; indexMonth <= 12; indexMonth++) {
                months.push(this.BuildYearMonth(<TCGB_ValidMonths>indexMonth, year, labelMonthNamePrefix, labelMonthNameSufix, addMonthName));
            }
            return {
                NYear: months[0].Year,
                Months: months
            };
        }

        /** Crea un mes real
         * @param nMonth 1 = "Enero", ...
         * @param year (opcional) por defecto es el Año actual
         * @param labelMonthNamePrefix (opcional) el prefijo de nombre se añade en la propiedad Name del objeto devuelto
         * @param addMonthName (opcional) Agrega en la propiedad Name, el nombre real del mes
        */
        private BuildYearMonth(nMonth: TCGB_ValidMonths, year?: number, labelMonthNamePrefix = "", labelMonthNameSufix = "", addMonthName = true): ICGB_Month {
            if (nMonth < 1 || nMonth > 12) {
                return null;
            }

            let dateMonth = this.GeInitMonthDate(nMonth, year);
            const totalDays = dateMonth.getDate();
            let month = <ICGB_Month>{
                NMonth: nMonth,
                Days: [],
                IsEnable: true,
                Name: labelMonthNamePrefix + (addMonthName ? UIUtilTime._GetYearMonths()[nMonth - 1] : "") + labelMonthNameSufix,
                Year: dateMonth.getFullYear()
            }

            let nWeek = 1;
            for (let d = 1; d <= totalDays; d++) {
                dateMonth.setDate(d);
                if (d != 1 && dateMonth.getDay() == 0) nWeek++;
                month.Days.push({
                    Name: d + "",
                    WeekDay: dateMonth.getDay(),
                    MonthDay: d,
                    YearDay: this.GetDayIntoYear(dateMonth),
                    IsEnable: true,
                    NMonthWeek: nWeek
                    // IsInRange: false,
                })
            }
            return month;
        }

        private GeInitMonthDate(nMonth: number, year?: number): Date {
            let date = new Date();
            if (year) date.setFullYear(year);
            date.setDate(1);
            date.setMonth(nMonth);
            date.setDate(0);
            return date;
        }

        /** Devuelve el numero de día del año */
        private GetDayIntoYear(date: Date): number {
            return (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) - Date.UTC(date.getFullYear(), 0, 0)) / 24 / 60 / 60 / 1000;
        }

        /** @returns date ? Number(String(yyyy + mm + dd)) : (type == "min" ? 0 : 99999999) */
        private GetDateEvaluator(type: "min" | "max", date?: Date): number {
            if (date) {
                const yyyy = date.getFullYear().toString();
                const mm = (date.getMonth() + 1) < 10 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
                const dd = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
                return Number(yyyy + mm + dd);
            }
            return (type == "min" ? 0 : 99999999);
        }
    }
}
