import * as d3 from "d3";
import { group as d3Group } from "d3-array";
import { MainPage } from "../../MainPage";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import { ICatalogoSATFormaPago } from "../../data/entidad/Factura";
import { _AltaMetodoPagoElectronico, _AltaMetodoPagoNoElectronico, _EditarMetodoPagoElectronico, _EditarMetodoPagoNoElectronico, _ObtenerFormaPagoSATByMetodoPago } from "../../data/modulo/FinanzaMetodoPago";
import { DataUtil } from "../../data/util/Util";
import _L from "../../util/Labels";
import { IConfigGridExcelExport, IGridExtraTableConfig, IGridRenderInfo, VentanaGrid } from "../controlD3/AVentanaGrid";
import { ExcelThings } from "../controlD3/ExcelExport";
import { Fields, FormGenerator } from "../controlD3/Formulario";
import { Table } from "../controlD3/Tabla";
import { UIUtilLang } from "../util/Language";
import { UIUtilMask } from "../util/Mask";
import { UIUtilPermission } from "../util/Permission";
import { UIUtilGeneral } from "../util/Util";

import IMetodoPago = Entidad.IFinanzaMetodoPago;

type ICatalogoSATFormaPagoForm = ICatalogoSATFormaPago & {
    full_name: string
}

interface IMetodoPagoOpenPay extends IMetodoPago {
    /** OpenPay */
    IdMetodoPagoElectronico: string;
    PublicKey: string;
    PrivateKey: string;
    // ElectronicPayMethodProvider: CProveedorMetodoPagoElectronico;
}

import CProveedorMetodoPagoElectronico = Entidad.CProveedorMetodoPagoElectronico;

export class UIVentanaFinanzasMetodosPago extends VentanaGrid<IMetodoPago> {
    constructor(content: d3.Selection<HTMLDivElement, any, HTMLElement, any>, modulo: Entidad.CModulo) {
        super(content, modulo);
    }

    // ***********************************************************************
    // Get data
    // ***********************************************************************

    protected GRID_GetDataRequestID(): DataModuloMain.TipoRequestMonitorId {
        return Entidad.CTipoRequest.FinanzaMetodoPago;
    }

    // ***********************************************************************
    // Table config
    // ***********************************************************************

    protected GRID_GetTableConfigBase() {
        return <IGridRenderInfo<IMetodoPago>>{
            IdTabla: "FinanzasCatalogos-MetodosPago",
            DefaultSort: "Nombre",
            IdData: "IdMetodoPago",
            MinWidth: 600,
            Columns: [
                { Field: "Nombre", Label: "Nombre", MinWidth: "250px", Width: "50%" },
                { Field: "NombreEscuela", Label: "Escuela", MinWidth: "200px", Width: "40%" },
            ]
        }
    }

    protected GRID_GetTableConfigAdvanced() {
        return <IGridExtraTableConfig<IMetodoPago>>{
            EvaluatorAndSubLevelsBuild: {
                OnStepCellTable: (container, datum, field: keyof IMetodoPago) => {
                    if (field == "Nombre") {
                        if (datum.ProveedorElectronico == CProveedorMetodoPagoElectronico.OpenPay && !datum.ModoProduccion) {
                            let span = container.select("pre");
                            if (!span.node()) {
                                span = container.append("pre")
                                    .style("padding", "2px")
                                    .style("font-size", "calc(var(--fontsize) - 3px)")
                                    .style("border-radius", "var(--border_radius_base)")
                                    .style("background-color", "var(--color_app_orange1)")
                                    .text(this.VB_GetUIStringModule("noproductivo"))
                            }
                        }
                        else {
                            container.select("pre").remove();
                        }
                    }
                }
            }
        }
    }

    protected GRID_GetFilters(): Array<Table.IParametroFiltro<IMetodoPago>> {
        return [
            { Label: "Nombre", Field: "Nombre" },
            // {
            //     Label: "Modo productivo", Field: "ModoProduccion",
            //     Type: "select",
            //     Options: [
            //         { Name: UIUtilLang.fn_GetUIString("general", "afirma"), Id: true },
            //         { Name: UIUtilLang.fn_GetUIString("general", "niega"), Id: false }
            //     ]
            // }
        ]
    }

    protected GRID_GetMenuTopGrid(): Table.ITableMenuTopDefaultOptionConfig[] {
        let opciones: Table.ITableMenuTopDefaultOptionConfig[] = [];

        if (this.GridHasPermisoAccion(Entidad.CAccionPermiso.Agregar)) {
            opciones.push({
                Label: "Agregar",
                Callback: () => this.OpenModal_FormAgregarMetodoPagoNoElectronico()
            })

            let escuelasConPermisoDeModulo = this.GridGetEscuelasConPermisoDeAccion(Entidad.CAccionPermiso.Agregar)
                .filter(d => UIUtilPermission._HasFinanzasModulesPermissionFromSchools(d.IdKinder));

            if (escuelasConPermisoDeModulo.length) {
                opciones.push({
                    Label: "action_confmtdopagoelectr",
                    Callback: () => this.OpenModal_FormAgregarMetodoPagoElectronico()
                })
            }
        }

        return opciones;
    }

    protected GRID_GetSelectionDataMenuV2(menuLocation: "row" | "top-selected", dataGridSelected: IMetodoPago[]): Table.ITableMenuDataSelectedOptionConfig<IMetodoPago>[] {
        let opciones: Array<Table.ITableMenuDataSelectedOptionConfig<IMetodoPago>> = [];

        if (dataGridSelected[0].ProveedorElectronico == CProveedorMetodoPagoElectronico.OpenPay) { // && this.GridHasPermisoAccion(data.Entidades.CAccionPermiso.Editar)) {
            opciones.push({
                Label: "Editar",
                MultiData: false,
                Callback: (dato) => {
                    this.OpenModal_FormEditarMetodoPagoElectronico(dato[0]);
                }
            })
        }
        if (DataModuloMain._GetDataValueFieldByName("Escuela", dataGridSelected[0].IdEscuela, "UsaFactura") && dataGridSelected[0].ProveedorElectronico == 0 && DataUtil._Usuario._PerfilAdmin) {
            opciones.push({
                Label: "Editar",
                MultiData: false,
                Callback: (dato) => {
                    this.OpenModal_FormAgregarMetodoPagoNoElectronico(dato[0]);
                }
            })
        }

        return opciones;
    }

    // ***********************************************************************
    // Formulario things
    // ***********************************************************************

    private OpenModal_FormAgregarMetodoPagoNoElectronico(metodoPago?: IMetodoPago) {
        const idMetodoPago = metodoPago?.IdMetodoPago
        const action = idMetodoPago ? Entidad.CAccionPermiso.Editar : Entidad.CAccionPermiso.Agregar;
        const actionEsAgregar = action == Entidad.CAccionPermiso.Agregar
        const escuelasList: Entidad.IEscuela[] = this.GridGetEscuelasConPermisoDeAccion(action, metodoPago?.IdEscuela || undefined)
        const initData = <IMetodoPago>(metodoPago || {
            IdEscuela: (escuelasList.length == 1 ? escuelasList[0].IdKinder : null),
        })
        this.GridOpenModal_ActionFormToAGridData({
            Action: action,
            Width: 400,
            GetForm: () => {
                const form = new FormGenerator<IMetodoPago>()
                const fnLoadOrChangeSchool = () => {
                    const usaFactura = DataModuloMain._GetDataValueFieldByName("Escuela", form._Data.IdEscuela, "UsaFactura")
                    const formaPagoSATControl = form._GetModelControl<"selectMaterial">("IdFormaPagoSAT")
                    const formaPagoListPrommise = this.SvObtenerFormaPagoSATByMetodoPago(form._Data.IdEscuela, idMetodoPago)
                    formaPagoSATControl.row.classed("hide", !usaFactura)
                    formaPagoSATControl.instance._UpdateList(formaPagoListPrommise)
                }
                form._Crear({
                    LabelMaxWidth: 100,
                    OnAssignData: () => fnLoadOrChangeSchool(),
                    schema: [
                        {
                            model: "Nombre", type: Fields.input, labelText: _L("finanzasmetodospago.d_field_nombre"),
                            inputAttr: {
                                required: true,
                                disabled: !actionEsAgregar,
                                removeBorder: !actionEsAgregar,
                            },
                        },
                        {
                            model: "IdEscuela", type: Fields.selectMaterial, labelText: _L("finanzasmetodospago.d_field_nombreescuela"),
                            selectMaterialAttr: {
                                valueMember: "IdKinder", displayMember: "Nombre", required: true,
                                Data: escuelasList,
                                disabled: !actionEsAgregar,
                                removeBorder: !actionEsAgregar,
                                onChange: () => fnLoadOrChangeSchool()
                            },
                        },
                        {
                            model: "IdFormaPagoSAT", type: Fields.selectMaterial, labelText: _L("finanzasmetodospago.d_field_idformapagosat"),
                            selectMaterialAttr: {
                                required: true,
                                valueMember: "key",
                                displayMember: "full_name",
                                Data: this.SvObtenerFormaPagoSATByMetodoPago(null, idMetodoPago),
                                onChange: (_, item: ICatalogoSATFormaPago) => {
                                    form._DataOrigin.NombreFormaPagoSAT = item.name
                                }
                            },
                        }
                    ],
                }, initData)
                return form
            },
            OnAccept: (form) => {
                if (actionEsAgregar) {
                    return _AltaMetodoPagoNoElectronico(form._Data)
                }
                return _EditarMetodoPagoNoElectronico(idMetodoPago, form._GetModelValue("IdFormaPagoSAT"), form._DataOrigin.NombreFormaPagoSAT)
            },
        })
    }

    private OpenModal_FormAgregarMetodoPagoElectronico() {
        let escuelasConPermisoAccion = this.GridGetEscuelasConPermisoDeAccion(Entidad.CAccionPermiso.Agregar);
        const metodoelEctronicoInicial = CProveedorMetodoPagoElectronico.OpenPay;

        let initDataForm = <IMetodoPagoOpenPay>{
            Nombre: CProveedorMetodoPagoElectronico[metodoelEctronicoInicial],
            ProveedorElectronico: metodoelEctronicoInicial
        }

        if (!escuelasConPermisoAccion.length) {
            this.notificacion._Mostrar(UIUtilLang._GetUIString("permission", "notif_noschoolspermission"), "ADVERTENCIA");
            return;
        }

        escuelasConPermisoAccion = escuelasConPermisoAccion.filter(d => UIUtilPermission._HasFinanzasModulesPermissionFromSchools(d.IdKinder));

        if (!escuelasConPermisoAccion.length) {
            this.notificacion._Mostrar(UIUtilLang._GetUIString("permission", "notif_noschoolscanusemodule"), "ADVERTENCIA");
            return;
        }

        escuelasConPermisoAccion = escuelasConPermisoAccion.filter(dEscuela => {
            let itemFound = DataModuloMain._GetReqDataArrayByName("FinanzaMetodoPago")
                .find(dMdoPago => {
                    return (dMdoPago.ProveedorElectronico == CProveedorMetodoPagoElectronico.OpenPay && dMdoPago.IdEscuela == dEscuela.IdKinder)
                })

            if (itemFound) {
                console.debug(itemFound.NombreEscuela, "Ya tiene proveedor electrónico openpay");
            }
            return !Boolean(itemFound);
        })

        if (!escuelasConPermisoAccion.length) {
            this.notificacion._Mostrar(this.VB_GetUIStringModule("notif_allschoolshaveopenpayconf"), "ADVERTENCIA");
            return;
        }

        this.GridOpenModal_ActionFormToAGridData({
            Action: Entidad.CAccionPermiso.Agregar,
            Title: "frm2_confmtdopagoelectr",
            AccionToHttpMessage: "agregarmetodopagoelectronico",
            GetForm: () => this.GetFormMetodoPagoElectronico(escuelasConPermisoAccion, initDataForm, true),
            AutoReloadGridRequestOnFinally: false,
            OnAccept: (form) => {
                return this.SvRegistrarMetodoPagoElectronico(form._Data)
            },
        });
    }

    private OpenModal_FormEditarMetodoPagoElectronico(metodoPagoData: IMetodoPago) {
        if (!metodoPagoData.Configuracion?.length) {
            this.notificacion._Mostrar(this.VB_GetUIStringModule("notif_configincomplete"), "ADVERTENCIA");
        }
        let configuracion = metodoPagoData.Configuracion || [];

        let initDataForm = <IMetodoPagoOpenPay>{
            ...metodoPagoData,
            ... {
                IdMetodoPagoElectronico: configuracion[0] || "",
                PrivateKey: configuracion[1] || "",
                PublicKey: configuracion[2] || ""
            }
        }

        // NOTE PROXIMA IMPLEMENTACIÓN: Agregar prop de permiso de acción
        this.GridOpenModal_ActionFormToAGridData({
            Title: "frm2_confmtdopagoelectr_edit",
            // Action: null,
            AccionToHttpMessage: "editarmetodopagoelectronico",
            GetForm: () => this.GetFormMetodoPagoElectronico(this.kinders, initDataForm, false),
            AutoReloadGridRequestOnFinally: false,
            OnAccept: (form) => this.SvEditarMetodoPagoElectronico(form._Data)
        })
    }

    private GetFormMetodoPagoElectronico(escuelas: Entidad.IEscuela[], dataForm: IMetodoPagoOpenPay, escuelaEditable: boolean): FormGenerator<IMetodoPagoOpenPay> {
        const idMetodoPago = dataForm?.IdMetodoPago
        const form = new FormGenerator<IMetodoPagoOpenPay>()
        const fnLoadOrChangeSchool = () => {
            const usaFactura = DataModuloMain._GetDataValueFieldByName("Escuela", form._Data.IdEscuela, "UsaFactura")
            const formaPagoSATControl = form._GetModelControl<"selectMaterial">("IdFormaPagoSAT")
            const formaPagoListPrommise = this.SvObtenerFormaPagoSATByMetodoPago(form._Data.IdEscuela, idMetodoPago)
            formaPagoSATControl.row.classed("hide", !usaFactura)
            formaPagoSATControl.instance._UpdateList(formaPagoListPrommise)
        }
        form._Crear({
            OnAssignData: () => setTimeout(fnLoadOrChangeSchool),
            schema: [
                {
                    type: Fields.input, model: "Nombre", labelText: _L("finanzasmetodospago.frm2_nombre"),
                    inputAttr: { disabled: true, required: true },
                },
                {
                    type: Fields.selectMaterial, model: "IdEscuela", labelText: _L("finanzasmetodospago.frm2_escuela"),
                    values: escuelas,
                    selectMaterialAttr: {
                        valueMember: "IdKinder", displayMember: "Nombre",
                        required: true,
                        Data: escuelas,
                        disabled: !escuelaEditable,
                        removeBorder: !escuelaEditable,
                        onChange: () => fnLoadOrChangeSchool(),
                    },
                },
                {
                    type: Fields.input, model: "PrefijoPago", labelText: _L("finanzasmetodospago.frm2_prefijo"),
                    inputAttr: { placeholder: "PREFI", maxlength: 5, minlength: 1, required: true },
                },
                {
                    type: Fields.input, model: "IdMetodoPagoElectronico", labelText: _L("finanzasmetodospago.frm2_id"),
                    inputAttr: { required: true },
                },
                {
                    type: Fields.input, model: "PrivateKey", labelText: _L("finanzasmetodospago.frm2_privatekey"),
                    inputAttr: { required: true },
                },
                {
                    type: Fields.input, model: "PublicKey", labelText: _L("finanzasmetodospago.frm2_publickey"),
                    inputAttr: { required: true },
                },
                {
                    model: "IdFormaPagoSAT", type: Fields.selectMaterial, labelText: _L("finanzasmetodospago.d_field_idformapagosat"),
                    selectMaterialAttr: {
                        required: true,
                        valueMember: "key",
                        displayMember: "name",
                        Data: this.SvObtenerFormaPagoSATByMetodoPago(null, idMetodoPago),
                        OnStepItemListUI: (cont, d: ICatalogoSATFormaPago) => cont.text(d.key + " - " + d.name),
                        onChange: (_, item: ICatalogoSATFormaPago) => {
                            form._DataOrigin.NombreFormaPagoSAT = item.name
                        }
                    }
                }
            ],
            BuildView: (container, controlsForm, form) => {
                let fn_ValidateKeysDownOfInputElement = (model: keyof IMetodoPagoOpenPay) => {
                    (form._ControlsData.get(model)
                        .selection
                        .node() as HTMLInputElement)
                        .onkeydown = e => {
                            return (e.key != ",") //  && e.key != ".")
                        }
                }

                container.append(() => controlsForm.get("Nombre").row.node());
                container.append(() => controlsForm.get("IdEscuela").row.node());

                let prefixControl = controlsForm.get("PrefijoPago");
                container.append(() => prefixControl.row.node());
                let prefixInput = prefixControl.selection as d3.Selection<HTMLInputElement, any, any, any>;
                prefixInput
                    .style("width", "40%")
                    .style("min-width", "100px");
                // .style("text-align", "end");
                UIUtilMask._ApplyUpperCaseMask(prefixInput, false);
                // let prefixInputWrapper =
                d3.select(prefixControl.selection.node().parentElement)
                    .classed(UIUtilGeneral.FBoxAlign.StartCenter, true)
                    .append("label")
                    .style("padding-left", "5px")
                    .text("-000001");

                container.append(() => controlsForm.get("IdMetodoPagoElectronico").row.node());
                container.append(() => controlsForm.get("PrivateKey").row.node());
                container.append(() => controlsForm.get("PublicKey").row.node());

                fn_ValidateKeysDownOfInputElement("IdMetodoPagoElectronico");
                fn_ValidateKeysDownOfInputElement("PrefijoPago");
                fn_ValidateKeysDownOfInputElement("PrivateKey");
                fn_ValidateKeysDownOfInputElement("PublicKey");

                container.append(() => controlsForm.get("IdFormaPagoSAT").row.node());
            },
            Validation: (value, field, dataForm, controls) => {
                //return Boolean(value) && Boolean(String(value).trim()) && !/[,]/.test(String(value)); // NOTE any string keys, except comas // & points
                return !/[,]/.test(String(value)); // NOTE any string keys, except comas // & points
            },
            LabelMaxWidth: 160
        }, dataForm);

        return form
    }

    protected GRID_GetExportarConfig(dataGrid: IMetodoPago[]): IConfigGridExcelExport<IMetodoPago> {
        return {
            IdsEscuelas: [...new Set(dataGrid.map(d => d.IdEscuela))],
            ColumnsConfig: this.ctrlTabla
                ._InfoColumns
                .map<ExcelThings.IColumnToExcelExportFileConfig<IMetodoPago>>((d) => ({
                    Field: d.Field as keyof IMetodoPago,
                    HeaderTag: d.Label,
                    WidthCell: 25
                })),
            OnGetDataBySheets: async () => {
                return Array.from(d3Group(dataGrid, d => d.IdEscuela))
                    .map<ExcelThings.ISheetConfig<IMetodoPago>>(entrie => ({
                        IdSheet: entrie[0], // IdEscuela
                        SheetName: entrie[1][0].NombreEscuela,
                        Data: entrie[1]
                    }))
            },
            OnGetEscuelasTagInSheet: (dataSheet) => dataSheet[0].NombreEscuela,
            OnStepFieldInDataRow: (field, dato) => {
                switch (field) {
                    case "ModoProduccion":
                        return UIUtilLang._GetUIString("general", dato.ModoProduccion ? "afirma" : "niega");
                    default:
                        return dato[field] as any;
                }
            }
        }
    }

    // ***********************************************************************
    // Data final things
    // ***********************************************************************

    /* private async SvRegistrarMetodoPago(dato: IMetodoPago) {
        let res = await DataModuloFinanzaMetodoPago._AltaMetodoPagoNoElectronico(dato);
        if (res.Resultado > 0) {
            MainPage._ReloadService(this.GRID_GetDataRequestID(), dato.IdEscuela);
        }
        return res;
    } */

    private async SvRegistrarMetodoPagoElectronico(dato: IMetodoPagoOpenPay) {
        let config = [dato.IdMetodoPagoElectronico, dato.PrivateKey, dato.PublicKey];
        let res = await _AltaMetodoPagoElectronico(dato, dato.ProveedorElectronico, config);
        if (res.Resultado > 0) {
            MainPage._ReloadService(this.GRID_GetDataRequestID(), dato.IdEscuela);
        }
        return res;
    }

    private async SvEditarMetodoPagoElectronico(dato: IMetodoPagoOpenPay) {
        let config = [dato.IdMetodoPagoElectronico, dato.PrivateKey, dato.PublicKey];
        let res = await _EditarMetodoPagoElectronico(dato, config);
        if (res.Resultado > 0) {
            MainPage._ReloadService(this.GRID_GetDataRequestID(), dato.IdEscuela);
        }
        return res;
    }

    private SvObtenerFormaPagoSATByMetodoPago(idEscuela: number, idMetodoPago: number): Promise<ICatalogoSATFormaPagoForm[]> {
        return _ObtenerFormaPagoSATByMetodoPago(idEscuela, idMetodoPago)
            .then(res => res.map(mp => ({ ...mp, full_name: `${mp.key} - ${mp.name}` })))
    }
}
