import { Entidad } from "../../data/Entidad";
import { UIUtilLang } from "../util/Language";
import { CalendarioGridBase } from "./CalendarioGridBase";

export namespace CalendarioGridMaterias {
    enum CViewMode { Months = 1, MonthsPlus }

    type TCalendarGridNMesesView = 0 | 1 | 2 | 3 | 4 | 6;

    type CPeriodicidadMateria = Entidad.CMateriaFrecuenciaEvaluacion;

    const CPeriodicidadMateria = { ...Entidad.CMateriaFrecuenciaEvaluacion }

    export interface ICalendarioGridMateriaConfig {
        Periodicidad: CPeriodicidadMateria;
        /** Cantidad de meses visibles en la primer vista, en caso de ser 0 no muestra la vista */
        NMesesEnVistaInicial: TCalendarGridNMesesView;
        ContainerToRedimention: d3.Selection<HTMLDivElement, any, HTMLElement, any>;
        BloqueIniciar: number;
        DiaCalificacion: number;
        DiasCalificar: number;
        OnChangeSomeSelector: (resultSelectors: IResultSelectors) => void;
    }

    interface IResultSelectors {
        Aplicacion: IResSel;
        Vencimiento: IResSel;
    }

    interface IResSel {
        Month?: number;
        Days: number;
    }

    enum CTypeSelector {
        Aplicacion = "Aplicación",
        Vencimiento = "Vencimiento"
    }

    export class CalendarioGridMaterias extends CalendarioGridBase.CalendarGridBase<"DataBase", CTypeSelector, IResSel> {
        private config: ICalendarioGridMateriaConfig;
        private currentViewMode: CViewMode = CViewMode.Months;
        private isAnualView: boolean;
        private btnToggle: d3.Selection<HTMLDivElement, any, HTMLElement, any>;
        private areaTitle: d3.Selection<HTMLDivElement, any, HTMLElement, any>;

        /* selector actual en arrastre */
        private selectorDrag: CalendarioGridBase.ICGB_SelectorMap<CTypeSelector, IResSel>["DataBase"];

        constructor(parent: d3.Selection<HTMLDivElement, any, HTMLElement, any>, config: Omit<ICalendarioGridMateriaConfig, keyof Pick<ICalendarioGridMateriaConfig, "Periodicidad" | "NMesesEnVistaInicial">>) {
            super({
                Parent: parent,
                TypeSelector: "DataBase",
                Selectors: [
                    {
                        Id: CTypeSelector.Aplicacion,
                        Date: 1,
                        Days: 1,
                        Month: 1,
                        PriorityWhenMerging: 1,
                        IdMaxSelector: CTypeSelector.Vencimiento,
                        Class: "sel_1",
                        InitColor: "green"
                    },
                    {
                        Id: CTypeSelector.Vencimiento,
                        Date: 1,
                        Days: 0,
                        Month: 1,
                        IdMinSelector: CTypeSelector.Aplicacion,
                        PriorityWhenMerging: 1,
                        Class: "sel_2",
                        InitColor: "red"
                    }
                ]
            });

            this.InitDraw();
            this.UpdateConfigAndView(config as ICalendarioGridMateriaConfig);
        }

        set _Periodicidad(periodicidad: CPeriodicidadMateria) {
            this.config.Periodicidad = periodicidad;
            this.config.NMesesEnVistaInicial = this.GetNMonthsToView(periodicidad);
            this.UpdateConfigAndView(this.config);
            this.UpdateTitle();
        }

        get _Periodicidad(): CPeriodicidadMateria {
            return this.config.Periodicidad;
        }

        private InitDraw(): void {
            let subHeader = this.CGBcontrolContainer.select<HTMLDivElement>(".sub_header")
                .style("justify-content", "space-between");

            this.areaTitle = subHeader.append("div")
                .classed("periodicidad", true);

            this.btnToggle = subHeader.append("div")
                .classed("accion_item", true);
        }

        private UpdateConfigAndView(config: ICalendarioGridMateriaConfig) {
            const evalIsExtended = () => {
                // Evaluar cuando anual pueda ser toggeble 
                if (config.Periodicidad == CPeriodicidadMateria.Anual) return false;
                //
                let NMesesPeriodicidad = this.GetNMonthsToView(this.config.Periodicidad);
                if (NMesesPeriodicidad < this.config.BloqueIniciar) return true;
                if ((((this.config.BloqueIniciar - 1) * 30) + this.config.DiaCalificacion + this.config.DiasCalificar - 1) > NMesesPeriodicidad * 30) return true;
                return false;
            }
            const defaultConfig = <ICalendarioGridMateriaConfig>{
                NMesesEnVistaInicial: 1
            }
            this.config = { ...defaultConfig, ...config };
            this.isAnualView = this.config.NMesesEnVistaInicial < 1;

            this.currentViewMode = evalIsExtended() ? CViewMode.MonthsPlus : CViewMode.Months;

            this.btnToggle.node().onclick = null;
            this.btnToggle.classed("hide", true);
            // Esto es cuando (no es anual !== 0) Ni es semanal 
            if (!this.isAnualView && this.config.Periodicidad != CPeriodicidadMateria.Semanal) {
                this.btnToggle.classed("hide", false);
                this.btnToggle.text(this.currentViewMode == CViewMode.MonthsPlus ? UIUtilLang._GetUIString("materias", "tag_vervistanormal") : UIUtilLang._GetUIString("materias", "tag_verampliado"));

                this.btnToggle.node().onclick = (e) => {
                    this.OnToggleCalendarMode();
                    this.btnToggle.text(this.currentViewMode == CViewMode.MonthsPlus ? UIUtilLang._GetUIString("materias", "tag_vervistanormal") : UIUtilLang._GetUIString("materias", "tag_verampliado"));
                }
            }
            this.UpdateAndDrawMode();
        }

        private UpdateTitle() {
            this.areaTitle.text(UIUtilLang._GetUIString("materias", "tag_frecuencia") + ": " + this.GetFrecuenciaKey(this.config.Periodicidad));
        }

        // Deberá de buscarse la manera para que en lugar de que cambie de meses a año qu cambie de NMeses a NMeses + 1;
        private OnToggleCalendarMode() {
            this.currentViewMode = this.currentViewMode == CViewMode.Months ? CViewMode.MonthsPlus : CViewMode.Months;
            this.UpdateAndDrawMode();
        }

        private UpdateAndDrawMode() {

            const nMonths = (this.currentViewMode == CViewMode.Months) ? this.config.NMesesEnVistaInicial : this.config.NMesesEnVistaInicial + 1;
            switch (nMonths) {
                case 1:
                    this.CGBUpdateAreaCalendarGrid()
                        .RedimContainer(500);
                    break;
                case 2:
                    this.CGBUpdateAreaCalendarGrid(2)
                        .RedimContainer(700);
                    break;
                case 3:
                    this.CGBUpdateAreaCalendarGrid(3)
                        .RedimContainer(860);
                    break;
                case 4:
                    this.CGBUpdateAreaCalendarGrid(2, 2)
                        .RedimContainer(700);
                    break;
                case 6:
                    this.CGBUpdateAreaCalendarGrid(3, 2)
                        .RedimContainer(800)
                    break;
                case 0:
                    this.CGBUpdateAreaCalendarGrid(4, 3)
                        .RedimContainer(1100);
                    break;
            }
            this.BuildMonthView(nMonths == 0 ? 12 : nMonths);
        }

        private RedimContainer(width: number) {
            if (this.config.ContainerToRedimention) {
                this.config.ContainerToRedimention.style("width", width + "px");
            }
        }

        private BuildMonthView(nMonths: number) {
            this.CGBcontrolContainer.select(".body").select(".area_calendar_grid").selectAll(".month").remove();
            this.CGBcreator.BuildManyBaseMonths(nMonths).forEach(month => {
                this.CGBDrawOrUpdateGridMonth(month);
            })
            this.CGBUpdateAllSelectorsPosition();
            this.CGBcontrolContainer.selectAll(".name_days").classed("hide", true);
            return this;
        }

        protected CGBOnStepMonthCell(datum: CalendarioGridBase.ICGB_DateCell, month: CalendarioGridBase.ICGB_Month, divCell: d3.Selection<HTMLDivElement, CalendarioGridBase.ICGB_DateCell, HTMLElement, any>, divSelectors: d3.Selection<HTMLDivElement, any, HTMLElement, any>, dateCell: Date, typeUpdate: "enter" | "update" | "exit"): void {
            divCell.node().ondragenter = null;
            divCell.node().ondragover = null;

            if (typeUpdate !== "exit") {
                divCell.node().ondragenter = e => {
                    this.CGBUpdateSelectorPosition(CTypeSelector.Aplicacion);

                    // LIMITE (LÓGICO) DE MOVILIDAD DEL SELECTOR DE APLICACIÓN
                    if (this.selectorDrag.MinSelectorData) {
                        if (datum.YearDay < this.selectorDrag.MinSelectorData.Date) {
                            return
                        }
                    }
                    if (this.selectorDrag.MaxSelectorData) {
                        if (datum.YearDay > this.selectorDrag.MaxSelectorData.Date) {
                            return
                        }
                    }

                    //El control de cargos evalua que la fecha de aplicación no sobrepase la periodicidad es decir Ej. [Bimestral] no puede pasar de 60 dias

                    if (this.selectorDrag.Container) {
                        this.selectorDrag.Date = datum.YearDay;
                        this.selectorDrag.Days = datum.MonthDay;
                        this.selectorDrag.Month = month.NMonth;

                        divSelectors.append(() => this.selectorDrag.Container.node());
                        this.CGBUpdateSelectoresPriority();
                        if (this.config.OnChangeSomeSelector) {
                            this.config.OnChangeSomeSelector(this.GetSelectedDates());
                        }
                    }
                }
            }
        }

        protected CGBOnCreateSelectorContainer(selector: CalendarioGridBase.ICGB_SelectorMap<CTypeSelector, IResSel>["DataBase"]) {
            let areaCalendar = this.CGBcontrolContainer.select(".area_calendar_grid");
            let contSelector = selector.Container
                .style("cursor", "move");

            // contSelector.style("position", "absolute");

            contSelector.node().draggable = true;

            // contSelector.node().ondrag = e => {}

            contSelector.node().ondragstart = e => {
                contSelector.classed("ondrag", true);
                this.selectorDrag = selector;

                // LIMITES (VIEW) DE MOVILIDAD DEL SELECTOR DE APLICACIÓN
                if (selector.MinSelectorData) {
                    for (let i = selector.MinSelectorData.Date - 1; i > 0; i--) {
                        areaCalendar.select("#cell_" + i)
                            .classed("cell_disabled", true);
                    }
                }

                let startMaxCellToDisable = selector.MaxSelectorData ? selector.MaxSelectorData.Date : 360;
                /* if (this.selectorDrag.Id == CTypeSelector.Aplicacion) {
                    if (this.config.Periodicidad == CPeriodicidadCargos.Unico) {
                        startMaxCellToDisable = 1;
                    } else {
                        let maxPeriodoDate = 30 * Entidad.PeriodicidadNMeses[this.config.Periodicidad];
                        startMaxCellToDisable = startMaxCellToDisable < maxPeriodoDate ? startMaxCellToDisable : maxPeriodoDate;
                    }
                } */
                for (let i = startMaxCellToDisable + 1; i < 361; i++) {
                    areaCalendar.select("#cell_" + i)
                        .classed("cell_disabled", true);
                }
                // *****************************************************
            }

            contSelector.node().ondragend = e => {
                contSelector.classed("ondrag", false);
                areaCalendar.selectAll(".cell")
                    .classed("cell_disabled", false);
                this.selectorDrag = null;
            }
        }

        private GetSelectedDates(): IResultSelectors {
            return {
                Aplicacion: {
                    Days: this.CGBdateSelectors.get(CTypeSelector.Aplicacion).Days,
                    Month: this.CGBdateSelectors.get(CTypeSelector.Aplicacion).Month
                },
                Vencimiento: {
                    Days: this.CGBdateSelectors.get(CTypeSelector.Vencimiento).Date - this.CGBdateSelectors.get(CTypeSelector.Aplicacion).Date,
                    // Month: this.CGBdateSelectors.get(CTypeSelector.Vencimiento).Month
                }
            }
        }

        private GetNMonthsToView(perdiodicidad: CPeriodicidadMateria): TCalendarGridNMesesView {
            switch (perdiodicidad) {
                case CPeriodicidadMateria.Mensual:
                case CPeriodicidadMateria.Semanal:
                case CPeriodicidadMateria.Libre:
                case CPeriodicidadMateria.Diario:
                    return 1;
                case CPeriodicidadMateria.Bimestral:
                    return 2;
                case CPeriodicidadMateria.Trimestral:
                    return 3;
                case CPeriodicidadMateria.Cuatrimestral:
                    return 4;
                case CPeriodicidadMateria.Semestral:
                    return 6;
                default:
                    return 0
            }
        }

        public _SetPosicionSelectores(mes = 1, aplicacion = 1, vencimiento = 1): void {
            this.CGBdateSelectors.get(CTypeSelector.Aplicacion).Days = aplicacion;
            this.CGBdateSelectors.get(CTypeSelector.Aplicacion).Month = mes;
            this.CGBdateSelectors.get(CTypeSelector.Aplicacion).Date = aplicacion + (30 * (mes - 1));
            this.CGBdateSelectors.get(CTypeSelector.Vencimiento).Date = this.CGBdateSelectors.get(CTypeSelector.Aplicacion).Date + vencimiento - 1;
            this.CGBUpdateAllSelectorsPosition();
        }

        public _GetResultSelected(): IResultSelectors {
            return this.GetSelectedDates();
        }

        private GetFrecuenciaKey = (tipoFrec: Entidad.CMateriaFrecuenciaEvaluacion) => {
            const keys = Object.keys(Entidad.CMateriaFrecuenciaEvaluacion) as Array<keyof typeof Entidad.CMateriaFrecuenciaEvaluacion>
            for (const key of keys) {
                if (Entidad.CMateriaFrecuenciaEvaluacion[key] == tipoFrec) {
                    return key;
                }
            }
            return null;
        }
    }
}
