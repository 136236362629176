import { ascending } from "d3-array";
import { ICatalogoSATPais, IFacturaCliente, IFacturaClienteAlumno } from "../../../data/entidad/Factura";
import { _ObtenerFCatalogoBanco, _ProcesaFacturaCatalogo } from "../../../data/modulo/FacturaCatalogo";
import { _ObtenerCatalogoSATPais, _ObtenerCatalogoSATRegimenFiscal, _ObtenerCatalogoSATUsoCFDI } from "../../../data/modulo/FacturaCatalogoSAT";
import { IClienteFacturaRegistrar, _SvFacturaAsignarClienteAlumno, _SvFacturaEliminarAsignacionClienteAlumno, _SvFacturaObtenerClientes, _SvFacturaObtenerClientesAlumno, _SvFacturaRegistrarCliente } from "../../../data/modulo/FacturaCliente";
import _L, { _HttpMsgV2 } from "../../../util/Labels";
import { Button } from "../../controlD3/Button";
import { DropdownFlexV2 } from "../../controlD3/DropdownFlexV2";
import { FormGenerator } from "../../controlD3/Formulario";
import { ModalThings } from "../../controlD3/ModalThings";
import { NotificacionV2 } from "../../controlD3/NotificacionV2";
import { HTMLCheckBoxElement } from "../../controlWC/CheckboxComponent";
import { UIUtilMask } from "../../util/Mask";
import { UIUtilStrings } from "../../util/Strings";
import { UIUtilGeneral } from "../../util/Util";
import { AlumnoEdoCtaFacturacionUtil as Util } from "./AlumnoEdoCtaFacturacionUtil";
import ic_options from '/image/iconos/ic_options.svg?raw';

const fieldsFormRegistrarClienteAux = {
    AgregarDatosExtra: true // boolean
}
type IFormRegistrarClienteAux = typeof fieldsFormRegistrarClienteAux
interface IFormRegistrarCliente extends IClienteFacturaRegistrar, IFormRegistrarClienteAux {
}

export namespace AlumnoEdoCtaFacturacionViewClientes {
    export interface ViewClientesAsignados {
        GetSelectedClient: () => IFacturaClienteAlumno,
        SelectClientRFC: (rfc: string) => IFacturaClienteAlumno,
        /** @default true */
        RefreshList: (force?: boolean) => void,
    }
    export function GenerarCFDI_ViewClientesAsignados(container: TSelectionHTML<"div">, mt: ModalThings.IModalThings, idEscuela: number, idAlumno: number): ViewClientesAsignados {
        let selectedClient: IFacturaClienteAlumno = null
        let clientesAlumno: IFacturaClienteAlumno[] = []
        let firstSuccessRequest = true
        container.style("min-height", "200px")
        const clientesListCtrl = Util._CreateListControl<IFacturaClienteAlumno>({ addMarginBottom: true })
            ._SetParent(container)
            ._SetUpdateItem((clienteContainer, cliente) => ViewClienteItemList(clienteContainer, cliente, selectedClient?.UID)
                .attr("id", "id" + cliente.UID)
                .style("position", "relative")
                .call((clienteContainer) => {
                    let optionsCont = clienteContainer.select<HTMLDivElement>(".btn_options")
                    let checkboxWrapper = clienteContainer.select<HTMLCheckBoxElement>(".checkbox_cont")
                    let clienteInfo = clienteContainer.select<HTMLDivElement>(".listitem_cont_info")
                    if (!optionsCont.node()) {
                        optionsCont = clienteContainer.append<HTMLDivElement>("div")
                            .attr("class", "btn_options btn_round")
                            .style("position", "absolute")
                            .style("right", "var(--padding2)")
                            .html(ic_options)
                    }
                    optionsCont.on("click", () => new DropdownFlexV2.Control({
                        Parent: optionsCont,
                        Items: [{
                            Label: _L("panelfinanzasedocuenta_factura.eliminar_asign"),
                            OnClick: () => fnOpenEliminarAsignacion(cliente),
                        }],
                    })._Show());
                    [checkboxWrapper, clienteInfo].forEach(element => element.on("click", () => {
                        asignSelectedClient(cliente)
                        clientesListCtrl._RefreshList()
                    }))
                }))
        container.append<HTMLDivElement>("div")
            .attr("class", `btn_wrapper ${UIUtilGeneral.FBoxOrientation.Horizontal}`)
            .style("padding", "var(--padding2) 0")
            .style("position", "absolute")
            .style("background-color", "var(--color_primary1)")
            .style("bottom", "-1px")
            .style("width", "100%")
            .call(buscarWrapper => {
                new Button(buscarWrapper, _L("panelfinanzasedocuenta_factura.asignar_cliente"))._d3Selection
                    .on("click", () => fnOpenAsignar())
            })
        const sinRegistrosComponent = Util._CreateSinRegistrosComponent(container)

        const fnRequestData = () => {
            mt.Progress.node()._Visible = true
            _SvFacturaObtenerClientesAlumno(idEscuela, idAlumno)
                .then(res => {
                    if (res.Resultado <= 0) {
                        const msgNoData = _HttpMsgV2(res)
                        NotificacionV2._Mostrar(msgNoData, "ADVERTENCIA")
                        sinRegistrosComponent._Visible(true)._SetText(msgNoData)
                        return
                    }
                    if (firstSuccessRequest && !clientesAlumno.length && !res.Datos?.length)
                        fnOpenAsignar()
                    if (firstSuccessRequest)
                        firstSuccessRequest = false

                    clientesAlumno = (res.Datos || [])
                        .sort((a, b) => ascending((a.Contacto.Nombre + a.Contacto.Apellidos), (b.Contacto.Nombre + b.Contacto.Apellidos)))

                    if (selectedClient && !clientesAlumno.find(c => c.RFC == selectedClient.RFC))
                        asignSelectedClient(null)
                    if (!selectedClient && clientesAlumno.length)
                        asignSelectedClient(clientesAlumno[0])
                    if (selectedClient)
                        fnSelectClientRFC(selectedClient.RFC)
                    sinRegistrosComponent._Visible(!clientesAlumno.length)._SetText(_L("panelfinanzasedocuenta_factura.alumno_sinclientes"))

                    clientesListCtrl._SetItems(clientesAlumno)
                })
                .finally(() => {
                    mt.Progress.node()._Visible = false
                })
        }
        const fnSelectClientRFC = (rfc: string) => {
            asignSelectedClient(null)
            const RFCLowerCase = rfc.toLowerCase()
            for (const cli of clientesAlumno) {
                if (cli.RFC.toLowerCase() == RFCLowerCase) {
                    asignSelectedClient(cli)
                    break
                }
            }
            clientesListCtrl._RefreshList()
            if (selectedClient)
                fnFocusElementByUID(selectedClient.UID)
            return selectedClient
        }
        const fnFocusElementByUID = (uid: string) => clientesListCtrl._ListContainerNode.querySelector("#id" + uid)
            ?.scrollIntoView({
                block: "nearest"
            })
        const asignSelectedClient = (cliente: IFacturaClienteAlumno) => {
            selectedClient = cliente
        }

        const fnOpenAsignar = () => OpenModal_AsignarClientesAsignar(idEscuela, idAlumno, (clienteAsignado) => {
            asignSelectedClient(clienteAsignado)
            fnRequestData()
        })
        const fnOpenEliminarAsignacion = (cliente: IFacturaClienteAlumno) =>
            ModalThings._GetConfirmacionBasicoV2({
                Title: _L("panelfinanzasedocuenta_factura.eliminar_asign"),
                Content: _L("panelfinanzasedocuenta_factura.eliminar_asign_confirma"),
                Width: 250,
            }).then(confirmacionRes => {
                if (!confirmacionRes) return
                mt.Progress.node()._Visible = true
                _SvFacturaEliminarAsignacionClienteAlumno(idAlumno, cliente.RFC, cliente.UID)
                    .then(res => {
                        NotificacionV2._Mostrar(_HttpMsgV2(res), (res.Resultado > 0 ? "INFO" : "ADVERTENCIA"))
                        if (res.Resultado > 0 || res.Resultado == -1)
                            setTimeout(fnRequestData)
                    })
                    .finally(() => {
                        mt.Progress.node()._Visible = false
                    })
            })
        fnRequestData()

        return {
            GetSelectedClient: () => selectedClient,
            RefreshList: (force = true) => {
                if (force)
                    fnRequestData()
                else if (!clientesAlumno.length)
                    fnRequestData()
            },
            SelectClientRFC: fnSelectClientRFC,
        }
    }
}

function OpenModal_AsignarClientesAsignar(idEscuela: number, idAlumno: number, callSuccess: (cliente: IFacturaCliente) => void) {
    let selectedClientAsignar: IFacturaCliente = null
    let clientesAsignar: IFacturaCliente[] = []
    ModalThings._GetModalToAProccess({
        Title: _L("panelfinanzasedocuenta_factura.asignar_cliente"),
        Width: 550,
        OnAccept: () => {
            if (!selectedClientAsignar) {
                console.warn("OpenModal_AsignarClientesAsignar -> No selected client")
                return null
            }
            return _SvFacturaAsignarClienteAlumno(idEscuela, idAlumno, selectedClientAsignar.RFC, selectedClientAsignar.UID)
                .then(res => {
                    if (res.Resultado > 0)
                        callSuccess(selectedClientAsignar)
                    return res;
                })
        },
        DrawContent: (container, mt) => {
            container.style("min-height", "200px")
            container.append<HTMLDivElement>("div")
                .attr("class", `top_wrapper ${UIUtilGeneral.FBoxOrientation.Horizontal}`)
                .style("padding", "var(--padding2) 0")
                .style("gap", "var(--padding1)")
                .style("position", "sticky")
                .style("top", "calc(-1 * var(--padding2))")
                .style("background-color", "var(--color_primary1)")
                .style("z-index", "1")
                .call(cont => {
                    cont.append<HTMLInputElement>("input")
                        .attr("class", "input-form")
                        .attr("type", "search")
                        .attr("placeholder", _L("general.buscar"))
                        .on("input", (_, i, elmts) => {
                            fnFiltrarClientes(elmts[i].value)
                        })
                    new Button(cont, _L("panelfinanzasedocuenta_factura.nuevo"))._d3Selection
                        .on("click", () => fnOpenRegistrar())
                })
            const clientesListCtrl = Util._CreateListControl<IFacturaCliente>()
                ._SetParent(container)
                ._SetUpdateItem((clienteContainer, cliente) => ViewClienteItemList(clienteContainer, cliente, selectedClientAsignar?.UID)
                    .on("click", (cliente) => {
                        fnSelectClientRFC(cliente)
                    }))
            const sinRegistrosComponent = Util._CreateSinRegistrosComponent(container)

            const fnRequestData = () => {
                mt.Progress.node()._Visible = true
                _SvFacturaObtenerClientes(idEscuela)
                    .then(res => {
                        if (res.Resultado <= 0) {
                            const msgNoData = _HttpMsgV2(res)
                            NotificacionV2._Mostrar(msgNoData, "ADVERTENCIA")
                            sinRegistrosComponent._Visible(true)._SetText(msgNoData)
                            return
                        }
                        clientesAsignar = (res.Datos || [])
                            .filter(cliente => !cliente.Alumnos.includes(idAlumno)) // Clientes no asignados
                            .sort((a, b) => ascending((a.Contacto.Nombre + a.Contacto.Apellidos), (b.Contacto.Nombre + b.Contacto.Apellidos)))
                        if (!clientesAsignar.length) {
                            fnOpenRegistrar()
                        }
                        sinRegistrosComponent._Visible(!clientesAsignar.length)._SetText(_L("panelfinanzasedocuenta_factura.sinclientes"))
                        clientesListCtrl._SetItems(clientesAsignar)
                    })
                    .finally(() => {
                        mt.Progress.node()._Visible = false
                    })
            }
            const fnSelectClientRFC = (cliente: IFacturaCliente) => {
                selectedClientAsignar = cliente
                clientesListCtrl._RefreshList()
                mt.BtnRight._Enable(true)
            }
            const fnFiltrarClientes = (strSearch: string) => {
                const camposBuscar: (keyof IFacturaCliente)[] = <(keyof IFacturaCliente)[]>[
                    "RFC", "UID", "RazonSocial",
                    "Calle", "Ciudad", "CodigoPostal", "Colonia", "Delegacion", "Estado", "Interior", "Localidad", "Pais",
                    "Contacto.Nombre" as any, "Contacto.Apellidos" as any, "Contacto.Email" as any, "Contacto.Telefono" as any,
                ]
                const clientesFiltrados: IFacturaCliente[] = []
                const strSearchFinal = UIUtilStrings._StringRemoveDiacriticMarks(strSearch.toLowerCase())
                for (const cliente of clientesAsignar) {
                    let strCliente = camposBuscar.reduce((res, campo) => {
                        const v = UIUtilGeneral._GetValueLevelsObj(campo, cliente)
                        return res + (v == null ? "" : " " + v)
                    }, "")
                    strCliente = UIUtilStrings._StringRemoveDiacriticMarks(strCliente)
                    if (strCliente.toLowerCase().includes(strSearchFinal)) {
                        clientesFiltrados.push(cliente)
                        continue
                    }
                }
                clientesListCtrl._SetItems(clientesFiltrados)
            }
            const fnOpenRegistrar = () => OpenModal_RegistrarCliente(idEscuela, idAlumno, (clienteRegistrado) => {
                mt.Modal._Ocultar()
                callSuccess(clienteRegistrado)
            })
            mt.BtnRight._Enable(false)
            fnRequestData()
        },
    })
}

function OpenModal_RegistrarCliente(idEscuela: number, idAlumno: number, callSuccessRecord: (clienteRegistrado: IFacturaCliente) => void) {
    type FormField = (keyof IFormRegistrarCliente)
    const camposBase: FormField[] = ["rfc", "razons", "codpos", "email", "usocfdi", "regimen", "AgregarDatosExtra"]
    const camposFiscal: FormField[] = ["title_info_fiscal" as FormField, "calle", "numero_exterior", "numero_interior", "colonia", "ciudad", "localidad", "estado", "pais"]
    const camposFiscalFull: FormField[] = [...camposFiscal, "numregidtrib"]
    const camposContacto: FormField[] = ["title_info_contacto" as FormField, "nombre", "apellidos", "telefono", "email2", "email3"]
    const camposBancaria: FormField[] = ["title_info_banco" as FormField, "banco", "cuenta"]
    const camposExtraToggle: FormField[] = [...camposFiscal, ...camposContacto, ...camposBancaria]

    const initData = <IFormRegistrarCliente>{
        pais: "MEX",
    }

    ModalThings._GetModalToForm({
        Title: _L("panelfinanzasedocuenta_factura.registrar_cliente"),
        Modulo: Util._MODULO_ALUMNO_EDOCTA,
        GetForm: (_, mt) => {
            mt.Modal._ModalSelection.classed("modal_factura_clienteinfo", true)
            const form = new FormGenerator<IFormRegistrarCliente>()
            form._Crear({
                OnLoad: (formSelection, rows) => {
                    const camposArea1 = [...camposBase, ...camposContacto]
                    const camposArea2 = [...camposFiscalFull, ...camposBancaria]
                    formSelection.append("div").call(div => {
                        camposArea1.forEach(field => div.append(() => rows.get(field).row.node()))
                    })
                    formSelection.append("div").call(div => {
                        camposArea2.forEach(field => div.append(() => rows.get(field).row.node()))
                    })
                },
                schema: [
                    {
                        model: "rfc", type: "input", labelText: _L("panelfinanzasedocuenta_factura.rfc"),
                        inputAttr: { required: true, minlength: 12, maxlength: 13 },
                        onLoad: ({ selection }) => UIUtilMask._ApplyUpperCaseMask(selection, false),
                    },
                    { model: "razons", type: "input", labelText: _L("panelfinanzasedocuenta_factura.razonsocial"), inputAttr: { required: true } },
                    { model: "codpos", type: "input", labelText: _L("panelfinanzasedocuenta_factura.cp"), inputAttr: { required: true } },
                    { model: "email", type: "input", labelText: _L("panelfinanzasedocuenta_factura.correo_el"), inputAttr: { required: true, type: "email" } },
                    {
                        model: "usocfdi", type: "selectMaterial", labelText: _L("panelfinanzasedocuenta_factura.uso_cfdi"),
                        selectMaterialAttr: {
                            required: true,
                            valueMember: "key",
                            displayMember: "name",
                            ShowAndEnableSearchText: true,
                            Data: _ProcesaFacturaCatalogo(_ObtenerCatalogoSATUsoCFDI(idEscuela))
                        }
                    },
                    {
                        model: "regimen", type: "selectMaterial", labelText: _L("panelfinanzasedocuenta_factura.regimen_fiscal"),
                        selectMaterialAttr: {
                            required: true,
                            valueMember: "key",
                            displayMember: "name",
                            ShowAndEnableSearchText: true,
                            Data: _ProcesaFacturaCatalogo(_ObtenerCatalogoSATRegimenFiscal(idEscuela))
                        }
                    },
                    {
                        model: "AgregarDatosExtra", type: "input", labelText: _L("panelfinanzasedocuenta_factura.datos_extra"),
                        inputAttr: (() => {
                            const fnChangeViewMode = (extrasVisible: boolean) => {
                                const fields = form._ControlsData
                                camposExtraToggle.forEach(model => fields.get(model).row.classed("hide", !extrasVisible))
                                form._Form.classed("full_mode", extrasVisible)
                            }
                            return {
                                type: "checkbox",
                                onload: () => setTimeout(() => fnChangeViewMode(false)),
                                onchange: (e) => {
                                    const extrasVisible = (e.target as HTMLInputElement).checked
                                    fnChangeViewMode(extrasVisible)
                                },
                            }
                        })()
                    },

                    // INFORMACIÓN FISCAL
                    { model: "title_info_fiscal", type: "label", labelText: _L("panelfinanzasedocuenta_factura.info_fiscal"), onLoad: ({ selection }) => selection.style("font-family", "Lato-Bold") },
                    { model: "calle", type: "input", labelText: _L("panelfinanzasedocuenta_factura.calle") },
                    { model: "numero_exterior", type: "input", labelText: _L("panelfinanzasedocuenta_factura.num_exterior") },
                    { model: "numero_interior", type: "input", labelText: _L("panelfinanzasedocuenta_factura.num_interior") },
                    { model: "colonia", type: "input", labelText: _L("panelfinanzasedocuenta_factura.colonia") },
                    { model: "ciudad", type: "input", labelText: _L("panelfinanzasedocuenta_factura.ciudad_delegacion") },
                    { model: "localidad", type: "input", labelText: _L("panelfinanzasedocuenta_factura.localidad") },
                    { model: "estado", type: "input", labelText: _L("panelfinanzasedocuenta_factura.estado") },
                    {
                        model: "pais", type: "selectMaterial", labelText: _L("panelfinanzasedocuenta_factura.pais"),
                        selectMaterialAttr: {
                            valueMember: "key",
                            displayMember: "name",
                            ShowAndEnableSearchText: true,
                            Data: _ProcesaFacturaCatalogo(_ObtenerCatalogoSATPais(idEscuela)),
                            onSelect: (pais: ICatalogoSATPais) => {
                                const numRegIdTribHide = pais?.key == "MEX";
                                form._ControlsData.get("numregidtrib").row.classed("hide", numRegIdTribHide)
                            },
                        }
                    },
                    { model: "numregidtrib", type: "input", labelText: _L("panelfinanzasedocuenta_factura.numregidtrib"), inputAttr: { required: true } },

                    // INFORMACIÓN DE CONTACTO
                    { model: "title_info_contacto", type: "label", labelText: _L("panelfinanzasedocuenta_factura.info_contacto"), onLoad: ({ selection }) => selection.style("font-family", "Lato-Bold") },
                    { model: "nombre", type: "input", labelText: _L("panelfinanzasedocuenta_factura.nombres") },
                    { model: "apellidos", type: "input", labelText: _L("panelfinanzasedocuenta_factura.apellidos"), },
                    { model: "telefono", type: "input", labelText: _L("panelfinanzasedocuenta_factura.tel"), },
                    { model: "email2", type: "input", labelText: _L("panelfinanzasedocuenta_factura.correo_a1") /* , inputAttr: { type: "email" } */ },
                    { model: "email3", type: "input", labelText: _L("panelfinanzasedocuenta_factura.correo_a2") /* , inputAttr: { type: "email" } */ },

                    // INFORMACIÓN BANCARIA
                    { model: "title_info_banco", type: "label", labelText: _L("panelfinanzasedocuenta_factura.info_banco"), onLoad: ({ selection }) => selection.style("font-family", "Lato-Bold") },
                    {
                        model: "banco", type: "selectMaterial", labelText: _L("panelfinanzasedocuenta_factura.banco"),
                        selectMaterialAttr: {
                            valueMember: "name",
                            displayMember: "name",
                            ShowAndEnableSearchText: true,
                            Data: _ProcesaFacturaCatalogo(_ObtenerFCatalogoBanco(idEscuela))
                        }
                    },
                    { model: "cuenta", type: "input", labelText: _L("panelfinanzasedocuenta_factura.4digitos_cta"), inputAttr: { minlength: 4, maxlength: 4 } },
                ]
            }, initData)
            return form
        },
        OnAccept: (form) => {
            const dataFinal: IFormRegistrarCliente = (() => {
                const dataForm = form._Data
                const dFinal = <IFormRegistrarCliente>{
                    IdEscuela: idEscuela,
                    IdAlumno: idAlumno,
                }
                for (const k in dataForm) {
                    const v = dataForm[k]
                    if (fieldsFormRegistrarClienteAux[k] !== undefined || v == null)
                        continue
                    dFinal[k] = v
                }
                return dFinal
            })()
            return _SvFacturaRegistrarCliente(dataFinal)
                .then(res => {
                    if (res.Resultado > 0 || res.Resultado == -4) {
                        const clienteRegistrado = res.Datos as IFacturaCliente
                        callSuccessRecord(clienteRegistrado)
                        res.Mensaje = _HttpMsgV2(res)
                        res.Resultado = 1
                    }
                    return res
                })
        }
    })
}

// UTILS

const ViewClienteItemList = (container: TSelectionHTML<"div">, cliente: Partial<IFacturaCliente>, UIDSelected?: string) => {
    const dirHTML: string = (() => {
        // const L = (key: TLabelTag) => _L(key)//.toLowerCase()
        const validTxt = (v: string | number) => !!(v || "").toString().trim()
        let dir = ""
        if (validTxt(cliente.Calle)) dir += `${_L("panelfinanzasedocuenta_factura.calle")} ${cliente.Calle}`
        if (validTxt(cliente.Numero)) dir += `, ${_L("panelfinanzasedocuenta_factura.num_exterior")} ${cliente.Numero}`
        if (validTxt(cliente.Interior)) dir += `, ${_L("panelfinanzasedocuenta_factura.num_interior")} ${cliente.Interior}`
        if (validTxt(cliente.Colonia)) dir += `, ${_L("panelfinanzasedocuenta_factura.colonia")} ${cliente.Colonia}`
        if (validTxt(cliente.Ciudad)) dir += `, ${_L("panelfinanzasedocuenta_factura.ciudad_delegacion")} ${cliente.Ciudad}`
        if (validTxt(cliente.Localidad)) dir += `, ${_L("panelfinanzasedocuenta_factura.localidad")} ${cliente.Localidad}`
        if (validTxt(cliente.Estado)) dir += `, ${_L("panelfinanzasedocuenta_factura.estado")} ${cliente.Estado}`
        // if (validTxt(cliente.Pais)) dir += `, ${L("panelfinanzasedocuenta_factura.pais")} ${cliente.Pais}`
        if (dir.startsWith(", ")) dir = dir.substring(2)
        return dir.trim()
            ? Util._ViewRowInfo("panelfinanzasedocuenta_factura.dir", dir, true, true)
            : ""
    })()
    const nombreHTML = !!cliente.Contacto.Nombre?.trim() || !!cliente.Contacto.Apellidos?.trim()
        ? Util._ViewRowInfo("panelfinanzasedocuenta_factura.nombre", cliente.Contacto.Nombre + " " + cliente.Contacto.Apellidos)
        : ""
    const checked = cliente.UID == UIDSelected
    const contentHTML = `
        ${Util._ViewRowInfo("panelfinanzasedocuenta_factura.razonsocial", cliente.RazonSocial, false)}
        ${Util._ViewRowInfo("panelfinanzasedocuenta_factura.rfc", cliente.RFC)}
        ${nombreHTML}
        ${Util._ViewRowInfo("panelfinanzasedocuenta_factura.uid", cliente.UID, true, true)}
        ${Util._ViewRowInfo("panelfinanzasedocuenta_factura.correo_el", cliente.Contacto.Email, true, true)}
        ${dirHTML}`
    return Util._ViewItemListCheck(container, checked, contentHTML)
}