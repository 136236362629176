import { DataDRequest } from "../DRequest";
import { Global } from "../Global";
import { IInfoEmpresaFactura } from "../entidad/Factura";

export function _ObtenerDetalleEmpresaFactura(idEmpresa: number) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "factura/ObtenerDetalleEmpresa"
    const params = {
        "IdEscuela": idEmpresa,
    }
    return DataDRequest._Request5<IInfoEmpresaFactura>(url, "GET", params)
}