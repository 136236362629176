import { ArrayV2 } from "../../util/ArrayV2";
import { DataDRequest } from "../DRequest";
import { Entidad } from "../Entidad";
import { Global } from "../Global";
import { DataUtil } from "../util/Util";

import ICuentaBancaria = Entidad.IFinanzaCuentaBancaria;
const IDREQUEST = Entidad.CTipoRequest.CuentaBancaria;

export const _DictCuentaBancaria = new Map<number, ICuentaBancaria>();

export async function _GetList(escuelas: Entidad.IEscuela[]): Promise<DataDRequest.IRequestResponseListA<ICuentaBancaria>[]> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "cuentaBancaria/ObtenerLista";

    return new ArrayV2(...escuelas)
        ._MapAwait<DataDRequest.IRequestResponseListA<ICuentaBancaria>>(async (escuela, i) => {
            const lastStrDate = await DataUtil._GetMaxDate(IDREQUEST, escuela.IdKinder);
            const params = {
                "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
                "IdEscuela": escuela.IdKinder,
                "Modificacion": lastStrDate,
            }

            return DataDRequest._Request4List(url, "GET", params, IDREQUEST, escuela.IdKinder);
        });
}

export function _NuevoRegistro(dato: ICuentaBancaria) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "cuentaBancaria/Nuevo";
    const params = {
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
        "IdEscuela": dato.IdEscuela,
        "Numero": dato.Numero,
        "Banco": dato.NombreBanco,
        "Clave": dato.Clave,
        "Sucursal": dato.Sucursal,
        "Beneficiario": dato.Beneficiario,
        "Tipo": dato.Tipo,
    };
    return DataDRequest._Request5(url, "POST", params, IDREQUEST);
}

export function _RegistroMultiple(dato: Omit<ICuentaBancaria, keyof Pick<ICuentaBancaria, "IdEscuela" | "Tipo">>, idsEscuelas: number[], tipos: Entidad.CTipoCuentaBancaria[]) {
    const totalEsperado = idsEscuelas.length * tipos.length;
    return new ArrayV2<number>()
        ._Push(...idsEscuelas)
        ._ReduceAwait(async (result, idEscuela) => {
            await new ArrayV2<Entidad.CTipoCuentaBancaria>()
                ._Push(...tipos)
                ._ForEachAwait(async (tipo) => {
                    const res = await _NuevoRegistro({
                        ...dato,
                        IdEscuela: idEscuela,
                        Tipo: tipo,
                    });
                    if (res.Resultado > 0) {
                        result.ExitososEsc.add(idEscuela);
                    } else {
                        result.Fallidos.push({
                            Resultado: res.Resultado,
                            Escuela: idEscuela,
                            Tipo: tipo,
                        });
                        if (result.Fallidos.length == totalEsperado) {
                            result.Resultado = result.Fallidos.length == 1 ? res.Resultado : 0;
                        }
                    }
                })
            return result;
        }, <DataDRequest.IRequestResponseA & { ExitososEsc: Set<number>, Fallidos: { Escuela: number, Tipo: Entidad.CTipoCuentaBancaria, Resultado: number }[] }>{
            Resultado: 1,
            TipoRequest: IDREQUEST,
            ExitososEsc: new Set<number>(),
            Fallidos: [],
        })
}

export function _ActualizarRegistro(dato: Omit<ICuentaBancaria, keyof Pick<ICuentaBancaria, "IdEscuela">>) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "cuentaBancaria/Actualizar";
    const params = {
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "Id": dato.Id,
        "Numero": dato.Numero,
        "Banco": dato.NombreBanco,
        "Clave": dato.Clave,
        "Sucursal": dato.Sucursal,
        "Beneficiario": dato.Beneficiario,
        "Tipo": dato.Tipo,
    };
    return DataDRequest._Request5(url, "POST", params, IDREQUEST);
}

export function _EliminarRegistro(id: number) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "cuentaBancaria/Eliminar";
    const params = {
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "Id": id,
    };
    return DataDRequest._Request5(url, "POST", params, IDREQUEST);
}

export default {
    _DictCuentaBancaria,
    _NuevoRegistro,
    _RegistroMultiple,
    _ActualizarRegistro,
    _EliminarRegistro
}
