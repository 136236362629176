import * as d3 from "d3";
import { group as d3Group } from "d3-array";
import { Entidad } from "../../data/Entidad";
import { Global } from "../../data/Global";
import { DataModuloMain } from "../../data/ModuloMain";
import DataModuloEscuela from "../../data/modulo/Escuela";
import DataModuloGrupo from "../../data/modulo/Grupo";
import DataModuloMaestro, { _MAESTRA_GENERAL as _MAESTRA_GENERAL_ID, _SvMaestroObtenerAccesos } from "../../data/modulo/Maestro";
import { DataUtil } from "../../data/util/Util";
import _L from "../../util/Labels";
import { IConfigGridExcelExport, IGridExtraTableConfig, IGridRenderInfo, VentanaGrid } from "../controlD3/AVentanaGrid";
import { CopyElementTextControl } from "../controlD3/CopyElementTextControl";
import { ExcelThings } from "../controlD3/ExcelExport";
import { FormGenerator, IControlCreated } from "../controlD3/Formulario";
import { ModalThings } from "../controlD3/ModalThings";
import { NotificacionV2 } from "../controlD3/NotificacionV2";
import { Table } from "../controlD3/Tabla";
import { _TextoCensuraDiv } from "../controlD3/TextoCensura";
import { UIUtilLang } from "../util/Language";
import { UIUtilPermission } from "../util/Permission";
import { UIUtilStrings } from "../util/Strings";
import { UIUtilGeneral } from "../util/Util";
import { UIUtilViewMaestro } from "../utilView/Maestro";

import CAccionPermiso = Entidad.CAccionPermiso;
import CModulo = Entidad.CModulo;
import CTipoPerfil = Entidad.CTipoPerfil;
import IEscuela = Entidad.IEscuela;

interface IMaestro extends Entidad.IMaestro {
    Grupos: string;
    Contrasenia: string;
}

interface IMaestroForm extends IMaestro {
    Contrasenia2?: string;
}

export class UIVentanaMaestros extends VentanaGrid<IMaestro> {

    constructor(content: d3.Selection<HTMLDivElement, undefined, HTMLElement, any>, modulo: CModulo) {
        super(content, modulo);
    }

    public _OnServiceEvent(eventName: DataModuloMain.TipoRequestMonitorId, reloadId?: number, error?: Error): void {
        super._OnServiceEvent(eventName, reloadId, error);

        // if (eventName == ) {
        // }
    }

    protected GRID_GetDataRequestID(): DataModuloMain.TipoRequestMonitorId {
        return Entidad.CTipoRequest.Maestro;
    }

    protected GRID_GetMenuTopGrid(): Table.ITableMenuTopDefaultOptionConfig[] {
        let objOpcion: Array<Table.ITableMenuTopDefaultOptionConfig> = [];
        if (this.GridHasPermisoAccion(CAccionPermiso.Agregar)) {
            objOpcion.push(
                {
                    Label: "Agregar",
                    Callback: () => this.OpenModal_AgregarEditar()
                }
            )
        }
        return objOpcion;
    }

    protected GRID_GetSelectionDataMenuV2(menuLocation: "row" | "top-selected", dataGridSelected: IMaestro[]): Table.ITableMenuDataSelectedOptionConfig<IMaestro>[] {
        let opciones: Array<Table.ITableMenuDataSelectedOptionConfig<IMaestro>> = []
        if (this.GridHasPermisoAccion(Entidad.CAccionPermiso.Editar)) {
            opciones.push({
                Label: "Editar",
                Callback: (datos) => this.OpenModal_AgregarEditar(datos[0]),
                MultiData: false
            })
        }
        if (this.GridHasPermisoAccion(Entidad.CAccionPermiso.Eliminar)) {
            opciones.push({
                Label: "Eliminar",
                Callback: (datos) => this.OpenModal_EliminarRegistro(datos)
            })
        }
        if (Global._GLOBAL_CONF.DEBUG_MODE || Global._DEVELOPMENT_UTILS) {
            opciones.push({
                Label: _L("control.tomar_sesion"),
                MultiData: false,
                Callback: (datos) => UIUtilViewMaestro._OpenModal_IniciarSesion(datos[0]),
            })
        }
        return opciones;
    }

    protected GRID_GetFilters(): Table.IParametroFiltro<IMaestro>[] {
        return [
            { Label: "", Field: "Nombre" },
            { Label: "", Field: "ApPaterno" },
            { Label: "", Field: "ApMaterno" },
        ]
    }

    protected GRID_GetTableConfigBase(): IGridRenderInfo<IMaestro> {
        return {
            IdTabla: "Maestros",
            Title: "",
            DefaultSort: "NombreCompleto",
            IdData: "Id",
            MinWidth: 800,
            Columns: [
                { Field: "NombreCompleto", Label: "Nombre", Width: "20%", MinWidth: "75px" },
                { Field: "Correo", Label: "Correo", Width: "20%", MinWidth: "75px" },
                { Field: "Telefono", Label: "", Width: "20%", MinWidth: "90px" },
                { Field: "Grupos", Label: "Grupos", Width: "15%", MinWidth: "70px" },
                { Field: "Contrasenia", Label: "Contraseña", Width: "20%", MinWidth: "100px" },
                { Field: "NombreEscuela", Label: "Escuela", Width: "20%", MinWidth: "75px" },
            ]
        }
    }

    protected GRID_GetTableConfigAdvanced(): IGridExtraTableConfig<IMaestro> {
        const escuelasConPermisoPsswd = [_MAESTRA_GENERAL_ID, ...UIUtilPermission._GetSchoolsByActionModule(CModulo.Maestros, CAccionPermiso.VerContrasenia).map(d => d.IdKinder)];
        return {
            EvaluatorAndSubLevelsBuild: {
                OnStepCellTable: (container, datum, field: keyof IMaestro) => {
                    switch (field) {
                        case "Grupos":
                            let grupos = datum.IdsGrupos
                                .map(idGrupo => DataModuloGrupo._DiccGrupo.get(idGrupo))
                                .filter(grupo => Boolean(grupo));

                            UIUtilGeneral._CreaElementosLinkeablesV2({
                                Container: container,
                                Data: grupos,
                                Path: "escuelas/grupos/panel",
                                GetTag: d => d.Nombre,
                                GetId: d => d.IdGrupo,
                            });
                            break;

                        case "Contrasenia":
                            let restriction: string;
                            if (!escuelasConPermisoPsswd.includes(datum.IdEscuela)) {
                                restriction = UIUtilLang._GetUIString("permission", "nopermiso_verinfo");
                            }
                            _TextoCensuraDiv(container, () => UIVentanaMaestros.Sv_ObtenerContrasenia(datum.Id), "100px", restriction);
                            break;

                        case "Telefono":
                        case "Correo":
                            CopyElementTextControl._AddCopyTextBehaviour(container.node(), () => datum[field]);
                            break;

                        case "NombreEscuela":
                            if (datum.IdEscuela == _MAESTRA_GENERAL_ID) {
                                container.text(UIUtilLang._GetUIString("general", "general"));
                            }
                            break;
                    }
                }
            }
        };
    }

    protected GRID_GetExportarConfig(dataGrid: IMaestro[]): IConfigGridExcelExport<IMaestro> {
        const bolUserAdmin = (DataUtil._Usuario.Perfil == Entidad.CTipoPerfil.Admin);
        return {
            IdsEscuelas: [...new Set(dataGrid.map(d => d.IdEscuela))],
            OnStepFieldInDataRow: (field, dato) => {
                if (field == "Grupos") {
                    return dato.IdsGrupos
                        .map(id => DataModuloGrupo._DiccGrupo.get(id)?.Nombre)
                        .filter(d => (d != null))
                        .join(", ");
                }
                return dato[field] as string;
            },
            OnGetDataBySheets: async () => {
                let maestrosauxiliar = dataGrid.map(d => UIUtilGeneral._ObjectClone(d));
                maestrosauxiliar.forEach(datum => {
                    if (datum.IdEscuela == _MAESTRA_GENERAL_ID) {
                        datum.NombreEscuela = UIUtilLang._GetUIString("general", "general");
                    }
                })
                if (bolUserAdmin) {
                    const idsMaestros = [...new Set(dataGrid.map(d => d.Id))];
                    const psswdsMaestros = await _SvMaestroObtenerAccesos(idsMaestros);
                    if (psswdsMaestros.Resultado > 0) {
                        maestrosauxiliar.forEach(d => {
                            d.Contrasenia = psswdsMaestros.Datos[d.Id];
                        })
                    }
                    else {
                        this.notificacion._Mostrar(UIUtilLang._GetUIString("general", "notif_fail_getpass"), "ADVERTENCIA");
                    }
                }
                return Array.from(d3Group(maestrosauxiliar, d => d.IdEscuela))
                    .map<ExcelThings.ISheetConfig<IMaestro>>(entrie => ({
                        IdSheet: entrie[0], // IdEscuela
                        SheetName: entrie[1][0].NombreEscuela,
                        Data: entrie[1]
                    }))
            },
            OnGetEscuelasTagInSheet: (dataSheet) => dataSheet[0].NombreEscuela,
        }
    }

    // ************************************************************************
    // Actions
    // ************************************************************************

    private OpenModal_AgregarEditar(datoMaestro = <IMaestro>{}) {
        const esEditar = datoMaestro.Id != null;
        let permisoViewPsswd = false;
        let escuelasConPermisoPsswd = UIUtilPermission._GetSchoolsByActionModule(CModulo.Maestros, CAccionPermiso.VerContrasenia);
        if ((datoMaestro.IdEscuela == 0 && esEditar && DataUtil._Usuario._PerfilAdmin) || escuelasConPermisoPsswd.find(f => f.IdKinder === datoMaestro.IdEscuela)) {
            permisoViewPsswd = true;
        }
        const action = esEditar ? CAccionPermiso.Editar : CAccionPermiso.Agregar;
        let passLoaded = false;
        let passChanged = false;

        this.GridOpenModal_ActionFormToAGridData({
            Action: action,
            IdsEscuelas: datoMaestro.IdEscuela ? [datoMaestro.IdEscuela] : null,
            OnGetIdEscuelasToReload: (d) => [d._Data.IdEscuela],
            GetForm: () => {
                let dataForm = Object.assign(<IMaestroForm>{
                    EntradaSalidaEscuela: datoMaestro.EntradaSalidaEscuela === undefined ? false : datoMaestro.EntradaSalidaEscuela
                }, datoMaestro);
                /* if (!dataForm.Telefono && !esEditar) {
                    let schoolPhoneCode: string;
                    const phoneCodes = Array.from(Data.Modulo.Escuela.DiccEscuela.values()).map(d => (d.Telefono && d.Telefono.split(" ")[0].includes("+")) ? d.Telefono.split(" ")[0] : "")
                    if (phoneCodes.every(d => d == phoneCodes[0])) {
                        schoolPhoneCode = phoneCodes[0];
                        if (!schoolPhoneCode.includes("+")) {
                            schoolPhoneCode = "+52";
                        }
                    }
                    dataForm.Telefono = dataForm.Telefono ? schoolPhoneCode + " " + dataForm.Telefono : schoolPhoneCode;
                } */
                //let escuelasConPermiso = Util.Permission.fn_GetSchoolsByActionModule(Entidad.CModulo.Maestros, action);
                const escuelasConPermiso = this.GridGetEscuelasConPermisoDeAccion(action, datoMaestro.IdEscuela);
                const escuelasOpts: Pick<IEscuela, "IdKinder" | "Nombre">[] = [];
                if (action == CAccionPermiso.Editar || (Global._DEVELOPMENT_UTILS && DataUtil._Usuario.Perfil == CTipoPerfil.Admin && escuelasConPermiso.length && escuelasConPermiso.every(d => (d.ZonaHoraria == escuelasConPermiso[0].ZonaHoraria)))) {
                    escuelasOpts.push(...escuelasConPermiso, {
                        IdKinder: _MAESTRA_GENERAL_ID,
                        Nombre: UIUtilLang._GetUIString("general", "general"),
                    });
                }
                else {
                    escuelasOpts.push(...escuelasConPermiso);
                }
                if (action == CAccionPermiso.Agregar && escuelasConPermiso.length == 1) {
                    datoMaestro.IdEscuela = escuelasConPermiso[0].IdKinder;
                }
                const form = new FormGenerator<IMaestroForm>()
                    ._Crear({
                        LangModuleKeyInContext: this.labelsKeyBase,
                        schema: [
                            { model: "Nombre", type: "input", inputAttr: { type: "text", maxlength: 100, required: true }, labelText: "d_field_nombre" },
                            { model: "ApPaterno", type: "input", inputAttr: { type: "text", maxlength: 100, required: true }, labelText: "d_field_appaterno" },
                            { model: "ApMaterno", type: "input", inputAttr: { type: "text", maxlength: 100 }, labelText: "d_field_apmaterno" },
                            {
                                model: "Telefono", type: "input", inputAttr: { type: "phone2_10A", required: true }, labelText: "d_field_telefono",
                                onValidate: (value) => {
                                    const telsplit = value.split(" ");
                                    const tel = telsplit.length == 1 ? telsplit[0] : telsplit[1];
                                    return UIUtilStrings._TrimStart(tel, "0").length == tel.length;
                                },
                            },
                            {
                                model: "IdEscuela", type: "selectMaterial",
                                labelText: "frm1_escuela",
                                selectMaterialAttr: {
                                    disabled: esEditar,
                                    removeBorder: esEditar,
                                    displayMember: "Nombre",
                                    valueMember: "IdKinder",
                                    required: true,
                                    onChange: (data: number) => {
                                        const selectPhoneCode = (form._ControlsData.get("Telefono") as IControlCreated<"input">)._SelectPhoneCode;
                                        if (!selectPhoneCode._uniqueVMSelected) {
                                            const escuela = DataModuloEscuela._DiccEscuela.get(data);
                                            if (escuela) {
                                                let code: string;
                                                if (escuela.Telefono)
                                                    code = escuela.Telefono.split(" ")[0].includes("+") ? escuela.Telefono.split(" ")[0] : null;
                                                selectPhoneCode._valueSelect([code || "+52"]);
                                            }
                                        }
                                    }
                                },
                                values: escuelasOpts,
                            },
                            {
                                model: "EntradaSalidaEscuela", type: "selectMaterial",
                                labelText: "frm1_marcar_es",
                                selectMaterialAttr: {
                                    displayMember: "Nombre",
                                    valueMember: "Id",
                                    required: true
                                },
                                values: [
                                    { Id: false, Nombre: this.VB_GetUIStringModule("tag_sologruposasig") },
                                    { Id: true, Nombre: this.VB_GetUIStringModule("tag_todosgrupos") },
                                ]
                            },
                            {
                                model: "Correo", type: "input", labelText: "d_field_correo",
                                inputAttr: { type: (esEditar && !dataForm.Correo.includes("@") ? "text" : "email"), maxlength: 200, required: true },
                            },
                            {
                                model: "Contrasenia", type: "input",
                                labelText: "frm1_pass",
                                inputAttr: { type: (permisoViewPsswd) ? "password" : "text", required: true, minlength: 8, inputProperties: { value: (esEditar && !permisoViewPsswd) ? "●●●●" : "" }, disabled: (esEditar && !permisoViewPsswd) },
                            },
                            {
                                model: "Contrasenia2", type: "input",
                                labelText: "frm1_reppass",
                                inputAttr: {
                                    type: (permisoViewPsswd) ? "password" : "text", required: true, inputProperties: { value: (esEditar && !permisoViewPsswd) ? "●●●●" : "" }, disabled: (esEditar && !permisoViewPsswd)
                                },
                            }
                        ],
                        BuildView: (container, controls, form) => {
                            const fnApdend = (key: keyof IMaestroForm) => {
                                container.append(() => controls.get(key).row.node());

                                if (key == "Contrasenia" || key == "Contrasenia2") {
                                    let input = controls.get(key).selection.node() as HTMLInputElement;
                                    input.addEventListener("keydown", () => {
                                        passChanged = true;
                                    })
                                }
                            }
                            fnApdend("Nombre");
                            fnApdend("ApPaterno");
                            fnApdend("ApMaterno");
                            fnApdend("Telefono");
                            fnApdend("IdEscuela");
                            fnApdend("EntradaSalidaEscuela");

                            container.append("label")
                                .style("font-weight", "bold")
                                .style("color", "gay")
                                .style("margin-bottom", "10px")
                                .text(UIUtilLang._GetUIString(this.labelsKeyBase, "tag_userdata"));

                            fnApdend("Correo");
                            fnApdend("Contrasenia");
                            fnApdend("Contrasenia2");
                        },
                        Validation: (value, field, datoF, controlsForm) => {
                            switch (field) {
                                case "Contrasenia2":
                                    if (datoF.Contrasenia != datoF.Contrasenia2) {
                                        this.notificacion._Mostrar(UIUtilLang._GetUIString("GENERAL", "notif_passwnotsame"), "ADVERTENCIA");
                                        return false;
                                    }
                                    break;
                            }
                            return true;
                        }
                    }, dataForm);

                return form;
            },
            DrawContent: async (content, form, mt) => {
                let inputPass = form._ControlsData.get("Contrasenia")
                    .selection
                    .node() as HTMLInputElement;

                let inputPass2 = form._ControlsData.get("Contrasenia2")
                    .selection
                    .node() as HTMLInputElement;

                if (esEditar && permisoViewPsswd) {
                    mt.Progress.attr("oculto", false);

                    const res = await _SvMaestroObtenerAccesos([datoMaestro.Id]);

                    if (res.Resultado > 0) {
                        passLoaded = true;
                        let strPass = res.Datos[datoMaestro.Id]

                        inputPass.value = strPass;
                        inputPass2.value = strPass;
                    }
                    else {
                        console.log('No cargó las contras');
                        NotificacionV2._Mostrar(UIUtilLang._GetUIString("general", "notif_fail_getpass"), "ADVERTENCIA");
                        // Button.fn_EnableButton(mt.BtnRight, false);
                        inputPass.removeAttribute("minlength");
                        inputPass.removeAttribute("required");
                        inputPass.disabled = true;
                        inputPass.value = "";

                        inputPass2.removeAttribute("minlength");
                        inputPass2.removeAttribute("required");
                        inputPass2.disabled = true;
                        inputPass2.value = "";
                    }

                    mt.Progress.attr("oculto", true);
                    console.log("Pass Load", passLoaded); //Quitar cuando se haya desusado la variable
                } else if (esEditar && !permisoViewPsswd) {
                    console.log("-d Editando sin permisos para ver contraseñas")
                    inputPass.removeAttribute("minlength");
                    inputPass.removeAttribute("required");
                    inputPass2.removeAttribute("minlength");
                    inputPass2.removeAttribute("required");
                }
            },
            OnAccept: async (form, mt) => {
                const formData = form._Data;
                if (esEditar) {
                    let pass = ""
                    if (passChanged) {
                        pass = formData.Contrasenia;
                    }
                    return DataModuloMaestro._ActualizarRegistro(formData, pass);
                }
                else {
                    let res = await DataModuloMaestro._NuevoRegistro(formData);
                    if (res.Datos == 0) {
                        console.log("Contraseña reutlizada");
                        ModalThings._GetModalSimple({
                            Width: 250,
                            LangModuleKeyInContext: this.labelsKeyBase,
                            Title: this.VB_GetUIStringModule("info_psswd"),
                            DrawContent: (content, mt) => {
                                content.style("display", "flex").style("flex-direction", "column")
                                content.html(this.VB_GetUIStringModule("psswd_reused").replace("_PASSWD", formData.Correo))
                                content.select("b").style("padding-top", "var(--padding1)");
                            }
                        })
                    }
                    if (formData.IdEscuela == _MAESTRA_GENERAL_ID && res.Resultado > 0) {
                        await this.OpenModal_AsignarTodosGrupos(res.Resultado);
                    }
                    return res;
                }
            }
        })
    }

    private OpenModal_EliminarRegistro(maestros: IMaestro[]) {
        this.GridOpenModal_ProccessArrayData({
            DataToProccess: maestros,
            Width: 300,
            OnGetIdEscuela: (maestro) => maestro.IdEscuela,
            OnError_GetItemDataTag: (maestro) => maestro.NombreCompleto,
            OnStepAProccess: (maestro) => DataModuloMaestro._EliminarRegistro(maestro.Id)
        })
    }

    private OpenModal_AsignarTodosGrupos(idMaestro: number) {
        const grupos = DataModuloMain._GetReqDataArrayByName("Grupo");
        return ModalThings._OpenModalToProccessServiceByServiceFromAArrayBasic({
            Title: this.VB_GetUIStringModule("asign_group_title"),
            Message: this.VB_GetUIStringModule("asign_group_message"),
            StrModalBotons: "sí_no",
            Width: 280,
            DataToProccess: grupos,
            TypeRequest: null,
            OnError_GetItemDataTag: (d) => d.Nombre,
            OnStepAProccess: (d) => DataModuloGrupo._AsignarGrupoMaestro(d.IdGrupo, idMaestro),
        })
    }

    // ************************************************************************
    // 
    // ************************************************************************

    private static async Sv_ObtenerContrasenia(IdMaestro: number): Promise<string> {
        //let escuelasConPermiso = Util.Permission.fn_GetSchoolsByActionModule(Entidad.CModulo.Maestros, Entidad.CAccionPermiso.VerContrasenia);
        let res = await _SvMaestroObtenerAccesos([IdMaestro]);
        if (!res.Datos) {
            return null;
        }
        return res.Datos[IdMaestro];
    }
}
