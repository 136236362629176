import * as d3 from "d3";
import { UIUtilElement } from "../util/Element";
import { UIUtilIconResources } from "../util/IconResourses";
import ic_closelight from '/image/iconos/ico_closelight.svg?raw';

export enum CBGColor {
    light = "bg_light",
    blue = "bg_blue",
    red = "bg_red",
    orange = "bg_orange",
    yellow = "bg_yellow",
    green = "bg_green",
    default = "bg_default"
}

export class Button {
    private d3Selection: d3.Selection<HTMLButtonElement, any, any, any>;
    private icon: d3.Selection<HTMLImageElement, unknown, HTMLElement, undefined>;
    private text: d3.Selection<HTMLSpanElement, unknown, HTMLElement, undefined>;
    private color: CBGColor;

    constructor(parent?: HTMLElement | TSelectionHTML<"htmlelement">, text: string = "", color: CBGColor = CBGColor.blue) {
        this.d3Selection = parent
            ? (parent instanceof HTMLElement ? d3.select(parent) as TSelectionHTML<"htmlelement"> : parent).append<HTMLButtonElement>("button")
            : d3.create("button");
        this.d3Selection.attr("type", "button");
        this.d3Selection.classed("UI_Button", true);
        this.icon = this.d3Selection.append("img");
        this.text = this.d3Selection.append("span");
        this.text.text(text);
        this._theme = color ? color : CBGColor.default;
    }

    get _node(): HTMLButtonElement {
        return this.d3Selection.node();
    }

    set _icon(value: string) {
        this.icon
            .property("src", value)
            .style("height", "20px")
            .style("margin-right", "10px")
        //this.d3Selection.style("padding", "10px");
    }

    set _theme(color: CBGColor) {
        this._ClearColor();
        this.d3Selection.classed(color, true);
        this.color = color;
    }

    get _theme() {
        return this.color;
    }

    set _type(value: string) {
        this.d3Selection.attr("type", value)
    }

    get _type() {
        return this.d3Selection.attr("type");
    }

    get _d3Selection() {
        return this.d3Selection;
    }

    get _enabled(): boolean {
        return !this.d3Selection.property("disabled");
    }

    set _enabled(value: boolean) {
        this.d3Selection.property("disabled", !value);
    }

    public _ClearColor(): void {
        this.d3Selection.classed(this.color, false);
    }
}

export namespace Button {
    export namespace BtnClose {
        /** Crea y devuelve un control btn_close (X) */
        export function _GetCloseButton<T extends HTMLElement>(parent: d3.Selection<T, any, HTMLElement, any>, onClick?: Function, color?: string): d3.Selection<HTMLDivElement, any, HTMLElement, HTMLElement> {
            const container = parent.append<HTMLDivElement>("wc-button")
                .classed("btn_close", true);

            container.property("_SrcIco", UIUtilIconResources.CGeneral.Close)

            /* let svg = container.append<SVGElement>("svg")
                .attr("viewBox", "0 0 100 100");

            svg.append("path")
                .attr("d", "M 8 8 l 84 84 z M 8 92 l 84 -84")
                .style("stroke", color); */

            if (onClick) {
                container.node().onclick = (e) => {
                    onClick();
                    e.stopPropagation();
                };
            }

            return container;
        }

        export function _GetCloseButtonLight(parent: TSelectionHTML<any>, onClick?: Function, color?: string): d3.Selection<HTMLDivElement, any, HTMLElement, HTMLElement> {
            let container = parent.append<HTMLDivElement>("wc-button")
                .classed("btn_close", true)
                .style("width", "15px")
                .style("height", "15px");

            const svgIco = UIUtilElement._CreateElementFromHTML(ic_closelight)
            svgIco.style.width = "15px";
            svgIco.style.height = "15px";
            container.property("_HtmlElement", svgIco)

            /* let svg = container.append<SVGElement>("svg")
                .attr("viewBox", "0 0 100 100");

            svg.append("path")
                .attr("d", "M 8 8 l 84 84 z M 8 92 l 84 -84")
                .style("stroke", color); */

            if (onClick) {
                container.node().onclick = (e) => {
                    onClick();
                    e.stopPropagation();
                };
            }

            return container;
        }

        /** Crea y devuelve un control svg -> class="btn_close_circle" */
        export function _GetCloseCircleButton(parent?: TSelectionHTML<any>) {
            const svg: d3.Selection<SVGSVGElement, any, any, any> = (parent ? parent.append("svg") : d3.create("svg"))
            svg.classed("btn_close_circle", true)
                .attr("viewBox", "0 0 100 100");

            svg.append("circle")
                .attr("cx", "50")
                .attr("cy", "50")
                .attr("r", "50");

            svg.append("path")
                .attr("d", "M 20 20 l 60 60 z M 20 80 l 60 -60");

            return svg;
        }
    }

    export namespace BtnPlus {

        /**
         * svg class="btn_plus"
         * @param dimention @default 15 px
         * @param parent
         */
        export function _GetPlusButton(dimention: number = 15, parent?: TSelectionHTML<any>) {
            let btn: d3.Selection<SVGSVGElement, any, any, any> = (parent ? parent.append("svg") : d3.create("svg"))
            btn.classed("btn_plus", true)
                .attr("viewBox", "0 0 100 100")
                .style("width", dimention + "px")
                .style("height", dimention + "px")
                .style("stroke-width", dimention + "px")
                .style("stroke", "#757575");

            btn.append("path")
                .attr("d", "M 50 0 l 0 100 z M 0 50 l 100 0 z");

            return btn;
        }
    }

    export namespace DirectionIndicator {

        const DirectionMap = {
            Left: "M 90 10 L 10 50 L 90 90 L 90 70 L 50 50 L 90 30 L 90 10 Z",
            Right: "M 10 90 L 90 50 L 10 10 L 10 30 L 50 50 L 10 70 L 10 90 Z"
        }

        export function _GetDirectionIndicator<K extends keyof (typeof DirectionMap)>(direction: K, dims = 20) {
            let control = d3.create<SVGElement>("svg")
                .style("width", dims + "px")
                .style("height", dims + "px")
                .attr("viewBox", "0 0 100 100")

            control.append<SVGPathElement>("path")
                .attr("d", () => DirectionMap[direction]);

            return control;
        }
    }

    // export namespace CalendarPickerIndicator {
    //     const colorPicker = "rgb(51, 103, 214)";
    //     interface ICPickerIndicatorDatum {
    //         IsPicked: boolean
    //     }

    //     export type ControlSelection = d3.Selection<SVGSVGElement, ICPickerIndicatorDatum, HTMLElement, any>;

    //     export function fn_GetCalendarPicker (isPicked: boolean = false, dims: number = 15): ControlSelection {
    //         let control = d3.create("svg")
    //             .classed("calendar_picker_indicator", true)
    //             .attr("viewBox", "0 0 24 24")
    //             .datum(<ICPickerIndicatorDatum>{
    //                 IsPicked: isPicked
    //             })

    //         control.append("path")
    //             .classed("path1", true)
    //             .attr("d", "M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z")
    //             .style("fill", () => isPicked ? colorPicker : null);

    //         // control.append("path")
    //         //     .classed("path2", true)
    //         //     .style("fill", "none")
    //         //     .attr("d", "M0 0h24v24H0z");

    //         if (dims != 15) {
    //             control.style("width", dims + "px")
    //                 .style("height", dims + "px");
    //         }

    //         return control;
    //     }

    //     export function fn_ApplySelectionControlDatum(svg: d3.Selection<SVGSVGElement, any, HTMLElement, any>): ControlSelection {
    //         svg.datum(<ICPickerIndicatorDatum>{
    //             IsPicked: svg.select(".path1").style("fill") == colorPicker
    //         })
    //         return svg as ControlSelection
    //     }

    //     export function fn_UpdatePickedStatus(svgSelection: ControlSelection, isPicked: boolean) {
    //         svgSelection
    //             .datum(<ICPickerIndicatorDatum>{
    //                 IsPicked: isPicked
    //             })
    //             .select(".path1")
    //             .style("fill", () => isPicked ? colorPicker : null)
    //     }
    // }

    /** 
     * @param select - d3.Selection
     * @param isEnable - Boolean -> true: enable, false: disable
    */
    export function _EnableButton(select: TSelectionHTML<any>, isEnable: boolean) {
        select.classed("btn_disable", !isEnable);
        select.attr("tabindex", isEnable ? null : "-1");
    }
}
