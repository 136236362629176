import * as d3 from "d3";
import { UIUtilIconResources } from "../util/IconResourses";
import { IComponent } from "./ListGrid";

interface IIcon extends IComponent { URL: string; }

interface ILabel extends IComponent { Text: string; }

export class FileButton {
    private d3Selection: d3.Selection<HTMLLabelElement, any, any, any>;
    private input: d3.Selection<HTMLInputElement, unknown, HTMLElement, any>;
    private filesPermitidos: string;

    public iconURL: IIcon;
    public text: ILabel;
    public multiselect: boolean;
    public fileHandler: (ev: FileList) => void;

    set _filePermitidos(filter: string[]) {
        let extensiones = filter.map(ext => "." + ext);
        this.filesPermitidos = extensiones.join(", ");
        if (this.d3Selection) this.input.attr("accept", this.filesPermitidos);
    }

    set _multiselect(value: boolean) {
        if (this.input) this.input.property("multiple", value);
    }

    get _multiselect(): boolean {
        return this.input ? this.input.property("multiple") : null;
    }


    constructor(handler: (ev: FileList) => void) {
        this.iconURL = <IIcon>{};
        this.text = <ILabel>{};
        this.fileHandler = handler;
    }

    public _Build(parent?: HTMLElement): void {
        this.d3Selection = parent ? d3.select(parent).append("label") : d3.create("label");
        this.d3Selection = this.d3Selection.classed("fileButton", true).attr("for", "fileButton");
        this.input = this.d3Selection.append("input").attr("type", "file").attr("id", "fileButton");
        this.iconURL.d3Selection = this.d3Selection.append("img").attr("height", "25px") as any;
        this.text.d3Selection = this.d3Selection.append("label").attr("for", "fileButton") as any;
        this._multiselect = true;
        this._SetValues();
        this._config();
    }

    public _SetValues(): void {
        this.iconURL.d3Selection.property("src", this.iconURL.URL ? this.iconURL.URL : UIUtilIconResources.CGeneral.Folder);
        this.text.d3Selection.text(this.text.Text ? this.text.Text : "...");
        this.input.attr("accept", this.filesPermitidos);
    }

    public _config(): void {
        this.d3Selection.select("input").on("change", (ev: Event = d3.event) => {
            this.fileHandler(this.d3Selection.select("input").property("files"));
            this.input.property("value", "");
        });
    }
}
