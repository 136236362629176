import { Entidad } from "../../data/Entidad";
import { _SvMaestroObtenerAccesos } from "../../data/modulo/Maestro";
import _L, { _HttpMsgV2 } from "../../util/Labels";
import { ModalThings } from "../controlD3/ModalThings";
import { NotificacionV2 } from "../controlD3/NotificacionV2";

export namespace UIUtilViewMaestro {
    export function _OpenModal_IniciarSesion(maestro: Pick<Entidad.IMaestro, "Id" | "IdUsuario" | "Correo">) {
        type IMessage = { idRequired: number, user?: string, pwd?: string };
        ModalThings._GetConfirmacionModal({
            Title: _L("control.tomar_sesion"),
            Message: _L("control.tomar_sesion_msj"),
            OnAccept: async (mt) => {
                mt.Progress.node()._Visible = true
                const res = await _SvMaestroObtenerAccesos([maestro.Id])

                if (res.Resultado <= 0) {
                    mt.Progress.node()._Visible = false
                    NotificacionV2._Mostrar(_HttpMsgV2(res), "ADVERTENCIA")
                    return
                }

                const webURL = import.meta.env.APP_TEACHER_WEBURLORIGIN
                const adminWindowA = window.open(webURL)
                setTimeout(() => {
                    const messageLogin: IMessage = {
                        idRequired: maestro.IdUsuario,
                        user: maestro.Correo,
                        pwd: res.Datos[maestro.Id]
                    }
                    console.warn(messageLogin)
                    adminWindowA.postMessage(messageLogin, "*")
                    mt.Progress.node()._Visible = false
                }, 2500)
            }
        })
    }
}