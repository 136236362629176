export namespace UIUtilStrings {
    /** Primera letra de la cadena */
    export function _CapitaliceString(str: string): Capitalize<string> {
        return str.charAt(0).toUpperCase() + str.slice(1) as Capitalize<string>;
    }

    export function _StringRemoveDiacriticMarks(value: string): string {
        return value?.normalize("NFD").replace(/[\u0300-\u036f]/g, '');
    }

    export function _TruncateStr(str: string, maxlength: number): string {
        return (str.length > maxlength) ?
            str.slice(0, maxlength - 1) + '…' : str;
    }

    export function _TrimStart(string: string, ...masks: string[]): string {
        if (!masks.length)
            return string.trimStart();

        for (let i = 0; i < masks.length; i++) {
            while (string !== "" && string.startsWith(masks[i])) {
                string = string.substring(masks[i].length);
            }
        }
        return string;
    }

    export function _TrimEnd(string: string, ...masks: string[]): string {
        if (!masks.length)
            return string.trimEnd();

        for (let i = 0; i < masks.length; i++) {
            while (string !== "" && string.endsWith(masks[i])) {
                string = string.substring(0, (string.length - masks[i].length));
            }
        }
        return string;
    }

    export function _Trim(string: string, ...masks: string[]): string {
        if (!masks.length)
            return string.trim();

        for (let i = 0; i < masks.length; i++) {
            while (string !== "" && string.startsWith(masks[i])) {
                string = string.substring(masks[i].length);
            }
            while (string !== "" && string.endsWith(masks[i])) {
                string = string.substring(0, (string.length - masks[i].length));
            }
        }
        return string;
    }

    const URL_REGEX = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;

    export function _AutoLink(str: string): HTMLSpanElement {
        const span = document.createElement('span');
        const links = _ExtractLinks(str).concat(null);
        let strFinal = "";
        let linkStart = 0;

        if (!str) return span;

        links.forEach((link) => {
            const startSlice = linkStart;

            if (link === null) {
                const finStr = str.slice(startSlice, str.length);
                strFinal += finStr;
                return;
            }

            const linkIndex = str.indexOf(link, linkStart);
            if (linkIndex == -1) {
                return;
            }

            linkStart = linkIndex;
            const normalStr = str.slice(startSlice, linkStart);
            const linkStr = str.slice(linkStart, linkStart + link.length);

            strFinal += (normalStr + `<a target="_blank" href="${linkStr}">${linkStr}</a>`)
            linkStart += linkStr.length;
        });

        span.innerHTML = strFinal; // FIXME no usar innerHTML
        return span;
    }

    export function _ExtractLinks(str: string): string[] {
        const links: string[] = [];
        const resolver = str => (links.push(str), str);

        if (!str) return links;

        String.prototype.replaceAll
            ? str.replaceAll(URL_REGEX, resolver)
            : str.replace(URL_REGEX, resolver)

        return links;
    }
}
