import * as d3 from 'd3';
import { DataUtilLocalStorage } from '../../data/util/LocalStorage';
import { UtilObject } from '../../util/Object';
import { HTMLCheckBoxElement } from '../controlWC/CheckboxComponent';
import { UIUtilColor } from '../util/Color';
import { UIUtilElement } from '../util/Element';
import { UIUtilGeneral } from '../util/Util';

type MutationObserverCalls = { onAppend: () => void, onRemove: () => void };

export abstract class CalendarioGridV2Base<Extras = unknown> {
    protected contentContainer: ContainerControlTree;
    protected calendariosMap: Map<number, ICCalendarioControl>;
    private gruposArr: ICGrupo[];
    protected flagFocusItemInCurrentView: boolean
    protected vista: CCVista;
    protected id: string;
    protected callbacks: Pick<ICConfig<Extras>, "OnChangeEvents" | "OnClickEvent" | "OnChangeCalendars" | "OnInputEvent" | "OnChangeTipoVista" | "OnChangeFocusDate">;

    protected mutationARObserverControler: {
        setWrapper(wrapper: HTMLElement): void;
        wrappers: Set<HTMLElement>;
        set(key: string, target: HTMLElement, calls: MutationObserverCalls): void;
        delete(key: string): void;
        items: { [key: string]: [HTMLElement, MutationObserverCalls] };
    };
    protected resizeObserverControler: {
        set(key: string, target: HTMLElement, call: (target: HTMLElement) => void,): void;
        delete(key: string): void;
        items: { [key: string]: [HTMLElement, (target: HTMLElement) => void] };
    };

    constructor(config?: ICConfig<Extras>) {
        this.id = config.ID;
        this.flagFocusItemInCurrentView = true;
        this.vista = (() => {
            const vista = config?.VistaInicial ? CCVista[config.VistaInicial] : CCVista.MONTH;
            const d = DataUtilLocalStorage._GetItem("calendar_" + this.id, "last_view_mode", CCVista[vista])
            return CCVista[d];
        })()
        this.SetCallback("OnChangeEvents", config?.OnChangeEvents);
        this.SetCallback("OnClickEvent", config?.OnClickEvent);
        this.SetCallback("OnChangeCalendars", config?.OnChangeCalendars);
        this.SetCallback("OnInputEvent", config?.OnInputEvent);
        this.SetCallback("OnChangeTipoVista", config?.OnChangeTipoVista);
        this.SetCallback("OnChangeFocusDate", config?.OnChangeFocusDate);

        this.InitDraw();
        this.InitBaseObservers();
        this.SetCalendariosList(config?.Calendarios);
    }

    protected abstract InitDraw(): void;

    protected abstract ViewRefreshGeneral(): void;

    protected ViewToogleOrRefreshTipoVista(newVista?: CCVista) {
        if (newVista != null) {
            this.vista = newVista;
        }
        for (const k in CCVista) {
            this.contentContainer.classed("view_" + k.toLowerCase(), false);
        }
        this.contentContainer.classed("view_" + CCVista[this.vista].toLowerCase(), true);
    }

    protected ViewRefreshCalendariosFiltro() {
        const [gruposOrder, calAgrupados] = (() => {
            const calendarios = this.calendariosMap;
            const gruposFirstLevel = this.gruposArr.filter(d => (d.Id).split("_").length == 1)
            const calAgrupados = new Map<string, ICCalendarioControl[]>(gruposFirstLevel.map(d => [d.Id, []]));
            for (const [_, cal] of calendarios) {
                if (calAgrupados.has(cal.IdGrupo + "")) {
                    calAgrupados.get(cal.IdGrupo + "").push(cal);
                    continue;
                }
                console.warn("-d: GrupoNoEncontrado")
            }
            return [
                gruposFirstLevel,
                calAgrupados,
            ];
        })()

        // const fnGrupoIdCSS = (d: ICalendarioGrupo) => ("g" + d.Id);
        // const fnGrupoSelector = (d: ICalendarioGrupo) => "#" + fnGrupoIdCSS(d);

        this.contentContainer._areaCalendarios._content
            .selectAll<HTMLDivElement, ICGrupo>(":scope > .g_calendarios")
            .data(gruposOrder, d => d.Id)
            .join("div")
            .classed("g_calendarios", true)
            // .attr("class", d => "g_calendarios " + fnGrupoIdCSS(d))
            .each((gr, i, divs) => {
                const DELAYCLICK = 300;
                const div = divs[i];
                // Grupo Name
                (() => {
                    let item_group = div.querySelector<HTMLElement>(".group_item");
                    let lblGrupoName: HTMLLabelElement;
                    let checkbox: HTMLCheckBoxElement;
                    if (!gr.Descripcion) {
                        item_group?.remove();
                        return;
                    }
                    else if (!item_group) {
                        item_group = d3.create("div")
                            .classed("group_item", true)
                            .node()
                        const item_sel = d3.select(item_group);
                        checkbox = item_sel.append<HTMLCheckBoxElement>("wc-checkbox").node();
                        checkbox.setAttribute("disabled", "")
                        lblGrupoName = item_sel.append("label").node();
                        div.insertAdjacentElement("afterbegin", item_group)
                    }

                    lblGrupoName = item_group.querySelector("label")
                    lblGrupoName.textContent = gr.Descripcion
                    checkbox = item_group.querySelector("wc-checkbox")


                    const grupoChecked = Array.from(this.calendariosMap.values()).filter(d => d.IdGrupo == gr.Id).some(d => d.Checked)

                    checkbox._Checked = grupoChecked;
                    let groupControl = <ICGrupoControl>{ Id: gr.Id, Descripcion: gr.Descripcion, Calendarios: gr.Calendarios, Checked: grupoChecked }
                    groupControl = UtilObject._GettersSetters(groupControl, {
                        getChecked: () => checkbox._Checked,
                        setChecked: (val) => checkbox._Checked = val,
                    })

                    let clicks = 0;
                    let timer: NodeJS.Timeout = null;

                    item_group.onclick = () => {
                        clicks++;
                        if (clicks == 1) {
                            timer = setTimeout(() => {
                                timer = null;
                                clicks = 0;
                                groupControl.Checked = !checkbox._Checked;
                                this.CheckCalendarios(gr.Calendarios, groupControl.Checked)
                                this.ViewRefreshGeneral();
                                this.CallOnChangeCalendars();
                            }, DELAYCLICK);
                        } else if (clicks == 2) {
                            clearTimeout(timer);
                            timer = null;
                            clicks = 0;
                            this.CheckCalendarios(this.GetCalendarsSelected().map(d => d.Id), false);
                            groupControl.Checked = true;
                            this.CheckCalendarios(gr.Calendarios, groupControl.Checked)
                            this.ViewRefreshGeneral();
                            this.CallOnChangeCalendars();
                        }
                    }

                    /* item_group.onclick = () => {
                        groupControl.Checked = !checkbox._Checked;
                        this.CheckCalendarios(gr.Calendarios, groupControl.Checked)
                        this.ViewRefreshGeneral();
                        this.CallOnChangeCalendars();
                    } */
                })()
                // Grupo Items
                const calendariosGrupo = calAgrupados.get(gr.Id);
                const calendariosGrupoFinal = (() => {
                    const res: ICCalendarioControl[] = [];
                    ([null, ...gr.Tipos] || []).forEach((tipo) => {
                        if (!tipo) {
                            const calsSinTipo = calendariosGrupo.filter(d => !d.IdTipo);
                            if (calsSinTipo.length) {
                                res.push(...calsSinTipo);
                            }
                            return;
                        }
                        const calsEnTipo = calendariosGrupo.filter(d => d.IdTipo + "" == tipo.Id);
                        if (calsEnTipo.length) {
                            res.push(<ICCalendarioControl>{ IdTipo: -tipo.Id, Id: -(res.length + 1), Descripcion: tipo.Descripcion });
                            res.push(...calsEnTipo);
                        }
                    })
                    return res;
                })()
                d3.select(div).selectAll<HTMLDivElement, ICCalendarioControl>(":scope > .item:not(.group_item)")
                    .data(calendariosGrupoFinal, d => d.Id)
                    .join("div")
                    .classed("item", true)
                    .each((cal, i, items) => {
                        const item = d3.select(items[i]);
                        if (cal.IdTipo <= 0) {
                            item.classed("level", true);
                            let checkbox = item.select<HTMLCheckBoxElement>("wc-checkbox").node();
                            let iDescription: HTMLElement = item.select<HTMLElement>("i").node();

                            if (!checkbox) {
                                checkbox = item.append<HTMLCheckBoxElement>("wc-checkbox").node();
                                checkbox.setAttribute("disabled", "");
                                iDescription = item.append("i").node();
                            }
                            iDescription.textContent = cal.Descripcion;
                            const grupoChecked = Array.from(this.calendariosMap.values()).filter(d => d.IdGrupo == gr.Id && d.IdTipo == (cal.IdTipo * -1)).some(d => d.Checked)
                            checkbox._Checked = grupoChecked;
                            cal.Checked = grupoChecked;
                            cal = UtilObject._GettersSetters(cal, {
                                getChecked: () => checkbox._Checked,
                                setChecked: (val) => checkbox._Checked = val,
                            })

                            let clicks = 0;
                            let timer: NodeJS.Timeout = null;

                            item.node().onclick = () => {
                                clicks++;
                                if (clicks == 1) {
                                    timer = setTimeout(() => {
                                        timer = null;
                                        clicks = 0;
                                        cal.Checked = !checkbox._Checked;
                                        const groupLevel = this.gruposArr.find(d => d.Id == gr.Id + "_" + (cal.IdTipo * -1));
                                        if (!groupLevel) return
                                        this.CheckCalendarios(groupLevel.Calendarios, cal.Checked)
                                        this.ViewRefreshGeneral();
                                        this.CallOnChangeCalendars();
                                    }, DELAYCLICK);
                                } else if (clicks == 2) {
                                    clearTimeout(timer);
                                    timer = null;
                                    clicks = 0;
                                    this.CheckCalendarios(this.GetCalendarsSelected().map(d => d.Id), false);
                                    cal.Checked = true;
                                    const groupLevel = this.gruposArr.find(d => d.Id == gr.Id + "_" + (cal.IdTipo * -1));
                                    if (!groupLevel) return
                                    this.CheckCalendarios(groupLevel.Calendarios, cal.Checked)
                                    this.ViewRefreshGeneral();
                                    this.CallOnChangeCalendars();
                                }
                            }

                            /* item.node().onclick = () => {
                                cal.Checked = !checkbox._Checked;
                                const groupLevel = this.gruposArr.find(d => d.Id == gr.Id + "_" + (cal.IdTipo * -1));
                                if (!groupLevel) return
                                this.CheckCalendarios(groupLevel.Calendarios, cal.Checked)
                                this.ViewRefreshGeneral();
                                this.CallOnChangeCalendars();
                            } */
                            return
                        }

                        let checkbox = item.select<HTMLCheckBoxElement>("wc-checkbox").node();
                        let lbl: HTMLLabelElement = item.select<HTMLLabelElement>("label").node();
                        if (!checkbox) {
                            checkbox = item.append<HTMLCheckBoxElement>("wc-checkbox").node();
                            checkbox.setAttribute("disabled", "");
                            lbl = item.append("label").node();
                        }
                        lbl.textContent = cal.Descripcion;
                        checkbox._Checked = cal.Checked;
                        checkbox._Color = this.GetCalendarColor(cal.Id);
                        cal = UtilObject._GettersSetters(cal, {
                            getChecked: () => checkbox._Checked,
                            setChecked: (val) => checkbox._Checked = val,
                        })
                        let clicks = 0;
                        let timer: NodeJS.Timeout = null;

                        item.node().onclick = () => {
                            clicks++;
                            if (clicks == 1) {
                                timer = setTimeout(() => {
                                    timer = null;
                                    clicks = 0;
                                    cal.Checked = !checkbox._Checked;
                                    this.ViewRefreshGeneral();
                                    this.CallOnChangeCalendars();
                                }, DELAYCLICK);
                            } else if (clicks == 2) {
                                clearTimeout(timer);
                                timer = null;
                                clicks = 0;
                                this.CheckCalendarios(this.GetCalendarsSelected().map(d => d.Id), false);
                                cal.Checked = true;
                                this.ViewRefreshGeneral();
                                this.CallOnChangeCalendars();
                            }
                        }
                    })
            })
    }

    protected InitBaseObservers() {
        // RESIZE OBSERVER
        const resizeObserver = UIUtilGeneral._GetResizeObserver((entries) => {
            entries.forEach(entry => {
                const obs = (entry.target["__resize_observers__"] as { [key: string]: number });
                for (const k in obs) {
                    if (!obs[k]) continue;
                    const [target, call] = this.resizeObserverControler.items[k];
                    call(target);
                }
            })
        })
        this.resizeObserverControler = {
            set: (key, target, callback) => {
                if (!resizeObserver) return;
                this.resizeObserverControler.delete(key);
                this.resizeObserverControler.items[key] = [target, callback];
                if (!target["__resize_observers__"])
                    target["__resize_observers__"] = {};
                target["__resize_observers__"][key] = 1;
                resizeObserver.observe(target);
            },
            delete: (key) => {
                if (!resizeObserver) return;
                const [target] = this.resizeObserverControler.items[key] || [];
                if (!target)
                    return;
                resizeObserver.unobserve(target);
                delete this.resizeObserverControler.items[key];
                (target["__resize_observers__"] as { [x: string]: number })[key] = 0;
            },
            items: {},
        };

        // MUTATION OBSERVER
        const mutationObserver = UIUtilElement._GetMutationObserver((entries) => {
            entries.forEach(entry => {
                if (entry.type != "childList") return;
                const wrapper = entry.target as HTMLDivElement;
                if (!this.mutationARObserverControler.wrappers.has(wrapper)) {
                    console.warn("Mutation observer >> wrapper not found", wrapper);
                    return;
                }
                const fnEvProccess = (evNode) => {
                    const obs = (evNode["__mutation_observers__"] as { [key: string]: number });
                    for (const k in obs) {
                        if (!obs[k]) continue;
                        const [target, calls] = this.mutationARObserverControler.items[k];
                        if (evNode.isConnected)
                            calls.onAppend();
                        else
                            calls.onRemove();
                        // call(target);
                    }
                }
                entry.addedNodes.forEach(fnEvProccess)
                entry.removedNodes.forEach(fnEvProccess)
            })
        })
        this.mutationARObserverControler = {
            setWrapper: (wrapper: HTMLElement) => {
                const alreadyExist = this.mutationARObserverControler.wrappers.has(wrapper);
                this.mutationARObserverControler.wrappers.add(wrapper);
                if (alreadyExist) return;
                mutationObserver.observe(wrapper, {
                    childList: true,
                })
            },
            set: (key, target, calls) => {
                if (!mutationObserver) return;
                this.mutationARObserverControler.delete(key);
                this.mutationARObserverControler.items[key] = [target, calls];
                if (!target["__mutation_observers__"])
                    target["__mutation_observers__"] = {};
                target["__mutation_observers__"][key] = 1;
            },
            delete: (key) => {
                if (!mutationObserver) return;
                const [target] = this.mutationARObserverControler.items[key] || [];
                if (!target)
                    return;
                // mutationObserver.unobserve(target);
                delete this.mutationARObserverControler.items[key];
                (target["__mutation_observers__"] as { [x: string]: number })[key] = 0;
            },
            wrappers: new Set(),
            items: {},
        };
    }

    private SetCalendariosList(calendarios: ICCalendario[]) {
        // const currentCals = this.calendariosMap;
        const calendariosResolved: ICCalendarioControl[] = (calendarios || [])
            .map(d_ => {
                const d: ICCalendarioControl = {
                    ...d_,
                    Color: this.GetCalendarColor(d_.Id),
                    Checked: null,
                };
                const lastChechedValue = this.calendariosMap?.get(d.Id)?.Checked || false;
                const dRes = UtilObject._GettersSetters<ICCalendario, ICCalendarioControl>(d, {
                    _Checked_: lastChechedValue,
                })
                return dRes;
            });
        this.calendariosMap = new Map(calendariosResolved.map(d => [d.Id, d]));
    }

    protected CheckCalendarios(ids: number[], checked: boolean) {
        ids.forEach((idCal) => {
            const cal = this.calendariosMap.get(idCal);
            if (!cal) return
            cal.Checked = checked;
        });
    }

    protected GetCalendarsSelected() {
        return [...this.calendariosMap.values()]
            .reduce((res, d) => {
                if (d.Checked) {
                    res.push({
                        Id: d.Id,
                        IdGrupo: d.IdGrupo,
                        Descripcion: d.Descripcion,
                    })
                }
                return res;
            }, <ICCalendario[]>[]);
    }

    // ****************************************************************
    // Callbacks
    // ****************************************************************

    private SetCallback<T extends keyof typeof this.callbacks>(k: T, callback: ICConfig<Extras>[T]) {
        if (!this.callbacks) {
            this.callbacks = {} as any;
        }
        this.callbacks[k] = callback;
    }

    private CallOnChangeCalendars() {
        const { OnChangeCalendars: onChange } = this.callbacks;
        if (!onChange) return;
        const calendars = this.GetCalendarsSelected();
        onChange(calendars);
    }

    // ****************************************************************
    // ?
    // ****************************************************************

    protected GetCalendarColor(idCalendario: number, opacity = 1): string {
        if (opacity === 1 && this.calendariosMap.get(idCalendario)?.Color) {
            return this.calendariosMap.get(idCalendario).Color
        }
        return UIUtilColor._StringToRGB(idCalendario + "010101010", opacity);
    }

    // ****************************************************************
    // Public Methods & Properties
    // ****************************************************************

    public get _TipoVista(): CCVista {
        return this.vista;
    }

    public get _ControlContainer(): ContainerControlTreeShare {
        return this.contentContainer;
    }

    public _OnChangeEvents(callback: ICConfig<Extras>["OnChangeEvents"]): this {
        this.SetCallback("OnChangeEvents", callback);
        return this;
    }

    public _OnClickEvent(callback: ICConfig<Extras>["OnClickEvent"]): this {
        this.SetCallback("OnClickEvent", callback);
        return this;
    }

    public _OnChangeCalendars(callback: ICConfig<Extras>["OnChangeCalendars"]): this {
        this.SetCallback("OnChangeCalendars", callback);
        return this;
    }

    public _OnInputEvent(callback: ICConfig<Extras>["OnInputEvent"]): this {
        this.SetCallback("OnInputEvent", callback);
        return this;
    }

    public _OnOnChangeTipoVista(callback: ICConfig<Extras>["OnChangeTipoVista"]): this {
        this.SetCallback("OnChangeTipoVista", callback);
        return this;
    }

    public _OnOnChangeFocusDate(callback: ICConfig<Extras>["OnChangeFocusDate"]): this {
        this.SetCallback("OnChangeFocusDate", callback);
        return this;
    }

    public _SetGrupos(grupos: ICGrupo[]) {
        // const mapEach = (gs: ICGrupo[]) => gs.map<ICGrupo>(g => ({
        //     ...g,
        //     SubGroups: mapEach(g.Tipos || []),
        // }))
        // this.gruposArr = mapEach(grupos);
        this.gruposArr = grupos;
        this.ViewRefreshGeneral();
        return this;
    }

    public _SetCalendarios(calendarios: ICCalendario[]) {
        this.SetCalendariosList(calendarios);
        this.ViewRefreshGeneral();
        return this;
    }

    public _GetCalendarColor(idCalendario: number, opacity = 1) {
        return this.GetCalendarColor(idCalendario, opacity);
    }
}

export interface ICEvento<Extras> {
    Id: number;
    IdCalendario: number;
    Descripcion: string;
    Inicio: Date;
    Fin: Date;
    Extra?: Extras;
}
export interface ICGrupo {
    Id: string;
    Descripcion: string;
    Tipos?: Omit<ICGrupo, keyof Pick<ICGrupo, "Tipos">>[];
    Calendarios?: number[];
}
export interface ICCalendario {
    Id: number;
    IdGrupo: string;
    IdTipo?: number;
    Descripcion: string;
}

export interface ICGrupoControl extends ICGrupo {
    get Checked(): boolean;
    set Checked(val: boolean);
}
export interface ICCalendarioControl extends ICCalendario {
    Color: string;
    get Checked(): boolean;
    set Checked(val: boolean);
}
export interface ICEventoControl<Extras = any> extends ICEvento<Extras> {
    _BeforeAlteration: Pick<ICEventoControl, "Inicio" | "Fin">;
    /** `style.class = "event_item"` */
    _Element: HTMLDivElement & {
        _Content?: HTMLDivElement;
        _Datum?: ICEventoControl<Extras>;
    };
    _Intersections: ICEventoControl[];
    _IntersectionsTop: ICEventoControl[];
    _IntersectionsBody: ICEventoControl[];
    _Interactable: Interact.Interactable; // FIXME IMPLEMENTAR Y CREAR FUNCIONES PARA //NOTE ARRASTRAR Y REDIMENSIONAR
    _InteractableObserverWorking: boolean
    get _IsInteracting(): boolean;
    get _IsFullDay(): boolean;
}
export interface ICEventoInput extends Pick<ICEvento<any>, "Descripcion" | "IdCalendario" | "Inicio" | "Fin"> {
    // Omit<ICEvento<undefined>, keyof Pick<ICEvento<any>, "Id" | "Extra">> {
}

export interface ICConfig<Extras = unknown> {
    OnChangeEvents?: (evento: ICEvento<Extras>[]) => void;
    OnClickEvent?: (evento: ICEvento<Extras>, element?: HTMLElement) => void;
    OnInputEvent?: (evento: ICEventoInput) => void;
    OnChangeCalendars?: (calendars: ICCalendario[]) => void;
    OnChangeTipoVista?: (tipoVista: CCVista) => void;
    OnChangeFocusDate?: (focusDate: Date) => void;
    DrawEventPreview?: (evento: ICEvento<Extras>, element: HTMLElement) => void;
    GetEventOptions?: (evento: ICEvento<Extras>) => Omit<ICEventoOption, keyof Pick<ICEventoOption, "Id">>[];
    FechaFocoInicial?: Date;
    Grupos?: ICGrupo[]
    VistaInicial?: TipoVista;
    Calendarios?: ICCalendario[];
    Eventos?: ICEvento<Extras>[];
    ID?: string;
}
export interface ICEventoOption {
    Id?: number;
    Label: string;
    Call(): void;
}
export type ICalendarioDateBlock = Date & {
    _disabled?: boolean;
}
export type EventInteractionCallback = (result: { type: "drag" | "resize", dtStart: Date, dtEnd: Date }) => void;

enum CCVista {
    DAY = 1,
    WEEK,
    MONTH,
    YEAR,
    DIARY,
}
export type TipoVista = keyof typeof CCVista;

export type ContainerControlTreeShare = TSelectionHTML<"div"> & Readonly<ControlTree>;
type ControlTree = {
    _areaCalendarios: TSelectionHTML<"div"> & {
        readonly _header?: TSelectionHTML<"div">;
        readonly _content?: TSelectionHTML<"div">;
    }
    _areaEventosWrapper?: TSelectionHTML<"div">;
    _areaEventos: TSelectionHTML<"div"> & {
        readonly _btnExpand?: TSelectionHTML<"div">;
        // /** != NULL: Vista.DAY, Vista.WEEK */
        // readonly _header?: TSelectionHTML<"div">;
        readonly _mainArea?: TSelectionHTML<"div">;
    };
    _areaNavegacion: TSelectionHTML<"div"> & {
        readonly _currentDate?: TSelectionHTML<"div">;
    };
}
type ContainerControlTree = TSelectionHTML<"div"> & ControlTree;

export type CalendarioGridV2ControlTree = ControlTree;
export type CalendarioGridV2ContainerControlTree = ContainerControlTree;
export import CCalendarioGridV2Vista = CCVista;
