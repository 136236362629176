import * as d3 from "d3";
import { MainPage } from "../../MainPage";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import DataModuloEgresoMovimiento from "../../data/modulo/EgresoMovimiento";
import { FormGenerator } from "../controlD3/Formulario";
import { List } from "../controlD3/List";
import { ModalThings } from "../controlD3/ModalThings";
import { NotificacionV2 } from "../controlD3/NotificacionV2";
import { Table } from "../controlD3/Tabla";
import { UIUtilLang } from "../util/Language";
import { UIUtilTime } from "../util/Time";
import { UIUtilGeneral } from "../util/Util";
import { UIUtilViewFinanzaEgreso } from "./FinanzasEgreso";
import { UIUtilFormat } from "../util/Format";
import { DateV2 } from "../../util/DateV2";

export namespace UIUtilViewFinanzaEgresoPago {
    // import CModulo = Entidad.CModulo;
    // import IEscuela = Entidad.IEscuela;
    // import IEgresoPlantilla = Entidad.IFinanzaEgresoPredefinido;
    // import IProveedor = Entidad.IFinanzaProveedor;
    import ICuentaBancaria = Entidad.IFinanzaCuentaBancaria;
    // import CTipoEgreso = Entidad.CFinanzaEgresoTipo;
    // import ICategoriaEgreso = Entidad.IFinanzasEgresoCategoria;

    const { _GetReqDataArrayByName: GetReqDataArray } = DataModuloMain;
    const { _GetUIString: LangString, _Label } = UIUtilLang;

    // ****************************************************************
    // PAGO UNICO
    // ****************************************************************

    export interface IPagarFormParamsBase {
        IdEscuela: number;
        // IdMovimiento: number;
        CantidadPago: number;
        FechaEmision: string;
    }
    export interface IPagarDataForm {
        IdCuenta: number;
        FechaPago: string;
        Cantidad: number;
    }
    export function _GetFormularioPago(formParams: IPagarFormParamsBase) {
        const { IdEscuela } = formParams;
        const cuentaBancariaStringKey = "finanzacuentabancaria";
        return new FormGenerator<IPagarDataForm>()._Crear({
            LangModuleKeyInContext: UIUtilViewFinanzaEgreso._EGRESO_LANGKEY,
            LabelMaxWidth: 120,
            schema: [
                {
                    model: "IdCuenta",
                    labelText: "tag_cuenta",
                    type: "selectMaterial",
                    selectMaterialAttr: {
                        required: true,
                        valueMember: <keyof ICuentaBancaria>"Id",
                        displayMember: <keyof ICuentaBancaria>"Beneficiario",
                        ShowAndEnableSearchText: true,
                        OnChangeSearchText_GetDataValueToEval: (dato: ICuentaBancaria) => dato.Beneficiario + dato.getTipo + (dato.Numero || "") + (dato.NombreBanco || ""),
                        OnStepItemListUI: (container, dato: ICuentaBancaria) => container
                            .style("display", "block")
                            .html(""
                                + `<b>${LangString(cuentaBancariaStringKey, "tag_beneficiario")}:</b> ${dato.Beneficiario}<br>`
                                + `<b>${LangString(cuentaBancariaStringKey, "d_field_tipo")}:</b> ${dato.getTipo}`
                                + (dato.Numero ? `<br><b>${LangString(cuentaBancariaStringKey, "d_field_numero")}:</b> ${dato.Numero}` : "")
                                + (dato.NombreBanco ? `<br><b>${LangString(cuentaBancariaStringKey, "d_field_nombrebanco")}:</b> ${dato.NombreBanco}` : "")
                            ),
                        Data: () => GetReqDataArray("CuentaBancaria", true)
                            .filter(d => d.IdEscuela === IdEscuela)
                            .sort((a, b) => d3.ascending(a.Beneficiario, b.Beneficiario)),
                    },
                },
                {
                    model: "Cantidad",
                    type: "input",
                    labelText: "tag_cantidad",
                    inputAttr: {
                        type: "currency", step: "0.01", required: true, min: 1,
                        max: formParams.CantidadPago, // GetSaldoFinalFromMto(movimiento),
                        value: formParams.CantidadPago.toString(), //GetSaldoFinalFromMto(movimiento).toString(),
                    }
                },
                {
                    model: "FechaPago",
                    type: "input",
                    labelText: "tag_fechapago",
                    inputAttr: {
                        type: "date", required: true,
                        min: UIUtilTime._FmtToInputDate(formParams.FechaEmision, IdEscuela), //GetUltimaFechaAplicacionFromMto(movimiento)),
                        max: UIUtilTime._FmtToInputDate(new Date()),
                    }
                }
            ]
        })
    }

    interface IPagarFormParams extends IPagarFormParamsBase {
        Concepto: string;
        IdMovimiento: number;
    }
    export function _OpenModal_PagarEgresoMov(formParams: IPagarFormParams) { //movimiento: IEgresoMovimiento) {
        const { IdEscuela } = formParams;
        return new Promise<boolean>(resolve => {
            let pagoAgregado = false;
            ModalThings._GetModalToForm<IPagarDataForm>({
                // LangModuleKeyInContext: FinanzaEgreso.EGRESO_LANGKEY,
                Title: _Label(UIUtilViewFinanzaEgreso._EGRESO_LANGKEY, "tag_pagar_egreso", formParams.Concepto),
                Width: 400,
                OnClose: () => resolve(pagoAgregado),
                OnAccept: async (form, mt) => {
                    const dataform = form._Data;
                    const res = await DataModuloEgresoMovimiento._NuevoPago({
                        IdMovimiento: formParams.IdMovimiento,
                        Cantidad: dataform.Cantidad,
                        IdCuenta: dataform.IdCuenta,
                        FechaPago: new DateV2(UIUtilTime._GetLocalDateFromInputDateString(dataform.FechaPago))
                            ._SetTimeZoneByIdSchool(IdEscuela, true)
                            ._ToISOString(),
                    });
                    if (res.Resultado > 0)
                        pagoAgregado = true;
                    // FIXME TOMAR DESICIONES SOBRE LOS OTROS RESULTADOS
                    return res;
                },
                DrawContent: async (container, form, mt) => {
                    mt.Progress.node()._Oculto = false;
                    await MainPage._ReloadServiceAndAwaitBool(Entidad.CTipoRequest.FinanzaMetodoPago, IdEscuela);
                    await MainPage._ReloadServiceAndAwaitBool(Entidad.CTipoRequest.CuentaBancaria, IdEscuela);
                    mt.Progress.node()._Oculto = true;
                },
                GetForm: (container, mt) => _GetFormularioPago(formParams),
            })
        })
    }

    // ****************************************************************
    // PAGO MULTIPLE AGRUPADO
    // ****************************************************************

    interface IPagoMultipleAgrupado extends IPagarFormParams {
        ProveedorRazonSocial: string;
        IdProveedor: number;
        IdEscuela: number;
    }
    interface IPagoMultipleAgrupadoTbl extends IPagoMultipleAgrupado {
        CantidadPagoFinal: number;
        _input?: HTMLInputElement,
    }

    export function _PreparaMovimientos_PagoAgrupado<T extends Pick<IPagoMultipleAgrupado, "IdProveedor" | "CantidadPago" | "IdEscuela">>(movimientos: T[]) {
        let movsConAdeudo: T[] = [];
        let movsSaldados: T[] = [];
        let esMismaEscuela = true;
        let esMismoProveedor = true;

        for (const d of movimientos) {
            if (d.CantidadPago > 0)
                movsConAdeudo.push(d);
            else
                movsSaldados.push(d);
            if (esMismoProveedor && movimientos[0].IdProveedor != d.IdProveedor) {
                esMismoProveedor = false;
            }
            if (esMismaEscuela && movimientos[0].IdEscuela != d.IdEscuela) {
                esMismaEscuela = false;
            }
        }
        const validos = esMismaEscuela && esMismoProveedor;
        return {
            MovsAdeudo: validos ? movsConAdeudo : [],
            MovsSaldados: validos ? movsSaldados : [],
            EsMismaEscuela: esMismaEscuela,
            EsMismoProveedor: esMismoProveedor,
        };
    }

    export async function _OpenModal_PagoMultipleAgrupado(movimientos: IPagoMultipleAgrupado[]): Promise<boolean> {
        const [movimientosPagar, movsSaldados] = (() => {
            const filtrados = _PreparaMovimientos_PagoAgrupado(movimientos);
            return [
                filtrados.MovsAdeudo.filter(d => movimientos[0].IdProveedor == d.IdProveedor),
                filtrados.MovsSaldados.filter(d => movimientos[0].IdProveedor == d.IdProveedor),
            ]
        })()

        if (!movimientosPagar.length) {
            console.warn("Los movimientos no son válidos para pagar", movimientos);
            return false;
        }

        if (movsSaldados.length) {
            await ModalThings._GetConfirmacionBasicoV2({
                Title: "Movimientos descartados", // FIX_LANGUAGE
                Content: d3.create("div").call(cont => {
                    cont.append("div").text("Algunos registros ya han sido saldados") // FIX_LANGUAGE
                        .style("margin-bottom", "var(--padding2)")
                    new List<IPagoMultipleAgrupado>()
                        ._SetParent(cont)
                        ._SetUpdateItem((item, d) => item.text(d.Concepto))
                        ._SetItems(movsSaldados)
                        ._ListContainerSelection.style("border-top", "1px solid var(--color_borderbox1)")
                }),
                Width: 350,
                Calcelable: false,
            })
        }

        if (movimientosPagar.length == 1) {
            return _OpenModal_PagarEgresoMov(movimientosPagar[0]);
        }

        const { IdEscuela, ProveedorRazonSocial } = movimientosPagar[0];
        const { FechaEmision: strDtEmisionMaxima } = movimientosPagar.reduce((a, b) => (new Date(a.FechaEmision) > new Date(b.FechaEmision) ? a : b));
        const totalPago = movimientosPagar.reduce((res, d) => (res + d.CantidadPago), 0);
        const movimientosTbl: IPagoMultipleAgrupadoTbl[] = movimientosPagar.map(d => ({
            ...d,
            CantidadPagoFinal: d.CantidadPago,
        }))
        return new Promise((resolve, reject) => {
            let pagosAgregados = false;
            let tblPagos: Table.Tabla<IPagoMultipleAgrupadoTbl>;

            ModalThings._GetModalToForm<IPagarDataForm>({
                // LangModuleKeyInContext: FinanzaEgreso.EGRESO_LANGKEY,
                Title: _Label(UIUtilViewFinanzaEgreso._EGRESO_LANGKEY, "tag_pagoaprov", ProveedorRazonSocial),
                Width: 500,
                OnClose: () => resolve(pagosAgregados),
                DrawContent: async (container, form, mt) => {
                    tblPagos = new Table.Tabla<IPagoMultipleAgrupadoTbl>({
                        IdTabla: "FinanzasEgresos-PagoMultipleAgrupado",
                        Title: "Pago MultiAgrupado",
                        Parent: container,
                        LangModuleKeyInContext: UIUtilViewFinanzaEgreso._EGRESO_LANGKEY,
                        FilterByStrSearch: "none",
                        MinWidth: 400,
                        HideCheckboxes: true,
                        RenderColumnHeadings: [
                            { Field: "Concepto", Label: "concepto", LabelLangKey: "d_field_descripcion", MinWidth: "100px", Width: "46%", IsSortable: false },
                            { Field: "CantidadPago", Label: "saldo", LabelLangKey: "d_field_saldo", MinWidth: "100px", Width: "27%", Type: "number", Format: "currency", IsSortable: false },
                            { Field: "CantidadPagoFinal", Label: "pago", LabelLangKey: "tag_pago", MinWidth: "100px", Width: "27%", Align: "end", IsSortable: false },
                        ],
                        EvaluatorAndSubLevelsBuild: {
                            OnStepCellTable: (container, d, field: keyof IPagoMultipleAgrupadoTbl) => {
                                if (field == "CantidadPagoFinal") {
                                    container.style("width", "90%").text("");
                                    let input: HTMLInputElement = container.select<HTMLInputElement>("input").node();
                                    if (!input) {
                                        input = container.append<HTMLInputElement>("input")
                                            .attr("class", "input-form")
                                            .attr("type", "number")
                                            .attr("step", ".01")
                                            .attr("min", ".01")
                                            .style("text-align", "end")
                                            .property("value", d.CantidadPagoFinal)
                                            .node();
                                    }
                                    d._input = input;
                                    input.max = d.CantidadPago + "";
                                    input.onkeyup = () => {
                                        d.CantidadPagoFinal = input.valueAsNumber || 0;
                                        if (!d.CantidadPagoFinal)
                                            input.value = d.CantidadPagoFinal + "";
                                        resultadosCtrl._refreshView();
                                    }
                                }
                            }
                        }
                    });
                    tblPagos._Control.style("margin-top", "var(--padding3)");

                    const resultadosCtrl = {
                        tag_deuda_inicial: totalPago,
                        tag_total_pago: totalPago,
                        tag_deuda_restante: totalPago,
                        _view: new List<{ TipoStr: string, Valor: number, ValorFmt: string }>()
                            ._SetParent(container)
                            ._SetCreateTemplate((container, d) => container
                                .classed(UIUtilGeneral.FBoxOrientation.Horizontal, true)
                                .classed(UIUtilGeneral.FBoxAlign.SpacebetweenCenter, true)
                                .call((cont) => {
                                    cont.append("label").attr("class", "lbl_tipo").text(d.TipoStr);
                                    cont.append("label").attr("class", "lbl_valor").text(d.ValorFmt);
                                }))
                            ._SetUpdateItem((container, d) => container
                                .call(cont => {
                                    cont.select(".lbl_tipo").text(d.TipoStr);
                                    cont.select(".lbl_valor").text(d.ValorFmt);
                                })),
                        _toArray: () => Object.keys(resultadosCtrl)
                            .filter(k => !k.startsWith("_"))
                            .map(k => ({ TipoStr: _Label(UIUtilViewFinanzaEgreso._EGRESO_LANGKEY, k), Valor: resultadosCtrl[k], ValorFmt: UIUtilFormat._CurrencyFmt(resultadosCtrl[k]) })),
                        _refreshView: () => {
                            const tblData = tblPagos._data;
                            tblData.forEach(d => {
                                if (!d._input) return;
                                d3.select(d._input).classed("input_err", !d._input.validity.valid);
                            })
                            resultadosCtrl.tag_total_pago = tblData.reduce((res, d) => (res + d.CantidadPagoFinal), 0);
                            resultadosCtrl.tag_deuda_restante = resultadosCtrl.tag_deuda_inicial - resultadosCtrl.tag_total_pago;
                            resultadosCtrl._view._SetItems(resultadosCtrl._toArray());
                        },
                    }

                    tblPagos._UpdateData(movimientosTbl);
                    resultadosCtrl._refreshView();

                    mt.Progress.node()._Oculto = false;
                    await MainPage._ReloadServiceAndAwaitBool(Entidad.CTipoRequest.FinanzaMetodoPago, IdEscuela);
                    await MainPage._ReloadServiceAndAwaitBool(Entidad.CTipoRequest.CuentaBancaria, IdEscuela);
                    mt.Progress.node()._Oculto = true;
                },
                GetForm: (container, mt) => {
                    const form = _GetFormularioPago({
                        IdEscuela: IdEscuela,
                        FechaEmision: strDtEmisionMaxima,
                        CantidadPago: 0, // NO SE TOMA EN CUENTA
                    })
                    form._ControlsData.get("Cantidad").row.classed("hide", true);
                    return form;
                },
                OnAccept: async (form, mt) => {
                    const dataform = form._Data;
                    const dataTable = tblPagos._data;

                    await UIUtilGeneral._Sleep(100);
                    if (dataTable.some(d => !d._input.validity.valid)) {
                        NotificacionV2._Mostrar("Revisa los montos de pago", "ADVERTENCIA"); // FIX_LANGUAGE
                        return null;
                    }

                    const res = await DataModuloEgresoMovimiento._NuevoPagoAgrupado({
                        FechaPago: new DateV2(UIUtilTime._GetLocalDateFromInputDateString(dataform.FechaPago))
                            ._SetTimeZoneByIdSchool(IdEscuela, true)
                            ._ToISOString(),
                        IdCuenta: dataform.IdCuenta,
                        IdMovimientos: dataTable.map(d => d.IdMovimiento),
                        Montos: dataTable.map(d => d.CantidadPagoFinal),
                    });
                    if (res.Resultado > 0)
                        pagosAgregados = true;
                    // FIXME TOMAR DESICIONES SOBRE LOS OTROS RESULTADOS
                    return res;
                },
            })
        })
    }
}
