import { DataDRequest } from "../DRequest";
import { Entidad } from "../Entidad";
import { Global } from "../Global";
import { DataUtil } from "../util/Util";

import IUsuario = Entidad.IUsuario;
import IAsignacionUser = Entidad.IPermisoAsignacionUser;

export const _DiccUsuarios: Map<number, IUsuario> = new Map();

const IDREQUEST = Entidad.CTipoRequest.Usuario;
const URL_USUARIO = Global._GLOBAL_CONF.URL_RSTV2 + "usuarioAdmin/";

export async function _GetList(kinders: Array<Entidad.IEscuela>): Promise<DataDRequest.IRequestResponseListA<IUsuario>[]> {
    const url = URL_USUARIO + "ObtenerLista";
    const strMaxDate = await DataUtil._GetMaxDate(IDREQUEST);
    const params = {
        "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
        "Modificacion": strMaxDate,
    }

    return [await DataDRequest._Request4List(url, "GET", params, IDREQUEST)];
}

// PERMISOS
// const _requestIDPermiso = data.Entidades.CTipoRequest.UsuarioPermisos;
// type ArrayDataPermiso = Array<data.Entidades.IPermiso>;
// type RequestResultPermiso = Utils.IRequestResult<ArrayDataPermiso>;
// export let ejemploRes: Array<number> = [];

// /** @deprecated */
// export async function fn_GetListSup(idUsuario: number, Callback: (permisos: ArrayDataPermiso) => void) {
//     let url = Utils.prop_URL_RSTV1 + "Usuario/ObtenerPermisosSuper";
//     let result = await fn_DownloadList(idUsuario, _requestIDPermiso, url);

//     if (Callback)
//         Callback(result.data);
// }
// /** @deprecated */
// export async function fn_GetListLim(idUsuario: number, Callback: (permisos: ArrayDataPermiso) => void) {
//     let url = Utils.prop_URL_RSTV1 + "Usuario/ObtenerPermisosLimitado";
//     let result = await fn_DownloadList(idUsuario, _requestIDPermiso, url);

//     if (Callback)
//         Callback(result.data);
// }

// /** @deprecated */
// async function fn_DownloadList(idUsuario: number, rowID: number, url: string): Promise<RequestResultPermiso> {
//     var params = {
//         "IdUsuario": idUsuario,//data.Utils.prop_Usuario.IdUsuario,
//         "Modificacion": null
//     }

//     let result: DataDRequest.IRequestResponse<ArrayDataPermiso> = await DataDRequest.fn_Request2<ArrayDataPermiso>(url, "GET", params);

//     let list = result.data;//temp
//     let requestResult: RequestResultPermiso = { data: list, finish: null, error: result["error"] };

//     // if (result.error == null && list !== null && list.length > 0) {
//     //     let maxDateItem = list.reduce((a, b) => new Date(a.Modificacion) > new Date(b.Modificacion) ? a : b);
//     //     requestResult.finish = async () => {

//     //         // if (await Utils.fn_SaveDate(rowID, maxDateItem.Modificacion)) {
//     //         //     _requestMaxDate[rowID] = maxDateItem.Modificacion;
//     //         // }
//     //     }
//     // }
//     return requestResult;
// }

// /** @deprecated */
// function GetPermisosArray(idPerfil: number, permisos: Map<number, Map<number, Partial<Entidades.IPermiso>>>): Array<IPermisoToEdit> {
//     if (idPerfil == Entidades.CTipoPerfil.Admin) {
//         let permisosArray: Array<Entidades.IPermiso> = [];
//         permisos.forEach((escuela, idEscuela) => {
//             if (escuela.size > 0) {
//                 permisosArray.push({
//                     IdKinder: idEscuela,
//                     IdCategoria: 0,
//                     Nivel: 0
//                 })
//             }
//         })
//         return permisosArray;
//     }
//     else if (idPerfil == Entidades.CTipoPerfil.Limitado) {
//         let permisosArray: Array<Entidades.IPermiso> = [];
//         permisos.forEach(escuela => {
//             escuela.forEach(permiso => {
//                 permisosArray.push({
//                     IdKinder: permiso.IdKinder,
//                     IdCategoria: permiso.IdCategoria,
//                     Nivel: permiso.Nivel
//                 });
//             });
//         });
//         return permisosArray;
//     }
//     return []
// }

// /** @deprecated */
// export async function fn_AgregarUsuario(user: Entidades.IUsuario, permisos: Map<number, Map<number, Partial<Entidades.IPermiso>>>): Promise<DataDRequest.IResultadoPeticion<null>> {
//     const url = Utils.prop_URL_RSTV1 + "Usuario/NuevoUsuario";
//     const params = {
//         "IdOrganizacion": Utils.prop_Usuario.IdOrganizacion,
//         "IdUsuarioRegistra": Utils.prop_Usuario.IdUsuario,
//         "IdKinder": 0, // user.IdKinder,
//         "Permisos": GetPermisosArray(user.Perfil, permisos),
//         "Perfil": user.Perfil,
//         "Correo": user.Correo,
//         "Nombre": user.Nombre,
//         "ApPaterno": user.ApPaterno,
//         "ApMaterno": user.ApMaterno
//     }

//     return new Promise((resolve, reject) => {
//         DataDRequest.fn_Request2<{ Contrasenia: string, Estado: number }>(url, "POST", params).then(result => {
//             resolve({
//                 Data: null,
//                 Resultado: result?.data ? result.data.Estado : -1
//             })
//         })
//     })
// }

// interface IPermisoToEdit extends Pick<Entidades.IPermiso, "IdKinder" | "IdCategoria" | "Nivel"> { }

// /** @deprecated */
// export async function fn_ActualizarUsuario(user: Entidades.IUsuario, permisos: Map<number, Map<number, Partial<Entidades.IPermiso>>>): Promise<DataDRequest.IResultadoPeticion<null>> {
//     const url = Utils.prop_URL_RSTV1 + "Usuario/ActualizarUsuario";
//     const params = {
//         "IdUsuario": user.IdUsuario,
//         "IdOrganizacion": Utils.prop_Usuario.IdOrganizacion,
//         "IdUsuarioModifica": Utils.prop_Usuario.IdUsuario,
//         "Permisos": GetPermisosArray(user.Perfil, permisos),
//         "Perfil": user.Perfil,
//         "Correo": user.Correo,
//         "Nombre": user.Nombre,
//         "ApPaterno": user.ApPaterno,
//         "ApMaterno": user.ApMaterno
//     }

//     return new Promise((resolve, reject) => {
//         DataDRequest.fn_Request2<number>(url, "POST", params)
//             .then(result => {
//                 resolve({
//                     Resultado: (result?.data || -100)
//                 })
//             })
//     })
// }

export async function _EliminarUsuario(idUserToDelete: number): Promise<DataDRequest.IResultadoPeticion<null>> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "usuarioAdmin/Eliminar"; // "AdminJS/Usuario/EliminarUsuario";
    const params = {
        "IdUsuarioAdmin": idUserToDelete,
        "IdUsuarioElimina": DataUtil._Usuario.IdUsuario
    }

    return new Promise((resolve, reject) => {
        DataDRequest._Request2<DataDRequest.IRequestResponseBase>(url, "POST", params)
            .then(result => {
                resolve({
                    Resultado: (result.data?.Resultado || -100)
                })
            })
    })
}

export interface IItemPermisoToInsertBase {
    IdEscuela: number;
    IdPermiso: number;
}
export interface IItemPermisoToInsert extends IItemPermisoToInsertBase {
    IdModulo: number;
    IdAccion: number;
}
export async function _AgregarUsuarioV2(user: Entidad.IUsuario & { Contrasenia?: string }, permisos: Array<IItemPermisoToInsert>): Promise<DataDRequest.IResultadoPeticion<null>> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "usuarioAdmin/Nuevo";
    const params = {
        "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "Perfil": user.Perfil,
        "Correo": user.Correo.trim(),
        "Nombre": user.Nombre,
        "ApPaterno": user.ApPaterno,
        "ApMaterno": user.ApMaterno,
        "Permisos": permisos,
        "Contrasenia": user.Contrasenia
    }
    return DataDRequest._Request5(url, "POST", params, Entidad.CTipoRequest.Usuario);
}

interface IUpdateUserResponse {
    PermisosEliminados: number;
    /** Puede retornar un lenght menor al arraglo enviado si alguno de los IDs no se encuentra en Permisos-Disponibles */
    PermisosNuevos: number;
}
/**
 * @param user User Data
 * @param newPermissions
 * @param permissionsToDelete Ids_Asignaciones[]
 */
export async function _ActualizarUsuarioV2(user: Entidad.IUsuario, newPermissions: Array<IItemPermisoToInsertBase>, permissionsToDelete: Array<number>): Promise<DataDRequest.IResultadoPeticion<IUpdateUserResponse>> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "usuarioAdmin/Actualizar";
    const params = {
        //Usuario quien modifica
        "IdUsuarioAdmin": DataUtil._Usuario.IdUsuario,
        //Usuario a modificar
        "IdUsuarioEditar": user.IdUsuario,
        "Perfil": user.Perfil,
        "Correo": user.Correo.trim(),
        "Nombre": user.Nombre,
        "ApPaterno": user.ApPaterno,
        "ApMaterno": user.ApMaterno,
        "PermisosNuevos": newPermissions,
        "PermisosEliminar": permissionsToDelete
    }
    return new Promise(async (resolve) => {
        await DataDRequest._Request2<{ Datos: IUpdateUserResponse, Resultado: number }>(url, "POST", params)
            .then(res => {
                resolve({
                    Resultado: res.error ? -1 : res.data.Resultado,
                    Data: res.error ? null : res.data.Datos
                })
            })
    })
}

/**
 * 
 * @param idUser Id de usuario
 * @returns
 * * Resultado: -1 (solo si ocurre un error)
 * * Data: Siempre retorna un arreglo
 */
export async function _ObtenerPermisosUsuario(idUser: number) {
    console.warn("idUser", idUser)
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "usuarioAdmin/ObtenerPermisos";
    const params = {
        "IdUsuario": idUser
    }

    return DataDRequest._Request5<IAsignacionUser[], { Perfil: Entidad.CTipoPerfil }>(url, "GET", params, IDREQUEST);
}

export function _RecuperarContraseniaViaCorreo(idUsuario: number): Promise<DataDRequest.IResultadoPeticion<undefined>> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "usuarioAdmin/RecuperarContraseniaViaEmail";
    const params = {
        "IdUsuario": idUsuario
    }

    return new Promise(async (resolve) => {
        DataDRequest._Request(url, "POST", params, (res: DataDRequest.IRequestResponseBase) => {
            resolve({
                Resultado: res?.Resultado || -100,
                TipoRequest: Entidad.CTipoRequest.Usuario
            })
        })
    })
}

export function _RecuperarContraseniaViaSMS(idUsuario: number, telefono: string): Promise<DataDRequest.IResultadoPeticion<undefined>> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "usuarioAdmin/RecuperarContraseniaViaSMS";
    const params = {
        "IdUsuario": idUsuario,
        "Telefono": telefono
    }

    return new Promise(async (resolve) => {
        DataDRequest._Request(url, "POST", params, (res: DataDRequest.IRequestResponseBase) => {
            resolve({
                Resultado: res?.Resultado || -100,
                TipoRequest: Entidad.CTipoRequest.Usuario
            })
        })
    })
}

export default {
    _DiccUsuarios,
    _EliminarUsuario,
    _AgregarUsuarioV2,
    _ActualizarUsuarioV2,
    _ObtenerPermisosUsuario,
    _RecuperarContraseniaViaCorreo,
    _RecuperarContraseniaViaSMS
}
