import * as d3 from "d3";
import { UIUtilLang } from "../util/Language";
import { NotificacionV2 } from "./NotificacionV2";
import heic2any from "heic2any";

export namespace InputFileDialog {
    interface IConfig {
        // /** configuracion avanzada de tipos: `image/*` */
        // AcceptFiles?: string[];
        /** @example [".pdf", ".png"] */
        AcceptExtensions?: string[];
        MaxFiles?: number;
        OnError?: ((e) => void);
        OnLoad: (files: File[]) => void;
        OnStartProcess?: () => void;
    }

    export function _Open(config: IConfig) {
        const { AcceptExtensions, MaxFiles } = config;
        const input = d3.create("input")
            .attr("type", "file")
            .attr("accept", AcceptExtensions?.join(","))
            .attr("multiple", true)
            .style("position", "absolute")
            .style("opacity", "0")
            .style("top", "-5000px")
            .node();
        document.body.appendChild(input);

        input.onchange = async (e) => {
            if (config.OnStartProcess) config.OnStartProcess();
            let files = Array.from(input.files);

            if (MaxFiles && files.length > MaxFiles) {
                NotificacionV2._Mostrar(
                    UIUtilLang._GetUIString("general", "notif_maxfiles")
                        .replace("_N", MaxFiles.toString()),
                    "ADVERTENCIA"
                );
                files.splice((MaxFiles - 1), (files.length - MaxFiles));
            }

            const filesProccesedHeic: File[] = [];
            let errorProcess: Error;
            for (let itemFile of files) {
                if (itemFile.name.split('.').pop().trim().toLowerCase() == 'heic') {
                    try {
                        let fileBlob = await heic2any({
                            blob: itemFile,
                            toType: "image/png"
                        });
                        itemFile = new File(fileBlob instanceof Blob ? [fileBlob] : fileBlob, itemFile.name.replace(/\.heic$/i, ".png"), { type: "image/png", lastModified: Date.now() });
                    } catch (err) {
                        console.warn("-d, Error: Heic2PngConversion");
                        errorProcess = err;
                        break;
                    }
                }
                filesProccesedHeic.push(itemFile);
            }

            input.value = "";
            input.onchange = null;
            input.remove();
            if (errorProcess == null) {
                config.OnLoad(filesProccesedHeic);
            } else if (config.OnError) {
                config.OnError(errorProcess);
            }
        }
        input.click();
    }
}
