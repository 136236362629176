import * as d3 from "d3";

type BaseType = { [k in string]: any };
type TRadioList_Direction = "horizontal" | "vertical";
type TData<T> = T[] | (() => T[])

export type IConfigRadioList<T = BaseType> = {
    [VM in (keyof T)]: {
        Data: TData<T>
        DisplayMember: (keyof T & string);
        Name: string;
        /** @default "horizontal" */
        Direction?: TRadioList_Direction;
        Disabled?: boolean;
        ValueMember: VM;
        OnChange?: (data: T, value: T[VM]) => void;
        OnStepItemUI?: (itemContainter: TSelectionHTML<"div">, itemLabel: TSelectionHTML<"label">, d: T) => void
    }
}[(keyof T)]
interface IItemRadio {
    Data: any;
    // Checked: boolean;
}

export class RadioList<T = BaseType> {
    private config: IConfigRadioList<T>;
    private containerSelection: TSelectionHTML<"div">;
    private items: IItemRadio[];

    constructor(config: IConfigRadioList<T>) {
        this.config = config;
        if (!this.config.Disabled) {
            this.config.Disabled = false;
        }
        this.Init();
        this.UpdateDataItems(this.config.Data);
        this.UpdateUIItems();
    }

    private Init() {
        this.containerSelection = d3.create("div")
            .attr("class", "radiolist_container")
            .style("flex-direction", (this.config.Direction == "vertical" ? "column" : null));
    }

    private GetConfigData(): T[] {
        if (typeof this.config.Data == "function") {
            return this.config.Data()
        }
        return this.config.Data
    }
    private GetControlData(): T[] {
        return Array.from(this.items.values()).map(item => item.Data)
    }

    private UpdateDataItems(data: TData<T>) {
        this.config.Data = data;
        data = this.GetConfigData()
        this.items = data.map(d => ({ Data: d }))
    }

    private UpdateUIItems() {
        const fnUpdateItem = (item: TSelectionHTML<"div", IItemRadio>) => {
            item.select("input")
                .property("disabled", this.config.Disabled)
                .attr("id", d => (this.config.Name + "_" + d.Data[this.config.ValueMember]))
                .on("change", () => {
                    if (this.config.OnChange) {
                        let itemSelected = this.GetSelected();
                        this.config.OnChange((itemSelected?.Data || null), (itemSelected ? itemSelected.Data[this.config.ValueMember] : null));
                    }
                });
            item.select("label")
                .attr("for", d => (this.config.Name + "_" + d.Data[this.config.ValueMember]))
                .html(d => d.Data[this.config.DisplayMember]);
            if (this.config?.OnStepItemUI) {
                item.each((datum, i, elms) => {
                    const container = d3.select<HTMLDivElement, any>(elms[i])
                    const label = container.select<HTMLLabelElement>(":scope>label");
                    this.config.OnStepItemUI(container, label, datum.Data);
                })
            }
            return item;
        }
        this.containerSelection
            .selectAll<HTMLDivElement, IItemRadio>(":scope > .itemradio")
            .data(this.items, d => d.Data[this.config.ValueMember])
            .join(
                enter => {
                    const item = enter.append("div")
                        .attr("class", "itemradio");
                    item.append("input")
                        .attr("type", "radio")
                        .attr("name", this.config.Name);
                    item.append("label");
                    return fnUpdateItem(item);
                },
                update => fnUpdateItem(update),
                exit => exit.remove()
            )
    }

    private GetSelected() {
        let selected: IItemRadio;
        const inputsElems = this.containerSelection.selectAll<HTMLInputElement, IItemRadio>(':scope > .itemradio > input[type="radio"]').nodes();
        for (const inputElem of inputsElems) {
            if (inputElem.checked) {
                selected = d3.select(inputElem).datum() as IItemRadio;
                break;
            }
        }
        return selected;
    }

    private SetSelected(val: any) {
        const data = this.GetControlData()
        if (!data.map(d => d[this.config.ValueMember]).includes(val)) {
            this.Reset();
            return;
        }
        let id = this.config.Name + "_" + val;
        this.containerSelection.select<HTMLInputElement>(':scope > .itemradio > input[id="' + id + '"]')
            .property("checked", true);
    }

    private Reset() {
        this.containerSelection.selectAll<HTMLInputElement, IItemRadio>(':scope > .itemradio > input[type="radio"]')
            .property("checked", false);
    }

    public get _ItemSelected() {
        return this.GetSelected()?.Data || null;
    }

    public get _ItemSelectedValue() {
        let itemSelected = this.GetSelected();
        return itemSelected ? itemSelected.Data[this.config.ValueMember] : null;
    }

    public get _Name() {
        return this.config.Name;
    }

    public get _ContainerSelection() {
        return this.containerSelection;
    }

    public get _ContainerNode() {
        return this.containerSelection.node();
    }

    public get _Disabled() {
        return this.config.Disabled;
    }

    public set _Disabled(val: boolean) {
        this.config.Disabled = val;
        this.UpdateUIItems();
    }

    public _Refresh() {
        this.UpdateDataItems(this.config.Data);
        this.UpdateUIItems();
    }

    public _ResetCheck() {
        this.Reset();
        return this;
    }

    public _SetValueSelected(val: any) {
        this.SetSelected(val);
        return this;
    }

    public _SetItems(data: TData<T>) {
        this.UpdateDataItems(data);
        this.UpdateUIItems();
        return this;
    }
}
