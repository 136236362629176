import * as d3 from "d3";
import { MainPage } from "../../MainPage";
import { DataDRequest } from "../../data/DRequest";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import DataModuloEscolaridad, { IConfiguraEscolaridadResponse } from "../../data/modulo/Escolaridad";
import DataModuloGrado from "../../data/modulo/Grado";
import { CardV2Collapse, TCARDV2COLL_OnEditOriginEvent } from "../controlD3/CardV2Collapse";
import { FormGenerator, IField, Fields } from "../controlD3/Formulario";
import { RadioList } from "../controlD3/RadioList";
import { UIUtilLang } from "../util/Language";
import { UIUtilViewData } from "../util/ViewData";
import { UIUtilViewEscolaridad } from "../utilView/Escolaridad";
import { UIUtilViewEscuelas } from "../utilView/Escuelas";
import CEvento = Entidad.CTipoEvento;
import IEscolaridad = Entidad.IEscolaridad;
import IConfiguracion = Entidad.IConfigEscolaridad;

interface IGradoItem extends UIUtilViewData.IBaseData {
    IsCheck?: boolean;
}

interface IItemList {
    IsCheck: boolean;
    TextValue: string;
}

interface IOtrosItem extends IItemList {
    Type: (CEvento.Covid)
}

enum CTipoChat {
    /** No se envia */
    Nunguno = 1,
    UnaVia = 2,
    DosVias = 3
}

interface IEntradaSalidaItem {
    IsActive: boolean;
    /** Tolerancia */
    CobroTolerancia: number;
    /** Fracción en minutos */
    CobroTiempo: number;
    /** Costo por fracción */
    CobroCosto: number;
}

interface IFormData extends UIUtilViewEscolaridad.IEscolaridadConfigFormData {
    Grados: IGradoItem[];
}

const MAXGRADOS = 6;

export class UIPanelCardEscuelasNivelInfoGeneral extends CardV2Collapse<[IEscolaridad]> {
    /** currentNivel */
    private dataEscolaridad: IEscolaridad;
    private currentConfiguracionNivel: IConfiguracion;
    private dataConfigFormularioEditable: IFormData;
    private dataConfigFormularioInicial: IFormData;
    private form: FormGenerator<IFormData>;

    constructor(modulo = Entidad.CModulo.PanelConfiguracionNiveles) {
        super("", Entidad.CModulo.PanelConfiguracionNiveles);
    }

    // *********************************************************************
    // HERENCIA
    // *********************************************************************

    protected CARDCOLL_OnInitBuild(contentContainer: TSelectionHTML<"div", any, any>): void {
        this.cardHeaderSelection.remove();
        // >> Crea formulario
        this.form = new FormGenerator<IFormData>();

        type TRadioEntradaSalida = { Value: boolean, Name: string };
        type TRadioChat = { Tipo: CTipoChat, Name: string };
        let optionsToRadiosCobros: TRadioEntradaSalida[] = [{ Value: true, Name: "Sí" }, { Value: false, Name: "No" }];

        const GetEntradaSalidaRadiosFieldConfig = (model: keyof Pick<IFormData, "EntradasAnticipadas" | "SalidasRetrasadas">, text: string) => {
            return <IField<IFormData>>{
                model: model,
                labelAttr: { text: text },
                radioListAttr: {
                    ValueMember: "Value", DisplayMember: "Name",
                    // returnOnlyValueMember: true,
                    OnChange: (value: TRadioEntradaSalida) => {
                        // console.log(model, value);
                        let control = this.form._ControlsData.get(model);
                        this.dataConfigFormularioEditable[model].IsActive = value.Value;

                        if (value.Value) {
                            control.row.append(() => control.selection.node());

                            control.selection.node().scrollIntoView({
                                behavior: "smooth"
                            });
                        } else {
                            control.selection.remove();
                            control.selection.selectAll("input").property("value", 0);
                            this.dataConfigFormularioEditable[model].CobroTolerancia = 0;
                            this.dataConfigFormularioEditable[model].CobroTiempo = 0;
                            this.dataConfigFormularioEditable[model].CobroCosto = 0;
                        }
                    },
                    Data: optionsToRadiosCobros,
                },
                type: Fields.radioList
            }
        }

        this.form._Crear({
            LabelMaxWidth: 250,
            LangModuleKeyInContext: this.moduloName,
            schema: [
                { model: "Grados", type: Fields.label, labelAttr: { text: "tag_grados" } },
                {
                    model: "TipoChat",
                    type: Fields.radioList,
                    labelAttr: { text: "tag_chat" },
                    radioListAttr: {
                        ValueMember: "Tipo", DisplayMember: "Name",
                        OnChange: (value: TRadioChat) => {
                            // console.log("Tipo chat:", value.Name, CTipoChat[value.Tipo]);
                            this.dataConfigFormularioEditable.TipoChat = value.Tipo;
                        },
                        Data: [
                            { Tipo: CTipoChat.Nunguno, Name: this.CARDCOLL_GetUIStringModule("tag_no") },
                            { Tipo: CTipoChat.UnaVia, Name: this.CARDCOLL_GetUIStringModule("tag_unavia") },
                            { Tipo: CTipoChat.DosVias, Name: this.CARDCOLL_GetUIStringModule("tag_dosvias") }
                        ]
                    },
                },
                GetEntradaSalidaRadiosFieldConfig("EntradasAnticipadas", "tag_entradasanticipadas"),
                GetEntradaSalidaRadiosFieldConfig("SalidasRetrasadas", "tag_salidasanticipadas"),
                { model: "Otros", type: Fields.label, labelAttr: { text: "tag_otros" } }
            ],
            BuildView: (container, controlsForm) => {
                // -> Dibujo inicial del formulario
                container.classed("form_conf_escolaridad", true);

                // -> GRADOS ROW
                const ctrlGrados = controlsForm.get("Grados");
                ctrlGrados.selection = ctrlGrados.row.append("div")
                    .classed("area_alimentos", true);

                ctrlGrados.selection.append("div")
                    .attr("class", "cont_alimentos_inputtext");

                // -> ENTRADAS ROW, SALIDAS ROW
                const CreateEntradaSalidaRow = (className: "area_entradas" | "area_salidas", model: keyof Pick<IFormData, "EntradasAnticipadas" | "SalidasRetrasadas">) => {
                    const control = controlsForm.get(model);
                    control.selection = control.row.append("div")
                        .classed(className, true);

                    let div_entrada_tolerancia = control.selection.append("div")
                        .attr("class", "div_tolerancia")
                    div_entrada_tolerancia.append("label").text(this.CARDCOLL_GetUIStringModule("tag_tolerancia"));
                    div_entrada_tolerancia.append("input").attr("type", "number")
                        .property("min", 1)
                        .property("step", 1)

                    let div_entrada_minutos = control.selection.append("div")
                        .attr("class", "div_fraccionminutos");
                    div_entrada_minutos.append("label").text(this.CARDCOLL_GetUIStringModule("tag_fraccionmints"));
                    div_entrada_minutos.append("input").attr("type", "number")
                        .property("min", 1)
                        .property("step", 1)

                    let div_entrada_fraccioncosto = control.selection.append("div")
                        .attr("class", "div_fraccioncosto");
                    div_entrada_fraccioncosto.append("label").text(this.CARDCOLL_GetUIStringModule("tag_costofraccion"));
                    div_entrada_fraccioncosto.append("input").attr("type", "number")
                        .property("min", 0.01)
                        .property("step", 0.01)

                    return control;
                }

                const ctrlEntradas = CreateEntradaSalidaRow("area_entradas", "EntradasAnticipadas");
                const ctrlSalidas = CreateEntradaSalidaRow("area_salidas", "SalidasRetrasadas");

                const ctrlOtros = controlsForm.get("Otros");
                ctrlOtros.selection = ctrlOtros.row.append("div")
                    .classed("area_alimentos", true);

                ctrlOtros.selection.append("div")
                    .attr("class", "cont_alimentos_biberonpapilla cont_otros");

                // -> Pegar rows personalizados al formulario
                container.append(() => ctrlGrados.row.node());
                container.append(() => controlsForm.get("TipoChat").row.node());
                container.append(() => ctrlEntradas.row.node());
                container.append(() => ctrlSalidas.row.node());
                container.append(() => ctrlOtros.row.node());
            },
            Validation: (value, field, datosForm, controlsForm) => {
                let rowIsValid = true;
                let inputsText: HTMLInputElement[] = [];

                switch (field) {
                    case "Grados":
                        break;
                    case "EntradasAnticipadas":
                        if (this.dataConfigFormularioEditable.EntradasAnticipadas.IsActive) {
                            inputsText = this.form._ControlsData.get("EntradasAnticipadas").selection.selectAll<HTMLInputElement, any>("input").nodes();
                        }
                        break;
                    case "SalidasRetrasadas":
                        if (this.dataConfigFormularioEditable.SalidasRetrasadas.IsActive) {
                            inputsText = this.form._ControlsData.get("SalidasRetrasadas").selection.selectAll<HTMLInputElement, any>("input").nodes();
                        }
                        break;
                }
                for (let input of inputsText) {
                    if (!input.validity.valid) {
                        rowIsValid = false;
                    }
                }

                console.log("validando...", field, rowIsValid)
                return rowIsValid;
            }
        }, <IFormData>{}, undefined, false);

        contentContainer.append(() => this.form._Form.node());
        this.UpdateModalAndFormMode();
    }

    protected CARDCOLL_GetVariantToValidateUpdate(cardData_0: IEscolaridad): string {
        return (cardData_0.Id + "-" + cardData_0.Modificacion + "_")
            .concat(
                this.GetGradosByNivel(cardData_0.Id)
                    .map(d => (d.IdNivel + "-" + d.Modificacion))
                    .join("")
            );
    }

    private idEscuela: number;
    protected CARDCOLL_OnUpdateData(cardData_0: IEscolaridad): void {
        if (this.idEscuela != cardData_0?.IdEscuela) {
            if (this.HasActionPermission(Entidad.CAccionPermiso.Editar)) {
                this.cardActionsContainerSelection
                    .append(() => this.btnEditarCard._node);
            } else {
                this.btnEditarCard._d3Selection.remove();
            }
        }
        this.idEscuela = cardData_0?.IdEscuela;
        this.UpdateView(cardData_0);
    }

    protected CARDCOLL_MostrarBody(): void {
    }

    protected CARDCOLL_OcultarBody(): void {
    }

    protected CARDCOLL_OnEditarCard(originEvent: TCARDV2COLL_OnEditOriginEvent): void {
        if (this.dataConfigFormularioEditable.PrimerConfiguracion) { // FIXME Agregar "?" // Hacer prueba de qué pasa cuando no lo lleva
            this.dataConfigFormularioEditable.EntradasAnticipadas.IsActive = true;
            this.dataConfigFormularioEditable.SalidasRetrasadas.IsActive = true;
            this.UpdateAllFormRows();
        }
        this.UpdateModalAndFormMode();
    }

    protected CARDCOLL_OnCancelaEditarCard(originEvent: TCARDV2COLL_OnEditOriginEvent): void {
        this.ResetView();
    }

    protected async CARDCOLL_GuardarCardV2(): Promise<DataDRequest.IRequestResponseA<any>> {
        let res: DataDRequest.IRequestResponseA<IConfiguraEscolaridadResponse>

        if (this.form._GetIsValidForm()) {
            const newConfig = UIUtilViewEscolaridad._GetFormularioNivelConfigChanges(this.dataConfigFormularioEditable, this.dataConfigFormularioInicial);
            const newGradosConfig = this.GetFormularioGradosChanges();

            let fnUpdateEscolaridad = async () => {
                let res = await DataModuloEscolaridad._ActualizarEscolaridad(
                    this.dataEscolaridad.Id,
                    this.dataEscolaridad.Nombre,
                    newGradosConfig.Grados.map(d => ({
                        Id: d.Id,
                        Nombre: d.Name
                    })),
                    newGradosConfig.GradosEliminar
                );

                if (res.Resultado > 0) {
                    await MainPage._ReloadServiceAndAwaitBool(Entidad.CTipoRequest.Grado, this.dataEscolaridad.IdEscuela);
                }
                else {
                    if (res.Resultado == -1) {
                        res.Resultado = -1.1;
                    }
                    setTimeout(() => {
                        this.ctrlNotification._Mostrar(UIUtilLang._GetHTTPMessage(res, "actualizagrados"), "ADVERTENCIA");
                    }, 1500);
                }
            }

            // let fnAsignarAlimentosCalendarioGoogle = () => {
            //     let alimentosOldConfig = this.dataConfigFormularioInicial.Actividades.get(CEvento.Alimentos).IsCheck;
            //     let alimentosNewConfig = this.dataConfigFormularioEditable.Actividades.get(CEvento.Alimentos).IsCheck;

            //     if (alimentosNewConfig && alimentosOldConfig != alimentosNewConfig) {
            //         UIUtilViewEscuelas._OpenModal_Escolaridad_AsignarAlimentosACalendarioGoogle(this.dataEscolaridad);
            //     }
            // }

            res = await DataModuloEscolaridad._ConfigurarEscolaridad(this.dataEscolaridad.Id, newConfig);

            if (res.Resultado > 0) {
                // >>
                // fnAsignarAlimentosCalendarioGoogle();

                // >>
                await MainPage._ReloadServiceAndAwaitBool(Entidad.CTipoRequest.Escolaridad, this.dataEscolaridad.IdEscuela);

                if (newGradosConfig) {
                    await fnUpdateEscolaridad();
                }
            }

            res.Mensaje = UIUtilLang._GetHTTPMessage(res);
        }
        return res;
    }

    protected CARDCOLL_SyncOrGetIdToDownloadData(): DataModuloMain.TipoRequestMonitorId | (() => Promise<any>) | DataModuloMain.TipoRequestMonitorId[] {
        // return data.Entidades.CTipoRequest.Escolaridad;
        return async () => {
            await this.UpdateView(this.dataEscolaridad, true);
        }
    }

    protected CARDCOLL_GetIdSchool(cardData_0_0: IEscolaridad): number {
        return cardData_0_0?.IdEscuela;
    }

    // *********************************************************************
    // PRIVATE
    // *********************************************************************

    private async ResetView() {
        await this.UpdateDataToFormulario(this.dataEscolaridad, false);
        this.UpdateAllFormRows();
        this.UpdateModalAndFormMode();
    }

    private async UpdateView(nivel = this.dataEscolaridad, requestGrados = false) {
        await this.UpdateDataToFormulario(nivel, true, requestGrados);
        this.UpdateAllFormRows();
        this.UpdateModalAndFormMode();
    }

    private UpdateModalAndFormMode() {
        const modoEditar: boolean = this.CARDCOLL_StatusCardEditando;
        this.form._Form.classed("preview_mode", !modoEditar);
        this.form._ControlsData.forEach((control, value) => {
            const inputs = control.selection.selectAll<HTMLInputElement, any>("input");
            this.form._TabIndexMode(inputs, !modoEditar);
        });
        if (this.dataConfigFormularioInicial) {
            const gradosContainer = this.form._ControlsData.get("Grados")
                .selection
                .select(".cont_alimentos_inputtext");
            if (modoEditar) {
                gradosContainer.select("#lbl_sinconfig").remove();
            } else {
                const tieneGrados = !this.dataConfigFormularioInicial.Grados.every(d => !d.IsCheck);
                if (tieneGrados) {
                    gradosContainer.select("#lbl_sinconfig").remove();
                }
                else if (!gradosContainer.select("#lbl_sinconfig").node()) {
                    gradosContainer.append("label")
                        .attr("id", "lbl_sinconfig")
                        .text(UIUtilLang._GetUIString("general", "sinconfig"));
                }
            }
        }
    }

    // FORM THINGS

    private async UpdateDataToFormulario(nivel: IEscolaridad, resetConfig: boolean, requestGrados: boolean = false) {
        let configuracion: IConfiguracion;

        if (!resetConfig && this.dataEscolaridad?.Id == nivel?.Id) {
            configuracion = this.currentConfiguracionNivel;
        }
        this.dataEscolaridad = nivel;

        if (!configuracion && nivel) {
            const resConfigEscolaridad = await DataModuloEscolaridad._ObtenerConfiguracion(nivel.Id);

            if (resConfigEscolaridad.Resultado > 0) {
                configuracion = resConfigEscolaridad.Datos;
                this.currentConfiguracionNivel = resConfigEscolaridad.Datos
            }
            else {
                this.ctrlNotification._Mostrar(UIUtilLang._GetUIString("general", "notif_fail_infosync"), "ADVERTENCIA");
            }
        }

        if (configuracion) {
            if (requestGrados && nivel) {
                await MainPage._ReloadServiceAndAwait(Entidad.CTipoRequest.Grado, nivel.IdEscuela).catch((err) => {
                    this.ctrlNotification._Mostrar(UIUtilLang._GetUIString("general", "notif_fail_infoupdate"), "ADVERTENCIA");
                });
            }

            this.dataConfigFormularioEditable = {
                ...{
                    Grados: nivel ? this.GetGradosToFormByNivel(nivel.Id) : [],
                },
                ...(nivel ? UIUtilViewEscolaridad._GetProcessedConfiguration(nivel.IdEscuela, configuracion) : <any>{})
            };
            this.dataConfigFormularioInicial = {
                ...{
                    Grados: (nivel ? this.GetGradosToFormByNivel(nivel.Id) : []),
                },
                ...(nivel ? UIUtilViewEscolaridad._GetProcessedConfiguration(nivel.IdEscuela, configuracion) : <any>{})
            };
        }
        else {
            this.dataConfigFormularioEditable = null;
            this.dataConfigFormularioInicial = null;
        }
    }

    private UpdateAllFormRows() {
        if (this.dataConfigFormularioEditable) {
            this.form._AsignaData(this.dataConfigFormularioEditable);
            this.UpdateAreaGrados();
            this.UpdateAreaEntradaSalida("EntradasAnticipadas");
            this.UpdateAreaEntradaSalida("SalidasRetrasadas");
            this.UpdateAreaExtras();
        }
    }

    /** Reutiliza estilos del area de Alimentos */
    private UpdateAreaGrados() {
        const gradosConfig = this.dataConfigFormularioEditable.Grados;
        const ctrlGrados = this.form._ControlsData.get("Grados");

        const fnValidaRemoverGrado = (grado: IGradoItem) => {
            if (grado.Id) {
                if (DataModuloGrado._LOCALDATA_GetAlumnosEnGrado(grado.Id).length) {
                    this.ctrlNotification._Mostrar(
                        this.CARDCOLL_GetUIStringModule("notif_gradohasalumnos")
                            .replace("_GRADO", grado.Name),
                        "ADVERTENCIA"
                    );
                    return false;
                }
                else if (DataModuloGrado._LOCALDATA_GetGruposEnGrado(grado.Id).size) {
                    this.ctrlNotification._Mostrar(
                        this.CARDCOLL_GetUIStringModule("notif_gradohasgrupos")
                            .replace("_GRADO", grado.Name),
                        "ADVERTENCIA"
                    )
                    return false;
                }
            }
            return true;
        }

        // -> Area de cajas de texto de grados
        const fnUpdateItemGrado = (item: TSelectionHTML<"div", IGradoItem>) => {
            let items = item
                .classed("opaque", d => !d.IsCheck)
                .nodes();
            let checks = item.select<HTMLInputElement>(".alimento_checkbox").property("checked", d => d.IsCheck).nodes();
            let inputs = item.select<HTMLInputElement>(".alimento_inputtext")
                .property("value", d => d.Name)
                .property("required", d => d.IsCheck)
                .each((d, i, arrItems) => {
                    const inputText = arrItems[i];

                    inputText.onkeyup = (e) => {
                        if (!inputText.value.trim() && !fnValidaRemoverGrado(d)) {
                            inputText.value = d.Name; // Reset input
                            return;
                        }
                        d.Name = inputText.value;
                        checks[i].checked = Boolean(d.Name);
                        d.IsCheck = checks[i].checked;
                        d3.select(items[i]).classed("opaque", !d.IsCheck);

                        d3.select(inputText)
                            .property("required", d.IsCheck);
                    }
                })
                .nodes();

            item.select<HTMLInputElement>(".alimento_checkbox")
                .on("click", (d, i, arrItems) => {
                    if (d.IsCheck && !fnValidaRemoverGrado(d)) {
                        checks[i].checked = true; // Reset input
                        return;
                    }
                    d.IsCheck = !d.IsCheck;
                    d.Name = !d.IsCheck ? "" : d.Name;

                    checks[i].checked = d.IsCheck;
                    d3.select(inputs[i]).property("value", d.Name)
                        .property("required", d.IsCheck);

                    if (d.IsCheck) {
                        inputs[i].focus();
                    }
                    d3.select(items[i]).classed("opaque", !d.IsCheck);
                })
            return item;
        }

        ctrlGrados.selection.select(".cont_alimentos_inputtext")
            .selectAll<HTMLDivElement, IGradoItem>(":scope > .item_alimento")
            .data(gradosConfig)
            .join(
                enter => {
                    let item = enter.append("div")
                        .classed("item_alimento", true);

                    item.append("input").attr("type", "checkbox")
                        .classed("alimento_checkbox", true);

                    item.append("input").attr("type", "text")
                        .classed("alimento_inputtext", true);

                    return fnUpdateItemGrado(item);
                },
                update => fnUpdateItemGrado(update),
                exit => exit.remove()
            )
    }

    private UpdateAreaEntradaSalida(model: keyof Pick<IFormData, "EntradasAnticipadas" | "SalidasRetrasadas">) {
        const entradasalidaConfig: IEntradaSalidaItem = this.dataConfigFormularioEditable[model];
        const control = this.form._ControlsData.get(model);

        (control.instance as RadioList)._SetValueSelected(entradasalidaConfig.IsActive)

        let GetEventTextUpdate = (inputKey: keyof Omit<IEntradaSalidaItem, keyof Pick<IEntradaSalidaItem, "IsActive">>, e: Event) => {
            const input = e.target as HTMLInputElement;
            // console.log(inputKey, e.type, entradasalidaConfig[inputKey], input.valueAsNumber);
            entradasalidaConfig[inputKey] = isNaN(input.valueAsNumber) ? 0 : input.valueAsNumber;

            input.value = entradasalidaConfig[inputKey].toString();
        }
        const inputTolerancia = control.selection.select(".div_tolerancia").select<HTMLInputElement>("input")
            .property("value", entradasalidaConfig.CobroTolerancia)
            .node();

        const inputFraccionMins = control.selection.select(".div_fraccionminutos").select<HTMLInputElement>("input")
            .property("value", entradasalidaConfig.CobroTiempo)
            .node();

        const inputFraccionCosto = control.selection.select(".div_fraccioncosto").select<HTMLInputElement>("input")
            .property("value", entradasalidaConfig.CobroCosto)
            .node();

        inputTolerancia.onkeyup = (e) => GetEventTextUpdate("CobroTolerancia", e);
        // inputTolerancia.onchange = (e) => GetEventTextUpdate("CobroTolerancia", e);

        inputFraccionMins.onkeyup = (e) => GetEventTextUpdate("CobroTiempo", e);
        // inputFraccionMins.onchange = (e) => GetEventTextUpdate("CobroTolerancia", e);

        inputFraccionCosto.onkeyup = (e) => GetEventTextUpdate("CobroCosto", e);
        // inputFraccionCosto.onchange = (e) => GetEventTextUpdate("CobroTolerancia", e);

        if (entradasalidaConfig.IsActive) {
            control.row.append(() => control.selection.node());
        } else {
            control.selection.remove();
        }
    }
    private UpdateAreaExtras() {
        const otrasConfigs = this.dataConfigFormularioEditable.Otros || [];
        const ctrlOtros = this.form._ControlsData.get("Otros");

        const UpdateItem = (item: TSelectionHTML<"div", IOtrosItem>) => {
            item
                .on("click", (d, i, arrItems) => {
                    d.IsCheck = !d.IsCheck;
                    d3.select(arrItems[i]).select<HTMLInputElement>("input[type='checkbox']")
                        .node()
                        .checked = d.IsCheck;
                })
                .select<HTMLInputElement>("input[type='checkbox']")
                .each((d, i, arrCheckBox) => {
                    d3.select(arrCheckBox[i]).node().checked = d.IsCheck;
                });

            item.select("label")
                .text(d => d.TextValue);
            return item;
        }

        ctrlOtros.selection.select(".cont_otros") // .cont_alimentos_biberonpapilla
            .selectAll<HTMLDivElement, IOtrosItem>(":scope > .item_alimento")
            .data(otrasConfigs)
            .join(
                enter => {
                    let item = enter.append("div")
                        .classed("item_alimento", true);
                    item.append("input")
                        .attr("type", "checkbox");
                    item.append("label");

                    return UpdateItem(item);
                },
                update => UpdateItem(update),
                exit => exit.remove()
            )
    }

    private GetFormularioGradosChanges() {
        const gruposFin = this.dataConfigFormularioEditable.Grados;
        const gruposInicial = this.dataConfigFormularioInicial.Grados;

        if (JSON.stringify(gruposFin) == JSON.stringify(gruposInicial)) {
            return null;
        }

        return {
            Grados: gruposFin
                .filter(d => {
                    let initItem = gruposInicial.find(dI => (d.Id == dI.Id));
                    let itemIsChanged = true;
                    if (initItem && (initItem.Name == d.Name)) {
                        itemIsChanged = false;
                    }
                    return (itemIsChanged && d.IsCheck && d.Name.trim());
                }),
            GradosEliminar: gruposFin
                .filter(d => ((d.Id > 0) && !d.IsCheck))
                .map(d => d.Id)
        }
    }

    private GetGradosByNivel(idEscolaridad: number) {
        return Array.from(DataModuloEscolaridad._LOCALDATA_GetGradosDeEscolaridad(idEscolaridad).values())
            .sort((a, b) => (a.IdNivel - b.IdNivel));
    }

    private GetGradosToFormByNivel(idEscolaridad: number) {
        const idEscuela = this.idEscuela;
        const idsEscuelas10 = [118];
        const maxGrados = idsEscuelas10.includes(idEscuela) ? 10 : MAXGRADOS;
        // -> DATO GRADOS
        let gradosToForm: IGradoItem[] = [];
        let grados = this.GetGradosByNivel(idEscolaridad);
        for (let i = 0; i < maxGrados; i++) {
            let grado = grados[i];
            gradosToForm.push({
                Id: (grado?.IdNivel || undefined),
                Name: (grado?.Nombre || ""),
                IsCheck: Boolean(grado)
            })
        }
        return gradosToForm;
    }

    // *********************************************************************
    // OVERRIDES
    // *********************************************************************
}
