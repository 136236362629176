export interface ILicenciaPlantilla {
    CostoUnitario: number
    Frecuencia: number
    IVA: number
    Id: number
    /** Es arreglo, ej: "" | "1" | "1,2,3" */
    ModulosExtra: CLicenciaModuloExtra[]
    /** Ej.: "Licenciamiento mensual Kidi full" */
    Nombre: string
}

export enum CLicenciaModuloExtra {
    Finanzas = 1
}

export enum CLicenciaConfigOrganizacionTipoRegistro {
    Cargo = 1,
    IVA = 2,
}
export interface ILicenciaConfigOrganizacion {
    CostoUnitario: number
    DiaAplicacion: number
    DiasTolerancia: number
    Frecuencia: number
    IVA: number
    IdConfig: number
    IdOrganizacion: number
    InicioUTC: string
    ModulosExtra: CLicenciaModuloExtra[]
    /** Ej.: "Licenciamiento mensual Kidi full" */
    Nombre: string
    VigenciaUTC: string | null
    /** @example
     * {[IdEscuela]: {@link ILicenciaConfigOrganizacionDetalleAdeudo}}
     * */
    DetalleAdeudos: { [k: number]: ILicenciaConfigOrganizacionDetalleAdeudo }
    _GetDetalleAdeudosArray: () => ILicenciaConfigOrganizacionDetalleAdeudo[]
    _GetAdeudosExpirados: () => ILicenciaConfigOrganizacionDetalleAdeudo[]
    _HasAdeudosExpirados: () => boolean
    /** Retorna `true` si tiene licencia expirada */
    _ValidarEsEscuelaExpirada: (idEscuela: number) => boolean
}

export interface ILicenciaConfigOrganizacionDetalleAdeudo {
    IdMov: number, // 364,
    Saldo: number, // 78,
    SaldoFinal: number, // 90.48,
    /** @example "2024-07-15" */
    FechaAplicacion: string
    /** @example "2024-07-15" */
    FechaTolerancia: string
    /** @example "2024-07-15" */
    FechaInicio: string
    /** @example "2024-07-15" */
    FechaFin: string
    TipoRegistro: CLicenciaConfigOrganizacionTipoRegistro.Cargo, // 1,
    Valor: number, // 78,
    Detalles: ILicenciaCOnfigOrganizacionDetalleAdeudoDetalle[],
    /** Extra */
    _IdEscuela: number
    /** Extra */
    _EsExpirado: () => boolean,
}

export interface ILicenciaCOnfigOrganizacionDetalleAdeudoDetalle {
    IdMov: number, // 387,
    IdMovPadre: number, // 364,
    Saldo: number, // 90.48,
    TipoRegistro: CLicenciaConfigOrganizacionTipoRegistro, // 2,
    Valor: number, // 12.48
}