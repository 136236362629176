import * as d3 from "d3";
import { UIUtilIconResources } from "../util/IconResourses";
import { UIUtilLang } from "../util/Language";
import { UIUtilGeneral } from "../util/Util";

export namespace CopyElementTextControl {
    const TRANSITIONDURATION = 300;
    const DELAYTIME = 350;
    const STYLE_DIM = "28px";
    const STYLE_COLORPRIMARY1 = "var(--color_primary1)";
    const STYLE_COLORPRIMARY2 = "var(--color_primary2)";
    const STYLE_COLORTEXT = "var(--color_text2)";

    const STYLE_COLORBORDERBOX1 = "var(--color_borderbox2)";
    const STYLE_COLORBORDERBOX2 = "var(--color_borderbox2)"; // "var(--color_action1focus)";

    const STYLE_SHADOWBASE = "0px 1px 3px 1px var(--color_borderbox1)";
    const STYLE_SHADOWACTIVE = "0px 1px 3px 1px var(--color_borderbox2)";

    let btnCopyElementAux: HTMLDivElement;

    type HTMLElementParent = HTMLElement & {
        __copyTextFnEvMouseEnter?: (ev: MouseEvent) => any;
        __copyTextFnEvMouseLeave?: (ev: MouseEvent) => any;
        __copyTextTimeOutRemover?: NodeJS.Timeout;
        __copyTextTimeOutAppend?: NodeJS.Timeout;
        __copyTextGetTextAux?: () => string;
    }

    // *****************************************************************************
    // PUBLIC FUNCTIONS
    // *****************************************************************************

    export function _AddCopyTextBehaviour(element: HTMLElementParent, getTextAux: () => string) {

        if (element.__copyTextFnEvMouseEnter && element.__copyTextFnEvMouseLeave) {
            return;
        }
        else if (element.__copyTextFnEvMouseEnter || element.__copyTextFnEvMouseLeave) {
            _RemoveCopyTextBehaviour(element);
        }

        element.__copyTextGetTextAux = getTextAux;

        element.__copyTextFnEvMouseEnter = (e: MouseEvent) => {
            e.stopPropagation();
            MouseEnter(element);
            if (fnTempOver) {
                element.removeEventListener("mouseover", fnTempOver);
                fnTempOver = null;
            }
        }

        element.__copyTextFnEvMouseLeave = (e: MouseEvent) => {
            e.stopPropagation();
            MouseLeave(element);
        }

        let fnTempOver = (e: MouseEvent) => {
            e.stopPropagation();
            MouseEnter(element);
            element.removeEventListener("mouseover", fnTempOver);
            fnTempOver = null;
        }
        element.addEventListener("mouseover", fnTempOver);

        element.addEventListener("mouseenter", element.__copyTextFnEvMouseEnter);
        element.addEventListener("mouseleave", element.__copyTextFnEvMouseLeave);
    }

    export function _RemoveCopyTextBehaviour(element: HTMLElementParent) {
        RemoveBtnCopy(element);

        if (element.__copyTextFnEvMouseEnter) {
            element.removeEventListener("mouseenter", element.__copyTextFnEvMouseEnter);
        }
        if (element.__copyTextFnEvMouseLeave) {
            element.removeEventListener("mouseleave", element.__copyTextFnEvMouseLeave);
        }
        delete element.__copyTextFnEvMouseEnter;
        delete element.__copyTextFnEvMouseLeave;
        delete element.__copyTextTimeOutAppend;
        delete element.__copyTextTimeOutRemover;
        delete element.__copyTextGetTextAux;
    }

    // *****************************************************************************
    // PRIVATE FUNCTIONS
    // *****************************************************************************

    function GetBtnCopy() {
        if (!btnCopyElementAux) {
            btnCopyElementAux = d3.create("div")
                .style("width", STYLE_DIM)
                .style("height", STYLE_DIM)
                .style("position", "fixed")
                .style("border", "1px solid " + STYLE_COLORBORDERBOX1) // "var(--color_borderbox1)")
                .style("border-radius", "50%")
                .style("background-color", STYLE_COLORPRIMARY1)
                .style("box-sizing", "border-box")
                .style("padding", "3px")
                .style("cursor", "pointer")
                .style("z-index", "60")
                .style("transition", "border .2s, box-shadow .2s")
                .style("box-shadow", STYLE_SHADOWBASE)
                .node();
            d3.select(btnCopyElementAux)
                .append("img")
                .attr("src", UIUtilIconResources.CGeneral.Copy)
                .attr("draggable", false)
                .style("width", "100%")
                .style("height", "100%");

            btnCopyElementAux.onmouseenter = e => {
                btnCopyElementAux.style.backgroundColor = STYLE_COLORPRIMARY2;
                btnCopyElementAux.style.border = "1px solid " + STYLE_COLORBORDERBOX2;
                btnCopyElementAux.style.boxShadow = STYLE_SHADOWACTIVE;
            }

            btnCopyElementAux.onmouseleave = e => {
                ResetBtnCopyBaseStyle();
            }
        }
        return btnCopyElementAux;
    }

    function ResetBtnCopyBaseStyle() {
        btnCopyElementAux.style.backgroundColor = STYLE_COLORPRIMARY1;
        btnCopyElementAux.style.border = "1px solid " + STYLE_COLORBORDERBOX1;
        btnCopyElementAux.style.boxShadow = STYLE_SHADOWBASE;
    }

    function MouseEnter(element: HTMLElementParent) {
        if (element.__copyTextTimeOutRemover) {
            clearTimeout(element.__copyTextTimeOutRemover);
            element.__copyTextTimeOutRemover = null;
            return;
        }
        if (element.__copyTextTimeOutAppend) {
            clearTimeout(element.__copyTextTimeOutAppend);
            element.__copyTextTimeOutAppend = null;
        }

        element.__copyTextTimeOutAppend = setTimeout(() => {
            const btnCopyElement = GetBtnCopy();

            element.__copyTextTimeOutAppend = null;
            ResetBtnCopyBaseStyle();
            AppendElement(element, btnCopyElement);

            btnCopyElement.onclick = async (e) => {
                e.stopPropagation();
                d3.select(element)
                    .selectAll(".text_copy_result")
                    .remove();

                const text = (element.__copyTextGetTextAux ? element.__copyTextGetTextAux() : element.textContent);
                try {
                    await navigator.clipboard.writeText(text);
                    ShowResult(element, UIUtilLang._GetUIString("general", "copiedtext"));
                }
                catch (ex) {
                    ShowResult(element, "Error");
                }
                finally {
                    RemoverElementAnimation(btnCopyElement);
                }
            };
        }, DELAYTIME);
    }

    function MouseLeave(element: HTMLElementParent) {
        if (element.__copyTextTimeOutAppend) {
            clearTimeout(element.__copyTextTimeOutAppend);
            element.__copyTextTimeOutAppend = null;
            return;
        }
        if (element.__copyTextTimeOutRemover) {
            clearTimeout(element.__copyTextTimeOutRemover);
            element.__copyTextTimeOutRemover = null;
        }
        element.__copyTextTimeOutRemover = setTimeout(() => {
            RemoveBtnCopy(element);
        }, DELAYTIME);
    }

    function RemoveBtnCopy(element: HTMLElementParent) {
        const btnCopyElement = GetBtnCopy();
        btnCopyElement.onclick = null;
        RemoverElementAnimation(btnCopyElement);
        element.__copyTextTimeOutRemover = null;
    }

    function AppendElement(parent: HTMLElement, child: HTMLElement) {
        const pos = UIUtilGeneral._GetRelativePositions(parent, "right", 1);
        d3.select(parent)
            .append(() => child)
            .style("margin-left", "2px")
            .style("left", (pos.Left - 10) + "px")
            .style("top", pos.Top ? pos.Top + "px" : null)
            .style("bottom", pos.Bottom ? pos.Bottom + "px" : null)
            .style("opacity", "0")
            .transition()
            .duration(TRANSITIONDURATION)
            .style("left", pos.Left + "px")
            .style("opacity", "1")
    }

    function RemoverElementAnimation(elem) {
        d3.select(elem)
            .style("opacity", "1")
            .transition()
            .duration(TRANSITIONDURATION)
            .style("opacity", "0")
            .remove();
    }

    function ShowResult(parent: HTMLElement, text: string) {
        const resultMessageElement = d3.create("div")
            .attr("class", "text_copy_result")
            .style("position", "fixed")
            .style("font-size", "var(--fontsize_me2)")
            .style("color", STYLE_COLORTEXT)
            .style("background-color", STYLE_COLORPRIMARY1)
            .style("border", "1px solid var(--color_borderbox1)")
            .style("padding", "4px")
            .style("z-index", "50")
            .style("pointer-events", "none")
            .text(text)
            .node();

        AppendElement(parent, resultMessageElement);

        setTimeout(() => {
            RemoverElementAnimation(resultMessageElement);
        }, 900);
    }
}
