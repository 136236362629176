import * as d3 from "d3";
import { DataDRequest } from "../../data/DRequest";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import DataModuloEscuela from "../../data/modulo/Escuela";
import DataModuloFinanzaCargo from "../../data/modulo/FinanzaCargo";
import DataModuloFinanzasAsignaciones from "../../data/modulo/FinanzasAsignaciones";
import { DataUtil } from "../../data/util/Util";
import { _HttpMsgV2 } from "../../util/Labels";
import { CalendarioGridYearDateRange } from "../controlD3/CalendarioGridYearDateRange";
import { IConfigCardCollapseExcelExport } from "../controlD3/CardCollapseAdvancedTable";
import { TCARDV2COLL_OnEditOriginEvent } from "../controlD3/CardV2Collapse";
import { CardV2CollapseAdvancedTable, IConfigCardV2CollapseExcelExport } from "../controlD3/CardV2CollapseAdvancedTable";
import { ElementWrapper } from "../controlD3/ElementWrapper";
import { Fields, FormGenerator } from "../controlD3/Formulario";
import { ModalThings } from "../controlD3/ModalThings";
import { NotificacionV2 } from "../controlD3/NotificacionV2";
import { SelectV2 } from "../controlD3/SelectV2";
import { Table } from "../controlD3/Tabla";
import { HTMLIconCollapseElement } from "../controlWC/IconTogglerCollapseComponent";
import { UIUtilFormat } from "../util/Format";
import { UIUtilIconResources } from "../util/IconResourses";
import { UIUtilLang } from "../util/Language";
import { UIUtilTime } from "../util/Time";
import { UIUtilGeneral } from "../util/Util";
import { UIUtilViewData } from "../util/ViewData";
import { UIUtilViewFinanzaCargo } from "../utilView/FinanzaCargo";

import CCategoriaTipoFinanza = Entidad.CFinanzaCargoCategoria;
import CTipoValor = Entidad.CFinanzaCargoTipoValor;
import CPeriodicidadCargos = Entidad.CFinanzaCargoPeriodicidadCargos;

import IFinanzaCargo = Entidad.IFinanzaCargo;
import ICicloEscolar = Entidad.ICicloEscolar;
import IAlumno = Entidad.IAlumno;

interface IAsignacionInfoDescuento extends Entidad.IFinanzaAsignacionInfoDescuentoV2 {
    /** Auxiliar en caso de seleccion de multiples alumnos */
    IDs?: Array<number>;
}

interface IAsignacionCargoDescuentoBase extends Entidad.IFinanzaAsignacionCargoDescuentoV2 {
    Repeticiones: ICargoRepeticion[];
    /** Auxiliar */
    IdCargoPlantilla: number;
    // InfoCargo?: IFinanzaCargo;
    /** Extra */
    Total?: number;
    /** Extra */
    NoRepeticiones?: number;

    FechaInicio: string;
    FechaFin: string;

    /** Auxiliar cálculo de rangos */
    DtFechaInicio?: Date;
    /** Auxiliar cálculo de rangos */
    DtFechaFin?: Date;
}

// Data general
interface IAsignacionCargoDesc extends IAsignacionCargoDescuentoBase { }

interface ICargoRepeticion extends Entidad.IFinanzaAsignacionRepeticionV2 {
    ParentAsignacion: IAsignacionCargoDesc;
    /** Auxiliar en caso de seleccion de multiples alumnos */
    IDs?: Array<number>;
    /** Temporal_Test */
    IdCargoAsignacion?: number;
    // DescuentosInfo?: Array<IAsignacionInfoDescuentos>;
    /** Auxiliar cálculo de rangos */
    DtFechaInicio?: Date;
    /** Auxiliar cálculo de rangos */
    DtFechaFin?: Date;

    /** Auxiliar temporal de seguridad de datos
     * Sin valor cuando es para Preview
     */
    IsSafe?: boolean;
}

// Data en algún proceso
interface IAsignacionCargoDescEnProceso extends IAsignacionCargoDescuentoBase {
    Repeticiones: ICargoRepeticionEnProceso[];
}
interface ICargoRepeticionEnProceso extends ICargoRepeticion {
    ParentAsignacion: IAsignacionCargoDescEnProceso;
    EnProceso: boolean;
}

/** 
 * // NOTE: Todos los alumnos seleccionados pertenecen a la misma escuela,
 * en caso de modificarlo, los permisos lo deben de tomar en cuenta
 */
export class UIPanelCardAlumnosCargosDescuentos extends CardV2CollapseAdvancedTable<IAsignacionCargoDesc, [IAlumno[]]> {
    /** (IdAlumno | IdsAlumnos) -> Movimientos
     * * En caso de ser una seleccion multipla de alumnos, la llave será el conjunto de todos los ids "4,6,32".
     * Nota: Cualquier modificación de la cadena, implica "resetear" los datos visibles de la tabla en el proximo refresh
    */
    private cargosAsignacionesMap: Map<string, IAsignacionCargoDesc[]>;

    private idTemporalAux: number;

    private readonly puedeEliminar: boolean;

    private ctrlTablaPreview: Table.Tabla<IAsignacionCargoDesc>;
    constructor(modulo: Entidad.CModulo.PanelFinanzasCargosDescuentos) {
        super("", modulo);
        this.idTemporalAux = Date.now();
        this.cargosAsignacionesMap = new Map();
        this.puedeEliminar = [Entidad.CTipoPerfil.Admin, Entidad.CTipoPerfil.SuperUsuario]
            .includes(DataUtil._Usuario.Perfil);
    }

    protected CARDCOLLADTAB_Table_GetMenuTop(): Table.ITableMenuTopDefaultOptionConfig[] {
        let opciones: Array<Table.ITableMenuTopDefaultOptionConfig> = [];

        if (this.HasActionPermission(Entidad.CAccionPermiso.Agregar)) {
            opciones.push({
                Label: "action_addcargo",
                Callback: () => this.OpenModal_AsignarCargoPeriodico() // this.ModalAsigCargoInit(),
                // OnValidateDisabling: () => this.HabilitarOpciones
            })
        }
        if (this.HasActionPermission(Entidad.CAccionPermiso.AgregarCargosUnicos)) {
            opciones.push({
                Label: "action_addcargounic",
                Callback: () => this.OpenModal_AsignarCargoUnico()//this.ModalAsigCargoUnicoInit(),
                // OnValidateDisabling: () => this.HabilitarOpciones
            })
        }
        return opciones
    }
    protected CARDCOLLADTAB_GetExportarConfig(dataTable: IAsignacionCargoDesc[]): IConfigCardV2CollapseExcelExport<any> | Promise<IConfigCardV2CollapseExcelExport<any>> {
        if (this.GetStudentsCount() !== 1) {
            this.ctrlNotification._Mostrar(this.CARDCOLL_GetUIStringModule("export_fail_multialumnos"), "ADVERTENCIA");
            return null;
        }
        interface IDataToExport extends Pick<IAsignacionCargoDesc, "StrPeriodicidad"> {
            Nombre?: string;
            Repeticiones?: string;
            Descuento?: string;
            Valor?: string;
            DtFechaInicio?: string;
            DtFechaFin?: string;
            Aplicacion?: string;
            Total?: string;
            // Procesado?: string; // FIXME
        };
        const hasDataSelected = (this.ctrlTabla._dataChecked.length > 0);
        const alumno = this.GetCurrentStudents()[0];

        return <IConfigCardCollapseExcelExport<IDataToExport>>{
            FileName: alumno.NombreCompleto + " - " + UIUtilViewData._GetStr_Modulo(this.modulo),
            IdsEscuelas: [alumno.IdKinder],
            TypeRequest: Entidad.CTipoRequest.Alumno,
            ColumnsConfig: [
                { Field: "Nombre", HeaderTag: this.CARDCOLL_GetUIStringModule("tag_cargo") },
                { Field: "Repeticiones", HeaderTag: this.CARDCOLL_GetUIStringModule("d_field_norepeticiones") },
                { Field: "Descuento", HeaderTag: this.CARDCOLL_GetUIStringModule("tag_desc") },
                { Field: "Valor", HeaderTag: this.CARDCOLL_GetUIStringModule("d_field_valor") },
                { Field: "StrPeriodicidad", HeaderTag: this.CARDCOLL_GetUIStringModule("d_field_strperiodicidad") },
                { Field: "DtFechaInicio", HeaderTag: this.CARDCOLL_GetUIStringModule("d_field_dtfechainicio") },
                { Field: "DtFechaFin", HeaderTag: this.CARDCOLL_GetUIStringModule("d_field_dtfechafin") },
                { Field: "Aplicacion", HeaderTag: this.CARDCOLL_GetUIStringModule("d_field_aplicacion") },
                { Field: "Total", HeaderTag: this.CARDCOLL_GetUIStringModule("d_field_total") },
            ],
            OnGetDataBySheets: async () => {
                let datosExport: IDataToExport[] = [];

                dataTable.forEach(cargoAsig => {
                    let repeticiones: ICargoRepeticion[] = [];

                    if (hasDataSelected) {
                        this.ctrlTabla._SelectItemData(cargoAsig.ID)
                            .GetChildrenData<ICargoRepeticion>()
                            .forEach(dRepeticion => {
                                if (dRepeticion.IsCheked) {
                                    repeticiones.push(dRepeticion.Data);
                                }
                            })
                    } else {
                        cargoAsig.Repeticiones.forEach(repeticion => {
                            repeticiones.push(repeticion);
                        })
                    }
                    let tagNoRepeticiones = cargoAsig.NoRepeticiones.toString();
                    if (cargoAsig.NoRepeticiones != repeticiones.length) {
                        tagNoRepeticiones = repeticiones.length + "/" + cargoAsig.NoRepeticiones.toString();
                    }

                    datosExport.push({
                        Nombre: cargoAsig.Nombre,
                        Valor: UIUtilFormat._CurrencyFmt(cargoAsig.Valor),
                        StrPeriodicidad: cargoAsig.StrPeriodicidad,
                        DtFechaInicio: (cargoAsig.DtFechaInicio ? UIUtilTime._DateFormatStandar(cargoAsig.DtFechaInicio) : ""),
                        DtFechaFin: (cargoAsig.DtFechaFin ? UIUtilTime._DateFormatStandar(cargoAsig.DtFechaFin) : ""),
                        Repeticiones: tagNoRepeticiones,
                        Total: UIUtilFormat._CurrencyFmt(cargoAsig.Total)
                    })

                    repeticiones
                        .forEach((repeticion: ICargoRepeticion) => {
                            datosExport.push({
                                // Nombre: cargoAsig.Nombre
                                Repeticiones: cargoAsig.Nombre,
                                Valor: UIUtilFormat._CurrencyFmt(cargoAsig.Valor),
                                DtFechaInicio: (repeticion.DtFechaInicio ? UIUtilTime._DateFormatStandar(repeticion.DtFechaInicio) : ""),
                                DtFechaFin: (repeticion.DtFechaFin ? UIUtilTime._DateFormatStandar(repeticion.DtFechaFin) : ""),
                                Aplicacion: (repeticion.Aplicacion ? UIUtilTime._DateFormatStandar(repeticion.Aplicacion) : ""),
                            })

                            repeticion.Descuentos
                                .forEach(datoDesc => {
                                    // const valorDescuento = this.GetValorDescuentoRelativo(datoDesc, repeticion.Descuentos, cargoAsig.Valor);
                                    datosExport.push({
                                        Descuento: datoDesc.Nombre,
                                        Valor: ("-" + UIUtilFormat._CurrencyFmt(datoDesc.Descuento))
                                    })
                                })
                        });
                });

                return [{
                    IdSheet: alumno.IdKinder, // IdEscuela
                    SheetName: alumno.NombreCompleto,
                    Data: datosExport,
                }]
            },
            OnGetEscuelasTagInSheet: (datos) => DataModuloEscuela._DiccEscuela.get(alumno.IdKinder).Nombre
        }
        /* if (this.GetStudentsCount() == 1) {
        } else {
            return null;
        } */
    }
    protected CARDCOLLADTAB_Table_GetConfig(): Omit<Table.IConfig<IAsignacionCargoDesc>, "Parent"> {
        return {
            IdTabla: "AlumnosPanelCargosDescuentos",
            // Title: getColumnas.Title,
            StickyCheckInRow: true,
            IdData: "ID",
            // OrderDefault: { Type: controlD3.Table.CStatusOrder.Asc, Field: "Id" },
            MinWidth: 1250,
            RenderColumnHeadings: [
                { Field: "Nombre", Label: "Nombre", Width: "25%", MinWidth: "150px" },
                // { Field: "Tipo", Label: "Tipo", Width: "48%", MinWidth: "100px" },
                { Field: "Valor", Label: "Valor", Width: "10%", MinWidth: "80px" },
                { Field: "StrPeriodicidad", Label: "Periodicidad", Width: "10%", MinWidth: "100px" },
                { Field: "DtFechaInicio", Label: "Inicio", Width: "10%", MinWidth: "100px" },
                { Field: "DtFechaFin", Label: "Fin", Width: "10%", MinWidth: "100px" },
                { Field: "Aplicacion" as any, Label: "Aplicación", Width: "10%", MinWidth: "100px" }, // Level 2
                { Field: "NoRepeticiones", Label: "Repeticiones", Width: "10%", MinWidth: "100px" },
                { Field: "Total", Label: "Total", Width: "10%", MinWidth: "100px" },
            ],
            OptionsTopDefaultV2: {
                MaxOptionsInRow: 2,
                Options: []
            },
            OptionsOfDataCheckV3: (datos) => {
                if (datos?.length) {
                    let datosAcciones = this.UI_TableGetSelectedDataMenuLvOne("top-selected", datos);
                    return this.CARDCOLLADTAB_GetTableOptionsToSelectedData(this.GetIdEscuela(), "top-selected", datosAcciones);
                }
                return null;
            },
            AddNameFieldClassToCells: true,
            EvaluatorAndSubLevelsBuild: this.GetTableConfigCollapseRows(true),
            OnValueSelectRow: (idDatum, dato, isSelected) => {
                console.debug("Card_NinioFinanza -> ", "OnValueSelectRow", isSelected, dato);
            }
        }
    }
    protected CARDCOLL_OnInitBuild(container: TSelectionHTML<"div", any, any>): void {
        this.btnEditarCard._d3Selection.remove();
        this.cardFooterSelection.remove();

        container.selectAll(".tabla_container").remove();

        this.CARDCOLLADTAB_CreateTable(container);

        this.ctrlTabla._Control
            .classed("tablafilas_coloresintercalados", true);
    }

    protected CARDCOLL_GetVariantToValidateUpdate(cardData_0: IAlumno[]): string {
        return cardData_0[0].IdChild.toString();
    }
    protected CARDCOLL_OnUpdateData(cardData_0: IAlumno[]): void | Promise<void> {
        return this.UI_UpdateCardData(true);
    }
    protected CARDCOLL_MostrarBody(): void {
        this.cardSelection.style("height", "100%");
        this.UI_UpdateCardData(true);
    }
    protected CARDCOLL_OcultarBody(): void {
        this.cardSelection.style("height", null);
    }
    protected CARDCOLL_OnEditarCard(originEvent: TCARDV2COLL_OnEditOriginEvent): void {
        //throw new Error("Method not implemented.");
    }
    protected CARDCOLL_OnCancelaEditarCard(originEvent: TCARDV2COLL_OnEditOriginEvent): void {
        //throw new Error("Method not implemented.");
    }
    protected CARDCOLL_GuardarCardV2(): Promise<DataDRequest.IRequestResponseA<any>> {
        throw new Error("Method not implemented.");
    }
    protected CARDCOLL_SyncOrGetIdToDownloadData(): DataModuloMain.TipoRequestMonitorId | (() => Promise<any>) | DataModuloMain.TipoRequestMonitorId[] {
        return () => this.UI_UpdateCardData();
    }
    protected CARDCOLL_GetIdSchool(cardData_0_0: IAlumno[]): number {
        return cardData_0_0[0].IdKinder;
    }

    // ************************************************************************
    // PROCESO: ELIMINAR CARGOS (REPETICIONES)
    // ************************************************************************

    private OpenModal_EliminarAsignacionesCargos(datos: ICargoRepeticion[]) {
        this.CARDCOLLAD_OpenModal_ProccessArrayData<ICargoRepeticion>({
            DataToProccess: datos,
            OnGetIdEscuela: (dato) => this.GetIdEscuela(),
            OnError_GetItemDataTag: (dato) => {
                let tag = `${this.CARDCOLL_GetUIStringModule("tag_cargo")}: <b>${dato.ParentAsignacion.Nombre}</b>`;
                if (dato.ParentAsignacion.Periodicidad != CPeriodicidadCargos.Unico) {
                    tag += `<br>${this.CARDCOLL_GetUIStringModule("tag_periodo")}: ${UIUtilTime._DateFormatStandar(new Date(dato.FechaInicio))} - ${UIUtilTime._DateFormatStandar(new Date(dato.FechaFin))}`;
                }
                return tag + `<br>${this.CARDCOLL_GetUIStringModule("tag_aplicacion")}: ${UIUtilTime._DateFormatStandar(new Date(dato.Aplicacion))}`
            },
            OnStepAProccess: null,
            TypeRequest: null,
            OnStepAllProccess: (datos) => {
                return this.Sv_EliminarCargosAsignados_Repeticiones(datos);
            },
            OnEndAndCloseProccess: async (correctos, incorrectos) => {
                if (correctos.length > 0) {
                    this.UI_UpdateCardData(true);
                }
            }
        })
    }

    // ************************************************************************
    // PROCESO: APLICAR CARGO
    // ************************************************************************

    private OpenModal_AplicarCargos(datos: ICargoRepeticion[]) {
        let datosProcesar = datos.filter(d => (this.GetTotalSaldoByCargoRep(d) > 0));
        let datosCargoCero = datos.filter(d => (this.GetTotalSaldoByCargoRep(d) == 0));

        if (datosProcesar.length == 0) {
            this.ctrlNotification._Mostrar("Error", "ADVERTENCIA");
            return;
        }

        const fnGetCargoTag = (cargo: ICargoRepeticion, container: TSelectionHTML<"div">) => {
            container.classed(UIUtilGeneral.FBoxOrientation.Vertical, true);

            let lblNombre = container.append("label")
                .text(`${this.CARDCOLL_GetUIStringModule("tag_cargo")}:`)
                .append("span")
                .text(" " + cargo.ParentAsignacion.Nombre);

            if (this.GetTotalSaldoByCargoRep(cargo) == 0) {
                lblNombre
                    .style("color", "red")
                    .style("cursor", "help")
                    .append("img")
                    .attr("src", UIUtilIconResources.CGeneral.InfoMark)
                    .attr("draggable", false)
                    .style("margin-left", "5px")
                    .style("width", "14px");

                lblNombre
                    .append("wc-tooltip")
                    .attr("max-width", "150px")
                    .attr("position", "right")
                    .html(`<span style="color:red;">${this.CARDCOLL_GetUIStringModule("tag_noaplica")}.</span> <br>${this.CARDCOLL_GetUIStringModule("tag_cargocero")}`);
            }

            if (cargo.ParentAsignacion.Periodicidad != CPeriodicidadCargos.Unico) {
                container.append("label")
                    .text(`${this.CARDCOLL_GetUIStringModule("tag_periodo")}: ${UIUtilTime._DateFormatStandar(new Date(cargo.FechaInicio))} - ${UIUtilTime._DateFormatStandar(new Date(cargo.FechaFin))}`);
            }
            container.append("label")
                .text(`${this.CARDCOLL_GetUIStringModule("tag_aplicacion")}: ${UIUtilTime._DateFormatStandar(new Date(cargo.Aplicacion))}`);
        }

        this.CARDCOLLAD_OpenModal_ProccessArrayData<ICargoRepeticion>({
            DataToProccess: datosProcesar,
            OnGetIdEscuela: (dato) => this.GetIdEscuela(),
            AccionToHttpMessage: "default", // NOTE No existe el tag
            Title: "action_aplicarcargo",
            Action: Entidad.CAccionPermiso.Agregar,
            OnDrawContent: (container, mt) => {
                container
                    .attr("class", UIUtilGeneral.FBoxOrientation.Vertical)
                    .style("row-gap", "10px")
                    .append("div")
                    .attr("class", UIUtilGeneral.FBoxOrientation.Vertical)
                    .style("row-gap", "10px")
                    .style("overflow", "hidden auto")
                    .style("max-height", "400px")
                    .selectAll(".itemcargo")
                    .data([...datosProcesar, ...datosCargoCero])
                    .join(
                        enter => enter.append("div")
                            .attr("class", "itemcargo")
                            .each((d, i, arr) => {
                                fnGetCargoTag(d, d3.select(arr[i]));
                            })
                    )
            },
            Message: "confirma_aplicarcargo",
            OnError_GetItemDataTag: (dato, container) => fnGetCargoTag(dato, container),
            OnStepAllProccess: (datos) => DataModuloFinanzasAsignaciones._AplicarCargos(datos.map(d => d.ID)),
            OnStepAProccess: null, // (dato) => data.modulos.FinanzasAsignaciones.fn_AplicarCargos(dato.ID),
            TypeRequest: null,
            OnEndAndCloseProccess: async (correctos) => {
                if (correctos.length) {
                    await this.UI_UpdateCardData();
                }
            }
        })
    }

    // ************************************************************************
    // PROCESO: ASIGNACIÓN DE CARGOS PERIÓDICOS
    // ************************************************************************

    private OpenModal_AsignarCargoPeriodico() {
        type IDataForm = {
            Periodo: string;
            IdsCargos: number[];
            // IdCicloEscolar: number;
        }
        const cargosList = this.GetCargosList("periodicos");

        if (cargosList.length == 0) { // filter(d => d.Periodicidad != CPeriodicidadCargos.Unico).length == 0) {
            this.ctrlNotification._Mostrar(this.CARDCOLL_GetUIStringModule("notif_nocargosregistds"), "ADVERTENCIA");
            return;
        }

        const ciclosEscolaresList = this.GetCiclosEscolaresList();

        if (ciclosEscolaresList.length == 0) {
            this.ctrlNotification._Mostrar(
                this.CARDCOLL_GetUIStringModule("notif_escsinciclosesc")
                    .replace("_ESCUELA", this.GetCurrentStudents()[0].NombreKinder),
                "ADVERTENCIA"
            );
            return;
        }

        // To step 1
        let ctrlSelectCicloEscolar: SelectV2<ICicloEscolar, "Id">;
        let ctrlCalendarRangeSelection: CalendarioGridYearDateRange.CalendarioGridYearDateRange;
        // To step 2
        let ctrlForm: FormGenerator<IDataForm>;
        // To step 3
        let ctrlTabla: Table.Tabla<IAsignacionCargoDesc>;

        ModalThings._GetModalToALongProccess({
            Modulo: this.modulo,
            Action: Entidad.CAccionPermiso.Agregar,
            IdsEscuelas: [this.GetIdEscuela()],
            AccionToHttpMessage: "Agregar",
            StartEndIds: ["cargo_periodo", "preview"],
            StepsConfig: [
                {
                    Id: "cargo_periodo",
                    NextID: "cargos",
                    Title: "tag_periodo",
                    Width: 800,
                    OnDrawContent: (content, mt) => {
                        let initCicloEscolar = this.GetCurrentCicloEscolar(ciclosEscolaresList);
                        content
                            .classed(UIUtilGeneral.FBoxOrientation.Vertical, true);

                        // >> Build Calendar Range selection

                        ctrlCalendarRangeSelection = new CalendarioGridYearDateRange.CalendarioGridYearDateRange(
                            content,
                            {
                                ContainerToRedimention: content,
                                FistMonthIsActualMonth: false,
                            }
                        );

                        ctrlCalendarRangeSelection._minDate = new Date(initCicloEscolar.FechaInicio);
                        ctrlCalendarRangeSelection._maxDate = new Date(initCicloEscolar.FechaFin);
                        ctrlCalendarRangeSelection._UpdateViewMonthsYear(ctrlCalendarRangeSelection._minDate?.getFullYear());

                        setTimeout(() => {
                            ctrlCalendarRangeSelection._control.raise();
                        });

                        // >> Build select ciclo escolar

                        ctrlSelectCicloEscolar = new SelectV2<ICicloEscolar, "Id">({
                            Parent: content,
                            Data: ciclosEscolaresList,
                            ValueMember: "Id",
                            DisplayMember: "Nombre",
                            Type: "monoselect",
                            OnSelect: (dato) => {
                                const timeRangeSelected = ctrlCalendarRangeSelection._GetResultSelected();

                                ctrlCalendarRangeSelection._minDate = new Date(dato.FechaInicio); // auxMinDate && auxMinDate >= new Date() ? auxMinDate : new Date();
                                ctrlCalendarRangeSelection._maxDate = new Date(dato.FechaFin);

                                ctrlCalendarRangeSelection._UpdateViewMonthsYear(ctrlCalendarRangeSelection._minDate?.getFullYear());

                                if (
                                    (ctrlCalendarRangeSelection._minDate <= timeRangeSelected.StartDate && ctrlCalendarRangeSelection._minDate <= timeRangeSelected.EndDate)
                                    && (ctrlCalendarRangeSelection._maxDate >= timeRangeSelected.StartDate && ctrlCalendarRangeSelection._maxDate >= timeRangeSelected.EndDate)
                                ) {
                                    // console.log("Carga con selectores")
                                    ctrlCalendarRangeSelection._SetPosicionSelectores(timeRangeSelected.StartDate, timeRangeSelected.EndDate);
                                }
                                else {
                                    // console.log("Carga sin selectores")
                                    ctrlCalendarRangeSelection._SetPosicionSelectores(null, null);
                                }
                            }
                        });

                        content.append(() =>
                            ElementWrapper._WrapperToSelectControl(ctrlSelectCicloEscolar, this.CARDCOLL_GetUIStringModule("tag_cicloesc"))
                                .style("width", "50%")
                                .style("min-width", "380px")
                                .node()
                        );

                        ctrlSelectCicloEscolar._valueSelect(initCicloEscolar.Id);
                    },
                    OnValideStep: (content, mt) => {
                        const timeRangeSelected = ctrlCalendarRangeSelection._GetResultSelected();

                        if (timeRangeSelected.StartDate && timeRangeSelected.EndDate) {
                            return true;
                        }
                        else {
                            NotificacionV2._Mostrar(this.CARDCOLL_GetUIStringModule("notif_selectdts"), "ADVERTENCIA");
                        }
                        return false;
                    }
                },
                {
                    Id: "cargos",
                    PreviousID: "cargo_periodo",
                    NextID: "preview",
                    Title: "tag_cargos",
                    OnDrawContent: (content, mt) => {
                        ctrlForm = new FormGenerator<IDataForm>()
                            ._Crear({
                                LabelMaxWidth: 120,
                                LangModuleKeyInContext: this.moduloName,
                                schema: [
                                    {
                                        model: "Periodo",
                                        type: Fields.input,
                                        labelAttr: { text: "tag_periodo" },
                                        inputAttr: { type: "text", disabled: true, removeBorder: true }
                                    },
                                    {
                                        model: "IdsCargos",
                                        type: Fields.selectMaterial,
                                        labelAttr: { text: "tag_cargos2" },
                                        selectMaterialAttr: {
                                            valueMember: "ID",
                                            displayMember: "Nombre",
                                            ShowAndEnableSearchText: true,
                                            multiselect: true,
                                            ShowNSelectedInList: true,
                                            required: true
                                        },
                                        values: cargosList
                                    },
                                ],
                                //Validation: (val, field) => (field == "IdsCargos" ? Boolean((val as [])?.length) : true)
                            }, <IDataForm>{});

                        content.append(() => ctrlForm._Form.node());
                    },
                    OnFocusContent: (content, mt) => {
                        let timeRangeSelected = ctrlCalendarRangeSelection._GetResultSelected();
                        let tagPeriodo = UIUtilTime._DateFormatStandar(timeRangeSelected.StartDate) + " - " + UIUtilTime._DateFormatStandar(timeRangeSelected.EndDate);

                        ctrlForm._ControlsData.get("Periodo")
                            .selection
                            .property("value", tagPeriodo);
                    },
                    OnValideStep: () => ctrlForm._GetIsValidForm()
                },
                {
                    Id: "preview",
                    PreviousID: "cargos",
                    Title: "tag_prevcargosaplicar",
                    Width: 1300,
                    Height: "800px",
                    OnDrawContent: (content, mt) => {
                        content.append("label")
                            .html(`<b>${this.CARDCOLL_GetUIStringModule("tag_periodo")}:</b>`)
                            .append("span");

                        ctrlTabla = this.GetPrevisualizacionTabla(content);
                    },
                    OnFocusContent: (content, mt) => {
                        let timeRangeSelected = ctrlCalendarRangeSelection._GetResultSelected();

                        content.select(":scope > label > span")
                            .text(" " + UIUtilTime._DateFormatStandar(timeRangeSelected.StartDate) + " - " + UIUtilTime._DateFormatStandar(timeRangeSelected.EndDate));

                        let previewData = this.GetCargosPorAsignacionACargar(ctrlForm._Data.IdsCargos, timeRangeSelected.StartDate, timeRangeSelected.EndDate);

                        ctrlTabla._UpdateData(previewData);
                    },
                    OnAccept: async (mt) => {
                        let idCicloEscolar = ctrlSelectCicloEscolar._dataValueMemberSelected[0];
                        let asignacionesNuevas = ctrlTabla._data;

                        let res = await this.Sv_RegistrarAsignacionesCargos(idCicloEscolar, asignacionesNuevas);

                        if (res.Resultado > 0) {
                            this.UI_UpdateCardData(true);
                        }

                        return res;
                    }
                }
            ]
        })
    }

    // ************************************************************************
    // PROCESO: ASIGNACIÓN DE CARGOS POR FECHA ÚNICA
    // ************************************************************************

    private OpenModal_AsignarCargoUnico() {
        type IDataForm = {
            IdsCargos: number[];
            IdCicloEscolar: number;
            FechaAplicacion: string;
        };

        const cargosList = this.GetCargosList("unicos");

        if (cargosList.length == 0) {
            this.ctrlNotification._Mostrar(this.CARDCOLL_GetUIStringModule("notif_nocargosregistds"), "ADVERTENCIA");
            return;
        }

        const ciclosEscolaresList = this.GetCiclosEscolaresList();

        if (ciclosEscolaresList.length == 0) {
            this.ctrlNotification._Mostrar(
                this.CARDCOLL_GetUIStringModule("notif_escsinciclosesc")
                    .replace("_ESCUELA", this.GetCurrentStudents()[0].NombreKinder),
                "ADVERTENCIA"
            );
            return;
        }

        let form: FormGenerator<IDataForm>;
        let table: Table.Tabla<IAsignacionCargoDesc>;

        ModalThings._GetModalToALongProccess({
            Modulo: this.modulo,
            Action: Entidad.CAccionPermiso.AgregarCargosUnicos,
            IdsEscuelas: [this.GetIdEscuela()],
            AccionToHttpMessage: "Agregar",
            StartEndIds: ["form", "preview"],
            StepsConfig: [
                {
                    Id: "form",
                    Title: "tag_cargos",
                    NextID: "preview",
                    // Width: 500,
                    OnDrawContent: (content, mt) => {
                        let initCicloEscolar = this.GetCurrentCicloEscolar(ciclosEscolaresList);
                        let initMin = UIUtilTime._FmtToInputDate(initCicloEscolar.FechaInicio, { removeZ: true });
                        let initMax = UIUtilTime._FmtToInputDate(initCicloEscolar.FechaFin, { removeZ: true });
                        let initDataForm = <IDataForm>{
                            IdCicloEscolar: initCicloEscolar.Id
                        }

                        form = new FormGenerator<IDataForm>()
                            ._Crear({
                                LabelMaxWidth: 150,
                                //Validation: (val, field) => (field == "IdsCargos" ? Boolean((val as [])?.length) : Boolean(val)),
                                LangModuleKeyInContext: this.moduloName,
                                schema: [
                                    {
                                        model: "IdsCargos",
                                        type: Fields.selectMaterial, labelAttr: { text: "tag_cargos2" },
                                        selectMaterialAttr: {
                                            valueMember: "ID",
                                            displayMember: "Nombre",
                                            ShowAndEnableSearchText: true,
                                            multiselect: true,
                                            ShowNSelectedInList: true,
                                            required: true
                                        },
                                        values: cargosList
                                    },
                                    {
                                        model: "IdCicloEscolar",
                                        type: Fields.selectMaterial, labelAttr: { text: "tag_cicloesc" },
                                        selectMaterialAttr: {
                                            valueMember: "Id", displayMember: "Nombre", onChange: (id, dato) => {
                                                console.debug("Card_NinioFinanza -> ", dato, "change - ciclo escolar");
                                                // this.auxAsigCargoProceso.CicloEscolar = dato;
                                                //NOTE  hacer el cambio de aplicacion
                                                let initMin = UIUtilTime._FmtToInputDate(dato.FechaInicio, { removeZ: true });
                                                let initMax = UIUtilTime._FmtToInputDate(dato.FechaFin, { removeZ: true });
                                                form._ControlsData.get("FechaAplicacion").selection
                                                    .attr("min", initMin)
                                                    .attr("max", initMax)
                                                    .property("value", initMin)
                                                // NOTE Inicialicar en ciclo escolar actual
                                            }, required: true
                                        },
                                        values: ciclosEscolaresList
                                    },
                                    {
                                        model: "FechaAplicacion",
                                        type: Fields.input,
                                        inputAttr: { type: "date", required: true, min: initMin, max: initMax },
                                        labelAttr: { text: "tag_dtaplicacion" },
                                    }
                                ]
                            }, initDataForm);

                        content.append(() => form._Form.node());

                        // Inicializa valores de formulario
                        (<SelectV2>form._ControlsData.get("IdCicloEscolar").instance)
                            ._valueSelect(initCicloEscolar.Id);

                        form._ControlsData.get("FechaAplicacion").selection
                            .property("value", UIUtilTime._FmtToInputDate(initCicloEscolar.FechaInicio, { removeZ: true }));
                    },
                    OnValideStep: () => form._GetIsValidForm()
                },
                {
                    Id: "preview",
                    PreviousID: "form",
                    Title: "tag_prevcargosaplicar",
                    Width: 1300,
                    Height: "800px",
                    OnDrawContent: (content, mt) => {
                        content.append("label")
                            .html(`<b>${this.CARDCOLL_GetUIStringModule("tag_dtaplicacion")}:</b>`)
                            .append("span");

                        table = this.GetPrevisualizacionTabla(content);
                    },
                    OnFocusContent: (content, mt) => {
                        // console.warn("Form", form.prop_Data);
                        // let fechaInicio = new Date(form.prop_Data.FechaAplicacion + " 00:00");
                        let fechaInicio = UIUtilTime._GetDateConcatenatedDateTime(form._Data.FechaAplicacion);

                        content.select(":scope > label > span")
                            .text(" " + UIUtilTime._DateFormatStandar(fechaInicio));

                        let previewData = form._Data.IdsCargos
                            .map(idCargo => {
                                /** Asignación individual a dar de alta */
                                const tempAsignacion = this.GetNewAsignacion(idCargo).asignacion;
                                this.idTemporalAux++;

                                tempAsignacion.NoRepeticiones = 1;
                                tempAsignacion.Repeticiones = [{
                                    DtFechaInicio: fechaInicio,
                                    FechaInicio: fechaInicio.toISOString(),
                                    Aplicacion: fechaInicio.toISOString(),
                                    DtFechaFin: null,
                                    FechaFin: null,
                                    ParentAsignacion: tempAsignacion,

                                    ID: this.idTemporalAux,
                                    Descuentos: [],
                                    TotalDescuento: 0,
                                    Procesado: false,
                                    IsSafe: null
                                }];

                                tempAsignacion.Total = tempAsignacion.NoRepeticiones * tempAsignacion.Valor;
                                tempAsignacion.FechaInicio = fechaInicio.toISOString();
                                tempAsignacion.DtFechaInicio = fechaInicio;

                                return tempAsignacion;
                            });

                        // console.warn(previewData);

                        table._UpdateData(previewData);
                    },
                    OnAccept: async () => {
                        let res = await this.Sv_RegistrarAsignacionesCargos(form._Data.IdCicloEscolar, table._data);

                        if (res.Resultado > 0) {
                            this.UI_UpdateCardData(true);
                        }

                        return res;
                    }
                }
            ]
        });
    }

    // ************************************************************************
    // PROCESO: ASIGNACIÓN DESCUENTOS
    // ************************************************************************

    private OpenModal_AsignarDescuentos(cargosAsignaciones: IAsignacionCargoDescEnProceso[]) {
        type IFormDescuentos = {
            Ids: number[];
            Descuentos: IFinanzaCargo[];
        };
        const descuentosDisponiblesList = this.GetDescuentosList();
        const descuentosInvalidosList = descuentosDisponiblesList
            .filter(desct => {
                return cargosAsignaciones
                    .every(cargo => (!UIUtilViewFinanzaCargo._DATA_ValidaDescuentosACargo(cargo.Valor, [desct]).Valido))
            })
            .map(d => d.ID);

        if (descuentosDisponiblesList.length == 0) {
            this.ctrlNotification._Mostrar(this.CARDCOLL_GetUIStringModule("notif_nodescuentosregistds"), "ADVERTENCIA");
            return;
        }

        let formDescuentos: FormGenerator<IFormDescuentos>;
        let tblPreview: Table.Tabla<IAsignacionCargoDesc>;

        /** Destinado a validaciones */
        const cargosRepProceso: ICargoRepeticionEnProceso[] = [];

        // Agrupa los cargos a procesar
        cargosAsignaciones
            .forEach(cargoAsig => {
                cargoAsig.Repeticiones
                    .forEach(cargoRep => {
                        if (cargoRep.EnProceso) {
                            if (this.GetTotalSaldoByCargoRep(cargoRep) > 0) {
                                cargosRepProceso.push(cargoRep);
                            }
                            else {
                                // Fix dato en proceso
                                cargoRep.EnProceso = false;
                            }
                        }
                    })
            });

        if (cargosRepProceso.length == 0) {
            this.ctrlNotification._Mostrar("Error", "ADVERTENCIA");
            return;
        }

        const fnGetPreviewData = (descuentosExtras: IAsignacionInfoDescuento[]) => {
            return cargosAsignaciones
                .map(cargoAsig => {
                    const ca = Object.assign({}, cargoAsig);
                    const cargoInicial = ca.Valor;

                    ca.Repeticiones = ca.Repeticiones
                        .map(cargoRep => {
                            const cr = Object.assign({}, cargoRep);

                            cr.ParentAsignacion = ca;

                            cr.Descuentos = cr.Descuentos
                                .map(desc => Object.assign({}, desc));

                            if (cr.EnProceso && descuentosExtras.length) {
                                cr.Descuentos
                                    .push(...descuentosExtras);

                                cr.TotalDescuento = 0;

                                cr.Descuentos
                                    .forEach(descuento => {
                                        if (!descuento.Descuento) {
                                            console.log("Calculando monto de descuento faltante...", descuento);
                                            descuento.Descuento = this.GetValorDescuentoRelativo(descuento, cr.Descuentos, cargoInicial);
                                        }
                                        cr.TotalDescuento += descuento.Descuento;
                                    });
                            }

                            return cr;
                        });

                    ca.Total = this.GetCargoTotalDeAsignacion(ca);

                    return ca;
                });
        }

        ModalThings._GetModalToALongProccess({
            Modulo: this.modulo,
            Action: Entidad.CAccionPermiso.AgregarDescuentos,
            IdsEscuelas: [this.GetIdEscuela()],
            StartEndIds: ["select_descs", "preview"],
            StepsConfig: [
                {
                    Id: "select_descs",
                    NextID: "preview",
                    Title: "tag_descs",
                    Width: 420,
                    OnDrawContent: (content, mt) => {
                        // >> Create Form Descs
                        formDescuentos = new FormGenerator<IFormDescuentos>()
                            ._Crear({
                                LangModuleKeyInContext: this.moduloName,
                                LabelMaxWidth: 150,
                                schema: [
                                    {
                                        model: "Ids",
                                        type: Fields.selectMaterial, labelAttr: { text: "tag_eligedescs" },
                                        selectMaterialAttr: {
                                            valueMember: "ID",
                                            displayMember: "Nombre",
                                            multiselect: true,
                                            ShowNSelectedInList: true,
                                            required: true,
                                            onChange: (idsDescuentos: Array<number>, descuentosInfo: Array<IFinanzaCargo>) => {
                                                formDescuentos._DataOrigin.Descuentos = (descuentosInfo || []);
                                            },
                                            OnStepItemListUI: (container, datoDesc: IFinanzaCargo) => {
                                                const valid = cargosAsignaciones
                                                    .every(ca => {
                                                        return UIUtilViewFinanzaCargo._DATA_ValidaDescuentosACargo(ca.Valor, [datoDesc]).Valido;
                                                    })

                                                container
                                                    .classed(UIUtilGeneral.FBoxOrientation.Vertical, true)
                                                    .style("align-items", "start")
                                                    .text(datoDesc.Nombre);

                                                if (valid) {
                                                    container.select(".lbl_invalid")
                                                        .remove();
                                                } else {
                                                    container.append("label")
                                                        .attr("class", "lbl_invalid")
                                                        .style("color", "red")
                                                        .style("font-size", "var(--fontsize_me4)")
                                                        .text(this.CARDCOLL_GetUIStringModule("tag_excedesaldo"));
                                                }
                                            }
                                        },
                                        values: descuentosDisponiblesList
                                    }
                                ],
                                Validation: (val, field) => {
                                    // Valida Seleccion de Descuentos
                                    //if (Boolean(val) && (val as Array<number>).length) {
                                    return cargosRepProceso
                                        .every(cr => {
                                            const cargoAsig = cr.ParentAsignacion;
                                            const totalDescuentos = [
                                                ...cr.Descuentos,
                                                ...formDescuentos._Data.Descuentos
                                            ];
                                            return UIUtilViewFinanzaCargo._UI_ValidaDescuentosACargo(cargoAsig.Valor, cargoAsig.Nombre, totalDescuentos)
                                        })
                                    //}
                                    //return false;
                                }
                            }, <IFormDescuentos>{});

                        // >> Deshabilita los descuentos disponibles no válidos
                        (formDescuentos._ControlsData.get("Ids").instance as SelectV2<IFinanzaCargo, "ID">)
                            ._disableItems(descuentosInvalidosList);

                        content.append(() => formDescuentos._Form.node());
                    },
                    OnValideStep: () => formDescuentos._GetIsValidForm(),
                },
                {
                    Id: "preview",
                    PreviousID: "select_descs",
                    Title: "tag_prevdescsaplicar",
                    Width: 1300,
                    Height: "800px",
                    OnDrawContent: (container, mt) => {
                        container
                            .classed(UIUtilGeneral.FBoxOrientation.Vertical, true)
                            .style("row-gap", "10px");

                        // >> Descuentos seleccionados
                        container.append("label")
                            .html(`<b>${this.CARDCOLL_GetUIStringModule("tag_descs")}:</b>`)
                            .append("span");

                        // >> Create preview table
                        tblPreview = this.GetPrevisualizacionTabla(container);
                    },
                    OnFocusContent: (content, mt) => {
                        let descuentosSeleccionados = formDescuentos._Data.Descuentos
                            .map(d => Object.assign({}, d));

                        // >> Update UI tag descuentos seleccionados
                        content.select(":scope > label > span")
                            .text(
                                " " + descuentosSeleccionados
                                    ?.map(d => d.Nombre)
                                    ?.join(", ")
                            );

                        // >> Update preview data
                        tblPreview._UpdateData(fnGetPreviewData(descuentosSeleccionados as unknown as IAsignacionInfoDescuento[]));
                    },
                    OnAccept: async () => {
                        let res = await this.Sv_RegistrarAsignacionesDescuentos(cargosRepProceso, formDescuentos._Data.Ids, tblPreview._data);

                        if (res.Resultado > 0) {
                            this.UI_UpdateCardData(true);
                        }

                        return res;
                        // return this.Sv_RegistrarAsignacionesDescuentos({
                        //     AsignacionesOrigin: cargosAsignaciones,
                        //     AsignacionesPreview: tblPreview.prop_data,
                        //     Descuentos: formDescuentos.prop_Data.Descuentos,
                        //     IdsDescuento: formDescuentos.prop_Data.Ids,
                        //     RepeticionesSelected: cargosRepProceso
                        // });
                    }
                }
            ]
        })
    }

    // ************************************************************************
    // Configuraciones - Builds
    // ************************************************************************

    private GetPrevisualizacionTabla(container: d3.Selection<HTMLDivElement, any, HTMLElement, any>): Table.Tabla<IAsignacionCargoDesc> {
        container
            .classed(UIUtilGeneral.FBoxOrientation.Vertical, true)
            .style("row-gap", "10px");

        this.ctrlTablaPreview = new Table.Tabla<IAsignacionCargoDesc>({
            IdTabla: "AlumnosPanelCargosDescuentos-Preview",
            Parent: container,
            // Title: "",
            IdData: "ID",
            // OrderDefault: { Field: "Periodicidad", Type: controlD3.Table.CStatusOrder.Asc },
            StickyCheckInRow: true,
            AddNameFieldClassToCells: true,
            MinWidth: 1250,
            RenderColumnHeadings: this.ctrlTabla._InfoColumns,
            HideCheckboxes: true,
            EvaluatorAndSubLevelsBuild: this.GetTableConfigCollapseRows(false), // , this.ctrlTablaPreview), // 
            OnValueSelectRow: (idDatum, dato, isSelected) => {
                console.debug("Card_NinioFinanza -> ", dato)
            }
        })

        this.ctrlTablaPreview._Control
            .classed("tablafilas_coloresintercalados", true)
            .style("border-top", "1px solid #e4e4e4");

        return this.ctrlTablaPreview;
    }

    /**  */
    private GetTableConfigCollapseRows(isEditable: boolean): Table.IStepEvaluator<IAsignacionCargoDesc, ICargoRepeticion, any> {
        return {
            // >> NIVEL 1: Cargo (Asignación padre)

            OnEvalIsEnableRow: (datoAsig) => (this.Get_Valida_CargosRepeticionesSonValidos(datoAsig.Repeticiones)), // Toda la fila padre es desabilitada, si todos los items hijos ha sido procesados
            OnEvalIsCollapsedRow: () => (isEditable),
            GetOptionsInRowV2: isEditable ? (datoAsig) => (
                this.CARDCOLLADTAB_GetTableOptionsToSelectedData(this.GetIdEscuela(), "row", this.UI_TableGetSelectedDataMenuLvOne("row", [datoAsig]))
            ) : null,
            OnStepRowTable: (datoAsig, trRow, rowBody) => {
                let table: Table.Tabla<any> = (isEditable ? this.ctrlTabla : this.ctrlTablaPreview);

                let btnCollapser2 = trRow.select(".Aplicacion").select<HTMLIconCollapseElement>("wc-ic-collapse");
                if (!btnCollapser2.node()) {
                    btnCollapser2 = trRow.select(".Aplicacion > .item_cont")
                        .style("opacity", 1, "important")
                        .append<HTMLIconCollapseElement>("wc-ic-collapse")
                        .style("width", "15px");
                }

                btnCollapser2.node().onclick = (e) => {
                    e.stopPropagation();
                    let item = table._SelectItemData(datoAsig.ID);

                    item.CollapseItem(!item.IsCollapsed)
                        .RefreshTableView();
                }
                btnCollapser2.node()._Collapsed = table._SelectItemData(datoAsig.ID).IsCollapsed;

                trRow.on("click", () => {
                    console.debug("Card_NinioFinanza -> Cargo-Asign", datoAsig.ID, datoAsig);
                })
            },
            OnStepCellTable: (container, datum, field) => {
                let strWidthoutDate = datum.Periodicidad == CPeriodicidadCargos.Unico ? "---" : this.CARDCOLL_GetUIStringModule("tag_outrange");

                switch (field) {
                    case "Valor":
                    case "Total":
                        container.text(UIUtilFormat._CurrencyFmt(datum.Valor));
                        break;
                    case "DtFechaInicio":
                        container.text(datum.DtFechaInicio ? UIUtilTime._DateFormatStandar(datum.DtFechaInicio) : strWidthoutDate);
                        break;
                    case "DtFechaFin":
                        container.text(datum.DtFechaFin ? UIUtilTime._DateFormatStandar(datum.DtFechaFin) : strWidthoutDate);
                        break;
                }
            },
            OnCollapserChange: (isCollapsed, datoAsig, trRow) => {
                trRow.select<HTMLIconCollapseElement>(".Aplicacion wc-ic-collapse")
                    .node()
                    ._Collapsed = isCollapsed;
            },

            EvaluatorSubLevel: {
                // >> NIVEL 2: Repetición (Asignaciones por cargo)

                IdMember: "ID",
                OnGetData: (datoAsig) => datoAsig.Repeticiones,
                OnEvalIsEnableRow: (datoAsig) => (this.Get_Valida_DatoPuedeAccionarse(datoAsig)),
                GetOptionsInRowV2: isEditable ? (datoRep) => ({
                    MaxOptionsInRow: 3,
                    Options: this.UI_TableGetMenuInRowLevelTwo(datoRep)
                }) : null,
                OnStepRowTable: (datumRep, trRow2, rowBody2, indexOrigin, datoAsig) => {
                    trRow2.on("click", () => console.debug("Card_NinioFinanza -> Cargo-Rep ", datumRep.ID, datumRep));
                },
                OnStepCellTable: (container, datumRep, field) => {
                    let strWidthoutDate = datumRep.ParentAsignacion.Periodicidad == CPeriodicidadCargos.Unico ? "---" : this.CARDCOLL_GetUIStringModule("tag_outrange");

                    switch (field) {
                        case "Valor":
                            container.text(UIUtilFormat._CurrencyFmt(datumRep.ParentAsignacion.Valor));
                            break;
                        case "Nombre":
                            let lbl = container.select(".lblname");
                            if (!lbl.node()) {
                                lbl = container
                                    .classed(UIUtilGeneral.FBoxOrientation.Horizontal + " " + UIUtilGeneral.FBoxAlign.StartCenter, true)
                                    .style("column-gap", "5px")
                                    .append("label")
                                    .attr("class", "lblname");
                            }
                            lbl.text(datumRep.ParentAsignacion.Nombre);

                            let noEsDescsEnPreview = datumRep.Descuentos
                                .every((d) => ((d as unknown as IFinanzaCargo).Modificacion == null));

                            if (this.GetTotalSaldoByCargoRep(datumRep) == 0 && noEsDescsEnPreview) {
                                let info = container.select(".infosaldocero");

                                if (!info.node()) {
                                    info = container.append("div")
                                        .attr("class", "infosaldocero")
                                        .style("width", "15px")
                                        .style("cursor", "help")
                                        .style("display", "flex");

                                    info.append("img")
                                        .attr("src", UIUtilIconResources.CGeneral.InfoMark)
                                        .attr("draggable", false);

                                    info.append("wc-tooltip")
                                        .attr("position", "right")
                                        .attr("max-width", "160px");
                                }

                                info.select("wc-tooltip")
                                    .text(this.CARDCOLL_GetUIStringModule("tag_cargocero"));
                            }
                            else {
                                container.select(".infosaldocero")
                                    .remove();
                            }
                            break;
                        case "DtFechaInicio":
                            container.text(datumRep.DtFechaInicio ? UIUtilTime._DateFormatStandar(datumRep.DtFechaInicio) : strWidthoutDate);
                            break
                        case "DtFechaFin":
                            container.text(datumRep.DtFechaFin ? UIUtilTime._DateFormatStandar(datumRep.DtFechaFin) : strWidthoutDate);
                            break;
                        case "Aplicacion":
                            container.text(datumRep.Aplicacion ? UIUtilTime._DateFormatStandar(datumRep.Aplicacion) : UIUtilLang._GetUIString("general", "nodisponible"));
                            container.select("svg").remove();
                            break;
                        default:
                            if (container.text()) {
                                container.text("");
                            }
                            break;
                    }
                },

                AddRowMultiline: <Table.IRowMultilineConfig<ICargoRepeticion, IAsignacionInfoDescuento>>{
                    // >> NIVEL 2.1: Descuentos

                    OnGetDataToAddRow: (datumRep) => datumRep.Descuentos,
                    OnStepCell: (content, datoDesc, field, datoRep, restOfParentsData: [IAsignacionCargoDesc]) => {
                        // console.log(datoDesc, "Dato descuento")
                        content.style("color", "red");

                        switch (field) {
                            case "Nombre":
                                if (datoDesc.TipoValor == CTipoValor.Monto) {
                                    content.text(datoDesc.Nombre);
                                } else if (datoDesc.TipoValor == CTipoValor.Porcentaje) {
                                    content.text(datoDesc.Nombre + " (" + UIUtilFormat._PercentFmt(datoDesc.Valor) + ")")
                                }
                                break;
                            case "Valor":
                                content.text("-" + UIUtilFormat._CurrencyFmt(datoDesc.Descuento));
                                break;
                            case "Aplicacion":
                                content.select("svg").remove();
                                break;
                        }
                    },
                    OnStepNewTrs: (datum, tr, datoRep, restOfParentsData: [IAsignacionCargoDesc]) => {
                        tr.node().onclick = (e: MouseEvent) => {
                            console.debug("Card_NinioFinanza -> ", datum.ID, datum);
                            e.stopPropagation()
                        }
                        return !datoRep.Procesado;
                    }
                }
            }
        }
    }

    // ************************************************************************
    // UI THINGS
    // ************************************************************************

    private UI_UpdateCardData(showProgressBar = false): Promise<void> {
        if (!this.CARDCOLL_StatusCardExpandido) {
            this.ctrlTabla._UpdateData([]);
            return null;
        }
        return new Promise<void>(async (resolve, reject) => {
            if (showProgressBar) {
                this.ctrlProgress.attr("oculto", false);
            }

            await this.Sv_GetListaAsignaciones();

            this.ctrlTabla._UpdateData(this.GetCargosAsignadosList());

            if (showProgressBar) {
                this.ctrlProgress.attr("oculto", true);
            }
            resolve();
        })
    }

    private UI_TableGetSelectedDataMenuLvOne(location: "row" | "top-selected", asignacionesCargosDesc: IAsignacionCargoDesc[]) {
        let opciones: Array<Table.ITableMenuDataSelectedOptionConfig<IAsignacionCargoDesc>> = [];
        let fnEachCargoRepeticionNoProcesado = (datos: IAsignacionCargoDesc[], iteratorCargos: (cargo: ICargoRepeticion) => void) => {
            switch (location) {
                case "row":
                    datos[0].Repeticiones
                        .forEach(cargoRep => {
                            if (this.Get_Valida_DatoPuedeAccionarse(cargoRep)) {
                                iteratorCargos(cargoRep);
                            }
                        });
                    break;

                case "top-selected":
                    let dataCheckedInfo = this.ctrlTabla._DataAndChildsDataChecked as Table.IDataCheckedAdvanced<IAsignacionCargoDesc, ICargoRepeticion>[];

                    dataCheckedInfo
                        .forEach(datoAsigInfo => {
                            datoAsigInfo.ChildsChecked
                                .forEach(d => {
                                    if (this.Get_Valida_DatoPuedeAccionarse(d.Data)) {
                                        iteratorCargos(d.Data)
                                    }
                                });
                        });
                    break;
            }
        }
        let fnValidaDatosAccionar = (datos: IAsignacionCargoDesc[], cargoSaldoCeroValido: boolean) => {
            let repeticiones: ICargoRepeticion[] = [];

            fnEachCargoRepeticionNoProcesado(datos, (cargoRep) => {
                if (cargoSaldoCeroValido || (!cargoSaldoCeroValido && this.GetTotalSaldoByCargoRep(cargoRep) > 0)) {
                    repeticiones.push(cargoRep);
                }
            })

            return Boolean(repeticiones.length);
        }

        if (this.HasActionPermission(Entidad.CAccionPermiso.AgregarDescuentos)) {
            opciones.push({
                Label: "action_descuento",
                Callback: (cargosDesc) => {
                    this.OpenModal_AsignarDescuentos(
                        cargosDesc
                            .map<IAsignacionCargoDescEnProceso>(cd => {
                                const cargoAsig = Object.assign(<IAsignacionCargoDescEnProceso>{}, cd);

                                switch (location) {
                                    case "row":
                                        cargoAsig.Repeticiones = cargoAsig.Repeticiones
                                            .map<ICargoRepeticionEnProceso>(d => {
                                                const rep = Object.assign({}, d);

                                                rep.EnProceso = this.Get_Valida_DatoPuedeAccionarse(d); // && (this.GetTotalSaldoByCargoRep(d) > 0);

                                                return rep;
                                            });
                                        break;
                                    case "top-selected":
                                        const row = this.ctrlTabla._SelectItemData(cargoAsig.ID);
                                        const childrenRow = row.GetChildrenData<ICargoRepeticion>();

                                        cargoAsig.Repeticiones = cargoAsig.Repeticiones
                                            .map<ICargoRepeticionEnProceso>(d => {
                                                const rep = Object.assign({}, d);
                                                const repInTbl = childrenRow.get(rep.ID);

                                                rep.EnProceso = Boolean(repInTbl?.IsCheked); // && (this.GetTotalSaldoByCargoRep(d) > 0);

                                                return rep;
                                            });
                                        break;
                                }

                                return cargoAsig;
                            })
                    );
                },
                GetDetails: (datos) => {
                    let enable = fnValidaDatosAccionar(datos, false)
                    return {
                        Enabled: enable,
                        Description: enable ? `` : this.CARDCOLL_GetUIStringModule("tlt_agregardescuento_fail")
                    }
                }
            })
        }
        if (this.puedeEliminar) {
            // >> Todos los items checkeados de nivel 2: Siempre que sean válidos
            opciones.push({
                Label: "action_eliminar",
                Callback: (cargosAsig) => {
                    let repeticiones: ICargoRepeticion[] = [];

                    fnEachCargoRepeticionNoProcesado(cargosAsig, (CargoRep) => {
                        repeticiones.push(CargoRep);
                    });

                    this.OpenModal_EliminarAsignacionesCargos(repeticiones);
                },
                GetDetails: (datos) => fnValidaDatosAccionar(datos, true)
            })
        }
        if (this.HasActionPermission(Entidad.CAccionPermiso.Agregar) && this.GetStudentsCount() == 1) {
            opciones.push({
                Label: "action_aplicarcargo",
                Callback: (cargosAsig) => {
                    let repeticiones: ICargoRepeticion[] = [];

                    fnEachCargoRepeticionNoProcesado(cargosAsig, (CargoRep) => {
                        repeticiones.push(CargoRep);
                    });

                    this.OpenModal_AplicarCargos(repeticiones)
                },
                GetDetails: (datos) => {
                    let enable = fnValidaDatosAccionar(datos, false);
                    return {
                        Enabled: enable,
                        Description: enable ? `` : this.CARDCOLL_GetUIStringModule("tlt_applcargo_fail")
                    }
                }
            })
        }
        return opciones;
    }

    private UI_TableGetMenuInRowLevelTwo(datoRepInRow: ICargoRepeticion): Array<Table.ITableMenuDataSelectedOptionConfig<ICargoRepeticion>> {
        let opciones: Array<Table.ITableMenuDataSelectedOptionConfig<ICargoRepeticion>> = [];
        if (this.HasActionPermission(Entidad.CAccionPermiso.AgregarDescuentos)) {
            opciones.push({
                Label: this.CARDCOLL_GetUIStringModule("action_descuento"),
                Callback: (datos) => {
                    console.debug("Card_NinioFinanza -> ", datos[0], datos[0].ParentAsignacion, "Click en 'Agregar descuento' - ROW Lv.2");
                    this.OpenModal_AsignarDescuentos(
                        [datos[0].ParentAsignacion]
                            .map(ca => {
                                const cargoAsig = Object.assign(<IAsignacionCargoDescEnProceso>{}, ca);

                                cargoAsig.Repeticiones = ca.Repeticiones
                                    .map(cr => {
                                        const cargoRep = Object.assign(<ICargoRepeticionEnProceso>{}, cr);

                                        cargoRep.EnProceso = (datos[0] === cr);

                                        return cargoRep;
                                    })

                                return cargoAsig;
                            })
                    )
                },
                GetDetails: (datos) => {
                    if (!this.Get_Valida_DatoPuedeAccionarse(datos[0])) {
                        return {
                            Enabled: false,
                            Description: this.CARDCOLL_GetUIStringModule("tlt_lvl2_fail_processed")
                        }
                    }
                    if (this.GetTotalSaldoByCargoRep(datos[0]) <= 0) {
                        return {
                            Enabled: false,
                            Description: this.CARDCOLL_GetUIStringModule("tlt_lvl2adddesc_fail_zero")
                        }
                    }
                    return {
                        Enabled: true,
                        Description: ``
                    }
                }
            })
        }
        if (this.puedeEliminar) {
            // >> Eliminar un elemento de nivel 2: Asignación cargo (repetición)
            opciones.push({
                Label: this.CARDCOLL_GetUIStringModule("action_eliminar"),
                Callback: (datos) => this.OpenModal_EliminarAsignacionesCargos(datos),
                GetDetails: (datos) => this.Get_Valida_DatoPuedeAccionarse(datos[0])
            })
        }
        if (this.HasActionPermission(Entidad.CAccionPermiso.Agregar) && this.GetStudentsCount() == 1) {
            opciones.push({
                Label: this.CARDCOLL_GetUIStringModule("action_aplicarcargo"),
                Callback: (datos) => this.OpenModal_AplicarCargos(datos),
                GetDetails: (datos) => {
                    if (!this.Get_Valida_DatoPuedeAccionarse(datos[0])) {
                        return {
                            Enabled: false,
                            Description: this.CARDCOLL_GetUIStringModule("tlt_lvl2_fail_processed")
                        }
                    }
                    if (this.GetTotalSaldoByCargoRep(datos[0]) <= 0) {
                        return {
                            Enabled: false,
                            Description: this.CARDCOLL_GetUIStringModule("tlt_lvl2applcargo_fail_zero")
                        }
                    }
                    return {
                        Enabled: true,
                        Description: ``
                    }
                    //(this.Get_Valida_DatoPuedeAccionarse(datos[0]) && (this.GetTotalSaldoByCargoRep(datos[0]) > 0))
                }
            })
        }
        return opciones;
    }

    // ************************************************************************
    // PERMISOS COSAS
    // ************************************************************************

    // ************************************************************************
    // DATA - LOCAL
    // ************************************************************************

    // private GetEscuela() {
    //     return data.modulos.Kinder.DiccKinder.get(this.GetIdEscuela());
    // }

    private GetIdEscuela() {
        return this.GetCurrentStudents()[0]?.IdKinder;
    }

    private GetCurrentStudents(): IAlumno[] {
        return (this.cardData[0] || []);
    }

    private GetStudentsCount() {
        return this.GetCurrentStudents().length;
    }

    private GetIdsCurrentStudents() {
        return this.GetCurrentStudents()
            .map(d => d.IdChild);
    }

    private GetIdFirstStudent() {
        return this.GetCurrentStudents()[0]?.IdChild;
    }

    /** Retorna el primer ciclo esc en cuyo periodo se encuentra la fecha actual,
     * Si no se encuentra, retorna el primer elemento de la lista
     */
    private GetCurrentCicloEscolar(ciclosEscolaresList = this.GetCiclosEscolaresList()) {
        const now = new Date();
        let cicloEscFound = ciclosEscolaresList
            .find(d => new Date(d.FechaInicio) <= now && new Date(d.FechaFin) >= now);

        if (!cicloEscFound) {
            cicloEscFound = ciclosEscolaresList[0];
        }

        return cicloEscFound;
    }

    private GetCargosAsignadosList(keyCargos?: (string | number | number[])) {
        if (!keyCargos) {
            keyCargos = this.GetIdsCurrentStudents();
        }

        return (this.cargosAsignacionesMap.get(keyCargos.toString()) || []);
    }

    private SetCargosAsignadosList(key: (number | number[]), cargosAsignaciones: IAsignacionCargoDesc[]) {
        this.cargosAsignacionesMap.set(key.toString(), cargosAsignaciones);
    }

    private GetCargosList(type: ("unicos" | "periodicos" | "todos")): IFinanzaCargo[] {
        let cargos = Array.from(DataModuloFinanzaCargo._DiccFinanzasCargos.values())
            .filter(d => {
                if (d.Categoria == CCategoriaTipoFinanza.Cargo && d.IdEscuela == this.GetIdEscuela()) {
                    if (
                        (type == "todos")
                        || (type == "unicos" && d.Periodicidad == CPeriodicidadCargos.Unico)
                        || (type == "periodicos" && d.Periodicidad != CPeriodicidadCargos.Unico)
                    ) {
                        return true;
                    }
                }

                return false;
            })

        return cargos
            .sort((a, b) => (a.Periodicidad - b.Periodicidad));
    }

    /** Cada itemlist retornado ha sido asignado a un nuevo objeto */
    private GetDescuentosList() {
        return Array.from(DataModuloFinanzaCargo._DiccFinanzasCargos.values())
            .filter(d => (d.Categoria == CCategoriaTipoFinanza.Descuento && d.IdEscuela == this.GetIdEscuela()));
    }

    private GetCiclosEscolaresList() {
        return DataModuloMain._GetReqDataArrayByName("CicloEscolar")
            .filter(d => (d.IdEscuela == this.GetIdEscuela() && UIUtilTime._GetValidatorDateYMD(new Date(d.FechaFin)) >= UIUtilTime._GetValidatorDateYMD(new Date())))
            .sort((a, b) => (Number(new Date(a.FechaInicio)) - Number(new Date(b.FechaInicio))));
    }

    // >> VALIDACIONES

    /** Retorna true, si al menos una de las repeticiones (IsSafe y no ha llegado la fecha de procesado (Fecha Aplicación es mayor al día actual)) */
    private Get_Valida_CargosRepeticionesSonValidos(datos: ICargoRepeticion[]) {
        return Boolean(datos.find(d => (this.Get_Valida_DatoPuedeAccionarse(d))));
    }

    /** Retona true, si IsSafe y no ha llegado la fecha de proceso (Fecha Aplicación es mayor al día actual) */
    private Get_Valida_DatoPuedeAccionarse(d: ICargoRepeticion) {
        // Solo se puede Eliminar o Agregar descuento a un cargo si la fecha actual es mayor a la de aplicación, se ignora la hora
        return (
            d.IsSafe
            && (d.Procesado ? false : (UIUtilTime._GetValidatorDateYMD(new Date(d.Aplicacion)) > UIUtilTime._GetValidatorDateYMD(new Date())))
        ); // FIX_TIMEZONE
    }

    // >> PROCESOS INTERMEDIOS

    private GetTotalSaldoByCargoRep(cargoRep: ICargoRepeticion, localCalculate = false): number {
        const cargoInicial = cargoRep.ParentAsignacion.Valor;
        let saldo = cargoInicial;

        if (localCalculate) {
            cargoRep.Descuentos
                .forEach((desc) => {
                    const descuentoMonto = this.GetValorDescuentoRelativo(desc, cargoRep.Descuentos, cargoInicial);
                    saldo -= descuentoMonto;
                });
        }
        else {
            saldo -= cargoRep.TotalDescuento;
        }

        return saldo;
    }

    private GetValorDescuentoRelativo(descuento: IAsignacionInfoDescuento, allDescuentos: IAsignacionInfoDescuento[], valorCargo: number): number {
        let valorDescuento = 0;

        for (let desc of allDescuentos) {
            if (desc.TipoValor == CTipoValor.Porcentaje) {
                valorDescuento = ((desc.Valor / 100) * valorCargo);
            } else {
                valorDescuento = desc.Valor;
            }

            if (desc.ID == descuento.ID) {
                break;
            }

            valorCargo -= valorDescuento;
        }

        return valorDescuento;
    }

    private GetNewAsignacion(idCargo: number) {
        let cargo = DataModuloFinanzaCargo._DiccFinanzasCargos.get(idCargo);
        this.idTemporalAux++;
        let asignacion = <IAsignacionCargoDesc>{
            ID: this.idTemporalAux, // Aux para Tabla de previsualización
            Total: 0,
            IdCargoPlantilla: idCargo,
            Nombre: cargo.Nombre, //COPY
            Valor: cargo.Valor,//COPY
            TipoValor: cargo.TipoValor,//COPY
            Periodicidad: cargo.Periodicidad,//COPY
            StrPeriodicidad: UIUtilViewData._GetStr_Periodicidad(cargo.Periodicidad)
        }
        return {
            cargo: cargo,
            asignacion: asignacion
        }
    }

    /** Genera las asignaciones por cada cargo seleccionado y las repeticiones de asignación */
    private GetCargosPorAsignacionACargar(idsCargo: number[], fechaInicio: Date, fechaFin: Date): IAsignacionCargoDesc[] {
        // console.log(idsCargo, fechaInicio, fechaFin)
        return idsCargo.map(idCargo => {
            /* Asignación individual a dar de alta */
            const { asignacion, cargo } = this.GetNewAsignacion(idCargo);
            const repeticiones: ICargoRepeticion[] = [];
            let fechaProceso = new Date(fechaInicio);

            // console.debug(fechaProceso, (fechaProceso.setDate(1), fechaProceso), fechaFin);
            // Toods los bloques inician el día 1
            fechaProceso.setDate(1);

            while (fechaProceso.getTime() <= fechaFin.getTime()) {
                this.idTemporalAux++;
                // console.debug("Card_NinioFinanza -> ", fechaRango <= asignC.DtFechaFin, fechaRango, asignC.DtFechaFin)
                const repeticion: ICargoRepeticion = {
                    DtFechaInicio: null,
                    DtFechaFin: null,
                    FechaInicio: "",
                    FechaFin: "",
                    Aplicacion: "",
                    ParentAsignacion: asignacion,

                    ID: this.idTemporalAux,
                    Descuentos: [],
                    TotalDescuento: 0,
                    Procesado: null,
                    IsSafe: null
                };

                // La fecha de inicio
                repeticion.DtFechaInicio = new Date(fechaProceso); // FECHA DE INICIO DEL CARGO A APLICAR
                repeticion.FechaInicio = repeticion.DtFechaInicio.toISOString();

                // Fecha de aplicación
                const dtAplicacion = new Date(repeticion.DtFechaInicio);
                dtAplicacion.setMonth(dtAplicacion.getMonth() + cargo.MesAplicacion - 1);
                dtAplicacion.setDate(cargo.DiaAplicacion);
                repeticion.Aplicacion = dtAplicacion.toISOString();

                // Fecha fin
                fechaProceso.setMonth(fechaProceso.getMonth() + Entidad.PeriodicidadNMeses[cargo.Periodicidad]);
                fechaProceso.setDate(fechaProceso.getDate() - 1);
                repeticion.DtFechaFin = new Date(fechaProceso);

                // Repetición válida
                if (repeticion.DtFechaFin.getTime() <= fechaFin.getTime() || dtAplicacion.getTime() <= fechaFin.getTime() || !repeticiones.length) {
                    repeticion.FechaFin = repeticion.DtFechaFin.toISOString();

                    repeticiones.push(repeticion);
                }
                // Ajustar para siguiente iteración
                fechaProceso.setDate(fechaProceso.getDate() + 1);
            }
            asignacion.Repeticiones = repeticiones;
            asignacion.NoRepeticiones = repeticiones.length;

            asignacion.DtFechaInicio = asignacion.Repeticiones[0] ? asignacion.Repeticiones[0].DtFechaInicio : null;
            asignacion.DtFechaFin = asignacion.Repeticiones[asignacion.Repeticiones.length - 1] ? asignacion.Repeticiones[asignacion.Repeticiones.length - 1].DtFechaFin : null;
            asignacion.Total = asignacion.NoRepeticiones * asignacion.Valor;

            asignacion.FechaInicio = asignacion.DtFechaInicio.toISOString();
            asignacion.FechaFin = asignacion.DtFechaFin.toISOString();

            return asignacion;
        });
    }

    private GetCargoTotalDeAsignacion(asignacion: IAsignacionCargoDesc) {
        let total = 0;

        asignacion.Repeticiones
            .forEach((repeticion) => {
                let valorToAdd = asignacion.Valor;

                repeticion.Descuentos
                    ?.forEach(descuento => {
                        valorToAdd -= descuento.Descuento;
                    })

                if (valorToAdd < 0) {
                    console.error("-d", "Total de descuentos (" + valorToAdd + ") mayor al monto (" + asignacion.Valor + ") de cargo: ", repeticion);
                    valorToAdd = 0;
                }

                total += valorToAdd;
            });

        return total;
    }

    // ************************************************************************
    // DATA - SERVICIOS
    // ************************************************************************

    /** Consulta asignaciones de un sólo alumno */
    private async Sv_GetListaAsignaciones() {
        // console.warn("GetListaAsignaciones init... ", this.GetCurrentStudents());
        if (!this.CARDCOLL_StatusCardExpandido || this.GetStudentsCount() != 1) {
            console.log("No service");
            return
        }
        const res = await DataModuloFinanzasAsignaciones._GetAsignacionesListV3(this.GetCurrentStudents()[0].IdChild);

        if (res.Resultado <= 0) {
            this.ctrlNotification._Mostrar(_HttpMsgV2(res), "ADVERTENCIA");
            return
        }

        const cargosList = (res.Datos as IAsignacionCargoDesc[] || []);
        this.SetCargosAsignadosList(this.GetIdFirstStudent(), cargosList);
        cargosList
            .forEach(asignacion => {
                asignacion.StrPeriodicidad = UIUtilViewData._GetStr_Periodicidad(asignacion.Periodicidad);
                asignacion.NoRepeticiones = asignacion.Repeticiones.length;
                asignacion.FechaInicio = asignacion.Repeticiones[0].FechaInicio;
                asignacion.FechaFin = asignacion.Repeticiones[asignacion.Repeticiones.length - 1].FechaFin;
                asignacion.DtFechaInicio = new Date(asignacion.FechaInicio);
                if (asignacion.FechaFin) {
                    asignacion.DtFechaFin = new Date(asignacion.FechaFin);
                }

                asignacion.Repeticiones
                    .forEach(dR => {
                        dR.IsSafe = true;
                        dR.IDs = [dR.ID];
                        dR.DtFechaInicio = new Date(dR.FechaInicio);
                        dR.ParentAsignacion = asignacion;

                        if (dR.FechaFin) {
                            dR.DtFechaFin = new Date(dR.FechaFin);
                        }
                    });

                asignacion.Total = this.GetCargoTotalDeAsignacion(asignacion);
            });
    }

    private async Sv_RegistrarAsignacionesCargos(idCicloEscolar: number, asignacionesNuevas: IAsignacionCargoDesc[]) {
        const noCuenta = 1;
        const idsAlumnos = this.GetIdsCurrentStudents();
        const nAlumnos = idsAlumnos.length;

        const res = await DataModuloFinanzasAsignaciones._InsertAsignacionCargo(idsAlumnos, noCuenta, asignacionesNuevas, idCicloEscolar);

        if (res.Resultado > 0) {

            if (nAlumnos > 1) {
                // >> Prepara los datos locales temporales

                const repeticionesTotales: ICargoRepeticion[] = [];

                asignacionesNuevas
                    .forEach((asignacion) => {
                        repeticionesTotales.push(...asignacion.Repeticiones);
                    });

                let safeData = ((nAlumnos * repeticionesTotales.length) == res.Data.ID.length);

                /** Verifica cantidad longitud de arreglos devueltos */
                if (safeData) {
                    for (let idAlumno of idsAlumnos) {
                        const cargosAlumnoRes = res.ResolvedData[idAlumno];
                        // console.warn((repeticionesTotales.length == cargosAlumnoRes.length));
                        if (cargosAlumnoRes?.length == repeticionesTotales.length) {
                            repeticionesTotales
                                .forEach((repeticionA, indexRep) => {
                                    const repeticionRes = cargosAlumnoRes[indexRep];

                                    let repFInicio = repeticionA.FechaInicio ? repeticionA.FechaInicio.split("T")[0] : "";
                                    let dataFInicio = repeticionRes.FechaInicio.split("T")[0];

                                    let repFFin = repeticionA.FechaFin ? repeticionA.FechaFin.split("T")[0] : "";
                                    let dataFFin = repeticionRes.FechaFin ? repeticionRes.FechaFin.split("T")[0] : "";

                                    if ((repeticionA.IsSafe == null || repeticionA.IsSafe) && repFInicio == dataFInicio && repFFin == dataFFin) {
                                        if (repeticionA.IsSafe == null) {
                                            repeticionA.IsSafe = true;
                                        }
                                        if (!repeticionA.IDs) {
                                            repeticionA.IDs = [];
                                        }

                                        repeticionA.IDs.push(repeticionRes.Id);
                                    }
                                    else {
                                        repeticionA.IsSafe = false;
                                        console.warn("Los indices no corresponden!!");
                                    }
                                });
                        } else {
                            console.warn("Alumno sin cargos correctos!!!", cargosAlumnoRes, repeticionesTotales);
                            safeData = false;
                            break;
                        }
                    }
                }

                if (!safeData) {
                    console.warn("Datos corruptos!!!");

                    repeticionesTotales
                        .forEach(repeticion => {
                            repeticion.IsSafe = false;
                        });

                    setTimeout(() => {
                        console.log("Notif_ Adv");
                        this.ctrlNotification._Mostrar(this.CARDCOLL_GetUIStringModule("notif_errorasignacion"), "ADVERTENCIA");
                    }, 3000);
                }

                // >> Actualiza los datos locales temporales
                let currentAsignaciones = this.GetCargosAsignadosList(idsAlumnos);
                currentAsignaciones.push(...asignacionesNuevas);
                this.SetCargosAsignadosList(idsAlumnos, currentAsignaciones);
            }

            console.log(UIUtilLang._GetHTTPMessage(res, "agregar"));
            //this.ctrlNotification.met_Mostrar(UIUtilLang.fn_GetHTTPMessage(res, "agregar"), "INFO");
        }
        return res;
    }

    private async Sv_RegistrarAsignacionesDescuentos(cargosRepeticiones: ICargoRepeticion[], idsDescuentos: number[], asignacionesModificadas: IAsignacionCargoDesc[] /*datos: IAuxProcesoAsigDescuento*/) {
        // console.debug("Card_NinioFinanza -> ", datos, "RegistrarAsignacionesDescuentos")
        let idsCargosRepeticiones: Array<number> = [];

        cargosRepeticiones
            .forEach(d => {
                idsCargosRepeticiones.push(...d.IDs);
            });

        const idsAlumnos = this.GetIdsCurrentStudents();
        let res = await DataModuloFinanzasAsignaciones._InsertAsignacionDescuento(this.GetIdEscuela(), idsCargosRepeticiones, idsDescuentos);

        if (res.Resultado > 0) {
            if (this.GetStudentsCount() > 1) {
                // >> Actualiza los datos locales temporales
                let currentAsignaciones = this.GetCargosAsignadosList(idsAlumnos);

                currentAsignaciones
                    .forEach((asignacion, indexAsig) => {
                        let asignacionPrev = asignacionesModificadas.find(d => (d.ID == asignacion.ID));

                        if (asignacionPrev) {
                            currentAsignaciones[indexAsig] = asignacionPrev;

                            asignacionPrev.Repeticiones
                                .forEach(rep => {
                                    rep.Descuentos
                                        .forEach(desc => {
                                            this.idTemporalAux++;
                                            desc.ID = this.idTemporalAux;
                                        });
                                });
                            // console.debug("Card_NinioFinanza -> ", "Found asignación preview!", indexAsig);
                        }
                    })

                this.SetCargosAsignadosList(idsAlumnos, currentAsignaciones);
            }

            //this.ctrlNotification.met_Mostrar(UIUtilLang.fn_GetHTTPMessage(res), "INFO");
        }

        return res;
    }

    private async Sv_EliminarCargosAsignados_Repeticiones(datos: ICargoRepeticion[]) {
        const idsAlumnos = this.GetIdsCurrentStudents();
        let idsAsignacionRepeticiones: Array<number> = [];

        datos
            .forEach(d => {
                idsAsignacionRepeticiones.push(...d.IDs);
            });

        let res = await DataModuloFinanzasAsignaciones._DeleteCargosAsignadosRepeticiones(idsAsignacionRepeticiones);

        if (res.Resultado > 0) {
            if (this.GetStudentsCount() > 1) {
                let currentAsignaciones = this.GetCargosAsignadosList(idsAlumnos);
                // >> Actualiza los datos locales temporales
                datos
                    .forEach(repeticion => {
                        let indexAsignacionToRemove = currentAsignaciones.indexOf(repeticion.ParentAsignacion);
                        let asignacion = currentAsignaciones[indexAsignacionToRemove];
                        let indexRepeticionToRemove = asignacion?.Repeticiones.indexOf(repeticion);

                        console.warn("-d", asignacion, indexRepeticionToRemove);

                        if (indexRepeticionToRemove > -1) {
                            asignacion.Repeticiones.splice(indexRepeticionToRemove, 1);

                            if (asignacion.Repeticiones.length == 0) {
                                currentAsignaciones.splice(indexAsignacionToRemove, 1);
                            }
                        } else {
                            console.error("Los datos se corrompieron!!!");
                        }
                    })

                this.SetCargosAsignadosList(idsAlumnos, currentAsignaciones);
            }
            console.log(UIUtilLang._GetHTTPMessage(res));
            //this.ctrlNotification.met_Mostrar(UIUtilLang.fn_GetHTTPMessage(res), "ADVERTENCIA");
        }

        return res;
    }
}
