import { IFacturaClienteAlumno } from "../../../data/entidad/Factura"
import { _FacturaCrearCFDI } from "../../../data/modulo/FacturaCFDI"
import _L, { _HttpMsgV2 } from "../../../util/Labels"
import { Button } from "../../controlD3/Button"
import { List } from "../../controlD3/List"
import { ModalThings } from "../../controlD3/ModalThings"
import { HTMLProgressElement } from "../../controlWC/ProgressComponent"
import { AlumnoEdoCtaFacturacionUtil as Util } from "./AlumnoEdoCtaFacturacionUtil"
import { UIUtilViewAlumnoEdoCtaFacturacionViewCFDIInfo as ViewCFDIInfo } from "./AlumnoEdoCtaFacturacionViewCFDIInfo"
import { AlumnoEdoCtaFacturacionViewClientes as ViewClientes } from "./AlumnoEdoCtaFacturacionViewClientes"
import { AlumnoEdoCtaFacturacionViewMovs as ViewMovs } from "./AlumnoEdoCtaFacturacionViewMovs"

export namespace UIUtilViewAlumnoEdoCtaFacturacion {

    enum CStatus {
        _ = 0,
        PROCESANDO,
        OK,
        ERROR
    }
    type ICFDIInfoItemFinalControl = ViewCFDIInfo.ICFDIInfoItemFinal & {
        STATUS?: CStatus
        MESSAGE_STATUS?: string
        ID_FOLIO?: number
        // UID?: string
        UUID?: string
    }
    type ListFinal = List<ICFDIInfoItemFinalControl> & {
        // PROCESO_FIN?: boolean
    }

    export function _OpenModal_GeneraCFDI(idEscuela: number, idAlumno: number, onEndProccess: () => void, idsPagos?: number[]) {
        let viewInfoPagos: ViewMovs.ViewMovimientosPagos
        let viewClientesAsignados: ViewClientes.ViewClientesAsignados
        let viewInfoCFDI: ViewCFDIInfo.ViewInfoCFDI
        if (!idsPagos)
            idsPagos = []
        ModalThings._GetModalToALongProccess({
            Modulo: Util._MODULO_ALUMNO_EDOCTA,
            StartEndIds: ["movs", "detalle_final"],
            StepsConfig: [
                {
                    Id: "movs",
                    NextID: "clientes",
                    Title: _L("panelfinanzasedocuenta_factura.movs"),
                    OnValideStep: () => viewInfoPagos.IsValid(),
                    OnFocusContent: () => viewInfoPagos?.RefreshInfo(),
                    OnDrawContent: (container, mt) => setTimeout(() => {
                        viewInfoPagos = ViewMovs.GenerarCFDI_ViewPagosInfo(container, mt, idEscuela, idAlumno, idsPagos)
                        viewInfoPagos.LoadComplete.then(res => {
                            if (!res) {
                                console.warn("viewInfoPagos >> Error Disponibilidad")
                                return
                            }
                            if (idsPagos.length)
                                viewInfoPagos.SelectItems(idsPagos)
                            else
                                viewInfoPagos.SelectAll()
                        })
                        Util._ViewAddSyncButton(container, () => viewInfoPagos.RefreshInfo())
                    }),
                },
                {
                    Id: "clientes",
                    PreviousID: "movs",
                    NextID: "info_cfdi",
                    Title: _L("panelfinanzasedocuenta_factura.clientes_asig"),
                    OnDrawContent: (container, mt) => setTimeout(() => {
                        viewClientesAsignados = ViewClientes.GenerarCFDI_ViewClientesAsignados(container, mt, idEscuela, idAlumno)
                        Util._ViewAddSyncButton(container, () => viewClientesAsignados.RefreshList())
                    }),
                    OnValideStep: () => {
                        const valid = !!viewClientesAsignados.GetSelectedClient()
                        return {
                            IsValid: valid,
                            Message: !valid
                                ? _L("panelfinanzasedocuenta_factura.cliente_require")
                                : null,
                        }
                    },
                    OnFocusContent: () => viewClientesAsignados?.RefreshList(false),
                },
                {
                    Id: "info_cfdi",
                    PreviousID: "clientes",
                    NextID: "detalle_final",
                    Title: _L("panelfinanzasedocuenta_factura.nuevo_cfdi"),
                    Width: 1000,
                    OnDrawContent: (container, mt) => {
                        viewInfoCFDI = ViewCFDIInfo._GenerarCFDI_ViewInfo(container, mt, idEscuela, idAlumno)
                    },
                    OnFocusContent: () => viewInfoCFDI.Refresh(viewClientesAsignados.GetSelectedClient(), viewInfoPagos.GetMovimientosCFDI()),
                    OnValideStep: () => viewInfoCFDI.IsValid(),
                },
                {
                    Id: "detalle_final",
                    PreviousID: "info_cfdi",
                    Title: _L("panelfinanzasedocuenta_factura.tag_details"),
                    OnDrawContent: (content, mt) => {
                        content.append("b").attr("class", "tag_message")
                    },
                    Width: 400,
                    OnFocusContent: (content, mt) => {
                        const cfdisInfo = viewInfoCFDI.GetData()
                        console.debug(">>> ", cfdisInfo)
                        content.select(".tag_message")
                            .text(_L(cfdisInfo.length > 1
                                ? "panelfinanzasedocuenta_factura.cfdis_a_crear"
                                : "panelfinanzasedocuenta_factura.cfdi_a_crear", cfdisInfo.length))
                        // mt.BtnRight.text(_L("panelfinanzasedocuenta_factura.generar_cfdi"))
                        let listView: ListFinal = content.node()["__list_final__"]
                        if (!listView) {
                            listView = Util._CreateListControl<ICFDIInfoItemFinalControl>({ addMarginTop: true })
                                ._SetParent(content)
                                ._SetUpdateItem((itemContent, d) => UpdateResultItemListComponent(itemContent, d, idEscuela, mt.Progress.node()))
                            content.node()["__list_final__"] = listView
                        }
                        listView._SetItems(cfdisInfo)
                    },
                    OnAccept: async (mt) => {
                        const listView: ListFinal = mt.CurrentBody.node()["__list_final__"]
                        const confirma = await ModalThings._GetConfirmacionBasicoV2({
                            Title: _L("panelfinanzasedocuenta_factura.generar_cfdi"),
                            Content: _L("panelfinanzasedocuenta_factura.confirma_generar_cfdi"),
                            Width: 200,
                        })
                        if (!confirma) {
                            return null
                        }
                        // mt.BtnLeft.classed("hide_transparent", true).on("click", null)
                        mt.Modal._FooterSelection.remove()
                        mt.Modal._EscKeydownEnabled = false
                        const finalData: ICFDIInfoItemFinalControl[] = viewInfoCFDI.GetData()
                        listView._SetItems(finalData)
                        console.warn("Procesando...", finalData)
                        const cliente = viewClientesAsignados.GetSelectedClient()
                        let hasSuccessResponse = false
                        for (const cfdiInfo of finalData) {
                            cfdiInfo.STATUS = CStatus.PROCESANDO
                            cfdiInfo.MESSAGE_STATUS = _L("general.procesando")
                            listView._RefreshList()
                            const res = await CrearCFDI(idEscuela, idAlumno, cliente, cfdiInfo)
                            cfdiInfo.STATUS = res.Resultado > 0 ? CStatus.OK : CStatus.ERROR
                            cfdiInfo.MESSAGE_STATUS = _HttpMsgV2(res)
                            if (res.Resultado > 0) {
                                cfdiInfo.ID_FOLIO = res.Datos.IdFolio
                                // cfdiInfo.UID = res.Datos.UID
                                cfdiInfo.UUID = res.Datos.UUID
                                hasSuccessResponse = true
                            }
                            listView._RefreshList()
                            console.warn("Procesado ", cfdiInfo)
                        }
                        if (hasSuccessResponse) {
                            new Button(mt.CurrentBody, _L("general.descargartodo"))._d3Selection
                                .style("margin-top", "var(--padding1)")
                                .on("click", () => Util._DescargarCFDIsZip(cliente.RFC, idEscuela, finalData.map(d => d.ID_FOLIO), mt.Progress.node()))
                        }
                        // list.PROCESO_FIN = true
                        listView._RefreshList()
                        console.warn("FIN")
                        onEndProccess()
                        return null
                    }
                }
            ]
        })
    }

    function CrearCFDI(idEscuela: number, idAlumno: number, cliente: IFacturaClienteAlumno, cfdiInfo: ICFDIInfoItemFinalControl) {
        return _FacturaCrearCFDI(idEscuela, idAlumno, cliente.UID,
            {
                EnviarCorreo: cfdiInfo.EnviarCorreo,
                Fecha: cfdiInfo.Fecha,
                FormaPago: cfdiInfo.FormaPago,
                LugarExpedicion: cfdiInfo.LugarExpedicion,
                MetodoPago: cfdiInfo.MetodoPago,
                Serie: cfdiInfo.Serie,
                TipoDocumento: cfdiInfo.TipoCfdi,
                UsoCFDI: cfdiInfo.UsoCFDI
            },
            cfdiInfo.Conceptos.map(c => ({
                ClaveProdServSAT: c.ClaveProdServSAT,
                ClaveUnidadSAT: c.ClaveUnidadSAT,
                IdPago: c.IdPago,
                UnidadSAT: c.UnidadSAT,
            })))
    }

    function UpdateResultItemListComponent(itemContent: TSelectionHTML<"div", ICFDIInfoItemFinalControl, any, any>, d: ICFDIInfoItemFinalControl, idEscuela: number, progressBar: HTMLProgressElement) {
        let statusColor = "var(--color_borderbox1)"
        // let message = ""
        if (d.STATUS == CStatus.OK) {
            statusColor = "var(--color_app_green1)"
        } else if (d.STATUS == CStatus.PROCESANDO) {
            statusColor = "var(--color_app_orange1)"
        } else if (d.STATUS == CStatus.ERROR) {
            statusColor = "var(--color_app_red1)"
        }
        itemContent.text("")
        const div = itemContent.append("div")
            .style("padding", "var(--padding2)")
            .style("border-radius", "var(--border_radius_base)")
            .style("border-left", "6px solid " + statusColor)
            .style("display", "flex")
            .style("flex-direction", "column")
            .style("gap", "var(--padding2)")

        div.append("span")
            .call(span => {
                span.append("b").text(d.ID + (d.MESSAGE_STATUS ? ": " : ""))
                // div.append("br")
                if (d.MESSAGE_STATUS) {
                    span.append("label").text(d.MESSAGE_STATUS)
                }
            })
        if (d.ID_FOLIO) {
            Util._ViewDescargarCFDIFiles(div, idEscuela, d.ID_FOLIO, progressBar)
        }
    }
}
