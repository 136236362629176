import { DataDRequest } from "../../data/DRequest";
import { Entidad } from "../../data/Entidad";
import { _HttpMsgV2 } from "../../util/Labels";
import { UIUtilLang } from "../util/Language";
import { UIUtilPermission } from "../util/Permission";
import { Button } from "./Button";
import { FormGenerator } from "./Formulario";
import { Modal } from "./Modal";
import { NotificacionV2 } from "./NotificacionV2";

export namespace ModalThings {
    import CModulo = Entidad.CModulo;
    import CAccionPermiso = Entidad.CAccionPermiso;

    type TStrToModalBotons = "aceptar_cancelar" | "sí_no";
    export interface IModalThingsV2<TExtras = any> {
        Progress: TSelectionHTML<"wc-progress">;
        Modal: Modal;
        CurrentBody: d3.Selection<HTMLDivElement, any, any, any>;
        Extras?: TExtras;
    }

    interface IModalThingsBase1 {
        Title: string;
        /** @default "aceptar_cancelar" */
        StrModalBotons?: TStrToModalBotons;
        Width?: NonNullable<number>;

        /**
         * * Requerido para verificar permisos
         * * Auxiliar para obtener lang context en thisConfig.LangModuleKeyInContext
        */
        Modulo?: CModulo;
        /** Requerido para verificar permisos */
        Action?: CAccionPermiso;
        IdsEscuelas?: number[];

        /** @default config.Modulo != null ? CModulo[config.Modulo] : null */
        LangModuleKeyInContext?: string;
    }

    // **********************************************************
    // OLD MODALTHINGS
    // **********************************************************
    interface IModalButton extends TSelectionHTML<"button"> {
        /** @deprecated use {@link IModalButton._Enable} function instead */
        fn_Enable(): IModalButton;
        /** @deprecated use {@link IModalButton._Enable} function instead */
        fn_Disable(): IModalButton;
        _Enable(enable: boolean): IModalButton;
    }
    export interface IModalThings<TExtras = any> extends IModalThingsV2<TExtras> {
        readonly BtnLeft: IModalButton;
        readonly BtnRight: IModalButton;
    }

    /** // NOTE: No tiene soorte para ajustar Lang context */
    export function _GetModalThings<TExtras = any>(initTitle: string, initWidth: number, classSelected: string, modalThings?: IModalThings, initHeight?: string): IModalThings<TExtras> {
        if (!modalThings) {
            const modal = new Modal()
                ._Mostrar();
            let progress = modal._BodySelection.append<HTMLProgressElement>("wc-progress").attr("oculto", true);

            modal._FooterSelection
                .style("justify-content", "space-between")
                .selectAll(".buttons");

            const fnGetModalBotton = (button: TSelectionHTML<"button">): IModalButton => {
                const resButton = button as IModalButton;
                resButton.fn_Enable = () => (Button._EnableButton(button, true), resButton);
                resButton.fn_Disable = () => (Button._EnableButton(button, false), resButton);
                resButton._Enable = (enable: boolean) => (Button._EnableButton(button, enable), resButton);
                return resButton;
            }

            modalThings = {
                Progress: progress as any as TSelectionHTML<"wc-progress">,
                Modal: modal,
                CurrentBody: null,
                BtnLeft: fnGetModalBotton(modal._BtnLeftSelection),
                BtnRight: fnGetModalBotton(modal._BtnRightSelection),
            };
        }

        modalThings.Modal._BodySelection
            .style("height", null)
            .selectAll(":scope > div")
            .classed("hide", true);

        modalThings.CurrentBody = modalThings.Modal._BodySelection.select("." + classSelected);

        if (!modalThings.CurrentBody.node()) {
            modalThings.CurrentBody = modalThings.Modal._BodySelection.append("div")
                .classed(classSelected, true);
        }

        modalThings.Modal._ModalSelection
            .style("width", initWidth + "px")
            .style("height", (initHeight || null));

        modalThings.CurrentBody
            .classed("hide", false)
            .style("height", "100%");
        modalThings.BtnLeft.classed("hide_transparent", true).on("click", null);
        modalThings.BtnLeft.on("click", null);
        modalThings.Modal._SetTitle(initTitle);
        return modalThings;
    }
    export interface IConfigModalConfirmacion extends Omit<IModalThingsBase1, keyof Pick<IModalThingsBase1, "Title">> {
        /** @default "Mensaje de confirmación" */
        Title?: string;
        /** Agrega un label con el valor
         * * Nota: acepta html en la cadena
         */
        Message?: string;
        /** Personaliza contenido extra despues del mensaje de confirmación */
        DrawContent?: (content: TSelectionHTML<"div">, modalThings: IModalThings) => void;
        /** @default 400 px */
        Width?: NonNullable<number>;
        OnClose?: () => void;
        OnAccept?: (modalThings: IModalThings) => Promise<any> | any;
    }

    /** Si no se define OnAccept, el botón de "Cancelar" | "No" no será visible
     * * Retorna null, si no se tiene permisos
     */
    export function _GetConfirmacionModal(conf: IConfigModalConfirmacion): (IModalThings | null) {
        let finalConf: IConfigModalConfirmacion = {
            ... {
                Width: 400,
                StrModalBotons: "aceptar_cancelar"
            },
            ...conf
        }
        if (finalConf.Action == null) {
            finalConf.Title = (finalConf.Title || UIUtilLang._GetUIString("general", "msjconfirma"));
        }

        if (!ActionPermission(finalConf.Action, finalConf.Modulo, finalConf.IdsEscuelas)) {
            return null;
        }

        // >> LangModuleContext Fix
        const langContextTitle = GetLangContext(finalConf.LangModuleKeyInContext, finalConf.Title, finalConf.Modulo, finalConf.Action);
        finalConf.LangModuleKeyInContext = langContextTitle.ModuleKeyInContext;

        const langContextMessage = GetLangContext(finalConf.LangModuleKeyInContext, finalConf.Message, finalConf.Modulo, finalConf.Action);

        // >> Build modal
        let modalT = ModalThings._GetModalThings(langContextTitle.StringTag, finalConf.Width, "body_temp");

        if (finalConf.Message) {
            modalT.CurrentBody.append("label")
                .html(langContextMessage.StringTag)
                .style("white-space", "pre-line");
        }

        if (finalConf.DrawContent) {
            finalConf.DrawContent(modalT.CurrentBody, modalT);
        }

        if (finalConf.OnAccept) {
            modalT.BtnLeft.classed("hide_transparent", false);
        } else {
            modalT.BtnLeft.remove();
        }
        modalT.Modal._OnClose(finalConf.OnClose);
        modalT.Modal._FooterSelection
            .style("justify-content", null);

        modalT.BtnLeft
            .style("margin-right", "0px")
            .on("click", () => {
                modalT.Modal._Ocultar();
            })

        modalT.BtnRight
            .on("click", async () => {
                if (modalT.BtnRight.classed("btn_disable")) return;
                modalT.Modal._DeshabilitarBtns();
                if (finalConf.OnAccept) {
                    let onAcceptRes = finalConf.OnAccept(modalT);
                    // console.warn("Es promesa", (onAcceptRes instanceof Promise), (onAcceptRes instanceof Promise))
                    if (onAcceptRes && onAcceptRes instanceof Promise) {
                        modalT.Progress.node()._Visible = true;
                        await onAcceptRes;
                        modalT.Progress.node()._Visible = false;
                    }
                    // else {
                    //     finalConf.OnAccept(modalT);
                    // }
                }
                modalT.Modal._Ocultar();
            });

        AjustaTextoBotonesModal(modalT, finalConf.StrModalBotons);

        return modalT;
    }

    /**
     * @param title titulo de modal @default "Mensaje de confirmación"
     * @param content acepta html, si es `string` se appendiza dentro de un elemento `<label>`
     * @param width @default 400 + "px"
     * @param typeStrModalBotons @default "aceptar_cancelar"
     *
     * // NOTE No tiene soporte para ajustar Lang context
     */
    export function _GetConfirmacionBasico(title: (string | null), content: string | TSelectionHTML<"htmlelement"> | HTMLElement, width = 400, typeStrModalBotons: TStrToModalBotons = "aceptar_cancelar") {
        return _GetConfirmacionBasicoV2({
            Title: title,
            Content: content,
            Width: width,
            TypeStrModalBotons: typeStrModalBotons,
        })
    }

    interface IConfigModalConfirmacionBasicoV2 extends Pick<IConfigModalConfirmacion, "Title" | "Width"> {
        /** Acepta string-html, si es `string` se appendiza dentro de un elemento `<label>` */
        Content: string | TSelectionHTML<"htmlelement"> | HTMLElement;
        /** @default "aceptar_cancelar" */
        TypeStrModalBotons?: TStrToModalBotons;
        /** @default true */
        Calcelable?: boolean;
    }

    /** // NOTE No tiene soporte para ajustar Lang context */
    export function _GetConfirmacionBasicoV2(config: IConfigModalConfirmacionBasicoV2) {
        const { Content } = config;
        if (!config.Title) {
            config.Title = UIUtilLang._GetUIString("general", "msjconfirma");
        }
        return new Promise<boolean>(resolve => {
            const mt = _GetConfirmacionModal({
                Title: config.Title,
                Message: typeof Content == "string" ? Content : null,
                DrawContent: (() => {
                    if (typeof Content == "string") return null;
                    else if (Content instanceof HTMLElement) return (container) => container.append(() => Content);
                    else return (container) => container.append(() => Content.node());
                })(),
                Width: config.Width || 400,
                StrModalBotons: config.TypeStrModalBotons,
                OnAccept: () => resolve(true),
                OnClose: () => resolve(false),
            })
            if (config.Calcelable === false) {
                mt.Modal._EscKeydownEnabled = false;
                mt.BtnLeft.remove();
                mt.Modal._BtnCloseSelection.remove();
            }
        })
    }

    /** Modal sin pie 🦶 */
    export function _GetModalSimple(config: Pick<IConfigModalConfirmacion, "DrawContent" | "Message" | "StrModalBotons" | "Title" | "Width" | "LangModuleKeyInContext">) {
        _GetConfirmacionModal({
            Title: config.Title,
            Message: config.Message,
            LangModuleKeyInContext: config.LangModuleKeyInContext,
            Width: (config.Width || 400),
            DrawContent: (content, modalThings) => {
                modalThings.Modal._FooterSelection
                    .remove();

                if (config.DrawContent) {
                    config.DrawContent(content, modalThings);
                }
            },
            StrModalBotons: config.StrModalBotons
        })
    }

    export interface IConfigModalToForm<T, TExtras = any> extends Pick<IModalThingsBase1, "Title" | "Modulo" | "Action" | "IdsEscuelas" | "LangModuleKeyInContext"> {
        /** Si se define "Action", por default toma la cadena del key del enum, a menos que se defina */
        AccionToHttpMessage?: string;

        GetForm: (content: TSelectionHTML<"div">, modalThings: IModalThings<TExtras>) => FormGenerator<T>;
        // Message?: string,
        /** Personaliza contenido extra despues del mensaje de confirmación y despues de dibujar el formulario */
        DrawContent?: (content: TSelectionHTML<"div">, form: FormGenerator<T>, modalThings: IModalThings<TExtras>) => void;
        /** @default 500 px */
        Width?: number;
        Height?: string;
        OnClose?: () => void;
        OnAccept?: (form: FormGenerator<T>, modalThings: IModalThings<TExtras>) => Promise<DataDRequest.IResultadoPeticion<any>>;
    }

    /** Modal especial para un proceso de Formulario */
    export function _GetModalToForm<T, TExtras = any>(config: IConfigModalToForm<T, TExtras>) {
        const finalConf: IConfigModalToForm<T, TExtras> = {
            ... <IConfigModalToForm<T, TExtras>>{
                GetForm: null,
            },
            ...config
        }

        // >> LangModuleContext Fix
        const langContextTitle = GetLangContext(config.LangModuleKeyInContext, config.Title, config.Modulo, config.Action);

        // >> Verifica propiedades de cofiguración
        finalConf.AccionToHttpMessage = (finalConf.AccionToHttpMessage || CAccionPermiso[config.Action]);
        finalConf.Width = (finalConf.Width || 500);

        // >> Crea Form - instancia
        let modalForm: FormGenerator<T>;

        // Create ModalThings
        let modalT = ModalThings._GetModalThings<TExtras>(langContextTitle.StringTag, finalConf.Width, "body_temp", undefined, config.Height);

        // Append From
        if (finalConf.GetForm) {
            modalForm = finalConf.GetForm(modalT.CurrentBody, modalT);
            modalT.CurrentBody.append(() => modalForm._Form.node())
        }

        // Update content draw
        if (finalConf.DrawContent) {
            finalConf.DrawContent(modalT.CurrentBody, modalForm, modalT);
        }

        // Verificacion de permisos
        let hasPermission = ActionPermission(config.Action, config.Modulo, config.IdsEscuelas);

        modalT.Modal._OnClose(finalConf.OnClose);

        if (!finalConf.OnAccept || !hasPermission) {
            modalT.Modal._FooterSelection
                .remove();
        } else {
            modalT.BtnLeft.classed("hide_transparent", false);
            // else {
            //     modalT.BtnLeft.remove();
            // }

            modalT.Modal._FooterSelection
                .style("justify-content", null);

            modalT.BtnLeft
                .style("margin-right", "0px")
                .on("click", () => {
                    modalT.Modal._Ocultar();
                })

            modalT.BtnRight
                .on("click", async () => {
                    if (modalT.BtnRight.classed("btn_disable")) return;
                    if (finalConf.OnAccept) {
                        if (modalForm._GetIsValidForm()) {
                            modalT.Modal._DeshabilitarBtns();
                            modalT.Progress.attr("oculto", false);
                            // if (finalConf.OnAccept instanceof Promise) {
                            let res = await finalConf.OnAccept(modalForm, modalT);

                            if (res) {
                                let mess = res.Mensaje || UIUtilLang._GetHTTPMessage(res, finalConf.AccionToHttpMessage) || "";
                                if (res.Resultado > 0) {
                                    modalT.Modal._Ocultar();
                                } else {
                                    modalT.Modal._HabilitarBtns();
                                    modalT.Progress.attr("oculto", true);
                                }
                                NotificacionV2._Mostrar(mess, res.Resultado > 0 ? "INFO" : "ADVERTENCIA");
                            } else {
                                modalT.Modal._HabilitarBtns();
                                modalT.Progress.attr("oculto", true);
                            }
                        }
                    }
                });
        }

        return {
            ...modalT,
            ...{
                Form: modalForm
            }
        };
    }

    export interface IConfigModalToAProccess<TExtras> extends Pick<IModalThingsBase1, "Title" | "Modulo" | "Action" | "IdsEscuelas" | "LangModuleKeyInContext"> {
        /** Si se define "Action", por default toma la cadena del key del enum, a menos que se defina */
        AccionToHttpMessage?: string;
        /** Personaliza contenido extra despues del mensaje de confirmación */
        DrawContent?: (content: TSelectionHTML<"div">, modalThings: IModalThings<TExtras>) => void;
        /** @default 500 px */
        Width?: number;
        Height?: string;
        OnClose?: () => void;
        OnAccept?: (modalThings: IModalThings<TExtras>) => Promise<DataDRequest.IResultadoPeticion<any>>;
    }

    /** Modal especial para un proceso X */
    export function _GetModalToAProccess<TExtras = any>(config: IConfigModalToAProccess<TExtras>) {
        const finalConfig: IConfigModalToAProccess<TExtras> = {
            ... <IConfigModalToAProccess<TExtras>>{
                Width: 500,
                AccionToHttpMessage: CAccionPermiso[config.Action]
            },
            ...config
        }

        // >> Langcontext fix
        const langContextTitle = GetLangContext(finalConfig.LangModuleKeyInContext, finalConfig.Title, finalConfig.Modulo, finalConfig.Action);

        // Create ModalThings
        let modalT = ModalThings._GetModalThings<TExtras>(langContextTitle.StringTag, finalConfig.Width, "body_temp", undefined, config.Height);

        // Draw content
        if (finalConfig.DrawContent) {
            finalConfig.DrawContent(modalT.CurrentBody, modalT);
        }

        // Verificacion de permisos
        let hasPermission = ActionPermission(config.Action, config.Modulo, config.IdsEscuelas);

        modalT.Modal._OnClose(finalConfig.OnClose);

        if (!finalConfig.OnAccept || !hasPermission) {
            modalT.Modal._FooterSelection
                .remove();
        } else {
            modalT.BtnLeft.classed("hide_transparent", false);
            //     modalT.BtnLeft.remove();
            // }
            modalT.Modal._FooterSelection
                .style("justify-content", null);

            modalT.BtnLeft
                .style("margin-right", "0px")
                .on("click", () => {
                    modalT.Modal._Ocultar();
                })

            modalT.BtnRight
                .on("click", async () => {
                    if (modalT.BtnRight.classed("btn_disable")) return;
                    modalT.Modal._DeshabilitarBtns();
                    modalT.Progress.attr("oculto", false);
                    // if (finalConf.OnAccept instanceof Promise) {
                    let res = await finalConfig.OnAccept(modalT);

                    if (res) {
                        let mess = UIUtilLang._GetHTTPMessage(res, finalConfig.AccionToHttpMessage);
                        if (res.Resultado > 0) {
                            modalT.Modal._Ocultar();
                        } else {
                            modalT.Modal._HabilitarBtns();
                            modalT.Progress.attr("oculto", true);
                        }
                        NotificacionV2._Mostrar(mess, res.Resultado > 0 ? "INFO" : "ADVERTENCIA");
                    } else {
                        modalT.Modal._HabilitarBtns();
                        modalT.Progress.attr("oculto", true);
                    }
                });
        }
        return modalT;
    }

    interface IConfigModalInfoData<TData> extends Pick<IModalThingsBase1, "Title" | "LangModuleKeyInContext"> {
        InfoText: string;
        DataList: TData[];
        /** @default 400 px */
        Width?: number;
        OnStepItemData?: (content: TSelectionHTML<"div">, itemData: TData) => void;
        OnAccept?: (modalThings: IModalThings) => void;
        OnClose?: () => void;
    }
    /**
     * Muestra una lista de elementos X a manera de resumen básico
     * 
     * * Por defecto solo muestra el boton de "Aceptar" (BtnRight)
     * @param config 
     */
    export function _GetModalInfoDataList<TData>(config: IConfigModalInfoData<TData>) {
        return _GetConfirmacionModal({
            Title: config.Title,
            Message: config.InfoText,
            LangModuleKeyInContext: config.LangModuleKeyInContext,
            Width: (config.Width || 400),
            OnAccept: (modalThings) => {
                //modalThings.Modal.met_Ocultar();

                if (config.OnAccept) {
                    config.OnAccept(modalThings);
                }
            },
            OnClose: config.OnClose,
            DrawContent: (content, modalThings) => {
                modalThings.BtnLeft.classed("hide_transparent", false);
                modalThings.BtnLeft.classed("hide", true);

                content.classed("modal_details_1", true);

                let contDataList = content.append("div")
                    .attr("class", "datalist");
                config.DataList.forEach(d => {
                    let itemC = contDataList.append("div");
                    let content = itemC.append("div")
                        .style("border-left", "1px solid var(--color_primary4)")
                        .style("padding-left", "var(--padding1)");
                    if (config.OnStepItemData) {
                        config.OnStepItemData(content, d);
                    } else {
                        content.html(String(d));
                    }
                })
            },
        })
    }

    export interface IConfigProcessServiceByServiceBasic<TData> extends IModalThingsBase1 {
        /** Mensaje de confirmación para el usuario antes de continuar con el proceso */
        Message: string;
        OnDrawContent?: (content: TSelectionHTML<"div", any, any>, modalThings: IModalThings) => void;
        DataToProccess: Array<TData>;
        /** Se invoca al llamar al proceso de cada uno de los items del arreglo de datos
         * * Tiene prioridad sobre 'OnStepAllProccess'
         */
        OnStepAProccess: (itemData: TData) => Promise<NonNullable<DataDRequest.IResultadoPeticion<any>>>;
        /** Su uso supone que todos los elementos del arreglo se procesan al mismo tiempo (Ej.: En un solo servicio que recive una matriz)
         * * Solo se invoca si 'OnStepAProccess' no está definido
         */
        OnStepAllProccess?: (itemData: TData[]) => Promise<NonNullable<DataDRequest.IResultadoPeticion<any>>>;
        /** Acepta HTML */
        OnError_GetItemDataTag: (itemData: TData, container: TSelectionHTML<"div">) => (string | void);
        /** Se invoca al final de cada intento por procesar los datos faltantes (incluyendo la primera ves). No es el final de todo el proceso. */
        OnEndProccess?: (results: IResponseItemData<TData>[]) => void;
        // OnEndAllProccess: (itemsResult: Array<data.DRequest.IResultadoPeticion<any>>) => void;
        OnEvalRetry?: (results: IResponseItemData<TData>[]) => boolean;

        /** Si se define "Action", por default toma la cadena del key del enum, a menos que se defina
         * @deprecated
        */
        AccionToHttpMessage?: string;

        TypeRequest: Entidad.CTipoRequest;

        /** @default 500 */
        Width?: number;
    }

    interface IResponseItemData<TData> extends DataDRequest.IRequestResponseA<any> {
        ItemData: TData;
    }

    /** * Verifica permisos antes de abrir el modal, en caso de no tener permisos, no abre el modal, se necesita: Action, Modulo, IdsEscuelas (Opcional)
     * * Al finalizar, verifica si existen items incorrectos y muestra un modal a modo de resumen,
     * * La promesa devuelve (en caso de tener permisos) la lista de todos los datos a procesar con sus respectivos resultados de proceso.
    */
    export async function _OpenModalToProccessServiceByServiceFromAArrayBasic<TData>(config: IConfigProcessServiceByServiceBasic<TData>): Promise<IResponseItemData<TData>[] | null> {
        return new Promise((resolve1) => {
            let dataProccess: IResponseItemData<TData>[] = config.DataToProccess
                .map(d => ({
                    Resultado: -1,
                    ItemData: d,
                    TipoRequest: config.TypeRequest,
                }));

            const cmt = _GetConfirmacionModal({
                Title: config.Title,
                Message: config.Message,
                DrawContent: config.OnDrawContent,

                IdsEscuelas: config.IdsEscuelas,
                Modulo: config.Modulo,
                Action: config.Action,

                Width: config.Width,
                StrModalBotons: config.StrModalBotons,
                OnAccept: (modalThings) => _ProccessServiceByServiceFromAArrayIterator(modalThings, dataProccess, config),
                OnClose: () => resolve1(dataProccess),
            });

            if (cmt == null) {
                resolve1(null);
            }
        });
    }

    export interface IConfigProcessServiceByServiceAdvance<TData> extends Omit<IConfigProcessServiceByServiceBasic<TData>, keyof Pick<IConfigProcessServiceByServiceBasic<TData>, "DataToProccess" | "Message" | "StrModalBotons" | "TypeRequest">> {
        TypeRequest?: Entidad.CTipoRequest;
        OnGetDataToProccess: () => TData[];
        OnDrawContent: (content: TSelectionHTML<"div">, modalThings: IModalThings) => void;
        OnEndAndCloseProccess?: (datosCorrectos: TData[], allDataProccess: IResponseItemData<TData>[]) => void;
        OnValideData?: (modalThings: IModalThings) => boolean;
    }

    export async function _OpenModalToProccessServiceByServiceFromAArrayAdvance<TData>(config: IConfigProcessServiceByServiceAdvance<TData>): Promise<IResponseItemData<TData>[] | null> {
        const resultData = await new Promise<IResponseItemData<TData>[]>((resolve) => {
            let dataProccess: IResponseItemData<TData>[] = [];

            _GetModalToAProccess({
                Title: config.Title,

                IdsEscuelas: config.IdsEscuelas,
                Modulo: config.Modulo,
                Action: config.Action,
                // AccionToHttpMessage: config.AccionToHttpMessage,

                LangModuleKeyInContext: config.LangModuleKeyInContext,
                Width: (config.Width || 500),
                DrawContent: config.OnDrawContent,
                OnAccept: async (modalThings) => {
                    if (config.OnValideData && !config.OnValideData(modalThings)) {
                        return null;
                    }

                    dataProccess = config.OnGetDataToProccess()
                        .map(d => ({
                            Resultado: -1,
                            ItemData: d,
                            TipoRequest: config.TypeRequest,
                        }));

                    // >> Proceso
                    await _ProccessServiceByServiceFromAArrayIterator(modalThings, dataProccess, config);

                    // >> Fin
                    modalThings.Modal._Ocultar();
                    return null;
                },
                OnClose: () => resolve(dataProccess)
            })
        });

        if (config.OnEndAndCloseProccess) {
            const datosCorrectos = resultData
                .filter(d => (d.Resultado > 0))
                .map(d => d.ItemData);

            config.OnEndAndCloseProccess(datosCorrectos, resultData);
        }

        return resultData;
    }

    type IConfigProcessServiceByServiceIterator<T> = Pick<
        IConfigProcessServiceByServiceBasic<T>,
        "OnStepAProccess" | "OnStepAllProccess" | "OnEndProccess" | "OnEvalRetry" | "OnError_GetItemDataTag" | "AccionToHttpMessage" | "Action"
    > & {
        TypeRequest?: Entidad.CTipoRequest,
        GeneralErrMessage?: string
    }
    /** Proceso de iteración de datos recursivo
     * 
     * Finaliza cuando:
     * * Todos los datos se procesan correctamente (res.Resultado > 0)
     * * N datos resultan erroneos y no se Reintenta procesarlos
     * 
     * dataProcess:
     * * Al finalizar en implementación se puede ver dataProcess modificado
     */
    export function _ProccessServiceByServiceFromAArrayIterator<TData>(modalThings: IModalThings, dataProccess: IResponseItemData<TData>[], config: IConfigProcessServiceByServiceIterator<TData>, showNotif = true) {
        const actionHttp = config.AccionToHttpMessage || CAccionPermiso[config.Action];

        return new Promise<void>(async resolve2 => {
            const fnProccessData = async (dataToProccess: IResponseItemData<TData>[]) => {
                let itemsInProccess = dataToProccess.filter(d => (d.Resultado <= 0));
                modalThings.Modal._DeshabilitarBtns();
                modalThings.Progress.attr("oculto", false);

                if (config.OnStepAProccess) {
                    for (let itemData of itemsInProccess) {
                        let itemResult = await config.OnStepAProccess(itemData.ItemData) as IResponseItemData<TData>;

                        itemData.Datos = itemResult.Datos;
                        itemData.Mensaje = itemResult.Mensaje;
                        itemData.Resultado = itemResult.Resultado;
                        itemData.TipoRequest = itemResult.TipoRequest | config.TypeRequest;
                        itemData.EndPointName = itemResult.EndPointName;
                    }
                } else if (config.OnStepAllProccess) {
                    let itemsResult = await config.OnStepAllProccess(itemsInProccess.map(d => d.ItemData)) as IResponseItemData<TData>;

                    itemsInProccess.forEach(itemData => {
                        itemData.Datos = itemsResult.Datos;
                        itemData.Mensaje = itemsResult.Mensaje;
                        itemData.Resultado = itemsResult.Resultado;
                        itemData.TipoRequest = itemsResult.TipoRequest | config.TypeRequest;
                        itemData.EndPointName = itemsResult.EndPointName;
                    })
                }

                if (config.OnEndProccess) {
                    config.OnEndProccess(itemsInProccess);
                }

                // -> Items fallidos
                let itemsFallidos = itemsInProccess.filter(d => (d.Resultado <= 0));
                modalThings.Progress.attr("oculto", true);

                if (itemsFallidos.length > 0) {
                    const modalInfoList = _GetModalInfoDataList<IResponseItemData<TData>>({
                        Title: UIUtilLang._GetUIString("general", "failresume"),
                        InfoText: config.GeneralErrMessage ? config.GeneralErrMessage : UIUtilLang._GetUIString("general", "notif_failarrayproccess"),
                        DataList: itemsFallidos,
                        Width: 280,
                        OnStepItemData: (container, itemData) => {
                            let html = config.OnError_GetItemDataTag(itemData.ItemData, container);
                            if (html) {
                                let messageHttp = itemData.Mensaje || _HttpMsgV2(itemData) || (actionHttp ? UIUtilLang._GetHTTPMessage(itemData, actionHttp) : "");
                                if (messageHttp) {
                                    let lastIndexChar = messageHttp.length - 1;
                                    if (messageHttp[lastIndexChar] == ".") {
                                        messageHttp = messageHttp.slice(0, lastIndexChar);
                                    }
                                    html += `<br><i style="color: var(--color_app_red1);">${messageHttp}.</i>`;
                                }
                                container.html(html);
                            }
                        },
                        OnClose: () => resolve2(), // -> Resignación: Acepta que hay errores y finaliza
                        OnAccept: () => resolve2(), // -> Resignación: Acepta que hay errores y finaliza
                    })

                    if (!config.OnEvalRetry || config.OnEvalRetry(itemsFallidos)) {
                        modalInfoList.BtnLeft
                            .classed("hide", false)
                            .text(UIUtilLang._GetUIString("general", "reintentar"))
                            .on("click", async () => {
                                // -> Terquedad: -_-
                                modalInfoList.Modal._Ocultar(false);
                                if (await fnProccessData(itemsFallidos)) {
                                    resolve2();
                                }
                            })
                    }
                    return false;


                } else {
                    if (showNotif) {
                        let messageHttp = UIUtilLang._GetHTTPMessage({ Resultado: 1, TipoRequest: config.TypeRequest, ...itemsInProccess[0] }, actionHttp);
                        NotificacionV2._Mostrar(messageHttp, "INFO");
                    }
                    return true;
                }
            }

            if (await fnProccessData(dataProccess)) {
                resolve2();
            }
        })

        // console.log("Proceso fin")
        // resolve1();
    }

    // ****************************************************************
    // DOTEST MODAL TO A LONG PROCCESS // DOTEST
    // ****************************************************************
    interface IModalThings_LongProccess extends IModalThings {
        Next();
        Previus();
        CancelAndClose();
        // FocusItem(id: string): boolean;
        GetCurrentStepId(): string;
        // GetCurrentStep(): string;
    }

    type TModalToALongProccessItem_ValidationResult = (boolean | { IsValid: boolean, Message?: string });
    export interface IConfigModalToALongProccessItem {
        Id: string;
        Title: string;
        /** Personaliza contenido extra despues del mensaje de confirmación */
        OnDrawContent: (content: TSelectionHTML<"div">, modalThings: IModalThings) => void;
        OnFocusContent?: (content: TSelectionHTML<"div">, modalThings: IModalThings_LongProccess) => void;
        /** @default 500 px */
        Width?: number;
        Height?: string;
        NextID?: (string | ((modalThings: IModalThings_LongProccess) => string));
        PreviousID?: (string | ((modalThings: IModalThings_LongProccess) => string));
        /** Valida current step para evaluar si se puede enfocar el elemento siguiete */
        OnValideStep?: (content: TSelectionHTML<"div">, modalThings: IModalThings_LongProccess) => TModalToALongProccessItem_ValidationResult | Promise<TModalToALongProccessItem_ValidationResult>;

        OnAccept?: (modalThings: IModalThings) => Promise<DataDRequest.IRequestResponseA<any>>;
    }
    // type TConfigModalToALongProccessSteps = { [key: string]: IConfigModalToALongProccessItem<any> };
    // type TConfigModalToALongProccessSteps<T> = {
    //     [k in keyof T]: IConfigModalToALongProccessItem<keyof T & string>;
    // }[keyof T]

    interface IConfigModalToALongProccess extends Pick<IModalThingsBase1, "Modulo" | "Action" | "IdsEscuelas" | "LangModuleKeyInContext"> {
        /** Si se define "Action", por default toma la cadena del key del enum, a menos que se defina */
        AccionToHttpMessage?: string;

        StepsConfig: IConfigModalToALongProccessItem[];
        //SetConfig: <TStepsConfig extends TConfigModalToALongProccessSteps<TStepsConfig>>() => TStepsConfig2;
        StartEndIds: [string, string];
        OnClose?: () => void;
    }

    // type TConfigModalToALongProccessKeys = keyof TConfigModalToALongProccessSteps<undefined>;

    /** Modal especial para un proceso X de varios pasos */
    export function _GetModalToALongProccess(config: IConfigModalToALongProccess) {
        type TStep = {
            readonly Config: IConfigModalToALongProccessItem;
            Content: TSelectionHTML<"div", any, any>;
            /**
             * @param valideCurrentStep @default true
             */
            Next(valideCurrentStep?: boolean): void;
            Previous(): void;
        };

        const finalConfig: IConfigModalToALongProccess = {
            ... <IConfigModalToALongProccess>{
                // Width: 500,
                AccionToHttpMessage: CAccionPermiso[config.Action]
            },
            ...config
        }

        const steps = new Map<string, TStep>(finalConfig.StepsConfig.map(d => ([
            d.Id,
            {
                Config: {
                    ...<IConfigModalToALongProccessItem>{
                        Width: 500
                    },
                    ...d
                },
                Content: null as TSelectionHTML<"div">,
                Next: null,
                Previous: null
                // ModalThings: null as IModalStepItem_ModalThings
            }
        ])));

        let modalThings: IModalThings_LongProccess;
        let currentStepFocus: TStep;

        const fnExtendsModalThingsFunctions = () => {
            modalThings.CancelAndClose = () => {
                modalThings.Modal._Ocultar();
            }

            modalThings.Next = () => {
                currentStepFocus.Next();
            }

            modalThings.Previus = () => {
                currentStepFocus.Previous();
            }

            modalThings.GetCurrentStepId = () => {
                return currentStepFocus.Config.Id;
            }
        }

        const fnCreateStepModalThings = function (currentStepConfig: IConfigModalToALongProccessItem): TSelectionHTML<"div"> {
            const isFirstStep = Boolean(!modalThings);
            const stepBuilding = steps.get(currentStepConfig.Id);

            // >> Lang context fix
            const langContextTitle = GetLangContext(finalConfig.LangModuleKeyInContext, currentStepConfig.Title, finalConfig.Modulo, finalConfig.Action);

            // >> El primer elemento crea ModalThings
            modalThings = ModalThings._GetModalThings(langContextTitle.StringTag, currentStepConfig.Width, currentStepConfig.Id, modalThings, currentStepConfig.Height) as IModalThings_LongProccess;

            if (isFirstStep) {
                fnExtendsModalThingsFunctions();
            }

            currentStepConfig.OnDrawContent(modalThings.CurrentBody, modalThings);

            // >> On moving next anf previous
            const getStepId = function (getId: (string | ((modalThings: IModalThings_LongProccess) => string))): string {
                if (getId && !(typeof getId == "string")) {
                    return getId(modalThings);
                }
                return getId as string;
            }
            stepBuilding.Next = (valideCurrentStep = true) => fnStepFocus(getStepId(currentStepConfig.NextID), valideCurrentStep);
            stepBuilding.Previous = () => fnStepFocus(getStepId(currentStepConfig.PreviousID), false);

            return modalThings.CurrentBody;
        }

        const fnEvalValidationResult = (validationResult: TModalToALongProccessItem_ValidationResult) => {
            if ((typeof validationResult == "boolean") && !validationResult) {
                return false;
            }
            else if (validationResult != true && !validationResult.IsValid) {
                if (validationResult.Message) {
                    NotificacionV2._Mostrar(validationResult.Message, "ADVERTENCIA");
                }
                return false;
            }
            return true;
        }

        const fnStepFocus = async function (step: string | TStep, valideCurrentStep: boolean): Promise<boolean> {
            const requiredStepFocus = ((typeof step == "string") ? steps.get(step) : step);
            // const currentStepFocus = currentStepFocus;

            if (!requiredStepFocus) {
                console.warn(step, "Required step to focus no found");
                return false;
            }

            // >> Se valida el item actual para poder proceder al siguiente Step
            if (valideCurrentStep && currentStepFocus && currentStepFocus.Config.OnValideStep) {
                let currentStepValidationRes = currentStepFocus.Config.OnValideStep(currentStepFocus.Content, modalThings);
                let validationFinalRes: TModalToALongProccessItem_ValidationResult;

                if (currentStepValidationRes instanceof Promise) {
                    modalThings.Progress.attr("oculto", false);
                    modalThings.Modal._DeshabilitarBtns();
                    await currentStepValidationRes
                        .then((res) => {
                            validationFinalRes = res;
                        })
                        .catch(() => {
                            validationFinalRes = null;
                        });
                    modalThings.Progress.attr("oculto", true);
                    modalThings.Modal._HabilitarBtns();
                } else {
                    validationFinalRes = currentStepValidationRes;
                }

                if (!fnEvalValidationResult(validationFinalRes)) {
                    return false;
                }
            }

            if (!requiredStepFocus.Content) {
                // >> Crea Container faltante (muestra y oculta el actual por defecto)
                requiredStepFocus.Content = fnCreateStepModalThings(requiredStepFocus.Config);
            } else {
                // >> Lang context fix
                const langContextTitle = GetLangContext(finalConfig.LangModuleKeyInContext, requiredStepFocus.Config.Title, finalConfig.Modulo, finalConfig.Action);
                // >> Focus (Muestra el requerido y oculta el actual)
                ModalThings._GetModalThings(langContextTitle.StringTag, requiredStepFocus.Config.Width, requiredStepFocus.Config.Id, modalThings, requiredStepFocus.Config.Height);
            }

            // >> Ajusta vista de pie
            modalThings.BtnLeft.classed("hide_transparent", false);
            modalThings.BtnRight.classed("hide_transparent", false);

            // >>

            if (requiredStepFocus.Config.OnFocusContent) {
                requiredStepFocus.Config.OnFocusContent(requiredStepFocus.Content, modalThings);
            }

            // >> Configura pie de modal para current step
            const hasPrevious = (requiredStepFocus.Config.PreviousID != null);
            const hasNext = (requiredStepFocus.Config.NextID != null);
            const hasAccept = (requiredStepFocus.Config.OnAccept != null);

            modalThings.BtnLeft
                .text(UIUtilLang._GetUIString("general", "anterior"))
                .classed("hide_transparent", !hasPrevious)
                .on("click", !hasPrevious ? null : () => requiredStepFocus.Previous())

            modalThings.BtnRight
                .text(hasAccept ? UIUtilLang._GetUIString("general", "aceptar") : UIUtilLang._GetUIString("general", "siguiente"))
                .classed("hide_transparent", ((!hasNext && !hasAccept) || (!hasNext && !hasPermission)))
                .on("click", async () => {
                    if (modalThings.BtnRight.classed("btn_disable")) return;
                    let closeModal = false;
                    let continueAvailable = true;
                    let validarCurrentStep = true;
                    if (hasAccept) {
                        if (requiredStepFocus.Config.OnValideStep) {
                            let validationResult = requiredStepFocus.Config.OnValideStep(currentStepFocus.Content, modalThings);
                            let validationFinalRes: TModalToALongProccessItem_ValidationResult;

                            if (validationResult instanceof Promise) {
                                modalThings.Progress.attr("oculto", false);
                                modalThings.Modal._DeshabilitarBtns();
                                await validationResult
                                    .then((res) => {
                                        validationFinalRes = res;
                                    })
                                    .catch(() => {
                                        validationFinalRes = null;
                                    });
                                modalThings.Progress.attr("oculto", true);
                                modalThings.Modal._HabilitarBtns();
                            } else {
                                validationFinalRes = validationResult;
                            }
                            continueAvailable = fnEvalValidationResult(validationFinalRes);
                            validarCurrentStep = false;
                        }
                        if (continueAvailable) {
                            closeModal = await fnOnAccept(requiredStepFocus.Config.OnAccept);
                        }
                    }
                    if (!continueAvailable) {
                        console.warn("No es posible continuar...");
                        return;
                    }
                    if (hasNext) {
                        requiredStepFocus.Next(validarCurrentStep);
                    }
                    else if (closeModal) {
                        modalThings.Modal._Ocultar();
                    }
                });

            // >> Success
            currentStepFocus = requiredStepFocus;

            return true;
        }

        const fnOnAccept = async (fn: (modalT) => Promise<DataDRequest.IRequestResponseA>) => {
            modalThings.Modal._DeshabilitarBtns();
            modalThings.Progress.attr("oculto", false);
            // if (finalConf.OnAccept instanceof Promise) {
            const res = await fn(modalThings);
            let successProccess = false;

            if (res) {
                let mess = res.Mensaje;
                if (!mess) {
                    if (finalConfig.AccionToHttpMessage)
                        mess = UIUtilLang._GetHTTPMessage(res, finalConfig.AccionToHttpMessage);
                    else
                        mess = _HttpMsgV2(res)
                }
                // if (res.Resultado > 0) {
                // modalThings.Modal.met_Ocultar();
                // } else {
                if (res.Resultado > 0) {
                    successProccess = true;
                } else {
                    modalThings.Modal._HabilitarBtns();
                    modalThings.Progress.attr("oculto", true);
                }
                NotificacionV2._Mostrar(mess, res.Resultado > 0 ? "INFO" : "ADVERTENCIA");
            } else {
                modalThings.Modal._HabilitarBtns();
                modalThings.Progress.attr("oculto", true);
            }

            return successProccess;
        }

        const startItem = steps.get(config.StartEndIds[0]);
        //const endItem = steps.get(config.StartEndIds[1]);

        // fnCreateStepModalThings(startItem.Config);
        fnStepFocus(startItem, false);

        // >> Verificacion de permisos
        const hasPermission = ActionPermission(config.Action, config.Modulo, config.IdsEscuelas);

        modalThings.Modal._OnClose(finalConfig.OnClose);

        // >> Ajusta vista de pie
        /* modalThings.Modal.prop_FooterSelection
            .style("justify-content", null); */

        /* if (!endItem.Config.OnAccept || !hasPermission) {
            modalThings.Modal.prop_FooterSelection
                .remove();
        } */

        // >>

        if (!hasPermission) {
            return null;
        }

        return modalThings;
    }

    // ****************************************************************
    // LANG CONTEXT
    // ****************************************************************

    interface ILangContext {
        readonly ModuleKeyInContext: string;
        readonly StringTag: string;
    }
    function GetLangContext(moduleKeyInContext: string, stringKey: string, module?: CModulo, action?: CAccionPermiso): ILangContext {
        moduleKeyInContext = (moduleKeyInContext || CModulo[module]);
        let stringTag = stringKey;

        if (moduleKeyInContext && (!stringKey || !stringKey.includes(" "))) {
            if (!stringKey) {
                if (action && CAccionPermiso[action] != null) {
                    stringTag = UIUtilLang._GetUIString("c_actions", CAccionPermiso[action] as any);
                }
            } else {
                if (CAccionPermiso[stringKey] != null) {
                    stringTag = UIUtilLang._GetUIString("c_actions", stringKey as any);
                } else {
                    stringTag = (UIUtilLang._GetUIString(moduleKeyInContext, stringKey) || stringTag);
                }
            }
        }

        return {
            ModuleKeyInContext: moduleKeyInContext,
            StringTag: stringTag
        }
    }

    // ****************************************************************
    // PERMISSIONS THINGS
    // ****************************************************************

    function ActionPermission(action: CAccionPermiso, modulo: CModulo, idsEscuelas?: number[]): boolean {
        let hasPermission = true;
        let permissionMessage: string;

        if (action && modulo) {
            if (idsEscuelas?.length > 0) {
                let permissionRes = UIUtilPermission._HasActionsPermissionFromManySchools(action, modulo, idsEscuelas);
                hasPermission = permissionRes.HasPermission;
                permissionMessage = permissionRes.Message;
            } else {
                hasPermission = UIUtilPermission._HasAccionPermission(action, modulo);
            }
        }

        if (!hasPermission) {
            NotificacionV2._Mostrar((permissionMessage || UIUtilLang._GetUIString("permission", "accionparaescuelas")), "ADVERTENCIA");
        }

        return hasPermission;
    }

    // **

    function AjustaTextoBotonesModal(modalThings: IModalThings, typeStrModalBotons: TStrToModalBotons = "aceptar_cancelar") {
        if (typeStrModalBotons == "sí_no") {
            modalThings.BtnLeft.text(UIUtilLang._GetUIString("general", "niega"));
            modalThings.BtnRight.text(UIUtilLang._GetUIString("general", "afirma"));
        }
    }
}
