export namespace UIUtilScreen {
    // fn_LaunchFullScreen
    export function _LaunchFullScreen() {
        const element = document.body as any;
        if (element.requestFullScreen) {
            element.requestFullScreen();
        } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
        } else if (element.webkitRequestFullScreen) {
            element.webkitRequestFullScreen();
        }
    }
    // Lanza en pantalla completa en navegadores que lo soporten
    export function _CancelFullScreen() {
        const doc = document as any;
        if (doc.cancelFullScreen) {
            doc.cancelFullScreen();
        } else if (doc.mozCancelFullScreen) {
            doc.mozCancelFullScreen();
        } else if (doc.webkitCancelFullScreen) {
            doc.webkitCancelFullScreen();
        }
    }

    export function _IsFullScreen() {
        return document.fullscreenElement;
    }
}
