import * as d3 from "d3";
import VanillaRecyclerView, { InitializeParams, MountParams, VanillaRecyclerViewOptions, VanillaRecyclerViewRenderer } from "vanilla-recycler-view";
import _L from "../../util/Labels";
import { UIUtilGlobalKeyEvents } from "../util/GlobalKeyEvent";
import { UIUtilStrings } from "../util/Strings";
import { UIUtilGeneral } from "../util/Util";
import { CheckBox } from "./CheckBox";

export type TDropdownData<T> = T[] | (() => T[]) | Promise<T[]> | (() => Promise<T[]>);
export interface IDropDownAdvancedConfig<TData, VM extends keyof TData> {
    IsMultiSelect: boolean;
    Parent: HTMLElement | TSelectionHTML<any>;
    ListWidth?: string;
    ValueMember: VM;
    DisplayMember: keyof TData;
    /** NOTE: `() => Promise<T[]>` is Beta */
    Data?: TDropdownData<TData>;
    ShowAndEnableSearchText?: boolean;
    ShowNSelectedInList?: boolean;
    OnSelect?: (datos: Array<TData>) => void;
    OnChangeSearchText_Validator?: (textInput: string, dataItem: TData) => boolean;
    OnChangeSearchText_GetDataValueToEval?: (dataItem: TData) => string;
    GetExternalStringToSearch?: () => string;
    OnStepItemListUI?: (container: TSelectionHTML<any>, dato: TData, step: TypeStep) => void;
    OnChange?: (valueMembers: Array<TData[VM]>, datos: Array<TData>) => void;
    OnChangeVisible?: (visible: boolean) => void
    OnAccept?: (valueMembers: Array<TData[VM]>, datos: Array<TData>) => void; //Add
    OnUpdateData?: () => void;
    HideNoItemsFound?: boolean;
    ArrowMoveKeyBlurPrevent?: boolean;
    RecyclerView?: boolean;
    AppendInBody?: boolean;
}

type TypeStep = ("enter" | "update" | "exit");

interface IItemCtrlSelect<TData> {
    data: TData;
    check: boolean;
    enable: boolean;
    validFilter: boolean;
}

// let INST_COUNT = 0
// let INST_COUNT_REQUIRED = 3
// let INST: any

export class DropdownAdvanced<TData = any, VM extends keyof TData = any> {
    /** Almacena ids seleccionados antes de existir datos en el control.
     *
     * Lista temporal usada cuando la obtención de datos se realiza mediante Promesas.
    */
    private savedCheckedValPreDataList: TData[VM][];
    private dataItems: Map<TData[VM], IItemCtrlSelect<TData>>;
    private config: IDropDownAdvancedConfig<TData, VM>;
    private isMultiselect: boolean;
    private reziseObserver: ResizeObserver;
    private listContainer: d3.Selection<HTMLDivElement, any, HTMLElement, null>;
    private itemsListContainer: TSelectionHTML<"div">;
    private timeSearch: NodeJS.Timeout;
    private dataValuesAuxOnChange: TData[VM][];
    private recyclerView: VanillaRecyclerView<IItemCtrlSelect<TData>>

    // private lastSelections: Set<TData[VM]>; // NOTE Implementar? On Select se llega a llamar más de una ves, en UpdateData y SetSelected
    constructor(config: IDropDownAdvancedConfig<TData, VM>) {
        // if (!INST) {
        //     INST_COUNT++
        //     if (INST_COUNT == INST_COUNT_REQUIRED) {
        //         console.log("Installed")
        //         INST = this
        //     }
        // }
        this.dataItems = new Map();
        this.reziseObserver = UIUtilGeneral._GetResizeObserver(entries => {
            setTimeout(() => this.ReubicateV2(entries[0].contentRect), 200)
        })

        if (config) {
            if (config.ArrowMoveKeyBlurPrevent == null && config.ShowAndEnableSearchText) {
                config.ArrowMoveKeyBlurPrevent = true;
            }
            let defaultConfig = <IDropDownAdvancedConfig<TData, VM>>{
                Data: [],
                ShowAndEnableSearchText: false,
                ShowNSelectedInList: false,
                ArrowMoveKeyBlurPrevent: false,
                AppendInBody: false,
            }
            this.config = { ...defaultConfig, ...config };
            this.isMultiselect = this.config.IsMultiSelect;

            this.CrearElementos();
            if (config.Data)
                this.UpdateData(this.config.Data, true);
        }
    }

    private promiseData: Promise<TData[]>;
    private GetData(forceRequest: boolean): TData[] | Promise<TData[]> {
        if (!this.config.Data) {
            this.promiseData = null
            return []
        }
        if (Array.isArray(this.config.Data)) {
            this.promiseData = null
            return this.config.Data
        }
        if (!forceRequest && this.promiseData)
            return this.promiseData
        let getData: TData[] | Promise<TData[]>
        if (this.config.Data instanceof Promise) {
            getData = this.config.Data
        } else {
            getData = this.config.Data()
        }
        if (Array.isArray(getData)) {
            this.promiseData = null
            return getData
        }
        this.promiseData = getData
        return getData
    }

    private UpdateData(datos: TDropdownData<TData>, forceGetData: boolean = false, onProgress?: (status: ("start" | "end")) => void) {
        // if (INST === this) console.error("Update data init", forceGetData, datos)
        let beforeData: Map<TData[VM], IItemCtrlSelect<TData>>
        let beforeCheckedIdsSet: Set<TData[VM]>
        this.config.Data = datos;
        const fnResolveData = (datos: TData[]) => {
            // if (INST === this) console.log("|__", beforeCheckedIdsSet, `beforeData.size(${beforeData.size})`, this._dataValueMemberSelected)
            let onChangeSelections = false;
            datos.forEach(d => {
                const val = (d[this.config.ValueMember]);
                const beforeItem = beforeData.get(val);
                const checked = this.savedCheckedValPreDataList
                    ? this.savedCheckedValPreDataList.includes(val)
                    : beforeItem?.check || false;

                if (beforeItem) {
                    this.dataItems.set(val, { ...beforeItem, data: d, check: checked });
                } else {
                    this.dataItems.set(val, { check: checked, data: d, enable: true, validFilter: true });
                }
                if ((checked && !beforeCheckedIdsSet.has(val)) || (!checked && beforeCheckedIdsSet.has(val))) {
                    onChangeSelections = true;
                }
            })
            // if (INST === this) console.error("End update >>>>", onChangeSelections, this.savedCheckedValPreDataList, `lenght(${datos.length})>`, ((datos[0] || {})[this.config.DisplayMember as any] + ",..."), "cheched:", Array.from(this.dataItems.values()).filter(d => d.check))
            this.savedCheckedValPreDataList = null
            if (onChangeSelections) {
                this.OnSelectItems()
            }
        }
        const data = this.GetData(forceGetData)

        if (this.savedCheckedValPreDataList && !(this.promiseData instanceof Promise)) {
            this.savedCheckedValPreDataList = null
        }

        if (onProgress) onProgress("start");
        if (Array.isArray(data)) {
            beforeData = new Map(this.dataItems.entries());
            beforeCheckedIdsSet = new Set(this._dataValueMemberSelected);
            this.dataItems.clear();
            fnResolveData(data);
            // beforeData.clear();
            if (onProgress) onProgress("end");
            if (this.config.OnUpdateData) this.config.OnUpdateData();
        } else {
            data
                .then((datos) => {
                    beforeData = new Map(this.dataItems.entries());
                    beforeCheckedIdsSet = new Set(this._dataValueMemberSelected);
                    this.dataItems.clear();
                    fnResolveData(datos);
                })
                .catch((err) => console.warn(err))
                .finally(() => {
                    if (onProgress) onProgress("end");
                    if (this.config.OnUpdateData) this.config.OnUpdateData();
                });
        }
    }

    private Show() {
        this.dataValuesAuxOnChange = [...this.dataItems.entries()].filter(([k, d]) => d.check).map(([k, d]) => k);
        UIUtilGlobalKeyEvents._SetEscKeyEventCallback(this.listContainer.node(), () => this.Hide());
        // if (this.dataItems && this.dataItems.size > 0) {
        let { Parent, AppendInBody } = this.config;
        if (AppendInBody) {
            Parent = document.body
        }
        (Parent instanceof HTMLElement ? d3.select(Parent) as TSelectionHTML : Parent as TSelectionHTML).append(() => this.listContainer.node());
        this.listContainer.select(":scope > .search_wrapper > input")
            .property("value", "");
        this.UpdateItemsListView(true);
        this.ReubicateV2();
        this.reziseObserver?.observe(document.body);
        this.listContainer.classed("mostrar_combo", true);
        this.listContainer.select(".search_wrapper").select<HTMLInputElement>("input").node()?.focus();
        this.listContainer.on("click", () => {
            d3.event.stopPropagation();
        })
        if (this.config.OnChangeVisible) {
            this.config.OnChangeVisible(true);
        }
        this.listContainer.select<HTMLLIElement>(".elemento_lista_seleccionado").node()?.scrollIntoView();

        if (this["__clickbody"]) {
            document.removeEventListener("click", this["__clickbody"]);
        }
        this["__clickbody"] = () => {
            this.Hide();
        }
        setTimeout(() => {
            document.addEventListener("click", this["__clickbody"]);
        })
        if (!this.config.RecyclerView)
            UIUtilGlobalKeyEvents._SetArrowMoveKeyEventCallback({
                ListContainer: this.itemsListContainer.node(),
                StartFocus: [...this.itemsListContainer.node().children].findIndex(item => item.classList.contains("elemento_lista_seleccionado")),
                OnSelect: (item) => {
                    item.click();
                },
                UpdateItemFocusStyle: (item, focused) => {
                    item.style.backgroundColor = focused ? "var(--color_primary2)" : ""; // FIXME
                },
                OnMoveFocus: () => {
                    if (document.activeElement instanceof HTMLElement) {
                        document.activeElement.blur();
                    }
                },
                BlurPrevent: this.config.ArrowMoveKeyBlurPrevent
            })
    }

    private Hide() {
        if (this.isVisible()) {
            if (this["__clickbody"]) {
                document.removeEventListener("click", this["__clickbody"]);
            }

            this.OnChangeItemsSelected();
            this.dataValuesAuxOnChange = null;

            UIUtilGlobalKeyEvents._RemoveEscKeyEventCallback(this.listContainer.node());
            this.reziseObserver?.unobserve(document.body);
            if (this.config.OnAccept) {
                let itemsChecked = this.GetSelectedControlItems().map(d => d.data);
                this.config.OnAccept(itemsChecked.map(d => d[this.config.ValueMember]), itemsChecked);
            }
            this.listContainer.remove();
            if (this.config.OnChangeVisible) {
                this.config.OnChangeVisible(false);
            }
            UIUtilGlobalKeyEvents._RemoveArrowMoveKeyEventCallback(this.itemsListContainer.node())
        }
    }

    private OnChangeItemsSelected() {
        const valueM = this.config.ValueMember;
        const dataSelected: Array<TData> = this.GetDataSelected();
        const dataValuesSelected: Array<TData[VM]> = dataSelected.map(d => d[valueM]);
        const initSelection = this.dataValuesAuxOnChange;

        const hasChanged = (dataValuesSelected.length != initSelection.length) || dataValuesSelected.some(d => !initSelection.includes(d));
        // console.debug("hasChanged", hasChanged, dataValuesSelected, initSelection)

        if (hasChanged) {
            if (this.config.OnChange) {
                this.config.OnChange(dataValuesSelected, dataSelected);
            }
            this.OnSelectItems();
        }
        this.listContainer.select(".search_wrapper").select<HTMLInputElement>("input").node()?.focus();
    }

    // private timeoutOnSelect: NodeJS.Timeout
    private OnSelectItems() {
        // if (this.timeoutOnSelect)
        //     clearTimeout(this.timeoutOnSelect)
        // this.timeoutOnSelect = setTimeout(() => {
        //     this.timeoutOnSelect = null
        let dataValues = this.GetSelectedControlItems().map(d => d.data)
        if (this.config.OnSelect) {
            this.config.OnSelect(dataValues as Array<TData>)
        }
        this.UpdateNSelectedArea(dataValues);
        // }, 50);
    }

    private OnFilterByText(textToSearch: string) {
        this.dataItems.forEach(d => {
            if (this.config.OnChangeSearchText_Validator) {
                d.validFilter = this.config.OnChangeSearchText_Validator(textToSearch, d.data);
            }
            else if (this.config.OnChangeSearchText_GetDataValueToEval) {
                const valueToEval = this.config.OnChangeSearchText_GetDataValueToEval(d.data).toLowerCase();
                d.validFilter = UIUtilStrings._StringRemoveDiacriticMarks(valueToEval).includes(UIUtilStrings._StringRemoveDiacriticMarks(textToSearch.toLowerCase()));
            }
            else {
                const valueToEval = String(d.data[this.config.DisplayMember]).toLowerCase()
                d.validFilter = UIUtilStrings._StringRemoveDiacriticMarks(valueToEval).includes(UIUtilStrings._StringRemoveDiacriticMarks(textToSearch.toLowerCase()));
            }
        })
    }

    private EnableItems(valuesMember: TData[VM][], isEnable: boolean) {
        valuesMember.forEach(val => {
            let itemList = this.dataItems.get(val);
            if (itemList) {
                itemList.enable = isEnable;
            }
        })
    }

    private GetDataFiltered() {
        return Array.from(this.dataItems.values()).filter(d => d.validFilter);
    }

    private async UpdateItemsListView(requestData = false) {
        if (!this.listContainer.node()) return;
        this.UpdateNSelectedArea();

        const hasData = (this.GetDataFiltered().length > 0)

        if (this.config.HideNoItemsFound) {
            this.listContainer
                .classed("hide", !hasData);
        } else {
            this.listContainer.select(".itemsnofound")
                .classed("hide", hasData)
                .text(hasData ? "" : _L("general.loading"));
        }


        this.UpdateData(this.config.Data, requestData, (status) => {
            if (status == "start") {
                this.listContainer.append<HTMLProgressElement>("wc-progress").node();
                return
            }
            const searchInput = this.listContainer.select<HTMLInputElement>(":scope > .search_wrapper > input").node()
            this.OnFilterByText(this.config.GetExternalStringToSearch ? (this.config.GetExternalStringToSearch() || "") : searchInput?.value || "");
            this.listContainer.select<HTMLProgressElement>("wc-progress").remove();

            const dataItems = this.GetDataFiltered();

            let areaCheckMaster = this.listContainer.select(".masteritem_wrapper");
            if (areaCheckMaster.node()) {
                let btnMasterCheck = areaCheckMaster.select(".checkbox_table") as CheckBox.ControlSelection;
                let statusCheck = dataItems.every(d => (!d.enable || d.check));
                CheckBox._UpdateCheckStatus(btnMasterCheck, statusCheck);
            }
            this.RefreshListViewFinal(dataItems)

            if (this.config.HideNoItemsFound) {
                this.listContainer
                    .classed("hide", !hasData);
            } else {
                this.listContainer.select(".itemsnofound")
                    .text((dataItems.length > 0) ? "" : _L("general.nofindoptions"))
                    .classed("hide", (dataItems.length > 0));
            }
            this.ReubicateV2();
        });
    }

    private timeoutRefreshListViewFinal: NodeJS.Timeout
    private RefreshListViewFinal(dataItems: IItemCtrlSelect<TData>[]) {
        if (this.timeoutRefreshListViewFinal) {
            clearTimeout(this.timeoutRefreshListViewFinal)
        }
        this.timeoutRefreshListViewFinal = setTimeout(() => {
            this.timeoutRefreshListViewFinal = null
            if (!this.config.RecyclerView) {
                this.itemsListContainer
                    .selectAll<HTMLLIElement, IItemCtrlSelect<TData>>(":scope > .elemento_lista")
                    .data(dataItems)
                    .join(
                        enter => {
                            return enter
                                .append((d) => this.CreateItemList(d).node())
                                .each((d, i, elemnts) => this.UpdateItemListView(d3.select(elemnts[i]), d, "enter"))
                        },
                        update => update
                            .each((d, i, elemnts) => this.UpdateItemListView(d3.select(elemnts[i]), d, "update")),
                        exit => exit
                            .each((d, i, elemnts) => this.UpdateItemListView(d3.select(elemnts[i]), d, "exit"))
                            .remove()
                    )
                return
            }
            // RECYCLER MODE
            if (!this.recyclerView) {
                this.recyclerView = this.CreateReciclerView(this.itemsListContainer.node())
            }
            this.recyclerView.setData(dataItems)
        }, 50);
    }

    private CreateReciclerView(root: HTMLDivElement): VanillaRecyclerView<IItemCtrlSelect<TData>> {
        const _this = this;
        const options: VanillaRecyclerViewOptions<IItemCtrlSelect<TData>> = {
            //direction: DIRECTION.VERTICAL,
            data: [],
            preload: 20,
            /**
             * Dynamic row size
             */
            /* size: (params) => {
                // params.api
                console.info("*****", params)
            }, */
            renderer: class implements VanillaRecyclerViewRenderer<IItemCtrlSelect<TData>> {
                public layout: HTMLLIElement;
                // onUnmount: (params: UnmountParams<IItemCtrlSelect<TData>>) => void;

                initialize(params: InitializeParams<IItemCtrlSelect<TData>>) {
                    this.layout = _this.CreateItemList(params.data).node();
                    _this.UpdateItemListView(d3.select(this.layout), params.data, "update");
                }
                getLayout() {
                    return this.layout;
                }
                onMount(params: MountParams<IItemCtrlSelect<TData>>) {
                    const d = params.data;
                    _this.UpdateItemListView(d3.select(this.layout), d, "update");
                    return true;
                }
                // onUnmount() {
                //     // const trBodyNode = this.$layout;
                //     // // _this.MenuTopCheckDataOptions_Update();
                //     // // exit.each((d, i, trBodyArray) => {
                //     // (trBodyNode[prop_row_stepjoin] as TTypeStepJoin) = "exit";
                //     // trBodyNode[prop_row_updatedview] = false;
                //     // exit.remove();
                // }
            },
        };
        const control = new VanillaRecyclerView(root, options);
        return control
    }

    private CreateItemList(d: IItemCtrlSelect<TData>) {
        let item: d3.Selection<HTMLLIElement, IItemCtrlSelect<TData>, HTMLDivElement, any> = null;
        if (this.isMultiselect) {
            item = this.CreateItemListOfMultiselect("li")
        } else {
            item = d3.create("li")
                .attr("class", "elemento_lista");
        }
        item.datum(d)
        return item
    }

    private UpdateItemListView(itemListView: d3.Selection<HTMLLIElement, unknown, HTMLDivElement, any>, d: IItemCtrlSelect<TData>, step: TypeStep) {
        itemListView.classed("elemento_lista_seleccionado", d.check)

        let containerContent: (d3.Selection<HTMLDivElement, any, HTMLLIElement, any> | d3.Selection<HTMLLIElement, any, HTMLUListElement, any>) = null;
        let btnCheck: CheckBox.ControlSelection;
        if (this.isMultiselect) {
            containerContent = itemListView.select<HTMLDivElement>(".item_content") as any;
            btnCheck = CheckBox._SeletionCheckApplyDatum(itemListView.select(".check_wrapper").select<SVGSVGElement>(".checkbox_table"))

            CheckBox._UpdateCheckStatus(btnCheck, d.check);
        } else {
            containerContent = itemListView as any;
        }

        if (this.config.OnStepItemListUI) {
            this.config.OnStepItemListUI(containerContent, d.data, step);
        } else {
            containerContent.text(String(d.data[this.config.DisplayMember]));
        }

        if (step == "exit") {
            return;
        }

        itemListView.classed("disabled_item", !d.enable);
        if (d.enable) {
            itemListView.on("click", () => {
                if (!d.enable)
                    return;
                if (this.isMultiselect) {
                    d.check = !d.check;
                    this.UpdateItemsListView();
                } else {
                    this.dataItems.forEach(d => d.check = false);
                    d.check = true;
                    this.Hide();
                }
            });
        }
    }

    private CrearElementos() {
        this.listContainer = d3.create("div")
            .attr("class", "list_container");

        if (this.config.ShowAndEnableSearchText) {
            let input = this.listContainer.append("div")
                .attr("class", "search_wrapper")
                .attr("tabindex", "0")
                .append<HTMLInputElement>("input")
                .attr("type", "text")
                .attr("placeholder", _L("general.buscar"));
            input.node()
                .addEventListener("keyup", (e) => {
                    if (e.key == " ") {
                        e.stopPropagation();
                    }
                    clearTimeout(this.timeSearch);
                    this.timeSearch = setTimeout(() => {
                        this.UpdateItemsListView();
                        // this.OnFilterByText(input.node().value);
                    }, 100);
                });
        }
        if (this.isMultiselect) {
            let areaCheckMaster = this.CreateItemListOfMultiselect("div", _L("general.todo"));
            areaCheckMaster
                .attr("tabindex", "0")
                .node().onkeyup = e => {
                    if (e.key == " ") {
                        e.stopPropagation();
                        (e.target as HTMLElement).click();
                    }
                }

            let btnMasterCheck = CheckBox._SeletionCheckApplyDatum(areaCheckMaster.select(".checkbox_table"));
            this.listContainer.append(() => areaCheckMaster.node())
                .node()
                .onclick = e => {
                    Array.from(this.dataItems.values())
                        .forEach(itemD => {
                            if (!itemD.validFilter || !itemD.enable)
                                return;
                            itemD.check = !btnMasterCheck.datum().IsChecked;
                        })
                    this.UpdateItemsListView();
                }
        }
        this.itemsListContainer = this.listContainer.append("div").attr("class", "lista_de_elementos");

        if (!this.config.HideNoItemsFound) {
            this.listContainer.append("div")
                .text(_L("general.nofindoptions"))
                .classed("itemsnofound hide", true)
                .style("padding", "15px")
                .style("color", "gray")
                .style("font-size", "var(--fontsize_me2)");
        }

        if (this.config.ShowNSelectedInList) {
            this.listContainer.append("div")
                .attr("class", "n_selection_wrapper")
                .style("cursor", "default", "important");

            this.listContainer.select(".n_selection_wrapper").append("label")
                .attr("class", "n_selected_label")
        }

        if (this.config.IsMultiSelect) {
            this.listContainer.append("div").classed("btn_footer_container", true)
                .append("button")
                .classed("foot_btn_list bg_blue", true)
                .text("Aceptar")
                .on("click", () => {
                    this.Hide();
                })
                .node().onkeyup = e => {
                    if (e.key == " ") e.stopPropagation();
                }
        }
    }

    private CreateItemListOfMultiselect<K extends ("div" | "li")>(name: K, textContent: string = ""): d3.Selection<HTMLElementTagNameMap[K], any, null, undefined> {
        let itemList = d3.create(name)
            .attr("class", name == "li" ? "elemento_lista" : "masteritem_wrapper")
            .html(`
                        <div class="check_wrapper"></div>
                        <div class="item_content">${textContent}</div>
                    `);
        itemList.select(".check_wrapper")
            .append(() => CheckBox._GetCheckElement().node());
        return itemList as any;
    }

    private UpdateNSelectedArea(dataValues: TData[] = this.GetSelectedControlItems().map(d => d.data)) {
        if (this.listContainer.node() && this.config.ShowNSelectedInList) {
            this.listContainer.select(".n_selection_wrapper").select(".n_selected_label").text(this.GetDataSelected().length + "/" + this.dataItems.size);
        }
    }

    //private ReubicateV2(dimOverl?: DOMRectReadOnly) {
    //    const NATURALMAXHEIGHT = 400;
    //    const nearLimit = 10;
    //    let body: DOMRect = document.body.getBoundingClientRect();
    //    let parent: DOMRect = (this.config.Parent instanceof HTMLElement ? this.config.Parent : this.config.Parent.node() as HTMLElement).getBoundingClientRect();
    //    let list: DOMRect = this.listContainer.node().getBoundingClientRect();
    //
    //    //Obtener en que posicion hay mas espacio arriba o abajo
    //    const upSpace = parent.y - nearLimit;
    //    const downSpace = body.height - parent.bottom - nearLimit;
    //    this.listContainer.style("max-height", "");
    //
    //    // Se colocará arriba solo si (El alto de la lista es mayor al espacio de abajo y el espacio de arriba es mayor al espacio abajo)
    //    const putInTop = (downSpace < list.height && upSpace > downSpace);
    //
    //    let top: number;
    //    let maxHeight: number;
    //    // #1 Se posiciona arriba
    //    if (putInTop) {
    //        top = nearLimit; //10px
    //        let topDif = parent.y - list.height;
    //        top = topDif > nearLimit ? topDif : top;
    //        this.listContainer
    //            .style("width", (this.config.ListWidth ? this.config.ListWidth : parent.width + "px"))
    //            .style("top", top + "px")
    //            .style("left", parent.x + "px")
    //        maxHeight = parent.y - nearLimit;
    //        let difHeight = body.height - parent.y - nearLimit;
    //        if (difHeight < 0) {
    //            maxHeight += difHeight /* - nearLimit */;
    //            this.listContainer.style("top", nearLimit + "px");
    //        }
    //        this.listContainer.style("max-height", (maxHeight < NATURALMAXHEIGHT) ? (maxHeight + "px") : "");
    //    }
    //    // # Se posiciona abajo (NATURAL)
    //    else {
    //        top = parent.bottom;
    //        this.listContainer
    //            .style("width", (this.config.ListWidth ? this.config.ListWidth : parent.width + "px"))
    //            .style("top", top + "px")
    //            .style("left", parent.x + "px")
    //        list = this.listContainer.node().getBoundingClientRect();
    //        maxHeight = body.height - list.y - nearLimit;
    //        this.listContainer.style("max-height", (maxHeight < NATURALMAXHEIGHT) ? (maxHeight + "px") : "");
    //    }
    //    let pasao = (parent.x + (parent.width > list.width ? parent.width : list.width) + nearLimit) - body.width;
    //    if (pasao > 0) {
    //        this.listContainer
    //            .style("left", (parent.x - pasao) + "px")
    //    }
    //
    //}


    private ReubicateV2(dimOverl?: DOMRectReadOnly) {
        const NATURALMAXHEIGHT = 400; // El tamaño maximo establecido es 400px
        const nearLimit = 10; // El margen con respecto a los bordes de la pantalla es 10px
        let body: DOMRect = document.body.getBoundingClientRect(); // Dimensiones del cuerpo del doc
        let parent: DOMRect = (this.config.Parent instanceof HTMLElement ? this.config.Parent : this.config.Parent.node() as HTMLElement).getBoundingClientRect(); // Dimensiones del padre del dropdown
        let list: DOMRect = this.listContainer.node().getBoundingClientRect(); // Dimensiones del dropdown
        // Obtener en que posicion hay mas espacio arriba o abajo
        const upSpace = parent.y - nearLimit; // Distancia entre el punto más alto de padre y el top del body (menos el margen preestablecido)
        const downSpace = body.height - parent.bottom - nearLimit; // Distancia entre el punto más bajo del padre y el bottom del body (menos el margen preestablecido)
        this.listContainer.style("max-height", ""); // Se resetea el max-width
        // Se colocará arriba solo si (El alto de la lista es mayor al espacio de abajo y el espacio de arriba es mayor al espacio abajo)
        const putInTop = (downSpace < list.height && upSpace > downSpace);
        let top: number;
        let maxHeight: number;
        // #1 Se posiciona arriba
        if (putInTop) {
            this.listContainer
                .style("width", (this.config.ListWidth ? this.config.ListWidth : parent.width + "px"))
                .style("bottom", (body.bottom - parent.y) + "px")
                .style("left", parent.x + "px")
                .style("top", "unset")
            maxHeight = parent.y - nearLimit;
            // CUANDO EL PADRE YA NO ES VISIBLE
            let difHeight = body.height - parent.y - nearLimit;
            if (difHeight < 0) {
                maxHeight += difHeight /* - nearLimit */;
                this.listContainer.style("bottom", nearLimit + "px");
            }
            this.listContainer.style("max-height", (maxHeight < NATURALMAXHEIGHT) ? (maxHeight + "px") : "");
            if (this.recyclerView) {
                this.RefreshListViewFinal(this.GetDataFiltered())
            }
        }
        // # Se posiciona abajo (NATURAL)
        else {
            top = parent.bottom;
            this.listContainer
                .style("width", (this.config.ListWidth ? this.config.ListWidth : parent.width + "px"))
                .style("top", top + "px")
                .style("left", parent.x + "px")
                .style("bottom", "unset")
            list = this.listContainer.node().getBoundingClientRect();
            maxHeight = body.height - list.y - nearLimit;
            this.listContainer.style("max-height", (maxHeight < NATURALMAXHEIGHT) ? (maxHeight + "px") : "");
        }

        let pasao = (parent.x + (parent.width > list.width ? parent.width : list.width) + nearLimit) - body.width;
        if (pasao > 0) {
            this.listContainer
                .style("left", (parent.x - pasao) + "px")
        }

    }

    // private Reubicate(dimOverl?: DOMRectReadOnly) {
    //     const { Parent } = this.config;
    //     const dimCont = (Parent instanceof HTMLElement ? Parent : Parent.node()).getBoundingClientRect()
    //     dimOverl = dimOverl ? dimOverl : document.body.getBoundingClientRect()
    //     const nearLimit = 10;
    //     const limitBottom = 220;
    //     const useTop = (document.body.offsetHeight - dimCont.y) >= limitBottom;

    //     this.listContainer.style("max-height", "");
    //     let top = useTop ? dimCont.y + dimCont.height : dimCont.y - this.listContainer.node().clientHeight;
    //     this.listContainer
    //         .style("width", (this.config.ListWidth ? this.config.ListWidth : dimCont.width + "px"))
    //         .style("top", top + "px")
    //         .style("left", dimCont.x + "px")

    //     let dimList = this.listContainer.node().getBoundingClientRect()
    //     if ((dimList.y + dimList.height + nearLimit) > dimOverl.height) {
    //         let newH = (dimOverl.height - dimList.y) - nearLimit
    //         this.listContainer.style("max-height", newH + "px")
    //     }

    //     let pasao = (dimCont.x + dimList.width + 10) - dimOverl.width;
    //     if (pasao > 0) {
    //         this.listContainer
    //             .style("left", (dimCont.x - pasao) + "px")
    //     }
    // }

    private isVisible(): boolean {
        return this.listContainer.node().isConnected;
    }


    // *************************************************************************
    // PRIVATE GETERS
    // *************************************************************************

    private GetSelectedControlItems(): IItemCtrlSelect<TData>[] {
        // if (INST === this) console.warn("[1.1] >>>>", this.savedCheckedValPreDataList)
        if (this.savedCheckedValPreDataList) {
            return this.savedCheckedValPreDataList.map(val => {
                const d = this.dataItems.get(val);
                const finalVal = d?.data || <TData>{
                    [this.config.ValueMember]: val,
                    [this.config.DisplayMember]: null,
                }
                return {
                    check: true,
                    data: finalVal,
                    enable: true,
                    validFilter: undefined,
                }
            })
        }
        return Array.from(this.dataItems.values())
            .filter(d => d.check);
    }

    private GetDataSelected(): TData[] {
        return this.GetSelectedControlItems()
            .map(d => d.data);
    }

    // *************************************************************************
    // PUBLIC PROPERTIES
    // *************************************************************************

    public get _Visible() {
        return this.isVisible();
    }
    public get _dataitems() {
        return Array.from(this.dataItems.values()).map(d => d.data); //this.config.Data;
    }

    public get _controlItemSelected() {
        return this.GetSelectedControlItems()[0];
    }

    public get _uniqueDataSelected() {
        return this.GetDataSelected()[0]; // this.dataSelected;
    }

    public get _uniqueVMSelected() {
        return this.GetDataSelected().map(d => d[this.config.ValueMember])[0];
    }

    public get _controlItemsSelected() {
        return this.GetSelectedControlItems();
    }

    public get _dataSelected() {
        return this.GetDataSelected(); // this.dataSelected;
    }

    public get _dataValueMemberSelected() {
        return this.GetDataSelected().map(d => d[this.config.ValueMember]);
    }

    public get _listContainer() {
        return this.listContainer;
    }

    public set _onChange(onchange: (valueMember: Array<TData[VM]>, dato: Array<TData>) => void) {
        this.config.OnChange = onchange;
    }

    public _UpdateList(datos: TDropdownData<TData>) {
        this.UpdateData(datos, true, (status) => {
            if (status == "end") {
                this.OnSelectItems();
                this.UpdateItemsListView();
            }
        })
        return this;
    }

    public _RefreshList() {
        const datos = this.config.Data;
        this.UpdateData(datos, true, (status) => {
            if (status == "end") {
                this.OnSelectItems();
                this.UpdateItemsListView();
            }
        })
        return this;
    }

    public _ResetSelect() {
        if (this.dataItems) {
            this.savedCheckedValPreDataList = null;
            this.dataItems.forEach(d => d.check = false)
            // this.dataSelected = [];
            this.OnSelectItems();
            this.UpdateItemsListView();
        }
        return this;
    }

    /** Selecciona los elementos (si existen), deselecciona los anteriores, si son diferentes
     *
     * Si Data (dataList) retorna una Promesa, los valores se guardan y evaluan hasta que la promesa se cumpla
     *
     * @param valueMember recibe el valor de la propiedad */
    public _valueSelect(valueMember: Array<TData[VM]>) {
        const searchValue = (value: TData[VM]) => {
            let dat = this.dataItems.get(value)
            if (dat) {
                dat.check = true;
            }
            // else {
            //     console.warn(this.config.ValueMember, value, " <- No encontrado en ", this.config.Data)
            // }
            return dat;
        }

        this.dataItems.forEach(d => d.check = false);
        // this.dataSelected = [];

        if (Array.isArray(valueMember)) {
            if (valueMember.length == 1 && valueMember[0] == null) {
                valueMember = []
            }
            if (this.promiseData) {
                this.savedCheckedValPreDataList = valueMember
            }
            valueMember.forEach(value => {
                searchValue(value)
            })
        }

        this.dataValuesAuxOnChange = [...this.dataItems.entries()].filter(([k, d]) => d.check).map(([k, d]) => k);

        this.OnSelectItems();
        this.UpdateItemsListView();
        return this;
    }


    public _disableItems(valuesMember: (TData[VM] | TData[VM][])) {
        let items = !Array.isArray(valuesMember) ? [valuesMember] : valuesMember;
        this.EnableItems(items, false);
        this.UpdateItemsListView();
        return this;
    }

    public _enableItems(valuesMember: TData[VM][]) {
        let items = !Array.isArray(valuesMember) ? [valuesMember] : valuesMember;
        this.EnableItems(items, true);
        this.UpdateItemsListView();
        return this;
    }

    public _UpdateItemsListView() {
        this.UpdateItemsListView();
    }

    public _Show() {
        this.Show();
        return this;
    }

    public _Hide() {
        this.Hide();
        return this;
    }

}
