import { Global } from "../../data/Global";
import { DataUtilLocalStorage } from "../../data/util/LocalStorage";
import { Fields, FormGenerator, IField } from "../controlD3/Formulario";
import { ModalThings } from "../controlD3/ModalThings";
import { UIUtilLang } from "../util/Language";
import { UIUtilScreen } from "../util/Screen";

export namespace UIUtilViewUserPreferences {
    interface IUserPreferences {
        // OffLineMode: boolean;
        ForceWindowInfoFocus: boolean;
        // SendDevAlerts: boolean;
        // FullScreen: boolean;
        FontSize: number;
    }

    interface IFormPreferences extends Pick<IUserPreferences, "ForceWindowInfoFocus" | "FontSize"> {
        OffLineMode: boolean;
        SendDevAlerts: boolean;
        FullScreen: boolean;
    }

    const Default_Preferences: IFormPreferences = {
        FontSize: 16,
        ForceWindowInfoFocus: true,
        FullScreen: Boolean(UIUtilScreen._IsFullScreen()),
        OffLineMode: false,
        SendDevAlerts: true
    }

    function GetPreferences(): IFormPreferences {
        let prefsString = DataUtilLocalStorage._GetItem("user_preferences", "template");
        let prefsObj: IUserPreferences = JSON.parse(prefsString) || <IUserPreferences>{};
        const saved = prefsObj || <IUserPreferences>{};
        return {
            ...Default_Preferences,
            ...saved
        }
    }

    export function _AplicaPreferencias() {
        const prefs = GetPreferences();

        let stylesElement = document.querySelector<HTMLStyleElement>(".style_preference");
        let styleString = "\n:root {";
        if (!stylesElement) {
            stylesElement = document.createElement("style");
            stylesElement.className = "style_preference";
            document.body.append(stylesElement);
        }
        if (prefs.FontSize) {
            styleString += `\n--fontsize:${prefs.FontSize}px;`;
        }
        styleString += "\n}";
        styleString += "\n@media screen and (max-width: 959px) {";
        styleString += "\n:root {"
        styleString += `\n--fontsize:${prefs.FontSize - 2}px;`
        styleString += "\n}"
        styleString += "\n}"
        stylesElement.textContent = styleString;
    }

    export function _OpenModal_Preferencias() {
        let lastConfig = {
            ...GetPreferences()
        };

        ModalThings._GetModalToForm({
            Title: UIUtilLang._GetUIString("app", "preferences"),
            Width: 350,
            GetForm: () => {
                let schema: IField<IFormPreferences>[] = [];
                if (Global._DEVELOPMENT_UTILS) {
                    schema.push({
                        model: "OffLineMode",
                        type: Fields.input,
                        labelText: UIUtilLang._GetUIString("preferences", "offlinemode"),
                        inputAttr: { type: "checkbox" }
                    })
                }
                schema.push({
                    model: "ForceWindowInfoFocus",
                    type: Fields.input,
                    labelText: UIUtilLang._GetUIString("preferences", "globehelper"),
                    inputAttr: { type: "checkbox" }
                })
                if (Global._DEVELOPMENT_UTILS) {
                    schema.push(
                        {
                            model: "SendDevAlerts",
                            type: Fields.input,
                            labelText: UIUtilLang._GetUIString("preferences", "senddevalerts"),
                            inputAttr: { type: "checkbox" }
                        },
                        {
                            model: "FullScreen",
                            type: Fields.input,
                            labelText: UIUtilLang._GetUIString("preferences", "fullscreen"),
                            inputAttr: { type: "checkbox" }
                        }
                    )
                }
                schema.push({
                    model: "FontSize",
                    type: Fields.input,
                    labelText: UIUtilLang._GetUIString("preferences", "fontsize"),
                    inputAttr: { type: "number", min: 10, max: 18 },
                })

                return new FormGenerator<IFormPreferences>()
                    ._Crear({
                        LabelMaxWidth: 310,
                        schema: schema,
                        BuildView: (container, controlsForm, form) => {
                            const fnAppendRow = (model: keyof IFormPreferences) => {
                                let control = controlsForm.get(model);
                                if (control) {
                                    container.append(() => control.row.node())
                                        .select(":scope > .titulos_base")
                                        .style("min-width", "80%");
                                    if (model == "FullScreen") {
                                        control.row.select<HTMLInputElement>("input")
                                            .node()
                                            .onchange = e => {
                                                let checked = control.row.select<HTMLInputElement>("input")
                                                    .node()
                                                    .checked;
                                                if (checked) {
                                                    UIUtilScreen._LaunchFullScreen();
                                                } else {
                                                    UIUtilScreen._CancelFullScreen();
                                                }
                                            }
                                    }
                                }
                            }
                            console.log(container)
                            fnAppendRow("OffLineMode");
                            fnAppendRow("ForceWindowInfoFocus");
                            fnAppendRow("SendDevAlerts");
                            fnAppendRow("FullScreen");
                            fnAppendRow("FontSize");

                            container.append("span")
                                .text(`Kidi Admin v${Global._APP_VERSION_NAME}/${Global._APP_VERSION_BUILD}`)
                                .style("font-family", "Lato-Light")
                                .style("font-size", "var(--fontsize_me4)")
                                .style("padding-top", "var(--padding3)")
                        },
                    }, lastConfig)
            },
            OnAccept: (form, mt) => {
                let data = form._Data;
                const dataToStorage: IUserPreferences = { ForceWindowInfoFocus: data.ForceWindowInfoFocus, FontSize: data.FontSize }
                DataUtilLocalStorage._SetItem("user_preferences", "template", JSON.stringify(dataToStorage));
                _AplicaPreferencias()
                mt.Modal._Ocultar();
                return null;
            }
        })
    }

    export function _GetPrefsForceWindowInfoFocus() {
        return GetPreferences().ForceWindowInfoFocus;
    }
}