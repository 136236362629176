import { ArrayV2 } from "../../util/ArrayV2";
import { DataDRequest } from "../DRequest";
import { Entidad } from "../Entidad";
import { Global } from "../Global";
import { DataUtil } from "../util/Util";

type ICicloEscolar = Entidad.ICicloEscolar;
type IEscuela = Entidad.IEscuela;

export const _DiccCicloEscolar: Map<number, ICicloEscolar> = new Map();

const REQUESTID = Entidad.CTipoRequest.CicloEscolar;

export async function _GetListCicloEscolar(kinders: Array<IEscuela>): Promise<DataDRequest.IRequestResponseListA<Entidad.ICicloEscolar>[]> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "cicloEscolar/ObtenerLista";
    return new ArrayV2()
        ._Push(...kinders)
        ._MapAwait(async ({ IdKinder }) => {
            const currentDateStr = await DataUtil._GetMaxDate(REQUESTID, IdKinder);
            const params = {
                "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
                "Modificacion": currentDateStr,
                "IdEscuela": IdKinder,
            }
            return DataDRequest._Request4List(url, "GET", params, REQUESTID, IdKinder);
        })
}

export async function _AltaCiclo(datoCE: Pick<Entidad.ICicloEscolar, "Nombre" | "FechaInicio" | "FechaFin" | "IdEscuela">): Promise<DataDRequest.IResultadoPeticion<undefined>> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "cicloEscolar/Nuevo";
    const params = {
        "Nombre": datoCE.Nombre,
        "Inicio": datoCE.FechaInicio,
        "Fin": datoCE.FechaFin,
        "IdEscuela": datoCE.IdEscuela,
        "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
        "IdUsuario": DataUtil._Usuario.IdUsuario
    }
    return DataDRequest._Request5(url, "POST", params, REQUESTID);
}

export async function _EliminarCicloEscolar(idCicloEscolar: number, idEscuela: number): Promise<DataDRequest.IResultadoPeticion<undefined>> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "cicloEscolar/Eliminar";
    const params = {
        "IdCiclo": idCicloEscolar,
        "IdEscuela": idEscuela,
        "IdUsuario": DataUtil._Usuario.IdUsuario
    }
    return DataDRequest._Request5(url, "POST", params, REQUESTID);
}

export default {
    _DiccCicloEscolar,
    _AltaCiclo,
    _EliminarCicloEscolar
}
