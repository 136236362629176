import * as d3 from "d3";
import { MainPage } from "./MainPage";
import { DataCache } from "./data/Cache";
import { Entidad } from "./data/Entidad";
import { Global } from "./data/Global";
import { DataModuloMain } from "./data/ModuloMain";
import { DataIndexedDB } from "./data/indexedDB/DB";
import { _DiccEscuela } from "./data/modulo/Escuela";
import { _SvLicenciaObtenerConfiguracionOrganizacion } from "./data/modulo/Licencia";
import { _CerrarSesion } from "./data/modulo/Sesion";
import { DataUtilAlertBot } from "./data/util/AlertBot";
import { DataUtil } from "./data/util/Util";
import { DataUtilViewInfo } from "./data/util/ViewInfo";
import { UIWindowPagesNavUtils } from "./routes/MainPageMenu";
import { TRoutePath } from "./routes/Routes";
import { _ShowLoaderMessage } from "./ui/controlD3/FullLoader";
import { NotificacionV2 } from "./ui/controlD3/NotificacionV2";
import { Template } from "./ui/controlD3/Template";
import { ChangeSesionController } from "./ui/util/ChangeSesionController";
import { UIUtilInstancePageEvalControl } from "./ui/util/InstancePageEvalControl";
import { UIUtilLang } from "./ui/util/Language";
// import { UIUtilTime } from "./ui/util/Time"; // TEMPORAL // NOTE DESCOMENTAR SECCIÓN HASTA NUEVO AVISO [MULTIPLES BLOQUES]
import { UIUtilGeneral } from "./ui/util/Util";
// TEMPORAL // NOTE DESCOMENTAR SECCIÓN HASTA NUEVO AVISO [MULTIPLES BLOQUES]
/* import { LicenciamientoBloqueoView } from "./ui/utilView/LicenciamientoBloqueoView";
import { PlanLicenciaView } from "./ui/utilView/PlanLicenciaView"; */
import { UIUtilViewUserPreferences } from "./ui/utilView/UserPreferences";
import { UIWindowManager } from "./ui/ventana/WindowManager";
import _L, { _HttpMsgV2 } from "./util/Labels";

export namespace Main {
    export let _Template: Template.TemplateControl = null;
    let sesionLoaded = false;

    export function _Iniciar() {
        window.onmessage = (e: MessageEvent) => setTimeout(() => ChangeSesionController._SaveAndEvalRequiredSesion(e.data)
            .then(res => {
                if (res) window.onmessage = null
            })
        )
        window.onerror = (message, filename, lineno, colno, error) => {
            if (error) DataUtilAlertBot._SendError(error);
        }
        window.onload = async function (e: Event) {
            // const [isValidInstance, objWinstatusInstance] = EvalPageInstance();
            UIUtilViewUserPreferences._AplicaPreferencias();
            // if (!isValidInstance) return;
            d3.select(".basic_load").remove();
            _ShowLoaderMessage(_L("general.loading"));

            await DataUtil._Init();

            await UIUtilGeneral._Init();

            UIUtilInstancePageEvalControl._StartPageInstanceEval();
            window.onhashchange = function (e: HashChangeEvent) {
                PageChangeController();
            }
            _ShowLoaderMessage(_L("app.prepara_datos"));
            await PageChangeController();
            _ShowLoaderMessage(_L("app.valida_sesion"));
            const versionValid = await ValidarVersionInfo();

            if (versionValid) {
                RemoveLoaderMessage();
            }
            else {
                _ShowLoaderMessage(_L("app.error_sesion"));
            }
        }
    }

    function RemoveLoaderMessage() {
        const body = d3.select(document.body)
        body.select(":scope>wc-loader").remove();
        body.select<HTMLLabelElement>(":scope>.wc-loader-text").remove();
    }

    async function InitSesionThings(userData: Entidad.IUsuarioSesion): Promise<boolean> {
        console.debug("SESSION LOADED 🆗");
        const resLicencia = await _VerificaVigenciaLicencia()
        if (resLicencia <= 0) {
            return false
        }
        return new Promise<boolean>(async (resolve, reject) => {
            let validPermissions = await MainPage._StartWorker();

            if (Global._LICENCIA._HasAdeudosExpirados()) {
                console.debug("Info de licenciamiento PAGO REQUERIDO", Global._LICENCIA)
                // TEMPORAL // NOTE DESCOMENTAR SECCIÓN HASTA NUEVO AVISO [MULTIPLES BLOQUES]
                /* let delayToEvalLicencias = 0
                if (_DiccEscuela.size == 0) {
                    delayToEvalLicencias = 10 * UIUtilTime.CDuration.Second // NOTE Espera consulta de escuelas si es el caso
                }
                setTimeout(() => {
                    const adeudosList = Global._LICENCIA._GetDetalleAdeudosArray()
                    const todasEscuelasConAdeudos = adeudosList.every(d => _DiccEscuela.has(d._IdEscuela))
                    if (_DiccEscuela.size == 0 || (adeudosList.length == _DiccEscuela.size && todasEscuelasConAdeudos)) {
                        new LicenciamientoBloqueoView()
                    }
                    else {
                        Array.from(_DiccEscuela.values()).forEach((escuela) => {
                            if (!escuela.EnUso) return
                            escuela.EnUso = !Global._LICENCIA._ValidarEsEscuelaExpirada(escuela.IdKinder)
                            if (!escuela.EnUso)
                                _DiccEscuela.delete(escuela.IdKinder)
                        })
                    }
                }, delayToEvalLicencias); */
            }

            if (validPermissions) {
                CreateOrUpdateTemplate();

                // >> INIT UPDATEABLE DATA: Datos locales que consultan una actualización por cada reload
                await DataUtilViewInfo._Init()
                    .then(() => {
                        _Template._RefreshWindowInfo();
                    });

                resolve(true);
            }
            else {
                NotificacionV2._Mostrar(UIUtilLang._GetUIString("usuarios", "notif_failpermissionreq"), "ADVERTENCIA");
                setTimeout(() => {
                    _Logout(true);
                    resolve(false);
                }, 10000);
            }
        })
    }

    /**
     * * ` 1`: La licencia es valida
     * * `-1`: Error
     * * `-2`: Licencia expirada
     * */
    export function _VerificaVigenciaLicencia(): Promise<number> {
        return _SvLicenciaObtenerConfiguracionOrganizacion()
            .then((res) => {
                if (res.Resultado < 0) {
                    throw new Error("?")
                }
                // TEMPORAL // NOTE DESCOMENTAR SECCIÓN HASTA NUEVO AVISO [MULTIPLES BLOQUES]
                // return new Promise<number>((resolve) => resolve(1));
                // if ((!res.Datos.VigenciaUTC && !res.Datos?.IdConfig) || (!!res.Datos.VigenciaUTC && new Date() > new Date(res.Datos.VigenciaUTC))) {
                //     console.debug("Info de licenciamiento DEMO EDO VENCIDO", res)
                //     new PlanLicenciaView(res.Datos)
                //     return -2
                // }
                console.debug("Info de licenciamiento", res)
                return 1
            })
            .catch((e) => {
                console.warn("Error al obtener la info de licenciamiento", e)
                return -1
            })
    }

    async function ValidarVersionInfo(): Promise<boolean> {
        const updatedVersionInfo = await DataUtil._GetUpdatedVersionInfo();

        if (updatedVersionInfo == null) {
            return false;
        }

        // if (updatedVersionInfo.forceMatchUIVersion) {
        //     // window.location.reload(); // FIXME emparejar versiones JSfiles -> AdminInfo
        //     return;
        // }

        // >> Verifica acciones de versión
        if (updatedVersionInfo.logout) {
            DataUtil._UpdateVersionInfo("logout", false);
            if (sesionLoaded) {
                _Logout();
                return false;
            }
        }
        return true;
    }

    function PageChangeController(hash = window.location.hash/* e: HashChangeEvent*/) {
        console.debug("-d", hash);
        hash = hash.replace("#", "");

        return new Promise<void>((resolve, reject) => {
            DataUtil._GetInfoUsuario()
                .then(async ([tblExist, userData]) => {
                    if (tblExist && !userData) {
                        DataUtilAlertBot._SendInfo("user no exist in DB", "force logout");
                        Main._Logout(true);
                        return
                    }
                    await UIUtilInstancePageEvalControl._EvalPageInstance(userData);
                    if (userData && !sesionLoaded) {
                        sesionLoaded = true;
                        await InitSesionThings(userData);
                    }

                    if (!userData && hash != "login") {
                        window.location.replace("#" + <TRoutePath>"login");
                        return;
                    }
                    else if (userData && (hash == "" || hash == "login")) {
                        window.location.replace("#" + <TRoutePath>"home");
                        return;
                    }

                    if (!UIWindowManager._OnHashControl(hash)) {
                        UIWindowManager._HistoryBackEval("home")
                        //window.location.replace("#" + <TPath>"home");
                    }
                })
                .catch((e) => {
                    console.warn("Catch main error: ", e);
                    DataUtilAlertBot._SendError(e);
                })
                .finally(() => {
                    resolve();
                })
        });
    }

    function CreateOrUpdateTemplate() {
        if (!_Template) {
            let userName = "";

            if (DataUtil._Usuario) {
                userName = (DataUtil._Usuario.Nombre + " " + DataUtil._Usuario.ApPaterno);
            }

            _Template = new Template.TemplateControl()
                ._UpdateUser(userName);
        }

        _UpdateMainMenu();
    }

    let auxCurrentUsaFinanzas: string;
    /** Actualiza los items del menu principal evaluando la propiedad "UsaFinanzas" de cada escuela,
     * y actualizando el menu si hay algun cambio */
    export function _UpdateMainMenu() {
        let usaFinanzas = DataModuloMain._GetReqDataArrayByName("Escuela", true)
            .map(d => d.UsaFinanzas)
            .join();

        if (auxCurrentUsaFinanzas != usaFinanzas) {
            auxCurrentUsaFinanzas = usaFinanzas;
            _Template._UpdateMainOptions(
                UIWindowPagesNavUtils._GetMainPageMenu()
            );
        }
    }

    // *********************************************************************
    // SESION THINGS
    // *********************************************************************

    let _logoutByVigenciaPromiseAux: Promise<number>
    export function _LogoutByVigencia() {
        if (_logoutByVigenciaPromiseAux) return
        _logoutByVigenciaPromiseAux = _VerificaVigenciaLicencia()
        _logoutByVigenciaPromiseAux
            .then((res) => {
                if (res == -2) return
                _Logout(true)
            })
            .finally(() => {
                _logoutByVigenciaPromiseAux = null
            })
    }

    export async function _Logout(forceLogout = false): Promise<boolean> {
        console.info("-d", "Init logout");
        const instanceEvalRes = await UIUtilInstancePageEvalControl._EvalPageInstance()
        if (instanceEvalRes != null && instanceEvalRes != UIUtilInstancePageEvalControl.CSesionResultStatus.VALID) {
            return true
        }
        UIUtilInstancePageEvalControl._StopPageInstanceEval(true)

        if (!forceLogout) {
            const usuario = DataUtil._Usuario;
            if (usuario?.IMEI) {
                let resSesion = await _CerrarSesion();
                if (resSesion.Resultado <= 0) {
                    NotificacionV2._Mostrar(_HttpMsgV2(resSesion), "ADVERTENCIA");
                    UIUtilInstancePageEvalControl._StartPageInstanceEval()
                    return false;
                }
            }
        }

        return new Promise(async (resolve, reject) => {
            MainPage._StopWorker();
            await DataCache._DeleteCacheStorage();

            let timeoutFail = setTimeout(() => {
                if (status == 0) {
                    DataUtilAlertBot._SendWarn("Logout fail", "Desc: IndexedDB 10s sin responder.");
                }
            }, 10000);

            let status = 0;
            await DataIndexedDB._DBDelete()
                .then((deleted) => {
                    UIUtilInstancePageEvalControl._SetLocalStorageDefaultSesionID()
                    console.info("DB deleted", deleted);
                    // window.location.replace("#login");
                    window.location.reload();
                    sesionLoaded = false;
                    status = 1;
                    resolve(true);
                })
                .catch((err) => {
                    // console.warn("Error al eliminar BD. Recargando...", err);
                    status = -1;
                    DataUtilAlertBot._SendError(err, "Logout fail to delete DB");
                    setTimeout(() => {
                        window.location.replace("/");
                        resolve(false);
                    }, 1000);
                })
                .finally(() => {
                    clearTimeout(timeoutFail);
                    //     //window.location.replace("#login");
                });
        });
    }
}