import * as d3 from "d3";
import { UIUtilGeneral } from "../util/Util";

interface IItemTab {
    ID: string;
    TabName: string;
    Disabled?: boolean;
    OnFocus?: (content: TSelectionHTML<"htmlelement">) => void;
    OnUnFocus?: (content: TSelectionHTML<"htmlelement">) => void;
    Content: TSelectionHTML<"htmlelement">;
    // GetContent?: () => TSelectionHTML<"htmlelement">;
    // UpdateContent: (content: TSelectionHTML<"htmlelement">) => void;
}
const ANIMATIONDURATION = 250;
export class Tabs {
    private items: IItemTab[];
    private containerSelection: TSelectionHTML<"div">;
    private currentItemSelected: IItemTab;

    constructor() {
        this.items = [];
        this.InitTabsControl();
    }

    private InitTabsControl() {
        this.containerSelection = d3.create("div")
            .attr("class", "tabs_container " + UIUtilGeneral.FBoxOrientation.Vertical);

        this.containerSelection.append("div")
            .attr("class", "header");

        this.containerSelection.append("div")
            .attr("class", "content_container");
    }

    private RefreshTabs() {
        this.containerSelection
            .select(":scope > .header")
            .selectAll<HTMLDivElement, IItemTab>(":scope > .tab")
            .data(this.items, d => d.ID)
            .join(
                enter => {
                    return enter.append("div")
                        .attr("id", d => d.ID)
                        .attr("class", "tab")
                        .classed(UIUtilGeneral.FBoxOrientation.Horizontal, true)
                        .classed(UIUtilGeneral.FBoxAlign.CenterCenter, true)
                        .classed("disabled", d => d.Disabled)
                        .text(d => d.TabName)
                        .on("click", (d, i, elements) => {
                            if (!d.Disabled) {
                                elements[i].scrollIntoView({
                                    behavior: "smooth",
                                })
                                this.SetCurrentContent(d, d3.select(elements[i]))
                            }
                        });
                },
                update => {
                    return update
                        .attr("id", d => d.ID)
                        .text(d => d.TabName)
                        .on("click", (d, i, elements) => {
                            if (!d.Disabled) {
                                elements[i].scrollIntoView({
                                    behavior: "smooth",
                                })
                                this.SetCurrentContent(d, d3.select(elements[i]))
                            }
                        });
                    //.each((d, i, elements) => d.UpdateContent())
                },
                exit => exit.remove(),
            );
    }

    private SetCurrentContent(newItemSelected: IItemTab, tabBtn: TSelectionHTML<"div"> = null) {
        const id = newItemSelected.ID;
        // const contentSelection = datum.Content;
        if (this.currentItemSelected?.Content.node() == newItemSelected.Content.node()) {
            return;
        }
        if (this.currentItemSelected?.OnUnFocus) {
            this.currentItemSelected.OnUnFocus(this.currentItemSelected.Content);
        }
        if (newItemSelected.OnFocus) {
            newItemSelected.OnFocus(newItemSelected.Content);
        }

        if (!tabBtn) {
            tabBtn = this.containerSelection
                .select(":scope > .header > #" + id);
        }
        const contentContainer = this.containerSelection.select<HTMLDivElement>(":scope > .content_container");
        this.containerSelection
            .select<HTMLDivElement>(":scope > .header")
            .selectAll(":scope > .tab")
            .classed("active", false);
        tabBtn.classed("active", true);

        if (this.currentItemSelected) {
            this.currentItemSelected.Content
                .style("opacity", "1")
                .transition()
                .duration(ANIMATIONDURATION)
                .style("opacity", "0")
                .remove();
        }
        this.currentItemSelected = newItemSelected;
        contentContainer.append(() => newItemSelected.Content.node());
        newItemSelected.Content
            .style("opacity", "0")
            .transition()
            .duration(ANIMATIONDURATION)
            .style("opacity", "1");
    }

    private RemoveCurrentContent() {
        if (!this.currentItemSelected) {
            return;
        }
        if (this.currentItemSelected.OnUnFocus) {
            this.currentItemSelected.OnUnFocus(this.currentItemSelected.Content);
        }
        this.currentItemSelected.Content
            .style("opacity", "1")
            .transition()
            .duration(ANIMATIONDURATION)
            .style("opacity", "0")
            .remove();
        this.containerSelection
            .select<HTMLDivElement>(":scope > .header")
            .selectAll(":scope > .tab")
            .classed("active", false);
        this.currentItemSelected = null;
    }

    private HasID(id: string) {
        const item = this.items.find(d => (d.ID == id));
        return Boolean(item);
    }

    public _SetParentNode(parent: HTMLElement) {
        parent.append(this.containerSelection.node());
        return this;
    }

    public _SetParentSel(parent: TSelectionHTML) {
        parent.append(() => this.containerSelection.node());
        return this;
    }

    public _SetTabs(items: IItemTab[]) {
        this.items = items || [];
        if (this.currentItemSelected) {
            const newItem = this.items.find(d => (this.currentItemSelected.ID == d.ID));
            if (newItem) {
                this.SetCurrentContent(newItem);
            } else {
                this.RemoveCurrentContent();
            }
        }
        this.RefreshTabs();
        return this;
    }

    public _SetEnableTab(tabID: string, enable: boolean) {
        const item = this.items.find(d => d.ID == tabID);
        if (item) {
            item.Disabled = !enable;
            this.RefreshTabs();
        }
        return this;
    }

    public _SetSelectedTab(id: string) {
        const item = this.items.find(d => (d.ID == id));
        if (item) {
            this.SetCurrentContent(item);
        }
        return this;
    }

    public _ClearSelection() {
        this.RemoveCurrentContent();
        return this;
    }

    public _HasTabID(id: string): boolean {
        return this.HasID(id);
    }

    public get _CurrentTabIDSelected() {
        return this.currentItemSelected?.ID || null;
    }

    public get _CurrentContentNode() {
        return this.currentItemSelected?.Content.node() || null;
    }

    public get _ContainerSelection() {
        return this.containerSelection;
    }

    public get _ContainerNode() {
        return this.containerSelection.node();
    }

    public get _HeaderSelection() {
        return this.containerSelection
            .select<HTMLDivElement>(":scope > .header");
    }

    public get _HeaderNode() {
        return this.containerSelection
            .select<HTMLDivElement>(":scope > .header")
            .node();
    }

    public get _ContentContainerSelection() {
        return this.containerSelection
            .select<HTMLDivElement>(":scope > .content_container");
    }

    public get _ContentContainerNode() {
        return this.containerSelection
            .select<HTMLDivElement>(":scope > .content_container")
            .node();
    }

    public get _TabsLength() {
        return this.items.length
    }
}
