import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import { getDocument as pdfjs_getDocument, GlobalWorkerOptions as pdfjs_GlobalWorkerOptions, version as pdfjs_version } from "pdfjs-dist";
import { UIUtilIconResources } from "../util/IconResourses";
import { UIUtilLang } from "../util/Language";
import { UIUtilGeneral } from "../util/Util";

export namespace PDFThings {
    export const _METADATA = {
        title: undefined,
        subject: undefined,
        author: 'Elevenminds',
        keywords: '',
        creator: 'KidiAdmin'
    }

    interface IPDFCreate {
        Title: string;
        IncDescription: string;
        Logo: string;
        DataTable: { [k in string]: string }[];
        Resumen: { [k in string]: string };
        /** @default this.Title */
        MetadataTitle?: string;
        MetadataSubject?: string;
    }

    /**
     * @returns [`jspdf.jsPDF`, `number`]:
     * @example
     * [0]: instanceof jspdf.jsPDF
     * [1]:
     * * 1 = Success
     * * -1 = Error
     * * -2 = El logo no cargó
    */
    export async function _PDFCreate(config: IPDFCreate): Promise<[(jspdf.jsPDF | null), number]> {
        try {
            return await PDFCreate(config);
        }
        catch (e) {
            console.warn(">>", e)
            return [null, -1];
        }
    }

    async function PDFCreate(config: IPDFCreate): Promise<[jspdf.jsPDF, number]> {
        const DEV_MODE = false;
        const _CONTENT = {
            TopTitle: config.Title || _METADATA.title,
            LogoURL: config.Logo,
            TopContent: config.IncDescription,
            DataTable: config.DataTable,
            Resumen: config.Resumen,
        }

        const _STYLES = {
            PaddingPercentFromWidthPage: 4,
            LogoDimPercentFromWidthPage: 18,
            FontFamily: "helvetica", // "Courier" | "Times"
            FontSize: 10,
            FontColor: "#000",
            TableHeadFill: "#93c47d",
            TableRowFill: "#fff",
            TableColLinesFill: "#dbf3d1",
            TableRowLinesFill: "#b6d7a8",
            PageFormat: "A5",
        }

        // >> INIT BUILD

        let result = 1;
        const pdf = new jsPDF({
            orientation: "portrait",
            unit: "px", // in
            format: _STYLES.PageFormat,
        });

        const PAGE_WIDTH: number = pdf.internal.pageSize.getWidth();
        const PAGE_HEIGHT: number = pdf.internal.pageSize.getHeight();
        const PAGE_PADDING = (PAGE_WIDTH / 100 * _STYLES.PaddingPercentFromWidthPage);

        const STYLES = {
            ..._STYLES,
            ...{
                Padding: PAGE_PADDING,
                Height: PAGE_HEIGHT,
                Width: PAGE_WIDTH,
                LogoWidth: PAGE_WIDTH / 100 * _STYLES.LogoDimPercentFromWidthPage,
                WidthArea: PAGE_WIDTH - PAGE_PADDING * 2,
                HeightArea: PAGE_HEIGHT - PAGE_PADDING * 2,
            }
        }

        let contentY = STYLES.Padding;

        // >> METADATA
        pdf.setProperties({
            title: config.MetadataTitle || config.Title || _METADATA.title,
            subject: config.MetadataSubject || _METADATA.subject,
            author: _METADATA.author,
            creator: _METADATA.creator,
            keywords: _METADATA.keywords,
        });

        // >> SET FONT
        pdf.setFontSize(STYLES.FontSize);
        pdf.setFont(STYLES.FontFamily, "", "normal");
        contentY += pdf.getTextDimensions("T").h;

        // >> TOP TEXT
        let textTop = _CONTENT.TopTitle;

        pdf.setFont(STYLES.FontFamily, "", "bold")
        pdf.text(textTop, STYLES.Padding, contentY);
        let textDims = pdf.getTextDimensions(textTop);

        contentY += (textDims.h * 2);

        pdf.setFont(STYLES.FontFamily, "", "normal")
        textTop = _CONTENT.TopContent;

        pdf.text(textTop, STYLES.Padding, contentY, {
            maxWidth: (STYLES.WidthArea / 3) * 2,
        });

        textDims = pdf.getTextDimensions(textTop, {
            maxWidth: (STYLES.WidthArea / 3) * 2,
        });

        contentY += textDims.h;

        // >> SET LOGO

        await UIUtilGeneral._GetIMGElementOnLoadResource(_CONTENT.LogoURL)
            .then(img => {
                pdf.addImage(img, "JPEG", (STYLES.Width - (STYLES.Padding + STYLES.LogoWidth)), STYLES.Padding, STYLES.LogoWidth, 0);
            })
            .catch(() => {
                result = -2;
            })

        // >> TABLE "datos"

        interface IH {
            Label: string,
            DataKey: string,
            AlignCell: "left" | "center" | "right"
        }
        let headers: IH[] = [];
        let row0 = _CONTENT.DataTable[0];
        for (let k in row0) {
            let alignCell: IH["AlignCell"] = "left";
            let d = row0[k];
            if (typeof d == "number") {
                alignCell = "right"
            }
            // else if (typeof d == "string" && !isNaN(Number(d.replaceAll("$", "")))) {
            else if (typeof d == "string" && d.includes("$")) { // DOTEST // FIXME
                let val = (d.replaceAll ? d.replaceAll("$", "") : d.replace("$", ""));
                val = (d.replaceAll ? d.replaceAll(",", "") : d.replace(",", ""));
                if (!isNaN(Number(val))) {
                    alignCell = "right";
                }
            }

            headers.push({
                Label: k,
                DataKey: k,
                AlignCell: alignCell,
            });
        }

        autoTable(pdf, {
            tableId: "datos",
            startY: contentY,
            styles: {
                font: STYLES.FontFamily,
            },
            margin: {
                top: STYLES.Padding,
                bottom: STYLES.Padding,
                left: STYLES.Padding,
                right: STYLES.Padding
            },
            // head: [headers],
            theme: "plain",
            columns: headers.map(d => ({
                title: d.Label,
            })),
            headStyles: {
                fillColor: STYLES.TableHeadFill,
                halign: "center",
                valign: "middle",
                fontSize: STYLES.FontSize,
                fontStyle: "bold",
            },
            bodyStyles: {
                fontSize: STYLES.FontSize,
            },
            alternateRowStyles: {
                fillColor: STYLES.TableRowFill,
                lineColor: STYLES.TableRowLinesFill,
                cellWidth: "auto",
                minCellWidth: (STYLES.WidthArea / 5 * 4) / headers.length,
            },
            // body: _CONTENT.DataTable,
            // body: _CONTENT.DataTable.map<CellInput[]>(d => { // FIXME TIPADO
            body: _CONTENT.DataTable.map(d => {
                return headers.map(h => {
                    return {
                        content: d[h.DataKey],
                        styles: {
                            halign: h.AlignCell
                        }
                    }
                })
            }),
            footStyles: {
                lineColor: STYLES.TableRowLinesFill,
                lineWidth: 1,
                cellPadding: 0,
                fontSize: 0,
            },
            foot: [[""]]
        })

        // >> TABLE "resumen"

        if (_CONTENT.Resumen) {
            interface IResum {
                Title: string,
                Content: string,
            }
            let datosResumen: IResum[] = [];

            for (let k in _CONTENT.Resumen) {
                datosResumen.push({
                    Title: k,
                    Content: _CONTENT.Resumen[k],
                })
            }

            let remoA = DEV_MODE ? contentY : null; // TESTS
            contentY = (pdf as any).lastAutoTable.finalY;

            let tblResumenAproxHeight = pdf.getTextDimensions(".").h * 2.4 * datosResumen.length;
            if (DEV_MODE) { // TESTS
                // Height: Inicio de tabla "data" hasta - Final de la tabla "resumen"
                pdf.setDrawColor(90, 100, 10);
                pdf.line(STYLES.Padding - 2, remoA, STYLES.Padding - 2, contentY + tblResumenAproxHeight);
                // Height: Inicio de tabla "data" hasta - Final del area de contenido
                pdf.setDrawColor(90, 10, 10);
                pdf.line(STYLES.Padding / 2, remoA, STYLES.Padding / 2, STYLES.Padding + STYLES.HeightArea);
            }

            if (contentY + tblResumenAproxHeight > STYLES.Padding + STYLES.HeightArea) {
                pdf.addPage()
                contentY = STYLES.Padding;
            }

            let tblResumenWidth = STYLES.WidthArea / 11 * 4;
            let tblResumenLeft = (STYLES.Width - STYLES.Padding - tblResumenWidth);

            if (DEV_MODE) { // TESTS
                // Altura de tabla "resumen"
                pdf.setDrawColor(250, 0, 0);
                pdf.line(tblResumenLeft, contentY, tblResumenLeft, contentY + tblResumenAproxHeight);
            }

            autoTable(pdf, {
                tableId: "resumen",
                startY: contentY,
                margin: {
                    top: 0,
                    bottom: 0,
                    right: STYLES.Padding,
                    left: STYLES.Width - STYLES.Padding - tblResumenWidth,
                },
                styles: {
                    font: STYLES.FontFamily,
                },
                theme: "plain",
                tableWidth: tblResumenWidth,
                alternateRowStyles: {
                    cellWidth: "auto"
                },
                bodyStyles: {
                    fontSize: STYLES.FontSize,
                },
                // body: datosResumen.map<CellInput[]>(d => { // FIXME TIPADO
                body: datosResumen.map(d => ([
                    {
                        content: d.Title + ":",
                        styles: {
                            fontStyle: "bold",
                        },
                    },
                    {
                        content: d.Content,
                        styles: { halign: "right", }
                    }
                ])),
                footStyles: {
                    lineColor: STYLES.TableRowLinesFill,
                    lineWidth: 1,
                    cellPadding: 0,
                    fontSize: 0,
                },
                foot: [[""]]
            })
        }

        var pageCount = pdf.getNumberOfPages(); // Total Page Number
        for (let i = 0; i < pageCount; i++) {
            pdf.setPage(i);
            pdf.setFontSize(STYLES.FontSize - 2);
            let pageCurrent = pdf.getCurrentPageInfo().pageNumber; // Current Page
            pdf.text(
                `${UIUtilLang._GetUIString("general", "page")}: ${pageCurrent}/${pageCount}`,
                STYLES.Padding,
                (STYLES.Height - STYLES.Padding + pdf.getTextDimensions("1").h),
            );
            if (DEV_MODE) { // TESTS
                // >> MARGIN LINES
                pdf.setLineWidth(.5);
                pdf.setDrawColor(250, 100, 50);
                pdf.line(0, STYLES.Padding, STYLES.Width, STYLES.Padding,);
                pdf.line(0, (STYLES.Height - STYLES.Padding), STYLES.Width, (STYLES.Height - STYLES.Padding));
                pdf.setDrawColor(20, 200, 50);
                pdf.line(STYLES.Padding, 0, STYLES.Padding, STYLES.Height);
                pdf.line(STYLES.Width - STYLES.Padding, 0, STYLES.Width - STYLES.Padding, STYLES.Height);
            }
        }
        return [pdf, result];
    }

    /**
     * @returns
     * `<div class="cfdi_view">`
     */
    export function _PDFViewer(pdf: jspdf.jsPDF | File, container?: HTMLDivElement, changePageFocusCallback?: (nPage: number) => void, nPageFocus: number = 1) {
        let uri: string;
        let hasManyPages = true;
        if (pdf instanceof File) {
            uri = URL.createObjectURL(pdf);
        }
        else {
            uri = pdf.output("datauristring");
            hasManyPages = pdf.getNumberOfPages() > 1;
        }
        let controlWrapper: HTMLDivElement = (() => {
            function CreateWrapper() {
                let wrapper = document.createElement("div");
                wrapper.className = "pdf_view";
                wrapper.style.display = "flex";
                wrapper.style.flexDirection = "column";
                wrapper.style.gap = "var(--padding1)";
                wrapper.style.height = "100%";
                wrapper.style.width = "100%";
                return wrapper;
            }
            if (!container) {
                return CreateWrapper();
            } else {
                let controlWrapper = container.querySelector<HTMLDivElement>(":scope > .pdf_view");
                if (!controlWrapper) {
                    controlWrapper = CreateWrapper();
                    container.append(controlWrapper);
                } else {
                    controlWrapper.querySelectorAll(":scope > *").forEach(element => element.remove());
                }
                return controlWrapper;
            }
        })();
        fnPDFViewer(controlWrapper, uri, hasManyPages, changePageFocusCallback, nPageFocus);
        return controlWrapper;
    }

    function fnPDFViewer(controlWrapper: HTMLDivElement, pdfUri: string, showPagesControls: boolean /* pdfName: string, */, changePageFocusCallback?: (nPage: number) => void, nPageFocus: number = 1) {
        // <!-- <h1>${null/*pdfName*/}</h1> -->
        let htmlTemplate = ''
        if (showPagesControls) {
            htmlTemplate += `
                    <div style="display:flex;align-items:center;gap:var(--padding1);height:20px;">
                        <img class="btn_round" id="prev" src="${UIUtilIconResources.CGeneral.AngleDown}" style="transform: rotate(90deg);height:20px;width:20px;cursor:pointer;">
                        <img class="btn_round" id="next" src="${UIUtilIconResources.CGeneral.AngleDown}" style="transform: rotate(-90deg);height:20px;width:20px;cursor:pointer;">
                        <span>${UIUtilLang._GetUIString("general", "page")}: <span id="page_num"></span> / <span id="page_count"></span></span>
                    </div>`
        }
        htmlTemplate +=
            `<div class="file_wrapper" style="height:100%;overflow:auto;display:flex;justify-content:center;padding:var(--padding1);">
                        <canvas style="min-height:400px;border:1px solid var(--color_borderbox1);" id="the-canvas"></canvas>
                    <div>`
        controlWrapper.innerHTML = htmlTemplate;
        var url = pdfUri;

        var pdfDoc = null,
            pageNum = nPageFocus,
            pageRendering = false,
            pageNumPending = null,
            scale = 1.5, //0.8,
            canvas = controlWrapper.querySelector('#the-canvas') as HTMLCanvasElement,
            ctx = canvas.getContext('2d');

        /**
         * Get page info from document, resize canvas accordingly, and render page.
         * @param num Page number.
         */
        function renderPage(num) {
            pageRendering = true;
            // Using promise to fetch the page
            pdfDoc.getPage(num).then(function (page) {
                var viewport = page.getViewport({ scale: scale });
                canvas.height = viewport.height;
                canvas.width = viewport.width;

                // Render PDF page into canvas context
                var renderContext = {
                    canvasContext: ctx,
                    viewport: viewport
                };
                var renderTask = page.render(renderContext);

                // Wait for rendering to finish
                renderTask.promise.then(function () {
                    pageRendering = false;
                    if (pageNumPending !== null) {
                        // New page rendering is pending
                        renderPage(pageNumPending);
                        pageNumPending = null;
                    }
                });
            });

            // Update page counters
            if (controlWrapper.querySelector('#page_num')) {
                controlWrapper.querySelector('#page_num').textContent = num;
            }
        }

        /**
         * If another page rendering in progress, waits until the rendering is
         * finised. Otherwise, executes rendering immediately.
         */
        function queueRenderPage(num) {
            if (pageRendering) {
                pageNumPending = num;
            } else {
                renderPage(num);
            }
        }

        /**
         * Displays previous page.
         */
        function onPrevPage() {
            if (pageNum <= 1) {
                return;
            }
            pageNum--;
            queueRenderPage(pageNum);
            if (changePageFocusCallback) {
                changePageFocusCallback(pageNum);
            }
        }
        controlWrapper.querySelector('#prev')?.addEventListener('click', onPrevPage);

        /**
         * Displays next page.
         */
        function onNextPage() {
            if (pageNum >= pdfDoc.numPages) {
                return;
            }
            pageNum++;
            queueRenderPage(pageNum);
            if (changePageFocusCallback) {
                changePageFocusCallback(pageNum);
            }
        }
        controlWrapper.querySelector('#next')?.addEventListener('click', onNextPage);

        /**
         * Asynchronously downloads PDF.
        */
        if (!pdfjs_GlobalWorkerOptions.workerSrc) {
            /** Obtenido de `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${vrs}/pdf.worker.min.mjs`;
             * const vrs = pdfjs_version; -> 4.5.136
             */
            const WORKER_URL = "/lib/pdf.worker.min.mjs"
            pdfjs_GlobalWorkerOptions.workerSrc = WORKER_URL;
        }
        pdfjs_getDocument(url).promise.then(function (pdfDoc_) {
            pdfDoc = pdfDoc_;
            if (pageNum > pdfDoc.numPages) {
                pageNum = pdfDoc.numPages;
            }
            let pageCount = controlWrapper.querySelector('#page_count');
            if (pageCount) {
                pageCount.textContent = pdfDoc.numPages;
            }

            // Initial/first page rendering
            renderPage(pageNum);
            if (changePageFocusCallback) {
                changePageFocusCallback(pageNum);
            }
        });

    }
}
