import { Global } from "../Global";
import { DataSecurity } from "../Security";
import { DataUtilAlertBot } from "../util/AlertBot";
import { DataUtil } from "../util/Util";

type ISetRowsOptions = {
    storeName: string
    listRows: unknown[]
    /** @default true */
    encrypted?: boolean
    /** @default true */
    abortAllIfFailed?: true //boolean; // "false" (PENDIENTE)
}

type IGetIndexStoreOptions = {
    db: IDBOpenDBRequest
    storeName: string
    ifContains: boolean
    txMode: IDBTransactionMode
} // & Pick<IDBTransactionOptions, "durability">

export namespace DataIndexedDB {
    const dbName: string = Global._DB_NAME;
    let flagDBEnabled: boolean = true;

    /* export function _Init(dbName: string): void {
        flagDBEnabled = true;
        dbName = dbName;
    } */

    async function decrypt(items: Array<any>) {
        let res: Array<any> = [];
        for (let item of items) {
            await DataSecurity._Decrypt(item.data).then(function (data) {
                res.push(data);
            }).catch(() => {
                console.log("Error from decrypt")
            });
        }

        return res;
    }

    function GetInstance(version?: number): IDBOpenDBRequest | null {
        if (!flagDBEnabled) {
            return null;
        }
        let indexedDB = self.indexedDB;// || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;

        let DBInstance: IDBOpenDBRequest;
        if (version)
            DBInstance = indexedDB.open(dbName, version);
        else
            DBInstance = indexedDB.open(dbName);

        return DBInstance;
    }

    function GetTxIndexStore({ db, storeName, ifContains, txMode }: IGetIndexStoreOptions): [IDBTransaction | null, IDBObjectStore | null] {
        let tx: IDBTransaction = null;
        let store: IDBObjectStore = null;

        if (!flagDBEnabled) {
            return [tx, store];
        }
        try {
            if (db.result.objectStoreNames.contains(storeName) == ifContains) {
                tx = db.result.transaction(storeName, txMode/* , {durability} */);
                store = tx.objectStore(storeName);
                // console.warn(">>", storeName, store)
            } else {
                db.result.close();
            }
        }
        catch (e) {
            console.warn("BD -> ", storeName, e);
            tx = null;
            store = null;
        }
        return [tx, store];
    }


    function GetIndexStore(db: IDBOpenDBRequest, storeName: string, ifContains: boolean, txMode: IDBTransactionMode): IDBObjectStore | null {
        const [_, store] = GetTxIndexStore({ db, storeName, ifContains, txMode });
        return store;
    }

    export async function _CreateStores(stores: Array<any>): Promise<boolean> {
        return new Promise<boolean>(function (resolve) {
            let db = GetInstance();
            if (!db) {
                console.debug("create stores fail", db, flagDBEnabled)
                resolve(false);
                return;
            }
            db.onupgradeneeded = function (ev) {

                stores.forEach(store => {
                    let storeName: string = store.storeName;
                    let key: string = store.key;
                    let columns: string[] = store.columns;

                    let dbStore = db.result.createObjectStore(storeName, { keyPath: key });

                    columns.forEach(element => {
                        let _unique: boolean = false;
                        if (element == key)
                            _unique = true;
                        dbStore.createIndex(element, element, { unique: _unique });
                    });
                });

                //db.result.close();//Causes -> error: DOMException: The connection was closed.
                //  console.info("DB init successfull");
                resolve(true);
            };

            db.onerror = function (ev) {
                console.warn("error:", ev);
                //reject(Error("Error on Error;"))
                resolve(false);
            }

            db.onblocked = function (ev) {
                console.warn("Blocked:", ev);
                db.result.close();
                resolve(false);
            }
        });
    }

    export async function _CreateObjectStore(storeName: string, key: string, columns: string[]): Promise<boolean> {
        return new Promise<boolean>(function (resolve, reject) {
            let db = GetInstance();
            if (!db) {
                resolve(false);
                return;
            }

            db.onupgradeneeded = function () {
                //VALIDAR si Esto se generaria desde aqui
                let dbStore = db.result.createObjectStore(storeName, { keyPath: key });

                columns.forEach(element => {
                    let _unique: boolean = false;
                    if (element == key)
                        _unique = true;

                    dbStore.createIndex(element, element, { unique: _unique });
                });
            }

            db.onsuccess = function () {

                if (db.result.objectStoreNames.contains(storeName)) {
                    reject(Error("ObjectStore already exists!"));
                    return;
                }

                let version: number = parseInt(db.result.version.toString()) + 1;
                let _db = GetInstance(version);

                _db.onupgradeneeded = function () {
                    let dbStore = _db.result.createObjectStore(storeName, { keyPath: key });

                    columns.forEach(element => {
                        let _unique: boolean = false;
                        if (element == key)
                            _unique = true;

                        dbStore.createIndex(element, element, { unique: _unique });
                    });

                    db.result.close();
                    resolve(true);
                }
            }
        });
    }

    export function _StoreExists(storeName: string): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            let db = GetInstance();
            if (!db) {
                reject();
                return;
            }
            db.onsuccess = function () {
                /*if (db.result.objectStoreNames.contains(storeName)) {
                    resolve(true)
                } else {
                    resolve(false)
                }*/
                //console.warn("Stores", db.result.objectStoreNames);
                const contains = db.result.objectStoreNames.contains(storeName);
                db.result.close();//prueba
                resolve(contains);
            }
            db.onerror = function (ev) {
                console.warn("error DB....");
                reject(ev);
            }
            db.onblocked = function (ev) {
                console.warn("error DB 2....");
                reject(ev);
            }
            db.onupgradeneeded = function (ev) {
                console.warn("error DB 3....");
                reject(ev);
            }
        });
    }

    export function _DBDelete(disableDB = true): Promise<boolean> {
        const delay = 500;

        return new Promise(function (resolve, reject) {
            // if (!flagDBEnabled) { // NOTE bucle?
            //     resolve(false);
            //     return;
            // }
            flagDBEnabled = !disableDB;
            setTimeout(() => {
                let iDB = self.indexedDB;//|| window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;
                let dbRequest = iDB.deleteDatabase(dbName);

                dbRequest.onsuccess = function () {
                    console.debug("DB >> Delete > Successfull");
                    resolve(true);
                }
                dbRequest.onerror = function () {
                    reject(dbRequest.error);
                }
                dbRequest.onblocked = function (ev) {
                    reject(new Error("DB >> Delete > Blocked"));
                };
                dbRequest.onupgradeneeded = function (ev) {
                    reject(new Error("DB >> Delete > Upgrade needed"));
                };
            }, delay);
        });
    }

    export function _SetRowsV2(options: ISetRowsOptions): Promise<boolean> {
        const { storeName, listRows, encrypted, abortAllIfFailed } = <ISetRowsOptions>{
            abortAllIfFailed: true,
            encrypted: true,
            ...options
        }
        const getFinalListRows = async (keyPath: string) => {
            if (!encrypted)
                return listRows;
            let listRes = [];
            for (const item of listRows) {
                try {
                    const encryptData = await DataSecurity._Encrypt(JSON.stringify(item).toString());
                    const id = item[keyPath];
                    const newItem = {
                        [keyPath]: id,
                        data: encryptData,
                    }
                    listRes.push(newItem);
                } catch (error) {
                    console.warn("DB >> Put > Fail Encrypt", item, error);
                    listRes = null;
                    DataUtilAlertBot._SendWarn("DB >> Put > Fail Encrypt", error + "");
                    break;
                }
            }
            return listRes;
        }

        return new Promise<boolean>(async (resolve, reject) => {
            const { key } = DataUtil._GetDBTableKey(storeName) || {};
            const finalList = await getFinalListRows(key);

            if (finalList == null) {
                reject(new Error("DB >> Put > make final list failed"));
                return;
            }

            const db = GetInstance();
            if (!db) {
                resolve(false);
                return;
            }

            db.onsuccess = async function (ev) {
                // console.warn(`DB >> Put > db.success ${storeName} INIT`, key, finalList, encrypted);
                const [tx, store] = GetTxIndexStore({ db, storeName, ifContains: true, txMode: "readwrite" });

                if (!tx) {
                    const err = new Error(`DB >> Put > Tx is null on ${storeName} store`);
                    DataUtilAlertBot._SendError(err);
                    reject(err)
                    return;
                }
                if (!store) {
                    const err = new Error("DB >> Put > Store not found: " + storeName);
                    DataUtilAlertBot._SendError(err);
                    reject(err);
                    return;
                }

                tx.oncomplete = (e) => {
                    // store.transaction.db.close();
                    // console.info("DB >> Put > oncomplete", storeName, key, "items:", listRows.length);
                    db.result.close();
                    resolve(true);
                }
                tx.onerror = (e) => {
                    if (!!(e.target as IDBRequest).error || !!tx.error)
                        console.warn((e.target as IDBRequest).error, tx.error);
                    reject((e.target as IDBRequest).error);
                    db.result.close();
                    DataUtilAlertBot._SendWarn("DB >> Put > Error", (e.target as IDBRequest).error?.message + `\n{${storeName}}`)
                }
                tx.onabort = function (e) {
                    if (!!(e.target as IDBTransaction).error || !!tx.error)
                        console.warn((e.target as IDBTransaction).error, tx.error)
                    reject(new Error("DB >> Put > Abort"));
                    DataUtilAlertBot._SendWarn("DB >> Put > Abort", (e.target as IDBTransaction).error?.message + `\n{${storeName}}`)
                }

                let success = true;
                for (const item of finalList) {
                    const putResult = await new Promise<boolean>((resolve) => {
                        const request = store.put(item);
                        request.onsuccess = () => resolve(true);
                        request.onerror = () => resolve(false);
                    })
                    if (!putResult) {
                        console.warn("DB >> Put > Failed to put item (Aborting...) ", item);
                        tx.abort();
                        success = false;
                        break;
                    }
                }
                if (success) {
                    tx.commit(); // tx -> complete
                }
            }

            db.onerror = function (e) {
                console.warn("DB >> Put > db.error", db.error)
                reject(db.error);
            }
        })
    }

    export async function _GetRowById<T>(storeName: string, id: any, encrypted?: boolean): Promise<T | null> {
        return new Promise(function (resolve, reject) {

            let db = GetInstance();
            if (!db) {
                resolve(null);
                return;
            }

            db.onsuccess = function (_db) {
                let store = GetIndexStore(db, storeName, true, "readonly");
                if (!store) {
                    reject(Error("ObjectStore not Found!"));
                    return;
                }

                let request = store.get(id);

                request.onsuccess = async function (val: any) {
                    if (request != undefined && request.result) {
                        let item = request.result;


                        // console.log("RowID: " + encrypted, (encrypted == false))
                        if (encrypted == false) {
                            resolve(item);
                            return;
                        }
                        console.log("Paso a encriptar..")

                        DataSecurity._Decrypt(item.data).then(function (data) {
                            resolve(data);
                        }).catch(() => {
                            reject(Error("Error from decrypt"));
                        });
                    }
                    else
                        resolve(null);
                    // reject(Error("No Data found for this ID!"));

                    db.result.close();
                };
            };
        });
    }

    export async function _GetRows<T = unknown>(storeName: string): Promise<T[]> {
        return new Promise(function (resolve, reject) {
            let db = GetInstance();
            if (!db) {
                resolve([]);
                return;
            }

            db.onsuccess = function (_db) {
                let store = GetIndexStore(db, storeName, true, "readonly");
                if (!store) {
                    reject(Error("ObjectStore '" + storeName + "' not found on " + dbName + "!"));
                    return;
                }

                let request = store.getAll();

                request.onsuccess = async function () {
                    if (request.result) {
                        let res = await decrypt(request.result);
                        resolve(res);
                    }
                    else
                        reject(Error("The ObjectStore is empty!"));

                    clearTimeout(timeout);
                    db.result.close();
                };

                request.onerror = function (ev) {
                    clearTimeout(timeout);
                    reject(Error("db - request error"));
                }

                let timeout = setTimeout(() => {
                    // console.warn("fail", db, "  -  ", store, " -  ", request)
                    db.result.close();
                    reject(Error("db - timeout on GetRows"));
                }, 2200);
            };

            db.onerror = function (ev) {
                reject(Error("db error on GetRows"));
            }

            db.onblocked = function (ev) {
                reject(Error("db error on GetRows blocked"));
            }

            // db.onupgradeneeded = function () {
            // }
        });
    }

    /* @deprecated
    export async function _SetRows(storeName: string, listRows: Array<any>, encrypted?: boolean): Promise<boolean> {
        // await data.security.fn_Ini("contraseña", "elevenminds", "untextochido");
        return new Promise(function (resolve, reject) {

            let db = GetInstance();
            if (!db) {
                resolve(false);
                return;
            }

            db.onsuccess = function (ev) {
                let store = GetIndexStore(db, storeName, true, "readwrite");

                if (!store) {
                    reject(new Error("DB >> Put > Store not found: " + storeName));
                    return;
                }
                //  console.log(JSON.stringify(store), "GetIndexStore", encrypted)
                // if (!store) {
                //     reject(Error("MIRA EL ERROR"))
                //     return;
                // }
                // let store: IDBObjectStore = null;
                // if (encrypted == false) {
                //     store = GetIndexStore(db, storeName, true, "readwrite");
                // }

                store.transaction.oncomplete = function () {
                    store.transaction.db.close();
                    resolve(true);
                }
                store.transaction.onerror = function (event) {
                    reject(store.transaction.error);
                }
                store.transaction.onabort = function (e) {
                    reject(new Error("DB >> Put > Abort"));
                }

                let fails: number[] = [];
                listRows.forEach(async (item, i) => {
                    let newItem = item;
                    if (encrypted == undefined || encrypted == true) {
                        let encryptData = await DataSecurity._Encrypt(JSON.stringify(item).toString());

                        store = GetIndexStore(db, storeName, true, "readwrite");

                        let key: string;
                        if (store) {
                            key = store.keyPath.toString();
                        }
                        else {
                            fails.push(i + 1);
                            // console.warn(storeName, ">>", item, ">", listRows.length);
                            db.result.close();
                            return;
                        }
                        // if (key == null) {
                        //     db.result.close();
                        //     reject(Error("Key not Found"));
                        //     return;
                        // }

                        let id = item[key];
                        newItem = { data: encryptData }
                        newItem[key] = id;
                    }

                    let request = store.put(newItem);

                    let strCount = `${i + 1}/${listRows.length}`;
                    request.onerror = function () {
                        console.warn("DB >> Put error", request.error, strCount);
                        store.transaction.abort();
                        //reject(request.error);
                    }

                    request.onsuccess = function () {
                        //console.info("DB >> Put Success", storeName, strCount);
                        //console.info("DB >> Put Success", newItem);
                        //resolve(true)
                    }
                    // request.onsuccess = function () {
                    //     if (storeName == "tblPermisos")
                    //         console.warn("onsuccess")

                    //     // db.result.close();
                    //     // resolve(true);
                    // };
                    // request.onerror = function (event: any) {
                    //     console.log("onerror", request["error"], request)
                    //     // db.result.close();
                    //     //resolve(false);
                    //     //reject(event);//some error when add data
                    // }
                    // request.onblock
                });

                if (fails.length) {
                    let message =
                        `Fails: ${fails}/${listRows.length}`
                        + "\nT: " + storeName
                    DataUtilAlertBot._SendWarn("store fail save", message);
                    reject(Error("store fail to get"));
                }

                // db.result.close();
                //resolve(true)
            };

            db.onerror = function (e) {
                reject(false)
            }
        });
    } */

    /* export async function _CreateStoresV2(stores: Array<Entidad.ITabla>): Promise<boolean> {
        function fnCreateStores(db: IDBOpenDBRequest, verifyExisting: boolean = false) {
            for (let store of stores) {

                if (verifyExisting && db.result.objectStoreNames.contains(store.storeName)) {
                    console.warn("ObjectStore already exists!", store.storeName);
                    continue;
                }
                let dbStore = db.result.createObjectStore(store.storeName, { keyPath: store.key });

                store.columns.forEach(element => {
                    let _unique: boolean = false;
                    if (element == store.key)
                        _unique = true;
                    dbStore.createIndex(element, element, { unique: _unique });
                });

            }
        }

        return new Promise<boolean>(function (resolve, reject) {
            let db = GetInstance();
            if (!db) {
                resolve(false);
                return;
            }
            let dbSuccessfull = false;

            db.onupgradeneeded = function (ev) {
                // console.warn("Upgradenedded", db.result.version);
                dbSuccessfull = true;
                fnCreateStores(db, false);
                resolve(true);
            };

            db.onsuccess = function (ev) {
                if (!dbSuccessfull) {
                    let upgradedVersion = parseInt(db.result.version.toString()) + 1;

                    db.result.close();
                    let db2 = GetInstance(upgradedVersion);

                    db2.onupgradeneeded = (e) => {
                        // console.warn(upgradedVersion, "Upgradenedded", db.result.version);
                        fnCreateStores(db2, true);
                        resolve(true);
                    }

                    db2.onsuccess = () => {
                        db2.result.close();
                    }

                    db2.onerror = function (ev) {
                        console.warn(upgradedVersion, "error:", ev);
                        resolve(false);
                    }

                    db2.onblocked = function (ev) {
                        console.warn(upgradedVersion, "blocked:", ev);
                        db.result.close();
                        resolve(false);
                    }
                }
                else {
                    db.result.close();
                }
            }

            db.onerror = function (ev) {
                console.warn("error:", ev);
                resolve(false);
            }

            db.onblocked = function (ev) {
                console.warn("blocked: ", ev);
                db.result.close();
                resolve(false);
            }
        });
    } */

    /* export async function _AddRows(storeName: string, listRows: Array<any>): Promise<boolean> {
        return new Promise(function (resolve, reject) {

            let db = GetInstance();
            if (!db) {
                resolve(false);
                return;
            }

            db.onsuccess = async function (_db) {
                // let store = GetIndexStore(db, storeName, true, "readwrite");
                // if (!store) {
                //     reject(Error("ObjectStore not Found!"));
                //     return;
                // }

                listRows.forEach(async item => {
                    // for (let index = 0; index < listRows.length; index++) {
                    // let item = listRows[index];

                    // // let request = store.add(item);
                    // // request.onerror = function (e: any) {
                    // //     reject(Error(e));//some error when add data
                    // //     return;
                    // // }
                    if (!flagDBEnabled) {
                        resolve(false);
                        return;
                    }

                    let encryptData = await DataSecurity._Encrypt(JSON.stringify(item).toString());
                    let store = GetIndexStore(db, storeName, true, "readwrite");

                    if (!store) {
                        resolve(false);
                        return;
                    }

                    let key = store.keyPath.toString();
                    if (key == null) {
                        db.result.close();
                        reject(Error("Key not Found"));
                        return;
                    }

                    let id = item[key];
                    let newItem: any = { data: encryptData };
                    newItem[key] = id;

                    let request = store.add(newItem);
                    request.onsuccess = () => {
                        db.result.close();
                        resolve(true);
                    };
                    request.onerror = (event: any) => {
                        console.log("result onerror..." + event.target.error.message)
                        db.result.close();
                        resolve(false);
                        reject(event.target.error);
                    }

                    // request.oncomplete = (event: any) => {
                    //     console.log("¡Los datos se han añadido con éxito!");
                    // };
                });
                // }
            };

            db.onerror = function () {
                console.log("error")
                reject(Error("Error open DB"))
            }
        });
    } */

    /*  export async function _GetFilterRows(storeName: string, filterColumn: string, value: any) {
        return new Promise(function (resolve, reject) {

            let db = GetInstance();
            if (!db) {
                resolve(null);
                return;
            }

            db.onsuccess = function (_db) {
                let store = GetIndexStore(db, storeName, true, "readonly");
                if (!store) {
                    reject(Error("ObjectStore not Found!"));
                    return;
                }

                let request = store.index(filterColumn);

                let keyRangeValue = IDBKeyRange.only(value);

                let res = new Array();
                request.openCursor(keyRangeValue, "nextunique").onsuccess = function (event: any) {
                    let cursor = event.target.result;
                    if (cursor) {
                        let item = cursor.value;
                        DataSecurity._Decrypt(item.data).then(function (data) {
                            res.push(data);
                        })
                        cursor.continue();
                    } else {
                        resolve(res);
                        db.result.close();
                    }
                };
            };
        });
     } */

    /*export async function fn_GetMaxValue(storeName: string, filterColumn: string, value: string, optionValue: string) {
        return new Promise(function (resolve, reject) {

            let db = GetInstance();
            db.onsuccess = function () {
                let store = GetIndexStore(db, storeName, true, "readonly");
                if (!store) {
                    reject(Error("ObjectStore not Found!"));
                    return;
                }

                let index = store.index(filterColumn);
                let openCursorRequest = index.openCursor(optionValue, value);
                let maxRevisionObject = {};

                openCursorRequest.onsuccess = function (event: any) {
                    if (event.target.result) {
                        maxRevisionObject = event.target.result.value; //the object with max revision
                    }

                    resolve(maxRevisionObject);
                    db.result.close();
                };
            };
        });
    }*/

    /* export async function _CountRows(storeName: string, filterColumn: string) {
        return new Promise(function (resolve, reject) {
            let db = GetInstance();
            if (!db) {
                resolve(0);
                return;
            }
            db.onsuccess = function () {
                let store = GetIndexStore(db, storeName, true, "readonly");
                if (!store) {
                    reject(Error("ObjectStore not Found!"));
                    return;
                }

                let index = store.index(filterColumn);
                let request = index.count();

                request.onsuccess = function (event: any) {
                    resolve(request.result);
                    db.result.close();
                };
            };
        });
    } */

    /* export async function _GetIndexRows(storeName: string, indexed: string) {
        return new Promise(function (resolve, reject) {
            let db = GetInstance();
            if (!db) {
                resolve([]);
                return;
            }
            db.onsuccess = function () {
                let store = GetIndexStore(db, storeName, true, "readonly");
                if (!store) {
                    reject(Error("ObjectStore not Found!"));
                    return;
                }

                let request = store.index(indexed);

                let res = new Array();
                request.openCursor().onsuccess = function (event: any) {

                    try {
                        let cursor = event.target.result;
                        if (cursor) {
                            let item = cursor.value;
                            DataSecurity._Decrypt(item.data).then(function (data) {
                                res.push(data);
                            });
                            cursor.continue();
                        } else {
                            resolve(res);
                            db.result.close();
                        }
                    } catch (err) {
                        console.log(err)
                        reject(Error("The ObjectStore is empty!"));
                        db.result.close();
                    }
                };
            };
        });
    } */

    /* export async function _DeleteRows(storeName: string, ids: any[]) {
        return new Promise(function (resolve, reject) {
            let db = GetInstance();
            if (!db) {
                resolve(false);
                return;
            }

            db.onsuccess = function (_db) {
                let store = GetIndexStore(db, storeName, true, "readwrite");
                if (!store) {
                    reject(Error("ObjectStore not Found!"));
                    return;
                }

                ids.forEach(key => {
                    store.delete(key);
                    // let del = store.delete(key);
                    // console.log(del);
                    // del.onsuccess = function (event: any) {
                    //     console.log(event);
                    //     console.log("Item removed successfully.");
                    // };

                    // del.onerror = function (event: any) {
                    //     console.log(event);
                    //     console.log("Error while removing the item.");
                    // };
                });

                resolve(true);
                db.result.close();
            }
        });
    } */


    /* export async function _DeleteObjectStore(storeName: string) {
        return new Promise(function (resolve, reject) {
            let db = GetInstance();
            if (!db) {
                resolve(false);
                return;
            }

            db.onsuccess = function () {
                if (!db.result.objectStoreNames.contains(storeName)) {
                    reject(Error("ObjectStore not Found!"));
                    return;
                }

                let version = parseInt(db.result.version.toString()) + 1;

                db.result.close();
                let db2 = GetInstance(version);

                db2.onupgradeneeded = function () {
                    db2.result.deleteObjectStore(storeName);
                }
            }
        });
    } */

    /* export function _ClearObjectStore(storeName: string) : Promise<boolean> {
        return new Promise(function (resolve, reject) {
            let db = GetInstance();
            if (!db) {
                resolve(false);
                return;
            }
            db.onsuccess = function () {
                let store = GetIndexStore(db, storeName, true, "readwrite");
                if (!store) {
                    reject(Error("ObjectStore not Found!"));
                    return;
                }

                store.clear();

                resolve(true);
                db.result.close();
            }
        });
    } */
}
