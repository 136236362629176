import { DataDRequest } from "../DRequest";
import { Entidad } from "../Entidad";
import { Global } from "../Global";

export var _PermisosDisponiblesMap = new Map<number, Entidad.IPermisoDisponible>();
export var _PermisosAccionesMap = new Map<number, Entidad.IPermisoAccion>();
export var _PermisosModulosMap = new Map<number, Entidad.IPermisoModulo>();

export interface IPermisosDisponiblesDatosResponse {
    Acciones: Entidad.IPermisoAccion[];
    Modulos: Entidad.IPermisoModulo[];
    PermisosDisponibles: Entidad.IPermisoDisponible[];
}

export async function _ObtenerPermisosDisponibles(): Promise<DataDRequest.IRequestResponseBase<IPermisosDisponiblesDatosResponse>> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "permiso/ObtenerLista";
    return DataDRequest._Request5<IPermisosDisponiblesDatosResponse>(url, "GET", {});
}

export default {
    _PermisosDisponiblesMap,
    _PermisosAccionesMap,
    _PermisosModulosMap,
    _ObtenerPermisosDisponibles
}
