import { MainPage } from "../../MainPage";
import { DataDRequest } from "../../data/DRequest";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import { _SvAlumnoActualizarInfoExtra, _SvAlumnoInfoExtraObtener } from "../../data/modulo/Alumno";
import { CardV2Collapse, TCARDV2COLL_OnEditOriginEvent } from "../controlD3/CardV2Collapse";
import { FormGenerator, IField } from "../controlD3/Formulario";
import { UIUtilLang } from "../util/Language";

import IAlumno = Entidad.IAlumno;
import IEscuelaConfigInfoExtra = Entidad.IEscuelaConfigInfoExtra;

type TFormData = { [k in string]: string };
const PREFIX_FRM_K = "p";

export class UIPanelCardAlumnosInfoExtra extends CardV2Collapse<[IAlumno]> {

    private form: FormGenerator<TFormData>;
    //private cardWrapper: d3.Selection<HTMLElement, any, any, any>;

    private alumnoInfoExtra: TFormData;
    private alumnoCurrent: IAlumno;

    private escuelaCurrentId: number;
    private escuelaCurrentInfoExtra: IEscuelaConfigInfoExtra[];

    private hasICompleteInfo: boolean;
    private timeOut: NodeJS.Timeout;

    constructor(modulo: Entidad.CModulo) {
        super("card_headertitle", modulo, "alumnopanelinfoextra");
        this.alumnoInfoExtra = {};
        this.hasICompleteInfo = false;
    }


    protected CARDCOLL_OnInitBuild(contentContainer: TSelectionHTML<"div", any, any>): void {
        this.UI_UpdateCardVisibility();
    }
    protected CARDCOLL_GetVariantToValidateUpdate(cardData_0: IAlumno): string {
        return (cardData_0?.IdChild + cardData_0?.Modificacion).toString();
    }
    protected CARDCOLL_OnUpdateData(cardData_0: IAlumno): void | Promise<void> {
        let alumnoChanged = (!this.alumnoCurrent || this.alumnoCurrent.IdChild !== cardData_0.IdChild);
        let reloadEscuelaInfoExtra = (!this.alumnoCurrent || this.alumnoCurrent.IdKinder !== cardData_0.IdKinder);
        if (!this.alumnoCurrent || this.alumnoCurrent.IdChild !== cardData_0.IdChild) this.alumnoInfoExtra = {};
        this.alumnoCurrent = cardData_0;
        this.escuelaCurrentId = cardData_0.IdKinder;
        return this.UI_UpdateCardData(reloadEscuelaInfoExtra, false, alumnoChanged); // NOTE Hacer true si es necesario
    }
    protected CARDCOLL_MostrarBody(): void {
        this.UpdateUIFromPermissions();
        this.UI_UpdateCardData();
    }
    protected CARDCOLL_OcultarBody(): void {
        //throw new Error("Method not implemented.");s
    }
    protected CARDCOLL_OnEditarCard(originEvent: TCARDV2COLL_OnEditOriginEvent): void {
        if (originEvent == "onSuccessfullSave") {
            this.UI_UpdateCardData();
        } else {
            if (originEvent !== "onInitEdit") {
                this.ResetFormData();
            }
        }
        this.UpdateModalAndFormMode();
    }
    protected CARDCOLL_OnCancelaEditarCard(originEvent: TCARDV2COLL_OnEditOriginEvent): void {
        if (originEvent == "onSuccessfullSave") {
            this.UI_UpdateCardData();
        } else if (originEvent == "onCancel") {
            this.ResetFormData();
            //if ((this.alumnoCurrent.IdChild + this.alumnoCurrent.Modificacion).toString() !== (this.cardData[0].IdChild + this.cardData[0].Modificacion)) {
            this.UI_UpdateCardData(false, false, false);
            //}
        }
        this.UpdateModalAndFormMode();
    }
    protected async CARDCOLL_GuardarCardV2(): Promise<DataDRequest.IRequestResponseA<any>> {
        if (this.form._GetIsValidForm()) {
            let idAlumno = this.cardData[0].IdChild;
            let formData = this.form._Data;
            let originData = this.form._DataOrigin;
            let totalData = { // Sobre pone los valores de frm a los originales. Para conservar los valores de campos que posiblemente se removieron de la escuela
                ...originData,
                ...formData
            }

            let finalDato: TFormData = {};
            for (let k in totalData) {
                let finalK = k.replace(PREFIX_FRM_K, "");
                finalDato[finalK] = totalData[k];
            }

            // FIXME Conflicto de interfaces: Pendientes ajustes en data
            let res: DataDRequest.IResultadoPeticion<any> = await _SvAlumnoActualizarInfoExtra(idAlumno, finalDato);
            res.Mensaje = UIUtilLang._GetHTTPMessage(res);

            return res;
        }
        return null;
    }
    protected CARDCOLL_SyncOrGetIdToDownloadData(): DataModuloMain.TipoRequestMonitorId | DataModuloMain.TipoRequestMonitorId[] | (() => Promise<any>) {
        return () => this.UI_UpdateCardData(false, true);
    }
    protected CARDCOLL_GetIdSchool(cardData_0: IAlumno): number {
        return cardData_0.IdKinder;
    }

    protected CARDCOLL_UICardHasError(error: boolean = null) {
        super.CARDCOLL_UICardHasError(!this.hasICompleteInfo, this.CARDCOLL_GetUIStringModule("notif_faltallenar"));
    }

    // **************************************************************************
    // PRIVATE METHODS
    // **************************************************************************

    private async UpdateModalAndFormMode() {
        if (!this.form) {
            return;
        }

        this.form._PreviewMode(!this.CARDCOLL_StatusCardEditando);
    }

    private ResetFormData() {
        // >> Update data form
        let datoToForm = Object.assign({}, this.alumnoInfoExtra);
        // console.debug(datoToForm);

        this.form._AsignaData(datoToForm);
        this.form._GetIsValidForm();
    }

    private BuildForm(infoExtraItems: IEscuelaConfigInfoExtra[]) {
        this.form?._Form.remove();
        let schema: IField<TFormData>[] = [];
        const TDato = Entidad.CEscuelaInfoExtraTipoDato;

        // let frmTipo: controlD3.Fields;
        //let frmTipo = Fields.input;
        let frmInputType: string;

        infoExtraItems
            .forEach((d, i) => {
                switch (d.Tipo) {
                    case TDato.Text:
                        frmInputType = "text";
                        break;
                    case TDato.Number:
                        frmInputType = "number";
                        break;
                    case TDato.Date:
                        frmInputType = "date";
                        break;
                    case TDato.Check:
                        frmInputType = "checkbox";
                        break;
                }
                let schemaItem: IField<TFormData> = {
                    model: (PREFIX_FRM_K + d.Id),
                    type: "input",
                    labelAttr: { text: d.Tags[0] },
                    inputAttr: { type: frmInputType, required: d.Requerido }
                };

                schema.push(schemaItem);
            })

        this.form = new FormGenerator<TFormData>()
            ._Crear({
                schema: schema
            }, this.alumnoInfoExtra);

        this.cardContentContainerSelection.append(() => this.form._Form.node());
    }

    private async UI_UpdateCardData(reloadEscuelaInfoExtra = false, forceRebuildForm = false, showProgress = true): Promise<void> {
        if (this.timeOut != null) {
            clearTimeout(this.timeOut);
            this.timeOut == null;
        }
        this.timeOut = setTimeout(async () => {
            // Get escuela configuración de info extra
            const IdReq = Entidad.CTipoRequest.EscuelaAlumnoInfoExtra;
            this.escuelaCurrentInfoExtra = DataModuloMain._GetReqDataArrayById(IdReq)
                .filter(d => (d.IdEscuela == this.escuelaCurrentId))
                .sort((a, b) => (a.Orden - b.Orden));
            this.UI_UpdateCardVisibility();

            if (reloadEscuelaInfoExtra) {
                if (showProgress) this.ctrlProgress.attr("oculto", false);
                await MainPage._ReloadServiceAndAwait(IdReq, this.escuelaCurrentId)
                    .then(() => {
                        forceRebuildForm = true;
                        this.escuelaCurrentInfoExtra = DataModuloMain._GetReqDataArrayById(IdReq)
                            .filter(d => (d.IdEscuela == this.escuelaCurrentId))
                            .sort((a, b) => (a.Orden - b.Orden));
                    })
                    .catch(err => {
                        this.ctrlNotification._Mostrar(UIUtilLang._GetUIString("general", "notif_fail_infoupdate"), "ADVERTENCIA");
                    });
                if (showProgress) this.ctrlProgress.attr("oculto", true);
            }
            if (!this.form || this.form["__idEscuela"] != this.escuelaCurrentId || forceRebuildForm) {
                this.BuildForm(this.escuelaCurrentInfoExtra);
                //this.UpdateModalAndFormMode();
                this.form["__idEscuela"] = this.escuelaCurrentId;
            }
            // Get alumno info extra
            if (showProgress) this.ctrlProgress.attr("oculto", false);
            let resAlumnoInfoExtra = await _SvAlumnoInfoExtraObtener(this.alumnoCurrent.IdChild);
            if (showProgress) this.ctrlProgress.attr("oculto", true);

            //this.alumnoInfoExtra = {}; // FIXME Resetear solo si se ha cambiardo de alumno
            if (resAlumnoInfoExtra.Resultado > 0) {
                for (let k in resAlumnoInfoExtra.Datos) {
                    this.alumnoInfoExtra[PREFIX_FRM_K + k] = resAlumnoInfoExtra.Datos[k];
                }
            } else {
                this.ctrlNotification._Mostrar(UIUtilLang._GetUIString("general", "notif_fail_infoupdate"), "ADVERTENCIA");
            }

            // Evalua si hay datos faltantes por llenar
            this.hasICompleteInfo = true;
            this.escuelaCurrentInfoExtra
                .forEach((d) => {
                    let k = PREFIX_FRM_K + d.Id;
                    let val = this.alumnoInfoExtra[k]
                    if (!val) {
                        this.alumnoInfoExtra[k] = "";
                        if (d.Requerido && this.hasICompleteInfo) {
                            this.hasICompleteInfo = false;
                        }
                    }
                });

            this.ResetFormData();
            this.CARDCOLL_UICardHasError();
            this.form._GetIsValidForm(); // Resaltar campos requeridos por llenar
            this.UpdateModalAndFormMode();
            this.UI_UpdateCardVisibility();
        }, 50);
    }

    private UI_UpdateCardVisibility() {
        //this.cardWrapper
        //.style("display", (this.escuelaCurrentInfoExtra?.length > 0 ? "" : "none"), "important");
    }

    // **************************************************************************
    // PERMISOS COSAS
    // **************************************************************************

    private UpdateUIFromPermissions() {
        this.btnEditarCard._d3Selection.classed("hide", !this.HasActionPermission(Entidad.CAccionPermiso.Editar));
    }

}
