import { Entidad } from "../Entidad";
import { Global } from "../Global";
import { DataDRequest } from "../DRequest";
import { DataUtil } from "../util/Util";

type IEscuelaConfigInfoExtra = Entidad.IEscuelaConfigInfoExtra;
export const _DiccEscuelaAlumnoInfoExtra = new Map<number, IEscuelaConfigInfoExtra>();

export async function _GetAlumnosInfoExtraList(escuelas: Entidad.IEscuela[]): Promise<DataDRequest.IRequestResponseListA<IEscuelaConfigInfoExtra>[]> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "escuela/ObtenerCfgAlumnoInfoExtra";
    const idRequest = Entidad.CTipoRequest.EscuelaAlumnoInfoExtra;
    let requestResults: DataDRequest.IRequestResponseListA<IEscuelaConfigInfoExtra>[] = [];

    for (let escuela of escuelas) {
        const rowID = escuela.IdKinder;
        const lastStrDate = await DataUtil._GetMaxDate(idRequest, rowID);
        const params = {
            // "IdOrganizacion": Utils.prop_Usuario.IdOrganizacion,
            "IdEscuela": rowID,
            "Modificacion": lastStrDate
        }

        let result = await DataDRequest._Request4List(url, "GET", params, idRequest, rowID);

        requestResults.push(result);
    }

    return requestResults;
}

interface IInfoExtraAgregar {
    Tags: string[];
    Tipo: Entidad.CEscuelaInfoExtraTipoDato,
    Orden: number,
    // /** json as string: "{}" */
    // ExtraConfig: string,
    Requerido: boolean
}
interface IInfoExtraEditar {
    Id: number;
    Tags: string[];
    Orden: number,
    // /** json as string: "{}" */
    // ExtraConfig: string,
    Requerido: boolean
}
export function _InfoExtraActualizar(idEscuela: number, agregar: IInfoExtraAgregar[], editar: IInfoExtraEditar[], eliminar: number[]) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "escuela/ConfigurarAlumnoInfoExtra";
    const params = {
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "IdEscuela": idEscuela,
        "AgregarEditar": [
            ...agregar,
            ...editar
        ],
        "Eliminar": eliminar
    }
    return DataDRequest._Request5(url, "POST", params, Entidad.CTipoRequest.EscuelaAlumnoInfoExtra);
}

export default {
    _DiccEscuelaAlumnoInfoExtra,
    _InfoExtraActualizar
}
