import * as d3 from "d3";
import { Entidad } from "../../data/Entidad";
import { UIUtilIconResources } from "../util/IconResourses";
import { UIUtilLang } from "../util/Language";
import { FileButton } from "./InputFile";
import { IComponent, ListGrid, THeaders } from "./ListGrid";
import { SelectV2 } from "./SelectV2";

type TColumnsInList = {
    RemoveOption: boolean;
    Icon: boolean;
    Nombre: boolean;
    Tipo: boolean;
    Size: boolean;
    Date: boolean;
    //
    TipoExpediente: Boolean;
}

type tipoArchivo = {
    /** `ej.`  Label : "Documento" */
    Label: string;
    /** `ej.` Ext : ["doc", "docx"] , **sin el punto** `.`*/
    Ext: string[]
};

export type FilterFiles = Array<tipoArchivo>;

interface IHeader extends IComponent { }
interface IBody extends IComponent { }
interface IFooter extends IComponent { };

interface IFormatoRowItemsControl extends IFormatoRowItems {
    TipoExpedienteCtrl: SelectV2<{ Id: Entidad.CTipoExpediente; Nombre: string }>
}

export interface IFormatoRowItems {
    Nombre: string;
    Extension: string;
    File: File;
    TipoExpediente: Entidad.CTipoExpediente;
}

export class FileListUploadExpedientes {
    private listViewer: ListGrid<HTMLElement>;
    private inputFile: FileButton;
    private description: d3.Selection<HTMLDivElement, any, Element, any>;
    public filterFiles: FilterFiles;

    public visibleColumns: TColumnsInList;
    public D3Selection: d3.Selection<HTMLDivElement, any, any, any>;
    public header: IHeader;
    public body: IBody;
    public footer: IFooter;

    private icon: { edit: string, quit: string }

    public filterByExtencion;
    public ListaTipoExpediente: Array<{ Id: number, Nombre: string }>;

    /** nombre-archivo, File
     * * NOTA: El nombre de archivo original es la llave del item
    */
    private files: Map<string, IFormatoRowItemsControl>;
    // /** nombre-archivo, control-Select */
    //  private ComboTipo: Map<string, controlD3.Select<{ Id: string, Nombre: string }>>;

    constructor() {
        this.visibleColumns = <TColumnsInList>{};
        this.listViewer = new ListGrid();
        this.header = <IHeader>{};
        this.body = <IBody>{};
        this.footer = <IFooter>{};
        //this.fileHandler = new FileHandler();
        this.files = new Map();

        this.filterFiles = [{ Label: "Documento", Ext: ["doc", "docx"] }];

        this.visibleColumns.Icon = true;
        this.visibleColumns.Nombre = true;
        this.visibleColumns.TipoExpediente = true;
        this.visibleColumns.Tipo = true;
        this.visibleColumns.Size = true;
        this.icon = { edit: UIUtilIconResources.CGeneral.Editar, quit: UIUtilIconResources.CGeneral.Eliminar };
    }

    get _D3Selection() { return this.D3Selection; }

    get _files(): IFormatoRowItems[] {
        return Array.from(this.files.values())
            .map(d => ({
                Nombre: d.Nombre,
                File: d.File,
                Extension: d.Extension,
                TipoExpediente: d.TipoExpedienteCtrl._dataSelected[0]?.Id
            }))

    }

    set _filter(value: FilterFiles) {
        this.filterFiles = value;
    }

    // get prop_tiposExpedientesSeleccionados(): Map<string, { Id: string; Nombre: string; }> {
    //     let expedientes = new Map();
    //     // if (this.ComboTipo) {
    //     this.ComboTipo.forEach((sel, key) => {
    //         if (sel.prop_dataSelected.length > 0) {
    //             expedientes.set(key, sel.prop_dataSelected[0])
    //         }
    //     })
    //     // }
    //     return expedientes;
    // }

    public _Build(parent?: HTMLElement) {
        this.D3Selection = parent ? d3.select(parent).append("div") : d3.create("div");
        this.D3Selection = this.D3Selection.classed("fileListViewer", true);
        this.header.d3Selection = this.D3Selection.append("header");
        this.body.d3Selection = this.D3Selection.append("div").classed("content", true) as any;
        this.footer.d3Selection = this.D3Selection.append("footer");
        this.setHeaders();

        this.setFooter();

        this.listViewer._Build(this.body.d3Selection.node());
    }

    private setHeaders() {
        //if (this.visibleColumns.Icon) this.listViewer.header.titles.push({ Label: " ", Field: "df", Width: "40px" });

        for (let title in this.visibleColumns) {
            let cabecera = <THeaders>{ Label: "", Field: "" };
            switch (title) {
                case "Icon":
                    cabecera.Width = "40px";
                    break;
                case "Nombre":
                    cabecera.Label = UIUtilLang._GetUIString("panelexpediente", "d_field_nombre");
                    break;
                case "TipoExpediente":
                    cabecera.Label = UIUtilLang._GetUIString("panelexpediente", "d_field_strtipoexpediente");
                    break;
                case "Tipo":
                    cabecera.Label = UIUtilLang._GetUIString("panelexpediente", "tag_tipo");
                    break;
                case "Size":
                    cabecera.Width = "75px";
                    cabecera.Label = UIUtilLang._GetUIString("panelexpediente", "tag_tamanio");
                    break;
            }
            //40px 1fr 1fr 1fr 60px
            if (title) this.listViewer.header.titles.push(cabecera);
        }

    }

    public _getExtencionOnFilter(): string[] {
        if (this.filterFiles)
            return this.filterFiles.reduce((anterior: string[], actual) => anterior.concat(actual.Ext), []);
        else
            return [];
    }

    private setFooter() {
        this.inputFile = new FileButton((ev) => this.addFile(ev)/* this.fileHandler.met_handleFiles(ev) */);
        this.inputFile._filePermitidos = this._getExtencionOnFilter();
        this.inputFile._Build(this.footer.d3Selection.node());
        this.description = this.footer.d3Selection.append("div");
        this.updateDescription();
    }

    addFile(fileList: FileList, reinsert = false) {
        for (let file of fileList) {
            let { extension, name } = this.PartExtensionName(file.name);
            console.log(extension, name)
            if (this.validateExtension(extension) && this.files.has(file.name) == false) {
                let newItem: IFormatoRowItemsControl = {
                    Nombre: name,
                    Extension: extension,
                    File: file,
                    TipoExpedienteCtrl: this.build_selectTipo(file.name),
                    TipoExpediente: undefined
                }
                this.files.set(file.name, newItem);

                let tipo = this.findLabelExtension(extension);
                this.listViewer._addRow(this.build_OptionRemove(file.name), this.build_labelEditable(name, file.name), newItem.TipoExpedienteCtrl._controlSelection.node(), this.build_labelType(tipo != null ? tipo : file.type), this.build_labelSize(file.size))
                this.refresh();
            } else if (this.validateExtension(extension) && this.files.has(file.name) == true && reinsert == true) {
                let tipo = this.findLabelExtension(extension);
                let selectNode = this.files.get(file.name).TipoExpedienteCtrl._controlSelection.node();
                this.listViewer._addRow(this.build_OptionRemove(file.name), this.build_labelEditable(this.files.get(file.name).Nombre, file.name), selectNode, this.build_labelType(tipo != null ? tipo : file.type), this.build_labelSize(file.size))
            }

        }
    }

    private build_OptionRemove(id: string): HTMLImageElement {
        let img = d3.create("img")
            .attr("width", "20px")
            .property("src", this.icon.quit)
            .classed("remove", true)
            .on("click", e => this.removeFile(id));
        return img.node();

    }

    private build_labelEditable(text: string, id: string): HTMLDivElement {
        let div = d3.create("div").classed("label_input", true)

        let textAnterior: string;

        let input = div.append("input")
            .property("disabled", true)
            .datum({ editable: false, id: id })
            .classed("labelEditable", true)
            .classed("text_overflow_hidde", true)
            .property("value", text)
            .on("focusout", (d) => {
                d.editable = false;

                input.property("disabled", true);
                img.style("display", "block");
                d3.selectAll(".remove")
                    .style("visible", "visible");

                if (Boolean(input.property("value").trim())) {
                    this.renameFile(d.id, input.property("value").trim());
                }
                else {
                    input.property("value", textAnterior);
                }
            });

        let img = div.append("img").attr("width", "20px").property("src", this.icon.edit).on("click", () => {
            input.property("disabled", false).datum()["editable"] == true;
            input.node().focus();
            d3.selectAll(".remove").style("visible", "hidden")
            img.style("display", "none")
            textAnterior = input.property("value");
        }).classed("rename", true);
        return div.node();
    }

    private build_selectTipo(filename: string) {
        let d: d3.Selection<HTMLElement, any, any, any> = d3.create("div") as any;
        let s = new SelectV2({
            Type: "monoselect",
            Parent: d,
            Data: this.ListaTipoExpediente,
            ValueMember: "Id",
            DisplayMember: "Nombre",
        });
        //[{ "tipo": 1, "nombre": "Inscripción" }, { "tipo": 2, nombre: "Graduación" }
        // s._Configuracion(d, this.ListaTipoExpediente, "Id", "Nombre", "Id").node();
        return s;
    }

    private build_labelType(typeName: string): HTMLSpanElement {
        return d3.create("span")
            .text(typeName)
            .classed("text_overflow_hidde", true).node();
    }

    private build_labelSize(size: number): HTMLSpanElement {
        return d3.create("span").text(this.returnFileSize(size)).classed("size", true).node();
    }

    private updateDescription() {
        let res = { size: 0, n: 0 };
        this.files.forEach((file) => { res.size += file.File.size; res.n++ });
        this.description.text(`${UIUtilLang._GetUIString("panelexpediente", "tag_elementos")}: ${res.n} (${this.returnFileSize(res.size)})`);
    }

    private refresh() {
        this.listViewer._removeAllRows();
        this.files.forEach((file, i) => this.addFile([file.File] as any, true));
        console.log("Valores: ", Array.from(this.files.values()));
        this.updateDescription();
    }

    private removeFile(id: string) {
        this.files.delete(id);
        this.refresh();
    }

    private renameFile(id: string, value: string) {
        this.files.get(id).Nombre = value;
        console.log("Renombrando original:", id, "Editado:", value);
        this.refresh();
    }
    /** Sepera el nombre y la extension */
    private PartExtensionName(fileName: string) {
        let [extension, ...name] = fileName.split('.').reverse();
        return name.length > 0 ? { extension, name: name.reverse().join(".") } : { extension: "", name: fileName }
    }

    private findLabelExtension(extension: string) {
        if (this.filterFiles) {
            for (let filtro of <FilterFiles>this.filterFiles) {
                if (filtro.Ext.indexOf(extension) >= 0)
                    return filtro.Label + "/" + extension;
            }
        }
        return null;
    }
    //valida si la extension es permitida
    private validateExtension(extension: string) {
        console.log("Validando extension", extension, "en:", this.filterFiles);
        if (Boolean(this.filterFiles) == false) return true;
        for (let filtro of <FilterFiles>this.filterFiles) {
            if (filtro.Ext.indexOf(extension) >= 0) return true;
        }
        return false;
    }

    private returnFileSize(number) {
        if (number < 1024) {
            return number + ' bytes';
        } else if (number >= 1024 && number < 1048576) {
            return (number / 1024).toFixed(1) + ' KB';
        } else if (number >= 1048576 && number < 1073741824) {
            return (number / 1048576).toFixed(1) + ' MB';
        } else
            return (number / 1073741824).toFixed(1) + " GB";

    }
}
