import * as d3 from "d3";
import { MainPage } from "../../MainPage";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import { DataUtilPermission } from "../../data/util/Permission";
import { DataUtil } from "../../data/util/Util";
import { VentanaBase } from "../controlD3/AVentanaBase";
import { Button } from "../controlD3/Button";
import { TutorialActivarAlumno } from "../controlD3/TutorialActivarAlumno";
import { HTMLLoaderElement } from "../controlWC/LoaderComponent";
import { UIUtilLang } from "../util/Language";
import { UIUtilPermission } from "../util/Permission";
import CTipoRequest = Entidad.CTipoRequest;
import CModulo = Entidad.CModulo;

enum StatusLoad {
    Success = 1, Error, Waiting
}

/** Minimo de servicios requeridos por modulo */
const RequestByModule: { [key: number]: Array<CTipoRequest> } = {
    [CModulo.Escuelas]: [CTipoRequest.Escuela, CTipoRequest.CicloEscolar],
    [CModulo.PanelCicloEscolar]: [CTipoRequest.CicloEscolar],
    [CModulo.PanelConfiguracionNiveles]: [CTipoRequest.Grado],

    [CModulo.Tutores]: [CTipoRequest.Tutor],
    [CModulo.Grupos]: [CTipoRequest.Grupo, CTipoRequest.Grado, CTipoRequest.HorarioAlumno],
    [CModulo.Logros]: [CTipoRequest.Logro, CTipoRequest.LogroCategoria, CTipoRequest.LogroAsignacion],

    [CModulo.Alumnos]: [CTipoRequest.Alumno],
    // [CModulo.PanelInfoGeneral]: [CTipoRequest.Ninio],
    [CModulo.PanelTutores]: [CTipoRequest.Tutor, CTipoRequest.TutorAsignacion],
    [CModulo.PanelCircular]: [CTipoRequest.Circular],
    [CModulo.PanelNinioHorario]: [CTipoRequest.HorarioAlumno, CTipoRequest.Grupo],
    [CModulo.PanelNinioLogros]: [CTipoRequest.Logro],
    // [CModulo.PanelExpediente]: [CTipoRequest.Expediente],
    [CModulo.PanelFinanzasCargosDescuentos]: [CTipoRequest.FinanzaCargo, CTipoRequest.CicloEscolar],
    [CModulo.PanelFinanzasEdoCuenta]: [CTipoRequest.FinanzaMetodoPago, CTipoRequest.CicloEscolar],

    [CModulo.Tutores as any]: [CTipoRequest.TutorAsignacion], // FIXME // FIXME // FIXME any
    [CModulo.Circulares]: [CTipoRequest.Circular],
    // [CModulo.Usuarios]: [CTipoRequest.Usuarios],
    [CModulo.FinanzasCargo]: [CTipoRequest.FinanzaCargo],
    [CModulo.FinanzasHorasExtras]: [CTipoRequest.FinanzaHoraExtra],
    [CModulo.FinanzasMetodosPago]: [CTipoRequest.FinanzaMetodoPago],
}

export class UIVentanaLoadServicesV2 extends VentanaBase {
    private loadControl: HTMLLoaderElement;
    private itemsToWaitLoad: { [key: number]: StatusLoad };

    constructor() {
        super(d3.select("body"), null);
        this.loadControl = this.windowContent.append<HTMLLoaderElement>("wc-loader").node();
        this.loadControl._Description = "0%";
        this.itemsToWaitLoad = {};
        this.LoadItemsToWaitLoad();
    }

    private LoadItemsToWaitLoad() {
        // Si hay permisos, minimo hay una escuela
        if (DataUtilPermission._DiccPermisos.size > 0) {
            this.itemsToWaitLoad[CTipoRequest.Escuela] = StatusLoad.Waiting;
        }

        for (let modulo in RequestByModule) {
            if (UIUtilPermission._HasModulePermission(Number(modulo))) {
                RequestByModule[modulo].forEach(tipoRequest => {
                    this.itemsToWaitLoad[tipoRequest] = StatusLoad.Waiting;
                })
            }
        }

        let itemsToConsole = {};
        for (let key in this.itemsToWaitLoad) {
            itemsToConsole[Entidad.CTipoRequest[key]] = key;
        }
        console.debug(itemsToConsole, "this.itemsToWait LoadServicesV2");
    }

    private async ReLoadFails() {
        for (let key in this.itemsToWaitLoad) {
            if (this.itemsToWaitLoad[key] == StatusLoad.Error) {
                await MainPage._ReloadServiceAndAwaitBool(Number(key));
            }
        }
    }

    private UI_Update(hasFail: boolean) {
        this.loadControl._Description = this.GetProgreso() + "%";

        if (hasFail) {
            let btnReload = this.loadControl.querySelector<HTMLElement>(".btn_reload");
            if (!btnReload) {
                btnReload = new Button(this.loadControl, UIUtilLang._GetUIString("general", "reintentar"))
                    ._d3Selection.classed("btn_reload", true)
                    .style("min-width", "90px")
                    .node();

                btnReload.onclick = async (e) => {
                    btnReload.remove()
                    this.ReLoadFails(); // FIXME
                }
            }

            Button._EnableButton(d3.select(btnReload), true);
        }
    }

    public _OnServiceEvent(eventName: DataModuloMain.TipoRequestMonitorId, reloadId: number, errorStatus: Error): void {
        super._OnServiceEvent(eventName, reloadId, errorStatus);
        // let nameReq = data.Entidades.CTipoRequest[eventName]
        // console.log(eventName, " Arrival --> " + nameReq, errorStatus)
        // if (errorStatus != null) {
        let status = this.itemsToWaitLoad[eventName]
        if (status !== undefined && reloadId == -2) {
            let eventStatus = errorStatus ? StatusLoad.Error : StatusLoad.Success;
            //if (this.controlLoading.fn_cargaCompleta(status, carga);
            this.UI_Update(eventStatus == StatusLoad.Error);
            this.itemsToWaitLoad[eventName] = eventStatus;

            if (this.GetProgreso() == 100) {
                setTimeout(this.Redirecciona, 1000);
            }
            console.debug("Actualizando vista de carga (LoadServicesV2): ", eventName, CTipoRequest[eventName], StatusLoad[eventStatus])
        } // else console.warn("No encontrado...", eventName, nameReq)
        // } else {
        //     console.log("Estado de error: ", errorStatus)
        // }
    }

    private Redirecciona() {
        window.location.replace("#home");
        if (DataUtil._Usuario.NSesiones == 1 && TutorialActivarAlumno._HasPermissionToSee()) {
            TutorialActivarAlumno._StartTutorial(true);
        }
    }

    private GetProgreso() {
        let itemsLoaded = 1;
        let totalItems = 1;
        for (let key in this.itemsToWaitLoad) {
            totalItems++;
            if (this.itemsToWaitLoad[key] == StatusLoad.Success) {
                itemsLoaded++;
            }
        }
        return Math.round(itemsLoaded / totalItems * 100);
    }
}
