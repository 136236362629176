import { DataDRequest } from "../../data/DRequest";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import DataModuloEscolaridad from "../../data/modulo/Escolaridad";
import DataModuloEscuela from "../../data/modulo/Escuela";
import { FormGenerator, IField, Fields } from "../controlD3/Formulario";
import { InputFileControl } from "../controlD3/InputFileControlV2";
import { ModalThings } from "../controlD3/ModalThings";
import { NotificacionV2 } from "../controlD3/NotificacionV2";
import { UIUtilLang } from "../util/Language";
import { UIUtilTime } from "../util/Time";
import { UIUtilViewData } from "../util/ViewData";

export namespace UIUtilViewEscuelas {
    import IEscuela = Entidad.IEscuela;

    // *********************************************************************
    // Form
    // *********************************************************************

    export interface IEscuelaForm extends Partial<Omit<IEscuela, keyof Pick<IEscuela, "EnUso" | "Modificacion">>> {
        NumInterior?: string;
        Calle?: string;
        NumExterior?: string;
        CodigoPostal?: string;
        Ciudad?: string;
        Estado?: string;
        Pais?: string;
        LatLong?: string;

        DiasOperacion?: number[];

        IsChangedLogo?: boolean;
        IsChangedLatLong?: boolean;
    }

    export enum CFormModels {
        Logo = "Logo",
        Nombre = "Nombre",
        Tamanio = "Tamanio",
        Telefono = "Telefono",
        Correo = "Correo",

        Direccion = "Direccion", // Mapa
        Calle = "Calle",
        NumeroInterior = "NumInterior",
        NumeroExterior = "NumExterior",
        CodigoPostal = "CodigoPostal",
        Ciudad = "Ciudad",
        Estado = "Estado",
        Pais = "Pais",

        Entrada = "Entrada",
        Salida = "Salida",
        DiasOperacion = "DiasOperacion",
        // TipoLicencia = "TipoLicencia"
    }

    export function _Form_GetSchemaControls(form: FormGenerator<IEscuelaForm>): IField<IEscuelaForm>[] {
        const dataTamanioEsc: UIUtilViewData.IBaseData[] = [
            { Id: 20, Name: "1 - 20" },
            { Id: 50, Name: "21 - 50" },
            { Id: 100, Name: "51 - 100" },
            { Id: 300, Name: "101 - 300" },
            { Id: 500, Name: "301 - 500" },
            { Id: 501, Name: "> 500" }
        ]

        return [
            {
                type: Fields.fotoV2,
                fotoV2Attrs: {
                    RowClase: "alinear_foto",
                    // DefaultBackgroundImage: Utils.CIconResource.User,
                    TypeValue: "File",
                    AspectRatio: "contain",
                    ControlForm: InputFileControl.ControlForm.Semicuadrado,
                    OnChange: (isImage, isFromInput) => {
                        if (isImage && isFromInput) {
                            form._DataOrigin.IsChangedLogo = true;
                        }
                    }
                },
                model: CFormModels.Logo
            },
            {
                type: Fields.input,
                inputAttr: { type: "text", inputProperties: { required: true } },
                labelAttr: { text: "frm1_name" },
                model: CFormModels.Nombre
            },
            {
                type: Fields.selectMaterial,
                labelAttr: { text: "frm1_schoolsize" },
                selectMaterialAttr: { valueMember: "Id", displayMember: "Name", },
                values: dataTamanioEsc,
                model: CFormModels.Tamanio
            },
            {
                type: Fields.input,
                inputAttr: { type: "phone2_10A", inputProperties: { required: true } },
                labelAttr: { text: "frm1_telefono" },
                model: CFormModels.Telefono
            },
            {
                type: Fields.input,
                inputAttr: { type: "email", inputProperties: { required: true } },
                labelAttr: { text: "frm1_email" },
                model: CFormModels.Correo
            },
            // Dirección **********
            {
                type: Fields.input,
                inputAttr: { type: "text", disabled: false, inputProperties: { required: true } },
                labelAttr: { text: "frm1_calle" },
                model: CFormModels.Calle
            },
            {
                type: Fields.input,
                inputAttr: { type: "text", disabled: false, inputProperties: { required: false } },
                labelAttr: { text: "frm1_numinter" },
                model: CFormModels.NumeroInterior
            },
            {
                type: Fields.input,
                inputAttr: { type: "text", disabled: false, inputProperties: { required: true } },
                labelAttr: { text: "frm1_numext" },
                model: CFormModels.NumeroExterior
            },
            {
                type: Fields.input,
                inputAttr: { type: "text", disabled: false, inputProperties: { required: true } },
                labelAttr: { text: "frm1_cp" },
                model: CFormModels.CodigoPostal
            },
            {
                type: Fields.input,
                inputAttr: { type: "text", disabled: false, inputProperties: { required: true } },
                labelAttr: { text: "frm1_city" },
                model: CFormModels.Ciudad
            },
            {
                type: Fields.input,
                inputAttr: { type: "text", disabled: false, inputProperties: { required: true } },
                labelAttr: { text: "frm1_edo" },
                model: CFormModels.Estado
            },
            {
                type: Fields.input,
                inputAttr: { type: "text", disabled: false, inputProperties: { required: true } },
                labelAttr: { text: "frm1_pais", },
                model: CFormModels.Pais
            },
            {
                type: Fields.label,
                labelAttr: { text: "frm1_mapa" },
                model: CFormModels.Direccion // MAPA
            },
            // *********************
            {
                type: Fields.label,
                labelAttr: { text: "frm1_diasopera" },
                // selectMaterialAttr: { valueMember: "Id", displayMember: "Name", ShowNSelectedInList: true, multiselect: true },
                model: CFormModels.DiasOperacion,
                // values: dataDiasOperacion
            },
            {
                type: Fields.input,
                inputAttr: { type: "time" },
                labelAttr: { text: "frm1_hrentrada" },
                model: CFormModels.Entrada
            },
            {
                type: Fields.input,
                inputAttr: { type: "time" },
                labelAttr: { text: "frm1_hrsalida" },
                model: CFormModels.Salida
            },
            // {
            //     type: ui.controlD3.Fields.selectMaterial,asd
            //     selectMaterialAttr: { valueMember: "Id", displayMember: "Name" },
            //     labelAttr: { text: "Tipo de licencia:" },
            //     values: dataTipoLicencia,
            //     model: CFormModels.TipoLicencia
            // }
        ]
    }

    // *********************************************************************
    // Others
    // *********************************************************************

    export function _GetDirectionStr(escuela: Partial<IEscuelaForm>) {
        let splitDirection = escuela.Direccion.split("\n");

        if (splitDirection.length == 8) {
            let numInterior = splitDirection[0];
            let calle = splitDirection[1];
            let numExterior = splitDirection[2];
            let codigoPostal = splitDirection[3];
            let ciudad = splitDirection[4];
            let estado = splitDirection[5];
            let pais = splitDirection[6];
            // FIX_LANGUAGE
            return `${calle}, No. exterior ${numExterior}${numInterior ? ", No. interior " + numInterior : ""}, CP. ${codigoPostal}, ${ciudad}, ${estado}, ${pais}`;
        }
        return escuela.Direccion;
    }

    export function _GetValidTimeZoneById(idEscuela: number) {

    }

    export function _GetCiclosEscolaresListSchool(idEscuela: number, ciclosActuales = true, ordenar = true) {
        let ciclos = DataModuloMain._GetReqDataArrayByName("CicloEscolar");
        ciclos = ciclos.filter(d => (d.IdEscuela == idEscuela));
        if (ordenar) {
            ciclos = ciclos.sort((a, b) => (Number(new Date(a.FechaInicio)) - Number(new Date(b.FechaInicio))));
        }
        if (ciclosActuales) {
            const ciclosFilter = ciclos.filter(d => UIUtilTime._GetValidatorDateYMD(new Date(d.FechaFin)) >= UIUtilTime._GetValidatorDateYMD(new Date()));
            ciclos = !ciclosFilter.length ? [ciclos[ciclos.length - 1]] : ciclosFilter
        }

        return ciclos;
    }

    export function _GetCurrentCicloEscolar(ciclosEscolaresList: Entidad.ICicloEscolar[]) {
        if (!ciclosEscolaresList || !ciclosEscolaresList.length) return;
        const now = new Date();
        let cicloEscFound = ciclosEscolaresList
            .find(d => new Date(d.FechaInicio) <= now && new Date(d.FechaFin) >= now);

        if (!cicloEscFound) {
            cicloEscFound = ciclosEscolaresList[0];
        }

        return cicloEscFound;
    }

    // *********************************************************************
    // Escolaridad_AsignarAlimentosACalendarioGoogle
    // *********************************************************************

    // export function _OpenModal_Escolaridad_AsignarAlimentosACalendarioGoogle(escolaridad: Entidad.IEscolaridad) {
    //     return new Promise(resolve => {
    //         ModalThings._GetConfirmacionModal({
    //             Title: UIUtilLang._GetUIString("escuelas", "frm_alim_asigncalend_header"),
    //             Message: UIUtilLang._GetUIString("escuelas", "frm_alim_asigncalend_header")
    //                 .replace("_LEVELNAME", escolaridad.Nombre),
    //             OnAccept: () => resolve(Sv_EscolaridadAlimentos_AsignarCalendarioGoogle(escolaridad)),
    //             OnClose: () => resolve(null),
    //         })
    //     });
    // }

    // async function Sv_EscolaridadAlimentos_AsignarCalendarioGoogle(escolaridad: Entidad.IEscolaridad) {
    //     let res = await DataModuloEscolaridad._AsignarCalendarioGoogle(escolaridad.Id);
    //     let message = UIUtilLang._GetHTTPMessage(res, "asignargooglecalendar");

    //     if (res.Resultado > 0) {
    //         message = message?.replace("_ESCOLARIDADNAME", escolaridad.Nombre);
    //     } else if (res.Resultado == -3) {
    //         message = message?.replace("_ESCUELA", DataModuloEscuela._DiccFullEscuelas.get(escolaridad.IdEscuela).Nombre)
    //     }
    //     NotificacionV2?._Mostrar(message, (res.Resultado > 0 ? "INFO" : "ADVERTENCIA"));
    // }

    export async function _SvUpdateFoto(dato: Partial<IEscuelaForm>, logoFile: File): Promise<DataDRequest.IResultadoPeticion<null>> {
        if (logoFile instanceof File) {
            let res = await DataModuloEscuela._ActualizarLogo(dato.IdKinder, logoFile);

            res.Mensaje = UIUtilLang._GetHTTPMessage(res, "editarlogo");

            return res;
        } else {
            // this.notificacion.fn_Mostrar(controlD3.CDefaultMessage.EscuelaLogoError, controlD3.Notificacion.CTipoNotificacion.ADVERTENCIA);
            console.warn("-d", "Update school photo: Invalid image, should be a File instance!");
            return { Resultado: -1/*  Mensaje: controlD3.CDefaultMessage.EscuelaLogoError  */ }
        }
    }
}
