import * as d3 from "d3";
import { MainPage } from "../../MainPage";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import { IGridRenderInfo } from "../controlD3/AVentanaGrid";
import { ElementWrapper } from "../controlD3/ElementWrapper";
import { SelectV2 } from "../controlD3/SelectV2";
import { Table } from "../controlD3/Tabla";
import { UIUtilLang } from "../util/Language";
import { UIUtilGeneral } from "../util/Util";
import { UIUtilViewData } from "../util/ViewData";
import { EgresoMovimientoInterfaces, UIVentanaFinanzasEgresosMovimientos } from "./FinanzasEgresosMovimientos";
import { UIUtilFormat } from "../util/Format";

type IEgresoMovimiento = EgresoMovimientoInterfaces.IEgresoMovimiento /* & {
            Monto?: number;
            MontoFmt?: string;
        };*/
// type IDetalle = EgresoMovimientoInterfaces.IDetalle;

type IProveedor = Entidad.IFinanzaProveedor;
type TPermissionID = Entidad.CModulo.FinanzasEgresosMovimientos;

export class UIVentanaFinanzasEgresosMovimientosProveedor extends UIVentanaFinanzasEgresosMovimientos {
    private comboProveedor: SelectV2<IProveedor, "Id", "multiselect">;

    constructor(content: d3.Selection<HTMLDivElement, undefined, HTMLElement, any>, modulo: TPermissionID) {
        super(content, modulo);
    }

    protected GRID_GetDataRequestID(): DataModuloMain.TipoRequestMonitorId {
        return null;// data.Entidades.CTipoRequest.EgresoMovimiento;
    }

    protected GRID_GetTableConfigBase(): IGridRenderInfo<IEgresoMovimiento> {
        const config = super.GRID_GetTableConfigBase();
        config.DefaultSort = "ProveedorNombre";
        config.IdData = "Id";
        config.MinWidth = 1300;
        return config;
    }

    protected GRID_GetTablaId(): string {
        return "FinanzasEgresos-MovimientosProveedor"
    }

    // VISTA_CARGOS
    protected GetColumnas_VistaCargos(): IGridRenderInfo<IEgresoMovimiento>["Columns"] {
        const cols: IGridRenderInfo<IEgresoMovimiento>["Columns"] = [
            { Field: "_DOC" as any, Label: "", Width: "1%", MinWidth: "30px", IsSortable: false, Icon: { Type: "file", Text: "DOC" } },
            { Field: "ProveedorNombre", Label: "proveedor", LabelLangKey: "d_field_proveedor", Width: "12%", MinWidth: "90px" },
            // { Field: "PeriodoFmt", Label: "periodo", Width: "7%", MinWidth: "60px" },
            { Field: "FechaAplicacionFmt", Label: "aplicacion", Width: "10%", MinWidth: "90px", OrderField: "FechaAplicacion" },
            { Field: "FechaVencimientoFmt", Label: "vencimiento", Width: "10%", MinWidth: "100px", OrderField: "FechaVencimiento" },
            { Field: "Folio", Label: "folio", Width: "10%", MinWidth: "75px" },
            // { Field: "TipoEgresoFmt", Label: "TipoEgreso", Width: "15%", MinWidth: "75px" },
            { Field: "Descripcion", Label: "descripcion", Width: "18%", MinWidth: "90px" },
            { Field: "CategoriaFmt", Label: "categoria", Width: "12%", MinWidth: "90px" },
            // { Field: "DescuentoFmt", Label: "Descuento", Width: "12%", MinWidth: "75px" },
            // { Field: "ImpuestosFmt", Label: "Impuestos", Width: "14%", MinWidth: "75px" },
            // { Field: "DeudaAuxFmt", Label: "deuda", Width: "10%", MinWidth: "75px", OrderField: "DeudaAux" },
            { Field: "MontoFmt" as any, Label: "Monto", Width: "10%", MinWidth: "75px", OrderField: "Monto" as any },
            { Field: "SaldoFmt", Label: "saldo", LabelLangKey: "d_field_deudaaux", Width: "10%", MinWidth: "75px", OrderField: "SaldoFinal" },
            { Field: "NombreEscuela", Label: "Escuela", Width: "10%", MinWidth: "75px" },
        ]
        cols.forEach(d => {
            if (d.LabelLangKey === undefined) {
                d.LabelLangKey = UIUtilLang._FixFieldKey(d.Field);
            }
            return d;
        })
        return cols;
    }

    protected GRID_GetFilters(): Table.IParametroFiltro<EgresoMovimientoInterfaces.IEgresoMovimiento>[] {
        const superFiltrosCuenta = super.GRID_GetFilters()
            .filter(d => (d.Field == "TipoCuentaPago" as any
                || d.Field == "BeneficiarioCuentaPago" as any
                || d.Field == "CategoriaFmt"
                || d.Field == "CuentaPago" as any));
        if (superFiltrosCuenta.length != 4) console.info("👁 Los filtros de super cambiaron 👁")
        return [
            { Field: "ProveedorNombre", LabelLangKey: "d_field_proveedor" },
            { Field: "TipoEgreso", Type: "select", Options: UIUtilViewData._GetList_EgresoTipo() },
            { Field: "FechaAplicacion", Type: "date" },
            { Field: "FechaVencimiento", Type: "date" },
            { Field: "Folio" },
            {
                Field: "Descripcion", Type: "select",
                Options: () => DataModuloMain._GetReqDataArrayByName("EgresoPredefinido")
                    .map(d => ({ Id: d.Descripcion, Name: d.Descripcion }))
                    .concat(this.ctrlTabla._data.map(d => ({ Id: d.Descripcion, Name: d.Descripcion })))
                    .filter(d => (!!d.Name))
                    .sort((a, b) => d3.ascending(a.Name.toLowerCase(), b.Name.toLowerCase())),
            },
            ...superFiltrosCuenta,
            { Field: "Monto" as any, Type: "number" },
            { Field: "SaldoFinal", Type: "number", LabelLangKey: "d_field_deudaaux" },
        ];
    }

    protected AjustaDrawGrid() {
        super.AjustaDrawGrid();
        const topFiltrosContainer = this.windowContent.select<HTMLDivElement>(".top_filtros_container");

        this.comboProveedor = new SelectV2<IProveedor, "Id", "multiselect">({
            Type: "multiselect",
            Parent: topFiltrosContainer,
            ValueMember: "Id",
            DisplayMember: "RazonSocial",
            ShowAndEnableSearchText: true,
            ListWidth: "220px",
            OnStepItemListUI: (container, dato) => {
                if (this.GridGetEscuelasSeleccionadas().length == 1)
                    container.text(dato.RazonSocial)
                else
                    container.html(`${dato.RazonSocial}<br>
                            <span style="font-size:var(--fontsize_me4);">${dato.getNombreEscuela}</span>`)
            },
            OnChange: () => this.GridUpdateData(),
            OnChangeSearchText_GetDataValueToEval: (d) => d.RazonSocial + d.getNombreEscuela,
        })

        ElementWrapper._WrapperToSelectControl(this.comboProveedor, this.VB_GetUIStringModule("d_field_proveedor") + ":")
            .style("min-width", "285px")
            .call((wrapper) => topFiltrosContainer.node().insertAdjacentElement("afterbegin", wrapper.node()))

        this["__proveedorListener"] = MainPage._AddEventListenerWorkerRequest(Entidad.CTipoRequest.Proveedor, e => {
            this.UpdateProveedorCombo();
        })
    }

    private UpdateProveedorCombo() {
        this.comboProveedor._UpdateList(async () => {
            const idsEscuelasSeleccionadas = this.GridGetEscuelasSeleccionadas().map(d => d.IdKinder);
            const proveedores = DataModuloMain._GetReqDataArrayByName("Proveedor", true)
                .filter(prov => idsEscuelasSeleccionadas.includes(prov.IdEscuela))
                .sort((a, b) => d3.ascending(a.RazonSocial, b.RazonSocial));
            return proveedores;
        });
    }

    /** @override */
    protected async GridOnKinderSelectionChange(): Promise<void> {
        // super.GridOnKinderSelectionChange();
        this.ctrlTabla._CheckAllItems(false);
        this.UpdateProveedorCombo();
        this.GridUpdateData();
    }

    /**
     * @override
     */
    protected async GridGetData(): Promise<IEgresoMovimiento[]> {
        const data = await super.GridGetData();
        data.forEach((d) => {
            d["Monto"] = d.Saldo;
            d["MontoFmt"] = UIUtilFormat._CurrencyFmt(d.Saldo);
            d.Detalles.forEach(det => {
                det["Monto"] = det.Valor;
                det["MontoFmt"] = det.ValorFmt;
            })
        })
        const idProveedores = this.comboProveedor._dataSelected.map(d => d.Id);
        return data.filter(d => idProveedores.includes(d.IdProveedor));
    }

    public _Destroy(): void {
        super._Destroy();
        MainPage._RemoveEventListenerWorkerRequest(Entidad.CTipoRequest.Proveedor, this["__proveedorListener"]);
    }
}
