import * as d3 from "d3";
import { group as d3Group } from "d3-array";
import { MainPage } from "../../MainPage";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import DataModuloLogro from "../../data/modulo/Logro";
import { IConfigGridExcelExport, IGridExtraTableConfig, IGridRenderInfo, VentanaGrid } from "../controlD3/AVentanaGrid";
import { ExcelThings } from "../controlD3/ExcelExport";
import { Fields, FormGenerator, IField } from "../controlD3/Formulario";
import { ExcelThingsV2 } from "../controlD3/InputExcelV2";
import { ModalThings } from "../controlD3/ModalThings";
import { SelectV2 } from "../controlD3/SelectV2";
import { Table } from "../controlD3/Tabla";
import { UIUtilLang } from "../util/Language";

import ILogro = Entidad.ILogro;

export class UIVentanaLogro extends VentanaGrid<ILogro> {

    constructor(content: d3.Selection<HTMLDivElement, undefined, HTMLElement, any>, modulo: Entidad.CModulo) {
        super(content, modulo);
    }

    private GetForm(action: (Entidad.CAccionPermiso.Agregar | Entidad.CAccionPermiso.Editar), datoL = <ILogro>{}, soloMasivo = false) {
        let datoToForm = Object.assign({}, datoL);
        let esAgregar = (action == Entidad.CAccionPermiso.Agregar);
        let escuelasConPermiso = this.GridGetEscuelasConPermisoDeAccion(action, datoL?.IdKinder);
        let form = new FormGenerator<ILogro>();
        let schema: IField<ILogro>[] = [
            {
                type: Fields.selectMaterial,
                labelAttr: { text: "d_field_strescuela" },
                model: "IdKinder",
                selectMaterialAttr: {
                    valueMember: "IdKinder",
                    displayMember: "Nombre",
                    required: true,
                    disabled: !esAgregar,
                    onSelect: (escuelaData: Entidad.IEscuela) => {
                        const logroCategoria = DataModuloMain._GetReqDataArrayByName("LogroCategoria", true)
                            .filter(d => (d.IdKinder == escuelaData?.IdKinder));
                        let controlCategoria = form?._ControlsData.get("IdCategoria").instance as SelectV2;
                        controlCategoria?._UpdateList(logroCategoria);
                    },
                    removeBorder: !esAgregar,
                },
                values: escuelasConPermiso
            },
            {
                type: Fields.selectMaterial,
                labelAttr: { text: "d_field_strcategoria" },
                model: "IdCategoria",
                selectMaterialAttr: {
                    valueMember: "IdCategoria",
                    displayMember: "Nombre",
                    required: true,
                    disabled: !esAgregar,
                    removeBorder: !esAgregar
                },
                values: []
            }
        ];

        if (!soloMasivo) {
            schema.push(
                {
                    type: Fields.input,
                    inputAttr: { type: "text", required: true, disabled: !esAgregar },
                    labelAttr: { text: "d_field_nombre" },
                    model: "Nombre"
                },
                {
                    type: Fields.radioList,
                    labelAttr: { text: "d_field_strtipoproceso" },
                    model: "Proceso",
                    radioListAttr: {
                        ValueMember: "valor",
                        DisplayMember: "titulo",
                        required: true,
                        ReturnOnlyValueMember: true,
                        Direction: "vertical",
                        Data: [
                            { titulo: this.VB_GetUIStringModule("frm1_tipoprocc_complet"), valor: 1 },
                            { titulo: this.VB_GetUIStringModule("frm1_tipoprocc_part"), valor: 2 }
                        ],
                    }
                }
            )
        }

        form._Crear({
            schema: schema,
            LabelMaxWidth: 120,
            LangModuleKeyInContext: this.labelsKeyBase,
            BuildView: (container, controlsForm, form) => {
                let fnAppendRowModel = (model: keyof ILogro) => {
                    if (controlsForm.has(model)) {
                        container.append(() => controlsForm.get(model).row.node());
                    }
                }
                fnAppendRowModel("Nombre");
                fnAppendRowModel("IdKinder");
                fnAppendRowModel("IdCategoria");
                fnAppendRowModel("Proceso");
            }
        }, datoToForm);

        return form;
    }

    protected GRID_GetMenuTopGrid(): Array<Table.ITableMenuTopDefaultOptionConfig> {
        if (this.GridHasPermisoAccion(Entidad.CAccionPermiso.Agregar)) {
            return [
                {
                    Label: "Agregar", Callback: () => {
                        this.OpenModal_FormularioAgregar();
                    }
                },
                {
                    Label: "action_cargamasiva", Callback: () => {
                        this.OpenModal_CargaMasiva();
                    }
                }
            ]
        } else {
            return [];
        }
    }

    protected GRID_GetSelectionDataMenuV2(menuLocation: "row" | "top-selected", dataGridSelected: ILogro[]): Table.ITableMenuDataSelectedOptionConfig<ILogro>[] {
        let acciones: Array<Table.ITableMenuDataSelectedOptionConfig<ILogro>> = [];
        if (this.GridHasPermisoAccion(Entidad.CAccionPermiso.Editar)) {
            acciones.push({
                Label: "Editar", MultiData: false,
                Callback: (datos: Array<ILogro>) => {
                    this.OpenModal_FormularioEditar(datos[0]);
                }
            })
        }
        if (this.GridHasPermisoAccion(Entidad.CAccionPermiso.Eliminar)) {
            acciones.push({
                Label: "Eliminar",
                Callback: (datos: Array<ILogro>) => {
                    this.OpenModal_EliminarDatos(datos);
                }
            })
        }
        return acciones;
    }

    protected GRID_GetFilters(): Array<Table.IParametroFiltro<ILogro>> {
        return [
            { Label: "Nombre", Field: "Nombre" },
            { Label: "Categoria", Field: "StrCategoria" },
            { Label: "Tipo", Field: "StrTipoProceso" },
        ]
    }

    protected GRID_GetTableConfigBase(): IGridRenderInfo<ILogro> {
        return {
            IdTabla: "Logros",
            Title: "",
            DefaultSort: "Nombre",
            IdData: "IdLogro",
            MinWidth: 100,
            Columns: [
                { Field: "Nombre", Label: "Nombre", Width: "25%", MinWidth: "75px" },
                { Field: "StrCategoria", Label: "Categoria", Width: "25%", MinWidth: "85px" },
                { Field: "StrTipoProceso", Label: "Tipo", Width: "25%", MinWidth: "75px" },
                { Field: "StrEscuela", Label: "Escuela", Width: "25%", MinWidth: "75px" }
            ]
        }
    }

    protected GRID_GetTableConfigAdvanced(): IGridExtraTableConfig<ILogro> {
        return null;
    }

    protected GRID_GetDataRequestID(): DataModuloMain.TipoRequestMonitorId {
        return Entidad.CTipoRequest.Logro;
    }

    // public met_OnServiceEvent(eventName: data.Entidades.CTipoRequest): void {
    //     super.met_OnServiceEvent(eventName);
    //     switch (eventName) {
    //         case data.Entidades.CTipoRequest.LogroCategoria:
    //             this.GetLogrosCategorias();
    //             break;
    //     }
    // }

    private OpenModal_FormularioAgregar() {
        this.GridOpenModal_ActionFormToAGridData({
            Action: Entidad.CAccionPermiso.Agregar,
            GetForm: () => this.GetForm(Entidad.CAccionPermiso.Agregar),
            OnAccept: (form, modalThings) => {
                let formData = form._Data;
                return DataModuloLogro._AltaLogro(formData.IdKinder, formData.IdCategoria, formData.Nombre, formData.Proceso);
            }
        })
    }

    private OpenModal_FormularioEditar(datos: ILogro) {
        this.GridOpenModal_ActionFormToAGridData({
            Action: Entidad.CAccionPermiso.Editar,
            IdsEscuelas: [datos.IdKinder],
            GetForm: () => this.GetForm(Entidad.CAccionPermiso.Editar, datos),
            OnAccept: (form, modalThings) => {
                let dataForm = form._Data
                return DataModuloLogro._ActualizarLogro(dataForm.IdLogro, dataForm.Proceso);
            }
        })
    }

    private OpenModal_EliminarDatos(datos: Array<ILogro>) {
        this.GridOpenModal_ProccessArrayData({
            DataToProccess: datos,
            OnGetIdEscuela: (logro) => logro.IdKinder,
            OnError_GetItemDataTag: (logro) => logro.Nombre,
            OnStepAProccess: (logro) => DataModuloLogro._EliminarLogro(logro.IdLogro)
        })
    }

    private OpenModal_CargaMasiva() {
        let formRegistrar: FormGenerator<ILogro>;
        let timeout: NodeJS.Timeout = null;
        const fnReloadGridData = (idEscuela: number) => {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null
            }
            timeout = setTimeout(() => {
                MainPage._ReloadService(this.GRID_GetDataRequestID(), idEscuela);
            }, 4000);
        }

        ExcelThingsV2._UploadExcelData<ILogro>({
            ValidateUniqueItems: true,
            ModalWidth: 800,
            TableMinWidth: 600,
            TableId: "Logros-CargaMasiva",
            Columns: [
                { Field: "Nombre", Label: this.VB_GetUIStringModule("d_field_nombre"), Required: true, UIMinWidthTH: "150px" },
                {
                    Field: "Proceso",
                    Label: this.VB_GetUIStringModule("d_field_strtipoproceso"),
                    Required: true,
                    UIMinWidthTH: "150px",
                    RequiredValueType: "number",
                    ValueFormat: "excel_origin",
                    OnGetFixedValueMapped: (adaptedValue, cellVal) => {
                        if (cellVal == "Completo") {
                            return Entidad.CLogroTipoProceso.Completo;
                        } else if (cellVal == "Parcial") {
                            return Entidad.CLogroTipoProceso.Parcial;
                        } else if (cellVal) {
                            return -1 as any;
                        }
                        return null;
                    },
                    OnGetErrorType: (value) => {
                        return {
                            Valid: (value != -1 as any),
                            ErrorType: "_strfmt"
                        }
                    },
                    OnGetErrorMessage: (value, errorType) => {
                        if (errorType == "_strfmt") {
                            return [
                                UIUtilLang._GetUIString("crgamasiva", "tag_incorrcttipo"),
                                "'Completo' o 'Parcial'"
                            ]
                        }
                        return "";
                    }
                },
            ],
            OnGetTemplateFile: () => DataModuloLogro._DownloadExcelTemplate(),
            ConfigActionUploadSelection: {
                TypeUpload: "oneByOne",
                OnCallBeforeToProccess: () => {
                    return new Promise<boolean>((resolve, reject) => {
                        ModalThings._GetModalToForm({
                            Title: UIUtilLang._GetUIString("crgamasiva", "tag_assignregts"),
                            Width: 400,
                            GetForm: () => {
                                formRegistrar = this.GetForm(Entidad.CAccionPermiso.Agregar, <ILogro>{}, true);
                                return formRegistrar;
                            },
                            OnClose: () => resolve(false),
                            OnAccept: (form, mt) => {
                                resolve(true);
                                mt.Modal._Ocultar();
                                return null;
                            }
                        })
                    });
                },
                OnCallService: async (dato) => {
                    let datosGen = formRegistrar._Data;
                    let res = await DataModuloLogro._AltaLogro(datosGen.IdKinder, datosGen.IdCategoria, dato.Nombre, dato.Proceso);

                    if (res.Resultado > 0) {
                        fnReloadGridData(datosGen.IdKinder);
                    }
                    return res;
                },
                OnCallItemErrorTag: (dato) => dato.Nombre
            }
        });
    }

    protected GRID_GetExportarConfig(dataGrid: ILogro[]): IConfigGridExcelExport<ILogro> {
        return {
            IdsEscuelas: [...new Set(dataGrid.map(d => d.IdKinder))],
            OnGetDataBySheets: async () => {
                return Array.from(d3Group(dataGrid, d => d.IdKinder))
                    .map<ExcelThings.ISheetConfig<ILogro>>(entrie => ({
                        IdSheet: entrie[0], // IdEscuela
                        SheetName: entrie[1][0].StrEscuela,
                        Data: entrie[1]
                    }))
            },
            OnGetEscuelasTagInSheet: (dataSheet) => dataSheet[0].StrEscuela,
        }
    }

    // ************************************************************************
    // Data & Services
    // ************************************************************************
}
