import httplabels from '../cfg/string/httpv2-es.json';
import menulabels from '../cfg/string/menu-es.json';
import labels from '../cfg/string/ui-es.json';
import { DataDRequest } from '../data/DRequest';

// ****************************************************************
// LABELS
// ****************************************************************

const LABELSEPARATOR = ".";
const LABELTAGS: { [k in TLabelTag]: string } = Object.keys(labels).reduce((res, g) => {
    for (let l in labels[g])
        res[g + LABELSEPARATOR + l] = labels[g][l];
    return res;
}, {}) as any;

type TLabels = typeof labels;
type TLabelGroup = keyof TLabels & string;
type TLabelGroupTags<G extends TLabelGroup> = keyof TLabels[G] & string;
export type TLabelTag = {
    [G in TLabelGroup]: {
        [K in TLabelGroupTags<G>]: `${G}${typeof LABELSEPARATOR}${K}`
    }[TLabelGroupTags<G>]
}[TLabelGroup];

/**
 * @param tag
 * @param args reemplazan a "__" en la cadena
 * @returns `string`
 */
export function _Label(tag: TLabelTag, ...args: any[]) {
    let lbl = LABELTAGS[tag];
    if (lbl === undefined) {
        return `{${tag}}`;
    }
    if (args?.length) for (let arg of args) {
        lbl = lbl.replace("__", arg);
    }
    return lbl + "";
}

// ****************************************************************
// HTTP LABELS
// ****************************************************************

const HTTPSEPARATOR = "/";
const HTTPTAGS: { [k in (THTTPSrvTag | THTTPDefaultsGroup | THTTPActionsTags)]: string } = Object.keys(httplabels).reduce((res, g) => {
    if (g == <THTTPDefaultsGroup>"default")
        res[g] = httplabels[g];
    else for (let l in httplabels[g]) {
        if (g == <THTTPActionsGroup>"default.action")
            res[g + LABELSEPARATOR + l] = httplabels[g][l];
        else
            res[g + HTTPSEPARATOR + l] = httplabels[g][l];
    }
    return res;
}, {}) as any;

type THTTPLabels = typeof httplabels;
type THTTPDefaultsGroup = keyof Pick<THTTPLabels, "default">;
type THTTPActionsGroup = keyof Pick<THTTPLabels, "default.action">;
type THTTPNOSERVICESGroups = THTTPDefaultsGroup | THTTPActionsGroup;
type THTTPSrvGroups = keyof Omit<THTTPLabels, THTTPNOSERVICESGroups> & string;
type THTTPLabelsGroupTags<G extends keyof THTTPLabels> = keyof THTTPLabels[G] & string;

type THTTPSrvTag = {
    [G in THTTPSrvGroups]: {
        [K in THTTPLabelsGroupTags<G>]: `${G}${typeof HTTPSEPARATOR}${K}`
    }[THTTPLabelsGroupTags<G>]
}[THTTPSrvGroups];

type THTTPActionsTags = {
    [G in THTTPActionsGroup]: {
        [K in THTTPLabelsGroupTags<G>]: `${G}${typeof LABELSEPARATOR}${K}`
    }[THTTPLabelsGroupTags<G>]
}[THTTPActionsGroup];

export const RESCODES_MAPPED = [DataDRequest.__RESCODE_OFFLINECLIENT, DataDRequest.__RESCODE_HTTP_METHODNOTALLOWED];

/**
 * @param tag
 * @param resCode
 * @param defaultaction
 * @param args reemplazan a "__" en la cadena
 * @returns `string`
 */
export function _HttpMsg(tag: THTTPSrvTag, resCode: number, defaultaction?: keyof THTTPLabels["default.action"], ...args: any[]) {
    const statusStr: keyof typeof httplabels["default"] = resCode > 0 ? "success" : "fail";
    let message: string;
    if (HTTPTAGS[tag]) {
        message = HTTPTAGS[tag][resCode]; // Códigos de API
        if (!message && RESCODES_MAPPED.includes(resCode))
            message = HTTPTAGS["default"][resCode]; // Códigos de sistema
        if (!message)
            message = HTTPTAGS[tag][statusStr]; // Sin códigos mapeados

        if (message && args?.length) for (let arg of args)
            message = message.replace("__", arg);
    }
    else if (RESCODES_MAPPED.includes(resCode))
        message = HTTPTAGS["default"][resCode]; // Códigos de sistema
    if (!message && defaultaction) {
        const tagaction = (<THTTPActionsGroup>"default.action") + LABELSEPARATOR + defaultaction;
        message = HTTPTAGS[tagaction] ? HTTPTAGS[tagaction][statusStr] : null; // Msgs por tipo de servicio
    }
    if (!message) {
        message = HTTPTAGS["default"][statusStr] || ""; // Msgs finales por defecto
    }
    return message;
}

type IResponseInfo = Pick<DataDRequest.IRequestResponseA, "Resultado" | "EndPointName">
export function _HttpMsgV2({ Resultado, EndPointName }: IResponseInfo, defaultaction?: keyof THTTPLabels["default.action"], ...args: any[]): string {
    if (!EndPointName && !defaultaction)
        return "";
    return _HttpMsg(EndPointName as any, Resultado, defaultaction, ...args);
}

// ****************************************************************
// MENU LABELS
// ****************************************************************

export function _MenuLabel(k: (keyof typeof menulabels)) {
    if (menulabels[k] === undefined)
        console.warn(`Menu label "${k}" no found`);
    return menulabels[k] || "";
}

// ****************************************************************
//
// ****************************************************************

export function _FixHTMLTags(html: string) {
    const templateFieldsRegex = /\{\{(.*?)\}\}/g
    return html.replace(templateFieldsRegex, (_match, token) => {
        return _L(token) + ""
    })
}

const _L = _Label;
export default _L;