import { Entidad } from "../Entidad";
import { Global } from "../Global";
import { DataDRequest } from "../DRequest";

// export var DiccCovidEncuesta: Map<number, Entidades.ICovidEncuesta> = new Map();

type ArrayData = Array<Entidad.ICovidEncuesta>;

interface IRespose {
    Datos: ArrayData;
    Resultado: number;
}

export async function _GetCovidEncuestaList(idEscuela: number): Promise<DataDRequest.IResultadoPeticion<ArrayData>> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "covid/ObtenerListaEncuesta";
    const params = {
        IdEscuela: idEscuela
    }
    return new Promise(async (resolve, reject) => {
        await DataDRequest._Request2<IRespose>(url, "GET", params)
            .then(res => {
                resolve({
                    Resultado: (res.error ? -1 : res.data.Resultado),
                    Data: (res.error ? null : res.data.Datos)
                })
            })
    })
}
