import { DataDRequest } from "../DRequest";
import { Entidad } from "../Entidad";
import { Global } from "../Global";
import { ICatalogoSATFormaPago } from "../entidad/Factura";
import { DataUtil } from "../util/Util";
import { _ObtenerCatalogoSATFormaPago } from "./FacturaCatalogoSAT";

const RequestID = Entidad.CTipoRequest.FinanzaMetodoPago;
export const _DiccMetodosPago: Map<number, Entidad.IFinanzaMetodoPago> = new Map();


export async function _GetList(escuelas: Array<Entidad.IEscuela>) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "metodoPago/ObtenerLista"; // "AdminJS/MetodoPago/ObtenerMetodosPago";
    const results = new Array<DataDRequest.IRequestResponseListA<Entidad.IFinanzaMetodoPago>>();

    for await (let escuela of escuelas) {
        const rowID = escuela.IdKinder;
        const lastStrDate = await DataUtil._GetMaxDate(RequestID, rowID);
        const params = {
            "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
            "IdEscuela": rowID,
            "Modificacion": lastStrDate
        }

        let result = await DataDRequest._Request4List(url, "GET", params, RequestID, rowID);

        results.push(result);
    }
    return results;
}

/** Si la consulta falla retorna la config del metodo de pago */
export function _ObtenerFormaPagoSATByMetodoPago(idEscuela: number, idMetodoPago: number): Promise<ICatalogoSATFormaPago[]> {
    const mp = _DiccMetodosPago.get(idMetodoPago)
    const catchList: ICatalogoSATFormaPago[] = []
    if (mp)
        catchList.push({ key: mp.IdFormaPagoSAT, name: mp.NombreFormaPagoSAT })
    return new Promise(resolve => {
        if (!idEscuela) {
            resolve(catchList)
            return
        }
        _ObtenerCatalogoSATFormaPago(idEscuela)
            .then(res => {
                if (res.Resultado > 0)
                    resolve(res.Datos)
                else
                    resolve(catchList)
            })
            .catch(() => resolve(catchList))
    })
}

export function _AltaMetodoPagoNoElectronico(dato: Entidad.IFinanzaMetodoPago): Promise<DataDRequest.IResultadoPeticion<null>> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "metodoPago/NuevoMetodoPago"; // "AdminJS/MetodoPago/NuevoMetodoPago";
    const params = {
        "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "IdEscuela": dato.IdEscuela,
        "Nombre": dato.Nombre,
        "IdFormaPagoSAT": dato.IdFormaPagoSAT,
        "NombreFormaPagoSAT": dato.NombreFormaPagoSAT,
    }
    return DataDRequest._Request5(url, "POST", params, RequestID);
}

export function _EditarMetodoPagoNoElectronico(idMetodoPago: number, idFormaPagoSAT: string, nombreFormaPagoSAT: string): Promise<DataDRequest.IRequestResponseA> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "metodoPago/EditarMetodoPago"
    const params = {
        "Id": idMetodoPago,
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "IdFormaPagoSAT": idFormaPagoSAT,
        "NombreFormaPagoSAT": nombreFormaPagoSAT,
    }
    return DataDRequest._Request5(url, "POST", params)
}

export function _AltaMetodoPagoElectronico(dato: Entidad.IFinanzaMetodoPago, provElectronico: number, conf: Array<string>): Promise<DataDRequest.IResultadoPeticion<null>> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "metodoPago/MetodoPagoElectronicoV2"; // MetodoPagoElectronico"; // "AdminJS/MetodoPago/NuevoMetodoPago";

    const params = {
        "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "IdEscuela": dato.IdEscuela,
        "Nombre": dato.Nombre,
        "ConfigPago": conf,
        "ProvElectronico": provElectronico,
        "PrefijoPago": dato.PrefijoPago,
        "IdFormaPagoSAT": dato.IdFormaPagoSAT,
        "NombreFormaPagoSAT": dato.NombreFormaPagoSAT,
    }

    return DataDRequest._Request5(url, "POST", params, RequestID);
}

export function _EditarMetodoPagoElectronico(dato: Entidad.IFinanzaMetodoPago, conf: Array<string>): Promise<DataDRequest.IResultadoPeticion<null>> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "metodoPago/EditarMetodoPagoElectronicoV2"; // EditarMetodoPagoElectronico";

    const params = {
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "IdMetodoPago": dato.IdMetodoPago,
        "ConfigPago": conf,
        "PrefijoPago": dato.PrefijoPago,
        "IdFormaPagoSAT": dato.IdFormaPagoSAT,
        "NombreFormaPagoSAT": dato.NombreFormaPagoSAT,
    }

    return DataDRequest._Request5(url, "POST", params, RequestID);
}

/* export default {
    _AltaMetodoPagoNoElectronico,
    _AltaMetodoPagoElectronico,
    _EditarMetodoPagoElectronico
} */