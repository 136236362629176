export class ArrayV2<T = any> extends Array<T> {

    /** @return Retorna un nuevo arreglo */
    public _MoveIndex(indexFrom: number, indexTo: number) {
        if (indexFrom < 0 || indexTo < 0 || indexFrom > this.length - 1 || indexTo > this.length - 1) {
            console.error("Indice fuera de los limites", indexFrom, indexTo, this.length);
            const resFinal = new ArrayV2();
            resFinal.push(...this);
            return resFinal;
        }
        let moveValue = this[indexFrom];

        let listNotValue = this
            .filter((d, i) => (i != indexFrom));

        // AddValuePosition
        const res = listNotValue
            .slice(0, indexTo)
            .concat(moveValue, this.slice(indexTo));

        const resFinal = new ArrayV2();
        resFinal.push(...res);
        return resFinal;
    }

    /** @return Retorna un nuevo arreglo */
    public _AddValuePosition(d: T, index: number) {
        const res = this
            .slice(0, index)
            .concat(d, this.slice(index));

        const resFinal = new ArrayV2();
        resFinal.push(...res);
        return resFinal;
    }

    public async _ForEachAwait(fnIterable: (d: T, index: number) => Promise<void>): Promise<void> {
        let i = 0;
        for (const item of this) {
            await fnIterable(item, i);
            i++;
        }
    }

    /** @return Retorna un nuevo arreglo */
    public async _MapAwait<TMapped>(fnIterable: (d: T, index: number) => Promise<TMapped>): Promise<TMapped[]> {
        let i = 0;
        const arrayResult: ArrayV2<TMapped> = new ArrayV2();
        for (const item of this.values()) {
            const itemRes = await fnIterable(item, i);
            arrayResult.push(itemRes);
            i++;
        }
        return arrayResult;
    }

    public async _ReduceAwait<Result>(iterable: (result: Result, currentItem: T, index: number) => Promise<Result>, init?: Result): Promise<Result> {
        let i = 0;
        let result: Result = init;
        for (const item of this.values()) {
            result = await iterable(result, item, i);
            i++;
        }
        return result;
    }

    public _Push(...items: T[]) {
        this.push(...items);
        return this;
    }
}