import { Entidad } from "../Entidad";
import { Global } from "../Global";
import { DataDRequest } from "../DRequest";
import { DateV2 } from "../../util/DateV2";

import CTipoEvento = Entidad.CReporteTipoEvento;
type IEventoEscuela = Entidad.IEventoEscuela & {
    IdGrupo: number
};
type IEventoGrupo = Entidad.IEventoGrupo;
type IEventoAlumno = Entidad.IEventoAlumno;

export async function _ObtenerEventosPorAlumno(idAlumno: number, idEscuela: number, tipoEvento: CTipoEvento, fecha: DateV2): Promise<DataDRequest.IResultadoPeticion<IEventoAlumno[]>> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "evento/ObtenerEventosAlumno";
    const params = {
        // IdEscuela: idEscuela
        "IdChild": idAlumno,
        "IdKinder": idEscuela,
        "TipoEvento": tipoEvento,
        "Fecha": fecha._ToISOString()
    }

    return new Promise(async (resolve, reject) => {
        await DataDRequest._Request2<DataDRequest.IRequestResponseBase<IEventoEscuela[]>>(url, "GET", params)
            .then(res => {
                resolve({
                    Resultado: (res.error ? -100 : res.data.Resultado),
                    Data: (res.error ? null : res.data.Datos),
                    TipoRequest: null
                })
            })
    })
}

export function _ObtenerEventosPorGrupo(idGrupo: number, idEscuela: number, tipoEvento: Entidad.CReporteTipoEvento, fecha: string): Promise<DataDRequest.IResultadoPeticion<IEventoGrupo[]>> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "evento/ObtenerEventosGrupo";
    const params = {
        "IdGrupo": idGrupo,
        "IdKinder": idEscuela,
        "TipoEvento": tipoEvento,
        "Fecha": fecha
    }

    return new Promise(async (resolve) => {
        await DataDRequest._Request2<DataDRequest.IRequestResponseBase<IEventoGrupo[]>>(url, "GET", params)
            .then(res => {
                resolve({
                    Resultado: (res.error ? -100 : res.data.Resultado),
                    Data: (res.error ? null : res.data.Datos),
                    TipoRequest: null
                })
            })
    })
}

export async function _EventoObtenerEventosEscuela(idEscuela: number, tipoEvento: CTipoEvento, fecha: string): Promise<DataDRequest.IRequestResponseA<IEventoEscuela[]>> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "evento/ObtenerEventosEscuela";
    const params = {
        // IdEscuela: idEscuela
        "IdKinder": idEscuela,
        "TipoEvento": tipoEvento,
        "Fecha": fecha,
    }

    return DataDRequest._Request5<IEventoEscuela[]>(url, "GET", params)
}

/**
 * @param fechaFoto si no es Date respetar el formato ISO
 */
export function _GetUrlObtenerFoto(idFoto: number, fechaFoto: Date | string, tamanio: 1 | 2 | 3): string {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "evento/ObtenerFoto";
    const strFecha = (typeof fechaFoto == "string") ? fechaFoto : fechaFoto.toISOString();
    return url + `?IdFoto=${idFoto}&FechaFoto=${strFecha}&Tamanio=${tamanio}`;
}

export default {
    _ObtenerEventosPorAlumno,
    _ObtenerEventosPorGrupo,
    _GetUrlObtenerFoto
}
