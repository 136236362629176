import * as d3 from "d3";
import { UIUtilGlobalKeyEvents } from "../util/GlobalKeyEvent";
import { UIUtilGeneral } from "../util/Util";
import { DataUtilLocalStorage } from "../../data/util/LocalStorage";

export interface IColorSelector {
    Parent: d3.Selection<HTMLElement, any, any, any>;
    Data?: string[];
    OnSelect?: (datos: string[]) => void;
    OnChange?: (datos: string[]) => void;
    OnChangeVisible?: (visible: boolean) => void;
    StorageColorKey?: string;
    MaxFooterOptions?: number;
}

type TypeStep = ("enter" | "update" | "exit");

interface IItemCircleSelector {
    data: string;
    selected: boolean;
}

const NATURALHEIGHTSELECTOR = 306;

export class ColorSelector {
    private colorItems: Map<string, IItemCircleSelector>;
    private colorFooterItems: Map<string, IItemCircleSelector>;
    private config: IColorSelector;
    private selectColorsContainer: d3.Selection<HTMLDivElement, any, HTMLElement, null>;
    private resizeObserver: ResizeObserver;

    constructor(config: IColorSelector) {
        this.colorItems = new Map();
        this.colorFooterItems = new Map();
        this.resizeObserver = UIUtilGeneral._GetResizeObserver(entries => {
            setTimeout(() => this.Reubicate(entries[0].contentRect), 200)
        })
        let defaultConfig = <IColorSelector>{
            Data: [
                "#FF0000", "#FB8C00", "#FFFF00", "#23FF00", "#00B9FF", "#5D00FF", "#FF00AA",
                "#E74C3C", "#FF9800", "#F1C40F", "#2ECC71", "#3498DB", "#9B59B6", "#EC407A",
                "#EC7063", "#FFA726", "#F4D03F", "#58D68D", "#5DADE2", "#AF7AC5", "#F06292",
                "#F1948A", "#FFB74D", "#F7DC6F", "#82E0AA", "#85C1E9", "#C39BD3", "#F48FB1",
                "#A40122", "#D84315", "#FFF59D", "#0FC148", "#4DD0E1", "#FFFFFF", "#000000",
            ],
            MaxFooterOptions: 6
        };

        config.Data = config.Data?.length ? config.Data : defaultConfig.Data;
        config.MaxFooterOptions = config.MaxFooterOptions ? config.MaxFooterOptions : defaultConfig.MaxFooterOptions;

        this.config = { ...defaultConfig, ...config };
        this.CreateElemnts();
        this.UpdateMainData(this.config.Data);
        this.config.MaxFooterOptions;
        //this.UpdateFooterData();
    }

    private UpdateMainData(datos: string[]) {
        if (datos) {
            this.config.Data = datos;
            let beforeData = new Map(this.colorItems.entries());

            this.colorItems.clear();
            datos.forEach(d => {
                let key = d;
                let beforeItem = beforeData.get(key);
                if (beforeItem) {
                    this.colorItems.set(d, { data: d, selected: beforeItem.selected });
                } else {
                    this.colorItems.set(d, { data: d, selected: false });
                }
            });

            beforeData.clear();
        }
    }

    private UpdateFooterData() {
        if (this.config.StorageColorKey) {
            let strColorsThemeConfig = DataUtilLocalStorage._GetItem("colorthemes", "Recents");
            let objColorsThemeConfig = strColorsThemeConfig ? JSON.parse(strColorsThemeConfig) : {};
            let arrRecentColors: string[] = objColorsThemeConfig[this.config.StorageColorKey] || [];
            if (arrRecentColors) {
                arrRecentColors = arrRecentColors.splice(0, this.config.MaxFooterOptions);
                let beforeFooterData = new Map(this.colorFooterItems.entries());

                this.colorFooterItems.clear();

                arrRecentColors.forEach(d => {
                    let key = d;
                    let beforeItem = beforeFooterData.get(key);
                    if (beforeItem) {
                        this.colorFooterItems.set(d, { data: d, selected: beforeItem.selected });
                    } else {
                        this.colorFooterItems.set(d, { data: d, selected: false });
                    }
                });

                beforeFooterData.clear();
            }
        }
    }

    private Show() {
        UIUtilGlobalKeyEvents._SetEscKeyEventCallback(this.selectColorsContainer.node(), () => this.Hide())
        if (this.colorItems && this.colorItems.size) {
            this.config.Parent.append(() => this.selectColorsContainer.node());
            this.UpdateItemsListView();
            this.Reubicate();
            this.resizeObserver?.observe(document.body);
            this.selectColorsContainer.classed("mostrar_selector", true);
            this.selectColorsContainer.on("click", () => {
                d3.event.stopPropagation();
            })

            this.selectColorsContainer.select<HTMLDivElement>(".color_options").node()?.scrollIntoView();

            if (this.config.OnChangeVisible) {
                this.config.OnChangeVisible(true);
            }

            if (this["__clickbody"]) {
                document.removeEventListener("click", this["__clickbody"]);
            }
            this["__clickbody"] = () => {
                this.Hide();
            }
            setTimeout(() => {
                document.addEventListener("click", this["__clickbody"]);
            })
        }
    }

    private Hide() {
        if (this.isVisible()) {
            UIUtilGlobalKeyEvents._RemoveEscKeyEventCallback(this.selectColorsContainer.node());
            this.resizeObserver?.unobserve(document.body);
            this.selectColorsContainer.remove();
            if (this.config.OnChangeVisible) {
                this.config.OnChangeVisible(false);
            }
        }

        if (this["__clickbody"]) {
            document.removeEventListener("click", this["__clickbody"]);
        }
    }


    private OnChangeItemsSelected(dataSelected: string[]) {
        if (this.config.OnChange) {
            this.config.OnChange(dataSelected);
        }
        this.OnSelectItems();
    }

    private OnSelectItems() {
        let dataSelected = this.GetDataSelected();
        if (this.config.OnSelect) {
            this.config.OnSelect(dataSelected);
        }
    }

    private UpdateItemsListView() {
        this.UpdateFooterData();
        if (this.selectColorsContainer.node()) {
            let dataItems = Array.from(this.colorItems.values());
            this.selectColorsContainer.select<HTMLDivElement>(".color_options")
                .selectAll<HTMLDivElement, IItemCircleSelector>(":scope > .option_color")
                .data(dataItems)
                .join(
                    enter => {
                        let item: d3.Selection<HTMLDivElement, IItemCircleSelector, HTMLDivElement, null> = null;
                        item = enter.append("div")
                            .classed("option_color", true);

                        return this.UpdateItemListView(item, "enter");
                    },
                    update => this.UpdateItemListView(update, "update"),
                    exit => this.UpdateItemListView(exit, "exit").remove()
                )

            let dataFooterItems = Array.from(this.colorFooterItems.values());
            this.selectColorsContainer.select<HTMLDivElement>(".footer_options").classed("hide", !dataFooterItems.length)
            this.selectColorsContainer.select<HTMLDivElement>(".footer_options").select<HTMLDivElement>(".footer_color_options")
                .selectAll<HTMLDivElement, IItemCircleSelector>(":scope > .option_color")
                .data(dataFooterItems)
                .join(
                    enter => {
                        let item: d3.Selection<HTMLDivElement, IItemCircleSelector, HTMLDivElement, null> = null;
                        item = enter.append("div")
                            .classed("option_color", true);

                        return this.UpdateItemListView(item, "enter");
                    },
                    update => this.UpdateItemListView(update, "update"),
                    exit => this.UpdateItemListView(exit, "exit").remove()
                )

        }
    }

    private UpdateItemListView(itemCircle: d3.Selection<HTMLDivElement, IItemCircleSelector, HTMLDivElement, null>, step: TypeStep) {
        itemCircle.each((d, i, arr) => {
            let itemListView = d3.select<HTMLDivElement, IItemCircleSelector>(arr[i])
                .classed("circle_option_selected", d.selected);

            itemListView.style("background-color", d.data);

            if (step == "exit") {
                return;
            }

            itemListView.on("click", (d, i, arrCircl) => {
                const initDataSelected = this.GetDataSelected();
                this.colorItems.forEach(d => d.selected = false);
                this.colorItems.forEach(dI => { if (dI.data == d.data) dI.selected = true })

                if (!initDataSelected[0] || initDataSelected[0] !== d.data) {
                    if (this.config.StorageColorKey) {
                        let arrRecentColors = Array.from(this.colorFooterItems.values()).map(d => d.data);
                        let itemAlreadyInArr = arrRecentColors.findIndex(dR => d.data == dR);
                        if (itemAlreadyInArr > -1) arrRecentColors.splice(itemAlreadyInArr, 1);
                        arrRecentColors.unshift(d.data);
                        arrRecentColors = arrRecentColors.splice(0, this.config.MaxFooterOptions);
                        let strColorsThemeConfig = DataUtilLocalStorage._GetItem("colorthemes", "Recents");
                        let objColorsThemeConfig = strColorsThemeConfig ? JSON.parse(strColorsThemeConfig) : {};
                        objColorsThemeConfig[this.config.StorageColorKey] = arrRecentColors;
                        DataUtilLocalStorage._SetItem("colorthemes", "Recents", JSON.stringify(objColorsThemeConfig));
                    }
                    this.OnChangeItemsSelected([d.data]);
                }

                this.Hide();
                d3.event.stopPropagation();
            })
        });

        return itemCircle;
    }

    private CreateElemnts() {
        let colorSelect = this.selectColorsContainer = d3.create("div")
            .attr("class", "color_select")

        colorSelect.append("div").classed("color_options", true);
        let footerOptionsContainer = colorSelect.append("div").classed("footer_options", true);
        footerOptionsContainer.append("div").classed("lbl_footer", true).text("Recientes");
        footerOptionsContainer.append("div").classed("footer_color_options", true);
    }


    private Reubicate(dimOverl?: DOMRectReadOnly) {
        // Crear nuestro propio algoritmo para reubicar este elemento??
        // 1. Este primer algoritmo Ubica el selector lo mas cerca posible a de su padre
        // Le establece 3 propiedades al selector, la ultima puede ser nula, es decir puede quitarsela:
        //  {
        //      top: "establece la posición en el eje Y",
        //      left: "establece la posición en el eje X",
        //      height: "cuando no hay espacio para dibujar el selector completo se ajusta el height, cuando si se quita y queda el max-height por defecto"
        //  }
        //

        // Se obtienen las posiciones/Dimensiones de: ["Body", "Parent", "Selector"]
        let body = document.body.getBoundingClientRect();
        let parent = this.config.Parent.node().getBoundingClientRect();
        let selectColorsContainer = this.selectColorsContainer.node().getBoundingClientRect();

        // Se declara el alto sin valor 
        let height: number;

        // Se evalúa (el alto del body es menor al alto natural del selector + margen) ? el alto se ajusta al alto del body con un margen : No existe height 
        if (body.height < (NATURALHEIGHTSELECTOR + 2)) {
            height = (body.height - 2);
        }

        // Se establece la propiedad left la cual se obtiene de una evaluación que se divide en 2:
        // 1. (ancho de body - (distancia de L2R hasta el final del padre + el ancho del selector + margen )) => Es decir, ¿existe espacio del lado derecho para colocar el selector?
        // 2. (existe suficiente espacio para colocarse del lado izquierdo)
        // Si se cumplen ambas condiciones => left toma valor de (distancia de L2R hasta el inicio del padre) menos (ancho de selector y margen)
        // Si no, toma el valor de (distancia de L2R hasta el final del padre + margen)
        let left = (
            (body.width - (parent.right + selectColorsContainer.width + 15)) <= 0
            && (parent.left - selectColorsContainer.width - 2) > 0)
            ? parent.left - selectColorsContainer.width - 2
            : parent.right + 2;

        // dif sirve para: evaluar la posición top del selector
        // Se obtiene de la resta de (Alto del body - (la distancia de U2D del padre + el alto del selector + margen))
        // dif solo se utiliza cuando es negativa
        let dif = body.height - (parent.bottom + selectColorsContainer.height + 15)

        // Top se establece en base a
        // Distancia U2D del padre +
        // Si dif es negativo entonces se suma (-dif)
        // Si no 0
        let top = parent.bottom + (dif < 0 ? dif : 0);
        // Evaluamos si top queda negativo entonces se vuelve 2 => un margen
        // Si no, es top
        top = top > 0 ? top : 2;

        // Se establecen sus propiedades
        this.selectColorsContainer
            .style("top", top + "px")
            .style("left", (left) + "px")
            .style("height", height ? (height + "px") : null)
    }

    private isVisible(): boolean {
        return this.selectColorsContainer.node().isConnected;
    }

    // ************************************************
    // PRIVATE GETTERS
    // ************************************************

    private GetSelectedControlItems() {
        return Array.from(this.colorItems.values())
            .filter(d => d.selected);
    }

    private GetDataSelected() {
        return this.GetSelectedControlItems()
            .map(d => d.data);
    }

    // ************************************************
    // PUBLIC PROPERTIES
    // ************************************************

    public get _Visible() {
        return this.isVisible();
    }

    public get _dataItems() {
        return this.config.Data;
    }

    public get _controlItemSelected() {
        return this.GetSelectedControlItems()[0];
    }

    public get _uniqueDataSelected() {
        return this.GetDataSelected()[0];
    }

    public get _controlItemsSelected() {
        return this.GetSelectedControlItems();
    }

    public get _dataSelected() {
        return this.GetDataSelected();
    }

    public get _listContainer() {
        return this.selectColorsContainer;
    }

    public set _onChange(onChange: (datos: string[]) => void) {
        this.config.OnChange = onChange;
    }

    public _UpdateList(datos?: Array<string>, datosFooter?: Array<string>) {
        this.UpdateMainData(datos);
        this.OnSelectItems();
        this.UpdateItemsListView();
        return this;
    }

    public _UpdateFooterList(datos: Array<string>) {
        this.OnSelectItems();
        this.UpdateItemsListView();
        return this;
    }

    public _ResetSelect() {
        if (this.colorItems) {
            this.colorItems.forEach(d => d.selected = false);

            this.OnSelectItems();
            this.UpdateItemsListView();
        }
        return this;
    }

    //DOTEST Cuando en el arreglo de colores vienen repetidos
    public _valueSelect(values: string[]) {
        const searchValue = (value: string) => {
            let dat = this.colorItems.get(value);
            if (dat) {
                dat.selected = true;
            }

            return dat;
        }

        this.colorItems.forEach(d => d.selected = false);

        if (Array.isArray(values)) {
            values.forEach(value => {
                searchValue(value);
            })
        }

        this.OnSelectItems();
        this.UpdateItemsListView();
        return this;
    }

    public _Show() {
        this.Show();
    }

    public _Hide() {
        this.Hide();
    }
}
