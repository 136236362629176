import * as d3 from "d3";
import { group as d3Group } from "d3-array";
import { DataUtilAlertBot } from "../../data/util/AlertBot";
import { DataUtilLocalStorage } from "../../data/util/LocalStorage";
import { HTMLFileIcoElement } from "../controlWC/FileIcoComponent";
import { HTMLIconCollapseElement } from "../controlWC/IconTogglerCollapseComponent";
import { HTMLResizerIndicatorElement } from "../controlWC/ResizerIndicatorComponent";
import { HTMLSpinnerElement } from "../controlWC/SpinnerComponent";
import { UIUtilElementBehaviors } from "../util/ElementBehaviors";
import { UIUtilFormat } from "../util/Format";
import { UIUtilGlobalKeyEvents } from "../util/GlobalKeyEvent";
import { UIUtilIconResources } from "../util/IconResourses";
import { UIUtilLang } from "../util/Language";
import { UIUtilTime } from "../util/Time";
import { UIUtilGeneral } from "../util/Util";
import { Button } from "./Button";
import { CheckBox } from "./CheckBox";
import { FilterControlV2, IMainFilterItem, IMainFilterItemTypeConfig, TFiltroActivoValueShareMainMap, TMainFilterType } from "./FilterControlV2";
import { FloatingOptionsBtns } from "./FloatingOptions";
import { MenuFlex } from "./MenuFlexV2";
import { TableUtil as Util } from "./TablaUtil";

let idCount = 0;
const PREFIX_IDENTIFIER = "tbl";
export namespace Table {
    const ANIMATION_DURATION = 400;

    export enum CTypeOriginEvent {
        OnClickRowTopCheck = 1,
        OnClickSuperCheck,
        OnExternalEvent,
        OnCloseOptionsOfDataCheck,
        OnClickChildCheck,
        /** Cuando el la actualización de los datos colapsables desencadena que el item top cambie su estado
         *
         * * Caso: Cuando los elementos colapsables checkeados son removidos en una actualización de datos
         * sin interactuar directamente con los datos
         */
        OnChildsUpdateTopCheck,
        OnUpdateDataTable
    }

    type TStepJoin = UIUtilGeneral.TTypeStepJoin;

    type TIdType = (number | string);
    interface IPropieties<TData> {
        /** Datos Originales (INMUTABLE) */
        DataOrigin: Array<TData>;

        /** Datos Originales procesados por el control (Solo se procesan una vez por Update) */
        DataOriginTableMap: Map<TIdType, TDataItemTable<TData>>;
        /** Datos Originales procesados por el control (Solo se procesan una vez por Update) */
        DataOriginTableArray: Array<TDataItemTable<TData>>;

        /** Estructura de datos usados por la Tabla, son los datos que se mapean al DOM
         * * Incluye datos: 
         *      - Checkeados
         *      - Inhabilitados
        */
        DataTableFilteredMap: Map<TIdType, TDataItemTable<TData>>;
        /** Estructura de datos usados por la Tabla, son los datos que se mappean al DOM
         * * Incluye datos: 
         *      - Checkeados
         *      - Inhabilitados
        */
        DataTableFilteredArray: Array<TDataItemTable<TData>>;

        /** Datos checkeados, incluye:
         * * Datos que no pasaron los filtros
        */
        DataTableCheckedMap: Map<TIdType, TDataItemTable<TData>>;

        /** Propiedades de IConfig más propiedades de funcionamiento de la tabla */
        ColumnHeaders: Array<ITableField<TData>>
        /** Datos actuales confiables por Identificadores no repetidos
         * * En caso de ser falso, la próxima actualización de datos receteará los estados referentes a checkeo y collapso */
        ReliableCurrentData: boolean
    }

    export interface IConfig<TDataTable> {
        /** Identificador único de tabla (Para preservar configuración de tabla en LocalStorage) */
        IdTabla: string;
        /** Contenedor padre de la tabla */
        Parent: d3.Selection<HTMLElement, any, any, any>;
        /** (Opcional) Título de la tabla */
        Title?: string;
        /** (Opcional) Llave a considerar como id por registro
         * * En caso de que cada registro tenga un Identificador único, los elementos checkeados serán conservados despues de IObjectControl.met_UpdateData().
         * * En caso contrario, así como al omitir 'IdData', cada invocación de IObjectControl.met_UpdateData()
         * reseteará los estados (IsChecked, IsCollapsed) de los items. */
        IdData?: (keyof TDataTable & string);
        ItemGrouperField?: (keyof TDataTable & string);
        /** Almacena parámetros del ordenamiento inicial de datos */
        OrderDefault?: Omit<TOrderField<TDataTable>, keyof Pick<TOrderField<TDataTable>, "IsLowLevel">>;
        /**
         * Define la configuración para la caja de búsqueda por texto
         * @example
         * "none": Sin caja de búsqueda
         * ((dato: TDataTable) => string[]): Retornar manualmente las cadenas correspondientes al dato en iteración
         * @default "default" */
        FilterByStrSearch?: ("default" | "none" | ((dato: TDataTable) => string[])),
        /** Configuracón de datos y dimenciones a mostar */
        RenderColumnHeadings: Array<IColumn<TDataTable>>;
        /** (Opcional) Minimo del ancho total de la Tabla.
         * * Por default desde los estilos el ancho es de 1000px */
        MinWidth?: number,
        /** (Opcional) Menú ubicado en la barra superior de la Tabla */
        OptionsTopDefaultV2?: ITableMenuTopDefaultConfig;
        /** (Opcional) Menú se ubica sobre la barra superior y sobre los encabezados ('Title', 'OptionsTopDefaultV2' si existen)
         * al tener filas Checkeadas, con comportamiendo Toggle
         * * Nota: se invoca (se actualiza ui) por cada dato checkeado/descheckeado
         * */
        OptionsOfDataCheckV3?: (dato: TDataTable[]) => ITableMenuDataSelectedConfig<TDataTable>;
        /** Cantidad de celdas de izquierda a derecha para ignorar su width en el calculo del ancho del menu en file
         * @default 1
         */
        MenuInRowNoCellsToIgnoreWidth?: number;
        /** (Opcional) Parámetros de filtro, si el arreglo tiene elementos agrega un area un area con las opciones de filtros especificados */
        FilterParameters?: Array<IParametroFiltro<TDataTable>>;
        /** (Opcional) Agrega a la tabla la carácteristica de pasar al inicio a los elementos chequeados
         * @default true */
        EnableRelevanceSelections?: boolean;
        /** (Opcional) Las columna de acción check se ajusta como elementos pegajosos (siempre visibles en caso de scroll horizontal)
         * @default false */
        StickyCheckInRow?: boolean;
        // StickyCollapserIndicatorInRow?: boolean;
        /** (Opcional) Permite que el usuario habilite una columna como elemento pegajoso (siempre visible en caso de scroll horizontal)
         * @default false */
        EnableStickyFields?: boolean;
        /** (Opcional) Muestra/oculta la columna de los checkbox
         * @default false */
        // NOTE Pasar a EVALUADOR DE NIVELES ?
        HideCheckboxes?: boolean;
        /** (Opcional) Al tener valor True cada celda se clasea con el nombre del campo correspondiente al objeto al que pertenece
         * @default false */
        AddNameFieldClassToCells?: boolean;
        /** @default true */
        HideItemWhenItHasNoChildItems?: boolean;
        /** Las filas deshabilitadas con derecho a tener menú de fila
         * @default false */
        DisabledRowsWithMenuInRow?: boolean;
        /** (Opcional) Se invoca al hacer click sobre una fila, agrega estilo gris claro a la celda clickeada (estilo Toggle)
         * @deprecated
        */
        // NOTE pasar a Evaluador de niveles
        OnValueSelectRow?: (idDatum: string | number, datum: TDataTable, isSelected: boolean) => void;
        EvaluatorAndSubLevelsBuild?: IStepEvaluator<TDataTable, any, any>;
        OnEndUpdateDataInView?: (dataFiltered: TDataItemTableAux<TDataTable>[], dataChecked: TDataItemTableAux<TDataTable>[]) => void;
        OnClickSyncTable?: () => Promise<Array<TDataTable>>;
        /** Key del "modulo" al que pertenecen los strings de la tabla:
         * * Headers
         */
        LangModuleKeyInContext?: string;
        FilterExtraBeforeFilterTable?: (dato: TDataTable) => boolean;
        OnFilter?: () => void;
        OnChangeOrder?: (field: (keyof TDataTable & string), orderType: CStatusOrder) => void;
        /** (Opcional) Hace que las columnas sean redimensionables
         * @default false */ //TEMPORAL
        Resizable?: boolean;
    }

    export type IStepEvaluator<TData, TSubData1, TSubData2> = {
        RemoveLevelCheckboxes?: boolean;
        HideLevelCollapseIndicator?: boolean;
        EvaluatorSubLevel?: IStepSubLevelEvaluator<TData, TSubData1, TSubData2>;
        /** Define si el row se inicializa como colapsado, por omisión el row es colapsado */
        OnEvalIsCollapsedRow?: (data: TData, lastStatus: boolean, indexOrigin: number) => boolean;
        /** Define si el row se inicializa como habilitado, por omisión el row es habilitado */
        OnEvalIsEnableRow?: (dato: TData, lastStatus: boolean, indexOrigin: number) => boolean;

        /** Nota: En el caso de los subniveles se invoca con las filas colapsables */
        OnStepCellTable?: (container: TSelectionHTML<"div">, datum: TData, fieldNameStep: (keyof TData & string) | string, /* typeStep: TTypeStep, */ indexInicialOfDatum?: number) => void;
        /** Nota: En el caso de los subniveles se invoca con las filas colapsables */ // SOBREESCRITO
        OnStepRowTable?: (datum: TData, tableRow: TSelectionHTML<"tr", any>, rowBody: TSelectionHTML<"div", any>, indexOrigin: number, parentData: undefined, restOfParentsData: []) => void; // ctrlExtraFeatures: IExtraRowFeatures<TDataTable>) => void

        OnCollapserChange?: (isCollapsed: boolean, dato: TData, rowBodyParent: TSelectionHTML<"div", TDataItemTable<TData>>) => void;

        /** Por cada checkeo o descheckeo realizado se ejecuta (cada vez que el número de datos checkeados cambia) */  // SOBREESCRITO
        OnCheckedData?: (dataChecked: Array<TData>, originEvent: Table.CTypeOriginEvent, parentData: null, restOfParentsData: null) => void;

        /** Menú sobre la fila seleccionada (al hacer click sobre la fila) con comportamiento Toggle */
        GetOptionsInRowV2?: (dato: TData) => ITableMenuDataSelectedConfig<TData>;

        /** Agrega lineas extras a la fila correspondiente */
        AddRowMultiline?: IRowMultilineConfig<TData, any> // <TData>(config: IRowMultilineConfig<TData>) => void;

        /** @default false */
        ShowNoContentTagRow?: boolean;
    } & IStepEvaluatorDataEval;

    type IStepEvaluatorDataEvalAux = { 1: true, 2: false };
    type IStepEvaluatorDataEval = {
        [k in keyof IStepEvaluatorDataEvalAux]: {
            /** Define el momento en que se invoca {@link IStepSubLevelEvaluator.OnGetData}
             * * `true`: La funcion {@link IStepSubLevelEvaluator.OnGetData OnGetData} del nivel inferior se ejecutará solo cuando se descolapse la la fila de éste nivel.
             *      Si {@link IStepSubLevelEvaluator.OnGetData OnGetData} retorna una promesa:
             *      * Agrega un spinner de carga a la fila
             *      * Las filas sin childs no se ocultan
             * * `false`: invoca {@link IStepSubLevelEvaluator.OnGetData OnGetData} al pasar data a la tabla (no recomendado con Promesas)
             *      * Por defecto las filas sin childs no se ocultan (se modifica con `ForceShowLevelRows`)
             *  @default false
             * */
            ActiveDataOnlyWhenDisplayData?: IStepEvaluatorDataEvalAux[k];
        } & (IStepEvaluatorDataEvalAux[k] extends true ? {
            /** Válido solo cuando `ActiveDataOnlyWhenDisplayData == (false | null | undefined)` */
            ForceHideLevelRowsWithoutChilds?: false;
        } : {
            /** Válido solo cuando `ActiveDataOnlyWhenDisplayData == (false | null | undefined)` */
            ForceHideLevelRowsWithoutChilds?: true;
        })
    }[keyof IStepEvaluatorDataEvalAux]

    export type IStepSubLevelEvaluator<TDataParent, TData, TSubData1> = {
        /** Si es (null | undefined), se toma el valor de "IdData" de la configuración de la tabla.
         *
         * Si el valor del dato no es encontrado, cada vez que se actualicen los datos colapsables, sus estados (IsChecked, IsCollapsed) serán reseteados
         */
        IdMember: string;
        /** Define los datos colapsables correspondientes al dato
         * @param dato Padre inmediado
         * @param parentsDataSuper Resto de los padres superiores
        */
        /** Solo debe retornar Promise cuando ActiveDataOnlyWhenDisplayData del nivel superior es == true */
        OnGetData: (dato: TDataParent, parentsDataSuper: any[]) => (TData[] | Promise<TData[] | Error>);

        OnClickCheck?: (itemDato: TData, isChecked: boolean, /** allChildrenChecked: TSubData1[] parentsData: any[] */) => void,

        // SOBREESCRITOS ***************

        /** Nota: En el caso de los subniveles se invoca con las filas colapsables */
        OnStepRowTable?: (datum: TData, tableRow: TSelectionHTML<"tr", any>, rowBody: TSelectionHTML<"div", any>, indexOrigin: number, parentData: TDataParent, restOfParentsData: any[]/* [TDataParent, ...any]*/) => void; // ctrlExtraFeatures: IExtraRowFeatures<TDataTable>) => void
        /** Por cada checkeo o descheckeo realizado se ejecuta (cada vez que el número de datos checkeados cambia)
         * * @param parentsData los datos padres de los checheados comienza por el más cercano [0] == parent1 ...
         * * // NOTE // REMOVER?
         * */
        OnCheckedData?: (dataChecked: Array<TData>, originEvent: Table.CTypeOriginEvent, parentData: TDataParent, restOfParentsData: any[]) => void;
    } & IStepEvaluator<TData, TSubData1, any>

    type ILevelEvaluator = {
        Level: number;
    } & IStepSubLevelEvaluator<any, any, any>

    // *********************************************************************
    // FILTROS
    // *********************************************************************

    type IParametroFiltroMap<TData, KTipoFiltro extends TMainFilterType> = {
        /** Ejs: "Nombre" | "Objeto.Nombre" | ... */
        Field: (keyof TData & string);
        Label?: string;
        /** @default == thisconfig.Field */
        LabelLangKey?: string;
        /** if == null -> No search Lang context
         * @default config.LangModuleKeyInContext */
        LangModuleKeyInContext?: string;
        /** @default "text" */
        Type?: KTipoFiltro;
        /** Aplica solo para la conf de la Tabla
         * @default 1
        */
        Level?: number;
        OnGetValueToMatch?: (dato: TData) => Array<
            KTipoFiltro extends "number" ? number
            : KTipoFiltro extends "date" ? (string | Date)
            : KTipoFiltro extends "text" ? string
            : any
        >;
        OnChange?: (filtro: TFiltroActivoValueShareMainMap[KTipoFiltro]) => void;
    } & Omit<IMainFilterItemTypeConfig<KTipoFiltro>, keyof Pick<IMainFilterItemTypeConfig<"text">, "Type" | "Key" | "Label" | "OnGetValueData">>;

    export type IParametroFiltro<TData = Object> = {
        [KFilterType in TMainFilterType]: IParametroFiltroMap<TData, KFilterType>;
    }[TMainFilterType];

    // *********************************************************************
    // MENUS
    // *********************************************************************

    interface ITableMenuDataDetails {
        Enabled?: boolean;
        Description?: string;
    }

    // MENU TOP DEFAULT

    export interface ITableMenuTopDefaultConfig {
        /** @default 4 */
        MaxOptionsInRow?: number;
        Options: Array<ITableMenuTopDefaultOptionConfig>;
    }

    export type ITableMenuTopDefaultOptionConfig = {
        Label: string;
        Callback: () => void | Function | Promise<void>;
        /** 
         * @returns
         * * `boolean` (Habilita/Deshabilita)
         * * `ITableMenuDataDetails` (Opciones avanzadas)
        */
        GetDetails?: () => (boolean | ITableMenuDataDetails);
    }

    // MENU TOP DATA SELECTED

    export interface ITableMenuDataSelectedConfig<TData = Object> {
        /** @default 4 */
        MaxOptionsInRow?: number;
        Options: Array<ITableMenuDataSelectedOptionConfig<TData>>;
    }

    export type ITableMenuDataSelectedOptionConfig<TData = Object> = {
        Label: string;
        /**
         * * `True` Opcion habilitado en multiselect, deshabilitado en 1 select
         * * `False` Opcion deshabilitado en multiselect, habilitado en 1 select
         * @default null siempre habilitado
         */
        MultiData?: boolean;
        Callback: (data: TData[]) => void | Function | Promise<void>;
        /** 
         * @returns
         * * `boolean` (Habilita/Deshabilita)
         * * `ITableMenuDataDetails` (Opciones avanzadas)
        */
        GetDetails?: (data: Array<TData>) => (boolean | ITableMenuDataDetails);
    }

    // MENU IN ROW
    // export interface ITableMenuRowConfig<TData = Object> {
    //     /** @default 4 */
    //     MaxOptionsInRow?: number;
    //     Options: Array<ITableMenuRowOptionConfig<TData>>;
    // }

    // export type ITableMenuRowOptionConfig<TData = Object> = {
    //     Label: string;
    //     Callback: (data: TData) => void | Function | Promise<void>;
    //     /** Reemplaza a Multidata
    //      * * True // Habilita
    //      * * False // Deshabilita
    //     */
    //     OnValidateDisabling?: (data: TData) => boolean;
    // }

    // 

    interface IControlesGenerales<TData> {
        /** Contenedor del control */
        DivContent?: d3.Selection<HTMLDivElement, any, HTMLElement, null>;

        /** Contenedor principal del menú superior <div class"area_acciones""> */
        A_DivAcciones: d3.Selection<HTMLDivElement, any, HTMLElement, null>;
        /** Contenedor principal del area de filtros <div class"area_filtros""> */
        A2_DivFiltros: d3.Selection<HTMLDivElement, any, HTMLElement, null>;
        /** Contenedor principal de la maquetación de la tabla * <div class"area_control""> */
        B_DivControl: d3.Selection<HTMLDivElement, any, HTMLElement, null>;

        AA_DivMenuOfChecks: d3.Selection<HTMLDivElement, any, HTMLElement, null>
        /** Contenedor directo de la tabla && area de scroll * <div class"space_tabla""> */
        BA_DivSpaceTabla: d3.Selection<HTMLDivElement, any, HTMLElement, null>;
        /** Tabla * <div class"control_tabla""> */
        BAAA_TablaHead: d3.Selection<HTMLDivElement, any, HTMLElement, null>;
        /** Contenedor de registros  * <div class"rbodyB""> -- <div> */
        BAAB_TablaBodyA: d3.Selection<HTMLDivElement, any, HTMLElement, null>;
        /** Contenedor de registros  * <div class"rbodyB""> -- <div> */
        BAAB_TablaBodyB: d3.Selection<HTMLDivElement, any, HTMLElement, null>;

        C_CtrlMenuInRowV2: MenuFlex & {
            __idData?: string;
            __data?: TDataItemTable<any, any>;
            __onparentchanged?: (oldParent: TSelectionHTML<"htmlelement">, newParent: TSelectionHTML<"htmlelement">) => void;
            __onshow?: () => void;
            __onhide?: () => void;
        };
        C_CtrlMenuTopOfChecksV2: MenuFlex & {
            __onparentchanged?: (oldParent: TSelectionHTML<"htmlelement">, newParent: TSelectionHTML<"htmlelement">) => void;
            __onshow?: () => void;
            __onhide?: () => void;
        };
        C_CtrlMenuTopDefaultV2: MenuFlex & {
            __onparentchanged?: (oldParent: TSelectionHTML<"htmlelement">, newParent: TSelectionHTML<"htmlelement">) => void;
            __onshow?: () => void;
            __onhide?: () => void;
        };
        C_CtrlFiltrosV2: FilterControlV2;

        /** Control Check General por página */
        C_CheckCol: CheckBox.ControlSelection;
        /** Contenedor del espacio de pie */
        BB_DivSpacePie: d3.Selection<HTMLDivElement, {}, HTMLElement, null>;

        BBAA_DivButtonItPag: d3.Selection<HTMLDivElement, {}, HTMLElement, null>;

        BA_SpaceTablaStyleSizing: d3.Selection<HTMLStyleElement, {}, HTMLElement, null>;
        BA_SpaceTablaSwitchLastCellSizing: d3.Selection<HTMLStyleElement, {}, HTMLElement, null>;
    }

    type TInfoColumns<TData> = Omit<IColumn<TData>, keyof Pick<IColumn<TData>, "IsSortable" | "IsStickable">>[]

    export type TDataItemTable<TData, TDataChild = any> = {
        // key: number;
        readonly id: TIdType;
        readonly data: TData;
        // dataView: { [k in keyof TData]: any }; // FIXME IMPLEMENTAR, Funcionará para ExcelExport, Busquedas, Filtros, ViewDeTabla // FIXME
        isChecked: boolean;
        // isRowSelected: boolean;
        readonly initialPos: number;
        isRowEnable: boolean;
        readonly levelRow: number;
        isCollapsed: boolean;
        childDataOriginMap: Map<TIdType, TDataItemTable<TDataChild>>;
        childDataFilteredArray: Array<TDataItemTable<TDataChild>>;
        itemParent: TDataItemTable<any>;
    }

    export type TDataItemTableAux<TData, TDataChild = any> = {
        readonly [k in keyof TDataItemTable<TData, TDataChild>]: TDataItemTable<TData, TDataChild>[k];
    }

    export interface IRowMultilineConfig<TParentData, TData> {
        OnGetDataToAddRow: (dataParent: TParentData) => TData[],
        OnStepCell?: (tdContent: TSelectionHTML<"div">, datum: TData, field: string, parentData: TParentData, restOfParentsData: any[] /*, typeStep: TTypeStep*/) => void;
        OnStepNewTrs?: (d: TData, tr: TSelectionHTML<"tr">, parentData: TParentData, restOfParentsData: any[] /* [TParentData, ...any] */ /* typeStep: TTypeStep*/) => boolean;
    }

    type TColumnAlignment = "start" | "end" | "center";

    type TColumnType = "text" | "number" | "date"; // | "img" | "file" | "boolean"; // FIXME // FIXME // FIXME IMPLEMENTAR FALTANTES + SOPORTE PARA EXPORTAR DATOS FORMATEADOS
    type TColumnAutoFormatConfig<T extends TColumnType> =
        T extends "date" ? {
            Format?: UIUtilTime.TDateStandarFormats;
        } :
        T extends "number" ? {
            Format?: "currency" | "percent"; // | "decimal_2digits";
        } : {};

    type TColumnHeaderIconType = "img" | "file";
    type TColumnHeaderIcon = {
        [T in TColumnHeaderIconType]: {
            /** @default "img" */
            Type: T;
        } & (T extends "img" ? {
            Src: string;
        } : {
            /** @example "PDF", "IMG", "FILE" */
            Text: string;
        })
    }[TColumnHeaderIconType];

    export type IColumn<TDataTable> = {
        [CT in TColumnType]: IColumnBase<TDataTable, CT>;
    }[TColumnType];

    type IColumnBase<TDataTable, Type extends TColumnType = "text"> = {
        /** Propiedad a MOSTRAR
         * * Puede mostrar datos de otros niveles del objeto, "CampoEjemplo.Valor" */
        Field: (keyof TDataTable & string);
        /**
         * @default "text"
         */
        Type?: Type;
        /**
         * @default
         * "start": if Type = "text"
         * "end": if Type = "number" | "date"
         * "center": if Type = "img" | "file"
         */
        Align?: TColumnAlignment;
        /**
         * * `string`: Acepta un src de icono
         * * `TColumnHeaderIcon`: Configuración especifica
         */
        Icon?: string | TColumnHeaderIcon;
        /** Texto de encabezado */
        Label: string;
        /** @default == thisconfig.Field */
        LabelLangKey?: string;
        /** Usar _% / 100% -> "10%"*/
        Width?: string;
        /** Usar px -> 100px*/
        MinWidth: string;
        /** Estilo aplicado al td (contenedor) de esta propiedad */
        ClassStyle?: string;
        /** Evento click en la celda (td) correspondiente */
        OnClickInCellCont?: (datum: TDataTable, event: MouseEvent) => void;
        /** Agrega a la cabecera la caracteristica de reordenar la columna
         * @default true */
        IsSortable?: boolean;
        /** Agrega en cabecera botón habilitador de columna pegajosa
         * @default false */
        IsStickable?: boolean;
        /** Es elemento pegajozo?
         * @default false */
        Sticked?: boolean;
        /** @default 1 */
        OrderLevelRows?: number;
        /** @default "auto" */
        OrderTypeParse?: TOrderTypeData;
        /** Propiedad para tomar en cuenta para la comparación de ORDENAMIENTO
         * * Puede mostrar datos de otros niveles del objeto, "CampoEjemplo.Valor"
         * @default this.Field
        */
        OrderField?: (keyof TDataTable & string);

        /** @default ```true``` */
        ResizableWidth?: boolean; // FIXME IMPLEMENTAR Agregar al <style> Anchura de las columnas

        OnLoadHead?: (container: TSelectionHTML<"div">) => void;
    }
        & TColumnAutoFormatConfig<Type>;

    type TOrderTypeData = StringConstructor | NumberConstructor | DateConstructor | BooleanConstructor | "auto";

    type ITableField<TData> = IColumn<TData> & {
        Order: CStatusOrder;
        /** Indicador de la ubcación real del valor
         * @example
         * * this.Field == "Nombre" ? false
         * * this.Field == "ValorObjeto.Nombre" ? true
         */
        IsLowLevel: boolean;
        MaxWidth?: string;
        OriginalMinWidth: string;
    }

    export enum CStatusOrder {
        Asc = 1,
        Desc,
        None
    }

    type TOrderField<T = any> = {
        Type: CStatusOrder;
        Field: keyof T & string;
        IsLowLevel: boolean;
    }

    export interface IDataCheckedAdvanced<TData, TData2 = any, TData3 = any, TData4 = any> {
        Data: TData;
        IsChecked: boolean;
        ChildsChecked: IDataCheckedAdvanced<TData2, TData3, TData4>[];
    }

    interface ISelectedItem<TData, TDataChild> {
        /** En caso de que el dato no se encuentre su valor será undefined */
        readonly Id: TIdType;
        readonly IsCheked: boolean;
        readonly IsEnable: boolean;
        readonly IsCollapsed: boolean;
        //readonly IsCollapse: boolean;
        /** En caso de que el dato no se encuentre su valor será undefined */
        Data: TData;
        GetChildrenData: <TD = TDataChild, TDChild = unknown>() => Map<TIdType, ISelectedItem<TD, TDChild>>;
        GetChildrenDataFiltered: <TD = TDataChild, TDChild = unknown>() => Map<TIdType, ISelectedItem<TD, TDChild>>;
        /** Selecciona un elemento dentro de los datos colapsables del item seleccionado actual */
        SelectChildItem: <TDataChild, TDataChildThreeLv>(id: TIdType) => ISelectedItem<TDataChild, TDataChildThreeLv>;
        /** Cambia el estado "Checkeado" del dato, no actualiza la tabla, hasta que se refresque la tabla */
        CheckItem: (check: boolean) => ISelectedItem<TData, TDataChild>;
        /** Cambia el estado "Habilitado" del dato, no actualiza la tabla, hasta que se refresque la tabla */
        EnableItemRow: (enable: boolean) => ISelectedItem<TData, TDataChild>;
        /** Cambia el estado "Colapsado" de la fila, no actualiza la tabla, hasta que se refresque la tabla */
        CollapseItem: (collapse: boolean) => ISelectedItem<TData, TDataChild>;
        // RemoveFromTable: () => ISelectedItem<TData>;
        /** Refresca los elementos UI de la tabla */
        RefreshTableView: () => ISelectedItem<TData, TDataChild>;
        // Collapse: (collapse: boolean) => void;
    }

    type IDataAsInnerText<TData> = { [k in keyof TData & string]: string }

    /** Nota: Las filas tienden a actualizarse en cada Intersection.
     * * La propiedad almacena un valor booleano en los nodos de las filas (.tr_body)
     * con el que IntersectionObserver puede verificar si d3.join ha actualizado el datum de la fila.
     * * En caso de que el valor sea falso (la fila tiene nuevo datum), IntersectionObserver lanza la aztualización del UI de la fila.
     *
     * @valuetype boolean
    */
    const prop_row_updatedview = "_UpdatedView";
    /** @valuetype TStepJoin */
    const prop_row_stepjoin = "_StepJoin";

    export class Tabla<TDataTable> {
        #floatingOptShowTimeOut: NodeJS.Timeout;
        private props: IPropieties<TDataTable>;
        private elements: IControlesGenerales<TDataTable>;
        private config: IConfig<TDataTable>;
        private levelsConfig: Map<number, ILevelEvaluator>;

        private hasCollapseColumn: boolean;
        private intersectedObserverRowsPrincipal: IntersectionObserver;
        private timeOutToPrincipalRows: NodeJS.Timeout;
        private orderItemsOutTimeOut: boolean;
        private tableIdentifier: string;
        private startResizing: boolean;
        private ctrlFloatingOptions: FloatingOptionsBtns;

        constructor(config: IConfig<TDataTable>) {
            this.props = {
                DataOrigin: [],
                DataTableCheckedMap: new Map(),
                DataOriginTableMap: new Map(),
                DataOriginTableArray: [],
                DataTableFilteredMap: new Map(),
                DataTableFilteredArray: [],
                ReliableCurrentData: true,
                ColumnHeaders: [],
            };
            this.levelsConfig = new Map();
            this.hasCollapseColumn = false;
            idCount++;
            this.tableIdentifier = PREFIX_IDENTIFIER + idCount;
            this.InitElements();
            this.SetConfig(config);
            this.BuildSizeStyleConfig();
            this.BuildTablefFloatingOptions();
            this.ControllerIntersectedObserver();
            this.OriginalControllerResizerObserver();
            this.startResizing = false;
        }

        private InitElements() {
            this.elements = <IControlesGenerales<TDataTable>>{}

            this.elements.DivContent = d3.create<HTMLDivElement>("html:div")
                .classed("tabla_container flex_colcontrol " + this.tableIdentifier, true)
            this.elements.A_DivAcciones = this.elements.DivContent.append<HTMLDivElement>("div")
                .classed("area_acciones", true)
                .classed("hide", true)

            this.elements.A2_DivFiltros = this.elements.DivContent.append<HTMLDivElement>("div")
                .classed("area_filtros", true)
                .classed("hide", true)

            this.elements.B_DivControl = this.elements.DivContent.append<HTMLDivElement>("div")
                .classed("area_control flex_colcontrol", true)

            this.elements.AA_DivMenuOfChecks = this.elements.A_DivAcciones.append<HTMLDivElement>("div")
                .style("height", "100%")
                .style("max-width", "0px")

            this.elements.BA_DivSpaceTabla = this.elements.B_DivControl.append<HTMLDivElement>("div")
                .classed("space_tabla", true)

            this.elements.BA_SpaceTablaStyleSizing = this.elements.BA_DivSpaceTabla.append("style").classed("sizing_table_pref", true)
            this.elements.BA_SpaceTablaSwitchLastCellSizing = this.elements.BA_DivSpaceTabla.append("style").classed("table_switch_last_col", true)

            this.elements.BA_DivSpaceTabla.append("div")
                .classed("dim_width", true);
            this.elements.BAAA_TablaHead = this.elements.BA_DivSpaceTabla.append<HTMLDivElement>("div")
                .classed("rhead", true)
            this.elements.BAAB_TablaBodyA = this.elements.BA_DivSpaceTabla.append<HTMLDivElement>("div")
                .classed("rbodyA", true)
            this.elements.BAAB_TablaBodyB = this.elements.BA_DivSpaceTabla.append<HTMLDivElement>("div")
                .classed("rbodyB", true)

            this.elements.BB_DivSpacePie = this.elements.B_DivControl.append<HTMLDivElement>("div")
                .classed("space_pie hide", true);
        }

        private SetConfig(config: IConfig<TDataTable>) {
            this.config = Object.assign({}, config);

            // CONFIGS POR DEFAULT //
            this.config.EnableRelevanceSelections = (config.EnableRelevanceSelections != null ? config.EnableRelevanceSelections : true);
            this.config.EnableStickyFields = (config.EnableStickyFields != null ? config.EnableStickyFields : false);
            this.config.StickyCheckInRow = (config.StickyCheckInRow != null ? config.StickyCheckInRow : false);
            this.config.HideCheckboxes = (config.HideCheckboxes != null ? config.HideCheckboxes : false);
            this.config.AddNameFieldClassToCells = (config.AddNameFieldClassToCells != null ? config.AddNameFieldClassToCells : false);
            this.config.HideItemWhenItHasNoChildItems = (config.HideItemWhenItHasNoChildItems != null ? config.HideItemWhenItHasNoChildItems : true);
            this.config.DisabledRowsWithMenuInRow = (config.DisabledRowsWithMenuInRow != null ? config.DisabledRowsWithMenuInRow : false);
            this.config.FilterByStrSearch = (config.FilterByStrSearch == null ? "default" : config.FilterByStrSearch);
            this.config.MenuInRowNoCellsToIgnoreWidth = (this.config.MenuInRowNoCellsToIgnoreWidth != null ? this.config.MenuInRowNoCellsToIgnoreWidth : 1);
            this.config.Resizable = false; //TEMPORAL
            //this.config.Resizable = (config.Resizable != null ? config.Resizable : true);

            // CONFIGURACIONES EN NIVELES //
            const AddConfigsLevels = (level: number, evalConfig: ILevelEvaluator) => {
                evalConfig.Level = level;
                evalConfig.IdMember = (evalConfig.IdMember ? evalConfig.IdMember : this.config.IdData);
                evalConfig.ActiveDataOnlyWhenDisplayData = evalConfig.ActiveDataOnlyWhenDisplayData || false;
                this.levelsConfig.set(level, evalConfig);

                if (evalConfig.EvaluatorSubLevel) {
                    AddConfigsLevels(level + 1, <ILevelEvaluator>evalConfig.EvaluatorSubLevel);
                }
            }

            AddConfigsLevels(1, <ILevelEvaluator>{
                IdMember: this.config.IdData as string,
                EvaluatorSubLevel: this.config.EvaluatorAndSubLevelsBuild?.EvaluatorSubLevel,
                AddRowMultiline: this.config.EvaluatorAndSubLevelsBuild?.AddRowMultiline,
                ActiveDataOnlyWhenDisplayData: this.config.EvaluatorAndSubLevelsBuild?.ActiveDataOnlyWhenDisplayData,
                ForceHideLevelRowsWithoutChilds: this.config.EvaluatorAndSubLevelsBuild?.ForceHideLevelRowsWithoutChilds,
                ShowNoContentTagRow: this.config.EvaluatorAndSubLevelsBuild?.ShowNoContentTagRow,
                HideLevelCollapseIndicator: this.config.EvaluatorAndSubLevelsBuild?.HideLevelCollapseIndicator,
                GetOptionsInRowV2: this.config.EvaluatorAndSubLevelsBuild?.GetOptionsInRowV2,
                OnEvalIsCollapsedRow: this.config.EvaluatorAndSubLevelsBuild?.OnEvalIsCollapsedRow,
                OnEvalIsEnableRow: this.config.EvaluatorAndSubLevelsBuild?.OnEvalIsEnableRow,

                OnCollapserChange: this.config.EvaluatorAndSubLevelsBuild?.OnCollapserChange,
                OnCheckedData: this.config.EvaluatorAndSubLevelsBuild?.OnCheckedData,

                OnStepRowTable: this.config.EvaluatorAndSubLevelsBuild?.OnStepRowTable,
                OnStepCellTable: this.config.EvaluatorAndSubLevelsBuild?.OnStepCellTable,
            });

            // SUB CONTROLES DE TABLA EN BASE A LA CONFIGURACIÓN //

            this.elements.BA_DivSpaceTabla.classed("without_checkboxes", this.config.HideCheckboxes);
            this.elements.BA_DivSpaceTabla.classed("without_collapsers", !this.hasCollapseColumn);
            this.elements.BA_DivSpaceTabla.classed("auxpadding", !this.hasCollapseColumn && this.config.HideCheckboxes);

            if (this.config.OptionsTopDefaultV2 || this.config.Title) {
                this.elements.A_DivAcciones.classed("hide", false);

                this.MenuTopDefaultOptions_UpdateOptions();
                this.MenuTopDefaultOptions_UpdateTitle();
            }

            if (this.config.OptionsOfDataCheckV3) {
                this.elements.A_DivAcciones.classed("hide", false);
            }

            this.BuildColumnsConfig();

            this.BuildFilters();

            if (this.config.OnClickSyncTable) {
                let btnSync = this.elements.A_DivAcciones.append("div")
                    .classed("area_sync", true)
                    .append("img")
                    .attr("src", UIUtilIconResources.CGeneral.Sync)
                    .attr("draggable", false);

                btnSync.on("click", async () => {
                    btnSync.classed("anim_syncing", true);
                    let dataToUpdate = await this.config.OnClickSyncTable();
                    this.UpdateData(dataToUpdate);
                    btnSync.classed("anim_syncing", false);
                })
            }

            if (this.config.MinWidth || this.config.Resizable) {
                this.elements.BA_DivSpaceTabla.append("style");
                this.GeneralRowsApplyMinWidth(this.config.MinWidth);
            }

            this.elements.BA_DivSpaceTabla.node().onscroll = (ev: Event) => this.Page_ApplyScrollX();
            this.config.Parent.append<HTMLDivElement>(() => this.elements.DivContent.node());
            this.UpdateColumnHeaders();
        }

        private BuildColumnsConfig() {
            this.props.ColumnHeaders = this.config.RenderColumnHeadings
                .map<ITableField<TDataTable> & { Type?: any }>((item, i, arr) => {
                    let ObjOrder = <ITableField<TDataTable>>{
                        Order: CStatusOrder.None,
                        Sticked: item.Sticked,
                        IsLowLevel: item.Field.split(".").length > 1,
                        OriginalMinWidth: item.MinWidth
                    };
                    item.IsSortable = item.IsSortable === undefined || item.IsSortable === null ? true : item.IsSortable; // default true
                    item.IsStickable = item.IsStickable === undefined || item.IsStickable === null ? false : item.IsStickable; // default false
                    item.Sticked = item.Sticked === undefined || item.Sticked === null ? false : item.Sticked; // default false
                    item.Type = item.Type || "text";

                    if (!item.Align && ["number", "date"].includes(item.Type))
                        item.Align = "end";
                    else if (!item.Align && ["img", "file"].includes(item.Type))
                        item.Align = "center";

                    if (this.config.OrderDefault && this.config.OrderDefault.Field == item.Field) {
                        ObjOrder.Order = this.config.OrderDefault.Type;
                    }
                    item.OrderLevelRows = item.OrderLevelRows || 1;
                    item.OrderTypeParse = item.OrderTypeParse || "auto";
                    item.OrderField = item.OrderField || item.Field;

                    if (item.LabelLangKey === undefined) {
                        item.LabelLangKey = item.Field;
                    }

                    // >> Fix Lang context
                    if (item.LabelLangKey) {
                        let lblTag = this.GetLangContext(item.LabelLangKey);
                        if (lblTag != item.LabelLangKey) {
                            item.Label = lblTag;
                        }
                    }

                    //
                    return { ...item, ...ObjOrder }
                })

            if (this.config.Resizable) {
                let objTableDimConfig = this.GetObjectConfig_LS()
                if (!objTableDimConfig[this.config.IdTabla])
                    objTableDimConfig[this.config.IdTabla] = {};
                if (!objTableDimConfig[this.config.IdTabla]["Columns"]) {
                    this.FillColumnsConfg_LS();
                } else {
                    this.props.ColumnHeaders.forEach(d => {
                        const colCfg = objTableDimConfig[this.config.IdTabla]["Columns"][d.Field]
                        if (colCfg) {
                            const colWidth = colCfg["Width"];
                            const colMinWidth = colCfg["MinWidth"];
                            const colMaxWidth = colCfg["MaxWidth"];
                            if (colWidth) { d.Width = colWidth }
                            else { console.warn("-d", d.Field, "Prop. Width not found") }

                            if (colMinWidth) { d.MinWidth = colMinWidth }
                            else { console.warn("-d", d.Field, "Prop. MinWidth not found") }

                            if (colMaxWidth) { d.MaxWidth = colMaxWidth }
                            else { console.warn("-d", d.Field, "Prop. MaxWidth not found") }
                        } else {
                            console.warn("-d", d.Field, "Column not found")
                        }
                    })
                }
            }
        }

        private FillColumnsConfg_LS() {
            let objTableDimConfig = this.GetObjectConfig_LS();
            if (!objTableDimConfig[this.config.IdTabla]) {
                objTableDimConfig[this.config.IdTabla] = {};
            }
            objTableDimConfig[this.config.IdTabla]["Columns"] = {};
            this.props.ColumnHeaders.forEach(d => {
                objTableDimConfig[this.config.IdTabla]["Columns"][d.Field] = {};
                objTableDimConfig[this.config.IdTabla]["Columns"][d.Field]["Width"] = d.Width;
                objTableDimConfig[this.config.IdTabla]["Columns"][d.Field]["MinWidth"] = d.MinWidth;
                objTableDimConfig[this.config.IdTabla]["Columns"][d.Field]["MaxWidth"] = d.MaxWidth;
            })
            let jsonString = JSON.stringify(objTableDimConfig);
            DataUtilLocalStorage._SetItem("winstatus", "tabledimconfig", jsonString);
        }

        private GetObjectConfig_LS() {
            let strTableDimConfig = DataUtilLocalStorage._GetItem("winstatus", "tabledimconfig")
            return (strTableDimConfig ? JSON.parse(strTableDimConfig) : {})
        }

        private UpdateTableColumnDimConfig_LS(columnName: string) {
            const objTableConfigDim = this.GetObjectConfig_LS();
            if (objTableConfigDim[this.config.IdTabla] && objTableConfigDim[this.config.IdTabla]["Columns"] && objTableConfigDim[this.config.IdTabla]["Columns"][columnName]) {
                const column = this.props.ColumnHeaders.find(d => d.Field == columnName);
                objTableConfigDim[this.config.IdTabla]["Columns"][columnName]["Width"] = column.Width;
                objTableConfigDim[this.config.IdTabla]["Columns"][columnName]["MinWidth"] = column.MinWidth;
                objTableConfigDim[this.config.IdTabla]["Columns"][columnName]["MaxWidth"] = column.MaxWidth;
            }

            let jsonString = JSON.stringify(objTableConfigDim);
            DataUtilLocalStorage._SetItem("winstatus", "tabledimconfig", jsonString)
        }

        private BuildFilters() {
            const hasFilters = this.config.FilterParameters?.length > 0 || this.config.FilterByStrSearch != "none";
            this.elements.A2_DivFiltros.classed("area_filtros flex_rowcontrol", hasFilters).classed("hide", !hasFilters);
            if (hasFilters) {
                let onGetValuesToFilterBySearch: (dato: TDataItemTable<TDataTable>) => string[];
                const paramsFilter = this.config.FilterParameters || [];

                if (this.config.FilterByStrSearch == "default") {
                    onGetValuesToFilterBySearch = (dato) => {
                        if (dato.levelRow == 1) {
                            const setFullFields = new Set([
                                ...this.config.RenderColumnHeadings.map(d => d.Field),
                                ...paramsFilter.map(d => d.Field)
                            ]);
                            return Array.from(setFullFields)
                                .filter(field => (typeof dato.data[field] == "string"))
                                .map(field => (dato.data[field] as unknown as string));
                        }
                        return null;
                    }
                }
                else if (this.config.FilterByStrSearch != "none") {
                    onGetValuesToFilterBySearch = (dato) => {
                        if (dato.levelRow == 1) {
                            return (this.config.FilterByStrSearch as Function)(dato.data);
                        }
                        return null;
                    }
                }
                const mainFilters: IMainFilterItem[] = paramsFilter
                    .map((d) => {
                        let label = d.Label;
                        if (d.LabelLangKey && d.LangModuleKeyInContext) {
                            label = UIUtilLang._GetUIString(d.LangModuleKeyInContext, d.LabelLangKey);
                        } else if (d.LabelLangKey) {
                            label = this.GetLangContext(d.LabelLangKey);
                        }
                        d.Type = d.Type || "text";
                        d.Level = d.Level || 1;

                        return <IMainFilterItemTypeConfig<TMainFilterType>>{
                            Key: d.Field,
                            Type: d.Type,
                            Label: label,
                            Min: (d as IParametroFiltroMap<any, "number">).Min,
                            Max: (d as IParametroFiltroMap<any, "number">).Max,
                            MinDate: (d as IParametroFiltroMap<any, "date">).MinDate,
                            MaxDate: (d as IParametroFiltroMap<any, "date">).MaxDate,
                            Options: (d as IParametroFiltroMap<any, "select">).Options,
                            OnGetAdvancedFilterDraw: (d as IParametroFiltroMap<any, "advanced">).OnGetAdvancedFilterDraw,
                            GetAdvancedFilterValue: (d as IParametroFiltroMap<any, "advanced">).GetAdvancedFilterValue,
                            OnStepAdvancedFilter: (d as IParametroFiltroMap<any, "advanced">).OnStepAdvancedFilter,
                            OnChange: d.OnChange,
                            OnGetValueData: d.Type == "advanced" ? null : (dato: TDataItemTable<TDataTable>) => {
                                if (dato.isChecked || dato.levelRow != d.Level) {
                                    // console.error("El filtro ", d.Field, " no aplica al nivel ", d.Level); // REMOVER
                                    return null;
                                }
                                let values: any[];
                                if (d.OnGetValueToMatch) {
                                    values = d.OnGetValueToMatch(dato.data);
                                } else {
                                    values = [UIUtilGeneral._GetValueLevelsObj(d.Field, dato.data)];
                                }

                                switch (d.Type) {
                                    case "text":
                                        values = values.map(v => (v ? v + "" : null)); // Se ajustan los valores a string
                                        break;
                                    case "number":
                                        break;
                                }
                                return values;
                            },
                        } as IMainFilterItem;
                    });

                if (!this.elements.C_CtrlFiltrosV2) {
                    this.elements.C_CtrlFiltrosV2 = new FilterControlV2({
                        Parent: this.elements.A2_DivFiltros,
                        MainFilters: mainFilters,
                        OnChangeFilters: () => {
                            // console.warn("-d", "Filter change");
                            this.RefreshFilterMainData();
                            this.UpdateDataView(true, true);
                        },
                        OnGetValuesToFilterBySearch: onGetValuesToFilterBySearch
                    });
                }
                this.elements.C_CtrlFiltrosV2._SetMainFilters(mainFilters);
            }
        }

        private OriginalControllerResizerObserver() {
            let lastWidth: number;
            let resizeObserver = UIUtilGeneral._GetResizeObserver((entries) => {
                let trD = this.elements.BAAA_TablaHead.select<HTMLTableRowElement>("tr");
                if (trD.node()) {
                    this.SwitchLastCellColConfig(false)
                    let newWidth = trD.node().scrollWidth; // DOTEST
                    if (lastWidth != newWidth) {
                        lastWidth = newWidth;
                        this.elements.BA_DivSpaceTabla.select(".dim_width")
                            .style("width", lastWidth + "px");
                        // Si el tamaño con scroll del trD es mayor
                        if ((lastWidth - this.elements.BAAA_TablaHead.node().clientWidth) > 1) {
                            this.Page_ApplyScrollX();
                            if (this.config.Resizable) this.GeneralRowsApplyMinWidth(lastWidth); // DOTEST
                        } else {
                            if (this.config.Resizable) this.GeneralRowsApplyMinWidth(this.config.MinWidth); //DOTEST
                        }
                    }
                    if (this.config.Resizable) {
                        const trHeadWidth = Array.from(trD.node().childNodes.values()).reduce<number>((sum, child) => sum + (child as HTMLElement).getBoundingClientRect().width, 0);
                        const dif = lastWidth - trHeadWidth;
                        if (dif > 1) {
                            this.SwitchLastCellColConfig(true)
                        }
                    }
                }
            });
            resizeObserver.observe(this.elements.BA_DivSpaceTabla.node());
        }

        private ControllerIntersectedObserver() {
            this.intersectedObserverRowsPrincipal = new IntersectionObserver(
                (entries) => {
                    entries
                        .forEach((entry) => {
                            // let level = entry.target["_level"];
                            let trBody = d3.select<HTMLDivElement, TDataItemTable<TDataTable>>(entry.target as HTMLDivElement)
                            // tr.classed("hide", !entry.isIntersecting);

                            if (entry.isIntersecting) {
                                trBody//.style("pointer-events", null)
                                    .style("min-height", null);

                                const joinStep = entry.target[prop_row_stepjoin] as TStepJoin;

                                if (entry.target[prop_row_updatedview]) {
                                    return;
                                }
                                entry.target[prop_row_updatedview] = true;


                                let datum = trBody.datum();

                                this.UpdateRowData(trBody, datum, joinStep);
                            }
                            // else {
                            //     trBody.style("pointer-events", "none");
                            // }
                        })
                },
                {
                    root: this.elements.BA_DivSpaceTabla.node(),
                    // threshold: 0
                    // rootMargin: "50px"
                }
            );
        }

        // NOTE INICIO DE DATOS
        private UpdateData(data: Array<TDataTable> = []) {
            let lastReliableData = this.props.ReliableCurrentData;
            this.hasCollapseColumn = false;
            this.props.ReliableCurrentData = true;

            this.props.DataOrigin = data;
            this.props.DataOriginTableMap = this.IteratorUpdateData(1, lastReliableData, data, this.props.DataOriginTableMap, null);
            this.props.DataOriginTableArray = Array.from(this.props.DataOriginTableMap.values());

            Array.from(this.props.DataTableCheckedMap.values())
                .forEach((d) => {
                    if (!d.isChecked || !this.props.DataOriginTableMap.has(d.id)) {
                        console.warn("-d", "CHECK Removiendo elemento", d.id, this.props.DataOriginTableMap.has(d.id), d); // REMOVER // DOTEST
                        this.props.DataTableCheckedMap.delete(d.id);
                    }
                })

            this.RefreshFilterMainData();

            if (this.props.DataTableCheckedMap.size > 0 && this.levelsConfig.get(1).OnCheckedData) {
                const dataChecked = this.GetDataOriginCheckedArray();
                this.levelsConfig.get(1).OnCheckedData(dataChecked, CTypeOriginEvent.OnUpdateDataTable, null, null);
            }

            this.UpdateDataView(true, true);
        }

        private RefreshFilterMainData() {
            this.props.DataTableFilteredArray = this.GetFiltrarDatos(this.props.DataOriginTableArray, this.props.DataOriginTableArray, true);
            this.props.DataTableFilteredMap = new Map(this.props.DataTableFilteredArray.map(d => ([d.id, d])));
            // this.UpdateDataView(true, true);
        }

        /** Evalua antes si el filtro es posible. Retorna un arreglo nuevo */
        private GetFiltrarDatos(dataFull: Map<TIdType, TDataItemTable<any>> | Array<TDataItemTable<any>>, lastFilteredData: TDataItemTable<any>[], enableRowLevel: boolean): TDataItemTable<any>[] {
            let data = Array.isArray(dataFull) ? dataFull : Array.from(dataFull.values());
            let finalData: TDataItemTable<any>[] = [];
            const level = data[0]?.levelRow;

            if (level == 1 && this.config.FilterExtraBeforeFilterTable) {
                data = data.filter(d => this.config.FilterExtraBeforeFilterTable(d.data));
            }

            if (!this.elements.C_CtrlFiltrosV2) {
                finalData = [...data];
            }
            else if (!enableRowLevel) {
                finalData = [...lastFilteredData];
            }
            else if (data.length) {
                finalData = this.elements.C_CtrlFiltrosV2._FilterData(
                    data,
                    (level == 1),
                    this.GetKeysFiltersByLevel(level).map(d => d.Field)
                )
                finalData.forEach(d => {
                    if (d.childDataOriginMap.size || d.childDataFilteredArray.length) {
                        const [item] = d.childDataOriginMap.values();
                        const tieneFiltros = item ? this.config.FilterParameters?.some(d => d.Level == item.levelRow) : false;
                        d.childDataFilteredArray = this.GetFiltrarDatos(d.childDataOriginMap, d.childDataFilteredArray, tieneFiltros ? !d.isChecked : true);
                    }
                });
                const levelConfig = this.levelsConfig.get(level);
                if (this.levelsConfig.has(level + 1) && levelConfig.ActiveDataOnlyWhenDisplayData == false && levelConfig.ForceHideLevelRowsWithoutChilds) {
                    finalData = finalData.filter(d => (d.childDataOriginMap.size && d.childDataFilteredArray.length));
                }
            }
            if (dataFull == this.props.DataOriginTableArray && this.config.OnFilter) setTimeout(() => {
                this.config.OnFilter();
            });
            return finalData;
        }

        private GetKeysFiltersByLevel(level: number): IParametroFiltro<TDataTable>[] {
            if (this.config.FilterParameters) {
                return this.config.FilterParameters
                    .filter(d => (d.Level == level));
            }
            return [];
        }

        /**
        *
        * @param level Nivel actual de items, empieza por el 1
        * @param reliablePastData Los datos anteriores fueron son "seguros"
        * @param newData Nuevos datos del nivel
        * @param lastItemsTable Mapa de datos (del mismo nivel) anteriores
        * @param parentItem Si se está en un nivel inferior el primero, se requiere el item "padre" al cual se suscriben los nuevos datos
        * @returns
        */
        private IteratorUpdateData(level: number, reliablePastData: boolean, newData: (TDataTable | any)[], lastItemsTable: Map<TIdType, TDataItemTable<TDataTable>>, parentItem?: TDataItemTable<TDataTable>) {
            const levelConfig = this.levelsConfig.get(level);
            const idMember = levelConfig.IdMember;
            const nextLevel = level + 1;

            if (!idMember) {
                this.props.ReliableCurrentData = false;
            }

            const lastItemsTableAux = new Map(lastItemsTable);
            lastItemsTable.clear();

            newData.forEach((dat, i) => {
                // CREA INDENTIFICADOR //
                let ID: TIdType;// = ("falseID-" + i);
                if (idMember) {
                    ID = dat[idMember];

                    if (ID === undefined) {
                        ID = "NotFoundId-" + i;
                        this.props.ReliableCurrentData = false;
                        console.warn(this.config.IdData + " (NoFoundId): ", dat);
                        DataUtilAlertBot._SendInfoDelay300ms("Table Id no found", ID);
                    }
                    else if (lastItemsTable.has(ID)) {
                        this.props.ReliableCurrentData = false;
                        let location = `Level: ${level}, index: ${i}, IdData (${idMember}): ${ID}`;
                        console.warn(`Se han encontrado registros con el mismo Identificador de fila -> ${location}`);
                        DataUtilAlertBot._SendInfoDelay300ms("Table Id repited", location);
                    }
                } else {
                    ID = -i;
                }

                // Construir item table nuevo o en base al último existente //
                // let lastSameItem: TDataItemTable<TDataTable>;
                let itemResult: TDataItemTable<any>;

                if (reliablePastData) {
                    itemResult = lastItemsTableAux.get(ID);
                }
                if (itemResult) {
                    (itemResult.data as any) = dat;
                    (itemResult.initialPos as any) = i;
                    itemResult.isRowEnable = levelConfig.OnEvalIsEnableRow ? levelConfig.OnEvalIsEnableRow(dat, itemResult.isRowEnable, i) : true;
                    itemResult.itemParent = parentItem;
                    // itemResult.isCollapsed = levelConfig.OnEvalIsCollapsedRow ? levelConfig.OnEvalIsCollapsedRow(dat, lastSameItem.isCollapsed, i) : true;
                }
                else {
                    itemResult = {
                        id: ID,
                        data: dat,
                        isChecked: (parentItem) ? parentItem.isChecked : false, //Revisar con reyno
                        initialPos: i,
                        isRowEnable: levelConfig.OnEvalIsEnableRow ? levelConfig.OnEvalIsEnableRow(dat, true, i) : true,
                        levelRow: level,
                        isCollapsed: levelConfig.OnEvalIsCollapsedRow ? levelConfig.OnEvalIsCollapsedRow(dat, true, i) : true,
                        childDataOriginMap: new Map(),
                        childDataFilteredArray: [],
                        itemParent: parentItem,
                    }
                }

                // Verificar subniveles //
                if (this.levelsConfig.has(nextLevel) && !levelConfig.ActiveDataOnlyWhenDisplayData) {
                    const parentsArray = this.GetParentsOfItemTable(itemResult)
                        .map(d => d.data);

                    const subData = this.levelsConfig.get(nextLevel)
                        .OnGetData(dat, parentsArray) as any[];

                    itemResult.childDataOriginMap = this.IteratorUpdateData(nextLevel, reliablePastData, (subData || []), itemResult.childDataOriginMap, itemResult);
                    itemResult.childDataFilteredArray = itemResult.childDataFilteredArray
                        .filter(d => !itemResult.childDataOriginMap.has(d.id));

                    if (itemResult.childDataOriginMap.size == 0) {
                        itemResult.isCollapsed = true;
                    } else {
                        this.hasCollapseColumn = true;
                    }
                    // console.log(level, "N subniveles", itemResult.dataCollapser.size);
                } else if (levelConfig.ActiveDataOnlyWhenDisplayData) {
                    this.hasCollapseColumn = true;
                }

                // Verifica estado Check de acuerdo a la info actualizada //
                if (itemResult.isChecked) {
                    itemResult.isChecked = this.GetCheckStatusEvaluated(itemResult);
                }

                lastItemsTable.set(ID, itemResult);
            })

            return lastItemsTable;
        }

        /** Dibuja filas de la página actual
        * @param isAB boolean: hace referencia al orden en que se van a actualizar las areas rBodyA y rBodyB
        * @param orderData Si es true; vuelve a agrupar paginas //  TEMPORAL Comentario
        * @param doOnEndUpdateDataInView ayuda a evitar bucles en caso de invocar un refres de table desde this.config.OnEndUpdateDataInView
        */
        private UpdateDataView(isAB: boolean = true, orderData: boolean = false, doOnEndUpdateDataInView: boolean = true): void {
            // console.debug("TABLE -> UpdateDataView invocado...  ", date.toISOString());
            if (this.timeOutToPrincipalRows) {
                clearTimeout(this.timeOutToPrincipalRows);
            }

            if (!this.orderItemsOutTimeOut && orderData) {
                this.orderItemsOutTimeOut = true;
            }

            this.timeOutToPrincipalRows = setTimeout(() => {
                this.timeOutToPrincipalRows = null;

                // console.debug("TABLE -> UpdateDataView ejecutando...", this.propiedades.DataTable, this.propiedades.DataTable.size, this.propiedades.PagActual);
                if (this.orderItemsOutTimeOut) {
                    this.props.DataTableFilteredArray = this.Order_GetRefreshCurrentOrder(this.props.DataTableFilteredArray, 1);
                    this.orderItemsOutTimeOut = false;
                }

                // let datos: Array<TDataItemTable<TDataTable>> = this.GetFilteredItems(); // auxPaginaData ? auxPaginaData.Data : []
                let datos = this.props.DataTableFilteredArray;
                let dataChecked: TDataItemTable<TDataTable, any>[] = [];

                if (this.config.EnableRelevanceSelections) {
                    datos = datos.filter(d => (!d.isChecked));
                    dataChecked = Array.from(this.props.DataTableCheckedMap.values()).reverse();
                }
                // let dataChecked = Array.from(this.props.DataChecksMap.values()).reverse(); // .sort((a, b) => (a.initialPos - b.initialPos));// this.propiedades.DataChecks.values()

                // console.debug(`TABLE -> UpdateDataView >> TotalData: ${this.propiedades.DataTable.size}, Cheks: ${dataChecked.length}, PageData: ${data.length} Page: ${this.propiedades.PagActual}`);
                let tbodyA = this.elements.BAAB_TablaBodyA
                let tbodyB = this.elements.BAAB_TablaBodyB

                this.elements.BA_DivSpaceTabla.selectAll("tr").classed("tr_selected", false);
                this.elements.BA_DivSpaceTabla.selectAll(".tr_body").interrupt();

                this.UpdateColumnHeaders();

                if (datos.length == 0) {
                    tbodyA
                        .style("max-height", "100%")
                        .style("flex", "auto");
                    tbodyB.classed("hide", true);
                } else {
                    tbodyA
                        .style("max-height", "40%")
                        .style("flex", "none");
                    tbodyB.classed("hide", false);
                }
                if (this.config.EnableRelevanceSelections) {
                    tbodyA.classed("border_bottom_2", dataChecked.length > 0 && datos.length > 0);
                }

                const fnUseAnimation = (cont: TSelectionHTML, data: any[]) => (this["__lastcountdata"] > 100 || this["__lastcountdatachecked"] > 100 || datos.length > 100 || dataChecked.length > 100)
                    ? false
                    : (cont.node().hasChildNodes() || data.length > 0)

                if (isAB) {
                    // console.error("RenderData 1: ", dataChecked);
                    if (this.config.EnableRelevanceSelections) {
                        this.DrawData(dataChecked, tbodyA, 1, fnUseAnimation(tbodyA, datos));
                    }
                    // console.error("RenderData 2: ", data)
                    this.DrawData(datos, tbodyB, 1, fnUseAnimation(tbodyB, dataChecked));
                } else {
                    this.DrawData(datos, tbodyB, 1, fnUseAnimation(tbodyB, dataChecked));
                    if (this.config.EnableRelevanceSelections) {
                        this.DrawData(dataChecked, tbodyA, 1, fnUseAnimation(tbodyA, datos));
                    }
                }

                this.UpdateStickyFields();

                if (this.config.OnEndUpdateDataInView && doOnEndUpdateDataInView) {
                    // this.config.OnEndUpdateDataInView(this.propiedades.PagActualOLD, datos, dataChecked);
                    this.config.OnEndUpdateDataInView(datos, dataChecked);
                }

                this["__lastcountdata"] = datos.length
                this["__lastcountdatachecked"] = dataChecked.length
            }, 150);

            this.elements.BA_DivSpaceTabla.classed("without_collapsers", !this.hasCollapseColumn);
        }

        private BuildRowAndCells(trBody: TSelectionHTML<"div", TDataItemTable<TDataTable>>, level: number) {
            const columnsConfig = this.props.ColumnHeaders;
            const levelConfig = this.levelsConfig.get(level);

            let tr = trBody.append<HTMLTableRowElement>("tr")
                .classed("principal", true)

            // Check por fila
            let tdToCheck = tr.append("td")
                .classed("actioncell checkcell", true)
                .classed("sticky", this.config.StickyCheckInRow);

            if (!levelConfig.RemoveLevelCheckboxes) {
                tdToCheck.append(() => this.Check_GetCheckBoxDrawing());
            }

            tr.append("td")
                .classed("actioncell collapsercell", true)
            // .classed("sticky", this.config.StickyCheckInRow);

            columnsConfig.forEach(item => {
                tr.append("td")
                    .text("◽◽◽")
                    .classed("field", true)
                    .classed(item.Field, true)
                    .style("justify-content", item.Align);
            })

            tr.append<HTMLTableCellElement>("td")
                .classed("auxcell-resize", true)

            tr.append<HTMLTableCellElement>("td")
                .classed("sticky lastcell", true)

            if (level > 1) {
                tr.selectAll("td").style("background", this.GetRGBColorToLevelRow(level));
            }

            return tr;
        }

        private UpdateRowData(rowBody: TSelectionHTML<"div", TDataItemTable<TDataTable>>, dataItemT: TDataItemTable<TDataTable>, typeUpdate: TStepJoin) {
            const columnsConfig = this.props.ColumnHeaders;
            const levelConfig = this.levelsConfig.get(dataItemT.levelRow);
            const nextLevel = dataItemT.levelRow + 1;

            let trRowToDatum: TSelectionHTML<"tr">;

            // >> BUILD ROW & CELLS
            if (rowBody.classed("waiting-build")) {
                trRowToDatum = this.BuildRowAndCells(rowBody, dataItemT.levelRow);
                rowBody.classed("waiting-build", false);
            } else {
                trRowToDatum = rowBody.select<HTMLTableRowElement>(":scope > .principal");
            }

            // >> UPDATE ROW & CELLS
            const tds = (() => {
                if (trRowToDatum.node().firstElementChild instanceof HTMLTableCellElement) {
                    return trRowToDatum.selectAll<HTMLTableCellElement, any>(":scope>.field").nodes(); //trRow.node().cells
                } else {
                    return trRowToDatum.selectAll<HTMLTableCellElement, any>(":scope>.trorigin>.field").nodes(); //trRow.node().cells
                }
            })()
            const ctrlCheckInRow = <CheckBox.ControlSelection>trRowToDatum.select(".checkbox_table"); // this.elements.CC_CtrlsChecks.get(dataItemT.id)

            trRowToDatum.node().onclick = null;

            const UpdateDatumRowBody = () => {
                rowBody.datum(dataItemT);
            }

            if (ctrlCheckInRow.node()) {
                ctrlCheckInRow.node().onclick = null;
                // if (typeUpdate != "exit") {
                ctrlCheckInRow.node().onclick = (e) => {
                    this.Check_CheckItemsData([dataItemT], !dataItemT.isChecked, Table.CTypeOriginEvent.OnClickRowTopCheck);
                    UpdateDatumRowBody();
                    e.stopPropagation();
                }
                //}
                this.Check_UpdateCheckBoxStatus(dataItemT.isChecked, ctrlCheckInRow); //, (typeUpdate == "enter" || typeUpdate == "exit"))
            }
            else if (!levelConfig.RemoveLevelCheckboxes) {
                console.warn("checkInRow NoFound: ", dataItemT);
            }

            if (typeUpdate == "exit") {
                return;
            }

            // const defaultCells = ["checkcell", "collapsercell", "lastcell"];
            const lastClass = "lastcell";
            const nColRequired = (columnsConfig.length)// + defaultCells.length);
            const colsChanged = tds.length != nColRequired;
            if (colsChanged) {
                // console.warn(trRowToDatum.node(), tds)
                // las columnas cambiaron
                tds
                if (tds.length > nColRequired) {
                    // const dataCols = tds.filter((td) => !defaultCells.some(def => (td.classList.contains(def))))
                    const dif = tds.length - nColRequired;
                    for (let i = 0; i < dif; i++) {
                        tds[i].remove();
                    }
                } else {
                    const dif = nColRequired - tds.length;
                    const lastCell = trRowToDatum.select<HTMLTableCellElement>("." + lastClass).node()
                    // .text("◽◽◽")
                    for (let i = 0; i < dif; i++) {
                        const newCell = d3.create("td").classed("field", true).node();
                        lastCell.insertAdjacentElement("beforebegin", newCell);
                    }
                }
            }

            for (let i = 0; i < columnsConfig.length; i++) {
                // UPDATE CELL
                const configCol = columnsConfig[i];
                const td = d3.select(tds[i]);
                let contentContainer = td.select<HTMLDivElement>(".item_cont");

                if (!tds[i]) {
                    // console.warn("field cell no found -> ", dataItemT.id, configCol.Field, dataItemT.data);
                    continue;
                }

                if (td.classed(".lastcell")) {
                    continue
                }

                td.node().onclick = null;

                if (colsChanged) {
                    contentContainer.text(""); // RESET COL
                }

                contentContainer = this.UpdateCellView(levelConfig, configCol, dataItemT, td, contentContainer);

                UIUtilElementBehaviors._EllipsisTextTooltip(contentContainer.node());

                if (configCol.OnClickInCellCont) {
                    contentContainer.node().onclick = (e) => {
                        configCol.OnClickInCellCont(dataItemT.data, e);
                        e.stopPropagation();
                    }
                }
            }

            // >> MENU IN ROW

            if (dataItemT.isRowEnable || this.config.DisabledRowsWithMenuInRow) {
                // if (dataItemT.levelRow == 1) {
                trRowToDatum.node().onclick = (e) => {
                    setTimeout(() => {
                        if (!window.getSelection().toString()) {
                            if (this.config.OnValueSelectRow && dataItemT.levelRow == 1) { // NOTE aun limitado al nivel 1
                                // console.debug("Item Table", dataItemT)
                                const isSelected = trRowToDatum.classed("tr_selected");
                                this.elements.BA_DivSpaceTabla.selectAll("tr").classed("tr_selected", false);
                                trRowToDatum.classed("tr_selected", !isSelected); // Toggle style
                                this.config.OnValueSelectRow(dataItemT.id, dataItemT.data, trRowToDatum.classed("tr_selected"));
                            }
                            if (levelConfig.GetOptionsInRowV2) {
                                this.MenuRowSelectionOptions_Toggle(dataItemT, trRowToDatum);
                            }
                        }
                    }, 80);
                    // e.stopPropagation();
                }
                if (this.MenuRowSelectionOptions_EvalOldAndNewData(dataItemT)) {
                    this.MenuRowSelectionOptions_UpdateData(dataItemT, trRowToDatum);
                }
                // }
            } else {
                if (ctrlCheckInRow.node()) {
                    ctrlCheckInRow.node().onclick = null;
                }
                trRowToDatum.node().onclick = null;
                if (this.MenuRowSelectionOptions_EvalOldAndNewData(dataItemT)) {
                    this.MenuRowSelectionOptions_Remove();
                }
            }

            trRowToDatum.classed("tr_disabled", !dataItemT.isRowEnable);

            // >> COLLAPSER THINGS

            let btnCollapse: TSelectionHTML<"wc-ic-collapse">;
            let errorGetData: Error = null;
            const UpdateViewChilds = async (isFromClick: boolean) => {
                errorGetData = null;
                if (btnCollapse?.node()) {
                    btnCollapse.node()._Collapsed = dataItemT.isCollapsed;
                }
                if (levelConfig.HideLevelCollapseIndicator) {
                    btnCollapse?.classed("hide", true);
                }

                if (!dataItemT.isCollapsed) {
                    if ((!dataItemT.isChecked || isFromClick) && levelConfig.ActiveDataOnlyWhenDisplayData && levelConfig.EvaluatorSubLevel?.OnGetData) {
                        let getData = levelConfig.EvaluatorSubLevel?.OnGetData(dataItemT.data, this.GetParentsOfItemTable(dataItemT).map(d => d.data));
                        let subData = [];
                        if (getData instanceof Promise) {
                            Button._EnableButton(btnCollapse, false);
                            let spinner = trRowToDatum.select(".collapsercell")
                                .append<HTMLSpinnerElement>("wc-spinner")
                                .attr("dim", 20)
                                .attr("border-width", 3)
                                .attr("center", true);
                            let dataResponse = await getData;
                            if (dataResponse instanceof Error) {
                                errorGetData = dataResponse;
                            } else {
                                subData = dataResponse;
                            }
                            spinner.remove();
                            Button._EnableButton(btnCollapse, true);
                        } else {
                            subData = getData;
                        }
                        dataItemT.childDataOriginMap = this.IteratorUpdateData(
                            nextLevel,
                            this.props.ReliableCurrentData,
                            (subData ? subData : []),
                            dataItemT.childDataOriginMap,
                            dataItemT
                        );
                        const chilTieneFiltros = this.config.FilterParameters?.some(d => d.Level == dataItemT.levelRow + 1)
                        dataItemT.childDataFilteredArray = this.GetFiltrarDatos(dataItemT.childDataOriginMap, dataItemT.childDataFilteredArray, chilTieneFiltros ? !dataItemT.isChecked : true)
                    }
                }
                // let dataCollapse = Array.from(dataItemT.dataCollapser.values());
                // console.log(rowBody.node(), dataItemT.isCollapsed, dataCollapse, dataItemT.dataCollapser.size, "AAA", dataItemT.data[this.config.RenderColumnHeadings[0].Field]);

                // const hasDataToShow = (this.GetFilteredItems(dataItemT.childDataOriginMap, true).length > 0);
                const hasDataToShow = (dataItemT.childDataFilteredArray.length > 0);
                if (hasDataToShow) { // dataItemT.dataCollapser.size > 0) {
                    if (!dataItemT.isCollapsed) {
                        dataItemT.childDataFilteredArray = this.Order_GetRefreshCurrentOrder(dataItemT.childDataFilteredArray, nextLevel);
                        this.DrawData(dataItemT.childDataFilteredArray, rowBody, nextLevel);
                    } else {
                        if (dataItemT.childDataFilteredArray.length < 20) {
                            this.DrawData([], rowBody, nextLevel); // DOTEST Pruebas de rendimiento con ambas formas
                        } else {
                            rowBody.selectAll(".tr_body").remove();
                        }
                    }
                } else {
                    // this.DrawData([], rowBody, nextLevel); // DOTEST Pruebas de rendimiento con ambas formas
                    rowBody.selectAll(".tr_body").remove();
                }

                if ((levelConfig.ShowNoContentTagRow || levelConfig.ActiveDataOnlyWhenDisplayData) && !dataItemT.isCollapsed && (!hasDataToShow || errorGetData)) {
                    // Fila desagrupada y sin datos
                    let rowNoDataInfo = rowBody.select<HTMLDivElement>(".info_nodata");
                    if (!rowNoDataInfo.node()) {
                        rowNoDataInfo = rowBody.append("div")
                            .attr("class", "tr_body row_extra info_nodata")

                        rowNoDataInfo.append("tr")
                            .attr("class", "principal")
                            // .style("min-width", this.config.MinWidth + "px")
                            .append("td")
                            .attr("class", "sticky lastcell")
                            .style("min-width", "100%")
                            .style("justify-content", "center", "important")
                            .style("height", "40px")
                            .style("background", this.GetRGBColorToLevelRow(nextLevel))
                            .style("font-weight", "bold")
                            .style("color", errorGetData ? "var(--color_app_red1)" : null)
                            .text(errorGetData ? errorGetData.message : UIUtilLang._GetUIString("general", "sincontenido"));
                    }
                }
            }

            // if (levelConfig.ShowNoContentTagRow || levelConfig.ActiveDataOnlyWhenDisplayData || this.GetFilteredItems(dataItemT.childDataOriginMap, true).length > 0) {
            if (levelConfig.ShowNoContentTagRow || levelConfig.ActiveDataOnlyWhenDisplayData || dataItemT.childDataFilteredArray.length > 0) {
                btnCollapse = trRowToDatum.select(".collapsercell > wc-ic-collapse");
                if (!btnCollapse.node()) {
                    btnCollapse = trRowToDatum.select(".collapsercell").append<HTMLIconCollapseElement>("wc-ic-collapse");
                }

                btnCollapse.node().onclick = async (e) => {
                    dataItemT.isCollapsed = !dataItemT.isCollapsed;
                    UpdateDatumRowBody();
                    UpdateViewChilds(true);
                    if (levelConfig.OnCollapserChange) {
                        levelConfig.OnCollapserChange(dataItemT.isCollapsed, dataItemT.data, trRowToDatum);
                    }
                    e.stopPropagation();
                }
            } else {
                trRowToDatum.select(".collapsercell > wc-ic-collapse").remove();
            }

            UpdateViewChilds(false);

            // MULTILINE THINGS

            if (levelConfig.AddRowMultiline) {
                const trPrincipal = rowBody.select<HTMLTableRowElement>(".principal");
                let datosMultiline = levelConfig.AddRowMultiline.OnGetDataToAddRow(dataItemT.data);

                this.AddRowMultiline(trPrincipal, datosMultiline, dataItemT, levelConfig.AddRowMultiline, colsChanged);
            }

            // STEP ROW TO CONFIG

            // if (this.config.OnStepDelayedItemRowTableV2) {
            if (levelConfig.OnStepRowTable) {
                // this.config.OnStepDelayedItemRowTableV2(rowBody, trRow, dataItemT.data, /* typeUpdate, */ this.config.RenderColumnHeadings, dataItemT, this.extraFeaturesController); //, dataItemT.isRowSelected)
                levelConfig.OnStepRowTable(dataItemT.data, trRowToDatum, rowBody, dataItemT.initialPos, dataItemT.itemParent?.data, this.GetParentsOfItemTable(dataItemT.itemParent).map(d => d.data));
            }
        }

        private UpdateCellView(levelConfig: ILevelEvaluator, configCol: ITableField<TDataTable>, dataItemT: TDataItemTable<TDataTable, any>, td: TSelectionHTML<"td">, contentContainer: TSelectionHTML<"div"> | null): TSelectionHTML<"div"> {
            td.style("justify-content", configCol.Align); // FIXME Agregar al configurador css

            if (!contentContainer?.node()) {
                contentContainer = td.html(`<div class="item_cont"></div>`).select(".item_cont");
            }

            //if (this.config.AddNameFieldClassToCells) {
            configCol.Field.split(".").forEach(strFieldName => {
                td.classed(strFieldName, true);
            })
            //}

            if (contentContainer.node().childElementCount == 0) {
                let value = "";
                if (configCol.IsLowLevel) {
                    value = UIUtilGeneral._GetValueLevelsObj(configCol.Field, dataItemT.data);
                } else {
                    value = dataItemT.data[configCol.Field as any];
                }
                contentContainer.text(this.CellFormater(value, configCol));
            }
            if (levelConfig.OnStepCellTable) {
                levelConfig.OnStepCellTable(contentContainer, dataItemT.data, configCol.Field, dataItemT.initialPos);
            }

            if (configCol.ClassStyle) {
                contentContainer.classed(configCol.ClassStyle, true);
            }
            return contentContainer
        }

        private CellFormater(value: any, configCol: ITableField<TDataTable>): string {
            const isNumber = (configCol.Type == "number" && typeof value == "number")
            let result: string = (value == null ? "" : value) + "";
            if (isNumber) {
                if (configCol.Format == "currency")
                    result = UIUtilFormat._CurrencyFmt(value);
                else if (configCol.Format == "percent")
                    result = UIUtilFormat._PercentFmt(value);
            } else {
                const isDate = (configCol.Type == "date")
                if (isDate) {
                    let dt: Date
                    if (value instanceof Date)
                        dt = value
                    else if (typeof value == "string" || typeof value == "number")
                        dt = new Date(value)
                    let aux = UIUtilTime._DateFormatStandar(dt, configCol.Format)
                    if (aux)
                        result = aux;
                }
            }
            return result;
        }

        private DrawData(data: TDataItemTable<TDataTable>[], tbody: TSelectionHTML<"div">, level: number, useAnimation = true) {
            tbody.selectAll<HTMLDivElement, TDataItemTable<TDataTable>>(":scope > .tr_body")
                .interrupt()
                .data(data, (d, i) => (d.id + ""))
                .join(
                    enter => {
                        let trBody = enter.append<HTMLDivElement>("div")
                            .classed("tr_body", true)
                            .classed("row_extra", (level > 1))
                            .classed("waiting-build", true)
                            .style("min-height", "40px");

                        trBody.each((d, i, trBodyArray) => {
                            (trBodyArray[i][prop_row_stepjoin] as TStepJoin) = "enter";
                            trBodyArray[i][prop_row_updatedview] = false;
                            this.intersectedObserverRowsPrincipal.observe(trBodyArray[i]);
                        })

                        if (useAnimation) {
                            trBody
                                .style("max-height", "0px")
                                .style("overflow", "hidden")
                                .transition()
                                .duration(ANIMATION_DURATION * 2)
                                .style("max-height", "400px")
                                .on("interrupt", (d, i, trArray) => {
                                    Array.from(trArray).forEach((element) => {
                                        d3.select(element)
                                            .style("max-height", null)
                                            .style("overflow", null);
                                    })
                                })
                                .transition()
                                .duration((ANIMATION_DURATION * 2) + 10)
                                .style("max-height", null)
                                .style("overflow", null)
                                .on("interrupt", (d, i, trArray) => {
                                    Array.from(trArray).forEach((element) => {
                                        d3.select(element)
                                            .style("max-height", null)
                                            .style("overflow", null);
                                    })
                                });
                        }

                        return trBody;
                    },
                    update => {
                        return update.each((d, i, trBodyArray) => {
                            d3.select<HTMLDivElement, TDataItemTable<TDataTable>>(trBodyArray[i])
                                .style("max-height", null)
                                .style("overflow", null);

                            (trBodyArray[i][prop_row_stepjoin] as TStepJoin) = "update";
                            trBodyArray[i][prop_row_updatedview] = false;

                            this.intersectedObserverRowsPrincipal.unobserve(trBodyArray[i]);
                            this.intersectedObserverRowsPrincipal.observe(trBodyArray[i]);
                            // this.UpdateRowData(trBody, trBody.select(".principal"), d, "update")
                        });
                    },
                    exit => {
                        this.MenuTopCheckDataOptions_Update();
                        exit.each((d, i, trBodyArray) => {
                            (trBodyArray[i][prop_row_stepjoin] as TStepJoin) = "exit";
                            trBodyArray[i][prop_row_updatedview] = false;

                            this.intersectedObserverRowsPrincipal.unobserve(trBodyArray[i]);
                            this.intersectedObserverRowsPrincipal.observe(trBodyArray[i]);
                        })

                        exit.on("interrupt", (d, i, trArray) => {
                            d3.select(trArray[i]).remove();
                        })

                        if (useAnimation) {
                            exit
                                // .style("max-height", "250px")
                                .style("max-height", (d, i, arrElem) => arrElem[i].clientHeight + "px")
                                .style("overflow", "hidden")
                                .selectAll(".tr_body")
                                .style("overflow", "hidden")

                            exit
                                .transition()
                                .duration(ANIMATION_DURATION)
                                .style("max-height", "0px")
                                .remove();
                        }
                        else {
                            exit.remove();
                        }
                    }
                )
            this.Page_ApplyScrollX();
        }

        private UpdateStickyFields() { // FIXME left de filas nivel > 1
            let headRow = this.elements.BAAA_TablaHead.select<HTMLTableRowElement>("tr")
            let cells = headRow.node().cells;
            for (let cellNode of cells) {
                if (cellNode.cellIndex != 0 && cellNode.cellIndex != cells.length - 1) {
                    let cell = d3.select(cellNode);

                    let nthStrSelChild = ":nth-child(" + (cellNode.cellIndex + 1) + ")"
                    let allFieldCells = this.elements.BA_DivSpaceTabla.selectAll("tr").selectAll<HTMLTableCellElement, any>(":scope > td" + nthStrSelChild + ", :scope > th" + nthStrSelChild)

                    if (cell.classed("sticky")) {
                        let right = 0;
                        let left = 0;
                        let isSumLeft = true;
                        for (let cellNode2 of cells) {
                            if (cellNode2 == cellNode) {
                                isSumLeft = false;
                            } else {
                                if (isSumLeft) {
                                    if (d3.select(cellNode2).classed("sticky")) {
                                        left += cellNode2.getBoundingClientRect().width
                                    }
                                } else {
                                    if (d3.select(cellNode2).classed("sticky")) {
                                        right += cellNode2.getBoundingClientRect().width
                                    }
                                }
                            }
                        }
                        // console.log("dimensiones LR: ", cellNode, left, right)
                        allFieldCells.classed("sticky", true)
                        allFieldCells.style("z-index", null)

                        allFieldCells.style("left", () => left > 0 ? left + "px" : null)

                        allFieldCells.style("right", () => right > 0 ? right + "px" : null)
                    } else {
                        allFieldCells.classed("sticky", false)
                            // .style("z-index", null)
                            .style("left", null)
                            .style("right", null)
                    }
                }
            }
        }

        private AuxLastCellActive: boolean;

        private UpdateColumnHeaderItem(datum: ITableField<TDataTable>, th: d3.Selection<HTMLTableHeaderCellElement, any, HTMLElement, any>, tr: d3.Selection<HTMLTableRowElement, any, HTMLElement, any>) {
            if (th.property("__field") && th.property("__field") != datum.Field) {
                th.classed(th.property("__field"), false);
            }
            th
                .property("__field", datum.Field)
                .classed(datum.Field, true)
                .classed("sticky", datum.Sticked)
                .style("justify-content", datum.Align)

            th.select(".lbl_cont>b")
                .text(datum.Label);

            if (this.config.EnableStickyFields || datum.IsStickable) {
                const eventSticky = (e: MouseEvent) => {
                    // if (e.target == th.select())
                    datum.Sticked = !datum.Sticked;
                    th.classed("sticky", datum.Sticked)
                    this.UpdateStickyFields()
                    e.stopPropagation()
                };

                if (th.select<HTMLElement>(".sticker").node()) {
                    th.select<HTMLElement>(".sticker").node().onclick = eventSticky;
                } else {
                    th.append("div").classed("sticker", true)
                        .append("img")
                        .attr("draggable", false)
                        .attr("src", "image/menu/ic_pin.png") // cambiar ic_pin.png por uno que represente sticker
                        .node().onclick = eventSticky;
                }
            }
            if (datum.IsSortable) {
                const eventOrder = (e: MouseEvent) => {
                    if (e.target == th.select(".sticker").node()) return
                    if (tr.classed("sizing")) return;
                    if (this.GetHasDataFiltered()) {
                        let order = CStatusOrder.Asc;
                        if (datum.Order === CStatusOrder.None) {
                            order = CStatusOrder.Asc;
                        } else if (datum.Order === CStatusOrder.Asc) {
                            order = CStatusOrder.Desc;
                        } else if (datum.Order === CStatusOrder.Desc) {
                            order = CStatusOrder.None;
                        }
                        this.props.ColumnHeaders.forEach(d => d.Order = CStatusOrder.None);
                        datum.Order = order;

                        this.UpdateDataView(true, true);
                        if (this.config.OnChangeOrder) this.config.OnChangeOrder(datum.Field, datum.Order);
                    }
                }
                if (th.select(".order_arrows").node()) {
                    th.node().onclick = eventOrder;
                } else {
                    th.append(() => this.Order_GetArrowsOrderDrawing());
                    th.node().onclick = eventOrder;
                }
            } else {
                th.style("cursor", "default")
            }

            if (datum.Icon) {
                const iconD = datum.Icon;
                const c = th.select(".icon")
                const imgSrc = typeof iconD == "string" ? iconD : (iconD.Type == "img" ? iconD.Src : null);

                let imgEl = c.select<HTMLImageElement>("img").node();
                if (imgSrc && !imgEl) imgEl = c.append("img").node();
                if (imgSrc && imgEl) imgEl.src = imgSrc;
                else if (imgEl) c.select("img").remove();

                let fileEl = c.select<HTMLFileIcoElement>("wc-fileico");
                if (typeof iconD == "object" && iconD.Type == "file") {
                    if (!fileEl.node()) fileEl = c.append<HTMLFileIcoElement>("wc-fileico");
                    fileEl.attr("ext", iconD.Text)
                        .style("width", "19px")
                        .style("height", "24px")
                        .style("border", "1px solid var(--color_borderbox1)")
                        .style("border-radius", "var(--border_radius_base)")
                        // .style("margin", "3px 5px")
                        .attr("ext-color", iconD.Text.toLowerCase() == "file" ? "gray" : null)
                        .property("_LblSize", "6px");
                }
                else if (fileEl.node()) fileEl.remove();
            }
            else th.select(".icon > *").remove();

            this.Order_UpdateArrowsOrderStatus(datum.Order, th);

            if (!this.config.Resizable) return;
            let resizer = th.select<HTMLResizerIndicatorElement>("wc-resizer")

            if (!resizer.node()) {
                resizer = th.append<HTMLResizerIndicatorElement>("wc-resizer")
                    .classed("resizer", true)
            }

            let resizerTimeOut: NodeJS.Timeout;
            resizer
                .attr("min", datum.OriginalMinWidth.replace("px", ""))
                .attr("init-dim", datum.OriginalMinWidth.replace("px", ""));

            resizer.node()._OnStartSizing = () => {
                tr.classed("sizing", true);
            }

            resizer.node()._OnEndSizing = () => {
                // NOTE: Importante el TimeOut para prevenir el ordenamiento en click de <th>
                setTimeout(() => {
                    tr.classed("sizing", false);
                })
            }

            resizer.node()._OnResize = (e) => {
                if (!this.startResizing) this.startResizing = true;
                if (resizerTimeOut) {
                    clearTimeout(resizerTimeOut);
                    resizerTimeOut = null;
                }
                resizerTimeOut = setTimeout(() => {
                    this.SwitchLastCellColConfig(false);
                    resizerTimeOut = null;
                    const i = this.props.ColumnHeaders.findIndex(d => d.Field == datum.Field);
                    this.props.ColumnHeaders[i].Width = e.detail.Width + "px";
                    this.props.ColumnHeaders[i].MinWidth = e.detail.Width + "px";
                    this.props.ColumnHeaders[i].MaxWidth = e.detail.Width + "px";
                    this.BuildSizeStyleConfig();
                    th.style("width", null);
                    th.style("min-width", null);
                    th.style("max-width", null);
                    const trHeadWidth = Array.from(tr.node().childNodes.values()).reduce((sum, child: HTMLElement) => sum + child.getBoundingClientRect().width, 0);
                    const dif = trHeadWidth - this.elements.BA_DivSpaceTabla.node().clientWidth;
                    if (dif > 1) {
                        this.elements.BA_DivSpaceTabla.select<HTMLDivElement>(".dim_width").style("width", (this.elements.BA_DivSpaceTabla.node().clientWidth + dif) + "px");
                        this.GeneralRowsApplyMinWidth(this.elements.BA_DivSpaceTabla.node().clientWidth + dif)
                    } else if (dif < -1) {
                        this.SwitchLastCellColConfig(true)
                        this.elements.BA_DivSpaceTabla.select<HTMLDivElement>(".dim_width").style("width", (this.elements.BA_DivSpaceTabla.node().clientWidth) + "px");
                        this.GeneralRowsApplyMinWidth(this.config.MinWidth);
                    }
                    this.UpdateTableColumnDimConfig_LS(datum.Field)
                }, 200)
            }
        }

        private UpdateColumnHeaders(): void {
            let trD = this.elements.BAAA_TablaHead.select<HTMLTableRowElement>("tr");
            if (!trD.node()) {
                trD = this.elements.BAAA_TablaHead.append<HTMLTableRowElement>("tr")
            }

            let thToCheck = trD.select<HTMLTableCellElement>(".th-master-check")
            if (!thToCheck.node()) {
                thToCheck = trD.append<HTMLTableCellElement>("th")
                    .classed("th-master-check", true)
                    .classed("actioncell checkcell", true);

                thToCheck.classed("sticky", this.config.StickyCheckInRow);
                this.elements.C_CheckCol = <CheckBox.ControlSelection>thToCheck.append(() => this.Check_GetCheckBoxDrawing());
            }

            if (!trD.select(".collapsercell").node()) {
                trD.append("th")
                    .classed("actioncell collapsercell", true)
                    // .classed("sticky", this.config.StickyCheckInRow)
                    .append("wc-ic-collapse")
                    .attr("class", "hide_transparent");
            }

            this.Check_UpdateCheckBoxStatus(this.GetIsAllFilteredEnableDataChecked(), this.elements.C_CheckCol);

            this.elements.C_CheckCol.on("click", () => {
                const datosCheckables = this.GetFilteredDataCheckable().reverse();

                if (datosCheckables.length) {
                    this.Check_CheckItemsData(datosCheckables, true, Table.CTypeOriginEvent.OnClickSuperCheck);
                }
                else if (this.props.DataTableCheckedMap.size) {
                    this.Check_CheckAllData(false, Table.CTypeOriginEvent.OnClickSuperCheck);
                }
            })

            trD.selectAll<HTMLTableCellElement, ITableField<TDataTable>>(".field")
                .data(this.props.ColumnHeaders, (d, i) => i + "")
                .join(
                    enter => {
                        const th = enter.append<HTMLTableCellElement>("th")
                            .style("justify-content", d => d.Align)
                            .classed("field", true);

                        th.append("div").attr("class", "icon");
                        th.append("div").attr("class", "lbl_cont").append("b");

                        return th.each((d, i, arrTh) => {
                            this.UpdateColumnHeaderItem(d, d3.select(arrTh[i]), trD)

                            if (d.OnLoadHead) d.OnLoadHead(d3.select(arrTh[i]));
                        });
                    },
                    update => update.each((d, i, arrTh) => {
                        this.UpdateColumnHeaderItem(d, d3.select(arrTh[i]), trD)
                    }),
                    exit => exit.remove()
                )
            // div extra para menúInRow
            const lastTH = trD.select<HTMLTableCellElement>(".th-last");
            if (!lastTH.node()) {
                trD.append<HTMLTableCellElement>("th")
                    .classed("th-last", true)
                    .style("padding", "0px")
                    .style("max-width", "0px");
            } else {
                lastTH.raise();
            }
        }

        private ResetColumnsDimensions() {
            this.config.RenderColumnHeadings.forEach(d => {
                const i = this.props.ColumnHeaders.findIndex(col => d.Field == col.Field);
                this.props.ColumnHeaders[i].Width = d.Width;
                this.props.ColumnHeaders[i].MinWidth = d.MinWidth;
                this.props.ColumnHeaders[i].MaxWidth = null;
            })

            this.BuildSizeStyleConfig();
            this.FillColumnsConfg_LS();
            this.SwitchLastCellColConfig(false);
            this.GeneralRowsApplyMinWidth(this.config.MinWidth);
            let trD = this.elements.BAAA_TablaHead.select<HTMLTableRowElement>("tr");
            if (trD.node()) {
                let newWidth = trD.node().scrollWidth;
                this.elements.BA_DivSpaceTabla.select(".dim_width")
                    .style("width", newWidth + "px");
            }
        }

        //****************************************************************************************************
        // MENUS TOP DEFAULT
        //****************************************************************************************************

        private MenuTopDefaultOptions_GetControl() {
            if (!this.elements.C_CtrlMenuTopDefaultV2) {
                this.elements.C_CtrlMenuTopDefaultV2 = new MenuFlex({
                    Parent: this.elements.A_DivAcciones,
                    Orientation: "lefttoright",
                    OnChangeParent: (oldParent, newParent) => {
                        if (this.elements.C_CtrlMenuTopDefaultV2["__onparentchanged"]) {
                            this.elements.C_CtrlMenuTopDefaultV2["__onparentchanged"](oldParent, newParent);
                        }
                    },
                    OnShow: () => {
                        if (this.elements.C_CtrlMenuTopDefaultV2["__onshow"]) {
                            this.elements.C_CtrlMenuTopDefaultV2["__onshow"]();
                        }
                    },
                    OnHide: () => {
                        if (this.elements.C_CtrlMenuTopDefaultV2["__onhide"]) {
                            this.elements.C_CtrlMenuTopDefaultV2["__onhide"]();
                        }
                    },
                    OnGetMaxWidthCallback: () => {
                        const totalWidthAvailable = this.elements.A_DivAcciones.node().clientWidth;
                        const widthBtnSync = this.elements.A_DivAcciones.select<HTMLDivElement>(".area_sync").node()?.clientWidth || 0;
                        const widthFixed = totalWidthAvailable - widthBtnSync;
                        return widthFixed < 0 ? 0 : widthFixed;
                    },
                });
                this.elements.C_CtrlMenuTopDefaultV2._ControlContainer
                    .style("background", "var(--color_primary3)");
            }
            return this.elements.C_CtrlMenuTopDefaultV2;
        }

        private MenuTopDefaultOptions_Refresh() {
            if (!this.elements.C_CtrlMenuTopDefaultV2) {
                return null;
            }
            this.MenuTopDefaultOptions_UpdateTitle();
            return this.MenuTopDefaultOptions_UpdateOptions();
        }

        private MenuTopDefaultOptions_UpdateOptions() {
            const menuConfig = this.config.OptionsTopDefaultV2;
            const controlDefault = this.MenuTopDefaultOptions_GetControl();

            if (menuConfig?.Options.length) {
                controlDefault
                    ._SetMaxHorizontalItems(menuConfig.MaxOptionsInRow || 4)
                    ._UpdateMenuOptions(menuConfig.Options.map(d => {
                        let details = d.GetDetails ? d.GetDetails() : true;
                        let enable = (typeof details == "boolean") ? details : (details?.Enabled === undefined ? true : details.Enabled);
                        let description = (typeof details == "boolean") ? null : (details?.Description || null);

                        return {
                            Label: d.Label,
                            Enabled: enable,
                            Description: description,
                            OnClick: () => d.Callback(),
                        }
                    }))

                if (!this.elements.C_CtrlMenuTopOfChecksV2?._Visible && !controlDefault._Visible) {
                    controlDefault._Show();
                }
            } else {
                controlDefault._UpdateMenuOptions([]);
            }

            return controlDefault;
        }

        private MenuTopDefaultOptions_UpdateTitle(title?: string) {
            title = this.config.Title || ""
            const controlDefault = this.MenuTopDefaultOptions_GetControl()
                ._SetDescription(title);

            if (!this.elements.C_CtrlMenuTopOfChecksV2?._Visible && !controlDefault._Visible) {
                controlDefault._Show();
            }
            return controlDefault;
        }

        private MenuTopDefaultOptions_Visible(visible: boolean) {
            const controlDefault = this.elements.C_CtrlMenuTopDefaultV2;
            if (controlDefault) {
                if (visible && !controlDefault._Visible) {
                    controlDefault._Show();
                }
                else if (!visible && controlDefault._Visible) {
                    controlDefault._Hide();
                }
            }
        }

        //****************************************************************************************************
        // MENU TOP CHECK DATA
        //****************************************************************************************************

        private MenuTopCheckDataOptions_GetControl() {
            if (!this.elements.C_CtrlMenuTopOfChecksV2) {
                // this.elements.A_DivAcciones.classed("hide", false);
                this.elements.C_CtrlMenuTopOfChecksV2 = new MenuFlex({
                    Parent: this.elements.AA_DivMenuOfChecks,
                    BtnCloseVisible: true,
                    Orientation: "lefttoright",
                    OnChangeParent: (oldParent, newParent) => {
                        if (this.elements.C_CtrlMenuTopOfChecksV2["__onparentchanged"]) {
                            this.elements.C_CtrlMenuTopOfChecksV2["__onparentchanged"](oldParent, newParent);
                        }
                    },
                    OnGetMaxWidthCallback: () => {
                        const totalWidthAvailable = this.elements.A_DivAcciones.node().clientWidth;
                        const widthBtnSync = this.elements.A_DivAcciones.select<HTMLDivElement>(".area_sync").node()?.clientWidth || 0;
                        const widthFixed = totalWidthAvailable - widthBtnSync;
                        return widthFixed < 0 ? 0 : widthFixed;
                    },
                    OnShow: () => {
                        UIUtilGlobalKeyEvents._SetEscKeyEventCallback(this.elements.C_CtrlMenuTopOfChecksV2._ControlContainer.node(), () => this.elements.C_CtrlMenuTopOfChecksV2._Hide())
                        this.MenuTopDefaultOptions_Visible(false);
                        if (this.elements.C_CtrlMenuTopOfChecksV2["__onshow"]) {
                            this.elements.C_CtrlMenuTopOfChecksV2["__onshow"]();
                        }
                    },
                    OnHide: () => {
                        this.Check_CheckAllData(false, Table.CTypeOriginEvent.OnCloseOptionsOfDataCheck);
                        UIUtilGlobalKeyEvents._RemoveEscKeyEventCallback(this.elements.C_CtrlMenuTopOfChecksV2._ControlContainer.node());
                        this.MenuTopDefaultOptions_Visible(true);
                        if (this.elements.C_CtrlMenuTopOfChecksV2["__onhide"]) {
                            this.elements.C_CtrlMenuTopOfChecksV2["__onhide"]();
                        }
                    }
                });

                this.elements.C_CtrlMenuTopOfChecksV2._ControlContainer
                    .style("background", "var(--color_primary3)");
            }
            return this.elements.C_CtrlMenuTopOfChecksV2;
        }

        private MenuTopCheckDataOptions_Refresh() {
            if (!this.elements.C_CtrlMenuTopOfChecksV2) {
                return null;
            }
            return this.MenuTopCheckDataOptions_Update();
        }

        private MenuTopCheckDataOptions_Update() {
            let fnGetMenuOptions = this.config.OptionsOfDataCheckV3;
            const controlMenuCheckedData = this.MenuTopCheckDataOptions_GetControl();

            if (!fnGetMenuOptions) {
                if (controlMenuCheckedData._Visible) {
                    controlMenuCheckedData
                        ._UpdateMenuOptions([])
                        ._Hide();
                }
                return;
            }

            if (this.props.DataTableCheckedMap.size > 0) {
                const dataChecked = this.GetDataOriginCheckedArray();
                const menuConfig = fnGetMenuOptions(dataChecked);
                const menuFiltered = menuConfig?.Options.filter(d => (d.MultiData !== false || dataChecked.length <= 1)) || [];

                if (!menuConfig && controlMenuCheckedData._Visible) {
                    controlMenuCheckedData._Hide();
                    return;
                }
                controlMenuCheckedData
                    ._SetMaxHorizontalItems(menuConfig?.MaxOptionsInRow || 4)
                    ._UpdateMenuOptions(menuFiltered.map(d => {
                        let details = d.GetDetails ? d.GetDetails(dataChecked) : true;
                        let enable = (typeof details == "boolean") ? details : (details?.Enabled === undefined ? true : details.Enabled);
                        let description = (typeof details == "boolean") ? null : (details?.Description || null);

                        return {
                            Label: d.Label,
                            Enabled: enable,
                            Description: description,
                            OnClick: () => d.Callback(dataChecked),
                        }
                    }))
                    ._SetDescription(dataChecked.length + " " + (
                        dataChecked.length == 1
                            ? UIUtilLang._GetUIString("general", "selected1")
                            : UIUtilLang._GetUIString("general", "selected2")
                    ));

                if (!controlMenuCheckedData._Visible) {
                    controlMenuCheckedData._Show();
                }
            }
            else if (controlMenuCheckedData._Visible) {
                controlMenuCheckedData._Hide();
            }
        }

        //****************************************************************************************************
        // MENU IN ROW
        //****************************************************************************************************

        private MenuRowSelectionOptions_GetControl() {
            if (!this.elements.C_CtrlMenuInRowV2) {
                this.elements.C_CtrlMenuInRowV2 = new MenuFlex({
                    ResizeReference: this.elements.BA_DivSpaceTabla,
                    Orientation: "righttoleft",
                    BtnCloseVisible: false,
                });
            }
            return this.elements.C_CtrlMenuInRowV2;
        }

        private MenuRowSelectionOptions_Remove() {
            this.elements.C_CtrlMenuInRowV2?._Hide();
        }

        private MenuRowSelectionOptions_EvalOldAndNewData(itemTable: TDataItemTable<any>) {
            const idDataEval = itemTable.levelRow + "_" + itemTable.id;
            const idDataControl = this.MenuRowSelectionOptions_GetControl()["__idData"];

            if (idDataControl !== undefined && idDataEval === idDataControl) {
                return true;
            }
            return false;
        }

        private MenuRowSelectionOptions_Refresh() {
            if (!this.elements.C_CtrlMenuInRowV2) {
                return null;
            }
            const controlMenuRow = this.MenuRowSelectionOptions_GetControl();
            const itemTable = controlMenuRow["__data"];

            if (itemTable == null) {
                return null;
            }
            const fnGetMenuOptions = this.levelsConfig.get(itemTable?.levelRow)?.GetOptionsInRowV2;
            const menuConfig = fnGetMenuOptions ? fnGetMenuOptions(itemTable.data) : null;

            if (!menuConfig?.Options.length) {
                controlMenuRow._UpdateMenuOptions([]);
                if (controlMenuRow._Visible) {
                    return controlMenuRow._Hide();
                }
                return controlMenuRow;
            }
            return controlMenuRow
                ._SetMaxHorizontalItems(menuConfig.MaxOptionsInRow || 4)
                ._UpdateMenuOptions(menuConfig.Options.map((d) => {
                    let details = d.GetDetails ? d.GetDetails([itemTable.data]) : true;
                    let enable = (typeof details == "boolean") ? details : (details?.Enabled === undefined ? true : details.Enabled);
                    let description = (typeof details == "boolean") ? null : (details?.Description || null);

                    return {
                        Label: d.Label,
                        Enabled: enable,
                        Description: description,
                        OnClick: () => {
                            d.Callback([itemTable.data]);
                        }
                    }
                }));
        }

        private MenuRowSelectionOptions_UpdateData(itemTable: TDataItemTable<any>, row: TSelectionHTML<"tr">) {
            if (row.select(".trorigin").node()) {
                row = row.select(".trorigin");
            }
            const childsCells = row.node().childNodes as NodeListOf<HTMLTableCellElement>;
            const controlMenuRow = this.MenuRowSelectionOptions_GetControl();

            if (this.props.ReliableCurrentData) {
                controlMenuRow["__idData"] = itemTable.levelRow + "_" + itemTable.id;
                controlMenuRow["__data"] = itemTable;
            } else {
                controlMenuRow["__idData"] = undefined;
                controlMenuRow["__data"] = undefined;
            }
            controlMenuRow
                ._SetParent(d3.select(childsCells[childsCells.length - 1]))
                ._OnParentChanged((oldParent, newParent) => {
                    if (controlMenuRow["__onparentchanged"]) {
                        controlMenuRow["__onparentchanged"](oldParent, newParent);
                    }
                })
                ._OnShow(() => {
                    row.classed("tr_click", true)
                    UIUtilGlobalKeyEvents._SetEscKeyEventCallback(this.elements.C_CtrlMenuInRowV2._ControlContainer.node(), () => this.elements.C_CtrlMenuInRowV2._Hide());

                    if (controlMenuRow["__onshow"]) {
                        controlMenuRow["__onshow"]();
                    }
                })
                ._OnHide(() => {
                    row.classed("tr_click", false);
                    UIUtilGlobalKeyEvents._RemoveEscKeyEventCallback(this.elements.C_CtrlMenuInRowV2._ControlContainer.node());
                    if (controlMenuRow["__idData"] !== undefined) {
                        controlMenuRow["__idData"] = null;
                        controlMenuRow["__data"] = null;
                    }
                    controlMenuRow
                        ._ControlContainer
                        .style("min-width", null);

                    if (controlMenuRow["__onhide"]) {
                        controlMenuRow["__onhide"]();
                    }
                })
                ._SetOnGetMaxWidthCallback(() => Util._GetMaxWidthMenuFlex(row, this.config.MenuInRowNoCellsToIgnoreWidth, this.elements.BA_DivSpaceTabla, this.MenuRowSelectionOptions_GetControl()));

            return this.MenuRowSelectionOptions_Refresh();
        }

        private MenuRowSelectionOptions_Toggle(itemTable: TDataItemTable<any>, row: TSelectionHTML<"tr">) {
            if (row.select(".trorigin").node()) {
                row = row.select(".trorigin");
            }
            const controlMenuRow = this.MenuRowSelectionOptions_GetControl();
            if (row.classed("tr_click")) {
                controlMenuRow._Hide();
            }
            else if (this.GetNoDatosCheck() == 0) {
                if (controlMenuRow._Visible) {
                    controlMenuRow._Hide();
                }
                this.MenuRowSelectionOptions_UpdateData(itemTable, row);
                if (controlMenuRow._ItemsLength) {
                    controlMenuRow._Show();
                }
            }
        }

        //****************************************************************************************************
        // MENUS GENERAL
        //****************************************************************************************************

        private MenuControlPublicShare<Type extends ("row" | "top-check" | "top-default")>(type: Type) {
            let controlMenu: MenuFlex;
            switch (type) {
                case "row":
                    controlMenu = this.MenuRowSelectionOptions_GetControl();
                    break;
                case "top-check":
                    controlMenu = this.MenuTopCheckDataOptions_GetControl();
                    break;
                case "top-default":
                    controlMenu = this.MenuTopDefaultOptions_GetControl();
                    break;
            }
            type IMenuControlShare = {
                _Hide: () => IMenuControlShare;
                _Show: () => IMenuControlShare;
                _SetParent: (parent: TSelectionHTML) => IMenuControlShare;
                _SetDescription: (description: string) => IMenuControlShare;
                _Refresh: () => IMenuControlShare;
                _SetOrientation: (val: MenuFlex.TOrientation) => IMenuControlShare;
                _UpdateConfig: Type extends "top-default" ? (config: ITableMenuTopDefaultConfig) => IMenuControlShare : never;
                _SetMaxHorizontalItems: Type extends "top-default" ? never : (maxItems: number) => IMenuControlShare;
                _UpdateMenuOptions: Type extends "top-default" ? never : (options: MenuFlex.IMenuItemConfig[]) => IMenuControlShare;
                _OnHide: (callback: () => void) => IMenuControlShare;
                _OnShow: (callback: () => void) => IMenuControlShare;
                _OnParentChanged: (callback: (oldParent: TSelectionHTML, newParent: TSelectionHTML) => void) => IMenuControlShare;
                get _IsVisible(): boolean;
                get _ControlContainer(): TSelectionHTML;
            }

            const refControl: IMenuControlShare = {
                _Refresh: () => {
                    switch (type) {
                        case "top-default":
                            this.MenuTopDefaultOptions_Refresh();
                            break;
                        case "top-check":
                            this.MenuTopCheckDataOptions_Refresh();
                            break;
                        case "row":
                            this.MenuRowSelectionOptions_Refresh();
                            break;
                    }
                    // controlMenu.met_Refresh();
                    return refControl;
                },
                _UpdateConfig: (type == "top-default"
                    ? (config) => {
                        this.config.OptionsTopDefaultV2 = config;
                        this.MenuTopDefaultOptions_UpdateOptions();
                        return refControl;
                    }
                    : undefined) as Type extends "top-default" ? (config: ITableMenuTopDefaultConfig) => IMenuControlShare : never,
                _SetMaxHorizontalItems: (type != "top-default"
                    ? (maxItems) => {
                        controlMenu._SetMaxHorizontalItems(maxItems);
                        return refControl;
                    }
                    : undefined) as Type extends "top-default" ? never : (maxItems: number) => IMenuControlShare,
                _UpdateMenuOptions: (type != "top-default"
                    ? (options) => {
                        controlMenu._UpdateMenuOptions(options);
                        return refControl;
                    }
                    : undefined) as Type extends "top-default" ? never : (options: MenuFlex.IMenuItemConfig[]) => IMenuControlShare,
                _Hide: () => {
                    controlMenu._Hide();
                    return refControl;
                },
                _Show: () => {
                    controlMenu._Show();
                    return refControl;
                },
                _SetParent: (parent) => {
                    controlMenu._SetParent(parent);
                    return refControl;
                },
                _SetDescription: (description: string) => {
                    controlMenu._SetDescription(description);
                    return refControl;
                },
                _SetOrientation: (value) => {
                    controlMenu._SetOrientation(value);
                    return refControl;
                },
                _OnHide: (callback: () => void) => {
                    controlMenu["__onhide"] = callback;
                    return refControl;
                },
                _OnShow: (callback: () => void) => {
                    controlMenu["__onshow"] = callback;
                    return refControl;
                },
                _OnParentChanged: (callback: (oldParent: TSelectionHTML, newParent: TSelectionHTML) => void) => {
                    controlMenu["__onparentchanged"] = callback;
                    return refControl;
                },
                _IsVisible: null,
                _ControlContainer: null,
            }

            return UIUtilGeneral._ObjectAddGetters(refControl, {
                _IsVisible: () => controlMenu._Visible,
                _ControlContainer: () => controlMenu._ControlContainer,
            });
        }

        //****************************************************************************************************
        // CHECK THINGS
        //****************************************************************************************************

        private Check_GetCheckBoxDrawing() {
            return CheckBox._GetCheckElement().node();
        }

        private Check_UpdateCheckBoxStatus(checked: boolean, svg: CheckBox.ControlSelection, inicializar: boolean = false) {
            CheckBox._UpdateCheckStatus(svg, checked, inicializar);
        }

        private Check_CheckItemById(IDs: Array<string | number>, check: boolean) {
            const itemsFinded: TDataItemTable<TDataTable>[] = IDs
                .filter(idSearch => this.props.DataOriginTableMap.has(idSearch))
                .map(idFind => this.props.DataOriginTableMap.get(idFind));

            if (itemsFinded.length > 0) {
                this.Check_CheckItemsData(itemsFinded, check, Table.CTypeOriginEvent.OnExternalEvent);
            }
        }

        /** Evalua checheo antes de realizarlo (Todas los check siguen las mismas reglas):
        * * Un item solo puede ser checkeado cuando está FILTRADO (Preferentemente).
        * * Un item solo puede ser checkeado cuando está HABILITADO (Preferentemente).
        * * Un item SIEMPRE puede ser descheckeado.
        */
        private Check_DoCheck(d: TDataItemTable<any>, val: boolean, force: boolean = false): boolean {
            // if (d.isFiltered) {
            if (d.isRowEnable) {
                d.isChecked = val;
                return true;
            } else if (!val) {
                d.isChecked = val;
                return true;
            }
            return false;
        }

        /** Todos los items recibidos tienen que ser del mismo nivel y mismo ItemPadre (Temporal) */
        private Check_CheckItemsData(dataItemsT: TDataItemTable<TDataTable | any>[], checkValue: boolean, originEvent: Table.CTypeOriginEvent) {
            const finalItemsT = dataItemsT;
            let doCheckLv1 = false;

            const fn_ValidaItemParent = (itemTable: TDataItemTable<any>) => { // FIXME no lanza evento OnCheckedData de los padres inmediatos al lv 1 cuando el item checkeado viene de un nivel más bajo, ej. lv 3
                if (itemTable.levelRow == 1) {
                    if (itemTable.isChecked) {
                        this.props.DataTableCheckedMap.set(itemTable.id, itemTable);
                    } else {
                        this.props.DataTableCheckedMap.delete(itemTable.id);
                    }
                    doCheckLv1 = true;
                }
                else {
                    const initParentCheckStatus = itemTable.itemParent.isChecked;
                    if (itemTable.isChecked) {
                        this.Check_DoCheck(itemTable.itemParent, true);
                    } else {
                        // itemTable.itemParent.isChecked = Boolean(this.GetItemValidChecked(itemTable.itemParent)?.IsChecked);
                        const parentHasChildsChecked = this.GetItemDataHasCheckedChild(itemTable.itemParent);
                        // Boolean(this.GetItemValidChecked(itemTable.itemParent)?.IsChecked);
                        this.Check_DoCheck(itemTable.itemParent, parentHasChildsChecked);
                    }
                    if (initParentCheckStatus != itemTable.itemParent.isChecked) {
                        fn_ValidaItemParent(itemTable.itemParent);
                    }
                }
            }

            const fn_CheckeaChilldItems = (childData: Array<TDataItemTable<any>>): boolean => {
                if (childData.length) {
                    let changeCheckedStatus = false;
                    childData.forEach(dChild => {
                        // if (!checkValue && this.elements.C_CtrlFiltros) {
                        //     d.isFiltered = this.elements.C_CtrlFiltros.met_IsItemValid(d, false, false); // TEMPORAL // REMOVER
                        // }
                        if (this.Check_DoCheck(dChild, checkValue)) {
                            changeCheckedStatus = true;
                            fn_CheckeaChilldItems(dChild.childDataFilteredArray);
                        }
                    })
                    return changeCheckedStatus;
                }
                return false;
            }

            finalItemsT.forEach(dataItemT => {
                // dataItemT.isChecked = checkValue;
                if (this.Check_DoCheck(dataItemT, checkValue)) {
                    fn_ValidaItemParent(dataItemT);

                    if (fn_CheckeaChilldItems(dataItemT.childDataFilteredArray)) {
                        if (this.levelsConfig.get(dataItemT.levelRow + 1).OnCheckedData) {
                            let dataChecked = this.GetDataOriginCheckedFromMap(dataItemT.childDataOriginMap);
                            let parentsD: any[] = [];
                            parentsD.push(...this.GetParentsOfItemTable(dataItemT).map(d => d.data));
                            this.levelsConfig.get(dataItemT.levelRow + 1).OnCheckedData(dataChecked, originEvent, dataItemT.data, parentsD);
                        }
                    }
                    // if (!checkValue) {
                    //     // Si el item es descheckeado, los filtros a los childs se DESCONGELAN
                    //     dataItemT.childDataFilteredArray = this.GetFiltrarDatos(dataItemT.childDataOriginMap, dataItemT.childDataFilteredArray, true);
                    // }
                }
            })
            // Remueve menú de fila
            this.MenuRowSelectionOptions_Remove();

            if (doCheckLv1 && this.levelsConfig.get(1).OnCheckedData) {
                const dataChecked = this.GetDataOriginCheckedArray();
                this.levelsConfig.get(1).OnCheckedData(dataChecked, originEvent, null, null);
            }

            if (!checkValue)
                this.RefreshFilterMainData();

            this.MenuTopCheckDataOptions_Update();
            this.UpdateDataView(!checkValue, true);
        }

        /** Check/Uncheck all Data */
        private Check_CheckAllData(check: boolean, originEvent: Table.CTypeOriginEvent) {
            this.Check_CheckItemsData(this.props.DataOriginTableArray, check, originEvent);
        }

        //****************************************************************************************************
        // ORDER THINGS
        //****************************************************************************************************
        //#region

        private Order_GetArrowsOrderDrawing(): SVGSVGElement {
            let control = d3.create<SVGSVGElement>("svg")
                .attr("viewBox", "0 0 100 100")
                .classed("order_arrows", true);
            control.append<SVGPathElement>("path")
                .attr("d", "M 24.187 7.5 L 39.83 7.5 L 39.83 65.758 L 24.187 65.758 L 24.187 7.5 Z  M 9 65.758 L 32.009 92.5 L 55.017 65.758 L 9 65.758 L 9 65.758 Z")
                .attr("fill", "#A8A8A8")
                .classed("arrow_asc", true);
            control.append<SVGPathElement>("path")
                .attr("d", "M 75.83 92.5 L 60.187 92.5 L 60.187 34.242 L 75.83 34.242 L 75.83 92.5 Z  M 91.017 34.242 L 68.009 7.5 L 45 34.242 L 91.017 34.242 L 91.017 34.242 Z")
                .attr("fill", "#A8A8A8")
                .classed("arrow_desc", true);
            return control.node()
        }

        private Order_UpdateArrowsOrderStatus(status: CStatusOrder, th: TSelectionHTML<"th">) {
            let svg = th.select<SVGElement>(".order_arrows")
            switch (status) {
                case CStatusOrder.None:
                    th.transition().duration(ANIMATION_DURATION)
                        .style("color", null)
                    svg.select(".arrow_asc").transition().duration(ANIMATION_DURATION)
                        .attr("fill", "#A8A8A8");
                    svg.select(".arrow_desc").transition().duration(ANIMATION_DURATION)
                        .attr("fill", "#A8A8A8");
                    break;
                case CStatusOrder.Asc:
                    th.transition().duration(ANIMATION_DURATION)
                        .style("color", "var(--color_text1)") // FIXME HACER CLASE
                    svg.select(".arrow_asc").transition().duration(ANIMATION_DURATION)
                        .attr("fill", "#A8A8A8");
                    svg.select(".arrow_desc").transition().duration(ANIMATION_DURATION)
                        .attr("fill", "#484849");
                    break;
                case CStatusOrder.Desc:
                    th.transition().duration(ANIMATION_DURATION)
                        .style("color", "var(--color_text1)") // FIXME HACER CLASE
                    svg.select(".arrow_asc").transition().duration(ANIMATION_DURATION)
                        .attr("fill", "#484849");
                    svg.select(".arrow_desc").transition().duration(ANIMATION_DURATION)
                        .attr("fill", "#A8A8A8");
                    break;
            }
        }

        private Order_GetRefreshCurrentOrder<T extends TDataItemTable<TDataTable>>(datos: T[], level: number): T[] {
            const headerOrder = this.props.ColumnHeaders
                .find(d => (d.Order != CStatusOrder.None));
            const orderType = headerOrder?.Order || CStatusOrder.None;
            const dataSortable = datos;
            let dataResult: T[];

            switch (orderType) {
                case CStatusOrder.Asc:
                    if (headerOrder.OrderLevelRows == level) { // NOTE VALIDAR todo el switch a partir de una implementación de ordenamiento multiple
                        dataSortable.sort((a, b) => this.Order_EvalValueItems(headerOrder, d3.ascending, a.data, b.data));
                    }
                    break;
                case CStatusOrder.Desc:
                    if (headerOrder.OrderLevelRows == level) {
                        dataSortable.sort((a, b) => this.Order_EvalValueItems(headerOrder, d3.descending, a.data, b.data));
                    }
                    break;
                case CStatusOrder.None:
                    dataSortable.sort((a, b) => d3.ascending(a.initialPos, b.initialPos));
                    break;
            }
            if (level == 1 && this.config.ItemGrouperField) {
                dataResult = [];
                d3Group(dataSortable, d => d.data[this.config.ItemGrouperField])
                    .forEach((itemsG, key) => {
                        itemsG.forEach(d => {
                            dataResult.push(d);
                        })
                    })
            }
            else {
                dataResult = dataSortable;
            }
            return dataResult;
        }

        private Order_EvalValueItems(headerOrder: ITableField<TDataTable>, /* levelConfig: ILevelEvaluator,*/ funcToOrder: ((a: d3.Primitive, b: d3.Primitive) => number), dataA: any, dataB: any): number {
            const field = headerOrder.OrderField;
            const isLowLevel = headerOrder.OrderField.split(".").length > 1;
            const typeV = headerOrder.OrderTypeParse;

            let aValue: d3.Primitive = isLowLevel ? UIUtilGeneral._GetValueLevelsObj(field, dataA) : dataA[field];
            let bValue: d3.Primitive = isLowLevel ? UIUtilGeneral._GetValueLevelsObj(field, dataB) : dataB[field];

            switch (typeV) {
                case Date:
                    aValue = aValue ? UIUtilTime._GetValidDate(aValue as (string | Date)) : null;
                    bValue = bValue ? UIUtilTime._GetValidDate(bValue as (string | Date)) : null;
                    break;
                case String:
                    aValue = aValue ? String(aValue).toLowerCase() : "";
                    bValue = bValue ? String(bValue).toLowerCase() : "";
                    break;
                case Number:
                    aValue = aValue !== undefined ? Number(aValue) : 0;
                    bValue = bValue !== undefined ? Number(bValue) : 0;
                    break;
                case Boolean:
                    aValue = Boolean(aValue);
                    bValue = Boolean(bValue);
                    break;
                default:
                    // VALIDA: TIPOS DE DATOS GUALES
                    let ambosNumeros = (typeof aValue == "number" && typeof bValue == "number");
                    let ambosCadenas = false;
                    let ambosFechas = false;

                    if (!ambosNumeros) {
                        ambosCadenas = (typeof aValue == "string" && typeof bValue == "string");
                        if (!ambosCadenas) {
                            ambosFechas = (aValue instanceof Date && bValue instanceof Date);
                        }
                    }

                    // AJUSTA A TIPOS DE DATOS IGUALES (STRING)
                    if (!ambosNumeros && !ambosCadenas && !ambosFechas) {
                        aValue = aValue == null ? (typeof bValue == "number" ? 0 : "") : aValue;
                        bValue = bValue == null ? (typeof aValue == "number" ? 0 : "") : bValue;
                    }

                    if (typeof aValue == "string" && typeof bValue == "string") {
                        aValue = aValue.toLowerCase();
                        bValue = bValue.toLowerCase();
                        // if (!isNaN(aValue as any) && !isNaN(bValue as any)) {
                        //     aValue = Number(aValue);
                        //     bValue = Number(bValue);
                        // }
                    }
                    break;
            }

            return funcToOrder(aValue, bValue);
        }

        //#endregion

        // ********************************************************************************
        // PAGE THINGS
        // ********************************************************************************
        //#region

        private Page_ApplyScrollX() {
            let elem = this.elements.BA_DivSpaceTabla.node()
            this.elements.BAAA_TablaHead.node().scrollLeft = elem.scrollLeft;
            this.elements.BAAB_TablaBodyA.node().scrollLeft = elem.scrollLeft;
            this.elements.BAAB_TablaBodyB.node().scrollLeft = elem.scrollLeft;
        }

        // ********************************************************************************
        // SIZE THINGS
        // ********************************************************************************
        private GeneralRowsApplyMinWidth(newWidth: number) {
            this.elements.BA_DivSpaceTabla.select("style:not(.sizing_table_pref,.table_switch_last_col)")
                .html(`
                                    .${this.tableIdentifier} .tr_body,
                                    .${this.tableIdentifier} .rhead>tr,
                                    .${this.tableIdentifier} .dim_width {
                                        min-width: ${newWidth}px !important;
                                    }
                                `.trim());
        }

        private SwitchLastCellColConfig(auxLastCellActive: boolean) {
            // console.warn("SwitchLastCellColConfig", auxLastCellActive)
            this.AuxLastCellActive = auxLastCellActive;
            let style = ``;
            const gral = `.${this.tableIdentifier} > .area_control > .space_tabla`
            style += `
                ${gral} .rbodyA > tr > td.auxcell-resize,
                ${gral} .rbodyA > tr > th.auxcell-resize,
                ${gral} .rbodyA .tr_body > tr > td.auxcell-resize,
                ${gral} .rbodyA .tr_body > tr > th.auxcell-resize,
                ${gral} .rbodyA .tr_body > tr > tr > td.auxcell-resize,
                ${gral} .rbodyA .tr_body > tr > tr > th.auxcell-resize,
                ${gral} .rbodyB > tr > td.auxcell-resize,
                ${gral} .rbodyB > tr > th.auxcell-resize,
                ${gral} .rbodyB .tr_body > tr > td.auxcell-resize,
                ${gral} .rbodyB .tr_body > tr > th.auxcell-resize,
                ${gral} .rbodyB .tr_body > tr > tr > td.auxcell-resize,
                ${gral} .rbodyB .tr_body > tr > tr > th.auxcell-resize {
                    max-width: ${this.AuxLastCellActive ? 'unset !important' : ''};
                }
            `
            this.elements.BA_SpaceTablaSwitchLastCellSizing.html(style);
        }

        private BuildSizeStyleConfig() {
            let style = ``;
            this.props.ColumnHeaders.forEach((val, i, arr) => {
                style += `.${this.tableIdentifier} .${val.Field} {
                                width: ${val.Width};
                                min-width: ${val.MinWidth};
                                max-width: ${val.MaxWidth}
                            }\n`
            })
            this.elements.BA_SpaceTablaStyleSizing.html(style);
        }

        private BuildTablefFloatingOptions() {
            // NOTE: eventualmente la condición podría cambiar, para que existan opciones no relacionadas a Resizable
            if (this.config.Resizable) {
                this.ctrlFloatingOptions = new FloatingOptionsBtns({
                    Items: [
                        {
                            Description: UIUtilLang._GetUIString("table", "reset_dims"),
                            Enable: true,
                            OnSelect: () => {
                                this.ResetColumnsDimensions();
                            },
                            Src: UIUtilIconResources.CGeneral.Dimensions,
                        },
                    ],
                    Parent: this.elements.BAAA_TablaHead,
                    ElementToHover: this.elements.BAAA_TablaHead,
                    OnHide: () => {
                        if (this.#floatingOptShowTimeOut != null) {
                            clearTimeout(this.#floatingOptShowTimeOut);
                            this.#floatingOptShowTimeOut = null;
                        }
                    }
                })

                this.elements.BAAA_TablaHead.node().onmouseenter = e => {
                    if (this.#floatingOptShowTimeOut == null) {
                        this.#floatingOptShowTimeOut = setTimeout(() => {
                            if (!this.ctrlFloatingOptions._IsVisible()) {
                                this.ctrlFloatingOptions._Show();
                            }
                            this.#floatingOptShowTimeOut = null
                        }, 1000)
                    }
                }

                this.elements.BAAA_TablaHead.node().onmouseleave = e => {
                    if (this.#floatingOptShowTimeOut != null) {
                        clearTimeout(this.#floatingOptShowTimeOut);
                        this.#floatingOptShowTimeOut = null;
                    }
                }
            }
        }

        //#endregion

        // ********************************************************************************
        // PRIVATE GETs
        // ********************************************************************************
        //#region

        private GetLangContext(stringKey: string) {
            if (this.config.LangModuleKeyInContext) {
                return (UIUtilLang._GetUIString(this.config.LangModuleKeyInContext, stringKey) || stringKey);
            }
            return stringKey;
        }

        /** @returns Enabled and No checked filtered data */
        private GetFilteredDataCheckable() {
            return this.props.DataTableFilteredArray
                .filter(d => (d.isRowEnable && !d.isChecked));
        }

        private GetIsAllFilteredEnableDataChecked() {
            return (
                this.props.DataTableCheckedMap.size > 0
                && this.GetFilteredDataCheckable().length == 0
            );
        }

        /** Devuelve el número de datos checkeados del nivel 1 */
        private GetNoDatosCheck(): number {
            return this.props.DataTableCheckedMap ? this.props.DataTableCheckedMap.size : 0;
        }

        private GetHasDataFiltered(): boolean {
            return this.props.DataTableFilteredMap.size > 0;
        }

        /** Retorna Array convertido de Map de datos checkeados */
        private GetDataOriginCheckedFromMap(itemTableChilds: Map<TIdType, TDataItemTable<any>>): Array<TDataTable | any> {
            return Array.from(itemTableChilds.values())
                .filter(d => d.isChecked)
                .map(d => d.data);
        }

        private GetDataOriginCheckedArray() {
            return Array.from(this.props.DataTableCheckedMap.values())
                .map(d => d.data);
        }

        private GetDataViewArray(datos: TDataItemTable<TDataTable, any>[]): IDataAsInnerText<TDataTable>[] {
            const container = !this.elements.BAAB_TablaBodyA.classed("hide") ? this.elements.BAAB_TablaBodyA : this.elements.BAAB_TablaBodyB
            const datosFinal: IDataAsInnerText<TDataTable>[] = []
            datos.forEach(dataItemT => {
                const levelConfig = this.levelsConfig.get(dataItemT.levelRow);
                const dataView = <IDataAsInnerText<TDataTable>>{}
                const rowBody = container.append("div").attr("class", "tr_body")
                const trRowToDatum: TSelectionHTML<"tr"> = this.BuildRowAndCells(rowBody, dataItemT.levelRow);
                const tds = (() => {
                    if (trRowToDatum.node().firstElementChild instanceof HTMLTableCellElement) {
                        return trRowToDatum.selectAll<HTMLTableCellElement, any>(":scope>.field").nodes(); //trRow.node().cells
                    } else {
                        return trRowToDatum.selectAll<HTMLTableCellElement, any>(":scope>.trorigin>.field").nodes(); //trRow.node().cells
                    }
                })()
                this.props.ColumnHeaders.forEach((columnConfig, i) => {
                    const td = d3.select(tds[i])
                    const contentContainer = this.UpdateCellView(levelConfig, columnConfig, dataItemT, td, null)
                    dataView[columnConfig.Field] = contentContainer.node().innerText;
                })
                datosFinal.push(dataView)
                rowBody.remove()
            })
            return datosFinal
        }

        private GetItemDataHasCheckedChild(itemT: TDataItemTable<any>): boolean {
            const checkedItemIndex = Array.from(itemT.childDataOriginMap.values())
                .findIndex(d => d.isChecked);

            return checkedItemIndex > -1;
        }

        /** Evalua los estados isChecked del item y sus subdatos y retorna información referente,
        * * Si ni el item ni sus descendientes están checkeados, retorna null
        * * Si el dato no tiene desendientes, la propiedad "ChildsChecked" es null */
        private GetItemValidChecked(itemT: TDataItemTable<any>) {
            let itemRes: IDataCheckedAdvanced<TDataTable> = {
                Data: itemT.data,
                IsChecked: itemT.isChecked,
                ChildsChecked: null
            }
            if (itemT.childDataOriginMap?.size > 0) {
                itemRes.ChildsChecked = Array.from(itemT.childDataOriginMap.values())
                    .map(dT => this.GetItemValidChecked(dT))
                    .filter(dC => (dC !== null));

                return ((itemRes.ChildsChecked.length > 0) ? itemRes : null);
            }
            else if (itemRes.IsChecked) {
                return itemRes;
            }
            else {
                return null;
            }
            // return ((itemRes.IsChecked || itemRes.ChildsChecked?.length > 0) ? itemRes : null);
        }

        /** Evalua si el CheckedStatus conserva su valor */
        private GetCheckStatusEvaluated(itemT: TDataItemTable<any>): boolean {
            const res = this.GetItemValidChecked(itemT);
            return res ? res.IsChecked : false;
        }

        /** Retorna todos los datos checkeados, incluye childs */
        private GetDataAndChildsDataChecked() {
            let result: IDataCheckedAdvanced<TDataTable>[] = [];

            this.props.DataTableCheckedMap.forEach(d => {
                let itemAux = this.GetItemValidChecked(d);
                if (itemAux !== null) {
                    result.push(itemAux);
                }
            })
            return result;
        }

        /** Retorna información y métodos relacionados con los estados del dato encontrado */
        private GetSelectItem<TData, TDataChild>(id: TIdType, collectionData: Map<TIdType, TDataItemTable<TData>>, itemFinded_?: TDataItemTable<TData>) {
            const itemFinded = itemFinded_ ? itemFinded_ : collectionData.get(id);
            const selectItem: ISelectedItem<TData, TDataChild> = {
                Id: itemFinded?.id,
                Data: itemFinded?.data,
                IsCheked: (itemFinded ? itemFinded.isChecked : false),
                IsEnable: (itemFinded ? itemFinded.isRowEnable : false),
                IsCollapsed: (itemFinded ? itemFinded.isCollapsed : true),
                // ChildrenData: (itemFinded ? new Map(Array.from(itemFinded.dataCollapser.values()).map(d => ([d.id, this.GetSelectItem<TDataChild, any>(undefined, undefined, d)]))) : new Map()),
                GetChildrenData: () => (itemFinded ? new Map(Array.from(itemFinded.childDataOriginMap.values()).map(d => ([d.id, this.GetSelectItem<TDataChild, any>(undefined, undefined, d)]))) : new Map()),
                GetChildrenDataFiltered: () => (itemFinded ? new Map(itemFinded.childDataFilteredArray.map(d => ([d.id, this.GetSelectItem<TDataChild, any>(undefined, undefined, d)]))) : new Map()),
                SelectChildItem: (id) => {
                    return this.GetSelectItem<any, any>(id, ((itemFinded && itemFinded.childDataOriginMap) ? itemFinded.childDataOriginMap : new Map()));
                },
                CheckItem: (check) => {
                    if (itemFinded) itemFinded.isChecked = check;
                    return selectItem;
                },
                EnableItemRow: (enable) => {
                    if (itemFinded) itemFinded.isRowEnable = enable;
                    return selectItem;
                },
                CollapseItem: (collapse: boolean) => {
                    if (itemFinded) itemFinded.isCollapsed = collapse;
                    return selectItem;
                },
                // RemoveFromTable: () => {
                //     collectionData.delete(id);
                //     return item;
                // },
                RefreshTableView: () => {
                    this.UpdateDataView();
                    return selectItem;
                }
            }
            return selectItem;
        }

        /** Retorna arreglo de itemsParent en orden ascendente de acuerdo al nivel del itemTable,
        * * NOTE: [0] == PadreInmediato */
        private GetParentsOfItemTable(itemTable: TDataItemTable<any>): TDataItemTable<any>[] {
            let parents: TDataItemTable<any>[] = [];
            const SearchParents = (itemT: TDataItemTable<any>) => {
                parents.push(itemT);
                if (itemT.itemParent) {
                    SearchParents(itemT.itemParent);
                }
            }
            if (itemTable?.itemParent) {
                SearchParents(itemTable.itemParent);
            }
            return parents;
        }

        private GetRGBColorToLevelRow(level: number) {
            let backgroundColor = 255 - ((level - 1) * 10);
            return `rgb(${backgroundColor}, ${backgroundColor}, ${backgroundColor})`;
        }

        //#endregion

        // ********************************************************************************
        // PUBLIC PROPERTIES
        // ********************************************************************************

        //#region

        get _Control() {
            return this.elements.DivContent;
        }

        /** Menú ubicado en la parte superior de la tabla, con las opciones configuradas como por default */
        get _MenuTopDefault() {
            return this.MenuControlPublicShare("top-default");
        }

        /** Menú ubicado en la parte superior de la tabla, cada vez que hay datos checkeados (sólo si está incluido en la configuración) */
        get _MenuTopOfDataChecked() {
            return this.MenuControlPublicShare("top-check");
        }

        /** Menú ubicado en la fila clickeada siempre que no haya ningún dato checkeado (el menú es visible e invisible con cada click) */
        get _MenuInRow() {
            return this.MenuControlPublicShare("row");
        }

        get _DataCheckedLength() {
            return this.props.DataTableCheckedMap.size
        }

        get _dataChecked(): Array<TDataTable> {
            return this.GetDataOriginCheckedArray();
        }

        get _DataAndChildsDataChecked() {
            return this.GetDataAndChildsDataChecked();
        }

        get _data() {
            return this.props.DataOrigin;
        }

        get _dataFiltered() {
            return this.props.DataTableFilteredArray.map(d => d.data);
        }

        /** Retorna en un arreglo el texto de cada columna con el valor de `innerText` de la celda correspondiente por dato */
        get _DataCheckedInnerText(): IDataAsInnerText<TDataTable>[] {
            const dataToInnerText = Array.from(this.props.DataTableCheckedMap.values())
            return this.GetDataViewArray(dataToInnerText)
        }

        /** Retorna en un arreglo el texto de cada columna con el valor de `innerText` de la celda correspondiente por dato */
        get _DataFilteredInnerText(): IDataAsInnerText<TDataTable>[] {
            const dataToInnerText = this.props.DataTableFilteredArray
            return this.GetDataViewArray(dataToInnerText)
        }

        get _InfoColumns(): TInfoColumns<TDataTable> {
            return this.config.RenderColumnHeadings;
        }

        get _CurrentSearchText(): string {
            return this.elements.C_CtrlFiltrosV2?._CurrentSearchText || "";
        }

        get _CurrentOrder() {
            let order = this.props.ColumnHeaders
                .find(d => (d.Order != CStatusOrder.None));
            return {
                Field: order?.Field,
                Type: order?.Order,
            }
        }

        get _ColumnsDimConfig() {
            return this.props.ColumnHeaders.map(d => ({ Field: d.Field, Width: d.Width, MinWidth: d.MinWidth, MaxWidth: d.MaxWidth }))
        }

        //#endregion

        // ********************************************************************************
        // PUBLIC METHODS
        // ********************************************************************************

        //#region

        public _UpdateData(data: Array<TDataTable>) {
            this.UpdateData(data);
            return this;
        }

        public _RefreshFilter() {
            this.RefreshFilterMainData();
            this.UpdateDataView();
            return this;
        }

        public _SetSearchText(value: string) {
            this.elements.C_CtrlFiltrosV2._SetSearchText(value);
            return this;
        }

        public _UpdateTitle(title: string) {
            this.config.Title = title;
            this.MenuTopDefaultOptions_UpdateTitle(title);
            return this;
        }

        public _HideCheckboxes(hide: boolean) {
            this.config.HideCheckboxes = hide;
            this.elements.BA_DivSpaceTabla.classed("without_checkboxes", hide);
            this.UpdateDataView();
            return this;
        }

        /** Refresca los elementos UI de la tabla
        * @param doOnEndUpdateDataInView ayuda a evitar bucles en caso de invocar un refres de table desde this.config.OnEndUpdateDataInView
        */
        public _RefreshView(firstAreaToUpdate: "A" | "B" = "A", doOnEndUpdateDataInView = true) {
            this.UpdateDataView(firstAreaToUpdate == "A", false, doOnEndUpdateDataInView);
            return this;
        }

        /** Checkea todos los items encontrados incluyendo los desplegables (si existen) */
        public _CheckItemsByID(IDs: (Array<TIdType> | TIdType), check: boolean) {
            this.Check_CheckItemById((Array.isArray(IDs) ? IDs : [IDs]), check);
            return this;
        }

        /** Checkea todos los items incluyendo los desplegables (si existen) */
        public _CheckAllItems(check: boolean) {
            this.Check_CheckAllData(check, Table.CTypeOriginEvent.OnExternalEvent);
            return this;
        }

        /** Retorma una selección de un item de la tabla comenzando por los datos del nivel 1 */
        public _SelectItemData<TDataChild>(id: TIdType): ISelectedItem<TDataTable, TDataChild> {
            return this.GetSelectItem<TDataTable, TDataChild>(id, this.props.DataTableFilteredMap);
        }

        public _SetColumns(columns: IColumn<TDataTable>[]) {
            this.config.RenderColumnHeadings = columns;
            this.BuildColumnsConfig();
            this.UpdateDataView();
            this.BuildSizeStyleConfig();
            return this;
        }

        public _SetIdTable(idTabla: string) {
            this.config.IdTabla = idTabla;
        }

        public _SetMainFilters(filters: IParametroFiltro<TDataTable>[]) {
            this.config.FilterParameters = filters;
            this.BuildFilters();
            return this;
        }

        //#endregion

        // ********************************************************************************
        // EXTRA FEATURES
        // ********************************************************************************

        private AddRowMultiline<TData>(trPrincipal: TSelectionHTML<"tr">, datos: any[], datoParent: TDataItemTable<any>, config: IRowMultilineConfig<any, any>, resetView = false) {
            let templateTr = "";
            let columns = this.props.ColumnHeaders;

            if (trPrincipal.select(".trorigin").node()) {
                templateTr = trPrincipal.select(".trorigin").html();
            } else {
                templateTr = trPrincipal.html();

                let tds = trPrincipal
                    .style("flex-direction", "column")
                    .selectAll("td");
                tds.remove();
                let originalTr = trPrincipal.append("tr")
                    .classed("trorigin", true)
                    .style("border-bottom", "none");

                tds.each((d, i, arrElem) => {
                    originalTr.append(() => arrElem[i])
                })
            }

            let updateTr = (tr: TSelectionHTML<"tr">, datum: TData, step: TStepJoin) => {
                let tds = tr.selectAll<HTMLTableCellElement, any>(".field").nodes();
                let isEnable = true;
                let parents = [];
                if (config.OnStepCell || config.OnStepNewTrs) {
                    parents.push(...this.GetParentsOfItemTable(datoParent).map(d => d.data));
                }
                columns.forEach((d, i) => {
                    let td = d3.select(tds[i])
                    let content = td.html(`<div class="item_cont"></div>`).select<HTMLDivElement>(".item_cont"); // FIXME
                    // let content = td.select<HTMLDivElement>(".item_cont").text("");
                    // if (!content.node()) {
                    //     content = td.append("div").classed("item_cont", true);
                    // }
                    if (config.OnStepCell) {
                        config.OnStepCell(content, datum, d.Field, datoParent.data, parents);
                    }
                })
                if (config.OnStepNewTrs) {
                    isEnable = config.OnStepNewTrs(datum, tr, datoParent.data, parents);
                }
                tr.classed("tr_disabled", !isEnable);
            }

            if (resetView) {
                trPrincipal.selectAll<HTMLTableRowElement, TData>(":scope > .trcopy").remove();
            }
            trPrincipal.selectAll<HTMLTableRowElement, TData>(":scope > .trcopy").data(datos).join(
                enter => {
                    let tr = enter.append("tr")
                        .classed("trcopy", true)
                        .html(templateTr)
                        .style("border-bottom", "none");

                    tr.select(".lastcell .menuflex").remove();

                    tr.selectAll(".actioncell").select("svg").classed("hide_transparent", true);

                    tr.selectAll(":scope > td:not(.lastcell)")
                        .style("padding-top", "5px", "important")
                        .style("padding-bottom", "5px")

                    tr.each((d, i, arrElem) => {
                        updateTr(d3.select(arrElem[i]), d, "enter");
                        return false;
                    })
                    return tr
                },
                update => update.each((d, i, arrElem) => {
                    updateTr(d3.select(arrElem[i]), d, "update");
                    return false;
                }),
                exit => exit.each((d, i, arrElem) => {
                    updateTr(d3.select(arrElem[i]), d, "exit");
                    return false;
                }).remove()
            )
        }
    }
}
