import * as d3 from "d3";

type TID<TD, TId extends keyof TD> = TD[TId];
type TOriginEvent = "userInput" | "background";

interface IComponentItem<TData, TId extends keyof TData> {
    Id: TID<TData, TId>;
    Data: TData;
    Selected: boolean;
    Color: string;
}

interface IConfigCircleItemsComponent<TData> {
    Parent?: HTMLElement;
    Data?: TData[];
    IdData: keyof TData & string;
    OnMouseEnter?: (dato: TData) => void;
    OnMouseLeave?: (dato: TData) => void;
    OnMouseClick?: (dato: TData) => void;

    OnGetCircleTag: (dato: TData) => string;
    /** Array<[tag, text]> -> label.text("tag: text") */
    OnGetContentToTooltip: (dato: TData) => Array<[string, string]>;
    /** Es invocado por cualquier forma de seleccionar o deseleccionar un item: Por click de usuario, Por seleccion sin interacción de usuario */
    OnSelectItemsEvent?: (datosSeleccionados: TData[], originEvent: TOriginEvent) => void;
    Colores?: string[];
    /** @default 85 px */
    ItemDimention?: number;
}

export class ComponentCircleItems<TData, TId extends keyof TData> {

    private containerD3: TSelectionHTML<"div">;
    private dataItems: Map<TID<TData, TId>, IComponentItem<TData, TId>>;
    private config: IConfigCircleItemsComponent<TData>;
    private status_Enable: boolean;

    constructor(config: IConfigCircleItemsComponent<TData>) {
        this.config = {
            ...{
                ItemDimention: 85,
                Data: [],
                Colores: []
            },
            ...config
        };
        this.status_Enable = true;
        this.dataItems = new Map();

        this.DATA_UpdateDataItems(config.Data);
        this.UI_Build();
        this.UI_RefreshCirculos();
    }

    private DATA_UpdateDataItems(data: TData[] = []) {
        let beforeItems = new Map(this.dataItems);
        let lastSelectedItems = this.DATA_GetItemsSelected();

        let indexColor = 0;
        this.dataItems = new Map(
            data.map<[TID<TData, TId>, IComponentItem<TData, TId>]>((d) => {
                let idItem = d[this.config.IdData as string] as TID<TData, TId>;
                let itemCircle: IComponentItem<TData, TId> = beforeItems.get(idItem);
                let color = this.config.Colores[indexColor];

                if (!color) {
                    indexColor = 0;
                    color = this.config.Colores[indexColor];
                }

                if (!itemCircle) {
                    itemCircle = {
                        Id: idItem,
                        Color: color,
                        Data: d,
                        Selected: false,
                    }
                }
                itemCircle.Data = d;
                itemCircle.Color = color;

                indexColor++;
                return [idItem, itemCircle]
            })
        )

        if (lastSelectedItems.length != this.DATA_GetItemsSelected().length) {
            // -> Se lanza onSelect event si el núm. de datos nuevos seleccionados con respecto a los anteriores a la actualización cambia
            if (this.config.OnSelectItemsEvent) {
                this.config.OnSelectItemsEvent(this.DATA_GetItemsSelected(), "background");
            }
        }
    }

    private DATA_GetItemsSelected() {
        return Array.from(this.dataItems.values())
            .filter(d => (d.Selected))
            .map(d => d.Data);
    }

    private UI_Build() {
        this.containerD3 = (this.config.Parent ? d3.select(this.config.Parent).append("div") : d3.create("div"))
            .classed("circleitems_component", true);

        this.containerD3.append("div")
            .attr("class", "header");

        this.containerD3.append("div")
            .attr("class", "content")
    }

    private UI_RefreshCirculos(): void {
        let datos = Array.from(this.dataItems.values());

        this.containerD3.select(".content")
            .selectAll<HTMLDivElement, IComponentItem<TData, TId>>(":scope > .item")
            .data(datos, (d, i) => i + "")
            .join(
                enter => {
                    let item: TSelectionHTML<"div", IComponentItem<TData, TId>> = enter.append("div")
                        .attr("class", "item");
                    item.append("div")
                        .attr("class", "circle")
                        .style("width", (this.config.ItemDimention == 85 ? null : this.config.ItemDimention))
                        .style("height", (this.config.ItemDimention == 85 ? null : this.config.ItemDimention))
                        .append("div")
                        .append("span");

                    item.select(".circle")
                        .append("wc-tooltip")
                        .attr("max-width", "200px")
                        .attr("position", "button");

                    return this.UI_UpdateItem(item);
                },
                update => this.UI_UpdateItem(update),
                exit => exit.remove()
            );
    }

    private UI_UpdateItem(items: TSelectionHTML<"div", IComponentItem<TData, TId>>) {
        items.each((d, i, arr) => {
            let itemDiv = (d3.select(arr[i]) as TSelectionHTML<"div", IComponentItem<TData, TId>>)
                .classed("selected", d.Selected);

            itemDiv.select(".circle")
                .style("background-color", d.Color)
                .style("box-shadow", (!d.Selected && !this.status_Enable ? "none" : null), "important")
                .style("cursor", (this.status_Enable ? null : "not-allowed"));

            itemDiv.select("span")
                .text(this.config.OnGetCircleTag(d.Data));

            // -> EVENTS HANDLER
            if (this.config.OnMouseEnter) {
                itemDiv.on("mouseenter", () => this.config.OnMouseEnter(d.Data));
            }
            if (this.config.OnMouseLeave) {
                itemDiv.on("mouseleave", () => this.config.OnMouseLeave(d.Data));
            }

            if (this.status_Enable) {
                itemDiv.on("click", () => {
                    d.Selected = !d.Selected;
                    // console.log("click", d)

                    if (this.config.OnMouseClick) {
                        this.config.OnMouseClick(d.Data);
                    }
                    if (this.config.OnSelectItemsEvent) {
                        this.config.OnSelectItemsEvent(this.DATA_GetItemsSelected(), "userInput");
                    }

                    this.UI_RefreshCirculos();
                })
            } else {
                itemDiv.on("click", null);
            }

            // -> UPDATE TOOLTIP
            itemDiv.select("wc-tooltip")
                .html(this.UI_GetContentTooltip(d.Data));
            // let tooltip = itemDiv.node()["_tooltip"] as controlD3.Tooltip<IComponentItem<TData, TId>>;
            // if (tooltip) {
            //     tooltip.met_ReplaceContent(this.UI_GetContentTooltip(d.Data))
            // } else {
            //     tooltip = new controlD3.Tooltip<IComponentItem<TData, TId>>({
            //         Elemento: itemDiv,
            //         contenido: this.UI_GetContentTooltip(d.Data),
            //         Position: controlD3.CPosition.Bottom,
            //         MouseMove: true
            //     });
            //     tooltip.prop_delayCreate = 800;
            //     itemDiv.node()["_tooltip"] = tooltip;
            // }
        })
        return items;
    }

    private UI_GetContentTooltip(dato: TData): string {
        // let content = d3.create("div").classed("tooltip_content", true);
        let content = "";

        let args: Array<[string, string]> = this.config.OnGetContentToTooltip(dato);
        for (let d of args) {
            if (d[1]) {
                if (content != "") {
                    content += "<br>";
                }
                content += `<b>${d[0]}:</b> ${d[1]}`
            }
            // content.append("label").text(d[0] + ": ").append("label").text(d[1]);
        }
        return content; //.node();
    }

    get _ControlContainer() {
        return this.containerD3;
    }

    get _HeaderContainer() {
        return this.containerD3.select<HTMLDivElement>(".header");
    }

    get _DataSelected() {
        return this.DATA_GetItemsSelected();
    }

    set _EnableControl(val: boolean) {
        this.status_Enable = val;
        this.UI_RefreshCirculos();
    }

    public _UpdateItems(datos: TData[]) {
        this.config.Data = datos;
        this.DATA_UpdateDataItems(datos);
        return this;
    }

    /** Invoca a OnSelectItemsEvent si está configurado */
    public _SelectItems(selected: boolean, ...ids: TID<TData, TId>[]) {
        ids.forEach(id => {
            if (this.dataItems.has(id)) {
                this.dataItems.get(id).Selected = selected;
            }
        });
        if (this.config.OnSelectItemsEvent) {
            this.config.OnSelectItemsEvent(this.DATA_GetItemsSelected(), "background");
        }
        return this;
    }

    public _ItemColor(id: TID<TData, TId>, color: string) {
        if (this.dataItems.has(id)) {
            this.dataItems.get(id).Color = color;
        }
        return this;
    }

    public _UI_RefreshCirculos() {
        this.UI_RefreshCirculos();
        return this;
    }

    public _ItemHasSelected(id: TID<TData, TId>): boolean {
        return Boolean(this.dataItems.get(id)?.Selected);
    }
}
