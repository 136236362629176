import * as d3 from "d3";
import { Entidad } from "../../data/Entidad";
import { _ObtenerCalendarios } from "../../data/modulo/Calendario";
import { _DiccEscuela } from "../../data/modulo/Escuela";
import { _AgregarEventos, _EditarEvento, _EliminarEvento, _ObtenerConfiguracionInfo, _ObtenerEventosAgrupadosPorIdEvento, _ObtenerEventosPorIdsCalendarios, ICalendariosToSend } from "../../data/modulo/EventoCalendario";
import { DataModuloMain } from "../../data/ModuloMain";
import { DateV2 } from "../../util/DateV2";
import _L, { _HttpMsgV2 } from "../../util/Labels";
import { VentanaBase } from "../controlD3/AVentanaBase";
import { CalendarioGridV2 } from "../controlD3/CalendarioGridV2";
import { CCalendarioGridV2Vista, ICGrupo } from "../controlD3/CalendarioGridV2Base";
import { FormGenerator, IAtributosSelectMaterial } from "../controlD3/Formulario";
import { ModalThings } from "../controlD3/ModalThings";
import { NotificacionV2 } from "../controlD3/NotificacionV2";
import { SelectV2 } from "../controlD3/SelectV2";
import { HTMLProgressElement } from "../controlWC/ProgressComponent";
import { UIUtilTime } from "../util/Time";
import { UIUtilViewData } from "../util/ViewData";
import ic_sync from '/image/iconos/ic_sync.svg?raw';
import { group as d3Group } from "d3-array";

// import CAccionPermiso = Entidad.CAccionPermiso;
// import IGrupo = Entidad.IGrupo;

import CTipoEntidad = Entidad.CCalendarioTipoEntidad;
// type ICalendario = Entidad.ICalendario;
type IEventoCalendario = Entidad.IEventoPorCalendario;

interface IEventToGetBlocks extends Omit<Entidad.IEventoConfig, "Inicio" | "Fin" | "FinRecurrencia"> {
    IdsCalendarios?: number[];
    FechaInicio: Date;
    FechaFin: Date;
    FechaFinRecurrencia: Date;
}

interface IEventInBlock extends Pick<IEventoCalendario, "Nombre" | "Inicio" | "Fin"> { }

interface IEventForm extends Pick<Entidad.IEventoConfig, "Nombre" | "IsAllDay" | "TipoRecurrencia" | "VarianteRecurrencia" | "TipoFinRecurrencia" | "NRecurrencias"> {
    IdCalendarios?: number[];
    FechaUnicaYMD: string;
    FechaInicioYMD: string;
    FechaFinRecurrenciaYMD: string;
    HoraInicioHM: string;
    FechaFinYMD: string;
    HoraFinHM: string;
    ReferenceBlock: Entidad.IEventoPorCalendario
}

export class UIVentanaCalendarios extends VentanaBase {
    private calendar: CalendarioGridV2;
    private ctrlProgress: HTMLProgressElement;
    private datos: {
        calendariosList: Entidad.ICalendario[];
        eventosList: Entidad.IEventoPorCalendario[];
    }

    constructor(content: d3.Selection<HTMLDivElement, undefined, HTMLElement, any>, modulo: Entidad.CModulo) {
        super(content, modulo);
        this.datos = {
            calendariosList: [],
            eventosList: [],
        }
        setTimeout(() => {
            this.windowContent.classed("win_calendario", true);
            this.ctrlProgress = this.windowContent.append<HTMLProgressElement>("wc-progress").node();
            this.calendar = new CalendarioGridV2<IEventoCalendario>({ ID: "calendarios_1" })
                ._SetParent(this.windowContent)
                ._SetFocusDate(new Date())
                ._OnInputEvent(evento => {
                    this.OpenModal_AgregarEvento(<Partial<IEventoCalendario>>{
                        IdEscuela: 0,
                        IdCalendario: evento.IdCalendario,
                        Inicio: evento.Inicio?.toString(),
                        Fin: evento.Fin?.toISOString(),
                    });
                })
                ._DrawEventPreview(({ Descripcion, Inicio, Fin, IdCalendario }, container) => {
                    const cal = this.datos.calendariosList.find(d => IdCalendario == d.Id);
                    const escuela = _DiccEscuela.get(cal?.IdEscuela);
                    const calColor = this.calendar._GetCalendarColor(IdCalendario);
                    const calColorGlass = this.calendar._GetCalendarColor(IdCalendario, .4);
                    const isFullDay = this.calendar._EventEvalFullDay(Inicio, Fin);

                    container.innerHTML = `
                    <div class="ev_tag">
                        <div class="cal_color" style="background-color:${calColor}"></div>
                        <b class="ev_name">${Descripcion || `(${_L("calendar.sintitulo")})`}</b>
                    </div>
                    <div class="cal_date">
                        <div>${UIUtilTime._DateFormatStandar(Inicio, "d MMM yyyy")}</div>
                        ${isFullDay
                            ? _L("calendar.fullday")
                            : `<div>
                                <span> ${UIUtilTime._DateFormatStandar(Inicio, "h12:mm")} </span> -
                                <span> ${UIUtilTime._DateFormatStandar(Fin, "h12:mm")} </span>
                            </div>`
                        }
                    </div>
                    <div class="cal_name" style="border-color:${calColor}; background-color:${calColorGlass};">
                        ${cal?.Nombre || ""}
                    </div>
                    <div>
                        ${escuela?.Nombre || ""}
                    </div>`
                })
                ._GetEventOptions((event) => ([
                    { Label: _L("c_actions.editar"), Call: () => this.OpenModal_EditarEvento(event.Extra) },
                    { Label: _L("c_actions.eliminar"), Call: () => this.OpenModal_EliminarEvento(event.Extra) },
                ]))
                ._OnOnChangeTipoVista((tipoVista) => {
                    this.SyncEventos(true);
                })
                ._OnOnChangeFocusDate(() => {
                    this.SyncEventos(true);
                })
                ._OnChangeEvents(async (eventos) => {
                    // ******* WORKING *******
                    const eventEdit: Entidad.IEventoInGrid = eventos[0].Extra as any; // (Es decir el evento que será editado, mantiene sus propiedades hasta que se realiza la edición)
                    const eventGrid: Entidad.IEventoInGrid = eventos[0] as any; // (Contiene las propiedades del evento que se modifican en el control)
                    const hasConfig = eventEdit.IdAgrupador != 0;
                    /* if (hasConfig) {
                        this.ProgressVisible(true);
                        const res = await _ObtenerConfiguracionInfo(eventEdit.Id, eventEdit.IdAgrupador);
                        this.ProgressVisible(false);
                        if (res.Resultado < 1) {
                            this.refreshEvents(this.datos.eventosList);
                            this.calendar.RefreshView();
                            this.notificacion._Mostrar("Ocurrió un error", "ADVERTENCIA");
                            return;
                        }
                        const infoEvent = res.Datos;
                    } */
                    let typeEdit: Entidad.CTipoAccionEvento = Entidad.CTipoAccionEvento.Unico;
                    if (hasConfig) {
                        const optsEdit: IDatoBase[] = [];
                        let optCurrentEvent = true;
                        let optThisAndNext = true;
                        let optAll = true;

                        const changeOnlyDate = UIUtilTime._GetValidatorDateYMDV2(eventEdit.Inicio) != UIUtilTime._GetValidatorDateYMDV2(new Date(eventGrid.Inicio));

                        // » Al evento se le cambia la fecha (Ej. 5 feb -> 6 feb)
                        if (changeOnlyDate)
                            optAll = false;

                        if (optCurrentEvent) optsEdit.push({ Id: Entidad.CTipoAccionEvento.Unico, Name: _L("calendario.tag_current_event") })
                        // if (optThisAndNext) optsEdit.push({ Id: Entidad.CTipoAccionEvento.Futuros, Name: _L("calendario.tag_current&future_event") })
                        if (optAll) optsEdit.push({ Id: Entidad.CTipoAccionEvento.Todos, Name: _L("calendario.tag_all_event") })

                        typeEdit = Entidad.CTipoAccionEvento.Todos;
                        if (optsEdit.length) {
                            const formEditionConfirm = new FormGenerator();
                            formEditionConfirm._Crear({
                                LabelMaxWidth: 130,
                                schema: [
                                    {
                                        model: "EditaConfig",
                                        type: "radioList",
                                        radioListAttr: {
                                            ValueMember: "Id",
                                            DisplayMember: "Name",
                                            Data: optsEdit,
                                            Direction: "vertical",
                                            ReturnOnlyValueMember: true,
                                            required: true,
                                        }
                                    }
                                ]
                            }, { EditaConfig: optsEdit[0].Id })
                            const confrim = await ModalThings._GetConfirmacionBasicoV2({
                                Title: _L("c_actions.editar"),
                                Width: 300,
                                Content: formEditionConfirm._Form.node()
                            })
                            typeEdit = formEditionConfirm._Data.EditaConfig
                            if (!confrim || !typeEdit) {
                                this.refreshEvents(this.datos.eventosList);
                                this.calendar.RefreshView();
                                if (!typeEdit) this.notificacion._Mostrar(_L("calendario.notif_err_noedittype"), "ADVERTENCIA")
                                return;
                            }
                        }
                    }

                    const calendar = this.datos.calendariosList.find(d => d.Id == eventEdit.IdCalendario);
                    let calendarioInfo: ICalendariosToSend = {
                        IdCalendario: calendar.Id,
                        IdKinder: calendar.IdEscuela,
                        IdEscolaridad: calendar.IdEscolaridad,
                        IdGrupo: calendar.TipoEntidad == Entidad.CCalendarioTipoEntidad.Grupo ? calendar.IdEntidad : 0,
                        TipoEvento: calendar.TipoEntidad == Entidad.CCalendarioTipoEntidad.Alimento ? -1 : calendar.TipoEntidad,
                        IdEntidad: calendar.IdEntidad,
                    }

                    this.ProgressVisible(true);
                    const res = await _EditarEvento(
                        {
                            IdEvento: eventEdit.Id,
                            TipoEdicion: typeEdit as any,
                            Nombre: eventEdit.Nombre,
                            IsAllDay: null,
                            Inicio: new DateV2(eventGrid.Inicio)._ToISOLocalString(),
                            Fin: new DateV2(eventGrid.Fin)._ToISOLocalString(),
                            IdCalendario: eventEdit.IdCalendario as any,
                            TipoRecurrencia: null,
                            VarianteRecurrencia: null,
                            TipoFinRecurrencia: null,
                            FinRecurrencia: null,
                            NRecurrencias: 0,
                            IdKinder: calendarioInfo.IdKinder,
                            IdEscolaridad: calendarioInfo.IdEscolaridad,
                            IdGrupo: calendarioInfo.IdGrupo,
                            TipoEvento: calendarioInfo.TipoEvento,
                            ReuseConfig: true,
                            IdEntidad: calendarioInfo.IdEntidad,
                        }
                    )
                    this.ProgressVisible(false);
                    if (res.Resultado > 0) {
                        this.SyncEventos();
                    } else {
                        this.refreshEvents(this.datos.eventosList);
                        this.calendar.RefreshView();
                        let errMsg = _HttpMsgV2({ Resultado: res.Resultado, EndPointName: res.EndPointName })
                        this.notificacion._Mostrar(errMsg, "ADVERTENCIA");
                    }
                    return res;
                })
            // ._OnChangeCalendars((cals) => {
            //     this.SyncEventos();
            // })

            this.calendar._ControlContainer._areaNavegacion.append("div")
                .classed("btn_sync", true)
                .html(ic_sync)
                .call(cont => {
                    const btnSync = cont.select("svg");
                    btnSync.on("click", () => {
                        btnSync.classed("anim_syncing", true);
                        this.SyncCalendarios().finally(() => {
                            btnSync.classed("anim_syncing", false);
                        });
                    })
                })

            this.SyncCalendarios();
        });
    }

    private async SyncCalendarios(syncEvents = true, showProgressBar = true): Promise<void> {
        // const escuelas = DataModuloMain._GetReqDataArrayByName("Escuela")
        const idsEscuelas = DataModuloMain._GetReqDataArrayByName("Escuela").map(d => d.IdKinder);

        if (!idsEscuelas.length) {
            this.calendar._SetGrupos([])._SetCalendarios([]);
            this.SyncEventos();
            return;
        }
        if (showProgressBar) this.ProgressVisible(true);
        return _ObtenerCalendarios(idsEscuelas).then(async (res) => {
            let resCalendarios = (res.Datos || []);
            resCalendarios = resCalendarios.sort((a, b) => d3.ascending(a.Nombre.toLowerCase(), b.Nombre.toLowerCase()))
            this.datos.calendariosList = resCalendarios;
            const agrupadores = DataModuloMain._GetReqDataArrayByName("Escuela")
                .filter(k => resCalendarios.some(c => k.IdKinder == c.IdEscuela))
            const idsNewCalsInControl = (() => {
                const existCals = this.calendar._CalendarsList;
                return resCalendarios
                    .filter(r => !existCals.some(c => c.Id == r.Id))
                    .map(d => d.Id);
            })()

            const GruposCal: ICGrupo[] = []
            GruposCal.push(...agrupadores.map(d => ({
                Id: d.IdKinder + "",
                Descripcion: d.Nombre,
                Tipos: [
                    { Id: CTipoEntidad.Escuela + "", Descripcion: _L("general.general") },
                    { Id: CTipoEntidad.Grupo + "", Descripcion: _L("c_calendario_tipoentidad.grupo") },
                    { Id: CTipoEntidad.Alimento + "", Descripcion: _L("c_calendario_tipoentidad.alimento") },
                ],
                Calendarios: resCalendarios.filter(c => d.IdKinder == c.IdEscuela).map(cal => cal.Id)
            })))

            d3Group(resCalendarios, (cal) => cal.IdEscuela).forEach((calendars, idEscuela) => {
                d3Group(calendars, (calendar) => calendar.TipoEntidad).forEach((cals, tipoEntidad) => {
                    GruposCal.push({ Id: idEscuela + "_" + tipoEntidad, Descripcion: "", Calendarios: cals.map(d => d.Id) })
                })
            })

            this.calendar
                ._SetGrupos(GruposCal)
                ._SetCalendarios(resCalendarios.map(d => ({
                    Id: d.Id,
                    Descripcion: d.Nombre,
                    IdGrupo: d.IdEscuela + "",
                    IdTipo: d.TipoEntidad,
                })))
                ._CheckCalendars(idsNewCalsInControl, true)

            if (showProgressBar) this.ProgressVisible(false);
            if (syncEvents)
                await this.SyncEventos()
        })
    }

    private async SyncEventos(showProgressBar = true): Promise<void> {
        const tipoVista = this.calendar._TipoVista;
        const focusDate = this.calendar._CurrentDateFocused;
        const ids = this.datos.calendariosList.map(d => d.Id)

        const [dtInicio, dtFin] = (() => {
            const [anio, mes, dia] = [focusDate.getFullYear(), focusDate.getMonth(), focusDate.getDate()];
            const inicio = new Date(anio, mes, dia, -1, 0, 0, 0);
            const fin = new Date(anio, mes, dia, 24, 0, 0, 0);
            // if (tipoVista == CCalendarioGridV2Vista.DAY)
            // return [inicio, new Date(anio, mes, dia + 1)]
            if (tipoVista == CCalendarioGridV2Vista.WEEK) {
                inicio.setDate(inicio.getDate() - 7);
                fin.setDate(fin.getDate() + 7);
            }
            // return [inicio, new Date(anio, mes, dia + 1)]
            if (tipoVista == CCalendarioGridV2Vista.MONTH) {
                //inicio.setMonth(0);
                inicio.setDate(0);
                // fin.setMonth(11);
                fin.setDate(32);
            }
            if (tipoVista == CCalendarioGridV2Vista.YEAR) {
                inicio.setMonth(0);
                inicio.setDate(0);
                fin.setMonth(11);
                fin.setDate(32);
            }
            // return [inicio, new Date(anio, mes, dia + 1)]
            return [inicio, fin];
        })()

        if (!ids.length) {
            this.refreshEvents([])
            return;
        }
        if (showProgressBar) this.ProgressVisible(true);
        return _ObtenerEventosPorIdsCalendarios(ids, dtInicio, dtFin).then((res) => {
            const evetos = (res.Datos || []);
            const EventosInCalendar: Entidad.IEventoPorCalendario[] = []
            evetos.forEach(d => {
                // d.Inicio = new DateV2(d.Inicio)._SetTimeZoneByIdSchool(d.IdEscuela)._ToISOString();
                // d.Fin = new DateV2(d.Fin)._SetTimeZoneByIdSchool(d.IdEscuela)._ToISOString();
                const daysBlock = UIUtilTime._GetTimeElapsedSimple(new Date(d.Inicio), new Date(d.Fin)).Days()

                let auxDateB1 = new Date(d.Inicio);
                if (daysBlock > 1) {
                    while (auxDateB1.getTime() < new Date(d.Fin).getTime()) {
                        const init = new Date(auxDateB1);
                        auxDateB1.setDate(auxDateB1.getDate() + 1);
                        EventosInCalendar.push({ ...d, Inicio: init.toISOString(), Fin: auxDateB1.toISOString(), BlockReference: d })
                    }
                } else {
                    EventosInCalendar.push({ ...d })
                }
            })

            this.datos.eventosList = EventosInCalendar;

            this.refreshEvents(EventosInCalendar)
            if (showProgressBar) this.ProgressVisible(false);
        })
    }

    private refreshEvents(eventosInCalendar: Entidad.IEventoPorCalendario[]) {
        let decrementAuxId = 0;
        this.calendar._SetEventos(eventosInCalendar.map(d => ({
            Id: d.BlockReference ? decrementAuxId-- : d.Id,
            Descripcion: d.Nombre,
            Inicio: new DateV2(new Date(d.Inicio))._SetTimeZoneByIdSchool(d.IdEscuela),
            Fin: new DateV2(new Date(d.Fin))._SetTimeZoneByIdSchool(d.IdEscuela),
            IdCalendario: d.IdCalendario,
            Extra: {
                ...d,
                Inicio: new DateV2(new Date(d.Inicio))._SetTimeZoneByIdSchool(d.IdEscuela),
                Fin: new DateV2(new Date(d.Fin))._SetTimeZoneByIdSchool(d.IdEscuela)
            },
        })));
    }

    private ProgressVisible(show = true) {
        const progress = this.ctrlProgress;
        if (this["__timerShowProgress__"]) {
            clearTimeout(this["__timerShowProgress__"])
        }
        this["__timerShowProgress__"] = setTimeout(() => {
            this["__timerShowProgress__"] = null;
            progress._Visible = show;
        }, 10);
    }

    // Algoritmo para generar bloques de eventos basado en un evento plantilla
    private GenerateEventsBlocks(eventoToBlocks: IEventToGetBlocks) {

        const configEvent: Entidad.IEventoConfig = {
            Nombre: eventoToBlocks.Nombre,
            IsAllDay: eventoToBlocks.IsAllDay,
            Inicio: eventoToBlocks.FechaInicio.toISOString(),
            Fin: eventoToBlocks.FechaFin.toISOString(),
            TipoRecurrencia: eventoToBlocks.TipoRecurrencia,
            VarianteRecurrencia: eventoToBlocks.VarianteRecurrencia,
            TipoFinRecurrencia: eventoToBlocks.TipoFinRecurrencia,
            NRecurrencias: eventoToBlocks?.NRecurrencias,
            FinRecurrencia: eventoToBlocks.FechaFinRecurrencia ? eventoToBlocks.FechaFinRecurrencia.toISOString() : null
        }
        const { nDay } = GetMonthlyDtInfo(new Date(eventoToBlocks.FechaInicio));
        const isLastDayCf = eventoToBlocks.VarianteRecurrencia == Entidad.CCalendarioTipoRecurrenciaMensual.LastDay;
        // Bloque 1: -> eventoToBlocks.FechaInicio & eventoToBlocks.FechaFin
        // ¿Cuantos días hay entre el inicio y fin? => Se obtendrá un arreglo inicial de elementos con solo 2 propiedades => {DtInicio, DtFin}
        interface IAuxItems extends Pick<IEventToGetBlocks, "FechaInicio" | "FechaFin"> { };
        let initEventBlock: IAuxItems[] = [];
        let auxDateB1 = new Date(eventoToBlocks.FechaInicio);
        if (eventoToBlocks.IsAllDay) {
            while (auxDateB1.getTime() < eventoToBlocks.FechaFin.getTime()) {
                const init = new Date(auxDateB1);
                auxDateB1.setDate(auxDateB1.getDate() + 1);
                initEventBlock.push({ FechaInicio: init, FechaFin: new Date(auxDateB1) })
            }
        } else {
            initEventBlock.push({ FechaInicio: new Date(eventoToBlocks.FechaInicio), FechaFin: new Date(eventoToBlocks.FechaFin) })
        }

        console.log(initEventBlock)
        // Bloque 2: Se construye la fecha de fin de recurrencias
        // -> Considerando la fecha de inicio de la plantilla y la fecha fin de recurrencia->
        // La fecha fin será creada en base al tipo de recurrencia (Fecha | NRecurrencias)
        // Caso 1: Fecha -> Se iguala a la fecha de config
        // Caso 2: NRecurrencias -> La fecha se creará a partir del numero de recurrencias y el tipo de recurrencias

        // Creación de fecha fin de construcción de recurrencias
        // Tiene que tener la misma hora de inicio
        let fechaFinRecurrencia: Date;
        if (eventoToBlocks.TipoRecurrencia != Entidad.CCalendarioTipoRecurrencia.SinRecurrencia) {
            if (eventoToBlocks.TipoFinRecurrencia == Entidad.CCalendarioFinRecurrencia.Fecha) {
                fechaFinRecurrencia = new Date(eventoToBlocks.FechaFinRecurrencia);
            } else {
                // #Caso2
                // La fecha Fin se crea a partir de la fecha de inicio y se irá extendiendo de acuerdo a la recurrencia y sus ciclos
                fechaFinRecurrencia = new Date(eventoToBlocks.FechaInicio);
                // **3
                const auxDtTrack = new Date(fechaFinRecurrencia);
                auxDtTrack.setDate(1);
                // Se recorre deacuerdo al número de recurrencias (Y el numero de recurrencias correctas)
                // Ej.1: El 31 de cada mes (termina despues de 5 recurrencias) -> Se omiten los meses que no tienen la fecha 31 -> Fecha Fin -> 31 Agosto 2024
                // Ej.2: Anualmente el 29 de feb (termina después de 2 recurrencias)-> Se omiten los años que no tienen 29 de febrero
                let index = 0;
                while (index < (eventoToBlocks.NRecurrencias - 1)) {
                    let bolCorrectRecurrency = true;
                    switch (eventoToBlocks.TipoRecurrencia) {
                        case Entidad.CCalendarioTipoRecurrencia.Diaria:
                            fechaFinRecurrencia.setDate(fechaFinRecurrencia.getDate() + 1);
                            break;
                        case Entidad.CCalendarioTipoRecurrencia.Semanal:
                            fechaFinRecurrencia.setDate(fechaFinRecurrencia.getDate() + 7);
                            break;
                        case Entidad.CCalendarioTipoRecurrencia.Mensual:
                            // Casos de recurrencia: 1. Fecha literal, 2. N de día en mes, 3. Ultimo N día en mes
                            switch (eventoToBlocks.VarianteRecurrencia) {
                                case Entidad.CCalendarioTipoRecurrenciaMensual.LiteralDate:
                                    fechaFinRecurrencia.setMonth(fechaFinRecurrencia.getMonth() + 1);
                                    auxDtTrack.setMonth(auxDtTrack.getMonth() + 1);
                                    fechaFinRecurrencia.setDate(eventoToBlocks.FechaInicio.getDate()); // **1
                                    // **2ab
                                    if (fechaFinRecurrencia.getMonth() != auxDtTrack.getMonth()) {
                                        bolCorrectRecurrency = false;
                                        fechaFinRecurrencia.setDate(0);
                                    }
                                    break;
                                case Entidad.CCalendarioTipoRecurrenciaMensual.NDay:
                                case Entidad.CCalendarioTipoRecurrenciaMensual.LastDay:
                                    fechaFinRecurrencia = new Date(this.getDateDayPositionNextMonth(isLastDayCf ? -1 : nDay, fechaFinRecurrencia))
                                    break;
                            }
                            break;
                        case Entidad.CCalendarioTipoRecurrencia.Anual:
                            fechaFinRecurrencia.setFullYear(fechaFinRecurrencia.getFullYear() + 1);
                            auxDtTrack.setFullYear(auxDtTrack.getFullYear() + 1);
                            fechaFinRecurrencia.setDate(eventoToBlocks.FechaInicio.getDate()); // **1
                            // **2ab
                            if (fechaFinRecurrencia.getMonth() != auxDtTrack.getMonth()) {
                                bolCorrectRecurrency = false;
                                fechaFinRecurrencia.setDate(0);
                            }
                            break;
                    }
                    if (bolCorrectRecurrency) {
                        index++;
                    }
                }

                /* fechaFinRecurrencia = new Date(eventoToBlocks.FechaInicio);
                // Ajuste para controlar los recorridos que salen del límite
                const auxDtTrack = new Date(fechaFinRecurrencia);
                auxDtTrack.setDate(1);
                switch (eventoToBlocks.TipoRecucurrencia) {
                    case CCalendarioTipoRecurrencia.Diaria:
                        fechaFinRecurrencia.setDate(fechaFinRecurrencia.getDate() + (eventoToBlocks.NRecurrencias - 1));
                        break;
                    case CCalendarioTipoRecurrencia.Semanal:
                        fechaFinRecurrencia.setDate(fechaFinRecurrencia.getDate() + ((eventoToBlocks.NRecurrencias - 1) * 7));
                        break;
                    case CCalendarioTipoRecurrencia.Mensual:
                        // SE DEBE CONSIDERAR 2 CASOS
                        // #1 Cada fecha:  Ej. Mensual, cada -> 10 Marzo
                        // #2 Cada #día del mes: Ej. Mensual, cada (1er Domingo | 2do Domingo | 3er Domingo | 4to Domingo | 5to Domingo) 
    
                        // CASE #1
                        fechaFinRecurrencia.setMonth(fechaFinRecurrencia.getMonth() + (eventoToBlocks.NRecurrencias - 1));
                        auxDtTrack.setMonth(auxDtTrack.getMonth() + (eventoToBlocks.NRecurrencias - 1));
                        if (fechaFinRecurrencia.getMonth() > auxDtTrack.getMonth()) {
                            fechaFinRecurrencia.setDate(0);
                        }
                        break;
                    case CCalendarioTipoRecurrencia.Anual:
                        fechaFinRecurrencia.setFullYear(fechaFinRecurrencia.getFullYear() + (eventoToBlocks.NRecurrencias - 1));
                        auxDtTrack.setFullYear(auxDtTrack.getFullYear() + (eventoToBlocks.NRecurrencias - 1));
                        if (fechaFinRecurrencia.getMonth() > auxDtTrack.getMonth()) {
                            fechaFinRecurrencia.setDate(0);
                        }
                        break;
                } */
            }
        } else {
            fechaFinRecurrencia = new Date(eventoToBlocks.FechaInicio)
        }
        // console.log(initEventBlock);
        // console.log(fechaFinRecurrencia);
        // Bloque 3: Bloque de eventos (Con recurrencia aplicada)
        let recurrentEventBlock: IAuxItems[] = [];
        let auxRecurrenceDt = new Date(eventoToBlocks.FechaInicio);
        let nLap = 0;
        // Teniendo 1.Bloque inicial eventos && 2.Fecha fin recurrencia -> Se crean bloques de eventos resultantes -> Mientras fecha aux no se pase de fecha del fin de la recurrencia
        while (auxRecurrenceDt.getTime() <= fechaFinRecurrencia.getTime()) {
            let initDateEldestSiblingInBlock: Date;
            let endDateEldestSiblingInBlock: Date;
            // Se recorre cada elemento del bloque inicial
            for (const [i, event] of initEventBlock.entries()) {
                let boolCorrectRecurrency = true;
                let init = new Date(event.FechaInicio);
                let finDt = new Date(event.FechaFin);
                // **3
                const auxDtInit = new Date(init);
                const auxDtEnd = new Date(finDt);
                auxDtInit.setDate(1);
                auxDtEnd.setDate(1);
                switch (eventoToBlocks.TipoRecurrencia) {
                    case Entidad.CCalendarioTipoRecurrencia.Diaria:
                        init.setDate(init.getDate() + nLap);
                        finDt.setDate(finDt.getDate() + nLap);
                        break;
                    case Entidad.CCalendarioTipoRecurrencia.Semanal:
                        init.setDate(init.getDate() + (nLap * 7));
                        finDt.setDate(finDt.getDate() + (nLap * 7));
                        break;
                    case Entidad.CCalendarioTipoRecurrencia.Mensual:
                        switch (eventoToBlocks.VarianteRecurrencia) {
                            case Entidad.CCalendarioTipoRecurrenciaMensual.LiteralDate:
                                init.setMonth(init.getMonth() + nLap);
                                finDt.setMonth(finDt.getMonth() + nLap);
                                auxDtInit.setMonth(auxDtInit.getMonth() + nLap);
                                auxDtEnd.setMonth(auxDtEnd.getMonth() + nLap);
                                // **2
                                if (init.getMonth() != auxDtInit.getMonth()) {
                                    boolCorrectRecurrency = false;
                                }
                                break;
                            case Entidad.CCalendarioTipoRecurrenciaMensual.NDay:
                            case Entidad.CCalendarioTipoRecurrenciaMensual.LastDay:
                                if (nLap > 0) {
                                    if (i == 0) {
                                        init = new Date(this.getDateDayPositionNextMonth(isLastDayCf ? -1 : nDay, init, nLap));
                                        console.log(init);
                                        finDt = new Date(init);
                                        if (eventoToBlocks.IsAllDay) finDt.setDate(finDt.getDate() + 1);
                                        else {
                                            finDt.setHours(eventoToBlocks.FechaFin.getHours());
                                            finDt.setMinutes(eventoToBlocks.FechaFin.getMinutes());
                                            finDt.setMilliseconds(eventoToBlocks.FechaFin.getMilliseconds());
                                        }
                                        initDateEldestSiblingInBlock = new Date(init);
                                        endDateEldestSiblingInBlock = new Date(finDt);
                                    }
                                    init = new Date(initDateEldestSiblingInBlock);
                                    finDt = new Date(endDateEldestSiblingInBlock);
                                    init.setDate(initDateEldestSiblingInBlock.getDate() + i);
                                    finDt.setDate(endDateEldestSiblingInBlock.getDate() + i);
                                }
                                break;
                        }
                        break;
                    case Entidad.CCalendarioTipoRecurrencia.Anual:
                        init.setFullYear(init.getFullYear() + nLap);
                        finDt.setFullYear(finDt.getFullYear() + nLap);
                        auxDtInit.setFullYear(auxDtInit.getFullYear() + nLap);
                        auxDtEnd.setFullYear(auxDtEnd.getFullYear() + nLap);
                        // **2
                        if (init.getMonth() != auxDtInit.getMonth()) {
                            boolCorrectRecurrency = false;
                        }
                        break;
                }
                if (!boolCorrectRecurrency && i == 0) {
                    break;
                }
                recurrentEventBlock.push({ FechaInicio: new Date(init), FechaFin: new Date(finDt) });
            }
            nLap++;
            /* initEventBlock.forEach((event, i, arr) => {
                let boolCorrectConcurrency = true;
                // Fecha Inicio/Fin de acuerdo a la fecha del item del bloque inicial
                const init = new Date(event.FechaInicio);
                const finDt = new Date(event.FechaFin);
                // Aux Inicio/Fin de acuerdo a la fecha del item del bloque -> Con la fecha seteada al 1er dia del mes
                const auxDtInit = new Date(init);
                const auxDtEnd = new Date(finDt);
                auxDtInit.setDate(1);
                auxDtEnd.setDate(1);
                switch (eventoToBlocks.TipoRecucurrencia) {
                    case CCalendarioTipoRecurrencia.Diaria:
                        init.setDate(init.getDate() + nLap);
                        finDt.setDate(finDt.getDate() + nLap);
                        break;
                    case CCalendarioTipoRecurrencia.Semanal:
                        init.setDate(init.getDate() + (nLap * 7));
                        finDt.setDate(finDt.getDate() + (nLap * 7));
                        break;
                    case CCalendarioTipoRecurrencia.Mensual:
                        init.setMonth(init.getMonth() + nLap);
                        finDt.setMonth(finDt.getMonth() + nLap);
                        auxDtInit.setMonth(auxDtInit.getMonth() + nLap);
                        auxDtEnd.setMonth(auxDtEnd.getMonth() + nLap);
                        if (init.getMonth() > auxDtInit.getMonth() || finDt.getMonth() > auxDtEnd.getMonth()) {
                            boolCorrectConcurrency = false;
                        }
                        break;
                    case CCalendarioTipoRecurrencia.Anual:
                        init.setFullYear(init.getFullYear() + nLap);
                        finDt.setFullYear(finDt.getFullYear() + nLap);
                        auxDtInit.setFullYear(auxDtInit.getFullYear() + nLap);
                        auxDtEnd.setFullYear(auxDtEnd.getFullYear() + nLap);
                        if (init.getMonth() > auxDtInit.getMonth() || finDt.getMonth() > auxDtEnd.getMonth()) {
                            boolCorrectConcurrency = false;
                        }
                        break;
                }
                if (!boolCorrectConcurrency) {
                    console.log("No entra", init);
                    return;
                }
                recurrentEventBlock.push({ FechaInicio: new Date(init), FechaFin: new Date(finDt) });
            }) */
            const trackingEvalDt = new Date(auxRecurrenceDt);
            trackingEvalDt.setDate(1);
            // DOTEST
            switch (eventoToBlocks.TipoRecurrencia) {
                case Entidad.CCalendarioTipoRecurrencia.Diaria:
                case Entidad.CCalendarioTipoRecurrencia.SinRecurrencia:
                    auxRecurrenceDt.setDate(auxRecurrenceDt.getDate() + 1);
                    break;
                case Entidad.CCalendarioTipoRecurrencia.Semanal:
                    auxRecurrenceDt.setDate(auxRecurrenceDt.getDate() + 7);
                    break;
                case Entidad.CCalendarioTipoRecurrencia.Mensual:
                    switch (eventoToBlocks.VarianteRecurrencia) {
                        case Entidad.CCalendarioTipoRecurrenciaMensual.LiteralDate:
                            auxRecurrenceDt.setMonth(auxRecurrenceDt.getMonth() + 1);
                            trackingEvalDt.setMonth(trackingEvalDt.getMonth() + 1);
                            // **1
                            auxRecurrenceDt.setDate(eventoToBlocks.FechaInicio.getDate()); // **1
                            // **2
                            if (auxRecurrenceDt.getMonth() != trackingEvalDt.getMonth()) {
                                auxRecurrenceDt.setDate(0);
                            }
                            break;
                        case Entidad.CCalendarioTipoRecurrenciaMensual.NDay:
                        case Entidad.CCalendarioTipoRecurrenciaMensual.LastDay:
                            auxRecurrenceDt = new Date(this.getDateDayPositionNextMonth(isLastDayCf ? -1 : nDay, auxRecurrenceDt));
                            break;
                    }
                    break;
                case Entidad.CCalendarioTipoRecurrencia.Anual:
                    auxRecurrenceDt.setFullYear(auxRecurrenceDt.getFullYear() + 1);
                    trackingEvalDt.setFullYear(trackingEvalDt.getFullYear() + 1);
                    auxRecurrenceDt.setDate(eventoToBlocks.FechaInicio.getDate()); // **1
                    // **2
                    if (auxRecurrenceDt.getMonth() > trackingEvalDt.getMonth()) {
                        auxRecurrenceDt.setDate(0);
                    }
                    break;
            }

            // **1: Ayuda a mantener la fecha (date de la fecha) siempre en la fecha configurada de inicio. Ej. 31 del mes | 29 de Feb
            // **2: Se evalúa si la fecha se recorrío a otro mes (El mes no contiene la fecha lo cual hace que se recorra al primer día del mes siguiente)
            // **2a -> Si ocurre -> Esa concurrencia no es válida
            // **2b -> Si ocurre -> Se recorre al último dia del mes anterior
            // **3: Se crea una fecha auxiliar que nos ayuda a evitar que se brinque a una fecha distinta igualandose al primer día
        }
        // console.log(recurrentEventBlock);
        // Bloque 4: Se agrega el nombre a los eventos
        let eventsToSend: IEventInBlock[] = recurrentEventBlock.map<IEventInBlock>(e => ({
            Nombre: eventoToBlocks.Nombre,
            Inicio: e.FechaInicio.toISOString(),
            Fin: e.FechaFin.toISOString()
        }))
        let calendariosInfo: ICalendariosToSend[] = eventoToBlocks.IdsCalendarios.map<ICalendariosToSend>(id => {
            const calendar = this.datos.calendariosList.find(d => d.Id == id);
            return {
                IdCalendario: calendar.Id,
                IdKinder: calendar.IdEscuela,
                IdEscolaridad: calendar.IdEscolaridad,
                IdGrupo: calendar.TipoEntidad == Entidad.CCalendarioTipoEntidad.Grupo ? calendar.IdEntidad : 0,
                TipoEvento: calendar.TipoEntidad == Entidad.CCalendarioTipoEntidad.Alimento ? -1 : calendar.TipoEntidad,
                IdEntidad: undefined, // calendar.IdEntidad,
            }
        })
        // BLOQUE 4: bloques de eventos por calendarios seleccionados -> Preparar para ser enviados
        // let eventsToSend: IEventInBlock[][] = [];
        // eventoToBlocks.IdsCalendario.forEach(id => {
        //     const calendar = this.datos.calendariosList.find(d => d.Id == id);
        //     if (!calendar) return;
        //     console.log(calendar);
        //     const eventBlockInCalendar = recurrentEventBlock.map<IEventInBlock>(e => ({
        //         Nombre: eventoToBlocks.Nombre,
        //         DtInicio: e.FechaInicio.toISOString(),
        //         DtFin: e.FechaFin.toISOString(),
        //         IdCalendario: id,
        //         IdKinder: calendar.IdEscuela,
        //         IdEscolaridad: calendar.IdEscolaridad,
        //         IdGrupo: calendar.TipoEntidad == Entidad.CCalendarioTipoEntidad.Grupo ? calendar.IdEntidad : 0,
        //         TipoEvento: calendar.TipoEntidad == Entidad.CCalendarioTipoEntidad.Alimento ? -1 : calendar.TipoEntidad
        //     }))
        //     eventsToSend.push(eventBlockInCalendar)
        // })
        return {
            EventsBlocksToSend: eventsToSend,
            CalendariosInfo: calendariosInfo,
            EventoConfig: configEvent
        }
    }

    private GenerateEventsBlocksV2(eventoToBlocks: IEventToGetBlocks) {
        const nDiasBlock = UIUtilTime._GetTimeElapsedSimple(eventoToBlocks.FechaInicio, eventoToBlocks.FechaFin).Days();
        console.log(nDiasBlock);
        // #1. Generar configuración del evento -> Se guarda en tbl.cfg_evento_calendario 
        const configEvent: Entidad.IEventoConfig = {
            Nombre: eventoToBlocks.Nombre,
            IsAllDay: eventoToBlocks.IsAllDay,
            Inicio: eventoToBlocks.FechaInicio.toISOString(),
            Fin: eventoToBlocks.FechaFin.toISOString(),
            TipoRecurrencia: eventoToBlocks.TipoRecurrencia,
            VarianteRecurrencia: eventoToBlocks.VarianteRecurrencia,
            TipoFinRecurrencia: eventoToBlocks.TipoFinRecurrencia,
            NRecurrencias: eventoToBlocks?.NRecurrencias,
            FinRecurrencia: eventoToBlocks.FechaFinRecurrencia ? eventoToBlocks.FechaFinRecurrencia.toISOString() : null
        }

        // Información del día de inicio
        const { nDay } = GetMonthlyDtInfo(new Date(eventoToBlocks.FechaInicio));
        const isLastDayCf = eventoToBlocks.VarianteRecurrencia == Entidad.CCalendarioTipoRecurrenciaMensual.LastDay;

        // #2. Creación evento inicial
        interface IAuxItems extends Pick<IEventToGetBlocks, "FechaInicio" | "FechaFin"> { };
        let initEvent: IAuxItems = { FechaInicio: eventoToBlocks.FechaInicio, FechaFin: eventoToBlocks.FechaFin }
        console.log(initEvent);

        // #3. Creación de eventos Recurrentes
        // #3.1 Eventos con NRecurrencias
        // #3.2 Eventos con FechaFinRecurrencia
        const recurrentEventBlock: IAuxItems[] = [];
        recurrentEventBlock.push({ ...initEvent });

        const rangeDateByRecurrency = (auxDateInit: Date, auxDateFinish: Date): { init: Date, end: Date, correctRecurrency: boolean } => {
            let boolCorrectRecurrency = true;
            const trackingEvalDt = new Date(auxDateInit);
            trackingEvalDt.setDate(1);

            switch (eventoToBlocks.TipoRecurrencia) {
                case Entidad.CCalendarioTipoRecurrencia.Diaria:
                    auxDateInit.setDate(auxDateInit.getDate() + 1);
                    auxDateFinish.setDate(auxDateFinish.getDate() + 1);
                    break;
                case Entidad.CCalendarioTipoRecurrencia.Semanal:
                    auxDateInit.setDate(auxDateInit.getDate() + 7);
                    auxDateFinish.setDate(auxDateFinish.getDate() + 7);
                    break;
                case Entidad.CCalendarioTipoRecurrencia.Mensual:
                    switch (eventoToBlocks.VarianteRecurrencia) {
                        // NOTE: En caso de que el calendario gregoriano cambie -> Deberá reconsiderase este caso (Entidad.CCalendarioTipoRecurrenciaMensual.LiteralDate)
                        case Entidad.CCalendarioTipoRecurrenciaMensual.LiteralDate:
                            // FIXME: REVISAR IMPLEMENTACIONES QUE NO CONTEMPLAN EL SALTO INDESEADO EN LA FECHA FIN
                            auxDateInit.setMonth(auxDateInit.getMonth() + 1);
                            trackingEvalDt.setMonth(trackingEvalDt.getMonth() + 1);
                            auxDateInit.setDate(eventoToBlocks.FechaInicio.getDate()); // **1
                            auxDateFinish = new Date(auxDateInit)
                            auxDateFinish.setDate(auxDateFinish.getDate() + nDiasBlock);
                            auxDateFinish.setHours(eventoToBlocks.FechaFin.getHours());
                            auxDateFinish.setMinutes(eventoToBlocks.FechaFin.getMinutes());
                            auxDateFinish.setMilliseconds(eventoToBlocks.FechaFin.getMilliseconds());
                            if (auxDateInit.getMonth() != trackingEvalDt.getMonth()) {
                                auxDateInit.setDate(0);
                                boolCorrectRecurrency = false;
                            }
                            break
                        case Entidad.CCalendarioTipoRecurrenciaMensual.LastDay:
                        case Entidad.CCalendarioTipoRecurrenciaMensual.NDay:
                            auxDateInit = new Date(this.getDateDayPositionNextMonth(isLastDayCf ? -1 : nDay, auxDateInit));
                            auxDateFinish = new Date(auxDateInit)
                            auxDateFinish.setDate(auxDateFinish.getDate() + nDiasBlock);
                            auxDateFinish.setHours(eventoToBlocks.FechaFin.getHours());
                            auxDateFinish.setMinutes(eventoToBlocks.FechaFin.getMinutes());
                            auxDateFinish.setMilliseconds(eventoToBlocks.FechaFin.getMilliseconds());
                            break;
                    }
                    break;
                case Entidad.CCalendarioTipoRecurrencia.Anual:
                    auxDateInit.setFullYear(auxDateInit.getFullYear() + 1);
                    trackingEvalDt.setFullYear(trackingEvalDt.getFullYear() + 1);
                    auxDateInit.setDate(eventoToBlocks.FechaInicio.getDate()); // **1
                    auxDateFinish = new Date(auxDateInit);
                    auxDateFinish.setDate(auxDateFinish.getDate() + nDiasBlock)
                    auxDateFinish.setHours(eventoToBlocks.FechaFin.getHours());
                    auxDateFinish.setMinutes(eventoToBlocks.FechaFin.getMinutes());
                    auxDateFinish.setMilliseconds(eventoToBlocks.FechaFin.getMilliseconds());
                    if (auxDateInit.getMonth() > trackingEvalDt.getMonth()) {
                        auxDateInit.setDate(0);
                        boolCorrectRecurrency = false;
                    }
                    break;
            }

            return {
                init: auxDateInit,
                end: auxDateFinish,
                correctRecurrency: boolCorrectRecurrency
            }
        }

        switch (eventoToBlocks.TipoFinRecurrencia) {
            case Entidad.CCalendarioFinRecurrencia.Fecha:
                let auxDateInit = new Date(initEvent.FechaInicio);
                let auxDateFinish = new Date(initEvent.FechaFin);
                while (eventoToBlocks.FechaFinRecurrencia.getTime() > auxDateInit.getTime()) {
                    let dates = rangeDateByRecurrency(new Date(auxDateInit), new Date(auxDateFinish));
                    auxDateInit = new Date(dates.init);
                    auxDateFinish = new Date(dates.end);
                    if (dates.correctRecurrency && eventoToBlocks.FechaFinRecurrencia.getTime() >= auxDateInit.getTime()) {
                        recurrentEventBlock.push({ FechaInicio: new Date(auxDateInit), FechaFin: new Date(auxDateFinish) })
                    }
                }
                break;
            case Entidad.CCalendarioFinRecurrencia.Numero:
                let auxDateStart = new Date(initEvent.FechaInicio);
                let auxDateEnd = new Date(initEvent.FechaFin);
                for (let index = 1; index < eventoToBlocks.NRecurrencias;) {
                    let dates = rangeDateByRecurrency(new Date(auxDateStart), new Date(auxDateEnd));
                    auxDateStart = new Date(dates.init);
                    auxDateEnd = new Date(dates.end);
                    if (dates.correctRecurrency) {
                        index++;
                        recurrentEventBlock.push({ FechaInicio: new Date(auxDateStart), FechaFin: new Date(auxDateEnd) })
                    }
                }
                break;
        }

        console.log(recurrentEventBlock);

        // # 4. Formateo de eventos a enviar
        let eventsToSend: IEventInBlock[] = recurrentEventBlock.map<IEventInBlock>(e => ({
            Nombre: eventoToBlocks.Nombre,
            Inicio: e.FechaInicio.toISOString(),
            Fin: e.FechaFin.toISOString()
        }))
        // # 5. Calendarios a asignar eventos (INFO)
        let calendariosInfo: ICalendariosToSend[] = eventoToBlocks.IdsCalendarios.map<ICalendariosToSend>(id => {
            const calendar = this.datos.calendariosList.find(d => d.Id == id);
            return {
                IdCalendario: calendar.Id,
                IdKinder: calendar.IdEscuela,
                IdEscolaridad: calendar.IdEscolaridad,
                IdGrupo: calendar.TipoEntidad == Entidad.CCalendarioTipoEntidad.Grupo ? calendar.IdEntidad : 0,
                TipoEvento: calendar.TipoEntidad == Entidad.CCalendarioTipoEntidad.Alimento ? -1 : calendar.TipoEntidad,
                IdEntidad: undefined, // calendar.IdEntidad,
            }
        })

        return {
            EventsBlocksToSend: eventsToSend,
            CalendariosInfo: calendariosInfo,
            EventoConfig: configEvent
        }
    }

    private getDateDayPositionNextMonth(dayPosition: number, dt: Date, nMonths = 1) {
        // FIXME: REVISAR TODAS LAS IMPLEMENTACIONES DE .setMonth -> Si se utiliza para pasar al siguiente mes tener cuidado con el salto de 2 meses
        // -> Ej. 31 ene +1 Month = 1 mar
        const dtTracked = new Date(dt);
        const dtAux = new Date(dt);
        dtAux.setDate(1);
        for (let index = 0; nMonths > index; index++) {
            if (dayPosition != -1) {
                let nPosition = 0;
                while (nPosition < dayPosition) {
                    dtTracked.setDate(dtTracked.getDate() + 7);
                    // Esta evaluación falla porque no considera el cambio de años
                    if (dtTracked.getMonth() != dtAux.getMonth()) nPosition++;
                }
                dtAux.setMonth(dtTracked.getMonth())
            } else {
                let nMonthsTrack = 0;
                while (nMonthsTrack < 2) {
                    dtTracked.setDate(dtTracked.getDate() + 7);
                    if (dtTracked.getMonth() != dtAux.getMonth()) {
                        nMonthsTrack++;
                        dtAux.setMonth(dtTracked.getMonth());
                    }
                }
                dtTracked.setDate(dtTracked.getDate() - 7);
                dtAux.setMonth(dtTracked.getMonth());
            }
        }
        return dtTracked;
    }

    private OpenModal_AgregarEvento(event: Partial<IEventoCalendario>) {
        ModalThings._GetModalToForm({
            Title: _L("c_actions.agregar"),
            GetForm: (_, mt) => {
                const form = this.BuildForm();
                this.AssignDataForm(form, event);
                return form;
            },
            OnAccept: async (form, mt) => {
                const formData = form._Data;
                const IsAllDay = formData.IsAllDay;
                const IsRecurrent = formData.TipoRecurrencia !== Entidad.CCalendarioTipoRecurrencia.SinRecurrencia;
                const IsMonthlyRecurrent = formData.TipoRecurrencia == Entidad.CCalendarioTipoRecurrencia.Mensual;

                // Para determinar -> Fecha de Inicio / Fecha Fin
                // >> FechaInicioYMD / FechaFinYMD -> Cuando -> Sea todo el día +HoraCero || Cuando -> No sea todo el día +HoraInicioHM/+HoraFinHM
                let strInitDate: string;
                if (IsAllDay) strInitDate = formData.FechaInicioYMD + " 00:00";
                else strInitDate = formData.FechaUnicaYMD + " " + formData.HoraInicioHM;
                const dtInicio = new Date(strInitDate);
                // TEMPORAL: AJUSTE TEMPORAL PARA OCULTAR LA FECHA FIN EN EVENTOS ALLDAY (SOLO SERÁN BLOQUES DE UN DÍA)
                let strEndDate: string;
                if (IsAllDay) strEndDate = formData.FechaInicioYMD + " 00:00";
                else strEndDate = formData.FechaUnicaYMD + " " + formData.HoraFinHM;
                const dtFin = new Date(strEndDate);
                if (IsAllDay) dtFin.setDate(dtFin.getDate() + 1);

                // let strEndDate: string;
                // if (IsAllDay) strEndDate = formData.FechaFinYMD + " 00:00";
                // else strEndDate = formData.FechaUnicaYMD + " " + formData.HoraFinHM;
                // const dtFin = new Date(strEndDate);
                // if (IsAllDay) dtFin.setDate(dtFin.getDate() + 1);

                let calendariosInfo: ICalendariosToSend[] = formData.IdCalendarios.map<ICalendariosToSend>(id => {
                    const calendar = this.datos.calendariosList.find(d => d.Id == id);
                    return {
                        IdCalendario: calendar.Id,
                        IdKinder: calendar.IdEscuela,
                        IdEscolaridad: calendar.IdEscolaridad,
                        IdGrupo: calendar.TipoEntidad == Entidad.CCalendarioTipoEntidad.Grupo ? calendar.IdEntidad : 0,
                        TipoEvento: calendar.TipoEntidad == Entidad.CCalendarioTipoEntidad.Alimento ? -1 : calendar.TipoEntidad,
                        IdEntidad: calendar.IdEntidad,
                    }
                })

                const resAddEvent = await _AgregarEventos(
                    {
                        Nombre: formData.Nombre,
                        IsAllDay: formData.IsAllDay,
                        Inicio: new DateV2(dtInicio)._ToISOLocalString(),
                        Fin: new DateV2(dtFin)._ToISOLocalString(),
                        TipoRecurrencia: formData.TipoRecurrencia,
                        VarianteRecurrencia: (!IsMonthlyRecurrent) ? Entidad.CCalendarioTipoRecurrenciaMensual.NoAplica : formData.VarianteRecurrencia,
                        TipoFinRecurrencia: (IsRecurrent) ? formData.TipoFinRecurrencia : null,
                        FinRecurrencia: (IsRecurrent && formData.TipoFinRecurrencia == Entidad.CCalendarioFinRecurrencia.Fecha) ? new DateV2(new Date(formData.FechaFinRecurrenciaYMD + (formData.IsAllDay ? " " : (" " + formData.HoraInicioHM))))._ToISOLocalString() : null,
                        NRecurrencias: (IsRecurrent && formData.TipoFinRecurrencia == Entidad.CCalendarioFinRecurrencia.Numero) ? formData.NRecurrencias : null,
                    },
                    calendariosInfo
                )
                if (resAddEvent.Resultado > 0) {
                    this.SyncEventos();
                }
                return resAddEvent;
            }
        })
    }

    private async OpenModal_EditarEvento(event: IEventoCalendario) {
        let infoEvent: Entidad.IEventoInfo;
        ModalThings._GetModalToForm({
            Title: _L("calendario.edit_event"),
            GetForm: () => {
                const form = this.BuildForm(event);
                return form;
            },
            DrawContent: async (_, form, mt) => {
                if (event.IdAgrupador != 0) {
                    mt.Progress.attr("oculto", false);
                    mt.BtnRight._Enable(false);
                    const res = await _ObtenerConfiguracionInfo(event.Id, event.IdAgrupador);
                    mt.Progress.attr("oculto", true);
                    mt.BtnRight._Enable(true);
                    if (res.Resultado < 1) {
                        this.notificacion._Mostrar(_L("general.notif_fail"), "ADVERTENCIA");
                        mt.Modal._Ocultar();
                        return;
                    }
                    infoEvent = res.Datos
                    // console.log(infoEvent.FinRecurrencia)
                }
                this.AssignDataForm(form, event, infoEvent);
            },
            OnAccept: async (form, mt) => {
                const formData = form._Data;
                const IsAllDay = formData.IsAllDay;
                const IsRecurrent = formData.TipoRecurrencia !== Entidad.CCalendarioTipoRecurrencia.SinRecurrencia;
                const IsMonthlyRecurrent = formData.TipoRecurrencia == Entidad.CCalendarioTipoRecurrencia.Mensual;

                // Para determinar -> Fecha de Inicio / Fecha Fin
                // >> FechaInicioYMD / FechaFinYMD -> Cuando -> Sea todo el día +HoraCero || Cuando -> No sea todo el día +HoraInicioHM/+HoraFinHM
                let strInitDate: string;
                if (IsAllDay) strInitDate = formData.FechaInicioYMD + " 00:00";
                else strInitDate = formData.FechaUnicaYMD + " " + formData.HoraInicioHM;
                const dtInicio = new Date(strInitDate);
                // TEMPORAL: AJUSTE TEMPORAL PARA OCULTAR LA FECHA FIN EN EVENTOS ALLDAY (SOLO SERÁN BLOQUES DE UN DÍA)
                let strEndDate: string;
                if (IsAllDay) strEndDate = formData.FechaInicioYMD + " 00:00";
                else strEndDate = formData.FechaUnicaYMD + " " + formData.HoraFinHM;
                const dtFin = new Date(strEndDate);
                if (IsAllDay) dtFin.setDate(dtFin.getDate() + 1);

                const calendar = this.datos.calendariosList.find(d => d.Id == formData.IdCalendarios as any);
                let calendarioInfo: ICalendariosToSend = {
                    IdCalendario: calendar.Id,
                    IdKinder: calendar.IdEscuela,
                    IdEscolaridad: calendar.IdEscolaridad,
                    IdGrupo: calendar.TipoEntidad == Entidad.CCalendarioTipoEntidad.Grupo ? calendar.IdEntidad : 0,
                    TipoEvento: calendar.TipoEntidad == Entidad.CCalendarioTipoEntidad.Alimento ? -1 : calendar.TipoEntidad,
                    IdEntidad: calendar.IdEntidad,
                }

                const ApplyDtFinRec = IsRecurrent && formData.TipoFinRecurrencia == Entidad.CCalendarioFinRecurrencia.Fecha;
                const ApplyNRec = IsRecurrent && formData.TipoFinRecurrencia == Entidad.CCalendarioFinRecurrencia.Numero;

                // CONFIG THINGS
                const VarianteRecurrencia = (IsMonthlyRecurrent) ? formData.VarianteRecurrencia : Entidad.CCalendarioTipoRecurrenciaMensual.NoAplica;
                const TipoFinRecurrencia = (IsRecurrent) ? formData.TipoFinRecurrencia : null;
                const NRecurrencias = (ApplyNRec) ? formData.NRecurrencias : 0;
                // const FechaFinRecurrencia = (ApplyDtFinRec) ? new DateV2(formData.FechaFinRecurrenciaYMD + " ") : null;
                // TIME ZONE THINGS
                const FechaFinRecurrencia = (ApplyDtFinRec) ? new DateV2(formData.FechaFinRecurrenciaYMD + (formData.IsAllDay ? " " : (" " + formData.HoraInicioHM)))._ToISOLocalString() : null;

                // Pregunta de confirmación de edición
                // Casos para no confirmar edición
                // (El evento es unico >>  IdConfig = 0) || (cambio de calendario) || (enciende o apaga recurrencia) || (Cambio en ConfiguraciónRecurrencia && CambioFechaEvento)
                // ConfigRec (Tipo recurrencia, VarianteRecurrencia, TipoFinRecurrencia, FechaFinRecurrencia, NRecurrencias)
                const hasConfig = event.IdAgrupador != 0;
                const goWithRecurrency = formData.TipoRecurrencia != Entidad.CCalendarioTipoRecurrencia.SinRecurrencia;
                const turnRecurrency = (!hasConfig && goWithRecurrency) || (hasConfig && !goWithRecurrency);

                let typeEdit: Entidad.CTipoAccionEvento = Entidad.CTipoAccionEvento.Unico;
                if (hasConfig) {
                    typeEdit = Entidad.CTipoAccionEvento.Todos
                    const EventCfgFechaFinRecurrencia = infoEvent.FinRecurrencia ? (new DateV2(infoEvent.FinRecurrencia)._SetTimeZoneByIdSchool(event.IdEscuela)._ToISOLocalString()) : null;
                    const changeCalendar = infoEvent?.IdCalendario != (formData.IdCalendarios as any as number);
                    if (!changeCalendar) {
                        const chCfTipoRecurrencia = infoEvent?.TipoRecurrencia != formData.TipoRecurrencia;
                        const chCfVarianteRec = infoEvent?.VarianteRecurrencia != VarianteRecurrencia;
                        const chCfTipoFinRec = infoEvent?.TipoFinRecurrencia != TipoFinRecurrencia;
                        const chCfNRecurrencias = infoEvent?.NRecurrencias != NRecurrencias;

                        const chCfFechaFinRecurrencia = (() => {
                            if (!infoEvent?.FinRecurrencia && FechaFinRecurrencia) return true
                            if (infoEvent?.FinRecurrencia && !FechaFinRecurrencia) return true
                            if (!infoEvent?.FinRecurrencia && !FechaFinRecurrencia) return false
                            return infoEvent?.FinRecurrencia ? UIUtilTime._GetValidatorDateYMDV2(new DateV2(EventCfgFechaFinRecurrencia.replace("Z", ""))) != UIUtilTime._GetValidatorDateYMDV2(new Date(FechaFinRecurrencia.replace("Z", ""))) : false;
                        })()

                        const changeRecConfig = chCfTipoRecurrencia || chCfVarianteRec || chCfTipoFinRec || chCfFechaFinRecurrencia || chCfNRecurrencias;
                        const changeOnlyDate = UIUtilTime._GetValidatorDateYMDV2(dtInicio) != UIUtilTime._GetValidatorDateYMDV2(new Date(event.Inicio));

                        const optsEdit: IDatoBase[] = [];
                        let optCurrentEvent = true;
                        let optThisAndNext = true;
                        let optAll = true;

                        // » (Cambio de configuración de recurrencia && fecha (Ej. 5 feb -> 6 feb) (No se considera horas como cambio)) || (enciende o apaga recurrencia) ||  (cambio calendario)
                        if ((changeRecConfig && changeOnlyDate) || turnRecurrency || changeCalendar) {
                            optCurrentEvent = false;
                            optThisAndNext = false;
                            optAll = false;
                        }
                        // » Se modifica la configuración de recurrencia
                        if (changeRecConfig) {
                            optCurrentEvent = false;
                        }
                        // » Al evento se le cambia la fecha (Ej. 5 feb -> 6 feb)
                        // TEMPORAL: POR EL MOMENTO LA OPCIÓN SE HABILITARÁ HASTA QUE SE CONSTRUYA (Este evento y los siguientes)
                        // if (changeOnlyDate)
                        //     optAll = false;

                        if (optCurrentEvent) optsEdit.push({ Id: Entidad.CTipoAccionEvento.Unico, Name: _L("calendario.tag_current_event") })
                        // if (optThisAndNext) optsEdit.push({ Id: Entidad.CTipoAccionEvento.Futuros, Name: _L("calendario.tag_current&future_event") })
                        if (optAll) optsEdit.push({ Id: Entidad.CTipoAccionEvento.Todos, Name: _L("calendario.tag_all_event") })
                        // if (changeRecConfig && changeOnlyDate) typeEdit = Entidad.CTipoAccionEvento.Futuros
                        console.warn({ chCfTipoRecurrencia, chCfVarianteRec, chCfTipoFinRec, chCfFechaFinRecurrencia, chCfNRecurrencias, changeOnlyDate })
                        if (optsEdit.length) {
                            const formEditionConfirm = new FormGenerator();
                            formEditionConfirm._Crear({
                                LabelMaxWidth: 130,
                                schema: [
                                    {
                                        model: "EditaConfig",
                                        type: "radioList",
                                        radioListAttr: {
                                            ValueMember: "Id",
                                            DisplayMember: "Name",
                                            Data: optsEdit,
                                            Direction: "vertical",
                                            ReturnOnlyValueMember: true,
                                            required: true,
                                        }
                                    }
                                ]
                            }, { EditaConfig: optsEdit[0].Id })
                            const confrim = await ModalThings._GetConfirmacionBasicoV2({
                                Title: _L("c_actions.editar"),
                                Width: 300,
                                Content: formEditionConfirm._Form.node()
                            })
                            typeEdit = formEditionConfirm._Data.EditaConfig
                            if (!confrim || !typeEdit) {
                                // this.refreshEvents(this.datos.eventosList);
                                // this.calendar.RefreshView();
                                if (!typeEdit) this.notificacion._Mostrar(_L("calendario.notif_err_noedittype"), "ADVERTENCIA")
                                return;
                            }
                        }
                    }
                }
                const res = await _EditarEvento(
                    {
                        IdEvento: event.Id,
                        TipoEdicion: typeEdit,
                        Nombre: formData.Nombre,
                        IsAllDay: IsAllDay,
                        Inicio: new DateV2(dtInicio)._ToISOLocalString(),
                        Fin: new DateV2(dtFin)._ToISOLocalString(),
                        IdCalendario: formData.IdCalendarios as any,
                        TipoRecurrencia: formData.TipoRecurrencia,
                        VarianteRecurrencia: (!IsMonthlyRecurrent) ? Entidad.CCalendarioTipoRecurrenciaMensual.NoAplica : formData.VarianteRecurrencia,
                        TipoFinRecurrencia: (IsRecurrent) ? formData.TipoFinRecurrencia : null,
                        FinRecurrencia: (ApplyDtFinRec) ? FechaFinRecurrencia : null,
                        NRecurrencias: (ApplyNRec) ? formData.NRecurrencias : 0,
                        IdKinder: calendarioInfo.IdKinder,
                        IdEscolaridad: calendarioInfo.IdEscolaridad,
                        IdGrupo: calendarioInfo.IdGrupo,
                        TipoEvento: calendarioInfo.TipoEvento,
                        IdEntidad: calendarioInfo.IdEntidad,
                    }
                )

                if (res.Resultado > 0) {
                    this.SyncEventos();
                }
                /* else {
                    this.refreshEvents(this.datos.eventosList);
                    this.calendar.RefreshView();
                } */
                return res;
            }
        })
    }

    private async OpenModal_EliminarEvento(event: IEventoCalendario) {
        let EventosHermanos: Entidad.IEventoPorCalendario[] = [];
        let EventosHermanosFuturo: Entidad.IEventoPorCalendario[] = [];
        const form = new FormGenerator();

        ModalThings._GetConfirmacionModal({
            Title: _L("c_actions.eliminar"),
            Width: 300,
            Message: _L("calendario.confirma_eliminar", event.Nombre),
            DrawContent: async (cont, mt) => {
                mt.Progress.attr("oculto", false);
                mt.Modal._DeshabilitarBtns();
                let groupedEvents: Entidad.IEventoPorCalendario[];
                const res = await _ObtenerEventosAgrupadosPorIdEvento(event.Id);
                if (res.Resultado > 0) {
                    groupedEvents = res.Datos;
                } else {
                    NotificacionV2._Mostrar(_L("calendario.notif_err_geteventgroup"), "ADVERTENCIA")
                    mt.Modal._Ocultar();
                    return;
                }
                mt.Modal._HabilitarBtns();
                mt.Progress.attr("oculto", true);

                EventosHermanos = groupedEvents.filter(d => d.Id != event.Id);
                EventosHermanosFuturo = EventosHermanos.filter(d => d.Inicio >= (event.Inicio as unknown as Date).toISOString());

                if (EventosHermanos.length) {
                    cont.append("p")
                        .text(`(${_L("calendario.tag_recurrent_event")})`)
                        .style("margin-top", "var(--padding2)")
                        .style("margin-bottom", "var(--padding2)");
                }

                const optDelet = <IDatoBase[]>[
                    { Id: Entidad.CTipoAccionEvento.Unico, Name: _L("calendario.tag_current_event") }
                ]
                if (EventosHermanosFuturo.length) optDelet.push({ Id: Entidad.CTipoAccionEvento.Futuros, Name: _L("calendario.tag_current&future_event") });
                if (EventosHermanos.length) optDelet.push({ Id: Entidad.CTipoAccionEvento.Todos, Name: _L("calendario.tag_all_event") })
                form._Crear({
                    LabelMaxWidth: 130,
                    schema: [
                        {
                            model: "EliminaConfig",
                            type: "radioList",
                            radioListAttr: {
                                ValueMember: "Id",
                                DisplayMember: "Name",
                                Data: optDelet,
                                Direction: "vertical",
                                ReturnOnlyValueMember: true,
                                required: true,
                                Disabled: !EventosHermanos.length,
                            }
                        }
                    ]
                }, { EliminaConfig: Entidad.CTipoAccionEvento.Unico })
                cont.append(() => form._Form.node());
            },
            OnAccept: async (mt) => {
                const tipoDelete = form._Data.EliminaConfig;
                mt.Progress.attr("oculto", false);
                const res = await _EliminarEvento(event.Id, tipoDelete);
                if (res.Resultado > 0) {
                    NotificacionV2._Mostrar(_L("calendario.notif_success_event_delete"), "INFO")
                    this.SyncEventos();
                } else {
                    NotificacionV2._Mostrar(_L("calendario.notif_err_event_delete"), "ADVERTENCIA")
                }
                mt.Progress.attr("oculto", true);
            }
        })
    }

    private BuildForm(evento: Partial<IEventoCalendario> = {}) {
        const isNewEvent = !(evento?.Id);
        const form = new FormGenerator<IEventForm>()
        form._Crear({
            LabelMaxWidth: 130,
            schema: [
                { model: "Nombre", type: "input", labelText: _L("calendario.nombre"), inputAttr: { required: true } },
                { model: "IsAllDay", type: "input", labelText: _L("calendario.fullday"), inputAttr: { type: "checkbox", } },
                {
                    model: "FechaUnicaYMD", type: "input", labelText: _L("general.fecha"),
                    // onValidate: () => ValideFormDateRange(form._Data),
                    inputAttr: { required: true, type: "date", },
                },
                // FECHA INICIO (TodoElDia=true)
                {
                    model: "FechaInicioYMD", type: "input", labelText: _L("calendario.start"),
                    onValidate: () => ValideFormDateRange(form._Data),
                    inputAttr: {
                        required: true, type: "date", onchange: ({ target }) => {
                            // TEMPORAL: FechaFinYMD OCULTA
                            // const inicio = (target as HTMLInputElement).value;
                            // const tipoRec = (form._ControlsData.get("TipoRecurrencia").instance as SelectV2);
                            // tipoRec._RefreshList();
                            // const tipoRecSelected: Entidad.CCalendarioTipoRecurrencia = tipoRec._dataValueMemberSelected[0] || Entidad.CCalendarioTipoRecurrencia.SinRecurrencia;
                            // tipoRec._valueSelect(tipoRecSelected);
                            // form._ControlsData.get("FechaFinYMD").selection.attr("min", inicio);
                            const tipoRecM = (form._ControlsData.get("VarianteRecurrencia").instance as SelectV2);
                            tipoRecM._RefreshList();
                        }
                    },
                },
                { model: "HoraInicioHM", type: "input", labelText: _L("calendario.start"), inputAttr: { type: "time" }, onValidate: () => { return ValideFormDateRange(form._Data) } },
                // TEMPORAL: SE OCULTARÁ "FechaFinYMD"
                // FECHA FIN (TodoElDia=true)
                // {
                //     model: "FechaFinYMD", type: "input", labelText: _L("calendario.end"),
                //     onValidate: () => ValideFormDateRange(form._Data),
                //     inputAttr: {
                //         required: true, type: "date", onchange: ({ target }) => {
                //             const fin = (target as HTMLInputElement).value;
                //             const tipoRec = (form._ControlsData.get("TipoRecurrencia").instance as SelectV2);
                //             tipoRec._RefreshList();
                //             const tipoRecSelected: Entidad.CCalendarioTipoRecurrencia = tipoRec._dataValueMemberSelected[0] || Entidad.CCalendarioTipoRecurrencia.SinRecurrencia;
                //             tipoRec._valueSelect(tipoRecSelected);
                //             form._ControlsData.get("FechaInicioYMD").selection.attr("max", fin);
                //         }
                //     },
                // },
                { model: "HoraFinHM", type: "input", labelText: _L("calendario.end"), inputAttr: { type: "time" } },
                {
                    model: "IdCalendarios", type: "selectMaterial",
                    labelText: _L("calendario.calendar"),
                    selectMaterialAttr: <IAtributosSelectMaterial<Entidad.ICalendario, "Id">>{
                        Data: this.datos.calendariosList.sort((a, b) => a.TipoEntidad - b.TipoEntidad).sort((a, b) => a.IdEscuela - b.IdEscuela),
                        required: true,
                        valueMember: "Id",
                        displayMember: "Nombre",
                        multiselect: isNewEvent,
                        OnStepItemListUI: (container, dato: Entidad.ICalendario, step) => {
                            container.html("")
                            const colorCal = this.calendar._GetCalendarColor(dato.Id);
                            const esEscuela = dato.TipoEntidad == CTipoEntidad.Escuela;
                            const schoolName = DataModuloMain._GetDataValueFieldByName("Escuela", dato.IdEscuela, "Nombre") || "No Disponible";

                            const cal_cont = container.append("div").classed("cal_cont", true);
                            cal_cont.append("b").classed("color", true).style("background-color", colorCal);
                            const cal_info = cal_cont.append("div").classed("cal_info", true);
                            cal_info.append("div").text(dato.Nombre).classed("is_school", esEscuela)
                            if (!esEscuela)
                                cal_info.append("label").classed("school_aux", true).text(schoolName)
                            cal_cont.append("div").text(UIUtilViewData._GetStr_CalTipoEntidad(dato.TipoEntidad))
                        },
                        ShowAndEnableSearchText: true,
                        OnChangeSearchText_GetDataValueToEval: (d: Entidad.ICalendario) => d.TipoEntidad == CTipoEntidad.Escuela
                            ? d.Nombre
                            : d.Nombre + (_DiccEscuela.get(d.IdEscuela)?.Nombre || ""),

                    }
                },
                {
                    model: "TipoRecurrencia", type: "selectMaterial", labelText: _L("calendario.tiporecurrencia"),
                    selectMaterialAttr: {
                        valueMember: "Id",
                        displayMember: "Name",
                        required: true,
                        Data: () => GetTiposRecurrencia(form._Data),
                        onChange: () => {
                            const dataF = form._Data;
                            let dtInicio = new Date((dataF.IsAllDay ? dataF.FechaInicioYMD : dataF.FechaUnicaYMD) + " ");
                            let nRec = 0;
                            const tipoRec = dataF.TipoRecurrencia;
                            switch (tipoRec) {
                                case Entidad.CCalendarioTipoRecurrencia.Diaria:
                                    dtInicio.setMonth(dtInicio.getMonth() + 1)
                                    dtInicio.setDate(dtInicio.getDate() - 1)
                                    nRec = 30;
                                    break;
                                case Entidad.CCalendarioTipoRecurrencia.Semanal:
                                    dtInicio.setMonth(dtInicio.getMonth() + 3)
                                    nRec = 13;
                                    break;
                                case Entidad.CCalendarioTipoRecurrencia.Mensual:
                                    dtInicio.setMonth(dtInicio.getMonth() + 12)
                                    nRec = 12;
                                    break;
                                case Entidad.CCalendarioTipoRecurrencia.Anual:
                                    dtInicio.setFullYear(dtInicio.getFullYear() + 5)
                                    nRec = 5;
                                    break;
                            }

                            switch (dataF.TipoFinRecurrencia) {
                                case Entidad.CCalendarioFinRecurrencia.Fecha:
                                    const nRecurrencies = form._ControlsData.get("NRecurrencias").row.select<HTMLInputElement>("#NRecurrencias").node();
                                    nRecurrencies.value = nRec + ""
                                    break;
                                case Entidad.CCalendarioFinRecurrencia.Numero:
                                    const dtFinRec = form._ControlsData.get("FechaFinRecurrenciaYMD").row.select<HTMLInputElement>("#FechaFinRecurrenciaYMD").node();
                                    dtFinRec.value = UIUtilTime._FmtToInputDate(dtInicio)
                                    break;
                            }
                        },
                        onSelect: (data) => {
                            const tipoRecM = (form._ControlsData.get("VarianteRecurrencia").instance as SelectV2);
                            tipoRecM._RefreshList();
                            if (data != Entidad.CCalendarioTipoRecurrencia.Mensual) {
                                tipoRecM._valueSelect(Entidad.CCalendarioTipoRecurrenciaMensual.NoAplica);
                            }
                        },
                    }
                },
                {
                    model: "TipoFinRecurrencia", type: "radioList", labelText: _L("calendario.finaliza"),
                    radioListAttr: {
                        ValueMember: "Id",
                        DisplayMember: "Name",
                        ReturnOnlyValueMember: true,
                        required: true,
                        Data: <IDatoBase[]>[
                            { Id: Entidad.CCalendarioFinRecurrencia.Fecha, Name: _L("calendario.finaliza_fecha") },
                            { Id: Entidad.CCalendarioFinRecurrencia.Numero, Name: _L("calendario.finaliza_max") },
                        ],
                        Direction: "vertical",
                        OnStepItemUI: (container, _, d: IDatoBase) => setTimeout(() => {
                            if (d.Id == Entidad.CCalendarioFinRecurrencia.Fecha)
                                container.append(() => form._ControlsData.get("FechaFinRecurrenciaYMD").row.node())
                            else if (d.Id == Entidad.CCalendarioFinRecurrencia.Numero)
                                container.append(() => form._ControlsData.get("NRecurrencias").row.node())
                        })
                    }
                },
                {
                    model: "FechaFinRecurrenciaYMD", type: "input", inputAttr: { type: "date" },
                    onValidate: (val) => {
                        const dataFrm = form._Data;
                        const conRecurrencia = dataFrm.TipoRecurrencia != Entidad.CCalendarioTipoRecurrencia.SinRecurrencia;
                        const tipoReqFecha = dataFrm.TipoFinRecurrencia == Entidad.CCalendarioFinRecurrencia.Fecha;
                        if (!conRecurrencia || !tipoReqFecha) return true;
                        if (!dataFrm.FechaFinRecurrenciaYMD) return false;
                        else if (!isNewEvent) return true
                        const dt = new Date(`${val}T00:00`);
                        const dtFin = new Date(`${dataFrm.IsAllDay ? dataFrm.FechaInicioYMD : dataFrm.FechaUnicaYMD}T00:00`);
                        return dt.getTime() >= dtFin.getTime();
                    },
                },
                {
                    model: "NRecurrencias", type: "input", inputAttr: { type: "number" }, labelText: _L("calendario.recurrencias"),
                    onValidate: (val) => {
                        const dataFrm = form._Data;
                        const conRecurrencia = dataFrm.TipoRecurrencia != Entidad.CCalendarioTipoRecurrencia.SinRecurrencia;
                        const tipoReqNumero = dataFrm.TipoFinRecurrencia == Entidad.CCalendarioFinRecurrencia.Numero;
                        if (!conRecurrencia || !tipoReqNumero) return true;
                        return val > 0
                    },
                },
                {
                    model: "VarianteRecurrencia", type: "selectMaterial", labelText: _L("calendario.tiporecurrenciamensual"),
                    selectMaterialAttr: {
                        valueMember: "Id",
                        displayMember: "Name",
                        required: true,
                        Data: () => GetOpcionesRecurrenciaMensual(form._Data),
                    }
                }
            ],
            OnAssignData: (data, controls) => {
                if (!data) return;
                // TEMPORAL: FECHA FIN -> OCULTAR
                // FIXME: QUITAR MAX EN FECHA DE INICIO AL QUITAR EL CAMBIO "//TEMPORAL"
                // controls.get("FechaInicioYMD").selection.attr("max", data.FechaFinYMD);
                // controls.get("FechaFinYMD").selection.attr("min", data.FechaInicioYMD);
            },
            BuildView: (container, controlsForm, form) => {
                form._Form.classed("calendarios_form_event", true);
                container.append(() => controlsForm.get("Nombre").row.node());

                container.append(() => controlsForm.get("IsAllDay").row.node());

                container.append(() => controlsForm.get("FechaUnicaYMD").row.node());

                container.append(() => controlsForm.get("FechaInicioYMD").row.node())
                container.append(() => controlsForm.get("HoraInicioHM").row.node())

                // TEMPORAL: FECHA FIN -> OCULTAR
                // container.append(() => controlsForm.get("FechaFinYMD").row.node())
                container.append(() => controlsForm.get("HoraFinHM").row.node())

                container.append(() => controlsForm.get("IdCalendarios").row.node());

                container.append("div").attr("class", "row").append("b").text(_L("calendario.recurrencia"));
                container.append(() => controlsForm.get("TipoRecurrencia").row.node());
                container.append(() => controlsForm.get("VarianteRecurrencia").row.node());
                container.append(() => controlsForm.get("TipoFinRecurrencia").row.node());
            },
        })
        return form;
    }

    // FIXME // FIXME // FIXME
    // * Agregar dando click sobre el control en el area de un día, cuando es Vista=Dia,Semana
    // * Acciones: Agregar, Editar, Eliminar
    // FIXME // FIXME // FIXME

    private async AssignDataForm(form: FormGenerator, evento: Partial<IEventoCalendario>, infoEvent?: Entidad.IEventoConfig) {
        // console.log(infoEvent)
        // const esAgregar = ;
        let eventoF: Partial<IEventForm>;
        if (!evento.Id) { // Es Nuevo
            const [focusDateA, focusDateB] = (() => {
                if (evento.Inicio) {
                    return [new Date(evento.Inicio), new Date(evento.Fin)];
                }
                const now = new Date();
                const dt = this.calendar._GetFocusDate();
                const dtTimeNow = new Date(dt)
                dtTimeNow.setHours(now.getHours(), now.getMinutes())

                let dtTimeNearest = new Date(dtTimeNow);
                dtTimeNearest.setMinutes(dtTimeNearest.getMinutes() + 30)

                if (dtTimeNearest.getDate() != dtTimeNow.getDate()) {
                    dtTimeNearest = dtTimeNow
                }

                const dtInicio = new Date(dtTimeNearest);
                dtInicio.setHours(dtInicio.getHours(), 0, 0, 0);
                const dtFin = new Date(dtInicio);
                dtFin.setHours(dtInicio.getHours() + 1);
                if (dtInicio.getDate() != dtFin.getDate()) dtFin.setMinutes(dtFin.getMinutes() - 1)
                return [dtInicio, dtFin];
            })()
            console.warn("focusDate: ", focusDateA, focusDateB);
            const finReqInit = (() => {
                const dt = new Date(focusDateB)
                dt.setMonth(dt.getMonth() + 2);
                return dt
            })()
            // const dtStart = new Date(focusDate);
            // const dtEnd = new Date(focusDate);
            // dtEnd.setDate(dtEnd.getDate() + 1)
            // dtEnd.setMilliseconds()
            const idCal = this.calendar._GetCalendarsSelected()
                .sort((a, b) => Number(a.IdGrupo) - Number(b.IdGrupo))[0]?.Id || 0;
            // No consulta solo asigna Fecha, IdCalendario, TodoELDia
            eventoF = {
                ...evento,
                IdCalendarios: [idCal],
                TipoRecurrencia: Entidad.CCalendarioTipoRecurrencia.SinRecurrencia,
                VarianteRecurrencia: Entidad.CCalendarioTipoRecurrenciaMensual.NoAplica,
                FechaFinRecurrenciaYMD: UIUtilTime._FmtToInputDate(finReqInit),
                NRecurrencias: 2,
                TipoFinRecurrencia: Entidad.CCalendarioFinRecurrencia.Fecha,
                FechaUnicaYMD: UIUtilTime._FmtToInputDate(focusDateA),
                FechaInicioYMD: UIUtilTime._FmtToInputDate(focusDateA),
                HoraInicioHM: UIUtilTime._FmtToInputTime(focusDateA),
                FechaFinYMD: UIUtilTime._FmtToInputDate(focusDateB),
                HoraFinHM: UIUtilTime._FmtToInputTime(focusDateB),
                IsAllDay: false,
            }
        } else {
            eventoF = {
                Nombre: evento.Nombre,
                IdCalendarios: [evento.IdCalendario],
                TipoRecurrencia: Entidad.CCalendarioTipoRecurrencia.SinRecurrencia,
                VarianteRecurrencia: Entidad.CCalendarioTipoRecurrenciaMensual.NoAplica,
                TipoFinRecurrencia: Entidad.CCalendarioFinRecurrencia.Fecha,
                FechaFinRecurrenciaYMD: null,
                NRecurrencias: null,
                IsAllDay: (UIUtilTime._GetTimeElapsedSimple(new Date(evento.Inicio), new Date(evento.Fin)).Hours() == 24),
                ReferenceBlock: evento.BlockReference
            }

            if (infoEvent) {
                // eventoF.IsAllDay = infoEvent.IsAllDay;
                eventoF.TipoRecurrencia = infoEvent.TipoRecurrencia;
                eventoF.VarianteRecurrencia = infoEvent.VarianteRecurrencia;
                eventoF.TipoFinRecurrencia = infoEvent.TipoFinRecurrencia;
                eventoF.FechaFinRecurrenciaYMD = UIUtilTime._FmtToInputDate(infoEvent.FinRecurrencia, evento.IdEscuela);
                eventoF.NRecurrencias = infoEvent.NRecurrencias;
            }
            if (eventoF.ReferenceBlock) {
                eventoF.FechaInicioYMD = UIUtilTime._FmtToInputDate(eventoF.ReferenceBlock.Inicio);
                const end = new Date(eventoF.ReferenceBlock.Fin)
                end.setDate(end.getDate() - 1);
                eventoF.FechaFinYMD = UIUtilTime._FmtToInputDate(end);
            } else {
                eventoF.FechaInicioYMD = UIUtilTime._FmtToInputDate(evento.Inicio);
                eventoF.FechaFinYMD = UIUtilTime._FmtToInputDate(evento.Inicio);
            }
            eventoF.FechaUnicaYMD = UIUtilTime._FmtToInputDate(evento.Inicio);
            eventoF.HoraInicioHM = (!eventoF.IsAllDay) ? UIUtilTime._FmtToInputTime(new Date(evento.Inicio)) : "10:00";
            eventoF.HoraFinHM = (!eventoF.IsAllDay) ? UIUtilTime._FmtToInputTime(new Date(evento.Fin)) : "10:30";
        }
        form._AsignaData(eventoF);
        return infoEvent;
    }
}

/**
 * Si es {@link IEventForm.TodoElDia}; se usa {@link IEventForm.FechaUnicaYMD} (1 día)
 */
function CountDaysFrmInputs({ FechaInicioYMD, FechaFinYMD, IsAllDay }: IEventForm) {
    if (!IsAllDay)
        return 1;
    if (IsAllDay) return 1; // TEMPORAL: OCULTAR FECHA FIN
    if (!FechaInicioYMD || !FechaFinYMD)
        return 0;
    const dtInicio = new Date(`${FechaInicioYMD}T00:00`);
    const dtFin = new Date(`${FechaFinYMD}T00:00`);
    //if (FechaInicioYMD == FechaFinYMD) {
    dtFin.setDate(dtFin.getDate() + 1);
    // }
    return CountDays(dtInicio, dtFin);
}

function CountDays(dtInicio: Date, dtFin: Date) {
    return UIUtilTime._GetTimeElapsedSimple(dtInicio, dtFin).Days();
}

// FIXME: VALIDAR CAMPOS PARA INICIO Y FIN
function ValideFormDateRange({ FechaUnicaYMD, FechaInicioYMD, HoraInicioHM, FechaFinYMD, HoraFinHM, IsAllDay }: IEventForm) {
    // if (((!FechaInicioYMD || !FechaFinYMD) && IsAllDay) || (!IsAllDay && (!HoraInicioHM || !HoraFinHM))) {
    // TEMPORAL: FechaFinYMD -> OCULTO
    if ((!FechaInicioYMD && IsAllDay) || (!IsAllDay && (!HoraInicioHM || !HoraFinHM))) {
        return false;
    }

    HoraInicioHM = IsAllDay ? "00:00" : HoraInicioHM;
    HoraFinHM = IsAllDay ? "00:00" : HoraFinHM;

    const dtInicio = new Date(`${IsAllDay ? FechaInicioYMD : FechaUnicaYMD}T${HoraInicioHM}`);
    // const dtFin = new Date(`${IsAllDay ? FechaFinYMD : FechaUnicaYMD}T${HoraFinHM}`);
    // TEMPORAL: FechaFinYMD -> OCULTO
    const dtFin = new Date(`${IsAllDay ? FechaInicioYMD : FechaUnicaYMD}T${HoraFinHM}`);

    if (IsAllDay) {
        return dtFin.getTime() >= dtInicio.getTime();
    }
    return dtFin.getTime() > dtInicio.getTime();
}

function GetTiposRecurrencia(dataF: IEventForm) {
    const result: IDatoBase[] = [];
    const tipos: { [k in Entidad.CCalendarioTipoRecurrencia]: IDatoBase } = {
        [Entidad.CCalendarioTipoRecurrencia.SinRecurrencia]: { Id: Entidad.CCalendarioTipoRecurrencia.SinRecurrencia, Name: _L("calendario.cal_tiporec_sin") },
        [Entidad.CCalendarioTipoRecurrencia.Diaria]: { Id: Entidad.CCalendarioTipoRecurrencia.Diaria, Name: _L("calendario.cal_tiporec_diaria") },
        [Entidad.CCalendarioTipoRecurrencia.Semanal]: { Id: Entidad.CCalendarioTipoRecurrencia.Semanal, Name: _L("calendario.cal_tiporec_semanal") },
        [Entidad.CCalendarioTipoRecurrencia.Mensual]: { Id: Entidad.CCalendarioTipoRecurrencia.Mensual, Name: _L("calendario.cal_tiporec_mensual") },
        [Entidad.CCalendarioTipoRecurrencia.Anual]: { Id: Entidad.CCalendarioTipoRecurrencia.Anual, Name: _L("calendario.cal_tiporec_anual") },
    };
    const countDays = CountDaysFrmInputs(dataF);
    if (countDays < 366)
        result.push(tipos[Entidad.CCalendarioTipoRecurrencia.Anual]);
    if (countDays < 29)
        result.push(tipos[Entidad.CCalendarioTipoRecurrencia.Mensual]);
    if (countDays < 8)
        result.push(tipos[Entidad.CCalendarioTipoRecurrencia.Semanal]);
    if (countDays < 2)
        result.push(tipos[Entidad.CCalendarioTipoRecurrencia.Diaria]);
    result.push(tipos[Entidad.CCalendarioTipoRecurrencia.SinRecurrencia]);
    return result.reverse();
}

function GetMonthlyDtInfo(fecha: Date) {
    const dayWk = UIUtilTime._GetDayInWeek(fecha)
    // Numero de repetición de dia en el mes
    // Determina el límite Inicio / Fin
    const initLimit = new Date(fecha);
    initLimit.setDate(1);
    const endLimit = new Date(initLimit);
    endLimit.setMonth(endLimit.getMonth() + 1);
    endLimit.setDate(0);
    // Obtener los casos anteriores
    let countDaysBf = 0;
    let auxDateEval = new Date(fecha);
    auxDateEval.setDate(auxDateEval.getDate() - 7);
    while (auxDateEval.getTime() >= initLimit.getTime()) {
        countDaysBf++;
        auxDateEval.setDate(auxDateEval.getDate() - 7);
    }
    // Obtener los casos posteriores
    let countDaysAf = 0;
    auxDateEval = new Date(fecha);
    auxDateEval.setDate(auxDateEval.getDate() + 7);
    while (auxDateEval.getTime() <= endLimit.getTime()) {
        countDaysAf++;
        auxDateEval.setDate(auxDateEval.getDate() + 7);
    }
    const isLast = countDaysAf == 0;
    const nDay = countDaysBf + 1
    return {
        isLast,
        nDay,
        dayWk
    }
}

function GetOpcionesRecurrenciaMensual(dataForm: IEventForm) {
    const result: (IDatoBase & { NDays?: number })[] = [];
    if (dataForm.TipoRecurrencia != Entidad.CCalendarioTipoRecurrencia.Mensual) {
        result.push({ Id: Entidad.CCalendarioTipoRecurrenciaMensual.NoAplica, Name: "" })
        return result;
    }
    // Para determinar -> Fecha de Inicio
    // >> FechaInicioYMD -> Cuando -> Sea todo el día +HoraCero
    // >> FechaUnicaYMD -> Cuando -> No sea todo el día +HoraInicioHM
    let strInitDate: string;
    if (dataForm.IsAllDay) strInitDate = dataForm.FechaInicioYMD + " 00:00";
    else strInitDate = dataForm.FechaUnicaYMD + " " + dataForm.HoraInicioHM;
    const dtInicio = new Date(strInitDate);

    const { isLast, nDay, dayWk } = GetMonthlyDtInfo(dtInicio)

    const numsPosition: { [k in number]: string } = {
        1: "primer",
        2: "segundo",
        3: "tercer",
        4: "cuarto"
    }

    // Construcción de opciones
    result.push({ Id: Entidad.CCalendarioTipoRecurrenciaMensual.LiteralDate, Name: _L("calendario.monthly_rec_literal", dtInicio.getDate()) })
    if (nDay != 5) {
        result.push({ Id: Entidad.CCalendarioTipoRecurrenciaMensual.NDay, Name: _L("calendario.monthly_rec_nday", numsPosition[nDay], UIUtilTime._GetDayName(dayWk, "long", false)), NDays: nDay })
    }
    if (isLast) {
        result.push({ Id: Entidad.CCalendarioTipoRecurrenciaMensual.LastDay, Name: _L("calendario.monthly_rec_lastday", UIUtilTime._GetDayName(dayWk, "long", false)) })
    }
    return result;
}

function BuildEventItems({ FechaInicioYMD, HoraInicioHM, FechaFinYMD, HoraFinHM, IsAllDay, TipoRecurrencia, FechaFinRecurrenciaYMD, NRecurrencias }: IEventForm): IEventoCalendario[] {
    console.warn("BuildEventItems", IsAllDay, FechaInicioYMD, HoraInicioHM, FechaFinYMD, HoraFinHM);

    const events: IEventoCalendario[] = [];
    HoraInicioHM = IsAllDay ? "00:00" : HoraInicioHM;
    HoraFinHM = IsAllDay ? "00:00" : HoraFinHM;
    const dtInicio = new Date(`${FechaInicioYMD}T${HoraInicioHM}`);
    const dtFin = new Date(`${FechaFinYMD}T${HoraFinHM}`);

    console.debug(">>", dtInicio, dtFin);


    console.warn(...events);
    return [];
}