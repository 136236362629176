import { Main } from "../../Main";
import { DataModuloMain } from "../../data/ModuloMain";
import { Router } from "../../routes/Router";
import { IRouteItem, TRoutePath } from "../../routes/Routes";
import { VentanaBase } from "../controlD3/AVentanaBase";
import { NotificacionV2 } from "../controlD3/NotificacionV2";
import { UIUtilLang } from "../util/Language";

type TParamType = string | number | boolean | string[] | number[] | boolean[];
export type THashParams = string | URLSearchParams | { [k in string]: TParamType };

export namespace UIWindowManager {
    const SEPARATOR = "--";
    const EVENTPARAMSCHANGENAME = "urlparamschange";
    const EVENTHASHPATHCHANGENAME = "urlhashpathchange" as const;
    export type HashEvent = CustomEvent<{
        Path: TRoutePath,
        Params: URLSearchParams;
    }>
    export interface IHashInfo {
        Path: TRoutePath;
        Params: URLSearchParams;
        CompleteHash: string;
    }

    const HISTORY: IHashInfo[] = [];

    export function _OnServiceEvent(requestID: DataModuloMain.TipoRequestMonitorId, reloadId: number, error: Error): void {
        // console.debug("New service event:", requestID, data.Entidades.CTipoRequest[requestID], reloadId, error);
        if (_lastInstance) {
            _lastInstance._OnServiceEvent(requestID, reloadId, error);
        }
    }

    /** // TEMPORAL export */
    export var _lastInstance: VentanaBase = null;

    function CreateWindow(path: TRoutePath, itemWindow: IRouteItem): void {
        if (_lastInstance) {
            _lastInstance._Destroy();
            _lastInstance = null;
        }

        let container = Main._Template ? Main._Template._ContenedorPrincipal : null;
        _lastInstance = new itemWindow.View(container, itemWindow.IdModulo);
        _lastInstance._Mostrar();

        Main._Template?._RefreshLocationNavs(path);
    }

    // *********************************************************
    // Navigation Things
    // *********************************************************

    export function _AddOnHashPathChangeListener(callback: ((e: HashEvent) => void)) {
        document.addEventListener(EVENTHASHPATHCHANGENAME, callback as any);
        return callback;
    }

    export function _RemoveOnHashPathChangeListener(callback: ((e: HashEvent) => void)) {
        document.removeEventListener(EVENTHASHPATHCHANGENAME, callback as any);
    }

    export function _AddOnParamsChangeEventListener(callback: (e: HashEvent) => void) {
        document.addEventListener(EVENTPARAMSCHANGENAME, callback as any);
        return callback;
    }

    export function _RemoveOnParamsChangeEventListener(callback: (e: HashEvent) => void) {
        document.removeEventListener(EVENTPARAMSCHANGENAME, callback as any);
    }

    export function _SetParam(param: string, value: TParamType, onlyIfValueIsDifferent: boolean = false) {
        const currentHashInfo = GetLastHashInfo();
        if (currentHashInfo) {
            let params: URLSearchParams = new URLSearchParams(currentHashInfo.Params);
            let valueFinal = value + "";
            if (onlyIfValueIsDifferent && params.get(param) == valueFinal) {
                // console.warn("El valor del parámetro", param, "ya existe");
                return;
            }
            params.set(param, valueFinal);
            _DoHash(currentHashInfo.Path, params);
        } else {
            console.warn("current hash no found");
        }
    }

    export function _RemoveParam(param: string) {
        const currentHashInfo = GetLastHashInfo();
        let params: URLSearchParams;
        if (currentHashInfo?.Params) {
            params = new URLSearchParams(currentHashInfo.Params);
            params.delete(param);
        }
        _DoHash(currentHashInfo.Path, params);
    }

    export function _GetCurrentPath() {
        return GetLastHashInfo()?.Path;
    }

    export function _GetCurrentParams() {
        return new URLSearchParams(GetLastHashInfo()?.Params);
    }

    /**
     * @param last (```default: 0```)
     *  ```
     *  = 0: Hash info actual (last - current)
     *  = 1: Hash info anterior
     * ...
     * ``` */
    export function _GetHashInfoInHistory(last: number = 0) {
        const nInHistory = HISTORY.length - (last + 1);
        const itemHistory = HISTORY[nInHistory];
        return itemHistory;
    }

    // export function fn_GetCurrentParamsAsObject<T>(): ({ [K in keyof T]: string }) {
    //     const currentHashInfo = GetLastHashInfo();
    //     const result = <{ [K in keyof T]: string }>{};
    //     if (currentHashInfo.Params) {
    //         currentHashInfo.Params
    //             ?.forEach((value, key) => {
    //                 result[key] = value;
    //             })
    //     }
    //     return result;
    // }

    export function _DoLastHashStatusFromPath(path: TRoutePath) {
        const lastHash = Array.from(HISTORY).reverse()
            .find(d => (d[0] == path));

        if (lastHash) {
            _DoHash(lastHash.Path, lastHash.Params)
        } else {
            console.warn("DoLastHash", path, "no found");
        }
    }

    export function _OnHashControl(hash: string): boolean {
        const newHashInfo = GetHashInfo(hash);
        const viewInfo = Router._GetRouteView(newHashInfo.Path);
        const lastHashInfo = GetLastHashInfo();

        if (viewInfo) {
            HISTORY.push(newHashInfo);

            if (newHashInfo.Path != lastHashInfo?.Path) {
                document.dispatchEvent(<HashEvent>new CustomEvent(EVENTHASHPATHCHANGENAME, {
                    detail: {
                        Path: newHashInfo.Path,
                        Params: new URLSearchParams(newHashInfo.Params),
                    }
                }));
                // const isSameLastInstance = _lastInstance instanceof viewInfo.View;
                // if (!isSameLastInstance) {
                //     CreateWindow(viewInfo);
                // }
                CreateWindow(newHashInfo.Path, viewInfo);
            }
            else if (newHashInfo.Params?.toString() != lastHashInfo.Params?.toString()) {
                console.warn("OnHashChanged");
                document.dispatchEvent(<HashEvent>new CustomEvent(EVENTPARAMSCHANGENAME, {
                    detail: {
                        Path: newHashInfo.Path,
                        Params: new URLSearchParams(newHashInfo.Params),
                    }
                }))
            }
            // console.warn("DoHash Finish!");
        }
        else {
            console.warn("-d", "No window found!!");
            if (viewInfo === undefined) NotificacionV2._Mostrar(UIUtilLang._GetUIString("general", "notif_infonodisponible"), "ADVERTENCIA");
            return false;
        }
        return true;
    }

    export function _DoHash(path: TRoutePath, params?: THashParams) {
        // console.warn("DoHash Init >>", path, params);
        // let lastWindowStatus = _lastInstance?.met_GetLastStatusToParams();
        let newHashLocation = GetHashString(path, params);
        window.location.assign(newHashLocation);
    }

    export function _DoHashReplace(path: TRoutePath, params?: THashParams) {
        console.log("DoHashReplace Init >>", path, params);
        let newHashLocation = GetHashString(path, params);
        window.location.replace(newHashLocation);
    }

    export function _HistoryBackEval(pathInstead: TRoutePath) {
        if (HISTORY.length > 1) {
            history.back();
        } else {
            _DoHash(pathInstead);
        }
    }

    function GetLastHashInfo() {
        return HISTORY[HISTORY.length - 1];
    }

    function GetHashInfo(hash: string): IHashInfo {
        const location = hash.split(SEPARATOR)[0] as TRoutePath;
        const strParams = hash.split(SEPARATOR)[1];
        let params: URLSearchParams = undefined;

        if (strParams) {
            params = new URLSearchParams(strParams);
        }

        return {
            Path: location,
            Params: params,
            CompleteHash: hash,
        }
    }

    // function ParamsIterador(strParams: string, fnIterador: (key: string, value: string) => void) {
    //     if ('URLSearchParams' in window) {
    //         const urlParams = new URLSearchParams(strParams);
    //         for (let [key, value] of urlParams.entries()) {
    //             fnIterador(key, value);
    //         }
    //     } else {
    //         for (let item of strParams.split("&")) {
    //             let key = item.split("=")[0] as (keyof IGridParamsBase | keyof IPanelsParamsBase);
    //             let value: any = item.split("=")[1];

    //             fnIterador(key, value);
    //         }
    //     }
    // }

    function GetHashString(hash: TRoutePath, params?: THashParams) {
        let finalHash: string = hash;
        if (hash[0] != "#") {
            finalHash = "#" + hash;
        }
        if (params) {
            finalHash += SEPARATOR + new URLSearchParams(params as any).toString();
        }
        return finalHash;
    }
}
