import * as d3 from "d3";
import { group as d3Group } from "d3-array";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import DataModuloEscuela from "../../data/modulo/Escuela";
import DataModuloLogroCategoria from "../../data/modulo/LogroCategoria";
import { IConfigGridExcelExport, IGridExtraTableConfig, IGridRenderInfo, VentanaGrid } from "../controlD3/AVentanaGrid";
import { ExcelThings } from "../controlD3/ExcelExport";
import { Fields, FormGenerator } from "../controlD3/Formulario";
import { InputFileControl } from "../controlD3/InputFileControlV2";
import { Table } from "../controlD3/Tabla";
import { UIUtilIconResources } from "../util/IconResourses";

import ILogroCategoria = Entidad.ILogroCategoria;

interface ILogroCategoriaForm extends ILogroCategoria {
    Imagen?: string;
}

export class UIVentanaLogroCategorias extends VentanaGrid<ILogroCategoria>{
    constructor(content: d3.Selection<HTMLDivElement, undefined, HTMLElement, any>, modulo: Entidad.CModulo) {
        super(content, modulo, "logroscategorias");
    }

    private GetForm(action: (Entidad.CAccionPermiso.Agregar | Entidad.CAccionPermiso.Editar), datoLC = <ILogroCategoria>{}) {
        let dataForm: ILogroCategoriaForm = Object.assign({}, datoLC);
        let form = new FormGenerator<ILogroCategoriaForm>();
        let escuelasConPermiso = this.GridGetEscuelasConPermisoDeAccion(action);
        const esEditar = (action == Entidad.CAccionPermiso.Editar);

        if (esEditar && !escuelasConPermiso.find(d => d.IdKinder == datoLC.IdKinder) && DataModuloEscuela._DiccEscuela.has(datoLC.IdKinder)) {
            escuelasConPermiso.push(DataModuloEscuela._DiccEscuela.get(datoLC.IdKinder));
        }

        if (esEditar) {
            dataForm.Imagen = DataModuloLogroCategoria._GetUrlObtenerFotoCategoria(datoLC.IdCategoria);
        }

        form._Crear({
            schema: [
                {
                    type: Fields.fotoV2,
                    fotoV2Attrs: {
                        RowClase: "alinear_foto",
                        TypeValue: "base64",
                        DefaultBackgroundImage: UIUtilIconResources.CGeneral.Folder,
                        ControlForm: InputFileControl.ControlForm.Semicuadrado,
                        AspectRatio: "contain",
                        Disabled: (action == Entidad.CAccionPermiso.Editar),
                        required: true
                    },
                    model: "Imagen"
                },

                {
                    type: Fields.input,
                    inputAttr: { type: "text", required: true },
                    labelAttr: { text: "d_field_nombre" },
                    model: "Nombre"
                },
                {
                    type: Fields.selectMaterial,
                    labelAttr: { text: "d_field_nombrekinder" },
                    selectMaterialAttr: {
                        valueMember: "IdKinder",
                        displayMember: "Nombre",
                        disabled: esEditar,
                        removeBorder: esEditar,
                        required: true
                    },
                    values: escuelasConPermiso,
                    model: "IdKinder"

                }
            ],
            LabelMaxWidth: 100,
            LangModuleKeyInContext: this.labelsKeyBase,
            //Validation: (value, field, dataF, controlsForm) => Boolean(value) && Boolean(String(value).trim())
        }, dataForm);
        // console.error(this.kinders, "this.kinders")
        return form;
    }

    protected GRID_GetMenuTopGrid(): Array<Table.ITableMenuTopDefaultOptionConfig> {
        let opciones: Array<Table.ITableMenuTopDefaultOptionConfig> = [];

        if (this.GridHasPermisoAccion(Entidad.CAccionPermiso.Agregar)) {
            opciones.push({
                Label: "Agregar", Callback: () => {
                    this.OpenModal_FormularioAgregar();
                }
            });
        }
        return opciones
    }

    protected GRID_GetSelectionDataMenuV2(menuLocation: "row" | "top-selected", dataGridSelected: ILogroCategoria[]): Table.ITableMenuDataSelectedOptionConfig<ILogroCategoria>[] {
        let opciones: Array<Table.ITableMenuDataSelectedOptionConfig<ILogroCategoria>> = []

        if (this.GridHasPermisoAccion(Entidad.CAccionPermiso.Editar)) {
            opciones.push({
                Label: "Editar", MultiData: false, Callback: (datos) => {
                    this.OpenModal_FormularioEditar(datos[0]);
                }
            })
        }

        if (this.GridHasPermisoAccion(Entidad.CAccionPermiso.Eliminar)) {
            opciones.push({
                Label: "Eliminar", Callback: (datos) => {
                    this.OpenModal_EliminarDatos(datos);
                }
            });
        }
        return opciones;
    }

    protected GRID_GetFilters(): Array<Table.IParametroFiltro<ILogroCategoria>> {
        return [
            { Label: "Categoría", Field: "Nombre" }
        ]
    }

    protected GRID_GetTableConfigBase(): IGridRenderInfo<ILogroCategoria> {
        return {
            IdTabla: "LogrosCategorias",
            Title: "",
            DefaultSort: "Nombre",
            IdData: "IdCategoria",
            MinWidth: 600,
            Columns: [
                { Field: "Nombre", Label: "Categoría", Width: "50%", MinWidth: "90px" },
                { Field: "NombreKinder", Label: "Escuela", Width: "50%", MinWidth: "100px" }
            ]
        }
    }

    protected GRID_GetTableConfigAdvanced(): IGridExtraTableConfig<ILogroCategoria> {
        return null;
    }

    protected GRID_GetDataRequestID(): DataModuloMain.TipoRequestMonitorId {
        return Entidad.CTipoRequest.LogroCategoria
    }

    private OpenModal_FormularioAgregar() {
        this.GridOpenModal_ActionFormToAGridData({
            Action: Entidad.CAccionPermiso.Agregar,
            GetForm: () => this.GetForm(Entidad.CAccionPermiso.Agregar),
            OnAccept: (form) => this.Sv_AltaRegistro(form._Data),
            Width: 400
        })
    }

    private OpenModal_FormularioEditar(datoLogroCat: ILogroCategoria) {
        this.GridOpenModal_ActionFormToAGridData({
            Action: Entidad.CAccionPermiso.Editar,
            IdsEscuelas: [datoLogroCat.IdKinder],
            GetForm: () => this.GetForm(Entidad.CAccionPermiso.Editar, datoLogroCat),
            OnAccept: (form) => this.Sv_ActualizaRegistro(form._Data),
            Width: 400
        })
    }

    private OpenModal_EliminarDatos(datos: Array<ILogroCategoria>) {
        this.GridOpenModal_ProccessArrayData({
            DataToProccess: datos,
            OnError_GetItemDataTag: (dato) => dato.Nombre,
            OnGetIdEscuela: (dato) => dato.IdKinder,
            OnStepAProccess: (dato) => this.Sv_EliminaRegistro(dato)
        })
    }

    protected GRID_GetExportarConfig(dataGrid: ILogroCategoria[]): IConfigGridExcelExport<ILogroCategoria> {
        return {
            IdsEscuelas: [...new Set(dataGrid.map(d => d.IdKinder))],
            OnGetDataBySheets: async () => {
                return Array.from(d3Group(dataGrid, d => d.IdKinder))
                    .map<ExcelThings.ISheetConfig<ILogroCategoria>>(entrie => ({
                        IdSheet: entrie[0], // IdEscuela
                        SheetName: entrie[1][0].NombreKinder,
                        Data: entrie[1]
                    }))
            },
            OnGetEscuelasTagInSheet: (dataSheet) => dataSheet[0].NombreKinder,
        }
    }

    private Sv_AltaRegistro(datos: ILogroCategoriaForm) {
        return DataModuloLogroCategoria._AltaCategoria(datos);
    }

    private Sv_ActualizaRegistro(datos: ILogroCategoriaForm) {
        return DataModuloLogroCategoria._ActualizarCategoria(datos);
    }

    private Sv_EliminaRegistro(datos: ILogroCategoriaForm) {
        return DataModuloLogroCategoria._EliminaCategoria(datos);
    }
}
