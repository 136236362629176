import { UIUtilGeneral } from "../util/Util";

const TEMPLATE = document.createElement("template");

TEMPLATE.innerHTML = `
<style id="style_base">
</style>
<style>
    :host {
        border: none;
        margin: 1px;
        display: block;
        transition: scale .15s, box-shadow .15s;
        cursor: pointer;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-origin: content-box;
    }
    :host(:hover) {
        /* scale: 1.04; */
        /* filter: contrast(1.5); */
        filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.2));
    }
    :host(:focus) {
        /* scale: 1.03; */
        /* filter: contrast(1.5); */
        filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.2));
    }
    :host(:active) {
        scale: .95;
    }
</style>
<slot></slot>`;

interface IProperties {
    Dim: string;
    SrcIco: string;
    HtmlElement?: HTMLElement | SVGElement;
    Disabled: boolean;
}
const BASEPROPERTIES: IProperties = {
    Dim: "min-content",
    SrcIco: "",
    Disabled: false,
}

/** `"wc-button"`
 *
 * Attributes
 * * `dim`: Dimension LxL
 * * `src-ico`: URL
 * * `disabled`: if has attribute
 *
 * Properties
 * * `_Dim`: Dimension LxL
 * * `_SrcIco`: URL
 * * `_Disabled`: if has attribute
 * * `_HtmlElement`: HTML Text
 */
export class HTMLButton2Element extends HTMLElement {
    #styleBase: HTMLStyleElement;
    #properties: IProperties;
    #makeBlur: boolean;

    constructor() {
        super();
        this.attachShadow({ mode: "open" });
        this.#properties = { ...BASEPROPERTIES };
        this.shadowRoot.appendChild(TEMPLATE.content.cloneNode(true));

        this.#makeBlur = true;
        this.#styleBase = this.shadowRoot.querySelector("#style_base");
        this.SetStylesBase();
    }

    // *****************************************************************************
    // PRIVATE METHODS
    // *****************************************************************************

    private SetStylesBase(props?: Partial<IProperties>) {
        if (props) for (let k in props) {
            this.#properties[k] = props[k] || BASEPROPERTIES[k];
        }
        const htmlIco = this.#properties.HtmlElement
        const srcIco = htmlIco ? "" : this.#properties.SrcIco
        this.#styleBase.textContent =
            `:host {
                    --dim:${this.#properties.Dim};
                    width: var(--dim);
                    height: var(--dim);
                    ${srcIco ? `background-image: url(${srcIco});` : ""}
                    ${this.#properties.Disabled ? `opacity: 0.6; pointer-events: none; cursor: default;` : ""}
                }
                `
        // HTML-ICO
        let container: HTMLDivElement = this.shadowRoot.querySelector(":scope>.container")
        if (htmlIco) {
            // const htmlIco = UIUtilElement._CreateElementFromHTML(htmlIco)
            if (!container) {
                container = document.createElement("div")
                container.className = "container"
                this.shadowRoot.appendChild(container)
            }
            container.querySelectorAll(":scope>*").forEach(child => child.remove())
            container.append(htmlIco)
        }
        else if (container) {
            container.remove()
        }
        // DISABLED
        setTimeout(() => {
            this.tabIndex = this.#properties.Disabled ? -1 : 0;
        });
    }

    // *****************************************************************************
    // ELEMENT LIFE CICLE CALLBACKS
    // *****************************************************************************


    /** Invocado cuando el componente personalizado se conecta por primera vez al DOM del documento. */
    connectedCallback() {
        if (!this["__keydownevent"]) {
            this["__keydownevent"] = (e: KeyboardEvent) => {
                if (this.#properties.Disabled) return;
                if (e.code == "Enter" || e.code == "Space") {
                    this.style.scale = "1";
                }
            }
            this["__keyupevent"] = (e: KeyboardEvent) => {
                if (this.#properties.Disabled) return;
                if (e.code == "Enter" || e.code == "Space") {
                    this.#makeBlur = false;
                    this.click();
                    this.#makeBlur = true;
                    this.style.scale = null;
                }
                else if (e.code == "Escape") {
                    this.blur();
                }
            }
            this["__clickevent"] = (e: MouseEvent) => {
                if (this.#properties.Disabled) return;
                if (this.#makeBlur) {
                    this.blur();
                }
            }
            this.addEventListener("keydown", this["__keydownevent"]);
            this.addEventListener("keyup", this["__keyupevent"]);
            this.addEventListener("click", this["__clickevent"]);
        }
    }

    /** Invocado cuando el componente personalizado se deconecta del DOM del documento. */
    disconnectedCallback() {
        if (this["__keydownevent"]) {
            this.removeEventListener("keydown", this["__keydownevent"]);
            this.removeEventListener("keyup", this["__keyupevent"]);
            this.removeEventListener("click", this["__clickevent"]);
            this["__keydownevent"] = null;
            this["__keyupevent"] = null;
            this["__clickevent"] = null;
        }
    }

    /** Invocado cuando el componente personalizado se mueve a un nuevo documento. */
    adoptedCallback(oldDocument: Document, newDocument: Document) {
    }

    /** Invocado cuando uno de los atributos del componente personalizado es añadido, removido o modificado. */
    attributeChangedCallback(attrName: string, oldValue: string, newValue: string) {
        switch (attrName) {
            case "dim":
                this.SetStylesBase({
                    Dim: UIUtilGeneral._FixValueAsPxValue(newValue),
                });
                break;
            case "src-ico":
                if (newValue || oldValue) {
                    this.SetStylesBase({
                        SrcIco: newValue,
                    })
                }
                break;
            case "disabled":
                this.SetStylesBase({
                    Disabled: this.hasAttribute("disabled"),
                })
                break;
        }
    }

    static get observedAttributes() {
        return ['dim', 'src-ico', 'disabled'];
    }

    // *****************************************************************************
    // PUBLIC PROPERTIES
    // *****************************************************************************

    public set _Dim(value: (string | number)) {
        this.setAttribute("dim", value + "");
    }

    public set _SrcIco(value: string) {
        if (this.hasAttribute("src-ico")) {
            if (value)
                this.setAttribute("src-ico", value);
            else
                this.removeAttribute("src-ico");
        }
        else {
            this.SetStylesBase({
                SrcIco: value,
            });
        }
    }

    public set _HtmlElement(value: HTMLElement | SVGElement) {
        this.SetStylesBase({
            HtmlElement: value,
        });
    }

    public set _Disabled(value: boolean) {
        if (value) this.setAttribute("disabled", "");
        else this.removeAttribute("disabled");
    }
}
customElements.define("wc-button", HTMLButton2Element);
