import { select } from "d3";
import { ascending, group as d3Group, descending } from "d3-array";
import saveAs from "file-saver";
import JSZip from "jszip";
import printJS from "print-js";
import { MainPage } from "../../MainPage";
import { Entidad } from "../../data/Entidad";
import { Global } from "../../data/Global";
import { DataModuloMain } from "../../data/ModuloMain";
import { _LOCALDATA_GetGruposHorariosDeAlumno, _LOCALDATA_GetTutoresDeAlumno, _SvAlumnoGetBoletasEnCicloInfo, _SvAlumnoInasistenciasObtener, _SvAlumnoInfoExtraObtener, _SvAlumnoNuevoExpedienteBoleta } from "../../data/modulo/Alumno";
import { _DiccEscuelaAlumnoInfoExtra } from "../../data/modulo/EscuelaAlumnoInfoExtra";
import DataModuloMaestro from "../../data/modulo/Maestro";
import { _DiccMateriasV2 } from "../../data/modulo/MateriaV2";
import { IMapSendCincularItems, _SvNoticiaNueva } from "../../data/modulo/Noticia";
import { DataUtilAlertBot } from "../../data/util/AlertBot";
import { ArrayV2 } from "../../util/ArrayV2";
import { DateV2 } from "../../util/DateV2";
import _L from "../../util/Labels";
import { Button } from "../controlD3/Button";
import { List } from "../controlD3/List";
import { ModalThings } from "../controlD3/ModalThings";
import { NotificacionV2 } from "../controlD3/NotificacionV2";
import { PDFThings } from "../controlD3/PDFBuilder";
import { HTMLTooltipComponent } from "../controlWC/TooltipComponent";
import { UIUtilIconResources } from "../util/IconResourses";
import { UIUtilLang } from "../util/Language";
import { UIUtilTime } from "../util/Time";
import { UIUtilGeneral } from "../util/Util";
import { UIUtilViewCalificacionBoletaV2 } from "./CalificacionBoletaV2";
import { UIUtilViewCalificacionBoletaV3 } from "./CalificacionBoletaV3";
import { UIUtilViewCalificacionBoletaV4 } from "./CalificacionBoletaV4";

export namespace UIUtilViewCalificacion {
    const LastPeriodPosition = 2;

    export const IdsSchoolsUseBoletaV4 = [];

    if (Global._GLOBAL_CONF.DEBUG_MODE) {
        IdsSchoolsUseBoletaV4.push(4);
    } else if (Global._GLOBAL_CONF.RELEASE_MODE || Global._GLOBAL_CONF.BETA_MODE) {
        IdsSchoolsUseBoletaV4.push(100, 101, 8, 51);
    }

    export const MapEscuelasDireccionGeneral = new Map<number, string>();
    if (Global._GLOBAL_CONF.DEBUG_MODE) {
        MapEscuelasDireccionGeneral.set(4, "Lic. Miguel Jared Martinez Angeles");
    } else if (Global._GLOBAL_CONF.RELEASE_MODE) {
        MapEscuelasDireccionGeneral.set(102, "Lic. Cynthia Limón Monroy");
    }

    import CTipoEvaluacion = Entidad.CTipoEvaluacion;

    export interface IBoletaConfigV2 {
        Titulo?: string;
        Logo?: string;
        IdEscuela: number;
        NombreAlumno: string;
        FechaNacimiento: string;
        Nivel: string;
        Grado: string;
        Grupos: string;
        Fecha?: string;
        CicloEscolar: string;
        MateriasCalificacionesAllPeriods: ICalificacionInfoAPeriods[];
        MateriasCurrentCalificaciones: ICalificacionInfoV2[];
        MetadataTitle?: string;
        Comentario?: string;
        InfoExtra: UIUtilViewCalificacion.IInfoExtraBoleta[];
        InasistenciasAllPeriods: number[];
        InasistenciasCurrentPeriod: number; // Considerar remover
        TeachersCommentsAllPeriods: string[];
        TeachersComments: string
        TutoresCommentsAllPeriods: string[];
        TutoresNamesAllPeriods: string[];
        BoletasEnCiclo: Entidad.BoletasGeneradasInfo[];
        BoletaActualPosition: number;
    }

    export interface ICalificacionInfoAPeriods extends Omit<ICalificacionInfoV2, "Evaluaciones" | "IdEvaluadores" | "MinimoAprobatorio"> { Evaluaciones: IEvaluacionesPeriods[]; }

    interface IEvaluacionesPeriods extends Pick<IEvaluacionesV2, "IdAsignacion" | "Nombre"> { Evaluaciones: string[][]; MaxDtsEvals: string[]; MaxIdsEvals: number[]; }

    export interface ICalificacionInfoV2 {
        IdMateria: number;
        MateriaNombre: string;
        TieneCriterios: boolean;
        Evaluaciones: IEvaluacionesV2[];
        TipoEval: CTipoEvaluacion;
        MinimoAprobatorio: string;
        EvalValor: string[];
        EvalDescripcion: string[];
        IdEvaluadores: number[];
    }

    export interface IEvaluacionesV2 {
        Id: string;
        IdAsignacion: number;
        Nombre: string;
        Evaluacion: string[];
        Comentarios: string;
        // ValNombres: string[];
        // ValDescripciones: string[];
        MaxDtEval: string;
        MaxIdEval: number;
    }

    export interface ItemConfigEval { Valor: string; Descripcion: string; }

    export interface MateriasPerEvalCfg { ConfigEval: ItemConfigEval[]; MateriasCalificadas: ICalificacionInfoV2[] }

    export interface MateriasPerEvalCfgV2 { ConfigEval: ItemConfigEval[]; MateriasCalificadas: ICalificacionInfoAPeriods[]; }

    export interface IAuxInfoEvaluaciones {
        Evaluacion: string;
        FechaEval: string;
        IdEvaluacion: number;
        IdEvaluador: number;
        Observacion: string;
        TipoEval: CTipoEvaluacion;
    }
    export interface IElementoCalificado extends Entidad.IElementoCalificado {
        /** Elementos individuales de `Evaluacion (Promedio | Moda del elemento)` (separado por `"\r\n"`) */
        EvaluacionArr: string[];
        /** Valor de `Tipo` y valores por posicion de los arreglos:
         * `Evaluaciones`, `FechasEval`, `IdsEvaluaciones`, `Observaciones`
         */
        AuxInfoEvaluaciones?: IAuxInfoEvaluaciones[];
    }
    export interface ICalificacion {
        IdMateriaGrid: string;
        IdMateria: number;
        IdGrupo: number;
        TipoEval: number;
        Evaluacion: string[];
        StrMateria: string;
        ComentariosMateria: string;
        // /** @deprecated use `Evaluaciones` instead */
        Criterios: Array<IElementoCalificado & { MaxDtEval: string, MaxIdEval: number }>;
        // Evaluaciones: IElementoCalificado[];
        EvalIds?: number[][];
        ValDescripcion: string[];
        ValNombres: string[];
        MinimoAprobatorio: string;
        IdsTeachersEvaluantes: number[];
        /** Extra: Solo tiene valor real cuando es una materia sin criterios */
        IdAsignacion: number;
        MaxDtEval: string;
        MaxIdEval: number;
    }

    export interface IInfoExtraBoleta { Tag: string; Val: string; }

    export interface IMoreInfoBoleta { Inasistencias: Entidad.IInasistenciasInfo[]; }

    export interface IAlumnoBoleta {
        Alumno: Entidad.IAlumno;
        // /** @deprecated use Evaluaciones instead */
        // Calificaciones?: ICalificacion[];
        Evaluaciones: IElementoCalificado[];
        CicloEscolar: Entidad.ICicloEscolar;
        InfoExtra: IInfoExtraBoleta[];
        Inasistencias: number;
        BoletasGeneradasEnCiclo: Entidad.BoletasGeneradasInfo[];
        Comentario?: string;
    }

    interface IAlumnoBoletaFinal extends Omit<IAlumnoBoleta, keyof Pick<IAlumnoBoleta, "Evaluaciones">> {
        IdsAllEvals: number[];
        ComentariosTeachers: string;
        ComentariosFinalesTeachers: string;
        ComentariosTutores: string;
        ComentariosFinalesTutores: string;
        CurrentPeriodPos: number;
        NombreTutores: string;
        NombreTutoresFinal: string;
        /** ??? */
        CalificacionesFinal: ICalificacion[];
        IdBoletaParent: number;
    }

    type IAlumno = Entidad.IAlumno;

    export interface IInfoBoletaExpediente {
        TipoExpediente: number;
        Estado: number;
        IdPadreBoleta: number;
        IdCicloEscolar: number;
        IdNivel: number;
        IdGrado: number;
        IdsGrupos: number[];
        IdsEvaluaciones: number[];
        IdsMaterias: number[];
        IdsEvaluadores: string[];
    }

    type ItemAlumno = Pick<IAlumno, "IdChild" | "NombreCompleto"> & {
        Selected: boolean;
        IdEscuela: number;
        PDF: File;
        PDFStatus: CPDFStatus;
        PDFFileName: string;
        PageFocus: number
        AlumnoDescripcion: string;
        IdsEval: number[];
        SentExp?: boolean;
        InfoBoletaFinal: IAlumnoBoletaFinal;
        InfoBoleta?: IInfoBoletaExpediente;
    }

    enum CPDFStatus {
        Pendiente = 0,
        Construido = 1,
    }

    // Recibe un conjunto de evaluaciones deberá retornar la evalGral (Promedio, Moda) en el orden en el que fue ingresado
    // Si fue ingresado de la siguiente manera ['B+', 'C+', 'A+', 'B+', 'C+'], el resultado es ['C+', 'B+'] o ['B+', 'C+'] 
    // De manera que el resultado debe ser retornado basado en el orden inverso que fue ingresado ['B+, C+']
    export function _GetEvalGral(evaluaciones: string[], tipoEval: CTipoEvaluacion): string[] {
        evaluaciones = evaluaciones.filter(ev => ev != '');
        if (!evaluaciones.length) return [''];
        let evalGral: string[];
        const evalsOrder = {}
        let iOrder = 0;
        evaluaciones.forEach(d => {
            if (evalsOrder[d] == null) { evalsOrder[d] = iOrder; iOrder++; }
        })
        switch (tipoEval) {
            case CTipoEvaluacion.Letras:
            case CTipoEvaluacion.Colores:
            case CTipoEvaluacion.Cualitativa:
                evalGral = UIUtilGeneral._GetStrModa(evaluaciones);
                break;
            case CTipoEvaluacion.Numeros:
                const promedio = UIUtilGeneral._PromedioFromNumList(evaluaciones.map(d => Number(d)));
                evalGral = [promedio.toFixed(1)];
                break;
        }
        return evalGral.sort((a, b) => ascending(evalsOrder[a], evalsOrder[b]));
    }

    interface IResLoadInfoToBuildReport {
        successReload: boolean;
        mapInfoExtra?: Map<number, IInfoExtraBoleta[]>;
        mapOtherInfo?: Map<number, IMoreInfoBoleta>
        mapBoletasInfoChild?: Map<number, Entidad.BoletasGeneradasInfo[]>
        arrAsignacionInfo?: Entidad.IAsignacionInfo[]
    }
    // REALIZAR COMO ESTÁ EN VENTANA, SOLO SE PERMITE GENERAR BOLETAS A ALUMNOS DE LA MISMA ESCUELA
    export async function _LoadInfoToBuildReport(idsAlumnos: number[], idEscuela: number, idCicloEscolar: number, inicio?: string, fin?: string): Promise<IResLoadInfoToBuildReport> {
        let objResReloadBuildReport: IResLoadInfoToBuildReport = { successReload: true };
        // RELOAD GRUPOS - ALUMNO
        objResReloadBuildReport.successReload = await MainPage._ReloadServiceAndAwaitBool(Entidad.CTipoRequest.HorarioAlumno, idEscuela);
        if (!objResReloadBuildReport.successReload) return objResReloadBuildReport;
        // RELOAD TEACHERS: AHORA SE RECARGA SIN IMPORTAR LA ESCUELA
        objResReloadBuildReport.successReload = await MainPage._ReloadServiceAndAwaitBool(Entidad.CTipoRequest.Maestro, idEscuela);
        if (!objResReloadBuildReport.successReload) return objResReloadBuildReport;
        // RELOAD MATERIAS
        objResReloadBuildReport.successReload = await MainPage._ReloadServiceAndAwaitBool(Entidad.CTipoRequest.MateriaV2, idEscuela);
        if (!objResReloadBuildReport.successReload) return objResReloadBuildReport;
        // LOAD INASISTENCIAS: INASISTENCIAS SE OBTIENE AHORA DE CUALQUIER ESCUELA
        const mapMoreInfoChild: Map<number, IMoreInfoBoleta> = new Map<number, IMoreInfoBoleta>();
        let resInasistencias = await _SvAlumnoInasistenciasObtener(idsAlumnos, idEscuela, inicio, fin)
        if (resInasistencias.Resultado < 1) { objResReloadBuildReport.successReload = false; return objResReloadBuildReport }
        const inasistencias = resInasistencias.Datos;
        for (const idAlumno of idsAlumnos) {
            const inasistenciasAlumno = inasistencias.filter(d => d.IdAlumno == idAlumno);
            mapMoreInfoChild.set(idAlumno, { Inasistencias: inasistenciasAlumno });
        }
        // LOAD INFO BOLETAS EN CICLO 
        const resBoletasEnCicloInfo = await _SvAlumnoGetBoletasEnCicloInfo(idsAlumnos, idEscuela, idCicloEscolar);
        if (resBoletasEnCicloInfo.Resultado < 1) { objResReloadBuildReport.successReload = false; return objResReloadBuildReport; }
        const boletasInfo = resBoletasEnCicloInfo.Datos || [];
        const auxInfoAsignaciones: Entidad.IAsignacionInfo[] = ((resBoletasEnCicloInfo as any).AsignacionesData) || [];
        if (boletasInfo.length && !auxInfoAsignaciones.length) { objResReloadBuildReport.successReload = false; return objResReloadBuildReport; }
        const mapBoletasInfoChild: Map<number, Entidad.BoletasGeneradasInfo[]> = new Map<number, Entidad.BoletasGeneradasInfo[]>();
        d3Group(boletasInfo, (d) => d.IdAlumno).forEach((boletasAlumno, idAlumno) => {
            mapBoletasInfoChild.set(idAlumno, boletasAlumno);
        })
        // RELOAD INFOEXTRA
        objResReloadBuildReport.successReload = await MainPage._ReloadServiceAndAwaitBool(Entidad.CTipoRequest.EscuelaAlumnoInfoExtra, idEscuela);
        if (!objResReloadBuildReport.successReload) return objResReloadBuildReport;
        const arrInfoUseBoleta = Array.from(_DiccEscuelaAlumnoInfoExtra.values()).filter(d => d.IdEscuela == idEscuela && d.UsarEnBoleta);
        const mapInfoExtraChild: Map<number, IInfoExtraBoleta[]> = new Map<number, IInfoExtraBoleta[]>();
        for (const idAlumno of idsAlumnos) {
            let resAlumnoInfoExtra = await _SvAlumnoInfoExtraObtener(idAlumno);
            if (resAlumnoInfoExtra.Resultado < 1) { objResReloadBuildReport.successReload = false; return objResReloadBuildReport; };
            const alumnoIExtra = resAlumnoInfoExtra.Datos;
            mapInfoExtraChild.set(idAlumno, arrInfoUseBoleta.map(d => ({ Tag: d.Tags[0], Val: alumnoIExtra[d.Id] })))
        }
        objResReloadBuildReport.mapOtherInfo = mapMoreInfoChild;
        objResReloadBuildReport.mapInfoExtra = mapInfoExtraChild;
        objResReloadBuildReport.mapBoletasInfoChild = mapBoletasInfoChild;
        objResReloadBuildReport.arrAsignacionInfo = auxInfoAsignaciones;
        return objResReloadBuildReport;
    }

    export function _FilterInasistenciasByRanges(inasistencias: Entidad.IInasistenciasInfo[], periodosDtSelected: Date[], idSchoolToTimeZone: number, rangeDates?: { dtInitRange: DateV2, dtFinRange: DateV2 }): Entidad.IInasistenciasInfo[] {
        return inasistencias.filter(dInasistencia => {
            let dateInRange = false;
            const dtEval = new DateV2(dInasistencia.FechaInasistencia)._SetTimeZoneByIdSchool(idSchoolToTimeZone);
            for (const dtPeriodo of periodosDtSelected) {
                const dtInicio = new DateV2(dtPeriodo);
                const dtFin = new DateV2(dtPeriodo);
                dtFin.setMonth(dtFin.getMonth() + 1);
                dtFin.setMilliseconds(dtFin.getMilliseconds() - 1);
                if (dtEval.getTime() >= dtInicio.getTime() && dtEval.getTime() <= dtFin.getTime()) {
                    dateInRange = true;
                    break;
                }
            }
            if (rangeDates && rangeDates.dtInitRange && rangeDates.dtInitRange) {
                dateInRange = (dtEval.getTime() >= rangeDates.dtInitRange.getTime() && dtEval.getTime() <= rangeDates.dtFinRange.getTime());
            }
            return dateInRange;
        })
    }

    export function _IsDateInRange(dtEval: DateV2, periodosDtSelected: Date[], rangeDates?: { dtInitRange: DateV2, dtFinRange: DateV2 }): boolean {
        let dateInRange = false;
        for (const dtPeriodo of periodosDtSelected) {
            const dtInicio = new DateV2(dtPeriodo);
            const dtFin = new DateV2(dtPeriodo);
            dtFin.setMonth(dtFin.getMonth() + 1);
            dtFin.setMilliseconds(dtFin.getMilliseconds() - 1);
            if (dtEval.getTime() >= dtInicio.getTime() && dtEval.getTime() <= dtFin.getTime()) {
                dateInRange = true;
                break;
            }
        }
        if (rangeDates && rangeDates.dtInitRange && rangeDates.dtFinRange) {
            dateInRange = (dtEval.getTime() >= rangeDates.dtInitRange.getTime() && dtEval.getTime() <= rangeDates.dtFinRange.getTime());
        }
        return dateInRange;
    }

    export function _GetInitAndEndInPeriodosList(periodosDtSelected: Date[]): { dtInicio: DateV2, dtFin: DateV2 } {
        if (!periodosDtSelected.length) return { dtInicio: null, dtFin: null };
        periodosDtSelected.sort((a, b) => ascending(a.getTime(), b.getTime()));
        let dtInicio = new DateV2(periodosDtSelected[0]);
        let dtFin = new DateV2(periodosDtSelected[periodosDtSelected.length - 1]);
        dtFin.setMonth(dtFin.getMonth() + 1);
        dtFin.setMilliseconds(dtFin.getMilliseconds() - 1);
        return { dtInicio, dtFin }
    }

    export async function _OpenModalGenerarBoletas(dataAlumnos: IAlumnoBoleta[], auxAsignacionesInfo: Entidad.IAsignacionInfo[], onClose?: () => void) {
        const dataAlumnosFinal: IAlumnoBoletaFinal[] = dataAlumnos.map<IAlumnoBoletaFinal>(d => {
            const [materiaCaificaciones, idsEvaluaciones, boletaComentariosTeachers] = ProcesaBoletaEvaluacionesInit(d.Evaluaciones);
            // GETTING ID BOLETA PADRE
            // SI NO HAY BOLETAS GENERADAS EN EL CICLO, NO HAY IDPARENT
            // SI HAY BOLETAS GENERADAS EN CICLO, 2 OPCIONES
            // #1 Ordenar por fecha de creación, el Id de la más antigua será IdBoletaParent
            // #2 Buscar el registro que no tiene IdPadre, ese será IdBoletaParent
            const usesBoletaV4 = IdsSchoolsUseBoletaV4.includes(d.Alumno.IdKinder);

            const boletasCiclos = d.BoletasGeneradasEnCiclo || []
            const boletasParent = boletasCiclos.find(d => d.IdPadre == 0);
            const mapTutoresAlumno = _LOCALDATA_GetTutoresDeAlumno(d.Alumno.IdChild)
            const arrNombresTutores: string[] = [];
            mapTutoresAlumno.forEach((val, id) => { arrNombresTutores.push(val.NombreCompleto); })
            return {
                Alumno: d.Alumno,
                IdsAllEvals: idsEvaluaciones,
                CicloEscolar: d.CicloEscolar,
                CalificacionesFinal: materiaCaificaciones,
                Comentario: d.Comentario,
                InfoExtra: d.InfoExtra,
                Inasistencias: d.Inasistencias,
                BoletasGeneradasEnCiclo: d.BoletasGeneradasEnCiclo,
                ComentariosTeachers: usesBoletaV4 ? boletaComentariosTeachers.join("\n") : "",
                ComentariosFinalesTeachers: "",
                ComentariosTutores: "",
                ComentariosFinalesTutores: "",
                NombreTutores: usesBoletaV4 ? arrNombresTutores.join("\n") : "",
                NombreTutoresFinal: (boletasCiclos.length == LastPeriodPosition) ? (usesBoletaV4 ? arrNombresTutores.join("\n") : "") : "",
                IdBoletaParent: boletasParent?.IdBoletaGenerada || 0,
                CurrentPeriodPos: boletasCiclos.length
            }
        });
        console.warn("generarboletas procesados", dataAlumnosFinal)

        const manySchools = d3Group(dataAlumnosFinal, (d) => d.Alumno.IdKinder).size > 1;
        // Data de evaluaciones
        const dataPorAlumno = new Map<number, IAlumnoBoletaFinal>()
        // Lista de alumnos en modal de generación de boletas 
        const dataAlumnosList = new ArrayV2<ItemAlumno>();

        dataAlumnosFinal.forEach(info => {
            const alumno = info.Alumno;
            let strDescripcion = "";
            if (alumno.Matricula) {
                strDescripcion += _L("alumnos.d_field_matricula") + ": " + alumno.Matricula + "\n";
            }
            if (manySchools) {
                strDescripcion += _L("calificaciones.d_field_strescuela") + ": " + alumno.NombreKinder;
            }

            // GETTING MATERIAS && TEACHERSEVALUANTES
            const arrIdsMaterias: number[] = [];
            const arrIdsEvaluadoresMaterias: string[] = [];
            info.CalificacionesFinal.forEach(materiaCal => {
                arrIdsMaterias.push(materiaCal.IdMateria);
                arrIdsEvaluadoresMaterias.push(materiaCal.IdsTeachersEvaluantes.join(","));
            })

            dataAlumnosList.push({
                IdChild: alumno.IdChild,
                IdEscuela: alumno.KinderFiltro[0],
                NombreCompleto: alumno.NombreCompleto,
                Selected: false,
                PDF: null,
                PDFStatus: CPDFStatus.Pendiente,
                PDFFileName: _L("calificacion.boleta") + " " + UIUtilTime._GetValidatorDateYMD(new Date()) + " - " + alumno.NombreCompleto + ".pdf",
                PageFocus: 1,
                AlumnoDescripcion: strDescripcion,
                IdsEval: info.IdsAllEvals,
                InfoBoletaFinal: info,
                InfoBoleta: {
                    TipoExpediente: Entidad.CTipoExpediente.Boleta,
                    Estado: 0,
                    IdPadreBoleta: info.IdBoletaParent,
                    IdCicloEscolar: info.CicloEscolar.Id,
                    IdNivel: alumno.IdEscolaridad,
                    IdGrado: alumno.IdGrado,
                    IdsGrupos: Array.from(_LOCALDATA_GetGruposHorariosDeAlumno(alumno.IdChild).values()).map(d => d.IdGrupo),
                    IdsEvaluaciones: info.IdsAllEvals,
                    IdsMaterias: arrIdsMaterias,
                    IdsEvaluadores: arrIdsEvaluadoresMaterias,
                }
            })
            dataPorAlumno.set(alumno.IdChild, info);
        });

        ModalThings._GetModalToAProccess({
            Title: _L("calificacion.generarboleta_title"),
            Width: 1000,
            Height: "800px",
            DrawContent: (content, mt) => {
                content.classed(UIUtilGeneral.FBoxOrientation.Horizontal, true);
                let divPadre = content.append("div");
                divPadre.classed("divPadreLateral", true);

                const ctrlList = new List<ItemAlumno>()
                    ._SetParent(divPadre.node())
                    ._SetCreateTemplate((itemContainer, d) => {
                        let lbl = itemContainer
                            .classed(UIUtilGeneral.FBoxOrientation.Horizontal, true)
                            .classed(UIUtilGeneral.FBoxAlign.SpacebetweenCenter, true)
                            .append("label");
                        let sentStatus = lbl.append("img")
                            .attr("id", "sentExp")
                            .attr("src", UIUtilIconResources.CGeneral.AcceptInCircle)
                            .attr("draggable", false)
                            .classed("hide", true)
                            .style("height", "15px")
                            .style("padding-right", "5px");
                        lbl.append("span")
                            .attr("id", "name");
                        lbl.append("pre")
                            .attr("id", "description");

                        const tooltipElement = itemContainer.append<HTMLTooltipComponent>("wc-tooltip").classed("tooltip_status", true).node();
                        tooltipElement._SetObservedElementsAdvanced(
                            {
                                Target: sentStatus.node(),
                                Text: _L("calificacion.sent_as_expedient")
                            }
                        )
                    })
                    ._SetUpdateItem((itemContainer, d) => {
                        itemContainer.select("#sentExp")
                            .classed("hide", !d.SentExp);
                        itemContainer.select("#name")
                            .text(d.NombreCompleto);
                        itemContainer.select("#description")
                            .text(d.AlumnoDescripcion);

                        itemContainer.on("click", async (d) => {
                            if (!d.Selected) {
                                dataAlumnosList.forEach(d => d.Selected = false);
                                d.Selected = true;
                                if (!d.PDF || d.PDFStatus == CPDFStatus.Pendiente) {
                                    await fnAlumnoPDFBuild(d);
                                } else {
                                    ctrlList._RefreshList();
                                    PDFThings._PDFViewer(d.PDF, pdfViewerContainer.node(), (nPage) => { d.PageFocus = nPage }, d.PageFocus);
                                }
                            }
                        });

                        if (d.Selected) {
                            if (!itemContainer.select(".opciones").node()) {
                                itemContainer.style("background-color", "var(--color_primary2)");

                                const opcionesContainer = itemContainer.append("div")
                                    .attr("class", "opciones")
                                    .classed(UIUtilGeneral.FBoxOrientation.Horizontal, true)
                                    .classed(UIUtilGeneral.FBoxAlign.EndCenter, true)
                                    .style("min-width", "min-width")
                                    .style("gap", "var(--padding1)");

                                const tooltipElement = itemContainer.append<HTMLTooltipComponent>("wc-tooltip").classed("tooltip_options", true).node();

                                tooltipElement._SetObservedElementsAdvanced(
                                    {
                                        Target: opcionesContainer.append("img")
                                            .attr("class", "btn_round")
                                            .attr("src", UIUtilIconResources.CGeneral.Editar)
                                            .on("click", () => {
                                                const boletaInfo = dataPorAlumno.get(d.IdChild);
                                                EditarBoletasInfo(boletaInfo, () => {
                                                    d.PDFStatus = CPDFStatus.Pendiente;
                                                    d.PDF = null;
                                                    fnAlumnoPDFBuild(d, true);
                                                });
                                            })
                                            .node(),
                                        Text: IdsSchoolsUseBoletaV4.includes(d.IdEscuela) ? _L("boleta_v4.title_edit") : _L("boleta_v3.title_edit"),
                                    },
                                    {
                                        Target: opcionesContainer.append("img")
                                            .attr("class", "btn_round")
                                            .attr("src", UIUtilIconResources.CGeneral.Print)
                                            .on("click", () => {
                                                if (!d.SentExp) {
                                                    mt.Modal._EscKeydownEnabled = false;
                                                    SendBoletaAsExpediente([d], () => {
                                                        ctrlList._RefreshList();
                                                        mt.Modal._EscKeydownEnabled = true;
                                                        printPDF(d.PDF);
                                                    });
                                                } else {
                                                    printPDF(d.PDF);
                                                }
                                            })
                                            .node(),
                                        Text: UIUtilLang._GetUIString("general", "print"),
                                    },
                                    {
                                        Target: opcionesContainer.append("img")
                                            .attr("class", "btn_round")
                                            .attr("src", UIUtilIconResources.CGeneral.Download)
                                            .on("click", () => {
                                                if (!d.SentExp) {
                                                    mt.Modal._EscKeydownEnabled = false;
                                                    SendBoletaAsExpediente([d], () => {
                                                        ctrlList._RefreshList();
                                                        mt.Modal._EscKeydownEnabled = true;
                                                        saveAs(d.PDF, d.PDFFileName)
                                                    })
                                                } else saveAs(d.PDF, d.PDFFileName);
                                            })
                                            .node(),
                                        Text: _L("general.descargar"),
                                    },
                                    {
                                        Text: _L("calificacion.enviartutor"),
                                        Target: opcionesContainer.append("img")
                                            .attr("class", "btn_round")
                                            .attr("src", UIUtilIconResources.CGeneral.Circular2)
                                            .on("click", async () => {
                                                OpenModal_EnviarTutor(d, ctrlList)
                                            })
                                            .node(),
                                    }
                                )
                            }
                        } else {
                            itemContainer.style("background-color", null)
                            itemContainer.select(".opciones").remove();
                            itemContainer.select(".tooltip_options").remove();
                        }
                    })
                    ._SetItems(dataAlumnosList);

                let divButtons = divPadre.append("div");
                divButtons.classed("divButtons", true);

                new Button(divButtons.node(), _L("general.descargartodo"))
                    ._d3Selection
                    .on("click", async () => {
                        if (!dataAlumnosList.every(d => d.SentExp)) {
                            mt.Modal._EscKeydownEnabled = false;
                            mt.Progress.attr("oculto", false);
                            await dataAlumnosList._ForEachAwait(async (d) => {
                                await fnAlumnoPDFBuild(d, false);
                            });
                            mt.Progress.attr("oculto", true);
                            SendBoletaAsExpediente(dataAlumnosList.filter(d => !d.SentExp), () => {
                                ctrlList._RefreshList();
                                mt.Modal._EscKeydownEnabled = true;
                                fnBuildAndDownloadAllPDF();
                            });

                        } else fnBuildAndDownloadAllPDF();
                    })

                // NOTE: BOTON GENERAL MODAL ENVIAR A TUTORES
                new Button(divButtons.node(), _L("calificacion.enviartutores"))
                    ._d3Selection.classed("sendTutores", true)
                    .on("click", async () => {
                        let btnSendTutores = mt.CurrentBody.select(".divPadreLateral").select(".divButtons").select<HTMLButtonElement>(".sendTutores");
                        if (btnSendTutores.classed("btn_disable")) return;
                        btnSendTutores.classed("btn_disable", true);
                        mt.Progress.attr("oculto", false);
                        mt.Modal._DeshabilitarBtns();
                        let arrErr: ItemAlumno[] = [];
                        await dataAlumnosList._ForEachAwait(async (d) => {
                            await fnAlumnoPDFBuild(d, true);
                            if (!d.PDF || d.PDFStatus != CPDFStatus.Construido) {
                                arrErr.push(d);
                                btnSendTutores.classed("btn_disable", false);
                                return;
                            }
                        });
                        if (arrErr.length) {
                            NotificacionV2._Mostrar(_L("calificacion.pdf_fail_construct"), "ADVERTENCIA")
                            mt.Progress.attr("oculto", true);
                            mt.Modal._HabilitarBtns();
                            btnSendTutores.classed("btn_disable", false);
                            return;
                        }

                        let messageCircular: string = "";
                        ModalThings._OpenModalToProccessServiceByServiceFromAArrayBasic({
                            Title: _L("calificacion.title_confirm_envio"),
                            Message: _L("calificacion.optional_mssg"),
                            OnDrawContent: (content) => {
                                content.append("br");
                                let inputMssg = content.append("textarea").style("margin-top", "var(--padding2)").node();
                                inputMssg.oninput = e => {
                                    messageCircular = inputMssg.value;
                                }
                                if (!dataAlumnosList.every(d => d.SentExp)) content.append("b").text(_L("calificacion.adv_boletastoexp")).style("font-size", "var(--fontsize_me2)");
                            },
                            DataToProccess: dataAlumnosList,
                            Width: 440,
                            OnStepAProccess: async (d) => {
                                const iBoleta = d.InfoBoleta;
                                let blob = d.PDF;

                                let errExp: string;

                                if (!d.SentExp) {
                                    let newExpRes = await _SvAlumnoNuevoExpedienteBoleta({
                                        IdAlumno: d.IdChild,
                                        IdEscuela: d.IdEscuela,
                                        IdCicloEscolar: iBoleta.IdCicloEscolar,
                                        IdNivel: iBoleta.IdNivel,
                                        IdGrado: iBoleta.IdGrado,
                                        IdsGrupos: iBoleta.IdsGrupos,
                                        IdPadreBoleta: iBoleta.IdPadreBoleta,
                                        IdsMaterias: iBoleta.IdsMaterias,
                                        IdsEvaluadores: iBoleta.IdsEvaluadores,
                                        IdsEvaluaciones: iBoleta.IdsEvaluaciones,
                                        TipoExpediente: iBoleta.TipoExpediente,
                                        Estado: iBoleta.Estado,
                                        NInasistencias: d.InfoBoletaFinal.Inasistencias,
                                        ObservacionTeachers: d.InfoBoletaFinal.ComentariosTeachers,
                                        ObservacionTeachersFinal: d.InfoBoletaFinal.ComentariosFinalesTeachers,
                                        ComentarioTutores: d.InfoBoletaFinal.ComentariosTutores,
                                        ComentarioTutoresFinal: d.InfoBoletaFinal.ComentariosFinalesTutores,
                                        NombreTutores: d.InfoBoletaFinal.NombreTutores,
                                        NombreTutoresFinal: d.InfoBoletaFinal.NombreTutoresFinal,
                                        Archivo: { File: d.PDF, Nombre: d.PDFFileName }
                                    })

                                    if (newExpRes.Resultado > 0) d.SentExp = true;
                                    else errExp = _L("calificacion.envio_expediente");

                                    ctrlList._RefreshList();
                                }

                                let resCircular = await _SvNoticiaNueva("ByAlumnos", <IMapSendCincularItems["ByAlumnos"]>{
                                    IdEscuela: d.IdEscuela,
                                    Titulo: _L("calificacion.boleta") + " " + UIUtilTime._GetValidatorDateYMD(new Date()) + " - " + d.NombreCompleto,
                                    Mensaje: messageCircular,
                                    Archivo: new File([blob], d.PDFFileName, { type: blob.type }),
                                    Programado: null,
                                    ReqAutorizacion: false,
                                    IdAlumnos: [d.IdChild]
                                });

                                if (resCircular.Resultado < 1 || errExp) {
                                    let msg = "";
                                    if (errExp) msg += errExp;
                                    if (errExp && resCircular.Resultado < 1) msg += " - ";
                                    if (resCircular.Resultado < 1) msg += _L("calificacion.envio_circular");

                                    resCircular.Resultado = -1.33;
                                    resCircular.Mensaje = msg
                                }

                                return resCircular;
                            },
                            OnError_GetItemDataTag: (d) => {
                                return `${d.NombreCompleto}`;
                            },
                            TypeRequest: null
                        }).then((value) => {
                            btnSendTutores.classed("btn_disable", false);
                        }).catch((err) => null);
                        mt.Progress.attr("oculto", true);
                        mt.Modal._HabilitarBtns();
                    });

                const pdfViewerContainer = content.append("div")
                    .classed("pdfViewerContainer", true);

                const fnAlumnoPDFBuild = async (d: ItemAlumno, showProgres = true) => {
                    if (!d.PDF || d.PDFStatus !== CPDFStatus.Construido) {
                        if (showProgres) mt.Progress.attr("oculto", false);
                        let pdf = await GetPDFBuilded(d.IdChild, d.NombreCompleto, d.IdEscuela, d.PDFFileName);
                        d.PDF = pdf;
                        d.PDFStatus = (!pdf) ? CPDFStatus.Pendiente : CPDFStatus.Construido;
                        if (showProgres) mt.Progress.attr("oculto", true);
                        if (d.Selected) {
                            ctrlList._RefreshList();
                            PDFThings._PDFViewer(d.PDF, pdfViewerContainer.node(), (nPage) => { d.PageFocus = nPage }, d.PageFocus);
                        }
                    }
                }

                const fnBuildAndDownloadAllPDF = async () => {
                    const resultFolderName = _L("calificacion.boleta_title") + " " + UIUtilTime._GetValidatorDateYMD(new Date());
                    const zip = new JSZip();
                    const resumenFolder = zip.folder(resultFolderName);
                    mt.Progress.attr("oculto", false);
                    await dataAlumnosList
                        ._ForEachAwait(async (d) => {
                            await fnAlumnoPDFBuild(d, false);
                            let blob = d.PDF
                            resumenFolder.file(d.PDFFileName, blob);
                        })
                    zip.generateAsync({ type: "blob" })
                        .then(function (content) {
                            saveAs(content, resultFolderName);
                        })
                        .catch((e) => {
                            DataUtilAlertBot._SendError(e, "Reporte Boleta calificaciones");
                            NotificacionV2._Mostrar(UIUtilLang._GetUIString("general", "notif_fail"), "ADVERTENCIA")
                        })
                        .finally(() => {
                            mt.Progress.attr("oculto", true);
                        });
                }

                setTimeout(async () => {
                    if (dataPorAlumno.size == 1) {
                        let alumno = dataAlumnosList[0];
                        alumno.Selected = true;
                        fnAlumnoPDFBuild(alumno);
                    }
                });
            },
            OnClose: () => {
                if (onClose) {
                    onClose();
                }
            }
        });

        const GetPDFBuilded = (idAlumno: number, nombreAlumno: string, idEscuela: number, alumnoPDFFileTitle: string) => {
            // const tutores = Array.from(_LOCALDATA_GetTutoresDeAlumno(idAlumno).values()).map(d => d.NombreCompleto);
            // const { Alumno, CalificacionesFinal } = dataPorAlumno.get(idAlumno);
            const itemAlumno = dataPorAlumno.get(idAlumno);
            const boletaConfig = ProcesaEvaluacionesBoletaFinalV2(itemAlumno, alumnoPDFFileTitle, auxAsignacionesInfo);
            // const boletaConfig = ProcesaEvaluacionesBoletaV2Final(itemAlumno);
            return new Promise<File>((res, rej) => {
                if (IdsSchoolsUseBoletaV4.includes(boletaConfig.IdEscuela)) {
                    UIUtilViewCalificacionBoletaV4._BoletaPDFV4(boletaConfig).then((pdf) => {
                        res(new File([pdf.output("blob")], "Boleta"))
                    })
                } else {
                    UIUtilViewCalificacionBoletaV3._BoletaPDFV3(boletaConfig).then((pdf) => {
                        res(new File([pdf.output("blob")], "Boleta"))
                    })
                }
            })
            // return UIUtilViewCalificacionBoletaV2._BoletaDiaPDFV2(boletaConfig);

            /* return UIUtilViewCalificacionBoletaV2._BoletaDiaPDFV2({
                NombreAlumno: nombreAlumno,
                IdEscuela: idEscuela,
                Nivel: Alumno.StrEscolaridad,
                Grado: Alumno.StrGrado,
                Evaluaciones: Calificaciones.map(d => {
                    const tieneCriterios = d.Criterios.length > 0;
                    return {
                        GrupoNombre: DataModuloMain._GetDataValueFieldByName("Grupo", d.IdGrupo, "Nombre") || _L("general.nodisponible"),
                        Materias: [{
                            MateriaNombre: d.StrMateria,
                            TipoEval: d.TipoEval,
                            TieneCriterios: tieneCriterios,
                            Evaluaciones: [{
                                Nombre: "",
                                Evaluacion: [""],
                                Comentarios: "",
                            }]
                        }]
                    }
                })
            }) */

            /* return UIUtilViewCalificacionBoletaV1._BoletaDiaPDFV1({
                NombreAlumno: nombreAlumno,
                IdEscuela: idEscuela,
                Tutores: tutores,
                Grado: calificacionesAlumno.StrGrado,
                Nivel: calificacionesAlumno.StrEscolaridad,
                Evaluaciones: calificacionesAlumno.Calificaciones.map<IEvaluacionInfo>(d => {
                    return {
                        Grupo: DataModuloMain._GetDataValueFieldByName("Grupo", d.IdGrupo, "Nombre") || "No disponible",
                        Materia: d.StrMateria,
                        CriterioEval: (!d.Criterios.length) ? [{ Criterio: d.StrMateria, Evaluacion: d.Evaluacion }] : d.Criterios.map<ICriterioEval>(c => {
                            return {
                                Criterio: c.Criterio,
                                Evaluacion: c.EvaluacionArr
                            }
                        }),
                        TipoEval: d.TipoEval,
                        MinimoAprobatorio: d.MinimoAprobatorio,
                        EvalDescripcion: d.ValDescripcion,
                        EvalValor: d.ValNombres
                    }
                }),
                Comentario: calificacionesAlumno?.Comentario || null
            }) */
        }
    }

    function printPDF(pdf: File) {
        const uri = URL.createObjectURL(pdf);
        printJS({
            printable: uri,
            type: "pdf",
            showModal: true,
            onPrintDialogClose() {
                URL.revokeObjectURL(uri);
            }
        })
    }

    function OpenModal_EnviarTutor(d: ItemAlumno, ctrlList: List<ItemAlumno>) {
        let messageCircular = "";
        ModalThings._GetConfirmacionModal({
            Title: _L("calificacion.title_confirm_envio"),
            Message: _L("calificacion.optional_mssg") + ":",
            Width: 440,
            DrawContent: (content, mt) => {
                content.append("br");
                let inputMssg = content.append("textarea").style("margin-top", "var(--padding2)").node();
                inputMssg.oninput = e => {
                    messageCircular = inputMssg.value;
                }
                if (!d.SentExp) content.append("b").text(_L("calificacion.adv_boletastoexp")).style("font-size", "var(--fontsize_me2)");
            },
            OnAccept: async (mt) => {
                mt.Progress.attr("oculto", false);
                const iBoleta = d.InfoBoleta;
                if (!d.SentExp) {
                    let newExpRes = await _SvAlumnoNuevoExpedienteBoleta({
                        IdAlumno: d.IdChild,
                        IdEscuela: d.IdEscuela,
                        IdCicloEscolar: iBoleta.IdCicloEscolar,
                        IdNivel: iBoleta.IdNivel,
                        IdGrado: iBoleta.IdGrado,
                        IdsGrupos: iBoleta.IdsGrupos,
                        IdPadreBoleta: iBoleta.IdPadreBoleta,
                        IdsMaterias: iBoleta.IdsMaterias,
                        IdsEvaluadores: iBoleta.IdsEvaluadores,
                        IdsEvaluaciones: iBoleta.IdsEvaluaciones,
                        TipoExpediente: iBoleta.TipoExpediente,
                        Estado: iBoleta.Estado,
                        NInasistencias: d.InfoBoletaFinal.Inasistencias,
                        ObservacionTeachers: d.InfoBoletaFinal.ComentariosTeachers,
                        ObservacionTeachersFinal: d.InfoBoletaFinal.ComentariosFinalesTeachers,
                        ComentarioTutores: d.InfoBoletaFinal.ComentariosTutores,
                        ComentarioTutoresFinal: d.InfoBoletaFinal.ComentariosFinalesTutores,
                        NombreTutores: d.InfoBoletaFinal.NombreTutores,
                        NombreTutoresFinal: d.InfoBoletaFinal.NombreTutoresFinal,
                        Archivo: { File: d.PDF, Nombre: d.PDFFileName }
                    })

                    if (newExpRes.Resultado > 0) {
                        d.SentExp = true;
                        NotificacionV2._Mostrar(_L("calificacion.notif_boletaguardada_success"), "INFO");
                    }
                    else NotificacionV2._Mostrar(_L("calificacion.fail_expediente_process"), "ADVERTENCIA");

                    ctrlList._RefreshList();
                }

                mt.Modal._DeshabilitarBtns();
                let res = await _SvNoticiaNueva("ByAlumnos", <IMapSendCincularItems["ByAlumnos"]>{
                    IdEscuela: d.IdEscuela,
                    Titulo: _L("calificacion.boleta") + " " + UIUtilTime._GetValidatorDateYMD(new Date()) + " - " + d.NombreCompleto,
                    Mensaje: messageCircular,
                    Archivo: d.PDF,
                    Programado: null,
                    ReqAutorizacion: false,
                    IdAlumnos: [d.IdChild]
                })
                mt.Progress.attr("oculto", true);
                mt.Modal._HabilitarBtns();
                if (res.Resultado > 0) {
                    NotificacionV2._Mostrar(_L("calificacion.success_circular_process"), "INFO");
                } else {
                    NotificacionV2._Mostrar(_L("calificacion.fail_circular_process"), "ADVERTENCIA");
                }
            },
        })
    }

    function SendBoletaAsExpediente(datos: ItemAlumno[], allSent?: () => void) {
        ModalThings._OpenModalToProccessServiceByServiceFromAArrayAdvance({
            Title: _L("calificacion.enviarexpedientes"),
            IdsEscuelas: [datos[0].IdEscuela],
            AccionToHttpMessage: "send_expediente",
            Width: 300,
            OnDrawContent: (content, mt) => {
                mt.Modal._EscKeydownEnabled = false;
                mt.Modal._HeaderSelection.select(".area_actions").remove();
                mt.BtnLeft.text(_L("general.niega"));
                mt.BtnRight.text(_L("general.afirma"));
                content.append("label").text(_L("calificacion.confirm_send_as_expedient"));
            },
            OnGetDataToProccess: () => datos,
            OnStepAProccess: async (item) => {
                const iBoleta = item.InfoBoleta;
                let res = await _SvAlumnoNuevoExpedienteBoleta({
                    IdAlumno: item.IdChild,
                    IdEscuela: item.IdEscuela,
                    IdCicloEscolar: iBoleta.IdCicloEscolar,
                    IdNivel: iBoleta.IdNivel,
                    IdGrado: iBoleta.IdGrado,
                    IdsGrupos: iBoleta.IdsGrupos,
                    IdPadreBoleta: iBoleta.IdPadreBoleta,
                    IdsMaterias: iBoleta.IdsMaterias,
                    IdsEvaluadores: iBoleta.IdsEvaluadores,
                    IdsEvaluaciones: iBoleta.IdsEvaluaciones,
                    TipoExpediente: iBoleta.TipoExpediente,
                    Estado: iBoleta.Estado,
                    NInasistencias: item.InfoBoletaFinal.Inasistencias,
                    ObservacionTeachers: item.InfoBoletaFinal.ComentariosTeachers,
                    ObservacionTeachersFinal: item.InfoBoletaFinal.ComentariosFinalesTeachers,
                    ComentarioTutores: item.InfoBoletaFinal.ComentariosTutores,
                    ComentarioTutoresFinal: item.InfoBoletaFinal.ComentariosFinalesTutores,
                    NombreTutores: item.InfoBoletaFinal.NombreTutores,
                    NombreTutoresFinal: item.InfoBoletaFinal.NombreTutoresFinal,
                    Archivo: { File: item.PDF, Nombre: item.PDFFileName }
                })
                if (res.Resultado > 0) item.SentExp = true;
                return res;
            },
            OnError_GetItemDataTag: (item, container) => item.PDFFileName,
            OnEndAndCloseProccess: (datosCorrectos, allDataProccess) => {
                /* if (!allDataProccess.length) return;
                if (datosCorrectos.length !== allDataProccess.length) return; */
                if (allSent) allSent();
            },
        })
    }

    function EditarBoletasInfo(boletaInfo: IAlumnoBoletaFinal, onAccept: () => void) {
        const TeachersCommentsCurrentPeriod = boletaInfo.ComentariosTeachers || "";
        const TeachersFinalComments = boletaInfo.ComentariosFinalesTeachers || "";
        const TutoresCommentsCurrentPeriod = boletaInfo.ComentariosTutores || "";
        const TutoresFinalComments = boletaInfo.ComentariosFinalesTutores || "";
        const TutoresNames = boletaInfo.NombreTutores || "";
        const TutoresFinalNames = boletaInfo.NombreTutoresFinal || "";
        const usesBoletaV4 = IdsSchoolsUseBoletaV4.includes(boletaInfo.Alumno.IdKinder);

        // BOLETA_V4
        let inputTeacherCurrentComment: HTMLTextAreaElement;
        let inputTeacherFinalComment: HTMLTextAreaElement;
        let inputTutorCurrentComment: HTMLTextAreaElement;
        let inputTutorCurentNames: HTMLTextAreaElement;
        let inputTutorFinalComment: HTMLTextAreaElement;
        let inputTutorFinalNames: HTMLTextAreaElement;

        // BOLETA_V3
        let inputInasistencias: HTMLInputElement;

        ModalThings._GetConfirmacionModal({
            Title: usesBoletaV4 ? _L("boleta_v4.title_edit") : _L("boleta_v3.title_edit"),
            Width: usesBoletaV4 ? 520 : 250,
            DrawContent: (content, mt) => {
                mt.Modal._EnableEnterKey(false);
                content.classed(usesBoletaV4 ? "edit_bolcal_v4" : "edit_bolcal_v3", true);
                if (usesBoletaV4) {
                    // ***** TEACHERS SECTION *****
                    let contTeachersRemarks = content.append("div").classed("teachers_comment", true).classed("section", true);
                    contTeachersRemarks.append("label").text(_L("boleta_v4.teachers_remarks"))
                    const currentPeriodTeachersCont = contTeachersRemarks.append("div").classed("period_remark_teacher", true).classed("section_item_info", true);
                    currentPeriodTeachersCont.append("label").text(`${_L("boleta_v4.trimestre")} ${boletaInfo.CurrentPeriodPos + 1}:`);
                    inputTeacherCurrentComment = currentPeriodTeachersCont.append("textarea").classed("input-form", true).node();
                    // ***** TUTORES SECTION *****
                    let contenTutoresRemarks = content.append("div").classed("tutors_comment", true).classed("section", true);
                    contenTutoresRemarks.append("label").text(_L("boleta_v4.tag_tutors_comments"));
                    // ***** [#Trimestre] *****
                    const currentPeriodTutorCont = contenTutoresRemarks.append("div").classed("period_tutores", true).classed("section_item_info", true);
                    currentPeriodTutorCont.append("label").text(`${_L("boleta_v4.trimestre")} ${boletaInfo.CurrentPeriodPos + 1}:`);
                    // ***** Comentario *****
                    const currentPeriodTutors = currentPeriodTutorCont.append("div").classed("period_remark_tutor", true).classed("subsection_item_info", true);
                    currentPeriodTutors.append("label").text(`${_L("boleta_v4.comment")}:`);
                    inputTutorCurrentComment = currentPeriodTutors.append("textarea").classed("input-form", true).node();
                    // ***** NombreTutores *****
                    const tutoresNameCont = currentPeriodTutorCont.append("div").classed("tutores_name", true).classed("subsection_item_info", true);
                    tutoresNameCont.append("label").text(_L("boleta_v4.tag_tutors_names") + ":");
                    inputTutorCurentNames = tutoresNameCont.append("textarea").classed("input-form", true).node();

                    // ***** LAST PERIOD INFO *****
                    // if (boletaInfo.CurrentPeriodPos == LastPeriodPosition) {
                    const finalRemarksTeachersCont = contTeachersRemarks.append("div").classed("final_remark_teacher", true).classed("section_item_info", true);
                    finalRemarksTeachersCont.append("label").text(_L("boleta_v4.final") + ":");
                    inputTeacherFinalComment = finalRemarksTeachersCont.append("textarea").classed("input-form", true).node();
                    // ***** TUTORES SECTION ***** 
                    // *****    [Final]     *****
                    const finalTutorCont = contenTutoresRemarks.append("div").classed("final_tutores", true).classed("section_item_info", true);
                    finalTutorCont.append("label").text(_L("boleta_v4.final") + ":");
                    // *****   Comentario   *****
                    const finalRemarksTutors = finalTutorCont.append("div").classed("final_remark_tutor", true).classed("subsection_item_info", true);
                    finalRemarksTutors.append("label").text(_L("boleta_v4.comment") + ":");
                    inputTutorFinalComment = finalRemarksTutors.append("textarea").classed("input-form", true).node();
                    // ***** NombreTutores  *****
                    const tutoresNameFinalCont = finalTutorCont.append("div").classed("tutores_name", true).classed("subsection_item_info", true);
                    tutoresNameFinalCont.append("label").text(_L("boleta_v4.tag_tutors_names") + ":");
                    inputTutorFinalNames = tutoresNameFinalCont.append("textarea").classed("input-form", true).node();
                    // }

                    inputTeacherCurrentComment.value = TeachersCommentsCurrentPeriod;
                    inputTutorCurrentComment.value = TutoresCommentsCurrentPeriod;
                    inputTutorCurentNames.value = TutoresNames;

                    // if (boletaInfo.CurrentPeriodPos == LastPeriodPosition) {
                    inputTeacherFinalComment.value = TeachersFinalComments
                    inputTutorFinalComment.value = TutoresFinalComments;
                    inputTutorFinalNames.value = TutoresFinalNames;
                    // }
                } else {
                    content.classed("form_modalbase", true);
                    // ***** INASISTENCIAS SECTION *****
                    let contInasistSection = content.append("div").classed("inasist_count", true).classed("section", true);
                    const currentInasistencias = contInasistSection.append("div").classed("period_absences", true).classed("section_item_info", true);
                    currentInasistencias.append("label").text(`Inasistencias en Periodo ${boletaInfo.CurrentPeriodPos + 1}:`);
                    inputInasistencias = currentInasistencias.append("div").classed("form_row", true).append("input").classed("inasitencias", true).attr("type", "number").attr("min", 0).attr("autocomplete", "off").node();
                    inputInasistencias.value = boletaInfo.Inasistencias + '';
                }
            },
            OnAccept: () => {
                if (usesBoletaV4) {
                    boletaInfo.ComentariosTeachers = inputTeacherCurrentComment.value;
                    boletaInfo.ComentariosTutores = inputTutorCurrentComment.value;
                    boletaInfo.NombreTutores = inputTutorCurentNames.value;
                    // if (boletaInfo.CurrentPeriodPos == LastPeriodPosition) {
                    boletaInfo.ComentariosFinalesTeachers = inputTeacherFinalComment.value;
                    boletaInfo.ComentariosFinalesTutores = inputTutorFinalComment.value;
                    boletaInfo.NombreTutoresFinal = inputTutorFinalNames.value;
                    // }
                } else {
                    if (Number(inputInasistencias.value) < 0) {
                        NotificacionV2._Mostrar(_L("calificacion.inasistencias_invalid"), "ADVERTENCIA");
                        return;
                    }
                    boletaInfo.Inasistencias = Number(inputInasistencias.value);
                }
                onAccept();
            }
        })
    }

    /** Editar Comentarios y Observaciones */
    function EditarBoleta(boletaInfo: IAlumnoBoletaFinal, onAccept: () => void) {
        ModalThings._GetConfirmacionModal({
            Title: _L("boleta_v2.edit_boleta_comts"),
            Width: 845,
            DrawContent: (content, mt) => {
                mt.Progress.node()._Visible = true;
                const boletaConfig = ProcesaEvaluacionesBoletaV2Final(boletaInfo);
                UIUtilViewCalificacionBoletaV2._CreateBoletaElement(boletaConfig)
                    .then(element => {
                        mt.Progress.node()._Visible = false;
                        element.style.position = "relative";
                        content.append(() => element);
                        const elEditables = select(element).selectAll<HTMLElement, any>(`*[contenteditable="true"]`)
                            .style("background-color", "#d6f4ff")
                            .nodes();
                        if (elEditables.length)
                            setTimeout(() => elEditables[0].scrollIntoView({
                                behavior: "smooth",
                            }), 200);
                    })
            },
            OnAccept: (mt) => {
                const elEditables = mt.Modal._BodySelection.selectAll<HTMLElement, any>(`*[contenteditable="true"]`).nodes();
                elEditables.forEach(element => {
                    const id = element.id;
                    const comment = element.textContent;
                    if (id == "comentario_general") {
                        boletaInfo.Comentario = comment;
                    }
                    else if (id.startsWith("materia")) {
                        for (const mat of boletaInfo.CalificacionesFinal) {
                            const idB = GetId("materia", mat)
                            if (idB == id) {
                                mat.ComentariosMateria = comment;
                                break;
                            }
                        }
                    }
                    else if (id.startsWith("criterio")) {
                        let found = false;
                        for (const mat of boletaInfo.CalificacionesFinal) {
                            for (const cri of mat.Criterios) {
                                const idB = GetId("criterio", cri)
                                if (idB == id) {
                                    cri.Observaciones = [comment];
                                    found = true
                                    break;
                                }
                            }
                            if (found) break;
                        }
                    }
                })
                onAccept();
            },
        })
    }

    /**
     * Aquí vienen las materias del periodo actual (VentanaAlumno -> Selección de periodos / PanelAlumnosCalificaciones -> Selección en tabla)
     * @returns [EvalsPorMateria[], idsEvaluaciones[]]
     */
    function ProcesaBoletaEvaluacionesInit(evaluaciones: IElementoCalificado[]): [ICalificacion[], number[], string[]] {
        const idsEvaluacionesFinales: number[] = [];
        const evaluacionesFixed: ICalificacion[] = []; // REVISAR SI EVALUACIONES FIXED PUEDE TENER EN SU VALOR CRITERIOS, LOS VALORES DE LAS EVALUACIONES DE ESA MATERIA (CRITERIOS O MATERIASINCRITERIOS) INDISTINTAMENTE
        let comentariosBoleta: string[] = [];

        evaluaciones
            .sort((a, b) => ascending(a.Materia, b.Materia))
            .sort((a, b) => ascending((a.Tipo == CTipoEvaluacion.Numeros ? -1 : a.Tipo), (b.Tipo == CTipoEvaluacion.Numeros ? -1 : b.Tipo)))

        // d3Group(evaluaciones, d => d.IdMateria + "_" + d.Tipo)
        d3Group(evaluaciones, d => d.IdMateria)
            .forEach((evaluacionesMateria, idMateria) => {
                let { IdMateria, IdGrupo, Criterios, Materia, ValDescripcion, ValNombres, Tipo, MinimoAprobatorio, IdAsignacion } = evaluacionesMateria[0];
                // const sinCriterios = evaluacionesMateria.length == 1 && (!evaluacionesMateria[0].Criterio || evaluacionesMateria[0].Criterio == '');
                const tieneCriterios = Criterios.length > 0;
                const criteriasOrder = {};
                // if (!sinCriterios) {
                if (tieneCriterios) {
                    Criterios.forEach((criterio, iPos) => {
                        criteriasOrder[criterio] = iPos;
                    })
                }

                const evaluaciones: string[] = [];
                const idsTeacherEvaluantes: number[] = [];
                const arrMaestros = Array.from(DataModuloMaestro._DictMaestros.values());
                evaluacionesMateria.forEach((eva) => {
                    eva.IdsEvaluadores.forEach(idEvaluante => {
                        const maestroItem = arrMaestros.find(d => d.IdUsuario == idEvaluante);
                        if (maestroItem) {
                            if (!idsTeacherEvaluantes.includes(maestroItem.Id)) {
                                idsTeacherEvaluantes.push(maestroItem.Id);
                            }
                        }
                    })
                    evaluaciones.push(...eva.EvaluacionArr);
                    idsEvaluacionesFinales.push(...eva.IdsEvaluaciones);
                });

                if (tieneCriterios) {
                    evaluacionesMateria
                        .sort((a, b) => ascending(a.IdAsignacion, b.IdAsignacion))
                        .sort((a, b) => ascending(criteriasOrder[a.Criterio], criteriasOrder[b.Criterio]))
                }

                evaluacionesMateria.forEach(evalu => {
                    comentariosBoleta.push(...evalu.Observaciones)
                });

                let evalGral = [UIUtilViewCalificacion._GetEvalGral(evaluaciones, evaluacionesMateria[0].Tipo)[0]];
                let comentariosGral = ""; // NOTE Observaciones de materia en blanco

                evaluacionesFixed.push({
                    IdMateriaGrid: idMateria + "", // idMateriaGrupo,
                    IdMateria,
                    IdGrupo,
                    Evaluacion: evalGral,
                    TipoEval: Tipo,
                    Criterios: (!Criterios.length) ? [] : evaluacionesMateria.map(d => ({
                        ...d,
                        // Criterio: (d.Criterio && d.Criterio.length) ? d.Criterio : Materia,
                        Observaciones: [], // NOTE Observaciones de materia en blanco
                        MaxDtEval: [...d.FechasEval].sort((a, b) => descending(a, b))[0],
                        MaxIdEval: [...d.IdsEvaluaciones].sort((a, b) => b - a)[0]
                    })),
                    IdAsignacion: (!Criterios.length) ? IdAsignacion : null,
                    ComentariosMateria: comentariosGral,
                    MinimoAprobatorio,
                    ValNombres,
                    ValDescripcion,
                    StrMateria: Materia,
                    IdsTeachersEvaluantes: idsTeacherEvaluantes,
                    MaxDtEval: (!Criterios.length) ? [...evaluacionesMateria[0].FechasEval].sort((a, b) => descending(a, b))[0] : null,
                    MaxIdEval: (!Criterios.length) ? [...evaluacionesMateria[0].IdsEvaluaciones].sort((a, b) => b - a)[0] : null
                    // MaxDtEval: (sinCriterios) ? evaluacionesMateria[0].FechasEval.sort((a, b) => descending(a, b))[0] : null 
                })
            });

        comentariosBoleta = comentariosBoleta.filter(d => d != '');
        evaluacionesFixed.sort((a, b) => ascending(a.StrMateria, b.StrMateria));
        return [evaluacionesFixed, idsEvaluacionesFinales, comentariosBoleta];
    }

    function ProcesaEvaluacionesBoletaV2Final({ Alumno, CalificacionesFinal, Comentario }: IAlumnoBoletaFinal): UIUtilViewCalificacionBoletaV2.IBoletaConfig {
        return {
            NombreAlumno: Alumno.NombreCompleto,
            IdEscuela: Alumno.IdKinder,
            Nivel: Alumno.StrEscolaridad,
            Grado: Alumno.StrGrado,
            Comentario: Comentario,
            Evaluaciones: [...d3Group(CalificacionesFinal, d => d.IdGrupo).values()]
                .reduce((res, evalsGrupo) => {
                    const [mainItem] = evalsGrupo
                    const nombreGrupo = DataModuloMain._GetDataValueFieldByName("Grupo", mainItem.IdGrupo, "Nombre") || _L("general.nodisponible");
                    res.push({
                        GrupoNombre: nombreGrupo,
                        Materias: evalsGrupo.map(eg => {
                            const tieneCriterios = eg.Criterios.length > 0;
                            return <UIUtilViewCalificacionBoletaV2.ICalificacionInfo>{
                                MateriaNombre: eg.StrMateria,
                                TipoEval: eg.TipoEval,
                                TieneCriterios: tieneCriterios,
                                Evaluaciones: tieneCriterios
                                    ? eg.Criterios.map(d => ({
                                        Id: GetId("criterio", d),
                                        Nombre: d.Criterio,
                                        Evaluacion: d.EvaluacionArr,
                                        Comentarios: (() => {
                                            let msg = d.Observaciones.filter(o => !!o.trim()).join('. ');
                                            // if (msg.trim() && msg[msg.length - 1].trim() != ".")
                                            //     msg += ".";
                                            return msg;
                                        })(),
                                    }))
                                    : [{
                                        Id: GetId("materia", eg),
                                        Nombre: eg.StrMateria,
                                        Evaluacion: eg.Evaluacion,
                                        Comentarios: eg.ComentariosMateria,
                                    }]
                            }
                        })
                    })
                    return res;
                }, <UIUtilViewCalificacionBoletaV2.IBoletaConfig["Evaluaciones"]>[])
        }
    }

    function ProcesaEvaluacionesBoletaFinalV2({ Alumno, CalificacionesFinal, Comentario, CicloEscolar, InfoExtra, Inasistencias, BoletasGeneradasEnCiclo, ComentariosTeachers, ComentariosFinalesTeachers, ComentariosTutores, ComentariosFinalesTutores, NombreTutores: TutoresNames, NombreTutoresFinal }: IAlumnoBoletaFinal, alumnoPDFFileTitle: string, auxArrInfoBoletasBefore: Entidad.IAsignacionInfo[]): IBoletaConfigV2 {
        const escuelaLogo = DataModuloMain._GetDataValueFieldByName("Escuela", Alumno.IdKinder, "Logo");
        if (BoletasGeneradasEnCiclo && BoletasGeneradasEnCiclo.length > 0) {
            BoletasGeneradasEnCiclo.sort((a, b) => ascending(a.FechaCreacion, b.FechaCreacion));
        }

        interface IEvaluacionInfo {
            IdEvaluacion: number;
            TipoEval: Entidad.CTipoEvaluacion;
            FechaEval: string;
            NombreCriterio: string;
            IdAsignacion: number;
            IdMateria: number;
            EvalValor: string;
        }

        interface IAuxCalificacion {
            IdAsignacion: number;
            IdMateria: number;
            TipoEval: Entidad.CTipoEvaluacion;
            NombreCriterio: string;
            EvalGral: string[];
            IdsEvaluaciones: number[];
            MaxDtEval: string;
            MaxIdEval: number;
            // ValNombres: string[];
            // ValDescripciones: string[];
        }

        interface IMateriaEvaluacion {
            IdMateria: number,
            TipoEval: Entidad.CTipoEvaluacion,
            TieneCriterios: boolean,
            Evaluaciones: IEvaluacionesV2[]
        }

        BoletasGeneradasEnCiclo = BoletasGeneradasEnCiclo || [];

        const BoletasMateriasEvaluaciones: IMateriaEvaluacion[][] = [];
        const ArrComentariosTeacher: string[] = [];
        const ArrComentariosTutores: string[] = [];
        const ArrTutoresNames: string[] = [];
        const BoletaActualPosition = BoletasGeneradasEnCiclo.length;
        BoletasGeneradasEnCiclo.forEach((boleta) => {
            ArrComentariosTeacher.push((boleta.Observaciones) ? (boleta.Observaciones[0] || "") : "");
            ArrComentariosTutores.push((boleta.Observaciones) ? (boleta.Observaciones[2] || "") : "");
            ArrTutoresNames.push((boleta.Observaciones) ? (boleta.Observaciones[4] || "") : "");
        })
        ArrComentariosTeacher.push(ComentariosTeachers);
        ArrComentariosTutores.push(ComentariosTutores);
        ArrTutoresNames.push(TutoresNames);
        ArrComentariosTeacher[3] = ComentariosFinalesTeachers;
        ArrComentariosTutores[3] = ComentariosFinalesTutores;
        ArrTutoresNames[3] = NombreTutoresFinal;

        const InasistenciasPeriods: number[] = [];

        BoletasGeneradasEnCiclo.forEach((boleta) => {
            InasistenciasPeriods.push(boleta.NInasistencias);
        })

        InasistenciasPeriods.push(Inasistencias);
        BoletasGeneradasEnCiclo.forEach(boleta => {
            const arrEvaluacionesInfo: IEvaluacionInfo[] = [];
            boleta.IdsEvaluaciones.forEach((d, iPos) => {
                const idAsignacion = boleta.EvaluacionesIdAsignaciones[iPos];
                const itemAsignInfo = auxArrInfoBoletasBefore.find(a => a.IdAsignacion == idAsignacion);
                arrEvaluacionesInfo.push({
                    IdEvaluacion: boleta.IdsEvaluaciones[iPos],
                    IdAsignacion: boleta.EvaluacionesIdAsignaciones[iPos],
                    EvalValor: boleta.EvaluacionesValores[iPos],
                    NombreCriterio: (itemAsignInfo.Criterio),
                    IdMateria: itemAsignInfo.IdMateria,
                    TipoEval: itemAsignInfo.TipoEval,
                    FechaEval: boleta.EvaluacionesFechas[iPos]
                })
            })

            const arrAsignacionesEvals: IAuxCalificacion[] = [];
            d3Group(arrEvaluacionesInfo, d => d.IdAsignacion)
                .forEach((evaluacionesAsignacion, idAsignacion) => {
                    evaluacionesAsignacion.sort((a, b) => descending(a.IdEvaluacion, b.IdEvaluacion));
                    evaluacionesAsignacion.sort((a, b) => descending(a.FechaEval, b.FechaEval));
                    // const itemAsignInfo = auxArrInfoBoletasBefore.find(a => a.IdAsignacion == idAsignacion);
                    arrAsignacionesEvals.push({
                        IdAsignacion: idAsignacion,
                        IdMateria: evaluacionesAsignacion[0].IdMateria,
                        IdsEvaluaciones: evaluacionesAsignacion.map(d => d.IdEvaluacion),
                        NombreCriterio: evaluacionesAsignacion[0].NombreCriterio,
                        TipoEval: evaluacionesAsignacion[0].TipoEval,
                        EvalGral: [_GetEvalGral(evaluacionesAsignacion.map(d => d.EvalValor), evaluacionesAsignacion[0].TipoEval)[0]],
                        // ValNombres: itemAsignInfo.EvalNombres,
                        // ValDescripciones: itemAsignInfo.EvalDescripciones,
                        MaxDtEval: evaluacionesAsignacion[0].FechaEval,
                        MaxIdEval: [...evaluacionesAsignacion.map(d => d.IdEvaluacion)].sort((a, b) => b - a)[0]
                    })
                })

            const arrMateriasEvaluaciones: Array<{ IdMateria: number, TipoEval: Entidad.CTipoEvaluacion, TieneCriterios: boolean, Evaluaciones: IEvaluacionesV2[] }> = [];
            // d3Group(arrAsignacionesEvals, d => d.IdMateria + "_" + d.TipoEval)
            d3Group(arrAsignacionesEvals, d => d.IdMateria)
                .forEach((califs, idMateria) => {
                    // Evaluar si la materia en si es una materia con criterios o si la asignación que tiene corresponde a una asignación de materia sin criterios
                    // Las materias en sus registros no tienen una columna que indique si tiene criterios o no, en la configuración una materia sin criterios es un arreglo vacío
                    // De momento para saber si se trata de una materia sin criterios se considerará que:
                    // El la longitud del arreglo de califs es 1,
                    // El califs[0].NombreCriterio no tiene valor es decir es '' o null
                    const materiaSinCriterios = califs.length == 1 && (!califs[0].NombreCriterio || califs[0].NombreCriterio == '');
                    arrMateriasEvaluaciones.push({
                        IdMateria: califs[0].IdMateria,
                        TipoEval: califs[0].TipoEval,
                        TieneCriterios: !materiaSinCriterios,
                        Evaluaciones: materiaSinCriterios
                            ? [{ Id: null, IdAsignacion: califs[0].IdAsignacion, Nombre: null, Evaluacion: califs[0].EvalGral, Comentarios: null, MaxDtEval: califs[0].MaxDtEval, MaxIdEval: califs[0].MaxIdEval }]
                            : califs.map(d => ({ Id: null, IdAsignacion: d.IdAsignacion, Nombre: d.NombreCriterio, Evaluacion: d.EvalGral, Comentarios: null, MaxDtEval: d.MaxDtEval, MaxIdEval: d.MaxIdEval }))
                        // ? [{ Id: null, IdAsignacion: califs[0].IdAsignacion, Nombre: null, Evaluacion: califs[0].EvalGral, Comentarios: null, ValNombres: califs[0].ValNombres, ValDescripciones: califs[0].ValDescripciones, MaxDtEval: califs[0].MaxDtInEval }]
                        // : califs.map(d => ({ Id: null, IdAsignacion: d.IdAsignacion, Nombre: d.NombreCriterio, Evaluacion: d.EvalGral, Comentarios: null, ValNombres: d.ValNombres, ValDescripciones: d.ValDescripciones, MaxDtEval: d.MaxDtInEval }))
                    })
                })
            BoletasMateriasEvaluaciones.push(arrMateriasEvaluaciones);
        })

        BoletasMateriasEvaluaciones.push(CalificacionesFinal.map(materiaCal => {
            const tieneCriterios = materiaCal.Criterios.length > 0;
            return {
                IdMateria: materiaCal.IdMateria,
                TipoEval: materiaCal.TipoEval,
                TieneCriterios: tieneCriterios,
                Evaluaciones: tieneCriterios
                    ? materiaCal.Criterios.map(d => ({ Id: null, IdAsignacion: d.IdAsignacion, Nombre: d.Criterio, Evaluacion: d.EvaluacionArr, Comentarios: null, MaxDtEval: d.MaxDtEval, MaxIdEval: d.MaxIdEval }))
                    : [{ Id: null, IdAsignacion: materiaCal.IdAsignacion, Nombre: materiaCal.StrMateria, Evaluacion: materiaCal.Evaluacion, Comentarios: null, MaxDtEval: materiaCal.MaxDtEval, MaxIdEval: materiaCal.MaxIdEval }]
                // ? materiaCal.Criterios.map(d => ({ Id: null, IdAsignacion: d.IdAsignacion, Nombre: d.Criterio, Evaluacion: d.EvaluacionArr, Comentarios: null, ValNombres: d.ValNombres, ValDescripciones: d.ValDescripcion, MaxDtEval: d.MaxDtEval }))
                // : [{ Id: null, IdAsignacion: materiaCal.IdAsignacion, Nombre: materiaCal.StrMateria, Evaluacion: materiaCal.Evaluacion, Comentarios: null, ValNombres: materiaCal.ValNombres, ValDescripciones: materiaCal.ValDescripcion, MaxDtEval: materiaCal.MaxDtEval }]
            }
        }))


        // interface ICalificacionInfoAPeriods extends Pick<ICalificacionInfoV2, "IdMateria" | "MateriaNombre" | "EvalValor" | "EvalDescripcion" | "TieneCriterios" | "TipoEval" | "Evaluaciones" | "IdEvaluadores" | "MinimoAprobatorio"> {

        // interface ICfgEval { Nombre: string, Descripcion: string };
        // let mapEvalCfgMateria: Map<string, ICfgEval>;
        const ArrMateriasCalificaciones: ICalificacionInfoAPeriods[] = [];
        BoletasMateriasEvaluaciones.forEach((boletaMateriasEval, iPos) => {
            boletaMateriasEval.forEach(materia => {
                // mapEvalCfgMateria = new Map<string, ICfgEval>();
                // let materiaCalif = ArrMateriasCalificaciones.find(d => d.IdMateria == materia.IdMateria && d.TipoEval == materia.TipoEval);
                // const { Nombre: NombreMateria } = _DiccMateriasV2.get(materia.IdMateria);
                let materiaCalif = ArrMateriasCalificaciones.find(d => d.IdMateria == materia.IdMateria);
                const { Nombre, EvalValor, EvalDescripcion } = _DiccMateriasV2.get(materia.IdMateria);
                const materiaSinCriterios = materia.Evaluaciones.length == 1 && (!materia.Evaluaciones[0].Nombre || materia.Evaluaciones[0].Nombre == '');
                if (!materiaCalif) {
                    ArrMateriasCalificaciones.push({ IdMateria: materia.IdMateria, MateriaNombre: Nombre, TipoEval: materia.TipoEval, EvalValor: EvalValor, EvalDescripcion: EvalDescripcion, TieneCriterios: materia.TieneCriterios, Evaluaciones: [] });
                    // ArrMateriasCalificaciones.push({ IdMateria: materia.IdMateria, MateriaNombre: NombreMateria, TipoEval: materia.TipoEval, EvalValor: [], EvalDescripcion: [], TieneCriterios: materia.TieneCriterios, Evaluaciones: [] }); // FIXME: REMPLAZAR EvalValor && EvalDescripcion por LA CONJUNCIÓN "DISTINCT" DE los valores que conforman la evaluación 
                }
                materiaCalif = ArrMateriasCalificaciones.find(d => d.IdMateria == materia.IdMateria);
                // materiaCalif = ArrMateriasCalificaciones.find(d => d.IdMateria == materia.IdMateria && d.TipoEval == materia.TipoEval); //NOTE: Para cambios siguientes 
                materia.Evaluaciones.forEach(asigEval => {
                    let asigEvalsP = materiaCalif.Evaluaciones.find(d => d.IdAsignacion == asigEval.IdAsignacion);
                    // asigEval.ValNombres.forEach((_, i) => {
                    //     mapEvalCfgMateria.set(asigEval.ValNombres[i] + "_" + asigEval.ValDescripciones[i], { Nombre: asigEval.ValNombres[i], Descripcion: asigEval.ValDescripciones[i] })
                    // })
                    if (!asigEvalsP) {
                        materiaCalif.Evaluaciones.push({ IdAsignacion: asigEval.IdAsignacion, Nombre: materiaSinCriterios ? Nombre : asigEval.Nombre, Evaluaciones: [], MaxDtsEvals: [], MaxIdsEvals: [] });
                    }
                    asigEvalsP = materiaCalif.Evaluaciones.find(d => d.IdAsignacion == asigEval.IdAsignacion);
                    asigEvalsP.Evaluaciones[iPos] = asigEval.Evaluacion || [];
                    asigEvalsP.MaxDtsEvals[iPos] = asigEval.MaxDtEval || "";
                    asigEvalsP.MaxIdsEvals[iPos] = asigEval.MaxIdEval;
                })
                // const arrItemsEvalCfg = Array.from(mapEvalCfgMateria.values());
                // materiaCalif.EvalValor = arrItemsEvalCfg.map(d => d.Nombre);
                // materiaCalif.EvalDescripcion = arrItemsEvalCfg.map(d => d.Descripcion);
            })
        })
        ArrMateriasCalificaciones
            .sort((a, b) => ascending(a.MateriaNombre, b.MateriaNombre))
            .sort((a, b) => ascending((a.TipoEval == CTipoEvaluacion.Numeros ? -1 : a.TipoEval), (b.TipoEval == CTipoEvaluacion.Numeros ? -1 : b.TipoEval)))

        ArrMateriasCalificaciones.forEach(materia => {
            const CriteriosMaterias = _DiccMateriasV2.get(materia.IdMateria)?.Criterios || [];
            const HasCriteriasConfig = CriteriosMaterias.length > 0;
            const criteriasOrder = {};
            if (HasCriteriasConfig) {
                CriteriosMaterias.forEach((criterio, iPos) => {
                    criteriasOrder[criterio] = iPos;
                })
            }

            materia.Evaluaciones.forEach(asignacionEval => {
                const AsignacionCalifs: string[] = [];
                asignacionEval.Evaluaciones.forEach(evalAsig => { if (evalAsig) AsignacionCalifs.push(...evalAsig) })
                asignacionEval.Evaluaciones[3] = [_GetEvalGral(AsignacionCalifs, materia.TipoEval).reverse()[0]]; // NOTE: PUEDE TRATARSE COMO 3 O + DEPENDIENDO DE EL TAMAÑO DE PERIODOS POSIBLES
            })

            materia.Evaluaciones
                .sort((a, b) => ascending(a.IdAsignacion, b.IdAsignacion))
                .sort((a, b) => ascending(criteriasOrder[a.Nombre], criteriasOrder[b.Nombre]))
        })

        ArrMateriasCalificaciones.forEach(d => {
            d.Evaluaciones.forEach(asigEvals => {
                for (let index = 0; index < 3; index++) {
                    asigEvals.Evaluaciones[index] = asigEvals.Evaluaciones[index] || []
                    asigEvals.MaxDtsEvals[index] = asigEvals.MaxDtsEvals[index] || "";
                }
            });
        })

        return {
            Titulo: _L("boleta_v2.evaluacion"),
            Logo: escuelaLogo,
            NombreAlumno: Alumno.NombreCompleto,
            FechaNacimiento: UIUtilTime._DateFormatStandar(Alumno.FechaNacimiento),
            IdEscuela: Alumno.IdKinder,
            Grupos: Alumno.StrGrupoPrincipal.replace(/[^\x00-\x7F]/g, ""),
            Nivel: Alumno.StrEscolaridad,
            CicloEscolar: CicloEscolar.Nombre,
            Fecha: UIUtilTime._DateFormatStandar(new Date()),
            Grado: Alumno.StrGrado,
            Comentario: Comentario,
            InfoExtra: InfoExtra,
            InasistenciasCurrentPeriod: Inasistencias,
            InasistenciasAllPeriods: InasistenciasPeriods,
            MetadataTitle: alumnoPDFFileTitle,
            BoletasEnCiclo: BoletasGeneradasEnCiclo,
            MateriasCalificacionesAllPeriods: ArrMateriasCalificaciones,
            BoletaActualPosition: BoletaActualPosition || 0,
            TeachersCommentsAllPeriods: ArrComentariosTeacher,
            TeachersComments: ComentariosTeachers,
            TutoresCommentsAllPeriods: ArrComentariosTutores,
            TutoresNamesAllPeriods: ArrTutoresNames,
            MateriasCurrentCalificaciones: CalificacionesFinal.map<ICalificacionInfoV2>(materiaCal => {
                const tieneCriterios = materiaCal.Criterios.length > 0;
                const criteriasOrder = {};
                if (tieneCriterios) {
                    materiaCal.Criterios[0].Criterios.forEach((criterio, iPos) => {
                        criteriasOrder[criterio] = iPos;
                    })
                }
                return {
                    IdMateria: materiaCal.IdMateria,
                    MateriaNombre: materiaCal.StrMateria,
                    TipoEval: materiaCal.TipoEval,
                    EvalValor: materiaCal.ValNombres,
                    EvalDescripcion: materiaCal.ValDescripcion,
                    MinimoAprobatorio: materiaCal.MinimoAprobatorio,
                    TieneCriterios: tieneCriterios,
                    IdEvaluadores: materiaCal.IdsTeachersEvaluantes,
                    Evaluaciones: tieneCriterios
                        ? materiaCal.Criterios.map(d => ({
                            Id: GetId("criterio", d),
                            IdAsignacion: d.IdAsignacion,
                            Nombre: d.Criterio,
                            Evaluacion: d.EvaluacionArr,
                            Comentarios: (() => {
                                let msg = d.Observaciones.filter(o => !!o.trim()).join('. ');
                                return msg;
                            })(),
                            // ValNombres: d.ValNombres, // QUESTION
                            // ValDescripciones: d.ValDescripcion, // QUESTION
                            MaxDtEval: d.MaxDtEval,
                            MaxIdEval: d.MaxIdEval
                        }))
                            .sort((a, b) => ascending(a.Id, b.Id))
                            .sort((a, b) => ascending(criteriasOrder[a.Nombre], criteriasOrder[b.Nombre]))
                        : [{
                            Id: GetId("materia", materiaCal),
                            IdAsignacion: materiaCal.IdAsignacion,
                            Nombre: materiaCal.StrMateria,
                            Evaluacion: materiaCal.Evaluacion,
                            Comentarios: materiaCal.ComentariosMateria,
                            // ValNombres: materiaCal.ValNombres, // QUESTION
                            // ValDescripciones: materiaCal.ValDescripcion, // QUESTION
                            MaxDtEval: materiaCal.MaxDtEval,
                            MaxIdEval: materiaCal.MaxIdEval
                        }]
                }
            })
                .sort((a, b) => ascending(a.MateriaNombre, b.MateriaNombre))
                .sort((a, b) => ascending((a.TipoEval == CTipoEvaluacion.Numeros ? -1 : a.TipoEval), (b.TipoEval == CTipoEvaluacion.Numeros ? -1 : b.TipoEval)))
        }
    }

    function GetId<T extends ("materia" | "criterio")>(tipo: T, d: T extends "materia" ? ICalificacion : IElementoCalificado) {
        if (tipo == "materia")
            return `materia-${d.IdGrupo}-${d.IdMateria}`;

        return `criterio-${d.IdGrupo}-${d.IdMateria}-${(d as IElementoCalificado).IdsEvaluaciones}`
        // (<IElementoCalificado>null).IdsEvaluaciones
    }
}
