import { UIUtilGeneral } from "../util/Util";

interface IProperties {
    Dim: string;
    BorderWidth: string;
}
const TEMPLATE = document.createElement("template");
const BASEPROPERTIES: IProperties = {
    Dim: "20px",
    BorderWidth: "calc(var(--dim) / 20)",
}

TEMPLATE.innerHTML = `
<style id="style_base">
    :host {
        --dim;
        --border_width;
    }
</style>
<style>
    /* DEFAULT STYLE */

    :host {
        width: var(--dim) !important;
        height: var(--dim) !important;
        display: inline-block;
        box-sizing: border-box;
        position: relative;
        color: #007bff;
        overflow: hidden;
    }
    :host::before {
        content: '';
        display: block;
        width: var(--dim) !important;
        height: var(--dim) !important;
        border-radius: 50%;
        border-style: solid;
        border-width: var(--border_width);
        /* border-color: var(--color); */
        border-bottom-color: transparent;
        box-sizing: border-box;
        animation: rotation .8s linear infinite;
    }
    :host([center]) {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto !important;
    }
    :host([center="false"]), :host([center="off"]) {
        position: relative;
        top: auto;
        left: auto;
        right: auto;
        bottom: auto;
        margin: 0 !important;
    }
    @keyframes rotation {
        0% {
        transform: rotate(0deg);
        }
        100% {
        transform: rotate(360deg);
        }
    }
    @keyframes rotationBack {
        0% {
        transform: rotate(0deg);
        }
        100% {
        transform: rotate(-360deg);
        }
    }
</style>
`;

/** `wc-spinner`
 * Atributos
 * * `dim` Dimensiones de ancho y alto: `number` | `(number + "px")`
 * * `center` Centrar el spinner respecto al elmento relativo:
 * `(!this.hasAttribute("center") || attrValue == "false" || attrValue == "off" ? false : true)`
 * * `border-width`: `number` | `(number + "px")`
 */
export class HTMLSpinnerElement extends HTMLElement {
    #styleBase: HTMLStyleElement;
    #properties: IProperties;

    constructor() {
        super();
        this.attachShadow({ mode: "open" });
        this.#properties = { ...BASEPROPERTIES };
        this.shadowRoot.appendChild(TEMPLATE.content.cloneNode(true));

        this.#styleBase = this.shadowRoot.querySelector("#style_base");
        this.SetStylesBase();
    }

    // *****************************************************************************
    // PRIVATE METHODS
    // *****************************************************************************

    private SetStylesBase(props?: Partial<IProperties>) {
        if (props) for (let k in props) {
            this.#properties[k] = props[k] || BASEPROPERTIES[k];
        }
        this.#styleBase.textContent =
            `:host {
                    --dim:${this.#properties.Dim};
                    --border_width:${this.#properties.BorderWidth};
                }`
    }

    // *****************************************************************************
    // ELEMENT LIFE CICLE CALLBACKS
    // *****************************************************************************


    /** Invocado cuando el componente personalizado se conecta por primera vez al DOM del documento. */
    connectedCallback() {
    }

    /** Invocado cuando el componente personalizado se deconecta del DOM del documento. */
    disconnectedCallback() {
        // this.#controlContainerElement.removeEventListener("click", this.onClickEvent);
    }

    /** Invocado cuando el componente personalizado se mueve a un nuevo documento. */
    adoptedCallback(oldDocument: Document, newDocument: Document) {
    }

    /** Invocado cuando uno de los atributos del componente personalizado es añadido, removido o modificado. */
    attributeChangedCallback(attrName: string, oldValue: string, newValue: string) {
        switch (attrName) {
            case "dim":
                this.SetStylesBase({
                    Dim: UIUtilGeneral._FixValueAsPxValue(newValue),
                });
                break;
            // case "center":
            //     this.SetStylesBase({
            //         Center: (!this.hasAttribute("center") || newValue == "false" || newValue == "off" ? false : true)
            //     });
            case "border-width":
                this.SetStylesBase({
                    BorderWidth: UIUtilGeneral._FixValueAsPxValue(newValue),
                })
                break;
        }
    }

    static get observedAttributes() {
        return ['dim', "border-width"];
    }

    // *****************************************************************************
    // PUBLIC PROPERTIES
    // *****************************************************************************

    public set _Dim(value: (string | number)) {
        if (value != null) this.setAttribute("dim", value + "");
        else this.removeAttribute("dim");
    }

    public set _Center(value: boolean) {
        if (value && !this.hasAttribute("center")) {
            this.setAttribute("center", "");
        }
        else if (!value && this.hasAttribute("center")) {
            this.removeAttribute("center");
        }
    }

    /**
     * `number` | `(number + "px")`
     */
    public set _BorderWidth(value: number | string) {
        if (value) this.setAttribute("border-width", value + "");
        else this.removeAttribute("border-width");
    }
}

customElements.define("wc-spinner", HTMLSpinnerElement);
