import * as d3 from "d3";
import { HTMLSpinnerElement } from "../controlWC/SpinnerComponent";
import { HTMLTooltipComponent } from "../controlWC/TooltipComponent";
import { UIUtilGlobalKeyEvents } from "../util/GlobalKeyEvent";
import { UIUtilIconResources } from "../util/IconResourses";
import { UIUtilLang } from "../util/Language";

const TRANSITION_DURATION = 200;
export interface IFullViewExtraBtns {
    Icon: string;
    OnClick: () => void;
    Description?: string;
    // GetElement?: () => HTMLElement;
}
export class FullView {
    #btnClose: TSelectionHTML<"button">;
    #controlContainer: TSelectionHTML<"div">;
    #contentContainer: TSelectionHTML<"div">;
    #extraBtns: IFullViewExtraBtns[];

    private __onClose: Function;
    private __onRemove: Function;

    constructor() {
        this.Init();
    }

    private Init() {
        this.#controlContainer = d3.select(document.body)
            .append("div")
            .attr("class", "fullview");

        this.#controlContainer
            .style("opacity", 0)
            .transition()
            .duration(TRANSITION_DURATION)
            .style("opacity", 1);

        this.#controlContainer.append("div")
            .attr("class", "header")
            .call((header) => {
                header.append("b")

                header.append("div")
                    .attr("class", "actions")
                    .call((actions) => {
                        this.#btnClose = actions.append("button")
                            .attr("class", "btn_close")
                            .attr("class", "btn_roundbase")
                            .on("click", () => this.Close())
                            .call(btn => btn.append("img")
                                .attr("src", UIUtilIconResources.CGeneral.Close))

                    })
            });

        this.#controlContainer.append("div")
            .attr("class", "content_wrapper")
            .call(contentWrapper => {
                this.#contentContainer = contentWrapper.append("div")
                    .attr("class", "content")
            });

        this.#controlContainer.append("div")
            .attr("class", "footer")
            .call(footer => {
                footer.append("div").attr("class", "description");
                footer.append("div").attr("class", "actions");
            })

        UIUtilGlobalKeyEvents._SetEscKeyEventCallback(this.#controlContainer.node(), () => this.Remove());
    }

    private UpdateOptions() {
        const actionsContainer = this.#controlContainer.select(":scope > .footer > .actions");
        actionsContainer.selectAll<HTMLButtonElement, IFullViewExtraBtns>(":scope > button")
            .data(this.#extraBtns, d => d.Icon)
            .join(
                enter => enter.append("button")
                    .classed("btn_roundbase", true)
                    .on("click", d => d.OnClick())
                    .call(btn => btn.append("img")
                        .attr("src", d => d.Icon))
                    .call(btn => btn.append<HTMLTooltipComponent>("wc-tooltip")
                        .attr("position", "top")
                        .text(d => d.Description)),
                update => update
                    .on("click", d => d.OnClick())
                    .call(btn => btn.select("img").attr("src", d => d.Icon))
                    .call(btn => btn.select("wc-tooltip")
                        .text(d => d.Description)),
                exit => exit.remove()
            )
    }

    private Close() {
        if (this.__onClose) this.__onClose();
        this.Remove();
    }

    private Remove() {
        this.#controlContainer
            .style("opacity", 1)
            .transition()
            .duration(TRANSITION_DURATION)
            .style("opacity", 0)
            .remove()
            .call(() => {
                if (this.__onRemove) this.__onRemove();
            });
    }

    private ShowSpinner() {
        const spinner = document.createElement("wc-spinner") as HTMLSpinnerElement;
        spinner._Center = true;
        spinner._Dim = 50;
        spinner._BorderWidth = 4;
        this.#controlContainer.append(() => spinner);
        return spinner;
    }

    private RemoveAllSpinners() {
        this.#controlContainer.selectAll(":scope > wc-spinner").remove();
    }

    get _controlContainerSel() {
        return this.#controlContainer;
    }

    get _contentWrapperSel() {
        return this.#contentContainer;
    }

    get _btnCloseNode() {
        return this.#btnClose.node();
    }

    get _title() {
        return this.#controlContainer.select(":scope > .header > b").text() || "";
    }

    get _description() {
        return this.#controlContainer.select(":scope > .footer > .description").text() || "";
    }

    _Destroy() {
        this.Remove();
        return this;
    }

    _Options(btns: IFullViewExtraBtns[]) {
        this.#extraBtns = btns ? [...btns] : [];
        this.UpdateOptions();
        return this;
    }

    _OnClose(call: typeof this.__onClose) {
        this.__onClose = call
        return this;
    }

    _OnRemove(call: typeof this.__onRemove) {
        this.__onRemove = call
        return this;
    }

    _SetTitle(title: string) {
        this.#controlContainer.select(":scope > .header > b")
            .text(title);
        return this;
    }

    _SetDescription(description: string) {
        this.#controlContainer.select(":scope > .footer > .description")
            .text(description);
        return this;
    }

    _Content(content: HTMLElement) {
        this.#contentContainer.selectAll(":scope > *").remove();
        this.#contentContainer.append(() => content);
        return this;
    }

    _AwaitContent(contentPromise: Promise<HTMLElement>) {
        this.#contentContainer.selectAll(":scope > *").remove();
        const spinner = this.ShowSpinner();
        contentPromise
            .then((content) => {
                this.#contentContainer.selectAll(":scope > *").remove();
                if (content)
                    this.#contentContainer.append(() => content);
                else
                    this.#contentContainer.append("label")
                        .attr("class", "lbl_error")
                        .text(UIUtilLang._GetUIString("general", "notif_fail"))
            })
            .catch(() => {
                this.#contentContainer.selectAll(":scope > *").remove();
                this.#contentContainer.append("label")
                    .attr("class", "lbl_error")
                    .text(UIUtilLang._GetUIString("general", "notif_fail"))
            })
            .finally(() => {
                spinner.remove();
            })
        return this;
    }

    _RemoveContent(): this {
        this.#contentContainer.selectAll(":scope > *").remove();
        return this;
    }

    _ShowSpinner() {
        return this.ShowSpinner();
    }

    _RemoveSpinners(): this {
        this.RemoveAllSpinners();
        return this;
    }
}
