import { json } from "d3";
import { DataDRequest } from "../../data/DRequest";
import { Entidad } from "../../data/Entidad";
import { Global } from "../../data/Global";
import { _HttpMsgV2 } from "../../util/Labels";

/** Language Utils
 * @deprecated use "src/util/Labels" functions instead
*/
export namespace UIUtilLang {
    type TBasicTypeStrings = (TBasicModules | "app" | "sesion" | "general");
    type TBasicModules = Lowercase<keyof typeof Entidad.CModulo>;
    type TBasicActions = (keyof typeof Entidad.CAccionPermiso) | (Lowercase<(keyof typeof Entidad.CAccionPermiso)>);

    var _LANGUAGES_AVAILABLE = ["es", "en"];
    var _LANGUAGE_CURRENT = _LANGUAGES_AVAILABLE[0];
    // _LANGUAGES_AVAILABLE.find(d => (navigator.language.toLowerCase().startsWith(d.toLowerCase()))) || "es"; // [Math.max(0, _LANGUAGES_AVAILABLE.indexOf(navigator.language))];
    export async function _Init(): Promise<void> {
        await _InitString_Http();
        await _InitString_HttpV2();
        // await fn_InitString_Notifications();
        await _InitString_Modules();
        await _InitString_UI();
        // await fn_InitMenuLabel();
        // await fn_InitCfgMenu();
        // await fn_InitCfgMenuPermissions();
        // languageToDateFormat = _LANGUAGE_CURRENT == "en" ? "en-US" : "es-ES";
    }

    export function _GetLanguage(): ("es" | "en") {
        return _LANGUAGE_CURRENT as ("es" | "en");
    }

    // >> HTTP STRINGS

    /** @deprecated */
    var _MESSAGES_HTTP = new Map<string, Map<string, Map<string, string>>>();
    var _MESSAGES_HTTP_DEFAULT: Map<string, Map<string, string>>;

    /** "deprecated" */
    async function _InitString_Http(): Promise<void> {
        let lng = "cfg/string/http-" + _LANGUAGE_CURRENT + ".json?v" + Global._APP_VERSION_NAME;

        let requestTypes = new Map(Object.entries(await json(lng)));
        for (const requestTypeK of requestTypes.keys()) {
            let actionsinfo = new Map<string, object>(Object.entries(requestTypes.get(requestTypeK)));
            let actions = new Map<string, Map<string, string>>();
            for (const actionK of actionsinfo.keys()) {
                actions.set(actionK, new Map<string, string>(Object.entries(actionsinfo.get(actionK))));
            }
            _MESSAGES_HTTP.set(requestTypeK, actions);
        }
        _MESSAGES_HTTP_DEFAULT = _MESSAGES_HTTP.get("default");
    }

    var _MESSAGES_HTTP_V2 = new Map<string, Map<string, Map<string, string>>>();

    async function _InitString_HttpV2(): Promise<void> {
        let lng = "cfg/string/httpv2-" + _LANGUAGE_CURRENT + ".json?v" + Global._APP_VERSION_NAME;

        let requestTypes = new Map(Object.entries(await json(lng)));
        for (const requestTypeK of requestTypes.keys()) {
            let actionsinfo = new Map<string, object>(Object.entries(requestTypes.get(requestTypeK)));
            let actions = new Map<string, Map<string, string>>();
            for (const actionK of actionsinfo.keys()) {
                actions.set(actionK, new Map<string, string>(Object.entries(actionsinfo.get(actionK))));
            }
            _MESSAGES_HTTP_V2.set(requestTypeK, actions);
        }
        // console.warn("_MESSAGES_HTTP_V2", _MESSAGES_HTTP_V2);
        // _MESSAGES_HTTP_DEFAULT = _MESSAGES_HTTP.get("default");
    }

    type IResponseInfo = Pick<DataDRequest.IRequestResponseA, "TipoRequest" | "Resultado" | "EndPointName">
    /**
    * _GetHTTPMessage<"basic"> por defecto provee de actions predefinidos, en caso de tener alguno que no exista, usar _GetHTTPMessage<"other">
    * @param responseInfo
    * @param action tipos de respuesta por cada modulo. En processo de DEPRECADO por  integracion de `EndPointName`
    * @returns string | null
    */
    export function _GetHTTPMessage(responseInfo: IResponseInfo, action?: TBasicActions): string;
    export function _GetHTTPMessage<K extends (TBasicActions | string)>(responseInfo: IResponseInfo, action?: (K extends TBasicActions ? TBasicActions : string)): string;
    export function _GetHTTPMessage(responseInfo: IResponseInfo, action?: string): string {
        let message: string = null;
        let genericActionKey = responseInfo.Resultado > 0 ? "success" : "fail";

        if (responseInfo.EndPointName) {
            message = _HttpMsgV2(responseInfo, action?.toLowerCase() as any);
            if (message) return message;
        }
        if (!responseInfo.TipoRequest) {
            message = _MESSAGES_HTTP_DEFAULT.get(action?.toLowerCase())?.get(responseInfo.Resultado + "");
            if (!message) {
                message = _MESSAGES_HTTP_DEFAULT.get(action?.toLowerCase())?.get(genericActionKey);
            }
        }
        else if (action) {
            var actionInfo = _MESSAGES_HTTP.get(Entidad.CTipoRequest[responseInfo.TipoRequest].toLowerCase())?.get(action.toLowerCase());
            if (actionInfo) {
                message = actionInfo.get("" + responseInfo.Resultado);
                if (!message) {
                    message = actionInfo.get(genericActionKey);
                }
                if (!message) {
                    message = _MESSAGES_HTTP_DEFAULT.get(action.toLowerCase())?.get(genericActionKey);
                    console.warn("-d", "Http message no found", Entidad.CTipoRequest[responseInfo.TipoRequest], action, responseInfo.Resultado, genericActionKey);
                }
            } else {
                console.warn("-d", `TipoRequest: ${Entidad.CTipoRequest[responseInfo.TipoRequest].toLowerCase()} or Action: ${action.toLowerCase()} no found 😮`);
            }
        }
        if (!message) {
            message = _MESSAGES_HTTP_DEFAULT.get("basico").get(genericActionKey);
            console.warn("-d", "http message is basic! 🙁");
        }
        return message;
    }

    // >> NOTIFICATIONS STRINGS

    // /** @deprecated */
    // var _MESSAGES_NOTIFICATIONS: Map<string, Map<string, string>> = new Map();
    // /** @deprecated */
    // async function fn_InitString_Notifications(): Promise<void> {
    //     let lng = "cfg/string/notifications-" + _LANGUAGE_CURRENT + ".json";

    //     let notificationsObject = await d3.json(lng) as { [keyA: string]: { [keyB: string]: string } };
    //     for (let moduleKey in notificationsObject) {
    //         let notificationsMap = new Map<string, string>();
    //         for (let notifKey in notificationsObject[moduleKey]) {
    //             notificationsMap.set(notifKey, notificationsObject[moduleKey][notifKey]);
    //         }
    //         _MESSAGES_NOTIFICATIONS.set(moduleKey, notificationsMap);
    //     }
    // }

    // /**
    // * fn_GetNotificationMessage<"basic"> por defecto provee de actions predefinidos, en caso de tener alguno que no exista, usar fn_GetNotificationMessage<"other">
    // * @param responseInfo 
    // * @param action tipos de respuesta por cada modulo
    // * @returns string | null
    // * @deprecated
    // */
    // export function fn_GetNotificationMessage(typeMessage: TBasicTypeStrings, messageKey?: TBasicMessages): string;
    // /** @deprecated */
    // export function fn_GetNotificationMessage<TS extends (TBasicTypeStrings | string), KS extends (TBasicMessages | string)>(typeMessage: (TS extends TBasicTypeStrings ? TBasicTypeStrings : string), messageKey?: (KS extends TBasicMessages ? TBasicMessages : string)): string;
    // /** @deprecated */
    // export function fn_GetNotificationMessage(typeMessage: string, messageKey: string) {
    //     let message: string = null;
    //     let typeStringsMap = _MESSAGES_NOTIFICATIONS.get(typeMessage.toLowerCase());
    //     if (typeStringsMap) {
    //         message = typeStringsMap.get(messageKey.toLowerCase());
    //         if (!message) {
    //             console.error("language_error -> message no smafound", messageKey);
    //         }
    //     } else {
    //         console.error("language_error -> type no found", typeMessage);
    //     }
    //     return message;
    // }

    // >> UI STRINGS

    var _STRING_UI: Map<string, Map<string, string>> = new Map();

    async function _InitString_UI(): Promise<void> {
        let lng = "cfg/string/ui-" + _LANGUAGE_CURRENT + ".json?v" + Global._APP_VERSION_NAME;

        let notificationsObject = await json(lng) as { [keyA: string]: { [keyB: string]: string } };
        for (let moduleKey in notificationsObject) {
            let notificationsMap = new Map<string, string>();
            for (let notifKey in notificationsObject[moduleKey]) {
                notificationsMap.set(notifKey, notificationsObject[moduleKey][notifKey]);
            }
            _STRING_UI.set(moduleKey, notificationsMap);
        }
    }

    type IBasicTypeStringsUIMapV2 =
        {
            c_modules: TBasicModules;
            c_actions: TBasicActions;
            general: "general" | "buscar" | "aplicar" | "categoria" | "tipo" | "nombre" | "escuela" | "aceptar" | "cancelar" | "todo"
            | "user" | "contrasenia" | "fecha" | "afirma" | "niega" | "nodisponible" | "sinasignaciones" | "sincontenido"
            | "siguiente" | "anterior" | "save" | "sinconfig" | "msjconfirma" | "reintentar" | "salir" | "startswith" | "endswith" | "includes" | "minorthan" | "higherthan" | "equalto" | "omitir"
            | "nofindoptions" | "nooptions" | "selectaoption" | "failresume" | "inicio" | "fin" | "nodtselected" | "clear" | "copiedtext" | "mas" | "menu" | "selected1" | "selected2" | "edad"
            | "notif_fail" | "notif_fail_infoupdate" | "notif_fail_infosync" | "notif_infonodisponible" | "notif_fail_getpass" | "notif_failarrayproccess" | "page"
            | "descargar" | "descargartodo" | "print" | "ninguno" | "preview" | "notif_maxfiles" | "resumen" | "pendiente" | "fin_exitoso" | "notif_infofalta" | "loading" | "userdata" | "email";
            control: "cargararchivo" | "click_draganddrop" | "drop" | "total"
        }
        &
        {
            [k in TBasicTypeStrings]: string
        }

    type UIStrMap = IBasicTypeStringsUIMapV2;
    type TUIStrMap = keyof UIStrMap & string;
    /**
    *
    * @param typeString
    * * LowerCase predefinidos: modo estricto
    * * UpperCase predefinidos y any string: modo libre
    * @param stringKey sus posibles valores dependen de ```typeString```
    * @returns ```string | null```
    * @deprecated use _L(tag) -> _Label(tag) instead
    */
    export function _GetUIString<K extends TUIStrMap | string>(typeString: K, stringKey: K extends TUIStrMap ? UIStrMap[K] : string): string;
    export function _GetUIString<K extends (TUIStrMap | Uppercase<TUIStrMap>)>(typeString: K, stringKey: (K extends TUIStrMap ? UIStrMap[K] : string)): string;
    export function _GetUIString(typeString: string, stringKey: string): string {
        let uiString: string = null;
        let typeStringsMap = _STRING_UI.get(typeString.toLowerCase());
        if (typeStringsMap) {
            uiString = typeStringsMap.get(stringKey.toLowerCase());
            if (uiString == null) {
                console.warn("-d", `language_error -> string_key no found "${stringKey}"`);
            }
            // else uiString += "*"
        } else {
            console.warn("-d", `language_error -> string_type no found "${typeString}"`);
        }
        return uiString;
    }

    /**
     * Modo estricto de {@link _GetUIString}. +Funcionalidad para pasar parámetros a la cadena
    * @param typeString
    * @param stringKey sus posibles valores dependen de ```typeString```
    * @param args Sus valores reemplazan los `__` (doble guión bajo) en la cadena encontrada
    * @returns ```string | null```
    */
    export function _Label(typeString: string, stringKey: string, ...args: any[]): string {
        let uiString: string = null;
        let typeStringsMap = _STRING_UI.get(typeString);
        if (typeStringsMap) {
            uiString = typeStringsMap.get(stringKey);
            if (uiString == null) {
                console.warn("-d", `language_error -> string_key no found "${stringKey}"`);
            } else if (args?.length) {
                for (const arg of args)
                    uiString = uiString.replace("__", arg);
            }
            // else uiString += "*"
        } else {
            console.warn("-d", `language_error -> string_type no found "${typeString}"`);
        }
        return uiString;
    }

    // >> MENU STRINGS

    var _STRING_MENU = new Map<string, string>();

    async function _InitString_Modules(): Promise<void> {
        let lng = "cfg/string/menu-" + _LANGUAGE_CURRENT + ".json?v" + Global._APP_VERSION_NAME;
        _STRING_MENU = new Map(Object.entries(await json(lng)));
    }

    export function _GetMenuLabel(menuId: string): string {
        let menuStr = _STRING_MENU.get(menuId.toLowerCase());
        if (!menuStr) {
            console.warn("-d", "language_error -> menu str no found", menuId);
        }
        return menuStr;
    }

    export function _FixFieldKey(fieldStr: string): string {
        fieldStr = fieldStr.toLowerCase();
        if (fieldStr.startsWith("get")) fieldStr = fieldStr.slice(3);
        if (fieldStr.endsWith("fmt")) fieldStr = fieldStr.slice(0, -3);
        return "d_field_" + fieldStr;
    }
}
