import { MainPage } from "../../MainPage";
import { DataDRequest } from "../../data/DRequest";
import { Entidad } from "../../data/Entidad";
import { Global } from "../../data/Global";
import { DataModuloMain } from "../../data/ModuloMain";
import DataModuloEscuela from "../../data/modulo/Escuela";
import DataModuloGrupo from "../../data/modulo/Grupo";
import DataModuloMaestro, { _DictMaestros, _MAESTRA_GENERAL as _MAESTRA_GENERAL_ID, _SvMaestroObtenerAccesos } from "../../data/modulo/Maestro";
import { DataUtil } from "../../data/util/Util";
import _L from "../../util/Labels";
import { TCARDV2COLL_OnEditOriginEvent } from "../controlD3/CardV2Collapse";
import { CardV2CollapseAdvancedTable, IConfigCardV2CollapseExcelExport } from "../controlD3/CardV2CollapseAdvancedTable";
import { CopyElementTextControl } from "../controlD3/CopyElementTextControl";
import { DropdownAdvanced } from "../controlD3/DropdownAdvanced";
import { NotificacionV2 } from "../controlD3/NotificacionV2";
import { Table } from "../controlD3/Tabla";
import { _TextoCensuraDiv } from "../controlD3/TextoCensura";
import { UIUtilLang } from "../util/Language";
import { UIUtilGeneral } from "../util/Util";
import { UIUtilViewMaestro } from "../utilView/Maestro";

import CAccionPermiso = Entidad.CAccionPermiso;
import IGrupo = Entidad.IGrupo;
import IMaestro = Entidad.IMaestro;

interface IMaestroAsignado extends Entidad.IMaestroGrupo {
    NombreCompleto?: string;
    Contrasenia?: string;
}

export class UIPanelCardGruposMaestrosV2 extends CardV2CollapseAdvancedTable<IMaestroAsignado, [IGrupo]> {
    private maestrosAsignacionesMap: Map<string, IMaestroAsignado[]>;
    private controlSelectMaestros: DropdownAdvanced<IMaestro, "Id">;

    constructor(modulo: Entidad.CModulo.PanelMaestros) {
        super("card_headertitle", modulo);
        this.maestrosAsignacionesMap = new Map();
    }

    protected CARDCOLLADTAB_Table_GetMenuTop(): Table.ITableMenuTopDefaultOptionConfig[] {
        let opciones: Array<Table.ITableMenuTopDefaultOptionConfig> = [];

        if (this.HasActionPermission(CAccionPermiso.Agregar)) {
            opciones.push({
                Label: "action_agregar",
                Callback: () => this.OpenList_TutoresSelect()
            });
        }

        return opciones;
    }
    protected CARDCOLLADTAB_GetExportarConfig(dataTable: IMaestroAsignado[]): IConfigCardV2CollapseExcelExport<any> | Promise<IConfigCardV2CollapseExcelExport<any>> {
        const bolUserAdmin = (DataUtil._Usuario.Perfil == Entidad.CTipoPerfil.Admin);
        const grupo = this.GetCurrentGroup();

        return {
            FileName: grupo.Nombre + " - Maestros",
            IdsEscuelas: [grupo.IdKinder],
            TypeRequest: Entidad.CTipoRequest.Grupo,
            ColumnsConfig: this.ctrlTabla
                ._InfoColumns
                .filter(d => (bolUserAdmin || d.Field != "Contrasenia"))
                .map(d => ({
                    Field: d.Field as keyof IMaestroAsignado,
                    HeaderTag: d.Label,
                    WidthCell: 25
                })),
            OnGetDataBySheets: async () => {
                const maestrosAux = dataTable.map(d => UIUtilGeneral._ObjectClone(d));
                if (bolUserAdmin) {
                    const idsMaestros = dataTable.map(d => d.IdMaestro);
                    const psswds = await _SvMaestroObtenerAccesos(idsMaestros);
                    if (psswds.Resultado > 0) {
                        maestrosAux.forEach(d => {
                            d.Contrasenia = psswds.Datos[d.IdMaestro];
                        })
                    }
                    else {
                        NotificacionV2._Mostrar(UIUtilLang._GetUIString("general", "notif_fail_getpass"), "ADVERTENCIA");
                        //this.notificacion.met_Mostrar(UIUtilLang.fn_GetUIString("general", "notif_fail_getpass"), "ADVERTENCIA");
                    }
                }
                return [{
                    IdSheet: grupo.IdKinder, // IdEscuela
                    SheetName: grupo.Nombre,
                    Data: maestrosAux,
                }]
            },
            OnGetEscuelasTagInSheet: (datos) => DataModuloEscuela._DiccEscuela.get(this.cardData[0].IdKinder).Nombre
        }
    }
    protected CARDCOLLADTAB_Table_GetConfig(): Omit<Table.IConfig<IMaestroAsignado>, "Parent"> {
        return {
            IdTabla: "GruposPanelMaestros",
            Title: "",
            IdData: "IdAsignacion",
            OrderDefault: { Type: Table.CStatusOrder.Asc, Field: "NombreCompleto" },
            MinWidth: 600,
            StickyCheckInRow: true,
            RenderColumnHeadings: [
                { Field: "NombreCompleto", Label: "Nombre", Width: "25%", MinWidth: "75px" },
                // { Field: "Telefono", Label: "Teléfono", Width: "20%", MinWidth: "75px" },
                { Field: "Correo", Label: "Correo", Width: "23%", MinWidth: "75px" },
                { Field: "Contrasenia", Label: "Contraseña", Width: "20%", MinWidth: "75px", IsSortable: false },
            ],
            OptionsTopDefaultV2: {
                MaxOptionsInRow: 30,
                Options: []
            },
            OnValueSelectRow: (id, datum) => console.debug(id, datum),
            OptionsOfDataCheckV3: () => this.CARDCOLLADTAB_GetTableOptionsToSelectedData(this.GetIdEscuela(), "top-selected", this.TableGetDataSelectedMenu()),
            EvaluatorAndSubLevelsBuild: {
                GetOptionsInRowV2: () => this.CARDCOLLADTAB_GetTableOptionsToSelectedData(this.GetIdEscuela(), "row", this.TableGetDataSelectedMenu()),
                OnStepCellTable: (container: d3.Selection<HTMLDivElement, any, HTMLElement, any>, datum: IMaestroAsignado, fieldNameStep: string) => {
                    switch (fieldNameStep) {
                        case "Correo":
                            CopyElementTextControl._AddCopyTextBehaviour(container.node(), () => datum[fieldNameStep]);
                            break;
                        case "Contrasenia":
                            if (this.HasActionPermission(CAccionPermiso.VerContrasenia)) {
                                _TextoCensuraDiv(container, () => this.Sv_ObtenerContrasenia(datum.IdMaestro), "100px");
                            }
                            else {
                                let restriction = UIUtilLang._GetUIString("permission", "nopermiso_verinfo");
                                _TextoCensuraDiv(container, () => this.Sv_ObtenerContrasenia(datum.IdMaestro), "100px", restriction);
                            }
                            break;
                    }
                }
            }
        }
    }
    protected CARDCOLL_OnInitBuild(container: TSelectionHTML<"div", any, any>): void {
        //this.cardHeaderSelection.remove();
        //this.cardActionsContainerSelection.style("top", "-32px");
        //this.cardSelection.style("height", "100%");

        this.btnEditarCard._d3Selection.remove();
        this.cardFooterSelection.remove();


        this.CARDCOLLADTAB_CreateTable(container);

        this.controlSelectMaestros = new DropdownAdvanced<IMaestro, "Id">({
            IsMultiSelect: false,
            Parent: this.ctrlTabla._Control.select(".area_acciones"),
            ListWidth: "330px",
            ValueMember: "Id",
            DisplayMember: "NombreCompleto",
            ShowAndEnableSearchText: true,
            OnChangeSearchText_GetDataValueToEval: (datum) => (datum.NombreCompleto + " " + datum.Correo),
            OnStepItemListUI: (container, datum, step) => {
                if (step == "enter") {
                    container.classed(UIUtilGeneral.FBoxOrientation.Vertical, true)
                        .style("align-items", "flex-start");
                    container.append("label")
                        .style("cursor", "pointer");
                    container.append("label")
                        .style("cursor", "pointer")
                        .style("font-size", "var(--fontsize_me4)");
                    container.append("b")
                        .style("cursor", "pointer")
                        .style("font-size", "var(--fontsize_me4)");
                }
                container.select("label:nth-child(1)").text(datum.NombreCompleto);
                container.select("label:nth-child(2)").text(datum.Correo);
                container.select("b").classed("hide", (datum.IdEscuela > 0)).text(this.CARDCOLL_GetUIStringModule("maestro_gen"));
            },
            OnChange: async (idTutor, datoT) => {
                await this.Sv_AsignarMaestro(idTutor[0]);
                await this.UI_UpdateCardData(true);
            }
        })
    }
    protected CARDCOLL_GetVariantToValidateUpdate(cardData_0: IGrupo): string {
        return cardData_0.IdGrupo + cardData_0.Nombre + cardData_0.EsPrincipal + cardData_0.IdNivel + cardData_0.IdEscolaridad;
    }
    protected CARDCOLL_OnUpdateData(cardData_0: IGrupo) {
        return this.UI_UpdateCardData(true);
    }
    protected CARDCOLL_MostrarBody(): void {
        this.cardContentContainerSelection.style("height", "100%");
        this.UI_UpdateCardData(true);
    }
    protected CARDCOLL_OcultarBody(): void {
        this.cardContentContainerSelection.style("height", null);
    }
    protected CARDCOLL_OnEditarCard(originEvent: TCARDV2COLL_OnEditOriginEvent): void {
        //throw new Error("Method not implemented.");
    }
    protected CARDCOLL_OnCancelaEditarCard(originEvent: TCARDV2COLL_OnEditOriginEvent): void {
        //throw new Error("Method not implemented.");
    }
    protected CARDCOLL_GuardarCardV2(): Promise<DataDRequest.IRequestResponseA<any>> {
        return null;
    }
    protected CARDCOLL_SyncOrGetIdToDownloadData(): DataModuloMain.TipoRequestMonitorId | DataModuloMain.TipoRequestMonitorId[] | (() => Promise<any>) {
        return () => this.UI_UpdateCardData();
    }
    protected CARDCOLL_GetIdSchool(cardData_0: IGrupo): number {
        return cardData_0.IdKinder;
    }

    // ************************************************
    // UI THINGS
    // ************************************************

    private TableGetDataSelectedMenu() {
        let opciones: Array<Table.ITableMenuDataSelectedOptionConfig<IMaestroAsignado>> = [];

        if (this.HasActionPermission(CAccionPermiso.Eliminar)) {
            opciones.push({
                Label: "action_eliminar",
                Callback: (datos) => this.OpenModal_EliminarAsignaciones(datos),
                // OnValidateDisabling: (datos) => {
                //     let maestrosEnTabla = this.ctrlTabla.prop_data;
                //     return (maestrosEnTabla.length > 1 && datos.length < maestrosEnTabla.length);
                // }
            });
        }
        if (Global._GLOBAL_CONF.DEBUG_MODE || Global._DEVELOPMENT_UTILS) {
            opciones.push({
                Label: _L("control.tomar_sesion"),
                MultiData: false,
                Callback: (datos) => UIUtilViewMaestro._OpenModal_IniciarSesion(_DictMaestros.get(datos[0].IdMaestro)),
            })
        }
        return opciones;
    }

    private OpenModal_EliminarAsignaciones(dataTutores: IMaestroAsignado[]) {
        this.CARDCOLLAD_OpenModal_ProccessArrayData<IMaestroAsignado>({
            DataToProccess: dataTutores,
            TypeRequest: null, // data.Entidades.CTipoRequest.Grupos,
            OnEndProccess: null,
            AccionToHttpMessage: "desasignarmaestro", // FIXME Ajustar funcion de ModalThings para mostrar mensaje
            Message: this.CARDCOLL_GetUIStringModule(dataTutores.length == 1 ? "confirma_asignremover" : "confirma_asignsremover")
                .replace(
                    "_T",
                    new Intl["ListFormat"](
                        UIUtilLang._GetLanguage(),
                        { style: 'long', type: 'conjunction' }
                    ).format(dataTutores.map(d => (d.Nombre + " " + d.ApPaterno)))
                ), // FIXME Tipear
            OnError_GetItemDataTag: (dato) => dato.NombreCompleto,
            OnGetIdEscuela: (dato) => this.GetIdEscuela(),
            OnStepAProccess: (dato) => this.Sv_EliminarAsignacion(dato.IdAsignacion),
            OnEndAndCloseProccess: async (datosCorrectos) => {
                if (datosCorrectos.length) {
                    // this.ctrlProgress.met_Show();
                    return await this.UI_UpdateCardData(true);
                }
            }
        })
    }

    private OpenList_TutoresSelect() {
        const datosEnTabla = this.ctrlTabla._data;
        const maestrosDisponibles = Array.from(DataModuloMaestro._DictMaestros.values())
            .filter(d => (d.IdEscuela == _MAESTRA_GENERAL_ID || d.IdEscuela == this.GetIdEscuela()) && !Boolean(datosEnTabla.find(dTabla => dTabla.IdMaestro == d.Id)));

        if (maestrosDisponibles.length > 0) {
            console.log(this.controlSelectMaestros._Visible);
            if (this.controlSelectMaestros._Visible) {
                this.controlSelectMaestros._Hide();
            } else {
                this.controlSelectMaestros._ResetSelect();
                this.controlSelectMaestros._UpdateList(maestrosDisponibles);
                this.controlSelectMaestros._Show();
            }
        } else {
            NotificacionV2._Mostrar(this.CARDCOLL_GetUIStringModule("notif_sinmaestros"), "ADVERTENCIA");
            //this.notificacion.met_Mostrar(this.CARDCOLL_GetUIStringModule("notif_sinmaestros"), "ADVERTENCIA");
        }
    }

    private async UI_UpdateCardData(showProgressBar = false): Promise<void> {
        let currentGroup = this.GetCurrentGroup();

        if (currentGroup && this.CARDCOLL_StatusCardExpandido) {
            const idEscuela = this.GetIdEscuela();

            if (showProgressBar) {
                this.ctrlProgress.attr("oculto", false);
            }

            let maestrosAsignados = await this.Sv_GetListaAsignaciones();

            if (idEscuela) {
                let resultSuccess = await MainPage._ReloadServiceAndAwaitBool(Entidad.CTipoRequest.Maestro, idEscuela);
                if (!resultSuccess) {
                    setTimeout(() => {
                        NotificacionV2._Mostrar(UIUtilLang._GetUIString("general", "notif_fail_infosync"), "ADVERTENCIA");
                        //this.notificacion.met_Mostrar(UIUtilLang.fn_GetUIString("general", "notif_fail_infosync"), "ADVERTENCIA");
                    }, 2000);
                }
            }
            this.ctrlTabla._UpdateData(maestrosAsignados);

            if (showProgressBar) { // NOTE Se realiza en CardCollapseAdvancedTable._OnServiceEvent
                this.ctrlProgress.attr("oculto", true);
            }
        }
        else {
            this.ctrlTabla._UpdateData([]);
        }
    }

    // **************************************************************************
    // PERMISOS COSAS
    // **************************************************************************

    // **************************************************************************
    // DATA - LOCAL
    // **************************************************************************

    private GetCurrentGroup() {
        if (this.cardData) {
            return (this.cardData[0] || null);
        }
        return null;
    }

    private GetIdCurrentGroup() {
        return (this.GetCurrentGroup()?.IdGrupo || null);
    }

    private GetIdEscuela() {
        return (this.GetCurrentGroup()?.IdKinder || null);
    }

    private GetMaestrosAsignadosList(idGrupo?: number) {
        if (!idGrupo) {
            idGrupo = this.GetIdCurrentGroup();
        }

        return (this.maestrosAsignacionesMap.get(idGrupo.toString()) || []);
    }

    private SetMaestrosAsignadosList(idGrupo: number, maestrosAsignados: IMaestroAsignado[]) {
        this.maestrosAsignacionesMap.set(idGrupo.toString(), maestrosAsignados);
    }

    // **************************************************************************
    // SERVICIOS COSAS
    // **************************************************************************

    private async Sv_GetListaAsignaciones() {
        const idGrupo = this.GetIdCurrentGroup();

        if (idGrupo == null) {
            return [];
        }

        let res = await DataModuloGrupo._ObtenerAsignacionesGrupoMaestros(idGrupo);
        let maestrosList = (res.Datos as IMaestroAsignado[] || []);

        if (res.Resultado > 0) {
            this.SetMaestrosAsignadosList(idGrupo, maestrosList);

            maestrosList
                .forEach(m => {
                    m.NombreCompleto = m.Nombre + " " + m.ApPaterno + " " + m.ApMaterno;
                });

            return maestrosList;
        }
        else {
            NotificacionV2._Mostrar(UIUtilLang._GetUIString("general", "notif_fail_infoupdate"), "ADVERTENCIA");
            return this.GetMaestrosAsignadosList();
        }
    }

    private async Sv_AsignarMaestro(idMaestro: number) {
        const idGrupo = this.GetIdCurrentGroup();

        if (idGrupo == null) {
            return null;
        }

        this.ctrlProgress.attr("oculto", false);
        let res = await DataModuloGrupo._AsignarGrupoMaestro(idGrupo, idMaestro);

        let mensaje = UIUtilLang._GetHTTPMessage(res, "asignarmaestro");
        NotificacionV2._Mostrar(mensaje, (res.Resultado > 0) ? "INFO" : "ADVERTENCIA");

        this.ctrlProgress.attr("oculto", true);

        return res;
    }

    private Sv_EliminarAsignacion(idAsignacion: number) {
        return DataModuloGrupo._EliminarAsignacionGrupoMaestro(idAsignacion);
    }

    private async Sv_ObtenerContrasenia(IdMaestro: number): Promise<string> {
        let res = await _SvMaestroObtenerAccesos([IdMaestro]);
        return res.Datos[IdMaestro];
    }
}
