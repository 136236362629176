import * as d3 from "d3";
import { DataDRequest } from "../../data/DRequest";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import { _LOCALDATA_GetTutoresDeAlumno } from "../../data/modulo/Alumno";
import DataModuloEscuela from "../../data/modulo/Escuela";
import DataModuloFinanzaCargo from "../../data/modulo/FinanzaCargo";
import DataModuloFinanzasAsignaciones from "../../data/modulo/FinanzasAsignaciones";
import DataModuloFinanzasEstadoCuenta, { _ObtenerListaMovimientosAlumnoV2, _SvFinanzasEstadoCuentaEliminarDescuento, _SvFinanzasEstadoCuentaEliminarMorosidad, _SvFinanzasEstadoCuentaEliminarPagoCompleto } from "../../data/modulo/FinanzasEstadoCuenta";
import { DataUtil } from "../../data/util/Util";
import _L from "../../util/Labels";
import { Button } from "../controlD3/Button";
import { IConfigCardCollapseExcelExport } from "../controlD3/CardCollapseAdvancedTable";
import { TCARDV2COLL_OnEditOriginEvent } from "../controlD3/CardV2Collapse";
import { CardV2CollapseAdvancedTable, IConfigCardV2CollapseExcelExport } from "../controlD3/CardV2CollapseAdvancedTable";
import { ElementWrapper } from "../controlD3/ElementWrapper";
import { Fields, FormGenerator } from "../controlD3/Formulario";
import { MenuFlex } from "../controlD3/MenuFlexV2";
import { ModalThings } from "../controlD3/ModalThings";
import { NotificacionV2 } from "../controlD3/NotificacionV2";
import { SelectV2 } from "../controlD3/SelectV2";
import { Table } from "../controlD3/Tabla";
import { TableUtil } from "../controlD3/TablaUtil";
import { HTMLImage2Component } from "../controlWC/HTMLImage2Component";
import { UIUtilFormat } from "../util/Format";
import { UIUtilIconResources } from "../util/IconResourses";
import { UIUtilLang } from "../util/Language";
import { UIUtilStrings } from "../util/Strings";
import { UIUtilTime } from "../util/Time";
import { UIUtilGeneral } from "../util/Util";
import { UIUtilViewData } from "../util/ViewData";
import { UIUtilViewAlumnoEdoCtaFacturacion } from "../utilView/AlumnoEdoCtaFacturacion/AlumnoEdoCtaFacturacion";
import { AlumnoEdoCtaFacturacionUtil as FacturacionUtil } from "../utilView/AlumnoEdoCtaFacturacion/AlumnoEdoCtaFacturacionUtil";
import { UIUtilViewFinanzaCargo } from "../utilView/FinanzaCargo";

import CTipoValor = Entidad.CFinanzaCargoTipoValor;
import CCategoriaMov = Entidad.CFinanzaCategoriaMovimiento;
import CTipoPago = Entidad.CTipoPago;
import GetDateValidatorYM = UIUtilTime._GetValidatorDateYM;

type IMetodoPago = Entidad.IFinanzaMetodoPago;
type ICicloEscolar = Entidad.ICicloEscolar;
type IAlumno = Entidad.IAlumno;
type IFinanzaCargo = Entidad.IFinanzaCargo;

interface IPeriodoD extends UIUtilViewData.IPeriodoD {
    EnCicloEscolar: boolean;
}

interface IMovimiento extends Entidad.IFinanzaMovimientoV2 {
    Periodo?: string;
    /** Solo para configuración de tabla */
    PagoAbono?: string;
    /** Solo para configuración de tabla */
    FechaPago?: string;
    /** Solo para configuración de tabla */
    MetodoPago?: string;
}
interface IMovimientoDetalle extends Entidad.IFinanzaMovimientoDetalleV2 {
    ParentMovimiento?: IMovimiento;
}

interface ICurrentData {
    IdAlumno: number;
    AlumnoNombre: string;
    NoCuenta: string;
    Tutoes: string[];
    StrNivel: string;

    StrPeriodo: string;
    SaldoPeriodo: number;
    SaldoPendiente: number;
    InteresesGenerados: number;
    TotalPago: number;
    MovimientosPeriodo: IMovimiento[];

    MovimientosTbl_TopPeriodo: IMovimiento[];
}

enum CTableColumns {
    Periodo = "Periodo",
    Descripcion = "Cargo",
    /** Valor de: Cargo, descuento  */
    Valor = "Valor",
    PagoAbono = "PagoAbono",
    Saldo = "Saldo",
    Recargo = "Recargo",
    FechaVencimiento = "FechaVencimiento",
    FechaPago = "FechaPago",
    MetodoPago = "MetodoPago"
}
const columnsTable = [CTableColumns.Periodo, CTableColumns.Descripcion,
CTableColumns.Valor, CTableColumns.Recargo,
CTableColumns.PagoAbono, CTableColumns.Saldo,
CTableColumns.FechaVencimiento, CTableColumns.FechaPago, CTableColumns.MetodoPago]

export class UIPanelCardAlumnosEstadoCuenta extends CardV2CollapseAdvancedTable<IMovimiento, [IAlumno]> {
    private ctrlSelectCicloEscolar: SelectV2<ICicloEscolar, "Id", "monoselect">;
    private ctrlSelectPeriodo: SelectV2<IPeriodoD, "Id", "monoselect">;
    private ctrlMenuInRowAux: MenuFlex;

    /** No usar directamente, usar this.GetCurrentInfoGeneral */
    private infoGeneral: ICurrentData;
    /** No usar directamente, usar this.GetCiclosEscolaresList */
    private ciclosEscolaresList: ICicloEscolar[];
    /** IdAlumno -> Movimientos */
    private movimientosMap: Map<number, IMovimiento[]>;

    constructor(modulo: Entidad.CModulo.PanelFinanzasEdoCuenta) {
        super("", modulo);
        this.movimientosMap = new Map();
    }

    protected CARDCOLLADTAB_Table_GetMenuTop(): Table.ITableMenuTopDefaultOptionConfig[] {
        let opciones: Array<Table.ITableMenuTopDefaultOptionConfig> = [];

        opciones.push({
            Label: "action_addcargo",
            Callback: async () => {
                const form = UIUtilViewFinanzaCargo._GetFormAddEditCargo(Entidad.CAccionPermiso.Agregar, <UIUtilViewFinanzaCargo.TFinanzaCargoForm>{ IdEscuela: this.GetIdEscuela() }, "AlumnosEstadoCuenta", this.GetIdEscuela());
                let res = await UIUtilViewFinanzaCargo._OpenModal_FormAddEditCargo(Entidad.CAccionPermiso.Agregar, <UIUtilViewFinanzaCargo.TFinanzaCargoForm>{ IdEscuela: this.GetIdEscuela() }, form, "AlumnosEstadoCuenta", this.GetIdCurretStudent());
                if (res) {
                    this.UI_UpdateCardData(false, true);
                }
            }
        })
        EvalGenerarCFDIAction(opciones, this.GetEscuela(), this.cardData[0].IdChild, () => this.UI_UpdateCardData(false, true))
        return opciones;
    }

    protected CARDCOLLADTAB_GetExportarConfig(dataTable: IMovimiento[]): IConfigCardV2CollapseExcelExport<any> | Promise<IConfigCardV2CollapseExcelExport<any>> {
        type keys = CTableColumns;
        type IDataToExport = Partial<Record<keys, string>>;

        const alumno = this.GetCurrentStudent();

        return <IConfigCardCollapseExcelExport<IDataToExport>>{
            FileName: alumno.NombreCompleto + " - " + UIUtilViewData._GetStr_Modulo(this.modulo),
            IdsEscuelas: [alumno.IdKinder],
            TypeRequest: Entidad.CTipoRequest.Alumno,
            ColumnsConfig: [
                { Field: "Periodo", HeaderTag: this.CARDCOLL_GetUIStringModule("d_field_periodo") },
                { Field: "Cargo", HeaderTag: this.CARDCOLL_GetUIStringModule("d_field_descripcion") }, // Descripción
                { Field: "Valor", HeaderTag: this.CARDCOLL_GetUIStringModule("d_field_valor") },
                { Field: "Recargo", HeaderTag: this.CARDCOLL_GetUIStringModule("d_field_recargo") },
                { Field: "PagoAbono", HeaderTag: this.CARDCOLL_GetUIStringModule("d_field_pagoabono") },
                { Field: "Saldo", HeaderTag: this.CARDCOLL_GetUIStringModule("d_field_saldo") },
                { Field: "FechaVencimiento", HeaderTag: this.CARDCOLL_GetUIStringModule("d_field_fechavencimiento"), TypeData: "date" },
                { Field: "FechaPago", HeaderTag: this.CARDCOLL_GetUIStringModule("d_field_fechapago"), TypeData: "date" },
                { Field: "MetodoPago", HeaderTag: this.CARDCOLL_GetUIStringModule("d_field_metodopago") },
            ],
            OnGetDataBySheets: async () => {
                let datosExport: IDataToExport[] = [];

                dataTable.forEach(movimiento => {
                    datosExport.push({
                        Periodo: movimiento.Periodo,
                        Cargo: movimiento.Cargo,
                        Valor: this.GetUIStrFieldData("cargo", "Valor", movimiento),
                        // Recargo: Utils.fn_GetCurrencyFormat(movimiento.Recargo),
                        Saldo: this.GetUIStrFieldData("cargo", "Saldo", movimiento),
                        FechaVencimiento: this.GetUIStrFieldData("cargo", "FechaVencimiento", movimiento)
                    })

                    movimiento.Detalles
                        .forEach((datoChildMov, i) => {
                            datosExport.push({
                                Cargo: this.GetUIStrFieldData("detalle", "Cargo", datoChildMov),
                                Valor: this.GetUIStrFieldData("detalle", "Valor", datoChildMov),
                                Recargo: this.GetUIStrFieldData("detalle", "Recargo", datoChildMov),
                                PagoAbono: this.GetUIStrFieldData("detalle", "PagoAbono", datoChildMov),
                                Saldo: this.GetUIStrFieldData("detalle", "Saldo", datoChildMov),
                                FechaPago: this.GetUIStrFieldData("detalle", "FechaPago", datoChildMov),
                                MetodoPago: this.GetUIStrFieldData("detalle", "MetodoPago", datoChildMov)
                            })
                        })
                });

                return [{
                    IdSheet: alumno.IdKinder, // IdEscuela
                    SheetName: alumno.NombreCompleto,
                    Data: datosExport,
                }]
            },
            OnGetEscuelasTagInSheet: (datos) => DataModuloEscuela._DiccEscuela.get(alumno.IdKinder).Nombre,
        }
    }
    protected CARDCOLLADTAB_Table_GetConfig(): Omit<Table.IConfig<IMovimiento>, "Parent"> {
        const fnAcciones = (movimientos: IMovimiento[]) => {
            const opciones: Table.ITableMenuDataSelectedOptionConfig<IMovimiento>[] = [];

            // EvalGenerarCFDIAction(opciones, this.GetEscuela(), this.cardData[0].IdChild, movimientos)

            if (this.HasActionPermission(Entidad.CAccionPermiso.AgregarDescuentos)) {
                opciones.push({
                    Label: this.CARDCOLL_GetUIStringModule("action_adddesc"),
                    Callback: (datosAsig) => this.OpenModal_AsignarDescuento(datosAsig),
                    GetDetails: () => {
                        let message = '';
                        const enable = !movimientos.some(mov => {
                            if (this.GetSaldoMovimiento(mov) <= 0) {
                                message = this.CARDCOLL_GetUIStringModule("tlt_adddesc_fail_zero");
                                return true;
                            }
                            const tienePagos = mov.Detalles.some(d => [CCategoriaMov.PagoParcial, CCategoriaMov.PagoCompleto].includes(d.Categoria));
                            if (tienePagos) {
                                message = this.CARDCOLL_GetUIStringModule("tlt_adddesc_fail_withpays");
                            }
                            return tienePagos;
                        });
                        return {
                            Enabled: enable,
                            Description: message
                        };
                    }
                });
            }
            if (this.HasActionPermission(Entidad.CAccionPermiso.Eliminar)) { // NOTE Antes Eliminar solo disponible para Admins ahora con permiso
                opciones.push({
                    Label: this.CARDCOLL_GetUIStringModule("action_eliminar"),
                    Callback: (datoAsig) => this.OpenModal_EliminarRegistro(datoAsig),
                    GetDetails: () => {
                        let message = "";
                        const enable = !movimientos.some(mov => {
                            const tienePagoParcial = mov.Detalles.some(d => [CCategoriaMov.PagoParcial].includes(d.Categoria))
                            if (tienePagoParcial && this.GetSaldoMovimiento(mov) > 0) {
                                message = this.CARDCOLL_GetUIStringModule("tlt_dltcargo_fail_parcials");
                                return true
                            }
                            return false;
                        })
                        return {
                            Enabled: enable,
                            Description: message
                        }
                    }
                })
            }
            return opciones;
        }
        return {
            IdTabla: "AlumnosPanelEstadoCuenta",
            Title: null,
            IdData: "Id",
            // OrderDefault: {
            //     Type: controlD3.Table.CStatusOrder.Asc,
            //     Field: this.TableGetConfig().DefaultSort
            // },
            OnValueSelectRow: (idDatum, datum, isSelected) => {
                console.log(idDatum, isSelected, datum);
            },
            MinWidth: 1350,
            StickyCheckInRow: true,
            RenderColumnHeadings: [// FIXMEE
                { Field: CTableColumns.Periodo, Label: "Período", Width: "10%", MinWidth: "90px" },
                { Field: CTableColumns.Descripcion, Label: "Descripción", LabelLangKey: "d_field_descripcion", Width: "15%", MinWidth: "120px" },
                { Field: CTableColumns.Valor, Label: "Adeudo", Width: "8%", MinWidth: "90px" },
                { Field: CTableColumns.Recargo, Label: "Recargos", Width: "8%", MinWidth: "80px" },
                { Field: CTableColumns.PagoAbono as any, Label: "Pagos/abonos", Width: "8%", MinWidth: "120px" }, // Level x (Multiline)
                { Field: CTableColumns.Saldo, Label: "Saldo", Width: "8%", MinWidth: "65px" }, // NOTE En las filas padres, solo mostrar "Saldo" cuando el item no tiene "Detalles".lenght > 0
                { Field: CTableColumns.FechaVencimiento, Label: "Fecha vencimiento", Width: "10%", MinWidth: "100px" },
                { Field: CTableColumns.FechaPago, Label: "Fecha pago", Width: "10%", MinWidth: "100px" }, // Level x (Multiline)
                { Field: CTableColumns.MetodoPago, Label: "Método pago", Width: "8%", MinWidth: "80px" }, // Level x (Multiline)
            ],
            AddNameFieldClassToCells: true,
            OptionsOfDataCheckV3: (movimientos) => {
                const opciones = fnAcciones(movimientos);
                return this.CARDCOLLADTAB_GetTableOptionsToSelectedData(this.GetIdEscuela(), "top-selected", opciones);
            },
            FilterByStrSearch: datum => columnsTable
                .reduce((result, field) => {
                    result.push(this.GetUIStrFieldData("cargo", field, datum))
                    datum.Detalles.forEach(det => {
                        result.push(this.GetUIStrFieldData("detalle", field, det))
                    })
                    return result
                }, <string[]>[])
                .filter(str => !!str),
            EvaluatorAndSubLevelsBuild: {
                // >> Nivel 1: Movimiento - Cargo

                // GetOptionsInRow: () => this.CARDCOLLAD_GetTableOptionsToSelectedData(this.dataChild.IdKinder, "row"),
                OnStepCellTable: (container, datum, field: keyof IMovimiento) => {
                    container
                        .text(this.GetUIStrFieldData("cargo", field, datum));

                    switch (field) {
                        case CTableColumns.Descripcion:
                            container.classed("link_item", true).node()
                                .onclick = e => (e.stopPropagation(), this.OpenModal_Detalle("cargo", datum));
                            break;
                        case CTableColumns.Saldo:
                        case CTableColumns.Valor:
                            this.VerifyAndApplyNegativeColor(datum[field], container);
                            break;
                    }
                },
                GetOptionsInRowV2: (datoAsig) => ({
                    MaxOptionsInRow: 2,
                    Options: fnAcciones([datoAsig])
                }),
                AddRowMultiline: this.GetTableConfigMiltiline()
            }
        }
    }
    protected CARDCOLL_OnInitBuild(container: TSelectionHTML<"div", any, any>): void {
        this.btnEditarCard._d3Selection.remove();
        this.btnCancelarCard._d3Selection.remove();

        let listPeriodos: IPeriodoD[] = [];
        container
            .classed(UIUtilGeneral.FBoxOrientation.Vertical, true)
            .classed("finanzas_edocuenta", true)

        let areaMenu = container.append("div")
            .classed(UIUtilGeneral.FBoxOrientation.Horizontal, true)
            .classed(UIUtilGeneral.FBoxAlign.StartCenter, true)
            .classed("area_menu", true);

        let areaInfo = container.append("div")
            .classed(UIUtilGeneral.FBoxOrientation.Horizontal, true)
            .classed(UIUtilGeneral.FBoxAlign.SpacebetweenStart, true)
            .classed("area_info", true)

        areaInfo.append<HTMLImage2Component>("wc-img")
            .attr("class", "escuela_logo")
            .attr("spinner-border-width", 5)
            .attr("spinner-dim", 40);

        areaInfo.append("div")
            .classed(UIUtilGeneral.FBoxOrientation.Vertical, true)
            .classed(UIUtilGeneral.FBoxAlign.StartStart, true)
            .classed("subareainfo_left", true)

        areaInfo.append("div")
            .classed(UIUtilGeneral.FBoxOrientation.Vertical, true)
            .classed(UIUtilGeneral.FBoxAlign.StartStart, true)
            .classed("subareainfo_right", true)

        container.selectAll(".tabla_container").remove();

        this.CARDCOLLADTAB_CreateTable(container);

        // >> Filtros superiores (Ciclo escolar, Periodo)

        this.ctrlSelectCicloEscolar = new SelectV2<ICicloEscolar, "Id">({
            Type: "monoselect",
            Parent: areaMenu,
            Data: [],
            ValueMember: "Id",
            DisplayMember: "Nombre",
            ListWidth: "260px",
            OnStepItemListUI: (container, d) => container
                .style("flex-direction", "column")
                .style("align-items", "start")
                .html(d.Nombre + `<span>${UIUtilTime._DateFormatStandarFixTimeZoneByIdSchool(d.FechaInicio, d.IdEscuela)} - ${UIUtilTime._DateFormatStandarFixTimeZoneByIdSchool(d.FechaFin, d.IdEscuela)}</span>`)
                .select("span")
                .style("font-size", "var(--fontsize_me4)"),
            OnChange: (id, cicloEscolar) => {
                console.log(cicloEscolar, "onselect CICLO ESCOLAR")
                let periodos = this.GetPeriodosListFromCicloEscolar(cicloEscolar);
                let periodoValid = periodos.find(d => d.Month == new Date().getMonth() && d.Year == new Date().getFullYear());
                periodoValid = periodoValid ? periodoValid : periodos[0];
                this.ctrlSelectPeriodo._UpdateList(periodos)
                this.ctrlSelectPeriodo._valueSelect(periodoValid.Id);

                const info = this.GetCurrentInfoGeneral();
                this.UI_UpdateCardInfoStatus(info);
                this.ctrlTabla._UpdateData(info.MovimientosTbl_TopPeriodo);
            }
        });
        areaMenu.append(() => ElementWrapper._WrapperToSelectControl(this.ctrlSelectCicloEscolar, this.CARDCOLL_GetUIStringModule("filtro_cicloesc")).node());

        this.ctrlSelectPeriodo = new SelectV2<IPeriodoD, "Id">({
            Type: "monoselect",
            Parent: areaMenu,
            Data: listPeriodos,
            ValueMember: "Id",
            DisplayMember: "Name",
            ListWidth: "250px",
            OnStepItemListUI: (container, d) => container
                .style("flex-direction", "column")
                .style("align-items", "start")
                .html(d.Name + (d.EnCicloEscolar ? "" : `<span>${this.CARDCOLL_GetUIStringModule("fueradeciclo")}</span>`))
                .select("span")
                .style("font-size", "var(--fontsize_me4)")
                .style("color", "var(--color_app_red1)"),
            OnChange: (id, periodo) => {
                // console.log(periodo, "onselect PERIODO")
                const info = this.GetCurrentInfoGeneral();
                this.UI_UpdateCardInfoStatus(info);
                this.ctrlTabla._UpdateData(info.MovimientosTbl_TopPeriodo);
            }
        })
        areaMenu.append(() => ElementWrapper._WrapperToSelectControl(this.ctrlSelectPeriodo, this.CARDCOLL_GetUIStringModule("filtro_periodo")).node())
            .style("height", "100%");

        this.ctrlMenuInRowAux = new MenuFlex({
            BtnCloseVisible: false,
            Orientation: "righttoleft",
        });
    }
    protected CARDCOLL_GetVariantToValidateUpdate(cardData_0: IAlumno): string {
        return cardData_0.IdChild.toString();
    }
    protected CARDCOLL_OnUpdateData(cardData_0: IAlumno): any | Promise<any> {
        this.UI_UpdateCardPermissions();
        return this.UI_UpdateCardData(true, true);
    }
    protected CARDCOLL_MostrarBody(): void {
        this.cardSelection.style("height", "100%");
        this.UI_UpdateCardPermissions();
        this.UI_UpdateCardData(false, true);
    }
    protected CARDCOLL_OcultarBody(): void {
        this.cardSelection.style("height", null);
    }
    protected CARDCOLL_OnEditarCard(originEvent: TCARDV2COLL_OnEditOriginEvent): void {
        //throw new Error("Method not implemented.");
    }
    protected CARDCOLL_OnCancelaEditarCard(originEvent: TCARDV2COLL_OnEditOriginEvent): void {
        //throw new Error("Method not implemented.");
    }
    protected CARDCOLL_GuardarCardV2(): Promise<DataDRequest.IRequestResponseA<any>> {
        throw new Error("Method not implemented.");
    }
    protected CARDCOLL_SyncOrGetIdToDownloadData(): DataModuloMain.TipoRequestMonitorId | DataModuloMain.TipoRequestMonitorId[] | (() => any | Promise<any>) {
        return () => this.UI_UpdateCardData();
    }
    protected CARDCOLL_GetIdSchool(cardData_0_0: IAlumno): number {
        return cardData_0_0.IdKinder;
    }

    /* public met_Destroy(): this {
        super.met_Destroy();
        MainPage.fn_RemoveEventListenerWorkerRequest(Entidad.CTipoRequest.Alumno, this["__workerListenerReqAlumno"]);
        this["__workerListenerReqAlumno"] = null;
        return this;
    } */

    private UI_UpdateCardInfoStatus(info: ICurrentData = this.GetCurrentInfoGeneral()) {
        const infoLeft = this.cardContentContainerSelection.select(".subareainfo_left");
        const infoRight = this.cardContentContainerSelection.select(".subareainfo_right");

        let strTutores = (info.Tutoes.length == 0) ? UIUtilLang._GetUIString("general", "sinasignaciones") : info.Tutoes.join(", ");

        infoLeft.html(`
                    <label>${this.CARDCOLL_GetUIStringModule("infoalumno_nombre")}:
                        <span>${info.AlumnoNombre}</span>
                    </label>
                    <!--
                    <label>${this.CARDCOLL_GetUIStringModule("infoalumno_nocta")}:
                        <span>${info.NoCuenta}</span>
                    </label>
                    -->
                    <label>${this.CARDCOLL_GetUIStringModule("infoalumno_tutoresnombrea")}:
                        <span>${strTutores}</span>
                    </label>
                    <label>${this.CARDCOLL_GetUIStringModule("infoalumno_nivel")}:
                        <span>${info.StrNivel}</span>
                    </label>
                `)

        infoRight.html(`
                    <label>${this.CARDCOLL_GetUIStringModule("infocta_fecha")}:
                        <span>${UIUtilTime._DateFormatStandar(new Date())}</span>
                    </label>
                    <label>${this.CARDCOLL_GetUIStringModule("infocta_periodo")}:
                        <span>${info.StrPeriodo || "---"}</span>
                    </label>
                    <label>${this.CARDCOLL_GetUIStringModule("infocta_saldoperiodo")}:
                        <span>${UIUtilFormat._CurrencyFmt(info.SaldoPeriodo)}</span>
                    </label>
                    <label>${this.CARDCOLL_GetUIStringModule("infocta_saldopendiente")}:
                        <span>${UIUtilFormat._CurrencyFmt(info.SaldoPendiente)}</span>
                    </label>
                    <label>${this.CARDCOLL_GetUIStringModule("infocta_totinteres")}:
                        <span>${UIUtilFormat._CurrencyFmt(info.InteresesGenerados)}</span>
                    </label>
                    <label>${this.CARDCOLL_GetUIStringModule("infocta_totpagar")}:
                        <span class="sometotal">${UIUtilFormat._CurrencyFmt(info.TotalPago)}</span>
                    </label>
                `)

        Button._EnableButton(this.btnGuardarCard._d3Selection, (info.TotalPago > 0));
    }

    /** Parte de la configuración de la tabla: Filas hijas de la principal, visualización de los detalles de un cargo */
    private GetTableConfigMiltiline(): Table.IRowMultilineConfig<IMovimiento, IMovimientoDetalle> {
        return {
            OnGetDataToAddRow: (movimiento) => {
                return movimiento.Detalles
            },
            OnStepCell: (content, datoChildMov, field: keyof IMovimiento, datoAsig) => {
                content
                    .text(this.GetUIStrFieldData("detalle", field, datoChildMov));

                switch (field) {
                    // case CTableColumns.Periodo:
                    //     break;
                    case CTableColumns.Descripcion:
                        content.classed("link_item", false);
                        content.on("click", null);
                        content.node().onclick = null

                        switch (datoChildMov.Categoria) {
                            case CCategoriaMov.Descuento:
                                content.classed("link_item", true).node()
                                    .onclick = e => (e.stopPropagation(), this.OpenModal_Detalle("descuento", datoChildMov));
                                break;
                            case CCategoriaMov.Morosidad:
                                content.classed("link_item", true).node()
                                    .onclick = e => (e.stopPropagation(), this.OpenModal_Detalle("morosidad", datoChildMov));
                                break;
                            case CCategoriaMov.PagoCompleto:
                            case CCategoriaMov.PagoParcial:
                                if (datoChildMov.IdFacturaFolio > 0) {
                                    content.classed("link_item", true).node()
                                        .onclick = e => (e.stopPropagation(), FacturacionUtil._OpenModal_ViewDescargarCFDIFiles_ByFolio(datoChildMov.UUID, this.GetIdEscuela(), datoChildMov.IdFacturaFolio));
                                }
                                break;
                        }
                        // >> CCategoriaMov.PagoCompleto || CCategoriaMov.PagoParcial
                        const facturaTag = content.select<HTMLDivElement>(".tag_factura").node()
                        if (datoChildMov.IdFacturaFolio > 0 && !facturaTag) {
                            content.append("div")
                                .text(_L("panelfinanzasedocuenta.facturado"))
                                .attr("class", "tag_factura")
                                .style("background-color", "var(--color_app_blue1)")
                                .style("border-radius", "var(--border_radius_base)")
                                .style("padding", "1px 2px")
                                .style("margin-top", "2px")
                                .style("width", "min-content")
                        } else if (!datoChildMov.IdFacturaFolio && facturaTag) {
                            facturaTag.remove()
                        }
                        break;
                    case CTableColumns.Valor:
                        if (datoChildMov.Categoria == CCategoriaMov.Descuento) {
                            this.VerifyAndApplyNegativeColor(-datoChildMov.Valor, content);
                        }
                        break;
                    // case CTableColumns.Recargo:
                    //     break;
                    case CTableColumns.PagoAbono:
                        if ((datoChildMov.Categoria == CCategoriaMov.PagoCompleto) || (datoChildMov.Categoria == CCategoriaMov.PagoParcial)) {
                            this.VerifyAndApplyNegativeColor(-datoChildMov.Valor, content);
                        }
                        break;
                    case CTableColumns.Saldo:
                        this.VerifyAndApplyNegativeColor(datoChildMov.Saldo, content);
                        break;
                    // case CTableColumns.FechaPago:
                    //     break;
                    // case CTableColumns.MetodoPago:
                    //     break;
                }
            },
            OnStepNewTrs: (datum, tr, parentData) => {
                // const invalidMovToDesc = datum.ParentMovimiento.Detalles
                //     .find(d => (d.Categoria == CCategoriaMov.PagoParcial) || (d.Categoria == CCategoriaMov.PagoCompleto));
                const ctrlMenuAux = this.ctrlMenuInRowAux;
                const actionAvailable = [
                    CCategoriaMov.Descuento,
                    CCategoriaMov.Morosidad,
                    CCategoriaMov.PagoCompleto,
                ].includes(datum.Categoria);

                if (ctrlMenuAux._Visible) {
                    ctrlMenuAux._Hide();
                }

                tr.classed("row_shadow_inset", actionAvailable);

                if (!actionAvailable) {
                    tr.node().onclick = (e) => {
                        e.stopPropagation();
                        console.debug(datum);
                    }
                } else {
                    tr.node().onclick = (e) => {
                        e.stopPropagation();
                        console.debug(datum);
                        this.ctrlTabla._MenuInRow._Hide();

                        const visible = ctrlMenuAux._Visible;
                        const idRef = ctrlMenuAux["__IdRef"];
                        const fnMenuAuxHideAndClear = () => {
                            ctrlMenuAux._Hide();
                            ctrlMenuAux._UpdateMenuOptions([]); // ? null
                        }

                        if (visible && idRef == datum.Id) {
                            fnMenuAuxHideAndClear()
                        } else {
                            let opt: MenuFlex.IMenuItemConfig[] = [];
                            if (datum.Categoria == CCategoriaMov.Descuento && this.HasActionPermission(Entidad.CAccionPermiso.EliminarDescuentos)) {
                                const esSaldado = datum.ParentMovimiento.Detalles.some(d => [CCategoriaMov.PagoCompleto].includes(d.Categoria))
                                opt.push({
                                    Label: this.CARDCOLL_GetUIStringModule("action_eliminar_desc"),
                                    OnClick: async () => {
                                        fnMenuAuxHideAndClear()
                                        console.log("Descuento ", datum);
                                        this.OpenModal_EliminarDescuento(datum);
                                    },
                                    Enabled: !esSaldado,
                                    Description: esSaldado ? _L("panelfinanzasedocuenta.tag_saldado") : ""
                                })
                            }
                            if (datum.Categoria == CCategoriaMov.Morosidad && this.HasActionPermission(Entidad.CAccionPermiso.Eliminar)) {
                                // NOTE: Eliminar morosidad si es último movimiento
                                if (datum.ParentMovimiento.Detalles[datum.ParentMovimiento.Detalles.length - 1] === datum) {
                                    opt.push({
                                        Label: this.CARDCOLL_GetUIStringModule("action_eliminar_moro"),
                                        OnClick: () => {
                                            fnMenuAuxHideAndClear()
                                            console.log("Morosidad ", datum);
                                            this.OpenModal_EliminarMorosidad(datum);
                                        },
                                    })
                                }
                            }
                            if (DataUtil._Usuario._PerfilAdmin && datum.Categoria == CCategoriaMov.PagoCompleto) { // && this.HasActionPermission(Entidad.CAccionPermiso.Eliminar)) {
                                const esPagoOpay = DataModuloMain._GetDataValueFieldByName("FinanzaMetodoPago", datum.IdMetodoPago, "ProveedorElectronico") == 1
                                const esFacturado = datum.IdFacturaFolio != 0
                                opt.push({
                                    Label: this.CARDCOLL_GetUIStringModule("action_eliminar_pago_7"),
                                    OnClick: () => {
                                        fnMenuAuxHideAndClear()
                                        console.log("Pago ", datum);
                                        this.OpenModal_EliminarPago(datum);
                                    },
                                    Enabled: !esPagoOpay && !esFacturado,
                                    Description: esFacturado ? _L("panelfinanzasedocuenta.no_eliminar_pago_factura") : esPagoOpay ? _L("panelfinanzasedocuenta.no_eliminar_pago_opay") : null,
                                })
                            }
                            if (opt.length > 0) {
                                this.ctrlTabla._MenuInRow
                                    ._OnShow(() => {
                                        fnMenuAuxHideAndClear()
                                    })
                                ctrlMenuAux["__IdRef"] = datum.Id;
                                ctrlMenuAux._SetParent(tr.select(".lastcell"));
                                const DivSpaceTable = this.ctrlTabla._Control.select(".area_control").select<HTMLDivElement>(".space_tabla");
                                ctrlMenuAux._SetOnGetMaxWidthCallback(() => TableUtil._GetMaxWidthMenuFlex(tr, 0, DivSpaceTable, ctrlMenuAux))
                                ctrlMenuAux._SetResizeReferenceSelection(DivSpaceTable);
                                ctrlMenuAux._UpdateMenuOptions(opt)
                                ctrlMenuAux._Show();
                            }
                        }
                    }
                }
                return true;
            }
        }
    }

    private VerifyAndApplyNegativeColor(valor: number, element?: d3.Selection<HTMLElement, any, any, any>): string {
        let color = (valor < 0) ? "red" : null;
        element?.style("color", color);
        return color;
    }

    // ************************************************************************
    // DETALLES
    // ************************************************************************

    private OpenModal_Detalle<TMode extends ("cargo" | "descuento" | "morosidad")>(mode: TMode, dato: (TMode extends "cargo" ? IMovimiento : IMovimientoDetalle)) {
        const width = (mode == "descuento" ? 350 : 390);

        ModalThings._GetModalSimple({
            Title: this.CARDCOLL_GetUIStringModule("tag_details"),
            Width: width,
            DrawContent: async (content, modalThings) => {
                const infoNoFoundTag = UIUtilLang._GetUIString("general", "notif_infonodisponible");
                const fnBuildInfoTags = (tagsArray: [string, string][]) => {
                    // >> Draw
                    let html = "";
                    tagsArray
                        .forEach((tags) => {
                            html += `<label><b>${this.CARDCOLL_GetUIStringModule(tags[0])}</b>: ${tags[1] || infoNoFoundTag}</label>`;
                        });

                    content
                        .classed("body_informacion", true)
                        .html(html);
                }

                switch (mode) {
                    case "cargo":
                        const cargoData = (dato as IMovimiento);
                        const morosidadStr: [string, string][] = this.GetAdeudoRecargoFromCargo(cargoData) > 0
                            ? [["tag_fechamorosidad", UIUtilTime._DateFormatStandar(cargoData.FechaMorosidad, "d MMM yyyy")]]
                            : [];
                        fnBuildInfoTags([
                            ["tag_descript", cargoData.Cargo],
                            ["d_field_saldo", UIUtilFormat._CurrencyFmt(this.GetSaldoMovimiento(cargoData))],
                            ["tag_periodic", UIUtilViewData._GetStr_Periodicidad(cargoData.Periodicidad)],
                            ["tag_fechaaplica", UIUtilTime._DateFormatStandar(cargoData.FechaAplicacion, "d MMM yyyy")],
                            ["tag_fechavence", UIUtilTime._DateFormatStandar(cargoData.FechaVencimiento, "d MMM yyyy")],
                            ...morosidadStr,
                        ])
                        break;
                    case "descuento":
                        const descuentoData = (dato as IMovimientoDetalle);
                        fnBuildInfoTags([
                            ["tag_descript", descuentoData.Cargo],
                            ["tag_val", UIUtilFormat._CurrencyFmt(descuentoData.Valor)], // NOTE Falta el valor de tipo Porcentaje
                            ["tag_tipo", UIUtilViewData._GetStr_TipoValor(descuentoData.TipoValor)]
                        ])
                        break;
                    case "morosidad":
                        modalThings.Progress.attr("oculto", false);
                        let tagRecargos = this.CARDCOLL_GetUIStringModule("tag_recargomoroso");
                        fnBuildInfoTags([
                            ["tag_descript", tagRecargos],
                            ["tag_fechamorosidad", null],
                            ["tag_recargosmorosidad", null]
                        ]);

                        const recargo = (dato as IMovimientoDetalle);
                        const morosidadRes = await DataModuloFinanzasAsignaciones._ObtenerCargoCloneByID(recargo.IdCargo);

                        if (morosidadRes.Resultado > 0) {
                            fnBuildInfoTags([
                                ["tag_descript", tagRecargos],
                                ["tag_fechamorosidad", UIUtilTime._DateFormatStandar(recargo.FechaMorosidad, "d MMM yyyy")],
                                ["tag_recargosmorosidad", (UIUtilViewFinanzaCargo._GetStrRecargosCol(morosidadRes.Datos) || "N/A")]
                            ]);
                        } else {
                            NotificacionV2._Mostrar(UIUtilLang._GetUIString("general", "notif_infonodisponible"), "ADVERTENCIA");
                        }
                        modalThings.Progress.attr("oculto", true);
                        break;
                }
            }
        })
    }

    // ************************************************************************
    // PROCESO: DE REGISTRO DE PAGO
    // ************************************************************************

    private OpenModal_ProcesoPago(onClose: () => void) {
        enum CTipoProcesoPago {
            PagoSaldoTotal = 1,
            PagoSaldoPeriodo = 2,
            PagoParcial = 3
        }
        type IBaseData = UIUtilViewData.IBaseData;
        type IItemCargoPago = {
            Cargo: IMovimiento;
            Pago: number;
        }
        type IDataForm = {
            TipoPago: CTipoProcesoPago;
            IdsCargosParciales: number[];
            CargosToPay: IItemCargoPago[];
            IdMetodoPago: number;
        }

        const metodosPagoList = this.GetMetodosPagoList();
        const info = this.GetCurrentInfoGeneral();
        const idEscuela = this.GetIdEscuela();
        const tipoPagosList = [
            { Id: CTipoProcesoPago.PagoSaldoTotal, Name: this.CARDCOLL_GetUIStringModule("tag_paytotal") },
            { Id: CTipoProcesoPago.PagoSaldoPeriodo, Name: this.CARDCOLL_GetUIStringModule("tag_payperiod") },
            { Id: CTipoProcesoPago.PagoParcial, Name: this.CARDCOLL_GetUIStringModule("tag_paypartial") }
        ];

        if (metodosPagoList.length == 0) {
            this.ctrlNotification._Mostrar(
                this.CARDCOLL_GetUIStringModule("notif_escsinmtdspago")
                    .replace("_ESCUELA", this.GetEscuela()?.Nombre),
                "ADVERTENCIA"
            );
            return;
        }

        const movimientosFaltoPagoList = info.MovimientosTbl_TopPeriodo
            .filter(d => (this.GetSaldoMovimiento(d) > 0));

        const movimientosPeriodoFaltoPagoList = info.MovimientosPeriodo
            .filter(d => (this.GetSaldoMovimiento(d) > 0));

        const fnUpdateProcesoPagoDataFromFormsData = () => {
            procesoPagoData.TipoPago = formTipoPago._Data.TipoPago;
            procesoPagoData.IdsCargosParciales = formTipoPago._Data.IdsCargosParciales;
            procesoPagoData.IdMetodoPago = formMetodoPago?._Data?.IdMetodoPago;
        }

        let procesoPagoData = <IDataForm>{};

        let formTipoPago: FormGenerator<IDataForm>;
        let formMetodoPago: FormGenerator<IDataForm>;
        let ctrlTblPagosParciales: Table.Tabla<IItemCargoPago>;

        ModalThings._GetModalToALongProccess({
            Modulo: this.modulo,
            Action: Entidad.CAccionPermiso.RegistrarPagos,
            IdsEscuelas: [idEscuela],
            StartEndIds: ["pay_tipopago", "pay_mtdopago"],
            StepsConfig: [
                {
                    // >> Step 1: Selecciona el tipo de pago. Cargos agrupados en:
                    // * Pago del saldo total: todos los visibles en la tabla
                    // * Pago del saldo del periodo: todos los del periodo filtrado
                    // * Pago parcial: solo los que seleccione el usuario
                    Id: "pay_tipopago",
                    Title: "tag_paycharges",
                    NextID: () => ((formTipoPago._Data.TipoPago == CTipoProcesoPago.PagoParcial) ? "pay_parcialpagos" : "pay_resumen"),
                    Width: 420,
                    OnDrawContent: (content, mt) => {
                        const fnUpdateFormTipoPago = (idTipoPago: CTipoProcesoPago) => {
                            const esPagoParcial = (idTipoPago == CTipoProcesoPago.PagoParcial);
                            const formRowCargosParciales = formTipoPago._ControlsData.get("IdsCargosParciales");

                            let selectPagosParciales = formTipoPago._ControlsData.get("IdsCargosParciales").selection;
                            selectPagosParciales.attr("required", esPagoParcial ? "" : null);

                            formRowCargosParciales.row
                                .classed("hide", !esPagoParcial);

                            if (esPagoParcial) {
                                (formRowCargosParciales.instance as SelectV2)
                                    ._ResetSelect()
                                    ._showOptionsList();
                            } else {
                                mt.BtnRight._Enable(!!idTipoPago);
                            }
                        }

                        formTipoPago = new FormGenerator<IDataForm>()
                            ._Crear({
                                LangModuleKeyInContext: this.moduloName,
                                LabelMaxWidth: 125,
                                schema: [
                                    {
                                        model: "TipoPago",
                                        labelAttr: { text: "tag_paytype" },
                                        type: Fields.selectMaterial,
                                        selectMaterialAttr: {
                                            required: true,
                                            valueMember: "Id",
                                            displayMember: "Name",
                                            onSelect: (dato: IBaseData) => {
                                                if (formTipoPago) {
                                                    fnUpdateFormTipoPago(dato?.Id);
                                                }
                                            },
                                            // onChange: (id, dato: IBaseData) => {}
                                        },
                                        values: tipoPagosList
                                    },
                                    {
                                        model: "IdsCargosParciales",
                                        labelAttr: { text: "tag_chargestopay" },
                                        type: Fields.selectMaterial,
                                        selectMaterialAttr: {
                                            valueMember: "Id",
                                            displayMember: "Cargo",
                                            multiselect: true,
                                            required: false,
                                            onSelect: (datos: IMovimiento[]) => {
                                                mt.BtnRight._Enable(!!datos.length);
                                                // console.log(datos);
                                            },
                                            // onChange: (a: number, b: IMovimiento[]) => {}
                                            OnStepItemListUI: (container, data: IMovimiento, step) => {
                                                let one = container.select(".one");
                                                let two = container.select(".two");
                                                let three = container.select(".three");
                                                if (step == "enter") {
                                                    container.classed(UIUtilGeneral.FBoxOrientation.Vertical, true);
                                                    one = container.append("label").classed("one", true);
                                                    two = container.append("label").classed("two", true);
                                                    three = container.append("label").classed("three", true);
                                                }
                                                one.text(this.CARDCOLL_GetUIStringModule("tag_period") + ": " + data.Periodo);
                                                two.text(this.CARDCOLL_GetUIStringModule("tag_descript") + ": " + data.Cargo);
                                                three.text(this.CARDCOLL_GetUIStringModule("d_field_saldo") + ": " + UIUtilFormat._CurrencyFmt(this.GetSaldoMovimiento(data)));
                                            },
                                            OnChangeSearchText_GetDataValueToEval: (dato: IMovimiento) => `${dato.Periodo} ${dato.Cargo} ${UIUtilFormat._CurrencyFmt(this.GetSaldoMovimiento(dato))}`,
                                            ShowAndEnableSearchText: true,
                                            ShowNSelectedInList: true
                                        },
                                        values: movimientosFaltoPagoList
                                    }
                                ],
                                // Validation: (val, field) => {
                                //     if (field == "TipoPago") {
                                //         return Boolean(val);
                                //     }
                                //     return true;
                                // }
                            }, procesoPagoData);


                        // Deshabilita tipo de pago para el total del periodo, si no hay datos que cargos que pagar
                        if (!movimientosPeriodoFaltoPagoList.length) {
                            (formTipoPago._ControlsData.get("TipoPago").instance as SelectV2)
                                ._disableItems(CTipoProcesoPago.PagoSaldoPeriodo);
                        }

                        // Inicializa vista del frm
                        fnUpdateFormTipoPago(null);

                        content.append(() => formTipoPago._Form.node());
                    },
                    OnValideStep: () => {
                        const formIsValid = formTipoPago._GetIsValidForm();

                        if (formIsValid) {
                            fnUpdateProcesoPagoDataFromFormsData();

                            // Ajusta los cargos a pagar antes de pasar a la vista siguiente
                            procesoPagoData.CargosToPay = [];

                            switch (formTipoPago._Data.TipoPago) {
                                case CTipoProcesoPago.PagoSaldoTotal:
                                    procesoPagoData.CargosToPay = movimientosFaltoPagoList
                                        .map<IItemCargoPago>(d => ({
                                            Cargo: d,
                                            Pago: this.GetSaldoMovimiento(d)
                                        }))
                                    break;
                                case CTipoProcesoPago.PagoSaldoPeriodo:
                                    procesoPagoData.CargosToPay = movimientosPeriodoFaltoPagoList
                                        .map<IItemCargoPago>(d => ({
                                            Cargo: d,
                                            Pago: this.GetSaldoMovimiento(d)
                                        }));
                                    break;
                                case CTipoProcesoPago.PagoParcial:
                                    const ctrlSelectCargos = (formTipoPago._ControlsData.get("IdsCargosParciales").instance as unknown as SelectV2<IMovimiento, "Id", "multiselect">);

                                    procesoPagoData.CargosToPay = ctrlSelectCargos._dataSelected
                                        .map(d => ({
                                            Cargo: d,
                                            Pago: this.GetSaldoMovimiento(d)
                                        }))
                                    break;
                            }
                            console.warn("-d", "procesoPagoData", procesoPagoData);
                            return Boolean(procesoPagoData.CargosToPay.length);
                        }

                        return false;
                    }
                },
                {
                    // >> Step 1.1: Solo cuando el tipo de pago sea Parcial
                    Id: "pay_parcialpagos",
                    Title: "tag_payselchargs",
                    PreviousID: "pay_tipopago",
                    NextID: "pay_resumen",
                    Width: 650,
                    OnDrawContent: (content, mt) => {
                        content
                            .classed(UIUtilGeneral.FBoxOrientation.Vertical, true)
                            .style("row-gap", "10px")
                            .append("label")
                            .text(this.CARDCOLL_GetUIStringModule("tag_confirmpays"));

                        const fnValidaInputPagoUI = (itemPago: IItemCargoPago, inputBox: TSelectionHTML<"div">, cellContent: TSelectionHTML<"div">) => {
                            let resValidation = this.GetPagoValidation(itemPago.Pago, itemPago.Cargo);

                            // inputBox.classed("input_err", !resValidation.IsValid);

                            if (resValidation.IsValid) {
                                cellContent.select(".lbl_error").remove();
                                // mt.Notificator.fn_Mostrar(resValidation.Message, "ADVERTENCIA");
                            } else {
                                let lblError = cellContent.select(".lbl_error");

                                if (!lblError.node()) {
                                    lblError = cellContent.append("label")
                                        .attr("class", "lbl_error")
                                        .style("color", "red")
                                        .style("cursor", "pointer")
                                        .style("font-size", "calc(var(--fontsize_me4) - 2px)");
                                }

                                lblError
                                    .on("click", () => {
                                        let pagoEsperadoFixer: number;

                                        if (resValidation.TypeFix == "minrecargo") {
                                            pagoEsperadoFixer = this.GetAdeudoRecargoFromCargo(itemPago.Cargo);
                                        } else {
                                            pagoEsperadoFixer = this.GetSaldoMovimiento(itemPago.Cargo);
                                        }

                                        itemPago.Pago = pagoEsperadoFixer;
                                        inputBox.select("input")
                                            .property("value", pagoEsperadoFixer);

                                        fnValidaInputPagoUI(itemPago, inputBox, cellContent);
                                    })
                                    .text(resValidation.Message);
                            }
                        }

                        ctrlTblPagosParciales = new Table.Tabla<IItemCargoPago>({
                            IdTabla: "AlumnosPanelEstadoCuenta-PayPagosParciales",
                            Parent: content,
                            IdData: "" as any,
                            MinWidth: 580,
                            HideCheckboxes: true,
                            Resizable: false,
                            RenderColumnHeadings: [
                                { Field: "Periodo" as any, Label: this.CARDCOLL_GetUIStringModule("tag_perioddesc"), IsSortable: false, MinWidth: "120px", Width: "50%" },
                                { Field: "Saldo" as any, Label: this.CARDCOLL_GetUIStringModule("tag_debe"), IsSortable: false, MinWidth: "90px", Width: "25%" },
                                { Field: "Pago" as any, Label: this.CARDCOLL_GetUIStringModule("tag_montopay"), IsSortable: false, MinWidth: "90px", Width: "25%" },
                            ],
                            OnValueSelectRow: (id, datum) => {
                                console.log(id, datum);
                            },
                            EvaluatorAndSubLevelsBuild: {
                                OnStepRowTable: (itemCargo, tr) => {
                                    tr
                                        .classed("cargo_" + itemCargo.Cargo.Id, true)
                                        .style("border-bottom", "none");
                                },
                                OnStepCellTable: (container, datum, field) => {
                                    let saldo = this.GetSaldoMovimiento(datum.Cargo);

                                    switch (field) {
                                        case "Periodo":
                                            container.classed(UIUtilGeneral.FBoxOrientation.Vertical, true);
                                            container.html(`
                                                        <span id="periodo">${this.CARDCOLL_GetUIStringModule("tag_period")}: ${datum.Cargo.Periodo}</span>
                                                        <span id="descripcion">${this.CARDCOLL_GetUIStringModule("tag_descript")}: ${datum.Cargo.Cargo}</span>
                                                    `);
                                            break;
                                        case "Saldo":
                                            container.text(UIUtilFormat._CurrencyFmt(saldo))
                                            break;
                                        case "Pago":
                                            let inputC = container.select<HTMLDivElement>(".input_content");

                                            if (!inputC.node()) {
                                                inputC = container
                                                    .style("width", "100%")
                                                    .html(`<div class="input_content input_currency"></div>`)
                                                    .select<HTMLDivElement>(".input_content")
                                                    .style("position", "relative");

                                                inputC.append<HTMLInputElement>("input")
                                                    .attr("type", "number")
                                                    .attr("step", ".01");
                                                inputC.append("div")
                                                    .attr("class", "input_symbol")
                                                    .append("span")
                                                    .text("$");
                                            }
                                            inputC.select<HTMLInputElement>("input")
                                                .attr("min", this.GetAdeudoRecargoFromCargo(datum.Cargo))
                                                .attr("max", saldo)
                                                .property("value", datum.Pago)
                                                .node()
                                                .onchange = (e) => {
                                                    datum.Pago = d3.select(e.target as HTMLInputElement).node().valueAsNumber;

                                                    if (isNaN(datum.Pago)) {
                                                        datum.Pago = 0;
                                                        (e.target as HTMLInputElement).value = "0";
                                                    }

                                                    fnValidaInputPagoUI(datum, inputC, container);
                                                };

                                            fnValidaInputPagoUI(datum, inputC, container);
                                            break;
                                        // return false;
                                    }
                                }
                            }
                            // OnStepDelayedItemRowTableV2: (rowBody, tr) => {return true;},
                            // OnStepItemTableV2: (container, datum, field, index) => {}
                        });
                    },
                    OnFocusContent: (content, mt) => {
                        ctrlTblPagosParciales._UpdateData(procesoPagoData.CargosToPay);
                    },
                    OnValideStep: (content, mt) => {
                        let isValid = true;
                        let message: string;

                        for (let cargoAPagar of procesoPagoData.CargosToPay) {
                            let resValidation = this.GetPagoValidation(cargoAPagar.Pago, cargoAPagar.Cargo);

                            if (!resValidation.IsValid) {
                                isValid = resValidation.IsValid;
                                message = resValidation.Message;

                                ctrlTblPagosParciales._Control
                                    .select(".rbodyB")
                                    .select<HTMLTableRowElement>(".cargo_" + cargoAPagar.Cargo.Id)
                                    .node()
                                    ?.scrollIntoView({
                                        block: "center",
                                        behavior: "smooth"
                                    });
                                break;
                            }
                        }

                        return {
                            IsValid: isValid,
                            Message: message
                        }
                    }
                },
                {
                    // >> Step 2: Resumen del pago a aplicar
                    Id: "pay_resumen",
                    Title: "", // "tag_resum",
                    PreviousID: () => ((formTipoPago._Data.TipoPago == CTipoProcesoPago.PagoParcial) ? "pay_parcialpagos" : "pay_tipopago"),
                    NextID: "pay_mtdopago",
                    Width: 560,
                    OnDrawContent: (content, mt) => {
                        content.classed("body_pagodos_info", true);
                    },
                    OnFocusContent: (content, mt) => {
                        mt.Modal._SetTitle(`${this.CARDCOLL_GetUIStringModule("tag_resum")} - ${tipoPagosList.find(d => (d.Id == formTipoPago._Data.TipoPago)).Name}`);
                        Button._EnableButton(mt.BtnRight, true);

                        const fnBuildResumenContent = (body: d3.Selection<HTMLDivElement, any, any, any>, cargosToPay: IItemCargoPago[], description?: string) => {
                            body.html(`
                                    <label class="description ${(!description ? "hide" : "")}">${description}</label>
                                    <div class="list ${UIUtilGeneral.FBoxOrientation.Vertical}">
                                        <div class="cargos_header">
                                            <div class="item_row">
                                                <label class="desc">${this.CARDCOLL_GetUIStringModule("tag_cargo")}</label>
                                                <label class="debe">${this.CARDCOLL_GetUIStringModule("tag_debe")}</label>
                                                <label class="pago">${this.CARDCOLL_GetUIStringModule("tag_pago")}</label>
                                            </div>
                                        </div>
                                        <div class="cargos ${UIUtilGeneral.FBoxOrientation.Vertical}">
                                            <div class="item_row">
                                                <div class="desc"></div>
                                                <div class="debe"></div>
                                                <div class="pago"></div>
                                            </div>
                                        </div>
                                        <div class="totales ${UIUtilGeneral.FBoxOrientation.Vertical}">
                                            <div class="item_row">
                                                <label class="desc"></label>
                                                <label class="debe"></label>
                                                <label class="pago"></label>
                                            </div>
                                        </div>
                                    </div>
                                    `);

                            body.select<HTMLDivElement>(".cargos").selectAll<HTMLDivElement, IItemCargoPago>(".item_row").data(cargosToPay).join(
                                enter => {
                                    let itemRow = enter.append("div")
                                        .attr("class", "item_row");

                                    itemRow.append("div").attr("class", "desc").html(d => `
                                                <label>${this.CARDCOLL_GetUIStringModule("tag_period")}: ${d.Cargo.Periodo}</label>
                                                <label>${this.CARDCOLL_GetUIStringModule("tag_descript")}: ${d.Cargo.Cargo}</label>
                                            `);
                                    itemRow.append("div").attr("class", "debe").text(d => UIUtilFormat._CurrencyFmt(this.GetSaldoMovimiento(d.Cargo)));
                                    itemRow.append("div").attr("class", "pago").text(d => UIUtilFormat._CurrencyFmt(d.Pago));
                                    return itemRow;
                                },
                                update => {
                                    update.select(".desc").html(d => `
                                                <label>${this.CARDCOLL_GetUIStringModule("tag_period")}: ${d.Cargo.Periodo}</label>
                                                <label>${this.CARDCOLL_GetUIStringModule("tag_descript")}: ${d.Cargo.Cargo}</label>
                                            `)
                                    update.select(".debe").text(d => UIUtilFormat._CurrencyFmt(this.GetSaldoMovimiento(d.Cargo)));
                                    update.select(".pago").text(d => UIUtilFormat._CurrencyFmt(d.Pago));
                                    return update;
                                },
                                exit => exit.remove()
                            );
                        }


                        let totalPago = 0;
                        let deudaInicial = 0;

                        procesoPagoData.CargosToPay
                            .forEach(d => {
                                deudaInicial += this.GetSaldoMovimiento(d.Cargo);
                                totalPago += d.Pago;
                            });

                        switch (procesoPagoData.TipoPago) {
                            case CTipoProcesoPago.PagoSaldoTotal:
                            case CTipoProcesoPago.PagoSaldoPeriodo:
                                let strDescription = ""

                                if (procesoPagoData.TipoPago == CTipoProcesoPago.PagoSaldoTotal) {
                                    strDescription = this.CARDCOLL_GetUIStringModule("tag_paysaldoacumperiodo");
                                } else {
                                    strDescription = this.CARDCOLL_GetUIStringModule("tag_paysaldoperiodo");
                                }

                                fnBuildResumenContent(content, procesoPagoData.CargosToPay, (strDescription + ": " + info.StrPeriodo));

                                content.select<HTMLDivElement>(".totales")
                                    .html(`
                                                <div class="item_row">
                                                    <label class="desc">${this.CARDCOLL_GetUIStringModule("infocta_totpagar")}:</label>
                                                    <label class="debe"></label>
                                                    <label class="pago sometotal">${UIUtilFormat._CurrencyFmt(totalPago)}</label>
                                                </div>
                                            `);
                                break;
                            case CTipoProcesoPago.PagoParcial:
                                fnBuildResumenContent(content, procesoPagoData.CargosToPay);

                                content.select<HTMLDivElement>(".totales").html(`
                                            <div class="item_row">
                                                <label class="desc">${this.CARDCOLL_GetUIStringModule("infocta_totpagar")}:</label>
                                                <label class="debe"></label>
                                                <label class="pago">${UIUtilFormat._CurrencyFmt(totalPago)}</label>
                                            </div>
                                            <div class="item_row">
                                                <label class="desc">${this.CARDCOLL_GetUIStringModule("tag_deudainit")}:</label>
                                                <label class="debe"></label>
                                                <label class="pago">${UIUtilFormat._CurrencyFmt(deudaInicial)}</label>
                                            </div>
                                            <div class="item_row">
                                                <label class="desc">${this.CARDCOLL_GetUIStringModule("tag_deudaresta")}:</label>
                                                <label class="debe"></label>
                                                <label class="pago sometotal">${UIUtilFormat._CurrencyFmt(deudaInicial - totalPago)}</label>
                                            </div>
                                        `);
                                break;
                        }
                    }
                },
                {
                    // >> Step 3: Selección del método de pago (último paso)
                    Id: "pay_mtdopago",
                    Title: "tag_paymtd",
                    PreviousID: "pay_resumen",
                    Width: 400,
                    OnDrawContent: (content, mt) => {
                        formMetodoPago = new FormGenerator<IDataForm>()
                            ._Crear({
                                LangModuleKeyInContext: this.moduloName,
                                LabelMaxWidth: 140,
                                schema: [
                                    {
                                        model: "IdMetodoPago",
                                        type: Fields.selectMaterial,
                                        labelAttr: { text: "tag_paymtd" },
                                        selectMaterialAttr: {
                                            valueMember: "IdMetodoPago",
                                            displayMember: "Nombre",
                                            onSelect: (datoMP: IMetodoPago) => {
                                                Button._EnableButton(mt.BtnRight, Boolean(datoMP));
                                            }
                                        },
                                        values: metodosPagoList
                                    }
                                ],
                                Validation: (val) => Boolean(val)
                            }, procesoPagoData);

                        content.append(() => formMetodoPago._Form.node());
                    },
                    OnFocusContent: (content, mt) => {
                        if (formMetodoPago?._Data.IdMetodoPago) return;
                        setTimeout(() => {
                            (formMetodoPago._ControlsData.get("IdMetodoPago").instance as SelectV2)._showOptionsList();
                        }, 200);
                    },
                    OnValideStep: () => formMetodoPago._GetIsValidForm(),
                    OnAccept: async (mt) => {
                        fnUpdateProcesoPagoDataFromFormsData();
                        // console.warn(procesoPagoData);
                        const res = await this.Sv_RegistrarPagosMultiple(procesoPagoData.IdMetodoPago, procesoPagoData.CargosToPay);
                        const someCargosPagados = [-2, -3].includes(res.Resultado);
                        if (res.Resultado > 0 || someCargosPagados) {
                            this.UI_UpdateCardData(false, true);
                            if (someCargosPagados) {
                                mt.Modal._Ocultar();
                            }
                        }
                        return res;
                    }
                }
            ],
            OnClose: () => {
                onClose();
            }
        });
    }

    // ************************************************************************
    // PROCESO: REGISTRO DE DESCUENTO
    // ************************************************************************

    private OpenModal_AsignarDescuento(datosMovts: IMovimiento[]) {
        type TFormData = {
            IdDescuento: number;
            Descuento: IFinanzaCargo;
        };

        const datosMovsProcesar = datosMovts.filter(d => (this.GetSaldoMovimiento(d) > 0));
        const datosMovsEnCero = datosMovts.filter(d => (this.GetSaldoMovimiento(d) == 0));

        if (datosMovsProcesar.length == 0) {
            this.ctrlNotification._Mostrar("Error", "ADVERTENCIA");
            return;
        }

        const descuentosDisponiblesList = this.GetDescuentosList();
        const descuentosInvalidosList = descuentosDisponiblesList
            .filter(d => {
                return datosMovsProcesar
                    .every(cargoMov => (!UIUtilViewFinanzaCargo._DATA_ValidaDescuentosACargo(this.GetSaldoMovimiento(cargoMov), [d]).Valido))
            })
            .map(d => d.ID);

        if (descuentosDisponiblesList.length == 0) {
            this.ctrlNotification._Mostrar(this.CARDCOLL_GetUIStringModule("notif_nodescuentosregistds"), "ADVERTENCIA");
            return;
        }

        let form: FormGenerator<TFormData>;

        ModalThings._GetModalToALongProccess({
            Modulo: this.modulo,
            IdsEscuelas: [this.GetIdEscuela()],
            LangModuleKeyInContext: this.moduloName,
            StartEndIds: ["form", "info"],
            StepsConfig: [
                {
                    Id: "form",
                    Title: "action_adddesc",
                    NextID: "info",
                    Width: 400,
                    OnDrawContent: (content, mt) => {
                        form = new FormGenerator<TFormData>()
                            ._Crear({
                                LabelMaxWidth: 150,
                                LangModuleKeyInContext: this.moduloName,
                                schema: [
                                    {
                                        model: "IdDescuento",
                                        type: Fields.selectMaterial, labelAttr: { text: "frm_eligedescuento" },
                                        selectMaterialAttr: {
                                            valueMember: "ID",
                                            displayMember: "Nombre",
                                            ShowAndEnableSearchText: true,
                                            required: true,
                                            onChange: (idDescuento: number, descuentoInfo: IFinanzaCargo) => {
                                                form._DataOrigin.Descuento = descuentoInfo;
                                            },
                                            OnStepItemListUI: (container, datoDesc: IFinanzaCargo) => {
                                                const valid = datosMovsProcesar
                                                    .every(cargoM => {
                                                        return UIUtilViewFinanzaCargo._DATA_ValidaDescuentosACargo(this.GetSaldoMovimiento(cargoM), [datoDesc]).Valido;
                                                    })

                                                container
                                                    .classed(UIUtilGeneral.FBoxOrientation.Vertical, true)
                                                    .style("align-items", "start")
                                                    .text(datoDesc.Nombre);

                                                if (valid) {
                                                    container.select(".lbl_invalid")
                                                        .remove();
                                                } else {
                                                    container.append("label")
                                                        .attr("class", "lbl_invalid")
                                                        .style("color", "red")
                                                        .style("font-size", "var(--fontsize_me4)")
                                                        .text(this.CARDCOLL_GetUIStringModule("tag_excedesaldo"));
                                                }
                                            }
                                        },
                                        values: descuentosDisponiblesList
                                    },
                                ],
                                Validation: (value, field, dataForm) => {
                                    //if (value != null) {
                                    const descuentoSelected = form._DataOrigin.Descuento; // descuentosDisponiblesList.find(d => (d.ID == value as number));

                                    if (descuentoSelected) {
                                        return datosMovsProcesar
                                            .every(cargoM => {
                                                return UIUtilViewFinanzaCargo._UI_ValidaDescuentosACargo(this.GetSaldoMovimiento(cargoM), cargoM.Cargo, [descuentoSelected]);
                                            });
                                    } else {
                                        return false;
                                    }
                                    //}
                                    //return false;
                                }
                            }, <TFormData>{});

                        (form._ControlsData.get("IdDescuento").instance as SelectV2<IFinanzaCargo, "ID">)
                            ._disableItems(descuentosInvalidosList);

                        content.append(() => form._Form.node());
                    },
                    OnValideStep: (content, mt) => form._GetIsValidForm()
                },
                {
                    Id: "info",
                    Title: "action_adddesc",
                    PreviousID: "form",
                    Width: 320,
                    OnDrawContent: (content, mt) => {
                        content
                            .classed(UIUtilGeneral.FBoxOrientation.Vertical, true)
                            .style("row-gap", "10px");

                        content.append("label")
                            .text(this.CARDCOLL_GetUIStringModule("tag_movsprocesar") + ":");

                        content.append("div")
                            .attr("class", "items " + UIUtilGeneral.FBoxOrientation.Vertical)
                            .style("row-gap", "10px")
                            .style("overflow", "hidden auto")
                            .style("max-height", "400px");
                    },
                    OnFocusContent: (container, mt) => {
                        const fnUpdateItemCargo = (item: TSelectionHTML<"div", IMovimiento>) => {
                            return item
                                .each((d, i, arr) => {
                                    const esValidoParaDesc = (this.GetSaldoMovimiento(d) > 0);
                                    let container = d3.select(arr[i]);

                                    if (!container.select("label").node()) {
                                        // Nombre periodo
                                        container.append("label")
                                            .text(this.CARDCOLL_GetUIStringModule("tag_period") + ": ")
                                            .append("span");
                                        // Descripción cargo
                                        container.append("label")
                                            .text(this.CARDCOLL_GetUIStringModule("tag_descript") + ": ")
                                            .append("span")
                                            .style("cursor", "help");
                                        // Saldo
                                        container.append("label")
                                            .text(this.CARDCOLL_GetUIStringModule("d_field_saldo") + ": ")
                                            .append("span");
                                        // Descuento
                                        container.append("label")
                                            .text(this.CARDCOLL_GetUIStringModule("tag_desc") + ": ")
                                            .append("span")
                                            .style("color", "red");
                                    }
                                    // Tag periodo
                                    container.select("label:nth-child(1) > span")
                                        .text(d.Periodo);

                                    // Tag descripción cargo
                                    let lblCargoNombre = container.select("label:nth-child(2) > span")
                                        .style("color", (!esValidoParaDesc ? "red" : null))
                                        .text(d.Cargo);

                                    if (!esValidoParaDesc) {
                                        if (!lblCargoNombre.select("img").node()) {
                                            lblCargoNombre.append("img")
                                                .attr("src", UIUtilIconResources.CGeneral.InfoMark)
                                                .attr("draggable", false)
                                                .style("margin-left", "5px")
                                                .style("width", "14px");

                                            lblCargoNombre.append("wc-tooltip")
                                                .attr("max-width", "150px")
                                                .attr("position", "right");
                                        }

                                        lblCargoNombre.select("wc-tooltip")
                                            .html(`<span style="color:red;">${this.CARDCOLL_GetUIStringModule("tag_noaplica")}.</span> <br>${this.CARDCOLL_GetUIStringModule("tag_cargocero")}`);
                                    }
                                    else {
                                        lblCargoNombre.select("img").remove();
                                        lblCargoNombre.select("wc-tooltip").remove();
                                    }

                                    // Tag Saldo
                                    container.select("label:nth-child(3) > span")
                                        .text(UIUtilFormat._CurrencyFmt(this.GetSaldoMovimiento(d)));

                                    // Tag Descuento
                                    let tagDescuento = "";

                                    if (esValidoParaDesc) {
                                        let descuentoSelected = form._DataOrigin.Descuento;
                                        let descuentoValor = UIUtilViewFinanzaCargo._DATA_ValidaDescuentosACargo(this.GetSaldoMovimiento(d), [descuentoSelected]).DescuentoMontoTotal;
                                        tagDescuento = "-" + UIUtilFormat._CurrencyFmt(descuentoValor);

                                        if (descuentoSelected.TipoValor == CTipoValor.Porcentaje) {
                                            tagDescuento += ` (${UIUtilFormat._PercentFmt(descuentoSelected.Valor)})`;
                                        }
                                    }

                                    container.select("label:nth-child(4)")
                                        .classed("hide", !esValidoParaDesc)
                                        .select("span")
                                        .text(tagDescuento);
                                });
                        }

                        container.select(".items")
                            .selectAll<HTMLDivElement, IMovimiento>(".itemcargo")
                            .data([...datosMovsProcesar, ...datosMovsEnCero])
                            .join(
                                enter => fnUpdateItemCargo(
                                    enter.append("div")
                                        .attr("class", "itemcargo")
                                        .classed(UIUtilGeneral.FBoxOrientation.Vertical, true)
                                ),
                                update => fnUpdateItemCargo(update),
                                exit => exit.remove()
                            )
                    },
                    OnAccept: async (mt) => {
                        const res = await DataModuloFinanzasEstadoCuenta._RegistrarDescuentos(datosMovsProcesar.map(d => d.Id), this.cardData[0].IdChild, form._Data.IdDescuento);
                        if (res.Resultado > 0 || [-1, -2].includes(res.Resultado)) {
                            this.UI_UpdateCardData();
                        }
                        return res;
                    }
                }
            ]
        });
    }

    // ************************************************************************
    // PROCESO: ELIMINAR REGISTRO
    // ************************************************************************

    private OpenModal_EliminarRegistro(datoAsig: IMovimiento[]) {
        const { IdChild, IdKinder } = this.cardData[0] || {};
        let forceUpdate = false;
        this.CARDCOLLAD_OpenModal_ProccessArrayData({
            DataToProccess: datoAsig,
            Width: 400,
            Message: this.CARDCOLL_GetUIStringModule("confirma_eliminar"),
            OnGetIdEscuela: (dato) => IdKinder,
            OnError_GetItemDataTag: (dato) => {
                let tag = `${this.CARDCOLL_GetUIStringModule("tag_descript")}: <b>${dato.Cargo}</b>`;
                // tag += `<br>Periodo: ${Utils.Time.fn_DateFormatStandar(new Date(dato.FechaInicio))} - ${Utils.Time.fn_DateFormatStandar(new Date(dato.FechaFin))}`;
                tag += `<br>${this.CARDCOLL_GetUIStringModule("tag_period")}: ${dato.Periodo}`;
                return tag + `<br>${this.CARDCOLL_GetUIStringModule("tag_fechaaplica")}: ${UIUtilTime._DateFormatStandarFixTimeZoneByIdSchool(dato.FechaAplicacion, IdKinder)}`;
            },
            OnStepAProccess: async (dato) => {
                const res = await DataModuloFinanzasEstadoCuenta._EliminarEdoCta(IdChild, dato.Id);
                if (!forceUpdate && res.Resultado == -1) forceUpdate = true;
                return res;
            },
            TypeRequest: null,
            OnEndAndCloseProccess: async (datosCorrectos) => {
                if (datosCorrectos.length || forceUpdate) {
                    this.UI_UpdateCardData();
                }
            }
        })
    }

    private OpenModal_EliminarDescuento(descuento: IMovimientoDetalle) {
        ModalThings._GetModalToAProccess({
            Title: this.CARDCOLL_GetUIStringModule("action_eliminar_desc"),
            Width: 300,
            DrawContent: (container, mt) => {
                container.text(this.CARDCOLL_GetUIStringModule("confirma_eliminar_desc"))
            },
            OnAccept: async (mt) => {
                const res = await _SvFinanzasEstadoCuentaEliminarDescuento(descuento.Id, descuento.ParentMovimiento.Id);
                const forceCloser = [-1, -2, -4].includes(res.Resultado);

                if (res.Resultado > 0 || forceCloser) {
                    this.UI_UpdateCardData(false, true);

                    if (forceCloser) {
                        mt.Modal._Ocultar();
                    }
                }

                return res;
            }
        });
    }

    private OpenModal_EliminarMorosidad(morosidad: IMovimientoDetalle) {
        ModalThings._GetModalToAProccess({
            Title: this.CARDCOLL_GetUIStringModule("action_eliminar_moro"),
            Width: 300,
            AccionToHttpMessage: "alumno_edocta_eliminar_morosidad",
            DrawContent: (container, mt) => {
                container.text(this.CARDCOLL_GetUIStringModule("confirma_eliminar_moro"));
            },
            OnAccept: async (mt) => {
                let res = await _SvFinanzasEstadoCuentaEliminarMorosidad(morosidad.Id, morosidad.ParentMovimiento.Id);
                let forceCloser = (res.Resultado == -1);

                if (res.Resultado > 0 || forceCloser) {
                    this.UI_UpdateCardData(false, true);

                    if (forceCloser) {
                        mt.Modal._Ocultar();
                    }
                }
                return res;
            }
        })
    }

    private OpenModal_EliminarPago(pago: IMovimientoDetalle) {
        ModalThings._GetModalToAProccess({
            Title: this.CARDCOLL_GetUIStringModule("action_eliminar_pago_7"),
            Width: 300,
            DrawContent: (container, mt) => {
                container.text(this.CARDCOLL_GetUIStringModule("confirma_eliminar_pago"));
            },
            OnAccept: async (mt) => {
                let res = await _SvFinanzasEstadoCuentaEliminarPagoCompleto(pago.Id, pago.ParentMovimiento.Id);
                let forceCloser = (res.Resultado == -1);

                if (res.Resultado > 0 || forceCloser) {
                    this.UI_UpdateCardData(false, true);

                    if (forceCloser) {
                        mt.Modal._Ocultar();
                    }
                }
                return res;
            }
        })
    }

    // **************************************************************************
    // 
    // **************************************************************************

    /**
     * 
     * @param updateLogo @default false
     * @param showProgressBar @default false
     */
    private UI_UpdateCardData(updateLogo: boolean = false, showProgressBar = false) {
        if (this.cardData == undefined) {
            console.log("DataUndefined");
            return null;
        };
        if (updateLogo) {
            this.cardContentContainerSelection.select<HTMLImage2Component>(".escuela_logo")
                .attr("src", this.GetEscuela().Logo);
            // .text(this.GetEscuela().Nombre)
        }
        if (!this.CARDCOLL_StatusCardExpandido) {
            this.ctrlTabla._UpdateData([]);
            return true;
        }

        return new Promise<boolean>(async (resolve, reject) => {
            if (showProgressBar) {
                this.ctrlProgress.attr("oculto", false);
            }

            // >> Get Data

            const result = await this.Sv_GetMovementsList();

            // >> Update UI

            const cicloesEscolaresList = this.GetCiclosEscolaresList(true);
            this.ctrlSelectCicloEscolar._UpdateList(cicloesEscolaresList);
            let cicloEscCurrent: ICicloEscolar;
            if (!this.ctrlSelectCicloEscolar._dataValueMemberSelected.length) {
                cicloEscCurrent = this.GetCurrentCicloEscolar(cicloesEscolaresList);
                this.ctrlSelectCicloEscolar._valueSelect(cicloEscCurrent?.Id);
            }

            const periodosList = this.GetPeriodosListFromCicloEscolar(this.ctrlSelectCicloEscolar._dataSelected[0], cicloesEscolaresList);
            this.ctrlSelectPeriodo._UpdateList(periodosList)
            if (!this.ctrlSelectPeriodo._dataValueMemberSelected.length) {
                const periodoCurrent = this.GetInitPeriodoFromCicloEsc(cicloEscCurrent, periodosList);
                this.ctrlSelectPeriodo._valueSelect(periodoCurrent?.Id);
            }

            const dataInfo = this.GetCurrentInfoGeneral(true);

            this.UI_UpdateCardInfoStatus(dataInfo);
            this.ctrlTabla._UpdateData(dataInfo.MovimientosTbl_TopPeriodo);

            // this.ctrlTabla.met_UpdateData(this.listaMovimientosFiltrados);// FIXME
            // } else {
            //     // NOTE Se limpia en caso de que falle la consulta con un alumno nuevo,
            //     // no debe conservar los datos del alumno anterior  // TEMPORAL
            //     this.ctrlTabla.met_UpdateData([]);
            // }
            if (showProgressBar) {
                this.ctrlProgress.attr("oculto", true);
            }

            resolve(result);
        })
    }

    // **************************************************************************
    // PERMISOS COSAS
    // **************************************************************************

    private UI_UpdateCardPermissions() {
        const hasPermission = this.HasActionPermission(Entidad.CAccionPermiso.RegistrarPagos);
        this.btnGuardarCard._enabled = hasPermission;

        if (this.CARDCOLL_StatusCardExpandido) {
            this.cardFooterSelection.classed("hide", !hasPermission);
        } else {
            this.cardFooterSelection.classed("hide", true);
        }

        if (hasPermission) {
            this.btnGuardarCard._d3Selection
                .text(this.CARDCOLL_GetUIStringModule("pay"))
                .on("click", () => {
                    // this.OpenProcesoPago_1()// FIXMEE
                    if (this.btnGuardarCard._d3Selection.attr("oculto") == "true") return;
                    this.btnGuardarCard._d3Selection.attr("oculto", true);
                    this.OpenModal_ProcesoPago(() => { this.btnGuardarCard._d3Selection.attr("oculto", null) });
                })
        }
    }

    // **************************************************************************
    // DATA - LOCAL
    // **************************************************************************

    private GetEscuela() {
        return DataModuloEscuela._DiccEscuela.get(this.GetIdEscuela());
    }

    private GetIdEscuela() {
        return this.cardData[0]?.IdKinder;
    }

    private GetCurrentStudent() {
        return this.cardData[0];
    }

    private GetIdCurretStudent() {
        return this.GetCurrentStudent()?.IdChild;
    }

    /**
     * @returns
     * * El ciclo escolar actual (de acuerdo a la fecha actual)
     * * En caso de no existir uno, retorna el primer elemento de la lista
     * */
    private GetCurrentCicloEscolar(ciclosEscolares = this.GetCiclosEscolaresList()): (ICicloEscolar | null) {
        const now = new Date();

        let cicloEsc = ciclosEscolares
            .find(d => new Date(d.FechaInicio) <= now && new Date(d.FechaFin) >= now);

        if (!cicloEsc) {
            cicloEsc = ciclosEscolares[0];
        }

        return cicloEsc;
    }

    /**
     * @returns
     * * El periodo actual (de acuerdo a la fecha actual)
     * * En caso de no existir uno, retorna el primer elemento de la lista
     * */
    private GetInitPeriodoFromCicloEsc(cicloEscolar: ICicloEscolar, periodos = this.GetPeriodosListFromCicloEscolar(cicloEscolar)): (IPeriodoD | null) {
        const now = new Date();
        let periodo = periodos
            .find(d => d.Month == now.getMonth() && d.Year == now.getFullYear());

        if (!periodo && periodos) {
            periodo = periodos[0];
        }

        return periodo;
    }

    private GetSaldoMovimiento(mov: IMovimiento): number {
        let saldo = mov.Saldo;

        if (mov.Detalles?.length) {
            saldo = mov.Detalles[mov.Detalles.length - 1].Saldo;
        }

        return saldo;
    }

    /** Recargo pendiente por pagar */
    private GetAdeudoRecargoFromCargo(mov: IMovimiento): number {
        let totalRecargos = 0;
        if (mov.Detalles && mov.Detalles.length > 0) {
            mov.Detalles
                .filter((d, i) => (
                    d.Categoria == CCategoriaMov.Morosidad
                    && d.IdMetodoPago == 0
                    && (!mov.Detalles[i + 1] || ![CCategoriaMov.PagoParcial, CCategoriaMov.PagoCompleto].includes(mov.Detalles[i + 1].Categoria))
                ))
                .forEach(d => totalRecargos += d.Recargo);
        }
        return totalRecargos;
    }

    private GetPagoValidation(inputPago: number, cargo: IMovimiento) {
        type TFix = ("maxpay" | "minrecargo");
        const saldo = this.GetSaldoMovimiento(cargo);
        let decimales = (String(inputPago).split("."))[1];
        let decimalesValidos = (decimales ? (decimales.length > 0 && decimales.length < 3) : true);
        // let isValid = true;
        let message: string;
        let typeFix: TFix = "maxpay";

        if (inputPago <= 0) {
            message = this.CARDCOLL_GetUIStringModule("notif_pagoinvalido")
                .replace("_VALOR", UIUtilFormat._CurrencyFmt(inputPago));
        }
        else if (inputPago < this.GetAdeudoRecargoFromCargo(cargo)) {
            message = this.CARDCOLL_GetUIStringModule("notif_pagominrecargo");
            typeFix = "minrecargo";
        }
        else if (inputPago > saldo) {
            message = this.CARDCOLL_GetUIStringModule("notif_pagoexcede");
        }
        else if (!decimalesValidos) {
            message = this.CARDCOLL_GetUIStringModule("notif_cantsinvalid");
        }

        return {
            IsValid: (message == null),
            Message: message,
            TypeFix: typeFix
        }
    }

    /**
     * * Los datos cálculados a partir de los movimientos se hace en base al periodo actual
     * * Filtro de movimientos con la FechaAplicacion
     */
    private GetCurrentInfoGeneral(forceUpdate = false): ICurrentData {
        const student = this.GetCurrentStudent();
        const periodo = this.ctrlSelectPeriodo._dataSelected[0];

        if (!this.infoGeneral) {
            forceUpdate = true;
        }
        else if (this.infoGeneral.IdAlumno != student?.IdChild || this.infoGeneral.StrPeriodo != periodo?.Name) {
            forceUpdate = true;
        }

        if (forceUpdate) {
            //** Todos los movimientos menores o iguales al año y mes del periodo filtrado */
            let movimientosTopPeriodoChidos: IMovimiento[] = [];
            //** Solo los movimientos pertenecientes al periodo actual */
            let movimientosPeriodo: IMovimiento[] = [];
            let saldoPeriodo = 0;
            let saldoPendiente = 0;
            let interesesGenerados = 0;
            let tutores: string[] = [];

            if (student) {
                tutores = Array.from(_LOCALDATA_GetTutoresDeAlumno(student.IdChild).values())
                    .map(d => (`${d.Nombre} ${d.ApPaterno}`));
            }

            if (periodo) {
                const datePeriodoValidator = new Date(periodo.Year, periodo.Month, 1);
                const periodoValidator = GetDateValidatorYM(datePeriodoValidator);

                // Filtra movimientos
                this.GetMovimientosList()
                    .forEach(mov => {
                        const newMov = Object.assign({}, mov);
                        const dateA = new Date(newMov.FechaAplicacion);
                        const dateVValidator = GetDateValidatorYM(dateA);
                        const esPeriodoActual = dateA.getFullYear() == periodo.Year && dateA.getMonth() == periodo.Month;

                        if (dateVValidator <= periodoValidator) {
                            let detallesChidos = [];
                            if (newMov.Detalles) {
                                const tienePagoCompleto = newMov.Detalles.some(d => d.Categoria == CCategoriaMov.PagoCompleto);
                                newMov.Detalles.forEach((detalle: IMovimientoDetalle, i) => {
                                    if (!(detalle.Categoria == CCategoriaMov.Morosidad && detalle.Recargo == 0)) {
                                        detalle.ParentMovimiento = newMov;
                                        detallesChidos.push(detalle);
                                        if (!tienePagoCompleto) {
                                            const detallePosterior = newMov.Detalles[i + 1];
                                            const detallePosteriorCancela = (
                                                detallePosterior
                                                && [CCategoriaMov.PagoCompleto, CCategoriaMov.PagoParcial, CCategoriaMov.Descuento].includes(detallePosterior.Categoria)
                                            );
                                            if (!detallePosteriorCancela) {
                                                interesesGenerados += detalle.Recargo; // ACUMULACIÓN DE RECARGOS
                                            }
                                        }
                                    }
                                })
                            }
                            newMov.Detalles = detallesChidos;
                            movimientosTopPeriodoChidos.push(newMov);

                            let childsSinMorosidad = newMov.Detalles ? newMov.Detalles.filter(d => d.Categoria != CCategoriaMov.Morosidad) : [];
                            // let totalPago = 0;
                            // (newMov.Detalles ? newMov.Detalles.filter(d => (d.Categoria == CCategoriaMov.PagoCompleto || d.Categoria == CCategoriaMov.PagoParcial)) : [])
                            //     .forEach(d => {
                            //         totalPago += d.Valor;
                            //     });

                            // NOTE primero lo del boton "Pagar"

                            if (esPeriodoActual) {
                                // this.infoCurrentData.MovimientosPeriodo.push(newMov);
                                movimientosPeriodo.push(newMov);

                                let auxSaldoPeriodo = newMov.Saldo
                                if (childsSinMorosidad.length > 0) {
                                    auxSaldoPeriodo = childsSinMorosidad[childsSinMorosidad.length - 1].Saldo;
                                }
                                saldoPeriodo += auxSaldoPeriodo; // - totalPago);
                            } else {
                                let auxSaldoPendiente = newMov.Saldo;
                                if (childsSinMorosidad.length > 0) {
                                    auxSaldoPendiente = childsSinMorosidad[childsSinMorosidad.length - 1].Saldo;
                                }
                                saldoPendiente += auxSaldoPendiente; // - totalPago);
                            }
                        }
                    })
            }

            this.infoGeneral = {
                IdAlumno: student?.IdChild,
                AlumnoNombre: (student?.NombreCompleto || ""),
                NoCuenta: (this.infoGeneral?.NoCuenta || UIUtilLang._GetUIString("general", "nodisponible")), // NOTE Corregir cuando tenga no.cta.
                Tutoes: tutores,
                StrNivel: (student?.StrEscolaridad || ""),

                StrPeriodo: (periodo?.Name || ""),
                SaldoPeriodo: saldoPeriodo,
                SaldoPendiente: saldoPendiente,
                InteresesGenerados: interesesGenerados,
                TotalPago: (saldoPeriodo + saldoPendiente + interesesGenerados),
                MovimientosPeriodo: movimientosPeriodo
                    .sort((a, b) => Number(new Date(b.FechaAplicacion)) - Number(new Date(a.FechaAplicacion))),

                MovimientosTbl_TopPeriodo: movimientosTopPeriodoChidos
                    .sort((a, b) => Number(new Date(b.FechaAplicacion)) - Number(new Date(a.FechaAplicacion)))
            }
        }

        return this.infoGeneral;
    }

    private GetStrPeriodo(fechaInicio: string, fechaFin: string): string {
        const startMonthName = UIUtilTime._DateFormatStandar(fechaInicio, "MMM yyyy");
        const endMonthName = UIUtilTime._DateFormatStandar(fechaFin, "MMM yyyy");

        if (!endMonthName || startMonthName == endMonthName) {
            return UIUtilStrings._CapitaliceString(startMonthName);
        }

        return `${UIUtilStrings._CapitaliceString(startMonthName)} - ${UIUtilStrings._CapitaliceString(endMonthName)}`;
    }

    private GetUIStrFieldData<TMode extends ("cargo" | "detalle")>(type: TMode, field: keyof IMovimiento, dato: (TMode extends "cargo" ? IMovimiento : IMovimientoDetalle)) {
        const detalle = ((type == "detalle") ? (dato as IMovimientoDetalle) : null);
        const cargo = ((type == "cargo") ? (dato as IMovimiento) : null);
        let strText = "";

        switch (field) {
            case CTableColumns.Periodo:
                if (cargo) {
                    strText = cargo.Periodo;
                }
                break;
            case CTableColumns.Descripcion:
                strText = dato.Cargo; // cargo | descuento
                if (detalle) {
                    switch (detalle.Categoria) {
                        case CCategoriaMov.Morosidad:
                            strText = _L("panelfinanzasedocuenta.tag_recargomoroso");
                            break;
                        case CCategoriaMov.LlegadaAnticipada:
                        case CCategoriaMov.SalidaTarde:
                        case CCategoriaMov.PagoParcial:
                        case CCategoriaMov.PagoCompleto:
                            strText = UIUtilViewData._GetStr_CategoriaMovimiento(detalle.Categoria);
                            break;
                    }
                }
                break;
            case CTableColumns.Valor: // Cargo | descuento
                if (cargo) {
                    strText = UIUtilFormat._CurrencyFmt(cargo.Valor);
                }
                else if (detalle.Categoria == CCategoriaMov.Descuento) {
                    strText = "-" + UIUtilFormat._CurrencyFmt(detalle.Valor);
                }
                break;
            case CTableColumns.Recargo:
                if (detalle && detalle.Recargo > 0) {
                    strText = UIUtilFormat._CurrencyFmt(detalle.Recargo);
                }
                break;
            case CTableColumns.PagoAbono:
                if (detalle && ((detalle.Categoria == CCategoriaMov.PagoCompleto) || (detalle.Categoria == CCategoriaMov.PagoParcial))) {
                    strText = "-" + UIUtilFormat._CurrencyFmt(detalle.Valor);
                }
                break;
            case CTableColumns.Saldo:
                const detalles = (cargo?.Detalles || detalle?.ParentMovimiento.Detalles || []);

                if (cargo && !detalles.length) {
                    strText = UIUtilFormat._CurrencyFmt(cargo.Saldo);
                }
                else if (detalle && detalles[detalles.length - 1] === detalle) {
                    strText = UIUtilFormat._CurrencyFmt(detalle.Saldo);
                }
                break;
            case CTableColumns.FechaVencimiento:
                if (cargo) {
                    strText = UIUtilTime._DateFormatStandar(cargo.FechaVencimiento);
                }
                break;
            case CTableColumns.FechaPago:
                if (detalle && ((detalle.Categoria == CCategoriaMov.PagoCompleto) || (detalle.Categoria == CCategoriaMov.PagoParcial))) {
                    strText = UIUtilTime._DateFormatStandar(detalle.FechaRegistro);
                }
                break;
            case CTableColumns.MetodoPago:
                if (detalle && ((detalle.Categoria == CCategoriaMov.PagoCompleto) || (detalle.Categoria == CCategoriaMov.PagoParcial))) {
                    if (detalle.IdMetodoPago) {
                        if (detalle.IdMetodoPago > 0) { // TEMPORAL condición: hasta que OpenPay sea parte de los datos reales
                            strText = DataModuloMain._GetDataValueFieldByName("FinanzaMetodoPago", detalle.IdMetodoPago, "Nombre");
                            if (!strText) {
                                strText = UIUtilLang._GetUIString("general", "nodisponible");
                            }
                        }
                        else if (detalle.IdMetodoPago == -1) { // TEMPORAL -1 == OpenPlay
                            strText = "OpenPay";
                        }
                    } else {
                        strText = this.CARDCOLL_GetUIStringModule("tag_efectivo");
                    }
                }
                break;
        }
        return strText;
    }

    /**
     * @param type @default "all"
     * * Para realizar el filtro, se extrae el periodo seleccionado en el filtro
     */
    private GetMovimientosList(): IMovimiento[] {
        return (this.movimientosMap.get(this.GetIdCurretStudent()) || []);
    }

    /** Obtiene todos los ciclos escolares donde existen movimientos */
    private GetCiclosEscolaresList(force: boolean = !Boolean(this.ciclosEscolaresList?.length)) {
        if (force) {
            this.ciclosEscolaresList = DataModuloMain._GetReqDataArrayByName("CicloEscolar")
                .filter(dCiclo => {
                    if (dCiclo.IdEscuela == this.GetIdEscuela()) { // && new Date(dCiclo.FechaInicio) < new Date()) {
                        return Boolean(this.GetMovimientosList().find(mov => (mov.CicloEscolar == dCiclo.Id)));
                    }
                    return false;
                })
                .sort((a, b) => Number(new Date(b.FechaInicio)) - Number(new Date(a.FechaInicio)));
        }
        return (this.ciclosEscolaresList || []);
    }

    /** Obtiene todos los meses (dentro del ciclo escolar)  donde existen movimientos */
    private GetPeriodosListFromCicloEscolar(cicloEscolar: ICicloEscolar, ciclosEscolaresList = this.GetCiclosEscolaresList(true)): IPeriodoD[] {
        const ultimoCiclo = ciclosEscolaresList.findIndex(c => c.Id == cicloEscolar.Id) == 0;
        const movimientos = this.GetMovimientosList();
        let periodos: IPeriodoD[] = [];

        if (cicloEscolar && movimientos.length) {
            const maxMovAplicacionDate: IMovimiento = movimientos.reduce((a, b) => (new Date(a.FechaAplicacion) > new Date(b.FechaAplicacion) ? a : b));
            const maxAplicacionDateEval = GetDateValidatorYM(new Date(maxMovAplicacionDate.FechaAplicacion));
            const now = new Date();
            now.setDate(2);

            const getIsValidPeriodoByMovs = (periodoValidator: number) => {
                let itemMenorAPeriodo = movimientos
                    .find(mov => (GetDateValidatorYM(new Date(mov.FechaAplicacion)) <= periodoValidator));

                return (itemMenorAPeriodo && (periodoValidator <= maxAplicacionDateEval));
            }

            periodos = UIUtilViewData._GetPeriodosFromCicloEscolar(cicloEscolar, false)
                .filter(d => {
                    now.setFullYear(d.Year);
                    now.setMonth(d.Month);
                    let procesoPeriodoValidator = GetDateValidatorYM(now);
                    return getIsValidPeriodoByMovs(procesoPeriodoValidator)
                })
                .map(d => ({ ...d, EnCicloEscolar: true }));

            if (ultimoCiclo) {
                const auxPeriodoMap = new Map(periodos.map(d => [GetDateValidatorYM(new Date(d.Year, d.Month, 2)), d]))
                const maxPeriodo = auxPeriodoMap.size ? Array.from(auxPeriodoMap.keys()).reduce((a, b) => a > b ? a : b) : 0;
                periodos = movimientos.reduce((periodos, mov) => {
                    const dt = new Date(mov.FechaAplicacion)
                    const mov_yyyymm = GetDateValidatorYM(dt)
                    if (!auxPeriodoMap.has(mov_yyyymm) && mov_yyyymm > maxPeriodo) {
                        console.warn(mov_yyyymm, mov)
                        auxPeriodoMap.set(mov_yyyymm, {
                            Id: mov_yyyymm,
                            Month: dt.getMonth(),
                            Year: dt.getFullYear(),
                            Name: UIUtilStrings._CapitaliceString(UIUtilTime._DateFormatStandar(dt, "MMM yyyy")),
                            EnCicloEscolar: false,
                        })
                        periodos.push(auxPeriodoMap.get(mov_yyyymm))
                    }
                    return periodos;
                }, periodos).sort((a, b) => new Date(b.Year, b.Month, 2).getTime() - new Date(a.Year, a.Month, 2).getTime())
            }
        }
        // console.log(periodos, "init edocta periodos");
        return periodos;
    }

    private GetDescuentosList() {
        return Array.from(DataModuloFinanzaCargo._DiccFinanzasCargos.values())
            .filter(d => d.Categoria == Entidad.CFinanzaCargoCategoria.Descuento && d.IdEscuela == this.GetIdEscuela())
            .map(d => Object.assign({}, d));
    }

    private GetMetodosPagoList(): IMetodoPago[] {
        return DataModuloMain._GetReqDataArrayByName("FinanzaMetodoPago")
            .filter(d => (d.IdEscuela == this.GetIdEscuela() && d.ProveedorElectronico == Entidad.CProveedorMetodoPagoElectronico.SinProveedor));
    }

    // **************************************************************************
    // DATA - SERVICIOS
    // **************************************************************************

    private async Sv_GetMovementsList() {
        const idAlumno = this.GetIdCurretStudent();
        const res = await _ObtenerListaMovimientosAlumnoV2(idAlumno);
        const movimientosRes: IMovimiento[] = (res.Datos || []);

        if (res.Resultado > 0) {
            this.movimientosMap.set(idAlumno, movimientosRes);

            movimientosRes
                .forEach(mov => {
                    mov.Periodo = this.GetStrPeriodo(mov.FechaInicio, mov.FechaFin);
                    mov.Detalles
                        ?.forEach((detalle: IMovimientoDetalle) => {
                            // detalle.ParentMovimiento = mov;
                            if (detalle.Categoria == CCategoriaMov.Comision) {
                                const prefTag = UIUtilViewData._GetStr_CategoriaMovimiento(detalle.Categoria);
                                const detalleComision = detalle.TipoValorComisionPlantilla == CTipoValor.Porcentaje
                                    ? UIUtilFormat._PercentFmt(detalle.ValorComisionPlantilla * 100)
                                    : UIUtilFormat._CurrencyFmt(detalle.ValorComisionPlantilla)
                                detalle.Cargo = `${prefTag}: ${detalle.Cargo} (${detalleComision})`
                            }
                        })
                });
        } else {
            this.ctrlNotification._Mostrar(this.CARDCOLL_GetUIStringModule("tag_fail_getmovs"), "ADVERTENCIA");
        }
        // console.warn(this.movimientosMap, movimientosRes, "GetListaMovimientos");
        return (res.Resultado > 0);
    }

    private async Sv_RegistrarPagosMultiple(idMetodoPago: number, cargosToPay: { Cargo: IMovimiento, Pago: number }[]) {
        return await DataModuloFinanzasEstadoCuenta._RegistrarPagos(
            idMetodoPago,
            cargosToPay.map(d => {
                return {
                    Id: d.Cargo.Id,
                    Monto: d.Pago,
                    TipoPago: ((d.Pago == this.GetSaldoMovimiento(d.Cargo)) ? CTipoPago.PagoCompleto : CTipoPago.PagoParcial)
                }
            })
        );
    }
}

function EvalGenerarCFDIAction(opciones: Table.ITableMenuDataSelectedOptionConfig<IMovimiento>[], escuela: Entidad.IEscuela, idAlumno: number, onEndProccess: () => void, movimientos?: IMovimiento[]) {
    const idsMovsPagos = GetIdsPagosMovimientos(movimientos || [])
    if ((!movimientos || !!idsMovsPagos.length) && escuela.UsaFactura) {
        opciones.push({
            Label: _L("panelfinanzasedocuenta.action_genera_cdfi"),
            Callback: () => UIUtilViewAlumnoEdoCtaFacturacion._OpenModal_GeneraCFDI(escuela.IdKinder, idAlumno, onEndProccess, idsMovsPagos)
        })
    }
}

/* function HasMovimientosPagos(movimientos: IMovimiento[]) {
    const tipoPago = [CTipoPago.PagoParcial, CTipoPago.PagoCompleto]
    return movimientos.some(mov => {
        return mov.Detalles
            .some(det => tipoPago.includes(det.Categoria as unknown as CTipoPago))
    })
} */

function GetIdsPagosMovimientos(movimientos: IMovimiento[]) {
    const tipoPago = [CTipoPago.PagoParcial, CTipoPago.PagoCompleto]
    const ids: number[] = []
    movimientos.forEach(mov => {
        return mov.Detalles.forEach(det => {
            const esPago = tipoPago.includes(det.Categoria as unknown as CTipoPago)
            if (!esPago) return
            ids.push(det.Id)
        })
    })
    return ids
}