import * as d3 from "d3";
import { group as d3Group } from "d3-array";
import saveAs from "file-saver";
import JSZip from "jszip";
import printJS from "print-js";
import { DataDRequest } from "../../data/DRequest";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import { _DICC_ALUMNO, _LOCALDATA_GetTutoresDeAlumno } from "../../data/modulo/Alumno";
import { _FinanzasObtenerPagosRecibidos } from "../../data/modulo/FinanzasPagosRecibidos";
import { IMapSendCincularItems, _SvNoticiaNueva } from "../../data/modulo/Noticia";
import { DataUtilAlertBot } from "../../data/util/AlertBot";
import { ArrayV2 } from "../../util/ArrayV2";
import { DateV2 } from "../../util/DateV2";
import _L from "../../util/Labels";
import { IConfigGridExcelExport, IGridExtraTableConfig, IGridRenderInfo, VentanaGrid } from "../controlD3/AVentanaGrid";
import { Button } from "../controlD3/Button";
import { ElementWrapper } from "../controlD3/ElementWrapper";
import { ExcelThings } from "../controlD3/ExcelExport";
import { List } from "../controlD3/List";
import { ModalThings } from "../controlD3/ModalThings";
import { NotificacionV2 } from "../controlD3/NotificacionV2";
import { PDFThings } from "../controlD3/PDFBuilder";
import { SelectV2 } from "../controlD3/SelectV2";
import { Table } from "../controlD3/Tabla";
import { HTMLTooltipComponent } from "../controlWC/TooltipComponent";
import { UIUtilFormat } from "../util/Format";
import { UIUtilIconResources } from "../util/IconResourses";
import { UIUtilLang } from "../util/Language";
import { UIUtilTime } from "../util/Time";
import { UIUtilGeneral } from "../util/Util";
import { UIUtilViewEscuelas } from "../utilView/Escuelas";
import { UIUtilViewFinanzasIngresoUtil } from "../utilView/FinanzasIngresoUtil";
import { CFinanzasTabs } from "./AlumnosPanelV2";

import CCategoriaMov = Entidad.CFinanzaCategoriaMovimiento;

type IItemComboYear = UIUtilViewFinanzasIngresoUtil.IItemComboYear
type IItemComboMonth = UIUtilViewFinanzasIngresoUtil.IItemComboMonth
type IFinanzaPagoRecibidoDetalleBase = Entidad.IFinanzaPagoRecibidoDetalle;
type IFinanzaPagoRecibidoBase = Entidad.IFinanzaPagoRecibido;

interface IPagoRecibidoDetalle extends IFinanzaPagoRecibidoDetalleBase {
    IDFolio: string;
    PadreCargo: IPagoRecibidoCargo;
    StrMetodoPago: string;
    /** Valor pagado */
    Valor: number;
    KinderFiltro: number[];
    get ValorCargo(): number;
    get DescuentoTotalCargo(): number;
    get ValorFaltanteCargo(): number;
    get ConceptoCargo(): string;
    get FechaVencimientoCargo(): string;
    get FechaAplicacionCargo(): string;
    get ZonaHoraria(): string;
    get NombreCompletoAlumno(): string;
    get StrEscuela(): string;
}

interface IPagoRecibidoCargo extends IFinanzaPagoRecibidoBase {
    Detalles: IPagoRecibidoDetalle[];
    // NombreCompletoAlumno: string;
    // DescuentoTotal: number;
    // ValorFaltante: number;
    // StrEscuela: string;
}

export class UIVentanaFinanzasPagosRecibidosV2 extends VentanaGrid<IPagoRecibidoDetalle> {
    private comboYear: SelectV2<IItemComboYear, "Id", "multiselect">;
    private comboMonth: SelectV2<IItemComboMonth, "Id", "multiselect">;
    private dataPagosBySchool: Map<number, IPagoRecibidoDetalle[]>;

    private forceDownloadData: boolean;

    constructor(content: d3.Selection<HTMLDivElement, any, HTMLElement, any>, modulo: Entidad.CModulo) {
        super(content, modulo);
    }

    protected GridInitTable() {
        this.dataPagosBySchool = new Map();

        super.GridInitTable();
        this.AjustaDrawGrid();
        this.forceDownloadData = true;
    }

    private AjustaDrawGrid() {
        // Area combos-filtros
        let filterCont = this.ctrlTabla._Control.select(".filter_container");
        let searchwrapper = filterCont.select(".search-wrapper");

        let areaCombos = filterCont.append("div")
            .attr("class", "search-wrapper " + UIUtilGeneral.FBoxOrientation.Horizontal)
            // .style("height", "30px")
            .style("column-gap", "40px")
            .style("padding", "0 20px")
            .style("box-sizing", "border-box")

        this.comboYear = (() => {
            const select = new SelectV2<IItemComboYear, "Id", "multiselect">({
                Parent: areaCombos,
                Type: "multiselect",
                ValueMember: "Id",
                DisplayMember: "Name",
                ListWidth: "200px",
                Data: () => UIUtilViewFinanzasIngresoUtil._ObtenerAniosDisponibles(this.kinders),
                OnChange: () => {
                    const monthsA = [...this.comboMonth._dataitems.map(d => d.Id)]
                    this.comboMonth._RefreshList()
                    if (this.comboMonth._dataSelected.length == 0) {
                        this.comboMonth._SelectAll(true)
                    } else {
                        const monthsB = [...this.comboMonth._dataitems.map(d => d.Id)]
                        const monthsSelect: string[] = []
                        for (const mB of monthsB) {
                            if (monthsA.includes(mB)) {
                                continue
                            }
                            monthsSelect.push(mB)
                        }
                        this.comboMonth._valueSelect(this.comboMonth._dataValueMemberSelected.concat(...monthsSelect))
                    }
                    this.forceDownloadData = false
                    this.GridUpdateData()
                }
            })
            areaCombos.append(() => {
                return ElementWrapper._WrapperToSelectControl(select, _L("time.anio") + ":",
                    {
                        Borders: ["left", "right"]
                    })
                    .style("height", "35px")
                    .node();
            })
            return select
        })()
        this.comboMonth = (() => {
            const select = new SelectV2<IItemComboMonth, "Id", "multiselect">({
                Type: "multiselect",
                Parent: areaCombos,
                ValueMember: "Id",
                DisplayMember: "Name",
                ListWidth: "200px",
                Data: () => {
                    const idYears = this.comboYear._dataValueMemberSelected
                    return UIUtilViewFinanzasIngresoUtil._ObtenerMesesDisponibles(idYears)
                },
                OnChange: () => {
                    this.forceDownloadData = false
                    this.GridUpdateData()
                }
            })
            areaCombos.append(() => {
                return ElementWrapper._WrapperToSelectControl(select, _L("time.mes") + ":", {
                    Borders: ["left", "right"]
                }).node();
            })
            return select
        })()

        searchwrapper.raise()

        this.ctrlTabla._Control.select(".space_pie")
            .classed("hide", false)
            .style("display", "flex")
            .style("justify-content", "end")
            .text(_L("finanzaspagosrecibidos.tag_totalpagado") + ": " + UIUtilFormat._CurrencyFmt(0));

        // INIT VALUES
        this.comboYear._SelectAll(true)
        this.comboMonth._RefreshList()
        this.comboMonth._SelectAll(true)
        this.GridUpdateData()
    }

    private UpdateCurrentMonthTotal(data: Table.TDataItemTableAux<IPagoRecibidoDetalle>[]) {
        const total = this.CalcularTotal_Tabla(data);
        this.ctrlTabla._Control
            .select(".space_pie")
            .text(_L("finanzaspagosrecibidos.tag_totalpagado") + ": " + UIUtilFormat._CurrencyFmt(total));
    }

    public _OnServiceEvent(requestID: DataModuloMain.TipoRequestMonitorId) {
        super._OnServiceEvent(requestID);
        if (requestID == Entidad.CTipoRequest.Alumno) {
            // this.UpdateGridData(true);
            this.forceDownloadData = false;
            this.GridUpdateData();
        }
    }

    // ***********************************************************************
    // Modal Form
    // ***********************************************************************


    // ***********************************************************************
    // Table config
    // ***********************************************************************

    protected GRID_GetMenuTopGrid(): Table.ITableMenuTopDefaultOptionConfig[] {
        return [{
            Label: this.VB_GetUIStringModule("generarresumen_opc"),
            Callback: () => this.OpenModal_GenerarResumenPDF(this.dataGrid),
            GetDetails: () => {
                let enable = this.dataGrid.length > 0; //this.ctrlTabla ? (this.ctrlTabla.prop_data.length > 0) : false;
                let description = enable ? "" : this.VB_GetUIStringModule("generarresumen_err1");
                return {
                    Enabled: enable,
                    Description: description
                }
            }
        }];
    }
    protected GRID_GetSelectionDataMenuV2(menuLocation: "row" | "top-selected", dataGridSelected: IPagoRecibidoDetalle[]): Table.ITableMenuDataSelectedOptionConfig<IPagoRecibidoDetalle>[] {
        return [{
            Label: this.VB_GetUIStringModule("generarresumen_opc"),
            Callback: (datos) => this.OpenModal_GenerarResumenPDF(datos),
        }];
    }
    protected GRID_GetDataRequestID(): DataModuloMain.TipoRequestMonitorId {
        return null;
    }

    protected GRID_GetTableConfigBase(): IGridRenderInfo<IPagoRecibidoDetalle> {
        return {
            IdTabla: "FinanzasIngresos-PagosRecibidos",
            Title: "",
            DefaultSort: "NombreCompletoAlumno",
            ItemGrouperField: "IDFolio",
            IdData: "Id",
            MinWidth: 1700,
            Columns: [
                { Field: "IDFolio", Label: "Folio", MinWidth: "60px", Width: "5%" },
                { Field: "NombreCompletoAlumno", Label: "Alumn@", MinWidth: "100px", Width: "13%" },
                { Field: "ConceptoCargo", Label: "Concepto", MinWidth: "80px", Width: "13%" },
                { Field: "ValorCargo", Label: "Total de cargo", MinWidth: "80px", Width: "8%" },
                { Field: "DescuentoTotalCargo", LabelLangKey: "d_field_descuentototal", Label: "Total de descuento", MinWidth: "95px", Width: "8%" },
                { Field: "ValorFaltanteCargo", LabelLangKey: "d_field_valorfaltante", Label: "Total por cobrar", MinWidth: "80px", Width: "8%" },
                { Field: "Valor", Label: "Pagado", MinWidth: "80px", Width: "8%", OrderField: "Valor", OrderTypeParse: Number },
                { Field: "StrMetodoPago", Label: "Método pago", MinWidth: "100px", Width: "9%", OrderTypeParse: String },
                { Field: "FolioPago", Label: "Folio pago", MinWidth: "80px", Width: "9%", OrderTypeParse: String },
                { Field: "FechaRegistro", Label: "Fecha pago", MinWidth: "80px", Width: "9%", OrderTypeParse: Date },
                { Field: "FechaAplicacionCargo", LabelLangKey: "d_field_fechaaplicacion", Label: "Fecha aplicación", MinWidth: "90px", Width: "8%", OrderTypeParse: Date },
                { Field: "FechaVencimientoCargo", LabelLangKey: "d_field_fechavencimiento", Label: "Fecha vencimiento", MinWidth: "100px", Width: "8%", OrderTypeParse: Date },
                { Field: "StrEscuela", Label: "Escuela", MinWidth: "80px", Width: "9%" },
            ]
        }
    }

    protected GRID_GetTableConfigAdvanced(): IGridExtraTableConfig<IPagoRecibidoDetalle> {
        return {
            OnEndUpdateDataInView: (currentDataInTable, dataChecked) => {
                let pagos: Table.TDataItemTableAux<IPagoRecibidoDetalle>[] = [];
                currentDataInTable.forEach(dP => {
                    pagos.push(dP);
                });
                dataChecked.forEach(dP => {
                    pagos.push(dP);
                });
                this.UpdateCurrentMonthTotal(pagos);
            },
            EvaluatorAndSubLevelsBuild: <Table.IStepEvaluator<IPagoRecibidoDetalle, never, never>>{
                OnStepCellTable: (container, pago, field: (keyof IPagoRecibidoDetalle)) => {
                    switch (field) {
                        case "NombreCompletoAlumno":
                            UIUtilGeneral._ElementAdd_LinkToGoToPanel(container, "alumnos/alumnos/panel", pago.PadreCargo.IdAlumno);
                            break;
                        case "ValorCargo":
                        case "Valor":
                        case "ValorFaltanteCargo":
                            container.text(UIUtilFormat._CurrencyFmt(pago[field]));
                            break;
                        case "DescuentoTotalCargo":
                            container.text(UIUtilFormat._CurrencyFmt(pago[field] || 0));
                            UIUtilGeneral._ElementAdd_LinkToGoToPanel(container, "alumnos/alumnos/panel", pago.PadreCargo.IdAlumno, null, CFinanzasTabs.EdoCuenta);
                            break;
                        case "FechaRegistro": // Pago
                            container.text(UIUtilTime._DateFormatStandarFixTimeZone(pago[field], pago.ZonaHoraria, "dd/mm/yyyy h24:mm"));
                            break;
                        case "FechaAplicacionCargo":
                        case "FechaVencimientoCargo":
                            container.text(UIUtilTime._DateFormatStandarFixTimeZone(pago[field], pago.ZonaHoraria));
                            break;
                    }
                },
            }
        }
    }

    protected GRID_GetFilters(): Array<Table.IParametroFiltro<IPagoRecibidoDetalle>> {
        return [
            { Field: "IDFolio", Label: "ID" },
            { Field: "NombreCompletoAlumno", Label: "Nombre" },
            {
                Field: "ConceptoCargo", Label: "Concepto", Type: "select",
                Options: () => DataModuloMain._GetReqDataArrayByName("FinanzaCargo")
                    .map(d => ({ Id: d.Nombre, Name: d.Nombre }))
                    .concat(this.ctrlTabla._data.map(d => ({ Id: d.PadreCargo.Concepto, Name: d.PadreCargo.Concepto })))
                    .filter(d => (!!d.Name))
                    .sort((a, b) => d3.ascending(a.Name.toLowerCase(), b.Name.toLowerCase())),
            },
            { Field: "ValorCargo", Label: "Total de cargo $", Type: "number" },
            { Field: "DescuentoTotalCargo", LabelLangKey: "d_field_descuentototal", Label: "Total de descuento $", Type: "number" },
            { Field: "ValorFaltanteCargo", LabelLangKey: "d_field_valorfaltante", Label: "Total por cobrar $", Type: "number" },
            { Field: "Valor", Label: "Pagado $", Type: "number", OnGetValueToMatch: (dato: IPagoRecibidoDetalle) => [dato.Valor] },
            {
                Field: "StrMetodoPago", Label: "Método de pago", Type: "select",
                Options: () => DataModuloMain._GetReqDataArrayByName("FinanzaMetodoPago")
                    .map(d => ({ Id: d.Nombre, Name: d.Nombre }))
                    .sort((a, b) => d3.ascending(a.Name.toLowerCase(), b.Name.toLowerCase())),
            },
            { Field: "FolioPago", Label: "Folio de pago" },
            {
                Field: "FechaRegistro", Label: "Fecha pago", Type: "date-time", OnGetValueToMatch: (d) => ([
                    new DateV2(d.FechaRegistro)._SetTimeZoneByIdSchool(d.KinderFiltro[0])
                ])
            },
            { Field: "FechaAplicacionCargo", LabelLangKey: "d_field_fechaaplicacion", Label: "Fecha aplicación", Type: "date" },
            { Field: "FechaVencimientoCargo", LabelLangKey: "d_field_fechavencimiento", Label: "Fecha vencimiento", Type: "date" }
        ]
    }

    // protected GridRefreshKinderList(): void {
    //     super.GridRefreshKinderList();

    // const aniosDisponibles = this.GetAniosDisponibles();
    /* if (aniosDisponibles.length > 0) {
        // this.comboYear._UpdateList(aniosDisponibles);

        let urlParamsFiltroFecha = UIWindowManager._GetCurrentParams().get("fdt");
        if (urlParamsFiltroFecha) {
            let filtroParams = urlParamsFiltroFecha.split("-");
            let anioInicial = Number(filtroParams[0]);
            let mesInicial = Number(filtroParams[1]) - 1;
            this.comboYear._valueSelect(anioInicial);
            this.UpdateOrResetMonthsAvailables(anioInicial);
            this.comboMonth._valueSelect(mesInicial);
        }
        else {
            let maxItemYear = aniosDisponibles.reduce((a, b) => (a.Id > b.Id ? a : b));
            this.comboYear._valueSelect(maxItemYear.Id);

            if (maxItemYear) {
                this.UpdateOrResetMonthsAvailables(maxItemYear.Id);
            }
        }
    } */
    // }

    protected GridOnSyncData() {
        this.forceDownloadData = true;
        return super.GridOnSyncData();
    }

    protected async GridGetData() {
        // console.warn("GridGetData"); // FIXME Al crear la ventana, se repiten dos veces las peticiones. Origen > VentanaGrid, SelectControlAño
        if (this.dataPagosBySchool.size == 0 || this.forceDownloadData) {
            const res = await this.GetPagosRecibidosList();
            let hasError: boolean
            res.forEach((d) => {
                if (!hasError && d.Resultado <= 0) {
                    hasError = true
                }
                if (d.Resultado > 0) {
                    this.dataPagosBySchool.set(d.Resultado, d.Datos)
                }
            })
            if (hasError) {
                this.notificacion._Mostrar(_L("general.notif_fail_infoupdate"), "ADVERTENCIA")
            }
        }
        const monthFilter = this.comboMonth._dataSelected
        const dataFilter: IPagoRecibidoDetalle[] = []

        this.dataPagosBySchool.forEach((pagosEscuela, idEscuela) => {
            pagosEscuela.forEach(p => {
                const dt = new DateV2(p.FechaRegistro)._SetTimeZoneByIdSchool(idEscuela)
                const isValid = monthFilter.some(f => (dt.getFullYear() == f.Year && dt.getMonth() == f.Month))
                if (isValid) {
                    dataFilter.push(p)
                }
            })
        })
        setTimeout(() => {
            this.ctrlTabla._MenuTopDefault._Refresh();
        });
        return dataFilter;
    }

    // ***********************************************************************
    // Get data
    // ***********************************************************************

    private OpenModal_GenerarResumenPDF(datos: IPagoRecibidoDetalle[]) {
        type IAlumno = Entidad.IAlumno;
        type ItemAlumno = Pick<IAlumno, "IdChild" | "NombreCompleto"> & {
            Selected: boolean;
            IdEscuela: number;
            PDF: jspdf.jsPDF;
            PDFStatus: number;
            PDFFileName: string;
            AlumnoDescripcion: string;
        };
        const manySchools = d3Group(datos, (d) => d.KinderFiltro[0]).size > 1;
        const dataPorAlumno = d3Group(datos, (d) => d.PadreCargo.IdAlumno);
        const dataAlumnosList = new ArrayV2<ItemAlumno>();
        dataPorAlumno.forEach((pagos, idAlumno) => {
            const alumno = DataModuloMain._GetItemDataByName("Alumno", idAlumno);
            let strDescripcion = "";
            if (alumno.Matricula) {
                strDescripcion += UIUtilLang._GetUIString("alumnos", "d_field_matricula") + ": " + alumno.Matricula + "\n";
            }
            if (manySchools) {
                strDescripcion += this.VB_GetUIStringModule("d_field_strescuela") + ": " + alumno.NombreKinder;
            }
            dataAlumnosList.push({
                IdChild: idAlumno,
                IdEscuela: pagos[0].KinderFiltro[0],
                NombreCompleto: pagos[0].NombreCompletoAlumno,
                Selected: false,
                PDF: null,
                PDFStatus: 0,
                PDFFileName: this.VB_GetUIStringModule("resumen_title") + " - " + pagos[0].NombreCompletoAlumno + ".pdf",
                AlumnoDescripcion: strDescripcion,
            })
        })
        ModalThings._GetModalToAProccess({
            Title: this.VB_GetUIStringModule("generarresumen_title"),
            Width: 1000,
            Height: "800px",
            DrawContent: (content, mt) => {
                content.classed(UIUtilGeneral.FBoxOrientation.Horizontal, true);
                let divPadre = content.append("div");
                divPadre.classed("divPadreLateral", true);
                const ctrlList = new List<ItemAlumno>()
                    ._SetParent(divPadre.node())
                    ._SetCreateTemplate((itemContainer, d) => {
                        let lbl = itemContainer
                            .classed(UIUtilGeneral.FBoxOrientation.Horizontal, true)
                            .classed(UIUtilGeneral.FBoxAlign.SpacebetweenCenter, true)
                            .append("label")
                        lbl.append("span")
                            .attr("id", "name");
                        lbl.append("pre")
                            .attr("id", "description")
                    })
                    ._SetUpdateItem((itemContainer, d) => {
                        itemContainer.select("#name")
                            .text(d.NombreCompleto);
                        itemContainer.select("#description")
                            .text(d.AlumnoDescripcion);
                        itemContainer.on("click", async (d) => {
                            if (!d.Selected) {
                                dataAlumnosList.forEach(d => d.Selected = false);
                                d.Selected = true;
                                if (!d.PDF || d.PDFStatus != 1) {
                                    await fnAlumnoPDFBuild(d);
                                }
                                else {
                                    ctrlList._RefreshList();
                                    PDFThings._PDFViewer(d.PDF, pdfViewerContainer.node())
                                }
                            }
                        })

                        if (d.Selected) {
                            if (!itemContainer.select(".opciones").node()) {
                                itemContainer.style("background-color", "var(--color_primary2)");

                                const opcionesContainer = itemContainer.append("div")
                                    .attr("class", "opciones")
                                    .classed(UIUtilGeneral.FBoxOrientation.Horizontal, true)
                                    .classed(UIUtilGeneral.FBoxAlign.EndCenter, true)
                                    .style("min-width", "min-width")
                                    .style("gap", "var(--padding1)");

                                const tooltipElement = itemContainer.append<HTMLTooltipComponent>("wc-tooltip").node()
                                if (d.PDFStatus == -2) {
                                    tooltipElement._SetObservedElementsAdvanced({
                                        Target: opcionesContainer.append("img")
                                            .attr("class", "btn_round")
                                            .attr("src", UIUtilIconResources.CGeneral.Sync)
                                            .attr("draggable", false)
                                            .on("click", async () => {
                                                await fnAlumnoPDFBuild(d);
                                            })
                                            .node(),
                                        Text: this.VB_GetUIStringModule("rebuildlogopdf"),
                                    })
                                }
                                tooltipElement._SetObservedElementsAdvanced(
                                    {
                                        Target: opcionesContainer.append("img")
                                            .attr("class", "btn_round")
                                            .attr("src", UIUtilIconResources.CGeneral.Print)
                                            .attr("draggable", false)
                                            .on("click", () => {
                                                let uri = d.PDF.output("bloburi");
                                                printJS({
                                                    printable: uri,
                                                    type: "pdf",
                                                    showModal: true
                                                });
                                            })
                                            .node(),
                                        Text: UIUtilLang._GetUIString("general", "print"),
                                    },
                                    {
                                        Target: opcionesContainer.append("img")
                                            .attr("class", "btn_round")
                                            .attr("src", UIUtilIconResources.CGeneral.Download)
                                            .attr("draggable", false)
                                            .on("click", () => d.PDF.save(d.PDFFileName))
                                            .node(),
                                        Text: UIUtilLang._GetUIString("general", "descargar"),
                                    },
                                    {
                                        Text: this.VB_GetUIStringModule("enviartutor"),
                                        Target: opcionesContainer.append("img")
                                            .attr("class", "btn_round")
                                            .attr("src", UIUtilIconResources.CGeneral.Circular2)
                                            .attr("draggable", false)
                                            .on("click", async () => {
                                                ModalThings._GetConfirmacionModal({
                                                    Title: this.VB_GetUIStringModule("title_confirm_envio"),
                                                    Message: this.VB_GetUIStringModule("confirm_envio_circular"),
                                                    OnAccept: async (mt) => {
                                                        mt.Progress.attr("oculto", false);
                                                        mt.Modal._DeshabilitarBtns();
                                                        let blob = d.PDF.output("blob")
                                                        let res = await _SvNoticiaNueva("ByAlumnos", <IMapSendCincularItems["ByAlumnos"]>{
                                                            IdEscuela: d.IdEscuela,
                                                            Titulo: this.VB_GetUIStringModule("resumen_pago"),
                                                            Mensaje: this.VB_GetUIStringModule("resumen_pago") + " - " + d.NombreCompleto,
                                                            Archivo: new File([blob], d.PDFFileName, { "type": blob.type }),
                                                            Programado: null,
                                                            ReqAutorizacion: false,
                                                            IdAlumnos: [d.IdChild]
                                                        });
                                                        mt.Progress.attr("oculto", true);
                                                        mt.Modal._HabilitarBtns();
                                                        if (res.Resultado > 0) {
                                                            NotificacionV2._Mostrar(this.VB_GetUIStringModule("success_process"), "INFO");
                                                        }
                                                        else {
                                                            NotificacionV2._Mostrar(this.VB_GetUIStringModule("fail_process"), "ADVERTENCIA");
                                                        }
                                                    },
                                                })
                                            })
                                            .node(),
                                    },
                                );
                            }
                        }
                        else {
                            itemContainer.style("background-color", null)
                            itemContainer.select(".opciones").remove();
                            itemContainer.select("wc-tooltip").remove();
                        }

                    })
                    ._SetItems(dataAlumnosList);

                let divButtons = divPadre.append("div");
                divButtons.classed("divButtons", true);

                new Button(divButtons.node(), UIUtilLang._GetUIString("general", "descargartodo"))
                    ._d3Selection
                    .on("click", () => {
                        fnBuildAndDownloadAllPDF();
                    });

                new Button(divButtons.node(), this.VB_GetUIStringModule("enviartutores"))
                    ._d3Selection
                    .on("click", async () => {
                        mt.Progress.attr("oculto", false);
                        mt.Modal._DeshabilitarBtns();
                        let arrErr: ItemAlumno[] = [];
                        await dataAlumnosList._ForEachAwait(async (d) => {
                            await fnAlumnoPDFBuild(d, false);
                            if (!d.PDF || d.PDFStatus != 1) {
                                arrErr.push(d);
                                return;
                            }
                        });
                        if (arrErr.length > 0) {
                            NotificacionV2._Mostrar(this.VB_GetUIStringModule("pdf_fail_construct"), "ADVERTENCIA");
                            mt.Progress.attr("oculto", true);
                            mt.Modal._HabilitarBtns();
                            return;
                        }
                        ModalThings._OpenModalToProccessServiceByServiceFromAArrayBasic({
                            Title: this.VB_GetUIStringModule("title_confirm_envio"),
                            Message: this.VB_GetUIStringModule("confirm_envio_circulares"),
                            DataToProccess: dataAlumnosList,
                            Width: 400,
                            OnStepAProccess: async (d) => {
                                let blob = d.PDF.output("blob");
                                return _SvNoticiaNueva("ByAlumnos", <IMapSendCincularItems["ByAlumnos"]>{
                                    IdEscuela: d.IdEscuela,
                                    Titulo: this.VB_GetUIStringModule("resumen_pago"),
                                    Mensaje: this.VB_GetUIStringModule("resumen_pago") + " - " + d.NombreCompleto,
                                    Archivo: new File([blob], d.PDFFileName, { "type": blob.type }),
                                    Programado: null,
                                    ReqAutorizacion: false,
                                    IdAlumnos: [d.IdChild]
                                })
                            },
                            OnError_GetItemDataTag: (d) => {
                                return `${d.NombreCompleto}`;
                            },
                            TypeRequest: null,
                        })
                        mt.Progress.attr("oculto", true);
                        mt.Modal._HabilitarBtns();
                    });

                const pdfViewerContainer = content.append("div")
                    .classed("pdfViewerContainer", true)

                const fnAlumnoPDFBuild = async (d: ItemAlumno, showProgres = true) => {
                    if (!d.PDF || d.PDFStatus != 1) {
                        if (showProgres) mt.Progress.attr("oculto", false);
                        let [pdf, result] = await GetPDFBuilded(d.IdChild, d.NombreCompleto, d.IdEscuela, d.PDFFileName);
                        d.PDF = pdf;
                        d.PDFStatus = result;
                        if (showProgres) mt.Progress.attr("oculto", true);
                        if (d.Selected) {
                            ctrlList._RefreshList();
                            PDFThings._PDFViewer(d.PDF, pdfViewerContainer.node());
                        }
                    }
                }
                const fnBuildAndDownloadAllPDF = async () => {
                    const resultFolderName = this.VB_GetUIStringModule("resumen_title") + " " + UIUtilTime._GetValidatorDateYMD(new Date());
                    const zip = new JSZip();
                    const resumenFolder = zip.folder(resultFolderName);
                    mt.Progress.attr("oculto", false);
                    await dataAlumnosList
                        ._ForEachAwait(async (d) => {
                            await fnAlumnoPDFBuild(d, false);
                            let blob = d.PDF.output("blob")
                            resumenFolder.file(d.PDFFileName, blob);
                        })
                    zip.generateAsync({ type: "blob" })
                        .then(function (content) {
                            saveAs(content, resultFolderName);
                        })
                        .catch((e) => {
                            DataUtilAlertBot._SendError(e, "Reporte pagos recib");
                            this.notificacion._Mostrar(UIUtilLang._GetUIString("general", "notif_fail"), "ADVERTENCIA");
                        })
                        .finally(() => {
                            mt.Progress.attr("oculto", true);
                        });
                }

                // Se inicializa con pagos de un solo alumno
                setTimeout(async () => {
                    if (dataPorAlumno.size == 1) {
                        let alumno = dataAlumnosList[0];
                        alumno.Selected = true;
                        fnAlumnoPDFBuild(alumno);
                    }
                });
            }
        });
        const GetPDFBuilded = (idAlumno: number, nombreAlumno: string, idEscuela: number, alumnoPDFFileTitle: string) => {
            const escuela = DataModuloMain._GetItemDataByName("Escuela", idEscuela);
            const tutores = Array.from(_LOCALDATA_GetTutoresDeAlumno(idAlumno).values())
                .map(d => d.NombreCompleto)
                .join(", ");
            const dtCurrent = new DateV2()
                ._SetTimeZone(escuela.ZonaHoraria);
            const pagosAlumno = dataPorAlumno.get(idAlumno);
            let totalPago = 0;
            pagosAlumno.forEach(d => {
                totalPago += d.Valor;
            })

            return PDFThings._PDFCreate({
                Title: this.VB_GetUIStringModule("resumen_title"),
                Logo: escuela.Logo,
                IncDescription: UIUtilLang._GetUIString("general", "fecha") + ": " + UIUtilTime._DateFormatStandar(dtCurrent) + "\n\n"
                    + escuela.Nombre + "\n"
                    + UIUtilViewEscuelas._GetDirectionStr(escuela) + "\n"
                    + escuela.Telefono + "\n"
                    + escuela.Correo + "\n\n"
                    + this.VB_GetUIStringModule("resumen_alumno") + ": " + nombreAlumno + "\n"
                    + this.VB_GetUIStringModule("resumen_tutor") + ": " + tutores,
                DataTable: pagosAlumno
                    .map(d => ({
                        [this.VB_GetUIStringModule("d_field_idfolio")]: d.IDFolio,
                        [this.VB_GetUIStringModule("d_field_conceptocargo")]: d.ConceptoCargo,
                        [this.VB_GetUIStringModule("d_field_valorcargo")]: UIUtilFormat._CurrencyFmt(d.ValorCargo),
                        [this.VB_GetUIStringModule("d_field_descuentototal")]: UIUtilFormat._CurrencyFmt(d.DescuentoTotalCargo),
                        [this.VB_GetUIStringModule("d_field_strmetodopago")]: d.StrMetodoPago,
                        [this.VB_GetUIStringModule("d_field_fecharegistro")]: UIUtilTime._DateFormatStandarFixTimeZone(d.FechaRegistro, escuela.ZonaHoraria, "dd/mm/yyyy h24:mm"),
                        [this.VB_GetUIStringModule("d_field_valor")]: UIUtilFormat._CurrencyFmt(d.Valor),
                    })),
                Resumen: {
                    [_L("finanzaspagosrecibidos.tag_totalpagado")]: UIUtilFormat._CurrencyFmt(totalPago)
                },
                MetadataTitle: alumnoPDFFileTitle,
            })
        }
    }

    private CalcularTotal_Tabla(datos: Table.TDataItemTableAux<IPagoRecibidoDetalle, any>[]): number {
        return datos.reduce<number>((total, itemTablePago) => {
            total += itemTablePago.data.Valor;
            return total;
        }, 0)
    }

    private CalcularTotal_Data(datos: Pick<IPagoRecibidoDetalle, "Valor">[]): number {
        return datos.reduce<number>((total, d) => (total + d.Valor), 0)
    }

    // private CalcularTotal<T, Field extends TOnlyKeysTypeof<T, number>>(datos: T[], field: Field): number {
    //     return datos.reduce<number>((total, item) => {
    //         total += (item[field] as number);
    //         return total;
    //     }, 0)
    // }

    // ***********************************************************************
    // Data final things
    // ***********************************************************************

    private async GetPagosRecibidosList(): Promise<DataDRequest.IRequestResponseA<IPagoRecibidoDetalle[]>[]> {
        // const escuelas = [...DataModuloEscuela._DiccEscuela.values()]
        const result: DataDRequest.IRequestResponseA<IPagoRecibidoDetalle[]>[] = []
        for (let { IdKinder, Nombre: NombreEscuela, ZonaHoraria } of this.kinders) {
            const pagosrecibidos: IPagoRecibidoDetalle[] = []
            const res = await _FinanzasObtenerPagosRecibidos(IdKinder)
            if (res.Resultado <= 0) {
                result.push(res as any)
                continue
            }
            for (const cargoPago of res.Datos) {
                let alumno: Entidad.IAlumno = _DICC_ALUMNO.get(cargoPago.IdAlumno)

                if (!alumno) {
                    // console.warn("-d", "Student no found", IdKinder, cargoPago)
                    continue
                }
                // const escuela = DataModuloEscuela._DiccEscuela.get(alumno.IdKinder)
                let totalCobrar = 0 // cargo + recargo + comisión
                let totalRestar = 0 // totalDescuento, totalCobrado
                let totalRecargo = 0
                let totalCobrado = 0 // pago_parcial + pago_completo
                let totalDescuento = 0
                let totalComision = 0
                let totalFaltante = 0

                const refCargoPago: IPagoRecibidoCargo = <IPagoRecibidoCargo>{ // REMOVER?
                    ...cargoPago,
                    // NombreCompletoAlumno: alumno.NombreCompleto,
                    // StrEscuela: NombreEscuela,
                    Detalles: [],
                }

                for (const det of cargoPago.Detalles) {
                    switch (det.Categoria) {
                        case CCategoriaMov.PagoCompleto:
                        case CCategoriaMov.PagoParcial:
                            totalCobrado += det.Valor;
                            // detalleResult["PadreCargo"] = <any>cargoPago;
                            const detalleResult: IPagoRecibidoDetalle = UIUtilGeneral._ObjectAddGetters(<IPagoRecibidoDetalle>{
                                ...det,
                                IDFolio: cargoPago.Id.toString().padStart(6, "0"),
                                StrMetodoPago: DataModuloMain._GetReqDataMapByName("FinanzaMetodoPago").get(det.IdMetodoPago)?.Nombre || UIUtilLang._GetUIString("general", "nodisponible"),
                                KinderFiltro: [IdKinder], // NOTE (1) Los pagos van a "cambiar de escuela" si se cambia al alumno. (2) Los pagos no se verán cuando el alumno no esté
                                PadreCargo: refCargoPago,
                            }, {
                                ValorCargo: () => totalCobrar,
                                ConceptoCargo: () => cargoPago.Concepto,
                                ValorFaltanteCargo: () => totalFaltante, // refCargoPago.ValorFaltante,
                                DescuentoTotalCargo: () => totalDescuento, // refCargoPago.DescuentoTotal,
                                FechaAplicacionCargo: () => cargoPago.FechaAplicacion,
                                FechaVencimientoCargo: () => cargoPago.FechaVencimiento,
                                StrEscuela: () => NombreEscuela,
                                NombreCompletoAlumno: () => alumno.NombreCompleto,
                                ZonaHoraria: () => ZonaHoraria,
                            })
                            refCargoPago.Detalles.push(detalleResult);
                            pagosrecibidos.push(detalleResult);
                            break;
                        case CCategoriaMov.Descuento:
                            // refCargoPago.DescuentoTotal += det.Valor;
                            totalDescuento += det.Valor;
                            break;
                        case CCategoriaMov.Morosidad:
                            totalRecargo += det.Recargo;
                            break;
                        case CCategoriaMov.Comision:
                            totalComision += det.Valor // DOTEST
                            break;
                    }
                }
                const decimalNumbersLength = 2; // todos los numeros deberian tener <= 2 decimales;
                //se eleva el 10 a la cantidad de números decimales que hay
                //para tener un numero con la misma cantidad de ceros que decimales
                const fixNum = 10 ** decimalNumbersLength;
                // se toman los números después del punto
                totalCobrar = ((cargoPago.Valor * fixNum) + (totalRecargo * fixNum) + (totalComision * fixNum)) / fixNum
                totalRestar = ((totalDescuento * fixNum) + (totalCobrado * fixNum)) / fixNum
                totalFaltante = ((totalCobrar * fixNum) - (totalRestar * fixNum)) / fixNum;
            }
            result.push({
                Resultado: IdKinder,
                Datos: pagosrecibidos
            })
        }
        return result
    }

    protected GRID_GetExportarConfig(dataGrid: IPagoRecibidoDetalle[]): IConfigGridExcelExport<any> {
        type IDataToExport = Pick<IPagoRecibidoDetalle,
            "IDFolio" |
            "NombreCompletoAlumno" |
            "ConceptoCargo" |
            "ValorCargo" |
            "DescuentoTotalCargo" |
            "ValorFaltanteCargo" |
            "Valor" |
            "StrMetodoPago" |
            "FolioPago" |
            "FechaRegistro" |
            "FechaAplicacionCargo" |
            "FechaVencimientoCargo" |
            "StrEscuela"
        > & { _valor: number };

        const idsEscuelas = [...new Set(dataGrid.map(d => d.KinderFiltro[0]))];

        return <IConfigGridExcelExport<IDataToExport>>{
            IdsEscuelas: idsEscuelas,
            ColumnsConfig: this.ctrlTabla
                ._InfoColumns
                .filter(d => (d.Field != "StrEscuela"))
                .map<ExcelThings.IColumnToExcelExportFileConfig<IDataToExport>>(d => ({
                    Field: d.Field as keyof IDataToExport,
                    HeaderTag: d.Label,
                    WidthCell: d.Field == "NombreCompletoAlumno" ? 35 : 25
                })),
            OnGetDataBySheets: async () => {
                let groupedData: Map<number, IDataToExport[]> = new Map(idsEscuelas.map(idEscuela => ([idEscuela, []])));

                dataGrid.forEach(d => {
                    const idEscuela = d.KinderFiltro[0];
                    let datosEnEscuela = groupedData.get(idEscuela);
                    datosEnEscuela.push({
                        IDFolio: d.IDFolio,
                        NombreCompletoAlumno: d.NombreCompletoAlumno,
                        ConceptoCargo: d.ConceptoCargo,
                        ValorCargo: UIUtilFormat._CurrencyFmt(d.ValorCargo) as any,
                        DescuentoTotalCargo: UIUtilFormat._CurrencyFmt(d.DescuentoTotalCargo || 0) as any,
                        ValorFaltanteCargo: UIUtilFormat._CurrencyFmt(d.ValorFaltanteCargo) as any,
                        Valor: UIUtilFormat._CurrencyFmt(d.Valor) as any,
                        _valor: d.Valor,
                        StrMetodoPago: d.StrMetodoPago,
                        FolioPago: d.FolioPago,
                        FechaRegistro: UIUtilTime._DateFormatStandarFixTimeZone(d.FechaRegistro, d.ZonaHoraria, "dd/mm/yyyy h24:mm"),
                        FechaAplicacionCargo: UIUtilTime._DateFormatStandarFixTimeZone(d.FechaAplicacionCargo, d.ZonaHoraria),
                        FechaVencimientoCargo: UIUtilTime._DateFormatStandarFixTimeZone(d.FechaVencimientoCargo, d.ZonaHoraria),
                        StrEscuela: d.StrEscuela, // Sin columna
                    })
                })

                return Array.from(groupedData)
                    .map(([idEscuela, datosEscuela]) => ({
                        IdSheet: idEscuela,
                        SheetName: datosEscuela[0].StrEscuela,
                        Data: datosEscuela,
                        ExtraPie: [{
                            Label: _L("finanzaspagosrecibidos.tag_totalpagado"),
                            Value: UIUtilFormat._CurrencyFmt(this.CalcularTotal_Data(datosEscuela.map(d => ({ "Valor": d._valor })))),
                            Alignment: "right"
                        }]
                    }));
            },
            OnGetEscuelasTagInSheet: (datos) => datos[0].StrEscuela
        }
    }
}
