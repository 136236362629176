import * as d3 from "d3";
import _L from "../../util/Labels";
import { HTMLButton2Element } from "../controlWC/Button2Component";
import { HTMLButtonHostElement } from "../controlWC/ButtonHostComponent";
import { UIUtilElementBehaviors } from "../util/ElementBehaviors";
import { UIUtilGlobalKeyEvents } from "../util/GlobalKeyEvent";
import { UIUtilIconResources } from "../util/IconResourses";
import { UIUtilLang } from "../util/Language";
import { UIUtilStrings } from "../util/Strings";
import { UIUtilTime } from "../util/Time";
import { UIUtilGeneral } from "../util/Util";
import { UIUtilViewData } from "../util/ViewData";
import { Button } from "./Button";
import { DateInput, IDateVal, TypeDateInput } from "./DateInput";
import { TDropdownData } from "./DropdownAdvanced";
import { DropdownFlexV2 } from "./DropdownFlexV2";
import { Fields, FormGenerator, IField } from "./Formulario";
import { ModalThings } from "./ModalThings";
import { NotificacionV2 } from "./NotificacionV2";
import { SelectV2 } from "./SelectV2";

const ANIMATION_DURATION = 200;
type TKeyFilter = string;

interface IConfigControlV2 {
    Parent: d3.Selection<HTMLDivElement, any, any, any>;
    MainFilters: Array<IMainFilterItem>;
    // ShowSearchFilter: boolean;
    OnChangeFilters: Function;
    /** Si es = NULL el control no incluye el filtro por búsqueda de caja de texto */
    OnGetValuesToFilterBySearch?: (dato: any) => string[];
}

export enum CTextFilterMode {
    StartsWith = 1,
    EndsWith,
    Includes,
    NoIncludes,
}

export enum CNumberFilterMode {
    Minor = 1,
    Higher,
    Equal
}

interface IElements {
    /** Contenedor del control */
    DivContent: d3.Selection<HTMLDivElement, any, HTMLElement, null>;
    DivAreaParam: d3.Selection<HTMLDivElement, any, HTMLElement, null>;
    BtnClear: d3.Selection<HTMLButton2Element, any, HTMLElement, null>;
    DivButtonAdd: TSelectionHTML<"wc-button-host">;
    DivContainerBtnAdd: d3.Selection<HTMLDivElement, any, HTMLElement, null>;
    DivSearch: d3.Selection<HTMLDivElement, any, HTMLElement, null>;
    Dropdown: DropdownFlexV2.Control;
}

// *** CONFIGURACIONES POR TIPO DE FILTRO *** //

type TMainFilterExtrasMap = {
    "text": {
        /** Si retorna null, el fitro se omite */
        OnGetValueData: <TData>(dato: TData) => string[];
    };
    "number": {
        OnGetValueData: <TData>(dato: TData) => number[];
        Min?: number;
        Max?: number;
    };
    "select": {
        /** Si retorna null, el fitro se omite */
        OnGetValueData: <TData>(dato: TData) => any[];
        Options: TDropdownData<{ Id: any, Name: string }>;
    }
    "date-time": {
        /** Si retorna null, el fitro se omite */
        OnGetValueData: <TData>(dato: TData) => (string | Date)[];
        MinDate?: string;
        MaxDate?: string;
    },
    "date": {
        /** Si retorna null, el fitro se omite */
        OnGetValueData: <TData>(dato: TData) => (string | Date)[];
        MinDate?: string;
        MaxDate?: string;
    },
    "advanced": {
        OnGetAdvancedFilterDraw: (startValue: any) => d3.Selection<HTMLElement, any, any, any>;
        GetAdvancedFilterValue: () => any;
        OnStepAdvancedFilter: <TData>(filterValue: any, itemData: TData) => boolean;
    }
}

export type TMainFilterType = keyof TMainFilterExtrasMap & string;
export type IMainFilterItemTypeConfig<KFilterType extends TMainFilterType> = {
    Key: TKeyFilter;
    Label: string;
    Type: KFilterType;
    OnChange?: (filtro: TFiltroActivoValueShareMainMap[KFilterType]) => void;
    // OnStepFilter: <TData>(value: TFiltroActivoValueMain[K], datum: TData) => boolean;
} & TMainFilterExtrasMap[KFilterType];

export type IMainFilterItem = {
    [KFilterType in TMainFilterType]: IMainFilterItemTypeConfig<KFilterType>;
}[TMainFilterType];

// *** FILTRO ON CHANGE VALUE *** //

export type TFiltroActivoValueShareMainMap = {
    "text": TFiltroActivoValueMainMap["text"];
    "number": TFiltroActivoValueMainMap["number"];
    "select": TFiltroActivoValueMainMap["select"];
    "date": IDateVal;
    "date-time": IDateVal;
    "advanced": TFiltroActivoValueMainMap["advanced"];
}

// *** FILTRO ACTIVO *** //

type TFiltroActivoValueMainMap = {
    "text": {
        Text: string;
        Mode: CTextFilterMode;
    };
    "number": {
        Number: number;
        Mode: CNumberFilterMode;
    };
    "select": unknown[];
    "date": DateInput;
    "date-time": DateInput;
    /* {
        Tipo: TypeDateInput;
        DateA: Date;
        DateB: Date;
    }, */
    "advanced": unknown;
}

type TFiltroActivoBase<KFilterType extends TMainFilterType> = {
    ValueR: TFiltroActivoValueMainMap[KFilterType];
    ValueView: string[];
} & IMainFilterItemTypeConfig<KFilterType>

type IFiltroActivo = {
    [KFilterType in TMainFilterType]: TFiltroActivoBase<KFilterType>;
}[TMainFilterType];

export class FilterControlV2 {
    /** Guarda los parámetros de búsqueda actuales */
    private filtrosActivos: Map<string, IFiltroActivo>;
    private config: IConfigControlV2;
    private elements: IElements;

    private startSearch: NodeJS.Timeout;
    private searchText: string;

    constructor(config: IConfigControlV2) {
        this.elements = <IElements>{};
        this.filtrosActivos = new Map();
        this.searchText = "";
        this.Init();
        this.SetConfig(config);
    }

    private Init() {
        let elem = this.elements
        elem.DivContent = d3.create("div")
            .classed("filter_container", true)
        elem.DivContainerBtnAdd = d3.create("div");

        let containerFilters = elem.DivContent.append("div")
            .classed("filters-wrapper", true)

        let containerSearch = elem.DivContent.append("div")
            .classed("search-wrapper", true)

        elem.DivAreaParam = containerFilters.append<HTMLDivElement>("div")
            .classed("area_parametros", true)
        elem.BtnClear = containerFilters.append<HTMLButton2Element>("wc-button")
            .classed("btn_clear", true)
            .text(_L("table.limpiarfiltro"));

        this.elements.DivSearch = containerSearch.append<HTMLDivElement>("div")
            .classed("search", true)

        const input = this.elements.DivSearch
            .append("input")
            .attr("type", "text")
            .attr("placeholder", _L("general.buscar"))
            .node();

        UIUtilGlobalKeyEvents._SetKeyEventCallback(input, {
            key: "F",
            altKey: true,
            callback: () => {
                this.elements.Dropdown._Hide();
                input.focus();
            },
        })

        input.oninput = e => this.SearchByText();
        input.onkeyup = e => {
            if (e.code == "Enter")
                this.SearchByText(0);
        }

        this.elements.DivSearch
            .append("wc-button")
            .attr("dim", "24")
            .property("_SrcIco", UIUtilIconResources.CGeneral.ClearText)
            .on("click", () => {
                input.value = "";
                this.SearchByText(0);
                input.focus();
            });


        this.elements.DivButtonAdd = d3.create<HTMLButtonHostElement>("wc-button-host");
        UIUtilGlobalKeyEvents._SetKeyEventCallback(this.elements.DivButtonAdd.node(), {
            key: "F",
            altKey: true,
            shiftKey: true,
            callback: () => this.elements.DivButtonAdd.node().click()
        })
    }

    private SetConfig(config: IConfigControlV2) {
        this.config = config;
        this.config.Parent.append(() => this.elements.DivContent.node());
        this.DrawConfig();

        this.elements.DivContent.select(".search-wrapper").classed("hide", !this.config.OnGetValuesToFilterBySearch);

        // >>
        this.SetMainFilters(this.config.MainFilters);
    }

    private DrawConfig() {
        this.elements.DivAreaParam.append(() => this.elements.DivButtonAdd.node());

        //Button.BtnPlus.fn_GetPlusButton(15, this.elements.DivButtonAdd);
        Button.BtnPlus._GetPlusButton(15, this.elements.DivContainerBtnAdd);

        this.elements.DivContainerBtnAdd
            .classed("button_gen button_add", true)
        this.elements.DivContainerBtnAdd.append("label")
            .text(_L("table.filtrar"))
        this.elements.DivButtonAdd.append(() => this.elements.DivContainerBtnAdd.node());

        this.elements.DivButtonAdd.on("click", () => {
            if (this.elements.Dropdown._Visible) {
                this.elements.Dropdown._Hide();
            } else {
                this.elements.Dropdown._Show();
            }
        })

        this.elements.BtnClear.on("click", () => {
            this.FilterActive_Clear()
        })
    }

    private SetMainFilters(filters: IMainFilterItem[]) {
        this.FilterActive_Clear();
        this.config.MainFilters = filters;

        if (this.config.MainFilters.length == 0) {
            this.elements.DivContent.select(".filters-wrapper").classed("hide", true)
            this.elements.DivContent.select(".search-wrapper").classed("without-border", true)
        }

        if (!this.elements.Dropdown) {
            this.elements.Dropdown = new DropdownFlexV2.Control({
                Parent: this.elements.DivButtonAdd,
            })
        }
        this.elements.Dropdown._SetMenuOptions(this.config.MainFilters
            .map(mFilter => ({
                Label: mFilter.Label,
                OnClick: () => this.OpenMiniModal(mFilter),
            })));
    }

    private ExistFilters(): boolean {
        return (this.filtrosActivos.size > 0 || this.searchText != "");
    }

    private FilterActive_Add(filtro: IFiltroActivo) {
        this.filtrosActivos.set(filtro.Key, filtro);
        this.DrawFiltersActive();
        this.OnFilter();
        if (filtro.OnChange) {
            if (filtro.Type == "date" || filtro.Type == "date-time") {
                filtro.OnChange(filtro.ValueR._GetValues());
            } else {
                filtro.OnChange(filtro.ValueR as any);
            }
        }
    }

    private FilterActive_Remove(filtroKey: TKeyFilter) {
        const filtro = this.filtrosActivos.get(filtroKey)
        if (!filtro) {
            return
        }
        if (this.filtrosActivos.delete(filtroKey)) {
            this.DrawFiltersActive();
            this.OnFilter();
            if (filtro.OnChange) filtro.OnChange(null as any);
        }
    }

    private FilterActive_Clear(): void {
        const filtros = Array.from(this.filtrosActivos.values())
        if (this.filtrosActivos.size > 0) {
            this.filtrosActivos.clear();
            this.DrawFiltersActive();
            this.OnFilter();
            filtros.forEach(filtro => {
                if (!filtro.OnChange) return
                filtro.OnChange(null as any)
            })
        }
    }

    private DrawFiltersActive() {
        const parametros: Array<IFiltroActivo> = Array.from(this.filtrosActivos.values());

        this.elements.DivAreaParam.selectAll<HTMLDivElement, IFiltroActivo>(".item_filter")
            .data(parametros, (d) => d.Key)
            .join(
                enter => {
                    let item = enter.append<HTMLDivElement>("div")
                        .style("opacity", 0)
                        .classed("button_gen item_filter", true)

                    item.append("label");

                    Button.BtnClose._GetCloseCircleButton(item);

                    item.each((d, i, items) => {
                        let itemDiv = d3.select<HTMLDivElement, IFiltroActivo>(items[i])
                        this.UpdateFilterItemView(d, itemDiv);
                        return false;
                    })
                    item.transition().duration(ANIMATION_DURATION)
                        .style("opacity", 1);

                    return item;
                },
                update => update
                    .each((d, i, items) => {
                        let itemDiv = d3.select<HTMLDivElement, IFiltroActivo>(items[i])
                        this.UpdateFilterItemView(d, itemDiv);
                        return false;
                    }),
                exit => exit.transition().duration(ANIMATION_DURATION)
                    .style("opacity", 0)
                    .style("width", "0px")
                    .remove()
            )
        this.elements.DivButtonAdd.raise();
    }

    private UpdateFilterItemView(d: IFiltroActivo, viewItem: d3.Selection<HTMLDivElement, IFiltroActivo, HTMLElement, any>) {
        const isMultiple = d.Type == "select";
        (d.Type == "number")
            ? viewItem.select<HTMLLabelElement>("label").text(d.Label + ': ' + d.ValueView)
            : viewItem.select<HTMLLabelElement>("label").text(d.Label + ': ' + d.ValueView.map(val => `"${UIUtilStrings._TruncateStr(val, 45)}"`).join(', '));

        viewItem.on("click", () => {
            let even = d3.event
            if (even.target.localName === "label" || even.target.localName === "div") {
                this.OpenMiniModal(d, d);
            }
        })
        viewItem.select<SVGElement>(".btn_close_circle").on("click", () => {
            this.FilterActive_Remove(d.Key);
        })

        UIUtilElementBehaviors._EllipsisTextTooltip(
            viewItem.select<HTMLLabelElement>("label").node(),
            "button",
            () => d.ValueView.map(val => ((isMultiple ? "* " : "") + val)).join('\n')
        );
    }

    /**
     * @param timeDelay @default 1000ms
     */
    private SearchByText(timeDelay = 1000) {
        let input = this.elements.DivSearch.select<HTMLInputElement>("input").node();

        if (this.searchText == input.value) {
            return
        }
        this.searchText = input.value;

        if (this.startSearch) {
            clearTimeout(this.startSearch);
        }
        this.startSearch = setTimeout(() => {
            this.startSearch = null;
            // this.ControllerDataFilter(this.config.Data);
            this.OnFilter();
        }, timeDelay);
    }

    private OnFilter() {
        this.config.OnChangeFilters();
    }

    private ItemControllerFilter<TData>(itemD: TData, includeSearchTextFilter: boolean, keysFiltersUse?: TKeyFilter[]): boolean {
        // FILTROS TOP
        let filtrosActivos: IFiltroActivo[];
        if (keysFiltersUse) {
            filtrosActivos = Array.from(this.filtrosActivos.values())
                .filter(d => (keysFiltersUse.indexOf(d.Key) > -1));
        } else {
            filtrosActivos = Array.from(this.filtrosActivos.values());
        }

        for (const filtroA of filtrosActivos) {
            switch (filtroA.Type) {
                case "text":
                    const textValues = filtroA.OnGetValueData(itemD);
                    if (textValues) {
                        const inputTextFilter = UIUtilStrings._StringRemoveDiacriticMarks(filtroA.ValueR.Text.toLowerCase());
                        const textMatched = textValues.find(textValue => {
                            if (textValue == null) {
                                return false;
                            }
                            textValue = UIUtilStrings._StringRemoveDiacriticMarks(textValue.toLowerCase());
                            if (filtroA.ValueR.Mode == CTextFilterMode.EndsWith) {
                                return textValue.endsWith(inputTextFilter);
                            } else if (filtroA.ValueR.Mode == CTextFilterMode.Includes) {
                                return textValue.includes(inputTextFilter);
                            } else if (filtroA.ValueR.Mode == CTextFilterMode.NoIncludes) {
                                return !textValue.includes(inputTextFilter);
                            } else {
                                return textValue.startsWith(inputTextFilter);
                            }
                        })

                        if (textMatched === undefined) {
                            return false;
                        }
                    }
                    break;
                case "number":
                    const numberAdvValues = filtroA.OnGetValueData(itemD);
                    if (numberAdvValues) {
                        const numAdvValMatched = numberAdvValues.find(numberAdvValue => {
                            if (numberAdvValue == null) return false;

                            if (filtroA.ValueR.Mode == CNumberFilterMode.Minor) {
                                return filtroA.ValueR.Number > numberAdvValue;
                            } else if (filtroA.ValueR.Mode == CNumberFilterMode.Higher) {
                                return filtroA.ValueR.Number < numberAdvValue;
                            } else {
                                return filtroA.ValueR.Number === numberAdvValue;
                            }
                        })

                        if (numAdvValMatched === undefined) return false;
                    }
                    break;
                case "select":
                    const selectValues = filtroA.OnGetValueData(itemD);
                    if (selectValues) {
                        const selectValMatched = selectValues.find(value => (filtroA.ValueR.includes(value)));
                        if (selectValMatched === undefined) {
                            return false;
                        }
                    }
                    break;
                case "date":
                case "date-time":
                    const dateValues = filtroA.OnGetValueData(itemD);
                    if (dateValues) {
                        const dateMatched = dateValues.find(dateValue => {
                            dateValue = UIUtilTime._GetValidDate(dateValue);
                            return (dateValue && this.GetCompareDates(filtroA.ValueR, dateValue))
                        });
                        if (dateMatched === undefined) {
                            return false;
                        }
                    }
                    break;
                case "advanced": // DOTEST
                    let customValue = filtroA.GetAdvancedFilterValue();

                    if (filtroA.OnStepAdvancedFilter(customValue, itemD)) {
                        return false;
                    }
                    break;
            }
        }

        // FILTROS STR SEARCH
        if (includeSearchTextFilter && this.searchText) {
            // this.Item_ApplySearchByText(itemD);
            let strs = this.config.OnGetValuesToFilterBySearch(itemD);
            // let strs = filtroA.GetStrToMatchSearch(itemD);
            if (strs) {
                let match = strs
                    .find((str) => (
                        UIUtilStrings._StringRemoveDiacriticMarks(str.toLowerCase())
                            .includes(UIUtilStrings._StringRemoveDiacriticMarks(this.searchText.toLowerCase()))
                    ));

                if (match === undefined) {
                    return false;
                }
            }
        }
        // }
        // if (this.config.OnStepItemFilterFinal) {
        //     filterResult = this.config.OnStepItemFilterFinal(itemD, filterResult, originEvent);
        // }
        return true;
    }

    private GetCompareDates(filtroFecha: TFiltroActivoValueMainMap["date"], dateData: Date): boolean {
        const dateVals = filtroFecha._GetValues()
        if (dateVals.dateA && dateVals.dateB) {
            if (dateData.getTime() >= dateVals.dateA.getTime() && dateData.getTime() < dateVals.dateB.getTime()) { // DOTEST
                return true
            }
            return false;
        }
        /* else if (filtroFecha.DateA) {
            let dateD = UIUtilTime._TruncDate(dateData, "Hour");
            let filtroDA = UIUtilTime._TruncDate(filtroFecha.DateA, "Hour");

            if (dateD.getTime() == filtroDA.getTime()) {
                return true;
            }
            return false;
        }
        else {
            return false;
        } */
    }

    private OpenMiniModal(mainFilter: IMainFilterItem, value: IFiltroActivo = this.filtrosActivos.get(mainFilter.Key)) {
        const typeF = mainFilter.Type;
        // const modeF = mainFilter.Mode;
        const modelMainFilter = "filter";
        const modelTextFilterMode = "modo";

        ModalThings._GetModalToForm({
            Title: mainFilter.Label,
            Width: (typeF == "date" || typeF == "date-time" ? 380 : 260),
            GetForm: (content) => {
                // Get schema & init data
                let schema: IField<any>[] = [];
                let dataFormInit = {
                    [modelMainFilter]: value?.ValueR
                };

                switch (typeF) {
                    case "text":
                        schema.push({
                            model: modelMainFilter,
                            type: Fields.input,
                            inputAttr: {
                                type: typeF,
                                placeholder: _L("general.buscar"),
                                required: true,
                                inputProperties: { required: true, value: value?.ValueR as string || "" },
                                onload: (input) => setTimeout(() => input.node().focus()),
                            }
                        });
                        let textValue = (value as TFiltroActivoBase<"text">);
                        dataFormInit[modelMainFilter] = textValue?.ValueR.Text;
                        dataFormInit[modelTextFilterMode] = textValue?.ValueR.Mode || CTextFilterMode.Includes;
                        schema.push({
                            model: modelTextFilterMode,
                            type: Fields.selectMaterial,
                            labelAttr: { text: _L("control.tipo_busqueda") },
                            selectMaterialAttr: {
                                valueMember: "Id",
                                displayMember: "Name",
                                required: true,
                            },
                            values: <UIUtilViewData.IBaseData[]>[
                                { Id: CTextFilterMode.Includes, Name: _L("general.includes") },
                                { Id: CTextFilterMode.NoIncludes, Name: _L("general.noincludes") },
                                { Id: CTextFilterMode.StartsWith, Name: _L("general.startswith") },
                                { Id: CTextFilterMode.EndsWith, Name: _L("general.endswith") },
                            ]
                        });
                        break;
                    case "number":
                        let numbervalue = (value as TFiltroActivoBase<"number">);
                        dataFormInit[modelMainFilter] = numbervalue?.ValueR.Number;
                        dataFormInit[modelTextFilterMode] = numbervalue?.ValueR.Mode || CNumberFilterMode.Equal;

                        schema.push({
                            model: modelMainFilter,
                            type: Fields.input,
                            inputAttr: {
                                type: typeF,
                                placeholder: _L("general.buscar"),
                                min: (mainFilter as IMainFilterItemTypeConfig<"number">).Min,
                                max: (mainFilter as IMainFilterItemTypeConfig<"number">).Max,
                                required: true,
                                onload: (input) => setTimeout(() => input.node().focus()),
                                inputProperties: { step: "1", value: value?.ValueR as string || "" }
                            }
                        })

                        schema.push({
                            model: modelTextFilterMode,
                            type: Fields.selectMaterial,
                            labelText: "Tipo de Búsqueda",
                            selectMaterialAttr: {
                                valueMember: "Id",
                                displayMember: "Name",
                                required: true,
                            },
                            values: <UIUtilViewData.IBaseData[]>[
                                { Id: CNumberFilterMode.Minor, Name: _L("general.minorthan") },
                                { Id: CNumberFilterMode.Higher, Name: _L("general.higherthan") },
                                { Id: CNumberFilterMode.Equal, Name: _L("general.equalto") }
                            ]
                        });
                        break;
                    case "select":
                        schema.push({
                            model: modelMainFilter,
                            type: Fields.selectMaterial,
                            selectMaterialAttr: {
                                valueMember: "Id",
                                displayMember: "Name",
                                multiselect: true,
                                ShowAndEnableSearchText: true,
                                required: true,
                                Data: (mainFilter as IMainFilterItemTypeConfig<"select">).Options,
                            },
                            onValidate: (value) => {
                                return value != null && (value as string[]).toString().trim() != "";
                            }
                        });
                        break;
                    case "date":
                    case "date-time":
                        schema.push({
                            model: modelMainFilter,
                            type: Fields.label,
                            onValidate: (mt, control) => {
                                const dateControl = control.instance as unknown as DateInput;
                                const dateVal = dateControl._GetValues();
                                let val: boolean;
                                if (dateVal.typeDateInput == TypeDateInput.DateRange) {
                                    val = Boolean(dateVal.dateA) && Boolean(dateVal.dateB);
                                } else if (dateVal.typeDateInput == TypeDateInput.OnlyADate) {
                                    val = Boolean(dateVal.dateA);
                                }
                                if (!val) {
                                    return false;
                                }
                                val = dateVal.dateA.getTime() <= dateVal.dateB.getTime()
                                if (!val)
                                    NotificacionV2._Mostrar(_L("control.inicio_reqbigger"), "ADVERTENCIA")
                                return val;
                            },
                            onLoad: (controlMainFilter) => {
                                const dateControl = new DateInput({
                                    Parent: controlMainFilter.row,
                                    MinDate: (mainFilter as IMainFilterItemTypeConfig<"date">).MinDate,
                                    MaxDate: (mainFilter as IMainFilterItemTypeConfig<"date">).MaxDate,
                                    IncludeTime: (typeF == "date-time")
                                });
                                controlMainFilter.instance = dateControl as any;
                                controlMainFilter.selection = dateControl._ControlContainer as any; // NOTE TYPES

                                dateControl._ControlContainer.selectAll("input").classed("input-form", true);

                                const initValueDate = (<TFiltroActivoBase<"date">>value);

                                const vals = initValueDate?.ValueR?._GetValues() || {};
                                (controlMainFilter.instance as unknown as DateInput)._SetValues({
                                    typeDateInput: TypeDateInput.DateRange,
                                    ...(vals as any),
                                });
                            }
                        })
                        break;
                    case "advanced":
                        schema.push({
                            model: modelMainFilter,
                            type: Fields.label,
                            onValidate: () => {
                                return (mainFilter as IMainFilterItemTypeConfig<"advanced">).GetAdvancedFilterValue() != null;
                            },
                            onLoad: (controlMainFilter, form) => {
                                let customSelection = (mainFilter as IMainFilterItemTypeConfig<"advanced">).OnGetAdvancedFilterDraw(value?.ValueR);
                                controlMainFilter.selection = customSelection;
                                form._Form.append(() => customSelection.node());
                            }
                        })
                        break;
                }

                // Build Form
                return new FormGenerator()
                    ._Crear({
                        schema: schema,
                        LabelMaxWidth: 150,
                    }, dataFormInit);
            },
            DrawContent: (content, form, mt) => {
                mt.BtnLeft.remove();
                content.classed("filteredit_container", true);
                mt.Modal._ContainerSelection.on("click", () => {
                    if (d3.event.target == mt.Modal._ContainerSelection.node()) {
                        mt.Modal._Ocultar();
                    }
                });

                const modal = mt.Modal._ModalSelection
                    .style("position", "absolute")
                    .node();
                const modalParent = d3.select(modal.parentElement)
                    .style("position", "absolute")
                    .style("background-color", "transparent")
                    .node();

                let observeResize = UIUtilGeneral._GetResizeObserver(entries => {
                    let limits = this.elements.DivButtonAdd.node().getBoundingClientRect();
                    let containerBox = modalParent.getBoundingClientRect();
                    let dif = containerBox.width - (limits.x + limits.width + 300);
                    let left = limits.x + (dif < 0 ? dif : 0);

                    modal.style.top = (limits.y + limits.height) + "px";
                    modal.style.left = left + "px";
                });

                observeResize.observe(modalParent);
            },
            OnAccept: (form, mt) => {
                const dataForm = form._Data;
                let valueR;
                let valueView: string[];
                switch (typeF) {
                    case "text":
                        valueView = dataForm[modelMainFilter];
                        if (dataForm[modelTextFilterMode] == CTextFilterMode.StartsWith) {
                            valueView = [valueView + "..."];
                        } else if (dataForm[modelTextFilterMode] == CTextFilterMode.EndsWith) {
                            valueView = ["..." + valueView];
                        } else if (dataForm[modelTextFilterMode] == CTextFilterMode.Includes) {
                            valueView = ["..." + valueView + "..."];
                        } else if (dataForm[modelTextFilterMode] == CTextFilterMode.NoIncludes) {
                            valueView = ["!" + valueView];
                        } else {
                            valueView = [valueView + ""];
                        }
                        valueR = <TFiltroActivoValueMainMap["text"]>{
                            Text: dataForm[modelMainFilter],
                            Mode: dataForm[modelTextFilterMode]
                        }
                        break;
                    case "number":
                        valueView = dataForm[modelMainFilter];
                        if (dataForm[modelTextFilterMode] == CNumberFilterMode.Minor) {
                            valueView = [_L("general.minorthan") + " " + valueView];
                        } else if (dataForm[modelTextFilterMode] == CNumberFilterMode.Higher) {
                            valueView = [_L("general.higherthan") + " " + valueView];
                        } else {
                            valueView = [_L("general.equalto") + " " + valueView];
                        }
                        valueR = <TFiltroActivoValueMainMap["number"]>{
                            Number: dataForm[modelMainFilter],
                            Mode: dataForm[modelTextFilterMode]
                        }
                        break;
                    case "date":
                    case "date-time":
                        const dateControl = form._ControlsData.get(modelMainFilter).instance as unknown as DateInput;
                        const dateVal = dateControl._GetValues();
                        valueR = dateControl
                        let strView = "";

                        if (!dateControl._IncludeTime) {
                            if (dateVal.typeDateInput == TypeDateInput.DateRange) {
                                strView = UIUtilTime._DateFormatStandar(dateVal.dateA) + " - " + UIUtilTime._DateFormatStandar(dateVal.dateB)
                            } else if (dateVal.typeDateInput == TypeDateInput.OnlyADate) {
                                strView = UIUtilTime._DateFormatStandar(dateVal.dateA);
                            }
                        }
                        if (!strView) {
                            const once59 = (() => {
                                const dtA = new Date(dateVal.dateA.getFullYear(), dateVal.dateA.getMonth(), dateVal.dateA.getDate() + 1).getTime();
                                const dtB = new Date(dateVal.dateA.getFullYear(), dateVal.dateA.getMonth(), dateVal.dateA.getDate()).getTime() + 1
                                return dtA - dtB;
                            })();
                            const rangeDt = dateVal.dateB.getTime() - dateVal.dateA.getTime();
                            if (rangeDt == once59) {
                                strView = UIUtilTime._DateFormatStandar(dateVal.dateA);
                            }
                            else if (rangeDt < once59) {
                                strView = `${UIUtilTime._DateFormatStandar(dateVal.dateA)} (${UIUtilTime._DateFormatStandar(dateVal.dateA, "h24:mm")} - ${UIUtilTime._DateFormatStandar(dateVal.dateB, "h24:mm")})`;
                            }
                            else {
                                strView = UIUtilTime._DateFormatStandar(dateVal.dateA, "dd/mm/yyyy h24:mm") + " - " + UIUtilTime._DateFormatStandar(dateVal.dateB, "dd/mm/yyyy h24:mm")
                            }
                        }
                        valueView = [strView];
                        // console.warn(dateControl)
                        break;
                    case "select":
                        const selectControl = form._ControlsData.get(modelMainFilter).instance as SelectV2<UIUtilViewData.IBaseData>;
                        valueView = selectControl._dataSelected.map(d => d.Name);
                        valueR = <TFiltroActivoValueMainMap["select"]>selectControl._dataSelected.map(d => d.Id);
                        break;
                    case "advanced":
                        // DOTEST
                        valueR = (mainFilter as IMainFilterItemTypeConfig<"advanced">).GetAdvancedFilterValue();
                        valueView = valueR; // (mainFilter as IMainFilterItemTypeConfig<"advanced">).GetAdvancedFilterValue();
                        break;
                }

                this.FilterActive_Add({
                    ...mainFilter,
                    ...{
                        ValueR: valueR,
                        ValueView: valueView,
                    }
                });

                mt.Modal._Ocultar();
                return null;
            }
        })
    }

    // ********************************************************************************
    // PUBLIC METHODS
    // ********************************************************************************

    public get _CurrentSearchText() {
        return this.searchText;
    }

    public get _ControlSelection() {
        return this.elements.DivContent;
    }

    public get _ControlNode() {
        return this.elements.DivContent.node();
    }

    public _SetMainFilters(filters) {
        this.SetMainFilters(filters);
        return this;
    }

    public _SetSearchText(value: string) {
        this.searchText = value;
        this.elements.DivSearch
            .select("input")
            .property("value", value);

        this.OnFilter();
    }

    public _GetFiltersExist() {
        this.ExistFilters();
    }

    /**
     * @param data
     * @param includeSearchTextFilter (default ```true```)
     * @param keysFiltersUse Si se establece, el filtrado solo atiende a los campos indicados
     * @returns
     */
    public _FilterData<TData>(data: Array<TData>, includeSearchTextFilter: boolean = true, keysFiltersUse?: TKeyFilter[]): TData[] {
        if (this.ExistFilters()) {
            return data
                .filter(d => {
                    return this.ItemControllerFilter(d, includeSearchTextFilter, keysFiltersUse);
                })
        }
        return [...data];
    }

    /**
     * @param item
     * @param includeSearchTextFilter (default ```true```)
     * @param keysFiltersUse  Si se establece, el filtrado solo atiende a los filtros indicados
     * @returns
     */
    public _IsItemValid<TData>(item: TData, includeSearchTextFilter: boolean = true, keysFiltersUse?: TKeyFilter[]) {
        return this.ItemControllerFilter(item, includeSearchTextFilter, keysFiltersUse);
    }

    public _Clear() {
        this.FilterActive_Clear();
    }
}
