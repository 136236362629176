import * as d3 from "d3";
import { MainPage } from "../../MainPage";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import DataModuloAyuda from "../../data/modulo/Ayuda";
import { VentanaBase } from "../controlD3/AVentanaBase";
import { Button } from "../controlD3/Button";
import { ElementWrapper } from "../controlD3/ElementWrapper";
import { InputFileControl } from "../controlD3/InputFileControlV2";
import { TutorialActivarAlumno } from "../controlD3/TutorialActivarAlumno";
import { UIUtilLang } from "../util/Language";
import { UIUtilMimeType } from "../util/MimeTypes";
import { UIUtilTime } from "../util/Time";
import { UIUtilGeneral } from "../util/Util";
import IAyuda = Entidad.IAyuda;
const TRequestAyuda = Entidad.CTipoRequest.Ayuda;
import CAyudaTipoAplicacion = Entidad.CAyudaTipoAplicacion;
import { FilterControl } from "../controlD3/FilterControl";
import { FilePreviewControl } from "../controlD3/ItemFileControlDocuments"

export class UIVentanaAyudaBase extends VentanaBase {
    private ayudaTipoAplicacion: CAyudaTipoAplicacion;
    private ctrlFilter: FilterControl<IAyuda>;

    constructor(container: d3.Selection<HTMLDivElement, undefined, HTMLElement, any>, modulo: Entidad.CModulo, tipoAyuda: CAyudaTipoAplicacion) {
        super(container, modulo, "ayuda");
        this.ayudaTipoAplicacion = tipoAyuda;

        this.UI_Init();
        const datos = this.DATA_GetData();
        this.UI_UpdateList(datos);
        this.ctrlFilter._UpdateData(datos, false);

        MainPage._ReloadService(TRequestAyuda, null, () => {
            this.ctrlFilter._UpdateData(this.DATA_GetData(), true);
        })
    }

    public _OnServiceEvent(eventName: DataModuloMain.TipoRequestMonitorId, reloadId?: number, error?: Error): void {
        super._OnServiceEvent(eventName, reloadId, error);

        if (eventName == TRequestAyuda) {
            const datos = this.DATA_GetData();
            this.ctrlFilter._UpdateData(datos, true);

            if (!this.ctrlFilter._CurrentSearchText?.trim()) {
                this.UI_UpdateList(datos);
            }
        }
    }

    public _Mostrar(): void {
        super._Mostrar();
        MainPage._ActivarServiceMonitor(Entidad.CTipoRequest.Ayuda, true);
    }

    public _Destroy(): void {
        super._Destroy();
        MainPage._ActivarServiceMonitor(Entidad.CTipoRequest.Ayuda, false);
    }

    private UI_Init() {
        this.windowContent
            .classed("grid_ayuda", true);

        let winHeader = this.windowContent.append("div")
            .attr("class", "ayuda_header");

        this.ctrlFilter = new FilterControl<IAyuda>({
            Parent: winHeader,
            ColumnsFields: ["Titulo", "Descripcion", "Enlace"],
            OnFilter: (datos) => {
                console.warn("-d", "onfilter", datos)
                this.UI_UpdateList(datos);
            },
            ShowSearchFilter: true,
            Parameters: []
        })

        // const inputSearch
        const inputParent = this.ctrlFilter._ControlSelection
            .select<HTMLDivElement>(".search")
            .node();

        d3.select(inputParent)
            .select<HTMLInputElement>("input")
            .attr("placeholder", "");

        // const inputParent = inputSearch.node().parentElement;

        const searchWrapper = ElementWrapper._WrapperToElement(inputParent, UIUtilLang._GetUIString("general", "buscar"))

        searchWrapper.select(".area_label")
            .style("width", "100px");

        const headerContainer = d3.select(searchWrapper.node().parentElement)
            .classed(UIUtilGeneral.FBoxOrientation.Horizontal + " " + UIUtilGeneral.FBoxAlign.SpacebetweenCenter, true);

        if (this.ayudaTipoAplicacion == CAyudaTipoAplicacion.Admin && TutorialActivarAlumno._HasPermissionToSee()) {
            new Button(headerContainer.node(), this.VB_GetUIStringModule("tag_starttutorial"))
                ._d3Selection
                .on("click", () => {
                    TutorialActivarAlumno._StartTutorial();
                })

            d3.select(inputParent)
                .classed(UIUtilGeneral.FBoxOrientation.Horizontal, true)
                .classed(UIUtilGeneral.FBoxAlign.SpacebetweenCenter, true);
        }

        this.windowContent.append("div")
            .classed("list", true);
    }

    private async UI_UpdateList(datos: IAyuda[]) {
        const areaList = this.windowContent
            .select(".list")

        areaList
            .selectAll<HTMLDivElement, IAyuda>(".item_ayuda")
            .data(datos)
            .join(
                enter => this.UI_UpdateItemAyuda(this.UI_CreateItemAyuda(enter)),
                update => this.UI_UpdateItemAyuda(update),
                exit => exit
                    .style("opacity", 1)
                    .transition()
                    .duration(300)
                    .style("opacity", 0)
                    .remove()
            )

        let tagSinContenido = areaList.select(":scope > label");
        if (datos.length) {
            tagSinContenido.remove();
        } else if (!tagSinContenido.node()) {
            areaList.append("label")
                .text(UIUtilLang._GetUIString("general", "sincontenido"))
                .style("font-weight", "bold");
        }
    }

    private UI_CreateItemAyuda(enter: d3.Selection<d3.EnterElement, IAyuda, any, any>) {
        // >> Item container
        let item = enter.append("div")
            .classed("item_ayuda", true);

        // >> Item header
        item.append("div")
            .classed("header", true)
            .append("label")
            .classed("lbl_title", true);

        // >> Item content container
        const cont = item.append("div")
            .classed("content", true);

        cont.append("pre")
            .classed("lbl_description", true);

        cont.append("a")
            .classed("lbl_link", true);

        cont.each((d, i, arr) => {
            // let cont = d3.select<HTMLDivElement, IAyuda>(arr[i]);
            let documentPreview: FilePreviewControl.FileControlDocuments = new FilePreviewControl.FileControlDocuments();

            let inputF: InputFileControl.InputFile = new InputFileControl.InputFile({
                Dimentions: "30%",
                MinDimention: "min-content",
                MaxDimention: "100%",
                ControlStyle: "foto_control_style2",
                ControlForm: InputFileControl.ControlForm.Cuadrado,
                ShowBtnLoadFile: false,
                ShowBtnDownloadFile: true,
                // OnDownload_GetRealResource: () => {
                //     if (inputF.prop_IsValidFile && !inputF.prop_IsImage) {
                //         return inputF.prop_File;
                //     }
                //     else if (inputF["_dato"]) {
                //         const idAyuda = (inputF["_dato"] as IAyuda).Id;
                //         return data.modulos.Ayuda.fn_GetUrlObtenerArchivo(idAyuda, 1);
                //     }
                //     return null;
                // },
                OnEndLoadFileStatus: (isImage, isFromInput, isSuccess) => {
                    inputF._ControlSelection.classed("hide", !isSuccess);

                    if (isSuccess) {
                        inputF._Dimentions = (isImage ? /* "calc(100% - 3px)" */ "100%" : "30%"); // FIXME HACER SOLO 100%

                        inputF._ControlSelection
                            .interrupt()
                            .style("opacity", 0)
                            .transition()
                            .duration(300)
                            .style("opacity", 1);
                    }
                }
            })

            inputF._ControlSelection
                .classed("hide", true);
            documentPreview._ControlSelection
                .classed("hide", true)

            arr[i].append(inputF._ControlNode);
            arr[i]["_inputFile"] = inputF;
            arr[i].append(documentPreview._ControlNode);
            arr[i]["_documentPrev"] = documentPreview
        })

        // >> Item footer
        item.append("div")
            .classed("footer", true)
            .append("label")
            .attr("class", "lbl_footer")
            .text(this.VB_GetUIStringModule("tag_dtpublication") + ": ")
            .append("span");

        // >> Item animation

        item
            .style("opacity", 0)
            .transition()
            .duration(300)
            .style("opacity", 1);

        return item;
    }

    private UI_UpdateItemAyuda(item: TSelectionHTML<"div", IAyuda>) {
        item.select(".lbl_title")
            .text(d => d.Titulo);

        item.select(".lbl_description")
            .text(d => d.Descripcion);

        item.select(".lbl_link")
            .classed("hide", d => !Boolean(d.Enlace))
            .attr("href", d => d.Enlace)
            .attr("target", "_blank")
            // .style("overflow-wrap", "anywhere")
            .text(d => d.Enlace);

        item.select<HTMLDivElement>(".content")
            .each((d, i, arr) => {
                let cont = d3.select<HTMLDivElement, IAyuda>(arr[i]);
                let inputF = arr[i]["_inputFile"] as InputFileControl.InputFile;
                let documentPreview = arr[i]["_documentPrev"] as FilePreviewControl.FileControlDocuments;
                // inputF.prop_ControlSelection.classed("hide", true);

                // if (d.ConArchivo && (inputF["_dato"] !== d || !inputF.prop_IsValidFile)) {
                if (d.ConArchivo) {
                    // if (d.MimeType?.startsWith("audio") || d.MimeType?.startsWith("video")) {
                    // >> IF VIDEO RESOURSE
                    if (d.MimeType.startsWith("video")) {
                        cont.select("audio").classed("hide", true);
                        inputF._ControlSelection.classed("hide", true);
                        documentPreview._ControlSelection.classed("hide", true);

                        let videoSelection = cont.select<HTMLVideoElement>("video")
                            .classed("hide", false);

                        if (!videoSelection.node()) {
                            videoSelection = cont.append("video")
                                .attr("controls", true)
                                .style("width", "auto")
                                .style("height", "250px");

                            videoSelection
                                .text("Tu navegador no admite HTML5")
                                .node().preload = "none";
                        }

                        videoSelection.attr("src", DataModuloAyuda._GetUrlObtenerArchivo(d.Id, 1));

                        if (d.ConImagenMini) {
                            videoSelection
                                .attr("poster", DataModuloAyuda._GetUrlObtenerThumbnail(d, 1))
                                .style("object-fit", "cover");

                            videoSelection.node().onplay = e => {
                                videoSelection
                                    .style("object-fit", "contain");
                            }
                        }
                        else {
                            videoSelection.attr("poster", "");
                        }
                    }
                    else if (d.MimeType.startsWith("audio")) {
                        // >> IF AUDIO RESOURSE
                        cont.select("video").classed("hide", true);
                        inputF._ControlSelection.classed("hide", true);
                        documentPreview._ControlSelection.classed("", true);

                        let audioSelection = cont.select<HTMLAudioElement>("audio")
                            .classed("hide", false);

                        if (!audioSelection.node()) {
                            audioSelection = cont.append("audio")
                                .attr("controls", true)
                                .style("width", "auto")

                            audioSelection
                                .text("Tu navegador no admite HTML5")
                                .node().preload = "none";
                        }

                        audioSelection.attr("src", DataModuloAyuda._GetUrlObtenerArchivo(d.Id, 1));
                    }
                    else {
                        cont.select("audio").classed("hide", true);
                        cont.select("video").classed("hide", true);
                        if (d.MimeType.startsWith("application/pdf")) {
                            inputF._ControlSelection.classed("hide", true);
                            documentPreview._ItemTitle = d.Titulo;
                            documentPreview._ControlSelection.classed("hide", false);
                            documentPreview._UrlResource = DataModuloAyuda._GetUrlObtenerArchivo(d.Id, 1);
                            documentPreview
                                ._OnDownload_GetRealresource(() => {
                                    return DataModuloAyuda._GetUrlObtenerArchivo(d.Id, 1);
                                })
                                ._OnGetFileName(() => d.Titulo)
                                ._SpinnerControl.node()._Dim = 60
                            return;
                        }

                        inputF._ControlSelection.classed("hide", false);

                        inputF._AuxExtensionFile = UIUtilMimeType._GetExtensionFromMimeType(d.MimeType);
                        inputF._UrlResource = DataModuloAyuda._GetUrlObtenerArchivo(d.Id, 2);
                        inputF
                            ._OnDownload_GetRealresource(() => {
                                return DataModuloAyuda._GetUrlObtenerArchivo(d.Id, 1);
                            })
                            ._OnGetFileName(() => d.Titulo)
                            ._SpinnerControl.node()._Dim = 60;
                        // "https://rst1-dev.kidi.mx/adminjsv2/alumno/ObtenerFoto?IdUsuario=153&Tamanio=3&Modificacion=2021-12-20T23:42:59.193Z";
                    }
                } else {
                    cont.select("audio").classed("hide", true);
                    cont.select("video").classed("hide", true);
                    inputF._ControlSelection.classed("hide", true);
                }

                // inputF["_dato"] = d;
            });

        item.select(".lbl_footer")
            .select("span")
            .text(d => (d.FechaRegistro ? (" " + UIUtilTime._DateFormatStandar(d.FechaRegistro)) : ""));

        return item;
    }

    private DATA_GetData() {
        return DataModuloMain._GetReqDataArrayById(TRequestAyuda, true)
            .filter(d => (d.TipoAplicacion == this.ayudaTipoAplicacion));
    }
}

export class UIVentanaAyudaAdmin extends UIVentanaAyudaBase {
    constructor(container: d3.Selection<HTMLDivElement, any, any, any>, modulo: Entidad.CModulo) {
        super(container, modulo, CAyudaTipoAplicacion.Admin);
    }
}

export class UIVentanaAyudaTeacher extends UIVentanaAyudaBase {
    constructor(container: d3.Selection<HTMLDivElement, any, any, any>, modulo: Entidad.CModulo) {
        super(container, modulo, CAyudaTipoAplicacion.Docente);
    }
}

export class UIVentanaAyudaPapas extends UIVentanaAyudaBase {
    constructor(container: d3.Selection<HTMLDivElement, any, any, any>, modulo: Entidad.CModulo) {
        super(container, modulo, CAyudaTipoAplicacion.Padre);
    }
}

export class UIVentanaAyudaAlumno extends UIVentanaAyudaBase {
    constructor(container: d3.Selection<HTMLDivElement, any, any, any>, modulo: Entidad.CModulo) {
        super(container, modulo, CAyudaTipoAplicacion.Alumno);
    }
}
