import { Selection, create } from "d3";
import { Global } from "../../data/Global";
import { DataUtilAlertBot } from "../../data/util/AlertBot";
import { TRoutePath } from "../../routes/Routes";
import { KCardAlumnos } from "../ventana/AlumnosPanelV2";
import { KGruposCard } from "../ventana/GruposPanelV2";
import { UIWindowManager } from "../ventana/WindowManager";
import { UIUtilDialPhoneCodes } from "./DialPhoneCodes";
import { UIUtilLang } from "./Language";
import { UIUtilMimeType } from "./MimeTypes";
import { Loader } from "@googlemaps/js-api-loader";
import { UIUtilElement } from "./Element";

export namespace UIUtilGeneral {

    export type TTypeStepJoin = "enter" | "update" | "exit";

    //** Clases para alinear elementos dentro de un contenedor */
    export enum FBoxOrientation {
        /** Column */
        Vertical = "fx_col",
        /** Column Reverse */
        VerticalReverse = "fx_col_reverse",
        /** Row */
        Horizontal = "fx_row",
        /** Row Reverse */
        HorizontalReverse = "fx_row_reverse"
    }

    /** Se aplican a contenedores con 'display: flex'. Requires @enum FlexOrientation (class: fx_col, fx_row, fx_row-reverse, fx_col-reverse)
     * * Justifica - Alinea (Eje principal - Eje secundario)
     * * if (display: flex; flex-direction: row) -> Alinea contenedor en eje X - Y
     * * if (display: flex; flex-direction: column) -> Alinea contenedor en eje Y - X
    */
    export enum FBoxAlign {
        StartCenter = "fx_align_start_center",
        StartStart = "fx_align_start_start",
        StartEnd = "fx_align_start_end",
        StartStretch = "fx_align_start_stretch",
        CenterCenter = "fx_align_center_center",
        CenterStart = "fx_align_center_start",
        CenterEnd = "fx_align_center_end",
        CenterStretch = "fx_align_center_stretch",
        EndCenter = "fx_align_end_center",
        EndStart = "fx_align_end_start",
        EndEnd = "fx_align_end_end",
        EndStretch = "fx_align_end_stretch",
        SpacebetweenCenter = "fx_align_spacebetween_center",
        SpacebetweenStart = "fx_align_spacebetween_start",
        SpacebetweenEnd = "fx_align_spacebetween_end",
        SpacebetweenStretch = "fx_align_spacebetween_stretch",
        StretchCenter = "fx_align_stretch_center",
        StretchStart = "fx_align_stretch_start",
        StretchEnd = "fx_align_stretch_end",
        StretchStretch = "fx_align_stretch_stretch"
    }

    export enum FBoxWrap {
        Wrap = "fx_wrap",
        NoWrap = "fx_nowrap",
        WrapReverse = "fx_wrap_reverse"
    }

    export async function _Init() {
        await UIUtilLang._Init();
        await UIUtilMimeType._Init();
        await UIUtilDialPhoneCodes._Init();
    }

    /** Devuelve un valor encontrado en niveles inferiores dentro de un objeto
     * * Ej: ("otrodato.valor", dato): dato.otrodato.valor;
     */
    export function _GetValueLevelsObj<TResVal = any>(campos: string, dato: Object): TResVal | null {
        if (campos) {
            let findedValue: TResVal = dato as any;
            let camposSplit = campos.split(".");
            for (let campo of camposSplit) {
                if (findedValue !== undefined)
                    findedValue = findedValue[campo];
                else
                    return null;
            }
            return findedValue === dato ? null : findedValue;
        }
        return null;
    }

    // *********************************************************************
    // FORMATERS THINGS
    // *********************************************************************



    // *********************************************************************
    // GOOGLE MAPS THINGS
    // *********************************************************************

    let MapGoogleLoaded: google.maps.MapsLibrary = null;
    export async function _GetGoogleLoad(): Promise<google.maps.MapsLibrary | null> {
        if (MapGoogleLoaded)
            return MapGoogleLoaded;

        const loader = new Loader({
            apiKey: Global._GLOBAL_CONF.GoogleMaps_Key,
            version: "weekly",
            libraries: ["places"],
        });
        return loader.importLibrary("maps")
            .then((Map) => {
                MapGoogleLoaded = Map;
                return Map;
            })
            .catch((e) => {
                DataUtilAlertBot._SendError(e, "Google load fail");
                return null;
            })
    }

    // *********************************************************************
    // HTML ELEMENTS - EXTIENDE FUNCIONALIDADES
    // *********************************************************************

    type TPanelPath = Extract<TRoutePath,
        "alumnos/alumnos/panel" | "escuelas/grupos/panel" | "escuelas/escuelas/panel"
    >;

    type TPanelModeMap = {
        "alumnos/alumnos/panel": KCardAlumnos;
        "escuelas/grupos/panel": KGruposCard;
        "escuelas/escuelas/panel": "";
    }
    // interface IPanelsParamsBase 

    type ILinkPanelThings = {
        [P in TPanelPath]: {
            Hash: P;
            OnClick?: Function;
            Params?: {
                mode: TPanelModeMap[P] | "All";
                id: number[];
            };
        }
    }[TPanelPath];

    // /**
    //  *
    //  * @param container
    //  * @param data
    //  * @param panelPath
    //  * @param onGetStr function | null
    //  * @param onGetId
    //  * @param onStepItemLink
    //  */
    // export function _CreaElementosLinkeables<T>(container: TSelectionHTML<"div">, data: T[], panelPath: TPanelPath, onGetStr: ((d: T) => string), onGetId: ((d: T) => number), onStepItemLink?: ((label: TSelectionHTML<"label">, d: T) => void)) {
    //     container.html("");

    //     data.forEach(((d, i, arr) => {
    //         let lbl = container.append("label")
    //         if (onGetStr) {
    //             lbl.text(onGetStr(d));
    //         }
    //         if (onStepItemLink) {
    //             onStepItemLink(lbl, d);
    //         }

    //         _ElementAdd_LinkToGoToPanel(lbl, panelPath, onGetId(d));

    //         if (arr[i + 1]) {
    //             container.append("span").text(", ");
    //         }
    //     }))
    // }

    interface ILinkedElementItem<T> {
        Container: TSelectionHTML<"htmlelement">,
        Data: T[];
        Path: TPanelPath;
        GetTag: keyof T & string | ((d: T) => string);
        GetId: TOnlyKeysTypeof<T, number> | ((d: T) => number);
        /**
         * @default "horizontal"
         */
        Direction?: "vertical" | "horizontal";
        OnClickItem?: (dato: T) => void;
        OnStepItemLink?: ((label: TSelectionHTML<"a">, d: T) => void);
    }
    export function _CreaElementosLinkeablesV2<T>(params: ILinkedElementItem<T>) {
        params.Container.html("");

        params.Data.forEach(((d, i, arr) => {
            const a = params.Container.append<HTMLAnchorElement>("a")
            if (params.GetTag) {
                if (typeof params.GetTag == "string")
                    a.text(d[params.GetTag] + "");
                else
                    a.text(params.GetTag(d));
            }
            if (params.OnStepItemLink) {
                params.OnStepItemLink(a, d);
            }

            let id = (
                typeof params.GetId == "function"
                    ? params.GetId(d)
                    : d[params.GetId]
            )
            a.attr("href", `#${params.Path}--id=${id}&mode=All`);

            if (a["__click"]) {
                a.node().removeEventListener('click', a["__click"]);
                a["__click"] = null;
            }
            if (params.OnClickItem) {
                a["__click"] = () => params.OnClickItem(d);
                a.node().addEventListener('click', a["__click"]);
            }
            // fn_ElementAdd_LinkToGoToPanel(a, params.Path, (id as number), (params.OnClickItem ? () => params.OnClickItem(d) : null));

            if (arr[i + 1]) {
                params.Container.append("span").text(", ");
                if (params.Direction == "vertical") {
                    params.Container.append("br");
                }
            }
        }))
    }

    export function _ElementAdd_LinkToGoToPanel<P extends TPanelPath>(element: TSelectionHTML<any>, panelPath: P, id: number, onClick?: Function, mode?: TPanelModeMap[P]) {
        let item: ILinkPanelThings = {
            Hash: panelPath as any,
            OnClick: onClick,
            Params: {
                id: [id],
                mode: (mode || "All") as any
            }
        }

        let elementNode = element
            .classed("link_item", true)
            .node();

        elementNode["_LinkThings"] = item;

        elementNode.removeEventListener("click", ElementClick_GoTo);
        elementNode.addEventListener("click", ElementClick_GoTo);

        return element;
    }

    export function _CreateLinkeableElement(content: TSelectionHTML<"htmlelement"> | string, href: string) {
        let linkeableElement = create("a");
        linkeableElement.attr("href", href);
        if (typeof (content) === "string") {
            linkeableElement.text(content)
        } else {
            linkeableElement.append(() => content.node())
        }
        return linkeableElement;
    }

    export function _Sleep(ms: number): Promise<void> {
        return new Promise((resolve) => setTimeout(resolve, ms))
    }

    function ElementClick_GoTo(e: MouseEvent) {
        e.stopPropagation();
        let item = e.currentTarget["_LinkThings"] as ILinkPanelThings;
        UIWindowManager._DoHash(item.Hash, item.Params);

        if (item.OnClick) {
            item.OnClick();
        }
    }

    /** Agrega un evento keypress al input, que acepta solo la entrada de números */
    export function _InputAddKeyPress_OnlyNumber(input: TSelectionHTML<"input">) {
        // -> Remueve el evento si es que ya lo tenía
        input.node().removeEventListener("keypress", InputKeyPress_OnlyNumberEvent);
        input.node().removeEventListener("paste", InputKeyPress_OnlyNumberEvent);
        // ->
        input.node().addEventListener("keypress", InputKeyPress_OnlyNumberEvent);
        input.node().addEventListener("paste", InputKeyPress_OnlyNumberEvent);
    }

    // export function fn_InputAddKeyPress_ToLowerCase(input: TSelectionHTML<"input">,) {
    //     // input.node().removeEventListener("keypress", InputValue_OnlyLowerCase);
    //     input.node().removeEventListener("keyup", InputValue_OnlyLowerCase);
    //     input.node().removeEventListener("paste", InputValue_OnlyLowerCase);
    //     input.node().removeEventListener("change", InputValue_OnlyLowerCase);

    //     // input.node().addEventListener("keypress", InputValue_OnlyLowerCase);
    //     input.node().addEventListener("keyup", InputValue_OnlyLowerCase);
    //     input.node().addEventListener("paste", InputValue_OnlyLowerCase);
    //     input.node().addEventListener("change", InputValue_OnlyLowerCase);
    // }

    // export function fn_InputAddKeyPress_ToUpperCase(input: TSelectionHTML<"input">,) {
    //     // input.node().removeEventListener("keypress", InputValue_OnlyUpperCase);
    //     input.node().removeEventListener("keyup", InputValue_OnlyUpperCase);
    //     input.node().removeEventListener("paste", InputValue_OnlyUpperCase);
    //     input.node().removeEventListener("change", InputValue_OnlyUpperCase);

    //     // input.node().addEventListener("keypress", InputValue_OnlyUpperCase);
    //     input.node().addEventListener("keyup", InputValue_OnlyUpperCase);
    //     input.node().addEventListener("paste", InputValue_OnlyUpperCase);
    //     input.node().addEventListener("change", InputValue_OnlyUpperCase);
    // }

    export function _InputAddKeyPress_NoWhiteSpaces(input: TSelectionHTML<"input">,) {
        // input.node().removeEventListener("keypress", InputValue_NoWhiteSpaces);
        input.node().removeEventListener("keyup", InputValue_NoWhiteSpaces);
        input.node().removeEventListener("paste", InputValue_NoWhiteSpaces);
        input.node().removeEventListener("change", InputValue_NoWhiteSpaces);

        // input.node().addEventListener("keypress", InputValue_NoWhiteSpaces);
        input.node().addEventListener("keyup", InputValue_NoWhiteSpaces);
        input.node().addEventListener("paste", InputValue_NoWhiteSpaces);
        input.node().addEventListener("change", InputValue_NoWhiteSpaces);
    }

    // export function fn_InputAddKeyPress_Trim(input: TSelectionHTML<"input">,) {
    //     // input.node().removeEventListener("keypress", InputValue_Trim);
    //     input.node().removeEventListener("keyup", InputValue_Trim);
    //     input.node().removeEventListener("paste", InputValue_Trim);
    //     input.node().removeEventListener("change", InputValue_Trim);

    //     // input.node().addEventListener("keypress", InputValue_Trim);
    //     input.node().addEventListener("keyup", InputValue_Trim);
    //     input.node().addEventListener("paste", InputValue_Trim);
    //     input.node().addEventListener("change", InputValue_Trim);
    // }

    function InputKeyPress_OnlyNumberEvent(e: KeyboardEvent | ClipboardEvent) {
        if (e instanceof KeyboardEvent) {
            if (e.key.trim() && isNaN(Number(e.key))) {
                e.preventDefault();
            }
        } else {
            setTimeout(() => {
                // NOTE El value del input pierde carácteres válidos si tiene maxlenght, los espacios los ocupan.
                let inputValue = (e.target as HTMLInputElement).value.split(" ").join(""); // replaceAll;
                if (isNaN(Number(inputValue))) {
                    (e.target as HTMLInputElement).value = "";
                } else {
                    (e.target as HTMLInputElement).value = inputValue;
                }
            });
        }
    }

    // function InputValue_OnlyLowerCase(e: KeyboardEvent | ClipboardEvent) {
    //     // setTimeout(() => {
    //     (e.target as HTMLInputElement).value = (e.target as HTMLInputElement).value
    //         .toLowerCase();
    //     // });
    // }

    // function InputValue_OnlyUpperCase(e: KeyboardEvent | ClipboardEvent) {
    //     // setTimeout(() => {
    //     (e.target as HTMLInputElement).value = (e.target as HTMLInputElement).value
    //         .toUpperCase();
    //     // });
    // }

    function InputValue_NoWhiteSpaces(e: KeyboardEvent | ClipboardEvent) {
        // setTimeout(() => {
        (e.target as HTMLInputElement).value = (e.target as HTMLInputElement).value
            .split(" ")
            .join("");
        // });
    }

    // function InputValue_Trim(e: KeyboardEvent | ClipboardEvent) {
    //     // setTimeout(() => {
    //     (e.target as HTMLInputElement).value = (e.target as HTMLInputElement).value
    //         .trim();
    //     // });
    // }

    export function _FixValueAsPxValue(value: string): (string | null) {
        if (!value) return null;
        if (!isNaN(Number(value))) {
            value = value + "px";
        }
        else if (!value.endsWith("px")) {
            value = null;
        }
        return value;
    }

    // *********************************************************************
    // 
    // *********************************************************************

    type Getters<T> = Partial<{
        [x in keyof T]: x extends PropertyKey ? ((thisDato: T) => T[x]) : never;
    }>;

    export function _ObjectAddGetters<DataBase extends {}>(dato: DataBase, getters?: Getters<Readonly<DataBase>>) {
        Object.getOwnPropertyNames(getters)
            .forEach(key => {
                Object.defineProperty(
                    dato, key, {
                    get: () => getters[key](dato),
                    // set: (val: any) => console.warn("-d", "Property ", name, " is readonly")
                }
                )
            });
        return dato;
    }

    export function _ObjectClone<T extends Object>(obj: T) {
        let result: T = Object.assign({}, obj);

        for (let key of Reflect.ownKeys(obj)) {                         // Properties
            const descrOrigin = Object.getOwnPropertyDescriptor(obj, key);
            const descrResult = Object.getOwnPropertyDescriptor(result, key);
            if (!descrResult || descrResult.configurable) {
                if (descrOrigin.get) { // || descrOrigin.set) {         // descriptor with getter and setter
                    Object.defineProperty(result, key,
                        Object.assign(descrOrigin, {
                            // set: (descrOrigin.set ? ((val) => obj[key](val)) : undefined),
                            // get: (descrOrigin.get ? (() => obj[key]) : undefined), // Referencia al getter del objeto original
                            get: (() => obj[key]),                      // Referencia al getter del objeto original
                            // set: (descrOrigin.set ? descrOrigin.set.bind(result) : undefined),
                            // get: (descrOrigin.get ? descrOrigin.get.bind(result) : undefined)
                        })
                    );
                    // result[key] = copy(obj[key]);
                }
                // else {                                               // descriptor with value
                //     Object.defineProperty(result, key,
                //         Object.assign(descrOrigin, { value: copy(obj[key]) })
                //     );
                // }
            }
        }

        return result;
    }

    export function _FileToBase64(file: File): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            if (file) {
                const reader = new FileReader();

                reader.onloadend = async (e) => {
                    resolve(String(reader.result));
                }
                reader.onerror = (e) => {
                    reject(e);
                }

                reader.readAsDataURL(file);
            }
            else {
                reject(new Error("File missing on get base64 data"));
            }
        });
    }

    /** Devuelve `HTMLImageElement` cuando finaliza la descarga del recurso */
    export function _GetIMGElementOnLoadResource(imageURL: string): Promise<HTMLImageElement> {
        let img = document.createElement("img");
        return new Promise((resolve, reject) => {
            img.onload = e => {
                resolve(img);
            }
            img.onerror = e => {
                reject(e.toString());
            }
            img.src = imageURL;
        })
    }

    type TRelativePosition = "bottom" | "top" | "left" | "right";

    /** Evalua la posición de @param parentElement con respecto a los limites (width, height) del body y retorna el "mejor" posicionamiento posible para @param position */
    export function _GetRelativePositions(parentElement: HTMLElement, position: TRelativePosition, separation = 0) {
        const pBounds = parentElement.getBoundingClientRect();
        const screenWidth = document.body.clientWidth;
        const screenHeight = document.body.clientHeight;
        // const screenHeight = document.body.clientHeight;

        // LT: this.GetPointGridLocation(pBounds.xLeft, pBounds.yTop),
        // RT: this.GetPointGridLocation(pBounds.xRight, pBounds.yTop),
        // RB: this.GetPointGridLocation(pBounds.xRight, pBounds.yBottom),
        // LB: this.GetPointGridLocation(pBounds.xLeft, pBounds.yBottom)

        let positions = {
            Top: null as number,
            Bottom: null as number,
            Left: null as number,
            Right: null as number
        }

        switch (position) {
            case "bottom":
                let lbB = GetPointGridLocation(pBounds.left, pBounds.bottom);

                positions.Top = (pBounds.bottom + separation);

                if (lbB[0] == 1) {
                    positions.Left = pBounds.left;
                } else {
                    positions.Right = (screenWidth - pBounds.right);
                }
                break;
            case "top":
                let lbT = GetPointGridLocation(pBounds.left, pBounds.top);

                positions.Bottom = (screenHeight - pBounds.top + separation);

                if (lbT[0] == 1) {
                    positions.Left = pBounds.left;
                } else {
                    positions.Right = (screenWidth - pBounds.right);
                }
                break;
            case "left":
                let lbL = GetPointGridLocation(pBounds.left, pBounds.top);

                positions.Right = (screenWidth - pBounds.left + separation);

                if (lbL[1] == 1) {
                    positions.Top = pBounds.top;
                } else {
                    positions.Bottom = (screenHeight - pBounds.top + separation);
                }
                break;
            case "right":
                let lbR = GetPointGridLocation(pBounds.right, pBounds.top);

                positions.Left = (pBounds.left + pBounds.width + separation);

                if (lbR[1] == 1) {
                    positions.Top = pBounds.top;
                } else {
                    positions.Bottom = (screenHeight - (pBounds.top + pBounds.height) + separation);
                }
                break;
        }

        // console.debug(positions);

        return positions;
    }

    /** Locations:
     * 
     *         x1      x2
     *    
     *    y1   [1, 1] | [2, 1]
     * 
     *    y2   [1, 2] | [2, 2]
     * 
     * @param pointX (left | right)
     * @param pointY (top | bottom)
     */
    function GetPointGridLocation(pointX: number, pointY: number) {
        const screenCenter = {
            x: (document.body.clientWidth / 2),
            y: (document.body.clientHeight / 2)
        };

        let x: (1 | 2) = (pointX < screenCenter.x ? 1 : 2);
        let y: (1 | 2) = (pointY < screenCenter.y ? 1 : 2);

        return [x, y];
    }

    /** @deprecated use `UIUtilElement._GetResizeObserver` instead */
    export function _GetResizeObserver(callback: ResizeObserverCallback) {
        return UIUtilElement._GetResizeObserver(callback);
    }

    export function _GetElementWidthInViewport(element: HTMLElement, viewportElement: HTMLElement = element.parentElement): number {
        const elementDims = element.getBoundingClientRect();
        const viewportDims = viewportElement.getBoundingClientRect();

        const [viewportXW, elementXW] = [(viewportDims.x + viewportDims.width), (elementDims.x + elementDims.width)];

        let resultW = elementDims.width;

        if (viewportDims.x > elementDims.x) {
            resultW -= (viewportDims.x - elementDims.x);
        }
        if (viewportXW < elementXW) {
            resultW -= (elementXW - viewportXW);
        }

        return (resultW < 0 ? 0 : resultW);
    }

    export function _ArrayMoveIndex<T>(array: T[], indexFrom: number, indexTo: number) {
        if (indexFrom < 0 || indexTo < 0 || indexFrom > array.length - 1 || indexTo > array.length - 1) {
            console.error("Indice fuera de los limites", indexFrom, indexTo, array.length);
            return array;
        }
        let moveValue = array[indexFrom];

        let listNotValue = array
            .filter((d, i) => (i != indexFrom));

        return _ArrayAddValuePosition(listNotValue, moveValue, indexTo);
    }

    export function _ArrayAddValuePosition<T = any>(array: T[], value: T, index: number) {
        return array
            .slice(0, index)
            .concat(value, array.slice(index));
    }

    export function _GetStrModa(arr: string[]): string[] {
        const frecuencia: { [key: string]: number } = {}
        arr.forEach((evalu) => {
            frecuencia[evalu] = (frecuencia[evalu] || 0) + 1;
        })

        const maxFrecuencia = Math.max(...Object.values(frecuencia));

        const moda: string[] = Object.keys(frecuencia)
            .filter((numero) => frecuencia[numero] === maxFrecuencia)
        return moda;
    }

    export function _PromedioFromNumList(numbers: number[]): number {
        if (!numbers.length)
            return 0;
        const sum = numbers.reduce((acumulador, numero) => (acumulador + numero), 0);
        return (sum / numbers.length);
    }

    export function _GetLocationDialCode(): string {
        const dialCodes = {
            "es": "+52",
            "en": "+1"
        }
        const lang_current = navigator.language.toLowerCase();
        for (const lang in dialCodes) {
            if (lang_current.toLowerCase().startsWith(lang))
                return dialCodes[lang] as string;
        }
        return null;
    }
}

// REMOVER
// type THTMLWCElementTagNameMap = {
//     "wc-checkbox": HTMLCheckBoxElement;
//     "wc-tooltip": HTMLTooltipComponent;
//     "wc-icon-toggle": HTMLIconToggleElement;
//     "wc-ic-collapse": HTMLIconCollapseElement;
//     "wc-fileico": HTMLFileIcoElement;
//     "wc-button": HTMLButton2Element;
//     "wc-spinner": HTMLSpinnerElement;
//     "wc-progress": HTMLProgressElement;
//     "wc-button-host": HTMLButtonHostElement;
// }
// type THTMLElements = (HTMLElementTagNameMap & THTMLWCElementTagNameMap) & {
//     "htmlelement": HTMLElement;
// };

// type TSelectionHTML<
//     KElement extends keyof THTMLElements & string = "htmlelement",
//     TDatum = any,
//     KElemParent extends keyof HTMLElementTagNameMap = any
// > = Selection<
//     THTMLElements[KElement],
//     TDatum,
//     (HTMLElementTagNameMap[KElemParent] | any),
//     any
// >;