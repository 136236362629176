import { ArrayV2 } from "../../util/ArrayV2";
import { DataDRequest } from "../DRequest";
import { Entidad } from "../Entidad";
import { Global } from "../Global";
import { DataUtil } from "../util/Util";

import IEscuela = Entidad.IEscuela;
import IAlumnoHorarioAsignacion = Entidad.IAlumnoHorarioAsignacion;

export const _DiccNinioHorarioAsignacion: Map<number, IAlumnoHorarioAsignacion> = new Map();

/** Map<IdGrupo, Map<IdHorario, INinioHorarioAsignacion>> */
export let _DiccAsignacionGrupoAlumnos: Map<number, Map<number, IAlumnoHorarioAsignacion>> = new Map();

/** Map<IdAlumno, Map<IdHorario (id de asignación), Entidades.INinioHorarioAsignacion>> */
export let _DiccAsignacionAlumnoGruposHorarios: Map<number, Map<number, IAlumnoHorarioAsignacion>> = new Map();

const IDREQUEST = Entidad.CTipoRequest.HorarioAlumno;
const SVC_HORARIO = Global._GLOBAL_CONF.URL_RSTV2 + "horario/";

export function _GetList(escuelas: IEscuela[]): Promise<DataDRequest.IRequestResponseListA<IAlumnoHorarioAsignacion>[]> {
    const url = SVC_HORARIO + "ObtenerListaV2";
    return new ArrayV2<IEscuela>()
        ._Push(...escuelas)
        ._MapAwait(async ({ IdKinder }) => {
            const currentDateStr = await DataUtil._GetMaxDate(IDREQUEST, IdKinder);
            const params = {
                "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
                "IdEscuela": IdKinder,
                "Modificacion": currentDateStr
            }
            return DataDRequest._Request4List(url, "GET", params, IDREQUEST, IdKinder);
        });
}

// ******************************************************************
// SERVICIOS V3
// ******************************************************************

type TDiasSemana = keyof typeof Entidad.CDiaSemanal;
type TTypeTime = {
    Entrada: string, //Date;
    Salida: string, //Date;
}
export type IHorariosWeek = {
    [k in TDiasSemana]: TTypeTime
}
type TGrupoHorarioSchemeWeekToService = [string, string, string, string, string, string, string];

function GetPreparedHorariosData(horariosEnter: IGrupoHorarioActualizarV2[]): IGrupoHorarioToService[] {
    let GetDayValue = function (diaValues: TTypeTime, typeTime: (keyof TTypeTime & string)) {
        if (diaValues) {
            return (diaValues[typeTime]/*?.toISOString()*/ || null);
        }
        return null;
    }
    let GetHorariosScheme = function (horariosWeek: IHorariosWeek, typeTime: (keyof TTypeTime & string)): TGrupoHorarioSchemeWeekToService {
        return [
            GetDayValue(horariosWeek.Lunes, typeTime),
            GetDayValue(horariosWeek.Martes, typeTime),
            GetDayValue(horariosWeek.Miercoles, typeTime),
            GetDayValue(horariosWeek.Jueves, typeTime),
            GetDayValue(horariosWeek.Viernes, typeTime),
            GetDayValue(horariosWeek.Sabado, typeTime),
            GetDayValue(horariosWeek.Domingo, typeTime),
        ]
    }

    return horariosEnter.map<IGrupoHorarioToService>(horarioEnter => {
        return {
            IdGrupo: horarioEnter.IdGrupo,
            IdHorario: horarioEnter.IdHorario,
            Entradas: GetHorariosScheme(horarioEnter.Horarios, "Entrada"),
            Salidas: GetHorariosScheme(horarioEnter.Horarios, "Salida"),
        }
    })
}
interface IGrupoHorarioBase {
    /** Para actualizar existente */
    IdHorario?: number;
    /** Para dar de alta nuevo horario */
    IdGrupo?: number;
}
interface IGrupoHorarioToService extends IGrupoHorarioBase {
    Entradas: TGrupoHorarioSchemeWeekToService;
    Salidas: TGrupoHorarioSchemeWeekToService;
}
export interface IGrupoHorarioActualizarV2 extends IGrupoHorarioBase {
    Horarios: IHorariosWeek;
}

export async function _AsignarHorariosV3(idEscuela: number, idsAlumnos: number[], horariosAgregar: IGrupoHorarioActualizarV2[]): Promise<DataDRequest.IRequestResponseA<number[]>> {
    const url = SVC_HORARIO + "AsignarHorariosV2";
    const params = {
        "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "IdEscuela": idEscuela,
        "Alumnos": idsAlumnos,
        "Horarios": GetPreparedHorariosData(horariosAgregar)
    }
    return DataDRequest._Request5<number[]>(url, "POST", params, IDREQUEST);
}

export interface IHorarioActualizarResponse {
    "Actualizados": number[],
    "Eliminados": number[],
    "Nuevos": number[]
}

export async function _ActualizarHorarioV3(idEscuela: number, idAlumno: number, horariosActualizar: IGrupoHorarioActualizarV2[], HorariosEliminar: number[]): Promise<DataDRequest.IRequestResponseA<IHorarioActualizarResponse>> {
    const url = SVC_HORARIO + "ActualizarHorarioV2"
    const params = {
        "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "IdEscuela": idEscuela,
        "IdAlumno": idAlumno,
        "Horarios": GetPreparedHorariosData(horariosActualizar),
        "HorariosEliminar": HorariosEliminar
    }
    return DataDRequest._Request5<IHorarioActualizarResponse>(url, "POST", params, IDREQUEST);
}

export default {
    _DiccNinioHorarioAsignacion,
    _DiccAsignacionAlumnoGruposHorarios,
    _DiccAsignacionGrupoAlumnos,
    _AsignarHorariosV3,
    _ActualizarHorarioV3
}
