import * as d3 from "d3";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import { CAlumnoEdoLicencia } from "../../data/entidad/LicenciaOld";
import { _DICC_ALUMNO, _LOCALDATA_GetGruposHorariosDeAlumno, _LOCALDATA_GetHermanos } from "../../data/modulo/Alumno";
import { DateV2 } from "../../util/DateV2";
import { VentanaBase } from "../controlD3/AVentanaBase";
import { CardV2Collapse } from "../controlD3/CardV2Collapse";
import { CardInfoV2 } from "../controlD3/CardV2Info";
import { ExpanderItemListCardStyle, IExpanderItemListItemConfig } from "../controlD3/ExpanderItemList";
import { List } from "../controlD3/List";
import { NotificacionV2 } from "../controlD3/NotificacionV2";
import { Tabs } from "../controlD3/Tabs";
import { HTMLImage2Component } from "../controlWC/HTMLImage2Component";
import { HTMLTooltipComponent } from "../controlWC/TooltipComponent";
import { UIPanelCardAlumnosBoletas } from "../panelCard/AlumnosBoletas";
import { UIPanelCardAlumnosCargosDescuentos } from "../panelCard/AlumnosCargosDescuentos";
import { UIPanelCardAlumnosCircular } from "../panelCard/AlumnosCircular";
import { UIPanelCardAlumnosEstadoCuenta } from "../panelCard/AlumnosEstadoCuenta";
import { UIPanelCardAlumnosExpediente } from "../panelCard/AlumnosExpediente";
import { UIPanelCardAlumnosFotos } from "../panelCard/AlumnosFotos";
import { UIPanelCardAlumnosInfoExtra } from "../panelCard/AlumnosInfoExtra";
import { UIPanelCardAlumnosInfoGralPreview } from "../panelCard/AlumnosInfoGralPreview";
import { UIPanelCardAlumnosInfoTutores } from "../panelCard/AlumnosInfoTutores";
import { UIPanelCardAlumnosInscripcionPreview } from "../panelCard/AlumnosInscripcionPreview";
import { UIPanelCardAlumnosLogros } from "../panelCard/AlumnosLogros";
import { UIPanelCardAlumnosMaterias } from "../panelCard/AlumnosMaterias";
import { UIUtilIconResources } from "../util/IconResourses";
import { UIUtilLang } from "../util/Language";
import { UIUtilPermission } from "../util/Permission";
import { UIUtilTime } from "../util/Time";
import { UIUtilGeneral } from "../util/Util";
import { UIUtilViewData } from "../util/ViewData";
import { UIUtilViewAlumno } from "../utilView/Alumno";
import { UIUtilViewGrupos } from "../utilView/Grupos";
import { UIWindowManager } from "./WindowManager";

import IAlumno = Entidad.IAlumno;
import CModulo = Entidad.CModulo;
import _HasModulePermission = UIUtilPermission._HasModulePermission;
import _MODULOS_FINANZAS = Entidad._MODULOS_FINANZAS;
import _MODULOS_LOGROS = Entidad._MODULOS_LOGROS;
import _MODULOS_CALIFICACIONES = Entidad._MODULOS_CALIFICACIONES;
export enum CFinanzasTabs {
    CargosyDesc = "palumnocargosdescuentos",
    EdoCuenta = "palumnoedocta",
}
export enum CCalificacionesTabs {
    Asignaciones = "palumnomaterias",
    Boletas = "palumnoboletas"
}

type CardsKeys = "palumnoinfogeneral" | "palumnotutores" | "palumnoexpedientes" | "palumnoinfoextra" | "palumnohorariosgrupos" | "palumnologros" | "palumnocalificaciones" | "palumnocomunicados" | "palumnofinanzasmain" | "palumnofotos";
type CardTabsFinanzasKeys = CFinanzasTabs.CargosyDesc | CFinanzasTabs.EdoCuenta;
type CardTabsCalificacionesKeys = CCalificacionesTabs.Asignaciones | CCalificacionesTabs.Boletas;
type Modes = CardsKeys | CardTabsFinanzasKeys | CardTabsCalificacionesKeys | "All";

export type KCardAlumnos = CardsKeys | CardTabsFinanzasKeys | CardTabsCalificacionesKeys;

const TarjetasDisponibles: { [k in CardsKeys]: { modulo: Entidad.CModulo, cardColl: CardV2Collapse<any>, langKey?: string, errList?: boolean } } = {
    "palumnoinfogeneral": { modulo: CModulo.PanelInfoGeneral, cardColl: null, errList: true }, /* Manejo de Error // Servicio para Mov */
    "palumnoinfoextra": { modulo: CModulo.Alumnos, cardColl: null, langKey: "alumnopanelinfoextra", errList: true }, /* Manejo de Error // Servicio */
    "palumnotutores": { modulo: CModulo.PanelTutores, cardColl: null, errList: true }, /* Manejo de Error */
    "palumnoexpedientes": { modulo: CModulo.PanelExpediente, cardColl: null }, /* Va por servicios */
    "palumnohorariosgrupos": { modulo: CModulo.PanelNinioHorario, cardColl: null, errList: true }, /* Multiple // Manejo de Error */
    "palumnologros": { modulo: CModulo.PanelNinioLogros, cardColl: null }, /* Multiple */
    "palumnocalificaciones": { modulo: CModulo.PanelAlumnoMaterias, cardColl: null, langKey: "alumnopanelcalificaciones" }, /* Hijos => Servicios */
    "palumnocomunicados": { modulo: CModulo.PanelCircular, cardColl: null }, /* Servicio */
    "palumnofinanzasmain": { modulo: CModulo.PanelFinanzasEdoCuenta, cardColl: null, langKey: "alumnopanelfinanzasmain" }, /* Hijos => Servicios */
    "palumnofotos": { modulo: CModulo.Alumnos, cardColl: null, langKey: "panelfotos" }, /* Servicio */
}

const TarjetasFinanzasTabs: { [k in CardTabsFinanzasKeys]: { modulo: Entidad.CModulo, cardColl: CardV2Collapse<any>, langKey?: string, errList?: boolean } } = {
    "palumnocargosdescuentos": { modulo: CModulo.PanelFinanzasCargosDescuentos, cardColl: null }, /* Multiple // Servicio */
    "palumnoedocta": { modulo: CModulo.PanelFinanzasEdoCuenta, cardColl: null } /* Servicio */
}

const TarjetasCalificacionesTabs: { [k in CardTabsCalificacionesKeys]: { modulo: Entidad.CModulo, cardColl: CardV2Collapse<any>, langKey?: string, errList?: boolean } } = {
    "palumnomaterias": { modulo: CModulo.Materias, cardColl: null },
    "palumnoboletas": { modulo: CModulo.Materias, cardColl: null }
}

const ErrorsPriority = new Map<CardsKeys, number>([["palumnoinfogeneral", 1], ["palumnotutores", 2], ["palumnohorariosgrupos", 3]])

export class UIVentanaAlumnosPanelV2 extends VentanaBase {
    private mapAlumnos: Map<number, IAlumno> = new Map<number, IAlumno>();
    private currentIdAlumnos: number[] = [];
    private currentAlumnos: IAlumno[] = [];
    private currentMode: Modes;
    private ctrCardInfo: CardInfoV2;
    private alumnosList: List<IAlumno>;
    private ctrlCardsList: ExpanderItemListCardStyle;
    private ctrlFinanzasTabs: Tabs;
    private ctrlCalificacionesTabs: Tabs;
    private errorsCardsPriority: Array<{ CardKey: CardsKeys, Priority: number }> = [];

    constructor(contenedor: d3.Selection<HTMLDivElement, any, any, any>, modulo: Entidad.CModulo) {
        super(contenedor, modulo, "alumnospanel");
    }

    public _Mostrar(): void {
        super._Mostrar();
        this.InitPanel();
        this.InitInicialParams();
    }

    private RefreshCurrentAlumnos() {
        let bolReplaceHash = false;
        this.currentAlumnos.forEach((alumno, index, arr) => {
            let alumnoUpdt = DataModuloMain._GetItemDataByName("Alumno", alumno.IdChild);
            if (alumnoUpdt) {
                this.mapAlumnos.set(alumno.IdChild, alumnoUpdt);
            } else {
                bolReplaceHash = true;
                this.mapAlumnos.delete(alumno.IdChild);
            }
        });
        this.currentAlumnos = [...this.mapAlumnos.values()];
        this.currentIdAlumnos = [...this.mapAlumnos.keys()];
        if (!this.currentAlumnos.length) {
            this.ErrorInPanelsGoBack(UIUtilLang._GetUIString("general", "notif_infonodisponible"));
            return false;
        }
        if (bolReplaceHash) {
            UIWindowManager._DoHashReplace("alumnos/alumnos/panel", {
                mode: this.currentMode,
                id: [...this.mapAlumnos.keys()]
            });
            return false;
        }
        return true;
    }

    public _OnServiceEvent(eventName: DataModuloMain.TipoRequestMonitorId, reloadId?: number, error?: Error): void {
        super._OnServiceEvent(eventName);
        switch (eventName) {
            case Entidad.CTipoRequest.Alumno:
            case Entidad.CTipoRequest.Licencia:
                if (!this.RefreshCurrentAlumnos()) return;
                TarjetasDisponibles["palumnoinfogeneral"].cardColl._UpdateCardData(false, this.currentAlumnos[0]);
                TarjetasDisponibles["palumnoinfoextra"].cardColl._UpdateCardData(false, this.currentAlumnos[0]);
                TarjetasDisponibles["palumnotutores"].cardColl._UpdateCardData(false, this.currentAlumnos[0]);
                TarjetasDisponibles["palumnoexpedientes"].cardColl._UpdateCardData(false, this.currentAlumnos[0]);
                TarjetasDisponibles["palumnocomunicados"].cardColl._UpdateCardData(false, this.currentAlumnos[0]);
                TarjetasFinanzasTabs["palumnoedocta"].cardColl._UpdateCardData(false, this.currentAlumnos[0]);
                TarjetasDisponibles["palumnofotos"].cardColl._UpdateCardData(false, this.currentAlumnos[0]);
                TarjetasDisponibles["palumnohorariosgrupos"].cardColl._UpdateCardData(false, this.currentAlumnos);
                TarjetasDisponibles["palumnologros"].cardColl._UpdateCardData(false, this.currentAlumnos);
                TarjetasFinanzasTabs["palumnocargosdescuentos"].cardColl._UpdateCardData(false, this.currentAlumnos);
                TarjetasCalificacionesTabs["palumnomaterias"].cardColl._UpdateCardData(false, this.currentAlumnos);
                TarjetasCalificacionesTabs["palumnoboletas"].cardColl._UpdateCardData(false, this.currentAlumnos[0]);
                this.UpdateCardInfo();
                break;
            case Entidad.CTipoRequest.TutorAsignacion:
                if (!this.RefreshCurrentAlumnos()) return;
                this.UpdateCardInfo();
                break;
            case Entidad.CTipoRequest.HorarioAlumno:
                if (!this.RefreshCurrentAlumnos()) return;
                this.UpdateCardInfo();
                break;
        }
    }

    private ChangeHeaderInteraction(quit: boolean) {
        this.ctrlCardsList._ControlContainerSelection.selectAll(":scope >.item").select("wc-ic-collapse").classed("hide", quit);
        this.ctrlCardsList._ControlContainerSelection.selectAll(":scope >.item").style("pointer-events", quit ? "none" : "auto");
    }

    private manageCardError(isError: boolean, itemSelection: TSelectionHTML<"div", any, any>, reason: string, cardKey: CardsKeys) {
        let isErrorPriority = false
        if (ErrorsPriority.get(cardKey)) {
            isErrorPriority = true
            if (!isError) {
                this.errorsCardsPriority = this.errorsCardsPriority.filter(d => d.CardKey != cardKey);
            } else {
                let i = this.errorsCardsPriority.find(d => d.CardKey == cardKey);
                if (!i) {
                    this.errorsCardsPriority.push({ CardKey: cardKey, Priority: ErrorsPriority.get(cardKey) })
                }
            }
        }
        if (itemSelection) {
            itemSelection.classed("itemlist_error", isError);
            let div_infoerror = itemSelection.select<HTMLDivElement>(".mark_infoerror");
            if (!div_infoerror.node()) {
                div_infoerror = itemSelection.append("div").classed("mark_infoerror hide", true)
                if (isErrorPriority) {
                    let priorityNum = this.errorsCardsPriority.sort((a, b) => b.Priority - a.Priority).findIndex(d => d.CardKey == cardKey) + 1;
                    div_infoerror.append("div").text(priorityNum).classed("circle-priority", true);
                } else {
                    div_infoerror.append("img")
                        .attr("src", UIUtilIconResources.CGeneral.InfoMark)
                        .attr("draggable", false);
                }
                div_infoerror.append("wc-tooltip")
                    .attr("max-width", "300px");
            }
            div_infoerror.classed("hide", !(isError && Boolean(reason)));
            if (isError && reason) {
                div_infoerror.select("wc-tooltip")
                    .text(reason);
            }
        }
        this.UpdateCardsPriorityIndicator();
    }

    private UpdateCardsPriorityIndicator() {
        for (const key in TarjetasDisponibles) {
            if (ErrorsPriority.get(key as CardsKeys)) {
                let itemSelection = this.ctrlCardsList._getItemList(key)?.CollapserItemSelection;
                if (itemSelection) {
                    let div_infoerror = itemSelection.select(".mark_infoerror");
                    if (div_infoerror.node()) {
                        let priorityIndicator = div_infoerror.select(".circle-priority");
                        if (priorityIndicator.node()) {
                            let priorityNum = this.errorsCardsPriority.sort((a, b) => b.Priority - a.Priority).findIndex(d => d.CardKey == key) + 1;
                            priorityIndicator.text(priorityNum);
                        }
                    }
                }
            }
        }
    }

    private InitPanel() {
        this.windowContent.classed("panel", true);
        this.ctrCardInfo = new CardInfoV2()._SetTitulo(null)
            ._MainInfo_CreateImageControl({
                AspectRatio: "cover", ControlForm: "circle", Border: { Width: 5, Color: null }, SpinnerWidth: 5,
                UpdateImageCallbackService: async (src64: string, file: File) => {
                    let res = await UIUtilViewAlumno._SvActualizarFoto(this.currentAlumnos[0], src64);
                    if (res.Resultado > 0) {
                        return true;
                    }
                    if (res.Mensaje) {
                        this.notificacion._Mostrar(res.Mensaje, (res.Resultado > 0 ? "INFO" : "ADVERTENCIA"))
                    }
                    return false;
                }
            });
        this.ctrlFinanzasTabs = new Tabs();
        this.ctrlCalificacionesTabs = new Tabs();
        this.ctrCardInfo._CardSelection.select(".content").classed("infoalumno", true);
        this.ctrCardInfo._CardSelection.append("div").classed("content listaalumnos", true)
        this.windowContent.append(() => this.ctrCardInfo._CardNode);

        // TEMPORAL ???
        // if (TarjetasDisponibles["palumnoinfogeneral"].cardColl == null) {
        TarjetasDisponibles["palumnoinfogeneral"].cardColl = new UIPanelCardAlumnosInfoGralPreview(CModulo.PanelInfoGeneral);
        TarjetasDisponibles["palumnoinfoextra"].cardColl = new UIPanelCardAlumnosInfoExtra(CModulo.Alumnos);
        TarjetasDisponibles["palumnotutores"].cardColl = new UIPanelCardAlumnosInfoTutores(CModulo.PanelTutores);
        TarjetasDisponibles["palumnoexpedientes"].cardColl = new UIPanelCardAlumnosExpediente(CModulo.PanelExpediente);
        TarjetasDisponibles["palumnologros"].cardColl = new UIPanelCardAlumnosLogros(CModulo.PanelNinioLogros);
        TarjetasDisponibles["palumnocomunicados"].cardColl = new UIPanelCardAlumnosCircular(CModulo.PanelCircular);
        TarjetasDisponibles["palumnohorariosgrupos"].cardColl = new UIPanelCardAlumnosInscripcionPreview();
        TarjetasFinanzasTabs[CFinanzasTabs.CargosyDesc].cardColl = new UIPanelCardAlumnosCargosDescuentos(CModulo.PanelFinanzasCargosDescuentos);
        TarjetasFinanzasTabs[CFinanzasTabs.EdoCuenta].cardColl = new UIPanelCardAlumnosEstadoCuenta(CModulo.PanelFinanzasEdoCuenta);
        TarjetasDisponibles["palumnofotos"].cardColl = new UIPanelCardAlumnosFotos(CModulo.Alumnos);
        TarjetasCalificacionesTabs[CCalificacionesTabs.Asignaciones].cardColl = new UIPanelCardAlumnosMaterias(CModulo.PanelAlumnoMaterias);
        TarjetasCalificacionesTabs[CCalificacionesTabs.Boletas].cardColl = new UIPanelCardAlumnosBoletas(CModulo.PanelBoletas);

        this.AllCardsRemoveHeader();
        this.CardHundredPercentHeight("palumnotutores");
        this.CardHundredPercentHeight("palumnologros");
        this.CardHundredPercentHeight("palumnoexpedientes");
        this.CardHundredPercentHeight("palumnocomunicados");
        this.CardHundredPercentHeight("palumnohorariosgrupos");
        this.CardHundredPercentHeight("palumnofotos");
        // }

        TarjetasDisponibles["palumnoinfogeneral"].cardColl._OnEditarChange((editing) => {
            if (this.currentMode == "All") {
                this.ChangeHeaderInteraction(editing);
            }
        });
        TarjetasDisponibles["palumnoinfoextra"].cardColl._OnEditarChange((editing) => {
            if (this.currentMode == "All") {
                this.ChangeHeaderInteraction(editing);
            }
        });
        TarjetasDisponibles["palumnohorariosgrupos"].cardColl._OnEditarChange(editing => {
            if (this.currentMode == "All") {
                this.ChangeHeaderInteraction(editing);
            }
        })

        // Control de Error en tarjetas, para su marcaje en rojo
        for (const key in TarjetasDisponibles) {
            const Tarjeta = TarjetasDisponibles[key as CardsKeys];
            if (Tarjeta.errList) {
                Tarjeta.cardColl._OnCardError((isError, reason) => {
                    let itemSelection = this.ctrlCardsList._getItemList(key)?.CollapserItemSelection;
                    this.manageCardError(isError, itemSelection, reason, (key as CardsKeys));
                })
            }
        }

        //CtrlCardList Select/Collapse
        this.ctrlCardsList = new ExpanderItemListCardStyle({
            OnCreateTemplate: (id: CardsKeys, item) => item.classed({
                "palumnotutores": "card_tutores",
                "palumnohorariosgrupos": "card_horarios",
                "palumnoinfogeneral": "card_infogeneral",
            }[id] || "", true), // Clases para Tutorial
            OnSelectItem: (id: CardsKeys & CardTabsFinanzasKeys & CardTabsCalificacionesKeys, item) => {
                switch (id) {
                    case "palumnofinanzasmain":
                    case CFinanzasTabs.CargosyDesc:
                    case CFinanzasTabs.EdoCuenta:
                        this.ExpandFinanzasTab();
                        break;
                    case "palumnocalificaciones":
                    case CCalificacionesTabs.Asignaciones:
                    case CCalificacionesTabs.Boletas:
                        this.ExpandCalificacionesTab();
                        break;
                    default:
                        this.ExpandCard(id);
                }
            },
            OnCollapseItem: (id: CardsKeys, item) => this.CollapseCard(id)
        })
        this.windowContent.append(() => this.ctrlCardsList._ControlContainerNode);
    }

    private InitInicialParams() {
        const paramsAux = UIWindowManager._GetCurrentParams();
        const success = this.UpdateDataPanelByURLParams(paramsAux);
        if (success) {
            const onchangeparams: ((e: UIWindowManager.HashEvent) => void) = (e) => {
                this.UpdateDataPanelByURLParams(e.detail.Params);
            }
            this["__onchangeparams"] = onchangeparams;
            UIWindowManager._AddOnParamsChangeEventListener(onchangeparams);
        }
    }

    private UpdateDataPanelByURLParams(URLParams: URLSearchParams) {
        const idAlumnos: number[] = URLParams.get("id")?.split(",").map(d => Number(d));
        const mode: Modes = (URLParams.get("mode")?.split(",")[0] as Modes || null);
        this.mapAlumnos.clear();
        if (!idAlumnos.length || mode == null) {
            this.ErrorInPanelsGoBack();
            return false;
        }

        let escuelaVal: number;
        let bolReplaceHash = false;
        //Evaluar aquí las futuras excepciones para AlumnosAsignaciones Ej. Que sean del mismo nivel o grupo.
        idAlumnos.forEach(idAlumno => {
            const alumno = _DICC_ALUMNO.get(idAlumno);
            //Se evalua que si el alumno existe debe de ser de la misma escuela que todos los anteriores
            if (alumno && (alumno.IdKinder == escuelaVal || this.mapAlumnos.size == 0)) {
                escuelaVal = alumno.IdKinder;
                this.mapAlumnos.set(idAlumno, alumno);
            } else if (alumno && alumno.IdKinder !== escuelaVal) { // Se evalua el error, si el alumno no esta en la misma escuela que los anteriores
                console.warn(`Id => ${alumno.IdChild} - ${alumno.NombreCompleto} no se encuentra en la misma escuela`);
                bolReplaceHash = true;
            } else if (!alumno) { //Se evalua el error si el alumno no existe
                console.warn(`Id => ${idAlumno} no existe`);
                bolReplaceHash = true;
            }
        });

        if (![...this.mapAlumnos.values()].length) {
            this.ErrorInPanelsGoBack(UIUtilLang._GetUIString("general", "notif_infonodisponible"));
            return false;
        }

        //Se evalua si la tarjeta del modo al que se quiere acceder acepta multiples alumnos
        if (mode !== "palumnologros" && mode !== "palumnohorariosgrupos" && mode !== CFinanzasTabs.CargosyDesc && mode !== CCalificacionesTabs.Asignaciones) {
            let idAlumnoFirst = [...this.mapAlumnos.values()][0].IdChild;
            for (let key of this.mapAlumnos.keys()) {
                if (key !== idAlumnoFirst) {
                    this.mapAlumnos.delete(key);
                    bolReplaceHash = true;
                }
            }
        }

        const alumnosChanged = idAlumnos[0] != this.currentIdAlumnos[0];
        const modeChanged = mode != this.currentMode;
        this.currentMode = mode;
        this.currentAlumnos = [...this.mapAlumnos.values()];
        this.currentIdAlumnos = [...this.mapAlumnos.keys()];

        if (!this.currentAlumnos.length) {
            this.ErrorInPanelsGoBack(UIUtilLang._GetUIString("general", "notif_infonodisponible"));
            return false;
        }
        if (bolReplaceHash) {
            UIWindowManager._DoHashReplace("alumnos/alumnos/panel", {
                mode: mode,
                id: [...this.mapAlumnos.keys()]
            });
            //Poner return false??
        }

        this.RefreshFinanzasTabs();
        this.RefreshCalificacionesTabs();
        if (this.RefreshCardList((!alumnosChanged && !modeChanged), mode)) {
            this.ManageModeInHash(modeChanged);
            this.UpdateCardInfo();
        }
        return true;
    }

    private ErrorInPanelsGoBack(message: string = "") {
        if (message) {
            NotificacionV2._Mostrar(message, "ADVERTENCIA");
        }
        UIWindowManager._HistoryBackEval("home");
    }

    private UpdateCardInfo() {
        this.ctrCardInfo._CardSelection.select<HTMLDivElement>(".content.infoalumno").classed("hide", this.currentAlumnos.length != 1);
        const content2 = this.ctrCardInfo._CardSelection.select<HTMLDivElement>(".content.listaalumnos").classed("hide", this.currentAlumnos.length == 1);
        if (this.currentAlumnos.length === 1) {
            let strEdad: string;
            let strCumpleanios: string;

            let dtNacimiento: DateV2;
            let timeZone: string;

            if (this.currentAlumnos[0].FechaNacimiento) {
                timeZone = DataModuloMain._GetDataValueFieldByName("Escuela", this.currentAlumnos[0].IdKinder, "ZonaHoraria");
                dtNacimiento = new DateV2(this.currentAlumnos[0].FechaNacimiento)
                    ._SetTimeZone(timeZone);
            }

            if (dtNacimiento) {
                strCumpleanios = UIUtilTime._DateFormatStandar(dtNacimiento, "d MMM yyyy");
                strEdad = UIUtilTime._GetTimeElapsedAFmt(dtNacimiento, new DateV2()._SetTimeZone(timeZone, true), { TimeA: "Year", TimeB: "Month", IgnoreZeros: "allexceptmin" });
            } else {
                strCumpleanios = UIUtilLang._GetUIString("alumnos", "tag_sinfechanac");
                strEdad = UIUtilLang._GetUIString("alumnos", "tag_sinedad");
            }

            const hermanos = Array.from(_LOCALDATA_GetHermanos(this.currentIdAlumnos[0]).values());

            this.ctrCardInfo
                ._MainInfo_SetDescripcionGeneralPrimaryInfo(this.currentAlumnos[0].NombreCompleto)
                ._MainInfo_SetURLResourceToImageControl(this.currentAlumnos[0].getThumbnailURL || UIUtilIconResources.CGeneral.User)
                ._MainInfo_SetColors(UIUtilViewAlumno._ALUMNOSEXOCOLOR_2[this.currentAlumnos[0].Sexo], UIUtilViewAlumno._ALUMNOSEXOCOLOR[this.currentAlumnos[0].Sexo])
                ._MainInfo_SetDescripcionGeneralSecondaryInfo([
                    {
                        TopicTag: UIUtilLang._GetUIString("general", "edad"),
                        Description: strEdad
                    },
                    {
                        TopicTag: UIUtilLang._GetUIString("alumnos", "d_field_strgrupoprincipal"),
                        Description: (elemento) => {
                            let gruposAlumnoArray = Array.from(_LOCALDATA_GetGruposHorariosDeAlumno(this.currentIdAlumnos[0]).values()).filter(d => d.EsPrincipal);
                            if (gruposAlumnoArray.length) {
                                gruposAlumnoArray.forEach((d, i, arr) => {
                                    elemento.append(() => UIUtilGeneral._CreateLinkeableElement(UIUtilViewGrupos._GetLblGrupoName(d, true), `#escuelas/grupos/panel--id=${d.IdGrupo}&mode=All`).node());
                                    if (arr[i + 1])
                                        elemento.append("span").text(", ");
                                })
                            }
                            else
                                elemento.text(UIUtilLang._GetUIString("general", "sinasignaciones"));
                        }
                    },
                    {
                        TopicTag: UIUtilLang._GetUIString("grupos", "d_field_nombrekinder"),
                        Description: (elemento) => {
                            elemento.append(() => UIUtilGeneral._CreateLinkeableElement(this.currentAlumnos[0].NombreKinder, `#escuelas/escuelas/panel--id=${this.currentAlumnos[0].IdKinder}`).node());
                        }
                    },
                    {
                        TopicTag: UIUtilLang._GetUIString("alumnos", "tag_licencia"),
                        Description: (elemento) => {
                            const visible = ![CAlumnoEdoLicencia.SinLicencia, null].includes(this.currentAlumnos[0].getEdoLicencia)
                            d3.select(elemento.node().parentElement).classed("hide", !visible);
                            if (!visible) return
                            elemento
                                .text(UIUtilViewData._GetStr_AlumnoLicenciaEdo(this.currentAlumnos[0].getEdoLicencia))
                                .style("color", UIUtilViewAlumno._UILicenciaColorBackLigth(this.currentAlumnos[0].getEdoLicencia))
                        }
                    }
                ])
                ._SetSections([
                    {
                        ID: "cumpleaños",
                        Title: "Cumpleaños",
                        GetTemplateContent: (seccion) => {
                            seccion.html(`<b>${UIUtilLang._GetUIString("alumnos", "tag_cumpleanios")}</b><label class="section_cumpleaños"></label>`)
                        },
                        OnUpdate: (seccion) => {
                            seccion.select(".section_cumpleaños").text(strCumpleanios);
                        }
                    },
                    {
                        ID: "hermanos",
                        Title: "Hermanos",
                        GetTemplateContent: (seccion) => {
                            seccion.html(`
                                        <b>${UIUtilLang._GetUIString("alumnos", "tag_hermanos")}</b>
                                        <div class="section_hermanos"></label>
                                    `)
                        },
                        OnUpdate: (seccion) => {
                            const container = seccion.select(":nth-child(2)");
                            let lblNoTiene = container.select<HTMLLabelElement>(".notiene").node();
                            if (hermanos.length > 0) {
                                lblNoTiene?.remove();
                                container.style("justify-content", null).style("min-height", "90px").style("padding", "5px 0").style("overflow", "hidden");
                                //setTimeout(() => {
                                container
                                    .style("overflow", null);
                                //}, 300);
                            } else if (hermanos.length == 0 && !lblNoTiene) {
                                lblNoTiene = container
                                    .append("label")
                                    .attr("class", "notiene")
                                    .node();

                                //setTimeout(() => {
                                container
                                    .style("justify-content", "start")
                                    .style("min-height", null)
                                    .style("padding", null)
                                lblNoTiene.textContent = UIUtilLang._GetUIString("alumnos", "tag_sinhermanos");
                                //}, 300);
                            }

                            let tooltip = container.select<HTMLTooltipComponent>("wc-tooltip").node();
                            if (!tooltip) {
                                tooltip = container
                                    .append<HTMLTooltipComponent>("wc-tooltip")
                                    .attr("max-width", "250px")
                                    .node()
                            }
                            container
                                .selectAll<HTMLDivElement, IAlumno>(":scope > .item_carnal")
                                .data(hermanos, d => d.IdChild + "")
                                .join(
                                    enter => {
                                        let item = enter.append("div")
                                            .attr("class", "item_carnal")
                                        item.append("label");
                                        item.append<HTMLImage2Component>("wc-img")
                                            .attr("spinner-border-width", "2px")
                                            .attr("spinner-dim", "72px")
                                            .style("border-width", 0)

                                        setTimeout(() => {
                                            item.select("wc-img")
                                                .style("border-width", null)
                                        }, 10);
                                        return this.UI_UpdateItemHermano(item, tooltip);
                                    },
                                    update => {
                                        let updated = this.UI_UpdateItemHermano(update, tooltip);
                                        return updated;
                                    },
                                    exit => {
                                        exit.remove();
                                    }
                                )

                            this.ctrCardInfo._RefeshTooltipResumenGral();
                        }
                    }
                ])
        } else if (this.currentAlumnos.length > 1) {
            if (!this.alumnosList) {
                content2
                    .style("gap", "var(--padding2)")
                    .append("b");
                this.alumnosList = new List<IAlumno>()
                    ._SetCreateTemplate((container) => {
                        container
                            .classed(UIUtilGeneral.FBoxOrientation.Horizontal, true)
                            .classed(UIUtilGeneral.FBoxAlign.StartCenter, true)
                            .style("gap", "var(--padding2)");
                        container.append<HTMLImage2Component>("wc-img")
                            .attr("spinner-border-width", "2px")
                            .attr("spinner-dim", "15px")
                            .attr("default-src", UIUtilIconResources.CMenuIco.Alumno)
                            .style("width", "20px")
                            .style("height", "20px")
                            .style("border", "1px solid var(--color_borderbox1)")
                            .style("border-radius", "50%");
                        container.append("label");
                    })
                    ._SetUpdateItem((container, d) => {
                        container.select<HTMLImage2Component>("wc-img")
                            .attr("spinner-border-color", UIUtilViewAlumno._ALUMNOSEXOCOLOR_2[d.Sexo])
                            .attr("src", d.getThumbnailURL)
                        container.select("label")
                            .text(d.NombreCompleto);
                    })
                    ._SetParent(content2);

                this.alumnosList
                    ._ListContainerSelection
                    .style("border-top", "1px solid var(--color_borderbox1)");
            }
            content2.select(":scope > b")
                .text(`${UIUtilLang._GetMenuLabel("alumnos")} (${this.currentAlumnos.length})`);
            this.alumnosList
                ._SetItems(this.currentAlumnos);
        }
    }

    private UI_UpdateItemHermano(itemsContainer: TSelectionHTML<"div", IAlumno>, tooltip: HTMLTooltipComponent): TSelectionHTML<"div", IAlumno> {
        return itemsContainer
            .each((alumno, i, arrItems) => {
                const itemContainer = arrItems[i];
                const ctrlFoto = itemContainer.querySelector<HTMLImage2Component>("wc-img");
                if (d3.select(itemContainer).select("a").node()) {
                    d3.select(itemContainer).select("a").remove();
                }
                d3.select(itemContainer).append(() => UIUtilGeneral._CreateLinkeableElement(d3.select(ctrlFoto), `#alumnos/alumnos/panel--id=${alumno.IdChild}&mode=All`).node());
                itemContainer.querySelector("label").textContent = alumno.Nombre;
                ctrlFoto.style.borderColor = UIUtilViewAlumno._ALUMNOSEXOCOLOR[alumno.Sexo];
                ctrlFoto._SpinnerBorderColor = UIUtilViewAlumno._ALUMNOSEXOCOLOR_2[alumno.Sexo];
                ctrlFoto._Src = alumno.getThumbnailURL;
                tooltip._SetObservedElementsAdvanced({
                    Target: itemContainer,
                    Text: alumno.NombreCompleto,
                });

                //Util.fn_ElementAdd_LinkToGoToPanel(d3.select(itemContainer), "alumnos/alumnos/panel", alumno.IdChild);
                UIUtilViewAlumno._UIAlumnoLicenciaInfo(itemContainer, alumno.getEdoLicencia);
            })
    }

    private RefreshCardList(useAnimation = true, mode: Modes) {
        let itemsCardList: IExpanderItemListItemConfig[] = [];

        for (const key in TarjetasDisponibles) {
            const Tarjeta = TarjetasDisponibles[key as CardsKeys];
            if ((mode == "All" || mode == key)) {
                let infoExtra = DataModuloMain._GetReqDataArrayById(Entidad.CTipoRequest.EscuelaAlumnoInfoExtra).filter(d => d.IdEscuela == this.currentAlumnos[0].IdKinder)
                if (key == "palumnoinfoextra" && infoExtra.length < 1) {
                    continue;
                }
                if (this.PANELS_VerifyPermissionToCreateCard(key as CardsKeys, this.currentAlumnos)) {
                    let label: string;
                    if (Tarjeta.langKey) {
                        label = UIUtilLang._GetUIString(Tarjeta.langKey, "card_headertitle")
                    } else {
                        label = UIUtilLang._GetUIString(Entidad.CModulo[Tarjeta.modulo], "card_headertitle")
                    }
                    const getContentSelection = () => {
                        switch (key as CardsKeys) {
                            case "palumnofinanzasmain":
                                return this.ctrlFinanzasTabs._ContainerSelection;
                                break;
                            case "palumnocalificaciones":
                                return this.ctrlCalificacionesTabs._ContainerSelection;
                                break;
                            default:
                                return Tarjeta.cardColl._CardSelection
                                break;
                        }
                    }
                    itemsCardList.push({
                        Id: key,
                        Label: label,
                        ContentSelection: getContentSelection()
                    })
                    if (key !== "palumnofinanzasmain" && key !== "palumnocalificaciones") Tarjeta.cardColl._SetTitulo(label);
                    if (key == "palumnofinanzasmain") {
                        TarjetasFinanzasTabs["palumnocargosdescuentos"].cardColl._SetTitulo(UIUtilLang._GetUIString("panelfinanzascargosdescuentos", "card_headertitle"));
                        TarjetasFinanzasTabs["palumnoedocta"].cardColl._SetTitulo(UIUtilLang._GetUIString("panelfinanzasedocuenta", "card_headertitle"));
                    }
                    if (key == "palumnocalificaciones") {
                        TarjetasCalificacionesTabs["palumnomaterias"].cardColl._SetTitulo(UIUtilLang._GetUIString("panelalumnomaterias", "card_headertitle"));
                        TarjetasCalificacionesTabs["palumnoboletas"].cardColl._SetTitulo(UIUtilLang._GetUIString("panelboletas", "card_headertitle"));
                    }
                }
            }
        }

        if (mode == CFinanzasTabs.CargosyDesc && this.PermissionCargosDesc) {
            itemsCardList.push({
                Id: mode,
                Label: UIUtilLang._GetUIString("alumnopanelfinanzasmain", "card_headertitle"),
                ContentSelection: this.ctrlFinanzasTabs._ContainerSelection
            })
            TarjetasFinanzasTabs["palumnocargosdescuentos"].cardColl._SetTitulo(UIUtilLang._GetUIString("panelfinanzascargosdescuentos", "card_headertitle"));
        }

        if (mode == CFinanzasTabs.EdoCuenta && this.PermissionEdoCuenta) {
            itemsCardList.push({
                Id: mode,
                Label: UIUtilLang._GetUIString("alumnopanelfinanzasmain", "card_headertitle"),
                ContentSelection: this.ctrlFinanzasTabs._ContainerSelection
            })
            TarjetasFinanzasTabs["palumnoedocta"].cardColl._SetTitulo(UIUtilLang._GetUIString("panelfinanzasedocuenta", "card_headertitle"));
        }

        if (mode == CCalificacionesTabs.Asignaciones && this.PermissionAsignaciones) {
            itemsCardList.push({
                Id: mode,
                Label: "Calificaciones",
                ContentSelection: this.ctrlCalificacionesTabs._ContainerSelection
            })
            TarjetasCalificacionesTabs["palumnomaterias"].cardColl._SetTitulo(UIUtilLang._GetUIString("panelalumnomaterias", "card_headertitle"));
        }

        if (mode == CCalificacionesTabs.Boletas && this.PermissionBoletas) {
            itemsCardList.push({
                Id: mode,
                Label: "Calificaciones",
                ContentSelection: this.ctrlCalificacionesTabs._ContainerSelection
            })
            TarjetasCalificacionesTabs["palumnoboletas"].cardColl._SetTitulo(UIUtilLang._GetUIString("panelboletas", "card_headertitle"));
        }

        //No se añadieron ninguna card a la lista, eso puede ser por que no se tuvo permiso de ninguna o no existe el mode
        if (!itemsCardList.length) {
            this.ErrorInPanelsGoBack(UIUtilLang._GetUIString("general", "notif_infonodisponible"));
            return false;
        }

        this.ctrlCardsList
            ._SetItems(itemsCardList, useAnimation);
        return true;
    }

    private ManageModeInHash(modeChanged: boolean) {
        //Cuando mode es All
        if (this.currentMode == "All") {
            this.ChangeHeaderInteraction(false);
            this.manageSyncBnt();
            this.ctrlCardsList._RemoveCurrentItemFocus();
            for (const key in TarjetasDisponibles) {
                this.CollapseCard(key as CardsKeys);
            }
            TarjetasDisponibles["palumnoinfogeneral"].cardColl._UpdateCardData(true, this.currentAlumnos[0]);
            TarjetasDisponibles["palumnoinfoextra"].cardColl._UpdateCardData(true, this.currentAlumnos[0]);
            TarjetasDisponibles["palumnotutores"].cardColl._UpdateCardData(true, this.currentAlumnos[0]);
            TarjetasDisponibles["palumnoexpedientes"].cardColl._UpdateCardData(true, this.currentAlumnos[0]);
            TarjetasDisponibles["palumnocomunicados"].cardColl._UpdateCardData(true, this.currentAlumnos[0])
            TarjetasDisponibles["palumnofotos"].cardColl._UpdateCardData(true, this.currentAlumnos[0]);
            TarjetasFinanzasTabs["palumnoedocta"].cardColl._UpdateCardData(true, this.currentAlumnos[0]);
            TarjetasCalificacionesTabs["palumnoboletas"].cardColl._UpdateCardData(true, this.currentAlumnos[0]);
            TarjetasDisponibles["palumnologros"].cardColl._UpdateCardData(true, this.currentAlumnos);
            TarjetasDisponibles["palumnohorariosgrupos"].cardColl._UpdateCardData(true, this.currentAlumnos);
            TarjetasFinanzasTabs["palumnocargosdescuentos"].cardColl._UpdateCardData(true, this.currentAlumnos);
            TarjetasCalificacionesTabs["palumnomaterias"].cardColl._UpdateCardData(true, this.currentAlumnos);

            (TarjetasDisponibles["palumnohorariosgrupos"].cardColl as UIPanelCardAlumnosInscripcionPreview)._ExitWhenFinish(false);
            return;
        }

        //Existe un mode
        this.ChangeHeaderInteraction(true);
        this.manageSyncBnt();

        switch (this.currentMode) {
            case "palumnofinanzasmain":
                TarjetasFinanzasTabs[CFinanzasTabs.CargosyDesc].cardColl._UpdateCardData(true, this.currentAlumnos);
                TarjetasFinanzasTabs[CFinanzasTabs.EdoCuenta].cardColl._UpdateCardData(true, this.currentAlumnos[0]);
                break;
            case CFinanzasTabs.CargosyDesc:
                TarjetasFinanzasTabs[CFinanzasTabs.CargosyDesc].cardColl._UpdateCardData(true, this.currentAlumnos);
                break;
            case CFinanzasTabs.EdoCuenta:
                TarjetasFinanzasTabs[CFinanzasTabs.EdoCuenta].cardColl._UpdateCardData(true, this.currentAlumnos[0]);
                break;
            case "palumnocalificaciones":
                TarjetasCalificacionesTabs[CCalificacionesTabs.Asignaciones].cardColl._UpdateCardData(true, this.currentAlumnos);
                TarjetasCalificacionesTabs[CCalificacionesTabs.Boletas].cardColl._UpdateCardData(true, this.currentAlumnos[0]);
                break;
            case CCalificacionesTabs.Asignaciones:
                TarjetasCalificacionesTabs[CCalificacionesTabs.Asignaciones].cardColl._UpdateCardData(true, this.currentAlumnos);
                break;
            case CCalificacionesTabs.Boletas:
                TarjetasCalificacionesTabs[CCalificacionesTabs.Boletas].cardColl._UpdateCardData(true, this.currentAlumnos[0]);
                break;
            case "palumnologros":
                TarjetasDisponibles[this.currentMode].cardColl._UpdateCardData(true, this.currentAlumnos);
                break;
            case "palumnohorariosgrupos":
                TarjetasDisponibles[this.currentMode].cardColl._UpdateCardData(true, this.currentAlumnos);
                (TarjetasDisponibles["palumnohorariosgrupos"].cardColl as UIPanelCardAlumnosInscripcionPreview)._ExitWhenFinish(true);
                break;
            case "palumnoinfoextra":
            case "palumnoinfogeneral":
                TarjetasDisponibles[this.currentMode].cardColl._UpdateCardData(true, this.currentAlumnos[0]);
                break;
            default:
                TarjetasDisponibles[this.currentMode].cardColl._UpdateCardData(true, this.currentAlumnos[0]);
                break;
        }


        if (modeChanged) {
            this.ctrlCardsList._SetIDSelected(this.currentMode);
        }

        if (this.currentMode == "palumnoinfogeneral" || this.currentMode == "palumnoinfoextra" || this.currentMode == "palumnohorariosgrupos") {
            TarjetasDisponibles[this.currentMode].cardColl._EditarCard();
        }
    }

    private RefreshFinanzasTabs() {
        this.ctrlFinanzasTabs._SetTabs([
            {
                ID: CFinanzasTabs.CargosyDesc,
                TabName: "Cargos y descuentos",
                Content: TarjetasFinanzasTabs[CFinanzasTabs.CargosyDesc].cardColl._CardSelection,
                OnFocus: (content) => TarjetasFinanzasTabs[CFinanzasTabs.CargosyDesc].cardColl._Expandir(),
                OnUnFocus: (content) => TarjetasFinanzasTabs[CFinanzasTabs.CargosyDesc].cardColl._CancelarEdicionCard()._Colapsar()
            },
            {
                ID: CFinanzasTabs.EdoCuenta,
                TabName: "Estado de cuenta",
                Content: TarjetasFinanzasTabs[CFinanzasTabs.EdoCuenta].cardColl._CardSelection,
                OnFocus: (content) => TarjetasFinanzasTabs[CFinanzasTabs.EdoCuenta].cardColl._Expandir(),
                OnUnFocus: (content) => TarjetasFinanzasTabs[CFinanzasTabs.EdoCuenta].cardColl._CancelarEdicionCard()._Colapsar(),
            },
        ].filter(d => (
            (d.ID == CFinanzasTabs.CargosyDesc && this.PermissionCargosDesc && (this.currentMode == "All" || this.currentMode == "palumnofinanzasmain" || this.currentMode == CFinanzasTabs.CargosyDesc))
            || (d.ID == CFinanzasTabs.EdoCuenta && this.PermissionEdoCuenta && (this.currentMode == "All" || this.currentMode == "palumnofinanzasmain" || this.currentMode == CFinanzasTabs.EdoCuenta))
        )));
    }

    private ExpandFinanzasTab() {
        //Se colapsan las expandidas antes de expandir la tarjeta
        for (const key in TarjetasDisponibles) {
            if (key !== "palumnofinanzasmain" && key !== "palumnocalificaciones") {
                const expanded = TarjetasDisponibles[key as CardsKeys].cardColl._expanded;
                if (expanded) TarjetasDisponibles[key as CardsKeys].cardColl._Colapsar();
            }
        }
        for (const key in TarjetasFinanzasTabs) {
            const expanded = TarjetasFinanzasTabs[key as CardTabsFinanzasKeys].cardColl._expanded;
            if (key !== this.currentMode && expanded) {
                TarjetasFinanzasTabs[key as CardTabsFinanzasKeys].cardColl._Colapsar();
                this.ctrlFinanzasTabs._ClearSelection();
            }
        }

        for (const key in TarjetasCalificacionesTabs) {
            const expanded = TarjetasCalificacionesTabs[key as CardTabsCalificacionesKeys].cardColl._expanded;
            if (key !== this.currentMode && expanded) {
                TarjetasCalificacionesTabs[key as CardTabsCalificacionesKeys].cardColl._Colapsar();
                this.ctrlCalificacionesTabs._ClearSelection();
            }
        }

        switch (this.currentMode) {
            case "All":
            case "palumnofinanzasmain":
                if (!this.ctrlFinanzasTabs._CurrentTabIDSelected) {
                    this.ctrlFinanzasTabs._SetSelectedTab(this.firstTabFinanzasAvailable);
                }
                break;
            case CFinanzasTabs.CargosyDesc:
            case CFinanzasTabs.EdoCuenta:
                this.ctrlFinanzasTabs._SetSelectedTab(this.currentMode);
                break;
            default:
                console.warn("Mode Err ExpandTab");
                break;
        }
    }

    private RefreshCalificacionesTabs() {
        this.ctrlCalificacionesTabs._SetTabs([
            {
                ID: CCalificacionesTabs.Asignaciones,
                TabName: "Materias",
                Content: TarjetasCalificacionesTabs[CCalificacionesTabs.Asignaciones].cardColl._CardSelection,
                OnFocus: (content) => TarjetasCalificacionesTabs[CCalificacionesTabs.Asignaciones].cardColl._Expandir(),
                OnUnFocus: (content) => TarjetasCalificacionesTabs[CCalificacionesTabs.Asignaciones].cardColl._CancelarEdicionCard()._Colapsar()
            },
            {
                ID: CCalificacionesTabs.Boletas,
                TabName: "Calificaciones al día",
                Content: TarjetasCalificacionesTabs[CCalificacionesTabs.Boletas].cardColl._CardSelection,
                OnFocus: (content) => TarjetasCalificacionesTabs[CCalificacionesTabs.Boletas].cardColl._Expandir(),
                OnUnFocus: (content) => TarjetasCalificacionesTabs[CCalificacionesTabs.Boletas].cardColl._CancelarEdicionCard()._Colapsar()
            }
        ].filter(d => (
            (d.ID == CCalificacionesTabs.Asignaciones && this.PermissionAsignaciones && (this.currentMode == "All" || this.currentMode == "palumnocalificaciones" || this.currentMode == CCalificacionesTabs.Asignaciones))
            || (d.ID == CCalificacionesTabs.Boletas && this.PermissionBoletas && (this.currentMode == "All" || this.currentMode == "palumnocalificaciones" || this.currentMode == CCalificacionesTabs.Boletas))
        )));
    }

    private ExpandCalificacionesTab() {
        for (const key in TarjetasDisponibles) {
            if (key !== "palumnofinanzasmain" && key !== "palumnocalificaciones") {
                const expanded = TarjetasDisponibles[key as CardsKeys].cardColl._expanded;
                if (expanded) TarjetasDisponibles[key as CardsKeys].cardColl._Colapsar();
            }
        }

        for (const key in TarjetasCalificacionesTabs) {
            const expanded = TarjetasCalificacionesTabs[key as CardTabsCalificacionesKeys].cardColl._expanded;
            if (key !== this.currentMode && expanded) {
                TarjetasCalificacionesTabs[key as CardTabsCalificacionesKeys].cardColl._Colapsar();
                this.ctrlCalificacionesTabs._ClearSelection();
            }
        }

        for (const key in TarjetasFinanzasTabs) {
            const expanded = TarjetasFinanzasTabs[key as CardTabsFinanzasKeys].cardColl._expanded;
            if (key !== this.currentMode && expanded) {
                TarjetasFinanzasTabs[key as CardTabsFinanzasKeys].cardColl._Colapsar();
                this.ctrlFinanzasTabs._ClearSelection();
            }
        }

        switch (this.currentMode) {
            case "All":
            case "palumnocalificaciones":
                if (!this.ctrlCalificacionesTabs._CurrentTabIDSelected) {
                    this.ctrlCalificacionesTabs._SetSelectedTab(this.firstTabCalificacionesAvailable);
                }
                break;
            case CCalificacionesTabs.Asignaciones:
            case CCalificacionesTabs.Boletas:
                this.ctrlCalificacionesTabs._SetSelectedTab(this.currentMode);
                break;
            default:
                console.warn("Mode Err ExpandTab");
        }
    }

    private ExpandCard(id: CardsKeys) {
        for (const key in TarjetasDisponibles) {
            if (key == "palumnofinanzasmain" || key == "palumnocalificaciones") continue;
            const expanded = TarjetasDisponibles[key as CardsKeys].cardColl._expanded;
            if (key !== id && expanded) {
                TarjetasDisponibles[key as CardsKeys].cardColl._Colapsar();
                return;
            }
            if (key == id && !expanded) {
                TarjetasDisponibles[key].cardColl._Expandir();
            }
        }

        for (const key in TarjetasFinanzasTabs) {
            const expanded = TarjetasFinanzasTabs[key as CardTabsFinanzasKeys].cardColl._expanded;
            if (key !== id && expanded) {
                TarjetasFinanzasTabs[key as CardTabsFinanzasKeys].cardColl._Colapsar();
            }
        }

        for (const key in TarjetasCalificacionesTabs) {
            const expanded = TarjetasCalificacionesTabs[key as CardTabsCalificacionesKeys].cardColl._expanded;
            if (key !== id && expanded) {
                TarjetasCalificacionesTabs[key as CardTabsCalificacionesKeys].cardColl._Colapsar();
            }
        }
    }

    private CollapseCard(id: CardsKeys) {
        if (id !== "palumnofinanzasmain" && id !== "palumnocalificaciones" && TarjetasDisponibles[id]?.cardColl?._expanded) {
            TarjetasDisponibles[id].cardColl._Colapsar();
            return;
        }
        if (id == "palumnocalificaciones" && this.ctrlCalificacionesTabs._CurrentTabIDSelected) {
            this.ctrlCalificacionesTabs._ClearSelection();
            return;
        }
        if (id == "palumnofinanzasmain" && this.ctrlFinanzasTabs._CurrentTabIDSelected) {
            this.ctrlFinanzasTabs._ClearSelection();
            return;
        }
    }

    private manageSyncBnt() {
        switch (this.currentMode) {
            case "All":
                //Se muestran si la selección NO es multiple => En teoría nunca debería de darse el caso múltiple, el panel lo impide
                if (TarjetasDisponibles["palumnologros"].cardColl?._CardActionsNode) {
                    TarjetasDisponibles["palumnologros"].cardColl._CardActionsSelection.classed("hide", this.currentAlumnos.length > 1);
                }
                if (TarjetasDisponibles["palumnohorariosgrupos"].cardColl?._CardActionsNode) {
                    if (this.currentAlumnos.length > 1) {
                        TarjetasDisponibles["palumnohorariosgrupos"].cardColl._CardActionsSelection.select(".btn_sync").style("display", "none", "important");
                    } else {
                        TarjetasDisponibles["palumnohorariosgrupos"].cardColl._CardActionsSelection.select(".btn_sync").style("display", "block");
                    }
                }
                if (TarjetasFinanzasTabs["palumnocargosdescuentos"].cardColl?._CardActionsNode) {
                    TarjetasFinanzasTabs["palumnocargosdescuentos"].cardColl._CardActionsSelection.classed("hide", this.currentAlumnos.length > 1);
                }
                break;
            case "palumnofinanzasmain":
                //Se muestra sin click
                if (TarjetasFinanzasTabs["palumnocargosdescuentos"].cardColl?._CardActionsNode) {
                    TarjetasFinanzasTabs["palumnocargosdescuentos"].cardColl._CardActionsSelection.classed("hide", this.currentAlumnos.length > 1);
                }
                break;
            case "palumnologros":
                //No se muestra si es selección Múltiple
                if (TarjetasDisponibles[this.currentMode].cardColl?._CardActionsNode) {
                    TarjetasDisponibles[this.currentMode].cardColl._CardActionsSelection.classed("hide", this.currentAlumnos.length > 1);
                }
                break;
            case "palumnohorariosgrupos":
                if (TarjetasDisponibles[this.currentMode].cardColl?._CardActionsNode) {
                    if (this.currentAlumnos.length > 1) {
                        TarjetasDisponibles[this.currentMode].cardColl._CardActionsSelection.select(".btn_sync").style("display", "none", "important");
                    } else {
                        TarjetasDisponibles[this.currentMode].cardColl._CardActionsSelection.select(".btn_sync").style("display", "block");
                    }
                }
                break;
            case CFinanzasTabs.CargosyDesc:
                //No se muestra si es selección múltiple
                if (TarjetasFinanzasTabs[this.currentMode].cardColl?._CardActionsNode) {
                    TarjetasFinanzasTabs[this.currentMode].cardColl._CardActionsSelection.classed("hide", this.currentAlumnos.length > 1);
                }
                break;
            case CCalificacionesTabs.Asignaciones:
                if (TarjetasCalificacionesTabs[this.currentMode].cardColl?._CardActionsNode) {
                    TarjetasCalificacionesTabs[this.currentMode].cardColl._CardActionsSelection.classed("hide", this.currentAlumnos.length > 1);
                }
                break;
        }
    }

    private AllCardsRemoveHeader() {
        for (const key in TarjetasDisponibles) {
            if (TarjetasDisponibles[key as CardsKeys].cardColl?._HeaderNode) {
                TarjetasDisponibles[key as CardsKeys].cardColl._HeaderSelection.remove();
                TarjetasDisponibles[key as CardsKeys].cardColl._CardActionsSelection
                    .style("top", "var(--ventanapanel-top-options)")
                    .style("right", "0")
                    .style("padding", "var(--padding2) calc(2 * var(--padding2))");
            }
        }
        for (const key in TarjetasFinanzasTabs) {
            if (TarjetasFinanzasTabs[key as CardTabsFinanzasKeys].cardColl?._HeaderNode) {
                TarjetasFinanzasTabs[key as CardTabsFinanzasKeys].cardColl._HeaderSelection.remove();
                TarjetasFinanzasTabs[key as CardTabsFinanzasKeys].cardColl._CardActionsSelection
                    .style("top", "var(--ventanapanel-toptab-options)")
                    .style("right", "0")
                    .style("padding", "var(--padding2) calc(2 * var(--padding2))");
            }
        }

        for (const key in TarjetasCalificacionesTabs) {
            if (TarjetasCalificacionesTabs[key as CardTabsCalificacionesKeys].cardColl?._HeaderNode) {
                TarjetasCalificacionesTabs[key as CardTabsCalificacionesKeys].cardColl._HeaderSelection.remove();
                TarjetasCalificacionesTabs[key as CardTabsCalificacionesKeys].cardColl?._CardActionsSelection
                    .style("top", "var(--ventanapanel-toptab-options)")
                    .style("right", "0")
                    .style("padding", "var(--padding2) calc(2 * var(--padding2))");
            }
        }
    }

    private CardHundredPercentHeight(id: CardsKeys) {
        if (TarjetasDisponibles[id].cardColl?._CardNode) {
            TarjetasDisponibles[id].cardColl._CardSelection.style("height", "100%");
        }
    }

    private PANELS_VerifyPermissionToCreateCard(keyCard: CardsKeys /*| CardTabsKeys */, dataPanels_0: IAlumno[]): boolean {
        if (dataPanels_0[0]) {
            const idEscuela = dataPanels_0[0].IdKinder
            switch (keyCard) {
                case "palumnoinfogeneral":
                    return this.HasModulePermission(CModulo.PanelInfoGeneral, idEscuela);
                case "palumnotutores":
                    return this.HasModulePermission(CModulo.PanelTutores, idEscuela);
                case "palumnoexpedientes":
                    return this.HasModulePermission(CModulo.PanelExpediente, idEscuela);
                case "palumnohorariosgrupos":
                    return this.HasModulePermission(CModulo.PanelNinioHorario, idEscuela);
                case "palumnologros":
                    return this.HasModulePermission(CModulo.PanelNinioLogros, idEscuela);
                case "palumnocomunicados":
                    return this.HasModulePermission(CModulo.PanelCircular, idEscuela);
                case "palumnofotos":
                    return this.HasModulePermission(CModulo.PanelFotos, idEscuela);
                case "palumnoinfoextra":
                    return true;
                case "palumnofinanzasmain":
                    return (this.HasModulePermission(CModulo.PanelFinanzasCargosDescuentos, idEscuela) || this.HasModulePermission(CModulo.PanelFinanzasEdoCuenta, idEscuela));
                case "palumnocalificaciones":
                    return (this.HasModulePermission(CModulo.PanelAlumnoMaterias, idEscuela));
                default:
                    return false;
            }
        }
        return false;
    }

    private HasModulePermission(modulo: CModulo, idEscuela: number) {
        if (_MODULOS_FINANZAS.includes(modulo) && !UIUtilPermission._HasFinanzasModulesPermissionFromSchools(idEscuela)) {
            return false;
        }
        else if (_MODULOS_LOGROS.includes(modulo) && !UIUtilPermission._HasLogrosModulesPermissionFromSchools(idEscuela)) {
            return false;
        }
        else if (_MODULOS_CALIFICACIONES.includes(modulo) && !UIUtilPermission._HasCalifModulesPermissionFromSchools(idEscuela)) {
            return false;
        }
        return _HasModulePermission(modulo, idEscuela);
    }

    private get PermissionCargosDesc() {
        if (this.currentAlumnos[0]?.IdKinder) {
            return this.HasModulePermission(Entidad.CModulo.PanelFinanzasCargosDescuentos, this.currentAlumnos[0].IdKinder)
        }
        return false;
    }

    private get PermissionEdoCuenta() {
        if (this.currentAlumnos[0]?.IdKinder) {
            return this.HasModulePermission(Entidad.CModulo.PanelFinanzasEdoCuenta, this.currentAlumnos[0].IdKinder)
        }
        return false;
    }

    private get PermissionAsignaciones() {
        if (this.currentAlumnos[0]?.IdKinder) {
            return this.HasModulePermission(Entidad.CModulo.PanelAlumnoMaterias, this.currentAlumnos[0]?.IdKinder)
        }
        return false;
    }

    private get PermissionBoletas() {
        if (this.currentAlumnos[0]?.IdKinder) {
            return this.HasModulePermission(Entidad.CModulo.PanelAlumnoMaterias, this.currentAlumnos[0]?.IdKinder)
        }
        return false;
    }

    private get firstTabFinanzasAvailable() {
        if (this.PermissionCargosDesc) {
            return CFinanzasTabs.CargosyDesc;
        }
        if (this.PermissionEdoCuenta) {
            return CFinanzasTabs.EdoCuenta;
        }
        return null;
    }

    private get firstTabCalificacionesAvailable() {
        if (this.PermissionAsignaciones) {
            return CCalificacionesTabs.Asignaciones;
        }
        if (this.PermissionBoletas) {
            return CCalificacionesTabs.Boletas;
        }
        return null;
    }

    public _Destroy(): void {
        super._Destroy();
        if (this["__onchangeparams"]) {
            UIWindowManager._RemoveOnParamsChangeEventListener(this["__onchangeparams"]);
        }

        for (const key in TarjetasDisponibles) {
            this.CollapseCard(key as CardsKeys);
            if (key != "palumnofinanzasmain" && key != "palumnocalificaciones") TarjetasDisponibles[key as CardsKeys].cardColl._Destroy();
        }

        for (const key in TarjetasFinanzasTabs) {
            TarjetasFinanzasTabs[key as CardTabsFinanzasKeys].cardColl._Colapsar();
            TarjetasFinanzasTabs[key as CardTabsFinanzasKeys].cardColl._Destroy();
        }

        for (const key in TarjetasCalificacionesTabs) {
            TarjetasCalificacionesTabs[key as CardTabsCalificacionesKeys].cardColl._Colapsar();
            TarjetasCalificacionesTabs[key as CardTabsCalificacionesKeys].cardColl._Destroy();
        }
    }
}
