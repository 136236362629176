import { Main } from "./Main";
import WModulos from "./background/WModulos?worker";
import { Entidad } from "./data/Entidad";
import { Global } from "./data/Global";
import { DataModuloMain } from "./data/ModuloMain";
import { CLicenciaModuloExtra } from "./data/entidad/Licencia";
import { DMUsuarioSesion } from "./data/model/MUsuarioSesion";
import { _LOCALDATA_GetGruposHorariosDeAlumno, _SvAlumnoURLObtenerFoto } from "./data/modulo/Alumno";
import DataModuloEscolaridad from "./data/modulo/Escolaridad";
import DataModuloEscuela from "./data/modulo/Escuela";
import { _FN_MODULO_FACTURA_ACTIVO } from "./data/modulo/FacturaCFDI";
import DataModuloFinanzaHoraExtra from "./data/modulo/FinanzaHoraExtra";
import DataModuloGrado from "./data/modulo/Grado";
import DataModuloHorarioAlumno from "./data/modulo/HorarioAlumno";
import { _LOCALDATA_Licencia_EdoActualAlumno } from "./data/modulo/LicenciaOld";
import DataModuloLogroAsignacion from "./data/modulo/LogroAsignacion";
import DataModuloMateriaV2 from "./data/modulo/MateriaV2";
import DataModuloPermisos from "./data/modulo/Permisos";
import { ISesionVigencia } from "./data/modulo/Sesion";
import DataModuloTutorAsignacion from "./data/modulo/TutorAsignacion";
import { DataUtilPermission } from "./data/util/Permission";
import { DataUtil } from "./data/util/Util";
import { UIUtilFormat } from "./ui/util/Format";
import { UIUtilLang } from "./ui/util/Language";
import { UIUtilPermission } from "./ui/util/Permission";
import { UIUtilTime } from "./ui/util/Time";
import { UIUtilGeneral } from "./ui/util/Util";
import { UIUtilViewData } from "./ui/util/ViewData";
import { UIUtilViewFinanzaCargo } from "./ui/utilView/FinanzaCargo";
import { UIUtilViewFinanzaEgresoCategoria } from "./ui/utilView/FinanzaEgresoCategoria";
import { UIUtilViewGrupos } from "./ui/utilView/Grupos";
import { UIVentanaLoadServicesV2 } from "./ui/ventana/LoadServicesV2";
import { UIWindowManager } from "./ui/ventana/WindowManager";
import { UtilObject } from "./util/Object";

export namespace MainPage {
    type TTipoRequestMonitor = DataModuloMain.TipoRequestMonitorId; // data.Entidades.CTipoRequest;
    type TRequestEntitiesMap = DataModuloMain.TTypeRequestEntitiesMap;
    type TReloadRerviceCallback<T extends TTipoRequestMonitor> = (reqID: T, reloadId: number, error: Error, newData?: Array<TRequestEntitiesMap[T]>) => void;
    interface IItemReloadService {
        id: TTipoRequestMonitor;
        reloadId: number;
        onServiceEventProto?: TReloadRerviceCallback<any>;
    }

    const _serviceWaitingToReload = new Map<number, IItemReloadService>();

    export type EventWkrMessage<k extends TTipoRequestMonitor> = CustomEvent<{
        ReloadId?: number;
        Error?: Error;
        TipoRequest?: k;
        Data: TRequestEntitiesMap[k][];
    }>

    type EventWkrMessageCallback<k extends TTipoRequestMonitor> = (e: EventWkrMessage<k>) => void;
    function CreateEventsAndListeners(): void {
        for (const evt in DataModuloMain._GetModulesConfig()) {
            const evtValue: number = Entidad.CTipoRequest[evt];
            document.addEventListener(evtValue.toString(), ((e: EventWkrMessage<any>) => {
                UIWindowManager._OnServiceEvent(evtValue, e.detail.ReloadId, e.detail.Error);
            }) as any);
        }
    }

    export function _AddEventListenerWorkerRequest<k extends TTipoRequestMonitor>(idRequest: k, callback: EventWkrMessageCallback<k>) {
        document.addEventListener(idRequest.toString(), callback as any);
        return callback;
    }

    export function _RemoveEventListenerWorkerRequest<k extends TTipoRequestMonitor>(idRequest: k, callback: EventWkrMessageCallback<k>) {
        document.removeEventListener(idRequest.toString(), callback as any);
    }

    ///WORKER
    var wkModulo: Worker;
    export function _StartWorker(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            CreateEventsAndListeners();
            let reqWaitFirstResCount = 0;
            const reqWaitFirstRes: (DataModuloMain.TipoRequestMonitorId[] | null) = null; // FIXME // DOTEST Tendrá vista propia?
            // (() => {
            //     const modules = DataModuloMain.fn_GetModulesConfig();
            //     const res: DataModuloMain.TipoRequestMonitorId[] = [];
            //     for (const k in modules) {
            //         const module = modules[k as keyof typeof modules];
            //         if (!module.SaveInLocalBD && module.RequestOnFirstLoad) {
            //             res.push(Entidad.CTipoRequest[k]);
            //         }
            //     }
            //     return res.length ? res : null;
            // })();

            // console.debug("_StartWorker..");
            // const url = "./background/WModulos" //?v" + DataUtil._DEBUG_VERSION;
            wkModulo = new WModulos();

            // wkModulo = new Worker("js/background/WModulos.js?v" + DataUtil._DEBUG_VERSION);

            wkModulo.postMessage(<Entidad.IMessageDOMToWorker>{
                Type: "load",
                Message: {
                    User: DataUtil._Usuario.__Entity,
                }
            })

            wkModulo.onmessage = async function (event: MessageEvent<Entidad.IMessageWorkerToDOM>) {
                const respuesta = event.data;

                switch (respuesta.Type) {
                    case "load":
                        if (!respuesta.Message.User) {
                            resolve(false)
                            return;
                        }
                        DataUtil._Usuario = new DMUsuarioSesion(respuesta.Message.User);
                        DataUtilPermission._DiccPermisos = respuesta.Message.UserPermissions;
                        DataModuloPermisos._PermisosAccionesMap = respuesta.Message.AccionesDisponibles;
                        DataModuloPermisos._PermisosModulosMap = respuesta.Message.ModulosDisponibles;
                        DataModuloPermisos._PermisosDisponiblesMap = respuesta.Message.PermisosDisponibles;

                        wkModulo.postMessage(<Entidad.IMessageDOMToWorker>{
                            Type: "start",
                        });
                        break;
                    case "request-flag":
                        // console.debug("Reload Request Status:", respuesta.Message.Flag);
                        if (reqWaitFirstRes === null && respuesta.Message.FirstLocalData && respuesta.Message.Flag == "finish") {
                            resolve(true);
                            // resolved = true;
                        }
                        break;
                    case "request-data":
                        let message = respuesta.Message;
                        let itemServiceWaiting = _serviceWaitingToReload.get(message.ReloadId);
                        if (itemServiceWaiting) {
                            _serviceWaitingToReload.delete(message.ReloadId);
                        }

                        if (!message.ForceLoad && message.Data.length == 0 && !message.Forced && message.Error == null && !(UIWindowManager._lastInstance instanceof UIVentanaLoadServicesV2)) {
                            return;
                        }

                        if (reqWaitFirstRes?.includes(message.Id)) {
                            console.warn("llegada de ", message.Id, Entidad.CTipoRequest[message.Id], reqWaitFirstResCount + 1, "/", reqWaitFirstRes.length);
                            reqWaitFirstResCount++;
                            if (reqWaitFirstRes.length == reqWaitFirstResCount) {
                                console.warn("datos básicos completos")
                                resolve(true);
                            }
                        }

                        let serviceID = <TTipoRequestMonitor>message.Id;
                        let evt = new CustomEvent(serviceID.toString(), {
                            bubbles: false,
                            cancelable: true,
                            detail: {
                                ReloadId: message.ReloadId,
                                Error: message.Error,
                                TipoRequest: message.Id as any,
                                Data: message.Data as any,
                            }
                        }) as EventWkrMessage<any>;

                        if (!message.Error) await RefreshServiceDict(serviceID, message.FirstLocalData, message.Data);
                        if (itemServiceWaiting?.onServiceEventProto) {
                            // NOTE itemServiceWaiting.onServiceEventProto, se tiene que ejecutar (por conservación del orden de procesos) antes que dispatchEvent.
                            itemServiceWaiting.onServiceEventProto(serviceID, message.ReloadId, message.Error, message.Data);
                            setTimeout(() => document.dispatchEvent(evt));
                        } else {
                            document.dispatchEvent(evt);
                        }
                        break;
                    case "permission-reload":
                        if (!respuesta.Message.UserValid) {
                            resolve(false)
                            return;
                        }
                        DataUtilPermission._DiccPermisos = respuesta.Message.UserPermissions;
                        break;
                }
            }
        });
    }

    export function _StopWorker() {
        if (wkModulo) {
            wkModulo.terminate();
            wkModulo = undefined;
        }
    }

    export function _CrearPermisosLocalesWK(idEscuela: number) {
        wkModulo.postMessage(<Entidad.IMessageDOMToWorker>{
            Type: "permission-reload",
            Message: {
                idSchool: idEscuela
            }
        })
    }

    export function _ActivarServiceMonitor<T extends TTipoRequestMonitor>(serviceID: T, activar: boolean) {
        DataModuloMain._ActivarServiceMonitor(serviceID, activar);
        wkModulo.postMessage(<Entidad.IMessageDOMToWorker>{
            Type: "request-active",
            Message: {
                id: serviceID,
                active: activar,
            }
        });
    }

    /**
     * Fuerza una petición para obtener la lista de datos perteneciente al CTipoRequest
     * @param serviceID
     * @param idsEscuelas (Opcional) Si es (null | undefined | <= 0) se consultan todas las escuelas
     * @param onServiceEvent (Opcional) Se invoca cuando llegue el request de éste reload especifico
     *
     * @returns Retorna un identificador de la invocación
     */
    export function _ReloadService<T extends TTipoRequestMonitor>(serviceID: T, idsEscuelas?: number | Array<number>, onServiceEvent?: TReloadRerviceCallback<T>): number {
        if (typeof idsEscuelas == "number") {
            idsEscuelas = [idsEscuelas];
        } else if (!(idsEscuelas instanceof Array)) {
            idsEscuelas = null;
        }
        const itemInReload: Entidad.IMessageDOMToWorker = {
            Type: "request-run",
            Message: {
                id: serviceID,
                reloadId: (serviceID * 200) + _serviceWaitingToReload.size + 1 + Number(new Date()),
                IdsEscuelas: idsEscuelas as number[],
            }
        }

        _serviceWaitingToReload.set(itemInReload.Message.reloadId, {
            id: itemInReload.Message.id,
            reloadId: itemInReload.Message.reloadId,
            onServiceEventProto: onServiceEvent
        });

        wkModulo.postMessage(itemInReload);
        return itemInReload.Message.reloadId;
    }

    export function _ForceMonitor() {
        wkModulo.postMessage(<Entidad.IMessageDOMToWorker>{
            Type: "monitor-run",
        })
    }

    /**
     * Fuerza una petición para obtener la lista de datos perteneciente al CTipoRequest
     * @param serviceID
     * @param idsEscuelas (Opcional) Si es (null | undefined | <= 0) se consultan todas las escuelas
     *
     * @returns Retorna una promesa que se resuelve con la respuesta de la petición (los datos devueltos son los de la consulta)
     */
    export function _ReloadServiceAndAwait<T extends TTipoRequestMonitor>(serviceID: T, idsEscuelas?: number | Array<number>): Promise<TRequestEntitiesMap[T][]> {
        return new Promise((resolve, reject) => {
            _ReloadService<T>(serviceID, idsEscuelas, (requestID, reloadId, err, data) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(data);
                }
            })
        })
    }

    /**
     * Fuerza una petición para obtener la lista de datos perteneciente al CTipoRequest
     * @param serviceID
     * @param idsEscuelas (Opcional) Si es (null | undefined | <= 0) se consultan todas las escuelas
     *
     * @returns Retorna una promesa que se resuelve con la respuesta de la petición (es verdaderi si no hay error)
     */
    export function _ReloadServiceAndAwaitBool<T extends TTipoRequestMonitor>(serviceID: T, idsEscuelas?: number | Array<number>): Promise<boolean> {
        return new Promise((resolve, reject) => {
            _ReloadService<T>(serviceID, idsEscuelas, (requestID, reloadId, err, data) => {
                resolve(!Boolean(err));
            })
        })
    }

    // export function RefreshDict<ReqID extends CTipoRequest>(serviceID: ReqID): void {
    //     let itemRequestConfig = data.ModulesMain.fn_GetModuleConfig(serviceID);
    //     let datos = Array.from(itemRequestConfig.DictData.values())

    //     RefreshServiceDict(serviceID, true, datos as any);
    //     // let evt = _serviceEvents[serviceID];
    //     // document.dispatchEvent(evt);
    // }

    function RefreshServiceDict(serviceID: TTipoRequestMonitor, firstLocalData: boolean, items: Array<{ [x: string]: any; EnUso?: boolean; }>) {
        let itemRequestConfig = DataModuloMain._GetModuleConfig(serviceID as any);
        let svcDict = itemRequestConfig.DictData;
        let idKey = itemRequestConfig.IdData as string;

        if (svcDict == null) {
            console.warn("No dictionary found for ", serviceID);
            return;
        }
        for (let item of items) {
            let id = item[idKey];
            let enUso = (item.EnUso === true || item.EnUso === undefined);

            if (!firstLocalData && svcDict.has(id)) {
                svcDict.delete(id);
            }

            if (OnServiceItemLoaded(serviceID, item, enUso)) {
                svcDict.set(id, item as any);
            }
            else if (svcDict.has(id)) {
                svcDict.delete(id);
            }
        }
    }

    function OnServiceItemLoaded(serviceID: TTipoRequestMonitor, item: any, enUso: boolean): boolean {
        const CTipoRequest = Entidad.CTipoRequest;
        /** Se debe respetar su valor cuando es falso ⚠ */
        let save = enUso;

        if (enUso) {
            // Items que no requieren validar enUso == false
            switch (serviceID) {
                case CTipoRequest.LogroCategoria:
                    ItemLogroCategoria(item);
                    break;
                case CTipoRequest.Logro:
                    ItemLogros(item);
                    break;
                case CTipoRequest.TutorAsignacion:
                    // El item siempre es ((enUso == undefined) == true)
                    save = ItemAsignacionTutor(item);
                    break;
                // case CTipoRequest.UsuarioPermisos:
                //     ItemUsuarioPermiso(item);
                //     break;
                case CTipoRequest.Circular:
                    ItemCircular(item);
                    break;
                case CTipoRequest.FinanzaCargo:
                    ItemFinanzaCargo(item);
                    break;
                case CTipoRequest.FinanzaMetodoPago:
                    ItemFinanzaMetodoPago(item);
                    break;
                case CTipoRequest.Maestro:
                    ItemMaestro(item);
                    break;
                case CTipoRequest.EgresoPredefinido:
                    ItemEgresoPredefinido(item);
                    break;
                case CTipoRequest.CuentaBancaria:
                    ItemCuentaBancaria(item);
                    break;
                case CTipoRequest.Proveedor:
                    ItemProveedor(item);
                    break;
                case CTipoRequest.EgresoCategoria:
                    ItemEgresoCategoria(item);
                    break;
            }
        }

        // EnUso se valída aquí
        switch (serviceID) {
            case CTipoRequest.Usuario:
                save = ItemUsuario(item, enUso);
                break;
            case CTipoRequest.Alumno:
                save = ItemNino(item, enUso);
                break;
            case CTipoRequest.Escuela:
                save = ItemEscuela(item, enUso);
                break;
            case CTipoRequest.Escolaridad:
                ItemEscolaridad(item);
                break;
            case CTipoRequest.Grado:
                ItemGrado(item);
                break;
            case CTipoRequest.Grupo:
                ItemGrupo(item);
                break;
            case CTipoRequest.Tutor:
                ItemTutor(item, enUso);
                break;
            case CTipoRequest.HorarioAlumno:
                ItemAsignacionAlumnoHorario(item);
                break;
            case CTipoRequest.LogroAsignacion:
                ItemLogroAsignacion(item);
                break;
            // case CTipoRequest.CicloEscolar:
            //     save = ItemCicloEscolar(item);
            //     break;
            case CTipoRequest.Sesion:
                ItemSesion(item)
                break;
            case CTipoRequest.FinanzaHoraExtra:
                ItemFinanzaHoraExtra(item);
                break;
            case CTipoRequest.MateriaV2:
                ItemMateria(item, enUso);
                break;
        }
        return save;
    }

    /**
     * ASIGNACIONES CON
     * * Tutor
     * * Alumnos (Hermanos)
     * * Grupos inscritos (Horarios)
     * * Logros
     * * Expedientes
     *
     * @param item
     * @param enUso
     * @returns
     */
    function ItemNino(item: Entidad.IAlumno, enUso: boolean): boolean {
        if (item.IdChildMovimiento == Entidad.CNinioMovimiento.BajaDefinitiva || item.IdChildMovimiento == Entidad.CNinioMovimiento.Graduado) {
            return false;
        }

        item.StrSexo = UIUtilViewData._GetStr_Sexo(item.Sexo);
        item.StrEstado = UIUtilViewData._GetStr_EdoMovimiento(item.IdChildMovimiento);

        item.NombreCompleto = (item.Nombre + " " + item.ApPaterno + " " + item.ApMaterno).trim();
        item.KinderFiltro = [item.IdKinder];

        item = UIUtilGeneral._ObjectAddGetters(item, {
            getFechaNacimientoFmt: () => UIUtilTime._DateFormatStandarFixTimeZone(item.FechaNacimiento, DataModuloMain._GetDataValueFieldByName("Escuela", item.IdKinder, "ZonaHoraria")),
            getThumbnailURL: () => _SvAlumnoURLObtenerFoto(item, 3),

            NombreKinder: () => (DataModuloMain._GetReqDataMapByName("Escuela").get(item.IdKinder)?.Nombre || ""),
            StrEscolaridad: () => (DataModuloMain._GetReqDataMapByName("Escolaridad").get(item.IdEscolaridad)?.Nombre || ""),
            StrGrado: () => (DataModuloMain._GetReqDataMapByName("Grado").get(item.IdGrado)?.Nombre || ""),
            StrGrupoPrincipal: () => UIUtilViewGrupos._GetLblsGrupos(item).join(", "),
            StrGrupoPrincipalNames: () => Array.from(_LOCALDATA_GetGruposHorariosDeAlumno(item.IdChild).values())
                .filter(d => d.EsPrincipal)
                .map(d => d.Nombre),
            getEdoLicencia: () => _LOCALDATA_Licencia_EdoActualAlumno(item.IdChild)
        })

        return enUso;
    }


    function ItemMateria(item: Entidad.IMateria, enUso: boolean): boolean {
        item = UIUtilGeneral._ObjectAddGetters(item, {
            getThumbnailURL: () => DataModuloMateriaV2._GetUrlObtenerLogoMateria(item, 3)
        })

        return enUso;
    }

    function ItemLogroCategoria(item: Entidad.ILogroCategoria): void {
        item.KinderFiltro = [item.IdKinder];
        item = UIUtilGeneral._ObjectAddGetters(item, {
            NombreKinder: () => (DataModuloMain._GetDataValueFieldByName("Escuela", item.IdKinder, "Nombre") || "")
        });
    }

    function ItemGrado(item: Entidad.IGrado): void {
        item.KinderFiltro = [item.IdKinder];
        item = UIUtilGeneral._ObjectAddGetters(item, {
            NombreKinder: () => (DataModuloMain._GetDataValueFieldByName("Escuela", item.IdKinder, "Nombre") || "")
        })

        // >> Asignaciones
        const asignacionesEscolaridadGrados = DataModuloEscolaridad._DiccAsignacionEscolaridadGrados.get(item.IdEscolaridad);
        if (asignacionesEscolaridadGrados) {
            if (item.EnUso) {
                asignacionesEscolaridadGrados.set(item.IdNivel, item);
            } else {
                asignacionesEscolaridadGrados.delete(item.IdNivel);
            }
        } else if (item.EnUso) {
            DataModuloEscolaridad._DiccAsignacionEscolaridadGrados.set(item.IdEscolaridad, new Map([[item.IdNivel, item]]));
        }
    }

    function ItemGrupo(item: Entidad.IGrupo): void {
        item.KinderFiltro = [item.IdKinder];
        item.StrTipoGrupo = UIUtilLang._GetUIString("grupos", (item.EsPrincipal ? "tag_primario" : "tag_secundario"));

        item = UIUtilGeneral._ObjectAddGetters(item, {
            NombreKinder: () => (DataModuloMain._GetDataValueFieldByName("Escuela", item.IdKinder, "Nombre") || ""),
            NombreGrado: () => (DataModuloGrado._DiccGrado.get(item.IdNivel)?.Nombre || ""),
        })

        if (item.IdEscolaridad) {
            item = UIUtilGeneral._ObjectAddGetters(item, {
                NombreEscolaridad: () => (DataModuloMain._GetDataValueFieldByName("Escolaridad", item.IdEscolaridad, "Nombre") || "")
            })
        } else {
            // REMOVER EL servicio comienza a retornar IdEscolaridad
            item = UIUtilGeneral._ObjectAddGetters(item, {
                IdEscolaridad: () => DataModuloGrado._DiccGrado.get(item.IdNivel)?.IdEscolaridad,
                NombreEscolaridad: () => {
                    let idEscolaridad = DataModuloGrado._DiccGrado.get(item.IdNivel)?.IdEscolaridad;
                    if (idEscolaridad) {
                        return (DataModuloMain._GetDataValueFieldByName("Escolaridad", idEscolaridad, "Nombre") || "");
                    }
                    return "";
                }
            })
        }

        item.Horario = GetHorarioGrupo(item); //horarios;

        const asignacionesNivelGrupos = DataModuloGrado._DiccAsignacionGradoGrupos.get(item.IdNivel);
        if (asignacionesNivelGrupos) {
            if (item.EnUso) {
                asignacionesNivelGrupos.set(item.IdGrupo, item);
            } else {
                asignacionesNivelGrupos.delete(item.IdGrupo);
            }
        } else if (item.EnUso) {
            DataModuloGrado._DiccAsignacionGradoGrupos.set(item.IdNivel, new Map([[item.IdGrupo, item]]));
        }
    }

    function ItemLogros(item: Entidad.ILogro): void {
        item.KinderFiltro = [item.IdKinder];
        item.StrTipoProceso = "";

        item = UIUtilGeneral._ObjectAddGetters(item, {
            StrEscuela: () => (DataModuloMain._GetDataValueFieldByName("Escuela", item.IdKinder, "Nombre") || ""),
            StrCategoria: () => (DataModuloMain._GetDataValueFieldByName("LogroCategoria", item.IdCategoria, "Nombre") || ""),
        })

        item.StrTipoProceso = UIUtilViewData._GetStr_LogroTipoProceso(item.Proceso);
    }

    function ItemLogroAsignacion(item: Entidad.ILogroAsignacion): void {
        let asignacionesAlumno = DataModuloLogroAsignacion._DiccAlumnosAsignaciones.get(item.IdChild);
        if (item.EnUso) {
            if (!asignacionesAlumno) {
                asignacionesAlumno = new Map();
                DataModuloLogroAsignacion._DiccAlumnosAsignaciones.set(item.IdChild, asignacionesAlumno);
            }
            asignacionesAlumno.set(item.IdAsignacion, item);
        } else {
            if (asignacionesAlumno) {
                asignacionesAlumno.delete(item.IdAsignacion);
                if (asignacionesAlumno.size == 0) {
                    DataModuloLogroAsignacion._DiccAlumnosAsignaciones.delete(item.IdChild);
                }
            }
        }

        item.StrEstado = UIUtilViewData._GetStr_LogroAsignEstado(item.Estado);
    }

    function ItemTutor(item: Entidad.ITutor, enUso: boolean): void {
        item.NombreCompleto = (item.Nombre + " " + item.ApPaterno + " " + item.ApMaterno).trim();
        item.IdEscuelas = item.IdEscuelas || "";
        item.KinderFiltro = (item.IdEscuelas == "") ? null : item.IdEscuelas.split(",").map(id => Number(id));

        if (item.Telefono) {
            item.Telefono = item.Telefono.trim();
            if (!item.Telefono.split(" ").reverse()[1])
                item.Telefono = "+52" + " " + item.Telefono.split(" ").reverse()[0];
        }
    }

    function ItemAsignacionTutor(item: Entidad.ITutorAsignacion): boolean {
        let diccAsignacionesAlumno = DataModuloTutorAsignacion._DiccAsignacionesPorAlumno;
        let diccAsignacionesTutor = DataModuloTutorAsignacion._DiccAsignacionesPorTutor;
        // let diccAsignacionesEscuela = data.modulos.TutorAsignacion.DiccAsignacionesPorEscuela;
        const asignacionesAlumno = diccAsignacionesAlumno.get(item.IdChild);
        const asignacionesTutor = diccAsignacionesTutor.get(item.IdPadre);
        // const asignacionesEscuela = diccAsignacionesEscuela.get(item.IdKinder);
        let save = item.FechaFin == null;

        if (asignacionesAlumno) {
            if (save) {
                asignacionesAlumno.set(item.IdRegistro, item);
            } else {
                asignacionesAlumno.delete(item.IdRegistro);
                if (asignacionesAlumno.size == 0) {
                    diccAsignacionesAlumno.delete(item.IdChild);
                }
            }
        } else if (save) {
            diccAsignacionesAlumno.set(item.IdChild, new Map([[item.IdRegistro, item]]));
        }

        if (asignacionesTutor) {
            if (save) {
                asignacionesTutor.set(item.IdRegistro, item);
            } else {
                asignacionesTutor.delete(item.IdRegistro);
                if (asignacionesTutor.size == 0) {
                    diccAsignacionesTutor.delete(item.IdPadre);
                }
            }
        } else if (save) {
            diccAsignacionesTutor.set(item.IdPadre, new Map([[item.IdRegistro, item]]));
        }

        // if (asignacionesEscuela) {
        //     if (save) {
        //         asignacionesEscuela.set(item.IdRegistro, item);
        //     } else {
        //         asignacionesEscuela.delete(item.IdRegistro);
        //         if (asignacionesEscuela.size == 0) {
        //             diccAsignacionesEscuela.delete(item.IdKinder);
        //         }
        //     }
        // } else if (save) {
        //     diccAsignacionesEscuela.set(item.IdKinder, new Map([[item.IdRegistro, item]]));
        // }

        // if (item.IdChild == 1016 || item.IdChild == 1017 || item.IdChild == 864) {
        //     console.log(item, diccAsignacionesAlumno.get(item.IdChild), "itemdebugasigntutaslumn")
        // }
        // console.info("itemASIGNACION TUTOR-ALUMNO")
        // AsignacionesTutorAlumno(item, save, undefined, undefined);
        return save;
    }

    function ItemAsignacionAlumnoHorario(item: Entidad.IAlumnoHorarioAsignacion): void {
        item.Horario = new Array<Entidad.IHorarioDia>();

        // -> Update DiccAsignacionGrupoAlumnos
        if (item.EnUso) {
            let asignacionesGrupo = DataModuloHorarioAlumno._DiccAsignacionGrupoAlumnos.get(item.IdGrupo);
            if (!asignacionesGrupo) {
                asignacionesGrupo = new Map();
                DataModuloHorarioAlumno._DiccAsignacionGrupoAlumnos.set(item.IdGrupo, asignacionesGrupo);
            }
            asignacionesGrupo.set(item.IdHorario, item);
        } else {
            let asignacionesGrupo = DataModuloHorarioAlumno._DiccAsignacionGrupoAlumnos.get(item.IdGrupo);
            if (asignacionesGrupo) {
                if (asignacionesGrupo.delete(item.IdHorario) && asignacionesGrupo.size == 0) {
                    DataModuloHorarioAlumno._DiccAsignacionGrupoAlumnos.delete(item.IdGrupo);
                }
            }
        }

        // -> Update DiccAsignacionAlumnoGrupos
        if (item.EnUso) {
            let asignacionesAlumnoGrupos = DataModuloHorarioAlumno._DiccAsignacionAlumnoGruposHorarios.get(item.IdNinio);
            if (!asignacionesAlumnoGrupos) {
                asignacionesAlumnoGrupos = new Map();
                DataModuloHorarioAlumno._DiccAsignacionAlumnoGruposHorarios.set(item.IdNinio, asignacionesAlumnoGrupos);
            }
            asignacionesAlumnoGrupos.set(item.IdHorario, item);
        } else {
            let asignacionesAlumnoGrupos = DataModuloHorarioAlumno._DiccAsignacionAlumnoGruposHorarios.get(item.IdNinio);
            if (asignacionesAlumnoGrupos) {
                if (asignacionesAlumnoGrupos.delete(item.IdHorario) && asignacionesAlumnoGrupos.size == 0) {
                    DataModuloHorarioAlumno._DiccAsignacionAlumnoGruposHorarios.delete(item.IdNinio);
                }
            }
        }

        // -> Ordena los el horario en un mapa de Dias de la semana
        if (item.Entradas && item.Salidas) {
            item.Entradas.forEach((strDate, i) => {
                const strEntrada = strDate;
                const strSalida = item.Salidas[i];

                if (strEntrada && strSalida) {
                    item.Horario.push({
                        IdDia: i + 1,
                        IdHorario: item.IdHorario,
                        Entrada: strEntrada,
                        Salida: strSalida
                    })
                } else if ((strEntrada && !strSalida) || !strEntrada && strSalida) {
                    console.warn("-d", `Incongruencia de datos -> IdHorario: ${item.IdHorario}, Día: ${Entidad.CDiaSemanal[i + 1]}, Entrada: ${strEntrada}, Salida: ${strSalida}`);
                }
            })
        } else {
            console.warn("-d", "Configuración de información incorrecta, faltan 'Entradas' y 'Salidas'", item.IdHorario);
            // REMOVER Aplicación
            for (let strDia in Entidad.CDiaSemanal) {
                if (isNaN(Number(strDia))) {
                    /** De origen: "hh:mm:ss" -> Esperado: UTC Format (yyyy-mm-dd hh:mm:ss) */
                    let strEntrada: string = item[strDia + "Entrada"];
                    let strSalida: string = item[strDia + "Salida"];

                    if (strEntrada && strSalida) {
                        // -> Aplica Formato UTC // FIXME Proximanante usar 'Modificacion'
                        if (!strEntrada.includes("T")) {
                            strEntrada = UIUtilTime._GetLocalDateFromTimeZone_ReplaceHourFromDateRef(strEntrada, item.Modificacion).toISOString();
                            // strEntrada = ui.Utils.fn_GetLocalDate_ReplaceHourFromDateRef(strEntrada, (new Date("2020/01/01").toISOString())).toISOString();
                            // item[strDia + "Entrada"] = strEntrada;
                        }
                        if (!strSalida.includes("T")) {
                            strSalida = UIUtilTime._GetLocalDateFromTimeZone_ReplaceHourFromDateRef(strSalida, item.Modificacion).toISOString();
                        }
                        item.Horario.push({
                            IdDia: Number(Entidad.CDiaSemanal[strDia]),
                            IdHorario: item.IdHorario,
                            Entrada: strEntrada,
                            Salida: strSalida
                        });
                    } else if ((strEntrada && !strSalida) || !strEntrada && strSalida) {
                        console.warn("-d", `Incongruencia de datos -> IdHorario: ${item.IdHorario}, Día: ${strDia}, Entrada: ${strEntrada}, Salida: ${strSalida}`);
                    }
                }
            }
        }
    }

    // function ItemUsuarioPermiso(item: data.Entidades.IHorarioDia): void { }

    function ItemUsuario(item: Entidad.IUsuario, enUso: boolean) {
        if (DataUtil._Usuario.IdUsuario == item.IdUsuario || item.Perfil <= DataUtil._Usuario.Perfil) {
            return false;
        }
        item.NombreCompleto = (item.Nombre + " " + item.ApPaterno + " " + item.ApMaterno).trim();
        item.StrPerfil = UIUtilViewData._GetStr_PerfilUser(item.Perfil);
        item.Escuelas = item.Escuelas || [];
        item.KinderFiltro = item.Escuelas
            .filter(idEscuela => Boolean(DataModuloMain._GetItemDataByName("Escuela", idEscuela)));

        if (!item.KinderFiltro.length) item.KinderFiltro = null;

        item = UIUtilGeneral._ObjectAddGetters(item, {
            getEscuelaNombres: () => item.Escuelas
                .map(idE => DataModuloEscuela._DiccFullEscuelas.get(idE)?.Nombre)
                .filter(d => d != null)
                .join(", ")
        });

        return enUso;
    }

    async function ItemCircular(item: Entidad.ICircular) {
        item.StrFecha = "";
        item.KinderFiltro = [item.IDKinder];

        item.NombreKinder = DataModuloMain._GetDataValueFieldByName("Escuela", item.IDKinder, "Nombre") || "";

        if (item.Programado)
            item.StrFecha = UIUtilTime._DateFormatStandarFixTimeZoneByIdSchool(item.Programado, item.IDKinder, "dd/mm/yyyy h12:mm");
        else
            item.StrFecha = UIUtilLang._GetUIString("general", "sinconfig");
        if (item.Expira) {
            item.StrFechaExpira = UIUtilTime._DateFormatStandarFixTimeZoneByIdSchool(item.Expira, item.IDKinder, "dd/mm/yyyy h12:mm");
        } else {
            item.StrFechaExpira = UIUtilLang._GetUIString("general", "sinconfig");
        }
    }

    function ItemEscolaridad(item: Entidad.IEscolaridad) {
        item.NombreEscuela = DataModuloMain._GetDataValueFieldByName("Escuela", item.IdEscuela, "Nombre") || "";

        // >> Asignaciones
        const asignacionesEscuelaEscolaridad = DataModuloEscuela._DiccAsignacionEscuelaEscolaridades.get(item.IdEscuela);
        if (asignacionesEscuelaEscolaridad) {
            if (item.EnUso) {
                asignacionesEscuelaEscolaridad.set(item.Id, item);
            } else {
                asignacionesEscuelaEscolaridad.delete(item.Id);
            }
        } else if (item.EnUso) {
            DataModuloEscuela._DiccAsignacionEscuelaEscolaridades.set(item.IdEscuela, new Map([[item.Id, item]]));
        }
    }

    function ItemEscuela(item: Entidad.IEscuela, enUso: boolean): boolean {
        if (enUso) {
            DataModuloEscuela._DiccFullEscuelas.set(item.IdKinder, item);
        } else {
            DataModuloEscuela._DiccFullEscuelas.delete(item.IdKinder);
        }
        item.KinderFiltro = [item.IdKinder];
        item.ModuloEval = item.ModuloEval || Entidad.CTipoEvento.Logros;
        // Todas las escuelas tienen telefono?
        item.Telefono = item.Telefono.trim();
        if (!item.Telefono.split(" ").reverse()[1])
            item.Telefono = "+52" + " " + item.Telefono.split(" ").reverse()[0];
        if (item.ConLogo)
            item.Logo = DataModuloEscuela._GetUrlObtenerLogo(item);
        else
            item.Logo = null;

        item.Entrada = item.HoraEntradas.find(d => Boolean(d)) || "";
        item.Salida = item.HoraSalidas.find(d => Boolean(d)) || "";

        const usaFinanzas = item.UsaFinanzas
        const usaFactura = item.UsaFactura
        item = UtilObject._GettersSetters(item, {
            getUsaFactura: () => (((DataUtil._Usuario._PerfilAdmin || DataUtil._Usuario._PerfilSuperUsuario) && _FN_MODULO_FACTURA_ACTIVO())
                ? usaFactura
                : false),
            getUsaFinanzas: () => (Global._LICENCIA.ModulosExtra.includes(CLicenciaModuloExtra.Finanzas)
                ? usaFinanzas
                : false),
        })

        if (!UIUtilPermission._HasSchoolPermission(item.IdKinder)) {
            return false;
        }
        if (!enUso) return enUso
        enUso = !Global._LICENCIA._ValidarEsEscuelaExpirada(item.IdKinder)
        item.EnUso = enUso
        return enUso;
    }

    function ItemFinanzaCargo(item: Entidad.IFinanzaCargo) {
        if (item.Categoria == Entidad.CFinanzaCargoCategoria.Descuento) {
            item.StrCategoria = UIUtilViewData._GetStr_CargoCategoria(Entidad.CFinanzaCargoCategoria.Descuento);
        } else {
            item.StrCategoria = UIUtilViewData._GetStr_CargoCategoria(Entidad.CFinanzaCargoCategoria.Cargo);
        }
        // item.StrCategoria = data.Entidades.CFinanzaCargoCategoria[item.Categoria];
        item.StrPeriodicidad = UIUtilViewData._GetStr_Periodicidad(item.Periodicidad);
        item.StrEscuela = DataModuloEscuela._DiccEscuela.get(item.IdEscuela)?.Nombre || "";
        item.KinderFiltro = [item.IdEscuela];
        item.IntervaloBloque = item.IntervaloBloque || 0;
        item = UIUtilGeneral._ObjectAddGetters(item, {
            getValorRecargoFmt: (dato) => UIUtilViewFinanzaCargo._GetStrRecargosCol(dato),
        })
        // UIUtilViewFinanzaCargo.fn_GetStrRecargosCol(dato)
    }

    function ItemFinanzaMetodoPago(item: Entidad.IFinanzaMetodoPago) {
        item.NombreEscuela = DataModuloEscuela._DiccEscuela.get(item.IdEscuela)?.Nombre;
        item.KinderFiltro = [item.IdEscuela];
    }

    function ItemFinanzaHoraExtra(item: Entidad.IFinanzaItemHoraExtra) {
        let diccAsignacionesAlumno = DataModuloFinanzaHoraExtra._DiccHorasExtrasByAlumno;
        const asignacionesAlumno = diccAsignacionesAlumno.get(item.IdAlumno);
        let save = item.EnUso;

        if (asignacionesAlumno) {
            if (save) {
                asignacionesAlumno.set(item.Id, item);
            } else {
                asignacionesAlumno.delete(item.Id);
                if (asignacionesAlumno.size == 0) {
                    diccAsignacionesAlumno.delete(item.IdAlumno);
                }
            }
        }
        else if (save) {
            diccAsignacionesAlumno.set(item.IdAlumno, new Map([[item.Id, item]]));
        }
    }

    function ItemSesion(itemControler: ISesionVigencia) {
        if (itemControler.Vigencia <= 0) {
            console.warn("-d", "Deslogueo forzado 😒...", DataUtil._Usuario.NSesiones);
            // Main._Logout(true); // data.Utils.prop_Usuario.NSesiones == null);
            Main._LogoutByVigencia()
        }
    }

    function ItemMaestro(item: Entidad.IMaestro) {
        item.NombreCompleto = (item.Nombre + " " + item.ApPaterno + " " + item.ApMaterno).trim();
        item.KinderFiltro = item.IdEscuela ? [item.IdEscuela] : null;
        item.NombreEscuela = (DataModuloEscuela._DiccEscuela.get(item.IdEscuela)?.Nombre || "");
        item.IdsGrupos = (item.IdsGrupos || []);
        if (item.Telefono) {
            item.Telefono = item.Telefono.trim();
            if (!item.Telefono.split(" ").reverse()[1])
                item.Telefono = "+52" + " " + item.Telefono.split(" ").reverse()[0];
        }
    }

    function ItemEgresoPredefinido(item: Entidad.IFinanzaEgresoPredefinido) {
        item["KinderFiltro"] = [item.IdEscuela];
        item.IdCategoria = item.IdCategoria || 0;
        item = UIUtilGeneral._ObjectAddGetters(item, {
            getEscuelaNombre: () => DataModuloMain._GetDataValueFieldByName("Escuela", item.IdEscuela, "Nombre") || "",
            getEsValorVariable: () => UIUtilViewData._GetStr_EgresoTipo(item.TipoGasto),
            getValorFmt: () => UIUtilFormat._CurrencyFmt(item.Valor),
            getNombreCategoria: () => UIUtilViewFinanzaEgresoCategoria._CategoriaNombre(item.IdCategoria),
        })
    }

    function ItemCuentaBancaria(item: Entidad.IFinanzaCuentaBancaria) {
        item["KinderFiltro"] = [item.IdEscuela];
        item = UIUtilGeneral._ObjectAddGetters(item, {
            getNombreEscuela: () => DataModuloMain._GetDataValueFieldByName("Escuela", item.IdEscuela, "Nombre") || "",
            getTipo: () => UIUtilViewData._GetStr_TipoCuentaBancaria(item.Tipo),
        })
    }

    function ItemProveedor(item: Entidad.IFinanzaProveedor) {
        item["KinderFiltro"] = [item.IdEscuela];
        item = UIUtilGeneral._ObjectAddGetters(item, {
            getNombreEscuela: () => DataModuloMain._GetDataValueFieldByName("Escuela", item.IdEscuela, "Nombre") || "",
        })
    }

    function ItemEgresoCategoria(item: Entidad.IFinanzasEgresoCategoria) {
        item.KinderFiltro = [item.IdEscuela];
        item = UIUtilGeneral._ObjectAddGetters(item, {
            NombreKinder: () => DataModuloMain._GetDataValueFieldByName("Escuela", item.IdEscuela, "Nombre") || "",
        })
    }

    // ********************************************************************************
    // Asignaciones y complementos
    // ********************************************************************************

    // /**
    //  *
    //  * @param asignacion (Requerido)
    //  * @param saveRels (Requerido)
    //  * @param itemTutor (Opcional)
    //  * @param itemAlumno (Opcional)
    //  * @param itemEscuela (Removed)
    //  * @returns esProcesoExitoso
    //  *
    //  * @deprecated
    //  */
    // function AsignacionesTutorAlumno(asignacion: data.Entidades.ITutorAsignacion, saveRels: boolean, itemTutor?: data.Entidades.ITutor, itemAlumno?: data.Entidades.IChild /*, itemEscuela?: data.Entidades.IKinder */): boolean {
    //     let diccAlumnos = data.modulos.Ninio.DiccNinios;
    //     let diccTutores = data.modulos.Tutor.DiccTutores;
    //     // let diccEscuelas = data.modulos.Kinder.DiccKinder;
    //     // let diccTutoresDuplicados = data.modulos.Tutor.DiccTutoresDuplicados;
    //     // const idPadreEscuela = asignacion.IdPadre + "-" + asignacion.IdKinder;
    //     // const deOndeViene = itemTutor ? 1 : itemAlumno ? 2 : itemEscuela ? 3 : 0;

    //     // if (diccEscuelas.size != 0 && !itemEscuela) itemEscuela = diccEscuelas.get(asignacion.IdKinder);

    //     if (diccAlumnos.size != 0 && !itemAlumno) itemAlumno = diccAlumnos.get(asignacion.IdChild);
    //     if (diccTutores.size != 0 && !itemTutor) itemTutor = diccTutores.get(asignacion.IdPadre);

    //     if (itemTutor) {
    //         if (!itemTutor.KinderFiltro) itemTutor.KinderFiltro = [];
    //         if (!itemTutor.AlumnosAsignados) itemTutor.AlumnosAsignados = new Map();
    //         if (!itemTutor.EscuelaAlumnos) itemTutor.EscuelaAlumnos = new Map();
    //     }

    //     if (itemAlumno) {
    //         if (!itemAlumno.KinderFiltro) itemAlumno.KinderFiltro = [];
    //         // if (!itemAlumno.Tutores) itemAlumno.Tutores = new Map();
    //         if (!itemAlumno.Hermanos) itemAlumno.Hermanos = new Map();
    //     }

    //     if (/* !itemEscuela || */ !itemTutor || !itemAlumno) {
    //         // console.warn("Asignaciones Alumno-Tutor, Asig:" + asignacion + ", dato no encontrado -> Esc: ", itemEscuela, ", Alum:", itemAlumno, ", Tut:", itemTutor);
    //         return false;
    //     }

    //     if ((itemTutor && asignacion.IdPadre != itemTutor.IdPadre) ||
    //         (itemAlumno && asignacion.IdChild != itemAlumno.IdChild)
    //         // (asignacion.IdKinder != itemEscuela.IdKinder)
    //     ) {
    //         console.groupCollapsed("Asignaciones Alumno-Tutor, datos no confiables para procesar")
    //         console.warn("Asignación: ", asignacion)
    //         if (itemTutor) console.warn("idTutor: ", itemTutor.IdPadre);
    //         if (itemAlumno) console.warn("idAlumno: ", itemAlumno.IdChild);
    //         // if (itemEscuela) console.warn("idEscuela: ", itemEscuela.IdKinder);
    //         console.groupEnd();
    //         return false;
    //     }

    //     // AGREGA/ACTUALIZA ASIGNACIONES ********************
    //     if (asignacion.FechaFin == null && saveRels) {
    //         // <Asignaciones tutor
    //         if (itemTutor) {
    //             itemTutor.AlumnosAsignados.set(asignacion.IdChild, {
    //                 Asignacion: asignacion,
    //                 Alumno: itemAlumno
    //                 // Escuela: itemEscuela
    //             })
    //             if (itemTutor.EscuelaAlumnos.has(asignacion.IdKinder)) {
    //                 itemTutor.EscuelaAlumnos.get(asignacion.IdKinder).set(asignacion.IdChild, itemAlumno);
    //             } else {
    //                 let alumnoMap: Map<number, data.Entidades.IChild> = new Map();
    //                 alumnoMap.set(asignacion.IdChild, itemAlumno);
    //                 itemTutor.EscuelaAlumnos.set(asignacion.IdKinder, alumnoMap);
    //             }
    //         }
    //         // Asignaciones tutor/>

    //         // <Asignaciones alumno
    //         if (itemAlumno) {
    //             // itemAlumno.Tutores.set(asignacion.IdPadre, itemTutor);
    //             if (itemTutor && !asignacion.EsMonitoreo) { // NOTE si la relación alumno-tutor pasa de EsMonitoreo false a true o viceversa?
    //                 // Actualización de Hermanos
    //                 itemTutor.AlumnosAsignados.forEach((asignacionData, idAlumno) => {
    //                     // Solo se cuentan como Hermanos a las asignaciones del Tutor, si la asignación Alumno-Tutor no es Monitoreo
    //                     if (itemAlumno.IdChild != idAlumno && !asignacionData.Asignacion.EsMonitoreo) {
    //                         // Actualiza los hermanos del alumno actual
    //                         itemAlumno.Hermanos.set(idAlumno, asignacionData.Alumno);
    //                         // Actualiza a los hermanos del alumno actual
    //                         asignacionData.Alumno?.Hermanos.set(itemAlumno.IdChild, itemAlumno);
    //                     }
    //                 })
    //             }
    //         }
    //         // Asignacines alumno/>
    //     }
    //     // QUITA ASIGNACIONES ********************
    //     else {
    //         // // NOTE: Si el tutor aún tiene alumnos en la escuela (y era una 2da, 3ra o etc Escuela), la fn TutoresDuplicados volverá a crear un Tutor Duplicado en ella
    //         // diccTutoresDuplicados.delete(idPadreEscuela);

    //         // <Asignaciones alumno
    //         if (itemAlumno) {
    //             // itemAlumno.Tutores.delete(asignacion.IdPadre);
    //             if (itemTutor) {
    //                 // Actualización de Hermanos
    //                 itemTutor.AlumnosAsignados.forEach((asignacionData, idAlumno) => {
    //                     if (itemAlumno.IdChild != idAlumno && asignacionData.Alumno) {
    //                         // Actualiza los hermanos del alumno actual
    //                         itemAlumno.Hermanos.delete(idAlumno);
    //                         // Actualiza a los hermanos del alumno actual
    //                         asignacionData.Alumno.Hermanos.delete(itemAlumno.IdChild);
    //                     }
    //                 })
    //             }
    //         }
    //         // Asignacines alumno/>

    //         // <Asignaciones tutor
    //         if (itemTutor) {
    //             itemTutor.AlumnosAsignados.delete(asignacion.IdChild);
    //             let escuelaAlumnos = itemTutor.EscuelaAlumnos.get(asignacion.IdKinder)
    //             if (escuelaAlumnos) {
    //                 if (escuelaAlumnos.has(asignacion.IdChild)) {
    //                     escuelaAlumnos.delete(asignacion.IdChild);
    //                     if (escuelaAlumnos.size == 0) {
    //                         itemTutor.EscuelaAlumnos.delete(asignacion.IdKinder);
    //                     }
    //                 }
    //             }
    //         }
    //         // Asignaciones tutor/>
    //     }

    //     // if (itemTutor) {
    //     //     itemTutor.KinderFiltro = Array.from(itemTutor.EscuelaAlumnos.keys());
    //     //     TutoresDuplicados(itemTutor);
    //     // }
    //     return true;
    // }

    function GetHorarioGrupo(item: Entidad.IGrupo): Array<Entidad.IHorarioDia> {
        let horarios = Array<Entidad.IHorarioDia>();
        let tiempos = ["Entrada", "Salida"];
        for (let dia in Entidad.CDiaSemanal) {
            if (isNaN(Number(dia))) {
                let horario = <Entidad.IHorarioDia>undefined;
                tiempos.forEach((tiempo) => {
                    /** @example "LunesEntrada" */
                    const prop = dia + tiempo;
                    const horarioValue = item[prop] || null;
                    if (Boolean(horarioValue)) {
                        if (horario == undefined) {
                            horario = <Entidad.IHorarioDia>{};
                        }
                        horario[tiempo] = horarioValue;
                        horario.IdDia = Number(Entidad.CDiaSemanal[dia]);
                        delete item[prop];
                    }
                });
                if (Boolean(horario)) horarios.push(horario);
            }
        }

        return horarios;
    }

    // /** Recibe grupo, grado o escolaridad, pero no los tres al mismo tiempo */
    // function AsignacionesGrupoGradoEscolaridadEscuela(grupo: data.Entidades.IGrupos, grado: data.Entidades.INivel, escolaridad: data.Entidades.IEscolaridad, escuela?: data.Entidades.IKinder) {
    //     const GetGrado = (grad: data.Entidades.INivel, idGrad: number) => {
    //         if (!grad) {
    //             grad = data.modulos.Nivel.DiccNivel.get(idGrad);
    //         }
    //         return grad;
    //     }
    //     const GetEscolaridad = (escolar: data.Entidades.IEscolaridad, idEscolar: number) => {
    //         if (!escolar) {
    //             escolar = data.modulos.Escolaridad.DiccEscolaridad.get(idEscolar);
    //         }
    //         return escolar;
    //     }
    //     const GetEscuela = (esc: data.Entidades.IKinder, idEsc: number) => {
    //         if (!esc) {
    //             esc = data.modulos.Kinder.DiccKinder.get(idEsc);
    //         }
    //         return esc;
    //     }

    //     // -> Busca todos los items que puedan existir
    //     if (grupo) {
    //         escuela = GetEscuela(escuela, grupo.IdKinder);
    //         grado = GetGrado(grado, grupo.IdNivel);
    //     }

    //     if (grado) {
    //         escuela = GetEscuela(escuela, grado.IdKinder);
    //         escolaridad = GetEscolaridad(escolaridad, grado.IdEscolaridad);
    //     }

    //     if (escolaridad) {
    //         escuela = GetEscuela(escuela, escolaridad.IdEscuela);
    //     }

    //     // -> Asignaciones Grupo - Grado (Nivel)
    //     if (grado) {
    //         if (data.modulos.Nivel.DiccAsignacionNivelGrupos.has(grado.IdNivel)) {
    //             grado.IdsGrupo = data.modulos.Nivel.DiccAsignacionNivelGrupos.get(grado.IdNivel);
    //         }
    //         if (!grado.IdsGrupo) {
    //             grado.IdsGrupo = new Map();
    //         }

    //         if (!grupo && data.modulos.Nivel.DiccAsignacionNivelGrupos.get(grado.IdNivel)?.size > 0) {
    //             data.modulos.Nivel.DiccAsignacionNivelGrupos.get(grado.IdNivel).forEach(grupo => {
    //                 AsignacionesGrupoGradoEscolaridadEscuela(grupo, grado, escolaridad, escuela);
    //             })
    //         }
    //     }

    //     // -> Asignaciones Grado (Nivel) - Escolaridad
    //     if (escolaridad) {
    //         if (data.modulos.Escolaridad.DiccAsignacionEscolaridadGrados.has(escolaridad.Id)) {
    //             escolaridad.IdGrados = data.modulos.Escolaridad.DiccAsignacionEscolaridadGrados.get(escolaridad.Id);
    //         }
    //         if (!escolaridad.IdGrados) {
    //             escolaridad.IdGrados = new Map();
    //         }

    //         if (!grado && data.modulos.Escolaridad.DiccAsignacionEscolaridadGrados.get(escolaridad.Id)?.size > 0) {
    //             data.modulos.Escolaridad.DiccAsignacionEscolaridadGrados.get(escolaridad.Id).forEach(grado_2 => {
    //                 AsignacionesGrupoGradoEscolaridadEscuela(null, grado_2, escolaridad, escuela);
    //             })
    //         }
    //     }

    //     // -> Asignaciones Escolaridad - Escuela
    //     if (escuela) {
    //         if (data.modulos.Kinder.DiccAsignacionEscuelaEscolaridades.has(escuela.IdKinder)) {
    //             escuela.IdsEscolaridad = data.modulos.Kinder.DiccAsignacionEscuelaEscolaridades.get(escuela.IdKinder);
    //         }
    //         if (!escuela.IdsEscolaridad) {
    //             escuela.IdsEscolaridad = new Map();
    //         }
    //     }
    //     // else {
    //     //     console.warn("Error de asignacion Escuela-Escolaridad-Nivel-Grupos items incompletos", escuela, escolaridad, grado, grupo);
    //     //     return;
    //     // }
    // }

    // function AsignacionHorarioAlumno(item: data.Entidades.IAlumnoHorarioAsignacion) {
    //     let getNinio = data.modulos.Ninio.DiccNinios.get(item.IdNinio);

    //     /**
    //     * Asignar grupo al niño
    //     */
    //     if (getNinio) {
    //         getNinio.StrGrupos = "";
    //         if (!getNinio.IdGrupos) getNinio.IdGrupos = new Map(); // FIXME
    //         if (item.EnUso) { //si la asignacion se sigue ocupando
    //             /**
    //              * Los grupos inscritos tendran los horarios perzonalizados
    //              */
    //             let getInscritos = getNinio.IdGrupos.get(item.IdGrupo);
    //             if (getInscritos) {
    //                 getInscritos.IdHorario = item.IdHorario;
    //                 getInscritos.Horario = GetHorarioPerzoinalizado(item); //GetHorario(item as any);
    //                 getNinio.IdGrupos.set(item.IdGrupo, getInscritos);
    //             } else {
    //                 /**
    //                  * Si es una nueva asignacion se manda el horario que se da de alta desde nuevo grupo
    //                  */
    //                 //  let getGrupo = data.modulos.Grupos.DiccGrupo.get(item.IdGrupo);
    //                 let getGrupo = data.modulos.Grupos.DiccGrupo.get(item.IdGrupo); // FIXME HARD QUÉ PASA SI NO SE ENCUENTRA EL GRUPO ?
    //                 if (getGrupo) {//solo si el grupo existe
    //                     console.warn("El grupo no existe");
    //                     let copyGrupo = JSON.parse(JSON.stringify(getGrupo));//copia del item grupo para no modificar el horario gobal
    //                     copyGrupo.IdHorario = item.IdHorario;
    //                     copyGrupo.Horario = GetHorarioPerzoinalizado(item);
    //                     getNinio.IdGrupos.set(copyGrupo.IdGrupo, copyGrupo);
    //                 }
    //             }
    //         } else
    //             getNinio.IdGrupos.delete(item.IdGrupo);//Solo se elimina el grupo asignado al niño el horario aun esta asignado al grupo

    //         Array.from(getNinio.IdGrupos.values()).forEach((grupo, i) => {
    //             getNinio.StrGrupos += (i > 0 ? ", " : "") + grupo.Nombre;
    //         });
    //     }
    // }

    // // FIXME hacer un mapa de idGrupo - Alumnos
    // function AsignacionAlumInscrito(item: data.Entidades.IGrupos, itemsAignacion: data.Entidades.INinioHorarioAsignacion[] = []) {
    //     const Asignacion = (dAsignacion: data.Entidades.INinioHorarioAsignacion) => {
    //         let getNinio = data.modulos.Ninio.DiccNinios.get(dAsignacion.IdNinio);
    //         if (!dAsignacion.EnUso) {
    //             item.RelacionNinios.delete(dAsignacion.IdNinio)
    //         } else if (dAsignacion.EnUso && getNinio) { //la asignacion existe como el niño
    //             item.RelacionNinios.set(dAsignacion.IdNinio, getNinio)
    //         }
    //         // else if (!getNinio) { //el niño a sido eliminado
    //         //     item.RelacionNinios.delete(dAsignacion.IdNinio);
    //         // }
    //     }
    //     // TEMPORAL // FIXME
    //     if (itemsAignacion.length > 0) {
    //         itemsAignacion.forEach(item => {
    //             Asignacion(item);
    //         })
    //     } else {
    //         if (data.modulos.NinioHorarioAsignacion.DiccNinioHorarioAsignacion.size > 0) {
    //             data.modulos.NinioHorarioAsignacion.DiccNinioHorarioAsignacion.forEach((dAsignacion) => {
    //                 if (item.IdGrupo == dAsignacion.IdGrupo) {
    //                     Asignacion(dAsignacion);
    //                 }
    //             });
    //         }
    //     }
    // }

    // ********************************************************************************
    // Complementos de asignaciones
    // ********************************************************************************

    // function TutoresDuplicados(itemPadre: data.Entidades.ITutor) {
    //     return
    //     let diccTutoresDuplicados = data.modulos.Tutor.DiccTutoresDuplicados;
    //     let index = 0;
    //     let escuela: data.Entidades.IKinder;

    //     itemPadre.EscuelaAlumnos.forEach((alumnos, idEscuela) => {
    //         let nombreEscuela = "";
    //         let nombresAlumnos = [];
    //         let idPadreEscuela = itemPadre.IdPadre + "-" + idEscuela;
    //         if (!escuela || escuela.IdKinder != idEscuela) {
    //             escuela = data.modulos.Kinder.DiccKinder.get(idEscuela);
    //         }
    //         nombreEscuela = escuela ? escuela.Nombre : "";
    //         alumnos.forEach((itemAlumno, idAlumno) => {
    //             if (itemAlumno) {
    //                 nombresAlumnos.push(itemAlumno.Nombre);
    //             }
    //         })

    //         if (index > 0) {
    //             let itemPadreDuplicado = Object.assign({}, itemPadre);
    //             itemPadreDuplicado.AlumnosNombresEnEscuela = nombresAlumnos.join(", ");
    //             itemPadreDuplicado.NombreEscuelaAsigAlumno = nombreEscuela;
    //             itemPadreDuplicado.IdPadreEscuela = idPadreEscuela;
    //             diccTutoresDuplicados.set(itemPadreDuplicado.IdPadreEscuela, itemPadreDuplicado);
    //             itemPadreDuplicado.KinderFiltro = [idEscuela];
    //         } else {
    //             itemPadre.AlumnosNombresEnEscuela = nombresAlumnos.join(", ");
    //             itemPadre.NombreEscuelaAsigAlumno = nombreEscuela;
    //             itemPadre.IdPadreEscuela = idPadreEscuela;
    //             itemPadre.KinderFiltro = [idEscuela];
    //         }
    //         index++;
    //     })
    // }
}