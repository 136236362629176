import * as d3 from "d3";
import { HTMLTooltipComponent } from "../controlWC/TooltipComponent";
import { UIUtilLang } from "../util/Language";
import { UIUtilGeneral } from "../util/Util";
import { Button } from "./Button";
import { DropdownFlexV2 } from "./DropdownFlexV2";

export namespace MenuFlex {
    export type TOrientation = "righttoleft" | "lefttoright"; //| "toptobottom" | "bottomtotop";

    export interface IConfig {
        Items?: IMenuItemConfig[];
        Parent?: TSelectionHTML<any>;
        /** @default 3 */
        MaxHorizontalItems?: number;
        /** @default "righttoleft" */
        Orientation?: TOrientation;
        /** @default <body> */
        ResizeReference?: TSelectionHTML;
        /** @default false */
        BtnCloseVisible?: boolean;
        OnShow?: () => void;
        OnHide?: () => void;
        OnChangeParent?: (oldParent: TSelectionHTML, newParent: TSelectionHTML) => void;
        OnGetMaxWidthCallback?: () => number;
    }

    export interface IMenuItemConfig {
        Label: string | (() => string);
        /** @default true */
        Enabled?: boolean;
        Description?: string | (() => string);
        OnClick: () => void;
    }
}

type IConfig = MenuFlex.IConfig;
type IMenuItemConfig = MenuFlex.IMenuItemConfig;
interface IMenuItem extends MenuFlex.IMenuItemConfig {
    Id: string;
}

const DEFAULTCONFIG = <IConfig>{
    Items: [],
    MaxHorizontalItems: 3,
    Orientation: "righttoleft",
    ShowBtnClose: false,
}

export class MenuFlex {
    private config: IConfig;
    private controlContainer: TSelectionHTML<"div">;
    private items: IMenuItem[];
    private itemsPrincipales: IMenuItem[];
    private itemsSecundarios: IMenuItem[];
    private ctrlDropdown: DropdownFlexV2.Control; // NOTE Separar Dropdown del Select para usar como DropdownV3?
    private visible: boolean;
    private btnCloseSelection: TSelectionHTML<"div">;

    private resizeObserver: ResizeObserver;
    // private onGetMaxWidthCallback: () => number;
    // private currentReferenceObserved: HTMLElement;
    private WidthReference = 0;

    constructor(config: IConfig = DEFAULTCONFIG) {
        this.config = {
            ...DEFAULTCONFIG,
            ...config,
        }
        if (!this.config.ResizeReference) {
            this.config.ResizeReference = d3.select("body");
        }
        this.visible = false;
        this.UpdateMenuItemsConfig(this.config.Items);
        this.Init();
    }

    // **********************************************************************************************
    // PRIVATE METHODS
    // **********************************************************************************************

    private Init() {
        this.controlContainer = d3.create("div")
            .attr("class", "menuflex")
            .classed(this.config.Orientation, true);

        // this.controlContainer.append("div").attr("class", "area_close");
        this.btnCloseSelection = Button.BtnClose._GetCloseButton(this.controlContainer as any, () => this.Hide());
        this.btnCloseSelection.classed("hide", !this.config.BtnCloseVisible);

        this.controlContainer.append("div").attr("class", "area_extras hide");
        this.controlContainer.append("div").attr("class", "items_container");

        this.ctrlDropdown = new DropdownFlexV2.Control({
            OnStepItem: (container, d, step) => {
                let optionMenu = this.itemsSecundarios?.find(item => (item.Label == d.Label));
                if (optionMenu?.Description) {
                    dropdownTooltipElement._SetObservedElementsAdvanced({
                        Target: container.node(),
                        Text: this.GetItemDescripcion(optionMenu.Description),
                    })
                }
                else if (!optionMenu?.Description || step == "exit") {
                    dropdownTooltipElement._RemoveObservedELements(container.node());
                }
                container.text(this.GetItemLabelText(d.Label));
            }
        });
        const dropdownTooltipElement = this.ctrlDropdown._ListContainer
            .append<HTMLTooltipComponent>("wc-tooltip")
            .attr("position", "bottom")
            .attr("max-width", "250px")
            .node();
    }

    private ObserveResize() {
        if (!this.resizeObserver) {
            this.resizeObserver = UIUtilGeneral._GetResizeObserver((entries) => {
                if (this.visible && this.WidthReference != entries[0].contentRect.width) {
                    this.WidthReference = entries[0].contentRect.width;
                    const maxWidthAvailable = this.GetMaxWidth();
                    if (maxWidthAvailable >= 0) {
                        this.RedistribuirItems(maxWidthAvailable, false);
                    }
                }
            });
        }
        if (this.resizeObserver) {
            this.resizeObserver.observe(this.config.ResizeReference.node());
        }
    }

    private UnObserveResize() {
        this.WidthReference = 0;
        if (this.resizeObserver) {
            this.resizeObserver.unobserve(this.config.ResizeReference.node());
        }
    }

    private RedistribuirItems(maxWidthAvailable = this.GetMaxWidth(), forceRefreshView = true, maxPrincipalItems = this.config.MaxHorizontalItems) {
        const itemsPrincipalesTemp = this.items.map(d => Object.assign({}, d));
        const itemsSecundariosTemp = itemsPrincipalesTemp.splice(maxPrincipalItems, itemsPrincipalesTemp.length);

        if (itemsSecundariosTemp.length) {
            itemsPrincipalesTemp.push({
                Id: "mas",
                Label: UIUtilLang._GetUIString("general", (itemsPrincipalesTemp.length ? "mas" : "menu")),
                Enabled: true,
                OnClick: () => {
                    if (this.ctrlDropdown._Visible) {
                        this.ctrlDropdown._Hide();
                    } else {
                        this.ctrlDropdown
                            ._Show()
                            ._ListContainer
                            .style("color", "var(--color_action1)");
                    }
                }
            })
        }

        const sameItemsLength = this.itemsPrincipales?.length == itemsPrincipalesTemp.length && this.itemsSecundarios?.length == itemsSecundariosTemp.length;
        this.itemsPrincipales = itemsPrincipalesTemp;
        this.itemsSecundarios = itemsSecundariosTemp;

        if (!sameItemsLength && !forceRefreshView) {
            forceRefreshView = true;
        }
        if (!sameItemsLength || forceRefreshView) {
            this.RefreshItemsView();
        }

        const items = this.controlContainer.select<HTMLDivElement>(":scope > .items_container")
            .selectAll<HTMLDivElement, IMenuItem>(":scope > .item")
            .nodes();

        const controlContainerWidth = this.controlContainer.node().getBoundingClientRect().width;
        const itemsWidth = this.GetItemsWidth(items);
        let totalWidth = itemsWidth;
        if ((controlContainerWidth - itemsWidth) > 0) {
            totalWidth += (controlContainerWidth - itemsWidth);
        }
        if (maxWidthAvailable < totalWidth) {
            maxPrincipalItems--;
            if (maxPrincipalItems > -1) {
                this.RedistribuirItems(maxWidthAvailable, false, maxPrincipalItems);
            }
        }
    }

    private RefreshItemsView() {
        if (!this.itemsSecundarios?.length && this.ctrlDropdown._Visible) {
            this.ctrlDropdown
                ._SetMenuOptions([])
                ._Hide();
        }
        const itemsContainer = this.controlContainer.select<HTMLDivElement>(":scope > .items_container");
        let tooltipElement = itemsContainer.select<HTMLTooltipComponent>("wc-tooltip").node();
        if (!tooltipElement) {
            tooltipElement = itemsContainer.append<HTMLTooltipComponent>("wc-tooltip")
                .attr("position", "bottom")
                .attr("max-width", "250px")
                .node();
        }
        itemsContainer
            .selectAll<HTMLDivElement, IMenuItem>(":scope > .item")
            .data(this.itemsPrincipales, (d, i) => d.Id)
            .join(
                enter => {
                    let item = enter.append("div").attr("class", "item");
                    item.append("label");
                    return this.UpdateViewHorizontalItem(item);
                },
                update => this.UpdateViewHorizontalItem(update),
                exit => exit
                    .each((d, i, elements) => tooltipElement._RemoveObservedELements(elements[i]))
                    .remove()
            )
    }

    private GetItemDescripcion(descripcion: IMenuItem["Description"]): string {
        let description = "";
        if (typeof descripcion == "string") description = descripcion;
        if (typeof descripcion == "function") description = descripcion();
        return description;
    }

    private GetItemLabelText(label: IMenuItem["Label"]): string {
        let text = "";
        if (typeof label == "string") text = label;
        if (typeof label == "function") text = label();
        return text;
    }

    private UpdateViewHorizontalItem(item: TSelectionHTML<"div", IMenuItem>) {
        return item
            .classed("disabled", d => !d.Enabled)
            .each((d, i, elements) => {
                const itemElement = elements[i];
                const itemSelection = d3.select(itemElement);
                itemSelection.select("label").text(this.GetItemLabelText(d.Label))
                if (d.Id == "mas") {
                    if (!itemElement.querySelector(".btn_mas")) {
                        itemElement.append(this.GetMasIcon());
                    }
                    this.ctrlDropdown
                        ._SetParent(itemSelection as any)
                        ._SetMenuOptions(this.itemsSecundarios);
                }
                else if (itemElement.querySelector(".btn_mas")) {
                    itemSelection.select(".btn_mas").remove();
                }

                const tooltipElement = itemElement.parentElement.querySelector<HTMLTooltipComponent>("wc-tooltip");
                if (d.Description) {
                    tooltipElement._SetObservedElementsAdvanced({
                        Target: itemElement,
                        HTMLBasic: this.GetItemDescripcion(d.Description),
                    });
                } else {
                    tooltipElement._RemoveObservedELements(itemElement);
                    itemElement.onmouseover = null;
                }

                if (d.Enabled) {
                    itemElement.onclick = (e) => {
                        d.OnClick();
                        e.stopPropagation();
                    }
                }
                else {
                    itemElement.onclick = (e) => e.stopPropagation();
                }
            })
    }

    private GetMasIcon() {
        const icon = d3.create<SVGElement>("svg")
            .attr("class", "btn_mas")
            .attr("viewBox", "0 0 20 12")
            .attr("width", 20)
            .attr("height", 12)

        icon.append<SVGPathElement>("path")
            .attr("d", "M 5 5 l 12 0 l -6 5 z").attr("stroke-width", 1)
            .attr("fill", "rgb(30, 144, 255)");

        return icon.node();
    }

    private GetItemsWidth(items: HTMLDivElement[]) {
        let itemsWidth = 0;
        items.forEach((d, i, arr) => {
            itemsWidth += d.offsetWidth;
        });

        return itemsWidth;
    }

    private GetMaxWidth(): number {
        let maxWidth = 0;
        if (this.config.OnGetMaxWidthCallback) {
            maxWidth = this.config.OnGetMaxWidthCallback();
        } else {
            maxWidth = this.config.ResizeReference.node().getBoundingClientRect().width;
        }
        return maxWidth;
    }

    private Show() {
        this.config.Parent.append(() => this.controlContainer.node());
        this.RedistribuirItems();
        this.ObserveResize();
        this.visible = true;
        if (this.config.OnShow) {
            this.config.OnShow();
        }
    }

    private Hide() {
        this.controlContainer.remove();
        this.ctrlDropdown._Hide();
        this.itemsPrincipales = null;
        this.itemsSecundarios = null;
        this.UnObserveResize();
        this.visible = false;
        if (this.config.OnHide) {
            this.config.OnHide();
        }
    }

    private ChangeParent(newParent: TSelectionHTML) {
        const visible = this.visible;
        const oldParent = this.config.Parent;
        // const parentChanged = ;
        if (oldParent?.node() != newParent.node()) {
            this.config.Parent = d3.select(newParent.node());

            if (this.config.OnChangeParent) {
                this.config.OnChangeParent(oldParent, newParent);
            }
            // } else {
            //     return;

            if (visible) {
                this.controlContainer.remove();
                this.ctrlDropdown._Hide();
                this.UnObserveResize();

                this.config.Parent.append(() => this.controlContainer.node());
                this.visible = false;
                this.ObserveResize();
                this.visible = true;
                this.itemsPrincipales = null;
                this.itemsSecundarios = null;
                this.RedistribuirItems();
            }
        }
    }

    private UpdateMenuItemsConfig(items: IMenuItemConfig[]) {
        this.items = items
            .map((d, i) => {
                let dd: IMenuItem = <IMenuItem>Object.assign({}, d);

                dd.Id = i.toString();
                if (dd.Enabled == null) {
                    dd.Enabled = true;
                }
                return dd;
            });
        if (this.visible) {
            this.itemsPrincipales = null;
            this.itemsSecundarios = null;
            this.RedistribuirItems();
        }
    }

    // **********************************************************************************************
    // PUBLIC METHODS
    // **********************************************************************************************

    public _SetMaxHorizontalItems(maxItems: number) {
        const updateView = this.config.MaxHorizontalItems != maxItems;
        this.config.MaxHorizontalItems = maxItems;
        if (this.visible && updateView) {
            this.itemsPrincipales = null;
            this.itemsSecundarios = null;
            this.RedistribuirItems();
        }
        return this;
    }

    public _UpdateMenuOptions(options: IMenuItemConfig[]) {
        this.UpdateMenuItemsConfig(options);
        return this;
    }

    public _SetOnGetMaxWidthCallback(callback: () => number) {
        this.config.OnGetMaxWidthCallback = callback;
        return this;
    }

    public _OnShow(callback: () => void) {
        this.config.OnShow = callback;
        return this;
    }

    public _OnHide(callback: () => void) {
        this.config.OnHide = callback;
        return this;
    }

    public _OnParentChanged(callback: (oldParent: TSelectionHTML, newParent: TSelectionHTML) => void) {
        this.config.OnChangeParent = callback;
        return this;
    }

    public _SetDescription(description: string) {
        this.controlContainer.select(":scope > .area_extras")
            .text(description)
            .classed("hide", !description);
        return this;
    }

    public _SetBtnCloseVisible(visible: boolean) {
        this.btnCloseSelection.classed("hide", !visible);
        return this;
    }

    public _SetOrientation(orientation: MenuFlex.TOrientation) {
        if (this.config.Orientation != orientation) {
            this.controlContainer.classed(this.config.Orientation, false);
        }
        this.config.Orientation = orientation;
        this.controlContainer.classed(orientation, true);
        return this;
    }

    public _SetResizeReferenceSelection(elementReference: TSelectionHTML) {
        if (this.visible && this.config.ResizeReference.node() != elementReference.node()) {
            this.UnObserveResize();
        }
        this.config.ResizeReference = d3.select(elementReference.node());
        if (this.visible) {
            this.ObserveResize();
        }
        return this;
    }

    public _SetParent(parent: TSelectionHTML) {
        this.ChangeParent(parent);
        return this;
    }

    public _Show() {
        this.Show();
        return this;
    }

    public _Hide() {
        this.Hide();
        return this;
    }

    public _Refresh() {
        this.RedistribuirItems();
        return this;
    }

    public get _ControlContainer() {
        return this.controlContainer;
    }

    public get _Visible() {
        return this.visible;
    }

    public get _ItemsLength() {
        return this.items.length;
    }
}
