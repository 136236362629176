import { Entidad } from "../Entidad";
import { DModelBase } from "./MBase";

type IUsuarioSesion = Entidad.IUsuarioSesion;
export class DMUsuarioSesion extends DModelBase<IUsuarioSesion> implements IUsuarioSesion {
    constructor(user: IUsuarioSesion) {
        super(user);
    }

    get _PerfilAdmin() {
        return this.Perfil == Entidad.CTipoPerfil.Admin;
    }
    get _PerfilSuperUsuario() {
        return this.Perfil == Entidad.CTipoPerfil.SuperUsuario;
    }
    get _PerfilLimitado() {
        return this.Perfil == Entidad.CTipoPerfil.Limitado;
    }

    readonly ApMaterno: string;
    readonly ApPaterno: string;
    /** @deprecated SIN USO */
    readonly IdKinder: number;
    readonly IdOrganizacion: number;
    readonly IdSesion: number;
    readonly IdUsuario: number;
    readonly Nombre: string;
    /** @deprecated SIN USO */
    readonly PaqueteBasico: boolean;
    readonly Perfil: Entidad.CTipoPerfil;
    readonly Correo: string;
    NSesiones: number;
    IMEI: string;
    DeviceType: 1 | 2 | 3;
}
