import * as d3 from "d3";
import { UIUtilGeneral } from "../util/Util";

export namespace TreeViewBase {
    type TIdType = (string | number);

    export interface IControlConfig<TDatasInLevels extends Array<any>, KToAddInViewItem = any> {
        ParentContainer: d3.Selection<HTMLElement, any, any, any>;
        /** Si es true: se usará LevelsConfig[0].OnGetData, que se invocará Haciendo _UpdateData al control (no importa ) */
        UseGetDataOfFirstLevel?: boolean;
        LevelsConfig: TLevelsConfig<TDatasInLevels, KToAddInViewItem>;
    }

    export type TLevelsConfig<TDatasInLevels extends Array<any>, KToAddInViewItem = any> = {
        [k in keyof TDatasInLevels]: ILevelConfig<TDatasInLevels[k], KToAddInViewItem>
    }

    export interface ILevelConfig<TData, KToAddInViewItem = any> {
        OnGetData: (parent: ITreeItemToShare<any, any, TData>) => Array<TData>;
        /** NOTA: El valor de esta propiedad debe de ser unico en éste nivel con respecto a su Padre
         * (Los valores de la propiedad pueden repetirse siempre que no pertenescan al mismo Padre)
         */
        IdMember?: keyof TData & string;

        // Data_OnCreateItemData?: (data: TLevelData, parents: Array<any>) => void;
        Data_OnUpdateTreeDataItem?: <VLevel extends number>(data: (ITreeViewItem<KToAddInViewItem, VLevel> & KToAddInViewItem), lastItemData: (ITreeViewItem<KToAddInViewItem, VLevel> & KToAddInViewItem), isLastItemReliable: boolean, parent: ITreeItemToShare<any, any, TData>, level: VLevel) => void;

        UI_OnUpdateItem: (itemContainer: TSelectionHTML<"div">, stepDato: TData) => void;

        UI_OnCreateItemTemplate: (itemContainer: TSelectionHTML<"div">, stepDAto: TData) => void;
        // UI_OnD3Join_RemoveItem?: (container: SelectionHTML<"div">) => void;
    }

    // export interface ILevelConfigAdvanced extends ILevelConfig<any> { }

    export interface ITreeItemToShare<TData, TDataParent, TDataChilds> {
        Parent: ITreeItemToShare<TDataParent, any, TData>;
        Childs: ITreeItemToShare<TDataChilds, TData, any>[];
        Data: TData;
    }

    export interface ITreeViewItem<KToAddInViewItem, TData, TParentData = any, TChildsData = any> { // FIXME // TEMPORAL Implementar Tipos de Padres e Hijos
        Id: TIdType;
        Data: TData;
        Parent: ITreeViewItem<KToAddInViewItem, TParentData, any, TData> & KToAddInViewItem;
        Childs: Map<TIdType, ITreeViewItem<KToAddInViewItem, TChildsData, TData, any> & KToAddInViewItem>;
        Level: number;

        /** Datos Hijos actuales confiables
         * 
         * Nota: la fiabilidad de los datos gira en torno al ID encontrado, si en el grupo de hijos (nivel inferior),
         * existen IDs repetidos o no existen, la rama a partir de este item es inviable para conservar algún estado en la proxima Actualización de los datos
         * 
         * Nota: cualquier "estado" definido por alguna implementación del control,
         * puede ser conservada despues de actualizar los datos con ayuda del valor del "IdMember"
         */
        Status_IsReliableCurrentDataChild: boolean;
    }

    export class TreeView<TDatasInLevels extends Array<any>, KToAddInViewItem = any> {
        private config: IControlConfig<TDatasInLevels, KToAddInViewItem>;
        private levelsConfig: Map<number, ILevelConfig<any>>;

        private controlContainer: TSelectionHTML<"div">;

        private originData: Array<TDatasInLevels[0]>
        protected TreeBase_TreeData: Map<TIdType, ITreeViewItem<KToAddInViewItem, keyof TDatasInLevels> & KToAddInViewItem>;

        /** Datos actuales confiables, Nivel "Padre" */
        private statusReliableCurrentData: boolean;

        // @ts-ignore // FIXME implementar
        private intersectedObserverRowsPrincipal: IntersectionObserver;

        constructor() { }

        private InitConfigAndControl(config: IControlConfig<TDatasInLevels>) {
            this.config = config;

            this.levelsConfig = new Map(this.config.LevelsConfig.map((configL, i) => {
                return [i, configL];
            }))
            // {
            //     // Default config
            //     ...{
            //         Data: []
            //     },
            //     // Develop config 
            //     ...config
            // }
            this.Init();
        }

        private Init() {
            this.Data_UpdateDataByLevel_0();
            this.UI_InitBuild();
            // this.UI_InitControllerIntersectedObserver(); // NOTE Inicializar IntersectingObserver
            this.UI_UpdateLevelList(this.controlContainer, this.TreeBase_TreeData, 0);
        }

        // *****************************************************
        // DATA THINGS
        // *****************************************************

        private Data_UpdateDataByLevel_0() {
            let levelZeroConfig = this.levelsConfig.get(0);
            let dataToInit = [];
            if (levelZeroConfig.OnGetData) {
                dataToInit = levelZeroConfig.OnGetData(null);
            }

            this.Data_UpdateTreeData(dataToInit);
        }

        private Data_UpdateTreeData(dataList: Array<TDatasInLevels[0]>) {
            this.originData = dataList;

            let reliablePastData = this.statusReliableCurrentData;
            this.statusReliableCurrentData = true;

            this.TreeBase_TreeData = this.Data_IteratorUpdateData(0, reliablePastData, dataList, this.TreeBase_TreeData, null);
        }

        private Data_IteratorUpdateData<VCurrLevel extends number, TDato extends TDatasInLevels[VCurrLevel]>(level: VCurrLevel, reliablePastData: boolean, newDataList: Array<TDato>, lastItemsTable: Map<TIdType, ITreeViewItem<KToAddInViewItem, TDato> & KToAddInViewItem>, parentItem?: ITreeViewItem<KToAddInViewItem, any> & KToAddInViewItem) {
            let levelConfig = this.levelsConfig.get(level);
            let idsToValidateReset = [];
            let idMember = levelConfig.IdMember;

            let reliableCurrentData = true;
            const parentTreeItemToShare = this.Data_GetTreeItemToShare(parentItem, level - 1);

            let newTreeViewItems: Map<TIdType, ITreeViewItem<KToAddInViewItem, TDato> & KToAddInViewItem> = new Map(newDataList.map((newDato, i) => {
                // -> Crear Identificador *****************
                let ID: TIdType = ("falseID-" + i);
                if (idMember) { // if (this.config.IdData) {
                    ID = newDato[idMember]; // dat[this.config.IdData];
                    ID = (typeof ID == "string") ? ID.trim() : (typeof ID == "number") ? ID : null;

                    if (ID !== null && ID !== "") {
                        const indexFound = idsToValidateReset.indexOf(ID);
                        idsToValidateReset.push(ID);

                        if (indexFound >= 0) {
                            reliableCurrentData = false;
                            // if (!this.propiedades.ResetDataChecked) { this.propiedades.ResetDataChecked = true; }
                            console.warn(`Se han encontrado registros con el mismo Identificador de fila -> Level: ${level}, index: ${i}, IdData (${idMember}): ${ID} -> Change to: "falseID-${i}"`);
                            ID = "falseID-" + i;
                        }
                    } else {
                        ID = "NotFoundId-" + i;
                        reliableCurrentData = false;
                        // if (!this.propiedades.ResetDataChecked) { this.propiedades.ResetDataChecked = true; }
                        console.warn(idMember + " (NoFoundId): ", newDato);
                    }
                } else {
                    reliableCurrentData = false;
                }

                // -> Obtener item anterior con el mismo ID
                let lastSameItem: ITreeViewItem<KToAddInViewItem, TDato> & KToAddInViewItem;
                if (reliablePastData) {
                    lastSameItem = lastItemsTable.get(ID);
                }

                // -> Crear item
                let itemResult: (ITreeViewItem<KToAddInViewItem, TDato> & KToAddInViewItem) = {
                    Id: ID,
                    Data: newDato,
                    Parent: parentItem,
                    Childs: new Map(),
                    Level: level,
                    Status_IsReliableCurrentDataChild: reliableCurrentData
                } as any;

                // const parentsInTree = this.Data_GetParentsOfTreeViewItem(itemResult).map(d => d.Data);

                // -> Verifica subniveles
                if (this.levelsConfig.has(level + 1)) {
                    const thisTreeItemToShare = this.Data_GetTreeItemToShare(itemResult, level);
                    let subData = this.levelsConfig.get(level + 1).OnGetData(thisTreeItemToShare);

                    let lastDataCollapser = (lastSameItem?.Childs ? lastSameItem.Childs : itemResult.Childs);
                    itemResult.Childs = this.Data_IteratorUpdateData((level + 1), itemResult.Status_IsReliableCurrentDataChild, (subData ? subData : []), lastDataCollapser, itemResult);
                }

                if (levelConfig.Data_OnUpdateTreeDataItem) {
                    levelConfig.Data_OnUpdateTreeDataItem<VCurrLevel>(itemResult, lastSameItem, reliablePastData, parentTreeItemToShare, level);
                }

                return [ID, itemResult as ITreeViewItem<KToAddInViewItem, TDato> & KToAddInViewItem];
            }))

            return newTreeViewItems;
        }

        /** Retorna arreglo de itemsParent en orden ascendente de acuerdo al nivel del itemTable,
         * * NOTE: [0] == PadreInmediato */
        // @ts-ignore
        private Data_GetParentsOfTreeViewItem(itemTable: ITreeViewItem<any>): (ITreeViewItem<KToAddInViewItem, any> & KToAddInViewItem)[] {
            let parents: (ITreeViewItem<KToAddInViewItem, any> & KToAddInViewItem)[] = [];
            const SearchParents = (itemT: ITreeViewItem<KToAddInViewItem, any> & KToAddInViewItem) => {
                parents.push(itemT);
                if (itemT.Parent) {
                    SearchParents(itemT.Parent);
                }
            }
            if (itemTable?.Parent) {
                SearchParents(itemTable.Parent);
            }
            return parents;
        }

        private Data_GetTreeItemToShare<VLevel extends keyof TDatasInLevels & number>(treeItem: (ITreeViewItem<KToAddInViewItem, any> & KToAddInViewItem), level: VLevel) {
            let item: ITreeItemToShare<TDatasInLevels[VLevel], any, any>

            if (treeItem) {
                item = {
                    Parent: treeItem.Parent ? this.Data_GetTreeItemToShare(treeItem.Parent, treeItem.Parent.Level) : null,
                    Childs: treeItem.Childs.size > 0 ? Array.from(treeItem.Childs.values()).map(d => this.Data_GetTreeItemToShare(d, level + 1)) : null,
                    Data: treeItem.Data
                }
            }
            return item;
        }

        // *****************************************************
        // UI THINGS
        // *****************************************************

        // class: treeview_container -> contenedorUI del árbol
        // class: treeview_itemcontainer -> itemUI de un nivel
        // class: treeview_itemcontent -> itemUI de un nivel
        // class: treeview_childscontainer -> contenedor de los hijos de un nivel dentro de un itemUI

        private UI_InitBuild() {
            this.controlContainer = d3.create("div")
                .classed("treeview_container", true)
                .classed(UIUtilGeneral.FBoxOrientation.Vertical, true);

            if (this.config.ParentContainer) {
                this.config.ParentContainer.append(() => this.controlContainer.node());
            }
        }

        protected UI_UpdateLevelList<VLevel extends number>(container: TSelectionHTML<"div">, dataList: Map<TIdType, TDatasInLevels[VLevel]>, level: VLevel) {
            let dataToBuild = Array.from(dataList.values());

            container.selectAll<HTMLDivElement, TDatasInLevels[VLevel]>(":scope > .treeview_itemcontainer").data(dataToBuild).join(
                enter => {
                    let item = enter.append("div")
                        .classed("treeview_itemcontainer", true);

                    return this.UI_BuilbTemplateItem(item, "enter", level)
                },
                update => this.UI_BuilbTemplateItem(update, "update", level),
                exit => exit.remove()
            )
        }

        private UI_BuilbTemplateItem<VLevel extends keyof TDatasInLevels & number>(itemListUI: TSelectionHTML<"div", TDatasInLevels[VLevel]>, step: ("enter" | "update"), level: VLevel) {
            let content = itemListUI.select(":scope > .treeview_itemcontent");
            if (!content.node()) {
                // -> Crea un template ligero predefinido
                content = itemListUI
                    .classed(UIUtilGeneral.FBoxOrientation.Vertical, true)
                    .append("div")
                    .classed("treeview_itemcontent", true);

                content.nodes().forEach(d => d["_isFinalItem"] = false);
            }

            // TEMPORAL Pasar step item a IntersectingObserver
            itemListUI.each((d, i, items) => {
                this.UI_UpdateItemList(d3.select(items[i]), d, level);
            })
            return itemListUI;
        }

        private UI_UpdateItemList<VLevel extends keyof TDatasInLevels & number>(itemListUI: TSelectionHTML<"div", TDatasInLevels[VLevel]>, datum: ITreeViewItem<KToAddInViewItem, TDatasInLevels[VLevel]> & KToAddInViewItem, level: VLevel) {
            const levelConfig = this.levelsConfig.get(level);
            const content = itemListUI.select<HTMLDivElement>(":scope > .treeview_itemcontent");

            if (content.node()["_isFinalItem"] == false && levelConfig.UI_OnCreateItemTemplate) {
                levelConfig.UI_OnCreateItemTemplate(content, datum.Data);
                content.node()["_isFinalItem"] = true;
            }

            // Step item
            if (levelConfig.UI_OnUpdateItem) {
                levelConfig.UI_OnUpdateItem(content, datum.Data);
            }

            // // -> Childs things

            // if (datum.Childs.size > 0 && datum.Status_ChildsVisibles) {
            //     let childsContainer = itemListUI.select<HTMLDivElement>(":scope > .treeview_childscontainer");

            //     if (!childsContainer.node()) {
            //         childsContainer = itemListUI.append("div")
            //             .classed("treeview_childscontainer", true)
            //     }

            //     this.UI_UpdateList(childsContainer, datum.Childs, level + 1);
            //     childsContainer.raise();

            // } else {
            //     itemListUI.select(":scope > .treeview_childscontainer").remove()
            // }
        }

        // @ts-ignore // FIXME IMPLEMENTAR USO
        private UI_InitControllerIntersectedObserver() {
            this.intersectedObserverRowsPrincipal?.disconnect();
            if (this.config.ParentContainer.node()) {
                this.intersectedObserverRowsPrincipal = new IntersectionObserver((entries) => {

                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {

                            if (entry.target["_UpdatedView"]) {
                                return;
                            }
                            entry.target["_UpdatedView"] = true;

                            let itemContainer = d3.select<HTMLDivElement, ITreeViewItem<KToAddInViewItem, any> & KToAddInViewItem>(entry.target as HTMLDivElement)

                            let datum = itemContainer.datum();

                            this.UI_UpdateItemList(itemContainer, datum, datum.Level);
                        }
                    })

                    // console.warn(entries.length, nodesVisible.length, nodesVisible, nodesNoVisible);
                    // }, 100);
                }, {
                    root: this.config.ParentContainer.node(),
                    // threshold: 0
                    // rootMargin: "50px"
                });
            } else {
                console.warn("Intersect observer is not init, parent node no found");
            }
        }

        // *****************************************************
        // PRIVATE PROPERTIES
        // *****************************************************

        public get _ControlContainer() {
            return this.controlContainer;
        }

        public get _Data() {
            return this.originData;
        }

        public _GetParent() {
            return this.config.ParentContainer;
        }

        // public met_SetParent(parentContainer: d3.Selection<HTMLElement, any, any, any>) {
        //     this.config.PatentContainer = parentContainer;
        //     parentContainer.append(() => this.controlContainer.node());
        //     // this.UI_InitControllerIntersectedObserver(); // NOTE Re-Inicializar IntersectingObserver
        //     return this;
        // }

        public _UpdateData(dataList: Array<TDatasInLevels[0]> = []) {
            if (this.config.UseGetDataOfFirstLevel) {
                this.Data_UpdateDataByLevel_0();
            } else {
                this.Data_UpdateTreeData(dataList);
            }
            return this;
        }

        public _RefreshView() {
            if (this["__RefreshTimeout"]) clearTimeout(this["__RefreshTimeout"]);
            this["__RefreshTimeout"] = setTimeout(() => {
                this["__RefreshTimeout"] = null;
                this.UI_UpdateLevelList(this.controlContainer, this.TreeBase_TreeData, 0);
            }, 20);
            return this;
        }

        public _SetConfig(config: IControlConfig<TDatasInLevels>) {
            this.InitConfigAndControl(config)
        }
    }
}
