export namespace UIUtilFormat {
    const currencyFormater: Intl.NumberFormat = new Intl.NumberFormat("es-MX", { style: "currency", currency: "MXN", minimumFractionDigits: 2, });
    const percentFormater: Intl.NumberFormat = new Intl.NumberFormat("es-MX", { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 });

    export const _EMAILREGEX = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    /** @returns Ej: "$10.00" (Moneda mexicana) */
    export function _CurrencyFmt(value: number) {
        return currencyFormater.format(value);
    }

    /**
     * @param value Ej: 10 -> "10%"", 0.5 -> "0.5"%
     */
    export function _PercentFmt(value: number) {
        return percentFormater.format(value / 100);
    }

    /**
     * 
     * @param cardNumber "340000000000009" | "340000XXXXX0009"
     * @returns "411X XXXX XXXX 1111"
     */
    export function _CardNumberFmt(cardNumber: string): string {
        let maskCardNumber = "";
        let maskFormat = [];

        for (let i = 0; i < cardNumber.length; i++) {
            if (i < 3 || i > cardNumber.length - 5) maskCardNumber += cardNumber.charAt(i);
            else maskCardNumber += "X";

            if ((i + 1) % 4 == 0) {
                maskCardNumber += " ";
                maskFormat.push(maskCardNumber);
                maskCardNumber = "";
            }
        }

        return maskFormat.join("");
    }
}