import saveAs from "file-saver";
import JSZip from "jszip";
import { Entidad } from "../../../data/Entidad";
import { IPagoInfoFacturar } from "../../../data/entidad/Factura";
import { _ObtenerArchivoCFDI } from "../../../data/modulo/FacturaCFDI";
import { DataUtilAlertBot } from "../../../data/util/AlertBot";
import _L, { TLabelTag, _HttpMsgV2 } from "../../../util/Labels";
import { Button } from "../../controlD3/Button";
import { List } from "../../controlD3/List";
import { ModalThings } from "../../controlD3/ModalThings";
import { NotificacionV2 } from "../../controlD3/NotificacionV2";
import { HTMLFileIcoElement } from "../../controlWC/FileIcoComponent";
import { HTMLProgressElement } from "../../controlWC/ProgressComponent";
import { UIUtilElement } from "../../util/Element";
import { UIUtilGeneral } from "../../util/Util";
import ic_sync from '/image/iconos/ic_sync.svg?raw';

export namespace AlumnoEdoCtaFacturacionUtil {
    export const _MODULO_ALUMNO_EDOCTA = Entidad.CModulo.PanelFinanzasEdoCuenta;

    export function _CreateListControl<T>(options?: { addMarginBottom?: boolean, addMarginTop?: boolean }) {
        const list = new List<T>()
        list._ListContainerSelection.style("border-top", "1px solid var(--color_borderbox2)")
        if (options?.addMarginBottom) list._ListContainerSelection.style("margin-bottom", "50px")
        if (options?.addMarginTop) list._ListContainerSelection.style("margin-top", "var(--padding2)")
        return list
    }

    export function _CreateSinRegistrosComponent(container: TSelectionHTML) {
        type Component = HTMLElement & {
            _SetText(text: string): Component
            _Visible(visible: boolean): Component
        }
        const element: Component = UIUtilElement._CreateElementFromHTML(
            `<div><div style="margin: var(--padding2) auto; width: max-content;">
                TEXTO
            </div></div>`) as any
        element._SetText = (text) => ((element.querySelector(":scope>div").textContent = text), element)
        element._Visible = (visible) => ((visible ? container.node().append(element) : element.remove()), element)
        return element
    }

    export const _ViewRowInfo = (tag: TLabelTag, txt: string, br = true, infoExtra?: boolean) => {
        return _ViewRowInfoBase(_L(tag), txt, br, infoExtra)
    }

    export const _ViewRowInfoBase = (tag: string, txt: string, br = true, esInfoExtra?: boolean) => {
        const fontSizePxDif = esInfoExtra ? "3px" : null
        const style = fontSizePxDif ? `style="font-size: calc(var(--fontsize) - ${fontSizePxDif});"` : ""
        return (br ? "<br>" : "")
            + `<b ${style}>${tag || ""}${(!txt || !tag) ? "" : ":"}</b> <span ${style}>${txt}</span>`;
    }

    export const _ViewItemListCheck = (container: TSelectionHTML, checked: boolean, contentHTML: string) => container
        .classed(`${UIUtilGeneral.FBoxOrientation.Horizontal} ${UIUtilGeneral.FBoxAlign.StartCenter}`, true)
        .style("gap", "var(--padding2)")
        .style("cursor", "pointer")
        .html(
            `<div class="checkbox_cont">
                <wc-checkbox
                    style-form="circle-check"
                    ${checked ? "checked" : ""}
                    style="width:20px;height:20px;"
                    disabled>
                </wc-checkbox>
            </div>
            <div class="listitem_cont_info" style="width:100%">
                ${contentHTML}
            </div>`
        )

    export function _ViewAddSyncButton(container: TSelectionHTML, call: Function) {
        let btn = container.append<HTMLDivElement>("wc-button")
            .style("width", "20px")
            .style("height", "20px")
            .style("position", "absolute")
            .style("top", "var(--padding2)")
            .style("right", "var(--padding2)")
            .on("click", () => call())

        const svgIco = UIUtilElement._CreateElementFromHTML(ic_sync)
        svgIco.style.width = "100%"
        svgIco.style.height = "100%"
        btn.property("_HtmlElement", svgIco)
    }

    export function _OpenModal_ViewDescargarCFDIFiles_ByFolio(UUID: string, idEscuela: number, idFolio: number) {
        ModalThings._GetModalSimple({
            Title: _L("panelfinanzasedocuenta_factura.cfdi") + " ..." + UUID,
            Width: 250,
            DrawContent: (container, mt) => {
                container
                    .style("display", "flex")
                    .style("flex-direction", "column")
                    .style("gap", "var(--padding2)")

                _ViewDescargarCFDIFiles(container, idEscuela, idFolio, mt.Progress.node())

                new Button(container, _L("general.descargartodo"))._d3Selection
                    .style("margin-top", "var(--padding1)")
                    .on("click", () => _DescargarCFDIsZip(null, idEscuela, [idFolio], mt.Progress.node()))
            }
        })
    }

    export function _ViewDescargarCFDIFiles(container: TSelectionHTML, idEscuela: number, idFolio: number, progressBar: HTMLProgressElement) {
        container.append("label").text(_L("panelfinanzasedocuenta_factura.descargar_doc") + ":")
        container.append("div").call((cont) => {
            const filesExt = ["xml", "pdf"] as const
            cont.classed(UIUtilGeneral.FBoxOrientation.Horizontal, true)
                .style("gap", "var(--padding2)")
            filesExt.forEach(ext => cont.append<HTMLFileIcoElement>("wc-fileico")
                .attr("ext", ext.toUpperCase())
                .style("width", "45px")
                .style("height", "55px")
                .style("cursor", "pointer")
                .on("click", () => _DescargarCFDI(idEscuela, idFolio, ext, progressBar)))
        })
    }

    function _DescargarCFDI(idEscuela: number, idFolio: number, ext: "xml" | "pdf", progressBar: HTMLProgressElement) {
        progressBar._Visible = true
        _ObtenerArchivoCFDI(idEscuela, idFolio, ext)
            .then((res) => {
                if (res.Resultado <= 0) {
                    NotificacionV2._Mostrar(_HttpMsgV2(res, "consulta"), "ADVERTENCIA")
                    return
                }
                saveAs(res.Datos)
            })
            .finally(() => {
                progressBar._Visible = false
            })
    }

    /** Consulta .xml, .pdf */
    export async function _DescargarCFDIsZip(folderName: string | null, idEscuela: number, idsFolios: number[], progressBar: HTMLProgressElement) {
        progressBar._Visible = true
        const filesExt = ["xml", "pdf"] as const
        const files: File[] = []
        for (const idFolio of idsFolios) {
            for (const ext of filesExt) {
                const res = await _ObtenerArchivoCFDI(idEscuela, idFolio, ext)
                if (res.Resultado <= 0 || !res.Datos) {
                    NotificacionV2._Mostrar(_HttpMsgV2(res, "consulta"), "ADVERTENCIA")
                    progressBar._Visible = false
                    return
                }
                files.push(res.Datos)
            }
        }
        progressBar._Visible = false
        const folderNameFinal = idsFolios.length == 1 ? files[0].name.split(".")[0] : folderName ? (folderName + "_CFDIs") : "CFDIs"
        const zip = new JSZip();
        const resumenFolder = zip.folder(folderNameFinal);
        for (const file of files) {
            resumenFolder.file(file.name, file)
        }
        zip.generateAsync({ type: "blob" })
            .then(function (content) {
                saveAs(content, folderNameFinal);
            })
            .catch((e) => {
                DataUtilAlertBot._SendError(e, `Descarga CFDIs zip`);
                NotificacionV2._Mostrar(_L("general.notif_fail"), "ADVERTENCIA")
            })
    }

    export type IMovimientoPagosAgrupados = Pick<IPagoInfoFacturar, "Monto" | "FechaPago" | "FechaPagoDia" | "NombreMetodoPago" | "IdMetodoPago" | "IdFormaPagoSAT" | "GrupoCFDI"> & {
        ID: string
        IdsPagos: number[]
        Pagos: IPagoInfoFacturar[]
    }
}