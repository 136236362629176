import { group as d3Group } from "d3-array";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import { _GetCovidEncuestaList } from "../../data/modulo/Covid";
import DataModuloEscuela from "../../data/modulo/Escuela";
import DataModuloTutor from "../../data/modulo/Tutor";
import { IConfigGridExcelExport, IGridExtraTableConfig, IGridRenderInfo, VentanaGrid } from "../controlD3/AVentanaGrid";
import { ExcelThings } from "../controlD3/ExcelExport";
import { Table } from "../controlD3/Tabla";
import { UIUtilLang } from "../util/Language";
import { UIUtilTime } from "../util/Time";
import { UIUtilGeneral } from "../util/Util";
import { UIUtilViewData } from "../util/ViewData";
import { UIUtilViewAlumno } from "../utilView/Alumno";
import { UIUtilViewGrupos } from "../utilView/Grupos";

import ICovidEncuesta = Entidad.ICovidEncuesta;
import IChild = Entidad.IAlumno;
import IEscuela = Entidad.IEscuela;

interface IEncuesta {
    /**  Auxiliar */
    Id: number;
    // Tutor: ITutor;
    Encuesta: ICovidEncuesta;

    Parent: ICovidEncuestaData;
    StrTutorNombre: string;
    Fecha: string;
    Resultado: number;
}

interface ICovidEncuestaData {
    IdChild: number,
    Alumno: IChild;
    Escuela: IEscuela;
    Encuestas: IEncuesta[];
    KinderFiltro: number[];

    StrAlumnoNombre: string;
    StrGrupoPrincipal: string;
    StrGrupoPrincipalNames: string[];
}

export class UIVentanaCovidEncuesta extends VentanaGrid<ICovidEncuestaData> {
    private forceDownloadData: boolean;

    constructor(content: TSelectionHTML<"div">, modulo: Entidad.CModulo) {
        super(content, modulo, "covidencuestaresultados");
    }

    protected GridInitTable() {
        this.forceDownloadData = true;
        super.GridInitTable();
        // this.UpdateGridData();
        // this.InitConfigModal();
    }

    public _OnServiceEvent(requestID: DataModuloMain.TipoRequestMonitorId) {
        super._OnServiceEvent(requestID);
        if (requestID == Entidad.CTipoRequest.Alumno) {
            this.forceDownloadData = false;
            this.GridUpdateData();
        }
    }
    // **********************************************************************
    // FORMULARIO CONFIGURACIONES
    // **********************************************************************

    //#region

    // private InitConfigModal() { }

    // private GetSchemaControls(opcion: IModoFormulario): controlD3.IField<ICovidEncuesta>[] { }

    // private OnBuild(container: TSelectionHTML<"div">, controlsForm: Map<keyof IDataUsuario, controlD3.IControlCreated<controlD3.Fields>>, form: controlD3.FormGenerator<IDataUsuario>) { }

    //#endregion

    // **********************************************************************
    // GRID CONFIGURACIONES
    // **********************************************************************

    //#region

    protected GRID_GetMenuTopGrid(): Table.ITableMenuTopDefaultOptionConfig[] {
        return null;
    }
    protected GRID_GetSelectionDataMenuV2(menuLocation: "row" | "top-selected", dataGridSelected: ICovidEncuestaData[]): Table.ITableMenuDataSelectedOptionConfig<ICovidEncuestaData>[] {
        return null;
    }
    protected GRID_GetDataRequestID(): DataModuloMain.TipoRequestMonitorId {
        return null;
    }

    protected GRID_GetTableConfigBase(): IGridRenderInfo<ICovidEncuestaData> {
        return {
            IdTabla: "Covid",
            Title: "",
            DefaultSort: "StrAlumnoNombre",
            IdData: "IdChild",
            MinWidth: 500,
            Columns: [
                { Field: "StrAlumnoNombre", Label: "Alumno", Width: "25%", MinWidth: "100px" },
                { Field: "StrGrupoPrincipal", Label: "Grupo principal", Width: "18%", MinWidth: "80px" },
                { Field: "StrTutorNombre" as any, Label: "Tutor generó", Width: "25%", MinWidth: "100px" }, // Level 2
                { Field: "Fecha" as any, Label: "Fecha", Width: "20%", MinWidth: "80px" }, // Level 2
                { Field: "Resultado" as any, Label: "Resultado", Width: "12%", MinWidth: "85px" } // Level 2
            ]
        }
    }

    protected GRID_GetTableConfigAdvanced(): IGridExtraTableConfig<ICovidEncuestaData> {
        return {
            EvaluatorAndSubLevelsBuild: {
                ForceHideLevelRowsWithoutChilds: true,
                // >> Nivel 1: Alumno
                OnStepCellTable: (container, usuarioDato, field) => {
                    switch (field) {
                        case "StrAlumnoNombre":
                            UIUtilGeneral._ElementAdd_LinkToGoToPanel(container, "alumnos/alumnos/panel", usuarioDato.Alumno.IdChild);
                            UIUtilViewAlumno._UIAlumnoLicenciaInfo(container, usuarioDato.Alumno.getEdoLicencia, "oninfoico", -10, true);
                            break;
                        case "StrGrupoPrincipal":
                            UIUtilViewGrupos._ApplyLblsGruposToContainer(container, usuarioDato.Alumno.IdChild);
                            break;
                    }
                },

                EvaluatorSubLevel: <Table.IStepSubLevelEvaluator<ICovidEncuestaData, IEncuesta, any>>{
                    // Nivel 2: Resultado Encuesta
                    IdMember: "Id",
                    // RemoveLevelCheckboxes: true,
                    OnGetData: (dato) => dato.Encuestas,
                    OnStepCellTable: (container, dato, field, i) => {
                        switch (field) {
                            case "Fecha":
                                container.text(UIUtilTime._DateFormatStandarFixTimeZone(dato.Encuesta.FechaRegistro, dato.Parent.Escuela.ZonaHoraria, "dd/mm/yyyy h12:mm"));
                                break;
                            case "Resultado":
                                let backgroudColor = "";
                                container
                                    .text("")
                                    .style("width", "80%")
                                    .style("height", "100%");
                                switch (dato.Encuesta.Calificacion) {
                                    case 0: // Red
                                        backgroudColor = "rgb(234 84 84)";
                                        break;
                                    case 1: // Yellow
                                        backgroudColor = "rgb(234 232 114)";
                                        break;
                                    case 2: // Green
                                        backgroudColor = "rgb(107 220 95)";
                                        break;
                                    case 3: // Blue
                                        backgroudColor = "rgb(94 185 243)";
                                        break;
                                }
                                // d3.select(container.node().parentElement).style("background", colorBack);
                                container.style("background", backgroudColor);
                                break;
                        }
                    }
                }
            }
        }
    }

    protected GRID_GetFilters(): Array<Table.IParametroFiltro<ICovidEncuestaData>> {
        // let kinderOptions: Array<controlD3.IParametroSimple> = this.TranslateKindersObject("Value", "Label") as Array<controlD3.IParametroSimple>
        return [
            { Field: "Alumno.Nombre" as any, LangModuleKeyInContext: "alumnos", LabelLangKey: "d_field_nombre", Type: "text" },
            { Field: "Alumno.ApPaterno" as any, LangModuleKeyInContext: "alumnos", LabelLangKey: "d_field_appaterno", Type: "text" },
            { Field: "Alumno.ApMaterno" as any, LangModuleKeyInContext: "alumnos", LabelLangKey: "d_field_apmaterno", Type: "text" },
            // { Label: "Fecha de encuesta", Field: "", IsDate: true }, // NOTE Aplica a los childs del nivel principal :c
            {
                Field: "Alumno.Sexo" as any,
                LangModuleKeyInContext: "alumnos",
                LabelLangKey: "d_field_strsexo",
                Type: "select",
                Options: UIUtilViewData._GetList_Sexo()
            },
            { Field: "Alumno.StrGrado" as any, LangModuleKeyInContext: "alumnos", LabelLangKey: "d_field_strgrado" },
            {
                Label: "Grupo principal",
                Field: "StrGrupoPrincipal",
                Type: "text",
                OnGetValueToMatch: (dato: ICovidEncuestaData) => dato.StrGrupoPrincipalNames,
            },
            { Field: "StrTutorNombre" as any, Label: "Tutor generó", Level: 2 },
            { Field: "Fecha" as any, Label: "Fecha", Level: 2, Type: "date", MaxDate: new Date().toISOString() }
        ]
    }

    protected GRID_GetExportarConfig(dataGrid: ICovidEncuestaData[]): IConfigGridExcelExport<any> {
        type IExportar = {
            StrAlumnoNombre?: string;
            IdEscuela: number;
            StrEscuela: string;
            StrGrupoPrincipal?: string;
            StrTutorNombre?: string;
            Fecha?: string;
            Resultado?: number | string;
        }
        // const arrayCalificacion = ["🔴", "💛", "💚", "💙"]

        const hasDataSelected = (this.ctrlTabla._dataChecked.length > 0);

        return <IConfigGridExcelExport<IExportar>>{
            IdsEscuelas: [...new Set(dataGrid.map(d => d.Alumno.IdKinder))],
            OnGetDataBySheets: async () => {
                let datosExportar: IExportar[] = [];

                dataGrid.forEach((dR) => {
                    let strEscuela = DataModuloEscuela._DiccEscuela.get(dR.Alumno.IdKinder).Nombre;

                    datosExportar.push({
                        StrAlumnoNombre: dR.Alumno.NombreCompleto,
                        IdEscuela: dR.Alumno.IdKinder,
                        StrEscuela: strEscuela,
                        StrGrupoPrincipal: dR.StrGrupoPrincipal
                    })

                    this.ctrlTabla._SelectItemData(dR.IdChild).GetChildrenDataFiltered<ICovidEncuestaData["Encuestas"][number]>().forEach(({ Data: dE, IsCheked: isChecked }) => {
                        if (hasDataSelected && !isChecked) return;
                        datosExportar.push({
                            IdEscuela: dR.Alumno.IdKinder,
                            StrEscuela: strEscuela,
                            StrTutorNombre: dE.StrTutorNombre,
                            Fecha: UIUtilTime._DateFormatStandarFixTimeZone(dE.Fecha, dE.Parent.Escuela.ZonaHoraria, "dd/mm/yyyy h12:mm"),
                            Resultado: dE.Resultado.toString() // arrayCalificacion[dE.Encuesta.Calificacion]
                        })
                    })
                })

                return Array.from(d3Group(datosExportar, d => d.IdEscuela))
                    .map<ExcelThings.ISheetConfig<any>>((entrie) => ({
                        IdSheet: entrie[0], // IdEscuela
                        SheetName: DataModuloEscuela._DiccEscuela.get(entrie[0]).Nombre,
                        Data: entrie[1],
                    }))
            },
            OnGetEscuelasTagInSheet: (datos) => datos[0].StrEscuela,
        }
    }

    protected GridOnSyncData() {
        this.forceDownloadData = true;
        return super.GridOnSyncData();
    }

    protected async GridGetData() {
        let gridDataEncuestas = this.dataGrid;
        if (gridDataEncuestas.length == 0 || this.forceDownloadData) {
            let res = await this.Sv_GetEncuestasDatosAllSchools();
            if (res.Resultado < 0) {
                this.notificacion._Mostrar(UIUtilLang._GetUIString("general", "notif_fail_infoupdate"), "ADVERTENCIA");
            } else {
                gridDataEncuestas = res.Data;
            }

            // if (res.Data.length >= this.ctrlTabla.prop_data.length) {
            // this.ctrlTabla.met_UpdateData(res.Data);
            // }
        }
        // this.CtrlProgressBar.met_Hide();
        return gridDataEncuestas;
    }

    // protected GridGetDataRequestID() {
    //     return data.Entidades.CTipoRequest.Usuarios;
    // }

    // protected GridGetMenu(): controlD3.TOptionInMain<ICovidEncuestaData>[] {
    //     if (this.GridHasPermisoAccion(CAccionPermiso.Agregar)) {
    //         return [
    //             {
    //                 Label: "Agregar",
    //                 Callback: () => this.OpenFormularioAgregar()
    //             }
    //         ]
    //     } else {
    //         return [];
    //     }
    // }

    // protected GridGetRowMenu(): controlD3.TOptionInMain<IUsuario>[] {
    //     return this.GetMenuDataSelected();
    // }

    // protected GridGetSelectionMenu(): controlD3.TOptionInMain<IUsuario>[] {
    //     return this.GetMenuDataSelected();
    // }

    // private GetMenuDataSelected() {
    //     let acciones: controlD3.TOptionInMain<IUsuario>[] = [];
    //     if (this.GridHasPermisoAccion(CAccionPermiso.Editar)) {
    //         acciones.push({
    //             Label: "Editar",
    //             Callback: (datoUser) => this.OpenFormularioEditar(datoUser[0]),
    //             MultiData: false
    //         })
    //     }
    //     if (this.GridHasPermisoAccion(CAccionPermiso.Eliminar)) {
    //         acciones.push({
    //             Label: "Eliminar",
    //             Callback: (datoUser) => this.OpenFormularioEliminar(datoUser),
    //         })
    //     }

    //     return acciones;
    // }

    //#endregion

    // **********************************************************************
    // FORM COSAS
    // **********************************************************************

    //#region

    // private OpenFormularioAgregar() {
    //     this.MostrarModal({
    //         TipoModal: controlD3.CTipoModal.Agregar,
    //         Data: {},
    //         WidthModal: 1000,
    //         OnAcept: async (datosEncuesta) => {
    //             return this.RegistrarUsuario(datosEncuesta)
    //             // return { Resultado: -1 };
    //         }
    //     });
    // }

    // private async OpenFormularioEditar(encuestaDato: ICovidEncuestaData) {
    //     this.MostrarModal({
    //         TipoModal: controlD3.CTipoModal.Editar,
    //         Data: encuestaDato,
    //         WidthModal: 1000,
    //         OnAcept: (datosEncuesta) => {
    //             return this.ActualizarUsuario(datosEncuesta);
    //             // console.log(datosUsuario, "No disponible");
    //             // return { Resultado: -1 };
    //         }
    //     });
    // }

    // private OpenFormularioEliminar(datos: ICovidEncuestaData[]) {
    //     let idsEscuelas = new Map();
    //     datos.forEach(d => d.Escuelas.forEach(idEsc => { idsEscuelas.set(idEsc, idEsc); }));

    //     if (this.GridHasPermisoAccion(CAccionPermiso.Eliminar, ...Array.from(idsEscuelas.values()))) {
    //         this.MostrarModal({
    //             TipoModal: controlD3.CTipoModal.Eliminar,
    //             Data: datos as ICovidEncuestaData[],
    //             MaxHeight: "200px",
    //             OnAcept: (datum) => {
    //                 return this.EliminarUsuario(datum);
    //             }
    //         })
    //     }
    // }

    // **********************************************************************
    // DATOS Y SERVICIOS
    // **********************************************************************

    private async Sv_GetEncuestasDatosAllSchools() {
        // NOTE No tiene asignación de permisos
        const escuelas = Array.from(DataModuloEscuela._DiccEscuela.values());
        let encuestas: ICovidEncuestaData[] = [];
        let resultado = 1;

        for (let escuela of escuelas) {
            const res = await _GetCovidEncuestaList(escuela.IdKinder);
            if (res.Resultado < 0) {
                resultado = -1;
            } else {
                res.Data.forEach((d, i) => {
                    let tutor = DataModuloTutor._DiccTutores.get(d.IdTutor);
                    let encuestaItem = encuestas.find(dEnc => (dEnc.Alumno.IdChild == d.IdAlumno));

                    if (encuestaItem) {
                        encuestaItem.Encuestas.push({
                            Id: encuestaItem.Encuestas.length,
                            Encuesta: d,
                            // Tutor: tutor,
                            StrTutorNombre: (tutor?.NombreCompleto || UIUtilLang._GetUIString("general", "nodisponible")),
                            Fecha: d.FechaRegistro,
                            Resultado: d.Calificacion,
                            Parent: encuestaItem,
                        })
                    } else {
                        let alumno = DataModuloMain._GetReqDataMapByName("Alumno").get(d.IdAlumno);
                        if (alumno) {
                            encuestaItem = {
                                IdChild: alumno.IdChild,
                                Alumno: alumno,
                                Escuela: escuela,
                                Encuestas: [],
                                StrAlumnoNombre: alumno.NombreCompleto,
                                StrGrupoPrincipal: alumno.StrGrupoPrincipal,
                                StrGrupoPrincipalNames: alumno.StrGrupoPrincipalNames,
                                "KinderFiltro": [escuela.IdKinder]
                            }
                            encuestaItem.Encuestas.push({
                                Id: 0,
                                Encuesta: d,
                                // Tutor: tutor,
                                StrTutorNombre: (tutor?.NombreCompleto || UIUtilLang._GetUIString("general", "nodisponible")),
                                Fecha: d.FechaRegistro,
                                Resultado: d.Calificacion,
                                Parent: encuestaItem,
                            })
                            encuestas.push(encuestaItem);
                        } else {
                            console.warn("-d", "Alumno no encontrado", d.IdAlumno);
                        }
                    }
                })
            }
        }

        encuestas
            .forEach(d => {
                d.Encuestas = d.Encuestas.sort((a, b) => (Number(new Date(b.Encuesta.FechaRegistro)) - Number(new Date(a.Encuesta.FechaRegistro))));
            });

        return {
            Data: encuestas,
            Resultado: resultado
        }
    }
}
