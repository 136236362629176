import { Global } from '../../data/Global';
import loaderHTML from '../template/loader.html?raw';

interface IProperties {
    Dim: string;
}
const TEMPLATE = document.createElement("template");
const BASEPROPERTIES: IProperties = {
    Dim: "210px",
}

TEMPLATE.innerHTML = `
<style>
:host .anim_rebote {
    animation: keyframe_rebote_label .3s;
}

@keyframes keyframe_rebote_label {
    0% {
        transform: scale(1)
    }
    50% {
        transform: scale(1.05)
    }
    100% {
        transform: scale(1)
    }
}
</style>`+ loaderHTML;

/** `wc-loader`
 * Attributes
 * * 'description': number
 */
export class HTMLLoaderElement extends HTMLElement {
    // #styleBase: HTMLStyleElement;
    #properties: IProperties;

    constructor() {
        super();
        this.attachShadow({ mode: "open" });
        this.#properties = { ...BASEPROPERTIES };
        this.shadowRoot.appendChild(TEMPLATE.content.cloneNode(true));
        this.shadowRoot.querySelector("div[description] > span[v]").textContent = Global._APP_VERSION_NAME_TAG
    }

    // *****************************************************************************
    // PRIVATE METHODS
    // *****************************************************************************

    // private SetStylesBase(props?: Partial<IProperties>) {
    //     if (props) for (let k in props) {
    //         this.#properties[k] = props[k] || BASEPROPERTIES[k];
    //     }
    //     this.#styleBase.textContent =
    //         `:host {
    //                 --dim:${this.#properties.Dim};
    //             }`
    // }

    // *****************************************************************************
    // ELEMENT LIFE CICLE CALLBACKS
    // *****************************************************************************


    /** Invocado cuando el componente personalizado se conecta por primera vez al DOM del documento. */
    connectedCallback() {
    }

    /** Invocado cuando el componente personalizado se deconecta del DOM del documento. */
    disconnectedCallback() {
    }

    /** Invocado cuando el componente personalizado se mueve a un nuevo documento. */
    adoptedCallback(oldDocument: Document, newDocument: Document) {
    }

    /** Invocado cuando uno de los atributos del componente personalizado es añadido, removido o modificado. */
    attributeChangedCallback(attrName: string, oldValue: string, newValue: string) {
        switch (attrName) {
            case "description":
                const descriptionText = this.shadowRoot.querySelector<HTMLLabelElement>("div[description]>label");
                if (newValue) {
                    descriptionText.textContent = newValue;
                    descriptionText.classList.remove("anim_rebote")
                    setTimeout(() => {
                        descriptionText.classList.add("anim_rebote");
                    }, 10);
                } else {
                    descriptionText.textContent = "";
                }
                break;
        }
    }

    static get observedAttributes() {
        return ['dim', 'description'];
    }

    // *****************************************************************************
    // PUBLIC PROPERTIES
    // *****************************************************************************

    public set _Description(value: (string | number)) {
        this.setAttribute("description", value + "");
    }
}

customElements.define("wc-loader", HTMLLoaderElement);
