import { DataDRequest } from "../DRequest";
import { Global } from "../Global";
import {
    ICatalogoSATClaveProductServ, ICatalogoSATClaveUnidad, ICatalogoSATFormaPago,
    ICatalogoSATImpuesto, ICatalogoSATMetodoPago, ICatalogoSATPais,
    ICatalogoSATRegimenFiscal, IUsoCFDI
} from "../entidad/Factura";

/** TipoCFDI */
export function _ObtenerTiposDocumentos(/* idEscuela: number */) {
    return [
        { key: "factura", name: "Factura" },
        // { key: "factura_hotel", name: "Factura para hoteles" },
        // { key: "honorarios", name: "Recibo de honorarios" },
        // { key: "nota_cargo", name: "Nota de cargo" },
        // { key: "donativos", name: "Donativo" },
        // { key: "arrendamiento", name: "Recibo de arrendamiento" },
        // { key: "nota_credito", name: "Nota de crédito" },
        // { key: "nota_debito", name: "Nota de débito" },
        // { key: "nota_devolucion", name: "Nota de devolución" },
        // { key: "carta_porte", name: "Carta porte" },
        // { key: "carta_porte_ingreso", name: "Carta porte de Ingreso" },
        // { key: "pago", name: "Pago" },
        // { key: "retencion", name: "Retención" },
    ]
}

const REQUEST_MANAGER: { [k: string]: { [x: number]: Promise<DataDRequest.IRequestResponseA<any>> } } = {}
function ManageRequest<T>(url: string, idEscuela: number, params: Object): Promise<DataDRequest.IRequestResponseA<T[]>> {
    if (!REQUEST_MANAGER[url])
        REQUEST_MANAGER[url] = {}
    if (REQUEST_MANAGER[url][idEscuela]) {
        return REQUEST_MANAGER[url][idEscuela]
    }
    const res = DataDRequest._Request5<T[]>(url, "GET", params)
        .then(res => {
            if (res.Resultado <= 0) {
                REQUEST_MANAGER[url][idEscuela] = null
            }
            return res
        })
    REQUEST_MANAGER[url][idEscuela] = res
    return res
}

export function _ObtenerCatalogoSATRegimenFiscal(idEscuela: number) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "factura/ObtenerCatalogoSATRegimenFiscal"
    const params = {
        "IdEscuela": idEscuela,
    }
    return ManageRequest<ICatalogoSATRegimenFiscal>(url, idEscuela, params)
}

export function _ObtenerCatalogoSATPais(idEscuela: number) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "factura/ObtenerCatalogoSATPais"
    const params = {
        "IdEscuela": idEscuela,
    }
    return ManageRequest<ICatalogoSATPais>(url, idEscuela, params)
}

export function _ObtenerCatalogoSATUsoCFDI(idEscuela: number) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "factura/ObtenerCatalogoSATUsoCFDI"
    const params = {
        "IdEscuela": idEscuela,
    }
    return ManageRequest<IUsoCFDI>(url, idEscuela, params)
}

export function _ObtenerCatalogoSATFormaPago(idEscuela: number) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "factura/ObtenerCatalogoSATFormaPago"
    const params = {
        "IdEscuela": idEscuela,
    }
    return ManageRequest<ICatalogoSATFormaPago>(url, idEscuela, params)
}

export function _ObtenerCatalogoSATMetodoPago(idEscuela: number) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "factura/ObtenerCatalogoSATMetodoPago"
    const params = {
        "IdEscuela": idEscuela,
    }
    return ManageRequest<ICatalogoSATMetodoPago>(url, idEscuela, params)
}

export function _ObtenerCatalogoSATImpuesto(idEscuela: number) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "factura/ObtenerCatalogoSATImpuesto"
    const params = {
        "IdEscuela": idEscuela,
    }
    return ManageRequest<ICatalogoSATImpuesto>(url, idEscuela, params)
}

export function _ObtenerCatalogoSATClaveUnidad(idEscuela: number) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "factura/ObtenerCatalogoSATClaveUnidad"
    const params = {
        "IdEscuela": idEscuela,
    }
    return ManageRequest<ICatalogoSATClaveUnidad>(url, idEscuela, params)
}

export function _ObtenerCatalogoSATClaveProductServ(idEscuela: number) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "factura/ObtenerCatalogoSATClaveProductServ"
    const params = {
        "IdEscuela": idEscuela,
    }
    return ManageRequest<ICatalogoSATClaveProductServ>(url, idEscuela, params)
}