import { DataDRequest } from "../DRequest";

const CATALOGOSv3 = [
    "Aduana", "ClaveProdServ", "ClaveUnidad", "CodigoPostal", "FormaPago",
    "Impuesto", "MetodoPago", "Moneda", "NumPedimentoAduana", "Pais",
    "PatenteAduanal", "RegimenFiscal", "TasaOCuota", "TipoDeComprobante", "TipoFactor",
    "TipoRelacion", "UsoCFDI",
] as const;
const CATALOGOSv4 = [...CATALOGOSv3, ...["Exportacion"] as const];
// type TCatalogoV3 = typeof CATALOGOSv3[number];
type TCatalogoV4 = typeof CATALOGOSv4[number];
const BASEURL = "https://raw.githubusercontent.com/bambucode/"
// const BASEURLv3 = BASEURL + "catalogos_sat_JSON/master/c_CAT.json";
const BASEURLv4 = BASEURL + "catalogos_JSON_CFDI/cfdi-4.0/c_CAT.json";

export const _ImpuestosMap = {
    "001": "ISR",
    "002": "IVA",
    "003": "IEPS",
} as const
interface ICatalogoBaseResponse {
    id: string,
    descripcion: string,
    /** En caso de que no esté en ´descripcion´ */
    nombre: string;
}
type IImpuestosV4Response = [
    {
        "id": "001",
        "descripcion": "ISR",
        "retencion": "Si",
        "traslado": "No",
        // "localOFederal": "Federal", "fechaInicioDeVigencia": "01-01-2022", "fechaFinDeVigencia": ""
    },
    {
        "id": "002",
        "descripcion": "IVA",
        "retencion": "Si",
        "traslado": "Si",
    },
    {
        "id": "003",
        "descripcion": "IEPS",
        "retencion": "Si",
        "traslado": "Si",
    }
]
type IResponseMap = {
    [k in TCatalogoV4]: k extends "Impuesto"
    ? IImpuestosV4Response
    : ICatalogoBaseResponse[]
}
const catalogosMaps = new Map<TCatalogoV4, any[]>();

// /** @reference https://github.com/bambucode/catalogos_sat_JSON
//  * CFDI v3.3
// */
// export function fn_GetCatalogoV3(catalogo: TCatalogoV3) {
//     return new Promise<ICatalogoResponse[]>(resolve => {
//         if (catalogosMaps.has(catalogo)) {
//             resolve(catalogosMaps.get(catalogo));
//             return;
//         }
//         const url = BASEURLv3.replace("CAT", catalogo);
//         DRequest.fn_Request(url, "GET", {}, (response: ICatalogoResponse[]) => {
//             if (response && Array.isArray(response)) {
//                 catalogosMaps.set(catalogo, response);
//             }
//             resolve(response);
//         });
//     })
// }

// export async function fn_GetCatalogoV3Value(catalogo: TCatalogoV3, idValue: string) {
//     if (!idValue?.trim()) return "";
//     let catalogoMap = await fn_GetCatalogoV3(catalogo);
//     let value: string;
//     if (catalogoMap) {
//         let item = catalogoMap.find((d) => d.id === idValue);
//         value = item?.descripcion || item?.nombre;
//     }
//     return value || `No disponible (${idValue})`;
// }

/** @reference https://github.com/bambucode/catalogos_JSON_CFDI
 * CFDI v4.0
*/
export function _GetCatalogoV4<TCat extends TCatalogoV4>(catalogo: TCat): Promise<IResponseMap[TCat]> {
    return new Promise<IResponseMap[TCat]>(resolve => {
        if (catalogosMaps.has(catalogo)) {
            resolve(catalogosMaps.get(catalogo) as any);
            return;
        }
        const url = BASEURLv4.replace("CAT", catalogo);
        DataDRequest._Request(url, "GET", {}, (response: IResponseMap[TCat]) => {
            if (response && Array.isArray(response)) {
                catalogosMaps.set(catalogo, response);
            }
            resolve(response as any);
        });
    })
}

export async function _GetCatalogoV4Value<TCat extends TCatalogoV4>(catalogo: TCat, idValue: string) {
    if (!idValue?.trim()) return "";
    let catalogoMap = await _GetCatalogoV4<TCat>(catalogo);
    let value: string;
    if (catalogoMap) {
        let item = (catalogoMap as any[]).find((d) => d.id === idValue);
        value = item?.descripcion || item?.nombre;
    }
    return value || `No disponible (${idValue})`;
}

export default {
    _GetCatalogoV4,
    _GetCatalogoV4Value
}