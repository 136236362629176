import { DateV2 } from "../../util/DateV2";
import { DataDRequest } from "../DRequest";
import { Entidad } from "../Entidad";
import { Global } from "../Global";
import { DataUtil } from "../util/Util";
import { _SvFinanzasUtilEvaluarIntervaloBloque, _SvFinanzasUtilEvaluarTipoInteres, _SvFinanzasUtilEvaluarTipoTasa } from "./FinanzaCargo";

type IAsignacionCargoDesc = Entidad.IFinanzaAsignacionCargoDescuento;

interface IGetAsignacionesResponseData {
    Cargos: Array<IAsignacionCargoDesc>;
    Descuentos: Array<Entidad.IFinanzaAsignacionInfoDescuentos>;
}

interface ICargoClone extends Pick<Entidad.IFinanzaCargo, "Categoria" | "Nombre" | "TipoInteres" | "TipoRecargo" | "TipoValor" | "Valor" | "ValorRecargo" | "TipoTasa" | "IntervaloBloque"> {
    DiasMorosidad: number;
}

// /** @deprecated */
// export async function fn_GetAsignacionesList(idAlumno: number): Promise<data.DRequest.IResultadoPeticion<IGetAsignacionesResponseData>> {
//     const url = Utils._GLOBAL_CONF.URL_RSTV2 + "finanzas/ObtenerListaCargoAsignacionAlumno";
//     const params = {
//         IdAlumno: idAlumno
//     }

//     return new Promise((resolve, reject) => {
//         DRequest.fn_Request(url, "GET", params, (result) => {
//             resolve({
//                 Resultado: result ? result.Resultado : -1,
//                 Data: result ? <IGetAsignacionesResponseData>{
//                     Cargos: result.Cargos ? result.Cargos : [],
//                     Descuentos: result.Descuentos ? result.Descuentos : []
//                 } : null
//             })
//         })
//     })
// }

/** Uso destinado para completar la info de Edo.Cta.
 * @deprecated
 */
export async function _GetAsignacionesListV2(idAlumno: number): Promise<DataDRequest.IResultadoPeticion<IGetAsignacionesResponseData>> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "finanzas/ObtenerListaCargoAsignacionAlumnoV2";
    const params = {
        IdAlumno: idAlumno
    }

    return new Promise((resolve, reject) => {
        DataDRequest._Request<any>(url, "GET", params, (result) => {

            resolve({
                Resultado: result ? result.Resultado : -1,
                Data: result ? <IGetAsignacionesResponseData>{
                    Cargos: result.Cargos ? result.Cargos : [],
                    Descuentos: result.Descuentos ? result.Descuentos : []
                } : null
            })
        })
    })
}

export async function _GetAsignacionesListV3(idAlumno: number) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "finanzas/ObtenerListaCargoAsignacionAlumnoV3";
    const params = {
        IdAlumno: idAlumno
    }

    return DataDRequest._Request5<Entidad.IFinanzaAsignacionCargoDescuentoV2[]>(url, "GET", params);
}

export async function _ObtenerCargoCloneByID(idCargo: number): Promise<DataDRequest.IRequestResponseA<ICargoClone>> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "finanzas/ObtenerCargo";
    const params = {
        "IdCargo": idCargo
    }
    const res = await DataDRequest._Request5<ICargoClone[]>(url, "GET", params);

    return {
        Resultado: res.Resultado,
        Datos: (res.Datos?.length ? res.Datos[0] : null)
    };
}

interface IInsertAsignacionCargo {
    IdCargoPlantilla: number;
    Repeticiones: {
        FechaInicio: string;
        FechaFin: string;
    }[];
}
interface IAltaAsignacionResponseData {
    Alumno: Array<number>;
    /** Ids de las asignaciones (agrupan cada repetición) */
    CargosClon: Array<number>;
    CargosTemplate: Array<number>;
    FechaFin: Array<string>;
    FechaInicio: Array<string>;
    /** IDs de la cada asignación-repetición nueva */
    ID: Array<number>;
}
interface IDataResolvedItem {
    IdAlumno: number;
    IdCargoClon: number;
    IdCargoTemplate: number;
    FechaFin: string;
    FechaInicio: string;
    Id: number;
}
interface IDataResolved {
    /** Key -> IdAlumno */
    [idAlumno: number]: IDataResolvedItem[];
}
interface IResultResolved extends DataDRequest.IResultadoPeticion<IAltaAsignacionResponseData> {
    ResolvedData: IDataResolved;
}
export async function _InsertAsignacionCargo(idsAlumno: Array<number>, idCuenta: number, datos: IInsertAsignacionCargo[], idCicloEscolar: number): Promise<IResultResolved> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "finanzas/NuevoCargoAsignacionAlumno";

    const finalParams = {
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        // "IdOrganizacion": data.Utils.prop_Usuario.IdOrganizacion,
        "Alumnos": idsAlumno,
        "IdCicloEscolar": idCicloEscolar,
        "IdCuenta": idCuenta,
        "Cargos": datos.map(d => {
            return {
                "IdCargo": d.IdCargoPlantilla,
                "Repeticiones": d.Repeticiones.map(dRep => {
                    const inicio = (dRep.FechaInicio) ?
                        new DateV2(dRep.FechaInicio)._ToISOLocalString() :
                        dRep.FechaInicio;
                    const fin = (dRep.FechaFin) ?
                        new DateV2(dRep.FechaFin)._ToISOLocalString() :
                        dRep.FechaFin;
                    return {
                        "FechaInicio": inicio,
                        "FechaFin": fin
                    }
                }),
            }
        })
    };
    // console.debug("FinanzaAsignacion ->", finalParams, "FINAL PARAMS - fn_InsertAsignacionDescuento");

    return new Promise((resolve, reject) => {
        DataDRequest._Request(url, "POST", finalParams, ((result: DataDRequest.IRequestResponseBase<IAltaAsignacionResponseData>) => {

            let dataResolved = <IDataResolved>{};

            result?.Datos.Alumno
                .forEach((idAlumno, i) => {
                    if (dataResolved[idAlumno] == null) {
                        dataResolved[idAlumno] = [];
                    }

                    dataResolved[idAlumno]
                        .push({
                            IdAlumno: result.Datos.Alumno[i],
                            IdCargoClon: result.Datos.CargosClon[i],
                            IdCargoTemplate: result.Datos.CargosTemplate[i],
                            FechaFin: result.Datos.FechaFin[i],
                            FechaInicio: result.Datos.FechaInicio[i],
                            Id: result.Datos.ID[i]
                        });
                });

            console.debug("FinanzaAsignacion -> _InsertAsignacionCargo", result?.Resultado, result?.Datos, dataResolved);
            resolve({
                Resultado: (result?.Resultado || -100),
                Data: result?.Datos,
                ResolvedData: dataResolved
            })
        }));
    })
}

export async function _SvFinanzasNuevoCargoAsignAlumnoNoPlantilla(cargo: Entidad.IFinanzaCargo, idAlumno: number, idCuenta: number, idCicloEscolar: number, ini: string, fin: string, claveProdServ: string, nombreProdServ: string) {
    claveProdServ = claveProdServ || ""
    nombreProdServ = nombreProdServ || ""
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "finanzas/NuevoCargoAsignAlumnoNoPlantilla";
    cargo.TipoInteres = _SvFinanzasUtilEvaluarTipoInteres(cargo)
    cargo.IntervaloBloque = _SvFinanzasUtilEvaluarIntervaloBloque(cargo)
    cargo.TipoTasa = _SvFinanzasUtilEvaluarTipoTasa(cargo)
    const params = {
        IdAlumno: idAlumno,
        IdCuenta: idCuenta,
        IdOrganizacion: DataUtil._Usuario.IdOrganizacion,
        IdEscuela: cargo.IdEscuela,
        IdUsuario: DataUtil._Usuario.IdUsuario,
        Nombre: cargo.Nombre,
        Valor: cargo.Valor,
        Periodicidad: cargo.Periodicidad,
        MesAplicacion: cargo.MesAplicacion,
        DiaAplicacion: cargo.DiaAplicacion,
        DiaMorosidad: cargo.DiaMorosidad,
        DiaVencimiento: cargo.DiaVencimiento,
        TipoRecargo: cargo.TipoRecargo,
        TipoInteres: cargo.TipoInteres,
        ValorRecargo: cargo.ValorRecargo,
        TipoTasa: cargo.TipoTasa,
        IntervaloBloque: cargo.IntervaloBloque,
        IdCicloEscolar: idCicloEscolar,
        FechaInicio: ini,
        FechaFin: fin,
        ClaveProdServSAT: claveProdServ,
        NombreProdServSAT: nombreProdServ,
    }

    return DataDRequest._Request5(url, "POST", params);
}

interface IAltaDescuentosResponseData {
    CargoAsignacion: Array<number>;
    Descuento: Array<number>;
}
export async function _InsertAsignacionDescuento(idEscuela: number, idsAsignacionRepeticiones: Array<number>, idsDescuentos: Array<number>) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "finanzas/NuevoDescuentoAsignacion";
    const params = {
        IdUsuario: DataUtil._Usuario.IdUsuario,
        Asignaciones: idsAsignacionRepeticiones,
        Descuentos: idsDescuentos,
        IdEscuela: idEscuela
    }

    return DataDRequest._Request5<IAltaDescuentosResponseData>(url, "POST", params);
}

export async function _DeleteCargosAsignadosRepeticiones(idsAsignaciones: number[]) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "finanzas/EliminarCargoAsignacion";
    const params = {
        "IdCargo": idsAsignaciones,
        "IdUsuario": DataUtil._Usuario.IdUsuario
    }

    return DataDRequest._Request5(url, "POST", params);
}

export function _AplicarCargos(idsAsignacion: number[]): Promise<DataDRequest.IResultadoPeticion<undefined>> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "finanzas/CargosAplicar"; // "finanzas/CargoAplicar";
    const params = {
        // "IdAsignacion": idAsignacion,
        "Asignaciones": idsAsignacion,
        "IdUsuario": DataUtil._Usuario.IdUsuario
    }

    return new Promise(resolve => {
        DataDRequest._Request(url, "POST", params, ((result: DataDRequest.IRequestResponseBase) => {
            resolve({
                Resultado: (result?.Resultado || -100)
            })
        }))
    })
}
/*
DiccFinanzasAsignacionesTEST.set(1, {
    "ID": 1,
    "IdAlumno": 464,
    "IdCuenta": 1,
    // "IdCargo": 107,
    "Nombre": "Cargo test 7 Mensual 9ts",
    "Valor": 120.5,
    "TipoValor": 1,
    "Periodicidad": 1,
    "IdCargoPlantilla": 107,
    "FechaInicio": "2021-04-10T00:00:51.657Z",
    "FechaFin": "2021-08-09T00:00:51.657Z",
    "StrPeriodicidad": "Mensual",
    // "Total": 482,
    "NoRepeticiones": 4,
    "Repeticiones": [
        {
            "FechaInicio": "2021-04-10T00:00:51.657Z",
            "FechaFin": "2021-05-09T00:00:51.657Z",
        },
        {
            "FechaInicio": "2021-05-10T00:00:51.657Z",
            "FechaFin": "2021-06-09T00:00:51.657Z",
        },
        {
            "FechaInicio": "2021-06-10T00:00:51.657Z",
            "FechaFin": "2021-07-09T00:00:51.657Z",
        },
        {
            "FechaInicio": "2021-07-10T00:00:51.657Z",
            "FechaFin": "2021-08-09T00:00:51.657Z",
        }
    ]
})

DiccFinanzasAsignacionesTEST.set(2, {
    "ID": 2,
    "IdAlumno": 464,
    "IdCuenta": 2,
    // "IdCargo": 107,
    "Nombre": "test uno Anual 3ts",
    "Valor": 100,
    "TipoValor": 1,
    "Periodicidad": 12,
    "IdCargoPlantilla": 101,
    "FechaInicio": "2021-04-10T00:00:51.657Z",
    "FechaFin": "2023-03-08T00:00:51.657Z",
    "StrPeriodicidad": "Anual",
    // "Total": 482,
    "NoRepeticiones": 2,
    "Repeticiones": [
        {
            "FechaInicio": "2021-04-10T00:00:51.657Z",
            "FechaFin": "2022-04-09T00:00:51.657Z",
        },
        {
            "FechaInicio": "2022-04-10T00:00:51.657Z",
            "FechaFin": "2023-05-09T00:00:51.657Z",
        },
    ]
})
*/

export default {
    _GetAsignacionesListV2,
    _GetAsignacionesListV3,
    _ObtenerCargoCloneByID,
    _InsertAsignacionCargo,
    _InsertAsignacionDescuento,
    _DeleteCargosAsignadosRepeticiones,
    _AplicarCargos,
}
