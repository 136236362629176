export class DModelBase<Entity> {
    #entity: Entity;
    #lastProperties: string[];
    constructor(entity: Entity) {
        this.#entity = entity;

        if (!this.#lastProperties) {
            this.#lastProperties = [];
        }
        else if (this.#lastProperties.length) {
            this.#lastProperties.forEach(prop => {
                delete this[prop];
            })
            this.#lastProperties = [];
        }
        for (const k in entity) {
            this.#lastProperties.push(k);
            this[k as any] = entity[k];
        }
    }

    get __Entity() {
        return this.#entity;
    }
}
