import { MainPage } from "../../MainPage";
import { DataDRequest } from "../../data/DRequest";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import { UIUtilLang } from "../util/Language";
import { CardV2Collapse } from "./CardV2Collapse";
import { ModalThings } from "./ModalThings";

interface ICardCollapseActionProccess {
    TypeRequest: Entidad.CTipoRequest;
}

type IConfigModalForm<TData> = Pick<
    ModalThings.IConfigModalToForm<TData>,
    "Action" | "DrawContent" | "AccionToHttpMessage" | "GetForm" | "IdsEscuelas" | "OnAccept" | "OnClose" | "Width" | "Title"
>
    // & Partial<Pick<controlD3.ModalThings.IConfigProcessServiceByService<TData>, "">>
    & {
        OnEndReloadService: (res: DataDRequest.IResultadoPeticion<any>) => Promise<void>;
    }
    & ICardCollapseActionProccess

type IConfigModalToProccessArrayData<TData> = Pick<ModalThings.IConfigProcessServiceByServiceBasic<TData>, "DataToProccess" | "OnStepAProccess" | "OnError_GetItemDataTag" | "AccionToHttpMessage" | "OnEndProccess" | "Width" | "OnDrawContent">
    & Partial<Pick<ModalThings.IConfigProcessServiceByServiceBasic<TData>, "Title" | "Action" | "TypeRequest" | "Message" | "OnStepAllProccess">>
    & ICardCollapseActionProccess
    & {
        OnGetIdEscuela: (dato: TData) => (number | number[]);
        OnEndAndCloseProccess: (datosCorrectos: TData[], datosError: TData[]) => Promise<void>;
    };

export abstract class CardV2CollapseAdvanced<TDataToDoUpdate extends Array<any> = [unknown]> extends CardV2Collapse<TDataToDoUpdate> {
    constructor(titulo: string, modulo: Entidad.CModulo = null, moduloName?: string) {
        super(titulo, modulo, moduloName);
    }

    // ****************************************************************************
    // PRIVATE METHODS
    // ****************************************************************************

    // ****************************************************************************
    // PROTECTED METHODS
    // ****************************************************************************

    /**
     * * Si la propiedad "Action" no tiene un valor, Modulo y IdsEscuelas ya no son requeridos. No se verifican permisos.
     * * Solo con las acciones Agregar y Editar construye un Tittle predefinido Action + (Module | TipeRequest). Ej: "Agregar circular".
     * Excepto si se define Tittle */
    protected CARDCOLLAD_OpenModal_ActionFormToAGridData<TData>(config: IConfigModalForm<TData>) {  // FIX_LANGUAGE MODAL E IMPLEMENTACIONES
        // let autoReloadOnFinally = (config.AutoReloadGridRequestOnFinally == null ? true : config.AutoReloadGridRequestOnFinally);
        const idsEscuelas = Array.from(new Set(config.IdsEscuelas || []));

        ModalThings._GetModalToForm({
            Title: config.Title,
            Action: config.Action,
            Modulo: (config.Action ? this.modulo : undefined),
            LangModuleKeyInContext: this.moduloName,
            DrawContent: config.DrawContent,
            AccionToHttpMessage: config.AccionToHttpMessage,
            GetForm: config.GetForm,
            IdsEscuelas: (config.Action ? idsEscuelas : undefined),
            OnAccept: !config.OnAccept ? null : async (form, modalThings) => {
                let res = await config.OnAccept(form, modalThings);

                this.ctrlProgress.attr("oculto", false);

                if (res?.Resultado > 0) {
                    // if (autoReloadOnFinally) {
                    if (config.TypeRequest != null) {
                        let resultSuccess = true;

                        if (idsEscuelas.length) {
                            for (let idEscuela of idsEscuelas) {
                                let resSuccess = await MainPage._ReloadServiceAndAwaitBool(config.TypeRequest as DataModuloMain.TipoRequestMonitorId, idEscuela);
                                if (!resSuccess) {
                                    resultSuccess = false;
                                }
                            }
                        } else {
                            resultSuccess = await MainPage._ReloadServiceAndAwaitBool(config.TypeRequest as DataModuloMain.TipoRequestMonitorId);
                        }

                        await config.OnEndReloadService(res);
                        this.ctrlProgress.attr("oculto", true);

                        if (!resultSuccess) setTimeout(() => {
                            this.ctrlNotification._Mostrar(UIUtilLang._GetUIString("general", "notif_fail_infosync"), "ADVERTENCIA");
                        }, 2000);
                    } else {
                        await config.OnEndReloadService(res);
                        this.ctrlProgress.attr("oculto", true);
                    }
                    // }
                } else {
                    await config.OnEndReloadService(res);
                    this.ctrlProgress.attr("oculto", true);
                }

                return res;
            },
            OnClose: config.OnClose,
            Width: config.Width
        })
    }

    /**
   * * Preparado por defecto para la acción de Eliminar un array de datos
   * * Revisa permisos de usuario !
   * * Activa el progressbar si al final de todo el proceso si al menos un item de DataToProccess es res.Resultado > 0
   * @defaults
   * * Action -> Por defecto es data.Entidades.CAccionPermiso.Eliminar
   * * Message -> Por defecto es el referente a "eliminarseleccionados"
   */
    protected async CARDCOLLAD_OpenModal_ProccessArrayData<T>(config: IConfigModalToProccessArrayData<T>) {
        // let autoReloadOnFinally = (config.AutoReloadGridRequestOnFinally == null ? true : config.AutoReloadGridRequestOnFinally);
        const idsEscuelas: number[] = (config.OnGetIdEscuela ? [] : null);
        config.Action = (config.Action || Entidad.CAccionPermiso.Eliminar);

        if (config.OnGetIdEscuela) {
            config.DataToProccess
                .map(d => config.OnGetIdEscuela(d))
                .filter(d => Boolean(d))
                .forEach(d => {
                    if (typeof d == "number") {
                        d = [d];
                    }
                    d.forEach(idEscuela => {
                        if (idsEscuelas.indexOf(idEscuela) == -1) {
                            idsEscuelas.push(idEscuela);
                        }
                    })
                })
        }

        // >> Message Fix Lang context
        if (!config.Message) {
            if (config.Action == Entidad.CAccionPermiso.Eliminar) {
                config.Message = UIUtilLang._GetUIString("confirmation", "eliminarseleccionados")
                    .replace("_NDATA", config.DataToProccess.length + "");
            } else {
                console.warn("-d", "Message no found!!!");
                config.Message = UIUtilLang._GetUIString("c_actions", Entidad.CAccionPermiso[config.Action] as any);
            }
        }

        // >> Build Modal
        let finalResults = await ModalThings._OpenModalToProccessServiceByServiceFromAArrayBasic({
            DataToProccess: config.DataToProccess,
            Title: config.Title,
            Message: config.Message,
            Action: config.Action,
            Modulo: this.modulo,
            LangModuleKeyInContext: this.moduloName,
            IdsEscuelas: idsEscuelas,
            TypeRequest: config.TypeRequest,
            OnDrawContent: config.OnDrawContent,
            OnError_GetItemDataTag: config.OnError_GetItemDataTag,
            OnStepAProccess: config.OnStepAProccess,
            OnStepAllProccess: config.OnStepAllProccess,
            OnEndProccess: config.OnEndProccess,
            Width: (config.Width || 400),
            AccionToHttpMessage: config.AccionToHttpMessage
        })

        if (finalResults?.find(d => (d.Resultado > 0))) {
            // if (autoReloadOnFinally) {
            // -> Lanza el reload de las escuelas de los datos procesados correctamente
            // * Si tan solo uno de los datos no tiene Id de Escuela, se realizará el reload de Todas las escuelas
            let escuelasARecargar: number[] = [];
            let correctos: T[] = [];
            let incorrectos: T[] = [];

            for (let res of finalResults) {
                if (res.Resultado > 0) {
                    correctos.push(res.ItemData);

                    if (config.OnGetIdEscuela) {
                        let idsEscuelas2 = config.OnGetIdEscuela(res.ItemData);
                        if (typeof idsEscuelas2 == "number") {
                            idsEscuelas2 = [idsEscuelas2];
                        }
                        if (idsEscuelas2?.length) {
                            idsEscuelas2.forEach(idEscuela => {
                                if (escuelasARecargar.indexOf(idEscuela) == -1) {
                                    escuelasARecargar.push(idEscuela);
                                }
                            })
                        } else {
                            console.warn("-d", "Item sin Id de Escuela", res.ItemData);
                            // escuelasARecargar = [];
                            break;
                        }
                    }
                } else {
                    incorrectos.push(res.ItemData);
                }
            }

            this.ctrlProgress.attr("oculto", false);

            if (config.TypeRequest) {
                let resultSuccess: boolean;
                if (escuelasARecargar.length) {
                    resultSuccess = await MainPage._ReloadServiceAndAwaitBool(config.TypeRequest as DataModuloMain.TipoRequestMonitorId, escuelasARecargar);
                } else {
                    resultSuccess = await MainPage._ReloadServiceAndAwaitBool(config.TypeRequest as DataModuloMain.TipoRequestMonitorId);
                }

                await config.OnEndAndCloseProccess(correctos, incorrectos);
                this.ctrlProgress.attr("oculto", true);

                if (!resultSuccess) setTimeout(() => {
                    this.ctrlNotification._Mostrar(UIUtilLang._GetUIString("general", "notif_fail_infosync"), "ADVERTENCIA");
                }, 2000);

            } else {
                await config.OnEndAndCloseProccess(correctos, incorrectos);
                this.ctrlProgress.attr("oculto", true);
            }
            // }
        } else {
            config.OnEndAndCloseProccess([], (finalResults.map(d => d.ItemData) || []));
        }
    }

    // ****************************************************************************
    // PUBLIC PROPERTIES
    // ****************************************************************************
}
