import { Entidad } from "../Entidad";
import { Global } from "../Global";
import { DataDRequest } from "../DRequest";
import { DataUtil } from "../util/Util";

import CTipoPago = Entidad.CTipoPago;

interface IRespuestaRegistroPagos {
    IdMovimiento: number;
    IdPago: number;
}

/** Movimiento */
interface IDataToPay {
    /** Id del cargo */
    Id: number;
    Monto: number;
    TipoPago: CTipoPago;
}

// export var DiccFinanzasMovimiento: Map<number, IFinanzaMovimiento> = new Map();

// /** @deprecated */
// export async function fn_GetMovimientosList(idAlumno: number) {
//     const url = Utils._GLOBAL_CONF.URL_RSTV2 + "finanzas/ObtenerListaMovimientosAlumno"
//     const params = {
//         IdAlumno: idAlumno
//     }

//     const res = await DRequest.fn_Request5<Entidades.IFinanzaMovimiento[]>(url, "GET", params);
//     res.Datos = res.Datos || [];
//     return res;
// }

export async function _ObtenerListaMovimientosAlumnoV2(idAlumno: number) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "finanzas/ObtenerListaMovimientosAlumnoV2";
    const params = {
        IdAlumno: idAlumno
    }

    return DataDRequest._Request5<Entidad.IFinanzaMovimientoV2[]>(url, "GET", params);
}

export async function _RegistrarPago(idMovimiento: number, montoPago: number, idMetodoPago, tipoPago: Entidad.CTipoPago): Promise<DataDRequest.IResultadoPeticion<null>> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "finanzas/NuevoPago";
    const params = {
        "IdMovimiento": idMovimiento,
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "IdMetodoPago": idMetodoPago,
        "TipoPago": tipoPago,
        "Monto": montoPago
    }

    return new Promise(async (resolve, reject) => {
        DataDRequest._Request2<{ Resultado: number }>(url, "POST", params)
            .then((res) => {
                console.log(res)
                resolve({
                    Resultado: (!res.error && res.data?.Resultado) ? res.data.Resultado : -1
                })
            })
    })
}

export async function _RegistrarPagos(idMetodoPago: number, movimientos: IDataToPay[]): Promise<DataDRequest.IRequestResponseA<IRespuestaRegistroPagos[]>> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "finanzas/NuevoPagoMultiple";
    const params = {
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "IdMetodoPago": idMetodoPago,
        "Movimientos": movimientos
    }

    return DataDRequest._Request5<IRespuestaRegistroPagos[]>(url, "POST", params)
}

export async function _RegistrarDescuentos(idsAsignaciones: number[], idAlumno: number, idDescuento: number) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "finanzas/NuevosDescuentosEstadoCuenta"; //"finanzas/NuevoDescuentoEstadoCuenta";
    const params = {
        // "IdPadre": idPadre,
        "Asignaciones": idsAsignaciones,
        "IdAlumno": idAlumno,
        "IdDescuento": idDescuento,
        "IdUsuario": DataUtil._Usuario.IdUsuario,
    }
    return DataDRequest._Request5(url, "POST", params);
}

export async function _EliminarEdoCta(idAlumno: number, idMovimiento: number) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "finanzas/EdoCuentaEliminar";
    const params = {
        "IdMovimiento": idMovimiento,
        "IdAlumno": idAlumno,
        "IdUsuario": DataUtil._Usuario.IdUsuario,
    }
    return DataDRequest._Request5(url, "POST", params);
}

export function _SvFinanzasEstadoCuentaEliminarDescuento(idMovimientoDetalle: number, idPadre: number) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "finanzas/EliminarDescuentoEstadoCuenta";
    const params = {
        "IdPadre": idPadre,
        "IdMovimiento": idMovimientoDetalle,
        "IdUsuario": DataUtil._Usuario.IdUsuario,
    }

    return DataDRequest._Request5(url, "POST", params);
}

export function _SvFinanzasEstadoCuentaEliminarMorosidad(idMovimientoDetalle: number, idPadre: number) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "finanzas/EliminarMorosidad";
    const params = {
        "IdPadre": idPadre,
        "IdMovimiento": idMovimientoDetalle,
        "IdUsuario": DataUtil._Usuario.IdUsuario
    }
    return DataDRequest._Request5(url, "POST", params);
}

export function _SvFinanzasEstadoCuentaEliminarPagoCompleto(idMovimientoDetalle: number, idPadre: number) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "finanzas/EliminarPagoCompleto";
    const params = {
        "IdPadre": idPadre,
        "IdMovimiento": idMovimientoDetalle,
        "IdUsuario": DataUtil._Usuario.IdUsuario
    }
    return DataDRequest._Request5(url, "POST", params);
}

export default {
    _RegistrarPago,
    _RegistrarPagos,
    _RegistrarDescuentos,
    _EliminarEdoCta,
}
