import { ArrayV2 } from "../../util/ArrayV2";
import { DataDRequest } from "../DRequest";
import { Entidad } from "../Entidad";
import { Global } from "../Global";
import { DataUtil } from "../util/Util";

import IEgresoP = Entidad.IFinanzaEgresoPredefinido;
const IDREQUEST = Entidad.CTipoRequest.EgresoPredefinido;

export const _DictEgresosPredefinidos = new Map<number, IEgresoP>();

export async function _GetList(escuelas: Entidad.IEscuela[]): Promise<DataDRequest.IRequestResponseListA<IEgresoP>[]> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "finanzaEgresoPlantilla/ObtenerListaV2";

    return new ArrayV2(...escuelas)
        ._MapAwait<DataDRequest.IRequestResponseListA<IEgresoP>>(async (escuela, i) => {
            const lastStrDate = await DataUtil._GetMaxDate(IDREQUEST, escuela.IdKinder);
            const params = {
                "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
                "IdEscuela": escuela.IdKinder,
                "Modificacion": lastStrDate,
            }

            return DataDRequest._Request4List(url, "GET", params, IDREQUEST, escuela.IdKinder);
        });
}

export function _NuevoRegistro(dato: Pick<IEgresoP, "IdEscuela" | "Descripcion" | "TipoGasto" | "Valor" | "IdCategoria">) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "finanzaEgresoPlantilla/NuevoV2";
    const params = {
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
        "IdEscuela": dato.IdEscuela,
        "Descripcion": dato.Descripcion,
        "TipoGasto": dato.TipoGasto,
        "Valor": dato.Valor,
        "IdCategoria": dato.IdCategoria,
    };
    return DataDRequest._Request5(url, "POST", params, IDREQUEST);
}

export function _ActualizarRegistro(dato: IEgresoP) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "finanzaEgresoPlantilla/ActualizarV2";
    const params = {
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "IdPlantilla": dato.Id,
        "IdEscuela": dato.IdEscuela,
        "Descripcion": dato.Descripcion,
        "TipoGasto": dato.TipoGasto,
        "Valor": dato.Valor,
        "IdCategoria": dato.IdCategoria,
    };
    return DataDRequest._Request5(url, "POST", params, IDREQUEST);
}

export function _EliminarRegistro(id: number) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "finanzaEgresoPlantilla/Eliminar";
    const params = {
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "IdPlantilla": id
    };
    return DataDRequest._Request5(url, "POST", params, IDREQUEST);
}

export default {
    _DictEgresosPredefinidos,
    _NuevoRegistro,
    _ActualizarRegistro,
    _EliminarRegistro
}
