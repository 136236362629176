import * as d3 from "d3";
import { group as d3Group } from "d3-array";
import { MainPage } from "../../MainPage";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import DataModuloEgresoMovimiento from "../../data/modulo/EgresoMovimiento";
import { ArrayV2 } from "../../util/ArrayV2";
import { DateV2 } from "../../util/DateV2";
import { IConfigGridExcelExport, IGridExtraTableConfig, IGridRenderInfo, VentanaGrid } from "../controlD3/AVentanaGrid";
import { CopyElementTextControl } from "../controlD3/CopyElementTextControl";
import { ElementWrapper } from "../controlD3/ElementWrapper";
import { ExcelThings } from "../controlD3/ExcelExport";
import { SelectV2 } from "../controlD3/SelectV2";
import { Table } from "../controlD3/Tabla";
import { UIUtilFormat } from "../util/Format";
import { UIUtilLang } from "../util/Language";
import { UIUtilStrings } from "../util/Strings";
import { UIUtilTime } from "../util/Time";
import { UIUtilGeneral } from "../util/Util";
import { UIUtilViewData } from "../util/ViewData";
import { UIUtilViewFinanzaEgresoCategoria } from "../utilView/FinanzaEgresoCategoria";
import { UIUtilViewFinanzaEgreso } from "../utilView/FinanzasEgreso";
import { UIUtilViewFinanzaEgresoCargaXMLMasivo } from "../utilView/FinanzasEgresoCargaXMLMasivo";
import { UIUtilViewFinanzaEgresoPago } from "../utilView/FinanzasEgresoPago";
import { UIUtilViewFinanzaEgresoUtil } from "../utilView/FinanzasEgresoUtil";

import CAccionPermiso = Entidad.CAccionPermiso;
import ICuenta = Entidad.IFinanzaCuentaBancaria;
import CCategoria = Entidad.CFinanzaCategoriaMovimiento;
import CTipoCuenta = Entidad.CTipoCuentaBancaria;

export namespace EgresoMovimientoInterfaces {
    export interface IEgresoMovimiento extends Entidad.IFinanzaEgresoMovimiento {
        KinderFiltro: number[];
        NombreEscuela: string;
        ProveedorNombre: string;
        DescuentoFmt: string;
        /** total */
        ImpuestosTrasladados: number;
        ImpuestosTrasladadosFmt: string;
        /** total */
        ImpuestosRetenidos: number;
        ImpuestosRetenidosFmt: string;
        /** FechaAplicacion as 'MM YYYY' */
        PeriodoFmt: string;
        TipoEgresoFmt: string;
        get CategoriaFmt(): string;
        ValorCargoFmt: string;
        SaldoFinal: number;
        SaldoFmt: string;
        FechaAplicacionFmt: string;
        FechaVencimientoFmt: string;
        Detalles: IDetalle[];
        DeudaAux: number;
        DeudaAuxFmt: string;

        Pago: number;
        PagoFmt: string;
        IdAgrupador: number;
        IdCuentaPago: number;
        TipoRegistroFmt: string;
    }

    export interface IDetalle extends Entidad.IFinanzaEgresoMovimientoDetalle {
        ValorFmt: string;
        SaldoFmt: string;
        FechaAplicacionFmt: string;
        Descripcion: string;
        DeudaAux: number;
        DeudaAuxFmt: string;
        get getCuenta(): ICuenta;
        _IdEscuela: number;
        // _ParentCargo: IEgresoMovimiento;
        TipoEgreso: number;
        TipoEgresoFmt: string;
        IdCategoria: number;
        get CategoriaFmt(): string;
        Pago: number;
        PagoFmt: string;
        TipoRegistroFmt: string;
        Folio: string;
    }
}
type IEgresoMovimiento = EgresoMovimientoInterfaces.IEgresoMovimiento;
type IDetalle = EgresoMovimientoInterfaces.IDetalle;

type IItemCombo = UIUtilViewData.IBaseData;

type TPermissionID = Entidad.CModulo.FinanzasEgresosMovimientos;
const IdVistaPagos = 'Pagos';
const IdVistaCargos = 'Cargos';
export class UIVentanaFinanzasEgresosMovimientos extends VentanaGrid<IEgresoMovimiento, TPermissionID>{
    private comboYear: SelectV2<IItemCombo, "Id", "monoselect">;
    private comboMonth: SelectV2<IItemCombo, "Id", "monoselect">;
    private forceDownloadData: boolean;
    private relEscuelasMovimientos: { [k in number]: IEgresoMovimiento[] };

    private modoPagos: boolean;

    constructor(content: d3.Selection<HTMLDivElement, undefined, HTMLElement, any>, modulo: TPermissionID) {
        super(content, modulo, {
            ModuloObservableToTblRefresh: [Entidad.CTipoRequest.EgresoCategoria],
            ModuloForceRequestOnMostrar: [Entidad.CTipoRequest.EgresoCategoria],
        });
        this.modoPagos = false;
    }

    protected GRID_GetDataRequestID(): DataModuloMain.TipoRequestMonitorId {
        return null;// data.Entidades.CTipoRequest.EgresoMovimiento;
    }

    protected GRID_GetTableConfigBase(): IGridRenderInfo<IEgresoMovimiento> {
        return {
            IdTabla: this.GetIdTablaActual(),
            DefaultSort: null,
            IdData: "Id",
            MinWidth: 1780,
            Columns: this.GetColumnas_VistaActual(),
        }
    }


    protected GRID_GetTablaId(): string {
        return "FinanzasEgresos-Movimientos"
    }

    private GetColumnas_VistaActual(): IGridRenderInfo<IEgresoMovimiento>["Columns"] {
        return (this.modoPagos ? this.GetColumnas_VistaPagos() : this.GetColumnas_VistaCargos())
            .map(d => {
                if (d.LabelLangKey === undefined) {
                    d.LabelLangKey = UIUtilLang._FixFieldKey(d.Field);
                }
                return d;
            });
    }

    private GetIdTablaActual() {
        return (this.GRID_GetTablaId() + "-" + (this.modoPagos ? IdVistaPagos : IdVistaCargos));
    }

    // VISTA_CARGOS
    protected GetColumnas_VistaCargos(): IGridRenderInfo<IEgresoMovimiento>["Columns"] {
        return [
            { Field: "_DOC" as any, Label: "", Width: "1%", MinWidth: "30px", IsSortable: false, Icon: { Type: "file", Text: "DOC" } },
            { Field: "PeriodoFmt", Label: "periodo", Width: "7%", MinWidth: "75px" },
            { Field: "Folio", Label: "folio", Width: "10%", MinWidth: "75px" },
            { Field: "ProveedorNombre", Label: "proveedor", LabelLangKey: "d_field_proveedor", Width: "12%", MinWidth: "85px" },
            { Field: "TipoEgresoFmt", Label: "TipoEgreso", Width: "15%", MinWidth: "75px" },
            { Field: "CategoriaFmt", Label: "categoria", Width: "15%", MinWidth: "85px" },
            { Field: "Descripcion", Label: "descripcion", Width: "16%", MinWidth: "85px" },
            { Field: "ValorCargoFmt", Label: "Cargo", Width: "11%", MinWidth: "75px", OrderField: "Valor" },
            { Field: "DescuentoFmt", Label: "Descuento", Width: "11%", MinWidth: "95px" },
            { Field: "ImpuestosRetenidosFmt", Label: "Impuestos retenidos", Width: "12%", MinWidth: "95px", OrderField: "ImpuestosRetenidos" },
            { Field: "ImpuestosTrasladadosFmt", Label: "Impuestos trasladados", Width: "12%", MinWidth: "95px", OrderField: "ImpuestosTrasladados" },
            { Field: "DeudaAuxFmt", Label: "deuda", Width: "10%", MinWidth: "75px", OrderField: "DeudaAux" },
            { Field: "SaldoFmt", Label: "saldo", Width: "10%", MinWidth: "75px", OrderField: "SaldoFinal" },
            { Field: "FechaAplicacionFmt", Label: "aplicacion", Width: "10%", MinWidth: "95px", OrderField: "FechaAplicacion" },
            { Field: "FechaVencimientoFmt", Label: "vencimiento", Width: "10%", MinWidth: "100px", OrderField: "FechaVencimiento" },
            { Field: "NombreEscuela", Label: "Escuela", Width: "11%", MinWidth: "75px" },
        ]
    }

    // VISTA_PAGOS
    private GetColumnas_VistaPagos(): IGridRenderInfo<IEgresoMovimiento>["Columns"] {
        return [
            { Field: "_DOC" as any, Label: "", Width: "1%", MinWidth: "30px", IsSortable: false, Icon: { Type: "file", Text: "DOC" } },
            // { Field: "PeriodoFmt", Label: "periodo", Width: "7%", MinWidth: "60px" },
            // { Field: "Folio", Label: "folio", Width: "10%", MinWidth: "75px" },
            { Field: "ProveedorNombre", Label: "proveedor", LabelLangKey: "d_field_proveedor", Width: "12%", MinWidth: "75px" },
            { Field: "Descripcion", Label: "descripcion", Width: "16%", MinWidth: "75px" },
            { Field: "FechaAplicacionFmt", Label: "aplicacion", Width: "10%", MinWidth: "75px", OrderField: "FechaAplicacion" },
            { Field: "PagoFmt", Label: "pago", LabelLangKey: "tag_pago", Width: "10%", MinWidth: "75px", OrderField: "Pago" },
            { Field: "TipoRegistroFmt", Label: "tipo pago", LabelLangKey: "d_field_tipopago", Width: "10%", MinWidth: "75px", IsSortable: false },
            { Field: "SaldoFmt", Label: "saldo", LabelLangKey: "d_field_adeudopendiente", Width: "10%", MinWidth: "75px", IsSortable: false },
            { Field: "Folio", Label: "folio", Width: "15%", MinWidth: "75px" },
            { Field: "TipoEgresoFmt", Label: "TipoEgreso", Width: "15%", MinWidth: "75px", IsSortable: false },
            { Field: "CategoriaFmt", Label: "categoria", Width: "15%", MinWidth: "75px", IsSortable: false },
            // { Field: "ValorCargoFmt", Label: "Cargo", Width: "11%", MinWidth: "75px", OrderField: "Valor" },
            // { Field: "DescuentoFmt", Label: "Descuento", Width: "11%", MinWidth: "75px" },
            // { Field: "ImpuestosRetenidosFmt", Label: "Impuestos retenidos", Width: "12%", MinWidth: "75px", OrderField: "ImpuestosRetenidos" },
            // { Field: "ImpuestosTrasladadosFmt", Label: "Impuestos trasladados", Width: "12%", MinWidth: "75px", OrderField: "ImpuestosTrasladados" },
            // { Field: "DeudaAuxFmt", Label: "deuda", Width: "10%", MinWidth: "75px", OrderField: "DeudaAux" },
            // { Field: "PagoFmt", Label: "pago", Width: "10%", MinWidth: "75px", OrderField: "Valor" },
            // { Field: "FechaVencimientoFmt", Label: "vencimiento", Width: "10%", MinWidth: "75px", OrderField: "FechaVencimiento" },
            { Field: "NombreEscuela", Label: "Escuela", Width: "11%", MinWidth: "75px" },
        ];
    }

    protected GRID_GetFilters(): Table.IParametroFiltro<IEgresoMovimiento>[] {
        return this.GetFiltros_VistaActual();
    }

    private GetFiltros_VistaActual(): Table.IParametroFiltro<IEgresoMovimiento>[] {
        return (this.modoPagos ? this.GetFiltros_VistaPagos() : this.GetFiltros_VistaCargos())
            .map(d => {
                if (d.LabelLangKey === undefined) {
                    d.LabelLangKey = UIUtilLang._FixFieldKey(d.Field);
                }
                return d;
            });
    }

    private GetFiltros_VistaCargos(): Table.IParametroFiltro<IEgresoMovimiento>[] {
        return [
            { Field: "PeriodoFmt" },
            { Field: "Folio" },
            { Field: "ProveedorNombre", LabelLangKey: "d_field_proveedor" },
            { Field: "TipoEgreso", Type: "select", Options: UIUtilViewData._GetList_EgresoTipo() },
            {
                Field: "CategoriaFmt", Type: "select", Options: () => {
                    return UIUtilViewFinanzaEgresoCategoria._GetCategoriasFullList().map(d => ({ Id: d.Nombre, Name: d.Nombre }));
                }
            },
            {
                Field: "Descripcion", Type: "select",
                Options: () => DataModuloMain._GetReqDataArrayByName("EgresoPredefinido")
                    .map(d => ({ Id: d.Descripcion, Name: d.Descripcion }))
                    .concat(this.ctrlTabla._data.map(d => ({ Id: d.Descripcion, Name: d.Descripcion })))
                    .filter(d => (!!d.Name))
                    .sort((a, b) => d3.ascending(a.Name.toLowerCase(), b.Name.toLowerCase())),
            },
            {
                Field: "TipoCuentaPago" as any,
                LabelLangKey: "tag_tipocuentapago",
                Type: "select",
                Options: UIUtilViewData._GetList_TipoCuentaBancaria(),
                OnGetValueToMatch: (dato: EgresoMovimientoInterfaces.IEgresoMovimiento) => dato.Detalles.reduce((res, d) => {
                    if ([CCategoria.PagoParcial, CCategoria.PagoCompleto].includes(d.TipoRegistro)) {
                        if (!d.getCuenta) return res;
                        res.push(d.getCuenta.Tipo);
                    }
                    return res;
                }, <CTipoCuenta[]>[])
            },
            {
                Field: "BeneficiarioCuentaPago" as any,
                LabelLangKey: "tag_beneficiariocuentapago",
                OnGetValueToMatch: (dato: EgresoMovimientoInterfaces.IEgresoMovimiento) => dato.Detalles.reduce((res, d) => {
                    if ([CCategoria.PagoParcial, CCategoria.PagoCompleto].includes(d.TipoRegistro)) {
                        if (!d.getCuenta) res.push("no disponible");
                        else res.push(d.getCuenta.Beneficiario);
                    }
                    return res
                }, <string[]>[])
            },
            {
                Field: "CuentaPago" as any,
                LabelLangKey: "tag_cuentapago",
                OnGetValueToMatch: (dato: EgresoMovimientoInterfaces.IEgresoMovimiento) => dato.Detalles.reduce((res, d) => {
                    if ([CCategoria.PagoParcial, CCategoria.PagoCompleto].includes(d.TipoRegistro)) {
                        const cuenta = d.getCuenta;
                        if (!cuenta) {
                            res.push("no disponible"); // FIX_LANGUAGE
                            return res;
                        }
                        res.push(cuenta.Numero);
                        res.push(cuenta.Clave);
                        res.push(cuenta.NombreBanco);
                        res.push(cuenta.Sucursal);
                    }
                    return res
                }, <string[]>[])
            },
            { Field: "DeudaAux", Type: "number" },
            { Field: "Descuento", Type: "number" },
            { Field: "RetencionIEPS", Type: "number" },
            { Field: "RetencionISR", Type: "number" },
            { Field: "RetencionIVA", Type: "number" },
            { Field: "ImpuestosRetenidos", Type: "number", }, // total
            { Field: "TrasladoIEPS", Type: "number" },
            { Field: "TrasladoIVA", Type: "number" },
            { Field: "ImpuestosTrasladados", Type: "number" }, // total
            { Field: "Saldo", Type: "number", OnGetValueToMatch: (dato: IEgresoMovimiento) => [UIUtilViewFinanzaEgresoUtil._GetSaldoFinalFromMto(dato)] },
            { Field: "FechaAplicacion", Type: "date" },
            { Field: "FechaVencimiento", Type: "date" },
        ]
    }

    private GetFiltros_VistaPagos(): Table.IParametroFiltro<IEgresoMovimiento>[] {
        return [
            { Field: "ProveedorNombre", LabelLangKey: "d_field_proveedor" },
            { Field: "Descripcion" },
            { Field: "FechaAplicacion", Type: "date" },
            { Field: "Pago", Type: "number", LabelLangKey: "tag_pago" },
            { Field: "Folio", OnGetValueToMatch: (dato) => dato.Detalles.map(d => d.Folio) },
            { Field: "TipoEgresoFmt", OnGetValueToMatch: (dato) => dato.Detalles.map(d => d.TipoEgresoFmt) },
            { Field: "CategoriaFmt", OnGetValueToMatch: (dato) => dato.Detalles.map(d => d.CategoriaFmt) },
        ]
    }

    protected GRID_GetTableConfigAdvanced(): IGridExtraTableConfig<IEgresoMovimiento> {
        return {
            EvaluatorAndSubLevelsBuild: {
                OnStepCellTable: (container, datum, field: (keyof IEgresoMovimiento)) => {
                    if (field == "_DOC" as any) {
                        UIUtilViewFinanzaEgresoUtil._TblRowIconDocumentation(container, datum.Id);
                    }
                    if (field == "Folio") {
                        CopyElementTextControl._AddCopyTextBehaviour(container.node(), () => datum.Folio);
                    }
                    if (field == "FechaVencimientoFmt") {
                        container.classed("fecha_venc", true);
                    }
                },
                OnStepRowTable: (d, tr, rb, i) => tr.classed("vencido", UIUtilViewFinanzaEgresoUtil._EsMovimientoVencido({
                    PagoPendiente: d.SaldoFinal,
                    FechaVencimiento: d.FechaVencimiento,
                    IdEscuela: d.IdEscuela,
                })),
                AddRowMultiline: {
                    OnGetDataToAddRow: (dato) => dato.Detalles,
                    OnStepNewTrs: (d: IDetalle, tr) => (tr.node().onclick = e => (console.debug(d.Id, d), e.stopPropagation()), true),
                    OnStepCell: (container, datum: IDetalle, field: keyof IEgresoMovimiento) => {
                        switch (field) {
                            case "_DOC" as any:
                                UIUtilViewFinanzaEgresoUtil._TblRowIconDocumentation(container, datum.Id);
                                break;
                            //     container
                            //         .style("border-left", "2px solid var(--color_borderbox1)")
                            //         .style("padding-left", "var(--padding1)");
                            case "Descripcion":
                            case "TipoEgresoFmt":
                            case "CategoriaFmt":
                            case "Folio":
                            case "DeudaAuxFmt":
                            case "SaldoFmt":
                            case "PagoFmt":
                            case "TipoRegistroFmt":
                            case "FechaAplicacionFmt":
                            case "MontoFmt" as any: // NOTE Se usa en la ventana egreso-movimientos-proveedor
                                container.text(datum[field])
                                break;
                        }
                    },
                }
            },
            OnEndUpdateDataInView: (data, dataChecked) => this.UpdateAreaResumen([
                ...data.map(d => d.data),
                ...dataChecked.map(d => d.data)
            ])
        };
    }


    protected GRID_GetMenuTopGrid(): Array<Table.ITableMenuTopDefaultOptionConfig> {
        const opciones: Array<Table.ITableMenuTopDefaultOptionConfig> = []
        if (this.modoPagos) return [];
        if (this.GridHasPermisoAccion(CAccionPermiso.Agregar)) {
            opciones.push(
                {
                    Label: "Agregar",
                    Callback: () => this.OpenProceso_AgregarEgreso(),
                },
                {
                    Label: "tag_agregar_xml",
                    Callback: () => this.OpenProceso_AgregarEgresosXML(),
                });
        }
        return opciones;
    }

    protected GRID_GetSelectionDataMenuV2(menuLocation: "row" | "top-selected", dataGridSelected: IEgresoMovimiento[]): Table.ITableMenuDataSelectedOptionConfig<IEgresoMovimiento>[] {
        let opciones: Table.ITableMenuDataSelectedOptionConfig<IEgresoMovimiento>[] = [];
        let [dato1] = dataGridSelected;

        if (this.modoPagos) return [];

        if (this.GridHasPermisoAccionV2(CAccionPermiso.Agregar, dataGridSelected.map(d => d.IdEscuela))) {
            const movs = dataGridSelected.map(d => ({ ...d, CantidadPago: UIUtilViewFinanzaEgresoUtil._GetSaldoFinalFromMto(d) }));
            const { EsMismoProveedor, EsMismaEscuela, MovsAdeudo } = UIUtilViewFinanzaEgresoPago._PreparaMovimientos_PagoAgrupado(movs);
            // const saldoValido =  > 0;
            if (dataGridSelected.length == 1)
                opciones.push({
                    Label: "tag_pagar",
                    MultiData: false,
                    GetDetails: () => ({
                        Enabled: !!MovsAdeudo.length,
                        Description: MovsAdeudo.length ? null : "Movimiento con pagos", // FIX_LANGUAGE
                    }),
                    Callback: async () => {
                        const pagoAgregado = await UIUtilViewFinanzaEgresoPago._OpenModal_PagarEgresoMov({
                            IdMovimiento: dato1.Id,
                            IdEscuela: dato1.IdEscuela,
                            Concepto: dato1.Descripcion,
                            CantidadPago: UIUtilViewFinanzaEgresoUtil._GetSaldoFinalFromMto(dato1),
                            FechaEmision: dato1.FechaAplicacion,
                        })
                        if (pagoAgregado) {
                            this.ctrlProgressBar.node()._Oculto = false;
                            this.forceDownloadData = true;
                            this.GridUpdateData();
                        }
                    }
                })
            else if (dataGridSelected.length > 1)
                opciones.push({
                    Label: "tag_pagar",
                    MultiData: true,
                    GetDetails: () => ({
                        Enabled: EsMismaEscuela && EsMismoProveedor && !!MovsAdeudo.length,
                        Description: !EsMismaEscuela ? "Los adeudos pertenecen a diferentes escuelas" :
                            !EsMismoProveedor ? "Los adeudos pertenecen a diferentes proveedores" :
                                !MovsAdeudo.length ? "Los movimientos ya han sido pagados" : null, // FIX_LANGUAGE
                    }),
                    Callback: async () => {
                        const pagoAgregado = await UIUtilViewFinanzaEgresoPago._OpenModal_PagoMultipleAgrupado(dataGridSelected.map(d => ({
                            IdMovimiento: d.Id,
                            IdEscuela: d.IdEscuela,
                            CantidadPago: UIUtilViewFinanzaEgresoUtil._GetSaldoFinalFromMto(d),
                            FechaEmision: d.FechaEmision,
                            Concepto: d.Descripcion,
                            ProveedorRazonSocial: d.ProveedorNombre,
                            IdProveedor: d.IdProveedor,
                        })))
                        if (pagoAgregado) {
                            this.ctrlProgressBar.node()._Oculto = false;
                            this.forceDownloadData = true;
                            this.GridUpdateData();
                        }
                    }
                })
        }

        if (this.GridHasPermisoAccionV2(CAccionPermiso.Editar, dataGridSelected.map(d => d.IdEscuela)))
            opciones.push({
                Label: "Editar",
                MultiData: false,
                GetDetails: (movs) => {
                    const disable = movs.some(m => UIUtilViewFinanzaEgresoUtil._MovHasPago(m));
                    return {
                        Description: disable ? "Movimiento con pagos" : null, // FIX_LANGUAGE
                        Enabled: !disable,
                    }
                },
                Callback: ([mov]) => this.OpenModal_EditarEgreso(mov),
            })

        if (this.GridHasPermisoAccionV2(CAccionPermiso.Eliminar, dataGridSelected.map(d => d.IdEscuela)))
            opciones.push({
                Label: "Eliminar",
                MultiData: true,
                GetDetails: (movs) => {
                    const disable = movs.some(m => UIUtilViewFinanzaEgresoUtil._MovHasPago(m));
                    return {
                        Description: disable ? "Movimiento con pagos" : null, // FIX_LANGUAGE
                        Enabled: !disable,
                    }
                },
                Callback: movs => this.OpenModal_EliminarMovimientoPadre(movs),
            })
        return opciones;
    }

    protected GRID_GetExportarConfig(dataGrid: IEgresoMovimiento[]): IConfigGridExcelExport<IEgresoMovimiento> {
        return {
            IdsEscuelas: [...new Set(dataGrid.map(d => d.IdEscuela))],
            ColumnsConfig: this.ctrlTabla
                ._InfoColumns
                .filter(d => (d.Field != "_DOC" as any))
                .map<ExcelThings.IColumnToExcelExportFileConfig<IEgresoMovimiento>>((d) => ({
                    Field: d.Field as keyof IEgresoMovimiento,
                    HeaderTag: d.Label,
                    WidthCell: 25
                })),
            OnGetDataBySheets: async () => {
                let datos = dataGrid.reduce((result, mov) => {
                    result.push(mov);
                    result.push(...mov.Detalles
                        .map(d => {
                            let dd = { ...d };
                            dd["IdEscuela"] = mov.IdEscuela;
                            return dd;
                        }));
                    return result;
                }, [])
                return Array.from(d3Group(datos, d => d.IdEscuela))
                    .map<ExcelThings.ISheetConfig<IEgresoMovimiento>>(([idEscuela, datos]) => ({
                        IdSheet: idEscuela,
                        SheetName: datos[0].NombreEscuela,
                        Data: datos
                    }))
            },
            OnGetEscuelasTagInSheet: (dataSheet) => dataSheet[0].NombreEscuela,
        }
    }

    private async OpenProceso_AgregarEgreso() {
        const escuelaSelected = this.GridGetEscuelasSeleccionadas();
        let egresoAgregado = await UIUtilViewFinanzaEgreso._OpenModal_MovEgreso_Agregar(this.modulo, escuelaSelected);
        if (egresoAgregado) {
            this.forceDownloadData = true;
            this.GridUpdateData(true);
        }
    }

    private async OpenModal_EditarEgreso(mov: EgresoMovimientoInterfaces.IEgresoMovimiento) {
        let egresoAgregado = await UIUtilViewFinanzaEgreso._OpenModal_MovEgreso_Editar(this.modulo, {
            ...mov,
            ValorCargo: mov.Valor,
        });
        if (egresoAgregado) {
            this.forceDownloadData = true;
            this.GridUpdateData(true);
        }
    }

    private async OpenProceso_AgregarEgresosXML() {
        UIUtilViewFinanzaEgresoCargaXMLMasivo._OpenModal_AgregarEgresosXML({
            Modulo: this.modulo,
            Escuelas: this.GridGetEscuelasSeleccionadas(),
            OnEnd: (nuevosRegistros) => {
                if (nuevosRegistros) {
                    this.forceDownloadData = true;
                    this.GridUpdateData();
                }
            }
        });
    }

    private OpenModal_EliminarMovimientoPadre(movs: IEgresoMovimiento[]) {
        let forceUpdate = false;
        this.GridOpenModal_ProccessArrayData({
            DataToProccess: movs,
            OnError_GetItemDataTag: m => `${m.Descripcion}\n${m.PeriodoFmt}` + (m.Folio ? "\n" + this.VB_GetUIStringModule("d_field_folio") + ": " + m.Folio : ""),
            AutoReloadGridRequestOnFinally: false,
            OnGetIdEscuela: m => m.IdEscuela,
            OnStepAProccess: async m => {
                const res = await DataModuloEgresoMovimiento._EliminarEgreso(m.Id);
                if (!forceUpdate) forceUpdate = (res.Resultado == -1 || res.Resultado == -2)
                return res
            },
            OnEndAndCloseProccess: (successM, idsEscuelas) => {
                if (!successM.length && !forceUpdate) return;
                this.forceDownloadData = true;
                this.GridUpdateData();
            }
        })
    }

    protected AjustaDrawGrid() {
        this.windowContent.classed("grid_egreso_movimiento", true);
        const containerTop = d3.create("div")
            .attr("class", "top_container");

        this.windowContent.select<HTMLDivElement>(".right").node()
            .insertAdjacentElement("afterbegin", containerTop.node());

        // AREA COMBOS

        let topFiltrosContainer = containerTop.append("div")
            .attr("class", "top_filtros_container");

        this.comboYear = new SelectV2<IItemCombo, "Id", "monoselect">({
            Type: "monoselect",
            Parent: topFiltrosContainer,
            ValueMember: "Id",
            DisplayMember: "Name",
            ShowAndEnableSearchText: true,
            Data: [],
            OnChange: (id, item) => {
                if (item) {
                    let mesesDisponibles = UIUtilViewData._GetMonthsByYearCurrentMonthLimit(item.Id);
                    this.comboMonth._UpdateList(mesesDisponibles);
                    let selectedM = this.comboMonth._dataSelected[0];
                    if (!selectedM) {
                        this.comboMonth._valueSelect(mesesDisponibles.reduce((a, b) => (a.Id < b.Id ? a : b)).Id);
                    }
                } else {
                    this.comboMonth._UpdateList([]);
                }
                // this.noForceDownloadData = false;
                // this.GridUpdateDataWithDelay(0);
                this.GridUpdateData();
            }
        })

        this.comboMonth = new SelectV2<IItemCombo, "Id", "monoselect">({
            Type: "monoselect",
            Parent: topFiltrosContainer,
            ValueMember: "Id",
            DisplayMember: "Name",
            ShowAndEnableSearchText: true,
            OnChange: (idMonth, item) => {
                // this.noForceDownloadData = false;
                // this.GridUpdateDataWithDelay(0);
                this.GridUpdateData();
            }
        })

        // >> Valores iniciales del periodo

        const aniosDisponibles = this.GetAniosDisponibles();
        let anioInicial = aniosDisponibles[0].Id;
        let mesesDisponibles = UIUtilViewData._GetMonthsByYearCurrentMonthLimit(anioInicial);
        let mesInicial = mesesDisponibles[mesesDisponibles.length - 1].Id;

        this.comboYear._UpdateList(aniosDisponibles);
        this.comboYear._valueSelect(anioInicial);
        this.comboMonth._UpdateList(mesesDisponibles);
        if (!this.comboMonth._dataSelected[0]) {
            this.comboMonth._valueSelect(mesInicial);
        }

        // >> Filtros Wrappers

        topFiltrosContainer.append(() => {
            return ElementWrapper._WrapperToSelectControl(this.comboYear, UIUtilLang._GetUIString("time", "anio") + ":")
                .style("min-width", "125px")
                .node();
        })

        topFiltrosContainer.append(() => {
            return ElementWrapper._WrapperToSelectControl(this.comboMonth, UIUtilLang._GetUIString("time", "mes") + ":")
                .style("min-width", "125px")
                .node();
        })

        topFiltrosContainer.append("div")
            .attr("class", "periodos_container")
            .classed(UIUtilGeneral.FBoxOrientation.Horizontal, true)
            .classed(UIUtilGeneral.FBoxAlign.StartCenter, true)
            .call((container) => {
                container.append("label")
                    .text(this.VB_GetUIStringModule("tag_incluir_anterior"))
                    .attr("for", "incluir_anteriores");
                container.append("input")
                    .attr("type", "checkbox")
                    .attr("id", "incluir_anteriores")
                    .on("change", () => {
                        // this.noForceDownloadData = false;
                        // this.GridUpdateDataWithDelay(0);
                        this.GridUpdateData();
                    });
            })

        topFiltrosContainer.append("div")
            .attr("class", "modo_vista")
            .classed(UIUtilGeneral.FBoxOrientation.Horizontal, true)
            .classed(UIUtilGeneral.FBoxAlign.StartCenter, true)
            .call((container) => {
                container.append("label")
                    // .text(this.VB_GetUIStringModule("tag_incluir_anterior"))
                    .text(this.modoPagos ? "Vista: POR PAGOS" : "Vista: POR CARGOS") // FIX_LANGUAGE
                    .attr("for", "modo_vista");
                container.append("input")
                    .attr("class", "hide")
                    .attr("type", "checkbox")
                    .attr("id", "modo_vista")
                    .property("checked", this.modoPagos)
                    .on("change", async () => {
                        const input = container.select<HTMLInputElement>("input").node();
                        this.modoPagos = input.checked;
                        container.select("label")
                            .text(this.modoPagos ? "Vista: POR PAGOS" : "Vista: POR CARGOS") // FIX_LANGUAGE

                        // MODIFICAR VISTA AQUÍ
                        this.ctrlTabla._SetIdTable(this.GetIdTablaActual());
                        this.ctrlTabla._SetColumns(this.GetColumnas_VistaActual());
                        this.ctrlTabla._SetMainFilters(this.GetFiltros_VistaActual());
                        this.ctrlProgressBar.node()._Visible = true;
                        this.ctrlTabla._UpdateData([]);
                        await UIUtilGeneral._Sleep(500);
                        this.GridUpdateData();
                        this.ctrlProgressBar.node()._Visible = false;
                    });
            })

        // AREA RESUMEN

        containerTop.append("div")
            .attr("class", "info")
            .call((infoCont) => {
                // infoCont.append<HTMLImage2Component>("wc-img")
                //     .attr("spinner-border-width", 5)
                //     .attr("spinner-dim", 40)
                //     .attr("default-src", Util.IconResources.CMenuIco.Escuela);
                infoCont.append("div")
                    .attr("class", "resumen dates");
                infoCont.append("div")
                    .attr("class", "resumen totales");
            })

        this.UpdateAreaResumen([]);
    }

    private UpdateAreaResumen(datos: IEgresoMovimiento[]) {
        // const [escuelaSelected] = this.GridGetEscuelasSeleccionadas();
        const info = this.windowContent.select(".info");
        // info.select("wc-img").attr("src", escuelaSelected?.Logo);
        info.select(".dates")
            .html((() => {
                let str = `<label><b>${this.VB_GetUIStringModule("tag_fecha")}: </b> ${UIUtilTime._DateFormatStandar(new Date())}</label>`;
                return str + `<label><b>${this.VB_GetUIStringModule("tag_periodo")}: </b> ${UIUtilStrings._CapitaliceString(UIUtilTime._DateFormatStandar(new Date(), "MMM yyyy"))}</label>`;
            })())
        info.select(".totales")
            .html((() => {
                const yearSelected = this.comboYear._dataSelected[0].Id;
                const monthSelected = this.comboMonth._dataSelected[0].Id;
                const [periodoPendiente, totalPendiente, pagado] = datos
                    .reduce(([periodoPendiente, totalPendiente, pagado], movimiento) => {
                        if (this.modoPagos) {
                            pagado += movimiento.Pago;
                            return [periodoPendiente, totalPendiente, pagado];
                        }
                        const saldoF = UIUtilViewFinanzaEgresoUtil._GetSaldoFinalFromMto(movimiento);
                        totalPendiente += saldoF;
                        pagado += movimiento.Detalles?.reduce((t, detalle) => {
                            if ([CCategoria.PagoParcial, CCategoria.PagoCompleto].includes(detalle.TipoRegistro)) {
                                t += detalle.Valor;
                            }
                            return t;
                        }, 0) || 0;
                        let dtAplicacion = new DateV2(movimiento.FechaAplicacion)._SetTimeZoneByIdSchool(movimiento.IdEscuela);
                        const validadorYM = (() => {
                            let dtCurrentPeriodo = new DateV2(new Date(yearSelected, monthSelected, 2));
                            // .met_SetTimeZoneByIdSchool(movimiento.IdEscuela, true);
                            return UIUtilTime._GetValidatorDateYM(dtCurrentPeriodo);
                        })()
                        if (UIUtilTime._GetValidatorDateYM(dtAplicacion) == validadorYM) {
                            periodoPendiente += saldoF;
                        }
                        return [periodoPendiente, totalPendiente, pagado];
                    }, [0, 0, 0]);
                // let str = ""
                let str = "";
                if (!this.modoPagos) {
                    str += `<label><b>${this.VB_GetUIStringModule("tag_periodopendiente")}: </b> ${UIUtilFormat._CurrencyFmt(periodoPendiente)}</label>`;
                    if (this.IncluirPeriodosAnteriores()) {
                        str += `<label><b>${this.VB_GetUIStringModule("tag_totalpendiente")}: </b> ${UIUtilFormat._CurrencyFmt(totalPendiente)}</label>`;
                    }
                }
                str += `<label><b>${this.VB_GetUIStringModule("tag_totalpagado")}: </b> ${UIUtilFormat._CurrencyFmt(pagado)}</label>`;
                return str;
            })())
    }

    /** @override */
    protected async GridOnKinderSelectionChange(): Promise<void> {
        // super.GridOnKinderSelectionChange();
        this.ctrlTabla._CheckAllItems(false);
        this.GridUpdateData();
    }

    /** @override */
    protected GridInitTable() {
        super.GridInitTable();
        this.AjustaDrawGrid();
    }

    /** @override */
    protected GridOnSyncData(): Promise<IEgresoMovimiento[]> {
        this.forceDownloadData = true;
        return super.GridOnSyncData();
    }

    /**
     * @override
     */
    protected async GridGetData(): Promise<IEgresoMovimiento[]> {
        let primerCarga = false;
        if (!this.relEscuelasMovimientos) {
            primerCarga = true;
            this.relEscuelasMovimientos = {};
            // this.GridGetEscuelasConPermisoDeAccion(CAccionPermiso.Ver).forEach(escuela => { this.relEscuelasMovimientos[escuela.IdKinder] = []; });
        }
        const force = this.forceDownloadData;
        const idsEscuelasSeleccionadas = this.GridGetEscuelasSeleccionadas().map(d => d.IdKinder);
        const idsEscuelasConsultarMovs = (() => {
            if (this.forceDownloadData) {
                this.forceDownloadData = false;
                return [...idsEscuelasSeleccionadas];
            }
            return idsEscuelasSeleccionadas.filter(idEscuela => (!this.relEscuelasMovimientos[idEscuela]));
        })();
        const idsEscuelasConsultaSecundaria = (() => {
            if (force || primerCarga) return idsEscuelasSeleccionadas;
            return idsEscuelasSeleccionadas.filter(idEscuela => (!this.relEscuelasMovimientos[idEscuela]));
        })()

        if (idsEscuelasConsultarMovs.length) {
            if (!this.ctrlProgressBar.node()._Visible) this.ctrlProgressBar.node()._Visible = true;
            // Request
            let error = false;
            await new ArrayV2<number>()
                ._Push(...idsEscuelasConsultarMovs)
                ._ForEachAwait(async idEscuela => {
                    const res = await DataModuloEgresoMovimiento._GetMovimientos([idEscuela]);
                    if (!error && res.Resultado <= 0) error = true;
                    if (res.Datos) this.relEscuelasMovimientos[idEscuela] = res.Datos as IEgresoMovimiento[];
                    // .map(d => {
                    //     if (!d.Detalles) d.Detalles = [];
                    //     d.Detalles.forEach(dd => {
                    //         dd._ParentCargo = d;
                    //     })
                    //     return d;
                    // });
                })
            if (error) this.notificacion._Mostrar(UIUtilLang._GetUIString("general", "notif_fail_infosync"), "ADVERTENCIA");
        }
        if (idsEscuelasConsultaSecundaria.length) {
            if (!this.ctrlProgressBar.node()._Visible) this.ctrlProgressBar.node()._Visible = true;
            await MainPage._ReloadServiceAndAwaitBool(Entidad.CTipoRequest.CuentaBancaria, idsEscuelasConsultaSecundaria);
            await MainPage._ReloadServiceAndAwaitBool(Entidad.CTipoRequest.Proveedor, idsEscuelasConsultaSecundaria);
        }
        if (this.ctrlProgressBar.node()._Visible) this.ctrlProgressBar.node()._Visible = false;

        if (this.modoPagos)
            return this.Data_VistaPagos();
        else
            return this.Data_VistaCargos();
    }

    private Data_VistaCargos() {
        const anioFiltro = this.comboYear._dataSelected[0]?.Id;
        const idMesFiltro = this.comboMonth._dataSelected[0]?.Id;
        const incluirPeriodosAnteriores = this.IncluirPeriodosAnteriores();

        return Object.values(this.relEscuelasMovimientos)
            .reduce((result, list) => result.concat(list), [])
            .filter(mov => {
                let validadorMov = UIUtilTime._GetValidatorDateYM(new DateV2(mov.FechaAplicacion)._SetTimeZoneByIdSchool(mov.IdEscuela));
                let validadorPeriodo = UIUtilTime._GetValidatorDateYM(
                    new DateV2(new Date(anioFiltro, idMesFiltro, 1))
                        ._SetTimeZoneByIdSchool(mov.IdEscuela, true)
                );
                if (incluirPeriodosAnteriores)
                    return (validadorMov <= validadorPeriodo);
                else
                    return (validadorMov == validadorPeriodo);
            })
            .sort((a, b) => (new Date(b.FechaAplicacion).getTime() - new Date(a.FechaAplicacion).getTime()))
            .map((m) => {
                const item: IEgresoMovimiento = {
                    ...m,
                    ...<IEgresoMovimiento>{
                        KinderFiltro: [m.IdEscuela],
                        NombreEscuela: DataModuloMain._GetDataValueFieldByName("Escuela", m.IdEscuela, "Nombre"),
                        TipoEgresoFmt: UIUtilViewData._GetStr_EgresoTipo(m.TipoEgreso),
                        ValorCargoFmt: UIUtilFormat._CurrencyFmt(m.Valor),
                        SaldoFmt: (m.Detalles.length ? "" : UIUtilFormat._CurrencyFmt(m.Saldo)),
                        SaldoFinal: UIUtilViewFinanzaEgresoUtil._GetSaldoFinalFromMto(m),
                        FechaAplicacionFmt: UIUtilTime._DateFormatStandarFixTimeZoneByIdSchool(m.FechaAplicacion, m.IdEscuela),
                        FechaVencimientoFmt: UIUtilTime._DateFormatStandarFixTimeZoneByIdSchool(m.FechaVencimiento, m.IdEscuela),
                        PeriodoFmt: UIUtilStrings._CapitaliceString(UIUtilTime._DateFormatStandar(m.FechaAplicacion, "MMM yyyy")),
                        DescuentoFmt: UIUtilFormat._CurrencyFmt(m.Descuento),
                        ImpuestosRetenidos: (m.RetencionIEPS + m.RetencionISR + m.RetencionIVA),
                        ImpuestosTrasladados: (m.TrasladoIEPS + m.TrasladoIVA),
                        ImpuestosRetenidosFmt: ((str) => {
                            if (m.RetencionIVA) str += `${this.VB_GetUIStringModule("tag_iva")}: ${UIUtilFormat._CurrencyFmt(m.RetencionIVA)}`
                            if (m.RetencionIEPS) str += `\n${this.VB_GetUIStringModule("tag_ieps")}: ${UIUtilFormat._CurrencyFmt(m.RetencionIEPS)}`
                            if (m.RetencionISR) str += `\n${this.VB_GetUIStringModule("tag_isr")}: ${UIUtilFormat._CurrencyFmt(m.RetencionISR)}`
                            if (!str) str += UIUtilFormat._CurrencyFmt(0);
                            else if (str.includes("\n")) str += `\n${this.VB_GetUIStringModule("tag_total")}: ${UIUtilFormat._CurrencyFmt(m.RetencionIEPS + m.RetencionISR + m.RetencionIVA)}`
                            return str;
                        })(""),
                        ImpuestosTrasladadosFmt: ((str) => {
                            if (m.TrasladoIVA) str += `${this.VB_GetUIStringModule("tag_iva")}: ${UIUtilFormat._CurrencyFmt(m.TrasladoIVA)}`
                            if (m.TrasladoIEPS) str += `\n${this.VB_GetUIStringModule("tag_ieps")}: ${UIUtilFormat._CurrencyFmt(m.TrasladoIEPS)}`
                            if (!str) str += UIUtilFormat._CurrencyFmt(0);
                            else if (str.includes("\n")) str += `\n${this.VB_GetUIStringModule("tag_total")}: ${UIUtilFormat._CurrencyFmt(m.TrasladoIEPS + m.TrasladoIVA)}`;
                            return str;
                        })(""),
                        DeudaAux: m.Saldo, //(m.Valor + m.ValorInteres),
                        DeudaAuxFmt: UIUtilFormat._CurrencyFmt(m.Saldo), //.Valor + m.ValorInteres),
                        ProveedorNombre: DataModuloMain._GetDataValueFieldByName("Proveedor", m.IdProveedor, "RazonSocial"),
                        Detalles: (m.Detalles || []).map<IDetalle>((d, i) => UIUtilGeneral._ObjectAddGetters(<IDetalle>{
                            ...d,
                            ...{
                                _IdEscuela: m.IdEscuela,
                                SaldoFmt: (i == m.Detalles.length - 1) ? UIUtilFormat._CurrencyFmt(d.Saldo) : "",
                                ValorFmt: UIUtilFormat._CurrencyFmt(d.Valor),
                                FechaAplicacionFmt: UIUtilTime._DateFormatStandarFixTimeZoneByIdSchool(d.FechaAplicacion, m.IdEscuela),
                                DeudaAux: -d.Valor,
                                DeudaAuxFmt: "-" + UIUtilFormat._CurrencyFmt(d.Valor),
                                Descripcion: (() => {
                                    let str = "";
                                    if ([CCategoria.PagoParcial, CCategoria.PagoCompleto].includes(d.TipoRegistro)) {
                                        str = this.VB_GetUIStringModule("tag_pago");
                                        const cuenta = DataModuloMain._GetItemDataByName("CuentaBancaria", d.IdCuentaPago);
                                        if (cuenta) {
                                            str += ` (${cuenta.getTipo})`
                                            str += "\n" + this.VB_GetUIStringModule("tag_cuenta") + ": ";
                                            str += cuenta.Beneficiario;
                                            if (cuenta.Numero) str += ("\n" + cuenta.Numero)
                                            if (cuenta.NombreBanco) str += ` (${cuenta.NombreBanco})`;
                                        }
                                        else {
                                            str += "\n" + this.VB_GetUIStringModule("tag_cuenta") + ": ";
                                            str += UIUtilLang._GetUIString("general", "nodisponible");
                                        }
                                    }
                                    return str;
                                })()
                            }
                        }, {
                            getCuenta: (d) => (d.IdCuentaPago ? DataModuloMain._GetItemDataByName("CuentaBancaria", d.IdCuentaPago) : null),
                        }))
                    }
                };
                return UIUtilGeneral._ObjectAddGetters<IEgresoMovimiento>(item, {
                    CategoriaFmt: () => UIUtilViewFinanzaEgresoCategoria._CategoriaNombre(m.IdCategoria),
                })
            });
    }

    private Data_VistaPagos() {
        const anioFiltro = this.comboYear._dataSelected[0]?.Id;
        const idMesFiltro = this.comboMonth._dataSelected[0]?.Id;
        const incluirPeriodosAnteriores = this.IncluirPeriodosAnteriores();

        return Object.values(this.relEscuelasMovimientos)
            .reduce((result, list) => {
                list.forEach(m => {
                    // if (!m.Detalles.length) {
                    //     // Cargo sin pago
                    //     result.push(<IEgresoMovimiento>{
                    //         Id: m.Id,
                    //         PagoFmt: Util.fn_CurrencyFmt(0),
                    //         FechaAplicacion: new Date(1994, 1, 1, 0, 0, 0, 0).toISOString(),
                    //         IdEscuela: m.IdEscuela,
                    //         KinderFiltro: m.KinderFiltro,
                    //         ProveedorNombre: DataModuloMain.fn_GetDataValueFieldByName("Proveedor", m.IdProveedor, "RazonSocial"),
                    //         TipoEgresoFmt: UIUtilViewData.fn_GetStr_EgresoTipo(m.TipoEgreso),
                    //         Detalles: [],
                    //     })
                    //     return;
                    // }
                    m.Detalles.forEach(d => {
                        let movPagoEnGrupo = d.IdAgrupador ? result.find(dR => dR.IdAgrupador == d.IdAgrupador) : null;
                        let movDetalleCargo = <IEgresoMovimiento["Detalles"][number]>{
                            Id: m.Id,
                            Descripcion: m.Descripcion,
                            FechaAplicacion: m.FechaAplicacion,
                            Saldo: UIUtilViewFinanzaEgresoUtil._GetSaldoFinalFromMto(m),
                            IdCategoria: m.IdCategoria,
                            Folio: m.Folio,
                            // FechaAplicacionFmt: m.FechaAplicacionFmt,
                            TipoEgreso: m.TipoEgreso,
                            TipoEgresoFmt: UIUtilViewData._GetStr_EgresoTipo(m.TipoEgreso),
                            Pago: d.Valor,
                            PagoFmt: UIUtilFormat._CurrencyFmt(d.Valor),
                            TipoRegistroFmt: UIUtilViewData._GetStr_CategoriaMovimiento(d.TipoRegistro),
                        };

                        if (movPagoEnGrupo) {
                            movPagoEnGrupo.Pago += d.Valor; // Pago total del movimiento
                            movPagoEnGrupo.PagoFmt = UIUtilFormat._CurrencyFmt(movPagoEnGrupo.Pago);
                            movPagoEnGrupo.Detalles.push(movDetalleCargo);
                            return;
                        }
                        movPagoEnGrupo = <IEgresoMovimiento>{
                            Id: d.Id,
                            Pago: d.Valor,
                            PagoFmt: UIUtilFormat._CurrencyFmt(d.Valor),
                            IdAgrupador: d.IdAgrupador,
                            IdCuentaPago: d.IdCuentaPago,
                            FechaAplicacion: d.FechaAplicacion,
                            // FechaAplicacionFmt: d.FechaAplicacionFmt,
                            IdEscuela: m.IdEscuela,
                            KinderFiltro: m.KinderFiltro,
                            IdProveedor: m.IdProveedor,
                            // Descripcion: "Pago",
                            // TipoEgreso:
                            Detalles: [movDetalleCargo],
                        }
                        result.push(movPagoEnGrupo);
                    })
                })

                return result;
            }, [])
            .filter(mov => {
                let validadorMov = UIUtilTime._GetValidatorDateYM(new DateV2(mov.FechaAplicacion)._SetTimeZoneByIdSchool(mov.IdEscuela));
                let validadorPeriodo = UIUtilTime._GetValidatorDateYM(
                    new DateV2(new Date(anioFiltro, idMesFiltro, 1))
                        ._SetTimeZoneByIdSchool(mov.IdEscuela, true)
                );
                if (incluirPeriodosAnteriores)
                    return (validadorMov <= validadorPeriodo);
                else
                    return (validadorMov == validadorPeriodo);
            })
            .sort((a, b) => (new Date(b.FechaAplicacion).getTime() - new Date(a.FechaAplicacion).getTime()))
            .map((m) => {
                const item: IEgresoMovimiento = {
                    ...m,
                    ...<IEgresoMovimiento>{
                        KinderFiltro: [m.IdEscuela],
                        NombreEscuela: DataModuloMain._GetDataValueFieldByName("Escuela", m.IdEscuela, "Nombre"),
                        TipoEgresoFmt: UIUtilViewData._GetStr_EgresoTipo(m.TipoEgreso),
                        ValorCargoFmt: UIUtilFormat._CurrencyFmt(m.Valor),
                        SaldoFmt: (m.Detalles.length ? "" : UIUtilFormat._CurrencyFmt(m.Saldo)),
                        SaldoFinal: UIUtilViewFinanzaEgresoUtil._GetSaldoFinalFromMto(m),
                        FechaAplicacionFmt: UIUtilTime._DateFormatStandarFixTimeZoneByIdSchool(m.FechaAplicacion, m.IdEscuela),
                        FechaVencimientoFmt: UIUtilTime._DateFormatStandarFixTimeZoneByIdSchool(m.FechaVencimiento, m.IdEscuela),
                        PeriodoFmt: UIUtilStrings._CapitaliceString(UIUtilTime._DateFormatStandar(m.FechaAplicacion, "MMM yyyy")),
                        // DescuentoFmt: Util.fn_CurrencyFmt(m.Descuento),
                        // ImpuestosRetenidos: (m.RetencionIEPS + m.RetencionISR + m.RetencionIVA),
                        // ImpuestosTrasladados: (m.TrasladoIEPS + m.TrasladoIVA),
                        // ImpuestosRetenidosFmt: ((str) => {
                        //     if (m.RetencionIVA) str += `${this.VB_GetUIStringModule("tag_iva")}: ${Util.fn_CurrencyFmt(m.RetencionIVA)}`
                        //     if (m.RetencionIEPS) str += `\n${this.VB_GetUIStringModule("tag_ieps")}: ${Util.fn_CurrencyFmt(m.RetencionIEPS)}`
                        //     if (m.RetencionISR) str += `\n${this.VB_GetUIStringModule("tag_isr")}: ${Util.fn_CurrencyFmt(m.RetencionISR)}`
                        //     if (!str) str += Util.fn_CurrencyFmt(0);
                        //     else if (str.includes("\n")) str += `\n${this.VB_GetUIStringModule("tag_total")}: ${Util.fn_CurrencyFmt(m.RetencionIEPS + m.RetencionISR + m.RetencionIVA)}`
                        //     return str;
                        // })(""),
                        // ImpuestosTrasladadosFmt: ((str) => {
                        //     if (m.TrasladoIVA) str += `${this.VB_GetUIStringModule("tag_iva")}: ${Util.fn_CurrencyFmt(m.TrasladoIVA)}`
                        //     if (m.TrasladoIEPS) str += `\n${this.VB_GetUIStringModule("tag_ieps")}: ${Util.fn_CurrencyFmt(m.TrasladoIEPS)}`
                        //     if (!str) str += Util.fn_CurrencyFmt(0);
                        //     else if (str.includes("\n")) str += `\n${this.VB_GetUIStringModule("tag_total")}: ${Util.fn_CurrencyFmt(m.TrasladoIEPS + m.TrasladoIVA)}`;
                        //     return str;
                        // })(""),
                        DeudaAux: m.Saldo, //(m.Valor + m.ValorInteres),
                        DeudaAuxFmt: UIUtilFormat._CurrencyFmt(m.Saldo), //.Valor + m.ValorInteres),
                        ProveedorNombre: DataModuloMain._GetDataValueFieldByName("Proveedor", m.IdProveedor, "RazonSocial"),
                        Descripcion: (() => {
                            let str = "";
                            // if ([CCategoria.PagoParcial, CCategoria.PagoCompleto].includes(d.TipoRegistro)) {
                            str = this.VB_GetUIStringModule("tag_pago");
                            const cuenta = DataModuloMain._GetItemDataByName("CuentaBancaria", m.IdCuentaPago);
                            if (cuenta) {
                                str += ` (${cuenta.getTipo})`
                                str += "\n" + this.VB_GetUIStringModule("tag_cuenta") + ": ";
                                str += cuenta.Beneficiario;
                                if (cuenta.Numero) str += ("\n" + cuenta.Numero)
                                if (cuenta.NombreBanco) str += ` (${cuenta.NombreBanco})`;
                            }
                            else {
                                str += "\n" + this.VB_GetUIStringModule("tag_cuenta") + ": ";
                                str += UIUtilLang._GetUIString("general", "nodisponible");
                            }
                            // }
                            return str;
                        })(),
                        Detalles: (m.Detalles || []).map<IDetalle>((d, i) => UIUtilGeneral._ObjectAddGetters(<IDetalle>{
                            ...d,
                            ...{
                                _IdEscuela: m.IdEscuela,
                                SaldoFmt: UIUtilFormat._CurrencyFmt(d.Saldo),
                                // ValorFmt: Util.fn_CurrencyFmt(d.Valor),
                                FechaAplicacionFmt: UIUtilTime._DateFormatStandarFixTimeZoneByIdSchool(d.FechaAplicacion, m.IdEscuela),
                                DeudaAux: d.Valor,
                                DeudaAuxFmt: "-" + UIUtilFormat._CurrencyFmt(d.Valor),
                                // Descripcion: (() => {
                                //     let str = "";
                                //     if ([CCategoria.PagoParcial, CCategoria.PagoCompleto].includes(d.TipoRegistro)) {
                                //         str = this.VB_GetUIStringModule("tag_pago");
                                //         const cuenta = DataModuloMain.fn_GetItemDataByName("CuentaBancaria", d.IdCuentaPago);
                                //         if (cuenta) {
                                //             str += ` (${cuenta.getTipo})`
                                //             str += "\n" + this.VB_GetUIStringModule("tag_cuenta") + ": ";
                                //             str += cuenta.Beneficiario;
                                //             if (cuenta.Numero) str += ("\n" + cuenta.Numero)
                                //             if (cuenta.NombreBanco) str += ` (${cuenta.NombreBanco})`;
                                //         }
                                //         else {
                                //             str += "\n" + this.VB_GetUIStringModule("tag_cuenta") + ": ";
                                //             str += UIUtilLang.fn_GetUIString("general", "nodisponible");
                                //         }
                                //     }
                                //     return str;
                                // })()
                            }
                        }, {
                            CategoriaFmt: () => UIUtilViewFinanzaEgresoCategoria._CategoriaNombre(d.IdCategoria),
                            getCuenta: (d) => (d.IdCuentaPago ? DataModuloMain._GetItemDataByName("CuentaBancaria", d.IdCuentaPago) : null),
                        }))
                    }
                };
                return item;
            });
    }

    private GetAniosDisponibles(): IItemCombo[] {
        let initY = 2010;
        let currentY = new Date().getFullYear();
        let result: IItemCombo[] = [];
        for (let y = currentY; y >= initY; y--) {
            result.push({
                Id: y,
                Name: y.toString(),
            })
        }
        return result;
    }

    private IncluirPeriodosAnteriores() {
        return this.windowContent.select<HTMLInputElement>("#incluir_anteriores").node().checked;
    }
}
