import { UIUtilGeneral } from "../util/Util";

type TPosition = "bottom" | "top" | "left" | "right" | "cursor";
type HTMLElementObserved = HTMLElement & {
    __WCTooltipText?: string;
    __WCTooltipHTMLBasic?: string;
    __WCTooltipFocusTarget?: HTMLElement;
}
type TElementObservedConfig = {
    /** El tooltip es `visible` cuando el puntero esta sobre `Target` */
    Target: HTMLElement;
    Text?: string;
    HTMLBasic?: string;
    /** Cuando `position!="cursor"`; el tooltip toma de referencia este elemento (`FocusTarget`) para ubicarse */
    FocusTarget?: HTMLElement;
}

const HIDEDELAY = 250;
const SHOWDELAY = 250;
const ANIMATIONDURATION = 150;
const TEMPLATE = document.createElement("template");
TEMPLATE.innerHTML = `
<style>
    :host {
        display: contents;
    }

    :host>div {
        position: fixed;
        padding: 5px;
        background-color: rgba(0, 0, 0, .75);
        white-space: break-spaces;
        text-overflow: ellipsis;
        overflow: hidden;
        color: rgb(255, 255, 255);
        border-radius: 3px;
        border: solid 1px #d6d6d6;
        display: none;
        font-weight: normal;
        font-size: var(--fontsize_me2);
        opacity: 0;
        text-align: start;
        cursor: default;
        z-index: 1000;
        transform: scale(0);
        transition: transform ${ANIMATIONDURATION}ms ease, min-width ${ANIMATIONDURATION}ms ease, max-width ${ANIMATIONDURATION}ms ease, opacity ${ANIMATIONDURATION}ms ease;
    }
</style>

<div><slot></slot></div>
`;

/** `wc-tooltip`
 * Atributos
 * * `visible`
 * * `min-width`: `(number + "px")`
 * * `max-width`: `(number + "px")`
 * * `position`: "cursor" | "bottom" | "top" | "left" | "right"
 * * `observed-selection`: css selector
 */
export class HTMLTooltipComponent extends HTMLElement {
    #contentContainer: HTMLDivElement;
    #currentParent: HTMLElement;
    #currentFocusElement: HTMLElementObserved;
    #observedElements: HTMLElementObserved[];

    #showTimeout: NodeJS.Timeout;
    #hideTimeout: NodeJS.Timeout;

    #position: TPosition;
    #visible: boolean;

    private parentElementMouseEnterEvent: (e: MouseEvent) => void;
    private parentElementMouseLeaveEvent: (e: MouseEvent) => void;
    private parentElementMouseMoveEvent: (e: MouseEvent) => void;

    constructor() {
        super();
        this.attachShadow({ mode: 'open' });

        this.shadowRoot.appendChild(TEMPLATE.content.cloneNode(true));

        this.#observedElements = [];
        this.parentElementMouseEnterEvent = e => this.MouseEnterEvent(e);
        this.parentElementMouseLeaveEvent = e => this.MouseLeaveEvent(e);
        this.parentElementMouseMoveEvent = e => this.MouseMoveEvent(e);

        this.#contentContainer = this.shadowRoot.querySelector("div");
        this.#position = "bottom";
        this.#visible = false;

        const slotElement = this.#contentContainer.querySelector<HTMLSlotElement>(":scope > slot")
        slotElement.onslotchange = e => {
            // console.warn("Tooltip >>", this.childNodes.length, this.children.length)
            if (this.#visible && this.innerHTML.trim() === "") {
                this.RemoveTooltip();
            }
        }
    }

    // *****************************************************************************
    // PRIVATE METHODS
    // *****************************************************************************

    private RemoveTooltip(delay = true) {
        if (this.#showTimeout != null) {
            clearTimeout(this.#showTimeout);
            this.#showTimeout = null;
        }

        const removeContent = (this.#currentFocusElement !== this.#currentParent);
        this.#visible = false;
        this.#currentFocusElement = null;
        if (this.#hideTimeout == null) {
            this.#hideTimeout = setTimeout(() => {
                this.#contentContainer.ontransitionend = e => {
                    this.#contentContainer.style.display = null;//"none";
                }

                this.#contentContainer.style.opacity = null;//"0";
                this.#contentContainer.style.transform = null; //"scale(0)";
                if (removeContent) this.textContent = "";

                this.#hideTimeout = null;
            }, (delay ? HIDEDELAY : 0));
        }
    }

    private ShowTooltip(element: HTMLElementObserved, delay = true) {
        const content = element.__WCTooltipText || element.__WCTooltipHTMLBasic || this.innerHTML.trim();
        if (!content) {
            // console.warn("-d", "Tooltip >> Show fallo por falta de contenido");
            return;
        }
        if (this.#hideTimeout != null) {
            clearTimeout(this.#hideTimeout);
            this.#hideTimeout = null;
        }
        this.#visible = true;
        if (this.#currentFocusElement != element)
            this.#currentFocusElement = element;
        if (this.#showTimeout == null) {
            this.#showTimeout = setTimeout(() => {
                if (element.__WCTooltipText && this.textContent != content) {
                    this.textContent = content;
                }
                else if (element.__WCTooltipHTMLBasic && this.innerHTML != content) {
                    this.innerHTML = content;
                }
                if (this.#position != "cursor") {
                    let positions = UIUtilGeneral._GetRelativePositions(element.__WCTooltipFocusTarget || element, this.#position, 4);

                    this.#contentContainer.style.top = (positions.Top ? (positions.Top + "px") : null);
                    this.#contentContainer.style.bottom = (positions.Bottom ? (positions.Bottom + "px") : null);
                    this.#contentContainer.style.left = (positions.Left ? (positions.Left + "px") : null);
                    this.#contentContainer.style.right = (positions.Right ? (positions.Right + "px") : null);
                }

                this.#contentContainer.ontransitionend = null;
                this.#contentContainer.style.display = "block";
                setTimeout(() => {
                    this.#contentContainer.style.opacity = "1";
                    this.#contentContainer.style.transform = "scale(1)";
                });

                this.#showTimeout = null;
            }, (delay ? SHOWDELAY : 0));
        }
    }

    private RefreshTooltip() {
        if (this.#currentFocusElement && this.#visible) {
            this.ShowTooltip(this.#currentFocusElement, false);
        }
    }

    // *****************************************************************************
    // EVENTS
    // *****************************************************************************

    private MouseEnterEvent(e: MouseEvent) {
        let delay = (this.#hideTimeout == null);
        this.ShowTooltip(e.currentTarget as HTMLElement, delay);
    }

    private MouseLeaveEvent(e: MouseEvent) {
        this.RemoveTooltip();
    }

    private MouseMoveEvent(e: MouseEvent) {
        const element = e.currentTarget as HTMLElement;
        if (this.#position == "cursor" && this.#hideTimeout == null && e.target != this.#contentContainer && this._Visible) {
            let parentBounds = element.getBoundingClientRect();
            let cursorTop = e.clientY;
            let cursorLeft = e.clientX;

            if ( // Mouse dentro del elemento enfocado // FIXME Remover condición?
                (cursorTop >= parentBounds.top)
                && (cursorTop <= (parentBounds.top + parentBounds.height))
                && (cursorLeft >= parentBounds.left)
                && (cursorLeft <= (parentBounds.left + parentBounds.width))
            ) {
                let tooltipContentBounds = this.#contentContainer.getBoundingClientRect();
                // Fix Left
                // if (cursorLeft > (window.innerWidth / 2)) {
                //     cursorLeft -= tooltipContentBounds.width + 15;
                // }
                // Fix Top
                let auxTContentBottom = cursorTop + tooltipContentBounds.height;
                if (auxTContentBottom > window.innerHeight) {
                    cursorTop -= (auxTContentBottom - window.innerHeight + 5);
                    if (cursorTop < 0) {
                        cursorTop = 0;
                    }
                }
                this.#contentContainer.style.top = (cursorTop + 5) + "px";
                this.#contentContainer.style.left = (cursorLeft + 10) + "px";
            }
        }
    }

    private HasObservedElement(element: HTMLElement) {
        return this.#observedElements
            .find(d => (d == element));
    }

    private AddObservedElement(element: HTMLElementObserved) {
        if (!this.HasObservedElement(element))
            this.#observedElements.push(element);
        // else console.warn("Tooltip >> Element has already been observed");
    }

    private RemoveObservedElement(element: HTMLElementObserved) {
        let index = this.#observedElements.indexOf(element);
        if (index > -1) {
            // if (element.__WCTooltipText?.endsWith("_a")) {
            //     console.warn(">>");
            // }
            let [elementRemoved] = this.#observedElements.splice(index, 1);
            delete elementRemoved.__WCTooltipText;
            delete elementRemoved.__WCTooltipHTMLBasic;
            this.RemoveElementEvents(element);
        }
    }

    private PurgeDisconnectedElements() {
        this.#observedElements.forEach(element => {
            if (!element.isConnected) {
                // console.debug("Tooltip >> Purgando", element.__WCTooltipText, element.__WCTooltipHTMLBasic, element)
                this.RemoveObservedElement(element);
            }
        })
    }

    private AddElementEvents(element: HTMLElement) {
        element.addEventListener("mouseenter", this.parentElementMouseEnterEvent);
        element.addEventListener("mouseleave", this.parentElementMouseLeaveEvent);
        element.addEventListener("mousemove", this.parentElementMouseMoveEvent);
        // element.oncontextmenu = e => false; // DESCOMENTAR
    }

    private RemoveElementEvents(element: HTMLElement) {
        element.removeEventListener("mouseenter", this.parentElementMouseEnterEvent);
        element.removeEventListener("mouseleave", this.parentElementMouseLeaveEvent);
        element.removeEventListener("mousemove", this.parentElementMouseMoveEvent);
        // element.oncontextmenu = null; // DESCOMENTAR
    }

    private AddAllRequiredElemenEvents() {
        if (this.#observedElements.length) {
            this.#observedElements.forEach(element => this.AddElementEvents(element));
            this.#contentContainer.onmouseenter = e => {
                if (this.#hideTimeout) {
                    clearTimeout(this.#hideTimeout);
                    this.#hideTimeout = null;
                }
            }
            this.#contentContainer.onmouseleave = e => {
                this.RemoveTooltip();
            }
        }
        else {
            this.AddElementEvents(this.#currentParent);
        }
    }

    private RemoveAllElementEvents() {
        if (this.#currentParent) {
            this.RemoveElementEvents(this.#currentParent);
        }
        this.#observedElements.forEach(element => this.RemoveElementEvents(element));
        this.#contentContainer.onmouseenter = null;
        this.#contentContainer.onmouseleave = null;
    }

    private ResetAllElementEvents() {
        this.RemoveAllElementEvents();
        this.AddAllRequiredElemenEvents();
    }

    // *****************************************************************************
    // ELEMENT LIFE CICLE CALLBACKS
    // *****************************************************************************

    /** Invocado cuando el componente personalizado se conecta por primera vez al DOM del documento. */
    connectedCallback() {
        this.#currentParent = this.parentElement;
        this.AddAllRequiredElemenEvents();
        // console.warn("Tooltip connected!", this.parentElement, this.isConnected);
        this.#contentContainer.onclick = e => {
            this.RemoveTooltip(false);
            e.stopPropagation();
        };
    }

    /** Invocado cuando el componente personalizado se deconecta del DOM del documento. */
    disconnectedCallback() {
        this.RemoveAllElementEvents();
        this.#contentContainer.onclick = null;
        this.#currentParent = null;
        // console.warn("Tooltip disconnected!", this.parentElement, this.isConnected);
    }

    /** Invocado cuando el componente personalizado se mueve a un nuevo documento. */
    adoptedCallback(oldDocument: Document, newDocument: Document) {
        // console.warn("Tooltip adopted!", oldDocument, newDocument);
    }

    /** Invocado cuando uno de los atributos del componente personalizado es añadido, removido o modificado. */
    attributeChangedCallback(attrName: string, oldValue: string, newValue: string) {
        switch (attrName) {
            case "position":
                if (
                    (newValue as TPosition) == "cursor"
                    || (newValue as TPosition) == "bottom"
                    || (newValue as TPosition) == "top"
                    || (newValue as TPosition) == "left"
                    || (newValue as TPosition) == "right"
                ) {
                    this.#position = (newValue as TPosition);
                }
                else {
                    this.#position = "bottom";
                }
                break;
            case "visible":
                this._Visible = newValue == "true";
                break;
            case "min-width":
                this.#contentContainer
                    .style.minWidth = newValue;
                break;
            case "max-width":
                this.#contentContainer
                    .style.maxWidth = newValue;
                break;
            case "observed-selection":
                let oldObservedSelection = oldValue ? this.#currentParent.querySelector<HTMLElement>(oldValue) : null;
                let newObservedSelection = this.#currentParent.querySelector<HTMLElement>(newValue);
                if (oldObservedSelection) {
                    this.RemoveObservedElement(oldObservedSelection);
                }
                if (newObservedSelection) {
                    this.AddObservedElement(newObservedSelection);
                }
                this.ResetAllElementEvents();
                break;
        }
    }

    static get observedAttributes() {
        return ['visible', 'min-width', 'max-width', "position", "observed-selection"];
    }

    public set _Visible(val: boolean) {
        if (val) {
            if (this.#observedElements.length == 1) {
                this.ShowTooltip(this.#observedElements[0], false);
            }
            else if (this.#currentParent) {
                this.ShowTooltip(this.#currentParent, false);
            }
        }
        else {
            this.RemoveTooltip(false);
        }
    }

    public get _Visible() {
        return this.#visible;
    }

    public set _Position(value: TPosition) {
        if (value != null) {
            this.setAttribute("position", value);
        } else {
            this.removeAttribute("position");
        }
    }

    public get _Position(): TPosition {
        return this.#position;
    }

    // public met_SetObservedElements(...elements: HTMLElement[]) {
    //     this.PurgeDisconnectedElements();
    //     elements.forEach(element => this.AddObservedElement(element));
    //     this.ResetAllElementEvents();
    //     return this;
    // }

    public _SetObservedElementsAdvanced(...elements: TElementObservedConfig[]) {
        this.PurgeDisconnectedElements();
        elements.forEach(item => {
            const element = item.Target as HTMLElementObserved;
            const contentChanged = (element.__WCTooltipText != item.Text) || (element.__WCTooltipHTMLBasic != item.HTMLBasic);
            element.__WCTooltipText = item.Text;
            element.__WCTooltipHTMLBasic = item.HTMLBasic;
            element.__WCTooltipFocusTarget = item.FocusTarget
            this.AddObservedElement(element);
            if (contentChanged && this.#visible) {
                this.ShowTooltip(element);
            }
        });
        this.ResetAllElementEvents();
        return this;
    }

    public _RemoveObservedELements(...elements: HTMLElement[]) {
        elements.forEach(element => this.RemoveObservedElement(element));
        return this;
    }

    /** Refresca el contenido y la posición del control */
    public _Refresh() {
        this.RefreshTooltip();
        return this;
    }
}

customElements.define("wc-tooltip", HTMLTooltipComponent);
