import { Entidad } from "../../data/Entidad";
import { UIUtilLang } from "../util/Language";
import { UIUtilPermission } from "../util/Permission";
import { CardV2CollapseAdvanced } from "./CardV2CollapseAdvanced";
import { ExcelThings } from "./ExcelExport";
import { NotificacionV2 } from "./NotificacionV2";
import { Table } from "./Tabla";

export type IConfigCardV2CollapseExcelExport<T> = Pick<
    ExcelThings.IConfigExcelExport<T>,
    "ColumnsConfig" | "FileName" | "IdsEscuelas" | "OnGetDataBySheets" | "OnGetEscuelasTagInSheet" | "OnStepFieldInDataRow" | "TagEncabezadoHoja" | "ConsultarLogos"
>
    & {
        TypeRequest: Entidad.CTipoRequest
    }

export abstract class CardV2CollapseAdvancedTable<TDataTable = Object, TDataToDoUpdate extends Array<any> = [unknown]> extends CardV2CollapseAdvanced<TDataToDoUpdate> {
    protected ctrlTabla: Table.Tabla<TDataTable>;

    constructor(titulo: string, modulo: Entidad.CModulo = null, moduloName?: string) {
        super(titulo, modulo, moduloName);

        this.InitCardCollapseTable();
    }

    // ****************************************************************************
    // PRIVATE METHODS
    // ****************************************************************************

    // ****************************************************************************
    // PROTECTED METHODS
    // ****************************************************************************

    // protected CARDCOLL_UpdateExpandedCard(expandir: boolean) {
    // super.CARDCOLL_UpdateExpandedCard(expandir);
    // if (this.ctrlTabla) {
    //     this.ctrlTabla.met_RefreshView();
    // }
    // }

    public _getTopOptions() {
        let opciones: Table.ITableMenuTopDefaultOptionConfig[];
        opciones = (this.CARDCOLLADTAB_Table_GetMenuTop() || [])
            .map(d => {
                let dd = Object.assign({}, d);

                if (Entidad.CAccionPermiso[d.Label] != null) {
                    dd.Label = UIUtilLang._GetUIString("c_actions", d.Label as any);
                } else {
                    dd.Label = this.CARDCOLL_GetUIStringModule(dd.Label);
                }

                return dd;
            })
        if (this.modulo == null || (this.HasActionPermission(Entidad.CAccionPermiso.ExportarAExcel) && this.ctrlTabla._dataFiltered.length > 0)) {
            opciones.push({
                Label: UIUtilLang._GetUIString("c_actions", "exportaraexcel"),
                Callback: async () => {
                    if (this.ctrlTabla._dataFiltered.length < 1) {
                        NotificacionV2._Mostrar("No hay registros para exportar", "ADVERTENCIA");
                    }
                    else {
                        const exportConfig = this.CARDCOLLADTAB_GetExportarConfig(this.ctrlTabla._dataFiltered);
                        if (exportConfig != null) {
                            if (exportConfig instanceof Promise) {
                                this.GridOpen_ExportarData(await exportConfig);
                            } else {
                                this.GridOpen_ExportarData(exportConfig);
                            }
                        } else {
                            console.error("-d", "Sin configuración para exportar");
                        }
                    }
                },
            })
        }
        return opciones;
    }

    private InitCardCollapseTable() {
        this.CARDCOLLADTAB_CreateTable(this.cardContentContainerSelection);
    }

    protected async CARDCOLL_UpdateCardData(showProgress = false, ...cardData: TDataToDoUpdate) {
        await super.CARDCOLL_UpdateCardData(showProgress, ...cardData);

        setTimeout(() => {
            this.ctrlTabla._MenuTopDefault
                ._UpdateConfig({
                    MaxOptionsInRow: 3,
                    Options: this._getTopOptions()
                });
        });
    }

    protected abstract CARDCOLLADTAB_Table_GetMenuTop(): Array<Table.ITableMenuTopDefaultOptionConfig>;

    protected abstract CARDCOLLADTAB_GetExportarConfig(dataTable: TDataTable[]): IConfigCardV2CollapseExcelExport<TDataTable | any> | Promise<IConfigCardV2CollapseExcelExport<TDataTable | any>>;

    protected abstract CARDCOLLADTAB_Table_GetConfig(): Omit<Table.IConfig<TDataTable>, keyof Pick<Table.IConfig<TDataTable>, "Parent">>;

    protected CARDCOLLADTAB_CreateTable(container: TSelectionHTML<"div">): Table.Tabla<TDataTable> {
        if (this.ctrlTabla == null) {
            const tableConfig = this.CARDCOLLADTAB_Table_GetConfig();

            tableConfig.RenderColumnHeadings
                .forEach(colConf => {
                    if (colConf.LabelLangKey === undefined) {
                        colConf.LabelLangKey = UIUtilLang._FixFieldKey(colConf.Field);
                    }
                })

            this.ctrlTabla = new Table.Tabla<TDataTable>({
                ...{
                    Parent: container,
                    LangModuleKeyInContext: this.moduloName,
                    OnFilter: () => {
                        this.ctrlTabla._MenuTopDefault._UpdateConfig({
                            MaxOptionsInRow: 3,
                            Options: this._getTopOptions()
                        })
                    }
                },
                ...tableConfig
            });
        }

        return this.ctrlTabla;
    }

    protected CARDCOLLADTAB_GetTableOptionsToSelectedData(idEscuela: number, typeMenu: "row" | "top-selected", opciones: Table.ITableMenuDataSelectedOptionConfig<TDataTable>[] = []): Table.ITableMenuDataSelectedConfig<TDataTable> {
        // this.CARDCOLL_UpdateCardData
        const finalOptions = opciones
            .map(d => {
                let dd = Object.assign({}, d);

                if (Entidad.CAccionPermiso[d.Label] != null) {
                    dd.Label = UIUtilLang._GetUIString("c_actions", d.Label as any);
                } else {
                    dd.Label = this.CARDCOLL_GetUIStringModule(dd.Label);
                }

                return dd;
            })
        if (idEscuela && typeMenu == "top-selected" && (this.modulo == null || UIUtilPermission._HasAccionPermission(Entidad.CAccionPermiso.ExportarAExcel, this.modulo, idEscuela))) {
            finalOptions.push({
                Label: UIUtilLang._GetUIString("c_actions", "exportaraexcel"),
                MultiData: true,
                Callback: async (datos: Array<TDataTable>) => {
                    const exportConfig = this.CARDCOLLADTAB_GetExportarConfig(datos);
                    if (exportConfig != null) {
                        if (exportConfig instanceof Promise) {
                            this.GridOpen_ExportarData(await exportConfig);
                        } else {
                            this.GridOpen_ExportarData(exportConfig);
                        }
                    } else {
                        console.error("-d", "Sin configuración para exportar");
                    }
                },
                GetDetails: (datos: Array<TDataTable>) => Boolean(datos?.length)
            })
        }

        return {
            MaxOptionsInRow: 3,
            Options: finalOptions
        }
    }

    private GridOpen_ExportarData<T = TDataTable>(config: IConfigCardV2CollapseExcelExport<T>) {
        ExcelThings._ExportData<T>({
            ColumnsConfig: config.ColumnsConfig,
            FileName: config.FileName,
            IdCategoria: 0,
            IdsEscuelas: config.IdsEscuelas,
            Modulo: this.modulo,
            OnGetDataBySheets: config.OnGetDataBySheets,
            OnGetEscuelasTagInSheet: config.OnGetEscuelasTagInSheet,
            OnStepFieldInDataRow: config.OnStepFieldInDataRow,
            RequestType: config.TypeRequest,
            TagEncabezadoHoja: config.TagEncabezadoHoja
        })
    }

    protected CARDCOLLADTAB_ExportarData<T = TDataTable>(config: IConfigCardV2CollapseExcelExport<T>) {
        this.GridOpen_ExportarData(config);
    }

    // ****************************************************************************
    // PUBLIC PROPERTIES
    // ****************************************************************************
}
