import { DataDRequest } from "../../data/DRequest";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import DataModuloEscolaridad from "../../data/modulo/Escolaridad";
import DataModuloEscuela from "../../data/modulo/Escuela";
import { IConfigCardCollapseExcelExport } from "../controlD3/CardCollapseAdvancedTable";
import { TCARDV2COLL_OnEditOriginEvent } from "../controlD3/CardV2Collapse";
import { CardV2CollapseAdvancedTable } from "../controlD3/CardV2CollapseAdvancedTable";
import { Table } from "../controlD3/Tabla";
import { UIUtilViewGrupos } from "../utilView/Grupos";
import { UIWindowManager } from "../ventana/WindowManager";
import IGrupo = Entidad.IGrupo;
import IEscolaridad = Entidad.IEscolaridad;
import { TCARDCOLL_OnEditOriginEvent } from "../controlD3/CardCollapse";

export class UIPanelCardEscuelasNivelGrupos extends CardV2CollapseAdvancedTable<IGrupo, [IEscolaridad]> {
    constructor() {
        // FIXME TITTLE
        super("card_headertitle", Entidad.CModulo.Grupos, "grupos");
    }

    protected CARDCOLLADTAB_Table_GetConfig(): Omit<Table.IConfig<IGrupo>, keyof Pick<Table.IConfig<IGrupo>, "Parent">> {
        return {
            IdTabla: "EscuelasPanelNivelGrupos",
            IdData: "IdGrupo",
            OrderDefault: {
                Field: "Nombre",
                Type: Table.CStatusOrder.Asc,
            },
            MinWidth: 400,
            RenderColumnHeadings: [
                {
                    Field: "Nombre", Label: "Nombre", Width: "20%", MinWidth: "75px", ClassStyle: "link_item", OnClickInCellCont: (datoGrupo: IGrupo, event) => {
                        UIWindowManager._DoHash("escuelas/grupos/panel", {
                            mode: "All",
                            id: [datoGrupo.IdGrupo]
                        })
                    }
                },
                { Field: "StrTipoGrupo", Label: "Tipo", Width: "20%", MinWidth: "75px" },
                { Field: "NombreGrado", Label: "Grado", Width: "20%", MinWidth: "75px" },
            ],
            OnValueSelectRow: (id, datum) => console.debug(id, datum),
            OptionsOfDataCheckV3: () => this.CARDCOLLADTAB_GetTableOptionsToSelectedData(this.cardData[0].IdEscuela, "top-selected"),
            FilterParameters: [
                { Label: "Nombre", Field: "Nombre" },
                {
                    LabelLangKey: "d_field_strtipogrupo",
                    Field: "EsPrincipal",
                    Type: "select",
                    Options: [
                        { Id: true, Name: this.CARDCOLL_GetUIStringModule("tag_primario") },
                        { Id: false, Name: this.CARDCOLL_GetUIStringModule("tag_secundario") },
                    ],
                },
                { Label: "Grado", Field: "NombreGrado" },
            ],
            EvaluatorAndSubLevelsBuild: {
                OnStepCellTable: (container, datum, field: keyof IGrupo) => {
                    switch (field) {
                        case "Nombre":
                            container.text(UIUtilViewGrupos._GetLblGrupoName(datum));
                            break;
                    }
                }
            }
        }
    }

    protected CARDCOLLADTAB_Table_GetMenuTop(): Table.ITableMenuTopDefaultOptionConfig[] {
        return null;
    }

    protected CARDCOLLADTAB_GetExportarConfig(dataTable: IGrupo[]): IConfigCardCollapseExcelExport<IGrupo> {
        const escolaridad = this.cardData[0];
        return {
            FileName: escolaridad.NombreEscuela + " - " + escolaridad.Nombre + " - Grupos",
            IdsEscuelas: [escolaridad.IdEscuela],
            TypeRequest: Entidad.CTipoRequest.Escuela,
            ColumnsConfig: this.ctrlTabla._InfoColumns
                .map(d => ({
                    Field: d.Field as keyof IGrupo,
                    HeaderTag: d.Label,
                    WidthCell: 25
                })),
            OnGetDataBySheets: async () => {
                return [{
                    IdSheet: escolaridad.IdEscuela, // IdEscuela
                    SheetName: escolaridad.Nombre,
                    Data: dataTable,
                }]
            },
            OnGetEscuelasTagInSheet: (datos) => DataModuloEscuela._DiccEscuela.get(escolaridad.IdEscuela).Nombre,
        }
    }

    protected CARDCOLL_OnInitBuild(contentContainer: TSelectionHTML<"div", any, any>): void {
        this.btnEditarCard._d3Selection.remove();
        this.cardFooterSelection.remove();
        this.cardHeaderSelection.remove();
    }

    protected CARDCOLL_GetVariantToValidateUpdate(cardData_0: IEscolaridad): string {
        return null;
    }

    protected CARDCOLL_OnUpdateData(cardData_0: IEscolaridad): void {
        const grupos = DataModuloEscolaridad._LOCALDATA_GetGruposEnEscolaridad(cardData_0.Id);
        this.ctrlTabla._UpdateData(Array.from(grupos.values()));
    }

    protected CARDCOLL_MostrarBody(): void {
        this.cardSelection.style("height", "100%");
    }

    protected CARDCOLL_OcultarBody(): void {
        this.cardSelection.style("height", null);
    }

    protected CARDCOLL_OnEditarCard(originEvent: TCARDCOLL_OnEditOriginEvent): void {
        // throw new Error("Method not implemented.");
    }

    protected CARDCOLL_OnCancelaEditarCard(originEvent: TCARDV2COLL_OnEditOriginEvent): void { }

    protected CARDCOLL_GuardarCardV2(): Promise<DataDRequest.IRequestResponseA<any>> {
        throw new Error("Method not implemented.");
    }

    protected CARDCOLL_SyncOrGetIdToDownloadData(): DataModuloMain.TipoRequestMonitorId | (() => Promise<any>) | DataModuloMain.TipoRequestMonitorId[] {
        return Entidad.CTipoRequest.Grupo;
    }

    protected CARDCOLL_GetIdSchool(cardData_0_0: IEscolaridad): number {
        return cardData_0_0?.IdEscuela;
    }
}
