import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import { UIUtilStrings } from "../util/Strings";
import { UIUtilTime } from "../util/Time";
import { UIUtilViewData } from "../util/ViewData";

export namespace UIUtilViewFinanzasIngresoUtil {
    export type IItemComboYear = UIUtilViewData.IBaseData;
    export type IItemComboMonth = { Id: string, Year: number, Month: number, Name: string };

    /** La disponibilidad de años se basa en los Ciclos escolares disponibles */
    export function _ObtenerAniosDisponibles(escuelas: Entidad.IEscuela[]): IItemComboYear[] {
        let years: IItemComboYear[] = [];
        let ciclosEscolares = DataModuloMain._GetReqDataArrayByName("CicloEscolar")
            .filter(cicloE => Boolean(escuelas.find(esc => (esc.IdKinder == cicloE.IdEscuela))));

        if (ciclosEscolares.length > 0) {
            let minCiclo = ciclosEscolares.reduce((a, b) => (new Date(a.FechaInicio) < new Date(b.FechaInicio) ? a : b));
            let maxCiclo = ciclosEscolares.reduce((a, b) => (new Date(a.FechaFin) > new Date(b.FechaFin) ? a : b));
            let minYear = new Date(minCiclo.FechaInicio).getFullYear();
            let maxYear = new Date(maxCiclo.FechaFin).getFullYear();
            let currentYear = new Date().getFullYear();

            maxYear = (maxYear > currentYear ? currentYear : maxYear);

            for (let y = minYear; y <= maxYear; y++) {
                years.push({ Id: y, Name: y.toString() });
            }
        }
        return years.sort((a, b) => (b.Id - a.Id));
    }

    export function _ObtenerMesesDisponibles(years: number[]): IItemComboMonth[] {
        return years.sort((a, b) => (b - a)).reduce((res, year) => {
            UIUtilViewData._GetMonthsByYearCurrentMonthLimit(year).sort((a, b) => (b.Id - a.Id))
                .forEach(({ Id: month }) => {
                    const dt = new Date(year, month, 2)
                    res.push({
                        Id: (year + "-" + month),
                        Year: year,
                        Month: month,
                        Name: UIUtilStrings._CapitaliceString(UIUtilTime._DateFormatStandar(dt, "MMM yyyy"))
                    })
                })
            return res
        }, <IItemComboMonth[]>[])
    }
}