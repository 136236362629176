import * as d3 from "d3";
import { HTMLFileIcoElement } from "../controlWC/FileIcoComponent";
import { UIUtilLang } from "../util/Language";
import { NotificacionV2 } from "./NotificacionV2";

export class FileLoader {
    private shadowRoot: HTMLDivElement;
    constructor(container: TSelectionHTML<"htmlelement">) {
        this.Init(container);
    }

    private Init(container: TSelectionHTML<"htmlelement">) {
        this.shadowRoot = ((container ? container.append("div") : d3.create("div")) as TSelectionHTML<"div">)
            .attr("class", "file_loader")
            .node();

        this.shadowRoot.innerHTML =
            `<div class="files_preview"></div>
                <div class="drag_text">
                ${UIUtilLang._GetUIString("control", "cargararchivo")}<br>
                <span>${UIUtilLang._GetUIString("control", "click_draganddrop")}</span>
                </div>
                <input class="file_upload_input" type='file' accept="*/*" />`

        const input = this.shadowRoot.querySelector("input")
        input.addEventListener("change", e => {
            this.ReadFile();
        });
        input.addEventListener("dragenter", e => {
            this.RefreshDragStyle(true);
        });
        input.addEventListener("dragleave", e => {
            this.RefreshDragStyle(false);
        });
        input.addEventListener("mouseleave", e => {
            this.RefreshDragStyle(false);
        });
    }

    private RefreshDragStyle(dragging: boolean) {
        d3.select(this.shadowRoot).classed("file_hover", dragging);
        const lbl2 = this.shadowRoot.querySelector(":scope > .drag_text > span");
        lbl2.textContent = dragging ? UIUtilLang._GetUIString("control", "drop") : UIUtilLang._GetUIString("control", "click_draganddrop");
    }

    private ReadFile() {
        const input = this.shadowRoot.querySelector("input");
        const [valid, extensionError] = this.ValidaArchivo();
        this.RefreshDragStyle(false);
        if (!valid) {
            if (extensionError) NotificacionV2._Mostrar(`Archivo ${"<b>." + extensionError + "</b>"} no permitido`, "ADVERTENCIA");
            input.value = null;
        }
        if (input.files && input.files?.length) {
            let [file] = input.files;
            let reader = new FileReader();

            reader.onload = (e) => {
                // this.sha('.image-upload-wrap').hide();
                if (!this.shadowRoot.querySelector(".drag_text").classList.contains("hide")) {
                    this.shadowRoot.querySelector(".drag_text").classList.add("hide");
                }

                let fileIco: HTMLFileIcoElement = this.shadowRoot.querySelector("wc-fileico");
                if (!fileIco) {
                    fileIco = document.createElement("wc-fileico") as HTMLFileIcoElement;
                    this.shadowRoot.querySelector(".files_preview").append(fileIco);
                    const { clientHeight } = this.shadowRoot;
                    if (clientHeight > 80) {
                        fileIco.style.height = "80px";
                        fileIco.style.width = "60px";
                    } else {
                        fileIco.style.height = clientHeight + "px";
                        fileIco.style.width = (clientHeight * .78) + "px";
                    }
                }
                fileIco._Filename = file.name;
                fileIco._Ext = this.GetExtension(file.name)?.toUpperCase();
            };
            reader.onerror = e => {
                this.RemoveUpload();
            }
            reader.readAsDataURL(file);
        }
        else {
            this.RemoveUpload();
        }
    }

    private GetExtension(filename: string) {
        const fileNameSplited = filename.split(".");
        return fileNameSplited[fileNameSplited.length - 1];
    }

    /** [valid, invalid_extension] */
    private ValidaArchivo() {
        const input = this.shadowRoot.querySelector("input");
        if (input) {
            if (!input.accept)
                return [true, null];

            const [file] = input.files;
            if (!file)
                return [false, null];

            const ext = this.GetExtension(file.name)?.toLowerCase();
            const valid = input.accept.toLowerCase().includes(ext);
            return [valid, (valid ? null : ext)];
        }
        return [false, null];
    }

    private RemoveUpload() {
        this.shadowRoot.querySelectorAll(".files_preview > *").forEach(element => element.remove());
        this.shadowRoot.querySelector(".drag_text").classList.remove("hide");
    }

    public get _Files() {
        return Array.from(this.shadowRoot.querySelector("input").files || []);
    }

    public get _File() {
        return (this.shadowRoot.querySelector("input").files || [])[0];
    }

    public get _InputElement() {
        return this.shadowRoot.querySelector("input");
    }

    set _Files(files: File[]) {
        let container = new DataTransfer();
        files.forEach(file => container.items.add(file))
        this.shadowRoot.querySelector("input")
            .files = container.files;
        this.ReadFile();
    }

    get _ControlContainerSel() {
        return d3.select(this.shadowRoot);
    }

    get _ControlContainerElemt() {
        return this.shadowRoot;
    }

    get _InputNode() {
        return this.shadowRoot.querySelector<HTMLInputElement>(":scope > input");
    }
}