import * as d3 from "d3";
import { UIUtilElementBehaviors } from "../util/ElementBehaviors";
import { UIUtilGlobalKeyEvents } from "../util/GlobalKeyEvent";
import { UIUtilLang } from "../util/Language";
import { UIWindowManager } from "../ventana/WindowManager";
import { Button } from "./Button";

type TOnCloseCallback = () => void;
interface IModalConfig {
    /** @default 450px */
    Width?: number,
    OnClose?: TOnCloseCallback;
    /** Presiona `Esc` para cerrar el modal
     * @default true */
    EnableEscapeKeydown?: boolean;
}

/** Identifica a cada instancia de modal, incremental por cada instancia creada 
 * 
 * Su límite máximo es 1,000,000
 */
let IdModal = 0;

export class Modal {
    private containerSelection: TSelectionHTML<"div">;
    private modalSelection: TSelectionHTML<"div">;
    private modalHeaderSelection: TSelectionHTML<"div">;
    private modalBodySelection: TSelectionHTML<"div">;
    private modalFooterSelection: TSelectionHTML<"div">;
    private config: IModalConfig;
    private visible: boolean;
    private enableEscapeKeydown: boolean;
    private identificadorModal: number;

    constructor(config?: IModalConfig) {
        IdModal = (IdModal < 1000000) ? ++IdModal : 0;
        this.identificadorModal = IdModal;
        this.visible = false;
        this.enableEscapeKeydown = config?.EnableEscapeKeydown == null ? true : config.EnableEscapeKeydown;
        this.config = config || {};
        this.Init();
    }

    private Init() {
        // >> Crear modal
        this.containerSelection = d3.create("div")
            .attr("class", "modal_container");

        this.modalSelection = this.containerSelection.append("div")
            .attr("class", "modal_content");

        if (this.config.Width) this.modalSelection.style("width", this.config.Width);

        // >> Crear modal header
        this.modalHeaderSelection = this.modalSelection.append("div")
            .classed("modal_header", true)

        UIUtilElementBehaviors._EllipsisTextTooltip(this.modalHeaderSelection.append("h1").node());

        Button.BtnClose._GetCloseButtonLight(
            this.modalHeaderSelection.append("div")
                .attr("class", "area_actions"),
            () => this.Ocultar(true),
            "var(--color_primary1)"); //Color sin uso actualmente

        // >> Crear modal body
        this.modalBodySelection = this.modalSelection.append("div")
            .attr("class", "modal_body");

        // >> Crear modal footer
        this.modalFooterSelection = this.modalSelection.append("div")
            .classed("modal_footer", true);

        this.modalFooterSelection.append("button")
            .text(UIUtilLang._GetUIString("general", "cancelar"))
            .attr("class", "buttons buttons_cancel")
            .on("click", (d, i, nodes) => this.Ocultar(true));

        this.modalFooterSelection.append("button")
            .text(UIUtilLang._GetUIString("general", "aceptar"))
            .attr("class", "buttons buttons_ok");

        // + Comportamientos

        this.HabilitarBtns(true);
        this.containerSelection.node()
            .onclick = e => {
                if (e.target == this.containerSelection.node()) {
                    this.modalSelection.classed("anim_rebote", false);
                    setTimeout(() => {
                        this.modalSelection.classed("anim_rebote", true);
                    }, 100);
                }
            }
    }

    private Mostrar() {
        this.visible = true;
        document.body.append(this.containerSelection.node());
        this.containerSelection.interrupt()
            .style("opacity", null)
            .style("scale", null);

        if (this["__changehashevent"]) {
            UIWindowManager._RemoveOnHashPathChangeListener(this["__changehashevent"]);
        }
        this["__changehashevent"] = UIWindowManager._AddOnHashPathChangeListener((e) => {
            this.Ocultar(true);
        })
    }

    private Ocultar(triggerOncloseEvent: boolean) {
        this.visible = false;
        this.containerSelection
            .style("opacity", 1)
            .style("scale", 1)
            .transition()
            .duration(200)
            .style("opacity", 0)
            .style("scale", 1.05)
            .remove();

        if (this["__changehashevent"]) {
            UIWindowManager._RemoveOnHashPathChangeListener(this["__changehashevent"]);
            this["__changehashevent"] = null;
        }
        if (this.config.OnClose && triggerOncloseEvent) {
            this.config.OnClose();
        }
    }

    private HabilitarBtns(val: boolean) {
        // let pointerEvents = (val ? null : "none");
        let btnClose = this.modalHeaderSelection.select<HTMLElement>(".btn_close")
            .classed("btn_disable", !val)
        // .style("pointer-events", pointerEvents);
        this.modalFooterSelection.selectAll(".buttons")
            .classed("btn_disable", !val)
        // .style("pointer-events", pointerEvents);

        if (val) {
            if (this.enableEscapeKeydown) UIUtilGlobalKeyEvents._SetEscKeyEventCallback(this.containerSelection.node(), () => btnClose.node().click());
            this._EnableEnterKey(true);
        } else {
            UIUtilGlobalKeyEvents._RemoveEscKeyEventCallback(this.containerSelection.node());
            this._EnableEnterKey(false);
        }
    }

    public get _ContainerSelection() {
        return this.containerSelection;
    }
    public get _ModalSelection() {
        return this.modalSelection;
    }
    // public get prop_ModalNode() {
    //     return this.modalSelection.node();
    // }
    public get _HeaderSelection() {
        return this.modalHeaderSelection;
    }
    // public get prop_HeaderNode() {
    //     return this.modalHeaderSelection.node();
    // }
    public get _BodySelection() {
        return this.modalBodySelection;
    }
    // public get prop_BodyNode() {
    //     return this.modalBodySelection.node();
    // }
    public get _FooterSelection() {
        return this.modalFooterSelection;
    }
    // public get prop_FooterNode() {
    //     return this.modalFooterSelection.node();
    // }
    public get _BtnCloseSelection() {
        return this.modalHeaderSelection.select<HTMLElement>(".btn_close");
    }
    /** class="buttons_cancel" */
    public get _BtnLeftSelection() {
        return this.modalFooterSelection.select<HTMLButtonElement>(".buttons_cancel");
    }
    /** class="buttons_ok" */
    public get _BtnRightSelection() {
        return this.modalFooterSelection.select<HTMLButtonElement>(".buttons_ok");
    }

    public get _Visible() {
        return this.visible;
    }

    public set _EscKeydownEnabled(enable: boolean) {
        if (this.enableEscapeKeydown == enable) return;
        this.enableEscapeKeydown = enable;
        const btnClose = this.modalHeaderSelection.select<HTMLElement>(".btn_close");
        const isBtnCloseEnable = !btnClose.classed("btn_disable");
        if (!isBtnCloseEnable && enable) return; // No habilitar la posibilidad de Cerrar Modal
        if (enable) UIUtilGlobalKeyEvents._SetEscKeyEventCallback(this.containerSelection.node(), () => this.Ocultar(true));
        else UIUtilGlobalKeyEvents._RemoveEscKeyEventCallback(this.containerSelection.node());
    }

    public get _EscKeydownEnabled() {
        return this.enableEscapeKeydown;
    }

    public _Mostrar() {
        this.Mostrar();
        return this;
    }

    public _Ocultar(triggerOncloseEvent = true) {
        this.Ocultar(triggerOncloseEvent);
        return this;
    }

    public _HabilitarBtns() {
        this.HabilitarBtns(true);
        return this;
    }

    public _DeshabilitarBtns() {
        this.HabilitarBtns(false);
        return this;
    }

    public _SetTitle(text: string) {
        this.modalHeaderSelection.select(":scope > h1")
            .text(text);
        return this;
    }

    public _OnClose(call: TOnCloseCallback) {
        this.config.OnClose = call;
        return this;
    }

    public _EnableEnterKey(val: boolean) {
        if (val) {
            UIUtilGlobalKeyEvents._SetKeyEventCallback(`modal_aceptar_${this.identificadorModal}`, {
                key: "Enter",
                refElement: this.containerSelection.node(),
                callback: () => {
                    let btnOk = this.modalFooterSelection.select<HTMLButtonElement>(".buttons_ok").node();
                    if (!btnOk || !btnOk.offsetParent || btnOk.className.includes("hide_transparent") || document.activeElement == btnOk)
                        return;
                    btnOk.click();
                },
            })
        } else {
            UIUtilGlobalKeyEvents._RemoveKeyEventCallback(`modal_aceptar_${this.identificadorModal}`);
        }
    }
}
