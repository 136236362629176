import { group as d3Group } from "d3-array";
import { MainPage } from "../../MainPage";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import DataModuloCuentaBancaria from "../../data/modulo/CuentaBancaria";
import { IConfigGridExcelExport, IGridExtraTableConfig, IGridRenderInfo, VentanaGrid } from "../controlD3/AVentanaGrid";
import { ExcelThings } from "../controlD3/ExcelExport";
import { FormGenerator, IField } from "../controlD3/Formulario";
import { ModalThings } from "../controlD3/ModalThings";
import { Table } from "../controlD3/Tabla";
import { UIUtilLang } from "../util/Language";
import { UIUtilGeneral } from "../util/Util";
import { UIUtilViewData } from "../util/ViewData";

import CTipoCuentaBancaria = Entidad.CTipoCuentaBancaria;
import CAccion = Entidad.CAccionPermiso;
import IBaseData = UIUtilViewData.IBaseData
type ICuentaBancaria = Entidad.IFinanzaCuentaBancaria & {
    IdsCuentas: number[];
    KinderFiltro: number[];
    IdsEscuelas: number[];
    CuentasReales: Entidad.IFinanzaCuentaBancaria[];
}

type ICuentaFormAgregar = Omit<ICuentaBancaria, keyof Pick<ICuentaBancaria, "IdEscuela" | "Tipo">> & {
    IdEscuela: number[];
    Tipo: CTipoCuentaBancaria[];
}
type ICuentaFormEditar = Omit<ICuentaBancaria, keyof Pick<ICuentaBancaria, "IdEscuela" | "Tipo">> & {
    IdEscuela: number[];
    Tipo: CTipoCuentaBancaria;
}

export class UIVentanaFinanzasCuentasBancarias extends VentanaGrid<ICuentaBancaria> {
    constructor(content: TSelectionHTML<"div">, modulo: Entidad.CModulo) {
        super(content, modulo, "finanzacuentabancaria");
    }

    protected GRID_GetDataRequestID(): DataModuloMain.TipoRequestMonitorId {
        return Entidad.CTipoRequest.CuentaBancaria;
    }

    protected GRID_GetMenuTopGrid(): Table.ITableMenuTopDefaultOptionConfig[] {
        let res: Table.ITableMenuTopDefaultOptionConfig[] = [];
        if (this.GridHasPermisoAccion(Entidad.CAccionPermiso.Agregar)) {
            res.push({
                Label: "Agregar",
                Callback: () => this.OpenModal_Agregar()
            })
        }
        return res;
    }
    protected GRID_GetSelectionDataMenuV2(menuLocation: "row" | "top-selected", dataGridSelected: ICuentaBancaria[]): Table.ITableMenuDataSelectedOptionConfig<ICuentaBancaria>[] {
        let opciones: Array<Table.ITableMenuDataSelectedOptionConfig<ICuentaBancaria>> = [];
        if (this.GridHasPermisoAccion(Entidad.CAccionPermiso.Editar)) {
            opciones.push({
                Label: "Editar",
                Callback: (datos) => this.OpenModal_Editar(datos[0]),
                MultiData: false
            })
        }
        if (this.GridHasPermisoAccion(Entidad.CAccionPermiso.Eliminar)) {
            opciones.push({
                Label: "Eliminar",
                Callback: (dato) => this.OpenModal_Eliminar(dato),
            })
        }
        return opciones;
    }
    protected GRID_GetFilters(): Table.IParametroFiltro<ICuentaBancaria>[] {
        return [
            { Field: "Beneficiario", Label: "Beneficiario" },
            { Field: "Tipo", Label: "Tipo", Type: "select", Options: UIUtilViewData._GetList_TipoCuentaBancaria() },
            { Field: "Numero", Label: "Numero" },
            { Field: "NombreBanco", Label: "NombreBanco" },
            { Field: "Clave", Label: "Clave" },
            { Field: "Sucursal", Label: "Sucursal" },
        ]
    }
    protected GRID_GetTableConfigBase(): IGridRenderInfo<ICuentaBancaria> {
        return {
            IdTabla: "FinanzasCatalogos-Cuentas",
            IdData: "Id",
            DefaultSort: "Beneficiario",
            MinWidth: 1120,
            Columns: [
                { Label: "Beneficiario", Field: "Beneficiario", Width: "13%", MinWidth: "170px" },
                { Label: "Tipo", Field: "getTipo", LabelLangKey: "d_field_tipo", Width: "8%", MinWidth: "100px" },
                { Label: "Numero", Field: "Numero", Width: "13%", MinWidth: "100px" },
                { Label: "Banco", Field: "NombreBanco", Width: "9%", MinWidth: "100px" },
                { Label: "Clave", Field: "Clave", Width: "10%", MinWidth: "100px" },
                { Label: "Sucursal", Field: "Sucursal", Width: "9%", MinWidth: "100px" },
                { Label: "Escuelas", Field: "IdsEscuelas", LabelLangKey: "d_field_nombreescuela", Width: "12%", MinWidth: "100px", },
            ]
        }
    }
    protected GRID_GetTableConfigAdvanced(): IGridExtraTableConfig<ICuentaBancaria> {
        return {
            EvaluatorAndSubLevelsBuild: {
                OnStepCellTable: (container, datum, field: keyof ICuentaBancaria) => {
                    if (field == "IdsEscuelas") {
                        UIUtilGeneral._CreaElementosLinkeablesV2<number>({ // FIXME Pasar a VentanaGrid, pruebas con ventanaEscuela, ¿usar d3.join?, ¿remover childNode instanceof Text?
                            Container: container,
                            Data: datum.IdsEscuelas,
                            GetId: d => d,
                            GetTag: d => DataModuloMain._GetDataValueFieldByName("Escuela", d, "Nombre"),
                            Path: "escuelas/escuelas/panel",
                            Direction: "vertical",
                        })
                    }
                }
            }
        };
    }

    protected GRID_GetExportarConfig(dataGrid: ICuentaBancaria[]): IConfigGridExcelExport<any> {
        return {
            IdsEscuelas: [...new Set(dataGrid.map(d => d.IdEscuela))],
            ColumnsConfig: this.ctrlTabla
                ._InfoColumns
                .filter(d => d.Field != "IdsEscuelas")
                .map<ExcelThings.IColumnToExcelExportFileConfig<ICuentaBancaria>>((d) => ({
                    Field: d.Field as keyof ICuentaBancaria,
                    HeaderTag: d.Label,
                    WidthCell: 25
                })),
            OnGetDataBySheets: async () => {
                return Array.from(d3Group(dataGrid, d => d.IdEscuela))
                    .map<ExcelThings.ISheetConfig<ICuentaBancaria>>(entrie => ({
                        IdSheet: entrie[0], // IdEscuela
                        SheetName: entrie[1][0].getNombreEscuela,
                        Data: entrie[1]
                    }))
            },
            OnGetEscuelasTagInSheet: (dataSheet: ICuentaBancaria[]) => dataSheet[0].getNombreEscuela,
        }
    }

    // FORMS

    /**
     * @param action `Agregar` | `Editar`
     * @param dato `ICuentaBancaria` | `undefined`
     * @returns Form
     */
    private GetForm(action: CAccion.Agregar, dato?: null): FormGenerator<ICuentaFormAgregar>;
    private GetForm(action: CAccion.Editar, dato: ICuentaBancaria): FormGenerator<ICuentaFormEditar>;
    private GetForm(action: CAccion.Agregar | CAccion.Editar, dato: ICuentaBancaria): FormGenerator<any> {
        const { IdEscuela } = (dato || {});
        const escuelasList = this.GridGetEscuelasConPermisoDeAccion(action, IdEscuela);
        const esEditar = (action == CAccion.Editar);

        const form = new FormGenerator<ICuentaFormAgregar | ICuentaFormEditar>()
            ._Crear({
                LabelMaxWidth: 152,
                LangModuleKeyInContext: this.labelsKeyBase,
                schema: [
                    {
                        model: "Beneficiario", type: "input",
                        labelText: "d_field_beneficiario",
                        inputAttr: {
                            maxlength: 60,
                            inputProperties: { required: true }
                        }
                    },
                    {
                        model: "IdEscuela", type: "selectMaterial",
                        labelText: "d_field_nombreescuela",
                        selectMaterialAttr: {
                            disabled: esEditar,
                            removeBorder: esEditar,
                            valueMember: "IdKinder",
                            displayMember: "Nombre",
                            required: true,
                            multiselect: true,
                        },
                        values: escuelasList,
                    },
                    (() => {
                        if (esEditar) {
                            return <IField>{
                                model: "Tipo", type: "radioList",
                                labelText: "d_field_tipo",
                                radioListAttr: {
                                    Direction: "vertical",
                                    Data: UIUtilViewData._GetList_TipoCuentaBancaria(),
                                    ValueMember: "Id",
                                    DisplayMember: "Name",
                                    ReturnOnlyValueMember: true,
                                    required: true,
                                    OnChange: (cta: IBaseData, id: CTipoCuentaBancaria) => {
                                        const esEfectivo = (id == CTipoCuentaBancaria.Efectivo);
                                        form._ControlsData.get("Numero").selection.property("required", !esEfectivo);
                                        form._ControlsData.get("NombreBanco").selection.property("required", !esEfectivo);
                                    }
                                }
                            } as any
                        }
                        return <IField>{
                            model: "Tipo", type: "selectMaterial",
                            labelText: "d_field_tipo",
                            selectMaterialAttr: {
                                valueMember: "Id",
                                displayMember: "Name",
                                required: true,
                                multiselect: true,
                            },
                            values: UIUtilViewData._GetList_TipoCuentaBancaria(),
                        }
                    })(),
                    {
                        model: "Numero", type: "input",
                        labelText: "d_field_numero",
                        inputAttr: {
                            type: "number",
                            valueAs: "text",
                            maxlength: 30,
                        }
                    },
                    {
                        model: "Clave", type: "input",
                        labelText: "d_field_clave",
                        inputAttr: { maxlength: 30, }
                    },
                    {
                        model: "NombreBanco", type: "input",
                        labelText: "d_field_nombrebanco",
                        inputAttr: { maxlength: 50, }
                    },
                    {
                        model: "Sucursal", type: "input",
                        labelText: "d_field_sucursal",
                        inputAttr: { maxlength: 50, }
                    },
                ],
            });

        if (dato) {
            form._AsignaData({
                ...dato,
                IdEscuela: dato.IdsEscuelas,
            })
        }

        if (esEditar) {
            const esEfectivo = dato.Tipo == CTipoCuentaBancaria.Efectivo;
            form._ControlsData.get("Numero").selection.property("required", !esEfectivo);
            form._ControlsData.get("NombreBanco").selection.property("required", !esEfectivo);
        }
        return form;
    }

    private OpenModal_Agregar() {
        this.GridOpenModal_ActionFormToAGridData({
            Title: "Agregar",
            GetForm: () => this.GetForm(Entidad.CAccionPermiso.Agregar),
            Action: Entidad.CAccionPermiso.Agregar,
            Width: 500,
            OnGetIdEscuelasToReload: (form) => form._Data.IdEscuela,
            OnAccept: async (form, mt) => {
                const data = form._Data
                const res = await DataModuloCuentaBancaria._RegistroMultiple(data, data.IdEscuela, data.Tipo);
                if ((data.Tipo.length == 1 && data.IdEscuela.length == 1) || !res.Fallidos.length) {
                    if (res.Resultado == -1) // 1: El numero y tipo de cuenta ya existen
                        await MainPage._ReloadServiceAndAwaitBool(res.TipoRequest, data.IdEscuela);
                    console.warn(res);
                    return res;
                }
                if (res.ExitososEsc.size || res.Fallidos.find(d => d.Resultado == -1)) {
                    const escuelasSync = new Set(res.ExitososEsc);
                    res.Fallidos.forEach(d => {
                        if (d.Resultado == -1) escuelasSync.add(d.Escuela);
                    })
                    await MainPage._ReloadServiceAndAwaitBool(res.TipoRequest, Array.from(escuelasSync));
                    this.GridUpdateData();
                }
                ModalThings._GetModalInfoDataList<typeof res.Fallidos[number]>({
                    Title: UIUtilLang._GetUIString("general", "resumen"),
                    InfoText: UIUtilLang._GetUIString("general", "notif_failarrayproccess"),
                    Width: 300,
                    DataList: res.Fallidos,
                    OnStepItemData: (container, item) => {
                        container.html(`<b>${DataModuloMain._GetDataValueFieldByName("Escuela", item.Escuela, "Nombre")}</b><br>`
                            + this.VB_GetUIStringModule("d_field_tipo") + ": " + UIUtilViewData._GetStr_TipoCuentaBancaria(item.Tipo) + "<br>"
                            + UIUtilLang._GetHTTPMessage({
                                TipoRequest: res.TipoRequest,
                                Resultado: item.Resultado,
                            }, "agregar"))
                    },
                    OnClose: () => mt.Modal._Ocultar(),
                })
                return null;
            },
            AutoReloadGridRequestOnFinally: true,
        })
    }

    private OpenModal_Editar(dato: ICuentaBancaria) {
        this.GridOpenModal_ActionFormToAGridData({
            Title: "Editar",
            Action: Entidad.CAccionPermiso.Editar,
            IdsEscuelas: [dato.IdEscuela],
            GetForm: () => this.GetForm(Entidad.CAccionPermiso.Editar, dato),
            Width: 500,
            OnAccept: (form) => DataModuloCuentaBancaria._ActualizarRegistro(form._Data),
            AutoReloadGridRequestOnFinally: true,
        })
    }

    private OpenModal_Eliminar(datos: ICuentaBancaria[]) {
        this.GridOpenModal_ProccessArrayData({
            DataToProccess: datos,
            Width: 300,
            OnGetIdEscuela: (dato) => dato.IdEscuela,
            OnError_GetItemDataTag: (dato) => (
                `${this.VB_GetUIStringModule("d_field_nombrebanco")}: ${dato.NombreBanco}\n`
                + `${this.VB_GetUIStringModule("d_field_numero")}: ${dato.Numero}\n`
                + `${this.VB_GetUIStringModule("d_field_clave")}: ${dato.Clave}\n`
                + `${this.VB_GetUIStringModule("d_field_beneficiario")}: ${dato.Beneficiario}`
            ),
            OnStepAProccess: (dato) => DataModuloCuentaBancaria._EliminarRegistro(dato.Id),
        })
    }

    protected async GridGetData(): Promise<ICuentaBancaria[]> {
        const data = await super.GridGetData();
        const dataGroupByNumCta = Array.from(d3Group(data, d => (!d.Numero ? d.Id : d.Tipo + d.Numero)).values());
        console.debug(dataGroupByNumCta)
        return dataGroupByNumCta.reduce((result, cuentas) => {
            const cuentaClon = UIUtilGeneral._ObjectClone(cuentas[0]);
            cuentaClon.IdsCuentas = cuentas.map(d => d.Id);
            cuentaClon.IdsEscuelas = cuentas.map(d => d.IdEscuela);
            cuentaClon.KinderFiltro = cuentas.map(d => d.IdEscuela);
            cuentaClon.CuentasReales = cuentas;
            result.push(cuentaClon);
            return result;
        }, <ICuentaBancaria[]>[]);
    }
}
