import { ArrayV2 } from "../../util/ArrayV2";
import { DataDRequest } from "../DRequest";
import { Entidad } from "../Entidad";
import { Global } from "../Global";
import { DataUtil } from "../util/Util";

export const _DiccTutorAsignaciones: Map<number, Entidad.ITutorAsignacion> = new Map();

/** Diccionario extra (IMPORTANTE: USO SOLO DE MAINPAGE (por ahora)):
 * * Map<idTutor, asignaciones<Map<idAsignacion, dataITutorAsignacion>>> */
export var _DiccAsignacionesPorTutor: Map<number, Map<number, Entidad.ITutorAsignacion>> = new Map();

/** Diccionario extra (IMPORTANTE: USO SOLO DE MAINPAGE (por ahora)):
 * * Map<idAlumno, asignaciones<Map<idAsignacion, dataITutorAsignacion>>> */
export var _DiccAsignacionesPorAlumno: Map<number, Map<number, Entidad.ITutorAsignacion>> = new Map();

// /** Diccionario extra (IMPORTANTE: USO SOLO DE MAINPAGE (por ahora)):
//  * * Map<idEscuela, asignaciones<Map<idAsignacion, dataITutorAsignacion>>> */
// export var DiccAsignacionesPorEscuela: Map<number, Map<number, Entidades.ITutorAsignacion>> = new Map();

type TIKinder = Entidad.IEscuela;
const IDREQUEST = Entidad.CTipoRequest.TutorAsignacion;

export function _GetList(escuelas: TIKinder[]) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "padre/ObtenerAsignacionesAlumno";
    return new ArrayV2<TIKinder>()
        ._Push(...escuelas)
        ._MapAwait(async ({ IdKinder }) => {
            const currentDateStr = await DataUtil._GetMaxDate(IDREQUEST, IdKinder);
            const params = {
                "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
                "IdEscuela": IdKinder,
                "Modificacion": currentDateStr,
            }
            return DataDRequest._Request4List(url, "GET", params, IDREQUEST, IdKinder);
        });
}

interface IAsignarTutorResponse {
    Resultado: number,
    /** { IdTutor: StrPassword } */
    tutor: { [key: number]: string },
}
export async function _AsignarAlumnoTutor(idAlumno: number, idTutor: number, idEscuela: number, esMonitoreo: boolean): Promise<DataDRequest.IRequestResponseA & IAsignarTutorResponse> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "alumno/AsignarTutor";
    const params = {
        "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "IdAlumno": idAlumno,
        "IdTutor": idTutor,
        "IdEscuela": idEscuela,
        "Monitoreo": esMonitoreo
    }
    return DataDRequest._Request5(url, "POST", params, Entidad.CTipoRequest.TutorAsignacion);
}

export async function _EliminarAsignacionAlumnoTutor(idAsignacion: number): Promise<DataDRequest.IResultadoPeticion<undefined>> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "alumno/EliminarTutorAsignacion";
    const params = {
        "IdAsignacion": idAsignacion
    }

    return new Promise(function (resolve, reject) {
        DataDRequest._Request(url, "POST", params, (result: DataDRequest.IRequestResponseBase) => {
            resolve({
                Resultado: result?.Resultado || -100,
                TipoRequest: Entidad.CTipoRequest.TutorAsignacion
            })
        });
    });
}

//var dtMax: Date = null;
//var strMax: string = null;

/*             export async function fn_ObtenerAsignacionTutor(idOrganizacion: number, modificacion: string, callback?: Function): Promise<Object> {
                var params = {
                    "IdOrganizacion": idOrganizacion,
                    "Modificacion": modificacion // != null ? modificacion : "1970-01-01T00:00:00.000Z"
                }
 
                // console.log("fn_ObtenerChilds ", params);
                var url = app.prop_URLPrincipal + "Padre/ObtenerAsignacionesChild";
                return new Promise(function (resolve, reject) {
                    DRequest.fn_Request(url, "GET", params, (result: IResponse) => {
                        result = result != null ? result : <IResponse>{};
 
                        var fecha: Date = null;
                        var guardarFecha: boolean = false;
 
                        fecha = new Date(result.Maxima);
 
                        if (fecha > dtMax) {
                            guardarFecha = true;
                            dtMax = fecha;
                            strMax = result.Maxima;
                        }
 
                        let data = result.Datos;
 
                        var rData: Object = {
                            //maxDate: strtMax,
                            maxDate: guardarFecha ?strMax :null,
                            data: data
                        }
                        resolve(rData);
                    });
                });
            } */

export default {
    _DiccTutorAsignaciones,
    _DiccAsignacionesPorTutor,
    _DiccAsignacionesPorAlumno,
    _AsignarAlumnoTutor,
    _EliminarAsignacionAlumnoTutor
}
