import { group as d3Group } from "d3-array";
import { MainPage } from "../../MainPage";
import { DataDRequest } from "../../data/DRequest";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import { _LOCALDATA_GetGruposHorariosDeAlumno } from "../../data/modulo/Alumno";
import DataModuloEscolaridad from "../../data/modulo/Escolaridad";
import DataModuloEscuela from "../../data/modulo/Escuela";
import DataModuloGrado from "../../data/modulo/Grado";
import DataModuloGrupo from "../../data/modulo/Grupo";
import DataModuloHorarioAlumno, { IGrupoHorarioActualizarV2, IHorarioActualizarResponse, IHorariosWeek } from "../../data/modulo/HorarioAlumno";
import { DateV2 } from "../../util/DateV2";
import { CardV2Collapse, TCARDV2COLL_OnEditOriginEvent } from "../controlD3/CardV2Collapse";
import { ComponentCircleItems } from "../controlD3/ComponentCircleItems";
import { ElementWrapper } from "../controlD3/ElementWrapper";
import { SelectV2 } from "../controlD3/SelectV2";
import { UIUtilLang } from "../util/Language";
import { UIUtilGeneral } from "../util/Util";
import { UIUtilViewAlumno } from "../utilView/Alumno";
import { UIUtilViewAlumnoScheduleControl } from "../utilView/AlumnoScheduleControl";
import { UIUtilViewGrupos } from "../utilView/Grupos";
import { UIWindowManager } from "../ventana/WindowManager";

import CAccionPermiso = Entidad.CAccionPermiso;
import IEscolaridad = Entidad.IEscolaridad;
import IGrado = Entidad.IGrado;
import IGrupo = Entidad.IGrupo;
import CDiaSemanal = Entidad.CDiaSemanal;
import IAlumno = Entidad.IAlumno;

interface IGrupoR extends IGrupo {
    Color: string;
    StrEscolaridad: string;
}

interface IItemHorarioG {
    IdHorario: number;
    Horario: Entidad.IHorarioDia[];
}

interface IInfoGeneral {
    Alumnos: IAlumno[];
    Escuela: Entidad.IEscuela;
    /** @deprecated */
    IdGrado: number;
    IdEscolaridad: number;
    /** < IdGrupo, IdHorario, HORARIO_ITEMS[] >
     * * Todos los grupos a los que el alumno esté inscrito, independiente del grado y nivel actual del alumno
     * * NOTE: SOLO ALMACENA VALORES CUANDO Alumnos.length == 1
    */
    GruposHorariosPersonalizados: Map<number, IItemHorarioG>;
    /** NOTA: Anteriormente eran:
     * * Grupos por grado (gradoGrupo == gradoAlumno).
     * * Por Escolaridad
     * * Ahora por ESCUELA
     * 
     * @beforename GruposRealesNivel
    */
    GruposRealesEscuela: Map<number, IGrupoR>;
}

const coloresDisponibles = ["#ffd92f", "#17becf", "#d0e6a5", "#ff9190", "#ccabda", "#ffbe72", "#e29135", "#62c245", "#ff4d4d", "#45c2a7",
    "#5b5dff", "#a53fe0", "#c24598", "#b6c245", "#90aca7", "#df9aff", "#9aca14", "#d87c31"];

/** 
 * // NOTE: Todos los alumnos seleccionados pertenecen a la misma escuela y nivel escolar (escolaridad) . // y grado (implementación anterior).
 * Los grupos que se muestran se filtran por escuela y grado (cada grado está asignado a un solo nivele escolar).
 * 
 * // NOTE PERMISOS: Todos los alumnos seleccionados pertenecen a la misma escuela,
 * en caso de modificarlo, los permisos lo deben de tomar en cuenta.
 * 
 * // NOTE: Funcionalidades:
 * * AsignarNuevos -> Monoselección-Alumno (Si el alumno no tiene asignaciones). Multiselección-Alumnos
 * * AsignarNuevos, Editar y Eliminar -> Si el alumno ya tenia horarios asignados.
 * Se toman en cuenta los horarios con grupos de otros grados y grupos eliminados, los cuales al guardar la información, serán desasignados del alumno.
*/
export class UIPanelCardAlumnosInscripcionPreview extends CardV2Collapse<[IAlumno[]]> {
    private infoData: IInfoGeneral;

    private scheduleControl: UIUtilViewAlumnoScheduleControl.ScheduleControl;
    private grupoComponent: ComponentCircleItems<IGrupoR, "IdGrupo">;
    private ctrlSelectEscolaridades: SelectV2<IEscolaridad, "Id", "multiselect">;
    private ctrlSelectGrados: SelectV2<IGrado, "IdNivel", "multiselect">;

    private exitWhenFinished: boolean;

    constructor() {
        super("", Entidad.CModulo.PanelNinioHorario);
        this.exitWhenFinished = false;
        this.forceUpdateInEditingStatus = true;
    }

    protected CARDCOLL_OnInitBuild(contentContainer: TSelectionHTML<"div", any, any>): void {
        contentContainer
            .style("overflow-y", "hidden")
            .style("row-gap", "10px");

        // AREA SCHEDULE

        this.scheduleControl = new UIUtilViewAlumnoScheduleControl.ScheduleControl({
            Parent: contentContainer,
            OnCloseAItem: (idGrupo: number, idDia: number) => {
                if (!this.scheduleControl._HasItemsFromIdGroup(idGrupo)) {
                    // this.infoData.GruposRealesNivel es la data del componente
                    // this.infoData.GruposRealesEscuela.get(idGrupo).Seleccionado = false;
                    this.grupoComponent
                        ._SelectItems(false, idGrupo)
                        ._UI_RefreshCirculos();
                }
            }
        });

        this.scheduleControl
            ._controlContainer
            .style("overflow", "auto")
            .style("border-bottom", "solid var(--color_borderbox1) 1px");

        // AREA GRUPOS

        this.grupoComponent = new ComponentCircleItems<IGrupoR, "IdGrupo">({
            Parent: contentContainer.node(),
            IdData: "IdGrupo",
            OnGetCircleTag: (dato) => UIUtilViewGrupos._GetLblGrupoName(dato),
            OnGetContentToTooltip: (dato) => {
                return [
                    [this.CARDCOLL_GetUIStringModule("tag_nivel"), dato.StrEscolaridad],
                    [this.CARDCOLL_GetUIStringModule("tag_grado"), dato.NombreGrado],
                    [this.CARDCOLL_GetUIStringModule("tag_grupo"), UIUtilViewGrupos._GetLblGrupoName(dato)]
                ]
            },
            OnMouseEnter: (dato) => {
                // console.debug("mouseenter", dato.IdGrupo, this.grupoComponent.met_ItemHasSelected(dato.IdGrupo), dato)
                this.InsertItemsByGroup(dato, true);
            },
            OnMouseLeave: (dato) => {
                // console.log("mouseleave", dato.IdGrupo, dato.Seleccionado, dato)
                // if (!dato.Seleccionado) {
                if (!this.grupoComponent._ItemHasSelected(dato.IdGrupo)) {
                    this.scheduleControl._DeleteItemsByGroup(dato.IdGrupo);
                } else {
                    this.scheduleControl._DeleteItemsByGroup(dato.IdGrupo, "preview");
                }
            },
            OnMouseClick: (dato) => {
                if (dato.Horario.length > 0) {
                    // if (dato.Seleccionado) {
                    if (this.grupoComponent._ItemHasSelected(dato.IdGrupo)) {
                        // if (this.scheduleControl.met_HasItemsFromIdGroup(dato.IdGrupo, true)) {
                        //     this.scheduleControl.met_DeleteItemsByGroup(dato.IdGrupo, true);
                        //     this.InsertItemsByGroup(dato, false);
                        //     this.grupoComponent.met_SelectItems(true, dato.IdGrupo);
                        // } else {
                        if (this.scheduleControl._HasItemsFromIdGroup(dato.IdGrupo, "preview")) {
                            // Si tiene elementos "preview" (que se integraron en OnMouseEnter),
                            // es porque el grupo real tiene elementos que no existen el la conf actual de alumno.

                            // El alumno está usando todos los bloques disponibles del grupo
                            this.scheduleControl._DeleteItemsByGroup(dato.IdGrupo, "preview");
                            this.InsertItemsByGroup(dato, false);
                        }
                    } else {
                        // Si un circulo ya estaba seleccionado y se vuelve a clickear,
                        // verificar si se va a deseleccionar o si seguirá seleccionado y se agregarán los items faltantes
                        if (this.scheduleControl._HasItemsFromIdGroup(dato.IdGrupo, "sticked")) {
                            // Tiene items del grupo seleccionados
                            if (this.scheduleControl._HasItemsFromIdGroup(dato.IdGrupo, "preview")) {
                                // Si tiene items "preview", los remueve y los agrega como nuevos seleccionados
                                this.scheduleControl._DeleteItemsByGroup(dato.IdGrupo, "preview");
                                this.InsertItemsByGroup(dato, false);
                                // Volver a seleccionar el grupo (circulo)
                                this.grupoComponent._SelectItems(true, dato.IdGrupo)
                                    ._UI_RefreshCirculos();
                            } else {
                                // No hay items faltantes (preview), deseleccionan todos
                                this.scheduleControl._DeleteItemsByGroup(dato.IdGrupo);
                            }
                        }
                    }
                    this.grupoComponent._UI_RefreshCirculos();
                } else {
                    this.ctrlNotification._Mostrar(this.CARDCOLL_GetUIStringModule("notif_gruposinhorario"), "ADVERTENCIA");
                }
            },
        });

        // AREA FILTROS: SELECT ESCOLARIDADES Y GRADOS
        let gruposHeader = this.grupoComponent._HeaderContainer // .prop_ControlContainer.select<HTMLHeadElement>("header")
            .classed(UIUtilGeneral.FBoxOrientation.Vertical, true)

        let labelTag = gruposHeader.append("div")
            .classed(UIUtilGeneral.FBoxOrientation.Horizontal, true)
            .style("cursor", "pointer")
            .style("padding-bottom", "10px");

        // labelTag.html("<label>Opciones avanzadas <span style='font-weight: bold;'>+</span><label>")

        let filtrosCont = gruposHeader.append("div")
            .classed(UIUtilGeneral.FBoxOrientation.Horizontal, true)
            .style("column-gap", "10px")
            .style("padding-bottom", "10px");

        labelTag.on("click", () => {
            if (!this.ctrlSelectEscolaridades._visible && !this.ctrlSelectGrados._visible) {
                let noVisible = filtrosCont.classed("hide");
                this.UI_UpdateAreaGruposFiltroVisible(noVisible);
            }
        })

        this.ctrlSelectEscolaridades = new SelectV2({
            Parent: filtrosCont,
            ValueMember: "Id",
            DisplayMember: "Nombre",
            Type: "multiselect",
            ShowNSelectedInList: true,
            Placeholder: this.CARDCOLL_GetUIStringModule("tag_nivelplacehold"),
            OnSelect: (escolaridadSelected) => {
                // console.log(escolaridadSelected, "escolaridadSelected");
                if (escolaridadSelected) {
                    let grados = Array.from(DataModuloGrado._DiccGrado.values())
                        .filter(d => (escolaridadSelected.find(dE => (dE.Id == d.IdEscolaridad))))
                        .sort(d => d.IdEscolaridad)
                    this.ctrlSelectGrados._UpdateList(grados);

                    let itemsDisabled = grados
                        .filter(d => (DataModuloGrado._LOCALDATA_GetGruposEnGrado(d.IdNivel).size == 0))
                        .map(d => d.IdNivel);

                    this.ctrlSelectGrados._disableItems(itemsDisabled);
                }
            },
        })

        filtrosCont.append(() => ElementWrapper._WrapperToSelectControl(this.ctrlSelectEscolaridades, this.CARDCOLL_GetUIStringModule("tag_nivel") + ":").node());

        this.ctrlSelectGrados = new SelectV2({
            Parent: filtrosCont,
            ValueMember: "IdNivel",
            DisplayMember: "Nombre",
            Type: "multiselect",
            ShowNSelectedInList: true,
            Placeholder: this.CARDCOLL_GetUIStringModule("tag_gradoplacehold"),
            OnSelect: (gradosSelected) => {
                // console.log(gradosSelected, "gradosSelected");
                if (gradosSelected) {
                    setTimeout(() => {
                        this.UI_UpdateGruposByIds(...gradosSelected.map(d => d.IdNivel))
                    });
                }
            },
            OnStepItemListUI: (container, dato, step) => {
                if (step == "enter") {
                    container.classed(UIUtilGeneral.FBoxOrientation.Vertical, true);
                }
                if (this.ctrlSelectEscolaridades._dataSelected.length > 1) {
                    container.html(`
                                <label>${this.CARDCOLL_GetUIStringModule("tag_nivel")}: ${DataModuloEscolaridad._DiccEscolaridad.get(dato.IdEscolaridad)?.Nombre || UIUtilLang._GetUIString("general", "nodisponible")}</label>
                                <label>${this.CARDCOLL_GetUIStringModule("tag_grado")}: ${dato.Nombre}</label>
                            `)
                } else {
                    container.html(`<label>${dato.Nombre}</label>`);
                }
                if (DataModuloGrado._LOCALDATA_GetGruposEnGrado(dato.IdNivel).size == 0) {
                    container.append("label")
                        .style("color", "red")
                        .style("font-size", "var(--fontsize_me4)")
                        .text(this.CARDCOLL_GetUIStringModule("tag_gradosingrupos"));
                }
            }
        })

        filtrosCont.append(() => ElementWrapper._WrapperToSelectControl(this.ctrlSelectGrados, this.CARDCOLL_GetUIStringModule("tag_grados") + ":").node());

        //ocultar el div de grupos
        // this.grupoComponent.prop_ControlContainer.classed("hide", true); // .style("display", "none");
        this.UI_UpdateAreaGruposFiltroVisible(false);

        if (!this["__workerListenerReq"]) {
            this["__workerListenerReq"] = MainPage._AddEventListenerWorkerRequest(Entidad.CTipoRequest.HorarioAlumno, (e) => {
                if (!this.cardData) return;
                this._UpdateCardData(false, ...this.cardData);
            })
        }
    }
    protected CARDCOLL_GetVariantToValidateUpdate(cardData_0: IAlumno[]): string {
        const alumno = cardData_0[0];
        if (alumno) {
            let variant = `${alumno.IdKinder}_${alumno.IdEscolaridad}_${alumno.IdGrado}-${alumno.IdChild}>>`;

            _LOCALDATA_GetGruposHorariosDeAlumno(alumno.IdChild)
                .forEach(d => {
                    variant += d.Entradas.toString() + "__";
                    variant += d.Salidas.toString() + ">>";
                })
            return variant;
        }
        return null;
    }
    protected CARDCOLL_OnUpdateData(cardData_0: IAlumno[]): void | Promise<void> {
        const hasPermissionToEdit = this.HasActionPermission(CAccionPermiso.Editar);
        //if (!this.exitWhenFinished) {
        this.UpdateData(cardData_0);
        this.UI_Update_ScheduleLimits();

        if (this.CARDCOLL_StatusCardEditando) {
            this.UI_EditarCard();
        } else {
            this.UI_ResetScheduleItems();
            this.btnEditarCard?._d3Selection.classed("hide", !hasPermissionToEdit);
        }
        //}

        this.CARDCOLL_UICardHasError();

        if (!hasPermissionToEdit) {
            this.btnEditarCard?._d3Selection.classed("hide", true);
        }

        this.UpdateUIFromPermissions();
    }
    protected CARDCOLL_MostrarBody(): void {
        this.cardContentContainerSelection.style("height", "100%");
        this.grupoComponent._ControlContainer.classed("hide", true);
        this.UpdateUIFromPermissions();
    }
    protected CARDCOLL_OcultarBody(): void {
        this.cardContentContainerSelection.style("height", null);
    }
    protected CARDCOLL_OnEditarCard(originEvent: TCARDV2COLL_OnEditOriginEvent): void {
        if (originEvent !== "onCancel") {
            this.UI_EditarCard();
        }
    }
    protected CARDCOLL_OnCancelaEditarCard(originEvent: TCARDV2COLL_OnEditOriginEvent): void {
        this.UI_CancelarCard();
    }
    protected CARDCOLL_GuardarCardV2(): Promise<DataDRequest.IRequestResponseA<any>> {
        if (!this.scheduleControl._HasIntersections) {
            if (this.scheduleControl._LengthItems > 0) {
                return this.Sv_GuardarInscripcion();
            } else {
                this.ctrlNotification._Mostrar(this.CARDCOLL_GetUIStringModule("notif_sinvalidconfig"), "ADVERTENCIA");
            }
        } else {
            this.ctrlNotification._Mostrar(this.CARDCOLL_GetUIStringModule("notif_hrsmerged"), "ADVERTENCIA");
        }
        return null;
    }
    protected CARDCOLL_SyncOrGetIdToDownloadData(): DataModuloMain.TipoRequestMonitorId | (() => Promise<any>) | DataModuloMain.TipoRequestMonitorId[] {
        return Entidad.CTipoRequest.HorarioAlumno;
    }
    protected CARDCOLL_GetIdSchool(cardData_0_0: IAlumno[]): number {
        return cardData_0_0[0].IdKinder;
    }

    protected CARDCOLL_UICardHasError(error: boolean = null) {
        super.CARDCOLL_UICardHasError(!this.GetAlumnosEstanInscritos().AllHaveValidGroups, UIUtilLang._GetUIString("alumnos", "notif_falta_grupo"));
    }

    public _Destroy(): this {
        super._Destroy();
        MainPage._RemoveEventListenerWorkerRequest(Entidad.CTipoRequest.HorarioAlumno, this["__workerListenerReq"]);
        this["__workerListenerReq"] = null;
        return this;
    }

    private UpdateData(alumnos: IAlumno[]) {
        this.infoData = {
            Alumnos: alumnos,
            Escuela: DataModuloEscuela._DiccEscuela.get(alumnos[0].IdKinder),
            IdEscolaridad: alumnos[0].IdEscolaridad,
            IdGrado: alumnos[0].IdGrado,
            GruposHorariosPersonalizados: new Map(),
            GruposRealesEscuela: new Map()
        }

        let indexToColor = 0;
        this.infoData.GruposRealesEscuela = new Map(Array.from(DataModuloGrupo._DiccGrupo.values())
            // .filter(g => data.modulos.Nivel.DiccNivel.get(g.IdNivel).IdEscolaridad == this.infoData.IdEscolaridad)
            .filter(g => g.IdKinder == this.infoData.Escuela.IdKinder)
            .map(d => {
                let grupoCasiClone = <IGrupoR>Object.assign({}, d);
                grupoCasiClone.Color = coloresDisponibles[indexToColor];
                grupoCasiClone.StrEscolaridad = DataModuloEscolaridad._DiccEscolaridad.get(DataModuloGrado._DiccGrado.get(grupoCasiClone.IdNivel)?.IdEscolaridad)?.Nombre || UIUtilLang._GetUIString("general", "nodisponible");

                if (indexToColor == coloresDisponibles.length - 1) {
                    indexToColor = 0;
                } else {
                    indexToColor++;
                }
                return ([d.IdGrupo, grupoCasiClone]);
            }));

        if (alumnos.length == 1) {
            _LOCALDATA_GetGruposHorariosDeAlumno(alumnos[0].IdChild)
                .forEach(grupo => {
                    this.infoData.GruposHorariosPersonalizados.set(grupo.IdGrupo, {
                        IdHorario: grupo.IdHorario,
                        Horario: grupo.Horario
                    })
                })
        }
    }

    private GetAlumnosEstanInscritos(includeGroupsNoFounded = false) {
        return UIUtilViewAlumno._GetGruposInscritosValidos(this.infoData.Alumnos, includeGroupsNoFounded);
    }

    private GetIdsGruposSeleccionadosActuales(): number[] {
        return Array.from(this.infoData.GruposHorariosPersonalizados.keys())
    }

    private UI_Update_ScheduleLimits() {
        let escuela = this.infoData.Escuela;
        this.scheduleControl._UpdateHorarioLimitesGeneral(escuela.Entrada, escuela.Salida);
    }

    private UI_ResetScheduleItems() {
        const timeZone = this.infoData.Escuela.ZonaHoraria;
        let itemsToInsert: UIUtilViewAlumnoScheduleControl.IItemBlockAlumnoDate[] = [];

        // FIXME 
        this.scheduleControl._controlContainer.selectAll(".schedule_item_range").remove();

        this.infoData.GruposHorariosPersonalizados.forEach((itemHorario, idGrupo) => {
            /** idGrupo -> idDia, ITEM */
            let allGrupos: Map<number, Map<number, UIUtilViewAlumnoScheduleControl.IItemBlockAlumnoDate>> = new Map();

            let grupoOrigen = this.infoData.GruposRealesEscuela.get(idGrupo);
            // data.modulos.Grupos.DiccGrupo.get(idGrupo);

            // -> Items Base, sin personalización
            grupoOrigen?.Horario.forEach(horarioR => {
                let horariosMap = allGrupos.get(grupoOrigen.IdGrupo);

                if (!horariosMap) {
                    horariosMap = new Map();
                    allGrupos.set(idGrupo, horariosMap);
                }

                let horarioItem: UIUtilViewAlumnoScheduleControl.IItemBlockAlumnoDate = {
                    Id: grupoOrigen.IdGrupo + "_" + horarioR.IdDia,
                    Inicio: new DateV2(horarioR.Entrada)._SetTimeZone(timeZone),
                    Fin: new DateV2(horarioR.Salida)._SetTimeZone(timeZone),
                    IdDia: horarioR.IdDia,
                    DataExtra: {
                        ColorBase: grupoOrigen.Color,
                        // Seleccionado: grupoOrigen.Seleccionado,
                        Type: "sticked",
                        Entrada: null, // new Date(horarioR.Entrada),
                        Salida: null, // new Date(horarioR.Entrada),
                        GrupoId: grupoOrigen.IdGrupo,
                        GrupoNombreText: UIUtilViewGrupos._GetLblGrupoName(grupoOrigen),
                        HorarioId: null
                    }
                }

                horariosMap.set(horarioR.IdDia, horarioItem);
            })

            // -> Items Personalizados
            itemHorario.Horario.forEach((d, i) => {
                let horariosMap = allGrupos.get(idGrupo);

                if (horariosMap) {
                    let horarioItem = horariosMap.get(d.IdDia);

                    if (horarioItem) {
                        horarioItem.DataExtra.Entrada = new DateV2(d.Entrada)._SetTimeZone(timeZone);
                        horarioItem.DataExtra.Salida = new DateV2(d.Salida)._SetTimeZone(timeZone);
                        horarioItem.DataExtra.HorarioId = d.IdHorario;

                        // console.log(horarioItem, "HORARIO ITEM")
                        itemsToInsert.push(horarioItem);
                    } else {
                        // NOTE EL HORARIO DE _DIA_ YA NO ESTÁ DISPONIBLE
                    }
                }
            })
        })

        this.scheduleControl._SetEnableItemsToEdit(this.CARDCOLL_StatusCardEditando);
        this.scheduleControl._ClearItems();
        this.scheduleControl._SetScheduleItem(...itemsToInsert);
    }

    private InsertItemsByGroup(grupoB: IGrupoR, asPreviewItems: boolean) {
        const timeZone = this.infoData.Escuela.ZonaHoraria;
        let itemsHorarioGrupo: UIUtilViewAlumnoScheduleControl.IItemBlockAlumnoDate[] = [];
        let grupo = DataModuloGrupo._DiccGrupo.get(grupoB.IdGrupo);

        grupo.Horario.forEach((horarioR, i) => {
            if (!this.scheduleControl._HasItemByGrupoAndDay(grupoB.IdGrupo, horarioR.IdDia)) {
                let horarioGrupo: UIUtilViewAlumnoScheduleControl.IItemBlockAlumnoDate = {
                    Id: grupo.IdGrupo + "_" + horarioR.IdDia,
                    Inicio: new DateV2(horarioR.Entrada)._SetTimeZone(timeZone),
                    Fin: new DateV2(horarioR.Salida)._SetTimeZone(timeZone),
                    IdDia: horarioR.IdDia,
                    DataExtra: {
                        ColorBase: grupoB.Color,
                        // Seleccionado: grupoOrigen.Seleccionado,
                        Type: asPreviewItems ? "preview" : "sticked",
                        Entrada: new DateV2(horarioR.Entrada)._SetTimeZone(timeZone),
                        Salida: new DateV2(horarioR.Salida)._SetTimeZone(timeZone),
                        GrupoId: grupo.IdGrupo,
                        GrupoNombreText: UIUtilViewGrupos._GetLblGrupoName(grupo),
                        HorarioId: 0 - (grupo.IdGrupo + 1)
                    }
                }
                itemsHorarioGrupo.push(horarioGrupo);
            }
        });

        this.scheduleControl._SetScheduleItem(...itemsHorarioGrupo);
    }

    private UI_EditarCard() {
        let escolaridades = Array.from(DataModuloEscolaridad._DiccEscolaridad.values())
            .filter(d => (d.IdEscuela == this.infoData.Escuela.IdKinder));

        // let grados = Array.from(data.modulos.Nivel.DiccNivel.values())
        //     .filter(d => (d.IdEscolaridad == this.infoData.IdEscolaridad));

        let idsGrados = Array.from(this.infoData.GruposHorariosPersonalizados.keys()) // IdsGrupos
            .map(idGrupo => this.infoData.GruposRealesEscuela.get(idGrupo)) // Grupos
            .map(grupo => grupo?.IdNivel) // IdsGrados

        let idsEscolaridades = [];
        idsGrados.forEach(idGrado => {
            let idEsc = DataModuloGrado._DiccGrado.get(idGrado)?.IdEscolaridad;
            if (idEsc) {
                if (idsEscolaridades.indexOf(idEsc) == -1) {
                    idsEscolaridades.push(idEsc);
                }
            } else {
                console.warn("-d", "Nivel (Grado) no encontrado: ", idGrado);
            }
        });

        if (idsEscolaridades.length == 0) {
            idsEscolaridades = [this.infoData.IdEscolaridad];
        }

        if (idsGrados.length == 0) {
            idsGrados = [this.infoData.IdGrado];
        }

        this.ctrlSelectEscolaridades._UpdateList(escolaridades);
        this.ctrlSelectEscolaridades._valueSelect(idsEscolaridades);

        // this.ctrlSelectGrados.met_UpdateList(grados);
        this.ctrlSelectGrados._valueSelect(idsGrados);
        // this.UI_UpdateGruposByEscolaridad(this.infoData.Alumnos[0].IdEscolaridad);

        if (this.infoData.Alumnos.length == 1) this.UI_ResetScheduleItems()
        this.grupoComponent._ControlContainer.classed("hide", false);
        this.UI_UpdateAreaGruposFiltroVisible(false);
    }

    private UI_CancelarCard() {
        this.CARDCOLL_UICardHasError();

        if (this.exitWhenFinished) {
            setTimeout(() => {
                if (this.CARDCOLL_StatusCardExpandido) UIWindowManager._HistoryBackEval("alumnos/alumnos");
            })
        } else {
            this.UpdateData(this.infoData.Alumnos);
            this.UI_ResetScheduleItems();
            this.grupoComponent._ControlContainer.classed("hide", true);
        }
    }

    private UI_UpdateGruposByIds(...idsGrados: number[]) {
        let gruposByEscolaridad = Array.from(this.infoData.GruposRealesEscuela.values())
            .filter(d => Boolean(idsGrados.find(idGrado => (idGrado == d.IdNivel))));

        this.grupoComponent._UpdateItems(gruposByEscolaridad);
        this.grupoComponent._SelectItems(false, ...gruposByEscolaridad.map(d => d.IdGrupo));
        this.grupoComponent._SelectItems(true, ...this.GetIdsGruposSeleccionadosActuales());

        this.infoData.GruposRealesEscuela.forEach((grupo, idGrupo) => {
            this.grupoComponent._ItemColor(idGrupo, grupo.Color);
        })
        // console.debug("Grupos seleccionados", ...this.GetIdsGruposSeleccionadosActuales())

        this.grupoComponent._UI_RefreshCirculos();
    }

    private UI_UpdateAreaGruposFiltroVisible(filtersVisible: boolean) {
        let gruposHeader = this.grupoComponent._HeaderContainer; //.select<HTMLHeadElement>("header");
        let lbl_text = this.CARDCOLL_GetUIStringModule("tag_advancedopcs");

        gruposHeader.select("div:nth-child(1)")
            .html("<label style='cursor:pointer;'>" + lbl_text + " <span style='font-weight: bold;'>" + (filtersVisible ? "-" : "+") + "</span><label>");

        gruposHeader.select("div:nth-child(2)")
            .classed("hide", !filtersVisible);
    }

    public _ExitWhenFinish(value: boolean) {
        this.exitWhenFinished = value;
    }

    private UpdateUIFromPermissions() {
        if (!this.CARDCOLL_StatusCardEditando) {
            this.btnEditarCard._d3Selection.classed("hide", !this.HasActionPermission(Entidad.CAccionPermiso.Editar));
        }
    }

    /** @returns -{ [key as DIA ]: { [key as ("Entrada" | "Salida") ] : string} } */
    private GetHorarioRealFromControlData(itemsInAWeek: UIUtilViewAlumnoScheduleControl.IItemBlockResult[]) {
        const timeZone = this.infoData.Escuela.ZonaHoraria;
        let horarioToUp: IHorariosWeek = <any>{};

        for (let keyDia in CDiaSemanal) {
            if (!isNaN(Number(keyDia))) {
                let idDia: CDiaSemanal = Number(keyDia);
                let strDia: keyof typeof CDiaSemanal = CDiaSemanal[keyDia] as any;
                let horarioInADay = itemsInAWeek.find(dd => dd.IdDia == idDia);

                if (horarioInADay) {
                    horarioToUp[strDia] = {
                        Entrada: new DateV2()
                            ._SetTimeZone(timeZone)
                            ._SetLocalStrHour(horarioInADay.DataExtra.HrEntrada + ":00")
                            ._ToISOString(),
                        //horarioInADay.DataExtra.HrEntrada,
                        Salida: new DateV2()
                            ._SetTimeZone(timeZone)
                            ._SetLocalStrHour(horarioInADay.DataExtra.HrSalida + ":00")
                            ._ToISOString(),
                        //horarioInADay.DataExtra.HrSalida
                    }
                }
            }
        }

        return horarioToUp;
    }

    // **************************************************************************
    // PERMISOS COSAS
    // **************************************************************************

    // **************************************************************************
    // SERVICIOS COSAS
    // **************************************************************************

    /** //NOTE: (1) Si el alumno está inscrito en grupos de niveles diferentes, se van mandarán como eliminados */
    private Sv_GuardarInscripcion() {
        type TResponse = DataDRequest.IRequestResponseA<IHorarioActualizarResponse | number[]>;

        let idEscuela = this.infoData.Escuela.IdKinder;
        let res: TResponse;
        let horariosResult = this.scheduleControl._GetCurrentItems();

        return new Promise<TResponse>(async resolve => {
            // Si el alumno ya estaba inscrito (independiente del nivel y grado), ACTUALIZAR //NOTE (1)
            // Solo se actualizan
            if (this.infoData.Alumnos.length == 1 && this.infoData.GruposHorariosPersonalizados.size > 0) {
                let idAlumno = this.infoData.Alumnos[0].IdChild;
                let horariosActualizarInsert: IGrupoHorarioActualizarV2[] = [];

                // NOTE Si el Id de horario es negativo, entonces no es uno real, sino uno formado en el momento de Edición
                let horariosGroupByIdGrupo = d3Group(horariosResult, d => d.DataExtra.GrupoId);

                let horariosEliminar: number[] = [];
                // horariosGroupByIdHorario.forEach((horarioItem, idHorario) => {
                // -> Busca sí ya existia Horario antes de editar y actualiza
                this.infoData.GruposHorariosPersonalizados.forEach((itemHorario, idGrupo) => {

                    if (horariosGroupByIdGrupo.has(idGrupo)) {
                        horariosActualizarInsert.push({
                            IdHorario: itemHorario.IdHorario,
                            Horarios: this.GetHorarioRealFromControlData(horariosGroupByIdGrupo.get(idGrupo))
                        });
                        // Los encontrados son removidos para que los sobrandes se envien como items nuevos
                        horariosGroupByIdGrupo.delete(idGrupo);

                    } else if (itemHorario.IdHorario > 0) {
                        // -> Si no existia agrega a horariosEliminados
                        horariosEliminar.push(itemHorario.IdHorario);
                    } else {
                        console.warn("-d", itemHorario, "??")
                    }
                })

                // -> Los items sobrantes se envian como nuevos
                if (horariosGroupByIdGrupo.size > 0) {
                    horariosGroupByIdGrupo.forEach((horarioItems, idGrupo) => {
                        horariosActualizarInsert.push({
                            IdGrupo: idGrupo,
                            Horarios: this.GetHorarioRealFromControlData(horarioItems)
                        });
                    })
                }
                // )

                res = await DataModuloHorarioAlumno._ActualizarHorarioV3(idEscuela, idAlumno, horariosActualizarInsert, horariosEliminar);
                res.Mensaje = UIUtilLang._GetHTTPMessage(res);

            } else {
                // >> Se usa el servicio de NUEVOS horarios, cuando:
                // * El alumno del card no tenía horarios previos
                // * El card tiene una selección multiple de alumnos

                // >> Revisa los horarios anteriores del alumno(2)
                // * Se remueven todas las asignaciones que pudieran tener los alumnos.
                // Incluye las que pudieron llegar (por worker) mientras se estaba Editando

                let alumnosHorariosInfo = this.GetAlumnosEstanInscritos(true);
                let alumnosHorarios = new Map(alumnosHorariosInfo.GruposHorariosValidosMap);
                alumnosHorariosInfo.GruposHorariosNoValidosMap.forEach((h, k) => alumnosHorarios.set(k, h));

                for (let item of alumnosHorarios) {
                    let horariosAlumnos = item[1];
                    if (horariosAlumnos.length > 0) {
                        let idAlumno = item[0];
                        let idEscuelaAsig = horariosAlumnos[0].IdKinder;
                        let horariosEliminar = horariosAlumnos.map(d => d.IdHorario);

                        console.debug("Removiendo asignaciones del alumno...", idAlumno, horariosEliminar)
                        let resAlumno = await DataModuloHorarioAlumno._ActualizarHorarioV3(idEscuelaAsig, idAlumno, [], horariosEliminar);
                        if (resAlumno.Resultado < 0) {
                            console.error("Error al remover asignaciones del alumno ", idAlumno, resAlumno.Resultado, horariosEliminar, resAlumno.Datos);
                            res = resAlumno;
                            break;
                        }
                    }
                }

                if (!res) {
                    let idsAlumnos = this.infoData.Alumnos.map(d => d.IdChild);
                    let gruposToUp: IGrupoHorarioActualizarV2[] = [];
                    let horariosGroupByGrupo = d3Group(horariosResult, d => d.DataExtra.GrupoId);

                    horariosGroupByGrupo.forEach((horarioSemana, idGrupo) => {
                        console.log(idGrupo, horarioSemana);
                        gruposToUp.push({
                            IdGrupo: idGrupo,
                            Horarios: this.GetHorarioRealFromControlData(horarioSemana)
                        });
                    });

                    res = await DataModuloHorarioAlumno._AsignarHorariosV3(idEscuela, idsAlumnos, gruposToUp);
                }
                res.Mensaje = UIUtilLang._GetHTTPMessage(res);
            }

            this.ctrlNotification._Mostrar(res.Mensaje, (res.Resultado > 0 ? "INFO" : "ADVERTENCIA"));
            res.Mensaje = null;

            if (res.Resultado > 0) {
                MainPage._ReloadService(Entidad.CTipoRequest.HorarioAlumno, idEscuela, (requestID, reloadId, error, datos) => {
                    if (this.exitWhenFinished) {
                        setTimeout(() => {
                            resolve(res);
                        }, 2000);
                    } else {
                        resolve(res);
                    }
                    // let alumnosUpdated = this.infoData.Alumnos
                    //     .map(d => {
                    //         return data.modulos.Ninio.DiccNinios.get(d.IdChild);
                    //     })
                    //     .filter(d => {
                    //         if (!d) {
                    //             console.error("En proceso final, un alumno no fue encontrado en el diccionario 😮!!!");
                    //             return false;
                    //         }
                    //         return true;
                    //     })

                    // if (!this.exitWhenFinished) {
                    // // Por ahora éste caso solo entra cuando se está procesando a un solo alumno
                    // if (alumnosUpdated[0]) {
                    //     console.info(datos, data.modulos.Ninio.fn_LOCALDATA_GetGruposHorariosDeAlumno(alumnosUpdated[0].IdChild), "NUEVOS DATOS");
                    //     this.met_UpdateData(alumnosUpdated);
                    // } else {
                    //     this.notificacion.fn_Mostrar(Utils.fn_GetNotificationMessage("general", "fail"), "ADVERTENCIA");
                    // }
                    // super.CARDCOLL_UpdateEditandoCard(false, "onSuccessfullSave");
                    // this.met_SetCardButtonsEnable(true);
                    // this.ctrlProgress.met_Hide();
                    // } else {
                    // >> Verifica que todos los alumnos tengan por lo menos los mismos grupos asignados
                    // let idsGruposControl = Array.from(data.modulos.Ninio.fn_LOCALDATA_GetGruposHorariosDeAlumno(alumnosUpdated[0].IdChild).values()) // Array.from(alumnosUpdated[0].IdGrupos.values())
                    //     .map(d => d.IdGrupo);
                    // let allHaveSameGroups = alumnosUpdated.every((horarioAl, iHA) => {
                    //     if (iHA > 0) {
                    //         let idsHorarios = Array.from(data.modulos.Ninio.fn_LOCALDATA_GetGruposHorariosDeAlumno(horarioAl.IdChild).values()) // horarioAl.IdGrupos.values())
                    //             .map(d => d.IdGrupo);
                    //         return (idsHorarios.every((id, i) => idsGruposControl[i] == id));
                    //     }
                    //     return true;
                    // })

                    // if (!allHaveSameGroups) {
                    //     // FIXME // TEMPORAL tomar otras medidas de respuesta para éste caso
                    //     this.notificacion.fn_Mostrar(Utils.fn_GetNotificationMessage("PanelNinioHorario", "asigalumnosfail"), "REQUIRED");
                    // }

                });
            }
            else {
                resolve(res);
            }
        })
        // return res;
    }
}
