import * as d3 from "d3";
export namespace ScheduleBase {
    export type TID = (number | string);
    // type TTypeStep = "enter" | "update" | "exit";
    export type TUpdateItem = "resize_top" | "resize_bottom" | "move";

    interface IConfigToScheduleBaseBase<TBlockData> {
        Parent?: d3.Selection<HTMLElement, any, any, any>;
        RowsHeaderText?: string;
        /**
         * Valor de inicio del primer row (Limite inicial)
         * @default 0 */
        RowStartValue: number;
        // /** Los valores de reposicionado y redimencionado solo se aplican cuando son multiplos de éste valor
        //  * @default 1
        // */
        // Multiplos_MovementY?: number;
        /** @default "100%" */
        Width?: string;
        /** @default "100%" */
        Height?: string;

        /** Se lanza cuando se crean los ItemBlock (UNO POR INVOCACIÓN) */
        UI_ItemBlock_Template_OnDraw?: (container: TSelectionHTML<"div">, dataItemBlock: IBlockItemControl<TBlockData>, parentContainer: TSelectionHTML<"div">) => void;
        /** Se lanza cuando el datum de un ItemBlock es actualizado */
        UI_ItemBlock_Item_OnUpdate?: (container: TSelectionHTML<"div">, datum: IBlockItemReadOnly<TBlockData>, parentContainer: TSelectionHTML<"div">) => void;
        /** Valida OnUpdatingUI de un ItemBlock antes de aplicarse el reposicionado o redimencionado (UN ITEM POR INVOCACIÓN) */
        UI_ItemBlock_OnValide_OnUpdatingUI?: (valuesChanging: IOnUpdateD, type: TUpdateItem, datum: IBlockItemReadOnly<TBlockData>) => boolean;
        /** Se lanza cuando el ItemBlock es reposicionado o redimencionado (UN ITEM POR INVOCACIÓN)*/
        UI_ItemBlock_ItemUI_OnUpdating?: (container: TSelectionHTML<"div">, datum: IBlockItemReadOnly<TBlockData>, parentContainer: TSelectionHTML<"div">) => void;
        /** Se lanza cuando finaliza el reposicionado o redimencionado del ItemBlock (UN ITEM POR INVOCACIÓN) */
        UI_ItemBlock_Data_OnUpdateEnd?: (container: TSelectionHTML<"div">, datum: IBlockItemReadOnly<TBlockData>) => void;

        UI_ItemBlock_OnUpdateAllItemsEnd?: () => void;

        /** Validar enter de un ItemBlock en una columns: Si retorna true, el item se va a pegar en la columna */
        UI_Column_ItemBlock_OnValideEnter?: (column: IColumnItem, item: IBlockItemReadOnly<TBlockData>) => boolean;
        /** Update de las columnas UI */
        UI_Column_ColumnArea_JOIN_OnUpdate?: (container: TSelectionHTML<"div">, dataColumn: IColumnItem) => void;
        /** Dibujo personalizado del encabezado de columna (UN ITEM POR INVOCACIÓN) */
        UI_Column_ItemHeader_JOIN_OnUpdate?: (container: TSelectionHTML<"div">, dataColumn: IColumnItem) => void;
        /** Seleccion de todos los items de join.Enter */
        UI_Column_ItemHeader_Template_OnDraw?: (container: TSelectionHTML<"div", IColumnItem>) => void;

        /** Dibujo personalizado del encabezado de fila (UN ITEM POR INVOCACIÓN) */
        UI_Row_ItemHeader_JOIN_OnUpdate?: (container: TSelectionHTML<"div", IRowItem>, item: IRowItem) => void;
    }

    export interface IConfigToScheduleBase<TBlockData> extends IConfigToScheduleBaseBase<TBlockData> {
        ColumnsConfig: IColumnItem[];
        RowsConfig: IRowItem[];
    }

    interface IConfigAdvance<TBlockData> extends IConfigToScheduleBaseBase<TBlockData> {
        ColumnsConfig: IColumnItemA[];
        RowsConfig: IRowItemA[];
        /**  */
        RowTotalRange: number;
    }

    export interface IColumnItem {
        Id: TID;
        /** Agrega un label y texto correspondiente */
        Text?: string;
        /** @default true */
        Visible?: boolean;
        /** 50px */
        MinWidth?: TID;
        // BlockRange?: number;
    }

    interface IColumnItemA extends IColumnItem {
    }

    export interface IRowItem {
        /** Agrega un label y texto correspondiente */
        Text?: string;
        Data?: any;
        // MinHeight: string;
        RowRange: number;
    }

    interface IRowItemA extends IRowItem {
        /** Pixeles */
        MinHeight: number;
        /** Porcentaje */
        Height: number;
    }

    // interface IBlockItemBase<TData = any> {
    //     Data?: TData
    //     Id: TID;
    //     IdColumnPositon: TID;
    //     InitRange: number;
    //     EndRange: number;
    // }

    export interface IBlockItem<TData = any> {
        Data?: TData
        Id: TID;
        IdColumnPositon: TID;
        InitRange: number;
        EndRange: number;
        // UI_OnUpdateItem?: (container: SelectionHTML<"div">) => void;
        /** @default true */
        EnableMoveTopDown?: boolean;
        /** @default true */
        EnableMoveLeftRight?: boolean;
        /** @default true */
        EnableResizing?: boolean;
    }

    export type IBlockItemReadOnly<TBlockData = any> = {
        readonly [k in keyof IBlockItem]: IBlockItem<TBlockData>[k];
    }

    interface IBlockItemControl<TData> extends IBlockItem<TData> {
        UI_Item: TSelectionHTML<"div", IBlockItemControl<TData>>;
    }

    export interface IOnUpdateD {
        Init: number;
        End: number;
    }

    const areaSchedule_MinHeight = 500;

    export class ScheduleBase<TBlockData = any> {
        private controlContainer: TSelectionHTML<"div">;
        private currentBlockMoving: IBlockItemControl<TBlockData>;

        protected config: IConfigAdvance<TBlockData>;
        protected scheduleItems: Map<TID, IBlockItemControl<TBlockData>>;

        constructor() { }

        private UI_Init() {
            this.controlContainer = d3.create("div")
                .attr("class", "schedule_container")
                .style("width", this.config.Width)
                .style("height", this.config.Height);

            // -> Template base

            this.controlContainer.html(`
                    <div class="schedule_headers">
                        <div class="rows_header">
                            <label>${this.config.RowsHeaderText}</label>
                        </div>
                        <div class="columns_header"></div>
                    </div>

                    <div class="schedule_body">
                        <div class="rows_aux"></div>
                        <div class="rows_cont"></div>
                        <div class="columns_cont"></div>
                    </div>
                    `);
            // -> 

            this.UI_UpdateColumnsAreas();
            this.UI_UpdateRowsAreas();
            this.UI_UpdateBlockItems();

            this.config.Parent?.append(() => this.controlContainer.node());
        }

        private UI_UpdateItemHeaderBase(d: IColumnItemA | IRowItemA, itemCont: TSelectionHTML<"div">) {
            let lblHead = itemCont.select(".lbl_text");

            if (d.Text) {
                if (!lblHead.node()) {
                    lblHead = itemCont.append("label").classed("lbl_text", true);
                }
                lblHead.text(d.Text);
            } else if (lblHead.node()) {
                lblHead.remove();
            }
        }

        private UI_UpdateColumnsAreas() {
            // -> Update Headers

            const UpdateItem = (item: TSelectionHTML<"div", IColumnItemA>) => {
                item.each((d, i, items) => {
                    let itemCont = d3.select(items[i]) as TSelectionHTML<"div", IColumnItemA>;
                    this.UI_UpdateItemHeaderBase(d, itemCont);

                    itemCont.classed("hide", !d.Visible)

                    if (this.config.UI_Column_ItemHeader_JOIN_OnUpdate) {
                        this.config.UI_Column_ItemHeader_JOIN_OnUpdate(itemCont, d);
                    }
                })
                return item;
            }

            this.controlContainer.select(".schedule_headers").select(".columns_header")
                .selectAll<HTMLDivElement, IColumnItemA>(":scope > div")
                .data(this.config.ColumnsConfig)
                .join(
                    enter => {
                        let item = enter.append("div");
                        if (this.config.UI_Column_ItemHeader_Template_OnDraw) {
                            this.config.UI_Column_ItemHeader_Template_OnDraw(item);
                        }
                        return UpdateItem(item);
                    },
                    update => UpdateItem(update),
                    exit => exit.remove()
                )

            // -> Update columns containers

            const UpdateColArea = (item: TSelectionHTML<"div", IColumnItemA>) => {
                item.each((d, i, colsAreas) => {
                    if (this.config.UI_Column_ColumnArea_JOIN_OnUpdate) {
                        this.config.UI_Column_ColumnArea_JOIN_OnUpdate(d3.select(colsAreas[i]), d);
                    }
                    colsAreas[i].id = "col_" + d.Id;
                    this.controlContainer.select(".schedule_body").select(".columns_cont").select(`#col_${d.Id}`).classed("hide", !d.Visible);
                    if (d.Visible) {
                        colsAreas[i].onmouseenter = (e) => {
                            if (this.currentBlockMoving && this.currentBlockMoving.UI_Item.node()) {
                                if (this.config.UI_Column_ItemBlock_OnValideEnter && !this.config.UI_Column_ItemBlock_OnValideEnter(d, this.currentBlockMoving)) {
                                    return;
                                }
                                this.currentBlockMoving.IdColumnPositon = d.Id;
                                d3.select(colsAreas[i]).append(() => this.currentBlockMoving.UI_Item.node());
                            }
                        }
                    } else {
                        colsAreas[i].onmouseenter = null;
                    }
                })
                return item;
            }

            this.controlContainer.select(".schedule_body").select(".columns_cont")
                .selectAll<HTMLDivElement, IColumnItemA>(":scope > div")
                .data(this.config.ColumnsConfig)
                .join(
                    enter => UpdateColArea(enter.append("div")),
                    update => UpdateColArea(update),
                    exit => exit.remove()
                )
        }

        private UI_UpdateRowsAreas() {
            const UpdateItem = (item: TSelectionHTML<"div", IRowItemA>) => {
                return item.each((d, i, items) => {
                    let itemCont = (d3.select(items[i]) as TSelectionHTML<"div", IRowItemA>)
                        .style("height", d.Height + "%")
                        .style("min-height", d.MinHeight + "px");

                    this.UI_UpdateItemHeaderBase(d, itemCont);

                    if (this.config.UI_Row_ItemHeader_JOIN_OnUpdate) {
                        this.config.UI_Row_ItemHeader_JOIN_OnUpdate(itemCont, d);
                    }
                })
            }
            const UpdateRowLine = (item: TSelectionHTML<"hr", IRowItemA>) => {
                return item.each((d, i, items) => {
                    const hrElement: HTMLHRElement = items[i];

                    let topT = 0;
                    for (let dR of this.config.RowsConfig) {
                        topT += dR.Height;
                        if (d == dR) break;
                    }
                    // NOTE -1px para que no aparezca el scroll vertical
                    hrElement.style.top = `calc(${topT}% - 1px)`;
                })
            }

            let totalMinHeight = 0;
            this.config.RowsConfig.forEach(d => {
                totalMinHeight += d.MinHeight;
            })

            this.controlContainer
                .select(".columns_cont")
                .style("min-height", totalMinHeight + "px")

            this.controlContainer
                .select(".rows_aux")
                .style("min-height", totalMinHeight + "px")
                .selectAll<HTMLHRElement, IRowItemA>(":scope > hr")
                .data(this.config.RowsConfig)
                .join(
                    enter => UpdateRowLine(enter.append("hr")),
                    update => UpdateRowLine(update),
                    exit => exit.remove()
                )

            this.controlContainer.select(".rows_cont")
                .selectAll<HTMLDivElement, IRowItemA>(":scope > div")
                .data(this.config.RowsConfig)
                .join(
                    enter => UpdateItem(enter.append("div")),
                    update => UpdateItem(update),
                    exit => exit.remove()
                )
        }

        // private UI_UpdateScheduleItems_FAIL() {
        // -> // NOTE Update Positions fail :(
        // Problemas para hacerlo dentro del join, todos los elementos deben tener el mismo padre.
        // El parent de los elementos seleccionados se actualiza al elemento inmediato del que surge la seleccion de childs (parent.selectAll())
        //     let container = this.controlContainer.select(".schedule_body").select<HTMLDivElement>(".columns_cont");
        //     container
        //         .selectAll<HTMLDivElement, IBlockItemA>(".schedule_item_range")
        //         .data(Array.from(this.scheduleItems.values()))
        //         .join(
        //             enter => {
        //                 // let items = enter.append("div")
        //                 //     .attr("class", "schedule_item_range hide");

        //                 // items.append("div").attr("class", "resizer").attr("pos", "top");
        //                 // items.append("div").attr("class", "content_cont");
        //                 // items.append("div").attr("class", "resizer").attr("pos", "bottom");

        //                 // if (this.config.UI_ItemBlock_OnDrawTemplate) {
        //                 //     this.config.UI_ItemBlock_OnDrawTemplate(items.select(":scope > .content_cont"), items);
        //                 // }

        //                 return this.UI_UpdateEachScheduleItem(items, container);
        //             },
        //             update => this.UI_UpdateEachScheduleItem(update, container),
        //             // exit => this.UI_UpdateEachScheduleItem(exit, container).style("opacity", "1")
        //             //     .transition()
        //             //     .duration(400)
        //             //     .style("opacity", "0")
        //             //     .remove()
        //         )
        // }

        // private UI_UpdateEachScheduleItem(items: SelectionHTML<"div", IBlockItemA>, containerGrid: SelectionHTML<"div">) {
        //     return items.each((datum, i, items) => {
        //         let item = d3.select(items[i]) as SelectionHTML<"div", IBlockItemA>;
        //         this.UI_UpdateAScheduleItem(item, datum, containerGrid);
        //         // console.log(item.node())
        //     })
        // }

        private UI_UpdateBlockItems(blockItems?: Map<TID, IBlockItemControl<TBlockData>>) {
            if (!blockItems) {
                blockItems = this.scheduleItems;
            }
            blockItems.forEach((datum) => {
                if (!datum.UI_Item) {
                    datum.UI_Item = d3.create("div")
                        .attr("class", "schedule_item_range hide");

                    datum.UI_Item.append("div").attr("class", "content_cont");
                    datum.UI_Item.append("div").attr("class", "resizer").attr("pos", "top");
                    datum.UI_Item.append("div").attr("class", "resizer").attr("pos", "bottom");
                    datum.UI_Item.append("div")
                        .attr("class", "r_options")

                    if (this.config.UI_ItemBlock_Template_OnDraw) {
                        this.config.UI_ItemBlock_Template_OnDraw(datum.UI_Item.select(":scope > .content_cont"), datum, datum.UI_Item);
                    }
                }

                datum.UI_Item.datum(datum);
                this.UI_UpdateABlockItem(datum.UI_Item, datum);
            })

            this.UI_UpdateIntersections();
        }

        private UI_DeleteEachItem(...itemsToDelete: IBlockItemControl<TBlockData>[]) {
            itemsToDelete.forEach(item => {
                // console.log(item.Id, item.IdColumnPositon, item.UI_Item.node(), "To delete");
                item.UI_Item// ?.style("opacity", "1")
                    // .transition()
                    // .duration(200)
                    // .style("opacity", "0")
                    .remove()
            });

            this.UI_UpdateIntersections();
        }

        private UI_UpdateIntersections() {
            if (this.config.UI_ItemBlock_OnUpdateAllItemsEnd) {
                this.config.UI_ItemBlock_OnUpdateAllItemsEnd();
            }
        }

        /**
         * item <class = "schedule_item_range">
         */
        protected UI_UpdateABlockItem(item: TSelectionHTML<"div">, datum: IBlockItemControl<TBlockData>, containerGrid?: d3.Selection<HTMLDivElement, any, any, any>) {
            if (!containerGrid) {
                containerGrid = this.controlContainer.select<HTMLDivElement>(".schedule_body>.columns_cont")
            }
            // item.each((datum, i, items) => {
            // datum.UI_Item = item;

            // NOTE Ideal, posicionar item aquí :(
            let columnContainer = containerGrid.select<HTMLDivElement>("#col_" + datum.IdColumnPositon);
            // let item = datum.UI_Item;

            if (columnContainer.node()) {
                item.classed("hide", false);
                this.UI_BlockItemUpdate_PositionTopDims(item, datum);

                columnContainer.append(() => item.node());
            } else {
                item.classed("hide", true);
            }

            // console.log(datum.Id, datum.IdColumnPositon, columnContainer.node(), "Datum")

            if (this.config.UI_ItemBlock_Item_OnUpdate) {
                this.config.UI_ItemBlock_Item_OnUpdate(item.select(".content_cont"), datum, item);
            }

            this.UI_BlockItemDragHandler(datum, item, containerGrid);
            return item;
        }

        private UI_BlockItemUpdate_PositionTopDims(item: TSelectionHTML<"div">, datum: IBlockItemControl<TBlockData>) {
            let starRowsValue = this.config.RowStartValue;
            item.style("top", this.DAT_GetUIPercent_ByNumber(datum.InitRange - starRowsValue) + "%")
                .style("height", this.DAT_GetUIPercent_ByNumber(datum.EndRange - starRowsValue) - this.DAT_GetUIPercent_ByNumber(datum.InitRange - starRowsValue) + "%");
        }

        private UI_BlockItemDragHandler(datum: IBlockItemControl<TBlockData>, item: TSelectionHTML<"div", any>, containerGrid: TSelectionHTML<"div">) {
            let content = item.select<HTMLDivElement>(":scope > .content_cont");
            let itemContent = item.select<HTMLDivElement>(":scope > .content_cont");
            let resizerTop = item.select<HTMLDivElement>(':scope > .resizer[pos="top"]')
                .classed("hide", !datum.EnableResizing)
                .node();
            let resizerBottom = item.select<HTMLDivElement>(':scope > .resizer[pos="bottom"]')
                .classed("hide", !datum.EnableResizing)
                .node();

            const ResizerHandler = (resizerArea: HTMLDivElement, pos: "top" | "bottom") => {
                if (datum.EnableResizing) {
                    if (resizerArea) {
                        resizerArea.style.cursor = "ns-resize";
                        resizerArea.onpointerdown = (evAreaResizer) => {
                            if (evAreaResizer.button == 0) { // NOTE Solo click izquierdo
                                let valuesAux: IOnUpdateD = {
                                    Init: datum.InitRange,
                                    End: datum.EndRange
                                }
                                // d3.select(document.body).style("cursor", "ns-resize", "important");

                                let valueTop = item.node().offsetTop;
                                let valueH = item.node().getBoundingClientRect().height; //.offsetHeight;
                                item.raise(); // .node().style.zIndex = "1";

                                // console.info("onpointerdown ->", datum.InitRange, datum.EndRange);

                                document.onpointermove = (evCont) => {
                                    evCont.preventDefault();
                                    let fn_Validator = this.config.UI_ItemBlock_OnValide_OnUpdatingUI;

                                    switch (pos) {
                                        case "top":
                                            valueTop += evCont.movementY;
                                            valueH -= evCont.movementY;

                                            valuesAux.Init = this.DAT_GetUnitValueFromPxValue(valueTop, containerGrid.node());
                                            valuesAux.End = this.DAT_GetUnitValueFromPxValue(valueTop + valueH, containerGrid.node());

                                            if ((valuesAux.Init < valuesAux.End) && (!fn_Validator || fn_Validator(valuesAux, "resize_top", datum))) {
                                                datum.InitRange = valuesAux.Init;
                                                datum.EndRange = valuesAux.End;
                                                item.node().style.top = valueTop + "px";
                                                item.node().style.height = valueH + "px";
                                            }
                                            break;
                                        case "bottom":
                                            valueH += evCont.movementY;
                                            valuesAux.End = this.DAT_GetUnitValueFromPxValue(valueTop + valueH, containerGrid.node());

                                            if ((valuesAux.Init < valuesAux.End) && (!fn_Validator || fn_Validator(valuesAux, "resize_bottom", datum))) {
                                                datum.InitRange = valuesAux.Init;
                                                datum.EndRange = valuesAux.End;
                                                item.node().style.height = valueH + "px";
                                            }
                                            // if (valueH % this.config.Multiplos_MovementY != 0) {
                                            // return;
                                            // }
                                            break;
                                    }

                                    // this.UI_UpdateBlockItem_Datum(valueTop, valueH, containerGrid.node(), datum);

                                    if (this.config.UI_ItemBlock_ItemUI_OnUpdating) {
                                        this.config.UI_ItemBlock_ItemUI_OnUpdating(itemContent, datum, item);
                                    }
                                    // console.info("onpointermove ->", datum.InitRange, datum.EndRange);
                                }

                                document.onpointerup = (evCont) => {
                                    document.onpointerup = null;
                                    document.onpointermove = null;
                                    // d3.select(document.body).style("cursor", null);
                                    // item.node().style.zIndex = null;
                                    this.UI_BlockItemUpdate_PositionTopDims(item, datum);
                                    if (this.config.UI_ItemBlock_Data_OnUpdateEnd) {
                                        this.config.UI_ItemBlock_Data_OnUpdateEnd(itemContent, datum);
                                    }
                                    // console.info("onpointerup ->", valueTop, valueH)
                                }
                            }
                        }
                    }
                } else if (resizerArea) {
                    resizerArea.onpointerdown = null;
                    resizerArea.style.cursor = null;
                }
            }

            if (datum.EnableMoveTopDown) {
                content.node().style.cursor = "pointer";
                content.node().onpointerdown = (eBlock) => {
                    if (eBlock.button == 0) { // NOTE Solo click izquierdo
                        let valuesAux: IOnUpdateD = {
                            Init: datum.InitRange,
                            End: datum.EndRange
                        }
                        const valueRange = datum.EndRange - valuesAux.Init;
                        // d3.select(document.body).style("cursor", "move", "important");

                        let valuePxTop = item.node().offsetTop;
                        // let valuePxH = item.node().offsetHeight;
                        item.raise(); // .node().style.zIndex = "1";

                        if (datum.EnableMoveLeftRight) { // FIXME solo si EnableMoveTopDown = true (-_-)
                            this.currentBlockMoving = datum;
                        }
                        // console.info("onpointerdown ->", datum.InitRange, datum.EndRange);

                        document.onpointermove = (evCont) => {
                            valuePxTop += evCont.movementY;

                            // console.log(valuePxTop);
                            valuesAux.Init = this.DAT_GetUnitValueFromPxValue(valuePxTop, containerGrid.node());

                            let fn_Validator = this.config.UI_ItemBlock_OnValide_OnUpdatingUI;
                            if (!fn_Validator || fn_Validator(valuesAux, "move", datum)) {
                                item.node().style.top = valuePxTop + "px";

                                valuesAux.End = valuesAux.Init + valueRange;

                                datum.InitRange = valuesAux.Init;
                                datum.EndRange = valuesAux.End;

                                if (this.config.UI_ItemBlock_ItemUI_OnUpdating) {
                                    this.config.UI_ItemBlock_ItemUI_OnUpdating(itemContent, datum, item);
                                }
                            }
                            // console.info("onpointermove ->", datum.InitRange, datum.EndRange);
                        }

                        document.onpointerup = (evCont) => {
                            this.currentBlockMoving = null;
                            document.onpointerup = null;
                            document.onpointermove = null;
                            // d3.select(document.body).style("cursor", null);
                            // item.node().style.zIndex = null;
                            this.UI_BlockItemUpdate_PositionTopDims(item, datum);
                            if (this.config.UI_ItemBlock_Data_OnUpdateEnd) {
                                this.config.UI_ItemBlock_Data_OnUpdateEnd(itemContent, datum);
                            }
                        }
                    }
                }
            } else {
                content.node().onpointerdown = null;
                content.node().style.cursor = null;
            }

            ResizerHandler(resizerTop, "top");
            ResizerHandler(resizerBottom, "bottom");
        }

        // *********************************************************************************
        // DATA HANDLERS
        // *********************************************************************************

        private DAT_Init(config: IConfigToScheduleBaseBase<TBlockData>) {
            this.config = {
                ...{
                    RowsHeaderText: "",
                    RowStartValue: 0,
                    Multiplos_MovementY: 1
                    // Width: "100%",
                    // Height: "100%"
                },
                ...config as any
            }
            this.scheduleItems = new Map();

            this.DAT_ColumnConfig_SetDefaults(this.config.ColumnsConfig);
            this.DAT_RowConfig_SetDefaults(this.config.RowsConfig);
        }

        private DAT_ColumnConfig_SetDefaults(columnsConfig: IColumnItem[]) {
            this.config.ColumnsConfig = columnsConfig.map<IColumnItemA>(item => {
                let itemA: IColumnItemA = {
                    ...{
                        Visible: true,
                        MinWidth: "50px"
                    },
                    ...item
                }
                return itemA;
            })
        }

        private DAT_RowConfig_SetDefaults(rowsConfig: IRowItem[]) {
            this.config.RowTotalRange = 0;

            rowsConfig.forEach(d => {
                this.config.RowTotalRange += d.RowRange;
            })

            this.config.RowsConfig = rowsConfig.map<IRowItemA>(item => {
                let itemA: IRowItemA = {
                    ...{
                        Height: this.DAT_GetUIPercent_ByNumber(item.RowRange),
                        MinHeight: this.DAT_GetUIPercent_ByNumber(item.RowRange, areaSchedule_MinHeight) * 2
                    },
                    ...item
                }
                return itemA;
            })
        }

        private DAT_BlockConfig_SetDefaults(blockConfig: IBlockItem[]) {
            let newItems: Map<TID, IBlockItemControl<TBlockData>> = new Map();
            blockConfig.forEach(item => {
                let newItem: IBlockItemControl<TBlockData> = {
                    ...{
                        EnableMoveTopDown: true,
                        EnableMoveLeftRight: true,
                        EnableResizing: true
                    },
                    ...item as IBlockItemControl<TBlockData>
                }
                this.scheduleItems.set(item.Id, newItem);
                newItems.set(newItem.Id, newItem);
            })
            return newItems;
        }

        /** Retorna un porcentaje para UI a partir del valor real del dato */
        private DAT_GetUIPercent_ByNumber(number: number, totalRange?: number) {
            if (totalRange == null) {
                totalRange = this.config.RowTotalRange;
            }
            return 100 * (number) / totalRange;
        }

        /**
         * Retorna la unidades reales un porcentaje representado en la vista (dentro del rango)
         * @param percent 0, ..., 100
         */
        private DAT_GetUnitValue_ByUIPercent(percent: number, totalRange?: number) {
            if (totalRange == null) {
                totalRange = this.config.RowTotalRange;
            }
            return (totalRange / (100 / percent)) + this.config.RowStartValue;
        }

        // private UI_GetUnitValueFromPxValue(valuePx: number, valueHeight: number, parentGrid: HTMLDivElement, datum: IBlockItemA) {
        /** Traduce una posición en UI a las unidades reales dentro del rango */
        private DAT_GetUnitValueFromPxValue(valuePx: number, parentGrid?: HTMLDivElement) {
            if (parentGrid == null) {
                parentGrid = this.controlContainer.select<HTMLDivElement>(".columns_cont").node();
            }
            let pTop = this.DAT_GetUIPercent_ByNumber(valuePx, parentGrid.offsetHeight);
            // let pHeight = this.DAT_GetUIPercent_ByNumber(valueHeight, parentGrid.offsetHeight);
            return this.DAT_GetUnitValue_ByUIPercent(pTop);
            // datum.InitRange = this.DAT_GetDATNumber_ByUIPercent(pTop);
            // datum.EndRange = this.DAT_GetDATNumber_ByUIPercent(pTop + pHeight);
        }

        // *********************************************************************************
        // PROTECTED PROPERTIES & METHODS
        // *********************************************************************************

        protected SCHEDULE_GetUnitValue_FromPercentUI(value: number, totalRange?: number) {
            return this.DAT_GetUnitValue_ByUIPercent(value, totalRange);
        }

        protected SCHEDULE_GetUnitValue_FromPxValue(value: number) {
            let containerGrid = this.controlContainer
                .select<HTMLDivElement>(":scope > .schedule_body > .columns_cont")
                .node();
            return this.DAT_GetUnitValueFromPxValue(value, containerGrid);
        }

        public SCHEDULE_GetUIPercent_ByUnitNumber(value: number, totalRange?: number) {
            return this.DAT_GetUIPercent_ByNumber(value, totalRange);
        }

        protected SCHEDULE_UpdateColumns(columnsConfig?: IColumnItem[]) {
            if (columnsConfig == null) {
                columnsConfig = this.config.ColumnsConfig
            }
            this.DAT_ColumnConfig_SetDefaults(columnsConfig);

            // DOTEST
            this.UI_UpdateColumnsAreas();
        }

        protected SCHEDULE_UpdateRows(rowsConfig?: IRowItem[]) {
            if (rowsConfig == null) {
                rowsConfig = this.config.RowsConfig;
            }
            this.DAT_RowConfig_SetDefaults(rowsConfig);

            // DOTEST
            this.UI_UpdateRowsAreas();
            // this.UI_UpdateScheduleItems();
        }

        protected SCHEDULE_SetRowStarValue(start: number) {
            this.config.RowStartValue = start;

            this.UI_UpdateRowsAreas();
        }

        protected SCHEDULE_GetItemsFilterByIdColumn(idColumn: TID) {
            return Array.from(this.scheduleItems.values())
                .filter(d => d.IdColumnPositon == idColumn);
        }

        /** Agrega o actualiza */
        protected SCHEDULE_SetScheduleItems(...scheduleItems: IBlockItem<TBlockData>[]) {
            let newItems = this.DAT_BlockConfig_SetDefaults(scheduleItems);
            this.UI_UpdateBlockItems(newItems);
            // console.info(this.scheduleItems, "items")
            return this;
        }

        protected SCHEDULE_DeleteSheduleItem(...ids: (number | string)[]) {
            let itemsToDelete: IBlockItemControl<TBlockData>[] = [];
            ids.forEach(id => {
                let item = this.scheduleItems.get(id);
                if (item) {
                    itemsToDelete.push(item);
                    this.scheduleItems.delete(id);
                    // this.UI_UpdateScheduleItems();
                }
            })
            if (itemsToDelete.length > 0) {
                this.UI_DeleteEachItem(...itemsToDelete);
            }
        }

        protected SCHEDULE_DeleteAllSheduleItem() {
            this.UI_DeleteEachItem(...Array.from(this.scheduleItems.values()));
            this.scheduleItems.clear();
            // this.UI_UpdateScheduleItems();
        }

        protected SCHEDULE_InitConfig(config: IConfigToScheduleBase<TBlockData>) {
            this.DAT_Init(config);
            this.UI_Init();
        }

        protected get _SCHEDULE_RowStarValue(): number {
            return this.config.RowStartValue;
        }

        protected get _SCHEDULE_RowRangeEndValue(): number {
            return this.config.RowStartValue + this.config.RowTotalRange;
        }

        protected get _SCHEDULE_RowTotalRangeValue(): number {
            return this.config.RowTotalRange;
        }

        // *********************************************************************************
        // PUBLIC PROPERTIES & METHODS
        // *********************************************************************************

        public get _controlContainer() {
            return this.controlContainer;
        }
    }
}
