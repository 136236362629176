import { HTMLTooltipComponent } from "../controlWC/TooltipComponent";
import { select } from "d3";

export namespace UIUtilElementBehaviors {
    export function _EllipsisTextTooltip(textElement: HTMLElement, position: "button" | "top" = "button", getText?: (textElement: HTMLElement) => string, tooltipDefault?: HTMLTooltipComponent) {
        if (!textElement) {
            return;
        }
        if (tooltipDefault) {
            if (!textElement["__tooltipDefault"]) {
                textElement["__tooltipDefault"] = tooltipDefault;
            } else if (tooltipDefault !== textElement["__tooltipDefault"]) {
                return;
            }
        }

        const tooltipDefaultExists = (textElement["__tooltipDefault"] != null);

        // type TEv = (this: HTMLElement, ev: MouseEvent) => any;

        if (textElement["__callbackEllipsisTextTooltip"]) {
            textElement.removeEventListener("mouseenter", textElement["__callbackEllipsisTextTooltip"]);
        }

        const hideTooltip = (tooltipTextComplet: HTMLTooltipComponent) => {
            if (!tooltipDefaultExists)
                tooltipTextComplet?.remove();
            else {
                tooltipTextComplet._Visible = false;
                tooltipTextComplet._RemoveObservedELements(textElement)
            }
        }

        const getTextFinal = () => (getText ? getText(textElement) : textElement.innerText);

        textElement["__callbackEllipsisTextTooltip"] = (e: MouseEvent) => {
            let tooltipTextComplet: HTMLTooltipComponent = tooltipDefaultExists
                ? textElement["__tooltipDefault"]
                : textElement.querySelector<HTMLTooltipComponent>(":scope > .tooltip_txtcontent");
            if (textElement.scrollWidth > textElement.offsetWidth) {
                if (!tooltipTextComplet) {
                    tooltipTextComplet = select(textElement).append<HTMLTooltipComponent>("wc-tooltip")
                        .attr("class", "tooltip_txtcontent")
                        .attr("position", position)
                        .attr("max-width", "800px")
                        .style("display", "flex")
                        .text(getTextFinal())
                        .node()
                } else if (tooltipDefaultExists) {
                    tooltipTextComplet._SetObservedElementsAdvanced({
                        Target: textElement,
                        Text: getTextFinal()
                    })
                }
                tooltipTextComplet._Visible = true;
                textElement.addEventListener("mouseleave", e => {
                    hideTooltip(tooltipTextComplet)
                })
            }
            else
                hideTooltip(tooltipTextComplet)
        }
        textElement.addEventListener("mouseenter", textElement["__callbackEllipsisTextTooltip"])
    }
}
