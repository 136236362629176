import { create } from "d3";
import DataModuloSAT from "../../data/modulo/SAT";
import { HTMLSpinnerElement } from "../controlWC/SpinnerComponent";
import { UIUtilGeneral } from "./Util";
import { DataUtilAlertBot } from "../../data/util/AlertBot";
import { UIUtilFormat } from "./Format";
import { xml2js, Element as XML_Element } from "xml-js";

export namespace UIUtilCFDI {
    export function _CFDIFileToHTML(file: File, container?: TSelectionHTML<"htmlelement">): Promise<TSelectionHTML<"htmlelement">> {
        return ProcesaStringCFDI_ByFile(file)
            .then(stringXML => _CFDIStringToHTML(stringXML, container))
            .catch(() => null);
    }

    /**
     * @returns
     * `<div class="cfdi_view">`
     */
    export function _CFDIStringToHTML(xml: string, container?: TSelectionHTML<"htmlelement">) {
        let controlWrapper: TSelectionHTML<"div">;
        if (!container) {
            controlWrapper = create("div").attr("class", "cfdi_view")
        } else {
            controlWrapper = container.select<HTMLDivElement>(":scope > .cfdi_view");
            if (!controlWrapper.node()) {
                controlWrapper = container.append("div")
                    .attr("class", "cfdi_view")
            } else {
                controlWrapper.selectAll(":scope > *").remove();
            }
        }
        try {
            const COMPROBANTE = _ProcesaComprobanteCFDI_ByStringV2(xml);
            const { Emisor, Receptor, Conceptos, Impuestos } = COMPROBANTE.Sequence;
            // const Impuestos = Comprobante.elems.Impuestos as any as CFDIElementImpuestos;
            const [ComplementoTimbreFiscalDigital, ComplementosExtra] = (() => {
                type TComplemento = typeof COMPROBANTE.Sequence.Complemento.Childs[number];
                const complementos = (COMPROBANTE.Sequence.Complemento?.Childs);
                let compTimbradoDigita: TComplemento;
                let complementosExtra: TComplemento[] = [];
                complementos?.forEach(d => {
                    if (d.Name == "TimbreFiscalDigital") compTimbradoDigita = d;
                    else complementosExtra.push(d)
                });
                return [compTimbradoDigita, complementosExtra];
            })()
            // complementos] = Complemento?.Childs;

            setTimeout(async () => {
                // console.debug(Comprobante)
                controlWrapper
                    .append<HTMLSpinnerElement>("wc-spinner")
                    .attr("center", "")
                    .attr("dim", "40")
                    .attr("border-width", "3")
                await DataModuloSAT._GetCatalogoV4("UsoCFDI")
                await DataModuloSAT._GetCatalogoV4("TipoDeComprobante")
                await DataModuloSAT._GetCatalogoV4("RegimenFiscal")
                await DataModuloSAT._GetCatalogoV4("Exportacion")
                await DataModuloSAT._GetCatalogoV4("FormaPago")
                await DataModuloSAT._GetCatalogoV4("MetodoPago")

                controlWrapper
                    .call((c) => c.select("wc-spinner").remove())
                    .style("padding", "var(--padding1)")
                    .append("div")
                    .classed(UIUtilGeneral.FBoxOrientation.Horizontal, true)
                    .call(AplicaEstiloSeccion)
                    .call(async (topContainer) => {
                        // left
                        topContainer.append("div")
                            .call(AplicaEstiloSeccionLabels)
                            .html(`<b>Emisor</b>`
                                + KeyAndValue("RFC", Emisor.Attrs.Rfc, 5)
                                + KeyAndValue("Nombre", Emisor.Attrs.Nombre, 5)
                                + `<b>Receptor</b>`
                                + KeyAndValue("RFC", Receptor.Attrs.Rfc, 5)
                                + KeyAndValue("Nombre", Receptor.Attrs.Nombre, 5)
                                + KeyAndValue("Código postal", Receptor.Attrs.DomicilioFiscalReceptor, 5)
                                // + KeyAndValue("Régimen fiscal", await Data.Modulo.SAT.fn_GetCatalogoValue("RegimenFiscal", Receptor.attr.RegimenFiscalReceptor), 5)
                                + KeyAndValue("Uso CFDI", await DataModuloSAT._GetCatalogoV4Value("UsoCFDI", Receptor.Attrs.UsoCFDI), 5)
                            )

                        // right
                        topContainer.append("div")
                            .call(AplicaEstiloSeccionLabels)
                            .html(KeyAndValue("Folio fiscal", ComplementoTimbreFiscalDigital?.Attrs.UUID)
                                + KeyAndValue("No. de serie del CSD", COMPROBANTE.Attrs.NoCertificado)
                                + KeyAndValue("Código postal, fecha y hora de emisión", (COMPROBANTE.Attrs.LugarExpedicion || "") + " " + (COMPROBANTE.Attrs.Fecha?.replace("T", " ")))
                                + KeyAndValue("Efecto de comprobante", await DataModuloSAT._GetCatalogoV4Value("TipoDeComprobante", COMPROBANTE.Attrs.TipoDeComprobante))
                                + KeyAndValue("Régimen fiscal", await DataModuloSAT._GetCatalogoV4Value("RegimenFiscal", Emisor.Attrs.RegimenFiscal))
                                // + KeyAndValue("Régimen fiscal", Emisor.attr.Nombre)
                                + KeyAndValue("Exportación", await DataModuloSAT._GetCatalogoV4Value("Exportacion", COMPROBANTE.Attrs.Exportacion))
                            )
                    })

                // container.append("pre").html(JSON.stringify(obj, null, 4))

                const catImpuesto = new Map((await DataModuloSAT._GetCatalogoV4("Impuesto")).map(d => [d.id, d]));
                controlWrapper.append("table")
                    .call(AplicaEstiloSeccion)
                    .style("min-width", "100%")
                    .call(table => {
                        table.append("thead")
                            .html(`
                        <tr>
                            <th>C.Serv.Prod</th>
                            <th class="text_right">Cant.</th>
                            <th>Unidad</th>
                            <th>Descripción</th>
                            <th class="text_right">Precio unitario</th>
                            <th class="text_right">Importe</th>
                        </tr>
                    `)

                        table.append("tbody")
                            .call(tbody => Conceptos.Childs.forEach(concepto => {
                                tbody.append("tr")
                                    .html(`
                                    <td>${concepto.Attrs.ClaveProdServ || ""}</td>
                                    <td class="text_right">${concepto.Attrs.Cantidad}</td>
                                    <td>${concepto.Attrs.Unidad || ""}</td>
                                    <td style="min-width:400px;">
                                    ${concepto.Attrs.Descripcion + (() => {
                                            let str = "";
                                            // type IImto = (CFDIV4.IConceptoImptoRetencionAttrs | CFDIV4.IConceptoImptoTrasladoAttrs) & { _Tipo: string }
                                            const impuestos = [
                                                ...(concepto.Sequence.Impuestos?.Sequence.Retenciones?.Childs.map(d => ({ ...d, _Tipo: "Ret." })) || []),
                                                ...(concepto.Sequence.Impuestos?.Sequence.Traslados?.Childs.map(d => ({ ...d, _Tipo: "Trdo." })) || [])
                                            ].filter(d => d.Attrs.Importe != "0");
                                            if (concepto.Attrs.Descuento) str += "<br><b>Descuento</b>: " + currencyFmt(concepto.Attrs.Descuento);
                                            if (concepto.Sequence.CuentaPredial) str += "<br><b>Cuenta predial</b>: " + concepto.Sequence.CuentaPredial.Attrs.Numero;
                                            // if (concepto.Sequence.ACuentaTerceros) str += "<br><b>Complemento concepto</b>: " + concepto.Sequence.ACuentaTerceros.Attrs.NombreACuentaTerceros;
                                            // if (concepto.Sequence.ComplementoConcepto) str += "<br><b>Cuenta predial</b>: " + concepto.Sequence.ComplementoConcepto;
                                            // if (concepto.Sequence.Parte) str += "<br><b>Info. aduanera</b>: " + concepto.Sequence.Parte;
                                            if (concepto.Sequence.InformacionAduanera) str += "<br><b>Info. aduanera</b>: " + concepto.Sequence.InformacionAduanera.Attrs.NumeroPedimento;
                                            if (impuestos.length) {
                                                str += '<br><div class="impuestos_header"><b>Impuestos</b></div>';
                                                str += `<table>
                                                        <thead><tr>
                                                            <th class="text_right">Base</th>
                                                            <th>Impuesto</th>
                                                            <th>Tipo fact.</th>
                                                            <th class="text_right">Tasa o cuota</th>
                                                            <th class="text_right">Importe</th>
                                                        </tr></thead>
                                                        <tbody>
                                                        ${impuestos.reduce((res, d) => res + `<tr>
                                                            <td class="text_right">${currencyFmt(d.Attrs.Base)}</td>
                                                            <td>${catImpuesto.get(d.Attrs.Impuesto as any).descripcion}</td>
                                                            <td>${d.Attrs.TipoFactor}</td>
                                                            <td class="text_right">${d.Attrs.TasaOCuota}</td>
                                                            <td class="text_right">${currencyFmt(d.Attrs.Importe)}</td>
                                                            </tr>`, "")}
                                                        </tbody>
                                                        </table>`
                                            }
                                            return str;
                                        })()}
                                    </td>
                                    <td class="text_right">${currencyFmt(concepto.Attrs.ValorUnitario)}</td>
                                    <td class="text_right">${currencyFmt(concepto.Attrs.Importe)}</td>
                                        `)
                            }));

                        table.append("tfoot")
                            .call(tfoot => {
                                const fnAddTotals = (tag: string, value: string) => {
                                    if (value != null)
                                        tfoot.append("tr")
                                            .html(`
                                                <th colspan="4" class="text_right">${tag}</th>
                                                <td colspan="2" class="text_right">${currencyFmt(value)}</td>`);
                                }

                                tfoot.append("tr").style("height", "var(--padding2)"); // Separador
                                fnAddTotals("Subtotal", COMPROBANTE.Attrs.SubTotal)
                                fnAddTotals("Descuento", COMPROBANTE.Attrs.Descuento || "0")
                                fnAddTotals("Impuestos retenidos", Impuestos?.Attrs.TotalImpuestosRetenidos || "0")
                                fnAddTotals("Impuestos trasladados", Impuestos?.Attrs.TotalImpuestosTrasladados || "0")
                                fnAddTotals("Total", COMPROBANTE.Attrs.Total)
                            })
                    })

                if (COMPROBANTE.Attrs.Moneda || COMPROBANTE.Attrs.FormaPago || COMPROBANTE.Attrs.MetodoPago)
                    controlWrapper.append("div")
                        .call(AplicaEstiloSeccionLabels)
                        .call(AplicaEstiloSeccion)
                        .html(
                            KeyAndValue("Moneda", COMPROBANTE.Attrs.Moneda)
                            + KeyAndValue("Forma de pago", await DataModuloSAT._GetCatalogoV4Value("FormaPago", COMPROBANTE.Attrs.FormaPago))
                            + KeyAndValue("Método de pago", await DataModuloSAT._GetCatalogoV4Value("MetodoPago", COMPROBANTE.Attrs.MetodoPago))
                        )

                if (ComplementosExtra.length) {
                    for (const complemento of ComplementosExtra) {
                        let html = "";
                        for (let attr in complemento.Attrs) {
                            html += KeyAndValue(attr, complemento.Attrs[attr]);
                        }
                        if (html) controlWrapper.append("div")
                            .call(AplicaEstiloSeccionLabels)
                            .html(html);
                    }
                }

                if (ComplementoTimbreFiscalDigital) // Complemento
                    controlWrapper.append("div")
                        .call(AplicaEstiloSeccionLabels)
                        .html(
                            KeyAndValue("Sello digital del CFDI", ComplementoTimbreFiscalDigital.Attrs.SelloCFD)
                            + KeyAndValue("Sello digital del SAT", ComplementoTimbreFiscalDigital.Attrs.SelloSAT)

                            // + KeyAndValue("Cadena Original del complemento de certificación digital del SAT", "???") // FIXME
                            // || _ | _ | _ | _ + SelloCFD + | + NoCertificadoSAT + ||

                            + KeyAndValue("RFC del proveedor de certificación", ComplementoTimbreFiscalDigital.Attrs.RfcProvCertif)
                            + KeyAndValue("No. de serie del certificado SAT", ComplementoTimbreFiscalDigital.Attrs.NoCertificadoSAT)
                            + KeyAndValue("Fecha y hora de certificación", ComplementoTimbreFiscalDigital.Attrs.FechaTimbrado?.replace("T", " "))
                        )
            });

        }
        catch (e) {
            controlWrapper.text("Error al procesar el XML");
            console.error(e);
            DataUtilAlertBot._SendError(e as any || {}, "Error al procesar XML");
        }
        return controlWrapper;
    }

    function ProcesaStringCFDI_ByFile(file: File) {
        const reader = new FileReader();
        return new Promise<string>((resolve, reject) => {
            reader.onload = async (event) => {
                const text = reader.result as string;
                resolve(text);
            };
            reader.onerror = () => resolve(null);
            reader.readAsText(file);
        })
    }

    export function _ProcesaComprobanteCFDI_ByFile(file: File): Promise<CFDIV4.CFDIV4_ESQUEMA> {
        return ProcesaStringCFDI_ByFile(file)
            .then(stringXML => _ProcesaComprobanteCFDI_ByStringV2(stringXML))
            .catch((e) => null);
    }

    // type CFDIElementName = "Comprobante" | "Emisor" | "Receptor" | "Conceptos" | "Concepto"
    //     | "Impuestos" | "Retenciones" | "Traslados" | "Traslado" | "Impuestos" | "Complemento" | "TimbreFiscalDigital"

    // /** @deprecated */
    // interface CFDIElement {
    //     readonly attr: { [key in string]: string; /* XMLJS.Attributes;*/ };
    //     readonly elems: { [key in CFDIElementName]: CFDIElement };
    // }
    // /** @deprecated */
    // export function fn_ProcesaComprobanteCFDI_ByString(xml: string): CFDIElement {
    //     // 1: Procesamiento de cadena XML

    //     let jsonResult = xml2js(xml) as XMLJS.Element;
    //     console.warn("JSON", jsonResult)
    //     let [comprobanteObj] = jsonResult.elements;
    //     // if (!comprobanteObj.name.startsWith("cfdi:"))
    //     if (comprobanteObj.name != "cfdi:Comprobante")
    //         throw new Error(comprobanteObj.name + " no es comprobante");


    //     // 2: Simplificacion de Objeto CDFI

    //     if (comprobanteObj.elements) {
    //         const ProcesaCFDIElement = (xmlElement: XMLJS.Element): CFDIElement => {
    //             let attributes: CFDIElement["attr"] = xmlElement.attributes || <any>{};
    //             let elements = <CFDIElement["elems"]>{};
    //             if (xmlElement.elements)
    //                 for (let item of xmlElement.elements) {
    //                     if (item.type != "element") {
    //                         continue;
    //                     }
    //                     let [prefix, attrName] = item.name.split(":");
    //                     if (!prefix || !attrName) {
    //                         console.warn("formato incorrecto", item)
    //                         throw new Error(item.name + " formato incorrecto");
    //                     }
    //                     elements[attrName] = ProcesaCFDIElement(item)
    //                 }
    //             return { attr: attributes, elems: elements, }
    //         }
    //         const res = ProcesaCFDIElement(comprobanteObj);
    //         console.warn(">>", res);
    //         return res
    //     }
    //     return null;
    // }

    // interface IItemCFDI {
    //     // _Attrs?: string[];
    //     Attrs?: { [k in string]: "required" | "optional" };
    //     Name?: string;
    //     Childs?: IItemCFDI[];
    //     Sequence?: { [k in string]: IItemCFDI };
    // }
    export function _ProcesaComprobanteCFDI_ByStringV2(xml: string): CFDIV4.CFDIV4_ESQUEMA {
        // 1: Procesamiento de cadena XML

        let jsonResult = xml2js(xml) as XML_Element;
        console.warn("JSON", jsonResult)
        let [comprobanteObj] = jsonResult.elements;
        // if (!comprobanteObj.name.startsWith("cfdi:"))
        if (comprobanteObj.name != "cfdi:Comprobante")
            throw new Error(comprobanteObj.name + " no es comprobante");


        // 2: Simplificacion de Objeto CDFI

        if (comprobanteObj.elements) {
            const ProcesaCFDIElement = (xmlElement: XML_Element): CFDIV4.IBaseItem => {
                const itemName = xmlElement.name.split(":").pop();
                const arrayChilds = (itemName == "CfdiRelacionados" || itemName == "Conceptos"
                    || itemName == "Traslados" || itemName == "Retenciones"
                    || itemName == "ComplementoConcepto" || itemName == "Parte" || itemName == ""
                    || itemName == "Complemento" || itemName == "Addenda")
                const attributes: CFDIV4.IBaseItem["Attrs"] = xmlElement.attributes || <{}>undefined;
                const sequence: CFDIV4.IBaseItem["Sequence"] = !arrayChilds ? {} : undefined;
                const childs: CFDIV4.IBaseItem["Childs"] = arrayChilds ? [] : undefined;

                if (xmlElement.elements)
                    for (let item of xmlElement.elements) {
                        if (item.type != "element") {
                            continue;
                        }
                        let [prefix, subItemName] = item.name.split(":");
                        if (!prefix || !subItemName) {
                            console.warn("formato incorrecto", item)
                            throw new Error(item.name + " formato incorrecto");
                        }
                        const subItemProcesado = ProcesaCFDIElement(item)
                        if (sequence) sequence[subItemName] = subItemProcesado;
                        else if (childs) childs.push(subItemProcesado);
                    }
                return {
                    Name: itemName,
                    Attrs: attributes,
                    Sequence: sequence,
                    Childs: childs,
                }
            }
            const res = ProcesaCFDIElement(comprobanteObj);
            console.warn(">>", res);
            return res as CFDIV4.CFDIV4_ESQUEMA;
        }
        return null;
    }

    function KeyAndValue(key: string, value: string, paddingLeft?: number) {
        if (value?.trim())
            if (paddingLeft)
                return `<label style="padding-left: ${paddingLeft}px"><b>${key}:</b> ${value}</label>`
            else
                return `<label><b>${key}:</b> ${value}</label>`
        console.warn(key, "sin valor")
        return ""
    }

    function AplicaEstiloSeccionLabels(seccion: TSelectionHTML<"div">) {
        seccion
            .classed(UIUtilGeneral.FBoxOrientation.Vertical, true)
            .style("width", "100%")
            .style("gap", "var(--padding1")
    }

    function AplicaEstiloSeccion(seccion: TSelectionHTML<any>) {
        seccion
            .style("border-bottom", "1px solid var(--color_borderbox1)")
            .style("padding-bottom", "var(--padding2)")
            .style("margin-bottom", "var(--padding2)");
    }

    function currencyFmt(val: string) {
        return UIUtilFormat._CurrencyFmt(Number(val))
    }

    // **** Procesamiento de esquema de CFDI(V4) http://www.sat.gob.mx/sitio_internet/cfd/4/cfdv40.xsd

    // export function fn_ProcesaCFDIScheme(xml: string) {
    //     // 1: Procesamiento de cadena XML

    //     const jsonResult = xml2js(xml) as XMLJS.Element;
    //     console.warn("JSON", jsonResult)
    //     const infoComprobante = jsonResult.elements[0].elements.find(d => (d.attributes.name == "Comprobante"));
    //     // if (!comprobanteObj.name.startsWith("cfdi:"))
    //     console.warn("Comprobante", infoComprobante);

    //     interface XSMLProcesado extends XMLJS.Element {
    //         // _Attrs?: string[];
    //         Attrs?: { [k in string]: "required" | "optional" };
    //         Name?: string;
    //         // _ComplexType?: XSMLProcesado;
    //         Childs?: XSMLProcesado[];
    //         Sequence?: { [k in string]: XSMLProcesado };
    //     }
    //     const entidades = {};
    //     // const secuencia = {};
    //     const ProcesarElemento = (item: XMLJS.Element): XSMLProcesado => {
    //         if (item.type == "text" || item.name == "xs:documentation" || item.name == "xs:restriction" || item.name == "xs:annotation") return null;
    //         const res: XSMLProcesado = {};
    //         if (item.name != "xs:element") res.name = item.name;
    //         if (item.type != "element") res.type = item.type;
    //         if (item.attributes) {
    //             if (item.attributes.name) {
    //                 res.Name = item.attributes.name.toString();
    //                 // setTimeout(() => {
    //                 //     delete item.attributes.name;
    //                 //     delete item.attributes.maxOccurs;
    //                 //     delete item.attributes.minOccurs;
    //                 // });
    //                 if (item.name == "xs:element") {
    //                     entidades[res.Name] = {};
    //                 }
    //             }
    //             if (Object.keys(item.attributes).length)
    //                 res.attributes = item.attributes;
    //         }
    //         if (item.elements) {
    //             for (let elem of item.elements) {
    //                 const elemProcesado = ProcesarElemento(elem);
    //                 if (elemProcesado) {
    //                     if (elemProcesado.name == "xs:attribute") {
    //                         if (!res.Attrs) res.Attrs = {};
    //                         res.Attrs[elemProcesado.Name] = elemProcesado.attributes.use as any;
    //                     }
    //                     else if (elemProcesado.name == "xs:complexType") {
    //                         // res._ComplexType = elemProcesado;
    //                         if (elemProcesado.Attrs) {
    //                             res.Attrs = elemProcesado.Attrs;
    //                             if (entidades[res.Name] && !Object.keys(entidades[res.Name]).length)
    //                                 entidades[res.Name] = Object.entries(res.Attrs).reduce((res, [attrName, use]) => ({
    //                                     ...res, [attrName]: (use == "optional" ? "?" : "")
    //                                 }), {});
    //                             else console.error("entidad", res.Name, "ya tiene attrs")
    //                         }
    //                         if (elemProcesado.Childs) res.Childs = elemProcesado.Childs;
    //                     }
    //                     else if (elemProcesado.name == "xs:sequence") {
    //                         res.Childs = elemProcesado.elements;
    //                     }
    //                     else { //if (elemProcesado.name == "xs:element") {
    //                         if (res.Sequence) res.Sequence[elemProcesado.Name] = elemProcesado;

    //                         if (!res.elements) res.elements = [];
    //                         res.elements.push(elemProcesado);
    //                     }
    //                     // else console.error("name no controlado", elemProcesado);
    //                 }
    //             }
    //             if (res.Childs && res.Childs[0].attributes.maxOccurs != "unbounded") {
    //                 res.Sequence = {};
    //                 res.Childs.forEach(child => {
    //                     res.Sequence[child.Name] = child;
    //                 })
    //                 setTimeout(() => delete res.Childs);
    //             }
    //             setTimeout(() => delete res.attributes);
    //         }
    //         // if (!res.attributes && !res.elements?.length) return null;
    //         return res;
    //     }
    //     const esquema = ProcesarElemento(infoComprobante)
    //     console.warn("Esquema", esquema)
    //     console.warn("Entidades", entidades)
    //     // console.warn("Secuencia", secuencia)
    // }
}
