import * as d3 from "d3";
import { group as d3Group } from "d3-array";
import { MainPage } from "../../MainPage";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import DataModuloEgresoMovimiento, { _GetPagosPendientes } from "../../data/modulo/EgresoMovimiento";
import { IConfigGridExcelExport, IGridExtraTableConfig, IGridRenderInfo, VentanaGrid } from "../controlD3/AVentanaGrid";
import { ExcelThings } from "../controlD3/ExcelExport";
import { Table } from "../controlD3/Tabla";
import { UIUtilFormat } from "../util/Format";
import { UIUtilLang } from "../util/Language";
import { UIUtilTime } from "../util/Time";
import { UIUtilGeneral } from "../util/Util";
import { UIUtilViewData } from "../util/ViewData";
import { UIUtilViewFinanzaEgresoCategoria } from "../utilView/FinanzaEgresoCategoria";
import { UIUtilViewFinanzaEgreso } from "../utilView/FinanzasEgreso";
import { UIUtilViewFinanzaEgresoCargaXMLMasivo } from "../utilView/FinanzasEgresoCargaXMLMasivo";
import { UIUtilViewFinanzaEgresoPago } from "../utilView/FinanzasEgresoPago";
import { UIUtilViewFinanzaEgresoUtil } from "../utilView/FinanzasEgresoUtil";

import CAccionPermiso = Entidad.CAccionPermiso;

interface IPagoPendiente extends Entidad.IFinanzaEgresoPagoPendiente {
    KinderFiltro: number[];
    NombreEscuela: string;
    ProveedorFmt: string;
    TipoEgresoFmt: string;
    ValorCargoFmt: string;
    DescuentoFmt: string;
    /** total */
    ImpuestosTrasladados: number;
    ImpuestosTrasladadosFmt: string;
    /** total */
    ImpuestosRetenidos: number;
    ImpuestosRetenidosFmt: string;
    SaldoFmt: string;
    PagadoFmt: string;
    AdeudoFmt: string;
    get CategoriaFmt(): string;
    // FechaEmisionFmt: string;
    FechaAplicacionFmt: string;
    FechaVencimientoFmt: string;
}
type TPermissionID = Entidad.CModulo.FinanzasEgresosPagosPendientes;

export class UIVentanaFinanzasEgresosPagosPendientes extends VentanaGrid<IPagoPendiente, TPermissionID>{

    constructor(content: d3.Selection<HTMLDivElement, undefined, HTMLElement, any>, modulo: TPermissionID) {
        super(content, modulo, {
            ModuloObservableToTblRefresh: [Entidad.CTipoRequest.EgresoCategoria],
            ModuloForceRequestOnMostrar: [Entidad.CTipoRequest.EgresoCategoria],
        });
    }

    protected GRID_GetDataRequestID(): DataModuloMain.TipoRequestMonitorId {
        return null; // data.Entidades.CTipoRequest.EgresoMovimiento;
    }

    protected GRID_GetTableConfigBase(): IGridRenderInfo<IPagoPendiente> {
        return {
            IdTabla: "FinanzasEgresos-CuentasPorPagar",
            DefaultSort: "Folio",
            IdData: "IdAsignacion",
            MinWidth: 1820,
            Columns: [
                { Field: "_DOC" as any, Label: "", Width: "1%", MinWidth: "30px", IsSortable: false, Icon: { Type: "file", Text: "DOC" } },
                { Field: "Descripcion", Label: "Concepto", Width: "20%", MinWidth: "90px" },
                { Field: "TipoEgresoFmt", Label: "TipoEgreso", Width: "15%", MinWidth: "80px" },
                { Field: "CategoriaFmt", Label: "categoria", LabelLangKey: "d_field_categoria", Width: "14%", MinWidth: "90px" },
                { Field: "Folio", Label: "Foli", Width: "12%", MinWidth: "75px" },
                { Field: "ProveedorFmt", Label: "proveedor", Width: "12%", MinWidth: "90px" },
                { Field: "ValorCargoFmt", Label: "ValorCargo", Width: "13%", MinWidth: "75px", OrderField: "ValorCargo" },
                { Field: "DescuentoFmt", Label: "Descuento", Width: "12%", MinWidth: "95px", OrderField: "Descuento" },
                { Field: "ImpuestosRetenidosFmt", Label: "Impuestos retenidos", Width: "14%", MinWidth: "95px", OrderField: "ImpuestosRetenidos" },
                { Field: "ImpuestosTrasladadosFmt", Label: "Impuestos trasladados", Width: "14%", MinWidth: "95px", OrderField: "ImpuestosTrasladados" },
                { Field: "SaldoFmt", Label: "Saldo", Width: "13%", MinWidth: "85px", OrderField: "Saldo" },
                // { Field: "PagadoFmt", Label: "Pagado", Width: "15%", MinWidth: "75px" },
                { Field: "AdeudoFmt", Label: "Adeudo", Width: "13%", MinWidth: "75px", OrderField: "Adeudo" },
                { Field: "FechaAplicacionFmt", Label: "F.Aplicación", Width: "13%", MinWidth: "90px", OrderField: "FechaAplicacion" },
                { Field: "FechaVencimientoFmt", Label: "F.Vencimiento", Width: "13%", MinWidth: "100px", OrderField: "FechaVencimiento" },
                { Field: "NombreEscuela", Label: "Escuela", Width: "20%", MinWidth: "75px" },
            ],
        }
    }

    protected GRID_GetTableConfigAdvanced(): IGridExtraTableConfig<IPagoPendiente> {
        return {
            OnEndUpdateDataInView: (currentDataInTable, datacheched) => {
                this.ctrlTabla._Control
                    .select(".space_pie")
                    .text((() => {
                        let total = [...currentDataInTable.map(data => data.data), ...datacheched.map(data => data.data)]
                            .reduce((total, d) => (total + d.Adeudo), 0)
                        return this.VB_GetUIStringModule("tag_adeudototal") + ": " + UIUtilFormat._CurrencyFmt(total);
                    })());
            },
            EvaluatorAndSubLevelsBuild: {
                OnStepCellTable: (container, d, field) => {
                    switch (field) {
                        case "_DOC" as any:
                            UIUtilViewFinanzaEgresoUtil._TblRowIconDocumentation(container, d.IdAsignacion);
                            break;
                        case "FechaVencimientoFmt":
                            container.classed("fecha_venc", true);
                            break;
                    }
                },
                OnStepRowTable: (d, tr, rb, i) => tr.classed("vencido", UIUtilViewFinanzaEgresoUtil._EsMovimientoVencido({
                    PagoPendiente: d.Adeudo,
                    FechaVencimiento: d.FechaVencimiento,
                    IdEscuela: d.IdEscuela,
                }))
            }
        };
    }

    protected GRID_GetFilters(): Array<Table.IParametroFiltro<IPagoPendiente>> {
        return [
            {
                Field: "Descripcion", Type: "select",
                Options: () => DataModuloMain._GetReqDataArrayByName("EgresoPredefinido")
                    .map(d => ({ Id: d.Descripcion, Name: d.Descripcion }))
                    .concat(this.ctrlTabla._data.map(d => ({ Id: d.Descripcion, Name: d.Descripcion })))
                    .filter(d => (!!d.Name))
                    .sort((a, b) => d3.ascending(a.Name.toLowerCase(), b.Name.toLowerCase())),
            },
            { Field: "TipoEgreso", Type: "select", Options: UIUtilViewData._GetList_EgresoTipo() },
            {
                Field: "CategoriaFmt", LabelLangKey: "d_field_categoria",
                Type: "select", Options: () => {
                    return UIUtilViewFinanzaEgresoCategoria._GetCategoriasFullList().map(d => ({ Id: d.Nombre, Name: d.Nombre }));
                }
            },
            { Field: "Folio" },
            { Field: "ValorCargo", Type: "number" },
            { Field: "Descuento", Type: "number" },
            { Field: "ImpuestosRetenidos", Type: "number", }, // total
            { Field: "RetencionIEPS", Type: "number" },
            { Field: "RetencionISR", Type: "number" },
            { Field: "RetencionIVA", Type: "number" },
            { Field: "ImpuestosTrasladados", Type: "number" }, // total
            { Field: "TrasladoIEPS", Type: "number" },
            { Field: "TrasladoIVA", Type: "number" },
            { Field: "Saldo", Type: "number" },
            { Field: "Adeudo", Type: "number" },
            { Field: "FechaAplicacion", Type: "date" },
            { Field: "FechaVencimiento", Type: "date" },
        ]
    }

    protected GRID_GetMenuTopGrid(): Array<Table.ITableMenuTopDefaultOptionConfig> {
        const opciones: Array<Table.ITableMenuTopDefaultOptionConfig> = []
        if (this.GridHasPermisoAccion(CAccionPermiso.Agregar)) {
            opciones.push(
                {
                    Label: "Agregar",
                    Callback: () => this.OpenModal_Agregar(),
                },
                {
                    Label: "tag_agregar_xml",
                    Callback: () => this.OpenProceso_AgregarEgresosXML(),
                });
        }
        return opciones;
    }

    protected GRID_GetSelectionDataMenuV2(menuLocation: "row" | "top-selected", dataGridSelected: IPagoPendiente[]): Table.ITableMenuDataSelectedOptionConfig<IPagoPendiente>[] {
        let opciones: Table.ITableMenuDataSelectedOptionConfig<IPagoPendiente>[] = [];
        let [dato1] = dataGridSelected;

        if (this.GridHasPermisoAccionV2(CAccionPermiso.Agregar, [dato1.IdEscuela])) {
            const movs = dataGridSelected.map(d => ({ ...d, CantidadPago: d.Adeudo }));
            const { EsMismoProveedor, EsMismaEscuela } = UIUtilViewFinanzaEgresoPago._PreparaMovimientos_PagoAgrupado(movs);

            if (dataGridSelected.length == 1)
                opciones.push({
                    Label: "tag_pagar",
                    MultiData: false,
                    Callback: async () => {
                        const pagoAgregado = await UIUtilViewFinanzaEgresoPago._OpenModal_PagarEgresoMov({
                            IdMovimiento: dato1.IdAsignacion,
                            IdEscuela: dato1.IdEscuela,
                            Concepto: dato1.Descripcion,
                            CantidadPago: dato1.Adeudo, // UIUtilViewFinanzaEgreso.GetSaldoFinalFromMto(dato1),
                            FechaEmision: dato1.FechaEmision,
                        })
                        if (pagoAgregado) {
                            this.ctrlProgressBar.node()._Oculto = false;
                            this.GridUpdateData();
                        }
                    }
                })
            else if (dataGridSelected.length > 1)
                opciones.push({
                    Label: "tag_pagar",
                    MultiData: true,
                    GetDetails: () => ({
                        Enabled: EsMismoProveedor && EsMismaEscuela,
                        Description: !EsMismaEscuela ? "Los adeudos pertenecen a diferentes escuelas" :
                            !EsMismoProveedor ? "Los adeudos pertenecen a diferentes proveedores" : "", // FIX_LANGUAGE
                    }),
                    Callback: async () => {
                        const pagoAgregado = await UIUtilViewFinanzaEgresoPago._OpenModal_PagoMultipleAgrupado(dataGridSelected.map(d => ({
                            IdMovimiento: d.IdAsignacion,
                            IdEscuela: d.IdEscuela,
                            CantidadPago: d.Adeudo, // UIUtilViewFinanzaEgreso.GetSaldoFinalFromMto(dato1),
                            FechaEmision: d.FechaEmision,
                            Concepto: d.Descripcion,
                            ProveedorRazonSocial: d.ProveedorFmt,
                            IdProveedor: d.IdProveedor,
                        })))
                        if (pagoAgregado) {
                            this.ctrlProgressBar.node()._Oculto = false;
                            this.GridUpdateData();
                        }
                    }
                })
        }
        if (this.GridHasPermisoAccionV2(CAccionPermiso.Eliminar, dataGridSelected.map(d => d.IdEscuela)))
            opciones.push({
                Label: "Editar",
                MultiData: false,
                GetDetails: (movs) => {
                    const disable = movs.some(m => m.Pagado > 0);
                    return {
                        Description: disable ? this.VB_GetUIStringModule("tag_movconpagos") : null, // FIX_LANGUAGE
                        Enabled: !disable,
                    }
                },
                Callback: ([mov]) => this.OpenModal_Editar(mov),
            })

        if (this.GridHasPermisoAccionV2(CAccionPermiso.Eliminar, dataGridSelected.map(d => d.IdEscuela)))
            opciones.push({
                Label: "Eliminar",
                MultiData: true,
                GetDetails: (movs) => {
                    const disable = movs.some(m => m.Pagado > 0);
                    return {
                        Description: disable ? this.VB_GetUIStringModule("tag_movconpagos") : null, // FIX_LANGUAGE
                        Enabled: !disable,
                    }
                },
                Callback: movs => this.OpenModal_EliminarMovimientoPadre(movs),
            })
        return opciones;
    }

    private async OpenModal_Agregar() {
        const egresoAgregado = await UIUtilViewFinanzaEgreso._OpenModal_MovEgreso_Agregar(this.modulo);
        if (egresoAgregado) {
            this.ctrlProgressBar.node()._Oculto = false;
            this.GridUpdateData();
        }
    }

    private async OpenModal_Editar(movimiento: IPagoPendiente) {
        const success = await UIUtilViewFinanzaEgreso._OpenModal_MovEgreso_Editar(this.modulo, {
            ...movimiento,
            Id: movimiento.IdAsignacion,
        });
        if (success) {
            this.ctrlProgressBar.node()._Oculto = false;
            this.GridUpdateData();
        }
    }

    private async OpenProceso_AgregarEgresosXML() {
        UIUtilViewFinanzaEgresoCargaXMLMasivo._OpenModal_AgregarEgresosXML({
            Modulo: this.modulo,
            Escuelas: this.GridGetEscuelasSeleccionadas(),
            OnEnd: (nuevosRegistros) => {
                if (nuevosRegistros) {
                    this.GridUpdateData();
                }
            }
        });
    }

    private OpenModal_EliminarMovimientoPadre(movs: IPagoPendiente[]) {
        let forceUpdate = false;
        this.GridOpenModal_ProccessArrayData({
            DataToProccess: movs,
            OnError_GetItemDataTag: m => `${m.Descripcion}\n${m.SaldoFmt}` + (m.Folio ? "\n" + this.VB_GetUIStringModule("d_field_folio") + ": " + m.Folio : ""),
            AutoReloadGridRequestOnFinally: false,
            OnGetIdEscuela: m => m.IdEscuela,
            OnStepAProccess: async m => {
                const res = await DataModuloEgresoMovimiento._EliminarEgreso(m.IdAsignacion);
                if (!forceUpdate) forceUpdate = (res.Resultado == -1 || res.Resultado == -2);
                return res
            },
            OnEndAndCloseProccess: (successM, idsEscuelas) => {
                if (!successM.length && !forceUpdate) return;
                // this.forceDownloadData = true;
                this.GridUpdateData();
            }
        })
    }

    //***************************************************************************************
    // PROCESO AGREGAR/EDITAR CARGO
    //***************************************************************************************

    protected GRID_GetExportarConfig(dataGrid: IPagoPendiente[]): IConfigGridExcelExport<IPagoPendiente> {
        return {
            IdsEscuelas: [...new Set(dataGrid.map(d => d.IdEscuela))],
            ColumnsConfig: this.ctrlTabla
                ._InfoColumns
                .filter(d => (d.Field != "_DOC" as any))
                .map<ExcelThings.IColumnToExcelExportFileConfig<IPagoPendiente>>((d) => ({
                    Field: d.Field as keyof IPagoPendiente,
                    HeaderTag: d.Label,
                    WidthCell: 25
                })),
            OnGetDataBySheets: async () => {
                return Array.from(d3Group(dataGrid, d => d.IdEscuela))
                    .map<ExcelThings.ISheetConfig<IPagoPendiente>>(([idEscuela, datos]) => ({
                        IdSheet: idEscuela,
                        SheetName: datos[0].NombreEscuela,
                        Data: datos
                    }))
            },
            OnGetEscuelasTagInSheet: (dataSheet) => dataSheet[0].NombreEscuela,
        }
    }

    /**
     * @override
     */
    protected async GridGetData(): Promise<IPagoPendiente[]> {
        let idsEscuelas = this.GridGetEscuelasSeleccionadas().map(d => d.IdKinder);
        let res = await _GetPagosPendientes(idsEscuelas);

        if (res.Resultado <= 0) {
            this.notificacion._Mostrar(UIUtilLang._GetUIString("general", "notif_fail_infosync"), "ADVERTENCIA");
            return [];
        }
        const proveedorReqRes = await MainPage._ReloadServiceAndAwaitBool(Entidad.CTipoRequest.Proveedor, idsEscuelas);
        if (!proveedorReqRes) {
            this.notificacion._Mostrar(UIUtilLang._GetUIString("general", "notif_fail_infosync"), "ADVERTENCIA");
        }

        return res.Datos
            .map<IPagoPendiente>((d) => {
                const dd = <IPagoPendiente>{
                    ...d,
                    KinderFiltro: [d.IdEscuela],
                    NombreEscuela: DataModuloMain._GetDataValueFieldByName("Escuela", d.IdEscuela, "Nombre"),
                    ProveedorFmt: DataModuloMain._GetDataValueFieldByName("Proveedor", d.IdProveedor, "RazonSocial"),
                    TipoEgresoFmt: UIUtilViewData._GetStr_EgresoTipo(d.TipoEgreso),
                    ValorCargoFmt: UIUtilFormat._CurrencyFmt(d.ValorCargo),
                    DescuentoFmt: UIUtilFormat._CurrencyFmt(d.Descuento),
                    ImpuestosRetenidos: (d.RetencionIEPS + d.RetencionISR + d.RetencionIVA),
                    ImpuestosTrasladados: (d.TrasladoIEPS + d.TrasladoIVA),
                    ImpuestosRetenidosFmt: ((str) => {
                        if (d.RetencionIVA) str += `${this.VB_GetUIStringModule("tag_iva")}: ${UIUtilFormat._CurrencyFmt(d.RetencionIVA)}`
                        if (d.RetencionIEPS) str += `\n${this.VB_GetUIStringModule("tag_ieps")}: ${UIUtilFormat._CurrencyFmt(d.RetencionIEPS)}`
                        if (d.RetencionISR) str += `\n${this.VB_GetUIStringModule("tag_isr")}: ${UIUtilFormat._CurrencyFmt(d.RetencionISR)}`
                        if (!str) str += UIUtilFormat._CurrencyFmt(0);
                        else if (str.includes("\n")) str += `\n${this.VB_GetUIStringModule("tag_total")}: ${UIUtilFormat._CurrencyFmt(d.RetencionIEPS + d.RetencionISR + d.RetencionIVA)}`
                        return str;
                    })(""),
                    ImpuestosTrasladadosFmt: ((str) => {
                        if (d.TrasladoIVA) str += `${this.VB_GetUIStringModule("tag_iva")}: ${UIUtilFormat._CurrencyFmt(d.TrasladoIVA)}`
                        if (d.TrasladoIEPS) str += `\n${this.VB_GetUIStringModule("tag_ieps")}: ${UIUtilFormat._CurrencyFmt(d.TrasladoIEPS)}`
                        if (!str) str += UIUtilFormat._CurrencyFmt(0);
                        else if (str.includes("\n")) str += `\n${this.VB_GetUIStringModule("tag_total")}: ${UIUtilFormat._CurrencyFmt(d.TrasladoIEPS + d.TrasladoIVA)}`;
                        return str;
                    })(""),
                    SaldoFmt: UIUtilFormat._CurrencyFmt(d.Saldo),
                    PagadoFmt: UIUtilFormat._CurrencyFmt(d.Pagado),
                    AdeudoFmt: UIUtilFormat._CurrencyFmt(d.Adeudo),
                    // FechaEmisionFmt: d.FechaEmision ? UIUtilTime.fn_DateFormatStandar(d.FechaEmision) : "",
                    FechaAplicacionFmt: UIUtilTime._DateFormatStandarFixTimeZoneByIdSchool(d.FechaAplicacion, d.IdEscuela),
                    FechaVencimientoFmt: UIUtilTime._DateFormatStandarFixTimeZoneByIdSchool(d.FechaVencimiento, d.IdEscuela),
                }

                return UIUtilGeneral._ObjectAddGetters(dd, {
                    CategoriaFmt: (d => UIUtilViewFinanzaEgresoCategoria._CategoriaNombre(d.IdCategoria)),
                });
            })
    }

    /**
     * @override
     */
    protected GridInitTable(): void {
        super.GridInitTable();

        this.ctrlTabla._Control.select(".space_pie")
            .classed("hide", false)
            .style("display", "flex")
            .style("justify-content", "end")
            .text(this.VB_GetUIStringModule("tag_adeudototal") + ": " + UIUtilFormat._CurrencyFmt(0));
    }
}
