import { DataDRequest } from "../DRequest";
import { Entidad } from "../Entidad";
import { Global } from "../Global";
import { DataUtil } from "../util/Util";

export function _ObtenerEventosPorIdsCalendarios(ids: number[], inicio: Date, fin: Date) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "eventoCalendario/ObtenerEventosPorCalendarios"
    const params = {
        "IdsCalendarios": ids,
        "Inicio": inicio.toISOString(),
        "Fin": fin.toISOString(),
    }
    console.debug(inicio.toISOString(), fin.toISOString())
    return DataDRequest._Request5<Entidad.IEventoPorCalendario[]>(url, "GET", params);
}

export function _ObtenerEventosAgrupadosPorIdEvento(id: number) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "eventoCalendario/ObtenerEventosAgrupados";
    const params = {
        "Id": id
    }
    return DataDRequest._Request5<Entidad.IEventoPorCalendario[]>(url, "GET", params);
}

export interface ICalendariosToSend {
    IdCalendario: number;
    IdKinder: number;
    IdEscolaridad: number;
    IdGrupo: number;
    TipoEvento: number;
    IdEntidad: number;
}

export function _AgregarEventos(eventConfig: Entidad.IEventoConfig, calendariosInfo: ICalendariosToSend[]) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "eventoCalendario/AgregarEventos";
    const params = {
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
        "Nombre": eventConfig.Nombre,
        "TodoDia": eventConfig.IsAllDay,
        "Inicio": eventConfig.Inicio,
        "Fin": eventConfig.Fin,
        "TipoRecurrencia": eventConfig.TipoRecurrencia,
        "FechaFinRecurrencia": eventConfig.FinRecurrencia,
        "NRecurrencias": eventConfig.NRecurrencias,
        "VarianteTipoRecurrencia": eventConfig.VarianteRecurrencia,
        "TipoFinRecurrencia": eventConfig.TipoFinRecurrencia,
        "CalendariosInfo": calendariosInfo
    }
    return DataDRequest._Request5(url, "POST", params);
}

export function _AgregarEvento(eventConfig: Entidad.IEventoConfig, calendariosInfo: ICalendariosToSend[], eventosInfo: any) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "eventoCalendario/AgregarEvento";
    const params = {
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
        "Nombre": eventConfig.Nombre,
        "TodoDia": eventConfig.IsAllDay,
        "Inicio": eventConfig.Inicio,
        "Fin": eventConfig.Fin,
        "CalendariosInfo": calendariosInfo,
        "TipoRecurrencia": eventConfig.TipoRecurrencia,
        "VarianteTipoRecurrencia": eventConfig.VarianteRecurrencia,
        "TipoFinRecurrencia": eventConfig.TipoFinRecurrencia,
        "FechaFinRecurrencia": eventConfig.FinRecurrencia,
        "NRecurrencias": eventConfig.NRecurrencias,
        "EventosInfo": eventosInfo
    }

    return DataDRequest._Request5(url, "POST", params);
}




interface IEventoSentEdit extends Entidad.IEventoConfig {
    IdEvento: number
    IdCalendario: number
    TipoEdicion: Entidad.CTipoAccionEvento
    IdKinder: number,
    IdEscolaridad: number,
    IdGrupo: number,
    TipoEvento: number,
    ReuseConfig?: boolean,
    IdEntidad: number,
}

export function _EditarEvento(eventoEdit: IEventoSentEdit) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "eventoCalendario/EditarEvento";
    const params = {
        "IdUsuario": DataUtil._Usuario.IdUsuario, // Para int_idmodifico (Tablas que ocupen)
        "IdOrganizacion": DataUtil._Usuario.IdOrganizacion, // (Tablas que ocupen)
        "IdEvento": eventoEdit.IdEvento, // (EVENT)
        "TipoEdicion": eventoEdit.TipoEdicion, // (ALGORITHM)
        "Nombre": eventoEdit.Nombre, // (EVENT & CONFIG)
        "TodoDia": eventoEdit.IsAllDay, // (CONFIG)
        "Inicio": eventoEdit.Inicio, // (EVENT)
        "Fin": eventoEdit.Fin, // (EVENT)
        "IdCalendario": eventoEdit.IdCalendario, // (EVENT & CONFIG)
        "IdKinder": eventoEdit.IdKinder, // (EVENT) 
        "IdEscolaridad": eventoEdit.IdEscolaridad, // (EVENT)
        "IdGrupo": eventoEdit.IdGrupo, // (EVENT)
        "TipoEvento": eventoEdit.TipoEvento, // (EVENT)
        "TipoRecurrencia": eventoEdit.TipoRecurrencia, // (CONFIG)  
        "VarianteTipoRecurrencia": eventoEdit.VarianteRecurrencia, // (CONFIG)
        "TipoFinRecurrencia": eventoEdit.TipoFinRecurrencia, // (CONFIG)
        "FechaFinRecurrencia": eventoEdit.FinRecurrencia, // (CONFIG)
        "NRecurrencias": eventoEdit.NRecurrencias, // (CONFIG)
        "ReUseConfig": eventoEdit.ReuseConfig || false,
        "IdEntidad": eventoEdit.IdEntidad,
    }

    return DataDRequest._Request5(url, "POST", params);
}

export function _ObtenerConfiguracionInfo(idEvento: number, idConfiguracion: number) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "eventoCalendario/ObtenerConfiguracion";

    const params = {
        "IdEvento": idEvento,
        "IdConfiguracion": idConfiguracion
    }

    return DataDRequest._Request5<Entidad.IEventoInfo>(url, "GET", params, {});
}

export function _EliminarEvento(id: number, tipoEliminacion: Entidad.CTipoAccionEvento) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "eventoCalendario/EliminarEvento";
    const params = {
        "IdEvento": id,
        "TipoDelete": tipoEliminacion
    }

    return DataDRequest._Request5(url, "POST", params);
}