import * as d3 from "d3";
import { UIUtilGlobalKeyEvents } from "../util/GlobalKeyEvent";
import { UIUtilGeneral } from "../util/Util";

export namespace DropdownFlexV2 {
    type TStep = UIUtilGeneral.TTypeStepJoin;
    type TItems<Extras> = IMenuItemConfig<Extras>[] | (() => IMenuItemConfig<Extras>[]);
    export interface IConfig<Extras = unknown> {
        Parent?: TSelectionHTML<any>;
        OnStepItem?: (container: TSelectionHTML<"li">, d: IMenuItemConfig, step: TStep) => void;
        OnShow?: () => void;
        OnHide?: () => void;
        Items?: TItems<Extras>;
    }

    export interface IMenuItemConfig<Extras = unknown> {
        Label: string | (() => string);
        /** @default true */
        Enabled?: boolean;
        Icon?: string;
        OnClick: (d: IMenuItemConfig<Extras>) => void;
        Extras?: Extras;
    }
    interface IMenuItem extends IMenuItemConfig {
        // Id: string;
    }

    const DEFAULTCONFIG = <IConfig<any>>{
        Items: [],
    }

    export class Control<Extras = unknown> {
        private config: IConfig<Extras>;
        private listContainer: TSelectionHTML<"ul">;
        private items: TItems<Extras>;
        private resizeObserver: ResizeObserver;
        private focusIndex: number;
        private align: string = "BOTTOM"

        constructor(config: IConfig<Extras> = DEFAULTCONFIG, align = "BOTTOM") {
            this.config = <IConfig<Extras>>{
                ...DEFAULTCONFIG,
                ...config,
            }
            this.align = align;
            this.focusIndex = - 1;
            this.Init();
            this._SetMenuOptions(this.config.Items);
        }

        // **********************************************************************************************
        // PRIVATE METHODS
        // **********************************************************************************************

        private Init() {
            this.listContainer = d3.create<HTMLUListElement>("ui")
                .classed("dropdown_list", true)
                .attr("slot", "others");

            this.resizeObserver = UIUtilGeneral._GetResizeObserver((entries, observer) => {
                setTimeout(this.FixListContainerLocation.bind(this), 200);
            });
        }

        private FixListContainerLocation() {
            if (this.align === "HORIZONTAL") {
                let body = document.body.getBoundingClientRect();
                let parentItem = this.config.Parent.node().getBoundingClientRect();
                let listDrop = this.listContainer.node().getBoundingClientRect();
                let dif = body.height - (parentItem.y + listDrop.height + 25);
                let left = ((body.width - (parentItem.right + listDrop.width + 25)) <= 0) ? parentItem.left - listDrop.width - 5 : parentItem.right + 5;
                let top = parentItem.y + (dif < 0 ? dif : 0);
                this.listContainer
                    .style("top", top + "px")
                    .style("left", left + "px")
            }
            else if (this.align === "BOTTOM") {
                const positions = UIUtilGeneral._GetRelativePositions(this.config.Parent.node(), "bottom", 5);
                let body = document.body.getBoundingClientRect();
                let parentItem = this.config.Parent.node().getBoundingClientRect();
                let listDrop = this.listContainer.node().getBoundingClientRect();
                let top = parentItem.bottom + 5;

                if ((body.height - (parentItem.y + parentItem.height + listDrop.height + 25)) <= 0 && ((parentItem.y - listDrop.height - 10) > 0)) {
                    top = parentItem.top - listDrop.height - 5;
                }

                this.listContainer
                    .style("top", top + "px")
                    .style("right", positions.Right ? (positions.Right + "px") : null)
                    .style("left", positions.Left ? (positions.Left + "px") : null)
                    .style("max-height", `calc(100% - ${positions.Top + 5}px)`);
            }
        }

        private UpdateViewList() {
            const items = ((typeof this.items == "function" ? this.items() : this.items) || [])
                .map((d, i) => this.EvalItem(<IMenuItem>Object.assign({}, d)));

            this.listContainer
                .selectAll<HTMLLIElement, IMenuItem>(":scope > .item")
                .data(items)
                .join(
                    enter => {
                        let item = enter.append("li").attr("class", "item");
                        if (!this.config.OnStepItem) {
                            item.append("img");
                            item.append("label");
                        }
                        return this.UpdateViewItemList(item, 'enter')
                    }
                    ,
                    update => this.UpdateViewItemList(update, 'update'),
                    exit => this.UpdateViewItemList(exit, 'exit')
                        .remove()
                );

            this.FixListContainerLocation();

            UIUtilGlobalKeyEvents._SetArrowMoveKeyEventCallback({
                ListContainer: this.listContainer.node(),
                GetElementIsValid: (element: HTMLElement) => {
                    if (!(element instanceof HTMLLIElement)) return false;
                    return d3.select<HTMLLIElement, IMenuItem>(element).datum()?.Enabled
                },
                UpdateItemFocusStyle: (element, focused) => d3.select(element)
                    .classed("focused", focused),
                OnSelect: (element: HTMLElement) => {
                    let d = d3.select<HTMLLIElement, IMenuItem>(element as any).datum();
                    if (d.Enabled) {
                        element.click();
                    }
                },
                StartFocus: this.focusIndex,
            })
        }

        private GetItemLabelText(label: IMenuItemConfig["Label"]): string {
            let text = "";
            if (typeof label == "string") text = label;
            if (typeof label == "function") text = label();
            return text;
        }

        private UpdateViewItemList(item: TSelectionHTML<"li", IMenuItem>, step: TStep) {
            return item
                .classed("item_disabled", d => !d.Enabled)
                .each((d, i, elements) => {
                    elements[i].tabIndex = (d.Enabled ? 0 : -1);
                    if (this.config.OnStepItem) {
                        this.config.OnStepItem(d3.select(elements[i]), d, step);
                    }
                    else {
                        if (d?.Icon) {
                            d3.select(elements[i])
                                .style("column-gap", "10px")
                                .style("display", "flex")
                            d3.select(elements[i]).select("img")
                                .attr("src", d.Icon)
                                .style("width", "20px");
                            d3.select(elements[i]).select("label")
                                .text(this.GetItemLabelText(d.Label))
                        } else {
                            d3.select(elements[i]).text(this.GetItemLabelText(d.Label));
                        }
                    }
                    if (step == 'exit') {
                        return;
                    }
                    if (d.Enabled) {
                        elements[i].onclick = (e) => {
                            d.OnClick(d);
                            this.Hide();
                            e.stopPropagation();
                        }
                    }
                    else {
                        elements[i].onclick = (e) => {
                            e.stopPropagation();
                        };
                    }
                })
        }

        private Show() {
            if (this.config.Parent) {
                this.config.Parent.node().append(this.listContainer.node());
            }

            this.focusIndex = - 1;

            this.UpdateViewList();

            if (this.config.OnShow) {
                this.config.OnShow();
            }

            this.resizeObserver?.observe(document.body);
            UIUtilGlobalKeyEvents._SetEscKeyEventCallback(this.listContainer.node(), () => this.Hide());
            if (this["__clickbody"]) {
                document.removeEventListener("click", this["__clickbody"]);
            }
            this["__clickbody"] = () => {
                this.Hide();
            }
            setTimeout(() => {
                document.addEventListener("click", this["__clickbody"]);
            })
        }

        private Hide() {
            if (this.isVisible()) {
                UIUtilGlobalKeyEvents._RemoveEscKeyEventCallback(this.listContainer.node());
                this.listContainer.node().remove();

                if (this.config.OnHide) {
                    this.config.OnHide();
                }

                this.resizeObserver?.unobserve(document.body);
            }
            if (this["__clickbody"]) {
                document.removeEventListener("click", this["__clickbody"]);
            }
        }

        private ChangeParent(newParent: TSelectionHTML) {
            const visible = this.isVisible();

            if (visible && this.config.Parent.node() != newParent.node()) {
                this.resizeObserver?.unobserve(document.body);
            }
            this.config.Parent = d3.select(newParent.node());
            if (visible) {
                this.resizeObserver?.observe(document.body);
                this.UpdateViewList();
            }
        }

        private isVisible(): boolean {
            return this.listContainer.node().isConnected;
        }

        // **********************************************************************************************
        // PUBLIC METHODS
        // **********************************************************************************************

        public get _ListContainer() {
            return this.listContainer;
        }

        public get _Visible() {
            return this.isVisible();
        }

        private EvalItem(item: IMenuItem) {
            if (item.Enabled == null) {
                item.Enabled = true;
            }
            return item;
        }

        public _SetMenuOptions(options: TItems<Extras>) {
            this.items = options;
            if (this.isVisible()) {
                this.UpdateViewList();
            }
            return this;
        }

        public _SetParent(parent: TSelectionHTML) {
            this.ChangeParent(parent);
            return this;
        }

        public _Show() {
            this.Show();
            return this;
        }

        public _Hide() {
            this.Hide();
            return this;
        }

        public _OnShow(callback: () => void) {
            this.config.OnShow = callback;
            return this;
        }

        public _OnHide(callback: () => void) {
            this.config.OnHide = callback;
            return this;
        }
    }
}
