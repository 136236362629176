import { Main } from "../../Main";
import { Global } from "../../data/Global";
import DataModuloSesion from "../../data/modulo/Sesion";
import { DataUtil } from "../../data/util/Util";
import _L from "../../util/Labels";
import { UIUtilIconResources } from "../util/IconResourses";
import { UIUtilGeneral } from "../util/Util";
import { UIUtilViewUserPreferences } from "../utilView/UserPreferences";
import { Button } from "./Button";
import { DropdownFlexV2 } from "./DropdownFlexV2";
import { FormGenerator } from "./Formulario";
import { ModalThings } from "./ModalThings";
import { NotificacionV2 } from "./NotificacionV2";
import { _ShowTemporalTooltip } from "./TemporalTooltip";

export namespace TemplateMenuUser {
    export function _Create(parent: TSelectionHTML) {
        const control = new DropdownFlexV2.Control({
            Parent: parent,
            Items: () => {
                const menuItms: DropdownFlexV2.IMenuItemConfig[] = [];

                menuItms.push({
                    Label: _L("app.preferences"),
                    OnClick: () => UIUtilViewUserPreferences._OpenModal_Preferencias(),
                    Icon: UIUtilIconResources.CGeneral.Settings
                })

                menuItms.push({
                    Label: _L("general.userdata"),
                    OnClick: () => OpenUserData(),
                    Icon: UIUtilIconResources.CGeneral.User,
                })

                menuItms.push({
                    Label: _L("sesion.logout"),
                    OnClick: () => Main._Logout(), // this.Logout(),
                    Icon: UIUtilIconResources.CGeneral.Logout
                })

                menuItms.push({
                    Label: _L("app.about"),
                    OnClick: () => OpenAcercaDe(),
                    Icon: UIUtilIconResources.CGeneral.App,
                })

                return menuItms;
            }
        })
        control._ListContainer.style("z-index", 3)
        return control
    }

    /* function OpenPreferencias() { // FIX_LANGUAGE
        ModalThings._GetModalToForm({
            Title: _L("app.preferences"),
            Width: 350,
            GetForm: () => {
                let conf = {
                    OffLineMode: false,
                    ForceWindowInfoFocus: true,
                    SendDevAlerts: true,
                    FullScreen: UIUtilScreen._IsFullScreen(),
                    FontSize: 14,
                    // Tema: "kidi-light" // ("kidi-light" | "kidi-dark" | "kidi-contrast" )
                }
                type TConf = typeof conf;
                return new FormGenerator<TConf>()
                    ._Crear({
                        LabelMaxWidth: 300,
                        schema: [
                            {
                                model: "OffLineMode",
                                type: Fields.input,
                                labelAttr: { text: "Modo offline" },
                                inputAttr: { type: "checkbox" }
                            },
                            {
                                model: "ForceWindowInfoFocus",
                                type: Fields.input,
                                labelAttr: { text: "Globo de ayuda persistente" },
                                inputAttr: { type: "checkbox" }
                            },
                            {
                                model: "SendDevAlerts",
                                type: Fields.input,
                                labelAttr: { text: "Autorizar mensajes de error al desarrollador" },
                                inputAttr: { type: "checkbox" }
                            },
                            {
                                model: "FullScreen",
                                type: Fields.input,
                                labelAttr: { text: "Pantalla completa" },
                                inputAttr: { type: "checkbox" }
                            },
                            {
                                model: "FontSize",
                                type: Fields.input,
                                labelText: "Tamaño de letra",
                                inputAttr: { type: "number" }
                            }
                        ],
                        BuildView: (container, controlsForm, form) => {
                            container.html(`
                                            <div class="row">
                                                <b>Kidi Admin v${Global._APP_VERSION.toString()}-${Global._DEBUG_VERSION.toString()}</b>
                                            </div>
                                        `)

                            container.append(() => controlsForm.get("OffLineMode").row.node())
                                .select(":scope > .titulos_base")
                                .style("min-width", "80%");
                            container.append(() => controlsForm.get("ForceWindowInfoFocus").row.node())
                                .select(":scope > .titulos_base")
                                .style("min-width", "80%");
                            container.append(() => controlsForm.get("SendDevAlerts").row.node())
                                .select(":scope > .titulos_base")
                                .style("min-width", "80%");
                            let fullScreenRow = container.append(() => controlsForm.get("FullScreen").row.node())
                            fullScreenRow.select(":scope > .titulos_base")
                                .style("min-width", "80%");
                            fullScreenRow.select<HTMLInputElement>("input")
                                .node()
                                .onchange = e => {
                                    let checked = fullScreenRow.select<HTMLInputElement>("input")
                                        .node()
                                        .checked;
                                    if (checked) {
                                        UIUtilScreen._LaunchFullScreen();
                                    } else {
                                        UIUtilScreen._CancelFullScreen();
                                    }
                                }
                            container.append(() => controlsForm.get("FontSize").row.node())
                                .select(":scope > .titulos_base")
                                .style("min-width", "80%")
                        }
                    }, conf);
            },
            DrawContent: (container, form, mt) => {
                mt.BtnLeft.remove();
                mt.BtnRight.text(_L("general.save"));
            },
            OnAccept: () => {
                return null;
            }
        });
    } */

    function OpenUserData() {
        ModalThings._GetModalSimple({
            Title: _L("general.userdata"),
            Width: 350,
            DrawContent: (content, modalThings) => {
                content.style("display", "flex").style("flex-direction", "column").style("gap", "var(--padding2)");
                content.append("div")
                    .call(div => {
                        div.append("span").call(span => {
                            span.append("b").text("Nombre: ");
                            span.append("span").text(`${DataUtil._Usuario.Nombre} ${DataUtil._Usuario.ApPaterno} ${DataUtil._Usuario.ApMaterno}`);
                        })
                        div.append("br")
                        div.append("span").call(span => {
                            span.append("b").text(_L("general.email") + ": ");
                            span.append("span").text(DataUtil._Usuario.Correo);
                        })
                    });

                new Button(content.node(), _L("sesion.change_pass"))._node.onclick = (e) => {
                    OpenCambiarContrasenia();
                }
            }
        })
    }

    function OpenCambiarContrasenia() {
        ModalThings._GetModalToForm({
            Title: _L("sesion.change_pass"),
            LangModuleKeyInContext: "sesion",
            AccionToHttpMessage: "changepasswd",
            Width: 350,
            GetForm: () => GetFormResetPasswd(),
            OnAccept: async (form) => {
                if (form._GetIsValidForm()) {
                    let res = await DataModuloSesion._CambiarContrasenia(form._Data.Psswd1, form._Data.Psswd2);
                    return res;
                }
                return null;
            }
        })
    }

    function GetFormResetPasswd() {
        return new FormGenerator<{ Psswd1: string, Psswd2: string, Psswd3: string }>()
            ._Crear({
                LabelMaxWidth: 300,
                schema: [
                    { type: "input", inputAttr: { required: true, placeholder: "", type: "password" }, model: "Psswd1", labelText: _L("sesion.current_password") },
                    { type: "input", inputAttr: { required: true, placeholder: "", type: "password" }, model: "Psswd2", labelText: _L("sesion.new_password") },
                    { type: "input", inputAttr: { required: true, placeholder: "", type: "password" }, model: "Psswd3", labelText: _L("sesion.confirm_password") }
                ],
                Validation: (value, field, dForm, controlsForm) => {
                    switch (field) {
                        case "Psswd2":
                        case "Psswd3":
                            if (field == "Psswd2" && (value as string).length < 8) {
                                NotificacionV2._Mostrar(_L("sesion.notif_minlenghtpsswd"), "ADVERTENCIA");
                                return false;
                            }
                            if (dForm.Psswd2 != dForm.Psswd3 && dForm.Psswd2.length >= 8) {
                                if (field == "Psswd3") NotificacionV2._Mostrar(_L("sesion.notif_noequalpasswords"), "ADVERTENCIA");
                                return false;
                            }
                            return true;
                            break;
                        default:
                            return true;
                    }
                }
            });
    }

    function OpenAcercaDe() {
        ModalThings._GetModalSimple({
            Title: _L("app.about_ka"),
            Width: 300,
            DrawContent: (cont) => {
                cont.classed("about_app", true);
                cont.classed(UIUtilGeneral.FBoxOrientation.Vertical, true)
                cont.classed(UIUtilGeneral.FBoxAlign.CenterCenter, true)
                cont.html(`
                <style>
                .about_app label {
                    text-align: center;
                }
                </style>

                <b>${_L("app.version")}</b>
                <label>${Global._APP_VERSION_NAME}</label>
                <br/>

                ${Global._GLOBAL_CONF.RELEASE_MODE ? "" : `
                <b>${_L("app.mode")}</b>
                <label>${Global._GLOBAL_CONF.DEBUG_MODE ? "QA" : (Global._GLOBAL_CONF.BETA_MODE ? "BETA" : "?")}</label>
                <br/>`}

                ${!Global._DEVELOPMENT_UTILS ? "" : `
                <b>Development Utils ✅</b>
                <br/>`}

                <b>Copyright</b>
                <label>${_L("app.copyright")}</label>
                `)
                const strFullVersion = `${Global._APP_VERSION_NAME}/${Global._APP_VERSION_BUILD}`
                const lblVersion = cont.append<HTMLSpanElement>("span")
                    .style("font-size", "10px")
                    .style("margin-top", "var(--padding3)")
                    .style("padding", "0 var(--padding1)")
                    .style("background-color", "rgba(0, 0, 0, .1)")
                    .style("cursor", "copy")
                    .text(strFullVersion)
                lblVersion.node().onclick = () => {
                    navigator.clipboard.writeText(strFullVersion)
                    _ShowTemporalTooltip(lblVersion, _L("general.copiedtext"))
                }
                lblVersion.node().onmousedown = () => lblVersion.style("background-color", "rgba(0, 0, 0, .2)")
                lblVersion.node().onmouseup = () => lblVersion.style("background-color", "rgba(0, 0, 0, .1)")
            }
        })
    }
}