import * as d3 from "d3";
import { UIUtilLang } from "../util/Language";
import { UIUtilMask } from "../util/Mask";
import { TDropdownData, DropdownAdvanced } from "./DropdownAdvanced";

export interface ISelectSuggestConfig<TData, VM extends keyof TData> {
    Parent: d3.Selection<HTMLElement, any, any, any>;
    AppendInputOnParent?: boolean
    ListWidth?: string;
    ValueMember: VM;
    DisplayMember: keyof TData;
    Data?: TDropdownData<TData>;
    Placeholder?: string;
    Disabled?: boolean;
    RemoveBorder?: boolean;
    OnSelect?: (datos: Array<TData>) => void;
    OnChange?: (valueMembers: Array<TData[VM]>, datos: Array<TData>) => void;
    OnAccept?: (valueMembers: Array<TData[VM]>, datos: Array<TData>) => void;
    OnStepItemListUI?: (container: TSelectionHTML<"htmlelement">, itemData: TData, step: TypeStep) => void;
    ToApplyMask?: (inpunt: HTMLInputElement) => void;
    ShowAndEnableSearchText?: boolean;
    StrictSelect?: boolean;
}

interface IElements {
    Container: TSelectionHTML<"div">;
}

type TypeStep = ("enter" | "update" | "exit");

export class SelectSuggest<
    TData = any,
    VM extends keyof TData = any
> {
    private elements: IElements;
    private config: ISelectSuggestConfig<TData, VM>;

    private dropList: DropdownAdvanced<TData, VM>;

    constructor(config: ISelectSuggestConfig<TData, VM>) {
        this.elements = <IElements>{};

        if (config) {
            let defaultConfig = <ISelectSuggestConfig<TData, VM>>{
                Data: [],
                Placeholder: UIUtilLang._GetUIString("general", "selectaoption"),
                Disabled: false,
                RemoveBorder: false,
                AppendInputOnParent: true
            }
            this.config = { ...defaultConfig, ...config };
            this.CrearElementos(this.config.Parent);
        }
    }

    private ShowListView() {
        if (!this.config.Disabled) {
            this.dropList._Show();
        }
    }

    private RemoveListView() {
        this.dropList._Hide();
    }

    private CrearElementos(parent: d3.Selection<HTMLElement, any, HTMLElement, any>) {
        this.elements.Container = (this.config.AppendInputOnParent ? parent.append("div") : d3.create("div"))
        this.elements.Container.classed("contenedor_combo", true)
            .classed("combo-borde", this.config.RemoveBorder)

        this.elements.Container.html(`
                    <input class="input_text_seleccion"></input>
                `);

        this.GetMainInputSelection()
            .attr("type", "text")
            .node()
            .oninput = (e) => {
                if (!this._visible)
                    this.ShowListView();

                setTimeout(() => {
                    let itemFound = this._dataitems.find(d => d[this.config.DisplayMember] == this.GetMainInputNode().value);
                    this._valueSelect(itemFound ? [itemFound[this.config.ValueMember]] : [])
                });
            };

        if (this.config.ToApplyMask) {
            this.config.ToApplyMask(this.GetMainInputNode()); // Apply node
        }

        this.elements.Container
            .on("click", () => {
                //if (!this.prop_visible) {
                this.ShowListView();
                //}
            });

        // Instanciar clase de listaAvanzada
        this.dropList = new DropdownAdvanced<TData, VM>({
            IsMultiSelect: false,
            Parent: this.elements.Container,
            ListWidth: this.config.ListWidth,
            ValueMember: this.config.ValueMember,
            DisplayMember: this.config.DisplayMember,
            Data: this.config.Data,
            OnUpdateData: () => {
                this.GetMainInputSelection().attr("placeholder", this.GetPlaceholderText());
            },
            OnSelect: (datos) => {
                if (this.config.OnSelect)
                    this.config.OnSelect(datos)
                this.UpdateInputText(datos);
                this.GetMainInputSelection().attr("placeholder", this.GetPlaceholderText());
            },
            OnChange: (ids, datos) => {
                if (this.config.OnChange) {
                    this.config?.OnChange(ids, datos);
                }
                this.UpdateInputText(datos);
                this.GetMainInputSelection().attr("placeholder", this.GetPlaceholderText());
            },
            OnAccept: (ids, datos) => {
                if (this.config.OnAccept) {
                    this.config.OnAccept(ids, datos);
                }
            },
            OnStepItemListUI: this.config.OnStepItemListUI,
            OnChangeVisible: (visible) => {
                this.UpdateInputText();
                if (!visible) {
                    this.elements.Container
                        .on("click", () => {
                            this.ShowListView();
                        });
                } else {
                    this.elements.Container
                        .on("click", null);
                }
            },
            GetExternalStringToSearch: () => this.GetMainInputNode().value,
            HideNoItemsFound: !this.config.StrictSelect,
            ArrowMoveKeyBlurPrevent: true
        })

        this.GetMainInputSelection()
            .attr("placeholder", this.GetPlaceholderText());
    }

    private UpdateInputText(dataValues: TData[] = this.dropList._controlItemsSelected.map(d => d.data)) {
        const { length: totalSelected } = dataValues;
        if (totalSelected == 0) {
            return;
        }

        const valuesD = dataValues.map(d => d[this.config.DisplayMember]) as string[]
        const inputNode = this.GetMainInputNode();
        if (inputNode?.__imask)
            inputNode.__imask.value = String(valuesD[0])
        else inputNode.value = String(valuesD[0]);
    }

    // *************************************************************************
    // PRIVATE GETERS
    // *************************************************************************

    private GetMainInputSelection() {
        return this.elements.Container.select<HTMLInputElement & UIUtilMask.HTMLInputElementMask>(":scope > .input_text_seleccion");
    }

    private GetMainInputNode() {
        return this.GetMainInputSelection().node();
    }

    private GetPlaceholderText() {
        if (!this.dropList?._dataitems.length) {
            return UIUtilLang._GetUIString("general", "nooptions");
        }
        return this.config.Placeholder;
    }

    // *************************************************************************
    // PUBLIC PROPERTIES
    // *************************************************************************

    public get _visible() {
        return this.dropList._Visible;
    }

    public get _dataitems() {
        return this.dropList._dataitems;
    }

    public get _dataSelected() {
        return this.dropList._dataSelected; // this.dataSelected;
    }

    public get _dataValueMemberSelected() {
        return this.dropList._dataValueMemberSelected;
    }

    public get _uniqueDataSelected() {
        return this.dropList._uniqueDataSelected; // this.dataSelected;
    }

    public get _uniqueVMSelected() {
        return this.dropList._uniqueVMSelected;
    }

    public get _inputValue() {
        return this.GetMainInputNode().value;
    }

    public get _controlSelection() {
        return this.elements.Container;
    }

    public get _listContainer() {
        return this.dropList._listContainer;
    }

    public get _validity() {
        const inputHasValue = this.GetMainInputNode()?.value != "";
        const listWithItemSelected = !!this._uniqueVMSelected;
        return {
            valid: this.config.StrictSelect ? listWithItemSelected : inputHasValue,
            inputHasValue: inputHasValue,
            listWithItemSelected: listWithItemSelected,
        }
    }

    public set _disabled(value: boolean) {
        this.GetMainInputSelection().property("disabled", value)
        this.config.Disabled = value;
    }

    public set _onChange(onchange: (valueMembers: TData[VM][], datos: TData[]) => void) {
        this.config.OnChange = onchange;
    }

    public _UpdateList(datos: TDropdownData<TData>) {
        this.dropList._UpdateList(datos);
        return this;
    }

    public _ResetSelect() {
        this.dropList._ResetSelect()
        return this;
    }

    public _valueSelect(valueMember: TData[VM][]) {
        let items = (Array.isArray(valueMember)) ? valueMember : [valueMember];
        this.dropList._valueSelect(items);
        this.dropList._listContainer.select<HTMLLIElement>(".elemento_lista_seleccionado").node()?.scrollIntoView();
        return this;
    }

    public _disableItems(valuesMember: (TData[VM] | TData[VM][])) {
        this.dropList._disableItems(valuesMember);
        return this;
    }

    public _enableItems(valuesMember: TData[VM][]) {
        this.dropList._enableItems(valuesMember);
        return this;
    }

    public _showOptionsList() {
        this.ShowListView();
        return this;
    }

    public _hideOptionsList() {
        this.RemoveListView();
        return this;
    }

    public _ShowInputBorder(value: boolean) {
        this.elements.Container.classed("combo-borde", value);
        return this;
    }

    public _Configuracion(
        elemento_adjunto: TSelectionHTML,
        datos: TDropdownData<TData>,
        valueMember: VM, displayMember: keyof TData,
        placeholder?: string,
        disabled?: boolean,
        onChange?: (data: TData[VM][], dataObj: TData[]) => void,
        onSelect?: (data: TData[]) => void,
        removeBorder?: boolean,
        onStepItemListUI?: (container: TSelectionHTML, dato: TData, step: TypeStep) => void,
        strict?: boolean
    ): TSelectionHTML<"div"> {

        if (this.config) {
            return null;
        }
        this.config = {
            Parent: elemento_adjunto,
            Data: datos,
            ValueMember: valueMember,
            DisplayMember: displayMember,
            Placeholder: (placeholder ? placeholder : UIUtilLang._GetUIString("general", "selectaoption")),
            OnChange: onChange,
            OnSelect: onSelect,
            RemoveBorder: removeBorder,
            Disabled: disabled,
            AppendInputOnParent: true,
            OnStepItemListUI: onStepItemListUI,
            StrictSelect: strict
        }
        this.CrearElementos(elemento_adjunto);
        return this.elements.Container;
    }
}
