import { DataUtil } from "../../data/util/Util";
import { DataUtilViewInfo, IViewInfoItem } from "../../data/util/ViewInfo";
import { UIUtilIconResources } from "../util/IconResourses";
import { UIUtilLang } from "../util/Language";
import { UIUtilPermission } from "../util/Permission";
import { UIUtilViewUserPreferences } from "../utilView/UserPreferences";
import { UIWindowManager } from "../ventana/WindowManager";
import { Button } from "./Button";

export class ViewInfoControl {
    /**
     *  -  .template_viewinfo_container
     *  -  | > .content
     *  -       > .close_wrapper
     *  -       > .viewinfo_principal
     *             > .info_previous
     *             > .info_current
     *             > .info_next
     *          > .viewinfo_allnav
     */
    private container: d3.Selection<HTMLDivElement, any, any, any>;

    constructor(parentSelection: d3.Selection<HTMLElement, any, any, any>) {
        this.UI_Build(parentSelection);
        this["__HashPathChangeListener"] = UIWindowManager._AddOnHashPathChangeListener(e => this.UI_UpdateViewInfo());
    }

    private UI_Build(parentSelection: d3.Selection<HTMLElement, any, any, any>) {
        // viewInfoContainer
        this.container = parentSelection
            .append("div")
            .attr("class", "template_viewinfo_container")
            .style("width", "0px")
            .style("height", "0px")
            .style("margin-left", "0px");

        let viewInfocontent = this.container
            .append("div")
            .attr("class", "content");

        /* viewInfocontent
            .on("click", () => {
                if (!this.container.classed("expanded")) {
                    this.container.classed("expanded", true);
                }
            }); */

        viewInfocontent.append("div")
            .attr("class", "close_wrapper")
            .append("wc-tooltip");

        Button.BtnClose._GetCloseButtonLight(
            viewInfocontent.select(":scope > .close_wrapper"),
            null,
            "var(--color_text4)" //Color sin uso actualmente
        );

        let viewInfoPrincipal = viewInfocontent
            .append("div")
            .attr("class", "viewinfo_principal");

        viewInfoPrincipal
            .append("img")
            .attr("class", "info_previous")
            .attr("src", UIUtilIconResources.CGeneral.AngleDown)
            .attr("draggable", false);

        viewInfoPrincipal
            .append("div")
            .attr("class", "info_current");

        viewInfoPrincipal
            .append("img")
            .attr("class", "info_next")
            .attr("src", UIUtilIconResources.CGeneral.AngleDown)
            .attr("draggable", false);

        viewInfocontent
            .append("div")
            .attr("class", "viewinfo_allnav")
    }

    private UI_ResetDanceAnimation() {
        let contentElement = this.container
            .select<HTMLDivElement>(":scope > .content")
            .node();

        contentElement.style.animation = "none";
        contentElement.offsetHeight; /** trigger reflow */
        contentElement.style.animation = null;
    }

    private UI_UpdateViewInfo() {
        let winHash = window.location.hash.replace("#", "").split("--")[0];

        type IViewInfo = IViewInfoItem & {
            Focus?: boolean;
        }

        const itemsInfo: IViewInfo[] = DataUtilViewInfo._GetViewInfoOfPath(winHash)
            .filter(d => {
                if (d.Module != null && d.Action != null) {
                    return UIUtilPermission._HasAccionPermission(d.Action, d.Module);
                }
                return true;
            });

        // let userInfoContainer = this.controlContainer.select(":scope > .win_container > .topbar > .userinfo");
        let btnCloseWrapper = this.container.select<HTMLDivElement>(":scope > .content > .close_wrapper");

        const forceWindowInfo = UIUtilViewUserPreferences._GetPrefsForceWindowInfoFocus();
        if (itemsInfo.length) {
            let viewInfocontent = this.container
                .select<HTMLDivElement>(".content")

            viewInfocontent
                .on("click", () => {
                    if (!this.container.classed("expanded")) {
                        let firstItemToRead = itemsInfo.find(d => (!d.Readed));
                        if (firstItemToRead) fnUpdateItemSelected(firstItemToRead);
                        this.container.classed("expanded", true);
                    }
                });

            let infoBtnPrevious = this.container.select(".viewinfo_principal > .info_previous")
                .classed("hide", (itemsInfo.length == 1));

            let infoBtnNext = this.container.select(".viewinfo_principal > .info_next")
                .classed("hide", (itemsInfo.length == 1));

            let infoCurrentItems = this.container.select(".viewinfo_principal > .info_current")

            let infoNavDivs = this.container.select(".viewinfo_allnav")
                .classed("hide", (itemsInfo.length == 1));

            const fnHasUnreadItems = () => {
                return Boolean(itemsInfo.find(d => (!d.Readed)));
            }

            const fnUpdateTooltipTag = () => {
                if (fnHasUnreadItems()) {
                    btnCloseWrapper.select("wc-tooltip")
                        .text(UIUtilLang._GetUIString("general", "omitir"));
                } else {
                    btnCloseWrapper.select("wc-tooltip")
                        .text("");
                }
            }

            const fnUpdateItemSelected = (...items: IViewInfo[]) => {
                DataUtilViewInfo._SetIdReaded(
                    winHash,
                    items
                        .map(d => {
                            d.Focus = true;
                            d.Readed = true;

                            return d.Id;
                        })
                );

                fnUpdateTooltipTag();
            }

            const fnResetItems = () => {
                itemsInfo.forEach(d => d.Focus = false);
            }

            const fnNextOrPrevious = (type: "previous" | "next") => {
                let startsIndex = (type == "previous" ? (itemsInfo.length - 1) : 0);
                let jumps = (type == "previous" ? -1 : 1);
                let currentItemSelected = itemsInfo.find(d => d.Focus);
                let currentIndex = itemsInfo.indexOf(currentItemSelected);
                let nextIndex = startsIndex;

                if (itemsInfo[currentIndex + jumps]) {
                    nextIndex = (currentIndex + jumps);
                }

                fnResetItems();
                fnUpdateItemSelected(itemsInfo[nextIndex]);
                fnUpdateItemsInfoDivs();
                fnUpdateNavDivs();
            }

            const fnUpdateItemsInfoDivs = () => {
                infoCurrentItems
                    .selectAll<HTMLDivElement, IViewInfo>("div")
                    .data(itemsInfo, (d, i) => i.toString())
                    .join(
                        enter => enter
                            .append("div")
                            .attr("class", "hide_scroll")
                            .classed("focus", d => d.Focus)
                            .text(d => d.Description)
                            .style("font-weight", d => (d.IdParent == 0 ? "bold" : null)),
                        update => update
                            .classed("focus", d => d.Focus)
                            .text(d => d.Description)
                            .style("font-weight", d => (d.IdParent == 0 ? "bold" : null)),
                        exit => exit
                            .remove()
                    );
            }

            const fnUpdateNavDivs = () => {
                const fnUpdateNav = (itemNav: d3.Selection<HTMLDivElement, IViewInfo, any, any>) => {
                    return itemNav
                        .classed("focus", d => d.Focus)
                        .on("click", (d, i, items) => {
                            fnResetItems();
                            fnUpdateItemSelected(d);
                            fnUpdateItemsInfoDivs();
                            fnUpdateNavDivs();
                        });
                }
                infoNavDivs
                    .selectAll<HTMLDivElement, IViewInfo>("div")
                    .data(itemsInfo, (d, i) => i.toString())
                    .join(
                        enter => fnUpdateNav(enter.append("div")),
                        update => fnUpdateNav(update),
                        exit => exit.remove()
                    );
            }

            // >> REFRESH ITEMS

            this.UI_ResetDanceAnimation();

            this.container
                .style("width", null)
                .style("height", null)
                .style("margin-left", null);

            if (this.container.classed("expanded")) {
                this.container
                    .select(":scope > .content")
                    .style("animation", "keyframe_anim_rebote 0.4s");
            } else {
                this.container
                    .select(":scope > .content")
                    .style("animation", null);
            }

            let firstItemToRead = itemsInfo.find(d => (!d.Readed));

            if (!firstItemToRead) {
                firstItemToRead = itemsInfo[0];
                firstItemToRead.Focus = true;
                fnUpdateTooltipTag();
            }
            else {
                // >> Existe por lo menos un item no leído
                if (forceWindowInfo) { // Si está habilitado para mostrarse en preferencias
                    fnUpdateItemSelected(firstItemToRead);
                    if (!this.container.classed("expanded")) {
                        this.container.classed("expanded", true);
                    }
                } else {
                    firstItemToRead.Focus = true;
                }
            }
            if (!forceWindowInfo) {
                this.container
                    .classed("expanded", false)
            }

            btnCloseWrapper
                .node()
                .onclick = e => {
                    let markAsReaded = itemsInfo
                        .filter((d) => (!d.Readed));

                    if (markAsReaded.length) {
                        fnUpdateItemSelected(...markAsReaded);
                    }

                    if (this.container.classed("expanded")) {
                        this.container
                            .classed("expanded", false)
                            .select(":scope     > .content")
                            .style("animation", null);
                    }
                    e.stopPropagation();
                };

            infoBtnPrevious
                .on("click", () => {
                    fnNextOrPrevious("previous");
                });
            infoBtnNext
                .on("click", () => {
                    fnNextOrPrevious("next");
                });

            fnUpdateItemsInfoDivs();
            fnUpdateNavDivs();
        }
        else {
            this.container
                .classed("expanded", false)
                // .classed("hide", true);
                .style("width", "0px")
                .style("height", "0px")
                .style("margin-left", "0px");
        }
        // console.warn("Items info", itemsInfo);
    }

    public _Refresh() {
        this.UI_UpdateViewInfo();
    }

    public _Destroy() {
        UIWindowManager._RemoveOnHashPathChangeListener(this["__HashPathChangeListener"]);
    }
}
