export namespace UIUtilElement {
    export function _CreateElementFromHTML<T extends HTMLElement | SVGElement>(htmlString: string): T {
        const div = document.createElement('div');
        div.innerHTML = htmlString.trim();
        return div.firstElementChild as T;
    }

    export function _CreateElementsFromHTML<T extends ChildNode = ChildNode>(htmlString: string): T[] {
        const div = document.createElement('div');
        div.innerHTML = htmlString.trim();
        return Array.from(div.childNodes) as T[];
    }

    export function _AppendHTML(container: HTMLElement, htmlString: string) {
        const nodes = _CreateElementsFromHTML(htmlString)
        nodes.forEach(node => container.appendChild(node))
    }

    export function _GetResizeObserver(callback: ResizeObserverCallback) {
        if (window["ResizeObserver"]) {
            return new ResizeObserver(callback);
        }
        console.warn("-d", "ResizeObserver no soportado");
        return null;
    }

    export function _GetMutationObserver(callback: MutationCallback) {
        if (window["MutationObserver"]) {
            return new MutationObserver(callback);
        }
        console.warn("-d", "MutationObserver no soportado");
        return null;
    }
}