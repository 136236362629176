import { Entidad } from "../../data/Entidad";
import { IConfiguraEscolaridadParams as IConfiguracionToSend } from "../../data/modulo/Escolaridad";
import { UIUtilLang } from "../util/Language";
import { UIUtilViewData } from "../util/ViewData";

export namespace UIUtilViewEscolaridad {
    import CEvento = Entidad.CTipoEvento;
    import CTipoChat = Entidad.CTipoChat;
    // import IEscolaridad = data.Entidades.IEscolaridad;
    import IConfiguracion = Entidad.IConfigEscolaridad;
    import IEventoEscolaridadItem = Entidad.IEventoEscolaridadItem;

    interface ITipoEventoItem extends UIUtilViewData.ITipoEventoItemList<"normal"> {
        IsCheck?: boolean;
    }

    interface IEmocionItem extends UIUtilViewData.IEmocionItemList { }

    interface IItemList {
        IsCheck: boolean;
        TextValue: string;
    }

    interface IBiberonPapillaItem extends IItemList {
        Type: CBiberonPapilla;
    }

    enum CBiberonPapilla {
        Biberon = 1,
        Papilla = 2
    }

    interface IOtrosItem extends IItemList {
        Type: (CEvento.Covid)
    }

    interface IEntradaSalidaItem {
        IsActive: boolean;
        /** Tolerancia */
        CobroTolerancia: number;
        /** Fracción en minutos */
        CobroTiempo: number;
        /** Costo por fracción */
        CobroCosto: number;
    }

    export interface IEscolaridadConfigFormData {
        /** Actividades == null */
        PrimerConfiguracion: boolean;
        Actividades: Map<CEvento, ITipoEventoItem>;
        Emociones: Array<IEmocionItem>;
        AlimentosConfig: {
            Alimentos: Array<IItemList>;
            /** [BiberonConf, PapillaConf] */
            BiberonPapilla: [IBiberonPapillaItem, IBiberonPapillaItem];
        };
        TipoChat: CTipoChat;
        EntradasAnticipadas: IEntradaSalidaItem;
        SalidasRetrasadas: IEntradaSalidaItem;
        Otros: [IOtrosItem];
        // {
        //     Covid: boolean;
        //     Qr: boolean;
        // }
    }

    const MAXALIMENTOS = 5;

    /** Crea la estructura de datos que maneja el formulario en base a la escolaridad */
    export function _GetProcessedConfiguration(idEscuela: number, configuracion: IConfiguracion): IEscolaridadConfigFormData {
        // -> DATO ACTIVIDADES
        let actividadesToForm: ITipoEventoItem[] = UIUtilViewData._GetList_TiposEventos("normal", idEscuela)
            .map<ITipoEventoItem>(d => ({
                Id: d.Id,
                Name: d.Name,
                Icon: d.Icon
            }));

        actividadesToForm.forEach(dToForm => {
            let actConfig = GetConfiguracionEvento(configuracion, dToForm.Id);
            dToForm.IsCheck = Boolean(actConfig);
            // (configuracion.Actividades?.findIndex(dInit => dInit.Tipo == dToForm.Id) > -1)
        })

        // -> DATO EMOCIONES
        // Los items de emociones se deben de controlar por indice de arreglo
        let emocionesToForm: IEmocionItem[] = UIUtilViewData._GetList_Emociones()
            .map<IEmocionItem>(d => ({
                Id: d.Id,
                Name: d.Name,
                Icon: d.Icon
            }))
        // JSON.parse(JSON.stringify(EmocionesDefault));
        let emocionesConfigInicial = GetConfiguracionEvento(configuracion, CEvento.Humor);
        // configuracion.Actividades?.find(d => d.Tipo == CTipoActividadEscolaridad.Humor);

        if (emocionesConfigInicial) {
            // Si ya tenia conf de emociones, reemplazar los textos default
            emocionesToForm.forEach((emoToForm, i) => {
                emoToForm.Name = emocionesConfigInicial.Configuracion[i] as string;
            })
        }
        // else {
        //     console.log("No existe conf emociones inicial");
        // }

        // -> DATO ALIMENTOS
        let alimentosToForm: Array<IItemList> = Array(MAXALIMENTOS);
        let biberonPapillaToForm: [IBiberonPapillaItem, IBiberonPapillaItem] = GetBiberonPapillaList();
        let alimentosConfigInicial = GetConfiguracionEvento(configuracion, CEvento.Alimentos);
        // configuracion.Actividades?.find(d => d.Tipo == CTipoActividadEscolaridad.Alimentos); // FIXME

        for (let i = 0; i < MAXALIMENTOS; i++) {
            let alimentoUser: string = "";
            if (alimentosConfigInicial?.Configuracion?.length) {
                alimentoUser = alimentosConfigInicial?.Configuracion[i];
            } else if (alimentosConfigInicial?.Configuracion === null) {
                console.warn("-d", "Error de configuración (Alimentos), no puede ser null!");
            }
            alimentosToForm[i] = {
                IsCheck: Boolean(alimentoUser),
                TextValue: alimentoUser ? alimentoUser : ""
            }
        }

        let biberonPapillaInitConf = alimentosConfigInicial ? alimentosConfigInicial.ConfiguracionSecundaria : [false, false];
        // Biberón
        biberonPapillaToForm[0].IsCheck = biberonPapillaInitConf[0];
        // Papilla
        biberonPapillaToForm[1].IsCheck = biberonPapillaInitConf[1];

        // -> DATO TIPO CHAT
        let tipoChat = CTipoChat.Nunguno;
        let chatInicial = GetConfiguracionEvento(configuracion, CEvento.Chat);
        // configuracion.Actividades?.find(d => (d.Tipo == CTipoActividadEscolaridad.Chat));
        if (chatInicial) {
            if (chatInicial.Configuracion) {
                if (chatInicial.Configuracion[0] != null) {
                    tipoChat = Number(chatInicial.Configuracion[0]) as number;
                } else {
                    console.log(chatInicial.Configuracion, "chatInicial.Configuracion arreglo no válido");
                }
            } else {
                console.warn("-d", "Error: No hay config de chat", chatInicial);
            }
        }

        // -> DATO ENTRADA ANTICIPADA
        let entradasAntToForm: IEntradaSalidaItem = {
            IsActive: false,
            CobroTolerancia: 0,
            CobroTiempo: 0,
            CobroCosto: 0
        }

        // -> DATO SALIDA RETRASADA
        let salidasAntToForm: IEntradaSalidaItem = {
            IsActive: false,
            CobroTolerancia: 0,
            CobroTiempo: 0,
            CobroCosto: 0
        }

        if (configuracion.CobroTolerancia) {
            entradasAntToForm.CobroTolerancia = configuracion.CobroTolerancia[0];
            salidasAntToForm.CobroTolerancia = configuracion.CobroTolerancia[1];
        }
        if (configuracion.CobroTiempo) {
            entradasAntToForm.CobroTiempo = configuracion.CobroTiempo[0];
            salidasAntToForm.CobroTiempo = configuracion.CobroTiempo[1];
        }
        if (configuracion.CobroCosto) {
            entradasAntToForm.CobroCosto = configuracion.CobroCosto[0];
            salidasAntToForm.CobroCosto = configuracion.CobroCosto[1];
        }
        entradasAntToForm.IsActive = Boolean(entradasAntToForm.CobroTolerancia + entradasAntToForm.CobroTiempo + entradasAntToForm.CobroCosto);
        salidasAntToForm.IsActive = Boolean(salidasAntToForm.CobroTolerancia + salidasAntToForm.CobroTiempo + salidasAntToForm.CobroCosto);

        // -> OTROS: DATO COVID, DATO ENTRADA - SALIDA QR
        const itemCovid = GetConfiguracionEvento(configuracion, CEvento.Covid);
        let otrosConfig = GetConfiguracionOtrosList();
        otrosConfig[0].IsCheck = itemCovid ? itemCovid.Configuracion[0] == 1 : false;

        // -> DATO TO FORM FINAL
        return {
            PrimerConfiguracion: (configuracion.Actividades == null),
            Actividades: new Map(actividadesToForm.map(d => ([d.Id, d]))),
            Emociones: emocionesToForm,
            AlimentosConfig: {
                Alimentos: alimentosToForm,
                BiberonPapilla: biberonPapillaToForm
            },
            TipoChat: tipoChat,
            EntradasAnticipadas: entradasAntToForm,
            SalidasRetrasadas: salidasAntToForm,
            Otros: otrosConfig
            // {
            //     Covid: (itemCovid?.Configuracion?.length ? itemCovid.Configuracion[0] == 1 : false),
            //     Qr: (itemQR?.Configuracion?.length ? itemQR.Configuracion[0] == 1 : false),
            // }
        }
    }

    function GetConfiguracionEvento<K extends CEvento>(config: IConfiguracion, tipo: K): IEventoEscolaridadItem<K> {
        let u = config.Actividades
            ?.find(d => d.Tipo == tipo);

        return u as IEventoEscolaridadItem<K>;
    }

    function GetBiberonPapillaList(): [IBiberonPapillaItem, IBiberonPapillaItem] {
        return [
            { Type: CBiberonPapilla.Biberon, TextValue: UIUtilLang._GetUIString("panelconfiguracionniveles", "tag_biberon"), IsCheck: false },
            { Type: CBiberonPapilla.Papilla, TextValue: UIUtilLang._GetUIString("panelconfiguracionniveles", "tag_papilla"), IsCheck: false }
        ]
    }

    function GetConfiguracionOtrosList(): [IOtrosItem] {
        return [
            { Type: CEvento.Covid, TextValue: UIUtilLang._GetUIString("panelconfiguracionniveles", "tag_covid"), IsCheck: false },
        ]
    }

    // *******************************


    /** TRADUCE Y DEVUELVE LOS CAMBIOS QUE SURGIERON EN LA CONFIGURACIÓN */
    export function _GetFormularioNivelConfigChanges(dataFormFin: IEscolaridadConfigFormData, dataFormInicial: IEscolaridadConfigFormData) {
        let newConfig: IConfiguracionToSend = {
            CobroTolerancia: [dataFormFin.EntradasAnticipadas.CobroTolerancia, dataFormFin.SalidasRetrasadas.CobroTolerancia],
            CobroCosto: [dataFormFin.EntradasAnticipadas.CobroCosto, dataFormFin.SalidasRetrasadas.CobroCosto],
            CobroTiempo: [dataFormFin.EntradasAnticipadas.CobroTiempo, dataFormFin.SalidasRetrasadas.CobroTiempo],
            Actividades: Array.from(dataFormInicial.Actividades.values())
                .filter(actividadInicial => {
                    let actividadFin = dataFormFin.Actividades.get(actividadInicial.Id);
                    if (actividadFin.IsCheck) {
                        let confInicial = null;
                        let confFin = null;

                        switch (actividadFin.Id) {
                            case CEvento.Alimentos:
                                confInicial = dataFormInicial.AlimentosConfig.Alimentos
                                    .map(d => d.TextValue)
                                    .concat(dataFormInicial.AlimentosConfig.BiberonPapilla.map(d => d.IsCheck + ""));

                                confFin = dataFormFin.AlimentosConfig.Alimentos
                                    .map(d => d.TextValue)
                                    .concat(dataFormFin.AlimentosConfig.BiberonPapilla.map(d => d.IsCheck + ""));
                                break;
                            case CEvento.Humor:
                                confInicial = dataFormInicial.Emociones.map(d => d.Name);
                                confFin = dataFormFin.Emociones.map(d => d.Name);
                                break;
                        }

                        if (!actividadInicial.IsCheck) {
                            // Actividad fue agregada
                            // console.log(actividadFin.Type, CTipoActividadEscolaridad[actividadFin.Type], "NO cambió pero fue checkeado");
                            return true;
                        }
                        if (JSON.stringify(confInicial) == JSON.stringify(confFin)) {
                            // Actividad no modificada
                            // console.log(actividadFin.Type, CTipoActividadEscolaridad[actividadFin.Type], "NO cambió");
                            return false;
                        } else {
                            // Actividad modificada
                            // console.log(actividadFin.Type, CTipoActividadEscolaridad[actividadFin.Type], "cambió !!!");
                            return true;
                        }
                    } else {
                        // Actividades no chequeadas: 1.- Nunca lo estuveron. 2.- Fueron descheckeadas
                        // console.log(actividadFin.Type, CTipoActividadEscolaridad[actividadFin.Type], "No es una actividad checheada");
                        return false
                    }
                })
                .map(d => {
                    let config: string[] = [];
                    let configSecundaria: [boolean, boolean] = undefined; // por ahora solo aplica a biberon papilla

                    if (d.Id == CEvento.Alimentos) {
                        config = dataFormFin.AlimentosConfig.Alimentos
                            .map(d => d.TextValue)
                            .filter(text => Boolean(text.trim()));

                        configSecundaria = [dataFormFin.AlimentosConfig.BiberonPapilla[0].IsCheck, dataFormFin.AlimentosConfig.BiberonPapilla[1].IsCheck]
                    }
                    if (d.Id == CEvento.Humor) {
                        config = dataFormFin.Emociones
                            .map(d => d.Name.trim());
                    }
                    return {
                        Tipo: d.Id,
                        Configuracion: config ? config : null,
                        ConfiguracionSecundaria: configSecundaria
                    }
                })
                .concat(dataFormFin.Otros
                    // .filter((d, i) => (d.IsCheck != dataFormInicial.Otros[i].IsCheck))
                    .map(d => ({
                        Tipo: d.Type,
                        Configuracion: [(d.IsCheck ? 1 : 0) + ""],
                        ConfiguracionSecundaria: null
                    }))),
            ActividadesEliminar: Array.from(dataFormInicial.Actividades.values())
                .map(actividadInicial => {
                    let actividadFin = dataFormFin.Actividades.get(actividadInicial.Id);
                    if (actividadInicial.IsCheck && !actividadFin.IsCheck) {
                        // Actividad fué descheckeada
                        // console.log(actividadInicial.Type, CTipoActividadEscolaridad[actividadInicial.Type], "fue deseleccionado -> x");
                        return actividadInicial.Id;
                    }
                    // La actividad está checkeada: 1.- No se descheckeó 2.- Fue checkeada
                    return null;
                })
                .filter(d => (d !== null))
        }

        // -> Si cambió area de chat
        if (dataFormInicial.TipoChat !== dataFormFin.TipoChat) {
            // console.log("Chat conf. fue modificado a", CTipoChat[dataFormFin.TipoChat]);
            if (dataFormFin.TipoChat == CTipoChat.Nunguno) {
                newConfig.ActividadesEliminar.push(CEvento.Chat);
            } else {
                newConfig.Actividades.push({
                    Tipo: CEvento.Chat,
                    Configuracion: [dataFormFin.TipoChat],
                    // ConfiguracionSecundaria: undefined
                })
            }
        }

        return newConfig;
    }
}
