import { Entidad } from "../Entidad";
import { Global } from "../Global";
import { DataDRequest } from "../DRequest";
import { DataUtil } from "../util/Util";
import { DataModuloMain } from "../ModuloMain";

import IEscolaridad = Entidad.IEscolaridad;
import IEscuela = Entidad.IEscuela;

const RequestID = Entidad.CTipoRequest.Escolaridad;
export const _DiccEscolaridad: Map<number, IEscolaridad> = new Map();
// /** Map<IdEscolaridad, Map<IdAlumno, IChild>> */
// export var DiccAsignacionEscolaridadAlumnos: Map<number, Map<number, Entidades.IChild>> = new Map();
/** Map<idEscolaridad, Map<idGrado, Grado(Nivel)>> */
export const _DiccAsignacionEscolaridadGrados: Map<number, Map<number, Entidad.IGrado>> = new Map();

export async function _GetList(escuelas: Array<IEscuela>): Promise<DataDRequest.IRequestResponseListA<IEscolaridad>[]> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "escolaridad/ObtenerLista";
    const results = new Array<DataDRequest.IRequestResponseListA<IEscolaridad>>();

    for await (let escuela of escuelas) {
        const rowID = escuela.IdKinder
        const currentDateStr = await DataUtil._GetMaxDate(RequestID, rowID);
        const params = {
            "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
            "IdEscuela": rowID,
            "Modificacion": currentDateStr
        }

        let result = await DataDRequest._Request4List(url, "GET", params, RequestID, rowID);

        results.push(result);
    }
    return results;
}

export async function _AltaEscolaridad(idEscuela: number, nombreNivel: string, grados: Array<string>): Promise<DataDRequest.IRequestResponseA> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "escolaridad/NuevaEscolaridad";
    const params = {
        IdOrganizacion: DataUtil._Usuario.IdOrganizacion,
        IdUsuario: DataUtil._Usuario.IdUsuario,
        IdEscuela: idEscuela,
        Nombre: nombreNivel,
        Grados: grados
    }
    return DataDRequest._Request5(url, "POST", params, Entidad.CTipoRequest.Escolaridad);
}

export async function _ObtenerConfiguracion(idEscolaridad: number) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "escolaridad/ObtenerConfiguracion";
    const params = {
        IdEscolaridad: idEscolaridad,
        Modificacion: null
    }
    const res = await DataDRequest._Request5<Entidad.IConfigEscolaridad>(url, "GET", params);

    if (res.Resultado > 0 && res.Datos?.Actividades) {
        for (let k in res.Datos.Actividades) {
            let item = res.Datos.Actividades[k];
            switch (item.Tipo) {
                case Entidad.CTipoEvento.Chat:
                    item.Configuracion = [
                        Number((item.Configuracion || [1])[0]) as Entidad.CTipoChat
                    ];
                    break;
                case Entidad.CTipoEvento.Alimentos:
                    if (!item.ConfiguracionSecundaria) {
                        item.ConfiguracionSecundaria = [false, false];
                    }
                    item.ConfiguracionSecundaria[0] = item.ConfiguracionSecundaria[0] + "" == "true" ? true : false;
                    item.ConfiguracionSecundaria[1] = item.ConfiguracionSecundaria[1] + "" == "true" ? true : false;
                    break;
                case Entidad.CTipoEvento.Covid:
                    item.Configuracion = [
                        Number((item.Configuracion || [0])[0]) as 0 | 1
                    ]
                    break;
            }
        }
    }

    return res;
}

export interface IConfiguraEscolaridadResponse {
    Actualizados: Array<number>;
    Eliminados: Array<number>;
    Nuevos: Array<number>;
}
type IEventoEscolaridadItem = Entidad.IEventoEscolaridadItem<Entidad.CTipoEvento>;
type IEventoEscolaridadItemReq = Omit<IEventoEscolaridadItem, keyof Pick<IEventoEscolaridadItem, "EnUso">>;
export interface IConfiguraEscolaridadParams {
    Actividades: Array<IEventoEscolaridadItemReq>,
    ActividadesEliminar: Array<number>,
    CobroTiempo: Array<number>,
    CobroCosto: Array<number>,
    CobroTolerancia: Array<number>
}
export async function _ConfigurarEscolaridad(idEscolaridad: number, params: IConfiguraEscolaridadParams): Promise<DataDRequest.IRequestResponseA<IConfiguraEscolaridadResponse>> {
    let url = Global._GLOBAL_CONF.URL_RSTV2 + "escolaridad/ConfigurarEscolaridad";
    const finalParams: IConfiguraEscolaridadParams & { IdUsuario: number, IdEscolaridad: number } = {
        IdUsuario: DataUtil._Usuario.IdUsuario,
        IdEscolaridad: idEscolaridad,
        Actividades: params.Actividades,
        ActividadesEliminar: params.ActividadesEliminar,
        CobroTiempo: params.CobroTiempo,
        CobroCosto: params.CobroCosto,
        CobroTolerancia: params.CobroTolerancia
    }
    return DataDRequest._Request5<IConfiguraEscolaridadResponse>(url, "POST", finalParams, Entidad.CTipoRequest.Escolaridad);
}

interface IEditarEscolaridadResponse {
    Actualizados: number[];
    Eliminados: number[];
    Nuevos: number[];
}
interface IItemGrado {
    /** Los grados a actualizar requieren Id */
    Id?: number;
    Nombre: string;
}
export async function _ActualizarEscolaridad(idEscolaridad: number, nombreEscolaridad: string, grados: IItemGrado[], gradosEliminar: number[]): Promise<DataDRequest.IRequestResponseA<IEditarEscolaridadResponse>> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "escolaridad/ActualizarEscolaridad";
    const params = {
        "IdEscolaridad": idEscolaridad,
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "Nombre": nombreEscolaridad,
        "Grados": grados,
        "GradosEliminar": gradosEliminar
    }
    return DataDRequest._Request5(url, "POST", params, Entidad.CTipoRequest.Escolaridad);
}

export async function _EliminarEscolaridad(idEscolaridad: number): Promise<DataDRequest.IRequestResponseA> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "escolaridad/EliminarEscolaridad";
    const params = {
        "IdEscolaridad": idEscolaridad,
        "IdUsuario": DataUtil._Usuario.IdUsuario
    }
    return DataDRequest._Request5(url, "POST", params, Entidad.CTipoRequest.Escolaridad);
}

// /** Resultado
//  * * (> 0) - Exitoso
//  * * (-1) - Escolaridad no encontrada
//  * * (-2) - No tiene configuración para tipos de alimento
//  * * (-3) - Calendario no configurado (no vinculado)
//  */
// export async function _AsignarCalendarioGoogle(idEscolaridad: number): Promise<DataDRequest.IRequestResponseA> {
//     const url = Global._GLOBAL_CONF.URL_RSTV2 + "escolaridad/AsignarCalendariosAlimento";
//     const params = {
//         "IdUsuario": DataUtil._Usuario.IdUsuario,
//         "IdEscolaridad": idEscolaridad
//     }
//     return DataDRequest._Request5(url, "POST", params, Entidad.CTipoRequest.Escolaridad);
// }

// *************************************************************
// LOCAL DATA
// *************************************************************

/**
 * @param idEscolaridad
 * @returns Map()
 */
export function _LOCALDATA_GetGradosDeEscolaridad(idEscolaridad: number): Map<number, Entidad.IGrado> {
    return (_DiccAsignacionEscolaridadGrados.get(idEscolaridad) || new Map());
}

/**
* @param idEscolaridad
* @returns Array()
*/
export function _LOCALDATA_GetGruposEnEscolaridad(idEscolaridad: number): Array<Entidad.IGrupo> {
    return DataModuloMain._GetReqDataArrayByName("Grupo")
        .filter(d => (d.IdEscolaridad == idEscolaridad));
}

/**
 * @param idEscolaridad
 * @returns Array()
 */
export function _LOCALDATA_GetAlumnosEnEscolaridad(idEscolaridad: number): Array<Entidad.IAlumno> {
    return DataModuloMain._GetReqDataArrayByName("Alumno")
        .filter(d => (d.IdEscolaridad == idEscolaridad));
}

export default {
    _DiccEscolaridad,
    _DiccAsignacionEscolaridadGrados,
    _AltaEscolaridad,
    _ObtenerConfiguracion,
    _ConfigurarEscolaridad,
    _ActualizarEscolaridad,
    _EliminarEscolaridad,
    _LOCALDATA_GetGradosDeEscolaridad,
    _LOCALDATA_GetGruposEnEscolaridad,
    _LOCALDATA_GetAlumnosEnEscolaridad
}
