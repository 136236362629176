type TStyleCheck = "quadrate" | "circle" | "circle-check";

const TRANSITIONDURATION = 300;
const TEMPLATE = document.createElement("template");
TEMPLATE.innerHTML = `
<style>
    :host {
        display: inline-block;
        min-width: 5px;
        min-height: 5px;
        width: 18px;
        height: 18px;
    }

    /* DEFAULT STYLE */

    .k_checkbox {
        width: 100%;
        height: 100%;
        border: gray solid 2px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px;
    }

    .k_checkbox>.check_action {
        background-color: rgb(117, 117, 117);
        position: relative;
        transition: transform ${TRANSITIONDURATION}ms;
    }

    .disabled {
        opacity: .8;
        pointer-events: none;
        cursor: default;
    }

    /* QUADRATE STYLE */

    .quadrate>.check_action {
        width: 70%;
        height: 70%;
        border-radius: 1px;
        transform: scale(0);
    }

    .quadrate[checked]>.check_action {
        transform: scale(1);
    }

    /* CIRCLE STYLE */

    .circle {
        border-radius: 50%;
    }

    .circle>.check_action {
        border-radius: 50%;
        width: 70%;
        height: 70%;
        transform: scale(0);
    }

    .circle[checked]>.check_action {
        transform: scale(1);
    }

    /* CIRCLE-CHECK STYLE */

    .circle-check {
        border-radius: 50%;
        border-color: whitesmoke !important;
        border-width: 1px !important;
        background-color: rgba(1, 1, 1, .4);
    }

    .circle-check>.check_action {
        /* transition: transform ${TRANSITIONDURATION}ms !important; */
        transition: none;
        transform: rotate(45deg) skewY(2deg) scaleY(0);
        box-sizing: border-box;
        background-color: transparent !important;
        width: 40%;
        height: 70%;
    }

    .circle-check>.check_action::before {
        content: "";
        display: inline-block;
        width: 100%;
        height: 22%;
        background-color: white;
        position: absolute;
        bottom: 0;
    }

    .circle-check>.check_action::after {
        content: "";
        display: inline-block;
        width: 40%;
        height: 100%;
        background-color: white;
        position: absolute;
        right: 0;
    }

    .circle-check[checked] {
        background-color: rgb(44, 144, 255);
    }

    .circle-check[checked]:hover {
        background-color: rgb(40, 135, 240);
    }

    .circle-check[checked]>.check_action {
        transform: rotate(40deg) skewY(2deg) scaleY(1);
    }
</style>

<div class="k_checkbox">
    <div class="check_action"></div>
</div>
`

// '', '', /* 'color',*/ ''
/** `wc-checkbox`
 * Attributes
 * * `checked`:
 * * `disabled`:
 * * `color`:
 * * `style-form`: `"circle"` | `"circle-check"` | `"quadrate"`
 */
export class HTMLCheckBoxElement extends HTMLElement {
    #controlContainerElement: HTMLDivElement;
    // #actionElement: HTMLDivElement;
    // #currentParentElement: HTMLElement;

    #disabledStatus: boolean;

    #evOnchange: (e: Event) => void;

    constructor() {
        super();
        this.attachShadow({ mode: "open" });

        this.shadowRoot.appendChild(TEMPLATE.content.cloneNode(true));

        this.#controlContainerElement = this.shadowRoot.querySelector(".k_checkbox");
        // this.#actionElement = this.shadowRoot.querySelector(".check_action");

        this.#disabledStatus = false;
        this.#controlContainerElement.classList.add("quadrate");
    }

    // *****************************************************************************
    // PRIVATE METHODS
    // *****************************************************************************

    private Data_SetCheckStatus(checked: boolean) {
        if (checked) {
            this.setAttribute("checked", "");
        } else {
            this.removeAttribute("checked");
        }
    }

    private OnChange(e: Event) {
        if (this.#evOnchange) {
            this.#evOnchange(e);
        }
    }

    // *****************************************************************************
    // ELEMENT LIFE CICLE CALLBACKS
    // *****************************************************************************

    private checkboxOnClickEvent: (e: MouseEvent) => void;

    /** Invocado cuando el componente personalizado se conecta por primera vez al DOM del documento. */
    connectedCallback() {
        this.checkboxOnClickEvent = (e) => {
            if (!this.#disabledStatus) {
                this.Data_SetCheckStatus(!this.hasAttribute("checked"));
                this.OnChange(e);
            }
            e.stopPropagation();
        }

        this.#controlContainerElement.addEventListener("click", this.checkboxOnClickEvent);
    }

    /** Invocado cuando el componente personalizado se deconecta del DOM del documento. */
    disconnectedCallback() {
        this.#controlContainerElement.removeEventListener("click", this.checkboxOnClickEvent);
    }

    /** Invocado cuando el componente personalizado se mueve a un nuevo documento. */
    adoptedCallback(oldDocument: Document, newDocument: Document) {
    }

    /** Invocado cuando uno de los atributos del componente personalizado es añadido, removido o modificado. */
    attributeChangedCallback(attrName: string, oldValue: string, newValue: string) {
        switch (attrName) {
            case "checked":
                const checked = this.hasAttribute("checked");

                if (checked) {
                    this.#controlContainerElement.setAttribute("checked", "");
                }
                else {
                    this.#controlContainerElement.removeAttribute("checked");
                }
                break;
            case "disabled":
                this.#disabledStatus = this.hasAttribute("disabled");

                if (this.#disabledStatus) {
                    this.#controlContainerElement.classList.add("disabled");
                }
                else {
                    this.#controlContainerElement.classList.remove("disabled");
                }
                break;
            case "style-form":
                let styleForm = (this.getAttribute("style-form") as TStyleCheck);

                if (styleForm != "circle" && (styleForm != "circle-check") && (styleForm != "quadrate")) {
                    styleForm = "quadrate";
                }

                this.#controlContainerElement.classList.remove("circle");
                this.#controlContainerElement.classList.remove("circle-check");
                this.#controlContainerElement.classList.remove("quadrate");
                this.#controlContainerElement.classList.add(styleForm);
                break;
            case "color":
                this.#controlContainerElement.style.borderColor = newValue;
                this.#controlContainerElement.querySelector<HTMLDivElement>(".check_action")
                    .style.backgroundColor = newValue;
                break;
        }
    }

    static get observedAttributes() {
        return ['checked', 'disabled', 'color', 'style-form'];
    }

    // *****************************************************************************
    // PUBLIC PROPERTIES
    // *****************************************************************************

    public get _Checked() {
        return this.hasAttribute("checked");
    }

    public set _Checked(value: boolean) {
        this.Data_SetCheckStatus(value);
    }

    public set _OnChange(fn: (e: Event) => void) {
        this.#evOnchange = fn;
    }

    public set _Color(color: string) {
        this.setAttribute("color", color);
    }

    public get _Color() {
        return this.getAttribute("color");
    }
}

customElements.define("wc-checkbox", HTMLCheckBoxElement);
