import { DataDRequest } from "../DRequest";
import { Global } from "../Global";


export async function _SvArchivoDescargarPlantillaExcel() {
    const url = Global._GLOBAL_CONF.URL_SrvRoot + "archivo/importar/plantilla/ObtenerExcel?IdEscuela=0&IdCategoria=20";
    const filename = "Alumnos.xlsx";

    return DataDRequest._DownloadFile(url, filename);
}
