import { MainPage } from "../../MainPage";
import { DataDRequest } from "../../data/DRequest";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import { _SvAlumnoObtenerListaNoticias } from "../../data/modulo/Alumno";
import DataModuloEscuela from "../../data/modulo/Escuela";
import { IMapSendCincularItems, _SvNoticiaNueva } from "../../data/modulo/Noticia";
import { TCARDV2COLL_OnEditOriginEvent } from "../controlD3/CardV2Collapse";
import { CardV2CollapseAdvancedTable, IConfigCardV2CollapseExcelExport } from "../controlD3/CardV2CollapseAdvancedTable";
import { ModalThings } from "../controlD3/ModalThings";
import { Table } from "../controlD3/Tabla";
import { UIUtilIconResources } from "../util/IconResourses";
import { UIUtilLang } from "../util/Language";
import { UIUtilMonitorRequestObserver } from "../util/MonitorRequestObserver";
import { UIUtilStrings } from "../util/Strings";
import { UIUtilTime } from "../util/Time";
import { UIUtilViewData } from "../util/ViewData";
import { UIUtilViewCircular } from "../utilView/Circular";

import IAlumno = Entidad.IAlumno;
import CAutorizado = Entidad.CCircularEstadoAutorizacion;

interface ICircularAlumno extends Entidad.ICircularAlumno {
    Icon: any;
    StrAutorizacion: string;
    StrFecha: string;
}

export class UIPanelCardAlumnosCircular extends CardV2CollapseAdvancedTable<ICircularAlumno, [IAlumno]> {

    private alumnoCurrent: IAlumno;

    constructor(modulo: Entidad.CModulo.PanelCircular) {
        super("", modulo);
    }

    protected CARDCOLLADTAB_Table_GetMenuTop(): Table.ITableMenuTopDefaultOptionConfig[] {
        let opcionesTop: Table.ITableMenuTopDefaultOptionConfig[] = [];

        if (this.HasActionPermission(Entidad.CAccionPermiso.Agregar)) {
            opcionesTop = [{
                Label: "action_agregar",
                Callback: () => this.OpenModal_FormularioAgregar(),
                GetDetails: () => {
                    let enable = (this.cardData[0].IdChildMovimiento == Entidad.CNinioMovimiento.Activo);
                    return {
                        Enabled: enable,
                        Description: enable ? '' : this.CARDCOLL_GetUIStringModule("tlt_agreegar_fail_inactive")
                    }
                }
            }]
        }
        return opcionesTop;
    }
    protected CARDCOLLADTAB_GetExportarConfig(dataTable: ICircularAlumno[]): IConfigCardV2CollapseExcelExport<any> | Promise<IConfigCardV2CollapseExcelExport<any>> {
        return {
            FileName: this.cardData[0].NombreCompleto + " - " + UIUtilViewData._GetStr_Modulo("circulares"),
            IdsEscuelas: [this.cardData[0].IdKinder],
            TypeRequest: Entidad.CTipoRequest.Circular,
            ColumnsConfig: this.ctrlTabla._InfoColumns
                .filter(d => (d.Field != "Icon"))
                .map(d => ({
                    Field: d.Field,
                    HeaderTag: d.Label,
                    WidthCell: (d.Field == "Mensaje" ? 35 : 30)
                })),
            OnGetDataBySheets: async () => {
                return [{
                    IdSheet: this.cardData[0].IdKinder, // IdEscuela
                    SheetName: this.cardData[0].NombreCompleto,
                    Data: dataTable
                }]
            },
            OnGetEscuelasTagInSheet: (dataSheet) => DataModuloEscuela._DiccEscuela.get(this.cardData[0].IdKinder).Nombre,
            OnStepFieldInDataRow: async (field, dato) => {
                return dato[field] as string;
            }
        }
    }
    protected CARDCOLLADTAB_Table_GetConfig(): Omit<Table.IConfig<ICircularAlumno>, "Parent"> {
        return {
            IdTabla: "AlumnosPanelCircular",
            IdData: "IdNoticia",
            OrderDefault: { Type: Table.CStatusOrder.Desc, Field: "StrFecha" },
            MinWidth: 850,
            StickyCheckInRow: true,
            RenderColumnHeadings: [
                { Field: "Icon", Label: "", Width: "2%", MinWidth: "45px", IsSortable: false, Align: "center", Icon: UIUtilIconResources.CGeneral.Circular2 },
                { Field: "Titulo", Label: "Título", Width: "25%", MinWidth: "75px" },
                { Field: "Mensaje", Label: "Mensaje", Width: "30%", MinWidth: "75px", OrderTypeParse: String },
                { Field: "StrFecha", Label: "Fecha", Width: "25%", MinWidth: "75px", OrderField: "DtProgramado", OrderTypeParse: Date },
                { Field: "StrAutorizacion", LabelLangKey: "d_field_autorizacion", Label: "Respuesta", Width: "15%", MinWidth: "100px", },
            ],
            OptionsTopDefaultV2: {
                MaxOptionsInRow: 30,
                Options: []
            },
            OptionsOfDataCheckV3: (datos) => this.CARDCOLLADTAB_GetTableOptionsToSelectedData(this.cardData[0].IdKinder, "top-selected"),
            EvaluatorAndSubLevelsBuild: {
                OnStepCellTable: (container, datum, field: keyof ICircularAlumno) => {
                    let { IdNoticia, Titulo, Mensaje } = datum;
                    switch (field) {
                        case "Icon":
                            UIUtilViewCircular._UIStepContentIcoElement(container, { IdNoticia, Mensaje, Titulo }, this.modulo);
                            break;
                        case "Mensaje":
                            container.html(UIUtilStrings._AutoLink(Mensaje).outerHTML);
                            break;
                    }
                }
            }
        }
    }
    protected CARDCOLL_OnInitBuild(contentContainer: TSelectionHTML<"div", any, any>): void {
        this.btnEditarCard._d3Selection.remove();
        this.cardFooterSelection.remove();
        /* if (!this["__workerListenerAlumno"]) {
            this["__workerListenerAlumno"] = MainPage.fn_AddEventListenerWorkerRequest(Entidad.CTipoRequest.Alumno, (e) => {
                if (!this.cardData) return;
                let alumnoUpdt = e.detail.Data.find(alumno => alumno.IdChild == this.cardData[0].IdChild);
                if (alumnoUpdt) {
                    console.log("Actualizando data");
                    this.cardData[0] = alumnoUpdt;
                }
            })
        } */
    }
    protected CARDCOLL_GetVariantToValidateUpdate(cardData_0: IAlumno): string {
        return null;
    }
    protected CARDCOLL_OnUpdateData(cardData_0: IAlumno): void | Promise<void> {
        let changedAlumno = (!this.alumnoCurrent || this.alumnoCurrent.IdChild !== cardData_0.IdChild);
        this.alumnoCurrent = cardData_0;
        return this.UI_UpdateCardData(changedAlumno, false);
    }
    protected CARDCOLL_MostrarBody(): void {
        this.UI_UpdateCardData(false);
        this.cardContentContainerSelection.style("height", "100%");
        MainPage._ActivarServiceMonitor(Entidad.CTipoRequest.Circular, true);
        new UIUtilMonitorRequestObserver()
            ._SetCancelingElement(this.cardSelection.node())
            ._SetCallback(Entidad.CTipoRequest.Circular, ({ Data: datos }) => {
                console.log("Escuché Req: Circular");
                if (datos.find(circular => circular.Alumnos.find(id => id == this.cardData[0].IdChild))) {
                    console.log("Entré para update table");
                    this.UI_UpdateCardData(false, false);
                }
            })
            ._ObserveReq(Entidad.CTipoRequest.Circular)
    }
    protected CARDCOLL_OcultarBody(): void {
        this.cardContentContainerSelection.style("height", null);
        MainPage._ActivarServiceMonitor(Entidad.CTipoRequest.Circular, false);
    }
    protected CARDCOLL_OnEditarCard(originEvent: TCARDV2COLL_OnEditOriginEvent): void {
        //throw new Error("Method not implemented.");
    }
    protected CARDCOLL_OnCancelaEditarCard(originEvent: TCARDV2COLL_OnEditOriginEvent): void {
        //throw new Error("Method not implemented.");
    }
    protected CARDCOLL_GuardarCardV2(): Promise<DataDRequest.IRequestResponseA<any>> {
        throw new Error("Method not implemented.");
    }
    protected CARDCOLL_SyncOrGetIdToDownloadData(): DataModuloMain.TipoRequestMonitorId | DataModuloMain.TipoRequestMonitorId[] | (() => Promise<any>) {
        return () => this.UI_UpdateCardData();
        //return Entidad.CTipoRequest.Circular;
    }
    protected CARDCOLL_GetIdSchool(cardData_0_0: IAlumno): number {
        return cardData_0_0.IdKinder;
    }

    /* public met_Destroy(): this {
        super.met_Destroy();
        MainPage.fn_RemoveEventListenerWorkerRequest(Entidad.CTipoRequest.Alumno, this["__workerListenerAlumno"]);
        this["__workerListenerAlumno"] = null;
        return this;
    } */

    private OpenModal_FormularioAgregar() {
        const typeAction = Entidad.CAccionPermiso.Agregar;
        // const typeRequest = data.Entidades.CTipoRequest.Circular;

        ModalThings._GetModalToForm<UIUtilViewCircular.ICircularForm>({
            Title: Entidad.CAccionPermiso[typeAction],
            Action: typeAction,
            Modulo: this.modulo,
            GetForm: () => UIUtilViewCircular._GetNoticeForm(this.modulo as Entidad.CModulo.PanelCircular, Entidad.CAccionPermiso.Agregar, "childPanel", <Entidad.ICircular>{ IDKinder: this.cardData[0].IdKinder }).Form,
            OnAccept: async (form, modalThings) => {
                let res = await this.Sv_RegistrarCircular(form._Data);
                if (res.Resultado > 0) {
                    this.ctrlProgress.attr("oculto", false);
                }
                return res;
            },
        })
    }

    private UI_UpdateCardData(clearData = false, showProgress = true): Promise<void> {
        if (!this.CARDCOLL_StatusCardExpandido || this.cardData == undefined) {
            this.ctrlTabla._UpdateData([]);
            return null;
        }
        return new Promise<void>(async (resolve, reject) => {
            if (showProgress) {
                this.ctrlProgress.attr("oculto", false);
            }

            if (clearData) this.ctrlTabla._UpdateData([]);
            const data = await this.GetAlumnoCirculares();
            if (data) this.ctrlTabla._UpdateData(data);

            //if (forceRequestData) {
            this.ctrlProgress.attr("oculto", true);
            //}
            resolve();
        })
    }

    // **************************************************************************
    // PERMISOS COSAS
    // **************************************************************************

    // **************************************************************************
    // SERVICIOS COSAS
    // **************************************************************************

    private async GetAlumnoCirculares(/* forceRequestData = false */) {
        //Si el niño no está activo => No se muestran circulares ??
        if (this.cardData[0]?.IdChildMovimiento !== Entidad.CNinioMovimiento.Activo) return [];

        let alumnoCircularesMap = new Map<number, ICircularAlumno>();
        let resAlumnoCircular = await _SvAlumnoObtenerListaNoticias(this.cardData[0].IdChild);

        if (resAlumnoCircular.Resultado > 0) {
            resAlumnoCircular.Datos.map<ICircularAlumno>(d => (
                {
                    ...d,
                    StrAutorizacion: CAutorizado[d.EstadoAutorizado] || "NA",
                    Icon: null, StrFecha: (d.DtProgramado) ? UIUtilTime._DateFormatStandarFixTimeZoneByIdSchool(d.DtProgramado, this.cardData[0].IdKinder, "dd/mm/yyyy h12:mm") : UIUtilLang._GetUIString("general", "sinconfig")
                }
            )).forEach(circular => alumnoCircularesMap.set(circular.IdNoticia, circular));
            return Array.from(alumnoCircularesMap.values());
        } else {
            this.ctrlNotification._Mostrar(UIUtilLang._GetUIString("general", "notif_fail_infoupdate"), "ADVERTENCIA");
            return null;
        }
    }

    /* private async GetCirculares(forceRequestData = false): Promise<ICircular[]> {
        if (this.cardData[0]?.IdChildMovimiento !== Entidad.CNinioMovimiento.Activo) return [];

        const gruposAlumno = Data.Modulo.Alumno.fn_LOCALDATA_GetGruposHorariosDeAlumno(this.cardData[0].IdChild, this.cardData[0].IdKinder);

        if (forceRequestData) {
            const res = await MainPage.fn_ReloadServiceAndAwaitBool(Entidad.CTipoRequest.Circular, this.cardData[0].IdKinder);
            if (!res) {
                this.ctrlNotification.met_Mostrar(UIUtilLang.fn_GetUIString("general", "notif_fail_infoupdate"), "ADVERTENCIA");
            }
        }

        return DataModuloMain.fn_GetReqDataArrayByName("Circular")
            .filter(circular => {
                let isValid = false;
                // console.log(this.cardData[0].Nombre, this.cardData[0].IdGrado, Array.from(this.cardData[0].IdGrupos.keys()), " --- ", dCircular.Titulo, dCircular.Niveles, dCircular.Grupos);
                if (circular.Alumnos.length > 0) {
                    isValid = circular.Alumnos.findIndex(idAlumno => (idAlumno == this.cardData[0].IdChild)) >= 0;
                }
                else if (circular.Niveles && this.cardData[0].IdGrado) {
                    isValid = circular.Niveles.findIndex(idNivel => (idNivel == this.cardData[0].IdGrado)) >= 0;

                    if (isValid && circular.Grupos?.length) {
                        isValid = circular.Grupos.findIndex(idGrupo => {
                            for (let [_, grupo] of gruposAlumno) {
                                if (idGrupo == grupo.IdGrupo) {
                                    return true;
                                }
                            }
                            return false;
                        }) >= 0;
                    }
                }
                return isValid;
            })
            .map<ICircular>(dato => {
                const dd: ICircular = Util._ObjectAddGetters(Object.assign(<ICircular>{}, dato as ICircular), {
                    getStrAutorizacion: () => {
                        if (dd.ReqAutorizacion) {
                            if (dd.AlumnoDestinoInfo) {
                                return UIUtilViewData.fn_GetStr_EdoAutorizacion(dd.AlumnoDestinoInfo.GruposInfoAutorizacion[0].Autorizado);
                            } else {
                                return UIUtilLang.fn_GetUIString("general", "nodisponible");
                            }
                        } else {
                            return "N/A";
                        }
                    }
                });
                return dd;
            })
    } */

    private async Sv_RegistrarCircular(dataForm: UIUtilViewCircular.ICircularForm) {
        let res = await _SvNoticiaNueva("ByAlumnos", <IMapSendCincularItems["ByAlumnos"]>{
            IdEscuela: this.cardData[0].IdKinder,
            Titulo: dataForm.Titulo,
            Mensaje: dataForm.Mensaje,
            Archivo: ((dataForm.Archivo as any instanceof File) ? dataForm.Archivo : null) as any as File,
            Programado: dataForm.Programado,
            ReqAutorizacion: dataForm.ReqAutorizacion,
            IdAlumnos: [this.cardData[0].IdChild],
            Expira: dataForm.Expira,
        });

        if (res.Resultado > 0) {
            MainPage._ReloadService(Entidad.CTipoRequest.Circular, this.cardData[0].IdKinder);
        }

        return res;
    }
}
