import * as d3 from "d3";
import _L from "../../util/Labels";
import { UIUtilElement } from "../util/Element";
import { UIUtilTime } from "../util/Time";
import { UIUtilGeneral } from "../util/Util";

type DivSelection = d3.Selection<HTMLDivElement, any, HTMLElement, any>;
export enum TypeDateInput {
    DateRange = 1,
    // Year = 2,
    // YearMonth = 3,
    OnlyADate = 4
}

export interface IConfigDateInput {
    Parent: d3.Selection<HTMLDivElement, any, HTMLElement, any>
    /** @default [TypeDateInput.DateRange, TypeDateInput.OnlyADate] */
    Types?: TypeDateInput[]
    OnChange?: (date: IDateVal) => void
    /** Si es `string`, se ajustará a `Date` (`new Date(MinDate)`) */
    MinDate?: string | Date
    /** Si es `string`, se ajustará a `Date` (`new Date(MinDate)`) */
    MaxDate?: string | Date
    IncludeTime?: boolean
}

export interface IDateVal {
    dateA: Date,
    dateB: Date,
    typeDateInput: TypeDateInput
}


export class DateInput {
    private config: IConfigDateInput;
    private control: DivSelection;
    // private typeDateInput: TypeDateInput;
    private values: IDateVal;
    private options: Partial<{ [x in TypeDateInput]: DivSelection }>

    constructor(config: IConfigDateInput) {
        this.values = <IDateVal>{};
        this.options = {};
        this.SetConfig(config);
        this.DrawControl();
    }

    private SetConfig(config: IConfigDateInput) {
        let defaultConfig: IConfigDateInput = {
            Parent: null,
            Types: [TypeDateInput.DateRange, TypeDateInput.OnlyADate],
            // MinDate: new Date("1980-01-01").toISOString()
        }
        this.config = { ...defaultConfig, ...config };
        if (this.config.Types.length > 0) {
            // this.value.typeDateInput = this.config.Types[0];
        }
    }

    private DrawControl() {
        this.control = this.config.Parent.append<HTMLDivElement>("div")
            .classed("date-input", true);
        this.control.append("div")
            .classed("area-input", true);

        let areaOptions = this.control.append("div")
            .classed("area-options", true);

        this.config.Types.forEach(typeInput => {
            const includeTime = this.config.IncludeTime
            // const hideTime = includeTime ? "" : `class="hide"`
            switch (typeInput) {
                case TypeDateInput.DateRange:

                    let areaDateRange_ = UIUtilElement._CreateElementFromHTML(`
                    <div class="area-date-range">
                        <label>${"Fecha de inicio "} ${includeTime ? "(Hora opcional)" : ""}</label>
                        <div class="${UIUtilGeneral.FBoxOrientation.Horizontal} ${UIUtilGeneral.FBoxAlign.StartCenter}" style="gap:var(--padding1)">
                            <input
                                type="date"
                                class="filter_input date-start"
                                min="${this.GetDateToInput(this.config.MinDate)}"
                                max="${this.GetDateToInput(this.config.MaxDate)}"
                            >
                            <input type="time" class="filter_input time-start ${includeTime ? "" : "hide"}" style="width:60%">
                        </div>
                        <label>${"Fecha fin"} ${includeTime ? "(Hora opcional)" : ""}</label>
                        <div class="${UIUtilGeneral.FBoxOrientation.Horizontal} ${UIUtilGeneral.FBoxAlign.StartCenter}" style="gap:var(--padding1)">
                            <input
                                type="date"
                                class="filter_input date-end"
                                min="${this.GetDateToInput(this.config.MinDate)}"
                                max="${this.GetDateToInput(this.config.MaxDate)}"
                            >
                            <input type="time" class="filter_input time-end ${includeTime ? "" : "hide"}" style="width:60%">
                        </div>
                    </div>`)
                    const areaDateRange = d3.select<HTMLDivElement, any>(areaDateRange_ as any);

                    areaDateRange.selectAll<HTMLInputElement, any>("input")
                        .each((_, i, arr) => {
                            let selInpt = d3.select(arr[i]);
                            // if (selInpt.attr("type") == "time")
                            //     selInpt.style("margin", 0, "important");
                            this.ApplyEvents(selInpt);
                        });

                    let opcRangeDate = areaOptions.append("div")
                        .classed("opc-daterange", true)
                        .on("click", () => {
                            this.values.typeDateInput = TypeDateInput.DateRange;
                            this.ShowInputArea();
                        });
                    opcRangeDate.append("label").text("Rango de fehas");
                    this.options[TypeDateInput.DateRange] = areaDateRange;
                    break;

                case TypeDateInput.OnlyADate:
                    let areaDate_ = UIUtilElement._CreateElementFromHTML(`
                    <div class="area-date">
                        <label>${_L("general.fecha")}*</label>
                        <input
                            type="date"
                            class="filter_input date-start"
                            min="${this.GetDateToInput(this.config.MinDate)}"
                            max="${this.GetDateToInput(this.config.MaxDate)}"
                        >
                        <label class="${includeTime ? "" : "hide"}">${"Rango horas (opcional)"}</label>
                        <div class="${UIUtilGeneral.FBoxOrientation.Horizontal} ${UIUtilGeneral.FBoxAlign.StartCenter} ${includeTime ? "" : "hide"}" style="gap:var(--padding1)">
                            <input type="time" class="filter_input time-start">
                            <span> - </span>
                            <input type="time" class="filter_input time-end">
                        </div>
                    </div>
                    `)
                    const areaDate = d3.select<HTMLDivElement, any>(areaDate_ as any);
                    areaDate.selectAll<HTMLInputElement, any>("input")
                        .each((_, i, arr) => {
                            let selInpt = d3.select(arr[i]);
                            if (selInpt.attr("type") == "time")
                                selInpt.style("margin", 0, "important");
                            this.ApplyEvents(selInpt);
                        });


                    let opcDate = areaOptions.append("div")
                        .classed("opc-onlyadate", true)
                        .on("click", () => {
                            this.values.typeDateInput = TypeDateInput.OnlyADate;
                            this.ShowInputArea();
                        });
                    opcDate.append("label").text("Fecha específica");

                    this.options[TypeDateInput.OnlyADate] = areaDate;
                    break;
            }
        })
        this.ShowInputArea()
    }

    private ApplyEvents(element: d3.Selection<HTMLInputElement, any, HTMLElement, any>) {
        const elInput = element.node();
        elInput.oninput = (e) => {
            const strValue = elInput.value;
            const type = this.values.typeDateInput
            if (element.classed("date-start")) {
                this.SetDateByStr(this.values.dateA, strValue);
                if (type == TypeDateInput.OnlyADate) {
                    this.SetDateByStr(this.values.dateB, strValue);
                    // this.values.dateB.setDate(this.values.dateB.getDate() + 1)
                }
                if (type == TypeDateInput.DateRange)
                    this.SetMinDate(strValue);
            }
            else if (element.classed("date-end")) {
                this.SetDateByStr(this.values.dateB, strValue);
                if (type == TypeDateInput.DateRange)
                    this.SetMaxDate(strValue);
            }
            else if (element.classed("time-start")) {
                this.SetHourByStr(this.values.dateA, strValue || "00:00:00")
            }
            else if (element.classed("time-end")) {
                this.SetHourByStr(this.values.dateB, strValue || "23:59:59", 999)
            }
            if (this.config.OnChange) {
                this.config.OnChange(this.values);
            }
            console.warn("change values", strValue, this.values);
        }
    }

    private ShowInputArea() {
        let areaViewInput = this.control.select(".area-input");
        areaViewInput.selectAll(":scope>div").remove();
        let areaOptions = this.control.select(".area-options");
        areaOptions.selectAll(":scope>div").classed("opc-selected", false);

        if (!this.values.dateA)
            this.values.dateA = this.GetToday();
        if (!this.values.dateB) {
            this.values.dateB = this.GetToday();
            this.SetHourByStr(this.values.dateB, "23:59:59", 999)
        }
        let strDateStart = this.GetStrDate(this.values.dateA);
        let strDateEnd = this.GetStrDate(this.values.dateB);
        let strTimeStart = UIUtilTime._FmtToInputTime(this.values.dateA);
        let strTimeEnd = UIUtilTime._FmtToInputTime(this.values.dateB);

        const areaInput = this.options[this.values.typeDateInput]
        if (this.values.typeDateInput && areaInput) {
            areaViewInput.append(() => areaInput.node());
            areaInput.selectAll("input").classed("input_war", false)
            switch (this.values.typeDateInput) {
                case TypeDateInput.DateRange:
                    areaOptions.select(".opc-daterange").classed("opc-selected", true)
                    areaInput.select<HTMLInputElement>(".date-start").property("value", strDateStart);
                    areaInput.select<HTMLInputElement>(".date-end").property("value", strDateEnd);
                    this.SetMinDate(strDateStart);
                    this.SetMinDate(strDateEnd);
                    break;
                case TypeDateInput.OnlyADate:
                    areaOptions.select(".opc-onlyadate").classed("opc-selected", true)
                    areaInput.select<HTMLInputElement>(".date-start").property("value", strDateStart);
                    this.SetDateByStr(this.values.dateB, strDateStart);
                    break;
            }
            areaInput.select<HTMLInputElement>(".time-start").property("value", strTimeStart);
            areaInput.select<HTMLInputElement>(".time-end").property("value", strTimeEnd);
        }
    }

    private GetToday() {
        const today = new Date();
        const dt = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        console.warn("Today", dt)
        return dt;
    }
    private GetStrDate(value: Date): string {
        // if (!value) return "";
        const separator = "-"
        const yyyy = value.getFullYear().toString();
        const mm = (value.getMonth() + 1).toString().padStart(2, "0");
        const dd = value.getDate().toString().padStart(2, "0");
        return yyyy + separator + mm + separator + dd;
    }

    /** format: `yyyy-mm-dd` */
    private GetSplitDateByStr(dateStr: string) {
        let dateSplit = dateStr.split("-");
        return {
            year: parseInt(dateSplit[0] || "0", 10),
            month: parseInt(dateSplit[1] || "0", 10),
            date: parseInt(dateSplit[2] || "0", 10)
        }
    }
    /** format: `hh:mm:ss` */
    private GetSplitHourByStr(hrStr: string) {
        let hrSplit = hrStr.split(":");
        return {
            hour: parseInt(hrSplit[0] || "0", 10),
            minute: parseInt(hrSplit[1] || "0", 10),
            second: parseInt(hrSplit[2] || "0", 10)
        }
    }
    private SetDateByStr(dt: Date, strDate: string) {
        const date = this.GetSplitDateByStr(strDate);
        dt.setFullYear(date.year);
        dt.setMonth(date.month - 1);
        dt.setDate(date.date);
    }
    private SetHourByStr(dt: Date, strHour: string, ms?: number) {
        const hr = this.GetSplitHourByStr(strHour);
        dt.setHours(hr.hour);
        dt.setMinutes(hr.minute);
        dt.setSeconds(hr.second);
        if (ms != null) {
            dt.setMilliseconds(ms);
        }
    }

    private SetMinDate(strDateValue: string) {
        const dateEndInput = this.control.select(".area-input").select(".area-date-range").select<HTMLInputElement>(".date-end");
        dateEndInput.attr("min", strDateValue || this.GetDateToInput(this.config.MinDate));
    }
    private SetMaxDate(strDateValue: string) {
        const dateEndInput = this.control.select(".area-input").select(".area-date-range").select<HTMLInputElement>(".date-start");
        dateEndInput.attr("max", strDateValue || this.GetDateToInput(this.config.MaxDate));
    }

    private GetDateToInput(date: string | Date) {
        return (date ? UIUtilTime._FmtToInputDate(date) : null);
    }

    private GetValue() {
        return this.values;
    }

    private SetValue(value: IDateVal) {
        this.values = value;
        this.ShowInputArea();
    }

    private Show() {
        this.config.Parent.append(() => this.control.node())
    }

    private Remove() {
        this.control.remove()
    }

    // ****************************************************************
    // PUBLIC METHODS
    // ****************************************************************

    get _ControlContainer() {
        return this.control;
    }
    get _IncludeTime() {
        return !!this.config.IncludeTime;
    }
    public _Show(): void {
        this.Show();
    }

    public _Remove(): void {
        this.Remove();
    }

    public _GetValues(): IDateVal {
        return this.GetValue();
    }

    public _SetValues(value: IDateVal): this {
        this.SetValue(value);
        return this;
    }
}
