import * as d3 from "d3";
export namespace CheckBox {

    export interface ICheckBoxDatum {
        IsChecked: boolean;
    }

    export type ControlSelection = d3.Selection<SVGSVGElement, ICheckBoxDatum, HTMLElement | null, any>;

    /** Devuelve una selección SVG class="checkbox_table"
     * @param isChecked Valor inicial del control @default false
    */
    export function _GetCheckElement(isChecked: boolean = false): ControlSelection {
        let control = d3.create("svg")
            .attr("viewBox", "0 0 100 100")
            .classed("checkbox_table", true)
            .datum(<ICheckBoxDatum>{
                IsChecked: isChecked
            })

        control.append<SVGRectElement>("rect")
            .classed("check_border", true)
            .attr("x", 5)
            .attr("y", 5)

        control.append<SVGRectElement>("rect")
            .classed("check_action", true)
            .attr("width", 0)
            .attr("height", 0)
            .attr("x", 50)
            .attr("y", 50)

        _UpdateCheckStatus(control, isChecked);

        return control;
    }

    /** Reaplica Datum a svg */
    export function _SeletionCheckApplyDatum(selection: d3.Selection<SVGSVGElement, any, HTMLElement | null, any>) {
        const check_action = selection.select(".check_action");
        selection.datum(<ICheckBoxDatum>{
            IsChecked: check_action.attr("width") == "50"
        })
        return selection as ControlSelection;
    }

    /** Checkea/descheckea selección check
     * @param control Selección SVG class="checkbox_table"
     * @param checked Valor que tomará el control
     * @param inicializar Inicializar a valor contrario de 'checked' antes de aplizar la animación @default false
     * @param duration Duración de la animación @default 400 milisegundos
     */
    export function _UpdateCheckStatus(control: ControlSelection, checked: boolean, inicializar: boolean = false, duration: number = 300) {
        const check_action = control.select(".check_action");
        control.datum(<ICheckBoxDatum>{
            IsChecked: checked
        });

        if (checked) {
            if (inicializar) {
                check_action
                    .attr("width", 0)
                    .attr("height", 0)
                    .attr("x", 50)
                    .attr("y", 50)
            }
            check_action.transition().duration(duration)
                .attr("width", 50)
                .attr("height", 50)
                .attr("x", 25)
                .attr("y", 25)
        } else {
            if (inicializar) {
                check_action
                    .attr("width", 50)
                    .attr("height", 50)
                    .attr("x", 25)
                    .attr("y", 25)
            }
            check_action.transition().duration(duration)
                .attr("width", 0)
                .attr("height", 0)
                .attr("x", 50)
                .attr("y", 50)
        }
    }
}
