import { DataDRequest } from "../DRequest";
import { Global } from "../Global";
import { IPagoInfoFacturar } from "../entidad/Factura";
import { DataUtil } from "../util/Util";

export const _FN_MODULO_FACTURA_ACTIVO: () => boolean = () => true // (Global._DEVELOPMENT_UTILS || Global._GLOBAL_CONF.BETA_MODE || Global._GLOBAL_CONF.DEBUG_MODE)

console.warn("MODULO_FACTURA_ACTIVO", Global._GLOBAL_CONF.DEBUG_MODE)

export function _SvFacturaCFDIObtenerPagosInfoFacturar(idEscuela: number, idAlumno: number, idPagos: number[]): Promise<DataDRequest.IRequestResponseA<IPagoInfoFacturar[]>> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "factura/ObtenerPagosInfo"
    const params = {
        "IdEscuela": idEscuela,
        "IdAlumno": idAlumno,
        "IdsPagos": idPagos,
    }
    return DataDRequest._Request5<IPagoInfoFacturar[]>(url, "GET", params)
}

interface IFacturaCrearCFDIItem {
    TipoDocumento: string
    Fecha: string // "2024-04-19"
    LugarExpedicion: string // "44444"
    // Conceptos: []
    UsoCFDI: string // "S01"
    Serie: number // 57739
    FormaPago: string // "03"
    MetodoPago: string// "PUE"
    EnviarCorreo: boolean // false
}
interface IFacturaCrearCFDIItemConcepto {
    IdPago: number // 3206
    ClaveProdServSAT: string // "81112101"
    ClaveUnidadSAT: string | number // 18
    UnidadSAT: string // "Tambor de cincuenta y cinco galones (EUA)"
}

interface IFacturaCrearCFDIRespuesta {
    IdFolio: number
    /** @example "66270820bae12" */
    UID: string
    /** @example "99f228b5-aa4d-4113-9c22-eaf1fadddc05" */
    UUID: string
}
export function _FacturaCrearCFDI(idEscuela: number, idAlumno: number, UIDCliente: string, cfdiInfo: IFacturaCrearCFDIItem, conceptos: IFacturaCrearCFDIItemConcepto[]): Promise<DataDRequest.IRequestResponseA<IFacturaCrearCFDIRespuesta>> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "factura/CrearCFDI"
    const params = {
        "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "IdEscuela": idEscuela,
        "IdAlumno": idAlumno,
        "UIDReceptor": UIDCliente,
        ...cfdiInfo,
        "Conceptos": conceptos,
    }
    return DataDRequest._Request5<IFacturaCrearCFDIRespuesta>(url, "POST", params)
}

/** returns URL string
 * @example
 * ".../factura/ObtenerCfdiXml?..." || ".../factura/ObtenerCfdiPdf?..."
 */
export function _CreateURLArchivoCFDI(idEscuela: number, idFolio: number, extension: "xml" | "pdf") {
    const idUsuario = DataUtil._Usuario.IdUsuario
    const auxUrl = {
        "xml": "Xml",
        "pdf": "Pdf",
    }
    return Global._GLOBAL_CONF.URL_RSTV2 + `factura/ObtenerCfdi${auxUrl[extension]}?IdEscuela=${idEscuela}&IdFolio=${idFolio}&IdUsuario=${idUsuario}`;
}

export function _ObtenerArchivoCFDI(idEscuela: number, idFolio: number, extension: "xml" | "pdf") {
    const url = _CreateURLArchivoCFDI(idEscuela, idFolio, extension);
    return new Promise<DataDRequest.IRequestResponseA<File>>(resolve => {
        DataDRequest._RequestBlobFromUrlResource(url, (fileBlob, err, response) => {
            const filename: string = response ? response.headers.get("Filename") : null
            resolve({
                Resultado: !err ? 1 : -1,
                Datos: !err ? new File([fileBlob], filename) : null,
            })
        })
    })
}