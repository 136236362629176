import * as d3 from "d3";
import { Entidad } from "../../data/Entidad";
import { DateV2 } from "../../util/DateV2";
import { FormGenerator } from "../controlD3/Formulario";
import { ModalThings } from "../controlD3/ModalThings";
import { NotificacionV2 } from "../controlD3/NotificacionV2";
import { ScheduleBase } from "../controlD3/ScheduleBase";
import { ScheduleBaseMinutes } from "../controlD3/ScheduleBaseMinutes";
import { HTMLButton2Element } from "../controlWC/Button2Component";
import { UIUtilElementBehaviors } from "../util/ElementBehaviors";
import { UIUtilIconResources } from "../util/IconResourses";
import { UIUtilLang } from "../util/Language";
import { UIUtilTime } from "../util/Time";
import { UIUtilGeneral } from "../util/Util";

export namespace UIUtilViewAlumnoScheduleControl {

    interface IConfig {
        Parent: d3.Selection<HTMLElement, any, any, any>;
        OnCloseAItem: (idGrupo: number, idDia: Entidad.CDiaSemanal) => void
    }
    type TTypeBlock = "sticked" | "preview";
    type IOnUpdateD = ScheduleBase.IOnUpdateD;
    type TID = ScheduleBase.TID;
    type IBlockItemReadOnly<TBlockData = any> = ScheduleBase.IBlockItemReadOnly<TBlockData>;

    interface IInsideBlock {
        Type: TTypeBlock;
        ColorBase: string;
        GrupoId: number;
        HorarioId: number;
        /** Acepta html */
        GrupoNombreText: string;
    }

    interface IInsideBlockDate extends IInsideBlock {
        Entrada: Date;
        Salida: Date;
    }

    interface IInsideBlockMinutes extends IInsideBlock {
        EnableItemInside: boolean;
        Entrada: number;
        Salida: number
    }

    export interface IItemBlockAlumnoDate extends ScheduleBaseMinutes.IItemBlockMinutes<IInsideBlockDate> { }

    interface IInsideBlockDateResult extends IInsideBlock {
        HrEntrada: string;
        HrSalida: string;
    }
    export type IItemBlockResult = Pick<ScheduleBaseMinutes.IItemBlockMinutes<IInsideBlockDateResult>, "Id" | "IdDia" | "DataExtra"> & {
        /** Ej: `12:30` */
        HrInicio: string;
        /** Ej: `13:30` */
        HrFin: string;
    };

    export class ScheduleControl extends ScheduleBaseMinutes.ScheduleBMinutesBase<IInsideBlockDate, IInsideBlockMinutes> {
        private enableItemsToEdit: boolean;
        // /**
        //  * { [IDColumn: DiaSemana]: IDTimeuut }
        //  * * Auxiliar para carácteristica de, editar uno a la vez por columna.
        //  */
        // private currentColsInEdit: { [key: number]: NodeJS.Timeout }; // NOTE Implementar

        constructor(config: IConfig) {
            super();
            // this.currentColsInEdit = {};
            this.enableItemsToEdit = true;

            super.SCHEDULE_MIN_InitConfig({
                Parent: config.Parent,
                UIDATA_OnCloseAItem: (datum) => config.OnCloseAItem(datum.Data.GrupoId, datum.IdColumnPositon as Entidad.CDiaSemanal),
                UI_ItemBlock_Template_OnDraw: (container, dataItem, parentContainer) => {
                    parentContainer.select(".r_options").style("z-index", 1);
                    parentContainer.select(".r_options").append("wc-button")
                        .attr("dim", "10")
                        .property("_SrcIco", UIUtilIconResources.CGeneral.Editar)
                        .classed("editarbtn", true)
                        .classed("btn_round", true)
                        .style("width", "18px")
                        .style("height", "18px")
                    let item = container.append("div")
                        .attr("class", "horario_personal")
                    item.append(() => dataItem.UI_Item.select('.r_options').node())

                    item.append("div")
                        .attr("class", "resizer")
                        .attr("pos", "top");
                    let cont = item.append("div")
                        .attr("class", "cont")
                    UIUtilElementBehaviors._EllipsisTextTooltip(
                        cont.append("div")
                            .attr("class", "cont_groupname")
                            .append<HTMLLabelElement>("label")
                            .attr("class", "lbl_groupname")
                            .style("cursor", this.enableItemsToEdit ? "pointer" : "auto")
                            .node(),
                        "top"
                    );
                    UIUtilElementBehaviors._EllipsisTextTooltip(
                        cont.append("label")
                            .attr("class", "lbl_hour")
                            .style("cursor", this.enableItemsToEdit ? "pointer" : "auto")
                            .node()
                    )
                    item.append("div")
                        .attr("class", "resizer")
                        .attr("pos", "bottom");
                    let expired: number;
                    let expired2: number;
                    container.select<HTMLLabelElement>(".lbl_hour").node().onpointerdown = (e) => {
                        if (!expired2) {
                            expired2 = e.timeStamp + 400
                        } else if (e.timeStamp <= expired2 && this.enableItemsToEdit) {
                            e.preventDefault();
                            e.stopPropagation();
                            this.OpenEditHorario(container, dataItem.Id, parentContainer);
                            expired2 = null;
                        } else {
                            expired2 = e.timeStamp + 400
                        }
                    }
                    container.select<HTMLLabelElement>(".lbl_hour").node().onclick = (e) => {
                        e.stopPropagation();
                        if (!expired) {
                            expired = e.timeStamp + 400
                        } else if (e.timeStamp <= expired && this.enableItemsToEdit) {
                            e.preventDefault();
                            expired = null;
                        } else {
                            expired = e.timeStamp + 400
                        }
                    }
                    parentContainer.select<HTMLButton2Element>(".editarbtn").node().onclick = (e) => {
                        e.stopPropagation();
                        this.OpenEditHorario(container, dataItem.Id, parentContainer);
                    }
                },
                UI_ItemBlock_Item_OnUpdate: (container, datum, parentContainer) => {
                    parentContainer.classed("item_disable", !datum.Data.EnableItemInside);
                    const areaRealHorario = container.select<HTMLDivElement>(".horario_personal")
                        .style("background-color", datum.Data.ColorBase);

                    // container.classed("hide", (datum.Data.Salida == datum.Data.Entrada && datum.Data.Type != "toEdit"));
                    parentContainer.style("opacity", (datum.Data.Type != "sticked" ? .7 : null));

                    areaRealHorario.select("label[class='lbl_hour']").style("cursor", this.enableItemsToEdit ? "pointer" : "auto");
                    areaRealHorario.select("label[class='lbl_groupname']").style("cursor", this.enableItemsToEdit ? "pointer" : "auto");

                    const UpdatePositionsToPercentValues = () => {
                        let top = datum.Data.Entrada - datum.InitRange;
                        let range = datum.Data.Salida - datum.Data.Entrada;
                        const totalRealRangeRef = datum.EndRange - datum.InitRange;

                        let topPercent = this.SCHEDULE_GetUIPercent_ByUnitNumber(top, totalRealRangeRef);
                        let heightPercent = this.SCHEDULE_GetUIPercent_ByUnitNumber(range, totalRealRangeRef);
                        areaRealHorario
                            .style("top", topPercent + "%")
                            .style("height", heightPercent + "%");
                    }

                    const UpdateBlockText = () => {
                        let strRangeHours = this.SCHEDULE_MIN_GetHoursRangeStr(datum.Data.Entrada, datum.Data.Salida);
                        areaRealHorario.select("label[class='lbl_hour']").html(strRangeHours);
                        areaRealHorario.select("label[class='lbl_groupname']").html(!datum.Data.EnableItemInside ? (datum.Data.GrupoNombreText + "<br>") : "");
                    }

                    UpdatePositionsToPercentValues();
                    UpdateBlockText();

                    let resizerTop = areaRealHorario.select<HTMLDivElement>(':scope > .resizer[pos="top"]')
                        .classed("hide", !datum.Data.EnableItemInside)
                        .node();
                    let resizerBottom = areaRealHorario.select<HTMLDivElement>(':scope > .resizer[pos="bottom"]')
                        .classed("hide", !datum.Data.EnableItemInside)
                        .node();

                    // -> EVENTS HANDLER
                    let isClick = false;

                    parentContainer.node().onpointerdown = e => {
                        isClick = true;
                    }
                    parentContainer.node().onclick = (e) => {
                        // Pasar elemento hermano (1st node) al frente solo si no se interactua con éste elemento
                        const nodeThisElement = parentContainer.node();
                        if (nodeThisElement?.parentElement) {
                            const nodesBrothersElems = nodeThisElement.parentElement.children;

                            if (isClick && nodesBrothersElems.length > 1) {
                                if (nodesBrothersElems[nodesBrothersElems.length - 1] == nodeThisElement) {
                                    d3.select(nodesBrothersElems[0]).raise();
                                } else {
                                    parentContainer.raise();
                                }
                                isClick = false;
                                console.info(datum.Id, datum.Data.GrupoNombreText, datum.Data.GrupoId, datum.Data.HorarioId)
                            }
                        }
                    }

                    const fnResizerHandler = (resizerArea: HTMLDivElement, pos: "top" | "bottom") => {
                        if (datum.Data.EnableItemInside) {
                            areaRealHorario.node().style.cursor = "pointer";
                            // -> RESIZE INSIDE ELEMENT
                            resizerArea.onpointerdown = (eResizer) => {
                                if (eResizer.button == 0) { // NOTE solo el click del boton izq
                                    isClick = true;
                                    // NOTE Uso de getBoundingClientRect para aumentar la precisión

                                    const auxColumnsContTop = this._controlContainer.select<HTMLDivElement>(".columns_cont")
                                        .node()
                                        .getBoundingClientRect()
                                        .top;

                                    const containerPxTop = container.node().parentElement.getBoundingClientRect().top - auxColumnsContTop;
                                    parentContainer.raise();

                                    let valuesAux: IOnUpdateD = {
                                        Init: datum.Data.Entrada,
                                        End: datum.Data.Salida
                                    }

                                    let valueTop = areaRealHorario.node().getBoundingClientRect().top - (auxColumnsContTop + containerPxTop);
                                    //let valueH = areaRealHorario.node().offsetHeight;
                                    let valueH = areaRealHorario.node().getBoundingClientRect().height;
                                    areaRealHorario.raise();

                                    document.onpointermove = (evCont) => {
                                        isClick = false;
                                        let fn_Validator = this.SCHEDULE_MIN_ItemBlock_OnValide_OnUpdatingUI;
                                        let valueRange = 0;
                                        evCont.preventDefault();

                                        switch (pos) {
                                            case "top":
                                                valueTop += evCont.movementY;
                                                valueH -= evCont.movementY;

                                                valuesAux.Init = this.SCHEDULE_GetUnitValue_FromPxValue(containerPxTop + valueTop);
                                                valuesAux.End = this.SCHEDULE_GetUnitValue_FromPxValue(containerPxTop + valueTop + valueH);

                                                valueRange = valuesAux.End - valuesAux.Init;
                                                if ((valuesAux.Init < valuesAux.End) && fn_Validator(valuesAux, "resize_top", valueRange, datum.InitRange, datum.EndRange)) {
                                                    datum.Data.Entrada = valuesAux.Init;
                                                    datum.Data.Salida = valuesAux.End;

                                                    areaRealHorario.node().style.top = valueTop + "px";
                                                    areaRealHorario.node().style.height = valueH + "px";

                                                    UpdateBlockText();
                                                }
                                                break;
                                            case "bottom":
                                                valueH += evCont.movementY;
                                                valuesAux.End = this.SCHEDULE_GetUnitValue_FromPxValue(containerPxTop + valueTop + valueH);

                                                valueRange = valuesAux.End - valuesAux.Init;
                                                if (fn_Validator(valuesAux, "resize_bottom", valueRange, datum.InitRange, datum.EndRange)) {
                                                    datum.Data.Entrada = valuesAux.Init;
                                                    datum.Data.Salida = valuesAux.End;

                                                    areaRealHorario.node().style.height = valueH + "px";

                                                    UpdateBlockText();
                                                }
                                                break;
                                        }
                                    }

                                    document.onpointerup = (eCont) => {
                                        document.onpointerup = null;
                                        document.onpointermove = null;
                                        UpdatePositionsToPercentValues();
                                        this.ResalteIntersections(datum.IdColumnPositon);
                                    }
                                }
                            }
                        } else {
                            resizerArea.onpointerdown = null;
                        }
                    }

                    fnResizerHandler(resizerTop, "top");
                    fnResizerHandler(resizerBottom, "bottom");

                    if (datum.Data.EnableItemInside) {
                        // -> MOVE INSIDE ELEMENT
                        areaRealHorario.select<HTMLDivElement>(".cont").node()
                            .onpointerdown = (evInsideBlock) => {
                                if (evInsideBlock.button == 0) { // NOTE solo boton izq
                                    isClick = true;
                                    const containerPxTop = container.node().parentElement.offsetTop;
                                    parentContainer.raise();

                                    let valuesAux: IOnUpdateD = {
                                        Init: datum.Data.Entrada,
                                        End: datum.Data.Salida
                                    }

                                    let valuePxTop = areaRealHorario.node().offsetTop;

                                    document.onpointermove = (evCont) => {
                                        isClick = false;
                                        valuePxTop += evCont.movementY;

                                        valuesAux.Init = this.SCHEDULE_GetUnitValue_FromPxValue(valuePxTop + containerPxTop);

                                        const valueRange = datum.Data.Salida - datum.Data.Entrada;
                                        if (this.SCHEDULE_MIN_ItemBlock_OnValide_OnUpdatingUI(valuesAux, "move", valueRange, datum.InitRange, datum.EndRange)) {
                                            datum.Data.Entrada = valuesAux.Init;
                                            datum.Data.Salida = valuesAux.End;

                                            areaRealHorario.node().style.top = valuePxTop + "px";

                                            UpdateBlockText();
                                        }
                                    }
                                    document.onpointerup = () => {
                                        document.onpointerup = null;
                                        document.onpointermove = null;
                                        UpdatePositionsToPercentValues();
                                        this.ResalteIntersections(datum.IdColumnPositon);
                                    }
                                }
                            }
                    } else {
                        areaRealHorario.select<HTMLDivElement>(".cont").node().onpointerdown = null;
                        areaRealHorario.node().style.cursor = null;
                    }
                },
                UI_ItemBlock_ItemUI_OnUpdating: (container, dataItem, parentContainer) => {
                    container.style("background-color", dataItem.Data.ColorBase)
                },
                UI_ItemBlock_OnUpdateAllItemsEnd: () => {
                    this.config.ColumnsConfig.forEach(col => {
                        this.ResalteIntersections(col.Id);
                    })
                }
            })

            this._controlContainer.classed("schedule_container_alumno", true);
        }

        private ResalteIntersections(idColumn: TID) {
            let itemsToVerify = Array.from(this.scheduleItems.values()).filter(d => (d.IdColumnPositon == idColumn));

            itemsToVerify.forEach(d => d.UI_Item.select(".horario_personal").classed("input_err", false));

            itemsToVerify.forEach((item, i) => {
                itemsToVerify.forEach(otroItem => {
                    if (item.Data.GrupoId !== otroItem.Data.GrupoId && this.GetIsIntersecting(item, otroItem).choca) {
                        item.UI_Item.select(".horario_personal").classed("input_err", true);
                        otroItem.UI_Item.select(".horario_personal").classed("input_err", true);
                    }
                })
            })
        }

        // NOTE Implementar
        // private InEditItem(item: IBlockItemB<IInsideBlockMinutes>) {
        // }

        /** Si no se envia el parámetro, retorna todas las intersecciones que se encuentren */
        private GetIntersections(idBlock?: TID) {
            let intersections: Map<TID, IBlockItemReadOnly<IInsideBlockMinutes>> = new Map();

            if (idBlock != undefined) {
                let item = this.scheduleItems.get(idBlock);
                if (item) {
                    Array.from(this.scheduleItems.values())
                        .forEach(d => {
                            if (item.IdColumnPositon == d.IdColumnPositon) {
                                if (item.Data.GrupoId !== d.Data.GrupoId && this.GetIsIntersecting(item, d).choca) {
                                    intersections.set(d.Id, d);
                                    if (!intersections.has(item.Id)) {
                                        intersections.set(item.Id, d); // Se va a actualizar
                                    }
                                }
                            }
                        })
                }
            } else {
                let allItems = Array.from(this.scheduleItems.values())
                allItems.forEach((item, i) => {
                    allItems.forEach(otroItem => {
                        if (item.IdColumnPositon == otroItem.IdColumnPositon) {
                            if (item.Data.GrupoId !== otroItem.Data.GrupoId && this.GetIsIntersecting(item, otroItem).choca) {
                                intersections.set(otroItem.Id, otroItem);
                                if (!intersections.has(item.Id)) {
                                    intersections.set(item.Id, otroItem); // Se va a actualizar
                                }
                            }
                        }
                    })
                })
            }

            return intersections;
        }

        /** 9 casos de intersección */
        private GetIsIntersecting(unItem: IBlockItemReadOnly<IInsideBlockMinutes>, otroItem: IBlockItemReadOnly<IInsideBlockMinutes>) {
            if (unItem.IdColumnPositon == otroItem.IdColumnPositon) {

                let dataitem = unItem.Data;
                if ((dataitem.Entrada > otroItem.Data.Entrada) && (dataitem.Entrada < otroItem.Data.Salida) && (dataitem.Salida > otroItem.Data.Salida)) {
                    // IF limite top de item intersecta con otro elemento (1 caso)
                    return {
                        choca: true,
                        case: "TOP",
                        desc: UIUtilLang._GetUIString("panelniniohorario", "notif_intersect_top").replace("_GRUPO", otroItem.Data.GrupoNombreText)
                    };
                } else if ((dataitem.Salida < otroItem.Data.Salida) && (dataitem.Salida > otroItem.Data.Entrada) && (dataitem.Entrada < otroItem.Data.Entrada)) {
                    // IF limite bottom de item intersecta con otro elemento (1 caso)
                    return {
                        choca: true,
                        case: "BOTTOM",
                        desc: UIUtilLang._GetUIString("panelniniohorario", "notif_intersect_bottom").replace("_GRUPO", otroItem.Data.GrupoNombreText)
                    };
                } else if ((dataitem.Entrada <= otroItem.Data.Entrada) && (dataitem.Salida >= otroItem.Data.Salida)) {
                    // IF otro elemento está entre el item o si son del mismo rango (4 casos)
                    return {
                        choca: true,
                        case: "OVER",
                        desc: UIUtilLang._GetUIString("panelniniohorario", "notif_intersect_over").replace("_GRUPO", otroItem.Data.GrupoNombreText)
                    };
                } else if ((dataitem.Entrada >= otroItem.Data.Entrada) && (dataitem.Salida < otroItem.Data.Salida)) {
                    // IF item está entre otro elemento o si son del mismo rango (4 casos)
                    return {
                        choca: true,
                        case: "INTO",
                        desc: UIUtilLang._GetUIString("panelniniohorario", "notif_intersect_into").replace("_GRUPO", otroItem.Data.GrupoNombreText)
                    };
                }
            }
            return {
                choca: false,
                case: "",
                desc: ""
            };
        }

        private OpenEditHorario(container: TSelectionHTML<"div", any, any>, dataItemId: TID, parentContainer: TSelectionHTML<"div", any, any>) {
            let actualDatumBlock = this.scheduleItems.get(dataItemId);
            let entrada = new DateV2()
                ._SetLocalStrHour("00:00:00.000")
                ._AddMinutes(actualDatumBlock.Data.Entrada)
            let timeEntrada = UIUtilTime._FmtToInputTime(entrada)
            let salida = new DateV2()
                ._SetLocalStrHour("00:00:00:000")
                ._AddMinutes(actualDatumBlock.Data.Salida)
            let timeSalida = UIUtilTime._FmtToInputTime(salida);

            interface horarios {
                horaEntrada: string,
                horaSalida: string
            }
            ModalThings._GetModalToForm({
                Title: `${UIUtilTime._GetDayName(actualDatumBlock.IdColumnPositon as number)} - ${actualDatumBlock.Data.GrupoNombreText}`,
                Width: 220,
                GetForm: () => {
                    return new FormGenerator<horarios>()._Crear({
                        LabelMaxWidth: 100,
                        schema: [
                            { model: "horaEntrada", type: "input", labelText: UIUtilLang._GetUIString("panelniniohorario", "lbl_entrada"), inputAttr: { type: "time", required: true, inputProperties: { "value": timeEntrada } } },
                            { model: "horaSalida", type: "input", labelText: UIUtilLang._GetUIString("panelniniohorario", "lbl_salida"), inputAttr: { type: "time", required: true, inputProperties: { "value": timeSalida } } },
                        ],
                        Validation: (value, model, dataForm, controlsForm) => {
                            let valueEntrada = Number(dataForm.horaEntrada.split(':')[0]) * 60 + Number(dataForm.horaEntrada.split(':')[1]);
                            let valueSalida = Number(dataForm.horaSalida.split(':')[0]) * 60 + Number(dataForm.horaSalida.split(':')[1]);
                            let caso: string = "";
                            let mensaje: string = "";
                            Array.from(this.scheduleItems.values())
                                .forEach(d => {
                                    if (actualDatumBlock.IdColumnPositon == d.IdColumnPositon) {
                                        let validateIntersection = this.GetIsIntersecting({ ...actualDatumBlock, Data: { ...actualDatumBlock.Data, Entrada: valueEntrada, Salida: valueSalida } }, d);
                                        if (actualDatumBlock.Data.GrupoId !== d.Data.GrupoId && validateIntersection.choca) {
                                            caso = validateIntersection.case;
                                            mensaje = validateIntersection.desc;
                                        }
                                    }
                                })
                            if (model === "horaEntrada") {
                                if (valueEntrada > (valueSalida - ScheduleBaseMinutes._MIN_BLOCK_MINUTES)) {
                                    return false;
                                }
                                if (actualDatumBlock.InitRange > valueEntrada) {
                                    NotificacionV2._Mostrar(UIUtilLang._GetUIString("panelniniohorario", "notif_initoutrange"), "ADVERTENCIA");
                                    return false;
                                }
                                if (caso == "TOP") {
                                    NotificacionV2._Mostrar(mensaje, "ADVERTENCIA");
                                    return false;
                                }
                                if (caso == "OVER" || caso == "INTO") {
                                    return false;
                                }
                                //return actualDatumBlock.InitRange <= valueEntrada;
                            }
                            if (model === "horaSalida") {
                                if (valueEntrada > (valueSalida - ScheduleBaseMinutes._MIN_BLOCK_MINUTES)) {
                                    NotificacionV2._Mostrar(UIUtilLang._GetUIString("panelniniohorario", "notif_initexcedend").replace("_MIN", ScheduleBaseMinutes._MIN_BLOCK_MINUTES.toString()), "ADVERTENCIA");
                                    return false;
                                }
                                if (valueSalida > actualDatumBlock.EndRange) {
                                    NotificacionV2._Mostrar(UIUtilLang._GetUIString("panelniniohorario", "notif_endoutrange"), "ADVERTENCIA");
                                    return false;
                                }
                                if (caso == "BOTTOM") {
                                    NotificacionV2._Mostrar(mensaje, "ADVERTENCIA");
                                    return false;
                                }
                                if (caso == "OVER" || caso == "INTO") {
                                    NotificacionV2._Mostrar(mensaje, "ADVERTENCIA");
                                    return false;
                                }
                            }
                            return true;
                        }
                    }, { horaEntrada: timeEntrada, horaSalida: timeSalida })
                },
                DrawContent: (content, form, mt) => {
                    mt.BtnLeft.remove();
                    content.classed("filteredit_container", true);

                    const modal = mt.Modal._ModalSelection
                        .style("position", "absolute")
                        .node();
                    const modalParent = d3.select(modal.parentElement)
                        .style("position", "absolute")
                        .style("background-color", "transparent")
                        .node();
                    let colocaModal = () => {
                        let limits = container.select<HTMLDivElement>(".horario_personal").node().getBoundingClientRect();
                        let containerBox = modalParent.getBoundingClientRect();
                        let box = modal.getBoundingClientRect();
                        let left = ((containerBox.width - (limits.right + box.width + 20)) <= 0) ? limits.left - box.width : limits.right;
                        let dif = containerBox.height - (limits.y + box.height + 20);
                        let top = limits.y + (dif < 0 ? dif : 0);
                        modal.style.left = left + "px";
                        modal.style.top = top + "px";
                    }
                    colocaModal();
                    let observeResize = UIUtilGeneral._GetResizeObserver(entries => {
                        setTimeout(colocaModal, 200)
                    });
                    observeResize.observe(modalParent);
                },
                OnAccept: (form, mt) => {
                    actualDatumBlock.Data.Entrada = Number(form._Data.horaEntrada.split(':')[0]) * 60 + Number(form._Data.horaEntrada.split(':')[1]);
                    actualDatumBlock.Data.Salida = Number(form._Data.horaSalida.split(':')[0]) * 60 + Number(form._Data.horaSalida.split(':')[1]);
                    this.UI_UpdateABlockItem(parentContainer, actualDatumBlock);
                    this.ResalteIntersections(actualDatumBlock.IdColumnPositon);
                    mt.Modal._Ocultar();
                    return null;
                }
            })
        }

        // NOTE Integrar
        // private GetItemEsDesfazado(item: IBlockItemB<IInsideBlockMinutes>) {
        //     return (item.Data.Entrada < item.InitRange || item.Data.Salida > item.EndRange);
        // }

        // ***************************************************************************
        // PUBLIC METHODS
        // ***************************************************************************

        public _SetScheduleItem(...items: IItemBlockAlumnoDate[]) {
            super.SCHEDULE_SetScheduleItems(...items
                .map(item => {
                    let initRange = this.SCHEDULE_MIN_GetDayMinutes_FromDate(item.Inicio);
                    let endRange = this.SCHEDULE_MIN_GetDayMinutes_FromDate(item.Fin);
                    return this.SCHEDULE_MIN_GetScheduleItemValid(item.Id, initRange, endRange, item.IdDia, {
                        EnableBtns: this.enableItemsToEdit,
                        EnableItemInside: this.enableItemsToEdit,
                        ColorBase: item.DataExtra.ColorBase,
                        Type: item.DataExtra.Type,
                        Entrada: this.SCHEDULE_MIN_GetDayMinutes_FromDate(item.DataExtra.Entrada),
                        Salida: this.SCHEDULE_MIN_GetDayMinutes_FromDate(item.DataExtra.Salida),
                        GrupoId: item.DataExtra.GrupoId,
                        GrupoNombreText: item.DataExtra.GrupoNombreText,
                        HorarioId: item.DataExtra.HorarioId
                    }, false);
                })
            );
        }

        public _GetCurrentItems(): IItemBlockResult[] {
            return Array.from(this.scheduleItems.values())
                // .filter(d => (d.Data.Salida > d.Data.Entrada))
                .map((d) => ({
                    Id: d.Id,
                    HrInicio: this.SCHEDULE_MIN_GetHoursStr_FromDayMinutes(d.InitRange),
                    HrFin: this.SCHEDULE_MIN_GetHoursStr_FromDayMinutes(d.EndRange),
                    IdDia: d.IdColumnPositon as Entidad.CDiaSemanal,
                    DataExtra: {
                        ColorBase: d.Data.ColorBase,
                        HrEntrada: this.SCHEDULE_MIN_GetHoursStr_FromDayMinutes(d.Data.Entrada),
                        HrSalida: this.SCHEDULE_MIN_GetHoursStr_FromDayMinutes(d.Data.Salida),
                        Type: d.Data.Type,
                        GrupoId: d.Data.GrupoId,
                        GrupoNombreText: d.Data.GrupoNombreText,
                        HorarioId: d.Data.HorarioId,
                    }
                }));
        }

        public _SetEnableItemsToEdit(value: boolean) {
            this.enableItemsToEdit = value;

            super.SCHEDULE_SetScheduleItems(...Array.from(this.scheduleItems.values())
                .map(d => {
                    d.Data.EnableBtns = value;
                    d.Data.EnableItemInside = value;
                    d.EnableMoveLeftRight = false;
                    d.EnableMoveTopDown = false;
                    d.EnableResizing = false;
                    return d
                })
            );
        }

        public _DeleteItemsByGroup(idGrupo: number, onlyThisItemsType?: TTypeBlock) {
            let itemsToDelete = Array.from(this.scheduleItems.values())
                .filter(d => (d.Data.GrupoId == idGrupo) && (onlyThisItemsType ? d.Data.Type == onlyThisItemsType : true));

            this.SCHEDULE_DeleteSheduleItem(...itemsToDelete.map(d => d.Id));
        }

        public get _HasIntersections() {
            return (this.GetIntersections().size > 0)
        }

        // /** NOTA: Toma en cuenta items que no son válidos */
        public _HasItemsFromIdGroup(idGrupo: number, onlyThisItemsType?: TTypeBlock) {
            let dataFromGrupos = Array.from(this.scheduleItems.values())
                .filter(d => {
                    // (d.Data.Salida > d.Data.Entrada) // NOTE válida items válidos // comentario no válido
                    return (d.Data.GrupoId == idGrupo) && (onlyThisItemsType ? d.Data.Type == onlyThisItemsType : true);
                });

            return (dataFromGrupos.length > 0);
        }

        public _HasItemByGrupoAndDay(idGrupo: number, idDia: Entidad.CDiaSemanal) {
            for (let item of this.scheduleItems.values()) {
                if (item.Data.GrupoId == idGrupo && item.IdColumnPositon == idDia) {
                    return true;
                }
            }
            return false;
        }

        /** Numero de items válidos
         * NOte: un item válido se válida de acuerdo al rango, si (salida > entrada)
         */
        public get _LengthItems() {
            return Array.from(this.scheduleItems.values())
                // .filter(d => (d.Data.Salida > d.Data.Entrada))
                .length;
        }
    }
}
