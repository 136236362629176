import { json } from "d3";
import { DataUtil } from "../../data/util/Util";
import { Global } from "../../data/Global";
export namespace UIUtilDialPhoneCodes {
    interface IDialPhoneCode {
        "Id": string;
        "Flag": string;
        "Code": string;
        "Pais": string;
        "NativeName"?: string;
        "NDigitos"?: number[];
    }

    var DialCodeArr: IDialPhoneCode[] = [];

    export async function _Init() {
        let url = "cfg/dial-codes.json?v" + Global._APP_VERSION_NAME;
        DialCodeArr = await json(url) as IDialPhoneCode[];
    };

    export function _GetDialCodeArr() {
        return DialCodeArr;
    }

    export function _GetDialCodeItem(phoneCode: string) {
        return DialCodeArr.find(d => d.Code == phoneCode);
    }
}
