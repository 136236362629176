import * as jspdf from "jspdf";
import { DataDRequest } from "../../data/DRequest";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import _L from "../../util/Labels";
import { PDFThings } from "../controlD3/PDFBuilder";
import { UIUtilTime } from "../util/Time";

import CTipoEvaluacion = Entidad.CTipoEvaluacion;

const CRITERIO_TAB = "25px";
const COMENTARIOS_WIDTH = "200px";
const fn_DateFmt = UIUtilTime._DateFormatStandar;
const _METADATA = PDFThings._METADATA;

export namespace UIUtilViewCalificacionBoletaV2 {

    export async function _BoletaDiaPDFV2(info: IBoletaConfig): Promise<File> {
        console.warn(info)
        const boletaElement = await _CreateBoletaElement(info);
        return CreatePDF_A4(boletaElement, "boleta")
            .then(file => {
                boletaElement.remove();
                return file
            })
    }

    async function CreatePDF_A4(element: HTMLElement, title: string): Promise<File> {
        const pdf = new jspdf.jsPDF({
            orientation: "portrait",
            unit: "cm",
            format: [21.59, 27.94], // A4
        });

        if (!title.toLowerCase().endsWith(".pdf")) {
            title = title + ".pdf";
        }

        // >> METADATA
        pdf.setProperties({
            title: title,
            ..._METADATA
        });
        // >> CONTENT
        const w = element.offsetWidth;
        const fixer = 37.8;
        // const logoKidi = element.querySelector<HTMLImageElement>(".logo_kidi");
        const logoEscuela = element.querySelector<HTMLImageElement>(".logo_escuela");
        // const { x: logoK_x, y: logoK_y, width: logoK_w, height: logoK_h } = logoKidi;
        const { x: logoE_x, y: logoE_y, width: logoE_w, height: logoE_h } = logoEscuela;
        // logoKidi.remove();
        logoEscuela.remove();

        await pdf.html(element, {
            filename: title,
            width: w,
            windowWidth: w * fixer,
            autoPaging: 'text',
        })
        pdf.setPage(1);
        // pdf.addImage(logoKidi, "png", (logoK_x / fixer), (logoK_y / fixer), (logoK_w / fixer), (logoK_h / fixer));
        pdf.addImage(logoEscuela, "png", (logoE_x / fixer), (logoE_y / fixer), (logoE_w / fixer), (logoE_h / fixer));

        return new File([pdf.output("blob")], title);
    }

    export async function _CreateBoletaElement(info: IBoletaConfig): Promise<HTMLDivElement> {
        info = {
            Fecha: fn_DateFmt(new Date(), "d MMM yyyy"),
            Comentario: "",
            ...info,
        }
        if (!info.Comentario) info.Comentario = "";
        const { IdEscuela, Evaluaciones: detalle } = info;
        const escuela = DataModuloMain._GetItemDataByName("Escuela", IdEscuela);
        const contDiv = document.createElement("div");
        contDiv.className = "boleta_v2";
        document.body.appendChild(contDiv);

        // HEADER

        const logoBase64 = await new Promise<string>((resolve) => DataDRequest._RequestBlobFromUrlResourceV2(escuela.Logo, (blob) => {
            let reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => resolve(reader.result as string);
            reader.onerror = () => resolve("");
        }))
        contDiv.innerHTML = `
        <div class="header">
            <table>
                <td>
                    <img class="img logo_escuela" src="${logoBase64}">
                </td>
                <td class="font_bold">
                    ${escuela.Nombre}
                </td>
                <td>
                </td>
            </table>
            <hr>
        </div>

        <section class="alumno_info">
            <table>
                <tr>
                    <td>${_L("boleta_v2.nombre")}</td>
                    <td colspan="3">
                        <div class="box nombre_alumno">${info.NombreAlumno}</div>
                    </td>
                </tr>
                <tr>
                    <td>${_L("boleta_v2.nivel")}</td>
                    <td>
                        <div class="box nivel">${info.Nivel}</div>
                    </td>
                    <td>${_L("boleta_v2.grado")}</td>
                    <td>
                        <div class="box grado">${info.Grado}</div>
                    </td>
                </tr>
                <tr>
                    <td>${_L("general.fecha")}</td>
                    <td>
                        <div class="box fecha">${info.Fecha}</div>
                    </td>
                </tr>
            </table>
        </section>`

        const buildTitleEvalRow = (nombre: string) => {
            return `<tr> <td class="font_bold">${nombre}</td> </tr>`
        }
        const buildEvalRow = (id: string, esCriterio: boolean, tipoEval: CTipoEvaluacion, nombre: string, evals: string[], comentarios: string) => {
            const tipoColor = tipoEval == CTipoEvaluacion.Colores;
            let res = `<tr> <td>${esCriterio ? `<div style="padding-left:${CRITERIO_TAB}">${nombre}</div>` : `<div class="font_bold">${nombre}</div>`}</td>`
            res += (tipoColor ? "<td>" : `<td class="box">`);
            if (tipoColor) {
                res += `${evals.map(ev => `<div style="background-color: ${ev}" class="box color"></div>`)}`
            } else {
                res += `<div>${evals.map((ev) => (evals.length > 1 ? "• " : "") + ev).join("\n")}</div>`
            }
            res += `</td>`;
            res += `<td class="box comentario" contenteditable="true" id=${id}>${comentarios} </td> </tr>`;
            return res;
        }

        detalle.forEach(d => {
            const evalCont = document.createElement("section");
            contDiv.append(evalCont);
            evalCont.className = "content";
            evalCont.innerHTML = `
            <div class="section_title">
                <label class="font_bold">${_L("boleta_v2.grupo")}: ${d.GrupoNombre} </label>
            </div>
            <table>
                <td class="evaluaciones">
                    <table class="evaluaciones">
                        <tr>
                            <td>${_L("boleta_v2.materias")}</td>
                            <td>${_L("boleta_v2.evaluacion")}</td>
                            <td style="width:${COMENTARIOS_WIDTH}">${_L("boleta_v2.observaciones")}</td>
                        </tr>
                        ${(() => {
                    return d.Materias.reduce((res, materia) => {
                        if (materia.TieneCriterios) {
                            res += buildTitleEvalRow(materia.MateriaNombre);
                        }
                        materia.Evaluaciones.forEach(eva => {
                            res += buildEvalRow(eva.Id, materia.TieneCriterios, materia.TipoEval, eva.Nombre, eva.Evaluacion, eva.Comentarios)
                        })
                        return res
                    }, "")
                })()}
                    </table>
                </td>
            </table>`
        })

        const comment = document.createElement("section");
        contDiv.append(comment);
        comment.className = "comments";
        comment.innerHTML = `
        <div class="section_title">
            <div class="font_bold comentarios">${_L("boleta_v2.comentarios")}</div>
        </div>
        <div class="content" contenteditable="true" id="comentario_general">
            ${info.Comentario}
        </div>`

        const leyends = document.createElement("div");
        leyends.className = "leyends";
        leyends.textContent = info.Leyendas;
        contDiv.append(leyends);

        return contDiv;
    }

    export interface IBoletaConfig {
        IdEscuela: number;
        NombreAlumno: string;
        // Tutores: string[];
        Nivel: string;
        Grado: string;
        /** @default Fecha_Actual fmt_es `1 de Agosto de 2023` */
        Fecha?: string;
        Evaluaciones: IEvaluacionGrupo[];
        Comentario?: string;
        /** HTML text */
        Leyendas?: string;
    }
    interface IEvaluacionGrupo {
        GrupoNombre: string;
        Materias: ICalificacionInfo[];
    }
    export interface ICalificacionInfo {
        // Grupo: string;
        MateriaNombre: string;
        TieneCriterios: boolean;
        Evaluaciones: IEvaluaciones[];
        TipoEval: CTipoEvaluacion;
        // MinimoAprobatorio: string;
        // EvalValor: string[];
        // EvalDescripcion: string[];
    }
    export interface IEvaluaciones {
        Id: string;
        Nombre: string;
        Evaluacion: string[];
        Comentarios: string;
    }
}
