import { ArrayV2 } from "../../util/ArrayV2";
import { DataDRequest } from "../DRequest";
import { Entidad } from "../Entidad";
import { Global } from "../Global";
import { DataUtil } from "../util/Util";

import IEscuela = Entidad.IEscuela;
import IFinanzaItemHoraExtra = Entidad.IFinanzaItemHoraExtra;

export const _DiccHorasExtras: Map<number, IFinanzaItemHoraExtra> = new Map();

/** Diccionario extra (IMPORTANTE: USO SOLO DE MAINPAGE):
 * * Map<idAlumno, asignaciones<Map<idAsignacion, dataIFinanzaItemHoraExtra>>> */
export var _DiccHorasExtrasByAlumno: Map<number, Map<number, IFinanzaItemHoraExtra>> = new Map();

const IDREQUEST = Entidad.CTipoRequest.FinanzaHoraExtra;

export function _GetList(escuelas: IEscuela[]): Promise<DataDRequest.IRequestResponseListA<IFinanzaItemHoraExtra>[]> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "finanzas/ObtenerListaHorasExtra";
    return new ArrayV2<IEscuela>()
        ._Push(...escuelas)
        ._MapAwait(async ({ IdKinder }) => {
            const currentDateStr = await DataUtil._GetMaxDate(IDREQUEST, IdKinder);
            const params = {
                "IdEscuela": IdKinder,
                "Modificacion": currentDateStr,
            }
            return DataDRequest._Request4List(url, "GET", params, IDREQUEST, IdKinder);
        });
}

interface IItemHoraPago {
    IdCargo: number;
    Ids: Array<number>;
    Valor: number;
    NombreCargo: string;
}
export async function _FinanzaHoraExtraGenerarCargo(idAlumno: number, idCuenta: number, idCicloEscolar: number, fAplicacion: Date, entradas: IItemHoraPago, salidas: IItemHoraPago): Promise<DataDRequest.IRequestResponseA<number[]>> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "finanzas/HorasExtraGenerarCargo";
    const params = {
        IdAlumno: idAlumno,
        IdCuenta: idCuenta,
        IdCicloEscolar: idCicloEscolar,
        IdUsuario: DataUtil._Usuario.IdUsuario,
        FechaInicio: fAplicacion.toISOString(), // "2021-06-05T01:00:00Z",
        Entradas: entradas || null,
        Salidas: salidas || null,
        // {
        //     IdCargo: 77,
        //     Ids: [7],
        //     Valor: 150
        // }
    }
    return DataDRequest._Request5<number[]>(url, "POST", params)
}

export async function _FinanzaHoraExtraIgnorarRegistro(idsTiempoExtra: Array<number>): Promise<DataDRequest.IRequestResponseA<number[]>> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "finanzas/IgnorarRegistro";
    const params = {
        "IdTiempoExtra": idsTiempoExtra,
        "IdUsuario": DataUtil._Usuario.IdUsuario
    }
    return DataDRequest._Request5<number[]>(url, "POST", params)
}

export default {
    _DiccHorasExtras,
    _DiccHorasExtrasByAlumno,
}
