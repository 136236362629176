import { group as d3Group } from "d3-array";
import { MainPage } from "../../MainPage";
import { DataDRequest } from "../../data/DRequest";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import { _LOCALDATA_GetLogroAsignacionesDeAlumno } from "../../data/modulo/Alumno";
import DataModuloEscuela from "../../data/modulo/Escuela";
import DataModuloLogroAsignacion from "../../data/modulo/LogroAsignacion";
import { DataUtilAlertBot } from "../../data/util/AlertBot";
import { ArrayV2 } from "../../util/ArrayV2";
import { TCARDV2COLL_OnEditOriginEvent } from "../controlD3/CardV2Collapse";
import { CardV2CollapseAdvancedTable, IConfigCardV2CollapseExcelExport } from "../controlD3/CardV2CollapseAdvancedTable";
import { List } from "../controlD3/List";
import { ModalThings } from "../controlD3/ModalThings";
import { NotificacionV2 } from "../controlD3/NotificacionV2";
import { Table } from "../controlD3/Tabla";
import { UIUtilLang } from "../util/Language";
import { UIUtilTime } from "../util/Time";
import { UIUtilGeneral } from "../util/Util";
import { UIUtilViewData } from "../util/ViewData";

import ILogro = Entidad.ILogro;
import CEstado = Entidad.CLogroAsignacionEstado;
import IAlumno = Entidad.IAlumno;
import IAsignacionBase = Entidad.ILogroAsignacion;

interface IAsignacion extends IAsignacionBase {
    LogroNombre?: string;
    DtIniciadoFmt?: string;
    DtAvanzadoFmt?: string;
    DtTerminadoFmt?: string;
    IdsAsignacionReal?: number[];
}

interface ICategoriaLogro {
    IdCategoria: number;
    StrCategoria: string;
    Logros: ILogro[];
}

export class UIPanelCardAlumnosLogros extends CardV2CollapseAdvancedTable<IAsignacion, [IAlumno[]]> {

    private currentEscuela: Entidad.IEscuela;
    private currentAlumnos: IAlumno[];
    private logrosCompartidos: Map<string, IAsignacion[]>;

    constructor(modulo: Entidad.CModulo.PanelNinioLogros) {
        super("", modulo);
        this.logrosCompartidos = new Map();
    }

    protected CARDCOLLADTAB_Table_GetMenuTop(): Table.ITableMenuTopDefaultOptionConfig[] {
        let options: Table.ITableMenuTopDefaultOptionConfig[] = [];

        if (this.HasActionPermission(Entidad.CAccionPermiso.Agregar)) {
            options.push({
                Label: "action_agregar",
                Callback: () => {
                    if (this.currentEscuela) {
                        let categoriasLogros = this.GetLogrosDisponibles();
                        console.log(categoriasLogros);
                        if (categoriasLogros.length > 0) {
                            this.OpenModal_LogrosDisponibles(categoriasLogros);
                        } else {
                            this.ctrlNotification._Mostrar(
                                this.CARDCOLL_GetUIStringModule("notif_sinlogrosescuela").replace("_ESCUELA", this.currentEscuela.Nombre),
                                "ADVERTENCIA"
                            );
                        }
                    } else {
                        this.ctrlNotification._Mostrar(this.CARDCOLL_GetUIStringModule("notif_escuelanodisponible"), "ADVERTENCIA");
                    }
                }
            })
        }
        return options;
    }
    protected CARDCOLLADTAB_GetExportarConfig(dataTable: IAsignacion[]): IConfigCardV2CollapseExcelExport<IAsignacion> {
        if (this.currentAlumnos.length !== 1) {
            this.ctrlNotification._Mostrar(this.CARDCOLL_GetUIStringModule("export_fail_multialumnos"), "ADVERTENCIA");
            return null;
        }
        const alumno = this.currentAlumnos[0];
        return {
            FileName: alumno.NombreCompleto + " - " + UIUtilViewData._GetStr_Modulo("logros"),
            IdsEscuelas: [alumno.IdKinder],
            TypeRequest: Entidad.CTipoRequest.Alumno,
            ColumnsConfig: this.ctrlTabla._InfoColumns
                .map(d => ({
                    Field: d.Field as keyof IAsignacion,
                    HeaderTag: d.Label,
                    WidthCell: 25
                })),
            OnGetDataBySheets: async () => {
                return [{
                    IdSheet: alumno.IdKinder, // IdEscuela
                    SheetName: alumno.NombreCompleto,
                    Data: dataTable,
                }]
            },
            OnGetEscuelasTagInSheet: (datos) => DataModuloEscuela._DiccEscuela.get(alumno.IdKinder).Nombre,
        }
    }
    protected CARDCOLLADTAB_Table_GetConfig(): Omit<Table.IConfig<IAsignacion>, keyof Pick<Table.IConfig<IAsignacion>, "Parent">> {
        return {
            IdTabla: "AlumnosPanelLogros",
            IdData: "IdAsignacion",
            MinWidth: 800,
            OrderDefault: {
                Field: "DtIniciadoFmt",
                Type: Table.CStatusOrder.Desc
            },
            StickyCheckInRow: true,
            RenderColumnHeadings: [
                { Field: "LogroNombre", Label: "Logro", Width: "25%", MinWidth: "30px" },
                { Field: "StrEstado", Label: "Estado", Width: "15%", MinWidth: "30px" },
                { Field: "DtIniciadoFmt", LabelLangKey: "d_field_iniciado", Label: "Iniciado", Width: "20%", MinWidth: "30px", OrderField: "Iniciado", OrderTypeParse: Date },
                { Field: "DtAvanzadoFmt", LabelLangKey: "d_field_avanzado", Label: "Avanzado", Width: "20%", MinWidth: "30px", OrderField: "Avanzado", OrderTypeParse: Date },
                { Field: "DtTerminadoFmt", LabelLangKey: "d_field_terminado", Label: "Terminado", Width: "20%", MinWidth: "30px", OrderField: "Terminado", OrderTypeParse: Date },
            ],
            OptionsOfDataCheckV3: () => this.CARDCOLLADTAB_GetTableOptionsToSelectedData(this.currentAlumnos[0].IdKinder, "top-selected", this.TableGetDataSelectedMenu()),
            EvaluatorAndSubLevelsBuild: {
                GetOptionsInRowV2: () => {
                    return this.CARDCOLLADTAB_GetTableOptionsToSelectedData(this.currentAlumnos[0].IdKinder, "row", this.TableGetDataSelectedMenu());
                },
            },
            OptionsTopDefaultV2: {
                MaxOptionsInRow: 4,
                Options: []
            }
        }
    }
    protected CARDCOLL_OnInitBuild(contentContainer: TSelectionHTML<"div", any, any>): void {
        this.btnEditarCard._d3Selection.remove();
        this.cardFooterSelection.remove();
    }
    protected CARDCOLL_GetVariantToValidateUpdate(alumnosData: IAlumno[]): string {
        return alumnosData.reduce((variant, alumno) => (
            variant += alumno.IdChild + alumno.Modificacion
            + "-"
            // + this.logrosCompartidos.get(this.GetCurrentLogrosKey(alumnosData)).map(asig => asig.IdAsignacion)
            + Array.from(_LOCALDATA_GetLogroAsignacionesDeAlumno(alumno.IdChild).values())
                .map(d => d.IdAsignacion)
            + "."
        ), "");
    }
    protected CARDCOLL_OnUpdateData(alumnosData: IAlumno[]): void {
        this.currentEscuela = DataModuloEscuela._DiccEscuela.get(alumnosData[0].IdKinder);
        this.currentAlumnos = alumnosData.sort((a, b) => (a.IdChild - b.IdChild));

        if (this.currentEscuela) {
            this.ctrlTabla._UpdateData(this.GetDataAsignaciones());

        } else {
            this.ctrlNotification._Mostrar(UIUtilLang._GetUIString("general", "notif_fail_infoupdate"), "ADVERTENCIA");
        }
    }
    protected CARDCOLL_MostrarBody(): void {
        this.cardContentContainerSelection.style("height", "100%");
        if (!this["__workerListenerReq"]) {
            this["__workerListenerReq"] = MainPage._AddEventListenerWorkerRequest(Entidad.CTipoRequest.LogroAsignacion, (e) => {
                if (!this.cardData) return;
                this.CARDCOLL_UpdateCardData(false, ...this.cardData);
                //this.met_UpdateCardData(false, ...this.cardData);
            })
        }
    }
    protected CARDCOLL_OcultarBody(): void {
        this.cardContentContainerSelection.style("height", null)
        MainPage._RemoveEventListenerWorkerRequest(Entidad.CTipoRequest.LogroAsignacion, this["__workerListenerReq"]);
        this["__workerListenerReq"] = null;
    }
    protected CARDCOLL_OnEditarCard(originEvent: TCARDV2COLL_OnEditOriginEvent): void {
        //throw new Error("Method not implemented.");
    }
    protected CARDCOLL_OnCancelaEditarCard(originEvent: TCARDV2COLL_OnEditOriginEvent): void {
        //throw new Error("Method not implemented.");
    }
    protected CARDCOLL_GuardarCardV2(): Promise<DataDRequest.IRequestResponseA<any>> {
        throw new Error("Method not implemented.");
    }
    protected CARDCOLL_SyncOrGetIdToDownloadData(): DataModuloMain.TipoRequestMonitorId | DataModuloMain.TipoRequestMonitorId[] | (() => Promise<any>) {
        return Entidad.CTipoRequest.LogroAsignacion;
    }
    protected CARDCOLL_GetIdSchool(cardData_0: IAlumno[]): number {
        return cardData_0[0].IdKinder;
    }

    public _Destroy() {
        this.logrosCompartidos.clear();
        super._Destroy();
        return this;
    }

    //Methods

    private GetCurrentLogrosKey(currentAlumnos = this.currentAlumnos) {
        return currentAlumnos
            .map(d => (d.IdChild))
            .join();
    }

    private GetCategoriasByCurrentSchool() {
        console.log(DataModuloMain._GetReqDataArrayByName("LogroCategoria"))
        return DataModuloMain._GetReqDataArrayByName("LogroCategoria", true)
            .filter(d => (this.currentEscuela?.IdKinder == d.IdKinder));
    }

    private GetLogrosByCategoria(idCategoria: number) {
        return DataModuloMain._GetReqDataArrayByName("Logro", true)
            .filter(d => (this.currentEscuela?.IdKinder == d.IdKinder && d.IdCategoria == idCategoria));
    }

    /** Retorna una estructura de asignaciones tomando en cuenta las asignaciones de uno de los alumnos actuales */
    private GetLogrosDisponibles(): ICategoriaLogro[] {
        let categorias = this.GetCategoriasByCurrentSchool();
        let logrosAsignadosArray = this.logrosCompartidos.get(this.GetCurrentLogrosKey()) || [];
        let logrosAsignados = new Map(logrosAsignadosArray.map(d => [d.IdLogro, d]));
        let catLogos: ICategoriaLogro[] = [];
        categorias.forEach(cat => {
            let itemCatLogros: ICategoriaLogro = {
                IdCategoria: cat.IdCategoria,
                StrCategoria: cat.Nombre,
                Logros: []
            }
            this.GetLogrosByCategoria(cat.IdCategoria)
                .forEach(logro => {
                    if (!logrosAsignados.has(logro.IdLogro)) {
                        itemCatLogros.Logros.push(logro);
                    }
                })

            if (itemCatLogros.Logros.length > 0) {
                catLogos.push(itemCatLogros);
            }
        })

        return catLogos;
    }

    private TableGetDataSelectedMenu() {
        let options: Table.ITableMenuDataSelectedOptionConfig<IAsignacion>[] = [];

        if (this.HasActionPermission(Entidad.CAccionPermiso.Eliminar)) {
            options.push({
                Label: "action_eliminar",
                Callback: (datos) => this.OpenModal_EliminarAsignaciones(datos)
            })
        }

        return options;
    }

    // **************************************************************************
    // PRIVATE METHODS
    // **************************************************************************

    private OpenModal_LogrosDisponibles(categoriasLogros: ICategoriaLogro[]) {
        let table: Table.Tabla<ICategoriaLogro>;

        ModalThings._GetModalToAProccess({
            Title: this.CARDCOLL_GetUIStringModule("tag_logrosdisponibles"),
            Modulo: this.modulo,
            Action: Entidad.CAccionPermiso.Agregar,
            IdsEscuelas: [this.currentEscuela.IdKinder],
            DrawContent: (content) => {
                table = new Table.Tabla<ICategoriaLogro>({
                    IdTabla: "AlumnosPanelLogros-LogrosDisponibles",
                    IdData: "IdCategoria",
                    Parent: content,
                    MinWidth: 400,
                    // FilterByStrSearch: "none", // FIXME
                    EnableRelevanceSelections: false,
                    RenderColumnHeadings: [
                        { Field: "StrCategoria", Label: this.CARDCOLL_GetUIStringModule("tag_categoria"), MinWidth: "40px", Width: "50%", IsSortable: false },
                        { Field: "Nombre" as any, Label: this.CARDCOLL_GetUIStringModule("tag_logro"), MinWidth: "40px", Width: "50%", IsSortable: false } // Level 2
                    ],
                    EvaluatorAndSubLevelsBuild: {
                        OnEvalIsCollapsedRow: () => false,

                        EvaluatorSubLevel: {
                            OnStepCellTable: (container, datum, field) => { },
                            OnGetData: (dato) => {
                                return dato.Logros
                            },
                            IdMember: "IdLogro",
                        },
                    }
                })
                table._Control.select(".area_filtros").remove(); // FIXME
                table._UpdateData(categoriasLogros);
                // table.prop_Control.selectAll(".collapsercell").remove();
            },
            OnAccept: async (modalThings) => {
                let dataChecked: Table.IDataCheckedAdvanced<ICategoriaLogro, ILogro, any, any>[] = table._DataAndChildsDataChecked;
                let logros: ILogro[] = [];
                if (dataChecked.length > 0) {
                    dataChecked.forEach(d => {
                        d.ChildsChecked.forEach(l => {
                            logros.push(l.Data);
                        })
                    })

                    // console.debug(logros, "Logros to insert");
                    let res = await this.Sv_AsignarLogros(logros.map(d => d.IdLogro));

                    if (res.Resultado > 0) {
                        // this.ctrlTabla.met_RefreshView();
                        //console.warn("Progress OnAccept");
                        //this.ctrlProgress.attr("oculto", false);
                    }
                    return res
                } else {
                    NotificacionV2._Mostrar(this.CARDCOLL_GetUIStringModule("notif_sinlogrosselec"), "ADVERTENCIA");
                }
                return null;
            }
        })
    }

    private OpenModal_EliminarAsignaciones(asignacionesLogros: IAsignacion[]) {
        this.CARDCOLLAD_OpenModal_ProccessArrayData<IAsignacion>({
            DataToProccess: asignacionesLogros,
            OnDrawContent: (container) => {
                let asignacionesEnProceso = asignacionesLogros
                    .reduce((asignacionResult, d) => {
                        let asignacionesReales = d.IdsAsignacionReal
                            .map(idAsignacion => DataModuloMain._GetItemDataByName("LogroAsignacion", idAsignacion))
                        asignacionResult.push(...asignacionesReales);
                        return asignacionResult;
                    }, new Array<IAsignacion>())
                    .filter((d) => (d != null && d?.Estado != CEstado.Registrado));
                let asignacionesEnProcesoByAlumno = d3Group(asignacionesEnProceso, (d) => d.IdChild);

                if (asignacionesEnProcesoByAlumno.size > 0) {
                    container.classed(UIUtilGeneral.FBoxOrientation.Vertical, true)
                        .style("gap", "var(--padding2)")
                    container.append("label")
                        .text(this.CARDCOLL_GetUIStringModule("tag_logroyaproceso") + ":")
                    new List<number>()
                        ._SetParent(container)
                        ._SetUpdateItem((container, idAlumno) => {
                            container.text(DataModuloMain._GetDataValueFieldByName("Alumno", idAlumno, "NombreCompleto"))
                            let logros = asignacionesEnProcesoByAlumno.get(idAlumno);
                            container.append("div")
                                .selectAll(":scope > li")
                                .data(logros)
                                .join("li")
                                .text(d => `${DataModuloMain._GetDataValueFieldByName("Logro", d.IdLogro, "Nombre")} (${d.StrEstado})`)
                        })
                        ._SetItems(Array.from(asignacionesEnProcesoByAlumno.keys()))
                        ._ListContainerSelection
                        .style("border-top", "1px solid var(--color_borderbox1)")
                }
            },
            OnGetIdEscuela: (dato) => this.currentEscuela.IdKinder,
            OnError_GetItemDataTag: (dato) => (dato.LogroNombre),
            TypeRequest: Entidad.CTipoRequest.LogroAsignacion,
            AccionToHttpMessage: "eliminar",
            OnStepAProccess: (asignacionLogro) => this.Sv_RemoverAsignaciones(asignacionLogro),
            OnEndAndCloseProccess: async (correctos) => {
                if (correctos.length) {
                    this.ctrlProgress.attr("oculto", false);
                }
                if (this.currentAlumnos.length > 1)
                    this.CARDCOLL_UpdateCardData(false, ...this.cardData); // Forzar actualización de la tabla
            }
        })
    }

    // **************************************************************************

    // public met_SetExitWhenFinished(value: boolean) {
    //     this.exitWhenFinished = value;
    // }

    // **************************************************************************
    // PERMISOS COSAS
    // **************************************************************************

    private GetDataAsignaciones(): IAsignacion[] {
        let asignaciones: IAsignacion[];
        if (this.currentAlumnos.length > 1) {
            asignaciones = this.logrosCompartidos.get(this.GetCurrentLogrosKey()) || [];
        }
        else {
            asignaciones = Array.from(_LOCALDATA_GetLogroAsignacionesDeAlumno(this.currentAlumnos[0].IdChild).values())
                .map((d) => ({
                    ...d,
                    ...{ IdsAsignacionReal: [d.IdAsignacion] }
                }));
            this.logrosCompartidos.set(this.GetCurrentLogrosKey(), asignaciones);
        }
        return asignaciones.map(d => {
            const asignacion: IAsignacion = Object.assign({}, d);
            const timeZone = this.currentEscuela.ZonaHoraria;
            asignacion.LogroNombre = DataModuloMain._GetDataValueFieldByName("Logro", d.IdLogro, "Nombre") || UIUtilLang._GetUIString("general", "nodisponible");
            asignacion.DtAvanzadoFmt = UIUtilTime._DateFormatStandarFixTimeZone(d.Avanzado, timeZone, "dd/mm/yyyy h12:mm");
            asignacion.DtIniciadoFmt = UIUtilTime._DateFormatStandarFixTimeZone(d.Iniciado, timeZone, "dd/mm/yyyy h12:mm");
            asignacion.DtTerminadoFmt = UIUtilTime._DateFormatStandarFixTimeZone(d.Terminado, timeZone, "dd/mm/yyyy h12:mm"); 1
            return asignacion;
        })
    }

    // **************************************************************************
    // SERVICIOS COSAS
    // **************************************************************************

    private auxId: number;
    private async Sv_AsignarLogros(idsLogros: number[]) {
        const idsAlumnos = this.currentAlumnos.map(d => d.IdChild);
        const logrosID = this.GetCurrentLogrosKey();
        const res = await DataModuloLogroAsignacion._AltaAsignacion(idsAlumnos, idsLogros, this.currentEscuela.IdKinder);
        if (res.Resultado > 0) {
            await MainPage._ReloadServiceAndAwaitBool(Entidad.CTipoRequest.LogroAsignacion, this.currentEscuela.IdKinder);
            if (idsAlumnos.length > 1) {
                if (this.auxId == null) this.auxId = 0;
                let asigancionesPlaying: IAsignacionBase[] = idsAlumnos.reduce((asignaciones, idAlumno) => {
                    let alumnoAsignaciones = _LOCALDATA_GetLogroAsignacionesDeAlumno(idAlumno);
                    asignaciones.push(...Array.from(alumnoAsignaciones.values()));
                    return asignaciones;
                }, new Array<IAsignacionBase>());
                let asigancionesByLogro = d3Group(asigancionesPlaying, (a) => a.IdLogro);
                let newTempAsignaciones = idsLogros.map((idLogro) => {
                    this.auxId--;
                    let idsAsignacionesByAlumnos = asigancionesByLogro.get(idLogro)
                        .map(d => d.IdAsignacion);
                    const valid = idsAsignacionesByAlumnos.length == idsAlumnos.length;
                    if (!valid) {
                        let msg = `Logro: ${idLogro}`;
                        msg += `\nAsignaciones: ${idsAsignacionesByAlumnos} (${idsAsignacionesByAlumnos.length})`;
                        msg += `\nAlumnos: ${idsAlumnos} (${idsAlumnos.length})`;
                        DataUtilAlertBot._SendWarn("AlumnosLogros fallo al actualizar vista de asiganciones", msg);
                    }
                    return <IAsignacion>{
                        IdAsignacion: this.auxId,
                        IdChild: 0,
                        Estado: CEstado.Registrado,
                        StrEstado: UIUtilViewData._GetStr_LogroAsignEstado("registrado"),
                        EnUso: true,
                        IdLogro: idLogro,
                        IdsAsignacionReal: (valid ? idsAsignacionesByAlumnos : []),
                    }
                })
                // console.warn("tempAsignaciones", newTempAsignaciones)
                let currentLogros = (this.logrosCompartidos.get(logrosID) || []);
                currentLogros.push(...newTempAsignaciones);
                this.logrosCompartidos.set(logrosID, currentLogros);
            }
        }
        return res;
    }

    private async Sv_RemoverAsignaciones(asignacionLogro: IAsignacion) {
        const currentAsignaciones = this.logrosCompartidos.get(this.GetCurrentLogrosKey());
        let arr = new ArrayV2<number>();
        arr.push(...asignacionLogro.IdsAsignacionReal)
        const res = await arr._MapAwait(async (idAsignacion, i) => ({
            ...(await DataModuloLogroAsignacion._EliminaAsignacion(idAsignacion)),
            ...{ IdAsignacion: idAsignacion }
        }));
        const correctos = res.filter(d => (d.Resultado > 0));
        const hasFallos = (correctos.length != asignacionLogro.IdsAsignacionReal.length);
        if (currentAsignaciones && correctos.length) {
            // >> Remueve los IDs reales que fueron desasignados.
            // Al REINTENTAR solo se mandarán al sv los fallidos
            correctos.forEach(d => {
                let i = asignacionLogro.IdsAsignacionReal.indexOf(d.IdAsignacion);
                if (i >= 0) {
                    asignacionLogro.IdsAsignacionReal.splice(i, 1);
                }
            })
            // >> Remover el logro principal (visual) si por lo menos una asignacion se removió
            // Al salir del modal ya no hay posibilidad de REINTENTAR, (num_alumnos != nun_asignaciones)
            let iLogro = currentAsignaciones.findIndex((d) => (d.IdAsignacion == asignacionLogro.IdAsignacion));
            // console.debug("Removiendo logros", fallos.length, asignacionLogro.IdsAsignacionReal.length, iLogro);
            if (iLogro > -1)
                currentAsignaciones.splice(iLogro, 1);
        }
        let result = res[0].Resultado;
        if (asignacionLogro.IdsAsignacionReal.length > 1 && hasFallos) {
            result = -10;
        }
        return <typeof res[0]>{
            Resultado: result,
            Data: res[0].Data,
            TipoRequest: res[0].TipoRequest,
        }
    }

}
