import * as d3 from "d3";
import saveAs from "file-saver";
import { DataDRequest } from "../../data/DRequest";
import { HTMLFileIcoElement } from "../controlWC/FileIcoComponent";
import { UIUtilIconResources } from "../util/IconResourses";
import { UIUtilLang } from "../util/Language";
import { UIUtilMimeType } from "../util/MimeTypes";
import { Button } from "./Button";
import { FullView } from "./FullView";
import { NotificacionV2 } from "./NotificacionV2";
import { PDFThings } from "./PDFBuilder";

type TFile = File | Blob;
type TDownload = (() => string | boolean) | string | boolean;
type TContent<TF extends TFile, TDown extends TDownload = TDownload> = {
    Id: any;
    Title?: string | (() => string);
    Description?: string | (() => string);
    /** @default true */
    Download?: TDown;
    ThumbnailIMG?: string | (() => string);
    Content: TF /* | Promise<TF> */ | (() => Promise<TF>) | (() => string);
    OnDraw?: (contentWrapper: TSelectionHTML<"div">, content: TSelectionHTML, contentInfo: TContent<TF>) => void;
}
    & (TF extends File
        ? {
            // Filename?: never;
            // FileExt?: never;
        }
        : {
            Filename: string | (() => string);
            FileExt?: string | (() => string);
        })
    & (TDown extends false // boolean
        ? {
            // Filename?: never;
            // FileExt?: never;
        }
        : {
            Filename: string | (() => string);
            FileExt?: string | (() => string);
        });

type TContentControl<T extends TFile> = TContent<T> & {
    __CurrentFile?: File;
};

interface IExtraOptions {
    Icon: string;
    Callback: (datum: TContent<any, any>) => unknown | Promise<any>;
    Description?: string
}

export class FullViewFile {
    #controler: FullView;
    #contentList: TContentControl<TFile>[];
    #currentIndex: number;
    #currentItemInfo: TContentControl<TFile>;
    #refreshTimeout: NodeJS.Timeout;
    #extraOpts: IExtraOptions[];

    constructor() {
        this.#currentIndex = 0;
        this.#contentList = [];
        this.#controler = new FullView();
        this.#extraOpts = [];
        this.Init();
    }

    private Init(): void {
        this.#controler._controlContainerSel
            .classed("fullview_files", true)
            .select(".content_wrapper")
            .call(contentWrapper => {
                ["btn_left", "btn_right"].forEach(btncls => {
                    const btn = contentWrapper.append("div").attr("class", "btn " + btncls)
                    btn.append("div");
                    btn.append("img").attr("src", UIUtilIconResources.CGeneral.AngleDown);
                })

                contentWrapper.select(".btn_left").on("click", () => {
                    this.#currentIndex--;
                    if (this.#currentIndex < 0)
                        this.#currentIndex = this.#contentList.length - 1;
                    this.Refresh();
                })
                contentWrapper.select(".btn_right").on("click", () => {
                    this.#currentIndex++;
                    if (this.#currentIndex > this.#contentList.length - 1)
                        this.#currentIndex = 0;
                    this.Refresh();
                });
            })
    }

    private Refresh(forceClear: boolean = false) {
        if (this.#refreshTimeout)
            clearTimeout(this.#refreshTimeout);

        this.#refreshTimeout = setTimeout(() => {
            this.#refreshTimeout = null;

            const lastContentInfo = this.#currentItemInfo;
            const contentInfo = this.GetSelectedInfo();
            const { Title, Description, Content } = contentInfo || {};
            let title = Title ? (typeof Title == "string" ? Title : Title()) : "";
            let description = Description ? (typeof Description == "string" ? Description : Description()) : null;
            let file: TFile | Promise<TFile>;
            let elementContent: HTMLElement | Promise<HTMLElement>;

            if (forceClear || lastContentInfo !== contentInfo) {
                this.#controler._RemoveContent();
                this.#controler._RemoveSpinners();
                this.#controler._SetTitle("");
                this.#controler._SetDescription("");
            }

            this.LoadThumbnail(contentInfo);
            this.UpdateOptions();

            if (!contentInfo) {
                elementContent = document.createElement("div");
                elementContent.textContent = UIUtilLang._GetUIString("general", "sincontenido");
            } else if (typeof Content == "function") {
                const contentAux = Content();
                if (typeof contentAux == "string") {
                    file = this.RequestFile(contentAux, contentInfo as TContent<any, string>);
                } else {
                    file = contentAux;
                }
            } else {
                file = Content;
            }

            this.#controler._SetTitle(title || (file instanceof File ? file.name : ""));
            this.#controler._SetDescription(description);

            if (file instanceof Promise) {
                this.#controler._AwaitContent(file
                    .then(file => {
                        return this.PrepareFile(file, (contentInfo as TContent<Blob>));
                    })
                    .then(file => {
                        if (!this.#controler._title) {
                            const fileName = (contentInfo as TContent<Blob>).Filename
                            this.#controler._SetTitle(file?.name || (typeof fileName === 'function' ? fileName() : fileName));
                        }
                        if (contentInfo === this.#currentItemInfo) {
                            contentInfo.__CurrentFile = file;
                        }
                        return this.GetContentElement(file)
                            .then(res => {
                                if (contentInfo.OnDraw)
                                    contentInfo.OnDraw(this.#controler._contentWrapperSel, d3.select(res), contentInfo);
                                return res;
                            });
                    })
                    .catch(e => (console.warn(e), null))
                )
            }
            else {
                const finalFile = this.PrepareFile(file, (contentInfo as TContent<Blob>)/* , (contentInfo as TContent<Blob>).FileExt */);
                contentInfo.__CurrentFile = finalFile;
                this.#controler._AwaitContent(this.GetContentElement(finalFile)
                    .then(res => {
                        if (contentInfo.OnDraw)
                            contentInfo.OnDraw(this.#controler._contentWrapperSel, d3.select(res), contentInfo);
                        return res;
                    }));
            }
        }, 50);
    }

    private LoadThumbnail(contentInfo: TContent<any>) {
        const thumbnailURL = typeof contentInfo.ThumbnailIMG == "function" ? contentInfo.ThumbnailIMG() : contentInfo.ThumbnailIMG;
        if (!thumbnailURL) return;
        const spinner = this.#controler._ShowSpinner();
        DataDRequest._RequestBlobFromUrlResourceV2(thumbnailURL, async (fileBlob, error) => {
            spinner.remove();
            if (error || !fileBlob) {
                return
            }
            const file = this.PrepareFile(fileBlob, { Filename: "thumbnail" });
            const content = await this.GetContentElement(file);
            if (contentInfo === this.#currentItemInfo && !this.#currentItemInfo.__CurrentFile)
                this.#controler._Content(content);
        })
    }

    private UpdateOptions(contentInfo: TContent<any, any> = this.#currentItemInfo) {
        if (!contentInfo) {
            this.#controler._Options([]);
            return;
        }
        const fullOptions: IExtraOptions[] = this.#extraOpts ? [...this.#extraOpts] : [];

        const downloadInfo = (typeof contentInfo.Download == "function" ? contentInfo.Download() : contentInfo.Download);
        if (!(downloadInfo === false)) {
            fullOptions.push({
                Icon: UIUtilIconResources.CGeneral.Download,
                Callback: () => this.DownloadFile(contentInfo),
                Description: UIUtilLang._GetUIString("general", "descargar"),
            });
        }

        this.#controler._Options(fullOptions
            .map(d => ({
                Icon: d.Icon,
                Description: d.Description,
                OnClick: () => {
                    const res = d.Callback(contentInfo);
                    if (res instanceof Promise) {
                        this.#controler._controlContainerSel.selectAll(".footer > .actions > *").each((_, i, elemts) => {
                            Button._EnableButton(d3.select(elemts[i] as HTMLElement), false);
                        })
                        res.finally(() => {
                            this.#controler._controlContainerSel.selectAll(".footer > .actions > *").each((_, i, elemts) => {
                                Button._EnableButton(d3.select(elemts[i] as HTMLElement), true);
                            })
                        })
                    }
                }
            }))
        );
    }

    private PrepareFile(fileBlob: TFile, { Filename }: Partial<TContent<Blob>>/* , fileExt: string */): File | null {
        const fileName = typeof Filename == 'function' ? Filename() : (Filename || "file");
        if (fileBlob && !(fileBlob instanceof File)) {
            // const extension = Util.MimeType.fn_GetExtensionFromMimeType(fileBlob.type) || fileExt.replace(".", "").toLowerCase();
            return new File([fileBlob], fileName, { type: fileBlob.type });
        }
        return fileBlob as File;
    }

    private async DownloadFile(contentInfo: TContentControl<TFile>) {
        const downloadInfo = typeof contentInfo.Download == "function" ? contentInfo.Download() : contentInfo.Download;

        if (downloadInfo === false) return;

        const spinner = this.#controler._ShowSpinner();

        if (typeof downloadInfo == "boolean" || downloadInfo == null) {
            let file = contentInfo.__CurrentFile;
            if (!file && (typeof contentInfo.Content == "function" || contentInfo.Content instanceof Promise)) {
                let promiseFile: Promise<TFile>;
                if (typeof contentInfo.Content == "function") {
                    const contentAux = contentInfo.Content();
                    if (typeof contentAux == "string")
                        promiseFile = this.RequestFile(contentAux, contentInfo as TContent<any, string>);
                    else
                        promiseFile = contentAux;

                }
                else
                    promiseFile = contentInfo.Content;

                const res = await promiseFile
                    .catch(() => null);

                file = this.PrepareFile(res, (contentInfo as TContent<Blob>));
            }
            else console.debug("ya existia")

            saveAs(file);
            spinner.remove();
            return
        }
        return this.RequestFile(downloadInfo, contentInfo as TContent<any, string>)
            .then(file => {
                saveAs(file);
            })
            .catch(() => {
                NotificacionV2._Mostrar(UIUtilLang._GetUIString("general", "notif_fail"), "ADVERTENCIA")
            })
            .finally(() => {
                spinner.remove();
            })
    }

    private RequestFile(url: string, contentInfo: TContent<any, string>) {
        return new Promise<File>((resolve, reject) => {
            DataDRequest._RequestBlobFromUrlResource(url, (fileBlob, error) => {
                if (error || !fileBlob) {
                    reject(error);
                    return
                }
                resolve(this.PrepareFile(fileBlob, contentInfo/* , FileExt */));
            })
        })
    }

    private async GetContentElement(file?: File): Promise<HTMLElement> {
        if (!file) return null;

        if (file.type.toLowerCase().includes("pdf") || file.name.toLowerCase().endsWith(".pdf")) {
            return PDFThings._PDFViewer(file);
        }
        return new Promise((resolve) => {
            const urlImg = URL.createObjectURL(file);
            const img = document.createElement("img");
            img.src = urlImg;
            img.onload = e => {
                URL.revokeObjectURL(urlImg);
                resolve(img);
            }
            img.onerror = e => {
                console.debug("No es posible renderizar el archivo", file.name);
                const fileIco = document.createElement("wc-fileico") as HTMLFileIcoElement;
                fileIco._Filename = file.name;
                fileIco._Ext = file.type.includes("/") ? UIUtilMimeType._GetExtensionFromMimeType(file.type) : file.type.replace(".", "").toUpperCase();
                fileIco._LblSize = "15px"; // REMOVER // FIXME
                resolve(fileIco);
            }
        })
    }

    private GetSelectedInfo(index = this.#currentIndex) {
        const lastSelection = this.#currentItemInfo;
        if (!this.#contentList.length) {
            if (lastSelection) lastSelection.__CurrentFile = null;
            this.#currentItemInfo = null;
            this.#currentIndex = 0;
            return null;
        }
        if (!this.#contentList[index])
            index = 0;
        this.#currentIndex = index;
        this.#currentItemInfo = this.#contentList[index];
        if (lastSelection && lastSelection === this.#currentItemInfo) lastSelection.__CurrentFile = null;
        return this.#currentItemInfo;
    }

    get _controlContainerSel() {
        return this.#controler._controlContainerSel
    }

    get _btnCloseNode() {
        return this.#controler._btnCloseNode;
    }

    _SetContent<T extends TFile, TDown extends TDownload>(contentList: TContent<T, TDown>[]): this {
        this.#contentList = contentList as TContent<TFile>[];

        this.#controler._controlContainerSel.select(".content_wrapper")
            .selectAll(":scope > .btn")
            .classed("hide", !(contentList.length > 1));

        this.Refresh();
        return this;
    }

    _FocusID(id: any): this {
        this.#currentIndex = this.#contentList.findIndex(d => d.Id === id);
        this.Refresh();
        return this;
    }

    _Refresh(clear: boolean = false): this {
        this.Refresh(clear);
        return this;
    }

    _Destroy(): this {
        this.#controler._Destroy();
        return this;
    }

    _SetExtraOptions(opts: IExtraOptions[]) {
        this.#extraOpts = opts;
        this.UpdateOptions();
        return this;
    }

    _OnRemove(call: () => void): this {
        this.#controler._OnRemove(call);
        return this;
    }

    _ShowSpinner(): this {
        this.#controler._ShowSpinner();
        return this;
    }

    _RemoveSpinners(): this {
        this.#controler._RemoveSpinners();
        return this;
    }
}
