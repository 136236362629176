import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import { CAlumnoEdoLicencia, CLicenciaEstado, CLicenciaFormaPago } from "../../data/entidad/LicenciaOld";
import { UIUtilIconResources } from "./IconResourses";
import { UIUtilLang } from "./Language";
import { UIUtilStrings } from "./Strings";
import { UIUtilTime } from "./Time";

export namespace UIUtilViewData {
    export interface IBaseData extends IDatoBase {
    }

    export interface IPeriodoD extends IBaseData {
        Month: number;
        Year: number;
    }

    /** Obtiene todos los meses (dentro del ciclo escolar) */
    export function _GetPeriodosFromCicloEscolar(cicloEscolar: Entidad.ICicloEscolar, onlyBeforeCurrentDate = true): IPeriodoD[] {
        // const getIsValidPeriodoByMovs = (periodoValidator: number) => {
        //     for (let mov of this.listaMovimientos) {
        //         if (GetDateValidatorYM(new Date(mov.FechaAplicacion)) <= periodoValidator) {
        //             return true;
        //         }
        //     }
        //     return false;
        // }
        const currentDateValidator = (onlyBeforeCurrentDate ? UIUtilTime._GetValidatorDateYM(new Date()) : 999999);
        const startDateCicloEscolarValidator = UIUtilTime._GetValidatorDateYM(new Date(cicloEscolar.FechaInicio));
        let auxDateProcesoPeriodo = new Date(cicloEscolar.FechaFin);
        auxDateProcesoPeriodo.setDate(2);
        let procesoPeriodoValidator = UIUtilTime._GetValidatorDateYM(auxDateProcesoPeriodo);
        let periodos: IPeriodoD[] = [];
        let index = 1;

        // console.log(cicloEscolar.Nombre, "________________________")
        // console.log(startDateValidator, "startDateValidator", currentDateValidator, "currentDateValidator");
        // Los periodos se válidan, del ultimo, al primero. Son inválidos los mayores al mes y año actual
        while (procesoPeriodoValidator >= startDateCicloEscolarValidator) {
            let periodo: IPeriodoD = {
                Id: index,
                Month: auxDateProcesoPeriodo.getMonth(),
                Year: auxDateProcesoPeriodo.getFullYear(),
                Name: UIUtilStrings._CapitaliceString(UIUtilTime._DateFormatStandar(auxDateProcesoPeriodo, "MMM yyyy"))
            };
            // console.log(auxValidator, "aux validator")
            if (procesoPeriodoValidator <= currentDateValidator) { // && getIsValidPeriodoByMovs(procesoPeriodoValidator)) {
                periodos.push(periodo);
            }
            auxDateProcesoPeriodo.setMonth(auxDateProcesoPeriodo.getMonth() - 1);
            procesoPeriodoValidator = UIUtilTime._GetValidatorDateYM(auxDateProcesoPeriodo);
            index++;
        }
        // console.log(cicloEscolar, periodos, "all periodos");
        return periodos;
    }

    /** Obtiene todos los dias de la semana con el lenguaje actual */
    export function _GetAllWeekDays(): IBaseData[] {
        return [
            { Id: Entidad.CDiaSemanal.Lunes, Name: UIUtilTime._GetDayName(Entidad.CDiaSemanal.Lunes) },
            { Id: Entidad.CDiaSemanal.Martes, Name: UIUtilTime._GetDayName(Entidad.CDiaSemanal.Martes) },
            { Id: Entidad.CDiaSemanal.Miercoles, Name: UIUtilTime._GetDayName(Entidad.CDiaSemanal.Miercoles) },
            { Id: Entidad.CDiaSemanal.Jueves, Name: UIUtilTime._GetDayName(Entidad.CDiaSemanal.Jueves) },
            { Id: Entidad.CDiaSemanal.Viernes, Name: UIUtilTime._GetDayName(Entidad.CDiaSemanal.Viernes) },
            { Id: Entidad.CDiaSemanal.Sabado, Name: UIUtilTime._GetDayName(Entidad.CDiaSemanal.Sabado) },
            { Id: Entidad.CDiaSemanal.Domingo, Name: UIUtilTime._GetDayName(Entidad.CDiaSemanal.Domingo) },
        ];
    }

    /** Obtiene los meses disponibles del año indicado
     * @param year
     * @param mode
     * * "endsInCurrentYear": Los meses del año actual se limitan hasta el mes actual
     * * "starsInCurrentYear": Los meses inician a partir del año actual
     * @default "endsInCurrentYear"
     */
    export function _GetMonthsByYearCurrentMonthLimit(year: number, mode: ("endsInCurrentDtMonth" | "startsInCurrentDtMonth") = "endsInCurrentDtMonth") {
        let today = new Date();
        let months: IBaseData[] = [];
        const allMonths = UIUtilTime._GetYearMonths();

        let maxMonth = 11;
        let minMonth = 0;

        if (year == today.getFullYear()) {
            switch (mode) {
                case "endsInCurrentDtMonth":
                    maxMonth = today.getMonth();
                    break;
                case "startsInCurrentDtMonth":
                    minMonth = today.getMonth();
                    break;
            }
        }

        for (let m = minMonth; m <= maxMonth; m++) {
            months.push({
                Id: m,
                Name: allMonths[m]
            })
        }

        return months;
    }

    // *************************************************************************
    // Enum Base Lang context
    // *************************************************************************

    type TEnumKeyParam<T extends { [key: number]: string }> = (T[keyof T & string] & number | Lowercase<keyof T & string>);
    // (TEnum | Lowercase<keyof TEnum & string>);

    function GetEnumStringContext<T extends { [key: number]: string }>(enumerator: T, stringsKey: string, stringKey: (T[keyof T & string] & number | Lowercase<keyof T & string>)) {
        const strKey: string = (typeof stringKey == "number" ? enumerator[stringKey] : stringKey);
        if (strKey) {
            return (UIUtilLang._GetUIString(stringsKey, strKey) || "");
        }
        return "";
    }

    interface TBaseItemList<T> {
        Id: T[keyof T & string];
        Name: string;
    }
    function GetEnumListContext<T extends { [key: number]: string }, TItemList extends TBaseItemList<T> = TBaseItemList<T>>(enumerator: T, stringsKey: string, iteratorFn?: (enumVal: (T[keyof T & string]), enumStringKey: (keyof T & string)) => Omit<TItemList, keyof Pick<TItemList, "Id">>) {
        let list = new Array<TItemList>();

        for (const key in enumerator) {
            if (isNaN(Number(key))) {
                const stringKey: (keyof T & string) = key;
                const enumVal = (enumerator[stringKey]);
                let itemList: TItemList = <TItemList>{
                    Id: enumVal
                };

                if (iteratorFn) {
                    itemList = {
                        ...itemList,
                        ...iteratorFn(enumVal, stringKey)
                    }
                } else {
                    itemList.Name = GetEnumStringContext(enumerator, stringsKey, stringKey as Lowercase<keyof T & string>);
                }

                list.push(itemList);
            }
        }

        return list;
    }

    // *************************************************************************
    // Modulos
    // *************************************************************************

    const STRINGSKEY_MODULO = "c_modules";

    export function _GetStr_Modulo(key: TEnumKeyParam<typeof Entidad.CModulo>): string {
        return GetEnumStringContext(Entidad.CModulo, STRINGSKEY_MODULO, key);
    }

    // *************************************************************************
    // Acciones generales
    // *************************************************************************

    const STRINGSKEY_ACCION = "c_actions";

    export function _GetStr_Accion(key: TEnumKeyParam<typeof Entidad.CAccionPermiso>): string {
        return GetEnumStringContext(Entidad.CAccionPermiso, STRINGSKEY_ACCION, key);
    }

    // *************************************************************************
    // Logro Tipos proceso
    // *************************************************************************

    const STRINGSKEY_LOGROTIPOPROCESO = "c_logrotipoproceso";

    export function _GetStr_LogroTipoProceso(key: TEnumKeyParam<typeof Entidad.CLogroTipoProceso>): string {
        return GetEnumStringContext(Entidad.CLogroTipoProceso, STRINGSKEY_LOGROTIPOPROCESO, key);
    }

    // *************************************************************************
    // Logro Estado asignación
    // *************************************************************************

    const STRINGSKEY_LOGROASIGNESTADO = "c_logroasignestado";

    export function _GetList_LogroAsignEstados() {
        return GetEnumListContext(Entidad.CLogroAsignacionEstado, STRINGSKEY_LOGROASIGNESTADO);
    }

    export function _GetStr_LogroAsignEstado(key: TEnumKeyParam<typeof Entidad.CLogroAsignacionEstado>): string {
        return GetEnumStringContext(Entidad.CLogroAsignacionEstado, STRINGSKEY_LOGROASIGNESTADO, key);
    }

    // *************************************************************************
    // Tipos eventos
    // *************************************************************************

    const STRINGSKEY_TIPOEVENTO = "c_tipoeventos";
    export interface ITipoEventoItemList<TTipo extends TEventoTipo> extends TBaseItemList<(TTipo extends "reportes" ? typeof Entidad.CReporteTipoEvento : typeof Entidad.CTipoEvento)> {
        Icon: UIUtilIconResources.CActividadesEscolaridad;
    }
    type TEventoTipo = "normal" | "reportes";

    export function _GetList_TiposEventos<TTipo extends TEventoTipo>(type: TTipo, idEscuela: number): ITipoEventoItemList<TTipo>[] {
        const list: ITipoEventoItemList<"normal" | "reportes">[] = [
            {
                Id: Entidad.CTipoEvento.Comentarios,
                Name: _GetStr_TipoEvento("comentarios"),
                Icon: UIUtilIconResources.CActividadesEscolaridad.Comentarios
            },
            {
                Id: Entidad.CTipoEvento.Humor,
                Name: _GetStr_TipoEvento("humor"),
                Icon: UIUtilIconResources.CActividadesEscolaridad.Humor
            },
            {
                Id: Entidad.CTipoEvento.Alimentos,
                Name: _GetStr_TipoEvento("alimentos"),
                Icon: UIUtilIconResources.CActividadesEscolaridad.Alimentos
            },
            {
                Id: Entidad.CTipoEvento.Fotos,
                Name: _GetStr_TipoEvento("fotos"),
                Icon: UIUtilIconResources.CActividadesEscolaridad.Fotos
            },
            {
                Id: Entidad.CTipoEvento.Higiene,
                Name: _GetStr_TipoEvento("higiene"),
                Icon: UIUtilIconResources.CActividadesEscolaridad.Higiene
            },
            {
                Id: Entidad.CTipoEvento.Actividades,
                Name: _GetStr_TipoEvento("actividades"),
                Icon: UIUtilIconResources.CActividadesEscolaridad.Actividades
            },
            ...(() => {
                const moduloEval = DataModuloMain._GetDataValueFieldByName("Escuela", idEscuela, "ModuloEval");
                if (!moduloEval || moduloEval == Entidad.CTipoEvento.Logros) {
                    return [{
                        Id: Entidad.CTipoEvento.Logros,
                        Name: _GetStr_TipoEvento("logros"),
                        Icon: UIUtilIconResources.CActividadesEscolaridad.Logros
                    }]
                } else {
                    return [{
                        Id: Entidad.CTipoEvento.Evaluacion,
                        Name: _GetStr_TipoEvento("evaluacion"),
                        Icon: UIUtilIconResources.CActividadesEscolaridad.Logros
                    }]
                }
            })(),
            {
                Id: Entidad.CTipoEvento.Siesta,
                Name: _GetStr_TipoEvento("siesta"),
                Icon: UIUtilIconResources.CActividadesEscolaridad.Siesta
            }
        ]

        if (type == "reportes") {
            list.push({
                Id: Entidad.CReporteTipoEvento.Asistencia as any,
                Name: _GetStr_TipoEvento("asistencia"),
                Icon: null
            })
        }

        return list;
    }

    export function _GetStr_TipoEvento(key: TEnumKeyParam<typeof Entidad.CReporteTipoEvento>) {
        return GetEnumStringContext(Entidad.CReporteTipoEvento, STRINGSKEY_TIPOEVENTO, key);
    }

    // *************************************************************************
    // Emociones
    // *************************************************************************

    const CEmociones = Entidad.CEmociones;
    type CEmociones = typeof CEmociones;
    export interface IEmocionItemList extends TBaseItemList<typeof CEmociones> {
        Icon: UIUtilIconResources.CEmociones;
    }

    const STRINGSKEY_EMOCIONES = "c_emociones";

    export function _GetList_Emociones(): IEmocionItemList[] {

        return GetEnumListContext<CEmociones, IEmocionItemList>(CEmociones, STRINGSKEY_EMOCIONES, (enumVal, enumStringKey) => {
            return {
                Name: _GetStr_Emocion(enumStringKey as any),
                Icon: UIUtilIconResources.CEmociones[enumStringKey]
            }
        });
    }

    export function _GetStr_Emocion(key: TEnumKeyParam<typeof Entidad.CEmociones>): string {
        return GetEnumStringContext(Entidad.CEmociones, STRINGSKEY_EMOCIONES, key);
    }

    // *************************************************************************
    // Tipo de licencias
    // *************************************************************************

    const STRINGSKEY_TIPOLICENCIA = "c_tipolicencia";

    export function _GetList_TipoLicencia() {
        return GetEnumListContext(Entidad.CTipoLicencia, STRINGSKEY_TIPOLICENCIA);
    }

    export function _GetStr_TipoLicencia(key: TEnumKeyParam<typeof Entidad.CTipoLicencia>): string {
        return GetEnumStringContext(Entidad.CTipoLicencia, STRINGSKEY_TIPOLICENCIA, key);
    }

    // *************************************************************************
    // Perfil User
    // *************************************************************************

    const STRINGSKEY_PERFILUSER = "c_perfiluser";

    export function _GetList_PerfilUser() {
        return GetEnumListContext(Entidad.CTipoPerfil, STRINGSKEY_PERFILUSER);
    }

    export function _GetStr_PerfilUser(key: TEnumKeyParam<typeof Entidad.CTipoPerfil>): string {
        return GetEnumStringContext(Entidad.CTipoPerfil, STRINGSKEY_PERFILUSER, key);
    }

    // *************************************************************************
    // Alumno movimientos (estados)
    // *************************************************************************

    const STRINGSKEY_ESTADOSMOVS = "c_estadosmovimientos";

    export function _GetList_EstadosMovimientos() {
        return GetEnumListContext(Entidad.CNinioMovimiento, STRINGSKEY_ESTADOSMOVS);
    }

    export function _GetStr_EdoMovimiento(key: TEnumKeyParam<typeof Entidad.CNinioMovimiento>): string {
        return GetEnumStringContext(Entidad.CNinioMovimiento, STRINGSKEY_ESTADOSMOVS, key);
    }

    // *************************************************************************
    // Sexo
    // *************************************************************************

    const STRINGSKEY_SEXO = "c_sexo";

    export function _GetList_Sexo() {
        return [
            // {Id: data.Entidades.CSexo.Default, Name: ""},
            {
                Id: Entidad.CSexo.Femenino,
                Name: _GetStr_Sexo("femenino")
            },
            {
                Id: Entidad.CSexo.Masculino,
                Name: _GetStr_Sexo("masculino")
            }
        ]
    }

    export function _GetStr_Sexo(key: TEnumKeyParam<typeof Entidad.CSexo>): string {
        return GetEnumStringContext(Entidad.CSexo, STRINGSKEY_SEXO, key);
    }

    // *************************************************************************
    // Finanza periodicidad
    // *************************************************************************

    type CPerioricidad = typeof Entidad.CFinanzaCargoPeriodicidadCargos;
    const CPerioricidad = Entidad.CFinanzaCargoPeriodicidadCargos;
    interface IPeriodicidadItemList extends TBaseItemList<typeof CPerioricidad> {
        NMonths: number;
    }
    const STRINGSKEY_PERIODICIDAD = "c_periodicidad";

    export function _GetList_Periodicidad() {

        return GetEnumListContext<CPerioricidad, IPeriodicidadItemList>(CPerioricidad, STRINGSKEY_PERIODICIDAD, (enumVal, enumStringKey) => {
            let nMonths = 0;

            switch (enumVal) {
                case CPerioricidad.Mensual:
                    nMonths = 1;
                    break;
                case CPerioricidad.Bimestral:
                    nMonths = 2;
                    break;
                case CPerioricidad.Trimestral:
                    nMonths = 3;
                    break;
                case CPerioricidad.Cuatrimestral:
                    nMonths = 4;
                    break;
                case CPerioricidad.Semestral:
                    nMonths = 6;
                    break;
                case CPerioricidad.Anual:
                    nMonths = 12;
                    break;
            }
            return {
                Name: _GetStr_Periodicidad(enumStringKey as any),
                NMonths: nMonths
            }
        });
    }

    export function _GetStr_Periodicidad(key: TEnumKeyParam<typeof Entidad.CFinanzaCargoPeriodicidadCargos>): string {
        return GetEnumStringContext(Entidad.CFinanzaCargoPeriodicidadCargos, STRINGSKEY_PERIODICIDAD, key);
    }

    // *************************************************************************
    // Finanza Cargos categoría
    // *************************************************************************

    const STRINGSKEY_CARGOCATEGORIA = "c_cargocategoria";

    export function _GetList_CargoCategoria() {
        return GetEnumListContext(Entidad.CFinanzaCargoCategoria, STRINGSKEY_CARGOCATEGORIA);
    }

    export function _GetStr_CargoCategoria(key: TEnumKeyParam<typeof Entidad.CFinanzaCargoCategoria>): string {
        return GetEnumStringContext(Entidad.CFinanzaCargoCategoria, STRINGSKEY_CARGOCATEGORIA, key);
    }

    // *************************************************************************
    // Tipo valor
    // *************************************************************************

    const STRINGSKEY_TIPOVALOR = "c_tipovalor";

    type TTipoListValor = "partial" | "complete";
    /**
     * @param type
     * * "partial" returns [Monto, Porcentaje]
     * * "complete" returns [Ninguno, Monto, Porcentaje]
     */
    export function _GetList_TipoValor(type: TTipoListValor = "partial") {
        if (type == "partial") {
            return [
                {
                    Id: Entidad.CFinanzaCargoTipoValor.Monto,
                    Name: _GetStr_TipoValor("monto")
                },
                {
                    Id: Entidad.CFinanzaCargoTipoValor.Porcentaje,
                    Name: _GetStr_TipoValor("porcentaje")
                }
            ]
        } else {
            return GetEnumListContext(Entidad.CFinanzaCargoTipoValor, STRINGSKEY_TIPOVALOR);
        }
    }

    export function _GetStr_TipoValor(key: TEnumKeyParam<typeof Entidad.CFinanzaCargoTipoValor>): string {
        return GetEnumStringContext(Entidad.CFinanzaCargoTipoValor, STRINGSKEY_TIPOVALOR, key);
    }

    // *************************************************************************
    // Tipo interés
    // *************************************************************************

    const STRINGSKEY_TIPOINTERES = "c_tipointeres";

    export function _GetList_TipoInteres() {
        return [
            // { Id: data.Entidades.CFinanzaCargoTipoValor.Porcentaje, Name: "" },
            {
                Id: Entidad.CFinanzaCargoTipoInteres.MontoUnico,
                Name: _GetStr_TipoInteres("montounico")
            },
            {
                Id: Entidad.CFinanzaCargoTipoInteres.Simple,
                Name: _GetStr_TipoInteres("simple")
            },
            {
                Id: Entidad.CFinanzaCargoTipoInteres.Compuesto,
                Name: _GetStr_TipoInteres("compuesto")
            },
        ]
    }

    export function _GetStr_TipoInteres(key: TEnumKeyParam<typeof Entidad.CFinanzaCargoTipoInteres>): string {
        return GetEnumStringContext(Entidad.CFinanzaCargoTipoInteres, STRINGSKEY_TIPOINTERES, key);
    }

    // *************************************************************************
    // Tipo Tasa
    // *************************************************************************

    const STRINGSKEY_TIPOTASA = "c_tipo_tasa";

    export function _GetStr_TipoTasa(key: TEnumKeyParam<typeof Entidad.CFinanzasCargoTipoTasa>): string {
        return GetEnumStringContext(Entidad.CFinanzasCargoTipoTasa, STRINGSKEY_TIPOTASA, key);
    }

    // *************************************************************************
    // Tipo Hora extra
    // *************************************************************************

    const STRINGSKEY_TIPOHORAEXTRA = "c_tipohoraextra";

    export function _GetList_TipoHoraExtra() {
        return GetEnumListContext(Entidad.CTipoHoraExtra, STRINGSKEY_TIPOHORAEXTRA);
    }

    export function _GetStr_TipoHoraExtra(key: TEnumKeyParam<typeof Entidad.CTipoHoraExtra>): string {
        return GetEnumStringContext(Entidad.CTipoHoraExtra, STRINGSKEY_TIPOHORAEXTRA, key);
    }

    // *************************************************************************
    // Tipo Estado de cobro
    // *************************************************************************

    const STRINGSKEY_ESTADOCOBRO = "c_edocobro";

    export function _GetList_EdoCobro() {
        return GetEnumListContext(Entidad.CEstadoCobro, STRINGSKEY_ESTADOCOBRO);
    }

    export function _GetStr_EdoCobro(key: TEnumKeyParam<typeof Entidad.CEstadoCobro>): string {
        return GetEnumStringContext(Entidad.CEstadoCobro, STRINGSKEY_ESTADOCOBRO, key);
    }

    // *************************************************************************
    // Circular Estado de autorización
    // *************************************************************************

    const STRINGSKEY_EDOAUTORIZACION = "c_edoautorizacion";

    export function _GetList_EdoAutorizacion() {
        return GetEnumListContext(Entidad.CCircularEstadoAutorizacion, STRINGSKEY_EDOAUTORIZACION);
    }

    export function _GetStr_EdoAutorizacion(key: TEnumKeyParam<typeof Entidad.CCircularEstadoAutorizacion>): string {
        return GetEnumStringContext(Entidad.CCircularEstadoAutorizacion, STRINGSKEY_EDOAUTORIZACION, key);
    }

    // *************************************************************************
    // Tipo expediente
    // *************************************************************************

    const STRINGSKEY_TIPOEXPEDIENTE = "c_tipoexpediente";

    export function _GetList_TiposExpedientes() {
        return GetEnumListContext(Entidad.CTipoExpediente, STRINGSKEY_TIPOEXPEDIENTE);
    }

    export function _GetStr_TipoExpediente(key: TEnumKeyParam<typeof Entidad.CTipoExpediente>): string {
        return GetEnumStringContext(Entidad.CTipoExpediente, STRINGSKEY_TIPOEXPEDIENTE, key);
    }

    // *************************************************************************
    // Finanzas Categoria movimiento
    // *************************************************************************

    const STRINGSKEY_CATEGORIAMOV = "c_categmov";

    export function _GetList_CategoriasMovimientos() {
        return GetEnumListContext(Entidad.CFinanzaCategoriaMovimiento, STRINGSKEY_CATEGORIAMOV);
    }

    export function _GetStr_CategoriaMovimiento(key: TEnumKeyParam<typeof Entidad.CFinanzaCategoriaMovimiento>): string {
        return GetEnumStringContext(Entidad.CFinanzaCategoriaMovimiento, STRINGSKEY_CATEGORIAMOV, key);
    }

    // *************************************************************************
    // Banio
    // *************************************************************************

    const STRINGSKEY_BANIO = "c_banio";

    export function _GetList_Banio() {
        return GetEnumListContext(Entidad.CBanio, STRINGSKEY_BANIO);
    }

    export function _GetStr_Banio(key: TEnumKeyParam<typeof Entidad.CBanio>): string {
        return GetEnumStringContext(Entidad.CBanio, STRINGSKEY_BANIO, key);
    }

    // *************************************************************************
    // Licencias Estados
    // *************************************************************************

    const STRINGSKEY_LICENCIAEDO = "c_licenciaedo";

    export function _GetList_LicenciaEdo() {
        return GetEnumListContext(CLicenciaEstado, STRINGSKEY_LICENCIAEDO);
    }

    export function _GetStr_LicenciaEdo(key: TEnumKeyParam<typeof CLicenciaEstado>): string {
        return GetEnumStringContext(CLicenciaEstado, STRINGSKEY_LICENCIAEDO, key);
    }

    // *************************************************************************
    // Alumno Licencia Estados
    // *************************************************************************

    const STRINGSKEY_ALUMNOEDOLICENCIA = "c_alumnoedolicencia";

    export function _GetList_AlumnoLicenciaEdo() {
        return GetEnumListContext(CAlumnoEdoLicencia, STRINGSKEY_ALUMNOEDOLICENCIA);
    }

    export function _GetStr_AlumnoLicenciaEdo(key: TEnumKeyParam<typeof CAlumnoEdoLicencia>): string {
        return GetEnumStringContext(CAlumnoEdoLicencia, STRINGSKEY_ALUMNOEDOLICENCIA, key);
    }

    // *************************************************************************
    // Forma de pago
    // *************************************************************************

    const STRINGSKEY_FORMAPAGO = "c_formapago";

    export function _GetList_FormaPago() {
        return GetEnumListContext(CLicenciaFormaPago, STRINGSKEY_FORMAPAGO);
    }

    export function _GetStr_FormaPago(key: TEnumKeyParam<typeof CLicenciaFormaPago>): string {
        return GetEnumStringContext(CLicenciaFormaPago, STRINGSKEY_FORMAPAGO, key);
    }

    // *************************************************************************
    // Escuela (Info extra de alumno) - Tipo de dato
    // *************************************************************************

    const STRINGSKEY_INFOEXTRATIPODATO = "c_infoextratipodato";

    export function _GetList_InfoExtraTipoDato() {
        return GetEnumListContext(Entidad.CEscuelaInfoExtraTipoDato, STRINGSKEY_INFOEXTRATIPODATO);
    }

    export function _GetStr_InfoExtraTipoDato(key: TEnumKeyParam<typeof Entidad.CEscuelaInfoExtraTipoDato>): string {
        return GetEnumStringContext(Entidad.CEscuelaInfoExtraTipoDato, STRINGSKEY_INFOEXTRATIPODATO, key);
    }

    // *************************************************************************
    // Egreso - Tipo Gasto
    // *************************************************************************

    const STRINGSKEY_FINANZAEGRESOTIPOGASTO = "c_egresotipogasto";

    export function _GetList_EgresoTipo() {
        return GetEnumListContext(Entidad.CFinanzaEgresoTipo, STRINGSKEY_FINANZAEGRESOTIPOGASTO);
    }

    export function _GetStr_EgresoTipo(key: TEnumKeyParam<typeof Entidad.CFinanzaEgresoTipo>): string {
        return GetEnumStringContext(Entidad.CFinanzaEgresoTipo, STRINGSKEY_FINANZAEGRESOTIPOGASTO, key);
    }

    // *************************************************************************
    // Cuenta Bancaria - Tipo
    // *************************************************************************

    const STRINGSKEY_TIPOCUENTABANCARIA = "c_tipocuentabancaria";

    export function _GetList_TipoCuentaBancaria() {
        return GetEnumListContext(Entidad.CTipoCuentaBancaria, STRINGSKEY_TIPOCUENTABANCARIA);
    }

    export function _GetStr_TipoCuentaBancaria(key: TEnumKeyParam<typeof Entidad.CTipoCuentaBancaria>): string {
        return GetEnumStringContext(Entidad.CTipoCuentaBancaria, STRINGSKEY_TIPOCUENTABANCARIA, key);
    }

    const STRINGSKEY_MATERIATIPOEVALUACION = "c_tipoevaluacionmateria";
    export function _GetList_TipoEvaluacionMateria() {
        return GetEnumListContext(Entidad.CTipoEvaluacion, STRINGSKEY_MATERIATIPOEVALUACION);
    }

    const STRINGSKEY_MATERIAFRECUENCIAEVALUACION = "c_frecuenciaeval";
    export function _GetList_TipoFrecuenciaEval() {
        return GetEnumListContext(Entidad.CMateriaFrecuenciaEvaluacion, STRINGSKEY_MATERIAFRECUENCIAEVALUACION);
    }

    // *************************************************************************
    // Egreso - Tipo Gasto
    // *************************************************************************

    const STRINGSKEY_CALENDARIOTIPOENTIDAD = "c_calendario_tipoentidad";

    export function _GetList_CalTipoEntidad() {
        return GetEnumListContext(Entidad.CCalendarioTipoEntidad, STRINGSKEY_CALENDARIOTIPOENTIDAD);
    }

    export function _GetStr_CalTipoEntidad(key: TEnumKeyParam<typeof Entidad.CCalendarioTipoEntidad>): string {
        return GetEnumStringContext(Entidad.CCalendarioTipoEntidad, STRINGSKEY_CALENDARIOTIPOENTIDAD, key);
    }
}
