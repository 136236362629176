import * as d3 from "d3";
import { group as d3Group } from "d3-array";
import saveAs from "file-saver";
import JSZip from "jszip";
import { DataUtilAlertBot } from "../../data/util/AlertBot";
import { HTMLCheckBoxElement } from "../controlWC/CheckboxComponent";
import { UIUtilLang } from "../util/Language";
import { UIUtilGeneral } from "../util/Util";
import { InputFileControl } from "./InputFileControlV2";
import { NotificacionV2 } from "./NotificacionV2";

export namespace GridGallery {
    type TTypeID = string | number;

    interface IItemPhoto<T> {
        Id: TTypeID;
        Data: T;
        IsSelected: boolean;
        fnDownload: () => Promise<File>;
    }

    interface IConfig<T> {
        ID: keyof T;
        /** El valor devuelt indica a qué bloque será agrupado el item
         * y qué orden llevará el bloque con respecto a los otros */
        OnGrouping: (d: T) => number;
        OnGetBlockTitle: (idBlock: number, items: T[]) => string;
        OnUpdateItem: (ctrlImage: InputFileControl.InputFile, dato: T) => void;
        OnItemGetResource: (d: T) => string;
        OnDownload_GetRealResource: (d: T) => string | Promise<File>;
        OnSelectItems: (d: T[]) => void
    }

    export class Gallery<T> {
        #bloques: Map<number, IItemPhoto<T>[]>;
        #dataorigin: T[];
        #config: IConfig<T>
        #container: TSelectionHTML<"div">;

        constructor(config: IConfig<T>) {
            this.#config = config;
            this.#bloques = new Map();

            this.#container = d3.create("div")
                .classed("gallery_grid", true)
                .classed(UIUtilGeneral.FBoxOrientation.Vertical, true)
                .style("overflow-y", "auto")
                .style("height", "100%")
                .style("row-gap", "15px");
        }

        // ***********************************************************
        // PRIVATE METHODS
        // ***********************************************************

        private UI_UpdateGalleryBlocks() {
            const weekDataGrouped = this.#bloques;
            const areaContent = this.#container;

            this.#container
                .selectAll<HTMLDivElement, number>(".grid_bloque")
                .data(Array.from(weekDataGrouped.keys()))
                .join(
                    enter => {
                        let item = enter.append("div")
                            .classed("grid_bloque", true)
                            .classed(UIUtilGeneral.FBoxOrientation.Vertical, true)
                            .style("row-gap", "10px");

                        item.append("label")
                            .style("font-weight", "bold");

                        item.append("div")
                            // .classed("grid_items " + Utils.FBoxOrientation.Horizontal, true)
                            .classed("grid_items " + UIUtilGeneral.FBoxOrientation.Horizontal, true)
                            .style("flex-wrap", "wrap")
                            .style("gap", "10px");

                        return item
                            .each((idDia, i, arr) => {
                                this.UI_UpdateFotosBloqueContainer(d3.select(arr[i]), idDia, weekDataGrouped.get(idDia));
                            })
                    },
                    update => update
                        .each((idDia, i, arr) => {
                            this.UI_UpdateFotosBloqueContainer(d3.select(arr[i]), idDia, weekDataGrouped.get(idDia));
                        }),
                    exit => exit.remove()
                )

            const lblSinContenido = areaContent.select(".lbl_sincontenido");

            if (weekDataGrouped.size) {
                if (lblSinContenido.node()) {
                    areaContent.classed(UIUtilGeneral.FBoxAlign.CenterCenter, false)
                    lblSinContenido.remove();
                }
            } else {
                if (!lblSinContenido.node()) {
                    areaContent.classed(UIUtilGeneral.FBoxAlign.CenterCenter, true)
                        .append("label")
                        .attr("class", "lbl_sincontenido")
                        .style("font-weight", "bold")
                        .text("Sin contenido");
                }
            }
        }

        private UI_UpdateFotosBloqueContainer(diaContainer: TSelectionHTML<"div">, idBlock: number, eventos: IItemPhoto<T>[]) {
            const tittle = this.#config.OnGetBlockTitle(idBlock, eventos.map(d => d.Data));
            let lblTittle = diaContainer.select(":scope > label");

            if (!lblTittle.node()) {
                lblTittle = diaContainer.append("label");
            } else if (!tittle) {
                lblTittle.remove();
            }
            lblTittle?.text(tittle);

            diaContainer.select(".grid_items")
                .selectAll<HTMLDivElement, IItemPhoto<T>>(".item_grid")
                .data(eventos)
                .join(
                    enter => {
                        let item = enter.append((d) => {
                            // >> Append photo control
                            let ctrlFoto = new InputFileControl.InputFile({
                                ControlStyle: "foto_control_style3",
                                ShowBtnLoadFile: false,
                                ShowBtnDownloadFile: true,
                                ControlForm: InputFileControl.ControlForm.Cuadrado,
                                Dimentions: "100%",
                                MinDimention: "120px"
                            });

                            ctrlFoto._ControlNode["_photo-control"] = ctrlFoto;
                            d3.select(ctrlFoto._ControlNode)
                                .classed("item_grid hov_minzoom", true)
                                .append("wc-checkbox")
                                .attr("style-form", "circle-check")
                                .style("position", "absolute")
                                .style("width", "24px")
                                .style("height", "24px")
                                .style("top", "3px")
                                .style("right", "3px");

                            return ctrlFoto._ControlNode;
                        });

                        return this.UI_UpdateItemFoto(item);
                    },
                    update => this.UI_UpdateItemFoto(update),
                    exit => exit.remove()
                )

            return diaContainer;
        }

        private UI_UpdateItemFoto(itemsContainer: TSelectionHTML<"div", IItemPhoto<T>>): TSelectionHTML<"div", IItemPhoto<T>> {
            return itemsContainer
                .each((d, i, arrItems) => {
                    const itemContainer = arrItems[i]; // d3.select<HTMLDivElement, IAlumno>(arrItems[i]);
                    const ctrlFoto = itemContainer["_photo-control"] as InputFileControl.InputFile;

                    const checkbox = ctrlFoto
                        ._ControlSelection
                        .select<HTMLCheckBoxElement>("wc-checkbox")
                        .node()

                    const fnUpdateCheckedApparience = () => {
                        if (d.IsSelected) {
                            ctrlFoto._ControlSelection
                                // .style("transform", "scale(1.03)")
                                .classed("shadow1", true)
                            // .style("border", "2px solid rgb(38 122 215)")
                        } else {
                            ctrlFoto._ControlSelection
                                // .style("transform", null)
                                .style("box-shadow", null);
                            // .style("border", null);
                        }
                    }

                    checkbox._Checked = d.IsSelected;
                    fnUpdateCheckedApparience();

                    checkbox._OnChange = (e) => {
                        d.IsSelected = checkbox._Checked;
                        fnUpdateCheckedApparience();

                        this.#config.OnSelectItems(this._GetDataSelected());
                    }

                    d.fnDownload = () => ctrlFoto._GetDownloaded();

                    ctrlFoto
                        ._Reset()
                        ._OnDownload_GetRealresource(() => {
                            return this.#config.OnDownload_GetRealResource(d.Data);
                        })
                        ._UrlResource = this.#config.OnItemGetResource(d.Data);
                    ctrlFoto._SpinnerControl.node()._Dim = 80;

                    if (this.#config.OnUpdateItem) {
                        this.#config.OnUpdateItem(ctrlFoto, d.Data);
                    }
                })
        }

        // ***********************************************************
        // PUBLIC PROPERTIES
        // ***********************************************************

        public _Data(): T[] {
            return this.#dataorigin;
        }

        public _DataUpdate(datos: T[]): this {
            this.#dataorigin = datos;
            let lastDataGrouped = new Map(this.#bloques);
            let items = datos.map<IItemPhoto<T>>(d => ({
                Id: d[this.#config.ID] as unknown as TTypeID,
                Data: d,
                IsSelected: false,
                fnDownload: null
            }))

            this.#bloques = d3Group(items, itemN => {
                let idNewBlock = this.#config.OnGrouping(itemN.Data);

                if (lastDataGrouped.has(idNewBlock)) {
                    let beforeItem = lastDataGrouped.get(idNewBlock)
                        .find(d => (d.Id == itemN.Id))

                    if (beforeItem) {
                        itemN.IsSelected = beforeItem.IsSelected;
                    }
                }

                return idNewBlock;
            })
            return this;
        }

        public _SelectItems(id: TTypeID | TTypeID[]) {
            let ids = ((typeof id == "number" || typeof id == "string") ? [id] : id);
            let itemsF: IItemPhoto<T>[] = [];

            this.#bloques.forEach(items => {
                let blockItemsF = items.filter(d => (ids.indexOf(d.Id) > -1))

                if (blockItemsF.length) {
                    itemsF.push(...blockItemsF);
                }
            })

            let selection = {
                Checked: (val: boolean) => {
                    itemsF.forEach(item => {
                        item.IsSelected = val;
                    })
                    this.#config.OnSelectItems(this._GetDataSelected());

                    return selection;
                },
                Download: async (fileName: string) => {
                    const getNameFileFormers = (fileBlob: File) => {
                        let arrFileName = fileBlob.name.split(" - ");
                        return { NameAl: arrFileName[0], Fecha: arrFileName[1].split(".")[0], Extension: arrFileName[1].split(".")[1] }
                    }
                    if (itemsF.length > 1) {
                        const fileNames = new Map<string, number>();
                        const zip = new JSZip();
                        const resumenFolder = zip.folder(fileName);
                        for (let item of itemsF) {
                            if (item.fnDownload) {
                                const fileBlob = await item.fnDownload();
                                if (fileBlob) {
                                    let fileStr = getNameFileFormers(fileBlob);
                                    fileNames.set(fileBlob.name.split(".")[0], fileNames.get(fileBlob.name.split(".")[0]) ? (fileNames.get(fileBlob.name.split(".")[0]) + 1) : 1);
                                    resumenFolder.file(fileStr.Fecha + " (" + fileNames.get(fileBlob.name.split(".")[0]) + ")" + "." + fileStr.Extension, fileBlob);
                                } else {
                                    console.warn("-d", "Image undefined");
                                    NotificacionV2._Mostrar(UIUtilLang._GetUIString("general", "notif_fail"), "ADVERTENCIA"); return selection;
                                }
                            }
                        }
                        zip.generateAsync({ type: "blob" })
                            .then(function (content) {
                                saveAs(content, fileName);
                            })
                            .catch((e) => {
                                DataUtilAlertBot._SendError(e, "Descarga de fotos");
                                NotificacionV2._Mostrar(UIUtilLang._GetUIString("general", "notif_fail"), "ADVERTENCIA")
                            })
                    } else {
                        if (itemsF[0]?.fnDownload) {
                            const fileBlob = await itemsF[0].fnDownload();
                            if (fileBlob) {
                                let fileStr = getNameFileFormers(fileBlob);
                                saveAs(fileBlob, fileStr.NameAl + " - " + fileStr.Fecha + "." + fileStr.Extension);
                            } else {
                                console.warn("-d", "Image undefined");
                                NotificacionV2._Mostrar(UIUtilLang._GetUIString("general", "notif_fail"), "ADVERTENCIA");
                            }
                        }
                    }
                    return selection;
                },
                UIRefresh: () => {
                    this._UIRefresh();

                    return selection;
                }
            }

            return selection;
        }

        public _UIRefresh(): void {
            this.UI_UpdateGalleryBlocks();
        }

        public _GetDataSelected(): T[] {
            let dataSelected: T[] = [];
            this.#bloques.forEach(itemsB => {
                let bloqueItemsSeleccionados = itemsB
                    .filter(d => d.IsSelected)
                    .map(d => d.Data);

                dataSelected.push(...bloqueItemsSeleccionados);
            })

            return dataSelected;
        }

        public _SelectionCsontrol() {
            return this.#container;
        }

        public _NodeControl() {
            return this.#container.node();
        }
    }
}
