import * as d3 from "d3";
import { group as d3Group } from "d3-array";
import { MainPage } from "../../MainPage";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import DataModuloCircular, { _SvNoticiaNueva } from "../../data/modulo/Noticia";
import DataModuloGrupo from "../../data/modulo/Grupo";
import { DateV2 } from "../../util/DateV2";
import { IConfigGridExcelExport, IGridExtraTableConfig, IGridRenderInfo, VentanaGrid } from "../controlD3/AVentanaGrid";
import { ExcelThings } from "../controlD3/ExcelExport";
import { List } from "../controlD3/List";
import { ModalThings } from "../controlD3/ModalThings";
import { NotificacionV2 } from "../controlD3/NotificacionV2";
import { Table } from "../controlD3/Tabla";
import { HTMLButton2Element } from "../controlWC/Button2Component";
import { UIUtilIconResources } from "../util/IconResourses";
import { UIUtilLang } from "../util/Language";
import { UIUtilStrings } from "../util/Strings";
import { UIUtilGeneral } from "../util/Util";
import { UIUtilViewData } from "../util/ViewData";
import { UIUtilViewCircular } from "../utilView/Circular";
import { UIUtilViewGrupos } from "../utilView/Grupos";

import ICircularForm = UIUtilViewCircular.ICircularForm;
import ICircular = Entidad.ICircular;
import IGrupo = Entidad.IGrupo;
import CEstadoAutorizacion = Entidad.CCircularEstadoAutorizacion;

interface ICircularGrid extends Pick<ICircular, "Programado" | "IDKinder" | "Titulo" | "Mensaje" | "ReqAutorizacion" | "StrFecha" | "IdNoticia" | "KinderFiltro" | "NombreKinder"> {
    Id: string;
    Circular: ICircular;
    Icon: string;
    Respuesta: string;
    StrEscolaridad: string;
    StrGrado: string;
    StrGrupos: string;
    Grupos: IGrupo[];
    IdsGrupos: number[];
}

export class UIVentanaCircularesV3 extends VentanaGrid<ICircularGrid> {

    constructor(content: d3.Selection<HTMLDivElement, undefined, HTMLElement, any>, modulo: Entidad.CModulo) {
        super(content, modulo);
    }

    protected GRID_GetMenuTopGrid(): Array<Table.ITableMenuTopDefaultOptionConfig> {
        let opciones: Array<Table.ITableMenuTopDefaultOptionConfig> = [];

        if (this.GridHasPermisoAccionV2(Entidad.CAccionPermiso.Agregar)) {
            opciones.push({
                Label: "Agregar",
                Callback: () => this.OpenModal_FormularioAgregar()
            });
        }
        return opciones;
    }

    protected GRID_GetSelectionDataMenuV2(menuLocation: "row" | "top-selected", dataGridSelected: ICircularGrid[]): Table.ITableMenuDataSelectedOptionConfig<ICircularGrid>[] {
        let opciones: Array<Table.ITableMenuDataSelectedOptionConfig<ICircularGrid>> = [];
        const esProcesado = this.EsCircularProcesada(dataGridSelected[0]);

        if (esProcesado)
            opciones.push({
                Label: "tag_detalle",
                MultiData: false,
                Callback: (datum) => this.OpenModal_VerDetalleDestino(datum[0]),
                GetDetails: () => ({
                    Description: this.VB_GetUIStringModule("tag_detalledescr"),
                })
            })

        if (esProcesado || this.GridHasPermisoAccionV2(Entidad.CAccionPermiso.Editar)) {
            opciones.push({
                Label: (esProcesado && dataGridSelected.length == 1 ? "Ver" : "Editar"),
                MultiData: false,
                Callback: (dato: Array<ICircularGrid>) => this.OpenModal_FormularioEditar(dato[0], esProcesado)
            });
        }

        if (this.GridHasPermisoAccionV2(Entidad.CAccionPermiso.Eliminar, dataGridSelected.map(d => d.IDKinder))) {
            opciones.push({
                Label: "Eliminar",
                Callback: (datos: Array<ICircularGrid>) => this.OpenModal_DeleteData(datos)
                // NOTE Siempre se pueden eliminar?
            })
        }

        return opciones;
    }

    protected GRID_GetFilters(): Array<Table.IParametroFiltro<ICircularGrid>> {
        return [
            { Label: "Título", Field: "Titulo" },
            { LabelLangKey: "d_field_strfecha", Field: "Programado", Type: "date" },
            {
                LabelLangKey: "d_field_reqautorizacion",
                Field: "ReqAutorizacion",
                Type: "select",
                Options: [
                    { Name: UIUtilLang._GetUIString("general", "afirma"), Id: true },
                    { Name: UIUtilLang._GetUIString("general", "niega"), Id: false }
                ]
            },

            {
                Label: "Grupo", Field: "StrGrupos", Type: "text",
                OnGetValueToMatch: (dato: ICircularGrid) => dato.Grupos.map(d => d.Nombre),
            },
            { Label: "Grado", Field: "StrGrado" },
            { Label: "Nivel", Field: "StrEscolaridad" },
        ]
    }

    protected GRID_GetTableConfigBase(): IGridRenderInfo<ICircularGrid> {
        return {
            IdTabla: "Circulares",
            DefaultSort: "Titulo",
            IdData: "Id",
            MinWidth: 1150,
            MenuInRowNoCellsToIgnoreWidth: 2,
            Columns: [
                { Field: "Icon", Label: "", Width: "4%", MinWidth: "50px", IsSortable: false, Align: "center", Icon: UIUtilIconResources.CGeneral.Circular2 },
                { Field: "Titulo", Label: "Título", Width: "23%", MinWidth: "75px" },
                { Field: "Respuesta", Label: "Respuesta", Width: "11%", MinWidth: "100px", OrderTypeParse: String },
                { Field: "StrFecha", Label: "Fecha", Width: "15%", MinWidth: "75px", OrderField: "Programado", OrderTypeParse: Date },
                { Field: "StrGrupos", Label: "Grupo", Width: "16%", MinWidth: "75px" },
                { Field: "StrGrado", Label: "Grado", Width: "15%", MinWidth: "75px" },
                { Field: "StrEscolaridad", Label: "Nivel", Width: "15%", MinWidth: "75px" },
                { Field: "NombreKinder", Label: "Escuela", Width: "15%", MinWidth: "75px" },
            ]
        };
    }
    protected GRID_GetDataRequestID(): DataModuloMain.TipoRequestMonitorId {
        return Entidad.CTipoRequest.Circular;
    }

    protected GRID_GetTableConfigAdvanced(): IGridExtraTableConfig<ICircularGrid> {
        return {
            // NIVEL 1 -> Circulares
            EvaluatorAndSubLevelsBuild: <Table.IStepEvaluator<ICircularGrid, UIUtilViewCircular.ICircularGradoDestino, any>>{
                OnStepCellTable: (container, datum, field: keyof ICircularGrid) => {
                    switch (field) {

                        case "Icon":
                            let { IdNoticia, Titulo, Mensaje } = datum.Circular;
                            UIUtilViewCircular._UIStepContentIcoElement(container, { IdNoticia, Titulo, Mensaje }, this.modulo);
                            break;
                        case "Respuesta":
                            if (!container.html()) {
                                container.html("...");
                            }
                            UIUtilViewCircular._GetAutorizacionRespuestaFormat(datum.Circular, datum.IdsGrupos)
                                .then(str => {
                                    container.html(str);
                                })
                            break;
                        case "StrGrupos":
                            UIUtilGeneral._CreaElementosLinkeablesV2({
                                Container: container,
                                Data: Array.from(datum.Grupos.values()),
                                Path: "escuelas/grupos/panel",
                                GetTag: d => UIUtilViewGrupos._GetLblGrupoName(d, false, true),
                                GetId: "IdGrupo",
                                Direction: "vertical",
                            })
                            break;
                    }
                },
            }
        }
    }

    // *********************************************************************
    // Configuración formularios
    // *********************************************************************

    private async OpenModal_FormularioAgregar() {
        this.GridOpenModal_ActionFormToAGridData<ICircularForm>({
            // IdsEscuelas: [],
            Action: Entidad.CAccionPermiso.Agregar,
            GetForm: () => {
                return UIUtilViewCircular._GetNoticeForm(this.modulo as Entidad.CModulo.Circulares, Entidad.CAccionPermiso.Agregar, "grid").Form;
            },
            OnAccept: (form, modalThings) => {
                const finalData: ICircularForm = form._Data;
                if (finalData.Programado) {
                    finalData.Programado = new DateV2(finalData.Programado)
                        ._SetTimeZoneByIdSchool(finalData.IDKinder, true)
                        ._ToISOString();
                }
                return _SvNoticiaNueva("ByGrupos", {
                    IdEscuela: finalData.IDKinder,
                    Titulo: finalData.Titulo,
                    Mensaje: finalData.Mensaje,
                    Archivo: ((finalData.Archivo as any instanceof File) ? finalData.Archivo : null) as any as File,
                    Programado: finalData.Programado,
                    ReqAutorizacion: finalData.ReqAutorizacion,
                    Niveles: finalData.Niveles,
                    Grupos: finalData.Grupos,
                    Expira: finalData.Expira
                });
            }
        })
    }

    private OpenModal_FormularioEditar(dato: ICircularGrid, esProcesado: boolean) {
        let formD = UIUtilViewCircular._GetNoticeForm(this.modulo as Entidad.CModulo.Circulares, Entidad.CAccionPermiso.Editar, "grid", dato.Circular);

        this.GridOpenModal_ActionFormToAGridData<ICircularForm>({
            Action: (esProcesado) ? Entidad.CAccionPermiso.Ver : Entidad.CAccionPermiso.Editar,
            Title: (esProcesado ? "tag_verprocesada" : undefined),
            IdsEscuelas: [dato.IDKinder],
            GetForm: () => formD.Form,
            DrawContent: (content, form, modalThings) => {
                modalThings.Modal._FooterSelection
                    .classed("hide", !formD.EsEditable)
            },
            OnAccept: (form, modalThings) => {
                const finalData = form._Data;
                if (finalData.Programado) {
                    finalData.Programado = new DateV2(finalData.Programado)
                        ._SetTimeZoneByIdSchool(finalData.IDKinder, true)
                        ._ToISOString();
                }
                return DataModuloCircular._Actualizar(finalData);
            },
        })
    }

    private OpenModal_DeleteData(circularesToDelete: Array<ICircularGrid>) {
        interface CircularConflict {
            circular: ICircular
            gruposConflicto: IGrupo[]
        }

        let circularesConflicto: CircularConflict[] = [];

        d3Group(circularesToDelete, d => d.Circular.IdNoticia)
            .forEach((arr, idNoticia) => {
                let circular = arr[0].Circular;
                let gruposConflicto: IGrupo[] = [];
                let gruposSelect: number[] = [];
                arr.forEach(d => {
                    gruposSelect.push(...d.IdsGrupos)
                });
                console.log(gruposSelect);
                circular.Grupos.forEach(g => {
                    if (gruposSelect.every(gs => gs !== g)) {
                        let grupoConflict = DataModuloGrupo._DiccGrupo.get(g);
                        if (grupoConflict) {
                            gruposConflicto.push(grupoConflict);
                        }
                    }
                })
                if (gruposConflicto.length > 0) {
                    circularesConflicto.push({ circular, gruposConflicto })
                }
            })

        this.GridOpenModal_ProccessArrayData({
            DataToProccess: circularesToDelete,
            OnGetIdEscuela: (circular) => circular.IDKinder,
            OnError_GetItemDataTag: (circular) => circular.Titulo,
            OnStepAProccess: (circular) => DataModuloCircular._Eliminar(circular.IdNoticia),
            OnDrawContent: (container) => {
                if (circularesConflicto.length > 0) {
                    container.classed(UIUtilGeneral.FBoxOrientation.Vertical, true)
                        .style("gap", "var(--padding2)");
                    container.append("label")
                        .text(circularesConflicto.length > 1 ? this.VB_GetUIStringModule("mess_manycirc_afectagrupos") : this.VB_GetUIStringModule("mess_onecirc_afectagrupos"))
                    new List<CircularConflict>()
                        ._SetParent(container)
                        ._SetUpdateItem((container, circularConflict) => {
                            container.text(circularConflict.circular.Titulo)
                            container.append("div")
                                .selectAll(":scope > li")
                                .data(circularConflict.gruposConflicto)
                                .join("li")
                                .text(d => d.Nombre)
                        })
                        ._SetItems(circularesConflicto)
                        ._ListContainerSelection
                        .style("border-top", "1px solid var(--color_borderbox1)")
                }
            }
        })
    }

    private async OpenModal_VerDetalleDestino(dato: ICircularGrid) {
        const width = dato.ReqAutorizacion ? 800 : 650;
        type TD = {
            IdAlumno: number;
            NombreAlumno: string;
            Tutor: string;
            Leido: string;
            Estado: number;
            IdGrupo: number;
            TutorAutorizacion: string;
            Autorizacion: string;
        };
        ModalThings._GetModalSimple({
            // LangModuleKeyInContext: this.moduloName,
            Title: this.VB_GetUIStringModule("tag_detalle") + ": " + dato.Titulo,
            Width: width,
            DrawContent: async (content, modalThings) => {
                modalThings.CurrentBody.classed(UIUtilGeneral.FBoxOrientation.Vertical, true)
                    .style("row-gap", "var(--padding2)")
                    .style("overflow", "hidden");
                modalThings.Modal._FooterSelection.remove();
                modalThings.Modal._ModalSelection
                    .style("height", "350px");

                // Title & Message
                let gruposCont = modalThings.CurrentBody.append("label")
                    .html(
                        `<b>${this.VB_GetUIStringModule("d_field_strgrupos")}:</b></br>`
                        + `<span></span>`
                    )
                    .select<HTMLSpanElement>("span")

                UIUtilGeneral._CreaElementosLinkeablesV2({
                    Container: gruposCont,
                    Data: dato.Grupos,
                    Path: "escuelas/grupos/panel",
                    GetTag: d => UIUtilViewGrupos._GetLblGrupoName(d, false, true),
                    GetId: "IdGrupo",
                    OnClickItem: () => modalThings.Modal._Ocultar(),
                })

                dato.Grupos
                    .sort((a, b) => (Number(b.EsPrincipal) - Number(a.EsPrincipal)))
                    .map(grupo => UIUtilViewGrupos._GetLblGrupoName(grupo, false, true))
                    .join(", ")


                if (dato.Mensaje.trim()) {
                    modalThings.CurrentBody.append("div")
                        .html(
                            `<b>${this.VB_GetUIStringModule("d_field_mensaje")}:</b></br>`
                            + `<div>${UIUtilStrings._AutoLink(dato.Mensaje).innerHTML}</div>`
                        )
                        .select("div")
                        .style("max-height", "60px")
                        .style("overflow", "auto")
                        .style("line-break", "anywhere")
                }

                let destContainer = modalThings.CurrentBody.append("div")
                    .style("display", "flex")
                    .style("justify-content", "space-between");
                destContainer.append("b")
                    .text(this.VB_GetUIStringModule("tag_destinatarios") + ":");
                const btnSync = destContainer.append<HTMLButton2Element>("wc-button")
                    .attr("src-ico", UIUtilIconResources.CGeneral.Sync)
                    .attr("dim", "20")
                    .style("margin-right", "var(--padding3)")
                    .on("click", () => UpdateTable());

                // Table
                let table: Table.Tabla<TD>;
                const UpdateTable = async () => {
                    btnSync.classed("anim_syncing", true);
                    modalThings.Progress.attr("oculto", false);
                    const res = await DataModuloCircular._ObtenerDetalle(dato.Circular.IdNoticia, dato.IdsGrupos);
                    modalThings.Progress.attr("oculto", true);
                    btnSync.classed("anim_syncing", false);

                    if (res.Resultado < 0 || !res.Datos.length) {
                        if (table) {
                            if (res.Resultado < 0)
                                NotificacionV2._Mostrar(UIUtilLang._GetUIString("general", "notif_fail_infoupdate"), "ADVERTENCIA");
                            else
                                NotificacionV2._Mostrar("Error", "ADVERTENCIA");
                        }
                        else {
                            let lblRes = modalThings.CurrentBody.select<HTMLLabelElement>(":scope > .error_message");
                            if (!lblRes.node())
                                lblRes = modalThings.CurrentBody.append("label")
                                    .classed("error_message " + UIUtilGeneral.FBoxOrientation.Horizontal + " " + UIUtilGeneral.FBoxAlign.CenterCenter, true)
                                    .style("margin", "0")
                                    .style("height", "100%");

                            if (res.Resultado > 0)
                                lblRes.text(this.VB_GetUIStringModule("tag_sindetalleenvio"));
                            else
                                lblRes.text(UIUtilLang._GetUIString("general", "notif_fail_infoupdate"));
                        }
                        return
                    }
                    else
                        modalThings.CurrentBody.select(":scope > .error_message").remove();

                    modalThings.Modal._ModalSelection
                        .style("transition", "height .3s")
                        .style("height", res.Datos.length < 7 ? "600px" : "800px");

                    let alumnosRepetidos = {};
                    let tblData = Array.from(res.Datos.values())
                        .filter(d => {
                            // NOTE: Los estados son los mismos para todos los grupos donde coincida el alumno
                            if (!alumnosRepetidos[d.IdAlumno] && DataModuloMain._GetItemDataByName("Alumno", d.IdAlumno)) {
                                alumnosRepetidos[d.IdAlumno] = true;
                                return true;
                            }
                            return false;
                        })
                        .map<TD>(d => ({
                            IdAlumno: d.IdAlumno,
                            NombreAlumno: DataModuloMain._GetDataValueFieldByName("Alumno", d.IdAlumno, "NombreCompleto"), // d.Alumno.NombreCompleto,
                            Tutor: (d.Tutores || [])
                                .map(idTutor => DataModuloMain._GetDataValueFieldByName("Tutor", idTutor, "NombreCompleto"))
                                .filter(d => Boolean(d))
                                .join(", "),
                            Leido: UIUtilLang._GetUIString("general", d.Estado == 2 ? "afirma" : "niega"),
                            Estado: d.Estado,
                            IdGrupo: d.IdGrupo,
                            TutorAutorizacion: DataModuloMain._GetDataValueFieldByName("Tutor", d.IdTutorAutorizacion, "NombreCompleto"),
                            Autorizacion: (
                                dato.ReqAutorizacion
                                    ? UIUtilViewData._GetStr_EdoAutorizacion(
                                        d.IdTutorAutorizacion
                                            ? (d.EsAutorizado ? CEstadoAutorizacion.Aprobado : CEstadoAutorizacion.Rechazado)
                                            : CEstadoAutorizacion.Pendiente
                                    )
                                    : ""
                            )
                        }))
                        .sort((a, b) => (b.Estado - a.Estado));
                    alumnosRepetidos = null;

                    if (!table) CrearTable();
                    table._UpdateData(tblData);
                }
                const CrearTable = () => {
                    table = new Table.Tabla<TD>({
                        IdTabla: "Circulares-Detalle",
                        Parent: modalThings.CurrentBody,
                        MinWidth: width - 20,
                        HideCheckboxes: true,
                        RenderColumnHeadings: [
                            { Field: "NombreAlumno", Label: this.VB_GetUIStringModule("tag_alumno"), MinWidth: "60px", Width: "30%" },
                            { Field: "Tutor", Label: this.VB_GetUIStringModule("tag_tutor"), MinWidth: "60px", Width: "30%" },
                            { Field: "Leido", Label: this.VB_GetUIStringModule("tag_leido"), MinWidth: "60px", Width: "10%" },
                            ...(
                                dato.ReqAutorizacion
                                    ? <Table.IConfig<TD>["RenderColumnHeadings"]>[
                                        { Field: "Autorizacion", Label: this.VB_GetUIStringModule("tag_autorizacion"), MinWidth: "60px", Width: "15%" },
                                        { Field: "TutorAutorizacion", Label: this.VB_GetUIStringModule("tag_tutorautoriza"), MinWidth: "60px", Width: "20%" },
                                    ]
                                    : []
                            )
                        ],
                        EvaluatorAndSubLevelsBuild: {
                            OnStepRowTable: (dato, tr) => tr.on("click", () => console.debug(dato)),
                            OnStepCellTable: (container, dato, field) => {
                                switch (field) {
                                    case "NombreAlumno":
                                        UIUtilGeneral._ElementAdd_LinkToGoToPanel(container, "alumnos/alumnos/panel", dato.IdAlumno, () => {
                                            modalThings.Modal._Ocultar();
                                        });
                                        break;
                                }
                            },
                        }
                    })
                }
                UpdateTable();
            }
        })
    }

    protected GRID_GetExportarConfig(dataGrid: ICircularGrid[]): IConfigGridExcelExport<any> {
        type IDataToExport = Partial<ICircularGrid>;

        let idsEscuelas = [...new Set(dataGrid.map(d => d.IDKinder))];

        let tblColumns: ExcelThings.IColumnToExcelExportFileConfig<IDataToExport>[] = this.ctrlTabla._InfoColumns
            .filter(d => (d.Field != "Icon"))
            .map(d => ({
                Field: d.Field,
                HeaderTag: d.Label,
                WidthCell: 30,
            }));

        tblColumns.splice(1, 0, {
            Field: "Mensaje",
            HeaderTag: UIUtilLang._GetUIString("circulares", "d_field_mensaje"),
            WidthCell: 40,
        })

        return <IConfigGridExcelExport<IDataToExport>>{
            IdsEscuelas: idsEscuelas,
            ColumnsConfig: tblColumns,
            OnGetDataBySheets: async () => {
                let groupedData: Map<number, IDataToExport[]> = new Map(idsEscuelas.map(idEscuela => ([idEscuela, []])));

                for (let item of dataGrid) {
                    let datosEnEscuela = groupedData.get(item.IDKinder);
                    let respuestaStr = await UIUtilViewCircular._GetAutorizacionRespuestaFormat(item.Circular, item.IdsGrupos, true);

                    datosEnEscuela.push({
                        Titulo: item.Titulo,
                        Mensaje: item.Mensaje,
                        Respuesta: respuestaStr,
                        StrFecha: item.StrFecha,
                        NombreKinder: item.NombreKinder,
                        StrEscolaridad: item.StrEscolaridad,
                        StrGrado: item.StrGrado,
                        StrGrupos: item.StrGrupos,
                    })
                }

                return Array.from(groupedData)
                    .map(entrie => ({
                        IdSheet: entrie[0], // IdEscuela
                        SheetName: entrie[1][0].NombreKinder,
                        Data: entrie[1]
                    }));
            },
            OnGetEscuelasTagInSheet: (datos) => datos[0].NombreKinder
        }
    }

    protected async GridGetData(): Promise<ICircularGrid[]> {
        let dataResult: ICircularGrid[] = [];
        await super.GridGetData()
            .then(dataBase => {
                (dataBase as any as ICircular[]).forEach((d, i) => {
                    let grupos = d.Grupos
                        .map(idGrupo => DataModuloMain._GetItemDataByName("Grupo", idGrupo))
                        .filter(d => Boolean(d))

                    d3Group(grupos, d => d.IdNivel)
                        .forEach((grupos, idGrado) => {
                            const idG = grupos.map(d => d.IdGrupo);
                            dataResult.push({
                                ...d,
                                ...{
                                    Id: d.IdNoticia + "_" + idG,
                                    Circular: d,
                                    Icon: undefined,
                                    Respuesta: null,
                                    StrEscolaridad: DataModuloMain._GetDataValueFieldByName("Escolaridad", grupos[0].IdEscolaridad, "Nombre"),
                                    StrGrado: DataModuloMain._GetDataValueFieldByName("Grado", idGrado, "Nombre"),
                                    StrGrupos: grupos.map(d => d.Nombre).join(",\n"),
                                    IdsGrupos: idG,
                                    Grupos: grupos,
                                }
                            })
                        })
                });
            })

        return dataResult;
    }

    private EsCircularProcesada(circular: ICircularGrid) {
        let timeZone = DataModuloMain._GetDataValueFieldByName("Escuela", circular.IDKinder, "ZonaHoraria");
        let dtProgramado = new DateV2(circular.Programado)
            ._SetTimeZone(timeZone);
        let dtCurrent = new DateV2()
            ._SetTimeZone(timeZone);
        return (dtProgramado < dtCurrent);
    }

    // *********************************************************************
    // COSAS DE SERVICIOS
    // *********************************************************************

    // *********************************************************************
    // Public methods
    // *********************************************************************

    public _Mostrar(): void {
        super._Mostrar();
        MainPage._ActivarServiceMonitor(Entidad.CTipoRequest.Circular, true);
    }

    public _Destroy(): void {
        super._Destroy();
        MainPage._ActivarServiceMonitor(Entidad.CTipoRequest.Circular, false);
    }
}
