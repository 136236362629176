import { UIUtilGeneral } from "../util/Util";

type IProperties = {
    Height: string;
    Position: "top" | "bottom";
};
const BASEPROPERTIES: IProperties = {
    Height: "3px",
    Position: "top",
}
const TEMPLATE = document.createElement("template");
TEMPLATE.innerHTML = `
<style id="base_styles">
    :host {
        --progressheight;
        --progresstop;
        --progressbottom;
    }
</style>
<style>
    :host {
        width: 100%;
        height: 100%;
        display: inline-block;
        position: absolute;
        background: rgba(255, 255, 255, 0.40);
        overflow: hidden;
        z-index: 2;
        top: 0;
        left: 0;
        box-sizing: border-box;
    }
    :host([nocover]) {
        height: var(--progressheight);
    }
    :host([nocover="false"]), :host([nocover="off"]){
        height: 100%;
    }
    :host([oculto]) {
        display: none;
    }
    :host([oculto="false"]), :host([oculto="off"]){
        display: inline-block;
    }
    :host::after {
        content: '';
        width: 100%;
        height: var(--progressheight);
        background: var(--color_action1);
        position: absolute;
        border-radius: 25%;
        top: var(--progresstop);
        bottom: var(--progressbottom);
        left: 0;
        box-sizing: border-box;
        animation: animloader 0.65s linear infinite;
    }
    @keyframes animloader {
        0% {
        left: 0;
        transform: translateX(-100%);
        }
        100% {
        left: 100%;
        transform: translateX(0%);
        }
    }
</style>
`

/** El control cubre toda la superficie de su elemento padre
 *
 * Atributos
 * * `nocover` El control no cubre toda la superficie de su elemento padre
 * `(!this.hasAttribute("nocover") || attrValue == "false" || attrValue == "off" ? false : true)`
 *
 * * `oculto` Se oculta
 *  `(!this.hasAttribute("oculto") || attrValue == "false" || attrValue == "off" ? false : true)`
 *
 * * `position`: "top" or "bottom"
 *
 * * `progress-height`: number or (string + "px")
 */
export class HTMLProgressElement extends HTMLElement {
    #baseStyles: HTMLStyleElement;
    #properties: IProperties;

    constructor() {
        super();
        this.attachShadow({ mode: 'open' });
        this.#properties = { ...BASEPROPERTIES };
        this.shadowRoot.appendChild(TEMPLATE.content.cloneNode(true));
        this.#baseStyles = this.shadowRoot.querySelector("#base_styles");
        this.SetStylesBase();
    }

    private SetStylesBase(props?: Partial<IProperties>) {
        if (props) for (let k in props) {
            this.#properties[k] = props[k] || BASEPROPERTIES[k];
        }
        this.#baseStyles.textContent =
            `:host {
                    --progressheight:${this.#properties.Height};
                    --progresstop:${this.#properties.Position == "top" ? "0" : null};
                    --progressbottom:${this.#properties.Position == "bottom" ? "0" : null};
                }`
    }

    /** Invocado cuando uno de los atributos del componente personalizado es añadido, removido o modificado. */
    attributeChangedCallback(attrName: string, oldValue: string, newValue: string) {
        switch (attrName) {
            case "position":
                this.SetStylesBase({
                    Position: (newValue == "top" || newValue == "bottom") ? newValue : "top",
                });
                break;
            case "progress-height":
                this.SetStylesBase({
                    Height: UIUtilGeneral._FixValueAsPxValue(newValue),
                })
                break;
        }
    }

    connectedCallback() {
    }

    static get observedAttributes() {
        return ['position', "progress-height"];
    }

    public set _NoCover(value: boolean) {
        if (value && !this.hasAttribute("nocover")) {
            this.setAttribute("nocover", "");
        }
        else if (!value && this.hasAttribute("nocover")) {
            this.removeAttribute("nocover");
        }
    }

    public get _NoCover(): boolean {
        return this.hasAttribute("nocover") && this.getAttribute("nocover") != "false" && this.getAttribute("nocover") != "off";
    }

    public set _Oculto(value: boolean) {
        if (value && !this.hasAttribute("oculto")) {
            this.setAttribute("oculto", "");
        }
        else if (!value && this.hasAttribute("oculto")) {
            this.removeAttribute("oculto");
        }
    }

    public get _Oculto(): boolean {
        return this.hasAttribute("oculto") && this.getAttribute("oculto") != "false" && this.getAttribute("oculto") != "off";
    }

    public set _Visible(value: boolean) {
        this._Oculto = !value;
    }

    public get _Visible(): boolean {
        return !this._Oculto;
    }

    public set _ProgressHeight(value: number | string) {
        this.setAttribute("progress-height", value + "");
    }

    public set _Position(value: "top" | "bottom") {
        this.setAttribute("position", value);
    }
}

customElements.define("wc-progress", HTMLProgressElement);
