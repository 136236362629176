import * as IMask from 'imask';
export namespace UIUtilMask {
    export type HTMLInputElementMask = HTMLInputElement & {
        __imask?: IMask.InputMask<any>;
    }
    type TInput = TSelectionHTML<"input"> | HTMLInputElementMask;

    function GetInputNode(element: TInput): HTMLInputElementMask {
        if (element instanceof HTMLInputElement) {
            element.type = "text";
            return element;
        }
        element.node().type = "text";
        return element.node();
    }

    export function _RemoveMask(element: TInput) {
        let input = GetInputNode(element);
        if (input.__imask) {
            input.__imask.destroy();
            input.__imask = undefined;
        }
    }

    /** @mask `0000000000` */
    export function _ApplyPhoneAMask(element: TInput, nDigits = []) {
        let input = GetInputNode(element);
        _RemoveMask(input);
        if (!nDigits || !nDigits.length) {
            input.pattern = "[0-9]+";
            input.removeAttribute("maxlength");
            input.removeAttribute("size");
            input.placeholder = "0".repeat(10);
        } else {
            let maxDijit = nDigits.sort((a, b) => (b - a))[0];
            let pattern = "[0-9]"
            nDigits.forEach(d => { pattern += `|[0-9]{${d}}` })
            input.pattern = pattern // regex.source; // FIXME // TEMPORAL Cuando Telefono no es requerido
            // input.pattern = regex.source
            input.maxLength = maxDijit;
            input.size = maxDijit;
            input.placeholder = "0".repeat(maxDijit);
        }
        if (input.type != "tel") input.type = "tel";
        // input[MASKPROPNAME] = new IMask.InputMask(input, {
        //     mask: "0000000000",
        //     lazy: false,
        //     validate: (value, masked) => {
        //         console.debug("mask", value, masked);
        //         if (value == "h") return false;
        //         return true;
        //     }
        // })
    }

    /** @mask `00-00-00-00-00` */
    export function _ApplyPhone10BMask(element: TInput) {
        let input = GetInputNode(element);
        _RemoveMask(input);
        input.__imask = new IMask.InputMask<IMask.FactoryOpts>(input, {
            mask: "00-00-00-00-00",
        })
    }

    export function _ApplyNoWhiteSpacesMask(element: TInput) {
        let input = GetInputNode(element);
        _RemoveMask(input);
        // IMask.createMask({
        //     mask: String,
        //     prepare: 
        // })
        input.__imask = new IMask.InputMask<IMask.FactoryOpts>(input, {
            mask: String as unknown as string,
            prepare: (val: string) => {
                if (val == " ") return "";
                return val;
            },
        })
    }

    /**
     *
     * @param element
     * @param whiteSpaces @default true
     * @param includeNumbers @default true
     * @param includeSpecialCharacters @default true
     */
    export function _ApplyUpperCaseMask(element: TInput, whiteSpaces = true, includeNumbers = true, includeSpecialCharacters = true) {
        const regexNumber = /^\d+$/;
        const regexABC = /^[A-Z]+$/i;
        let input = GetInputNode(element);
        _RemoveMask(input);
        input.__imask = new IMask.InputMask<IMask.FactoryOpts>(input, {
            mask: String as unknown as string,
            prepare: (val: string) => {
                val = val.toUpperCase();
                const vWhiteSpaceFail = (!whiteSpaces && val == " ");
                const vNumberFail = (!includeNumbers && regexNumber.test(val));
                const vSpecialChar = (!includeSpecialCharacters && !regexABC.test(val))
                    && !(whiteSpaces && val == " ")
                    && !(includeNumbers && regexNumber.test(val))

                if (vWhiteSpaceFail || vNumberFail || vSpecialChar) {
                    val = "";
                }
                return val;
            }
        })
    }

    export function _ApplyEmailMask(element: TInput) {
        let input = GetInputNode(element);
        _RemoveMask(input);
        // input.type = 'email';
        // input.pattern = "[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"; // NOT WORKING // FIXME Se agregó la validación en Formulario
        input.__imask = new IMask.InputMask<IMask.FactoryOpts>(input, {
            mask: String as unknown as string,
            // pattern: "/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i",
            prepare: (val: string) => val
                .toLocaleLowerCase()
                .replace(" ", ""),
            // validate: (val: string) => !val.includes(" ")
        });
    }

    export function _ApplyBankCardMask(element: TInput) {
        let input = GetInputNode(element);
        _RemoveMask(input);
        input.__imask = new IMask.InputMask<IMask.FactoryOpts>(input, {
            mask: "0000 0000 0000 0000",
            lazy: false,
        });
    }

    export function _ApplyPercentMask(element: TInput) {
        let input = GetInputNode(element);
        _RemoveMask(input);
        input.__imask = new IMask.InputMask<IMask.FactoryOpts>(input, {
            mask: "num%",
            lazy: false,
            blocks: {
                num: {
                    mask: Number,
                    scale: 2,
                    radix: '.',
                    mapToRadix: ["."],
                    max: 100,
                    min: 0
                }
            },
        })
    }

    export function _ApplyCurrencyMask(element: TInput) {
        let input = GetInputNode(element);
        _RemoveMask(input);
        input.__imask = new IMask.InputMask<IMask.FactoryOpts>(input, {
            mask: '$num',
            lazy: false,
            blocks: {
                num: {
                    mask: Number,
                    scale: 2,
                    thousandsSeparator: ',',
                    padFractionalZeros: true,
                    radix: '.',
                    mapToRadix: ['.'],
                }
            }
        });
    }

    export function _ApplyDialCodeMask(element: TInput) {
        let input = GetInputNode(element);
        _RemoveMask(input);
        input.__imask = new IMask.InputMask<IMask.FactoryOpts>(input, {
            mask: '+dialcode',
            lazy: false,
            blocks: {
                dialcode: {
                    mask: /^\d+$/,
                    expose: true
                }
            }
        })
    }

    export function _ApplyDateMask_DD_MM(element: TInput) {
        let input = GetInputNode(element);
        _RemoveMask(input);
        input.__imask = new IMask.InputMask<IMask.FactoryOpts>(input, {
            mask: 'd{/}m{/}YYYY',
            blocks: {
                d: {
                    mask: IMask.MaskedRange,
                    from: 1,
                    to: 31,
                    maxLength: 2,
                },
                m: {
                    mask: IMask.MaskedRange,
                    from: 1,
                    to: 12,
                    maxLength: 2,
                },
            },
            autofix: true,
            lazy: false,
            overwrite: false,
            placeholderChar: '_'
        })
    }

    export function _ApplyDateMask_DD_MM_YYYY(element: TInput) {
        let input = GetInputNode(element);
        _RemoveMask(input);
        input.__imask = new IMask.InputMask<IMask.FactoryOpts>(input, {
            mask: 'd{/}m{/}y',
            blocks: {
                d: {
                    mask: IMask.MaskedRange,
                    from: 1,
                    to: 31,
                    maxLength: 2,
                },
                m: {
                    mask: IMask.MaskedRange,
                    from: 1,
                    to: 12,
                    maxLength: 2,
                },
                y: {
                    mask: IMask.MaskedRange,
                    from: 1970,
                    to: 3000,
                    maxLength: 4,
                },
            },
            autofix: true,
            lazy: false,
            overwrite: false,
            placeholderChar: '_'
        })
    }

    // NOTES
    // "Cadenas Compuestas"
    // ^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$
    // let regex = /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/g;
    // * \u00f1 y \u00d1 son el equivalente para "ñ" y "Ñ", respectivamente en ejemplo lo explicado
    // * admite tildes y otros, como diéresis
}
