import * as d3 from "d3";
import { HTMLIconCollapseElement } from "../controlWC/IconTogglerCollapseComponent";

interface IConfig {
    OnCreateTemplate?: (id: any, item: TSelectionHTML<"div">) => void;
    OnSelectItem?: (id: any, item: TSelectionHTML<"div">) => void;
    OnCollapseItem?: (id: any, item: TSelectionHTML<"div">) => void;
}
interface IExpanderItemListItem {
    Id: any;
    Label: string;
    ContentSelection: TSelectionHTML<"htmlelement">;
    /** ```
     * <div class="item">
     *      <wc-ic-collapse></wc-ic-collapse>
     *      <label>Titulo</label>
     * </div>
    ``` */
    CollapserItemSelection: TSelectionHTML<"div">;
    OnDestroy?: () => void;
}
export type IExpanderItemListItemConfig = Pick<IExpanderItemListItem, "Id" | "Label" | "ContentSelection" | "OnDestroy">;
const TRANSITIONDURATION = 250;
export class ExpanderItemList {
    protected items: IExpanderItemListItem[];
    protected currentFocus: IExpanderItemListItem;
    /** `.expander_list` */
    protected controlContainerSelection: TSelectionHTML<"div">;
    /** `.expander_list > .content` */
    protected contentContainerSelection: TSelectionHTML<"div">;
    private fnOnOnCreateTemplate: IConfig["OnCreateTemplate"];
    private fnOnExpandedItemFocus: IConfig["OnSelectItem"];
    private fnOnCollapseItemFocus: IConfig["OnCollapseItem"];

    constructor(config: IConfig = {}) {
        this.items = [];
        this.fnOnOnCreateTemplate = config?.OnCreateTemplate;
        this.fnOnExpandedItemFocus = config?.OnSelectItem;
        this.fnOnCollapseItemFocus = config?.OnCollapseItem;
        this.InitExpanderItemList();
    }

    private InitExpanderItemList() {
        this.controlContainerSelection = d3.create("div")
            .attr("class", "expander_list");

        this.contentContainerSelection = d3.create("div")
            .classed("content", true);
    }

    private CreateItemList(item: IExpanderItemListItem) {
        const itemSelection = d3.create("div")
            .attr("class", "item")
            // .style("transition", TRANSITIONDURATION + "ms all")
            .on("click", () => {
                const collapsedToggled = !btnCollapse.node()._Collapsed;
                if (collapsedToggled) {
                    this.RemoveCurrentFocus();
                }
                else {
                    this.SetCurrentFocus(item);
                }
            });
        const btnCollapse = itemSelection
            .append<HTMLIconCollapseElement>("wc-ic-collapse");

        itemSelection
            .append("label")
            .text(item.Label);

        if (this.fnOnOnCreateTemplate) {
            this.fnOnOnCreateTemplate(item.Id, itemSelection);
        }
        item.CollapserItemSelection = itemSelection;
    }

    public _getItemList = (itemId: any): IExpanderItemListItem => {
        return this.items.find(d => d.Id == itemId);
    }

    private SetCurrentFocus(itemFucus: IExpanderItemListItem) {
        // console.warn("Set current focus", itemFucus);
        this.currentFocus = itemFucus;
        if (this.fnOnExpandedItemFocus) {
            this.fnOnExpandedItemFocus(itemFucus.Id, itemFucus.CollapserItemSelection);
        }
        this.items
            .forEach((d, i, elements) => {
                if (d.Id !== itemFucus.Id) {
                    // Animación de salida de los items no enfocados
                    this.AnimationRemoveItemList(d, 0);
                }
                else {
                    // Vista del elemento enfocado
                    d.CollapserItemSelection
                        .select<HTMLIconCollapseElement>("wc-ic-collapse")
                        .node()
                        ._Collapsed = false;
                }
            })

        // Muestra contenido de item seleccionado
        setTimeout(() => {
            if (this.currentFocus) {
                this.controlContainerSelection
                    .append(() => this.contentContainerSelection.node())
                    .append(() => this.currentFocus.ContentSelection.node())

                if (this.contentContainerSelection.style("height") !== "calc(100% - 40px)") {
                    this.contentContainerSelection
                        .style("height", "0%")
                        .transition()
                        .duration(TRANSITIONDURATION)
                        .style("height", "calc(100% - 40px)");
                }
            }
        }, TRANSITIONDURATION);
    }

    private RemoveCurrentFocus(remove = true) {
        if (this.currentFocus) {
            // Remueve el contenido
            const auxItem = this.currentFocus;
            this.currentFocus = null;
            if (remove) {
                this.contentContainerSelection.remove().style("height", "0%");
            }
            auxItem.ContentSelection.remove();
            if (this.fnOnCollapseItemFocus) {
                this.fnOnCollapseItemFocus(auxItem.Id, auxItem.CollapserItemSelection);
            }
            // Regresa a la lista de items (Reset)
            this.items
                .forEach((d) => {
                    this.controlContainerSelection.append(() => d.CollapserItemSelection.node());

                    if (d.Id == auxItem.Id) {
                        // Reseta vista del elemento enfocado
                        d.CollapserItemSelection
                            .select<HTMLIconCollapseElement>("wc-ic-collapse")
                            .node()
                            ._Collapsed = true;
                    }
                    else {
                        // Animación de entrada a los items no visibles
                        this.AnimationInsertItemList(d);
                    }
                });
        }
    }

    private AnimationInsertItemList(item: IExpanderItemListItem, duration = TRANSITIONDURATION) {
        item.CollapserItemSelection
            .style("opacity", "0")
            .transition()
            .duration(duration)
            .style("opacity", "1")
            .on("interrupt", (d, i,) => {
                item.CollapserItemSelection
                    .style("opacity", null);
            })
            .transition()
            .duration(10)
            .style("max-height", null)
            .on("interrupt", (d, i, trArray) => {
                item.CollapserItemSelection
                    .style("opacity", null);
            });
    }
    private AnimationRemoveItemList(item: IExpanderItemListItem, duration = TRANSITIONDURATION) {
        item.CollapserItemSelection
            .style("opacity", "1")
            .transition()
            .duration(duration)
            .style("opacity", "0")
            .remove()
            .end()
            .then(() => {
                item.CollapserItemSelection
                    .style("opacity", null);
            })
    }

    public _SetItems(items: IExpanderItemListItemConfig[], useAnimation = true) {
        const animationDuration = useAnimation ? TRANSITIONDURATION : 0;
        // Remueve elementos sobrantes
        this.items.forEach(d => {
            const itemExisted = items.find(d2 => (d2.Id == d.Id));
            if (!itemExisted) {
                this.AnimationRemoveItemList(d, animationDuration);
            }
        });
        // Contruye/retona elementos
        this.items = items
            .map<IExpanderItemListItem>(d => {
                const newItem = (<IExpanderItemListItem>{ ...d });
                const itemExisted = this.items.find(d2 => (d.Id == d2.Id));
                if (itemExisted) {
                    newItem.CollapserItemSelection = itemExisted.CollapserItemSelection;
                    newItem.CollapserItemSelection
                        .select(":scope > label")
                        .text(newItem.Label);
                }
                else {
                    this.CreateItemList(newItem);
                }
                return newItem;
            });
        // Comprueba que aún exista el elemento enfocado
        if (this.currentFocus) {
            const bolItemExist = Boolean(this.items.find(d => (d.Id == this.currentFocus.Id)));
            if (bolItemExist) {
                return this; // NOTE No se comprueba que el nodo contenido sea el mismo
            }
            this.RemoveCurrentFocus();
        }
        // List view
        this.items
            .forEach((d) => {
                if (d.CollapserItemSelection.node().parentElement) {
                    d.CollapserItemSelection.raise();
                }
                else {
                    this.controlContainerSelection.append(() => d.CollapserItemSelection.node());
                    this.AnimationInsertItemList(d, animationDuration);
                }
            });
        return this;
    }

    public _SetIDSelected(id: any) {
        const item = this.items.find((d) => (d.Id == id));
        if (item) {
            this.RemoveCurrentFocus(this.currentFocus != null);
            this.SetCurrentFocus(item);
        }
        return this;
    }

    public _RemoveCurrentItemFocus() {
        this.RemoveCurrentFocus();
        return this;
    }

    public _HasIDItem(id: any) {
        const item = this.items.find((d) => (d.Id == id));
        return Boolean(item);
    }

    public get _IdSelected() {
        return this.currentFocus?.Id || null;
    }

    public get _ControlContainerSelection() {
        return this.controlContainerSelection;
    }

    public get _ControlContainerNode() {
        return this.controlContainerSelection.node();
    }
}

// **************************************************************************
// HEREDADOS
// **************************************************************************

type IConfig2 = Pick<IConfig, "OnCreateTemplate" | "OnSelectItem" | "OnCollapseItem">;

export class ExpanderItemListCardStyle extends ExpanderItemList {
    constructor(config: IConfig2 = {}) {
        super({
            OnCreateTemplate: (id, item) => {
                item.classed("card_style", true)
                    .style("padding", "0 var(--padding2)");
                item
                    .select(":scope > wc-ic-collapse")
                    .style("margin", "var(--padding2) 0")
                item
                    .select(":scope > label")
                    .style("margin", "var(--padding2) 0");

                if (config.OnCreateTemplate) {
                    config.OnCreateTemplate(id, item);
                }
            },
            OnSelectItem: (id, item) => {
                this.controlContainerSelection
                    .style("row-gap", "0px")
                    .style("height", "100%");

                if (config.OnSelectItem) {
                    config.OnSelectItem(id, item);
                }
            },
            OnCollapseItem: (id, item) => {
                this.controlContainerSelection
                    .style("row-gap", "var(--padding2)")
                    .style("height", null);

                if (config.OnCollapseItem) {
                    config.OnCollapseItem(id, item);
                }
            },
        });
        this.controlContainerSelection
            .style("width", "100%")
        // .style("height", "100%")
        // .style("padding", "2px");
    }
}

// export class ExpanderItemListChangeHeaderStyle extends ExpanderItemList {
//     constructor(config: IConfig2 = {}) {
//         super({
//             OnCreateTemplate: (item) => {
//                 item
//                     .classed("card_style", true)
//                     .style("padding", "0 10px")
//                     .select(":scope > wc-ic-collapse")
//                     .style("margin", "10px 0")
//                 // .classed("btn_round", false);
//                 item
//                     .select(":scope > label")
//                     .style("margin", "10px 0");
//             },
//             OnSelectItem: (id, item) => {
//                 this.controlContainerSelection
//                     .style("height", "100%");

//                 item
//                     .classed("card_style", false)
//                     .select(":scope > wc-ic-collapse")
//                     .classed("btn_round", true);

//                 if (config.OnSelectItem) {
//                     config.OnSelectItem(id, item);
//                 }
//             },
//             OnCollapseItem: (id, item) => {
//                 this.items.forEach(d => {
//                     d.CollapserItemSelection.classed("card_style", false);
//                 })

//                 this.controlContainerSelection
//                     .style("height", null);

//                 this.items.forEach(d => {
//                     d.CollapserItemSelection.classed("card_style", true);
//                 });
//                 item
//                     .select(":scope > wc-ic-collapse")
//                     .classed("btn_round", false);

//                 if (config.OnCollapseItem) {
//                     config.OnCollapseItem(id, item);
//                 }
//             },
//         });
//         this.controlContainerSelection
//             .style("width", "100%")
//             // .style("height", "100%")
//             .style("padding", "2px");
//     }
// }
