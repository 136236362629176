import saveAs from "file-saver";
import JSZip from "jszip";
import { DataDRequest } from "../../data/DRequest";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import { _SvAlumnoObtenerFotosMes, IFotoEvento } from "../../data/modulo/Alumno";
import DataModuloEscuela from "../../data/modulo/Escuela";
import DataModuloEventos from "../../data/modulo/Eventos";
import { DataUtilAlertBot } from "../../data/util/AlertBot";
import { DateV2 } from "../../util/DateV2";
import { TCARDV2COLL_OnEditOriginEvent } from "../controlD3/CardV2Collapse";
import { CardV2CollapseAdvanced } from "../controlD3/CardV2CollapseAdvanced";
import { ElementWrapper } from "../controlD3/ElementWrapper";
import { GridGallery } from "../controlD3/GridGallery";
import { MenuFlex } from "../controlD3/MenuFlexV2";
import { ModalThings } from "../controlD3/ModalThings";
import { NotificacionV2 } from "../controlD3/NotificacionV2";
import { SelectV2 } from "../controlD3/SelectV2";
import { UIUtilLang } from "../util/Language";
import { UIUtilTime } from "../util/Time";
import { UIUtilGeneral } from "../util/Util";
import { UIUtilViewData } from "../util/ViewData";
import { UIUtilViewAlumno } from "../utilView/Alumno";
import IAlumno = Entidad.IAlumno;
import IDataBase = UIUtilViewData.IBaseData;
import IEvento = Entidad.IEventoAlumno;

interface IDataSemanaItem extends IDataBase {
    Inicio: Date;
    Fin: Date;
}

export class UIPanelCardAlumnosFotos extends CardV2CollapseAdvanced<[IAlumno]> {
    private ctrlSelectYear: SelectV2<IDataBase, "Id">; // , "multiselect">;
    private ctrlSelectMonths: SelectV2<IDataBase, "Id", "multiselect">;
    private ctrlSelectSemanas: SelectV2<IDataSemanaItem, "Id">; // , "multiselect">;
    private ctrlGallery: GridGallery.Gallery<IEvento>;

    private dictEventos: Map<string, IEvento[]>;
    private lastWeekData: number;

    constructor(modulo: Entidad.CModulo) {
        super("card_headertitle", modulo, "panelfotos");
        this.dictEventos = new Map();
    }

    protected CARDCOLL_OnInitBuild(container: TSelectionHTML<"div", any, any>): void {
        this.btnEditarCard._d3Selection.remove(); // .classed("hide", !this.HasActionPermission(data.Entidades.CAccionPermiso.Editar));

        container
            .classed("alumnopanel_fotos " + UIUtilGeneral.FBoxOrientation.Vertical, true)
            .style("row-gap", "15px");

        const areaFiltros = container.append("div")
            .classed("area_filtros " + UIUtilGeneral.FBoxOrientation.Horizontal, true)

        const areaOpciones = container.append("div")
            .classed("area_opciones " + UIUtilGeneral.FBoxOrientation.Horizontal, true)

        const AllSelected = () => {
            if (!this.ctrlGallery?._Data() || !this.ctrlGallery._Data().length) return false;
            return this.ctrlGallery._Data().length == this.ctrlGallery._GetDataSelected().length;
        }

        // .style("column-gap", "10px")
        let ctrlOptions = new MenuFlex({
            OnHide: () => {
                let seleccionados = this.ctrlGallery._GetDataSelected()
                    .map(d => d.Id);

                this.ctrlGallery
                    ._SelectItems(seleccionados)
                    .Checked(false)
                    .UIRefresh();
            },
            Parent: areaOpciones,
            MaxHorizontalItems: 4,
            Items: [
                {
                    Label: () => AllSelected() ? this.CARDCOLL_GetUIStringModule("action_deselect") : this.CARDCOLL_GetUIStringModule("action_selectall"),
                    OnClick: () => {
                        let todosSelected = this.ctrlGallery._Data().length == this.ctrlGallery._GetDataSelected().length;
                        this.ctrlGallery
                            ._SelectItems(this.ctrlGallery._Data().map(d => d.Id))
                            .Checked(!todosSelected)
                            .UIRefresh();
                    }
                },
                {
                    Label: this.CARDCOLL_GetUIStringModule("action_download"),
                    OnClick: async () => {
                        let seleccionados = this.ctrlGallery._GetDataSelected()
                            .map(d => d.Id);
                        this.ctrlProgress.attr("oculto", false);
                        if (!seleccionados.length) seleccionados = this.ctrlGallery._Data().map(d => d.Id);
                        await this.ctrlGallery
                            ._SelectItems(seleccionados)
                            .Download(this.cardData[0].NombreCompleto);
                        this.ctrlProgress.attr("oculto", true);
                    },
                    Description: () =>
                        (!this?.ctrlGallery || !this.ctrlGallery._GetDataSelected().length)
                            ? this.CARDCOLL_GetUIStringModule("tlt_dwnl_all")
                            : this.ctrlGallery._GetDataSelected().length + " " + ((this.ctrlGallery._GetDataSelected().length == 1)
                                ? UIUtilLang._GetUIString("general", "selected1")
                                : UIUtilLang._GetUIString("general", "selected2"))
                },
                {
                    Label: this.CARDCOLL_GetUIStringModule("action_dwnl_bymonth"),
                    OnClick: () => {
                        this.OpenGetPhotosMonthModal();
                    }
                },
            ]
        })

        ctrlOptions._ControlContainer.style("background", "inherit");
        ctrlOptions._Show();

        this.ctrlGallery = new GridGallery.Gallery({
            ID: "Id",
            OnGrouping: (d) => {
                return new Date(d.EvtInicio).getDay();
            },
            OnGetBlockTitle: (idDia, eventos) => UIUtilTime._GetDayName(idDia) + ` (${UIUtilTime._DateFormatStandar(eventos[0].EvtInicio)})`,
            OnUpdateItem: (ctrlImage, d) => {
                //const alumno = this.cardData[0];
                ctrlImage
                    ._OnGetFileName(() => {
                        return this.cardData[0].NombreCompleto + " - " + UIUtilTime._DateFormatStandar(d.EvtInicio).split("/").reverse().join("");
                    })
                    ._SpinnerColor = UIUtilViewAlumno._ALUMNOSEXOCOLOR_2[this.cardData[0].Sexo]
            },
            OnItemGetResource: (d) => DataModuloEventos._GetUrlObtenerFoto(d.InfoEvt, d.EvtInicio, 3),
            OnDownload_GetRealResource: (d) => DataModuloEventos._GetUrlObtenerFoto(d.InfoEvt, d.EvtInicio, 1),
            OnSelectItems: (eventos: IEvento[]) => {
                ctrlOptions._Refresh();
            }
        });

        container.append(() => this.ctrlGallery._NodeControl());

        this.ctrlSelectYear = new SelectV2<IDataBase, "Id">({
            Parent: areaFiltros,
            Type: "monoselect",
            ValueMember: "Id",
            DisplayMember: "Name",
            OnSelect: (dato) => {
                if (dato) {
                    let anio = this.ctrlSelectYear._dataSelected[0].Id;
                    let months = UIUtilViewData._GetMonthsByYearCurrentMonthLimit(anio);

                    this.ctrlSelectMonths._UpdateList(months);

                    if (!this.ctrlSelectMonths._dataSelected[0]) {
                        let lastWeek = months.reduce((a, b) => (a.Id > b.Id ? a : b));
                        this.ctrlSelectMonths._valueSelect(lastWeek.Id);
                    }
                } else {
                    this.ctrlSelectMonths._UpdateList([]);
                }

                this.UI_UpdateAreaFotosGallery(false, () => ctrlOptions._Refresh());
            }
        });

        this.ctrlSelectMonths = new SelectV2<IDataBase, "Id", "multiselect">({
            Parent: areaFiltros,
            Type: "multiselect",
            ValueMember: "Id",
            DisplayMember: "Name",
            ShowNSelectedInList: true,
            ListWidth: "160px",
            OnSelect: (datos) => {
                if (datos?.length) {
                    let anio = this.ctrlSelectYear._dataSelected[0].Id;
                    let weeksInYear = this.GetWeeksInYear(anio, datos.map(d => d.Id));

                    this.ctrlSelectSemanas._UpdateList(weeksInYear);

                    if (weeksInYear.length && !this.ctrlSelectSemanas._dataSelected[0]) {
                        let lastWeek = weeksInYear.reduce((a, b) => (a.Id > b.Id ? a : b));
                        this.ctrlSelectSemanas._valueSelect(lastWeek.Id);
                    }
                } else {
                    this.ctrlSelectSemanas._UpdateList([]);
                }

                this.UI_UpdateAreaFotosGallery(false, () => ctrlOptions._Refresh());
            }
        });

        this.ctrlSelectSemanas = new SelectV2<IDataSemanaItem, "Id">({
            Parent: areaFiltros,
            Type: "monoselect",
            ValueMember: "Id",
            DisplayMember: "Name",
            OnSelect: () => {
                this.UI_UpdateAreaFotosGallery(false, () => ctrlOptions._Refresh());
            }
        });

        areaFiltros.append(() => ElementWrapper._WrapperToSelectControl(this.ctrlSelectYear, UIUtilLang._GetUIString("time", "anio") + ":").node())
            .classed("anio_wrapper", true);
        areaFiltros.append(() => ElementWrapper._WrapperToSelectControl(this.ctrlSelectMonths, UIUtilLang._GetUIString("time", "mes") + ":").node())
            .classed("mes_wrapper", true);
        areaFiltros.append(() => ElementWrapper._WrapperToSelectControl(this.ctrlSelectSemanas, UIUtilLang._GetUIString("time", "semana") + ":").node())
            .classed("semana_wrapper", true);
    }
    protected CARDCOLL_GetVariantToValidateUpdate(cardData_0: IAlumno): string {
        return cardData_0.IdChild.toString();
    }
    protected CARDCOLL_OnUpdateData(cardData_0: IAlumno): void | Promise<void> {
        let aniosDisponibles = this.GetAniosDisponibles();
        this.ctrlSelectYear._UpdateList(aniosDisponibles);

        this.dictEventos.clear();

        if (!this.ctrlSelectYear._dataSelected[0] && aniosDisponibles.length) {
            this.ctrlSelectYear._valueSelect(aniosDisponibles.reduce((a, b) => (a.Id > b.Id ? a : b)).Id);
        }

        return this.UI_UpdateAreaFotosGallery(this.CARDCOLL_StatusCardExpandido);
    }
    protected CARDCOLL_MostrarBody(): void {
        this.cardContentContainerSelection.style("height", "100%");
        this.UI_UpdateAreaFotosGallery(true);
    }
    protected CARDCOLL_OcultarBody(): void {
        this.cardContentContainerSelection.style("height", null);
    }
    protected CARDCOLL_OnEditarCard(originEvent: TCARDV2COLL_OnEditOriginEvent): void {
        //throw new Error("Method not implemented.");
    }
    protected CARDCOLL_OnCancelaEditarCard(originEvent: TCARDV2COLL_OnEditOriginEvent): void {
        //throw new Error("Method not implemented.");
    }
    protected CARDCOLL_GuardarCardV2(): Promise<DataDRequest.IRequestResponseA<any>> {
        //throw new Error("Method not implemented.");
        return null;
    }
    protected CARDCOLL_SyncOrGetIdToDownloadData(): DataModuloMain.TipoRequestMonitorId | (() => Promise<any>) | DataModuloMain.TipoRequestMonitorId[] {
        return () => this.UI_UpdateAreaFotosGallery(true);
    }
    protected CARDCOLL_GetIdSchool(cardData_0_0: IAlumno): number {
        return cardData_0_0.IdKinder;
    }

    /* public met_Destroy(): this {
        super.met_Destroy();
        MainPage.fn_RemoveEventListenerWorkerRequest(Entidad.CTipoRequest.Alumno, this["__workerListenerAlumno"]);
        this["__workerListenerAlumno"] = null;
        return this;
    } */

    private UI_UpdateAreaFotosGallery(forceUpdate: boolean = false, onUpdate?: () => void) {
        const itemWeekSeleted = this.ctrlSelectSemanas._dataSelected[0];
        const reset = !this.CARDCOLL_StatusCardExpandido;

        if (!reset && !forceUpdate && this.lastWeekData && this.lastWeekData == itemWeekSeleted?.Id) {
            return null;
        }

        let weekData: IEvento[] = [];

        if (reset) {
            this.lastWeekData = null;
        } else {
            let dateSelected = itemWeekSeleted?.Inicio;
            this.lastWeekData = itemWeekSeleted?.Id;

            if (dateSelected) {
                return new Promise<void>(async (resolve, reject) => {

                    this.ctrlProgress.attr("oculto", false);

                    let res = await this.Sv_ObtenerEventosFotosPorEscuela(this.cardData[0], dateSelected);
                    weekData = (res.Data || []);

                    if (res.Resultado <= 0) {
                        this.ctrlNotification._Mostrar(UIUtilLang._GetUIString("general", "notif_fail_infoupdate"), "ADVERTENCIA");
                    }

                    this.ctrlProgress.attr("oculto", true);
                    this.ctrlGallery
                        ._DataUpdate(weekData)
                        ._UIRefresh();
                    resolve();
                    if (onUpdate) onUpdate();
                })
            }
            if (onUpdate) onUpdate();
        }

        // >> UPDATE GALLERY
        this.ctrlGallery
            ._DataUpdate(weekData)
            ._UIRefresh();
        if (onUpdate) onUpdate();
        return null;

    }

    // **************************************************************************
    // PERMISOS COSAS
    // **************************************************************************

    // **************************************************************************
    //  COSAS: SERVICIOS Y DATA
    // **************************************************************************

    private GetWeeksInYear(anio: number, months: number[]): IDataSemanaItem[] {
        let yearWeeks: IDataSemanaItem[] = [];
        let today = new Date();
        let startDayOfCurrentWeek = this.GetStartWeekDay(today);
        const validatorStartDayOfCurrentWeek = UIUtilTime._GetValidatorDateYMD(startDayOfCurrentWeek);

        months
            .sort((a, b) => (a - b))
            .forEach((month, i) => {
                let auxStartDate = new Date(anio, month, 1);
                let firstDayIsMonday = (auxStartDate.getDay() == 1);
                let firstWeekCanStartInPreviousMonth = true;
                if (!firstDayIsMonday && i > 0 && (month - 1 == months[i - 1])) {
                    firstWeekCanStartInPreviousMonth = false;
                }
                let weeksInMonth = this.GetWeeksInMonth(auxStartDate, firstWeekCanStartInPreviousMonth);

                weeksInMonth
                    .forEach(week => {
                        if (UIUtilTime._GetValidatorDateYMD(week.Inicio) <= validatorStartDayOfCurrentWeek) {
                            yearWeeks.push(week);
                        }
                    })
            })

        return yearWeeks;
    }

    private GetWeeksInMonth(d: Date, firstWeekCanStartInPreviousMonth: boolean = false): IDataSemanaItem[] {
        let auxDInitWeek = this.GetStartDayOfFirstWeekOfMonth(d, firstWeekCanStartInPreviousMonth);
        let monthWeeks: IDataSemanaItem[] = [];
        let nWeek = 1;

        let fnValid = () => {
            if (nWeek == 1 && firstWeekCanStartInPreviousMonth) {
                let previusMonth = new Date(d);
                previusMonth.setMonth(previusMonth.getMonth() - 1);
                return (auxDInitWeek.getMonth() == d.getMonth()) || (auxDInitWeek.getMonth() == previusMonth.getMonth());
            }
            return (auxDInitWeek.getMonth() == d.getMonth());
        }

        while (fnValid()) {
            let weekItem = this.GetWeekItem(auxDInitWeek);
            monthWeeks.push(weekItem);
            auxDInitWeek.setDate(auxDInitWeek.getDate() + 7);
            nWeek++;
        }

        return monthWeeks;
    }

    private GetStartDayOfFirstWeekOfMonth(d: Date, firstWeekDayCanBeFromPreviousMonth: boolean = false) {
        let auxD = this.GetStartWeekDay(d);
        if (!firstWeekDayCanBeFromPreviousMonth) {
            if (UIUtilTime._GetValidatorDateYM(auxD) < UIUtilTime._GetValidatorDateYM(d)) {
                auxD.setDate(auxD.getDate() + 7);
            }
        }
        return auxD;
    }

    private GetStartWeekDay(d: Date): Date {
        let auxD = new Date(d);
        let weekDay = auxD.getDay() == 0 ? 7 : auxD.getDay();

        auxD.setDate(auxD.getDate() + (1 - weekDay));

        return auxD;
    }

    private GetWeekItem(startDate: Date): IDataSemanaItem {
        let endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + 6);

        return {
            Id: Number(startDate),
            Name: UIUtilTime._DateFormatStandar(startDate) + " - " + UIUtilTime._DateFormatStandar(endDate),
            Inicio: new Date(startDate),
            Fin: endDate
        }
    }

    private GetAniosDisponibles() {
        let years: IDataBase[] = [];
        let school = DataModuloEscuela._DiccEscuela.get(this.cardData[0].IdKinder);
        let initY = new Date(school.FechaRegistro).getFullYear();

        for (let i = initY; i <= new Date().getFullYear(); i++) {
            years.push({
                Id: i,
                Name: i.toString()
            })
        }

        return years.sort((a, b) => (b.Id - a.Id));
    }

    private OpenGetPhotosMonthModal() {
        let ctrlSelectAnios: SelectV2<IDataBase, "Id">;
        let ctrlSelectMes: SelectV2<IDataBase, "Id">;

        ModalThings._GetModalToAProccess({
            Title: this.CARDCOLL_GetUIStringModule("tag_getphotos_bymonth"),
            DrawContent: (container, mt) => {
                let selectorsContainer = container.append("div")
                    .classed(UIUtilGeneral.FBoxOrientation.Vertical, true)
                    .style("gap", "24px")
                    .style("padding", "14px");

                ctrlSelectAnios = new SelectV2<IDataBase, "Id">({
                    Type: "monoselect",
                    Parent: selectorsContainer,
                    DisplayMember: "Name",
                    ValueMember: "Id",
                    OnSelect: (dato) => {
                        if (dato) {
                            let anio = ctrlSelectAnios._dataSelected[0].Id;
                            let months = UIUtilViewData._GetMonthsByYearCurrentMonthLimit(anio);
                            ctrlSelectMes._UpdateList(months);
                            if (!ctrlSelectMes._dataSelected[0]) {
                                let lastWeek = months.reduce((a, b) => (a.Id > b.Id ? a : b));
                                ctrlSelectMes._valueSelect(lastWeek.Id);
                            }
                        } else {
                            ctrlSelectMes._UpdateList([]);
                        }
                    }
                });

                selectorsContainer.append(() => ElementWrapper._WrapperToSelectControl(ctrlSelectAnios, UIUtilLang._GetUIString("time", "anio") + ":").node());

                ctrlSelectMes = new SelectV2<IDataBase, "Id">({
                    Type: "monoselect",
                    Parent: selectorsContainer,
                    DisplayMember: "Name",
                    ValueMember: "Id"
                });

                selectorsContainer.append(() => ElementWrapper._WrapperToSelectControl(ctrlSelectMes, UIUtilLang._GetUIString("time", "mes") + ":").node());

                let aniosDisponibles = this.GetAniosDisponibles();
                ctrlSelectAnios._UpdateList(aniosDisponibles);
                if (!ctrlSelectAnios._dataSelected[0] && aniosDisponibles.length) {
                    ctrlSelectAnios._valueSelect(aniosDisponibles.reduce((a, b) => (a.Id > b.Id ? a : b)).Id);
                }

                container.append("div")
                    .classed("counter_files", true)
                    .style("display", "flex")
                    .style("padding", "0px 14px")
                    .style("justify-content", "end")
                    .append("label")
                    .classed("counter", true);
            },
            Width: 400,
            OnAccept: async (mt) => {
                let counterFiles = mt.CurrentBody.select(".counter_files").select(".counter").text("");
                const anio = ctrlSelectAnios._dataSelected[0].Id;
                const mes = ctrlSelectMes._dataSelected[0].Id;

                let inicio = new DateV2(new Date(anio, mes))._SetTimeZoneByIdSchool(this.cardData[0].IdKinder);
                let fin = new DateV2(inicio)._SetTimeZoneByIdSchool(this.cardData[0].IdKinder);
                fin.setMonth(fin.getMonth() + 1);
                fin.setMilliseconds(fin.getMilliseconds() - 1);

                let resFotosMes = await _SvAlumnoObtenerFotosMes(this.cardData[0].IdKinder, this.cardData[0].IdChild, inicio._ToISOString(), fin._ToISOString());
                if (resFotosMes.Resultado < 1) {
                    this.ctrlNotification._Mostrar(UIUtilLang._GetUIString("general", "notif_fail"), "ADVERTENCIA")
                } else {
                    if (!resFotosMes.Datos.length) {
                        this.ctrlNotification._Mostrar(this.CARDCOLL_GetUIStringModule("notif_nophotos_mes"), "ADVERTENCIA");
                    } else {
                        let arrFotosRes = resFotosMes.Datos;
                        arrFotosRes.sort((a, b) => a.DtEvento.localeCompare(b.DtEvento));
                        const fileNames = new Map<string, number>();
                        let folderName = this.cardData[0].NombreCompleto + " - " + ctrlSelectMes._dataSelected[0].Name + " " + ctrlSelectAnios._dataSelected[0].Name;
                        const zip = new JSZip();
                        const resumenFolder = zip.folder(folderName);
                        let successFiles = 0;
                        counterFiles.text(successFiles + "/" + arrFotosRes.length)

                        const GetFotosFiles = async (arrFotos: Array<IFotoEvento>) => {
                            const ArrFotosErrrs: Array<IFotoEvento> = []
                            for (let foto of arrFotos) {
                                let url = DataModuloEventos._GetUrlObtenerFoto(foto.Id, foto.DtEvento, 1);
                                if (!url) { ArrFotosErrrs.push(foto) }
                                let succesFile = await new Promise<boolean>((resolve) => {
                                    DataDRequest._RequestBlobFromUrlResource(url, (resource, error) => {
                                        let newFile: File;
                                        if (error == null) {
                                            if (resource) {
                                                let fechaStr = UIUtilTime._DateFormatStandar(foto.DtEvento, "dd/mm/yyyy").split("/").reverse().join("");
                                                fileNames.set(fechaStr, fileNames.get(fechaStr) ? (fileNames.get(fechaStr) + 1) : 1);
                                                newFile = new File([resource], fechaStr + " (" + fileNames.get(fechaStr) + ")", { type: resource.type });
                                                resumenFolder.file(newFile.name + "." + newFile.type.split("/")[1], newFile);
                                                successFiles++;
                                                counterFiles.text(successFiles + "/" + arrFotosRes.length)
                                            }
                                        } else {
                                            console.warn("-d Error al obtener blob")
                                            resolve(false);
                                        }
                                        resolve(true);
                                    })
                                })
                                if (!succesFile) { ArrFotosErrrs.push(foto) }
                            }

                            if (!!ArrFotosErrrs.length) {
                                let res = await ModalThings._GetConfirmacionBasicoV2({
                                    Title: this.CARDCOLL_GetUIStringModule("tag_retryphotos_downl"),
                                    Content: this.CARDCOLL_GetUIStringModule("monthlydown_retrycontent").replace("_DOWNERRS", ArrFotosErrrs.length + ""),
                                    TypeStrModalBotons: "sí_no"
                                })
                                if (!res) { counterFiles.text(""); return; }
                                await GetFotosFiles(ArrFotosErrrs);
                                return;
                            } else {
                                await zip.generateAsync({ type: "blob" })
                                    .then(function (content) {
                                        saveAs(content, folderName);
                                        NotificacionV2._Mostrar(UIUtilLang._GetUIString("panelfotos", "success_process"), "INFO");
                                    })
                                    .catch((e) => {
                                        DataUtilAlertBot._SendError(e, "Descarga de fotos");
                                        NotificacionV2._Mostrar(UIUtilLang._GetUIString("general", "notif_fail"), "ADVERTENCIA")
                                    })
                                counterFiles.text("");
                            }

                        }

                        await GetFotosFiles(arrFotosRes);
                    }
                }
                return null;
            }
        })
    }

    private async Sv_ObtenerEventosFotosPorEscuela(alumno: IAlumno, date: Date): Promise<DataDRequest.IResultadoPeticion<IEvento[]>> {
        // let dateValidatos = Utils.fn_GetValidatorDateYM(dat)
        let date2 = new DateV2(date)._SetTimeZoneByIdSchool(this.cardData[0].IdKinder, true);
        let eventosSemana = (this.dictEventos.get(date2._ToISOString()) || []);
        // let eventosFecha = eventosSemana?.get(date.toISOString());

        if (eventosSemana.length) {
            return {
                Resultado: 1,
                Data: eventosSemana,
                TipoRequest: null
            }
        } else {
            let res = await DataModuloEventos._ObtenerEventosPorAlumno(alumno.IdChild, alumno.IdKinder, Entidad.CReporteTipoEvento.Fotos, date2);

            if (res.Resultado > 0 && (UIUtilTime._GetValidatorDateYMD(date2) + 7) <= UIUtilTime._GetValidatorDateYMD(new Date())) {
                eventosSemana.push(...(res.Data || []));

                this.dictEventos.set(date2._ToISOString(), eventosSemana);
            }

            return res;
        }
    }
}
