import * as d3 from "d3";
import { MainPage } from "../../MainPage";
import { DataCache } from "../../data/Cache";
import { DataDRequest } from "../../data/DRequest";
import { Entidad } from "../../data/Entidad";
import { Global } from "../../data/Global";
import { DataModuloMain } from "../../data/ModuloMain";
import { CAlumnoEdoLicencia } from "../../data/entidad/LicenciaOld";
import { _LOCALDATA_GetGruposHorariosDeAlumno, _LOCALDATA_GetTutoresDeAlumno, _SvAlumnoActualizarFotoPerfil, _SvAlumnoEditar, _SvAlumnoURLObtenerFoto } from "../../data/modulo/Alumno";
import DataModuloEscolaridad from "../../data/modulo/Escolaridad";
import DataModuloEscuela from "../../data/modulo/Escuela";
import DataModuloGrado from "../../data/modulo/Grado";
import DataModuloHorarioAlumno from "../../data/modulo/HorarioAlumno";
import { _LOCALDATA_Licencia_EdoActualAlumno } from "../../data/modulo/LicenciaOld";
import { DataUtilAlertBot } from "../../data/util/AlertBot";
import { DataUtil } from "../../data/util/Util";
import { DateV2 } from "../../util/DateV2";
import _L, { _HttpMsgV2 } from "../../util/Labels";
import { Fields, FormGenerator, IField } from "../controlD3/Formulario";
import { FullViewFile } from "../controlD3/FullViewFile";
import { InputFileDialog } from "../controlD3/InputFileDialog";
import { ModalThings } from "../controlD3/ModalThings";
import { NotificacionV2 } from "../controlD3/NotificacionV2";
import { SelectV2 } from "../controlD3/SelectV2";
import { TutorialActivarAlumno } from "../controlD3/TutorialActivarAlumno";
import { HTMLImage2Component } from "../controlWC/HTMLImage2Component";
import { UIUtilIconResources } from "../util/IconResourses";
import { UIUtilLang } from "../util/Language";
import { UIUtilPermission } from "../util/Permission";
import { UIUtilTime } from "../util/Time";
import { UIUtilGeneral } from "../util/Util";
import { UIUtilViewData } from "../util/ViewData";

export namespace UIUtilViewAlumno {
    import IAlumno = Entidad.IAlumno;
    import CAccionPermiso = Entidad.CAccionPermiso;
    import CModulo = Entidad.CModulo;
    import CNinioMovimiento = Entidad.CNinioMovimiento;
    import CAlumnoTutorHorarioMissActv = Entidad.CAlumnoTutorHorarioMissActv;

    export const _ALUMNOSEXOCOLOR = { // FIXME
        [Entidad.CSexo.Default]: "var(--color_app_green1)", // "#00d75a",
        [Entidad.CSexo.Femenino]: "var(--color_app_pink1)", // "#D30C7B",
        [Entidad.CSexo.Masculino]: "var(--color_app_blue1)" // "#44CCFF",
    }

    export const _ALUMNOSEXOCOLOR_2 = { // REMOVER
        [Entidad.CSexo.Default]: "rgba(60, 140, 60, 0.7)",
        [Entidad.CSexo.Femenino]: "rgba(170, 10, 100, 0.7)",
        [Entidad.CSexo.Masculino]: "rgba(40, 130, 160, 0.7)",
    }

    // NOTE TEMPORAL -> Busca un minimo de dos carácteres válidos consecutivos
    const regexValidCharacters = /[a-zA-Z]{2}/;

    // *********************************************************************
    // Form
    // *********************************************************************

    export interface IAlumnoForm extends IAlumno {
        Foto: string;
        ImageIsChange: boolean;
    }

    export function _GetListasToForm(datoAlumno: IAlumno, action: (CAccionPermiso.Agregar | CAccionPermiso.Editar), modulo: (CModulo.Alumnos | CModulo.PanelInfoGeneral)) {
        let listaEdosAlumno = GetListaEstadosParaNinio(action, datoAlumno);
        let listaEscuelasConPermiso = UIUtilPermission._GetSchoolsByActionModule(modulo, action);

        // -> Fix: Si no tiene permisos para la escuela a la que pertenece
        if (datoAlumno.IdKinder && action == CAccionPermiso.Editar && !listaEscuelasConPermiso.find(d => (d.IdKinder == datoAlumno.IdKinder)) && DataModuloEscuela._DiccEscuela.has(datoAlumno.IdKinder)) {
            listaEscuelasConPermiso.push(DataModuloEscuela._DiccEscuela.get(datoAlumno.IdKinder));
        }

        return {
            ListaEdos: listaEdosAlumno,
            ListaEscuelas: listaEscuelasConPermiso
        }
    }

    export function _GetAlumnoForm<TDataForm extends (IAlumno | IAlumnoForm) = IAlumnoForm>(action: (CAccionPermiso.Agregar | CAccionPermiso.Editar), modulo: (CModulo.Alumnos | CModulo.PanelInfoGeneral), datoAlumno = <IAlumno>{}): FormGenerator<TDataForm> {
        let datoForm: IAlumnoForm = <IAlumnoForm>{
            ...datoAlumno,
            ...{
                Foto: datoAlumno.getThumbnailURL,
                FechaNacimiento: (datoAlumno.FechaNacimiento ? UIUtilTime._FmtToInputDate(datoAlumno.FechaNacimiento, datoAlumno.IdKinder) : datoAlumno.FechaNacimiento),
                ImageIsChange: false
            }
        };

        let listas = _GetListasToForm(datoAlumno, action, modulo);
        let listaEdosAlumno = listas.ListaEdos;
        let listaEscuelasConPermiso = listas.ListaEscuelas;
        let form = new FormGenerator<IAlumnoForm>();

        // -> Verifica rows y propiedades

        let initFormSchema: Array<IField<IAlumnoForm>> = [
            { type: Fields.input, inputAttr: { type: "text" }, labelAttr: { text: "d_field_matricula" }, model: "Matricula" },
            {
                type: Fields.input,
                model: "Nombre",
                inputAttr: { required: true },
                labelAttr: { text: "d_field_nombre" },
                onValidate: (val) => (!regexValidCharacters.test(String(val))
                    ? _L("alumnos.notif_invalidinput")
                    : true)
            },
            {
                type: Fields.input,
                model: "ApPaterno",
                inputAttr: { required: true },
                labelAttr: { text: "d_field_appaterno" },
                onValidate: (val) => (!regexValidCharacters.test(String(val))
                    ? _L("alumnos.notif_invalidinput")
                    : true)
            },
            {
                type: Fields.input,
                model: "ApMaterno",
                labelAttr: { text: "d_field_apmaterno" },
                onValidate: (val) => (!!val && val.trim() != "" && !regexValidCharacters.test(String(val))
                    ? _L("alumnos.notif_invalidinput")
                    : true)
            },
            { type: Fields.input, inputAttr: { type: "date", required: datoForm.IdChildMovimiento == Entidad.CNinioMovimiento.Activo, max: UIUtilTime._FmtToInputDate(new Date()) }, labelAttr: { text: "frm1_fechanacimiento" }, model: "FechaNacimiento" },
            {
                type: Fields.radioList,
                labelAttr: { text: "d_field_strsexo", },
                model: "Sexo",
                radioListAttr: {
                    ValueMember: "Id", DisplayMember: "Name", ReturnOnlyValueMember: true, required: datoForm.IdChildMovimiento == Entidad.CNinioMovimiento.Activo,
                    Data: UIUtilViewData._GetList_Sexo(),
                }
            },
            {
                type: Fields.selectMaterial, labelAttr: { text: "d_field_nombrekinder", }, model: "IdKinder",
                selectMaterialAttr: {
                    valueMember: "IdKinder", displayMember: "Nombre",
                    required: true,
                    onSelect: (/* id,  */datoEscuela: Entidad.IEscuela) => {
                        // console.log(datoEscuela, "onselect escuela")
                        let comboEscolaridad = form._ControlsData.get("IdEscolaridad").instance as SelectV2;
                        if (datoEscuela) {
                            comboEscolaridad._UpdateList(GetEscolaridadesFiltrados(datoEscuela.IdKinder));
                        } else {
                            comboEscolaridad._ResetSelect();
                            comboEscolaridad._UpdateList([]);
                        }
                    }
                }, values: listaEscuelasConPermiso
            },
            {
                type: Fields.selectMaterial, labelAttr: { text: "d_field_strescolaridad", }, model: "IdEscolaridad",
                selectMaterialAttr: {
                    valueMember: "Id", displayMember: "Nombre",
                    required: true,
                    onSelect: (datoEscolaridad) => {
                        // console.log(datoEscolaridad, "onselect escolaridad ")
                        let comboGrado = form._ControlsData.get("IdGrado").instance as SelectV2;
                        if (datoEscolaridad) {
                            comboGrado._UpdateList(GetGradosFiltrados(datoEscolaridad.IdEscuela, datoEscolaridad.Id));
                        } else {
                            comboGrado._ResetSelect();
                            comboGrado._UpdateList([]);
                        }
                    },
                }, values: [],
            },
            {
                type: Fields.selectMaterial,
                labelAttr: { text: "d_field_strgrado", },
                model: "IdGrado",
                selectMaterialAttr: { valueMember: "IdNivel", displayMember: "Nombre", required: true, }, values: [],
            },
        ];

        if (modulo == CModulo.Alumnos) {
            initFormSchema.push({
                model: "Foto",
                type: Fields.fotoV2,
                fotoV2Attrs: {
                    RowClase: "alinear_foto",
                    TypeValue: "base64",
                    DefaultBackgroundImage: UIUtilIconResources.CGeneral.User,
                    OnChange: (isImage, isFromInput, file, src) => {
                        if (isImage && isFromInput) {
                            form._DataOrigin.ImageIsChange = true;
                        }
                    }
                }
            });
        }

        if (action == CAccionPermiso.Editar) {
            initFormSchema.push({
                type: Fields.selectMaterial,
                labelAttr: { text: "d_field_strestado", },
                model: "IdChildMovimiento",
                selectMaterialAttr: {
                    valueMember: "Id", displayMember: "Name",
                    required: true,
                    OnStepItemListUI: (container, dato: typeof listaEdosAlumno[number]) => container
                        .classed("opc_" + dato.Name.toLowerCase(), true)
                        .text(dato.Name),
                    onChange: (_id, datoMovimiento: typeof listaEdosAlumno[number]) => {
                        if (!datoMovimiento) return;
                        let fecha = form._ControlsData.get("FechaNacimiento").selection.node() as HTMLInputElement;
                        let sexo = form._ControlsData.get("Sexo").selection.node() as HTMLInputElement;
                        if (datoMovimiento.Id == Entidad.CNinioMovimiento.Activo) {

                            fecha.setAttribute("required", "");
                            sexo.setAttribute("required", "");

                            let getFecha = form._Data["FechaNacimiento"];
                            let getSexo = form._Data["Sexo"];


                            if (getFecha == "" || !getSexo) {

                                let mensaje = UIUtilLang._GetUIString("alumnos", "notif_falta_nacimientosexo");
                                NotificacionV2._Mostrar(mensaje, "ADVERTENCIA");
                                //  this.notificacion.fn_Mostrar("Para los alumnos activos son obligatorios los campos: fecha de nacimiento y sexo.", controlD3.Notificacion.CTipoNotificacion.INFO)
                            }
                        } else {
                            fecha.removeAttribute("required");
                            sexo.removeAttribute("required");
                        }
                    }
                },
                values: listaEdosAlumno
            })
        }

        const ctrlNotification = new NotificacionV2();
        // -> Creación de control Formulario
        form._Crear({
            LangModuleKeyInContext: "alumnos",
            schema: initFormSchema,
            LabelMaxWidth: (modulo == CModulo.PanelInfoGeneral ? 300 : null),
            Validation: (value, field, dataF, controlsForm) => {
                let dataForm: IAlumno = { // REMOVER // FIXME ésta implementación cuando alumno.Tutores sea deprecado
                    ...form._DataOrigin, // Obtiene los objetos (para el caso de Editar)
                    ...form._Data
                }
                let res = FormAlumnoValidator(value, field, form._DataOrigin, dataForm, (action != CAccionPermiso.Agregar ? CAccionPermiso.Editar : action));
                if (res.Message && form["__noshownotification__"] === undefined) {
                    ctrlNotification
                        ._DestroyAll()
                        ._Mostrar(res.Message, !res.IsValid ? "ADVERTENCIA" : "INFO");
                }
                return res.IsValid;
            },
            BuildView: (container, controlsForm, form) => {
                const AppendRow = (model: keyof IAlumnoForm) => {
                    if (controlsForm.has(model)) {
                        container.append(() => controlsForm.get(model).row.node());
                    }
                }
                // -> Orden de los elementos en el formulario
                AppendRow("Foto");
                AppendRow("Matricula");
                AppendRow("Nombre");
                AppendRow("ApPaterno");
                AppendRow("ApMaterno");
                AppendRow("FechaNacimiento");
                AppendRow("Sexo");
                AppendRow("IdKinder");
                AppendRow("IdEscolaridad");
                AppendRow("IdGrado");
                AppendRow("IdChildMovimiento");
            }
        }, datoForm);

        return form as unknown as FormGenerator<TDataForm>;
    }

    /**
     * @param tipo
     *
     * "agregar" -> [Activo, Preiscripcion]
     *
     * "editar" -> Los elementos de la lista se actualizan de acuerdo al valor de dataNinio.IdChildMovimiento
     * * (3) BajaDefinitiva: [BajaDefinitiva]
     * * (4) Graduado: [Graduado]
     * * (1) Activo: (any != Preinscrito)[]
     * * (2) BajaTemporal: (any != Graduado)[]
     * * (5) Preinscrito: [Preinscrito, Activo, BajaDefinitiva]
     * @param dataNinio
     */
    function GetListaEstadosParaNinio(action: (CAccionPermiso.Agregar | CAccionPermiso.Editar), dataNinio?: IAlumno) {
        const allEstados = UIUtilViewData._GetList_EstadosMovimientos();
        let result: (typeof allEstados[0])[];

        if (action == CAccionPermiso.Agregar) {
            return allEstados.filter(d => (d.Id == CNinioMovimiento.Activo || d.Id == CNinioMovimiento.Preinscripcion));
        }
        else if (action == CAccionPermiso.Editar) {
            switch (dataNinio.IdChildMovimiento) {
                case CNinioMovimiento.Activo:
                    result = allEstados.filter(d => (d.Id != CNinioMovimiento.Preinscripcion));
                    break;
                case CNinioMovimiento.BajaTemporal:
                    result = allEstados.filter(d => (d.Id != CNinioMovimiento.Graduado));
                    break;
                case CNinioMovimiento.Preinscripcion:
                    result = allEstados.filter(d => (d.Id == CNinioMovimiento.Preinscripcion || d.Id == CNinioMovimiento.Activo || d.Id == CNinioMovimiento.BajaDefinitiva));
                    break;
                case CNinioMovimiento.BajaDefinitiva:
                case CNinioMovimiento.Graduado:
                default:
                    result = allEstados.filter(d => (d.Id == dataNinio.IdChildMovimiento));
                    break;
            }
        }
        // console.debug(CAccionPermiso[action], dataNinio.IdChildMovimiento, CNinioMovimiento[dataNinio.IdChildMovimiento], result)
        return result;
    }

    function GetEscolaridadesFiltrados(idEscuela: number): Array<Entidad.IEscolaridad> {
        return Array.from(DataModuloEscolaridad._DiccEscolaridad.values())
            .filter(dEscolaridad => (dEscolaridad.IdEscuela == idEscuela));
    }

    function GetGradosFiltrados(idEscuela: number, idEscolaridad: number): Array<Entidad.IGrado> {
        return Array.from(DataModuloGrado._DiccGrado.values())
            .filter(dGrado => (dGrado.IdKinder == idEscuela && dGrado.IdEscolaridad == idEscolaridad));
    }

    // *********************************************************************
    // Form validators
    // *********************************************************************

    function FormAlumnoValidator(val: any, field: (keyof IAlumnoForm | keyof IAlumno), initAlumnoData: IAlumnoForm, dato: (IAlumnoForm | IAlumno), action: (CAccionPermiso.Agregar | CAccionPermiso.Editar)) {
        let res: boolean = true;
        let message = "";

        if (_LOCALDATA_Licencia_EdoActualAlumno(initAlumnoData.IdChild) == CAlumnoEdoLicencia.Caducado) { // FIXME Evaliar en OnAccept
            return {
                IsValid: false,
                Message: UIUtilLang._GetUIString("alumnos", "notif_liccaduca")
            }
        }

        return {
            IsValid: res,
            Message: message
        };
    }

    /**
     * validar al cambiar al estado activo de un registro solo cuando se edita,
     * cuando se agrega se manda directo a los paneles al finalizar el alta del registro
     *
     * @param datoNinio IdAlumno as number | data as @interface IAlumno
     */
    export function _ValidarTutorYHorario(datoNinio: IAlumno | number) {
        datoNinio = typeof datoNinio == "number" ? DataModuloMain._GetReqDataMapByName("Alumno").get(datoNinio) : datoNinio;
        let mensaje: string = "";
        let res: boolean = false;
        let err: CAlumnoTutorHorarioMissActv = CAlumnoTutorHorarioMissActv.None;

        if (datoNinio) {
            const asignacionesHorarioNinio = _LOCALDATA_GetGruposHorariosDeAlumno(datoNinio.IdChild, datoNinio.IdKinder);

            if (datoNinio) { // FIXME Ajustar la implementación cuando alumno.Tutores sea deprecado
                const tutoresAlumno = _LOCALDATA_GetTutoresDeAlumno(datoNinio.IdChild);
                if (tutoresAlumno.size == 0 && asignacionesHorarioNinio.size == 0) { // !Ninios.fn_GetGruposInscritosValidos([updateNinio]).AllHaveValidGroups) {
                    mensaje = UIUtilLang._GetUIString("alumnos", "notif_falta_tutorgrupo");
                    err = CAlumnoTutorHorarioMissActv.Ambos;
                } else if (tutoresAlumno.size == 0) {
                    mensaje = UIUtilLang._GetUIString("alumnos", "notif_falta_tutor");
                    err = CAlumnoTutorHorarioMissActv.Tutor;
                } else if (asignacionesHorarioNinio.size == 0) { // !Ninios.fn_GetGruposInscritosValidos([updateNinio]).AllHaveValidGroups) {
                    mensaje = UIUtilLang._GetUIString("alumnos", "notif_falta_grupo");
                    err = CAlumnoTutorHorarioMissActv.Horario;
                } else {
                    res = true;
                    err = CAlumnoTutorHorarioMissActv.None;
                }
            } else {
                console.warn("-d", "alumno no found to validate tutores & horarios!")
            }
        }

        return {
            IsValid: res,
            Message: mensaje,
            SpecificErr: err
        }
    }

    export async function _TutorYHorarioStepGuideForm(missingStep: CAlumnoTutorHorarioMissActv, idChild: number, idEscuela: number) {
        if (!TutorialActivarAlumno._HasPermissionToActivate(missingStep, idEscuela)) {
            let mssg = "";
            switch (missingStep) {
                case CAlumnoTutorHorarioMissActv.Ambos:
                    mssg = UIUtilLang._GetUIString("alumnos", "tag_missing_tutor_group");
                    break;
                case CAlumnoTutorHorarioMissActv.Horario:
                    mssg = UIUtilLang._GetUIString("alumnos", "tag_missing_group");
                    break;
                case CAlumnoTutorHorarioMissActv.Tutor:
                    mssg = UIUtilLang._GetUIString("alumnos", "tag_missing_tutor");
                    break;
            }
            NotificacionV2._Mostrar(mssg, "ADVERTENCIA", 3500);
            return false;
        }
        const container = d3.create("div");
        container.append("label").text(UIUtilLang._GetUIString("alumnos", "tag_title_activation_modal"));
        const list = container.append("div")
            .classed("modal_details_1", true)
            .append("div")
            .classed("datalist", true)

        list.append("div")
            .classed("hide", ![CAlumnoTutorHorarioMissActv.Ambos, CAlumnoTutorHorarioMissActv.Horario].includes(missingStep))
            .append("div")
            .style("border-left", "1px solid var(--color_primary4)")
            .style("padding-left", "var(--padding1)")
            .style("display", "grid")
            .append("label").text(UIUtilLang._GetUIString("alumnos", "tag_schedule_assign"))

        list.append("div")
            .classed("hide", ![CAlumnoTutorHorarioMissActv.Ambos, CAlumnoTutorHorarioMissActv.Tutor].includes(missingStep))
            .append("div")
            .style("border-left", "1px solid var(--color_primary4)")
            .style("padding-left", "var(--padding1)")
            .style("display", "grid")
            .append("label").text(UIUtilLang._GetUIString("alumnos", "tag_tutor_assign"))

        container.append("div")
            .style("margin-top", "10px")
            .append("label")
            .text(UIUtilLang._GetUIString("alumnos", "tag_confirm_activation_guide"))

        let resConfirm = await ModalThings._GetConfirmacionBasicoV2({
            Title: UIUtilLang._GetUIString("alumnos", "title_actvguide_modal_confirm"),
            Content: container,
            Width: 400
        })

        if (resConfirm) {
            TutorialActivarAlumno._StartStudentActivationGuide(missingStep, idChild);
        }
        return resConfirm;
    }

    export function _NotiFalloEditEstadoYGrado(originIdChildMovimiento: number, originIdGrado: number, formIdGrado: number) {
        if (originIdChildMovimiento != CNinioMovimiento.Activo && originIdGrado != formIdGrado) {
            ModalThings._GetModalSimple({
                Title: UIUtilLang._GetUIString("alumnos", "tag_warn_modal"),
                Width: 280,
                Message: UIUtilLang._GetUIString("alumnos", "tag_fail_edition_gradeactivation"),
            })
            return false;
        }
        return true;
    }

    // *********************************************************************
    // Procesos y auxiliares
    // *********************************************************************

    export function _GetDtNacimientoFixedTZ(alumno: Pick<Entidad.IAlumno, "IdKinder" | "FechaNacimiento">) {
        if (alumno.FechaNacimiento) {
            let timeZone = DataModuloMain._GetDataValueFieldByName("Escuela", alumno.IdKinder, "ZonaHoraria");
            return new DateV2(UIUtilTime._GetLocalDateFromInputDateString(alumno.FechaNacimiento))
                ._SetTimeZone(timeZone, true)
                ._ToISOString();
        }
        return null;
    }

    export function _UIUpdateFotoAlumno(container: TSelectionHTML<"div">, datum: IAlumno) {
        let perfilIMG = container.select<HTMLImage2Component>("wc-img");
        if (!perfilIMG.node() && !container.text("")) {
            container.text("");
        }
        setTimeout(() => {
            if (!perfilIMG.node()) {
                perfilIMG = container
                    .classed("alumno_foto_cont", true)
                    .append<HTMLImage2Component>("wc-img")
                    .attr("class", "alumno_thumbnail1")
                    // .attr("spinner-dim", "33px")
                    .attr("spinner-border-width", 3)
                    .attr("default-src", UIUtilIconResources.CMenuIco.Alumno);
            }
            perfilIMG
                .attr("spinner-border-color", _ALUMNOSEXOCOLOR_2[datum.Sexo])
                .attr("src", datum.getThumbnailURL)
                .node()
                .onclick = e => {
                    e.stopPropagation();
                    OpenModal_AlumnoFotoView(datum);
                };
        }, 100);

        _UIAlumnoLicenciaInfo(container, datum.getEdoLicencia, "oninfoico", 0);
    }

    function OpenModal_AlumnoFotoView(dato: IAlumno) { // , modulo: data.Entidades.CModulo) {
        const enableEdit = UIUtilPermission._HasAccionPermission(CAccionPermiso.Editar, CModulo.Alumnos, dato.IdKinder);

        const mainView = new FullViewFile()
            ._SetContent([{
                Id: dato.IdChild,
                Title: () => dato.NombreCompleto,
                Filename: () => dato.NombreCompleto,
                ThumbnailIMG: () => dato.getThumbnailURL,
                Download: () => _SvAlumnoURLObtenerFoto(dato.IdChild, 1) || false,
                Content: () => _SvAlumnoURLObtenerFoto(dato.IdChild, 1) || UIUtilIconResources.CMenuIco.Alumno,
                OnDraw: (_wrapper, content) => content
                    .style("width", !dato.FechaFoto ? "20%" : null)
                    .style("height", !dato.FechaFoto ? "20%" : null)
                    .style("margin", !dato.FechaFoto ? "auto" : null)
            }])

        if (!enableEdit) return;

        mainView._SetExtraOptions([{
            Icon: UIUtilIconResources.CGeneral.Upload,
            Description: UIUtilLang._GetUIString("alumnos", "tag_cambiarfoto"),
            Callback: () => InputFileDialog._Open({
                MaxFiles: 1,
                AcceptExtensions: [".png", ".jpg", ".jpeg"],
                OnStartProcess: () => mainView._ShowSpinner(),
                OnError: (e) => {
                    NotificacionV2._Mostrar(_L("general.notif_fail_fileprocessed"), "ADVERTENCIA");
                    mainView._RemoveSpinners();
                },
                OnLoad: ([file]) => {
                    mainView._RemoveSpinners();
                    let base64: string;
                    mainView._controlContainerSel.classed("hide", true);
                    const preView = new FullViewFile()
                        ._OnRemove(() => mainView._controlContainerSel.classed("hide", false))
                        ._SetContent([{
                            Id: dato.IdChild,
                            Title: UIUtilLang._GetUIString("general", "preview"),
                            Download: false,
                            Content: () => UIUtilGeneral._FileToBase64(file)
                                .then((str) => {
                                    base64 = str.split(",").pop();
                                    return file
                                })
                                .catch(() => {
                                    NotificacionV2._Mostrar(UIUtilLang._GetUIString("general", "notif_fail"), "ADVERTENCIA");
                                    preView._Destroy();
                                    return null;
                                })
                        }])
                        ._SetExtraOptions([
                            {
                                Icon: UIUtilIconResources.CGeneral.CloseInCircle,
                                Callback: () => preView._Destroy(),
                            },
                            {
                                Icon: UIUtilIconResources.CGeneral.AcceptInCircle,
                                Callback: async () => {
                                    preView._ShowSpinner();
                                    const res = await _SvAlumnoActualizarFotoPerfil(dato.IdChild, dato.IdKinder, base64);
                                    const resMessage = _HttpMsgV2(res);

                                    NotificacionV2._Mostrar(resMessage, res.Resultado > 0 ? "INFO" : "ADVERTENCIA");
                                    if (res.Resultado <= 0) {
                                        preView._RemoveSpinners();
                                        return
                                    }
                                    const resultSuccess = await MainPage._ReloadServiceAndAwaitBool(Entidad.CTipoRequest.Alumno, dato.IdKinder);
                                    if (!resultSuccess) {
                                        setTimeout(() => {
                                            NotificacionV2._Mostrar(UIUtilLang._GetUIString("general", "notif_fail_infosync"), "ADVERTENCIA");
                                        }, 2000);
                                    }
                                    mainView._Destroy();
                                    preView._Destroy();
                                    OpenModal_AlumnoFotoView(DataModuloMain._GetItemDataByName("Alumno", dato.IdChild));
                                }
                            },
                        ]);
                    preView._btnCloseNode.remove();
                }
            })
        }])
    }

    export function _UILicenciaColorBackLigth(edoLicencia: CAlumnoEdoLicencia): string {
        const colors = {
            [CAlumnoEdoLicencia.SinLicencia]: "var(--color_text1)",
            [CAlumnoEdoLicencia.Activo]: "var(--color_app_green1)",
            [CAlumnoEdoLicencia.Pausado]: "var(--color_text2)",
            [CAlumnoEdoLicencia.Vencido]: "var(--color_app_orange1)",
            [CAlumnoEdoLicencia.Caducado]: "var(--color_app_red1)",
        }
        return colors[edoLicencia]
    }
    export function _UILicenciaColorBackDark(edoLicencia: CAlumnoEdoLicencia): string {
        const colors = {
            [CAlumnoEdoLicencia.SinLicencia]: "var(--color_text1)",
            [CAlumnoEdoLicencia.Activo]: "var(--color_app_green1)",
            [CAlumnoEdoLicencia.Pausado]: "var(--color_text2)",
            [CAlumnoEdoLicencia.Vencido]: "var(--color_app_yellow1)",
            [CAlumnoEdoLicencia.Caducado]: "var(--color_app_orange1)",
        }
        return colors[edoLicencia]
    }

    /**
     * @param content Se le agregan los estilos:
     *      - position: relative;
     * @param EdoLicencia
     * @param TooltipMode default "oninfoico"
     *      - oncontent: el tooltip se agrega sobre el elemento param content.
     *      - oninfoico: el tooltip se agrega sobre el icono de info.
    */
    export function _UIAlumnoLicenciaInfo(Content: (HTMLElement | TSelectionHTML), EdoLicencia: CAlumnoEdoLicencia, TooltipMode: ("oncontent" | "oninfoico") = "oninfoico", Margin: number = 5, ForceOverflowVisible = false) {
        _UIAlumnoLicenciaInfoV2({ Content, EdoLicencia, TooltipMode, Margin, ForceOverflowVisible })
    }

    interface IConfigLicenciaBubble {
        Content: HTMLElement | TSelectionHTML
        EdoLicencia: CAlumnoEdoLicencia
        /** @default "oninfoico" */
        TooltipMode?: ("oncontent" | "oninfoico")
        /** @default 5px (css) */
        Margin?: number
        /** @default false */
        ForceOverflowVisible?: boolean
    }
    export function _UIAlumnoLicenciaInfoV2(config: IConfigLicenciaBubble) {
        const defaultCfg = <IConfigLicenciaBubble>{
            TooltipMode: "oninfoico",
            ForceOverflowVisible: false,
            Margin: -1,
        }
        config = { ...config, ...defaultCfg }
        let cont: d3.Selection<HTMLElement, any, any, any>;

        if (config.Content instanceof HTMLElement) {
            cont = d3.select(config.Content);
        } else {
            cont = config.Content;
        }
        if (!DataUtil._EvalItemEnvironment(DataModuloMain._GetModuleConfig(Entidad.CTipoRequest.Licencia).Environment, true)) {
            return;
        }

        cont.style("position", "relative");
        let divInfo = cont.select<HTMLDivElement>(":scope > .edo_licencia_info_bubble");

        if (config.ForceOverflowVisible) {
            cont.style("overflow", "visible");
        }

        if (!divInfo.node()) {
            divInfo = cont.append("div")
                .classed("edo_licencia_info_bubble", true)
                .style("left", (config.Margin == null ? null : config.Margin + "px"))
                .style("top", (config.Margin == null ? null : config.Margin + "px"));
            divInfo.node().onclick = e => e.stopPropagation();

            divInfo.append("img")
                .attr("src", UIUtilIconResources.CGeneral.InfoMark)

            if (config.TooltipMode == "oninfoico") {
                divInfo.append("wc-tooltip").classed("tooltip_lic_info", true).attr("position", "left");
            } else {
                cont.append("wc-tooltip").classed("tooltip_lic_info", true).attr("position", "left");
            }
        }

        const visible = [CAlumnoEdoLicencia.Vencido, CAlumnoEdoLicencia.Caducado].includes(config.EdoLicencia)
        divInfo.classed("hide", !visible);

        if (visible) {
            const tagsMap = {
                [CAlumnoEdoLicencia.Caducado]: _L("alumnos.tag_liccaducada_paga"),
                [CAlumnoEdoLicencia.Vencido]: _L("alumnos.tag_licvencida_paga"),
            }
            const colorText = _UILicenciaColorBackDark(config.EdoLicencia)
            const textLic = tagsMap[config.EdoLicencia]

            cont.select(".tooltip_lic_info")
                .html(`<span style="color: ${colorText};">${textLic}</span>`);

            divInfo.raise()
        }
    }

    // *********************************************************************
    // Validadores generales
    // *********************************************************************

    /**
     * @param alumnos todos deben de pertenecer a la misma escuela, escolaridad(nivel escolar) y grado
     * @param includeGroupsNoFounded
     * * IMPORTANT: Solo los grupos que pertenezcan a la escuela, escolaridad y grado actual de los alumnos
     * * Retorna todas las asignaciones de cada alumno (incluye a las que pudiera encontrar en alguna otra escuela (Poco probable))
    */
    export function _GetGruposInscritosValidos(alumnos: Array<IAlumno>, includeGroupsNoFounded: boolean = false) {
        // let grupos: data.Entidades.IGrupos[] = [];// data.Entidades.INinioHorarioAsignacion[] = [];
        let gruposValidosMap: Map<number, Entidad.IAlumnoHorarioGrupoAsignacion[]> = new Map(alumnos.map(d => ([d.IdChild, []])));
        let gruposNoValidosMap: Map<number, Entidad.IAlumnoHorarioGrupoAsignacion[]> = new Map(alumnos.map(d => ([d.IdChild, []])));
        let alumnosSonIscritos: Map<number, boolean> = new Map(alumnos.map(d => ([d.IdChild, false])));

        alumnos.forEach((dNinio) => {
            _LOCALDATA_GetGruposHorariosDeAlumno(dNinio.IdChild, null, includeGroupsNoFounded)
                .forEach((dGrupoInscrito) => {
                    // grupos.push(dGrupoInscrito);
                    if (dNinio.IdKinder == dGrupoInscrito.IdKinder) { //  && dNinio.IdGrado == dGrupoInscrito.IdNivel) {
                        gruposValidosMap.get(dNinio.IdChild)
                            .push(dGrupoInscrito);
                        alumnosSonIscritos.set(dNinio.IdChild, true);
                    } else {
                        gruposNoValidosMap.get(dNinio.IdChild)
                            .push(dGrupoInscrito);
                    }
                });
        });
        // return Array.from(grupos.values())//inscrito;
        return {
            /** * No son referencias de los grupos originales.
             * * Grupo válido: grupohorario.escuela == alumno.escuela
             * @returns Map<IdAlumno, Grupos[]>
            */
            GruposHorariosValidosMap: gruposValidosMap,
            /** * No son referencias de los grupos originales
             *
             * El caso ideal es que el alumno no tenga grupos inválidos.
             * Estos arreglos por alumno está destinados para tales validaciones.
             * * Grupo inválido: grupohorario.escuela != alumno.escuela
             * @returns Map<IdAlumno, Grupos[]>
            */
            GruposHorariosNoValidosMap: gruposNoValidosMap,
            /** Retorna true, solo si todos los alumnos tienen grupos válidos.
             * * Retorna true independientemente de si algún alumno tiene grupos inválidos
            */
            AllHaveValidGroups: Array.from(alumnosSonIscritos.values()).every(esInscrito => esInscrito)
        }
    }

    // *********************************************************************
    // Servicios
    // *********************************************************************

    export async function _SvActualizarFoto(datos: IAlumno, foto: string) {
        const res = await _SvAlumnoActualizarFotoPerfil(datos.IdChild, datos.IdKinder, foto);

        setTimeout(() => {
            const message = _HttpMsgV2(res);
            if (res.Resultado > 0) {
                NotificacionV2._Mostrar(message, "INFO");
            } else {
                NotificacionV2._Mostrar(message, "ADVERTENCIA");
            }
        }, 2000);

        return res;
    }

    export async function _SvCambiarEstadoEscolar(datoAlumno: IAlumno, estado: CNinioMovimiento) {
        // let res = await data.modulos.Ninio.fn_CambiarEstadoEscolar(datoAlumno.IdChild, estado);
        const params = Object.assign({}, datoAlumno);
        params.IdChildMovimiento = estado;
        const res = await _SvAlumnoEditar(params, estado == CNinioMovimiento.Activo && estado != datoAlumno.IdChildMovimiento);
        if (res.Resultado > 0 && datoAlumno.getThumbnailURL && (estado == CNinioMovimiento.BajaDefinitiva || estado == CNinioMovimiento.Graduado)) {
            DataCache._DeleteItemResourse(datoAlumno.getThumbnailURL);
        }
        return res;
    }

    /**
     * Proceso de actualización de un alumno, verifica cambios en los datos y lanza preguntas de confirmación.
     *
     * NOTA: No actualiza la foto del alumno
     *
     * @param initialData dato del alumno antes de editar
     * @param formUpdatedData nueva información de alumno que se desea editar
     * @param triggerReload si es verdadero se lanza el reloadService correspondiente a la petición realizada
     * @param onEndReload Se ejecuta al finalizar el reloadService
     * @returns
     * * Promesa null: Si no se realizó nungún request
     * * La promesa != null (ResultadoPetición): corresponde a la respuesta del servicio de Editar que se consumió: _SvCambiarEstadoEscolar | Sv_ActualizaRegistro.
     * Contiene el "Mensaje" (para usuario) correspondiente al servicio
     */
    export async function _ProcesoFinal_ActualizarAlumno(initialData: IAlumno, formUpdatedData: IAlumno, triggerReload = true, onEndReload?: (res: DataDRequest.IRequestResponseA) => void) { // , onServiceResolve: (res: data.DRequest.IResultadoPeticion<undefined>) => void) {
        return new Promise<(DataDRequest.IRequestResponseA | null)>(async (resolveFinal, reject) => {
            let res: DataDRequest.IRequestResponseA = null;
            const estadoOLD = initialData.IdChildMovimiento;
            const estadoNEW = formUpdatedData.IdChildMovimiento;
            let confirmacion = false;

            if (estadoNEW == CNinioMovimiento.BajaDefinitiva || estadoNEW == CNinioMovimiento.Graduado) {
                // (1) Si es baja definitiva o Graduado, todos los otros cambios del alumno son ignorados y solo se cambia el estado.
                // Son casos en los que el alumno se pierde en el limbo de los EnUso == false.

                if (estadoNEW == CNinioMovimiento.BajaDefinitiva) {
                    confirmacion = await ModalThings._GetConfirmacionBasico(
                        _L("alumnos.action_bajadefinit"),
                        _L("alumnos.confirma_bajadef_msg", initialData.NombreKinder)
                    );
                } else {
                    confirmacion = await ModalThings._GetConfirmacionBasico(
                        _L("alumnos.confirma_gradua_title"),
                        _L("alumnos.confirma_gradua_msg", initialData.NombreKinder)
                    );
                }

                console.debug(CNinioMovimiento[estadoNEW], confirmacion);
                if (confirmacion) {
                    res = await _SvCambiarEstadoEscolar(initialData, estadoNEW);

                    if (res.Resultado > 0) {
                        res.Mensaje = UIUtilLang._GetHTTPMessage(res);
                        if (triggerReload) {
                            await MainPage._ReloadServiceAndAwaitBool(Entidad.CTipoRequest.HorarioAlumno, initialData.IdKinder);
                            MainPage._ReloadService(Entidad.CTipoRequest.Alumno, initialData.IdKinder, (!onEndReload ? null : () => onEndReload(res)));
                        }
                    } else {
                        res.Mensaje = UIUtilLang._GetHTTPMessage(res, "editar");
                    }
                }
                resolveFinal(res);

            } else {
                // Si no, se consume el servicio para editar datos del alumno
                confirmacion = true;
                let removerHorariosManual = false;
                let currentHorarios = Array.from(_LOCALDATA_GetGruposHorariosDeAlumno(initialData.IdChild, null, true).values());

                if (initialData.IdKinder != formUpdatedData.IdKinder) {
                    // (2) Cambio de escuela - todas las asignaciones del alumno se resetean y el estado de alumno regresa a Preinscrito
                    confirmacion = await ModalThings._GetConfirmacionBasico(
                        _L("alumnos.confirma_cambioesc_title"),
                        _L("alumnos.confirma_cambioesc_msg"),
                        400
                    );

                } else {

                    if (initialData.IdGrado !== formUpdatedData.IdGrado && currentHorarios.length > 0) {
                        // (3.1) Confirmar cambio de Grado - junto con la actualización, se REMUEVEN las asignaciones de horarios del alumno.
                        // El servicio de Actualizar aun no realiza "desasignaciones" automáticas
                        const cambioNivel = initialData.IdEscolaridad !== formUpdatedData.IdEscolaridad;

                        if (cambioNivel) {
                            // El cambio de grado implica un cambio de escolaridad
                            confirmacion = await ModalThings._GetConfirmacionBasico(
                                _L("alumnos.confirma_cambionivel_title"),
                                _L("alumnos.confirma_cambionivel_msg"),
                                450
                            );
                            removerHorariosManual = confirmacion;
                        } else {
                            // Cambio de grado dentro de la misma escolaridad
                            confirmacion = await ModalThings._GetConfirmacionBasico(
                                _L("alumnos.confirma_cambiogrado_title"),
                                _L("alumnos.confirma_cambiogrado_msg"),
                                450
                            );
                            removerHorariosManual = confirmacion;
                        }
                    }

                    if (confirmacion && estadoOLD != estadoNEW) {
                        // (3.2) Confirmar cambio de estado
                        if (estadoNEW == CNinioMovimiento.BajaTemporal) {
                            // Confirmar baja temporal
                            confirmacion = await ModalThings._GetConfirmacionBasico(
                                _L("alumnos.confirma_bajatemp_title"),
                                _L("alumnos.confirma_bajatemp_msg", formUpdatedData.NombreCompleto)
                            );
                        }
                        else if (estadoNEW == CNinioMovimiento.Activo) {
                            let escuela = DataModuloEscuela._DiccFullEscuelas.get(formUpdatedData.IdKinder);
                            if (Global._LICENCIA.IdConfig > 0) {
                                let dtLicDtInicio = new Date(Global._LICENCIA.InicioUTC);
                                let dtToday = new DateV2()._SetTimeZone(escuela.ZonaHoraria, true);

                                let ymdInicioLicencia = UIUtilTime._GetValidatorDateYMD(dtLicDtInicio);
                                let ymdToday = UIUtilTime._GetValidatorDateYMD(dtToday);

                                // Confirmar activación de alumno (creación automática de licencias)
                                if (dtLicDtInicio && ymdToday >= ymdInicioLicencia) {
                                    confirmacion = await ModalThings._GetConfirmacionBasico(
                                        _L("alumnos.confirma_crearlicencia_title"),
                                        _L("alumnos.confirma_crearlicencia_msg", (Global._LICENCIA.DiasTolerancia + Global._LICENCIA.DiaAplicacion))
                                    );
                                }
                            }
                        }
                    }
                }

                if (confirmacion) {
                    res = await _SvAlumnoEditar(formUpdatedData, estadoNEW == CNinioMovimiento.Activo && estadoNEW != estadoOLD);
                    res.Mensaje = UIUtilLang._GetHTTPMessage(res, "Editar");
                    let requestHorarios = estadoNEW == CNinioMovimiento.BajaTemporal;

                    if (removerHorariosManual && res.Resultado > 0) {
                        // Eliminar todos los horarios que puede tener el alumno, los del grado actual y cualquiera que se pudo haber colado
                        // let horariosToDelete = currentHorarios.map(d => d.IdHorario)
                        const [horariosToDelete, gruposToDelete] = currentHorarios
                            // .filter(d => {
                            //     if (!cambioNivel) {
                            //         return initialData.IdGrado == d.IdGrado; // Solo horarios del grado anterior
                            //     }
                            //     const grupo = DataModuloMain.fn_GetItemDataByName("Grupo", d.IdGrupo);
                            //     if (!grupo) {
                            //         return true; // Sin grupo en dict
                            //     }
                            //     return initialData.IdEscolaridad == grupo.IdEscolaridad; // Solo horarios del nivel escolar anterior
                            // })
                            .reduce((([horariosID, gruposID], d) => {
                                horariosID.push(d.IdHorario);
                                gruposID.push(d.IdGrupo);
                                return [
                                    horariosID,
                                    gruposID
                                ]
                            }), [<number[]>[], <number[]>[]]);

                        let r = await DataModuloHorarioAlumno._ActualizarHorarioV3(initialData.IdKinder, initialData.IdChild, [], horariosToDelete);
                        if (r.Resultado <= 0) {
                            console.warn("-d", "Alumno editado, Horarios no removidos", horariosToDelete, currentHorarios, initialData);
                            DataUtilAlertBot._SendWarn("Editar alumno - error al remover grupos a alumno",
                                `\nAlumno: ${initialData.IdChild}` +
                                `\nHorarios: ${currentHorarios.map(d => d.IdHorario).toString()}` +
                                `\nHorariosEliminar: ${horariosToDelete.toString()}` +
                                `\nGruposEliminar: ${gruposToDelete.toString()}` +
                                `\nEdo: ${estadoOLD} -> ${estadoNEW}` +
                                `\nEscuela: ${initialData.IdKinder} -> ${formUpdatedData.IdKinder}` +
                                `\nNivel: ${initialData.IdEscolaridad} -> ${formUpdatedData.IdEscolaridad}` +
                                `\nGrado: ${initialData.IdGrado} -> ${formUpdatedData.IdGrado}`
                            )
                            ModalThings._GetConfirmacionBasicoV2({
                                Title: UIUtilLang._GetUIString("general", "notif_fail"),
                                Content: UIUtilLang._GetUIString("alumnos", "notif_cambionivelgrado_eliminagrupos_fail")
                            })
                        }
                        else if (triggerReload && !requestHorarios) {
                            requestHorarios = true;
                        }
                    }

                    if (requestHorarios) {
                        if (initialData.IdKinder == formUpdatedData.IdKinder) {
                            await MainPage._ReloadServiceAndAwaitBool(Entidad.CTipoRequest.HorarioAlumno, formUpdatedData.IdKinder);
                        } else {
                            await MainPage._ReloadServiceAndAwaitBool(Entidad.CTipoRequest.HorarioAlumno, [initialData.IdKinder, formUpdatedData.IdKinder]);
                        }
                    }

                    if (triggerReload && res.Resultado > 0) {
                        if (initialData.IdKinder == formUpdatedData.IdKinder) {
                            await MainPage._ReloadServiceAndAwaitBool(Entidad.CTipoRequest.Alumno, formUpdatedData.IdKinder);
                        } else {
                            await MainPage._ReloadServiceAndAwaitBool(Entidad.CTipoRequest.Alumno, [initialData.IdKinder, formUpdatedData.IdKinder])
                        }
                        setTimeout(() => {
                            if (onEndReload) onEndReload(res);
                        }, 50);
                    }
                }
                resolveFinal(res);
            }
        })
    }
}
