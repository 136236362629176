import { Selection, ascending, sum } from "d3";
import { group as d3Group } from "d3-array";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import { _FinanzasObtenerListaCargosPorAplicar } from "../../data/modulo/FinanzasCargosPorAplicar";
import { DateV2 } from "../../util/DateV2";
import _L from "../../util/Labels";
import { IConfigGridExcelExport, IGridExtraTableConfig, IGridRenderInfo, VentanaGrid } from "../controlD3/AVentanaGrid";
import { ElementWrapper } from "../controlD3/ElementWrapper";
import { ExcelThings } from "../controlD3/ExcelExport";
import { SelectV2 } from "../controlD3/SelectV2";
import { Table } from "../controlD3/Tabla";
import { UIUtilFormat } from "../util/Format";
import { UIUtilStrings } from "../util/Strings";
import { UIUtilTime } from "../util/Time";
import { UIUtilGeneral } from "../util/Util";
import { UIUtilViewData } from "../util/ViewData";
import { UIUtilViewFinanzasIngresoUtil } from "../utilView/FinanzasIngresoUtil";
import { CFinanzasTabs } from "./AlumnosPanelV2";

interface ICargoAplicar extends Entidad.IFinanzaCargoPorAplicar {
    NombreCompletoAlumno: string;
    KinderFiltro: number[];
    StrEscuela: string;
    TotalDescuento: number;
    TotalCobrar: number;
}

type IItemComboYear = UIUtilViewFinanzasIngresoUtil.IItemComboYear
type IItemComboMonth = UIUtilViewFinanzasIngresoUtil.IItemComboMonth

export class UIVentanaFinanzasCargosPorAplicar extends VentanaGrid<ICargoAplicar> {
    private comboYear: SelectV2<IItemComboYear, "Id", "multiselect">;
    private comboMonth: SelectV2<IItemComboMonth, "Id", "multiselect">;

    constructor(content: Selection<HTMLDivElement, any, HTMLElement, any>, modulo: Entidad.CModulo) {
        super(content, modulo, "finanzascargosporaplicar");
    }

    protected GridInitTable() {
        super.GridInitTable();
        this.AjustaDrawGrid();
    }

    private AjustaDrawGrid() {
        // Area combos-filtros
        let filterCont = this.ctrlTabla._Control.select(".filter_container");
        let searchwrapper = filterCont.select(".search-wrapper");

        let areaCombos = filterCont.append("div")
            .attr("class", "search-wrapper " + UIUtilGeneral.FBoxOrientation.Horizontal)
            // .style("height", "30px")
            .style("column-gap", "40px")
            .style("padding", "0 20px")
            .style("box-sizing", "border-box")

        searchwrapper.raise();

        this.comboYear = (() => {
            const select = new SelectV2<IItemComboYear, "Id", "multiselect">({
                Parent: areaCombos,
                Type: "multiselect",
                ValueMember: "Id",
                DisplayMember: "Name",
                ListWidth: "200px",
                Data: this.GetAniosDisponibles(),
                OnChange: () => {
                    const monthsA = [...this.comboMonth._dataitems.map(d => d.Id)]
                    this.comboMonth._RefreshList()
                    if (this.comboMonth._dataSelected.length == 0) {
                        this.comboMonth._SelectAll(true)
                    } else {
                        const monthsB = [...this.comboMonth._dataitems.map(d => d.Id)]
                        const monthsSelect: string[] = []
                        for (const mB of monthsB) {
                            if (monthsA.includes(mB)) {
                                continue
                            }
                            monthsSelect.push(mB)
                        }
                        this.comboMonth._valueSelect(this.comboMonth._dataValueMemberSelected.concat(...monthsSelect))
                    }
                    this.GridUpdateDataWithDelay();
                }
            })
            areaCombos.append(() => {
                return ElementWrapper._WrapperToSelectControl(select, _L("time.anio") + ":",
                    {
                        Borders: ["left", "right"]
                    })
                    .style("height", "35px")
                    .node();
            })
            return select
        })()

        this.comboMonth = (() => {
            const select = new SelectV2<IItemComboMonth, "Id", "multiselect">({
                Parent: areaCombos,
                Type: "multiselect",
                ValueMember: "Id",
                DisplayMember: "Name",
                ListWidth: "250px",
                Data: () => this.GetMesesDisponibles(),
                OnChange: () => {
                    // console.debug(item);
                    // this.forceDownloadData = false;
                    this.GridUpdateDataWithDelay()
                }
            })
            areaCombos.append(() => {
                return ElementWrapper._WrapperToSelectControl(select, _L("time.mes") + ":", {
                    Borders: ["left", "right"]
                }).node();
            })
            return select
        })()

        this.ctrlTabla._Control.select(".space_pie")
            .classed("hide", false)
            .style("display", "flex")
            .style("justify-content", "end")
            .text(_L("finanzascargosporaplicar.tag_totalcobrar") + ": " + UIUtilFormat._CurrencyFmt(0));

        // >> Filtro de año values
        this.comboYear._SelectAll(true)
        this.comboMonth._RefreshList()
        this.comboMonth._SelectAll(true)
    }

    private UpdateCurrentMonthTotal(data: Table.TDataItemTableAux<ICargoAplicar, undefined>[]) {
        const total = this.CalcularTotalCobrar("table", data);

        this.ctrlTabla._Control
            .select(".space_pie")
            .text(_L("finanzascargosporaplicar.tag_totalcobrar") + ": " + UIUtilFormat._CurrencyFmt(total));
    }

    public _OnServiceEvent(requestID: DataModuloMain.TipoRequestMonitorId) {
        super._OnServiceEvent(requestID);
        if (requestID == Entidad.CTipoRequest.Alumno) {
            this.GridUpdateData();
        }
    }

    // ***********************************************************************
    // Get data
    // ***********************************************************************

    private CalcularTotalCobrar<T extends "table" | "data">(type: T, datos: T extends "table" ? Table.TDataItemTableAux<ICargoAplicar>[] : ICargoAplicar[]): number {
        return datos.reduce((total, d) => (type == "table"
            ? (total + (d as Table.TDataItemTableAux<ICargoAplicar>).data.TotalCobrar)
            : (total + (d as ICargoAplicar).TotalCobrar)), 0)
    }

    /** Del año actual hasta 6 años en el futuro */
    private GetAniosDisponibles(): IItemComboYear[] {
        const currentDt = new Date();
        const maxYearsCount = 6;
        const maxYear = (currentDt.getFullYear() + maxYearsCount);
        const years: IItemComboYear[] = [];

        for (let y = currentDt.getFullYear(); y < maxYear; y++) {
            years.push({
                Id: y,
                Name: y.toString()
            })
        }

        // let ciclosEscolares = Array.from(data.modulos.CicloEscolar.DiccCicloEscolar.values())
        //     .filter(cicloE => Boolean(this.kinders.find(esc => (esc.IdKinder == cicloE.IdEscuela))));

        return years; //.sort((a, b) => (b.Id - a.Id));
    }

    private GetMesesDisponibles(): IItemComboMonth[] {
        const years = this.comboYear._dataValueMemberSelected
        let result: IItemComboMonth[] = []
        for (const year of years) {
            const mesesDisponibles = UIUtilViewData._GetMonthsByYearCurrentMonthLimit(year, "startsInCurrentDtMonth");
            result = result.concat(...mesesDisponibles.map<IItemComboMonth>(({ Id: month }) => {
                const dt = new Date(year, month, 2)
                return {
                    Id: (year + "-" + month),
                    Year: year,
                    Month: month,
                    Name: UIUtilStrings._CapitaliceString(UIUtilTime._DateFormatStandar(dt, "MMM yyyy"))
                }
            }))
        }
        return result
    }

    // ***********************************************************************
    // Table config
    // ***********************************************************************

    protected GRID_GetMenuTopGrid(): Table.ITableMenuTopDefaultOptionConfig[] {
        return null;
    }
    protected GRID_GetSelectionDataMenuV2(menuLocation: "row" | "top-selected", dataGridSelected: ICargoAplicar[]): Table.ITableMenuDataSelectedOptionConfig<ICargoAplicar>[] {
        return null;
    }
    protected GRID_GetDataRequestID(): DataModuloMain.TipoRequestMonitorId {
        return null;
    }

    protected GRID_GetTableConfigBase(): IGridRenderInfo<ICargoAplicar> {
        return {
            IdTabla: "FinanzasIngresos-CargosPorAplicar",
            Title: "",
            DefaultSort: "NombreCompletoAlumno",
            IdData: "Id",
            MinWidth: 1200,
            Columns: [
                { Field: "NombreCompletoAlumno", Label: "Alumn@", MinWidth: "100px", Width: "15%" },
                { Field: "Valor", Label: "Total de cargo", MinWidth: "80px", Width: "9%" },
                { Field: "TotalDescuento", Label: "Total de descuento", MinWidth: "90px", Width: "9%" },
                { Field: "TotalCobrar", Label: "Total por cobrar", MinWidth: "80px", Width: "9%" },
                { Field: "Cargo", Label: "Concepto", MinWidth: "80px", Width: "12%" },
                { Field: "FechaAplicacion", Label: "Fecha aplicación", MinWidth: "90px", Width: "8%" },
                { Field: "StrEscuela", Label: "Escuela", MinWidth: "80px", Width: "8%" },
            ]
        }
    }

    protected GRID_GetTableConfigAdvanced(): IGridExtraTableConfig<ICargoAplicar> {
        return {
            OnEndUpdateDataInView: (currentDataInTable, dataChecked) => {
                let pagos: Table.TDataItemTableAux<ICargoAplicar, undefined>[] = [
                    ...currentDataInTable,
                    ...dataChecked
                ];

                this.UpdateCurrentMonthTotal(pagos);
            },
            EvaluatorAndSubLevelsBuild: <Table.IStepEvaluator<ICargoAplicar, undefined, any>>{
                OnStepCellTable: (container, cargo, field: (keyof ICargoAplicar)) => {
                    switch (field) {
                        case "NombreCompletoAlumno":
                            UIUtilGeneral._ElementAdd_LinkToGoToPanel(container, "alumnos/alumnos/panel", cargo.IdAlumno);
                            break;
                        //@ts-expect-error
                        case "TotalDescuento":
                            UIUtilGeneral._ElementAdd_LinkToGoToPanel(container, "alumnos/alumnos/panel", cargo.IdAlumno, null, CFinanzasTabs.CargosyDesc);
                        case "Valor":
                        case "TotalCobrar":
                            container.text(UIUtilFormat._CurrencyFmt(cargo[field] || 0));
                            break;
                        case "FechaAplicacion":
                            container.text(UIUtilTime._DateFormatStandar(new DateV2(cargo[field])._SetTimeZoneByIdSchool(cargo.KinderFiltro[0])));
                            break;
                    }
                }
            }
        }
    }

    protected GRID_GetFilters(): Array<Table.IParametroFiltro<ICargoAplicar>> {
        return [
            { Label: "Nombre", Field: "NombreCompletoAlumno" },
            { Label: "Total de cargo $", Field: "Valor", Type: "number" },
            { Label: "Total de descuento $", Field: "TotalDescuento", Type: "number" },
            { Label: "Total por cobrar $", Field: "TotalCobrar", Type: "number" },
            {
                Label: "Concepto", Field: "Cargo", Type: "select",
                Options: () => DataModuloMain._GetReqDataArrayByName("FinanzaCargo")
                    .map(d => ({ Id: d.Nombre, Name: d.Nombre }))
                    .concat(this.ctrlTabla._data.map(d => ({ Id: d.Cargo, Name: d.Cargo })))
                    .filter(d => (!!d.Name))
                    .sort((a, b) => ascending(a.Name.toLowerCase(), b.Name.toLowerCase())),
            },
            // { Label: "Fecha aplicación", Field: "FechaAplicacion", IsDate: true, Level: 1 }, // TEMPORAL Descomentar despues de arreglar el filtro para fechas futuras
            // { Label: "Fecha vencimiento", Field: "FechaFin", IsDate: true, Level: 1 }
        ]
    }

    protected async GridGetData() {
        // console.warn("GridGetData"); // FIXME Al crear la ventana, se repiten dos veces las peticiones. Origen > VentanaGrid, SelectControlAño
        const res = await this.GetPagosPendientesList()
        if (res.Resultado <= 0) {
            this.notificacion._Mostrar(_L("general.notif_fail_infoupdate"), "ADVERTENCIA")
        }
        return (res.Datos || []);
    }

    // ***********************************************************************
    // Formulario things
    // ***********************************************************************

    // ***********************************************************************
    // Data final things
    // ***********************************************************************

    private async GetPagosPendientesList(): Promise<{ Resultado: number, Datos?: ICargoAplicar[] }> {
        // >> Get filter dates
        const monthFilter = this.comboMonth._dataSelected
        if (monthFilter.length == 0) {
            return {
                Resultado: 1
            }
        }
        const [dtA, dtB] = (() => {
            const minMonth = monthFilter[0]
            const maxMonth = monthFilter[monthFilter.length - 1]
            return [
                new Date(minMonth.Year, minMonth.Month, 1),
                new Date(maxMonth.Year, maxMonth.Month + 1, 1, 0, 0, 0, -1)
            ]
        })()

        // >> Get Request
        // const escuelas = Array.from(DataModuloEscuela._DiccEscuela.values());
        let pagosrecibidos: ICargoAplicar[] = [];
        let resultado = 1;

        for (let { IdKinder, Nombre: NombreEscuela } of this.kinders) {
            const res = await _FinanzasObtenerListaCargosPorAplicar(IdKinder, dtA, dtB)
            if (res.Resultado <= 0) {
                resultado = -1
                continue
            }
            for (const cargoAplicar of res.Datos) {
                const dtAplicacion = new DateV2(cargoAplicar.FechaAplicacion)._SetTimeZoneByIdSchool(IdKinder)
                const idValid = monthFilter.some(f => (dtAplicacion.getFullYear() == f.Year && dtAplicacion.getMonth() == f.Month))
                if (!idValid) {
                    continue
                }
                const alumno = DataModuloMain._GetReqDataMapByName("Alumno").get(cargoAplicar.IdAlumno)
                if (!alumno) {
                    console.warn("-d", "Student no found", cargoAplicar.IdAlumno)
                    continue
                }
                const totalDescuento = sum(cargoAplicar.Detalles.map(d => d.Descuento))
                pagosrecibidos.push({
                    ...<ICargoAplicar>{
                        NombreCompletoAlumno: alumno.NombreCompleto,
                        KinderFiltro: [IdKinder], // FIXME (1) Los pagos van a "cambiar de escuela" si se cambia al alumno. (2) Los pagos no se verán cuando el alumno no esté
                        StrEscuela: NombreEscuela,

                        TotalDescuento: totalDescuento,
                        TotalCobrar: (cargoAplicar.Valor - totalDescuento)
                    },
                    ...cargoAplicar
                })
            }
        }
        return {
            Datos: pagosrecibidos,
            Resultado: resultado
        }
    }

    protected GRID_GetExportarConfig(dataGrid: ICargoAplicar[]): IConfigGridExcelExport<any> {
        type IDataToExportPick = Pick<ICargoAplicar,
            "NombreCompletoAlumno"
            | "Valor"
            | "TotalDescuento"
            | "TotalCobrar"
            | "Cargo"
            | "FechaAplicacion"
            | "StrEscuela">;
        type IDataToExportPickAux = { [k in keyof IDataToExportPick]: string };
        interface IDataToExport extends IDataToExportPickAux {
            IdEscuela: number;
        }

        let idsEscuelas = [...new Set(dataGrid.map(d => d.KinderFiltro[0]))];

        return <IConfigGridExcelExport<IDataToExport>>{
            IdsEscuelas: idsEscuelas,
            ColumnsConfig: this.ctrlTabla
                ._InfoColumns
                .map<ExcelThings.IColumnToExcelExportFileConfig<IDataToExport>>(d => ({
                    Field: d.Field as keyof IDataToExport,
                    HeaderTag: d.Label,
                    WidthCell: d.Field == "NombreCompletoAlumno" ? 35 : 25
                })),
            OnGetDataBySheets: async () => {
                const groupedDataExport: Map<number, IDataToExport[]> = new Map(idsEscuelas.map(idEscuela => ([idEscuela, []])));
                const groupedDataCalc = d3Group(dataGrid, d => d.KinderFiltro[0])

                for (let item of dataGrid) {
                    let dPago = this.ctrlTabla._SelectItemData(item.Id);
                    let idEscuela = dPago.Data.KinderFiltro[0];
                    let datosEnEscuela = groupedDataExport.get(idEscuela);

                    // await this.GetAsignacionCargoByAlumno(dPago.Data.IdAlumno, dPago.Data.IdCargo)
                    //     .then(asignacion => {
                    //         dPago.Data.Cargo = (asignacion?.Nombre || _L("general.nodisponible"));
                    //     })

                    datosEnEscuela.push({
                        IdEscuela: idEscuela,
                        NombreCompletoAlumno: dPago.Data.NombreCompletoAlumno,
                        Valor: UIUtilFormat._CurrencyFmt(dPago.Data.Valor),
                        TotalDescuento: UIUtilFormat._CurrencyFmt(dPago.Data.TotalDescuento),
                        TotalCobrar: UIUtilFormat._CurrencyFmt(dPago.Data.TotalCobrar),
                        Cargo: dPago.Data.Cargo,
                        FechaAplicacion: UIUtilTime._DateFormatStandar(new DateV2(dPago.Data.FechaAplicacion)._SetTimeZoneByIdSchool(idEscuela)),
                        StrEscuela: dPago.Data.StrEscuela
                    });
                }

                return Array.from(groupedDataExport)
                    .map(([idEscuela, datosEscuela]) => ({
                        IdSheet: idEscuela,
                        SheetName: datosEscuela[0].StrEscuela,
                        Data: datosEscuela,
                        ExtraPie: [{
                            Label: _L("finanzascargosporaplicar.tag_totalcobrar"),
                            Value: UIUtilFormat._CurrencyFmt(this.CalcularTotalCobrar("data", groupedDataCalc.get(idEscuela))),
                            Alignment: "right",
                        }]
                    }));
            },
            OnGetEscuelasTagInSheet: (datos) => datos[0].StrEscuela
        }
    }
}
