export namespace DataUtilDevice {
    interface IDeviceInfo {
        readonly IMEI: string;
        /**
         * * 1: iOS
         * * 2: Android
         * * 3: Web
         * */
        readonly MobileType: 1 | 2 | 3;

    }
    let deviceInfo: IDeviceInfo;
    export function _GetUUID_Random() {
        return 'xxxxxx-xxxx-xxxx-xxxxx'.replace(/[x]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    export function _GetAppInfo() {
        // if (isMobile) {
        //     let dataRes = Mobile._LoadAPPInfo();
        //     if (dataRes) Mobile._OnSetAPPInfo(dataRes);
        // } else {

        //Default App Values
        if (!deviceInfo) {
            deviceInfo = {
                IMEI: _GetUUID_Random(),
                MobileType: 3
            }
        }

        return deviceInfo;
    }

    export function _GetUserAgent() {
        let sBrowser, sUsrAg = navigator.userAgent;

        if (sUsrAg.indexOf("Chrome") > -1) {
            sBrowser = "Google Chrome";
        } else if (sUsrAg.indexOf("Safari") > -1) {
            sBrowser = "Apple Safari";
        } else if (sUsrAg.indexOf("Opera") > -1) {
            sBrowser = "Opera";
        } else if (sUsrAg.indexOf("Firefox") > -1) {
            sBrowser = "Mozilla Firefox";
        } else if (sUsrAg.indexOf("MSIE") > -1) {
            sBrowser = "Microsoft Internet Explorer";
        } else {
            sBrowser = "UserAgent Unknow";
        }

        return sBrowser;
    }
}