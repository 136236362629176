import { Main } from "../../Main"
import { Entidad } from "../../data/Entidad"
import { DataUtilLocalStorage } from "../../data/util/LocalStorage"
import { DataUtil } from "../../data/util/Util"
import _L from "../../util/Labels"
import { _ShowLoaderMessage } from "../controlD3/FullLoader"
import { UIVentanaLogin } from "../ventana/Login"
import { UIWindowManager } from "../ventana/WindowManager"

export namespace ChangeSesionController {
    interface IUserSesionRequired {
        idRequired: number
        user: string
        pwd: string
    }

    export async function _SaveAndEvalRequiredSesion(userRequired: IUserSesionRequired): Promise<boolean> {
        if (!userRequired || !userRequired.idRequired)
            return false

        DataUtilLocalStorage._SetItem("sesionadvanced", "userrequired", JSON.stringify(userRequired))
        console.warn("ChangeSesionController 1.userRequired", userRequired);
        const loaderView = _ShowLoaderMessage(_L("general.loading"));

        const { idRequired, user, pwd } = userRequired
        userRequired = null
        const [_, dbUser]: [boolean, Entidad.IUsuarioSesion] = await DataUtil._GetInfoUsuario().catch(err => ([null, null]))

        if (dbUser?.IdUsuario == idRequired) {
            DataUtilLocalStorage._RemoveItem("sesionadvanced", "userrequired")
            loaderView.remove()
            return true
        }
        console.warn("ChangeSesionController 2.userExist", dbUser?.IdUsuario);
        if (dbUser?.IdUsuario) {
            Main._Logout(false)
            return true
        }
        console.warn("ChangeSesionController 4.StartSesion");
        _EvalAndStartRequiredSesion()
        return true
    }

    /** Solo se invoca desde el Login */
    export function _EvalAndStartRequiredSesion(): boolean {
        const savedValue = DataUtilLocalStorage._GetItem("sesionadvanced", "userrequired")
        if (!savedValue) return false
        setTimeout(() => {
            const userRequired: IUserSesionRequired = JSON.parse(savedValue)

            console.warn("ChangeSesionController 4.FromLogin", !!UIWindowManager._lastInstance);
            DataUtilLocalStorage._RemoveItem("sesionadvanced", "userrequired")
            UIVentanaLogin._IniciarLoginExtern(UIWindowManager._lastInstance as UIVentanaLogin, userRequired.user, userRequired.pwd)
        }, 500);
        return true
    }
}