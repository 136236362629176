import { json } from "d3";
export namespace UIUtilMimeType {
    interface IMimeInfoItem {
        /** Ej: "iana" */
        "source": string;
        "compressible": boolean | undefined;
        /** Ej: ["mp4s","m4p"] */
        "extensions": string[] | undefined;
    }

    var _MIMETYPES: Map<string, IMimeInfoItem> = new Map();

    /** // NOTE BD obtenida de: https://cdn.jsdelivr.net/gh/jshttp/mime-db@master/db.json  */
    export async function _Init() {
        let urlRes = "cfg/mimetypes-db.json";

        let infoObject = await json(urlRes) as { [keyA: string]: IMimeInfoItem };
        for (let k in infoObject) {
            _MIMETYPES.set(k, infoObject[k]);
        }
    }

    /** @returns Ej: ["mp4s","m4p"] */
    export function _GetExtensionsFromMimeType(mimetype: string): string[] {
        let info = _MIMETYPES.get(mimetype);
        if (info?.extensions?.length) {
            return info.extensions;
        } else {
            console.warn("-d", `MimeType error: Info (ext) de ${mimetype} no encontrado.`, info);
            return null;
        }
    }

    export function _GetExtensionFromMimeType(mimetype: string): string {
        let extensions = _GetExtensionsFromMimeType(mimetype);
        if (extensions) {
            return extensions[0];
        }
        return null;
    }
}
