import * as d3 from "d3";
import { UIUtilElementBehaviors } from "../util/ElementBehaviors";
import { SelectV2 } from "./SelectV2";

export namespace ElementWrapper {

    function WrapperToElement(element: HTMLElement, text: string, pasteOnParent: boolean) {
        let wrapper = d3.create("div")
            .attr("class", "element_wrapper combo_wrapper");

        if (text) {
            wrapper.append("div")
                .attr("class", "area_label")
                .append("label")
                .text(text)
                .call(cont => UIUtilElementBehaviors._EllipsisTextTooltip(cont.node(), "button"));
        }


        if (pasteOnParent) {
            // element.parentElement.append(wrapper.node());
            element.parentElement.insertBefore(wrapper.node(), element);
        }

        wrapper.append("div")
            .attr("class", "area_element")
            .append(() => element);

        return wrapper;
    }

    type TBorder = "top" | "bottom" | "left" | "right"
    interface IExtraConfig {
        /** @default ["top", "bottom", "left", "right"] */
        Borders: [TBorder] | [TBorder, TBorder] | [TBorder, TBorder, TBorder] | [TBorder, TBorder, TBorder, TBorder]
    }

    export function _WrapperToSelectControl(control: (SelectV2<any, any, "monoselect" | "multiselect">), text?: string, extraConfig?: IExtraConfig): TSelectionHTML<"div"> {
        let wrapper = WrapperToElement(control._controlSelection.node(), text, false);

        if (extraConfig?.Borders) {
            wrapper.style("border", "none");
            extraConfig.Borders.forEach(direction => {
                wrapper.style("border-" + direction, "#c8c8c8 solid 1px");
            })
        }

        return wrapper;
    }

    export function _WrapperToElement(element: (HTMLElement | d3.Selection<HTMLElement, any, any, any>), text?: string, extraConfig?: IExtraConfig) {
        let elem = element instanceof HTMLElement ? element : element.node();
        let wrapper = WrapperToElement(elem, text, true);

        if (extraConfig?.Borders) {
            wrapper.style("border", "none");
            extraConfig.Borders.forEach(direction => {
                wrapper.style("border-" + direction, "#c8c8c8 solid 1px");
            })
        }

        return wrapper;
    }
}
