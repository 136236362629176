export namespace DataCache {
    const hostName = location.hostname;
    const cacheVersion = 1;
    const cacheName = `${hostName}-${cacheVersion}`;
    const variantForCacheItem = "Modificacion";
    const imageSizesToAccept = [3];
    const debugPrefix = hostName + "-cache-v" + cacheVersion + " -> ";
    // const expirationTime = ? // 30 min

    export async function _DeleteCacheStorage() {
        await DeleteOldCaches();
        return caches.delete(cacheName);
    }

    export async function _DeleteItemResourse(url: string) {
        const cacheStorage = await caches.open(cacheName);
        const cachedResponse = await cacheStorage.match(url);

        if (!cachedResponse) {
            return false;
        }

        return cacheStorage.delete(url);
    }

    /** Try to get data from the cache, but fall back to fetching it live if params are accepted
     * * Only to GET request */
    export async function _GetData(url: string) {
        let cachedData = await GetCachedData(url);

        if (cachedData) {
            // console.log(debugPrefix, 'Retrieved cached data');
            return cachedData;
        }

        await FetchAndSaveData(url);
        await DeleteOldCaches();

        // return cachedData;
        return await GetCachedData(url);
    }

    async function FetchAndSaveData(url: string): Promise<boolean> {
        const cacheStorage = await caches.open(cacheName);
        const params = GetParams(url);
        const endPoint = url.split("?")[0];

        if (params) {
            if (imageSizesToAccept?.length > 0 && params.hasOwnProperty("Tamanio") && imageSizesToAccept.indexOf(Number(params["Tamanio"])) == -1) {
                console.debug(debugPrefix, `File size <${params["Tamanio"]}> cannot be saved! -> `, endPoint, params);
                return false;
            }

            if (variantForCacheItem && params.hasOwnProperty(variantForCacheItem)) {
                const items = await cacheStorage.keys();

                for (const item of items) {
                    if (item.url.startsWith(endPoint)) {
                        let paramsFromUrlExisted = GetParams(item.url);
                        if (params[variantForCacheItem] != paramsFromUrlExisted[variantForCacheItem] && ObjectsHasSameParams(params, paramsFromUrlExisted)) {
                            let deleted = await cacheStorage.delete(item.url);
                            console.debug(debugPrefix, "Delete old resourse", deleted, endPoint, "OLD:", paramsFromUrlExisted); // , "NEW:", params);
                        }
                    }
                }
            }
        }

        // console.log(debugPrefix, 'Fetching fresh data', endPoint, params);
        await cacheStorage.add(url);
        return true;
    }

    // Get data from the cache.
    async function GetCachedData(url): Promise<Response> {
        const cacheStorage = await caches.open(cacheName);
        const cachedResponse = await cacheStorage.match(url);

        if (!cachedResponse || !cachedResponse.ok) {
            return null;
        }

        return await cachedResponse; // .json();
    }

    // Delete any old caches to respect user's disk space.
    async function DeleteOldCaches() {
        const keys = await caches.keys();

        for (const key of keys) {
            const isOurCache = key.startsWith(hostName);

            if (cacheName === key || !isOurCache) {
                continue;
            }

            caches.delete(key);
        }
    }

    function GetParams(url: string): Object {
        let strParams = url.split("?")[1];
        if (strParams) {
            let objParams = {};
            for (let item of strParams.split("&")) {
                let param = item.split("=")[0];
                let value = item.split("=")[1];
                objParams[param] = value;
            }
            return objParams;
        }
        return null;
    }

    function ObjectsHasSameParams(obj1: Object, obj2: Object): boolean {
        for (let key in obj1) {
            if (key != variantForCacheItem && key != "Tamanio") {
                if (obj1[key] != obj2[key]) {
                    return false;
                }
            }
        }
        return true;
    }
}
