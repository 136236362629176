import { DataDRequest } from "../DRequest";
import { Global } from "../Global";
import { IFacturaSerie } from "../entidad/Factura";

// type SerieType = "factura"
export function _ObtenerSeries(idEscuela: number) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "factura/ObtenerSeries"
    const params = {
        "IdEscuela": idEscuela,
        "Tipo": "factura",
    }
    return DataDRequest._Request5<IFacturaSerie[]>(url, "GET", params)
}