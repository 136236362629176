import { UIUtilColor } from "../util/Color";

const TEMPLATE = document.createElement("template")
TEMPLATE.innerHTML = `<style>
:host {
    display: inline-block;
    white-space: normal;
}

:host * {
    box-sizing: border-box;
}

.wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
}

.wrapper > .sheetbackground {
    width: 100%;
    height: 100%;
    background-color: #e2e5e7;
    border-radius: 10%;
    position: relative;
    overflow: hidden;
    padding-left: 10%;
}

/*
.wrapper > .sheetbackground > .doblado {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-radius: 0 0 0 4px;
    background-color: #b0b7bd;
    border-bottom: solid #b0b7bd;
    border-left: solid #b0b7bd;
    border-top: solid white;
    border-right: solid white;
    border-width: .8vmin;
    box-shadow: gray 3px 2px 8px 0px;
}
*/

.wrapper .ext {
    width: 80%;
    height: 30%;
    background-color: gray;
    border-radius: 10%;
    position: absolute;
    top: 50%;
    left: 5%;
    color: white;
    font-family: 'Lato';
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 65%;
    box-shadow: gray 1px 2px 4px 0px;
    overflow: hidden;
}

.wrapper .filename {
    font-size: 10px;
    text-align: center;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

</style>
<div class="wrapper">
    <div class="sheetbackground">
        <!-- <div class="doblado"></div> -->
        <div class="ext"></div>
    </div>
    <label class="filename"></label>
</div>`
/** `wc-fileico`
 * Attributes
 * * `ext`: ".pdf" | "pdf"
 * * `ext-color`: string
 * * `filename`: "archido.pdf"
 */
export class HTMLFileIcoElement extends HTMLElement {
    constructor() {
        super();
        this.attachShadow({ mode: "open" });
        this.shadowRoot.append(TEMPLATE.content.cloneNode(true));
        this.UI_ApplyAttrs();
    }

    // *****************************************************************************
    // PRIVATE METHODS
    // *****************************************************************************

    private UI_ApplyAttrs() {
        const lblExtension = this.shadowRoot.querySelector<HTMLDivElement>(".ext");
        const lblFileName = this.shadowRoot.querySelector<HTMLElement>(".filename");
        const textFileName = this.getAttribute("filename");
        const textExtension = this.getAttribute("ext");

        lblExtension.textContent = textExtension || "";
        lblExtension.style.backgroundColor = this.getAttribute("ext-color") || this.UI_GetTagColor(textExtension || "");
        lblFileName.style.display = textFileName ? "block" : "none";
        lblFileName.textContent = textFileName || "";
    }

    private UI_GetTagColor(ext: string = null) {
        const COLOR_EXTENSION = {
            pdf: "#f15642", // red
            avi: "#f15642",
            ppt: "#f1950a", // orange
            pptx: "#f1950a",
            xls: "#84BD5A", // green-light
            xlsx: "#84BD5A",
            zip: "#84BD5A",
            html: "#84BD5A",
            eps: "#F7B84E", // yellow
            flv: "#F7B84E",
            svg: "#F7B84E",
            css: "#F7B84E",
            dmg: "#576D7E", // blue-dark
            dll: "#576D7E",
            php: "#576D7E",
            js: "#576D7E",
            ts: "#576D7E",
            txt: "#576D7E",
            raw: "#576D7E",
            indd: "#A066AA", // purple
            png: "#A066AA",
            gif: "#A066AA",
            doc: "#50BEE8", // blue-light
            docx: "#50BEE8",
            xml: "#50BEE8",
            jpg: "#50BEE8",
            jpeg: "#50BEE8",
            psd: "#50BEE8",
            mp3: "#50BEE8",
        }
        return COLOR_EXTENSION[ext?.replace(".", "")?.toLowerCase()] || UIUtilColor._StringToRGB(ext, .6); /*|| "gray" */
    }

    // *****************************************************************************
    // ELEMENT LIFE CICLE CALLBACKS
    // *****************************************************************************

    /** Se invoca cuando uno de los atributos del elemento se ha añadido, quitado o cambiado */
    attributeChangedCallback(attrName: string, oldVal: string, newVal: string, otro, mas) {
        this.UI_ApplyAttrs();
    }

    /** Se invoca cada vez que el elemento se añade a un documento */
    connectedCallback() {
    }

    static get observedAttributes() {
        return ["ext", "ext-color", "filename"];
    }

    // *****************************************************************************
    // PUBLIC PROPERTIES
    // *****************************************************************************

    get _Ext() {
        return this.getAttribute("ext");
    }
    set _Ext(val: string) {
        this.setAttribute("ext", val);
    }

    get _Filename() {
        return this.getAttribute("filename");
    }
    set _Filename(val: string) {
        this.setAttribute("filename", val);
    }

    set _LblSize(val: string) {
        const lblExtension = this.shadowRoot.querySelector<HTMLDivElement>(".ext");
        const lblFileName = this.shadowRoot.querySelector<HTMLElement>(".filename");
        if (lblExtension) lblExtension.style.fontSize = val;
        if (lblFileName) lblFileName.style.fontSize = val;
    }
}

customElements.define("wc-fileico", HTMLFileIcoElement);
