import { Entidad } from "../Entidad";
import { DataUtil } from "./Util";
import { DataModuloMain } from "../ModuloMain";
import DataModuloPermisos, { IPermisosDisponiblesDatosResponse } from "../modulo/Permisos";
import { DataIndexedDB } from "../indexedDB/DB";
import DataModuloUsuario from "../modulo/Usuario";
import { DMUsuarioSesion } from "../model/MUsuarioSesion";
import { group as d3Group } from "d3-array";

export namespace DataUtilPermission {
    /** Map<IdEscuela, Map<IdModulo, Map<IdAccion, @interface Entidades.IPermisosAccion>>> */
    export var _DiccPermisos: Entidad.TUserPermisosMap = new Map();

    /** Si es Super usuario, devuelve todas los Ids Disponibles */
    export function _GetIdsEscuelasAcceso(/* diccPermisos: Entidades.TUserPermisosMap*/) {
        let idsEscuelasPermisos: number[] = [];
        if (DataUtil._Usuario.Perfil !== Entidad.CTipoPerfil.Admin) {
            _DiccPermisos?.forEach((permisosModulos, idEscuela) => {
                idsEscuelasPermisos.push(idEscuela);
            })
        } else {
            idsEscuelasPermisos = DataModuloMain._GetReqDataArrayByName("Escuela")
                .map(d => d.IdKinder);
        }
        return idsEscuelasPermisos;
    }
    export async function _GuardarPermisosUsuario(permiso: Array<Entidad.IPermisoAsignacionUser>): Promise<boolean> {
        //let permisosUsuario: Entidad.IPermisoAsignacionUser[] = await Data.IndexedDB.DB.fn_GetRows("tblPermisosCurrentUser");
        //permisosUsuario = MapArraysEnUsoValues(permisosUsuario, permiso, "IdAsignacionPermiso", flag);

        return await DataIndexedDB._SetRowsV2({ storeName: "tblPermisosCurrentUser", listRows: permiso })
            .catch(() => false);
    }

    export async function _GuardarPermisosDisponibles(dataPermisosDisponibles: IPermisosDisponiblesDatosResponse): Promise<boolean> {
        let resA = await DataIndexedDB._SetRowsV2({ storeName: "tblAcciones", listRows: dataPermisosDisponibles.Acciones })
            .catch(() => false);

        let resM = await DataIndexedDB._SetRowsV2({ storeName: "tblModulos", listRows: dataPermisosDisponibles.Modulos })
            .catch(() => false);

        let resP = await DataIndexedDB._SetRowsV2({ storeName: "tblPermisosDisponibles", listRows: dataPermisosDisponibles.PermisosDisponibles })
            .catch(() => false);

        return resA && resM && resP;
    }

    function MapArraysEnUsoValues<T extends { EnUso?: boolean }>(currentData: T[], updatedData: T[], id: keyof T) {
        const debugUpdatedData = [];
        const debugAddedData = [];
        const dataMapped: T[] = [];

        // >> Actualiza existentes (EnUso)
        currentData
            .forEach(d => {
                const itemUpdater = updatedData.find(dA => (dA[id] == d[id]));
                const exist = Boolean(itemUpdater);

                if (d.EnUso != exist) {
                    debugUpdatedData.push([d, d.EnUso, exist]);
                }

                if (exist) {
                    itemUpdater.EnUso = true;
                    dataMapped.push(itemUpdater);
                } else {
                    d.EnUso = false;
                    dataMapped.push(d);
                }
            })

        // >> Agrega nuevos
        updatedData
            .forEach(d => {
                if (!currentData.find(dA => (dA[id] == d[id]))) {
                    d.EnUso = true;
                    debugAddedData.push([id, d]);
                    dataMapped.push(d);
                }
            })

        if (debugUpdatedData.length) {
            console.debug(id, "Permissions updated:", debugUpdatedData.length);
        }
        if (debugAddedData.length) {
            console.debug(id, "Permissions added:", debugAddedData.length);
        }

        return dataMapped;
    }

    export async function _Init() {
        console.debug("Obteniendo permisos...", Entidad.CTipoPerfil[DataUtil._Usuario.Perfil]);
        if (
            !await DataIndexedDB._StoreExists("tblAcciones") ||
            !await DataIndexedDB._StoreExists("tblModulos") ||
            !await DataIndexedDB._StoreExists("tblPermisosDisponibles") ||
            !await DataIndexedDB._StoreExists("tblPermisosCurrentUser")) {
            console.error("GetPermission_Error: tables no found");
            throw new Error("permissions: tables no found");
        }
        // >> UPDATE ONLINE DATA
        let accionesDisponibles: Entidad.IPermisoAccion[] = await DataIndexedDB._GetRows("tblAcciones");
        let modulosDisponibles: Entidad.IPermisoModulo[] = await DataIndexedDB._GetRows("tblModulos");
        let permisosDisponibles: Entidad.IPermisoDisponible[] = await DataIndexedDB._GetRows("tblPermisosDisponibles");
        let permisosUsuario: Entidad.IPermisoAsignacionUser[] = await DataIndexedDB._GetRows("tblPermisosCurrentUser");
        let permisosUserFinalMap: Entidad.TUserPermisosMap;

        // Get permisos disponibles
        let permisosDisponiblesUpdatedRes = await DataModuloPermisos._ObtenerPermisosDisponibles();

        console.debug("Permissions A. result: ", (permisosDisponiblesUpdatedRes.Resultado > 0));

        if (permisosDisponiblesUpdatedRes.Resultado > 0) {
            accionesDisponibles = MapArraysEnUsoValues(accionesDisponibles, permisosDisponiblesUpdatedRes.Datos.Acciones, "IdAccion");
            modulosDisponibles = MapArraysEnUsoValues(modulosDisponibles, permisosDisponiblesUpdatedRes.Datos.Modulos, "IdModulo");
            permisosDisponibles = MapArraysEnUsoValues(permisosDisponibles, permisosDisponiblesUpdatedRes.Datos.PermisosDisponibles, "IdPermiso");
            // await fn_GuardarPermisosDisponibles(permisosDisponiblesUpdatedRes.Datos);
            await _GuardarPermisosDisponibles({
                Acciones: accionesDisponibles,
                Modulos: modulosDisponibles,
                PermisosDisponibles: permisosDisponibles,
            });
        }

        // Get permisos usuario
        if (DataUtil._Usuario.Perfil != Entidad.CTipoPerfil.Admin) {
            let permisosUsuarioRes = await DataModuloUsuario._ObtenerPermisosUsuario(DataUtil._Usuario.IdUsuario);
            if (permisosUsuarioRes.Perfil !== DataUtil._Usuario.Perfil) {
                const usuarioSesion = { ...DataUtil._Usuario.__Entity, Perfil: permisosUsuarioRes.Perfil };
                DataUtil._Usuario = new DMUsuarioSesion(usuarioSesion);
                await DataIndexedDB._SetRowsV2({ storeName: "tblUsuarioSesion", listRows: [usuarioSesion] });
            }

            console.debug("User permissions result: ", (permisosUsuarioRes.Resultado > 0));

            if (permisosUsuarioRes.Resultado > 0) {
                permisosUsuario = MapArraysEnUsoValues(permisosUsuario, permisosUsuarioRes.Datos, "IdAsignacionPermiso")
                    .map(permisoFinal => {
                        if (permisoFinal.IdAsignacionPermiso < 0) { // Procesa permisos temporales
                            let permisoEscuelaN = permisosUsuarioRes.Datos.find(dC => (dC.IdEscuela === permisoFinal.IdEscuela));

                            if (permisoEscuelaN)
                                // Si el user tiene permisos temporales de una escuela y le asignan permanentes, los anteriores pierden validez
                                permisoFinal.EnUso = false;
                            else
                                // Si no tiene permanentes, conservar validez anterior
                                permisoFinal.EnUso = permisosUsuario.find(permisoLast => (permisoLast.IdAsignacionPermiso == permisoFinal.IdAsignacionPermiso)).EnUso;

                        }
                        return permisoFinal;
                    })

                await _GuardarPermisosUsuario(permisosUsuario);
            }
        } else {
            permisosUsuario = [];
        }

        permisosUserFinalMap = await UpdatePermissionDicts(accionesDisponibles, modulosDisponibles, permisosDisponibles, permisosUsuario);
        return {
            Success: (DataUtil._Usuario.Perfil == Entidad.CTipoPerfil.Admin) || (permisosUserFinalMap.size > 0),
            Permissions: permisosUserFinalMap,
        }
    }

    async function UpdatePermissionDicts(
        accionesDisponibles: Entidad.IPermisoAccion[],
        modulosDisponibles: Entidad.IPermisoModulo[],
        permisosDisponibles: Entidad.IPermisoDisponible[],
        permisosUsuario: Entidad.IPermisoAsignacionUser[]
    ) {
        // >> ONLY ENUSO
        permisosUsuario = permisosUsuario.filter(d => d.EnUso);

        const fnSaveDataInMap = function <T extends { EnUso?: boolean }>(arrayData: T[], mapData: Map<number, T>, id: keyof T) {
            arrayData
                .forEach(d => {
                    let ID = (d[id] as unknown as number);
                    if (d.EnUso || d.EnUso === undefined) {
                        mapData.set(ID, d);
                    }
                    else if (mapData.has(ID)) {
                        mapData.delete(ID);
                    }
                })
        }

        // >> PREPARE FINAL DATA SCHEMES

        fnSaveDataInMap(accionesDisponibles, DataModuloPermisos._PermisosAccionesMap, "IdAccion");
        fnSaveDataInMap(modulosDisponibles, DataModuloPermisos._PermisosModulosMap, "IdModulo");
        fnSaveDataInMap(permisosDisponibles, DataModuloPermisos._PermisosDisponiblesMap, "IdPermiso");

        // accionesDisponibles.forEach(d => modulos.Usuario.permisosAccionesMap.set(d.IdAccion, d));
        // modulosDisponibles.forEach(d => modulos.Usuario.permisosModulosMap.set(d.IdModulo, d));
        // permisosDisponibles.forEach(d => modulos.Usuario.permisosDisponiblesMap.set(d.IdPermiso, d));

        // fn_CreateTemporal_AllMissingPermissions(permisosUsuario, permisosDisponibles, Util.prop_Usuario.IdUsuario, Util.prop_Usuario.Perfil);

        _DiccPermisos = GetPermisosMapFromPermisosArray(permisosUsuario);

        // console.warn(permisosUsuario, DiccPermisos, "GetInfoPermisosFromTbl")

        console.debug("Permisos listos!!");
        return _DiccPermisos;
    }

    /** @returns Map<IdEscuela, Map<IdModulo, Map<IdAccion, @interface Entidades.IPermisosAccion>>> */
    function GetPermisosMapFromPermisosArray(permisos: Entidad.IPermisoAsignacionUser[]): Entidad.TUserPermisosMap {
        let permisosMap: Entidad.TUserPermisosMap = new Map();

        if (DataUtil._Usuario.Perfil !== Entidad.CTipoPerfil.Admin) {
            for (let itemPermiso of permisos) {
                let infoPermisoDisponible = DataModuloPermisos._PermisosDisponiblesMap.get(itemPermiso.IdPermiso);
                if (!infoPermisoDisponible) {
                    console.debug("-d", "Permiso disponible no found,  itemPermiso: ", itemPermiso);
                    continue
                }
                else if (!DataModuloPermisos._PermisosModulosMap.has(infoPermisoDisponible.IdModulo)) {
                    console.debug("-d", "Modulo disponible no found,  itemPermiso: ", infoPermisoDisponible.IdModulo);
                    continue
                }
                else if (!DataModuloPermisos._PermisosAccionesMap.has(infoPermisoDisponible.IdAccion)) {
                    console.debug("-d", "Accion disponible no found,  Accion: ", infoPermisoDisponible.IdAccion);
                    continue
                }
                // Nivel 1: Escuelas -> idEscuela: Mapa de modulos
                let permisosModulosInEscuela = permisosMap.get(itemPermiso.IdEscuela);
                if (!permisosModulosInEscuela) {
                    permisosModulosInEscuela = new Map();
                    permisosMap.set(itemPermiso.IdEscuela, permisosModulosInEscuela);
                }

                // Nivel 2: Modulos -> idModulo: Mapa de acciones
                let permisosAccionesInModulo = permisosModulosInEscuela.get(infoPermisoDisponible.IdModulo)
                if (!permisosAccionesInModulo) {
                    permisosAccionesInModulo = new Map();
                    permisosModulosInEscuela.set(infoPermisoDisponible.IdModulo, permisosAccionesInModulo);
                }

                // Nivel 3: Acciones -> Accion
                if (!permisosAccionesInModulo.has(infoPermisoDisponible.IdAccion)) {
                    permisosAccionesInModulo.set(infoPermisoDisponible.IdAccion, DataModuloPermisos._PermisosAccionesMap.get(infoPermisoDisponible.IdAccion));
                } else {
                    console.warn("-d", "Una permiso se repitió", itemPermiso);
                }
            }
        }
        return permisosMap;
    }

    // Permisos temporales // TEMPORAL ?? Obsoleto cuando permisos sea Monitoreado ?
    /** Crea permisos temporales a una escuela
     * * Actualiza el Diccionario global de permisos
     * * "IdAsignacionPermiso" falsos, menores a 0 y empezando por 200 menos que el ID existente menor
     * @returns una lista con todos los permisos actualizados (chidos y temporales)
     */
    export async function _CreateAndSaveTemporal_AllPermisosByEscuela(idEscuela: number, user: Entidad.IUsuarioSesion = DataUtil._Usuario): Promise<boolean> {
        if (DataUtil._Usuario.Perfil !== Entidad.CTipoPerfil.Admin) {
            let new_permisosTemp: Entidad.IPermisoAsignacionUser[] = [];

            let permisosUsuario: Entidad.IPermisoAsignacionUser[] = await DataIndexedDB._GetRows("tblPermisosCurrentUser");
            let minPermisoID = permisosUsuario.reduce((a, b) => (a.IdAsignacionPermiso < b.IdAsignacionPermiso) ? a : b);

            let indexToId = (minPermisoID.IdAsignacionPermiso > 0)
                ? -minPermisoID.IdAsignacionPermiso
                : minPermisoID.IdAsignacionPermiso;

            // console.log("MinID, Current permisos", minPermisoID, permisosUsuario);

            for (let permiso of DataModuloPermisos._PermisosDisponiblesMap.values()) {
                if (!DataModuloPermisos._PermisosModulosMap.get(permiso.IdModulo).EnUso) continue;
                indexToId--;

                new_permisosTemp.push({
                    IdAsignacionPermiso: indexToId,
                    IdUsuario: user.IdUsuario,
                    IdEscuela: idEscuela,
                    IdPermiso: permiso.IdPermiso,
                    EnUso: true
                })
            }

            let permisosTempSaved = await _GuardarPermisosUsuario(new_permisosTemp);

            if (permisosTempSaved) {
                // console.log("Permisos guardados...")
                permisosUsuario = permisosUsuario.concat(new_permisosTemp);

                _DiccPermisos.clear();

                GetPermisosMapFromPermisosArray(permisosUsuario)
                    .forEach((permisoTree, idEscuela) => {
                        _DiccPermisos.set(idEscuela, permisoTree);
                    })
                // console.log(DiccPermisos, "nuevos permisos Dicc")

                // app.ReloadService(data.Entidades.CTipoRequest.Kinder); // DOTEST // NOTE SOLO PARA PRUEBAS PROBAR EN CONSOLA
                return true;
            } else {
                console.warn("Fail save: Permisos_temp!!!");
            }
            return false;
        }
        return true;
    }

    export async function _CrearPermisosLocales() {
        let accionesDisponibles: Entidad.IPermisoAccion[];
        let modulosDisponibles: Entidad.IPermisoModulo[];
        let permisosDisponibles: Entidad.IPermisoDisponible[];
        let permisosUsuario: Entidad.IPermisoAsignacionUser[];
        let permisosUserFinalMap: Entidad.TUserPermisosMap;

        accionesDisponibles = await DataIndexedDB._GetRows("tblAcciones");
        modulosDisponibles = await DataIndexedDB._GetRows("tblModulos");
        permisosDisponibles = await DataIndexedDB._GetRows("tblPermisosDisponibles");
        permisosUsuario = await DataIndexedDB._GetRows("tblPermisosCurrentUser");
        permisosUserFinalMap = await UpdatePermissionDicts(accionesDisponibles, modulosDisponibles, permisosDisponibles, permisosUsuario);
        return {
            Success: (DataUtil._Usuario.Perfil == Entidad.CTipoPerfil.Admin) || (permisosUserFinalMap.size > 0),
            Permissions: permisosUserFinalMap,
        }
    }

    /** Crea los permisos faltantes por escuela (fantasmas locales), solo para SuperUsuarios
     * * Una escuela resulta con asignaciones de permisos tanto reales y fantasmas
     * * Solo crea permisos para las escuelas que ya tenían al menos una asignación de permisos
     */
    export function _CreateTemporal_AllMissingPermissions(currentPermissions: Entidad.IPermisoAsignacionUser[], permisosDisponibles: Entidad.IPermisoDisponible[], idUsuario: number, tipoPerfil: Entidad.CTipoPerfil): Entidad.IPermisoAsignacionUser[] {
        if (tipoPerfil == Entidad.CTipoPerfil.SuperUsuario) {
            let minId = currentPermissions?.reduce((a, b) => (a.IdAsignacionPermiso < b.IdAsignacionPermiso ? a : b)).IdAsignacionPermiso;
            if (minId) {
                const debugData = [];
                minId = -minId;
                d3Group(currentPermissions, (d) => d.IdEscuela)
                    .forEach((permisosDeEscuela, idEscuela) => {
                        permisosDisponibles.forEach(permisoDisponible => {
                            if (!permisosDeEscuela.find(d => (d.IdPermiso == permisoDisponible.IdPermiso))) {
                                currentPermissions.push({
                                    IdAsignacionPermiso: minId,
                                    IdEscuela: idEscuela,
                                    IdPermiso: permisoDisponible.IdPermiso,
                                    IdUsuario: idUsuario
                                })

                                minId--;
                                debugData.push([idEscuela, minId, permisoDisponible.IdPermiso]);
                                // console.debug("Ghost permission item created", idEscuela, minId, permisoDisponible.IdPermiso);
                            }
                        })
                    })

                if (debugData.length) {
                    console.debug("Items ghost permissions created", debugData.length);
                }
            }
        }

        return currentPermissions;
    }
}
