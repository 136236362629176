import { Entidad } from "../Entidad";
import { Global } from "../Global";
import { DataDRequest } from "../DRequest";
// export var DiccPagosRecibidos: Map<number, Entidades.ICovidEncuesta> = new Map();

/** Los Detalles por cargo pagado incluyen Pagos (Completos y Parciales) y Descuentos */
export async function _FinanzasObtenerPagosRecibidos(idEscuela: number): Promise<DataDRequest.IRequestResponseA<Entidad.IFinanzaPagoRecibido[]>> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "finanzas/ObtenerPagos";
    const params = {
        IdEscuela: idEscuela
    }
    return DataDRequest._Request5(url, "GET", params, {
        idToKeepPromiseUntilFinished: idEscuela
    })
}
