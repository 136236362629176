import * as d3 from "d3";
import { group as d3Group } from "d3-array";
import { MainPage } from "../../MainPage";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import DataModuloEgresoPredefinido from "../../data/modulo/EgresoPredefinido";
import DataModuloEscuela from "../../data/modulo/Escuela";
import { ArrayV2 } from "../../util/ArrayV2";
import { IConfigGridExcelExport, IGridExtraTableConfig, IGridRenderInfo, VentanaGrid } from "../controlD3/AVentanaGrid";
import { ExcelThings } from "../controlD3/ExcelExport";
import { Fields, FormGenerator } from "../controlD3/Formulario";
import { ModalThings } from "../controlD3/ModalThings";
import { SelectV2 } from "../controlD3/SelectV2";
import { Table } from "../controlD3/Tabla";
import { UIUtilLang } from "../util/Language";
import { UIUtilGeneral } from "../util/Util";
import { UIUtilViewData } from "../util/ViewData";
import { UIUtilViewFinanzaEgresoCategoria } from "../utilView/FinanzaEgresoCategoria";

import CTipoGasto = Entidad.CFinanzaEgresoTipo;

interface IEgreso extends Entidad.IFinanzaEgresoPredefinido {
    KinderFiltro: [];
}

export class UIVentanaFinanzasCatalogoEgresos extends VentanaGrid<IEgreso> {

    constructor(content: d3.Selection<HTMLDivElement, undefined, HTMLElement, any>, modulo: Entidad.CModulo) {
        super(content, modulo, {
            LabelsKeyBase: "finanzaegresoplantilla",
            ModuloObservableToTblRefresh: [Entidad.CTipoRequest.EgresoCategoria],
            ModuloForceRequestOnMostrar: [Entidad.CTipoRequest.EgresoCategoria],
        });
    }

    protected GRID_GetDataRequestID(): DataModuloMain.TipoRequestMonitorId {
        return Entidad.CTipoRequest.EgresoPredefinido;
    }

    protected GRID_GetTableConfigBase(): IGridRenderInfo<IEgreso> {
        return {
            IdTabla: "FinanzasCatalogos-Egresos",
            DefaultSort: "Descripcion",
            IdData: "Id",
            MinWidth: 700,
            Columns: [
                { Field: "Descripcion", Label: "Descripción", Width: "22%", MinWidth: "100px" },
                { Field: "getEsValorVariable", Label: "Variabilidad", LabelLangKey: "d_field_tipogasto", Width: "20%", MinWidth: "85px" },
                { Field: "getNombreCategoria", Label: "categoria", LabelLangKey: "d_field_categoria", Width: "20%", MinWidth: "90px" },
                { Field: "getValorFmt", Label: "Valor", LabelLangKey: "d_field_valor", Width: "18%", MinWidth: "90px" },
                { Field: "getEscuelaNombre", Label: "Escuela", LabelLangKey: "d_field_nombreescuela", Width: "20%", MinWidth: "75px" },
            ],
        }
    }

    protected GRID_GetTableConfigAdvanced(): IGridExtraTableConfig<IEgreso> {
        return null;
    }

    protected GRID_GetFilters(): Array<Table.IParametroFiltro<IEgreso>> {
        return [
            { Field: "Descripcion", Label: "Nombre" },
            {
                Field: "TipoGasto", Label: "TipoGasto", Type: "select",
                Options: UIUtilViewData._GetList_EgresoTipo(),
            },
            {
                Field: "getNombreCategoria",
                LabelLangKey: "d_field_categoria",
                Type: "select",
                Options: () => UIUtilViewFinanzaEgresoCategoria._GetCategoriasFullList()
                    .map((d) => ({ Id: d.Nombre, Name: d.Nombre }))
            },
            { Field: "Valor", Label: "Valor", Type: "number" }
        ]
    }

    protected GRID_GetMenuTopGrid(): Array<Table.ITableMenuTopDefaultOptionConfig> {
        const opciones: Array<Table.ITableMenuTopDefaultOptionConfig> = []
        if (this.GridHasPermisoAccion(Entidad.CAccionPermiso.Agregar)) {
            opciones.push({
                Label: "Agregar", // "action_addcargo",
                Callback: () => this.OpenModal_AgregarEditar()
            });
        }
        return opciones;
    }

    protected GRID_GetSelectionDataMenuV2(menuLocation: "row" | "top-selected", dataGridSelected: IEgreso[]): Table.ITableMenuDataSelectedOptionConfig<IEgreso>[] {
        const opciones: Array<Table.ITableMenuDataSelectedOptionConfig<IEgreso>> = [];

        if (this.GridHasPermisoAccion(Entidad.CAccionPermiso.Editar)) {
            opciones.push({
                Label: "Editar", // "action_addcargo",
                MultiData: false,
                Callback: (dato) => this.OpenModal_AgregarEditar(dato[0])
            });
        }

        if (this.GridHasPermisoAccion(Entidad.CAccionPermiso.Eliminar)) {
            opciones.push({
                Label: "Eliminar",
                Callback: (datos: Array<IEgreso>) => this.OpenFormularioEliminar(datos),
                GetDetails: (datos) => true
            });
        }
        return opciones;
    }

    //***************************************************************************************
    // PROCESO AGREGAR/EDITAR CARGO
    //***************************************************************************************

    protected GRID_GetExportarConfig(dataGrid: IEgreso[]): IConfigGridExcelExport<any> {
        return {
            IdsEscuelas: [...new Set(dataGrid.map(d => d.IdEscuela))],
            ColumnsConfig: this.ctrlTabla
                ._InfoColumns
                .map<ExcelThings.IColumnToExcelExportFileConfig<IEgreso>>((d) => ({
                    Field: d.Field as keyof IEgreso,
                    HeaderTag: d.Label,
                    WidthCell: 25
                })),
            OnGetDataBySheets: async () => {
                return Array.from(d3Group(dataGrid, d => d.IdEscuela))
                    .map<ExcelThings.ISheetConfig<IEgreso>>(entrie => ({
                        IdSheet: entrie[0], // IdEscuela
                        SheetName: entrie[1][0].getEscuelaNombre,
                        Data: entrie[1]
                    }))
            },
            OnGetEscuelasTagInSheet: (dataSheet: IEgreso[]) => dataSheet[0].getEscuelaNombre,
        }
    }

    //***************************************************************************************
    // PROCESO AGREGAR/EDITAR DESCUENTO
    //***************************************************************************************

    private OpenModal_AgregarEditar(dato?: IEgreso) {
        type T = IEgreso & { IdEscuelas?: number[] };
        if (!dato) {
            dato = <T>{
                Valor: 0,
                IdCategoria: UIUtilViewFinanzaEgresoCategoria._ID_EGRESO_GENERAL,
            }
        }
        const esEditar = dato.Id != null;

        this.GridOpenModal_ActionFormToAGridData({
            Title: esEditar ? "Editar" : "Agregar",
            Width: 400,
            Action: (esEditar) ? Entidad.CAccionPermiso.Editar : Entidad.CAccionPermiso.Agregar,
            IdsEscuelas: [dato.IdEscuela],
            GetForm: (_, mt) => {
                let escuelasConPermiso = this.GridGetEscuelasConPermisoDeAccion(esEditar ? Entidad.CAccionPermiso.Editar : Entidad.CAccionPermiso.Agregar, dato?.IdEscuela);
                let tipoGastoList = UIUtilViewData._GetList_EgresoTipo();

                const fnReqEgresoCategoria = (idEscuela: number, onEnd?: (() => void)) => setTimeout(async () => {
                    if (!idEscuela) return;
                    mt.Progress.node()._Visible = true;
                    await MainPage._ReloadServiceAndAwaitBool(Entidad.CTipoRequest.EgresoCategoria, idEscuela);
                    mt.Progress.node()._Visible = false;
                    if (onEnd) onEnd();
                });
                const fnOnChangeIdEscuela = () => {
                    const selectCat = (form._ControlsData.get("IdCategoria").instance as SelectV2<Entidad.IFinanzasEgresoCategoria>);
                    const dataF = form._Data;
                    const idEscuela = (() => {
                        let idEscuela = -1
                        if (esEditar) {
                            idEscuela = dataF.IdEscuela || -1;
                        } else if (dataF.IdEscuelas.length == 1) {
                            idEscuela = dataF.IdEscuelas[0];
                        }
                        return idEscuela;
                    })()
                    selectCat._UpdateList(() => UIUtilViewFinanzaEgresoCategoria._GetCategoriasFullList(idEscuela));
                    return idEscuela;
                }

                const form = new FormGenerator<T>()
                    ._Crear({
                        LabelMaxWidth: 120,
                        LangModuleKeyInContext: this.labelsKeyBase,
                        schema: [
                            {
                                model: "Descripcion", type: Fields.input,
                                labelText: "d_field_descripcion",
                                inputAttr: { type: "text", required: true, maxlength: 200 },
                            },
                            {
                                model: esEditar ? "IdEscuela" : "IdEscuelas" as any, type: Fields.selectMaterial,
                                labelText: "d_field_nombreescuela",
                                selectMaterialAttr: {
                                    multiselect: !esEditar,
                                    valueMember: "IdKinder",
                                    displayMember: "Nombre",
                                    required: true, removeBorder: esEditar, disabled: esEditar,
                                    onChange: () => {
                                        const idEscuela = fnOnChangeIdEscuela();
                                        if (idEscuela != -1) {
                                            fnReqEgresoCategoria(idEscuela);
                                        }
                                    },
                                },
                                values: escuelasConPermiso
                            },
                            {
                                model: "TipoGasto", type: Fields.selectMaterial,
                                labelText: "d_field_tipogasto",
                                selectMaterialAttr: {
                                    valueMember: "Id",
                                    displayMember: "Name",
                                    required: true,
                                    onChange: (id: (typeof tipoGastoList[0]["Id"]), item: typeof tipoGastoList[0]) => {
                                        let control = form._ControlsData.get("Valor");
                                        let input = control.selection as TSelectionHTML<"input">;
                                        let required = (id == CTipoGasto.Fijos);
                                        input
                                            .attr("required", required ? "" : null)
                                            .attr("min", required ? 1 : null);
                                    },
                                },
                                values: tipoGastoList,
                            },
                            {
                                model: "Valor", type: Fields.input,
                                labelText: "d_field_valor",
                                inputAttr: {
                                    type: "currency",
                                    min: (dato.TipoGasto == CTipoGasto.Fijos ? 1 : null),
                                    required: (dato.TipoGasto == CTipoGasto.Fijos),
                                }
                            },
                            {
                                model: "IdCategoria", type: Fields.selectMaterial,
                                labelText: "d_field_categoria",
                                selectMaterialAttr: {
                                    valueMember: "Id",
                                    displayMember: "Nombre",
                                    required: true,
                                    ShowAndEnableSearchText: true,
                                    Data: () => UIUtilViewFinanzaEgresoCategoria._GetCategoriasFullList(dato.IdEscuela || -1),
                                },
                            },
                        ],
                    }, dato);

                fnReqEgresoCategoria(dato.IdEscuela, () => {
                    fnOnChangeIdEscuela();
                    form._AsignaData(dato);
                });
                return form;
            },
            OnAccept: async (form, MT) => {
                let dato = form._Data;
                if (esEditar) {
                    return DataModuloEgresoPredefinido._ActualizarRegistro(dato);
                } else {
                    interface DataProcess {
                        Resultado: number,
                        ItemData: { idEscuela: number, dato: T },
                        TipoRequest: Entidad.CTipoRequest
                    }
                    let dataProcess = dato.IdEscuelas.map<DataProcess>(idEscuela => ({
                        Resultado: -1,
                        ItemData: { idEscuela, dato },
                        TipoRequest: this.GRID_GetDataRequestID()
                    }));
                    await ModalThings._ProccessServiceByServiceFromAArrayIterator(MT, dataProcess, {
                        OnStepAProccess: async (itemData) => {
                            itemData.dato = { ...itemData.dato, IdEscuela: itemData.idEscuela };
                            return DataModuloEgresoPredefinido._NuevoRegistro(itemData.dato);
                        },
                        OnError_GetItemDataTag: (itemData, container) => `${DataModuloMain._GetItemDataById(Entidad.CTipoRequest.Escuela, itemData.idEscuela).Nombre}`,
                    }).catch((err) => { console.log(err); return null; });

                    let arrDataProcess = new ArrayV2<DataProcess>()._Push(...dataProcess);
                    let nReloadErr = 0;
                    await arrDataProcess._ForEachAwait(async (d) => {
                        if (d.Resultado > 0) {
                            await MainPage._ReloadServiceAndAwait(this.GRID_GetDataRequestID(), d.ItemData.idEscuela).catch((err) => {
                                nReloadErr++;
                            });
                        }
                    })
                    if (nReloadErr > 0) this.notificacion._Mostrar(UIUtilLang._GetUIString("general", "notif_fail_infoupdate"), "ADVERTENCIA");
                    MT.Modal._Ocultar();
                    await UIUtilGeneral._Sleep(300);
                    return null;
                }
            }
        })
    }

    private OpenFormularioEliminar(datos: Array<IEgreso>) {
        this.GridOpenModal_ProccessArrayData({
            DataToProccess: datos,
            Title: UIUtilLang._GetUIString("c_actions", "eliminar"), // TEMPORAL PERMISOS
            Width: 350,
            OnGetIdEscuela: (dato) => dato.IdEscuela,
            OnError_GetItemDataTag: (dato) => `${dato.Descripcion} (${DataModuloEscuela._DiccFullEscuelas.get(dato.IdEscuela)?.Nombre})`,
            OnStepAProccess: (dato: IEgreso) => DataModuloEgresoPredefinido._EliminarRegistro(dato.Id),
        })
    }
}
