import { Entidad } from "../Entidad";
import { Global } from "../Global";
import { DataDRequest } from "../DRequest";
import { DateV2 } from "../../util/DateV2";

export async function _FinanzasObtenerListaCargosPorAplicar(idEscuela: number, fechaInicio: Date, fechaFin: Date): Promise<DataDRequest.IRequestResponseA<Entidad.IFinanzaCargoPorAplicar[]>> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "finanzas/ObtenerListaCargosPorAplicarV2"; // "finanzas/ObtenerListaCargosPorAplicar";
    const params = {
        "IdEscuela": idEscuela,
        "Inicio": new DateV2(fechaInicio)._ToISOLocalString(),
        "Fin": new DateV2(fechaFin)._ToISOLocalString(),
    }
    return DataDRequest._Request5(url, "GET", params, { idToKeepPromiseUntilFinished: idEscuela })
}

