import { Entidad } from "../Entidad";
import { IViewInfoItem } from "../util/ViewInfo";

export async function _ObtenerViewInfo(maxDate: string): Promise<IViewInfoItem[]> {
    // const url = Utils.prop_URL_RSTV2 + "permiso/ObtenerLista";
    // console.log("fn_ObtenerViewInfo maxDate", maxDate);

    return new Promise((resolve) => {
        let dataRequest: IViewInfoItem[];

        if (maxDate) {
            let dtMaxDate = new Date(maxDate);
            dataRequest = _VIEWINFO
                .filter(d => (new Date(d.Modificacion) > dtMaxDate));
        } else {
            dataRequest = _VIEWINFO;
        }

        // console.warn("Data Request: ", dataRequest);

        resolve(
            dataRequest
                .map(d => {
                    if (d.EnUso == undefined) {
                        d.EnUso = true;
                    }
                    return d;
                })
        );
    })
}


/** // TEMPORAL */
export const _VIEWINFO: IViewInfoItem[] = [
    // Escuelas
    {
        Id: 1,
        IdParent: 0,
        Path: "escuelas/escuelas",
        Description: "Aquí puedes agregar una escuela y encontrarás la lista de todas las escuelas o planteles que administras y su información básica.",
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
    {
        Id: 2,
        IdParent: 1,
        Path: "escuelas/escuelas",
        Description: `Al hacer clic en "Agregar" puedes incluir una nueva escuela o plantel llenando la información solicitada.`,
        Module: Entidad.CModulo.Escuelas,
        Action: Entidad.CAccionPermiso.Agregar,
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
    {
        Id: 3,
        IdParent: 1,
        Path: "escuelas/escuelas",
        Description: `Puedes vincular la escuela a un calendario de Google seleccionándola y escogiendo "Calendario de Google".`,
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
    {
        Id: 4,
        IdParent: 1,
        Path: "escuelas/escuelas",
        Description: `Si quieres ver los grados y grupos de cada nivel despliega la flecha que está bajo el nombre de la escuela y sobre el renglón del nivel.`,
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
    {
        Id: 5,
        IdParent: 1,
        Path: "escuelas/escuelas",
        Description: `Al hacer clic sobre el nombre del nivel, aparece en el menú la opción vincularlo a un "Calendario de alimentos" y/o a un "Calendario de grupos" los cuales se vinculan al calendario de Google de la escuela.`,
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
    {
        Id: 6,
        IdParent: 1,
        Path: "escuelas/escuelas",
        Description: `Si haces clic en el nombre de la escuela irás al panel de configuración.`,
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
    // Escuelas / Panel
    {
        Id: 7,
        IdParent: 0,
        Path: "escuelas/escuelas/panel",
        Description: "Aquí encuentras la información general de la escuela y puedes configurar la información que Kidi va a reportar.",
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
    {
        Id: 8,
        IdParent: 7,
        Path: "escuelas/escuelas/panel",
        Description: "Al hacer clic en cada pestaña, despliegas la información a configurar.",
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
    {
        Id: 9,
        IdParent: 7,
        Path: "escuelas/escuelas/panel",
        Description: "Si haces clic de nuevo en la pestaña regresas al panel anterior.",
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
    // Grupos
    {
        Id: 10,
        IdParent: 0,
        Path: "escuelas/grupos",
        Description: "Aquí puedes agregar un grupo nuevo y encontrarás la lista de todos los grupos.",
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
    {
        Id: 11,
        IdParent: 10,
        Path: "escuelas/grupos",
        Description: `Puedes agregar un grupo nuevo de forma individual con "Agregar" o crear varios a la vez usando "Carga masiva".`,
        Module: Entidad.CModulo.Grupos,
        Action: Entidad.CAccionPermiso.Agregar,
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
    {
        Id: 12,
        IdParent: 10,
        Path: "escuelas/grupos",
        Description: "Si haces clic en el nombre del grupo irás al panel de configuración.",
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
    {
        Id: 13,
        IdParent: 10,
        Path: "escuelas/grupos",
        Description: "Puedes descargar la información cuando seleccionas una o más escuelas de la tabla.",
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
    // Grupos / Panel
    {
        Id: 14,
        IdParent: 0,
        Path: "escuelas/grupos/panel",
        Description: "Aquí encuentras la información general del grupo, puedes configurar el horario y consultar los alumnos asignados al mismo.",
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
    {
        Id: 15,
        IdParent: 14,
        Path: "escuelas/grupos/panel",
        Description: "Al hacer clic en cada pestaña, despliegas la información a configurar.",
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
    {
        Id: 16,
        IdParent: 14,
        Path: "escuelas/grupos/panel",
        Description: "Si haces clic de nuevo en la pestaña regresas al panel anterior.",
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
    {
        Id: 17,
        IdParent: 14,
        Path: "escuelas/grupos/panel",
        Description: "Puedes descargar la información cuando seleccionas una o más grupos de la tabla.",
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
    // Alumnos
    {
        Id: 18,
        IdParent: 0,
        Path: "alumnos/alumnos",
        Description: "Aquí puedes agregar un alumn@ nuev@ y encontrarás la lista de todos los alumn@s y su estado.",
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
    {
        Id: 19,
        IdParent: 18,
        Path: "alumnos/alumnos",
        Description: `Puedes agregar un alumn@ nuevo de forma individual con "Agregar" o dar de alta varios a la vez usando "Carga masiva".`,
        Module: Entidad.CModulo.Alumnos,
        Action: Entidad.CAccionPermiso.Agregar,
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
    {
        Id: 20,
        IdParent: 18,
        Path: "alumnos/alumnos",
        Description: "Si haces clic en el nombre del alumn@ irás al panel de configuración.",
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
    {
        Id: 21,
        IdParent: 18,
        Path: "alumnos/alumnos",
        Description: "Puedes descargar la información cuando seleccionas un@ o más alumn@s de la tabla.",
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
    // Alumnos / Panel
    {
        Id: 22,
        IdParent: 0,
        Path: "alumnos/alumnos/panel",
        Description: "Aquí encuentras la información general del alumn@ y puedes configurar y consultar su información.",
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
    {
        Id: 23,
        IdParent: 22,
        Path: "alumnos/alumnos/panel",
        Description: "Al hacer clic en cada pestaña, despliegas la información a consultar y/o configurar.",
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
    {
        Id: 24,
        IdParent: 22,
        Path: "alumnos/alumnos/panel",
        Description: "Si haces clic de nuevo en la pestaña regresas al panel anterior.",
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
    // Alumnos / Tutores
    {
        Id: 25,
        IdParent: 0,
        Path: "alumnos/tutores",
        Description: "Aquí puedes agregar un tutor nuevo y encontrarás la lista de todos los tutores junto con sus datos de contacto y de acceso a Kidi.",
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
    {
        Id: 26,
        IdParent: 25,
        Path: "alumnos/tutores",
        Description: `Puedes agregar un tutor nuevo de forma individual con "Agregar" o dar de alta varios a la vez usando "Carga masiva".`,
        Module: Entidad.CModulo.Tutores,
        Action: Entidad.CAccionPermiso.Agregar,
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
    {
        Id: 27,
        IdParent: 25,
        Path: "alumnos/tutores",
        Description: "Puedes descargar la información cuando seleccionas una o más tutores de la tabla.",
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
    // Alumnnos / Covid
    {
        Id: 28,
        IdParent: 0,
        Path: "alumnos/covid",
        Description: "Aquí encuentras los resultados del cuestionario Covid que llenan los papás para cada alumno.",
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
    {
        Id: 29,
        IdParent: 28,
        Path: "alumnos/covid",
        Description: "Puedes descargar la información cuando seleccionas uno o más alumn@s de la tabla.",
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
    // Finanzas / Cargos
    {
        Id: 30,
        IdParent: 0,
        Path: "finanzas/ingresos/cargos",
        Description: "Aquí puedes agregar los cargos y descuentos a aplicarse y encontrarás la lista de todas los que han sido configurados y su información básica.",
        Modificacion: "2022-08-16T20:00:00.896Z"
    },
    {
        Id: 31,
        IdParent: 30,
        Path: "finanzas/ingresos/cargos",
        Description: `Al hacer clic en "Agregar cargo" o "Agregar descuento" puedes configurar un nuevo cargo o descuento.`,
        Module: Entidad.CModulo.FinanzasCargo,
        Action: Entidad.CAccionPermiso.Agregar,
        Modificacion: "2022-08-16T20:00:00.896Z"
    },
    {
        Id: 32,
        IdParent: 30,
        Path: "finanzas/ingresos/cargos",
        Description: "Si haces clic en el nombre del cargo o descuento aparecerán todos los datos que fueron configurados.",
        Modificacion: "2022-08-16T20:00:00.896Z"
    },
    {
        Id: 33,
        IdParent: 30,
        Path: "finanzas/ingresos/cargos",
        Description: "Puedes descargar la información cuando seleccionas una o más cargos y/o descuentos de la tabla.",
        Modificacion: "2022-08-16T20:00:00.896Z"
    },
    // Finanzas / Métodos de pago
    {
        Id: 34,
        IdParent: 0,
        Path: "finanzas/metodospago",
        Description: "Aquí puedes agregar un método de pago y encontrarás la lista de todos los que han sido configurados.",
        Modificacion: "2022-08-16T20:00:00.896Z"
    },
    {
        Id: 35,
        IdParent: 34,
        Path: "finanzas/metodospago",
        Description: `Si vas a cobrar a través de Kidi mediante Open pay, aquí lo configuras al seleccionar "Configurar método electrónico".`,
        Module: Entidad.CModulo.FinanzasMetodosPago,
        Action: Entidad.CAccionPermiso.Agregar,
        Modificacion: "2022-08-16T20:00:00.896Z"
    },
    {
        Id: 36,
        IdParent: 34,
        Path: "finanzas/metodospago",
        Description: "Puedes descargar la información cuando seleccionas una o más métodos de pago de la tabla.",
        Modificacion: "2022-08-16T20:00:00.896Z"
    },
    // Finanzas / Horas Extras
    {
        Id: 37,
        IdParent: 0,
        Path: "finanzas/ingresos/horasextras",
        Description: "Aquí puedes consultar los cargos de horas extras generados para cada alumno antes de generar su cobro en el estado de cuenta.",
        Modificacion: "2022-08-16T20:00:00.896Z"
    },
    {
        Id: 38,
        IdParent: 37,
        Path: "finanzas/ingresos/horasextras",
        Description: "Si seleccionas uno o más alumnos puedes ver los cargos por horas extras, generar su cobro y enviarlo al estado de cuenta o ignorarlos y no enviarlos al estado de cuenta.",
        // Module: data.Entidades.CModulo.FinanzasMetodosPago,
        // Action: data.Entidades.CAccionPermiso.GenerarCobro || data.Entidades.CAccionPermiso.Ignorar,
        Modificacion: "2022-08-16T20:00:00.896Z"
    },
    {
        Id: 39,
        IdParent: 37,
        Path: "finanzas/ingresos/horasextras",
        Description: "Una vez que hayas generado el cobro o hayas ignorados los cargos, el costo se vuelve $0.",
        // Module: data.Entidades.CModulo.FinanzasMetodosPago,
        // Action: data.Entidades.CAccionPermiso.GenerarCobro || data.Entidades.CAccionPermiso.Ignorar,
        Modificacion: "2022-08-16T20:00:00.896Z"
    },
    {
        Id: 40,
        IdParent: 37,
        Path: "finanzas/ingresos/horasextras",
        Description: "Si haces clic en valor de las horas extras, puedes el desglose y elegir si generas el cobro o si lo ignoras.",
        Modificacion: "2022-08-16T20:00:00.896Z"
    },
    {
        Id: 41,
        IdParent: 37,
        Path: "finanzas/ingresos/horasextras",
        Description: "Puedes descargar la información cuando seleccionas una o más alumn@s de la tabla.",
        Modificacion: "2022-08-16T20:00:00.896Z"
    },
    // Finanzas / Pagos recibidos
    {
        Id: 42,
        IdParent: 0,
        Path: "finanzas/ingresos/pagosrecibidos",
        Description: "Aquí puedes consultar los pagos que ya fueron hechos y descargar un reporte de los mismos.",
        Modificacion: "2022-08-16T20:00:00.896Z"
    },
    {
        Id: 43,
        IdParent: 42,
        Path: "finanzas/ingresos/pagosrecibidos",
        Description: "Para ver la información debes seleccionar el año y mes del reporte a consultar.",
        Modificacion: "2022-08-16T20:00:00.896Z"
    },
    {
        Id: 44,
        IdParent: 42,
        Path: "finanzas/ingresos/pagosrecibidos",
        Description: "Si despliegas la flecha a lado del nombre del alumn@, podrás ver el detalle del pago.",
        Modificacion: "2022-08-16T20:00:00.896Z"
    },
    {
        Id: 45,
        IdParent: 42,
        Path: "finanzas/ingresos/pagosrecibidos",
        Description: "Puedes descargar la información cuando seleccionas una o más alumn@s de la tabla.",
        Modificacion: "2022-08-16T20:00:00.896Z"
    },
    // Finanzas / Pagos pendientes
    {
        Id: 46,
        IdParent: 0,
        Path: "finanzas/ingresos/pagospendientes",
        Description: "Aquí puedes consultar los cargos que ya se han aplicado en los estados de cuenta y que están pendientes de pago y descargar un reporte de los mismos.",
        Modificacion: "2022-08-16T20:00:00.896Z"
    },
    {
        Id: 47,
        IdParent: 46,
        Path: "finanzas/ingresos/pagospendientes",
        Description: "Para ver la información debes seleccionar el año y mes del reporte a consultar.",
        Modificacion: "2022-08-16T20:00:00.896Z"
    },
    {
        Id: 48,
        IdParent: 46,
        Path: "finanzas/ingresos/pagospendientes",
        Description: "Puedes descargar la información cuando seleccionas una o más alumn@s de la tabla.",
        Modificacion: "2022-08-16T20:00:00.896Z"
    },
    // Finanzas / Cargos por aplicar
    {
        Id: 49,
        IdParent: 0,
        Path: "finanzas/ingresos/cargosporaplicar",
        Description: "Aquí puedes consultar los cargos que faltan por aplicar y descargar un reporte de los mismos.",
        Modificacion: "2022-08-16T20:00:00.896Z"
    },
    {
        Id: 50,
        IdParent: 49,
        Path: "finanzas/ingresos/cargosporaplicar",
        Description: "Para ver la información debes seleccionar el año y mes del reporte a consultar.",
        Modificacion: "2022-08-16T20:00:00.896Z"
    },
    {
        Id: 51,
        IdParent: 49,
        Path: "finanzas/ingresos/cargosporaplicar",
        Description: "Puedes descargar la información cuando seleccionas una o más alumn@s de la tabla.",
        Modificacion: "2022-08-16T20:00:00.896Z"
    },
    // Circulares
    {
        Id: 52,
        IdParent: 0,
        Path: "circulares",
        Description: "Aquí puedes crear una circular y encontrarás la lista de todas las circulares que han sido enviadas y la información que contienen.",
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
    {
        Id: 53,
        IdParent: 52,
        Path: "circulares",
        Description: "Para ver el contenido de la circular enviada, debes hacer clic en la imagen a la izquierda del título.",
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
    {
        Id: 54,
        IdParent: 52,
        Path: "circulares",
        Description: "Para verificar a quién fue enviada la circular, puedes desplegar la flecha a lado de la imagen.",
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
    {
        Id: 55,
        IdParent: 52,
        Path: "circulares",
        Description: "Si haces clic en el título de la circular, se desplegará la lista de los alumnos a quienes se les envió y, si requería autorización, se mostrará quién respondió y cuál fue la respuesta.",
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
    {
        Id: 56,
        IdParent: 52,
        Path: "circulares",
        Description: "Puedes descargar la información cuando seleccionas una o más circulares de la tabla.",
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
    // Logros / Logros
    {
        Id: 57,
        IdParent: 0,
        Path: "logros/logros",
        Description: "Aquí puedes crear un logro y encontrarás la lista de todos los logros y la información de configuración.",
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
    {
        Id: 58,
        IdParent: 57,
        Path: "logros/logros",
        Description: `Puedes agregar un logro nuevo de forma individual con "Agregar" o crear varios a la vez usando "Carga masiva".`,
        Module: Entidad.CModulo.Logros,
        Action: Entidad.CAccionPermiso.Agregar,
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
    {
        Id: 59,
        IdParent: 57,
        Path: "logros/logros",
        Description: "Si seleccionas un logro, puedes editar su configuración.",
        Module: Entidad.CModulo.Logros,
        Action: Entidad.CAccionPermiso.Editar,
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
    {
        Id: 60,
        IdParent: 57,
        Path: "logros/logros",
        Description: "Puedes descargar la información cuando seleccionas una o más logros de la tabla.",
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
    // Logros / Categorias
    {
        Id: 61,
        IdParent: 0,
        Path: "logros/categorias",
        Description: "Aquí puedes crear una categoría para clasificar logros y encontrarás la lista de todos las existentes.",
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
    {
        Id: 62,
        IdParent: 61,
        Path: "logros/categorias",
        Description: "Si seleccionas una categoría, puedes editar su configuración.",
        Module: Entidad.CModulo.Logros,
        Action: Entidad.CAccionPermiso.Editar,
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
    {
        Id: 63,
        IdParent: 61,
        Path: "logros/categorias",
        Description: "Puedes descargar la información cuando seleccionas una o más categorías de la tabla.",
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
    // Usuarios
    {
        Id: 64,
        IdParent: 0,
        Path: "usuarios",
        Description: "Aquí puedes crear un nuevo usuario de Kidi Admin y encontrarás la lista de todos los usuarios existentes.",
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
    {
        Id: 65,
        IdParent: 64,
        Path: "usuarios",
        Description: "Si seleccionas un usuario, puedes editar sus datos, el tipo de usuario y los permisos de acceso del mismo.",
        Module: Entidad.CModulo.Usuarios,
        Action: Entidad.CAccionPermiso.Editar,
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
    {
        Id: 66,
        IdParent: 64,
        Path: "usuarios",
        Description: `También puedes recuperar los datos de acceso del usuario si lo seleccionas y eliges "Recuperar contraseña". La contraseña se puede enviar  al correo electrónico o al celular registrados.`,
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
    {
        Id: 67,
        IdParent: 64,
        Path: "usuarios",
        Description: "Puedes descargar la información cuando seleccionas una o más usuarios de la tabla.",
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
    // Reportes / Asistencias
    {
        Id: 68,
        IdParent: 0,
        Path: "reportes/asistencias",
        Description: "Aquí puedes consultar el reporte de asistencia de l@s alumn@s.",
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
    {
        Id: 69,
        IdParent: 68,
        Path: "reportes/asistencias",
        Description: "Para ver la información debes seleccionar, la(s) escuela(s), el año y el mes a consultar.",
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
    {
        Id: 70,
        IdParent: 68,
        Path: "reportes/asistencias",
        Description: "Puedes descargar la información cuando seleccionas una o más alumn@s de la tabla.",
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
    // Reportes / Eventos 
    {
        Id: 71,
        IdParent: 0,
        Path: "reportes/eventos",
        Description: "Aquí puedes consultar el reporte de todos los eventos registrados a l@s alumn@s.",
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
    {
        Id: 72,
        IdParent: 71,
        Path: "reportes/eventos",
        Description: "Para ver la información debes seleccionar, la(s) escuela(s), el año, el mes  y los eventos a consultar.",
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
    {
        Id: 73,
        IdParent: 71,
        Path: "reportes/eventos",
        Description: "Puedes descargar la información cuando seleccionas una o más alumn@s de la tabla.",
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
    // Reportes / Logros
    {
        Id: 74,
        IdParent: 0,
        Path: "reportes/logros",
        Description: "Aquí puedes consultar el reporte de los logros asignados a l@s alumn@s.",
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
    {
        Id: 75,
        IdParent: 74,
        Path: "reportes/logros",
        Description: "Para ver la información debes seleccionar, la(s) escuela(s), el año y el mes a consultar.",
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
    {
        Id: 76,
        IdParent: 74,
        Path: "reportes/logros",
        Description: "Puedes descargar la información cuando seleccionas una o más alumn@s de la tabla.",
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
    // Ayuda / Admin
    {
        Id: 77,
        IdParent: 0,
        Path: "ayuda/admin",
        Description: "Aquí puedes consultar los tutoriales que explican el uso de Kidi Admin.",
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
    // Ayuda / Maestros
    {
        Id: 78,
        IdParent: 0,
        Path: "ayuda/maestros",
        Description: "Aquí puedes consultar los tutoriales que explican el uso de Kidi Teachers.",
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
    // Ayuda / Papás
    {
        Id: 79,
        IdParent: 0,
        Path: "ayuda/papas",
        Description: "Aquí puedes consultar los tutoriales que explican el uso de Kidi Papás.",
        Modificacion: "2022-03-25T15:54:44.617Z"
    },
]

export default {
    _ObtenerViewInfo,
    _VIEWINFO
}
