import * as d3 from "d3";
import { DataDRequest } from "../../data/DRequest";
import { Entidad } from "../../data/Entidad";
import { UIUtilLang } from "../util/Language";
import { UIUtilTime } from "../util/Time";
import { UIUtilGeneral } from "../util/Util";
import { ModalThings } from "./ModalThings";
import { NotificacionV2 } from "./NotificacionV2";
import { InputFileDialog } from "./InputFileDialog";
import { Table } from "./Tabla";
import { ItemsController } from "./AnyItemsContainerController";
import { ExcelThings } from "./ExcelExport";
import ExcelJS from 'exceljs'
import { UIUtilFormat } from "../util/Format";

export namespace ExcelThingsV2 {
    import CSexo = Entidad.CSexo;
    type TPublicData<T> = T & { _ID: number };
    type TKD<TData> = (keyof TData & string);
    type TError = ("ongettemplate" | "onloadfiles");
    type TRowDefaultValidators = ("uniquerow");
    type TCellDefaultValidators = ("uniquecell" | "required" | "phonefmt" | "emailfmt" | "generofmt" | "datefmt");
    type TRequiredValueType = (keyof TRequiredValueTypeMap & string); // ("string" | "date" | "number" | "boolean"); // | ((value: any) => any));
    type TRequiredValueTypeMap = {
        "string": string;
        "number": number;
        "boolean": boolean;
        "date_defaultUTC": Date;
        "date_localUTC": Date;
        "enumsexo": CSexo;
    };
    type TColumnsConfig<TData> = {
        [DataKey in TKD<TData>]: {
            [RequiredTV in TRequiredValueType]: IImportExcelColumn<DataKey, TData[DataKey], RequiredTV>;
        }[TRequiredValueType];
    }[TKD<TData>];

    interface IImportExcelConfig<TData> {
        Columns: TColumnsConfig<TData>[]; //IImportExcelColumn<TData>[];
        /** @default 1 */
        StartsRowTable?: number;
        /** "A" | "B" | ... | "Z"
         * @default "A"
        */
        StartsColumnTable?: Uppercase<string>;
        // /** @example ["B", "F"] */
        // ColumnsRangeToRead: [string, string];
        /** urlString | excelTemplateFile */
        /** @default true */
        ValidateUniqueItems?: boolean;
        TableMinWidth?: number;
        TableId: string;
        /** @default 1200 */
        ModalWidth?: number;
        OnGetTemplateFile: () => /* string | */ | Promise<Boolean>;
        OnGetMain_DefaultTop?: () => Table.ITableMenuTopDefaultOptionConfig[];
        // OnGetMain_ToCheckedData?: (dataChecked: TData[]) => Table.ITableMenuDataSelectedOptionConfig<TData>[];
        OnError?: (type: TError) => void;
        OnStepCellTableUI?: (container: TSelectionHTML<"div">, datum: TData, field: TKD<TData>) => void;
        ConfigActionUploadSelection: {
            [k in "oneByOne" | "allByOne" | never]: {
                /** key para obtener etiqueta Lang
                 * @default "tag_assignregts"
                 * */
                TagKey?: string;
                /** 
                 * * "onByOne": itera los datos para procesarlos, se realiza un request por dato
                 * * "allByOne": todos los datos se procesan en el mismo servicio
                 * @default "allByOne"
                 * */
                TypeUpload?: k;
                OnCallBeforeToProccess?: () => Promise<boolean>;
                OnCallService: (dataSelected: (k extends "oneByOne" ? TPublicData<TData> : TPublicData<TData>[])) => Promise<DataDRequest.IRequestResponseA<unknown>>;
                OnCallItemErrorTag?: k extends "oneByOne" ? (itemData: TData) => string : never;
            }
        }["oneByOne" | "allByOne" | never]
        // OnValideRow?: (datum: TData, errors: unknown) => boolean;
    }

    interface IImportExcelColumn<DataKey, DataValueType, RequiredTypeValue extends TRequiredValueType> {
        Field: DataKey;
        Label: string;
        /** @default (columnsLength / 100) % */
        UIWidthTH?: string;
        /** @default "100px" */
        UIMinWidthTH?: string;
        /**
         * @default index in Array. Starts: 0
        */
        IndexColumnInFile?: number;

        /** Se usa para evaluar mapear los datos originales leídos.
         * * Ajusta el valor a algo más comprensible
         * * Se toma en cuenta que no siempre se puede controlar el contenido de las celdas del archivo
         // * @default "string"
         * */
        RequiredValueType?: RequiredTypeValue;
        /** Se invoca después de evaluar los datos con "RequiredValueType" */
        OnGetFixedValueMapped?: (adaptedValue: RequiredTypeValue, cellValue: (string | number | boolean | Date | null)) => DataValueType;
        // OnGetFixedValueMapped?: (cellValue: (TRequiredValueTypeMap[RequiredTypeValue])) => DataValueType;

        /** @default "plane" */
        ValueFormat?: "plane" | "phonenumber" | "email" | "genero" | "dt_ddmmyyyy" | "dt_hhmm" | "excel_origin";
        /** @default false */
        Required?: boolean;
        /** @default false */
        UniqueValue?: boolean;

        OnGetErrorType?: ((value: DataValueType /*, allData: TData[]*/) => { ErrorType: string, Valid: boolean });
        /** El mensaje de error se visualiza en la celda */
        OnGetErrorMessage?: ((value: DataValueType, errorType: string) => string | [string, string]);
    }

    interface IData<TData> {
        Id: number;
        File: File;
        // Sheet: string;

        ExcelData: { [k in keyof TData]: ExcelJS.CellValue };
        // CellValue: ExcelJS.CellValue;
        MappedData: TData;
        ValideInfo: IValideDataInfo<TData>;
    }

    interface IValideDataInfo<TData> {
        RowValid: boolean;
        /**
         * * Los mensajes a nivel de fila se muestran en un tooltip
         * * Internamente el control maneja TRowDefaultErrors
         * */
        RowErrorTypes: {
            [k in TRowDefaultValidators]: IRowValidatorInfo<k>;
        };
        RowValideFields: Map<
            (keyof TData & string),
            {
                [k in (TCellDefaultValidators)]: ICellValidatorInfo<k>;
                // CellValid: boolean;
                // CellInfoErrors: {
                //     CellErrorUILocation: ("tooltip" | "cellmessage");
                //     /** Internamente el control maneja TCellDefaultErrors */
                //     CellErrorType: TCellDefaultValidators;
                // }[];
            }
        >;
    }

    interface IRowValidatorInfo<TValidator extends TRowDefaultValidators> {
        Valid: boolean;
        Repetidos?: TValidator extends "uniquerow" ? number : never;
    }

    interface ICellValidatorInfo<TValidator extends TCellDefaultValidators> {
        // CellErrorUILocation: ("tooltip" | "cellmessage");
        Valid: boolean;
        Repetidos?: TValidator extends "uniquecell" ? number : never;
    }

    interface IFileLoadedItem {
        File: File;
        Message: string;
        Color: string;
        NData: number;
    }

    const ACCEPTFILES = [".xlsx", ".xltx", ".xlsm"];
    const MAXFILES = 5;
    const ABC = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
    const STRSEXOTAGS = {
        [Entidad.CSexo.Femenino]: [
            "Femenino"
        ],
        [Entidad.CSexo.Masculino]: [
            "Masculino"
        ],
        [Entidad.CSexo.Default]: [
            "", null, undefined
        ]
    }

    /**
     * * El reserva el parámetro "_ID" en la entidad de los datos 
     * */
    export function _UploadExcelData<TData>(config: IImportExcelConfig<TData>) {
        // const readonly config: IImportExcelConfig<TData>;
        let dataLoaded: IData<TData>[];

        let ctrlModal: ModalThings.IModalThings;
        let ctrlFilesLoaded: ItemsController.ItemsController<IFileLoadedItem>;
        let ctrlTabla: Table.Tabla<IData<TData>>;

        // constructor(config: IImportExcelConfig<TData>) {
        config = <IImportExcelConfig<TData>>{
            ...config
        };

        config.StartsRowTable = (config.StartsRowTable || 1);
        config.StartsColumnTable = (config.StartsColumnTable || "A");
        config.ValidateUniqueItems = (config.ValidateUniqueItems == null ? true : config.ValidateUniqueItems);

        config.Columns = (config.Columns || [])
            .map((d, index) => {
                const dd = <IImportExcelColumn<any, any, any>>{
                    ...{
                        Required: false,
                        UniqueValue: false,
                        ValueFormat: "plane"
                    },
                    ...d
                };

                if (dd.IndexColumnInFile == null) {
                    dd.IndexColumnInFile = index;
                }

                dd.UIWidthTH = (dd.UIWidthTH || (config.Columns.length / 100) + "%");
                dd.UIMinWidthTH = (dd.UIMinWidthTH || "100px");
                dd.RequiredValueType = (dd.RequiredValueType || "string");

                return dd;
            })

        // if (config) {
        // UI_Init();
        // }
        // }

        /**
         * * Da valor a "Id" de cada dato
         */
        async function Controller_LoadFiles(files: File[]) {
            const startsRows = config.StartsRowTable + 1; // La fila inicial pertenece a los encabezados de columna
            let indexOfColumn = ABC.indexOf(config.StartsColumnTable);
            let auxLetter = "";

            const columnsConfig = config.Columns
                .map((d) => {
                    let letter = ABC[indexOfColumn]; // NOTE Despues de la "Z" no continúa

                    if (letter == null) { // NOTE // DOTEST
                        indexOfColumn = 0;
                        let auxIndex = ABC.indexOf(auxLetter) + 1;
                        auxLetter = ABC[auxIndex];
                        letter = ABC[indexOfColumn];
                    }

                    letter = (auxLetter + letter);

                    let col: any = {
                        ...d,
                        ...{
                            Letter: letter
                        }
                    }
                    indexOfColumn++;

                    return col;
                });

            const filesLoaded: IFileLoadedItem[] = files
                .map(file => ({
                    File: file,
                    Color: null,
                    Message: null,
                    NData: 0
                }));

            const fnGetDataSheet = (worksheet: ExcelJS.Worksheet, file: File, initIdData: number): IData<TData>[] => {
                let dataSheet: IData<TData>[] = [];
                const maxRows = worksheet.actualRowCount;

                if (worksheet.getRow(config.StartsRowTable).actualCellCount >= columnsConfig.length) {
                    for (let iRow = startsRows; iRow <= maxRows; iRow++) {
                        let row = worksheet.getRow(iRow);
                        let itemDataSheet = <IData<TData>>{
                            Id: initIdData,
                            File: file,
                            ExcelData: {},
                            MappedData: <TData>{}
                        };

                        columnsConfig
                            .forEach((columnConfig, i) => {
                                let rowCell = row.getCell(columnConfig.Letter);
                                let mappedValue = GetExcelCellValueFixed(columnConfig, rowCell.value);

                                itemDataSheet.ExcelData[columnConfig.Field] = rowCell.value;
                                itemDataSheet.MappedData[columnConfig.Field] = mappedValue;
                            });

                        dataSheet.push(itemDataSheet);
                        initIdData++;
                    }
                }

                return dataSheet;
            }

            let auxIdData = 1;
            dataLoaded = [];

            // >> Read data
            for (const fileL of filesLoaded) {
                let workbook: ExcelJS.Workbook;

                //
                await ExcelThings._LoadWorkbook(fileL.File)
                    .then(wb => {
                        if (wb) {
                            workbook = wb;
                        }
                    })
                    .catch(e => {
                    });

                if (!workbook) {
                    fileL.Color = "red";
                    fileL.Message = UIUtilLang._GetUIString("crgamasiva", "tag_noread");
                    continue;
                }

                // Read Sheets
                workbook
                    .eachSheet((worksheet, id) => {
                        let dataSheet = fnGetDataSheet(worksheet, fileL.File, auxIdData);
                        dataLoaded.push(...dataSheet);

                        fileL.NData += dataSheet.length;

                        auxIdData += dataSheet.length;
                    });
            }

            // >> Valide Data
            ValideData(dataLoaded);

            // >> Update top Current files view
            ctrlFilesLoaded._SetItems(
                ...filesLoaded
                    .map(item => ({
                        Id: item.File,
                        Data: item
                    }))
            );

            // >>
            UI_RefreshDataTable();
        }

        function GetExcelCellValueFixed(columnConfig: IImportExcelColumn<TKD<TData>, TData[TKD<TData>], TRequiredValueType>, cellValue: ExcelJS.CellValue) {
            const requiredValueType = columnConfig.RequiredValueType;
            const fnGetFixedValue = columnConfig.OnGetFixedValueMapped;
            let valOrigin: (string | number | boolean | Date) = null;
            let valAdapted = null;

            // >> Get cell value
            if (cellValue != null) {
                if ((cellValue as ExcelJS.CellHyperlinkValue).text != null) {
                    valOrigin = (cellValue as ExcelJS.CellHyperlinkValue).text;
                }
                else if ((cellValue as ExcelJS.CellFormulaValue).formula != null) {
                    valOrigin = (cellValue as ExcelJS.CellFormulaValue).formula;
                }
                else if (
                    (typeof cellValue == "number")
                    || (typeof cellValue == "boolean")
                    || (cellValue instanceof Date)
                ) {
                    valOrigin = cellValue;
                }
                else if (typeof cellValue == "string") {
                    valOrigin = cellValue.trim();
                }
            }

            // >> Fix value type to Required type
            if (requiredValueType == "string") {
                if (valOrigin == null) {
                    valAdapted = "";
                }
                else if (valOrigin instanceof Date) {
                    valAdapted = valOrigin.toISOString();
                } else {
                    valAdapted = String(valOrigin);
                }
            }
            else if (requiredValueType == "boolean") {
                valAdapted = Boolean(valOrigin);
            }
            else if (requiredValueType == "number") {
                valAdapted = Number(valOrigin);
                if (isNaN(valAdapted)) {
                    valAdapted == null;
                }
            }
            else if (requiredValueType == "date_defaultUTC" || requiredValueType == "date_localUTC") {
                const valOriginIsString = typeof valOrigin == "string";
                if (valOriginIsString && (valOrigin as string).includes("/")) {
                    let a = String(valOrigin).split("/").map(d => Number(d)).reverse();
                    valAdapted = new Date(a[0], a[1] - 1, a[2]); // TEMPORAL USAR Utils.Time.fn_GetValidDate
                }
                else if (valOriginIsString && (valOrigin as string).includes("-")) {
                    let a = String(valOrigin).split("-").map(d => Number(d)).reverse();
                    if (a[0] < 33) { // NOTE No puede empezar por Año
                        valAdapted = new Date(a[0], a[1] - 1, a[2]); // TEMPORAL USAR Utils.Time.fn_GetValidDate
                    }
                }
                else if (valOrigin instanceof Date) {
                    valAdapted = new Date(valOrigin);
                }
                if (!(valAdapted instanceof Date) || isNaN(Number(valAdapted))) { // TEMPORAL // REMOVER USAR Utils.Time.fn_GetValidDate
                    valAdapted == null;
                }
                if (valAdapted && requiredValueType == "date_localUTC") {
                    let utc = (valAdapted as Date).toISOString();
                    let dtStr = utc.split("T")[0];
                    let dtItems = dtStr.split("-");
                    let y = Number(dtItems[0]);
                    let m = Number(dtItems[1]) - 1;
                    let d = Number(dtItems[2]);

                    valAdapted = new Date(y, m, d);
                }
            }
            else if (requiredValueType == "enumsexo") {
                // if (typeof valOrigin == "string") {
                for (let enumS in STRSEXOTAGS) {
                    let indexTag = (STRSEXOTAGS[enumS] as Array<string>)
                        .indexOf(valOrigin as string);

                    if (indexTag > -1) {
                        valAdapted = Number(enumS) as CSexo;
                        break;
                    }
                }
                // }
            }

            if (fnGetFixedValue) {
                valAdapted = fnGetFixedValue(valAdapted, valOrigin);
            }

            return valAdapted;
        }

        function CountNDataForFile() {
            ctrlFilesLoaded._Data()
                .forEach(d => {
                    d.Data.NData = dataLoaded
                        .filter((dd) => (dd.File == d.Data.File))
                        .length;
                })
        }

        function ValideData(data: IData<TData>[]) {
            const columnsConfig = config.Columns;
            const validateUniqueItems = config.ValidateUniqueItems;

            data
                .forEach((d, i) => {
                    if (d.ValideInfo == null) {
                        d.ValideInfo = <IValideDataInfo<TData>>{
                            RowValid: true,
                            RowErrorTypes: {
                                "uniquerow": { Valid: true, Repetidos: 1 }
                            },
                            RowValideFields: new Map()
                        };
                    }

                    /** [field, value] */
                    let uniqueColumnValues: [(keyof TData & string), string][] = [];

                    columnsConfig
                        .forEach((colConfig) => {
                            let mappedFieldValue = d.MappedData[colConfig.Field] as any;

                            if (!d.ValideInfo.RowValideFields.has(colConfig.Field)) {
                                d.ValideInfo.RowValideFields.set(
                                    colConfig.Field,
                                    {
                                        "uniquecell": { Valid: true, /* CellErrorUILocation: "tooltip", */ Repetidos: 1 },
                                        "emailfmt": { Valid: true, /* CellErrorUILocation: "cellmessage" */ },
                                        "generofmt": { Valid: true, /* CellErrorUILocation: "cellmessage" */ },
                                        "phonefmt": { Valid: true, /* CellErrorUILocation: "cellmessage" */ },
                                        "required": { Valid: true, /* CellErrorUILocation: "cellmessage" */ },
                                        "datefmt": { Valid: true }
                                    }
                                );
                            }
                            let cellValideInfo = d.ValideInfo.RowValideFields.get(colConfig.Field);

                            // Agrupa datos para evaluar valor único
                            if (colConfig.UniqueValue) {
                                uniqueColumnValues.push([colConfig.Field, String(mappedFieldValue != null ? mappedFieldValue : "")]);
                                cellValideInfo.uniquecell.Valid = true;
                                cellValideInfo.uniquecell.Repetidos = 1;
                            }
                            // Evalua valor requerido
                            if (colConfig.Required) {
                                cellValideInfo.required.Valid = (mappedFieldValue != null && mappedFieldValue != "");
                            }
                            // Evalua el formáto
                            if (colConfig.ValueFormat != "plane") {
                                switch (colConfig.ValueFormat) {
                                    case "email":
                                        cellValideInfo.emailfmt.Valid = UIUtilFormat._EMAILREGEX.test(mappedFieldValue);
                                        break;
                                    case "phonenumber":
                                        cellValideInfo.phonefmt.Valid = ((String(mappedFieldValue).length == 10) && (isNaN(mappedFieldValue) == false));
                                        break;
                                    case "genero":
                                        cellValideInfo.generofmt.Valid = (mappedFieldValue == CSexo.Femenino || mappedFieldValue == CSexo.Masculino || mappedFieldValue == CSexo.Default);
                                        break;
                                    case "dt_ddmmyyyy":
                                    case "dt_hhmm":
                                        cellValideInfo.datefmt.Valid = !isNaN(Number(new Date(mappedFieldValue)));
                                        break;
                                }
                            }

                            // Evaluador personalizado
                            if (colConfig.OnGetErrorType) {
                                let extraEval = colConfig.OnGetErrorType(mappedFieldValue);

                                if (extraEval?.ErrorType) {
                                    if (cellValideInfo[extraEval.ErrorType] == null) {
                                        cellValideInfo[extraEval?.ErrorType] = <ICellValidatorInfo<any>>{
                                            // CellErrorUILocation: "cellmessage"
                                        };
                                    }
                                    (cellValideInfo[extraEval.ErrorType] as ICellValidatorInfo<any>).Valid = extraEval.Valid;
                                }
                            }
                        });

                    // Evalua datos contra toda la lista
                    let rowsRepetidos = 1;

                    if (validateUniqueItems || uniqueColumnValues.length) {
                        data
                            .forEach((df, indexf) => {
                                if (i != indexf) {
                                    // Evalua filas repetidas
                                    if (validateUniqueItems && (JSON.stringify(df.MappedData) == JSON.stringify(d.MappedData))) { // DOTEST
                                        rowsRepetidos++;
                                    }
                                    // Evalua valores de columna repetidos
                                    uniqueColumnValues
                                        .forEach(uniqueColInfo => {
                                            let field = uniqueColInfo[0];
                                            let mappedValueA = uniqueColInfo[1];
                                            let mappedValueB = String(df.MappedData[field] != null ? df.MappedData[field] : "");

                                            if (mappedValueA && (mappedValueA == mappedValueB)) {
                                                if (d.ValideInfo.RowValideFields.get(field).uniquecell.Valid) {
                                                    d.ValideInfo.RowValideFields.get(field).uniquecell.Valid = false;
                                                }
                                                d.ValideInfo.RowValideFields.get(field).uniquecell.Repetidos++;
                                            }
                                        });
                                }
                            });
                    }

                    if (validateUniqueItems) {
                        d.ValideInfo.RowErrorTypes.uniquerow.Valid = (rowsRepetidos == 1);
                        d.ValideInfo.RowErrorTypes.uniquerow.Repetidos = rowsRepetidos;
                    }

                    d.ValideInfo.RowValid = true;
                    // Define evaluación a la fila
                    for (let rowErrorType in d.ValideInfo.RowErrorTypes) {
                        if (d.ValideInfo.RowErrorTypes[rowErrorType] == false) {
                            d.ValideInfo.RowValid = false;
                            break;
                        }
                    }
                    if (d.ValideInfo.RowValid) {
                        for (let [_field, infoEvaluators] of d.ValideInfo.RowValideFields) {
                            for (let cellErrorType in infoEvaluators) {
                                if ((infoEvaluators[cellErrorType] as ICellValidatorInfo<any>).Valid == false) {
                                    d.ValideInfo.RowValid = false;
                                    break;
                                }
                            }
                            if (d.ValideInfo.RowValid == false) {
                                break;
                            }
                        }
                    }
                });
        }

        function Controller_Reset() {
            dataLoaded = [];
            ctrlFilesLoaded._Clear();

            UI_RefreshDataTable();
        }

        function Controller_RemoveDataItems(ids: number[], refreshUI = true) {
            ids
                .forEach(id => {
                    let indexToRemove = dataLoaded.findIndex((d, I) => (d.Id == id));

                    if (indexToRemove > -1) {
                        dataLoaded.splice(indexToRemove, 1);
                    }
                });

            CountNDataForFile();

            if (refreshUI) {
                UI_RefreshDataTable();
            }
        }

        function Controller_RemoveFileData(file: File, refreshUI = true) {
            dataLoaded = dataLoaded
                .filter(d => (d.File != file));

            if (refreshUI) {
                UI_RefreshDataTable();
            }
        }

        async function Controller_ProcesarData() {
            const actionConfig = config.ConfigActionUploadSelection;
            const dataToProccess = GetDataCheckedToShare();
            const typeProccess = actionConfig.TypeUpload || "allByOne"
            let dataSuccess: TPublicData<TData>[] = [];

            // >> Proccess data

            ctrlModal.Progress.attr("oculto", false);

            if (typeProccess == "allByOne") {
                let res = await actionConfig.OnCallService(dataToProccess as any);

                if (!res.Mensaje) {
                    res.Mensaje = UIUtilLang._GetHTTPMessage(res);
                }
                NotificacionV2._Mostrar(res.Mensaje, (res.Resultado > 0 ? "INFO" : "ADVERTENCIA"));

                if (res.Resultado > 0) {
                    dataSuccess.push(...dataToProccess);
                }
            }
            else if (typeProccess == "oneByOne") {
                let dataFail: TData[] = [];
                let resFails: DataDRequest.IRequestResponseA<unknown>[] = [];
                let anySuccessResult: DataDRequest.IRequestResponseA<unknown>;

                for (let itemD of dataToProccess) {
                    let res = await actionConfig.OnCallService(itemD as any);

                    if (res.Resultado > 0) {
                        dataSuccess.push(itemD);
                        if (anySuccessResult == null) {
                            anySuccessResult = res;
                        }
                    }
                    else {
                        dataFail.push(itemD);
                        resFails.push({ Mensaje: UIUtilLang._GetHTTPMessage(res), ...res });
                    }
                }

                if (anySuccessResult) {
                    if (!anySuccessResult.Mensaje) {
                        anySuccessResult.Mensaje = UIUtilLang._GetHTTPMessage(anySuccessResult);
                    }
                    NotificacionV2._Mostrar(anySuccessResult.Mensaje, "INFO");
                }
                if (dataFail.length) {
                    ModalThings._GetModalInfoDataList({
                        DataList: dataFail
                            .map((d, i) => actionConfig.OnCallItemErrorTag(d) +
                                (!resFails[i].Mensaje ? "" : `<br><i style="color:var(--color_app_red1);">${resFails[i].Mensaje}</i>`)),
                        Title: UIUtilLang._GetUIString("general", "failresume"),
                        InfoText: UIUtilLang._GetUIString("crgamasiva", "tag_failregs"),
                        Width: 330
                    });
                }
            }
            ctrlModal.Progress.attr("oculto", true);

            // >> Update control ui
            Controller_RemoveDataItems(dataSuccess.map(d => d._ID));
        }

        async function Controller_CerrarModal() {
            let cerrar = true;
            ctrlModal.Modal._DeshabilitarBtns();
            if (dataLoaded?.length) {
                cerrar = await ModalThings._GetConfirmacionBasico(
                    UIUtilLang._GetUIString("crgamasiva", "tag_exit"),
                    UIUtilLang._GetUIString("confirmation", "cargamasivaconfirmasalir")
                )
            }

            if (cerrar) {
                ctrlModal.Modal._Ocultar();
                ctrlFilesLoaded = null;
                ctrlModal = null;
                ctrlTabla = null;
                dataLoaded = null;
                config = null;
            } else {
                ctrlModal.Modal._HabilitarBtns();
            }
        }

        /** OpenAndDrawModal */
        function UI_Init() {
            ctrlModal = ModalThings._GetModalToAProccess({
                Title: UI_GetString("tag_header"),
                Width: (config.ModalWidth || 1200),
                Height: "800px",
                DrawContent: (container, modalThings) => {
                    container
                        .classed(UIUtilGeneral.FBoxOrientation.Vertical, true);

                    UI_InitFilesItems(container);

                    UI_InitTable(container);
                }
            });

            ctrlModal.Modal._BtnCloseSelection
                .node()
                .onclick = async () => Controller_CerrarModal()
        }

        function UI_InitFilesItems(container: TSelectionHTML<"div">) {
            ctrlFilesLoaded = new ItemsController.ItemsController<IFileLoadedItem>({
                // ShowAreaClearBtn: false,
                Container: container,
                OnChange: () => {
                    if (ctrlFilesLoaded._Data().length > 0) {
                        ctrlFilesLoaded._ControlSel
                            .style("margin-bottom", "10px");
                    } else {
                        ctrlFilesLoaded._ControlSel
                            .style("margin-bottom", null);
                    }
                },
                OnRemoveItem: (id: File, dataFile: IFileLoadedItem) => {
                    Controller_RemoveFileData(dataFile.File);
                },
                OnStepItem: (container, dataFile, id: File) => {
                    if (!container.classed(UIUtilGeneral.FBoxOrientation.Vertical)) {
                        container.classed(UIUtilGeneral.FBoxOrientation.Vertical, true);
                    }
                    // Tag Filename
                    let lblFileName = container.select("label");
                    if (!lblFileName.node()) {
                        lblFileName = container.append("label");
                    }
                    lblFileName
                        .text(dataFile.File.name);

                    let spanError = container.select("span");

                    if (!spanError.node()) {
                        spanError = container.append("span")
                            .style("font-size", "12px")
                    }
                    // Tag File Message
                    if (dataFile.Message) {
                        spanError
                            .text(dataFile.Message)
                            .style("color", dataFile.Color);
                    } else {
                        spanError
                            .text(
                                `(${UIUtilLang._GetUIString("crgamasiva", "tag_ndata")
                                    .replace("_N", dataFile.NData.toString())})`
                            )
                            .style("color", ((dataFile.NData == 0) ? "red" : null));
                        // container.select("span").remove();
                    }
                }
            });

            ctrlFilesLoaded._ControlSel
                .style("padding", 0);
        }

        function UI_InitTable(container: TSelectionHTML<"div">) {
            const columnsConfig = new Map(config.Columns.map(d => ([d.Field, d])));
            const topOpcExtra = config.OnGetMain_DefaultTop ? config.OnGetMain_DefaultTop() : [];

            ctrlTabla = new Table.Tabla<IData<TData>>({
                IdTabla: config.TableId,
                Parent: container,
                IdData: "Id",
                RenderColumnHeadings: config.Columns
                    .map<Table.IColumn<IData<TData>>>(d => ({
                        Field: d.Field as any,
                        Label: d.Label,
                        Width: d.UIWidthTH,
                        MinWidth: d.UIMinWidthTH,
                        OrderField: "MappedData." + d.Field as any,
                    })),
                MinWidth: config.TableMinWidth,
                FilterByStrSearch: (datum) => [JSON.stringify(datum.MappedData)],
                OnValueSelectRow: (id, datum) => console.log(id, datum),
                OptionsTopDefaultV2: {
                    MaxOptionsInRow: 3,
                    Options: [
                        {
                            Label: UI_GetString("action_cargadts"),
                            Callback: () => InputFileDialog._Open({
                                AcceptExtensions: ACCEPTFILES,
                                MaxFiles: MAXFILES,
                                OnLoad: (files) => {
                                    dataLoaded = [];
                                    Controller_Reset();
                                    Controller_LoadFiles(files);
                                }
                            })
                        },
                        {
                            Label: UI_GetString("action_downtemplate"),
                            Callback: async () => {
                                ctrlModal.Progress.attr("oculto", false);

                                const getTemplateResult = await config.OnGetTemplateFile();

                                if (!getTemplateResult && config.OnError) {
                                    config.OnError("ongettemplate");
                                    NotificacionV2._Mostrar(UIUtilLang._GetUIString("general", "notif_fail"), "ADVERTENCIA");
                                }

                                ctrlModal.Progress.attr("oculto", true);

                            }
                        },
                        ...topOpcExtra
                    ]
                },
                OptionsOfDataCheckV3: () => ({
                    MaxOptionsInRow: 3,
                    Options: [
                        {
                            Label: UIUtilLang._GetUIString("crgamasiva", config.ConfigActionUploadSelection.TagKey || "tag_assignregts"),
                            Callback: async (datos) => {
                                if (config.ConfigActionUploadSelection.OnCallBeforeToProccess) {
                                    let continuar = await config.ConfigActionUploadSelection.OnCallBeforeToProccess();
                                    if (!continuar) {
                                        return;
                                    }
                                }
                                Controller_ProcesarData();
                            }
                        },
                        // ...toCheckedDataOpcExtra
                    ]
                }),
                EvaluatorAndSubLevelsBuild: {
                    OnEvalIsEnableRow: (datum) => datum.ValideInfo.RowValid,
                    OnStepRowTable: (datum, tr, rowBody) => {
                        let strTooltipErrors: string[] = [];

                        if (!datum.ValideInfo.RowErrorTypes.uniquerow.Valid) {
                            tr.selectAll("td")
                                .style("background-color", "rgb(246, 234, 234)");
                            strTooltipErrors.push(
                                UIUtilLang._GetUIString("crgamasiva", "tag_repited")
                                    .replace("_N", datum.ValideInfo.RowErrorTypes.uniquerow.Repetidos.toString())
                            );
                        } else {
                            tr.selectAll("td")
                                .style("background-color", null)
                        }

                        // for (let [field, validationInfo] of datum.ValideInfo.RowValideFields) {
                        //     if (!validationInfo.uniquecell.Valid) {
                        //         const columnConfig = columnsConfig.get(field);

                        //         if (!validationInfo.uniquecell.Valid) {
                        //             strTooltipErrors.push(
                        //                 UIUtilLang.fn_GetUIString("crgamasiva", "tag_repited2")
                        //                     .replace("_FIELD", columnConfig.Label)
                        //                     .replace("_VALUE", (datum.MappedData[field] as unknown as string))
                        //                     .replace("_N", validationInfo.uniquecell.Repetidos.toString())
                        //             )
                        //         }
                        //     }
                        // }

                        // console.warn(datum.Id, strTooltipErrors)
                        if (strTooltipErrors.length) {
                            if (!rowBody.select("wc-tooltip").node()) {
                                rowBody.append("wc-tooltip")
                                    .attr("position", "cursor")
                                    .attr("max-width", "300px");
                            }

                            rowBody.select("wc-tooltip")
                                .html(strTooltipErrors[0])
                        } else {
                            rowBody.select("wc-tooltip")
                                .remove();
                        }
                    },
                    OnStepCellTable: (container, datum, field: TKD<TData>) => {
                        const columnConfig = columnsConfig.get(field);
                        const fieldValue = datum.MappedData[field] as any;
                        const cellValidators = datum.ValideInfo.RowValideFields.get(field);

                        if (!container.classed(UIUtilGeneral.FBoxOrientation.Vertical)) {
                            container.classed(UIUtilGeneral.FBoxOrientation.Vertical, true);
                            container.append("div")
                                .attr("class", "content");
                        }

                        let content = container.select<HTMLDivElement>(".content");

                        // Formato de dibujo de texto
                        switch (columnConfig.ValueFormat) {
                            case "excel_origin":
                                content.text(datum.ExcelData[field] as string);
                                break;

                            case "email":
                                let email = (fieldValue || "");

                                if (!content.select("a").node()) {
                                    content.append("a");
                                }

                                content.select("a")
                                    .attr("href", "mailto:" + email)
                                    .attr("target", "_blank")
                                    .style("color", "#676767")
                                    .text(email);
                                break;

                            case "genero":
                                // content.text(Utils.ViewData.fn_GetStr_Sexo(fieldValue));
                                content.text(datum.ExcelData[field] as any);
                                break;

                            case "phonenumber":
                                let phonenumber = (fieldValue || "");

                                if (!content.select("a").node()) {
                                    content.append("a");
                                }

                                content.select("a")
                                    .attr("href", "tel:" + phonenumber)
                                    .attr("target", "_blank")
                                    .style("color", "#676767")
                                    .text(phonenumber);
                                break;

                            case "dt_ddmmyyyy":
                            case "dt_hhmm":
                                if (cellValidators.datefmt.Valid) {
                                    if (columnConfig.ValueFormat == "dt_ddmmyyyy") {
                                        content.text(UIUtilTime._DateFormatStandar(fieldValue));
                                    }
                                    else if (columnConfig.ValueFormat == "dt_hhmm") {
                                        content.text(UIUtilTime._DateFormatStandar(fieldValue, "h12:mm"));
                                    }
                                }
                                else {
                                    content.text(fieldValue);
                                }
                                break;

                            case "plane":
                            default:
                                content.text(fieldValue);
                                break;
                        }

                        if (config.OnStepCellTableUI) {
                            config.OnStepCellTableUI(content, datum.MappedData, field);
                        }

                        // Errores
                        // const fnGetTooltipRow = () => { // NOTE IMPLEMENTAR
                        //     let tooltip = container.node()["tooltip"] as controlD3.Tooltip<string>;

                        //     if (!tooltip) {
                        //         tooltip = new controlD3.Tooltip<string>({
                        //             Elemento: d3.select(container.node()?.parentElement?.parentElement),
                        //             contenido: d3.create("div").classed("info_row", true).node(),
                        //             MouseMove: true
                        //         })
                        //     }

                        //     return tooltip;
                        // }

                        // const fnRemoveTooltipRow = () => { // NOTE IMPLEMENTAR
                        //     let tooltip = container.node()["tooltip"] as controlD3.Tooltip<string>;
                        //     tooltip?.met_ForceRemove();
                        // }

                        // let strTooltipErrors: string[] = []; //NOTE IMPLEMENTAR
                        let strCellErrors: [string, string][] = [];

                        // if (!datum.ValideInfo.RowErrorTypes.uniquerow) {
                        //     strTooltipErrors.push("");
                        // }
                        if (!cellValidators.required.Valid) {
                            strCellErrors.push([
                                UIUtilLang._GetUIString("crgamasiva", "tag_requiredfield"),
                                null
                            ]);
                        }
                        else {
                            for (let typeError in cellValidators) {
                                if (typeError != "required" && !(cellValidators[typeError] as ICellValidatorInfo<any>).Valid) {
                                    switch (typeError) {
                                        case "uniquecell":
                                            strCellErrors.push([
                                                UIUtilLang._GetUIString("crgamasiva", "tag_repited3")
                                                    .replace("_N", (cellValidators[typeError] as ICellValidatorInfo<any>).Repetidos.toString()),
                                                null
                                            ])
                                            break;
                                        case "emailfmt":
                                            strCellErrors.push([
                                                UIUtilLang._GetUIString("crgamasiva", "tag_incorrctfrmt"),
                                                UIUtilLang._GetUIString("crgamasiva", "tag_emailexample")
                                            ]);
                                            break;
                                        case "generofmt":
                                            strCellErrors.push([
                                                UIUtilLang._GetUIString("crgamasiva", "tag_incorrcttipo"),
                                                UIUtilLang._GetUIString("crgamasiva", "tag_femmasc")
                                            ]);
                                            break;
                                        case "phonefmt":
                                            strCellErrors.push([
                                                UIUtilLang._GetUIString("crgamasiva", "tag_incorrctfrmt"),
                                                UIUtilLang._GetUIString("crgamasiva", "tag_telexample")
                                            ]);
                                            break;
                                        case "datefmt":
                                            strCellErrors.push([
                                                UIUtilLang._GetUIString("crgamasiva", "tag_incorrctfrmt"),
                                                UIUtilLang._GetUIString("crgamasiva", "tag_dtexample")
                                            ]);
                                            break;
                                        default:
                                            if (columnConfig.OnGetErrorMessage) {
                                                let messageError = columnConfig.OnGetErrorMessage(datum.MappedData[field], typeError);
                                                if (typeof messageError == "string") {
                                                    strCellErrors.push([messageError, null]);
                                                }
                                                else if (messageError instanceof Array) {
                                                    strCellErrors.push(messageError);
                                                }
                                            }
                                            break
                                    }
                                }
                            }
                        }

                        const fnUpdateCellError = (label: d3.Selection<HTMLLabelElement, [string, string], any, any>) => {
                            return label.each((d, i, lbls) => {
                                let lbl = d3.select(lbls[i])
                                    .text(d[0]);

                                if (d[1]) {
                                    lbl.on("mouseenter", () => lbl.text(d[1]));
                                    lbl.on("mouseleave", () => lbl.text(d[0]));
                                }
                                else {
                                    lbl.on("mouseenter", null);
                                    lbl.on("mouseleave", null);
                                }
                            });
                        }

                        container.selectAll<HTMLLabelElement, [string, string]>(":scope > .lbl_error")
                            .data(strCellErrors)
                            .join(
                                enter => fnUpdateCellError(
                                    enter.append("label")
                                        .attr("class", "lbl_error")
                                        .style("color", "#b30000")
                                        .style("font-size", "12px")
                                        .style("font-style", "italic")
                                        .style("backgound-color", "aliceblue")
                                ),
                                update => fnUpdateCellError(update),
                                exit => exit.remove()
                            )
                    }
                }
            })
        }

        function UI_RefreshDataTable() {
            console.info("Excel input data: ", dataLoaded);
            ctrlTabla._UpdateData(dataLoaded);
        }

        function UI_GetString(stringKey: string) {
            return (UIUtilLang._GetUIString("crgamasiva", stringKey) || stringKey);
        }

        // ************************************************************************
        // DATA
        // ************************************************************************

        function GetDataLoadedToShare() {
            return dataLoaded
                .map<TPublicData<TData>>(d => ({
                    ...{
                        "_ID": d.Id
                    },
                    ...d.MappedData
                }));
        }

        function GetDataCheckedToShare() {
            return ctrlTabla._dataChecked
                .map<TPublicData<TData>>(d => ({
                    ...{
                        "_ID": d.Id
                    },
                    ...d.MappedData
                }));
        }

        // ************************************************************************

        UI_Init();

        return {
            GetDataTable: () => GetDataLoadedToShare(),
            GetDataFull: () => dataLoaded,
            GetDataTableChecked: () => GetDataCheckedToShare(),
            DeleteTableRows: (..._IDs: number[]) => Controller_RemoveDataItems(_IDs, true)
        }
    }
}
