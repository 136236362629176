import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import { UIUtilLang } from "../util/Language";

export namespace UIUtilViewFinanzaEgresoCategoria {
    export const _ID_EGRESO_GENERAL = 0;

    export function _CategoriaNombre(idCategoria: number): string {
        return (idCategoria == 0
            ? UIUtilLang._GetUIString("general", "general")
            : (DataModuloMain._GetDataValueFieldByName("EgresoCategoria", idCategoria, "Nombre") || UIUtilLang._GetUIString("general", "nodisponible")))
    }

    /** returns
    * `[item_general, ...(categorias || categorias_escuela)]`
    */
    export function _GetCategoriasFullList(idEscuela?: number): Entidad.IFinanzasEgresoCategoria[] {
        const idEgresoGeneral = UIUtilViewFinanzaEgresoCategoria._ID_EGRESO_GENERAL;
        const generalItem: Partial<Entidad.IFinanzasEgresoCategoria> = { Id: idEgresoGeneral, Nombre: UIUtilLang._GetUIString("general", "general") };
        const datos: Entidad.IFinanzasEgresoCategoria[] = [
            generalItem as any,
            ...DataModuloMain._GetReqDataArrayByName("EgresoCategoria", true)
        ]
        if (!idEscuela) {
            return datos;
        }
        return datos.filter(d => (d.Id == idEgresoGeneral || d.IdEscuela == idEscuela));
    }
}
