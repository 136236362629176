import { MainPage } from "../../MainPage";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import { _DICC_ALUMNO } from "../../data/modulo/Alumno";
import DataModuloEscuela from "../../data/modulo/Escuela";
import DataModuloFinanzaCargo from "../../data/modulo/FinanzaCargo";
import { _FinanzaHoraExtraGenerarCargo, _FinanzaHoraExtraIgnorarRegistro } from "../../data/modulo/FinanzaHoraExtra";
import { DateV2 } from "../../util/DateV2";
import { ExcelThings } from "../controlD3/ExcelExport";
import { Fields, FormGenerator, IField } from "../controlD3/Formulario";
import { ModalThings } from "../controlD3/ModalThings";
import { NotificacionV2 } from "../controlD3/NotificacionV2";
import { Table } from "../controlD3/Tabla";
import { UIUtilFormat } from "../util/Format";
import { UIUtilLang } from "../util/Language";
import { UIUtilPermission } from "../util/Permission";
import { UIUtilTime } from "../util/Time";
import { UIUtilGeneral } from "../util/Util";
import { UIUtilViewData } from "../util/ViewData";
import { UIUtilViewFinanzaCargo } from "./FinanzaCargo";

export namespace UIUtilViewFinanzaHorasExtras {
    import CEstadoCobro = Entidad.CEstadoCobro;
    import CAccionPermiso = Entidad.CAccionPermiso;
    import CModulo = Entidad.CModulo;
    import ICargo = Entidad.IFinanzaCargo;
    import IEscuela = Entidad.IEscuela;
    import ICicloEscolar = Entidad.ICicloEscolar;
    import CCategoriaFinanza = Entidad.CFinanzaCargoCategoria;
    import CTipoHE = Entidad.CTipoHoraExtra;
    import GetValidatorDateYM = UIUtilTime._GetValidatorDateYM;
    import _FmtToInputDate = UIUtilTime._FmtToInputDate;

    import CTipoValor = Entidad.CFinanzaCargoTipoValor;


    type IHoraExtra = Entidad.IFinanzaItemHoraExtra & {
        /** Auxiliar */
        DtEsperada?: DateV2;
    }
    type IAlumno = Entidad.IAlumno & {
        HorasExtras?: Map<number, IHoraExtra>;
    };

    /** La estructura almacena información de la escuela a la que los cargos de alumnos pertenecen */
    interface IProcesoRegistroCargo { // NOTE Arreglo de IProcesoRegistroCargo cuando sea una seleccion multiple de alumnos a procesar
        CicloEscolar: ICicloEscolar;
        FechaAplicacion: DateV2;
        ItemsProcesoCargo: IItemAlumnoProcesoCargo[];

        Escuela: IEscuela;
        CargoEntrada: ICargo;
        CargoSalida: ICargo;
        CiclosEscolares: ICicloEscolar[]
    }

    interface IItemAlumnoProcesoCargo {
        IdAux: number;
        Alumno: IAlumno;
        ParentProceso: IProcesoRegistroCargo;
        Entradas: IItemAlumnoHorasExtrasSelected;
        Salidas: IItemAlumnoHorasExtrasSelected;
    }

    interface IItemAlumnoHorasExtrasSelected {
        // Cargo: ICargo;
        HorasExtras: IHoraExtra[];
        /** Suma a pagar */
        ValorPago: number;
    }

    interface ICargoInit {
        // Monto: number;
        Aplicacion?: string;
        CicloEscolar?: number;

        /** formato de moneda */
        CargoEntradaMonto?: string;
        CargoEntradaNombre?: string;
        CargoEntradaVencimiento?: string;
        CargoEntradaMorosidad?: string;
        CargoEntradaRecargo?: string;

        /** formato de moneda */
        CargoSalidaMonto?: string;
        CargoSalidaNombre?: string;
        CargoSalidaVencimiento?: string;
        CargoSalidaMorosidad?: string;
        CargoSalidaRecargo?: string;
    }

    interface IParamsRegistroCargo {
        CicloEscolar: ICicloEscolar;
        FechaAplicacion: Date;
        CargoEntrada: ICargo;
        CargoSalida: ICargo;
        Item: IItemAlumnoProcesoCargo;
    }

    enum CPagoInitModels {
        Aplicacion = "Aplicacion",
        CicloEscolar = "CicloEscolar",

        CargoEntradaMonto = "CargoEntradaMonto",
        CargoEntradaNombre = "CargoEntradaNombre",
        CargoEntradaVencimiento = "CargoEntradaVencimiento",
        CargoEntradaMorosidad = "CargoEntradaMorosidad",
        CargoEntradaRecargo = "CargoEntradaRecargo",

        CargoSalidaMonto = "CargoSalidaMonto",
        CargoSalidaNombre = "CargoSalidaNombre",
        CargoSalidaVencimiento = "CargoSalidaVencimiento",
        CargoSalidaMorosidad = "CargoSalidaMorosidad",
        CargoSalidaRecargo = "CargoSalidaRecargo",
    }

    const MODULO = CModulo.FinanzasHorasExtras
    const MODULONAME = CModulo[MODULO];
    const DATA_REQUEST_ID = Entidad.CTipoRequest.FinanzaHoraExtra;

    function GetStringUI(stringKey: string): string {
        return (UIUtilLang._GetUIString(MODULONAME, stringKey) || stringKey);
    }

    function GetEscuela(idEscuela: number) {
        return DataModuloEscuela._DiccEscuela.get(idEscuela);
    }

    function GetCiclosEscolaresByIdEsc(idEscuela: number) {
        const validatorCurrentDate = GetValidatorDateYM(new Date());
        return DataModuloMain._GetReqDataArrayByName("CicloEscolar")
            .filter(d => (d.IdEscuela == idEscuela && (GetValidatorDateYM(new Date(d.FechaFin)) >= validatorCurrentDate)));
    }

    //function GetExtraHoursToSee_ByCurrentDataInAModal(DatoChild: IAlumno, StatusToSee: CEstadoCobro[]): IHoraExtra[] {
    function GetExtraHoursToSee_ByCurrentDataInAModal(DatoChild: IAlumno): IHoraExtra[] {
        let alumnoD = DatoChild;
        //let statusToSee = StatusToSee;

        let horasExtrasToSee = Array.from(alumnoD.HorasExtras.values())
            //.filter(d => (statusToSee.indexOf(d.EstadoCobro) > -1))
            .sort((a, b) => (Number(new Date(b.Fecha))) - Number(new Date(a.Fecha)))
            .map<IHoraExtra>(d => {
                let dd = <IHoraExtra>Object.assign({}, d);

                // Campo auxiliar hora esperada de llegada/salida del alumno
                dd.DtEsperada = new DateV2(d.Fecha)
                    ._SetTimeZoneByIdSchool(d.IdEscuela)
                    ._ReplaceUTCHour(d.HoraEsperada);

                return dd;
            });

        return horasExtrasToSee;
    }

    function GetTotalesByHorasExtras(horasExtras: IHoraExtra[]) {
        let totalPago = 0;
        let minutosTotales = 0;
        let minutosCobrados = 0;
        horasExtras.forEach(item => {
            totalPago += item.Costo;
            minutosTotales += item.MinutosTotales;
            minutosCobrados += item.MinutosCobrados;
        });
        return {
            TotalPago: totalPago,
            MinTotales: minutosTotales,
            MinCobrados: minutosCobrados
        }
    }

    function GetItemAlumnoProcesoCargo(datoChild: IAlumno, horasExtra: IHoraExtra[]): IItemAlumnoProcesoCargo {
        let item: IItemAlumnoProcesoCargo = {
            IdAux: datoChild.IdChild,
            Alumno: datoChild,
            ParentProceso: null,
            Entradas: {
                ValorPago: 0,
                // Cargo: null,
                HorasExtras: []
            },
            Salidas: {
                ValorPago: 0,
                // Cargo: null,
                HorasExtras: []
            }
        }

        // Agrupa Entradas, Salidas
        horasExtra.forEach(d => {
            if (d.Tipo == CTipoHE.Llegada) {
                item.Entradas.HorasExtras.push(d);
            } else if (d.Tipo == CTipoHE.Salida) {
                item.Salidas.HorasExtras.push(d);
            }
        });

        if (item.Entradas.HorasExtras.length > 0) {
            item.Entradas.ValorPago = GetTotalesByHorasExtras(item.Entradas.HorasExtras).TotalPago;
        }
        if (item.Salidas.HorasExtras.length > 0) {
            item.Salidas.ValorPago = GetTotalesByHorasExtras(item.Salidas.HorasExtras).TotalPago;
        }

        return item;
    }

    export function _GetFinanzaCargoToHoraExtra(idEscuela: number, tipo: (CCategoriaFinanza.CargoEntrada | CCategoriaFinanza.CargoSalida)) {
        return Array.from(DataModuloFinanzaCargo._DiccFinanzasCargos.values())
            .find(d => (d.IdEscuela == idEscuela && d.Categoria == tipo))
    }

    export interface ModalHorasExtrasAlumno extends ModalThings.IModalThings {
        Tabla: Table.Tabla<IHoraExtra>;
        DatoChild: IAlumno;
        //StatusToSee: CEstadoCobro[];
        refresh: () => void;
    }

    //export function fn_OpenModal_VerHorasExtras(datoChild: IAlumno, readOnly: boolean = false, statusToSee = [CEstadoCobro.Generado, CEstadoCobro.Descartado]): ModalHorasExtrasAlumno {
    export function _OpenModal_VerHorasExtras(datoChild: IAlumno, readOnly: boolean = false): ModalHorasExtrasAlumno {
        let modalThingsPrincipal: ModalThings.IModalThings;
        let tableInfo: Table.Tabla<IHoraExtra>;
        let infoArea: TSelectionHTML<"div">;
        let modal: ModalThings.IModalThings;

        const fnUpdateInfoAres = (horas: IHoraExtra[]) => {
            if (!readOnly && infoArea) {
                let totales = GetTotalesByHorasExtras(horas);
                infoArea.html(`
                        <label>${GetStringUI("tag_tots")}</label>
                        <label>${GetStringUI("tag_totsmins")}: <span>${totales.MinTotales} min</span></label>
                        <label>${GetStringUI("tag_minscobrds")}: <span>${totales.MinCobrados} min</span></label>
                        <label>${GetStringUI("tag_cost")}: <span>${UIUtilFormat._CurrencyFmt(totales.TotalPago)}</span></label>
                    `)
            }
        }

        modalThingsPrincipal = ModalThings._GetModalToAProccess({
            Modulo: MODULO,
            LangModuleKeyInContext: MODULONAME,
            Title: GetStringUI("tag_extrahrs") + " - " + datoChild.NombreCompleto,
            Width: (readOnly ? 1000 : 1200),
            DrawContent: (container, mt) => {
                mt.Modal._Mostrar()
                mt.CurrentBody
                    .classed(UIUtilGeneral.FBoxAlign.StartStart, true)
                    .classed(UIUtilGeneral.FBoxOrientation.Horizontal, true)
                    .classed("horasextras_modalbody", true);
                const tableArea = mt.CurrentBody.append("div").attr("class", "area_table")
                    .style("width", readOnly ? "100%" : null);
                if (!readOnly) {
                    infoArea = mt.CurrentBody.append("div").attr("class", "area_info");
                }

                const fnGetStringTblField = function (he: IHoraExtra, field: keyof IHoraExtra) {
                    let str = "";
                    switch (field) {
                        case "Fecha":
                            str = UIUtilTime._DateFormatStandarFixTimeZoneByIdSchool(he.Fecha, he.IdEscuela, "dd/mm/yyyy h12:mm");
                            break;
                        case "Costo":
                            str = UIUtilFormat._CurrencyFmt(he.Costo);
                            break;
                        case "Tipo":
                            str = UIUtilViewData._GetStr_TipoHoraExtra(he.Tipo);
                            break;
                        case "EstadoCobro":
                            str = ((he.EstadoCobro != CEstadoCobro.Generado) ? UIUtilViewData._GetStr_EdoCobro(he.EstadoCobro) : "");
                            break;
                        case "DtEsperada":
                            str = UIUtilTime._DateFormatStandar(he.DtEsperada, "h12:mm");
                            break;
                        case "Tolerancia":
                            str = (he.Tolerancia + " min");
                            break;
                        default:
                            str = (he[field] as string || "");
                            break;
                    }
                    return str;
                }

                const fnExportData = (dataToExport: IHoraExtra[]) => {
                    if (!dataToExport.length || !UIUtilPermission._HasAccionPermission(CAccionPermiso.ExportarAExcel, MODULO, datoChild.IdKinder)) {
                        return;
                    }
                    ExcelThings._ExportData<IHoraExtra>({
                        FileName: (datoChild.NombreCompleto + " " + GetStringUI("export_filename")),
                        IdsEscuelas: [datoChild.IdKinder],
                        IdCategoria: 0,
                        Modulo: MODULO,
                        RequestType: DATA_REQUEST_ID,
                        ElementToAppendProgressBar: modalThingsPrincipal.Modal._BodySelection,
                        ColumnsConfig: tableInfo._InfoColumns
                            .map(d => ({
                                Field: d.Field,
                                HeaderTag: d.Label
                            })),
                        OnGetDataBySheets: async () => {
                            return [
                                {
                                    IdSheet: datoChild.IdChild,
                                    Data: dataToExport,
                                    SheetName: UIUtilViewData._GetStr_Modulo(MODULO)
                                }
                            ];
                        },
                        OnGetEscuelasTagInSheet: () => datoChild.NombreKinder,
                        OnStepFieldInDataRow: (field, he) => fnGetStringTblField(he, field)
                    })
                }

                tableInfo = new Table.Tabla<IHoraExtra>({
                    IdTabla: "FinanzasIngresos-HorasExtras-VerHorasExtra",
                    Parent: tableArea,
                    MinWidth: 800,
                    HideCheckboxes: readOnly,
                    IdData: "Id",
                    OnClickSyncTable: async () => {
                        modalThingsPrincipal.Progress.attr("oculto", false);
                        await MainPage._ReloadServiceAndAwait(DATA_REQUEST_ID, datoChild.IdKinder).catch((err) => {
                            NotificacionV2._Mostrar(UIUtilLang._GetUIString("general", "notif_fail_infoupdate"), "ADVERTENCIA");
                        });
                        //return GetExtraHoursToSee_ByCurrentDataInAModal(datoChild, statusToSee);
                        return GetExtraHoursToSee_ByCurrentDataInAModal(datoChild);
                    },
                    RenderColumnHeadings: [
                        { Field: "Fecha", Label: GetStringUI("tblsee_field_dt"), Width: "20%", MinWidth: "80px" },
                        { Field: "Costo", Label: GetStringUI("tblsee_field_cost"), Width: "10%", MinWidth: "80px" },
                        { Field: "Tipo", Label: GetStringUI("tblsee_field_tipo"), Width: "10%", MinWidth: "80px" },
                        { Field: "EstadoCobro", Label: GetStringUI("tblsee_field_edocobr"), Width: "15%", MinWidth: "80px" },
                        { Field: "DtEsperada", Label: GetStringUI("tblsee_field_dtesp"), Width: "10%", MinWidth: "80px" },
                        { Field: "Segmentos", Label: GetStringUI("tblsee_field_segmts"), Width: "10%", MinWidth: "80px" },
                        { Field: "Tolerancia", Label: GetStringUI("tblsee_field_tolrcia"), Width: "10%", MinWidth: "80px" },
                    ],
                    OnValueSelectRow: (id, datum, isselected) => {
                        console.log(id, datum, isselected); // To debug
                    },
                    FilterByStrSearch: readOnly ? "none" : "default",
                    OptionsTopDefaultV2: {
                        MaxOptionsInRow: 3,
                        Options: [{
                            Label: UIUtilLang._GetUIString("c_actions", "exportaraexcel"),
                            Callback: () => fnExportData(tableInfo._data)
                        }]
                    },
                    OptionsOfDataCheckV3: (datos) => ({
                        MaxOptionsInRow: 3,
                        Options: [
                            {
                                __ID: CAccionPermiso.GenerarCobro as const,
                                Label: GetStringUI("action_generacobro"),
                                Callback: () => {
                                    if (!UIUtilPermission._HasAccionPermission(CAccionPermiso.GenerarCobro, MODULO, datoChild.IdKinder)) {
                                        return;
                                    }
                                    let IDSChecked: string = '';
                                    tableInfo._dataChecked.forEach(d => {
                                        IDSChecked += `${d.Id}`;
                                    });
                                    console.log(IDSChecked);
                                    modal = InitProcesoGeneraCobroByAAlumno(datoChild, tableInfo._dataChecked, tableInfo);
                                }
                            },
                            {
                                __ID: CAccionPermiso.Ignorar as const,
                                Label: GetStringUI("action_ignorextrahr"),
                                Callback: () => {
                                    if (!UIUtilPermission._HasAccionPermission(CAccionPermiso.Ignorar, MODULO, datoChild.IdKinder)) {
                                        return;
                                    }
                                    modal = _OpenModal_ProcesoIgnorarHorasExtras(tableInfo._dataChecked, [datoChild]);
                                }
                            },
                            {
                                __ID: CAccionPermiso.ExportarAExcel as const,
                                Label: UIUtilLang._GetUIString("c_actions", "exportaraexcel"),
                                Callback: () => fnExportData(tableInfo._data)
                            }
                        ]
                            .filter(d => (
                                (!readOnly && (d.__ID == CAccionPermiso.GenerarCobro || d.__ID == CAccionPermiso.Ignorar))
                                || (d.__ID == CAccionPermiso.ExportarAExcel)
                            ))
                    }),
                    EvaluatorAndSubLevelsBuild: {
                        OnEvalIsEnableRow: (datum) => (!readOnly ? (datum.EstadoCobro == CEstadoCobro.Generado) : true),
                        OnCheckedData: (datosHoras, originEvent) => {
                            fnUpdateInfoAres(datosHoras);
                        },
                        OnStepCellTable: (container, datum, field: keyof IHoraExtra) => {
                            container.text(fnGetStringTblField(datum, field));
                        },
                        ShowNoContentTagRow: true,
                    }
                });

                //tableInfo.met_UpdateData(GetExtraHoursToSee_ByCurrentDataInAModal(datoChild, statusToSee));
                tableInfo._UpdateData(GetExtraHoursToSee_ByCurrentDataInAModal(datoChild));
                fnUpdateInfoAres([]);
                if (tableInfo._data.length > 7) {
                    mt.CurrentBody.style("height", "600px", "important");
                }
            }
        })

        return {
            ...modalThingsPrincipal,
            ...{
                Tabla: tableInfo,
                DatoChild: datoChild,
                //StatusToSee: statusToSee,
                refresh: () => {
                    let beforeItemsIdsChecked = ``;
                    tableInfo?._dataChecked.forEach(d => beforeItemsIdsChecked += `${d.Id}`);
                    //tableInfo?.met_UpdateData(GetExtraHoursToSee_ByCurrentDataInAModal(datoChild, statusToSee));
                    tableInfo?._UpdateData(GetExtraHoursToSee_ByCurrentDataInAModal(datoChild));
                    tableInfo?._CheckItemsByID(tableInfo._data.filter(d => d.EstadoCobro == CEstadoCobro.Descartado).map(d => d.Id), false)
                    fnUpdateInfoAres(tableInfo?._dataChecked);
                    let newItemsIdsChecked = ``;
                    tableInfo?._dataChecked.forEach(d => newItemsIdsChecked += `${d.Id}`);
                    if ((beforeItemsIdsChecked !== newItemsIdsChecked) && modal && modal.Modal._Visible) {
                        let modalErr = ModalThings._GetConfirmacionModal({
                            Title: GetStringUI("datoscomprometidos"),
                            Message: GetStringUI("msgg_datoscomprometidos"),
                            Width: 300,
                            OnAccept: () => {
                                modal.Modal._Ocultar();
                            },
                            OnClose: () => {
                                modal.Modal._Ocultar();
                            }
                        })
                        modalErr.BtnLeft.remove();
                    }
                    modalThingsPrincipal?.Progress.attr("oculto", true);
                }
            }
        }
    }

    export function _OpenModal_ProcesoIgnorarHorasExtras(horasExtras: IHoraExtra[], alumnos: IAlumno[]) {
        let modalT = ModalThings._GetModalToAProccess({
            LangModuleKeyInContext: MODULONAME,
            Title: "action_ignorextrahr",
            IdsEscuelas: alumnos.map(d => d.IdKinder),
            Modulo: MODULO,
            Action: CAccionPermiso.Ignorar,
            Width: 800,
            // AccionToHttpMessage: "agregar",
            DrawContent: (content, modalThings) => {
                content
                    .classed(UIUtilGeneral.FBoxOrientation.Vertical, true)
                    .style("height", "100%")
                    .style("overflow", "hidden")
                    .style("row-gap", "10px")
                    .append("div")
                    .text(GetStringUI("tag_regtsaignorar"));

                let ctrltablaIgnorados = new Table.Tabla<IHoraExtra>({
                    IdTabla: "FinanzasIngresos-HorasExtras-VerHorasIgnorar",
                    Parent: content,
                    IdData: "Id",
                    FilterByStrSearch: "none",
                    HideCheckboxes: true,
                    MinWidth: 600,
                    RenderColumnHeadings: [
                        { Field: "Alumno" as any, Label: GetStringUI("tblignor_field_alum"), MinWidth: "100px", Width: "25%", IsSortable: false },
                        { Field: "Costo", Label: GetStringUI("tblignor_field_cost"), MinWidth: "100px", Width: "20%", IsSortable: false },
                        { Field: "Tipo", Label: GetStringUI("tblignor_field_tipo"), MinWidth: "100px", Width: "15%", IsSortable: false },
                        { Field: "Fecha", Label: GetStringUI("tblignor_field_dt"), MinWidth: "100px", Width: "20%", IsSortable: false },
                        { Field: "Escuela" as any, Label: GetStringUI("tblignor_field_esc"), MinWidth: "100px", Width: "20%", IsSortable: false }
                    ],
                    EvaluatorAndSubLevelsBuild: {
                        OnStepCellTable: (container, datum, field, step) => {
                            switch (field) {
                                case "Alumno":
                                    container.text(_DICC_ALUMNO.get(datum.IdAlumno).NombreCompleto);
                                    break;
                                case "Costo":
                                    container.text(UIUtilFormat._CurrencyFmt(datum.Costo));
                                    break;
                                case "Tipo":
                                    container.text(UIUtilViewData._GetStr_TipoHoraExtra(datum.Tipo));
                                    break;
                                case "Fecha":
                                    container.text(UIUtilTime._DateFormatStandar(datum.Fecha)); // FIX_TIMEZONE
                                    break;
                                case "Escuela":
                                    container.text(DataModuloEscuela._DiccEscuela.get(datum.IdEscuela).Nombre)
                                    break;
                            }
                        }
                    }
                    // OnStepItemTableV2: 
                })

                ctrltablaIgnorados._UpdateData(horasExtras);
            },
            OnAccept: (mt) => _SvIgnorarHorasExtras(horasExtras)
        })
        return modalT;
    }

    function InitProcesoGeneraCobroByAAlumno(datoChild?: IAlumno, horasExtra?: IHoraExtra[], tablaParent?: Table.Tabla<IHoraExtra>) {
        let escuela = GetEscuela(datoChild.IdKinder);
        let ciclosEscolares = GetCiclosEscolaresByIdEsc(datoChild.IdKinder);

        if (ciclosEscolares.length == 0) {
            NotificacionV2._Mostrar(
                GetStringUI("notif_escsinciclosesc")
                    .replace("_ESCUELA", escuela.Nombre),
                "ADVERTENCIA");
            return null;
        }

        // Inicializa procesoPago
        let itemAlumnoProceso = GetItemAlumnoProcesoCargo(datoChild, horasExtra);

        let procesoPago: IProcesoRegistroCargo = {
            CicloEscolar: null,
            FechaAplicacion: null,
            ItemsProcesoCargo: [itemAlumnoProceso],

            Escuela: escuela,
            CiclosEscolares: ciclosEscolares,
            CargoEntrada: null,
            CargoSalida: null
        }
        itemAlumnoProceso.ParentProceso = procesoPago;

        // Busca cargos de Entrada y Salida por escuela
        if (itemAlumnoProceso.Entradas.HorasExtras.length > 0) {
            procesoPago.CargoEntrada = { ..._GetFinanzaCargoToHoraExtra(datoChild.IdKinder, CCategoriaFinanza.CargoEntrada) };
            if (!procesoPago.CargoEntrada) {
                NotificacionV2._Mostrar(
                    GetStringUI("notif_escsincrgoentrada")
                        .replace("_ESCUELA", escuela.Nombre),
                    "ADVERTENCIA");
                return null;
            } else if (itemAlumnoProceso.Entradas.HorasExtras.length == 1) {
                const dt = new DateV2(itemAlumnoProceso.Entradas.HorasExtras[0].Fecha)._SetTimeZoneByIdSchool(escuela.IdKinder)
                procesoPago.CargoEntrada.Nombre += " - " + UIUtilTime._DateFormatStandar(dt, "dd/mm/yyyy h12:mm")
            }
        }
        if (itemAlumnoProceso.Salidas.HorasExtras.length > 0) {
            procesoPago.CargoSalida = { ..._GetFinanzaCargoToHoraExtra(datoChild.IdKinder, CCategoriaFinanza.CargoSalida) };
            if (!procesoPago.CargoSalida) {
                NotificacionV2._Mostrar(
                    GetStringUI("notif_escsincrgosalida")
                        .replace("_ESCUELA", escuela.Nombre),
                    "ADVERTENCIA")
                return null;
            } else if (itemAlumnoProceso.Salidas.HorasExtras.length == 1) {
                const dt = new DateV2(itemAlumnoProceso.Salidas.HorasExtras[0].Fecha)._SetTimeZoneByIdSchool(escuela.IdKinder)
                procesoPago.CargoSalida.Nombre += " - " + UIUtilTime._DateFormatStandar(dt, "dd/mm/yyyy h12:mm")
            }
        }
        // ----- -----

        const zonaHoraria = procesoPago.Escuela.ZonaHoraria;
        // >> Busca ciclo escolar actual e inicializa fecha de Aplicación
        procesoPago.CicloEscolar = ciclosEscolares
            .find(dCE => (
                (GetValidatorDateYM(new DateV2(dCE.FechaInicio)._SetTimeZone(zonaHoraria)) <= GetValidatorDateYM(new DateV2()._SetTimeZone(zonaHoraria, true)))
                && (GetValidatorDateYM(new DateV2(dCE.FechaFin)._SetTimeZone(zonaHoraria)) >= GetValidatorDateYM(new DateV2()._SetTimeZone(zonaHoraria, true)))
            ));
        if (procesoPago.CicloEscolar) {
            // La fecha actual se encuentra dentro del rango del ciclo escolar encontrado
            procesoPago.FechaAplicacion = new DateV2()
                ._SetTimeZone(zonaHoraria, true);
        } else {
            // El ciclo escolar minimo es mayor a la fecha actual
            procesoPago.CicloEscolar = ciclosEscolares[0];
            procesoPago.FechaAplicacion = new DateV2(procesoPago.CicloEscolar.FechaInicio)
                ._SetTimeZone(zonaHoraria);
        }

        return OpenModal_GeneraCobroByAAlumno(procesoPago, tablaParent);
    }

    function OpenModal_GeneraCobroByAAlumno(procesoPago: IProcesoRegistroCargo, tablaParent?: Table.Tabla<IHoraExtra>) {
        // Objeto inicializador de los datos del modal
        let initData: ICargoInit = {
            CicloEscolar: procesoPago.CicloEscolar.Id,
            Aplicacion: _FmtToInputDate(procesoPago.FechaAplicacion, procesoPago.Escuela.IdKinder),
        }

        let form: FormGenerator<ICargoInit>;

        let modalLong = ModalThings._GetModalToALongProccess({
            Modulo: Entidad.CModulo.FinanzasHorasExtras,
            StartEndIds: ["pago_1", "pago_2"],
            StepsConfig: [
                {
                    Id: "pago_1",
                    Title: GetStringUI("tag_dtaplica"),
                    NextID: () => "pago_2",
                    Width: 500,
                    OnDrawContent: (content) => {
                        form = new FormGenerator<ICargoInit>();
                        form._Crear({
                            schema: [
                                {
                                    type: Fields.selectMaterial,
                                    labelAttr: { text: GetStringUI("tag_cicloesc") },
                                    selectMaterialAttr: {
                                        valueMember: "Id", displayMember: "Nombre", onSelect: (cicloEscolarD: ICicloEscolar) => {
                                            procesoPago.CicloEscolar = cicloEscolarD;
                                            const fechaInicioCicloE = new DateV2(cicloEscolarD.FechaInicio)._SetTimeZone(procesoPago.Escuela.ZonaHoraria);
                                            const fechaFinCicloE = new DateV2(cicloEscolarD.FechaFin)._SetTimeZone(procesoPago.Escuela.ZonaHoraria);
                                            const currentDate = new DateV2()
                                                ._SetTimeZone(procesoPago.Escuela.ZonaHoraria);

                                            if ((GetValidatorDateYM(fechaInicioCicloE) <= GetValidatorDateYM(currentDate)) && (GetValidatorDateYM(fechaFinCicloE) >= GetValidatorDateYM(currentDate))) {
                                                // Es ciclo escolar actual
                                                procesoPago.FechaAplicacion = currentDate;
                                            } else {
                                                procesoPago.FechaAplicacion = fechaInicioCicloE;
                                            }

                                            form._ControlsData.get(CPagoInitModels.Aplicacion).selection
                                                .attr("min", _FmtToInputDate(procesoPago.FechaAplicacion, procesoPago.Escuela.IdKinder))
                                                .attr("max", _FmtToInputDate(fechaFinCicloE, procesoPago.Escuela.IdKinder))
                                                .property("value", _FmtToInputDate(procesoPago.FechaAplicacion, procesoPago.Escuela.IdKinder));
                                        }, required: true
                                    },
                                    model: CPagoInitModels.CicloEscolar,
                                    values: procesoPago.CiclosEscolares
                                },
                                {
                                    type: Fields.input,
                                    labelAttr: { text: GetStringUI("tag_dtaplica") },
                                    inputAttr: {
                                        type: "date",
                                        min: _FmtToInputDate(procesoPago.CicloEscolar.FechaInicio, procesoPago.Escuela.ZonaHoraria),
                                        max: _FmtToInputDate(procesoPago.CicloEscolar.FechaFin, procesoPago.Escuela.ZonaHoraria),
                                        required: true
                                    },
                                    model: CPagoInitModels.Aplicacion,
                                    values: []
                                }
                            ]
                        }, initData, "form_modalbase")
                        content.append(() => form._Form.node());
                    },
                    OnValideStep: () => {
                        if (form._GetIsValidForm()) {
                            procesoPago.FechaAplicacion = new DateV2(UIUtilTime._GetLocalDateFromInputDateString(form._Data.Aplicacion));
                            return true;
                        }
                        else {
                            return false;
                        }
                    }
                },
                {
                    Id: "pago_2",
                    Title: GetStringUI("tag_detspago"),
                    PreviousID: () => "pago_1",
                    Width: 500,
                    OnDrawContent: (container, mt) => {
                    },
                    OnFocusContent: (container, mt) => {
                        mt.CurrentBody.selectAll("div").remove();
                        mt.CurrentBody.select("hr").remove();
                        let initData: ICargoInit = {
                            // Monto: procesoPago.ValorPago, // Un solo monto para ambos tipos de Cargos
                            CicloEscolar: procesoPago.CicloEscolar.Id,
                            Aplicacion: _FmtToInputDate(procesoPago.FechaAplicacion, procesoPago.Escuela.IdKinder),

                            CargoEntradaMonto: UIUtilFormat._CurrencyFmt(procesoPago.ItemsProcesoCargo[0].Entradas.ValorPago),
                            CargoEntradaNombre: procesoPago.CargoEntrada?.Nombre,
                            CargoEntradaVencimiento: "", // "2020-02-02"
                            CargoEntradaMorosidad: "",
                            CargoEntradaRecargo: "",

                            CargoSalidaMonto: UIUtilFormat._CurrencyFmt(procesoPago.ItemsProcesoCargo[0].Salidas.ValorPago),
                            CargoSalidaNombre: procesoPago.CargoSalida?.Nombre,
                            CargoSalidaVencimiento: "",
                            CargoSalidaMorosidad: "",
                            CargoSalidaRecargo: ""
                        }

                        const GetUpdateStrs = (fAplicacion: DateV2, cargo: ICargo) => {
                            let vencimientoDt = new DateV2(fAplicacion);
                            vencimientoDt.setDate(vencimientoDt.getDate() + cargo.DiaVencimiento);

                            let morosidadDt = new DateV2(vencimientoDt);
                            morosidadDt.setDate(morosidadDt.getDate() + cargo.DiaMorosidad);

                            let recargo = "";
                            if (cargo.TipoRecargo != CTipoValor.Ninguno) {
                                recargo = UIUtilViewFinanzaCargo._GetStrRecargosCol(cargo);
                            } else {
                                recargo = "N/A";
                            }

                            return {
                                FechaVencimiento: UIUtilTime._DateFormatStandar(vencimientoDt),
                                FechaMorosidad: UIUtilTime._DateFormatStandar(morosidadDt),
                                Recargo: recargo
                            }
                        }
                        const BuildCargoInfoUI = (type: ("Entrada" | "Salida"), parent: TSelectionHTML, datosBuild: ICargoInit, cargoRealData: ICargo, content?: TSelectionHTML<"div">) => {
                            let form: FormGenerator<ICargoInit> = new FormGenerator<ICargoInit>();
                            if (!content?.node()) {
                                content = parent.append("div")
                                    .classed(type.toLowerCase() + " " + UIUtilGeneral.FBoxOrientation.Vertical, true)
                                    .style("row-gap", "10px");

                                let head = content.append("div")
                                    .style("width", "100%")
                                    .attr("class", "head " + UIUtilGeneral.FBoxOrientation.Horizontal + " " + UIUtilGeneral.FBoxAlign.SpacebetweenCenter)

                                head.append("label")
                                    .style("color", "gray")
                                    .style("font-weight", "bold")
                                    .text(type == "Entrada" ? GetStringUI("tag_cargoentrada") : GetStringUI("tag_cargosalida"));

                                if (UIUtilPermission._HasModulePermission(Entidad.CModulo.FinanzasCargo, cargoRealData.IdEscuela)) {
                                    head.append("label")
                                        .classed("accion_item", true)
                                        .text(GetStringUI("tag_editcharg"));
                                }
                            }

                            if (UIUtilPermission._HasModulePermission(Entidad.CModulo.FinanzasCargo, cargoRealData.IdEscuela)) {
                                content.select(".head").select(".accion_item")
                                    .on("click", async () => {
                                        const datoCargoFrm = _GetFinanzaCargoToHoraExtra(cargoRealData.IdEscuela, cargoRealData.Categoria as any)
                                        const formCargo = UIUtilViewFinanzaCargo._GetFormAddEditCargo(CAccionPermiso.Editar, datoCargoFrm);
                                        let res = await UIUtilViewFinanzaCargo._OpenModal_FormAddEditCargo(CAccionPermiso.Editar, datoCargoFrm, formCargo, "FinanzasCargos");
                                        if (!res) {
                                            return
                                        }
                                        const datoCargoFinal = { ..._GetFinanzaCargoToHoraExtra(cargoRealData.IdEscuela, cargoRealData.Categoria as any) };
                                        let strsUpdated = GetUpdateStrs(procesoPago.FechaAplicacion, datoCargoFinal);
                                        datosBuild["Cargo" + type + "Vencimiento"] = strsUpdated.FechaVencimiento;
                                        datosBuild["Cargo" + type + "Morosidad"] = strsUpdated.FechaMorosidad;
                                        datosBuild["Cargo" + type + "Recargo"] = strsUpdated.Recargo;

                                        datoCargoFinal.Nombre = cargoRealData.Nombre
                                        procesoPago["Cargo" + type] = datoCargoFinal;
                                        cargoRealData = datoCargoFinal;
                                        BuildCargoInfoUI(type, parent, datosBuild, cargoRealData, content);
                                    })
                            }
                            content.select(".formulario_generator").remove();

                            // Button.fn_EnableButton(editar, false);

                            let schema: IField<ICargoInit>[] = <IField<ICargoInit>[]>[
                                {
                                    type: "input",
                                    labelAttr: { text: "tag_nombre" },
                                    inputAttr: {
                                        oninput: (e) => {
                                            cargoRealData.Nombre = (e.target as HTMLInputElement).value;
                                        }
                                    },
                                    model: CPagoInitModels["Cargo" + type + "Nombre"]
                                },
                                {
                                    type: Fields.input,
                                    labelAttr: { text: "tag_monto" },
                                    inputAttr: { disabled: true, removeBorder: true },
                                    model: CPagoInitModels["Cargo" + type + "Monto"]
                                },
                                {
                                    type: Fields.input,
                                    labelAttr: { text: "tag_dtvence" },
                                    inputAttr: { disabled: true, removeBorder: true },
                                    model: CPagoInitModels["Cargo" + type + "Vencimiento"]
                                },
                                {
                                    type: Fields.input,
                                    labelAttr: { text: "tag_dtmoroso" },
                                    inputAttr: { disabled: true, removeBorder: true },
                                    model: CPagoInitModels["Cargo" + type + "Morosidad"]
                                },
                                {
                                    type: Fields.input,
                                    labelAttr: { text: "tag_recargo" },
                                    inputAttr: { disabled: true, removeBorder: true },
                                    model: CPagoInitModels["Cargo" + type + "Recargo"]
                                }
                            ]

                            form._Crear({
                                LangModuleKeyInContext: MODULONAME,
                                schema: schema
                            }, datosBuild);
                            content.append(() => form._Form.node());
                        }
                        if (procesoPago.CargoEntrada) {
                            let strsUpdated = GetUpdateStrs(procesoPago.FechaAplicacion, procesoPago.CargoEntrada);

                            initData.CargoEntradaVencimiento = strsUpdated.FechaVencimiento;
                            initData.CargoEntradaMorosidad = strsUpdated.FechaMorosidad;
                            initData.CargoEntradaRecargo = strsUpdated.Recargo;

                            BuildCargoInfoUI("Entrada", mt.CurrentBody, initData, procesoPago.CargoEntrada);

                            if (procesoPago.CargoSalida) {
                                mt.CurrentBody.append("hr")
                                    .style("margin-bottom", "15px");
                            }
                        }

                        if (procesoPago.CargoSalida) {
                            let strsUpdated = GetUpdateStrs(procesoPago.FechaAplicacion, procesoPago.CargoSalida);

                            initData.CargoSalidaVencimiento = strsUpdated.FechaVencimiento;
                            initData.CargoSalidaMorosidad = strsUpdated.FechaMorosidad;
                            initData.CargoSalidaRecargo = strsUpdated.Recargo;


                            BuildCargoInfoUI("Salida", mt.CurrentBody, initData, procesoPago.CargoSalida);
                        }
                    },
                    OnAccept: async () => {
                        let res = await _SvRegistrarCargo({
                            FechaAplicacion: procesoPago.FechaAplicacion,
                            CargoEntrada: procesoPago.CargoEntrada,
                            CargoSalida: procesoPago.CargoSalida,
                            CicloEscolar: procesoPago.CicloEscolar,
                            Item: procesoPago.ItemsProcesoCargo[0]
                        });
                        if (res.Resultado > 0) {
                            await MainPage._ReloadServiceAndAwait(DATA_REQUEST_ID).catch((err) => {
                                NotificacionV2._Mostrar(UIUtilLang._GetUIString("general", "notif_fail_infoupdate"), "ADVERTENCIA");
                            });
                        }
                        return res;
                    }
                }
            ]
        })
        return modalLong;
    }

    export async function _SvIgnorarHorasExtras(horasExtras: IHoraExtra[]) {
        console.log("Ignorar horas extras", horasExtras)
        const res = await _FinanzaHoraExtraIgnorarRegistro(horasExtras.map(d => d.Id));

        if (res.Resultado > 0) {
            await MainPage._ReloadServiceAndAwait(DATA_REQUEST_ID).catch((err) => {
                NotificacionV2._Mostrar(UIUtilLang._GetUIString("general", "notif_fail_infoupdate"), "ADVERTENCIA");
            });
        }

        return res;
    }

    export async function _SvRegistrarCargo(itemRegistrar: IParamsRegistroCargo, temp_Reload = true) { // IProcesoRegistroCargo
        console.log("RegistrarCargo: ", itemRegistrar)
        let itemAlumno = itemRegistrar.Item;
        let res = await _FinanzaHoraExtraGenerarCargo(
            itemAlumno.Alumno.IdChild,
            0, // IdCuenta
            itemRegistrar.CicloEscolar.Id,
            itemRegistrar.FechaAplicacion,
            (
                itemAlumno.Entradas.HorasExtras.length > 0
                    ? {
                        IdCargo: itemRegistrar.CargoEntrada.ID,
                        Valor: itemAlumno.Entradas.ValorPago,
                        Ids: itemAlumno.Entradas.HorasExtras.map(d => d.Id),
                        NombreCargo: itemRegistrar.CargoEntrada.Nombre,
                    }
                    : null
            ),
            (
                itemAlumno.Salidas.HorasExtras.length > 0
                    ? {
                        IdCargo: itemRegistrar.CargoSalida.ID,
                        Valor: itemAlumno.Salidas.ValorPago,
                        Ids: itemAlumno.Salidas.HorasExtras.map(d => d.Id),
                        NombreCargo: itemRegistrar.CargoSalida.Nombre,
                    }
                    : null
            ),
            // (!procesoPago.CargoEntrada ? null : { IdCargo: procesoPago.CargoEntrada.ID, Valor: procesoPago.ItemsProcesoCargo[0].Entradas.ValorPago, Ids: procesoPago.ItemsProcesoCargo[0].Entradas.HorasExtras.map(d => d.Id) }),
            // (!procesoPago.CargoSalida ? null : { IdCargo: procesoPago.CargoSalida.ID, Valor: procesoPago.ItemsProcesoCargo[0].Salidas.ValorPago, Ids: procesoPago.ItemsProcesoCargo[0].Salidas.HorasExtras.map(d => d.Id) })
        );
        return res;
    }

}
