import { ArrayV2 } from "../../util/ArrayV2";
import { DataDRequest } from "../DRequest";
import { Entidad } from "../Entidad";
import { Global } from "../Global";
import { ILicencia, ILicenciaEstado, CLicenciaFormaPago, ILicenciaPagoPendiente, CAlumnoEdoLicencia, CLicenciaEstado, ILicenciaPeriodo, ILicenciaPeriodoDetalle } from "../entidad/LicenciaOld";
import { DataUtil } from "../util/Util";
import { group as d3Group } from "d3-array";

const REQUESTID = Entidad.CTipoRequest.Licencia

export const _DICC_LICENCIA = new Map<number, ILicencia>()

/** @deprecated */
export async function _SvLicenciaObtenerLista(escuelas: Entidad.IEscuela[]): Promise<DataDRequest.IRequestResponseListA<ILicencia>[]> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "licencia/ObtenerLista";
    return []
    return new ArrayV2<Entidad.IEscuela>()
        ._Push(...escuelas)
        ._MapAwait(async ({ IdKinder }) => {
            const currentDateStr = await DataUtil._GetMaxDate(REQUESTID, IdKinder);
            const params = {
                "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
                "IdEscuela": IdKinder,
                "Modificacion": currentDateStr
            }
            return DataDRequest._Request4List(url, "GET", params, REQUESTID, IdKinder);
        })
}

/** @deprecated */
export async function _SvLicenciaObtenerListaEstadoActual(idsEscuelas: number[]): Promise<DataDRequest.IRequestResponseA<ILicenciaEstado[]>> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "licencia/ObtenerListaEstadoActual";
    let licencias: ILicenciaEstado[] = [];
    let resultCode = 1;

    for (let idEscuela of idsEscuelas) {
        const params = {
            "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
            "IdEscuela": idEscuela,
        }

        let result = await DataDRequest._Request5<ILicenciaEstado[]>(url, "GET", params);

        if (result.Resultado > 0) {
            licencias.push(...result.Datos);
        } else {
            licencias = null;
            resultCode = result.Resultado;
            break;
        }
    }

    return {
        Resultado: resultCode,
        Datos: licencias
    };
}

interface ILicenciaPeriodoRes {
    Periodo: string
    Estados: CLicenciaEstado[]
    IdAlumnos: number[]
    IdLicencias: number[]
}
/** @deprecated */
export async function _SvLicenciaObtenerListaPeriodos(idEscuela: number): Promise<DataDRequest.IRequestResponseA<ILicenciaPeriodo[]>> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "licencia/ObtenerListaPeriodos";
    const params = {
        "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
        "IdEscuela": idEscuela,
    }
    return DataDRequest._Request5<ILicenciaPeriodo[]>(url, "GET", params)
        .then(res => {
            const datos = Array.from(res.Datos || []) as any as ILicenciaPeriodoRes[]
            res.Datos = datos.map(d => ({
                Periodo: d.Periodo,
                IdEscuela: idEscuela,
                Licencias: d.IdLicencias.map<ILicenciaPeriodo["Licencias"][number]>((idLic, i) => ({
                    IdLicencia: idLic,
                    IdAlumno: d.IdAlumnos[i],
                    Estado: d.Estados[i],
                }))
            }))
            return res
        })
}

/** @deprecated */
export async function _SvLicenciaObtenerListaPorPeriodo(idEscuela: number, fecha: string): Promise<DataDRequest.IRequestResponseA<ILicenciaPeriodoDetalle[]>> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "licencia/ObtenerListaPorPeriodo";
    const params = {
        "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
        "IdEscuela": idEscuela,
        "Fecha": fecha + "Z",
    }
    return DataDRequest._Request5<ILicenciaPeriodoDetalle[]>(url, "GET", params)
}

/** @deprecated */
export async function _SvLicenciaObtenerListaPagoProgramado(idsEscuelas: number[]): Promise<DataDRequest.IRequestResponseA<ILicenciaPagoPendiente[]>> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "licencia/ObtenerListaPagoProgramado";
    let licenciasPagoPendiente: ILicenciaPagoPendiente[] = [];
    let resultCode = 1;

    for (let idEscuela of idsEscuelas) {
        const params = {
            "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
            "IdEscuela": idEscuela,
            // "Modificacion": null // lastStrDate
        }

        let result = await DataDRequest._Request5<ILicenciaPagoPendiente[]>(url, "GET", params);

        if (result.Resultado > 0) {
            licenciasPagoPendiente.push(...result.Datos);
        } else {
            resultCode = result.Resultado;
            licenciasPagoPendiente = null;
            break;
        }
    }

    return {
        Resultado: resultCode,
        Datos: licenciasPagoPendiente
    };
}

type TLicenciaPagar = {
    "Id": number;
    "IdAlumno": number;
    "Monto": number;
}
type TGenerarFolioResponse = {
    Folio: string;
    /** Si es mayor que 0, es el Id de Folio */
    Resultado: number;
} & DataDRequest.IRequestResponseA<undefined>;
export function _SvLicenciaGenerarFolio(idEscuela: number, formaPago: CLicenciaFormaPago, licenciasPagar: TLicenciaPagar[]): Promise<TGenerarFolioResponse> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "licencia/GenerarFolio";
    const params = {
        "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "IdEscuela": idEscuela,
        "FormaPago": formaPago,
        "Licencias": licenciasPagar
    }

    // return new Promise((resolve, reject) => { // DOTEST
    //     setTimeout(() => {
    //         resolve({
    //             Resultado: 1,
    //             TipoRequest: IDREQUEST_LICENCIA,
    //             Folio: "QWERT-00001"
    //         });
    //     }, 2000);
    // })
    return DataDRequest._Request5(url, "POST", params) as Promise<TGenerarFolioResponse>;
}

type TCardPayAddChargeResponse = {
    "id": string; //"trwevtr8zzrahx6yx6lt",
    "amount": number; // 107.55,
    "authorization": string; //"801585",
    "method": "card",
    "operation_type": string; //"in",
    "transaction_type": "charge",
    // "card": {
    //     "id": string; // unknow, null,
    //     "type": "debit",
    //     "brand": "visa",
    //     "address": null,
    //     "card_number": "411111XXXXXX1111",
    //     "holder_name": "ANNE WITH E",
    //     "expiration_year": "21",
    //     "expiration_month": "12",
    //     "allows_charges": true,
    //     "allows_payouts": true,
    //     "creation_date": null,
    //     "bank_name": "Banamex",
    //     "bank_code": "002"
    // },
    "status": "completed",
    "currency": "MXN",
    // "exchange_rate": {
    //     "from": null,
    //     "date": null,
    //     "value": null,
    //     "to": null
    // },
    "creation_date": "2021-09-11T13:54:18-05:00",
    "operation_date": "2021-09-11T13:54:19-05:00",
    "description": "Esto es una prueba",
    "error_message": null,
    "order_id": null
}
export function _SvLicenciaCardPayAddCharge(tokenCard: string, sessionOpenpay: string, monto: number, descripcionFolio: string, idFolio: number, clienteCorreo: string, clienteNombre: string): Promise<DataDRequest.IRequestResponseA<TCardPayAddChargeResponse>> {
    const url = Global._GLOBAL_CONF.URL_RSTLicenciasOpenPay + "cardpay/AddCharge";
    const params = {
        "TokenCard": tokenCard, //"kqt2j6psbzim0kookgzl",
        "SessionOpenpay": sessionOpenpay,  //"mlsnlrcw9szur1zq6hoi",
        "Amount": monto, //2.25,
        "Description": descripcionFolio, //"KOHALA-000001",
        "IdFolio": idFolio,
        "Email": clienteCorreo, //"reyno@elevenminds.com",
        "CustomerName": clienteNombre
    }

    // return new Promise((resolve, reject) => { // DOTEST
    //     setTimeout(() => {
    //         resolve({
    //             Resultado: 1,
    //             TipoRequest: IDREQUEST_LICENCIA,
    //             Datos: <TCardPayAddChargeResponse>{
    //                 id: "trxtii8grcs7bpux2wy2",
    //             }
    //         });
    //     }, 2000);
    // })
    return DataDRequest._Request5<TCardPayAddChargeResponse>(url, "POST", params);
}

type TCardPayAdd3DChargeResponse = {
    "id": string; // "tr349gtttv8jnwksomxz",
    "description": string; // "E122-000040",
    "authorization": string; // "tr349gtttv8jnwksomxz",
    "error_message": string; // null,
    "amount": string; //1184.97,
    "operation_type": string; //"in",
    "payment_method": {
        "type": "redirect",
        "url": string; //"https://sandbox-api.openpay.mx/v1/mtafzuwquqmipgkyih7i/charges/tr349gtttv8jnwksomxz/redirect/"
    },
    /** IdFolio */
    "order_id": string; //"148",
    "transaction_type": string; // "charge",
    "creation_date": string; // "2022-03-28T15:17:40-06:00",
    "operation_date": string; // "2022-03-28T15:17:40-06:00",
    // "card": {
    //     "id": null,
    //     "expiration_month": "12",
    //     "bank_code": "000",
    //     "bank_name": "BANK HANDLOWY W WARSZAWIE, S.A.",
    //     "brand": "mastercard",
    //     "allows_payouts": false,
    //     "type": "credit",
    //     "allows_charges": true,
    //     "address": null,
    //     "creation_date": null,
    //     "holder_name": "ROBERT JOSUÉ ",
    //     "card_number": "545454XXXXXX5454",
    //     "expiration_year": "22"
    // },
    "currency": "MXN",
    "status": string; //"charge_pending",
    "method": "card"
};
export function _SvLicenciaCardPayAdd3DCharge(tokenCard: string, sessionOpenpay: string, monto: number, descripcion: string, idFolio: number, clienteCorreo: string, clienteNombre: string): Promise<DataDRequest.IRequestResponseA<TCardPayAdd3DChargeResponse>> {
    const url = Global._GLOBAL_CONF.URL_RSTLicenciasOpenPay + "cardpay/Add3DCharge";
    const params = {
        "TokenCard": tokenCard, //"kqt2j6psbzim0kookgzl",
        "SessionOpenpay": sessionOpenpay,  //"mlsnlrcw9szur1zq6hoi",
        "Amount": monto, //2.25,
        "Description": descripcion, //"KOHALA-000001",
        "IdFolio": idFolio,
        "Email": clienteCorreo, //"reyno@elevenminds.com",
        "CustomerName": clienteNombre
    }

    // return new Promise((resolve, reject) => { // DOTEST
    //     setTimeout(() => {
    //         resolve({
    //             Resultado: 1,
    //             TipoRequest: IDREQUEST_LICENCIA,
    //             Datos: <TCardPayAdd3DChargeResponse>{
    //                 id: "trxtii8grcs7bpux2wy2",
    //                 "payment_method": {
    //                     "type": "redirect",
    //                     "url": "https://sandbox-api.openpay.mx/v1/mtafzuwquqmipgkyih7i/charges/tr349gtttv8jnwksomxz/redirect/"
    //                 },
    //             }
    //         });
    //     }, 2000);
    // })
    return DataDRequest._Request5<TCardPayAdd3DChargeResponse>(url, "POST", params);
}

type TTransferBuildSPEIReference = {
    "id": string; // "trazevg4jwd7zw7ojqdv",
    "authorization": string; // null,
    "operation_type": string, // "in",
    "transaction_type": string, // "charge",
    "status": string, // "in_progress",
    "conciliated": boolean; // false,
    "creation_date": string; //"2022-01-13T13:42:13-06:00",
    "operation_date": string; //"2022-01-13T13:42:13-06:00",
    "description": string; //"This is a test",
    "error_message": string; // null,
    "order_id": string; // "123-Rob",
    "due_date": string; // "2022-02-12T23:59:59-06:00",
    "payment_method": {
        "type": string; // "bank_transfer",
        "bank": string; // "BBVA Bancomer",
        "clabe": string; // "000000000000000001",
        "agreement": string; // "0000000",
        "name": string; // "11096923252630188264"
    },
    "amount": number; // 710,
    "currency": string; // "MXN",
    "customer": {
        "name": string; // "Robert & jsue",
        "last_name": string; // "",
        "email": string; // "rbt@kidi.mx",
        "phone_number": string; // "",
        "address": string; // null,
        "creation_date": string; // "2022-01-13T13:42:13-06:00",
        "external_id": string; // null,
        "clabe": string; // null
    },
    "method": string; // "bank_account"
}
export function _SvLicenciaTransferGenerarReferenciaSPEI(monto: number, descripcion: string, idFolio: number, clienteCorreo: string, clienteNombre: string): Promise<DataDRequest.IRequestResponseA<TTransferBuildSPEIReference>> {
    const url = Global._GLOBAL_CONF.URL_RSTLicenciasOpenPay + "transfer/AddTransfer";
    const params = {
        "Amount": monto,
        "Description": descripcion,
        "IdFolio": idFolio,
        "Email": clienteCorreo,
        "CustomerName": clienteNombre,
        "DueDate": GetDueDateAsISO()
    }

    // return new Promise((resolve, reject) => { // DOTEST
    //     setTimeout(() => {
    //         resolve({
    //             Resultado: 1,
    //             TipoRequest: IDREQUEST_LICENCIA,
    //             Datos: <TTransferBuildSPEIReference>{
    //                 id: "truyzyx4e1f6rtlhzi5c"
    //             }
    //         });
    //     }, 2000);
    // })
    return DataDRequest._Request5<TTransferBuildSPEIReference>(url, "POST", params);
}

type TPayStoreBuildReference = {
    "id": string; // "trebwfiyhik4gjdiq6rn",
    "description": string; // "M122-000067",
    "error_message": string; // null,
    "authorization": string; // null,
    "amount": number; //208.67,
    "operation_type": string;// "in",
    "payment_method": {
        "type": string; // "store",
        "reference": string; // "9988781250336368",
        "barcode_url": string; //"https://sandbox-api.openpay.mx/barcode/9988781250336368?width=1&height=45&text=false"
    },
    "order_id": string; // "M122-000067",
    "transaction_type": string; // "charge",
    "creation_date": string; // "2022-01-31T11:00:40-06:00"
    "currency": string; // "MXN",
    "status": string; // "in_progress",
    "method": string; // "store"
}
export function _SvLicenciaPaystoreGenerarReferencia(monto: number, descripcion: string, idFolio: number, clienteCorreo: string, clienteNombre: string): Promise<DataDRequest.IRequestResponseA<TPayStoreBuildReference>> {
    const url = Global._GLOBAL_CONF.URL_RSTLicenciasOpenPay + "paystore/AddPayStore";
    const params = {
        "Amount": monto,
        "Description": descripcion,
        "IdFolio": idFolio,
        "Email": clienteCorreo,
        "CustomerName": clienteNombre,
        "DueDate": GetDueDateAsISO()
    }

    return DataDRequest._Request5<TPayStoreBuildReference>(url, "POST", params);
}

// ********************************************************************************
// LOCAL DATA
// ********************************************************************************

/** @returns ej.: '2022-06-15T23:59:59.999Z' */
function GetDueDateAsISO() {
    let nowDt = new Date();
    let dueY = nowDt.getFullYear();
    let dueM = nowDt.getMonth();
    let dueD = nowDt.getDate() + 7;
    let auxDate = new Date(dueY, dueM, dueD, 12);

    let str = auxDate.toISOString();
    let date = str.split("T")[0];
    return date + "T23:59:59.999Z";
}

export function _LOCALDATA_GetURLFile_SPEIReferencePay(idTransaction: string): string {
    return Global._GLOBAL_CONF.IncOpenPay_URLDashboard + "/spei-pdf/" + Global._GLOBAL_CONF.IncOpenPay_MerchantId + "/" + idTransaction;
}

export function _LOCALDATA_GetURLFile_StoreReferencePay(reference: string): string {
    return Global._GLOBAL_CONF.IncOpenPay_URLDashboard + "/paynet-pdf/" + Global._GLOBAL_CONF.IncOpenPay_MerchantId + "/" + reference;
}

/**
 * Retorna una lista de las licencias por importancia:
 *
 * * Si el alumno tiene licencias CADUCADAS, descarta cualquier otra
 * * Si el alumno tiene licencias VENCIDAS y no CADUCADAS, descarta ACTIVO y PAUSADO
 * * Si no tiene CADUCADAS ni VENCIDAS, devuelve ACTIVO | PAUSADO
 * @param idAlumno
 */
export function _LOCALDATA_Licencia_EdoActualAlumno(idAlumno: number): CAlumnoEdoLicencia {
    const licenciasAlumno = Array.from(_DICC_LICENCIA.values()).filter(l => l.IdAlumno == idAlumno)
    if (!licenciasAlumno.length) {
        return CAlumnoEdoLicencia.SinLicencia
    }
    const licMapByStatus = d3Group(licenciasAlumno, l => l.Estado)
    if (licMapByStatus.has(CLicenciaEstado.Caducado)) {
        return CAlumnoEdoLicencia.Caducado
    } else if (licMapByStatus.has(CLicenciaEstado.Vencido)) {
        return CAlumnoEdoLicencia.Vencido
    }
    if (licMapByStatus.size > 1) {
        console.error("Alumno", idAlumno, "tiene más de dos estados", licMapByStatus)
    }
    const edo = Array.from(licMapByStatus.keys())[0]
    return edo as unknown as CAlumnoEdoLicencia
}
