import * as d3 from "d3";
import { UIUtilIconResources } from "../util/IconResourses";

export namespace InputPassword {

    export class InputPassword /** extends HTMLElement */ {
        #textvisible: boolean;
        #maxlength: number | null;

        #content: d3.Selection<HTMLDivElement, any, any, any>;

        constructor() {
            // super();
            // let shadow = this.attachShadow({ mode: "closed" });

            this.#content = d3.create("div")
                .classed("input_content", true)
                .classed("inputpass_content", true)

            // his.#content.append("input")
            // .classed("hide", true)
            // .text("hidden label to mislead chrome autocomplete");
            // <label for="" style="display: none;">hidden label to mislead chrome autocomplete</label>
            this.#textvisible = false;

            let input = this.#content.append<HTMLInputElement>("input")
                .attr("type", "password")
                // .attr("data-model", model)
                .attr("autocomplete", "off")
            // .style("padding-right", "35px")
            // .attr("id", this.getAttribute("id"))
            // .attr("part", "part_inputpassword");

            // input.classed("input_pass", true);

            let eyeCont = this.#content.append("div").classed("eye_container", true);

            // let eye = eyeCont.append("div").classed("input_eye", true);

            let imgEye = eyeCont.append("img")
                .attr("draggable", false)
                .attr("src", UIUtilIconResources.CGeneral.EyeHidden);

            eyeCont.node()
                .onclick = (e) => {
                    // e.preventDefault();
                    e.stopPropagation();

                    let typeIn = input.attr("type")
                    if (typeIn === "text") {
                        input.attr("type", "password");
                        imgEye.attr("src", UIUtilIconResources.CGeneral.EyeHidden);
                        this.#textvisible = false;
                        // eyeSvg.classed("idonsee", false);
                    }
                    else if (typeIn === "password") {
                        input.attr("type", "text");
                        imgEye.attr("src", UIUtilIconResources.CGeneral.Eye);
                        // eyeSvg.classed("idonsee", true);
                        this.#textvisible = true;
                    }

                    // Button.BtnEye.fn_UpdateBtn(imgEye, (typeIn == "text"));
                    input.node().focus();
                }

            // let style = document.createElement("style");
            // style.textContent = stylebase;

            // shadow.appendChild(style);
            // shadow.appendChild(this.#content.node());

            // this.ApplyAttrs();
        }

        // private ApplyAttrs() {
        //     let input = this.#content.select("input");
        //     this.#isDisabled = this.hasAttribute("disabled");

        //     input.attr("maxlength", this.getAttribute("maxlength"));
        //     input.property("disabled", this.#isDisabled)
        // }

        // ****************************************************************
        // ELEMENT LIFE CICLE CALLBACKS
        // ****************************************************************

        // /** Se invoca cuando uno de los atributos del elemento se ha añadido, quitado o cambiado */
        // attributeChangedCallback(attrName: string, oldVal: string, newVal: string, otro, mas) {
        //     // console.log(`attrName ${attrName} - ${typeof attrName}, oldVal ${oldVal} - ${typeof oldVal}, newVal ${newVal} ${typeof newVal} ----------- `, otro, mas)
        //     this.ApplyAttrs();
        // }

        // /** Se invoca cada vez que el elemento se añade a un documento */
        // connectedCallback() {
        //     // console.log("conented callback")
        // }

        // static get observedAttributes() {
        //     return ['maxlength', 'disabled'];
        // }

        // ****************************************************************
        // Public properties
        // ****************************************************************

        set _TextVisible(value: boolean) {
            this.#textvisible = value;
            this.#content.select("input").attr("type", value ? "text" : "password");
            this.#content.select("img").attr("src", value ? UIUtilIconResources.CGeneral.Eye : UIUtilIconResources.CGeneral.EyeHidden);
        }

        get _TextVisible() {
            return this.#textvisible
        }

        set _Disabled(value: boolean) {
            this.#content.select("input").property("disabled", value);
        }

        get _Disabled() {
            return this.#content.select("input").property("disabled")
        }

        set _Value(value: string) {
            (this.#content.select<HTMLInputElement>("input")).node().value = value;
        }

        get _Value() {
            return (this.#content.select<HTMLInputElement>("input")).node().value;
        }

        set _MaxLength(value: number) {
            this.#maxlength = value;
            this.#content.select("input")
                .attr("maxlength", value);
        }

        get _MaxLength() {
            return this.#maxlength;
        }

        get _ControlContainer() {
            return this.#content;
        }

        get _InputInside() {
            return this.#content.select<HTMLInputElement>("input");
        }

        get _EyeContainer() {
            return this.#content.select(".eye_container") as TSelectionHTML<"div">
        }

        /** Auxiliar de para el formulario */
        // get value() {
        //     return this.#value;
        // }
    }

    // customElements.define("input-password", InputPasswordElement);

    // const stylebase = `
    // .input_content {
    //     position: relative;
    //     display: flex;
    //     border-width: 1px;
    //     background-color: gray;
    // }
    // .input_content > .input_pass {
    //     padding-right: 35px;
    //     border-width: 0;
    //     background-color: #fff;
    // }
    // .input_content > .eye_container {
    //     position: absolute;
    //     right: 1px;
    //     width: 0;
    //     height: 95%;
    //     display: flex;
    //     align-items: center;
    // }
    // .input_content > .eye_container > .input_eye {
    //     min-width: 30px;
    //     height: inherit;
    //     background-color: rgb(250, 250, 250);
    //     display: inherit;
    //     align-items: inherit;
    //     justify-content: center;
    //     position: absolute;
    //     right: 0;
    //     cursor: pointer;
    // }

    // .input_content > .eye_container > .input_eye * {
    //     cursor: pointer;
    // }

    // .input_content > .eye_container > .input_eye > svg {
    //     box-sizing: border-box;
    //     padding: 1px 2px;
    //     display: flex;
    //     align-items: center;
    //     width: 20px;
    //     height: 10px;
    //     background-color: inherit;
    // }

    // .input_content > .eye_container > .input_eye > svg > ellipse {
    //     fill: rgba(255, 255, 255, 1);
    //     stroke: rgb(160, 160, 160);
    // }

    // .input_content > .eye_container > .input_eye > .idonsee > ellipse {
    //     stroke: rgb(100, 100, 100) !important;
    // }
    // `
}
