import * as d3 from 'd3';
import interact from 'interactjs';
import { DataUtilLocalStorage } from '../../data/util/LocalStorage';
import _L from '../../util/Labels';
import { UtilObject } from '../../util/Object';
import { UIUtilElement } from '../util/Element';
import { UIUtilTime } from '../util/Time';
import { Button } from './Button';
import {
    CCalendarioGridV2Vista as CCVista,
    CalendarioGridV2Base, EventInteractionCallback,
    ICCalendario, ICConfig,
    ICEvento,
    ICEventoControl,
    ICEventoInput,
    ICEventoOption, ICalendarioDateBlock,
    TipoVista
} from './CalendarioGridV2Base';
import { DropdownAdvanced } from './DropdownAdvanced';
import { DropdownFlexV2 } from './DropdownFlexV2';
import { SelectV2 } from './SelectV2';
import ic_angle_down from '/image/iconos/ic_angle_down.svg?raw';
import ic_close from '/image/iconos/ico_closelight.svg?raw';
import { _ObtenerConfiguracionInfo } from '../../data/modulo/EventoCalendario';

const MAX_DAYS_IN_WEEK = 7;
/** 1=Monday, 2=Tue,... */
const START_DAY_WEEK = 1; // Monday
const MONTH_N_WEEKS = 6;
const MAX_DAYS_IN_MONTH = MONTH_N_WEEKS * MAX_DAYS_IN_WEEK;

const EVENT_ITEM_DRAG_ATTR_OBSERVER = "dragging";
const EVENT_ITEM_RESIZE_ATTR_OBSERVER = "resizing";
const EVENT_SNAP_MINUTES_ONINTERACTION = 5;
const MUTATION_OBSERVER_TIMEOUT = 0;

export class CalendarioGridV2<Extras = unknown> extends CalendarioGridV2Base {
    private eventosMap: Map<number, ICEventoControl>;
    private selectTipoVista: SelectV2<{ id: CCVista, name: string }>;

    private eventConfigs: Pick<ICConfig<Extras>, "DrawEventPreview" | "GetEventOptions">;

    private focusDate: Date;

    constructor(config?: ICConfig<Extras>) {
        super(config);
        this._SetGrupos(config?.Grupos || []);
        this.eventConfigs = {
            DrawEventPreview: config?.DrawEventPreview,
            GetEventOptions: config?.GetEventOptions,
        }
        this.InitMutationObserver();
        this.SetFocusDateTag(config?.FechaFocoInicial || new Date());
        this.AddEventos(config?.Eventos);
        this.ViewRefreshGeneral();
    }

    private InitMutationObserver() {
        const accumulatedMutationsMap = new Map<number, MutationRecord>();
        const eventosModificadosMap: Map<number, ICEventoControl> = new Map();
        let timeout: NodeJS.Timeout;
        const observer = new MutationObserver((entries) => {
            entries.forEach(entry => {
                const elem = entry.target as ICEventoControl["_Element"];
                const targetSel = d3.select<HTMLDivElement, ICEventoControl>(elem);
                const d = elem._Datum;
                if (!targetSel.classed("TEMP"))
                    accumulatedMutationsMap.set(d.Id, entry);
            })
            console.debug("MutationObserver")
            if (timeout) {
                console.debug("MutationObserver cancela")
                clearTimeout(timeout);
            }
            timeout = setTimeout(() => {
                console.debug("MutationObserver aplica")
                timeout = null;
                let endDOMMutationsMap: Map<number, ICEventoControl> = new Map();
                let endInteractionFinal = true;
                [...accumulatedMutationsMap.values()]
                    .map(entry => {
                        const elem = entry.target as ICEventoControl["_Element"]
                        // const targetSel = d3.select<HTMLDivElement, ICEventoControl>(elem);
                        // return targetSel.datum(); //, entry] as const;
                        return elem._Datum;
                    })
                    .forEach((datum) => {
                        // const elem = datum._Element;
                        const targetSel = d3.select(datum._Element);
                        // const attrName = entry.attributeName;
                        // if (!elem.isConnected) {
                        //     console.info("Interaction: elemento !isConnected", datum)
                        //     mutations.delete(datum.Id);
                        //     eventosModificadosMap.set(datum.Id, datum);
                        //     return false;
                        // }
                        const endItemInteraction = [null, "false"].includes(targetSel.attr(EVENT_ITEM_DRAG_ATTR_OBSERVER))
                            && [null, "false"].includes(targetSel.attr(EVENT_ITEM_RESIZE_ATTR_OBSERVER));

                        if (endItemInteraction) {
                            endDOMMutationsMap.set(datum.Id, datum);
                            eventosModificadosMap.set(datum.Id, datum);
                        } else {
                            endDOMMutationsMap.delete(datum.Id);
                            endInteractionFinal = false;
                        }

                        // elem.isConnected
                        // [null, "true"].includes(entry.oldValue)
                        // && (EVENT_ITEM_DRAG_ATTR_OBSERVER == attrName || EVENT_ITEM_RESIZE_ATTR_OBSERVER == attrName)
                    })

                // console.debug("Mutation", ...entries);
                if (endDOMMutationsMap.size) {
                    console.debug(
                        "Interaction: end edit",
                        [...endDOMMutationsMap.values()].filter(d => d._Element.isConnected).map(d => d.Inicio.toLocaleString()),
                        [...endDOMMutationsMap.values()].filter(d => !d._Element.isConnected).map(d => d._Element)
                    );
                    [...endDOMMutationsMap.values()]
                        .filter(d => {
                            if (!d._Element.isConnected) {
                                accumulatedMutationsMap.delete(d.Id);
                            }
                            return d._Element.isConnected;
                        })
                        .forEach(d => {
                            const elem = d._Element;
                            elem.blur();
                            if (d._IsFullDay) {
                                // console.info("***** *****", d)
                                return;
                            }
                            this.FixItemsView(elem.parentElement.parentElement)//, [elem])
                        })
                }
                // else {
                //     console.debug("en edicion", ...entries.map(d =>
                //         [d.attributeName, (d.target as HTMLElement).getAttribute(d.attributeName), d.target]
                //     ))
                //     // console.debug("en edicion mutations", ...[...mutations.values()].map(d =>
                //     //     [d.attributeName, (d.target as HTMLElement).getAttribute(d.attributeName), d.target]
                //     // ))
                // }
                if (endInteractionFinal && eventosModificadosMap.size) {
                    this.CallOnChangeEvents([...eventosModificadosMap.values()]);
                    accumulatedMutationsMap.clear();
                    eventosModificadosMap.clear();
                }
            }, MUTATION_OBSERVER_TIMEOUT);
        })
        observer.observe(this.contentContainer._areaEventos._mainArea.node(), {
            // characterData: true,
            attributeOldValue: true,
            attributeFilter: [EVENT_ITEM_DRAG_ATTR_OBSERVER, EVENT_ITEM_RESIZE_ATTR_OBSERVER],
            // childList: true,
            subtree: true,
        })
    }

    // private EvalEventsIntersections(container: HTMLElement, itemsEval?: ICEventoControl["_Element"][]) {
    //     const childs = d3.select(container).selectAll<HTMLDivElement, unknown>(":scope>.events_wrapper>.event_item").nodes();
    //     if (!itemsEval) {
    //         itemsEval = [...childs];
    //     }
    //     // d3.selectAll<HTMLDivElement, ICEventoControl>(itemsEval)
    //     itemsEval
    //         .forEach(child => {
    //             // .each((_/* datumEval */, i, arr) => {
    //             // const item = arr[i];
    //             // const itemRect = item.getBoundingClientRect();
    //             // const dEval = datumEval;
    //             const dEval = child._Datum;
    //             const childEvents: ICEventoControl[] = [];
    //             const intersectChilds = childs
    //                 .map(c => {
    //                     const d = d3.select<HTMLDivElement, ICEventoControl>(c).datum();
    //                     childEvents.push(d);
    //                     return d
    //                 })
    //                 .filter(c => {
    //                     if (c.Id === dEval.Id || (!dEval.Inicio || !dEval.Fin || !c.Inicio || !c.Fin))
    //                         return false;
    //                     const evalInicio = dEval._Element.getBoundingClientRect().top; //new Date(dEval.FechaInicio).getTime();
    //                     const evalFin = dEval._Element.getBoundingClientRect().bottom; //new Date(dEval.FechaFin).getTime();
    //                     const cInicio = c._Element.getBoundingClientRect().top; //new Date(c.FechaInicio).getTime();
    //                     const cFin = c._Element.getBoundingClientRect().bottom; //new Date(c.FechaFin).getTime();
    //                     return (evalInicio < cInicio && evalFin > cInicio)
    //                         || (evalFin > cFin && evalInicio < cFin)
    //                         || (evalInicio > cInicio && evalFin < cFin)
    //                 })
    // 
    //             // Reset
    //             dEval._Element.style.left = null;
    //             dEval._Element.style.width = null;
    //             dEval._Intersections?.forEach(lastIntersection => {
    //                 lastIntersection._Element.style.left = null;
    //                 lastIntersection._Element.style.width = null;
    //             })
    //             dEval._Intersections = intersectChilds;
    // 
    //             if (!intersectChilds.length) {
    //                 return;
    //             }
    //             // Relocate
    //             console.debug("intersection FIXME", dEval) // FIXME
    //             const nItems = 1 + intersectChilds.length;
    //             const percent = 90 / (nItems / 2);
    // 
    //             [dEval, ...intersectChilds]
    //                 .filter(d => !d._IsInteracting) // FIXME AGRERGAR `get isInteracting` a item evento
    //                 .sort((a, b) => new Date(a.Inicio).getTime() - new Date(b.Inicio).getTime())
    //                 .forEach((child, i) => {
    //                     const leftMove = (i * 10);
    //                     child._Element.style.left = i == 0 ? null : leftMove + "%";
    //                     child._Element.style.width = (percent - leftMove) + "%";
    //                 })
    // 
    // 
    //             childEvents
    //                 .filter(d => !d._IsInteracting)
    //                 .sort((a, b) => new Date(a.Inicio).getTime() - new Date(b.Inicio).getTime())
    //                 .forEach(d => d3.select(d._Element).raise())
    //         })
    // }

    private FixItemsView(container: HTMLElement) {
        let childs: ICEventoControl["_Element"][] = d3.select(container).selectAll<HTMLDivElement, unknown>(":scope>.events_wrapper>.event_item").nodes();
        childs = childs.sort((a, b) => a._Datum.Id - b._Datum.Id)
        childs = childs.sort((a, b) => d3.ascending(a._Datum.Descripcion.toLowerCase(), b._Datum.Descripcion.toLowerCase()));
        childs = childs.sort((a, b) => (a._Datum.Inicio.toISOString() == b._Datum.Inicio.toISOString()) ? new Date(b._Datum.Fin).getTime() - new Date(a._Datum.Fin).getTime() : new Date(a._Datum.Inicio).getTime() - new Date(b._Datum.Inicio).getTime())

        // STEP 1: Actualiza Intersecciones de cada elemento
        childs.forEach((child, i, items) => {
            const dEval = child._Datum;
            const intersectChilds = childs
                .map(c => {
                    const d = c._Datum
                    return d
                })
                .filter(c => {
                    if (c.Id === dEval.Id || (!dEval.Inicio || !dEval.Fin || !c.Inicio || !c.Fin)) {
                        return false;
                    }
                    // Realizar casos de empalmamiento
                    // CASE #1: El elemento contiene en su interior a otro elemento
                    // CASE #2: El elemento está contenido por otro elemento
                    // CASE #3: El elemento intersecta a otro desde arriba o abajo

                    return (dEval.Inicio.toISOString() <= c.Inicio.toISOString() && dEval.Fin.toISOString() >= c.Fin.toISOString()) // CASE #1
                        || (dEval.Inicio.toISOString() >= c.Inicio.toISOString() && dEval.Fin.toISOString() <= c.Fin.toISOString()) // CASE #2
                        || (dEval.Inicio.toISOString() < c.Inicio.toISOString() && dEval.Fin.toISOString() > c.Inicio.toISOString()) // CASE  #3 UP
                        || (dEval.Inicio.toISOString() < c.Fin.toISOString() && dEval.Fin.toISOString() > c.Fin.toISOString()) // CASE #3 DOWN

                    // return (dEval.Inicio.toISOString() == c.Inicio.toISOString() && dEval.Fin.toISOString() < c.Fin.toISOString())
                    //     || (dEval.Fin.toISOString() == c.Fin.toISOString() && dEval.Inicio.toISOString() < c.Inicio.toISOString())
                    //     || (dEval.Inicio.toISOString() < c.Inicio.toISOString() && dEval.Fin.toISOString() > c.Inicio.toISOString()) // CHECK
                    //     || (dEval.Fin.toISOString() > c.Fin.toISOString() && dEval.Inicio.toISOString() < c.Fin.toISOString()) // CHECK
                    //     || (dEval.Fin.toISOString() == c.Fin.toISOString() && dEval.Inicio.toISOString() < c.Fin.toISOString())
                    //     || (dEval.Inicio.toISOString() > c.Inicio.toISOString() && dEval.Fin.toISOString() < c.Fin.toISOString()) // Case #2
                    //     || (dEval.Inicio.toISOString() == c.Inicio.toISOString() && dEval.Fin.toISOString() == c.Fin.toISOString())
                })
            child._Datum._Intersections = intersectChilds;
        })


        // STEP 2: TOMAR DECISIONES EN RELACION DE LAS INTERSECCIONES DE CADA CHILD 
        childs.forEach((child) => {
            const dEval = child._Datum;
            // RESET POSITION & WIDTH
            dEval._Element.style.left = null;
            dEval._Element.style.width = null;
            // SOLO CONTINUA SI TIENE INTERSECCIONES
            if (!dEval._Intersections.length) return;
            // GRAL PERCENT
            const percent = 90;

            // ARR: ELEMENTOS INTERCONECTADOS(DIRECTA E INDIRECTAMENTE) CON EL ITEM ACTUAL
            const ElementsInterConected: ICEventoControl<any>[] = [];
            // LLENA EL ARREGLO CON LAS INTERSECCIONES DIRECTAS DEL ELEMENTO Y LAS INTERACCIONES QUE TIENEN LAS INTERACCIONES DIRECTAS
            [dEval, ...dEval._Intersections].forEach(d => {
                // RECURSIÓN
                const EvalIntersectionToPush = (item: ICEventoControl<any>) => {
                    if (ElementsInterConected.find(e => e.Id == item.Id)) return;
                    ElementsInterConected.push(item);
                    if (!item._Intersections) return;
                    item._Intersections.forEach(i => EvalIntersectionToPush(i));
                }
                EvalIntersectionToPush(d);
            });

            ElementsInterConected
                .filter(d => !d._IsInteracting) // FIXME AGRERGAR `get isInteracting` a item evento
                .sort((a, b) => a.Id - b.Id)
                .sort((a, b) => d3.ascending(a.Descripcion.toLowerCase(), b.Descripcion.toLowerCase()))
                .sort((a, b) => (a.Inicio.toISOString() == b.Inicio.toISOString()) ? new Date(b.Fin).getTime() - new Date(a.Fin).getTime() : new Date(a.Inicio).getTime() - new Date(b.Inicio).getTime())
                .forEach((child, i, arr) => {
                    const minItemWidth = ((percent) / arr.length);
                    const leftMove = (i * minItemWidth);
                    const addPercentWidth = 70 / arr.length;
                    let itemWidth = minItemWidth;
                    if (i != arr.length - 1) {
                        itemWidth += addPercentWidth
                    }
                    child._Element.style.left = leftMove + "%";
                    child._Element.style.width = itemWidth + "%";
                })

            d3.select(child).raise()
        })
    }

    // private FixItemsViewCalendar(container: HTMLElement) {
    //     let childs: ICEventoControl["_Element"][] = d3.select(container).selectAll<HTMLDivElement, unknown>(":scope>.events_wrapper>.event_item").nodes();
    //     childs = childs.sort((a, b) => a._Datum.Id - b._Datum.Id);
    //     childs = childs.sort((a, b) => d3.descending(a._Datum.Descripcion.toLowerCase(), b._Datum.Descripcion.toLowerCase()));
    //     childs = childs.sort((a, b) => (a._Datum.Inicio.toISOString() == a._Datum.Inicio.toISOString()) ? (b._Datum.Fin.getTime() - a._Datum.Fin.getTime()) : (a._Datum.Inicio.getTime() - b._Datum.Inicio.getTime()))
    // 
    //     childs.forEach((item) => {
    //         const itemDatum = item._Datum;
    //         const itemInicioExtended = new Date(itemDatum.Inicio)
    //         itemInicioExtended.setHours(itemInicioExtended.getHours() + 1);
    //         const intersectedTop: ICEventoControl<any>[] = [];
    //         const intersectChilds: ICEventoControl<any>[] = [];
    //         const intersectedBody: ICEventoControl<any>[] = [];
    //         // Recorrer los demás items, para ver cuales intersectan con el y agrupar por tipo de interseccion
    //         childs.map(c => {
    //             const d = c._Datum;
    //             return d;
    //         })
    //             .filter(c => (c.Id != itemDatum.Id && itemDatum.Inicio && itemDatum.Fin && c.Inicio && c.Fin))
    //             .forEach((siblingItem) => {
    //                 if (siblingItem.Inicio.toISOString() >= itemDatum.Inicio.toISOString() && siblingItem.Inicio.toISOString() < itemInicioExtended.toISOString()) {
    //                     intersectedTop.push(siblingItem);
    //                 } else if (itemDatum.Inicio.toISOString() < siblingItem.Inicio.toISOString() && itemDatum.Fin.toISOString() > siblingItem.Inicio.toISOString()) {
    //                     intersectedBody.push(siblingItem);
    //                 } else if ((itemDatum.Inicio.toISOString() <= siblingItem.Inicio.toISOString() && itemDatum.Fin.toISOString() >= siblingItem.Fin.toISOString()) // CASE #1
    //                     || (itemDatum.Inicio.toISOString() >= siblingItem.Inicio.toISOString() && itemDatum.Fin.toISOString() <= siblingItem.Fin.toISOString()) // CASE #2
    //                     || (itemDatum.Inicio.toISOString() < siblingItem.Inicio.toISOString() && itemDatum.Fin.toISOString() > siblingItem.Inicio.toISOString()) // CASE  #3 UP
    //                     || (itemDatum.Inicio.toISOString() < siblingItem.Fin.toISOString() && itemDatum.Fin.toISOString() > siblingItem.Fin.toISOString()) // CASE #3 DOWN
    //                 ) {
    //                     intersectChilds.push(siblingItem);
    //                 }
    //             })
    //         console.log(item, intersectedTop, intersectedBody, intersectChilds);
    //         item._Datum._Intersections = intersectChilds;
    //         item._Datum._IntersectionsTop = intersectedTop;
    //         item._Datum._IntersectionsBody = intersectedBody;
    // 
    // 
    // 
    //         d3.select(item).raise();
    //     })
    // 
    //     childs.forEach(itemChild => {
    //         const itemDatum = itemChild._Datum;
    //         itemDatum._Element.style.width = null;
    //         itemDatum._Element.style.left = null;
    // 
    //         if (!itemDatum._Intersections.length && !itemDatum._IntersectionsTop.length && !itemDatum._IntersectionsBody) return;
    // 
    //         const itemsConnected = [itemDatum, ...itemChild._Datum._IntersectionsTop]
    //             .sort((a, b) => a.Id - b.Id)
    //             .sort((a, b) => d3.descending(a.Descripcion.toLowerCase(), b.Descripcion.toLowerCase()))
    //             .sort((a, b) => (a.Inicio.toISOString() == a.Inicio.toISOString()) ? (b.Fin.getTime() - a.Fin.getTime()) : (a.Inicio.getTime() - b.Inicio.getTime()))
    // 
    //         const indexCurrentChild = itemsConnected.findIndex(d => d.Id == itemDatum.Id);
    // 
    //         const minItemWidth = ((90) / itemsConnected.length);
    //         const leftMove = (indexCurrentChild * minItemWidth);
    //         const addPercentWidth = 70 / itemsConnected.length;
    //         let itemWidth = minItemWidth;
    //         if (indexCurrentChild != itemsConnected.length - 1) {
    //             itemWidth += addPercentWidth
    //         }
    //         itemDatum._Element.style.left = leftMove + "%";
    //         itemDatum._Element.style.width = itemWidth + "%";
    //     })
    // }

    protected InitDraw() {
        this.contentContainer = d3.create("div")
            .attr("class", "calendarios_controller") as typeof this.contentContainer;

        this.contentContainer._areaNavegacion = this.contentContainer.append("div")
            .attr("class", "area_navegacion")
        this.contentContainer._areaNavegacion.call(cont => {
            // CONFIG INPUT EVENT
            new Button(cont, _L("c_actions.agregar"))._node.onclick = e => {
                this.OnInputEvent({
                    Descripcion: "",
                    IdCalendario: 0,
                    Inicio: null,
                    Fin: null,
                })
            }

            // CONFIG TIPOS DE VISTAS
            const optionsTipoVista = [
                { id: CCVista.DAY, name: _L("c_calendario_vista.day") },
                { id: CCVista.WEEK, name: _L("c_calendario_vista.week") },
                { id: CCVista.MONTH, name: _L("c_calendario_vista.month") },
                { id: CCVista.YEAR, name: _L("c_calendario_vista.year") },
            ]
            this.selectTipoVista = new SelectV2<{ id: CCVista, name: string }>({
                Parent: cont,
                Type: "monoselect",
                ValueMember: "id",
                DisplayMember: "name",
                Data: optionsTipoVista,
                // OnStepItemListUI: (cont) => cont.node().onclick = e => e.stopPropagation(),
                OnChange: (id: CCVista) => {
                    this.SelectTipoVista(id);
                    // ONCHANGE TIPO VISTA
                    if (this.callbacks.OnChangeTipoVista) {
                        this.callbacks.OnChangeTipoVista(id);
                    }
                }
            })
            this.selectTipoVista._valueSelect(this.vista);

            //@ts-expect-error
            this.contentContainer._areaNavegacion._currentDate = cont.append("div")
                .attr("class", "area_current_date")
                .call(cont => {
                    cont.append("label")
                    // CONFIG FOCUS DATE
                    cont.append("input")
                        .attr("type", "date")
                        .call((input) => {
                            input.node().onchange = () => {
                                this.flagFocusItemInCurrentView = true;
                                const dt = input.node().valueAsDate;
                                const newDt = new Date(dt.getUTCFullYear(), dt.getUTCMonth(), dt.getUTCDate());
                                this._SetFocusDate(newDt);
                                // ONCHANGE FOCUS DATE
                                if (this.callbacks.OnChangeFocusDate) {
                                    this.callbacks.OnChangeFocusDate(newDt);
                                }
                            }
                        })
                        .node()
                        .valueAsDate = this.focusDate;
                });
        });

        this.contentContainer._areaCalendarios = this.contentContainer.append("div")
            .attr("class", "area_calendarios")
        this.contentContainer._areaCalendarios.call((cont) => {
            //@ts-expect-error
            this.contentContainer._areaCalendarios._header = cont.append("div")
                .attr("class", "g_cal_header")
                .text("Calendarios ") // FIX_LANGUAGE
            //@ts-expect-error
            this.contentContainer._areaCalendarios._content = cont.append("div")
                .attr("class", "g_cal_content")
            // this.contentContainer._areaCalendarios._itemsContainer = cont.append("div").attr("class", "items");
        });

        this.contentContainer._areaEventosWrapper = this.contentContainer.append("div")
            .attr("class", "area_eventos_wrapper");
        this.contentContainer._areaEventos = this.contentContainer._areaEventosWrapper.append("div")
            .attr("class", "area_eventos");
        //@ts-expect-error
        this.contentContainer._areaEventos._mainArea = this.contentContainer._areaEventos.append("div")
            .attr("class", "main_area");
        //@ts-expect-error
        this.contentContainer._areaEventos._btnExpand = this.contentContainer._areaEventosWrapper.append("div")
            // d3.create<HTMLDivElement>("div")
            .attr("class", "btn_expand")
            .html(ic_angle_down)
            .on("click", () => {
                const headExpanded = this.contentContainer._areaEventos.classed("header_expanded");
                this.contentContainer._areaEventos.classed("header_expanded", !headExpanded);
                // toggle this.contentContainer._areaEventos
                // header_expand
            })
    }

    protected ViewRefreshGeneral() {
        // console.debug("ViewRefreshGeneral")
        // this.contentContainer._areaNavegacion.append(this.focusDate.toISOString());
        if (this["__timerRefreshGeneral__"]) {
            clearTimeout(this["__timerRefreshGeneral__"])
        }
        this["__timerRefreshGeneral__"] = setTimeout(() => {
            this["__timerRefreshGeneral__"] = null;
            this.ViewToogleOrRefreshTipoVista();
            this.ViewRefreshCalendariosFiltro();
            this.ViewRefreshEventosGrid();
            if (this.flagFocusItemInCurrentView) {
                this.EventFocusEventMinDateIncurrentView();
            }
        }, 200);
    }

    private ViewRefreshEventosGrid(container = this.contentContainer._areaEventos, date = this.focusDate, vista = this.vista, eventos = this.GetEventosFiltrados()) {
        // console.warn("ViewRefreshEventosGrid init")
        const isMainView = container.node() === this.contentContainer._areaEventos.node();
        // Build headers
        (() => {
            // Build TOP AXIS data
            let columns: [string, Date][] = [];
            switch (vista) {
                case CCVista.DAY:
                case CCVista.WEEK:
                case CCVista.MONTH:
                    columns = this.BlocksDatesOfDate(date, vista == CCVista.DAY ? CCVista.DAY : CCVista.WEEK)
                        .map(b => {
                            const strTag = UIUtilTime._GetDayName(b.getDay());
                            if (vista == CCVista.WEEK || vista == CCVista.DAY)
                                return [strTag, b];
                            else
                                return [strTag, null];
                        });
                    break;
                case CCVista.DIARY:
                    break;
            }
            // Draw header month name
            if (vista == CCVista.MONTH && !isMainView) {
                let headerMonthName = container.select<HTMLDivElement>(":scope>.header_month_name");
                if (!headerMonthName.node()) {
                    headerMonthName = container.append("div")
                        .attr("class", "header_month_name")
                }
                headerMonthName.text(UIUtilTime._GetMonthName(date.getMonth()));
            } else {
                container.select(":scope>.header_month_name").remove();
            }

            // Draw header
            // let header = this.contentContainer._areaEventos._header;
            let header = container.select<HTMLDivElement>(":scope>.header");
            if (!columns.length) {
                header.remove();
                // container.select<HTMLDivElement>(":scope>.header_none").remove();
                return;
            }
            else if (!header.node()) {
                header = container.append("div").attr("class", "header") as any;
                // //@ts-expect-error
                // this.contentContainer._areaEventos._header = header;
            }
            // this.contentContainer._areaEventos.append(() => this.contentContainer._areaEventos._header.node());
            // this.contentContainer._areaEventos._header = header;
            // const _isMainA = isMainView;
            header.selectAll<HTMLDivElement, string>(":scope>.content")
                .data(columns)
                .join("div")
                .each(([_, date], i, arr) => {
                    const classBase = "content ";
                    if (isMainView && !!date) {
                        arr[i].className = classBase + this.BlockID(date);
                        return;
                    }
                    arr[i].className = classBase;
                })
                .html(([colName, dt]) => {
                    if (!isMainView)
                        return colName.substring(0, 1);
                    if (dt) {
                        const day = dt.getDate();
                        const totalClass = this.BlockIdIsToday(this.BlockID(dt)) ? "today_2" : ""
                        return `<label>${colName}</label>`
                            + `<label class="${totalClass}">${day}</label>`
                            + `<div class="events_wrapper"></div>`
                    }
                    return colName
                });
        })();

        // Build left blocks
        (() => {
            // Build TIME AXIS data
            let rows: number[] = [];
            let nRows = 0;
            switch (vista) {
                case CCVista.DAY:
                case CCVista.WEEK:
                    nRows = 24
                    break;
            }
            for (let i = 0; i < nRows; i++)
                rows.push(i + 1)
            // Draw
            let timeAxis = container.select<HTMLDivElement>(":scope>.time_axis_labels");
            let timeAxisLines = container.select<HTMLDivElement>(":scope>.time_axis_lines");
            if (!rows.length) {
                timeAxis.remove();
                timeAxisLines.remove();
                this.resizeObserverControler.delete("time_axis_lines");
                return;
            }
            else if (!timeAxis.node()) {
                timeAxisLines = container.append("div").attr("class", "time_axis time_axis_lines");
                timeAxis = container.append("div").attr("class", "time_axis time_axis_labels");
            }
            timeAxis.selectAll<HTMLDivElement, string>(":scope>.content").data(rows).join("div")
                .attr("class", "content")
                .html(d => {
                    let dt = new Date();
                    dt.setHours(d);
                    dt.setMinutes(0);
                    return `<div>${UIUtilTime._DateFormatStandar(dt, "h24:mm")}</div>`;
                    // + `<hr style="width:${this.contentContainer._areaEventos.node().clientWidth}px;">`
                })
            timeAxisLines.selectAll<HTMLDivElement, string>(":scope>.content").data(rows).join("div")
                .attr("class", "content")
                .html(d => {
                    let dt = new Date();
                    dt.setHours(d);
                    dt.setMinutes(0);
                    // return `<div>${UIUtilTime._DateFormatStandar(dt, "h24:mm")}</div>`
                    return `<hr style="width:${this.contentContainer._areaEventos.node().clientWidth}px;">`;
                })
            this.resizeObserverControler.set("time_axis_lines", this.contentContainer._areaEventos.node(), (target) => {
                // console.log("resize");
                timeAxisLines.selectAll<HTMLDivElement, string>(":scope>.content>hr")
                    .style("width", target.clientWidth + "px");
            })
            // this.contentContainer._areaEventos._header?.raise();
        })();

        // Build GRID events
        let mainArea = container.select<HTMLDivElement>(":scope>.main_area");
        const isYearView = vista == CCVista.YEAR; // FIXME // FIXME // FIXME RECURSIVO
        const isMonthView = vista == CCVista.MONTH;
        (() => {
            // Build TOP AXIS data
            const eventsContainers: ICalendarioDateBlock[] = this.BlocksDatesOfDate(date, vista);
            // Draw
            if (!mainArea.node()) {
                mainArea = container.append("div").attr("class", "main_area");
            }
            mainArea.selectAll<HTMLDivElement, ICalendarioDateBlock>(":scope>.events_container")
                .data(eventsContainers, d => this.BlockID(d, vista)) //.toISOString())
                .join("div")
                .attr("class", d => "events_container " + this.BlockID(d, vista))
                .classed("disabled", d => d._disabled)
                .classed("today", d => this.BlockIdIsToday(this.BlockID(d, vista)))
                .each((d, i, elems) => {
                    const cont = d3.select(elems[i]);

                    if (isYearView) {
                        let monthEventsMap: Map<string, ICEventoControl<any>[]>;
                        let monthEventsArr: ICEventoControl<any>[] = [];
                        if (!d._disabled) {
                            monthEventsMap = this.EventGetEventsInCurrentView(d, CCVista.MONTH, eventos)
                            monthEventsArr = [...monthEventsMap.values()]
                                .reduce((res, mData) => {
                                    if (mData.length)
                                        res = res.concat(mData);
                                    return res;
                                }, <ICEventoControl<any>[]>[]);
                            // monthEvents = eventos // .filter(d => d.IdCalendario == ) // FIXME // DOTEST
                        }
                        cont.selectAll(":scope>.events_wrapper").remove();
                        this.ViewRefreshEventosGrid(cont, d, CCVista.MONTH, monthEventsArr);
                        this.ViewRefreshEventosFinal(cont.select(".main_area"), monthEventsMap);
                    } else {
                        cont.select(":scope>.header_month_name").remove();
                        cont.select(":scope>.header").remove();
                        cont.select(":scope>.main_area").remove();
                        if (!cont.select(":scope>.events_wrapper").node()) {
                            const evWrapper = cont.append("div").attr("class", "events_wrapper").node();
                            this.mutationARObserverControler.setWrapper(evWrapper);
                        }
                    }

                    const content = this.BlockContent(d, vista);
                    let lblTag = cont.select<HTMLLabelElement>(":scope>.lbl_tag");
                    if (!content) {
                        lblTag.remove();
                    } else if (!lblTag.node()) {
                        lblTag = cont.append("label").attr("class", "lbl_tag").text(content);
                    } else {
                        lblTag.text(content);
                    }
                    if (lblTag.node()) {
                        if (this.vista == CCVista.MONTH || !d._disabled && this.vista == CCVista.YEAR) {
                            lblTag
                                .style("cursor", "pointer")
                                .node().onclick = () => {
                                    this.BlockOpenOptions(cont, d)
                                }
                        } else {
                            lblTag
                                .style("cursor", null)
                                .node().onclick = null;
                        }
                    }
                })
                .exit().remove();
        })()

        if (!isYearView) {
            this.ViewRefreshEventosFiltrar(mainArea, date, vista, eventos);
            // DOTEST // DOTEST
            if (!isMonthView) {
                this.contentContainer._areaEventos._mainArea.selectAll(":scope>*")
                    .each((d, i, arr) => {
                        this.FixItemsView(arr[i] as HTMLDivElement); // .event_item
                    })
            }
        }
    }

    private ViewRefreshEventosFiltrar(mainArea: TSelectionHTML<"div">, block: Date, vista: CCVista, eventosFiltrar: ICEventoControl[]) {
        const eventosFiltrados = this.EventGetEventsInCurrentView(block, vista, eventosFiltrar);
        this.ViewRefreshEventosFinal(mainArea, eventosFiltrados)
    }

    private ViewRefreshEventosFinal(mainArea: TSelectionHTML<"div">, eventosFinal: Map<string, ICEventoControl<any>[]>) {
        const viewRequireInteractionController: boolean = [CCVista.DAY, CCVista.WEEK].includes(this.vista)
        // const mainArea = this.contentContainer._areaEventos._mainArea;
        // console.debug(mainArea.node(), block, eventosFiltrados)

        eventosFinal.forEach((eventosF, k) => {
            const eventsContainer = mainArea.select(`.${k}`)
            const headerWrapper = this.contentContainer._areaEventos.select<HTMLDivElement>(`:scope>.header>.${k}>.events_wrapper`)
            // console.warn(k, eventsContainer, headerWrapper)
            if (!eventsContainer.node()) {
                return;
            }
            if (eventsContainer.classed("disabled")) {
                eventosF = [];
            }
            const mainWrapper = eventsContainer.select<HTMLDivElement>(`:scope>.events_wrapper`);
            if (mainWrapper.node()) {
                mainWrapper.selectAll(":scope>*").remove();
            }
            if (headerWrapper.node()) {
                headerWrapper.selectAll(":scope>*").remove();
            }

            // CREA LOS ELEMENTOS CORRESPONDIENTES
            eventosF.forEach((d) => {
                const isFullDay = d._IsFullDay;
                const newWrapper = (isFullDay && headerWrapper.node()) ? headerWrapper : mainWrapper;
                // EVALUAR ELEMENTOS
                const [elEvent, isFirstElement, isElementChanged, isWrapperChanged] = (() => {
                    const evID = "ev_" + d.Id;
                    const isFirst = d._Element == null
                    let isWraprChanged = false;
                    let isElementChanged = false;
                    let elE: HTMLDivElement;
                    if (d._Element) {
                        elE = d._Element;
                    }
                    else {
                        const existW: HTMLDivElement = newWrapper.select<HTMLDivElement>("#" + evID).node();
                        if (existW) {
                            const lastEv = d3.select<HTMLDivElement, ICEventoControl>(existW).datum();
                            if (lastEv) this.EventInteractionControllerRemove(lastEv); // Si el datum es otro, desvincular interacciones del nodo
                            elE = existW;
                            isElementChanged = true
                        } else {
                            // Creando elemento principal
                            elE = document.createElement("div");
                            elE.className = "event_item";
                            elE.id = evID;
                        }
                    }
                    if (d._Element && d._Element.parentElement !== newWrapper.node()) {
                        isWraprChanged = true;
                    }
                    return [elE, isFirst, isElementChanged, isWraprChanged];
                })()
                const [elContent, elColor] = (() => {
                    let elmt = elEvent.querySelector<HTMLDivElement>(":scope>.content");
                    let colorElmt = elEvent.querySelector<HTMLDivElement>(":scope>.color");
                    if (!colorElmt) {
                        colorElmt = document.createElement("div");
                        colorElmt.className = "color";
                        elEvent.appendChild(colorElmt);
                    }
                    if (!elmt) {
                        elmt = document.createElement("div");
                        elmt.className = "content";
                        elEvent.appendChild(elmt);
                    }
                    return [elmt, colorElmt];
                })()
                // ASIGNACIONES
                d._Element = elEvent;
                d._Element._Content = elContent;
                d._Element._Datum = d;
                // d3.select(d._Element).datum(d); // join de .events_container reemplaza el datum de .event_item
                // d3.select(elContent).datum(d);

                // COMPORTAMIENTOS
                d._Element.tabIndex = this.EventDragInteractionEnabled(d) ? 0 : -1;
                elColor.style.backgroundColor = this.GetCalendarColor(d.IdCalendario);
                d3.select(d._Element).classed("is_fullday", isFullDay);

                if (!viewRequireInteractionController) {
                    this.EventInteractionControllerRemove(d);
                    if (this.vista == CCVista.MONTH) {
                        this.EventContentRefresh(d._Element, d);
                        this.EventClickController(d);
                    }
                }
                if (viewRequireInteractionController && isFullDay) {
                    // Los wrapper de header no tienen escuchador onmutation
                    this.EventContentRefresh(d._Element, d);
                    this.EventInteractionControllerRemove(d); // DOTEST
                    this.EventInteractionControllerRestart(d);
                }
                if (viewRequireInteractionController && (isFirstElement || isElementChanged || !d._InteractableObserverWorking)) {
                    // this.EventContentRefresh(d._Element, d);
                    // if (isElementChanged) {
                    //     console.warn("cambio de elemento")
                    // }
                    d._Interactable = interact(elEvent);
                    this.mutationARObserverControler.set(elEvent.id + "_mutation", elEvent, {
                        onAppend: () => {
                            // console.debug("mutation on Append", elEvent.id, elEvent);
                            const dims = this.EventDimention(d);
                            elEvent.style.top = dims ? dims.top + "px" : null;
                            elEvent.style.height = dims ? dims.height + "px" : null;

                            this.EventContentRefresh(d._Element, d);
                            this.EventInteractionControllerRemove(d); // DOTEST
                            this.EventInteractionControllerRestart(d);
                        },
                        onRemove: () => {
                            // console.debug("mutation on Remove", elEvent.id, d.Inicio.toDateString(), elEvent);
                            this.EventInteractionControllerRemove(d);
                        }
                    })
                }
                newWrapper.append(() => d._Element);
            })
        })
    }

    // ****************************************************************
    // Blocks containers
    // ****************************************************************

    private BlockContent(block: Date, vista = this.vista): (string | null) {
        if (vista == CCVista.MONTH) {
            return block.getDate() + "";
        }
        // else if (vista == CCalendarioVista.YEAR) {
        //     return (block.getMonth()) + "";
        // }
        return null;
    }

    private BlocksDatesOfDateIDs(date = this.focusDate, vista = this.vista): string[] {
        return this.BlocksDatesOfDate(date, vista)
            .map(d => this.BlockID(d, vista));
    }

    private BlocksDatesOfDate(date = this.focusDate, vista = this.vista): ICalendarioDateBlock[] {
        const dt = new Date(date);
        const blocks: ICalendarioDateBlock[] = [];
        switch (vista) {
            case CCVista.DAY:
                blocks.push(dt);
                break;
            case CCVista.WEEK:
                const WEEKDAYS = 7;
                const dayW = UIUtilTime._GetDayInWeek(dt);
                const dif = dayW - START_DAY_WEEK;
                dt.setDate(dt.getDate() - dif);
                for (let i = 0; i < WEEKDAYS; i++) {
                    const tempDt = new Date(dt);
                    tempDt.setDate(dt.getDate() + i);
                    blocks.push(tempDt);
                }
                break;
            case CCVista.MONTH:
                const initDay = (dt.setDate(1), UIUtilTime._GetDayInWeek(dt));
                if (initDay > START_DAY_WEEK) {
                    const dif = initDay - START_DAY_WEEK - 1;
                    dt.setDate(-dif);
                }
                const disable = (d: Date) => (d.getMonth() != date.getMonth())

                blocks.push(new Date(dt));
                for (let i = 0; i < MAX_DAYS_IN_MONTH - 1; i++) {
                    // while (dt.getTime() <= maxDate.getTime()) {
                    dt.setDate(dt.getDate() + 1);
                    blocks.push(new Date(dt));
                }
                blocks.forEach(b => {
                    b._disabled = disable(b);
                })
                break;
            case CCVista.YEAR:
                const currentYear = dt.getFullYear();
                const maxMonths = 12;
                for (let month = 0; month < maxMonths; month++) {
                    blocks.push(new Date(currentYear, month, 1))
                }
                break;
            case CCVista.DIARY:
                blocks.push(new Date());
                break;
        }
        // console.log(blocks)
        return blocks;
    }

    private BlockID(dt: Date, vista: CCVista = this.vista): string {
        switch (vista) {
            case CCVista.DAY:
            case CCVista.WEEK:
            case CCVista.MONTH:
                return "day-" + dt.getFullYear() + '-' + dt.getMonth().toString().padStart(2, "0") + '-' + dt.getDate();
            case CCVista.YEAR:
                return "year-" + dt.getFullYear() + '-' + dt.getMonth();
            case CCVista.DIARY:
                const now = new Date();
                if (dt.getDate() < now.getDate()) {
                    return "x"; // Descartar eventos anteriores
                }
                return "diary-"
        }
    }

    private BlockIdIsToday(blockID: string) {
        const now = new Date();
        return this.BlockID(now, CCVista.DAY) == blockID;
    }

    private BlockOpenOptions(container: TSelectionHTML, block: ICalendarioDateBlock) {
        const itemList: DropdownFlexV2.IConfig<ICalendarioDateBlock>["Items"] = [
            {
                Label: _L("c_actions.agregar"), OnClick: () => {
                    console.debug(block)
                    this.OnInputEvent({
                        Descripcion: "",
                        IdCalendario: 0,
                        Inicio: block,
                        Fin: block,
                    })
                }
            },
            {
                Label: _L("calendar.see_day"), OnClick: () => {
                    this.SetFocusDateTag(block);
                    this.SelectTipoVista(CCVista.DAY);
                }
            },
            {
                Label: _L("calendar.see_week"), OnClick: () => {
                    this.SetFocusDateTag(block);
                    this.SelectTipoVista(CCVista.WEEK);
                }
            }
        ]
        new DropdownFlexV2.Control()
            ._SetParent(container)
            ._SetMenuOptions(itemList)
            ._Show()
    }

    // ****************************************************************
    // Event things
    // ****************************************************************

    /** Map<blockID, blockEvents> */
    private EventGetEventsInCurrentView(block: Date, vista: CCVista, eventos: ICEventoControl[]): Map<string, ICEventoControl<any>[]> {
        const vistaGlobal = this.vista;
        // const vista = this.vista;
        // console.debug("EventGetEventsInCurrentView", block.toDateString(), vista, eventos.length)
        const currentBlocksIDs: string[] = this.BlocksDatesOfDateIDs(block, vista);
        const eventosFiltrados = new Map(currentBlocksIDs.map(blockID => {
            const blockEvents: ICEventoControl<any>[] = []
            if (vistaGlobal == CCVista.YEAR) {
                const firstEv = eventos.find(ev => (blockID == this.BlockID(ev.Inicio, vista)))
                if (firstEv)
                    blockEvents.push(firstEv);
            }
            else {
                blockEvents.push(...eventos.filter(ev => (blockID == this.BlockID(ev.Inicio, vista))))
            }
            return [blockID, blockEvents];
        }))
        return eventosFiltrados;
    }

    private EventDragInteractionEnabled(d: ICEventoControl) {
        if (d._IsFullDay)
            return false;
        return [CCVista.DAY, CCVista.WEEK].includes(this.vista);
    }

    private EventClickEnabled(/*d: ICEventoControl*/) {
        return [CCVista.DAY, CCVista.WEEK, CCVista.MONTH].includes(this.vista);
    }

    private EventInteractionControllerRemove(d: ICEventoControl) {
        d._Interactable?.draggable(false);
        d._Interactable?.resizable(false);
        if (d._Element) {
            d._Element.onpointerdown = null;
            d._Element.onpointerup = null;
        }
        d._InteractableObserverWorking = false;
    }
    private EventInteractionControllerRestart(d: ICEventoControl) {
        const interactionEnabled = this.EventDragInteractionEnabled(d);
        if (d._Interactable && !interactionEnabled) {
            d._Interactable.draggable(false);
            d._Interactable.resizable(false);
        }
        const clickEnabled = this.EventClickEnabled();
        if (d._Element && !clickEnabled) {
            d._Element.onpointerdown = null;
            d._Element.onpointerup = null;
        }
        if (clickEnabled) {
            this.EventClickController(d);
        }
        if (interactionEnabled) {
            const onMove: EventInteractionCallback = ({ dtStart, dtEnd, type }) => {
                const elContent = d._Element?._Content;
                if (!elContent) return;
                if (type == "drag")
                    this.EventContentRefresh(elContent, { Inicio: (dtStart || d.Inicio), Fin: (dtEnd || d.Fin) });
                else if (type == "resize")
                    this.EventContentRefresh(elContent, { Fin: (dtEnd || d.Fin) });
            }
            const onEnd: EventInteractionCallback = ({ dtStart, dtEnd }) => {
                d._BeforeAlteration = {
                    Inicio: new Date(d.Inicio),
                    Fin: new Date(d.Fin),
                }
                d.Inicio = (dtStart || d.Inicio);
                d.Fin = (dtEnd || d.Fin);
            }
            this.EventDragController(d, onMove, onEnd);
            this.EventResizeController(d, onMove, onEnd);
        }
        d._InteractableObserverWorking = true;
    }

    private EventClickController(d: ICEventoControl) {
        if (!d._Element) return;
        let startPoint: [number, number];

        d._Element.onpointerdown = e => {
            const validElementsToClick: Set<EventTarget | HTMLElement> = new Set([
                e.currentTarget,
                ...(() => {
                    const content = d._Element._Content;
                    if (!content) return [];
                    return [content, ...content.querySelectorAll("*").values()];
                })()
            ])
            if (!validElementsToClick.has(e.target)) {
                console.debug("Click fuera de elemento evento", validElementsToClick)
                return;
            }
            startPoint = [e.clientX, e.clientY];
        }

        d._Element.onpointerup = e => {
            // e.stopPropagation();
            if (!startPoint) return;
            const clickValido = this.CallOnClickEvent(startPoint, [e.clientX, e.clientY], d);
            if (!clickValido) {
                startPoint = null;
            }
        }
    }

    private EventDragController(d: ICEventoControl, onMove: EventInteractionCallback, onEnd: EventInteractionCallback) {
        if (!d._Interactable || !d._Element) {
            return;
        }
        const focusElement = (element: HTMLDivElement) => {
            const eventItem = element;
            const eventItemClone = d3.select(eventItem)
                .clone(true)
                .classed("TEMP", true)
                .style("opacity", .5)
                .property("tabIndex", -1)
                .attr(EVENT_ITEM_DRAG_ATTR_OBSERVER, null)
                .attr(EVENT_ITEM_RESIZE_ATTR_OBSERVER, null)
                .node();

            if (eventItem["__temp_clone__"]) {
                eventItem["__temp_clone__"].remove();
            }
            eventItem.parentElement.appendChild(eventItemClone);
            eventItem["__temp_clone__"] = eventItemClone;

            d3.select(eventItem)
                .attr(EVENT_ITEM_DRAG_ATTR_OBSERVER, "true")
            // .raise();
            eventItem.focus();
            // console.log("focus", eventItem)
        }
        const dropElement = (element: HTMLDivElement) => {
            d3.select(element)
                .attr(EVENT_ITEM_DRAG_ATTR_OBSERVER, "false");

            if (element["__temp_clone__"]) {
                element["__temp_clone__"].remove();
                element["__temp_clone__"] = null;
            }
        }
        d._Interactable.draggable({
            lockAxis: "y",
            modifiers: this.EventInteractableBases(d),
            listeners: {
                start: (e: Interact.DragEvent) => {
                    focusElement(e.target as HTMLDivElement);
                },
                end: (e: Interact.DragEvent) => {
                    dropElement(e.target as HTMLDivElement);
                    const dtNewRanges = this.DatesInElementPosition(d);
                    onEnd({ type: "drag", dtStart: dtNewRanges.start, dtEnd: dtNewRanges.end });
                },
                move: (e: Interact.DragEvent) => {
                    const eventItem = e.target as HTMLDivElement;
                    const top = +eventItem.style.top.replace("px", "");
                    let newTop = top + e.delta.y;
                    eventItem.style.top = newTop + "px";

                    const dtNewRanges = this.DatesInElementPosition(d);
                    onMove({ type: "drag", dtStart: dtNewRanges.start, dtEnd: dtNewRanges.end });
                }
            }
        })
    }

    private EventResizeController(d: ICEventoControl, onMove: EventInteractionCallback, onEnd: EventInteractionCallback) {
        if (!d._Interactable || !d._Element) {
            return;
        }
        d._Interactable.resizable({
            edges: { top: false, left: false, bottom: true, right: false },
            margin: 8,
            modifiers: this.EventInteractableBases(d),
            listeners: {
                start: (e: Interact.ResizeEvent) => {
                    const eventItem = e.target as HTMLDivElement;
                    d3.select(eventItem)
                        .attr(EVENT_ITEM_RESIZE_ATTR_OBSERVER, "true")
                    // .raise();
                    eventItem.focus();
                },
                end: (e: Interact.ResizeEvent) => {
                    d3.select(e.target as HTMLDivElement)
                        .attr(EVENT_ITEM_RESIZE_ATTR_OBSERVER, "false")

                    // NOTE: Solo se edita dtFin
                    const dtNewRanges = this.DatesInElementPosition(d);
                    onEnd({ type: "resize", dtEnd: dtNewRanges.end, dtStart: null });
                },
                move: (e: Interact.ResizeEvent) => {
                    const eventItem = e.target as HTMLDivElement;
                    if (e.edges.top) {
                        const top = +eventItem.style.top.replace("px", "");
                        let newTop = top + e.delta.y;
                        eventItem.style.top = newTop + "px";
                    }
                    eventItem.style.height = e.rect.height + "px";

                    // NOTE: Solo se edita dtFin
                    const dtNewRanges = this.DatesInElementPosition(d);
                    onMove({ type: "resize", dtEnd: dtNewRanges.end, dtStart: null });
                }
            },
        })
    }

    private EventInteractableBases(d: ICEventoControl): (Interact.Options["modifiers"]) {
        if (!d._Element) {
            return [];
        }
        const parentElem = d._Element.parentElement.parentElement;
        return [
            interact.modifiers.snap({
                targets: [
                    interact.snappers.grid({
                        x: 1,
                        y: (this.PxInAMinute(d) * EVENT_SNAP_MINUTES_ONINTERACTION),
                        // y: (console.debug("grid-cell", this.PxInAMinute(d) * EVENT_SNAP_MOVE_MINUTES), this.PxInAMinute(d) * EVENT_SNAP_MOVE_MINUTES),
                    })
                ],
                range: Infinity,
                relativePoints: [{ x: 0, y: 1 }]
            }),
            interact.modifiers.restrict({
                restriction: parentElem,           // keep the drag coords within the element
                elementRect: { top: 0, left: 0, bottom: 1, right: 0 },
                // endOnly: true,
            })
        ]
    }

    /**
     * @param element ".event_item"|".content", si es ".event_item" se buscará ".content"
     * @param event Actualiza el DOM
     */
    /**
     * @param element ".event_item"|".content", si es ".event_item" se buscará ".content"
     * @param content.Inicio si es null | undefined no actualiza el DOM
     * @param content.Fin si es null | undefined no actualiza el DOM
     * @param content.Descripcion si es null | undefined no actualiza el DOM
     * @param content.IdCalendario si es null | undefined no actualiza el DOM
     */
    private EventContentRefresh(element: HTMLDivElement, content: Partial<Pick<ICEventoControl, "Inicio" | "Fin" | "Descripcion" | "IdCalendario">>) {
        //     return this.EventContentRefreshFinal(element, content.Inicio, content.Fin, content.Descripcion);
        // }
        // private EventContentRefreshFinal(element: HTMLDivElement, dtStart?: Date, dtEnd?: Date, descripcion?: string) {
        const { IdCalendario, Inicio, Fin, Descripcion } = content;
        const isFullDay = this.EventEvalIsFullDay(Inicio, Fin);
        if (!element) return;
        if (element.classList.contains("event_item")) {
            element = (element as ICEventoControl["_Element"])._Content;
            if (!element) return;
        }
        this.EventContentEvalTemplate(element);
        const lblHr = element.querySelector(":scope>.lbl_hr");
        if (Descripcion != null)
            element.querySelector(":scope>.lbl_desc").textContent = Descripcion || `(${_L("calendar.sintitulo")})`;
        if (isFullDay)
            lblHr.textContent = _L("calendar.fullday")
        else if (this.vista != CCVista.YEAR) {
            if (Inicio != null) {
                const lbl_hr_start = lblHr.querySelector(":scope>.lbl_hr_start")
                if (lbl_hr_start["__time_ms"] != Inicio.getTime()) {
                    lbl_hr_start["__time_ms"] = Inicio.getTime()
                    lbl_hr_start.textContent = UIUtilTime._DateFormatStandar(Inicio, "h24:mm")
                }
            }
            if (Fin != null)
                lblHr.querySelector(":scope>.lbl_hr_end").textContent = UIUtilTime._DateFormatStandar(Fin, "h24:mm")
        }

        if (IdCalendario != null) {
            const lblCal = element.querySelector<HTMLElement>(":scope>.lbl_cal")
            lblCal.textContent = this.calendariosMap.get(IdCalendario)?.Descripcion || "";
            // lblCal.style.borderColor = this.GetCalendarColor(IdCalendario);
            // lblCal.style.backgroundColor = this.GetCalendarColor(IdCalendario, .5);
        }
    }

    private EventContentEvalTemplate(element: HTMLDivElement) {
        let lblDesc = element.querySelector(":scope>.lbl_desc")
        let lblHr = element.querySelector(":scope>.lbl_hr")
        let lblCal = element.querySelector(":scope>.lbl_cal")
        if (!lblDesc) {
            lblDesc = document.createElement("div");
            lblDesc.className = "lbl_desc";
            element.append(lblDesc);
        }
        if (!lblHr) {
            lblHr = document.createElement("div");
            lblHr.className = "lbl_hr";
            element.append(lblHr);
        }
        if (lblHr.childElementCount == 0 || !lblHr.querySelector(".lbl_hr_start") || !lblHr.querySelector(".lbl_hr_end")) {
            // lblHr.innerHTML = `<span class="lbl_hr_start"></span>`
            //     + `<span class=""> - </span>`
            //     + `<span class="lbl_hr_end"></span>`
            const lbl_hr_start = document.createElement("span")
            lbl_hr_start.className = "lbl_hr_start"
            const lbl_hr_ = document.createElement("span")
            lbl_hr_.textContent = " - "
            const lbl_hr_end = document.createElement("span")
            lbl_hr_end.className = "lbl_hr_end"
            lblHr.append(lbl_hr_start)
            lblHr.append(lbl_hr_)
            lblHr.append(lbl_hr_end)
        }
        if (!lblCal) {
            lblCal = document.createElement("div");
            lblCal.className = "lbl_cal";
            element.append(lblCal);
        }
    }

    private EventDimention(event: ICEventoControl) {
        const proportion = this.PxInAMinute(event);
        if (![CCVista.DAY, CCVista.WEEK].includes(this.vista) || proportion == 0) {
            return null;
        }

        const startMinutes = (event.Inicio.getHours() * 60) + event.Inicio.getMinutes();
        const endMinutes = (event.Fin.getHours() * 60) + event.Fin.getMinutes();

        const top = startMinutes * proportion
        const bottom = endMinutes * proportion
        // console.log(top, bottom, proportion)
        return {
            top: top,
            height: bottom - top,
        }
    }

    private EventFocusGoToById(id: number, smooth = false) {
        const evCont = this.contentContainer.select<HTMLElement>("#ev_" + id).node()
        if (!evCont) return;
        this.EventFocusGoToByElement(evCont, smooth);
    }

    private EventFocusGoToByElement(eventContainer: HTMLElement, smooth = false) {
        if (!eventContainer.classList.contains("event_item")) {
            console.warn("El elemento no es un contenedor de evento", eventContainer);
            return;
        }
        setTimeout(() => {
            eventContainer.scrollIntoView({
                behavior: smooth ? "smooth" : "instant",
                block: "center",
            });
        }, 50);
    }

    private EventFocusEventMinDateIncurrentView(tipo: "all" | "main-area" = "main-area") {
        const container = (() => {
            if (tipo == "all") return this.contentContainer;
            else if (tipo == "main-area") return this.contentContainer._areaEventos._mainArea
        })()
        const evConts = container.selectAll<HTMLElement, ICEventoControl>(".event_item").nodes();
        const minEv = evConts.reduce((a, b) => {
            return a?.getBoundingClientRect().top < b?.getBoundingClientRect().top ? a : b;
        }, null)
        if (!minEv) return;
        this.flagFocusItemInCurrentView = false;
        this.EventFocusGoToByElement(minEv);
    }

    private EventShowPreviewAndOptions(event: ICEventoControl) {
        const element: HTMLElement = event._Element.parentElement.parentElement.classList.contains("disabled")
            ? event._Element.parentElement.parentElement
            : event._Element
        // DROPDOWN DATA ITEMS
        const prevAndOptionsScheme: ICEventoOption[] = [];
        if (this.eventConfigs.DrawEventPreview) {
            prevAndOptionsScheme.push({ Id: 0, Label: "", Call: null }) // PREVIEW ITEM
        }
        if (this.eventConfigs.GetEventOptions) {
            const options = this.eventConfigs.GetEventOptions(event);
            if (options?.length)
                prevAndOptionsScheme.push(...options.map<ICEventoOption>((op, i) => ({
                    Id: i + 1,
                    ...op
                })));
        }
        if (!prevAndOptionsScheme.length) {
            return;
        }
        // SHOW DROPDOWN
        event._Element.classList.add("ev_focus");
        this.EventInteractionControllerRemove(event);
        const dropdown = new DropdownAdvanced({
            Data: prevAndOptionsScheme,
            IsMultiSelect: false,
            Parent: element,
            ValueMember: "Id",
            DisplayMember: "Label",
            AppendInBody: true,
            ListWidth: "calc(200px + var(--padding2) * 10)",
            OnChange: ([id]) => {
                if (id == 0) return;
                const option = prevAndOptionsScheme.find(d => d.Id == id);
                option.Call();
            },
            OnStepItemListUI: (cont: TSelectionHTML, option) => {
                if (option.Id == 0) {
                    cont.classed("event_preview", true)
                        .style("border-bottom", prevAndOptionsScheme.length == 0 ? null : "1px solid var(--color_borderbox1)")
                    this.eventConfigs.DrawEventPreview(event, cont.node());
                    const btnClose = UIUtilElement._CreateElementFromHTML(`<div class="btn_close">${ic_close}</div>`)
                    cont.node().parentElement.append(btnClose);
                    btnClose.onclick = () => dropdown._Hide();
                } else {
                    cont.text(option.Label);
                }
            },
            OnChangeVisible: (visible) => {
                if (visible) return;
                event._Element?.classList.remove("ev_focus");
                event._Element?.blur()
                this.EventInteractionControllerRestart(event);
            }
        })._Show();
        dropdown._listContainer.classed("evento_opts", true)
    }

    private EventEvalIsFullDay(inicio: Date, fin: Date): boolean {
        if (!inicio || !fin)
            return false;
        const difHrs = UIUtilTime._GetTimeElapsedSimple(inicio, fin).Hours();
        return (difHrs == 24) // || difHrs == 23)
        /*&& UIUtilTime._DateFormatStandar(inicio, "h24:mm:ss") == "00:00:00"
        && UIUtilTime._DateFormatStandar(fin, "h24:mm:ss") == "00:00:00"*/
    }

    private EventPublic(event: ICEventoControl): ICEvento<Extras> {
        return {
            Id: event.Id,
            IdCalendario: event.IdCalendario,
            Descripcion: event.Descripcion,
            Inicio: event.Inicio,
            Fin: event.Fin,
            Extra: event.Extra,
        }
    }

    // ****************************************************************
    // Callbacks
    // ****************************************************************

    private OnInputEvent(tentativeEvent: ICEventoInput) {
        const { OnInputEvent } = this.callbacks || {};
        if (!OnInputEvent) return;
        OnInputEvent(tentativeEvent);
    }

    private CallOnChangeEvents(events: ICEventoControl[]) {
        const { OnChangeEvents: OnChange } = this.callbacks || {};
        if (!OnChange) return false;
        const changedEvents: ICEvento<Extras>[] = [];

        if (this["__tempeval_"] == null) //TEMPORAL
            this["__tempeval_"] = 0;
        this["__tempeval_"]++
        this["__tempeval"] = this["__tempeval_"] + " "

        console.group(this["__tempeval"] + "onchange: validating events");
        events.forEach(d => {
            if (!d._BeforeAlteration) {
                console.info(this["__tempeval"] + "onchange: no alterado", d._Element); // NO PASARÁ
                return
            }
            const originStart = d._BeforeAlteration.Inicio.getTime();
            const originEnd = d._BeforeAlteration.Fin.getTime();
            const evalStart = d.Inicio.getTime();
            const evalEnd = d.Fin.getTime();
            if (evalStart == originStart && evalEnd == originEnd) {
                console.info(this["__tempeval"] + "onchange: alteracion aplica");
                return
            }
            // d.Inicio = d._BeforeAlteration.Inicio;
            // d.Fin = d._BeforeAlteration.Fin;
            d._BeforeAlteration = null;

            changedEvents.push(this.EventPublic(d));
        });
        console.groupEnd();

        if (!changedEvents.length) return false;

        console.debug(this["__tempeval"] + "onchange: success", changedEvents)
        OnChange(changedEvents);
        return true;
    }

    private CallOnClickEvent(startPointer: [number, number], endPointer: [number, number], d: ICEventoControl) {
        const clickMargin = 1; // px
        const [startX, startY] = startPointer;
        const [endX, endY] = endPointer;
        const clickValido = endX <= startX + clickMargin && endX >= startX - clickMargin
            && endY <= startY + clickMargin && endY >= startY - clickMargin;

        if (!clickValido)
            return clickValido;

        if (this.callbacks?.OnClickEvent) {
            this.callbacks.OnClickEvent(this.EventPublic(d), d._Element);
        }
        this.EventShowPreviewAndOptions(d);
        return clickValido;
    }

    // ****************************************************************
    // ?
    // ****************************************************************

    private SetFocusDateTag(dt: Date) {
        this.focusDate = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate());
        const contTag = this.contentContainer._areaNavegacion._currentDate.select("label");
        // const vista = this.vista;
        let tag = "";
        // if (vista == CCalendarioVista.DAY)
        tag = UIUtilTime._DateFormatStandar(dt, "d MMM yyyy");
        // else if (vista == CCalendarioVista.WEEK)
        contTag.text(tag);
    }

    private SelectTipoVista(vista: CCVista) {
        const vistaName = CCVista[vista];
        this.vista = vista;
        this.flagFocusItemInCurrentView = true;
        DataUtilLocalStorage._SetItem("calendar_" + this.id, "last_view_mode", vistaName);
        this.ViewRefreshGeneral();
        const selected = this.selectTipoVista._dataSelected;
        if (selected.length && selected[0].id != vista) {
            this.selectTipoVista._valueSelect(this.vista);
        }
    }

    private AddEventos(eventos: ICEvento<Extras>[]) {
        if (!this.eventosMap) {
            this.eventosMap = new Map();
        }
        (eventos || []).forEach(d => {
            const dFinal: ICEventoControl = UtilObject._GettersSetters(d as ICEventoControl, {
                _Inicio_: d.Inicio,
                _Fin_: d.Fin,
                _Descripcion_: d.Descripcion,
                setInicio: (val) => this.EventContentRefresh(dFinal._Element, { Inicio: val }),
                setFin: (val) => this.EventContentRefresh(dFinal._Element, { Fin: val }),
                setDescripcion: (val) => this.EventContentRefresh(dFinal._Element, { Descripcion: val }),
                get_IsInteracting: (dato) => {
                    if (!dato._Element) return false;
                    return dato._Element.getAttribute(EVENT_ITEM_DRAG_ATTR_OBSERVER) == "true"
                        || dato._Element.getAttribute(EVENT_ITEM_RESIZE_ATTR_OBSERVER) == "true";
                },
                get_IsFullDay: (dato) => {
                    return this.EventEvalIsFullDay(dato.Inicio, dato.Fin);
                }
            })
            this.eventosMap.set(d.Id, dFinal);
        })
        // this.eventosMap = new Map(().map(d => [d.Id, <ICalendarioEventoControl>d]));
    }

    private GetEventosFiltrados(): ICEventoControl[] {
        const eventos: ICEventoControl[] = [];
        this.eventosMap.forEach((evento) => {
            if (this.calendariosMap.get(evento.IdCalendario)?.Checked) {
                eventos.push(evento);
            }
        })
        return eventos;
    }

    private DatesInElementPosition(event: ICEventoControl, round = true) {
        const eventElement = event._Element;
        // const event = d3.select<HTMLDivElement, ICEventoControl>(eventElement).datum();
        // const element = event._Element
        const pxInMinute = this.PxInAMinute(event);
        // console.debug(pxInMinute)

        const parentDayTop = eventElement.parentElement.parentElement.getBoundingClientRect().top;
        let { top, bottom } = eventElement.getBoundingClientRect();
        top -= parentDayTop;
        bottom -= parentDayTop;

        let minStart = top / pxInMinute;
        let minEnd = bottom / pxInMinute;

        if (round) {
            minStart = Math.round(minStart / EVENT_SNAP_MINUTES_ONINTERACTION) * EVENT_SNAP_MINUTES_ONINTERACTION;
            minEnd = Math.round(minEnd / EVENT_SNAP_MINUTES_ONINTERACTION) * EVENT_SNAP_MINUTES_ONINTERACTION;
        }

        const dtStart = new Date(event.Inicio.getFullYear(), event.Inicio.getMonth(), event.Inicio.getDate())
        const dtEnd = new Date(event.Fin.getFullYear(), event.Fin.getMonth(), event.Fin.getDate())
        // FIXME: SE AJUSTÓ ASÍ PORQUE SE SALTABA AL DÍA SIGUIENTE
        if (minEnd >= 1440) minEnd = 1439;

        dtStart.setMinutes(minStart);
        dtEnd.setMinutes(minEnd);
        return {
            start: dtStart,
            end: dtEnd,
        }
    }

    private PxInAMinute(event: ICEventoControl) {
        const element = event._Element
        const dayContainer = element.parentElement.parentElement;
        if (!element || !dayContainer)
            return 0;
        const dayContainerHeight = dayContainer.getBoundingClientRect().height;
        const dayMinutes = 24 * 60;
        return (dayContainerHeight / dayMinutes)
    }

    // ****************************************************************
    // Public Methods & Properties
    // ****************************************************************

    public _SetParent(parent: TSelectionHTML): this {
        parent.append(() => this.contentContainer.node());
        return this;
    }

    /** Reemplaza todos los eventos actuales del control */
    public _SetEventos(eventos: ICEvento<Extras>[]): this {
        this.eventosMap.clear();
        this.AddEventos(eventos);
        this.ViewRefreshGeneral();
        return this;
    }

    public RefreshView() {
        this.ViewRefreshGeneral();
    }

    public _AddOrReplaceEventos(eventos: ICEvento<Extras>[]): this {
        this.AddEventos(eventos);
        this.ViewRefreshGeneral();
        return this;
    }

    public _SelectTipoVista(vista: TipoVista): this {
        this.SelectTipoVista(CCVista[vista]);
        return this;
    }

    public _SetFocusDate(date: Date): this {
        this.SetFocusDateTag(date);
        this.ViewRefreshGeneral();
        return this;
    }

    public _GetFocusDate(): Date {
        return new Date(this.focusDate);
    }

    public _CheckCalendars(ids: number[] | "all", checked: boolean): this {
        let idsCals: number[] = [];
        if (ids == "all") {
            idsCals = [...this.calendariosMap.values()].map(d => d.Id);
        } else {
            idsCals = ids;
        }
        this.CheckCalendarios(idsCals, checked);
        this.ViewRefreshGeneral();
        return this;
    }

    public _GetCalendarsSelected(): ICCalendario[] {
        return this.GetCalendarsSelected();
    }

    public _DrawEventPreview(callback: ICConfig<Extras>["DrawEventPreview"]): this {
        this.eventConfigs.DrawEventPreview = callback;
        return this;
    }

    public _GetEventOptions(callback: ICConfig<Extras>["GetEventOptions"]): this {
        this.eventConfigs.GetEventOptions = callback;
        return this;
    }

    // FIXME ESTÁ INCOMPLETO, SOLO BUSCA AL ELEMENTO EN EL DOM
    // public _GoToEventById(id: number) {
    //     this.EventFocusGoToById(id);
    // }

    public _FocusMinEventInCurrentView(): this {
        this.EventFocusEventMinDateIncurrentView();
        return this;
    }

    public _EventEvalFullDay(inicio: Date, fin: Date) {
        return this.EventEvalIsFullDay(inicio, fin);
    }

    public get _CurrentDateFocused(): Date {
        return new Date(this.focusDate);
    }


    public get _CalendarsList(): ICCalendario[] {
        return [...this.calendariosMap.values()]
            .map(d => ({
                Id: d.Id,
                IdGrupo: d.IdGrupo,
                Descripcion: d.Descripcion,
            }))
    }
}