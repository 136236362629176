import * as d3 from "d3";

export class List<Datum = any> {
    private containerSelection: TSelectionHTML<"div">;
    private items: Datum[];

    private enterCallback: (itemContainer: TSelectionHTML<"div", Datum>, datum: Datum) => void;
    private updateCallback: (itemContainer: TSelectionHTML<"div", Datum>, datum: Datum) => void;
    private exitCallback: (itemContainer: TSelectionHTML<"div", Datum>, datum: Datum) => void;

    constructor() {
        this.items = [];
        this.InitUI();
    }

    private InitUI() {
        this.containerSelection = d3.create("div")
            .attr("class", "list");
    }

    private UpdateUI() {
        this.containerSelection.selectAll<HTMLDivElement, Datum>(":scope > .item")
            .data(this.items, (d, i) => i + "")
            .join(
                enter => {
                    return enter.append("div")
                        .attr("class", "item")
                        .each((d, i, entriesElements) => {
                            if (this.enterCallback || this.updateCallback) {
                                const itemSelection = d3.select<HTMLDivElement, Datum>(entriesElements[i]);
                                if (this.enterCallback) {
                                    this.enterCallback(itemSelection, d);
                                }
                                if (this.updateCallback) {
                                    this.updateCallback(itemSelection, d);
                                }
                            } else {
                                entriesElements[i].textContent = d + "";
                            }
                        });
                },
                update => update
                    .each((d, i, entriesElements) => {
                        if (this.updateCallback) {
                            const itemSelection = d3.select<HTMLDivElement, Datum>(entriesElements[i]);
                            this.updateCallback(itemSelection, d);
                        } else {
                            entriesElements[i].textContent = d + "";
                        }
                    }),
                exit => exit
                    .each((d, i, entriesElements) => {
                        if (this.exitCallback) {
                            const itemSelection = d3.select<HTMLDivElement, Datum>(entriesElements[i]);
                            this.exitCallback(itemSelection, d);
                        } else {
                            entriesElements[i].textContent = d + "";
                            exit.remove();
                        }
                    })
            )
    }

    get _Items() {
        return this.items;
    }
    get _ListContainerSelection() {
        return this.containerSelection;
    }
    get _ListContainerNode() {
        return this.containerSelection.node();
    }

    public _SetParent(parent: HTMLElement | TSelectionHTML<"htmlelement">) {
        if (parent instanceof HTMLElement) {
            parent.appendChild(this.containerSelection.node());
        }
        else if (parent) {
            parent.append(() => this.containerSelection.node());
        }
        else {
            this.containerSelection.remove();
        }
        return this;
    }

    public _SetItems(items: Datum[]): this {
        this.items = items || [];
        this.UpdateUI();
        return this;
    }

    public _RefreshList() {
        this.UpdateUI();
        return this;
    }

    public _SetCreateTemplate(call: typeof this.enterCallback) {
        this.enterCallback = call;
        return this;
    }

    public _SetUpdateItem(call: typeof this.updateCallback) {
        this.updateCallback = call;
        return this;
    }

    public _SetExitItem(call: typeof this.exitCallback) {
        this.exitCallback = call;
        return this;
    }
}
