import * as d3 from "d3";
import { MainPage } from "../../MainPage";
import { DataDRequest } from "../../data/DRequest";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import DataModuloEscolaridad, { IConfiguraEscolaridadResponse } from "../../data/modulo/Escolaridad";
import { CardV2Collapse, TCARDV2COLL_OnEditOriginEvent } from "../controlD3/CardV2Collapse";
import { FormGenerator, Fields } from "../controlD3/Formulario";
import { UIUtilLang } from "../util/Language";
import { UIUtilViewData } from "../util/ViewData";
import { UIUtilViewEscolaridad } from "../utilView/Escolaridad";
import { UIUtilViewEscuelas } from "../utilView/Escuelas";
import CEvento = Entidad.CTipoEvento;
import IEscolaridad = Entidad.IEscolaridad;
import IConfiguracion = Entidad.IConfigEscolaridad;

interface ITipoEventoItem extends UIUtilViewData.ITipoEventoItemList<"normal"> {
    IsCheck?: boolean;
}

interface IEmocionItem extends UIUtilViewData.IEmocionItemList { }

interface IItemList {
    IsCheck: boolean;
    TextValue: string;
}

interface IBiberonPapillaItem extends IItemList {
    Type: CBiberonPapilla;
}

enum CBiberonPapilla {
    Biberon = 1,
    Papilla = 2
}

type IFormData = UIUtilViewEscolaridad.IEscolaridadConfigFormData;

export class UIPanelCardEscuelasNivelEventos extends CardV2Collapse<[IEscolaridad]> {
    /** currentNivel */
    private dataEscolaridad: IEscolaridad;
    private currentConfiguracionNivel: IConfiguracion;
    private dataConfigFormularioEditable: IFormData;
    private dataConfigFormularioInicial: IFormData;
    private form: FormGenerator<IFormData>;

    constructor(modulo = Entidad.CModulo.PanelConfiguracionNiveles) {
        super("", Entidad.CModulo.PanelConfiguracionNiveles);
    }

    // *********************************************************************
    // HERENCIA
    // *********************************************************************

    protected CARDCOLL_OnInitBuild(contentContainer: TSelectionHTML<"div", any, any>): void {
        this.cardHeaderSelection.remove();
        // >> Crea formulario
        this.form = new FormGenerator<IFormData>();

        this.form._Crear({
            LabelMaxWidth: 250,
            LangModuleKeyInContext: this.moduloName,
            schema: [
                { model: "Actividades", type: Fields.label, labelAttr: { text: "tag_configevts" } },
                { model: "Emociones", type: Fields.label, labelAttr: { text: "tag_emociones" } },
                { model: "AlimentosConfig", type: Fields.label, labelAttr: { text: "tag_alimentos" } },
            ],
            BuildView: (container, controlsForm) => {
                // -> Dibujo inicial del formulario
                container.classed("form_conf_escolaridad", true);

                // -> ACTIVIDADES ROW
                const ctrlActividades = controlsForm.get("Actividades");
                ctrlActividades.selection = ctrlActividades.row.append("div")
                    .classed("area_actividades", true);

                // -> EMOCIONES ROW
                const ctrlEmociones = controlsForm.get("Emociones");
                ctrlEmociones.selection = ctrlEmociones.row.append("div")
                    .classed("area_emociones", true);

                // -> ALIMENTOS ROW
                const ctrlAlimentos = controlsForm.get("AlimentosConfig");
                ctrlAlimentos.selection = ctrlAlimentos.row.append("div")
                    .classed("area_alimentos", true);

                ctrlAlimentos.selection.append("div")
                    .attr("class", "cont_alimentos_inputtext");

                ctrlAlimentos.selection.append("div")
                    .attr("class", "cont_alimentos_biberonpapilla")

                // -> Pegar rows personalizados al formulario
                container.append(() => ctrlActividades.row.node());
                container.append(() => ctrlEmociones.row.node());
                container.append(() => ctrlAlimentos.row.node());
            },
            Validation: (value, field, datosForm, controlsForm) => {
                let rowIsValid = true;
                let inputsText: HTMLInputElement[] = [];

                switch (field) {
                    case "AlimentosConfig":
                        if (this.dataConfigFormularioEditable.Actividades.get(CEvento.Alimentos).IsCheck) {
                            inputsText = this.form._ControlsData.get("AlimentosConfig").selection.selectAll<HTMLInputElement, any>(".alimento_inputtext").nodes();
                            let alimentosStrs = this.dataConfigFormularioEditable.AlimentosConfig.Alimentos
                                .map(d => d.TextValue)
                                .filter(text => Boolean(text?.trim()));

                            if (alimentosStrs.length == 0) {
                                let mensaje = this.CARDCOLL_GetUIStringModule("notif_falta_alimento");
                                this.ctrlNotification._Mostrar(mensaje, "ADVERTENCIA");
                                //this.notificacion.fn_Mostrar("Configura al menos un alimento", Notificacion.CTipoNotificacion.ADVERTENCIA);
                                rowIsValid = false;
                            } else {
                                alimentosStrs.forEach(text => {
                                    if ((alimentosStrs.filter(d => (d === text)).length > 1)) {
                                        rowIsValid = false;
                                    }
                                })
                                if (!rowIsValid) {
                                    let mensaje = this.CARDCOLL_GetUIStringModule("notif_alimrepetido");
                                    this.ctrlNotification._Mostrar(mensaje, "ADVERTENCIA");
                                    // this.notificacion.fn_Mostrar("Existen alimentos repetidos", Notificacion.CTipoNotificacion.ADVERTENCIA);
                                }
                            }
                        }
                        break;
                    case "Emociones":
                        if (this.dataConfigFormularioEditable.Actividades.get(CEvento.Humor).IsCheck) {
                            inputsText = this.form._ControlsData.get("Emociones").selection.selectAll<HTMLInputElement, any>("input").nodes();
                        }
                        break;
                }
                for (let input of inputsText) {
                    if (!input.validity.valid) {
                        rowIsValid = false;
                    }
                }

                console.log("validando...", field, rowIsValid)
                return rowIsValid;
            }
        }, <IFormData>{}, undefined, false)

        contentContainer.append(() => this.form._Form.node());
        this.UpdateModalAndFormMode(false);
    }

    protected CARDCOLL_GetVariantToValidateUpdate(cardData_0: IEscolaridad): string {
        return cardData_0.Id + "_" + cardData_0.Modificacion;
    }

    private idEscuela: number;
    protected CARDCOLL_OnUpdateData(cardData_0: IEscolaridad): void {
        if (this.idEscuela != cardData_0?.Id) {
            if (this.HasActionPermission(Entidad.CAccionPermiso.Editar)) {
                if (!this.btnEditarCard._node.parentElement) {
                    this.cardActionsContainerSelection
                        .append(() => this.btnEditarCard._node);
                }
            } else {
                this.btnEditarCard._d3Selection.remove();
            }
        }
        this.idEscuela = cardData_0?.Id;
        this.UpdateView(cardData_0);
    }

    protected CARDCOLL_MostrarBody() {
    }

    protected CARDCOLL_OcultarBody(): void {
    }

    protected async CARDCOLL_OnEditarCard() {
        this.UpdateModalAndFormMode(true);
    }

    protected async CARDCOLL_OnCancelaEditarCard(originEvent: TCARDV2COLL_OnEditOriginEvent) {
        this.ResetView();
    }

    protected async CARDCOLL_GuardarCardV2(): Promise<DataDRequest.IRequestResponseA<IConfiguraEscolaridadResponse>> {
        let res: DataDRequest.IRequestResponseA<IConfiguraEscolaridadResponse>

        if (this.form._GetIsValidForm()) {
            const newConfig = UIUtilViewEscolaridad._GetFormularioNivelConfigChanges(this.dataConfigFormularioEditable, this.dataConfigFormularioInicial);

            // let fnAsignarAlimentosCalendarioGoogle = () => {
            //     let alimentosOldConfig = this.dataConfigFormularioInicial.Actividades.get(CEvento.Alimentos).IsCheck;
            //     let alimentosNewConfig = this.dataConfigFormularioEditable.Actividades.get(CEvento.Alimentos).IsCheck;

            //     if (alimentosNewConfig && alimentosOldConfig != alimentosNewConfig) {
            //         UIUtilViewEscuelas._OpenModal_Escolaridad_AsignarAlimentosACalendarioGoogle(this.dataEscolaridad);
            //     }
            // }

            res = await DataModuloEscolaridad._ConfigurarEscolaridad(this.dataEscolaridad.Id, newConfig);

            if (res.Resultado > 0) {
                // >>
                // fnAsignarAlimentosCalendarioGoogle();

                // >>
                await MainPage._ReloadServiceAndAwaitBool(Entidad.CTipoRequest.Escolaridad, this.dataEscolaridad.IdEscuela);
            }

            res.Mensaje = UIUtilLang._GetHTTPMessage(res);
        }
        return res;
    }

    protected CARDCOLL_SyncOrGetIdToDownloadData(): DataModuloMain.TipoRequestMonitorId | (() => Promise<void>) {
        return async () => {
            await this.UpdateView();
        }
    }

    protected CARDCOLL_GetIdSchool(cardData_0: IEscolaridad): number {
        return cardData_0.IdEscuela;
    }

    // *********************************************************************
    // PRIVATE
    // *********************************************************************

    private async ResetView() {
        await this.UpdateDataToFormulario(this.dataEscolaridad, false);
        this.UpdateAllFormRows();
        this.UpdateModalAndFormMode(false);
    }

    private async UpdateView(nivel = this.dataEscolaridad) {
        await this.UpdateDataToFormulario(nivel, true);
        this.UpdateAllFormRows();
    }

    private UpdateModalAndFormMode(modoEditar: boolean) {
        this.form._Form.classed("preview_mode", !modoEditar);
        this.form._ControlsData.forEach((control, value) => {
            const inputs = control.selection.selectAll<HTMLInputElement, any>("input");
            this.form._TabIndexMode(inputs, !modoEditar);
        })
    }

    // FORM THINGS

    /** Crea la estructura de datos que maneja el formulario en base a la escolaridad */
    private async UpdateDataToFormulario(nivel: IEscolaridad, reset: boolean) {
        let configuracion: IConfiguracion;

        if (!reset && this.dataEscolaridad?.Id == nivel.Id) {
            configuracion = this.currentConfiguracionNivel;
        }
        this.dataEscolaridad = nivel;

        if (!configuracion) {
            const resConfigEscolaridad = await DataModuloEscolaridad._ObtenerConfiguracion(nivel.Id);

            if (resConfigEscolaridad.Resultado > 0) {
                configuracion = resConfigEscolaridad.Datos;
                this.currentConfiguracionNivel = resConfigEscolaridad.Datos
            }
            else {
                this.ctrlNotification._Mostrar(UIUtilLang._GetUIString("general", "notif_fail_infosync"), "ADVERTENCIA");
            }
        }

        if (configuracion) {
            this.dataConfigFormularioEditable = UIUtilViewEscolaridad._GetProcessedConfiguration(nivel.IdEscuela, configuracion);
            this.dataConfigFormularioInicial = UIUtilViewEscolaridad._GetProcessedConfiguration(nivel.IdEscuela, configuracion);
        }
        else {
            this.dataConfigFormularioEditable = null;
            this.dataConfigFormularioInicial = null;
        }
    }

    private UpdateAllFormRows() {
        if (this.dataConfigFormularioEditable) {
            this.form._AsignaData(this.dataConfigFormularioEditable);
            this.UpdateAreaActividades();
            this.UpdateAreaEmociones();
            this.UpdateAreaAlimentos();
        }
    }

    private UpdateAreaActividades() {
        const actividades: ITipoEventoItem[] = Array.from(this.dataConfigFormularioEditable.Actividades.values());
        const ctrlActividades = this.form._ControlsData.get("Actividades");

        const UpdateItem = (item: TSelectionHTML<"div", ITipoEventoItem>) => {
            // item.classed("opaque", d => !d.IsCheck);
            let checks = item.select<HTMLInputElement>("input").property("checked", d => d.IsCheck).nodes();
            item.select("img").property("src", d => d.Icon);
            item.select("label").text(d => d.Name);

            item.on("click", (d, i, arrItems) => {
                d.IsCheck = !d.IsCheck;

                checks[i].checked = d.IsCheck;
                // d3.select(arrItems[i]).classed("opaque", !d.IsCheck);

                // -> Si Activa/Desactiva Humor
                if (d.Id == CEvento.Humor) {
                    let emocionesRow = this.form._ControlsData.get("Emociones").row
                        .classed("hide", !d.IsCheck)
                        .classed("anim_rebote", true);

                    if (d.IsCheck) {
                        emocionesRow.node().scrollIntoView({
                            behavior: "smooth",
                            block: "center"
                        });
                    }
                }

                // -> Si Activa/Desactiva Alimentos
                if (d.Id == CEvento.Alimentos) {
                    let alimentosRow = this.form._ControlsData.get("AlimentosConfig").row
                        .classed("hide", !d.IsCheck)
                        .classed("anim_rebote", d.IsCheck);

                    if (d.IsCheck) {
                        alimentosRow.node().scrollIntoView({
                            behavior: "smooth",
                            block: "center"
                        });
                    }
                }
            })
            return item;
        }
        ctrlActividades.selection.selectAll<HTMLDivElement, ITipoEventoItem>(":scope > .item_actividad").data(actividades).join(
            enter => {
                let item = enter.append("div")
                    .classed("item_actividad", true);

                item.append("div").classed("div_top", true);

                item.select(".div_top").append("input").attr("type", "checkbox");
                item.select(".div_top").append("img").property("draggable", false);
                item.append("label");

                return UpdateItem(item);
            },
            update => UpdateItem(update),
            exit => exit.remove()
        )
    }

    private UpdateAreaEmociones() {
        const emociones: IEmocionItem[] = this.dataConfigFormularioEditable.Emociones;
        const ctrlEmociones = this.form._ControlsData.get("Emociones");

        ctrlEmociones.row.classed("hide", !this.dataConfigFormularioEditable.Actividades.get(CEvento.Humor).IsCheck);

        const UpdateItem = (item: TSelectionHTML<"div", IEmocionItem>) => {
            item.select("img").property("src", d => d.Icon);
            item.select<HTMLInputElement>("input").property("value", d => d.Name)
                .each((d, i, arrInputs) => {
                    arrInputs[i].onkeyup = (e) => {
                        // console.log(d.Name, arrInputs[i].value, "Emocion update");
                        d.Name = arrInputs[i].value;
                    }
                })
            return item;
        }

        ctrlEmociones.selection.selectAll<HTMLDivElement, IEmocionItem>(":scope > .item_emocion").data(emociones).join(
            enter => {
                let item = enter.append("div")
                    .classed("item_emocion", true);

                item.append("img").property("draggable", false);
                item.append("input").attr("type", "text")
                    .property("required", true)
                    .attr("maxlength", 35);

                return UpdateItem(item);
            },
            update => UpdateItem(update),
            exit => exit.remove()
        )
    }

    private UpdateAreaAlimentos() {
        const alimentosConfig = this.dataConfigFormularioEditable.AlimentosConfig;
        const ctrlAlimentos = this.form._ControlsData.get("AlimentosConfig");

        ctrlAlimentos.row.classed("hide", !this.dataConfigFormularioEditable.Actividades.get(CEvento.Alimentos).IsCheck);

        // -> Area de cajas de texto de alimentos

        const UpdateItemAlimento = (item: TSelectionHTML<"div", IItemList>) => {
            // let items = item
            //     .classed("opaque", d => !d.IsCheck)
            //     .nodes();
            let checks = item.select<HTMLInputElement>(".alimento_checkbox").property("checked", d => d.IsCheck).nodes();
            let inputs = item.select<HTMLInputElement>(".alimento_inputtext")
                .property("value", d => d.TextValue)
                .property("required", d => d.IsCheck)
                .each((d, i, arrItems) => {
                    const inputText = arrItems[i];

                    inputText.onkeyup = (e) => {
                        d.TextValue = inputText.value;
                        checks[i].checked = Boolean(d.TextValue);
                        d.IsCheck = checks[i].checked;
                        // d3.select(items[i]).classed("opaque", !d.IsCheck);

                        d3.select(inputText)
                            .property("required", d.IsCheck);
                    }
                })
                .nodes();

            item.select<HTMLInputElement>(".alimento_checkbox").on("click", (d, i, arrItems) => {
                d.IsCheck = !d.IsCheck;
                d.TextValue = !d.IsCheck ? "" : d.TextValue;

                checks[i].checked = d.IsCheck;
                d3.select(inputs[i]).property("value", d.TextValue)
                    .property("required", d.IsCheck);

                if (d.IsCheck) {
                    inputs[i].focus();
                }
                // d3.select(items[i]).classed("opaque", !d.IsCheck);
            })
            return item;
        }

        ctrlAlimentos.selection.select(".cont_alimentos_inputtext")
            .selectAll<HTMLDivElement, IItemList>(":scope > .item_alimento")
            .data(alimentosConfig.Alimentos)
            .join(
                enter => {
                    let item = enter.append("div")
                        .classed("item_alimento", true);

                    item.append("input").attr("type", "checkbox")
                        .classed("alimento_checkbox", true);

                    item.append("input").attr("type", "text")
                        .classed("alimento_inputtext", true)
                        .attr("maxlength", 35);

                    return UpdateItemAlimento(item);
                },
                update => UpdateItemAlimento(update),
                exit => exit.remove()
            )

        // -> Area de lista de checkbox: Biberon / papilla

        const UpdateItemBiberonPapilla = (item: TSelectionHTML<"div", IBiberonPapillaItem>) => {
            item
                .on("click", (d, i, arrItems) => {
                    d.IsCheck = !d.IsCheck;
                    d3.select(arrItems[i]).select<HTMLInputElement>("input[type='checkbox']")
                        .node()
                        .checked = d.IsCheck;
                    // let checkbox = controlD3.CheckBox.fn_SeletionCheckApplyDatum(d3.select(arrItems[i]).select(".checkbox_table"));
                    // controlD3.CheckBox.fn_UpdateCheckStatus(checkbox, d.IsCheck);

                })
                .select<HTMLInputElement>("input[type='checkbox']")
                .each((d, i, arrCheckBox) => {
                    d3.select(arrCheckBox[i]).node().checked = d.IsCheck;
                    // let checkbox = controlD3.CheckBox.fn_SeletionCheckApplyDatum(d3.select(arrCheckBox[i]));
                    // controlD3.CheckBox.fn_UpdateCheckStatus(checkbox, d.IsCheck);
                });

            item.select("label")
                .text(d => d.TextValue);
            return item;
        }
        ctrlAlimentos.selection.select(".cont_alimentos_biberonpapilla")
            .selectAll<HTMLDivElement, IBiberonPapillaItem>(":scope > .item_alimento")
            .data(alimentosConfig.BiberonPapilla)
            .join(
                enter => {
                    let item = enter.append("div")
                        .classed("item_alimento", true);
                    // item.append(d => controlD3.CheckBox.fn_GetCheckElement(d.IsCheck).node());
                    item.append("input")
                        .attr("type", "checkbox");
                    item.append("label");

                    return UpdateItemBiberonPapilla(item);
                },
                update => UpdateItemBiberonPapilla(update),
                exit => exit.remove()
            )
    }

    // **************************************************************************
    // PERMISOS COSAS
    // **************************************************************************

    // **************************************************************************
    //  COSAS: SERVICIOS Y DATA
    // **************************************************************************
}
