import * as d3 from "d3";
import { Entidad } from "../../data/Entidad";
import { _LOCALDATA_GetGruposHorariosDeAlumno } from "../../data/modulo/Alumno";
import DataModuloGrupo from "../../data/modulo/Grupo";
import { DateV2 } from "../../util/DateV2";
import { Button } from "../controlD3/Button";
import { DropdownFlexV2 } from "../controlD3/DropdownFlexV2";
import { FormGenerator } from "../controlD3/Formulario";
import { ModalThings } from "../controlD3/ModalThings";
import { NotificacionV2 } from "../controlD3/NotificacionV2";
import { ScheduleBase } from "../controlD3/ScheduleBase";
import { ScheduleBaseMinutes } from "../controlD3/ScheduleBaseMinutes";
import { HTMLButton2Element } from "../controlWC/Button2Component";
import { HTMLTooltipComponent } from "../controlWC/TooltipComponent";
import { UIUtilIconResources } from "../util/IconResourses";
import { UIUtilLang } from "../util/Language";
import { UIUtilTime } from "../util/Time";
import { UIUtilGeneral } from "../util/Util";

export namespace UIUtilViewGruposScheduleControl {
    import CDia = Entidad.CDiaSemanal;

    interface IConfig {
        Parent: d3.Selection<HTMLElement, any, any, any>;
        IdGrupo?: number;
        ZonaHoraria?: string;
        // /** @deprecated //NOTE INTENTAR DEPRECAR  */
        // OnError: (errorInfo: IResumenErrorDia) => void;
    }

    type TBloqueError = "limites" | "noeliminardia";
    interface IResumenErrorDia {
        Tipo: TBloqueError;
        // Message: string;
        Alumnos: Entidad.IAlumno[];
        IdDia: CDia;
    }

    type IGroupItemBlock = ScheduleBaseMinutes.IItemBlockMinutes<undefined>;

    type IGroupItemBlockResult = Pick<ScheduleBaseMinutes.IItemBlockMinutes, "Id" | "IdDia"> & {
        /** Ej: `12:30` */
        HrInicio: string;
        /** Ej: `13:30` */
        HrFin: string;
    };

    export class ScheduleControl extends ScheduleBaseMinutes.ScheduleBMinutesBase<undefined> {
        private enableEditar: boolean;
        //private enableResizeMove: boolean;
        private idGrupo: number;
        private zonaHoraria: string;

        constructor(config: IConfig) {
            super();
            this.enableEditar = true;
            //this.enableResizeMove = true;
            this.idGrupo = config.IdGrupo;
            this.zonaHoraria = config.ZonaHoraria;

            let updatingTimeOut: NodeJS.Timeout;

            super.SCHEDULE_MIN_InitConfig({
                Parent: config.Parent,
                UI_Column_ItemHeader_Template_OnDraw: (container) => {
                    Button.BtnPlus._GetPlusButton(15, container)
                        .style("cursor", "pointer");
                },
                UI_Column_ItemHeader_JOIN_OnUpdate: (container, dataColumn) => {
                    container.select(".btn_plus").raise()
                        .classed("hide", !this.enableEditar)
                        .on("click", () => {
                            if (this.GetColumnIsValid(dataColumn.Id)) {
                                let id = 0 - Number(new Date());
                                let init = this._SCHEDULE_RowStarValue;
                                let newItem = this.SCHEDULE_MIN_GetScheduleItemValid(id, init, init + ScheduleBaseMinutes._HOUR_MINUTES, dataColumn.Id, { EnableBtns: dataColumn.Visible }, this.enableEditar);
                                super.SCHEDULE_SetScheduleItems(newItem);
                            }
                        })
                },
                UI_Column_ItemBlock_OnValideEnter: (columnD, blockD) => {
                    return this.GetColumnIsValid(columnD.Id);
                },
                UI_Column_ColumnArea_JOIN_OnUpdate: (container, columnData) => {
                    container.classed("column_disable", !columnData.Visible);
                    //container.classed("hide", !columnData.Visible);
                },
                UI_ItemBlock_Template_OnDraw: (container, datumBlock, parentContainer) => {
                    parentContainer.select(".r_options").append("wc-button-host").classed("options_btn", true)
                        .append("img")
                        .attr("src", UIUtilIconResources.CGeneral.Options)
                        .classed("btn_round", true)
                        .classed("btn_options", true)
                        .style("width", "18px")
                        .style("height", "18px")
                        .style("margin", "0px");
                    container.append("label");

                    let expired: number;
                    container.select<HTMLLabelElement>("label").node().onclick = (e) => {
                        if (!expired) {
                            expired = e.timeStamp + 500
                        } else if (e.timeStamp <= expired && this.enableEditar) {
                            e.preventDefault();
                            this.OpenEditarHorario(container, datumBlock.Id, parentContainer);
                            expired = null;
                        } else {
                            expired = e.timeStamp + 500
                        }
                    }
                    let dropdown = new DropdownFlexV2.Control({
                        Parent: parentContainer.select("wc-button-host"),
                        Items: [
                            { Label: UIUtilLang._GetUIString("grupopanelhorariooperacion", "lbl_editar"), OnClick: () => this.OpenEditarHorario(container, datumBlock.Id, parentContainer) },
                            { Label: UIUtilLang._GetUIString("grupopanelhorariooperacion", "lbl_aplicardiasrestantes"), OnClick: () => this.CopiaConfigDia(datumBlock.Id) },
                        ]
                    }, "HORIZONTAL");
                    parentContainer.select<HTMLButton2Element>(".options_btn").node().onclick = (e) => {
                        if (dropdown._Visible) {
                            dropdown._Hide();
                        } else {
                            dropdown._Show();
                        }
                    }
                },
                UI_ItemBlock_Item_OnUpdate: (container, datum, parentContainer) => {
                    let text = this.SCHEDULE_MIN_GetHoursRangeStr(datum.InitRange, datum.EndRange); // + datum.Id + "_" + datum.IdColumnPositon;
                    container.select("label").text(text).style("cursor", (this.enableEditar) ? "pointer" : "auto");

                    // NOTE Conflicto Eliminar bloque
                    let alumnosConflictoEliminar = this.GetPuedeEliminarBloque_AlumnosConflicto(datum);
                    let btnRemove = parentContainer.select<HTMLElement>(".btn_close")
                        .classed("input_err", Boolean(alumnosConflictoEliminar.length));
                    let tooltip: TSelectionHTML<"wc-tooltip"> = parentContainer.select<HTMLTooltipComponent>("#tooltip_err_days");

                    if (!tooltip.node()) {
                        tooltip = parentContainer.append<HTMLTooltipComponent>("wc-tooltip")
                            .attr("id", "tooltip_err_days")
                            // .attr("observed-selection", ".btn_close")
                            .attr("max-width", "250px")
                            .attr("position", "top");
                    }

                    if (alumnosConflictoEliminar.length) {
                        // Reemplaza el evento por default
                        btnRemove.on("click", null);
                        let errorMessage = this.GetErrorMessage({
                            Tipo: "noeliminardia",
                            IdDia: datum.IdColumnPositon as CDia,
                            Alumnos: alumnosConflictoEliminar
                        });
                        tooltip.node()._SetObservedElementsAdvanced({
                            Target: btnRemove.node(),
                            Text: errorMessage,
                        })
                    } else {
                        tooltip.node()._RemoveObservedELements(btnRemove.node());
                    }
                },
                UI_ItemBlock_ItemUI_OnUpdating: (container, datum, parentContainer) => {
                    let text = this.SCHEDULE_MIN_GetHoursRangeStr(datum.InitRange, datum.EndRange); // + datum.Id + "_" + datum.IdColumnPositon;
                    container.select("label").text(text);

                    clearTimeout(updatingTimeOut);
                    // let tooltip = parentContainer.select<controlWC.HTMLTooltipComponent>("#tooltip_err_limits");
                    let tooltip: TSelectionHTML<"wc-tooltip"> = container.select<HTMLTooltipComponent>("#tooltip_err_limits");
                    tooltip.node()?._Refresh(); // Actualiza la posición con respecto al bloque

                    updatingTimeOut = setTimeout(() => {
                        // >> Conflicto Redimencionar bloque
                        let alumnosConflictoLimites = this.GetErrorLimitesBloque_AlumnosConflicto(datum);

                        parentContainer.classed("input_err", Boolean(alumnosConflictoLimites.length));

                        if (alumnosConflictoLimites.length) {
                            if (!tooltip.node()) {
                                tooltip = container.append<HTMLTooltipComponent>("wc-tooltip")
                                    .attr("id", "tooltip_err_limits")
                                    .attr("max-width", "250px")
                                    // .attr("observed-selection", ".content_cont")
                                    .attr("position", "bottom");
                            }
                            let errorMessage = this.GetErrorMessage({
                                Tipo: "limites",
                                IdDia: datum.IdColumnPositon as CDia,
                                Alumnos: alumnosConflictoLimites.map(al => al.alumno)
                            });
                            tooltip.text(errorMessage);
                            if (!tooltip.node()._Visible) {
                                tooltip.node()._Visible = true;
                            }
                        }
                        else if (tooltip) {
                            tooltip.remove();
                        }
                    }, 400);
                }
            })
        }

        // ********************************************************************
        // PRIVATE GETTERS
        // ********************************************************************

        /** Un bloque solo puede entrar en una columna vacía */
        private GetColumnIsValid(idColumn: CDia | string) {
            let itemsCol = 0;
            this.scheduleItems.forEach(d => {
                if (d.IdColumnPositon == idColumn) {
                    itemsCol++;
                }
            });
            return (itemsCol == 0);
        }

        /** Valida si el bloque puede ser eliminado: Solo puede ser eliminado si nungún alumno vinculado tiene ese mismo dia configurado
         * @returns Alumno[] con conflicto
         */
        private GetPuedeEliminarBloque_AlumnosConflicto(bloqueE: ScheduleBase.IBlockItemReadOnly<any>) {
            return Array.from(DataModuloGrupo._LOCALDATA_GetAlumnosEnGrupo(this.idGrupo).values())
                .filter(alumno => {
                    const horariosAlumno = _LOCALDATA_GetGruposHorariosDeAlumno(alumno.IdChild);
                    const horarioAlumnoCurrentGrupo = horariosAlumno.get(this.idGrupo);

                    if (horarioAlumnoCurrentGrupo) {
                        for (let bloqueActual of horarioAlumnoCurrentGrupo.Horario) {
                            if (bloqueActual.IdDia == bloqueE.IdColumnPositon) {
                                return true;
                            }
                        }
                    }
                    return false;
                });
        }

        private GetErrorLimitesBloque_AlumnosConflicto(bloqueE: ScheduleBase.IBlockItemReadOnly<any>) {
            return Array.from(DataModuloGrupo._LOCALDATA_GetAlumnosEnGrupo(this.idGrupo).values())
                .map(alumno => {
                    const horariosAlumno = _LOCALDATA_GetGruposHorariosDeAlumno(alumno.IdChild);
                    const horarioAlumnoCurrentGrupo = horariosAlumno.get(this.idGrupo);

                    if (horarioAlumnoCurrentGrupo) {
                        let bloqueActual = horarioAlumnoCurrentGrupo.Horario.find(d => (d.IdDia == bloqueE.IdColumnPositon));
                        if (bloqueActual && !this.GetBloqueValido(bloqueE, bloqueActual).valid) {
                            return {
                                alumno,
                                range: this.GetBloqueValido(bloqueE, bloqueActual).range
                            };
                        }
                    }
                    return null;
                }).filter(al => al != null);
        }

        private GetBloqueValido(bloqueValidar: ScheduleBase.IBlockItemReadOnly<any>, bloqueRealComparado: Entidad.IHorarioDia) {
            let initRealRange = this.SCHEDULE_MIN_GetDayMinutes_FromDate(new DateV2(bloqueRealComparado.Entrada)._SetTimeZone(this.zonaHoraria));
            let endRealRange = this.SCHEDULE_MIN_GetDayMinutes_FromDate(new DateV2(bloqueRealComparado.Salida)._SetTimeZone(this.zonaHoraria));

            if (bloqueValidar.InitRange >= endRealRange) {
                return {
                    valid: false,
                    range: "Init"
                }
            }
            if (bloqueValidar.EndRange <= initRealRange) {
                return {
                    valid: false,
                    range: "End"
                }
            }
            return {
                valid: true,
                range: ""
            };
        }

        private GetResumenErrores() {
            // >> Valída limites horarios grupo - alumnos horarios:
            // Se verifica que todos los bloques (dias: entradas-salidas) de los alumnos asignados al grupo
            // queden dentro de los limites configurados.
            const alumnos = Array.from(DataModuloGrupo._LOCALDATA_GetAlumnosEnGrupo(this.idGrupo).values());
            const bloquesEditados = Array.from(this.scheduleItems.values());
            /** Map<CDia + _ + TBloqueError, IResumen...> */
            let resumenErrores: Map<string, IResumenErrorDia> = new Map();

            for (let alumno of alumnos) {
                const horariosAlumno = _LOCALDATA_GetGruposHorariosDeAlumno(alumno.IdChild);
                const horarioAlumnoCurrentGrupo = horariosAlumno.get(this.idGrupo);

                if (horarioAlumnoCurrentGrupo) {
                    for (let bloqueVigente of horarioAlumnoCurrentGrupo.Horario) {
                        let bloqueEditado = bloquesEditados.find(d => (d.IdColumnPositon == bloqueVigente.IdDia));
                        let tipoError: TBloqueError;
                        let idError = bloqueVigente.IdDia + "_";
                        let valido = true;

                        if (bloqueEditado) {
                            // Revisar error limites
                            if (!this.GetBloqueValido(bloqueEditado, bloqueVigente).valid) {
                                tipoError = "limites";
                                idError += tipoError;
                                valido = false;
                            }
                        } else {
                            // Error no eliminar día
                            tipoError = "noeliminardia";
                            idError += tipoError;
                            valido = false;
                        }

                        if (!valido) {
                            let itemError = resumenErrores.get(idError);

                            if (!itemError) {
                                itemError = {
                                    IdDia: bloqueVigente.IdDia,
                                    Tipo: tipoError,
                                    Alumnos: []
                                }
                            }
                            itemError.Alumnos.push(alumno);
                            resumenErrores.set(idError, itemError);
                        }
                    }
                }
            }
            return resumenErrores;
        }

        private GetErrorMessage(topError: IResumenErrorDia) {
            // OnError: (topError) => {
            let strTempMessage = "";
            switch (topError.Tipo) {
                case "limites":
                    if (topError.Alumnos.length > 1) {
                        // strTempMessage = this.CARDCOLL_GetUIStringModule("notif_alumnosfueradelimites_n")
                        strTempMessage = UIUtilLang._GetUIString("grupopanelhorariooperacion", "notif_alumnosfueradelimites_n")
                            .replace("_NALUMNOS", (topError.Alumnos.length - 1).toString());
                    } else {
                        strTempMessage = UIUtilLang._GetUIString("grupopanelhorariooperacion", "notif_alumnosfueradelimites_1");
                    }
                    strTempMessage = strTempMessage
                        .replace("_ALUMNO", topError.Alumnos[0].NombreCompleto)
                        .replace("_DIA", UIUtilTime._GetDayName(topError.IdDia));
                    break;
                case "noeliminardia":
                    if (topError.Alumnos.length > 1) {
                        strTempMessage = UIUtilLang._GetUIString("grupopanelhorariooperacion", "notif_noeliminarbloque_n")
                            // strTempMessage = this.CARDCOLL_GetUIStringModule("notif_noeliminarbloque_n")
                            .replace("_NALUMNOS", (topError.Alumnos.length - 1).toString());
                    } else {
                        strTempMessage = UIUtilLang._GetUIString("grupopanelhorariooperacion", "notif_noeliminarbloque_1")
                        // strTempMessage = this.CARDCOLL_GetUIStringModule("notif_noeliminarbloque_1");
                    }
                    strTempMessage = strTempMessage
                        .replace("_ALUMNO", topError.Alumnos[0].NombreCompleto)
                        .replace("_DIA", UIUtilTime._GetDayName(topError.IdDia));
                    break;
            }
            return strTempMessage;

            // this.notificacion.met_Mostrar(strTempMessage, "ADVERTENCIA");
        }

        private CopiaConfigDia(datumBlockId: number | string) {
            this.scheduleItems.forEach(d => {
                if (d.Id !== datumBlockId) {
                    let actItem = this.scheduleItems.get(datumBlockId);
                    d.InitRange = actItem.InitRange;
                    d.EndRange = actItem.EndRange;
                    this.UI_UpdateABlockItem(d.UI_Item, d);

                    let tooltip: TSelectionHTML<"wc-tooltip"> = d.UI_Item.select(".content_cont").select<HTMLTooltipComponent>("#tooltip_err_limits");
                    tooltip.node()?._Refresh(); // Actualiza la posición con respecto al bloque

                    let alumnosConflictoLimites = this.GetErrorLimitesBloque_AlumnosConflicto(d);

                    d.UI_Item.classed("input_err", Boolean(alumnosConflictoLimites.length));
                    if (alumnosConflictoLimites.length) {
                        if (!tooltip.node()) {
                            tooltip = d.UI_Item.select(".content_cont").append<HTMLTooltipComponent>("wc-tooltip")
                                .attr("id", "tooltip_err_limits")
                                .attr("max-width", "250px")
                                .attr("position", "bottom");
                        }
                        let errorMessage = this.GetErrorMessage({
                            Tipo: "limites",
                            IdDia: d.IdColumnPositon as CDia,
                            Alumnos: alumnosConflictoLimites.map(al => al.alumno)
                        });
                        tooltip.text(errorMessage);
                    }
                    else if (tooltip) {
                        tooltip.remove();
                    }
                }
            })
        }

        private OpenEditarHorario(container: TSelectionHTML<"div", any, any>, datumBlockId: number | string, parentContainer: TSelectionHTML<"div", any, any>) {
            let actualDatumBlock = this.scheduleItems.get(datumBlockId);
            let entrada = new DateV2()
                ._SetLocalStrHour("00:00:00.000")
                ._AddMinutes(actualDatumBlock.InitRange)
            let horaEntrada = UIUtilTime._FmtToInputTime(entrada)
            let salida = new DateV2()
                ._SetLocalStrHour("00:00:00:000")
                ._AddMinutes(actualDatumBlock.EndRange)
            let horaSalida = UIUtilTime._FmtToInputTime(salida);
            interface horarios {
                horaEntrada: string,
                horaSalida: string
            }
            ModalThings._GetModalToForm({
                Title: UIUtilTime._GetDayName(actualDatumBlock.IdColumnPositon as number),
                Width: 200,
                GetForm: () => {
                    return new FormGenerator<horarios>()._Crear({
                        LabelMaxWidth: 100,
                        schema: [
                            { "model": "horaEntrada", type: "input", inputAttr: { type: "time", required: true, inputProperties: { "value": horaEntrada } }, labelText: UIUtilLang._GetUIString("grupopanelhorariooperacion", "lbl_inicio") },
                            { "model": "horaSalida", type: "input", inputAttr: { type: "time", required: true, inputProperties: { "value": horaSalida } }, labelText: UIUtilLang._GetUIString("grupopanelhorariooperacion", "lbl_fin") }
                        ],
                        Validation: (value, model, dataForm, controlsForm) => {
                            let valueEntrada = Number(dataForm.horaEntrada.split(':')[0]) * 60 + Number(dataForm.horaEntrada.split(':')[1]);
                            let valueSalida = Number(dataForm.horaSalida.split(':')[0]) * 60 + Number(dataForm.horaSalida.split(':')[1]);
                            let alumnosConflictoLimites = this.GetErrorLimitesBloque_AlumnosConflicto({ ...actualDatumBlock, InitRange: valueEntrada, EndRange: valueSalida });
                            if (model === "horaEntrada") {
                                if (valueEntrada > (valueSalida - ScheduleBaseMinutes._MIN_BLOCK_MINUTES)) {
                                    return false
                                }
                                if (this._GetLimitesHorario.MinInicio > valueEntrada) {
                                    NotificacionV2._Mostrar(UIUtilLang._GetUIString("grupopanelhorariooperacion", "notif_entoutrange"), "ADVERTENCIA");
                                    return false
                                }
                                if (alumnosConflictoLimites.length && alumnosConflictoLimites.map(al => al.range)[0] === "Init") {
                                    NotificacionV2._Mostrar(UIUtilLang._GetUIString("grupopanelhorariooperacion", "notif_conflictalumno_initrange").replace("_ALUMNO", alumnosConflictoLimites.map(al => al.alumno)[0].NombreCompleto), "ADVERTENCIA");
                                    return false;
                                }
                            }
                            if (model === "horaSalida") {
                                if (valueEntrada > (valueSalida - ScheduleBaseMinutes._MIN_BLOCK_MINUTES)) {
                                    NotificacionV2._Mostrar(UIUtilLang._GetUIString("grupopanelhorariooperacion", "notif_initexcedend").replace("_MIN", ScheduleBaseMinutes._MIN_BLOCK_MINUTES.toString()), "ADVERTENCIA");
                                    return false
                                }
                                if (valueSalida > this._GetLimitesHorario.MinFin) {
                                    NotificacionV2._Mostrar(UIUtilLang._GetUIString("grupopanelhorariooperacion", "notif_endoutrange"), "ADVERTENCIA");
                                    return false;
                                }
                                if (alumnosConflictoLimites.length && alumnosConflictoLimites.map(al => al.range)[0] === "End") {
                                    NotificacionV2._Mostrar(UIUtilLang._GetUIString("grupopanelhorariooperacion", "notif_conflictoalumno_endrange").replace("_ALUMNO", alumnosConflictoLimites.map(al => al.alumno)[0].NombreCompleto), "ADVERTENCIA");
                                    return false;
                                }
                            }
                            return true;
                        }
                    }, { horaEntrada, horaSalida });
                },
                DrawContent: (content, form, mt) => {
                    mt.BtnLeft.remove();
                    content.classed("filteredit_container", true);

                    const modal = mt.Modal._ModalSelection
                        .style("position", "absolute")
                        .node();
                    const modalParent = d3.select(modal.parentElement)
                        .style("position", "absolute")
                        .style("background-color", "transparent")
                        .node();
                    let colocaModal = () => {
                        let limits = container.node().getBoundingClientRect();
                        let containerBox = modalParent.getBoundingClientRect();
                        let box = modal.getBoundingClientRect();
                        let left = ((containerBox.width - (limits.right + box.width + 20)) <= 0) ? limits.left - box.width : limits.right;
                        //let top = (containerBox.height > (limits.y + box.height + 30)) ? limits.y : limits.y - box.height;
                        let dif = containerBox.height - (limits.y + box.height + 20);
                        let top = limits.y + (dif < 0 ? dif : 0);
                        modal.style.left = left + "px";
                        modal.style.top = top + "px";
                    }
                    colocaModal();
                    let observeResize = UIUtilGeneral._GetResizeObserver(entries => {
                        setTimeout(colocaModal, 200);
                    });
                    observeResize.observe(modalParent);
                },
                OnAccept: (form, mt) => {
                    actualDatumBlock.InitRange = Number(form._Data.horaEntrada.split(':')[0]) * 60 + Number(form._Data.horaEntrada.split(':')[1]);
                    actualDatumBlock.EndRange = Number(form._Data.horaSalida.split(':')[0]) * 60 + Number(form._Data.horaSalida.split(':')[1]);
                    this.UI_UpdateABlockItem(parentContainer, actualDatumBlock);
                    mt.Modal._Ocultar();
                    actualDatumBlock.UI_Item.classed("input_err", false);
                    container.select("#tooltip_err_limits").remove();
                    return null;
                }
            })
        }

        // ********************************************************************
        // PUBLIC METHODS
        // ********************************************************************

        set _IdGrupoToValidateBlocks(value: number) {
            this.idGrupo = value;
        }

        set _ZonaHoraria(value: string) {
            this.zonaHoraria = value;
        }

        public _SetScheduleItem(...items: IGroupItemBlock[]) {
            let itemsUpdate: ScheduleBase.IBlockItem[] = items
                .map(item => {
                    let initRange = this.SCHEDULE_MIN_GetDayMinutes_FromDate(item.Inicio);
                    let endRange = this.SCHEDULE_MIN_GetDayMinutes_FromDate(item.Fin);
                    return this.SCHEDULE_MIN_GetScheduleItemValid(item.Id, initRange, endRange, item.IdDia, { EnableBtns: this.enableEditar }, this.enableEditar);
                });

            super.SCHEDULE_SetScheduleItems(...itemsUpdate);
            return this;
        }

        public _GetCurrentItems(): IGroupItemBlockResult[] {
            return Array.from(this.scheduleItems.values())
                .map(d => ({
                    Id: d.Id as any,
                    IdDia: d.IdColumnPositon as CDia,
                    HrInicio: this.SCHEDULE_MIN_GetHoursStr_FromDayMinutes(d.InitRange),
                    HrFin: this.SCHEDULE_MIN_GetHoursStr_FromDayMinutes(d.EndRange),
                }))
        }

        public _SetEnableItemsToEdit(enableInteractions: boolean) {
            //this.enableResizeMove = enableInteractions;

            if (enableInteractions !== this.enableEditar) {
                this.enableEditar = enableInteractions;
                this.SCHEDULE_UpdateColumns(this.config.ColumnsConfig);
            }

            super.SCHEDULE_SetScheduleItems(...Array.from(this.scheduleItems.values())
                .map(d => {
                    d.Data.EnableBtns = enableInteractions;
                    d.EnableMoveLeftRight = enableInteractions;
                    d.EnableMoveTopDown = enableInteractions;
                    d.EnableResizing = enableInteractions;

                    return d
                })
            );
        }
        //PASAR A SCHEDULE BASE
        public _DaysEnable(days: CDia[], visible: boolean) {
            let configDays = this.config.ColumnsConfig.map(col => {
                if (days.find(d => d == col.Id)) {
                    col.Visible = visible;
                }
                return col;
            })
            this.SCHEDULE_UpdateColumns(configDays);
        }

        public _GetResumenErrores() {
            return Array.from(this.GetResumenErrores().values());
        }
    }
}
