import * as d3 from "d3";
import saveAs from "file-saver";
import { DataDRequest } from "../../data/DRequest";
import { Entidad } from "../../data/Entidad";
import DataModuloEgresoMovimiento from "../../data/modulo/EgresoMovimiento";
import { Button } from "../controlD3/Button";
import { FullView } from "../controlD3/FullView";
import { NotificacionV2 } from "../controlD3/NotificacionV2";
import { PDFThings } from "../controlD3/PDFBuilder";
import { HTMLFileIcoElement } from "../controlWC/FileIcoComponent";
import { HTMLSpinnerElement } from "../controlWC/SpinnerComponent";
import { HTMLTooltipComponent } from "../controlWC/TooltipComponent";
import { UIUtilIconResources } from "../util/IconResourses";
import { UIUtilLang } from "../util/Language";
import { UIUtilGeneral } from "../util/Util";
import { UIUtilViewFinanzaEgreso } from "./FinanzasEgreso";
import { FileLoader } from "../controlD3/FileLoader";
import { UIUtilCFDI } from "../util/CFDI";
import { HTMLProgressElement } from "../controlWC/ProgressComponent";
import { DateV2 } from "../../util/DateV2";

export namespace UIUtilViewFinanzaEgresoUtil {
    const { _GetUIString: LangString } = UIUtilLang;
    const { _Mostrar: MostrarNotificacion } = NotificacionV2;

    import IEgresoMovimiento = Entidad.IFinanzaEgresoMovimiento;
    import CCategoriaMov = Entidad.CFinanzaCategoriaMovimiento;
    // import IDetalle = Entidad.IFinanzaEgresoMovimientoDetalle;

    export function _GetSaldoFinalFromMto(movimiento: IEgresoMovimiento): number {
        if (!movimiento.Detalles.length) {
            return movimiento.Saldo;
        }
        return movimiento.Detalles[movimiento.Detalles.length - 1].Saldo;
    }

    export function _MovHasPago(mov: IEgresoMovimiento): boolean {
        return mov.Detalles?.some(d => [CCategoriaMov.PagoParcial, CCategoriaMov.PagoCompleto].includes(d.TipoRegistro))
    }

    /** Usado para validar fechas de pago, no se puede registrar un pago anterior a la fecha del último realizado */
    export function _GetUltimaFechaAplicacionFromMto(movimiento: IEgresoMovimiento): DateV2 {
        if (!movimiento.Detalles.length) {
            return new DateV2(movimiento.FechaAplicacion)
                ._SetTimeZoneByIdSchool(movimiento.IdEscuela)
        }
        return new DateV2(movimiento.Detalles[movimiento.Detalles.length - 1].FechaAplicacion)
            ._SetTimeZoneByIdSchool(movimiento.IdEscuela);
    }

    export function _EsMovimientoVencido({ PagoPendiente, FechaVencimiento, IdEscuela }: { PagoPendiente: number; FechaVencimiento: string; IdEscuela: number; }) {
        return (FechaVencimiento && PagoPendiente > 0 && new DateV2()._SetTimeZoneByIdSchool(IdEscuela) > new DateV2(FechaVencimiento)._SetTimeZoneByIdSchool(IdEscuela))
    }

    function CreateIcon() {
        return d3.create<HTMLFileIcoElement>("wc-fileico")
            .style("width", "calc(20px + var(--padding1))")
            .style("height", "calc(28px + var(--padding1))")
            .style("font-size", "12px")
            .node()
    }

    export function _IconDocumentacionXMLOpenPreview(container: TSelectionHTML<"div">, file: File) {
        let icon = container.select<HTMLFileIcoElement>("wc-fileico");
        if (!icon.node()) {
            container.node().onclick = e => e.stopPropagation();
            icon = container.style("position", "relative")
                .style("white-space", "normal")
                .append(() => CreateIcon())
                .style("cursor", "pointer")
                .attr("ext", file.name.split(".").pop().toUpperCase())
            container.append<HTMLTooltipComponent>("wc-tooltip")
        }
        container.select("wc-tooltip").text(file.name);
        icon.on("click", () => new FullView()
            ._SetTitle(file.name)
            ._AwaitContent(UIUtilCFDI._CFDIFileToHTML(file)
                .then(container => container.node())
                .catch(() => null)
            )
        )
    }

    export function _TblRowIconDocumentation(container: TSelectionHTML<"div">, idMovimiento: number) {
        let icon = container.select<HTMLFileIcoElement>("wc-fileico");
        if (!icon.node()) {
            container.node().onclick = e => e.stopPropagation();
            icon = container.style("position", "relative")
                .style("white-space", "normal")
                .append(() => CreateIcon())
                .attr("ext-color", "var(--color_action1)")
                .attr("ext", "docs")
            container.append<HTMLTooltipComponent>("wc-tooltip")
                .text(LangString("finanzasegresosmovimientos", "tag_documentacion"));
        }
        icon
            .style("filter", "grayscale(1)")
            .style("pointer-events", "none")
            .style("cursor", "default")
            .on("click", null);

        let spinner = container.select<HTMLSpinnerElement>("wc-spinner");
        if (!spinner.node()) {
            spinner = container.append<HTMLSpinnerElement>("wc-spinner")
                .attr("dim", 20)
                .attr("center", "")
                .attr("border-width", 2)
        }
        DataModuloEgresoMovimiento._ObtenerInfoArchivos(idMovimiento)
            .then((res) => {
                if (res.Resultado > 0 && res.Datos.length) {
                    icon.style("filter", null);
                }
            })
            .finally(() => {
                icon.style("pointer-events", null)
                    .style("cursor", "pointer")
                    .on("click", () => {
                        UIUtilViewFinanzaEgreso._CargarArchivos(idMovimiento)
                            .then(result => {
                                if (result) {
                                    _TblRowIconDocumentation(container, idMovimiento);
                                };
                            })
                    });
                spinner.remove();
            })
    }

    interface IControlArchivoWrapper {
        Container: TSelectionHTML<"div">;
        IdMovimiento: number;
        OnLoad?: (nFiles: number) => void;
        OnError: Function;
        OnChange: (nFiles: number) => void;
    }
    export interface IControlArchivoWrapperResult {
        Nuevos(): File[];
        IdsRealesEliminados(): number[];
    }
    export function _ControlArchivosWrapper({ Container, IdMovimiento, OnError, OnChange, OnLoad }: IControlArchivoWrapper): IControlArchivoWrapperResult {
        const N_UPLOAD_LIMIT = 5;
        Container.classed(UIUtilGeneral.FBoxOrientation.Horizontal, true)
            .style("gap", "var(--padding1)")
            .style("flex-wrap", "wrap");

        // Draw content
        const idsRealesEliminados: number[] = [];
        const mapControls = new Map<number, IControlArchivo>();
        const createFileControl = (id: number) => {
            const control = _ControlArchivo()
                .fn_Tipo(".xml", ".pdf", ".png", ".jpg")
                .fn_OnChange((file) => {
                    if (file && id > 0) {
                        mapControls.delete(id)
                        mapControls.set(-new Date().getTime(), control)
                        idsRealesEliminados.push(id)
                    }
                    if (file && mapControls.size < N_UPLOAD_LIMIT && !hasEmptyControl()) {
                        createFileControl(-new Date().getTime())
                            .fn_ContainerSelection()
                            .raise()
                    }
                    else if (!file) {
                        // if (mapControls.size > N_UPLOAD_LIMIT )
                        deleteControl(id);
                        if (mapControls.size < N_UPLOAD_LIMIT && !hasEmptyControl())
                            createFileControl(-new Date().getTime())
                                .fn_ContainerSelection()
                                .raise()
                    }

                    OnChange(getNFiles());
                })
            control.fn_ContainerNode().style.width = "180px";
            control.fn_ContainerNode().style.height = "180px";
            Container.append(() => control.fn_ContainerNode())
            mapControls.set(id, control);
            return control;
        }
        const deleteControl = (id: number) => {
            const controlContainer = mapControls.get(id);
            if (controlContainer) {
                mapControls.delete(id);
                controlContainer.fn_Destroy();
                if (id > 0) idsRealesEliminados.push(id);
            }
        }

        const getNewFiles = () => Array.from(mapControls)
            .filter(([id, control]) => (Boolean(id < 0 && control.fn_GetFile())))
            .map(([id, control]) => control.fn_GetFile());

        const getNFiles = () => Array.from(mapControls.values()).reduce((n, c) => {
            if (c.fn_GetFile() || c.fn_GetInitialFileData()) n++
            return n;
        }, 0)

        const hasEmptyControl = () => (getNFiles() < mapControls.size)

        let control1 = createFileControl(-1);

        // Get movimiento files
        setTimeout(async () => {
            let progress = Container.append<HTMLProgressElement>("wc-progress");
            progress.node()._Oculto = false;
            let archivosInfo = await DataModuloEgresoMovimiento._ObtenerInfoArchivos(IdMovimiento);
            if (archivosInfo.Resultado < 0) {
                OnError();
            }
            else if (archivosInfo.Datos.length) {
                archivosInfo.Datos.forEach((fileInfo, i) => {
                    createFileControl(fileInfo.IdArchivo).fn_InitialFileData({
                        FileName: fileInfo.Nombre,
                        UrlDownload: DataModuloEgresoMovimiento._URLArchivo(fileInfo.IdArchivo),
                    })
                })
                if (archivosInfo.Datos.length >= N_UPLOAD_LIMIT)
                    deleteControl(-1)
                else
                    control1.fn_ContainerSelection().raise();
            }
            if (OnLoad) OnLoad(archivosInfo.Datos?.length || 0);
            progress.remove();
        });
        return {
            Nuevos: () => getNewFiles(),
            IdsRealesEliminados: () => idsRealesEliminados,
        }
    }

    type TTipoArchivo = ".xml" | ".pdf" | ".png" | ".jpg";
    type TInitialFileData = {
        UrlDownload: string;
        FileName: string;
    }
    interface IControlArchivo {
        fn_Tipo(...tipo: TTipoArchivo[]): IControlArchivo
        fn_InitialFileData(initialFileInfo: TInitialFileData): IControlArchivo
        fn_GetFile(): File
        fn_GetInitialFileData(): TInitialFileData;
        fn_ContainerNode(): HTMLDivElement
        fn_ContainerSelection(): TSelectionHTML<"div">
        fn_OnChange(call: (file: File) => void): IControlArchivo
        fn_Destroy(): void
    }
    export function _ControlArchivo(): IControlArchivo {
        let _inputFile: HTMLInputElement;
        let _wcFileLoader: FileLoader;
        let _opcionesContainer: TSelectionHTML<"div">;
        const controlState = UIUtilGeneral._ObjectAddGetters({
            tipo: <TTipoArchivo[]>null,
            initialFileData: <TInitialFileData>null,
            fileLoaded: <File>null,
            onChange: <(file: File) => void>null,
            hasFile: <boolean>null,
        }, {
            hasFile: (dato) => !!(dato.initialFileData || dato.fileLoaded),
        });

        const RefreshEnableBtns = () => {
            _opcionesContainer.classed("hide", !controlState.hasFile);
            _wcFileLoader._ControlContainerSel
                .style("border-bottom", controlState.hasFile ? "none" : null)
                .style("border-radius", controlState.hasFile ? "var(--border_radius_base) var(--border_radius_base) 0 0" : null)
                .style("height", controlState.hasFile ? "calc(100% - 35px)" : "100%")
        }

        const ShowPreview = () => {
            // const { tipo } = controlState;
            const controlView = new FullView()
                ._AwaitContent(
                    GetRealFile()
                        .then((file) => {
                            controlView._SetTitle(file.name);
                            const fileExt = "." + file.name.split(".").pop().toLowerCase() as TTipoArchivo;
                            // const valid = !!tipo.find(t => t.includes(fileExt));
                            if (fileExt == ".xml") {
                                return UIUtilCFDI._CFDIFileToHTML(file)
                                    .then(container => container.node())
                                    .catch(() => null)
                            }
                            else if (fileExt == ".pdf") {
                                return PDFThings._PDFViewer(file);
                            }
                            else if (fileExt == ".png" || fileExt == ".jpg") {
                                const urlImg = URL.createObjectURL(file);
                                const img = document.createElement("img")
                                img.src = urlImg;
                                img.onload = e => {
                                    URL.revokeObjectURL(urlImg);
                                    img.onload = null;
                                }
                                return img
                            }
                            return null;
                        })
                        .catch(() => {
                            MostrarNotificacion(LangString("general", "notif_fail"), "ADVERTENCIA");
                            return null;
                        })
                )
        }

        const GetRealFile = () => {
            return new Promise<File>((resolve, reject) => {
                if (controlState.fileLoaded?.size) {
                    resolve(controlState.fileLoaded);
                }
                else if (controlState.initialFileData) {
                    const { UrlDownload, FileName } = controlState.initialFileData;
                    DataDRequest._RequestBlobFromUrlResource(UrlDownload, (fileBlob, error) => {
                        if (error) {
                            reject();
                            return;
                        }
                        resolve(new File([fileBlob], FileName));
                    })
                }
                else {
                    reject();
                }
            })
        }

        const DownloadFile = () => {
            Button._EnableButton(_wcFileLoader._ControlContainerSel, false);
            Button._EnableButton(_opcionesContainer, false);
            const spinner = _controlContainer.append<HTMLSpinnerElement>("wc-spinner").attr("center", "");
            GetRealFile()
                .then(file => saveAs(file, file.name))
                .catch(() => MostrarNotificacion(LangString("general", "notif_fail"), "ADVERTENCIA"))
                .finally(() => {
                    spinner.remove()
                    Button._EnableButton(_wcFileLoader._ControlContainerSel, true);
                    Button._EnableButton(_opcionesContainer, true);
                });
        }
        const DeleteFile = () => {
            _wcFileLoader._Files = [];
            OnChange();
        }

        const OnChange = () => {
            controlState.initialFileData = null;
            controlState.fileLoaded = _wcFileLoader._File;
            if (controlState.onChange)
                controlState.onChange(controlState.fileLoaded);
            RefreshEnableBtns();
        }

        // Init draw
        const _controlContainer = d3.create("div")
            .classed(UIUtilGeneral.FBoxOrientation.Vertical, true)
            .style("position", "relative")
            .call(fileContainer => {
                fileContainer.classed(UIUtilGeneral.FBoxOrientation.Horizontal, true)
                    .style("flex-wrap", "wrap")
                    .style("height", "130px")
                _wcFileLoader = new FileLoader(fileContainer)
                _wcFileLoader._ControlContainerSel
                    .style("width", "100%")
                    .style("height", "100%")
                _inputFile = _wcFileLoader._InputElement;
                _inputFile.onchange = () => OnChange();

                _opcionesContainer = fileContainer.append("div")
                    .attr("class", UIUtilGeneral.FBoxOrientation.Horizontal + " " + UIUtilGeneral.FBoxAlign.StartCenter)
                    .style("width", "100%")
                    .style("padding", "var(--padding1)")
                    .style("border-style", "solid")
                    .style("border-color", "var(--color_borderbox1)")
                    .style("border-width", "0 1px 1px")
                    .style("border-radius", "0 0 var(--border_radius_base) var(--border_radius_base)")
                    .style("gap", "var(--padding1)");

                _opcionesContainer.append<HTMLTooltipComponent>("wc-tooltip").node()
                    ._SetObservedElementsAdvanced(
                        {
                            Target: _opcionesContainer.append("img")
                                .attr("class", "btn_round")
                                .style("cursor", "pointer")
                                .attr("src", UIUtilIconResources.CGeneral.EyeV2)
                                .attr("draggable", false)
                                .on("click", () => ShowPreview()).node(),
                            Text: UIUtilLang._GetUIString("c_actions", "ver")
                        },
                        {
                            Target: _opcionesContainer.append("img")
                                .attr("class", "btn_round")
                                .style("cursor", "pointer")
                                .attr("src", UIUtilIconResources.CGeneral.Download)
                                .attr("draggable", false)
                                .on("click", () => DownloadFile()).node(),
                            Text: UIUtilLang._GetUIString("general", "descargar")
                        },
                        {
                            Target: _opcionesContainer.append("img")
                                .attr("class", "btn_round")
                                .style("cursor", "pointer")
                                .attr("src", UIUtilIconResources.CGeneral.Eliminar)
                                .attr("draggable", false)
                                .on("click", () => DeleteFile()).node(),
                            Text: UIUtilLang._GetUIString("c_actions", "eliminar")
                        },
                    )

                RefreshEnableBtns();
            })

        return (() => {
            const resultControl: IControlArchivo = {
                fn_Tipo(...tipo: TTipoArchivo[]) {
                    controlState.tipo = tipo;
                    _inputFile.accept = (tipo || "") + "";
                    return resultControl;
                },
                fn_InitialFileData(initialFileInfo: TInitialFileData) {
                    if (controlState.fileLoaded)
                        controlState.fileLoaded = null;
                    controlState.initialFileData = initialFileInfo;
                    _wcFileLoader._Files = [
                        new File([], initialFileInfo.FileName),
                    ]
                    RefreshEnableBtns();
                    return resultControl;
                },
                fn_GetFile() {
                    return controlState.fileLoaded;
                },
                fn_GetInitialFileData() {
                    return controlState.initialFileData;
                },
                fn_ContainerSelection() {
                    return _controlContainer;
                },
                fn_ContainerNode() {
                    return _controlContainer.node();
                },
                fn_OnChange(call) {
                    controlState.onChange = call;
                    return resultControl;
                },
                fn_Destroy() {
                    _controlContainer.node().remove()
                }
            }
            return resultControl;
        })()
    }
}
