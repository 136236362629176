export interface ILicencia {
    /** @example "2024-05-15T16:43:52.095Z" */
    Asignacion: string
    Dias: number
    EnUso: boolean
    Estado: CLicenciaEstado
    Id: number
    IdAlumno: number
    IdEscuela: number
    Modificacion: string
}

export interface ILicenciaEstado {
    CostoTotal: number;
    Dias: number;
    Estado: CLicenciaEstado;
    /** "2022-04-11T09:50:57.698-05:00" */
    FechaAsignacion: string;
    // /** "2022-04-11T09:50:57.698-05:00" */
    // FechaInicio: string;
    /** "2022-04-11T09:50:57.698-05:00" */
    FechaVencimiento: string;
    IdAlumno: number;
    IdEscuela: number;
    IdLicencia: number;

    /** Extra */
    KinderFiltro?: number[];
}

export interface ILicenciaPeriodo {
    Periodo: string
    IdEscuela: number
    Licencias: {
        Estado: CLicenciaEstado
        IdAlumno: number
        IdLicencia: number
    }[]
}

export interface ILicenciaPeriodoDetalle {
    Asignacion: string
    CostoTotal: number
    Dias: number
    Estado: CLicenciaEstado
    Id: number
    IdAlumno: number
    IdEscuela: number
    Vencimiento: string
}

export interface ILicenciaPagoPendiente {
    Id: number;
    Folio: string;
    EnUso: boolean;
    IdEscuela: number;
    IdsAlumno: number[];
    IdsLicencia: number[];
    Montos: number[];
    IdTransaccion: string;
    EstadoPago: CLicenciaPagoEnLineaEstado;
    FormaPago: CLicenciaFormaPago;
    Detalles: string[];
    Modificacion: string;
    FechaVigencia: string;
    FechaCreacion: string;
}

export enum CLicenciaEstado {
    Activo = 1,
    // Finalizado = 2,
    Vencido = 3,
    Caducado = 4,
    Pagado = 5,
    Pausado = 6,
}

export enum CLicenciaPagoEnLineaEstado {
    Pendiente = 1,
    Pagado = 2
}

export enum CAlumnoEdoLicencia {
    SinLicencia = 0,
    Activo = CLicenciaEstado.Activo,
    Vencido = CLicenciaEstado.Vencido,
    Caducado = CLicenciaEstado.Caducado,
    Pausado = CLicenciaEstado.Pausado,
}

export enum CLicenciaFormaPago {
    Tarjeta = 1,
    /** Transferencia bancaria */
    Transferencia = 2,
    Efectivo = 3
}