import { UIUtilMimeType } from "../util/MimeTypes";
import { UIUtilGeneral } from "../util/Util";

// export namespace InputFileControl { ES PARTE DE OTRO CONTROL
type TFail = "error-onreading" | "invalid-beforeread" | "invalid-afterread";
export class FileLoaded {
    private file: File;
    private fileAsBase64Url: string;
    private thumbnail: File;
    private isValidFile: boolean;

    // private allowedExtensions: string[];
    private onReadStart: () => void;
    private onReadEndSuccessfull: () => void;
    private onReadEndFail: (type: TFail, file: File, extension: string) => void;
    private onValidateFileAfterReading: (file: File, base64: string) => Promise<boolean>;

    constructor() {
        this.isValidFile = false;
    }

    private ReadFile(file: File) {
        setTimeout(async () => {
            let typeFail: TFail;

            if (this.onReadStart) {
                this.onReadStart();
            }

            if (!this.GetAllowedFile(file)) {
                typeFail = "invalid-beforeread";
            }
            else {
                // >> Reading image
                if (this.GetIsImage(file)) {
                    await this.GetImageReaded(file)
                        .then(async res => {
                            if (this.onValidateFileAfterReading && !(await this.onValidateFileAfterReading(file, res.Base64))) {
                                typeFail = "invalid-afterread";
                            }
                            else {
                                this.fileAsBase64Url = res.Base64;
                                this.thumbnail = res.Thumbnail;
                            }
                        })
                        .catch((e) => {
                            console.warn("-d", "Error on reading image", e);
                            typeFail = "error-onreading";
                        });
                }
                // >> Reading video
                else if (this.GetIsVideo(file)) {
                    await this.GetVideoReaded(file)
                        .then(async res => {
                            if (this.onValidateFileAfterReading && !(await this.onValidateFileAfterReading(file, null))) {
                                typeFail = "invalid-afterread";
                            }
                            else {
                                this.thumbnail = res.Thumbnail;
                            }
                        })
                        .catch((e) => {
                            console.warn("-d", "Error on reading video", e);
                            typeFail = "error-onreading";
                        });
                }
                // >> Any file
                else {
                    if (this.onValidateFileAfterReading && !(await this.onValidateFileAfterReading(file, null))) {
                        typeFail = "invalid-afterread";
                    } else {
                        this.thumbnail = null;
                        this.fileAsBase64Url = null;
                    }
                }
            }

            if (typeFail == null) {
                this.file = file;
                this.isValidFile = true;

                if (this.onReadEndSuccessfull) {
                    this.onReadEndSuccessfull();
                }
            }
            else {
                console.warn("-d", typeFail, file);
                if (this.onReadEndFail) {
                    this.onReadEndFail(typeFail, file, this.GetExtension(file));
                }
            }
        });
    }

    private GetImageReaded(file: File) {
        return new Promise<{ Base64: string; Thumbnail: File; }>((resolve, reject) => {
            let image = new Image();
            let thumbnailURL = URL.createObjectURL(file);

            image.addEventListener("load", () => {
                UIUtilGeneral._FileToBase64(file)
                    .then(base64 => {
                        URL.revokeObjectURL(thumbnailURL);
                        resolve({
                            Base64: base64,
                            Thumbnail: new File([file], file.name, {
                                type: file.type,
                            }) // DOTEST Conservar?
                        });
                    })
                    .catch((e) => {
                        reject(e);
                    });
            });

            image.addEventListener("error", (e) => {
                reject(e);
            });

            image.src = thumbnailURL;
        });
    }

    private GetVideoReaded(file: File) {
        return new Promise<{ Thumbnail: File; }>((resolve, reject) => {
            let videoElement = document.createElement("video");
            let canvasElement = document.createElement("canvas");
            let videoURL = URL.createObjectURL(file);

            document.body.append(videoElement);
            videoElement.style.position = "absolute";
            videoElement.style.top = "0";
            videoElement.style.opacity = "0";
            videoElement.width = 500;

            videoElement.onloadeddata = async () => {
                URL.revokeObjectURL(videoURL);
                setTimeout(() => {
                    let context = canvasElement.getContext('2d');

                    canvasElement.width = videoElement.videoWidth;
                    canvasElement.height = videoElement.videoHeight;

                    context.drawImage(videoElement, 0, 0, canvasElement.width, canvasElement.height);

                    canvasElement.toBlob((blob) => {
                        let videoImgThumbnail = new File([blob], file.name, {
                            type: file.type
                        });

                        resolve({
                            Thumbnail: videoImgThumbnail
                        });
                    })
                    videoElement.remove();

                }, 1000);
            }
            videoElement.onerror = (e) => {
                URL.revokeObjectURL(videoURL);
                reject(e);
            }

            videoElement.src = videoURL;
        });
    }

    private GetIsImage(file: File = this.file) {
        if (file) {
            return (file.type.split("/")[0] == "image");
        }
        return false;
    }

    private GetIsVideo(file: File = this.file) {
        if (file) {
            return (file.type.split("/")[0] == "video");
        }
        return false;
    }

    private GetAllowedFile(file: (File | null)) {
        if (!(file?.size > 0)) {
            return false;
        }
        // if (this.allowedExtensions?.length) {
        //     let ext = file.name
        //         .split(".")
        //         .pop()
        //         .toLowerCase();

        //     return (this.allowedExtensions.indexOf(ext) > -1);
        // }
        return true;
    }

    private GetExtension(file: File) {
        if (file) {
            let extensionesList = (UIUtilMimeType._GetExtensionsFromMimeType(file.type) || []);
            let extFileName = file.name.split(".")
                .pop()
                .toLocaleLowerCase();

            if (extensionesList.indexOf(extFileName) > -1) {
                return extFileName;
            }
            else {
                extensionesList[0];
            }
        }
        return null;
    }

    private Reset() {
        this.isValidFile = false;
        this.file = null;
        this.fileAsBase64Url = null;
        this.thumbnail = null;
    }

    // ***********************
    // PUBLIC METHODS
    // ***********************

    public _OnReadStart(callback: () => void) {
        this.onReadStart = callback;
        return this;
    }

    public _OnReadEndSuccessfull(callback: () => void) {
        this.onReadEndSuccessfull = callback;
        return this;
    }

    public _OnReadEndFail(callback: (type: TFail, file: File, extension: string) => void) {
        this.onReadEndFail = callback;
        return this;
    }

    public _OnValidateFileAfterReading(callback: (file: File, base64: string) => Promise<boolean>) {
        this.onValidateFileAfterReading = callback;
        return this;
    }

    // public met_AllowedExtensions(string: string[]) {
    //     this.allowedExtensions = string
    //         .map(
    //             ext => ext
    //                 .replace(".", "")
    //                 .toLowerCase()
    //         );

    //     return this;
    // }

    public _SetFile(file: (File | null)) {
        this.ReadFile(file);
        return this;
    }

    public _SetThumbnail(file: File) {
        this.thumbnail = file;
        return this;
    }

    public _Reset() {
        this.Reset();
        return this;
    }

    /** Ej.: "mp4" | "jpeg" */
    public get _Extension(): string {
        return this.GetExtension(this.file);
    }

    public get _File(): File {
        return this.file;
    }

    public get _FileAsBase64(): string {
        // if (this.fileAsBase64Url) {
        return this.fileAsBase64Url; //.split(",")[1];
        // }
        // return null;
    }

    public get _Thumbnail(): File {
        return this.thumbnail;
    }

    public get _IsImage(): boolean {
        return this.GetIsImage();
    }

    public get _IsVideo(): boolean {
        return this.GetIsVideo();
    }

    public get _IsValid(): boolean {
        return this.isValidFile;
    }
}
