import { UIUtilIconResources } from "../util/IconResourses";
import { HTMLIconToggleElement } from "./IconToggleComponent";

export class HTMLIconCollapseElement extends HTMLIconToggleElement {
    constructor() {
        super();
        // this.attachShadow({ mode: "open" });

        setTimeout(() => {
            this.setAttribute("status-false", UIUtilIconResources.CGeneral.AngleUp);
            this.setAttribute("status-true", UIUtilIconResources.CGeneral.AngleDown);
            if (!this.hasAttribute("collapsed")) {
                this.setAttribute("collapsed", "true");
            }
        });
    }

    // *****************************************************************************
    // PRIVATE METHODS
    // *****************************************************************************

    private IsCollapsed() {
        return this.getAttribute("collapsed") == "true";
    }

    // *****************************************************************************
    // ELEMENT LIFE CICLE CALLBACKS
    // *****************************************************************************

    /** Invocado cuando el componente personalizado se conecta por primera vez al DOM del documento. */
    connectedCallback() {
        super.connectedCallback();
    }

    /** Invocado cuando el componente personalizado se deconecta del DOM del documento. */
    disconnectedCallback() {
        super.disconnectedCallback();
    }

    /** Invocado cuando el componente personalizado se mueve a un nuevo documento. */
    adoptedCallback(oldDocument: Document, newDocument: Document) {
        super.adoptedCallback(oldDocument, newDocument);
    }

    /** Invocado cuando uno de los atributos del componente personalizado es añadido, removido o modificado. */
    attributeChangedCallback(attrName: string, oldValue: string, newValue: string) {
        switch (attrName) {
            case "collapsed":
                setTimeout(() => {
                    this.setAttribute("status", this.IsCollapsed() + "");
                });
                break;
            default:
                super.attributeChangedCallback(attrName, oldValue, newValue);
                break;
        }
    }

    static get observedAttributes() {
        return ['collapsed', ...super.observedAttributes];
    }

    // *****************************************************************************
    // PUBLIC PROPERTIES
    // *****************************************************************************

    public get _Collapsed() {
        return this.IsCollapsed();
    }

    public set _Collapsed(value: boolean) {
        this.setAttribute("collapsed", Boolean(value)?.toString());
    }
}

customElements.define("wc-ic-collapse", HTMLIconCollapseElement);
