import { ArrayV2 } from "../../util/ArrayV2";
import { DataDRequest } from "../DRequest";
import { Entidad } from "../Entidad";
import { Global } from "../Global";
import { DataUtil } from "../util/Util";

type IEscuela = Entidad.IEscuela;
type ILogroAsignacion = Entidad.ILogroAsignacion;

export const _DiccLogroAsignaciones: Map<number, Entidad.ILogroAsignacion> = new Map();

/** Map<IdAlumno, Map<IdAsignacion, Asignacion>> */
export const _DiccAlumnosAsignaciones: Map<number, Map<number, Entidad.ILogroAsignacion>> = new Map();

const REQUESTID = Entidad.CTipoRequest.LogroAsignacion;

export function _GetListLogroAsignacion(escuelas: IEscuela[]): Promise<DataDRequest.IRequestResponseListA<ILogroAsignacion>[]> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "logro/ObtenerListaAsignacion";
    return new ArrayV2<IEscuela>()
        ._Push(...escuelas)
        ._MapAwait(async ({ IdKinder }) => {
            const currentDateStr = await DataUtil._GetMaxDate(REQUESTID, IdKinder);
            const params = {
                "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
                "IdEscuela": IdKinder,
                "Modificacion": currentDateStr,
            }
            return DataDRequest._Request4List(url, "GET", params, REQUESTID, IdKinder);
        })
}

/** { [IdAlumno: number: IdsLogros[]] } */
type TAltaAsignacionDataResponse = { [idAlumno: number]: number[] };

export async function _AltaAsignacion(idsAlumnos: number[], idsLogros: number[], idEscuela: number): Promise<DataDRequest.IRequestResponseA<TAltaAsignacionDataResponse>> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "logro/NuevaAsignacion";
    const params = {
        "IdsAlumnos": idsAlumnos,
        "Logros": idsLogros,
        "IdEscuela": idEscuela,
        "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
        "IdUsuario": DataUtil._Usuario.IdUsuario
    }
    return DataDRequest._Request5(url, "POST", params, REQUESTID);
}

// export async function fn_EliminaCategoria(params: Array<Object>, updateDetails: Function) {
export async function _EliminaAsignacion(idAsignacion: number): Promise<DataDRequest.IResultadoPeticion<undefined>> {
    const url = Global._GLOBAL_CONF.URL_RSTV1 + "Logro/EliminarAsignacion";
    const params = {
        "IdAsignacion": idAsignacion,
        "IdUsuario": DataUtil._Usuario.IdUsuario
    }
    return new Promise(async function (resolve, reject) {
        DataDRequest._Request(url, "POST", params, (result: number) => {
            resolve({
                Resultado: result || -100,
                TipoRequest: Entidad.CTipoRequest.LogroAsignacion
            });
        });
    });
}

export default {
    _DiccLogroAsignaciones,
    _DiccAlumnosAsignaciones,
    _AltaAsignacion,
    _EliminaAsignacion
}
