import * as d3 from "d3";
import saveAs from "file-saver";
import { HTMLSpinnerElement } from "../controlWC/SpinnerComponent";
import { UIUtilIconResources } from "../util/IconResourses";
import { Button } from "./Button";
import { HTMLFileIcoElement } from "../controlWC/FileIcoComponent";
import { DataDRequest } from "../../data/DRequest";
import { UIUtilMimeType } from "../util/MimeTypes";
import { NotificacionV2 } from "./NotificacionV2";
import _L from "../../util/Labels";
import { PDFDocumentProxy, getDocument as pdfjs_getDocument, GlobalWorkerOptions as pdfjs_GlobalWorkerOptions } from 'pdfjs-dist'
import { FullViewFile } from "./FullViewFile";

export namespace FilePreviewControl {
    import CDefaultSrc = UIUtilIconResources.CGeneral;

    export interface IConfig {
        /** @returns url-resource | file */
        OnDownload_GetRealResource?: () => string | File | Promise<File>;
        /** @default () => "kidiadmin-image" */
        OnGetFileName?: () => string;
        OptionsExtras?: IOption[];
    }

    interface IOption {
        Icon?: string;
        OnClick: () => Promise<any>;
        Visible?: boolean;
    }

    interface IFileControl {
        File: File;
        PdfDoc: PDFDocumentProxy;
    }

    export class FileControlDocuments {
        #config: IConfig;
        #ctrlContainer: d3.Selection<HTMLDivElement, any, (HTMLElement | null), any>;
        #ctrlContent: d3.Selection<HTMLDivElement, any, (HTMLElement | null), any>;
        #prefix_test: string;
        #ctrlSpinner: TSelectionHTML<"wc-spinner">;
        #titleItem: string;
        #urlResource: string;
        #timeoutUpdate: NodeJS.Timeout;
        #ctrlDocCanvas: d3.Selection<HTMLCanvasElement, any, (HTMLElement | null), any>;
        #ctrlErrorContainer: d3.Selection<HTMLDivElement, any, HTMLElement, any>;
        #btnReloadFile: d3.Selection<HTMLDivElement, any, HTMLElement, any>;
        #fileController: IFileControl;
        #ctrlDocumentIcon: TSelectionHTML<"wc-fileico">;

        constructor(config?: IConfig) {
            this.#config = {
                ...<IConfig>{
                    OnGetFileName: () => "kidiadmin-file"
                },
                ...config
            }
            this.#fileController = { File: null, PdfDoc: null }
            this.UI_BuildControl();
        }

        private UI_BuildControl() {
            // Contenedor general
            this.#ctrlContainer = d3.create("div")
                .classed("document_control_container", true);
            // Contenedor de preview
            this.#ctrlContent = this.#ctrlContainer.append("div")
                .classed("document-control", true);

            // Spinner progress
            this.#ctrlSpinner = this.#ctrlContainer.append<HTMLSpinnerElement>("wc-spinner")
                .attr("center", "");

            // FileIcon // NOTE: Por el momento solo será de tipo PDF
            this.#ctrlDocumentIcon = this.#ctrlContainer.append<HTMLFileIcoElement>("wc-fileico")
                .attr("ext", "PDF");

            // Canvas donde se verá la previsualización del archivo
            this.#ctrlDocCanvas = this.#ctrlContent.append<HTMLCanvasElement>("canvas")
                .attr("id", "the-canvas")
                .classed("hide", true);

            // Error Element
            this.#ctrlErrorContainer = this.#ctrlContent.append<HTMLDivElement>("div")
                .classed("item_error", true)
                .classed("hide", true);
            // BtnReloadFile
            this.#btnReloadFile = this.#ctrlErrorContainer.append("div")
                .classed("btn_sync_cont", true);
            this.#btnReloadFile.append("img").attr("src", CDefaultSrc.Sync);
            this.#ctrlErrorContainer.append("label").text(_L("ayuda.notif_err_getfile"));
            this.UI_UpdateOptionsArea();
            this.UI_UpdateView();
        }

        private UI_UpdateOptionsArea() {
            if (this.#timeoutUpdate) {
                clearTimeout(this.#timeoutUpdate);
            }
            this.#timeoutUpdate = setTimeout(() => {
                this.#timeoutUpdate = null;
                let options: IOption[] = [
                    {
                        Icon: CDefaultSrc.Download,
                        Visible: true,
                        OnClick: () => this.DATA_DownloadCurrentFile()
                    },
                    {
                        Icon: CDefaultSrc.EyeV2,
                        Visible: true,
                        OnClick: () => this.DATA_FullViewDocument()
                    }
                ];
                const showOptnsArea = Boolean(options.find(d => d.Visible));
                let areaOptions = this.#ctrlContent.select<HTMLDivElement>(".area_options");

                if (showOptnsArea) {
                    if (!areaOptions.node()) {
                        areaOptions = this.#ctrlContainer.append("div")
                            .classed("area_options", true)
                    }
                    areaOptions
                        .selectAll<HTMLDivElement, IOption>(".option")
                        .data(options)
                        .join(
                            enter => {
                                let itemEnter = enter
                                    .append("div")
                                    .classed("option", true)
                                itemEnter.append("img")
                                    .attr("draggable", false)
                                    .classed("hide", d => !d.Icon)
                                    .attr("src", d => d.Icon);
                                itemEnter
                                    .classed("hide", d => !d.Visible)
                                    .on("click", async (d, i, arr) => {
                                        Button._EnableButton(d3.select(arr[i]), false);
                                        await d.OnClick();
                                        Button._EnableButton(d3.select(arr[i]), true);
                                    });
                                return itemEnter;
                            },
                            update => update
                                .each((d: IOption, i, arr) => {
                                    let item = d3.select<HTMLDivElement, IOption>(arr[i]);
                                    item.select("img")
                                        .classed("hide", d => !d.Icon)
                                        .attr("src", d.Icon);
                                })
                                .classed("hide", d => !d.Visible)
                                .on("click", async (d, i, arr) => {
                                    Button._EnableButton(d3.select(arr[i]), false);
                                    await d.OnClick();
                                    Button._EnableButton(d3.select(arr[i]), true);
                                }),
                            exit => exit.remove()
                        )
                } else if (areaOptions.node()) {
                    areaOptions.remove();
                }
            }, 100)
        }

        private async UI_UpdateView() {
            var scale = 1,
                canvas = this.#ctrlDocCanvas.node() as HTMLCanvasElement,
                ctx = canvas.getContext('2d');
            if (!this.#fileController.PdfDoc) {
                this.#ctrlSpinner.classed("hide", true);
                this.#ctrlDocCanvas.classed("hide", true);
                this.#ctrlErrorContainer.classed("hide", false)
                this.#btnReloadFile.node().onclick = () => {
                    this.DATA_LoadAndReadResourceFileFromUri(this.#urlResource);
                }
            } else {
                this.#ctrlErrorContainer.classed("hide", true);
                this.#ctrlDocCanvas.classed("hide", false);

                this.#fileController.PdfDoc.getPage(1).then((page) => {
                    var viewport = page.getViewport({ scale: scale });
                    canvas.height = viewport.height;
                    canvas.width = viewport.width;

                    var renderContext = {
                        canvasContext: ctx,
                        viewport: viewport
                    };
                    var renderTask = page.render(renderContext);
                    renderTask.promise.then(() => {
                        this.#ctrlSpinner.classed("hide", true);
                    });
                });
            }
        }

        private Reset() {
            this.#fileController.File = null;
            this.#fileController.PdfDoc = null;
            this.UI_UpdateView();
        }

        private ReadFile(newFile: (File | null)) {
            if (!newFile) {
                this.Reset()
                return;
            }

            let uri: string;
            uri = URL.createObjectURL(newFile);

            if (!pdfjs_GlobalWorkerOptions.workerSrc) {
                const WORKER_URL = "/lib/pdf.worker.min.mjs"
                pdfjs_GlobalWorkerOptions.workerSrc = WORKER_URL;
            }

            pdfjs_getDocument(uri).promise.then((pdfDoc_) => {
                this.#fileController.File = newFile;
                this.#fileController.PdfDoc = pdfDoc_;
                this.UI_UpdateView();
            }).catch(() => {
                console.warn("-d", "Error al obtener archivo")
                this.UI_UpdateView();
            })
        }

        private DATA_LoadAndReadResourceFileFromUri(value: string) {
            this.#ctrlErrorContainer.classed("hide", true);
            this.#ctrlDocCanvas.classed("hide", true);
            this.#ctrlSpinner.classed("hide", false);

            DataDRequest._RequestBlobFromUrlResourceV2(value, (blob, error) => {
                let newFile: File;
                if (error == null) {
                    if (blob) {
                        newFile = new File([blob], this.DATA_GetFullFileName(blob), { "type": blob.type });
                    } else {
                        console.warn("-d", this.#prefix_test, "Resource fail load 2, LoadAndReadResourceFileFromUri");
                    }
                } else {
                    console.warn("-d", this.#prefix_test, "Resource fail load 1, LoadAndReadResourceFileFromUri");
                }
                this.ReadFile(newFile);
            })
        }

        private DATA_GetFullFileName(blob: Blob): string {
            if (blob) {
                let extension = "." + UIUtilMimeType._GetExtensionFromMimeType(blob.type);
                let filename = this.#config.OnGetFileName();

                if (filename.toLowerCase().endsWith(extension)) {
                    return filename;
                } else {
                    return (filename + extension);
                }
            }
            return this.#config.OnGetFileName();
        }

        private async DATA_DownloadCurrentFile(save = true) {
            let fileBlob: File;

            this.#ctrlSpinner.classed("hide", false);

            if (this.#config.OnDownload_GetRealResource) {
                let resFile = this.#config.OnDownload_GetRealResource();
                if (typeof resFile == "string") {
                    await new Promise<void>(resolve => {
                        DataDRequest._RequestBlobFromUrlResourceV2(resFile as string, (blob, error) => {
                            if (error == null && blob) {
                                fileBlob = new File([blob], this.DATA_GetFullFileName(blob), { "type": blob.type });
                            }
                            resolve();
                        })
                    })
                } else if (resFile instanceof Promise) {
                    await resFile
                        .then(res => {
                            fileBlob = res;
                        })
                        .catch(e => {
                            console.warn("Error to get file");
                        })
                } else if (resFile instanceof File) {
                    fileBlob = resFile;
                }
            }

            if (save) {
                if (fileBlob) {
                    saveAs(fileBlob, fileBlob.name);
                } else {
                    let error = new Error();
                    error.name = this.#prefix_test;
                    error.message = "filenofound";

                    console.warn("-d", error);
                    NotificacionV2._Mostrar(_L("general.notif_fail"), "ADVERTENCIA");
                }
            }

            this.#ctrlSpinner.classed("hide", true);
            return fileBlob;
        }

        private async DATA_FullViewDocument() {
            new FullViewFile()._SetContent([
                {
                    Id: 1,
                    Filename: () => this.#titleItem,
                    Download: () => this.#urlResource,
                    Content: () => this.#urlResource,
                }
            ])
        }

        // **********************************************************************************
        // PUBLIC PROPERTIES
        // **********************************************************************************
        get _ControlSelection() {
            return this.#ctrlContainer;
        }

        get _ControlNode(): (HTMLDivElement | null) {
            return this.#ctrlContainer.node();
        }

        set _UrlResource(value: string) {
            this.#urlResource = value;

            if (Boolean(value)) {
                this.DATA_LoadAndReadResourceFileFromUri(value);
            } else {
                this.Reset();
            }
        }

        get _UrlResource(): string {
            return this.#urlResource;
        }

        set _ItemTitle(value: string) {
            this.#titleItem = value;
        }

        get _SpinnerControl() {
            return this.#ctrlSpinner;
        }

        public _OnDownload_GetRealresource(fn: () => string | Promise<File>) {
            this.#config.OnDownload_GetRealResource = fn;
            return this;
        }

        public _OnGetFileName(fn: () => string) {
            this.#config.OnGetFileName = fn;
            return this;
        }
    }
}

