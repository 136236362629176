import { DataDRequest } from "../../data/DRequest";
import { HTMLSpinnerElement } from "./SpinnerComponent";

type TObjectFit = "contain" | "cover" | "fill" | "none" | "scale-down";
const TEMPLATE = document.createElement("template");
TEMPLATE.innerHTML = `
<style>
    :host {
        position: relative;
        overflow: hidden;
        display: inline-block;
        box-sizing: border-box;
        width: 25px;
        height: 25px;
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        box-sizing: border-box;
    }

    wc-spinner {
        position: absolute;
        top: 0;
        left: 0;
        box-sizing: border-box;
    }
</style>
<!-- <slot></slot> -->
`;

/** Attributes
 * * "src"
 * * "default-src"
 * * "object-fit": "contain" | "cover" | "fill" | "none" | "scale-down"
 * * "spinner-dim"
 * * "spinner-border-width"
 * * "spinner-border-color"
 */
export class HTMLImage2Component extends HTMLElement {
    // private loading: boolean;
    private currentSrc: string;
    constructor() {
        super();
        this.attachShadow({ mode: "open" })
        this.shadowRoot.appendChild(TEMPLATE.content.cloneNode(true));
    }

    private CreateIMG() {
        let img = this.shadowRoot.querySelector("img");
        if (!img) {
            img = document.createElement("img");
            img.draggable = false;
            let spinnerExist = this.shadowRoot.querySelector("wc-spinner");
            if (spinnerExist) {
                this.shadowRoot.insertBefore(img, spinnerExist);
            }
            else this.shadowRoot.append(img);
        }
        img.style.objectFit = this.getAttribute("object-fit");
        return img;
    }

    private ShowSpinner() {
        if (this["__removeSpinnerTimeout"]) {
            clearTimeout(this["__removeSpinnerTimeout"]);
            this["__removeSpinnerTimeout"] = null;
        }
        let spinner = this.shadowRoot.querySelector<HTMLSpinnerElement>("wc-spinner");
        if (!spinner) {
            spinner = document.createElement("wc-spinner") as HTMLSpinnerElement;
            this.shadowRoot.append(spinner);
        }
        spinner.style.color = this.getAttribute("spinner-border-color");
        spinner._BorderWidth = this.getAttribute("spinner-border-width");
        spinner._Center = true;
        spinner._Dim = (() => {
            let spinnerDim: number | string = this.getAttribute("spinner-dim");
            if (!spinnerDim) {
                const w = this.clientWidth;
                const h = this.clientHeight;
                spinnerDim = (w < h ? w : h);
            }
            return spinnerDim;
        })();
    }

    private RemoveSpinner() {
        let spinner = this.shadowRoot.querySelector<HTMLSpinnerElement>("wc-spinner");
        if (!spinner) return;
        if (this["__removeSpinnerTimeout"]) {
            clearTimeout(this["__removeSpinnerTimeout"]);
        }
        this["__removeSpinnerTimeout"] = setTimeout(() => {
            this["__removeSpinnerTimeout"] = null;
            spinner.remove();
        }, 100);
    }

    private LoadImage() {
        this.ShowSpinner();
        if (this["__loadImageTimeout"]) {
            clearTimeout(this["__loadImageTimeout"]);
        }
        this["__loadImageTimeout"] = setTimeout(async () => {
            this["__loadImageTimeout"] = null;
            let src = this.getAttribute("src");
            if (src?.trim()) {
                if (this.currentSrc == src) {
                    this.RemoveSpinner();
                    return;
                }
                // this.loading = true;
                let primaryFile = await this.RequestImage(src);
                if (primaryFile) {
                    let img = this.CreateIMG();
                    let successLoad = await this.RenderImage(img, primaryFile);
                    if (successLoad) {
                        this.currentSrc = src;
                        this.RemoveSpinner();
                        return;
                    }
                }
                // this.loading = false;
            }
            this.currentSrc = null;
            let defaultSrc = this.getAttribute("default-src");
            if (defaultSrc?.trim()) {
                let secondaryFile = await this.RequestImage(defaultSrc);
                if (secondaryFile) {
                    let img = this.CreateIMG();
                    let successLoad = await this.RenderImage(img, secondaryFile);
                    if (successLoad) {
                        this.shadowRoot.querySelector("img")?.remove();
                        this.shadowRoot.append(img);
                        this.RemoveSpinner();
                        return;
                    }
                }
            }
            else this.shadowRoot.querySelector("img")?.remove();
            this.RemoveSpinner();
        }, 50);
    }

    private RenderImage(img: HTMLImageElement, file: File | string) {
        return new Promise<boolean>((resolve, reject) => {
            img.onload = (e) => {
                URL.revokeObjectURL((e.target as HTMLImageElement).src);
                img.onload = null;
                img.onerror = null;
                resolve(true);
            }
            img.onerror = (e) => {
                URL.revokeObjectURL(img.src);
                resolve(false);
            }
            img.src = (typeof file == "string" ? file : URL.createObjectURL(file));
        })
    }

    private async RequestImage(src: string): Promise<File> {
        return new Promise<File>((resolve, reject) => {
            DataDRequest._RequestBlobFromUrlResourceV2(src, (blob, error) => {
                if (error == null && blob) {
                    let srcSplited = src.split("/");
                    let newFile = new File([blob], srcSplited[srcSplited.length - 1], { "type": blob.type });
                    resolve(newFile);
                } else {
                    resolve(null);
                    // console.warn("-d", "wc-img fail load", src, error);
                }
            })
        })
    }

    // *****************************************************************************
    // ELEMENT LIFE CICLE CALLBACKS
    // *****************************************************************************

    /** Invocado cuando el componente personalizado se conecta por primera vez al DOM del documento. */
    connectedCallback() {
        if (!this.currentSrc && (this.getAttribute("src") || this.getAttribute("default-src")))
            this.LoadImage();
    }

    /** Invocado cuando el componente personalizado se deconecta del DOM del documento. */
    disconnectedCallback() {
        // this.#controlContainerElement.removeEventListener("click", this.checkboxOnClickEvent);
    }

    /** Invocado cuando el componente personalizado se mueve a un nuevo documento. */
    adoptedCallback(oldDocument: Document, newDocument: Document) {
    }

    /** Invocado cuando uno de los atributos del componente personalizado es añadido, removido o modificado. */
    attributeChangedCallback(attrName: string, oldValue: string, newValue: string) {
        switch (attrName) {
            case "src":
                if (this.isConnected) this.LoadImage();
                break;
            case "default-src":
                if (this.isConnected) this.LoadImage();
                break;
            case "object-fit":
                let img = this.shadowRoot.querySelector("img");
                if (img) img.style.objectFit = newValue;
                break;
            case "spinner-dim":
            case "spinner-border-width":
            case "spinner-border-color":
                let spinner = this.shadowRoot.querySelector<HTMLSpinnerElement>("wc-spinner")
                if (spinner) switch (attrName) {
                    case "spinner-dim":
                        spinner._Dim = newValue;
                        break;
                    case "spinner-border-width":
                        spinner._BorderWidth = newValue;
                        break;
                    case "spinner-border-color":
                        spinner.style.color = newValue;
                        break;
                }
                break;
        }
    }

    static get observedAttributes() {
        return ["src", "default-src", "object-fit", "spinner-dim", "spinner-border-width", "spinner-border-color"];
    }

    set _Src(value: string) {
        this.setAttribute("src", value || "");
    }
    get _Src() {
        return this.getAttribute("src");
    }

    set _DefaultSrc(value: string) {
        this.setAttribute("default-src", value || "");
    }
    get _DefaultSrc() {
        return this.getAttribute("default-src");
    }

    set _ObjectFit(value: TObjectFit) {
        this.setAttribute("object-fit", value || "");
    }
    get _ObjectFit() {
        return this.getAttribute("object-fit") as TObjectFit;
    }

    set _SpinnerDim(value: string) {
        this.setAttribute("spinner-dim", value || "");
    }
    get _SpinnerDim() {
        return this.getAttribute("spinner-dim");
    }

    set _SpinnerBorderWidth(value: string) {
        this.setAttribute("spinner-border-width", value || "");
    }
    get _SpinnerBorderWidth() {
        return this.getAttribute("spinner-border-width");
    }

    set _SpinnerBorderColor(value: string) {
        this.setAttribute("spinner-border-color", value + "");
    }
    get _SpinnerBorderColor() {
        return this.getAttribute("spinner-border-color");
    }
}
customElements.define("wc-img", HTMLImage2Component);
