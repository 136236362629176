import * as d3 from "d3";

export abstract class MatrixBase<T> {

    protected matrix: T[][];

    constructor() {
        this.matrix = new Array(/* Array() */);
    }

    get _rowLenght() {
        return this.matrix.length;
    }
    get _columnlenght() {
        return this._columnas.length;
    }

    get _filas() {
        return this.matrix;
    }

    get _columnas() {
        return this.transpuesta();
    }
    protected clear() {
        this.matrix = new Array();
    }

    protected getElement(fila: number, columna: number): T {
        let element: T = null;
        if (this.matrix.length > 0 && this.matrix.length >= fila) {
            if (this.matrix[fila].length > 0 && this.matrix[fila].length >= columna) {
                element = this.matrix[fila][columna];
            }
        }
        return element;
    }

    /** @param inRow  En una fila especifica (no mayor a row.lengh +1) */
    protected addInRow(inRow: number, ...items: T[]) {
        if (this.matrix.length < inRow) {
            this.matrix[inRow].push(...items);
        } else if (this.matrix.length + 1 < inRow) {
            this._addRow(...items);
        } else {
            console.warn("-d", "La posicion{" + inRow + "} es mayor a las files de la matrix {" + this.matrix.length + "}");
            return 0;
        }
        return this.matrix.length;
    }

    /** Agrega elemento(s) en una fila nueva */
    protected _addRow(...items: T[]): number {
        this.matrix.push(Array());
        this.matrix[this.matrix.length - 1].push(...items);
        return this.matrix.length;
    }

    protected transpuesta() {
        let auxMatrix: T[][] = [];
        this.forEach((item, fila, column) => {
            if (auxMatrix[column] == undefined) {
                auxMatrix[column] = new Array();
            }
            auxMatrix[column][fila] = item;
        });

        return auxMatrix;
    }

    protected forEach(callback: (item: T, fila: number, column: number) => void) {
        for (let f = 0; f < this.matrix.length; f++) {
            for (let c = 0; c < this.matrix[f].length; c++) {
                callback(this.matrix[f][c], f, c);
            }
        }
    }
}
//------------------------------------------------------------------------------------------

export type THeaders = {
    Field: string;
    Label: string;
    Width?: string;
    MinWidth?: string;
}

export interface IComponent { d3Selection: TSelectionHTML<"htmlelement"> };
interface IHeader extends IComponent { titles: THeaders[]/* Map<string, string>; */ }
interface IBody extends IComponent { }
interface IFooter extends IComponent { }

type PropInterface<T> = Record<keyof T, string>;

export class ListGrid<T> extends MatrixBase<HTMLElement> {
    public D3Selection: TSelectionHTML<"div">
    public header: IHeader;
    public body: IBody;
    public footer: IFooter;

    private typeElementDefault: string;
    private controlValueDefault: string;

    public DisplayMember: PropInterface<T>;
    public Control: PropInterface<T>;
    public ControlValue: PropInterface<T>
    private DataSet: T[];
    private templateColumns: string;

    constructor() {
        super();
        this.header = <IHeader>{};
        this.header.titles = [];/* new Map(); */
        this.body = <IBody>{};
        this.footer = <IFooter>{};
        this.DataSet = [];
        //config
        this.typeElementDefault = "span";
        this.controlValueDefault = "text";
        this.DisplayMember = <PropInterface<T>>{};
        this.Control = <PropInterface<T>>{};
        this.ControlValue = <PropInterface<T>>{};

    }

    set _Data(value) { this.DataSet = value; }
    get _Data() { return this.DataSet; }

    public _Build(parent?: HTMLElement) {
        this.D3Selection = parent ? d3.select(parent).append("div") : d3.create("div");
        this.D3Selection = this.D3Selection.classed("listViewer", true);
        this.header.d3Selection = this.D3Selection.append("header");
        this.body.d3Selection = this.D3Selection.append("div").classed("content", true) as any;
        this.footer.d3Selection = this.D3Selection.append("footer");

        this.renderHeader();
        this.renderBody();
    }

    private renderHeader() {
        let columns = [];

        if (this.header.titles && this.header.titles.length > 0) {
            this.header.d3Selection.selectAll("span")
                .data(this.header.titles)
                .join(enter => {
                    return enter.append("span").text(title => {
                        if (title.Width) columns.push(title.Width)
                        else columns.push("1fr");
                        return title.Label;
                    });
                })
        } else if (this.DataSet) {
            let n = Object.keys(this.DataSet[0]).length;
            do {
                columns.push("1fr");
                n--;
            } while (n != 0);

        }
        this.templateColumns = columns.join(" ");
        this.header.d3Selection.style("grid-template-columns", this.templateColumns);
    }

    public _addData<K extends keyof T>(objeto: T) {//ej.persona : {Nombre, Ap}
        let rowElements: HTMLElement[] = [];
        for (let prop in <Object>objeto) {//ej.. prop = Nombre, data[prop] = "juan"
            rowElements.push(this.renderData(objeto[prop], prop));
        }
        super._addRow(...rowElements);
        this.DataSet.push(objeto);
        if (this.DataSet && this.header.d3Selection) this.renderHeader();
    }

    private renderData(value: TDataPrimitive, prop: string): HTMLElement {
        let element: d3.Selection<HTMLElement, null, Element, null>;

        if (this.Control[prop])
            element = d3.create(this.Control[prop]);
        else
            element = d3.create(this.typeElementDefault);

        if (this.ControlValue[prop]) {
            if (prop == "value" || prop == "src")
                element.property(this.ControlValue[prop], value);
            else
                element.attr(this.ControlValue[prop], value);
        } else {
            if (this.controlValueDefault == "text")
                element.text(value);
            else
                element.attr(this.controlValueDefault, value);
        }

        return element.node();
    }

    public _addElements(...element: HTMLElement[]) {
        let res = super._addRow(...element);
        let ultimoItem = this._filas[this._rowLenght - 1];
        this.renderItem(ultimoItem);
        return res;
    }

    public _addRow(...items: HTMLElement[]) {
        let res = super._addRow(...items);
        let ultimoItem = this._filas[this._rowLenght - 1];
        this.renderItem(ultimoItem as HTMLElement[]);
        return res;
    }

    public _removeAllRows() {
        d3.selectAll(".fila_item").remove();
        this.clear();
    }

    private renderItem(ItemRow: HTMLElement[] | T[]) {
        const itemFile = this.body.d3Selection.append("div").classed("fila_item", true);
        ItemRow.forEach(element => itemFile.append(() => element));
        itemFile.style("grid-template-columns", this.templateColumns);
    }

    private renderBody() {
        this._filas.forEach((fila) => {
            this.renderItem(fila);
        })
    }

    public _DrawItemList() {
        return this.body.d3Selection.append("div").classed("item_list", true);
    }
}

/**Selector: `d3.selection`, sin un data, datum  */
//export interface D3Selection<T extends d3.BaseType> extends d3.Selection<T, {}, HTMLElement, any> { };

export type TDataPrimitive = string | number | boolean; // | null | undefined;
