import { DataDRequest } from "./DRequest";
import { Entidad } from "./Entidad";
import { ILicencia } from "./entidad/LicenciaOld";
import { _DICC_ALUMNO, _SvAlumnoObtenerLista } from "./modulo/Alumno";
import { _DiccAyuda, _GetList as _GetListAyuda } from "./modulo/Ayuda";
import { _DiccCicloEscolar, _GetListCicloEscolar } from "./modulo/CicloEscolar";
import { _DictCuentaBancaria, _GetList as _GetListCuentaBancaria } from "./modulo/CuentaBancaria";
import { _DictCategorias, _GetList as _GetListEgresoCategoria } from "./modulo/EgresoCategoria";
import { _DictEgresosPredefinidos, _GetList as _GetListEgresoPredefinido } from "./modulo/EgresoPredefinido";
import { _DiccEscolaridad, _GetList as _GetListEscolaridad } from "./modulo/Escolaridad";
import { _DiccEscuela, _GetList as _GetListEscuela } from "./modulo/Escuela";
import { _DiccEscuelaAlumnoInfoExtra, _GetAlumnosInfoExtraList as _GetListEscuelaAlumnoInfoExtra } from "./modulo/EscuelaAlumnoInfoExtra";
import { _DiccFinanzasCargos, _GetListFinanzaCargo } from "./modulo/FinanzaCargo";
import { _DiccHorasExtras, _GetList as _GetListFinanzaHoraExtra } from "./modulo/FinanzaHoraExtra";
import { _DiccMetodosPago, _GetList as _GetListFinanzaMetodoPago } from "./modulo/FinanzaMetodoPago";
import { _DiccGrado, _GetList as _GetListGrado } from "./modulo/Grado";
import { _DiccGrupo, _GetList as _GetListGrupo } from "./modulo/Grupo";
import { _DiccNinioHorarioAsignacion, _GetList as _GetListHorarioAlumno } from "./modulo/HorarioAlumno";
import { _DICC_LICENCIA, _SvLicenciaObtenerLista } from "./modulo/LicenciaOld";
import { _DiccLogros, _GetList as _GetListLogro } from "./modulo/Logro";
import { _DiccLogroAsignaciones, _GetListLogroAsignacion } from "./modulo/LogroAsignacion";
import { _DiccCategorias, _GetList as _GetListLogroCategoria } from "./modulo/LogroCategoria";
import { _DictMaestros, _GetList as _GetListMaestro } from "./modulo/Maestro";
import { _DiccMateriasV2, _GetList as _GetListMateriaV2 } from "./modulo/MateriaV2";
import { _DICC_NOTICIA, _GetList as _GetListCircular } from "./modulo/Noticia";
import { _DictProveedor, _GetList as _GetListProveedor } from "./modulo/Proveedor";
import { ISesionVigencia, _DiccSesionVigencia, _VerificarSesion } from "./modulo/Sesion";
import { _DiccTutores, _GetList as _GetListTutor } from "./modulo/Tutor";
import { _DiccTutorAsignaciones, _GetList as _GetListTutorAsignacion } from "./modulo/TutorAsignacion";
import { _DiccUsuarios, _GetList as _GetListUsuario } from "./modulo/Usuario";
import { DataUtil } from "./util/Util";

/** Control de modulos monitoreables (MM), Los datos de cada MM...
 * * Se obtienen de un servicio desde una función especifica
 * * Se guardan en indexDB (opcinal)
 *     - Nota: Si están disponibles en indexDB, el proximo reload inicia con esos datos
 * * Se guardan en un Map
 * * Pueden ser invocados manualmente, disparando los puntos anteriores
 * * Se consultan cada N milisegundos en el worker (opcional)
*/
export namespace DataModuloMain {
    import CTypeRequest = Entidad.CTipoRequest;
    type OmitEntity = undefined;

    export type TTypeRequestEntitiesMap = {
        [k in CTypeRequest]: (
            k extends CTypeRequest.Escuela ? Entidad.IEscuela
            : k extends CTypeRequest.Alumno ? Entidad.IAlumno
            : k extends CTypeRequest.LogroCategoria ? Entidad.ILogroCategoria
            : k extends CTypeRequest.Tutor ? Entidad.ITutor
            : k extends CTypeRequest.Grupo ? Entidad.IGrupo
            : k extends CTypeRequest.Grado ? Entidad.IGrado
            : k extends CTypeRequest.Logro ? Entidad.ILogro
            : k extends CTypeRequest.LogroAsignacion ? Entidad.ILogroAsignacion
            : k extends CTypeRequest.TutorAsignacion ? Entidad.ITutorAsignacion
            : k extends CTypeRequest.HorarioAlumno ? Entidad.IAlumnoHorarioAsignacion
            // : k extends CTypeRequest.UsuarioPermisos ? never // NEVER
            : k extends CTypeRequest.Circular ? Entidad.ICircular
            // : k extends CTypeRequest.Archivo ? never // NEVER
            : k extends CTypeRequest.Escolaridad ? Entidad.IEscolaridad
            // : k extends CTypeRequest.Finanzas ? never // NEVER
            : k extends CTypeRequest.FinanzaCargo ? Entidad.IFinanzaCargo
            : k extends CTypeRequest.Sesion ? ISesionVigencia
            : k extends CTypeRequest.CicloEscolar ? Entidad.ICicloEscolar
            : k extends CTypeRequest.FinanzaMetodoPago ? Entidad.IFinanzaMetodoPago
            : k extends CTypeRequest.FinanzaHoraExtra ? Entidad.IFinanzaItemHoraExtra
            : k extends CTypeRequest.Usuario ? Entidad.IUsuario
            : k extends CTypeRequest.Ayuda ? Entidad.IAyuda
            : k extends CTypeRequest.Maestro ? Entidad.IMaestro
            : k extends CTypeRequest.EscuelaAlumnoInfoExtra ? Entidad.IEscuelaConfigInfoExtra
            : k extends CTypeRequest.EgresoPredefinido ? Entidad.IFinanzaEgresoPredefinido
            : k extends CTypeRequest.CuentaBancaria ? Entidad.IFinanzaCuentaBancaria
            : k extends CTypeRequest.Proveedor ? Entidad.IFinanzaProveedor
            : k extends CTypeRequest.MateriaV2 ? Entidad.IMateria
            : k extends CTypeRequest.EgresoCategoria ? Entidad.IFinanzasEgresoCategoria
            : k extends CTypeRequest.Licencia ? ILicencia
            // : k extends CTypeRequest.EgresoMovimiento ? Entidades.IFinanzaEgresoMovimiento
            : OmitEntity
        )
    }

    export type TipoRequestMonitorId = {
        [k in keyof typeof CTypeRequest]: (TTypeRequestEntitiesMap[typeof CTypeRequest[k]]) extends OmitEntity ? never : (typeof CTypeRequest)[k]
    }[keyof typeof CTypeRequest]

    export type TipoRequestMonitorName = {
        [k in keyof typeof CTypeRequest]: (TTypeRequestEntitiesMap[typeof CTypeRequest[k]]) extends OmitEntity ? never : k
    }[keyof typeof CTypeRequest]

    type TMonitoreable = {
        1: true;
        2: false;
    }

    type IItemModule<TypeRequest extends CTypeRequest, Entity = TTypeRequestEntitiesMap[TypeRequest]> = {
        // [kE in keyof Entity]: {
        [kM in keyof TMonitoreable]: {
            // NOTE Implementar ids as number[]
            readonly GetDataList: (idsEscuelas: Array<Entidad.IEscuela>) => Promise<Array<DataDRequest.IRequestResponseListA<Entity>>>;
            readonly DictData: Map<number, Entity>;
            readonly IdData: TOnlyKeysTypeof<Entity, number>; // Entity[kE] extends number ? (kE & string) : never;
            readonly IsMonitorable: TMonitoreable[kM];
            /** If true: Admin creates tables in BD for module data */
            readonly SaveInLocalBD: boolean;
            // readonly CreateStorage: boolean;
            readonly Environment?: DataUtil.TEnvironment;
            __StorageAvailable?: boolean;
            readonly ForcedLoad?: boolean;
        }
        & (
            TMonitoreable[kM] extends true
            ? {
                /** Establece la frecuencia de monitoreo:
                 * Es un múltiplo para el número de vuelta en la que se encuentra el monitor.
                 *
                 * Puede impedir la primer carga de datos.
                 * @example
                 * MonitorFrecuency = 2 // Consulta datos cada 2 vueltas del monitor
                 */
                readonly MonitorFrecuency?: number;
                /**
                 * Forza una primera carga de los datos.
                */
                readonly RequestOnFirstLoad?: boolean;
            }
            : {
                /**
                 * Forza una primera carga de los datos.
                */
                readonly RequestOnFirstLoad?: true;
            }
        )
    }[keyof TMonitoreable];
    // }[keyof Entity];

    type IModuleConfig = {
        [k in TipoRequestMonitorName]: IItemModule<typeof CTypeRequest[k]>;
    }
    let modulesConfig: IModuleConfig;
    /**
     *
     * @returns
     *
     * - {
     * -      Kinder: IItemModule<CTypeRequest.Kinder, Entidades.IKinder>;
     * -      Ninio: IItemModule<CTypeRequest.Ninio, Entidades.IChild>;
     * -      ... 16 or more ...;
     * -      Ayuda: IItemModule<...>;
     * - }
     */
    export function _GetModulesConfig(): IModuleConfig {
        if (!modulesConfig || !modulesConfig.Escuela.GetDataList) {
            modulesConfig = {
                Sesion: {
                    GetDataList: _VerificarSesion,
                    DictData: _DiccSesionVigencia,
                    IdData: "Id",
                    IsMonitorable: true,
                    SaveInLocalBD: false
                },
                Escuela: {
                    GetDataList: _GetListEscuela,
                    DictData: _DiccEscuela,
                    IdData: "IdKinder",
                    IsMonitorable: true,
                    MonitorFrecuency: 2,
                    RequestOnFirstLoad: true,
                    SaveInLocalBD: true
                },
                Licencia: {
                    GetDataList: _SvLicenciaObtenerLista,
                    DictData: _DICC_LICENCIA,
                    IdData: "Id",
                    IsMonitorable: true,
                    RequestOnFirstLoad: true,
                    SaveInLocalBD: false,
                    Environment: "debug",
                },
                Tutor: {
                    GetDataList: _GetListTutor,
                    DictData: _DiccTutores,
                    IdData: "IdPadre",
                    IsMonitorable: true,
                    MonitorFrecuency: 3,
                    RequestOnFirstLoad: true,
                    SaveInLocalBD: true
                },
                Grado: {
                    GetDataList: _GetListGrado,
                    DictData: _DiccGrado,
                    IdData: "IdNivel",
                    IsMonitorable: true,
                    SaveInLocalBD: true
                },
                Escolaridad: {
                    GetDataList: _GetListEscolaridad,
                    DictData: _DiccEscolaridad,
                    IdData: "Id",
                    IsMonitorable: true,
                    MonitorFrecuency: 2,
                    RequestOnFirstLoad: true,
                    SaveInLocalBD: true
                },
                Grupo: {
                    GetDataList: _GetListGrupo,
                    DictData: _DiccGrupo,
                    IdData: "IdGrupo",
                    IsMonitorable: true,
                    MonitorFrecuency: 3,
                    RequestOnFirstLoad: true,
                    SaveInLocalBD: true
                },
                HorarioAlumno: {
                    GetDataList: _GetListHorarioAlumno,
                    DictData: _DiccNinioHorarioAsignacion,
                    IdData: "IdHorario",
                    IsMonitorable: true,
                    MonitorFrecuency: 2,
                    RequestOnFirstLoad: true,
                    SaveInLocalBD: false,
                },
                LogroCategoria: {
                    GetDataList: _GetListLogroCategoria,
                    DictData: _DiccCategorias,
                    IdData: "IdCategoria",
                    IsMonitorable: true,
                    MonitorFrecuency: 3,
                    RequestOnFirstLoad: true,
                    SaveInLocalBD: false,
                },
                Logro: {
                    GetDataList: _GetListLogro,
                    DictData: _DiccLogros,
                    IdData: "IdLogro",
                    IsMonitorable: true,
                    MonitorFrecuency: 3,
                    RequestOnFirstLoad: true,
                    SaveInLocalBD: false,
                },
                Alumno: {
                    GetDataList: _SvAlumnoObtenerLista,
                    DictData: _DICC_ALUMNO,
                    IdData: "IdChild",
                    IsMonitorable: true,
                    SaveInLocalBD: true,
                },
                LogroAsignacion: {
                    GetDataList: _GetListLogroAsignacion,
                    DictData: _DiccLogroAsignaciones,
                    IdData: "IdAsignacion",
                    IsMonitorable: true,
                    SaveInLocalBD: true,
                },
                TutorAsignacion: {
                    GetDataList: _GetListTutorAsignacion,
                    DictData: _DiccTutorAsignaciones,
                    IdData: "IdRegistro",
                    IsMonitorable: true,
                    SaveInLocalBD: true,
                },
                // UsuarioPermisos: null,
                Circular: {
                    GetDataList: _GetListCircular,
                    DictData: _DICC_NOTICIA,
                    IdData: "IdNoticia",
                    IsMonitorable: false,
                    SaveInLocalBD: false,
                    RequestOnFirstLoad: true,
                    ForcedLoad: true
                },
                // Archivo: null,
                FinanzaCargo: {
                    GetDataList: _GetListFinanzaCargo,
                    DictData: _DiccFinanzasCargos,
                    IdData: "ID",
                    IsMonitorable: true,
                    SaveInLocalBD: true,
                },
                CicloEscolar: {
                    GetDataList: _GetListCicloEscolar,
                    DictData: _DiccCicloEscolar,
                    IdData: "Id",
                    IsMonitorable: true,
                    SaveInLocalBD: true,
                },
                FinanzaMetodoPago: {
                    GetDataList: _GetListFinanzaMetodoPago,
                    DictData: _DiccMetodosPago,
                    IdData: "IdMetodoPago",
                    IsMonitorable: true,
                    SaveInLocalBD: true,
                },
                FinanzaHoraExtra: {
                    GetDataList: _GetListFinanzaHoraExtra,
                    DictData: _DiccHorasExtras,
                    IdData: "Id",
                    IsMonitorable: true,
                    SaveInLocalBD: false,
                },
                Usuario: {
                    GetDataList: _GetListUsuario,
                    DictData: _DiccUsuarios,
                    IdData: "IdUsuario",
                    IsMonitorable: false,
                    SaveInLocalBD: false,
                },
                Ayuda: {
                    GetDataList: _GetListAyuda,
                    DictData: _DiccAyuda,
                    IdData: "Id",
                    IsMonitorable: false,
                    SaveInLocalBD: false,
                },
                Maestro: {
                    GetDataList: _GetListMaestro,
                    DictData: _DictMaestros,
                    IdData: "Id",
                    IsMonitorable: true,
                    MonitorFrecuency: 8,
                    RequestOnFirstLoad: true,
                    SaveInLocalBD: false,
                },
                EscuelaAlumnoInfoExtra: {
                    GetDataList: _GetListEscuelaAlumnoInfoExtra,
                    DictData: _DiccEscuelaAlumnoInfoExtra,
                    IdData: "Id",
                    IsMonitorable: false,
                    SaveInLocalBD: true,
                    RequestOnFirstLoad: true,
                },
                EgresoPredefinido: {
                    GetDataList: _GetListEgresoPredefinido,
                    DictData: _DictEgresosPredefinidos,
                    IdData: "Id",
                    IsMonitorable: true,
                    MonitorFrecuency: 2,
                    RequestOnFirstLoad: true,
                    SaveInLocalBD: false,
                },
                CuentaBancaria: {
                    GetDataList: _GetListCuentaBancaria,
                    DictData: _DictCuentaBancaria,
                    IdData: "Id",
                    IsMonitorable: false,
                    RequestOnFirstLoad: true,
                    SaveInLocalBD: false,
                },
                Proveedor: {
                    GetDataList: _GetListProveedor,
                    DictData: _DictProveedor,
                    IdData: "Id",
                    IsMonitorable: false,
                    RequestOnFirstLoad: true,
                    SaveInLocalBD: false,
                },
                MateriaV2: {
                    GetDataList: _GetListMateriaV2,
                    DictData: _DiccMateriasV2,
                    IdData: "IdMateria",
                    IsMonitorable: false,
                    RequestOnFirstLoad: true,
                    SaveInLocalBD: false,
                },
                EgresoCategoria: {
                    GetDataList: _GetListEgresoCategoria,
                    DictData: _DictCategorias,
                    IdData: "Id",
                    IsMonitorable: false,
                    SaveInLocalBD: false,
                }
            }
        }
        return modulesConfig;
    }

    export function _GetModuleConfig<ReqID extends TipoRequestMonitorId>(idRequest: ReqID): IItemModule<ReqID> {
        return _GetModulesConfig()[CTypeRequest[idRequest] as keyof CTypeRequest] || null;
    }

    export function _ActivarServiceMonitor<ReqID extends TipoRequestMonitorId>(idRequest: ReqID, activar: boolean) {
        const item = DataModuloMain._GetModuleConfig(idRequest);
        (item.IsMonitorable as any) = activar;
    }

    // MAP

    export function _GetReqDataMapById<ReqID extends TipoRequestMonitorId>(requestID: ReqID) {
        return DataModuloMain._GetModuleConfig(requestID).DictData;
    }

    export function _GetReqDataMapByName<ReqName extends TipoRequestMonitorName>(requestName: ReqName) {
        const requestID = CTypeRequest[requestName];
        return _GetReqDataMapById(requestID);
    }

    // ARRAY

    export function _GetReqDataArrayById<ReqID extends TipoRequestMonitorId>(requestID: ReqID, sort = false) {
        let itemRequestConfig = _GetModuleConfig(requestID);
        let idKey = itemRequestConfig.IdData as string;

        let res = Array.from(itemRequestConfig.DictData.values());

        if (sort) {
            return res.sort((a, b) => (a[idKey] - b[idKey]));
        }
        return res;
    }

    export function _GetReqDataArrayByName<ReqName extends TipoRequestMonitorName>(requestName: ReqName, sort = false) {
        const requestID = CTypeRequest[requestName];
        return _GetReqDataArrayById(requestID, sort);
    }

    // GET DATA

    export function _GetItemDataById<ReqName extends TipoRequestMonitorId>(requestName: ReqName, id: number) {
        const map = _GetReqDataMapById(requestName);
        const dato = map.get(id);
        if (!dato) {
            return null;
        }
        return dato;
    }

    export function _GetItemDataByName<ReqName extends TipoRequestMonitorName>(requestName: ReqName, id: number) {
        const requestID = CTypeRequest[requestName];
        return _GetItemDataById(requestID, id);
    }

    export function _GetDataValueFieldById<ReqName extends TipoRequestMonitorId, FieldDataType extends keyof TTypeRequestEntitiesMap[ReqName]>(requestName: ReqName, id: number, field: FieldDataType) {
        const map = _GetReqDataMapById(requestName);
        const dato = map.get(id);
        if (!dato) {
            return null;
        }
        return dato[field];
    }

    export function _GetDataValueFieldByName<ReqName extends TipoRequestMonitorName, FieldDataType extends keyof TTypeRequestEntitiesMap[(typeof CTypeRequest)[ReqName]]>(requestName: ReqName, id: number, field: FieldDataType) {
        const requestID = CTypeRequest[requestName];
        return _GetDataValueFieldById(requestID, id, field);
    }
}
