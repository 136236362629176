import * as d3 from "d3";
import { MainPage } from "../../MainPage";
import { DataDRequest } from "../../data/DRequest";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import DataModuloEscuela from "../../data/modulo/Escuela";
import DataModuloMaestro from "../../data/modulo/Maestro";
import DataModuloPermisos from "../../data/modulo/Permisos";
import DataModuloTutor from "../../data/modulo/Tutor";
import DataModuloUsuario, { IItemPermisoToInsertBase, IItemPermisoToInsert } from "../../data/modulo/Usuario";
import { DataUtilPermission } from "../../data/util/Permission";
import { DataUtil } from "../../data/util/Util";
import { VentanaGrid, IGridRenderInfo, IGridExtraTableConfig, IConfigGridExcelExport } from "../controlD3/AVentanaGrid";
import { CheckBox } from "../controlD3/CheckBox";
import { CopyElementTextControl } from "../controlD3/CopyElementTextControl";
import { ExcelThings } from "../controlD3/ExcelExport";
import { FormGenerator, IField, Fields } from "../controlD3/Formulario";
import { ModalThings } from "../controlD3/ModalThings";
import { Notificacion } from "../controlD3/Notificacion";
import { Table } from "../controlD3/Tabla";
import { TreeViewBase } from "../controlD3/TreeViewBase";
import { TreeViewV2 } from "../controlD3/TreeViewV2";
import { UIUtilPermission } from "../util/Permission";
import { UIUtilStrings } from "../util/Strings";
import { UIUtilGeneral } from "../util/Util";
import { UIUtilViewData } from "../util/ViewData";
import IUsuario = Entidad.IUsuario;
import CTipoPerfil = Entidad.CTipoPerfil;
import IEscuela = Entidad.IEscuela;

// import IPermiso = data.Entidades.IPermiso;
import CModulo = Entidad.CModulo;
import CAccionPermiso = Entidad.CAccionPermiso;

// import IPermisoAccion = data.Entidades.IPermisoAccion;
// import IPermisoModulo = data.Entidades.IPermisoModulo;
import IPermisoDisponible = Entidad.IPermisoDisponible;
import IPermisoAsignacionUser = Entidad.IPermisoAsignacionUser;

interface IUsuarioForm extends IUsuario {
    Contrasenia?: string;
    Contrasenia2?: string;
    /** Extra => Maestro-Tutor */
    Telefono?: string;
    /** Extra => Maestro */
    IdEscuela?: number;
    /** Map<idkinder, Map<IdPermisoDisponible, permisodisponible>> */
    Permisos: Map<number, Map<number, IPermisoDisponible>>;
    /** Uso solo en Editar. No mutable, para validar el resultado del formulario (las modificaciones que se hicieron con respecto a los permisos) */
    PermisosOrigin?: IPermisoAsignacionUser[];
    /** Para configuración extra Maestr@/Tutor */
    ExtraConfig?: boolean;
}

enum CFormModels {
    Nombre = "Nombre",
    ApPaterno = "ApPaterno",
    ApMaterno = "ApMaterno",
    Correo = "Correo",
    // Escuela = "IdKinder",
    Perfil = "Perfil",
    Permisos = "Permisos",
    Contrasenia = "Contrasenia",
    Contrasenia2 = "Contrasenia2",
    Telefono = "Telefono",
    IdEscuela = "IdEscuela",
    ExtraConfig = "ExtraConfig"
}

interface IItemModuloPermiso {
    Id: string;
    Escuela: IEscuela;

    PermisoDisponible?: IPermisoDisponible;
    ModuloName: string; // IPermisoModulo;
    AccionName?: string; //IPermisoAccion;

    ParentModuloPermiso: IItemModuloPermiso;

    ChildsAcciones: Map<number, IItemModuloPermiso>;
    ChildsModulosPermisos: Map<number, IItemModuloPermiso>;
}

export class UIVentanaUsuarios extends VentanaGrid<IUsuario> {

    constructor(content: TSelectionHTML<"div">, modulo: CModulo) {
        super(content, modulo);
    }

    public _Mostrar(): void {
        super._Mostrar();
        MainPage._ActivarServiceMonitor(Entidad.CTipoRequest.Usuario, true);
    }

    public _Destroy(): void {
        super._Destroy();
        MainPage._ActivarServiceMonitor(Entidad.CTipoRequest.Usuario, false);
    }

    // **********************************************************************
    // FORMULARIO CONFIGURACIONES
    // **********************************************************************

    //#region

    private GetForm(modalContainer: TSelectionHTML<"div">, action: (CAccionPermiso.Agregar | CAccionPermiso.Editar), datoU = <IUsuario>{}) {
        let dataForm: IUsuarioForm;
        let form = new FormGenerator<IUsuarioForm>();

        if (action == CAccionPermiso.Agregar) {
            dataForm = <IUsuarioForm>{
                Perfil: CTipoPerfil.Limitado,
                ExtraConfig: true,
                Permisos: new Map(),
                PermisosOrigin: []
            };
        } else {
            dataForm = {
                ... {
                    Permisos: new Map(),
                    PermisosOrigin: []
                },
                ...Object.assign({}, datoU)
            }
        }

        const escuelasConPermiso = UIUtilPermission._GetSchoolsByActionModule(Entidad.CModulo.Usuarios, CAccionPermiso.Agregar);
        const escuelasOpts: Pick<IEscuela, "IdKinder" | "Nombre">[] = [];
        escuelasOpts.push(...escuelasConPermiso);

        const updateExtraConfig = () => {
            if (action != CAccionPermiso.Agregar) return;
            if (form._Data.Perfil != CTipoPerfil.Limitado) {
                (form._ControlsData.get("ExtraConfig").selection.node() as HTMLInputElement).checked = true;
            }
            const extraConfig = form._Data.ExtraConfig;

            form._ControlsData.get("Contrasenia").selection.attr("required", extraConfig ? "" : null);
            form._ControlsData.get("Contrasenia2").selection.attr("required", extraConfig ? "" : null);

            form._ControlsData.get("ExtraConfig").row.classed("hide", form._Data.Perfil != CTipoPerfil.Limitado)
            form._Form.select(".row_1").select(".extraconfig").classed("hide", !extraConfig);
            form._Form.select(".row_1").select(".extraconfig_teacher").classed("hide", !extraConfig);
            form._ControlsData.get("Telefono").row.classed("hide", !extraConfig);
            form._ControlsData.get("IdEscuela").row.classed("hide", !extraConfig);
        }

        let schema: IField<IUsuarioForm>[] = [
            {
                type: Fields.input,
                inputAttr: { type: "text", required: true },
                labelAttr: { text: "d_field_nombre" },
                model: CFormModels.Nombre
            },
            {
                type: Fields.input,
                inputAttr: { type: "text", required: true },
                labelAttr: { text: "d_field_appaterno" },
                model: CFormModels.ApPaterno
            },
            {
                type: Fields.input,
                inputAttr: { type: "text" },
                labelAttr: { text: "d_field_apmaterno" },
                model: CFormModels.ApMaterno
            },
            {
                type: Fields.selectMaterial,
                selectMaterialAttr: {
                    valueMember: "Id",
                    displayMember: "Name",
                    onChange: (idPerfil: number, perfil) => {
                        let user = (<IUsuarioForm>form._DataOrigin)
                        user.Perfil = idPerfil;
                        form._ControlsData.get(CFormModels.Permisos)["_UpdatePermisosFormUI"](user);
                        updateExtraConfig();
                    },
                    required: true
                },
                labelAttr: { text: "d_field_strperfil" },
                values: this.GetUserList(),
                model: CFormModels.Perfil
            },
            {
                type: Fields.input,
                inputAttr: { type: "email", required: true },
                labelAttr: { text: "d_field_correo" },
                model: CFormModels.Correo
            }
        ];

        if (action == CAccionPermiso.Agregar) {
            schema.push(
                {
                    type: Fields.input,
                    inputAttr: { type: "text", minlength: 8 },
                    labelText: "d_field_contrasenia",
                    model: CFormModels.Contrasenia,
                    onValidate: (value) => {
                        if (!form._Data.ExtraConfig) {
                            return value.length <= 0 || value.trim() != ""
                        }
                        return true;
                    }
                },
                {
                    type: Fields.input,
                    inputAttr: { type: "text" },
                    labelText: "d_field_contrasenia2",
                    model: CFormModels.Contrasenia2,
                    onValidate: (value) => {
                        return value == form._Data.Contrasenia
                    }
                },
                {
                    type: Fields.input,
                    inputAttr: { type: "checkbox" },
                    onLoad: (control) => {
                        control.selection.node().onchange = (ev) => {
                            updateExtraConfig();
                        }
                    },
                    labelText: "d_field_extraconfig",
                    model: CFormModels.ExtraConfig
                },
                {
                    type: Fields.input,
                    inputAttr: { type: "phone2_10A", required: true },
                    labelText: "d_field_telefono",
                    model: CFormModels.Telefono
                },
                {
                    type: Fields.selectMaterial,
                    selectMaterialAttr: {
                        required: true,
                        displayMember: "Nombre",
                        valueMember: "IdKinder",
                        Data: escuelasOpts
                    },
                    labelText: "d_field_idescuela",
                    model: CFormModels.IdEscuela
                }
            )
        }

        schema.push(
            {
                type: Fields.label,
                model: CFormModels.Permisos,
                onValidate: () => {
                    console.warn("Permisos")
                    const permisos = form._DataOrigin.Permisos;
                    if (form._Data.Perfil == CTipoPerfil.Limitado) {
                        return permisos.size > 0;
                    }
                    else if (form._Data.Perfil == CTipoPerfil.SuperUsuario) {
                        // Retorna true si: por lo menos una de las escuelas tiene todos los permisos
                        return [...permisos.values()]
                            .some(permisosEscuela => this.SuperUserValidInSchool(permisosEscuela));
                    }
                    return true;
                }
            }
        )

        form._Crear({
            LangModuleKeyInContext: this.labelsKeyBase,
            schema: schema,
            BuildView: (container, controlsForm, form) => this.OnBuild(container, modalContainer, form, action)
        }, dataForm);

        form._ControlsData.get(CFormModels.Permisos)["_UpdatePermisosFormUI"](dataForm);

        form._Form.style("height", action == CAccionPermiso.Agregar ? "555px" : "400px");
        updateExtraConfig();

        return form;
    }

    private OnBuild(container: TSelectionHTML<"form">, modalContainer: TSelectionHTML<"div">, form: FormGenerator<IUsuarioForm>, accionToDo: (CAccionPermiso.Agregar | CAccionPermiso.Editar)) {
        const controlsForm = form._ControlsData;

        container
            .classed("usuario_infoedit", true)
            .html(`
                        <div class="row_1">
                            <!-- <div class="sub_area"><h3>${this.VB_GetUIStringModule("tag_config")}</h3> <hr></div> -->
                        </div>
                        <div class="row_2">
                            <div class="sub_area"><h3></h3> <!-- <hr> --> </div>
                        </div>
                    `);

        const AppendUI = (idLocation: (1 | 2 | 3 | 4), idModel: (keyof IUsuarioForm)) => {
            let control = controlsForm.get(idModel);
            if (!control) return;

            const areaForm = container.select(".row_" + ([3, 4].includes(idLocation) ? 1 : idLocation));

            if (idLocation == 3 && !areaForm.select(".extraconfig").node()) {
                areaForm.append("div").classed("sub_area extraconfig", true).append("h3").text(this.VB_GetUIStringModule("tag_extraconfig"))
            }
            if (idLocation == 4 && !areaForm.select(".extraconfig_teacher").node()) {
                areaForm.append("div").classed("sub_area extraconfig_teacher", true).append("h3").text(this.VB_GetUIStringModule("tag_extraconfig_teacher"))
            }
            areaForm.append(() => control.row.node());

            if (idModel == CFormModels.Permisos) {
                control.row.classed("row_permisos", true);
                control.selection = control.row;

                const AplicarPermisosConfig = (user: IUsuarioForm) => {
                    // perfil: number, permisos: Array<IPermiso>
                    console.log(user, "Permisos...")
                    switch (user.Perfil) {
                        case CTipoPerfil.Admin:
                            // areaForm.select(".sub_area").select("h3").text("---");
                            areaForm.remove();
                            modalContainer.style("width", "550px");
                            break;

                        case CTipoPerfil.SuperUsuario:
                            areaForm.select(".sub_area").select("h3").text(this.VB_GetUIStringModule("tag_schoolaccess"));
                            container.append(() => areaForm.node());
                            modalContainer.style("width", "1000px");

                            control.row.select(".treeview_container").remove(); // Remueve el nodo de la vista anterior
                            let contEscuelas: TSelectionHTML<"div"> = form["_ContEscuelas"];

                            if (!contEscuelas) {
                                contEscuelas = d3.create("div")
                                    .attr("class", "cont_superpermisos");

                                form["_ContEscuelas"] = contEscuelas;
                            }

                            contEscuelas.selectAll("div").remove();

                            this.GridGetEscuelasConPermisoDeAccion(accionToDo).sort((a, b) => d3.ascending(a.Nombre.toLowerCase(), b.Nombre.toLowerCase())).forEach((escuela) => {
                                let item = contEscuelas.append("div")
                                    .classed(`item_1 ${UIUtilGeneral.FBoxOrientation.Horizontal} ${UIUtilGeneral.FBoxAlign.StartCenter}`, true);

                                // Valida si todos tiene todos los modulos activados
                                let initStatusChecked = false;
                                let permisosEscuela = user.Permisos.get(escuela.IdKinder);
                                if (permisosEscuela) {
                                    initStatusChecked = this.SuperUserValidInSchool(permisosEscuela);
                                }

                                let checkbox = CheckBox._GetCheckElement(initStatusChecked);

                                item.append(() => checkbox.node());
                                item.append("label").text(escuela.Nombre);

                                item.on("click", () => {
                                    checkbox.datum().IsChecked = !checkbox.datum().IsChecked
                                    CheckBox._UpdateCheckStatus(checkbox, checkbox.datum().IsChecked);

                                    if (checkbox.datum().IsChecked) {
                                        // CREA TODOS LOS PERMISOS A LA ESCUELA
                                        user.Permisos.set(escuela.IdKinder, new Map(DataModuloPermisos._PermisosDisponiblesMap.entries()));
                                    } else {
                                        // REMUEVE TODOS LOS PERMISOS
                                        user.Permisos.delete(escuela.IdKinder);
                                    }
                                })
                            })

                            control.row.append(() => contEscuelas.node());
                            break;

                        case CTipoPerfil.Limitado:
                            areaForm.select(".sub_area").select("h3").text(this.VB_GetUIStringModule("tag_especificpermission"));
                            container.append(() => areaForm.node());
                            modalContainer.style("width", "1000px");

                            control.row.select(".cont_superpermisos").remove(); // Remueve el nodo de la vista anterior

                            let permisosScheme: Map<number, IItemModuloPermiso> = form["_PermisosScheme"];
                            if (!permisosScheme) {
                                permisosScheme = this.GetPermisosDiponiblesMapScheme(accionToDo, form._Data);
                                form["_PermisosScheme"] = permisosScheme;
                                // console.log(permisosScheme, "permisosScheme");
                            }

                            let controlTree: TreeViewV2.TreeViewControl<[IItemModuloPermiso, IItemModuloPermiso, IItemModuloPermiso, IItemModuloPermiso]>;

                            controlTree = form["_TreeControlPermisos"];
                            if (!controlTree) {
                                controlTree = this.GetPermisosTreeControl(control.row, form, user);
                                form["_TreeControlPermisos"] = controlTree;
                            }

                            if (!controlTree._ControlContainer.node().parentElement) {
                                control.row.append(() => controlTree._ControlContainer.node());
                            }

                            controlTree._UpdateData(Array.from(permisosScheme.values()));
                            controlTree._RefreshView();
                            break;
                    }
                }
                control["_UpdatePermisosFormUI"] = AplicarPermisosConfig;
            }
        }
        AppendUI(1, CFormModels.Nombre);
        AppendUI(1, CFormModels.ApPaterno);
        AppendUI(1, CFormModels.ApMaterno);
        // AppendUI(1, CFormModels.Escuela);
        AppendUI(1, CFormModels.Perfil);
        AppendUI(1, CFormModels.Correo);
        AppendUI(1, CFormModels.Contrasenia);
        AppendUI(1, CFormModels.Contrasenia2);
        AppendUI(1, CFormModels.ExtraConfig);
        AppendUI(3, CFormModels.Telefono);
        AppendUI(4, CFormModels.IdEscuela);
        AppendUI(2, CFormModels.Permisos);
    }
    //#endregion

    // **********************************************************************
    // GRID CONFIGURACIONES
    // **********************************************************************

    //#region

    protected GRID_GetTableConfigBase(): IGridRenderInfo<IUsuario> {
        return {
            IdTabla: "Usuarios",
            Title: "",
            DefaultSort: "Nombre",
            IdData: "IdUsuario",
            MinWidth: 500,
            Columns: [
                { Field: "NombreCompleto", Label: "Nombre", Width: "30%", MinWidth: "100px" },
                { Field: "Correo", Label: "Correo", Width: "23%", MinWidth: "100px" },
                { Field: "getEscuelaNombres", LabelLangKey: "d_field_strescuela", Label: "Escuelas", Width: "23%", MinWidth: "100px" },
                { Field: "StrPerfil", Label: "Perfil", Width: "23%", MinWidth: "100px" }
            ]
        }
    }

    protected GRID_GetTableConfigAdvanced(): IGridExtraTableConfig<IUsuario> {
        return {
            EvaluatorAndSubLevelsBuild: {
                OnStepCellTable: (container, usuarioDato, field: keyof IUsuario) => {
                    switch (field) {
                        case "Correo":
                            CopyElementTextControl._AddCopyTextBehaviour(container.node(), () => usuarioDato[field]);
                            break;
                        case "getEscuelaNombres":
                            UIUtilGeneral._CreaElementosLinkeablesV2({
                                Container: container,
                                Data: usuarioDato.Escuelas
                                    .map(idE => DataModuloEscuela._DiccFullEscuelas.get(idE))
                                    .filter(d => d != null),
                                Path: "escuelas/escuelas/panel",
                                GetTag: d => d.Nombre,
                                GetId: d => d.IdKinder
                            })
                            break;
                    }
                }
            }
        }
    }

    protected GRID_GetFilters(): Array<Table.IParametroFiltro<IUsuario>> {
        //let kinderOptions: Array<controlD3.IParametroSimple> = this.TranslateKindersObject("Value", "Label") as Array<controlD3.IParametroSimple>
        return [
            { Field: "Nombre", Label: "Nombre" },
            { Field: "ApPaterno", Label: "Apellido paterno" },
            { Field: "ApMaterno", Label: "Apellido materno" },
            { Field: "Correo", Label: "Correo" },
            //{ Field: "IdKinder", Label: "Escuela", OptionsDefault: kinderOptions },
            { Field: "Perfil", LabelLangKey: "d_field_strperfil", Type: "select", Options: this.GetUserList() },
        ]
    }

    protected GRID_GetDataRequestID(): DataModuloMain.TipoRequestMonitorId {
        return Entidad.CTipoRequest.Usuario;
    }

    protected GRID_GetMenuTopGrid(): Table.ITableMenuTopDefaultOptionConfig[] {
        if (this.GridHasPermisoAccion(CAccionPermiso.Agregar)) {
            return [
                {
                    Label: "Agregar",
                    Callback: () => this.OpenModal_FormularioAgregar()
                }
            ]
        } else {
            return [];
        }
    }

    protected GRID_GetSelectionDataMenuV2(menuLocation: "row" | "top-selected", dataGridSelected: IUsuario[]): Table.ITableMenuDataSelectedOptionConfig<IUsuario>[] {
        let acciones: Table.ITableMenuDataSelectedOptionConfig<IUsuario>[] = [];
        if (DataUtil._Usuario.Perfil == CTipoPerfil.Admin) {
            acciones.push({
                MultiData: false,
                Label: "action_recuperarpass",
                Callback: (datoUser) => this.OpenModal_RecuperarContrasenia(datoUser[0])
            })
        }

        if (this.GridHasPermisoAccion(CAccionPermiso.Editar)) {
            acciones.push({
                Label: "Editar",
                Callback: (datoUser) => this.OpenModal_FormularioEditar(datoUser[0]),
                MultiData: false
            })
        }
        if (this.GridHasPermisoAccion(CAccionPermiso.Eliminar)) {
            acciones.push({
                Label: "Eliminar",
                Callback: (datoUser) => this.OpenModal_EliminarDatos(datoUser),
            })
        }

        return acciones;
    }

    //#endregion

    // **********************************************************************
    // FORM COSAS
    // **********************************************************************

    //#region

    private OpenModal_FormularioAgregar() {
        this.GridOpenModal_ActionFormToAGridData<IUsuarioForm>({
            Action: CAccionPermiso.Agregar,
            GetForm: (content) => this.GetForm(d3.select(content.node().parentElement.parentElement as HTMLDivElement), CAccionPermiso.Agregar),
            OnAccept: async (form, mt) => {
                let res = await this.Sv_RegistrarUsuario({
                    ...form._DataOrigin,
                    ...form._Data
                })
                if (res.Resultado > 0 && form._Data.ExtraConfig) {
                    interface DataProcess {
                        Resultado: number,
                        ItemData: request,
                        TipoRequest: Entidad.CTipoRequest
                    }
                    interface request {
                        Name: string;
                        Type: string
                    }
                    const dataToProccess: DataProcess[] = [
                        {
                            Resultado: -1,
                            ItemData: { Name: this.VB_GetUIStringModule("tag_add_teacher"), Type: "Maestro" },
                            TipoRequest: Entidad.CTipoRequest.Maestro
                        },
                        {
                            Resultado: -1,
                            ItemData: { Name: this.VB_GetUIStringModule("tag_add_tutor"), Type: "Tutor" },
                            TipoRequest: Entidad.CTipoRequest.Tutor
                        }
                    ]
                    await ModalThings._ProccessServiceByServiceFromAArrayIterator(
                        mt,
                        dataToProccess,
                        {
                            OnStepAProccess: (itemData) => {
                                switch (itemData.Type) {
                                    case "Maestro":
                                        return DataModuloMaestro._NuevoRegistro(<Entidad.IMaestro & { Contrasenia: string }>{
                                            Nombre: form._Data.Nombre,
                                            ApPaterno: form._Data.ApPaterno,
                                            ApMaterno: form._Data.ApMaterno,
                                            Telefono: form._Data.Telefono,
                                            IdEscuela: form._Data.IdEscuela,
                                            EntradaSalidaEscuela: false,
                                            Correo: form._Data.Correo,
                                            Contrasenia: form._Data.Contrasenia,
                                        })
                                        break;
                                    case "Tutor":
                                        return DataModuloTutor._Insert(<Entidad.ITutor>{
                                            Nombre: form._Data.Nombre,
                                            ApPaterno: form._Data.ApPaterno,
                                            ApMaterno: form._Data.ApMaterno,
                                            Correo: form._Data.Correo,
                                            Contrasenia: form._Data.Contrasenia,
                                            Telefono: form._Data.Telefono
                                        }, [])
                                        break;
                                    default:
                                        return null
                                }
                            },
                            OnError_GetItemDataTag: (itemData) => itemData.Name,
                            OnEvalRetry: (results) => {
                                const resultsFiltered = results.filter(d => {
                                    if ((d.ItemData.Type == "Maestro" && d.Resultado == -1) || (d.ItemData.Type == "Tutor" && d.Resultado == -2)) {
                                        return false;
                                    }
                                    return true;
                                });
                                return resultsFiltered.length > 0;
                            },
                            OnEndProccess: (results) => {
                                for (const result of results) {
                                    if (result.Resultado > 0) {
                                        MainPage._ReloadService(result.TipoRequest)
                                    }
                                }
                            },
                            Action: CAccionPermiso.Agregar,
                            GeneralErrMessage: this.VB_GetUIStringModule("tag_create_extrausers")
                        },
                        false
                    )
                }
                return res;
            }
        });
    }

    private OpenModal_FormularioEditar(usuarioDato: IUsuario) {
        this.GridOpenModal_ActionFormToAGridData<IUsuarioForm>({
            Action: CAccionPermiso.Editar,
            GetForm: (content) => this.GetForm(d3.select(content.node().parentElement.parentElement as HTMLDivElement), CAccionPermiso.Editar, usuarioDato),
            DrawContent: (content, form, modalThings) => {
                modalThings.Progress.attr("oculto", false);
                this.Sv_GetMapPermisosUsuarioV2(usuarioDato)
                    .then(permisosSchemes => {
                        modalThings.Progress.attr("oculto", true);

                        if (!permisosSchemes) {
                            this.notificacion._Mostrar(this.VB_GetUIStringModule("notif_failpermissionreq"), Notificacion.CTipoNotificacion.ADVERTENCIA);
                        }

                        form._DataOrigin.Permisos.clear();
                        permisosSchemes?.PermisosInMap?.forEach((d, k) => form._DataOrigin.Permisos.set(k, d));

                        form._DataOrigin.PermisosOrigin = permisosSchemes?.PermisosInArray ? permisosSchemes.PermisosInArray : [];

                        form._ControlsData.get(CFormModels.Permisos)["_UpdatePermisosFormUI"](form._DataOrigin);
                    })
            },
            OnAccept: (form) => this.Sv_ActualizarUsuario({
                ...form._DataOrigin,
                ...form._Data
            })
        });
    }

    private OpenModal_EliminarDatos(datos: IUsuario[]) {
        this.GridOpenModal_ProccessArrayData({
            DataToProccess: datos,
            OnGetIdEscuela: (dato) => dato.Escuelas,
            OnError_GetItemDataTag: (dato) => `${dato.Nombre} ${dato.ApPaterno} ${dato.ApMaterno}`,
            OnStepAProccess: (dato) => this.Sv_EliminarUsuario(dato)
        });
    }

    private OpenModal_RecuperarContrasenia(usuario: IUsuario) {
        interface IRecuperacionContrasenia {
            Tipo: "email" | "phonenumber";
            Telefono: string;
        }
        ModalThings._GetModalToForm<IRecuperacionContrasenia>({
            Title: this.VB_GetUIStringModule("frm2_header").replace("_USER", (usuario.Nombre + " " + usuario.ApPaterno)),
            Width: 350,
            GetForm: () => {
                let form = new FormGenerator<IRecuperacionContrasenia>()
                form._Crear({
                    LangModuleKeyInContext: this.labelsKeyBase,
                    schema: [
                        {
                            model: "Tipo",
                            type: "radioList",
                            labelText: "frm2_mtdoenvio",
                            radioListAttr: {
                                ValueMember: "valor",
                                DisplayMember: "titulo",
                                required: true,
                                ReturnOnlyValueMember: true,
                                OnChange: (value: { titulo: string, valor: string }) => {
                                    let telefonoRow = form._ControlsData.get("Telefono")
                                    telefonoRow.row.classed("hide", !(value.valor == "phonenumber"));
                                    (telefonoRow.selection.node() as HTMLInputElement).required = (value.valor == "phonenumber");
                                    if (value.valor == "phonenumber") {
                                        (telefonoRow.selection.node() as HTMLInputElement).focus();
                                    }
                                },
                                Data: [
                                    { titulo: this.VB_GetUIStringModule("d_field_correo"), valor: "email" },
                                    { titulo: this.VB_GetUIStringModule("tag_telefono"), valor: "phonenumber" }
                                ],
                            }
                        },
                        {
                            model: "Telefono",
                            type: "input",
                            labelText: "tag_telefono",
                            inputAttr: { type: "phone_10A" },
                            onValidate: (value) => {
                                const telsplit = value.split(" ");
                                const tel = telsplit.length == 1 ? telsplit[0] : telsplit[1];
                                return UIUtilStrings._TrimStart(tel, "0").length == tel.length;
                            },
                        }
                    ],
                }, <IRecuperacionContrasenia>{});

                let phoneCtrl = form._ControlsData.get("Telefono");
                phoneCtrl.row.classed("hide", true);

                return form;
            },
            OnAccept: (form) => {
                let formDato = form._Data;
                return this.Sv_EnviarContrasenia(usuario, formDato.Tipo, formDato.Telefono);
            }
        })
    }

    protected GRID_GetExportarConfig(dataGrid: IUsuario[]): IConfigGridExcelExport<IUsuario> {
        let idsEscuelas: number[] = [];
        dataGrid.forEach(d => {
            idsEscuelas.push(...d.Escuelas);
        });

        return {
            IdsEscuelas: [...new Set(idsEscuelas)],
            ColumnsConfig: this.ctrlTabla
                ._InfoColumns
                .map<ExcelThings.IColumnToExcelExportFileConfig<IUsuario>>((d) => ({
                    Field: d.Field as keyof IUsuario,
                    HeaderTag: d.Label,
                    WidthCell: d.Field == "Nombre" ? 30 : 25
                })),
            OnGetDataBySheets: async () => {
                return [<ExcelThings.ISheetConfig<IUsuario>>{
                    IdSheet: 0,
                    SheetName: UIUtilViewData._GetStr_Modulo(this.modulo),
                    Data: dataGrid
                }]
            },
            OnGetEscuelasTagInSheet: (dataSheet) => "",
        }
    }

    /** Retorna true si los permisos de a escuela están completos, para comprobar permisos de SuperUser */
    private SuperUserValidInSchool(permisosEscuela: Map<number, IPermisoDisponible>) {
        if (permisosEscuela.size == DataModuloPermisos._PermisosDisponiblesMap.size) {
            return true;
        }
        return false
    }

    /** control class = "treeview_container" */
    private GetPermisosTreeControl(container: TSelectionHTML<"div">, form: FormGenerator<IUsuarioForm>, user: IUsuarioForm) {

        /** Configuración del nivel 2 en adelante */
        const GetTreeLevelConfig = (level: number) => {
            let levelConfig: TreeViewV2.ILevelConfig<IItemModuloPermiso, any> = {
                IdMember: "Id",
                OnGetData: (parent: TreeViewBase.ITreeItemToShare<IItemModuloPermiso, IItemModuloPermiso, IItemModuloPermiso>) => {
                    let item = parent.Data;
                    let childs: IItemModuloPermiso[] = [];
                    if (item.ChildsModulosPermisos?.size > 0 || item.ChildsAcciones?.size > 0) {
                        item.ChildsAcciones?.forEach(accion => childs.push(accion));
                        item.ChildsModulosPermisos?.forEach(modulo => childs.push(modulo));
                    } else {
                        if (!item.AccionName)
                            console.warn("-d", "El padre no tiene hijos", item);
                    }

                    switch (level) {
                        case 2:
                            childs = childs.sort((a, b) => d3.ascending(a.ModuloName.toLowerCase(), b.ModuloName.toLowerCase()))
                            break;
                        case 3:
                            childs = childs.sort((a, b) => d3.ascending((a.AccionName == "Ver" ? a.ModuloName.toLowerCase() : "1_" + a.AccionName.toLowerCase()), (b.AccionName == "Ver" ? b.ModuloName.toLowerCase() : "1_" + b.AccionName.toLowerCase())));
                            break;
                        case 4:
                            childs = childs.sort((a, b) => d3.ascending(a.AccionName, b.AccionName))
                            break;
                    }
                    return childs;
                },
                OnGetHasCheckBox: (item) => {
                    return Boolean(item.PermisoDisponible);
                },
                OnGetInitCheckStatus: (item) => {
                    // console.log(userPermisos.has(item.PermisoDisponible.IdPermiso), item, "Si deberia de estar checkeado");
                    return Boolean(user.Permisos.get(item.Escuela.IdKinder)?.get(item.PermisoDisponible.IdPermiso));
                },
                UI_OnUpdateItem: (container, dato) => {
                    let str = "";
                    if (dato.ModuloName) {
                        str = dato.ModuloName;
                    } else if (dato.AccionName) {
                        str = dato.AccionName;
                    } else {
                        str = "Error!!!";
                        container.select(".treeitem_contentdata")
                            .style("color", "red");
                    }
                    container.select(".treeitem_contentdata")
                        .style("color", null)
                        .text(str);
                },
                OnCheckAItem: (item, checked) => {
                    const permisosEscuela = user.Permisos.get(item.Escuela.IdKinder);
                    if (checked) {
                        if (!permisosEscuela) user.Permisos.set(item.Escuela.IdKinder, new Map());
                        user.Permisos.get(item.Escuela.IdKinder).set(item.PermisoDisponible.IdPermiso, item.PermisoDisponible);
                    }
                    else if (permisosEscuela) {
                        permisosEscuela.delete(item.PermisoDisponible.IdPermiso);
                        if (!permisosEscuela.size) user.Permisos.delete(item.Escuela.IdKinder);
                    }
                    // console.debug(user.Permisos, "permisos de user");
                }
            }
            return levelConfig;
        }

        let controlTree = new TreeViewV2.TreeViewControl<[IItemModuloPermiso, IItemModuloPermiso, IItemModuloPermiso, IItemModuloPermiso]>()
            ._SetConfig({
                ParentContainer: container,
                LevelsConfig: [
                    // LEVEL 1 - Escuelas
                    {
                        IdMember: "Id",
                        OnGetData: () => [],
                        UI_OnUpdateItem: (container, dato) => {
                            let ui_content = container.select(".treeitem_contentdata")
                                .style("font-weight", "bold");
                            // let ui_logo = ui_content.select("treeitem_logo");
                            // let ui_nombre = ui_content.select("treeitem_nombre");

                            ui_content.text(dato.Escuela.Nombre);
                        },
                        OnGetInitCheckStatus: (item) => {
                            return Boolean(user.Permisos.get(item.Escuela.IdKinder)?.size);
                        },
                        OnCheckAItem: (item, checked) => {
                            if (!checked)
                                user.Permisos.delete(item.Escuela.IdKinder);
                            else if (!user.Permisos.has(item.Escuela.IdKinder))
                                user.Permisos.set(item.Escuela.IdKinder, new Map());
                            // console.debug(user.Permisos, "permisos de user");
                        }
                    },
                    // LEVEL 2 - Modulos principales
                    GetTreeLevelConfig(2),
                    // LEVEL 3 - Modulos secundarios (paneles) y Acciones del Modulo principal
                    GetTreeLevelConfig(3),
                    // LEVEL 4 - Acciones de los modulos secundarios (paneles)
                    GetTreeLevelConfig(4),
                ]
            });

        return controlTree;
    }

    // **********************************************************************
    // DATOS Y SERVICIOS
    // **********************************************************************

    private Sv_RegistrarUsuario(usuario: IUsuarioForm) {
        let permisos = this.GetPermisosArrayPrepareToService(usuario.Perfil, usuario.Permisos);
        console.log(permisos, "Permisos to INSERT");
        return DataModuloUsuario._AgregarUsuarioV2(usuario, permisos);
    }

    private Sv_ActualizarUsuario(usuario: IUsuarioForm) {
        const permisosInit = usuario.PermisosOrigin;
        const permisosFinMap = usuario.Permisos;

        let permisosToDelete: number[] = [];
        let permisosNewArray: IItemPermisoToInsertBase[] = [];

        // Si es de Perfil == Admin, no agrega ni remueve permisos, un perfil Admin ignora cualquier permiso especifico
        if (usuario.Perfil != CTipoPerfil.Admin) {
            // -> Llena permisosNew con todos los permisos finales configurados. NOTE: Los permisos incompletos deben ser ignorados en caso de SuperUsuario
            let permisosCompletosNewMap: Map<number, Map<number, IPermisoDisponible>> = new Map();
            let permisosInCompletosNewMap: Map<number, Map<number, IPermisoDisponible>> = new Map();

            permisosFinMap.forEach((escuelaAsignaciones, idEscuela) => {
                if (this.SuperUserValidInSchool(escuelaAsignaciones)) {
                    permisosCompletosNewMap.set(idEscuela, new Map(Array.from(escuelaAsignaciones.values()).map(d => ([d.IdPermiso, d]))));
                }
                else {
                    permisosInCompletosNewMap.set(idEscuela, new Map(Array.from(escuelaAsignaciones.values()).map(d => ([d.IdPermiso, d]))));
                }
            })

            permisosToDelete = permisosInit
                .filter((permisoAsignadoInit) => {
                    if (permisoAsignadoInit.IdAsignacionPermiso < 0) {
                        return false;
                    }
                    // -> Remueve todos los permisos que coincidan con los permisos iniciales, los permisos restantes son nuevos
                    if (permisosCompletosNewMap.has(permisoAsignadoInit.IdEscuela)) {
                        permisosCompletosNewMap.get(permisoAsignadoInit.IdEscuela).delete(permisoAsignadoInit.IdPermiso);
                        if (permisosCompletosNewMap.get(permisoAsignadoInit.IdEscuela)?.size == 0) {
                            permisosCompletosNewMap.delete(permisoAsignadoInit.IdEscuela);
                        }
                    } else if (permisosInCompletosNewMap.has(permisoAsignadoInit.IdEscuela)) {
                        if (permisosInCompletosNewMap.get(permisoAsignadoInit.IdEscuela).delete(permisoAsignadoInit.IdPermiso)) {
                            if (permisosInCompletosNewMap.get(permisoAsignadoInit.IdEscuela)?.size == 0) {
                                permisosInCompletosNewMap.delete(permisoAsignadoInit.IdEscuela);
                            }
                            if (usuario.Perfil == CTipoPerfil.SuperUsuario) {
                                // En caso de cambiar de LimitUser a SuperUser, los permisos incompletos anteriores (válidos en LimitUser) son considerados inválidos
                                return true;
                            }
                        }
                    }

                    // -> Busca el permiso inicial, en el mapa de permisos finales
                    if (!(permisosFinMap.get(permisoAsignadoInit.IdEscuela)?.has(permisoAsignadoInit.IdPermiso))) {
                        // -> Si un permiso inicial no está en el mapa final de la configuración, es un permiso removido
                        return true;
                    }
                    return false;
                })
                .map(d => { // NOTE COMENTAR TO TESTS
                    return d.IdAsignacionPermiso
                });

            // -> Ajusta los permisos nuevos para el servicio
            if (usuario.Perfil == CTipoPerfil.SuperUsuario) {
                permisosCompletosNewMap
                    .forEach((permisosEsc, idEscuela) => {
                        if (!permisosInit.find(d => (d.IdEscuela == idEscuela))) {
                            let permisoChido = Array.from(permisosEsc.values())
                                .find(d => (d.IdModulo == CModulo.Escuelas && d.IdAccion == CAccionPermiso.Ver));

                            permisosNewArray
                                .push({
                                    IdEscuela: idEscuela,
                                    IdPermiso: permisoChido.IdPermiso
                                })
                        }
                    })
            }
            else if (usuario.Perfil == CTipoPerfil.Limitado) {
                permisosCompletosNewMap.forEach((permisosEsc, idEscuela) => {
                    permisosEsc.forEach(permiso => {
                        permisosNewArray.push({
                            IdEscuela: idEscuela,
                            IdPermiso: permiso.IdPermiso
                        })
                    })
                })
                permisosInCompletosNewMap.forEach((permisosEsc, idEscuela) => {
                    permisosEsc.forEach(permiso => {
                        permisosNewArray.push({
                            IdEscuela: idEscuela,
                            IdPermiso: permiso.IdPermiso
                        })
                    })
                })
            }
            // permisosNewArray = this.GetPermisosArrayPrepareToService(usuario.Perfil, permisosCompletosNewMap)
            //     .map(d => ({
            //         IdEscuela: d.IdEscuela,
            //         IdPermiso: d.IdPermiso
            //     }))

            // NOTE DESCOMENTAR TO TESTS
            // let ppNew = permisosNewArray.map(d => ({
            //     PDis: data.modulos.Usuario.permisosDisponiblesMap.get(d.IdPermiso),
            //     DREAL: d,
            //     Modulo: data.modulos.Usuario.permisosModulosMap.get(d.IdModulo).Nombre,
            //     Accion: data.modulos.Usuario.permisosAccionesMap.get(d.IdAccion).Nombre,
            //     Esc: data.modulos.Kinder.DiccKinder.get(d.IdEscuela).Nombre
            // }));
            // let ppDel = permisosToDelete.map(d => ({
            //     PDis: data.modulos.Usuario.permisosDisponiblesMap.get(d.IdPermiso),
            //     DREAL: d,
            //     Modulo: data.modulos.Usuario.permisosModulosMap.get(data.modulos.Usuario.permisosDisponiblesMap.get(d.IdPermiso).IdModulo).Nombre,
            //     Accion: data.modulos.Usuario.permisosAccionesMap.get(data.modulos.Usuario.permisosDisponiblesMap.get(d.IdPermiso).IdAccion).Nombre,
            //     Esc: data.modulos.Kinder.DiccKinder.get(d.IdEscuela).Nombre
            // }))
            // console.log(permisosNewMap, ppNew, ppDel, "Permisos to UPDATE PREPARE");
        }
        console.log(permisosNewArray, permisosToDelete, "Permisos to UPDATE PREPARE")

        return DataModuloUsuario._ActualizarUsuarioV2(usuario, permisosNewArray, permisosToDelete);
    }

    private Sv_EliminarUsuario(usuario: IUsuario) {
        return DataModuloUsuario._EliminarUsuario(usuario.IdUsuario);
    }

    /** Prepara un Arreglo de permisos relacionados Escuela-Permiso */
    private GetPermisosArrayPrepareToService(tipoPerfil: CTipoPerfil, permisosMap: Map<number, Map<number, IPermisoDisponible>>) {
        let permisos: IItemPermisoToInsert[] = [];

        // -> Procesa esquema de permisos, para formar un arreglo válido para enviar al servicio
        if (tipoPerfil != CTipoPerfil.Admin) {
            for (let [idEscuela, permisosEscuela] of permisosMap) {

                if (tipoPerfil == CTipoPerfil.SuperUsuario) {
                    if (this.SuperUserValidInSchool(permisosEscuela)) {
                        // Basta con tomar solo una asignación para darla de alta
                        let permisoChido = Array.from(permisosEscuela.values())
                            .find(d => (d.IdModulo == CModulo.Escuelas && d.IdAccion == CAccionPermiso.Ver));

                        permisos.push({
                            IdEscuela: idEscuela,
                            IdModulo: permisoChido.IdModulo,
                            IdAccion: permisoChido.IdAccion,
                            IdPermiso: permisoChido.IdPermiso
                        });
                    } else {
                        // En caso de ser super usuario, ignora los permisos de la escuela que no los tenga completos
                        continue;
                    }
                } else {
                    permisosEscuela.forEach(permiso => {
                        permisos.push({
                            IdEscuela: idEscuela,
                            IdModulo: permiso.IdModulo,
                            IdAccion: permiso.IdAccion,
                            IdPermiso: permiso.IdPermiso
                        });
                    });
                }
            }
        }
        return permisos;
    }

    private async Sv_GetMapPermisosUsuarioV2(usuario: IUsuario) {
        let res = await DataModuloUsuario._ObtenerPermisosUsuario(usuario.IdUsuario);
        let permisosMap: Map<number, Map<number, IPermisoDisponible>>;
        if (res.Resultado > 0) {
            permisosMap = new Map();
            const escuelasConPermisos = this.GridGetEscuelasConPermisoDeAccion(CAccionPermiso.Editar);

            res.Datos = res.Datos.filter(dP => escuelasConPermisos.find(dE => (dE.IdKinder == dP.IdEscuela)));

            DataUtilPermission._CreateTemporal_AllMissingPermissions(res.Datos, Array.from(DataModuloPermisos._PermisosDisponiblesMap.values()), usuario.IdUsuario, usuario.Perfil)
                .forEach(permisoAsignado => {
                    let permisoDisponibleFound = DataModuloPermisos._PermisosDisponiblesMap.get(permisoAsignado.IdPermiso);
                    if (permisoDisponibleFound) {
                        let permisosEscuela = permisosMap.get(permisoAsignado.IdEscuela);
                        if (!permisosEscuela) {
                            permisosEscuela = new Map();
                            permisosMap.set(permisoAsignado.IdEscuela, permisosEscuela);
                        }
                        permisosEscuela.set(permisoAsignado.IdPermiso, {
                            IdPermiso: permisoDisponibleFound.IdPermiso,
                            IdAccion: permisoDisponibleFound.IdAccion,
                            IdModulo: permisoDisponibleFound.IdModulo,
                        })
                    } else {
                        console.warn("-d", "Permiso disponible no encontrado!!!", permisoAsignado);
                    }
                });

            console.log(permisosMap, "GetMapPermisosUsuarioV2");
            return {
                PermisosInArray: res.Datos,
                PermisosInMap: permisosMap
            };
        }
        return null;
    }

    /** Retorna un mapa de los permisos en estructura de árbol Map<IdEscuela, Map<IdModulo, Map<IdAccion, @interface Entidades.IPermisosAccion>>> */
    private GetPermisosDiponiblesMapScheme(accion: CAccionPermiso, formData?: IUsuarioForm): Map<number, IItemModuloPermiso> {
        // return new Promise(async resolve => {
        // let permisosRes = await data.modulos.Usuario.fn_ObtenerPermisosDisponibles();

        /** Mapa de los permisos en estructura de árbol Map<Id-modulo, Permiso-procesado> */
        let resultPermisosScheme: Map<number, IItemModuloPermiso> = new Map();

        // if (permisosRes.Resultado > 0) {
        const permisosDisponiblesMap = DataModuloPermisos._PermisosDisponiblesMap; //  new Map<number, IPermisoDisponible>();
        const accionesDisponiblesMap = DataModuloPermisos._PermisosAccionesMap; // new Map<number, IPermisoAccion>();
        const modulosDisponiblesMap = DataModuloPermisos._PermisosModulosMap; // new Map<number, IPermisoModulo>();

        // permisosRes.Data.PermisosDisponibles.forEach(permiso => permisosDisponiblesMap.set(permiso.IdPermiso, permiso));
        // permisosRes.Data.Acciones.forEach(accion => accionesDisponiblesMap.set(accion.IdAccion, accion));
        // permisosRes.Data.Modulos.forEach(modulo => modulosDisponiblesMap.set(modulo.IdModulo, modulo));

        // -> NIVEL 1: Escuelas disponibles
        //this.GridGetEscuelasConPermisoDeAccion(accion)
        this.GridGetEscuelasConPermisoDeAccion(accion).sort((a, b) => d3.ascending(a.Nombre.toLowerCase(), b.Nombre.toLowerCase())).forEach(escuela => {
            /** Rama del nivel 1 */
            let escuelaDisponible: IItemModuloPermiso = {
                Id: "e-" + escuela.IdKinder,
                Escuela: escuela,
                ModuloName: null,
                ParentModuloPermiso: null,
                ChildsModulosPermisos: new Map(),
                ChildsAcciones: new Map()
            }
            resultPermisosScheme.set(escuela.IdKinder, escuelaDisponible);

            // -> NIVEL 2,... : Modulos y submodulos disponibles por escuela (Todas las escuelas tienen los mismos modulos disponibles)
            const SearchOrCreateModuleBranch: ((idModulo: Entidad.CModulo) => IItemModuloPermiso) = (idModulo: Entidad.CModulo) => {
                if (!UIUtilPermission._HasModulePermission(idModulo, escuela.IdKinder) || !UIUtilPermission._IsSchoolModuleInUse(escuela.IdKinder, idModulo))
                    return null;
                const moduloInfo = modulosDisponiblesMap.get(idModulo);
                if (!moduloInfo) {
                    console.warn("-d", "Error: module no found");
                    return null;
                }
                if (!Array.from(permisosDisponiblesMap.values()).find(d => (d.IdModulo == idModulo && d.IdAccion == CAccionPermiso.Ver))) {
                    console.warn("-d", "Warn: module do not have Ver action", escuela.IdKinder, moduloInfo);
                    return null;
                }
                if (moduloInfo.IdPadre) {
                    let moduloPadre = SearchOrCreateModuleBranch(moduloInfo.IdPadre);
                    if (moduloPadre) {
                        let moduloDisponible = moduloPadre.ChildsModulosPermisos.get(idModulo);
                        if (!moduloDisponible && UIUtilViewData._GetStr_Modulo(moduloInfo.IdModulo)) {
                            moduloDisponible = {
                                Id: "m-" + idModulo,
                                Escuela: escuela,
                                ModuloName: UIUtilViewData._GetStr_Modulo(moduloInfo.IdModulo), // moduloInfo.Nombre,
                                ChildsAcciones: new Map(),
                                ParentModuloPermiso: moduloPadre,
                                ChildsModulosPermisos: new Map()
                            }
                            moduloPadre.ChildsModulosPermisos.set(idModulo, moduloDisponible);
                        }
                        return moduloDisponible;
                    }
                    console.warn("-d", "Modulo permiso padre no found");
                } else {
                    let moduloDisponible = escuelaDisponible.ChildsModulosPermisos.get(idModulo);
                    if (!moduloDisponible && UIUtilViewData._GetStr_Modulo(moduloInfo.IdModulo)) {
                        moduloDisponible = {
                            Id: "m-" + idModulo,
                            Escuela: escuela,
                            ModuloName: UIUtilViewData._GetStr_Modulo(moduloInfo.IdModulo), // moduloInfo.Nombre,
                            ChildsAcciones: new Map(),
                            ParentModuloPermiso: escuelaDisponible,
                            ChildsModulosPermisos: new Map()
                        }
                        escuelaDisponible.ChildsModulosPermisos.set(idModulo, moduloDisponible);
                    }
                    return moduloDisponible;
                }
                return null;
            }

            permisosDisponiblesMap.forEach((permisoDis, idPermisoDis) => {
                if (formData.Perfil == Entidad.CTipoPerfil.Limitado && permisoDis.IdModulo == Entidad.CModulo.Usuarios) {
                    return;
                }
                let accionDisponible = accionesDisponiblesMap.get(permisoDis.IdAccion);
                if (accionDisponible) {
                    let currentModule = SearchOrCreateModuleBranch(permisoDis.IdModulo)
                    if (currentModule) {
                        if (UIUtilPermission._HasAccionPermission(permisoDis.IdAccion, permisoDis.IdModulo, escuela.IdKinder)) {
                            if (accionDisponible.IdAccion == CAccionPermiso.Ver) {
                                // La acción de "Ver" está al nivel del modulo
                                currentModule.Id += "-a-" + accionDisponible.IdAccion
                                currentModule.AccionName = UIUtilViewData._GetStr_Accion(accionDisponible.IdAccion); // accionDisponible.Nombre;
                                currentModule.PermisoDisponible = permisoDis;
                            } else {
                                // El resto de acciones son "desplegables" del modulo al que pertenecen
                                let itemAccion: IItemModuloPermiso = {
                                    Id: "a-" + accionDisponible.IdAccion,
                                    Escuela: escuela,
                                    ChildsAcciones: null,
                                    ParentModuloPermiso: currentModule,
                                    ChildsModulosPermisos: null,
                                    ModuloName: null, //currentModule.ModuloName,
                                    AccionName: UIUtilViewData._GetStr_Accion(accionDisponible.IdAccion), // accionDisponible.Nombre,
                                    PermisoDisponible: permisoDis
                                }
                                currentModule.ChildsAcciones
                                    .set(permisoDis.IdAccion, itemAccion);
                            }
                        }
                    } else {
                        console.warn("-d", "Error: Current module no found");
                    }
                } else {
                    console.error("Error: Acción no Found", permisoDis.IdAccion);
                }
            })
        })
        return resultPermisosScheme;
    }

    private GetUserList() {
        return UIUtilViewData._GetList_PerfilUser()
            .filter(d => (d.Id > DataUtil._Usuario.Perfil));
    }

    private async Sv_EnviarContrasenia(usuario: IUsuario, type: "email" | "phonenumber", phonenumber: string) {
        let res: DataDRequest.IResultadoPeticion<undefined>;
        if (type == "email") {
            res = await DataModuloUsuario._RecuperarContraseniaViaCorreo(usuario.IdUsuario);
        } else if (type == "phonenumber") {
            res = await DataModuloUsuario._RecuperarContraseniaViaSMS(usuario.IdUsuario, phonenumber);
        }

        return res;
    }
}
