import { timeFormat } from "d3";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import DataModuloEscuela from "../../data/modulo/Escuela";
import { DataUtilAlertBot } from "../../data/util/AlertBot";
import { DateV2 } from "../../util/DateV2";
import { UIUtilLang } from "./Language";
import { UIUtilStrings } from "./Strings";
import { UIUtilGeneral } from "./Util";

export namespace UIUtilTime {

    function GetValidDate(date: (string | Date)): (Date | null) {
        if (
            (typeof date == "string" && date.trim())
            || (date instanceof Date && !isNaN(Number(date)))
        ) {
            return new Date(date);
        }
        return null;
    }

    export function _GetValidDate(date: (string | Date)): (Date | null) {
        return GetValidDate(date);
    }

    /** @returns Number("yyyy" + "mm") - Numero de 6 digitos, concatenación de los 4 dig. del año y 2 dig. del mes */
    export function _GetValidatorDateYM(date: Date): number {
        let splitedDate = _DateFormatStandar(date, "d3_dd/mm/yyyy").split("/");
        return Number(splitedDate[2] + splitedDate[1]);
    }

    /** @returns Number("yyyy" + "mm" + "dd") - Numero de 8 digitos, concatenación de los 4 dig. del año, los 2 dig. del mes y los 2 del día */
    export function _GetValidatorDateYMD(date: Date): number {
        let splitedDate = _DateFormatStandar(date, "d3_dd/mm/yyyy").split("/");
        return Number(splitedDate[2] + splitedDate[1] + splitedDate[0]);
    }

    export function _GetValidatorDateYMDV2(date: Date): number {
        return Number(date.getFullYear() + "" + date.getMonth() + "" + date.getDate())
    }

    interface IFmtToInputDateOptions {
        /** Remueve el indicador UTC (`"Z"`) a la cadena para tratar la fecha como local.
         * * Se aplica solo cuando el parámetro `date` es ISO string */
        removeZ?: boolean;
    }
    /**
     * @param date `Date` || UTC string
     * @param options `(string | number)`: Zona horaria o id de escuela | `IFmtToInputDateOptions`: Otras opciones
     * @returns `"yyyy-mm-dd"`
    */
    export function _FmtToInputDate(date: (string | Date), options?: (string | number | IFmtToInputDateOptions)) {
        if (typeof date == "string" && date.includes("/")) {
            DataUtilAlertBot._SendWarn("Date includes '/'");
            console.error("Date have '/'", date);
        }
        if (typeof options == "object" && typeof date == "string" && options.removeZ) {
            date = date.replace("Z", "");
        }
        date = GetValidDate(date);
        if (!date) return "";
        const zonah = (typeof options == "number"
            ? DataModuloMain._GetDataValueFieldByName("Escuela", options, "ZonaHoraria")
            : typeof options == "string" ? options : null);
        if (zonah) date = new DateV2(date)._SetTimeZone(zonah);
        return _DateFormatStandar(date, "d3_dd/mm/yyyy").split("/").reverse().join("-");
    }

    /** @returns `"hh:mm"` */
    export function _FmtToInputTime(date: Date): string { // FIX_TIMEZONE
        return timeFormat("%H:%M")(date);
    }

    /**
     * Retorma un Date valido
     * @param date @example "2023-02-01" -> (yyyy-mm-dd)
     * @returns new Date("01/02/2023")
     */
    export function _GetLocalDateFromInputDateString(date: string) {
        if (!date) {
            throw new Error("Time fail!");
        }
        return new Date(date.split("-").join("/"));
    }

    /** Formateador BASE */
    function GetDateTimeFormat(date: Date, formatOptions: Intl.DateTimeFormatOptions): string {
        // >> Language config
        let language: string = UIUtilLang._GetLanguage();
        if (navigator.language.toLowerCase().startsWith(language)) {
            language = navigator.language;
        }

        // >> Hour cycle
        let localesArgs = "-u";
        if (date.getHours() < 12) {
            localesArgs += "-hc-h11";
        } else {
            localesArgs += "-hc-h12";
        }

        // >>
        return new Intl.DateTimeFormat(language + localesArgs, formatOptions)
            .format(date);
        // return date?.toLocaleDateString(fn_GetLanguage(), formatOptions);
    }

    export type TDateStandarFormats = "d3_dd/mm/yyyy" | "dd/mm/yyyy"
        | "dd/mm/yyyy h24:mm" | "dd/mm/yyyy h12:mm"
        | "h24:mm" | "h12:mm"
        | "MMM yyyy" | "d MMM yyyy" | "d MMM"
        // | "dd d MMM yyyy"
        // | "dd d MMM yyyy h24:mm" | "dd d MMM yyyy h12:mm"
        | "dd/mm/yyyy h24:mm:ss" | "dd/mm/yyyy h12:mm:ss" | "h12:mm:ss" | "h24:mm:ss";

    /**
     * @param date string
     * @param idEscuela
     * @param format @default "dd/mm/yyyy"
     * @returns
     */
    export function _DateFormatStandarFixTimeZoneByIdSchool(date: string, idEscuela: number, format: TDateStandarFormats = "dd/mm/yyyy"): (string | null) {
        let timeZone = DataModuloMain._GetDataValueFieldByName("Escuela", idEscuela, "ZonaHoraria");
        return _DateFormatStandarFixTimeZone(date, timeZone, format);
    }

    /**
     * @param date string
     * @param timeZone @example "America/Monterrey"
     * @param format @default "dd/mm/yyyy"
     * @returns
     */
    export function _DateFormatStandarFixTimeZone(date: string, timeZone: string, format: TDateStandarFormats = "dd/mm/yyyy"): (string | null) {
        if (!timeZone) return null
        let dt = GetValidDate(date);
        if (!dt) return null;
        dt = new DateV2(dt)
            ._SetTimeZone(timeZone)
        return _DateFormatStandar(dt, format);
    }

    /**
     * @param date string | Date
     * @param format @default "dd/mm/yyyy"
     * @returns
     */
    export function _DateFormatStandar(date: (string | Date), format: TDateStandarFormats = "dd/mm/yyyy"): (string | null) {
        let str = "";
        date = GetValidDate(date);

        if (date == null) {
            return null;
        }

        const dateOptions: Intl.DateTimeFormatOptions = {
            year: "numeric",
            month: "2-digit",
            day: "2-digit"
        }

        const hourOptions: Intl.DateTimeFormatOptions = {
            hour: "2-digit",
            minute: "2-digit",
            // second: "2-digit"
        }

        // // NOTE (...Intl.DateTimeFormat(undefined...): undefined para que tome el formato de lenguaje real del sistema

        switch (format) {
            case "d3_dd/mm/yyyy":
                str = timeFormat("%d/%m/%Y")(date);
                break;
            case "dd/mm/yyyy":
                str = GetDateTimeFormat(date, dateOptions);
                break;
            case "MMM yyyy":
                dateOptions.day = undefined;
                dateOptions.month = "long";
                str = GetDateTimeFormat(date, dateOptions);
                break;
            case "d MMM yyyy":
                dateOptions.day = "numeric";
                dateOptions.month = "long";
                str = GetDateTimeFormat(date, dateOptions);
                break;
            case "d MMM":
                str = GetDateTimeFormat(date, {
                    day: "numeric",
                    month: "long"
                })
                break;
            // case "dd d MMM yyyy":
            //     dateOptions.day = "numeric";
            //     dateOptions.month = "long";
            //     dateOptions.weekday = "long";
            //     str = fn_GetDateTimeFormat(date, dateOptions);
            //     break;
            // case "dd d MMM yyyy h24:mm":
            // case "dd d MMM yyyy h12:mm":
            //     dateOptions.weekday = "long";
            //     dateOptions.day = "numeric";
            //     dateOptions.month = "long";
            //     // str = fn_GetDateTimeFormat(date, dateOptions);
            //     str = fn_GetDateTimeFormat(date, {
            //         ...{
            //             hour12: (format == "dd d MMM yyyy h12:mm"),
            //         },
            //         ...dateOptions,
            //         ...hourOptions
            //     });
            //     break;
            case "dd/mm/yyyy h24:mm":
            case "dd/mm/yyyy h12:mm":
            case "dd/mm/yyyy h24:mm:ss":
            case "dd/mm/yyyy h12:mm:ss":
                if (format == "dd/mm/yyyy h12:mm:ss" || format == "dd/mm/yyyy h24:mm:ss") {
                    hourOptions.second = "2-digit";
                }
                str = GetDateTimeFormat(date, {
                    ...{
                        hour12: (format == "dd/mm/yyyy h12:mm" || format == "dd/mm/yyyy h12:mm:ss"),
                    },
                    ...dateOptions,
                    ...hourOptions
                });
                break;
            case "h24:mm":
            case "h12:mm":
            case "h12:mm:ss":
            case "h24:mm:ss":
                if (format == "h12:mm:ss" || format == "h24:mm:ss") {
                    hourOptions.second = "2-digit";
                }
                str = GetDateTimeFormat(date, {
                    ...{
                        hour12: (format == "h12:mm" || format == "h12:mm:ss"),
                    },
                    ...hourOptions
                });
                break;
        }

        return str;
    }

    // *********************************************************************
    // 
    // *********************************************************************

    /** Retorna una cadena con el nombre del día con el lenguaje disponible
     * @param day dia requerido
     * @param format longitud del formato de la cadena @default "long"
     * @param capitalizeString true: primer letra mayuscula @default true
     * */
    export function _GetDayName(day: Entidad.CDiaSemanal, format: ("long" | "short" | "narrow") = "long", capitalizeString = true) {
        var baseDate = new Date(2017, 0, 1); // Domingo
        // if (day != data.Entidades.CDiaSemanal.Domingo) {
        baseDate.setDate(baseDate.getDate() + day);
        // }
        const strDay = GetDateTimeFormat(baseDate, { weekday: format });
        return (capitalizeString ? UIUtilStrings._CapitaliceString(strDay) : strDay);
    }

    // Revisar implementación de ZonasHorarias
    export function _GetDayInWeek(fecha: Date | number) {
        let numDay: number;
        if (fecha instanceof Date) numDay = fecha.getDay(); else numDay = fecha;
        if (numDay == 0) {
            return Entidad.CDiaSemanal.Domingo
        } else {
            return numDay;
        }
    }

    /** Retorna un arreglo con los dias de la semana con lenguaje disponible
     * @param format longitud de cada una de las cadenas
     * @param initDay día inicial del arreglo retornado "domingo" o "lunes"
     * @param capitalizeString true: primer letra de cada cadena en mayuscula
    */
    export function _GetWeekDays(format: ("long" | "short" | "narrow") = "long", initDay: ("domingo" | "lunes") = "domingo", capitalizeString = true) {
        var baseDate = new Date(Date.UTC(2017, 0, (initDay == "domingo" ? 2 : 3)));
        var weekDays: string[] = [];
        for (let i = 0; i < 7; i++) {
            const strDay = GetDateTimeFormat(baseDate, { weekday: format });
            weekDays.push(capitalizeString ? UIUtilStrings._CapitaliceString(strDay) : strDay);
            baseDate.setDate(baseDate.getDate() + 1);
        }
        return weekDays;
    }

    /**
     * @param month 0 (Enero), 1 (Febrero), ..., 11 (Diciembre)
     * @param format @default "long"
     * @param capitalizeString @default true
     */
    export function _GetMonthName(month: number, format: ("long" | "short" | "narrow") = "long", capitalizeString = true) {
        const baseDate = new Date(Date.UTC(2000, month, 2));
        const strMonth = GetDateTimeFormat(baseDate, { month: format });

        return (capitalizeString ? UIUtilStrings._CapitaliceString(strMonth) : strMonth);
    }

    /** Retorna un arreglo con nombres de los meses en lenguaje disponible
     * @param format longitud de cada una de las cadenas
     * @param capitalizeString true: primer letra de cada cadena en mayuscula
     * @returns ["Enero", "Febrero"..., "Diciembre"]
    */
    export function _GetYearMonths(format: ("long" | "short" | "narrow") = "long", capitalizeString = true) {
        var baseDate = new Date(Date.UTC(2000, 0, 2));
        var yearMonths: string[] = [];
        for (let i = 0; i < 12; i++) {
            // let strDay = fn_DateFormat(baseDate, { month: format });
            let strMonth = GetDateTimeFormat(baseDate, { month: format });
            yearMonths.push(capitalizeString ? UIUtilStrings._CapitaliceString(strMonth) : strMonth);
            baseDate.setMonth(baseDate.getMonth() + 1);
        }
        return yearMonths;
    }

    /**
     * @param strDate Ej: "2020-01-30"
     * @param strTime "Ej: "20:30"
     * @default "00:00"
     * @returns new Date(strDate + "T" + strTime)
     */
    export function _GetDateConcatenatedDateTime(strDate: string, strTime: string = "00:00") {
        return new Date(strDate + "T" + strTime);
    }

    /**
     * Retorna una cadena en formato humano del tiempo transcurrido entre dos fechas
     * @param { string | Date } startDate Fecha menor
     * @param { string | Date } endDate  Fecha mayor
     * @param { TTimeTypeKey } timeA
     * @param { TTimeTypeKey } timeB
     * @example
     * >> startDate = new Date(2000, 0, 1)
     * >> endDate = new Date(2022, 6, 11)
     * >> timeA = "Year" (default)
     * >> timeB = "Milisecond" (default)
     * << "22 años 6 meses 10 días 0 hrs 0 mins 0 segs 0 mss"
     */
    export function _GetTimeElapsedBFmt(startDate: (string | Date), endDate: (string | Date), timeA: TTimeTypeKey = "Year", timeB: TTimeTypeKey = "Milisecond"): string {
        return _GetTimeElapsedAFmt(startDate, endDate, {
            TimeA: timeA,
            TimeB: timeB,
            IgnoreZeros: "normal"
        })
    }

    interface IGetTimeElapsedFmtOptions {
        /** @default "Year" */
        TimeA?: TTimeTypeKey;
        /** @default "Milisecond" */
        TimeB?: TTimeTypeKey;
        /** 
         * * "normal": Ignora ceros de izq a derecha (Ej.: 0 años (Se ignora), 1 mes, 0 días (No se ignora), ...)
         * * "all": Se ignoran todos los ceros. Permite que retornar una cadena vacía
         * * "allexceptmin": Se ignoran casí todos los ceros. Si todo da 0, retorna el cero de TimeB (Ej.: "0 ms")
         * @default "normal" */
        IgnoreZeros?: "normal" | "all" | "allexceptmin";
    }
    export function _GetTimeElapsedAFmt(startDate: (string | Date), endDate: (string | Date), options: IGetTimeElapsedFmtOptions): string {
        startDate = GetValidDate(startDate);
        endDate = GetValidDate(endDate);

        const timeA = options.TimeA || "Year";
        const timeB = options.TimeB || "Milisecond";
        const ignoreCeros = options.IgnoreZeros || "normal";

        let startTypeTimeIndex = TYPE_TIMES_ORDER.indexOf(timeA);
        let endTypeTimeIndex = TYPE_TIMES_ORDER.indexOf(timeB);
        let i = startTypeTimeIndex;
        let str = "";

        const timeElapsed = _GetTimeElapsedAdvanced(startDate, endDate, timeA, timeB);

        while (i <= endTypeTimeIndex) {
            let typeTime = TYPE_TIMES_ORDER[i];
            let duration = timeElapsed[typeTime];
            let durationStr = duration.toString();
            let include = false;

            if (ignoreCeros == "all") {
                include = duration != 0;
            } else if (ignoreCeros == "normal") {
                include = !(duration == 0 && str == "") || (i == endTypeTimeIndex);
            } else if (ignoreCeros == "allexceptmin") {
                include = duration != 0 || (str == "" && i == endTypeTimeIndex);
            }

            if (include) {
                let sufix: string;
                switch (typeTime) {
                    case "Year":
                        sufix = UIUtilLang._GetUIString("time", (duration == 1 ? "anio" : "anios"));
                        break;
                    case "Month":
                        sufix = UIUtilLang._GetUIString("time", (duration == 1 ? "mes" : "meses"));
                        break;
                    case "Day":
                        sufix = UIUtilLang._GetUIString("time", (duration == 1 ? "dia" : "dias"));
                        break;
                    case "Hour":
                        sufix = UIUtilLang._GetUIString("time", "hr");
                        break;
                    case "Minute":
                        sufix = UIUtilLang._GetUIString("time", "min");
                        break;
                    case "Second":
                        sufix = UIUtilLang._GetUIString("time", "seg");
                        break;
                    case "Milisecond":
                        sufix = UIUtilLang._GetUIString("time", "ms");
                        break;
                }
                str += `${durationStr} ${sufix} `;
            }
            i++;
        }

        return str.trim().toLowerCase();
    }

    // *********************************************************************
    // TIME ADVANCED
    // *********************************************************************

    export enum CDuration {
        // Nanosecond = 1,
        // Microsecond = 1000 * Nanosecond,
        Millisecond = 1,// 1000 * Microsecond,
        Second = 1000 * Millisecond,
        Minute = 60 * Second,
        Hour = 60 * Minute,
        // Day = 24 * Hour
    }

    interface IDuration {
        Miliseconds(): number;
        Seconds(): number;
        Minutes(): number;
        Hours(): number;
        // Days(): number;
    }

    // const MONTHS_IN_YEAR = 12;
    // const DAYS_IN_WEEK = 7;

    /** Retorna equivalencias de tiempo a partir de milisegundos
     * @param ms miliseconds
     */
    export function _GetDurationMsTo(ms: number): IDuration {
        return {
            Miliseconds() {
                return ms; // Math.floor(CDuration.Millisecond * ms);
            },
            Seconds() {
                return Math.floor(ms / CDuration.Second);
            },
            Minutes() {
                return Math.floor(ms / CDuration.Minute);
            },
            Hours() {
                return Math.floor(ms / CDuration.Hour);
            },
            // Days() {
            //     return Math.floor(ms / CDuration.Day);
            // }
        }
    }

    interface ITimeElapsedBase extends IDuration {
        Miliseconds(): number;
        Seconds(): number;
        Minutes(): number;
        Hours(): number;
        Days(): number;
        Months(): number;
        Years(): number;
    }

    /**
     * Provee un objeto con funciones que retornan el tiempo transcurrido entre dos fechas
     * un tipo de tiempo a la vez
     * @param startDate 
     * @param endDate 
     */
    export function _GetTimeElapsedSimple(startDate: Date, endDate: Date): ITimeElapsedBase {
        let diff = endDate.getTime() - startDate.getTime();

        return {
            Miliseconds() {
                return _GetDurationMsTo(diff).Miliseconds();
            },
            Seconds() {
                return _GetDurationMsTo(diff).Seconds();
            },
            Minutes() {
                return _GetDurationMsTo(diff).Minutes();
            },
            Hours() {
                return _GetDurationMsTo(diff).Hours();
            },
            Days() {
                // return fn_GetDurationMsTo(diff).Days();
                const endMs = endDate.getTime();
                let days = 0;
                let auxDate = new Date(startDate);
                let auxDateMs = auxDate.getTime();

                while (auxDateMs <= endMs) {
                    auxDateMs = auxDate.setDate(auxDate.getDate() + 1);

                    if (auxDateMs <= endMs) {
                        days += 1;
                    }
                }

                return days;
            },
            Months() {
                const endMs = endDate.getTime();
                let months = 0;
                let auxDate = new Date(startDate);
                let auxDateMs = auxDate.getTime();

                while (auxDateMs <= endMs) {
                    auxDateMs = auxDate.setMonth(auxDate.getMonth() + 1);

                    if (auxDateMs <= endMs) {
                        months += 1;
                    }
                }

                return months;
            },
            Years() {
                let yearDiff = endDate.getFullYear() - startDate.getFullYear();
                if (startDate.getMonth() <= endDate.getMonth()) {
                    return yearDiff;
                } else {
                    return yearDiff - 1;
                }
            },
        }
    }

    interface ITimeElapsed {
        Year: number;
        Month: number;
        Day: number;
        Hour: number;
        Minute: number;
        Second: number;
        Milisecond: number;
    }
    type TTimeTypeKey = keyof ITimeElapsed;
    // type TTimeTypeKeyLowerCase = Lowercase<keyof ITimeElapsed>;
    const TYPE_TIMES_ORDER: TTimeTypeKey[] = ["Year", "Month", "Day", "Hour", "Minute", "Second", "Milisecond"];

    /**
     * Provee un objeto que representa el tiempo transcurrido entre dos fechas.
     * Toma en cuenta los parametros max y min de tipo de tiempo
     * @param startDate fecha menor
     * @param endDate fecha mayor
     * @param maxTimeType Tipo de tiempo maximo a considerar, si hay otros mayores son ignorados
     * @param minTimeType Tipo de tiempo minimo a considerar, si hay menores son ignorados
     */
    export function _GetTimeElapsedAdvanced(startDate: (string | Date), endDate: (string | Date), maxTimeType: TTimeTypeKey = "Year", minTimeType: TTimeTypeKey = "Milisecond"): ITimeElapsed {
        let minDate = GetValidDate(startDate);
        let maxDate = GetValidDate(endDate);

        const timeElapsed: ITimeElapsed = {
            Year: 0,
            Month: 0,
            Day: 0,
            Hour: 0,
            Minute: 0,
            Second: 0,
            Milisecond: 0
        }

        if (!minDate || !maxDate || minDate > maxDate) {
            return timeElapsed;
        }

        let auxDate = new Date(minDate);

        function GetTimeDiff(timeType: TTimeTypeKey): number {
            let diffDuration = 0;
            let temp_AuxMs = auxDate.getTime();
            let temp_AuxRealFinMs: number;

            switch (timeType) {
                case "Year":
                    diffDuration = _GetTimeElapsedSimple(auxDate, maxDate).Years();
                    temp_AuxRealFinMs = new Date(temp_AuxMs).setUTCFullYear(auxDate.getUTCFullYear() + diffDuration);
                    break;
                case "Month":
                    diffDuration = _GetTimeElapsedSimple(auxDate, maxDate).Months();
                    temp_AuxRealFinMs = new Date(temp_AuxMs).setMonth(auxDate.getMonth() + diffDuration);
                    break;
                case "Day":
                    diffDuration = _GetTimeElapsedSimple(auxDate, maxDate).Days();
                    temp_AuxRealFinMs = new Date(temp_AuxMs).setDate(auxDate.getDate() + diffDuration);
                    break;
                case "Hour":
                    diffDuration = _GetTimeElapsedSimple(auxDate, maxDate).Hours();
                    temp_AuxRealFinMs = new Date(temp_AuxMs).setHours(auxDate.getHours() + diffDuration);
                    break;
                case "Minute":
                    diffDuration = _GetTimeElapsedSimple(auxDate, maxDate).Minutes();
                    temp_AuxRealFinMs = new Date(temp_AuxMs).setMinutes(auxDate.getMinutes() + diffDuration);
                    break;
                case "Second":
                    diffDuration = _GetTimeElapsedSimple(auxDate, maxDate).Seconds();
                    temp_AuxRealFinMs = new Date(temp_AuxMs).setSeconds(auxDate.getSeconds() + diffDuration);
                    break;
                case "Milisecond":
                    diffDuration = _GetTimeElapsedSimple(auxDate, maxDate).Miliseconds();
                    temp_AuxRealFinMs = new Date(temp_AuxMs).setMilliseconds(auxDate.getMilliseconds() + diffDuration);
                    break;
            }
            let difMs = temp_AuxRealFinMs - temp_AuxMs;
            auxDate.setTime(auxDate.getTime() + difMs);

            return diffDuration;
        }

        let startTypeTimeIndex = TYPE_TIMES_ORDER.indexOf(maxTimeType);
        let endTypeTimeIndex = TYPE_TIMES_ORDER.indexOf(minTimeType);
        let i = startTypeTimeIndex;

        // console.warn(">> REQUIRED", fn_DateFormatStandar(endDate, "dd/mm/yyyy h24:mm"));
        // let auxAdjusted: [string, string, number][] = [[fn_DateFormatStandar(auxDate, "dd/mm/yyyy h24:mm"), null, null]];
        while (i <= endTypeTimeIndex) {
            let typeTime = TYPE_TIMES_ORDER[i];

            timeElapsed[typeTime] = GetTimeDiff(typeTime);
            // auxAdjusted.push([fn_DateFormatStandar(auxDate, "dd/mm/yyyy h24:mm"), typeTime, timeElapsed[typeTime]])
            i++;
        }
        // console.log(auxAdjusted);
        // console.warn(">> REQUIRED", fn_DateFormatStandar(endDate, "dd/mm/yyyy h24:mm"));

        return timeElapsed;
    }

    /**
     * @param date 
     * @param argT Trunca a partir del argumento especificado
     * @example 
     * >> date = "2020-04-12 12:23:22:123"
     * >> argT = "Day"
     * << resultDate: 2020/04/01 00:00:00.00
     */
    export function _TruncDate(date: (string | Date), argT: keyof Omit<ITimeElapsed, keyof Pick<ITimeElapsed, "Year">>): Date {
        let startTypeTimeIndex = TYPE_TIMES_ORDER.indexOf(argT);
        let endTypeTimeIndex = TYPE_TIMES_ORDER.length - 1;

        let resultDate = GetValidDate(date);

        let i = startTypeTimeIndex;

        while (i <= endTypeTimeIndex) {
            let typeTime = TYPE_TIMES_ORDER[i];

            switch (typeTime) {
                case "Month":
                    resultDate.setMonth(0); // Enero
                    break;
                case "Day":
                    resultDate.setDate(1);
                    break;
                case "Hour":
                    resultDate.setHours(0);
                    break;
                case "Minute":
                    resultDate.setMinutes(0);
                    break;
                case "Second":
                    resultDate.setSeconds(0);
                    break;
                case "Milisecond":
                    resultDate.setMilliseconds(0);
                    break;
            }

            i++;
        }

        return resultDate;
    }

    export function _GetDaysInMonth_YM(year: number, month: number): number {
        return _GetDaysInMonth(new Date(year, month, 1));
    };

    export function _GetDaysInMonth(date: string | Date): number {
        date = GetValidDate(date);

        date.setMonth(date.getMonth() + 1);
        date.setDate(0);

        return date.getDate();
    }

    // *********************************************************************
    // Time Zone Things
    // *********************************************************************

    function GetValidTimeZone(timeZoneOrIdSchool: (string | number)): string {
        let timeZone: string = "";
        if (typeof timeZoneOrIdSchool == "number") {
            timeZone = DataModuloEscuela._DiccFullEscuelas.get(timeZoneOrIdSchool)?.ZonaHoraria;
        } else {
            timeZone = timeZoneOrIdSchool;
        }

        if (!timeZone) {
            throw ("Time Zone No Found. timeZoneOrIdSchool: " + timeZoneOrIdSchool);
            // console.error(e);
            // return null;
        }
        return timeZone;
    }

    /** @deprecared  */
    function fn_GetLocalDateFromTimeZone(dateRef: (string | Date), timeZoneOrIdSchool: (string | number)) {
        let timeZone = GetValidTimeZone(timeZoneOrIdSchool);

        if (!timeZone) {
            return null;
        }

        dateRef = GetValidDate(dateRef);
        let strDate = dateRef.toLocaleString("en-US", { timeZone: timeZone });
        return new Date(strDate);
    }

    // /** // NOTE: Usar con precaución
    //  * @param localDate es una fecha formada a partir de la zona horaria local
    //  * @param timeZone ej: "America/Cancun"
    //  * @deprecated
    //  */
    // export function fn_GetUTCDateInTimeZoneFromLocalDateRef(localDate: (string | Date), timeZoneOrIdSchool: (string | number)): string {
    //     let dateCurrentZone = GetValidDate(localDate);
    //     let timeZone = GetValidTimeZone(timeZoneOrIdSchool);

    //     if (!timeZone) {
    //         return null;
    //     }

    //     let dateRealTimeZone = fn_GetLocalDateFromTimeZone(localDate, timeZone);

    //     let diferenciaMs = Number(dateRealTimeZone) - Number(dateCurrentZone);

    //     dateCurrentZone.setMilliseconds(dateCurrentZone.getMilliseconds() - diferenciaMs);

    //     return dateCurrentZone.toISOString();
    // }

    /** Reemplaza la hora de la fecha de referencia y retorna un objeto Date
     * @param hourStr HH:MM || HH:MM:SS
     * @param UTCRefDate Fecha de referencia
     * * // FIX_TIMEZONE Implementaciones de la función donde timeZoneOrIdSchool sea null
     * @deprecated
    */
    export function _GetLocalDateFromTimeZone_ReplaceHourFromDateRef(hourStr: string, UTCRefDate: string, timeZoneOrIdSchool: (number | string) = null) {
        let hourSplited = hourStr.split(":");
        let refDateSplited = UTCRefDate.split("T");
        let newStrDate = `${refDateSplited[0]}T${hourSplited[0]}:${hourSplited[1]}:${(hourSplited[2] ? hourSplited[2] : "00")}Z`; //.${refDateSplited[1].split(".")[1]}`;
        // console.log(UTCRefDate, newStrDate);

        // TEMPORAL El parámetro nunca debe ser null
        if (timeZoneOrIdSchool) {
            timeZoneOrIdSchool = GetValidTimeZone(timeZoneOrIdSchool);
            return fn_GetLocalDateFromTimeZone(newStrDate, timeZoneOrIdSchool); // FIXME Se conservan los cambios?

        } else {
            // NotificacionV2.fn_Mostrar("⚠️ Sin zona horaria!", "REQUIRED");
            return new Date(newStrDate);
        }
    }
}
