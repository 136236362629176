import { MainPage } from "../../MainPage";
import { DataDRequest } from "../../data/DRequest";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import { _LOCALDATA_GetAsignacionesTutoresDeAlumno, _LOCALDATA_GetTutoresDeAlumno } from "../../data/modulo/Alumno";
import DataModuloEscuela from "../../data/modulo/Escuela";
import DataModuloTutor, { _SvAlumnoRecuperarContraseniaViaEmail, _SvAlumnoRecuperarContraseniaViaSMS } from "../../data/modulo/Tutor";
import DataModuloTutorAsignacion from "../../data/modulo/TutorAsignacion";
import { DataUtil } from "../../data/util/Util";
import { _HttpMsgV2 } from "../../util/Labels";
import { TCARDV2COLL_OnEditOriginEvent } from "../controlD3/CardV2Collapse";
import { CardV2CollapseAdvancedTable, IConfigCardV2CollapseExcelExport } from "../controlD3/CardV2CollapseAdvancedTable";
import { CopyElementTextControl } from "../controlD3/CopyElementTextControl";
import { DropdownAdvanced } from "../controlD3/DropdownAdvanced";
import { ModalThings } from "../controlD3/ModalThings";
import { Notificacion } from "../controlD3/Notificacion";
import { Table } from "../controlD3/Tabla";
import { _TextoCensuraDiv } from "../controlD3/TextoCensura";
import { UIUtilLang } from "../util/Language";
import { UIUtilGeneral } from "../util/Util";

import CAccionPermiso = Entidad.CAccionPermiso;
import IAlumno = Entidad.IAlumno;

interface ITutor extends Entidad.ITutor {
    Asignacion: Entidad.ITutorAsignacion;
    EsMonitoreo: boolean;
    StrEsMonitoreo: string;
}

export class UIPanelCardAlumnosInfoTutores extends CardV2CollapseAdvancedTable<ITutor, [IAlumno]> {
    private controlSelectTutores: DropdownAdvanced<Entidad.ITutor, "IdPadre">;

    constructor(modulo: Entidad.CModulo.PanelTutores) {
        super("", modulo);
    }

    protected CARDCOLLADTAB_Table_GetMenuTop(): Table.ITableMenuTopDefaultOptionConfig[] {
        let opciones: Array<Table.ITableMenuTopDefaultOptionConfig> = [];

        if (this.HasActionPermission(CAccionPermiso.Agregar)) {
            opciones.push({
                Label: "action_agregar",
                Callback: () => this.OpenTutoresSelect(false)
                // OnValidateDisabling: () => { return this.HabilitarOpAgregar }
            });

            opciones.push({
                Label: "action_agregarmonitor",
                Callback: () => this.OpenTutoresSelect(true)
                // OnValidateDisabling: () => { return this.HabilitarOpAgregar }
            });
        }

        return opciones;
    }
    protected CARDCOLLADTAB_GetExportarConfig(dataTable: ITutor[]): IConfigCardV2CollapseExcelExport<ITutor> {
        const bolUserAdmin = (DataUtil._Usuario.Perfil == Entidad.CTipoPerfil.Admin);
        let alumno = this.cardData[0];
        return {
            FileName: this.cardData[0].NombreCompleto + " - Tutores",
            IdsEscuelas: [this.cardData[0].IdKinder],
            TypeRequest: Entidad.CTipoRequest.Alumno,
            ColumnsConfig: this.ctrlTabla
                ._InfoColumns
                .filter(d => (bolUserAdmin || d.Field != "Contrasenia"))
                .map(d => ({
                    Field: d.Field as keyof ITutor,
                    HeaderTag: d.Label,
                    WidthCell: 25
                })),
            OnGetDataBySheets: async () => {
                const padresAux = dataTable.map(d => UIUtilGeneral._ObjectClone(d));
                if (bolUserAdmin) {
                    const idsPadres = dataTable.map(d => d.IdPadre);
                    const resPass = await DataModuloTutor._GetPassword(idsPadres);
                    if (resPass.Resultado > 0) {
                        padresAux.forEach(d => {
                            d.Contrasenia = resPass.Data[d.IdPadre];
                        })
                    } else {
                        this.ctrlNotification._Mostrar(UIUtilLang._GetUIString("general", "notif_fail_getpass"), "ADVERTENCIA");
                    }
                }
                return [{
                    IdSheet: alumno.IdKinder,
                    SheetName: alumno.NombreCompleto,
                    Data: padresAux,
                }]
            },
            OnGetEscuelasTagInSheet: (datos) => DataModuloEscuela._DiccEscuela.get(this.cardData[0].IdKinder).Nombre
        }
    }
    protected CARDCOLLADTAB_Table_GetConfig(): Omit<Table.IConfig<ITutor>, keyof Pick<Table.IConfig<ITutor>, "Parent">> {
        return {
            IdTabla: "AlumnosPanelInfoTutores",
            Title: "",
            IdData: "IdPadre",
            OrderDefault: { Type: Table.CStatusOrder.Asc, Field: "NombreCompleto" },
            MinWidth: 800,
            StickyCheckInRow: true,
            RenderColumnHeadings: [
                { Field: "NombreCompleto", Label: "Nombre", Width: "25%", MinWidth: "75px" },
                { Field: "Telefono", Label: "Teléfono", Width: "20%", MinWidth: "75px" },
                { Field: "Correo", Label: "Correo", Width: "23%", MinWidth: "75px" },
                { Field: "Contrasenia", Label: "Contraseña", Width: "20%", MinWidth: "85px", IsSortable: false },
                { Field: "StrEsMonitoreo", Label: "Monitoreo", LabelLangKey: "d_field_esmonitoreo", Width: "12%", MinWidth: "75px" }
            ],
            OptionsTopDefaultV2: {
                MaxOptionsInRow: 30,
                Options: []
            },
            OnValueSelectRow: (id, datum) => console.debug(id, datum),
            OptionsOfDataCheckV3: (tutores) => this.CARDCOLLADTAB_GetTableOptionsToSelectedData(this.cardData[0]?.IdKinder, "top-selected", this.TableGetDataSelectedMenu(tutores)),
            EvaluatorAndSubLevelsBuild: {
                GetOptionsInRowV2: (tutores) => this.CARDCOLLADTAB_GetTableOptionsToSelectedData(this.cardData[0].IdKinder, "row", this.TableGetDataSelectedMenu([tutores])),
                OnStepCellTable: (container: d3.Selection<HTMLDivElement, any, HTMLElement, any>, datum: ITutor, fieldNameStep: string) => {
                    switch (fieldNameStep) {
                        case "Correo":
                        case "Telefono":
                            CopyElementTextControl._AddCopyTextBehaviour(container.node(), () => datum[fieldNameStep]);
                            break;
                        case "Contrasenia":
                            if (this.HasActionPermission(CAccionPermiso.VerContrasenia)) {
                                _TextoCensuraDiv(container, () => this.Sv_ObtenerContrasenia(datum.IdPadre), "100px");
                                container.select("div").classed("hide", false)
                            } else {
                                let restriction = UIUtilLang._GetUIString("permission", "nopermiso_verinfo");
                                _TextoCensuraDiv(container, () => this.Sv_ObtenerContrasenia(datum.IdPadre), "100px", restriction);
                            }
                            break;
                    }
                }
            }
        }
    }
    protected CARDCOLL_OnInitBuild(contentContainer: TSelectionHTML<"div", any, any>): void {
        this.btnEditarCard._d3Selection.remove();
        this.cardFooterSelection.remove();

        this.CARDCOLLADTAB_CreateTable(contentContainer);

        this.controlSelectTutores = new DropdownAdvanced({
            IsMultiSelect: false,
            DisplayMember: "NombreCompleto",
            ValueMember: "IdPadre",
            Parent: this.ctrlTabla._Control.select(".area_acciones"),
            ListWidth: "330px",
            ShowAndEnableSearchText: true,
            OnChangeSearchText_GetDataValueToEval: (datum) => (datum.NombreCompleto + " " + datum.Correo),
            OnStepItemListUI: (container, datum, step) => {
                if (step == "enter") {
                    container.classed(UIUtilGeneral.FBoxOrientation.Vertical, true)
                        .style("align-items", "flex-start");
                    container.append("label")
                        .style("cursor", "pointer");
                    container.append("label")
                        .style("cursor", "pointer")
                        .style("font-size", "var(--fontsize_me4)");
                }
                container.select("label:nth-child(1)").text(datum.NombreCompleto);
                container.select("label:nth-child(2)").text(datum.Correo)
            },
            OnChange: (idTutor, datoT) => {
                let esMonitoreo = this.controlSelectTutores["EsMonitoreo"] as boolean;
                this.Sv_AsignarChildPadre(idTutor[0], esMonitoreo);
            }
        })

        //???? En el listener de abajo se tiene que actualizar la data de la tarjeta?
        if (!this["__workerListenerReq"]) {
            this["__workerListenerReq"] = MainPage._AddEventListenerWorkerRequest(Entidad.CTipoRequest.TutorAsignacion, (e) => {
                //if (e.detail.Data.find(asignacion => asignacion.IdChild == this.cardData[0].IdChild)) {
                this.cardData[0] = DataModuloMain._GetItemDataByName("Alumno", this.cardData[0].IdChild);
                this.ActualizarCard();
                //}
            })
        }
    }
    protected CARDCOLL_GetVariantToValidateUpdate(cardData_0: IAlumno): string {
        return null;
    }
    protected CARDCOLL_OnUpdateData(cardData_0: IAlumno): void | Promise<void> {
        this.ActualizarCard(cardData_0);
    }
    protected CARDCOLL_MostrarBody(): void {
        this.cardContentContainerSelection.style("height", "100%");
    }
    protected CARDCOLL_OcultarBody(): void {
        this.cardContentContainerSelection.style("height", null);

    }
    protected CARDCOLL_OnEditarCard(originEvent: TCARDV2COLL_OnEditOriginEvent): void {
        //throw new Error("Method not implemented.");
    }
    protected CARDCOLL_OnCancelaEditarCard(originEvent: TCARDV2COLL_OnEditOriginEvent): void {
        //throw new Error("Method not implemented.");
    }
    protected CARDCOLL_GuardarCardV2(): Promise<DataDRequest.IRequestResponseA<any>> {
        return null
    }
    protected CARDCOLL_SyncOrGetIdToDownloadData(): DataModuloMain.TipoRequestMonitorId | DataModuloMain.TipoRequestMonitorId[] | (() => Promise<any>) {
        return Entidad.CTipoRequest.TutorAsignacion;
    }
    protected CARDCOLL_GetIdSchool(cardData_0_0: IAlumno): number {
        return cardData_0_0.IdKinder;
    }

    public _Destroy(): this {
        super._Destroy();
        MainPage._RemoveEventListenerWorkerRequest(Entidad.CTipoRequest.TutorAsignacion, this["__workerListenerReq"]);
        this["__workerListenerReq"] = null;
        //MainPage.fn_RemoveEventListenerWorkerRequest(Entidad.CTipoRequest.Alumno, this["__workerListenerAlumnos"])
        //this["__workerListenerAlumnos"] = null;
        return this;
    }

    // ******************************************************

    private TableGetDataSelectedMenu(tutores: ITutor[]) {
        let opciones: Array<Table.ITableMenuDataSelectedOptionConfig<ITutor>> = [];

        if (tutores.length == 1 && this.HasActionPermission(CAccionPermiso.EnviarSMS)) {
            opciones.push({
                Label: "action_sendsms",
                GetDetails: (datos) => ({
                    Enabled: (datos.length == 1),
                    Description: this.CARDCOLL_GetUIStringModule("tlt_sendsms_desc"),
                }),
                Callback: (datos) => this.OpenModal_SendSMS(datos[0])
            })
        }

        if (tutores.length == 1 && this.HasActionPermission(CAccionPermiso.EnviarEmail)) {
            opciones.push({
                Label: "action_sendemail",
                GetDetails: (datos) => ({
                    Enabled: (datos.length == 1),
                    Description: this.CARDCOLL_GetUIStringModule("tlt_sendemail_desc"),
                }),
                Callback: (datos) => this.OpenModal_SendEmail(datos[0])
            });
        }

        if (this.HasActionPermission(CAccionPermiso.Eliminar)) {
            opciones.push({
                Label: "action_eliminar",
                GetDetails: (datos) => {
                    let tutoresEnTabla = this.ctrlTabla._data;
                    // NOTE: Anteriormente el alumno debia conservar al menos un turor, solo si estaba Activo
                    // if (this.cardData[0].IdChildMovimiento == data.Entidades.CNinioMovimiento.Activo && this.cardData[0].Tutores.size == 1) {
                    let enable = (tutoresEnTabla.length > 1 && datos.length < tutoresEnTabla.length);
                    return {
                        Enabled: enable,
                        Description: (!enable ? this.CARDCOLL_GetUIStringModule("tlt_eliminar_desc_fail") : null),
                    }
                },
                Callback: (datos) => {
                    this.OpenModal_EliminarAsignaciones(datos);
                }
            });
        }
        return opciones;
    }

    private OpenTutoresSelect(esMonitoreo: boolean) {
        let alumno = this.cardData[0];
        let datosEnTabla = this.ctrlTabla._data;
        let tutoresDisponibles = Array.from(DataModuloTutor._DiccTutores.values())
            .filter(d => (
                (d.IdEscuelas == "" || d.IdEscuelas.split(",").includes(alumno?.IdKinder + ""))
                && !Boolean(datosEnTabla.find(dTabla => dTabla.IdPadre == d.IdPadre))
            ));

        // console.log(tutoresFaltantes);

        if (tutoresDisponibles.length > 0) {
            if (!this.controlSelectTutores._Visible || this.controlSelectTutores["EsMonitoreo"] != esMonitoreo) {
                this.controlSelectTutores["EsMonitoreo"] = esMonitoreo;
                this.controlSelectTutores._ResetSelect();
                this.controlSelectTutores._UpdateList(tutoresDisponibles);
                this.controlSelectTutores._Show();
            } else {
                this.controlSelectTutores._Hide();
            }
        } else {
            this.ctrlNotification._Mostrar(this.CARDCOLL_GetUIStringModule("notif_sintutores"), Notificacion.CTipoNotificacion.ADVERTENCIA);
        }
    }

    private async OpenModal_SendEmail(datumTutor: ITutor) {
        let bolSend = await ModalThings._GetConfirmacionBasicoV2({
            Title: "Enviar email",
            TypeStrModalBotons: "aceptar_cancelar",
            Content: "Un correo será envíado para recuperar la contraseña",
            Width: 300
        })

        if (bolSend) {
            this.Sv_EnviarEmail(datumTutor)
        }
    }

    private async OpenModal_SendSMS(datumTutor: ITutor) {
        let bolSend = await ModalThings._GetConfirmacionBasicoV2({
            Title: "Enviar SMS",
            TypeStrModalBotons: "aceptar_cancelar",
            Content: "Un SMS será envíado para recuperar la contraseña",
            Width: 300
        })

        if (bolSend) {
            this.Sv_EnviarSms(datumTutor);
        }
    }

    private async OpenModal_EliminarAsignaciones(dataTutores: ITutor[]) {
        this.CARDCOLLAD_OpenModal_ProccessArrayData<ITutor>({
            DataToProccess: dataTutores,
            TypeRequest: Entidad.CTipoRequest.TutorAsignacion,
            Message: UIUtilLang._GetUIString("confirmation", (dataTutores.length == 1 ? "asignaciontutorremover" : "asignaciontutoresremover"))
                .replace(
                    "_TUTOR",
                    new Intl["ListFormat"](
                        UIUtilLang._GetLanguage(),
                        { style: 'long', type: 'conjunction' }
                    ).format(dataTutores.map(d => (d.Nombre + " " + d.ApPaterno)))), // FIXME Tipear
            OnError_GetItemDataTag: (dato) => dato.NombreCompleto,
            OnGetIdEscuela: (dato) => this.cardData[0].IdKinder,
            OnStepAProccess: (dato) => this.Sv_EliminarAsignacion(dato),
            OnEndAndCloseProccess: async (datosCorrectos) => {
                if (datosCorrectos.length) {
                    this.ctrlProgress.attr("oculto", false);
                }
            }
        })
    }

    private ActualizarCard(cardData_0 = this.cardData[0]) {
        const tutoresAlumno = _LOCALDATA_GetAsignacionesTutoresDeAlumno(this.cardData[0].IdChild);
        const tutores: ITutor[] = Array.from(tutoresAlumno.values())
            .map<ITutor>((asignacionTutor) => {
                return Object.assign(
                    <ITutor>{
                        Asignacion: asignacionTutor.Asignacion,
                        EsMonitoreo: asignacionTutor.Asignacion.EsMonitoreo,
                        StrEsMonitoreo: (UIUtilLang._GetUIString("general", asignacionTutor.Asignacion.EsMonitoreo ? "afirma" : "niega")),
                    },
                    asignacionTutor.Tutor
                )
            })

        this.ctrlTabla._UpdateData(tutores);

        this.CARDCOLL_UICardHasError(_LOCALDATA_GetTutoresDeAlumno(cardData_0.IdChild).size == 0, UIUtilLang._GetUIString("alumnos", "notif_falta_tutor"));
    }

    // **************************************************************************
    // Services
    // **************************************************************************

    private async Sv_EnviarSms(datumTutor: ITutor) {
        this.ctrlProgress.attr("oculto", false);
        if (!datumTutor) {
            this.ctrlNotification._Mostrar(UIUtilLang._GetUIString("paneltutores", "notif_selecttutor"), "ADVERTENCIA");
        }

        else {
            const res = await _SvAlumnoRecuperarContraseniaViaSMS(datumTutor.Correo);
            const mensage = _HttpMsgV2(res);
            this.ctrlNotification._Mostrar(mensage, (res.Resultado > 0) ? "INFO" : "ADVERTENCIA");
        }
        this.ctrlProgress.attr("oculto", true);
    }

    private async Sv_EnviarEmail(datumTutor: ITutor) {
        this.ctrlProgress.attr("oculto", false);
        if (!datumTutor) {
            this.ctrlNotification._Mostrar(UIUtilLang._GetUIString("paneltutores", "notif_selecttutor"), "ADVERTENCIA");
        }

        else {
            const res = await _SvAlumnoRecuperarContraseniaViaEmail(datumTutor.Correo);
            const mensage = _HttpMsgV2(res);
            this.ctrlNotification._Mostrar(mensage, (res.Resultado > 0) ? "INFO" : "ADVERTENCIA");
        }
        this.ctrlProgress.attr("oculto", true);
    }

    private async Sv_AsignarChildPadre(idTutor: number, esMonitoreo: boolean) {
        this.ctrlProgress.attr("oculto", false);
        let res = await DataModuloTutorAsignacion._AsignarAlumnoTutor(this.cardData[0].IdChild, idTutor, this.cardData[0].IdKinder, esMonitoreo);

        let mensaje = UIUtilLang._GetHTTPMessage(res);
        this.ctrlNotification._Mostrar(mensaje, (res.Resultado > 0) ? "INFO" : "ADVERTENCIA");

        if (res.Resultado > 0 || res.Resultado == -1) {
            await MainPage._ReloadServiceAndAwait(Entidad.CTipoRequest.TutorAsignacion, this.cardData[0].IdKinder).catch((err) => {
                this.ctrlNotification._Mostrar(UIUtilLang._GetUIString("general", "notif_fail_infoupdate"), "ADVERTENCIA");
            });
        }
        this.ctrlProgress.attr("oculto", true);
    }

    private Sv_EliminarAsignacion(tutor: ITutor) {
        // let idAsignacion = tutor.AlumnosAsignados.get(this.cardData[0].IdChild).Asignacion.IdRegistro;
        return DataModuloTutorAsignacion._EliminarAsignacionAlumnoTutor(tutor.Asignacion.IdRegistro);
    }

    private async Sv_ObtenerContrasenia(idTutor: number): Promise<string> {
        let res = await DataModuloTutor._GetPassword([idTutor]);
        return res.Data[idTutor];
    }
}
