import * as d3 from "d3";
import { group as d3Group } from "d3-array";
import { MainPage } from "../../MainPage";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import { _DICC_ALUMNO } from "../../data/modulo/Alumno";
import DataModuloEscuela from "../../data/modulo/Escuela";
import DataModuloTutor from "../../data/modulo/Tutor";
import { DataUtil } from "../../data/util/Util";
import _L from "../../util/Labels";
import { IConfigGridExcelExport, IGridExtraTableConfig, IGridRenderInfo, VentanaGrid } from "../controlD3/AVentanaGrid";
import { CopyElementTextControl } from "../controlD3/CopyElementTextControl";
import { FormGenerator, IControlCreated } from "../controlD3/Formulario";
import { ExcelThingsV2 } from "../controlD3/InputExcelV2";
import { ModalThings } from "../controlD3/ModalThings";
import { Table } from "../controlD3/Tabla";
import { _TextoCensuraDiv } from "../controlD3/TextoCensura";
import { UIUtilLang } from "../util/Language";
import { UIUtilStrings } from "../util/Strings";
import { UIUtilGeneral } from "../util/Util";
import { UIUtilViewAlumno } from "../utilView/Alumno";
import { UIUtilViewTutor } from "../utilView/Tutor";

import CAccionPermiso = Entidad.CAccionPermiso;

interface ITutor extends Entidad.ITutor {
    /** En caso de tener asignaciones en una o más escuelas */
    NombreEscuelaAsigAlumno: string;
    /** En caso de tener asignaciones en una o más escuelas */
    AlumnosEnEscuela: Entidad.IAlumno[];
    getAlumnosNombresEnEscuela: string;
    /** 'IdPadre-IdEscuela' */
    IdPadreEscuela: string;
    IdEscuela: number;
}

interface ITutorForm extends Entidad.ITutor {
    EsGeneral: boolean;
}

// NOTE: El Grid de Tutor aun no tiene permisos especiales para VerContraseña
export class UIVentanaTutor extends VentanaGrid<ITutor> {

    constructor(content: d3.Selection<HTMLDivElement, undefined, HTMLElement, any>, modulo: Entidad.CModulo) {
        super(content, modulo, {
            ModuloObservableToTblRefresh: [Entidad.CTipoRequest.TutorAsignacion]
        });
    }

    private GetForm(datoTutor = <Entidad.ITutor>{}) {
        const dataForm = { ...(DataModuloMain._GetItemDataByName("Tutor", datoTutor.IdPadre) || {}) } as ITutorForm;
        const form = new FormGenerator<ITutorForm>();
        const action = dataForm.IdPadre ? CAccionPermiso.Editar : CAccionPermiso.Agregar;
        const actionEsAgregar = action == CAccionPermiso.Agregar;
        const esUserAdmin = DataUtil._Usuario._PerfilAdmin || DataUtil._Usuario._PerfilSuperUsuario;

        dataForm.EsGeneral = actionEsAgregar ? esUserAdmin : dataForm.IdEscuelas == "";

        form._Crear({
            LangModuleKeyInContext: this.labelsKeyBase,
            schema: [
                { model: "Nombre", type: "input", inputAttr: { type: "text", required: true, maxlength: 100 }, labelText: "d_field_nombre" },
                { model: "ApPaterno", type: "input", inputAttr: { type: "text", required: true, maxlength: 100 }, labelText: "d_field_appaterno" },
                { model: "ApMaterno", type: "input", inputAttr: { type: "text", maxlength: 100 }, labelText: "d_field_apmaterno" },
                { model: "Correo", type: "input", inputAttr: { type: "email", required: true, maxlength: 200 }, labelText: "d_field_correo", },
                {
                    model: "Telefono", type: "input", inputAttr: { type: "phone2_10A", required: true, }, labelText: "d_field_telefono",
                    onValidate: (value) => {
                        const telsplit = value.split(" ");
                        const tel = telsplit.length == 1 ? telsplit[0] : telsplit[1];
                        return UIUtilStrings._TrimStart(tel, "0").length == tel.length;
                    },
                },
                {
                    model: "EsGeneral" as any, type: "radioList", labelText: "tutortipo",
                    radioListAttr: {
                        required: true, ValueMember: "Id", DisplayMember: "Name", Disabled: !esUserAdmin, ReturnOnlyValueMember: true,
                        OnChange: (_, value: boolean) => {
                            const control = form._ControlsData.get("KinderFiltro") as IControlCreated<"selectMaterial">;
                            control.instance._valueSelect(0);
                            control.row.classed("hide", value);
                        },
                        Data: [
                            { Id: true, Name: UIUtilLang._GetUIString("general", "general") },
                            { Id: false, Name: this.VB_GetUIStringModule("tutorlimitado") },
                        ]
                    }
                },
                {
                    model: "KinderFiltro", type: "selectMaterial",
                    labelText: "d_field_nombreescuelaasigalumno",
                    selectMaterialAttr: {
                        required: true,
                        multiselect: true,
                        removeBorder: (!actionEsAgregar && !esUserAdmin),
                        disabled: (!actionEsAgregar && !esUserAdmin),
                        valueMember: "IdKinder",
                        displayMember: "Nombre",
                        Data: (() => {
                            if (actionEsAgregar || esUserAdmin)
                                return this.GridGetEscuelasConPermisoDeAccion(action)
                            return dataForm.KinderFiltro?.map(id => DataModuloMain._GetItemDataByName("Escuela", id)).filter(d => !!d) || [];
                        })(),
                    },
                },
            ],
        }, dataForm);

        form._ControlsData.get("KinderFiltro").row.classed("hide", dataForm.EsGeneral); // Es tutor general
        if (actionEsAgregar && !esUserAdmin)
            form._ControlsData.get("EsGeneral").row.classed("hide", true);

        return form;
    }

    protected GRID_GetDataRequestID(): DataModuloMain.TipoRequestMonitorId {
        return Entidad.CTipoRequest.Tutor;
    }

    protected async GridGetData(): Promise<Array<ITutor>> {
        return DataModuloMain._GetReqDataArrayByName("Tutor")
            .reduce((tutores, tutor, idTutor) => {
                const escuelasAlumnos = DataModuloTutor._LOCALDATA_GetAlumnosAsignadosGroupBySchools(tutor.IdPadre, "todo");

                if (escuelasAlumnos.size) {
                    escuelasAlumnos.forEach((asignaciones, idEscuela) => {
                        const alumnos = asignaciones
                            .map(d => d.Alumno || { IdChild: d.Asignacion.IdChild });
                        // .map(d => (d.Alumno.Nombre || Utils.fn_GetUIString("predefined", "general", "nodisponible") + "_" + d.Asignacion.IdChild))
                        // .join(", ");

                        const tutorAsignado: ITutor = {
                            ...tutor,
                            ...<ITutor>{
                                AlumnosEnEscuela: alumnos,
                                NombreEscuelaAsigAlumno: DataModuloEscuela._DiccEscuela.get(idEscuela)?.Nombre || UIUtilLang._GetUIString("general", "nodisponible"),
                                IdPadreEscuela: idTutor + "-" + idEscuela,
                                KinderFiltro: [idEscuela],
                                IdEscuela: idEscuela,
                            }
                        };
                        tutores.push(UIUtilGeneral._ObjectAddGetters(tutorAsignado, {
                            getAlumnosNombresEnEscuela: () => tutorAsignado.AlumnosEnEscuela
                                .map(d => d.NombreCompleto)
                                .join(", ")
                        }))
                    })
                }
                // else {
                (tutor.KinderFiltro ? tutor.KinderFiltro : (escuelasAlumnos.size ? [] : [null])).forEach(idEscuela => {
                    if (escuelasAlumnos.has(idEscuela))
                        return;

                    const tutorSinAsignacion: ITutor = {
                        ...tutor,
                        ...<ITutor>{
                            AlumnosEnEscuela: [],
                            NombreEscuelaAsigAlumno: DataModuloMain._GetDataValueFieldByName("Escuela", idEscuela, "Nombre") || "",
                            IdPadreEscuela: idTutor + "-" + (idEscuela || ""),
                            KinderFiltro: idEscuela ? [idEscuela] : null,
                            IdEscuela: idEscuela,
                        }
                    };

                    tutores.push(UIUtilGeneral._ObjectAddGetters(tutorSinAsignacion, {
                        getAlumnosNombresEnEscuela: () => ""
                    }));
                })
                // }
                return tutores;
            }, [])
    }

    protected GRID_GetMenuTopGrid(): Array<Table.ITableMenuTopDefaultOptionConfig> {
        let opciones: Array<Table.ITableMenuTopDefaultOptionConfig> = [];
        if (this.GridHasPermisoAccion(Entidad.CAccionPermiso.Agregar)) {
            opciones.push({
                Label: "Agregar", Callback: () => {
                    this.OpenModal_FormularioAgregar();
                }
            });

            opciones.push({
                Label: "action_cargamasiva",
                Callback: () => this.OpenModal_CargaMasiva()
            })
        }
        return opciones;
    }

    protected GRID_GetSelectionDataMenuV2(menuLocation: "row" | "top-selected", dataGridSelected: ITutor[]): Table.ITableMenuDataSelectedOptionConfig<ITutor>[] {
        let opciones: Array<Table.ITableMenuDataSelectedOptionConfig<ITutor>> = [];

        if (this.GridHasPermisoAccion(Entidad.CAccionPermiso.Editar)) {
            opciones.push({
                Label: "Editar",
                MultiData: false,
                Callback: (datos: Array<ITutor>) => this.OpenModal_FormularioEditar(Object.assign({}, datos[0]))

            })
        }

        const sendEmailPermisssion = this.GridHasPermisoAccionV2(CAccionPermiso.EnviarEmail)
        const sendSMSPermisssion = this.GridHasPermisoAccionV2(CAccionPermiso.EnviarSMS)
        if (sendEmailPermisssion || sendSMSPermisssion) {
            let alumnosAsignados = true
            let esMismaEscuela = true
            for (const t of dataGridSelected) {
                if (!t.AlumnosEnEscuela?.length) {
                    alumnosAsignados = false
                    break
                }
                if (dataGridSelected[0].IdEscuela != t.IdEscuela) {
                    esMismaEscuela = false
                    break
                }
            }
            if (sendEmailPermisssion) {
                opciones.push({
                    Label: "action_sendemail",
                    GetDetails: (datos) => ({
                        Enabled: (datos.length > 0 && alumnosAsignados && esMismaEscuela),
                        Description: _L("tutores.tlt_sendemail_desc")
                            + (!alumnosAsignados || !esMismaEscuela ? "\n\n" : "")
                            + (!alumnosAsignados ? _L("tutores.tlt_soloasignados")
                                : !esMismaEscuela ? _L("tutores.tlt_solounaescuela") : ""),
                    }),
                    Callback: (datos) => UIUtilViewTutor._RecuperarContraseniaViaEmail({
                        tutores: datos,
                        typeRequest: Entidad.CTipoRequest.Tutor,
                        modulo: this.modulo as any,
                    })
                })
            }
            if (sendSMSPermisssion) {
                opciones.push({
                    Label: "action_sendsms",
                    GetDetails: (datos) => ({
                        Enabled: (datos.length > 0 && alumnosAsignados && esMismaEscuela),
                        Description: _L("tutores.tlt_sendsms_desc")
                            + (!alumnosAsignados || !esMismaEscuela ? "\n\n" : "")
                            + (!alumnosAsignados ? _L("tutores.tlt_soloasignados")
                                : !esMismaEscuela ? _L("tutores.tlt_solounaescuela") : ""),
                    }),
                    Callback: (datos) => UIUtilViewTutor._RecuperarContraseniaViaSMS({
                        tutores: datos,
                        typeRequest: Entidad.CTipoRequest.Tutor,
                        modulo: this.modulo as any,
                    })
                })
            }
        }

        if ((DataUtil._Usuario._PerfilAdmin || DataUtil._Usuario._PerfilSuperUsuario) && this.GridHasPermisoAccion(Entidad.CAccionPermiso.Eliminar)) {
            opciones.push({
                Label: "Eliminar",
                Callback: (datosSelected: Array<ITutor>) => {
                    this.OpenModal_EliminarDatos(datosSelected.map(d => Object.assign({}, d)));
                }
            })
        }

        return opciones;
    }

    protected GRID_GetFilters(): Array<Table.IParametroFiltro<ITutor>> {
        return [
            { Label: "Nombre", Field: "Nombre" },
            { Label: "Apellido paterno", Field: "ApPaterno" },
            { Label: "Apellido materno", Field: "ApMaterno" },
            { Label: "Teléfono", Field: "Telefono" },
            { Label: "Correo", Field: "Correo" }
        ];
    }

    protected GRID_GetTableConfigBase(): IGridRenderInfo<ITutor> {
        return {
            IdTabla: "AlumnosTutores",
            Title: "",
            DefaultSort: "NombreCompleto",
            IdData: "IdPadreEscuela",
            MinWidth: 1100,
            Columns: [
                { Field: "NombreCompleto", Label: "Nombre", Width: "20%", MinWidth: "80px" },
                // { Field: "ApPaterno", Label: "Ap. Paterno", Width: "11%", MinWidth: "80px" },
                // { Field: "ApMaterno", Label: "Ap. Materno", Width: "11%", MinWidth: "80px" },
                { Field: "getAlumnosNombresEnEscuela", Label: "Alumn@s", Width: "20%", MinWidth: "90px" },
                { Field: "Telefono", Label: "Teléfono", Width: "15%", MinWidth: "80px" },
                { Field: "Correo", Label: "Correo", Width: "20%", MinWidth: "80px" },
                { Field: "Contrasenia", Label: "Contraseña", Width: "10%", MinWidth: "100px" },
                { Field: "NombreEscuelaAsigAlumno", Label: "Escuela", Width: "15%", MinWidth: "90px" },
            ],
        };
    }

    protected GRID_GetTableConfigAdvanced(): IGridExtraTableConfig<ITutor> {
        return <IGridExtraTableConfig<ITutor>>{
            EvaluatorAndSubLevelsBuild: {
                OnStepCellTable: (container, dato, field: keyof ITutor) => {
                    switch (field) {
                        case "Correo":
                        case "Telefono":
                            CopyElementTextControl._AddCopyTextBehaviour(container.node(), () => dato[field]);
                            break;
                        case "Contrasenia":
                            let restriction: string;
                            let isLimited = (DataUtil._Usuario.Perfil == Entidad.CTipoPerfil.Limitado);
                            if (!dato.IdEscuela && isLimited) {
                                restriction = UIUtilLang._GetUIString("permission", "nopermiso_verinfo");
                                _TextoCensuraDiv(container, () => UIVentanaTutor.Sv_ObtenerContrasenia(dato.IdPadre), "80px", restriction);
                            }
                            else if (this.GridHasPermisoAccionV2(Entidad.CAccionPermiso.VerContrasenia, [dato.IdEscuela])) {
                                _TextoCensuraDiv(container, () => UIVentanaTutor.Sv_ObtenerContrasenia(dato.IdPadre), "80px");
                            }
                            else {
                                restriction = UIUtilLang._GetUIString("permission", "nopermiso_verinfo");
                                _TextoCensuraDiv(container, () => UIVentanaTutor.Sv_ObtenerContrasenia(dato.IdPadre), "80px", restriction);
                            }
                            break;
                        case "getAlumnosNombresEnEscuela":
                            UIUtilGeneral._CreaElementosLinkeablesV2({
                                Container: container.style("overflow", "visible"),
                                Data: dato.AlumnosEnEscuela,
                                Path: "alumnos/alumnos/panel",
                                GetTag: d => (d.Nombre || UIUtilLang._GetUIString("general", "nodisponible") + "_" + d.IdChild),
                                GetId: d => d.IdChild,
                                OnStepItemLink: (element, d) => UIUtilViewAlumno._UIAlumnoLicenciaInfo(element, d.getEdoLicencia, "oninfoico", -6)
                            });
                            break;
                    }
                }
            }
        }
    }

    private OpenModal_FormularioAgregar() {
        this.GridOpenModal_ActionFormToAGridData({
            Action: CAccionPermiso.Agregar,
            GetForm: () => this.GetForm(),
            OnAccept: (form) => DataModuloTutor._Insert(form._Data, form._Data.KinderFiltro),
        });
    }

    private OpenModal_FormularioEditar(datoTutor: ITutor) {
        this.GridOpenModal_ActionFormToAGridData({
            Action: CAccionPermiso.Editar,
            IdsEscuelas: [datoTutor.IdEscuela],
            GetForm: () => this.GetForm(datoTutor),
            OnAccept: (form) => {
                const datoF = form._Data;
                if (datoF.EsGeneral) {
                    return DataModuloTutor._Update(datoF, datoF.EsGeneral, [], []);
                }
                const arrEscNew = [];
                const arrEscRemove = [];
                const arrCurrent = (datoTutor.IdEscuelas == "" ? [] : datoTutor.IdEscuelas.split(","))
                    .map(d => DataModuloMain._GetDataValueFieldByName("Escuela", +d, "IdKinder")) // Para procesar solo escuelas con permisos
                    .filter(d => !!d);

                const arrCurrentAux = (datoTutor.IdEscuelas == "" ? DataModuloMain._GetReqDataArrayByName("Escuela").map(d => d.IdKinder) : arrCurrent) // Si era Global validar todas las escuelas
                for (const idOld of arrCurrentAux) {
                    if (!datoF.KinderFiltro.includes(idOld)) {
                        const asignaciones = [...DataModuloTutor._LOCALDATA_GetAsignacionesAlumnos(datoTutor.IdPadre, "todo", true).values()];
                        const tieneAsignacionVigente = asignaciones
                            .some(({ Asignacion }) => {
                                const alumno = _DICC_ALUMNO.get(Asignacion.IdChild)
                                if (!alumno) return false
                                const estadoValido = ![Entidad.CNinioMovimiento.Graduado, Entidad.CNinioMovimiento.BajaDefinitiva].includes(alumno.IdChildMovimiento)
                                return (estadoValido && alumno.IdKinder == idOld)
                            })
                        if (tieneAsignacionVigente) {
                            const errMsg = this.VB_GetUIStringModule("notif_noeliminarescuela").replace("_E", DataModuloMain._GetDataValueFieldByName("Escuela", idOld, "Nombre"));
                            this.notificacion._Mostrar(errMsg, "ADVERTENCIA");
                            return null;
                        }
                        arrEscRemove.push(idOld);
                    }
                }
                datoF.KinderFiltro?.forEach(idNew => {
                    if (!arrCurrent.includes(idNew)) arrEscNew.push(idNew);
                });
                return DataModuloTutor._Update(datoF, datoF.EsGeneral, arrEscNew, arrEscRemove)
            },
        });
    }

    private OpenModal_EliminarDatos(datosTutor: Array<ITutor>) {
        for (let tutor of datosTutor) {
            const alumnosAsignados = Array.from(DataModuloTutor._LOCALDATA_GetAsignacionesAlumnos(tutor.IdPadre, "todo").values());
            if (alumnosAsignados.length > 0) {
                const idEscuelaAsignacion = alumnosAsignados[0].Asignacion.IdKinder;
                this.notificacion._Mostrar(
                    UIUtilLang._GetUIString("tutores", "notif_noeliminar")
                        .replace("_T", tutor.NombreCompleto)
                        .replace("_E", DataModuloMain._GetDataValueFieldByName("Escuela", idEscuelaAsignacion, "Nombre")),
                    "ADVERTENCIA"
                );
                return;
            }
        }

        const map_norepeat = {};
        let nregistros = 0;
        datosTutor = datosTutor.filter(d => {
            nregistros++;
            if (map_norepeat[d.IdPadre])
                return false
            map_norepeat[d.IdPadre] = true;
            return true;
        })
        const someHasEscuelas = datosTutor.some(d => d.IdEscuelas != "")
        this.GridOpenModal_ProccessArrayData({
            DataToProccess: datosTutor,
            Width: 350,
            Message: this.VB_GetUIStringModule(someHasEscuelas ? "deletemsg_escs" : "deletemsg_global")
                .replace("%s", nregistros.toString()),
            OnGetIdEscuela: (dato) => (dato.KinderFiltro || []),
            OnError_GetItemDataTag: (dato) => (dato.NombreCompleto),
            OnStepAProccess: (dato) => DataModuloTutor._Delete(dato.IdPadre),
            OnEndAndCloseProccess: (successData, escuelas, results) => {
                if (!successData.length && results.some(d => d.Resultado == -1)) {
                    MainPage._ReloadService(Entidad.CTipoRequest.TutorAsignacion);
                    MainPage._ReloadService(Entidad.CTipoRequest.Tutor);
                }
            }
        });
    }

    private OpenModal_CargaMasiva() {
        let timeout: NodeJS.Timeout = null;
        const fnReloadGridData = () => {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null
            }
            timeout = setTimeout(() => {
                MainPage._ReloadService(this.GRID_GetDataRequestID());
            }, 4000);
        }

        ExcelThingsV2._UploadExcelData<ITutor>({
            TableId: "Tutores-CargaMasiva",
            ValidateUniqueItems: true,
            Columns: [
                { Field: "Nombre", Label: this.VB_GetUIStringModule("d_field_nombre"), Required: true, UIMinWidthTH: "150px" },
                { Field: "ApPaterno", Label: this.VB_GetUIStringModule("d_field_appaterno"), Required: true, UIMinWidthTH: "150px" },
                { Field: "ApMaterno", Label: this.VB_GetUIStringModule("d_field_apmaterno"), Required: false, UIMinWidthTH: "150px" },
                { Field: "Telefono", Label: this.VB_GetUIStringModule("d_field_telefono"), Required: true, UIMinWidthTH: "150px", ValueFormat: "phonenumber", RequiredValueType: "string" },
                {
                    Field: "Correo", Label: this.VB_GetUIStringModule("d_field_correo"), Required: true, UIMinWidthTH: "240px",
                    ValueFormat: "email", UniqueValue: true,
                    // OnGetErrorType: (correo: string) => {
                    //     const correoF = correo.trim().toLowerCase();
                    //     let correoExistente = DataModuloMain.fn_GetReqDataArrayByName("Tutor")
                    //         .find((tutor) => (correoF == tutor.Correo.trim().toLowerCase())) != null;
                    //     return {
                    //         ErrorType: correoExistente ? "correo_existe" : null,
                    //         Valid: !correoExistente
                    //     }
                    // },
                    // OnGetErrorMessage: (value, errorType) => (errorType == "correo_existe") ? this.VB_GetUIStringModule("error_email_existe") : null,
                }
            ],
            OnGetTemplateFile: () => DataModuloTutor._DownloadExcelTemplate(),
            ConfigActionUploadSelection: {
                TypeUpload: "oneByOne",
                OnCallItemErrorTag: (dato) => (dato.Nombre + " " + dato.ApPaterno + " " + dato.ApMaterno),
                OnCallBeforeToProccess: () => {
                    return ModalThings._GetConfirmacionBasico(
                        UIUtilLang._GetUIString("general", "msjconfirma"),
                        UIUtilLang._GetUIString("tutores", "confirma_cargamasiva_asignar")
                    )
                },
                OnCallService: async (datoT) => {
                    let res = await DataModuloTutor._Insert(datoT, []); // FIXME // FIXME // FIXME

                    if (res.Resultado > 0) {
                        fnReloadGridData();
                    }

                    return res;
                }
            }
        });
    }

    protected GRID_GetExportarConfig(dataGrid: ITutor[]): IConfigGridExcelExport<ITutor> {
        const bolUserAdmin = (DataUtil._Usuario.Perfil == Entidad.CTipoPerfil.Admin);
        return {
            IdsEscuelas: [...new Set(dataGrid.map(d => Number(d.IdPadreEscuela.split("-")[1])))].filter(d => Boolean(d)),
            ColumnsConfig: this.ctrlTabla
                ._InfoColumns
                .filter(d => (bolUserAdmin || d.Field != "Contrasenia"))
                .map(d => ({
                    Field: d.Field as keyof ITutor,
                    HeaderTag: d.Label,
                    WidthCell: 25
                })),
            OnGetDataBySheets: async () => {
                const padresAux = dataGrid.map(d => UIUtilGeneral._ObjectClone(d))
                if (bolUserAdmin) {
                    const idsPadres = [...new Set(dataGrid.map(d => d.IdPadre))];
                    const resPass = await DataModuloTutor._GetPassword(idsPadres);
                    if (resPass.Resultado > 0) {
                        padresAux.forEach(d => {
                            d.Contrasenia = resPass.Data[d.IdPadre];
                        })
                    } else {
                        this.notificacion._Mostrar(UIUtilLang._GetUIString("general", "notif_fail_getpass"), "ADVERTENCIA");
                    }
                }
                return Array.from(d3Group(padresAux, d => Number(d.IdPadreEscuela.split("-")[1] || -1)))
                    .map((entrie) => ({
                        IdSheet: entrie[0], // IdEscuela
                        SheetName: (entrie[0] == -1 ? this.VB_GetUIStringModule("tag_sinasignaciones") : DataModuloEscuela._DiccEscuela.get(entrie[0]).Nombre),
                        Data: entrie[1],
                    }))
            },
            OnGetEscuelasTagInSheet: (datos) => {
                const idEscuela = Number(datos[0].IdPadreEscuela.split("-")[1] || -1);
                return (idEscuela == -1 ? this.VB_GetUIStringModule("tag_sinasignaciones") : DataModuloEscuela._DiccEscuela.get(idEscuela).Nombre)
            }
        }
    }

    // private OpenModal_VerContraseña(datoT: ITutor) {
    //     controlD3.ModalThings.fn_GetConfirmacionModal({
    //         Tittle: "Contraseña",
    //         Width: 300,
    //         Message: `Contraseña del usuario <b>${datoT.Correo}<b>`,
    //         DrawContent: async (container, modalThings) => {
    //             container.classed(Utils.FBoxOrientation.Vertical, true)
    //                 .style("row-gap", "10px");

    //             // lbl correo usuario
    //             container.select("label")
    //                 .style("overflow-wrap", "anywhere");

    //             let inputWrapper = container.append("div")
    //                 .attr("class", Utils.FBoxOrientation.Horizontal)
    //                 .style("column-gap", "10px");

    //             let input = inputWrapper.append("input")
    //                 .attr("class", "input-form")
    //                 .property("disabled", true)
    //                 .node();

    //             inputWrapper.append("img")
    //                 .style("width", "20px")
    //                 .attr("src", Utils.CIconResource.Copy)
    //                 .style("cursor", "pointer");

    //             inputWrapper.on("click", () => {
    //                 if (Utils.fn_CopyTextFromInput(input)) {
    //                     this.notificacion.fn_Mostrar(UIUtilLang.fn_GetUIString("general", "copiedtext"), "INFO");
    //                 }
    //             });

    //             modalThings.Progress.met_Show();
    //             let resPass = await this.Sv_ObtenerContrasenia(datoT.IdPadre);
    //             input.value = resPass.Data[datoT.IdPadre] || "";
    //             modalThings.Progress.met_Hide();

    //             if (resPass.Resultado < 0) {
    //                 this.notificacion.fn_Mostrar("Ocurrió un problema al consultar la información de acceso.", "ADVERTENCIA");
    //             }
    //         }
    //     })
    // }

    private static async Sv_ObtenerContrasenia(idTutor: number): Promise<string> {
        let res = await DataModuloTutor._GetPassword([idTutor]);
        return res.Data[idTutor];
    }
}
