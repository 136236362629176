import * as d3 from "d3";
import { UIUtilGeneral } from "../util/Util";
import { CheckBox } from "./CheckBox";
import { TreeViewBase } from "./TreeViewBase";

export namespace TreeViewV2 {

    type TIdType = (string | number);

    interface KToAddTreeItem {
        Status_ChildsVisibles: boolean;
        Status_IsChecked: boolean;
        Status_HasCheckbox: boolean;
        Status_HasCollapserIndicator: boolean;

        /** // NOTE aun no implementado */
        Status_IsEnable: boolean;
    }

    type TConfigToExtends = Pick<TreeViewBase.IControlConfig<any>, "ParentContainer" | "UseGetDataOfFirstLevel">;
    export interface IControlConfig<TDatasInLevels extends Array<any>, KToAddInViewItem = any> extends TConfigToExtends {
        LevelsConfig: TLevelsConfig<TDatasInLevels, KToAddInViewItem>;
        /** Si se habilita, los cambios cambio de check en un elemento afentan a los childs y padres
         * @default true
        */
        SmartCheckChange?: boolean;
    }

    type TLevelsConfig<TDatasInLevels extends Array<any>, KToAddInViewItem = any> = {
        [k in keyof TDatasInLevels]: ILevelConfig<TDatasInLevels[k], KToAddInViewItem>
    }
    type TOnCheckedOrigin = "click" | "treeevent";

    export interface ILevelConfig<TData, KToAddInViewItem = any> extends Omit<TreeViewBase.ILevelConfig<TData, KToAddInViewItem>, keyof Pick<TreeViewBase.ILevelConfig<TData, KToAddInViewItem>, "UI_OnCreateItemTemplate">> {
        OnGetData: (parent: TreeViewBase.ITreeItemToShare<any, any, TData>) => Array<TData>;
        /** NOTA: El valor de esta propiedad debe de ser unico en éste nivel con respecto a su Padre
         * (Los valores de la propiedad pueden repetirse siempre que no pertenescan al mismo Padre)
         */
        IdMember?: keyof TData & string;

        OnGetHasCheckBox?: (dato: TData) => boolean;
        OnGetHasCollapserIndicator?: (dato: TData) => boolean;
        OnGetInitCheckStatus?: (dato: TData, lastCheckStatus: boolean) => boolean;
        OnGetInitChildsVisiblesStatus?: (dato: TData, lastCheckStatus: boolean) => boolean;

        OnCheckAItem?: (dato: TData, status: boolean, originEvent: TOnCheckedOrigin) => void;
        // Data_OnUpdateTreeDataItem?: <VLevel extends number>(data: (ITreeViewItem<VLevel> & KToAddInViewItem), lastItemData: (ITreeViewItem<VLevel> & KToAddInViewItem), parent: ITreeItemToShare<any, any, TData>, level: VLevel) => void;

        UI_OnUpdateItem: (itemContainer: TSelectionHTML<"div">, stepDato: TData) => void;

        /** No usar */
        // UI_OnCreateItemTemplate: (itemContainer: SelectionHTML<"div">, stepDAto: TData) => void;
    }

    interface ISelectedItem<TDato, TTreeView extends TTreeViewV2<TDato> = TTreeViewV2<TDato>> {
        /** En caso de que el dato no se encuentre su valor será undefined */
        readonly Id: TIdType;
        readonly IsChecked: boolean;
        // readonly IsEnable: boolean; // NOTE Decomentar cuando se implemente Status_IsEnable
        readonly IsVisiblesChilds: boolean;
        //readonly IsCollapse: boolean;
        /** En caso de que el dato no se encuentre su valor será undefined */
        Data: TDato;
        ChildrenData: Map<TIdType, TDato>; // ISelectedItem<TDato, TTreeView | any>>;
        Parent: ISelectedItem<TTreeView | any>;
        /** Selecciona un elemento dentro de los datos colapsables del item seleccionado actual */
        SelectChildItem: <TDataChild>(id: TIdType) => ISelectedItem<TDataChild>;
        /** Cambia el estado "Checkeado" del dato, no actualiza UI hasta que se refresque */
        CheckItem: (check: boolean) => ISelectedItem<TDato>;
        /** Cambia el estado "Checkeado" de los datos hijos, no actualiza UI hasta que se refresque
         * @param check
         * @param deep {boolean}
         * * `true` afecta a los hijos en todos los niveles
         * * `false` afecta solo a los hijos inmediatos
         * @default true
        */
        CheckChilds: (check: boolean, deep?: boolean) => ISelectedItem<TDato>;
        /** Cambia el estado "Checkeado" de los/el padre(s), no actualiza UI hasta que se refresque
         * @param check
         * @param bubble
         * * `true` afecta a los padres en todos los niveles superiores
         * * `false` afecta solo al padre inmediato
         * @default true
        */
        CheckParents: (check: boolean, bubble?: boolean) => ISelectedItem<TDato>;
        /** Cambia el estado "Colapsado" de la fila, no actualiza UI hasta que se refresque */
        ItemChildsVisibles: (collapse: boolean) => ISelectedItem<TDato>;
        /** Refresca los elementos UI del TreeView */
        RefreshTreeView: () => ISelectedItem<TDato | any>;
        // Collapse: (collapse: boolean) => void;
    }

    type TTreeViewV2<TDato = any> = TreeViewBase.ITreeViewItem<KToAddTreeItem, TDato> & KToAddTreeItem;

    export class TreeViewControl<TLevelsTypes extends Array<any>> extends TreeViewBase.TreeView<TLevelsTypes, KToAddTreeItem> {
        protected levelsConfigTreeV2: Map<number, ILevelConfig<any>>;

        constructor() {
            super();
        }

        /** Retorna información y métodos relacionados con los estados del dato encontrado */
        private GetSelectItem<TDato, TTreeView extends TTreeViewV2<TDato> = TTreeViewV2<TDato>>(itemOrId: (TTreeView | TIdType), collectionData?: Map<TIdType, TTreeView>) {
            const itemFinded: TTreeView = (typeof itemOrId == "number" || typeof itemOrId == "string") ? collectionData?.get(itemOrId) : itemOrId;
            // const levelConfig = this.levelsConfigTreeV2.get(itemFinded?.Level);

            const selectItem: ISelectedItem<TDato> = {
                Id: itemFinded?.Id,
                Data: itemFinded?.Data,
                IsChecked: (itemFinded ? itemFinded.Status_IsChecked : false),
                // IsEnable: (itemFinded ? itemFinded.Status_IsEnable : false), // NOTE descomentar cuando se implemente
                IsVisiblesChilds: (itemFinded ? itemFinded.Status_ChildsVisibles : false),
                Parent: itemFinded.Parent ? this.GetSelectItem<any>(itemFinded.Parent) : null,
                ChildrenData: (itemFinded ? new Map(Array.from(itemFinded.Childs.values()).map(d => ([d.Id, d.Data]))) : new Map()), // this.GetSelectItem<TDataChild, any>(undefined, undefined, d)]))) : new Map()),
                SelectChildItem: (id) => {
                    return this.GetSelectItem<any>(id, ((itemFinded && itemFinded.Childs) ? itemFinded.Childs : new Map()));
                },
                CheckItem: (check) => {
                    if (itemFinded) this.CheckItem(itemFinded, check, "treeevent");
                    return selectItem;
                },
                CheckChilds: (check: boolean, deep = true) => {
                    if (itemFinded) this.CheckChilds(itemFinded, check, deep);
                    return selectItem;
                },
                CheckParents: (check: boolean, bubble = true) => {
                    if (itemFinded) this.CheckParents(itemFinded, check, bubble);
                    return selectItem;
                },
                ItemChildsVisibles: (childsVisibles: boolean) => {
                    if (itemFinded) itemFinded.Status_ChildsVisibles = childsVisibles;
                    return selectItem;
                },
                RefreshTreeView: () => {
                    this._RefreshView();
                    return selectItem;
                }
            }
            return selectItem;
        }

        private CheckItem(dato: TTreeViewV2<any>, check: boolean, originEvent: TOnCheckedOrigin) {
            if (dato.Status_IsChecked == check) return;
            dato.Status_IsChecked = check;
            const levelConfig = this.levelsConfigTreeV2.get(dato?.Level);
            if (levelConfig?.OnCheckAItem) {
                levelConfig.OnCheckAItem(dato.Data, check, originEvent);
            }
            this._RefreshView();
        }

        private CheckChilds(dato: TTreeViewV2<any>, check: boolean, deep = true) {
            for (const [_, child] of dato.Childs) {
                this.CheckItem(child, check, "treeevent");
                if (deep)
                    this.CheckChilds(child, check, deep);
            }
        }

        private CheckParents(dato: TTreeViewV2<any>, check: boolean, bubble = true) {
            if (!dato.Parent) return;
            const parentHasCheckedChilds = Array.from(dato.Parent.Childs.values()).some(child => child.Status_IsChecked);
            if (!check && parentHasCheckedChilds) return;
            this.CheckItem(dato.Parent, check, "treeevent");
            if (!bubble) return;
            this.CheckParents(dato.Parent, check, bubble);
        }

        // **************************************************************
        // PUBLIC PROPERTIES
        // **************************************************************

        public _SetConfig(config: IControlConfig<TLevelsTypes>) {
            // TreeViewBase.TLevelsConfig<TLevelsTypes, KToAddTreeItem>
            config.SmartCheckChange = config.SmartCheckChange === undefined ? true : config.SmartCheckChange;
            this.levelsConfigTreeV2 = new Map(config.LevelsConfig.map((configL, i) => {
                return [i, configL];
            }));

            let levelsConfig = config.LevelsConfig.map<TreeViewBase.ILevelConfig<TLevelsTypes[any], KToAddTreeItem>>((levelConfig, level) => ({
                IdMember: levelConfig.IdMember,
                OnGetData: levelConfig.OnGetData,
                UI_OnUpdateItem: (itemContainer, dato) => {
                    const treeItem: (TreeViewBase.ITreeViewItem<KToAddTreeItem, typeof dato> & KToAddTreeItem) = itemContainer.datum();
                    const parentItemUI = d3.select(itemContainer.node().parentNode as HTMLDivElement);

                    let ui_checkbox: CheckBox.ControlSelection;
                    let ui_collapser: TSelectionHTML<"wc-ic-collapse">;
                    let ui_text = itemContainer.select<HTMLDivElement>(".treeitem_contentdata");

                    // -> CHECKBOX THINGS

                    if (treeItem.Status_HasCheckbox) {
                        ui_checkbox = itemContainer.select(".checkbox_table");
                        if (!ui_checkbox.node()) {
                            ui_checkbox = CheckBox._GetCheckElement();
                            itemContainer.append(() => ui_checkbox.node());
                        }
                        CheckBox._UpdateCheckStatus(ui_checkbox, treeItem.Status_IsChecked);

                        ui_checkbox.node().onclick = (e) => {
                            treeItem.Status_IsChecked = !treeItem.Status_IsChecked;
                            CheckBox._UpdateCheckStatus(ui_checkbox, treeItem.Status_IsChecked);
                            if (config.SmartCheckChange) {
                                this.CheckChilds(treeItem, treeItem.Status_IsChecked);
                                this.CheckParents(treeItem, treeItem.Status_IsChecked);
                            }
                            if (levelConfig.OnCheckAItem) {
                                levelConfig.OnCheckAItem(treeItem.Data, treeItem.Status_IsChecked, "click");
                            }
                            this._RefreshView();
                        }
                    } else {
                        itemContainer.select(".checkbox_table").remove();
                    }

                    // -> COLLAPSER CHILDS THINGS
                    const UpdateChilds = (childs: Map<any, any>) => {
                        if (childs.size > 0 && treeItem.Status_ChildsVisibles) {
                            let childsContainer = parentItemUI.select<HTMLDivElement>(":scope > .treeview_childscontainer");

                            if (!childsContainer.node()) {
                                childsContainer = parentItemUI.append("div")
                                    .classed("treeview_childscontainer", true)
                            }

                            childsContainer.raise();
                            this.UI_UpdateLevelList(childsContainer, childs, level + 1);

                        } else {
                            parentItemUI.select(":scope > .treeview_childscontainer").remove()
                        }
                    }

                    UpdateChilds(treeItem.Childs);

                    // -> COLLAPSER INDICATOR THINGS

                    if (treeItem.Childs.size > 0 && treeItem.Status_HasCollapserIndicator) {
                        ui_collapser = itemContainer.select("wc-ic-collapse");

                        if (!ui_collapser.node()) {
                            ui_collapser = itemContainer.append("wc-ic-collapse");
                        }

                        ui_collapser.node()._Collapsed = !treeItem.Status_ChildsVisibles;

                        ui_collapser.node().onclick = e => {
                            treeItem.Status_ChildsVisibles = !treeItem.Status_ChildsVisibles;
                            ui_collapser.node()._Collapsed = !treeItem.Status_ChildsVisibles;

                            let childs = treeItem.Status_ChildsVisibles ? treeItem.Childs : new Map();

                            UpdateChilds(childs);
                        }
                    } else {
                        itemContainer.select("wc-ic-collapse").remove();
                    }

                    // ->
                    levelConfig.UI_OnUpdateItem(itemContainer, dato);

                    // -> ORDER ITEM_CONTENT ELEMENTS
                    ui_checkbox?.raise();
                    ui_collapser?.raise();
                    ui_text?.raise();
                },
                UI_OnCreateItemTemplate: (container, dato) => {
                    container
                        .classed(UIUtilGeneral.FBoxOrientation.Horizontal, true)
                        .classed(UIUtilGeneral.FBoxAlign.StartCenter, true)
                        .style("padding-left", (level * 25) + "px")

                    container.append("div")
                        .classed("treeitem_contentdata", true);
                },
                Data_OnUpdateTreeDataItem: (newData, lastData, parent, level) => {
                    newData.Status_HasCheckbox = levelConfig.OnGetHasCheckBox ? levelConfig.OnGetHasCheckBox(newData.Data) : true;
                    newData.Status_HasCollapserIndicator = levelConfig.OnGetHasCollapserIndicator ? levelConfig.OnGetHasCollapserIndicator(newData.Data) : true;
                    // newData.Status_IsEnable = true; // NOTE Descomentar cuando se implemente

                    let lastCheckStatus = (lastData ? lastData.Status_IsChecked : false);
                    if (levelConfig.OnGetInitCheckStatus) {
                        newData.Status_IsChecked = levelConfig.OnGetInitCheckStatus(newData.Data, lastCheckStatus);
                    } else {
                        newData.Status_IsChecked = lastCheckStatus;
                    }

                    let lastChildsVisiblesStatus = (lastData ? lastData.Status_ChildsVisibles : false);
                    if (levelConfig.OnGetInitChildsVisiblesStatus) {
                        newData.Status_ChildsVisibles = levelConfig.OnGetInitChildsVisiblesStatus(newData.Data, lastChildsVisiblesStatus);
                    } else {
                        newData.Status_ChildsVisibles = lastChildsVisiblesStatus;
                    }

                    // newData.Status_ChildsVisibles = lastData ? lastData.Status_ChildsVisibles : false;
                }
            }))

            super._SetConfig({
                ParentContainer: config.ParentContainer,
                UseGetDataOfFirstLevel: config.UseGetDataOfFirstLevel,
                LevelsConfig: levelsConfig as any
            });
            return this;
        }
    }
}
