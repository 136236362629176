
import * as d3 from "d3";
import { Main } from "../../Main";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import { DataUtil } from "../../data/util/Util";
import { UIUtilLang } from "../util/Language";
import { NotificacionV2 } from "./NotificacionV2";
import CModulo = Entidad.CModulo;
import { DataUtilLocalStorage } from "../../data/util/LocalStorage";

export class VentanaBase<Modulo extends CModulo = CModulo> {
    private statusKey: string;
    protected readonly modulo: Modulo;
    protected readonly labelsKeyBase: string;

    private readonly windowContainer: TSelectionHTML<"div">;
    protected readonly windowContent: TSelectionHTML<"div">;
    protected readonly notificacion?: NotificacionV2;
    private winStatus: Object;

    constructor(container: d3.Selection<HTMLDivElement, undefined, HTMLElement, any>, modulo?: Modulo, labelsKeyBase?: string) {
        this.windowContainer = container;
        this.windowContent = d3.create("div")
            .classed("window_base", true);
        this.modulo = modulo;
        this.labelsKeyBase = labelsKeyBase || (modulo != null ? CModulo[modulo] : "");
        this.notificacion = new NotificacionV2();
    }

    /** Retorna el string asignado al modulo actual (si aplica) de acuerdo a @param stringKey,
     * Si no se encuentra retorna el mismo parámetro
    */
    protected VB_GetUIStringModule(stringKey: string): string {
        if (this.labelsKeyBase != null) {
            return (UIUtilLang._GetUIString(this.labelsKeyBase, stringKey) || stringKey);
        }
        return stringKey;
    }

    protected VB_SetStatusKey(key: string) {
        this.statusKey = key;
        let jsonString = DataUtilLocalStorage._GetItem("winstatus", "json");
        this.winStatus = (jsonString ? JSON.parse(jsonString) : {});
        return this;
    }

    protected VB_GetStatusObject<T>(): T {
        if (!this.statusKey) {
            console.error("statusKey is undefined");
            return null;
        }
        if (this.winStatus[this.statusKey]) {
            return this.winStatus[this.statusKey];
        }
        return null;
    }

    protected VB_SetStatusProp<T>(prop: (keyof T & string), val: T[typeof prop]) {
        if (this.statusKey) {
            if (!this.winStatus[this.statusKey]) {
                this.winStatus[this.statusKey] = {};
            }
            if (val != null)
                this.winStatus[this.statusKey][prop] = val;
            else
                delete this.winStatus[this.statusKey][prop];
            let jsonString = JSON.stringify(this.winStatus);
            DataUtilLocalStorage._SetItem("winstatus", "json", jsonString);
        }
        else {
            console.error("statusKey is undefined");
        }
        return this;
    }

    protected VB_GetStatusProp<T>(prop: (keyof T & string)): T[typeof prop] {
        if (this.statusKey) {
            if (this.winStatus[this.statusKey]) {
                return this.winStatus[this.statusKey][prop];
            }
        }
        else {
            console.error("statusKey is undefined");
        }
        return null;
    }

    public _Mostrar(): void {
        this.windowContainer.append(() => this.windowContent.node());
    }

    public _Destroy(): void {
        this.notificacion._DestroyAll();
        this.windowContent.remove();
    }

    /**
     * Se invoca con cada respuesta de un CTipoRequest
     * @param eventName CTipoRequest
     * @param reloadId
     * * n -> Valor que se obtiene al invocar app.ReloadService
     * * -1 -> Reservado para datos consultados de la BD local (IndexDB)
     * * -2 -> Reservado para respuestas de los servicios sin invocar app.ReloadService, reload automático
     * @param error 
     */
    public _OnServiceEvent(eventName: DataModuloMain.TipoRequestMonitorId, reloadId?: number, error?: Error): void {
        if (eventName == Entidad.CTipoRequest.Escuela) {
            Main._UpdateMainMenu();
        }
    }
}
