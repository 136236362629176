import { Entidad } from "../Entidad";
import { DataIndexedDB } from "../indexedDB/DB";
import DataModuloViewInfo from "../modulo/ViewInfo";

export interface IViewInfoItem {
    Id: number;
    IdParent: number;
    Path: string;
    // Title?: string; // Remover ?
    Description: string;
    Module?: Entidad.CModulo;
    Action?: Entidad.CAccionPermiso;
    EnUso?: boolean;
    Modificacion: string; // Simular la consulta por fecha de modificación

    Readed?: boolean;
}

export namespace DataUtilViewInfo {

    export var _DictViewInfo = new Map<string, Map<number, IViewInfoItem>>();

    export async function _Init() {
        let localData: IViewInfoItem[] = []
        let maxDate: string;

        await DataIndexedDB._GetRows<IViewInfoItem>("tblViewInfo")
            .then(res => {
                localData = res;
            })
            .catch(res => null);
        // console.warn("Init");

        RefreshInfoDict(localData);

        if (localData?.length) {
            maxDate = localData.reduce((a, b) => (new Date(a.Modificacion) > new Date(b.Modificacion)) ? a : b)
                ?.Modificacion;
        }

        let dataRequest = await DataModuloViewInfo._ObtenerViewInfo(maxDate);

        RefreshInfoDict(dataRequest);

        await DataIndexedDB._SetRowsV2({ storeName: "tblViewInfo", listRows: dataRequest });
    }

    function RefreshInfoDict(viewInfoData: IViewInfoItem[]) {
        viewInfoData
            ?.forEach(d => {
                if (d.EnUso == true || d.EnUso == undefined) {
                    let viewData = _DictViewInfo.get(d.Path);

                    if (!viewData) {
                        viewData = new Map();
                        _DictViewInfo.set(d.Path, viewData);
                    }

                    viewData.set(d.Id, d);
                }
                else {
                    let viewData = _DictViewInfo.get(d.Path);

                    if (viewData) {
                        viewData.delete(d.Id);

                        if (viewData.size == 0) {
                            _DictViewInfo.delete(d.Path);
                        }
                    }
                }
            })
    }

    export function _GetViewInfoOfPath(path: string): IViewInfoItem[] {
        let result: IViewInfoItem[];
        let pathData = _DictViewInfo
            .get(path);

        if (pathData) {
            result = Array.from(pathData.values());
        } else {
            result = [];
        }

        return result
            .sort((a, b) => (a.IdParent - b.IdParent))
            .map(d => Object.assign({}, d));
    }

    export async function _SetIdReaded(path: string, ids: number[]) {
        let dataReaded: IViewInfoItem[] = [];
        let pathData = _DictViewInfo.get(path);

        if (pathData) {
            ids
                .forEach(id => {
                    if (pathData.has(id)) {
                        let pathItem = pathData.get(id);
                        pathItem.Readed = true;
                        dataReaded.push(pathItem);
                    }
                });

            if (dataReaded.length) {
                // let res =
                await DataIndexedDB._SetRowsV2({ storeName: "tblViewInfo", listRows: dataReaded });
                // console.log("View info items saved as readed: ", res, path, ids, dataReaded);
            }
        }
    }
}
