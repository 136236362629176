export namespace UIUtilColor {
    const RGBLIMITS = [
        [0, 255], // R
        [0, 255], // G
        [0, 255], // B
    ]
    function FixCode(value, i) {
        const min = RGBLIMITS[i][0];
        const max = RGBLIMITS[i][1];
        while (value < min) {
            value = max - (min - value);
        }
        while (value > max) {
            value = min + (value - max);
        }
        return value;
    }
    // /**
    //  * @reference https://gist.github.com/0x263b/2bdd90886c2036a1ad5bcf06d6e6fb37
    //  */
    // export function fn_StringToHex(string: string): string {
    //     let hash = 0;
    //     if (string.length === 0) return null;
    //     for (let i = 0; i < string.length; i++) {
    //         hash = string.charCodeAt(i) + ((hash << 5) - hash);
    //         hash = hash & hash;
    //     }
    //     let color = '#';
    //     for (let i = 0; i < 3; i++) {
    //         let value = (hash >> (i * 8)) & 255;
    //         value = FixCode(value, i);
    //         color += ('00' + value.toString(16)).slice(-2);
    //     }
    //     return color;
    // }

    /**
     * @reference https://gist.github.com/0x263b/2bdd90886c2036a1ad5bcf06d6e6fb37
     */
    export function _StringToRGB(string: string, opacity = 1): string {
        let hash = 0;
        if (string.length === 0) return null;
        for (let i = 0; i < string.length; i++) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
            hash = hash & hash;
        }
        let rgb = [0, 0, 0];
        for (let i = 0; i < rgb.length; i++) {
            let value = (hash >> (i * 8)) & 255;
            value = FixCode(value, i);
            rgb[i] = value;
        }
        return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${opacity})`;
    }
}
