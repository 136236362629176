import { Entidad } from "../data/Entidad";
import { DataUtil } from "../data/util/Util";
import { UIUtilPermission } from "../ui/util/Permission";
import { _ROUTES, TRoutePath } from "./Routes";

export namespace Router {
    export function _GetRouteView(path: TRoutePath) {
        if (path === 'usuarios' && DataUtil._Usuario.Perfil == Entidad.CTipoPerfil.Limitado) {
            return null;
        }
        const pantallaDisponible = _ROUTES[path];
        if (!pantallaDisponible) {
            return null;
        }
        if (pantallaDisponible.IdModulo) {
            if (!UIUtilPermission._HasModulePermission(pantallaDisponible.IdModulo)) return undefined;
            if (!UIUtilPermission._IsConfigurableModuleAvailable(pantallaDisponible.IdModulo)) return undefined;
        }
        return pantallaDisponible;
    }
}
