import { DataDRequest } from "../../data/DRequest";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import DataModuloEscolaridad from "../../data/modulo/Escolaridad";
import DataModuloEscuela from "../../data/modulo/Escuela";
import { IConfigCardCollapseExcelExport } from "../controlD3/CardCollapseAdvancedTable";
import { TCARDV2COLL_OnEditOriginEvent } from "../controlD3/CardV2Collapse";
import { CardV2CollapseAdvancedTable } from "../controlD3/CardV2CollapseAdvancedTable";
import { Table } from "../controlD3/Tabla";
import { UIUtilGeneral } from "../util/Util";
import { UIUtilViewData } from "../util/ViewData";
import { UIUtilViewAlumno } from "../utilView/Alumno";
import { UIUtilViewGrupos } from "../utilView/Grupos";
import IAlumno = Entidad.IAlumno;
import IEscolaridad = Entidad.IEscolaridad;
import { TCARDCOLL_OnEditOriginEvent } from "../controlD3/CardCollapse";

export class UIPanelCardEscuelasNivelAlumnos extends CardV2CollapseAdvancedTable<IAlumno, [IEscolaridad]> {
    constructor() {
        super("card_headertitle", Entidad.CModulo.Alumnos, "escuelapanel_nivelalumnado");
    }

    protected CARDCOLLADTAB_Table_GetConfig(): Omit<Table.IConfig<IAlumno>, keyof Pick<Table.IConfig<IAlumno>, "Parent">> {
        return {
            IdTabla: "EscuelasPanelNivelAlumnos",
            IdData: "IdChild",
            OrderDefault: {
                Field: "NombreCompleto",
                Type: Table.CStatusOrder.Asc,
            },
            MinWidth: 1100,
            RenderColumnHeadings: [
                { Field: "getThumbnailURL", Label: "", MinWidth: "45px", Width: "5%", IsSortable: false },
                { Field: "NombreCompleto", Label: "Nombre", MinWidth: "80px", Width: "30%" },
                { Field: "Matricula", Label: "Matrícula", MinWidth: "95px", Width: "20%" },
                { Field: "StrSexo", Label: "Sexo", MinWidth: "50px", Width: "15%" },
                { Field: "StrEstado", Label: "Estado", MinWidth: "65px", Width: "15%" },
                { Field: "FechaNacimiento", Label: "Nacimiento", MinWidth: "95px", Width: "20%" },
                { Field: "StrGrupoPrincipal", Label: "Grupos", MinWidth: "70px", Width: "20%" },
                { Field: "StrGrado", Label: "Grado", MinWidth: "60px", Width: "20%" },
            ],
            OnValueSelectRow: (id, datum) => console.debug(id, datum),
            OptionsOfDataCheckV3: () => this.CARDCOLLADTAB_GetTableOptionsToSelectedData(this.cardData[0].IdEscuela, "top-selected"),
            FilterParameters: [
                { LangModuleKeyInContext: "alumnos", LabelLangKey: "d_field_nombre", Field: "Nombre" },
                { LangModuleKeyInContext: "alumnos", LabelLangKey: "d_field_appaterno", Field: "ApPaterno" },
                { LangModuleKeyInContext: "alumnos", LabelLangKey: "d_field_apmaterno", Field: "ApMaterno" },
                { LabelLangKey: "d_field_matricula", Field: "Matricula" },
                {
                    LabelLangKey: "d_field_strsexo",
                    Field: "Sexo",
                    Type: "select",
                    Options: UIUtilViewData._GetList_Sexo()
                },
                {
                    LabelLangKey: "d_field_strestado",
                    Field: "IdChildMovimiento",
                    Type: "select",
                    Options: UIUtilViewData._GetList_EstadosMovimientos()
                        .filter(d => (
                            (d.Id == Entidad.CNinioMovimiento.Activo)
                            || (d.Id == Entidad.CNinioMovimiento.Preinscripcion)
                            || (d.Id == Entidad.CNinioMovimiento.BajaTemporal)))
                },
                { LabelLangKey: "d_field_fechanacimiento", Field: "FechaNacimiento", Type: "date", MaxDate: new Date().toISOString() },
                {
                    Label: "Grupo principal", Field: "StrGrupoPrincipal", Type: "text",
                    OnGetValueToMatch: (dato: IAlumno) => dato.StrGrupoPrincipalNames
                },
                { Label: "Grado", Field: "StrGrado" },
            ],
            EvaluatorAndSubLevelsBuild: {
                // GetOptionsInRow: () => this.CARDCOLLAD_GetTableOptionsToSelectedData(this.cardData[0].IdKinder, "row"),
                OnStepCellTable: (container, datum, field: keyof IAlumno) => {
                    switch (field) {
                        case "getThumbnailURL":
                            UIUtilViewAlumno._UIUpdateFotoAlumno(container, datum);
                            break;
                        case "FechaNacimiento":
                            container.text(datum.getFechaNacimientoFmt);
                            break;
                        case "NombreCompleto":
                            UIUtilGeneral._ElementAdd_LinkToGoToPanel(container, "alumnos/alumnos/panel", datum.IdChild);
                            break;
                        case "StrGrupoPrincipal":
                            UIUtilViewGrupos._ApplyLblsGruposToContainer(container, datum.IdChild);
                            break;
                    }
                }
            }
        }
    }

    protected CARDCOLLADTAB_Table_GetMenuTop(): Table.ITableMenuTopDefaultOptionConfig[] {
        return null;
    }

    protected CARDCOLLADTAB_GetExportarConfig(dataTable: IAlumno[]): IConfigCardCollapseExcelExport<IAlumno> {
        const escolaridad = this.cardData[0];
        return {
            FileName: escolaridad.NombreEscuela + " - " + escolaridad.Nombre + " - Alumnos",
            IdsEscuelas: [escolaridad.IdEscuela],
            TypeRequest: Entidad.CTipoRequest.Escuela,
            ColumnsConfig: this.ctrlTabla._InfoColumns
                .filter(d => (d.Field != "getThumbnailURL"))
                .map(d => ({
                    Field: d.Field as keyof IAlumno,
                    HeaderTag: d.Label,
                    WidthCell: 25
                })),
            OnGetDataBySheets: async () => {
                return [{
                    IdSheet: escolaridad.IdEscuela, // IdEscuela
                    SheetName: escolaridad.Nombre,
                    Data: dataTable,
                }]
            },
            OnGetEscuelasTagInSheet: (datos) => DataModuloEscuela._DiccEscuela.get(escolaridad.IdEscuela).Nombre,
            OnStepFieldInDataRow: (field, dato) => {
                if (field == "FechaNacimiento" && dato[field]) {
                    return dato.getFechaNacimientoFmt;
                }
                return dato[field] as string;
            }
        }
    }

    protected CARDCOLL_OnInitBuild(contentContainer: TSelectionHTML<"div", any, any>): void {
        this.btnEditarCard._d3Selection.remove();
        this.cardFooterSelection.remove();
        this.cardHeaderSelection.remove();
    }

    protected CARDCOLL_GetVariantToValidateUpdate(cardData_0: IEscolaridad): string {
        return null;
    }

    protected CARDCOLL_OnUpdateData(cardData_0: IEscolaridad): void {
        const alumnos = DataModuloEscolaridad._LOCALDATA_GetAlumnosEnEscolaridad(cardData_0.Id);
        this.ctrlTabla._UpdateData(Array.from(alumnos.values()));
    }

    protected CARDCOLL_MostrarBody(): void {
        this.cardSelection.style("height", "100%");
    }

    protected CARDCOLL_OcultarBody(): void {
        this.cardSelection.style("height", null);
    }

    protected CARDCOLL_OnEditarCard(originEvent: TCARDCOLL_OnEditOriginEvent): void {
        // throw new Error("Method not implemented.");
    }

    protected CARDCOLL_OnCancelaEditarCard(originEvent: TCARDV2COLL_OnEditOriginEvent): void {
    }

    protected CARDCOLL_GuardarCardV2(): Promise<DataDRequest.IRequestResponseA<any>> {
        throw new Error("Method not implemented.");
    }

    protected CARDCOLL_SyncOrGetIdToDownloadData(): DataModuloMain.TipoRequestMonitorId | (() => Promise<any>) | DataModuloMain.TipoRequestMonitorId[] {
        return Entidad.CTipoRequest.Alumno;
    }

    protected CARDCOLL_GetIdSchool(cardData_0_0: IEscolaridad): number {
        return cardData_0_0?.IdEscuela;
    }
}
