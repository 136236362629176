import { Entidad } from "../Entidad";
import { Global } from "../Global";
import { DataDRequest } from "../DRequest";
import { DataUtil } from "../util/Util";
import { DataModuloMain } from "../ModuloMain";
import DataModuloGrupo from "./Grupo";
import { IConfiguracionFacturacion } from "../entidad/Factura";

/** Diccionario con las escuelas a las que el usuario tiene acceso */
export const _DiccEscuela: Map<number, Entidad.IEscuela> = new Map();

/** Diccionario con todas las escuelas (EnUso == false) de la organización a la que pertenece el usuario */
export const _DiccFullEscuelas: Map<number, Entidad.IEscuela> = new Map();

/** Map<idEscuela, Map<idEscolaridad, Escolaridad>> */
export const _DiccAsignacionEscuelaEscolaridades: Map<number, Map<number, Entidad.IEscolaridad>> = new Map();

type TIKinder = Entidad.IEscuela;
const RequestID = Entidad.CTipoRequest.Escuela;

export async function _GetList(kinders: Array<TIKinder>): Promise<DataDRequest.IRequestResponseListA<TIKinder>[]> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "escuela/ObtenerLista"; // "AdminJS/Kinder/ObtenerKinders";
    const rowID = RequestID;
    const currentDateStr = await DataUtil._GetMaxDate(RequestID, rowID);
    const params = {
        "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
        "Modificacion": currentDateStr
    }

    let result = await DataDRequest._Request4List(url, "GET", params, RequestID, rowID);
    return [result];
}

export async function _ActualizarLogo(idEscuela: number, logoFile: File): Promise<DataDRequest.IResultadoPeticion<null>> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "escuela/ActualizarLogo";

    let formData = new FormData();
    formData.append("IdUsuario", DataUtil._Usuario.IdUsuario.toString());
    formData.append("IdEscuela", idEscuela.toString());
    formData.append("Logo", logoFile, logoFile.name);

    return DataDRequest._Request3(url, "POST", formData, RequestID)
}

export async function _AltaEscuela(dato: Partial<Entidad.IEscuela>, logo: File): Promise<DataDRequest.IResultadoPeticion<null>> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "escuela/NuevaEscuelaV3"; // "NuevaEscuela";
    const formData = new FormData();
    formData.append("IdOrganizacion", DataUtil._Usuario.IdOrganizacion.toString());
    formData.append("IdUsuario", DataUtil._Usuario.IdUsuario.toString());
    formData.append("Nombre", dato.Nombre);
    formData.append("Direccion", dato.Direccion);
    formData.append("HoraEntradas", dato.HoraEntradas.map(d => (d ? ('"' + d + '"') : String(d))).toString());
    formData.append("HoraSalidas", dato.HoraSalidas.map(d => (d ? ('"' + d + '"') : String(d))).toString());
    formData.append("Tamanio", dato.Tamanio.toString())
    formData.append("TipoLicencia", Entidad.CTipoLicencia.Mensual.toString()) // dato.TipoLicencia.toString())
    formData.append("Telefono", dato.Telefono);
    formData.append("Correo", dato.Correo.trim());

    formData.append("ZonaHoraria", dato.ZonaHoraria); // "America/Mexico_City");

    if (logo) {
        formData.append("Logo", logo, logo.name);
    } else {
        formData.append("Logo", "");
    }

    formData.append("CodigoPais", dato.CodigoPais)

    return DataDRequest._Request3(url, "POST", formData, RequestID);
}

export async function _ActualizaEscuela(dato: Partial<Entidad.IEscuela>) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "escuela/ActualizarEscuelaV2"; // "ActualizarEscuela";
    const params = {
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "IdEscuela": dato.IdKinder,
        "Nombre": dato.Nombre,
        "Direccion": dato.Direccion,
        "HoraEntradas": dato.HoraEntradas, // .toString(),
        "HoraSalidas": dato.HoraSalidas, // .toString(),
        "Telefono": dato.Telefono,
        "Correo": dato.Correo.trim(),
        // "TiempoCobro": dato.TiempoCobro,
        // "ToleranciaRetraso": dato.ToleranciaRetraso,
        // "ToleranciaFactura": dato.ToleranciaFactura,
        "ZonaHoraria": dato.ZonaHoraria,
        "Tamanio": dato.Tamanio,
        "TipoLicencia": dato.TipoLicencia,
        "CodigoPais": dato.CodigoPais
        // "Sabado": dato.Sabado
    }

    return DataDRequest._Request5(url, "POST", params, RequestID);
}

export async function _EliminaEscuela(idEscuela: number): Promise<DataDRequest.IRequestResponseA> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "escuela/EliminarEscuela";
    const params = {
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "IdEscuela": idEscuela
    }
    return DataDRequest._Request5(url, "POST", params, RequestID);
}

// interface IResponseGetDirection {
//     results: Array<any>;
//     status: google.maps.GeocoderStatus
// }
export async function _GetDirectionInformation(latlng: string, language: "en" | "es"): Promise<DataDRequest.IResultadoPeticion<google.maps.GeocoderResponse>> {
    const url = "https://maps.googleapis.com/maps/api/geocode/json";
    const params = {
        latlng: latlng, // latitude + "," + longitude, // "40.714224,-73.961452",
        language: language,
        key: Global._GLOBAL_CONF.GoogleMaps_Key,
        // result_type: "street_number|route|street_address|postal_code|locality|political|country",
        location_type: google.maps.GeocoderLocationType.ROOFTOP // + "|" + google.maps.GeocoderLocationType.APPROXIMATE
    }

    return new Promise(async (resolve, reject) => {
        let result = await DataDRequest._Request2<google.maps.GeocoderResponse>(url, "GET", params);
        console.warn("-d", result, params, "_GetDirection RESULT");
        let resp: any = (result.data) ? result.data : -1;

        // result.data.results[0].address_components[0]
        resolve({
            Data: result.data,
            Resultado: resp,
            TipoRequest: (resp < 0) ? RequestID : undefined
        })
    })
}

interface ITimeZoneResponse {
    dstOffset: number,
    rawOffset: number,
    status: string,
    timeZoneId: string, // "America/Los_Angeles",
    timeZoneName: string // "Pacific Standard Time"
}
export async function _GetTimeZone(latlng: string): Promise<DataDRequest.IResultadoPeticion<ITimeZoneResponse>> {
    const url = "https://maps.googleapis.com/maps/api/timezone/json";
    const parans = {
        location: latlng, // 39.6034810,-119.6822510
        timestamp: "1331161200",
        key: Global._GLOBAL_CONF.GoogleMaps_Key
    }

    return new Promise(async (resolve, reject) => {
        let result = await DataDRequest._Request2<ITimeZoneResponse>(url, "GET", parans);
        console.log(result, parans, "_GetTimeZone");
        resolve({
            Data: (result.error ? null : result?.data),
            Resultado: (result.error ? -1 : 1),
            TipoRequest: RequestID
        })
    })
}

// /** Resultado
//  * * (> 0) - Exitoso (Id de la asignación del calendario a la escuela)
//  * * (-1) - Escuela no encontrada
//  * * (-2) - Calendario no configurado (no vinculado)
//  */
// export async function _AsignarCalendarioGoogle(idEscuela: number): Promise<DataDRequest.IResultadoPeticion<undefined>> {
//     const url = Global._GLOBAL_CONF.URL_RSTV2 + "escuela/AsignarCalendario";
//     const params = {
//         "IdUsuario": DataUtil._Usuario.IdUsuario,
//         "IdEscuela": idEscuela
//     }

//     return new Promise(async (resolve) => {
//         await DataDRequest._Request2<{ Resultado: number }>(url, "POST", params)
//             .then(res => {
//                 resolve({
//                     Resultado: res.data?.Resultado ? res.data.Resultado : -10,
//                     TipoRequest: RequestID
//                 })
//             })
//     })
// }

export function _GetUrlObtenerLogo(escuela: Entidad.IEscuela): string {
    if (typeof escuela == "number") {
        escuela = _DiccEscuela.get(escuela);
    }
    if (escuela) {
        return Global._GLOBAL_CONF.URL_RSTV2 + "escuela/ObtenerLogo?IdEscuela=" + escuela.IdKinder + "&Modificacion=" + escuela.Modificacion;
    }
    return null;
}

// export function _GetUrlCalendarioObtenerConfiguracion(idEscuela: number): string {
//     return Global._GLOBAL_CONF.URL_RSTV2 + `calendario/ObtenerConfiguracion?Id=${idEscuela}K${DataUtil._Usuario.IdUsuario}`;
// }

export function _ConfigurarEntradaSalidaQR(idEscuela: number, habilitar: boolean) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "escuela/ConfigurarEntradaSalidaQR";
    const params = {
        "IdEscuela": idEscuela,
        "Habilitar": habilitar,
    }
    return DataDRequest._Request5(url, "POST", params, RequestID);
}

export function _ObtenerEscuelaConfiguracionFacturacion(idEscuela: number): Promise<DataDRequest.IRequestResponseA<IConfiguracionFacturacion>> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + `escuela/ObtenerConfiguracionFacturacion`
    const params = {
        "IdEscuela": idEscuela,
    }
    return DataDRequest._Request5<IConfiguracionFacturacion>(url, "GET", params, RequestID)
}

export function _EscuelaConfigurarFacturacion(idEscuela: number, fApiKey: string, fSecretKey: string): Promise<DataDRequest.IRequestResponseA> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + `escuela/ConfigurarFacturacion`
    const params = {
        "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
        "IdEscuela": idEscuela,
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "FApiKey": fApiKey,
        "FSecretKey": fSecretKey,
    }
    return DataDRequest._Request5(url, "POST", params, RequestID)
}

export function _SvEscuelaDiasNoLectivos(idEscuela: number) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + `escuela/ObtenerDiasNoLectivos`;
    const params = {
        "IdEscuela": idEscuela
    }
    return DataDRequest._Request5<Entidad.IEscuelaDiaNoLectivo[]>(url, "GET", params, RequestID);
}

export function _SvEscuelaAgregarDiaNoLectivo(idEscuela: number, nombre: string, inicio: string, fin: string, frecuencia: number) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + `escuela/AgregarDiaNoLectivo`;
    const params = {
        "IdEscuela": idEscuela,
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "Frecuencia": frecuencia,
        "Nombre": nombre,
        "FechaInicio": inicio,
        "FechaFin": fin,
    }
    return DataDRequest._Request5(url, "POST", params, RequestID);
}

export function _SvEscuelaEditarDiaNoLectivo(idEscuela: number, nombre: string, inicio: string, fin: string, frecuencia: number, idRegistro: number) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + `escuela/EditarDiaNolectivo`;
    const params = {
        "IdRegistro": idRegistro,
        "IdEscuela": idEscuela,
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "Frecuencia": frecuencia,
        "Nombre": nombre,
        "FechaInicio": inicio,
        "FechaFin": fin,
    }
    return DataDRequest._Request5(url, "POST", params, RequestID);
}

export function _SvEscuelaEliminarDiaNoLectivo(idEscuela: number, idRegistro: number) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + `escuela/EliminarDiaNoLectivo`;
    const params = {
        "IdRegistro": idRegistro,
        "IdUsuario": DataUtil._Usuario.IdUsuario,
    }
    return DataDRequest._Request5(url, "POST", params, RequestID);
}

export function _SvEscuelaObtenerDashboardInfo(idEscuela: number, fecha: string) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + `escuela/ObtenerDashboardInfo`;
    const params = {
        "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
        "IdEscuela": idEscuela,
        "DtFechaActual": fecha,
    }

    return DataDRequest._Request5(url, "GET", params, RequestID);
}

// *************************************************************
// LOCAL DATA
// *************************************************************

export function _LOCALDATA_GetAlumnosEnEscuela(idEscuela: number): Map<number, Entidad.IAlumno> {
    let alumnos: Map<number, Entidad.IAlumno> = new Map();

    DataModuloMain._GetReqDataMapById(Entidad.CTipoRequest.Alumno)
        .forEach(alumno => {
            if (alumno.IdKinder == idEscuela && alumno.EnUso) {
                alumnos.set(alumno.IdChild, alumno);
            }
        })

    return alumnos;
}

export function _LOCALDATA_GetEscolaridadesDeEscuela(idEscuela: number): Map<number, Entidad.IEscolaridad> {
    return (_DiccAsignacionEscuelaEscolaridades.get(idEscuela) || new Map());
}

export function _LOCALDATA_GetGruposEnEscuela(idEscuela: number): Map<number, Entidad.IGrupo> {
    const grupos = new Map<number, Entidad.IGrupo>();

    DataModuloGrupo._DiccGrupo
        .forEach((d, k) => {
            if (d.IdKinder == idEscuela) {
                grupos.set(k, d);
            }
        });

    return grupos;
}

export default {
    _DiccEscuela,
    _DiccFullEscuelas,
    _DiccAsignacionEscuelaEscolaridades,
    _ActualizarLogo,
    _AltaEscuela,
    _ActualizaEscuela,
    _EliminaEscuela,
    _GetDirectionInformation,
    _GetTimeZone,
    _GetUrlObtenerLogo,
    _ConfigurarEntradaSalidaQR,
    _LOCALDATA_GetAlumnosEnEscuela,
    _LOCALDATA_GetEscolaridadesDeEscuela,
    _LOCALDATA_GetGruposEnEscuela
}
