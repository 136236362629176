import { Entidad } from "../../data/Entidad";
import { DataUtilAlertBot } from "../../data/util/AlertBot";
import { DataUtilLocalStorage } from "../../data/util/LocalStorage";
import { DataUtil } from "../../data/util/Util";
import _L from "../../util/Labels";
import { _ShowLoaderMessage } from "../controlD3/FullLoader";

export namespace UIUtilInstancePageEvalControl {
    const DEFAULT_SESION_ID = 0
    const WIN_INSTANCE_GROUP = "winstatusinstance"
    const WIN_KEY_USERID = "session"
    const INSTANCE_EXPIRES_MS = 25000 // 2min 120000
    let monitorTimeoutEval: NodeJS.Timeout
    let sesionID: number = null
    let firstEval: boolean = true
    let waitingReloadPage: boolean = false
    let stopAllEval = false

    export enum CSesionResultStatus {
        VALID = 1,
        CHANGED,
        ANOTHER_START,
        ALREADY_FINISHED,
    }

    // ****************************************************************
    // PUBLIC FUNCTIONS
    // ****************************************************************

    export function _StartPageInstanceEval(): void {
        stopAllEval = false
        InitSesionID().then(() => {
            if (monitorTimeoutEval)
                return
            // console.debug("PageEval start", sesionID)

            const fnEval = (tm = INSTANCE_EXPIRES_MS) => {
                monitorTimeoutEval = setTimeout(() => {
                    // console.debug("PageEval monitor eval auto run")
                    EvalPageInstance(null).then((result) => {
                        if (result != CSesionResultStatus.VALID) {
                            _StopPageInstanceEval()
                            return
                        }
                        fnEval()
                    })
                }, tm)
            }
            fnEval()
        })
    }

    /**
     * @param stopAll Detener todas las evaluaciones: Auto | Manual
     */
    export function _StopPageInstanceEval(stopAll: boolean = false): void {
        stopAllEval = stopAll
        if (!monitorTimeoutEval) return
        clearTimeout(monitorTimeoutEval)
        monitorTimeoutEval = null
    }

    /**
     * @param dbUserData current user data in DB
     */
    export async function _EvalPageInstance(dbUserData?: Entidad.IUsuarioSesion): Promise<CSesionResultStatus | null> {
        // console.warn("PageEval monitor eval manual run")
        return EvalPageInstance(dbUserData)
    }


    export function _SetLocalStorageSesionID(idSesion: number) {
        SetLocalStorageSesionID(idSesion)
    }

    export function _SetLocalStorageDefaultSesionID() {
        SetLocalStorageSesionID(DEFAULT_SESION_ID)
    }

    // ****************************************************************
    // EVAL CONTROL
    // ****************************************************************

    async function InitSesionID() {
        if (sesionID > DEFAULT_SESION_ID) {
            return
        }
        sesionID = GetLocalStorageSesionID()
        if (sesionID >= DEFAULT_SESION_ID) {
            return
        }
        // Inicializar en LocalStorage
        const existSesionID = DataUtil._Usuario?.__Entity?.IdSesion
        if (existSesionID) {
            SetLocalStorageSesionID(existSesionID)
            return
        }
        const [_, dbUser]: [boolean, Entidad.IUsuarioSesion] = await DataUtil._GetInfoUsuario().catch(err => ([null, null]))
        SetLocalStorageSesionID(dbUser?.IdSesion || DEFAULT_SESION_ID)
    }

    async function EvalPageInstance(dbUserData: Entidad.IUsuarioSesion | null): Promise<CSesionResultStatus> {
        const firstEvalAux = firstEval
        if (stopAllEval) return null

        if (firstEval) {
            firstEval = false
        }
        if (sesionID == null) {
            await InitSesionID()
            if (stopAllEval) return null
        }
        if (dbUserData == null) {
            const [_, userDB]: [boolean, Entidad.IUsuarioSesion] = await DataUtil._GetInfoUsuario().catch(() => ([null, null]))
            dbUserData = userDB
        }
        if (waitingReloadPage || stopAllEval) {
            return null
        }
        const dbSesionID = dbUserData?.IdSesion || DEFAULT_SESION_ID
        // console.debug("PageInstance dbSesionID", dbSesionID)
        let result = CSesionResultStatus.VALID

        if (firstEvalAux && sesionID != dbSesionID) {
            // console.debug("PageEval primera carga, ajustar SESION ID localstorage, old ", sesionID, " -> new ", dbSesionID)
            SetLocalStorageSesionID(dbSesionID)
            return result
        }
        if (sesionID != dbSesionID) {
            result = CSesionResultStatus.CHANGED
            if (sesionID > DEFAULT_SESION_ID && dbSesionID == DEFAULT_SESION_ID) {
                result = CSesionResultStatus.ALREADY_FINISHED
            } else if (sesionID == DEFAULT_SESION_ID && dbSesionID > DEFAULT_SESION_ID) {
                result = CSesionResultStatus.ANOTHER_START
            }
            LogW(`SESION_${CSesionResultStatus[result]}: ${sesionID} >> ${dbSesionID}`)
            InvalidControlActions(result, dbSesionID)
        }
        return result
    }

    function InvalidControlActions(result: CSesionResultStatus, dbSesionID: number) {
        if (result == CSesionResultStatus.VALID) {
            return
        }
        let mesage: string = ""
        if (result == CSesionResultStatus.CHANGED)
            mesage = _L("sesion.validation_sesionchanged")
        else if (result == CSesionResultStatus.ANOTHER_START)
            mesage = _L("sesion.validation_sesionnueva")
        else if (result == CSesionResultStatus.ALREADY_FINISHED)
            mesage = _L("sesion.validation_sesionterminada")
        _ShowLoaderMessage(mesage)
        console.warn("PageEval reloading...")
        SetLocalStorageSesionID(dbSesionID)
        waitingReloadPage = true
        setTimeout(() => {
            window.location.reload()
        }, 4000);
    }

    // ****************************************************************
    // FNs
    // ****************************************************************

    function GetLocalStorageSesionID(): number | null {
        const strID = DataUtilLocalStorage._GetItem(WIN_INSTANCE_GROUP, WIN_KEY_USERID)
        if (strID == "" || strID == null) {
            // console.warn("PageEval require initialization", WIN_INSTANCE_GROUP, WIN_KEY_USERID)
            return null
        }
        return +strID
    }

    function SetLocalStorageSesionID(sID: number = DEFAULT_SESION_ID): void {
        // console.warn("PageEval saving localstogare > sessionID", sID)
        sesionID = sID
        DataUtilLocalStorage._SetItem(WIN_INSTANCE_GROUP, WIN_KEY_USERID, sID || 0)
    }

    function LogW(message: string): void {
        console.warn("-d", message)
        // DataUtilAlertBot._SendWarn("PageEvalInstance", message)
    }
}
