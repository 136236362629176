import { Entidad } from "../../data/Entidad";
import { UIUtilLang } from "../util/Language";
import { UIUtilViewData } from "../util/ViewData";
import { CalendarioGridBase } from "./CalendarioGridBase";

export namespace CalendarioGridCargos {

    enum CViewMode {
        Months = 1, Year
    }

    type TCalendarGridNMesesView = 0 | 1 | 2 | 3 | 4 | 6;

    type CPeriodicidadCargos = Entidad.CFinanzaCargoPeriodicidadCargos;
    const CPeriodicidadCargos = {
        ...Entidad.CFinanzaCargoPeriodicidadCargos
    };

    export interface ICalendarioGridCargosConfig {
        Periodicidad: CPeriodicidadCargos;
        /** Cantidad de meses visibles en la primer vista, en caso de ser 0 no muestra la vista */
        NMesesEnVistaInicial: TCalendarGridNMesesView;
        ContainerToRedimention: d3.Selection<HTMLDivElement, any, HTMLElement, any>;
        OnChangeSomeSelector: (resultSelectors: IResultSelectors) => void;
    }

    interface IResultSelectors {
        Aplicacion: IResSel;
        Vencimiento: IResSel;
        Morocidad: IResSel;
    }

    interface IResSel {
        Month?: number;
        Days: number;
    }

    enum CTypeSelector {
        Aplicacion = "Aplicación",
        Vencimiento = "Vencimiento",
        Morosidad = "Morocidad",
    }

    export class CalendarioGridCargos extends CalendarioGridBase.CalendarGridBase<"DataBase", CTypeSelector, IResSel> {
        private config: ICalendarioGridCargosConfig;
        private currentViewMode: CViewMode;
        private btnToggle: d3.Selection<HTMLDivElement, any, HTMLElement, any>;
        private areaTitle: d3.Selection<HTMLDivElement, any, HTMLElement, any>;
        /** selector actual en arrastre */
        private selectorDrag: CalendarioGridBase.ICGB_SelectorMap<CTypeSelector, IResSel>["DataBase"];

        constructor(parent: d3.Selection<HTMLDivElement, any, HTMLElement, any>, config: Omit<ICalendarioGridCargosConfig, keyof Pick<ICalendarioGridCargosConfig, "Periodicidad" | "NMesesEnVistaInicial">>) {
            super({
                Parent: parent,
                TypeSelector: "DataBase",
                Selectors: [
                    {
                        Id: CTypeSelector.Aplicacion,
                        Date: 1,
                        Days: 1,
                        Month: 1,
                        PriorityWhenMerging: 1,
                        IdMaxSelector: CTypeSelector.Vencimiento,
                        Class: "sel_1"
                    },
                    {
                        Id: CTypeSelector.Vencimiento,
                        Date: 1,
                        Days: 0,
                        Month: 1,
                        IdMinSelector: CTypeSelector.Aplicacion,
                        IdMaxSelector: CTypeSelector.Morosidad,
                        PriorityWhenMerging: 2,
                        Class: "sel_2"

                    },
                    {
                        Id: CTypeSelector.Morosidad,
                        Date: 1,
                        Days: 0,
                        Month: 1,
                        IdMinSelector: CTypeSelector.Vencimiento,
                        PriorityWhenMerging: 3,
                        Class: "sel_3"

                    }
                ]
            });

            this.InitDraw();
            this.UpdateConfigAndView(config as ICalendarioGridCargosConfig);
        }

        set _Periodicidad(periodicidad: CPeriodicidadCargos) {
            this.config.Periodicidad = periodicidad;
            this.config.NMesesEnVistaInicial = this.GetNMonthsToView(periodicidad);
            this.UpdateConfigAndView(this.config);
            this.UpdateTitle();
        }

        get _Periodicidad(): CPeriodicidadCargos {
            return this.config.Periodicidad;
        }

        private InitDraw(): void {
            let subHeader = this.CGBcontrolContainer.select<HTMLDivElement>(".sub_header")
                .style("justify-content", "space-between");

            this.areaTitle = subHeader.append("div")
                .classed("periodicidad", true);

            this.btnToggle = subHeader.append("div")
                .classed("accion_item", true);
        }

        private UpdateConfigAndView(config: ICalendarioGridCargosConfig) {
            const defaultConfig = <ICalendarioGridCargosConfig>{
                NMesesEnVistaInicial: 1
            }
            this.config = { ...defaultConfig, ...config };
            this.currentViewMode = this.config.NMesesEnVistaInicial > 0 ? CViewMode.Months : CViewMode.Year;

            this.btnToggle.node().onclick = null;
            this.btnToggle.classed("hide", true);
            if (this.currentViewMode == CViewMode.Months) {
                this.btnToggle.classed("hide", false);
                this.btnToggle.text(UIUtilLang._GetUIString("finanzascargo", "tag_veranio"));

                this.btnToggle.node().onclick = (e) => {
                    this.OnToggleCalendarMode();
                    this.btnToggle.text(this.currentViewMode == CViewMode.Year ? UIUtilLang._GetUIString("finanzascargo", "tag_vermesperiod") : UIUtilLang._GetUIString("finanzascargo", "tag_veranio"));
                }
            }
            this.UpdateAndDrawMode();
        }

        private UpdateTitle() {
            this.areaTitle.text(UIUtilLang._GetUIString("finanzascargo", "tag_periodic") + ": " + UIUtilViewData._GetStr_Periodicidad(this.config.Periodicidad));
        }

        private OnToggleCalendarMode() {
            this.currentViewMode = this.currentViewMode == CViewMode.Months ? CViewMode.Year : CViewMode.Months;
            this.UpdateAndDrawMode();
        }

        private UpdateAndDrawMode() {
            if (this.currentViewMode == CViewMode.Months) {
                const nMonths = this.config.NMesesEnVistaInicial;
                switch (nMonths) {
                    case 1:
                        this.CGBUpdateAreaCalendarGrid()
                            .RedimContainer(500);
                        break;
                    case 2:
                        this.CGBUpdateAreaCalendarGrid(2)
                            .RedimContainer(700);
                        break;
                    case 3:
                        this.CGBUpdateAreaCalendarGrid(3)
                            .RedimContainer(860);
                        break;
                    case 4:
                        this.CGBUpdateAreaCalendarGrid(2, 2)
                            .RedimContainer(700);
                        break;
                    case 6:
                        this.CGBUpdateAreaCalendarGrid(3, 2)
                            .RedimContainer(860);
                        break;
                }
                this.BuildMonthView(nMonths);
            } else {
                this.CGBUpdateAreaCalendarGrid(4, 3)
                    .BuildMonthView(12)
                    .RedimContainer(1100);
            }
        }

        private RedimContainer(width: number) {
            if (this.config.ContainerToRedimention) {
                this.config.ContainerToRedimention.style("width", width + "px");
            }
        }

        private BuildMonthView(nMonths: number) {
            this.CGBcontrolContainer.select(".body").select(".area_calendar_grid").selectAll(".month").remove();
            this.CGBcreator.BuildManyBaseMonths(nMonths).forEach(month => {
                this.CGBDrawOrUpdateGridMonth(month);
            })
            this.CGBUpdateAllSelectorsPosition();
            this.CGBcontrolContainer.selectAll(".name_days").classed("hide", true);
            return this;
        }

        protected CGBOnStepMonthCell(datum: CalendarioGridBase.ICGB_DateCell, month: CalendarioGridBase.ICGB_Month, divCell: d3.Selection<HTMLDivElement, CalendarioGridBase.ICGB_DateCell, HTMLElement, any>, divSelectors: d3.Selection<HTMLDivElement, any, HTMLElement, any>, dateCell: Date, typeUpdate: "enter" | "update" | "exit") {
            divCell.node().ondragenter = null;
            divCell.node().ondragover = null;

            if (typeUpdate != "exit") {

                divCell.node().ondragenter = e => {
                    this.CGBUpdateSelectorPosition(CTypeSelector.Aplicacion);

                    // LA MOROSIDAD NO PUEDE INICIAR EL MISMO DÍA DE APLICACIÓN
                    if (this.selectorDrag.Id == CTypeSelector.Aplicacion) {
                        const selectorMorosidad = this.selectorDrag.MaxSelectorData.MaxSelectorData;
                        if (datum.YearDay >= selectorMorosidad.Date) {
                            return
                        }
                    }
                    else if (this.selectorDrag.Id == CTypeSelector.Morosidad) {
                        const selectorAplicacion = this.selectorDrag.MinSelectorData.MinSelectorData;
                        if (datum.YearDay <= selectorAplicacion.Date) {
                            return
                        }
                    }

                    // LIMITE (LÓGICO) DE MOVILIDAD DEL SELECTOR DE APLICACIÓN
                    if (this.selectorDrag.MinSelectorData) {
                        if (datum.YearDay < this.selectorDrag.MinSelectorData.Date) {
                            return
                        }
                    }
                    if (this.selectorDrag.MaxSelectorData) {
                        if (datum.YearDay > this.selectorDrag.MaxSelectorData.Date) {
                            return
                        }
                    }

                    if (this.selectorDrag.Id == CTypeSelector.Aplicacion) {
                        if ((datum.YearDay > 1 && this.config.Periodicidad == CPeriodicidadCargos.Unico) || (datum.YearDay > (Entidad.PeriodicidadNMeses[this.config.Periodicidad] * 30))) {
                            return;
                        }
                    }
                    // **********************************************

                    if (this.selectorDrag.Container) {
                        this.selectorDrag.Date = datum.YearDay;
                        this.selectorDrag.Days = datum.MonthDay;
                        this.selectorDrag.Month = month.NMonth;

                        divSelectors.append(() => this.selectorDrag.Container.node());
                        this.CGBUpdateSelectoresPriority();
                        if (this.config.OnChangeSomeSelector) {
                            this.config.OnChangeSomeSelector(this.GetSelectedDates());
                        }
                    }
                };

                // CUANDO EL ELEMENTO ARRASTRADO ESTÁ SOBRE EL ELEMENTO
                // divCell.node().ondragover = e => {};

                // CUANDO EL ELEMENTO ARRASTRADO ABANDONA EL DIV
                // divCell.node().ondragover = e => {};

                // CUANDO SE SUELTA
                // divCell.node().ondrop = e => {}
            }
        }

        protected CGBOnCreateSelectorContainer(selector: CalendarioGridBase.ICGB_SelectorMap<CTypeSelector, IResSel>["DataBase"]) {
            let areaCalendar = this.CGBcontrolContainer.select(".area_calendar_grid")
            let contSelector = selector.Container
                .style("cursor", "move");

            // contSelector.style("position", "absolute");

            contSelector.node().draggable = true;

            // contSelector.node().ondrag = e => {}

            contSelector.node().ondragstart = e => {
                contSelector.classed("ondrag", true);
                this.selectorDrag = selector;

                // LIMITES (VIEW) DE MOVILIDAD DEL SELECTOR DE APLICACIÓN
                if (selector.MinSelectorData) {
                    const minDif = (selector.Id == CTypeSelector.Morosidad && selector.MinSelectorData.Date == selector.MinSelectorData.MinSelectorData.Date)
                        ? 0
                        : 1
                    for (let i = selector.MinSelectorData.Date - minDif; i > 0; i--) {
                        areaCalendar.select("#cell_" + i)
                            .classed("cell_disabled", true);
                    }
                }

                const maxDif = (selector.Id == CTypeSelector.Aplicacion && selector.MaxSelectorData.Date == selector.MaxSelectorData.MaxSelectorData.Date)
                    ? 0
                    : 1
                let startMaxCellToDisable = selector.MaxSelectorData ? selector.MaxSelectorData.Date + maxDif : 360;
                if (this.selectorDrag.Id == CTypeSelector.Aplicacion) {
                    if (this.config.Periodicidad == CPeriodicidadCargos.Unico) {
                        startMaxCellToDisable = 2;
                    } else {
                        let maxPeriodoDate = 30 * Entidad.PeriodicidadNMeses[this.config.Periodicidad];
                        startMaxCellToDisable = startMaxCellToDisable < maxPeriodoDate ? startMaxCellToDisable : maxPeriodoDate;
                    }
                }
                for (let i = startMaxCellToDisable; i < 361; i++) {
                    areaCalendar.select("#cell_" + i)
                        .classed("cell_disabled", true);
                }
                // *****************************************************
            }

            contSelector.node().ondragend = e => {
                contSelector.classed("ondrag", false);
                areaCalendar.selectAll(".cell")
                    .classed("cell_disabled", false);
                this.selectorDrag = null;
            }
        }

        private GetSelectedDates(): IResultSelectors {
            return {
                Aplicacion: {
                    Days: this.CGBdateSelectors.get(CTypeSelector.Aplicacion).Days,
                    Month: this.CGBdateSelectors.get(CTypeSelector.Aplicacion).Month
                },
                Vencimiento: {
                    Days: this.CGBdateSelectors.get(CTypeSelector.Vencimiento).Date - this.CGBdateSelectors.get(CTypeSelector.Aplicacion).Date,
                    // Month: this.CGBdateSelectors.get(CTypeSelector.Vencimiento).Month
                },
                Morocidad: {
                    Days: this.CGBdateSelectors.get(CTypeSelector.Morosidad).Date - this.CGBdateSelectors.get(CTypeSelector.Vencimiento).Date,
                    // Month: this.CGBdateSelectors.get(CTypeSelector.Morosidad).Month
                }
            }
        }

        private GetNMonthsToView = (periodicidad: CPeriodicidadCargos): TCalendarGridNMesesView => {
            switch (periodicidad) {
                case CPeriodicidadCargos.Mensual:
                    return 1;
                case CPeriodicidadCargos.Unico:
                    return 1;
                case CPeriodicidadCargos.Bimestral:
                    return 2;
                case CPeriodicidadCargos.Trimestral:
                    return 3;
                case CPeriodicidadCargos.Cuatrimestral:
                    return 4;
                case CPeriodicidadCargos.Semestral:
                    return 6;
                default:
                    return 0;
            }
        }

        //*************************************************************************
        // Public Methods
        //*************************************************************************

        public _SetPosicionSelectores(mes = 1, aplicacion = 1, vencimiento = 0, morosidad = 0): void {
            this.CGBdateSelectors.get(CTypeSelector.Aplicacion).Days = aplicacion;
            this.CGBdateSelectors.get(CTypeSelector.Aplicacion).Month = mes;
            this.CGBdateSelectors.get(CTypeSelector.Aplicacion).Date = aplicacion + (30 * (mes - 1));
            this.CGBdateSelectors.get(CTypeSelector.Vencimiento).Date = this.CGBdateSelectors.get(CTypeSelector.Aplicacion).Date + vencimiento;
            this.CGBdateSelectors.get(CTypeSelector.Morosidad).Date = this.CGBdateSelectors.get(CTypeSelector.Vencimiento).Date + morosidad;
            this.CGBUpdateAllSelectorsPosition();
        }

        public _GetResultSelected(): IResultSelectors {
            return this.GetSelectedDates();
        }
    }
}
