import * as d3 from "d3";
import { UIUtilGeneral } from "../util/Util";

export namespace TutorialBuilder {
    const startendAnimationDuration = 300;
    let lastZIndex = 0;

    export interface IControlConfig {
        BackgroundBlur?: boolean;
        ItemsFocus?: IItemFocusConfig | IItemFocusConfig[];
        /** @default false */

        // ShowExit?: boolean;
        // FIXME IMPLEMENTAR
        /** Se invoca si el control es removido mientras hay elementos enfocados */
        OnRemove_Interrupting?: () => void;
        /** Se invoca si el control es removido mientras no hay elementos enfocados */
        OnRemove_Correctly?: () => void;
    }

    export interface IItemFocusConfig {
        Selector: string | HTMLElement;
        Text: string;
        ID?: string | number;

        SelectorsToFixFocus?: string[] | IItemToFixFocus[];
        OnClickSelection?: (e: MouseEvent/* , selection: d3.Selection<HTMLElement, any, any, any>*/) => void;
        OnInitFocus?: (elementFocus: HTMLElement) => void;
        OnRemoveFocus?: (elementFocus: HTMLElement) => void;
        /** Ej: "10px" | "10px 10px" | "10px 10px 10px 10px"
         * @default "2px"
         */
        PaddingFocus?: string;

        OnNextFocus?: () => void;
        OnPreviusFocus?: () => void;

        /** @default "bottom" */
        TextPosition?: "bottom" | "top" // | "left" | "right";
        /** @default 5 px */
        TextFixLeftPosition?: number;
        /** Retraso de aparición en milisegundos */
        Delay?: number;

        // FIXME IMPLEMENTAR
        ShowRemover?: boolean;
        ShowSkip?: boolean;
        ZIndexAdd?: number;
    }

    interface IItemToFixFocus {
        Selector: string;
        OnInitFocus?: (element: HTMLElement) => void;
        OnRemoveFocus?: (element: HTMLElement) => void;
    }

    interface IItemToFixFocusAdvanced extends IItemToFixFocus {
        Element: HTMLElement;
    }

    interface IItemFocused {
        ElementFocused: HTMLElement;
        Remove(): void;
        SetText(text: string): IItemFocused;
        ChangeFocusElement(selector: string): IItemFocused;
        Delay(time: number): IItemFocusedDelayed;
    }

    export interface IItemFocusedAdvance extends IItemFocused {
        // readonly LblTextSelection: TSelectionHTML<"pre">;
        // readonly ParentBase: HTMLElement;
        AdjustPositions(): void;
        Focused: boolean;
    }

    interface IItemFocusedDelayed {
        Remove(): void;
        SetText(text: string): IItemFocusedDelayed;
        ChangeFocusElement(selector: string): IItemFocusedDelayed;
        Delay(time: number): IItemFocusedDelayed;
    }

    export interface IControl {
        AddItem(config: IItemFocusConfig): string | number;
        AddItems(...configs: IItemFocusConfig[]): IControl;
        Delay(time: number): IControlDelayed;
        TextMaster(text: string): IControl;
        SelectItem(id: string | number): IItemFocused;
        RemoveTextMaster(): IControl;
        BackgroundBlur(blur: boolean): IControl;
        RemoveAllItemsFocused(): IControl;
        Remove(): void;
    }

    interface IControlDelayed {
        // AddItem: (config: IOptionsItemFocus) => string | number;
        AddItems(...configs: IItemFocusConfig[]): IControlDelayed;
        Delay(time: number): IControlDelayed;
        TextMaster(text: string): IControlDelayed;
        SelectItem(id: string | number): IItemFocusedDelayed;
        RemoveTextMaster(): IControlDelayed;
        BackgroundBlur(blur: boolean): IControlDelayed;
        RemoveAllItemsFocused(): IControlDelayed;
        Remove(): Pick<IControlDelayed, "Call">;
        Call(fun: () => void): IControlDelayed;
    }

    export function _ItemsFocus(config: IControlConfig = {}) {
        const zIndex = 500 + lastZIndex;
        const itemsFocus = new Map<number | string, IItemFocusedAdvance>();
        const blurOpacity = .65;
        // const timeouts: NodeJS.Timeout[] = []; // FIEMX IMPLEMENTAR

        config.ItemsFocus = config.ItemsFocus || [];
        config.ItemsFocus = (Array.isArray(config.ItemsFocus) ? config.ItemsFocus : [config.ItemsFocus]);
        config.BackgroundBlur = (config.BackgroundBlur || false);

        const body = d3.select(document.body); //d3.select(focusElement.parentElement);
        const container = body
            .append("div")
            .attr("class", "itemfocus_blur")
            .style("position", "fixed")
            // .style("background-color", "black")
            .style("top", 0)
            .style("left", 0)
            .style("width", "100%")
            .style("height", "100%")
            .style("z-index", zIndex)
            .style("backdrop-filter", "blur(0px)");
        container.node().onclick = (e) => {
            e.stopPropagation();
        }

        const lblExit = container.append("label")
            .attr("class", "tutorial_exit")
            .style("position", "absolute")
            .style("top", "20px")
            .style("left", "65px")
            .style("color", "var(--color_text4)")
            .style("cursor", "pointer")
            .style("font-size", "2.3vh")
            .style("background-color", "#000")
            .style("padding", "6px")
            .style("border-radius", "5px")
            .style("z-index", zIndex + 2)
            .text("Salir del tutorial")
            .on("click", () => {
                control.Remove();
            });

        const fnRemoveContainer = () => {
            const salidaLimpia = (itemsFocus.size == 0);

            container
                .style("opacity", 1)
                .transition()
                .duration(startendAnimationDuration)
                .style("opacity", 0)
                .remove();

            fnRemoveAllItems();

            if (salidaLimpia && config.OnRemove_Correctly) {
                config.OnRemove_Correctly();
            }
            else if (!salidaLimpia && config.OnRemove_Interrupting) {
                config.OnRemove_Interrupting();
            }

            positionsAdjusterObserver?.disconnect();
        }

        const fnRemoveAllItems = () => {
            itemsFocus
                .forEach((item, id) => {
                    item.Remove();
                });
            itemsFocus.clear();
        }

        const fnAddItemFocus = (cfg: IItemFocusConfig) => {
            // >> Revisión de ID
            if (cfg.ID == null) {
                cfg.ID = Number(new Date());
                while (itemsFocus.has(cfg.ID)) {
                    cfg.ID++;
                }
            }

            // >> Crea Item
            const fnACrear = () => {
                let newItem = CreateItemFocus(cfg, zIndex, container, itemsFocus);
                if (newItem) {
                    if (!itemsFocus.has(cfg.ID)) {
                        itemsFocus.set(cfg.ID, newItem);
                    } else {
                        newItem.Remove();
                        console.warn(cfg.ID, cfg.Selector, " already exist!!");
                    }
                }
            }

            if (cfg.Delay) {
                setTimeout(() => {
                    fnACrear();
                }, cfg.Delay);
            } else {
                fnACrear();
            }

            return cfg.ID;
        }

        const fnApplyBackgroundBlur = (blur: boolean) => {
            container
                .transition()
                .duration(startendAnimationDuration)
                .style("backdrop-filter", (blur ? "blur(1px)" : "blur(0px)"));
        }

        // >> Apply Configuration

        for (let cfg of config.ItemsFocus) {
            fnAddItemFocus(cfg);
        }

        fnApplyBackgroundBlur(config.BackgroundBlur);

        container
            .style("background-color", `rgba(0, 0, 0, 0)`)
            .transition()
            .duration(startendAnimationDuration)
            .style("background-color", `rgba(0, 0, 0, ${blurOpacity})`);

        // >> Adjust items focused positions
        const positionsAdjusterObserver = UIUtilGeneral._GetResizeObserver((entries, observer) => {
            itemsFocus.forEach(item => {
                if (!item.Focused) return;
                item.AdjustPositions();
            });
        });
        positionsAdjusterObserver?.observe(document.body);

        let lblTextMaster: TSelectionHTML<"label">;

        const control: IControl = {
            AddItem: (config: IItemFocusConfig) => {
                return fnAddItemFocus(config);
            },
            AddItems: (...configs: IItemFocusConfig[]) => {
                for (let config of configs) {
                    fnAddItemFocus(config);
                }
                return control;
            },
            TextMaster: (text: string) => {
                if (!lblTextMaster) {
                    lblTextMaster = container
                        .classed(UIUtilGeneral.FBoxOrientation.Horizontal, true)
                        .classed(UIUtilGeneral.FBoxAlign.CenterCenter, true)
                        .append("label")
                        .style("backdrop-filter", "blur(1px)")
                        .style("color", "var(--color_text4)")
                        .style("font-size", "4vh")
                        .style("font-weight", "bold")
                        .style("z-index", "1000")
                        .style("white-space", "pre-line")
                        .style("text-align", "center")
                        .style("border-radius", "5px")
                        .style("padding", "5px")
                        .style("margin", "20px");

                    lblTextMaster.style("opacity", 0)
                        .transition()
                        .duration(startendAnimationDuration)
                        .style("opacity", 1)
                }
                lblTextMaster
                    .text(text);

                lblExit.classed("hide", true);

                return control;
            },
            RemoveTextMaster: () => {
                if (lblTextMaster) {
                    lblTextMaster
                        .style("opacity", 1)
                        .transition()
                        .duration(startendAnimationDuration)
                        .style("opacity", 0)
                        .remove();

                    setTimeout(() => {
                        container
                            .classed(UIUtilGeneral.FBoxOrientation.Horizontal, false)
                            .classed(UIUtilGeneral.FBoxAlign.CenterCenter, false);
                    }, startendAnimationDuration);

                    lblTextMaster = null;
                }
                lblExit.classed("hide", false);
                return control;
            },
            SelectItem: (id: string | number) => {
                return itemsFocus.get(id);
            },
            Delay: (time: number) => {
                const awaiters: Function[] = [];
                const controlDelayed: IControlDelayed = {
                    AddItems: (...configs: IItemFocusConfig[]) => {
                        awaiters.push(() => control.AddItems(...configs));
                        return controlDelayed;
                    },
                    SelectItem: (id) => {
                        return control.SelectItem(id)
                            .Delay(time);
                    },
                    Delay: (timeB) => {
                        return control.Delay(time + timeB);
                    },
                    Remove: () => {
                        awaiters.push(control.Remove);
                        return controlDelayed;
                    },
                    RemoveAllItemsFocused: () => {
                        awaiters.push(control.RemoveAllItemsFocused);
                        return controlDelayed;
                    },
                    RemoveTextMaster: () => {
                        awaiters.push(control.RemoveTextMaster);
                        return controlDelayed;
                    },
                    TextMaster: (text) => {
                        awaiters.push(() => control.TextMaster(text));
                        return controlDelayed;
                    },
                    BackgroundBlur: (blur) => {
                        awaiters.push(() => control.BackgroundBlur(blur));
                        return controlDelayed;
                    },
                    Call: (fn: () => void) => {
                        awaiters.push(fn);
                        return controlDelayed;
                    },
                }

                setTimeout(() => {
                    awaiters
                        .forEach(fn => fn());
                }, time);

                return controlDelayed;
            },
            BackgroundBlur: (blur) => {
                fnApplyBackgroundBlur(blur);
                return control;
            },
            RemoveAllItemsFocused: () => {
                fnRemoveAllItems();
                return control;
            },
            Remove: fnRemoveContainer
        }
        return control;
    }

    function CreateItemFocus(optionsB: IItemFocusConfig, zIndex: number, container: TSelectionHTML<"div">, itemsFocus: Map<string | number, IItemFocused>) {
        const focusElement = typeof optionsB.Selector == "string" ? document.querySelector<HTMLElement>(optionsB.Selector) : optionsB.Selector;
        if (!focusElement) {
            console.warn("-d", optionsB.Selector, "no found");
            return null;
        }

        const elementsToFixFocus: IItemToFixFocusAdvanced[] = optionsB.SelectorsToFixFocus
            ?.map<IItemToFixFocusAdvanced>((d: string | IItemToFixFocus) => {
                const isString = typeof d == "string";
                const item = <IItemToFixFocusAdvanced>{
                    Selector: (isString) ? d : d.Selector,
                    OnInitFocus: (isString) ? null : d.OnInitFocus,
                    OnRemoveFocus: (isString) ? null : d.OnRemoveFocus,
                    Element: null,
                }

                item.Element = document.querySelector<HTMLElement>(item.Selector);
                return item;
            })
            ?.filter(item => Boolean(item.Element));

        // >> Default options
        optionsB = {
            ...{
                PaddingFocus: "2px",
                TextPosition: "bottom",
                TextFixLeftPosition: 5,
                Positions: "relative-absolute"
            },
            ...(optionsB)
        }

        const focusElementStyles = window.getComputedStyle(focusElement);
        // const baseBox = document.body.getBoundingClientRect();
        const auxElementBorderWidth = "2px";
        let restaureBackground = false;

        const fnElementApplyFocus = () => {
            let backcolor = focusElementStyles.backgroundColor;
            if (!backcolor || backcolor == "rgba(0, 0, 0, 0)") {
                backcolor = "var(--color_primary1)";
                restaureBackground = true;
                focusElement.style.backgroundColor = backcolor;
            }
            const zIndexAdded = optionsB.ZIndexAdd || 1;
            focusElement.style.zIndex = (zIndex + zIndexAdded).toString();

            elementsToFixFocus
                ?.forEach(item => {
                    item.Element.style.position = "relative"; // DOTEST Solo funciona con algunos elementos
                    item.Element.style.zIndex = "auto"; // zIndex.toString();

                    if (item.OnInitFocus) {
                        item.OnInitFocus(item.Element);
                    }
                    // zIndex++
                });

            if (optionsB.OnInitFocus) {
                optionsB.OnInitFocus(focusElement);
            }
        }

        const fnElementRemoveFocus = () => {
            focusElement.style.zIndex = null;
            if (restaureBackground) {
                focusElement.style.backgroundColor = null;
            }

            elementsToFixFocus
                ?.forEach(item => {
                    item.Element.style.position = null;
                    item.Element.style.zIndex = null

                    if (item.OnRemoveFocus) {
                        item.OnRemoveFocus(item.Element);
                    }
                });

            auxFocusElement
                .style("opacity", 1)
                .transition()
                .duration(startendAnimationDuration)
                .style("opacity", 0)
                .remove();

            lblText
                .style("opacity", 1)
                .transition()
                .duration(startendAnimationDuration)
                .style("opacity", 0)
                .remove();

            btnNextFocus?.remove();
            btnPreviusFocus?.remove();

            if (optionsB?.OnRemoveFocus) {
                optionsB.OnRemoveFocus(focusElement);
            }

            if (optionsB?.OnClickSelection) {
                focusElement.removeEventListener("click", optionsB.OnClickSelection);
            }

            itemFocus.Focused = false;
            itemsFocus.delete(optionsB.ID);

            positionsAdjusterObserver?.disconnect();
        }

        const auxFocusElement = container
            .append("div")
            .attr("class", "focuselement")
            .style("position", "absolute")
            .style("border", "solid white " + auxElementBorderWidth)
            .style("padding", optionsB.PaddingFocus)
            .style("border-radius", focusElementStyles.getPropertyValue("border-radius"));

        auxFocusElement.style("opacity", 0)
            .transition()
            .duration(startendAnimationDuration)
            .style("opacity", 1)
        // .style("backdrop-filter", "brightness(2)")

        const lblText = container.append("pre")
            .style("position", "absolute")
            .style("z-index", zIndex)
            .style("color", "var(--color_text4)")
            .style("font-weight", "bold")
            // .style("max-width", `calc(100% - ${focusElementBox.left + 30}px)`)
            .style("text-shadow", "0px 0px 15px black")
            .style("backdrop-filter", "blur(1px)")
            .style("padding", "4px")
            .text(optionsB.Text);

        lblText.style("opacity", 0)
            .transition()
            .duration(startendAnimationDuration)
            .style("opacity", 1)

        let btnNextFocus: HTMLLabelElement;
        if (optionsB.OnNextFocus) {
            btnNextFocus = container.append("label")
                .style("position", "fixed")
                .style("z-index", zIndex)
                .style("top", "10px")
                .style("right", "10px")
                .style("color", "var(--color_text4)")
                .style("font-weight", "bold")
                // .style("max-width", `calc(100% - ${focusElementBox.left + 30}px)`)
                .style("text-shadow", "0px 0px 15px black")
                .text("Siguiente >>")
                .node();

            btnNextFocus.onclick = () => optionsB.OnNextFocus();
        }

        let btnPreviusFocus: HTMLLabelElement;
        if (optionsB.OnPreviusFocus) {
            btnPreviusFocus = container.append("label")
                .style("position", "fixed")
                .style("z-index", zIndex)
                .style("top", "10px")
                .style("left", "10px")
                .style("color", "var(--color_text4)")
                .style("font-weight", "bold")
                // .style("max-width", `calc(100% - ${focusElementBox.left + 30}px)`)
                .style("text-shadow", "0px 0px 15px black")
                .text("<< Anterior")
                .node();

            btnPreviusFocus.onclick = () => optionsB.OnPreviusFocus();
        }

        // >> Positions adjust
        const auxElementStyles = auxFocusElement.node().style;

        const positionsAdjusterObserver = UIUtilGeneral._GetResizeObserver((entries, observer) => {
            fnAdjustPositionOfFocusingElements();
        });

        positionsAdjusterObserver?.observe(focusElement);

        const fnAdjustPositionOfFocusingElements = () => {
            const focusElementBox = focusElement.getBoundingClientRect();

            // >> Apply Aux focusElement positions
            auxFocusElement
                .style("top", `calc(${focusElementBox.top}px - ${auxElementStyles.paddingTop || "0px"} - ${auxElementBorderWidth})`)
                .style("left", `calc(${focusElementBox.left}px - ${auxElementStyles.paddingLeft || "0px"} - ${auxElementBorderWidth})`)
                .style("width", (focusElementBox.width) + "px")
                .style("height", (focusElementBox.height) + "px")

            // >> Apply text positions
            switch (optionsB.TextPosition) {
                case "top":
                    lblText
                        .style("top", (focusElementBox.top - lblText.node().getBoundingClientRect().height - 10) + "px")
                        .style("left", (focusElementBox.left + optionsB.TextFixLeftPosition) + "px");
                    break;
                case "bottom":
                    lblText
                        .style("top", (focusElementBox.top + focusElementBox.height + 8) + "px")
                        .style("left", (focusElementBox.left + optionsB.TextFixLeftPosition) + "px");
                    break;
            }
        }

        fnAdjustPositionOfFocusingElements();

        // >>
        if (optionsB?.OnClickSelection) {
            focusElement.addEventListener("click", optionsB.OnClickSelection);
        }

        // >> Last actions
        fnElementApplyFocus();
        lastZIndex++;

        const itemFocus: IItemFocusedAdvance = {
            Delay: (time: number) => {
                const awaiters: Function[] = [];
                const delayItem: IItemFocusedDelayed = {
                    ChangeFocusElement: (selector) => {
                        awaiters.push(() => itemFocus.ChangeFocusElement(selector));
                        return delayItem;
                    },
                    Delay: (timeB) => {
                        return itemFocus.Delay(time + timeB);
                    },
                    Remove: () => {
                        awaiters.push(itemFocus.Remove);
                    },
                    SetText: (text: string) => {
                        awaiters.push(() => itemFocus.SetText(text));
                        return delayItem;
                    }
                }

                setTimeout(() => {
                    awaiters
                        .forEach(fn => fn());
                }, time);

                return delayItem;
            },
            Remove: () => {
                fnElementRemoveFocus();
            },
            SetText: (text: string) => {
                lblText.text(text);
                return itemFocus;
            },
            ChangeFocusElement: (selector: string) => {
                fnElementRemoveFocus();
                optionsB.Selector = selector;
                let item = CreateItemFocus(optionsB, zIndex, container, itemsFocus);
                if (item) {
                    itemsFocus.set(optionsB.ID, item);
                } else {
                    itemsFocus.delete(optionsB.ID);
                }
                return itemFocus;
            },
            ElementFocused: focusElement,
            // LblTextSelection: lblText,
            // ParentBase: focusElementParent,
            AdjustPositions: fnAdjustPositionOfFocusingElements,
            Focused: true
        }

        return itemFocus;
    }
}
