import * as d3 from "d3";

export interface IObjectLocation {
    Label: string;
    BaseHash: string;
    OnCall?: () => void;
    // /** Identificador */
    // GetHash?: () => string;
    // OnClick?: (hash: string) => void;
    // OnRemove?: Function;
    // IsEnable: boolean;
}

export class LocationNav {
    private locations: Map<string, IObjectLocation>;
    private divControl: d3.Selection<HTMLDivElement, {}, HTMLElement, null>
    // private onClick: (hash: string) => void

    constructor(parentSelection: d3.Selection<HTMLDivElement, any, any, any>) {
        this.locations = new Map();
        this.divControl = parentSelection;
    }

    // private RemoveItemByHash(hash: string) {
    //     // let item = this.locations.get(hash)
    //     // if (item.OnRemove) item.OnRemove()
    //     this.locations.delete(hash);
    // }

    private DrawLocations() {
        let locationsAux = Array.from(this.locations.values())
        //console.log(locationsAux)
        this.divControl
            .selectAll("div")
            .data(locationsAux, (d: any) => d)
            .join(
                enter => {
                    return enter.append<HTMLDivElement>((d, i) => {
                        let div = d3.create<HTMLDivElement>("div");
                        let labelLocation = div.append<HTMLLabelElement>("label").text(() => d.Label)
                        let isLastD = (d === locationsAux[locationsAux.length - 1]);

                        if (!isLastD && d.OnCall) { // || d.OnClick)) {
                            labelLocation.classed("lbl_location_enable", true);
                            labelLocation.on("click", () => {
                                // if (d.OnClick) {
                                //     d.OnClick(d.GetHash)
                                // }
                                // else if (d.GetHash[0] === "#") {
                                //     if (this.onClick) this.onClick(d.GetHash)
                                // }
                                d.OnCall();
                                // this.ReplaceLocation(d.GetHash());
                                // this.LocateInHash(d)
                            })
                        } else {
                            labelLocation.classed("lbl_location_enable", false);
                        }
                        if (i < this.locations.size - 1) div.append<HTMLSpanElement>("span").text("/")
                        return div.node()
                    })
                },
                update => {
                    if (update.select("span").node() === null) {
                        return update.append<HTMLSpanElement>((d, i) => {
                            let span = d3.create<HTMLSpanElement>("span")
                            if (i < this.locations.size - 1) span.text("/")
                            return span.node()
                        })
                    } else {
                        return update.select("span").text((d, i) => {
                            return i < this.locations.size - 1 ? "/" : ""
                        })
                    }
                },
                exit => exit.remove()
            )
    }

    // private ReplaceLocation(hash: string) {
    //     ventanas.WindowManager.fn_DoHash(hash);
    //     // if (hash[0] == "#") {
    //     //     window.location.assign(hash);
    //     // }
    // }

    // ****************************************************************
    // PUBLIC PROPERTIES
    // ****************************************************************

    // /**  */
    // private LocateInHash(itemLocation: IObjectLocation): boolean {
    //     // let flag = this.locations.has(itemLocation.BaseHash);
    //     // flag -> Exite?
    //     if (this.locations.has(itemLocation.BaseHash)) {
    //         let locationsAux = Array.from(this.locations.values())
    //         let itemsFilter: Map<string, IObjectLocation> = new Map()
    //         let itemsRemove: IObjectLocation[] = new Array();
    //         // flag = false
    //         for (let i = 0; i < this.locations.size; i++) {
    //             let d = locationsAux[i]

    //             // if (!flag) 
    //             itemsFilter.set(d.GetHash(), d)
    //             else itemsRemove.push(d)
    //             // flag -> Encontrado?
    //             if (d.BaseHash === itemLocation.BaseHash) flag = true
    //         }

    //         // flag -> No. items a eliminar?
    //         if (itemsRemove.length > 0) {
    //             itemsRemove.reverse()
    //             itemsRemove.forEach(d => this.RemoveItemByHash(d.BaseHash));
    //             this.locations = itemsFilter
    //             this.DrawLocations()
    //         } else flag = false
    //     }
    //     return flag
    // }

    // /** Limpia locations */
    // public met_Clear() {
    //     this.locations.clear();
    //     this.DrawLocations();
    // }

    // /**
    //  * @param goToLastLocation Si es true, hace Hash del ultimo elemento de la lista de Locations
    //  * @param locationEnter Se concatena a ubicaciones las existentes
    //  */
    // public met_NewLocation(goToLastLocation: boolean, ...locationEnter: Array<IObjectLocation>) {
    //     locationEnter.forEach(loc => {
    //         this.locations.set(loc.BaseHash, loc);
    //     })

    //     if (goToLastLocation) {
    //         this.ReplaceLocation(locationEnter[locationEnter.length - 1].GetHash());
    //     }
    //     this.DrawLocations();
    // }

    /** Actualiza la barra de navs con los elementos nuevos */
    public _UpdateLocations(newLocations: Array<IObjectLocation>) {
        this.locations.clear();
        this.DrawLocations();
        newLocations.forEach(d => this.locations.set(d.BaseHash, d));
        this.DrawLocations();
    }
}
