type ResizerOnResizeEventDetails = {
    Width: number;
    Height: number;
    MovementX: number;
    MovementY: number;
}
export type ResizerOnResizeEvent = CustomEvent<ResizerOnResizeEventDetails>;
type CallResizerOnResize = (e: ResizerOnResizeEvent) => void;
const TEMPLATE = document.createElement("template");

TEMPLATE.innerHTML = `
<style>
    :host {
        display: none;
        position: absolute;
        height: 100%;
        width: 5px;
        cursor: col-resize !important;
        right: 0;
    }
    :host(.sizing) {
        display: grid;
    }

    :host(.sizing) .resizer_indicator {
        background-color: var(--color_action1);
    }

    :host(.error) .resizer_indicator {
        background-color: red;
    }

    .resizer_indicator {
        width: 2px;
        height: 100%;
        background-color: var(--color_primary4);
        justify-self: flex-end;
    }
</style>

<span class="resizer_indicator"></span>
`

export class HTMLResizerIndicatorElement extends HTMLElement {
    #min: number;
    #max: number;
    #initialDim: number;

    private callOnResize: CallResizerOnResize;
    private callOnStartSizing: () => void;
    private callOnEndSizing: () => void;

    constructor() {
        super();
        this.attachShadow({ mode: "open" })
        this.shadowRoot.appendChild(TEMPLATE.content.cloneNode(true));

        this.addEventListener("click", e => {
            e.stopPropagation();
            e.preventDefault();
        })
    }

    private AddSizingEvents() {
        this.onpointerdown = e => {
            if (e.button != 0) return;
            const parent = this.parentElement.getBoundingClientRect();
            parent.width -= 10;

            e.stopPropagation();
            e.preventDefault();
            document.body.style.cursor = "col-resize"
            this.classList.add("sizing")

            if (this.callOnStartSizing) {
                this.callOnStartSizing();
            }

            document.onpointermove = e => {
                parent.width += (e.movementX)
                if ((this.#min && parent.width < this.#min) || (this.#max && parent.width > this.#max)) return;
                this.ResizeParent(parent.width, parent.height, e.movementX, e.movementY, this.parentElement.getBoundingClientRect().width)
            }

            document.onpointerup = e => {
                e.stopPropagation();
                e.preventDefault();
                document.body.style.cursor = null;
                this.classList.remove("sizing")
                document.onpointerup = null;
                document.onpointermove = null;

                if (this.callOnEndSizing) {
                    this.callOnEndSizing();
                }
            }
        }

        this.ondblclick = e => {
            if (this.#initialDim) {
                const parent = this.parentElement.getBoundingClientRect();
                parent.width -= 10;
                let auxMovementX = 0;
                let auxMovementY = 0;
                auxMovementX = this.#initialDim - parent.width;
                parent.width = this.#initialDim;
                this.ResizeParent(parent.width, parent.height, auxMovementX, auxMovementY, 0);
            }
        }
    }

    private ResizeParent(newWidth: number, newHeight: number, movX: number, movY: number, oldWidth: number) {
        this.parentElement.style.width = newWidth + "px";
        this.parentElement.style.minWidth = newWidth + "px ";
        this.parentElement.style.maxWidth = newWidth + "px";
        const e: ResizerOnResizeEvent = new CustomEvent<ResizerOnResizeEventDetails>("resize", {
            bubbles: false,
            cancelable: true,
            composed: false,
            detail: {
                Width: newWidth,
                Height: newHeight,
                MovementX: movX,
                MovementY: movY,
            }
        })
        if (this.callOnResize) {
            this.callOnResize(e);
        }
    }

    connectedCallback() {
        this.AddSizingEvents();
    }

    attributeChangedCallback(attrName: string, oldValue: string, newValue: string) {
        switch (attrName) {
            case "min":
                this.#min = (this.hasAttribute("min") ? Number(this.getAttribute("min")) : null);
                break;
            case "max":
                this.#max = (this.hasAttribute("max") ? Number(this.getAttribute("max")) : null);
                break;
            case "init-dim":
                this.#initialDim = (this.hasAttribute("init-dim") ? Number(this.getAttribute("init-dim")) : null);
                break;
        }
    }

    static get observedAttributes() {
        return ["min", "max", "init-dim", "color-base"];
    }

    public set _OnResize(callback: CallResizerOnResize) {
        this.callOnResize = callback;
    }

    public set _OnStartSizing(callback: () => void) {
        this.callOnStartSizing = callback;
    }

    public set _OnEndSizing(callback: () => void) {
        this.callOnEndSizing = callback;
    }
}

customElements.define("wc-resizer", HTMLResizerIndicatorElement);
