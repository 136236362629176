import { group as d3Group } from "d3-array";
import { MainPage } from "../../MainPage";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import DataModuloEgresoCategoria from "../../data/modulo/EgresoCategoria";
import DataModuloEscuela from "../../data/modulo/Escuela";
import { ArrayV2 } from "../../util/ArrayV2";
import { IConfigGridExcelExport, IGridExtraTableConfig, IGridRenderInfo, VentanaGrid } from "../controlD3/AVentanaGrid";
import { ExcelThings } from "../controlD3/ExcelExport";
import { Fields, FormGenerator } from "../controlD3/Formulario";
import { ModalThings } from "../controlD3/ModalThings";
import { Table } from "../controlD3/Tabla";
import { UIUtilLang } from "../util/Language";
import { UIUtilGeneral } from "../util/Util";

interface ICategoriaEgreso extends Entidad.IFinanzasEgresoCategoria {
    KinderFiltro: [];
}

export class UIVentanaFinanzasEgresosCategorias extends VentanaGrid<ICategoriaEgreso> {
    constructor(content: TSelectionHTML<"div">, modulo: Entidad.CModulo) {
        super(content, modulo);
    }
    protected GRID_GetDataRequestID(): DataModuloMain.TipoRequestMonitorId {
        return Entidad.CTipoRequest.EgresoCategoria;
    }
    protected GRID_GetMenuTopGrid(): Table.ITableMenuTopDefaultOptionConfig[] {
        let opt: Table.ITableMenuTopDefaultOptionConfig[] = [];
        if (this.GridHasPermisoAccion(Entidad.CAccionPermiso.Agregar)) {
            opt.push({
                Label: "action_agregar",
                Callback: () => {
                    this.OpenModal_FormularioAgregar();
                }
            })
        }
        return opt;
    }
    protected GRID_GetSelectionDataMenuV2(menuLocation: "row" | "top-selected", dataGridSelected: ICategoriaEgreso[]): Table.ITableMenuDataSelectedOptionConfig<ICategoriaEgreso>[] {
        let opciones: Array<Table.ITableMenuDataSelectedOptionConfig<ICategoriaEgreso>> = [];
        if (this.GridHasPermisoAccionV2(Entidad.CAccionPermiso.Editar)) {
            opciones.push({
                Label: "action_editar",
                Callback: (datos) => {
                    this.OpenModal_FormularioEditar(datos[0]);
                },
                MultiData: false
            })
        }

        if (this.GridHasPermisoAccionV2(Entidad.CAccionPermiso.Eliminar)) {
            opciones.push({
                Label: "action_eliminar",
                Callback: (datos) => {
                    this.OpenModal_FormularioEliminar(datos);
                }
            })
        }
        return opciones;
    }
    protected GRID_GetFilters(): Table.IParametroFiltro<ICategoriaEgreso>[] {
        return [
            { Label: "", Field: "Nombre" }
        ];
    }
    protected GRID_GetTableConfigBase(): IGridRenderInfo<ICategoriaEgreso> {
        return {
            IdTabla: "FinanzasCatalogos-CategoriaEgresos",
            MinWidth: 700,
            IdData: "Id",
            DefaultSort: "Nombre",
            Columns: [
                { Field: "Nombre", Label: "", Width: "50%", MinWidth: "150px" },
                { Field: "NombreKinder", Label: "Escuela", Width: "50%", MinWidth: "150px" },
            ]
        }
    }
    protected GRID_GetTableConfigAdvanced(): IGridExtraTableConfig<ICategoriaEgreso> {
        return null;
    }
    protected GRID_GetExportarConfig(dataGrid: ICategoriaEgreso[]): IConfigGridExcelExport<ICategoriaEgreso> {
        return {
            IdsEscuelas: [...new Set(dataGrid.map(d => d.IdEscuela))],
            OnGetDataBySheets: async () => {
                return Array.from(d3Group(dataGrid, d => d.IdEscuela))
                    .map<ExcelThings.ISheetConfig<ICategoriaEgreso>>(([idEscuela, categorias]) => ({
                        IdSheet: idEscuela,
                        SheetName: categorias[0].NombreKinder,
                        Data: categorias
                    }))
            },
            OnGetEscuelasTagInSheet: (datos) => datos[0].NombreKinder
        }
    }

    private OpenModal_FormularioAgregar() {
        this.GridOpenModal_ActionFormToAGridData({
            Width: 400,
            Action: Entidad.CAccionPermiso.Agregar,
            GetForm: (content, mt) => this.GetForm(Entidad.CAccionPermiso.Agregar),
            OnAccept: async (form, mt) => {
                interface IDataProcess {
                    Resultado: number,
                    ItemData: ICategoriaEgreso,
                    TipoRequest: Entidad.CTipoRequest
                }

                let dataProcess = (form._Data.IdEscuela as any as number[]).map<IDataProcess>(idEscuela => ({
                    Resultado: -1,
                    ItemData: { ...form._Data, IdEscuela: idEscuela },
                    TipoRequest: this.GRID_GetDataRequestID(),
                }))

                await ModalThings._ProccessServiceByServiceFromAArrayIterator(
                    mt,
                    dataProcess,
                    {
                        OnStepAProccess: (itemData) => {
                            return DataModuloEgresoCategoria._Nuevo(itemData);
                        },
                        OnError_GetItemDataTag: (item) => DataModuloMain._GetDataValueFieldByName("Escuela", item.IdEscuela, "Nombre"),
                        Action: Entidad.CAccionPermiso.Agregar,
                        GeneralErrMessage: this.VB_GetUIStringModule("msg_addcategoria_err"),
                    },
                ).catch((err) => { console.warn(err); return null });

                let arrDataProcess = new ArrayV2<IDataProcess>()._Push(...dataProcess);
                let nReloadErr = 0;
                mt.Progress.attr("oculto", false);
                await arrDataProcess._ForEachAwait(async (d) => {
                    if (d.Resultado > 0) {
                        await MainPage._ReloadServiceAndAwait(this.GRID_GetDataRequestID(), d.ItemData.IdEscuela).catch((err) => {
                            nReloadErr++;
                        });
                    }
                })
                if (nReloadErr > 0) this.notificacion._Mostrar(UIUtilLang._GetUIString("general", "notif_fail_infoupdate"), "ADVERTENCIA");
                mt.Modal._Ocultar();
                await UIUtilGeneral._Sleep(300);
                return null;
            }
        });
    }

    private OpenModal_FormularioEditar(categoriaEditar: ICategoriaEgreso) {
        this.GridOpenModal_ActionFormToAGridData({
            Width: 400,
            Action: Entidad.CAccionPermiso.Editar,
            GetForm: (content, mt) => this.GetForm(Entidad.CAccionPermiso.Editar, categoriaEditar),
            OnAccept: async (form, mt) => {
                /* const res = await Data.Modulo.EgresoCategoria.actualizar(form.prop_Data);
                if (res.Resultado == -2) {
                    await MainPage.fn_ReloadServiceAndAwait(this.GRID_GetDataRequestID(), form.prop_DataOrigin.IdEscuela);
                    mt.Modal.met_Ocultar();
                }
                return res; */
                return DataModuloEgresoCategoria._Actualizar(form._Data);
            }
        })
    }

    private OpenModal_FormularioEliminar(datos: Array<ICategoriaEgreso>) {
        this.GridOpenModal_ProccessArrayData({
            DataToProccess: datos,
            OnGetIdEscuela: (dato) => dato.IdEscuela,
            OnError_GetItemDataTag: (dato) => dato.Nombre + " - " + dato.NombreKinder,
            OnStepAProccess: (dato) => {
                return DataModuloEgresoCategoria._Eliminar(dato.Id);
            },
            /* OnEndAndCloseProccess: (datosCorrectos, idsEscuelas, results) => {
                if (!datosCorrectos.length && results.some(d => d.Resultado == -2)) {
                    MainPage.fn_ReloadService(this.GRID_GetDataRequestID())
                }
            }, */
        })
    }

    private GetForm(action: (Entidad.CAccionPermiso.Agregar | Entidad.CAccionPermiso.Editar), datoCateg = <ICategoriaEgreso>{}) {
        let form = new FormGenerator<ICategoriaEgreso>();
        const esEditar = (action == Entidad.CAccionPermiso.Editar);
        let dataForm: ICategoriaEgreso = null;
        if (esEditar) dataForm = Object.assign(<ICategoriaEgreso>{}, datoCateg);
        let escuelasConPermiso = this.GridGetEscuelasConPermisoDeAccion(action);

        if (esEditar && !escuelasConPermiso.find(d => d.IdKinder == datoCateg.IdEscuela) && DataModuloEscuela._DiccEscuela.has(datoCateg.IdEscuela)) {
            escuelasConPermiso.push(DataModuloEscuela._DiccEscuela.get(datoCateg.IdEscuela));
        }
        form._Crear({
            schema: [
                {
                    model: "Nombre",
                    labelText: "d_field_nombre",
                    type: Fields.input,
                    inputAttr: { type: "text", required: true }
                },
                {
                    model: "IdEscuela",
                    type: Fields.selectMaterial,
                    labelText: "d_field_nombrekinder",
                    selectMaterialAttr: {
                        valueMember: "IdKinder",
                        displayMember: "Nombre",
                        required: true,
                        Data: escuelasConPermiso,
                        multiselect: !esEditar,
                    }
                }
            ],
            LabelMaxWidth: 110,
            LangModuleKeyInContext: Entidad.CModulo[this.modulo]
        }, dataForm);
        return form;
    }

    public _Mostrar(): void {
        super._Mostrar();
        MainPage._ActivarServiceMonitor(this.GRID_GetDataRequestID(), true)
    }

    public _Destroy(): void {
        super._Destroy();
        MainPage._ActivarServiceMonitor(this.GRID_GetDataRequestID(), false)
    }
}
