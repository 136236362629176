import { _DiccEscuela } from "../data/modulo/Escuela";

type TConstructorParams = [] | [Date] | [DateV2] | [string] | [number] // | [number, number, number]
interface IDatePart {
    Year: number;
    Month: number;
    Day: number;
    Hour: number;
    Minute: number;
    Second: number;
    Milisecond: number;
}
type TTimeTypeKey = keyof IDatePart;
// type TTimeTypeKeyLowerCase = Lowercase<keyof ITimeElapsed>;
const TYPE_TIMES_ORDER: TTimeTypeKey[] = ["Year", "Month", "Day", "Hour", "Minute", "Second", "Milisecond"];
export class DateV2 extends Date {
    private timeZone: string | undefined;
    private timeZoneOffset: number | undefined;

    constructor(...args: TConstructorParams) {
        if (args?.length == 1) {
            super(args[0]);

            if (args[0] instanceof DateV2) {
                this.timeZone = args[0].timeZone;
                this.timeZoneOffset = args[0].timeZoneOffset;
            }
        }
        else if (args?.length > 1) {
            throw (new Error("Parameters not supported"));
        }
        else {
            super();
        }
    }

    /** Solo KidiAdmin
     * @param idEscuela
     * @param preserveDate Define si la fecha será adaptada por la nueva zona horaria. @default false
     */
    public _SetTimeZoneByIdSchool(idEscuela: number, preserveDate: boolean = false) {
        const zonaHoraria = _DiccEscuela.get(idEscuela)?.ZonaHoraria || null;
        return this._SetTimeZone(zonaHoraria, preserveDate);
    }

    /** Asigna una zona horaria, solo se asigna valor si la instancia
     * no tiene asignada una zona horaria previa.
     * @param timeZone @example "America/Monterrey"
     * @param preserveDate Define si la fecha será adaptada por la nueva zona horaria. @default false
     */
    public _SetTimeZone(timeZone: string, preserveDate: boolean = false) {
        if (!timeZone) {
            console.warn("Invalid TimeZone parámeter", timeZone);
            return this;
        }
        if (!this.timeZone) {
            const oldTime = this.getTime();
            const msAux = this.getMilliseconds();
            this.timeZone = timeZone;

            let strDate = this.toLocaleString("en-US", { timeZone: this.timeZone });
            let dt = new Date(strDate);
            let timeDiference: number;
            let newTimeOffsetBase: number;

            if (preserveDate) {
                dt.setMilliseconds(msAux);
                timeDiference = dt.getTime() - oldTime;
                newTimeOffsetBase = dt.getTimezoneOffset();
            }
            else {
                this.setTime(dt.getTime());
                this.setMilliseconds(msAux);
                timeDiference = this.getTime() - oldTime;
                newTimeOffsetBase = this.getTimezoneOffset();
            }
            let minDif = timeDiference / 60000;
            this.timeZoneOffset = newTimeOffsetBase - minDif;
            // if (this.timeZoneOffset != this.getTimezoneOffset()) {
            //     console.debug(timeZone, timeDiference, minDif, this.timeZoneOffset, this.getTimezoneOffset())
            // } else {
            //     console.warn(timeZone, timeDiference, minDif, this.timeZoneOffset, this.getTimezoneOffset())
            // }
        }
        else if (this.timeZone != timeZone) {
            let err = new Error();
            err.message = "TimeZone already exist!"
                + "\nCurrent: " + this.timeZone
                + "\nFail: " + timeZone;
            throw (err);
        }
        return this;
    }

    public _GetTimeZone() {
        return this.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone;;
    }

    public _GetTimezoneOffset() {
        return this.timeZoneOffset !== undefined ? this.timeZoneOffset : this.getTimezoneOffset();
    }

    /** Returns a date as a string value in ISO format.
     * Use instead of `toISOString`
     */
    public _ToISOString() {
        let dtCurrentTimeZone = new Date(this);
        let currentTimeZoneOffset = dtCurrentTimeZone.getTimezoneOffset();
        if (this.timeZoneOffset != null && currentTimeZoneOffset != this.timeZoneOffset) {
            let difTimesZ = currentTimeZoneOffset - this.timeZoneOffset;
            dtCurrentTimeZone.setMinutes(dtCurrentTimeZone.getMinutes() - difTimesZ);
        }
        return dtCurrentTimeZone.toISOString();
    }

    public _ToISOLocalString() {
        const Y = this.getFullYear();
        const M = (this.getMonth() + 1).toString().padStart(2, "0");
        const D = this.getDate().toString().padStart(2, "0");
        const H = this.getHours().toString().padStart(2, "0");
        const MM = this.getMinutes().toString().padStart(2, "0");
        const S = this.getSeconds().toString().padStart(2, "0");
        const MS = this.getMilliseconds().toString().padStart(3, "0");

        return `${Y}-${M}-${D}T${H}:${MM}:${S}.${MS}Z`
    }

    // /** si `timeZoneOffset` está definido al final de la cadena la diferencia de hora de TZ,
    //  * sino retorna el valor de `_ToISOString`.
    //  *
    //  * "2020-02-01T00:00:00.00-07:00" || "2020-02-01T00:00:00.00Z"
    //  *
    // */
    // public _ToISOTZString() {
    //     let dateFmt: string;
    //     if (this.timeZoneOffset === undefined) {
    //         return this._ToISOString();
    //     }
    //     dateFmt = this.toISOString().replace("Z", "");
    //     const isNeg = this.timeZoneOffset < 0

    //     function fmtTime(minutos) {
    //         let horas: (number | string) = Math.floor(minutos / 60);
    //         let minutosRestantes: (number | string) = minutos % 60;

    //         horas = (horas < 10) ? '0' + horas : horas;
    //         minutosRestantes = (minutosRestantes < 10) ? '0' + minutosRestantes : minutosRestantes;

    //         return `${horas}:${minutosRestantes}`;
    //     }

    //     const strTm = isNeg
    //         ? "+" + fmtTime(-1 * this.timeZoneOffset)
    //         : "-" + fmtTime(this.timeZoneOffset);
    //     console.warn(dateFmt + strTm, this.timeZoneOffset)
    //     return dateFmt + strTm
    // }

    /**
     * @param timeStr
     * @example
     * "12:00"
     * "12:30:00"
     * "12:30:00.000"
     */
    public _SetLocalStrHour(timeStr: string) {
        const hrSplit = timeStr.split(":");
        const hr = hrSplit[0];
        const min = hrSplit[1];
        const sec = hrSplit[2];
        const ms = timeStr.split(".")[1];
        if (hr != null) {
            this.setHours(Number(hr));
        }
        if (min != null) {
            this.setMinutes(Number(min));
        }
        if (sec != null) {
            this.setSeconds(Number(sec));
        }
        if (ms != null) {
            this.setMilliseconds(Number(ms.replace("Z", "")));
        }
        return this;
    }

    /**
     * @param timeStr
     * * @example
     * "12:00"
     * "12:30:00"
     * "12:30:00.000"
     * "12:30:00.000Z"
     */
    public _ReplaceUTCHour(timeStr: string) {
        const hrSplit = timeStr.split(":");
        if (!hrSplit[0]) throw Error("String hour required to replace date");
        const refStrDt = this._ToISOString().split("T")[0];
        const hr = hrSplit[0];
        const min = hrSplit[1] || "00";
        const sec = hrSplit[2] || "00";
        const ms = timeStr.split(".")[1]?.replace("Z", "") || "00";
        let newStrUTCDt = `${refStrDt}T${hr}:${min}:${sec}.${ms}Z`;
        let dt = new DateV2(newStrUTCDt);
        if (this.timeZone) {
            dt._SetTimeZone(this.timeZone);
        }
        this.setFullYear(dt.getFullYear());
        this.setMonth(dt.getMonth());
        this.setDate(dt.getDate());
        this.setHours(dt.getHours(), dt.getMinutes(), dt.getSeconds(), dt.getMilliseconds());
        return this;
    }

    /**
     * @param start Trunca a partir del argumento especificado
     * @example 
     * this = "2020-04-12 12:23:22:123"
     * >> start = "Day"
     * resultDate: 2020/04/01 00:00:00.00
     */
    public _Truncate(start: keyof Omit<IDatePart, keyof Pick<IDatePart, "Year">>) {
        let startTypeTimeIndex = TYPE_TIMES_ORDER.indexOf(start);
        let endTypeTimeIndex = TYPE_TIMES_ORDER.length - 1;
        let i = startTypeTimeIndex;
        while (i <= endTypeTimeIndex) {
            let typeTime = TYPE_TIMES_ORDER[i];
            switch (typeTime) {
                case "Month":
                    this.setMonth(0); // Enero
                    break;
                case "Day":
                    this.setDate(1);
                    break;
                case "Hour":
                    this.setHours(0);
                    break;
                case "Minute":
                    this.setMinutes(0);
                    break;
                case "Second":
                    this.setSeconds(0);
                    break;
                case "Milisecond":
                    this.setMilliseconds(0);
                    break;
            }
            i++;
        }
        return this;
    }

    public _AddFullYears(years: number) {
        this.setFullYear(this.getFullYear() + years);
        return this;
    }

    public _AddMonths(months: number) {
        this.setMonth(this.getMonth() + months);
        return this;
    }

    /** Add to Date */
    public _AddDays(days: number) {
        this.setDate(this.getDate() + days);
        return this;
    }

    public _AddHours(hours: number) {
        this.setHours(this.getHours() + hours);
        return this;
    }

    public _AddMinutes(minutes: number) {
        this.setMinutes(this.getMinutes() + minutes);
        return this;
    }

    public _AddSeconds(seconds: number) {
        this.setSeconds(this.getSeconds() + seconds);
        return this;
    }

    public _AddMilliseconds(miliseconds: number) {
        this.setMilliseconds(this.getMilliseconds() + miliseconds);
        return this;
    }

    /** @deprecated Use `_ToISOString` instead */
    public toISOString() {
        return super.toISOString();
    }

    /** @deprecated Use `met_GetTimezoneOffset` instead */
    public getTimezoneOffset() {
        return super.getTimezoneOffset();
    }
}