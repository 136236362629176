import * as d3 from "d3";
import { HTMLCheckBoxElement } from "../controlWC/CheckboxComponent";
import { UIUtilGlobalKeyEvents } from "../util/GlobalKeyEvent";
import { UIUtilGeneral } from "../util/Util";

export interface ITreeViewConfig<TSub> {
    ParentContainer: d3.Selection<HTMLElement, any, any, any>;
    IdDataSub: keyof TSub;
    /** @default true */
    IsMultiselect?: boolean;
    GetContentTop: (container: TSelectionHTML<"div">) => string;
    StepUpdateContent: (container: TSelectionHTML<"div">, dato: TSub) => void;
    OnChangeByUser: () => void;
}

interface IItemTree<T> {
    Id: any;
    Data: T;
    IsChecked: boolean;
}

export class TreeView<TSub> {
    private config: ITreeViewConfig<TSub>;
    private content: TSelectionHTML<"div">;
    private treeData: Map<any, IItemTree<TSub>>;

    constructor(config: ITreeViewConfig<TSub>) {
        this.config = config;
        this.treeData = new Map();

        if (this.config.IsMultiselect == undefined) {
            this.config.IsMultiselect = true;
        }

        this.UI_Init();
    }

    private UI_Init() {
        this.content = this.config.ParentContainer
            .append("div")
            .attr("class", UIUtilGeneral.FBoxOrientation.Vertical + " tree");

        this.content.append("div")
            .attr("class", "top_tree")
            .append(() => this.UI_DrawItem().node());

        this.content.append("div")
            .attr("class", "sub_container");
    }

    private UI_DrawItem() {
        let item = d3.create("div")
            .attr("class", "sub_itemtree");

        item.append("wc-checkbox")
            .attr("style-form", "circle-check")
            .style("pointer-events", "none")
            .style("min-width", "18px");
        // .append("input").attr("type", "checkbox");

        item.append("div")
            .attr("class", "sub_itemcontent");

        return item;
    }

    private UI_UpdateTop() {
        let topItem = this.content
            .select(".top_tree")
            .classed("hide", !this.config.IsMultiselect)
            .select<HTMLDivElement>(".sub_itemtree");

        if (this.config.IsMultiselect) {
            let checkbox = topItem.select<HTMLCheckBoxElement>("wc-checkbox");

            checkbox.node()._Checked = this.DATA_AllItemsChecked();

            let content = topItem.select<HTMLDivElement>(".sub_itemcontent")
            content.html(this.config.GetContentTop(content));

            const fnToggleStatus = () => {
                let lastStatusCheck = this.DATA_AllItemsChecked();
                this.DATA_CheckAllItems(!lastStatusCheck);
                this.UI_UpdateTreeSubs();
                checkbox.node()._Checked = !lastStatusCheck;
                this.config.OnChangeByUser();
            }
            topItem.on("click", () => {
                if (!this.config.IsMultiselect) {
                    return;
                }
                fnToggleStatus();
            });
            UIUtilGlobalKeyEvents._SetKeyEventCallback(topItem.node(), {
                key: "0",
                altKey: true,
                callback: () => fnToggleStatus(),
            })
        } else {
            topItem.on("click", null);
        }
    }

    private UI_UpdateTreeSubs() {
        let datos = Array.from(this.treeData.values());

        this.content
            .select(".sub_container")
            .selectAll<HTMLDivElement, TSub>(".sub_itemtree")
            .data(datos)
            .join(
                enter => {
                    let item = enter.append(() => this.UI_DrawItem().node())
                    return this.UI_UpdateTreeItem(item);
                },
                update => this.UI_UpdateTreeItem(update),
                exit => exit.remove()
            )
    }

    private UI_UpdateTreeItem(items: TSelectionHTML<"div", IItemTree<TSub>>) {
        const isMultiselect = this.config.IsMultiselect;

        items.each((d, i, arr) => {
            let item = d3.select(arr[i])
                .classed("selected", d.IsChecked);

            let itemNode = arr[i];
            let checkbox = itemNode.querySelector<HTMLCheckBoxElement>("wc-checkbox");
            let content = itemNode.querySelector<HTMLDivElement>(".sub_itemcontent");

            this.config.StepUpdateContent(d3.select(content), d.Data);
            checkbox._Checked = d.IsChecked;

            item.style("padding-left", !isMultiselect ? "0px" : null);

            const DELAY = 300;
            let clicks = 0;
            let timer: NodeJS.Timeout = null;
            let nChildKey = [...itemNode.parentElement.childNodes].indexOf(itemNode) + 1;

            const fnToggleItemStatus = () => {
                let newStatus = !d.IsChecked;
                if (!isMultiselect && !newStatus) {
                    return;
                }
                let changed = this.DATA_OnCheckItem(d, newStatus);
                // d.IsChecked = newStatus;
                if (changed) {
                    checkbox._Checked = d.IsChecked;
                    // this.UI_UpdateTop();
                    this.UI_RefreshAll();
                    this.config.OnChangeByUser();
                    item.classed("selected", d.IsChecked);
                }
            }

            UIUtilGlobalKeyEvents._SetKeyEventCallback(itemNode, {
                key: nChildKey.toString() as any,
                altKey: true,
                callback: () => fnToggleItemStatus(),
            })
            itemNode.onclick = () => {
                clicks++;
                if (clicks == 1) {
                    timer = setTimeout(() => {
                        timer = null;
                        clicks = 0;
                        fnToggleItemStatus();
                    }, DELAY);
                }
                else if (clicks == 2) {
                    clearTimeout(timer);
                    timer = null;
                    clicks = 0;
                    if (d.IsChecked && this.DATA_GetCheckedItems().length == 1) {
                        return;
                    }
                    let newStatus = true; // !d.IsChecked;
                    this.treeData
                        .forEach((d) => {
                            d.IsChecked = false;
                        });
                    this.DATA_OnCheckItem(d, newStatus);
                    checkbox._Checked = d.IsChecked;
                    // this.UI_UpdateTop();
                    this.UI_RefreshAll();
                    this.config.OnChangeByUser();
                    item.classed("selected", d.IsChecked);
                }
            }
        })

        return items;
    }

    /** @returns success status changed */
    private DATA_OnCheckItem(item: IItemTree<TSub>, status: boolean): boolean {
        const isMultiselect = this.config.IsMultiselect;

        if (isMultiselect) {
            item.IsChecked = status;

            return true;
        }
        else if (item.IsChecked != status) {
            if (status) {
                this.treeData
                    .forEach((d) => {
                        d.IsChecked = false;
                    });
            }
            item.IsChecked = status; // true
            return true;
        }
        return false;
    }

    private UI_RefreshAll() {
        this.UI_UpdateTop();
        this.UI_UpdateTreeSubs();
    }

    private DATA_CheckAllItems(check: boolean) {
        let dataUpdated = false;
        if (this.config.IsMultiselect || !check) {
            this.treeData
                .forEach(d => {
                    if (this.DATA_OnCheckItem(d, check)) {
                        dataUpdated = true;
                        // d.IsChecked = check;
                    }
                });
        }

        return dataUpdated;
    }

    private DATA_CheckItems(ids: any[], check: boolean) {
        let dataUpdated = false;
        if (this.config.IsMultiselect) {
            ids
                .forEach(id => {
                    let item = this.treeData.get(id);
                    if (item) {
                        if (this.DATA_OnCheckItem(item, check)) {
                            dataUpdated = true;
                            // item.IsChecked = check;
                        }
                    }
                });
        } else {
            let item = this.treeData.get(ids[0]);
            if (item) {
                if (this.DATA_OnCheckItem(item, check)) {
                    dataUpdated = true;
                }
            }
        }

        return dataUpdated;
    }

    private DATA_AllItemsChecked(): boolean {
        return (this.treeData.size == this.DATA_GetCheckedItems().length);
    }

    private DATA_GetCheckedItems() {
        return Array.from(this.treeData.values())
            .filter(d => d.IsChecked);
    }

    private DATA_GetCheckedItemsData() {
        return this.DATA_GetCheckedItems()
            .map(d => d.Data);
    }

    // **********************************************************
    // PUBLIC PROPERTIES
    // **********************************************************

    public get _Data() {
        return Array.from(this.treeData.values())
            .map(d => d.Data);
    }

    public get _DataChecked() {
        return this.DATA_GetCheckedItemsData();
    }

    public get _HasAllDataChecked() {
        return this.DATA_AllItemsChecked();
    }

    public get _ControlContainer() {
        return this.content;
    }

    /** Si el control no es Multiselect y el nuevo estado check es true...
     * Solo se checkea el primer elemento si no hay otro checkeado
    */
    public _CheckAllItems(checkStatus: boolean) {
        if (this.DATA_CheckAllItems(checkStatus)) {
            this.UI_RefreshAll();
        }
        return this;
    }

    public _CheckItems(ids: any[], status: boolean) {
        if (this.DATA_CheckItems(ids, status)) {
            this.UI_RefreshAll();
        }
        return this;
    }

    public _RefreshView() {
        this.UI_RefreshAll();
        return this;
    }

    public _Multiselect(multiselect = true) {
        this.config.IsMultiselect = multiselect;

        if (!multiselect) {
            let idsChecked = this.DATA_GetCheckedItemsData()
                .map(d => d[this.config.IdDataSub])
                .slice(1);

            if (idsChecked.length > 0) {
                this.DATA_CheckItems(idsChecked, false);
            }
        }
        this.UI_RefreshAll();
        return this;
    }

    public _UpdateData(datos: TSub[]) {
        const currentDataTree = new Map(this.treeData);
        this.treeData.clear();
        // let triggerOnChange = false;

        datos.forEach(d => {
            const id = d[this.config.IdDataSub];
            let itemFounded = currentDataTree.get(id);

            if (itemFounded) {
                itemFounded.Data = d;
                currentDataTree.delete(id);
            } else {
                // triggerOnChange = true;
                itemFounded = {
                    Id: id,
                    Data: d,
                    IsChecked: false
                }
            }

            this.treeData.set(id, itemFounded);
        })

        // if (!triggerOnChange && currentDataTree.size) {
        //     triggerOnChange = true;
        // }

        this.UI_RefreshAll();

        // if (triggerOnChange) {
        //     this.config.OnChangeByUser();
        // }
        return this;
    }
}
