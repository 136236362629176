import { DataDRequest } from "../DRequest";
import { Entidad } from "../Entidad";
import { Global } from "../Global";
import { DataUtil } from "../util/Util";

import IMaestro = Entidad.IMaestro;
const IDREQUEST = Entidad.CTipoRequest.Maestro;
/** entidad_maestra.IdEscuela = 0 es Maestra general */
export const _MAESTRA_GENERAL = 0;

export const _DictMaestros = new Map<number, IMaestro>();

export async function _GetList(escuelas: Entidad.IEscuela[]): Promise<DataDRequest.IRequestResponseListA<IMaestro>[]> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "maestro/ObtenerLista";
    let requestResults: DataDRequest.IRequestResponseListA<IMaestro>[] = [];

    for (let idEscuela of [_MAESTRA_GENERAL, ...escuelas.map(d => d.IdKinder)]) {
        const lastStrDate = await DataUtil._GetMaxDate(IDREQUEST, idEscuela);
        const params = {
            "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
            "IdEscuela": idEscuela,
            "Modificacion": lastStrDate
        }

        let result = await DataDRequest._Request4List(url, "GET", params, IDREQUEST, idEscuela);

        requestResults.push(result);
    }

    return requestResults;
}

export function _NuevoRegistro(dato: Entidad.IMaestro & { Contrasenia: string }) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "maestro/Nuevo";
    const params = {
        "Nombre": dato.Nombre,
        "ApPaterno": dato.ApPaterno,
        "ApMaterno": dato.ApMaterno,
        "Correo": dato.Correo.toLowerCase().trim(),
        "Contrasenia": dato.Contrasenia,
        "Telefono": dato.Telefono,
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
        "IdEscuela": dato.IdEscuela,
        "EntradaSalidaEscuela": dato.EntradaSalidaEscuela
    };

    return DataDRequest._Request5(url, "POST", params, IDREQUEST);
}

export function _ActualizarRegistro(dato: Entidad.IMaestro, contrasenia: string) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "maestro/ActualizarV2"; // "maestro/Actualizar";
    const params = {
        "Nombre": dato.Nombre,
        "ApPaterno": dato.ApPaterno,
        "ApMaterno": dato.ApMaterno,
        "Correo": dato.Correo.toLowerCase().trim(),
        "Contrasenia": contrasenia,
        "Telefono": dato.Telefono,
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "IdMaestro": dato.Id,
        "EntradaSalidaEscuela": dato.EntradaSalidaEscuela
    };

    return DataDRequest._Request5(url, "POST", params, IDREQUEST);
}

export function _EliminarRegistro(idMaestro: number) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "maestro/Eliminar";
    const params = {

        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "IdMaestro": idMaestro
    };

    return DataDRequest._Request5(url, "POST", params, IDREQUEST);
}

export async function _SvMaestroObtenerAccesos(idsMaestros: number[]) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "maestro/ObtenerAccesos";
    const params = {
        Ids: idsMaestros
    }

    return DataDRequest._Request5<{ [key: string]: string }>(url, "GET", params, IDREQUEST);
}

export default {
    _DictMaestros,
    _NuevoRegistro,
    _ActualizarRegistro,
    _EliminarRegistro,
}
