import { ArrayV2 } from "../../util/ArrayV2";
import { DataDRequest } from "../DRequest";
import { Entidad } from "../Entidad";
import { Global } from "../Global";
import { DataUtil } from "../util/Util";

import ILogro = Entidad.ILogro;
import IEscuela = Entidad.IEscuela;

export const _DiccLogros: Map<number, Entidad.ILogro> = new Map();
const IDREQUEST = Entidad.CTipoRequest.Logro;

export function _GetList(escuelas: IEscuela[]): Promise<DataDRequest.IRequestResponseListA<ILogro>[]> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "logro/ObtenerLista";
    return new ArrayV2<IEscuela>()
        ._Push(...escuelas)
        ._MapAwait(async ({ IdKinder }) => {
            const currentDateStr = await DataUtil._GetMaxDate(IDREQUEST, IdKinder);
            const params = {
                "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
                "IdEscuela": IdKinder,
                "Modificacion": currentDateStr,
            }
            return DataDRequest._Request4List(url, "GET", params, IDREQUEST, IdKinder);
        });
}

export async function _AltaLogro(idEscuela: number, idLogroCategoria: number, nombre: string, proceso: Entidad.CLogroTipoProceso): Promise<DataDRequest.IResultadoPeticion<undefined>> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "logro/Nuevo";
    const params = {
        "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "IdKinder": idEscuela,
        "IdCategoria": idLogroCategoria,
        "Nombre": nombre,
        "Proceso": proceso
    }

    return DataDRequest._Request5(url, "POST", params, IDREQUEST);
}

export async function _ActualizarLogro(idLogro: number, proceso: Entidad.CLogroTipoProceso): Promise<DataDRequest.IResultadoPeticion<undefined>> {
    const url = Global._GLOBAL_CONF.URL_RSTV1 + "Logro/ActualizarLogro";
    const params = {
        "IdLogro": idLogro,
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "Proceso": proceso
    }

    return new Promise(function (resolve, reject) {
        DataDRequest._Request(url, "POST", params, (result: number) => {
            resolve({
                Resultado: (result || -100),
                TipoRequest: IDREQUEST
            });
        })
    });
}

export async function _EliminarLogro(idLogro: number): Promise<DataDRequest.IResultadoPeticion<undefined>> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "logro/Eliminar";
    const params = {
        "IdLogro": idLogro,
        "IdUsuario": DataUtil._Usuario.IdUsuario
    }

    return DataDRequest._Request5(url, "POST", params, IDREQUEST);
}

export async function _DownloadExcelTemplate() {
    const url = Global._GLOBAL_CONF.URL_SrvRoot + "archivo/importar/plantilla/ObtenerExcel?IdEscuela=0&IdCategoria=7";
    const filename = "Logros.xlsx";

    return DataDRequest._DownloadFile(url, filename);
}

export default {
    _DiccLogros,
    _AltaLogro,
    _ActualizarLogro,
    _EliminarLogro,
    _DownloadExcelTemplate,
}
