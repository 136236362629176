import * as d3 from "d3";
import { group as d3Group } from "d3-array";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import { _DICC_ALUMNO, _LOCALDATA_GetGruposHorariosDeAlumno } from "../../data/modulo/Alumno";
import DataModuloEscuela from "../../data/modulo/Escuela";
import DataModuloGrado from "../../data/modulo/Grado";
import DataModuloLogroAsignacion from "../../data/modulo/LogroAsignacion";
import { IConfigGridExcelExport, IGridExtraTableConfig, IGridRenderInfo, VentanaGrid } from "../controlD3/AVentanaGrid";
import { ExcelThings } from "../controlD3/ExcelExport";
import { Table } from "../controlD3/Tabla";
import { UIUtilLang } from "../util/Language";
import { UIUtilTime } from "../util/Time";
import { UIUtilGeneral } from "../util/Util";
import { UIUtilViewData } from "../util/ViewData";
import { UIUtilViewGrupos } from "../utilView/Grupos";

interface ILogroAsignacion extends Entidad.ILogroAsignacion {
    AlumnoNombre: string;
    AlumnoApPaterno: string;
    AlumnoApMaterno: string;
    AlumnoNombreCompleto: string;
    LogroNombre: string;
    /** ["★Prepa 1ro A", "Grupo 2"] */
    GruposNombres: string;
    /** ["Prepa 1ro A", "Grupo 2"] */
    GruposNombresSimples: string[];
    GradoNombre: string;
    NivelNombre: string;
    ZonaHoraria: string;
}
export class UIVentanaReporteLogros extends VentanaGrid<ILogroAsignacion> {

    constructor(content: d3.Selection<HTMLDivElement, undefined, HTMLElement, any>, modulo: Entidad.CModulo) {
        super(content, modulo, {
            LabelsKeyBase: "reportelogros",
            ModuloObservableToTblRefresh: [Entidad.CTipoRequest.HorarioAlumno],
        });
    }

    protected GRID_GetMenuTopGrid(): Table.ITableMenuTopDefaultOptionConfig[] {
        return null;
    }

    protected GRID_GetSelectionDataMenuV2(menuLocation: "row" | "top-selected", dataGridSelected: ILogroAsignacion[]): Table.ITableMenuDataSelectedOptionConfig<ILogroAsignacion>[] {
        let opciones: Array<Table.ITableMenuDataSelectedOptionConfig<ILogroAsignacion>> = [];

        if (this.GridHasPermisoAccion(Entidad.CAccionPermiso.Eliminar)) {
            opciones.push({
                Label: "Eliminar",
                Callback: (datos) => this.OpenModal_EliminarDatos(datos)
            })
        }

        return opciones;
    }

    protected GRID_GetFilters(): Array<Table.IParametroFiltro<ILogroAsignacion>> {
        return [
            { LangModuleKeyInContext: "alumnos", LabelLangKey: "d_field_nombre", Field: "AlumnoNombre" },
            { LangModuleKeyInContext: "alumnos", LabelLangKey: "d_field_appaterno", Field: "AlumnoApPaterno" },
            { LangModuleKeyInContext: "alumnos", LabelLangKey: "d_field_apmaterno", Field: "AlumnoApMaterno" },
            {
                LabelLangKey: "tag_logro",
                Field: "IdLogro",
                Type: "select",
                Options: DataModuloMain._GetReqDataArrayByName("Logro", true)
                    .map(d => ({
                        Id: d.IdLogro,
                        Name: d.Nombre
                    }))
            },
            {
                LabelLangKey: "d_field_strestado",
                Field: "Estado",
                Type: "select",
                Options: UIUtilViewData._GetList_LogroAsignEstados()
            },
            {
                Label: "Grupo",
                LabelLangKey: "d_field_gruposnombres",
                Field: "GruposNombresSimples",
                Type: "text", OnGetValueToMatch: (dato: ILogroAsignacion) => dato.GruposNombresSimples,
            },
            { Label: "Grado", Field: "GradoNombre" },
            { Label: "Escolaridad", Field: "NivelNombre" },
        ]
    }

    protected GRID_GetTableConfigBase(): IGridRenderInfo<ILogroAsignacion> {
        return {
            IdTabla: "ReportesLogros",
            Title: "",
            DefaultSort: "AlumnoNombreCompleto",
            IdData: "IdAsignacion",
            MinWidth: 1100,
            Columns: [
                { Field: "AlumnoNombreCompleto", Label: "Alumn@", Width: "20%", MinWidth: "90px" },
                { Field: "LogroNombre", Label: "Logro", Width: "13%", MinWidth: "90px" },
                { Field: "StrEstado", Label: "Estado", Width: "8%", MinWidth: "90px" },
                { Field: "Iniciado", Label: "Iniciado", Width: "10%", MinWidth: "90px" },
                { Field: "Avanzado", Label: "Avanzado", Width: "10%", MinWidth: "90px" },
                { Field: "Terminado", Label: "Terminado", Width: "10%", MinWidth: "90px" },
                { Field: "GruposNombres", Label: "Grupo", Width: "12%", MinWidth: "90px" },
                { Field: "GradoNombre", Label: "Grado", Width: "12%", MinWidth: "90px" },
                { Field: "NivelNombre", Label: "Escolaridad", Width: "12%", MinWidth: "90px" },
            ]
        }
    }

    protected GRID_GetTableConfigAdvanced(): IGridExtraTableConfig<ILogroAsignacion> {
        return {
            EvaluatorAndSubLevelsBuild: {
                OnStepCellTable: (container, datum, field: keyof ILogroAsignacion) => {
                    switch (field) {
                        case "AlumnoNombreCompleto":
                            UIUtilGeneral._ElementAdd_LinkToGoToPanel(container, "alumnos/alumnos/panel", datum.IdChild);
                            break;
                        case "GruposNombres":
                            UIUtilViewGrupos._ApplyLblsGruposToContainer(
                                container,
                                datum.IdChild,
                                "All"
                            )
                            break;
                        case "Iniciado":
                        case "Avanzado":
                        case "Terminado":
                            container.text(datum[field] ? UIUtilTime._DateFormatStandarFixTimeZone(datum[field], datum.ZonaHoraria, "dd/mm/yyyy h12:mm") : "");
                            break;
                    }
                }
            }
        }
    }

    protected GRID_GetDataRequestID(): DataModuloMain.TipoRequestMonitorId {
        return Entidad.CTipoRequest.LogroAsignacion;
    }

    private OpenModal_EliminarDatos(datos: Array<ILogroAsignacion>) {
        this.GridOpenModal_ProccessArrayData({
            DataToProccess: datos,
            OnGetIdEscuela: (dato) => dato.KinderFiltro[0],
            OnError_GetItemDataTag: (dato) => `
                        ${this.VB_GetUIStringModule("d_field_alumnonombrecompleto")}: ${dato.AlumnoNombreCompleto} <br>
                        ${this.VB_GetUIStringModule("tag_logro")}: ${dato.LogroNombre}
                    `,
            OnStepAProccess: (dato) => this.Sv_EliminaRegistro(dato)
        })
    }

    protected GRID_GetExportarConfig(dataGrid: ILogroAsignacion[]): IConfigGridExcelExport<ILogroAsignacion> {
        let datosFinal = dataGrid.filter(d => (d.KinderFiltro?.length > 0));

        return {
            IdsEscuelas: [...new Set(datosFinal.map(d => d.KinderFiltro[0]))],
            OnGetDataBySheets: async () => {
                return Array.from(d3Group(datosFinal, d => d.KinderFiltro[0]))
                    .map<ExcelThings.ISheetConfig<ILogroAsignacion>>(entrie => ({
                        IdSheet: entrie[0], // IdEscuela
                        SheetName: DataModuloEscuela._DiccEscuela.get(entrie[0]).Nombre,
                        Data: entrie[1]
                    }))
            },
            OnGetEscuelasTagInSheet: (dataSheet) => DataModuloEscuela._DiccEscuela.get(dataSheet[0].KinderFiltro[0]).Nombre,
            OnStepFieldInDataRow: (field, datum) => {
                if ((field == "Iniciado" || field == "Avanzado" || field == "Terminado") && datum[field]) {
                    return UIUtilTime._DateFormatStandarFixTimeZone(datum[field], datum.ZonaHoraria, "dd/mm/yyyy h12:mm");
                }
                return datum[field] as string;
            }
        }
    }

    protected async GridGetData(): Promise<ILogroAsignacion[]> {
        const logroAsignaciones = await super.GridGetData();
        return logroAsignaciones
            .map(d => {
                const asignacion: ILogroAsignacion = Object.assign({}, d);
                const alumno = _DICC_ALUMNO.get(d.IdChild);

                if (alumno) {
                    asignacion.AlumnoNombre = alumno.Nombre;
                    asignacion.AlumnoApPaterno = alumno.ApPaterno;
                    asignacion.AlumnoApMaterno = alumno.ApMaterno;
                    asignacion.AlumnoNombreCompleto = alumno.NombreCompleto;
                    asignacion.ZonaHoraria = DataModuloMain._GetDataValueFieldByName("Escuela", alumno.IdKinder, "ZonaHoraria") || null;
                    asignacion.LogroNombre = DataModuloMain._GetDataValueFieldByName("Logro", d.IdLogro, "Nombre") || UIUtilLang._GetUIString("general", "nodisponible");
                    asignacion.GruposNombres = UIUtilViewGrupos._GetLblsGrupos(alumno, "All", false).join(", ");
                    asignacion.GruposNombresSimples = Array.from(_LOCALDATA_GetGruposHorariosDeAlumno(alumno.IdChild).values())
                        .map(d => d.Nombre);
                    asignacion.GradoNombre = DataModuloGrado._DiccGrado.get(alumno.IdGrado)?.Nombre || UIUtilLang._GetUIString("general", "nodisponible");
                    asignacion.NivelNombre = DataModuloMain._GetDataValueFieldByName("Escolaridad", alumno.IdEscolaridad, "Nombre") || UIUtilLang._GetUIString("general", "nodisponible");
                    asignacion.KinderFiltro = [alumno.IdKinder];
                }

                return asignacion;
            })
            .filter(d => Boolean(d.AlumnoNombre));
    }

    private Sv_EliminaRegistro(datos: ILogroAsignacion) {
        return DataModuloLogroAsignacion._EliminaAsignacion(datos.IdAsignacion);
    }
}
