import * as d3 from "d3";
import { Entidad } from "../../data/Entidad";
import { UIUtilGlobalKeyEvents } from "../util/GlobalKeyEvent";
import { UIUtilLang } from "../util/Language";
import { UIUtilPermission } from "../util/Permission";
import { UIUtilGeneral } from "../util/Util";
import { UIWindowManager } from "../ventana/WindowManager";
import { TutorialBuilder } from "./TutorialBuilder";

export namespace TutorialActivarAlumno {

    import _HasAccionPermission = UIUtilPermission._HasAccionPermission;
    import CModulo = Entidad.CModulo;
    import CAccion = Entidad.CAccionPermiso;
    import CTypeErrVal = Entidad.CAlumnoTutorHorarioMissActv;

    export async function _HasPermissionToSee() {
        return (
            _HasAccionPermission(CAccion.Agregar, CModulo.Tutores)
            && _HasAccionPermission(CAccion.Agregar, CModulo.Alumnos)
            && _HasAccionPermission(CAccion.Agregar, CModulo.PanelTutores)
            && _HasAccionPermission(CAccion.Editar, CModulo.PanelNinioHorario)
            && _HasAccionPermission(CAccion.Editar, CModulo.PanelInfoGeneral)
        )
    }

    export function _HasPermissionToActivate(typeErrVal: CTypeErrVal, idEscuela: number) {
        const permisoTutores = _HasAccionPermission(CAccion.Agregar, CModulo.PanelTutores, idEscuela);
        const permisoHorarios = _HasAccionPermission(CAccion.Editar, CModulo.PanelNinioHorario, idEscuela);
        const permisoActivar = _HasAccionPermission(CAccion.Editar, CModulo.PanelInfoGeneral, idEscuela);
        console.log(permisoActivar, permisoHorarios, permisoTutores)
        return permisoActivar && (
            (typeErrVal == CTypeErrVal.Ambos && permisoTutores && permisoHorarios) ||
            (typeErrVal == CTypeErrVal.Tutor && permisoTutores) ||
            (typeErrVal == CTypeErrVal.Horario && permisoHorarios)
        )
    }

    /** Promete true si el tutorial fue completado */
    export async function _StartTutorial(showWelcomeMessage: boolean = false): Promise<boolean> {
        if (showWelcomeMessage) {
            await OpenTutorialTitles(UIUtilLang._GetUIString("ayuda", "tag_tutorial_welcome"));
        }

        await OpenTutorialTitles(UIUtilLang._GetUIString("ayuda", "tag_tutorial_intro")); // FIX_LANGUAGE

        UIUtilGlobalKeyEvents._StopGlobalListener();
        const proccess = await new Promise<boolean>(async (resolve) => {
            const tutorRegistrado = await RegistrarTutor().then(() => true).catch(() => false);
            console.debug("1/5", tutorRegistrado);
            if (!tutorRegistrado)
                return resolve(false);

            const alumnoRegistrado = await RegistrarAlumno().then(() => true).catch(() => false);
            console.debug("2/5", alumnoRegistrado);
            if (!alumnoRegistrado)
                return resolve(false);

            const asignaAlumnoTutor = await RegistraAsignacionAlumnoTutor().then(() => true).catch(() => false);
            console.debug("3/5", asignaAlumnoTutor);
            if (!asignaAlumnoTutor)
                return resolve(false);

            const asignaAlumnoGrupo = await RegistraAsignacionAlumnoGrupo().then(() => true).catch(() => false);
            console.debug("4/5", asignaAlumnoGrupo);
            if (!asignaAlumnoGrupo)
                return resolve(false);

            const activarAlumno = await _ActivarAlumno().then(() => true).catch(() => false);
            console.debug("5/5", activarAlumno);

            resolve(activarAlumno);
        })
        UIUtilGlobalKeyEvents._ActiveGlobalListener();
        return proccess;
    }

    export async function _StartStudentActivationGuide(typeErrVal: CTypeErrVal, idChild: number) {
        UIWindowManager._DoHash("alumnos/alumnos/panel", {
            mode: "All",
            id: idChild
        })
        // await OpenTutorialTitles("Guía de activación del alumno", 1200);
        await UIUtilGeneral._Sleep(400);
        UIUtilGlobalKeyEvents._StopGlobalListener();
        const proccess = await new Promise<boolean>(async (resolve) => {
            let tutorRegistrado = true;
            let alumnoGrupoAsign = ![CTypeErrVal.Ambos, CTypeErrVal.Horario].includes(typeErrVal);
            let alumnoTutorAsign = ![CTypeErrVal.Ambos, CTypeErrVal.Tutor].includes(typeErrVal);
            if (!tutorRegistrado) return resolve(false);
            if (!alumnoGrupoAsign) {
                alumnoGrupoAsign = await AlumnoGrupoAsignacion().then(() => true).catch(() => false);
            }
            if (!alumnoGrupoAsign) return resolve(false);
            if (!alumnoTutorAsign) {
                alumnoTutorAsign = await AlumnoTutorAsignacion().then(() => true).catch(() => false);
            }
            if (!alumnoTutorAsign) return resolve(false);
            const activarAlumno = await _AlumnoActivation().then(() => true).catch(() => false);
            resolve(activarAlumno);
        })
        UIUtilGlobalKeyEvents._ActiveGlobalListener();
        return proccess;
    }

    function OpenTutorialTitles(message: string, duration: number = 3800): Promise<void> {
        return new Promise(resolve => {
            TutorialBuilder._ItemsFocus({ BackgroundBlur: true })
                .TextMaster(message)
                .Delay(duration)
                .Remove()
                .Call(() => resolve());
        })
    }

    function OpenGridForm(firstMessage: string) {
        return new Promise<void>((resolve, reject) => {

            let timer: NodeJS.Timeout;

            const stepForm = TutorialBuilder._ItemsFocus({
                OnRemove_Correctly: () => {
                    clearInterval(timer);
                    resolve();
                },
                OnRemove_Interrupting: () => {
                    clearInterval(timer);
                    reject();
                }
            });

            stepForm.AddItem({
                ID: 0,
                Selector: ".area_acciones .menuflex > .items_container > .item",
                SelectorsToFixFocus: [".menuinrow"],
                Text: firstMessage,
                Delay: 500,
                OnClickSelection: () => {

                    stepForm.RemoveAllItemsFocused();

                    stepForm.AddItem({
                        ID: 1,
                        Selector: ".modal_body",
                        SelectorsToFixFocus: [{
                            Selector: ".modal_container",
                            OnInitFocus: (element) => {
                                element.style.position = "absolute";

                                timer = setInterval(() => {
                                    if (element.offsetWidth) return;
                                    stepForm
                                        .RemoveAllItemsFocused()
                                        .Remove();
                                }, 200);
                            }
                        }],
                        OnInitFocus: () => {
                            d3.select(document.body)
                                .classed("selects_up", true);
                        },
                        OnRemoveFocus: () => {
                            d3.select(document.body)
                                .classed("selects_up", false);
                        },
                        Text: UIUtilLang._GetUIString("ayuda", "tag_form_fill"),
                        Delay: 500,
                        ZIndexAdd: 2
                    })

                    stepForm.AddItem({
                        ID: 2,
                        Selector: ".modal_container .buttons_ok",
                        Text: UIUtilLang._GetUIString("ayuda", "tag_form_clickaccept"),
                        Delay: 1500,
                    });
                }
            })
        })
    }

    function RegistrarTutor() {
        return new Promise<void>(async (resolve, reject) => {
            await OpenTutorialTitles(UIUtilLang._GetUIString("ayuda", "tag_tutor_startadding"), 3900);

            UIWindowManager._DoHash("alumnos/tutores"); // TEMPORAL Mantener si el tutorial se dispara fuera del Grid de tutores

            await UIUtilGeneral._Sleep(500);
            // >> Grid Tutores

            await OpenGridForm(UIUtilLang._GetUIString("ayuda", "tag_tutor_openform"))
                .then(() => {
                    const stepTutorChido = TutorialBuilder._ItemsFocus({
                        OnRemove_Correctly: () => {
                            // >> Grid Alumno
                            UIUtilGeneral._Sleep(500).then(() => resolve());
                        },
                        OnRemove_Interrupting: () => {
                            reject();
                        }
                    });

                    stepTutorChido.AddItem({
                        Selector: ".area_control",
                        Text: UIUtilLang._GetUIString("ayuda", "tag_tutor_tutoradded"),
                        TextPosition: "top",
                        ID: "tutor_chido",
                        OnInitFocus: (element) => element.style.pointerEvents = "none",
                        OnRemoveFocus: (element) => element.style.pointerEvents = null,
                    });

                    stepTutorChido
                        .SelectItem("tutor_chido")
                        .Delay(4000)
                        .SetText(UIUtilLang._GetUIString("ayuda", "tag_tutor_goto_alumno"));

                    // >> Grid Alumnos
                    UIUtilGeneral._Sleep(3000)
                        .then(() => {
                            stepTutorChido.RemoveAllItemsFocused().Remove();
                            UIWindowManager._DoHash("alumnos/alumnos");
                        });
                })
                .catch(e => console.error(e));
        })
    }

    async function RegistrarAlumno() {
        return new Promise<void>(async (resolve, reject) => {
            // >> Grid Alumnos

            await OpenGridForm(UIUtilLang._GetUIString("ayuda", "tag_alumno_openform"))
                .then(() => {
                    let timer: NodeJS.Timeout;

                    const stepAlumnoChido = TutorialBuilder._ItemsFocus({
                        OnRemove_Correctly: () => {
                            clearInterval(timer);
                            setTimeout(() => {
                                // >> Panel Alumno
                                resolve();
                            }, 500);
                        },
                        OnRemove_Interrupting: () => {
                            clearInterval(timer);
                            reject();
                        }
                    });

                    stepAlumnoChido.AddItem({
                        ID: "alumno_chido",
                        Selector: ".area_control",
                        Text: UIUtilLang._GetUIString("ayuda", "tag_alumno_alumnoadded"),
                        TextPosition: "top",
                        OnInitFocus: (element) => {
                            element.style.pointerEvents = "none";

                            setTimeout(() => {
                                element.style.pointerEvents = null;

                                d3.select(element)
                                    .classed("only_name", true);
                                // .selectAll(".principal")

                                timer = setInterval(() => {
                                    if (element.clientWidth) return;
                                    stepAlumnoChido
                                        .RemoveAllItemsFocused()
                                        .Remove();
                                }, 200)
                            }, 5000);
                        }
                    });

                    stepAlumnoChido
                        .SelectItem("alumno_chido")
                        .Delay(3000)
                        .SetText(UIUtilLang._GetUIString("ayuda", "tag_alumno_stepsbefore"))
                        .Delay(4400)
                        .SetText(UIUtilLang._GetUIString("ayuda", "tag_alumno_clickonname"));
                })
        })
    }

    function RegistraAsignacionAlumnoTutor() {
        return new Promise<void>((resolve, reject) => {
            // >>  Panel Alumno

            const baseStrSelector = ".card_tutores";
            let timer: NodeJS.Timeout;
            let timeout: NodeJS.Timeout;

            const stepAlumnoTutor = TutorialBuilder._ItemsFocus({
                OnRemove_Correctly: () => {
                    clearInterval(timer);
                    resolve();
                },
                OnRemove_Interrupting: () => {
                    clearInterval(timer);
                    clearTimeout(timeout);
                    reject();
                }
            });

            stepAlumnoTutor.AddItem({
                ID: "alumno_tutor_01",
                Selector: baseStrSelector,
                Text: UIUtilLang._GetUIString("ayuda", "tag_tutores_opensection"),
                OnClickSelection: async (e) => {
                    // >>  Panel Alumno / Sección tutores

                    stepAlumnoTutor.RemoveAllItemsFocused();

                    await UIUtilGeneral._Sleep(1000);

                    stepAlumnoTutor.AddItem({
                        ID: "alumno_tutor_02",
                        Selector: ".area_acciones .menuflex > .items_container > .item",
                        SelectorsToFixFocus: [".menuinrow"],
                        Text: UIUtilLang._GetUIString("ayuda", "tag_tutores_selecttutor"),
                        OnClickSelection: () => {
                            stepAlumnoTutor.RemoveAllItemsFocused();

                            stepAlumnoTutor.AddItem({
                                ID: "alumno_tutor_03",
                                Selector: ".list_container",
                                SelectorsToFixFocus: [{
                                    Selector: ".overlayselect",
                                    OnInitFocus: (element) => {
                                        element.style.position = null;
                                    }
                                }],
                                Text: "",
                                Delay: 500,
                                OnInitFocus: (element) => {
                                    timer = setInterval(() => {
                                        if (element.clientWidth) return;
                                        clearInterval(timer);

                                        stepAlumnoTutor.RemoveAllItemsFocused();

                                        stepAlumnoTutor.AddItem({
                                            ID: "alumno_tutor_04",
                                            Selector: ".expander_list .area_control",
                                            Text: UIUtilLang._GetUIString("ayuda", "tag_tutor_assignsuccess"), // FIXME
                                            TextPosition: "top",
                                            OnInitFocus: async (element) => {
                                                await UIUtilGeneral._Sleep(4000);

                                                document.querySelector<HTMLHeadElement>(baseStrSelector).click();

                                                stepAlumnoTutor
                                                    .RemoveAllItemsFocused()
                                                    .Remove();
                                            }
                                        })
                                    }, 200);
                                }
                            })
                        }
                    })
                }
            });
        })
    }

    function AlumnoTutorAsignacion() {
        return new Promise<void>((resolve, reject) => {
            const baseStrSelector = ".card_tutores";
            let timer: NodeJS.Timeout;
            let timeout: NodeJS.Timeout;

            const stepAlumnoTutor = TutorialBuilder._ItemsFocus({
                OnRemove_Correctly: () => {
                    clearInterval(timer);
                    resolve();
                },
                OnRemove_Interrupting: () => {
                    clearInterval(timer);
                    clearTimeout(timeout);
                    reject();
                }
            });

            stepAlumnoTutor.AddItem({
                ID: "alumno_tutor_expand_card",
                Selector: baseStrSelector,
                Text: UIUtilLang._GetUIString("ayuda", "tag_tutores_section"),
                OnClickSelection: async (e) => {
                    stepAlumnoTutor.RemoveAllItemsFocused();
                    await UIUtilGeneral._Sleep(500);
                    const btn_add_tutor = document.querySelector<HTMLElement>(".area_acciones .menuflex > .items_container > .item");
                    btn_add_tutor.click();
                    stepAlumnoTutor.RemoveAllItemsFocused();

                    stepAlumnoTutor.AddItem({
                        ID: "alumno_tutor_listselect",
                        Selector: ".list_container",
                        SelectorsToFixFocus: [{
                            Selector: ".overlayselect",
                            OnInitFocus: (element) => {
                                element.style.position = null;
                            }
                        }],
                        Text: UIUtilLang._GetUIString("ayuda", "tag_tutores_select"),
                        Delay: 500,
                        OnInitFocus: (element) => {
                            timer = setInterval(() => {
                                if (element.clientWidth) return;
                                clearInterval(timer);

                                stepAlumnoTutor.RemoveAllItemsFocused();

                                stepAlumnoTutor.AddItem({
                                    ID: "alumno_tutor_successassig",
                                    Selector: ".expander_list .area_control",
                                    Text: UIUtilLang._GetUIString("ayuda", "tag_tutor_assignsuccess"), // FIXME
                                    TextPosition: "top",
                                    OnInitFocus: async (element) => {
                                        await UIUtilGeneral._Sleep(1500);
                                        document.querySelector<HTMLHeadElement>(baseStrSelector).click();
                                        stepAlumnoTutor
                                            .RemoveAllItemsFocused()
                                            .Remove();
                                    }
                                })
                            }, 200);
                        }
                    })
                }
            })

            setTimeout(() => {
                stepAlumnoTutor.SelectItem("alumno_tutor_expand_card").ElementFocused.click();
            }, 800)
        })
    }

    function RegistraAsignacionAlumnoGrupo() {
        return new Promise<void>((resolve, reject) => {
            // >>  Panel Alumno

            const baseStrSelector = ".card_horarios";
            let timer: NodeJS.Timeout;
            let timeout: NodeJS.Timeout;

            const stepAlumnoGrupo = TutorialBuilder._ItemsFocus({
                OnRemove_Correctly: () => {
                    clearInterval(timer);
                    resolve();
                },
                OnRemove_Interrupting: () => {
                    clearInterval(timer);
                    clearTimeout(timeout);
                    reject();
                }
            });

            stepAlumnoGrupo.AddItem({
                ID: "alumno_grupo_01",
                Selector: baseStrSelector,
                Text: UIUtilLang._GetUIString("ayuda", "tag_schedule_letsassign"),
                OnClickSelection: async (e) => {
                    // >>  Panel Alumno / Sección horarios y grupos

                    stepAlumnoGrupo.SelectItem("alumno_grupo_01").Remove();

                    await UIUtilGeneral._Sleep(400);
                    const cardHeader = document.querySelector<HTMLElement>(baseStrSelector);
                    const actionsContainer = document.querySelector<HTMLElement>(".expander_list .actions_container");

                    stepAlumnoGrupo.AddItems({
                        ID: "alumno_grupo_02",
                        Selector: ".expander_list",
                        Text: UIUtilLang._GetUIString("ayuda", "tag_schedule_view"),
                        TextPosition: "top",
                        OnInitFocus: () => {
                            cardHeader.style.pointerEvents = "none";
                            actionsContainer.style.pointerEvents = "none";
                        },
                        OnRemoveFocus: () => {
                            cardHeader.style.pointerEvents = null;
                            actionsContainer.style.pointerEvents = null;
                        },
                    })

                    await UIUtilGeneral._Sleep(3800);

                    stepAlumnoGrupo.SelectItem("alumno_grupo_02").Remove();

                    stepAlumnoGrupo.AddItem({
                        ID: "alumno_grupo_03",
                        Selector: ".expander_list .btn_edit",
                        // SelectorsToFixFocus: [".expander_list .header"],
                        Text: UIUtilLang._GetUIString("ayuda", "tag_cards_editionmode"),
                        TextFixLeftPosition: -50,
                        OnClickSelection: () => {
                            stepAlumnoGrupo.SelectItem("alumno_grupo_03").Remove();

                            stepAlumnoGrupo.AddItems(
                                {
                                    ID: "alumno_grupo_04",
                                    Selector: ".expander_list .content .content",
                                    Text: UIUtilLang._GetUIString("ayuda", "tag_selectgroupschedule"),
                                    TextPosition: "top",
                                    OnInitFocus: () => {
                                        d3.select(document.body).classed("selects_up", true);
                                    },
                                    OnRemoveFocus: () => {
                                        d3.select(document.body).classed("selects_up", false);
                                    },
                                    ZIndexAdd: 2
                                },
                                {
                                    ID: "alumno_grupo_05",
                                    Selector: ".expander_list .footer .btn_save",
                                    SelectorsToFixFocus: [".expander_list .footer"],
                                    Text: UIUtilLang._GetUIString("ayuda", "tag_presstosave"),
                                    TextFixLeftPosition: -30,
                                    Delay: 1500,
                                    OnInitFocus: (element) => {
                                        timer = setInterval(() => {
                                            if (element.clientWidth) return;
                                            clearInterval(timer);

                                            stepAlumnoGrupo.RemoveAllItemsFocused();

                                            stepAlumnoGrupo.AddItem({
                                                ID: "alumno_grupo_06",
                                                Selector: ".expander_list",
                                                Text: UIUtilLang._GetUIString("ayuda", "tag_schedule_assigsuccess"),
                                                TextPosition: "top",
                                                Delay: 200,
                                                OnRemoveFocus: (element) => element.style.pointerEvents = null,
                                                OnInitFocus: async () => {
                                                    element.style.pointerEvents = "none"
                                                    await UIUtilGeneral._Sleep(3200);
                                                    document.querySelector<HTMLHeadElement>(baseStrSelector).click();
                                                    stepAlumnoGrupo.RemoveAllItemsFocused().Remove();
                                                }
                                            })
                                        }, 200)
                                    }
                                }
                            )
                        }
                    })
                }
            });
        })
    }

    function AlumnoGrupoAsignacion() {
        return new Promise<void>((resolve, reject) => {
            const baseStrSelector = ".card_horarios";
            let timer: NodeJS.Timeout;
            let timeout: NodeJS.Timeout;

            const stepAlumnoGrupo = TutorialBuilder._ItemsFocus({
                OnRemove_Correctly: () => {
                    clearInterval(timer);
                    resolve();
                },
                OnRemove_Interrupting: () => {
                    clearInterval(timer);
                    clearTimeout(timeout);
                    reject();
                }
            });

            stepAlumnoGrupo.AddItem({
                ID: "alumno_grupo_expandcard",
                Selector: baseStrSelector,
                Text: UIUtilLang._GetUIString("ayuda", "tag_schedule_assig"),
                OnClickSelection: async (e) => {
                    stepAlumnoGrupo.SelectItem("alumno_grupo_expandcard").Remove();

                    await UIUtilGeneral._Sleep(400);
                    const btn_edit = document.querySelector<HTMLElement>(".expander_list .btn_edit");
                    btn_edit.click();

                    stepAlumnoGrupo.AddItems(
                        {
                            ID: "alumno_grupo_horarioview",
                            Selector: ".expander_list .content .content",
                            Text: UIUtilLang._GetUIString("ayuda", "tag_selectgroupschedule"),
                            TextPosition: "top",
                            OnInitFocus: () => {
                                d3.select(document.body).classed("selects_up", true);
                            },
                            OnRemoveFocus: () => {
                                d3.select(document.body).classed("selects_up", false);
                            },
                            ZIndexAdd: 2
                        },
                        {
                            ID: "alumno_grupo_guardar_horario",
                            Selector: ".expander_list .footer .btn_save",
                            SelectorsToFixFocus: [".expander_list .footer"],
                            Text: UIUtilLang._GetUIString("ayuda", "tag_presstosave"),
                            TextFixLeftPosition: -30,
                            Delay: 1000,
                            OnInitFocus: (element) => {
                                timer = setInterval(() => {
                                    if (element.clientWidth) return;
                                    clearInterval(timer);

                                    stepAlumnoGrupo.RemoveAllItemsFocused();

                                    stepAlumnoGrupo.AddItem({
                                        ID: "alumno_grupo_successassig",
                                        Selector: ".expander_list",
                                        Text: UIUtilLang._GetUIString("ayuda", "tag_schedule_assigsuccess"),
                                        TextPosition: "top",
                                        Delay: 200,
                                        OnRemoveFocus: () => {
                                            element.style.pointerEvents = null
                                        },
                                        OnInitFocus: async () => {
                                            element.style.pointerEvents = "none"
                                            await UIUtilGeneral._Sleep(1500);
                                            document.querySelector<HTMLHeadElement>(baseStrSelector).click();
                                            stepAlumnoGrupo.RemoveAllItemsFocused().Remove();
                                        }
                                    })
                                }, 200)
                            }
                        }
                    )
                }
            })
            setTimeout(() => {
                stepAlumnoGrupo.SelectItem("alumno_grupo_expandcard").ElementFocused.click();
            }, 800)
        })
    }

    export function _ActivarAlumno() {
        return new Promise<void>((resolve, reject) => {
            // >>  Panel Alumno

            const baseStrSelector = ".card_infogeneral";
            let timer: NodeJS.Timeout;
            let timeout: NodeJS.Timeout;

            const stepAlumnoActivar = TutorialBuilder._ItemsFocus({
                OnRemove_Correctly: () => {
                    clearInterval(timer);
                    resolve();
                },
                OnRemove_Interrupting: () => {
                    clearInterval(timer);
                    clearTimeout(timeout);
                    reject();
                }
            });

            stepAlumnoActivar.AddItem({
                ID: "alumno_activar_01",
                Selector: baseStrSelector,
                Text: UIUtilLang._GetUIString("ayuda", "tag_infogral_section"),
                OnClickSelection: async (e) => {
                    // >>  Panel Alumno / Infoamción general

                    stepAlumnoActivar.RemoveAllItemsFocused();
                    await UIUtilGeneral._Sleep(200);

                    stepAlumnoActivar.AddItem({
                        ID: "alumno_activar_02",
                        Selector: ".expander_list .btn_edit",
                        // SelectorsToFixFocus: [baseStrSelector + " header"],
                        Text: UIUtilLang._GetUIString("ayuda", "tag_cards_editionmode"),
                        TextFixLeftPosition: -50,
                        Delay: 200,
                        OnClickSelection: () => {
                            stepAlumnoActivar.RemoveAllItemsFocused();

                            stepAlumnoActivar.AddItem({
                                ID: "alumno_activar_03",
                                Selector: ".expander_list form .row_idchildmovimiento .contenedor_combo",
                                Text: UIUtilLang._GetUIString("ayuda", "tag_infogral_movlist"),
                                TextPosition: "top",
                                OnClickSelection: () => {
                                    stepAlumnoActivar.RemoveAllItemsFocused();

                                    const itemsStatusAux: HTMLElement[] = [];
                                    stepAlumnoActivar.AddItem({
                                        ID: "alumno_activar_04",
                                        Selector: ".list_container",
                                        SelectorsToFixFocus: [{
                                            Selector: ".overlayselect",
                                            OnInitFocus: (element) => {
                                                element.style.position = null;
                                                element.style.pointerEvents = "none";
                                            }
                                        }],
                                        Text: UIUtilLang._GetUIString("ayuda", "tag_infogral_movlist"),
                                        TextPosition: "top",
                                        Delay: 200,
                                        OnInitFocus: () => document.querySelectorAll<HTMLLIElement>(".list_container .lista_de_elementos .elemento_lista")
                                            .forEach((li, i) => {
                                                if (li.classList.contains("opc_activo")) return;
                                                li.classList.add("hide");
                                                itemsStatusAux.push(li);
                                            }),
                                        OnClickSelection: () => {
                                            stepAlumnoActivar.RemoveAllItemsFocused();
                                            itemsStatusAux.forEach((li) => li.classList.remove("hide"));

                                            // Comprueba todos los campos del formulario
                                            let form = document.querySelector(".expander_list form");
                                            let row5_FNac = form.querySelector<HTMLDivElement>(".row_fechanacimiento");
                                            let row6_Sexo = form.querySelector<HTMLDivElement>(".row_sexo");
                                            let checkboxesSexo = row6_Sexo.querySelectorAll("input");

                                            if (!row5_FNac.querySelector("input").value || (!checkboxesSexo[0].checked && !checkboxesSexo[1].checked)) {
                                                stepAlumnoActivar.AddItems(
                                                    {
                                                        Selector: row5_FNac,
                                                        Text: ""
                                                    },
                                                    {
                                                        Selector: row6_Sexo,
                                                        Text: UIUtilLang._GetUIString("ayuda", "tag_infogral_emptyfields")
                                                    }
                                                )
                                            }

                                            // Continuar...

                                            stepAlumnoActivar.AddItem({
                                                ID: "alumno_activar_05",
                                                Selector: ".expander_list .footer .btn_save",
                                                SelectorsToFixFocus: [".expander_list .footer"],
                                                Text: UIUtilLang._GetUIString("ayuda", "tag_infogral_presstosave"),
                                                TextFixLeftPosition: -30,
                                                OnInitFocus: (element) => {
                                                    timer = setInterval(() => {
                                                        if (!element.clientWidth) {
                                                            clearInterval(timer);

                                                            stepAlumnoActivar
                                                                .RemoveAllItemsFocused();

                                                            stepAlumnoActivar.AddItem({
                                                                ID: "alumno_activar_06",
                                                                Selector: ".expander_list",
                                                                Text: UIUtilLang._GetUIString("ayuda", "tag_infogral_actvsuccess"),
                                                                TextPosition: "top",
                                                                Delay: 200,
                                                                OnRemoveFocus: (elementFocus) => elementFocus.style.pointerEvents = null,
                                                                OnInitFocus: async () => {
                                                                    element.style.pointerEvents = "none"
                                                                    await UIUtilGeneral._Sleep(2500);
                                                                    stepAlumnoActivar.RemoveAllItemsFocused();

                                                                    document.querySelector<HTMLHeadElement>(baseStrSelector).click();

                                                                    stepAlumnoActivar
                                                                        .BackgroundBlur(true)
                                                                        .TextMaster(UIUtilLang._GetUIString("ayuda", "tag_tutorial_end"));

                                                                    await UIUtilGeneral._Sleep(3800);
                                                                    stepAlumnoActivar.Remove();
                                                                }
                                                            })
                                                        } else if (document.querySelector(".modal_container")) {
                                                            document.querySelector<HTMLDivElement>(".modal_container")
                                                                .style.zIndex = "600";
                                                        }
                                                    }, 200)
                                                }
                                            })
                                        }
                                    })
                                }
                            })
                        }
                    })
                }
            })
        })
    }

    export function _AlumnoActivation() {
        return new Promise<void>((resolve, reject) => {
            const baseStrSelector = ".card_infogeneral";
            let timer: NodeJS.Timeout;
            let timeout: NodeJS.Timeout;
            const itemsStatusAux: HTMLElement[] = [];

            const stepAlumnoActivar = TutorialBuilder._ItemsFocus({
                OnRemove_Correctly: () => {
                    clearInterval(timer);
                    itemsStatusAux.forEach((li) => li.classList.remove("hide"));
                    resolve();
                },
                OnRemove_Interrupting: () => {
                    clearInterval(timer);
                    clearTimeout(timeout);
                    itemsStatusAux.forEach((li) => li.classList.remove("hide"));
                    reject();
                }
            })

            stepAlumnoActivar.AddItem({
                ID: "alumno_infogral_expandcard",
                Selector: baseStrSelector,
                Text: UIUtilLang._GetUIString("ayuda", "tag_infogral_section"),
                OnClickSelection: async (e) => {
                    stepAlumnoActivar.RemoveAllItemsFocused();
                    await UIUtilGeneral._Sleep(400);
                    const btn_edit = document.querySelector<HTMLElement>(".expander_list .btn_edit");
                    btn_edit.click();

                    const selectIdMovimientoChild = document.querySelector<HTMLElement>(".expander_list form .row_idchildmovimiento .contenedor_combo");
                    selectIdMovimientoChild.click();

                    await UIUtilGeneral._Sleep(400);

                    stepAlumnoActivar.AddItem({
                        ID: "alumno_infogral_movlist",
                        Selector: ".list_container",
                        SelectorsToFixFocus: [{
                            Selector: ".overlayselect",
                            OnInitFocus: (element) => {
                                element.style.position = null;
                                element.style.pointerEvents = "none";
                            }
                        }],
                        Text: UIUtilLang._GetUIString("ayuda", "tag_infogral_movlist"),
                        TextPosition: "top",
                        Delay: 200,
                        OnInitFocus: () => document.querySelectorAll<HTMLLIElement>(".list_container .lista_de_elementos .elemento_lista")
                            .forEach((li, i) => {
                                if (li.classList.contains("opc_activo")) return;
                                li.classList.add("hide");
                                itemsStatusAux.push(li);
                            }),
                        OnClickSelection: () => {
                            stepAlumnoActivar.RemoveAllItemsFocused();

                            stepAlumnoActivar.AddItem({
                                ID: "alumno_infogral_formedit",
                                Selector: ".expander_list .content .content",
                                Text: UIUtilLang._GetUIString("ayuda", "tag_infogral_formedit"),
                                TextPosition: "top",
                                OnInitFocus: () => {
                                    d3.select(document.body).classed("selects_up", true);
                                },
                                OnRemoveFocus: () => {
                                    d3.select(document.body).classed("selects_up", false);
                                },
                                ZIndexAdd: 2
                            })

                            stepAlumnoActivar.AddItem({
                                ID: "alumno_infogral_save",
                                Selector: ".expander_list .footer .btn_save",
                                SelectorsToFixFocus: [".expander_list .footer"],
                                Text: UIUtilLang._GetUIString("ayuda", "tag_infogral_presstosave"),
                                TextFixLeftPosition: -30,
                                OnInitFocus: (element) => {
                                    timer = setInterval(() => {
                                        if (!element.clientWidth) {
                                            clearInterval(timer);

                                            stepAlumnoActivar
                                                .RemoveAllItemsFocused();

                                            stepAlumnoActivar.AddItem({
                                                ID: "alumno_infogral_successactv",
                                                Selector: ".expander_list",
                                                Text: UIUtilLang._GetUIString("ayuda", "tag_infogral_actvsuccess"),
                                                TextPosition: "top",
                                                Delay: 200,
                                                OnRemoveFocus: (elementFocus) => {
                                                    console.log("OnRemoveFocus");
                                                    element.style.pointerEvents = null // FIXME: Revisar en la otra implementación
                                                },
                                                OnInitFocus: async () => {
                                                    console.log("PointerEventsNone");
                                                    element.style.pointerEvents = "none"
                                                    await UIUtilGeneral._Sleep(1000);
                                                    stepAlumnoActivar.RemoveAllItemsFocused();

                                                    document.querySelector<HTMLHeadElement>(baseStrSelector).click();

                                                    stepAlumnoActivar.Remove();
                                                }
                                            })
                                        } else if (document.querySelector(".modal_container")) {
                                            document.querySelector<HTMLDivElement>(".modal_container")
                                                .style.zIndex = "600";
                                        }
                                    }, 200)
                                }
                            })
                        }
                    })
                }
            })

            setTimeout(() => {
                stepAlumnoActivar.SelectItem("alumno_infogral_expandcard").ElementFocused.click();
            }, 800)
        })
    }
}
