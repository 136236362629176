import * as d3 from "d3";
import { Entidad } from "../../data/Entidad";
import { DateV2 } from "../../util/DateV2";
import { UIUtilIconResources } from "../util/IconResourses";
import { UIUtilLang } from "../util/Language";
import { UIUtilTime } from "../util/Time";
import { ScheduleBase } from "./ScheduleBase";

export namespace ScheduleBaseMinutes {
    export const _HOUR_MINUTES = UIUtilTime.CDuration.Hour / UIUtilTime.CDuration.Minute;
    export const _MIN_BLOCK_MINUTES = 15;
    // const dayMid = 12 * hourMinutes;
    const STEP_MINUTES = 5;
    // const maxItemxInCol = 1;

    type TToConfigBase<TBlockData> = Pick<ScheduleBase.IConfigToScheduleBase<TBlockData>,
        "UI_Column_ItemHeader_JOIN_OnUpdate" |
        "UI_Column_ItemHeader_Template_OnDraw" |
        "UI_Column_ItemBlock_OnValideEnter" |
        "UI_Column_ColumnArea_JOIN_OnUpdate" |

        "UI_ItemBlock_Template_OnDraw" |
        "UI_ItemBlock_Item_OnUpdate" |
        "UI_ItemBlock_ItemUI_OnUpdating" |
        "UI_ItemBlock_OnUpdateAllItemsEnd"
    >

    interface IConfig<TBlockData> extends TToConfigBase<TBlockData> {
        Parent: d3.Selection<HTMLElement, any, any, any>;
        UIDATA_OnCloseAItem?: (datum: ScheduleBase.IBlockItemReadOnly<TBlockData>, container: TSelectionHTML<"div">) => void;
    }

    export interface IItemBlockMinutes<TBlockData = undefined> {
        Id: ScheduleBase.TID;
        Inicio: Date;
        Fin: Date;
        IdDia: Entidad.CDiaSemanal;
        DataExtra?: TBlockData;
    }

    var controlDaysDefault: ScheduleBase.IColumnItem[] = [];
    for (let diaItem in Entidad.CDiaSemanal) {
        if (!isNaN(Number(diaItem)) && Number(diaItem) != Entidad.CDiaSemanal.Domingo0) {
            controlDaysDefault.push({
                Id: Number(diaItem),
                MinWidth: "40px",
                Text: UIUtilTime._GetDayName(Number(diaItem), "short")
            })
        }
    }

    interface IBlockDataExtra {
        EnableBtns: boolean;
    }

    /**
     * El control solo interactúa con los MINUTOS totales de una fecha
     */
    export abstract class ScheduleBMinutesBase<TBlockDataReal, TBlockDataBase = {}> extends ScheduleBase.ScheduleBase<TBlockDataBase & IBlockDataExtra> {
        constructor() {
            super();
        }

        protected SCHEDULE_MIN_InitConfig(config: IConfig<TBlockDataBase & IBlockDataExtra>) {
            super.SCHEDULE_InitConfig({
                Parent: config.Parent,
                RowsHeaderText: UIUtilLang._GetUIString("time", "horas"),
                ColumnsConfig: controlDaysDefault,
                RowStartValue: 0,
                RowsConfig: [],
                UI_Row_ItemHeader_JOIN_OnUpdate: (container, data) => {
                    if (data.Data) {
                        container.selectAll("label").remove();
                        container.style("justify-content", "space-between");
                        container.append("label").text(data.Data.Inicio);
                        container.append("label").text(data.Data.Fin);
                    }
                },

                UI_Column_ItemHeader_Template_OnDraw: config.UI_Column_ItemHeader_Template_OnDraw,
                UI_Column_ItemHeader_JOIN_OnUpdate: config.UI_Column_ItemHeader_JOIN_OnUpdate,
                UI_Column_ItemBlock_OnValideEnter: config.UI_Column_ItemBlock_OnValideEnter,
                UI_Column_ColumnArea_JOIN_OnUpdate: config.UI_Column_ColumnArea_JOIN_OnUpdate,

                UI_ItemBlock_Template_OnDraw: (container, datumBlock, parentContainer) => {
                    parentContainer.select(".r_options").append("wc-button")
                        .attr("dim", "10")
                        .property("_SrcIco", UIUtilIconResources.CGeneral.Close)
                        .classed("btn_close", true)
                        .classed("btn_round", true)
                        .style("width", "18px")
                        .style("height", "18px")
                        .style("padding", "3px")


                    if (config.UI_ItemBlock_Template_OnDraw) {
                        config.UI_ItemBlock_Template_OnDraw(container, datumBlock, parentContainer);
                    }
                },
                UI_ItemBlock_Item_OnUpdate: (container, datum, parentContainer) => {
                    parentContainer.select(".r_options")
                        .classed("hide", !datum.Data.EnableBtns)
                        .select(".btn_close")
                        .on("click", () => {
                            this.SCHEDULE_DeleteSheduleItem(datum.Id);
                            if (config.UIDATA_OnCloseAItem) {
                                config.UIDATA_OnCloseAItem(datum, container)
                            }
                        })

                    if (config.UI_ItemBlock_Item_OnUpdate) {
                        config.UI_ItemBlock_Item_OnUpdate(container, datum, parentContainer);
                    }
                },
                UI_ItemBlock_OnValide_OnUpdatingUI: (valuesChanging, type, datum) => {
                    // console.log(values, "to valide");
                    const valueRange = datum.EndRange - datum.InitRange;
                    return this.SCHEDULE_MIN_ItemBlock_OnValide_OnUpdatingUI(valuesChanging, type, valueRange, this._SCHEDULE_RowStarValue, this._SCHEDULE_RowRangeEndValue);
                },
                UI_ItemBlock_ItemUI_OnUpdating: config.UI_ItemBlock_ItemUI_OnUpdating,
                UI_ItemBlock_OnUpdateAllItemsEnd: config.UI_ItemBlock_OnUpdateAllItemsEnd
            })
        }

        // ********************************************************************
        // PROTECTED METHODS
        // ********************************************************************

        /** Valida el cambio de limites y dimenciones de un ItemBlock */
        protected SCHEDULE_MIN_ItemBlock_OnValide_OnUpdatingUI(realValuesChanging: ScheduleBase.IOnUpdateD, type: ScheduleBase.TUpdateItem, realRangeBlock: number, realTopLimit: number, realBottomLimit: number): boolean {
            let initDt: Date;
            let endDt: Date;

            switch (type) {
                case "resize_bottom":
                    endDt = new Date(2000, 0, 1);
                    endDt.setMinutes(Math.round(realValuesChanging.End));
                    endDt = d3.timeMinute.every(STEP_MINUTES).round(endDt);
                    realValuesChanging.End = endDt.getHours() * 60 + endDt.getMinutes();

                    if (realValuesChanging.End < realValuesChanging.Init + _MIN_BLOCK_MINUTES) {
                        return false;
                    } else if (realValuesChanging.End > realBottomLimit) {
                        return false;
                    }
                    break;

                case "resize_top":
                    initDt = new Date(2000, 0, 1);
                    initDt.setMinutes(Math.round(realValuesChanging.Init));
                    initDt = d3.timeMinute.every(STEP_MINUTES).round(initDt);

                    endDt = new Date(2000, 0, 1);
                    endDt.setMinutes(Math.round(realValuesChanging.End));
                    endDt = d3.timeMinute.every(STEP_MINUTES).round(endDt);

                    realValuesChanging.Init = initDt.getHours() * 60 + initDt.getMinutes();
                    realValuesChanging.End = endDt.getHours() * 60 + endDt.getMinutes();
                    // realValuesChanging.End = realValuesChanging.Init + realRangeBlock;
                    if (realValuesChanging.End < realValuesChanging.Init + _MIN_BLOCK_MINUTES) {
                        return false;
                    }
                    else if (realValuesChanging.Init < realTopLimit) {
                        return false;
                    }
                    else if (realValuesChanging.End > realBottomLimit) {
                        return false;
                    }

                    break;

                case "move":
                    initDt = new Date(2000, 0, 1);
                    initDt.setMinutes(Math.round(realValuesChanging.Init));
                    initDt = d3.timeMinute.every(STEP_MINUTES).round(initDt);
                    realValuesChanging.Init = initDt.getHours() * 60 + initDt.getMinutes();
                    realValuesChanging.End = realValuesChanging.Init + realRangeBlock;

                    if (realValuesChanging.Init < realTopLimit) {
                        return false;
                    }
                    else if (realValuesChanging.End > realBottomLimit) {
                        return false;
                    }
                    // return (Math.round(newInitRange) % 5 == 0);
                    break;
            }
            return true;
        }

        protected SCHEDULE_MIN_GetDayMinutes_FromDate(auxDate: Date) {
            return auxDate.getHours() * _HOUR_MINUTES + auxDate.getMinutes();
        }

        /**
         * Retorna hora en formato HH:MM
         * @param dayMinutes
         * @returns
         */
        protected SCHEDULE_MIN_GetHoursStr_FromDayMinutes(dayMinutes: number): string {
            const dt = new Date(2000, 0, 1);
            dt.setMinutes(dayMinutes);
            return UIUtilTime._DateFormatStandar(dt, "h24:mm");
        }

        protected SCHEDULE_MIN_GetHoursRangeStr(startRangeInMinutes: number, endRangeInMinutes: number) {
            return this.SCHEDULE_MIN_GetHoursStr_FromDayMinutes(startRangeInMinutes)
                + " - "
                + this.SCHEDULE_MIN_GetHoursStr_FromDayMinutes(endRangeInMinutes);
        }

        protected SCHEDULE_MIN_GetScheduleItemValid(id: ScheduleBase.TID, initMinutes: number, endMinutes: number, dia: ScheduleBase.TID, data: TBlockDataBase & IBlockDataExtra, enableResizeMove: boolean) {
            return {
                Id: id,
                Data: data,
                InitRange: initMinutes,
                EndRange: endMinutes,
                IdColumnPositon: dia,
                EnableMoveLeftRight: enableResizeMove,
                EnableMoveTopDown: enableResizeMove,
                EnableResizing: enableResizeMove
            }
        }

        // ********************************************************************
        // PUBLIC METHODS
        // ********************************************************************

        /** Cada bloque por default representa 60 minutos, a excepción del primero y ultimo (solo si no acompletan)
         * @param entrada @default "07:00:00"
         * @param salida @default "22:00:00"
        */
        public _UpdateHorarioLimitesGeneral(hrEntrada: string, hrSalida: string) {
            if (!hrEntrada || !hrSalida) {
                throw (new Error("Parameters not supported"));
            }
            const dtEntrada = new DateV2()
                ._SetLocalStrHour(hrEntrada);
            const dtSalida = new DateV2()
                ._SetLocalStrHour(hrSalida);
            let entradaMinutos = this.SCHEDULE_MIN_GetDayMinutes_FromDate(dtEntrada);
            let salidaMinutos = this.SCHEDULE_MIN_GetDayMinutes_FromDate(dtSalida);

            super.SCHEDULE_SetRowStarValue(entradaMinutos);

            // -> El primer bloque de horas, puede no ser una hora completa

            let firstBlockRange = ((dtEntrada.getMinutes() > 0) ? _HOUR_MINUTES - dtEntrada.getMinutes() : _HOUR_MINUTES);
            let lastBlockEnd = entradaMinutos + firstBlockRange;

            let rowsConfigFinal: ScheduleBase.IRowItem[] = [{
                RowRange: firstBlockRange,
                // Text: this.GetHours(entradaDt.Minutos) + " - " + this.GetHours(lastBlockEnd),
                Data: {
                    Inicio: this.SCHEDULE_MIN_GetHoursStr_FromDayMinutes(entradaMinutos),
                    Fin: this.SCHEDULE_MIN_GetHoursStr_FromDayMinutes(lastBlockEnd)
                }
            }]

            // -> Bloques intermedios de 60 min
            while (lastBlockEnd <= salidaMinutos) {
                if ((lastBlockEnd + _HOUR_MINUTES) <= salidaMinutos) {
                    lastBlockEnd += _HOUR_MINUTES
                    rowsConfigFinal.push({
                        RowRange: _HOUR_MINUTES,
                        Text: this.SCHEDULE_MIN_GetHoursStr_FromDayMinutes(lastBlockEnd)
                    })
                } else {
                    break;
                }
            }

            // -> Ultimo bloque incompleto
            if (lastBlockEnd < salidaMinutos) {
                rowsConfigFinal.push({
                    RowRange: salidaMinutos - lastBlockEnd,
                    Text: this.SCHEDULE_MIN_GetHoursStr_FromDayMinutes(salidaMinutos)
                })
            }

            super.SCHEDULE_UpdateRows(rowsConfigFinal);
        }

        // public abstract met_SetEnableItemsToEdit(enable: boolean): void;

        // public abstract met_SetScheduleItem(...items: IItemBlockMinutes<TBlockDataReal>[]);

        // public abstract met_GetCurrentItems(): (Pick<IItemBlockMinutes, "Id" | "IdDia">)[];

        public _ClearItems() {
            this.SCHEDULE_DeleteAllSheduleItem();
        }

        public get _GetLimitesHorario() {
            return {
                /** Minutos */
                MinInicio: this._SCHEDULE_RowStarValue, // this.SCHEDULE_MIN_GetDate_FromMinutes(this.prop_SCHEDULE_RowStarValue),
                /** Minutos */
                MinFin: this._SCHEDULE_RowRangeEndValue, // this.SCHEDULE_MIN_GetDate_FromMinutes(this.prop_SCHEDULE_RowRangeEndValue)
            }
        }
    }
}
