import * as d3 from "d3";
import { group as d3Group } from "d3-array";
import { MainPage } from "../../MainPage";
import { DataDRequest } from "../../data/DRequest";
import { Entidad } from "../../data/Entidad";
import { DataModuloMain } from "../../data/ModuloMain";
import { _LOCALDATA_GetGruposHorariosDeAlumno } from "../../data/modulo/Alumno";
import DataModuloEscuela from "../../data/modulo/Escuela";
import { _EventoObtenerEventosEscuela } from "../../data/modulo/Eventos";
import { _DictMaestros } from "../../data/modulo/Maestro";
import { DateV2 } from "../../util/DateV2";
import { IConfigGridExcelExport, IGridExtraTableConfig, IGridRenderInfo, VentanaGrid } from "../controlD3/AVentanaGrid";
import { ElementWrapper } from "../controlD3/ElementWrapper";
import { ExcelThings } from "../controlD3/ExcelExport";
import { SelectV2 } from "../controlD3/SelectV2";
import { Table } from "../controlD3/Tabla";
import { UIUtilLang } from "../util/Language";
import { UIUtilStrings } from "../util/Strings";
import { UIUtilTime } from "../util/Time";
import { UIUtilGeneral } from "../util/Util";
import { UIUtilViewData } from "../util/ViewData";
import { UIUtilViewGrupos } from "../utilView/Grupos";

import IAlumno = Entidad.IAlumno;
import CTipoEvento = Entidad.CReporteTipoEvento;

interface IEvento extends Entidad.IEventoEscuela {
    IdGrupo: number;
    StrGrupo?: string;
    StrEvento?: string;
    EvtInicioFmt?: string;
    EvtFinFmt?: string;
    // HrEntrada?: string;
    // HrSalida?: string;
    InformacionAux?: string;
    ZonaHoraria?: string;
    NombreMaestra?: string
}

interface IDataGrid extends IAlumno {
    Eventos: IEvento[];
    // EvtInicio: string;
    // EvtFin: string;
    // HrEntrada: string;
    // HrSalida: string;
    StrGrupo: string
    StrEvento: string;
    InformacionAux: string;
    StrGradoInfo: string;
    NombreMaestra?: string
}

type IItemCombo = UIUtilViewData.IBaseData;

export class UIVentanaReportesEventos extends VentanaGrid<IDataGrid> {
    private comboYear: SelectV2<IItemCombo, "Id", "monoselect">;
    private comboMonth: SelectV2<IItemCombo, "Id", "monoselect">;
    private comboEvent: SelectV2<IItemCombo, "Id", "multiselect">;

    /** Map<IdEscuela, Map<StrDate, IEvento[]>> */
    private dicctEventos: Map<number, Map<string, Map<CTipoEvento, IEvento[]>>>;

    constructor(container: TSelectionHTML<"div">, modulo: Entidad.CModulo) {
        super(container, modulo, {
            LabelsKeyBase: "reporteseventos",
            ModuloObservableToTblRefresh: [Entidad.CTipoRequest.HorarioAlumno],
        });
        this.dicctEventos = new Map();
        // setTimeout(() => {
        //     this.GridSetEscuelasSeleccionadas(this.kinders.map(d => d.IdKinder), false);
        // });
    }

    protected GRID_GetDataRequestID(): DataModuloMain.TipoRequestMonitorId {
        return null;
    }

    protected GRID_GetMenuTopGrid(): Table.ITableMenuTopDefaultOptionConfig[] {
        return null;
    }

    protected GRID_GetSelectionDataMenuV2(menuLocation: "row" | "top-selected", dataGridSelected: IDataGrid[]): Table.ITableMenuDataSelectedOptionConfig<IDataGrid>[] {
        return null;
    }

    protected GRID_GetFilters(): Table.IParametroFiltro<IDataGrid>[] {
        return [
            { LangModuleKeyInContext: "alumnos", LabelLangKey: "d_field_nombre", Field: "Nombre" },
            { LangModuleKeyInContext: "alumnos", LabelLangKey: "d_field_appaterno", Field: "ApPaterno" },
            { LangModuleKeyInContext: "alumnos", LabelLangKey: "d_field_apmaterno", Field: "ApMaterno" },
            // { Label: "Matrícula", Field: "Matricula" },
            // { Label: "Fecha de nacimiento", Field: "FechaNacimiento", IsDate: true },
            {
                LangModuleKeyInContext: "alumnos",
                LabelLangKey: "d_field_strsexo",
                Field: "Sexo",
                Type: "select",
                Options: UIUtilViewData._GetList_Sexo()
            },
            { LangModuleKeyInContext: "alumnos", LabelLangKey: "d_field_strescolaridad", Field: "StrEscolaridad" },
            { LangModuleKeyInContext: "alumnos", LabelLangKey: "d_field_strgrado", Field: "StrGrado" },
            {
                Label: "Grupo",
                Field: "StrGrupo",
                Type: "text",
                OnGetValueToMatch: (datoAlumno: IDataGrid) => Array.from(_LOCALDATA_GetGruposHorariosDeAlumno(datoAlumno.IdChild).values())
                    .filter(d => d.EsPrincipal)
                    .map(d => d.Nombre),
            },
            { Label: "FechaInicio", Field: "EvtInicio" as any, Type: "date", Level: 2, MaxDate: new Date().toISOString() },
            { Label: "FechaFin", Field: "EvtFin" as any, Type: "date", Level: 2, MaxDate: new Date().toISOString() },
            {
                LabelLangKey: "d_field_strevento",
                Field: "TipoEVento" as any,
                Level: 2,
                Type: "select",
                Options: this.GetEventos()
            },
            { Label: "Información", Field: "InformacionAux", Level: 2 },
        ];
    }

    protected GRID_GetTableConfigBase(): IGridRenderInfo<IDataGrid> {
        return {
            IdTabla: "ReportesEventos",
            Title: "",
            DefaultSort: "NombreCompleto",
            IdData: "IdChild",
            MaxOptionsInRow: 2,
            MinWidth: 1250,
            Columns: [
                { Field: "NombreCompleto", Label: "Nombre", Width: "11%", MinWidth: "140px" },
                // { Field: "Matricula", Label: "Matrícula", Width: "12%", MinWidth: "80px" },
                // { Field: "FechaNacimiento", Label: "Nacimiento", Width: "10%", MinWidth: "75px" },
                // { Field: "StrSexo", Label: "Sexo", Width: "18%", MinWidth: "30px" },
                // { Field: "StrEstado", Label: "Estado", Width: "12%", MinWidth: "40px" },
                // { Field: "NombreKinder", Label: "Escuela", Width: "12%", MinWidth: "100px" },
                // { Field: "StrEscolaridad", Label: "Escolaridad", Width: "10%", MinWidth: "100px" },
                { Field: "StrGradoInfo", Label: "Grado", Width: "12.5%", MinWidth: "80px" },
                { Field: "StrGrupo", Label: "Grupo", Width: "11%", MinWidth: "80px" },
                { Field: "EvtInicioFmt" as any, Label: "Inicio", Width: "8%", MinWidth: "80px", OrderLevelRows: 2, OrderField: "EvtInicio" as any, OrderTypeParse: Date },
                { Field: "EvtFinFmt" as any, Label: "Fin", Width: "8%", MinWidth: "80px", OrderLevelRows: 2, OrderField: "EvtFin" as any, OrderTypeParse: Date },
                { Field: "StrEvento", Label: "Evento", Width: "9%", MinWidth: "80px", OrderLevelRows: 2, OrderTypeParse: String },
                // { Field: "HrEntrada", Label: "Hr. entrada", Width: "10%", MinWidth: "80px", LevelsTypeDataToOrder: [null, Date] },
                // { Field: "HrSalida", Label: "Hr. salida", Width: "10%", MinWidth: "80px", LevelsTypeDataToOrder: [null, Date] },
                { Field: "InformacionAux", Label: "Información", Width: "14%", MinWidth: "80px", OrderLevelRows: 2, OrderTypeParse: String },
                { Field: "NombreMaestra", Label: "maestr@", Width: "10%", MinWidth: "65px" },
            ]
        };
    }

    protected GRID_GetTableConfigAdvanced(): IGridExtraTableConfig<IDataGrid> {
        return {
            EvaluatorAndSubLevelsBuild: <Table.IStepEvaluator<IDataGrid, IEvento, any>>{
                OnStepCellTable: (container, datum, field: keyof IDataGrid) => {
                    switch (field) {
                        case "NombreCompleto":
                            UIUtilGeneral._ElementAdd_LinkToGoToPanel(container, "alumnos/alumnos/panel", datum.IdChild);
                            break;
                        case "StrGradoInfo":
                            // console.warn("Table step event item grado info")
                            let gradoResolved: UIUtilViewData.IBaseData[] = [
                                { Id: datum.IdKinder, Name: datum.NombreKinder },
                                { Id: null, Name: datum.StrEscolaridad },
                                { Id: null, Name: datum.StrGrado },
                            ];
                            let fnUpdateL = (label: TSelectionHTML<"label">, d: UIUtilViewData.IBaseData) => {
                                label.text(d.Name);
                                if (d.Id) {
                                    UIUtilGeneral._ElementAdd_LinkToGoToPanel(label, "escuelas/escuelas/panel", d.Id);
                                }
                                return label;
                            }
                            container
                                .text("")
                                .classed(UIUtilGeneral.FBoxOrientation.Vertical, true)
                                .selectAll("label")
                                .data(gradoResolved)
                                .join(
                                    enter => enter.append((d) => fnUpdateL(d3.create("label"), d).node()),
                                    update => update.each((d, i, arr: HTMLLabelElement[]) => fnUpdateL(d3.select(arr[i]), d)),
                                    exit => exit.remove()
                                )
                            break;
                        case "StrGrupo":
                            UIUtilViewGrupos._ApplyLblsGruposToContainer(container, datum.IdChild);
                            break;
                    }
                },
                // ActiveDataOnlyWhenDisplayData: true,

                EvaluatorSubLevel: {
                    IdMember: "Id",
                    RemoveLevelCheckboxes: true,
                    OnGetData: (dato) => dato.Eventos,
                    OnStepCellTable: (container, datum, field) => {
                        switch (field) {
                            case "InformacionAux":
                                container.html(UIUtilStrings._AutoLink(datum.InformacionAux).innerHTML);
                                break;
                        }
                    },
                }
            }
        };
    }

    protected GRID_GetExportarConfig(dataGrid: IDataGrid[]): IConfigGridExcelExport<IDataGrid> {
        const idsEscuelas = [...new Set(dataGrid.map(d => d.IdKinder))];

        return {
            IdsEscuelas: idsEscuelas,
            ColumnsConfig: this.ctrlTabla
                ._InfoColumns
                .map<ExcelThings.IColumnToExcelExportFileConfig<IDataGrid>>(d => ({
                    Field: d.Field as keyof IDataGrid,
                    HeaderTag: d.Label,
                    WidthCell: (d.Field as keyof IDataGrid) == "NombreCompleto" ? 35 : 25
                })),
            OnGetDataBySheets: async () => {
                let groupedData: Map<number, IDataGrid[]> = new Map(idsEscuelas.map(idEscuela => ([idEscuela, []])));

                dataGrid.forEach(alumno => {
                    let datosEnEscuela = groupedData.get(alumno.IdKinder);

                    datosEnEscuela.push(alumno);

                    let itemInTable = this.ctrlTabla._SelectItemData<IEvento>(alumno.IdChild);
                    let eventosChilds: IEvento[] = Array.from(itemInTable.GetChildrenData().values())
                        .map(d => d.Data);

                    eventosChilds.forEach(evento => {
                        datosEnEscuela.push(evento as unknown as IDataGrid);
                    })
                })

                return Array.from(groupedData)
                    .map(entrie => ({
                        IdSheet: entrie[0],
                        SheetName: entrie[1][0].NombreKinder,
                        Data: entrie[1]
                    }));
            },
            OnGetEscuelasTagInSheet: (datos) => datos[0].NombreKinder
        };
    }

    private UI_AjustaDrawGrid() {
        // Area combos-filtros
        let filterCont = this.ctrlTabla._Control.select(".filter_container");
        let searchwrapper = filterCont.select(".search-wrapper");

        let areaCombos = filterCont.append("div")
            .attr("class", "search-wrapper " + UIUtilGeneral.FBoxOrientation.Horizontal)
            // .style("height", "30px")
            .style("column-gap", "40px")
            .style("padding", "0 20px")
            .style("box-sizing", "border-box")

        searchwrapper.raise();

        this.comboYear = new SelectV2<IItemCombo, "Id", "monoselect">({
            Type: "monoselect",
            Parent: areaCombos,
            ValueMember: "Id",
            DisplayMember: "Name",
            Data: [],
            OnSelect: (item) => {
                this.UI_OnChangeYearSelected(item?.Id);
            }
        })

        this.comboMonth = new SelectV2<IItemCombo, "Id", "monoselect">({
            Type: "monoselect",
            Parent: areaCombos,
            ValueMember: "Id",
            DisplayMember: "Name",
            OnChange: (idMonth, item) => {
                this.GridUpdateData();
            }
        })

        this.comboEvent = new SelectV2<IItemCombo, "Id", "multiselect">({
            Type: "multiselect",
            Parent: areaCombos,
            ValueMember: "Id",
            DisplayMember: "Name",
            Data: this.GetEventos(),
            OnChange: () => {
                this.GridUpdateData();
            }
        })

        this.comboEvent._valueSelect(this.GetEventos().map(d => d.Id));

        areaCombos.append(() => {
            return ElementWrapper._WrapperToSelectControl(this.comboYear, UIUtilLang._GetUIString("time", "anio") + ":",
                {
                    Borders: ["left", "right"]
                })
                .style("height", "35px")
                .node();
        })

        areaCombos.append(() => {
            return ElementWrapper._WrapperToSelectControl(this.comboMonth, UIUtilLang._GetUIString("time", "mes") + ":", {
                Borders: ["left", "right"]
            }).node();
        })

        areaCombos.append(() => {
            return ElementWrapper._WrapperToSelectControl(this.comboEvent, this.VB_GetUIStringModule("tag_evnts") + ":", {
                Borders: ["left", "right"]
            }).node();
        })
    }

    private UI_OnChangeYearSelected(year: number = this.comboYear._dataSelected[0]?.Id) {
        if (year) {
            let mesesDisponibles = UIUtilViewData._GetMonthsByYearCurrentMonthLimit(year);
            this.comboMonth._UpdateList(mesesDisponibles);
            let selectedM = this.comboMonth._dataSelected[0];
            if (!selectedM) {
                this.comboMonth._valueSelect(mesesDisponibles.reduce((a, b) => (a.Id > b.Id ? a : b)).Id);
            }
        } else {
            this.comboMonth._UpdateList([]);
        }
        this.GridUpdateData();
    }

    // **********************************************
    // Overwrite methods
    // **********************************************

    protected GridInitTable() {
        super.GridInitTable();
        this.UI_AjustaDrawGrid();
    }

    protected GridRefreshKinderList() {
        super.GridRefreshKinderList();

        const initAniosDisponibles = this.GetAniosDisponibles();

        if (!this.comboYear._dataSelected?.length && initAniosDisponibles.length) {
            let initAnioSeleccionado = initAniosDisponibles.reduce((a, b) => (a.Id > b.Id ? a : b)).Id;

            this.comboYear._UpdateList(initAniosDisponibles);
            this.comboYear._valueSelect(initAnioSeleccionado);
            this.UI_OnChangeYearSelected();
        }
    }

    protected async GridOnKinderSelectionChange() {
        // super.GridOnKinderSelectionChange(items);
        await this.GridUpdateData(true, true);
    }

    protected async GridGetData(): Promise<IDataGrid[]> {
        let escuelasSeleccionadas = this.GridGetEscuelasSeleccionadas();
        let yearFilter = this.comboYear._dataSelected[0]?.Id;
        let monthFilter = this.comboMonth._dataSelected[0]?.Id;
        let eventsFilter = this.comboEvent._dataSelected.map(d => d.Id);

        // >> Consulta data
        escuelasSeleccionadas = this.GridGetEscuelasSeleccionadas();

        if (escuelasSeleccionadas.length && yearFilter != null && monthFilter != null && eventsFilter.length) {
            const eventosEscuelas = new Map<number, Map<number, IEvento[]>>();
            const dateToConsult = new Date(yearFilter, monthFilter, 1);


            // >> Consulta eventos de las escuelas seleccionadas y agrupa por Escuela y Alumno
            for (let escuela of escuelasSeleccionadas) {
                this.ctrlProgressBar.attr("oculto", false);
                let finalDate = new DateV2(dateToConsult)._SetTimeZone(escuela.ZonaHoraria, true);
                let res = await this.Sv_ObtenerEventosPorEscuela(escuela.IdKinder, finalDate, eventsFilter);

                if (res.Resultado > 0) {
                    let eventosAlumnos = d3Group(res.Datos, d => d.IdAlumno);

                    eventosEscuelas.set(escuela.IdKinder, eventosAlumnos);
                } else {
                    this.notificacion._Mostrar(UIUtilLang._GetUIString("general", "notif_fail_infoupdate"), "ADVERTENCIA");
                }
            }
            this.ctrlProgressBar.attr("oculto", false);

            /** Map<IdUsuario, IMaestro> */
            const maestrosMap = new Map<number, Entidad.IMaestro>(Array.from(_DictMaestros.values()).map((m) => ([m.IdUsuario, m])))

            // Agrupa los eventos dentro del objeto de alumno final
            return (DataModuloMain._GetReqDataArrayById(Entidad.CTipoRequest.Alumno) as IDataGrid[]) // (await super.GridGetData())
                .map(d => {
                    let alumno = UIUtilGeneral._ObjectClone(d);
                    let escuela = DataModuloMain._GetItemDataByName("Escuela", d.IdKinder);

                    alumno.Eventos = [];
                    alumno.StrGradoInfo = alumno.NombreKinder + "\n" + alumno.StrEscolaridad + "\n" + alumno.StrGrado;
                    alumno.StrGrupo = alumno.StrGrupoPrincipal;

                    eventosEscuelas.forEach((eventosEscuela, idEscuela) => {
                        if (idEscuela == alumno.IdKinder) {
                            // console.warn("Los eventos pertenecen al alumno, pero están asignados a otra escuela", alumno.IdChild, alumno.Nombre);
                            // data.Utils.Alerts.fn_SendInfoDelay300ms("Reporte-Evto Alumno.Escuela != Evento.Escuela", `ÀlumnoEscuela:${alumno.IdKinder},EvtoEscula:${idEscuela}`);
                            // } else {
                            let eventosAlumno = (eventosEscuela.get(alumno.IdChild) || [])
                                .map(d => {
                                    // d.HrEntrada = d.EvtInicio;
                                    // d.HrSalida = d.EvtFin;
                                    d.ZonaHoraria = escuela.ZonaHoraria;
                                    d.EvtInicioFmt = UIUtilTime._DateFormatStandar(d.EvtInicio, "dd/mm/yyyy h12:mm");
                                    d.EvtFinFmt = UIUtilTime._DateFormatStandar(d.EvtFin, "dd/mm/yyyy h12:mm");
                                    d.StrEvento = UIUtilViewData._GetStr_TipoEvento(d.TipoEVento) || "";
                                    d.InformacionAux = this.GetAsignaStrInformacionFaltante(d);
                                    d.StrGrupo = DataModuloMain._GetDataValueFieldByName("Grupo", d.IdGrupo, "Nombre") || "";
                                    d.NombreMaestra = maestrosMap.get(d.IdUsuarioModifico)?.NombreCompleto || ""
                                    return d;
                                })
                            alumno.Eventos.push(...eventosAlumno);
                        }
                    })

                    alumno.Eventos.sort((a, b) => (Number(new Date(a.EvtInicio)) - Number(new Date(b.EvtInicio))));

                    return alumno;
                })
                .filter(d => Boolean(d.Eventos.length))
        }
        return [];
    }

    // **********************************************
    // Data things
    // **********************************************

    private GetAsignaStrInformacionFaltante(dato: IEvento): string {
        let strInformacionAux = "";
        switch (dato.TipoEVento) {
            case CTipoEvento.Siesta:
                strInformacionAux = UIUtilTime._GetTimeElapsedBFmt(dato.EvtInicio, dato.EvtFin, "Year", "Minute");
                break;
            case CTipoEvento.Humor:
                if (dato.Informacion) {
                    console.warn("-d", "INFORMACIÓN", dato.Informacion);
                }

                strInformacionAux = this.VB_GetUIStringModule("tag_was")
                    .replace("_EMOCION", UIUtilViewData._GetStr_Emocion(dato.InfoEvt).toLowerCase());

                if (dato.Informacion) {
                    strInformacionAux += ("\n\n" + dato.Informacion);
                }
                break;
            case CTipoEvento.Actividades:
                strInformacionAux = dato.Informacion.replace("\n", " ") + (dato.Comentario ? "\n\n" + dato.Comentario : "");
                break;
            case CTipoEvento.Higiene:
                if ((dato.InfoEvt == Entidad.CBanio.Pipi) || (dato.InfoEvt == Entidad.CBanio.Popo)) {
                    strInformacionAux = UIUtilViewData._GetStr_Banio(dato.InfoEvt);
                }
                if (dato.Informacion) {
                    strInformacionAux += ((strInformacionAux ? ": " : "") + dato.Informacion);
                }
                break;
            case CTipoEvento.Alimentos:
                strInformacionAux = this.VB_GetUIStringModule("tag_porcion") + ": " + dato.Porcion;

                if (dato.Informacion) {
                    strInformacionAux += ("\n" + dato.Informacion);
                }
                if (dato.Comentario) {
                    strInformacionAux += ("\n" + dato.Comentario);
                }
                break;
            default:
                strInformacionAux = dato.Informacion;
                break;
        }
        return strInformacionAux;
    }

    // private GetStrHoraMientrasAsistencia(dato: IEvento, tiempo: "HrEntrada" | "HrSalida"): string {
    //     if (dato.TipoEVento == CTipoEvento.Asistencia) {
    //         if (dato[tiempo]) {
    //             return d3.timeFormat("%I:%M %p")(new Date(dato[tiempo]));
    //         } else {
    //             console.warn("Alumno", dato.IdAlumno, ", Evento:", dato.Id, tiempo + " no disponible");
    //             return "";
    //         }
    //     }
    //     else {
    //         return "";
    //     }
    // }

    private GetEventos(): IItemCombo[] {
        return UIUtilViewData._GetList_TiposEventos("normal", DataModuloMain._GetReqDataArrayByName("Escuela")[0].IdKinder)
            .filter(d => (
                d.Id == CTipoEvento.Comentarios
                || d.Id == CTipoEvento.Humor
                || d.Id == CTipoEvento.Alimentos
                || d.Id == CTipoEvento.Higiene
                || d.Id == CTipoEvento.Actividades
                || d.Id == CTipoEvento.Siesta
            ))
    }

    private GetAniosDisponibles() {
        let years: IItemCombo[] = [];
        let allSchools = Array.from(DataModuloEscuela._DiccEscuela.values());

        if (allSchools.length) {
            let schoolMinRecordDate = allSchools.reduce((a, b) => (new Date(a.FechaRegistro) < new Date(b.FechaRegistro) ? a : b));
            let initY = new Date(schoolMinRecordDate.FechaRegistro).getFullYear();

            for (let i = initY; i <= new Date().getFullYear(); i++) {
                years.push({
                    Id: i,
                    Name: i.toString()
                })
            }
        }

        return years.sort((a, b) => (b.Id - a.Id));
    }

    private async Sv_ObtenerEventosPorEscuela(idEscuela: number, date: DateV2, tiposEventos: CTipoEvento[]): Promise<DataDRequest.IRequestResponseA<IEvento[]>> {
        let eventosPorEscuela = this.dicctEventos.get(idEscuela);
        let tiposEventosFecha = eventosPorEscuela?.get(date._ToISOString());
        let eventosRes: IEvento[] = []; //  tiposEventosFecha?.get(date.toISOString());
        let resultado = 1;

        if (!eventosPorEscuela) {
            eventosPorEscuela = new Map();
            this.dicctEventos.set(idEscuela, eventosPorEscuela);
        }

        if (!tiposEventosFecha) {
            tiposEventosFecha = new Map();
            eventosPorEscuela.set(date._ToISOString(), tiposEventosFecha);
        }

        const escuelaHasMaestros = Array.from(_DictMaestros.values()).some(m => m.IdEscuela == idEscuela)
        if (!escuelaHasMaestros) {
            await MainPage._ReloadServiceAndAwait(Entidad.CTipoRequest.Maestro, idEscuela)
        }

        for (let tEvento of tiposEventos) {
            let eventosTipo = tiposEventosFecha.get(tEvento);

            if (eventosTipo) {
                eventosRes.push(...eventosTipo);
            } else {
                let res = await _EventoObtenerEventosEscuela(idEscuela, tEvento, date._ToISOString());

                if (res.Resultado <= 0) {
                    resultado = -1;
                }
                if (res.Resultado > 0 && UIUtilTime._GetValidatorDateYM(date) < UIUtilTime._GetValidatorDateYM(new Date())) {
                    tiposEventosFecha.set(tEvento, res.Datos || []);
                }

                eventosRes.push(...(res.Datos || []));
            }
        }

        return {
            Resultado: resultado,
            Datos: eventosRes,
        }
    }
}
