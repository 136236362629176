import { DataDRequest } from "../DRequest";
import { Global } from "../Global";
import {
    ICatalogoBanco
} from "../entidad/Factura";

export function _ProcesaFacturaCatalogo<T>(response: Promise<DataDRequest.IRequestResponseA<T[]>>): Promise<T[]> {
    return response.then(res => new Promise((resolve, reject) => {
        if (res.Resultado > 0) {
            resolve(res.Datos)
            return
        }
        reject()
    }))
}

export function _ObtenerFCatalogoBanco(idEscuela: number) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "factura/ObtenerCatalogoBancos"
    const params = {
        "IdEscuela": idEscuela,
    }
    return DataDRequest._Request5<ICatalogoBanco[]>(url, "GET", params)
}