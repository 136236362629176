import { Global } from "./Global";
import { DataModuloMain } from "./ModuloMain";
import { CAlumnoEdoLicencia } from "./entidad/LicenciaOld";

export namespace Entidad {

    // *********************************************************************
    // APP
    // *********************************************************************

    export type IAdminVersionInfo = {
        versionCode: number;
        version: string;
        versionName: string;
        logout: boolean;
        forceMatchUIVersion: boolean;
        releaseDate: string;

        // nextVersionCode: number;
        // nextVersion: string;
        // srv: string;
        // updateDB: 0,
        // versionAndroid: "1.0.8",
        // versionIOS: "1.0.8",
    } & { [k in typeof Global._APP_VERSION_BASE]: string }

    // Worker to DOM Comunication

    type TWorkerMessageType = "load" | "request-flag" | "request-data" | "permission-reload";
    type IWorkerMessagesMap = {
        "load": {
            User: Entidad.IUsuarioSesion;
            UserPermissions: Entidad.TUserPermisosMap;
            AccionesDisponibles: Map<number, IPermisoAccion>;
            ModulosDisponibles: Map<number, IPermisoModulo>;
            PermisosDisponibles: Map<number, IPermisoDisponible>;
        },
        "request-flag": {
            FirstLocalData: boolean;
            Flag: "start" | "finish"
        },
        "request-data": {
            Id: Entidad.CTipoRequest;
            FirstLocalData: boolean;
            Data: Array<any>;
            Forced: boolean;
            Error: Error;
            ReloadId: number;
            ForceLoad: boolean;
        },
        "permission-reload": {
            UserValid: boolean;
            UserPermissions: Entidad.TUserPermisosMap;
        }
    }

    export type IMessageWorkerToDOM = {
        [k in TWorkerMessageType]: {
            Type: k;
            Message: IWorkerMessagesMap[k];
        }
    }[TWorkerMessageType]

    // DOM to Worker Comunication

    type TDOMMessageType = "load" | "start" | "request-run" | "request-active" | "monitor-run" | "permission-reload";

    type IDOMMessagesMap = {
        "load": {
            User: IUsuarioSesion;
        };
        "start": undefined;
        "request-run": {
            id: DataModuloMain.TipoRequestMonitorId;
            reloadId: number
            // diccPermisos: Map<number, IItemReloadService>
            IdsEscuelas: number[];
        };
        "request-active": {
            id: DataModuloMain.TipoRequestMonitorId;
            active: boolean;
        },
        "monitor-run": undefined;
        "permission-reload": {
            idSchool: number
        };
        // "permissions": Entidad.TUserPermisosMap;
    }

    export type IMessageDOMToWorker = {
        [k in TDOMMessageType]: {
            Type: k;
            Message: IDOMMessagesMap[k];
        }
    }[TDOMMessageType]

    export interface IUsuarioSesion {
        readonly ApMaterno: string;
        readonly ApPaterno: string;
        /** @deprecated SIN USO */
        readonly IdKinder: number;
        readonly IdOrganizacion: number;
        readonly IdSesion: number;
        readonly IdUsuario: number;
        readonly Nombre: string;
        /** @deprecated SIN USO */
        readonly PaqueteBasico: boolean;
        readonly Perfil: CTipoPerfil;
        readonly Correo: string;

        // EXTRAS
        NSesiones: number;
        /** // TEMPORAL */
        IMEI: string;
        /** // TEMPORAL */
        DeviceType: 1 | 2 | 3;
    }

    export interface IUsuario {
        IdUsuario: number;
        Correo: string;
        Nombre: string;
        ApPaterno: string;
        ApMaterno: string;
        Perfil: CTipoPerfil;
        /** Escuelas a las que tiene permisos el usuario */
        Escuelas: Array<number> | null;
        // IdKinder: number;

        // AUXILIARES
        /** Extra */
        StrPerfil?: string
        /** Extra */
        NombreCompleto?: string;
        /** Extra */
        KinderFiltro?: number[];
        get getEscuelaNombres(): string;
    }

    /** Map<IdEscuela, Map<IdModulo, Map<IdAccion, @interface Entidades.IPermisosAccion>>> */
    export type TUserPermisosMap = Map<number, Map<number, Map<number, Entidad.IPermisoAccion>>>;

    export interface ITabla {
        ID: number;
        storeName: string;
        key: string;
        columns: Array<string>;
    }

    // *********************************************************************
    // ENTITIES
    // *********************************************************************

    export interface IEscuela {
        ConLogo: boolean;
        Correo: string;
        /** numInterior \n calle \n numExterior \n codigoPostal \n ciudad \n estado \n pais \n LatLong*/
        Direccion: string;
        CodigoPais: string;
        EnUso: boolean;
        /** Horas en LOCAL de escuela
         * @example "12:00:00" */
        HoraEntradas: Array<string>;
        /** Horas en LOCAL de escuela
         * @example "12:00:00" */
        HoraSalidas: Array<string>;
        IdKinder: number;
        Modificacion: string;
        Nombre: string;
        Tamanio: number;
        Telefono: string;
        TipoLicencia: CTipoLicencia;
        UsaEntradaSalidaQR: boolean;
        ZonaHoraria: string;
        CalendarioCfg: boolean;
        UsaFinanzas: boolean;
        UsaFactura: boolean;
        FechaRegistro: string;
        ModuloEval: CTipoEvento.Logros | CTipoEvento.Evaluacion;

        TiempoCobro: number;
        ToleranciaFactura: number;
        ToleranciaRetraso: number;

        // Auxiliares
        Logo: string;
        /** Hora en LOCAL de escuela
         * @example "12:00:00" */
        Entrada: string;
        /** Hora en LOCAL de escuela
         * @example "12:00:00" */
        Salida: string;

        // extra
        KinderFiltro?: Array<number>;
    }

    export interface IDashboardInfo {
        GruposCount: number;
        MaestrosCount: number;
        AlumnosActivosCount: number;
        EventosAlumnos: Array<{ IdPersona: number, IdTipoEvento: number }>;
        IngresosMesSum: number;
        IngresosMesCount: number;
        IngresosAnioSum: number;
        IngresosAnioCount: number;
        EgresosMesSum: number;
        EgresosMesCount: number;
        EgresosAnioSum: number;
        EgresosAnioCount: number;
        EventosAgenda: IEventosAgendaDashboard[];
        PagosPorRecibirMesCount: number;
        PagosPorRecibirMesAlumnosCount: number;
        PagosPorRecibirAnioCount: number;
        PagosPorRealizarMesCount: number;
        PagosPorRealizarAnioCount: number;
        IngresosPorMes: IFinanzasMesDashboard[];
        EgresosPorMes: IFinanzasMesDashboard[];
    }

    export interface IFinanzasMesDashboard {
        Sum: number;
        Count: number;
    }

    export interface IEventosAgendaDashboard {
        Evento: string;
        Fin: string;
        Id: number;
        Inicio: string;
    }

    export interface ICumpleAlumno {
        Nombre: string;
        Edad: number;
        DtCumple: string;
    }

    export interface IAlumno {
        IdChild: number;
        IdKinder: number;
        IdFoto: number;
        IdChildMovimiento: CNinioMovimiento;
        Nombre: string;
        ApPaterno: string;
        ApMaterno: string;
        EnUso: boolean;
        Modificacion: string;
        FechaNacimiento: string;
        FechaFoto: string;
        Sexo: CSexo;
        IdUsuario: number
        Matricula: string;
        IdGrado: number;
        IdEscolaridad?: number;

        // EXTRAS
        NombreCompleto: string;
        StrSexo: string;
        StrEstado: string;

        // Getters
        /** dd/mm/yyyy */
        get getFechaNacimientoFmt(): string;
        /** Url de foto de perfil tamanio 3 */
        get getThumbnailURL(): string;

        get StrEscolaridad(): string;
        get NombreKinder(): string;
        get StrGrado(): string;
        /** Uso para UI.
         * Ej: ```"★ GrupoName_1, ★ GrupoName_2"``` */
        get StrGrupoPrincipal(): string;
        /** Ej: ```["GrupoName_1", "GrupoName_2"]``` */
        get StrGrupoPrincipalNames(): string[]

        // Asignaciones
        KinderFiltro: Array<number>;
        get getEdoLicencia(): CAlumnoEdoLicencia;
    }

    export interface IDataNinioCargaMasiva extends Pick<IAlumno, "Nombre" | "ApPaterno" | "ApMaterno" | "Matricula" | "FechaNacimiento" | "Sexo"> {
        NombreT: string
        ApPaternoT: string
        ApMaternoT: string
        TelefonoT: string
        CorreoT: string
    }

    export interface IGrupo {
        Nombre: string;
        IdGrupo: number;
        /** IdGrado */
        IdNivel: number;
        IdEscolaridad: number;
        IdKinder: number;
        EsPrincipal: boolean;
        EnUso: boolean;
        Modificacion: string;

        // /** @deprecated sus valores se pasan al Map "Horario" */
        // LunesEntrada: string;
        // /** @deprecated sus valores se pasan al Map "Horario" */
        // LunesSalida: string;
        // /** @deprecated sus valores se pasan al Map "Horario" */
        // MartesEntrada: string;
        // /** @deprecated sus valores se pasan al Map "Horario" */
        // MartesSalida: string;
        // /** @deprecated sus valores se pasan al Map "Horario" */
        // MiercolesEntrada: string;
        // /** @deprecated sus valores se pasan al Map "Horario" */
        // MiercolesSalida: string;
        // /** @deprecated sus valores se pasan al Map "Horario" */
        // JuevesEntrada: string;
        // /** @deprecated sus valores se pasan al Map "Horario" */
        // JuevesSalida: string;
        // /** @deprecated sus valores se pasan al Map "Horario" */
        // ViernesEntrada: string;
        // /** @deprecated sus valores se pasan al Map "Horario" */
        // ViernesSalida: string;
        // /** @deprecated sus valores se pasan al Map "Horario" */
        // SabadoEntrada: string;
        // /** @deprecated sus valores se pasan al Map "Horario" */
        // SabadoSalida: string;
        // /** @deprecated sus valores se pasan al Map "Horario" */
        // DomingoEntrada: string;
        // /** @deprecated sus valores se pasan al Map "Horario" */
        // DomingoSalida: string;

        //Extra
        Horario: Array<IHorarioDia>;
        KinderFiltro: Array<number>;

        get NombreKinder(): string;
        get NombreGrado(): string;
        get NombreEscolaridad(): string;
        /** "Primario" | "Secundario" */
        StrTipoGrupo: string;
    }

    /** El horario de un día */
    export interface IHorarioDia {
        /** Fecha UTC */
        Entrada: string;
        /** Fecha UTC */
        Salida: string;
        IdDia: Entidad.CDiaSemanal;
        /** solo para personalizados */
        IdHorario?: number;
    }

    /** @beforename INivel */
    export interface IGrado {
        /** IdGrado */
        IdNivel: number;
        IdKinder: number;
        IdEscolaridad: number;
        Nombre: string;
        EnUso: boolean;
        Modificacion: string;
        //Extra
        get NombreKinder(): string;
        KinderFiltro: Array<number>;
    }
    export interface ITutor {
        IdPadre: number;
        IdFoto: number;
        IdUsuarioPadre: number;
        Nombre: string;
        ApPaterno: string;
        ApMaterno: string;
        EnUso: boolean;
        Modificacion: string;
        Telefono: string;
        Correo: string;
        /** `""` || `"ID,..."` */
        IdEscuelas: string;

        // EXTRAS
        NombreCompleto: string;
        ///**Contraseña del tutor */
        Contrasenia?: string;

        /** Extra */
        KinderFiltro: Array<number>;
    }

    export interface IChildAsignaTutor {
        Asignacion?: ITutorAsignacion;
        Alumno: IAlumno;
        // /** @deprecated */
        // Escuela?: IKinder;
    }

    export interface ILogro {
        IdLogro: number;
        IdKinder: number;
        IdCategoria: number;
        Nombre: string;
        Proceso: CLogroTipoProceso;
        EnUso: boolean;
        //extra
        get StrCategoria(): string;
        StrTipoProceso: string;
        get StrEscuela(): string;
        KinderFiltro: Array<number>;
    }

    export interface ILogroAsignacion {
        IdAsignacion: number;
        IdChild: number;
        IdLogro: number;
        Estado: CLogroAsignacionEstado;
        Iniciado: string;
        Avanzado: string;
        Terminado: string;
        EnUso: boolean;
        // Extra
        StrEstado: string;

        KinderFiltro: Array<number>;
    }

    export interface ILogroCategoria {
        IdCategoria: number;
        IdKinder: number;
        Nombre: string;
        EnUso: boolean;
        // Extra
        get NombreKinder(): string;
        KinderFiltro: Array<number>
    }

    export enum CLogroTipoProceso {
        Completo = 1,
        Parcial = 2
    }

    export interface ITutorAsignacion {
        IdRegistro: number;
        IdPadre: number;
        IdChild: number;
        IdKinder: number;
        EsMonitoreo: boolean;
        FechaFin: string;
    }

    /** Horario `personalizado` para cada alumno/horario */
    export interface IAlumnoHorarioAsignacion {
        EnUso: Boolean;
        /** Fechas UTC: [Lunes, Martes, ..., Domingo] */
        Entradas: [string, string, string, string, string, string, string];
        IdGrupo: number;
        IdHorario: number;
        IdKinder: number;
        IdNinio: number;
        Modificacion: string;
        /** Fechas UTC: [Lunes, Martes, ..., Domingo] */
        Salidas: [string, string, string, string, string, string, string];
        Horario: Array<IHorarioDia>;
    }

    /** Horario `personalizado` para cada alumno/(horario-grupo) */
    export interface IAlumnoHorarioGrupoAsignacion extends Entidad.IAlumnoHorarioAsignacion {
        /** Nombre de Grupo */
        Nombre: string;
        IdGrado: number;
        GrupoGradoNombre?: string;
        GrupoEscolaridadNombre?: string;
        GrupoEscuelaNombre?: string;
        EsPrincipal: boolean;
    }

    export interface IExpediente {
        Estado: number,
        Extension: string,
        IdAlumno: number,
        IdEscuela: number,
        IdExpediente: number,
        IdOrganizacion: number,
        IdTipoExpediente: CTipoExpediente, //number,
        Nombre: string
        EsImagen?: boolean;
    }

    export interface ITipoExpediente {
        IdTipoExpediente: number;
        IdKinder: number;
        Nombre: string;
        EnUso: boolean;
    }

    export interface ExpedienteEscuela {
        IdExpediente: number;
        IdChild: number;
        TipoExpediente: CTipoExpediente; // number;
        Extension: string;
        EstadoValidado: number;
        EnUso: boolean;
    }

    export enum CTipoExpediente {
        Inscripcion,
        Graduacion,
        Medicos,
        Beca,
        Legales,
        Otros,
        Fotografias,
        PersonaAutorizadaRecoger,
        Identificacion,
        Boleta,
    }

    export interface ICircular {
        IdNoticia: number;
        IDKinder: number;
        /** Ids de Grados */
        Niveles: Array<number>;
        /** Ids de Grupos */
        Grupos: Array<number>;
        Titulo: string;
        Mensaje: string;
        Estado: CTipoEstadoNoticia;
        /** Fecha */
        Programado: string,
        /** Fecha */
        Expira: string,
        EnUso: boolean;
        Modificacion: string;
        /** @deprecated */
        ConImagen: boolean;
        ReqAutorizacion: boolean;
        // Autoridado?: boolean
        /** Ids */
        Alumnos?: Array<number>;

        /** Extra */
        NombreKinder: string;
        /** Fecha formateada de this.Programado */
        StrFecha: string;
        /** Fecha formateada de this.Expira */
        StrFechaExpira: string;
        /** Extra */
        KinderFiltro?: Array<number>;
    }

    export interface IMateria {
        IdMateria: number;
        IdEscuela: number;
        IdEscolaridad: number;
        IdGrado: number;
        IdsGrupos: number[];
        Nombre: string;
        TipoEvaluacion: number;
        FrecuenciaEval: number;
        MinimoAprobar: number;
        UsaCriterios: boolean;
        ConImagen: boolean;
        BloqueCalificarInicio: number;
        DiaCalificarInicio: number;
        DiasCalificar: number;
        Modificacion: string;

        Criterios?: string[];
        EvalValor?: string[];
        EvalDescripcion?: string[];
        IdConfigMateria: number;

        /* Extras */
        EnUso: boolean;

        /* Getters */
        get getThumbnailURL(): string;
    }

    export interface ICriterio {
        Id?: number;
        IdMateria?: number;
        Orden?: number;
        Descripcion: string;
    }

    export interface IConfigEvalAux {
        EvalValor: string;
        EvalDescripcion: string
    }

    export interface IEvaluacionConfigItem {
        Id?: number,
        IdMateria?: number,
        Valor: number,
        Nombre: string,
        Descripcion: string,
        TipoEvaluacion?: number;
    }

    export interface IBoletaGeneradaInfo {
        EnUso: boolean;
        /** @example ".pdf" */
        Extension: string;
        Id: number;
        IdArchivo: number;
        IdAlumno: number;
        IdEscuela: number;
        /** @example "2023-08-17T02:09:12.413Z" */
        Modificacion: string;
        Nombre: string;
        /** @example "2023-08-17T02:09:12.413Z" */
        Registro: string;
        IdCicloEscolar: number
    }

    export enum CTipoEvaluacion {
        Letras = 1,
        Colores,
        Numeros,
        Cualitativa
    }

    export enum CMateriaFrecuenciaEvaluacion {
        Diario = 1,
        Semanal = 2,
        Mensual = 3,
        Bimestral = 4,
        Trimestral = 5,
        Cuatrimestral = 6,
        Semestral = 7,
        Anual = 8,
        Libre = 9,
    }

    export interface criteriosDisponibles extends Pick<ICriterio, "Id" | "Descripcion" | "Orden"> {
        NombreMateria: string;
    }

    export interface IMateriaDisponible {
        IdMateria: number;
        IdGrupo: number;
        NombreMateria: string;
        Criterios: Array<criteriosDisponibles>;
        BloqueCalificarInicio: number;
        DiaCalificarInicio: number;
        DiasCalificar: number;
        FrecuenciaEval: number;
        IdConfiguracionMateria: number;
        UsaCriterios: boolean;
    }

    export interface IAsignacionMateria {
        IdAsignacion: number,
        IdGrupo: number,
        IdMateria: number,
        IdAlumno: number,
        NombreMateria?: string
        Criterios: Array<Pick<ICriterio, "Descripcion" | "Orden"> & { IdCriterio: number, IdAsignacion: number, idAsignacionMaster: number }>;
    }

    export interface IAsignacionAlumnoMateria {
        IdAsignacion: number;
        IdAlumno: number;
        IdCicloEscolar: number;
        IdMateria: number;
        IdGrupo: number;
        IdConfigMateria: number;

        Criterio: string;
        ValorTipo: number;
        FrecuenciaEval: number;
        BloqueCalInicio: number;
        DiaCalInicio: number;
        DiasCalificar: number;
        DtFin: string;
        DtInicio: string;
        EnUso: boolean;
        Modificacion: string;
        TotalCalificados: number;
        TotalCalificaciones: number;

        Repeticiones: IRepeticionAsignAlumnoMateria[]; //No viene en el nuevo servicio => Se tiene que consultar aparte
    }
    export enum CEstadoEval {
        Registered = 0,
        Pending = 1,
        Expired = 2,
        Evaluated = 3,
    }

    export interface IRepeticionAsignAlumnoMateria {
        Id: number;
        IdMateria: number;
        IdCalifico: number;
        EvaluacionStatus: CEstadoEval;
        DtInicio: string;
        DtFin: string;
        DtCalificarInicio: string;
        DtCalificarFin: string;
        DtCalificacion?: string;
        Observacion: string;
        ValorEval?: string;
        EnUso: boolean;
        FrecuenciaEval: number;
        Modificacion: string;
    }

    export interface IEvaluacion {
        IdAlumno: number;
        IdEvaluacion: number;
        IdAsignacion: number;
        IdGrupo: number;
        IdMateria: number;
        IdCiclo: number;
        IdEscuela: number,
        Tipo: number;
        Criterio: string;
        Materia: string;
        MinimoAprobatorio: string;
        Evaluacion: string;
        FechaEval: string;
        Observacion: string;
        Criterios: string[];
        ValNombres: string[];
        ValDescripcion: string[];
    }

    export interface ICriterioAsignado {
        Criterio: string[];
        IdGrupo: number;
        IdMateria: number;
    }

    export interface IElementoCalificado {
        Criterio: string;
        Criterios: string[]
        Evaluaciones: string[];
        IdsEvaluadores: number[];
        FechasEval: string[];
        IdAsignacion: number;
        IdGrupo: number;
        IdMateria: number;
        IdsEvaluaciones: number[]; //Solo documentado en ObtenerBoletaV2
        Materia: string;
        MinimoAprobatorio: string;
        Observaciones: string[];
        Tipo: number;
        ValDescripcion: string[];
        ValNombres: string[];
        /* Falta */
        IdAlumno?: number;
        /**
         * Extra: No viene como respuesta del servicio 
         * @example
         * Tipo == 3 (Números)
        ? Promedio de Evaluaciones
        : Moda (ej: "Moda1" | "Moda1\r\nModa2") */
        Evaluacion: string;
    }

    export interface IInasistenciasInfo {
        IdAlumno: number;
        FechaInasistencia: string;
    }

    export interface BoletasGeneradasInfo {
        IdBoletaGenerada: number;
        IdPadre: number;
        IdAlumno: number;
        IdCicloEscolar: number;
        IdEscuela: number;
        IdNivel: number;
        IdGrado: number;
        IdsGrupos: number[];
        // NOTE: IdsMaterias Tiene relación con IdsEvaluadores, IdsEvaluadores representan los maestros que han avaluado esa materia al alumno
        IdsMaterias: number[];
        /** Arreglo de cadenas que están compuestas de la siguiente estructura: ["1,2,3", "1,2", "2,4"]
         ** Cada elemento/Posición en el arreglo está asociado a un elemento de IdsMaterias en su posición correspondiente
         */
        IdsEvaluadores: string[];
        Observaciones: string[];
        NInasistencias: number;
        IdExpediente: number;
        FechaCreacion: string;
        IdsEvaluaciones: number[];
        EvaluacionesIdAsignaciones: number[];
        EvaluacionesValores: string[];
        EvaluacionesMateriasIds: number[];
        EvalaucionesTiposEval: number[];
        EvaluacionesCriterios: string[];
        EvaluacionesFechas: string[];
    }

    export interface IAsignacionInfo {
        IdAsignacion: number;
        IdMateria: number;
        Criterio: string;
        TipoEval: number;
        MinAprobatorio: string;
        EvalNombres: string[];
        EvalDescripciones: string[];
    }

    export interface ICircularAlumno {
        Id: number;
        IdGrupo: number;
        IdNoticia: number;
        Tipo: number;
        Titulo: string;
        Mensaje: string;
        Estado: number;
        EstadoAutorizado: number;
        FechaRegistro: string;
        DtProgramado: string;
        DtEnviado: string;
        Autorizable: boolean;
        EnUso: boolean;
    }

    export interface ICircularAutorizaciones {
        AlumnosDestino: number[],
        Aprobados: number[],
        Rechazados: number[],
    }

    export interface ICircularGrupoAutorizaciones {
        Autorizaciones: Entidad.ICircularAutorizaciones,
        IdGrupo: number,
    }

    export enum CCircularEstadoAutorizacion {
        Aprobado = 1, Rechazado, Pendiente
    }

    export enum ICircularTamanio {
        Original = 1, Mitad, miniatura
    }
    export interface IInfoArchivo {
        EnUso: boolean;
        Extension: string;
        IdArchivo: number;
        Nombre: string;
        MimeType: string;
        /** Extra */
        UrlFile?: string
    }

    export interface IMovimientoChild {
        Fin: string,
        IdAlumno: number,
        IdMovimiento: CNinioMovimiento,
        Inicio: string,
    }

    export interface IEscolaridad {
        Id: number;
        Nombre: string;
        IdEscuela: number;
        NombreEscuela: string;
        Modificacion: string;
        EnUso: boolean;
    }

    export interface IConfigEscolaridad {
        Actividades: Array<IActividadEscolaridadItems>;
        CobroCosto: Array<number>;
        CobroTiempo: Array<number>;
        CobroTolerancia: Array<number>;
        IdEscolaridad: number;
        Covid: boolean;
        EntradaSalidaQR: boolean;
    }

    type TipoEvento = CTipoEvento;
    export type IEventoEscolaridadItem<TipoE extends TipoEvento> = {
        Tipo: TipoE,
        EnUso: boolean,
    } & IEventoEscolaridadTypesConfigsMap[TipoE];

    type IActividadEscolaridadItems = {
        [TipoE in TipoEvento]: IEventoEscolaridadItem<TipoE>;
    }[TipoEvento];

    type IEventoEscolaridadTypesConfigsMap = {
        [Tipo in TipoEvento]: (
            Tipo extends CTipoEvento.Chat ? {
                /**
                 * 1: No
                 * 2: Una vía
                 * 3: Dos vías
                 */
                Configuracion: [CTipoChat];
            }
            : Tipo extends CTipoEvento.Alimentos ? {
                /** Tags de alimentos. Máximo de elementos: 5 */
                Configuracion: string[];
                /** Biberon/papilla, ej: [true, false] */
                ConfiguracionSecundaria: [boolean, boolean];
            }
            : Tipo extends CTipoEvento.Humor ? {
                Configuracion: [string, string, string, string, string, string, string, string];
            }
            : Tipo extends CTipoEvento.Covid ? {
                /**
                 * 0: Deshabilitado
                 * 1: Habilitado
                 */
                Configuracion: [0 | 1];
            }
            : {
                Configuracion: []
            }
        )
    }

    export enum CTipoChat {
        /** No se envia */
        Nunguno = 1,
        UnaVia = 2,
        DosVias = 3
    }

    export enum CTipoEvento {
        Actividades = 4,
        Humor = 8,
        Fotos = 6,
        Higiene = 3,
        Alimentos = 2,
        Logros = 5,
        Siesta = 1,
        Comentarios = 7,
        Chat = 20,
        Covid = 21,
        Evaluacion = 11,
    }
    export enum CEmociones {
        Feliz = 1,
        Serio = 2,
        Triste = 3,
        Travieso = 4,
        Enojado = 5,
        Somnoliento = 6,
        Enfermo = 7,
        Lloroso = 8
    }
    export enum CBanio {
        Pipi = 1,
        Popo = 2
        // Dientes = 3
    }

    export interface IFinanzaCargo {
        ID?: number;
        Categoria: CFinanzaCargoCategoria;
        DiaAplicacion?: number;
        DiaMorosidad?: number;
        DiaVencimiento?: number;
        MesAplicacion?: number;
        Modificacion?: string;
        Nombre: string;
        Periodicidad?: CFinanzaCargoPeriodicidadCargos;
        TipoValor: CFinanzaCargoTipoValor;
        Valor: number;
        IdEscuela: number;
        ClaveProdServSAT: string;
        NombreProdServSAT: string;

        TipoRecargo: CFinanzaCargoTipoValor;
        TipoInteres: CFinanzaCargoTipoInteres;
        ValorRecargo: number;
        TipoTasa: CFinanzasCargoTipoTasa;
        /** Recurrencia de recargo */
        IntervaloBloque: CFinanzasCargoRecurrenciaRecargo;

        /** Extra */
        StrCategoria?: string;
        /** Extra */
        StrPeriodicidad?: string;
        /** Extra */
        StrEscuela?: string;
        /** Extra */
        KinderFiltro?: number[];
        get getValorRecargoFmt(): string;
    }

    // *********************************************************************
    // Finanzas asignaciones Alumno - Cargos (V1)
    // *********************************************************************

    /** @deprecated v1 */
    export interface IFinanzaAsignacionCargoDescuento extends Pick<IFinanzaCargo, "Nombre" | "Valor" | "TipoValor" | "Periodicidad" | "StrPeriodicidad"> {
        ID?: number;
        IdAlumno: number;
        IdCicloEscolar: number;
        // IdsDescuento?: Array<number>;
        IdCuenta: number;
        // Valor: number;
        Repeticiones?: Array<IFinanzaAsignacionRepeticion>;

        // Extras
        FechaInicio: string; // Date;
        FechaFin: string; //Date;
        /** Auxiliar */
        IdCargoPlantilla: number;
        // InfoCargo?: IFinanzaCargo;
        /** Extra */
        Total?: number;
        /** Extra */
        NoRepeticiones?: number;
        /** Auxiliar cálculo de rangos */
        DtFechaInicio?: Date;
        /** Auxiliar cálculo de rangos */
        DtFechaFin?: Date;
    }

    /** @deprecated v1 */
    export interface IFinanzaAsignacionRepeticion {//  extends IFinanzaCargo {
        ID?: number;
        Aplicacion: string;
        FechaInicio: string;
        FechaFin: string;
        /** Si pasa de la fecha de vencimiento respectiva */
        Procesado?: boolean;
        /** IdsDescuentos */
        Descuentos?: Array<number>;

        // Extras
        /** Auxiliar en caso de seleccion de multiples alumnos */
        IDs?: Array<number>;
        /** Temporal_Test */
        IdCargoAsignacion?: number;
        // CargoClone?: IFinanzaCargo;
        DescuentosInfo?: Array<IFinanzaAsignacionInfoDescuentos>;
        /** Auxiliar cálculo de rangos */
        DtFechaInicio?: Date;
        /** Auxiliar cálculo de rangos */
        DtFechaFin?: Date;
    }

    /** @deprecated v1 */
    export interface IFinanzaAsignacionInfoDescuentos extends Pick<IFinanzaCargo, "ID" | "Nombre" | "Valor" | "TipoValor"> {
        /** Auxiliar en caso de seleccion de multiples alumnos */
        IDs?: Array<number>;
    }

    // *********************************************************************
    // Finanzas asignaciones Alumno - Cargos (V2)
    // *********************************************************************

    export interface IFinanzaAsignacionCargoDescuentoV2 extends Pick<IFinanzaCargo, "Nombre" | "Valor" | "TipoValor" | "Periodicidad" | "StrPeriodicidad"> {
        IdAlumno: number;
        ID: number;
        IdCicloEscolar: number;
        Nombre: string;
        TipoValor: CFinanzaCargoTipoValor;
        Valor: number;
        Categoria: CFinanzaCargoCategoria;
        Periodicidad: CFinanzaCargoPeriodicidadCargos;
        IdCuenta: number;
        Repeticiones: IFinanzaAsignacionRepeticionV2[];
    }

    export interface IFinanzaAsignacionRepeticionV2 {
        ID: number;
        // Descuentos: number[];
        Descuentos: IFinanzaAsignacionInfoDescuentoV2[];
        FechaInicio: string;
        FechaFin: string;
        Procesado: boolean;
        Aplicacion: string;
        TotalDescuento: number;
    }

    export interface IFinanzaAsignacionInfoDescuentoV2 {
        ID: number;
        Nombre: string;
        Valor: number;
        TipoValor: CFinanzaCargoTipoValor;
        /** Valor monetario perteneciente al descuento correspondiente */
        Descuento: number;
    }

    export enum CFinanzaCargoCategoria {
        Cargo = 1,
        Descuento = 2,
        /* Entrada anticipada */
        CargoEntrada = 4,
        /* Salida retrasada */
        CargoSalida = 5
    }

    export enum CFinanzaCargoTipoValor {
        Ninguno = 0,
        Monto = 1,
        Porcentaje
    }

    export enum CFinanzaCargoTipoInteres {
        /** Si el cargo no tiene Recargo, es igual a Ninguno */
        MontoUnico = 0,
        Simple = 1,
        Compuesto
    }

    export enum CFinanzasCargoTipoTasa {
        NoAplica = 0,
        Diaria = 1,
        Mensual = 2
    }
    export enum CFinanzasCargoRecurrenciaRecargo {
        Diaria = 0,
        Mensual = 30,
    }
    export enum CFinanzaCargoPeriodicidadCargos {
        Mensual = 1,
        Bimestral = 2,
        Trimestral = 3,
        Cuatrimestral = 4,
        Semestral = 5,
        Anual = 6,
        Unico = 7
    }

    /** @deprecated new ui.Utils.ViewData.fn_GetList_Periodicidad() */
    export const PeriodicidadNMeses = {
        [CFinanzaCargoPeriodicidadCargos.Mensual]: 1, // Mensual
        [CFinanzaCargoPeriodicidadCargos.Bimestral]: 2, // Bimestral
        [CFinanzaCargoPeriodicidadCargos.Trimestral]: 3, // Trimestral
        [CFinanzaCargoPeriodicidadCargos.Cuatrimestral]: 4, // Cuatrimestral
        [CFinanzaCargoPeriodicidadCargos.Semestral]: 6, // Semestral
        [CFinanzaCargoPeriodicidadCargos.Anual]: 12 // Anual
    };

    export const MateriaPeriodicidadNMeses = {
        [CMateriaFrecuenciaEvaluacion.Mensual]: 1,
        [CMateriaFrecuenciaEvaluacion.Bimestral]: 2,
        [CMateriaFrecuenciaEvaluacion.Trimestral]: 3,
        [CMateriaFrecuenciaEvaluacion.Cuatrimestral]: 4,
        [CMateriaFrecuenciaEvaluacion.Semestral]: 6,
        [CMateriaFrecuenciaEvaluacion.Anual]: 12
    }

    // /** @deprecated */
    // export interface IFinanzaMovimiento {
    //     ID: number;
    //     Categoria: CFinanzaCategoriaMovimiento;
    //     FechaAplicacion: string,
    //     FechaVencimiento: string;
    //     /** Id de asignacion (cargo | descuento) */
    //     IDAsignacionCargo: number;
    //     Recargo: number;
    //     Saldo: number;
    //     Valor: number;
    //     Detalles: IFinanzaMovimientoDetalle[];
    //     /** IdClon */
    //     IDCargo: number;

    //     MetodoPago: string;
    //     Descripcion: string;
    //     Periodo: string;
    // }

    // /** @deprecated */
    // export interface IFinanzaMovimientoDetalle extends Omit<IFinanzaMovimiento, "Detalles" | "FechaVencimiento"> {
    //     FechaRegistro: string;
    //     IdMetodoPago: number;
    //     /** Auxiliar para NinioEstadosCuenta */
    //     ParentMovimiento: IFinanzaMovimiento;
    // }

    export interface IFinanzaMovimientoV2 {
        /** Descripción del cargo */
        Cargo: string;
        Categoria: CFinanzaCategoriaMovimiento;
        Detalles: IFinanzaMovimientoDetalleV2[];
        FechaAplicacion: string;
        FechaMorosidad: string;
        FechaVencimiento: string;
        Id: number;
        /** Id de asignación (cargo | descuento) */
        IdAsignacionCargo: number;
        /** Id de cargo clon  */
        IdCargo: number;
        Recargo: number;
        Saldo: number;
        /** Uso: ?? */
        TipoInteres: CFinanzaCargoTipoInteres;
        /** No tiene por qué ser diferente de Monto */
        TipoValor: CFinanzaCargoTipoValor;
        Valor: number;

        // Pendientes
        FechaInicio: string;
        FechaFin: string;
        Periodicidad: CFinanzaCargoPeriodicidadCargos;
        CicloEscolar: number;
    }

    export interface IFinanzaMovimientoDetalleV2 {
        /** Descripción del dato si aplica */
        Cargo: string;
        Categoria: CFinanzaCategoriaMovimiento;
        FechaMorosidad: string;
        FechaRegistro: string;
        Id: number;
        IdAsignacionCargo: number;
        IdCargo: number;
        IdMetodoPago: number;
        Recargo: number;
        Saldo: number;
        TipoInteres: CFinanzaCargoTipoInteres;
        TipoValor: CFinanzaCargoTipoValor;
        Valor: number;
        IdFacturaFolio: number;
        /** Último bloque del código UUID */
        UUID: string;

        /** != undefined: `Categoria` == Comision */
        IdComision?: number;
        /** != undefined: `Categoria` == Comision */
        TipoValorComisionPlantilla?: CFinanzaCargoTipoValor;
        /** != undefined: `Categoria` == Comision
         *
         * Relacionado con `TipoValorComisionPlantilla`,
         * si es Porcentaje(2) multiplicar por 100 */
        ValorComisionPlantilla?: number;
    }

    export enum CFinanzaCategoriaMovimiento {
        Cargo = 1, // CFinanzaCargoCategoria.Cargo,
        Descuento = 2, // CFinanzaCargoCategoria.Descuento,
        Morosidad = 3,
        LlegadaAnticipada = 4,
        SalidaTarde = 5,
        PagoParcial = 6,
        PagoCompleto = 7,
        Comision = 8,
    }

    export enum CTipoPago {
        PagoCompleto = CFinanzaCategoriaMovimiento.PagoCompleto,
        PagoParcial = CFinanzaCategoriaMovimiento.PagoParcial
    }

    export interface ICicloEscolar {
        Id: number;
        IdEscuela: number;
        Nombre: string;
        IdOrganizacion: number;
        FechaInicio: string;
        FechaFin: string;
    }

    export interface IFinanzaMetodoPago {
        IdMetodoPago: number;
        IdEscuela: number;
        Nombre: string;
        ProveedorElectronico: CProveedorMetodoPagoElectronico;
        EnUso?: boolean;
        Modificacion?: string;
        Configuracion?: [string, string, string];
        ModoProduccion?: boolean;
        PrefijoPago: string;
        /** @example "01" */
        IdFormaPagoSAT: string
        /** @example "Efectivo" */
        NombreFormaPagoSAT: string

        /** Extra */
        NombreEscuela: string;
        /** Extra */
        KinderFiltro?: number[];
    }

    export enum CProveedorMetodoPagoElectronico {
        SinProveedor = 0,
        OpenPay = 1
    }

    export interface IFinanzaItemHoraExtra {
        Id: number;
        Tipo: CTipoHoraExtra;
        Costo: number;
        Fecha: string;
        IdAlumno: number;
        IdEscuela: number;
        EstadoCobro: CEstadoCobro;

        MinutosTotales: number; // before names -> MinutosRetraso | MinutosAnticipo
        HoraEsperada: string;  // before names -> SalidaEstimada | EntradaEstimada

        HoraInicio: string; // -> (HoraSalida | HoraEntrada)
        HoraFin: string;

        MinutosPorCobrar: number; // Minutos totales - before name RetrasoTolerancia

        Segmentos: number;
        TiempoSegmento: number;
        Tolerancia: number
        /** (Segmentos * TiempoSegmento) */
        MinutosCobrados: number;
        EnUso: boolean;
    }

    export interface IFinanzaPagoRecibido {
        Id: number;
        IdAlumno: number;
        IdAsignacionCargo: number;
        IdCargo: number;
        Valor: number;
        Categoria: number;
        Concepto: string;
        FechaAplicacion: string;
        FechaVencimiento: string;
        /** ?? */
        FolioPago: string;
        Detalles: IFinanzaPagoRecibidoDetalle[];
        /** ?? */
        Recargo: number;
    }

    export interface IFinanzaPagoRecibidoDetalle {
        Categoria: CFinanzaCategoriaMovimiento;
        Concepto: string;
        /** Aplica para los pagos eléctronicos (Ej: OpenPay) */
        FolioPago: string;
        Id: number;
        IdMetodoPago: number;
        Valor: number;
        FechaRegistro: string;
        Recargo: number;
    }

    export interface IFinanzaPagoPendiente {
        Concepto: string;
        FechaAplicacion: string;
        FechaRegistro: string;
        FechaVencimiento: string;
        Id: number;
        IdAlumno: number;
        IdAsignacionCargo: number;
        IdCargo: number;
        IdPadre: number;
        Recargo: number;
        TotalCargo: number;
        TotalCobrar: number;
        TotalDescuentos: number;
    }

    export interface IFinanzaCargoPorAplicar {
        Cargo: string;
        Detalles: IFinanzaCargoPorAplicarDetalle[];
        FechaAplicacion: string;
        FechaFin: string;
        FechaInicio: string;
        FechaRegistro: string;
        Id: number;
        IdAlumno: number;
        IdCargo: number;
        TipoRegistro: number;
        TipoValor: number;
        /** ??? */
        Totales: [];
        Valor: number;
    }

    export interface IFinanzaCargoPorAplicarDetalle {
        Cargo: string;
        Descuento: number;
        Id: number;
        TipoRegistro: number;
        TipoValor: number;
        Valor: number;
    }

    export enum CTipoHoraExtra {
        Llegada = 3,
        Salida = 4
    }

    export enum CEstadoCobro {
        /** Estado inicial del registro */
        Generado = 1,
        /** El registro no debe considerarse para el cálculo de cobro */
        Descartado = 2,
        /** Un cargo ya ha sido generado para el registro */
        Cobrado = 3
    }

    export interface IFinanzaEgresoPredefinido {
        Id: number;
        Descripcion: string;
        TipoGasto: CFinanzaEgresoTipo;
        Valor: number;
        IdEscuela: number;
        IdCategoria: number;
        EnUso: boolean;
        Modificacion: string;

        get getEscuelaNombre(): string;
        get getValorFmt(): string;
        get getEsValorVariable(): string;
        get getNombreCategoria(): string;
    }

    export enum CFinanzaEgresoTipo {
        Fijos = 1,
        Variables = 2,
        Administrativos = 3,
        Inversiones = 4,
        Financieros = 5,
        Otros = 6,
    }

    export interface IFinanzasEgresoCategoria {
        Id: number;
        Nombre: string;
        IdEscuela: number;
        EnUso: boolean;
        Modificacion: string;
        get NombreKinder(): string;
        KinderFiltro: Array<number>;
    }

    export interface IFinanzaCuentaBancaria {
        Id: number;
        Numero: string;
        NombreBanco: string;
        Clave: string;
        Sucursal: string;
        Beneficiario: string;
        Tipo: CTipoCuentaBancaria;
        IdEscuela: number;
        EnUso: boolean;
        Modificacion: string;

        get getNombreEscuela(): string;
        get getTipo(): string;
    }

    export enum CTipoCuentaBancaria {
        Chequera = 1,
        Debito = 2,
        Credito = 3,
        MedioElectronico = 4,
        Efectivo = 5,
    }

    export interface IFinanzaProveedor {
        Id: number;
        IdEscuela: number;
        RazonSocial: string;
        RFC: string;
        CP: string;
        Telefono: string;
        Correo: string;
        Notas: string;
        EnUso: boolean;
        Modificacion: string;

        get getNombreEscuela(): string;
    }

    export interface IFinanzaEgresoPagoPendiente {
        Adeudo: number;
        Descripcion: string;
        Descuento: 0,
        FechaAplicacion: string;
        FechaEmision: string;
        FechaVencimiento: string;
        Folio: string;
        IdAsignacion: number;
        IdCategoria: number;
        IdEscuela: number;
        IdProveedor: number;
        Pagado: number;
        RetencionIEPS: number;
        RetencionISR: number;
        RetencionIVA: number;
        Saldo: number;
        /** * `0`: no tiene
         * * `1`: Factura */
        TipoDocumento: number;
        TipoEgreso: CFinanzaEgresoTipo;
        TipoRegistro: number;
        TrasladoIEPS: number;
        TrasladoIVA: number;
        ValorCargo: number;
    }

    export interface IFinanzaEgresoMovimiento {
        IdCategoria: number;
        Descripcion: string;
        Descuento: number;
        Detalles: IFinanzaEgresoMovimientoDetalle[];
        FechaAplicacion: string;
        FechaEmision: string;
        FechaVencimiento: string;
        Folio: string;
        Id: number;
        IdEscuela: number;
        IdProveedor: number;
        RetencionIEPS: number;
        RetencionISR: number;
        RetencionIVA: number;
        Saldo: number;
        TipoDocumento: number;
        TipoEgreso: number;
        TrasladoIEPS: number;
        TrasladoIVA: number;
        Valor: number;
    }

    export interface IFinanzaEgresoMovimientoDetalle {
        TipoRegistro: CFinanzaCategoriaMovimiento;
        FechaAplicacion: string;
        Id: number;
        IdAgrupador: number;
        IdCuentaPago: number;
        Saldo: number;
        Valor: number;
    }

    export interface ICovidEncuesta {
        Calificacion: number,
        FechaRegistro: string,
        IdAlumno: number,
        IdTutor: number
    }

    interface IEventoBase {
        /** StrDate
         * * Note: Solo cuando TipoEvento == 9 (Entrada-Salida(Asistencia))
         */
        EvtFin: string;
        /** StrDate */
        EvtInicio: string;
        Id: number;
        /** Casos:
         * * Tipo Evento Foto (6) -> Su valor será el id de la imagen
         * * Tipo Evento Humor (8) -> CEmociones
         * * Tipo Evento Higiene (3) -> CBanio
         * * Tipo Evento Asistencia (9) -> IdGrupo
         */
        InfoEvt: CEmociones | CBanio | number;
    }

    export interface IEventoAlumno extends IEventoBase {
        TipoEVento: CReporteTipoEvento;
    }
    export interface IEventoGrupo extends IEventoBase {
        IdAlumno: number;
        TipoEvento: CReporteTipoEvento;
    }
    export interface IEventoEscuela extends IEventoBase {
        IdAlumno: number;
        TipoEVento: CReporteTipoEvento;
        Informacion: string;
        Comentario: string;
        /** Casos:
         * * Tipo Evento Alimentos (2)
         */
        Porcion: string;
        /** Usuario Teacher */
        IdUsuarioModifico: number;
    }

    enum CReporteTipoEventoExtra {
        Asistencia = 9
    }

    export const CReporteTipoEvento = {
        ...CTipoEvento,
        ...CReporteTipoEventoExtra
    }
    export type CReporteTipoEvento = CTipoEvento | CReporteTipoEventoExtra;

    export enum CTipoAccionEvento {
        Unico = 1,
        Futuros = 2,
        Todos = 3
    }

    export interface IEventoPorCalendario {
        Fin: string;
        Id: number;
        IdAgrupador: number;
        IdCalendario: number;
        IdEscuela: number;
        Inicio: string;
        Nombre: string;
        BlockReference: IEventoPorCalendario;
    }

    export interface IEventoInGrid extends Omit<IEventoPorCalendario, "Fin" | "Inicio"> {
        Inicio: Date,
        Fin: Date
    }

    export enum CCalendarioTipoRecurrencia {
        SinRecurrencia = 0,
        Diaria,
        Semanal,
        Mensual,
        Anual,
    }

    export enum CCalendarioTipoRecurrenciaMensual {
        NoAplica = 0,
        LiteralDate, // Mensualmente, el día 28
        NDay,        // Mensualmente, el cuarto domingo
        LastDay      // Mensualmente, el último domingo
    }

    export enum CCalendarioFinRecurrencia {
        Fecha = 0,
        Numero
    }

    export interface IEventoConfig {
        Nombre: string;
        IsAllDay: boolean;
        Inicio: string;
        Fin: string;
        TipoRecurrencia: CCalendarioTipoRecurrencia;
        TipoFinRecurrencia: CCalendarioFinRecurrencia;
        FinRecurrencia: string;
        NRecurrencias: number;
        VarianteRecurrencia: CCalendarioTipoRecurrenciaMensual;
    }

    export interface IEventoInfo extends IEventoConfig {
        IdCalendario: number,
        InicioHermanos: string[],
    }

    export interface ICalendario {
        Id: number;
        IdEntidad: number;
        IdEscuela: number;
        IdEscolaridad: number;
        Nombre: string;
        TipoEntidad: number;
    }

    export enum CCalendarioTipoEntidad {
        Escuela = 1,
        Grupo = 2,
        Alimento = 3,
    }

    export interface IAyuda {
        Categoria: string;
        Descripcion: string;
        EnUso: boolean;
        Id: number;
        Modificacion: string;
        TipoAplicacion: number;
        Titulo: string;
        ConArchivo: boolean;
        ConImagenMini: boolean;
        Enlace: string;
        MimeType: string;
        FechaRegistro: string,
    }

    export interface IMaestro {
        ApMaterno: string;
        ApPaterno: string;
        Correo: string;
        EnUso: boolean;
        IdsGrupos: number[];
        Id: number;
        IdEscuela: number;
        Modificacion: string;
        Nombre: string;
        Telefono: string;
        IdUsuario: number;
        /** Marcar entrada salida a todos los alumnos de la escuela */
        EntradaSalidaEscuela: boolean;
        /** Extra */
        NombreCompleto: string;
        /** Extra */
        KinderFiltro?: number[];
        /** Extra */
        NombreEscuela: string;
    }

    /** Item de asignacion Maestro - Grupo */
    export interface IMaestroGrupo {
        ApMaterno: string;
        ApPaterno: string;
        Correo: string;
        IdAsignacion: number;
        IdGrupo: number;
        IdMaestro: number;
        Nombre: string;
    }

    export interface IEscuelaConfigInfoExtra {
        Id: number;
        Tags: string[];
        EnUso: true;
        Orden: number;
        Requerido: boolean;
        UsarEnBoleta: boolean;
        IdEscuela: number;
        // /** json
        //  * ```
        //  * "{MinWidth: 200}"
        //  * ```
        //  */
        // ExtraConfig: string;
        Modificacion: "2022-07-01T22:04:13.753Z";
        Tipo: CEscuelaInfoExtraTipoDato;
    }

    export enum CEscuelaInfoExtraTipoDato {
        Text = 1,
        Number = 2,
        Date = 3,
        Check = 4
    }

    export interface IEscuelaDiaNoLectivo {
        Id: number;
        IdEscuela: number;
        Nombre: string;
        FechaInicio: string;
        FechaFin: string;
        Frecuencia: number;
    }

    export enum CAyudaTipoAplicacion {
        Alumno = 1,
        Padre = 2,
        Docente = 4,
        Admin = 5
    }
    /** NOTA: No modificar */
    export enum CDiaSemanal {
        Domingo0 = 0,
        Lunes = 1,//empieza del #1
        Martes,
        Miercoles,
        Jueves,
        Viernes,
        Sabado,
        Domingo
    }

    export enum CNinioMovimiento {
        Activo = 1,
        BajaTemporal = 2,
        BajaDefinitiva = 3,
        Graduado = 4,
        Preinscripcion = 5
    }

    export enum CSexo {
        Default = 0,
        Femenino = 1,
        Masculino = 2
    }

    export enum CTipoRequest {
        Escuela = 1,
        Alumno = 2,
        LogroCategoria = 3,
        Tutor = 4,
        Grupo = 5,
        Grado = 6,
        Logro = 7,
        LogroAsignacion = 8,
        TutorAsignacion = 9,
        HorarioAlumno = 11,
        // /** @deprecated En desuso, hasta que los permisos sean monitoreados */
        // UsuarioPermisos = 12,
        // Expediente = 13,
        // TipoExpediente = 14,
        Circular = 15,
        // Archivo = 16,
        Escolaridad = 17,
        // Finanzas = 18,
        FinanzaCargo = 19,
        Sesion = 20,
        CicloEscolar = 21,
        FinanzaMetodoPago = 22,
        FinanzaHoraExtra = 23,
        Usuario = 24,
        //Covid = 25
        Ayuda = 26,
        //Escuela = 22
        Maestro = 27,
        Licencia = 28,
        // LicenciaPagoPendiente = 29,
        EscuelaAlumnoInfoExtra = 30,
        EgresoPredefinido = 31,
        // EgresoMovimiento = 32,
        CuentaBancaria = 33,
        Proveedor = 34,
        MateriaV2 = 36,
        EgresoCategoria = 37
    }

    export enum CTipoPerfil {
        /** Administrador de organización: acceso total
         * 
         * // NOTE No requiere de asignaciones de permisos
        */
        Admin = 1,
        /** Acceso total a las escuelas permitidas
         * 
         * // NOTE Basta una asignación Escuela-Permiso, de preferencia: (Escuela(any) - Modulo(Escuela) - Accion(Ver))
         * 
         * // NOTE El permiso disponible del modulo Escuela y la acción Ver, en BD(Real) nunca deden Deprecarse
         * * SOLO Importa la existenca de una relación con una escuela
         * 
         * // NOTE El resto de las asignaciones se crean localmente en base de
         * los Permisos Disponibles que se descargan al loguearse
        */
        SuperUsuario = 2,
        /** Acceso limitado de escuelas, modulos y acciones
         * 
         * // NOTE Requiere todas las asignaciones especificas
        */
        Limitado = 3
    }

    export enum CLogroAsignacionEstado {
        Registrado, Iniciado, Avanzado, Terminado
    }

    /** Modulos a a considerar permisibles (categoriapermiso | Modulo | Menu)*/
    export enum CModulo {
        // MODULOS PADRES
        Escuelas = 1,
        // , MAESTRAS = 2
        // , CATALOGOS = 3
        // , EGRESOS = 4
        Usuarios = 5,
        Circulares = 6,
        Logros = 7, // Modulo pendiente a modificar
        // , EstadoCuenta = 8 // es igual a finanzas
        // , Reportes = 9
        //, REPORTE_GRAFICA = 10

        Alumnos = 20,
        Grupos = 21,
        Tutores = 22,
        // , Categoria = 23
        // , Asignaciones = 24
        // , Niveles = 25
        FinanzasCargo = 26,
        FinanzasMetodosPago = 27,
        FinanzasHorasExtras = 28,
        FinanzasPagosRecibidos = 29,
        // , FinanzasReportes = ?

        // SUBMODULOS

        // SubModulos Alumnos
        // PanelInscribir = 40, //21,
        PanelInfoGeneral = 41, // 22,
        PanelTutores = 42, // 23,
        PanelExpediente = 43, // 24,
        PanelNinioHorario = 44, //25,
        PanelNinioLogros = 45, //26,
        PanelCircular = 46, // 27,
        PanelFinanzasCargosDescuentos = 47, // 28,
        PanelFinanzasEdoCuenta = 48,

        // SubModulos Escuelas
        PanelCicloEscolar = 49,
        PanelConfiguracionNiveles = 50,
        FinanzasEgresos = 51,
        Maestros = 52,
        PanelMaestros = 53,
        FinanzasCuentasBancarias = 54,
        FinanzasProveedores = 55,
        FinanzasPagosPendientes = 56,
        FinanzasCargosPorAplicar = 57,
        LogrosCategorias = 58,
        ReporteAsistencias = 59,
        ReporteEventos = 60,
        ReporteLogros = 61,

        /** Cuentas por pagar */
        FinanzasEgresosPagosPendientes = 62,
        FinanzasEgresosMovimientos = 63,
        /* Logros - Materias - Calificaciones - Boletas */
        Materias = 64,
        PanelAlumnoMaterias = 65,
        PanelBoletas = 66,
        PanelFotos = 67,
        FinanzasCategoriasEgresos = 68,
        Dashboard = 69,
    }

    export enum CAccionPermiso {
        Ver = 1,
        Agregar,
        Editar,
        Eliminar,
        VerDetalle,
        VerContrasenia,
        EnviarEmail,
        EnviarSMS,
        ExportarAExcel,
        EditarHorario,
        AgregarCargosUnicos,
        AgregarDescuentos,
        RegistrarPagos,
        GenerarCobro,
        Ignorar,
        EliminarDescuentos,
    }

    export const _MODULOS_FINANZAS = [
        CModulo.FinanzasMetodosPago,
        CModulo.FinanzasCargo,
        CModulo.FinanzasEgresos,
        CModulo.FinanzasCuentasBancarias,
        CModulo.FinanzasProveedores,

        CModulo.FinanzasHorasExtras,
        CModulo.FinanzasPagosRecibidos,
        CModulo.FinanzasEgresosPagosPendientes,
        CModulo.FinanzasCargosPorAplicar,

        CModulo.FinanzasPagosPendientes,
        CModulo.FinanzasEgresosMovimientos,

        CModulo.PanelFinanzasCargosDescuentos,
        CModulo.PanelFinanzasEdoCuenta,
        CModulo.FinanzasCategoriasEgresos,
    ]

    export const _MODULOS_LOGROS = [
        CModulo.Logros,
        CModulo.LogrosCategorias,
        CModulo.ReporteLogros,
        CModulo.PanelNinioLogros,
    ]

    export const _MODULOS_CALIFICACIONES = [
        CModulo.Materias,
        CModulo.PanelAlumnoMaterias,
        CModulo.PanelBoletas,
    ]

    export enum CTipoLicencia {
        Mensual = 1, Semestral, Anual
    }

    export enum CTipoEstadoNoticia {
        Programado, Enviado, Desconocido = 2
    }

    export enum CAlumnoTutorHorarioMissActv {
        None,
        Tutor,
        Horario,
        Ambos
    }

    // *********************************************************************
    // PERMISOS V2 THINGS TESTS
    // *********************************************************************

    export interface IPermisoModulo {
        IdModulo: CModulo;
        IdPadre: CModulo;
        Nombre: string;
        EnUso?: boolean;
    }

    export interface IPermisoAccion {
        IdAccion: CAccionPermiso;
        IdPadre: CAccionPermiso;
        Nombre: string;
        EnUso?: boolean;
    }

    export interface IPermisoDisponible {
        IdPermiso: number;
        IdModulo: CModulo;
        IdAccion: CAccionPermiso;
        EnUso?: boolean;
    }

    export interface IPermisoAsignacionUser {
        /** En caso de ser negativo, es un permiso fantasma local */
        IdAsignacionPermiso: number;
        IdUsuario: number;
        IdPermiso: number;
        IdEscuela: number;
        EnUso?: boolean;
    }

    // /** @deprecated En desuso*/
    // //@ts-ignore
    // let modulosArray: IPermisoModulo[] = [
    //     { IdModulo: CModulo.Escuelas, IdPadre: 0, Nombre: "Escuelas" }, // TIENE submodulos
    //     { IdModulo: CModulo.PanelCicloEscolar, IdPadre: CModulo.Escuelas, Nombre: "Ciclo escolar" }, // Submodulo
    //     { IdModulo: CModulo.PanelConfiguracionNiveles, IdPadre: CModulo.Escuelas, Nombre: "Configuración de niveles" }, // Submodulo

    //     { IdModulo: CModulo.Grupos, IdPadre: 0, Nombre: "Grupos" },

    //     { IdModulo: CModulo.Alumnos, IdPadre: 0, Nombre: "Alumnos" }, // TIENE submodulos
    //     { IdModulo: CModulo.PanelInfoGeneral, IdPadre: CModulo.Alumnos, Nombre: "Información general" }, // Submodulo
    //     { IdModulo: CModulo.PanelTutores, IdPadre: CModulo.Alumnos, Nombre: "Tutores" }, // Submodulo
    //     { IdModulo: CModulo.PanelExpediente, IdPadre: CModulo.Alumnos, Nombre: "Expediente" }, // Submodulo
    //     { IdModulo: CModulo.PanelNinioHorario, IdPadre: CModulo.Alumnos, Nombre: "Horarios y grupos" }, // Submodulo (Inscribir)
    //     { IdModulo: CModulo.PanelNinioLogros, IdPadre: CModulo.Alumnos, Nombre: "Logros" }, // Submodulo
    //     { IdModulo: CModulo.PanelCircular, IdPadre: CModulo.Alumnos, Nombre: "Comunicados" }, // Submodulo
    //     { IdModulo: CModulo.PanelFinanzasCargosDescuentos, IdPadre: CModulo.Alumnos, Nombre: "Finanzas - cargos y descuentos" }, // Submodulo
    //     { IdModulo: CModulo.PanelFinanzasEdoCuenta, IdPadre: CModulo.Alumnos, Nombre: "Finanzas - estado de cuenta" }, // Submodulo

    //     { IdModulo: CModulo.Tutores, IdPadre: 0, Nombre: "Tutores" },
    //     { IdModulo: CModulo.FinanzasCargo, IdPadre: 0, Nombre: "Finanzas cargos" },
    //     { IdModulo: CModulo.FinanzasMetodosPago, IdPadre: 0, Nombre: "Finanzas método pago" },
    //     { IdModulo: CModulo.FinanzasHorasExtras, IdPadre: 0, Nombre: "Finanzas horas extras" },
    //     { IdModulo: CModulo.Circulares, IdPadre: 0, Nombre: "Circulares" },
    //     { IdModulo: CModulo.Logros, IdPadre: 0, Nombre: "Logros" },
    //     { IdModulo: CModulo.Usuarios, IdPadre: 0, Nombre: "Usuarios" },
    // ]

    // /** @deprecated En desuso*/
    // //@ts-ignore
    // let accionesArray: IPermisoAccion[] = [
    //     { IdAccion: CAccionPermiso.Ver, IdPadre: 0, Nombre: "Ver" },
    //     { IdAccion: CAccionPermiso.Agregar, IdPadre: CAccionPermiso.Ver, Nombre: "Agregar" }, // Incluye "Carga masiva"
    //     { IdAccion: CAccionPermiso.Editar, IdPadre: CAccionPermiso.Ver, Nombre: "Editar" },
    //     { IdAccion: CAccionPermiso.Eliminar, IdPadre: CAccionPermiso.Ver, Nombre: "Eliminar" }, // "Baja definitiva" en el caso del módulo: Alumnos

    //     { IdAccion: CAccionPermiso.VerDetalle, IdPadre: CAccionPermiso.Ver, Nombre: "Ver detalle" },
    //     { IdAccion: CAccionPermiso.VerContrasenia, IdPadre: CAccionPermiso.Ver, Nombre: "Ver contraseña" },
    //     { IdAccion: CAccionPermiso.EnviarEmail, IdPadre: CAccionPermiso.Ver, Nombre: "Enviar email" },
    //     { IdAccion: CAccionPermiso.EnviarSMS, IdPadre: CAccionPermiso.Ver, Nombre: "Enviar SMS" },
    //     { IdAccion: CAccionPermiso.ExportarAExcel, IdPadre: CAccionPermiso.Ver, Nombre: "Exportar a excel" },
    //     // { Id: 10, IdPadre: 1, Name: "Asignar tutor" },
    //     { IdAccion: CAccionPermiso.EditarHorario, IdPadre: CAccionPermiso.Ver, Nombre: "Editar horario" },
    //     // { Id: 12, IdPadre: 1, Name: "Editar configuración escolar" },

    //     { IdAccion: CAccionPermiso.ArgegarCargosUnicos, IdPadre: CAccionPermiso.Ver, Nombre: "Agregar cargos únicos" }, // Cosas de Alumnos/Finanzas
    //     { IdAccion: CAccionPermiso.AgregarDescuentos, IdPadre: CAccionPermiso.Ver, Nombre: "Agregar descuentos" },
    //     { IdAccion: CAccionPermiso.RegistrarPagos, IdPadre: CAccionPermiso.Ver, Nombre: "Registrar pagos" },

    //     // { Id: 14, IdPadre: 1, Name: "Asignar logro" },
    //     { IdAccion: CAccionPermiso.GenerarCobro, IdPadre: CAccionPermiso.Ver, Nombre: "Generar cobro" },
    //     { IdAccion: CAccionPermiso.Ignorar, IdPadre: CAccionPermiso.Ver, Nombre: "Ignorar" }, // (Ignorar horas extras, )

    //     // { Id: 50, IdPadre: 1, Name: "Otro.." },
    // ]

    // /** @deprecated En desuso*/
    // //@ts-ignore
    // var permisosDisponiblesArray: IPermisoDisponible[] = [
    //     { IdPermiso: 1, IdModulo: CModulo.Escuelas, IdAccion: CAccionPermiso.Ver }, // Escuelas
    //     { IdPermiso: 2, IdModulo: CModulo.Escuelas, IdAccion: CAccionPermiso.Agregar },
    //     { IdPermiso: 3, IdModulo: CModulo.Escuelas, IdAccion: CAccionPermiso.Editar },
    //     { IdPermiso: 4, IdModulo: CModulo.Escuelas, IdAccion: CAccionPermiso.Eliminar },
    //     { IdPermiso: 5, IdModulo: CModulo.PanelCicloEscolar, IdAccion: CAccionPermiso.Ver }, // Escuelas / Ciclo escolar
    //     { IdPermiso: 6, IdModulo: CModulo.PanelCicloEscolar, IdAccion: CAccionPermiso.Agregar },
    //     { IdPermiso: 7, IdModulo: CModulo.PanelConfiguracionNiveles, IdAccion: CAccionPermiso.Ver }, // Escuelas / Configuración de niveles
    //     { IdPermiso: 8, IdModulo: CModulo.PanelConfiguracionNiveles, IdAccion: CAccionPermiso.Agregar },
    //     { IdPermiso: 9, IdModulo: CModulo.PanelConfiguracionNiveles, IdAccion: CAccionPermiso.Editar },
    //     { IdPermiso: 10, IdModulo: CModulo.PanelConfiguracionNiveles, IdAccion: CAccionPermiso.Eliminar },

    //     { IdPermiso: 11, IdModulo: CModulo.Grupos, IdAccion: CAccionPermiso.Ver }, // Grupos
    //     { IdPermiso: 12, IdModulo: CModulo.Grupos, IdAccion: CAccionPermiso.Agregar },
    //     { IdPermiso: 13, IdModulo: CModulo.Grupos, IdAccion: CAccionPermiso.Editar },
    //     { IdPermiso: 14, IdModulo: CModulo.Grupos, IdAccion: CAccionPermiso.Eliminar },
    //     { IdPermiso: 15, IdModulo: CModulo.Grupos, IdAccion: CAccionPermiso.ExportarAExcel },
    //     // { Id: 16, IdModulo: CModulo.Grupos, IdAccion: CAccionPermiso.EditarHorario },

    //     { IdPermiso: 17, IdModulo: CModulo.Alumnos, IdAccion: CAccionPermiso.Ver }, // Alumnos
    //     { IdPermiso: 18, IdModulo: CModulo.Alumnos, IdAccion: CAccionPermiso.Agregar },
    //     { IdPermiso: 19, IdModulo: CModulo.Alumnos, IdAccion: CAccionPermiso.Editar },
    //     { IdPermiso: 20, IdModulo: CModulo.Alumnos, IdAccion: CAccionPermiso.Eliminar },
    //     { IdPermiso: 21, IdModulo: CModulo.Alumnos, IdAccion: CAccionPermiso.VerContrasenia },
    //     { IdPermiso: 22, IdModulo: CModulo.Alumnos, IdAccion: CAccionPermiso.ExportarAExcel },
    //     { IdPermiso: 23, IdModulo: CModulo.PanelInfoGeneral, IdAccion: CAccionPermiso.Ver }, // Alumnos / Informacion general
    //     { IdPermiso: 24, IdModulo: CModulo.PanelInfoGeneral, IdAccion: CAccionPermiso.Editar },
    //     { IdPermiso: 25, IdModulo: CModulo.PanelTutores, IdAccion: CAccionPermiso.Ver }, // Alumnos / Tutores
    //     { IdPermiso: 26, IdModulo: CModulo.PanelTutores, IdAccion: CAccionPermiso.Agregar },
    //     { IdPermiso: 27, IdModulo: CModulo.PanelTutores, IdAccion: CAccionPermiso.Eliminar },
    //     { IdPermiso: 28, IdModulo: CModulo.PanelTutores, IdAccion: CAccionPermiso.VerContrasenia },
    //     { IdPermiso: 29, IdModulo: CModulo.PanelTutores, IdAccion: CAccionPermiso.EnviarEmail },
    //     { IdPermiso: 30, IdModulo: CModulo.PanelTutores, IdAccion: CAccionPermiso.EnviarSMS },
    //     { IdPermiso: 31, IdModulo: CModulo.PanelExpediente, IdAccion: CAccionPermiso.Ver }, // Alumnos / Expediente
    //     { IdPermiso: 32, IdModulo: CModulo.PanelExpediente, IdAccion: CAccionPermiso.Agregar },
    //     { IdPermiso: 33, IdModulo: CModulo.PanelNinioHorario, IdAccion: CAccionPermiso.Ver }, // Alumnos / Horarios y grupos
    //     // { Id: 34, IdModulo: CModulo.PanelNinioHorario, IdAccion: CAccionPermiso.Agregar },
    //     { IdPermiso: 35, IdModulo: CModulo.PanelNinioHorario, IdAccion: CAccionPermiso.Editar },
    //     // { Id: 36, IdModulo: CModulo.PanelNinioHorario, IdAccion: CAccionPermiso.Eliminar },
    //     { IdPermiso: 37, IdModulo: CModulo.PanelNinioLogros, IdAccion: CAccionPermiso.Ver }, // Alumnos / Logros
    //     { IdPermiso: 38, IdModulo: CModulo.PanelNinioLogros, IdAccion: CAccionPermiso.Agregar },
    //     { IdPermiso: 39, IdModulo: CModulo.PanelCircular, IdAccion: CAccionPermiso.Ver }, // Alumnos / Comunicados
    //     { IdPermiso: 40, IdModulo: CModulo.PanelCircular, IdAccion: CAccionPermiso.Agregar },
    //     { IdPermiso: 41, IdModulo: CModulo.PanelFinanzasCargosDescuentos, IdAccion: CAccionPermiso.Ver }, // Alumnos / Finanzas - Cargos y descuentos
    //     { IdPermiso: 42, IdModulo: CModulo.PanelFinanzasCargosDescuentos, IdAccion: CAccionPermiso.Agregar },
    //     { IdPermiso: 43, IdModulo: CModulo.PanelFinanzasCargosDescuentos, IdAccion: CAccionPermiso.ArgegarCargosUnicos },
    //     { IdPermiso: 44, IdModulo: CModulo.PanelFinanzasCargosDescuentos, IdAccion: CAccionPermiso.AgregarDescuentos },
    //     { IdPermiso: 45, IdModulo: CModulo.PanelFinanzasEdoCuenta, IdAccion: CAccionPermiso.Ver }, // Alumnos / Finanzas - Estado de cuenta
    //     { IdPermiso: 46, IdModulo: CModulo.PanelFinanzasEdoCuenta, IdAccion: CAccionPermiso.RegistrarPagos },

    //     { IdPermiso: 47, IdModulo: CModulo.Tutores, IdAccion: CAccionPermiso.Ver }, // Tutores
    //     { IdPermiso: 48, IdModulo: CModulo.Tutores, IdAccion: CAccionPermiso.Agregar },
    //     { IdPermiso: 49, IdModulo: CModulo.Tutores, IdAccion: CAccionPermiso.Editar },
    //     { IdPermiso: 50, IdModulo: CModulo.Tutores, IdAccion: CAccionPermiso.Eliminar },
    //     { IdPermiso: 51, IdModulo: CModulo.Tutores, IdAccion: CAccionPermiso.ExportarAExcel },

    //     { IdPermiso: 52, IdModulo: CModulo.FinanzasCargo, IdAccion: CAccionPermiso.Ver }, // FinanzaCargos
    //     { IdPermiso: 53, IdModulo: CModulo.FinanzasCargo, IdAccion: CAccionPermiso.Agregar },
    //     { IdPermiso: 54, IdModulo: CModulo.FinanzasCargo, IdAccion: CAccionPermiso.Editar },
    //     { IdPermiso: 55, IdModulo: CModulo.FinanzasCargo, IdAccion: CAccionPermiso.Eliminar },
    //     { IdPermiso: 56, IdModulo: CModulo.FinanzasCargo, IdAccion: CAccionPermiso.ExportarAExcel },
    //     { IdPermiso: 57, IdModulo: CModulo.FinanzasCargo, IdAccion: CAccionPermiso.AgregarDescuentos },

    //     { IdPermiso: 58, IdModulo: CModulo.FinanzasMetodosPago, IdAccion: CAccionPermiso.Ver }, // FinanzasMetodosPago
    //     { IdPermiso: 59, IdModulo: CModulo.FinanzasMetodosPago, IdAccion: CAccionPermiso.Agregar },

    //     { IdPermiso: 60, IdModulo: CModulo.FinanzasHorasExtras, IdAccion: CAccionPermiso.Ver }, // FinanzasHorasExtras
    //     // { Id: 61, IdModulo: CModulo.FinanzasHorasExtras, IdAccion: CAccionPermiso.Editar }, // (Editar cargos)
    //     { IdPermiso: 62, IdModulo: CModulo.FinanzasHorasExtras, IdAccion: CAccionPermiso.VerDetalle },
    //     { IdPermiso: 63, IdModulo: CModulo.FinanzasHorasExtras, IdAccion: CAccionPermiso.ExportarAExcel },
    //     { IdPermiso: 64, IdModulo: CModulo.FinanzasHorasExtras, IdAccion: CAccionPermiso.GenerarCobro },
    //     { IdPermiso: 65, IdModulo: CModulo.FinanzasHorasExtras, IdAccion: CAccionPermiso.Ignorar },

    //     { IdPermiso: 66, IdModulo: CModulo.Circulares, IdAccion: CAccionPermiso.Ver }, // Circulares
    //     { IdPermiso: 67, IdModulo: CModulo.Circulares, IdAccion: CAccionPermiso.Agregar },
    //     { IdPermiso: 68, IdModulo: CModulo.Circulares, IdAccion: CAccionPermiso.Editar },
    //     { IdPermiso: 69, IdModulo: CModulo.Circulares, IdAccion: CAccionPermiso.Eliminar },
    //     { IdPermiso: 70, IdModulo: CModulo.Circulares, IdAccion: CAccionPermiso.ExportarAExcel },

    //     { IdPermiso: 71, IdModulo: CModulo.Logros, IdAccion: CAccionPermiso.Ver }, // Logros
    //     { IdPermiso: 72, IdModulo: CModulo.Logros, IdAccion: CAccionPermiso.Agregar },
    //     { IdPermiso: 73, IdModulo: CModulo.Logros, IdAccion: CAccionPermiso.Editar },
    //     { IdPermiso: 74, IdModulo: CModulo.Logros, IdAccion: CAccionPermiso.Eliminar },

    //     { IdPermiso: 75, IdModulo: CModulo.Usuarios, IdAccion: CAccionPermiso.Ver }, // Usuarios
    //     { IdPermiso: 76, IdModulo: CModulo.Usuarios, IdAccion: CAccionPermiso.Agregar },
    //     { IdPermiso: 77, IdModulo: CModulo.Usuarios, IdAccion: CAccionPermiso.Editar },
    //     { IdPermiso: 78, IdModulo: CModulo.Usuarios, IdAccion: CAccionPermiso.Eliminar },

    //     // { Id: 76, IdModulo: 0, IdAccion: 0 },
    // ]

    // /** @deprecated */
    // export var _permisosDisponiblesMap = new Map<number, IPermisoDisponible>();
    // /** @deprecated */
    // export var _permisosAccionesMap = new Map<number, IPermisoAccion>();
    // /** @deprecated */
    // export var _permisosModulosMap = new Map<number, IPermisoModulo>();
    // permisosDisponiblesArray.forEach(permiso => _permisosDisponiblesMap.set(permiso.IdPermiso, permiso));
    // accionesArray.forEach(accion => _permisosAccionesMap.set(accion.IdAccion, accion));
    // modulosArray.forEach(modulo => _permisosModulosMap.set(modulo.IdModulo, modulo));
}